import React from "react"
import { Upload, message } from "antd"
import { v4 as uuidv4 } from "uuid"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import moment from "moment"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, {
  Select,
  TextField,
  TextArea,
  Switch,
  DateTimePicker,
} from "../ValidatorForm"
import { EVENT_TYPES } from "../../constants/uiConstants"
import Cron from "react-cron-generator"
import toastr from "toastr"
import "react-cron-generator/dist/cron-builder.css"

export default class UpdateEvent extends ErrorBoundComponent {
  state = {
    eventObj: {
      feedStartTime:
        this.props.selectInfo && moment(this.props.selectInfo.start),
    },
  }

  _uploadProfileImage = fileObj => {
    if (fileObj.file) {
      this.setState({
        profileImageFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        profileImageFile: fileObj.file,
      })
    }
  }

  _uploadProfileVideo = fileObj => {
    if (fileObj.file) {
      this.setState({
        profileVideoFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        profileVideoFile: fileObj.file,
      })
    }
  }

  _onSubmit = ({ errors, formData }) => {
    if (errors) {
      return
    }
    let payload = formData
    let data = new FormData()
    if (this.state.profileImageFile) {
      data.append("event_thumbnail", this.state.profileImageFile)
    }
    if (this.state.profileVideoFile) {
      data.append("event_video", this.state.profileVideoFile)
    }
    if (this.state.startCronValue) {
      payload["start_cron_expression"] = this.state.startCronValue
    }
    if (this.state.endCronValue) {
      payload["end_cron_expression"] = this.state.endCronValue
    }
    if (this.state.eventObj && this.state.eventObj.feedEndTime) {
      payload.feed_end_time = this.state.eventObj.feedEndTime
    }
    if (this.state.eventObj && this.state.eventObj.feedStartTime) {
      payload.feed_start_time = this.state.eventObj.feedStartTime
    }
    if (this.state.eventObj && this.state.eventObj.taxonomyEndTime) {
      payload.feed_end_time = this.state.eventObj.taxonomyEndTime
    }
    if (this.state.eventObj && this.state.eventObj.taxonomyStartTime) {
      payload.feed_start_time = this.state.eventObj.taxonomyStartTime
    }
    let id
    if (this.props.action === "update") {
      id = this.props.selectedEvent && this.props.selectedEvent["event_id"]
      data.append("fields", JSON.stringify(payload))
    } else {
      payload["event_id"] = uuidv4()
      data.append("fields", JSON.stringify(payload))
    }
    this.props.onSubmit(formData, data, id)
    this.setState({ eventObj: {} })
  }

  _onProfileImageRemove = () => {
    this.setState({
      profileImageFileList: [],
      profileImageFile: null,
    })
  }

  _onProfileVideoRemove = () => {
    this.setState({
      profileVideoFileList: [],
      profileVideoFile: null,
    })
  }

  render() {
    const { action, selectedEvent } = this.props
    return (
      <React.Fragment>
        {this.state.showCronStart && (
          <Card>
            <div>
              <div className="d-flex justify-content-center pt-3">
                <Cron
                  onChange={e => {
                    this.setState({ startCronValue: e })
                  }}
                  value={
                    this.state.startCronValue ||
                    (selectedEvent && selectedEvent["start_cron_expression"])
                  }
                  showResultText={true}
                  showResultCron={true}
                />
              </div>
              <div className="d-flex justify-content-end my-3 mx-3">
                <div className="px-2">
                  <Button
                    color="primary"
                    onClick={() => this.setState({ showCronStart: false })}
                  >
                    Done
                  </Button>
                </div>
                <div>
                  <Button
                    color="danger"
                    onClick={() =>
                      this.setState({
                        showCronStart: false,
                        startCronValue: undefined,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        )}
        {this.state.showCronEnd && (
          <Card>
            <div>
              <div className="d-flex justify-content-center pt-3">
                <Cron
                  onChange={e => {
                    this.setState({ endCronValue: e })
                  }}
                  value={this.state.endCronValue}
                  showResultText={true}
                  showResultCron={true}
                />
              </div>
              <div className="d-flex justify-content-end my-3 mx-3">
                <div className="px-2">
                  <Button
                    color="primary"
                    onClick={() => this.setState({ showCronEnd: false })}
                  >
                    Done
                  </Button>
                </div>
                <div>
                  <Button
                    color="danger"
                    onClick={() =>
                      this.setState({
                        showCronEnd: false,
                        endCronValue: undefined,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        )}
        {!this.state.showCronStart && !this.state.showCronEnd && (
          <Card>
            <CardBody>
              <ValidatorForm
                onSubmit={this._onSubmit}
                layout={"vertical"}
                {...this._formLayout}
              >
                <Row>
                  <Col sm="12" md="6" style={{ marginBottom: "10px" }}>
                    <TextField
                      label="Event Name"
                      placeholderLabel="Event Name"
                      field="event_name"
                      className="creator-form"
                      defaultValue={
                        selectedEvent && selectedEvent["event_name"]
                      }
                      disabled={
                        this.props.selectedEvent &&
                        this.props.selectedEvent.event_name
                      }
                      value={this.state.eventObj.eventName}
                      onChange={val => {
                        this.setState({
                          eventObj: { ...this.state.eventObj, eventName: val },
                        })
                      }}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <Select
                      label="Type"
                      placeholderLabel="Type"
                      field="type"
                      className="creator-form"
                      options={EVENT_TYPES}
                      defaultValue={selectedEvent && selectedEvent["type"]}
                      value={this.state.eventObj.type}
                      onChange={val =>
                        this.setState({
                          eventObj: { ...this.state.eventObj, type: val },
                        })
                      }
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <Select
                      label="Status"
                      placeholderLabel="Status"
                      field="status"
                      className="creator-form"
                      options={[
                        { label: "Active", value: "active" },
                        { label: "Inactive", value: "inactive" },
                      ]}
                      defaultValue={selectedEvent && selectedEvent["status"]}
                      value={this.state.eventObj.status}
                      onChange={val =>
                        this.setState({
                          eventObj: { ...this.state.eventObj, status: val },
                        })
                      }
                    />
                  </Col>
                  <Col sm="12" md="6" style={{ marginBottom: "10px" }}>
                    <TextField
                      label="Hashtag"
                      placeholderLabel="Hashtag"
                      field="hashtag"
                      className="creator-form"
                      defaultValue={selectedEvent && selectedEvent["hashtag"]}
                      value={this.state.eventObj.hashtag}
                      onChange={val =>
                        this.setState({
                          eventObj: { ...this.state.eventObj, hashtag: val },
                        })
                      }
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <DateTimePicker
                      style={{ padding: "9px", width: "100%" }}
                      label="Feed Start Time"
                      field="feed_start_time"
                      defaultValue={
                        this.state.eventObj && this.state.eventObj.feedStartTime
                          ? this.state.eventObj.feedStartTime
                          : selectedEvent && selectedEvent.feed_start_time
                          ? moment(selectedEvent.feed_start_time)
                          : this.props.selectInfo &&
                            moment(this.props.selectInfo.start)
                      }
                      validations={["required"]}
                      errors={["This field is required"]}
                      onChange={val => {
                        let difference
                        if (
                          (this.state.eventObj &&
                            this.state.eventObj.feedEndTime) ||
                          (selectedEvent && selectedEvent.feed_end_time)
                        ) {
                          difference =
                            this.state.eventObj &&
                            this.state.eventObj.feedEndTime
                              ? moment(this.state.eventObj.feedEndTime).diff(
                                  moment(val),
                                  "days"
                                )
                              : moment(selectedEvent.feed_end_time).diff(
                                  moment(val),
                                  "days"
                                )

                          if (difference <= 2) {
                            this.setState({
                              eventObj: {
                                ...this.state.eventObj,
                                feedStartTime: val,
                              },
                            })
                          } else {
                            toastr.error(
                              "Feed Date Rane should not be more than 2 days"
                            )
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <DateTimePicker
                      style={{ padding: "9px", width: "100%" }}
                      label="Feed End Time"
                      field="feed_end_time"
                      defaultValue={
                        this.state.eventObj && this.state.eventObj.feedEndTime
                          ? this.state.eventObj.feedEndTime
                          : selectedEvent && selectedEvent.feed_end_time
                          ? moment(selectedEvent.feed_end_time)
                          : undefined
                      }
                      validations={["required"]}
                      errors={["This field is required"]}
                      onChange={val => {
                        let difference
                        if (
                          (this.state.eventObj &&
                            this.state.eventObj.feedStartTime) ||
                          (selectedEvent && selectedEvent.feed_start_time)
                        ) {
                          difference =
                            this.state.eventObj &&
                            this.state.eventObj.feedStartTime
                              ? moment(val).diff(
                                  moment(this.state.eventObj.feedStartTime),
                                  "days"
                                )
                              : moment(val).diff(
                                  moment(selectedEvent.feed_start_time),
                                  "days"
                                )

                          if (difference <= 2) {
                            this.setState({
                              eventObj: {
                                ...this.state.eventObj,
                                feedEndTime: val,
                              },
                            })
                          } else {
                            toastr.error(
                              "Feed Date Rane should not be more than 2 days"
                            )
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <DateTimePicker
                      style={{ padding: "9px", width: "100%" }}
                      label="Taxonomy Start Time"
                      field="taxonomy_start_time"
                      defaultValue={
                        this.state.eventObj &&
                        this.state.eventObj.taxonomyStartTime
                          ? this.state.eventObj.taxonomyStartTime
                          : selectedEvent && selectedEvent.taxonomy_start_time
                          ? moment(selectedEvent.taxonomy_start_time)
                          : this.props.selectInfo &&
                            moment(this.props.selectInfo.start)
                      }
                      onChange={val =>
                        this.setState({
                          eventObj: {
                            ...this.state.eventObj,
                            taxonomyStartTime: val,
                          },
                        })
                      }
                      validations={["required"]}
                      errors={["This field is required"]}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <DateTimePicker
                      style={{ padding: "9px", width: "100%" }}
                      label="Taxonomy End Time"
                      field="taxonomy_end_time"
                      defaultValue={
                        this.state.eventObj &&
                        this.state.eventObj.taxonomyEndTime
                          ? this.state.eventObj.taxonomyEndTime
                          : selectedEvent && selectedEvent.taxonomy_end_time
                          ? moment(selectedEvent.taxonomy_end_time)
                          : undefined
                      }
                      validations={["required"]}
                      errors={["This field is required"]}
                      onChange={val =>
                        this.setState({
                          eventObj: {
                            ...this.state.eventObj,
                            taxonomyEndTime: val,
                          },
                        })
                      }
                    />
                  </Col>
                  <Col
                    sm="12"
                    md="6"
                    style={{ marginBottom: "10px" }}
                    onClick={() => this.setState({ showCronStart: true })}
                  >
                    <TextField
                      label="Start Cron Expression"
                      placeholderLabel="Start Cron Expression"
                      field="start_cron_expression"
                      className="creator-form"
                      value={this.state.startCronValue}
                      defaultValue={
                        selectedEvent && selectedEvent["start_cron_expression"]
                      }
                    />
                  </Col>
                  <Col
                    sm="12"
                    md="6"
                    style={{ marginBottom: "10px" }}
                    onClick={() => this.setState({ showCronEnd: true })}
                  >
                    <TextField
                      label="End Cron Expression"
                      placeholderLabel="End Cron Expression"
                      field="end_cron_expression"
                      value={this.state.endCronValue}
                      className="creator-form"
                      defaultValue={
                        selectedEvent && selectedEvent["end_cron_expression"]
                      }
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <Switch
                      label="Apply TTL"
                      field="apply_ttl"
                      className="creator-form"
                      defaultValue={selectedEvent && selectedEvent["apply_ttl"]}
                      value={this.state.eventObj.ttl}
                      onChange={val =>
                        this.setState({
                          eventObj: { ...this.state.eventObj, ttl: val },
                        })
                      }
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <Switch
                      label="Recurring"
                      field="recurring"
                      className="creator-form"
                      defaultValue={selectedEvent && selectedEvent["recurring"]}
                      value={this.state.eventObj.recc}
                      onChange={val =>
                        this.setState({
                          eventObj: { ...this.state.eventObj, recc: val },
                        })
                      }
                    />
                  </Col>
                  <Col style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={this._onProfileImageRemove}
                      defaultFileList={this.state.profileImageFileList}
                      fileList={this.state.profileImageFileList}
                      customRequest={this._uploadProfileImage}
                    >
                      <Button color="primary" onClick={() => {}}>
                        <i
                          className="bx bx-upload
me-2"
                        />{" "}
                        Click to Upload Event Thumbnail
                      </Button>
                    </Upload>
                  </Col>
                  <Col style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={this._onProfileVideoRemove}
                      defaultFileList={this.state.profileVideoFileList}
                      fileList={this.state.profileVideoFileList}
                      customRequest={this._uploadProfileVideo}
                    >
                      <Button color="primary" onClick={() => {}}>
                        <i
                          className="bx bx-upload
me-2"
                        />{" "}
                        Click to Upload Event Video
                      </Button>
                    </Upload>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="flex" style={{ marginTop: "10px" }}>
                      <div className="d-flex justify-content-end">
                        <button className="btn btn-primary" htmlType="submit">
                          Submit
                        </button>
                        <button
                          className="btn btn-danger mx-2"
                          onClick={() => this.props.onCancel()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </ValidatorForm>
            </CardBody>
          </Card>
        )}
      </React.Fragment>
    )
  }
}
