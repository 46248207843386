import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import {
  requestCreateIssueComment,
  requestGetIssueComments,
} from "appRedux/sagas/requests/issueComments"
import {
  createIssueComment,
  createIssueCommentSuccess,
  getIssuesComments,
  getIssuesCommentsSuccess,
} from "appRedux/slices/issuesComments"
import { fetchError } from "appRedux/slices/common"

function* handleGetIssueComments(params) {
  try {
    const data = yield call(requestGetIssueComments, params)
    yield put(getIssuesCommentsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCreateIssueComment(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestCreateIssueComment, params)
    yield put(createIssueCommentSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getIssuesComments.type, handleGetIssueComments)
  yield takeLatest(createIssueComment.type, handleCreateIssueComment)
}
