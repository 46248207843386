import React from "react"
import { connect } from "react-redux"
import { Tooltip, Modal } from "antd"
import toastr from "toastr"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import {
  getCMSVideoTemplateListByFilters,
  upsertCMSVideoTemplate,
  resetState,
} from "../../appRedux/slices/cmsVideoTemplates"
import { Card, CardBody, CardImg } from "reactstrap"
import { getCMSVideoAssetListByFilters } from "../../appRedux/slices/cmsVideoAssets"
import { CopyToClipboard } from "react-copy-to-clipboard"
import UpsertCMSVideoTemplatesForm from "./upsertCMSVideoTemplatesForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"

class ManageCMSVideoTemplates extends ErrorBoundComponent {
  state = {
    filters: {},
    showUpsertModal: false,
    videoTemplate: {},
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(getCMSVideoAssetListByFilters({}))
      this.props.dispatch(
        getCMSVideoTemplateListByFilters(_.deepClean(this.state.filters))
      )
    }
  }

  componentDidUpdate() {
    if (!this.props.common.error && this.props.refetchData) {
      this._closeUpsertModal()
      this.props.dispatch(
        getCMSVideoTemplateListByFilters(_.deepClean(this.state.filters))
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onSubmit = async formData => {
    this.props.dispatch(upsertCMSVideoTemplate(formData))
  }

  _closeUpsertModal = () => {
    this.setState({
      showUpsertModal: false,
      formAction: "create",
      videoTemplate: {},
    })
  }

  _onCreateVideoTemplate = () => {
    this.setState({
      showUpsertModal: true,
      formAction: "create",
      videoTemplate: {},
    })
  }

  _onEditVideoTemplate = videoTemplate => {
    this.setState({
      showUpsertModal: true,
      formAction: "update",
      videoTemplate: videoTemplate,
    })
  }

  _upsertModal = () => {
    return (
      <div className="view_container">
        <UpsertCMSVideoTemplatesForm
          formAction={this.state.formAction}
          videoTemplate={this.state.videoTemplate}
          cmsVideoAssets={this.props.cmsVideoAssets}
          onSubmit={this._onSubmit}
          onCancel={this._closeUpsertModal}
        />
      </div>
    )
  }

  _actionButtons = () => {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-outline-primary font-size-15 mb-4"
          onClick={this._onCreateVideoTemplate}
        >
          Create
        </button>
      </div>
    )
  }

  _closeVideoPlayModal = () => {
    this.setState({ showVideoPlayModal: false, videoTemplate: {} })
  }

  _onVideoTemplatePlay = videoTemplate => {
    this.setState({ showVideoPlayModal: true, videoTemplate: videoTemplate })
  }

  _videoPlayModal = () => {
    return (
      <Modal
        visible={this.state.showVideoPlayModal}
        onCancel={this._closeVideoPlayModal}
        footer={null}
        title="Sample Video"
        destroyOnClose
        bodyStyle={{ overflowY: "auto", overflow: "hidden", height: "500px" }}
      >
        <video
          src={this.state.videoTemplate.previewVideo}
          width="100%"
          height="450px"
          autoPlay
        />
      </Modal>
    )
  }

  _listCmsVideoTemplates = () => {
    return (
      <div className="discovery-card-container">
        {this.props.cmsVideoTemplates.map(videoTemplate => (
          <Card
            className="text-center shadow-lg"
            style={{
              minHeight: "250px",
              borderRadius: "10px",
            }}
          >
            <CardBody className="px-0 py-0">
              <CardImg
                src={videoTemplate.previewImage}
                width="100%"
                height="450px"
                alt="template"
                className="p-1"
              />
              <Tooltip title={videoTemplate.name} style={{ minHeight: "20px" }}>
                <div className="font-size-16 text-truncate font-weight-semibold">
                  <i className={`mx-1`} /> {videoTemplate.name}
                </div>
              </Tooltip>
            </CardBody>
            <div
              className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
              onClick={e => e.stopPropagation()}
            >
              <div className="flex-fill">
                <CopyToClipboard
                  text={videoTemplate.uuid}
                  onCopy={e => {
                    toastr.success("Copied sucessfully!")
                  }}
                >
                  <Tooltip title="Copy ID">
                    <i className="bx bx-copy-alt" role="button" />
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <div className="flex-fill">
                <Tooltip title="Edit">
                  <i
                    className="bx bx-edit-alt"
                    role="button"
                    onClick={e => {
                      if (
                        !this.props.currentUser?.permissions?.includes(
                          "EDIT_CMS_VIDEO_TEMPLATE"
                        ) &&
                        this.props?.location?.pathname.split("/")[1] ===
                          "cmsVideoTemplates"
                      ) {
                        toastr.error(NO_PERMISSION_MESSAGE)
                        return
                      }
                      this._onEditVideoTemplate(videoTemplate)
                    }}
                  />
                </Tooltip>
              </div>
              <div className="flex-fill">
                <Tooltip title="Play">
                  <i
                    className="bx bx-play-circle"
                    role="button"
                    onClick={e => {
                      this._onVideoTemplatePlay(videoTemplate)
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </Card>
        ))}
      </div>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showUpsertModal ? (
          <>
            {this._actionButtons()}
            {this.state.showVideoPlayModal && this._videoPlayModal()}
            {this._listCmsVideoTemplates()}
          </>
        ) : null}
        {this.state.showUpsertModal && this._upsertModal()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    cmsVideoTemplates: _.get(store, "cmsVideoTemplates.cmsVideoTemplates"),
    cmsVideoAssets: _.get(store, "cmsVideoAssets.cmsVideoAssets"),
    refetchData: _.get(store, "cmsVideoTemplates.refetchData"),
    loading: _.get(store, "cmsVideoTemplates.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageCMSVideoTemplates)
