import React, { Component } from "react"
import TinodeChatWindow from "./TinodeChatWindow"
import Carousel from "react-material-ui-carousel"
import HashNavigation from "./lib/navigation.js"
import { Card } from "@mui/material"

export default class TinodeWrapper extends Component {
  state = {
    assignedUserIds: [
      {
        username: "nishant",
        password: "nishant",
      },
      {
        username: "someuser",
        password: "someuser",
      },
    ],
  }

  handleHashRoute = () => {
    HashNavigation.navigateTo("")
  }

  // render() {
  //   return (
  //     <Carousel onChange={this.handleHashRoute} navButtonsAlwaysVisible>
  //       {this.state.assignedUserIds.map(user => (
  //         <TinodeChatWindow windowUser={user} />
  //       ))}
  //     </Carousel>
  //   )
  // }

  render() {
    return (
      <div className="chat-card-container">
        {this.state.assignedUserIds.map((user, index) => (
          <div className="chat-card">
            <TinodeChatWindow windowUser={user} chatIndex={index} />
          </div>
        ))}
      </div>
    )
  }
}
