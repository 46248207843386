import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { List, Tooltip } from "antd"
import ReactTimeAgo from "react-time-ago"
import { issueListFilter } from "constants/uiConstants"
import { Button, Card, CardBody, CardTitle, Col, Row, Badge } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  getIssues,
  createIssue,
  resetState,
  getActivity,
  getCmsUser,
} from "appRedux/slices/creatorIssues"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import CreateIssueForm from "./createIssueForm.js"
import "./issues.style.css"
import ApexRadial from "./apexRadial"
import ActivityComp from "./ActivityComp"
import IssueList from "./IssueList"
import QueryString from "query-string"
import Chips from "components/CommonFilter/chips"

import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import toastr from "toastr"

class BrowseIssues extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      issuesList: this.props.issuesList || [],
      severity_tasks: this.props.severity_tasks || [],
      activityFilters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 5,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        isAdmin:
          this.props.currentUser &&
          (this.props.currentUser.permissions.includes("ISSUE_ADMIN") ||
            [1, 2].includes(this.props.currentUser.userType) ||
            [1, 2].includes(this.props.currentUser.jotUserType))
            ? true
            : false,
        owner_email:
          this.props.currentUser && this.props.currentUser["email"]
            ? this.props.currentUser["email"]
            : "",
      },
      filters: {
        size:
          this.props.queryParams && this.props.queryParams["size"]
            ? this.props.queryParams["size"]
            : 20,
        from:
          this.props.queryParams && this.props.queryParams["from"]
            ? this.props.queryParams["from"]
            : 1,
        issueId:
          this.props.queryParams && this.props.queryParams["issueId"]
            ? this.props.queryParams["issueId"]
            : "",
        userUuid:
          this.props.queryParams && this.props.queryParams["userUuid"]
            ? this.props.queryParams["userUuid"]
            : "",
        contentUuid:
          this.props.queryParams && this.props.queryParams["contentUuid"]
            ? this.props.queryParams["contentUuid"]
            : "",
        status:
          this.props.queryParams && this.props.queryParams["status"]
            ? this.props.queryParams["status"]
            : "",
        issueSeverity:
          this.props.queryParams && this.props.queryParams["issueSeverity"]
            ? this.props.queryParams["issueSeverity"]
            : "",
        reportedBy:
          this.props.queryParams && this.props.queryParams["reportedBy"]
            ? this.props.queryParams["reportedBy"]
            : "",
        assignedTo:
          this.props.queryParams && this.props.queryParams["assignedTo"]
            ? this.props.queryParams["assignedTo"]
            : "",
        createdDateFrom:
          this.props.queryParams && this.props.queryParams["createdDateFrom"]
            ? this.props.queryParams["createdDateFrom"]
            : null,
        createdDateTo:
          this.props.queryParams && this.props.queryParams["createdDateTo"]
            ? this.props.queryParams["createdDateTo"]
            : null,
        modifiedDateFrom:
          this.props.queryParams && this.props.queryParams["modifiedDateFrom"]
            ? this.props.queryParams["modifiedDateFrom"]
            : null,
        modifiedDateTo:
          this.props.queryParams && this.props.queryParams["modifiedDateTo"]
            ? this.props.queryParams["modifiedDateTo"]
            : null,
        title:
          this.props.queryParams && this.props.queryParams["title"]
            ? this.props.queryParams["title"]
            : "",
        owner_email:
          this.props.currentUser && this.props.currentUser["email"]
            ? this.props.currentUser["email"]
            : "",
        isAdmin:
          this.props.currentUser &&
          (this.props.currentUser.permissions.includes("ISSUE_ADMIN") ||
            [1, 2].includes(this.props.currentUser.userType) ||
            [1, 2].includes(this.props.currentUser.jotUserType))
            ? true
            : false,
      },
      showModal: false,
      currentData: {},
      formAction: null,
      data: [
        {
          values: [19, 26, 55],
          labels: ["Low", "Moderate", "High"],
          type: "pie",
        },
      ],
      COLORS: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"],
      layout: {
        height: 400,
        width: 400,
        title: "Priority",
      },
      showFilters: false,
    }
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.activityFilters)
    newFilters["currentPage"] = value
    this.setState({ activityFilters: newFilters }, () => {
      //   this.props.history.push(
      //     `/hashtag/browse?${QueryString.stringify(
      //       _.deepClean(this.state.activityFilters)
      //     )}`
      //   )
      this.props.dispatch(getActivity(_.deepClean(newFilters)))
    })
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.deepClean(this.state.filters)
      this.props.dispatch(getIssues(filters))
      this.props.dispatch(getCmsUser({ currentPage: 1, pageSize: "10000" }))
      this.props.dispatch(getActivity(_.deepClean(this.state.activityFilters)))
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.common.error && this.props.refetchData) {
      let filters = _.deepClean(this.state.filters)
      this.props.dispatch(getIssues(filters))
      this.props.dispatch(getActivity(_.deepClean(this.state.activityFilters)))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  _onActionClicked = (action, rowData) => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_BROWSE_ISSUE")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({
      showModal: true,
      formAction: action,
      currentData: rowData,
    })
  }

  issueRenderer = issue => {
    return (
      <List.Item key={issue.issueId} className="p-3">
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <Row style={{ width: "100%" }}>
              <Col span={18}>
                <div className="amContentTitle">
                  <Tooltip title={issue.summary}>
                    <div className="titleText"> {issue.summary} </div>
                  </Tooltip>
                </div>
              </Col>
            </Row>
            <Row
              style={{ width: "100%", marginTop: this.props.isMobile ? 10 : 5 }}
            >
              <Col xs={12} sm={12} md={8} lg={8}>
                <Badge className="badge-soft-success  ms-1 p-2 font-size-16">
                  <Tooltip title="Priority">{issue.issueSeverity}</Tooltip>
                </Badge>

                {issue.issueType && (
                  <Badge className="badge-soft-primary  ms-1 p-2 font-size-16">
                    <Tooltip title="Issue Type">{issue.issueType}</Tooltip>
                  </Badge>
                )}
                {issue.phoneMake && (
                  <Badge className="badge-soft-danger  ms-1 p-2 font-size-16">
                    <Tooltip title="Phone Make">{issue.phoneMake}</Tooltip>
                  </Badge>
                )}
                {issue.phoneModel && (
                  <Badge className="badge-soft-warning  ms-1 p-2 font-size-16">
                    <Tooltip title="Phone Model">{issue.phoneModel}</Tooltip>
                  </Badge>
                )}
                {issue.status && (
                  <Badge className="badge-soft-info  ms-1 p-2 font-size-16">
                    <Tooltip title="Status">
                      {issue.status === "active" ? "ACTIVE" : "INACTIVE"}
                    </Tooltip>
                  </Badge>
                )}
                {issue.createdOn && (
                  <Badge className={`fontSize14 badge-soft-success ms-1 p-2`}>
                    <Tooltip title={"Created"}>
                      <ReactTimeAgo
                        date={
                          Number.isNaN(issue.createdOn)
                            ? Date.parse(issue.createdOn)
                            : issue.createdOn
                        }
                        locale="en-US"
                      />
                    </Tooltip>
                  </Badge>
                )}
                {issue.modifiedOn && (
                  <Badge className={`fontSize14 badge-soft-warning ms-1 p-2`}>
                    <Tooltip title="Modified">
                      <ReactTimeAgo
                        date={
                          Number.isNaN(issue.modifiedOn)
                            ? Date.parse(issue.modifiedOn)
                            : issue.modifiedOn
                        }
                        locale="en-US"
                      />
                    </Tooltip>
                  </Badge>
                )}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div
                  style={{ fontSize: "21px", float: "right", padding: "5px" }}
                  className="d-flex justify-content-start"
                >
                  <CopyToClipboard
                    text={issue.contentUuid}
                    onCopy={e => {
                      toastr.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy Content ID">
                      <i className="fontSize18 bx bx-copy mx-2" role="button" />
                    </Tooltip>
                  </CopyToClipboard>
                  <CopyToClipboard
                    text={issue.userUuid}
                    onCopy={e => {
                      toastr.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy User ID">
                      <i
                        className="fontSize18 bx bx-user-pin mx-2"
                        role="button"
                      />
                    </Tooltip>
                  </CopyToClipboard>
                  <Tooltip title="Edit">
                    <i
                      className="bx bx-edit mx-2"
                      role="button"
                      onClick={() => this._handleEditIssue(issue)}
                    />
                  </Tooltip>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </List.Item>
    )
  }

  _searchContent = () => {
    let { filters } = this.state
    if (filters && filters.size) {
      delete filters.from
    }
    this.props.history.push(
      `/browse/issues?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )

    this.setState({ isOpen: false }, () =>
      this.props.dispatch(getIssues(_.deepClean(filters)))
    )
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({
      showFilters: false,
    })
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    this.setState({
      filters: newFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: issueListFilter[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/browse/issues?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )

        this.props.dispatch(getIssues(_.deepClean(this.state.filters)))
      }
    )
  }

  _renderFilter = () => {
    return (
      <Chips
        showFilters={this.state.showFilters}
        filters={this.state.filters}
        search={this._closeFilters}
        removeFilter={this._removeFilter}
        handleFilterChange={this._handleFilterChange}
        type="issueList"
        toggleFilters={this._toggleFilters}
      />
    )
  }

  _resrtFilter = () => {
    const newFilter = {
      from: 1,
      size: 20,
      isAdmin:
        this.props.currentUser &&
        (this.props.currentUser.permissions.includes("ISSUE_ADMIN") ||
          [1, 2].includes(this.props.currentUser.userType) ||
          [1, 2].includes(this.props.currentUser.jotUserType))
          ? true
          : false,
      owner_email:
        this.props.currentUser && this.props.currentUser["email"]
          ? this.props.currentUser["email"]
          : "",
    }

    this.setState(
      {
        filters: newFilter,
      },
      () => {
        this._searchContent()
      }
    )
  }

  _showIssues = () => {
    return (
      <div className="issue_list_container">
        <div className="issue_btn_header">
          <div className="chips filterCss">
            <Chips
              showFilters={this.state.showFilters}
              filters={this.state.filters}
              search={this._closeFilters}
              removeFilter={this._removeFilter}
              handleFilterChange={this._handleFilterChange}
              type="issueList"
              toggleFilters={this._toggleFilters}
            />
          </div>
          <div className="display_flex_header">
            <div className="add_issue_button">
              <Button
                type="button"
                color="primary"
                // className="mb-1 me-1"
                className="oneLineText"
                onClick={this._resrtFilter}
                size="sm"
              >
                <span className="add_issue_button_text">
                  <i className="bx bx-reset me-1" />
                  Reset Filter
                </span>
              </Button>
            </div>
            <div className="add_issue_button">
              <Button
                type="button"
                color="primary"
                // className="mb-1 me-1"
                className="oneLineText"
                onClick={this._onActionClicked}
                size="sm"
              >
                <span
                  className="add_issue_button_text"
                  style={{ padding: "2px" }}
                >
                  <i className="bx bx-plus me-1" />
                  Create Issue
                </span>
              </Button>
            </div>
          </div>
        </div>
        <IssueList
          {...this.props}
          className="issuesList"
          filters={this.state.filters}
          handleEditIssue={this._handleEditIssue}
          renderFilter={() => {}}
        />
      </div>
    )
    // return (
    //     <List
    //         className="issuesList"
    //         loading={this.props.loading || !this.state.issuesList}
    //         dataSource={this.props.issuesList}
    //         renderItem={this.issueRenderer}
    //         loadMore={this.props.loadMore}
    //         scroll={{ x: "max-content" }}
    //         size="small"
    //         pagination={true}
    //     />
    // )
  }

  _onUserSearchChange = userSearchKeyword => {
    let filteredUserList = []
    if (userSearchKeyword) {
      filteredUserList = _.filter(this.props.userList, ({ name, email }) => {
        return (
          _.includes(name, userSearchKeyword) ||
          _.includes(email, userSearchKeyword)
        )
      })
    }
    this.setState({
      userList: filteredUserList,
      filterApplied: userSearchKeyword,
    })
  }

  _closeModal = () => {
    this.setState({
      showModal: false,
      issueData: {},
      defaultValue: null,
    })
  }

  _onIssueSubmit = async payload => {
    let response
    response = await this.props.dispatch(createIssue(payload))

    this.setState({ showModal: false, issueData: {} })
  }

  _getModalContent = () => {
    return (
      <CreateIssueForm
        cmsUserList={this.props.cmsUserList}
        issueData={this.state.issueData}
        loading={this.props.audioLoading}
        common={this.props.common}
        currentUser={this.props.currentUser}
        formAction={this.state.action}
        onCancel={this._closeModal}
        onSubmit={this._onIssueSubmit}
        filtersList={this.props.filtersList}
        effectsList={this.props.effectsList}
        masksList={this.props.masksList}
        faceUnityList={this.props.faceUnityList}
        selectedUser={this.state.selectedUser}
        isAdmin={this.state.filters.isAdmin}
      />
    )
  }

  _modal = () => {
    return (
      <Card>
        <CardTitle className="p-3 pb-0">{"Create/Edit Issue"}</CardTitle>
        <CardBody style={{ overflowY: "auto" }}>
          {this._getModalContent()}
        </CardBody>
      </Card>
    )
  }

  _handleStatusFilterChange = (value, type) => {
    let filteredUserList = []
    filteredUserList = this.props.userList.filter(user => {
      const val = type === "agency" ? value : parseInt(value)
      if (val === "NO_AGENCY") {
        return !user[type]
      }
      if (type === "permissions") {
        return user.permissions.includes(value)
      } else return user[type] === val
    })
    this.setState({
      userList: filteredUserList,
      filterApplied: value ? type : undefined,
    })
  }

  _handleCreateIssue = () => {
    this.setState({
      showModal: true,
    })
  }

  _handleEditIssue = issue => {
    this.setState({
      showModal: true,
      issueData: issue,
    })
  }

  _getOpenIssues = () => {
    const newFilter = {
      from: 1,
      size: 20,
      isAdmin:
        this.props.currentUser &&
        (this.props.currentUser.permissions.includes("ISSUE_ADMIN") ||
          [1, 2].includes(this.props.currentUser.userType) ||
          [1, 2].includes(this.props.currentUser.jotUserType))
          ? true
          : false,
      owner_email:
        this.props.currentUser && this.props.currentUser["email"]
          ? this.props.currentUser["email"]
          : "",
    }
    newFilter["status"] = ["reported", "verified_open", "waiting_for_feedback"]

    this.setState(
      {
        filters: newFilter,
      },
      () => {
        this._searchContent()
      }
    )
  }

  _getAssignedIssues = () => {
    const newFilter = {
      from: 1,
      size: 20,
      isAdmin:
        this.props.currentUser &&
        (this.props.currentUser.permissions.includes("ISSUE_ADMIN") ||
          [1, 2].includes(this.props.currentUser.userType) ||
          [1, 2].includes(this.props.currentUser.jotUserType))
          ? true
          : false,
      owner_email:
        this.props.currentUser && this.props.currentUser["email"]
          ? this.props.currentUser["email"]
          : "",
    }
    newFilter["assignedTo"] = this.state.filters?.owner_email || ""

    this.setState(
      {
        filters: newFilter,
      },
      () => {
        this._searchContent()
      }
    )
  }

  _getCreatededIssues = () => {
    const newFilter = {
      from: 1,
      size: 20,
      isAdmin:
        this.props.currentUser &&
        (this.props.currentUser.permissions.includes("ISSUE_ADMIN") ||
          [1, 2].includes(this.props.currentUser.userType) ||
          [1, 2].includes(this.props.currentUser.jotUserType))
          ? true
          : false,
      owner_email:
        this.props.currentUser && this.props.currentUser["email"]
          ? this.props.currentUser["email"]
          : "",
    }
    newFilter["reportedBy"] = this.state.filters?.owner_email || ""

    this.setState(
      {
        filters: newFilter,
      },
      () => {
        this._searchContent()
      }
    )
  }

  _getIssuesPriorityWise = priority => {
    const newFilter = {
      from: 1,
      size: 20,
      isAdmin:
        this.props.currentUser &&
        (this.props.currentUser.permissions.includes("ISSUE_ADMIN") ||
          [1, 2].includes(this.props.currentUser.userType) ||
          [1, 2].includes(this.props.currentUser.jotUserType))
          ? true
          : false,
      owner_email:
        this.props.currentUser && this.props.currentUser["email"]
          ? this.props.currentUser["email"]
          : "",
    }
    newFilter["status"] = ["reported", "verified_open", "waiting_for_feedback"]
    newFilter["issueSeverity"] = priority || ""

    this.setState(
      {
        filters: newFilter,
      },
      () => {
        this._searchContent()
      }
    )
  }

  render() {
    var ts = Math.round(new Date().getTime() / 1000)
    var tsYesterday = ts - 24 * 3600
    const { severity_tasks = [], issuesDetails = {} } = this.props
    const openIssues = issuesDetails.openIssues || 0
    const issuesCreated = issuesDetails.issuesCreatedByOwner || 0
    const issuesAssigned = issuesDetails.issuesAssignToOwner || 0
    let lowArr = severity_tasks.filter(task => task.key === "low")
    let moderateArr = severity_tasks.filter(task => task.key === "moderate")
    let highArr = severity_tasks.filter(task => task.key === "high")

    const low = lowArr.length > 0 ? lowArr[0]["doc_count"] : 0
    const moderate = moderateArr.length > 0 ? moderateArr[0]["doc_count"] : 0
    const high = highArr.length > 0 ? highArr[0]["doc_count"] : 0
    const totalIssues = issuesDetails.totalIssues || 0
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showModal && (
          <Row className="container_hight">
            <Col xl="8">
              <Row className="shadow-lg">
                {_.get(this.props, "issuesList") && !this.state.showModal
                  ? this._showIssues()
                  : ""}
              </Row>
            </Col>
            <Col xl="4" className="margin_left_auto hight_95_vh">
              <div>
                <Row className="chart_hight">
                  <Card className="shadow-lg">
                    <CardBody>
                      <div className="row">
                        <Col
                          xl="4"
                          role="button"
                          onClick={this._getOpenIssues}
                          title={"Issues Open: " + openIssues}
                        >
                          <ApexRadial
                            text="Open Issues"
                            count={openIssues}
                            priority={
                              openIssues && totalIssues
                                ? Math.round((openIssues / totalIssues) * 100)
                                : 0
                            }
                            color={"#556ee6"}
                          />
                        </Col>
                        <Col
                          xl="4"
                          role="button"
                          onClick={this._getAssignedIssues}
                          title={"Issues Assigned: " + issuesAssigned}
                        >
                          <ApexRadial
                            text="Issues Assigned"
                            count={issuesAssigned}
                            priority={
                              issuesAssigned && totalIssues
                                ? Math.round(
                                    (issuesAssigned / totalIssues) * 100
                                  )
                                : 0
                            }
                            color={"#69964e"}
                          />
                        </Col>
                        <Col
                          xl="4"
                          role="button"
                          onClick={this._getCreatededIssues}
                          title={"Issues Created: " + issuesCreated}
                        >
                          <ApexRadial
                            text="Issues Created"
                            count={issuesCreated}
                            priority={
                              issuesCreated && totalIssues
                                ? Math.round(
                                    (issuesCreated / totalIssues) * 100
                                  )
                                : 0
                            }
                            color={"#6ec23e"}
                          />
                        </Col>
                        <Col
                          xl="4"
                          role="button"
                          onClick={() => this._getIssuesPriorityWise("low")}
                          title={"Low Priority Issues: " + low}
                        >
                          <ApexRadial
                            text="Low Priority"
                            count={low}
                            priority={
                              low && openIssues
                                ? Math.round((low / openIssues) * 100)
                                : 0
                            }
                            color={"#ffff00"}
                          />
                        </Col>
                        <Col
                          xl="4"
                          role="button"
                          onClick={() =>
                            this._getIssuesPriorityWise("moderate")
                          }
                          title={"Moderate Priority Issues: " + moderate}
                        >
                          <ApexRadial
                            text="Moderate Priority"
                            count={moderate}
                            priority={
                              moderate && openIssues
                                ? Math.round((moderate / openIssues) * 100)
                                : 0
                            }
                            color={"#ffa500"}
                          />
                        </Col>
                        <Col
                          xl="4"
                          role="button"
                          onClick={() => this._getIssuesPriorityWise("high")}
                          title={"High Priority Issues: " + high}
                        >
                          <ApexRadial
                            text="High Priority"
                            count={high}
                            priority={
                              high && openIssues
                                ? Math.round((high / openIssues) * 100)
                                : 0
                            }
                            color={"#FF0000"}
                          />
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
                <Row className="activity_hight">
                  <ActivityComp
                    {...this.state}
                    _onPageChange={this._onPageChange}
                    currentUser={this.props.currentUser}
                  />
                </Row>
              </div>
            </Col>
          </Row>
        )}
        {this.state.showModal && this._modal()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    issuesList: _.get(store, "creatorIssues.issuesList"),
    refetchData: _.get(store, "creatorIssues.refetchData"),
    severity_tasks: _.get(store, "creatorIssues.severity_tasks"),
    issuesDetails: _.get(store, "creatorIssues.issuesDetails"),
    total: _.get(store, "creatorIssues.total"),
    activityList: _.get(store, "creatorIssues.activityList"),
    totalActivity: _.get(store, "creatorIssues.totalActivity"),
    loading: _.get(store, "creatorIssues.loading"),
    common: _.get(store, "common"),
    cmsUserList: _.get(store, "creatorIssues.cmsUserList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
  }
}

export default connect(mapStateToProps)(BrowseIssues)
