import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
// import { LANGUAGES } from "constants/uiConstants"

const { Option } = Select

const BrandLogoDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Campaign UUID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["campaignUUID"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(
                      e.target.value.trim(),
                      "campaignUUID"
                    )
                  }
                  allowClear={true}
                  name="campaignUUID"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>

        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Brand ID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["brandUUID"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "brandUUID")
                  }
                  allowClear={true}
                  name="brandUUID"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Campaign Name</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["campaignName"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(
                      e.target.value.trim(),
                      "campaignName"
                    )
                  }
                  allowClear={true}
                  name="campaignName"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(BrandLogoDrawer)
