import { createSlice } from "@reduxjs/toolkit"

const commentSlice = createSlice({
  name: "comment",
  initialState: {
    loading: false,
    commentList: [],
    commentGroups: null,
    comment: [],
    refetchTaskList: false,
    reassignAllTaskLoading: false,
    refetchData: false,
  },
  reducers: {
    getCommentByFilter(state, action) {
      return {
        ...state,
        loading: true,
        comment: [],
      }
    },
    getCommentsGroupByUser(state, action) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        commentGroups: null,
      }
    },
    getCommentsGroupByUserSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        commentGroups: payload.data,
      }
    },
    getCommentByFilterSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        comment: payload.data.comment,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
      }
    },
    bulkDeActivateComments(state) {
      return {
        ...state,
        referchData: false,
      }
    },
    bulkDeActivateCommentsSuccess(state, data) {
      return {
        ...state,
        refetchData: true,
      }
    },
    resetState(state) {
      return {
        ...state,
        loading: false,
        commentList: [],
        comment: [],
        refetchTaskList: false,
        reassignAllTaskLoading: false,
        commentGroups: [],
      }
    },
  },
})

export const {
  getCommentByFilter,
  getCommentByFilterSuccess,
  getCommentsGroupByUser,
  getCommentsGroupByUserSuccess,
  resetState,
  bulkDeActivateComments,
  bulkDeActivateCommentsSuccess,
} = commentSlice.actions

export default commentSlice.reducer
