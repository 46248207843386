import React from "react"
import { useDispatch } from "react-redux"
import { Card, CardTitle, CardBody, Badge } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip, Popconfirm } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { updateVersion } from "../../appRedux/slices/templateSection"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"

const TemplateSectionCard = props => {
  const dispatch = useDispatch()

  const { data } = props

  return (
    <>
      <Card
        onClick={() => props.onClickCard(data)}
        className={`${props.customClassname || ""} text-center shadow-lg`}
        style={{
          minHeight: "200px",
          borderRadius: "10px",
        }}
        key={data?.section_id || data?.section_id || ""}
      >
        <CardBody>
          <div className="avatar-md mb-4 m-auto">
            <span className="avatar-title rounded-circle bg-gradient font-size-24 font-weight-semibold">
              {data.section_name && data.section_name.charAt(0).toUpperCase()}
            </span>
          </div>
          <CardTitle className="font-size-16">{data.section_name}</CardTitle>
          <Tooltip title="Status">
            <Badge
              className={`${
                data.is_active ? "badge-soft-success" : "badge-soft-danger"
              } ms-1 p-2 font-size-12`}
            >
              {data.is_active ? "Active" : "Inactive"}
            </Badge>
          </Tooltip>
        </CardBody>
        <div
          className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <div className="flex-fill">
            <CopyToClipboard
              text={data?.section_id || ""}
              onCopy={e => {
                toastr.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy ID">
                <i className="bx bx-copy-alt" role="button" />
              </Tooltip>
            </CopyToClipboard>
          </div>

          <div className="flex-fill">
            <Tooltip title="Edit">
              <i
                className="bx bx-edit-alt"
                role="button"
                onClick={() => {
                  if (
                    !props.currentUser?.permissions?.includes(
                      "EDIT_TEMPLATES"
                    ) &&
                    props?.location?.pathname.split("/")[1] ===
                      "template-section"
                  ) {
                    toastr.error(NO_PERMISSION_MESSAGE)
                    return
                  }
                  props.onEditClick(data)
                }}
              />
            </Tooltip>
          </div>

          <div className="flex-fill">
            <Tooltip title="Update Version">
              <i
                className="bx bx-up-arrow"
                role="button"
                onClick={() => {
                  if (
                    !props.currentUser?.permissions?.includes(
                      "EDIT_TEMPLATES"
                    ) &&
                    props?.location?.pathname.split("/")[1] ===
                      "template-section"
                  ) {
                    toastr.error(NO_PERMISSION_MESSAGE)
                    return
                  }
                  dispatch(updateVersion({ sectionId: data?.section_id }))
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Card>
    </>
  )
}

export default TemplateSectionCard
