import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import {
  getChallengeListByFilters,
  resetState,
} from "appRedux/slices/challenge"
import _ from "lodash"
import { Input } from "antd"
import { Row, Col, Card, CardBody } from "reactstrap"
import ChallengeContentList from "../../components/ChallengeContentList/challengeContentList"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import "./styles.css"
import { challengeFilters } from "constants/uiConstants"
import { getContentByUUIDs } from "clientServices/contentService"

class BrowseChallenge extends ErrorBoundComponent {
  state = {
    contentUuids: [],
    hashtag: "",
    loading: false,
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      this.setState({
        loading: true,
      })
      let { data } = await getContentByUUIDs(this.state.contentUuids)
      this.setState({
        contentUUIDsDetails: data,
        loading: false,
      })
    }
  }

  _searchContent = async () => {
    this.setState({
      loading: true,
    })
    let { data } = await getContentByUUIDs(this.state.contentUuids)
    this.setState({
      contentUUIDsDetails: data,
    })
    this.setState({
      loading: false,
    })
  }

  _filters = () => {
    return (
      <Card>
        <CardBody>
          <Row className="d-flex align-items-center">
            <Col lg="6" md="6">
              <Input
                placeholder="Content IDs"
                onChange={e => {
                  this.setState(
                    {
                      contentUuids: e.target.value.trim().split(","),
                    },
                    () => this._searchContent()
                  )
                }}
                value={this.state.contentUuids.join(",")}
              />
            </Col>
            <Col lg="6" md="6">
              <Input
                placeholder="Hashtag"
                onChange={e => {
                  this.setState({
                    hashtag: e.target.value.trim(),
                  })
                }}
                value={this.state.hashtag}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        {this.state.contentUuids?.length > 0 && (
          <ChallengeContentList
            style={{ marginTop: "1rem" }}
            contentUUIDs={this.state.contentUuids}
            contentUUIDsDetails={this.state.contentUUIDsDetails}
            hashtag={this.state.hashtag}
          />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    common: _.get(store, "common"),
  }
}

export default connect(mapStateToProps)(BrowseChallenge)
