import React from "react"
import { connect } from "react-redux"
import { Button } from "reactstrap"
import Auxiliary from "../../util/Auxiliary"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import TemplateCard from "containers/Templates/templateCard"
import {
  getTemplates,
  addTemplate,
  resetTemplateState,
  refreshTemplate,
} from "../../appRedux/slices/templateSection"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import "../Comment/browseComment.style.css"
import TepmlateReorder from "./templateReorder"
import { Input } from "antd"

class SelectedTemplateList extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      showOrder: false,
      showSeachAndSelectModal: false,
      keyWord: "",
      templateViewOrderMap:
        this.props?.data?.sectionMappingData?.data?.reduce((acc, curr) => {
          return { ...acc, [curr.template_id]: curr.view_order }
        }, {}) || {},
      filters: {
        pageSize: 1000,
        currentPage: 1,
        templateIdList:
          this.props?.data?.sectionMappingData?.data?.map(
            template => template.template_id
          ) || [],
      },
    }
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters.templateIdList.length > 0
    ) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getTemplates({ filters: _.deepClean(filters) }))
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (
      this.props.refetchTemplateData &&
      this.state.filters.templateIdList.length > 0
    ) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getTemplates({ filters: _.deepClean(filters) }))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetTemplateState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
      })
    }
  }

  _buttons = () => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <div className="d-flex my-2">
            <Button
              size="sm"
              color="primary"
              className="font-size-12 text-truncate"
              onClick={() => this.props.onClose()}
            >
              <i className="fa fa-chevron-left mx-1 my-1" />
              Back
            </Button>
          </div>
          <div className="d-flex my-2">
            <Button
              size="sm"
              color="primary"
              className="font-size-12 text-truncate me-2"
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_TEMPLATES"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this.setState({ showSeachAndSelectModal: true })
              }}
            >
              <i className="fa fa-plus mx-1 my-1" />
              Add
            </Button>
            <Button
              color="primary"
              style={{
                marginRight: "5px",
              }}
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_TEMPLATES"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this.setState({ showOrder: true })
              }}
            >
              <i className="fa fa-sync" style={{ marginRight: "8px" }}></i>
              Reorder
            </Button>

            <Button
              color="primary"
              className="d-flex ml-6"
              onClick={() => {
                this.props.dispatch(refreshTemplate())
              }}
            >
              <i className="fa fa-undo mx-1 my-1" />
              Refresh
            </Button>
          </div>
        </div>
        <div>
          <Input
            className="mb-2"
            placeholder={"Search by name..."}
            onChange={e => this.setState({ keyWord: e.target.value })}
            allowClear={true}
            name="search"
            style={{ width: "35%" }}
          />
        </div>
      </>
    )
  }

  _onAdd = async selectedElements => {
    let filters = _.clone(this.state.filters)
    filters.templateIdList = selectedElements || []
    const templateViewOrderMap = {}
    const request = selectedElements.map((element, idx) => {
      templateViewOrderMap[element] = idx + 1
      return {
        sectionId: this.props?.data?.section_id,
        templateId: element,
        viewOrder: idx + 1,
      }
    })
    await this.props.dispatch(addTemplate(request))

    this.setState({
      filters: _.deepClean(filters),
      templateViewOrderMap,
      showSeachAndSelectModal: false,
    })
  }

  _onSubmitReorder = async data => {
    let filters = _.clone(this.state.filters)
    const templateIdList = []
    const templateViewOrderMap = {}
    const request = data.map((template, idx) => {
      templateIdList.push(template.template_uuid)
      templateViewOrderMap[template.template_uuid] = idx + 1
      return {
        sectionId: this.props?.data?.section_id,
        templateId: template.template_uuid,
        viewOrder: idx + 1,
      }
    })

    filters.templateIdList = templateIdList || []

    await this.props.dispatch(addTemplate(request))

    this.setState({
      filters: _.deepClean(filters),
      templateViewOrderMap,
      showOrder: false,
    })
  }

  _showReorderTab = data => {
    return (
      <TepmlateReorder
        onCancel={() => this.setState({ showOrder: false })}
        onSubmit={this._onSubmitReorder}
        data={data}
        sectionId={this.props?.data?.section_id}
      />
    )
  }

  render() {
    let finalList = this.props.templatesList
      .map(template => {
        return {
          ...template,
          viewOrder: this.state.templateViewOrderMap[template.template_uuid],
        }
      })
      .sort((a, b) => a.viewOrder - b.viewOrder)

    if (this.state.keyWord) {
      finalList = finalList.filter(template =>
        template?.asset_label?.toLowerCase()?.includes(this.state.keyWord)
      )
    }
    return (
      <React.Fragment>
        <Auxiliary
          loading={this.props.loading}
          error={_.get(this.props, "common.error")}
        >
          {!this.state.showSeachAndSelectModal && !this.state.showOrder && (
            <React.Fragment>
              {this._buttons()}
              {/* <Chips
                                showFilters={this.state.showFilters}
                                filters={this.state.filters}
                                search={this._closeFilters}
                                removeFilter={this._removeFilter}
                                handleFilterChange={this._handleFilterChange}
                                type="manage_gift_collection_entity"
                                toggleFilters={this._toggleFilters}
                                filterKeys={FILTER_KEYS}
                            /> */}
              <div className="discovery-card-container">
                {finalList &&
                  finalList.map((template, i) => {
                    return (
                      <TemplateCard
                        data={template}
                        key={template.template_uuid}
                        onEditClick={() => {}}
                        hideButton={true}
                        currentUser={this.props.currentUser}
                      />
                    )
                  })}
              </div>
              {/* {this.props.entityList && this.props.entityList.length > 0 && (
                                <div className="d-flex justify-content-end">
                                    <Pagination
                                        style={{
                                            marginTop: "1rem",
                                            marginBottom: "1rem",
                                        }}
                                        onChange={this._onPageChange}
                                        defaultCurrent={this.state.filters.currentPage}
                                        total={this.props.total < 10000 ? this.props.total : 10000}
                                        pageSize={this.state.filters.pageSize}
                                        showSizeChanger={false}
                                    />
                                </div>
                            )} */}
            </React.Fragment>
          )}
        </Auxiliary>
        {this.state.showOrder && this._showReorderTab(finalList)}
        {this.state.showSeachAndSelectModal && (
          <SearchAndSelect
            type="TEMPLATE_SECTION"
            visible={this.state.showSeachAndSelectModal}
            onClose={() => this.setState({ showSeachAndSelectModal: false })}
            onAdd={arr => this._onAdd(arr)}
            // element={this.state.element}
            selectedArr={_.uniq(
              Object.keys(this.state.templateViewOrderMap || {}) || []
            )}
          />
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "templateSection.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "templateSection.loading"),
    templateSections: _.get(store, "templateSection.templateSections"),
    refetch: _.get(store, "templateSection.refetchData"),
    refetchTemplateData: _.get(store, "templateSection.refetchTemplateData"),
    templatesList: _.get(store, "templateSection.templatesList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(SelectedTemplateList)
