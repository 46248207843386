import React from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Select, Divider } from "antd"
import { Col, Row } from "reactstrap"

const { Option } = Select

const ChallengeDrawer = props => {
  return (
    <React.Fragment>
      <Row gutter={12}>
        <Col lg="5">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              placeholder="Search Type"
              style={{ width: "35%" }}
              allowClear={true}
              size="default"
              defaultValue={props.filters["searchType"]}
              onChange={value => props.handleFilterChange(value, "searchType")}
            >
              <Option value="hashtag">Hashtag</Option>
              <Option value="challengeId">Challenge Id</Option>
            </Select>
            <Input
              placeholder="Search Text"
              allowClear={true}
              style={{ width: "65%" }}
              onChange={e =>
                props.handleFilterChange(e.target.value, "searchText")
              }
              validations={["required"]}
              defaultValue={props.filters.searchText}
              errors={["This field is required"]}
            />
          </Input.Group>
        </Col>
        <Col span={6}>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Challenge Status</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters.status}
            placeholder="Challenge Status"
            style={{ width: "100%" }}
            onChange={value => props.handleFilterChange(value, "status")}
          >
            <Option value="STARTED">Started</Option>
            <Option value="NOT_STARTED">Not Started</Option>
            <Option value="CREATED">Created</Option>
            <Option value="CANCELLED">Cancelled</Option>
            <Option value="ENDED">Ended</Option>
          </Select>
        </Col>
        <Col>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Challenge Type</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters.type}
            placeholder="Challenge Type"
            style={{ width: "100%" }}
            onChange={value => props.handleFilterChange(value, "type")}
          >
            <Option value="HASHTAG">Hashtag</Option>
            <Option value="AUDIO">Audio</Option>
            <Option value="COLLAB">Collab</Option>
          </Select>
        </Col>
        <Col>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Sponsered</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters.sponsored}
            style={{ width: "100%" }}
            disabled={props.currentUserType > 2}
            onChange={value => props.handleFilterChange(value, "sponsored")}
          >
            <Option value="Y">Ads</Option>
            <Option value="N">Non Ads</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(ChallengeDrawer)
