import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import Chips from "components/CommonFilter/chips"
import TaxonomyContentList from "../../components/ContentList/taxonomyContentList"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getTasksByFilter, resetState } from "appRedux/slices/content"
import Auxiliary from "../../util/Auxiliary"
import "./browseContent.style.css"
import {
  getAgencyList,
  getCmsUserList,
  getDesksList,
} from "appRedux/slices/cmsUser"
import { taxonomyFilters } from "constants/uiConstants"
import { getAllZones } from "appRedux/slices/zone"

class TaxonomyContent extends ErrorBoundComponent {
  state = {
    selectedContent: {},
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchAfter:
        this.props.queryParams && this.props.queryParams["searchAfter"]
          ? this.props.queryParams["searchAfter"]
          : null,
      moderationLevel:
        this.props.queryParams && this.props.queryParams["moderationLevel"]
          ? this.props.queryParams["moderationLevel"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : this.props.currentUser.userType === 4
          ? "CREATED"
          : "",
      opSearchType:
        this.props.queryParams && this.props.queryParams["opSearchType"]
          ? this.props.queryParams["opSearchType"]
          : null,
      createdDateFrom:
        this.props.queryParams && this.props.queryParams["createdDateFrom"]
          ? this.props.queryParams["createdDateFrom"]
          : null,
      createdDateTo:
        this.props.queryParams && this.props.queryParams["createdDateTo"]
          ? this.props.queryParams["createdDateTo"]
          : null,
      orderByField:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : this.props.currentUser.userType !== 4
          ? "created_date"
          : null,
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : this.props.currentUser.userType !== 4
          ? "desc"
          : null,
      contentUUID:
        this.props.queryParams && this.props.queryParams["contentUUID"]
          ? this.props.queryParams["contentUUID"]
          : null,
      langCode:
        this.props.queryParams && this.props.queryParams["langCode"]
          ? this.props.queryParams["langCode"]
          : "",
      agency:
        this.props.queryParams && this.props.queryParams["agency"]
          ? this.props.queryParams["agency"]
          : "",
      buckets:
        this.props.queryParams && this.props.queryParams["buckets"]
          ? this.props.queryParams["buckets"]
          : [],
      deskId:
        this.props.queryParams && this.props.queryParams["deskId"]
          ? this.props.queryParams["deskId"]
          : null,
      deskType:
        this.props.queryParams && this.props.queryParams["deskType"]
          ? this.props.queryParams["deskType"]
          : "",
      taskCompletedDateFrom:
        this.props.queryParams &&
        this.props.queryParams["taskCompletedDateFrom"]
          ? this.props.queryParams["taskCompletedDateFrom"]
          : null,
      taskCompletedDateTo:
        this.props.queryParams && this.props.queryParams["taskCompletedDateTo"]
          ? this.props.queryParams["taskCompletedDateTo"]
          : null,
      userEmail:
        this.props.queryParams && this.props.queryParams["userEmail"]
          ? this.props.queryParams["userEmail"]
          : "",
    },
  }

  componentDidMount() {
    const filters = this.state.filters
    if (!this.props.common.error) {
      this.props.dispatch(getCmsUserList())
      this.props.dispatch(getDesksList())
      this.props.dispatch(getAgencyList())
      this.props.dispatch(
        getAllZones({
          currentPage: 1,
          pageSize: 10000,
          projection: [
            "title",
            "sub_title",
            "type",
            "sub_type",
            "zone_uuid",
            "status",
            "level",
            "zone_image",
          ],
        })
      )

      this.props.dispatch(
        getTasksByFilter({
          filters: _.deepClean(filters),
          currentUser: this.props.currentUser,
        })
      )
      const filterCount = Object.keys(_.deepClean(filters)).length
      this.setState({
        filterCount,
        filters,
      })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (!this.props.common.error && this.props.refetchTaskList) {
      this.props.dispatch(getTasksByFilter(_.deepClean(this.state.filters)))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _getOptions = () => {
    if (this.state.filters["deskType"] === "INTERNAL_QC_DESK") {
      const options = this.props.deskList
        .filter(
          desk => desk.deskType === "QC_DESK" && desk.agency === "INTERNAL"
        )
        .map(desk => {
          return desk
        })
      return options
    }
    if (this.state.filters["deskType"] === "QC_DESK") {
      const options = this.props.deskList
        .filter(
          desk => desk.deskType === "QC_DESK" && desk.agency !== "INTERNAL"
        )
        .map(desk => {
          return desk
        })
      return options
    }
    if (
      this.state.filters["deskType"] === "QC_DESK" &&
      this.props.currentUser.userType < 5 &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("TAG_CONTENT_ADMIN")
    ) {
      const options = this.props.deskList
        .filter(
          desk =>
            desk.deskType === "QC_DESK" &&
            desk.agency === this.props.currentUser.agency
        )
        .map(desk => {
          return desk
        })
      return options
    }
    if (this.state.filters["deskType"] === "LANGUAGE_DESK") {
      const options = this.props.deskList
        .filter(desk => ["LANGUAGE_DESK"].includes(desk.deskType))
        .map(desk => {
          return desk
        })
      return options
    }
    if (
      this.state.filters["deskType"] === "LANGUAGE_DESK" &&
      this.props.currentUser.userType < 5 &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("TAG_CONTENT_ADMIN")
    ) {
      const options = this.props.deskList
        .filter(
          desk =>
            desk.deskType === "LANGUAGE_DESK" &&
            desk.agency === this.props.currentUser.agency
        )
        .map(desk => {
          return desk
        })
      return options
    } else return []
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    this.setState({
      filters: newFilters,
    })
  }

  _searchContent = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/tasks/content/tag?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(
      getTasksByFilter(_.deepClean({ filters: this.state.filters }))
    )
  }

  _onPageChange = (value, previewContent) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    newFilters["previewContent"] = previewContent ? true : false
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/tasks/content/tag?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getTasksByFilter(
            _.deepClean({
              filters: _.deepClean(newFilters),
              currentUser: this.props.currentUser,
            })
          )
        )
      }
    )
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: taxonomyFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => this._closeFilters()
    )
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({
      showAdvancedFilter: false,
    })
  }

  _showAdvancedFilter = () => {
    this.setState({
      showAdvancedFilter: true,
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <Chips
          showFilters={this.state.showFilters}
          showAdvancedFilters={this.state.showAdvancedFilters}
          filters={this.state.filters}
          search={this._closeFilters}
          removeFilter={this._removeFilter}
          handleFilterChange={this._handleFilterChange}
          type="taxonomy"
          toggleFilters={this._toggleFilters}
          toggleAdvancedFilters={this._toggleAdvancedFilters}
          deskOptions={this.props.deskList ? this._getOptions() : []}
          agencyList={this.props.agencyList}
        />
        <TaxonomyContentList
          contentList={this.props.contentList}
          isReProcessedModerationList={
            this.state.filters["searchType"] === "reprocessed_moderation" &&
            this.state.filters["searchText"]
          }
          onPageChange={this._onPageChange}
          total={this.props.total}
          searchAfter={this.props.searchAfter}
          isMobile={this.props.isMobile}
          currentUser={this.props.currentUser}
          currentPage={this.state.filters["currentPage"]}
          pageType="task"
          pageSize={this.state.filters["pageSize"]}
          previewContent={this.state.previewContent}
        />
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    contentList: _.get(store, "content.content"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "content.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "content.loading"),
    agencyList: _.get(store, "cmsUser.agencyList"),
    deskList: _.get(store, "cmsUser.deskList"),
    moderatorList: _.get(store, "cmsUser.userList") || [],
  }
}

export default connect(mapStateToProps)(TaxonomyContent)
