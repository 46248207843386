import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { Button } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Chips from "components/CommonFilter/chips"
import {
  manageAdsProfileFilters,
  NO_PERMISSION_MESSAGE,
} from "constants/uiConstants"
import {
  upsertAdsProfile,
  getAdsProfiles,
  resetState,
} from "../../appRedux/slices/ads"
import Auxiliary from "../../util/Auxiliary"
import { Divider, Input, Pagination, Tabs } from "antd"
import "../Comment/browseComment.style.css"
import AdsProfileCard from "./adsProfileCard"
import AdsProfileForm from "./adsProfileForm"

class ManageAdsProfile extends ErrorBoundComponent {
  state = {
    currentData: null,
    showModal: false,
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      id:
        this.props.queryParams && this.props.queryParams["id"]
          ? this.props.queryParams["id"]
          : "",
      name:
        this.props.queryParams && this.props.queryParams["name"]
          ? this.props.queryParams["name"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getAdsProfiles({ filters: _.deepClean(filters) }))
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getAdsProfiles({ filters: _.deepClean(filters) }))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
      })
    }
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/ads/profile/manage?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
      this.props.dispatch(getAdsProfiles({ filters: _.deepClean(newFilters) }))
    })
  }

  _onSubmit = async payload => {
    const response = await this.props.dispatch(upsertAdsProfile(payload))
    this.setState({ showModal: false, currentData: null })
  }

  _modal = () => {
    return (
      <div className="view_container">
        <AdsProfileForm
          // pageId={this.state.currentPageId}
          onSubmit={this._onSubmit}
          data={this.state.currentData}
          // action={this.state.action}
          onCancel={() =>
            this.setState({ showModal: false, currentData: null })
          }
        />
      </div>
    )
  }

  _onEdit = data => {
    this.setState({ currentData: data, showModal: true })
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    this.setState({
      filters: newFilters,
    })
  }

  _searchCollection = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/ads/profile/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(getAdsProfiles({ filters: _.deepClean(filters) }))
  }

  _closeFilters = () => {
    this._searchCollection()
    this.setState({
      showFilters: false,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: manageAdsProfileFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/ads/profile/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getAdsProfiles({ filters: _.deepClean(this.state.filters) })
        )
      }
    )
  }

  _buttons = () => {
    return (
      <div className="d-flex justify-content-end my-2">
        <div className="d-flex">
          <Button
            color="primary"
            className="d-flex"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes(
                  "EDIT_ADS_PROFILE"
                ) &&
                this.props?.location?.pathname.split("/")[1] === "ads" &&
                this.props?.location?.pathname.split("/")[2] === "profile"
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.setState({ showModal: true })
            }}
          >
            <i className="fa fa-plus mx-1 my-1" />
            Add
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Auxiliary
          loading={this.props.loading}
          error={_.get(this.props, "common.error")}
        >
          {!this.state.showModal && (
            <React.Fragment>
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                type="manage_ads_profile"
                toggleFilters={this._toggleFilters}
              />
              {this._buttons()}
              <div className="discovery-card-container">
                {this.props.adsProfileList &&
                  this.props.adsProfileList.map((profile, i) => {
                    return (
                      // <GiftCard
                      //     data={entity}
                      //     onEditClick={this._onEdit}
                      //     currentUser={this.props.currentUser}
                      //     onDeleteClick={this._onDelete}
                      //     isDeleteEnable={false}
                      // />
                      <AdsProfileCard
                        data={profile}
                        currentUser={this.props.currentUser}
                        _onEdit={this._onEdit}
                      />
                    )
                  })}
              </div>
              {this.props.adsProfileList &&
                this.props.adsProfileList.length > 0 && (
                  <div className="d-flex justify-content-end">
                    <Pagination
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                      onChange={this._onPageChange}
                      defaultCurrent={this.state.filters.currentPage}
                      total={
                        this.props.total < 10000 ? this.props.total : 10000
                      }
                      pageSize={this.state.filters.pageSize}
                      showSizeChanger={false}
                    />
                  </div>
                )}
            </React.Fragment>
          )}
        </Auxiliary>
        {this.state.showModal && this._modal()}
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "ads.adsProfileTotal"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "ads.loading"),
    adsProfileList: _.get(store, "ads.adsProfileList"),
    refetch: _.get(store, "ads.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageAdsProfile)
