import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Upload } from "antd"
import toastr from "toastr"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm from "../ValidatorForm"

export default class GiftCollectionForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      title: this.props?.data?.title || "",
      tag: this.props?.data?.tag || "",
      type: this.props?.data?.type || "",
      priority_order: this.props?.data?.priority_order || null,
    }
  }

  componentWillUnmount() {
    this._resetState()
  }

  _resetState() {
    this.setState({
      title: "",
      tag: "",
      type: "",
      priority_order: null,
    })
  }

  _onImageRemove = type => {
    if (type === "thumbnail") {
      this.setState({
        thumbnail_url_list: [],
        thumbnailImage: null,
      })
    }
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "thumbnail") {
        this.setState({
          thumbnail_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          thumbnailImage: fileObj.file,
        })
      }
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Gift Collection`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={6}>
                <label>Title</label>
                <input
                  className="form-control"
                  value={this.state.title}
                  onChange={e => this.setState({ title: e.target.value })}
                />
              </Col>
              <Col sm={12} md={6}>
                <label>Tag</label>
                <input
                  className="form-control"
                  value={this.state.tag}
                  onChange={e => this.setState({ tag: e.target.value })}
                />
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <label>Type</label>
                <div>
                  <input
                    className="form-control"
                    value={this.state.type}
                    onChange={e => this.setState({ type: e.target.value })}
                  />
                  {/* <select
                    className="form-select"
                    onChange={e => this.setState({ type: e.target.value })}
                    value={this.state.type}
                  >
                    <option value="GIFT">Gift</option>
                    <option value="SPIN">Spin</option>
                  </select> */}
                </div>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <label>Priority Order</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.priority_order}
                    onChange={e => {
                      if (e.target.value && e.target.value < 0) {
                        toastr.error("Priority order can not be zero...")
                        return
                      }
                      this.setState({ priority_order: e.target.value })
                    }}
                  />
                </div>
              </Col>

              <Col sm="12" md="6" className="mt-4">
                <Row style={{ marginTop: "10px" }}>
                  <Upload
                    showUploadList={true}
                    onRemove={() => this._onImageRemove("thumbnail")}
                    defaultFileList={this.state.thumbnail_url_list}
                    fileList={this.state.thumbnail_url_list}
                    customRequest={fileObj =>
                      this._uploadImage(fileObj, "thumbnail")
                    }
                  >
                    <Button color="primary" onClick={() => {}}>
                      <i className="fas fa-upload" />
                      &nbsp; Upload thumbnail image
                    </Button>
                  </Upload>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="flex" style={{ marginTop: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <Button htmlType="submit" color="primary">
                      {this.props.action === "update" ? "Update" : "Create"}
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.onCancel()
                        this._resetState()
                      }}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data = new FormData()
    const payload = {}
    const { data: giftCollectionData = {} } = this.props || {}
    const { gift_collection_uuid } = giftCollectionData || {}
    const { title, tag, type, priority_order, thumbnailImage } = this.state

    if (gift_collection_uuid) {
      payload.gift_collection_uuid = gift_collection_uuid
    }
    if (title) {
      payload.title = title
    }
    if (tag) {
      payload.tag = tag
    }
    if (type) {
      payload.type = type
    }
    if (priority_order || priority_order == 0) {
      payload.priority_order = +priority_order
    }
    if (thumbnailImage) {
      data.append("thumbnail_url", thumbnailImage)
    }

    data.append("payload", JSON.stringify(payload))
    this.props.onSubmit(data)
    this._resetState()
  }

  render() {
    return this._form()
  }
}
