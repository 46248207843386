import Axios from "axios"
import toastr from "toastr"

export const bulkUploadDeactivateAudio = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/audio/bulk/update-status`, formData)
    return data
  } catch (error) {
    toastr.error("Error in bulk updating deactivate status of audio")
    throw error
  }
}
