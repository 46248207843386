function removeNullAndUndefinedFromArray(arr) {
  return _.filter(arr, value => !_.isNil(value))
}

module.exports = {
  queryStringToJSON(qs) {
    var pairs = qs.split("&")
    var result = {}

    pairs.forEach(function (p) {
      var pair = p.split("=")
      var key = pair[0]
      var value = decodeURIComponent(pair[1] || "")
      if (result[key]) {
        if (Object.prototype.toString.call(result[key]) === "[object Array]") {
          result[key].push(value)
        } else {
          result[key] = [result[key], value]
        }
      } else {
        result[key] = value
      }
    })
    return JSON.parse(JSON.stringify(result))
  },

  formatNumber(num, afterDecimal = 2) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(afterDecimal).replace(/\.0$/, "") + "B"
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(afterDecimal).replace(/\.0$/, "") + "M"
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(afterDecimal).replace(/\.0$/, "") + "K"
    }
    return num
  },

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },

  isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  },

  getColor(value) {
    //value from 0 to 1
    const hue = ((1 - value) * 120).toString(10)
    return ["hsl(", hue, ", 100%, 50%)"].join("")
  },

  removeEmptyValuesFromObject(obj) {
    return _.transform(
      obj,
      (result, value, key) => {
        // Check for null or undefined value
        if (
          _.isNil(value) ||
          (typeof value === "string" && value.trim() === "")
        ) {
          // Explicitly return undefined to skip adding the key-value pair
          return
        }

        // Handle arrays recursively
        if (_.isArray(value)) {
          result[key] = removeNullAndUndefinedFromArray(value)
        } else {
          result[key] = value
        }
      },
      {}
    ) // Provide an empty object as the initial accumulator
  },
}
