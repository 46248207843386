import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const requestRooms = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"] ? payload.filters["pageSize"] : null
  try {
    let data = await Axios.post(
      `/api/joshlive/roominfo?${QueryString.stringify({
        offset,
        limit,
      })}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestLiveRooms = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"] ? payload.filters["pageSize"] : null
  try {
    let data = await Axios.post(
      `/api/joshlive/roominfo?${QueryString.stringify({
        offset,
        limit,
      })}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertRoom = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/joshlive/room/update`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestShutRoom = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/joshlive/shutroom`, {
      data: payload,
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetJoshLiveConfig = async () => {
  try {
    let data = await Axios.get(`/api/joshlive/config`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateJoshLiveConfig = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/joshlive/config`, {
      data: payload,
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
