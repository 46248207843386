// Authentication related containers
import Login from "../containers/Authentication/login"
import Logout from "../containers/Authentication/logout"
import VerifyUser from "../containers/CMSUsers/verifyUser"
// Dashboard
import BrowseContent from "containers/Content/browseContent"
import SourceContentUrl from "containers/Content/sourceContentUrl"
import BrowseHashtag from "containers/Hashtag/browseHashtag"
import BulkUploadReport from "containers/Activity/bulkUploadReport"
import viewActivity from "containers/Activity/viewActivity"
import manageCMSUser from "containers/CMSUsers/manageCMSUser"
import manageCollectionEntities from "containers/CollectionEntities/manageCollectionEntities"
import manageContent from "containers/Content/manageContent"
import taxonomyContent from "containers/Content/taxonomyContent"
import WebsiteVerification from "containers/AppUser/websiteVerification"
import taxonomyReport from "containers/Content/taxonomyReport"
import manageCMSVideoAssets from "containers/CMSVideoAssets/manageCMSVideoAssets"
import manageCMSVideoTemplates from "containers/CMSVideoAssets/manageCMSVideoTemplates"
import browseComment from "containers/Comment/browseComment"
import browseUser from "containers/AppUser/browseUser"
import fuzzySearchUser from "containers/AppUser/fuzzySearchUser"
import appUserDetails from "containers/AppUser/appUserDetails"
import manageChallenge from "containers/Challenge/manageChallenge"
import browseChallengeContents from "containers/Challenge/browseChallengeContents"
import manageAudio from "../containers/Audio/manageAudio"
import manageCameraAsset from "containers/CameraAsset/manageCameraAsset"
import manageCategoryAsset from "containers/CameraAsset/manageCategoryAssets"
import manageVideoAssets from "containers/CameraAsset/manageVideoAssets"
import manageStickersMapping from "containers/Stickers/manageStickersMapping"
import manageStickers from "containers/Stickers/manageStickers"
import manageStickersTab from "containers/Stickers/manageStickersTab"
import manageStickersSubType from "containers/Stickers/manageStickersSubType"
import managePages from "containers/Discovery/managePages"
import ChangePassword from "containers/CMSUsers/changePassword"
import manageSource from "containers/CMSUsers/manageSource"
import CommunityDashboard from "containers/Community/managerDashboard"
import CommunityLeaderboard from "containers/Community/communityLeaderboard"
import CommunityManagerList from "containers/Community/communityManagerList"
import manageTrendingEntity from "containers/Trending/manageTrendingEntity"
import tagTrendingContent from "containers/Trending/tagTrendingContent"
import manageCampaign from "containers/Campaign/manageCampaign"
import brandLogoCampaign from "containers/Campaign/brandLogoCampaign"
import ForgetPasswordPage from "containers/Authentication/forgetPassword"
import browseChallenge from "containers/Challenge/browseChallenge"
import manageContest from "containers/Contest/manageContest"
import browseContest from "containers/Contest/browseContest"
import BrowseBrands from "containers/SponsoredTags/browseBrands"
import managePageEntities from "containers/Discovery/managePageEntities"
import reorderPageEntities from "containers/Discovery/reorderPageEntities"
import reorderDefaultPageEntities from "containers/Discovery/defaultReorder"
import ReorderDefaultCollectionEntities from "containers/Discovery/defaultOrderCollectionEntities"
import boostContent from "containers/Content/boostContent"
import boostFollow from "containers/AppUser/boostFollow"
import boostFollowStatus from "containers/AppUser/boostFollowStatus"
import manageDelta from "containers/AppUser/manageDelta"
import collectionEntitiesList from "../containers/CollectionEntities/collectionEntitiesList"
import reorderCollectionEntities from "../containers/Discovery/reorderCollectionEntities"
import manageEntityListPage from "../containers/Entity/entity"
import manageTemplate from "../containers/Templates/manageTemplates"
import manageTemplateSection from "../containers/TemplateSection/manageTemplateSection"
import manageWebGames from "../containers/Games/manageWebGames"
import manageBanners from "../containers/Entity/manageBanner"
import manageWebviewBanners from "../containers/Entity/manageWebviewBanner"
import manageIcons from "../containers/Icons/manageIcons"
import manageChips from "../containers/Entity/manageChips"
import manageMusicArtist from "../containers/Entity/manageMusicArtist"
import manageMusicLabel from "../containers/Entity/manageMusicLable"
import manageMusicPlaylist from "../containers/Entity/manageMusicPlaylist"
import manageNamedCollection from "containers/Entity/manageNamedCollection"
import manageComments from "containers/Comment/manageComments"
import BrowseRooms from "containers/Joshlive/browseRooms"
import ManageDashTransactions from "containers/Dashboards/Transactions"
import activityBooster from "containers/Activity/activityBooster"
import browseIssues from "containers/CreatorIssues/browseIssues"
import issueDetailPage from "containers/CreatorIssues/issueDetailPage"
import ManageEvents from "containers/Events/event"
import manageGifts from "../containers/Gifts/manageGifts"
import manageGiftCollections from "../containers/Gifts/manageGiftCollections"
import managePackage from "../containers/Package/managePackage"
import manageGiftCollectionEntities from "../containers/Gifts/manageGiftCollectionEntities"
import manageGiftCollectionEntitiesReorder from "../containers/Gifts/manageGiftCollectionEntitiesReorder"
import managePackageCollections from "../containers/Package/managePackageCollections"
import managePackageCollectionEntities from "../containers/Package/managePackageCollectionEntities"
import browseImage from "containers/Image/browseImage"
import manageImage from "containers/Image/manageImage"
import browseZone from "containers/Zone/browseZone"
import manageZone from "containers/Zone/manageZone"
import detailZone from "containers/Zone/detailZone"
import browseSocial from "containers/Social/browseSocial"
import manageSocial from "containers/Social/manageSocial"
import BrowseMeme from "containers/Meme/browseMeme"
import BrowseListings from "containers/Listings/browseListings"
import manageAdsConfig from "../containers/Ads/manageAdsConfig"
import manageAdsProfile from "../containers/Ads/manageAdsProfile"
import zoneBannerReview from "../containers/Zone/zoneBannerReview"
import SpinTabConfig from "../containers/Spin/SpinTabConfig"
import SpinStaticConfig from "../containers/Spin/SpinStaticConfig"
import SpinStats from "../containers/Spin/SpinStats"
import SpinUserStats from "../containers/Spin/SpinUserStats"
import SpinGiftStats from "../containers/Spin/SpinGiftStats"
import SpinTop100 from "../containers/Spin/SpinTop100"
import CampaignSpinConfig from "../containers/Spin/CampaignSpinConfig"
import manageCMSVideoGreetings from "containers/CMSVideoAssets/manageCMSVideoGreetings"
import ChatBot from "../containers/ChatBot/ChatBot"
import agencyLimit from "containers/Content/agencyLimit"
import browseCTA from "../containers/CTA/browseCTA"
import manageFAQ from "../containers/CreatorPayout/manageFAQ"
import embeddingContent from "../containers/EmbeddingContent/embeddingContent"
import browseKYC from "../containers/KYC/browseKYC"
import exclusionEntities from "../containers/ExclusionEntities/exclusionEntities"
import roomModerations from "containers/Joshlive/roomModerations"
import tinodeWrapper from "containers/Tinode/TinodeWrapper"
import instagramCrawler from "containers/Crawler/InstagramCrawler"
import youtubeCrawler from "containers/Crawler/YoutubeCrawler"
import ManageInterests from "components/Interests/ManageInterests"
import CurationList from "components/Interests/CurationList"
import UpsertInterests from "components/Interests/UpsertInterests"
import ManageStrategy from "components/Strategy/ManageStrategy"
import browseDuplicate from "containers/Content/browseDuplicate"
import cameraReorder from "containers/CameraAsset/cameraReorder"
import Themefication from "components/Themefication/themefication"
import ManageColdStart from "components/Interests/ManageColdStart"
import CmsUserAudit from "containers/CMSUsers/cmsUserAudit"
import manageBadges from "containers/Badge/manageBadges"
import manageStories from "containers/AudioStories/manageStories"
import manageSeries from "containers/AudioStories/manageSeries"
import manageAudioStoryCollection from "containers/AudioStories/manageAudioStoryCollection"
import BlockedContent from "containers/Content/blockedContent"
import JoshPaymentAudit from "containers/Dashboards/JoshPaymentAudit"

const authProtectedRoutesModeration = [
  {
    title: "Activity",
    icon: "fab fa-buffer",
    key: "activity",
    childRoutes: [
      {
        title: "View Activity",
        key: "view_activity",
        showInSidebar: true,
        renderInRoutes: true,
        component: viewActivity,
        path: "/activity",
        permissions: ["VIEW_VIEW_ACTIVITY", "EDIT_VIEW_ACTIVITY"],
        roles: [1, 2, 3, 6, 7],
      },
    ],
  },
  {
    title: "Content",
    key: "content",
    icon: "fas fa-video",
    childRoutes: [
      {
        title: "Tag Content",
        key: "tag_content",
        showInSidebar: true,
        renderInRoutes: true,
        component: taxonomyContent,
        path: "/tasks/content/tag",
        permissions: [
          "TAG_CONTENT",
          "QC_TAG_CONTENT",
          "INTERNAL_QC_TAG_CONTENT",
        ],
        roles: [1, 2, 3, 6, 7],
      },
      {
        title: "Manage Content",
        key: "manage_content",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageContent,
        permissions: ["VIEW_MANAGE_CONTENT", "EDIT_MANAGE_CONTENT"],
        path: "/content/manage",
        roles: [1, 2, 3, 6, 11, 12, 13, 21, 22, 31, 32, 33],
      },
      {
        title: "Duplicate Content",
        path: "/content/duplicate",
        component: browseDuplicate,
        showInSidebar: true,
        renderInRoutes: true,
        permissions: ["EDIT_DUPLICATE_CONTENT", "VIEW_DUPLICATE_CONTENT"],

        roles: [1, 2, 3, 6, 11, 12, 13, 21, 22, 31, 32, 33],
      },
    ],
  },
  {
    title: "CMS Users",
    key: "cms_users",
    icon: "fas fa-user-circle",
    childRoutes: [
      {
        title: "Verify User",
        key: "verify_user",
        showInSidebar: false,
        component: VerifyUser,
        path: "/user/verify",
        roles: [
          1, 2, 3, 4, 6, 7, 11, 12, 13, 14, 15, 24, 15, 21, 22, 31, 32, 33, 50,
          1000,
        ],
      },
    ],
  },
  {
    title: "Feed Ops",
    key: "cms_feed_ops",
    icon: "fas fa-file-video",
    childRoutes: [
      {
        title: "Curation",
        key: "curation",
        showInSidebar: true,
        renderInRoutes: true,
        component: CurationList,
        path: "/feed/curation",
        roles: [1, 2, 3],
        permissions: ["MANAGE_CURATION"],
      },
    ],
  },
  {
    title: "User",
    icon: "fa fa-user",
    key: "user",
    childRoutes: [
      {
        title: "Change Password",
        key: "change_password",
        showInSidebar: false,
        component: ChangePassword,
        path: "/user/change-password",
        roles: [1, 2, 3, 4, 6, 7, 11, 12, 13, 14, 15, 21, 22, 31, 32, 33, 50],
      },
      {
        title: "Log Out",
        key: "log_out",
        showInSidebar: false,
        component: Logout,
        path: "/api/auth/logout",
        roles: [
          1, 2, 3, 4, 6, 7, 11, 12, 13, 14, 15, 21, 22, 31, 32, 33, 50, 1000,
        ],
      },
    ],
  },
]

const authProtectedRoutes = [
  {
    title: "Content",
    key: "content",
    icon: "fas fa-video",
    childRoutes: [
      {
        title: "Browse Content",
        path: "/content/browse",
        component: BrowseContent,
        showInSidebar: true,
        renderInRoutes: true,
        permissions: ["EDIT_BROWSE_CONTENT", "VIEW_BROWSE_CONTENT"],
        key: "browse_content",
        roles: [1, 2, 3, 6, 7, 11, 12, 13, 14, 15, 21, 22, 31, 32, 33],
      },
      {
        title: "Blocked Content",
        path: "/content/blocked",
        component: BlockedContent,
        showInSidebar: true,
        renderInRoutes: true,
        permissions: ["VIEW_BLOCKED_CONTENT"],
        key: "blocked_content",
        roles: [1, 2, 3, 6, 7, 11, 12, 13, 14, 15, 21, 22, 31, 32, 33],
      },
      {
        title: "Manage Content",
        key: "manage_content",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageContent,
        permissions: ["VIEW_MANAGE_CONTENT", "EDIT_MANAGE_CONTENT"],
        path: "/content/manage",
        roles: [1, 2, 3, 6, 11, 12, 13, 21, 22, 31, 32, 33],
      },
      {
        title: "Manage Content",
        key: "manage_content_url",
        showInSidebar: false,
        component: manageContent,
        path: "/contents/:contentUUID",
        roles: [1, 2, 3, 6, 11, 12, 13, 21, 22, 31, 32, 33],
      },
      {
        title: "Duplicate Content",
        path: "/content/duplicate",
        component: browseDuplicate,
        showInSidebar: true,
        renderInRoutes: true,
        permissions: ["EDIT_DUPLICATE_CONTENT", "VIEW_DUPLICATE_CONTENT"],

        roles: [1, 2, 3, 6, 11, 12, 13, 21, 22, 31, 32, 33],
      },
      {
        title: "Source Content URLs",
        key: "manage_source_content_urls",
        showInSidebar: true,
        component: SourceContentUrl,
        path: "/content/source/urls",
        permissions: ["VIEW_CONTENT_SOURCE"],
        roles: [1],
      },
      {
        title: "Embedding",
        path: "/content/embedding",
        component: embeddingContent,
        showInSidebar: true,
        renderInRoutes: true,
        permissions: ["VIEW_EMBEDDING"],
        key: "embedding",
        roles: [1],
      },
      {
        title: "Themification",
        path: "/content/themification",
        component: Themefication,
        showInSidebar: true,
        renderInRoutes: true,
        permissions: ["THEMIFICATION"],
        key: "themification",
        roles: [1, 2, 3, 6, 11, 12, 13, 21, 22, 31, 32, 33],
      },
      {
        title: "Tag Content",
        key: "tag_content",
        showInSidebar: true,
        renderInRoutes: true,
        component: taxonomyContent,
        path: "/tasks/content/tag",
        permissions: [
          "TAG_CONTENT",
          "QC_TAG_CONTENT",
          "INTERNAL_QC_TAG_CONTENT",
        ],
        roles: [1, 2, 3, 6, 7],
      },
      {
        title: "Agency Limit",
        key: "agency_limit",
        showInSidebar: true,
        renderInRoutes: true,
        component: agencyLimit,
        permissions: ["VIEW_AGENCY_LIMIT", "EDIT_AGENCY_LIMIT"],
        path: "/agency/limit",
        roles: [1, 2, 3, 6, 11, 12, 13, 21, 22, 31, 32, 33],
      },
      {
        title: "Taxonomy Dashboard",
        key: "taxonomy_dashboard",
        showInSidebar: true,
        renderInRoutes: true,
        component: taxonomyReport,
        path: "/tasks/taxonomy/dashboard",
        permissions: ["VIEW_TAXONOMY_DASHBOARD"],
        roles: [1, 2, 3, 4],
      },
      {
        title: "Boost Content History",
        key: "boost_content",
        showInSidebar: true,
        renderInRoutes: true,
        component: boostContent,
        path: "/content/boost",
        permissions: ["VIEW_BOOST_CONTENT", "EDIT_BOOST_CONTENT"],
        roles: [1, 2],
      },
      {
        title: "Browse Social",
        key: "browse_social",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseSocial,
        path: "/social/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_BROWSE_SOCIAL", "EDIT_BROWSE_SOCIAL"],
      },
      {
        title: "Manage Social",
        key: "manage_social",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageSocial,
        path: "/social/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MANAGE_SOCIAL", "EDIT_MANAGE_SOCIAL"],
      },
      {
        title: "Browse Image",
        key: "browse_image",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseImage,
        path: "/image/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_BROWSE_IMAGE", "EDIT_BROWSE_IMAGE"],
      },
      {
        title: "Manage Image",
        key: "manage_image",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageImage,
        path: "/image/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MANAGE_IMAGE", "EDIT_MANAGE_IMAGE"],
      },
    ],
  },
  {
    title: "CMS Video Assets",
    key: "cms_video_assets",
    icon: "fas fa-file-video",
    childRoutes: [
      {
        title: "Manage Assets",
        key: "manage_assets",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageCMSVideoAssets,
        path: "/cmsVideoAssets/manage",
        roles: [1, 2, 3],
        permissions: ["VIEW_CMS_VIDEO_ASSET", "EDIT_CMS_VIDEO_ASSET"],
      },
      {
        title: "Manage Templates",
        key: "manage_templates",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageCMSVideoTemplates,
        path: "/cmsVideoTemplates/manage",
        roles: [1, 2, 3],
        permissions: ["VIEW_CMS_VIDEO_TEMPLATE", "EDIT_CMS_VIDEO_TEMPLATE"],
      },
      {
        title: "Manage Greetings",
        key: "manage_greetings",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageCMSVideoGreetings,
        path: "/cmsVideoGreetings/manage",
        roles: [1, 2, 3],
        permissions: ["VIEW_CMS_VIDEO_GREETINGS", "EDIT_CMS_VIDEO_GREETINGS"],
      },
    ],
  },
  {
    title: "Community",
    key: "community_management",
    icon: "bx bxs-group",
    childRoutes: [
      {
        title: "Dashboard",
        key: "dashboard",
        component: CommunityDashboard,
        path: "/community/manage",
        showInSidebar: true,
        renderInRoutes: true,
        roles: [1, 2, 11, 12, 13, 31, 32, 33],
        permissions: ["VIEW_DASHBOARD", "EDIT_DASHBOARD"],
      },
      {
        title: "Leaderboard",
        key: "leaderboard",
        component: CommunityLeaderboard,
        path: "/community/leaderboard",
        showInSidebar: true,
        renderInRoutes: true,
        roles: [1, 2, 11, 12, 13, 31, 32, 33],
        permissions: ["VIEW_DASHBOARD", "EDIT_DASHBOARD"],
      },
      {
        title: "Community Management",
        key: "community_account_manager",
        component: CommunityManagerList,
        path: "/community/manager/management",
        showInSidebar: true,
        renderInRoutes: true,
        roles: [1, 2, 11, 12, 31, 32],
        permissions: ["VIEW_COMMUNITY_MANAGEMENT", "EDIT_COMMUNITY_MANAGEMENT"],
      },
    ],
  },
  // {
  //   title: "Image",
  //   key: "image",
  //   icon: "far fa-file-image",
  //   childRoutes: [
  //     {
  //       title: "Browse Image",
  //       key: "browse_image",
  //       showInSidebar: true,
  //       component: browseImage,
  //       path: "/image/browse",
  //       roles: [1, 2, 3, 6, 7],
  //       permissions: ["VIEW_BROWSE_IMAGE", "EDIT_BROWSE_IMAGE"],
  //     },
  //     {
  //       title: "Manage Image",
  //       key: "manage_image",
  //       showInSidebar: true,
  //       component: manageImage,
  //       path: "/image/manage",
  //       roles: [1, 2, 3, 6, 7],
  //       permissions: ["VIEW_MANAGE_IMAGE", "EDIT_MANAGE_IMAGE"],
  //     },
  //   ],
  // },
  // {
  //   title: "Zone",
  //   key: "zone",
  //   icon: "bx bx-area",
  //   childRoutes: [
  //     {
  //       title: "Browse Zone",
  //       key: "browse_zone",
  //       showInSidebar: true,
  //       component: browseZone,
  //       path: "/zone/browse",
  //       roles: [1, 2, 3, 6, 7],
  //       permissions: ["VIEW_BROWSE_ZONE", "EDIT_BROWSE_ZONE"],
  //     },
  //     {
  //       title: "Manage Zone",
  //       key: "manage_zone",
  //       showInSidebar: true,
  //       component: manageZone,
  //       path: "/zone/manage",
  //       roles: [1, 2, 3, 6, 7],
  //       permissions: ["VIEW_MANAGE_ZONE", "EDIT_MANAGE_ZONE"],
  //     },
  //   ],
  // },
  // {
  //   title: "Social",
  //   key: "social",
  //   icon: "bx bx-globe",
  //   childRoutes: [
  //     {
  //       title: "Browse Social",
  //       key: "browse_social",
  //       showInSidebar: true,
  //       component: browseSocial,
  //       path: "/social/browse",
  //       roles: [1, 2, 3, 6, 7],
  //       permissions: ["VIEW_BROWSE_SOCIAL", "EDIT_BROWSE_SOCIAL"],
  //     },
  //     {
  //       title: "Manage Social",
  //       key: "manage_social",
  //       showInSidebar: true,
  //       component: manageSocial,
  //       path: "/social/manage",
  //       roles: [1, 2, 3, 6, 7],
  //       permissions: ["VIEW_MANAGE_SOCIAL", "EDIT_MANAGE_SOCIAL"],
  //     },
  //   ],
  // },
  {
    title: "Comment",
    key: "comment",
    icon: "far fa-comment-dots",
    childRoutes: [
      {
        title: "Browse Comment",
        key: "browse_comment",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseComment,
        path: "/comment/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_BROWSE_COMMENT", "EDIT_BROWSE_COMMENT"],
      },
      {
        title: "Manage Comments",
        key: "manage_comments",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageComments,
        path: "/comment/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MANAGE_COMMENT", "EDIT_MANAGE_COMMENT"],
      },
    ],
  },
  {
    title: "Trending",
    key: "trending",
    icon: "bx bx-trending-up",
    childRoutes: [
      {
        title: "Manage Trending",
        key: "manage_trending",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageTrendingEntity,
        path: "/trending/manage",
        roles: [1, 2, 3, 4, 5, 13, 15, 17],
        permissions: ["VIEW_MANAGE_TRENDING", "EDIT_MANAGE_TRENDING"],
      },
      {
        title: "Tag Trending",
        key: "tag_trending",
        showInSidebar: true,
        renderInRoutes: true,
        component: tagTrendingContent,
        path: "/trending/tag",
        roles: [1, 2, 3, 4, 5, 7, 13, 15, 17],
        permissions: ["VIEW_TAG_TRENDING", "EDIT_TAG_TRENDING"],
      },
      {
        title: "Manage Trending",
        key: "tag_trending",
        showInSidebar: false,
        component: tagTrendingContent,
        path: "/trending/content",
        roles: [1, 2, 3, 4, 5, 7, 13, 15, 17],
        permissions: ["VIEW_TAG_TRENDING", "EDIT_TAG_TRENDING"],
      },
    ],
  },
  {
    title: "Campaign",
    key: "campaign",
    icon: "fas fa-microphone",
    childRoutes: [
      {
        title: "Manage Campaign",
        key: "manage_campaign",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageCampaign,
        path: "/campaign/manage",
        roles: [1, 2],
        permissions: ["VIEW_MANAGE_CAMPAIGN", "EDIT_MANAGE_CAMPAIGN"],
      },
      {
        title: "Manage Brand Logo Campaign",
        key: "manage_brand_logo_campaign",
        showInSidebar: true,
        renderInRoutes: true,
        component: brandLogoCampaign,
        path: "/campaign/brand-logo/manage",
        roles: [1, 2],
        permissions: ["VIEW_MANAGE_CAMPAIGN", "EDIT_MANAGE_CAMPAIGN"],
      },
    ],
  },
  {
    title: "Josh Live",
    key: "josh_live",
    icon: "bx bx-video-recording",
    childRoutes: [
      {
        title: "Browse Rooms",
        key: "room_info",
        showInSidebar: false,
        renderInRoutes: false,
        component: BrowseRooms,
        path: "/rooms/browse",
        roles: [1, 2], //Permissions and Roles ?
        permissions: ["VIEW_BROWSE_ROOM", "EDIT_BROWSE_ROOM"],
      },
      {
        title: "Rooms Moderation",
        key: "room_info",
        showInSidebar: false,
        renderInRoutes: false,
        component: roomModerations,
        path: "/rooms/moderation",
        roles: [1, 2], //Permissions and Roles ?
        permissions: ["VIEW_BROWSE_ROOM", "EDIT_BROWSE_ROOM"],
      },
      {
        title: "Gifts",
        key: "manage_gifts",
        showInSidebar: true,
        component: manageGifts,
        path: "/gifts/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["EDIT_GIFTS", "VIEW_GIFTS"],
      },
      {
        title: "Gift Collections",
        key: "manage_gift_collections",
        showInSidebar: true,
        component: manageGiftCollections,
        path: "/giftCollections/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["EDIT_GIFTS", "VIEW_GIFTS"],
      },
      {
        title: "Package",
        key: "manage_package",
        showInSidebar: true,
        component: managePackage,
        path: "/package/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_PACKAGE", "EDIT_PACKAGE"],
      },
      {
        title: "Package Collections",
        key: "manage_package_collections",
        showInSidebar: true,
        component: managePackageCollections,
        path: "/packageCollections/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_PACKAGE", "EDIT_PACKAGE"],
      },
      {
        title: "Gift Collection Entities",
        key: "manage_gift_collection_entities",
        showInSidebar: false,
        component: manageGiftCollectionEntities,
        path: "/giftCollection/entities/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["EDIT_GIFTS", "VIEW_GIFTS"],
      },
      {
        title: "Package Collection Entities",
        key: "manage_package_collection_entities",
        showInSidebar: false,
        component: managePackageCollectionEntities,
        path: "/packageCollection/entities/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_PACKAGE", "EDIT_PACKAGE"],
      },
      {
        title: "Gift Collection Entities Re-Order",
        key: "manage_gift_collection_entities_reorder",
        showInSidebar: false,
        component: manageGiftCollectionEntitiesReorder,
        path: "/giftCollection/entities/manage/reorder",
        roles: [1, 2, 3, 6, 7],
        permissions: ["EDIT_GIFTS", "VIEW_GIFTS"],
      },
      {
        title: "PWA Page Config",
        key: "campaign_spin_config",
        showInSidebar: true,
        renderInRoutes: true,
        component: CampaignSpinConfig,
        path: "/campaign/spin-config",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MANAGE_CAMPAIGN", "EDIT_MANAGE_CAMPAIGN"],
      },
      {
        title: "Spin Static Config",
        key: "spin_static_config",
        showInSidebar: true,
        renderInRoutes: true,
        component: SpinStaticConfig,
        path: "/spin/static-config",
        roles: [1, 2, 3, 6, 7],
        permissions: ["SPIN"],
      },
      {
        title: "Spin Tab Config",
        key: "spin_tab_config",
        showInSidebar: true,
        renderInRoutes: true,
        component: SpinTabConfig,
        path: "/spin/tab-config",
        roles: [1, 2, 3, 6, 7],
        permissions: ["SPIN"],
      },
      {
        title: "Spin Stats",
        key: "spin_stats",
        showInSidebar: true,
        renderInRoutes: true,
        component: SpinStats,
        path: "/spin/stats",
        roles: [1, 2, 3, 6, 7],
        permissions: ["SPIN"],
      },
      // {
      //   title: "Spin Transaction Stats",
      //   key: "spin_transaction",
      //   showInSidebar: true,
      //   renderInRoutes: true,
      //   component: SpinUserStats,
      //   path: "/spin/transaction",
      //   roles: [1, 2, 3, 6, 7],
      //   permissions: ["SPIN"],
      // },
      {
        title: "Spin Gift Stats",
        key: "spin_transaction",
        showInSidebar: true,
        renderInRoutes: true,
        component: SpinGiftStats,
        path: "/spin/gift",
        roles: [1, 2, 3, 6, 7],
        permissions: ["SPIN"],
      },
      {
        title: "Top 100 Spin",
        key: "top_100_spin",
        showInSidebar: true,
        renderInRoutes: true,
        component: SpinTop100,
        path: "/spin/top100",
        roles: [1, 2, 3, 6, 7],
        permissions: ["SPIN"],
      },
    ],
  },
  {
    title: "Listings",
    key: "listings",
    icon: "fas fa-list",
    childRoutes: [
      {
        title: "Browse Listings",
        key: "browse_listings",
        showInSidebar: true,
        renderInRoutes: true,
        component: BrowseListings,
        path: "/listings/browse",
        roles: [1, 2],
        permissions: ["VIEW_BROWSE_LISTINGS", "EDIT_BROWSE_LISTINGS"],
      },
    ],
  },
  {
    title: "Meme",
    key: "meme",
    icon: "fas fa-clipboard",
    childRoutes: [
      {
        title: "Browse Meme",
        key: "browse_meme",
        showInSidebar: true,
        renderInRoutes: true,
        component: BrowseMeme,
        path: "/meme/browse",
        roles: [1, 2],
        permissions: ["VIEW_BROWSE_MEME", "EDIT_BROWSE_MEME"],
      },
    ],
  },
  {
    title: "Hashtag",
    key: "hashtag",
    icon: "fab fa-slack-hash",
    childRoutes: [
      {
        title: "Browse Hashtag",
        key: "browse_hashtag",
        showInSidebar: true,
        renderInRoutes: true,
        component: BrowseHashtag,
        path: "/hashtag/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_BROWSE_HASHTAG", "EDIT_BROWSE_HASHTAG"],
      },
    ],
  },

  {
    title: "Josh Users",
    key: "josh_users",
    icon: "fas fa-user",
    childRoutes: [
      {
        title: "Browse User",
        key: "browse_user",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseUser,
        path: "/user/browse",
        roles: [1, 2, 3, 6, 7, 11, 12, 13, 14, 15, 21, 22, 31, 32, 33],
        permissions: ["VIEW_BROWSE_USER", "EDIT_BROWSE_USER"],
      },
      {
        title: "Fuzzy Search User",
        key: "fuzzy_search_user",
        showInSidebar: true,
        renderInRoutes: true,
        component: fuzzySearchUser,
        path: "/user/fuzzySearch",
        permissions: [
          "VIEW_BROWSE_USER",
          "EDIT_BROWSE_USER",
          "VIEW_USER_DETAILS",
          "EDIT_USER_DETAILS",
        ],
        roles: [1, 2, 3, 6, 7],
      },
      {
        title: "User Details",
        key: "user_details",
        showInSidebar: true,
        renderInRoutes: true,
        component: appUserDetails,
        path: "/appuser/details",
        roles: [1, 2, 3, 6, 7, 11, 12, 13, 21, 22, 31, 32, 33],
        permissions: ["VIEW_USER_DETAILS", "EDIT_USER_DETAILS"],
      },
      {
        title: "User Details",
        key: "user_details_url",
        showInSidebar: false,
        component: appUserDetails,
        path: "/user_info/:userUuid",
        roles: [1, 2, 3, 6, 7, 11, 12, 13, 21, 22, 31, 32, 33],
      },
      {
        title: "Website Verification",
        key: "website_verification",
        showInSidebar: true,
        renderInRoutes: true,
        component: WebsiteVerification,
        path: "/website/verification",
        roles: [1, 2, 3, 6, 7, 11, 12, 13, 14, 15, 21, 22, 31, 32, 33],
        permissions: ["VIEW_BROWSE_USER", "EDIT_BROWSE_USER"],
      },

      {
        title: "Update Delta History",
        key: "update_delta",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageDelta,
        path: "/user/delta",
        permissions: ["VIEW_UPDATE_DELTA_HISTORY", "EDIT_UPDATE_DELTA_HISTORY"],
        roles: [1, 2],
      },
      {
        title: "Boost Follow History",
        key: "boost_follow",
        showInSidebar: true,
        renderInRoutes: true,
        component: boostFollow,
        path: "/user/boost",
        permissions: ["VIEW_BOOST_FOLLOW_HISTORY", "EDIT_BOOST_FOLLOW_HISTORY"],
        roles: [1, 2],
      },
      {
        title: "Boost Follow Meta/Status",
        key: "boost_follow_status",
        showInSidebar: true,
        renderInRoutes: true,
        component: boostFollowStatus,
        path: "/appuser/boost/status",
        permissions: ["VIEW_BOOST_FOLLOW_META", "EDIT_BOOST_FOLLOW_META"],
        roles: [1, 2],
      },
      {
        title: "Badges",
        key: "manage_badges",
        showInSidebar: true,
        component: manageBadges,
        path: "/badges/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["EDIT_BADGES", "VIEW_BADGES"],
      },
    ],
  },
  {
    title: "Sponsored Tags",
    key: "sponsored_tags",
    icon: "fas fa-tags",
    childRoutes: [
      {
        title: "Browse Brands",
        key: "browse_brands",
        showInSidebar: true,
        renderInRoutes: true,
        component: BrowseBrands,
        path: "/brands/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_BROWSE_BRAND", "EDIT_BROWSE_BRAND"],
      },
    ],
  },
  {
    title: "Events",
    key: "events",
    icon: "fas fa-calendar-alt",
    childRoutes: [
      {
        title: "Manage Events",
        key: "manage_events",
        showInSidebar: false,
        renderInRoutes: false,
        component: ManageEvents,
        path: "/events/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MANAGE_EVENT", "EDIT_MANAGE_EVENT"],
      },
    ],
  },
  {
    title: "Challenge",
    key: "challenge",
    icon: "fas fa-trophy",
    childRoutes: [
      {
        title: "Browse Challenge",
        key: "browse_challenge",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseChallenge,
        path: "/challenge/browse",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_BROWSE_CHALLENGE", "EDIT_BROWSE_CHALLENGE"],
      },
      {
        title: "Manage Challenge",
        key: "manage_challenge",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageChallenge,
        path: "/challenge/manage",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_MANAGE_CHALLENGE", "EDIT_MANAGE_CHALLENGE"],
      },
      {
        title: "Browse Challenge Contents",
        key: "browse_challenge_contents",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseChallengeContents,
        permissions: [
          "VIEW_BROWSE_CHALLENGE_CONTENTS",
          "EDIT_BROWSE_CHALLENGE_CONTENTS",
        ],
        path: "/challenge/browse/contents",
        roles: [1, 2, 3, 6],
      },
    ],
  },
  {
    title: "Contest",
    key: "contest",
    icon: "fas fa-trophy",
    childRoutes: [
      {
        title: "Browse Contest",
        key: "browse_contest",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseContest,
        path: "/contest/browse",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_BROWSE_CONTEST", "EDIT_BROWSE_CONTEST"],
      },
      {
        title: "Manage Contest",
        key: "manage_contest",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageContest,
        path: "/contest/manage",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_MANAGE_CONTEST", "EDIT_MANAGE_CONTEST"],
      },
    ],
  },
  {
    title: "Audio",
    key: "audio",
    icon: "fas fa-file-audio",
    childRoutes: [
      {
        title: "Manage Audio",
        key: "manage_audio",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageAudio,
        path: "/audio/manage",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_MANAGE_AUDIO", "EDIT_MANAGE_AUDIO"],
      },
    ],
  },
  {
    title: "Ads",
    key: "ads",
    icon: "fas fa-tv",
    childRoutes: [
      {
        title: "Manage Ads Config",
        key: "manage_ads_config",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageAdsConfig,
        path: "/ads/config/manage",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_ADS_CONFIG", "EDIT_ADS_CONFIG"],
      },
      {
        title: "Manage Ads Profile",
        key: "manage_ads_profile",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageAdsProfile,
        path: "/ads/profile/manage",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_ADS_PROFILE", "EDIT_ADS_PROFILE"],
      },
    ],
  },
  {
    title: "Camera Assets",
    icon: "fas fa-camera",
    key: "camera_assets",
    childRoutes: [
      {
        title: "Manage Camera Assets",
        key: "manage_camera_assets",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageCameraAsset,
        path: "/cameraAsset/manage",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_MANAGE_CAMERA_ASSETS", "EDIT_MANAGE_CAMERA_ASSETS"],
      },
      {
        title: "Manage Category Assets",
        key: "manage_category_assets",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageCategoryAsset,
        path: "/categoryAssets/manage",
        roles: [1, 2, 3, 6],
        permissions: [
          "VIEW_MANAGE_CATEGORY_ASSETS",
          "EDIT_MANAGE_CATEGORY_ASSETS",
        ],
      },
      {
        title: "Manage Camera Reorder",
        key: "manage_camera_reorder",
        showInSidebar: true,
        renderInRoutes: true,
        component: cameraReorder,
        path: "/camera/reorder",
        roles: [1, 2, 3, 6],
        permissions: ["CAMERA_REORDER"],
      },
      {
        title: "Manage Video Assets",
        key: "manage_video_assets",
        showInSidebar: false,
        component: manageVideoAssets,
        path: "/videoAssets/manage",
        roles: [1, 2, 3, 6],
        permissions: [
          "VIEW_MANAGE_CATEGORY_ASSETS",
          "EDIT_MANAGE_CATEGORY_ASSETS",
        ],
      },
      {
        title: "Templates",
        key: "manage_templates",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageTemplate,
        path: "/templates/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_TEMPLATES", "EDIT_TEMPLATES"],
      },
      {
        title: "Template Section",
        key: "manage_template_section",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageTemplateSection,
        path: "/template-section/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_TEMPLATES", "EDIT_TEMPLATES"],
      },
    ],
  },
  {
    title: "Stickers",
    icon: "fab fa-font-awesome-flag",
    key: "stickers",
    childRoutes: [
      {
        title: "Manage Sticker Assets",
        key: "manage_sticker_assets",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageStickersTab,
        path: "/stickers/tabs",
        roles: [1, 2, 3, 6],
        permissions: [
          "VIEW_MANAGE_STICKER_ASSETS",
          "EDIT_MANAGE_STICKER_ASSETS",
        ],
      },
      {
        title: "Manage Sticker Subtype",
        key: "manage_sticker_subtype",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageStickersSubType,
        path: "/stickers/subtype",
        roles: [1, 2, 3, 6],
        permissions: [
          "VIEW_MANAGE_STICKER_SUBTYPE",
          "EDIT_MANAGE_STICKER_SUBTYPE",
        ],
      },
      {
        title: "Manage Stickers",
        key: "manage_stickers",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageStickers,
        path: "/stickers/manage",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_MANAGE_STICKERS", "EDIT_MANAGE_STICKERS"],
      },
      {
        title: "Manage Sticker Mapping",
        key: "manage_sticker_mapping",
        showInSidebar: false,
        component: manageStickersMapping,
        path: "/stickers/mapping",
        roles: [1, 2, 3, 6],
      },
    ],
  },
  {
    title: "Entities",
    key: "entities",
    icon: "fas fa-book",
    childRoutes: [
      {
        title: "Banners",
        key: "manage_banners",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageBanners,
        path: "/banners/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["EDIT_BANNERS", "VIEW_BANNERS"],
      },
      {
        title: "Chips",
        key: "manage_chips",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageChips,
        path: "/chips/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["EDIT_CHIPS", "VIEW_CHIPS"],
      },
      {
        title: "Music Artist",
        key: "manage_music_artist",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageMusicArtist,
        path: "/musicArtist/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MUSIC_ARTIST", "EDIT_MUSIC_ARTIST"],
      },
      {
        title: "Music Label",
        key: "manage_music_label",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageMusicLabel,
        path: "/musicLable/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MUSIC_LABEL", "EDIT_MUSIC_LABEL"],
      },
      {
        title: "Music Playlist",
        key: "manage_music_playlist",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageMusicPlaylist,
        path: "/musicPlaylist/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MUSIC_PLAYLIST", "EDIT_MUSIC_PLAYLIST"],
      },
      {
        title: "Named Collection",
        key: "manage_named_collection",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageNamedCollection,
        path: "/namedCollection/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_NAMED_COLLECTION", "EDIT_NAMED_COLLECTION"],
      },
      {
        title: "Web Games",
        key: "web_games",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageWebGames,
        path: "/webGames/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_WEB_GAMES", "EDIT_WEB_GAMES"],
      },
      {
        title: "Web View Banners",
        key: "manage_web_view_banners",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageWebviewBanners,
        path: "/webViewBanners/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_WEB_VIEW_BANNERS", "EDIT_WEB_VIEW_BANNERS"],
      },
      {
        title: "Manage Icons",
        key: "manage_icons",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageIcons,
        path: "/icons/manage",
        roles: [1, 2, 3, 6],
        permissions: ["VIEW_ICONS", "EDIT_ICONS"],
      },
    ],
  },
  {
    title: "Creator Issues",
    key: "creator",
    icon: "fab fa-jira",
    childRoutes: [
      {
        title: "Browse Issues",
        key: "browse_issues",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseIssues,
        path: "/browse/issues",
        permissions: ["VIEW_BROWSE_ISSUE", "EDIT_BROWSE_ISSUE"],
        roles: [1, 2, 3, 6, 11, 12, 13, 21, 22, 31, 32, 33, 50],
      },
      {
        title: "Issue Details",
        key: "issue_details",
        showInSidebar: false,
        component: issueDetailPage,
        path: "/issue/details",
        permissions: ["EDIT_BROWSE_ISSUE"],
        roles: [1, 2, 3, 6, 11, 12, 13, 21, 22, 31, 32, 33, 50],
      },
    ],
  },
  {
    title: "Discovery",
    key: "discovery",
    icon: "fas fa-search",
    childRoutes: [
      {
        title: "Discovery Pages",
        key: "discovery_pages",
        showInSidebar: true,
        renderInRoutes: true,
        component: managePages,
        path: "/manage/pages",
        roles: [1, 2, 3, 6, 13],
        permissions: ["VIEW_DISCOVERY_PAGE", "EDIT_DISCOVERY_PAGE"],
      },
      {
        title: "Manage Entities",
        key: "manage_entities",
        showInSidebar: true,
        component: manageEntityListPage,
        path: `/manage/entity`,
        roles: [1, 2, 3, 6, 13],
      },
      {
        title: "Manage Collection Entities",
        key: "manage_collection_entities",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageCollectionEntities,
        path: "/manage/collectionEntities",
        roles: [1, 2, 3, 6, 13],
        permissions: [
          "VIEW_MANAGE_COLLECTION_ENTITIES",
          "EDIT_MANAGE_COLLECTION_ENTITIES",
        ],
      },
      {
        title: "Manage Page Entities",
        key: "manage_page_entities",
        showInSidebar: false,
        component: managePageEntities,
        path: "/manage/pageEntities",
        roles: [1, 2, 3, 6, 13],
      },
      {
        title: "Reorder Page Entities",
        key: "reorder_page_entities",
        showInSidebar: false,
        component: reorderPageEntities,
        path: "/reorder/pageEntities",
        roles: [1, 2, 3, 6, 13],
      },
      {
        title: "Default Ordering",
        key: "default_ordering",
        showInSidebar: false,
        component: reorderDefaultPageEntities,
        path: "/reorder/defaultPageEntities",
        roles: [1, 2, 3, 6, 13],
      },
      {
        title: "Collection Entities Default Orderin",
        key: "collection_entities_default_ordering",
        showInSidebar: false,
        component: ReorderDefaultCollectionEntities,
        path: "/reorder/defaultCollectionEntities",
        roles: [1, 2, 3, 6, 13],
      },
      {
        title: "Manage Collection Entities List",
        key: "manage_collection_entities_list",
        showInSidebar: false,
        component: collectionEntitiesList,
        path: "/manage/collection/entities/list",
        roles: [1, 2, 3, 6, 13],
      },
      {
        title: "Reorder Collection Entities",
        key: "reorder_collection_entities",
        showInSidebar: false,
        component: reorderCollectionEntities,
        path: "/reorder/collectionEntities",
        roles: [1, 2, 3, 6, 13],
      },
    ],
  },
  {
    title: "CMS Users",
    key: "cms_users",
    icon: "fas fa-user-circle",
    childRoutes: [
      {
        title: "Manage CMS Users",
        key: "manage_cms_users",
        path: "/users/manage",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageCMSUser,
        permissions: ["VIEW_MANAGE_CMS_USER", "EDIT_MANAGE_CMS_USER"],
        roles: [1, 2, 6],
      },
      {
        title: "Verify User",
        key: "verify_user",
        showInSidebar: false,
        component: VerifyUser,
        path: "/user/verify",
        roles: [
          1, 2, 3, 4, 6, 7, 11, 12, 13, 14, 15, 24, 15, 21, 22, 31, 32, 33, 50,
          1000,
        ],
      },
      {
        title: "Manage Source",
        key: "manage_source",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageSource,
        path: "/source/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MANAGE_SOURCE", "EDIT_MANAGE_SOURCE"],
      },
      {
        title: "Audit User",
        key: "audit_user",
        showInSidebar: true,
        renderInRoutes: true,
        component: CmsUserAudit,
        path: "/audit/cms-user",
        roles: [1, 2, 3, 6, 7],
        permissions: ["AUDIT_CMS_USER"],
      },
    ],
  },
  {
    title: "Activity",
    icon: "fab fa-buffer",
    key: "activity",
    childRoutes: [
      {
        title: "View Activity",
        key: "view_activity",
        showInSidebar: true,
        renderInRoutes: true,
        component: viewActivity,
        path: "/activity",
        permissions: ["VIEW_VIEW_ACTIVITY", "EDIT_VIEW_ACTIVITY"],
        roles: [1, 2, 3, 6, 7],
      },
      {
        title: "Bulk Upload Report",
        key: "bulk_upload_report",
        showInSidebar: true,
        renderInRoutes: true,
        component: BulkUploadReport,
        path: "/activity/bulkuploadreport",
        permissions: ["VIEW_BULK_UPLOAD_REPORT", "EDIT_BULK_UPLOAD_REPORT"],
        roles: [1, 2, 3, 33],
      },
      {
        title: "Boost Report",
        key: "activity_booster",
        showInSidebar: true,
        renderInRoutes: true,
        component: activityBooster,
        permissions: ["VIEW_BOOST_REPORT", "EDIT_BOOST_REPORT"],
        path: "/activity/boost",
        roles: [1, 2, 3],
      },
    ],
  },
  {
    title: "User",
    icon: "fa fa-user",
    key: "user",
    childRoutes: [
      {
        title: "Change Password",
        key: "change_password",
        showInSidebar: false,
        component: ChangePassword,
        path: "/user/change-password",
        roles: [1, 2, 3, 4, 6, 7, 11, 12, 13, 14, 15, 21, 22, 31, 32, 33, 50],
      },
      {
        title: "Log Out",
        key: "log_out",
        showInSidebar: false,
        component: Logout,
        path: "/api/auth/logout",
        roles: [
          1, 2, 3, 4, 6, 7, 11, 12, 13, 14, 15, 21, 22, 31, 32, 33, 50, 1000,
        ],
      },
    ],
  },
  {
    title: "Zones",
    icon: "bx bx-area",
    key: "zone",
    childRoutes: [
      {
        title: "Browse Zone",
        key: "browse_zone",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseZone,
        path: "/zone/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_BROWSE_ZONE", "EDIT_BROWSE_ZONE"],
      },
      {
        title: "Manage Zone",
        key: "manage_zone",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageZone,
        path: "/zone/manage",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_MANAGE_ZONE", "EDIT_MANAGE_ZONE"],
      },
      {
        title: "Zone Review",
        key: "zone_banner_review",
        showInSidebar: true,
        renderInRoutes: true,
        component: zoneBannerReview,
        path: "/zone/review",
        roles: [1, 2, 3, 6, 7, 11, 12, 13, 14, 15, 21, 22, 31, 32, 33],
        permissions: ["ZONE_REVIEW"],
      },
      {
        title: "Detail Zone",
        key: "detail_zone",
        showInSidebar: false,
        renderInRoutes: true,
        component: detailZone,
        path: "/zone/detail",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_BROWSE_ZONE", "EDIT_BROWSE_ZONE"],
      },
    ],
  },
  {
    title: "Chat Bot",
    icon: "bx bxs-bot",
    key: "chat_bot",
    childRoutes: [
      {
        title: "Whats App",
        key: "whats_app_chat_bot",
        showInSidebar: true,
        renderInRoutes: true,
        component: ChatBot,
        path: "/chat-bot/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["CHAT_BOT"],
      },
    ],
  },
  {
    title: "CTA",
    icon: "bx bx-area",
    key: "cta",
    childRoutes: [
      {
        title: "Browse CTA",
        key: "browse_cta",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseCTA,
        path: "/cta/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_BROWSE_CTA", "EDIT_BROWSE_CTA"],
      },
    ],
  },
  {
    title: "Creator Payout",
    icon: "bx bx-wallet",
    key: "creator_payout",
    childRoutes: [
      {
        title: "Josh Payment Audit",
        key: "josh_payment_audit",
        showInSidebar: true,
        renderInRoutes: true,
        component: JoshPaymentAudit,
        path: "/josh-payment-audit",
        roles: [1, 2],
        permissions: ["VIEW_JOSH_PAYMENT_AUDIT"],
      },
      {
        title: "Transactions Dashboard",
        key: "manage_transactions",
        showInSidebar: true,
        renderInRoutes: true,
        component: ManageDashTransactions,
        path: "/dashboards/transaction",
        roles: [1, 2],
        permissions: ["VIEW_DASHBOARDS_TRANSACTION"],
      },
      {
        title: "Manage FAQ",
        key: "maange_faq",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageFAQ,
        path: "/creator-payout/manage/faq",
        roles: [1, 2, 3, 6, 7],
        permissions: ["EDIT_MANAGE_FAQS", "VIEW_MANAGE_FAQS"],
      },
    ],
  },
  {
    title: "KYC",
    icon: "bx bx-area",
    key: "cta",
    childRoutes: [
      {
        title: "Pan KYC",
        key: "browse_kyc",
        showInSidebar: true,
        renderInRoutes: true,
        component: browseKYC,
        path: "/kyc/browse",
        roles: [1, 2, 3, 6, 7],
        permissions: ["VIEW_BROWSE_KYC", "EDIT_BROWSE_KYC"],
      },
    ],
  },
  {
    title: "Exclusion Entities",
    icon: "bx bx-area",
    key: "exclusion_entities",
    childRoutes: [
      {
        title: "Browse Entities",
        key: "browse_entities",
        showInSidebar: true,
        renderInRoutes: true,
        component: exclusionEntities,
        path: "/exclusion/entities",
        roles: [1, 2, 3, 6, 7],
        permissions: ["BROWSE_EXCLUSION_ENTITY"],
      },
    ],
  },
  {
    title: "Crawler",
    icon: "bx bx-globe",
    key: "crawler",
    childRoutes: [
      {
        title: "Instagram",
        key: "instagram",
        showInSidebar: true,
        renderInRoutes: true,
        component: instagramCrawler,
        path: "/instagram/",
        roles: [1, 2],
        permissions: ["WEB_CRAWLING"],
      },
      {
        title: "Youtube",
        key: "youtube",
        showInSidebar: true,
        renderInRoutes: true,
        component: youtubeCrawler,
        path: "/youtube/",
        roles: [1, 2],
        permissions: ["WEB_CRAWLING_YOUTUBE"],
      },
    ],
  },
  {
    title: "Feed Ops",
    key: "cms_feed_ops",
    icon: "fas fa-file-video",
    childRoutes: [
      {
        title: "Manage Interests",
        key: "manage_interests",
        showInSidebar: true,
        renderInRoutes: true,
        component: ManageInterests,
        path: "/feed/interests",
        roles: [1, 2, 3],
        permissions: ["MANAGE_INTEREST", "EDIT_INTEREST"],
      },
      {
        title: "Manage Cold Start",
        key: "manage_cold_start",
        showInSidebar: true,
        renderInRoutes: true,
        component: ManageColdStart,
        path: "/feed/coldstart",
        roles: [1, 2, 3],
        permissions: ["MANAGE_INTEREST", "EDIT_INTEREST"],
      },
      {
        title: "Strategy",
        key: "manage_strategy",
        showInSidebar: true,
        renderInRoutes: true,
        component: ManageStrategy,
        path: "/feed/strategy",
        roles: [1, 2, 3],
        permissions: ["MANAGE_STRATEGY", "EDIT_STRATEGY"],
      },
      {
        title: "Manage Interests",
        key: "upsert_interests",
        showInSidebar: false,
        renderInRoutes: true,
        component: UpsertInterests,
        path: "/feed/interests/manage",
        roles: [1, 2, 3],
        permissions: ["MANAGE_INTEREST", "EDIT_INTEREST"],
      },
      {
        title: "Curation",
        key: "curation",
        showInSidebar: true,
        renderInRoutes: true,
        component: CurationList,
        path: "/feed/curation",
        roles: [1, 2, 3],
        permissions: ["MANAGE_CURATION"],
      },
    ],
  },
  {
    title: "Audio Stories",
    key: "cms_audio_stories",
    icon: "fas fa-file-video",
    childRoutes: [
      {
        title: "Manage Stories",
        key: "manage_stories",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageStories,
        path: "/audioStories/list",
        roles: [1, 2, 3],
        permissions: ["MANAGE_AUDIO_STORY", "EDIT_AUDIO_STORY"],
      },
      {
        title: "Manage Series",
        key: "manage_series",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageSeries,
        path: "/audioSeries/list",
        roles: [1, 2, 3],
        permissions: ["MANAGE_AUDIO_SERIES", "EDIT_AUDIO_SERIES"],
      },
      {
        title: "Manage Collection",
        key: "manage_collection",
        showInSidebar: true,
        renderInRoutes: true,
        component: manageAudioStoryCollection,
        path: "/audioCollection/list",
        roles: [1, 2, 3],
        permissions: [
          "MANAGE_AUDIO_STORY_COLLECTION",
          "EDIT_AUDIO_STORY_COLLECTION",
        ],
      },
    ],
  },
  // {
  //   title: "Instant Messaging",
  //   icon: "bx bx-conversation",
  //   key: "instant_messaging",
  //   childRoutes: [
  //     {
  //       title: "Instant Messaging",
  //       key: "instant_messaging",
  //       showInSidebar: false,
  //       renderInRoutes: false,
  //       component: tinode,
  //       path: "/instant-messaging/",
  //       roles: [1, 2],
  //       permissions: ["INSTANT_MESSAGING"],
  //     },
  //   ],
  // },
]

const publicRoutes = [
  { path: "/logout", key: "logout", title: "Logout", component: Logout },
  {
    path: "/forgot-password",
    key: "forgot_password",
    title: "Forgot Password",
    component: ForgetPasswordPage,
  },
  { path: "/login", key: "login", title: "Login", component: Login },
]

export { publicRoutes, authProtectedRoutes, authProtectedRoutesModeration }
