import React from "react"
import { connect } from "react-redux"
import Questionnaire from "./questionnaire"

class PeopleInVideo extends Questionnaire {
  state = {
    currentTab: "peopleInVideo",
    labelToDisplay: "People In Video",
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderStepper()}
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    currentData: _.get(store, "taxonomy.peopleInVideo"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(PeopleInVideo)
