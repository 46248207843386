import React, { useState } from "react"
import _ from "lodash"
import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap"
import { Button } from "@mui/material"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SimpleBar from "simplebar-react"

const UpsertSource = props => {
  const [type, setType] = useState(props.source ? props.source.type : undefined)
  const _onSubmit = formData => {
    formData.status = formData["sourceStatus"]
    formData.query = JSON.stringify(JSON.parse(formData.query))
    formData.metaInfo = JSON.parse(formData.metaInfo)
    formData.maxItemsToFetch = +formData.maxItemsToFetch
    formData.threshold = +formData.threshold
    props.onSubmit(formData, props.action)
  }
  return (
    <React.Fragment>
      <SimpleBar style={{ height: "70vh" }}>
        <Card className>
          <CardBody>
            <h4 className="card-title">
              {props.action === "create" ? "Create" : "Update"} Source
            </h4>
            <AvForm onValidSubmit={(e, values) => _onSubmit(values)}>
              <Row>
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Display Name</Label>

                    <AvField
                      name="displayName"
                      placeholder="Enter display name"
                      type="text"
                      errorMessage="Please enter display name"
                      validate={{
                        required: { value: true },
                      }}
                      value={props.source && props.source.display_name}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Type</Label>

                    <AvField
                      name="type"
                      type="select"
                      onChange={e => setType(e.target.value)}
                      value={
                        props.action === "create"
                          ? "MODERATION"
                          : props.source && props.source.type
                          ? props.source.type
                          : "MODERATION"
                      }
                    >
                      {" "}
                      <option value="LABELLING">Labelling</option>
                      <option value="MODERATION">Moderation</option>
                      <option value="TAXONOMY_STAGE_1">Taxonomy Stage 1</option>
                      <option value="TAXONOMY_STAGE_2">Taxonomy Stage 2</option>
                      <option value="TAXONOMY_STAGE_3">Taxonomy stage 3</option>
                      <option value="TAXONOMY">Taxonomy</option>
                      <option value="TAXONOMY_QC">Taxonomy QC</option>
                      <option value="TAXONOMY_INTERNAL_QC">
                        Taxonomy Internal QC
                      </option>
                      <option value="REVIEW_WEBSITE_LINK">
                        REVIEW WEBSITE LINK
                      </option>
                      <option value="ZONE_REVIEW">ZONE REVIEW</option>
                      <option value="ZONE_APPROVAL">Zone Approval</option>
                      <option value="TRENDING">Trending</option>
                      <option value="REVIEW_INTERESTS">Review Interests</option>
                      <option value="SEED_INTERESTS">Seed Interests</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Status</Label>

                    <AvField
                      name="sourceStatus"
                      type="select"
                      value={
                        props.action === "create"
                          ? "ACTIVE"
                          : props.source && props.source.status
                          ? props.source.status
                          : "ACTIVE"
                      }
                    >
                      {" "}
                      <option value="ACTIVE">Active</option>
                      <option value="IN_ACTIVE">In Active</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Data Source</Label>

                    <AvField
                      name="dataSource"
                      type="select"
                      value={
                        props.action === "create"
                          ? "ELASTIC_SEARCH"
                          : props.source && props.source.data_source
                          ? props.source.data_source
                          : "ELASTIC_SEARCH"
                      }
                    >
                      <option value="ELASTIC_SEARCH">Elastic Search</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Query</Label>

                    <AvField
                      name="query"
                      type="textarea"
                      errorMessage="Please enter query"
                      validate={{
                        required: { value: true },
                      }}
                      rows="5"
                      value={
                        props.source &&
                        JSON.stringify(JSON.parse(props.source.query))
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Meta Information</Label>

                    <AvField
                      name="metaInfo"
                      type="textarea"
                      errorMessage="Please enter meta information"
                      validate={{
                        required: { value: true },
                      }}
                      rows="5"
                      value={
                        props.source && JSON.stringify(props.source.meta_info)
                      }
                    />
                  </FormGroup>
                </Col>
                {(type === "TAXONOMY_QC" ||
                  type === "TAXONOMY" ||
                  type === "TAXONOMY_STAGE_1" ||
                  type === "TAXONOMY_STAGE_2" ||
                  type === "TAXONOMY_STAGE_3" ||
                  type === "ZONE_APPROVAL") && (
                  <Col lg="6">
                    <FormGroup row>
                      <Label className="col-form-label">Sub Type</Label>

                      <AvField
                        name="subType"
                        placeholder="Enter sub type"
                        type="text"
                        errorMessage="Please enter sub type"
                        value={props.source && props.source.subType}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">
                      Maximum Items To Fetch
                    </Label>

                    <AvField
                      name="maxItemsToFetch"
                      type="number"
                      errorMessage="Please enter the value"
                      validate={{
                        required: { value: true },
                      }}
                      value={props.source && +props.source.max_items_to_fetch}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label">Threshold</Label>

                    <AvField
                      name="threshold"
                      type="number"
                      errorMessage="Please enter the value"
                      validate={{
                        required: { value: true },
                      }}
                      value={props.source && props.source.threshold}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="mx-2"
                  variant="contained"
                >
                  {props.action === "create" ? "Create" : "Update"}
                </Button>
                <Button
                  color="error"
                  onClick={props.onCancel}
                  variant="contained"
                >
                  Cancel
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </SimpleBar>
    </React.Fragment>
  )
}

export default UpsertSource
