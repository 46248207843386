import React from "react"
import { connect } from "react-redux"
import { Select, Radio, Pagination, Tooltip, Popconfirm } from "antd"
import {
  Card,
  CardBody,
  Col,
  Button,
  Badge,
  Row,
  CardTitle,
  CardImg,
} from "reactstrap"
import Chips from "../../components/CommonFilter/chips"
import UploadBulkStickersForm from "./uploadBulkStickersForm"
import {
  getStickers,
  updateStickerTabOrder,
  deleteStickerById,
  upsertSticker,
  getMimeTypes,
  resetState,
} from "../../appRedux/slices/stickers"
import UpsertStickersForm from "./upsertStickersForm"
import QueryString from "query-string"
import { markTrending } from "appRedux/slices/trending"
import { getTrendingData } from "../../clientServices/trendingService"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import "./stickers.style.css"
const { Option } = Select
import { withRouter } from "react-router-dom"
import UpsertTrendingPayload from "../CommonTrending/trendingUpsert"
import toastr from "toastr"
import {
  stickersForSearchAndSelectFilters,
  NO_PERMISSION_MESSAGE,
} from "../../constants/uiConstants"

class ManageStickersTab extends ErrorBoundComponent {
  state = {
    showTrendingForm: false,
    showFilters: false,
    stickerId: "",
    filters: {
      pageSize: 25,
      currentPage: this.props.queryParams
        ? this.props.queryParams["currentPage"]
        : 1,
      mimeType: this.props.queryParams
        ? this.props.queryParams["mimeType"]
        : "animatedsticker",
      isActive: this.props.queryParams
        ? this.props.queryParams["isActive"]
        : true,
      name: this.props.queryParams ? this.props.queryParams["name"] : "",
      selectedFilter: this.props.queryParams
        ? this.props.queryParams["selectedFilter"]
        : "CAMERA",
    },
    showUpsertModal: false,
    stickerAsset: {},
    selectedFilter: "CAMERA",
  }

  componentDidMount() {
    const { filters } = this.state
    if (!this.props.common.error) {
      let payload = {
        mimeType: filters["mimeType"],
        isActive: filters["isActive"],
        pageNumber: filters["currentPage"],
        pageSize: filters["pageSize"],
        name: filters["name"],
        selectedFilter: filters["selectedFilter"],
      }
      this.props.dispatch(getStickers(payload))
      this.props.dispatch(
        getMimeTypes({ selectedFilter: filters["selectedFilter"] })
      )
    }
    window.addEventListener("keydown", this._keyDownHandler)
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.state
    if (
      (this.props.refetchData &&
        !this.props.common.error &&
        prevProps.refetchData !== this.props.refetchData) ||
      (this.props.queryParams &&
        JSON.stringify(prevProps.queryParams) !==
          JSON.stringify(this.props.queryParams))
    ) {
      let payload = {
        mimeType: this.props.queryParams
          ? this.props.queryParams["mimeType"]
          : "animatedsticker",
        isActive: this.props.queryParams
          ? this.props.queryParams["isActive"]
          : 1,
        pageNumber: this.props.queryParams
          ? this.props.queryParams["currentPage"]
          : 1,
        pageSize: filters["pageSize"],
        name: this.props.queryParams ? this.props.queryParams["name"] : "",
        selectedFilter: this.state.selectedFilter,
      }
      this.props.dispatch(getStickers(payload))
      this._closeUpsertModal()
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._searchStickers()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _closeUpsertModal = () => {
    this.setState({
      showUpsertModal: false,
      showBulkModal: false,
      formAction: "create",
      stickerAsset: {},
    })
  }

  _onCreateStickerAsset = () => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKERS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.setState({
      showUpsertModal: true,
      showBulkModal: false,
      formAction: "create",
      stickerAsset: {},
    })
  }

  _onBulkUploadStickers = () => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKERS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.setState({
      showUpsertModal: true,
      formAction: "upload",
    })
  }

  _onTrendingClick = async (e, stickerData) => {
    e.preventDefault()
    e.stopPropagation()
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKERS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    const data = await getTrendingData({
      type: "sticker",
      entityId: stickerData.assetId,
    })
    this.setState({
      trendingData: !_.isEmpty(data) ? data : {},
      showTrendingForm: true,
      action: "create",
      stickerId: stickerData.assetId,
      stickerData: stickerData,
    })
  }

  _trendingForm = () => {
    return (
      <div className="view_container">
        <UpsertTrendingPayload
          pageId={this.state.currentPageId}
          type={
            this.props.queryParams && this.props.queryParams.type
              ? this.props.queryParams.type
              : "TEMPLATE"
          }
          onSubmit={this._onTrendingSubmit}
          data={
            this.state.trendingData && this.state.trendingData.trending_meta_cms
              ? this.state.trendingData.trending_meta_cms
              : ""
          }
          action={
            this.state.trendingData &&
            !_.isEmpty(this.state.trendingData.trending_meta_cms)
              ? "update"
              : "create"
          }
          onCancel={() =>
            this.setState({ showTrendingForm: false, stickerId: "" })
          }
        />
      </div>
    )
  }

  _onTrendingSubmit = async payload => {
    let response
    response = await this.props.dispatch(
      markTrending({
        data: payload,
        type: "sticker",
        entityId: this.state.stickerId ? this.state.stickerId : "",
      })
    )

    this.setState({ showTrendingForm: false, templateData: null })
  }

  _onEditTabAsset = (e, stickerAsset) => {
    e.preventDefault()
    e.stopPropagation()
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKERS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.setState({
      showUpsertModal: true,
      showBulkModal: false,
      formAction: "update",
      stickerAsset: stickerAsset,
    })
  }

  _onDeleteTabById = (e, tabId) => {
    e.preventDefault()
    e.stopPropagation()
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKERS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }

    this.props.dispatch(
      deleteStickerById({
        selectedFilter: this.state.selectedFilter,
        tabId: tabId,
      })
    )
  }

  _onStickerSubmit = formData => {
    const data = {
      formData,
      query: {
        action: this.state.formAction,
        selectedFilter: this.state.filters["selectedFilter"],
        tabId: this.state.stickerAsset.stickerId,
      },
    }
    this.props.dispatch(upsertSticker(data))
  }

  _getModalContent = () => {
    return (
      <UpsertStickersForm
        formAction={this.state.formAction}
        stickerAsset={this.state.stickerAsset}
        stickersFor={this.state.filters["selectedFilter"]}
        onSubmit={this._onStickerSubmit}
        onCancel={this._closeUpsertModal}
      />
    )
  }

  _closeModal = () => {
    this.setState({
      showModal: false,
      audioId: null,
      defaultValue: null,
    })
  }

  _getBulkModalContent = () => {
    return (
      <UpsertStickersForm
        formAction={this.state.formAction}
        stickerAsset={this.state.stickerAsset}
        stickersFor={this.state.filters["selectedFilter"]}
        onSubmit={this._onStickerSubmit}
        onCancel={this._closeUpsertModal}
      />
    )
  }

  _modal = () => {
    return (
      <Card>
        <CardTitle className="p-3 pb-0">
          {this.state.formAction === "upload"
            ? "Upload Stickers"
            : "Create/Edit Stickers"}
        </CardTitle>
        <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
          {this.state.formAction === "upload" ? (
            <UploadBulkStickersForm onCancel={this._closeUpsertModal} />
          ) : (
            this._getModalContent()
          )}
        </CardBody>
      </Card>
    )
  }

  _handleFieldChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
    if (type === "selectedFilter") {
      this.props.dispatch(getMimeTypes({ selectedFilter: value }))
      this.setState({
        selectedFilter: value,
      })
    }
  }

  _searchStickers = () => {
    this.props.history.push(
      `/stickers/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: stickersForSearchAndSelectFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/stickers/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
      }
    )
  }

  _actionButtons = () => {
    return (
      <div>
        <Row className="actions-buttons" gutter={24}>
          <Col className="select" span={4}>
            <Radio.Group
              onChange={e =>
                this._handleFieldChange(e.target.value, "selectedFilter")
              }
              defaultValue={"CAMERA"}
              value={this.state.filters.selectedFilter}
            >
              <Radio value={"CAMERA"}>Camera</Radio>
              <Radio value={"COMMENT"}>Comments</Radio>
            </Radio.Group>
          </Col>
          <Col span={6}>
            <div className="d-flex justify-content-end mb-2 mx-4">
              <Button
                color="primary"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  this._searchStickers()
                }}
              >
                Search
              </Button>
              <Button color="primary" onClick={this._onCreateStickerAsset}>
                Create
              </Button>
              <Button
                color="primary"
                style={{ marginLeft: "5px" }}
                onClick={this._onBulkUploadStickers}
              >
                <i className="bx bx-upload me-1" />
                Bulk Upload
              </Button>
            </div>
          </Col>
          <Row>
            <Col span={22}>
              <Chips
                mimeTypes={this.props.mimeTypes}
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._searchStickers}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFieldChange}
                type="stickerForSearchAndSelect"
                toggleFilters={this._toggleFilters}
              />
            </Col>
          </Row>
        </Row>
      </div>
    )
  }

  _tabsList = () => {
    const { rows = [] } = this.props.stickersList
    return (
      <Row>
        {rows.map(tab => (
          <Col lg="2" sm="6" md="4">
            <Card className="overflow-hidden text-center shadow-lg">
              <CardBody className="px-0 py-0">
                <div>
                  <CardImg
                    src={
                      tab.thumbnailUrl ||
                      tab.stickerUrl ||
                      tab.sticker_thumbnail_url ||
                      tab.sticker_url
                    }
                    height="110px"
                    alt="Sticker"
                    style={{ filter: "blur(.5px)", opacity: "0.8" }}
                  />
                </div>
                {(tab.stickerName || tab.sticker_name) && (
                  <Badge
                    pill
                    className="badge-soft-primary m-1"
                    style={{ fontSize: "14px" }}
                  >
                    <div className="p-1 font-size-14">
                      #{tab.stickerName || tab.sticker_name}
                    </div>
                  </Badge>
                )}
                {(tab.mimeType || tab.sticker_mime_type) && (
                  <div className="my-1 mb-1">
                    <Badge
                      pill
                      className="badge-soft-success m-1"
                      style={{ fontSize: "14px" }}
                    >
                      <div className="p-1 font-size-14">
                        {tab.mimeType || tab.sticker_mime_type}
                      </div>
                    </Badge>
                  </div>
                )}
              </CardBody>
              <div className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center">
                <div className="flex-fill">
                  <Tooltip title="Edit Sticker">
                    <i
                      className="bx bx-edit-alt"
                      role="button"
                      onClick={
                        tab.tabType !== "LOCAL"
                          ? e => this._onEditTabAsset(e, tab)
                          : ""
                      }
                    />
                  </Tooltip>
                </div>
                <div className="flex-fill">
                  <Popconfirm
                    placement="topRight"
                    title="Are you sure you want to delete this Sticker?"
                    onConfirm={e => {
                      this._onDeleteTabById(e, tab.stickerId || tab.sticker_id)
                    }}
                  >
                    <i className="bx bx bx-trash-alt" role="button" />
                  </Popconfirm>
                </div>
                {this.state.filters.selectedFilter === "CAMERA" && (
                  <div className="flex-fill">
                    <Tooltip title="Mark Trending">
                      <i
                        className="bx bx-trending-up"
                        role="button"
                        onClick={e => this._onTrendingClick(e, tab)}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    )
  }

  _onPageChange = value => {
    this.setState({ loading: true })
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._searchContent()
      }
    )
  }

  _searchContent = () => {
    this.props.history.push(
      `/stickers/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showTrendingForm &&
          !this.state.showUpsertModal &&
          this._actionButtons()}
        {!this.state.showTrendingForm &&
          this.state.showUpsertModal &&
          this._modal()}
        {/* {!this.state.showTrendingForm &&
          !this.state.showUpsertModal && this.state.showBulkModal &&
          this._bulkModal()} */}
        {!this.state.showTrendingForm &&
          !this.state.showUpsertModal &&
          this._tabsList()}
        {this.state.showTrendingForm &&
          !this.state.showUpsertModal &&
          this._trendingForm()}
        {!this.state.showTrendingForm && !this.state.showUpsertModal && (
          <div className="d-flex justify-content-end">
            <Pagination
              style={{ marginTop: "1rem" }}
              onChange={this._onPageChange}
              defaultCurrent={this.state.filters.currentPage}
              total={
                this.props.stickersList && this.props.stickersList.total
                  ? this.props.stickersList.total
                  : 1
              }
              pageSize={this.state.filters["pageSize"] || "12"}
              showSizeChanger={false}
            />
          </div>
        )}
      </Auxiliary>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    stickersTabList: _.get(store, "stickers.stickersTabList"),
    stickersList: _.get(store, "stickers.stickersList"),
    refetchData: _.get(store, "stickers.refetchData"),
    loading: _.get(store, "stickers.loading"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    mimeTypes: _.get(store, "stickers.mimeTypes"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageStickersTab)
