import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const requestgetUser = async ({ payload }) => {
  const offset = payload["currentCreatorPage"]
    ? payload["currentCreatorPage"] - 1
    : 0
  const userUuid = payload.searchText
  const orderByField = payload.orderByField
  const order = payload.order
  const creatorType = payload.creatorType
  try {
    let data = await Axios.post(
      `/api/community/user/get?userId=${payload.creators}&offset=${offset}&userUuid=${userUuid}&orderByField=${orderByField}&order=${order}&creatorType=${creatorType}`,
      { filters: payload }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetManagersList = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/community/manager/list?userId=${payload.userId}&limit=${payload.subPageSize}&start=${payload.subCurrentPage}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetManagerData = async ({ payload }) => {
  try {
    let data = await Axios.get(`/api/community/managerData?userId=${payload}`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetUnassignedManagersList = async ({ payload }) => {
  try {
    let data = await Axios.get(`/api/community/getUnassignedManager`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestgetCommunityUserByType = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/community/list?limit=${payload.pageSize}&start=${payload.currentPage}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetCommunityContent = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/community/content/info`, {
      filters: payload,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetTopCreatorsList = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/community/topCreators/list`, {
      filters: payload,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetChartData = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllCreatorNumberByCuratedType?${payload}`
    )
    return data
  } catch (error) {
    throw error
  }
}
export const requestgetAllCreatorNumberByCuratedGenre = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllCreatorNumberByCuratedGenre?${payload}`
    )
    return data
  } catch (error) {
    throw error
  }
}
export const requestgetAllCreatorNumberByLanguage = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllCreatorNumberByLanguage?${payload}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetTimespanChartData = async ({ payload }) => {
  const { timespan = "", keyName = "", graphType = "" } = payload || {}
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllCreatorActiveLXDayByYType?${keyName}=${payload[keyName]}&days=${timespan}&type=${graphType}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetTrendsChartData = async ({ payload }) => {
  const { timespan = "", keyName = "", graphType = "" } = payload || {}
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllPostLXDayByYType?${keyName}=${payload[keyName]}&days=${timespan}&type=${graphType}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetActiveUserTrendsChartData = async ({ payload }) => {
  const { timespan = "", keyName = "" } = payload || {}
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllCreatorActiveByXDay?${keyName}=${payload[keyName]}&days=${timespan}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetAllPostsData = async ({ payload }) => {
  const { timespan = "", keyName = "" } = payload || {}
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllTypePostCount?${keyName}=${payload[keyName]}&days=${timespan}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetAllUserData = async ({ payload }) => {
  const { keyName = "" } = payload || {}
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllUserData?${keyName}=${payload[keyName]}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetTypeWiseActiveUserTrendsChartData = async ({
  payload,
}) => {
  const { timespan = "", keyName = "", graphType = "" } = payload || {}
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllCreatorActiveTypeByXDay?${keyName}=${payload[keyName]}&days=${timespan}&type=${graphType}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetAllPostTrendsChartData = async ({ payload }) => {
  const { timespan = "", keyName = "" } = payload || {}
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllPostLXDay?${keyName}=${payload[keyName]}&days=${timespan}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetAllPercentageOfPostByXViewData = async ({ payload }) => {
  const {
    views = "",
    keyName = "",
    selectedUser = "",
    timespan = 7,
  } = payload || {}
  try {
    let data = await Axios.get(
      `/api/crm/report/getAllPercentageOfPostByXView?${keyName}=${payload[keyName]}&views=${views}&selectedUser=${selectedUser}&days=${timespan}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetAggData = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/community/getAggData`, {
      filters: payload,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetTeamData = async ({ payload }) => {
  try {
    let data = await Axios.get(`/api/community/lead/teamData?userId=${payload}`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetChangelog = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/community/getChangeLog`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteUser = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/community/delete`, payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error("Action Failed !!")
    return error
  }
}

export const requestGetLeads = async () => {
  try {
    let response = await Axios.get(`/api/community/getLeads`)
    return response
  } catch (error) {
    return error
  }
}

export const requestGetCSVData = async ({ payload }) => {
  try {
    let response = await Axios.get(
      `/api/crm/report/getAllUserCSVData?communityUserId=${
        payload.communityUserId || ""
      }`
    )
    return response
  } catch (error) {
    toastr.error("Action Failed !!")
    return error
  }
}

export const requestGetDuplicateContentChartData = async ({ payload }) => {
  const { timespan = "", keyName = "", selectedUser = "" } = payload || {}
  try {
    let data = await Axios.get(
      `/api/crm/report/getDuplicateContentData?${keyName}=${payload[keyName]}&days=${timespan}&&selectedUser=${selectedUser}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUploadLeaderboard = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/community/pushLeaderboardToDB`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestLeaderboardUsersByFilters = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] || 10
    _.unset(filters, "currentPage")
    let response = await Axios.post(
      `/api/community/getDataForLeaderboard?offset=${offset}&limit=${limit}`,
      { filters: payload }
    )
    return response
  } catch (error) {
    throw error
  }
}
