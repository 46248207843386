import { call, takeLatest, put } from "redux-saga/effects"
import { requestHashtagByFilters } from "appRedux/sagas/requests/hashtag"
import {
  getHashtagByFilter,
  getHashtagByFilterSuccess,
} from "appRedux/slices/hashtag"
import { fetchError } from "appRedux/slices/common"

function* handleGetHashtagByFilters(params) {
  try {
    const data = yield call(requestHashtagByFilters, params)
    yield put(getHashtagByFilterSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getHashtagByFilter.type, handleGetHashtagByFilters)
}
