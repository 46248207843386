import React, { PureComponent } from "react"
import Plot from "react-plotly.js"

const StackedBarCharts = props => {
  let { data, title } = props
  let trace = []
  for (let item in data) {
    let x = [],
      y = []

    for (let item2 of data[item]) {
      x.push(item2.date)
      y.push(item2.count)
    }
    trace.push({
      x,
      y,
      name: item,
      type: "bar",
    })
  }

  return <Plot data={trace} layout={{ barmode: "stack", title }} bargap={0} />
}

export default StackedBarCharts
