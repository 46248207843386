import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import QueryString from "query-string"
import { getContentByFilter } from "appRedux/slices/content"
import {
  getTrendingEntityByFilters,
  entityUpsert,
} from "appRedux/slices/trending"
import Auxiliary from "../../util/Auxiliary"
import TagTrendingContent from "./tagTrendingContent"
import ContentList from "../../components/ContentList/contentList"
import NoDataFound from "../../components/NoDataFound/noDataFound"
import _ from "lodash"
import {
  Row,
  Col,
  Badge,
  ListGroup,
  ListGroupItem,
  Button,
} from "react-bootstrap"
import { Pagination, Tag } from "antd"
import { Card, CardBody } from "reactstrap"
import TrendingCard from "./trendingCard1"
import UpsertEntityForm from "./entityUpsertForm"
import Chips from "components/CommonFilter/chips"
import { trendingFilters, NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import toastr from "toastr"
import { formatNumber } from "../../util/common"
import "./styles.css"
// import { entityUpsert } from "../../../src/clientServices/trendingService"

class ManageTrendingEntity extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize: 10,
      page:
        this.props.queryParams && this.props.queryParams["page"]
          ? this.props.queryParams["page"]
          : 1,
      hashtag:
        this.props.queryParams && this.props.queryParams["hashtag"]
          ? this.props.queryParams["hashtag"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : "",
      audioId:
        this.props.queryParams && this.props.queryParams["audioId"]
          ? this.props.queryParams["audioId"]
          : "",
      trendingEntityId:
        this.props.queryParams && this.props.queryParams["trendingEntityId"]
          ? this.props.queryParams["trendingEntityId"]
          : "",
    },
    showForm:
      this.props.queryParams && this.props.queryParams.showForm
        ? this.props.queryParams.showForm
        : false,
    entityId:
      this.props.queryParams && this.props.queryParams.entityId
        ? this.props.queryParams.entityId
        : undefined,
    action:
      this.props.queryParams && this.props.queryParams.action
        ? this.props.queryParams.action
        : undefined,
    showFilters: false,
    showAdvancedFilters: false,
    hasEditPermission:
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_TRENDING"),
  }

  componentDidMount() {
    if (!this.props.common.error) {
      // this.props.dispatch(getContentByFilter(_.deepClean(this.state.filters)))
      this.props.dispatch(
        getTrendingEntityByFilters(_.deepClean(this.state.filters))
      )
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      this.props.dispatch(
        getTrendingEntityByFilters(_.deepClean(this.state.filters))
      )
    }
  }

  editClick = (trendingEntity, type) => {
    if (["create", "edit"].includes(type)) {
      this.setState({ showForm: true, action: type, trendingEntity })
    }
    if (type === "tag") {
      // this.setState({ showTagPage: true, trendingEntity })
      this.props.history.push(
        `/trending/content?hashtag=${
          trendingEntity.hashtag && trendingEntity.hashtag.substring(1)
        }&audioId=${trendingEntity.audio_id}&pageType=manage&entityId=${
          trendingEntity.entity_uuid
        }`
      )
    }
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["page"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/trending/manage?${QueryString.stringify(_.deepClean(newFilters))}`
      )
      this.props.dispatch(
        getTrendingEntityByFilters(_.deepClean(this.state.filters))
      )
    })
  }

  _onSubmit = payload => {
    this.props.dispatch(entityUpsert(payload))
  }

  _closeFilters = () => {
    this._onSearch()
    this.setState({ showFilters: false, showAdvancedFilters: false })
  }

  _onSearch = () => {
    let filters = _.cloneDeep(this.state.filters)
    _.map(this.state.filters, (filter, key) => {
      if (!filter) {
        _.unset(filters, key)
      }
    })
    this.props.history.push(
      `/trending/manage?${QueryString.stringify(filters)}`
    )
    this.props.dispatch(
      getTrendingEntityByFilters(_.deepClean(this.state.filters))
    )
  }

  _handleFilterChange = (value, type) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: trendingFilters[filter].defaultValue,
      showFilters: false,
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/trending/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        let filters = _.cloneDeep(this.state.filters)
        this.props.dispatch(getTrendingEntityByFilters(_.deepClean(filters)))
      }
    )
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  render() {
    let entityData = {}
    if (this.state.entityId) {
      ;[entityData] = this.props.trendingEntityList.filter(
        entity => entity.entity_uuid === this.state.entityId
      )
    }
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.state.showTagPage && (
          <div>
            <div
              className="avatar-xs float-end pb-3"
              role="button"
              onClick={() => {
                this.setState({ showTagPage: false })
                this.props.history.push(`/trending/manage`)
              }}
            >
              <div className="avatar-title rounded-circle bg-light">
                <span
                  className={
                    "avatar-title rounded-circle bg-light bg-" +
                    "secondary" +
                    " text-dark" +
                    " font-size-16" +
                    " font-weight-semibold"
                  }
                >
                  <i className="fas fa-times-circle" />
                </span>
              </div>
            </div>
            <TagTrendingContent
              audioId={this.state.trendingEntity.audio_id}
              hashtag={this.state.trendingEntity.hashtag}
              isManagePage={true}
              history={this.props.history}
            />
          </div>
        )}
        {!this.state.showTagPage && (
          <Row>
            <div
              className="justify-content-end mb-2"
              style={{ display: "flex" }}
            >
              {!this.state.showForm && (
                <Button
                  onClick={() => {
                    if (!this.state.hasEditPermission) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                    } else this.editClick({}, "create")
                  }}
                >
                  Create Trending Entity
                </Button>
              )}
            </div>
          </Row>
        )}
        {!this.state.showTagPage && (
          <Row>
            <Chips
              type="trending"
              showFilters={this.state.showFilters}
              showAdvancedFilters={this.state.showAdvancedFilters}
              filters={this.state.filters}
              search={this._closeFilters}
              removeFilter={this._removeFilter}
              handleFilterChange={this._handleFilterChange}
              toggleFilters={this._toggleFilters}
              toggleAdvancedFilters={this._toggleAdvancedFilters}
            />
            {this.props.trendingEntityList.length > 0 ? (
              <Col lg={this.state.showForm ? "7" : "12"}>
                {this.props.total && (
                  <div className="text-center mb-2">
                    <Tag color="geekblue">
                      Showing <b>{this.props.trendingEntityList.length}</b> of{" "}
                      <b>{formatNumber(this.props.total)}</b> Entities
                    </Tag>
                  </div>
                )}
                <div className="trending-card-container">
                  {this.props.trendingEntityList.map(
                    (trendingEntity, index) => (
                      <TrendingCard
                        trendingEntity={trendingEntity}
                        key={`${index}_${trendingEntity.title}`}
                        editClick={(trendingEntity, type) =>
                          this.editClick(trendingEntity, type)
                        }
                        audioDetails={
                          this.props.audioDetails
                            ? this.props.audioDetails.filter(
                                audio =>
                                  audio && audio.id === trendingEntity.audio_id
                              )
                            : null
                        }
                        history={this.props.history}
                        hasEditPermission={this.state.hasEditPermission}
                      />
                    )
                  )}
                </div>
              </Col>
            ) : (
              <NoDataFound message="No Data To Display" />
            )}
            {this.state.showForm && (
              <UpsertEntityForm
                currentUser={this.props.currentUser}
                action={this.state.action}
                oncancel={() => this.setState({ showForm: false })}
                onSubmit={this._onSubmit}
                data={
                  this.state.action === "edit"
                    ? this.state.trendingEntity || entityData
                    : {}
                }
                hasEditPermission={this.state.hasEditPermission}
              />
            )}
          </Row>
        )}
        {
          <div className="d-flex justify-content-end mb-3">
            <Pagination
              style={{ marginTop: "1rem" }}
              onChange={this._onPageChange}
              defaultCurrent={this.state.filters.page}
              total={this.props.total < 10000 ? this.props.total : 10000}
              pageSize={10}
              showSizeChanger={false}
            />
          </div>
        }
      </Auxiliary>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    contentList: _.get(store, "content.content"),
    total: _.get(store, "trending.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "trending.loading"),
    trendingEntityList: _.get(store, "trending.trendingEntityList"),
    audioDetails: _.get(store, "trending.audioDetails"),
    refetch: _.get(store, "trending.refetchData"),
  }
}

export default connect(mapStateToProps)(withRouter(ManageTrendingEntity))
