import React from "react"
import { connect } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import FullCalendar, { formatDate } from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import { INITIAL_EVENTS, createEventId } from "./event-utils"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Upload, Tag, Tooltip } from "antd"
import moment from "moment"
import ValidatorForm, {
  TextField,
  Select,
  Switch,
  DateTimePicker,
} from "../ValidatorForm"
import {
  createEvent,
  getEvents,
  upsertEvent,
} from "../../appRedux/slices/events"
import UpdateEvent from "./updateEvent"
import "./event.css"
import { ToolFilled } from "@ant-design/icons"
import { NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import toastr from "toastr"
const TYPE_TO_STYLE_MAP = {
  date: "type-date",
  day: "type-day",
  part_of_day: "type-part-day",
  religious_festivals: "type-religion",
  national_festivals: "type-national",
  regional_festivals: "type-regional",
  celeb_bdays: "type-celeb",
  election: "type-election",
  sports: "type-sports",
  rip: "type-rip",
}

class Events extends React.Component {
  calendarComponentRef = React.createRef()
  state = {
    weekendsVisible: true,
    currentEvents: [],
    showCreateForm: false,
    calendarEvents: [],
  }

  componentDidMount() {
    this.props.dispatch(getEvents())
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.eventList !== this.props.eventList &&
      this.props.eventList.length > 0
    ) {
      let calendarEvents = this.props.eventList.map(event => {
        const {
          event_name: title,
          feed_start_time: start,
          feed_end_time: end,
          type,
        } = event
        const className = TYPE_TO_STYLE_MAP[type]
        return { title, allDay: true, start, end, className, ...event }
      })
      this.setState({ calendarEvents })
    }
    if (
      this.props.upsertStatus &&
      this.props.upsertStatus === 200 &&
      this.props.refetchData
    ) {
      this.props.dispatch(getEvents())
    }
    if (
      this.props.createStatus &&
      this.props.createStatus === 200 &&
      this.props.refetchData
    ) {
      this.props.dispatch(getEvents())
    }
  }

  _onSubmit = (formData, data, id) => {
    if (this.state.action === "update") {
      this.props.dispatch(upsertEvent({ data, id }))
      this.setState({
        formData,
        showCreateForm: false,
      })
    } else {
      this.props.dispatch(createEvent(data))
      this.setState({
        calendarEvents: this.state.calendarEvents.concat({
          title: formData.event_name,
          start: moment(formData.feed_start_time).format("YYYY-MM-DD"),
          end: moment(formData.feed_end_time).format("YYYY-MM-DD"),
          allDay: true,
        }),
        showCreateForm: false,
        formData,
      })
    }
  }

  renderSidebar() {
    const upcomingEventList = this.props.eventList
      .filter(event => {
        if (
          event.feed_start_time &&
          moment(event.feed_start_time) > moment(Date())
        ) {
          return event
        }
      })
      .slice(0, 5)
    return (
      <div className="demo-app-sidebar">
        <div
          className="demo-app-sidebar-section"
          style={{ paddingBottom: "0px" }}
        >
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Click an event to modify</li>
          </ul>
        </div>
        <div className="demo-app-sidebar-section">
          <h2>Upcoming Events</h2>
          <div className="grid-container">
            {upcomingEventList.map(event => (
              <div className="grid-item">{this.renderSidebarEvent(event)}</div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    })
  }

  handleDateSelect = selectInfo => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_EVENT")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({ showCreateForm: true, selectInfo })
  }

  handleEventClick = clickInfo => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_EVENT")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    let def = clickInfo.event && clickInfo.event._def
    let selectedEvent = {}
    selectedEvent = def["extendedProps"]
    this.setState({ selectedEvent, showCreateForm: true, action: "update" })
  }

  handleEvents = events => {
    this.setState({
      currentEvents: events,
    })
  }

  handleEventRZesize = e => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_EVENT")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    const selectedEvent = e.event && e.event._def && e.event._def.extendedProps
    const endDelta = e.endDelta
    let date
    if (endDelta.days) {
      date = moment(selectedEvent.feed_end_time).add(endDelta.days, "days")
    }
    let data = new FormData()
    const id = selectedEvent && selectedEvent["event_id"]
    data.append("fields", JSON.stringify({ feed_end_time: date }))
    this.props.dispatch(upsertEvent({ data, id }))
    this.setState({ selectedEvent })
  }

  renderEventContent = eventInfo => {
    const event = eventInfo.event._def.extendedProps
    let src = event && event.event_thumbnail ? event.event_thumbnail : ""
    return (
      <>
        <img src={src} height={30} width={30} /> <b>{eventInfo.timeText}</b>
        <i style={{ textColor: "black", color: "black" }}>
          {eventInfo.event.title}
        </i>
      </>
    )
  }

  renderSidebarEvent = event => {
    return (
      <Card
        onClick={() => {}}
        key={event.event_id}
        style={{
          width: 160,
          boxShadow: "1px 1px 1px #AEAEAE",
          marginBottom: "0px",
        }}
      >
        <CardBody>
          <div className="d-flex justify-content-center mb-2 text-truncate-2 font-sixe-15">
            <img
              src={event.event_thumbnail ? event.event_thumbnail : ""}
              height={120}
              width={120}
            />
          </div>
          <div className="font-size-16 font-weight-semibold">
            {event.event_name}
          </div>
          <Tooltip title="Start Time">
            <Tag color="#6a6b6b">
              {event.feed_start_time &&
                moment(event.feed_start_time).format("YYYY-MM-DD HH:mm:ss")}
            </Tag>
          </Tooltip>
          <Tooltip title="End Time">
            <Tag color="red" className="mt-2">
              {event.feed_end_time &&
                moment(event.feed_end_time).format("YYYY-MM-DD HH:mm:ss")}
            </Tag>
          </Tooltip>
        </CardBody>
      </Card>
    )
  }

  render() {
    return (
      <div>
        {this.state.showCreateForm && (
          <UpdateEvent
            onSubmit={this._onSubmit}
            selectedEvent={this.state.selectedEvent}
            action={this.state.action}
            onCancel={() =>
              this.setState({ showCreateForm: false, selectedEvent: undefined })
            }
            selectInfo={this.state.selectInfo}
          />
        )}
        {!this.state.showCreateForm && (
          <React.Fragment>
            <div className="demo-app">
              {this.renderSidebar()}
              <div className="demo-app-main">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={this.state.weekendsVisible}
                  ref={this.calendarComponentRef}
                  select={this.handleDateSelect}
                  eventContent={this.renderEventContent}
                  eventClick={this.handleEventClick}
                  eventsSet={this.handleEvents}
                  events={this.state.calendarEvents}
                  eventResize={this.handleEventRZesize}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    contentList: _.get(store, "content.content"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "content.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "content.loading"),
    eventList: _.get(store, "events.eventList"),
    upsertStatus: _.get(store, "events.upsertStatus"),
    refetchData: _.get(store, "events.refetchData"),
    createStatus: _.get(store, "events.createStatus"),
  }
}

export default connect(mapStateToProps)(Events)
