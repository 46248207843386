import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import Cards from "./cards"

class CityTier extends Cards {
  state = {
    currentTab: "cityTier",
    labelToDisplay: "City Tier",
    currentHierarchy: ["city_tier"],
    selectionType: "SINGLE",
    currentLevel: 0,
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderStepper()}
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    currentData: _.get(store, "taxonomy.cityTier"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(CityTier)
