import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { DatePicker, Input, Select as AntSelect } from "antd"
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  InputGroup,
} from "reactstrap"
import ValidatorForm, { TextField } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getCmsUserList } from "../../appRedux/slices/cmsUser"
import {
  resetState,
  boostFollow,
  getActivityBoostHistory,
} from "appRedux/slices/appUser"
import Auxiliary from "../../util/Auxiliary"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import moment from "moment"
import { bulkUploadUserForBoosting } from "clientServices/appUserService"
import BoostReport from "./boostReport"
import { Pagination } from "antd"
import dayjs from "dayjs"
const { Option } = AntSelect

class ActivityBooster extends ErrorBoundComponent {
  state = {
    filters: {
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : "",
      searchType:
        this.props.queryParams && this.props.queryParams["searchType"]
          ? this.props.queryParams["searchType"]
          : "",
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      fromDate: this.props.queryParams
        ? this.props.queryParams["fromDate"]
        : "",
      toDate: this.props.queryParams ? this.props.queryParams["toDate"] : "",
      pageSize: this.props.queryParams
        ? this.props.queryParams["pageSize"]
        : 10,
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "desc",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(
        getActivityBoostHistory(_.deepClean(this.state.filters))
      )

      if (this.props.moderatorList && this.props.moderatorList.length === 0) {
        this.props.dispatch(getCmsUserList())
      }
    }
  }

  componentDidUpdate() {
    if (this.props.refetchData) {
      this.props.dispatch(
        getActivityBoostHistory(_.deepClean(this.state.filters))
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    if (type === "searchType" && !newFilters.searchText) {
      newFilters["searchText"] = undefined
    }
    this.setState({ filters: newFilters })
  }

  _searchContent = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/content/tag?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    this.setState({
      showForm: false,
      showBulkUploadForm: false,
    })
    this.props.dispatch(boostFollow(formData))
  }

  _form = () => {
    return (
      <ValidatorForm onSubmit={this._onSubmit} layout={"vertical"}>
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" lg="6">
                <TextField
                  label={"User ID"}
                  placeholderLabel="User ID"
                  field={"id"}
                  className="creator-form"
                  value={this.state.selectedId}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm="12" lg="6">
                <TextField
                  label={"Follow Count"}
                  placeholderLabel="Provide Follow Count"
                  className="creator-form"
                  field={"followCount"}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>

              <Col sm="12" lg="6">
                <TextField
                  label="Boosting Days"
                  placeholderLabel="Provide Boosting Days"
                  className="creator-form"
                  field={"boostingDays"}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm="12">
                <div className="d-flex justify-content-end">
                  <Button
                    htmlType="submit"
                    className="font-16 btn-block btn btn-primary"
                    color="primary"
                    disabled={this.state.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="danger"
                    size="default"
                    disabled={this.state.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                    onClick={() => this.setState({ showForm: false })}
                    className="mx-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ValidatorForm>
    )
  }

  _onUpload = async () => {
    const formData = new FormData()
    formData.append("csvFile", this.state.csvFile)
    await bulkUploadUserForBoosting(formData)
  }

  _bulkUploadForm = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="6">
              <label className="col-form-label">Upload CSV File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this.setState({
                    csvFile: e.target.files[0],
                  })
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm="12">
              <div className="d-flex justify-content-end">
                <Button
                  className="font-16 btn-block btn btn-primary"
                  color="primary"
                  onClick={this._onUpload}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="danger"
                  size="default"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showBulkUploadForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/activity/boost?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
      this.props.dispatch(
        getActivityBoostHistory(_.deepClean(this.state.filters))
      )
    })
  }

  _onContentSearch = () => {
    this.setState({ ...this.state.filters, ...{ currentPage: 1 } })
    this.props.history.push(
      `/activity/boost?${QueryString.stringify(
        _.deepClean({ ...this.state.filters, ...{ currentPage: "1" } })
      )}`
    )
    this.props.dispatch(
      getActivityBoostHistory(
        _.deepClean({ ...this.state.filters, ...{ currentPage: "1" } })
      )
    )
  }

  _filters = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="4" md="8">
              <Input.Group compact={true}>
                <AntSelect
                  allowClear={true}
                  defaultValue={this.state.filters["searchType"]}
                  onChange={value => {
                    this._handleFilterChange(value, "searchType")
                  }}
                  style={{ width: "35%" }}
                  options={[
                    { value: "userUuid", label: "User UUID" },
                    { value: "contentUuid", label: "  Content UUID" },
                    { value: "csvName", label: "CSV FileName" },
                    { value: "moderator", label: "Moderator" },
                  ]}
                />
                {this.state.filters["searchType"] === "moderator" ? (
                  <AntSelect
                    allowClear={true}
                    defaultValue={this.state.filters["searchText"]}
                    onChange={value =>
                      this._handleFilterChange(value, "searchText")
                    }
                    style={{ width: "65%" }}
                    options={this.props.moderatorList.map(user => {
                      return {
                        label: user.name,
                        value: user.userId.toString(),
                      }
                    })}
                  />
                ) : (
                  <Input
                    allowClear={true}
                    defaultValue={this.state.filters["searchText"] || ""}
                    type="text"
                    style={{ width: "65%" }}
                    onChange={e =>
                      this._handleFilterChange(e.target.value, "searchText")
                    }
                    validations={["required"]}
                    id="autoSizingInput"
                    placeholder={
                      this.state.filters["searchType"] === "csvName"
                        ? "Name"
                        : "ID"
                    }
                    errors={["This field is required"]}
                  />
                )}
              </Input.Group>
            </Col>
            <Col lg="3">
              <FormGroup>
                <InputGroup>
                  <DatePicker
                    defaultValue={
                      this.state.filters["fromDate"]
                        ? dayjs.unix(
                            this.state.filters["fromDate"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    style={{ width: "50%" }}
                    showTime
                    placeholder="From Date"
                    onChange={(date, dateString) => {
                      this._handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "fromDate"
                      )
                    }}
                  />
                  <DatePicker
                    defaultValue={
                      this.state.filters["toDate"]
                        ? dayjs.unix(
                            this.state.filters["toDate"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    style={{ width: "50%" }}
                    placeholder="To Date"
                    onChange={(date, dateString) => {
                      this._handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "toDate"
                      )
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col lg="1">
              <AntSelect
                value={this.state.filters["status"]}
                onChange={value => {
                  this._handleFilterChange(value, "status")
                }}
                placeholder="Status"
                options={[
                  { value: "", label: "All" },
                  { value: "SUCCESS", label: "Done" },
                  { value: "FAILED", label: "Failed" },
                ]}
                style={{ width: "100%" }}
                size="default"
              />
            </Col>
            <Col lg="3">
              <b>Boosted Date: </b>
              <AntSelect
                allowClear={true}
                defaultValue={this.state.filters["order"]}
                placeholder="Order"
                onChange={value => {
                  this._handleFilterChange(value, "order")
                }}
                style={{ width: "50%" }}
              >
                <Option value="asc">Ascending</Option>
                <Option value="desc">Descending</Option>
              </AntSelect>
            </Col>
            <Col lg="1" className="d-flex justify-content-end">
              <button
                onClick={this._onContentSearch}
                role="button"
                className="btn btn-success d-flex align-items-center"
              >
                <div className="mx-2">Apply</div>
                <span>
                  <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
                </span>
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  render() {
    if (this.state.showSearchAndSelect) {
      return (
        <SearchAndSelect
          type="VIDEO"
          visible={this.state.showSearchAndSelect}
          onClose={() => this.setState({ showSearchAndSelect: false })}
          onAdd={arr => this.setState({ selectedId: arr[0] })}
          selectOne={true}
          selectedArr={[]}
        />
      )
    }

    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}

        {this.state.showForm && this._form()}
        {this.state.showBulkUploadForm && this._bulkUploadForm()}
        {this.props.userList &&
          this.props.userList.length > 0 &&
          !this.state.showForm &&
          !this.state.showBulkUploadForm && (
            <BoostReport
              boostList={this.props.userList}
              total={this.props.total}
            />
          )}
        <div className="d-flex justify-content-end mb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={this._onPageChange}
            defaultCurrent={this.state.filters.currentPage}
            total={this.props.total < 10000 ? this.props.total : 10000}
            pageSize={this.state.filters.pageSize}
            showSizeChanger={false}
          />
        </div>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    userList: _.get(store, "appUser.boostHistory"),
    total: _.get(store, "appUser.total"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "appUser.loading"),
    moderatorList: _.get(store, "cmsUser.userList") || [],
    refetchData: _.get(store, "appUser.refetchBoostHistory"),
  }
}

export default connect(mapStateToProps)(ActivityBooster)
