import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import _ from "lodash"
import {
  getBulkReportList,
  getBulkReportListSuccess,
  getActivityList,
  getActivityListSuccess,
} from "appRedux/slices/activity"
import {
  requestGetBulkReportList,
  requestGetActivityDetails,
} from "appRedux/sagas/requests/activity"
import Axios from "axios"
import { fetchError } from "appRedux/slices/common"

export const fetchReportById = async params => {
  try {
    let data = await Axios.get(`/api/reports/getReportById?id=${params.id}`)
    return data
  } catch (error) {
    throw error
  }
}

function* handleActivityList(params) {
  try {
    const data = yield call(requestGetActivityDetails, params)
    yield put(getActivityListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetBulkReportList(params) {
  try {
    const data = yield call(requestGetBulkReportList, params)
    yield put(getBulkReportListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* fetchReportRequest(params) {
  try {
    const data = yield call(fetchReportById, params)
    yield put(getBulkReportByIdSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeEvery(getBulkReportList.type, handleGetBulkReportList)
  yield takeEvery(getActivityList.type, handleActivityList)
}
