import { createSlice } from "@reduxjs/toolkit"

const contestSlice = createSlice({
  name: "contest",
  initialState: {
    loading: false,
    contestList: [],
    contest: {},
  },
  reducers: {
    getContestListByFilters(state, action) {
      return {
        ...state,
        loading: true,
        contestList: [],
      }
    },
    getContestListByFiltersSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        contestList: payload.data.data,
        total: payload.data?.total?.value ? payload.data.total.value : 0,
      }
    },
    upsertContest(state) {
      return {
        ...state,
        loading: false,
      }
    },
    upsertContestSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    getContestDetails(state) {
      return {
        ...state,
        loading: true,
        refetchContestDetails: false,
      }
    },
    getContestDetailsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        contest: payload?.data,
        refetchContestDetails: false,
      }
    },
    updateCategory(state) {
      return {
        ...state,
        loading: true,
        refetchContestDetails: false,
      }
    },
    updateCategorySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchContestDetails: true,
      }
    },
    addCategory(state) {
      return {
        ...state,
        loading: false,
      }
    },
    addCategorySuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    addSubCategory(state) {
      return {
        ...state,
        loading: false,
      }
    },
    addSubCategorySuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    deleteBanner(state) {
      return {
        ...state,
        loading: true,
        refetchContestDetails: false,
      }
    },
    deleteBannerSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchContestDetails: true,
      }
    },
    resetState() {
      return {
        loading: false,
        contestList: [],
        contest: {},
        refetchContestDetails: false,
      }
    },
  },
})

export const {
  getContestListByFilters,
  getContestListByFiltersSuccess,
  upsertContest,
  upsertContestSuccess,
  getContestDetails,
  getContestDetailsSuccess,
  updateCategory,
  updateCategorySuccess,
  resetState,
  addCategory,
  addCategorySuccess,
  addSubCategory,
  addSubCategorySuccess,
  deleteBanner,
  deleteBannerSuccess,
} = contestSlice.actions

export default contestSlice.reducer
