import React from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Select, DatePicker, Divider, Checkbox } from "antd"
import { Col, Row } from "reactstrap"
import {
  DEACTIVATE_REASONS,
  LANGUAGES,
  TARGET_CATEGORY_V2,
} from "../../constants/uiConstants"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import ReactSelect from "react-select"
import "../../containers/Zone/style.css"
import dayjs from "dayjs"

const { Option } = Select

const formatOptionLabel = ({ value, title, zone_image }) => {
  return (
    <span>
      {zone_image ? (
        <img
          src={zone_image}
          alt={title}
          style={{ hight: "20px", width: "20px" }}
        />
      ) : (
        <i className="fa fa-users" aria-hidden="true"></i>
      )}{" "}
      &nbsp;
      <b>{title}</b>
    </span>
  )
}

const ContentDrawer = props => {
  const callFrom = props.callFrom || undefined
  const communityManager =
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("COMMUNITY_MANAGER")
  const realStatPermissionUser =
    // props.currentUser.jotUserType < 3 ||
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("VIEW_REAL_STATS")
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By</h5>
          </Divider>
          <Row className="w-100">
            <Col className="d-flex">
              <Input.Group compact={true}>
                <Select
                  placeholder="Search Type"
                  style={{ width: "50%" }}
                  allowClear={true}
                  defaultValue={props.filters["searchType"]}
                  onChange={value =>
                    props.handleFilterChange(value, "searchType")
                  }
                >
                  <Option value="content_uuid">Content UUID</Option>
                  {!["user_content", "zone_content"].includes(callFrom) && (
                    <Option value="created_by">User UUID</Option>
                  )}
                  <Option value="title">Title</Option>
                  <Option value="tag">Tag</Option>
                  <Option value="created_day">Created Day</Option>
                  <Option value="reject_reason">Reject Reason</Option>
                  <Option value="target_category">Approve Reason</Option>
                  <Option value="target_category_v2">Target Category</Option>
                  <Option value="reprocessed_moderation">
                    ReProcessed Moderation Level
                  </Option>
                </Select>
                {props.filters["searchType"] !== "created_day" &&
                  props.filters["searchType"] !== "reprocessed_moderation" &&
                  props.filters["searchType"] !== "reject_reason" &&
                  props.filters["searchType"] !== "target_category" &&
                  props.filters["searchType"] !== "target_category_v2" && (
                    <Input
                      defaultValue={props.filters["searchText"]}
                      placeholder={"Search Value"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value.trim(),
                          "searchText"
                        )
                      }
                      allowClear={true}
                      name="searchId"
                      style={{ width: "50%" }}
                    />
                  )}
                {props.filters["searchType"] === "created_day" && (
                  <DatePicker
                    defaultValue={
                      props.filters["searchText"]
                        ? dayjs.unix(
                            props.filters["searchText"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD"
                    onChange={(moment, dateString) => {
                      props.handleFilterChange(dateString, "searchText")
                    }}
                    style={{ width: "50%" }}
                  />
                )}
                {props.filters["searchType"] === "reject_reason" && (
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["searchText"]}
                    onChange={e => props.handleFilterChange(e, "searchText")}
                    placeholder="Reject Reason"
                    style={{ width: "50%" }}
                  >
                    {DEACTIVATE_REASONS.map(reason => (
                      <Option value={reason.value}>{reason.label}</Option>
                    ))}
                  </Select>
                )}
                {(props.filters["searchType"] === "target_category" ||
                  props.filters["searchType"] === "target_category_v2") && (
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["searchText"]}
                    onChange={e => props.handleFilterChange(e, "searchText")}
                    placeholder="Approval Reason"
                    style={{ width: "50%" }}
                  >
                    {TARGET_CATEGORY_V2.map(reason => (
                      <Option value={reason.value}>{reason.label}</Option>
                    ))}
                    <Option value="not_exist">Not Exist</Option>
                  </Select>
                )}
                {props.filters["searchType"] === "reprocessed_moderation" && (
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["searchText"]}
                    placeholder="New Auto Moderation Level"
                    onChange={e => props.handleFilterChange(e, "searchText")}
                    style={{ width: "50%" }}
                  >
                    <Option value="nsfw">NSFW</Option>
                    <Option value="sfw">SFW</Option>
                  </Select>
                )}
              </Input.Group>
            </Col>
          </Row>
        </Col>
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Order By</h5>
          </Divider>

          <Row className="w-100">
            <Col className="d-flex">
              <Input.Group compact={true}>
                <Select
                  allowClear={true}
                  defaultValue={props.filters["orderByField"]}
                  placeholder="Order By"
                  onChange={value => {
                    props.handleFilterChange(value, "orderByField")
                  }}
                  style={{ width: "50%" }}
                >
                  {!communityManager && (
                    <React.Fragment>
                      <Option value="view_count">View Count</Option>
                      <Option value="share_count">Share Count</Option>
                      <Option value="download_count">Download Count</Option>
                      <Option value="like_count">Like Count</Option>
                      <Option value="review_order">Review Order</Option>
                    </React.Fragment>
                  )}
                  <Option value="created_date">Created Date</Option>
                  <Option value="t_score_v2">T Score V2</Option>
                  {(props.currentUser.jotUserType < 3 ||
                    (props.currentUser.jotUserType === 4 &&
                      props.currentUser.permissions &&
                      props.currentUser.permissions.includes(
                        "VIEW_ACTIVITY"
                      ))) && <Option value="qc_order">QC Order</Option>}
                </Select>
                <Select
                  allowClear={true}
                  defaultValue={props.filters["order"]}
                  placeholder="Order"
                  onChange={value => {
                    props.handleFilterChange(value, "order")
                  }}
                  style={{ width: "50%" }}
                >
                  <Option value="asc">Ascending</Option>
                  <Option value="desc">Descending</Option>
                </Select>
              </Input.Group>
            </Col>
          </Row>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Moderation Level</h5>
          </Divider>
          <Col span={24}>
            <Select
              allowClear={true}
              placeholder="Moderation level"
              defaultValue={props.filters["moderationLevel"]}
              onChange={value => {
                props.handleFilterChange(value, "moderationLevel")
              }}
              style={{ width: "100%" }}
            >
              <Option value="">All</Option>
              <Option value="3">Auto Approved</Option>
              <Option value="0">Auto Disabled</Option>
              <Option value="2">Human Approved</Option>
              <Option value="-1">Human Disabled</Option>
              <Option value="1">Unmoderated</Option>
            </Select>
          </Col>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Include Languages</h5>
          </Divider>
          <Select
            mode="multiple"
            showSearch
            allowClear={true}
            placeholder="Include Languages"
            defaultValue={props.filters["includeLang"]}
            onChange={value => {
              props.handleFilterChange(value, "includeLang")
            }}
            style={{ width: "100%" }}
          >
            {LANGUAGES.map(lang => (
              <Option value={lang.value}>{lang.label}</Option>
            ))}
          </Select>
        </Col>
      </Row>
      {!["user_content", "zone_content"].includes(callFrom) ? (
        <>
          <div className="d-flex justify-content-center">
            <div onClick={props.toggleAdvancedFilters} role="button">
              {props.showAdvancedFilters ? (
                <i
                  className="fas fa-angle-double-up
  font-size-16 align-middle me-2"
                ></i>
              ) : (
                <i
                  className="fas fa-angle-double-down
   font-size-16 align-middle me-2"
                ></i>
              )}
              {props.showAdvancedFilters ? "Hide" : "Show"} Advanced Filters
            </div>
          </div>

          {props.showAdvancedFilters && (
            <Row style={{ marginBottom: "10px" }}>
              <Col lg="3" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Audio Id</h5>
                </Divider>
                <Input
                  defaultValue={props.filters["audioId"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "audioId")
                  }
                  allowClear={true}
                  name="audioId"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col lg="3" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Clustered Audio Id
                  </h5>
                </Divider>
                <Input
                  defaultValue={props.filters["clusteredAudioId"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(
                      e.target.value.trim(),
                      "clusteredAudioId"
                    )
                  }
                  allowClear={true}
                  name="clusteredAudioId"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col lg="3" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Glamour Level
                  </h5>
                </Divider>
                <Select
                  allowClear={true}
                  placeholder="Glamour Level"
                  defaultValue={props.filters["glamourLevel"]}
                  onChange={value => {
                    props.handleFilterChange(value, "glamourLevel")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="">All</Option>
                  <Option value="pretty_girl">G1 - Pretty girl</Option>
                  <Option value="glamorous">G2 - Glamourous</Option>
                  <Option value="classy_hot_sexy">G3 - Classy hot sexy</Option>
                  <Option value="masala">G4 - Masala</Option>
                  <Option value="vulgar_masala">G5 - Vulgar masala</Option>
                  <Option value="acceptable_child_nudity">
                    G6 - Acceptable child nudity
                  </Option>
                  <Option value="partial_adult_nudity">
                    G7 - Partial adult nudity
                  </Option>
                </Select>
              </Col>
              <Col lg="3" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Glamour Grade
                  </h5>
                </Divider>
                <Select
                  allowClear={true}
                  placeholder="Glamour Grade"
                  defaultValue={props.filters["glamourGrade"]}
                  onChange={value => {
                    props.handleFilterChange(value, "glamourGrade")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="">All</Option>
                  <Option value="a">Grade A</Option>
                  <Option value="b">Grade B</Option>
                  <Option value="c">Grade C</Option>
                </Select>
              </Col>
              <Col lg="3" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Glamour Region
                  </h5>
                </Divider>
                <Select
                  allowClear={true}
                  placeholder="Glamour Region"
                  defaultValue={props.filters["glamourRegion"]}
                  onChange={value => {
                    props.handleFilterChange(value, "glamourRegion")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="">All</Option>
                  <Option value="indian">Indian</Option>
                  <Option value="international_fair">International Fair</Option>
                  <Option value="international_black">
                    International Black
                  </Option>
                  <Option value="south_asian_chinese">
                    South Asian / Chinese
                  </Option>
                </Select>
              </Col>
              <Col lg="3" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Source Platform
                  </h5>
                </Divider>
                <Input
                  defaultValue={props.filters["sourcePlatform"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(
                      e.target.value.trim(),
                      "sourcePlatform"
                    )
                  }
                  allowClear={true}
                  name="sourcePlatform"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col lg="3" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Embedding</h5>
                </Divider>
                <Input
                  defaultValue={props.filters["embedding"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "embedding")
                  }
                  allowClear={true}
                  name="embedding"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col lg="3" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Source URL</h5>
                </Divider>
                <Input
                  defaultValue={props.filters["sourceUrl"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "sourceUrl")
                  }
                  allowClear={true}
                  name="sourceUrl"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col lg="6" md="12">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Range Filter</h5>
                </Divider>
                <Input.Group compact={true}>
                  <Row className="w-100">
                    <Col className="d-flex justify-content-center">
                      <Select
                        allowClear={true}
                        defaultValue={props.filters["rangeSearchType"]}
                        placeholder="Range Search Type"
                        onChange={value => {
                          props.handleFilterChange(value, "rangeSearchType")
                        }}
                        style={{ width: "100%" }}
                      >
                        <Option value="review_bucket">Bucket</Option>
                        {realStatPermissionUser && (
                          <React.Fragment>
                            <Option value="download_count">
                              Download Count
                            </Option>
                            <Option value="like_count">Like Count</Option>
                            <Option value="share_count">Share Count</Option>
                            <Option value="view_count">View Count</Option>
                          </React.Fragment>
                        )}
                        <Option value="video_duration">Video Duration</Option>
                        <Option value="taxonomy_meta.video_quality_attributes.rating">
                          Taxonomy Rating
                        </Option>
                        <Option value="trending_meta.rating">
                          Trending Entity Rating
                        </Option>
                        <Option value="trending_meta_cms.score">
                          Trending CMS Rating
                        </Option>
                        <Option value="trending_meta_system.score">
                          Trending System Rating
                        </Option>
                      </Select>
                      <Select
                        allowClear={true}
                        defaultValue={props.filters["opSearchType"]}
                        placeholder="Operator"
                        onChange={value => {
                          props.handleFilterChange(value, "opSearchType")
                        }}
                        style={{ width: "100%" }}
                      >
                        <Option value="bt">Between</Option>
                        <Option value="eq">Equal to</Option>
                        <Option value="gt">Greater Than</Option>
                        <Option value="lt">Less Than</Option>
                      </Select>
                      {props.filters["opSearchType"] !== "bt" && (
                        <Input
                          defaultValue={props.filters["rangeSearchText"]}
                          placeholder={"Range Search Text"}
                          onChange={e =>
                            props.handleFilterChange(
                              e.target.value,
                              "rangeSearchText"
                            )
                          }
                          allowClear={true}
                          style={{ width: "100%", marginBottom: "5px" }}
                        />
                      )}
                      {props.filters["opSearchType"] === "bt" && (
                        <Input
                          defaultValue={props.filters["rangeSearchFrom"]}
                          placeholder={"Range Search From"}
                          onChange={e =>
                            props.handleFilterChange(
                              e.target.value,
                              "rangeSearchFrom"
                            )
                          }
                          allowClear={true}
                          style={{ width: "100%", marginBottom: "5px" }}
                        />
                      )}
                      {props.filters["opSearchType"] === "bt" && (
                        <Input
                          defaultValue={props.filters["rangeSearchTo"]}
                          placeholder={"Range Search To"}
                          onChange={e =>
                            props.handleFilterChange(
                              e.target.value,
                              "rangeSearchTo"
                            )
                          }
                          allowClear={true}
                          style={{ width: "100%", marginBottom: "5px" }}
                        />
                      )}
                    </Col>
                  </Row>
                </Input.Group>
              </Col>
              {props.currentUser.jotUserType < 3 && (
                <Col lg="2" md="4">
                  <Divider>
                    <h5 className="font-size-11 mb-0 text-muted">
                      Source Type
                    </h5>
                  </Divider>
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["sourceType"]}
                    onChange={value => {
                      props.handleFilterChange(value, "sourceType")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="">All Content</Option>
                    <Option value="ads">Ads Content</Option>
                    <Option value="non_ads">Non Ads Content</Option>
                  </Select>
                </Col>
              )}

              {
                <Col lg="2" md="4">
                  <Divider>
                    <h5 className="font-size-11 mb-0 text-muted">
                      Video Source
                    </h5>
                  </Divider>
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["videoSource"]}
                    onChange={value => {
                      props.handleFilterChange(value, "videoSource")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="">All Sources</Option>
                    <Option value="GALLERY">Gallery</Option>
                    <Option value="CAMERA">Camera</Option>
                  </Select>
                </Col>
              }
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Bucket</h5>
                </Divider>
                <Select
                  showSearch
                  allowClear={true}
                  placeholder="Bucket"
                  defaultValue={props.filters["bucket"]}
                  onChange={value => {
                    props.handleFilterChange(value, "bucket")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="-1">All</Option>
                  {[...Array(100).keys()].map(value => (
                    <Option value={value}>{value}</Option>
                  ))}
                </Select>
              </Col>
              {props.currentUser.jotUserType && !props.currentUser.agency && (
                <Col lg="2" md="4">
                  <Divider>
                    <h5 className="font-size-11 mb-0 text-muted">
                      Content Type
                    </h5>
                  </Divider>
                  <Select
                    placeholder="Content Type"
                    allowClear={true}
                    style={{ width: "100%" }}
                    defaultValue={props.filters["contentType"]}
                    onChange={value =>
                      props.handleFilterChange(value, "contentType")
                    }
                  >
                    <Option value="">All Users</Option>
                    <Option value="e">External</Option>
                    <Option value="i">Internal</Option>
                    <Option value="g">Ghost</Option>
                  </Select>
                </Col>
              )}
              <Col lg="4" md="8">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Created Date</h5>
                </Divider>
                <Input.Group compact={true}>
                  <Row className="w-100">
                    <Col className="d-flex">
                      <DatePicker
                        defaultValue={
                          props.filters["createdDateFrom"]
                            ? dayjs.unix(
                                props.filters["createdDateFrom"] / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        placeholder="From Date"
                        onChange={(date, dateString) => {
                          props.handleFilterChange(
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null,
                            "createdDateFrom"
                          )
                        }}
                        style={{ width: "100%" }}
                      />
                      <DatePicker
                        defaultValue={
                          props.filters["createdDateTo"]
                            ? dayjs.unix(
                                props.filters["createdDateTo"] / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        placeholder="To Date"
                        onChange={(date, dateString) => {
                          props.handleFilterChange(
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null,
                            "createdDateTo"
                          )
                        }}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </Input.Group>
              </Col>
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Moderation QC Status
                  </h5>
                </Divider>
                <Select
                  placeholder="QC Status"
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.filters["qcDone"]}
                  onChange={value => props.handleFilterChange(value, "qcDone")}
                >
                  <Option value="">All</Option>
                  <Option value="Y">Done</Option>
                  <Option value="N">Not Done</Option>
                </Select>
              </Col>
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Masked Content
                  </h5>
                </Divider>
                <Select
                  mode="multiple"
                  placeholder="Masked Content"
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.filters["maskedContent"]}
                  onChange={value =>
                    props.handleFilterChange(value, "maskedContent")
                  }
                >
                  <Option value="">All</Option>
                  <Option value="SUCCESS">Success</Option>
                  <Option value="IN_PROGRESS">In Progress</Option>
                  <Option value="FAILED">Failed</Option>
                  <Option value="NOT_STARTED">Not Started</Option>
                  <Option value="SKIPPED">Skipped</Option>
                </Select>
              </Col>
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Exclude Languages
                  </h5>
                </Divider>
                <Select
                  mode="multiple"
                  showSearch
                  allowClear={true}
                  placeholder="Exclude Languages"
                  defaultValue={props.filters["excludeLang"]}
                  onChange={value => {
                    props.handleFilterChange(value, "excludeLang")
                  }}
                  style={{ width: "100%" }}
                >
                  {LANGUAGES.map(lang => (
                    <Option value={lang.value}>{lang.label}</Option>
                  ))}
                </Select>
              </Col>
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Tagged Content
                  </h5>
                </Divider>
                <Select
                  placeholder="Tagged Content"
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.filters["taggedContent"]}
                  onChange={value =>
                    props.handleFilterChange(value, "taggedContent")
                  }
                >
                  <Option value="">All</Option>
                  <Option value="Y">Yes</Option>
                  <Option value="N">No</Option>
                </Select>
              </Col>
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Trending Content
                  </h5>
                </Divider>
                <Select
                  placeholder="Trending Content"
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.filters["trendingContent"]}
                  onChange={value =>
                    props.handleFilterChange(value, "trendingContent")
                  }
                >
                  <Option value="">All</Option>
                  <Option value="Y">Yes</Option>
                  <Option value="N">No</Option>
                </Select>
              </Col>
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Shoppable Content
                  </h5>
                </Divider>
                <Select
                  placeholder="Shoppable Content"
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.filters["shoppableContent"]}
                  onChange={value =>
                    props.handleFilterChange(value, "shoppableContent")
                  }
                >
                  <Option value="">All</Option>
                  <Option value="Y">Yes</Option>
                  <Option value="N">No</Option>
                </Select>
              </Col>
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Duplicate Content
                  </h5>
                </Divider>
                <Select
                  placeholder="Duplicate Content"
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.filters["duplicateContent"]}
                  onChange={value =>
                    props.handleFilterChange(value, "duplicateContent")
                  }
                >
                  <Option value="">All</Option>
                  <Option value="Y">Yes</Option>
                  <Option value="N">No</Option>
                </Select>
              </Col>
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Duetable Content
                  </h5>
                </Divider>
                <Select
                  placeholder="Duetable Content"
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.filters["duetable"]}
                  onChange={value =>
                    props.handleFilterChange(value, "duetable")
                  }
                >
                  <Option value="">All</Option>
                  <Option value="Y">Yes</Option>
                  <Option value="N">No</Option>
                </Select>
              </Col>
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Review Status
                  </h5>
                </Divider>
                <Select
                  placeholder="Review Status"
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.filters["reviewDone"]}
                  onChange={value =>
                    props.handleFilterChange(value, "reviewDone")
                  }
                >
                  <Option value="">All</Option>
                  <Option value="Y">Done</Option>
                  <Option value="N">Not Done</Option>
                </Select>
              </Col>
              {props.currentUser.jotUserType < 3 &&
                props.currentUser.jotUserType !== 6 && (
                  <Col lg="2" md="4">
                    <Divider>
                      <h5 className="font-size-11 mb-0 text-muted">
                        Verified Profiles
                      </h5>
                    </Divider>
                    <Select
                      placeholder="Verified Profiles"
                      allowClear={true}
                      style={{ width: "100%" }}
                      defaultValue={props.filters["verifiedProfiles"]}
                      onChange={value =>
                        props.handleFilterChange(value, "verifiedProfiles")
                      }
                    >
                      <Option value="">All</Option>
                      <Option value="Y">True</Option>
                      <Option value="N">False</Option>
                    </Select>
                  </Col>
                )}
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Trending Entity Id
                  </h5>
                </Divider>
                <Col span={24}>
                  <Input
                    defaultValue={props.filters["trendingEntityId"]}
                    placeholder={"Trending Entity Id"}
                    onChange={e =>
                      props.handleFilterChange(
                        e.target.value.trim(),
                        "trendingEntityId"
                      )
                    }
                    allowClear={true}
                    name="trendingEntityId"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Col>
              <Col lg="1" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Page Size</h5>
                </Divider>
                <Select
                  allowClear={true}
                  defaultValue={props.filters["pageSize"]}
                  onChange={value => {
                    props.handleFilterChange(value, "pageSize")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value={10}>10</Option>
                  <Option value={50}>50</Option>
                  <Option value={100}>100</Option>
                </Select>
              </Col>
              <Col lg="3" className="mt-5">
                <Checkbox
                  onChange={e => {
                    props.handleFilterChange(e.target.checked, "isAssetUsed")
                  }}
                  checked={props.filters["isAssetUsed"]}
                >
                  {" "}
                  Asset Used{" "}
                </Checkbox>
                <Checkbox
                  onChange={e => {
                    props.handleFilterChange(e.target.checked, "reModeration")
                  }}
                  checked={props.filters["reModeration"]}
                >
                  {" "}
                  Re Moderation{" "}
                </Checkbox>
              </Col>

              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Input Zone</h5>
                </Divider>
                <ReactSelect
                  // defaultInputValue={props.filters["subType"]}
                  value={
                    props.allZoneList?.filter(
                      zone => zone?.zone_uuid === props.filters["inputZone"]
                    )[0] || ""
                  }
                  style={{ width: "100%" }}
                  isMulti={false}
                  options={props.allZoneList || []}
                  formatOptionLabel={formatOptionLabel}
                  onChange={value =>
                    props.handleFilterChange(value.zone_uuid, "inputZone")
                  }
                  placeholder="select input zone..."
                />
              </Col>

              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Active Zone</h5>
                </Divider>
                <ReactSelect
                  // defaultInputValue={props.filters["subType"]}
                  value={
                    props.allZoneList?.filter(
                      zone => zone?.zone_uuid === props.filters["activeZone"]
                    )[0] || ""
                  }
                  style={{ width: "100%" }}
                  isMulti={false}
                  options={props.allZoneList || []}
                  formatOptionLabel={formatOptionLabel}
                  onChange={value =>
                    props.handleFilterChange(value.zone_uuid, "activeZone")
                  }
                  placeholder="select active zone..."
                />
              </Col>

              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Type</h5>
                </Divider>
                <Col span={24}>
                  <Select
                    allowClear={true}
                    placeholder="Type"
                    defaultValue={props.filters["type"]}
                    onChange={value => {
                      props.handleFilterChange(value, "type")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="">All</Option>
                    <Option value="ENTERTAINMENT">ENTERTAINMENT</Option>
                    <Option value="MEME">MEME</Option>
                    <Option value="NEWS">NEWS</Option>
                    <Option value="VIDEO">VIDEO</Option>
                    <Option value="GREETING">GREETING</Option>
                  </Select>
                </Col>
              </Col>
              <Col lg="4" md="8">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    ACR Meta Created Date
                  </h5>
                </Divider>
                <Input.Group compact={true}>
                  <Row className="w-100">
                    <Col className="d-flex">
                      <DatePicker
                        defaultValue={
                          props.filters["acrMetaCreatedDateFrom"]
                            ? dayjs.unix(
                                props.filters["acrMetaCreatedDateFrom"] / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        placeholder="From Date"
                        onChange={(date, dateString) => {
                          props.handleFilterChange(
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null,
                            "acrMetaCreatedDateFrom"
                          )
                        }}
                        style={{ width: "100%" }}
                      />
                      <DatePicker
                        defaultValue={
                          props.filters["acrMetaCreatedDateTo"]
                            ? dayjs.unix(
                                props.filters["acrMetaCreatedDateTo"] / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        placeholder="To Date"
                        onChange={(date, dateString) => {
                          props.handleFilterChange(
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null,
                            "acrMetaCreatedDateTo"
                          )
                        }}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </Input.Group>
              </Col>
              <Col lg="2" md="3">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    ACR Meta Label
                  </h5>
                </Divider>
                <Input.Group compact={true}>
                  <Input
                    defaultValue={props.filters["acrMetaLabel"]}
                    placeholder={"Label Name"}
                    onChange={e =>
                      props.handleFilterChange(
                        e.target.value.trim(),
                        "acrMetaLabel"
                      )
                    }
                    allowClear={true}
                    name="acrMetaLabel"
                  />
                </Input.Group>
              </Col>
              <Col lg="2" md="3">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">
                    Blocked Reason
                  </h5>
                </Divider>
                <Input.Group compact={true}>
                  <Input
                    defaultValue={props.filters["blockedReason"]}
                    placeholder={"Blocked Reason"}
                    onChange={e =>
                      props.handleFilterChange(
                        e.target.value.trim(),
                        "blockedReason"
                      )
                    }
                    allowClear={true}
                    name="blockedReason"
                  />
                </Input.Group>
              </Col>
            </Row>
          )}
        </>
      ) : null}
    </React.Fragment>
  )
}

export default withRouter(ContentDrawer)
