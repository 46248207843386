import React, { useState } from "react"
import _ from "lodash"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  InputGroup,
} from "reactstrap"
import moment from "moment"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SimpleBar from "simplebar-react"
import { LANGUAGES } from "../../constants/uiConstants"
import Select from "react-select"
import Flatpickr from "react-flatpickr"
import toastr from "toastr"

const UpsertForm = props => {
  const [params, setPatams] = useState({})

  const _onSubmit = formData => {
    const { dateRange, langCode, categories, emo, ...restParams } = params
    const [startDate, endDate] = dateRange || []
    if (startDate && endDate) {
      const languages =
        langCode && langCode.length > 0
          ? langCode.map(lang => lang.value)
          : undefined
      const broadCategories =
        categories && categories.length > 0
          ? categories.map(cat => cat.value)
          : undefined
      const emotions =
        emo && emo.length > 0 ? emo.map(lang => lang.value) : undefined
      const { stat, ...rest } = formData
      let data = {
        ...rest,
        status: formData.stat,
        languages,
        broadCategories,
        emotions,
        startTime: startDate && startDate.valueOf(),
        endTime: endDate && endDate.valueOf(),
      }
      props.onSubmit(_.deepClean(data))
    } else toastr.error("Please Select Start and End Dates")
  }

  const _getDefault = (perms, reff) => {
    let array = []
    reff.forEach(p => {
      if (perms.includes(p.value)) {
        array.push(p)
      }
    })
    return array
  }

  const { jotUserType, permissions } = props.currentUser
  const isEditor =
    jotUserType &&
    (jotUserType === 1 ||
      jotUserType === 2 ||
      (permissions && permissions.includes("EDIT_MANAGE_CAMPAIGN")))

  const startedDate =
    props.campaign &&
    props.campaign.start_time &&
    moment(parseInt(props.campaign.start_time)).format("YYYY-MM-DD")
  const endedDate =
    props.campaign &&
    props.campaign.end_time &&
    moment(parseInt(props.campaign.end_time)).format("YYYY-MM-DD")
  return (
    <React.Fragment>
      <SimpleBar style={{ height: "70vh" }}>
        <Card className>
          <CardBody>
            <h4 className="card-title">
              {props.action === "create" ? "Create" : "Update"} Campaign
            </h4>
            <AvForm onValidSubmit={(e, values) => _onSubmit(values)}>
              <Row>
                {props.action === "create" && (
                  <React.Fragment>
                    <Col lg="6">
                      <FormGroup row>
                        <Label className="col-form-label">Campaign Name</Label>
                        <AvField
                          name="campaignName"
                          placeholder="Enter campaign name"
                          type="text"
                          errorMessage="Please enter campaign name"
                          validate={{
                            required: { value: true },
                          }}
                          value={props.campaign && props.campaign.campaign_name}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup row>
                        <Label className="col-form-label">Ad Name</Label>

                        <AvField
                          name="adName"
                          placeholder="Enter ad name"
                          type="text"
                          errorMessage="Please enter ad name"
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={props.campaign && props.campaign.ad_name}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup row>
                        <Label className="col-form-label">Channel</Label>

                        <AvField
                          name="channel"
                          placeholder="Enter channel name"
                          type="text"
                          errorMessage="Please enter channel name"
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={props.campaign && props.campaign.channel}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup row>
                        <Label className="col-form-label">Ad set</Label>

                        <AvField
                          name="adSet"
                          placeholder="Enter ad set"
                          type="text"
                          errorMessage="Please enter ad set"
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={props.campaign && props.campaign.ad_set}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup row>
                        <Label className="col-form-label">Media source</Label>

                        <AvField
                          name="mediaSource"
                          placeholder="Enter ad set"
                          type="text"
                          errorMessage="Please enter ad set"
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={props.campaign && props.campaign.mediaSource}
                        />
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                )}
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Status</Label>

                    <AvField
                      name="stat"
                      placeholder="Enter ad set"
                      type="text"
                      errorMessage="Please enter ad set"
                      // validate={{
                      //   required: { value: true },
                      // }}
                      value={props.campaign && props.campaign.status}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Languages</Label>

                    <Select
                      isMulti={true}
                      defaultValue={
                        props.campaign &&
                        props.campaign.languages &&
                        _getDefault(props.campaign.languages, LANGUAGES)
                      }
                      options={LANGUAGES}
                      onChange={val => setPatams({ ...params, langCode: val })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Emotions</Label>

                    <Select
                      isMulti={true}
                      options={[
                        {
                          label: "emotion",
                          value: "emotion",
                        },
                        {
                          label: "test",
                          value: "test",
                        },
                      ]}
                      defaultValue={
                        props.campaign &&
                        props.campaign.emotion &&
                        _getDefault(props.campaign.emotion, [
                          {
                            label: "emotion",
                            value: "emotion",
                          },
                          {
                            label: "test",
                            value: "test",
                          },
                        ])
                      }
                      onChange={val => setPatams({ ...params, emo: val })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row>
                    <Label className="col-form-label">Broad Categories</Label>

                    <Select
                      isMulti={true}
                      defaultValue={
                        props.campaign &&
                        props.campaign.broad_categories &&
                        _getDefault(props.campaign.broad_categories, [
                          {
                            label: "broad",
                            value: "broad",
                          },
                          {
                            label: "test",
                            value: "test",
                          },
                        ])
                      }
                      options={[
                        {
                          label: "broad",
                          value: "broad",
                        },
                        {
                          label: "test",
                          value: "test",
                        },
                      ]}
                      onChange={val =>
                        setPatams({ ...params, categories: val })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="status" className="col-form-label col-lg-2">
                      Date Range
                    </Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="Start Date-End Date"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                          defaultDate: [startedDate, endedDate],
                        }}
                        onChange={val =>
                          setPatams({ ...params, dateRange: val })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  type="submit"
                  color="primary"
                  className="mx-2"
                  disabled={!isEditor}
                >
                  {props.action === "create" ? "Create" : "Update"}
                </Button>
                <Button color="danger" onClick={props.onCancel}>
                  Cancel
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </SimpleBar>
    </React.Fragment>
  )
}

export default UpsertForm
