import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import toastr from "toastr"
import _ from "lodash"
import moment from "moment"
import "moment-timezone"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, {
  TextField,
  Select,
  DateTimePicker,
} from "../ValidatorForm"
import { Tooltip } from "antd"
import "./ads.styles.css"
import dayjs from "dayjs"

export default class AdsProfileForm extends ErrorBoundComponent {
  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>
            {`${this.props?.data?.id ? "Edit" : "Add"} Ads Profile`}
            <ValidatorForm
              onSubmit={this._onSubmit}
              layout={"vertical"}
              {...this._formLayout}
            >
              <Row>
                <Col sm={12} md={6}>
                  <TextField
                    label="Name"
                    placeholderLabel="Enter Name"
                    field={"name"}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    defaultValue={this.props?.data?.name || ""}
                  />
                </Col>
                <Col sm={12} md={6} className="font_weight_normal">
                  <Select
                    label="Status"
                    placeholderLabel="Select Status"
                    field="status"
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    options={[
                      { value: "ACTIVE", label: "Active" },
                      { value: "DRAFT", label: "Draft" },
                      { value: "INACTIVE", label: "Inactive" },
                    ]}
                    defaultValue={this.props?.data?.status || "ACTIVE"}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <TextField
                    label="Premium 0"
                    placeholderLabel="Enter Premium 0"
                    field={"premium_0"}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    defaultValue={this.props?.data?.premium_0 || ""}
                    inputType="number"
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TextField
                    label="Premium 1"
                    placeholderLabel="Enter Premium 1"
                    field={"premium_1"}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    defaultValue={this.props?.data?.premium_1 || ""}
                    inputType="number"
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TextField
                    label="Position 0"
                    placeholderLabel="Enter Position 0"
                    field={"p0"}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    defaultValue={this.props?.data?.p0 || ""}
                    inputType="number"
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TextField
                    label="Position 1"
                    placeholderLabel="Enter Position 1"
                    field={"p1"}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    defaultValue={this.props?.data?.p1 || ""}
                    inputType="number"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <TextField
                    label="Position 2"
                    placeholderLabel="Enter Position 2"
                    field={"p2"}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    defaultValue={this.props?.data?.p2 || ""}
                    inputType="number"
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TextField
                    label="Distance"
                    placeholderLabel="Enter Distance"
                    field={"d"}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    defaultValue={this.props?.data?.d || ""}
                    inputType="number"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <DateTimePicker
                    style={{ padding: "9px", width: "100%" }}
                    label="Start Time"
                    field="start_time"
                    defaultValue={
                      this.props.data && this.props.data.start_time
                        ? dayjs(this.props.data.start_time)
                        : null
                    }
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <DateTimePicker
                    style={{ padding: "9px", width: "100%" }}
                    label={
                      <div>
                        End Time
                        <Tooltip title="If you do not select any date it will be considered as infinite time period">
                          <span>
                            {" "}
                            <i class="bx bx-info-circle"></i>
                          </span>
                        </Tooltip>
                      </div>
                    }
                    field="end_time"
                    size="medium"
                    defaultValue={
                      this.props.data &&
                      this.props.data.end_time &&
                      this.props.data.end_time != -1
                        ? dayjs(this.props.data.end_time)
                        : null
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="flex" style={{ marginTop: "10px" }}>
                    <div className="d-flex justify-content-end">
                      <Button htmlType="submit" color="primary">
                        {this.props?.data?.id ? "Update" : "Create"}
                      </Button>
                      <Button
                        onClick={() => {
                          this.props.onCancel()
                          // this._resetState()
                        }}
                        color="danger"
                        className="mx-2"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </ValidatorForm>
          </CardTitle>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    if (!formData.name) {
      return toastr.error("Name is mandatory.")
    }
    if (!formData.status) {
      return toastr.error("Status is mandatory.")
    }
    if (!formData.premium_0 && formData.premium_0 != 0) {
      return toastr.error("Premium 0 is mandatory.")
    }
    if (!formData.premium_1 && formData.premium_1 != 0) {
      return toastr.error("Premium 1 is mandatory.")
    }
    if (!formData.p0 && formData.p0 != 0) {
      return toastr.error("Position 0 is mandatory.")
    }
    if (!formData.p1 && formData.p1 != 0) {
      return toastr.error("Position 1 is mandatory.")
    }
    if (!formData.p2 && formData.p2 != 0) {
      return toastr.error("Position 2 is mandatory.")
    }
    if (!formData.d && formData.d != 0) {
      return toastr.error("Distance is mandatory.")
    }
    if (formData.premium_0 > 100 || formData.premium_0 < -1) {
      return toastr.error("Premium 0 value should be between -1 to 100.")
    }
    if (formData.premium_1 > 100 || formData.premium_1 < -1) {
      return toastr.error("Premium 1 value should be between -1 to 100.")
    }
    if (formData.p0 > 100 || formData.p0 < -1) {
      return toastr.error("Position 0 value should be between -1 to 100.")
    }
    if (formData.p1 > 100 || formData.p1 < -1) {
      return toastr.error("Position 1 value should be between -1 to 100.")
    }
    if (formData.p2 > 100 || formData.p2 < -1) {
      return toastr.error("Position 2 value should be between -1 to 100.")
    }
    if (formData.d > 100 || formData.d < -1) {
      return toastr.error("Distance value should be between -1 to 100.")
    }
    if (
      formData.premium_0 == formData.premium_1 ||
      formData.premium_0 == formData.p0 ||
      formData.premium_0 == formData.p1 ||
      formData.premium_0 == formData.p2 ||
      formData.premium_1 == formData.p0 ||
      formData.premium_1 == formData.p1 ||
      formData.premium_1 == formData.p2 ||
      formData.p0 == formData.p1 ||
      formData.p0 == formData.p2 ||
      formData.p1 == formData.p2
    ) {
      return toastr.error("All Position Data should be different.")
    }

    let end_time = -1

    if (!formData.start_time) {
      return toastr.error("Start Time is mandatory.")
    }

    let start_time = dayjs(formData.start_time).valueOf()

    if (formData.end_time) {
      end_time = formData.end_time
    }

    if (end_time != -1) {
      end_time = dayjs(end_time).valueOf()
    }

    if (end_time != -1 && start_time > end_time) {
      return toastr.error("Start Time can not be greater than End Time.")
    }

    const payload = {
      name: formData.name,
      status: formData.status,
      premium_0: formData.premium_0,
      premium_1: formData.premium_1,
      p0: formData.p0,
      p1: formData.p1,
      p2: formData.p2,
      d: formData.d,
      start_time,
      end_time,
    }

    if (this.props?.data?.id) {
      payload.id = this.props.data.id
    }
    this.props.onSubmit(payload)
  }

  render() {
    return this._form()
  }
}
