import React, { useState } from "react"
import moment from "moment"
require("moment-duration-format")

export default function Bar(props) {
  const { duration, curTime, curEndTime, curStartTime, onTimeUpdate } = props

  const curPercentage = (curTime / duration) * 100

  const curPercentageStart = (curEndTime / duration) * 100
  const curPercentageEnd = (curStartTime / duration) * 100

  function formatDuration(duration) {
    return moment.duration(duration, "seconds").format("mm:ss", { trim: false })
  }

  function calcClickedTime3(e, type) {
    var clickPositionInPage = e.pageX

    const bar = document.querySelector(".bar__progress")
    const barStart = bar.getBoundingClientRect().left + window.scrollX
    const barWidth = bar.offsetWidth
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = duration / barWidth
    let returnTime = timePerPixel * clickPositionInBar
    if (returnTime > curEndTime && type == "start") {
      return curEndTime
    }
    if (returnTime < curStartTime && type == "end") {
      return curStartTime
    }
    if (returnTime < 0) {
      returnTime = 0
    } else if (returnTime > duration) {
      returnTime = duration
    }
    return returnTime
  }

  function handleTimeDrag(e, type) {
    onTimeUpdate(calcClickedTime3(e, type), type)

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcClickedTime3(eMove, type), type)
    }

    document.addEventListener("mousemove", updateTimeOnMove)

    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", updateTimeOnMove)
    })
  }

  return (
    <div className="bar">
      <span className="bar__time">{formatDuration(curTime)}</span>
      <div
        className="bar__progress"
        style={{
          background: `linear-gradient(to right, white ${curPercentageEnd}%, orange ${curPercentageEnd}%,orange ${curPercentageStart}% ,white ${curPercentageStart}%)`,
        }}
      >
        <span
          onMouseDown={e => handleTimeDrag(e, "start")}
          className="bar__progress__knob_start"
          style={{ left: `${curPercentageEnd - 1}%` }}
        >
          <i className="fas fa-angle-double-left audioarrow"></i>
        </span>
        <span
          onMouseDown={e => handleTimeDrag(e, "play")}
          className="bar__progress__knob"
          style={{ left: `${curPercentage - 4}%` }}
        />
        <span
          onMouseDown={e => handleTimeDrag(e, "end")}
          className="bar__progress__knob_end"
          style={{ left: `${curPercentageStart - 7}%` }}
        >
          <i className="fas fa-angle-double-right audioarrow"></i>
        </span>
      </div>
      <span className="bar__time">{formatDuration(duration)}</span>
    </div>
  )
}
