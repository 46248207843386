import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import BulkUploadReportList from "../../components/BulkReportList/bulkReportList"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getBulkReportList } from "appRedux/slices/activity"
import {
  createCommunityManager,
  assignCommunityManager,
  updateCommunityUser,
  deleteUser,
} from "../../clientServices/communityService"

import {
  getUser,
  getManagersList,
  getCommunityUserByType,
  getUnassignedManagersList,
  getLeads,
  resetState,
} from "appRedux/slices/community"
import Auxiliary from "../../util/Auxiliary"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import ManagerListPage from "./managerListPage"
import Select from "react-select"
import "../Comment/browseComment.style.css"
import Button from "reactstrap"

class CommunityManager extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      filters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 1000,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        subPageSize:
          this.props.queryParams && this.props.queryParams["subPageSize"]
            ? this.props.queryParams["subPageSize"]
            : 10,
        subCurrentPage:
          this.props.queryParams && this.props.queryParams["subCurrentPage"]
            ? this.props.queryParams["subCurrentPage"]
            : 1,
      },
    }
  }
  componentDidMount() {
    if (!this.props.common.error) {
      this.getInitialData()
      this.props.dispatch(getLeads({}))
    }
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/community/manager/management?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getCommunityUserByType(_.deepClean(this.state.filters))
        )
      }
    )
  }

  _onSubPageChange = (value, userId) => {
    let id = userId || this.props.currentUser.userId
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["subCurrentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/community/manager/management?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getManagersList({ userId: id, ..._.deepClean(this.state.filters) })
        )
      }
    )
  }

  getInitialData = () => {
    if (
      this.props.currentUser &&
      [12, 32].includes(this.props.currentUser.jotUserType)
    ) {
      this.props.dispatch(
        getManagersList({
          userId: this.props.currentUser.userId,
          ..._.deepClean(this.state.filters),
        })
      )
    }
    if (
      this.props.currentUser &&
      [1, 2, 11, 31].includes(this.props.currentUser.jotUserType)
    ) {
      this.props.dispatch(
        getCommunityUserByType(_.deepClean(this.state.filters))
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  getManagersList = id => {
    this.props.dispatch(getManagersList(id))
  }

  render() {
    const { communityUser = [] } = this.props
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <ManagerListPage
          user={this.props.currentUser}
          type={
            this.props.currentUser &&
            [12, 32].includes(this.props.currentUser.jotUserType)
              ? "lead"
              : "head"
          }
          communityManagerData={
            this.props.currentUser &&
            [12, 32].includes(this.props.currentUser.jotUserType)
              ? this.props.communityManagers
              : communityUser
          }
          getManagersList={this.getManagersList}
          getInitialData={this.getInitialData}
          total={this.props.total}
          currentPage={this.state.filters.currentPage}
          pageSize={this.state.filters.pageSize}
          subCurrentPage={this.state.filters.subCurrentPage}
          subPageSize={this.state.filters.subPageSize}
          _onPageChange={this._onPageChange}
          _onSubPageChange={(val, id) => this._onSubPageChange(val, id)}
          history={this.props.history}
        />
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "community.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    reportList: _.get(store, "activityLog.reportList"),
    moderatorList: _.get(store, "cmsUser.userList"),
    communityUser: _.get(store, "community.communityUser"),
    communityManagers: _.get(store, "community.communityManagers"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "community.loading"),
    unassignedManagers: _.get(store, "community.unassignedManagers"),
    managerDataLoading: _.get(store, "community.loading"),
  }
}

export default connect(mapStateToProps)(CommunityManager)
