import React, { Component } from "react"
import { Button, Chip } from "@mui/material"
import {
  Input,
  Select,
  DatePicker,
  Modal,
  Form,
  Radio,
  Tooltip,
  Space,
  Pagination,
} from "antd"
import { Col, Row, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import {
  getContentByInterestAndSubInterest,
  getDtaTaskContentByInterestAndSubInterest,
  getInterest,
  getInterestReviewed,
  updateReviewContent,
} from "clientServices/interestServices"
import { connect } from "react-redux"
import { BiShowAlt } from "react-icons/bi"
import { Upload } from "antd"
import "./manageSeed.css"
import ImportVideos from "./ImportVideos"
import VideoCard from "./Videocard"
import moment from "moment"
import Auxiliary from "util/Auxiliary"
import {
  CURATED_TYPE_MAPPING,
  curationFilters,
  ETHNICITY,
  LANGUAGES,
} from "constants/uiConstants"
import _ from "lodash"
import toastr from "toastr"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getSourceDetails } from "clientServices/sourceService"
import Chips from "components/CommonFilter/chips"
import { removeEmptyValuesFromObject } from "util/common"
const { Option } = Select
const { TextArea } = Input

class CurationList extends ErrorBoundComponent {
  state = {
    filter: {
      isServable: "servable",
      metric: "absolute",
      interests_review_status: true,
    },
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
    },
    formData: {},
    data: [],
    addVideosData: [],
    seedVideosSearchText: "",
    showImportVideos: false,
    fileList: [],
    agencyCurrentPage: 0,
    crawlerCurrentPage: 0,
    agencyPageSize: 20,
    crawlerPageSize: 20,
    loading: true,
  }

  props = {
    onRemove: file => {
      const index = this.state.fileList.indexOf(file)
      const newFileList = this.state.fileList.slice()
      newFileList.splice(index, 1)
      this.setState({
        fileList: newFileList,
      })
      // setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...this.state.fileList, file])
      return false
    },
    customRequest: () => {},
    fileList: this.state.fileList,
  }

  async componentDidMount() {
    const subinterestdata = await getInterest({ entity_type: "sub_interest" })
    let subInterestsId = []
    let subintereststatus = {}

    for (let j = 0; j < subinterestdata?.result?.data?.length; j++) {
      subintereststatus[subinterestdata?.result?.data[j].id] = {
        status: subinterestdata?.result?.data[j].is_active,
        name: subinterestdata?.result?.data[j].name,
      }
      subInterestsId.push(subinterestdata?.result?.data[j].id)
    }

    const getInterestReviewedCount = await getInterestReviewed()
    const interestdata = await getInterest({
      entity_type: "interest",
    })
    const subinterestData = await getInterest({
      entity_type: "interest",
    })

    this.setState({ loading: true })

    this.setState({
      subInterestStatusList: subintereststatus || {},
      initialinterestData: interestdata?.result?.data || [],
      initialsubinterestData: subInterestsId || [],
      interestData: interestdata?.result?.data || [],
      subinterestData: subInterestsId || [],
      allSubinterests: subinterestdata?.result?.data || [],
      getInterestReviewedCount,
    })

    try {
      let filters = _.clone(this.state.filters)
      const data = await getDtaTaskContentByInterestAndSubInterest({ filters })
      this.setState({
        filter: { ...this.state.filter, ...this.props },
        loading: false,
        data: data?.data?.contents,
        total: data?.data?.total,
      })
    } catch (error) {
      this.setState({ loading: false })
    }

    if (this.props.showAddVideos) {
      let formData = { ...this.state.formData }
      formData = {
        ...formData,
        mode: this.state.selectedVideoDetails?.is_private,
      }
      this.setState({
        showAddVideosPopup: true,
      })
      return
    }
  }

  _handleAddVideos = type => {
    this.setState({
      showAddVideosPopup: true,
      importType: type,
    })
  }

  getReviewedNumber = async () => {
    const getInterestReviewedCount = await getInterestReviewed()
    this.setState({
      getInterestReviewedCount,
    })
  }

  _handleVideoDetail = async data => {
    let interest, subInterest
    if (data.active_interests) {
      interest = data.active_interests[0]
      if (data.active_sub_interests) {
        subInterest = data.active_sub_interests[0]
      }
    }
    if (data.input_interests) {
      interest = data.input_interests[0]
      if (data.input_sub_interests) {
        subInterest = data?.input_sub_interests[0]
      }
    }
    let interestid = this.state.interestData.filter(
      item => item.id === interest
    )
    let subinterestid = this.state.allSubinterests.filter(
      item => item.id === subInterest
    )

    let markAsSeed =
      data.is_seed_video === true
        ? true
        : data.is_seed_video === false
        ? false
        : ""

    this.setState({
      showVideoDetailPopup: true,
      selectedVideoDetails: data,
      formData: {
        mode: interestid.length > 0 ? interestid[0].mode : undefined,
        type: interestid.length > 0 ? interestid[0].mode : undefined,
        interest: interestid.length > 0 ? interestid[0].id : undefined,
        subinterest: subinterestid.length > 0 ? subinterestid[0].id : undefined,
        mark_as_seed: markAsSeed,
      },
      interestData: this.state.initialinterestData || [],
      subinterestData: interestid[0]?.subInterests || [],
      isVideoEnded: false,
    })
  }

  _searchContent = async () => {
    try {
      let filters = { ...this.state.filters }
      this.setState({ loading: true })
      const data = await getDtaTaskContentByInterestAndSubInterest({
        filters,
      })
      this.setState({
        loading: false,
        data: data?.data?.contents,
        total: data?.data?.total,
        filters,
      })
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  handleFilterChange = async (val, type) => {
    let filters = { ...this.state.filters }
    filters[type] = val
    this.setState({
      filters,
    })
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({
      showFilters: false,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: curationFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => this._closeFilters()
    )
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _renderFilters = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col span={22}>
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this.handleFilterChange}
                type="curation"
                toggleFilters={this._toggleFilters}
                interestData={this.state.interestData}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  _renderVideo = video => {
    return (
      <div
        onClick={() => {
          this._handleVideoDetail(video)
        }}
      >
        <VideoCard
          key={video.content_uuid}
          parity={video.parity}
          review={true}
          details={true}
          content={video}
          metric={this.state.filter.metric}
        ></VideoCard>
      </div>
    )
  }

  _getModerationImage = content => {
    if (content.reviewed) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/review.jpg"
    } else if (content.qc_done) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/qcDone.jpg"
    } else if (content.moderation_level) {
      switch (content.moderation_level) {
        case 2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-approved.jpg"

        case 3:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-approved.jpg"

        case -1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-disabled.jpg"

        case 0:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-disabled.jpg"

        case -2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/machine-disabled.jpg"

        case 1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/unmoderated.jpg"

        default:
          return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
      }
    } else {
      return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
    }
  }
  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    this.setState({ formData }, () => {
      console.log(this.state)
    })
  }

  activeInterest = async () => {
    if (
      this.state.formData.type == "" ||
      this.state.formData.interest == "" ||
      this.state.formData.subinterest == ""
    ) {
      toastr.error("All fields are mandatory")
      return
    }

    const sourceData = await getSourceDetails(
      this.state.selectedVideoDetails.source_id
    )

    const data = removeEmptyValuesFromObject({
      content_uuid: this.state.selectedVideoDetails.content_uuid,
      taxonomy_task_info: this.state.selectedVideoDetails.taxonomy_task_info,
      taxonomy_meta: {
        content_uuid: this.state.selectedVideoDetails.content_uuid,
        details: {
          ...this.state.selectedVideoDetails.taxonomy_meta,
          ...{
            video_additional_info: {
              is_bharat: this.state.formData.is_bharat,
              ethnicity: this.state.formData.ethnicity,
              people_count: this.state.formData.people_count,
            },
          },
        },
      },
      active_interests: [this.state.formData.interest],
      active_sub_interests: this.state.formData.subinterest
        ? [this.state.formData.subinterest]
        : null,
      input_interests: this.state.selectedVideoDetails.input_interests
        ? [
            ...new Set([
              ...this.state.selectedVideoDetails.input_interests,
              ...[this.state.formData.interest],
            ]),
          ]
        : [this.state.formData.interest],

      input_sub_interests: this.state.selectedVideoDetails.input_sub_interests
        ? [
            ...new Set([
              ...this.state.selectedVideoDetails.input_sub_interests,
              ...[this.state.formData.subinterest],
            ]),
          ]
        : this.state.formData.subInterest
        ? [this.state.formData.subinterest]
        : null,
      is_seed_video: this.state.formData.mark_as_seed,
      interests_review_status: true,
      interests_review_action:
        this.state.formData.is_bharat == "N" ? "REVIEWED" : "ACCEPTED",
      lang_code:
        this.state.formData.lang_code ||
        this.state.selectedVideoDetails.lang_code,
      inReview: true,
      sourceData: sourceData,
    })

    let contentReview = await updateReviewContent(data, "Approved")

    try {
      this.setState({ loading: true })
      let filters = _.clone(this.state.filters)
      const data = await getDtaTaskContentByInterestAndSubInterest({ filters })
      this.getReviewedNumber()
      this.setState(
        {
          filter: { ...this.state.filter, ...this.props },
          loading: false,
          showVideoDetailPopup: false,
          data: data?.data?.contents,
          isVideoEnded: false,
        },
        () => {
          this._handleVideoDetail(this.state.data[0])
        }
      )
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  removeInterest = async () => {
    const sourceData = await getSourceDetails(
      this.state.selectedVideoDetails.source_id
    )

    const data = removeEmptyValuesFromObject({
      content_uuid: this.state.selectedVideoDetails.content_uuid,
      taxonomy_task_info: this.state.selectedVideoDetails.taxonomy_task_info,
      interests_review_status: true,
      interest_reject_reason: this.state.interest_reject_reason,
      taxonomy_meta: {
        content_uuid: this.state.selectedVideoDetails.content_uuid,
        details: {
          ...this.state.selectedVideoDetails.taxonomy_meta,
          ...{
            video_additional_info: {
              is_bharat: this.state.formData.is_bharat,
              ethnicity: this.state.formData.ethnicity,
              people_count: this.state.formData.people_count,
            },
          },
        },
      },
      interests_review_action: "REJECTED",
      inReview: true,
      lang_code:
        this.state.formData.lang_code ||
        this.state.selectedVideoDetails.lang_code,
      active_interests: [this.state.formData.interest],
      active_sub_interests: [this.state.formData.subinterest],
      sourceData: sourceData,
    })

    let contentReview = await updateReviewContent(data, "Rejected")
    try {
      this.setState({ loading: true })
      let filters = _.clone(this.state.filters)
      const data = await getDtaTaskContentByInterestAndSubInterest({ filters })
      this.getReviewedNumber()
      this.setState(
        {
          filter: { ...this.state.filter, ...this.props },
          loading: false,
          showVideoDetailPopup: false,
          data: data?.data?.contents,
          isVideoEnded: false,
        },
        () => {
          this._handleVideoDetail(this.state.data[0])
        }
      )
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  skipInterest = async () => {
    if (
      this.state.formData.type == "" ||
      this.state.formData.interest == "" ||
      this.state.formData.subinterest == ""
    ) {
      toastr.error("All fields are mandatory")
      return
    }
    const sourceData = await getSourceDetails(
      this.state.selectedVideoDetails.source_id
    )
    let contentReview = await updateReviewContent(
      {
        content_uuid: this.state.selectedVideoDetails.content_uuid,
        taxonomy_task_info: this.state.selectedVideoDetails.taxonomy_task_info,
        interests_review_status: true,
        interests_review_action: "REVIEWED",
        inReview: true,
        lang_code:
          this.state.formData.lang_code ||
          this.state.selectedVideoDetails.lang_code,
        sourceData: sourceData,
        active_interests: [this.state.formData.interest],
        active_sub_interests: [this.state.formData.subinterest],
      },
      "Reviewed"
    )
    try {
      this.setState({ loading: true })
      let filters = _.clone(this.state.filters)
      const data = await getDtaTaskContentByInterestAndSubInterest({ filters })
      this.getReviewedNumber()
      this.setState(
        {
          filter: { ...this.state.filter, ...this.props },
          loading: false,
          showVideoDetailPopup: false,
          data: data?.data?.contents,
          isVideoEnded: false,
        },
        () => {
          this._handleVideoDetail(this.state.data[0])
        }
      )
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  _renderVideoDetailsPopup = (
    isAdmin,
    hasSeedPermission,
    interestandsubinterestPermission
  ) => {
    return (
      <Modal
        width={1000}
        title=""
        // style={{ padding: "17px" }}
        open={this.state.showVideoDetailPopup}
        footer={null}
        onCancel={() => {
          this.setState({ showVideoDetailPopup: false })
          Modal.destroyAll()
        }}
        destroyOnClose
      >
        <Modal
          title="Reject Reason"
          width={700}
          open={this.state.rejectReasonPopup}
          onCancel={() => {
            this.setState({ rejectReasonPopup: false })
            Modal.destroyAll()
          }}
          onOk={() => {
            this.removeInterest()
            this.setState({
              rejectReasonPopup: false,
              showVideoDetailPopup: false,
            })
          }}
          destroyOnClose
          style={{ padding: "17px" }}
        >
          <Radio.Group
            onChange={e => {
              this.setState({
                interest_reject_reason: e.target.value,
              })
            }}
            style={{ display: "flex" }}
          >
            {/* <Space direction="horizontal"> */}
            <div>
              <Space direction="vertical">
                <Radio value="interest_mismatch">Interest Mismatch</Radio>
                <Radio value="long_content">Long Content</Radio>
                <Radio value="small_duration">Small Duration</Radio>
                <Radio value="abrupt_end">Abrupt End</Radio>
                <Radio value="language_mismatch">Language Mismatch</Radio>
                <Radio value="video_quality">Video Quality</Radio>
                <Radio value="glamour_gt_4">Glamour &gt; 4</Radio>
                <Radio value="audio_quality">Audio Quality</Radio>
                <Radio value="big_border">Big Border</Radio>
                <Radio value="colored_border">Colored Border</Radio>
                <Radio value="promotion_ad">Promotion/Ad</Radio>
              </Space>
            </div>
            <div>
              <Space direction="vertical">
                <Radio value="competitor_logo">Competitor Logo</Radio>
                <Radio value="horizontal_video">Horizontal Video</Radio>
                <Radio value="subscription_content">Subscription Content</Radio>
                <Radio value="text_ratio">Text Ratio</Radio>
                <Radio value="unreadable_text">Unreadable Text</Radio>
                <Radio value="unrecognizable_faces">Unrecognizable Faces</Radio>
                <Radio value="abusive">Abusive &nbsp; </Radio>
                <Radio value="anti_national">Anti-national</Radio>
                <Radio value="audio_mismatch">Audio Mismatch</Radio>
                <Radio value="background_noise"> Background Noise</Radio>
                <Radio value="others"> Others</Radio>
              </Space>
            </div>
          </Radio.Group>
        </Modal>
        <Modal
          title="Reference Seed Video"
          open={this.state.showSeedVideo}
          onCancel={() => {
            this.setState({ showSeedVideo: false })
            Modal.destroyAll()
          }}
          destroyOnClose
          style={{ padding: "17px" }}
        >
          {" "}
          <VideoCard
            height="700px"
            details={true}
            content={this.state.selectedSeedContent}
            playVideo={true}
            autoPlay={false}
          ></VideoCard>
        </Modal>
        {this.state.selectedVideoDetails && (
          <>
            {" "}
            <div className="">
              <Row style={{ marginBottom: "-15px" }}>
                <Col lg="4">
                  <VideoCard
                    height="700px"
                    details={false}
                    content={this.state.selectedVideoDetails}
                    playVideo={true}
                    autoPlay={true}
                    type="curation"
                    showControls={false}
                    handleDisable={() => {
                      this.setState({
                        isVideoEnded: true,
                      })
                    }}
                  ></VideoCard>
                </Col>
                <Col lg="8">
                  <Row>
                    <Col lg="6">
                      <div style={{ display: "flex" }}>
                        <img
                          src={this._getModerationImage(
                            this.state.selectedVideoDetails
                          )}
                          className="me-3 rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                        <div style={{ fontSize: "11px", marginLeft: "10px" }}>
                          <b>
                            {
                              this.state.selectedVideoDetails?.user_profile
                                ?.name
                            }
                          </b>
                          <div style={{ textAlign: "initial" }}>
                            {
                              CURATED_TYPE_MAPPING[
                                this.state.selectedVideoDetails?.user_profile
                                  ?.user_type
                              ]
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4">
                      <Chip
                        color="secondary"
                        size="small"
                        label={
                          this.state.selectedVideoDetails.source === "AGENCY"
                            ? "AGENCY"
                            : "NON-AGENCY"
                        }
                      />
                    </Col>
                    <Col lg="2">
                      <CopyToClipboard
                        text={this.state.selectedVideoDetails?.content_uuid}
                        onCopy={e => {
                          toastr.success("Copied sucessfully!")
                        }}
                      >
                        <Tooltip title="Copy Content ID">
                          <i
                            className="bx bx-copy"
                            style={{ marginRight: "10px" }}
                            role="button"
                          />
                        </Tooltip>
                      </CopyToClipboard>

                      {this.state.selectedVideoDetails
                        ?.source_blip_content_id && (
                        <Tooltip title="Open seed video">
                          <Link
                            to={`/content/manage?contentUUID=${this.state.selectedVideoDetails?.source_blip_content_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "unset" }}
                          >
                            <BiShowAlt
                              style={{
                                cursor: "pointer",
                                marginBottom: "4px",
                                color: "unset",
                                marginLeft: "5px",
                              }}
                            />
                          </Link>
                        </Tooltip>
                      )}
                      {this.state.selectedVideoDetails?.duplicate_content && (
                        <Tooltip title="Open Original Video">
                          <Link
                            to={`/content/manage?contentUUID=${this.state.selectedVideoDetails?.original_content_uuid}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "unset" }}
                          >
                            <i className="bx bx-shape-circle" role="button" />
                          </Link>
                        </Tooltip>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <label>Bucket</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          showSearch
                          disabled
                          size="large"
                          allowClear={true}
                          onChange={async e => {
                            this._handleFieldChange("type", e)

                            const interestdata = await getInterest({
                              entity_type: "interest",
                              type: e,
                              mode: this.state.formData.mode,
                            })
                            this.setState({
                              interestData: interestdata?.result?.data || [],
                              formData: {
                                ...this.state.formData,
                                ...{
                                  interest: "",
                                  subinterest: "",
                                },
                              },
                            })
                          }}
                          value={this.state.formData.type}
                          style={{ width: "100%" }}
                        >
                          <Option value="">---Choose Bucket---</Option>
                          <Option value="CATEGORY">Category</Option>
                          <Option value="TREND">Trend</Option>
                          <Option value="EVENT">Event</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg="6">
                      <label>Mark as Seed</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          showSearch
                          size="large"
                          allowClear={true}
                          onChange={async e => {
                            this._handleFieldChange("mark_as_seed", e)
                          }}
                          disabled={!isAdmin && !hasSeedPermission}
                          style={{ width: "100%" }}
                          value={this.state.formData.mark_as_seed}
                        >
                          <Option value="">---Choose---</Option>
                          <Option value={true}>True</Option>
                          <Option value={false}>False</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      {" "}
                      <label>Interest</label>
                      <Form.Item>
                        <Select
                          onChange={async e => {
                            this._handleFieldChange("interest", e)
                            const subinterestData = await getInterest({
                              entity_type: "interest",
                              id: e,
                            })
                            this.setState({
                              subinterestData:
                                subinterestData?.result?.data[0]
                                  ?.subInterests || [],
                              formData: {
                                ...this.state.formData,
                                ...{
                                  subinterest: "",
                                },
                              },
                            })
                          }}
                          size="large"
                          // placeholder="---Choose Interest---"
                          value={this.state.formData.interest}
                          style={{ width: "100%" }}
                          disabled={!interestandsubinterestPermission}
                        >
                          <Option value="">---Choose Interest---</Option>
                          {this.state.interestData?.map(item =>
                            this.state.formData.is_bharat === "N" &&
                            item.name === "Coldstart Experiment" ? (
                              <></>
                            ) : (
                              <Option value={item.id}>{item.name}</Option>
                            )
                          )}

                          {/* <Option value="PRIVATE">Private Mode</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg="6">
                      {" "}
                      <label>Sub-Interest</label>
                      <Form.Item>
                        <Select
                          onChange={value => {
                            this._handleFieldChange("subinterest", value)
                          }}
                          value={this.state.formData.subinterest}
                          style={{ width: "100%" }}
                          size="large"
                          disabled={!interestandsubinterestPermission}
                        >
                          <Option value="">---Choose subInterest---</Option>
                          {this.state.subinterestData?.map(item => (
                            <Option value={item}>
                              {this.state.subInterestStatusList[item].name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" lg="6">
                      <label>Language</label>
                      <Form.Item>
                        <Select
                          label={"Language"}
                          placeholderLabel="Select a Language"
                          options={LANGUAGES}
                          size="large"
                          style={{ width: "100%" }}
                          value={
                            this.state.formData.lang_code ||
                            this.state.selectedVideoDetails.lang_code
                          }
                          onChange={async e => {
                            this._handleFieldChange("lang_code", e)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm="12" lg="6">
                      <label>Is Bharat</label>
                      <Form.Item>
                        <Select
                          label={"Bharat"}
                          placeholderLabel="Select a Is Bharat"
                          options={[
                            { value: "Y", label: "Yes" },
                            { value: "N", label: "No" },
                          ]}
                          size="large"
                          style={{ width: "100%" }}
                          value={
                            this.state.formData.is_bharat ||
                            this.state.selectedVideoDetails.taxonomy_meta
                              ?.video_additional_info?.is_bharat
                          }
                          onChange={async e => {
                            this._handleFieldChange("is_bharat", e)
                          }}
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" lg="6">
                      <label>Ethnicity</label>
                      <Form.Item>
                        <Select
                          label={"Ethnicity"}
                          placeholderLabel="Select a Ethnicity"
                          options={ETHNICITY}
                          size="large"
                          style={{ width: "100%" }}
                          value={
                            this.state.formData.ethnicity ||
                            this.state.selectedVideoDetails.taxonomy_meta
                              ?.video_additional_info?.ethnicity
                          }
                          onChange={async e => {
                            this._handleFieldChange("ethnicity", e)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm="12" lg="6">
                      <label>People Count</label>
                      <Form.Item>
                        <Select
                          label={"People Count"}
                          placeholderLabel="Select a People Count"
                          options={[
                            { value: "single", label: "Single" },
                            { value: "multiple", label: "Multiple" },
                            { value: "na", label: "NA" },
                          ]}
                          size="large"
                          style={{ width: "100%" }}
                          value={
                            this.state.formData.people_count ||
                            this.state.selectedVideoDetails.taxonomy_meta
                              ?.video_additional_info?.people_count
                          }
                          onChange={async e => {
                            this._handleFieldChange("people_count", e)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col lg="12">
                      <table id="tbl" cellpading="2px" width="100%">
                        <thead>
                          <th>Metrics</th>
                          <th>Real</th>
                          <th>Gamified</th>
                          <th>VP%</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Views</td>
                            <td>
                              {this.state.selectedVideoDetails?.view_count ||
                                `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails?.fk_view_count ||
                                `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails?.view_count *
                                    100) /
                                  this.state.selectedVideoDetails?.fk_view_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.view_count *
                                      100) /
                                    this.state.selectedVideoDetails
                                      ?.fk_view_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Likes</td>
                            <td>
                              {this.state.selectedVideoDetails?.like_count ||
                                `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails?.fk_like_count ||
                                `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails?.like_count *
                                    100) /
                                  this.state.selectedVideoDetails?.fk_like_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.like_count *
                                      100) /
                                    this.state.selectedVideoDetails
                                      ?.fk_like_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Shares</td>
                            <td>
                              {this.state.selectedVideoDetails?.share_count ||
                                `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.fk_share_count || `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails
                                    ?.share_count *
                                    100) /
                                  this.state.selectedVideoDetails?.fk_like_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.share_count *
                                      100) /
                                    this.state.selectedVideoDetails
                                      ?.fk_like_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Downloads</td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.download_count || `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.fk_download_count || `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails
                                    ?.download_count *
                                    100) /
                                  this.state.selectedVideoDetails?.play_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.download_count *
                                      100) /
                                    this.state.selectedVideoDetails?.play_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Comments</td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.comments_count || `-`}
                            </td>
                            <td>-</td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails
                                    ?.comments_count *
                                    100) /
                                  this.state.selectedVideoDetails?.play_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.comments_count *
                                      100) /
                                    this.state.selectedVideoDetails?.play_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="12">
                      <table id="tbl" cellpading="2px" width="100%">
                        <thead>
                          <th>Parameter</th>
                          <th>Value</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Resolution</td>
                            <td>
                              {this.state.selectedVideoDetails?.pixel_size}
                            </td>
                          </tr>
                          <tr>
                            <td>Duration</td>
                            <td>
                              {this.state.selectedVideoDetails?.video_duration
                                ? new Date(
                                    this.state.selectedVideoDetails
                                      ?.video_duration * 1000
                                  )
                                    .toISOString()
                                    .slice(14, 19)
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Bitrate</td>
                            <td>{this.state.selectedVideoDetails?.bit_rate}</td>
                          </tr>
                          <tr>
                            <td>FPS</td>
                            <td>
                              {this.state.selectedVideoDetails?.fps || `-`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="4" style={{ marginBottom: "5px" }}>
                      <Button
                        onClick={() => {
                          this.setState({ rejectReasonPopup: true })
                        }}
                        style={{
                          width: "100%",
                        }}
                        variant="contained"
                        className="mx-1"
                        color="error"
                        size="small"
                        // disabled={!isAdmin}
                        // startIcon={<MdAdd />}
                      >
                        Reject
                      </Button>
                    </Col>

                    <Col lg="4" style={{ marginBottom: "5px" }}>
                      {/* <Button
                        onClick={() => {
                          this.skipInterest()
                          // this.setState({ rejectReasonPopup: true })
                        }}
                        style={{
                          width: "100%",
                        }}
                        variant="contained"
                        className="mx-1"
                        color="primary"
                        size="small"
                        disabled={!this.state.isVideoEnded && !isAdmin}
                        // startIcon={<MdAdd />}
                      >
                        Reviewed
                      </Button> */}
                    </Col>

                    <Col lg="4">
                      <Button
                        onClick={async () => {
                          this.activeInterest()
                        }}
                        style={{
                          width: "100%",
                        }}
                        variant="contained"
                        className="mx-1"
                        color="success"
                        size="small"
                        // disabled={!isAdmin}
                        // startIcon={<MdAdd />}
                      >
                        Approve
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Modal>
    )
  }

  _handleFilesUpload = ({ fileList }) => {
    // you store them in state, so that you can make a http req with them later
    this.setState({ fileList })
  }

  _handleRemoveSeed = () => {
    console.log("removed")
  }

  _onAgencyPageChange = value => {
    this.setState({
      agencyCurrentPage: value,
    })
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      async () => {
        // this.props.history.push(
        //   `/source/manage?${QueryString.stringify(
        //     _.deepClean(this.state.filters)
        //   )}`
        // )
        try {
          this.setState({ loading: true })
          let filters = _.clone(this.state.filters)
          const data = await getDtaTaskContentByInterestAndSubInterest({
            filters,
          })
          this.setState({
            filter: { ...this.state.filter, ...this.props },
            loading: false,
            data: data?.data?.contents,
            total: data?.data?.total,
          })
        } catch (error) {
          this.setState({ loading: false })
        }
      }
    )
  }

  render() {
    let agencyContents = this.state?.data
      ? _(this.state?.data)
          .drop((this.state.agencyCurrentPage - 1) * this.state.agencyPageSize)
          .take(this.state.agencyPageSize)
          .value()
      : []
    let isAdmin =
      this.props.currentUser.jotRoles.includes("ADMIN") ||
      this.props.currentUser.jotRoles.includes("SUPER_ADMIN")
    let hasSeedPermission =
      this.props.currentUser.jotPermissions.includes("MARK_AS_SEED")
    let interestandsubinterestPermission =
      this.props.currentUser.jotPermissions.includes(
        "CURATION_INTEREST_SUBINTEREST"
      )
    return (
      <Auxiliary loading={this.state.loading}>
        {!this.state.showImportVideos && !this.props.showAddVideos && (
          <>
            <div className="d-flex justify-content-between">
              <h3>Review Videos</h3>
              <h4>
                Reviewed :{" "}
                {this.state.getInterestReviewedCount &&
                this.state.getInterestReviewedCount["reviewed"]
                  ? this.state.getInterestReviewedCount["reviewed"]
                  : "-"}
              </h4>
            </div>
            <br />

            {isAdmin && this._renderFilters()}
            <br />
            {this.state?.data && (
              <>
                {agencyContents.length > 0 && (
                  <>
                    {" "}
                    <div
                      className="seed-videos-container"
                      style={{ margin: "10px" }}
                    >
                      {agencyContents.map(item => this._renderVideo(item))}
                    </div>
                  </>
                )}
                {isAdmin && (
                  <div className="d-flex justify-content-end pb-3">
                    <Pagination
                      style={{ marginTop: "1rem" }}
                      onChange={this._onPageChange} // To not show the preview upon clicking on new page number
                      defaultCurrent={this.state.filters.currentPage}
                      total={
                        this.state.total < 10000 ? this.state.total : 10000
                      }
                      pageSize={this.state.filters.pageSize}
                      showSizeChanger={false}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        <br />

        {this._renderVideoDetailsPopup(
          isAdmin,
          hasSeedPermission,
          interestandsubinterestPermission
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(CurationList)
