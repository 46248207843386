import Axios from "axios"
import toastr from "toastr"

export const cameraReorder = async formData => {
  toastr.info("Action Started!!")
  try {
    let data = await Axios.post(`/api/cameraAsset/reorder`, formData)
    toastr.success("Camera Reorder successfull")
    return data
  } catch (error) {
    toastr.error("Error in camera reordering ")
    throw error
  }
}
export const cameraReorderList = async formData => {
  // toastr.info("Action Started!!")
  try {
    let data = await Axios.post(`/api/cameraAsset/reorder/list`, formData)
    //   toastr.success("Camera Reorder successfull")
    return data
  } catch (error) {
    toastr.error("Error in camera reordering ")
    throw error
  }
}
