import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import { fetchError } from "appRedux/slices/common"
import {
  campaignCreate,
  campaignCreateSuccess,
  campaignDelete,
  campaignDeleteSuccess,
  campaignUpdate,
  campaignUpdateSuccess,
  getCampaign,
  getCampaignSuccess,
  getBrandCampaignSuccess,
  getBrandCampaign,
  upsertBrandLogo,
  upsertBrandLogoSuccess,
} from "../../slices/campaign"
import {
  requestCampaignCreate,
  requestCampaignDelete,
  requestCampaignUpdate,
  requestGetCampaign,
  requestGetBrandCampaign,
  requestBrandLogoUpsert,
} from "../requests/campaign"

function* handleGetCampaign(params) {
  try {
    const data = yield call(requestGetCampaign, params)
    yield put(getCampaignSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetBrandCampaign(params) {
  try {
    const data = yield call(requestGetBrandCampaign, params)
    yield put(getBrandCampaignSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCampaignCreate(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestCampaignCreate, params)
    yield put(campaignCreateSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleBrandLogoUpsert(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestBrandLogoUpsert, params)
    yield put(upsertBrandLogoSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCampaignUpdate(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestCampaignUpdate, params)
    yield put(campaignUpdateSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCampaignDelete(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestCampaignDelete, params)
    yield put(campaignDeleteSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getCampaign.type, handleGetCampaign),
    yield takeLatest(getBrandCampaign.type, handleGetBrandCampaign),
    yield takeLatest(campaignCreate.type, handleCampaignCreate)
  yield takeLatest(campaignUpdate.type, handleCampaignUpdate)
  yield takeLatest(upsertBrandLogo.type, handleBrandLogoUpsert)
  yield takeLatest(campaignDelete.type, handleCampaignDelete)
}
