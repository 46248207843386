import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { map } from "lodash"
import CmsUserList from "../../components/CmsUserCard/cmsUserCard"
import Chips from "components/CommonFilter/chips"
import { Pagination, Select } from "antd"
import { Col, Button } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getUserListByFilters,
  resetState,
  updateUser,
  createUser,
  getRoles,
} from "../../appRedux/slices/cmsUser"
import { getAgencyData } from "../../appRedux/slices/uiConstants"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import "./styles.css"
import SimpleBar from "simplebar-react"
import {
  PERMISSIONS_ARRAY,
  cmsUserFilters,
  NO_PERMISSION_MESSAGE,
} from "../../constants/uiConstants"
import CreateCmsUserForm from "../../containers/CMSUsers/cmsUserCreateForm"
import UpgradeUserForm from "../../containers/CMSUsers/upgradeUserForm"
import CmsUserProfile from "./cmsUserProfile"
import { upgradeUser } from "../../clientServices/cmsUserService"
import NoDataFound from "components/NoDataFound/noDataFound"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const USER_COLOR = ["warning", "primary", "success", "info"]

class ManageCMSUser extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : null,
      searchType:
        this.props.queryParams && this.props.queryParams["searchType"]
          ? this.props.queryParams["searchType"]
          : "email",
      userStatus:
        this.props.queryParams && this.props.queryParams["userStatus"]
          ? this.props.queryParams["userStatus"]
          : "",
      agency:
        this.props.queryParams && this.props.queryParams["agency"]
          ? this.props.queryParams["agency"]
          : "",
      jotUserType:
        this.props.queryParams && this.props.queryParams["jotUserType"]
          ? this.props.queryParams["jotUserType"]
          : "",
      permissions:
        this.props.queryParams && this.props.queryParams["permissions"]
          ? this.props.queryParams["permissions"]
          : "",
    },
    redirect: false,
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getUserListByFilters(_.deepClean(filters)))
      this.props.dispatch(getRoles())
      this.props.dispatch(getAgencyData())
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (this.props.refetchData) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getUserListByFilters(_.deepClean(filters)))
      this._onModalCancel()
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: cmsUserFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => this._closeFilters()
    )
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }
    this.setState({ filters: newFilters })
  }

  _searchUser = () => {
    let filters = _.clone(this.state.filters)
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/users/manage?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
    this.props.dispatch(getUserListByFilters(_.deepClean(filters)))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/users/manage?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
    })
    this.props.dispatch(getUserListByFilters(_.deepClean(newFilters)))
  }

  _closeFilters = () => {
    this._searchUser()
    this.setState({ showFilters: false, showForm: false })
  }

  _getPermissions = () => {
    let permissions = []
    permissions = PERMISSIONS_ARRAY.map(permission => ({
      value: permission.value,
      label: permission.label,
    }))
    permissions.push({ value: "", label: "Permissions" })
    if (this.props.currentUser.jotUserType > 1) {
      permissions.push(
        { value: "TAG_CONTENT_ADMIN", label: "Tag Content Admin" },
        { value: "COMMUNITY_MANAGER", label: "Community Manager" },
        { value: "VIEW_REAL_STATS", label: "View Real Stats" },
        { value: "MANAGE_CMS_USERS", label: "Manage CMS Users" }
      )
    }
    return permissions
  }

  _upgradeUser = async formData => {
    let loading,
      messageDesc = "Upgrading User"
    loading = "Action in progress..."
    try {
      const response = await upgradeUser({ data: formData })
      if (response.status === 200 && response.data) {
        toastr.success(`${messageDesc} success`, "")
        let filters = _.clone(this.state.filters)
        this.props.getUserListByFilters(_.deepClean(filters))
        this.setState({ showForm: false })
      } else {
        toastr.error(
          `${
            !_.isEmpty(response.response.data.message)
              ? response.response.data.message
              : `${messageDesc} failed`
          }`,
          ""
        )
      }
    } catch (error) {
      // console.log(error)
    }
  }

  _onUpsert = async formData => {
    let data = formData
    data.userType = formData.jotUserType % 10
    this._onModalCancel()
    switch (this.state.formAction) {
      case "update":
        this.props.dispatch(updateUser(data))
        break
      case "create":
        this.props.dispatch(createUser(data))
        break
      default:
    }
  }

  _onModalCancel = () => {
    this.setState({
      showForm: false,
      showModal: false,
      currentData: {},
    })
  }

  _getUserUpsertForm = () => {
    if (this.state.formAction === "upgrade") {
      return (
        <UpgradeUserForm
          onSubmit={this._upgradeUser}
          data={this.state.currentData}
          formAction={this.state.formAction}
          userId={this.state.userId}
          roleData={this.props.roleData && this.props.roleData.roles}
          onCancel={() => this.setState({ showForm: false, currentData: null })}
          currentUser={this.props.currentUser}
        />
      )
    } else if (
      this.state.formAction === "create" ||
      this.state.formAction === "update"
    ) {
      return (
        <CreateCmsUserForm
          onSubmit={this._onUpsert}
          data={this.state.currentData}
          formAction={this.state.formAction}
          userId={this.state.userId}
          currentUser={this.props.currentUser}
          roleData={this.props.roleData && this.props.roleData.roles}
          agencyList={this.props.agencyList}
          onCancel={() => this.setState({ showForm: false, currentData: null })}
        />
      )
    } else if (this.state.formAction === "profile") {
      return (
        <CmsUserProfile
          data={this.state.currentData}
          formAction={this.state.formAction}
          userId={this.state.userId}
          currentUser={this.props.currentUser}
        />
      )
    }
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <Chips
          showFilters={this.state.showFilters}
          filters={this.state.filters}
          search={this._closeFilters}
          removeFilter={this._removeFilter}
          handleFilterChange={this._handleFilterChange}
          type="cmsUser"
          toggleFilters={this._toggleFilters}
        />
        {!this.state.showForm && (
          <div className="text-sm-end" style={{ paddingBottom: "15px" }}>
            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary"
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_MANAGE_CMS_USER"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this.setState(
                  {
                    showForm: false,
                  },
                  () =>
                    this.setState({
                      formAction: "create",
                      showForm: true,
                      currentData: {},
                    })
                )
              }}
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Create User
            </Button>
          </div>
        )}
        {!this.state.showForm && (
          <>
            {this.props.moderatorList && this.props.moderatorList.length > 0 ? (
              <Col>
                <div className="card-container">
                  {map(this.props.moderatorList, (user, key) => (
                    <CmsUserList
                      user={user}
                      key={"_user_" + key}
                      color={USER_COLOR[Math.floor(Math.random() * 3)]}
                      editClick={(userData, action) => {
                        if (
                          !this.props.currentUser?.permissions?.includes(
                            "EDIT_MANAGE_CMS_USER"
                          )
                        ) {
                          toastr.error(NO_PERMISSION_MESSAGE)
                          return
                        }
                        this.setState(
                          {
                            showForm: false,
                          },
                          () =>
                            this.setState({
                              showForm: true,
                              currentData: userData,
                              formAction: action,
                            })
                        )
                      }}
                    />
                  ))}
                </div>
                {
                  <div className="d-flex justify-content-end">
                    <Pagination
                      className="m-2"
                      onChange={this._onPageChange}
                      defaultCurrent={this.state.filters.currentPage}
                      total={
                        this.props.total < 10000 ? this.props.total : 10000
                      }
                      pageSize={this.state.filters.pageSize}
                      showSizeChanger={false}
                    />
                  </div>
                }
              </Col>
            ) : (
              <NoDataFound message="No Data To Display" />
            )}
          </>
        )}
        {this.state.showForm && this._getUserUpsertForm()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "cmsUser.loading"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "cmsUser.total"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.user"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    refetchData: _.get(store, "cmsUser.refetchData"),
    roleData: _.get(store, "cmsUser.roles"),
    agencyList: _.get(store, "uiConstants.agencyList"),
  }
}

export default connect(mapStateToProps)(ManageCMSUser)
