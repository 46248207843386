import React from "react"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { LANGUAGES } from "constants/uiConstants"

const { Option } = Select

const AudioStoriesCollectionDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Collection ID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["collectionId"]}
                  placeholder={"Collection Id"}
                  onChange={e =>
                    props.handleFilterChange(
                      e.target.value.trim(),
                      "collectionId"
                    )
                  }
                  allowClear={true}
                  name="collectionId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(AudioStoriesCollectionDrawer)
