import { createSlice } from "@reduxjs/toolkit"

const zoneSlice = createSlice({
  name: "zone",
  initialState: {
    loading: false,
    loadingActivity: false,
    zoneList: [],
    allZoneList: [],
    refetchData: false,
    total: 0,
    issuedDetails: 0,
    zone: {},
    refetchZoneData: false,
    zoneVideos: [],
    totalZoneVideos: 0,
    zoneVediosLoading: false,
    zoneImages: [],
    totalZoneImages: 0,
    zoneImagesLoading: false,
    zoneSocials: [],
    totalZoneSocials: 0,
    zoneSocialsLoading: false,
  },

  reducers: {
    getZones(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getZonesSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        zoneList: payload?.result?.data || [],
        total: payload?.result?.total?.value
          ? payload.result.total.value
          : payload?.result?.total || 1,
      }
    },
    getAllZones(state) {
      return {
        ...state,
        // loading: true,
        refetchData: false,
      }
    },
    getAllZonesSuccess(state, { payload }) {
      return {
        ...state,
        // loading: false,
        refetchData: false,
        allZoneList: payload?.data || [],
      }
    },
    createZone(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createzoneSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: true,
        createStatus: payload.status,
      }
    },
    upsertZone(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertZoneSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
      }
    },
    getActivity(state) {
      return {
        ...state,
        loadingActivity: true,
      }
    },
    getActivitySuccess(state, { payload }) {
      return {
        ...state,
        loadingActivity: false,
        activityList: payload.result.data || [],
        totalActivity: payload.result.count || 0,
      }
    },
    getZoneDetails(state) {
      return {
        ...state,
        loading: true,
        zone: {},
        refetchZoneData: false,
      }
    },
    getZoneDetailsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        zone: payload.data,
        refetchZoneData: false,
      }
    },
    deleteBanner(state) {
      return {
        ...state,
        loading: true,
      }
    },
    deleteBannerSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    addPreviewImage(state) {
      return {
        ...state,
        loading: true,
        refetchZoneData: false,
      }
    },
    addPreviewImageSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchZoneData: true,
      }
    },
    getTasksByFilter(state) {
      return {
        ...state,
        loading: true,
        refetchTaskList: false,
        bannerList: [],
      }
    },
    getTasksByFilterSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        bannerList: payload.data.user || [],
        total: payload.data.total,
      }
    },
    getAppZoneVideos(state) {
      return {
        ...state,
        // zoneVideos: [],
        zoneVediosLoading: true,
      }
    },
    getAppZoneVideosSuccess(state, { payload }) {
      return {
        ...state,
        zoneVideos: payload?.data?.data?.data || payload?.data?.content || [],
        zoneVediosLoading: false,
        totalZoneVideos: payload?.data?.total?.value || 1,
      }
    },
    getAppZoneImages(state) {
      return {
        ...state,
        // zoneImages: [],
        zoneImagesLoading: true,
      }
    },
    getAppZoneImagesSuccess(state, { payload }) {
      return {
        ...state,
        zoneImages:
          payload?.data?.data?.data || payload?.data?.result?.data || [],
        zoneImagesLoading: false,
        totalZoneImages: payload?.data?.result?.total?.value
          ? payload?.data?.result?.total?.value
          : payload?.data?.result?.total || 1,
      }
    },
    getAppZoneSocials(state) {
      return {
        ...state,
        // zoneSocials: [],
        zoneSocialsLoading: true,
      }
    },
    getAppZoneSocialsSuccess(state, { payload }) {
      return {
        ...state,
        zoneSocials:
          payload?.data?.data?.data || payload?.data?.result?.data || [],
        zoneSocialsLoading: false,
        totalZoneSocials: payload?.data?.result?.total?.value
          ? payload?.data?.result?.total.value
          : payload?.data?.result?.total || 1,
      }
    },
    resetState() {
      return {
        loading: false,
        loadingActivity: false,
        zoneList: [],
        allZoneList: [],
        refetchData: false,
        total: 0,
        openIssues: 0,
        refetchZoneData: false,
        zoneVideos: [],
        totalZoneVideos: 0,
        zoneVediosLoading: false,
        zoneImages: [],
        totalZoneImages: 0,
        zoneImagesLoading: false,
        zoneSocials: [],
        totalZoneSocials: 0,
        zoneSocialsLoading: false,
      }
    },
  },
})

export const {
  createEvent,
  createEventSuccess,
  getZones,
  getZonesSuccess,
  getActivity,
  upsertZone,
  upsertZoneSuccess,
  getActivitySuccess,
  resetState,
  getZoneDetails,
  getZoneDetailsSuccess,
  deleteBanner,
  deleteBannerSuccess,
  getAllZones,
  getAllZonesSuccess,
  addPreviewImage,
  addPreviewImageSuccess,
  getTasksByFilter,
  getTasksByFilterSuccess,
  getAppZoneVideos,
  getAppZoneVideosSuccess,
  getAppZoneImages,
  getAppZoneImagesSuccess,
  getAppZoneSocials,
  getAppZoneSocialsSuccess,
} = zoneSlice.actions

export default zoneSlice.reducer
