import Axios from "axios"
import toastr from "toastr"

export const requestCmsUserList = async () => {
  try {
    let data = await Axios.post(`/api/users/getCMSUsersList`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestRoles = async () => {
  try {
    let data = await Axios.post(`/api/roles/getRoles`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUserListByFilters = async ({ payload }) => {
  const filters = payload
  const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
  const limit = payload["pageSize"] || 10
  try {
    let data = await Axios.post(
      `/api/users/getCMSUsersListByFilter?offset=${offset}&limit=${limit}`,
      filters
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateUser = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/users/updateUser`, payload)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
export const requestAgencyList = async () => {
  try {
    let data = await Axios.get(`/api/agency/getAgencyList`)
    return data
  } catch (error) {
    throw error
  }
}
export const requestDeskList = async () => {
  try {
    let data = await Axios.get(`/api/users/getDeskList`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestCreateUser = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/users/createUser`, payload)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
