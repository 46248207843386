import AudioVulgarity from "./audioVulgarity"
import Background from "./background"
import CityTier from "./cityTier"
import Emotions from "./emotions"
import Events from "./events"
import Genre from "./genre"
import Glamour from "./glamour"
import OrthogonalAttribute from "./orthogonalAttribute"
import OrthogonalQuality from "./orthogonalQuality"
import PeopleInVideo from "./peopleInVideo"
import TargetLanguages from "./targetLanguages"
import Violence from "./violence"
import Zone from "./zone"
import ZoneApproval from "./zoneApproval"
import Moderation from "./moderation"
import DefaultPageV1 from "./defaultPage"
import SubGenre from "./subgenre"
import ZoneAddition from "./zoneAddition"
import zoneVerification from "./zoneVerification"
import Ethnicity from "./ethnicity"
import PeopleCount from "./peopleCount"

const taxonomyRoutes = [
  // {
  //   key: "genre",
  //   title: "Genre",
  //   component: Genre,
  //   completed: false,
  //   showInSidebar: false,
  // },
  {
    key: "glamour",
    title: "Glamour",
    component: Glamour,
    completed: false,
    showInSidebar: false,
  },
  {
    key: "violence",
    title: "Violence",
    component: Violence,
    completed: false,
    showInSidebar: false,
  },
  {
    key: "audio_vulgarity",
    title: "Audio Vulgariry",
    component: AudioVulgarity,
    completed: false,
    showInSidebar: false,
  },
  // {
  //   key: "ethnicity",
  //   title: "Ethnicity",
  //   component: Ethnicity,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "people_count",
  //   title: "People Count",
  //   component: PeopleCount,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "is_bharat",
  //   title: "Is Bharat",
  //   component: Glamour,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "orthogonal_attribute",
  //   title: "Orthogonal Attribute",
  //   component: OrthogonalAttribute,
  //   completed: false,
  //   showInSidebar: false,
  // },

  // {
  //   key: "sub_genre",
  //   title: "Sub Genre",
  //   component: SubGenre,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "zone_verification",
  //   title: "Zone Verification",
  //   component: zoneVerification,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "zone_addition",
  //   title: "Zone Addition",
  //   component: ZoneAddition,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "emotions",
  //   title: "Emotions",
  //   component: Emotions,
  //   completed: false,
  //   showInSidebar: false,
  // },

  // {
  //   key: "city_tier",
  //   title: "City Tier",
  //   component: CityTier,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "people_in_video",
  //   title: "People in Video",
  //   component: PeopleInVideo,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "target_languages",
  //   title: "Target Languages",
  //   component: TargetLanguages,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "moderation",
  //   title: "Moderation",
  //   component: Moderation,
  //   completed: false,
  //   showInSidebar: false,
  // },
  // {
  //   key: "zone_approval",
  //   title: "Zone Approval",
  //   component: ZoneApproval,
  //   completed: false,
  //   showInSidebar: false,
  // },

  {
    key: "default",
    title: "default",
    component: DefaultPageV1,
    completed: false,
    showInSidebar: false,
  },
]

export { taxonomyRoutes }
