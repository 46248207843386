import React from "react"
import { Divider } from "antd"
import { Accordion } from "react-bootstrap"
import { Card, CardBody, Row, Col } from "reactstrap"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import UpsertCategory from "./upsertCategory"
import Auxiliary from "../../util/Auxiliary"
import { Form } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import moment from "moment"
import toastr from "toastr"
import { NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import "moment-timezone"
import "./styles.css"

export default class UpsertContest extends ErrorBoundComponent {
  state = {
    formData: {
      hide_stats: "Y",
      sponsored: "Y",
      disable_in_feed: false,
      faqs: [
        {
          question: "",
          answer: "",
        },
      ],
      fileList: {},
      contest_type: "type1",
      category_data: [{ id: uuidv4() }],
    },
    showAudioDetails: false,
    formAction: this.props.action,
    hasCategories: false,
    defaultActiveKey: 0,
    errors: {},
  }

  componentDidMount() {
    const { contest } = this.props
    if (!_.isEmpty(contest)) {
      const {
        category_data,
        hashtag,
        category_dialog_title,
        start_date,
        end_date,
        hide_stats,
        disable_in_feed,
        max_vote_allowed,
        max_participation_allowed,
        sponsored,
        sponsored_text,
        rules_data,
      } = contest

      const rulesJson = JSON.parse(rules_data)
      this.setState({
        formData: {
          ...this.state.formData,
          category_data,
          hashtag,
          category_dialog_title,
          start_date,
          end_date,
          hide_stats,
          disable_in_feed,
          max_participation_allowed,
          max_vote_allowed,
          sponsored,
          sponsored_text,
          faqs: rulesJson?.data?.faq,
          faq_title: rulesJson?.data.faq_title,
          rules_title: rulesJson?.data.rules_title,
          rules_html: rulesJson?.data.rules_html,
          contest_type: contest.contest_type,
        },
      })
    } else {
      let category_data = [{ id: uuidv4() }]

      switch (this.state.formData.contest_type) {
        case "type1":
          category_data = [{ id: uuidv4(), view_order: 0 }]
          break
        case "type2":
          category_data = [{ id: uuidv4(), view_order: 0 }]
          break
        case "type3":
          category_data = [
            {
              id: uuidv4(),
              sub_category_data: [{ id: uuidv4(), view_order: 0 }],
            },
          ]
          break
        case "type4":
          category_data = [
            {
              id: uuidv4(),
              sub_category_data: [{ id: uuidv4(), view_order: 0 }],
            },
          ]
          break
        default:
          break
      }

      this.setState({
        formData: {
          ...this.state.formData,
          category_data,
        },
      })
    }
  }

  _findFormErrors = () => {
    const newErrors = {}
    const { hashtag, start_date, end_date } = this.state.formData
    if (!hashtag || hashtag === "")
      newErrors.hashtag = "Please provide the hashtag!"
    if (!start_date || start_date === "")
      newErrors.start_date = "Please provide the start date!"
    if (!end_date || end_date === "")
      newErrors.end_date = "Please provide the end date!"
    return newErrors
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    if (field === "contest_type") {
      switch (value) {
        case "type1":
          formData.category_data = [{ id: uuidv4(), view_order: 0 }]
          break
        case "type2":
          formData.category_data = [{ id: uuidv4(), view_order: 0 }]
          break
        case "type3":
          formData.category_data = [
            {
              id: uuidv4(),
              sub_category_data: [{ id: uuidv4(), view_order: 0 }],
            },
          ]
          break
        case "type4":
          formData.category_data = [
            {
              id: uuidv4(),
              sub_category_data: [{ id: uuidv4(), view_order: 0 }],
            },
          ]
          break
        default:
          break
      }
    }

    this.setState({
      formData,
      errors: {
        ...this.state.errors,
        [field]: null,
      },
    })
  }

  _handleFaqsChange = (field, value, index) => {
    let faqs = [...this.state.formData.faqs]
    faqs[index] = {
      ...faqs[index],
      [field]: value,
    }
    this.setState({
      formData: {
        ...this.state.formData,
        faqs,
      },
    })
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      formData: {
        ...this.state.formData,
        fileList: {
          ...this.state.formData.fileList,
          [key]: file,
        },
      },
    })
  }

  _removeCategory = categoryId => {
    const category_data = [...this.state.formData.category_data]

    let updated_category_data = category_data.filter(
      category => category.id !== categoryId
    )

    updated_category_data.forEach((category, index) => ({
      ...category,
      view_order: index,
    }))

    this.setState({
      formData: {
        ...this.state.formData,
        category_data: updated_category_data,
      },
    })
  }

  _renderUpsertForm = () => {
    const disabled = this.props.formAction === "update"
    return (
      <Form>
        <Row className="mb-3">
          <Col lg="4">
            <Form.Group>
              <Form.Label>Hashtag *</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                onChange={e =>
                  this._handleFieldChange("hashtag", e.target.value)
                }
                defaultValue={this.state.formData.hashtag}
                disabled={disabled}
                isInvalid={!!this.state.errors.hashtag}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.hashtag}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg="8">
            <label>Sponsored Text</label>
            <div>
              <input
                className="form-control"
                type="text"
                onChange={e =>
                  this._handleFieldChange("sponsored_text", e.target.value)
                }
                defaultValue={this.state.formData.sponsored_text}
                disabled={disabled}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg="4">
            <label>Category Dialogue Title</label>
            <div>
              <input
                className="form-control"
                type="text"
                onChange={e =>
                  this._handleFieldChange(
                    "category_dialog_title",
                    e.target.value
                  )
                }
                defaultValue={this.state.formData.category_dialog_title}
              />
            </div>
          </Col>
          <Col lg="4">
            <Form.Group>
              <Form.Label> Start Date *</Form.Label>
              <Form.Control
                type="datetime-local"
                onChange={e =>
                  this._handleFieldChange(
                    "start_date",
                    moment
                      .tz(e.target.value, "Asia/Calcutta")
                      .format("YYYY-MM-DDTHH:mm:ss+05:30")
                  )
                }
                value={
                  this.props.formAction === "create"
                    ? null
                    : moment(this.state.formData.start_date).format(
                        "YYYY-MM-DDTHH:mm:00"
                      )
                }
                disabled={disabled}
                isInvalid={!!this.state.errors.start_date}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.start_date}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>End Date *</Form.Label>
              <Form.Control
                type="datetime-local"
                onChange={e =>
                  this._handleFieldChange(
                    "end_date",
                    moment
                      .tz(e.target.value, "Asia/Calcutta")
                      .format("YYYY-MM-DDTHH:mm:ss+05:30")
                  )
                }
                value={
                  this.props.formAction === "create"
                    ? null
                    : moment(this.state.formData.end_date).format(
                        "YYYY-MM-DDTHH:mm:00"
                      )
                }
                isInvalid={!!this.state.errors.end_date}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errors.end_date}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 d-flex align-items-center">
          <Col lg="4">
            <label>Maximum Vote Allowed</label>
            <div>
              <input
                className="form-control"
                type="number"
                onChange={e =>
                  this._handleFieldChange("max_vote_allowed", +e.target.value)
                }
                defaultValue={this.state.formData.max_vote_allowed}
                disabled={disabled}
              />
            </div>
          </Col>
          <Col lg="4">
            <label>Maximum Participation Allowed *</label>
            <div>
              <input
                className="form-control"
                type="number"
                onChange={e =>
                  this._handleFieldChange(
                    "max_participation_allowed",
                    +e.target.value
                  )
                }
                required
                defaultValue={this.state.formData.max_participation_allowed}
                disabled={disabled}
              />
            </div>
          </Col>
          <Col lg="4">
            <div>
              <label>Background Image</label>
              <input
                type="file"
                className="form-control"
                onChange={e =>
                  this._handleFileUpload("background_image", e.target.files[0])
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="2">
            <div className="form-check form-switch form-switch-lg">
              <input
                type="checkbox"
                className="form-check-input"
                onChange={e =>
                  this._handleFieldChange(
                    "sponsored",
                    e.target.checked ? "Y" : "N"
                  )
                }
                checked={this.state.formData.sponsored === "Y" ? true : false}
                disabled={disabled}
              />
              <label className="form-check-label">Sponsored</label>
            </div>
          </Col>
          <Col lg="2">
            <div>
              <div className="form-check form-switch form-switch-lg">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={e =>
                    this._handleFieldChange(
                      "hide_stats",
                      e.target.checked ? "Y" : "N"
                    )
                  }
                  checked={
                    this.state.formData.hide_stats === "Y" ? true : false
                  }
                  disabled={disabled}
                />
                <label className="form-check-label">Hide Stats</label>
              </div>
            </div>
          </Col>
          <Col lg="3">
            <div className="form-check form-switch form-switch-lg">
              <input
                type="checkbox"
                className="form-check-input"
                onChange={e =>
                  this._handleFieldChange("disable_in_feed", e.target.checked)
                }
                checked={this.state.formData.disable_in_feed}
              />
              <label className="form-check-label">Disable In Feed</label>
            </div>
          </Col>
        </Row>
        <Divider>
          <h5 className="mb-0">Rules</h5>
        </Divider>
        <Row className="mb-3">
          <Col lg="6">
            <label>FAQ Title</label>
            <div>
              <input
                className="form-control"
                type="text"
                onChange={e =>
                  this._handleFieldChange("faq_title", e.target.value)
                }
                defaultValue={this.state.formData.faq_title}
              />
            </div>
          </Col>
          <Col lg="6">
            <label>Rules Title </label>
            <div>
              <input
                className="form-control"
                type="text"
                onChange={e =>
                  this._handleFieldChange("rules_title", e.target.value)
                }
                defaultValue={this.state.formData.rules_title}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg="12">
            <label>Rules HTML</label>
            <div>
              <textarea
                className="form-control"
                rows="3"
                onChange={e =>
                  this._handleFieldChange("rules_html", e.target.value)
                }
                defaultValue={this.state.formData.rules_html}
              />
            </div>
          </Col>
        </Row>
        {this.state.formData.faqs.map((faq, index) => {
          return (
            <Row className="mb-3 d-flex align-items-center" key={index}>
              <Col lg="1">Question #{index + 1}</Col>
              <Col lg="5">
                <div>
                  <textarea
                    className="form-control"
                    rows="1"
                    placeholder="Type question here"
                    onChange={e =>
                      this._handleFaqsChange("question", e.target.value, index)
                    }
                    defaultValue={faq.question}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div>
                  <textarea
                    className="form-control"
                    rows="1"
                    placeholder="Type answer here"
                    onChange={e =>
                      this._handleFaqsChange("answer", e.target.value, index)
                    }
                    defaultValue={faq.answer}
                  />
                </div>
              </Col>
            </Row>
          )
        })}
        <div className="d-flex justify-content-end">
          <div
            className="btn btn-primary"
            onClick={() =>
              this.setState({
                formData: {
                  ...this.state.formData,
                  faqs: [
                    ...this.state.formData.faqs,
                    { question: "", answer: "" },
                  ],
                },
              })
            }
            role="button"
          >
            Add Question
          </div>
        </div>

        <div className="mt-3">
          <Accordion>
            {this.state.formData?.category_data.map((category, index) => (
              <div style={{ position: "relative" }} key={category.id}>
                {this.props.formAction === "create" &&
                  (index !== 0 ||
                    this.state.formData?.category_data.length > 1) && (
                    <span
                      className="delete-icon"
                      onClick={() => this._removeCategory(category.id)}
                    >
                      <i className="mdi mdi-delete"></i>
                    </span>
                  )}
                {this.props.formAction === "update" &&
                  category?.newly_added === true &&
                  (index !== 0 ||
                    this.state.formData?.category_data.length > 1) && (
                    <span
                      className="delete-icon"
                      onClick={() => this._removeCategory(category.id)}
                    >
                      <i className="mdi mdi-delete"></i>
                    </span>
                  )}
                <div>
                  <Accordion.Item eventKey={index} key={category.id}>
                    <Accordion.Header>{`Category #${
                      index + 1
                    }`}</Accordion.Header>
                    <Accordion.Body>
                      <UpsertCategory
                        categoryId={index}
                        categories={this.state.formData.category_data}
                        addSubCategory={this._onSubCategoryAdd}
                        handleFileUpload={this._handleFileUpload}
                        formAction={this.props.formAction}
                        updateCategory={this.props.updateCategory}
                        addCategory={this.props.addCategory}
                        contest={this.props.contest}
                        contestType={this.state.formData.contest_type}
                        updateSubCategory={this.props.updateSubCategory}
                        deleteBanner={this.props.deleteBanner}
                        currentUser={this.props.currentUser}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </div>
            ))}
          </Accordion>
        </div>
      </Form>
    )
  }

  _onSubCategoryAdd = categories => {
    this.setState({
      formData: {
        ...this.state.formData,
        category_data: categories,
      },
    })
  }

  _addCategory = () => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_CONTEST")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    const category_data = [...this.state.formData.category_data]

    if (this.state.formData.contest_type === "type2") {
      category_data.push({
        id: uuidv4(),
        view_order: this.state.formData.category_data.length,
        newly_added: true,
      })
    } else {
      category_data.push({
        id: uuidv4(),
        view_order: this.state.formData.category_data.length,
        sub_category_data: [{ id: uuidv4(), view_order: 0 }],
        newly_added: true,
      })
    }

    this.setState({
      formData: {
        ...this.state.formData,
        category_data,
      },
    })
  }

  _upsertContest = () => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_CONTEST")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    const errors = this._findFormErrors()
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      })
      return
    }

    if (
      this.props.formAction === "create" &&
      ["type2", "type4"].includes(this.state.formData.contest_type) &&
      this.state.formData.category_data.length < 2
    ) {
      toastr.error(
        "You must provide at lease two categories for the selected contest type!!"
      )
      return
    }
    const formData = _.cloneDeep(this.state.formData)
    if (
      !(
        formData.max_participation_allowed ||
        formData.max_participation_allowed === 0
      )
    ) {
      toastr.error("Maximum Participation Allowed is mandatory!!")
      return
    }
    const data = new FormData()

    const rules_data = {
      data: {
        faq_title: formData.faq_title,
        rules_title: formData.rules_title,
        rules_html: formData.rules_html,
        faq: formData.faqs,
      },
    }

    formData.rules_data = JSON.stringify(rules_data)

    formData.category_data.forEach(category => {
      delete category?.newly_added
      if (category.bannerType === "image") {
        delete category.video
      } else {
        delete category.banner_list
      }
      delete category.bannerType
      delete category?.fileList

      if (_.isEmpty(category.target)) {
        delete category.target
      } else {
        category.target.forEach(item => {
          if (item.bannerType === "image") {
            delete item?.video
          } else {
            delete item?.banner_list
          }
        })
      }

      if (category.sub_category_data) {
        category.sub_category_data.forEach(subCategory => {
          delete subCategory?.newly_added
          if (subCategory.bannerType === "image") {
            delete subCategory.video
          } else {
            delete subCategory.banner_list
          }
          delete subCategory.bannerType
          delete subCategory?.fileList

          if (_.isEmpty(subCategory.target)) {
            delete subCategory.target
          } else {
            subCategory.target.forEach(item => {
              if (item.bannerType === "image") {
                delete item?.video
              } else {
                delete item?.banner_list
              }
            })
          }
        })
      }
    })

    Object.keys(this.state.formData.fileList).forEach(fileName => {
      data.append(fileName, this.state.formData.fileList[fileName])
    })

    delete formData?.faqs
    delete formData?.faq_title
    delete formData?.rules_title
    delete formData?.rules_html
    delete formData?.fileList

    if (this.props.formAction === "update") {
      Object.keys(formData).forEach(key => {
        if (
          ![
            "rules_data",
            "end_date",
            "category_dialog_title",
            "contest_id",
            "disable_in_feed",
          ].includes(key)
        ) {
          delete formData?.[key]
        }
      })
      formData.contest_id = this.props.contest.contest_id
    }
    data.append("details", JSON.stringify(formData))
    this.props.upsertContest(data)
  }

  _render = () => {
    return (
      <Auxiliary loading={this.props.loading}>
        <Row className="mb-3">
          <Col lg="4">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <label className="col-form-label">Type</label>
              </div>
              <select
                className="form-select"
                onChange={e =>
                  this._handleFieldChange("contest_type", e.target.value)
                }
                value={this.state.formData.contest_type}
                disabled={this.props.formAction === "update"}
              >
                <option value="type1">Contest</option>
                <option value="type2">Contest With Only Categories</option>
                <option value="type3">Contest With Only Sub Categories</option>
                <option value="type4">
                  Contest With Categories and Sub Categories
                </option>
              </select>
            </div>
          </Col>
        </Row>
        <Card>
          <CardBody>
            {this._renderUpsertForm()}
            {["type2", "type4"].includes(this.state.formData.contest_type) && (
              <div className="d-flex justify-content-center my-2">
                <button className="btn btn-info" onClick={this._addCategory}>
                  Add Category
                </button>
              </div>
            )}
            <div className="d-flex justify-content-end mt-2">
              <button className="btn btn-success" onClick={this._upsertContest}>
                {this.props.formAction === "create"
                  ? "Create Contest"
                  : "Update Contest"}
              </button>
            </div>
          </CardBody>
        </Card>
      </Auxiliary>
    )
  }
}
