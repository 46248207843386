import React from "react"
import { connect } from "react-redux"
import { Upload } from "antd"
import { Row, Col } from "reactstrap"
import { Button } from "@mui/material"
import ValidatorForm, { Select, TextField } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { uploadUser } from "appRedux/slices/appUser"
import _ from "lodash"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "./styles.css"

class UploadBulkUserForm extends ErrorBoundComponent {
  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }

  _onVideoRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col lg="6">
            <Select
              label={"Upload Type"}
              placeholderLabel="Select an upload type"
              field="uploadType"
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              onChange={value => this.setState({ uploadType: value })}
              options={
                this.props.isPhoneUserBlockPermitted
                  ? [
                      { label: "FTP", value: "ftp" },
                      { label: "FTPS", value: "ftps" },
                      { label: "Cross Platform User", value: "crossplatform" },
                      { label: "Update User", value: "update" },
                      { label: "Update User Type", value: "user_type" },
                      { label: "Update Curated", value: "updatecurated" },
                      { label: "Update Tags (Badge)", value: "updatetags" },
                      {
                        label: "Community Language Code",
                        value: "community_lang_code",
                      },
                      {
                        label: "User Paid Status (remunerate)",
                        value: "remunerate",
                      },
                      { label: "Delete Tags (Badge)", value: "deletetags" },
                      {
                        label: "Gift / Live Enable",
                        value: "josh_live_enable",
                      },
                      { label: "Legal Block User", value: "legal_block" },
                      { label: "Block User", value: "block" },
                      {
                        label: "Verify / Un-verify User",
                        value: "verify_unverify_user",
                      },
                      { label: "Creator Payout", value: "creator_payout" },
                      {
                        label: "Live Block / Unblock",
                        value: "live_block_unblock",
                      },
                      { label: "Phone No. Block", value: "phone_no_block" },
                      {
                        label: "Gratification Disable",
                        value: "gratification_disable",
                      },
                    ]
                  : [
                      { label: "FTP", value: "ftp" },
                      { label: "FTPS", value: "ftps" },
                      { label: "Cross Platform User", value: "crossplatform" },
                      { label: "Update User", value: "update" },
                      { label: "Update User Type", value: "user_type" },
                      { label: "Update Curated", value: "updatecurated" },
                      { label: "Update Tags (Badge)", value: "updatetags" },
                      {
                        label: "Community Language Code",
                        value: "community_lang_code",
                      },
                      {
                        label: "User Paid Status (remunerate)",
                        value: "remunerate",
                      },
                      { label: "Delete Tags (Badge)", value: "deletetags" },
                      {
                        label: "Gift / Live Enable",
                        value: "josh_live_enable",
                      },
                      { label: "Legal Block User", value: "legal_block" },
                      { label: "Block User", value: "block" },
                      { label: "Creator Payout", value: "creator_payout" },
                      {
                        label: "Live Block / Unblock",
                        value: "live_block_unblock",
                      },
                      {
                        label: "Gratification Disable",
                        value: "gratification_disable",
                      },
                    ]
              }
            />
          </Col>
          {(this.state.uploadType === "ftp" ||
            this.state.uploadType === "ftps" ||
            this.state.uploadType === "crossplatform") && (
            <Col lg="6">
              <TextField
                label={"FTP Host"}
                placeholderLabel="Enter the FTP Host Details"
                field="host"
                defaultValue="dhpublishpush.dailyhunt.in"
                className="creator-form"
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}
          {(this.state.uploadType === "ftp" ||
            this.state.uploadType === "ftps" ||
            this.state.uploadType === "crossplatform") && (
            <Col lg="6">
              <TextField
                label={"FTP Username"}
                placeholderLabel="Enter the FTP User Name"
                field="username"
                defaultValue="josh-ftp"
                className="creator-form"
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}
          {(this.state.uploadType === "ftp" ||
            this.state.uploadType === "ftps" ||
            this.state.uploadType === "crossplatform") && (
            <Col lg="6">
              <TextField
                label={"FTP Password"}
                placeholderLabel="Enter the FTP Password"
                field="password"
                defaultValue="o7BgYwwnag"
                className="creator-form"
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col style={{ marginTop: "10px" }}>
            <Upload
              showUploadList={true}
              onRemove={this._onVideoRemove}
              defaultFileList={this.state.csvFileList}
              fileList={this.state.csvFileList}
              customRequest={this._uploadCSV}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <Button color="primary" variant="contained">
                <i
                  className="bx bx-upload
me-2"
                />{" "}
                Click to upload CSV
              </Button>
            </Upload>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={this.state.loading}
                className="font-16 btn-block btn btn-primary"
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                {/* <i className="bx bx-upload me-1" /> */}
                Submit
              </Button>
              <Button
                color="error"
                variant="contained"
                size="default"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (!this.state.csvFile) {
      toastr.error("Please upload the csv !!")
      return
    }

    formData.bulk_upload = true

    let data = new FormData()
    data.append("file", this.state.csvFile)
    data.append("details", JSON.stringify(formData))

    this.props.dispatch(uploadUser(data))
    this.props.onCancel()
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    loading: _.get(store, "user.loading"),
    common: _.get(store, "common"),
  }
}

export default connect(mapStateToProps)(UploadBulkUserForm)
