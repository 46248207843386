import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { Tabs } from "antd"
import { Container } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import { getMemes } from "appRedux/slices/meme"
import MemeReorderModel from "./memeReorderModel"
import { LANGUAGE_MAPPING } from "../../constants/uiConstants"

const { TabPane } = Tabs

class MemeReorder extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      reorderData: false,
      activeTab: "bn",
      hasEditPermission:
        this.props.currentUser &&
        this.props.currentUser.permissions &&
        this.props.currentUser.permissions.includes("EDIT_BROWSE_MEME"),
    }
  }

  componentDidMount() {
    this.props.dispatch(
      getMemes({ filters: { currentPage: 1, pageSize: 1000 } })
    )
  }

  _renderCards = () => {
    const memeDataByLang = {}
    Object.keys(LANGUAGE_MAPPING).map(lang => {
      const memeData = []
      this.props.memeList.map(meme => {
        if (meme.target && meme.target.length) {
          const langList = meme.target.map(
            ({ lang_code = "" } = {}) => lang_code
          )
          if (langList.includes(lang)) {
            const idx = langList.indexOf(lang)
            const lang_view_order =
              meme.target[idx].lang_code === lang
                ? meme.target[idx].view_order
                : undefined
            memeData.push({ ...meme, lang_view_order })
          }
        }
      })
      memeData.sort((a, b) => a.lang_view_order - b.lang_view_order)
      memeDataByLang[lang] = memeData
    })

    return (
      <MemeReorderModel
        {...this.props}
        data={memeDataByLang}
        activeTab={this.state.activeTab}
        setReorderData={value => this.setState({ reorderData: value })}
      />
    )
  }

  render() {
    let tabs = Object.keys(LANGUAGE_MAPPING) || []
    return (
      <Auxiliary
        loading={this.props.loading && tabs.length !== 0}
        error={_.get(this.props, "common.error")}
      >
        <Container>
          {tabs && tabs.length > 0 && (
            <Tabs
              onChange={key => this.setState({ activeTab: key })}
              type="card"
              activeKey={
                this.state.activeTab || (tabs && tabs.length > 0 && tabs[0])
              }
            >
              {tabs.map(tab => {
                return (
                  <TabPane
                    tab={LANGUAGE_MAPPING && LANGUAGE_MAPPING[tab]}
                    key={tab}
                    disabled={this.state.reorderData}
                  >
                    {!_.isEmpty(this.props.memeList) && this._renderCards()}
                  </TabPane>
                )
              })}
            </Tabs>
          )}
        </Container>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "meme.loading"),
    memeList: _.get(store, "meme.memeList"),
  }
}

export default connect(mapStateToProps)(MemeReorder)
