const _ = require("lodash")
import {
  emotionsData,
  orthogonalAttributeData,
  audioVulgarityData,
} from "constants/taxonomy"
import { jsonData as videoVulgarityData } from "components/TaxonomyDashboard/videoVulgarity"

export const transformToESPayload = (
  existingTaxonomy,
  taxonomyObj,
  content
) => {
  let existingTaxonomyClonedObj = JSON.parse(JSON.stringify(existingTaxonomy))
  existingTaxonomyClonedObj["version"] = "v2"
  let taxonomyClonedObj = JSON.parse(JSON.stringify(taxonomyObj))
  if (
    !_.isEmpty(taxonomyClonedObj["taxonomy_emotions"]) &&
    taxonomyClonedObj["taxonomy_emotions"]["heart_touching"] !== undefined &&
    taxonomyClonedObj["taxonomy_emotions"]["heart_touching"] === true
  ) {
    let emotions = taxonomyClonedObj["taxonomy_emotions"]
    if (emotions["heart_touching"] === true) {
      let types = []
      let data = emotionsData.filter(ele => ele.parent === "heart_touching")
      data.forEach(item => {
        if (emotions[item.key] === true) {
          types.push(item.key)
        }
      })
      if (types.length > 0) {
        existingTaxonomyClonedObj["taxonomy_emotions"]["type_v2"] = types
      }
    } else {
      existingTaxonomyClonedObj["taxonomy_emotions"]["type_v2"] = ["not_sure"]
    }

    if ("heart_touching" in existingTaxonomyClonedObj["taxonomy_emotions"]) {
      delete existingTaxonomyClonedObj["taxonomy_emotions"]["heart_touching"]
    }
  } else {
    existingTaxonomyClonedObj["taxonomy_emotions"]["type_v2"] = ["not_sure"]
  }

  if (!_.isEmpty(taxonomyClonedObj["taxonomy_video_quality"])) {
    let taxonomyVideoQuality = taxonomyClonedObj["taxonomy_video_quality"]
    existingTaxonomyClonedObj["taxonomy_video_quality"]["competition_logo"] =
      taxonomyVideoQuality["competition_logo"]
    existingTaxonomyClonedObj["taxonomy_video_quality"]["content_quality"] =
      taxonomyVideoQuality["content_quality"]
    existingTaxonomyClonedObj["taxonomy_video_quality"]["video_vulgarity"] =
      taxonomyVideoQuality["video_vulgarity"]
    if (
      taxonomyVideoQuality["is_video_vulgar"] !== undefined &&
      taxonomyVideoQuality["is_video_vulgar"] === true
    ) {
      let data = videoVulgarityData.filter(ele => ele.key === "is_video_vulgar")
      if (data && data.length === 1) {
        let types = getQuerstionnaireKeys(
          data[0].dependentQuestionnaire,
          taxonomyVideoQuality
        )
        if (types.length > 0) {
          existingTaxonomyClonedObj["taxonomy_video_quality"][
            "video_vulgarity_v2"
          ] = types
        }
      }
    }

    if (taxonomyVideoQuality["audio_present"] === true) {
      existingTaxonomyClonedObj["taxonomy_video_quality"]["audio_present"] =
        "yes"
    }

    if (taxonomyVideoQuality["audio_present"] === false) {
      existingTaxonomyClonedObj["taxonomy_video_quality"]["audio_present"] =
        "no"
    }

    if (
      taxonomyVideoQuality["is_audio_vulgar"] !== undefined &&
      taxonomyVideoQuality["is_audio_vulgar"] === true
    ) {
      let types = []
      let data = audioVulgarityData.filter(
        ele => ele.parent === "is_audio_vulgar"
      )
      data.forEach(item => {
        if (taxonomyVideoQuality[item.key] === true) {
          types.push(item.key)
        }
      })
      if (types.length > 0) {
        existingTaxonomyClonedObj["taxonomy_video_quality"][
          "audio_vulgarity_v2"
        ] = types
      }
    }

    if (
      "is_video_vulgar" in existingTaxonomyClonedObj["taxonomy_video_quality"]
    ) {
      delete existingTaxonomyClonedObj["taxonomy_video_quality"][
        "is_video_vulgar"
      ]
    }

    if (
      "is_audio_vulgar" in existingTaxonomyClonedObj["taxonomy_video_quality"]
    ) {
      delete existingTaxonomyClonedObj["taxonomy_video_quality"][
        "is_audio_vulgar"
      ]
    }
  }

  if (!_.isEmpty(taxonomyClonedObj["taxonomy_elements"])) {
    let taxonomyElements = taxonomyClonedObj["taxonomy_elements"]

    if (!_.isEmpty(taxonomyElements["background"])) {
      existingTaxonomyClonedObj["taxonomy_elements"]["background"] =
        taxonomyElements["background"]
    }
    if (!_.isEmpty(taxonomyElements["city_tier"])) {
      existingTaxonomyClonedObj["taxonomy_elements"]["city_tier"] =
        taxonomyElements["city_tier"]
    }

    existingTaxonomyClonedObj["taxonomy_elements"]["people_in_video"] =
      getPeopleInVideo(
        taxonomyElements["male"],
        taxonomyElements["female"],
        taxonomyElements["child"]
      )
  }

  if (taxonomyClonedObj["taxonomy_genre"]) {
    existingTaxonomyClonedObj["taxonomy_genre"]["target_type"] =
      taxonomyClonedObj["taxonomy_genre"]["target_type"]
    if (taxonomyClonedObj["taxonomy_genre"]["target_sub_type"]) {
      existingTaxonomyClonedObj["taxonomy_genre"]["target_sub_type"] =
        taxonomyClonedObj["taxonomy_genre"]["target_sub_type"]
    }
    if (taxonomyClonedObj["taxonomy_genre"]["target_theme"]) {
      existingTaxonomyClonedObj["taxonomy_genre"]["target_theme"] =
        taxonomyClonedObj["taxonomy_genre"]["target_theme"]
    }
  } else {
    taxonomyClonedObj["taxonomy_genre"] = {}
  }

  if (taxonomyClonedObj["video_quality_attributes"]) {
    const rating = Object.values(
      taxonomyClonedObj["video_quality_attributes"]
    ).filter(val => val === true).length
    existingTaxonomyClonedObj["video_quality_attributes"] =
      taxonomyClonedObj["video_quality_attributes"]
    existingTaxonomyClonedObj["video_quality_attributes"]["rating"] = rating
  }

  if (!_.isEmpty(taxonomyClonedObj["taxonomy_violence"])) {
    const violenceObject = { ...taxonomyClonedObj["taxonomy_violence"] }
    const tempObj = {}
    if (violenceObject.violence_type) {
      tempObj.type = violenceObject.violence_type
      tempObj.value =
        typesForViolenceAndGlamour["violence"][violenceObject.violence_type]
    } else {
      tempObj.type = "no_violence"
      tempObj.value = 0
    }
    existingTaxonomyClonedObj["taxonomy_video_quality"]["video_violence"] = {
      ...tempObj,
    }
    delete existingTaxonomyClonedObj["taxonomy_violence"]
  }
  if (!_.isEmpty(taxonomyClonedObj["video_additional_info"])) {
    existingTaxonomyClonedObj["video_additional_info"]["ethnicity"] =
      taxonomyClonedObj["video_additional_info"]["ethnicity"]
    existingTaxonomyClonedObj["video_additional_info"]["people_count"] =
      taxonomyClonedObj["video_additional_info"]["people_count"]
  }
  if (!_.isEmpty(taxonomyClonedObj["taxonomy_glamour"])) {
    const glamourObject = { ...taxonomyClonedObj["taxonomy_glamour"] }
    const tempObj = {}
    if (glamourObject.glamour_type) {
      let value = glamourObject.glamour_type
      if (value.indexOf("#") >= 0) {
        value = value.split("#")[0]
      }
      tempObj.type = value
      tempObj.value = typesForViolenceAndGlamour["glamour"][value]
      tempObj.sub_type = glamourObject.sub_type
      if (glamourObject.glamour_grade) {
        tempObj.grade = glamourObject.glamour_grade
      }
      if (glamourObject.glamour_region) {
        tempObj.region = glamourObject.glamour_region
      }
    } else {
      tempObj.type = "non_glamour"
      tempObj.value = 0
      tempObj.grade = ""
      tempObj.region = ""
    }
    existingTaxonomyClonedObj["taxonomy_video_quality"]["video_glamour"] = {
      ...tempObj,
    }
    delete existingTaxonomyClonedObj["taxonomy_glamour"]
  }

  if (!_.isEmpty(taxonomyClonedObj["orthogonal_attributes"])) {
    let data = orthogonalAttributeData
    if (data && data.length > 0) {
      let types = getQuerstionnaireKeys(
        data,
        taxonomyClonedObj["orthogonal_attributes"]
      )
      existingTaxonomyClonedObj["orthogonal_attributes"] = types
    }
  } else {
    existingTaxonomyClonedObj["orthogonal_attributes"] = []
  }

  if (taxonomyClonedObj["skip_reason"]) {
    existingTaxonomyClonedObj["skip_reason"] = taxonomyClonedObj["skip_reason"]
    if (taxonomyClonedObj["skip_reason"] == "competitor_logo") {
      existingTaxonomyClonedObj["competitor_logo_name"] =
        taxonomyClonedObj["competitor_logo_name"]
      if (taxonomyClonedObj["competitor_logo_name"] == "others") {
        existingTaxonomyClonedObj["competitor_logo_name_text"] =
          taxonomyClonedObj["competitor_logo_name_text"]
      }
    }
    if (taxonomyClonedObj["skip_reason"] == "banned_organization") {
      existingTaxonomyClonedObj["banned_organization_name"] =
        taxonomyClonedObj["banned_organization_name"]
    }
  }

  if (!_.isEmpty(taxonomyClonedObj["target_languages"])) {
    const langObj = taxonomyClonedObj["target_languages"]
    if (langObj.can_be_targeted === true) {
      if (langObj.language_type === "all") {
        existingTaxonomyClonedObj["target_languages"] = {
          type: "all",
          value: [
            "hi",
            "en",
            "te",
            "bh",
            "kn",
            "ta",
            "bn",
            "ml",
            "mr",
            "pa",
            "gu",
            "or",
          ],
        }
      } else if (langObj.language_type === "north") {
        existingTaxonomyClonedObj["target_languages"] = {
          type: "north",
          value: ["hi", "en", "bh", "bn", "mr", "pa", "gu", "or"],
        }
      } else if (langObj.language_type === "south") {
        existingTaxonomyClonedObj["target_languages"] = {
          type: "south",
          value: ["te", "ta", "kn", "ml"],
        }
      } else {
        existingTaxonomyClonedObj["target_languages"] = {
          type: "few",
          value: langObj.languages,
        }
      }
    } else {
      existingTaxonomyClonedObj["target_languages"] = {
        type: "none",
        value: [content.lang_code],
      }
    }
  }
  return existingTaxonomyClonedObj
}

export const parseESPayload = taxonomyObj => {
  let taxonomyClonedObj = JSON.parse(JSON.stringify(taxonomyObj))
  if (_.isEmpty(taxonomyClonedObj["taxonomy_genre"])) {
    taxonomyClonedObj["taxonomy_genre"] = {}
  }
  if (
    !_.isEmpty(taxonomyClonedObj["taxonomy_emotions"]) &&
    taxonomyClonedObj["taxonomy_emotions"]["type_v2"] !== undefined
  ) {
    let emotionsObj = taxonomyClonedObj["taxonomy_emotions"]
    if (
      emotionsObj["type_v2"].length > 0 &&
      !emotionsObj["type_v2"].includes("not_sure")
    ) {
      let emotionTypes = emotionsObj["type_v2"]
      emotionsObj["heart_touching"] = true
      emotionTypes.map(key => {
        emotionsObj[key] = true
      })
    } else {
      emotionsObj["heart_touching"] = false
    }
  } else {
    taxonomyClonedObj["taxonomy_emotions"] = {}
  }

  if (!_.isEmpty(taxonomyClonedObj["taxonomy_video_quality"])) {
    if (
      taxonomyClonedObj["taxonomy_video_quality"]["video_vulgarity_v2"] !==
      undefined
    ) {
      let videoQualityParams = taxonomyClonedObj["taxonomy_video_quality"]
      if (videoQualityParams["video_vulgarity_v2"].length > 0) {
        videoQualityParams["is_video_vulgar"] = true
        let data = videoVulgarityData.filter(
          ele => ele.key === "is_video_vulgar"
        )
        if (data && data.length === 1) {
          for (let quest of data[0].dependentQuestionnaire) {
            videoQualityParams[quest.key] = videoQualityParams[
              "video_vulgarity_v2"
            ].includes(quest.key)
          }
        }
      } else {
        videoQualityParams["is_video_vulgar"] = false
      }
    }

    if (
      taxonomyClonedObj["taxonomy_video_quality"]["video_vulgarity"] ===
      "highly_vulgar"
    ) {
      taxonomyClonedObj["skip_reason"] = "highly_vulgar"
    }

    if (
      taxonomyClonedObj["taxonomy_video_quality"]["content_quality"] ===
      "bad_quality"
    ) {
      taxonomyClonedObj["skip_reason"] = "bad_quality"
    }

    if (
      taxonomyClonedObj["taxonomy_video_quality"]["competition_logo"] === "yes"
    ) {
      taxonomyClonedObj["skip_reason"] = "competitor_logo"
    }

    if (
      taxonomyClonedObj["taxonomy_video_quality"]["audio_vulgarity_v2"] !==
      undefined
    ) {
      let audioQualityParams = taxonomyClonedObj["taxonomy_video_quality"]
      if (audioQualityParams["audio_vulgarity_v2"].length > 0) {
        audioQualityParams["is_audio_vulgar"] = true
        audioQualityParams["audio_vulgarity_v2"].forEach(type => {
          audioQualityParams[type] = true
        })
      } else {
        audioQualityParams["is_audio_vulgar"] = false
      }
    }

    if (
      taxonomyClonedObj["taxonomy_video_quality"]?.["audio_present"] === "yes"
    ) {
      taxonomyClonedObj["taxonomy_video_quality"]["audio_present"] = true
    }

    if (
      taxonomyClonedObj["taxonomy_video_quality"]?.["audio_present"] === "no"
    ) {
      taxonomyClonedObj["taxonomy_video_quality"]["audio_present"] = false
    }

    if (
      !_.isEmpty(taxonomyClonedObj["taxonomy_video_quality"]["video_violence"])
    ) {
      taxonomyClonedObj["taxonomy_violence"] = {}
      taxonomyClonedObj["taxonomy_violence"]["violence_type"] =
        taxonomyClonedObj["taxonomy_video_quality"]["video_violence"]["type"]
      delete taxonomyClonedObj["taxonomy_video_quality"]["video_violence"]
    }

    if (
      !_.isEmpty(taxonomyClonedObj["taxonomy_video_quality"]["video_glamour"])
    ) {
      taxonomyClonedObj["taxonomy_glamour"] = {}
      taxonomyClonedObj["taxonomy_glamour"]["glamour_type"] =
        taxonomyClonedObj["taxonomy_video_quality"]["video_glamour"]["type"]
      taxonomyClonedObj["taxonomy_glamour"]["sub_type"] =
        taxonomyClonedObj["taxonomy_video_quality"]["video_glamour"]["sub_type"]
      if (
        taxonomyClonedObj["taxonomy_video_quality"]["video_glamour"]["grade"]
      ) {
        taxonomyClonedObj["taxonomy_glamour"]["glamour_grade"] =
          taxonomyClonedObj["taxonomy_video_quality"]["video_glamour"]["grade"]
      }
      if (
        taxonomyClonedObj["taxonomy_video_quality"]["video_glamour"]["region"]
      ) {
        taxonomyClonedObj["taxonomy_glamour"]["glamour_region"] =
          taxonomyClonedObj["taxonomy_video_quality"]["video_glamour"]["region"]
      }
      delete taxonomyClonedObj["taxonomy_video_quality"]["video_glamour"]
    }
  } else {
    taxonomyClonedObj["taxonomy_video_quality"] = {}
  }

  let taxonomyElements = taxonomyClonedObj["taxonomy_elements"]

  if (!_.isEmpty(taxonomyElements)) {
    // Considering all the values of the earlier taxonomy as well
    if (taxonomyElements["people_in_video"] !== undefined) {
      let peopleInVideo = taxonomyElements["people_in_video"]
      taxonomyElements["male"] = [
        "family",
        "female_n_male",
        "male_with_kids",
        "male",
        "couple",
        "couple_with_kids",
        "male_only_friends_group",
        "mixed_friends_group",
      ].includes(peopleInVideo)
      taxonomyElements["female"] = [
        "family",
        "female_n_male",
        "female_with_kids",
        "female",
        "couple",
        "couple_with_kids",
        "female_n_female",
        "female_only_friends_group",
        "mixed_friends_group",
      ].includes(peopleInVideo)
      taxonomyElements["child"] = [
        "family",
        "male_with_kids",
        "female_with_kids",
        "young_kid",
        "kid_above5_yrs",
        "teen_above_10yrs",
        "couple_with_kids",
        "male_n_male",
      ].includes(peopleInVideo)
    }
  } else {
    taxonomyClonedObj["taxonomy_elements"] = {}
  }

  if (
    !_.isEmpty(taxonomyObj["orthogonal_attributes"]) &&
    taxonomyObj["orthogonal_attributes"].length > 0
  ) {
    let data = orthogonalAttributeData
    if (data && data.length > 0) {
      taxonomyClonedObj["orthogonal_attributes"] = {}
      for (let quest of data) {
        taxonomyClonedObj["orthogonal_attributes"][quest.key] = taxonomyObj[
          "orthogonal_attributes"
        ].includes(quest.key)
      }
    }
  } else {
    taxonomyClonedObj["orthogonal_attributes"] = {}
  }

  // if (_.isEmpty(taxonomyClonedObj["taxonomy_event"])) {
  //   taxonomyClonedObj["taxonomy_event"] = {}
  // }

  // if (_.isEmpty(taxonomyClonedObj["zone_obj"])) {
  //   taxonomyClonedObj["zone_obj"] = []
  // }

  if (_.isEmpty(taxonomyClonedObj["video_quality_attributes"])) {
    taxonomyClonedObj["video_quality_attributes"] = {}
  }

  if (!_.isEmpty(taxonomyClonedObj["target_languages"])) {
    if (taxonomyClonedObj["target_languages"].type === "none") {
      taxonomyClonedObj["target_languages"].can_be_targeted = false
    } else {
      taxonomyClonedObj["target_languages"].can_be_targeted = true
    }
    taxonomyClonedObj["target_languages"].language_type =
      taxonomyClonedObj["target_languages"].type
    taxonomyClonedObj["target_languages"].languages =
      taxonomyClonedObj["target_languages"].value

    delete taxonomyClonedObj["target_languages"].type
    delete taxonomyClonedObj["target_languages"].value
  } else {
    taxonomyClonedObj["target_languages"] = {}
  }

  if (_.isEmpty(taxonomyClonedObj["taxonomy_glamour"])) {
    taxonomyClonedObj["taxonomy_glamour"] = {
      glamour_type: "",
    }
  }

  if (_.isEmpty(taxonomyClonedObj["taxonomy_violence"])) {
    taxonomyClonedObj["taxonomy_violence"] = {
      violence_type: "",
    }
  }

  return taxonomyClonedObj
}

function getQuerstionnaireKeys(dependentQuestionnaire, selectedData) {
  let types = []
  for (let quest of dependentQuestionnaire) {
    if (
      selectedData[quest.key] !== undefined &&
      selectedData[quest.key] === true
    ) {
      types.push(quest.key)
    }
  }
  return types
}

function getPeopleInVideo(male, female, child) {
  if (male && female && child) {
    return "family"
  }

  if (male && female) {
    return "female_n_male"
  }

  if (male && child) {
    return "male_with_kids"
  }

  if (female && child) {
    return "female_with_kids"
  }

  if (male) {
    return "male"
  }

  if (female) {
    return "female"
  }

  if (child) {
    return "young_kid"
  }
  return "none"
}

const typesForViolenceAndGlamour = {
  glamour: {
    non_glamour: 0,
    pretty_girl: 1,
    glamorous: 2,
    classy_hot_sexy: 3,
    masala: 4,
    acceptable_child_nudity: 6,
    partial_adult_nudity: 7,
    molestation: 8,
    unacceptable_child_nudity: 9,
    full_adult_nudity: 10,
    vulgar_masala: 5,
  },
  violence: {
    no_violence: 0,
    legal_display_of_weapons: 1,
    animal_sports: 2,
    physical_abuse: 3,
  },
}

const labelsForViolenceAndGlamour = {
  glamour: {
    non_glamour: "No Glamour",
    masala_hot_sexy:
      "The woman is wearing VERY skimpy clothes, and the audio-visual seems to showcase very crude sexual movements or suggestiveness",
    glamorous:
      "The woman is wearing VERY skimpy clothes, but the audio-visual is classy (premium or international)",
    classy_hot_sexy:
      "The woman is wearing sexy clothes, with lots of skin visible, but is not making any sexually suggestive moves or actions",
    pretty_girl:
      "The woman is wearing pretty clothes, with low/ minimal skin show, and is mainly trying to look pretty",
    body_art:
      "Showcasing body art and tattoos where the focus is on the art; no sexual movements in the video; upper body nudity is allowed but not genitals",
    acceptable_child_nudity_a: "The focus is on nude child's cuteness",
    acceptable_child_nudity_b:
      "The focus is on partailly nude child's cuteness",
    partial_adult_nudity_a: "STRONG ARTISTIC FOCUS & ONLY UPPER BODY NUDITY",
    partial_adult_nudity_b: "NO ARTISTIC FOCUS, BUT NO GENITALS VISIBLE",
    partial_adult_nudity_c:
      "Very transparent clothing over the breasts & genital area",
    partial_adult_nudity_d:
      "Non-transparent clothing, only barely covering the breasts & genital area",
    molestation:
      "A man sexually touching a woman without her permission; videos that seem to capture women in compromising positions/ nudity without their permission; hidden camera",
    unacceptable_child_nudity_a:
      "The focus is on nude child's sexuality/genitals",
    unacceptable_child_nudity_b:
      "The focus is on partailly nude child's sexuality/genitals",
    full_adult_nudity_a: "STRONG ARTISTIC FOCUS & ONLY UPPER BODY NUDITY",
    full_adult_nudity_b: "NO ARTISTIC FOCUS, BUT NO GENITALS VISIBLE",
  },
  violence: {
    no_violence: "No Violence",
    legal_display_of_weapons: "Legal display of weapons in the video",
    animal_sports: "Animal being used for Sports (no killing or bloodshed)",
    physical_abuse: "Physical Abuse to Humans",
    public_protest: "Public protest",
    property_damage: "Property damage",
  },
}

export const findLabelForGlamourAndViolence = (tab, type, subType) => {
  if (tab === "GLAMOUR") {
    let key = type
    if (subType) {
      key = `${type}_${subType}`
    }
    return labelsForViolenceAndGlamour["glamour"][key]
  } else {
    return labelsForViolenceAndGlamour["violence"][type]
  }
}

export const clearStageWiseData = (taxonomyMeta, stage) => {
  let updatedDetails = {}
  // const stageOneKeys = ["taxonomy_genre", "taxonomy_video_quality", "version"]
  const stageOneKeys = [
    "taxonomy_genre",
    "taxonomy_video_quality",
    "skip_reason",
    "competitor_logo_name",
    "competitor_logo_name_text",
    "banned_organization_name",
    "video_additional_info",
    "version",
  ]
  const stageTwoKeys = [
    "taxonomy_genre",
    "taxonomy_video_quality",
    "orthogonal_attributes",
    "skip_reason",
    "competitor_logo_name",
    "competitor_logo_name_text",
    "banned_organization_name",
    "version",
  ]
  const stageThreeKeys = [
    "taxonomy_genre",
    "taxonomy_video_quality",
    "orthogonal_attributes",
    "taxonomy_elements",
    "target_languages",
    "taxonomy_emotions",
    "skip_reason",
    "competitor_logo_name",
    "competitor_logo_name_text",
    "banned_organization_name",
    "version",
  ]
  if (stage === "zone_approval") return taxonomyMeta
  if (stage === "labeling_stage_one") {
    Object.keys(taxonomyMeta).forEach(key => {
      if (stageOneKeys.includes(key)) {
        updatedDetails[key] = taxonomyMeta[key]
      }
    })
  }

  if (stage === "labeling_stage_two") {
    Object.keys(taxonomyMeta).forEach(key => {
      if (stageTwoKeys.includes(key)) {
        updatedDetails[key] = taxonomyMeta[key]
      }
    })
  }

  if (stage === "labeling_stage_three") {
    Object.keys(taxonomyMeta).forEach(key => {
      if (stageThreeKeys.includes(key)) {
        updatedDetails[key] = taxonomyMeta[key]
      }
    })
  }
  return updatedDetails
}

export const buildActivityLog = (
  currentData,
  previousData,
  stage,
  selectedDetails,
  zoneInfov1
) => {
  let activityLog = {}
  let added = []
  let modified = []
  let preVal
  if (currentData.skip_reason) {
    let status = "added"
    if (
      previousData.skip_reason &&
      currentData.skip_reason !== previousData.skip_reason
    ) {
      modified = ["skip_reason"]
      status = "modified"
      preVal = previousData.skip_reason
    } else {
      added = ["skip_reason"]
    }
    activityLog.skip_reason = {
      status,
      value: currentData.skip_reason,
      preVal: preVal,
    }
    if (currentData.skip_reason === "competitor_logo") {
      activityLog.skip_reason.competitor_logo_name =
        currentData.competitor_logo_name
      activityLog.skip_reason.competitor_logo_name_text =
        currentData.competitor_logo_name_text
    }
    if (currentData.skip_reason === "banned_organization") {
      activityLog.skip_reason.banned_organization_name =
        currentData.banned_organization_name
    }
    activityLog.added = added
    activityLog.modified = modified

    return activityLog
  }

  if (
    stage === "labeling_stage_one"
    // ||
    // stage === "labeling_stage_two" ||
    // stage === "labeling_stage_three"
  ) {
    // activityLog.genre = {
    //   status: "added",
    //   value: {
    //     target_type:
    //       currentData.taxonomy_genre && currentData.taxonomy_genre.target_type,
    //   },
    // }
    activityLog.audio_vulgariy = {
      status: "added",
      value: {
        audio_present: currentData.taxonomy_video_quality.audio_present,
        audio_vulgarity_v2:
          currentData.taxonomy_video_quality.audio_vulgarity_v2,
      },
    }
    activityLog.violence = {
      status: "added",
      value: currentData.taxonomy_video_quality.video_violence,
    }
    activityLog.glamour = {
      status: "added",
      value: currentData.taxonomy_video_quality.video_glamour,
    }
    // activityLog.ethnicity = {
    //   status: "added",
    //   value: currentData.video_additional_info.ethnicity,
    // }
    // activityLog.people_count = {
    //   status: "added",
    //   value: currentData.video_additional_info.people_count,
    // }
    // activityLog.zone_info = {
    //   status: "added",
    //   value: zoneInfov1,
    // }
    if (stage === "labeling_stage_one") {
      added = [
        "audio_vulgariy",
        "violence",
        "glamour",
        // "ethnicity",
        // "people_count",
      ]
    }
  }

  // if (stage === "labeling_stage_two" || stage === "labeling_stage_three") {
  //   let prev_target_Type = previousData?.taxonomy_genre?.target_type
  //   let curr_target_type = currentData?.taxonomy_genre?.target_type
  //   if (prev_target_Type !== curr_target_type) {
  //     activityLog.genre.status = "modified"
  //     activityLog.genre.preVal = prev_target_Type
  //     modified.push("genre")
  //   }

  //   if (
  //     previousData?.taxonomy_video_quality?.audio_present !==
  //     currentData?.taxonomy_video_quality?.audio_present
  //   ) {
  //     activityLog.audio_vulgariy.status = "modified"
  //     activityLog.audio_vulgariy.preVal =
  //       previousData?.taxonomy_video_quality?.audio_present
  //     modified.push("audio_vulgariy")
  //   } else {
  //     if (
  //       !_.isEqual(
  //         previousData?.taxonomy_video_quality?.audio_vulgarity_v2,
  //         previousData?.taxonomy_video_quality?.audio_vulgarity_v2
  //       )
  //     ) {
  //       activityLog.audio_vulgariy.status = "modified"
  //       activityLog.audio_vulgariy.preVal =
  //         previousData?.taxonomy_video_quality?.audio_vulgarity_v2
  //       modified.push("audio_vulgariy")
  //     }
  //   }
  //   let prev_violence_Type =
  //     previousData?.taxonomy_video_quality?.video_violence?.type
  //   let curr_violence_type =
  //     currentData?.taxonomy_video_quality?.video_violence?.type
  //   if (prev_violence_Type !== curr_violence_type) {
  //     activityLog.violence.status = "modified"
  //     activityLog.violence.preVal = prev_violence_Type
  //     modified.push("violence")
  //   }

  //   if (
  //     currentData?.taxonomy_video_quality?.video_glamour?.type !==
  //     previousData?.taxonomy_video_quality?.video_glamour?.type
  //   ) {
  //     activityLog.glamour.status = "modified"
  //     activityLog.glamour.preVal =
  //       previousData?.taxonomy_video_quality?.video_glamour?.type
  //     modified.push("glamour")
  //   }

  //   activityLog.orthogonal_attributes = {
  //     status: "added",
  //     value: currentData.orthogonal_attributes,
  //   }
  //   activityLog.zone_info = {
  //     status: "added",
  //     value: zoneInfov1,
  //   }

  //   if (stage === "labeling_stage_two") {
  //     added = ["orthogonal_attributes"]
  //   }

  //   if (currentData?.taxonomy_genre?.target_sub_type) {
  //     activityLog.sub_genre = {
  //       status: "added",
  //       value: {
  //         target_sub_type: currentData?.taxonomy_genre?.target_sub_type,
  //         target_theme: currentData?.taxonomy_genre?.target_theme,
  //       },
  //     }

  //     if (stage === "labeling_stage_two") {
  //       added.push("sub_genre")
  //     }
  //   }
  //   if (
  //     !_.isEmpty(selectedDetails?.approved_zone_obj?.qcApprovedZones) ||
  //     !_.isEmpty(selectedDetails?.approved_zone_obj?.qcRejectedZones)
  //   ) {
  //     activityLog.zone = {
  //       status: "added",
  //       value: {
  //         approved_zone: selectedDetails?.approved_zone_obj?.qcApprovedZones
  //           ? selectedDetails.approved_zone_obj.qcApprovedZones
  //           : [],
  //         rejected_zone: selectedDetails?.approved_zone_obj?.qcRejectedZones
  //           ? selectedDetails.approved_zone_obj.qcRejectedZones
  //           : [],
  //       },
  //     }
  //     added.push("zone")
  //   }
  // }

  // if (stage === "labeling_stage_three") {
  //   if (
  //     previousData?.taxonomy_genre?.target_sub_type !==
  //     currentData?.taxonomy_genre?.target_sub_type
  //   ) {
  //     if (activityLog.sub_genre) {
  //       activityLog.sub_genre.status = "modified"
  //       activityLog.sub_genre.preVal =
  //         previousData?.taxonomy_genre?.target_sub_type
  //       modified.push("sub_genre")
  //     }
  //   }
  //   if (
  //     !_.isEqual(
  //       previousData.orthogonal_attributes,
  //       currentData.orthogonal_attributes
  //     )
  //   ) {
  //     activityLog.orthogonal_attributes.status = "modified"
  //     let prevOrth = []
  //     if (_.isArray(previousData.orthogonal_attributes)) {
  //       prevOrth = previousData.orthogonal_attributes
  //     } else {
  //       for (let i in previousData.orthogonal_attributes) {
  //         if (previousData.orthogonal_attributes[i]) {
  //           prevOrth.push(i)
  //         }
  //       }
  //     }

  //     activityLog.orthogonal_attributes.preVal = prevOrth
  //     modified.push("orthogonal_attributes")
  //   }

  //   if (
  //     !_.isEmpty(selectedDetails?.approved_zone_obj?.qcApprovedZones) ||
  //     !_.isEmpty(selectedDetails?.approved_zone_obj?.qcRejectedZones)
  //   ) {
  //     activityLog.zone.status = "modified"
  //   }

  //   activityLog.emotions = {
  //     status: "added",
  //     value: currentData.taxonomy_emotions?.type_v2
  //       ? currentData.taxonomy_emotions.type_v2
  //       : ["not_sure"],
  //   }
  //   activityLog.target_languages = {
  //     status: "added",
  //     value: currentData.target_languages,
  //   }
  //   activityLog.people_in_video = {
  //     status: "added",
  //     value: currentData.taxonomy_elements.people_in_video,
  //   }
  //   activityLog.city_tier = {
  //     status: "added",
  //     value: currentData.taxonomy_elements.city_tier,
  //   }
  //   activityLog.zone_info = {
  //     status: "added",
  //     value: zoneInfov1,
  //   }
  //   added = ["emotions", "target_languages", "people_in_video", "city_tier"]
  // }

  activityLog.added = added
  activityLog.modified = modified

  return activityLog
}
