import Axios from "axios"
import toastr from "toastr"
import { sleep } from "util/common"

export const requestAudioDetails = async ({ payload }) => {
  try {
    let currentPage = parseInt(payload["currentPage"], 10) || 1
    const offset = currentPage - 1
    _.unset(payload, "currentPage")
    await sleep(1000)
    let data = await Axios.post(
      `/api/audio/getAudioListByFilter?offset=${offset}`,
      { filters: payload }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetAudioTabs = async () => {
  try {
    let data = await Axios.get(`/api/audio/tab/getAll`)
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestUpsertAudio = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/audio/upsert`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUploadAudio = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/audio/upload/bulk`, payload)
    const message = data && data.data
    toastr.info(message)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateAudioStatus = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/audio/updateStatus`, payload)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateAudioVisibility = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/audio/updateVisibility`, payload)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestPlaylist = async () => {
  try {
    let data = await Axios.get(`/api/audio/getPlaylistIds`)
    return data
  } catch (error) {
    throw error
  }
}
