import { createSlice } from "@reduxjs/toolkit"

const uiConstantsSlice = createSlice({
  name: "uiConstants",
  initialState: {
    languageList: [],
    loading: false,
  },
  reducers: {
    getLanguageData(state) {
      return { ...state, loading: true, languageList: [], agencyList: [] }
    },
    getLanguageDataSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        languageList: payload.data,
      }
    },
    getAgencyData(state) {
      return { ...state, loading: true, agencyList: [] }
    },
    getAgencyDataSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        agencyList: payload.data,
      }
    },
    resetLanguageState(state) {
      return {
        languageList: [],
        loading: false,
      }
    },
  },
})

export const {
  getLanguageData,
  getLanguageDataSuccess,
  getAgencyData,
  getAgencyDataSuccess,
  resetLanguageState,
} = uiConstantsSlice.actions

export default uiConstantsSlice.reducer
