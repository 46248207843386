import React, { Component } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Form, Input, Select, List } from "antd"
import { LANGUAGES } from "constants/uiConstants"
import { BiSolidUserCircle } from "react-icons/bi"
import { Button } from "@mui/material"
import { MdAdd } from "react-icons/md"
import { TiDelete } from "react-icons/ti"
import { createStrategy } from "clientServices/interestServices"
import toastr from "toastr"
import _ from "lodash"

const slots = {}

for (let key of LANGUAGES) {
  slots[key.value] = {
    data: Array.from({ length: 12 }).map((item, index) => {
      return {
        name: `Slot ${index + 1}`,
        combinations: {},
        selectedInterest: "",
        selectedType: "",
        contentUuids: "",
      }
    }),
  }
}

const { Option } = Select

export default class UpsertStrategyForm extends Component {
  state = {
    formData: {
      lang_code: "hi",
      slots,
      status: "START",
    },
    errors: {},
  }

  componentDidMount() {
    if (this.props.item) {
      let formData = {
        ..._.cloneDeep(this.state.formData),
        ...this.props.item,
      }

      delete formData?.name
      delete formData?.status
      delete formData?.created_by
      delete formData?.created_on
      delete formData?.modified_by
      delete formData?.modified_on

      this.setState({
        formData,
      })
    }
  }
  componentWillUnmount() {
    this.setState({
      formData: {
        lang_code: ["hi"],
        slots: Array.from({ length: 12 }).map((item, index) => {
          return {
            name: `Slot ${index + 1}`,
            combinations: {},
          }
        }),
        status: "START",
      },
    })
  }
  _findFormErrors = () => {
    const newErrors = {}
    const { name } = this.state.formData

    if (!name || name === "") newErrors.name = "This field is required"

    return newErrors
  }

  _handleAddCombination = index => {
    let formData = _.cloneDeep(this.state.formData)
    const slots = _.cloneDeep(formData.slots)
    let currentSlot = slots[this.state.formData.lang_code].data[index]

    const { selectedInterest, selectedType, contentUuids } = currentSlot
    if (selectedType === "content_uuids" && !contentUuids) return

    if (contentUuids) {
      currentSlot.combinations[selectedInterest] = contentUuids
    } else {
      currentSlot.combinations[selectedInterest] = selectedType
    }

    currentSlot.selectedInterest = ""
    currentSlot.selectedType = ""
    currentSlot.contentUuids = ""

    formData = {
      ...formData,
      slots,
    }

    this.setState({
      formData,
    })
  }

  _handleAddStrategy = async () => {
    const errors = this._findFormErrors()

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      })
      return
    }
    const formData = _.cloneDeep(this.state.formData)

    Object.keys(formData.slots).forEach(lang => {
      const data = formData.slots[lang].data
      data.forEach(slot => {
        Object.keys(slot.combinations).forEach(k => {
          ;(_.isEmpty(slot.combinations[k]) || k === "selectedInterest") &&
            delete slot.combinations[k]
          delete slot.selectedInterest
          delete slot.selectedType
          delete slot.contentUuids
        })
      })
    })

    let slotsFilled = true

    Object.keys(formData.slots).forEach(lang => {
      const data = formData.slots[lang].data
      data.forEach(slot => {
        if (_.isEmpty(slot.combinations)) {
          slotsFilled = false
          // return
        }
      })
    })

    if (!slotsFilled) {
      toastr.clear()
      toastr.error("Every slots should be filled for each target language...")
      return
    }

    // console.log('Hello')

    formData.mode = this.props.mode
    delete formData.lang_code

    try {
      const data = await createStrategy(formData)
      this.props.closeStrategyForm()
    } catch (error) {
      toastr.error("Strategy creation failed...")
    }
  }

  _handleFieldsChange = (value, field, index) => {
    let formData = _.cloneDeep(this.state.formData)

    if (field === "interest") {
      const slots = _.cloneDeep(formData.slots)
      let currentSlot = slots[this.state.formData.lang_code].data[index]
      currentSlot.selectedInterest = value
      formData = {
        ...formData,
        slots,
      }
    } else if (field === "type") {
      const slots = _.cloneDeep(formData.slots)
      let currentSlot = slots[this.state.formData.lang_code].data[index]
      currentSlot.selectedType = value
      formData = {
        ...formData,
        slots,
      }
    } else if (field === "content_uuids") {
      const slots = _.cloneDeep(formData.slots)
      let currentSlot = slots[this.state.formData.lang_code].data[index]
      currentSlot.contentUuids = value
      formData = {
        ...formData,
        slots,
      }
    } else {
      formData[field] = value
    }

    this.setState({
      formData,
      errors: {
        ...this.state.errors,
        [field]: null,
      },
    })
  }

  _removeCombination = (item, index) => {
    const formData = _.cloneDeep(this.state.formData)
    const slots = formData.slots

    let currentSlot = slots[this.state.formData.lang_code].data[index]
    delete currentSlot.combinations[item]

    this.setState({
      formData,
    })
  }

  _renderCombinations = (item, index) => {
    if (!_.isEmpty(item.combinations)) {
      const data = Object.keys(item.combinations).map(k => {
        if (
          k !== "type" &&
          k !== "selectedInterest" &&
          !_.isEmpty(item.combinations[k])
        )
          return (
            <li>
              {this.props.interestNameMappings[k]} &rarr;{" "}
              {item.combinations[k].toUpperCase()}
              <TiDelete
                color="red"
                className="mx-2"
                size={20}
                onClick={() => this._removeCombination(k, index)}
                role="button"
              />
            </li>
          )
      })
      return <ul className="mb-2">{data}</ul>
    }
  }

  _form = () => {
    const dataSource =
      this.state.formData.slots[this.state.formData.lang_code].data
    return (
      <Form layout="vertical" autoComplete="false">
        <Row>
          <Col lg="5">
            <Form.Item
              label="Strategy Name"
              validateStatus={this.state.errors?.name && "error"}
            >
              <Input
                type="text"
                placeholder="Add Strategy name.."
                onChange={e => this._handleFieldsChange(e.target.value, "name")}
                // value={this.state.formData.name}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col lg="6">
            <Form.Item label="Target Language">
              <Select
                // mode="multiple"
                showSearch
                size="large"
                allowClear={true}
                // placeholder="Include Languages"
                // defaultValue={props.filters["includeLang"]}
                onChange={value => {
                  this._handleFieldsChange(value, "lang_code")
                }}
                value={this.state.formData.lang_code}
                style={{ width: "100%" }}
              >
                {LANGUAGES.map(lang => (
                  <Option value={lang.value}>{lang.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <List
            itemLayout="vertical"
            size="large"
            className="mt-2"
            dataSource={dataSource}
            renderItem={(item, index) => (
              <Card className="border">
                {/* <CardTitle>{item.name}</CardTitle> */}

                <Row>
                  <Col lg="1" className="d-flex justify-content-center">
                    <div className="p-2">
                      <BiSolidUserCircle size={60} color="#1976d2" />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mt-2">
                      <h4>{item.name}</h4>
                    </div>
                    {this._renderCombinations(item, index)}
                  </Col>
                </Row>
                <Row className="m-2">
                  <Col lg="3">
                    <Select
                      showSearch
                      size="large"
                      allowClear={true}
                      placeholder="Choose Interest"
                      // defaultValue={props.filters["includeLang"]}
                      onChange={value => {
                        this._handleFieldsChange(value, "interest", index)
                      }}
                      filterOption={(inputValue, option) => {
                        return (
                          option.key
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        )
                      }}
                      value={item.selectedInterest}
                      style={{ width: "100%" }}
                    >
                      {this.props?.interestData.map(item => (
                        <Option value={item.id} key={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg="3">
                    <Select
                      showSearch
                      size="large"
                      allowClear={true}
                      placeholder="Choose Type"
                      // defaultValue={props.filters["includeLang"]}
                      onSelect={value => {
                        this._handleFieldsChange(value, "type", index)
                      }}
                      value={item.selectedType}
                      style={{ width: "100%" }}
                      className="mx-2"
                    >
                      <Option value="popular">Popular</Option>
                      <Option value="fresh">Fresh</Option>
                      <Option value="content_uuids">Content UUIDs</Option>
                    </Select>
                  </Col>

                  {item.selectedInterest &&
                    item.selectedType === "content_uuids" && (
                      <Col lg="4">
                        <Input
                          type="text"
                          placeholder="Content UUIDs"
                          onChange={e =>
                            this._handleFieldsChange(
                              e.target.value,
                              "content_uuids",
                              index
                            )
                          }
                          // value={this.state.formData.name}
                          value={item.contentUuids}
                          size="large"
                          className="mx-2"
                        />
                      </Col>
                    )}
                  <Col>
                    <Button
                      color="success"
                      variant="outlined"
                      onClick={() => {
                        this._handleAddCombination(index)
                      }}
                      startIcon={<MdAdd />}
                      size="large"
                      className="mx-2"
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Card>
            )}
          />
        </Row>
        <Row>
          {" "}
          <div className="d-flex justify-content-end mt-3">
            <Button
              color="success"
              variant="contained"
              startIcon={<MdAdd />}
              onClick={this._handleAddStrategy}
              size="large"
              style={{
                width: "100%",
              }}
            >
              Add
            </Button>
            <Button
              color="error"
              variant="contained"
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                return
              }}
              style={{
                width: "10%",
              }}
              onClick={() => this.props.toggleStrategy()}
              className="mx-2"
              size="large"
            >
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    )
  }
  render() {
    return (
      <Card>
        <Row className="mx-2 mt-3">
          <Col lg="12">
            <h4>
              <b> New Strategy</b>
            </h4>
          </Col>
        </Row>
        <CardBody>{this._form()}</CardBody>
      </Card>
    )
  }
}
