import { Col, Radio, Row, Input } from "antd"
import _ from "lodash"
import React from "react"
import { connect } from "react-redux"
import { Button } from "reactstrap"
const { Group } = Radio
import {
  competition_logos,
  banned_organizations,
} from "../../constants/uiConstants"
import ReactSelect from "react-select"

class Moderation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contentType:
        this.props.selectedContent.content_uuid.split("_")[0] === "i"
          ? "image"
          : null,
      labelToDisplay: "Moderation",
      currentHierarchy: ["moderation"],
      currentTab: "moderation",
      currentLevel: 0,
      zoneData: this.props.selectedContent?.taxonomy_meta?.zone_obj
        ? this.props.selectedContent.taxonomy_meta.zone_obj
        : this.props.selectedDetails?.zone_obj
        ? this.props.selectedDetails.zone_obj
        : [],
      selectedIds: [],
      selectedZones: [],
      zoneList: this.props.zoneList,
      selectedZoneCard: undefined,
      qcApprovedZones: [],
      qcRejectedZones: [],
      zoneInfo: this.props.selectedContent.zone_info,
      showOptions: false,
      selectedRejectReason: undefined,
      competitor_logo_name: undefined,
      competitor_logo_name_text: undefined,
      banned_organization_name: undefined,
      misc_reject_reason: undefined,
      isQCUser: false,
      isDeactive: false,
    }
  }

  componentDidMount() {
    const isQCUser = this.props.sourceData?.subType === "qc_moderation"
    let isDeactive =
      this.props.selectedContent?.moderation_level &&
      this.props.selectedContent.moderation_level === -1
    let isActive =
      this.props.selectedContent?.moderation_level &&
      this.props.selectedContent.moderation_level === 2
    let selectedRejectReason =
      this.props.selectedContent && this.props.selectedContent.reject_reason
    let competitor_logo_name =
      this.props.selectedContent &&
      this.props.selectedContent.competitor_logo_name
    let competitor_logo_name_text =
      this.props.selectedContent &&
      this.props.selectedContent.competitor_logo_text
    let banned_organization_name =
      this.props.selectedContent &&
      this.props.selectedContent.banned_organization_name
    let misc_reject_reason =
      this.props.selectedContent &&
      this.props.selectedContent.misc_reject_reason
    this.setState({
      showOptions: isDeactive,
      selectedRejectReason,
      competitor_logo_name,
      competitor_logo_name_text,
      banned_organization_name,
      misc_reject_reason,
      isQCUser,
      isActive,
      isDeactive,
    })
  }

  _handleDone = () => {
    this.props.onModerationUpdate(
      this.props.selectedContent,
      () => {},
      true,
      "deactivate",
      this.state.selectedRejectReason,
      {
        content_type: "image",
        competitor_logo_name: this.state.competitor_logo_name,
        competitor_logo_name_text: this.state.competitor_logo_name_text,
        banned_organization_name: this.state.banned_organization_name,
        misc_reject_reason: this.state.misc_reject_reason,
      }
    )
  }

  formatOptionLabel = ({ value, label, logoSrc }) => {
    return (
      <span>
        {logoSrc ? (
          <img
            src={logoSrc}
            alt={label}
            style={{ hight: "20px", width: "20px" }}
          />
        ) : (
          <i className="fa fa-users" aria-hidden="true"></i>
        )}{" "}
        &nbsp;
        <b>{label}</b>
      </span>
    )
  }

  _renderOptions = () => {
    return (
      <div className="d-flex">
        {
          <Row
            className={`d-flex question-card text-center contentCard my-5 justify-content-around ${
              this.state.showOptions || this.state.isDeactive
                ? "unSelectedCard"
                : ""
            }`}
            style={{
              width: "20rem",
              height: "5rem",
            }}
          >
            <Col sm={"8"} className="my-4 text-truncate-2">
              De Activate Content
            </Col>

            <Col
              role="button"
              sm="1"
              lg="1"
              className="my-3 mx-2"
              onClick={() => {
                this.setState({ showOptions: true })
              }}
            >
              <Button color="danger">Deactivate</Button>
            </Col>
          </Row>
        }
        {!(this.state.isQCUser && this.state.isActive) && (
          <Row
            className={`d-flex question-card text-center contentCard my-5 justify-content-around `}
            style={{
              width: "20rem",
              height: "5rem",
            }}
          >
            <Col sm={"8"} className="my-4 text-truncate-2">
              Activate Content
            </Col>

            <Col
              role="button"
              sm="1"
              lg="1"
              className="my-3 mx-2"
              onClick={() => {
                if (!this.props.enableButton) {
                } else {
                  this.setState({ showOptions: false })
                  this.props.onModerationUpdate(
                    this.props.selectedContent,
                    () => {},
                    true,
                    "activate",
                    "",
                    {
                      content_type: "image",
                    }
                  )
                }
              }}
              disabled={!this.props.enableButton}
            >
              <Button color="success" disabled={!this.props.enableButton}>
                Activate
              </Button>
            </Col>
          </Row>
        )}
        {/* {this.state.isQCUser && (
          <Row
            className={`d-flex question-card text-center contentCard my-5 justify-content-around `}
            style={{
              width: "20rem",
              height: "5rem",
            }}
          >
            <Col sm={"8"} className="my-4 text-truncate-2">
              Moderation Ok
            </Col>

            <Col
              role="button"
              sm="1"
              lg="1"
              className="my-3 mx-2"
              onClick={() => {
                this.setState({ showOptions: false })
                this.props.onModerationUpdate(
                  this.props.selectedContent,
                  () => {},
                  true,
                  "ok"
                )
              }}
            >
              <Button color="info">Ok</Button>
            </Col>
          </Row>
        )} */}
      </div>
    )
  }

  _getRejectReason = () => {
    const moderator =
      (this.props.currentUser?.jotUserType &&
        this.props.currentUser.jotUserType === 4) ||
      (this.props.currentUser?.userType &&
        this.props.currentUser.userType === 4)

    if (this.state.contentType === "image") {
      return (
        <>
          <div className="topicRadioBtn">
            <Radio value="competitor_logo">COMPETITOR LOGO</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="banned_organization">BANNED ORGANIZATION</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="josh_logo">JOSH LOGO</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="masala">MASALA</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="porn">PORN</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="blurred">BLURRED</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="blood">BLOOD</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="death">DEATH</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="accident">ACCIDENT</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="suicide">SUICIDE</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="anti_national">ANTI NATIONAL</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="anti_religion">ANTI RELIGION</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="misc">MISC</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="animal_cruelty">ANIMAL CRUELTY</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="child_nudity">CHILD NUDITY</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="abusive">ABUSIVE</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="violence">VIOLENCE</Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="copyright_content" disabled={moderator}>
              COPYRIGHT CONTENT
            </Radio>
          </div>

          <div className="topicRadioBtn">
            <Radio value="misinformation_&_fake_news" disabled={moderator}>
              MISINFORMATION &amp; FAKE NEWS{" "}
            </Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="cyberbullying_&_harassment" disabled={moderator}>
              {" "}
              CYBERBULLYING &amp; HARASSMENT{" "}
            </Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="identity_theft_&_impersonation" disabled={moderator}>
              IDENTITY THEFT &amp; IMPERSONATION
            </Radio>
          </div>
          <div className="topicRadioBtn">
            <Radio value="mob_violence">MOB VIOLENCE</Radio>
          </div>
        </>
      )
    }
    return (
      <>
        <div className="topicRadioBtn">
          <Radio value="competitor_logo">COMPETITOR LOGO</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="banned_organization">BANNED ORGANIZATION</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="josh_logo">JOSH LOGO</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="small_duration">SMALL DURATION</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="masala">MASALA</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="porn">PORN</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="blurred">BLURRED</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="blood">BLOOD</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="death">DEATH</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="accident">ACCIDENT</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="suicide">SUICIDE</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="anti_national">ANTI NATIONAL</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="anti_religion">ANTI RELIGION</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="misc">MISC</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="animal_cruelty">ANIMAL CRUELTY</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="child_nudity">CHILD NUDITY</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="abusive">ABUSIVE</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="violence">VIOLENCE</Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="copyright_content" disabled={moderator}>
            COPYRIGHT CONTENT
          </Radio>
        </div>

        <div className="topicRadioBtn">
          <Radio value="misinformation_&_fake_news" disabled={moderator}>
            MISINFORMATION &amp; FAKE NEWS{" "}
          </Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="cyberbullying_&_harassment" disabled={moderator}>
            {" "}
            CYBERBULLYING &amp; HARASSMENT{" "}
          </Radio>
        </div>
        <div className="topicRadioBtn">
          <Radio value="identity_theft_&_impersonation" disabled={moderator}>
            IDENTITY THEFT &amp; IMPERSONATION
          </Radio>
        </div>
      </>
    )
  }

  _renderRejectOptions = () => {
    const moderator =
      (this.props.currentUser?.jotUserType &&
        this.props.currentUser.jotUserType === 4) ||
      (this.props.currentUser?.userType &&
        this.props.currentUser.userType === 4)
    return (
      <Row>
        <div className="d-flex flex-wrap" style={{ width: "100%" }}>
          <Col span={24}>
            <span className="hashtaglabels">Reject</span>
          </Col>
          <Col span={24} style={{ display: "flex" }} className="flex-wrap">
            <Group
              style={{ display: "contents" }}
              onChange={e =>
                this.setState({ selectedRejectReason: e.target.value })
              }
              value={this.state.selectedRejectReason}
            >
              {this._getRejectReason()}
            </Group>
          </Col>
          {this.state.contentType && this.state.contentType === "image" ? (
            <>
              {this.state.selectedRejectReason &&
              this.state.selectedRejectReason === "competitor_logo" ? (
                <Col lg={6} xl={6} sm={12}>
                  <ReactSelect
                    style={{ width: "100%" }}
                    isMulti={false}
                    options={competition_logos}
                    formatOptionLabel={this.formatOptionLabel}
                    onChange={value =>
                      this.setState({
                        competitor_logo_name: value.value,
                      })
                    }
                    placeholder="Select Logo..."
                  />
                </Col>
              ) : null}
              {this.state.competitor_logo_name &&
              this.state.competitor_logo_name === "others" ? (
                <Col>
                  <Input
                    placeholder={"Enter Logo..."}
                    // onChange={e =>
                    //   props.handleFilterChange(e.target.value, "name")
                    // }
                    // size="small"
                    className="h-100"
                    allowClear={true}
                    name="logo"
                    required
                    style={{ width: "100%" }}
                    onChange={e => {
                      this.setState({
                        competitor_logo_name_text: e.target.value,
                      })
                    }}
                  />
                </Col>
              ) : null}
              {this.state.selectedRejectReason &&
              this.state.selectedRejectReason === "banned_organization" ? (
                <Col lg={24} xl={24} sm={24}>
                  <ReactSelect
                    style={{ width: "100%" }}
                    isMulti={false}
                    options={banned_organizations}
                    onChange={value =>
                      this.setState({
                        banned_organization_name: value.value,
                      })
                    }
                    placeholder="Select Organization..."
                  />
                </Col>
              ) : null}
              {this.state.selectedRejectReason &&
              this.state.selectedRejectReason === "misc" ? (
                <Col>
                  <Input
                    placeholder={"Enter Reject Reason..."}
                    // onChange={e =>
                    //   props.handleFilterChange(e.target.value, "name")
                    // }
                    // size="small"
                    className="h-100"
                    allowClear={true}
                    name="logo"
                    required
                    style={{ width: "100%" }}
                    onChange={e => {
                      this.setState({
                        misc_reject_reason: e.target.value,
                      })
                    }}
                  />
                </Col>
              ) : null}
            </>
          ) : null}
          <Col span={24} className="py-2">
            <Button
              color="danger"
              disabled={
                (this.state.selectedRejectReason == "competitor_logo" &&
                  !this.state.competitor_logo_name) ||
                (this.state.selectedRejectReason == "competitor_logo" &&
                  this.state.competitor_logo_name === "others" &&
                  !this.state.competitor_logo_name_text) ||
                (this.state.selectedRejectReason == "banned_organization" &&
                  !this.state.banned_organization_name) ||
                (this.state.selectedRejectReason == "misc" &&
                  !this.state.misc_reject_reason)
              }
              onClick={() => this._handleDone()}
            >
              Submit
            </Button>
          </Col>
        </div>
      </Row>
    )
  }

  _renderTitle = () => {
    return <div className="font-weight-semibold pt-3">Moderation</div>
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderTitle()}
        {this._renderOptions()}
        {this.state.showOptions && this._renderRejectOptions()}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "content.loading"),
    currentData: _.get(store, "taxonomy.zone"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
    zoneList: _.get(store, "zone.zoneList"),
  }
}

export default connect(mapStateToProps)(Moderation)
