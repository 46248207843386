import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestCameraAssetListByFilters,
  requestFiltersList,
  requestEffectsList,
  requestMasksList,
  requestFaceUnityList,
  requestUpsertCameraAsset,
  requestUpsertTemplate,
  requestTemplates,
  requestRemoveTemplate,
  requestModerateCameraAsset,
  requestArGames,
  requestStickerList,
  requestUpgradeCameraAssetVersion,
} from "appRedux/sagas/requests/cameraAsset"
import {
  getCameraAssetListByFilters,
  getCameraAssetListByFiltersSuccess,
  getFiltersList,
  getFiltersListSuccess,
  getEffectsList,
  getEffectsListSuccess,
  getMasksList,
  getMasksListSuccess,
  getFuList,
  getFuListSuccess,
  upsertCameraAsset,
  upsertCameraAssetSuccess,
  upsertTemplate,
  upsertTemplateSuccess,
  getTemplates,
  getTemplatesSuccess,
  removeTemplate,
  removeTemplateSuccess,
  moderateCameraAsset,
  moderateCameraAssetSuccess,
  getStickersList,
  getStickersListSuccess,
  getArGamesList,
  getArGamesListSuccess,
  upgradeCameraAssetVersion,
  upgradeCameraAssetVersionSuccess,
} from "appRedux/slices/cameraAsset"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

function* handleGetCameraAssetListByFilters(params) {
  try {
    const data = yield call(requestCameraAssetListByFilters, params)
    yield put(getCameraAssetListByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetFiltersList() {
  try {
    const data = yield call(requestFiltersList)
    yield put(getFiltersListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetStickersList() {
  try {
    const data = yield call(requestStickerList)
    yield put(getStickersListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetArGames() {
  try {
    const data = yield call(requestArGames)
    yield put(getArGamesListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpgradeCameraAssetVersion(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpgradeCameraAssetVersion, params)
    yield put(upgradeCameraAssetVersionSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleEffectsListGenerator() {
  try {
    let data = yield call(requestEffectsList)
    yield put(getEffectsListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleMasksListGenerator(payload) {
  try {
    let data = yield call(requestMasksList, payload)
    yield put(getMasksListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleFaceUnityListGenerator(payload) {
  try {
    let data = yield call(requestFaceUnityList, payload)
    yield put(getFuListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* upsertCameraAssetGenerator(payload) {
  try {
    yield call(requestUpsertCameraAsset, payload)
    yield put(upsertCameraAssetSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* moderateCameraAssetGenerator(payload) {
  try {
    yield call(requestModerateCameraAsset, payload)
    yield put(moderateCameraAssetSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertTemplate(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertTemplate, params)
    yield put(upsertTemplateSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleTemplates(params) {
  try {
    const data = yield call(requestTemplates, params)
    yield put(getTemplatesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleRemoveTemplate(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestRemoveTemplate, params)
    yield put(removeTemplateSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(
    getCameraAssetListByFilters.type,
    handleGetCameraAssetListByFilters
  )
  yield takeLatest(getFiltersList.type, handleGetFiltersList)
  yield takeLatest(getEffectsList.type, handleEffectsListGenerator)
  yield takeLatest(getMasksList.type, handleMasksListGenerator)
  yield takeLatest(getFuList.type, handleFaceUnityListGenerator)
  yield takeLatest(upsertCameraAsset.type, upsertCameraAssetGenerator)
  yield takeLatest(upsertTemplate.type, handleUpsertTemplate)
  yield takeLatest(getTemplates.type, handleTemplates)
  yield takeLatest(removeTemplate.type, handleRemoveTemplate)
  yield takeLatest(moderateCameraAsset.type, moderateCameraAssetGenerator)
  yield takeLatest(getStickersList.type, handleGetStickersList)
  yield takeLatest(getArGamesList.type, handleGetArGames)
  yield takeLatest(
    upgradeCameraAssetVersion.type,
    handleUpgradeCameraAssetVersion
  )
}
