import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"
import {
  getBadges,
  getBadgesSuccess,
  upsertBadge,
  upsertBadgeSuccess,
  deleteBadge,
  deleteBadgeSuccess,
  upgradeBadgeListVersion,
  upgradeBadgeListVersionSuccess,
} from "../../slices/badges"
import {
  requestBadges,
  requestUpsertBadge,
  requestDeleteBadges,
  requestUpgradeBadgeListVersion,
} from "../requests/badge"

function* handleEntities(params) {
  try {
    const data = yield call(requestBadges, params)
    yield put(getBadgesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertBdupsertBadge(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertBadge, params)
    yield put(upsertBadgeSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteBadeleteBadge(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestDeleteBadges, params)
    yield put(deleteBadgeSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleupgradeBadgeListVersion(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpgradeBadgeListVersion, params)
    yield put(upgradeBadgeListVersionSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getBadges.type, handleEntities)
  yield takeLatest(upsertBadge.type, handleUpsertBdupsertBadge)
  yield takeLatest(deleteBadge.type, handleDeleteBadeleteBadge)
  yield takeLatest(upgradeBadgeListVersion.type, handleupgradeBadgeListVersion)
}
