import Axios from "axios"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

export const requestContentByFilters = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/content/getContentByFilters?offset=${offset}&limit=${limit}`,
      {
        filters: payload,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestTasksByFilters = async ({ payload }) => {
  try {
    const filters = payload.filters
    const offset = payload?.filters["currentPage"]
      ? payload.filters["currentPage"] - 1
      : 0
    const limit = payload?.filters["pageSize"]
      ? payload.filters["pageSize"]
      : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/content/getTasksByFilters?offset=${offset}&limit=${limit}`,
      {
        filters: payload.filters,
        currentUser: payload.currentUser,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestLabelContentList = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/content/getLabelContent?offset=${offset}&limit=${limit}`,
      {
        filters: payload,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestTrendingContent = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/content/getTrendingContent?offset=${offset}&limit=${limit}`,
      {
        filters: payload,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateContent = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/content/update?contentId=${payload.contentId}`,
      payload.contentData
    )
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestUpdateContentZones = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/content/update/zones`, payload)
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestUpdateAction = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/action/update`, payload)
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestLabelContent = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/content/label?contentId=${payload.contentId}`,
      payload.contentData
    )
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestBoostContentHistory = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/content/boost/history`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const requestBoostContent = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/content/boost`, payload)
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestCreateContent = async ({ payload }) => {
  try {
    let data = await await Axios.post(`/api/content/create`, payload)
    toastr.success("Content Created Successfully!!")
    return data
  } catch (error) {
    toastr.error("Content Creation Failed!!")
    throw error
  }
}

export const requestFetchContentByUUIDs = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/content/getContentByUUIDs`, {
      contentUUIDs: payload,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateTag = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/content/cms-update`, payload)
    toastr.success("Action Successfull!!", "")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUploadContent = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/content/upload/bulk`, payload)
    const message = data && data.data
    toastr.info(message)
    return data
  } catch (error) {
    throw error
  }
}

export const requestShoppableCuratedTypes = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/content/shoppable/curatedTypes`, payload)
    toastr.success("Action Successfull!!", "")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertShoppableContent = async ({ payload }) => {
  try {
    let data = await Axios.post(
      `/api/content/shoppable/update-shoppable-content`,
      payload
    )
    toastr.success("Action Successfull!!", "")
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetShoppableCuratedTypes = async () => {
  try {
    let data = await Axios.get(`/api/content/shoppable/getCuratedTypes`)
    toastr.success("Action Successfull!!", "")
    return data
  } catch (error) {
    throw error
  }
}

export const requestMarkQcTaskAsCompleted = async ({ payload }) => {
  let data = await Axios.post(
    `/api/content/tag/markQcComplete?sendToInternalQC=${payload.sendToInternalQC}&sourceId=${payload.sourceId}`,
    payload.data
  )
  return data
}

export const requestGetContentDataInRange = async ({ payload }) => {
  try {
    let createdDateFrom = undefined
    let createdDateTill = undefined
    if (payload.dateRange) {
      createdDateFrom = payload.dateRange[0]
        ? payload.dateRange[0].valueOf()
        : undefined
      createdDateTill = payload.dateRange[1]
        ? payload.dateRange[1].valueOf()
        : undefined
    }

    const bucketFrom = payload.bucketRange[0]
    const bucketTill = payload.bucketRange[1]

    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10

    const showCurated = payload["showCuratedContent"]

    const data = await Axios.post(
      `/api/content/tagContentUUIDList?offset=${offset}&limit=${limit}`,
      { createdDateFrom, createdDateTill, bucketFrom, bucketTill, showCurated }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetEvents = async () => {
  try {
    let data = await Axios.get(`/api/event/get`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestAgencyLevelStats = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/taxonomy/getTaxonomyReportAgentWise?agency=${payload.agency}&days=${payload.days}&email=${payload.email}&langCode=${payload.langCode}&dateForm=${payload.dateFrom}&dateTo=${payload.dateTo}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestAgentListByAgency = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/taxonomy/getAgentListByAgency?agency=${payload.agency}&langCode=${payload.langCode}&days=${payload.days}&dateForm=${payload.dateFrom}&dateTo=${payload.dateTo}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUniqueTaggedAndQCTaggedContainData = async ({
  payload,
}) => {
  try {
    let data = await Axios.get(
      `/api/taxonomy/getTaxonomyReportUniqueTaggedAndQCTaggedContent?agency=${payload.agency}&days=${payload.days}&langCode=${payload.langCode}&isInternal=${payload.isInternal}&dateForm=${payload.dateFrom}&dateTo=${payload.dateTo}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestLanguageLevelStats = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/taxonomy/getTaxonomyReportLanguageWise?agency=${payload.agency}&days=${payload.days}&langCode=${payload.langCode}&dateForm=${payload.dateFrom}&dateTo=${payload.dateTo}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestAgentByLanguage = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/taxonomy/getTaxonomyReportAgentWiseByLanguage?agency=${payload.agency}&days=${payload.days}&langCode=${payload.langCode}&dateForm=${payload.dateFrom}&dateTo=${payload.dateTo}`
    )
    return data
  } catch (error) {
    throw error
  }
}
