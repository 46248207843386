import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Divider, Switch, Upload } from "antd"
import toastr from "toastr"
import _ from "lodash"
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  CardTitle,
  CardImg,
} from "reactstrap"
import ValidatorForm from "../ValidatorForm"
import { Player } from "@lottiefiles/react-lottie-player"

export default class GiftForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      title: this.props?.data?.title || "",
      orig_price: this.props?.data?.orig_price || null,
      discount_percentage: this.props?.data?.discount_percentage || 0,
      discounted_price: this.props?.data?.discounted_price || null,
      jems_count: this.props?.data?.jems_count || null,
      is_special: this.props?.data?.is_special === false ? false : true,

      gift_thumbnail_url: this.props?.data?.gift_thumbnail_url
        ? this.props?.data?.gift_thumbnail_url
        : "",
      gift_animated_url: this.props?.data?.gift_animated_url
        ? this.props?.data?.gift_animated_url
        : "",
      gift_share_url: this.props?.data?.gift_share_url
        ? this.props?.data?.gift_share_url
        : "",
      gift_unreceived_thumbnail_url: this.props?.data
        ?.gift_unreceived_thumbnail_url
        ? this.props?.data?.gift_unreceived_thumbnail_url
        : "",
    }
  }

  componentWillUnmount() {
    this._resetState()
  }

  _resetState() {
    this.setState({
      title: "",
      orig_price: null,
      discount_percentage: null,
      discounted_price: null,
      jems_count: null,
    })
  }

  _onImageRemove = type => {
    if (type === "thumbnail") {
      this.setState({
        gift_thumbnail_url_list: [],
        thumbnailImage: null,
      })
    }
    if (type === "animated") {
      this.setState({
        gift_animated_url_list: [],
        animatedImage: null,
      })
    }
    if (type === "share") {
      this.setState({
        gift_share_url_list: [],
        shareImage: null,
      })
    }
    if (type === "unreceived") {
      this.setState({
        gift_unreceived_thumbnail_url_list: [],
        unreceivedThumbnailImage: null,
      })
    }
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "thumbnail") {
        this.setState({
          gift_thumbnail_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          thumbnailImage: fileObj.file,
        })
      }
      if (type === "animated") {
        this.setState({
          gift_animated_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          animatedImage: fileObj.file,
        })
      }
      if (type === "share") {
        this.setState({
          gift_share_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          shareImage: fileObj.file,
        })
      }
      if (type === "unreceived") {
        this.setState({
          gift_unreceived_thumbnail_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          unreceivedThumbnailImage: fileObj.file,
        })
      }
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Gift`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={4} className="mt-2">
                <label>Title</label>
                <input
                  className="form-control"
                  value={this.state.title}
                  onChange={e => this.setState({ title: e.target.value })}
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Jems Count</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.jems_count}
                    onChange={e => {
                      if (e.target.value && e.target.value < 0) {
                        toastr.error("Jems count can not be zero...")
                        return
                      }
                      this.setState({ jems_count: e.target.value })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Is Special</label>
                <Switch
                  className="display_flex"
                  defaultChecked={this.state.is_special}
                  onChange={value => {
                    this.setState({
                      is_special: value,
                    })
                  }}
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Original Price</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.orig_price}
                    onChange={e => {
                      this.setState({
                        orig_price: e.target.value,
                      })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Discount Percentage (%)</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.discount_percentage}
                    onChange={e => {
                      // if (
                      //   e.target.value &&
                      //   (e.target.value < 0 || e.target.value > 100)
                      // ) {
                      //   toastr.error(
                      //     "Percentage should be between 0 to 100 ..."
                      //   )
                      //   return
                      // }
                      // let discounted_price =
                      //   this.state.discounted_price || this.state.orig_price
                      // if (e.target.value && this.state.orig_price) {
                      //   discounted_price =
                      //     ((100 - e.target.value) * this.state.orig_price) / 100
                      // }
                      // if (!e.target.value) {
                      //   discounted_price = this.state.orig_price
                      // }
                      this.setState({
                        discount_percentage: e.target.value,
                        // discounted_price,
                      })
                    }}
                    // disabled={!this.state.orig_price}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Discounted Price</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.discounted_price}
                    onChange={e => {
                      this.setState({
                        discounted_price: e.target.value,
                      })
                    }}
                  />
                </div>
              </Col>
              <Divider>Files</Divider>
              <Col sm="12" md="3">
                <Row
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "176px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("thumbnail")}
                      defaultFileList={this.state.gift_thumbnail_url_list}
                      fileList={this.state.gift_thumbnail_url_list}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "thumbnail")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        <i className="fas fa-upload" />
                        &nbsp; Thumbnail image
                      </Button>
                    </Upload>
                  </div>
                  {this.state.gift_thumbnail_url ? (
                    <div style={{ height: "5rem", width: "10rem" }}>
                      {!this.state.gift_thumbnail_url.includes(".json") ? (
                        <CardImg
                          top
                          className="img-fluid"
                          src={this.state.gift_thumbnail_url}
                          alt="Skote"
                          style={{
                            height: "5rem",
                            width: "5rem",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <Player
                          autoplay={true}
                          loop={true}
                          controls={true}
                          src={this.state.gift_thumbnail_url}
                          style={{
                            height: "5rem",
                            width: "5rem",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        ></Player>
                      )}
                    </div>
                  ) : (
                    <div style={{ height: "5rem", width: "10rem" }}></div>
                  )}
                </Row>
              </Col>
              <Col sm="12" md="3">
                <Row
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "176px" }}>
                    {" "}
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("animated")}
                      defaultFileList={this.state.gift_animated_url_list}
                      fileList={this.state.gift_animated_url_list}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "animated")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        <i className="fas fa-upload" />
                        &nbsp; Animated image
                      </Button>
                    </Upload>
                  </div>
                  {this.state.gift_animated_url ? (
                    <div style={{ height: "5rem", width: "10rem" }}>
                      {!this.state.gift_animated_url.includes(".json") ? (
                        <CardImg
                          top
                          className="img-fluid"
                          src={this.state.gift_animated_url}
                          alt="Skote"
                          style={{
                            height: "5rem",
                            width: "5rem",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <Player
                          autoplay={true}
                          loop={true}
                          controls={true}
                          src={this.state.gift_animated_url}
                          style={{
                            height: "5rem",
                            width: "5rem",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        ></Player>
                      )}
                    </div>
                  ) : (
                    <div style={{ height: "5rem", width: "10rem" }}></div>
                  )}
                </Row>
              </Col>
              <Col sm="12" md="3">
                <Row
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "176px" }}>
                    {" "}
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("share")}
                      defaultFileList={this.state.gift_share_url_list}
                      fileList={this.state.gift_share_url_list}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "share")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        <i className="fas fa-upload" />
                        &nbsp; Share image
                      </Button>
                    </Upload>
                  </div>
                  {this.state.gift_share_url ? (
                    <div style={{ height: "5rem", width: "10rem" }}>
                      {!this.state.gift_share_url.includes(".json") ? (
                        <CardImg
                          top
                          className="img-fluid"
                          src={this.state.gift_share_url}
                          alt="Skote"
                          style={{
                            height: "5rem",
                            width: "5rem",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <Player
                          autoplay={true}
                          loop={true}
                          controls={true}
                          src={this.state.gift_share_url}
                          style={{
                            height: "5rem",
                            width: "5rem",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        ></Player>
                      )}
                    </div>
                  ) : (
                    <div style={{ height: "5rem", width: "10rem" }}></div>
                  )}
                </Row>
              </Col>
              <Col sm="12" md="3">
                <Row
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <img src={this.state.gift_unreceived_thumbnail_url_list} /> */}

                  <div style={{ width: "245px" }}>
                    {" "}
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("unreceived")}
                      defaultFileList={
                        this.state.gift_unreceived_thumbnail_url_list
                      }
                      fileList={this.state.gift_unreceived_thumbnail_url_list}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "unreceived")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        <i className="fas fa-upload" />
                        &nbsp; Unreceived thumbnail image
                      </Button>
                    </Upload>
                  </div>
                  {this.state.gift_unreceived_thumbnail_url ? (
                    <div style={{ height: "5rem", width: "10rem" }}>
                      {!this.state.gift_unreceived_thumbnail_url.includes(
                        ".json"
                      ) ? (
                        <CardImg
                          top
                          className="img-fluid"
                          src={this.state.gift_unreceived_thumbnail_url}
                          alt="Skote"
                          style={{
                            height: "5rem",
                            width: "5rem",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <Player
                          autoplay={true}
                          loop={true}
                          controls={true}
                          src={this.state.gift_unreceived_thumbnail_url}
                          style={{
                            height: "5rem",
                            width: "5rem",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        ></Player>
                      )}
                    </div>
                  ) : (
                    <div style={{ height: "5rem", width: "10rem" }}></div>
                  )}
                </Row>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <div className="d-flex justify-content-end mt-4">
                  <Button htmlType="submit" color="success">
                    {this.props.action === "update" ? "Update" : "Create"}
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.onCancel()
                      this._resetState()
                    }}
                    color="danger"
                    className="mx-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data = new FormData()
    const payload = {}
    const { data: giftData = {} } = this.props || {}
    const { gift_element_uuid } = giftData || {}
    const {
      title,
      jems_count,
      discount_percentage,
      discounted_price,
      orig_price,
      thumbnailImage,
      animatedImage,
      shareImage,
      unreceivedThumbnailImage,
      is_special,
      bg_color,
    } = this.state

    if (
      (!thumbnailImage || !unreceivedThumbnailImage || !shareImage) &&
      this.props.action === "create"
    ) {
      toastr.error(
        "Please upload thumbnail, unreceived thumbnail and share image"
      )
      return
    }

    if (gift_element_uuid) {
      payload.gift_element_uuid = gift_element_uuid
    }

    if (title) {
      payload.title = title
    }
    if (is_special == true || is_special == false) {
      payload.is_special = is_special
    }
    if (jems_count || jems_count == 0) {
      payload.jems_count = +jems_count
    }
    if (discount_percentage || discount_percentage == 0) {
      payload.discount_percentage = +discount_percentage
    }
    if (discounted_price || discounted_price == 0) {
      payload.discounted_price = +discounted_price
    }
    if (orig_price || orig_price == 0) {
      payload.orig_price = +orig_price
    }
    if (bg_color) {
      payload.bg_color = bg_color
    }
    if (thumbnailImage) {
      data.append("gift_thumbnail_url", thumbnailImage)
    }
    if (animatedImage) {
      data.append("gift_animated_url", animatedImage)
    }
    if (shareImage) {
      data.append("gift_share_url", shareImage)
    }
    if (unreceivedThumbnailImage) {
      data.append("gift_unreceived_thumbnail_url", unreceivedThumbnailImage)
    }

    data.append("payload", JSON.stringify(payload))
    this.props.onSubmit(data)
    this._resetState()
  }

  render() {
    return this._form()
  }
}
