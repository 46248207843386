import React from "react"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { LANGUAGES } from "constants/uiConstants"

const { Option } = Select

const CurationDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Content ID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["contentUuid"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(
                      e.target.value.trim(),
                      "contentUuid"
                    )
                  }
                  allowClear={true}
                  name="contentUuid"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>

        <Col lg="2" md="2">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Action</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              placeholder="Action"
              style={{ width: "100%" }}
              allowClear={true}
              defaultValue={props && props.filters && props.filters["action"]}
              onChange={value => props.handleFilterChange(value, "action")}
            >
              <Option value="ACCEPTED">Accepted</Option>
              <Option value="REVIEWED">Reviewed</Option>
              <Option value="REJECTED">Rejected</Option>
            </Select>
          </Input.Group>
        </Col>

        <Col lg="2">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Language</h5>
          </Divider>
          <Select
            placeholder="Language"
            options={LANGUAGES}
            style={{ width: "100%" }}
            defaultValue={props && props.filters && props.filters["langCode"]}
            allowClear={true}
            onChange={value => props.handleFilterChange(value, "langCode")}
          />
        </Col>
        <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Interest</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              allowClear={true}
              defaultValue={props && props.filters && props.filters["interest"]}
              style={{ width: "100%" }}
              onChange={value => props.handleFilterChange(value, "interest")}
            >
              {props.interestData.map(interest => (
                <Option value={interest.id}>{interest.name}</Option>
              ))}
            </Select>
          </Input.Group>
        </Col>

        <Col lg="2" md="2">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Seed Video</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              placeholder="Seed Video"
              style={{ width: "100%" }}
              allowClear={true}
              defaultValue={
                props && props.filters && props.filters["is_seed_video"]
              }
              onChange={value =>
                props.handleFilterChange(value, "is_seed_video")
              }
            >
              <Option value="true">True</Option>
              <Option value="false">False</Option>
            </Select>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(CurationDrawer)
