import { LANGUAGES } from "constants/uiConstants"
import _ from "lodash"
import React from "react"
import moment from "moment"
import { Form } from "react-bootstrap"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import Auxiliary from "../../util/Auxiliary"
import { MultiSelect, TextArea, TextField } from "../ValidatorForm"

export default class SocialUpsertForm extends ErrorBoundComponent {
  state = {
    formData: {
      activeZones: [],
      inputZones: [],
      ceType: "PHOTO",
      langCode: "en",
      embedType: "YOUTUBE",
      isActive: true,
    },
    action: this.props.action,
    errors: {},
    selectedLangs: [],
  }

  componentDidMount() {
    const social = this.props.social
    let selectedLangs = [...this.state.selectedLangs]

    if (!_.isEmpty(social)) {
      let formData = {
        title: social.content_title,
        ceType: social.ce_type ? social.ce_type : social.type,
        langCode: social.lang_code,
        isActive: social.is_active,
        format: social.format,
        userUuid: social?.user_profile?.user_uuid,
        tags: social.tags,
        embedType: social.embed_type,
        targetLanguages: _.isEmpty(social.target_languages)
          ? []
          : social.target_languages,
        url: social.url,
        blockCodeText: social.blockquote_text,
        inputZones: _.isEmpty(social.input_zones) ? [] : social.input_zones,
        activeZones: _.isEmpty(social.active_zones) ? [] : social.active_zones,
        zoneInfo: _.isEmpty(social.zone_info_v1) ? [] : social.zone_info_v1,
      }
      this.setState({
        formData: {
          ...this.state.formData,
          ...formData,
        },
        selectedLangs: _.uniq(selectedLangs),
      })
    }
  }

  _findFormErrors = () => {
    const newErrors = {}
    const { tags, start_date, end_date } = this.state.formData
    if (!tags || tags === "") newErrors.tags = "Please provide the hashtag!"
    if (!start_date || start_date === "")
      newErrors.start_date = "Please provide the start date!"
    if (!end_date || end_date === "")
      newErrors.end_date = "Please provide the end date!"
    return newErrors
  }

  _handleTargetFieldsChange = (field, value, index, bannerIndex, parent) => {
    const target = [...this.state.formData.target]
    if (parent) {
      target[index] = {
        ...target[index],
        [parent]: {
          ...target[index][parent],
          [field]: value,
        },
      }
      this.setState({
        formData: {
          ...this.state.formData,
          target,
        },
      })
    } else {
      if (field === "bannerType") {
        if (value === "image") {
          target[index] = {
            ...target[index],
            banner_list: [
              {
                element_cta: "",
                id: uuidv4(),
                view_order: 0,
              },
            ],
            video: null,
            bannerType: "image",
          }
          this.setState({
            formData: {
              ...this.state.formData,
              target,
            },
          })
        } else {
          target[index] = {
            ...target[index],
            video: {
              id: uuidv4(),
              auto_play: true,
              mute_on_start: true,
            },
            banner_list: null,
            bannerType: "video",
          }
          this.setState({
            formData: {
              ...this.state.formData,
              target,
            },
          })
        }
      } else if (field === "element_cta") {
        const banner_list = [...target[index].banner_list]
        banner_list[bannerIndex] = {
          ...banner_list[bannerIndex],
          [field]: value,
        }
        target[index] = {
          ...target[index],
          banner_list,
        }
        this.setState({
          formData: {
            ...this.state.formData,
            target,
          },
        })
      } else {
        if (field === "lang_code") {
          const selectedLangs = [...this.state.selectedLangs]
          selectedLangs[index] = value
          this.setState({
            selectedLangs,
          })
        }
        target[index] = {
          ...target[index],
          [field]: value,
        }
        this.setState({
          formData: {
            ...this.state.formData,
            target,
          },
        })
      }
    }
  }

  _addTarget = () => {
    const target = [
      ...this.state.formData.target,
      {
        banner_list: [
          {
            element_cta: "",
            id: uuidv4(),
            view_order: 0,
          },
        ],
        video: {
          id: uuidv4(),
          auto_play: true,
          mute_on_start: true,
        },
        bannerType: "image",
      },
    ]

    this.setState({
      formData: {
        ...this.state.formData,
        target,
      },
    })
  }

  _onSubmit = () => {
    if (this.state.formData.activeZones.length > 0) {
      let sub_type_zone = []

      let updated_all_active_zones = this.state.formData.activeZones
      let updated_active_zones = []

      // check for valid zone
      for (let i = 0; i < updated_all_active_zones.length; i++) {
        for (let j = 0; j < this.props.zoneList.length; j++) {
          if (updated_all_active_zones[i] == this.props.zoneList[j].zone_uuid) {
            updated_active_zones = [
              ...updated_active_zones,
              this.props.zoneList[j].zone_uuid,
            ]
          }
        }
      }

      let validZones = [...updated_active_zones]

      validZones.map(item => {
        this.props.zoneList.map(zone => {
          if (item == zone.zone_uuid) {
            if (zone.level == 2) {
              updated_active_zones = [
                ...updated_active_zones,
                ...zone.sub_type,
                ...zone.type,
              ]
              sub_type_zone = [...sub_type_zone, ...zone.sub_type]
            } else if (zone.level == 1) {
              updated_active_zones = [...updated_active_zones, ...zone.type]
            }
          }
        })
      })
      sub_type_zone.map(item => {
        this.props.zoneList.map(zone => {
          if (item == zone.zone_uuid) {
            if (zone.level == 1) {
              updated_active_zones = [...updated_active_zones, ...zone.type]
            }
          }
        })
      })
      const newActiveSet = new Set(updated_active_zones)
      const activeZones = [...newActiveSet]

      this.state.formData.activeZones = [...newActiveSet]
      this.state.formData.inputZones = [
        ...this.state.formData.inputZones,
        ...this.state.formData.activeZones,
      ]
      const newInputSet = new Set(this.state.formData.inputZones)
      this.state.formData.inputZones = [...newInputSet]
      const inputZones = [...newInputSet]
      let zoneInfo = _.cloneDeep(this.state.formData.zoneInfo) || []

      //zoneInfo for Active Zone
      if (zoneInfo?.length > 0) {
        if (!_.isEmpty(activeZones)) {
          for (let activeZone of activeZones) {
            let zoneHandled = false
            for (let zone of zoneInfo) {
              if (zone.zone_id === activeZone) {
                zone.shouldRetrieve = true
                zoneHandled = true
                if (
                  zone.status === "system_removed" ||
                  zone.status === "human_removed"
                ) {
                  zone.status = "system_approved"
                  zone.modified_on = moment()
                    .tz("Asia/Calcutta")
                    .format("YYYY-MM-DDTHH:mm:ss+05:30")
                }
              }
            }

            if (!zoneHandled) {
              zoneInfo.push({
                zone_id: activeZone,
                status: "system_approved",
                source: "UGC",
                added_on: moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30"),
                modified_on: moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30"),
                modified_by: this.props.currentUser.email,
                shouldRetrieve: true,
              })
            }
          }

          for (let zone of zoneInfo) {
            if (!zone.shouldRetrieve) {
              zone.status = "human_removed"
              zone.modified_on = moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30")
            }
          }
        } else {
          for (let zone of zoneInfo) {
            zone.status = "human_removed"
            zone.modified_on = moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30")
          }
        }
      } else {
        if (!_.isEmpty(activeZones)) {
          for (let zone of activeZones) {
            zoneInfo.push({
              zone_id: zone,
              status: "system_approved",
              source: "UGC",
              added_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_by: this.props.currentUser.email,
              shouldRetrieve: true,
            })
          }
        }
      }
      if (zoneInfo?.length > 0) {
        if (!_.isEmpty(inputZones)) {
          for (let inputZone of inputZones) {
            let zoneHandled = false
            for (let zone of zoneInfo) {
              if (zone.zone_id === inputZone) {
                zone.shouldRetrieve = true
                zoneHandled = true
                if (
                  zone.status === "system_removed" ||
                  zone.status === "human_removed"
                ) {
                  zone.status = "added"
                  zone.modified_on = moment()
                    .tz("Asia/Calcutta")
                    .format("YYYY-MM-DDTHH:mm:ss+05:30")
                }
              }
            }

            if (!zoneHandled) {
              zoneInfo.push({
                zone_id: inputZone,
                status: "added",
                source: "UGC",
                added_on: moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30"),
                modified_on: moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30"),
                modified_by: this.props.currentUser.email,
                shouldRetrieve: true,
              })
            }
          }

          for (let zone of zoneInfo) {
            if (!zone.shouldRetrieve) {
              zone.status = "human_removed"
              zone.modified_on = moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30")
            }
            delete zone?.shouldRetrieve
          }
        } else {
          for (let zone of zoneInfo) {
            zone.status = "human_removed"
            zone.modified_on = moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30")
          }
        }
      } else {
        if (!_.isEmpty(inputZones)) {
          for (let zone of inputZones) {
            zoneInfo.push({
              zone_id: zone,
              status: "added",
              source: "UGC",
              added_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_by: this.props.currentUser.email,
            })
          }
        }
      }

      this.state.formData.zoneInfo = zoneInfo
    }
    if (this.props.formAction === "update") {
      this.props.onSubmit(this.state.formData, this.props.social.content_uuid)
    } else this.props.onSubmit(this.state.formData)
  }

  _addImages = () => {
    let image_details = [...this.state.formData.image_details]
    image_details = [
      ...image_details,
      {
        height: "",
        width: "",
        id: uuidv4(),
        view_order: image_details.length,
      },
    ]
    this.setState({
      formData: {
        ...this.state.formData,
        image_details,
      },
    })
  }

  _handleFieldChange = (field, value, parent, index) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: value,
      },
      errors: {
        ...this.state.errors,
        [field]: null,
      },
    })
  }

  _renderUpsertForm = () => {
    return (
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col lg="6">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  onChange={e =>
                    this._handleFieldChange("title", e.target.value)
                  }
                  defaultValue={this.state.formData.title}
                  isInvalid={!!this.state.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg="6">
              <label>Type</label>
              <select
                className="form-select"
                onChange={e =>
                  this._handleFieldChange("ceType", e.target.value)
                }
                value={this.state.formData.ceType}
              >
                <option value="PHOTO" key="PHOTO">
                  Photo
                </option>
                <option value="VIDEO" key="VIDEO">
                  Video
                </option>
                <option value="MEME" key="MEME">
                  Meme
                </option>
              </select>
            </Col>

            <Col lg="6">
              <label className="col-form-label">Language</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange("langCode", e.target.value)
                  }
                  value={this.state.formData.langCode}
                >
                  {LANGUAGES.map(lang => (
                    <option value={lang.value} key={lang.value}>
                      {lang.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>

            <Col lg="6">
              <label className="col-form-label">Embed Type</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange("embedType", e.target.value)
                  }
                  value={this.state.formData.embedType}
                >
                  <option value="YOUTUBE" key="Youtube">
                    Youtube
                  </option>
                  <option value="TWITTER" key="Twitter">
                    Twitter
                  </option>
                  <option value="FACEBOOK" key="Facebook">
                    Facebook
                  </option>
                  <option value="INSTA" key="Insta">
                    Insta
                  </option>
                </select>
              </div>
            </Col>

            <Col lg="6">
              <label className="col-form-label">Target Languages</label>
              <MultiSelect
                placeholderLabel="Languages"
                className="creator-form"
                defaultValue={this.state.formData.targetLanguages}
                options={LANGUAGES.map(lang => {
                  return { label: lang.label, value: lang.value }
                })}
                onChange={value =>
                  this._handleFieldChange("targetLanguages", value)
                }
              />
            </Col>
            <Col lg="6">
              <label className="col-form-label">Hashtags</label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={this.state.formData.tags}
                  onChange={e =>
                    this._handleFieldChange("tags", e.target.value)
                  }
                />
              </div>
            </Col>

            <Col lg="6">
              <label className="col-form-label">User UUID </label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  field="user_uuid"
                  onChange={e =>
                    this._handleFieldChange("userUuid", e.target.value)
                  }
                  defaultValue={this.state.formData.userUuid}
                />
              </div>
            </Col>
            {/* </Row>
          <Row> */}
            <Col lg="6">
              <Row>
                <Col sm="6" md="9">
                  <label className="col-form-label">Input Zones</label>
                  <TextField
                    // label="Input Zones"
                    // disabled="true"
                    placeholderLabel="Select Input Zones"
                    field="inputZones"
                    value={this.state.formData.inputZones}
                    onChange={val => {
                      if (val.trim()) {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            inputZones: val.split(","),
                          },
                        })
                      } else {
                        this.setState({
                          formData: { ...this.state.formData, inputZones: [] },
                        })
                      }
                    }}
                    className="creator-form"
                    defaultValue={this.state.formData.inputZones}
                    style={{ width: "95%" }}
                  />
                </Col>
                <Col
                  sm="6"
                  md="3"
                  style={{ alignSelf: "flex-end", marginBottom: "9px" }}
                >
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        selectInputZones: true,
                      })
                    }
                  >
                    Select
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <Row>
                <Col sm="6" md="9">
                  <label className="col-form-label">Active Zones</label>
                  <TextField
                    placeholderLabel="Select Active Zones"
                    field="activeZones"
                    className="creator-form"
                    value={this.state.formData.activeZones}
                    onChange={val => {
                      if (val.trim()) {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            activeZones: val.split(","),
                          },
                        })
                      } else {
                        this.setState({
                          formData: { ...this.state.formData, activeZones: [] },
                        })
                      }
                    }}
                    defaultValue={this.state.formData.activeZones}
                    style={{ width: "95%" }}
                  />
                </Col>
                <Col
                  sm="6"
                  md="3"
                  style={{ alignSelf: "flex-end", marginBottom: "9px" }}
                >
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        selectActiveZoneModal: true,
                      })
                    }
                  >
                    Select
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col lg="6">
              <label className="col-form-label">URL</label>
              <TextArea
                className="form-control"
                onChange={e => this._handleFieldChange("url", e)}
                defaultValue={this.state.formData.url}
                rows="3"
              />
            </Col>
            <Col lg="6">
              <label className="col-form-label">Blockquote</label>
              <TextArea
                className="form-control"
                onChange={e => this._handleFieldChange("blockCodeText", e)}
                defaultValue={this.state.formData.blockCodeText}
                rows="3"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="d-flex align-items-center mt-2">
              <div className="me-5">
                <div className="form-check form-switch form-switch-lg">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={e =>
                      this._handleFieldChange("isActive", e.target.checked)
                    }
                    checked={
                      this.state.formData.isActive
                        ? this.state.formData.isActive
                        : this.state.formData?.isActive?.toString() == "false"
                        ? false
                        : true
                    }
                  />
                  <label className="form-check-label">Is Active</label>
                </div>
              </div>
            </Col>
          </Row>

          <div className="d-flex justify-content-end mt-3">
            <Button
              color="danger"
              size="default"
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                return
              }}
              onClick={() => this.props.history.push(`/social/browse`)}
              className="mx-2"
            >
              Cancel
            </Button>
            <div
              className="btn btn-success"
              onClick={this._onSubmit}
              role="button"
            >
              {this.props.formAction === "update" ? `Update` : `Create`}
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }

  _render = () => {
    return (
      <Auxiliary loading={this.props.loading}>
        {this._renderUpsertForm()}
        {this.state.selectInputZones && (
          <SearchAndSelect
            type="ZONE"
            visible={this.state.selectInputZones}
            onClose={() => this.setState({ selectInputZones: false })}
            onAdd={arr =>
              this.setState({
                formData: { ...this.state.formData, inputZones: arr },
              })
            }
            zoneId={this.state.formData.inputZones}
            selectedArr={_.uniq(this.state.formData.inputZones.map(a => a.id))}
            allZoneList={this.props.zoneList.map(zone => {
              return {
                ...zone,
                value: zone.zone_uuid,
                label: zone.title || zone.sub_title,
              }
            })}
          />
        )}
        {this.state.selectActiveZoneModal && (
          <SearchAndSelect
            type="ZONE"
            visible={this.state.selectActiveZoneModal}
            onClose={() => this.setState({ selectActiveZoneModal: false })}
            onAdd={arr =>
              this.setState({
                formData: { ...this.state.formData, activeZones: arr },
              })
            }
            zoneId={this.state.activeZones}
            selectedArr={_.uniq(this.state.formData.activeZones.map(a => a))}
            allZoneList={this.props.zoneList.map(zone => {
              return {
                ...zone,
                value: zone.zone_uuid,
                label: zone.title || zone.sub_title,
              }
            })}
          />
        )}
      </Auxiliary>
    )
  }
}
