import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap"
import { Pagination } from "antd"
import ReactTimeAgo from "react-time-ago"
import CopyToClipboard from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import CameraAssetUpsertForm from "./cameraAssetUpsertForm"
import {
  getManagersList,
  deleteUser,
  createUser,
  updateUser,
} from "appRedux/slices/community"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import { isEmpty, size, map } from "lodash"

class CameraAssetList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      modal: false,
      expanded: false,
      CameraAssetColumns: [
        {
          text: "TITLE",
          dataField: "title",
          sort: true,
          style: {
            width: "20%",
          },
          formatter: (cellContent, user) => {
            return (
              <>
                <h5 className="font-size-14 mb-1 text-truncate">
                  {user.community ? (
                    <div
                      role="button"
                      onClick={e => {
                        e.stopPropagation(), e.preventDefault()
                        this.props.history.push(
                          `/community/manage?userId=${user.userId}&currentTab=dashboard`
                        )
                      }}
                    >
                      {user.title}
                    </div>
                  ) : (
                    user.title
                  )}
                </h5>
              </>
            )
          },
        },
        {
          dataField: "created_date",
          text: "Created",
          sort: true,
          style: {
            width: "20%",
          },
          formatter: (cellContent, cameraAsset) => {
            if (cameraAsset.created_date) {
              return (
                <div>
                  <ReactTimeAgo
                    date={
                      Number.isNaN(cameraAsset.created_date)
                        ? Date.parse(cameraAsset.created_date)
                        : cameraAsset.created_date
                    }
                    locale="en-US"
                  />
                </div>
              )
            } else return <div>INACTIVE</div>
          },
        },
        {
          text: "Active",
          dataField: "is_active",
          sort: true,
          style: {
            width: "30%",
          },
          formatter: (cellContent, cameraAsset) => {
            if (cameraAsset.is_active) {
              return <div>ACTIVE</div>
            } else return <div>INACTIVE</div>
          },
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          style: {
            width: "10%",
          },
          formatter: (cellContent, cameraAsset) => (
            <div className="d-flex align-items-center gap-3">
              <div role="button" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => {
                    e.stopPropagation(),
                      e.preventDefault(),
                      this.handleEditClick(cameraAsset)
                  }}
                ></i>
              </div>
              <CopyToClipboard
                text={cameraAsset.combo_id}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
              <CopyToClipboard
                text={cameraAsset.share_url}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Share URl">
                  <i className="bx bx-share-alt" role="button" />
                </Tooltip>
              </CopyToClipboard>
              {(cameraAsset.is_active === true ||
                cameraAsset.is_active === undefined) && (
                <div className="flex-fill">
                  <Tooltip title="Deactivate">
                    <i
                      className="bx bx-block"
                      role="button"
                      onClick={() =>
                        this._activateOrDeactivateCameraAsset(
                          "deactivate",
                          cameraAsset
                        )
                      }
                      style={{ color: "red" }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          ),
        },
      ],
    }
    this.handleEditClick = this.handleEditClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidUserSubmit = this.handleValidUserSubmit.bind(this)
    this.handleEditClicks = this.handleEditClicks.bind(this)
  }

  componentDidMount() {
    const { cameraAssetList: users } = this.props
    this.setState({ users })
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  handleEditClicks = arg => {
    this.setState({ showForm: true, formAction: "create" })
    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users } = this.props
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      this.setState({ users: {}, isEdit: false })
    }
    if (this.props.refetchData) {
      this.props.getInitialData()
    }
  }

  /* Insert,Update Delete data */

  handleDeleteUser = user => {
    this.props.dispatch(deleteUser({ id: user.userId }))
  }

  handleEditClick = arg => {
    const user = arg
    this.setState({ selectedUser: user, showForm: true, formAction: "edit" })
    this.toggle()
  }

  handleAssignClick = async arg => {
    const user = arg
    this.setState({
      selectedUser: user,
      showForm: true,
      formAction: "assign",
    })
    this.toggle()
  }

  /**
   * Handling submit user on user form
   */
  handleValidUserSubmit = (e, values) => {
    const { onAddNewUser, onUpdateUser } = this.props
    const { isEdit, users, selectedUser } = this.state

    if (isEdit) {
      const updateUser = {
        id: users.id,
        name: values.name,
        designation: values.designation,
        tags: values.tags,
        email: values.email,
        projects: values.projects,
      }

      // update user
      onUpdateUser(updateUser)
    } else {
      const newUser = {
        id: Math.floor(Math.random() * (30 - 20)) + 20,
        name: values["name"],
        designation: values["designation"],
        email: values["email"],
        tags: values["tags"],
        projects: values["projects"],
      }
      // save new user
      onAddNewUser(newUser)
    }
    this.setState({ selectedUser: null })
    this.toggle()
  }

  oncancel = () => {
    this.setState({ showForm: false, selectedUser: undefined })
  }

  rowEvents = {
    onClick: (e, row, index) => {
      this.setState({ index })
      if ([12, 32].includes(row.userType)) {
        this.props.dispatch(
          getManagersList({
            userId: row.userId,
            subCurrentPage: this.props.subCurrentPage,
            subPageSize: this.props.subPageSize,
          })
        )
      }
    },
  }

  _getExpandedRows = () => {
    let expanded = []
    if (
      this.props.communityManagers &&
      this.props.communityManagers.length > 0
    ) {
      return [parseInt(this.props.communityManagers[0].community.leadId)]
    }
    return expanded
  }

  render() {
    if (this.props.loading) {
      return
    }
    const { SearchBar } = Search
    const { isEdit, users = [] } = this.state

    const pageOptions = {
      sizePerPage: 10,
      totalSize: users.length, // replace later with size(users),
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ]

    const selectRow = {
      mode: "checkbox",
    }

    const expandRow = {
      expanded: this._getExpandedRows(),
      renderer: (row, rowIndex) => {
        return (
          <div>
            <BootstrapTable
              data={this.props.cameraAssetList}
              columns={this.state.CameraAssetColumns.map(column => {
                const { text, ...rest } = column
                return { ...rest }
              })}
              keyField="userId"
              classes={"table align-middle table-light table-hover"}
              bordered={false}
              striped={false}
              responsive
            />
            <div className="pagination pagination-rounded justify-content-end mb-2">
              <Pagination
                style={{ marginTop: "1rem" }}
                onChange={val => this.props._onSubPageChange(val, row.userId)}
                defaultCurrent={this.props.subCurrentPage}
                total={
                  this.props.totalManagers < 10000
                    ? this.props.totalManagers
                    : 10000
                }
                pageSize={this.props.subPageSize}
                showSizeChanger={false}
              />
            </div>
          </div>
        )
      },
    }

    return (
      <React.Fragment>
        <Container fluid style={{ minHeight: "90vh" }}>
          <Row className="justify-content-end">
            {!this.state.showForm && (
              <Col sm="8">
                <div className="text-sm-end pb-2">
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={this.handleEditClicks}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create
                  </Button>
                </div>
              </Col>
            )}
            <Col lg={this.state.showForm ? "7" : "12"}>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={this.state.CameraAssetColumns}
                    data={this.props.cameraAssetList}
                    search
                  >
                    {toolkitprops => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitprops.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-hover"
                                }
                                keyField="userId"
                                bordered={false}
                                striped={false}
                                expandRow={expandRow}
                                rowEvents={this.rowEvents}
                                responsive
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2">
                            <Pagination
                              style={{ marginTop: "1rem" }}
                              onChange={val =>
                                this.props.type === "lead"
                                  ? this.props._onSubPageChange(val)
                                  : this.props._onPageChange(val)
                              }
                              defaultCurrent={
                                this.props.type === "lead"
                                  ? this.props.subCurrentPage
                                  : this.props.currentPage
                              }
                              total={
                                this.props.type === "lead"
                                  ? this.props.totalManagers < 10000
                                    ? this.props.totalManagers
                                    : 10000
                                  : this.props.total < 10000
                                  ? this.props.total
                                  : 10000
                              }
                              pageSize={
                                this.props.type === "lead"
                                  ? this.props.subPageSize
                                  : this.props.pageSize
                              }
                              showSizeChanger={false}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
            {this.state.showForm && (
              <CameraAssetUpsertForm
                currentUser={this.props.currentUser}
                action={this.state.formAction}
                oncancel={this.oncancel}
                filtersList={this.props.filtersList}
                effectsList={this.props.effectsList}
                masksList={this.props.masksList}
                leadsData={this.props.leadsData}
                selectedUser={this.state.selectedUser}
              />
            )}
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

CameraAssetList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
}
function mapStateToProps(store) {
  return {
    cameraAssetList: _.get(store, "cameraAsset.cameraAssetList"),
    filtersList: _.get(store, "cameraAsset.filtersList"),
    effectsList: _.get(store, "cameraAsset.effectsList"),
    masksList: _.get(store, "cameraAsset.masksList"),
    queryParams: _.get(store, "routing.location.search")
      ? QueryString.parseUrl(_.get(store, "routing.location.search")).query
      : undefined,
    total: _.get(store, "cameraAsset.total.value"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    communityManagers: _.get(store, "community.communityManagers"),
    currentUserType: _.get(store, "currentUser.currentUser.data.userType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "community.managerDataLoading"),
    dataLoading: _.get(store, "community.dataLoading"),
    refetchData: _.get(store, "community.refetchData"),
    totalManagers: _.get(store, "community.totalManagers"),
    leadsData: _.get(store, "community.leads"),
  }
}

export default connect(mapStateToProps)(CameraAssetList)
