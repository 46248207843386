import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import toastr from "toastr"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Pagination, Tag, Divider } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getRooms,
  resetState,
  upsertRoom,
  shutRoom,
  getJoshLiveConfig,
  updateJoshLiveConfig,
} from "appRedux/slices/joshlive"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import Chips from "components/CommonFilter/chips"
import { banned_organizations, roomFilters } from "constants/uiConstants"
import RoomCard from "./roomCard"
import RoomUpsertForm from "../../components/JoshLive/UpdateRoomInfo"
import RoomConfigUpsertForm from "../../components/JoshLive/RoomConfigUpsertForm"
import { formatNumber } from "../../util/common"
import {
  ROOM_DEACTIVATE_REASONS,
  NO_PERMISSION_MESSAGE,
} from "../../constants/uiConstants"
import StandardImageList from "components/ImageList/ImageList"

class BrowseHashtag extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 12,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchType:
        this.props.queryParams && this.props.queryParams["searchType"]
          ? this.props.queryParams["searchType"]
          : "title",
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : null,
      orderByField:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : "startDateTime",
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "desc",
      // hashTags:
      //   this.props.queryParams && this.props.queryParams["hashTags"]
      //     ? this.props.queryParams["hashTags"]
      //     : null,
    },
    showFilters: false,
    showAdvancedFilters: false,
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getRooms({ filters: _.deepClean(filters) }))
      this.props.dispatch(getJoshLiveConfig())
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }
    this.setState({ filters: newFilters })
  }

  _searchHashtag = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/rooms/browse?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/rooms/browse?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
      this.props.dispatch(getRooms({ filters: _.deepClean(newFilters) }))
    })
  }

  _onSearch = () => {
    let filters = _.cloneDeep(this.state.filters)
    _.map(this.state.filters, (filter, key) => {
      if (!filter) {
        _.unset(filters, key)
      }
    })
    this.props.history.push(`/rooms/browse?${QueryString.stringify(filters)}`)
    this.props.dispatch(getRooms({ filters: _.deepClean(filters) }))
  }

  _closeFilters = () => {
    this._onSearch()
    this.setState({ showFilters: false, showAdvancedFilters: false })
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: roomFilters[filter].defaultValue,
      showFilters: false,
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/rooms/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        let filters = _.cloneDeep(this.state.filters)
        this.props.dispatch(getRooms({ filters: _.deepClean(filters) }))
      }
    )
  }

  _onEditClick = data => {
    this.setState({ selectedRoom: data, showEditForm: true, action: "update" })
  }

  _onConfigUpdate = data => {
    this.props.dispatch(updateJoshLiveConfig(data))
    this.setState({
      showConfigForm: false,
    })
  }

  _onSubmit = (formData, s3location) => {
    let finalData = new FormData()

    finalData.append("details", JSON.stringify(formData))

    if (s3location) {
      finalData.append("file", s3location)
    }

    this.props.dispatch(upsertRoom(finalData))
    this.setState({ showEditForm: false, selectedRoom: undefined })
    this.props.dispatch(getRooms({ filters: _.deepClean(this.state.filters) }))
  }

  _toggleViewModal = () => {
    this.setState({
      showActionReasonModal: false,
      showWarningReasonModal: false,
    })
  }

  _shutRoomModal = () => {
    return (
      <div style={{ padding: "10px" }}>
        <ModalHeader toggle={this._toggleViewModal}>Shut Reasons</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap">
            {ROOM_DEACTIVATE_REASONS.map((reason, index) => {
              return (
                <div className="form-check form-radio-outline form-radio-primary mb-3 mx-2">
                  <input
                    type="radio"
                    id={`radio${index}`}
                    name="customRadiooutlinecolor1"
                    className="form-check-input"
                    value={reason.value}
                    onChange={e =>
                      this.setState({ shutReason: e.target.value })
                    }
                  />
                  <label className="form-check-label" htmlFor={`radio${index}`}>
                    {reason.label}
                  </label>
                </div>
              )
            })}
          </div>
          <Divider>Banned Organization</Divider>
          <div className="d-flex flex-wrap">
            {banned_organizations.map((reason, index) => {
              return (
                <div className="form-check form-radio-outline form-radio-primary mb-3 mx-2">
                  <input
                    type="radio"
                    id={`radio${index}`}
                    name="customRadiooutlinecolor1"
                    className="form-check-input"
                    value={reason.value}
                    onChange={e =>
                      this.setState({
                        shutReason: `banned_organization_${e.target.value}`,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor={`radio${index}`}>
                    {reason.label}
                  </label>
                </div>
              )
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <div
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes(
                  "EDIT_BROWSE_ROOM"
                )
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this._shutRoom()
            }}
            role="button"
            className="btn btn-danger d-flex mx-1"
          >
            Shut Room
          </div>
        </ModalFooter>
      </div>
    )
  }

  _renderModerationImages = () => {
    return (
      <div>
        <ModalHeader
          toggle={() => {
            this.setState({
              showModerationImages: false,
              moderationImages: null,
            })
          }}
        >
          Moderation Images
        </ModalHeader>
        <ModalBody>
          <StandardImageList imageData={this.state.moderationImages} />
        </ModalBody>
      </div>
    )
  }

  _onShutRoomClick = data => {
    this.setState({
      showActionReasonModal: true,
      roomData: data,
    })
  }

  _onWarnUserClick = data => {
    this.setState({
      showWarningReasonModal: true,
      roomData: data,
    })
  }

  _shutRoom = () => {
    this.setState({
      showActionReasonModal: false,
    })
    this.props.dispatch(
      shutRoom({
        roomId: this.state.roomData,
        shutReason: this.state.shutReason,
      })
    )
    this.props.dispatch(getRooms({ filters: _.deepClean(this.state.filters) }))
  }

  toggleCreate = () => {
    this.setState({ showEditForm: true, action: "create" })
  }

  toggleConfig = () => {
    this.setState({ showConfigForm: true })
  }

  _onShowModerationImages = data => {
    const moderationImages = data.streamModerationDetails.map(item => ({
      img: item.stream_url,
      title: item.id,
    }))
    this.setState({ showModerationImages: true, moderationImages })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showEditForm && !this.state.showConfigForm && (
          <React.Fragment>
            <Chips
              type="rooms"
              showFilters={this.state.showFilters}
              showAdvancedFilters={this.state.showAdvancedFilters}
              filters={this.state.filters}
              search={this._closeFilters}
              removeFilter={this._removeFilter}
              handleFilterChange={this._handleFilterChange}
              toggleFilters={this._toggleFilters}
              toggleAdvancedFilters={this._toggleAdvancedFilters}
            />
            <div className="d-flex justify-content-end mb-2">
              {!this.state.showForm && (
                <button
                  className="font-16 btn-block btn btn-primary mx-1"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_BROWSE_ROOM"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.toggleCreate()
                  }}
                >
                  <i className="fas fa-plus me-1" />
                  Create
                </button>
              )}
              {!this.state.showConfigForm && (
                <button
                  className="font-16 btn-block btn btn-primary mx-1"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_JOSH_LIVE_CONFIG"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.toggleConfig()
                  }}
                >
                  <i className="fa fa-edit me-1" />
                  Config
                </button>
              )}
            </div>
            {this.props.roomList && this.props.roomList.length > 0 && (
              <div style={{ textAlign: "center" }} className="mb-2">
                <Tag color="geekblue">
                  Showing <b>{this.props.roomList.length}</b> of{" "}
                  <b>{formatNumber(this.props.total)}</b> Rooms
                </Tag>
              </div>
            )}
            <div className="room-card-container">
              {this.props.roomList &&
                this.props.roomList.length > 0 &&
                this.props.roomList.map(room => {
                  return (
                    <RoomCard
                      data={room}
                      onEditClick={data => this._onEditClick(data)}
                      onShutRoom={this._onShutRoomClick}
                      onWarnUser={this._onWarnUserClick}
                      currentUser={this.props.currentUser}
                      onShowModerationImages={this._onShowModerationImages}
                    />
                  )
                })}
            </div>
            <div className="d-flex justify-content-end mb-3">
              <Pagination
                style={{ marginTop: "1rem" }}
                onChange={this._onPageChange}
                defaultCurrent={this.state.filters.currentPage}
                total={this.props.total < 10000 ? this.props.total : 10000}
                pageSize={this.state.filters.pageSize}
                showSizeChanger={false}
              />
            </div>
          </React.Fragment>
        )}

        {this.state.showEditForm && (
          <RoomUpsertForm
            data={this.state.selectedRoom}
            action={this.state.action}
            onCancel={() => {
              this.setState({ showEditForm: false, selectedRoom: undefined })
            }}
            onSubmit={this._onSubmit}
          />
        )}

        {this.state.showConfigForm && (
          <RoomConfigUpsertForm
            action={this.state.action}
            onCancel={() => {
              this.setState({ showConfigForm: false })
            }}
            onSubmit={this._onConfigUpdate}
            data={this.props.liveConfig}
          />
        )}

        {this.state.showActionReasonModal && (
          <Modal
            centered={true}
            className="exampleModal"
            isOpen={this.state.showActionReasonModal}
          >
            {this._shutRoomModal()}
          </Modal>
        )}

        {this.state.showWarningReasonModal && (
          <Modal
            centered={true}
            className="exampleModal"
            isOpen={this.state.showWarningReasonModal}
          >
            {this._warnUserModal()}
          </Modal>
        )}

        {this.state.showModerationImages && (
          <Modal
            size="lg"
            centered={true}
            className="exampleModal"
            isOpen={this.state.showModerationImages}
          >
            {this._renderModerationImages()}
          </Modal>
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    roomList: _.get(store, "joshlive.roomsList"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "joshlive.total"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "joshlive.loading"),
    liveConfig: _.get(store, "joshlive.liveConfig"),
  }
}

export default connect(mapStateToProps)(BrowseHashtag)
