import React from "react"
import { connect } from "react-redux"
import { message, Upload } from "antd"
import { Button, Row, Col } from "reactstrap"
import { UploadOutlined } from "@ant-design/icons"
import ValidatorForm, { Select, TextField } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { uploadBulkAudio } from "../../appRedux/slices/audio"
import _ from "lodash"
import "./content.styles.css"

import { bulkUploadDeactivateAudio } from "../../clientServices/audioService"

class UploadBulkContentForm extends ErrorBoundComponent {
  state = {
    uploadType: "ftp",
  }

  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }

  _onCSVFileRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row gutter={16}>
          <Col lg="6">
            <Col sm="12">
              <Select
                label={"Upload Type"}
                placeholderLabel="Select an upload type"
                field="uploadType"
                className="creator-form"
                validations={["required"]}
                errors={["This field is required"]}
                defaultValue={"ftp"}
                onChange={value => this.setState({ uploadType: value })}
                options={[
                  { label: "FTP", value: "ftp" },
                  { label: "FTPS", value: "ftps" },
                  { label: "Update", value: "update" },
                  { label: "Bulk Status Update", value: "bulkStatusUpdate" },
                ]}
              />
            </Col>
            {(this.state.uploadType === "ftp" ||
              this.state.uploadType === "ftps") && (
              <>
                <Col sm="12">
                  <TextField
                    label={"FTP Host"}
                    placeholderLabel="Enter the FTP Host Details"
                    field="host"
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
                {/* <Col sm="12">
                  <TextField
                    label={"FTP Username"}
                    placeholderLabel="Enter the FTP User Name"
                    field="username"
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col> */}
              </>
            )}
          </Col>
          <Col lg="6">
            {(this.state.uploadType === "ftp" ||
              this.state.uploadType === "ftps") && (
              <>
                {/* <Col sm="12">
                  <TextField
                    label={"FTP Host"}
                    placeholderLabel="Enter the FTP Host Details"
                    field="host"
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col> */}
                <Col sm="12">
                  <TextField
                    label={"FTP Username"}
                    placeholderLabel="Enter the FTP User Name"
                    field="username"
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
                <Col sm="12">
                  <TextField
                    label={"FTP Password"}
                    placeholderLabel="Enter the FTP Password"
                    field="password"
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
              </>
            )}
          </Col>
          <Col style={{ marginTop: "10px" }}>
            <Upload
              showUploadList={true}
              onRemove={this._onCSVFileRemove}
              defaultFileList={this.state.csvFileList}
              fileList={this.state.csvFileList}
              customRequest={this._uploadCSV}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <Button color="primary">
                <UploadOutlined /> Click to upload CSV
              </Button>
            </Upload>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm="12">
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                color="primary"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                <UploadOutlined />
                Upload
              </Button>
              <Button
                color="danger"
                size="default"
                disabled={this.state.actionLoading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (!this.state.csvFile) {
      message.error("Please upload the csv")
    }
    if (this.state.uploadType == "bulkStatusUpdate") {
      const formData2 = new FormData()
      formData2.append("file", this.state.csvFile)
      await bulkUploadDeactivateAudio(formData2)
    } else {
      formData.bulk_upload = true

      let data = new FormData()
      data.append("file", this.state.csvFile)
      data.append("details", JSON.stringify(formData))

      this.props.dispatch(uploadBulkAudio(data))
    }
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    loading: _.get(store, "content.loading"),
    common: _.get(store, "common"),
  }
}

export default connect(mapStateToProps)(UploadBulkContentForm)
