import React from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Divider } from "antd"
import { Col, Row } from "reactstrap"

const EntitiesDrawer = props => {
  return (
    <React.Fragment>
      <Row gutter={12}>
        {props.filterKeys.map(filterKey => {
          return (
            <Col lg="3">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">
                  {filterKey.display_name}
                </h5>
              </Divider>
              <Input
                defaultValue={props.filters[filterKey.key_name]}
                placeholder={filterKey.display_name}
                onChange={e =>
                  props.handleFilterChange(
                    e.target.value.trim(),
                    filterKey.key_name
                  )
                }
                allowClear={true}
                name={filterKey.key_name}
                style={{ width: "100%" }}
              />
            </Col>
          )
        })}
      </Row>
    </React.Fragment>
  )
}

export default withRouter(EntitiesDrawer)
