import React from "react"
import { connect } from "react-redux"
import { Card, CardBody, Badge, Row, CardImg } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  NO_PERMISSION_MESSAGE,
  LANGUAGE_MAPPING,
} from "../../constants/uiConstants"

class MemeCard extends React.Component {
  render() {
    const { data } = this.props

    const image = data ? data.thumbnail_url || undefined : undefined

    return (
      <React.Fragment>
        <Card
          onClick={() => this.props.onEditClick(data)}
          className="text-center shadow-lg"
          style={{
            minHeight: "250px",
            borderRadius: "10px",
          }}
          key={data?.gift_element_uuid || data?.gift_collection_uuid || ""}
        >
          <div className="bg-primary bg-soft">
            <Row>
              {image ? (
                <div id={"play" + data.id} style={{ height: "20rem" }}>
                  <CardImg
                    top
                    className="img-fluid"
                    src={image}
                    alt="Skote"
                    // onClick={e => {
                    //   e.preventDefault()
                    //   e.stopPropagation()
                    // }}
                    style={{
                      height: "20rem",
                      cursor: "pointer",
                      opacity: "0.8",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                </div>
              ) : (
                <div
                  className="py-5 px-0"
                  style={{
                    height: "20rem",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <i className={`fa fa-photo-flim fa-7x py-4 text-secondary`} />
                </div>
              )}
            </Row>
          </div>
          <CardBody style={{ padding: "1rem 0.25rem" }}>
            {/* <Tooltip title={data?.type || ""} style={{ minHeight: "20px" }}>
                            <div className="font-size-16 text-truncate font-weight-semibold">
                                <i className={`mx-1`} /> {data?.type || ""}
                            </div>
                        </Tooltip> */}

            {/* <div style={{ minHeight: "30px" }}> */}
            {data.type ? (
              <Tooltip title="Type" style={{ minHeight: "20px" }}>
                <Badge
                  className={`${"badge-soft-primary"} ms-1 my-2 text-black me-1 font-size-16 text-truncate font-weight-semibold`}
                >
                  {data.type}
                </Badge>
              </Tooltip>
            ) : null}
            <br />
            {data.target
              ? data.target.length
                ? data.target.map(({ lang_code = "" } = {}) => (
                    <Tooltip title="Language" style={{ minHeight: "20px" }}>
                      <Badge
                        className={`${"badge-soft-success"} ms-1 my-2 text-black me-1 font-size-16 text-truncate`}
                      >
                        {LANGUAGE_MAPPING[lang_code]}
                      </Badge>
                    </Tooltip>
                  ))
                : null
              : null}
            {/* </div> */}
          </CardBody>
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={data?.id}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>

            {data?.download_url ? (
              <div className="flex-fill">
                <CopyToClipboard
                  text={data?.download_url}
                  onCopy={e => {
                    toastr.success("Copied sucessfully!")
                  }}
                >
                  <Tooltip title="Copy Download Url">
                    <i className="bx bx-copy-alt" role="button" />
                  </Tooltip>
                </CopyToClipboard>
              </div>
            ) : null}

            <div className="flex-fill">
              <Tooltip title="Edit">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={e => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_BROWSE_MEME"
                      ) &&
                      this.props?.location?.pathname.split("/")[1] === "meme"
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick(data)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Card>
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    refetch: _.get(store, "meme.refetchData"),
  }
}

export default connect(mapStateToProps)(MemeCard)
