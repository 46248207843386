import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { logoutUser } from "../../clientServices/authService"
import { getCurrentUser } from "appRedux/slices/currentUser"

//redux
import { useSelector, useDispatch } from "react-redux"

const Logout = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      await logoutUser()
      dispatch(getCurrentUser())
    })()
  }, [])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
