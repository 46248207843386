import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestCMSVideoAssetListByFilters,
  requestUpsertCMSVideoAsset,
} from "../requests/cmsVideoAssets"
import {
  getCMSVideoAssetListByFilters,
  getCMSVideoAssetListByFiltersSuccess,
  upsertCMSVideoAsset,
  upsertCMSVideoAssetSuccess,
} from "../../slices/cmsVideoAssets"
import { fetchError } from "appRedux/slices/common"

function* handleGetCMSVideoAssetListByFilters(payload) {
  try {
    const data = yield call(requestCMSVideoAssetListByFilters, payload)
    yield put(getCMSVideoAssetListByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertCMSVideoAsset(payload) {
  try {
    yield call(requestUpsertCMSVideoAsset, payload)
    yield put(upsertCMSVideoAssetSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(
    getCMSVideoAssetListByFilters.type,
    handleGetCMSVideoAssetListByFilters
  )
  yield takeLatest(upsertCMSVideoAsset.type, handleUpsertCMSVideoAsset)
}
