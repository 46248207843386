import React from "react"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Table,
  Badge,
  Row,
  CardTitle,
  CardText,
} from "reactstrap"
import ReactTimeAgo from "react-time-ago"
import { Player } from "@lottiefiles/react-lottie-player"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { LANGUAGES } from "../../constants/uiConstants"
import ReactPlayer from "react-player"
import { requestGetAppUserDetailsES } from "../../clientServices/appUserService"
import ReactCardFlip from "react-card-flip"
import CustomScrollbars from "util/CustomScrollbars"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import { MdMobileScreenShare } from "react-icons/md"

let currentDate = Date.now()

class HashtagCard extends React.Component {
  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
    this.state = {
      isAuthorized: false,
      isFlipped: false,
    }
  }

  componentDidMount = async () => {
    const { data } = this.props
    const userData = await requestGetAppUserDetailsES({
      payload: { userUuid: data.hostId },
    })

    this.setState({
      isAuthorized:
        (userData?.data?.allow_josh_live &&
          userData.data.allow_josh_live.toLowerCase() === "y") ||
        false,
    })
  }

  _setPlayBack = () => {
    this.videoRef.current.playbackRate = 1.0
    this.videoRef.current.volume = 0.1
  }

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  getTitle = (title, field, titleAlt) => {
    return (
      <Badge
        className={`${
          field === true ? "badge-soft-success" : "badge-soft-danger"
        } ms-1 my-1`}
      >
        <div className="font-size-15">
          <Tooltip title={title}>
            <div>
              <span>{field === true ? title : titleAlt}</span>{" "}
              <i className={field === true ? "fas fa-check" : ""} />
            </div>
          </Tooltip>
        </div>
      </Badge>
    )
  }

  render() {
    const { data } = this.props
    const endDate = new Date(data.endDateTime).valueOf()
    return (
      <React.Fragment>
        <ReactCardFlip
          isFlipped={this.state.isFlipped}
          flipDirection={"horizontal"}
        >
          <Card
            className={`text-center shadow-lg`}
            style={{
              height: "612px",
              borderRadius: "10px",
              backgroundColor: "",
            }}
          >
            <Col>
              {!data.shutRoom ? (
                <ReactPlayer
                  url={
                    data.playBackUrlHls
                      ? "https:" + data.playBackUrlHls.split(":")[1]
                      : data.deepLink
                  }
                  playing
                  loop
                  controls
                  height="455px"
                  width="100%"
                  muted
                />
              ) : (
                <div
                  role="button"
                  style={{ height: "455px" }}
                  className="d-flex align-items-center justify-content-center text-muted"
                >
                  <i
                    className="bx bx-building-house"
                    style={{ fontSize: "10rem" }}
                  />
                </div>
              )}
            </Col>

            <CardBody>
              <CardTitle
                className="mt-0 d-flex mx-1"
                style={{ height: "20px" }}
              >
                <Tooltip title={data.title}>
                  <div className="text-truncate">{data.title}</div>
                </Tooltip>
                <div
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <CopyToClipboard
                    text={data.id}
                    onCopy={e => toastr.success("Copied Successfully")}
                  >
                    <Tooltip title="Copy Id">
                      <div
                        role="button"
                        onClick={() => {}}
                        id={"copy" + data.id}
                      >
                        <i className="bx bx-copy fa-1x px-2" />
                      </div>
                    </Tooltip>
                  </CopyToClipboard>
                </div>
                <div>
                  {data.shutRoom ? (
                    <Badge
                      className={`badge-soft-${
                        data.shutRoom ? "danger" : "success"
                      } `}
                    >
                      <div className="font-size-12">
                        <Tooltip title={"Status"}>Ended</Tooltip>
                      </div>
                    </Badge>
                  ) : (
                    <Player
                      autoplay={true}
                      loop={true}
                      controls={true}
                      src=" https://assets7.lottiefiles.com/packages/lf20_ua3gryvm.json"
                      style={{ width: 40, height: 20 }}
                    ></Player>
                  )}
                </div>
              </CardTitle>
              <CardText className="text-start">
                <div className={` ms-1 my-1`} style={{ height: "20px" }}>
                  {data.topic && (
                    <div
                      className="font-size-15 text-black-50"
                      role="button"
                      key={"modified_date" + data.topic}
                    >
                      <Tooltip title={data.topic}>{data.topic}</Tooltip>
                    </div>
                  )}
                </div>
                {!data.shutRoom && (
                  <div className="mx-1">
                    <Badge
                      className={`badge-soft-${
                        this.state.isAuthorized === true ? "success" : "danger"
                      } `}
                    >
                      <div className="font-size-12">
                        <Tooltip title={"User Live Status"}>
                          {this.state.isAuthorized === true
                            ? "Authorized"
                            : "Unauthorized"}
                        </Tooltip>
                      </div>
                    </Badge>
                  </div>
                )}
              </CardText>
            </CardBody>
            <CardFooter className="bg-transparent border-top">
              <div className="contact-links d-flex font-size-20">
                <div className="flex-fill">
                  <CopyToClipboard
                    text={data.roomId}
                    title="Copy Room Id"
                    onCopy={() => {
                      toastr.success("Copied Successfully")
                    }}
                  >
                    <Tooltip title="Copy Room ID">
                      <div role="button">
                        <i className="bx bx-building-house" />
                      </div>
                    </Tooltip>
                  </CopyToClipboard>
                </div>
                <div className="flex-fill">
                  <CopyToClipboard
                    text={data.hostId}
                    title="Copy Host Id"
                    onCopy={() => {
                      toastr.success("Copied Successfully")
                      // props.viewQuery(data.hostId)
                    }}
                  >
                    <Tooltip title="Copy Host Id">
                      <div role="button">
                        <i className="bx bx-user" />
                      </div>
                    </Tooltip>
                  </CopyToClipboard>
                </div>
                <div className="flex-fill">
                  <Tooltip title="Edit Room">
                    <div
                      role="button"
                      onClick={() => {
                        if (
                          !this.props.currentUser?.permissions?.includes(
                            "EDIT_BROWSE_ROOM"
                          )
                        ) {
                          toastr.error(NO_PERMISSION_MESSAGE)
                          return
                        }
                        this.props.onEditClick(data)
                      }}
                    >
                      <i className="bx bx-pencil" />
                    </div>
                  </Tooltip>
                </div>
                <div className="flex-fill">
                  <Tooltip title="Shut Room">
                    <div
                      role="button"
                      onClick={() => {
                        if (
                          !this.props.currentUser?.permissions?.includes(
                            "EDIT_BROWSE_ROOM"
                          )
                        ) {
                          toastr.error(NO_PERMISSION_MESSAGE)
                          return
                        }
                        this.props.onShutRoom(data.id)
                      }}
                    >
                      <i className="bx bx-block" style={{ color: "red" }} />
                    </div>
                  </Tooltip>
                </div>
                {!_.isEmpty(data.streamModerationDetails) && (
                  <div className="flex-fill" role="button">
                    <Tooltip title="Moderation Images">
                      <MdMobileScreenShare
                        className="mb-2"
                        onClick={() => {
                          if (
                            !this.props.currentUser?.permissions?.includes(
                              "EDIT_BROWSE_ROOM"
                            )
                          ) {
                            toastr.error(NO_PERMISSION_MESSAGE)
                            return
                          }
                          this.props.onShowModerationImages(data)
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
                <div>
                  <Tooltip title="View More">
                    <i
                      className="bx bx-play"
                      role="button"
                      style={{ fontSize: "20px" }}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ isFlipped: true })
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </CardFooter>
          </Card>
          <Card
            className={`text-center shadow-lg`}
            style={{
              height: "612px",
              overflowX: "hidden",
              overflowY: "auto",
              borderRadius: "10px",
            }}
          >
            <CustomScrollbars>
              <CardBody className="px-0 py-0">
                <Row className="my-sm-2">
                  <Col className="ms-2">
                    <div className="avatar-xs">
                      <div className="avatar-title rounded-circle bg-light">
                        <span
                          className={
                            "avatar-title rounded-circle bg-light bg-" +
                            "secondary" +
                            " text-warning" +
                            " font-size-16" +
                            " font-weight-semibold"
                          }
                        >
                          <i className="bx bx-bar-chart" />
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col className="d-flex justify-content-end me-2">
                    <div
                      className="avatar-xs"
                      role="button"
                      onClick={() => this.setState({ isFlipped: false })}
                    >
                      <div className="avatar-title rounded-circle bg-light">
                        <span
                          className={
                            "avatar-title rounded-circle bg-light bg-" +
                            "secondary" +
                            " text-dark" +
                            " font-size-16" +
                            " font-weight-semibold"
                          }
                        >
                          <i className="fa fa-times-circle" />
                        </span>
                      </div>
                    </div>
                    <div></div>
                  </Col>
                </Row>
                <Row>
                  <div className="table-responsive">
                    <Table className="mb-0 table-striped">
                      <tbody>
                        <tr className="text-start">
                          <th scope="row">HashTags:</th>
                          <td>
                            <div style={{ height: "25px" }}>
                              {(data.hashTags &&
                                data.hashTags.length > 0 &&
                                data.hashTags.slice(0, 2).map((tag, i) => {
                                  return (
                                    <Badge className="badge-soft-primary ms-1 my-1">
                                      <div className="font-size-15">
                                        <Tooltip title="HashTags">
                                          {tag}
                                        </Tooltip>
                                      </div>
                                    </Badge>
                                  )
                                })) ||
                                "-"}
                              {data.hashTags && data.hashTags.length > 2 && (
                                <Tooltip
                                  title={data.hashTags
                                    .slice(2, data.hashTags.length)
                                    .map(tag => {
                                      return <div role="button">{tag}</div>
                                    })}
                                  placement="right"
                                >
                                  <Badge className="badge-soft-primary ms-1 my-1">
                                    <div
                                      className="font-size-15 px-2"
                                      key={
                                        "language_more" + data.hashTags.length
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      +{data.hashTags.length - 2} more
                                    </div>
                                  </Badge>
                                </Tooltip>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Start Time:</th>
                          <td>
                            Started{" "}
                            {data.startDateTime && (
                              <ReactTimeAgo
                                date={
                                  Number.isNaN(data.startDateTime)
                                    ? Date.parse(data.startDateTime)
                                    : data.startDateTime
                                }
                                locale="en-US"
                              />
                            )}
                          </td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">End Time:</th>
                          <td>
                            {endDate < currentDate ? "Ended " : "Ending "}
                            {endDate && endDate !== "Invalid Date" && (
                              <ReactTimeAgo
                                future={!(endDate < currentDate)}
                                date={
                                  Number.isNaN(data.endDateTime)
                                    ? Date.parse(data.endDateTime)
                                    : data.endDateTime
                                }
                                locale="en-US"
                              />
                            )}
                          </td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Interest Sub Category:</th>
                          <td>{data.interestSubCategoryName || "-"}</td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Interest Category:</th>
                          <td>{data.interestCategoryName || "-"}</td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Privacy Type:</th>
                          <td>{data.privacyType || "-"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Row>
              </CardBody>
            </CustomScrollbars>
          </Card>
        </ReactCardFlip>
      </React.Fragment>
    )
  }
}

export default HashtagCard
