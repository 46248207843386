import { createSlice } from "@reduxjs/toolkit"

const creatorIssuesSlice = createSlice({
  name: "creatorIssues",
  initialState: {
    loading: false,
    loadingActivity: false,
    issuesList: [],
    refetchData: false,
    total: 0,
    issuedDetails: 0,
    cmsUserList: [],
  },

  reducers: {
    getIssues(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getIssuesSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        issuesList: payload.result.data || [],
        severity_tasks:
          payload.result.severityResult &&
          payload.result.severityResult.severity_tasks
            ? payload.result.severityResult.severity_tasks
            : [],
        issuesDetails: payload.result.issuesDetails
          ? payload.result.issuesDetails
          : {},
        total: payload.result.total,
      }
    },
    createIssue(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createIssueSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertIssue(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertIssueSuccess(state) {
      return {
        ...state,
        loading: false,
        loadingActivity: false,
        issuesList: [],
        refetchData: true,
      }
    },
    getActivity(state) {
      return {
        ...state,
        loadingActivity: true,
      }
    },
    getActivitySuccess(state, { payload }) {
      return {
        ...state,
        loadingActivity: false,
        activityList: payload.result.data || [],
        totalActivity: payload.result.count || 0,
      }
    },
    getCmsUser(state, action) {
      return {
        ...state,
        cmsUserList: null,
      }
    },

    getCmsUserSuccess(state, { payload }) {
      return {
        ...state,
        cmsUserList: payload.data.userList,
      }
    },
    resetState() {
      return {
        loading: false,
        loadingActivity: false,
        issuesList: [],
        refetchData: false,
        total: 0,
        openIssues: 0,
        cmsUserList: [],
      }
    },
  },
})

export const {
  createIssue,
  createIssueSuccess,
  getIssues,
  getIssuesSuccess,
  getActivity,
  upsertIssue,
  upsertIssueSuccess,
  getActivitySuccess,
  getCmsUser,
  getCmsUserSuccess,
  resetState,
} = creatorIssuesSlice.actions

export default creatorIssuesSlice.reducer
