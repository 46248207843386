import { createSlice } from "@reduxjs/toolkit"

const memeSlice = createSlice({
  name: "meme",
  initialState: {
    memeList: [],
    total: 0,
    loading: false,
    refetchData: false,
  },
  reducers: {
    getMemes(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getMemesSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        total: payload.data.total || 1,
        memeList: payload.data.data,
      }
    },
    upsertMeme(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertMemeSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    reorderMeme(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    reorderMemeSuccess(state) {
      return {
        ...state,
        loading: true,
        refetchData: true,
      }
    },
    refreshMeme(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState() {
      return {
        memeList: [],
        total: 0,
        loading: false,
        refetchData: false,
      }
    },
  },
})

export const {
  getMemes,
  getMemesSuccess,
  upsertMeme,
  upsertMemeSuccess,
  reorderMeme,
  reorderMemeSuccess,
  resetState,
  refreshMeme,
} = memeSlice.actions

export default memeSlice.reducer
