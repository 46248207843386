import React from "react"
import { Link, withRouter } from "react-router-dom"
import { Tooltip, Tag, Button, Pagination, Input } from "antd"
import { Row, Col } from "react-bootstrap"
import { connect } from "react-redux"
import QueryString from "query-string"
import {
  AiOutlineVideoCamera,
  AiOutlineFileImage,
  AiOutlineArrowRight,
} from "react-icons/ai"
import {
  competition_logos,
  banned_organizations,
  DEACTIVATE_REASONS,
  emailListForNotifWorthyDeactivation,
  TARGET_CATEGORY,
  TARGET_CATEGORY_V2,
} from "../../constants/uiConstants"
import { CopyToClipboard } from "react-copy-to-clipboard"
import MarkTrendingForm from "../../containers/CommonTrending/trendingUpsert"
import {
  activateDeactivateContent,
  tagContent,
  tagNonEnglish,
  reProcessedContentModerationAction,
  convertToVertical,
  qualityCheckContent,
  reviewContent,
  sendNotificationContentDeactivationAlert,
  getContentByUUIDs,
  removeDuplicate,
} from "../../clientServices/contentService"
import { Redirect } from "react-router"
import _ from "lodash"
import TaxonomyDashboard from "../TaxonomyDashboard/TaxonomyDashboard"
import "./style.css"
import ContentCard from "../ContentCard/contentCard"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import ContentDetails from "../ContentDetails/contentDetails"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { markTrending } from "../../appRedux/slices/trending"
import WarningMessage from "../../containers/Content/warningMessageModal"
import { formatNumber } from "../../util/common"
import ReactSelect from "react-select"
import DuplicateContentCard from "components/ContentCard/duplicateContentCard"
import Auxiliary from "util/Auxiliary"
class DuplicateContentList extends React.Component {
  constructor(props) {
    super(props)
    let contentList = []
    let counter = 0

    for (let item of this.props.contentList) {
      const content = { ...item }
      if (this.props.contentList.length > 1) {
        if (counter === 0) {
          content["prev"] = null
          content["next"] = this.props.contentList[counter + 1]
        } else if (counter < this.props.contentList.length - 1) {
          content["prev"] = this.props.contentList[counter - 1]
          content["next"] = this.props.contentList[counter + 1]
        } else {
          content["prev"] = this.props.contentList[counter - 1]
          content["next"] = null
        }
        counter++
      } else {
        content["prev"] = null
        content["next"] = null
      }
      contentList.push(content)
    }
    let selectedContent = contentList[0]

    if (
      this.props.contentView === "details" &&
      this.props.queryParams?.view === "details" &&
      this.props.queryParams?.content_uuid
    ) {
      contentList.forEach(contentData => {
        if (contentData.content_uuid === this.props.queryParams?.content_uuid) {
          selectedContent = contentData
        }
      })
    }
    if (this.props.contentView === "details") {
      this.props.history.push(
        `/content/browse?view=details&content_uuid=${
          selectedContent?.content_uuid
        }&currentPage=${this.props?.filters?.currentPage || 1}`
      )
    }
    this.state = {
      selectedContent: selectedContent,
      previewContent: this.props.previewContent,
      contentList: contentList,
      redirectUserEdit: false,
      redirectContentEdit: false,
      showActionReasonModal: false,
      deactivatReason: DEACTIVATE_REASONS,
      activateReason: TARGET_CATEGORY,
      targetCategory: TARGET_CATEGORY_V2,
      actionButtonLoading: false,
      previewTaxonomyContent: false,
      originalloading: true,
      contentView: this.props.contentView || "grid",
    }
    this.escFunction = this.escFunction.bind(this)
  }
  formatOptionLabel({ value, label, logoSrc }) {
    return (
      <span>
        {logoSrc ? (
          <img
            src={logoSrc}
            alt={label}
            style={{ hight: "20px", width: "20px" }}
          />
        ) : (
          <i className="fa fa-users" aria-hidden="true"></i>
        )}{" "}
        &nbsp;
        <b>{label}</b>
      </span>
    )
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      if (this.props.pageType === "task") {
        this.setState({ escPressed: true })
        this.handleToggleModal()
      } else {
        this.props.history.push(
          `/content/browse?${QueryString.stringify(
            _.deepClean(this.props.filters)
          )}`
        )
        this._onContentViewChange("grid", null)
      }
    }
  }

  async componentDidMount() {
    if (
      this.props.pageType === "task" &&
      this.props.currentUser.jotUserType === 4
    ) {
      this._contentAction(this.props.contentList[0], "taxonomyContent")
      this.setState({
        selectedContent: this.props.contentList[0],
        previewTaxonomyContent: true,
      })
      return
    }
    if (this.props.contentList && this.props.contentList.length != 0) {
      let contentList = []
      for (let content of this.props.contentList) {
        contentList.push(content.original_content_uuid)
      }
      let originalContent = await getContentByUUIDs(contentList)
      this.setState({
        originalContent: originalContent.data,
        originalloading: false,
        hostName: window.location.hostname,
      })
    }
    window.addEventListener("keydown", this.handleKeyPress)
    document.addEventListener("keydown", this.escFunction, false)
  }

  handleKeyPress = e => {
    if (e.keyCode === 37) {
      this._prevContent()
    }
    if (e.keyCode === 39) {
      this._nextContent()
    }
    if (e.keyCode === 27) {
      this.escFunction(e)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress)
    document.removeEventListener("keydown", this.escFunction, false)
  }

  handleToggleModal() {
    this.setState({
      previewTaxonomyContent: !this.state.previewTaxonomyContent,
      contentView: "grid",
    })
  }

  _handleContentEdit = content => {
    this.setState({
      selectedContent: content.content_uuid,
      redirectContentEdit: true,
    })
  }

  _handleUserEdit = content => {
    this.setState({
      selectedUser: content.user_profile.user_uuid,
      redirectUserEdit: true,
    })
  }

  _changeReset = () => {
    this.setState({
      shouldReset: false,
    })
  }

  _contentActionApi = async (content, action) => {
    try {
      this.setState({ actionButtonLoading: true })
      const content = { ...this.state.selectedContent }
      if (action == "tagNonEnglish") {
        let response = await tagNonEnglish(content, action)
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success(`Content Tagged as Non English Successfully`)
        } else {
          throw Error()
        }
        return
      }

      if (this.state.contentAction === "target_category_v2") {
        let response = await tagContent({
          content_uuid: content.content_uuid,
          target_category_v2: this.state.target_category_v2,
        })
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success(`Content Tagged Successfully`)
        } else {
          throw Error()
        }
        return
      }

      if (this.state.contentAction == "deactivate") {
        content["reject_reason"] = this.state.reject_reason
        if (content["reject_reason"] == "misc") {
          content["miscRejectReason"] = this.state.miscRejectReason
        }
        if (content["reject_reason"] == "competitor_logo") {
          content["competitor_logo_name"] = this.state.competitor_logo_name
          if (content["competitor_logo_name"] == "others") {
            content["competitor_logo_name_text"] =
              this.state.competitor_logo_name_text
          }
        }
        if (content["reject_reason"] == "banned_organization") {
          content["banned_organization_name"] =
            this.state.banned_organization_name
        }
      } else {
        content["target_category"] = this.state.target_category
      }

      this._handleActivateDeactivateContent(content, this.state.contentAction)
    } catch (error) {
      toastr.error(`Content ${this.state.contentAction} Failed`)
      this.setState({ reject_reason: "", target_category: "" })
    } finally {
      this.setState({
        actionButtonLoading: false,
      })
    }
  }

  _handleActivateDeactivateContent = async (content, contentAction) => {
    try {
      let response = await activateDeactivateContent(
        content,
        contentAction,
        this.props.currentUser
      )
      let contentList = this.state.contentList
      contentList.splice(
        _.findIndex(this.state.contentList, {
          content_uuid: content.content_uuid,
        }),
        1
      )

      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status.code === 200
      ) {
        toastr.success(`Content ${contentAction}d Successfully`)
        if (this.state.isNotifWorthyContent && contentAction === "deactivate") {
          await sendNotificationContentDeactivationAlert({
            contentData: {
              id: content.content_uuid,
              deactivatedBy:
                this.props.currentUser && this.props.currentUser.email,
              title: content.content_title,
            },
            emailList: emailListForNotifWorthyDeactivation,
          })
        }

        if (
          ((this.props.currentUser.permissions &&
            this.props.currentUser.permissions.indexOf("VIEW_ACTIVITY") >= 0) ||
            !this.props.currentUser.agency) &&
          !content.qc_done &&
          (content.moderation_level === 2 || content.moderation_level === -1)
        ) {
          await qualityCheckContent(content.content_uuid)
        }

        if (this.state.selectedContent.next) {
          this.setState({
            contentList,
            showActionReasonModal: false,
            selectedContent: this.state.previewContent
              ? this.state.selectedContent.next
              : "",
            reject_reason: "",
            target_category: "",
            target_category_v2: "",
          })
        } else {
          this.props.onPageChange(this.props.currentPage, true)
        }
      } else {
        if (response && response.message.includes("401")) {
          toastr.error("User Not Verified!!")
        } else {
          throw new Error()
        }
      }
    } catch (error) {
      toastr.error(`Content ${contentAction} Failed`)
      this.setState({ reject_reason: "", target_category: "" })
    } finally {
      this.setState({
        actionButtonLoading: false,
      })
    }
  }

  _contentAction = async (content, action) => {
    try {
      if (action == "tagNonEnglish") {
        let response = await tagNonEnglish(content, action)
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success(`Content ${action} Successfull`)
        } else {
          throw Error()
        }
        return
      } else if (action === "convertToVertical") {
        let response = await convertToVertical(content, action)
        if (response && response.status && response.status === 200) {
          toastr.success(
            `Content will be processed for Horizontal to Vertical Conversion`
          )
        } else {
          throw Error()
        }
        return
      } else if (action === "target_category_v2") {
        await this.setState({
          showActionReasonModal: true,
          selectedContentRow: content,
          contentAction: action,
        })
      } else if (action === "activate") {
        this._handleActivateDeactivateContent(content, "activate")
      } else if (action === "quality_check") {
        const response = await qualityCheckContent(content.content_uuid)
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success("The content is marked as QC done successfully!")
        }
      } else if (action === "review_content") {
        const response = await reviewContent(content.content_uuid)

        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success("The content is marked as Review done successfully!")

          let contentList = this.state.contentList
          contentList.splice(
            _.findIndex(this.state.contentList, {
              content_uuid: content.content_uuid,
            }),
            1
          )

          if (this.state.selectedContent.next) {
            this.setState({
              contentList,
              selectedContent: this.state.previewContent
                ? this.state.selectedContent.next
                : "",
            })
          } else {
            this.props.onPageChange(this.props.currentPage, true)
          }
        }
      } else if (action === "taxonomyContent") {
        this.setState({
          previewTaxonomyContent: true,
          selectedContent: content,
        })
      } else {
        await this.setState({
          showActionReasonModal: true,
          selectedContentRow: content,
          contentAction: action,
        })
      }
    } catch (error) {
      toastr.error(`Content ${action} Failed`)
    }
  }

  _reProcessedModerationAction = async (content, action) => {
    try {
      let response = await reProcessedContentModerationAction(content, action)
      let contentList = this.state.contentList
      contentList.splice(
        _.findIndex(this.state.contentList, {
          content_uuid: content.content_uuid,
        }),
        1
      )

      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status.code === 200
      ) {
        toastr.success(`Content ${action} Successfull`)
        this.setState({
          contentList,
          selectedContent: this.state.previewContent
            ? this.state.selectedContent.next
            : "",
        })
      } else {
        throw Error()
      }
    } catch (error) {
      toastr.error(`Content ${action} Failed`)
    }
  }

  _generateContentStreamingButton = content => {
    switch (content.format) {
      case "IMAGE":
        return (
          <CopyToClipboard
            text={content.download_url}
            onCopy={() => {
              toastr.success("Copied sucessfully!")
            }}
          >
            <Tooltip title="Copy Image Serving URL">
              <Button
                size="small"
                icon={<AiOutlineFileImage />}
                type="dashed"
              />
            </Tooltip>
          </CopyToClipboard>
        )
      case "GIF":
        return (
          <CopyToClipboard
            text={content.download_url}
            onCopy={() => {
              toastr.success("Copied sucessfully!")
            }}
          >
            <Tooltip title="Copy Gif Serving URL">
              <Button
                size="small"
                icon={<AiOutlineFileImage />}
                type="dashed"
              />
            </Tooltip>
          </CopyToClipboard>
        )
      default:
        if (
          !content.not_processed &&
          content.m3u8_transcoding_status === "SUCCESS"
        ) {
          return (
            <CopyToClipboard
              text={content.m3u8_url}
              onCopy={() => {
                toastr.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy Video Streaming URL">
                <Button
                  size="small"
                  icon={<AiOutlineVideoCamera />}
                  type="dashed"
                />
              </Tooltip>
            </CopyToClipboard>
          )
        } else {
          return ""
        }
    }
  }

  _renderThumbnail = content => {
    switch (content.format) {
      case "IMAGE":
        return (
          <img src={content.download_url} width="100" height="100" alt="" />
        )
      case "GIF":
        return (
          <img src={content.download_url} width="100" height="100" alt="" />
        )
      default:
        return (
          <img
            src={content.thumbnail.replace("_r1_", "_v1_")}
            width="100"
            height="100"
            alt=""
          />
        )
    }
  }

  _pagination = () => {
    return (
      <div className="d-flex justify-content-end">
        <Pagination
          style={{ marginTop: "1rem" }}
          onChange={this._onPageChange}
          defaultCurrent={this.state.filters["currentPage"]}
          total={this.props.total < 10000 ? this.props.total : 10000}
          pageSize={this.state.filters["pageSize"]}
          showSizeChanger={false}
        />
      </div>
    )
  }

  _onContentViewChange = async (contentView, selectedContent) => {
    if (this.props.pageType === "task") {
      this.setState({
        previewTaxonomyContent: true,
        selectedContent: selectedContent,
      })
    } else if (contentView === "details") {
      this.props.history.push(
        `/content/manage?contentUUID=${selectedContent?.content_uuid}`
      )
    } else {
      this.props.history.push(
        `/content/browse?${QueryString.stringify(
          _.deepClean(this.props.filters)
        )}`
      )
    }
    await this.setState({ contentView, selectedContent })
    if (this.props.contentViewChange) {
      this.props.contentViewChange(contentView)
    }
  }

  _deativateReason = (content, action, isNotifWorthyContent) => {
    this.setState({
      showActionReasonModal: isNotifWorthyContent ? false : true,
      selectedContent: content,
      contentAction: action,
      isNotifWorthyContent,
      showNotificationPopup: isNotifWorthyContent ? true : false,
    })
  }

  _toggleViewModal = () => {
    this.setState({
      showActionReasonModal: false,
    })
  }

  showTrendingEntityDetails = () => {
    const [entityData] =
      this.props.trendingEntityList &&
      this.props.trendingEntityList.filter(
        entity => entity.entity_uuid === this.props.entityId
      )
    return (
      <Link
        to={`/trending/manage?showForm=true&entityId=${
          entityData && entityData.entity_uuid
        }&action=edit`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="d-flex align-items-center" role="button">
          <div
            className="text-muted mt-1"
            style={{
              marginBottom: "7px",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            {entityData && entityData.title}
          </div>
          <i
            className={`mdi mdi-circle ${
              ["STARTED", "active", "true"].includes(
                entityData && entityData.status
              )
                ? "text-success"
                : "text-danger"
            } align-middle me-1 pl-2 font-size-18 mx-2`}
          />
          <i className="bx bx-pencil pb-1 px-1" />
        </div>
      </Link>
    )
  }

  _onEditClick = (action, entity) => {
    this.setState({
      action,
      showModal: true,
      videoData: entity,
      entityId: entity.content_uuid,
    })
  }

  onMarkTrending = data => {
    this.props.dispatch(
      markTrending({
        data: data,
        entityId: this.state.entityId,
        type: "content",
      })
    )
    this.setState({ showModal: false, videoData: {} })
  }

  removeDuplicate = async content => {
    const response = await removeDuplicate(content.content_uuid)

    if (
      response &&
      response.data &&
      response.data.status &&
      response.data.status.code === 200
    ) {
      toastr.success("Removed Duplicate Content !!")
    }
  }

  render() {
    const { selectedContent = {}, contentList = [] } = this.state
    const contentMap = {}
    contentList.forEach(content => {
      if (!contentMap[content?.content_uuid]) {
        contentMap[content?.content_uuid] = content
      }
    })
    const newContentList = (contentMap && Object.values(contentMap)) || []

    return (
      <>
        {!this.state.showModal && (
          <React.Fragment>
            {this.props.pageSource &&
              this.props.pageSource === "manage-trending" &&
              this.showTrendingEntityDetails()}
            {newContentList &&
              newContentList.length > 0 &&
              this.state.contentView === "grid" && (
                <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                  <Tag color="geekblue">
                    Showing <b>{newContentList.length}</b> of&nbsp;
                    <b>{formatNumber(this.props.total)}</b> Contents
                  </Tag>
                </div>
              )}
            {newContentList &&
              newContentList.length > 0 &&
              this.state.contentView === "grid" && (
                <div className="duplicate-content-card-container">
                  {newContentList.map((content, index) => {
                    return (
                      <Row>
                        <Col md="12">
                          <Row>
                            <Col md="5">
                              {content && content.content_uuid ? (
                                <div className="content-card">
                                  {content && (
                                    <DuplicateContentCard
                                      content={content}
                                      key={index}
                                      currentUser={this.props.currentUser}
                                      editClick={content =>
                                        this._onContentViewChange(
                                          "details",
                                          content
                                        )
                                      }
                                      pageSource={this.props.pageSource}
                                      pageType={this.props.pageType}
                                      contentAction={this._contentAction}
                                      deativateReasonFunc={
                                        this._deativateReason
                                      }
                                      onEditClick={this._onEditClick}
                                    />
                                  )}
                                </div>
                              ) : null}
                            </Col>
                            <Col
                              md="1"
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <AiOutlineArrowRight
                                style={{ fontSize: "24px" }}
                              />
                            </Col>
                            <Col md="5">
                              {" "}
                              {content && content.content_uuid ? (
                                <Auxiliary
                                  loading={this.state.originalloading}
                                  error={_.get(this.props, "common.error")}
                                >
                                  {" "}
                                  <div className="content-card">
                                    {this.state.originalContent &&
                                      this.state.originalContent.length !=
                                        0 && (
                                        <DuplicateContentCard
                                          content={
                                            this.state.originalContent &&
                                            this.state.originalContent.length !=
                                              0 &&
                                            this.state.originalContent[index]
                                          }
                                          key={index}
                                          currentUser={this.props.currentUser}
                                          editClick={content =>
                                            this._onContentViewChange(
                                              "details",
                                              content
                                            )
                                          }
                                          pageSource={this.props.pageSource}
                                          pageType={this.props.pageType}
                                          contentAction={this._contentAction}
                                          deativateReasonFunc={
                                            this._deativateReason
                                          }
                                          onEditClick={this._onEditClick}
                                        />
                                      )}
                                  </div>
                                </Auxiliary>
                              ) : null}
                            </Col>
                          </Row>
                          {this.state.hostName != "cms.myjosh.in" ? (
                            <Row>
                              <Col md="10">
                                <Button
                                  onClick={() => {
                                    this.removeDuplicate(content)
                                  }}
                                >
                                  Remove Duplicate
                                </Button>
                              </Col>
                            </Row>
                          ) : (
                            <></>
                          )}
                        </Col>{" "}
                      </Row>
                    )
                  })}
                </div>
              )}
            {!this.state.previewTaxonomyContent
              ? this.state.contentView === "grid" &&
                this.props.contentView !== "details" && (
                  <div className="d-flex justify-content-end pb-3">
                    <Pagination
                      style={{ marginTop: "1rem" }}
                      onChange={this.props.onPageChange}
                      // onChange={(value) => {this.props.onPageChange(value, false)}} // To not show the preview upon clicking on new page number
                      defaultCurrent={this.props.currentPage}
                      total={
                        this.props.total < 10000 ? this.props.total : 10000
                      }
                      pageSize={this.props.pageSize}
                      showSizeChanger={false}
                    />
                  </div>
                )
              : ""}
          </React.Fragment>
        )}
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    isMobile: _.get(store, "common.isMobile"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
  }
}

export default withRouter(connect(mapStateToProps)(DuplicateContentList))
