import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import {
  requestGetActivity,
  requestGetIssues,
  requestUpsertIssue,
} from "appRedux/sagas/requests/creatorIssues"
import {
  getIssues,
  getIssuesSuccess,
  createIssue,
  createIssueSuccess,
  getActivitySuccess,
  getActivity,
  upsertIssue,
  upsertIssueSuccess,
  getCmsUser,
  getCmsUserSuccess,
} from "appRedux/slices/creatorIssues"
import { fetchError } from "appRedux/slices/common"
// import {  } from "../../slices/creatorIssues"
import { requestUpdateIssue } from "../requests/creatorIssues"
import { requestUserListByFilters } from "appRedux/sagas/requests/cmsUser"

function* handleGetIssues(params) {
  try {
    const data = yield call(requestGetIssues, params)
    yield put(getIssuesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* handleCmsUser(params) {
  try {
    const data = yield call(requestUserListByFilters, params)
    yield put(getCmsUserSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetActivity(params) {
  try {
    const data = yield call(requestGetActivity, params)
    yield put(getActivitySuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertIssue(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertIssue, params)
    yield put(createIssueSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateIssue(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdateIssue, params)
    yield put(upsertIssueSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getIssues.type, handleGetIssues)
  yield takeLatest(createIssue.type, handleUpsertIssue)
  yield takeLatest(upsertIssue.type, handleUpdateIssue)
  yield takeLatest(getActivity.type, handleGetActivity)
  yield takeLatest(getCmsUser.type, handleCmsUser)
}
