import Axios from "axios"
import toastr from "toastr"

export const requestGetKyc = async ({ payload = {} }) => {
  try {
    const currentPage = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const size = payload["size"] || 100
    let data = await Axios.post(
      `/api/pan/get?currentPage=${currentPage}&size=${size}`,
      { ...payload, currentPage }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetPendingKYCCSVData = async () => {
  try {
    let data = await Axios.get(`/api/pan/downloadKycPendingCsv`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUploadKycData = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/pan/uploadKycBulk`, payload)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
