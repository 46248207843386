import React from "react"
import Select from "react-select"

class CustomSelect extends React.Component {
  render() {
    const { isValid, errorMessage = "" } = this.props

    const customStyles = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#2684ff" : isValid ? "#ddd" : "#f46a6a",
        "&:hover": {
          borderColor: state.isFocused
            ? "#2644ff"
            : isValid
            ? "#ddd"
            : "#f46a6a",
        },
      }),
    }
    return (
      <>
        <Select styles={customStyles} {...this.props} />{" "}
        {!isValid && (
          <div
            style={{
              marginTop: "0.25rem",
              fontSize: "80%",
              color: "#f46a6a",
            }}
          >
            {errorMessage}
          </div>
        )}
      </>
    )
  }
}

export default CustomSelect
