import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Select, Tag, Tooltip } from "antd"
import { Card, Col, Row } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getBrands,
  resetState,
  upsertWhitelistedUser,
  upsertMastheadContent,
} from "appRedux/slices/sponsoredTags"
import Auxiliary from "../../util/Auxiliary"
import "./styles.css"
import CopyToClipboard from "react-copy-to-clipboard"
import toastr from "toastr"
import UpsertForm from "./upsertForm"
import { NO_PERMISSION_MESSAGE } from "constants/uiConstants"

class BrowseBrands extends ErrorBoundComponent {
  state = {
    showUserModal: false,
    showMastheadModal: false,
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getBrands())
      this.setState({ filters })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  onSubmit = formData => {
    if (this.state.action === "user") {
      this.props.dispatch(upsertWhitelistedUser(formData))
    } else this.props.dispatch(upsertMastheadContent(formData))
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {(this.state.showUserModal || this.state.showMastheadModal) && (
          <UpsertForm
            formAction={this.state.action}
            onCancel={() => {
              this.setState({
                showMastheadModal: false,
                showUserModal: false,
              })
            }}
            onSubmit={this.onSubmit}
          />
        )}
        {!(this.state.showUserModal || this.state.showMastheadModal) && (
          <React.Fragment>
            <div className="d-flex justify-content-end mb-3">
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (
                    !this.props.currentUser?.permissions?.includes(
                      "EDIT_BROWSE_BRAND"
                    )
                  ) {
                    toastr.error(NO_PERMISSION_MESSAGE)
                    return
                  }
                  this.setState({ showUserModal: true, action: "user" })
                }}
              >
                Add/Remove User
              </button>
              <button
                className="btn btn-primary mx-2"
                onClick={() => {
                  if (
                    !this.props.currentUser?.permissions?.includes(
                      "EDIT_BROWSE_BRAND"
                    )
                  ) {
                    toastr.error(NO_PERMISSION_MESSAGE)
                    return
                  }
                  this.setState({ showMastheadModal: true, action: "content" })
                }}
              >
                Add/Ignore Masthead
              </button>
            </div>

            {this.props.brandList && this.props.brandList.length > 0 && (
              <div style={{ textAlign: "center" }} className="mb-2">
                <Tag color="geekblue">
                  Showing <b>{this.props.brandList.length}</b> Tags
                </Tag>
              </div>
            )}
            <div className="brand-card-container">
              {this.props.brandList &&
                this.props.brandList.length > 0 &&
                this.props.brandList.map(brand => {
                  return (
                    <Card
                      className="shadow-sm"
                      style={{
                        width: "250px",
                      }}
                    >
                      <Row>
                        <Col xs="12" sm="3">
                          <div
                            className="py-3"
                            style={{
                              height: "5rem",
                              backgroundColor: "lightgray",
                              maxHeight: "5rem",
                            }}
                          >
                            <i
                              className="fas fa-tag fa-2x py-2 px-sm-2 text-secondary"
                              style={{ paddingLeft: "100px" }}
                            />
                          </div>
                        </Col>
                        <Col
                          xs="7"
                          sm="7"
                          className="font-size-16 text-truncate font-weight-semibold py-2 py-sm-0"
                          style={{
                            margin: "auto",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            maxHeight: "5rem",
                          }}
                        >
                          <Tooltip title={brand.name}>{brand.name}</Tooltip>
                        </Col>
                        <Col
                          xs="2"
                          sm="2"
                          className="font-size-16 text-secondary py-2 py-sm-0"
                          style={{
                            margin: "auto",
                            paddingLeft: "0px",
                            maxHeight: "5rem",
                          }}
                        >
                          <CopyToClipboard
                            text={brand.id}
                            onCopy={e => {
                              toastr.success("Copied sucessfully!")
                            }}
                          >
                            <Tooltip title="Copy ID">
                              <i className="bx bx-copy" role="button" />
                            </Tooltip>
                          </CopyToClipboard>
                        </Col>
                      </Row>
                    </Card>
                  )
                })}
            </div>
          </React.Fragment>
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "routing.location.search")
      ? QueryString.parseUrl(_.get(ownProps, "routing.location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "sponsoredTags.loading"),
    brandList: _.get(store, "sponsoredTags.brandList"),
  }
}

export default connect(mapStateToProps)(BrowseBrands)
