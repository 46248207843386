import React from "react"
import _ from "lodash"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Popover, Tooltip, Radio } from "antd"
import PropTypes from "prop-types"

const QuestionCard = props => {
  const options =
    props.question.sublevel &&
    props.question.sublevel.map(data => {
      return {
        label: data.label,
        value: data.value,
      }
    })
  let selectedClass =
    props.question.partialSelected === undefined
      ? ""
      : props.question.partialSelected === true
      ? "selectedCard"
      : "unSelectedCard"

  const imgSource =
    props.question.help && props.question.help.thumbnail
      ? props.question.help.thumbnail
      : "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/default/default.jpg"
  return (
    <div
      className={`question-card text-center contentCard ${selectedClass}`}
      style={{ width: "11rem" }}
    >
      <div className="card-img-container">
        <Popover
          trigger="hover"
          content={() => props.renderCardContent(props.question)}
          color="#D3D3D3"
          mouseEnterDelay="1"
          destroyTooltipOnHide
        >
          <div>
            <img
              className="card-img-top-custom"
              src={imgSource}
              alt="Card image caption"
            />
          </div>
        </Popover>
      </div>
      <div className="bg-transparent w-100">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ padding: "7px", flex: "auto", height: "3rem" }}
        >
          <Tooltip title={props.question.label}>
            <div className="card-text-footer">{props.question.label}</div>
          </Tooltip>
        </div>
        <div style={{ margin: "5px 0px" }}>
          <Radio.Group
            size="large"
            onChange={e => props._handleSelection(e)}
            value={props.question.value}
          >
            {options &&
              options.map(option => {
                return (
                  <Radio.Button value={option.value}>
                    <div
                      style={{
                        color: option.label === "Yes" ? "green" : "red",
                      }}
                      className="d-flex"
                    >
                      <div
                        style={{
                          marginTop: "-.2rem",
                        }}
                      >
                        {option.label === "Yes" ? (
                          <CheckOutlined
                            style={{ paddingRight: "5px", color: "green" }}
                          />
                        ) : (
                          <CloseOutlined
                            style={{ paddingRight: "5px", color: "red" }}
                          />
                        )}
                      </div>
                      <div>{option.label}</div>
                    </div>
                  </Radio.Button>
                )
              })}
          </Radio.Group>
        </div>
      </div>
    </div>
  )
}

QuestionCard.PropTypes = {
  question: PropTypes.object.isRequired,
  partialSelected: PropTypes.bool,
  _handleSelection: PropTypes.func,
  renderCardContent: PropTypes.func,
}

export default QuestionCard
