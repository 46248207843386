import React, { PureComponent } from "react"
import Plot from "react-plotly.js"

const LineBarCharts = props => {
  let { lineData = {}, barData = {}, title = "Line Bar Chart" } = props

  var trace1 = {
    x: lineData.labels,
    y: lineData.values,
    // text: lineData.values,
    type: "scatter",
    name: "Active Creator %",
    yaxis: "y2",
  }

  var trace2 = {
    x: barData.labels,
    y: barData.values,
    // text: barData.values,
    type: "bar",
    name: "Creator",
    yaxis: "y",
  }

  var data = [trace1, trace2]
  return (
    <Plot
      data={data}
      layout={{ title, yaxis2: { side: "right", overlaying: "y" } }}
    />
  )
}

export default LineBarCharts
