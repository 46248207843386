import Axios from "axios"
import toastr from "toastr"

export const requestUpsertCta = async ({ payload }) => {
  try {
    let data = await Axios.post(
      `/api/cta/upsert?type=${payload.entityType}&action=${payload.action}`,
      payload.data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetCta = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/cta/get?type=${payload.entityType}&page=${
        payload.currentPage - 1
      }&rows=10`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteCta = async ({ payload }) => {
  try {
    let data = await Axios.delete(
      `/api/cta/delete?type=${payload.entityType}&id=${payload.entityId}`
    )
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateCtaEntity = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/cta/update/entity`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
