import React from "react"
import { connect } from "react-redux"
import { CopyToClipboard } from "react-copy-to-clipboard"
import InfiniteScroll from "react-infinite-scroll-component"
import axios from "axios"
import ReactTimeAgo from "react-time-ago"
import Badge from "react-bootstrap/Badge"
import _ from "lodash"
import { Tooltip } from "antd"
import toastr from "toastr"
import QueryString from "query-string"

const statusBgColorMap = {
  reported: "primary",
  verified_open: "danger",
  waiting_for_feedback: "warning",
  closed: "success",
}

const statusLabelMap = {
  reported: "REPORTED",
  verified_open: "OPEN",
  waiting_for_feedback: "WAITING",
  closed: "CLOSED",
}

const issueSeverityBgColorMap = {
  high: "danger",
  moderate: "warning",
  low: "primary",
}

class IssueList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      issuesList: this.props.issuesList || [],
      loading: false,
      filters: this.props.filters || {},
      showFilters: false,
    }
  }

  MyItemRender = issueDetails => {
    const {
      issueId,
      title,
      summary,
      status: issue_status,
      issueSeverity,
      reportedBy,
      assignedTo,
      createdOn,
      modifiedOn,
      contentUuid,
      userUuid,
    } = issueDetails || {}

    let status = issue_status

    if (typeof issue_status === "boolean") {
      if (issue_status) {
        status = "active"
      } else {
        status = "inactive"
      }
    }

    return (
      <div
        className="card_view"
        onClick={() =>
          this.props.history.push(`/issue/details?issueId=${issueId}`)
        }
      >
        <div className="card-body">
          <div className="display_flex">
            <div className="card_title">
              <Tooltip title={title || summary || "<Issue Title>"}>
                {title || summary || "<Issue Title>"}
              </Tooltip>
            </div>

            <div className="tag_container">
              {status ? (
                <Tooltip title="Status">
                  <Badge
                    pill
                    bg={`${statusBgColorMap[status]} tag`}
                    align="right"
                  >
                    <span className="tag_label">
                      {statusLabelMap[status] || "<Issue Status>"}
                    </span>
                  </Badge>
                </Tooltip>
              ) : null}
              {issueSeverity ? (
                <Tooltip title="Issue Severity">
                  <Badge
                    pill
                    bg={`${issueSeverityBgColorMap[issueSeverity]} tag`}
                    align="right"
                  >
                    <span className="tag_label">
                      {issueSeverity.toUpperCase() || "<Issue Status>"}
                    </span>
                  </Badge>
                </Tooltip>
              ) : null}
            </div>
          </div>
          {title && summary ? (
            <h5 className="text-muted margin_bottom_10px summary">
              <Tooltip title={summary || "<Issue Summary>"}>
                {summary || "<Issue Summary>"}
              </Tooltip>
            </h5>
          ) : null}
          <h5 className="margin_bottom_5px card-text ">
            <span className="font_italic_bold">
              Details :{" "}
              <span className="text-muted" title="Issue Id">
                ({issueId})
              </span>
            </span>
          </h5>
          <div className="display_flex">
            <div className="row issue_details text-muted">
              {reportedBy ? (
                <div className="col-md-12 card-text">
                  <b>
                    Reported By: <i>{reportedBy || "<Issue Reported By>"}</i>
                  </b>
                </div>
              ) : null}
              {assignedTo ? (
                <div className="col-md-12 card-text">
                  <b>
                    Assigned To: <i>{assignedTo || "<Issue Assigned To>"}</i>
                  </b>
                </div>
              ) : null}
              {createdOn ? (
                <div className="col-md-12 card-text">
                  <b>
                    Created On:{" "}
                    <i>
                      {(
                        <ReactTimeAgo
                          date={
                            Number.isNaN(createdOn)
                              ? Date.parse(createdOn)
                              : createdOn
                          }
                          locale="en-US"
                        />
                      ) || "<Issue Created On>"}
                    </i>
                  </b>
                </div>
              ) : null}
              {modifiedOn ? (
                <div className="col-md-12 card-text">
                  <b>
                    Modified On:{" "}
                    <i>
                      {(
                        <ReactTimeAgo
                          date={
                            Number.isNaN(modifiedOn)
                              ? Date.parse(modifiedOn)
                              : modifiedOn
                          }
                          locale="en-US"
                        />
                      ) || "<Issue Modified On>"}
                    </i>
                  </b>
                </div>
              ) : null}
            </div>
            <div
              className="icon_button"
              align="right"
              onClick={e => e.stopPropagation()}
            >
              {issueDetails ? (
                <CopyToClipboard
                  text={
                    (issueDetails && JSON.stringify(issueDetails, {}, 4)) || {}
                  }
                  onCopy={e => {
                    toastr.success("Copied sucessfully!")
                  }}
                >
                  <Tooltip title="Copy Issue Details">
                    <i className="bx bx-copy bx-sm" role="button" />
                  </Tooltip>
                </CopyToClipboard>
              ) : null}
              {contentUuid ? (
                <CopyToClipboard
                  text={contentUuid}
                  onCopy={e => {
                    toastr.success("Copied sucessfully!")
                  }}
                >
                  <Tooltip title="Copy Content ID">
                    <i className="bx bx-book-content bx-sm" role="button" />
                  </Tooltip>
                </CopyToClipboard>
              ) : null}
              {userUuid ? (
                <CopyToClipboard
                  text={userUuid}
                  onCopy={e => {
                    toastr.success("Copied sucessfully!")
                  }}
                >
                  <Tooltip title="Copy User ID">
                    <i className="bx bx-user-pin bx-sm" role="button" />
                  </Tooltip>
                </CopyToClipboard>
              ) : null}
              {issueDetails ? (
                <Tooltip title="Edit Issue Details">
                  <i
                    className="bx bx-edit bx-sm"
                    role="button"
                    onClick={() =>
                      this.props.history.push(
                        `/issue/details?issueId=${issueId}`
                      )
                    }
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }

  getIssuesData = async (offset = 1, limit = 20, filter = {}) => {
    try {
      let response = await axios.post(
        `/api/issue/search?offset=${offset}&limit=${limit}`,
        filter
      )
      return response
    } catch (error) {
      return error
    }
  }

  scrollHandler = async () => {
    this.setState({ loading: true })
    const limit = 20
    const offset = (this.state.issuesList && this.state.issuesList.length) || 1
    const filters = {
      ...this.state.filters,
      limit,
      offset,
    }
    this.setState({ filters })
    try {
      const response = await this.getIssuesData(offset, limit, filters)
      const newIssuesList =
        (response &&
          response.data &&
          response.data.result &&
          response.data.result.data) ||
        []
      if (newIssuesList) {
        this.setState(prevState => ({
          issuesList: [...prevState.issuesList, ...newIssuesList],
          loading: false,
        }))
      }
    } catch (error) {
      return error
    }
  }

  render() {
    const { issuesList = [], loading = false } = this.state

    return (
      <>
        <div className="issue_list_container">
          {issuesList && issuesList.length ? (
            <InfiniteScroll
              dataLength={(issuesList && issuesList.length) || 0} //This is important field to render the next data
              next={this.scrollHandler}
              hasMore={
                (issuesList && issuesList.length < this.props.total?.value) || 0
              }
              className="list_view infinite_scroll"
              height="80vh"
            >
              {issuesList &&
                issuesList.map(element => {
                  return this.MyItemRender(element)
                })}
            </InfiniteScroll>
          ) : (
            <div className="no_data">No Data Available.</div>
          )}
        </div>
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    issuesList: _.get(store, "creatorIssues.issuesList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    total: _.get(store, "creatorIssues.total"),
  }
}

export default connect(mapStateToProps)(IssueList)
