import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestUserListByFilters,
  requestUpdateUser,
  requestCreateUser,
  requestCmsUserList,
  requestRoles,
  requestAgencyList,
  requestDeskList,
} from "appRedux/sagas/requests/cmsUser"
import {
  getCmsUserList,
  getCmsUserListSuccess,
  getUserListByFilters,
  getUserListByFiltersSuccess,
  updateUser,
  updateUserSuccess,
  createUser,
  createUserSuccess,
  getRoles,
  getRolesSuccess,
  getDesksList,
  getDesksListSuccess,
  getAgencyList,
  getAgencyListSuccess,
} from "appRedux/slices/cmsUser"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

function* handleGetUserListByFilters(params) {
  try {
    const data = yield call(requestUserListByFilters, params)
    yield put(getUserListByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetRoles(params) {
  try {
    const data = yield call(requestRoles, params)
    yield put(getRolesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetCmsUserList(params) {
  try {
    const data = yield call(requestCmsUserList)
    yield put(getCmsUserListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* fetchDesksRequest() {
  try {
    const data = yield call(requestDeskList)
    yield put(getDesksListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* fetchAgencyRequest() {
  try {
    const data = yield call(requestAgencyList)
    yield put(getAgencyListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateUser(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdateUser, params)
    yield put(updateUserSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCreateUser(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestCreateUser, params)
    yield put(createUserSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getUserListByFilters.type, handleGetUserListByFilters)
  yield takeLatest(updateUser.type, handleUpdateUser)
  yield takeLatest(createUser.type, handleCreateUser)
  yield takeLatest(getCmsUserList.type, handleGetCmsUserList)
  yield takeLatest(getRoles.type, handleGetRoles)
  yield takeLatest(getDesksList.type, fetchDesksRequest)
  yield takeLatest(getAgencyList.type, fetchAgencyRequest)
}
