import PropTypes from "prop-types"
import React, { useState, createRef } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  CardBody,
  Col,
  UncontrolledTooltip,
  Button,
  Table,
  Badge,
} from "reactstrap"
import { Tooltip, Tag } from "antd"
import { size, map } from "lodash"
import moment from "moment"
import CopyToClipboard from "react-copy-to-clipboard"
import "./style.css"
import { capitalize, formatNumber } from "../../util/common"
import ReactCardFlip from "react-card-flip"
import ReactTimeAgo from "react-time-ago"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { withRouter } from "react-router"
import CustomScrollbars from "util/CustomScrollbars"
import { NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import { sendNotificationContentDeactivationAlert } from "../../clientServices/contentService"
import EmbedContainer from "react-oembed-container"

const _renderThumbnail = content => {
  switch (content.format) {
    case "IMAGE":
      return (
        <img src={content.download_url} width="100%" height="455px" alt="" />
      )
    case "GIF":
      return (
        <img src={content.download_url} width="100%" height="380px" alt="" />
      )
    default:
      return (
        <img
          src={
            content.animated_icon_url
              ? content.animated_icon_url
              : content.thumbnail_url
              ? content.thumbnail_url
              : ""
          }
          width="100%"
          height="455px"
          alt=""
          style={{ filter: "blur(.5px)" }}
        />
      )
  }
}

const ContentCard = props => {
  const hasEditPermission =
    props.currentUser &&
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("EDIT_BROWSE_CONTENT")
  const [isOpen, setIsOpen] = useState(false)
  const [isFlipped, setIsFlipped] = useState(false)
  const [showVideo, setVideo] = useState(false)
  const [controls, setControls] = useState(props.currentUser.jotUserType !== 4)
  const [playbackRate, setPlayBackRate] = useState(
    props.pageType === "tagContent" ? "1.0" : "1.0"
  )
  const [content, setContent] = useState(props.content)
  const videoRef = createRef()
  const starStyle = {
    fontSize: "18px",
  }
  const _setPlayBack = () => {
    videoRef.current.playbackRate = playbackRate
    videoRef.current.volume = 0.4
  }
  const isTagContent = props.pageType === "task"

  const _onEdit = () => {
    props.history.push(`/content/manage?contentUUID=${content.content_uuid}`)
  }

  const _getModerationImage = content => {
    if (content.reviewed) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/review.jpg"
    } else if (content.qc_done) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/qcDone.jpg"
    } else if (content.moderation_level) {
      switch (content.moderation_level) {
        case 2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-approved.jpg"

        case 3:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-approved.jpg"

        case -1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-disabled.jpg"

        case 0:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-disabled.jpg"

        case -2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/machine-disabled.jpg"

        case 1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/unmoderated.jpg"

        default:
          return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
      }
    } else {
      return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
    }
  }

  const realStatPermissionUser =
    // props.currentUser.jotUserType < 3 ||
    // props.currentUser.jotUserType == 11 ||
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("VIEW_REAL_STATS")
  let contentTitle = !_.isEmpty(content.content_title)
    ? content.content_title
    : !_.isEmpty(content.prepared_content_title)
    ? content.prepared_content_title
    : "<No Title>"
  let bucket = "review_bucket" in content ? content.review_bucket : undefined
  const showTaxonomyContent =
    props.pageType === "taxonomyContent" || props.pageType === "task"
  const showDeActivateButton =
    content.moderation_level !== -1 && !showTaxonomyContent
  const showButtonToModerator =
    props.currentUser.jotUserType !== 4 &&
    (props.pageType === "content" ||
      props.pageType === "tagContent" ||
      props.pageType === "taxonomyContent" ||
      props.pageType === "task")
  const { customClassname = "" } = props
  const enableEdit = props.enableEdit === false ? false : true
  let today = moment(new Date())
  let notif_worthy_update_time =
    content.notif_worthy_update_time &&
    moment(new Date(content.notif_worthy_update_time))
  let isNotifWorthyContent =
    content &&
    content.notif_worthy &&
    content.notif_worthy_update_time &&
    moment.duration(today.diff(notif_worthy_update_time)).asHours() < 48
  let notifContentDeactivate =
    isNotifWorthyContent &&
    props.currentUser &&
    (![1, 2].includes(props.currentUser.userType) ||
      ![1, 2].includes(props.currentUser.jotUserType) ||
      (props.currentUser.permissions &&
        props.currentUser.permissions.includes("DEACTIVATE_NOTIFY_WORTHY")))

  let isExternalBrandContent =
    content &&
    content.user_profile &&
    (content.user_profile.user_type === "eb" ||
      content.user_profile.user_type === "ib")
  let enableDeactivateButton =
    props.currentUser.permissions &&
    (isExternalBrandContent
      ? props.currentUser.permissions.includes(
          "CONTENT_DEACTIVATE_PERMISSION"
        ) ||
        props.currentUser.permissions.includes(
          "EB_CONTENT_DEACTIVATION_PERMISSION"
        )
      : props.currentUser.permissions.includes("CONTENT_DEACTIVATE_PERMISSION"))

  const { currentUser } = props
  const isQCUser =
    (currentUser.permissions &&
      currentUser.permissions.includes("QC_TAG_CONTENT")) ||
    (currentUser.permissions &&
      currentUser.permissions.includes("INTERNAL_QC_TAG_CONTENT"))
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection={"horizontal"}>
      <Card
        className={`${customClassname} text-center shadow-lg`}
        style={{ borderRadius: "10px" }}
      >
        <div
          className="bg-primary bg-soft"
          onClick={() => {
            !enableEdit ? "" : props.editClick(content, "details")
          }}
          type={!enableEdit ? "" : "button"}
        >
          <Row>
            {props.renderSocial && content.blockquote_text && (
              <div
                style={{
                  height: "450px",
                  width: "300px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <EmbedContainer markup={content.blockquote_text}>
                  <article>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.blockquote_text,
                      }}
                    />
                  </article>
                </EmbedContainer>
              </div>
            )}
            {!showVideo && !props.renderSocial && (
              <Col className="align-self-end" style={{ width: "100%" }}>
                {_renderThumbnail(content)}
                <div
                  style={{ position: "absolute", top: "10px", left: "20px" }}
                >
                  <img
                    src={_getModerationImage(content)}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "40px",
                    width: "200px",
                  }}
                  className="d-flex justify-content-around"
                >
                  {!content.is_active && (
                    <div className="deleted-icon mx-2">
                      <Tooltip title="Deleted">
                        <i className="mdi mdi-delete-forever" />
                      </Tooltip>
                    </div>
                  )}
                  {content.duplicate_content && (
                    <div className="duplicate-icon mx-2">
                      <Tooltip title="Duplicate">
                        <i className="mdi mdi-content-duplicate" />
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <img
                    src={
                      (content &&
                        content.user_profile &&
                        content.user_profile.profile_pic) ||
                      "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
                    }
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                </div>
                {!props.hidePlayButton && (
                  <div
                    role="button"
                    id={"play" + content.content_uuid}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      setVideo(!showVideo)
                    }}
                  >
                    <i
                      className="bx bx-play"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "70px",
                        color: "white",
                      }}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={"play" + content.content_uuid}
                    >
                      Play Video
                    </UncontrolledTooltip>
                  </div>
                )}
                <div className="image-text-taxonomy"> {contentTitle}</div>
              </Col>
            )}
            {showVideo && (
              <Col>
                <video
                  src={content.preview_url}
                  ref={videoRef}
                  height="455px"
                  width="256px"
                  onCanPlay={() => _setPlayBack()}
                  controls={controls}
                  onEnded={() => {
                    setControls(true)
                  }}
                  controlsList={
                    props.currentUser.jotUserType > 3 ? "nodownload" : ""
                  }
                  // muted={props.pageType === "tagContent" ? false : true}

                  onContextMenu={e => {
                    e.preventDefault()
                    return false
                  }}
                  autoPlay
                />
              </Col>
            )}
          </Row>
        </div>
        <CardBody style={{ padding: ".5rem" }}>
          <div
            className="d-flex flex-wrap align-items-start"
            style={{
              maxHeight: "4.5rem",
            }}
          >
            <Tooltip title="User">
              <Tag color="orange" className="mt-1">
                {" "}
                {content.user_profile.user_name}
              </Tag>
            </Tooltip>
            {content.taxonomy_meta && (
              <Tooltip title="Tagged">
                <Tag color="red" className="mt-1">
                  Tagged
                </Tag>
              </Tooltip>
            )}
            <Tooltip title="Language" className="mt-1">
              <Tag color="#f50" style={{ color: "black" }}>
                {content.lang_code ? content.lang_code.toUpperCase() : ""}
              </Tag>
            </Tooltip>
            {content.moderation_level === -1 && (
              <Tooltip title="Reject Reason">
                <Tag color="#87d068" className="mt-1">
                  {content.reject_reason
                    ? capitalize(content.reject_reason)
                    : ""}
                </Tag>
              </Tooltip>
            )}

            <Tooltip
              title={
                "Created: " +
                moment(content.created_date).format("MMM DD YYYY, h:mm:ss a")
              }
            >
              <Tag color="#2db7f5" className="mt-1">
                <ReactTimeAgo date={content.created_date} locale="en-US" />
              </Tag>
            </Tooltip>
            {isQCUser && content?.taxonomy_task_info?.agency && (
              <Tooltip title="Tag Agency">
                <Tag
                  color="#ffbb76"
                  className="mt-1"
                  style={{ color: "black" }}
                >
                  {content.taxonomy_task_info.agency}
                </Tag>
              </Tooltip>
            )}
            {isQCUser && content?.taxonomy_task_info?.completed_by && (
              <Tooltip title="Tagged By">
                <Tag color="red" className="mt-1">
                  {content.taxonomy_task_info.completed_by}
                </Tag>
              </Tooltip>
            )}
          </div>
          {props.currentUser.jotUserType <= 3 && (
            <div className="d-flex justify-content-around align-items-center pt-2">
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  !enableEdit ? "" : props.editClick(content, "details")
                }}
                type={!enableEdit ? "" : "button"}
              >
                <i
                  className="bx bx-show"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {" "}
                  {formatNumber(content.view_count + content.fk_views) || 0}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  !enableEdit ? "" : props.editClick(content, "details")
                }}
                type={!enableEdit ? "" : "button"}
              >
                <i
                  className="bx bx-like"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {" "}
                  {formatNumber(content.like_count + content.fk_likes) || 0}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  !enableEdit ? "" : props.editClick(content, "details")
                }}
                type={!enableEdit ? "" : "button"}
              >
                <i
                  className="bx bx-share-alt"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {formatNumber(content.share_count + content.fk_shares) || 0}
                </span>
              </div>
              <div>
                <Tooltip title="View More">
                  <i
                    className="bx bx-play"
                    role="button"
                    style={{ fontSize: "20px" }}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      setIsFlipped(true)
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div>
            {map(
              content.permissions,
              (tag, index) =>
                index < 2 && (
                  <Link
                    to="#"
                    className="badge bg-primary font-size-11 m-1"
                    key={"_skill_" + content.id + index}
                  >
                    {tag}
                  </Link>
                )
            )}
            {size(content.permissions) > 2 && (
              <Button
                to="#"
                className="badge bg-success font-size-11 m-1"
                key={"_skill_" + content.id}
                onClick={() => setIsOpen(!isOpen)}
              >
                <span
                  title={content.permissions
                    .slice(1, content.permissions, length)
                    .map(a => (
                      <span>{a}</span>
                    ))}
                >
                  +{size(content.permissions) - 1} more
                </span>
              </Button>
            )}
          </div>
        </CardBody>
        <div className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center">
          <div className="flex-fill">
            <CopyToClipboard
              text={content.content_uuid}
              onCopy={e => {
                toastr.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy Content ID">
                <i className="bx bx-copy" role="button" />
              </Tooltip>
            </CopyToClipboard>
          </div>
          {!isTagContent && (
            <React.Fragment>
              <div className="flex-fill">
                <Tooltip title="Edit">
                  <i
                    className="bx bx-edit-alt"
                    role="button"
                    onClick={() => _onEdit()}
                  />
                </Tooltip>
              </div>
              <div className="flex-fill">
                <Tooltip title="Edit In New Window">
                  <Link
                    to={`/content/manage?contentUUID=${content.content_uuid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bx bx-link-external" role="button" />
                  </Link>
                </Tooltip>
              </div>
              <div className="flex-fill">
                <Tooltip title="Mark Trending">
                  <i
                    className="bx bx-line-chart"
                    role="button"
                    onClick={() => props.onEditClick("trending", content)}
                  />
                </Tooltip>
              </div>
              {showButtonToModerator && (
                <div className="flex-fill">
                  {showDeActivateButton ? (
                    <div className="flex-fill">
                      <Tooltip title="Deactivate">
                        <i
                          className="bx bx-block"
                          role="button"
                          disabled={true}
                          onClick={() => {
                            if (enableDeactivateButton) {
                              setContent({ ...content, moderation_level: -1 })
                              props.deativateReasonFunc(
                                content,
                                "deactivate",
                                isNotifWorthyContent
                              )
                            } else {
                              toastr.error(NO_PERMISSION_MESSAGE)
                            }
                          }}
                          style={{ color: "red" }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex-fill">
                      <Tooltip title="Activate">
                        <i
                          className="bx bx-comment-error"
                          role="button"
                          style={{ color: "green" }}
                          onClick={() => {
                            if (
                              content.taxonomy_meta?.skip_reason ||
                              content?.reject_reason === "copyright_content"
                            ) {
                              toastr.error(
                                "You can not activate this content !!"
                              )
                              return
                            }
                            setContent({ ...content, moderation_level: 1 })
                            props.contentAction(content, "activate")
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          )}
          {isTagContent && (
            <React.Fragment>
              <div className="flex-fill">
                <Tooltip title="Review">
                  <i
                    className="bx bx-search-alt"
                    role="button"
                    onClick={() =>
                      props.contentAction(content, "review_content")
                    }
                  />
                </Tooltip>
              </div>
              <div className="flex-fill">
                <Tooltip title="Add Taxonomy">
                  <i
                    className="bx bx-purchase-tag"
                    role="button"
                    onClick={() =>
                      props.contentAction(content, "taxonomyContent")
                    }
                  />
                </Tooltip>
              </div>
            </React.Fragment>
          )}
        </div>
      </Card>
      <Card
        className="text-center shadow-lg"
        style={{
          height: "616px",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        <CustomScrollbars>
          <CardBody className="px-0 py-0">
            <Row className="my-sm-2">
              <Col className="ms-2">
                <div className="avatar-xs">
                  <div className="avatar-title rounded-circle bg-light">
                    <span
                      className={
                        "avatar-title rounded-circle bg-light bg-" +
                        "secondary" +
                        " text-warning" +
                        " font-size-16" +
                        " font-weight-semibold"
                      }
                    >
                      <i className="bx bx-bar-chart" />
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="d-flex justify-content-end me-2">
                <div
                  className="avatar-xs"
                  role="button"
                  onClick={() => setIsFlipped(false)}
                >
                  <div className="avatar-title rounded-circle bg-light">
                    <span
                      className={
                        "avatar-title rounded-circle bg-light bg-" +
                        "secondary" +
                        " text-dark" +
                        " font-size-16" +
                        " font-weight-semibold"
                      }
                    >
                      <i className="fas fa-times-circle" />
                    </span>
                  </div>
                </div>
                <div></div>
              </Col>
            </Row>
            <Row>
              <div className="table-responsive">
                <Table className="mb-0 table-striped">
                  <tbody>
                    <tr className="text-start">
                      <th scope="row">Views:</th>
                      <td>
                        {formatNumber(content.view_count + content.fk_views) ||
                          0}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Downloads:</th>
                      <td>
                        {formatNumber(
                          content.download_count + content.fk_downloads
                        ) || 0}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Likes:</th>
                      <td>
                        {" "}
                        {formatNumber(content.like_count + content.fk_likes) ||
                          0}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Shares:</th>
                      <td>
                        {formatNumber(
                          content.share_count + content.fk_shares
                        ) || 0}
                      </td>
                    </tr>
                    {realStatPermissionUser && (
                      <React.Fragment>
                        <tr className="text-start">
                          <th scope="row">Real Downloads:</th>
                          <td>{formatNumber(content.download_count) || 0}</td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Real Views:</th>
                          <td>{formatNumber(content.view_count) || 0}</td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Real Likes:</th>
                          <td>{formatNumber(content.like_count) || 0}</td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Real Shares:</th>
                          <td>
                            {content.share_count ? content.share_count : 0}
                          </td>
                        </tr>
                      </React.Fragment>
                    )}

                    <tr className="text-start">
                      <th scope="row">User:</th>
                      <td className="break-word">
                        {content.user_profile && content.user_profile.user_name}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Language:</th>
                      <td>
                        {content.lang_code
                          ? content.lang_code.toUpperCase()
                          : ""}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Moderation Status:</th>
                      <td className="break-word">
                        {content.moderation_status}
                      </td>
                    </tr>
                    {bucket !== undefined && (
                      <tr className="text-start">
                        <th scope="row">Review Bucket:</th>
                        <td>{bucket}</td>
                      </tr>
                    )}
                    {content.user_profile && content.user_profile.verified && (
                      <tr className="text-start">
                        <th scope="row">User Type:</th>
                        <td>Verified</td>
                      </tr>
                    )}
                    {content.video_source && (
                      <tr className="text-start">
                        <th scope="row">Video Source:</th>
                        <td>{content.video_source}</td>
                      </tr>
                    )}
                    {content.notif_worthy && (
                      <tr className="text-start">
                        <th scope="row">Notification Worthy:</th>
                        <td>{content.notif_worthy}</td>
                      </tr>
                    )}
                    {content.enhancement_selected &&
                      content.enhancement_selected.length > 0 && (
                        <tr className="text-start">
                          <th scope="row">Enhancement Selected:</th>
                          <td style={{ wordBreak: "break-word" }}>
                            {content.enhancement_selected.map(enhancement => (
                              <span>{enhancement}</span>
                            ))}
                          </td>
                        </tr>
                      )}
                    {content.tags_array && content.tags_array.length > 0 && (
                      <tr className="text-start">
                        <th scope="row">Hashtags:</th>
                        <td className="break-word">
                          {content.tags_array.map(tag => (
                            <Badge
                              pill
                              className="badge-soft-primary m-1"
                              style={{ fontSize: "12px" }}
                            >
                              #{tag}
                            </Badge>
                          ))}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Row>
          </CardBody>
        </CustomScrollbars>
      </Card>
    </ReactCardFlip>
  )
}

ContentCard.propTypes = {
  content: PropTypes.object,
}

export default withRouter(ContentCard)
