import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ContentList from "../../components/ContentList/contentList"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Chips from "components/CommonFilter/chips"
import {
  getContentByFilter,
  getContentById,
  resetState,
} from "appRedux/slices/content"
import { getLocationData } from "appRedux/slices/location"
import Auxiliary from "../../util/Auxiliary"
import "./browseContent.style.css"
import { TARGET_CATEGORY_V2, contentFilters } from "../../constants/uiConstants"
import { getAllZones } from "appRedux/slices/zone"
import NoDataFound from "components/NoDataFound/noDataFound"

class BrowseContent extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    let view = "grid"
    if (this.props.queryParams?.view && this.props.queryParams?.content_uuid) {
      view = this.props.queryParams?.view || "grid"
    }
    this.state = {
      selectedContent: {},
      view: view || "grid",
      stateLocationList: [],
      cityLocationList: [],
      filters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        orderByField:
          this.props.queryParams && this.props.queryParams["orderByField"]
            ? this.props.queryParams["orderByField"]
            : "created_date",
        order:
          this.props.queryParams && this.props.queryParams["order"]
            ? this.props.queryParams["order"]
            : "desc",
        blockedReason:
          this.props.queryParams && this.props.queryParams["blockedReason"]
            ? this.props.queryParams["blockedReason"]
            : "AUDIO_COPYRIGHT",
        acrMetaLabel:
          this.props.queryParams && this.props.queryParams["acrMetaLabel"]
            ? this.props.queryParams["acrMetaLabel"]
            : "",
        acrMetaCreatedDateFrom:
          this.props.queryParams &&
          this.props.queryParams["acrMetaCreatedDateFrom"]
            ? this.props.queryParams["acrMetaCreatedDateFrom"]
            : null,
        acrMetaCreatedDateTo:
          this.props.queryParams &&
          this.props.queryParams["acrMetaCreatedDateTo"]
            ? this.props.queryParams["acrMetaCreatedDateTo"]
            : null,
      },
    }
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(getLocationData())
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getContentByFilter(_.deepClean(filters)))
      const filterCount = Object.keys(_.deepClean(filters)).length
      this.props.history.push(
        `/content/blocked?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
      this.setState({
        filterCount,
        filters,
      })

      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    if (
      type === "searchText" ||
      type === "order" ||
      type === "moderationLevel" ||
      type === "langCode"
    ) {
      this.setState({
        filters: newFilters,
      })
    } else {
      this.setState({
        filters: newFilters,
      })
    }
  }

  _searchContent = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/content/blocked?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.setState({ isOpen: false }, () =>
      this.props.dispatch(getContentByFilter(_.deepClean(filters)))
    )
  }

  _contentViewChange = contentView => {
    this.setState({ view: contentView })
  }

  _onPageChange = (value, previewContent) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = Number(value)
    if (previewContent === "next") {
      newFilters["currentPage"] = Number(value) + 1
      this.setState({
        view: "details",
      })
    }

    if (previewContent === "prev") {
      newFilters["currentPage"] = Number(value) - 1
      this.setState({
        view: "details",
      })
    }

    newFilters["previewContent"] = previewContent ? true : false
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/content/blocked?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(getContentByFilter(_.deepClean(this.state.filters)))
      }
    )
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: contentFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => this._closeFilters()
    )
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  render() {
    const updateContentList = [...this.props.contentList] || []
    const ContentIdList = []
    updateContentList.forEach(contentData => {
      ContentIdList.push(contentData.content_uuid)
    })

    const allZoneList = []
    this.props?.allZoneList?.map(zone => {
      if (zone && zone.zone_uuid) {
        allZoneList.push({
          ...zone,
          value: zone.zone_uuid,
          label: zone.title || zone.sub_title,
        })
      }
    })

    if (
      updateContentList &&
      updateContentList.length &&
      this.props?.queryParams?.content_uuid &&
      this.props.selectedContent &&
      !ContentIdList.includes(this.props.queryParams.content_uuid)
    ) {
      updateContentList.push(this.props.selectedContent)
    }

    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.state.view === "grid" && (
          <Chips
            showFilters={this.state.showFilters}
            showAdvancedFilters={this.state.showAdvancedFilters}
            filters={this.state.filters}
            search={this._closeFilters}
            removeFilter={this._removeFilter}
            handleFilterChange={this._handleFilterChange}
            type="blocked_content"
            toggleFilters={this._toggleFilters}
            toggleAdvancedFilters={this._toggleAdvancedFilters}
            allZoneList={allZoneList}
          />
        )}
        {updateContentList && updateContentList.length > 0 ? (
          <ContentList
            {...this.props}
            filters={this.state.filters}
            contentList={updateContentList}
            onPageChange={this._onPageChange}
            total={this.props.total}
            searchAfter={this.props.searchAfter}
            isMobile={this.props.isMobile}
            currentUser={this.props.currentUser}
            currentPage={this.state.filters["currentPage"]}
            pageType="blocked_content"
            pageSize={this.state.filters["pageSize"]}
            contentViewChange={this._contentViewChange}
          />
        ) : (
          <NoDataFound message="No data found for provided filters" />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    contentList: _.get(store, "content.content"),
    selectedContent: _.get(store, "content.selectedContent"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "content.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "content.loading"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(BrowseContent)
