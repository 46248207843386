import React from "react"
import { Card, CardBody, Badge, CardTitle, CardText } from "reactstrap"
import ReactTimeAgo from "react-time-ago"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { LANGUAGES } from "../../constants/uiConstants"
import { formatNumber } from "../../util/common"

let currentDate = Date.now()

class HashtagCard extends React.Component {
  state = { isPlaying: false }
  audioRef = React.createRef()

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  _playPreview() {
    if (!this.state.isPlaying) {
      this.setState({ isPlaying: true })
      this.audioRef.play()
    } else {
      this.setState({ isPlaying: false })
      this.audioRef.pause()
    }
  }

  render() {
    const { data, audioDetails = [], customClassname = "" } = this.props
    const [audio] = audioDetails || []

    return (
      <React.Fragment>
        <Card
          className={`${customClassname} text-center shadow-lg`}
          onClick={() =>
            this.props.pageSource &&
            this.props.pageSource === "manageCollection" &&
            this.props.onEditClick(data)
          }
          style={{
            minHeight: "200px",
            borderRadius: "10px",
          }}
        >
          <div
            className="py-3"
            style={{
              height: "10rem",
              cursor: "pointer",
              backgroundColor: "lightgray",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <i className="fas fa-hashtag fa-7x py-4 text-secondary" />
          </div>

          <CardBody>
            <CardTitle className="mt-0 d-flex mx-1" style={{ height: "20px" }}>
              <Tooltip title={data.name}>
                <div className="text-truncate">
                  {data.name || data.collectionId || data.id}
                </div>
              </Tooltip>
              <div
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <CopyToClipboard
                  text={data.name}
                  onCopy={e => toastr.success("Copied Successfully")}
                >
                  <Tooltip title="Copy Hashtag">
                    <div
                      role="button"
                      onClick={() => {}}
                      id={"copy" + data.name}
                    >
                      <i className="bx bx-copy fa-1x px-2" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <Tooltip title="Click to see all the contents having this tag">
                <div
                  role="button"
                  className="px-2"
                  onClick={e =>
                    this.props.history.push(
                      `/content/browse?searchType=tag&searchText=${data.name}`
                    )
                  }
                >
                  <i className="bx bx-link-external" />
                </div>
              </Tooltip>
              {this.props.pageSource &&
                this.props.pageSource === "manageCollection" && (
                  <div
                    role="button"
                    className="px-2"
                    onClick={() => this.props.onEditClick(data)}
                  >
                    <i className="bx bx-pencil" />
                  </div>
                )}
            </CardTitle>
            <CardText className="text-start" style={{ height: "40px" }}>
              {data.name_trans && (
                <div className={`d-flex ms-1 my-1`}>
                  <Tooltip title={data.name_trans}>
                    <div
                      className="font-size-15 text-truncate badge badge-soft-primary"
                      key={data.name_trans}
                    >
                      {data.name_trans}
                    </div>
                  </Tooltip>
                </div>
              )}
              {data.agg_modified_at && (
                <div className={`ms-1 my-1 d-flex `}>
                  <Tooltip title={data.agg_modified_at}>
                    <div
                      className="font-size-15 badge badge-soft-success"
                      role="button"
                      key={"modified_date" + data.agg_modified_at}
                    >
                      <ReactTimeAgo
                        date={
                          Number.isNaN(data.agg_modified_at)
                            ? Date.parse(data.agg_modified_at)
                            : data.agg_modified_at
                        }
                        locale="en-US"
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
            </CardText>
            <CardText style={{ height: "40px" }}>
              <div className="d-flex justify-content-around align-items-center pt-2">
                <div className="d-flex flex-column align-items-center">
                  <i className="bx bx-show" style={{ fontSize: "16px" }} />
                  <span>
                    {" "}
                    {formatNumber(data.view_count + data.fk_views) || 0}
                  </span>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <i className="bx bx-like" style={{ fontSize: "16px" }} />
                  <span>
                    {" "}
                    {formatNumber(data.like_count + data.fk_likes) || 0}
                  </span>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <i className="bx bx-share-alt" style={{ fontSize: "16px" }} />
                  <span>
                    {formatNumber(data.share_count + data.fk_shares) || 0}
                  </span>
                </div>
              </div>
            </CardText>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default HashtagCard
