import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getCurrentUser,
  getHostName,
  setHostName,
} from "appRedux/slices/currentUser"
import _Get from "lodash/get"
import Spinner from "components/Spinner/Spinner"
import { Switch, BrowserRouter as Router } from "react-router-dom"

// Import Routes all
import {
  authProtectedRoutes,
  publicRoutes,
  authProtectedRoutesModeration,
} from "./routes/AppRoutes"
import { Redirect } from "react-router-dom"

// Import all middleware
import Authmiddleware from "./routes/AuthMiddleware"

import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
TimeAgo.addDefaultLocale(en)

import "./util/lodash_mixins"
// Import scss
import "./assets/scss/theme.scss"

const App = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCurrentUser())
    dispatch(setHostName(window.location.hostname))
  }, [dispatch])

  const { loading, currentUser, dataFetched, hostName } = useSelector(
    state => state.currentUser
  )

  const getProtectedRoutes = routes => {
    let protectedRoutes = []
    routes.sort((menu, next_menu) => {
      if (menu.title < next_menu.title) {
        return -1
      }
      if (menu.title > next_menu.title) {
        return 1
      }
      return 0
    })
    routes.map(parentRoute =>
      parentRoute.childRoutes.map((route, idx) =>
        protectedRoutes.push(
          <Authmiddleware
            path={route.path}
            component={route.component}
            route={route}
            currentUser={currentUser}
            key={idx}
            parentRoute={parentRoute}
            isAuthProtected={true}
            exact
          />
        )
      )
    )
    return protectedRoutes.filter(
      comp => comp.props.route.renderInRoutes !== false
    )
  }

  const routesToRender =
    hostName?.includes("localhost") ||
    hostName?.includes("jot.myjosh.in") ||
    hostName?.includes("jot-gke.myjosh.in")
      ? authProtectedRoutes
      : authProtectedRoutesModeration

  if (!loading && dataFetched) {
    if (currentUser && currentUser.data) {
      if (
        currentUser?.data.permissions?.includes(
          "TAG_CONTENT",
          "QC_TAG_CONTENT",
          "INTERNAL_QC_TAG_CONTENT"
        )
      ) {
        return (
          <Router>
            <Switch>
              {getProtectedRoutes(routesToRender).map(route => route)}
              <Redirect to="/tasks/content/tag" />
            </Switch>
          </Router>
        )
      }
      return (
        <Router>
          <Switch>
            {getProtectedRoutes(routesToRender).map(route => route)}
            <Redirect to="/user/verify" />
          </Switch>
        </Router>
      )
    } else {
      return (
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                component={route.component}
                key={idx}
                currentUser={currentUser}
                route={route}
                isAuthProtected={false}
                exact
              />
            ))}
            <Redirect to="/login" />
          </Switch>
        </Router>
      )
    }
  }
  return <Spinner />
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
