import React, { useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import moment from "moment"
import dayjs from "dayjs"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import { LANGUAGES, CATEGORIES } from "../../constants/uiConstants"
import "react-datepicker/dist/react-datepicker.css"

const { Option } = Select

const AudioDrawer = props => {
  const [isNameField, setIsNameField] = useState(false)
  const [orderBy, setOrderBy] = useState("created_date")
  const [searchTitle, setSearchTitle] = useState("")

  const handleNameFilter = (value, field) => {
    let search = field === "title" ? value : ""
    if (
      (!_.isEmpty(search) && field === "title") ||
      value === "artist_name" ||
      value === "album_name" ||
      value === "label_name"
    ) {
      setIsNameField(true)
      setOrderBy("na")
    } else if (_.isEmpty(search) && field === "title") {
      setIsNameField(false)
      setOrderBy("created_date")
    } else {
      setIsNameField(false)
      setOrderBy("created_date")
    }
  }

  const handleOrderBy = value => {
    setIsNameField(false)
    setOrderBy(value)
  }

  const handleTitle = value => {
    setSearchTitle(value)
    // !_.isEmpty(value) ? setIsNameField(true) : setIsNameField(false)
    // setOrderBy("na")
  }

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Audio Id</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["audioId"]}
                  placeholder={"Audio Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "audioId")
                  }
                  allowClear={true}
                  name="audioId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Clustered Audio ID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["clusteredAudioId"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(
                      e.target.value.trim(),
                      "clusteredAudioId"
                    )
                  }
                  allowClear={true}
                  name="clusteredAudioId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Title</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["title"]}
                  placeholder={"Title"}
                  onChange={e => {
                    // handleTitle(e.target.value.trim())
                    //handleNameFilter(e.target.value.trim(), "title")
                    props.handleFilterChange(e.target.value.trim(), "title")
                  }}
                  allowClear={true}
                  name="title"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Language</h5>
          </Divider>
          <Select
            mode="multiple"
            showSearch
            allowClear={true}
            placeholder="Language"
            defaultValue={props.filters["languages"]}
            onChange={value => {
              props.handleFilterChange(value, "languages")
            }}
            style={{ width: "100%" }}
          >
            <Option value="">All</Option>
            {LANGUAGES.map(lang => (
              <Option value={lang.value}>{lang.label}</Option>
            ))}
          </Select>
        </Col>
        <Col lg="4" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Artist</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              placeholder="Search Type Artist"
              style={{ width: "50%" }}
              defaultValue={props.filters["searchTypeArtist"]}
              onChange={value => {
                props.handleFilterChange(value, "searchTypeArtist")
                //handleNameFilter(value)
              }}
            >
              <Option value="artist_id">Artist ID</Option>
              <Option value="artist_name">Artist Name</Option>
            </Select>
            <Input
              defaultValue={props.filters["artist"]}
              placeholder={"Artist Name/ ID"}
              onChange={e =>
                props.handleFilterChange(e.target.value.trim(), "artist")
              }
              allowClear={true}
              name="artist"
              style={{ width: "50%" }}
            />
          </Input.Group>
        </Col>
        <Col lg="4" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Label</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              placeholder="Search Type"
              style={{ width: "50%" }}
              defaultValue={props.filters["searchTypeLabel"]}
              onChange={value => {
                // handleNameFilter(value)
                props.handleFilterChange(value, "searchTypeLabel")
              }}
            >
              <Option value="label_id">Label ID</Option>
              <Option value="label_name">Label Name</Option>
            </Select>
            <Input
              defaultValue={props.filters["label"]}
              placeholder={"Label Name/ ID"}
              onChange={e =>
                props.handleFilterChange(e.target.value.trim(), "label")
              }
              allowClear={true}
              name="label"
              style={{ width: "50%" }}
            />
          </Input.Group>
        </Col>
        <Col lg="4" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Uploaded By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["uploadedBy"]}
                  placeholder={"Uploaded By"}
                  onChange={e =>
                    props.handleFilterChange(
                      e.target.value.trim(),
                      "uploadedBy"
                    )
                  }
                  allowClear={true}
                  name="uploadedBy"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="4" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Album</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              placeholder="Search Type"
              style={{ width: "50%" }}
              defaultValue={props.filters["searchTypeAlbum"]}
              onChange={value => {
                //handleNameFilter(value)
                props.handleFilterChange(value, "searchTypeAlbum")
              }}
            >
              <Option value="album_id">Album ID</Option>
              <Option value="album_name">Album Name</Option>
            </Select>
            <Input
              defaultValue={props.filters["album"]}
              placeholder={"Album Name/ ID"}
              onChange={e =>
                props.handleFilterChange(e.target.value.trim(), "album")
              }
              allowClear={true}
              name="album"
              style={{ width: "50%" }}
            />
          </Input.Group>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Categories</h5>
          </Divider>
          <Select
            mode="multiple"
            allowClear={true}
            placeholder="Category"
            defaultValue={props.filters["categories"]}
            onChange={value => {
              props.handleFilterChange(value, "categories")
            }}
            style={{ width: "100%" }}
          >
            {CATEGORIES.map(cat => (
              <Option value={cat.value}>{cat.label}</Option>
            ))}
          </Select>
        </Col>
        <Col
          lg={props.filters.orderByField !== "na" ? "4" : "2"}
          md={props.filters.orderByField !== "na" ? "8" : "4"}
        >
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Order By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              allowClear={true}
              defaultValue={props.filters["orderByField"]}
              //value= {isNameField ? "na" :  orderBy}
              placeholder="Order By"
              onChange={value => {
                //handleOrderBy(value)
                props.handleFilterChange(value, "orderByField")
              }}
              style={{ width: "50%" }}
            >
              <Option value="created_date">Created Date</Option>
              <Option value="modified_date">Modified On</Option>
              <Option value="na">N/A</Option>
            </Select>
            {/* {(!isNameField || orderBy !== "na") && */}
            {props.filters.orderByField !== "na" && (
              <Select
                allowClear={true}
                defaultValue={props.filters["order"]}
                placeholder="Order"
                onChange={value => {
                  props.handleFilterChange(value, "order")
                }}
                style={{ width: "50%" }}
              >
                <Option value="asc">Ascending</Option>
                <Option value="desc">Descending</Option>
              </Select>
            )}
          </Input.Group>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Sponsored</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["sponsored"]}
            style={{ width: "100%" }}
            disabled={props.currentUser.jotUserType > 2}
            onChange={value => props.handleFilterChange(value, "sponsored")}
          >
            <Option value="Y">Ads</Option>
            <Option value="N">Non Ads</Option>
          </Select>
        </Col>
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              ACR Meta Created Date
            </h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <DatePicker
                  defaultValue={
                    props.filters["acrMetaCreatedDateFrom"]
                      ? dayjs.unix(
                          props.filters["acrMetaCreatedDateFrom"] / 1000,
                          "YYYY-MM-DD"
                        )
                      : null
                  }
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  placeholder="From Date"
                  onChange={(date, dateString) => {
                    props.handleFilterChange(
                      !_.isEmpty(dateString)
                        ? moment(dateString).format("x")
                        : null,
                      "acrMetaCreatedDateFrom"
                    )
                  }}
                  style={{ width: "100%" }}
                />
                <DatePicker
                  defaultValue={
                    props.filters["acrMetaCreatedDateTo"]
                      ? dayjs.unix(
                          props.filters["acrMetaCreatedDateTo"] / 1000,
                          "YYYY-MM-DD"
                        )
                      : null
                  }
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  placeholder="To Date"
                  onChange={(date, dateString) => {
                    props.handleFilterChange(
                      !_.isEmpty(dateString)
                        ? moment(dateString).format("x")
                        : null,
                      "acrMetaCreatedDateTo"
                    )
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="2" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">ACR Meta Label</h5>
          </Divider>
          <Input.Group compact={true}>
            <Input
              defaultValue={props.filters["acrMetaLabel"]}
              placeholder={"Label Name"}
              onChange={e =>
                props.handleFilterChange(e.target.value.trim(), "acrMetaLabel")
              }
              allowClear={true}
              name="acrMetaLabel"
            />
          </Input.Group>
        </Col>
        <Col lg="2" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Blocked Reason</h5>
          </Divider>
          <Input.Group compact={true}>
            <Input
              defaultValue={props.filters["blockedReason"]}
              placeholder={"Blocked Reason"}
              onChange={e =>
                props.handleFilterChange(e.target.value.trim(), "blockedReason")
              }
              allowClear={true}
              name="blockedReason"
            />
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

AudioDrawer.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(AudioDrawer)
