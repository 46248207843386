import { createSlice } from "@reduxjs/toolkit"

const imageSlice = createSlice({
  name: "image",
  initialState: {
    loading: false,
    loadingActivity: false,
    imageList: [],
    refetchData: false,
    total: 0,
    issuedDetails: 0,
  },

  reducers: {
    getImages(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getImagesSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        imageList: payload?.result?.data || [],
        total: payload?.result?.total?.value
          ? payload?.result?.total?.value
          : payload?.result?.total || 1,
      }
    },
    createImage(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createImageSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: true,
        createStatus: payload.status,
      }
    },
    upsertImage(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertImageSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
      }
    },
    getActivity(state) {
      return {
        ...state,
        loadingActivity: true,
      }
    },
    getActivitySuccess(state, { payload }) {
      return {
        ...state,
        loadingActivity: false,
        activityList: payload.result.data || [],
        totalActivity: payload.result.count || 0,
      }
    },
    deleteImage(state) {
      return {
        ...state,
        loading: true,
      }
    },
    deleteImageSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
      }
    },
    resetState() {
      return {
        loading: false,
        loadingActivity: false,
        imageList: [],
        refetchData: false,
        total: 0,
        openIssues: 0,
      }
    },
  },
})

export const {
  createEvent,
  createEventSuccess,
  getImages,
  getImagesSuccess,
  getActivity,
  upsertImage,
  upsertImageSuccess,
  getActivitySuccess,
  deleteImage,
  deleteImageSuccess,
  resetState,
} = imageSlice.actions

export default imageSlice.reducer
