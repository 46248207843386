import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
// import { LANGUAGES } from "constants/uiConstants"

const { Option } = Select

const CommunityLeaderboardDrawer = props => {
  const LANGUAGES = [
    {
      label: "Hindi",
      value: "hi",
    },
    {
      label: "English",
      value: "en",
    },
    {
      label: "Telugu",
      value: "te",
    },
    {
      label: "Bhojpuri",
      value: "bh",
    },
    {
      label: "Kannada",
      value: "kn",
    },
    {
      label: "Tamil",
      value: "ta",
    },
    {
      label: "Bangla",
      value: "bn",
    },
    {
      label: "Malayalam",
      value: "ml",
    },
    {
      label: "Marathi",
      value: "mr",
    },
    {
      label: "Punjabi",
      value: "pn",
    },
    {
      label: "Gujarati",
      value: "gu",
    },
    {
      label: "Oriya",
      value: "or",
    },
  ]
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Language</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Language"
            defaultValue={props.filters["langCode"]}
            onChange={value => {
              props.handleFilterChange(value, "langCode")
            }}
            style={{ width: "100%" }}
          >
            <Option value="">All</Option>
            {LANGUAGES.map(lang => (
              <Option value={lang.label}>{lang.label}</Option>
            ))}
          </Select>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Pod Leader</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Pod Leader"
            defaultValue={props.filters["podLeader"]}
            onChange={value => {
              props.handleFilterChange(value, "podLeader")
            }}
            style={{ width: "100%" }}
          >
            <Option value="Romitha">Romitha</Option>
            <Option value="Kavita">Kavita</Option>
            <Option value="Sachin">Sachin</Option>
            <Option value="Chirag">Chirag</Option>
            <Option value="Catherine">Catherine</Option>
            <Option value="Kavitha">Kavitha</Option>
            <Option value="Romita">Romita</Option>
            <Option value="Pooja">Pooja</Option>
            <Option value="Keshav">Keshav</Option>
          </Select>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Pod AM</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["podAM"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "podAM")
                  }
                  allowClear={true}
                  name="podAM"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Pod</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Pod"
            defaultValue={props.filters["pod"]}
            onChange={value => {
              props.handleFilterChange(value, "pod")
            }}
            style={{ width: "100%" }}
          >
            <Option value="Community">Community</Option>
            <Option value="KOL">KOL</Option>
            <Option value="Macro">Macro</Option>
            <Option value="Nano">Nano</Option>
            <Option value="OGC">OGC</Option>
            <Option value="Celebrity">Celebrity</Option>
            <Option value="Micro">Micro</Option>
          </Select>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Paid Type</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Paid Type"
            defaultValue={props.filters["paidType"]}
            onChange={value => {
              props.handleFilterChange(value, "paidType")
            }}
            style={{ width: "100%" }}
          >
            <Option value="Paid">Paid</Option>
            <Option value="Non-Paid">Non-Paid</Option>
          </Select>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Rank Segment</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Rank Segment"
            defaultValue={props.filters["rankSegment"]}
            onChange={value => {
              props.handleFilterChange(value, "rankSegment")
            }}
            style={{ width: "100%" }}
          >
            <Option value="Top 100">Top 100</Option>
            <Option value="Top 500">Top 500</Option>
            <Option value="Top 1k">Top 1k</Option>
            <Option value="Top 5k">Top 5k</Option>
            <Option value="Remaining">Remaining</Option>
          </Select>
        </Col>
        <Col lg="1" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Page Size</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["pageSize"]}
            onChange={value => {
              props.handleFilterChange(value, "pageSize")
            }}
            style={{ width: "100%" }}
          >
            <Option value={10}>10</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(CommunityLeaderboardDrawer)
