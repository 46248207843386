import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Collapse,
  Button,
  Table,
  Badge,
  Row,
  CardTitle,
  CardImg,
  CardText,
} from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import images from "assets/images"
import { isEmpty, size, map } from "lodash"
import { Tooltip } from "antd"
import { DivOverlay } from "react-leaflet"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import CustomScrollbars from "util/CustomScrollbars"
import ReactCardFlip from "react-card-flip"
import { formatNumber } from "../../util/common"
import ReactTimeAgo from "react-time-ago"
import "./assetStyle.css"

class CameraAssetCard extends React.Component {
  state = { isPlaying: false, isFlipped: false }
  audioRef = React.createRef()

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  _getAssetImage = asset => {
    const {
      effectsList = [],
      filtersList = [],
      masksList = [],
      faceUnityList = [],
    } = this.props
    const defaultImage =
      "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
    if (asset.thumbnail) {
      return asset.thumbnail
    } else if (asset.effect_id) {
      let effectObj = effectsList.filter(item => item.id == asset.effect_id)
      return effectObj && effectObj.length > 0
        ? effectObj[0]["coverUrl"]
        : defaultImage
    } else if (asset.filter_id) {
      let filterObj = filtersList.filter(item => item.id == asset.filter_id)
      return filterObj && filterObj.length > 0
        ? filterObj[0]["coverUrl"]
        : defaultImage
    } else if (asset.mask_id) {
      let maskObj = masksList.filter(item => item.id == asset.mask_id)
      return maskObj && maskObj.length > 0
        ? maskObj[0]["coverUrl"]
        : defaultImage
    } else if (asset.fuAssetIds && asset.fuAssetIds.length > 0) {
      let fuObj = faceUnityList.filter(item => item.id == asset.mask_id)
      return fuObj && fuObj.length > 0 ? fuObj[0]["coverUrl"] : defaultImage
    } else {
      return defaultImage
    }
  }

  render() {
    const { cameraAsset, audioDetails = [], customClassname = "" } = this.props
    return (
      <React.Fragment>
        <Card
          className={`${customClassname} overflow-hidden text-center shadow-lg`}
        >
          <CardBody className="px-0 py-0">
            <div>
              <CardImg
                src={this._getAssetImage(cameraAsset)}
                width="100%"
                height="192px"
              />
              <div
                className="my-1 mb-1 fw-bold"
                style={{ fontSize: "18px", wordBreak: "break-word" }}
              >
                {cameraAsset.title}
              </div>
            </div>
            {cameraAsset.hashtag && (
              <Badge
                pill
                className="badge-soft-primary m-1"
                style={{ fontSize: "14px" }}
              >
                <div className="p-1 font-size-12">#{cameraAsset.hashtag}</div>
              </Badge>
            )}
            {cameraAsset.created_date ? (
              <div className="my-1 mb-1">
                <Badge pill className="badge-soft-success m-1">
                  <div className="p-1 font-size-12">
                    <ReactTimeAgo
                      date={
                        Number.isNaN(cameraAsset.created_date)
                          ? Date.parse(cameraAsset.created_date)
                          : cameraAsset.created_date
                      }
                      locale="en-US"
                    />
                  </div>
                </Badge>
              </div>
            ) : null}
            {cameraAsset.is_active ? (
              <div className="my-1 mb-1">
                <Badge pill className="badge-soft-primary m-1">
                  <div className="p-1 font-size-12">{"Active"}</div>
                </Badge>
              </div>
            ) : (
              <div className="my-1 mb-1" style={{ marginLeft: "5px" }}>
                <Badge pill className="badge-soft-danger m-1">
                  <div className="p-1 font-size-12">{"Inactive"}</div>
                </Badge>
              </div>
            )}
          </CardBody>
          <div className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center">
            <div className="flex-fill">
              <CopyToClipboard
                text={cameraAsset.combo_id}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <CopyToClipboard
                text={cameraAsset.deeplink}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Share URL">
                  <i className="bx bx-share-alt" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <Tooltip title="Edit Camera Asset">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_MANAGE_CAMERA_ASSETS"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick("update", cameraAsset)
                  }}
                />
              </Tooltip>
            </div>
            <div className="flex-fill">
              <Tooltip
                title={`${
                  cameraAsset.is_active ? "Deactivate" : "Activate"
                } Camera Asset`}
              >
                <i
                  className={
                    cameraAsset.is_active
                      ? "bx bx-block"
                      : "bx bx-comment-error"
                  }
                  style={{ color: cameraAsset.is_active ? "red" : "green" }}
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_MANAGE_CAMERA_ASSETS"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onModerationClick(
                      cameraAsset.combo_id,
                      cameraAsset.is_active
                    )
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Card>
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    cameraAssetList: _.get(store, "cameraAsset.cameraAssetList"),
    filtersList: _.get(store, "cameraAsset.filtersList"),
    effectsList: _.get(store, "cameraAsset.effectsList"),
    masksList: _.get(store, "cameraAsset.masksList"),
    faceUnityList: _.get(store, "cameraAsset.faceUnityList"),
    total: _.get(store, "cameraAsset.total.value"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    reportList: _.get(store, "activityLog.reportList"),
    moderatorList: _.get(store, "cmsUser.userList"),
    communityUser: _.get(store, "community.communityUser"),
    communityManagers: _.get(store, "community.communityManagers"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "cameraAsset.loading"),
    unassignedManagers: _.get(store, "community.unassignedManagers"),
    managerDataLoading: _.get(store, "community.loading"),
  }
}

export default connect(mapStateToProps)(CameraAssetCard)
