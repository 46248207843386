import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestContestListByFilters,
  requestUpsertContest,
  requestGetContestDetails,
  requestUpdateCategory,
  requestAddCategory,
  requestAddSubCategory,
  requestDeleteBanner,
} from "appRedux/sagas/requests/contest"
import {
  addCategory,
  addCategorySuccess,
  addSubCategory,
  addSubCategorySuccess,
  deleteBanner,
  deleteBannerSuccess,
  getContestDetails,
  getContestDetailsSuccess,
  getContestListByFilters,
  getContestListByFiltersSuccess,
  updateCategory,
  updateCategorySuccess,
  upsertContest,
  upsertContestSuccess,
} from "appRedux/slices/contest"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

function* handleGetContestListByFilters(params) {
  try {
    const data = yield call(requestContestListByFilters, params)
    yield put(getContestListByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertContest(params) {
  try {
    toastr.info("Action in progress...", "")
    yield call(requestUpsertContest, params)
    yield put(upsertContestSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetContestDetails(params) {
  try {
    const data = yield call(requestGetContestDetails, params)
    yield put(getContestDetailsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateCategory(params) {
  try {
    toastr.info("Action in progress...", "")
    const data = yield call(requestUpdateCategory, params)
    yield put(updateCategorySuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAddCategory(params) {
  try {
    toastr.info("Action in progress...", "")
    const data = yield call(requestAddCategory, params)
    yield put(addCategorySuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAddSubCategory(params) {
  try {
    toastr.info("Action in progress...", "")
    const data = yield call(requestAddSubCategory, params)
    yield put(addSubCategorySuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteBanner(params) {
  try {
    toastr.info("Action in progress...", "")
    const data = yield call(requestDeleteBanner, params)
    yield put(deleteBannerSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getContestListByFilters.type, handleGetContestListByFilters)
  yield takeLatest(upsertContest.type, handleUpsertContest)
  yield takeLatest(getContestDetails.type, handleGetContestDetails)
  yield takeLatest(updateCategory.type, handleUpdateCategory)
  yield takeLatest(addCategory.type, handleAddCategory)
  yield takeLatest(addSubCategory.type, handleAddSubCategory)
  yield takeLatest(deleteBanner.type, handleDeleteBanner)
}
