import Axios from "axios"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

export const requestUserListByFilters = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] || 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/user/getUserByFilters?offset=${offset}&limit=${limit}`,
      { filters: payload }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetAppUserDetailsES = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/user/getAppUserByID?userUUID=${payload.userUuid}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestFuzzySearchUsers = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/user/fuzzySearchUsers?searchText=${payload.searchText}&pageSize=${
        payload.pageSize
      }&currentPage=${payload.currentPage - 1}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestManageAppUser = async ({ payload }) => {
  try {
    let url = `/api/user/${payload.action}?user_uuid=${payload.userUuid}&description=${payload.description}`
    if (payload.legally_disabled) {
      url += `&legally_disabled=${payload.legally_disabled}`
    }
    const response = await Axios.get(url)
    if (
      response &&
      response.data &&
      response.data.status &&
      response.data.status.code === 200
    ) {
      toastr.success("Action Successfull!!", "")
    } else {
      toastr.success("Action Failed!!", "")
    }
    return response
  } catch (error) {
    throw error
  }
}

export const requsetUpdateAppUser = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/appuser/update?userUuid=${payload.userUuid}`,
      payload.data
    )
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requsetUpdateAppUserType = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/appuser/update/user-type`, payload)
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestUpdateAppUserCuratedEntities = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/appuser/update/curatedEntities?userUuid=${payload.userUuid}`,
      { data: payload.data }
    )
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestTagUser = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/appuser/tagUser`, { ...payload })
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestLiveModerateUser = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/appuser/liveModerate`, { ...payload })
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestGetAppUserDetails = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/user/details?user_id=${payload.userUuid}&username=${payload.username}&phoneNumber=${payload.phoneNumber}`
    )
    toastr.success("Action Successfull!!", "")
    return data
  } catch (error) {
    throw error
  }
}

export const requestLogs = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/activity/changelog`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestCreateAppUser = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/appuser/create`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUploadUser = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/user/upload/bulk`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestBoostFollowHistory = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/appuser/boost/history`, payload)
    return response
  } catch (error) {
    throw error
  }
}
export const requestActivityBoostHistory = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/activity/boosthistory`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const requestDeltaHistory = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/appuser/delta/history`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const requestBoostFollowStatus = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/appuser/boost/status`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const requestBoostFollow = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/appuser/boost`, payload)
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestUpdateFollow = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/appuser/delta`, payload)
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const requestToggleShoppable = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/user/update-shoppable-flag`, payload)
    toastr.success("Action Successfull!!")
    return response
  } catch (error) {
    throw error
  }
}

export const requestToggleAllowJoshLive = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/user/update-allow-josh-live-flag`,
      payload
    )
    toastr.success("Action Successfull!!")
    return response
  } catch (error) {
    throw error
  }
}

export const requestUpdateUserAICaption = async ({ payload }) => {
  try {
    let response = await Axios.post(`/api/user/update-caption-details`, payload)
    toastr.success("Action Successfull!!")
    return response
  } catch (error) {
    throw error
  }
}

export const requestTasksByFilters = async ({ payload }) => {
  try {
    const filters = payload.filters
    const offset = payload?.filters["currentPage"]
      ? payload.filters["currentPage"] - 1
      : 0
    const limit = payload?.filters["pageSize"]
      ? payload.filters["pageSize"]
      : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/user/getTaskByFilters?offset=${offset}&limit=${limit}`,
      {
        filters: payload.filters,
        currentUser: payload.currentUser,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}
