import React from "react"
import { connect } from "react-redux"
import { message, Upload, Select as AntSelect } from "antd"
import { Row, Col } from "reactstrap"
import { Button } from "@mui/material"
import ValidatorForm, {
  Select,
  TextField,
  Switch,
  ChipsInput,
  DateTimePicker,
  MultiSelect,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import {
  uploadContent,
  shoppableCuratedTypes,
  getShoppableCuratedTypes,
  upsertShoppableContent,
} from "../../appRedux/slices/content"
import { CURATED_TYPES } from "constants/uiConstants"
import moment from "moment"
import _ from "lodash"
import toastr from "toastr"
import "./content.styles.css"
import {
  activateContent,
  deactivateContent,
} from "../../clientServices/contentService"

import { getUserListByFilters as getCmsUser } from "appRedux/slices/cmsUser"
import dayjs from "dayjs"
import { MdOutlineUpload } from "react-icons/md"
class UploadBulkContentForm extends ErrorBoundComponent {
  state = {
    uploadType: "qyuki",
    formData: {
      shoppableContent: [
        {
          contentList: "",
          shoppable_start_date: "",
          shoppable_end_date: "",
          shoppable_content: true,
        },
      ],
      unshoppableContent: [],
    },
  }

  componentDidMount() {
    this.props.dispatch(getCmsUser({ currentPage: 1, pageSize: "10000" }))
    // this.props.dispatch(getCmsUserList())
  }

  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }

  _onVideoRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }

  _handleShoppableChange = (field, value, index) => {
    let shoppableContent = [...this.state.formData.shoppableContent]
    if (
      (field === "shoppable_end_date" || field === "shoppable_start_date") &&
      !_.isEmpty(value)
    ) {
      shoppableContent[index] = {
        ...shoppableContent[index],
        [field]: dayjs(value).format("YYYY-MM-DDTHH:MM:ssZ"),
      }
    } else {
      shoppableContent[index] = {
        ...shoppableContent[index],
        [field]: value,
      }
    }
    this.setState({
      formData: {
        ...this.state.formData,
        shoppableContent,
      },
    })
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row gutter={16}>
          <Col sm="12" lg="6">
            <Select
              label={"Upload Type"}
              placeholderLabel="Select an upload type"
              field="uploadType"
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              defaultValue={"qyuki"}
              onChange={value => this.setState({ uploadType: value })}
              options={[
                { label: "Qyuki", value: "qyuki" },
                { label: "Ftp", value: "ftp" },
                { label: "Ftps", value: "ftps" },
                { label: "Image", value: "image" },
                { label: "Social", value: "social" },
                { label: "Zone", value: "zone" },
                { label: "Update Content Zones", value: "updateContentZone" },
                { label: "Update Image Zones", value: "updateImageZone" },
                { label: "Update Content", value: "update" },
                { label: "Cross Platform Content", value: "crossplatform" },
                { label: "Enlist Shoppable Content", value: "shoppable" },
                { label: "Remove Shoppable Content", value: "unshoppable" },
                {
                  label: "Mark Curated Types Shoppable",
                  value: "curatedTypes",
                },
                { label: "Activate Content", value: "activateContent" },
                { label: "Deactivate Content", value: "deactivateContent" },
                { label: "Reprocess title", value: "reprocesstitle" },
                { label: "Restore Content", value: "restoreContent" },
                { label: "Remove Duplicate", value: "removeDuplicate" },
              ]}
            />
          </Col>
          {this.state.uploadType === "unshoppable" && (
            <Col lg="6">
              <div>
                <ChipsInput
                  onChange={value =>
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        unshoppableContent: value,
                      },
                    })
                  }
                  label="Content IDs"
                  fixedHeight={true}
                />
              </div>
            </Col>
          )}

          {this.state.uploadType === "shoppable" &&
            this.state.formData.shoppableContent.map((faq, index) => {
              return (
                <Row className="mb-3 d-flex align-items-center" key={index}>
                  <Col lg="4">
                    <div>
                      <ChipsInput
                        field="contentList"
                        onChange={e =>
                          this._handleShoppableChange("contentList", e, index)
                        }
                        label="Content ID"
                        fixedHeight={true}
                      />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div>
                      <DateTimePicker
                        label="Start Time"
                        field="shoppable_start_date"
                        style={{ padding: "9px", width: "100%" }}
                        onChange={val =>
                          this._handleShoppableChange(
                            "shoppable_start_date",
                            val,
                            index
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div>
                      <DateTimePicker
                        label="End Time"
                        field="shoppable_end_date"
                        style={{ padding: "9px", width: "100%" }}
                        onChange={val =>
                          this._handleShoppableChange(
                            "shoppable_end_date",
                            val,
                            index
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
              )
            })}
          {this.state.uploadType === "shoppable" && (
            <div className="d-flex justify-content-center mb-2">
              <div
                className="btn btn-primary"
                onClick={() =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      shoppableContent: [
                        ...this.state.formData.shoppableContent,
                        {
                          contentList: "",
                          shoppable_start_date: "",
                          shoppable_end_date: "",
                        },
                      ],
                    },
                  })
                }
                role="button"
              >
                Add More
              </div>
            </div>
          )}
          {this.state.uploadType === "curatedTypes" && (
            <Col sm="12" lg="6">
              <div style={{ paddingBottom: "8px" }}>{"Curated Types"}</div>

              <AntSelect
                showArrow
                showSearch
                size="large"
                style={{ height: "38px" }}
                label={"Curated Types*"}
                placeholder="Curated Types"
                onChange={value => {
                  this.setState({
                    curatedTypes: value,
                  })
                }}
                allowClear={true}
                className="creator-form"
                mode="multiple"
                options={CURATED_TYPES.map(type => {
                  return { label: type.label, value: type.value }
                })}
              />
            </Col>
          )}
          {(this.state.uploadType === "ftp" ||
            this.state.uploadType === "ftps" ||
            this.state.uploadType === "crossplatform" ||
            this.state.uploadType === "image" ||
            this.state.uploadType === "zone" ||
            this.state.uploadType === "social") && (
            <Col sm="12" lg="6">
              <TextField
                label={"FTP Host"}
                placeholderLabel="Enter the FTP Host Details"
                field="host"
                defaultValue="publish-josh.coolfie.io"
                className="creator-form"
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}
          {(this.state.uploadType === "ftp" ||
            this.state.uploadType === "ftps" ||
            this.state.uploadType === "crossplatform" ||
            this.state.uploadType === "image" ||
            this.state.uploadType === "zone" ||
            this.state.uploadType === "social") && (
            <Col sm="12" lg="6">
              <TextField
                label={"FTP Username"}
                placeholderLabel="Enter the FTP User Name"
                field="username"
                defaultValue="cscontentpartner"
                className="creator-form"
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}

          {(this.state.uploadType === "ftp" ||
            this.state.uploadType === "ftps" ||
            this.state.uploadType === "crossplatform" ||
            this.state.uploadType === "image" ||
            this.state.uploadType === "zone" ||
            this.state.uploadType === "social") && (
            <Col sm="12" lg="6">
              <TextField
                label={"FTP Password"}
                placeholderLabel="Enter the FTP Password"
                field="password"
                defaultValue="5@:)K?E*udxrgRK`"
                className="creator-form"
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}
          {(this.state.uploadType === "ftp" ||
            this.state.uploadType === "ftps") && (
            <>
              <Col sm="12" lg="6" style={{ marginTop: "10px" }}>
                <Switch label={"Transcode"} field={"transcode"} />
              </Col>
              <Col sm="12" lg="6">
                {/* <TextField
                  label={"Source"}
                  placeholderLabel="Content Source"
                  field="source"
                  className="creator-form"
                /> */}
                <Select
                  label={"Source"}
                  defaultValue={""}
                  field="source"
                  className="creator-form"
                  options={[{ value: "AGENCY", label: "Agency" }]}
                  allowClear={true}
                />
              </Col>
            </>
          )}
          {this.state.uploadType === "updateContentZone" && (
            <Col sm="12" lg="6">
              <Select
                label={"Content Zone Update Type"}
                defaultValue={"any"}
                field="content_zone_update_type"
                className="creator-form"
                options={[
                  { value: "replace", label: "Replace Zones" },
                  { value: "append", label: "Append Zones" },
                ]}
                allowClear={true}
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}
          {(this.state.uploadType === "update" ||
            this.state.uploadType === "qyuki" ||
            this.state.uploadType === "ftp" ||
            this.state.uploadType === "ftps") && (
            <Col sm="12" lg="6">
              <MultiSelect
                label="Email To"
                placeholderLabel="Email To"
                field="emailTo"
                allowClear={true}
                // className="creator-form"
                options={
                  this.props.cmsUserList &&
                  this.props.cmsUserList.length > 0 &&
                  this.props.cmsUserList.map(user => {
                    return { label: user.email, value: user.email }
                  })
                }
                // onChange={val => this.setState({ additionalPocs: val })}
              />
              {/* <MultiSelect
                label={"Email To"}
                placeholderLabel="Email To"
                allowClear={true}
                field={"emailTo"}
                className="creator-form"
                options={
                  this.props.cmsUserList &&
                  this.props.cmsUserList.length > 0 &&
                  this.props.cmsUserList.map(user => {
                    return { label: user.email, value: user.email }
                  })
                }
               
              /> */}
            </Col>
          )}
          {this.state.uploadType === "update" && (
            <Col sm="12" lg="6">
              <Select
                label={"Update Hashtag Type"}
                defaultValue={"replace"}
                field="update_hashtag_type"
                className="creator-form"
                options={[
                  { value: "replace", label: "Replace Hashtag" },
                  { value: "append", label: "Append Hashtag" },
                  { value: "remove", label: "Remove Hashtag" },
                ]}
                allowClear={true}
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}
          {(this.state.uploadType === "ftp" ||
            this.state.uploadType === "ftps" ||
            this.state.uploadType === "crossplatform" ||
            this.state.uploadType === "qyuki") && (
            <Col sm="12" lg="6">
              <Select
                label={"User Type"}
                defaultValue={"any"}
                field="user_type"
                className="creator-form"
                options={[
                  { value: "g", label: "Ghost" },
                  { value: "i", label: "Internal" },
                  { value: "e", label: "External" },
                  { value: "ib", label: "Internal Brand" },
                  { value: "eb", label: "External Brand" },
                  { value: "any", label: "Any" },
                ]}
                allowClear={true}
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}

          {this.state.uploadType !== "shoppable" &&
            this.state.uploadType !== "unshoppable" &&
            this.state.uploadType !== "curatedTypes" && (
              <Col style={{ marginTop: "30px" }}>
                <Upload
                  showUploadList={true}
                  onRemove={this._onVideoRemove}
                  defaultFileList={this.state.csvFileList}
                  fileList={this.state.csvFileList}
                  customRequest={this._uploadCSV}
                >
                  <Button
                    color="info"
                    variant="contained"
                    startIcon={<MdOutlineUpload />}
                  >
                    Click to upload CSV
                  </Button>
                </Upload>
              </Col>
            )}
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            <div className="d-flex justify-content-end">
              <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                color="error"
                size="default"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (this.props.currentUser && !this.props.currentUser.otpVerified) {
      toastr.error("OTP not verified!!", "")
      return
    }

    if (
      !this.state.csvFile &&
      this.state.uploadType !== "shoppable" &&
      this.state.uploadType !== "unshoppable" &&
      this.state.uploadType !== "curatedTypes"
    ) {
      message.error("Please upload the csv")
      return
    }

    if (
      this.state.uploadType === "curatedTypes" &&
      !_.isEmpty(this.state.curatedTypes)
    ) {
      this.props.dispatch(shoppableCuratedTypes(this.state.curatedTypes))
      this.props.onCancel()
    } else if (this.state.uploadType === "shoppable") {
      const { shoppableContent = [] } = this.state.formData
      let videos = []
      for (var i = 0; i < shoppableContent.length; i++) {
        shoppableContent[i].contentList?.forEach(content => {
          let payload = {
            content_uuid: content,
            shoppable_content: true,
            shoppable_start_date: shoppableContent[i]["shoppable_start_date"],
            shoppable_end_date: shoppableContent[i]["shoppable_end_date"],
          }
          videos.push(payload)
        })
      }
      this.props.dispatch(
        upsertShoppableContent({ videos, action: "shoppable" })
      )
    } else if (this.state.uploadType === "unshoppable") {
      this.props.dispatch(
        upsertShoppableContent({
          videos: this.state.formData.unshoppableContent,
          action: "unshoppable",
        })
      )
    } else if (this.state.uploadType == "activateContent") {
      const activateformData = new FormData()
      activateformData.append("files", this.state.csvFile)
      await activateContent(activateformData)
      this.props.onCancel()
    } else if (this.state.uploadType == "deactivateContent") {
      const deactivateformData = new FormData()
      deactivateformData.append("files", this.state.csvFile)
      await deactivateContent(deactivateformData)
      this.props.onCancel()
    } else {
      formData.bulk_upload = true

      let data = new FormData()
      data.append("file", this.state.csvFile)
      data.append("details", JSON.stringify(formData))

      this.props.dispatch(uploadContent(data))
      this.props.onCancel()
    }
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "content.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    cmsUserList: _.get(store, "cmsUser.user"),
    // curatedTypes: _.get(store, "content.curatedTypes")
  }
}

export default connect(mapStateToProps)(UploadBulkContentForm)
