import React, { useState } from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Divider } from "antd"
import Axios from "axios"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import ModerationRoomCard from "./moderationRoomCard"
import {
  ROOM_DEACTIVATE_REASONS,
  banned_organizations,
} from "../../constants/uiConstants"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import toastr from "toastr"

const RoomModeration = ({ queryParams, history, dispatch, currentUser }) => {
  const queryClient = useQueryClient()
  const [filters, setFilters] = useState({
    pageSize:
      queryParams && queryParams["pageSize"] ? queryParams["pageSize"] : 12,
    currentPage:
      queryParams && queryParams["currentPage"]
        ? queryParams["currentPage"]
        : 1,
    searchType:
      queryParams && queryParams["searchType"]
        ? queryParams["searchType"]
        : "title",
    searchText:
      queryParams && queryParams["searchText"]
        ? queryParams["searchText"]
        : null,
    orderByField:
      queryParams && queryParams["orderByField"]
        ? queryParams["orderByField"]
        : "startDateTime",
    order: queryParams && queryParams["order"] ? queryParams["order"] : "desc",
    shutRoom: false,
  })

  const [showActionReasonModal, setShowActionReasonModal] = useState(false)
  const [showWarnUserReasonModal, setShowWarnUserReasonModal] = useState(false)
  const [roomData, setRoomData] = useState(null)
  const [userData, setUserData] = useState(null)
  const [shutReason, setShutReason] = useState("")
  const [warnReason, setWarnReason] = useState("")

  const fetchRooms = useQuery({
    queryKey: ["live-rooms"],
    queryFn: async () => {
      const offset = filters["currentPage"] ? filters["currentPage"] - 1 : 0
      const limit = filters["pageSize"] ? filters["pageSize"] : null
      try {
        let { data } = await Axios.post(
          `/api/joshlive/roominfo?${QueryString.stringify({
            offset,
            limit,
          })}`,
          { filters: filters }
        )
        return data
      } catch (error) {
        throw error
      }
    },
    refetchInterval: 5000,
  })

  const shutRoomMutation = useMutation({
    mutationFn: async payload => {
      let data = await Axios.post(`/api/joshlive/shutroom`, {
        data: payload,
      })
      return data
    },
    isLoading: () => {
      toastr.info("Action in progress !!")
    },
    onSuccess: () => {
      toastr.success("Action Successfull !!")
      queryClient.invalidateQueries({ queryKey: ["live-rooms"] })
    },
    onError: () => {
      toastr.error("Error shutting down the room")
    },
  })

  const warnUserMutation = useMutation({
    mutationFn: async payload => {
      let data = await Axios.post(`/api/joshlive/warnuser`, {
        data: payload,
      })
      return data
    },
    isLoading: () => {
      toastr.info("Action in progress !!")
    },
    onSuccess: () => {
      toastr.success("Action successfull !!")
      queryClient.invalidateQueries({ queryKey: ["live-rooms"] })
    },
    onError: () => {
      toastr.error("Error shutting down the room")
    },
  })

  const _onShutRoomClick = data => {
    setShowActionReasonModal(true)
    setRoomData(data)
  }

  const _onWarnUserClick = data => {
    setShowWarnUserReasonModal(true)
    setUserData(data.hostId)
    setRoomData(data.id)
  }

  const _shutRoom = () => {
    setShowActionReasonModal(false)
    shutRoomMutation.mutate({
      roomId: roomData,
      shutReason,
    })
  }

  const _warnUser = () => {
    setShowWarnUserReasonModal(false)
    warnUserMutation.mutate({
      id: roomData,
      userId: userData,
      warningReason: warnReason,
    })
  }

  const _toggleViewModal = () => {
    setShowActionReasonModal(false)
    setShowWarnUserReasonModal(false)
  }

  const _shutRoomModal = () => {
    return (
      <div style={{ padding: "10px" }}>
        <ModalHeader toggle={_toggleViewModal}>Shut Reasons</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap">
            {ROOM_DEACTIVATE_REASONS.map((reason, index) => {
              return (
                <div className="form-check form-radio-outline form-radio-primary mb-3 mx-2">
                  <input
                    type="radio"
                    id={`radio${index}`}
                    name="customRadiooutlinecolor1"
                    className="form-check-input"
                    value={reason.value}
                    onChange={e => setShutReason(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor={`radio${index}`}>
                    {reason.label}
                  </label>
                </div>
              )
            })}
          </div>
          <Divider>Banned Organization</Divider>
          <div className="d-flex flex-wrap">
            {banned_organizations.map((reason, index) => {
              return (
                <div className="form-check form-radio-outline form-radio-primary mb-3 mx-2">
                  <input
                    type="radio"
                    id={`radio${index}`}
                    name="customRadiooutlinecolor1"
                    className="form-check-input"
                    value={reason.value}
                    onChange={e =>
                      setShutReason(`banned_organization_${e.target.value}`)
                    }
                  />
                  <label className="form-check-label" htmlFor={`radio${index}`}>
                    {reason.label}
                  </label>
                </div>
              )
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <div
            onClick={() => {
              if (!currentUser?.permissions?.includes("EDIT_BROWSE_ROOM")) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              _shutRoom()
            }}
            role="button"
            className="btn btn-danger d-flex mx-1"
          >
            Shut Room
          </div>
        </ModalFooter>
      </div>
    )
  }

  const _warnUserModal = () => {
    return (
      <div style={{ padding: "10px" }}>
        <ModalHeader toggle={_toggleViewModal}>Warning Reasons</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap">
            {ROOM_DEACTIVATE_REASONS.map((reason, index) => {
              return (
                <div className="form-check form-radio-outline form-radio-primary mb-3 mx-2">
                  <input
                    type="radio"
                    id={`radio${index}`}
                    name="customRadiooutlinecolor1"
                    className="form-check-input"
                    value={reason.value}
                    onChange={e => setWarnReason(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor={`radio${index}`}>
                    {reason.label}
                  </label>
                </div>
              )
            })}
          </div>
          <Divider>Banned Organization</Divider>
          <div className="d-flex flex-wrap">
            {banned_organizations.map((reason, index) => {
              return (
                <div className="form-check form-radio-outline form-radio-primary mb-3 mx-2">
                  <input
                    type="radio"
                    id={`radio${index}`}
                    name="customRadiooutlinecolor1"
                    className="form-check-input"
                    value={reason.value}
                    onChange={e =>
                      setWarnReason(`banned_organization_${e.target.value}`)
                    }
                  />
                  <label className="form-check-label" htmlFor={`radio${index}`}>
                    {reason.label}
                  </label>
                </div>
              )
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <div
            onClick={() => {
              if (!currentUser?.permissions?.includes("EDIT_BROWSE_ROOM")) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              _warnUser()
            }}
            role="button"
            className="btn btn-danger d-flex mx-1"
          >
            Warn User
          </div>
        </ModalFooter>
      </div>
    )
  }

  return (
    <Auxiliary loading={fetchRooms.isLoading} error={fetchRooms.isError}>
      <React.Fragment>
        {/* {fetchRooms.isSuccess && fetchRooms.data.data.length > 0 && (
          <div style={{ textAlign: "center" }} className="mb-2">
            <Tag color="geekblue">
              Showing <b>{fetchRooms.data.data.length}</b> of <b>{formatNumber(fetchRooms.data.total.value)}</b>{" "}
              Rooms
            </Tag>
          </div>
        )} */}
        <div className="room-card-container">
          {fetchRooms.isSuccess &&
            fetchRooms.data.data.length > 0 &&
            fetchRooms.data.data.map(room => {
              return (
                <ModerationRoomCard
                  data={room}
                  onEditClick={data => onEditClick(data)}
                  onShutRoom={_onShutRoomClick}
                  onWarnUser={_onWarnUserClick}
                  currentUser={currentUser}
                />
              )
            })}
        </div>
        {showActionReasonModal && (
          <Modal
            centered={true}
            className="exampleModal"
            isOpen={showActionReasonModal}
          >
            {_shutRoomModal()}
          </Modal>
        )}

        {showWarnUserReasonModal && (
          <Modal
            centered={true}
            className="exampleModal"
            isOpen={showWarnUserReasonModal}
          >
            {_warnUserModal()}
          </Modal>
        )}
        {/* <div className="d-flex justify-content-end mb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={_onPageChange}
            defaultCurrent={filters.currentPage}
            total={total < 10000 ? total : 10000}
            pageSize={filters.pageSize}
            showSizeChanger={false}
          />
        </div> */}
      </React.Fragment>
    </Auxiliary>
  )
}

function mapStateToProps(store, ownProps) {
  return {
    roomList: _.get(store, "joshlive.roomsList"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "joshlive.total"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "joshlive.loading"),
  }
}

export default connect(mapStateToProps)(RoomModeration)
