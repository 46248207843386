import React from "react"
import { connect } from "react-redux"
import _, { cloneDeep } from "lodash"
import InfiniteScroll from "react-infinite-scroll-component"
import { Divider, Input } from "antd"
import { Button } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import Cards from "./cards"
import ZoneCard from "./ZoneCard"
import SelectedZoneCard from "./SelectedZoneCards"
import { setTaxonomyRoutes } from "../../appRedux/slices/taxonomy"
import "./TaxonomyDashboard.css"

class Zone extends Cards {
  constructor(props) {
    super(props)
    let showZoneList = []
    for (let i = 0; i < 20; i++) {
      if (this.props?.zoneList[i]?.zone_uuid) {
        showZoneList.push(this.props.zoneList[i])
      }
    }
    this.state = {
      labelToDisplay: "Zone",
      currentHierarchy: ["zone"],
      currentTab: "zone",
      currentLevel: 0,
      zoneData: [],
      selectedIds: [],
      selectedZones: [],
      zoneList: this.props.zoneList,
      selectedZoneCard: undefined,
      addedZones: [],
      qcApprovedZones:
        this.props.selectedDetails?.approved_zone_obj?.qcApprovedZones || [],
      qcRejectedZones:
        this.props.selectedDetails?.approved_zone_obj?.qcRejectedZones || [],
      removedList: [],
      addedList: [],
      zoneInfo: this.props.selectedContent.zone_info_v1 || [],
      showZoneList,
    }
  }

  componentDidMount() {
    const addedZones = []
    const qcRejectedZones =
      this.props.selectedDetails?.approved_zone_obj?.qcRejectedZones || []
    const qcApprovedZones =
      this.props.selectedDetails?.approved_zone_obj?.qcApprovedZones || []
    if (qcRejectedZones.length === 0 && qcApprovedZones.length === 0) {
      this.state.zoneInfo &&
        this.state.zoneInfo.forEach(zone => {
          if (zone.status.includes("added")) {
            addedZones.push(zone.zone_id)
          }
          if (zone.status.includes("removed")) {
            qcRejectedZones.push(zone.zone_id)
          }
          if (zone.status.includes("approved")) {
            qcApprovedZones.push(zone.zone_id)
          }
        })
    }
    let showZoneList = []
    for (let i = 0; i < 20; i++) {
      if (this.props?.zoneList[i]?.zone_uuid) {
        showZoneList.push(this.props.zoneList[i])
      }
    }
    this.setState({
      zoneList: this.props.zoneList,
      zoneData: [],
      showZoneList,
      addedZones,
      qcApprovedZones,
      qcRejectedZones,
    })
  }

  _uniqueArray = (array, param) => {
    let field = param || "zone_uuid"
    let unique = array.filter(
      (v, i, a) => a.findIndex(v2 => v2[field] === v[field]) === i
    )
    return unique
  }

  _handleDone = () => {
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    let selectedDetails = cloneDeep(this.props.selectedDetails)
    const indexTab = taxonomyRoutes.findIndex(item => item.key == "zone")
    taxonomyRoutes[indexTab].completed = true
    selectedDetails["approved_zone_obj"] = {
      zoneInfo: this.state.zoneInfo,
      qcApprovedZones: this.state.qcApprovedZones,
      qcRejectedZones: this.state.qcRejectedZones,
    }
    this.props.setSelectedDetails(selectedDetails)
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))

    let nextIndex = 0
    for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
      if (taxonomyRoutes[i].showInSidebar) {
        nextIndex = i
        break
      }
    }
    this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
  }

  scrollHandler = () => {
    let zoneList = this.state.zoneList || this.props.zoneList || []
    let showZoneList = this.state.showZoneList || []
    let newShowZoneList = [...showZoneList]
    for (
      let i = showZoneList.length || 0;
      i < (showZoneList.length || 0) + 20;
      i++
    ) {
      if (zoneList[i]?.zone_uuid) {
        newShowZoneList.push(zoneList[i])
      }
    }
    this.setState({ showZoneList: newShowZoneList })
  }
  _renderSortedZones = zoneData => {
    let zoneList = this.state.zoneList || this.props.zoneList || []
    let showZoneList = this.state.showZoneList || []
    const zoneIds = zoneData.map(zone => zone.zone_uuid)
    return (
      <>
        <InfiniteScroll
          dataLength={(showZoneList && showZoneList.length) || 0} //This is important field to render the next data
          next={this.scrollHandler}
          hasMore={(showZoneList && showZoneList.length < zoneList.length) || 0}
          className="zone_infinite_scroll_list_view"
          height="50vh"
          style={{ overflowX: "hidden" }}
        >
          {showZoneList.map(data => {
            return (
              <ZoneCard
                key={data.zone_uuid}
                data={data}
                _handleSelection={this._handleClick}
                renderCardContent={this.props.renderCardContent}
                zoneData={zoneIds}
                action="ADD"
              />
            )
          })}
        </InfiniteScroll>
      </>
    )
  }

  setQCTaxonomyData = (status, data) => {
    const zoneInfo = _.cloneDeep(this.state.zoneInfo)
    let qcApprovedZones = this.state.qcApprovedZones
    let qcRejectedZones = this.state.qcRejectedZones
    let removedList = this.state.removedList
    let addedList = this.state.addedList
    if (status === "YES" || status === "ADD" || status === "APPROVED") {
      if (qcRejectedZones.includes(data.zone_uuid)) {
        qcRejectedZones = qcRejectedZones.filter(id => id !== data.zone_uuid)
      }
      if (status === "ADD") {
        addedList.push(data.zone_uuid)
      }
      qcApprovedZones.push(data.zone_uuid)
      zoneInfo.forEach(zone => {
        if (zone.zone_id === data.zone_uuid) {
          ;(zone.status = "approved"), (zone.modified_on = new Date())
          zone.modified_by = this.props.currentUser.email
        }
      })
    } else if (status === "REMOVE") {
      if (qcApprovedZones.includes(data.zone_uuid)) {
        qcApprovedZones = qcApprovedZones.filter(id => id !== data.zone_uuid)
      }
      if (addedList.includes(data.zone_uuid)) {
        addedList = addedList.filter(id => id !== data.zone_uuid)
      }
      removedList.push(data.zone_uuid)
    } else if (status === "REMOVED") {
      if (qcApprovedZones.includes(data.zone_uuid)) {
        qcApprovedZones = qcApprovedZones.filter(id => id !== data.zone_uuid)
      }
      if (addedList.includes(data.zone_uuid)) {
        addedList = addedList.filter(id => id !== data.zone_uuid)
      }
      removedList.push(data.zone_uuid)

      let shouldAdd = false

      this.props?.selectedContent?.zone_info_v1?.forEach(zone => {
        if (zone.zone_id === data.zone_uuid) shouldAdd = true
      })

      if (shouldAdd) {
        qcRejectedZones.push(data.zone_uuid)
      }
    } else {
      if (qcApprovedZones.includes(data.zone_uuid)) {
        qcApprovedZones = qcApprovedZones.filter(id => id !== data.zone_uuid)
      }
      if (
        !removedList.includes(data.zone_uuid) &&
        !addedList.includes(data.zone_uuid)
      ) {
        qcRejectedZones.push(data.zone_uuid)
      }
      zoneInfo.forEach(zone => {
        if (zone.zone_id === data.zone_uuid) {
          ;(zone.status = "removed"), (zone.modified_on = new Date())
          zone.modified_by = this.props.currentUser.email
        }
      })
    }

    this.setState({
      addedList: _.uniq(addedList),
      removedList: _.uniq(removedList),
      qcApprovedZones: _.uniq(qcApprovedZones),
      qcRejectedZones: _.uniq(qcRejectedZones),
      zoneInfo,
    })
  }
  donedisable = () => {
    let disabled = false
    for (let zone of this.state.zoneInfo) {
      if (zone.status == "added") {
        let flag = true
        let disabledzone = false
        let allzoneID = this.props.zoneList.map(item => item.zone_uuid)
        if (allzoneID.includes(zone.zone_id)) {
          disabledzone = false
        } else {
          disabledzone = true
        }

        if (disabledzone) {
        } else {
          for (let appzone of this.state.qcApprovedZones) {
            if (appzone === zone.zone_id) {
              flag = false
            }
          }

          for (let rejzone of this.state.qcRejectedZones) {
            if (rejzone === zone.zone_id) {
              flag = false
            }
          }
          if (flag) {
            disabled = true
            break
          }
        }
      }
    }
    // if(this.state.addedZones.length >0){
    //   disabled=true;
    // }
    return disabled
  }
  _renderZones = () => {
    let data = this.props.zoneList
    let allTypes = []
    let allSubTypes = []
    allTypes = data.filter(zone => zone.level == 0)
    allSubTypes = data.filter(zone => zone.level == 1)

    this._handleClick = (data, status) => {
      this.setQCTaxonomyData(status, data)
    }

    let allZones = [
      ...this.state.addedZones,
      ...this.state.qcApprovedZones,
      ...this.state.qcRejectedZones,
    ]

    let zoneData = []

    this.props.zoneList.forEach(zone => {
      if (allZones.includes(zone.zone_uuid)) {
        zoneData.push(zone)
      }
    })

    return (
      <PerfectScrollbar>
        <div>
          <Divider className="mb-0">
            <h5 className="font-size-14 mb-0 text-black">Selected</h5>
          </Divider>
          <div className="d-flex justify-content-end mx-5 pb-0">
            <Button
              disabled={this.donedisable()}
              color="success"
              onClick={() => this._handleDone()}
            >
              Done
            </Button>
          </div>
          <div style={{ marginRight: "0.5rem" }}>
            <PerfectScrollbar>
              <div
                className="d-flex flex-wrap mt-0"
                style={{
                  margin: "1rem",
                  height: "12.5vh",
                }}
              >
                {zoneData.map(data => {
                  let selectedCard = ""
                  let isSelected = false

                  if (this.state.qcApprovedZones.includes(data.zone_uuid)) {
                    selectedCard = "selectedCard"
                    isSelected = "selected"
                  } else if (
                    this.state.qcRejectedZones.includes(data.zone_uuid)
                  ) {
                    selectedCard = "unSelectedCard"
                    isSelected = "removed"
                  }

                  return (
                    <SelectedZoneCard
                      data={data}
                      _handleSelection={this._handleClick}
                      renderCardContent={this.props.renderCardContent}
                      className={`d-flex question-card text-center contentCard mx-2 ${selectedCard}`}
                      action={null}
                      isSelected={isSelected}
                    />
                  )
                })}
              </div>
            </PerfectScrollbar>
          </div>
        </div>

        <React.Fragment>
          <div className="mt-1 mb-2">
            <Divider className="mb-0 pb-0">
              <h5 className="font-size-14  text-black">Zones</h5>
            </Divider>
            <div className="d-flex justify-content-end mx-5">
              <Input
                placeholder={"Search"}
                onChange={e => {
                  let modifiedZoneList =
                    this.props.zoneList.filter(
                      zone =>
                        zone.title &&
                        zone.title
                          .toLowerCase()
                          .includes(e.target.value.toLocaleLowerCase())
                    ) || []
                  let showZoneList = []
                  for (let i = 0; i < 20; i++) {
                    if (modifiedZoneList[i]?.zone_uuid) {
                      showZoneList.push(modifiedZoneList[i])
                    }
                  }
                  this.setState({ zoneList: modifiedZoneList, showZoneList })
                }}
                allowClear={true}
                name="Search"
                style={{ width: "30%" }}
              />
            </div>
            <div style={{ marginRight: "0.5rem" }}>
              <PerfectScrollbar>
                <div
                  className="d-flex flex-wrap mt-0"
                  style={{ margin: "1rem", height: "50vh" }}
                >
                  {this._renderSortedZones(zoneData)}
                </div>
              </PerfectScrollbar>
            </div>
          </div>
          <div className="mt-1">
            <Divider>
              <h5 className="font-size-14 mb-0 text-black">Types</h5>
            </Divider>
            <div style={{ marginRight: "0.5rem", minHeight: "30vh" }}>
              <PerfectScrollbar>
                <div
                  className="d-flex flex-wrap mt-0"
                  style={{ margin: "1rem", height: "28vh" }}
                >
                  {allTypes.map(data => {
                    return (
                      <SelectedZoneCard
                        key={data.zone_uuid}
                        data={data}
                        _handleSelection={this._handleClick}
                        renderCardContent={this.props.renderCardContent}
                        // className={`d-flex question-card text-center contentCard mx-2 ${selectedCard}`}
                        hideActions={true}
                        zoneData={zoneData}
                        action="ADD"
                      />
                    )
                  })}
                </div>
              </PerfectScrollbar>
            </div>
          </div>
          <div>
            <Divider>
              <h5 className="font-size-14 mb-0 text-black">Sub Types</h5>
            </Divider>
            <div style={{ marginRight: "0.5rem", minHeight: "30vh" }}>
              <PerfectScrollbar>
                <div
                  className="d-flex flex-wrap mt-0"
                  style={{ height: "28vh", margin: "1rem" }}
                >
                  {allSubTypes.map(data => {
                    return (
                      <SelectedZoneCard
                        key={data.zone_uuid}
                        data={data}
                        _handleSelection={this._handleClick}
                        renderCardContent={this.props.renderCardContent}
                        // className={`d-flex question-card text-center contentCard mx-2 ${selectedCard}`}
                        hideActions={true}
                        zoneData={zoneData}
                        action="ADD"
                      />
                    )
                  })}
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </React.Fragment>
      </PerfectScrollbar>
    )
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderZones()}
      </div>
    )
  }
}
function mapStateToProps(store) {
  const zoneList =
    store.zone?.zoneList?.filter(zone => zone.status !== "DISABLED") || []
  return {
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "content.loading"),
    currentData: _.get(store, "taxonomy.zone"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
    zoneList,
  }
}

export default connect(mapStateToProps)(Zone)
