import { createSlice } from "@reduxjs/toolkit"

const socialSlice = createSlice({
  name: "social",
  initialState: {
    loading: false,
    loadingActivity: false,
    socialList: [],
    refetchData: false,
    total: 0,
    issuedDetails: 0,
    social: {},
  },

  reducers: {
    getSocial(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getSocialSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        socialList: payload?.result?.data || [],
        total: payload?.result?.total?.value
          ? payload.result.total.value
          : payload?.result?.total || 1,
      }
    },
    createSocial(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createSocialSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: true,
        createStatus: payload.status,
      }
    },
    upsertSocial(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertSocialSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
      }
    },
    getActivity(state) {
      return {
        ...state,
        loadingActivity: true,
      }
    },
    getActivitySuccess(state, { payload }) {
      return {
        ...state,
        loadingActivity: false,
        activityList: payload.result.data || [],
        totalActivity: payload.result.count || 0,
      }
    },
    getSocialDetails(state) {
      return {
        ...state,
        loading: true,
        social: {},
      }
    },
    getSocialDetailsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        social: payload.data,
      }
    },
    resetState() {
      return {
        loading: false,
        loadingActivity: false,
        socialList: [],
        refetchData: false,
        total: 0,
        openIssues: 0,
      }
    },
  },
})

export const {
  createEvent,
  createEventSuccess,
  getSocial,
  getSocialSuccess,
  getActivity,
  upsertSocial,
  upsertSocialSuccess,
  getActivitySuccess,
  resetState,
  getSocialDetails,
  getSocialDetailsSuccess,
} = socialSlice.actions

export default socialSlice.reducer
