import React from "react"
import { contentFilters } from "constants/uiConstants"

export const TYPE_TO_FILTER_MAP = {
  HASHTAG: {
    filters: {
      pageSize: 24,
      currentPage: 1,
      name: null,
    },
  },
  collection: {
    filters: {
      pageSize: 12,
      currentPage: 1,
      title: "",
      collectionType: "CHALLENGE",
    },
  },
  entity: {
    filters: {
      pageSize: 12,
      currentPage: 1,
    },
  },
  TEMPLATES: {
    filters: {
      pageSize: 12,
      currentPage: 1,
      isProcessed: "true",
    },
  },
  MUSIC: {
    filters: {
      pageSize: 10,
      currentPage: 1,
      audioId: "",
      categories: [],
      languages: [],
      title: "",
      sponsored: "N",
    },
  },
  VIDEO: {
    filters: {
      pageSize: 10,
      currentPage: 1,
      searchText: "",
      searchType: "content_uuid",
      orderByField: "view_count",
    },
  },
  CHALLENGE: {
    filters: {
      pageSize: 12,
      currentPage: 1,
      searchText: "",
      searchType: "",
      type: "",
      status: "",
      sponsored: "N",
    },
  },
  CREATOR: {
    filters: {
      pageSize: 10,
      currentPage: 1,
      searchAfter: null,
      searchText: "",
      searchType: "user_uuid",
      creatorType: "",
      contentCreateLangs: "",
      userLangPrimary: "",
      rangeSearchType: null,
      opSearchType: null,
      rangeSearchText: null,
      rangeSearchFrom: null,
      rangeSearchTo: null,
      createdDateFrom: null,
      createdDateTo: null,
      orderByField: "posts_count",
      curatedType: null,
    },
  },

  mixed: {
    filters: {
      pageSize: 12,
      currentPage: 1,
      searchAfter: null,
      searchText: "",
      searchType: 1,
      opSearchType: null,
    },
  },

  STICKERSUBTYPE: {
    filters: {
      pageSize: 12,
      currentPage: 1,
      searchAfter: null,
      searchText: "",
      opSearchType: null,
    },
  },

  STICKERS: {
    filters: {
      pageSize: 25,
      currentPage: 1,
      searchAfter: null,
      name: "",
      mimeType: "animatedsticker",
      isActive: true,
    },
  },

  GIFT: {
    filters: {
      pageSize: 30,
      currentPage: 1,
      type: "GIFT",
      name: "",
      giftId: "",
    },
  },

  PACKAGE: {
    filters: {
      pageSize: 30,
      currentPage: 1,
      type: "PACKAGE",
      name: "",
      packageId: "",
    },
  },

  COMBINATION: {
    filters: {
      pageSize: 12,
      currentPage: 1,
    },
  },
}
