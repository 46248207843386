import React, { useState } from "react"
import { Badge, Card, CardBody, CardFooter } from "reactstrap"
import { Tooltip } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import moment from "moment"
import "./styles.css"
import toastr from "toastr"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import "toastr/build/toastr.min.css"

const SourceCard = props => {
  const { source } = props

  return (
    <React.Fragment>
      <Card className="text-center shadow-lg" style={{ borderRadius: "10px" }}>
        <CardBody>
          <Tooltip title="Display Name">
            <div className="mt-2">
              <h5 className="text-dark">{source.display_name}</h5>
            </div>
          </Tooltip>
          <Tooltip title="Data Source">
            <div className="mt-2">{source.data_source}</div>
          </Tooltip>
          <Tooltip title="Status">
            <div className="mt-2">
              {source.status === "ACTIVE" ? (
                <Badge className="badge-soft-success ms-1 my-1">
                  <div className="font-size-15">{source.status}</div>
                </Badge>
              ) : (
                <Badge className="badge-soft-danger ms-1 my-1">
                  <div className="font-size-15">{source.status}</div>
                </Badge>
              )}
            </div>
          </Tooltip>
          <Tooltip title="Count">
            <div className="mt-2">
              <Badge className="badge-soft-primary ms-1 my-1">
                <div className="font-size-15">Count: {source.count || 0}</div>
              </Badge>
            </div>
          </Tooltip>
          <Tooltip title="Created On">
            <div className="mt-2">
              <Badge className="badge-soft-info ms-1 my-1">
                <div className="font-size-15">
                  {moment(source.created_on).format("DD MMM YYYY hh:mm A")}
                </div>
              </Badge>
            </div>
          </Tooltip>
          {/* {source.modifiedOn && (
            <Tooltip title="Modified On">
              <div className="mt-2">
                <Badge className="badge-soft-info ms-1 my-1">
                  <div className="font-size-15">
                    {moment(source.modifiedOn).format("DD MMM YYYY hh:mm A")}
                  </div>
                </Badge>
              </div>
            </Tooltip>
          )} */}
        </CardBody>
        <CardFooter className="bg-transparent border-top">
          <div className="contact-links d-flex font-size-20">
            <div className="flex-fill">
              <CopyToClipboard
                text={source.source_id}
                title="Copy Source Id"
                onCopy={() => {
                  toastr.success("Copied Successfully")
                }}
              >
                <Tooltip title="Copy Source ID">
                  <div role="button">
                    <i className="bx bx-copy" />
                  </div>
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <CopyToClipboard
                text={source.query}
                title="Copy Query"
                onCopy={() => {
                  toastr.success("Copied Successfully")
                  props.viewQuery(source.query)
                }}
              >
                <Tooltip title="Copy Query">
                  <div role="button">
                    <i
                      className="mdi mdi-database-search-outline
"
                    />
                  </div>
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <CopyToClipboard title="Edit">
                <Tooltip title="Edit Source">
                  <div
                    role="button"
                    onClick={() => props.editClick("edit", source)}
                  >
                    <i className="bx bx-pencil" />
                  </div>
                </Tooltip>
              </CopyToClipboard>
            </div>
          </div>
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

export default SourceCard
