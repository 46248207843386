import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import QueryString from "query-string"
import Chips from "components/CommonFilter/chips"
import {
  getCampaign,
  campaignCreate,
  campaignUpdate,
  campaignDelete,
  upsertBrandLogo,
  resetState,
  getBrandCampaign,
} from "../../appRedux/slices/campaign"
import { brandLogoFilters } from "../../constants/uiConstants"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import { Row, Col, Button } from "react-bootstrap"
import { Pagination, Tag } from "antd"
import { Card, CardBody } from "reactstrap"
import BrandLogoCampaignCard from "./brandLogoCampaignCard"
import BrandLogoCreateForm from "./brandLogoCreateForm"
import "./styles.css"
import { bulkUploadBrandCampaign } from "clientServices/campaignService"
import { getBrands } from "appRedux/slices/sponsoredTags"

class ManageBrandLogoCampaign extends ErrorBoundComponent {
  state = {
    action: "create",
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      campaignUUID:
        this.props.queryParams && this.props.queryParams["campaignUUID"]
          ? this.props.queryParams["campaignUUID"]
          : "",
      brandUUID:
        this.props.queryParams && this.props.queryParams["brandUUID"]
          ? this.props.queryParams["brandUUID"]
          : "",
      campaignName:
        this.props.queryParams && this.props.queryParams["campaignName"]
          ? this.props.queryParams["campaignName"]
          : "",
    },
    showBulkUploadForm: false,
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.cloneDeep(this.state.filters)
      this.props.dispatch(getBrandCampaign(filters))
      // this.props.dispatch(getBrands())
    }
  }

  componentDidUpdate(prevProps) {
    let filters = _.cloneDeep(this.state.filters)
    if (
      !this.props.common.error &&
      this.props.queryParams &&
      JSON.stringify(prevProps.queryParams) !==
        JSON.stringify(this.props.queryParams)
    ) {
      this.props.dispatch(getBrandCampaign(filters))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  toggleUploadBulk = arg => {
    this.setState({ showBulkUploadForm: true })
  }

  toggleCreate = () => {
    this.setState({ showForm: true, action: "create" })
  }

  _onUpload = async () => {
    const formData = new FormData()
    formData.append("file", this.state.csvFile)
    await bulkUploadBrandCampaign(formData)
    this.setState({ showBulkUploadForm: false })
  }

  _search = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/campaign/brand-logo/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(getBrandCampaign(_.deepClean(filters))) // this.setState({
    //   showFilters: false,
    //   showAdvancedFilters: false,
    // })
  }

  _closeFilters = () => {
    this._search()
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }
  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: brandLogoFilters[filter].defaultValue,
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/campaign/brand-logo/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
      }
    )
  }

  _handleFieldChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _onSubmit = formData => {
    this.setState({ showForm: false })
    const data = {
      formData,
      action: this.state.action,
    }
    this.props.dispatch(upsertBrandLogo(data))
    // if (this.state.action === "create") {
    //   this.props.dispatch(campaignCreate(formData))
    // } else
    //   this.props.dispatch(
    //     campaignUpdate({
    //       id: this.state.selectedCampaign.campaign_uuid,
    //       data: formData,
    //     })
    //   )
    // this.props.dispatch(getCampaign(_.deepClean(this.state.filters)))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.dispatch(getBrandCampaign(_.deepClean(newFilters)))
    })
  }

  _bulkUploadForm = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="6">
              <label className="col-form-label">Upload CSV File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this.setState({
                    csvFile: e.target.files[0],
                  })
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm="12">
              <div className="d-flex justify-content-end">
                <Button
                  className="font-16 btn-block btn btn-primary"
                  variant="primary"
                  onClick={this._onUpload}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="danger"
                  size="default"
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showBulkUploadForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showForm && (
          <Row>
            <Col span={22}>
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFieldChange}
                type="brand_logo"
                toggleFilters={this._toggleFilters}
              />
            </Col>
          </Row>
        )}
        <div className="d-flex justify-content-end mb-2 mx-4">
          {!this.state.showBulkUploadForm && !this.state.showForm && (
            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary mx-1"
              onClick={this.toggleCreate}
            >
              <i className="fas fa-plus me-1" />
              Create
            </Button>
          )}
          {/* {!this.state.showForm && (
            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary mx-1"
              onClick={this.toggleUploadBulk}
            >
              <i className="fas fa-arrow-up me-1" />
              Upload
            </Button>
          )} */}
        </div>

        {this.state.showBulkUploadForm && this._bulkUploadForm()}
        {!this.state.showForm && (
          <>
            <div className="campaign-card-container">
              {this.props.campaignList?.data &&
                this.props.campaignList.data.length > 0 &&
                this.props.campaignList.data.map((campaign, index) => {
                  return (
                    <BrandLogoCampaignCard
                      key={`${campaign.campaign_uuid}+${index}`}
                      campaign={campaign}
                      onEditClick={(action, selectedCampaign) =>
                        this.setState({
                          showForm: true,
                          action,
                          selectedCampaign,
                        })
                      }
                      onDeleteClick={(selectedCampaign, action) => {
                        this.setState({ selectedCampaign, action }, () => {
                          this.props.dispatch(
                            campaignDelete(selectedCampaign.campaign_uuid)
                          )
                          this.props.dispatch(
                            getCampaign(_.deepClean(this.state.filters))
                          )
                        })
                      }}
                    />
                  )
                })}
            </div>
            {this.props.totalCampaign > 0 && (
              <div className="d-flex justify-content-end mb-3 pagination_position">
                <Pagination
                  style={{ marginTop: "1rem" }}
                  onChange={this._onPageChange}
                  defaultCurrent={this.state.filters.currentPage}
                  total={
                    this.props.totalCampaign < 10000
                      ? this.props.totalCampaign
                      : 10000
                  }
                  pageSize={this.state.filters.pageSize}
                  showSizeChanger={false}
                />
              </div>
            )}
          </>
        )}
        {this.state.showForm && (
          <BrandLogoCreateForm
            currentUser={this.props.currentUser}
            formAction={this.state.action}
            onSubmit={formData => this._onSubmit(formData)}
            campaign={this.state.selectedCampaign}
            brandList={this.props.brandList}
            onCancel={() =>
              this.setState({
                showForm: false,
                selectedCampaign: {},
                action: "",
              })
            }
          />
        )}
      </Auxiliary>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    contentList: _.get(store, "content.content"),
    total: _.get(store, "trending.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "campaign.loading"),
    trendingEntityList: _.get(store, "trending.trendingEntityList"),
    audioDetails: _.get(store, "trending.audioDetails"),
    campaignList: _.get(store, "campaign.campaign"),
    totalCampaign: _.get(store, "campaign.totalCampaign"),
    refetch: _.get(store, "campaign.refetchData"),
    brandList: _.get(store, "sponsoredTags.brandList"),
  }
}
export default connect(mapStateToProps)(withRouter(ManageBrandLogoCampaign))
