import React from "react"
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import _ from "lodash"
import "./cmsVideoAssets.styles.css"
import { Divider, Tooltip } from "antd"
import toastr from "toastr"
import { LANGUAGES } from "constants/uiConstants"
import { LoadingOutlined } from "@ant-design/icons"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Button } from "@mui/material"

class UpsertCMSVideoGreetingsForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    let captions = [
      {
        text: "Happy Diwali",
        optional: false,
        char_limit: 15,
        font_size: 100,
        font_color: "#FFFFFF",
        font_type: "OpenSans",
        background_color: "",
        border_color: "#ffffff",
        border_thickness: 0,
        x_coordinate: 50,
        y_coordinate: 20,
        is_bold: false,
        is_italic: false,
        font_url:
          "https://qa-stream.myjosh.in/stream/qa-josh-content/josh_fonts/OpenSans_ExtraBold.ttf",
        font_language: "en",
      },
      {
        text: "",
        optional: true,
        char_limit: 15,
        font_size: 50,
        font_color: "#FFFFFF",
        font_type: "OpenSans",
        background_color: "",
        border_color: "#ffffff",
        border_thickness: 0,
        x_coordinate: 50,
        y_coordinate: 70,
        is_bold: false,
        is_italic: false,
        font_url:
          "https://qa-stream.myjosh.in/stream/qa-josh-content/josh_fonts/OpenSans_ExtraBold.ttf",
        font_language: "en",
      },
    ]

    if (props.captions) {
      captions = _.cloneDeep(props.captions)
      if (captions.length === 1) {
        captions = [
          ...captions,
          {
            text: "",
            optional: true,
            char_limit: 15,
            font_size: 50,
            font_color: "#FFFFFF",
            font_type: "OpenSans",
            background_color: "",
            border_color: "#ffffff",
            border_thickness: 0,
            x_coordinate: 50,
            y_coordinate: 70,
            is_bold: false,
            is_italic: false,
            font_url:
              "https://qa-stream.myjosh.in/stream/qa-josh-content/josh_fonts/OpenSans_ExtraBold.ttf",
            font_language: "en",
          },
        ]
      }
      captions = captions.map(caption => {
        //This is required for iOS backward compatibility
        caption.border_color = "#ffffff"
        caption.border_thickness = 0

        if (caption.background_color) {
          caption.background = true
        }
        return caption
      })
    }
    this.state = {
      formData: {
        captions,
      },
      file: props.itemFile,
    }
  }

  _handleFieldChange = (field, value, index) => {
    const captions = _.cloneDeep(this.state.formData.captions)

    if (field === "font_type") {
      const caption = captions[index]
      this.props.fontFamilies[caption.font_language].forEach(font => {
        if (font.font_name === value) {
          caption.font_url = font.url
        }
      })
    }

    if (field === "font_language") {
      const caption = captions[index]
      this.props.fontFamilies[value].every(font => {
        if (font.url) {
          caption.font_url = font.url
          caption.font_type = font.font_name
          return false
        }
      })
    }

    if (field === "background") {
      const caption = captions[index]
      if (value) {
        caption.background_color = "#000000"
      } else {
        caption.background_color = ""
      }
    }

    let currentCaption = captions[index]
    currentCaption[field] = value

    this.setState({
      formData: {
        captions,
      },
    })
  }

  _form = () => {
    return (
      <Row>
        <Col lg="8">
          <Card>
            <CardBody style={{ maxHeight: "70vh", overflowY: "auto" }}>
              <CardTitle className="d-flex justify-content-between">
                {`Greeting`}
                <div>
                  <Button
                    variant="contained"
                    disabled={this.props.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                    onClick={this._onSubmit}
                  >
                    Create
                  </Button>
                </div>
              </CardTitle>
              {this.state.formData.captions.map((caption, index) => (
                <>
                  <Divider>Caption #{index + 1}</Divider>
                  <Row>
                    <Col lg="4">
                      <label className="col-form-label">Caption Title</label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          onChange={e => {
                            if (e.target.value.length > caption.char_limit) {
                              toastr.remove()
                              toastr.error(
                                "Title can't be greater than the provided character limit !!"
                              )
                              return
                            }
                            this._handleFieldChange(
                              "text",
                              e.target.value,
                              index
                            )
                          }}
                          value={caption.text}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <label className="col-form-label">
                        Font size (in px)
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="number"
                          min={0}
                          onChange={e => {
                            toastr.remove()
                            if (e.target.value <= 0) {
                              toastr.error(
                                "Value can't be less than or equal to zero !!"
                              )
                              return
                            }
                            this._handleFieldChange(
                              "font_size",
                              Number(e.target.value),
                              index
                            )
                          }}
                          value={caption.font_size}
                        />
                      </div>
                    </Col>
                    <Col lg="3">
                      <label className="col-form-label">Font Language</label>
                      <div>
                        <select
                          className="form-select"
                          onChange={e =>
                            this._handleFieldChange(
                              "font_language",
                              e.target.value,
                              index
                            )
                          }
                          defaultValue={caption.font_language}
                          value={caption.font_language}
                        >
                          {LANGUAGES.map(lang => (
                            <>
                              <option value={lang.value} key={lang.value}>
                                {lang.label}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg="3">
                      <label className="col-form-label">Font Type</label>
                      <div>
                        <select
                          className="form-select"
                          onChange={e =>
                            this._handleFieldChange(
                              "font_type",
                              e.target.value,
                              index
                            )
                          }
                          value={caption.font_type}
                        >
                          {this.props.fontFamilies[caption.font_language]?.map(
                            font => {
                              if (font.url) {
                                return (
                                  <option value={font.font_name}>
                                    {font.font_name}
                                  </option>
                                )
                              }
                            }
                          )}
                        </select>
                      </div>
                    </Col>
                    <Col lg="2">
                      <label className="col-form-label">Character Limit</label>
                      <div>
                        <input
                          className="form-control"
                          type="number"
                          min={0}
                          onChange={e => {
                            toastr.remove()
                            if (e.target.value <= 0) {
                              toastr.error(
                                "Limit can't be less than or equal to zero !!"
                              )
                              return
                            }

                            if (e.target.value < caption.text.trim().length) {
                              toastr.error(
                                "Limit can't be less than the length of caption title !!"
                              )
                              return
                            }
                            this._handleFieldChange(
                              "char_limit",
                              Number(e.target.value),
                              index
                            )
                          }}
                          value={caption.char_limit}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <label className="col-form-label">Font Color</label>
                      <div>
                        <input
                          className="form-control form-control-color w-100"
                          type="color"
                          value={caption.font_color}
                          onChange={e =>
                            this._handleFieldChange(
                              "font_color",
                              e.target.value,
                              index
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <label className="col-form-label">
                        X Coordinate (in %)
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="number"
                          min={0}
                          onChange={e => {
                            toastr.remove()
                            if (e.target.value <= 0) {
                              toastr.error(
                                "Value can't be less than or equal to zero !!"
                              )
                              return
                            }
                            this._handleFieldChange(
                              "x_coordinate",
                              Number(e.target.value),
                              index
                            )
                          }}
                          value={caption.x_coordinate}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <label className="col-form-label">
                        Y Coordinate (in %)
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="number"
                          min={0}
                          onChange={e => {
                            toastr.remove()
                            if (e.target.value <= 0) {
                              toastr.error(
                                "Value can't be less than or equal to zero !!"
                              )
                              return
                            }
                            this._handleFieldChange(
                              "y_coordinate",
                              Number(e.target.value),
                              index
                            )
                          }}
                          value={caption.y_coordinate}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <label className="form-col-label mt-2">Bold</label>
                      <div className="form-check form-switch form-switch-lg">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={e =>
                            this._handleFieldChange(
                              "is_bold",
                              e.target.checked,
                              index
                            )
                          }
                          checked={caption.is_bold}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <label className="form-col-label mt-2">Italic</label>
                      <div className="form-check form-switch form-switch-lg">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={e =>
                            this._handleFieldChange(
                              "is_italic",
                              e.target.checked,
                              index
                            )
                          }
                          checked={caption.is_italic}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <label className="form-col-label mt-2">Optional</label>
                      <div className="form-check form-switch form-switch-lg">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={e =>
                            this._handleFieldChange(
                              "optional",
                              e.target.checked,
                              index
                            )
                          }
                          checked={caption.optional}
                        />
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col lg="3">
                          <label className="form-col-label mt-2">
                            Background
                          </label>
                          <div className="form-check form-switch form-switch-lg">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              onChange={e =>
                                this._handleFieldChange(
                                  "background",
                                  e.target.checked,
                                  index
                                )
                              }
                              checked={caption.background}
                            />
                          </div>
                        </Col>
                        {caption.background && (
                          <Col lg="4">
                            <label className="col-form-label">
                              Background Color
                            </label>
                            <div>
                              <input
                                className="form-control form-control-color w-100"
                                type="color"
                                value={caption.background_color}
                                onChange={e =>
                                  this._handleFieldChange(
                                    "background_color",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </>
              ))}
              <Divider />
              <Row>
                <Col md="6">
                  <div>
                    <label className="col-form-label">Upload File</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this.setState({
                          file: e.target.files[0],
                        })
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  disabled={this.props.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={this._onSubmit}
                >
                  Create
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody style={{ height: "70vh" }}>
              <CardTitle className="d-flex align-items-center">
                <div className="mx-2">Greeting Preview</div>
                {this.props.previewFile && (
                  <CopyToClipboard
                    text={JSON.stringify(this.state.formData.captions)}
                    onCopy={e => {
                      toastr.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy Captions">
                      <i className="fontSize18 bx bx-copy me-2" role="button" />
                    </Tooltip>
                  </CopyToClipboard>
                )}
              </CardTitle>
              <div className="d-flex justify-content-center align-items-center h-100">
                {(this.props.previewFile ||
                  this.props?.content?.greeting_url) &&
                  (this.state.fileType === "image" ||
                    this.props?.content?.fileType === "image") &&
                  !this.props.loading && (
                    <img
                      src={
                        this.props.previewFile
                          ? `data:image/png;base64,${this.props.previewFile}`
                          : this.props?.content?.greeting_url
                      }
                      height="500px"
                      width="281px"
                    />
                  )}
                {(this.props.previewFile ||
                  this.props?.content?.greeting_url) &&
                  (this.state.fileType === "video" ||
                    this.props?.content?.fileType === "video") &&
                  !this.props.loading && (
                    <video
                      src={
                        this.props.previewFile
                          ? `data:video/mp4;base64,${this.props.previewFile}`
                          : this.props?.content?.greeting_url
                      }
                      height="500px"
                      width="281px"
                      autoPlay
                      controls
                    />
                  )}
                {!this.props.previewFile &&
                  !this.props.loading &&
                  !this.props?.content?.greeting_url && (
                    <div>Preview will be shown here!</div>
                  )}
                {this.props.loading && (
                  <div className="d-flex">
                    <div className="mx-2">Generating Preview</div>{" "}
                    <LoadingOutlined
                      style={{ fontSize: "20px" }}
                      className="text-primary"
                    />
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  _onSubmit = async () => {
    if (!this.state.file) {
      toastr.error("Please upload file !!")
      return
    }
    const formData = this.state.formData

    if (this.state.file.name.split(".").pop() === "mp4") {
      formData.fileType = "video"
    } else {
      formData.fileType = "image"
    }

    this.setState({
      fileType: formData.fileType,
    })

    if (this.props.itemId) {
      formData.itemId = this.props.itemId
    }

    let captions = _.cloneDeep(this.state.formData.captions).filter(
      caption => caption.text
    )

    captions.forEach(caption => {
      if (!caption.font_type) {
        this.props.fontFamilies[caption.font_language].forEach(font => {
          if (font.url && !caption.font_type) {
            caption.font_url = font.url
            caption.font_type = font.font_name
            return
          }
        })
      }
    })

    console.log(formData)

    let data = new FormData()
    data.append("details", JSON.stringify(formData))
    data.append("content", JSON.stringify(this.props.content))
    data.append("file", this.state.file)

    this.props.setCaptions(captions)
    this.props.onSubmit(data)
  }

  render() {
    return <>{this._form()}</>
  }
}

export default UpsertCMSVideoGreetingsForm
