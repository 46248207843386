import { createSlice } from "@reduxjs/toolkit"

const sourceSlice = createSlice({
  name: "source",
  initialState: {
    sourceList: [],
    taskList: [],
    loading: false,
    refetchData: false,
    total: 0,
  },
  reducers: {
    getSourceListByFilters(state, action) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        sourceList: [],
      }
    },
    getSourceListByFiltersSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        sourceList: payload.data.data,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 0,
        refetchData: false,
      }
    },
    createSource(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createSourceSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    updateSource(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateSourceSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    createDesk(state) {},
    createDeskSuccess(state) {},
    listTask(state) {
      return {
        ...state,
        taskList: [],
        loading: true,
      }
    },
    listTaskSuccess(state, { action }) {
      return {
        ...state,
        taskList: action.payload.data,
        loading: false,
      }
    },
    markTaskCompleted() {},
    resetState(state) {
      return {
        sourceList: [],
        loading: false,
        refetchData: false,
        total: 0,
        taskList: [],
      }
    },
  },
})

export const {
  getSourceListByFilters,
  getSourceListByFiltersSuccess,
  resetState,
  createSource,
  createSourceSuccess,
  createDesk,
  listTask,
  listTaskSuccess,
  markTaskCompleted,
  updateSource,
  updateSourceSuccess,
} = sourceSlice.actions

export default sourceSlice.reducer
