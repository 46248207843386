import Axios from "axios"

export const getSearchAndSelectData = async (filters, type, zoneLevel) => {
  const offset = filters["currentPage"] ? filters["currentPage"] - 1 : 0
  const limit = filters["pageSize"] ? filters["pageSize"] : 10
  const assetType = filters["searchType"]
  const level = zoneLevel
  try {
    let response = await Axios.post(
      `/api/searchAndSelect?type=${type}&offset=${offset}&limit=${limit}&assetType=${assetType}&level=${level}`,
      {
        filters,
      }
    )
    return response
  } catch (error) {
    return error
  }
}
