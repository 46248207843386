import Axios from "axios"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

export const requestLanguageData = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/lookUp/getLookUpsByConstantKey?constantKey=LANGUAGES`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestAgencyData = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/lookUp/getLookUpsByConstantKey?constantKey=AGENCIES`
    )
    return data
  } catch (error) {
    throw error
  }
}
