import React from "react"
import { connect } from "react-redux"
import { Modal } from "react-bootstrap"
import ChipInput from "material-ui-chip-input"
import { Row, Col, CardTitle, Button } from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import { Divider, Select as AntdSelect } from "antd"
import "toastr/build/toastr.min.css"
import { TextField } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { LANGUAGES, LANGUAGE_MAPPING } from "../../constants/uiConstants"

const { Option } = AntdSelect

class TargetWiseSpinConfig extends ErrorBoundComponent {
  _handleFileUpload = (file, key, hideKey) => {
    this.props.setTargetImage(file, key, hideKey)
  }

  _handleFileDelete = key => {
    this.props.hideTargetImage(key)
  }

  addChip = value => {
    const feed_hashtags = this.props?.config?.feed_hashtags?.slice() || []
    feed_hashtags.push(value)
    this.props.setTargetValue(
      feed_hashtags,
      "feed_hashtags",
      this.props.campaignId,
      this.props.targetId
    )
  }
  addChipLabel = value => {
    const feed_hashtags = this.props?.config?.feed_hashtags_label?.slice() || []
    feed_hashtags.push(value)
    this.props.setTargetValue(
      feed_hashtags,
      "feed_hashtags_label",
      this.props.campaignId,
      this.props.targetId
    )
  }

  removeChip = (value, index) => {
    const feed_hashtags = this.props?.config?.feed_hashtags?.slice() || []
    feed_hashtags.splice(index, 1)
    this.props.setTargetValue(
      feed_hashtags,
      "feed_hashtags",
      this.props.campaignId,
      this.props.targetId
    )
  }

  removeChipLabel = (value, index) => {
    const feed_hashtags = this.props?.config?.feed_hashtags_label?.slice() || []
    feed_hashtags.splice(index, 1)
    this.props.setTargetValue(
      feed_hashtags,
      "feed_hashtags_label",
      this.props.campaignId,
      this.props.targetId
    )
  }

  deleteBanner = id => {
    const banner_list = this.state?.banner_list?.filter(banner => {
      if (banner.id != id) {
        return banner
      }
    })
    this.setState({ banner_list })
  }

  deleteButton = id => {
    const static_buttons_list = this.state?.static_buttons_list?.filter(
      button => {
        if (button.button_id != id) {
          return button
        }
      }
    )
    this.setState({ static_buttons_list })
  }

  deleteChip = id => {
    const chips_list = this.state?.chips_list?.filter(chip => {
      if (chip.chip_id != id) {
        return chip
      }
    })
    this.setState({ chips_list })
  }

  _getBannerForm = (banner, idx) => {
    return (
      <div key={banner.id}>
        <Divider className="m-auto">
          <h5 className="mb-0 text-muted">{`Banner - ${idx + 1}`}</h5>
        </Divider>
        <Row className="mb-2">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"Banner ID"}
              field={`banner_list.${idx}.id`}
              className="creator-form"
              defaultValue={banner.id}
              disabled
            />
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"View Order"}
              field={`banner_list.${idx}.view_order`}
              className="creator-form"
              type="number"
              defaultValue={
                banner.view_order || banner.view_order === 0
                  ? banner.view_order
                  : (this.props.config.banner_list &&
                      this.props.config.banner_list[idx] &&
                      this.props.config.banner_list[idx].view_order) ||
                    idx
              }
              disabled
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Deeplink"}
              field={`banner_list.${idx}.deeplink`}
              className="creator-form"
              defaultValue={banner.deeplink || ""}
              onChange={val =>
                this.props.setTargetEntityValue(
                  val,
                  "deeplink",
                  "banner_list",
                  this.props.campaignId,
                  this.props.targetId,
                  banner.id
                )
              }
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Type"}
              field={`banner_list.${idx}.type`}
              className="creator-form"
              defaultValue={banner.type || ""}
              onChange={val =>
                this.props.setTargetEntityValue(
                  val,
                  "type",
                  "banner_list",
                  this.props.campaignId,
                  this.props.targetId,
                  banner.id
                )
              }
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <label className="col-form-label" style={{ paddingTop: "0px" }}>
              Thumbnail
            </label>
            <input
              type="file"
              className="form-control"
              onChange={e =>
                this._handleFileUpload(
                  e.target.files[0],
                  `thumbnail_banner>${this.props.campaignId}>${this.props.targetId}>${banner.id}`,
                  `hide_image_>${this.props.campaignId}>${this.props.targetId}>${banner.id}`
                )
              }
              style={{ padding: "0.54rem 0.75rem" }}
            />
            {this.props[
              `thumbnail_banner>${this.props.campaignId}>${this.props.targetId}>${banner.id}`
            ] ? (
              <div className="bg-light ps-2">
                {
                  this.props[
                    `thumbnail_banner>${this.props.campaignId}>${this.props.targetId}>${banner.id}`
                  ].name
                }
              </div>
            ) : null}
            {banner.thumbnail_banner &&
            !this.props[
              `hide_image_>${this.props.campaignId}>${this.props.targetId}>${banner.id}`
            ] ? (
              <div className="d-flex justify-content-between align-items-center bg-light">
                <img
                  src={banner.thumbnail_banner}
                  width="50px"
                  height="50px"
                  className="p-2"
                />
                <i
                  className="bx bx bx-trash-alt text-danger p-2"
                  role="button"
                  onClick={() => {
                    this._handleFileDelete(
                      `hide_image_>${this.props.campaignId}>${this.props.targetId}>${banner.id}`
                    )
                    this.props.setTargetEntityValue(
                      null,
                      "thumbnail_banner",
                      "banner_list",
                      this.props.campaignId,
                      this.props.targetId,
                      banner.id
                    )
                  }}
                />
              </div>
            ) : null}
          </Col>
          <Col sm={12} md={6} lg={6} className="" style={{ display: "none" }}>
            <TextField
              field={`banner_list.${idx}.thumbnail`}
              className="creator-form"
              defaultValue={banner.thumbnail_banner || ""}
            />
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="d-flex justify-content-end align-items-end"
          >
            <i
              className="bx bx bx-trash-alt bx-sm text-danger p-2"
              role="button"
              onClick={() =>
                this.props.deleteEntity(
                  "banner_list",
                  this.props.campaignId,
                  this.props.targetId,
                  banner.id,
                  idx
                )
              }
            />
          </Col>
        </Row>
      </div>
    )
  }

  _getButtonForm = (button, idx) => {
    return (
      <div key={button.id}>
        <Divider className="m-auto">
          <h5 className="mb-0 text-muted">{`Button - ${idx + 1}`}</h5>
        </Divider>
        <Row className="mb-2">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"Button ID"}
              field={`static_buttons_list.${idx}.button_id`}
              className="creator-form"
              defaultValue={button.id}
              disabled
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Text"}
              field={`static_buttons_list.${idx}.text`}
              className="creator-form"
              defaultValue={button.text || ""}
              onChange={val =>
                this.props.setTargetEntityValue(
                  val,
                  "text",
                  "static_buttons_list",
                  this.props.campaignId,
                  this.props.targetId,
                  button.id
                )
              }
            />
          </Col>
          <Col sm={10} md={5} lg={5} className="">
            <label className="col-form-label" style={{ paddingTop: "0px" }}>
              Icon URL
            </label>
            <input
              type="file"
              className="form-control"
              onChange={e =>
                this._handleFileUpload(
                  e.target.files[0],
                  `icon_url_button>${this.props.campaignId}>${this.props.targetId}>${button.id}`,
                  `hide_image_>${this.props.campaignId}>${this.props.targetId}>${button.id}`
                )
              }
              style={{ padding: "0.54rem 0.75rem" }}
            />
            {this.props[
              `icon_url_button>${this.props.campaignId}>${this.props.targetId}>${button.id}`
            ] ? (
              <div className="bg-light ps-2">
                {
                  this.props[
                    `icon_url_button>${this.props.campaignId}>${this.props.targetId}>${button.id}`
                  ].name
                }
              </div>
            ) : null}
            {button.icon_url_button &&
            !this.props[
              `hide_image_>${this.props.campaignId}>${this.props.targetId}>${button.id}`
            ] ? (
              <div className="d-flex justify-content-between align-items-center bg-light">
                <img
                  src={button.icon_url_button}
                  width="50px"
                  height="50px"
                  className="p-2"
                />
                <i
                  className="bx bx bx-trash-alt text-danger p-2"
                  role="button"
                  onClick={() => {
                    this._handleFileDelete(
                      `hide_image_>${this.props.campaignId}>${this.props.targetId}>${button.id}`
                    )
                    this.props.setTargetEntityValue(
                      null,
                      "icon_url_button",
                      "static_buttons_list",
                      this.props.campaignId,
                      this.props.targetId,
                      button.id
                    )
                  }}
                />
              </div>
            ) : null}
          </Col>
          <Col sm={10} md={5} lg={5} className="" style={{ display: "none" }}>
            <TextField
              field={`static_buttons_list.${idx}.icon_url`}
              className="creator-form"
              defaultValue={button.icon_url_button || ""}
            />
          </Col>
          <Col
            sm={2}
            md={1}
            lg={1}
            className="d-flex justify-content-end align-items-end"
          >
            <i
              className="bx bx bx-trash-alt bx-sm text-danger p-2"
              role="button"
              onClick={() =>
                this.props.deleteEntity(
                  "static_buttons_list",
                  this.props.campaignId,
                  this.props.targetId,
                  button.id,
                  idx
                )
              }
            />
          </Col>
        </Row>
      </div>
    )
  }

  _getChipForm = (chip, idx) => {
    return (
      <div key={chip.id}>
        <Divider className="m-auto">
          <h5 className="mb-0 text-muted">{`Chip - ${idx + 1}`}</h5>
        </Divider>
        <Row className="mb-2">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"Chip ID"}
              field={`chips_list.${idx}.chip_id`}
              className="creator-form"
              defaultValue={chip.id}
              disabled
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Deeplink"}
              field={`chips_list.${idx}.deeplink`}
              className="creator-form"
              defaultValue={chip.deeplink || ""}
              onChange={val =>
                this.props.setTargetEntityValue(
                  val,
                  "deeplink",
                  "chips_list",
                  this.props.campaignId,
                  this.props.targetId,
                  chip.id
                )
              }
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Type"}
              field={`chips_list.${idx}.type`}
              className="creator-form"
              defaultValue={chip.type || ""}
              onChange={val =>
                this.props.setTargetEntityValue(
                  val,
                  "type",
                  "chips_list",
                  this.props.campaignId,
                  this.props.targetId,
                  chip.id
                )
              }
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <label className="col-form-label" style={{ paddingTop: "0px" }}>
              Icon URL
            </label>
            <input
              type="file"
              className="form-control"
              onChange={e =>
                this._handleFileUpload(
                  e.target.files[0],
                  `icon_url_chip>${this.props.campaignId}>${this.props.targetId}>${chip.id}`,
                  `hide_image_>${this.props.campaignId}>${this.props.targetId}>${chip.id}`
                )
              }
              style={{ padding: "0.54rem 0.75rem" }}
            />
            {this.props[
              `icon_url_chip>${this.props.campaignId}>${this.props.targetId}>${chip.id}`
            ] ? (
              <div className="bg-light ps-2">
                {
                  this.props[
                    `icon_url_chip>${this.props.campaignId}>${this.props.targetId}>${chip.id}`
                  ].name
                }
              </div>
            ) : null}
            {chip.icon_url_chip &&
            !this.props[
              `hide_image_>${this.props.campaignId}>${this.props.targetId}>${chip.id}`
            ] ? (
              <div className="d-flex justify-content-between align-items-center bg-light">
                <img
                  src={chip.icon_url_chip}
                  width="50px"
                  height="50px"
                  className="p-2"
                />
                <i
                  className="bx bx bx-trash-alt text-danger p-2"
                  role="button"
                  onClick={() => {
                    this._handleFileDelete(
                      `hide_image_>${this.props.campaignId}>${this.props.targetId}>${chip.id}`
                    )
                    this.props.setTargetEntityValue(
                      null,
                      "icon_url_chip",
                      "chips_list",
                      this.props.campaignId,
                      this.props.targetId,
                      chip.id
                    )
                  }}
                />
              </div>
            ) : null}
          </Col>
          <Col sm={12} md={6} lg={6} className="" style={{ display: "none" }}>
            <TextField
              field={`chips_list.${idx}.icon_url`}
              className="creator-form"
              defaultValue={chip.icon_url_chip || ""}
            />
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="d-flex justify-content-end align-items-end"
          >
            <i
              className="bx bx bx-trash-alt bx-sm text-danger p-2"
              role="button"
              onClick={() =>
                this.props.deleteEntity(
                  "chips_list",
                  this.props.campaignId,
                  this.props.targetId,
                  chip.id,
                  idx
                )
              }
            />
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    let selectedLangList = []
    this.props?.campaignData?.target?.forEach(config => {
      if (config.langCode) selectedLangList.push(config.langCode)
    })
    return (
      <>
        <Modal.Header className="d-flex justify-content-between pb-0 pt-0 border-top mt-4">
          <Row style={{ width: "100%" }}>
            <Col
              md={2}
              lg={2}
              style={{ width: "max-content" }}
              className="d-flex align-items-center"
            >
              <Modal.Title>
                <div className="font-size-18 text-wrap text-muted">
                  Config - {this.props.indx + 1} (
                  {(this.props.config &&
                    this.props.config.langCode &&
                    LANGUAGE_MAPPING[this.props.config.langCode]) ||
                    ""}
                  )
                </div>
              </Modal.Title>
            </Col>
            <Col className="d-flex align-items-center">
              <Divider />
            </Col>
            <Col
              md={1}
              lg={1}
              style={{ width: "max-content" }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <i
                className="bx bx bx-trash-alt bx-sm text-danger p-2"
                role="button"
                onClick={() =>
                  this.props.deleteTarget(
                    this.props.campaignId,
                    this.props.targetId,
                    this.props.indx
                  )
                }
              />
              <i
                className={
                  this.props[`show_${this.props.targetId}`]
                    ? "bx bx bx-hide bx-sm text-primary p-2"
                    : "bx bx bx-show bx-sm text-primary p-2"
                }
                role="button"
                onClick={() =>
                  this.props.hideTarget(`show_${this.props.targetId}`)
                }
              />
            </Col>
          </Row>
        </Modal.Header>
        {this.props[`show_${this.props.targetId}`] ? (
          <div className="m-2 mt-0 p-2">
            <Row>
              <Col sm={12} md={6} lg={6} className="">
                <h5 className="font-size-14 pt-1">Language</h5>
                <AntdSelect
                  showSearch
                  allowClear={true}
                  placeholder="Language"
                  defaultValue={
                    (this.props.config && this.props.config.langCode) || ""
                  }
                  size="large"
                  onChange={val =>
                    this.props.setTargetValue(
                      val,
                      "langCode",
                      this.props.campaignId,
                      this.props.targetId
                    )
                  }
                  style={{ width: "100%" }}
                >
                  {LANGUAGES.map(lang => {
                    if (selectedLangList.includes(lang.value))
                      return (
                        <Option disabled={true} value={lang.value}>
                          {lang.label}
                        </Option>
                      )
                    return <Option value={lang.value}>{lang.label}</Option>
                  })}
                </AntdSelect>
              </Col>
              <Col sm={12} md={6} lg={6} className="">
                <TextField
                  label={"Text"}
                  field={"text"}
                  className="creator-form"
                  defaultValue={
                    (this.props.config && this.props.config.text) || ""
                  }
                  onChange={val =>
                    this.props.setTargetValue(
                      val,
                      "text",
                      this.props.campaignId,
                      this.props.targetId
                    )
                  }
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="">
                <TextField
                  label={"Banner Height"}
                  field={"banner_height"}
                  inputType="number"
                  className="creator-form"
                  defaultValue={
                    (this.props.config && this.props.config.banner_height) || 0
                  }
                  onChange={val =>
                    this.props.setTargetValue(
                      val,
                      "banner_height",
                      this.props.campaignId,
                      this.props.targetId
                    )
                  }
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="">
                <TextField
                  label={"Banner Width"}
                  field={"banner_width"}
                  inputType="number"
                  className="creator-form"
                  defaultValue={
                    (this.props.config && this.props.config.banner_width) || 0
                  }
                  onChange={val =>
                    this.props.setTargetValue(
                      val,
                      "banner_width",
                      this.props.campaignId,
                      this.props.targetId
                    )
                  }
                />
              </Col>
              <Col sm="12" md="6" lg={6}>
                <label className="me-1" htmlFor="radioVertical">
                  HashTags
                </label>
                <br></br>
                <ChipInput
                  value={this.props?.config?.feed_hashtags || []}
                  onAdd={chip => this.addChip(chip)}
                  onDelete={(chip, index) => this.removeChip(chip, index)}
                  // disabled
                />
              </Col>
              <Col sm="12" md="6" lg={6}>
                <label className="me-1" htmlFor="radioVertical">
                  HashTags Label
                </label>
                <br></br>
                <ChipInput
                  value={this.props?.config?.feed_hashtags_label || []}
                  onAdd={chip => this.addChipLabel(chip)}
                  onDelete={(chip, index) => this.removeChipLabel(chip, index)}
                  // disabled
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="">
                <label className="col-form-label" style={{ paddingTop: "0px" }}>
                  Theme Background Image
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={e =>
                    this._handleFileUpload(
                      e.target.files[0],
                      `theme_bg_image>${this.props.campaignId}>${this.props.targetId}`,
                      `hide_image_theme_bg_image>${this.props.campaignId}>${this.props.targetId}`
                    )
                  }
                />
                {this.props[
                  `theme_bg_image>${this.props.campaignId}>${this.props.targetId}`
                ] ? (
                  <div className="bg-light ps-2">
                    {
                      this.props[
                        `theme_bg_image>${this.props.campaignId}>${this.props.targetId}`
                      ].name
                    }
                  </div>
                ) : null}
                {this.props.config.theme_bg_image &&
                !this.props[
                  `hide_image_theme_bg_image>${this.props.campaignId}>${this.props.targetId}`
                ] ? (
                  <div className="d-flex justify-content-between align-items-center bg-light">
                    <img
                      src={this.props.config.theme_bg_image}
                      width="50px"
                      height="50px"
                      className="p-2"
                    />
                    <i
                      className="bx bx bx-trash-alt text-danger p-2"
                      role="button"
                      onClick={() => {
                        this._handleFileDelete(
                          `hide_image_theme_bg_image>${this.props.campaignId}>${this.props.targetId}`
                        )
                        this.props.setTargetValue(
                          null,
                          "theme_bg_image",
                          this.props.campaignId,
                          this.props.targetId
                        )
                      }}
                    />
                  </div>
                ) : null}
              </Col>

              <Col sm={12} md={6} lg={6} className="">
                <label className="col-form-label" style={{ paddingTop: "0px" }}>
                  Banner URL
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={e =>
                    this._handleFileUpload(
                      e.target.files[0],
                      `banner_url>${this.props.campaignId}>${this.props.targetId}`,
                      `hide_image_banner_url>${this.props.campaignId}>${this.props.targetId}`
                    )
                  }
                />
                {this.props[
                  `banner_url>${this.props.campaignId}>${this.props.targetId}`
                ] ? (
                  <div className="bg-light ps-2">
                    {
                      this.props[
                        `banner_url>${this.props.campaignId}>${this.props.targetId}`
                      ].name
                    }
                  </div>
                ) : null}
                {this.props.config.banner_url &&
                !this.props[
                  `hide_image_banner_url>${this.props.campaignId}>${this.props.targetId}`
                ] ? (
                  <div className="d-flex justify-content-between align-items-center bg-light">
                    <img
                      src={this.props.config.banner_url}
                      width="50px"
                      height="50px"
                      className="p-2"
                    />
                    <i
                      className="bx bx bx-trash-alt text-danger p-2"
                      role="button"
                      onClick={() => {
                        this._handleFileDelete(
                          `hide_image_banner_url>${this.props.campaignId}>${this.props.targetId}`
                        )
                        this.props.setTargetValue(
                          null,
                          "banner_url",
                          this.props.campaignId,
                          this.props.targetId
                        )
                      }}
                    />
                  </div>
                ) : null}
              </Col>
              <Col sm={12} md={6} lg={6} className="">
                <label className="col-form-label" style={{ paddingTop: "0px" }}>
                  Sub Banner URL
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={e =>
                    this._handleFileUpload(
                      e.target.files[0],
                      `sub_banner_url>${this.props.campaignId}>${this.props.targetId}`,
                      `hide_image_sub_banner_url>${this.props.campaignId}>${this.props.targetId}`
                    )
                  }
                />
                {this.props[
                  `sub_banner_url>${this.props.campaignId}>${this.props.targetId}`
                ] ? (
                  <div className="bg-light ps-2">
                    {
                      this.props[
                        `sub_banner_url>${this.props.campaignId}>${this.props.targetId}`
                      ].name
                    }
                  </div>
                ) : null}
                {this.props.config.sub_banner_url &&
                !this.props[
                  `hide_image_sub_banner_url>${this.props.campaignId}>${this.props.targetId}`
                ] ? (
                  <div className="d-flex justify-content-between align-items-center bg-light">
                    <img
                      src={this.props.config.sub_banner_url}
                      width="50px"
                      height="50px"
                      className="p-2"
                    />
                    <i
                      className="bx bx bx-trash-alt text-danger p-2"
                      role="button"
                      onClick={() => {
                        this._handleFileDelete(
                          `hide_image_sub_banner_url>${this.props.campaignId}>${this.props.targetId}`
                        )
                        this.props.setTargetValue(
                          null,
                          "sub_banner_url",
                          this.props.campaignId,
                          this.props.targetId
                        )
                      }}
                    />
                  </div>
                ) : null}
              </Col>
              <Col sm={12} md={6} lg={6} className="">
                <TextField
                  label={"Sub Banner Deeplink"}
                  field={"sub_banner_deeplink"}
                  inputType="text"
                  className="creator-form"
                  defaultValue={
                    (this.props.config &&
                      this.props.config.sub_banner_deeplink) ||
                    ""
                  }
                  onChange={val =>
                    this.props.setTargetValue(
                      val,
                      "sub_banner_deeplink",
                      this.props.campaignId,
                      this.props.targetId
                    )
                  }
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="">
                <TextField
                  label={"Sub Banner Type"}
                  field={"sub_banner_type"}
                  inputType="text"
                  className="creator-form"
                  defaultValue={
                    (this.props.config && this.props.config.sub_banner_type) ||
                    ""
                  }
                  onChange={val =>
                    this.props.setTargetValue(
                      val,
                      "sub_banner_type",
                      this.props.campaignId,
                      this.props.targetId
                    )
                  }
                />
              </Col>
            </Row>
            <div className="mt-2 mb-2 font-size-18 text-center text-muted border-top">
              Banner List
            </div>
            <div className="d-flex justify-content-end">
              <Button
                color="outline-primary"
                className="btn-sm font-weight-semibold w-xs font-size-12"
                onClick={() => {
                  const newBannerList = this.props?.config?.banner_list
                    ? [...this.props.config.banner_list]
                    : []
                  newBannerList.push({
                    id: uuidv4(),
                  })
                  this.props.setTargetValue(
                    newBannerList,
                    "banner_list",
                    this.props.campaignId,
                    this.props.targetId
                  )
                }}
              >
                <i className="fa fa-plus mx-1 my-1" /> Banner
              </Button>
            </div>
            {this.props.config?.banner_list &&
            this.props.config.banner_list.length > 0 ? (
              this.props.config.banner_list.map((banner, idx) => {
                return this._getBannerForm(banner, idx)
              })
            ) : (
              <div
                className="d-flex justify-content-center m-5 text-muted"
                style={{ fontStyle: "italic" }}
              >
                No Banner present.
              </div>
            )}
            <div className="mt-2 mb-2 font-size-18 text-center text-muted border-top">
              Static Buttons List
            </div>
            <div className="d-flex justify-content-end">
              <Button
                color="outline-primary"
                className="btn-sm font-weight-semibold w-xs font-size-12"
                onClick={() => {
                  const newStaticButtonList = this.props?.config
                    ?.static_buttons_list
                    ? [...this.props.config.static_buttons_list]
                    : []
                  newStaticButtonList.push({
                    id: uuidv4(),
                  })
                  this.props.setTargetValue(
                    newStaticButtonList,
                    "static_buttons_list",
                    this.props.campaignId,
                    this.props.targetId
                  )
                }}
              >
                <i className="fa fa-plus mx-1 my-1" /> Button
              </Button>
            </div>
            {this.props.config?.static_buttons_list &&
            this.props.config.static_buttons_list.length > 0 ? (
              this.props.config.static_buttons_list.map((button, idx) => {
                return this._getButtonForm(button, idx)
              })
            ) : (
              <div
                className="d-flex justify-content-center m-5 text-muted"
                style={{ fontStyle: "italic" }}
              >
                No Button present.
              </div>
            )}

            <div className="mt-2 mb-2 font-size-18 text-center text-muted border-top">
              Chips List
            </div>
            <div className="d-flex justify-content-end">
              <Button
                color="outline-primary"
                className="btn-sm font-weight-semibold w-xs font-size-12"
                onClick={() => {
                  const newChipsList = this.props?.config?.chips_list
                    ? [...this.props.config.chips_list]
                    : []
                  newChipsList.push({
                    id: uuidv4(),
                  })
                  this.props.setTargetValue(
                    newChipsList,
                    "chips_list",
                    this.props.campaignId,
                    this.props.targetId
                  )
                }}
              >
                <i className="fa fa-plus mx-1 my-1" /> Chip
              </Button>
            </div>
            {this.props.config?.chips_list &&
            this.props.config.chips_list.length > 0 ? (
              this.props.config.chips_list.map((chip, idx) => {
                return this._getChipForm(chip, idx)
              })
            ) : (
              <div
                className="d-flex justify-content-center m-5 text-muted"
                style={{ fontStyle: "italic" }}
              >
                No Chip present.
              </div>
            )}
          </div>
        ) : null}
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "spin.loading"),
    data: _.get(store, "spin.data"),
    refetch: _.get(store, "spin.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(TargetWiseSpinConfig)
