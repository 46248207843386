import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import Cards from "./cards"

import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"
class Genre extends Cards {
  state = {
    labelToDisplay: "Genre",
    currentHierarchy: ["genre"],
    selectionType: "SINGLE",
    currentTab: "genre",
    currentLevel: 0,
  }
  _handleSelection = data => {
    let currentData = _.cloneDeep(this.props.currentData)
    let currentLevel = this.state.currentLevel
    let currentHierarchy = [...this.state.currentHierarchy]
    let selectionType = this.state.selectionType
    let labelToDisplay = this.state.labelToDisplay
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = _.cloneDeep(this.props.taxonomyRoutes)

    const indexTab = taxonomyRoutes.findIndex(
      item => item.key == this.props.selectedSection
    )
    // const indexTabSubgenre = taxonomyRoutes.findIndex(
    //   item => item.key == "sub_genre"
    // )
    currentData.forEach(item => {
      if (item.value === data.value) {
        item.selected = true
        selectedDetails[data.taxonomyJsonKey][data.key] = item.value
      } else {
        if (item.level > data.level) {
          item.selected = false
          selectedDetails[item.taxonomyJsonKey][item.key] = ""
        }
        if (item.level === data.level) {
          item.selected = false
        }
      }
    })
    // if (selectionType === "ALL") {
    //   currentData.forEach(item => {
    //     if (item.value === data.value) {
    //       item.selected = true
    //     }
    //   })
    // } else {
    //   currentData.forEach(item => {
    //     if (item.value === data.value) {
    //       item.selected = true
    //       selectedDetails[data.taxonomyJsonKey][data.key] = item.value
    //     } else {
    //       if (item.level > data.level) {
    //         item.selected = false
    //         selectedDetails[item.taxonomyJsonKey][item.key] = ""
    //       }
    //       if (item.level === data.level) {
    //         item.selected = false
    //       }
    //     }
    //   })
    //   currentLevel++
    // }

    // currentData.forEach(item => {
    //   if (item.level === data.level + 1) {
    //     if (
    //       item.parent === data.value ||
    //       (Array.isArray(item.parent) && item.parent.includes(data.value))
    //     ) {
    //       item.isVisible = true
    //     } else {
    //       delete item.isVisible
    //     }
    //   }
    // })

    // const nextLevelItems = currentData.filter(
    //   item =>
    //     item.level === currentLevel &&
    //     (item.parent === data.value ||
    //       (Array.isArray(item.parent) && item.parent.includes(data.value)))
    // ).length

    // if (nextLevelItems <= 0) {
    //   this.props.setSelectedSection(taxonomyRoutes[indexTab + 1].key)
    //   taxonomyRoutes[indexTab].completed = true
    // } else {
    //   taxonomyRoutes[indexTab].completed = false
    // }
    let nextIndex = 0
    for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
      if (taxonomyRoutes[i].showInSidebar) {
        nextIndex = i
        break
      }
    }
    this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
    taxonomyRoutes[indexTab].completed = true
    // taxonomyRoutes[indexTabSubgenre].completed = false
    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )
    // if (
    //   [
    //     "entertainment",
    //     "status_wishes",
    //     "food_cooking",
    //     "astrology",
    //     "motivational",
    //     "gods_or_goddess",
    //   ].includes(selectedDetails.taxonomy_genre.target_type)
    // ) {
    //   if (this.props?.sourceData?.subType === "labeling_stage_one") {
    //   } else {
    //     taxonomyRoutes[indexTabSubgenre].showInSidebar = true
    //     taxonomyRoutes[indexTabSubgenre].stage = 2
    //   }
    // } else {
    //   taxonomyRoutes[indexTabSubgenre].showInSidebar = false
    // }
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.setSelectedDetails(selectedDetails)

    this.setState({
      currentData,
      currentHierarchy,
      selectedDetails,
      selectionType,
      labelToDisplay,
      currentLevel,
    })
  }
  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderStepper()}
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "content.loading"),
    currentData: _.get(store, "taxonomy.genre"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(Genre)
