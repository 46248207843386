import React from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Select, Divider } from "antd"
import { Col, Row } from "reactstrap"

const { Option } = Select

const CameraAssetTemplateDrawer = props => {
  return (
    <React.Fragment>
      <Row gutter={12}>
        <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Name</h5>
          </Divider>
          <Input
            defaultValue={props.filters["label"]}
            placeholder={"Name"}
            onChange={e =>
              props.handleFilterChange(e.target.value.trim(), "label")
            }
            allowClear={true}
            name="label"
            style={{ width: "100%" }}
          />
        </Col>
        <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Is Processed</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["isProcessed"]}
            placeholder="Is Processed"
            style={{ width: "100%" }}
            onChange={value => props.handleFilterChange(value, "isProcessed")}
          >
            <Option value="">All</Option>
            <Option value="true">True</Option>
            <Option value="false">False</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(CameraAssetTemplateDrawer)
