import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ErrorBoundComponent from "../ErrorBoundComponent"
import "./selectedDataSection.css"
import { taxonomySideBarKeyValue } from "constants/uiConstants"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

class SelectedDataSection extends ErrorBoundComponent {
  eventUpdate = event => {
    event.target.parentElement
      .querySelector(".nested")
      .classList.toggle("active")
    event.target.classList.toggle("caret-down")
  }

  // genre = data => {
  //   return (
  //     data && (
  //       <ul class="nested active">
  //         {data.target_type && (
  //           <li>
  //             <span class="caret caret-down" onClick={this.eventUpdate}>
  //               Target Type
  //             </span>
  //             <ul class="nested active">
  //               <li>{this.keyValue(data.target_type)}</li>
  //             </ul>
  //           </li>
  //         )}
  //         {data.target_sub_type && (
  //           <li>
  //             <span class="caret caret-down" onClick={this.eventUpdate}>
  //               Target sub type
  //             </span>
  //             <ul class="nested active">
  //               <li>{this.keyValue(data.target_sub_type)}</li>
  //             </ul>
  //           </li>
  //         )}
  //         {data.target_theme && (
  //           <li>
  //             <span class="caret caret-down" onClick={this.eventUpdate}>
  //               Target theme
  //             </span>
  //             <ul class="nested active">
  //               <li>{this.keyValue(data.target_theme)}</li>
  //             </ul>
  //           </li>
  //         )}
  //       </ul>
  //     )
  //   )
  // }

  // zone = data => {
  //   let filteredData = []
  //   filteredData = data?.qcApprovedZonesData?.filter(zone =>
  //     data.qcApprovedZones.includes(zone.zone_uuid)
  //   )
  //   return (
  //     <ul class="nested active">
  //       {filteredData &&
  //         filteredData.map(zone => {
  //           let title = zone.title ? zone.title : ""
  //           return <li>{title}</li>
  //         })}
  //     </ul>
  //   )
  // }

  // approvedzone = data => {
  //   let zoneMap = this.props.zoneList.reduce((acc, curr) => {
  //     return { ...acc, [curr.zone_uuid]: curr.title }
  //   }, {})
  //   return (
  //     <ul class="nested active">
  //       {data?.zoneInfo
  //         ?.filter(zone => ["approved"].includes(zone.status))
  //         .map(zone => {
  //           return <li>{zoneMap[zone.zone_id]}</li>
  //         })}
  //     </ul>
  //   )
  // }

  // orthogonalquality = data => {
  //   return (
  //     data && (
  //       <ul class="nested active">
  //         {data.simple && <li>Simple</li>}
  //         {data.relatable && <li>Relatable</li>}
  //         {data.quality_talent && <li>Quality Talent</li>}
  //         {data.surprise_element && <li>Surprise Element</li>}
  //         {data.presentable && <li>Presentable</li>}
  //       </ul>
  //     )
  //   )
  // }

  // orthogonalattribute = data => {
  //   return (
  //     data && (
  //       <ul class="nested active">
  //         {data.singing && <li> Singing</li>}
  //         {data.acting && <li> Acting</li>}
  //         {data.dialogue && <li>Dialogue</li>}
  //         {data.music && <li>Music</li>}
  //         {data.original_performance && <li>Original Performance</li>}
  //       </ul>
  //     )
  //   )
  // }

  // emotions = data => {
  //   return (
  //     data && (
  //       <ul class="nested active">
  //         {data.romantic && <li> Romantic</li>}
  //         {data.sad && <li> Sad</li>}
  //         {data.heartbreak && <li>Heartbreak</li>}
  //       </ul>
  //     )
  //   )
  // }
  audio_vulgarity = data => {
    return (
      data && (
        <ul class="nested active">
          {data.audio_present && <li> Audio is present</li>}
          {data.is_audio_vulgar && <li> Audio is vulgar</li>}
          {data.friendly_abuse && <li>Friendly abuse</li>}
          {data.polictical_abuse && <li> Political abuse</li>}
          {data.religious_abuse && <li>Religious abuse</li>}
        </ul>
      )
    )
  }
  // background = data => {
  //   return (
  //     data && (
  //       <ul class="nested active">
  //         {data.background && <li> {this.keyValue(data.background)}</li>}
  //       </ul>
  //     )
  //   )
  // }
  // city_tier = data => {
  //   return (
  //     data && (
  //       <ul class="nested active">
  //         {data.city_tier && <li>{this.keyValue(data.city_tier)}</li>}
  //       </ul>
  //     )
  //   )
  // }
  glamour = data => {
    return (
      data && (
        <ul class="nested active">
          {data.glamour_type && <li> {this.keyValue(data.glamour_type)}</li>}
          {data.glamour_grade && <li> {this.keyValue(data.glamour_grade)}</li>}
          {data.glamour_region && (
            <li> {this.keyValue(data.glamour_region)}</li>
          )}
        </ul>
      )
    )
  }
  violence = data => {
    return (
      data && (
        <ul class="nested active">
          {data.violence_type && <li> {this.keyValue(data.violence_type)}</li>}
        </ul>
      )
    )
  }
  ethnicity = data => {
    return (
      data && (
        <ul class="nested active">
          {data.ethnicity && <li> {this.keyValue(data.ethnicity)}</li>}
        </ul>
      )
    )
  }
  peopleCount = data => {
    return (
      data && (
        <ul class="nested active">
          {data.people_count && <li> {this.keyValue(data.people_count)}</li>}
        </ul>
      )
    )
  }
  // target_language = data => {
  //   return (
  //     data &&
  //     data.can_be_targeted && (
  //       <ul class="nested active">
  //         {data.language_type && <li> {this.keyValue(data.language_type)}</li>}
  //       </ul>
  //     )
  //   )
  // }
  // people_in_video = data => {
  //   return (
  //     data && (
  //       <ul class="nested active">
  //         {data.male && <li> Male</li>}
  //         {data.female && <li> Female</li>}
  //         {data.child && <li> Child </li>}
  //       </ul>
  //     )
  //   )
  // }
  // event = data => {
  //   let event_obj =
  //     this.props.eventList &&
  //     this.props.eventList.filter(
  //       item =>
  //         item.event_id ===
  //         (data?.event_type == "inactive"
  //           ? data?.events_inactive
  //           : data?.event_type)
  //     )
  //   return (
  //     event_obj && (
  //       <ul class="nested active">
  //         {event_obj && event_obj[0] && event_obj[0].event_name && (
  //           <li> {event_obj[0].event_name}</li>
  //         )}
  //       </ul>
  //     )
  //   )
  // }

  iconShowHide = (data, fields) => {
    for (var i = 0; i < fields.length; i++) {
      if (data && data[fields[i]]) {
        return true
      }
    }

    return false
  }

  toShowSelectedData = val => {
    let showSection = this.props.taxonomyRoutes.filter(data => val === data.key)
    if (showSection.length <= 0) return
    return showSection[0].showInSidebar
  }

  keyValue = key => {
    return taxonomySideBarKeyValue[key] ? taxonomySideBarKeyValue[key] : key
  }

  renderSelectedData = () => {
    return (
      <PerfectScrollbar style={{ width: "230px" }}>
        <ul id="myUL">
          <li>
            <span
              className={`${
                this.props?.selectedDetails &&
                this.props?.selectedDetails["skip_reason"]
                  ? "caret caret-down"
                  : ""
              }`}
              onClick={this.eventUpdate}
            >
              Skip Reason
            </span>
            <ul class="nested active">
              <li>
                {this.props?.selectedDetails &&
                  this.props?.selectedDetails["skip_reason"]}
              </li>
            </ul>
          </li>

          {/* {this.toShowSelectedData("genre") && (
            <li>
              <span
                className={`${
                  (this.props?.selectedDetails &&
                    this.props?.selectedDetails["taxonomy_genre"] &&
                    this.props?.selectedDetails["taxonomy_genre"][
                      "target_type"
                    ]) ||
                  (this.props?.selectedDetails &&
                    this.props?.selectedDetails["taxonomy_genre"] &&
                    this.props?.selectedDetails &&
                    this.props?.selectedDetails["taxonomy_genre"][
                      "target_sub_type"
                    ]) ||
                  (this.props?.selectedDetails &&
                    this.props?.selectedDetails["taxonomy_genre"] &&
                    this.props?.selectedDetails["taxonomy_genre"][
                      "target_theme"
                    ])
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                Genre
              </span>
              {this.genre(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["taxonomy_genre"]
              )}
            </li>
          )} */}
          {this.toShowSelectedData("audio_vulgarity") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["taxonomy_video_quality"],
                    [
                      "audio_present",
                      "is_audio_vulgar",
                      "friendly_abuse",
                      "polictical_abuse",
                      "religious_abuse",
                    ]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                Audio Vulgarity
              </span>
              {this.audio_vulgarity(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["taxonomy_video_quality"]
              )}
            </li>
          )}
          {this.toShowSelectedData("glamour") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["taxonomy_glamour"],
                    ["glamour_type"]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                Glamour
              </span>
              {this.glamour(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["taxonomy_glamour"]
              )}
            </li>
          )}
          {this.toShowSelectedData("violence") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["taxonomy_violence"],
                    ["violence_type"]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                Violence
              </span>
              {this.violence(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["taxonomy_violence"]
              )}
            </li>
          )}
          {/* {this.toShowSelectedData("ethnicity") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["video_additional_info"],
                    ["ethnicity"]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                Ethnicity
              </span>
              {this.ethnicity(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["video_additional_info"]
              )}
            </li>
          )} */}
          {/* {this.toShowSelectedData("people_count") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["video_additional_info"],
                    ["people_count"]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                People Count
              </span>
              {this.peopleCount(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["video_additional_info"]
              )}
            </li>
          )} */}
          {/* {this.toShowSelectedData("orthogonal_attribute") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["orthogonal_attributes"],
                    [
                      "singing",
                      "acting",
                      "dialogue",
                      "music",
                      "original_performance",
                    ]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                Orthogonal attribute
              </span>
              {this.orthogonalattribute(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["orthogonal_attributes"]
              )}
            </li>
          )}
          {this.toShowSelectedData("zone_addition") && (
            <li>
              <span
                class={`${
                  this.zone(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["approved_zone_obj"]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                Zone
              </span>
              {this.zone(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["approved_zone_obj"]
              )}
            </li>
          )}
          {this.toShowSelectedData("emotions") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["taxonomy_emotions"],
                    ["romantic", "sad", "heartbreak"]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                Emotions
              </span>
              {this.emotions(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["taxonomy_emotions"]
              )}
            </li>
          )}
          {this.toShowSelectedData("city_tier") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["taxonomy_elements"],
                    ["city_tier"]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                City tier
              </span>
              {this.city_tier(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["taxonomy_elements"]
              )}
            </li>
          )}
          {this.toShowSelectedData("people_in_video") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["taxonomy_elements"],
                    ["female", "male", "child"]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                People In Video
              </span>
              {this.people_in_video(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["taxonomy_elements"]
              )}
            </li>
          )}
          {this.toShowSelectedData("target_languages") && (
            <li>
              <span
                class={`${
                  this.iconShowHide(
                    this.props?.selectedDetails &&
                      this.props?.selectedDetails["target_languages"],
                    ["language_type"]
                  )
                    ? "caret caret-down"
                    : ""
                }`}
                onClick={this.eventUpdate}
              >
                Target Languages
              </span>
              {this.target_language(
                this.props?.selectedDetails &&
                  this.props?.selectedDetails["target_languages"]
              )}
            </li>
          )} */}
        </ul>
      </PerfectScrollbar>
    )
  }
  render() {
    return this.renderSelectedData()
  }
}

function mapStateToProps(store, ownProps) {
  return {
    contentList: _.get(store, "content.contentList"),
    total: _.get(store, "content.total"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "content.loading"),
    eventList: _.get(store, "content.eventList"),
    zoneList: _.get(store, "zone.zoneList"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(SelectedDataSection)
