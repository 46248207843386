import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { Tabs } from "antd"
import InterestTabList from "./InterestTabList"
import UpsertInterest from "./UpsertInterests"
import ManageSeed from "./ManageSeed"
import { MdAdd } from "react-icons/md"
import ReviewSeed from "./ReviewSeed"
import AddTabVideos from "./AddTabVideos"

class ManageInterests extends ErrorBoundComponent {
  state = {
    showInterestForm: false,
    items: [
      {
        key: "1",
        label: `Public`,
        children: (
          <InterestTabList
            mode="PUBLIC"
            _toggleInterest={(action, data) =>
              this.setState({
                showInterestForm: !this.state.showInterestForm,
                action,
                interestData: data,
              })
            }
            toggleManageReviewVideos={data => {
              this.setState({
                showReviewSeed: true,
                currentInterest: data.currentInterest,
                currentSubInterest: data.currentSubInterest,
                subInterestName: data.subInterestName,
              })
            }}
            toggleManageSeedVideos={data => {
              this.setState({
                showManageSeed: true,
                currentInterest: data.currentInterest,
                currentSubInterest: data.currentSubInterest,
                subInterestName: data.subInterestName,
              })
            }}
            toggleManageAddVideos={data => {
              this.setState({
                showAddVideos: true,
                currentInterest: data.currentInterest,
                currentSubInterest: data.currentSubInterest,
                subInterestName: data.subInterestName,
              })
            }}
            toggleAddVideos={data => {
              this.setState({
                showTabAddVideos: true,
                currentInterest: data.currentInterest,
                currentSubInterest: data.currentSubInterest,
                subInterestName: data.subInterestName,
              })
            }}
          ></InterestTabList>
        ),
      },
      // {
      //   key: "2",
      //   label: `Private`,
      //   children: (
      //     <InterestTabList
      //       mode="PRIVATE"
      //       _toggleInterest={(action, data) =>
      //         this.setState({
      //           showInterestForm: !this.state.showInterestForm,
      //           action,
      //           interestData: data,
      //         })
      //       }
      //       toggleManageReviewVideos={data => {
      //         this.setState({
      //           showReviewSeed: true,
      //           currentInterest: data.currentInterest,
      //           currentSubInterest: data.currentSubInterest,
      //           subInterestName: data.subInterestName,
      //         })
      //       }}
      //       toggleManageSeedVideos={data => {
      //         this.setState({
      //           showManageSeed: true,
      //           currentInterest: data.currentInterest,
      //           currentSubInterest: data.currentSubInterest,
      //           subInterestName: data.subInterestName,
      //         })
      //       }}
      //       toggleManageAddVideos={data => {
      //         this.setState({
      //           showAddVideos: true,
      //           currentInterest: data.currentInterest,
      //           currentSubInterest: data.currentSubInterest,
      //           subInterestName: data.subInterestName,
      //         })
      //       }}
      //     ></InterestTabList>
      //   ),
      // },
    ],
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _toggleInterest = (action, data) => {
    this.setState({
      showInterestForm: !this.state.showInterestForm,
      action,
      interestData: data,
    })
  }

  onChange = key => {
    console.log(key)
  }

  toggleAddInterest = () => {
    this.setState({ showInterestForm: false })
  }

  _handleCloseSeed = () => {
    this.setState({
      showManageSeed: false,
      showReviewSeed: false,
      showTabAddVideos: false,
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          {this.state.showInterestForm && (
            <UpsertInterest
              toggleAddInterest={this.toggleAddInterest}
              interestData={this.state.interestData}
              action={this.state.action}
            />
          )}
          {(this.state.showManageSeed || this.state.showAddVideos) && (
            <ManageSeed
              interest={this.state.currentInterest}
              subInterest={this.state.currentSubInterest}
              showAddVideos={this.state.showAddVideos}
              subInterestName={this.state.subInterestName}
              closeInterest={() => {
                this.setState({ showManageSeed: true })
              }}
              handleCloseSeed={this._handleCloseSeed}
            />
          )}
          {(this.state.showTabAddVideos || this.state.showAddVideos) && (
            <AddTabVideos
              interest={this.state.currentInterest}
              subInterest={this.state.currentSubInterest}
              showAddVideos={this.state.showAddVideos}
              subInterestName={this.state.subInterestName}
              closeInterest={() => {
                this.setState({ showTabAddVideos: true })
              }}
              handleCloseSeed={this._handleCloseSeed}
            />
          )}
          {this.state.showReviewSeed && (
            <ReviewSeed
              interest={this.state.currentInterest}
              subInterest={this.state.currentSubInterest}
              showAddVideos={this.state.showAddVideos}
              subInterestName={this.state.subInterestName}
              handleCloseSeed={this._handleCloseSeed}
              closeInterest={() => {
                this.setState({ showReviewSeed: true })
              }}
            />
          )}

          {!this.state.showInterestForm &&
            !this.state.showManageSeed &&
            !this.state.showReviewSeed &&
            !this.state.showTabAddVideos && (
              <>
                <Row>
                  <Col lg="10" md="12">
                    <h3>Manage Interest</h3>
                  </Col>
                  <Col lg="2" md="12">
                    <div className="d-flex justify-content-end mb-3">
                      <Button
                        // className="btn btn-primary"
                        variant="contained"
                        onClick={() => {
                          this._toggleInterest("create", null)
                          //   this.props.history.push(`/feed/interests/manage`)
                        }}
                        startIcon={<MdAdd />}
                      >
                        Add New Interest
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Tabs
                  defaultActiveKey="1"
                  items={this.state.items}
                  onChange={this.onChange}
                />
              </>
            )}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageInterests)
