import React from "react"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { LANGUAGES } from "constants/uiConstants"

const { Option } = Select

const AudioSeriesDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Series ID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["seriesId"]}
                  placeholder={"Series Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "seriesId")
                  }
                  allowClear={true}
                  name="seriesId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(AudioSeriesDrawer)
