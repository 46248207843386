import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button as MuiButton, Stack } from "@mui/material"
import { Form, Input, Tabs, DatePicker, Divider, Select, Upload } from "antd"
import { Card, CardBody, Row, Col, Button } from "reactstrap"

import { checkItems } from "clientServices/themificationService"

const { Option } = Select
const { TextArea } = Input
class CheckItems extends ErrorBoundComponent {
  state = {
    showInterestForm: false,
    formData: {
      items: "",
    },
    listItem: [],
    errors: {},
    showListresult: false,
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      window.addEventListener("keydown", this._keyDownHandler)
    }
    if (this.props.currentRow) {
      this.setState({
        formData: {
          ...this.props.currentRow,
        },
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    this.setState(
      {
        formData,
        errors: {
          ...this.state.errors,
          [field]: null,
        },
      },
      () => {
        console.log(this.state.errors)
      }
    )
  }

  OnSubmit = async () => {
    let result = await checkItems({
      items: this.state.formData.items,
    })
    if (result) {
      // this.props.toggleListForm()
      this.setState({
        listItem: result,
        showListresult: true,
      })
    }
  }
  iscreateThemeForm = () => {
    this.props.toggleListForm()
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          <Card>
            <CardBody>
              <Row style={{ marginBottom: "5px" }}>
                <Col lg="12">
                  <h4>
                    <b> Add Items</b>
                  </h4>
                </Col>
              </Row>
              <Form layout="vertical" autoComplete="false">
                <Row className="mb-3">
                  <Col lg="6">
                    <Form.Item
                      label="Theme Name"
                      validateStatus={this.state.errors?.themeName && "error"}
                    >
                      <TextArea
                        placeholder="Add Items.."
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        onChange={e =>
                          this._handleFieldChange("items", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Row>
                <div className="d-flex justify-content-end">
                  <Button
                    htmlType="submit"
                    className="font-16 btn-block btn btn-primary"
                    color="primary"
                    disabled={this.state.loading}
                    onClick={() => {
                      this.OnSubmit()
                    }}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="danger"
                    size="default"
                    disabled={this.state.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                    onClick={() => this.iscreateThemeForm()}
                    className="mx-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
              {this.state.showListresult && (
                <Row>
                  <Col lg="12">
                    <h4>
                      <b>Results : </b>
                    </h4>
                  </Col>

                  <br />

                  {this.state.listItem.length > 0 &&
                    this.state.listItem.map(listitem => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ margin: "10px" }}>{listitem.id}</div> -
                        <div style={{ margin: "10px" }}>
                          {" "}
                          {listitem?.rejectReasons &&
                          listitem?.rejectReasons.length > 0
                            ? listitem.rejectReasons[0]
                            : "VALID"}
                        </div>
                      </div>
                    ))}
                </Row>
              )}
            </CardBody>
          </Card>
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(CheckItems)
