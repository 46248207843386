import { Form, Input, Tooltip, InputNumber } from "antd"
import PropTypes from "prop-types"
import React from "react"
import InputMask from "react-input-mask"

import ValidatorComponent from "./validator_component"

import { FormContextItem } from "./form_context"

class TextField extends ValidatorComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    layout: PropTypes.object,
    toolTipLabel: PropTypes.string,
    placeholderLabel: PropTypes.string,
    className: PropTypes.string,
    allowClear: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
      PropTypes.any,
    ]),
    defaultValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
      PropTypes.any,
    ]),
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }

  _onChange = async e => {
    if (!this.props.value) {
      this.setState(
        {
          value: e?.target?.value ?? e,
          defaultUpdated: true,
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.value)
          }
        }
      )
    } else {
      if (this.props.onChange) {
        await this.props.onChange(e.target.value)
        if (this.state.error) {
          this._validate()
        }
      }
    }
  }

  _inputRenderer = () => {
    if (!this.props.disabled && this.props.mask) {
      return (
        <InputMask
          maskChar=""
          mask={this.props.mask}
          value={this.state.value}
          onChange={this._onChange}
          disabled={false}
        >
          {inputProps => (
            <Input
              {...inputProps}
              prefix={this.props.prefix}
              addonBefore={this.props.addonBefore}
              type={this.props.inputType}
              style={this.props.style}
              placeholder={this.props.placeholderLabel}
              size={
                this.props.inputFieldSize ? this.props.inputFieldSize : "large"
              }
            />
          )}
        </InputMask>
      )
    }
    if (this.props.inputType === "Number") {
      return (
        <InputNumber
          style={this.props.style}
          value={this.state.value}
          disabled={this.props.disabled ? true : false}
          type={this.props.inputType}
          placeholder={this.props.placeholderLabel}
          onChange={this._onChange}
          onPressEnter={this.props.onPressEnter}
          min={this.props.minValue}
          size={this.props.inputFieldSize ? this.props.inputFieldSize : "large"}
          suffix={this.props.suffix}
        />
      )
    }
    if (this.props.inputType === "password") {
      return (
        <Input.Password
          style={this.props.style}
          value={this.state.value}
          disabled={this.props.disabled ? true : false}
          type={this.props.inputType}
          placeholder={this.props.placeholderLabel}
          onChange={this._onChange}
          onPressEnter={this.props.onPressEnter}
          size={this.props.inputFieldSize ? this.props.inputFieldSize : "large"}
          suffix={this.props.suffix}
        />
      )
    }
    return (
      <Input
        style={this.props.style}
        prefix={this.props.prefix}
        addonBefore={this.props.addonBefore}
        value={this.state.value}
        defaultValue={this.props.defaultValue}
        disabled={this.props.disabled ? true : false}
        type={this.props.inputType}
        placeholder={this.props.placeholderLabel}
        onChange={this._onChange}
        onPressEnter={this.props.onPressEnter}
        maxLength={this.props.maxLength}
        min={this.props.minValue}
        size={this.props.inputFieldSize ? this.props.inputFieldSize : "large"}
        suffix={this.props.suffix}
        allowClear={this.props.allowClear}
        {...this.props.additionalInputProps}
      />
    )
  }

  _inputContainer = () => {
    if (this.props.toolTipLabel) {
      return (
        <Tooltip
          title={this.props.toolTipLabel}
          trigger={["focus"]}
          placement="bottom"
        >
          {this._inputRenderer()}
        </Tooltip>
      )
    }
    return this._inputRenderer()
  }

  _render = () => {
    return (
      <Form.Item
        validateStatus={
          this.state.error || !this.props.overRideValidateStatus
            ? this.state.error
              ? "error"
              : "success"
            : this.props.overRideValidateStatus
        }
        help={this.state.error ? this.state.error : this.props.customError}
        label={this.props.label}
        className={this.props.className}
        {...this.props.layout}
      >
        {this._inputContainer()}
      </Form.Item>
    )
  }
}

export default FormContextItem(TextField)
