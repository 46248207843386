import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import {
  Button,
  Row,
  Col,
  CardImg,
  Card as ReactCard,
  CardBody,
  Badge,
} from "reactstrap"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import {
  getPageEntities,
  resetState,
  reorderDefaultPageEntities,
} from "../../appRedux/slices/zeroSearch"
import toastr from "toastr"
import Auxiliary from "../../util/Auxiliary"
import {
  Divider,
  Input,
  Pagination,
  Tabs,
  Modal,
  Card,
  message,
  Tooltip,
  Select,
  Radio,
  Popconfirm,
  Switch,
} from "antd"
import "../Comment/browseComment.style.css"
import * as XLSX from "xlsx"
import { MdDownload } from "react-icons/md"
import { Button as MuiButton, Stack } from "@mui/material"
const { Meta } = Card

class ReorderDefaultPageEntities extends ErrorBoundComponent {
  state = {
    pageEntities: [],
    showDefaultOrder: true,
    showModal: true,
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      pageId: this.props?.pageId || "",
      title:
        this.props.queryParams && this.props.queryParams["title"]
          ? this.props.queryParams["title"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : "",
      sortKey: "view_order",
      sortOrder: "asc",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let modifiedFilter = { ...this.state.filters, pageSize: 10000 }
      this.props.dispatch(
        getPageEntities({
          pageEntityId: this?.props?.pageId || "",
          filters: _.deepClean(modifiedFilter),
        })
      )
      let filters = _.clone(this.state.filters)
      this.setState({ filters })
    }
  }

  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  _showSpecificOrdering = () => {
    this.props.history.push(
      `/reorder/pageEntities?pageId=${this.props.queryParams.pageId}`
    )
  }

  _onDragEnd = result => {
    // dropped outside the list
    const prevEntityList = this.state?.pageEntities?.length
      ? this.state.pageEntities
      : this.props.pageEntities

    if (!result.destination) {
      return
    }

    const pageEntities = this._reorder(
      prevEntityList,
      result.source.index,
      result.destination.index
    )
    this.setState({
      pageEntities,
    })
  }

  _updateTabOrder = () => {
    const { pageEntities } = this.state
    const payload = {}
    const entities = []

    for (let i = 0; i < pageEntities.length; i++) {
      entities.push(pageEntities[i]["entity_uuid"])
    }
    payload.entities = entities
    this.props.dispatch(reorderDefaultPageEntities({ payload }))
  }

  _renderCard = (id, entityData, type, i) => {
    return (
      <Tooltip title="Default">
        <ReactCard
          key={id}
          style={{
            width: 150,
            margin: "1rem",
            boxShadow: "1px 4px 1px #AEAEAE",
            cursor: "pointer",
            backgroundColor: "#28a745",
          }}
        >
          <CardBody>
            <div className="d-flex justify-content-center mb-4 text-truncate-2 font-size-15">
              <i className="bx bx-shuffle fa-5x text-black-50" />
            </div>
            <div className="font-size-16 ">Default</div>
          </CardBody>
        </ReactCard>
      </Tooltip>
    )
  }
  handleDownloadExcel = () => {
    const collectionEntities = this.state?.pageEntities?.length
      ? this.state.pageEntities
      : this.props.pageEntities

    let collectionEntitiesFiltered = [["UUID", "TITLE"]]
    collectionEntities
      .slice()
      .filter(slot => slot.status != "REMOVED")
      .sort((a, b) => a.view_order - b.view_order)
      .map(item => {
        collectionEntitiesFiltered.push([
          item.entityData
            ? item.entityData.banner_uuid ||
              item.entityData.combo_id ||
              item.entityData.content_uuid ||
              item.entityData.template_uuid ||
              item.entityData.zone_uuid ||
              item.entityData.music_playlist_uuid ||
              item.entityData.music_label_uuid ||
              item.entityData.chip_uuid ||
              item.entityData.challenge_id ||
              item.entityData.collection_uuid ||
              item.entityData.music_artist_uuid ||
              item.entityData.audio_id ||
              item.entityData.contest_id ||
              item.entityData.user_uuid ||
              item.entityData.name ||
              item.entityData.hashtag
            : item.banner_uuid ||
              item.combo_id ||
              item.content_uuid ||
              item.template_uuid ||
              item.zone_uuid ||
              item.music_playlist_uuid ||
              item.music_label_uuid ||
              item.chip_uuid ||
              item.challenge_id ||
              item.collection_uuid ||
              item.music_artist_uuid ||
              item.audio_id ||
              item.contest_id ||
              item.user_uuid ||
              item.name ||
              item.hashtag,
          item.entityData
            ? item.entityData.heading_title ||
              item.entityData.title ||
              item.entityData.name ||
              item.entityData.hashtag ||
              item.entityData.sticker_name ||
              item.entityData.content_title ||
              item.entityData.asset_label
            : item.heading_title ||
              item.title ||
              item.name ||
              item.hashtag ||
              item.sticker_name ||
              item.content_title ||
              item.asset_label,
        ])
      })
    const worksheet = XLSX.utils.json_to_sheet(
      collectionEntitiesFiltered.slice(1) || []
    )
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "List")
    XLSX.utils.sheet_add_aoa(worksheet, [collectionEntitiesFiltered[0]], {
      origin: "A1",
    })
    XLSX.writeFile(workbook, "Default-List.xlsx")
  }

  render() {
    const pageEntities = this.state?.pageEntities?.length
      ? this.state.pageEntities
      : this.props.pageEntities
    return (
      <React.Fragment>
        <ReactCard>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <div
                style={{
                  height: "55vh",
                  overflowY: "scroll",
                  marginLeft: "10px",
                }}
              >
                <div style={{ display: "flex", flexFlow: "wrap" }}>
                  {this._renderCard("default")}
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              {this.state.showModal && (
                <div
                  style={{ margin: "1rem" }}
                  className="d-flex justify-content-between"
                >
                  <div
                    style={{
                      fontWeight: "700",
                      marginBottom: "5px",
                      marginTop: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {this.state.showModal && "Default"}
                  </div>
                  <MuiButton
                    variant="outlined"
                    startIcon={<MdDownload />}
                    // disabled={
                    //   !this.props?.csvData?.length || this.props.csvLoading
                    // }
                    onClick={this.handleDownloadExcel}
                  >
                    {" "}
                    List
                  </MuiButton>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (!this.props.hasEditPermission) {
                        return toastr.error(NO_PERMISSION_MESSAGE)
                      } else this._updateTabOrder()
                    }}
                  >
                    Done
                  </Button>
                </div>
              )}
              <div
                style={{
                  height: "55vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div style={{ marginBottom: 30 }} bordered={true}>
                  <Auxiliary loading={this.props.loadingPageEntities}>
                    <DragDropContext onDragEnd={this._onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {pageEntities.map((tab, index) => (
                              <Draggable
                                key={`${tab["entity_uuid"]}`}
                                draggableId={`${tab["entity_uuid"]}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Row>
                                      <Col>
                                        <Card
                                          className="cardStyle d-flex align-items-center"
                                          cover={
                                            tab?.entityData?.thumbnail ||
                                            tab?.entityData?.profile_pic ||
                                            tab?.entityData?.album_art ||
                                            tab?.entityData?.static_thumbnail ||
                                            tab.banner_url ||
                                            tab.icon_url ||
                                            tab.zone_image ||
                                            tab.thumbnail_url ? (
                                              <img
                                                alt="image"
                                                // src="https://stream.coolfie.io/stream/prod-ugc-contents/zerosearch/thumbnails/0a9cbabb5ac44725/82b24ff6f32882a2/0a9cbabb5ac4472582b24ff6f32882a2.jpg"
                                                src={
                                                  tab?.entityData?.thumbnail ||
                                                  tab?.entityData
                                                    ?.profile_pic ||
                                                  tab?.entityData?.album_art ||
                                                  tab?.entityData
                                                    ?.static_thumbnail ||
                                                  tab.banner_url ||
                                                  tab.icon_url ||
                                                  tab.zone_image ||
                                                  tab.thumbnail_url
                                                }
                                                style={{
                                                  width: 60,
                                                  height: 60,
                                                }}
                                                className="me-3"
                                              />
                                            ) : (
                                              <div>
                                                <i
                                                  style={{
                                                    width: 60,
                                                    height: 60,
                                                  }}
                                                  className="bx bx-gift bx-lg me-3"
                                                />
                                              </div>
                                            )
                                          }
                                          bordered={true}
                                          style={{
                                            padding: "10px 20px",
                                            display: "flex",
                                            // width: "50px",
                                          }}
                                        >
                                          <div className="d-flex">
                                            <Meta
                                              title={
                                                tab.entityData
                                                  ? tab.entityData.title ||
                                                    tab.entityData.hashtag ||
                                                    tab.entityData.name ||
                                                    tab.entityData
                                                      .sticker_name ||
                                                    tab.entityData.asset_label
                                                  : undefined
                                              }
                                              className="me-2"
                                            />
                                            <CopyToClipboard
                                              text={tab?.entity_uuid}
                                              onCopy={e => {
                                                toastr.success(
                                                  "Copied sucessfully!"
                                                )
                                              }}
                                              className="me-2"
                                            >
                                              <Tooltip title="Copy Entity ID">
                                                <i
                                                  className="bx bx-copy-alt"
                                                  role="button"
                                                />
                                              </Tooltip>
                                            </CopyToClipboard>
                                            <CopyToClipboard
                                              text={tab?.collection_uuid}
                                              onCopy={e => {
                                                toastr.success(
                                                  "Copied sucessfully!"
                                                )
                                              }}
                                            >
                                              <Tooltip title="Copy Collection ID">
                                                <i
                                                  className="bx bx-copy"
                                                  role="button"
                                                />
                                              </Tooltip>
                                            </CopyToClipboard>
                                          </div>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Auxiliary>
                </div>
                {!this.state.showDefaultOrder && this._showSpecificOrdering()}
              </div>
            </Col>
          </Row>
        </ReactCard>
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zeroSearch.totalEntities"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "zeroSearch.loadingEntities"),
    pageEntities: _.get(store, "zeroSearch.pageEntities"),
    refetch: _.get(store, "zeroSearch.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loadingPageEntities: _.get(store, "zeroSearch.loadingPageEntities"),
  }
}

export default connect(mapStateToProps)(ReorderDefaultPageEntities)
