import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider, Tag } from "antd"
import { Col, Row } from "reactstrap"
import dayjs from "dayjs"

const { Option } = Select

const CommentDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              placeholder="Search Type"
              style={{ width: "45%" }}
              allowClear={true}
              defaultValue={props.filters["searchType"]}
              onChange={value => props.handleFilterChange(value, "searchType")}
            >
              <Option value="id">Comment UUID</Option>
              <Option value="comment_by">User UUID</Option>
              <Option value="title">Title</Option>
              <Option value="user_name">User Name</Option>
              <Option value="user_handle">User Handle</Option>
              <Option value="parent_post_id">Parent Post Id</Option>
              <Option value="main_post_id">Main Post Id</Option>
              <Option value="moderation_reason">Moderation Reason</Option>
            </Select>
            {props.filters["searchType"] === "moderation_reason" ? (
              <Select
                defaultValue={props.filters["searchText"]}
                placeholder="Moderation Reason"
                onChange={value => {
                  props.handleFilterChange(value, "searchText")
                }}
                allowClear={true}
                name="searchId"
                style={{ width: "55%" }}
              >
                <Option value="">All</Option>
                {MODERATION_REASONS.map(reason => (
                  <Option value={reason.value}>{reason.label}</Option>
                ))}
              </Select>
            ) : (
              <Input
                defaultValue={props.filters["searchText"]}
                placeholder={"Search Value"}
                onChange={e =>
                  props.handleFilterChange(e.target.value, "searchText")
                }
                allowClear={true}
                name="searchId"
                style={{ width: "55%" }}
              />
            )}
          </Input.Group>
        </Col>
        <Col lg="3" md="6" style={{ marginBottom: "5px" }}>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Moderation Status</h5>
          </Divider>
          <Select
            allowClear={true}
            placeholder="Moderation level"
            defaultValue={props.filters["moderationLevel"]}
            onChange={value => {
              props.handleFilterChange(value, "moderationLevel")
            }}
            style={{ width: "100%" }}
          >
            <Option value="">All Moderated</Option>
            <Option value="3">Auto Approved</Option>
            <Option value="-1">Auto Disabled</Option>
            <Option value="2">Human Approved</Option>
            <Option value="-2">Human Disabled</Option>
            <Option value="0">Unsure</Option>
            <Option value="1">Unmoderated</Option>
          </Select>
        </Col>
        <Col lg="3" md="8" style={{ marginBottom: "5px" }}>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Order By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              allowClear={true}
              defaultValue={props.filters["orderByField"]}
              placeholder="Order By"
              onChange={value => {
                props.handleFilterChange(value, "orderByField")
              }}
              style={{ width: "50%" }}
            >
              <Option value="created_date">Created Date</Option>
              <Option value="report_count">Report Count</Option>
            </Select>
            <Select
              allowClear={true}
              defaultValue={props.filters["order"]}
              placeholder="Order"
              onChange={value => {
                props.handleFilterChange(value, "order")
              }}
              style={{ width: "50%" }}
            >
              <Option value="asc">Ascending</Option>
              <Option value="desc">Descending</Option>
            </Select>
          </Input.Group>
        </Col>
      </Row>
      <div className="d-flex justify-content-center">
        <div onClick={props.toggleAdvancedFilters} role="button">
          {props.showAdvancedFilters ? (
            <i
              className="fas fa-angle-double-up
 font-size-16 align-middle me-2"
            ></i>
          ) : (
            <i
              className="fas fa-angle-double-down
 font-size-16 align-middle me-2"
            ></i>
          )}
          {props.showAdvancedFilters ? "Hide" : "Show"} Advanced Filters
        </div>
      </div>
      {props.showAdvancedFilters && (
        <Row style={{ marginBottom: "10px" }} gutter={8}>
          <Col lg="6" md="12">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Range Filter</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex justify-content-center">
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["rangeSearchType"]}
                    placeholder="Range Search Type"
                    onChange={value => {
                      props.handleFilterChange(value, "rangeSearchType")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="review_bucket">Bucket</Option>
                    <Option value="report_count">Report Count</Option>
                  </Select>
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["opSearchType"]}
                    placeholder="Operator"
                    onChange={value => {
                      props.handleFilterChange(value, "opSearchType")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="bt">Between</Option>
                    <Option value="eq">Equal to</Option>
                    <Option value="gt">Greater Than</Option>
                    <Option value="lt">Less Than</Option>
                  </Select>
                  {props.filters["opSearchType"] !== "bt" && (
                    <Input
                      defaultValue={props.filters["rangeSearchText"]}
                      placeholder={"Range Search Text"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value,
                          "rangeSearchText"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  )}
                  {props.filters["opSearchType"] === "bt" && (
                    <Input
                      defaultValue={props.filters["rangeSearchFrom"]}
                      placeholder={"Range Search From"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value,
                          "rangeSearchFrom"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  )}
                  {props.filters["opSearchType"] === "bt" && (
                    <Input
                      defaultValue={props.filters["rangeSearchTo"]}
                      placeholder={"Range Search To"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value,
                          "rangeSearchTo"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  )}
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col span={24}>
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Bucket</h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Bucket"
              defaultValue={props.filters["bucket"]}
              onChange={value => {
                props.handleFilterChange(value, "bucket")
              }}
              style={{ width: "100%" }}
            >
              <Option value="-1">All</Option>
              <Option value="0">0</Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
              <Option value="11">11</Option>
              <Option value="12">12</Option>
              <Option value="13">13</Option>
              <Option value="14">14</Option>
              <Option value="15">15</Option>
              <Option value="16">16</Option>
              <Option value="17">17</Option>
              <Option value="18">18</Option>
              <Option value="19">19</Option>
              <Option value="20">20</Option>
              <Option value="21">21</Option>
              <Option value="22">22</Option>
              <Option value="23">23</Option>
              <Option value="24">24</Option>
              <Option value="25">25</Option>
              <Option value="26">26</Option>
              <Option value="27">27</Option>
              <Option value="28">28</Option>
              <Option value="29">29</Option>
              <Option value="30">30</Option>
              <Option value="31">31</Option>
              <Option value="32">32</Option>
              <Option value="33">33</Option>
              <Option value="34">34</Option>
              <Option value="35">35</Option>
              <Option value="36">36</Option>
              <Option value="37">37</Option>
              <Option value="38">38</Option>
              <Option value="39">39</Option>
              <Option value="40">40</Option>
              <Option value="41">41</Option>
              <Option value="42">42</Option>
              <Option value="43">43</Option>
              <Option value="44">44</Option>
              <Option value="45">45</Option>
              <Option value="46">46</Option>
              <Option value="47">47</Option>
              <Option value="48">48</Option>
              <Option value="49">49</Option>
              <Option value="50">50</Option>
              <Option value="51">51</Option>
              <Option value="52">52</Option>
              <Option value="53">53</Option>
              <Option value="54">54</Option>
              <Option value="55">55</Option>
              <Option value="56">56</Option>
              <Option value="57">57</Option>
              <Option value="58">58</Option>
              <Option value="59">59</Option>
              <Option value="60">60</Option>
              <Option value="61">61</Option>
              <Option value="62">62</Option>
              <Option value="63">63</Option>
              <Option value="64">64</Option>
              <Option value="65">65</Option>
              <Option value="66">66</Option>
              <Option value="67">67</Option>
              <Option value="68">68</Option>
              <Option value="69">69</Option>
              <Option value="70">70</Option>
              <Option value="71">71</Option>
              <Option value="72">72</Option>
              <Option value="73">73</Option>
              <Option value="74">74</Option>
              <Option value="75">75</Option>
              <Option value="76">76</Option>
              <Option value="77">77</Option>
              <Option value="78">78</Option>
              <Option value="79">79</Option>
              <Option value="80">80</Option>
              <Option value="81">81</Option>
              <Option value="82">82</Option>
              <Option value="83">83</Option>
              <Option value="84">84</Option>
              <Option value="85">85</Option>
              <Option value="86">86</Option>
              <Option value="87">87</Option>
              <Option value="88">88</Option>
              <Option value="89">89</Option>
              <Option value="90">90</Option>
              <Option value="91">91</Option>
              <Option value="92">92</Option>
              <Option value="93">93</Option>
              <Option value="94">94</Option>
              <Option value="95">95</Option>
              <Option value="96">96</Option>
              <Option value="97">97</Option>
              <Option value="98">98</Option>
              <Option value="99">99</Option>
            </Select>
          </Col>
          <Col lg="4">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Created Date</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row>
                <Col className="d-flex">
                  <DatePicker
                    defaultValue={
                      props.filters["createdDateFrom"]
                        ? dayjs.unix(
                            props.filters["createdDateFrom"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="From Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "createdDateFrom"
                      )
                    }}
                  />
                  <DatePicker
                    defaultValue={
                      props.filters["createdDateTo"]
                        ? dayjs.unix(
                            props.filters["createdDateTo"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="To Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "createdDateTo"
                      )
                    }}
                  />
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col lg="3">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Moderated By</h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Moderator"
              defaultValue={props.filters["moderatedBy"]}
              onChange={value => {
                props.handleFilterChange(value, "moderatedBy")
              }}
              style={{ width: "100%" }}
            >
              <Option value="">All</Option>
              {props.moderatorList &&
                props.moderatorList.map(user => (
                  <Option value={user.email}>{user.name}</Option>
                ))}
            </Select>
          </Col>
          <Col span={24}>
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Moderated Date</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row>
                <Col className="d-flex">
                  <DatePicker
                    defaultValue={
                      props.filters["moderatedDateFrom"]
                        ? dayjs.unix(
                            props.filters["moderatedDateFrom"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="From Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "moderatedDateFrom"
                      )
                    }}
                  />
                  <DatePicker
                    defaultValue={
                      props.filters["moderatedDateTo"]
                        ? dayjs.unix(
                            props.filters["moderatedDateTo"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="To Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "moderatedDateTo"
                      )
                    }}
                  />
                </Col>
              </Row>
            </Input.Group>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default withRouter(CommentDrawer)
