import React from "react"
import { Input, Pagination, Upload } from "antd"
import { Select, DatePicker, Tooltip, Divider, Modal, Form } from "antd"
import {
  getInstagramHandles,
  upsertBulkInstagramData,
  upsertInstagramData,
} from "clientServices/crawlerService"
import Auxiliary from "util/Auxiliary"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Button, Switch } from "@mui/material"
import { UploadOutlined } from "@ant-design/icons"
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap"
import ValidatorForm, {
  TextField,
  Switch as VSwitch,
} from "containers/ValidatorForm"
import moment from "moment"
import { MdEdit } from "react-icons/md"
import { getInterest } from "clientServices/interestServices"
import { LANGUAGES } from "constants/uiConstants"
const { Option } = Select
class InstagramCrawler extends React.Component {
  state = {
    loading: false,
    formData: {
      interest: "",
      subinterest: "",
    },
    instaHandles: [],
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      orderBy:
        this.props.queryParams && this.props.queryParams["orderBy"]
          ? this.props.queryParams["orderBy"]
          : "last_crawled",
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "ASC",
      instaHandle:
        this.props.queryParams && this.props.queryParams["instaHandle"]
          ? this.props.queryParams["instaHandle"]
          : "",
      userUuid:
        this.props.queryParams && this.props.queryParams["userUuid"]
          ? this.props.queryParams["userUuid"]
          : "",
    },
    showCreateForm: false,
    showUploadForm: false,
    showUpdateForm: false,
    currentData: {},
  }

  async componentDidMount() {
    this._fetchInstagramHandles()
    const interestdata = await getInterest({
      entity_type: "interest",
      sort: "desc",
    })
    const subinterestdata = await getInterest({ entity_type: "sub_interest" })
    let subintereststatus = {}
    for (let j = 0; j < subinterestdata?.result?.data?.length; j++) {
      subintereststatus[subinterestdata?.result?.data[j].id] = {
        status: subinterestdata?.result?.data[j].is_active,
        name: subinterestdata?.result?.data[j].name,
        id: subinterestdata?.result?.data[j].id,
      }
    }
    this.setState({
      interestData: interestdata?.result?.data || [],
      subInterestStatusList: subintereststatus || {},
    })
  }

  _fetchInstagramHandles = async () => {
    this.setState({
      loading: true,
    })
    try {
      const { data } = await getInstagramHandles({
        filters: this.state.filters,
      })
      this.setState({
        loading: false,
        instaHandles: data.data,
        total: data.total,
        showCreateForm: false,
        showUploadForm: false,
        showUpdateForm: false,
      })
    } catch (error) {
      this.setState({
        loading: false,
        showCreateForm: false,
        showUploadForm: false,
        showUpdateForm: false,
      })
    }
  }
  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }

  _onVideoRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }
  _handlePageChange = value => {
    this.setState(
      {
        filters: { ...this.state.filters, currentPage: value },
      },
      () => this._fetchInstagramHandles()
    )
  }

  _handleChangeStatus = async (e, rowData) => {
    this.setState({
      loading: true,
    })
    const data = {
      ...rowData,
      crawler_running: e.target.checked,
      action: "update",
    }
    try {
      const response = await upsertInstagramData(data)
    } catch (error) {
      this.setState({
        loading: false,
      })
    } finally {
      this._fetchInstagramHandles()
    }
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    try {
      this.setState({
        loading: true,
      })
      const response = await upsertInstagramData({
        ...formData,
        ...{
          active_interest: this.state.formData.interest,
          active_sub_interest: this.state.formData.subinterest,
          language: this.state.formData.language,
        },
      })
    } catch (error) {
      this.setState({
        loading: false,
      })
    } finally {
      this._fetchInstagramHandles()
    }
  }

  _onUpdate = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    try {
      this.setState({
        loading: true,
      })
      const response = await upsertInstagramData({
        ...formData,
        ...{
          active_interest: this.state.formData.interest,
          active_subinterest: this.state.formData.subinterest,
          language: this.state.formData.language,
        },
        action: "update",
      })
    } catch (error) {
      this.setState({
        loading: false,
      })
    } finally {
      this._fetchInstagramHandles()
    }
  }

  _onBulkSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    try {
      this.setState({
        loading: true,
      })
      let data = new FormData()
      data.append("files", this.state.csvFile)
      data.append("details", JSON.stringify({ type: this.state.uploadType }))
      const response = await upsertBulkInstagramData(data)
    } catch (error) {
      this.setState({
        loading: false,
      })
    } finally {
      this._fetchInstagramHandles()
    }
  }
  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    this.setState({ formData }, () => {
      console.log(this.state)
    })
  }

  _instaHandleUpsertFrom = () => {
    return (
      <Card>
        <CardTitle className="m-2">Create Insta Handle</CardTitle>
        <CardBody>
          <ValidatorForm layout={"vertical"} onSubmit={this._onSubmit}>
            <Row>
              <Col lg={6}>
                <TextField
                  label="Handle"
                  placeholderLabel="Handle"
                  field="handle"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col lg={6}>
                <TextField
                  label="Josh User"
                  placeholderLabel="Josh User UUID"
                  field="josh_user_uuid"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col lg={6} className="mt-1">
                <TextField
                  label="Last X Days"
                  placeholderLabel="Input last number of days to crawl handle"
                  field="last_x_days"
                  inputType="number"
                  errors={["This field is required"]}
                />
              </Col>
              <Col lg={6} className="mt-1">
                {" "}
                <label>Interest</label>
                <Form.Item>
                  <Select
                    onChange={async e => {
                      this._handleFieldChange("interest", e)
                      const subinterestData = await getInterest({
                        entity_type: "interest",
                        id: e,
                      })
                      this.setState({
                        subinterestData:
                          subinterestData?.result?.data[0]?.subInterests || [],
                        fieldChange: true,
                        formData: {
                          ...this.state.formData,
                          ...{
                            subinterest: "",
                          },
                        },
                      })
                    }}
                    size="large"
                    // placeholder="---Choose Interest---"
                    // value={this.state.formData.interest}
                    style={{ width: "100%" }}
                  >
                    <Option value="">---Choose Interest---</Option>
                    {this.state.interestData?.map(item => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}

                    {/* <Option value="PRIVATE">Private Mode</Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} className="mt-1">
                {" "}
                <label>Sub-Interest</label>
                <Form.Item>
                  <Select
                    // mode="multiple"
                    // showSearch
                    // allowClear={true}
                    // placeholder="Include Languages"
                    // defaultValue={props.filters["includeLang"]}
                    onChange={value => {
                      this._handleFieldChange("subinterest", value)
                      this.setState({
                        fieldChange: true,
                      })
                    }}
                    value={this.state.formData.subinterest}
                    style={{ width: "100%" }}
                    size="large"
                  >
                    <Option value="">---Choose subInterest---</Option>
                    {this.state.subinterestData?.map(item => (
                      <Option value={item}>
                        {this.state.subInterestStatusList[item].name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} className="mt-1">
                <div style={{ marginBottom: "7px" }}>Language</div>

                <Select
                  label={"Language"}
                  style={{ width: "100%" }}
                  placeholderLabel="Select a Language"
                  field="language"
                  onChange={async e => {
                    this._handleFieldChange("language", e)
                  }}
                  options={LANGUAGES}
                  size="large"
                />
                {/* <TextField
                  label="Language"
                  placeholderLabel="Language"
                  field="language"
                 
                  errors={["This field is required"]}
                /> */}
              </Col>
              <Col lg={6} className="mt-1">
                <VSwitch
                  label="Crawler Status"
                  field="crawler_running"
                  defaultChecked={false}
                />
              </Col>
            </Row>
            <Row>
              <div className="d-flex justify-content-end">
                <Button
                  type="submit"
                  variant="contained"
                  className="font-16 btn-block btn btn-primary"
                  color="success"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Create
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showCreateForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _instaHandleUpdateFrom = () => {
    return (
      <Card>
        <CardTitle className="m-2">Update Insta Handle</CardTitle>
        <CardBody>
          <ValidatorForm layout={"vertical"} onSubmit={this._onUpdate}>
            <Row>
              <Col lg={6}>
                <TextField
                  label="Handle"
                  placeholderLabel="Handle"
                  field="handle"
                  validations={["required"]}
                  errors={["This field is required"]}
                  disabled
                  value={this.state.currentData.handle}
                />
              </Col>
              <Col lg={6}>
                <TextField
                  label="Josh User"
                  placeholderLabel="Josh User UUID"
                  field="josh_user_uuid"
                  validations={["required"]}
                  errors={["This field is required"]}
                  defaultValue={this.state.currentData.josh_user_uuid}
                />
              </Col>
              <Col lg={6} className="mt-1">
                {" "}
                <label>Interest</label>
                <Form.Item>
                  <Select
                    onChange={async e => {
                      this._handleFieldChange("interest", e)
                      const subinterestData = await getInterest({
                        entity_type: "interest",
                        id: e,
                      })
                      this.setState({
                        subinterestData:
                          subinterestData?.result?.data[0]?.subInterests || [],
                        fieldChange: true,
                        formData: {
                          ...this.state.formData,
                          ...{
                            subinterest: "",
                          },
                        },
                      })
                    }}
                    size="large"
                    // placeholder="---Choose Interest---"
                    value={this.state.formData.interest}
                    style={{ width: "100%" }}
                  >
                    <Option value="">---Choose Interest---</Option>
                    {this.state.interestData?.map(item => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}

                    {/* <Option value="PRIVATE">Private Mode</Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} className="mt-1">
                {" "}
                <label>Sub-Interest2</label>
                <Form.Item>
                  <Select
                    // mode="multiple"
                    // showSearch
                    // allowClear={true}
                    // placeholder="Include Languages"
                    // defaultValue={props.filters["includeLang"]}
                    onChange={value => {
                      this._handleFieldChange("subinterest", value)
                      this.setState({
                        fieldChange: true,
                      })
                    }}
                    value={this.state.formData.subinterest}
                    style={{ width: "100%" }}
                    size="large"
                  >
                    <Option value="">---Choose subInterest---</Option>
                    {this.state.subinterestData?.map(item => (
                      <Option value={item}>
                        {this.state.subInterestStatusList[item].name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={6} className="mt-1">
                <div style={{ marginBottom: "7px" }}>Language</div>

                <Select
                  label={"Language"}
                  style={{ width: "100%" }}
                  placeholderLabel="Select a Language"
                  field="language"
                  onChange={async e => {
                    this._handleFieldChange("language", e)
                  }}
                  value={this.state.formData.language}
                  options={LANGUAGES}
                  size="large"
                />
                {/* <TextField
         label="Language"
         placeholderLabel="Language"
         field="language"
        
         errors={["This field is required"]}
       /> */}
              </Col>
            </Row>
            <Row>
              <div className="d-flex justify-content-end">
                <Button
                  type="submit"
                  variant="contained"
                  className="font-16 btn-block btn btn-primary"
                  color="success"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showUpdateForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _bulkinstaHandleUpsertFrom = () => {
    return (
      <Card>
        <CardTitle className="m-2">Bulk Create Insta Handle</CardTitle>
        <CardBody>
          <ValidatorForm layout={"vertical"} onSubmit={this._onBulkSubmit}>
            <Row>
              <Col md="2">
                <Select
                  placeholder="Upload Type"
                  onChange={value => {
                    this.setState({
                      uploadType: value,
                    })
                  }}
                  value={this.state.uploadType}
                  style={{ width: "100%" }}
                  size="large"
                >
                  <Option value="">---Choose Upload Type---</Option>

                  <Option value="create">Create</Option>
                  <Option value="update">Update</Option>
                </Select>
              </Col>
              <Col>
                <Upload
                  showUploadList={true}
                  onRemove={this._onVideoRemove}
                  defaultFileList={this.state.csvFileList}
                  fileList={this.state.csvFileList}
                  customRequest={this._uploadCSV}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<UploadOutlined />}
                  >
                    Click to upload CSV
                  </Button>
                </Upload>
              </Col>
            </Row>
            <Row>
              <div className="d-flex justify-content-end">
                <Button
                  type="submit"
                  variant="contained"
                  className="font-16 btn-block btn btn-primary"
                  color="success"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  {/* <i className="bx bx-plus me-1" /> */}
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showUploadForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _filters = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="4" md="8">
              <Input
                defaultValue={this.state.filters["instaHandle"]}
                placeholder={"Instagram Handle"}
                onChange={e =>
                  this.setState({
                    filters: {
                      ...this.state.filters,
                      instaHandle: e.target.value,
                    },
                  })
                }
                allowClear={true}
                name="searchId"
                size="large"
              />
            </Col>
            <Col lg="4" md="8">
              <Input
                defaultValue={this.state.filters["userUuid"]}
                placeholder={"Josh User UUID"}
                onChange={e =>
                  this.setState({
                    filters: {
                      ...this.state.filters,
                      userUuid: e.target.value,
                    },
                  })
                }
                allowClear={true}
                name="searchId"
                size="large"
              />
            </Col>
            <Col lg="1" md="8">
              <Input
                defaultValue={this.state.filters["gap"]}
                placeholder={"Gap"}
                onChange={e =>
                  this.setState({
                    filters: {
                      ...this.state.filters,
                      gap: e.target.value,
                    },
                  })
                }
                type="number"
                allowClear={true}
                name="gap"
                size="large"
              />
            </Col>
            <Col lg="2" md="12">
              <div className="d-flex justify-content-end mb-3">
                <Button
                  color="success"
                  variant="contained"
                  onClick={this._fetchInstagramHandles}
                >
                  Apply
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  render() {
    return (
      <Auxiliary loading={this.state.loading}>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <div className="d-flex mb-3 justify-content-end">
            <Button
              variant="contained"
              onClick={() =>
                this.setState({ showCreateForm: true, showUploadForm: false })
              }
            >
              Create
            </Button>
          </div>
          <div
            className="d-flex mb-3 justify-content-end"
            style={{ marginLeft: "10px" }}
          >
            <Button
              variant="contained"
              onClick={() =>
                this.setState({ showUploadForm: true, showCreateForm: false })
              }
            >
              Upload
            </Button>
          </div>
        </div>
        {this._filters()}
        {this.state.showCreateForm && this._instaHandleUpsertFrom()}
        {this.state.showUploadForm && this._bulkinstaHandleUpsertFrom()}
        {this.state.showUpdateForm && this._instaHandleUpdateFrom()}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Instagram Handle</b>
                  </TableCell>
                  <TableCell>
                    <b>Josh User</b>
                  </TableCell>
                  <TableCell>
                    <b>Last Crawled Time</b>
                  </TableCell>
                  <TableCell>
                    <b>Last X Days</b>
                  </TableCell>
                  <TableCell>
                    <b>Last Reel</b>
                  </TableCell>
                  <TableCell>
                    <b>Crawler Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Actions</b>
                  </TableCell>
                  {/* <TableCell>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.instaHandles.map(row => (
                  <TableRow
                    key={row.name}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.handle}</TableCell>
                    <TableCell>{row.josh_user_uuid}</TableCell>
                    <TableCell>
                      {row.last_crawled
                        ? moment
                            .unix(row.last_crawled)
                            .format("DD-MM-YY hh:mm a")
                        : ""}
                    </TableCell>
                    <TableCell>{row.last_x_days}</TableCell>
                    <TableCell>
                      {row.last_reel
                        ? moment.unix(row.last_reel).format("DD-MM-YY hh:mm a")
                        : ""}
                    </TableCell>
                    <TableCell>
                      <Switch
                        defaultChecked={row.crawler_running}
                        onChange={e => this._handleChangeStatus(e, row)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={async () => {
                          const subinterestData = await getInterest({
                            entity_type: "interest",
                            id: row.active_interest,
                          })

                          this.setState({
                            showUpdateForm: true,
                            currentData: row,
                            formData: {
                              ...this.state.formData,
                              ...{
                                interest: row.active_interest,
                                subinterest: row.active_subinterest,
                                language: row.language,
                              },
                            },

                            subinterestData:
                              subinterestData?.result?.data[0]?.subInterests ||
                              [],
                          })
                        }}
                      >
                        <MdEdit />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-end pb-3">
            <Pagination
              style={{ marginTop: "1rem" }}
              onChange={this._handlePageChange}
              defaultCurrent={this.state.filters.currentPage}
              total={this.state.total}
              pageSize={this.state.filters.pageSize}
              showSizeChanger={false}
            />
          </div>
        </Paper>
      </Auxiliary>
    )
  }
}

export default InstagramCrawler
