import Axios from "axios"

export const requestLocationList = async () => {
  try {
    let data = await Axios.get(`/api/location`)
    return data
  } catch (error) {
    throw error
  }
}
