import Axios from "axios"
import toastr from "toastr"

export const requestGetCampaign = async ({ payload }) => {
  try {
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"]
    payload["offset"] = offset
    payload["limit"] = limit
    _.unset(payload, "currentPage")
    _.unset(payload, "pageSize")
    let data = await Axios.post(`/api/campaign/search`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetBrandCampaign = async ({ payload }) => {
  try {
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"]
    payload["offset"] = offset
    payload["limit"] = limit
    _.unset(payload, "currentPage")
    _.unset(payload, "pageSize")
    let data = await Axios.post(`/api/campaign/brand-logo/search`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestCampaignCreate = async ({ payload }) => {
  try {
    const response = await Axios.post("/api/campaign/create", payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestBrandLogoUpsert = async ({ payload }) => {
  try {
    let response
    let action = payload?.action
    response = await Axios.post(
      `/api/campaign/brand-logo/upsert?action=${action}`,
      payload.formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    toastr.success("Action Successfull !!")
    // toastr.success("Action Successfull !!")
    // return response
    // if(action === "create") {
    //   response = await Axios.post("/api/campaign/create", payload)
    //   toastr.success("Action Successfull !!")
    //   return response
    // }
    // else {
    //   response = await Axios.put(
    //     `/api/campaign/update?action=${action}&campaignId=${payload.id}`,
    //     payload.data
    //   )
    //   toastr.success("Action Successfull !!")
    //   return response
    // }
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestCampaignUpdate = async ({ payload }) => {
  try {
    const response = await Axios.put(
      `/api/campaign/update?campaignId=${payload.id}`,
      payload.data
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestCampaignDelete = async ({ payload }) => {
  try {
    const response = await Axios.delete(
      `/api/campaign/delete?campaignId=${payload}`
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
