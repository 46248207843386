import React from "react"
import { DatePicker, Divider } from "antd"
import _ from "lodash"
import { Button } from "reactstrap"
import { Row, Col } from "reactstrap"
import ValidatorForm, {
  TextField,
  Select,
  Switch,
  DateTimePicker,
} from "../../containers/ValidatorForm"
import moment from "moment"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import ColorPicker from "components/ColorPicker/colorPicker"
import dayjs from "dayjs"
export default class AudioUpsertForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    const text_meta = this.props.data && this.props.data.text_meta
    this.state = {
      active_bg_color:
        text_meta && text_meta.active && text_meta.active.bg_color,
      active_text_color:
        text_meta && text_meta.active && text_meta.active.text_color,
      clicked_text_color:
        text_meta && text_meta.clicked && text_meta.clicked.text_color,
      clicked_bg_color:
        text_meta && text_meta.clicked && text_meta.clicked.bg_color,
      process_bg_color:
        text_meta && text_meta.in_process && text_meta.in_process.bg_color,
      process_text_color:
        text_meta && text_meta.in_process && text_meta.in_process.text_color,
      deeplink:
        this.props.data &&
        this.props.data.deep_link &&
        (this.props.data.deep_link.type || this.props.data.deep_link.url),
      platformDetails:
        this.props.data &&
        this.props.data.platform_details &&
        (this.props.data.platform_details.android ||
          this.props.data.platform_details.ios),
    }
  }

  _form = () => {
    const textMeta = this.props.data && this.props.data.text_meta
    // console.log(this.props.data.start_time.split("T")[0])
    // console.log( dayjs((this.props.data.start_time.split("T")[0])))
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col sm="12">
            <Row>
              <Col sm="12" md="6">
                <Select
                  label="Action Type"
                  field="action_type"
                  placeholderLabel="Action Type"
                  style={{ width: "100%" }}
                  allowClear={true}
                  defaultValue={this.props.data && this.props.data.action_type}
                  options={[
                    { value: "FOLLOW", label: "Follow" },
                    { value: "UPDATE_APP", label: "Update App" },
                    { value: "CUSTOM", label: "Custom" },
                    { value: "DEEPLINK", label: "Deeplink" },
                    { value: "LOGIN", label: "Login" },
                  ]}
                  onChange={value => this.setState({ actionType: value })}
                />
              </Col>
              {this.state.actionType && this.state.actionType === "CUSTOM" && (
                <Col sm="12" md="6">
                  <TextField
                    label={"Action Label"}
                    placeholderLabel="Action Label"
                    field={"action_label"}
                    className="creator-form"
                    defaultValue={
                      this.props.data && this.props.data.action_label
                    }
                  />
                </Col>
              )}
            </Row>
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Active Meta</h5>
            </Divider>
            <Row>
              <Col sm="12">
                <TextField
                  label={"Text"}
                  placeholderLabel="Text"
                  field={"active_text"}
                  className="creator-form"
                  defaultValue={
                    textMeta && textMeta.active && textMeta.active.text
                  }
                />
              </Col>
              <Col sm="12" md="6">
                <ColorPicker
                  label="Background Color"
                  placeholderLabel="Background Color"
                  field="active_bg_color"
                  defaultColor={this.state.active_bg_color || "#000000"}
                  onColorChange={val =>
                    this.setState({
                      active_bg_color: val.hex,
                    })
                  }
                />
              </Col>
              <Col sm="12" md="6">
                <ColorPicker
                  label="Text Color"
                  placeholderLabel="Text Color"
                  field="active_text_color"
                  defaultColor={this.state.active_text_color || "#ffffff"}
                  onColorChange={val =>
                    this.setState({
                      active_text_color: val.hex,
                    })
                  }
                />
              </Col>
            </Row>
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">In Process Meta</h5>
            </Divider>
            <Row>
              <TextField
                label={"Text"}
                placeholderLabel="Text"
                field={"process_text"}
                className="creator-form"
                defaultValue={
                  textMeta && textMeta.in_process && textMeta.in_process.text
                }
              />
              <Col sm="12" md="6">
                <ColorPicker
                  label="Background Color"
                  placeholderLabel="Background Color"
                  field="process_bg_color"
                  defaultColor={this.state.process_bg_color || "#000000"}
                  onColorChange={val =>
                    this.setState({
                      process_bg_color: val.hex,
                    })
                  }
                />
              </Col>
              <Col sm="12" md="6">
                <ColorPicker
                  label="Text Color"
                  placeholderLabel="Text Color"
                  field="process_text_color"
                  defaultColor={this.state.process_text_color || "#ffffff"}
                  onColorChange={val =>
                    this.setState({
                      process_text_color: val.hex,
                    })
                  }
                />
              </Col>
            </Row>
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Clicked Meta</h5>
            </Divider>
            <Row>
              <TextField
                label={"Text"}
                placeholderLabel="Text"
                field={"clicked_text"}
                className="creator-form"
                defaultValue={
                  textMeta && textMeta.clicked && textMeta.clicked.text
                }
              />
              <Col sm="12" md="6">
                <ColorPicker
                  label="Background Color"
                  placeholderLabel="Background Color"
                  field="clicked_bg_color"
                  defaultColor={this.state.clicked_bg_color || "#000000"}
                  onColorChange={val =>
                    this.setState({
                      clicked_bg_color: val.hex,
                    })
                  }
                />
              </Col>
              <Col sm="12" md="6">
                <ColorPicker
                  label="Text Color"
                  placeholderLabel="Text Color"
                  field="clicked_text_color"
                  defaultColor={this.state.clicked_text_color || "#ffffff"}
                  onColorChange={val =>
                    this.setState({
                      clicked_text_color: val.hex,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <TextField
                  label={"Notify Message"}
                  maxLength={80}
                  placeholderLabel="Notify Message"
                  field={"notify_message"}
                  className="creator-form"
                  defaultValue={
                    this.props &&
                    this.props.data &&
                    this.props.data.notify_message
                  }
                />
              </Col>
            </Row>
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Deeplink</h5>
            </Divider>
            <Switch
              label="Add Deeplink"
              field={"deeplink"}
              className="creator-form"
              defaultValue={
                this.props.data &&
                this.props.data.deep_link &&
                (this.props.data.deep_link.type ||
                  this.props.data.deep_link.url)
              }
              onChange={() => this.setState({ deeplink: !this.state.deeplink })}
            />
            {this.state.deeplink && (
              <React.Fragment>
                <Row>
                  <Col sm="12" md="6">
                    <Select
                      label="Type"
                      field="type"
                      placeholderLabel="Type"
                      style={{ width: "100%" }}
                      allowClear={true}
                      defaultValue={
                        this.props.data &&
                        this.props.data.deep_link &&
                        this.props.data.deep_link.type
                      }
                      options={[
                        { value: "INTERNAL", label: "Internal" },
                        { value: "EXTERNAL", label: "External" },
                      ]}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <TextField
                      label={"Url"}
                      placeholderLabel="Url"
                      field={"url"}
                      className="creator-form"
                      defaultValue={
                        this.props.data &&
                        this.props.data.deep_link &&
                        this.props.data.deep_link.url
                      }
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Platform Details</h5>
            </Divider>
            <Switch
              label="Add Platform Details"
              field={"platformDetails"}
              className="creator-form"
              defaultValue={
                this.props.data &&
                this.props.data.platform_details &&
                (this.props.data.platform_details.android ||
                  this.props.data.platform_details.ios)
              }
              onChange={() =>
                this.setState({ platformDetails: !this.state.platformDetails })
              }
            />
            {this.state.platformDetails && (
              <React.Fragment>
                <Row>
                  <Col sm="12" md="6">
                    <TextField
                      label={"Android"}
                      placeholderLabel="Android"
                      field={"android"}
                      className="creator-form"
                      defaultValue={
                        this.props.data &&
                        this.props.data.platform_details &&
                        this.props.data.platform_details.android &&
                        this.props.data.platform_details.android.min_app_version
                      }
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <TextField
                      label={"Ios"}
                      placeholderLabel="Ios"
                      field={"ios"}
                      className="creator-form"
                      defaultValue={
                        this.props.data &&
                        this.props.data.platform_details &&
                        this.props.data.platform_details.ios &&
                        this.props.data.platform_details.ios.min_app_version
                      }
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Campaign Id"}
              placeholderLabel="Campaign Id"
              field={"campaign_id"}
              className="creator-form"
              defaultValue={this.props.data && this.props.data.campaign_id}
            />
          </Col>

          <Col sm="12" className="d-flex">
            <React.Fragment>
              <div style={{ width: "100%" }}>
                <DateTimePicker
                  style={{ padding: "9px", width: "100%" }}
                  label="Start Time"
                  field="start_time"
                  defaultValue={
                    this.props.data && this.props.data.start_time
                      ? dayjs(this.props.data.start_time)
                      : null
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                <DateTimePicker
                  style={{ padding: "9px", width: "100%" }}
                  label="End Time"
                  field="end_time"
                  defaultValue={
                    this.props.data && this.props.data.end_time
                      ? dayjs(this.props.data.end_time)
                      : null
                  }
                  // onChange={val => console.log(moment(val).format())}
                />
              </div>
            </React.Fragment>
          </Col>
        </Row>
        <div className="d-flex justify-content-end pb-2 pt-2">
          <Button
            color="primary"
            size="default"
            type="submit"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            Update
          </Button>
          <Button
            color="danger"
            size="default"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
            onClick={() => this.props.onCancel()}
            className="mx-2"
          >
            Cancel
          </Button>
        </div>
      </ValidatorForm>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }

    let active = {
      text: formData.active_text,
      bg_color: this.state.active_bg_color,
      text_color: this.state.active_text_color,
    }

    let in_process = {
      text: formData.process_text,
      bg_color: this.state.process_bg_color,
      text_color: this.state.process_text_color,
    }
    let clicked = {
      text: formData.clicked_text,
      bg_color: this.state.clicked_bg_color,
      text_color: this.state.clicked_text_color,
    }

    let deeplink = this.state.deeplink
      ? {
          type: formData.type,
          url: formData.url,
        }
      : {}

    let platformDetails = this.state.platformDetails
      ? _.deepClean({
          android: {
            min_app_version: formData.android,
          },
          ios: {
            min_app_version: formData.ios,
          },
        })
      : {}

    let details = {
      action_label: formData.action_label,
      action_type: formData.action_type,
      text_meta: _.deepClean({
        active: _.deepClean(active),
        in_process: _.deepClean(in_process),
        clicked: _.deepClean(clicked),
      }),

      deep_link: _.deepClean(deeplink),
      start_time: formData.start_time
        ? dayjs(formData.start_time).format("YYYY-MM-DDTHH:MM:ssZ")
        : moment(new Date()).format(),
      end_time:
        formData.end_time &&
        dayjs(formData.end_time).format("YYYY-MM-DDTHH:MM:ssZ"),
      platform_details: _.deepClean(platformDetails),
      campaign_id: formData.campaign_id,
      notify_message: formData.notify_message,
    }
    this.props.onSubmit(details)
  }

  _render = () => {
    return this._form()
  }
}
