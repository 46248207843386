import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ManageDashboardList from "../../components/BulkReportList/bulkReportList"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getBulkReportList } from "appRedux/slices/activity"
import {
  getUser,
  getCommunityContent,
  getTopCreatorsList,
  getAggData,
  getChartData,
  getManagerData,
  getLogs,
  resetState,
} from "appRedux/slices/community"
import { getUserListByFilters } from "../../appRedux/slices/cmsUser"
import Auxiliary from "../../util/Auxiliary"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import Dashboard from "./dashboardPage"
import { withRouter, Link } from "react-router-dom"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormGroup,
} from "reactstrap"
import Select from "react-select"
// import { getCmsUserList } from "../../appRedux/actions/cmsUser.action"
import "../Comment/browseComment.style.css"
import NoDataFound from "components/NoDataFound/noDataFound"
import {
  userFilters,
  contentFilters,
  LANGUAGES,
} from "../../constants/uiConstants"

const startDay = Date.now() - 15 * 24 * 60 * 60 * 1000
class ManageDashboard extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentTab:
        (this.props.queryParams && this.props.queryParams.currentTab) ||
        "dashboard",
      userId: this.props.queryParams && this.props.queryParams.userId,
      userFilters: {
        searchText:
          this.props.queryParams && this.props.queryParams["searchText"]
            ? this.props.queryParams["searchText"]
            : "",
        searchType:
          this.props.queryParams && this.props.queryParams["searchType"]
            ? this.props.queryParams["searchType"]
            : "user_uuid",
        orderByField:
          this.props.queryParams && this.props.queryParams["orderByField"]
            ? this.props.queryParams["orderByField"]
            : "posts_count",
        order:
          this.props.queryParams && this.props.queryParams["order"]
            ? this.props.queryParams["order"]
            : "desc",

        creatorType:
          this.props.queryParams && this.props.queryParams["creatorType"]
            ? this.props.queryParams["creatorType"]
            : "",
        currentCreatorPage:
          this.props.queryParams && this.props.queryParams["currentCreatorPage"]
            ? this.props.queryParams["currentCreatorPage"]
            : 1,
      },
      contentFilters: {
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        searchAfter:
          this.props.queryParams && this.props.queryParams["searchAfter"]
            ? this.props.queryParams["searchAfter"]
            : null,
        contentType:
          this.props.queryParams && this.props.queryParams["contentType"]
            ? this.props.queryParams["contentType"]
            : "",
        verifiedProfiles:
          this.props.queryParams && this.props.queryParams["verifiedProfiles"]
            ? this.props.queryParams["verifiedProfiles"]
            : "",
        searchText:
          this.props.queryParams && this.props.queryParams["searchText"]
            ? this.props.queryParams["searchText"]
            : null,
        audioId:
          this.props.queryParams && this.props.queryParams["audioId"]
            ? this.props.queryParams["audioId"]
            : "",
        searchType:
          this.props.queryParams && this.props.queryParams["searchType"]
            ? this.props.queryParams["searchType"]
            : "content_uuid",
        // moderationLevel:
        //   this.props.queryParams && this.props.queryParams["moderationLevel"]
        //     ? this.props.queryParams["moderationLevel"]
        //     : "",
        langCode:
          this.props.queryParams && this.props.queryParams["langCode"]
            ? this.props.queryParams["langCode"]
            : "",
        rangeSearchType:
          this.props.queryParams && this.props.queryParams["rangeSearchType"]
            ? this.props.queryParams["rangeSearchType"]
            : null,
        opSearchType:
          this.props.queryParams && this.props.queryParams["opSearchType"]
            ? this.props.queryParams["opSearchType"]
            : null,
        rangeSearchText:
          this.props.queryParams && this.props.queryParams["rangeSearchText"]
            ? this.props.queryParams["rangeSearchText"]
            : null,
        rangeSearchFrom:
          this.props.queryParams && this.props.queryParams["rangeSearchFrom"]
            ? this.props.queryParams["rangeSearchFrom"]
            : null,
        rangeSearchTo:
          this.props.queryParams && this.props.queryParams["rangeSearchTo"]
            ? this.props.queryParams["rangeSearchTo"]
            : null,
        bucket:
          this.props.queryParams && this.props.queryParams["bucket"]
            ? this.props.queryParams["bucket"]
            : "-1",
        createdDateFrom:
          this.props.queryParams && this.props.queryParams["createdDateFrom"]
            ? this.props.queryParams["createdDateFrom"]
            : null,
        createdDateTo:
          this.props.queryParams && this.props.queryParams["createdDateTo"]
            ? this.props.queryParams["createdDateTo"]
            : null,
        orderByField:
          this.props.queryParams && this.props.queryParams["orderByField"]
            ? this.props.queryParams["orderByField"]
            : this.props.currentUser.permissions &&
              this.props.currentUser.permissions.includes("COMMUNITY_MANAGER")
            ? "created_date"
            : "view_count",
        order:
          this.props.queryParams && this.props.queryParams["order"]
            ? this.props.queryParams["order"]
            : "desc",
        sourceType:
          this.props.queryParams && this.props.queryParams["sourceType"]
            ? this.props.queryParams["sourceType"]
            : "non_ads",
        maskedContent:
          this.props.queryParams && this.props.queryParams["maskedContent"]
            ? this.props.queryParams["maskedContent"] === "true"
              ? true
              : false
            : false,
        isAssetUsed:
          this.props.queryParams && this.props.queryParams["isAssetUsed"]
            ? this.props.queryParams["isAssetUsed"] === "true"
              ? true
              : false
            : false,
        videoSource:
          this.props.queryParams && this.props.queryParams["videoSource"]
            ? this.props.queryParams["videoSource"]
            : "",
        qcDone:
          this.props.queryParams && this.props.queryParams["qcDone"]
            ? this.props.queryParams["qcDone"]
            : "",
        reviewDone:
          this.props.queryParams && this.props.queryParams["reviewDone"]
            ? this.props.queryParams["reviewDone"]
            : "",
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
      },
      toggleshowdetails: true,
    }
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getManagerData(this.state.userId || this.props.currentUser.userId)
      )
      this.props.dispatch(
        getUser({
          creators: this.state.userId || this.props.currentUser.userId,
          ...this.state.userFilters,
        })
      )
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.communityUser &&
      prevProps.communityUser !== this.props.communityUser &&
      this.props.communityManagerData &&
      this.props.communityManagerData.community &&
      this.props.communityManagerData.community.usersList &&
      this.props.communityManagerData.community.usersList.length > 0
    ) {
      this.props.dispatch(
        getTopCreatorsList({
          creators: this.props.communityManagerData.community.usersList,
          startDate: null,
          endDate: null,
        })
      )
      this.props.dispatch(
        getAggData(this.props.communityManagerData.community.usersList)
      )
      let userType
      ;[13, 33].includes(this.props.communityManagerData.jotUserType)
        ? (userType =
            "communityManagerId=" + this.props.communityManagerData.userId)
        : (userType =
            this.props.communityManagerData.community.usersList.length != 0
              ? "communityManagerId=" + this.props.communityManagerData.userId
              : "communityLeadId=" + this.props.communityManagerData.userId)

      this.props.dispatch(getChartData(userType))

      if (this.state.currentTab === "video") {
        this.props.dispatch(
          getCommunityContent({
            creators: this.props.communityManagerData.community.usersList,
            ...this.state.contentFilters,
          })
        )
      }
      if (this.state.currentTab === "changelog") {
        this.props.dispatch(
          getLogs({
            userIds: this.props.communityManagerData.community.usersList,
            startDay,
            endDate: null,
          })
        )
      }
    }
  }
  _toggleShowState = () => {
    this.setState({ toggleshowdetails: !this.state.toggleshowdetails })
  }
  _onUserSearch = () => {
    const { userFilters, contentFilters, ...restState } = this.state
    const filters = { ...restState, ...userFilters }
    this.props.history.push(
      `/community/manage?${QueryString.stringify(filters)}`
    )
    this.props.dispatch(
      getUser({
        creators: this.state.userId || this.props.currentUser.userId,
        ...this.state.userFilters,
      })
    )
  }

  _onContentSearch = () => {
    const { userFilters, contentFilters, ...restState } = this.state
    const filters = { ...restState, ...contentFilters }
    this.props.history.push(
      `/community/manage?${QueryString.stringify(filters)}`
    )
    if (
      this.props.communityManagerData &&
      this.props.communityManagerData.community &&
      this.props.communityManagerData.community.usersList &&
      this.props.communityManagerData.community.usersList.length > 0
    ) {
      this.props.dispatch(
        getCommunityContent({
          creators: this.props.communityManagerData.community.usersList,
          ...this.state.contentFilters,
        })
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  onVideoClick = currentPage => {
    const { userFilters, contentFilters, ...restState } = this.state
    const filters = { ...restState, ...contentFilters }
    this.setState(
      {
        currentTab: "video",
        contentFilters: { ...this.state.contentFilters, currentPage },
      },
      () => {
        this.props.history.push(
          `/community/manage?${QueryString.stringify(filters)}`
        )
        if (
          this.props.communityManagerData &&
          this.props.communityManagerData.community &&
          this.props.communityManagerData.community.usersList &&
          this.props.communityManagerData.community.usersList.length > 0
        ) {
          this.props.dispatch(
            getCommunityContent({
              creators: this.props.communityManagerData.community.usersList,
              ...this.state.contentFilters,
            })
          )
        }
      }
    )
  }

  onCreatorClick = currentPage => {
    this.setState(
      {
        currentTab: "creator",
        userFilters: {
          ...this.state.userFilters,
          currentCreatorPage: currentPage,
        },
      },
      () => {
        const { userFilters, contentFilters, ...restState } = this.state
        const filters = { ...restState, ...userFilters }
        this.props.history.push(
          `/community/manage?${QueryString.stringify(filters)}`
        )
        if (
          this.props.communityManagerData &&
          this.props.communityManagerData.community &&
          this.props.communityManagerData.community.usersList &&
          this.props.communityManagerData.community.usersList.length > 0
        ) {
          this.props.dispatch(
            getUser({
              creators: this.state.userId || this.props.currentUser.userId,
              ...this.state.userFilters,
            })
          )
        }
      }
    )
  }

  onDashboardClick = (startDate, endDate) => {
    // const startDate=this.props.queryParams && this.props.queryParams.startDate;
    // const endDate = this.props.queryParams && this.props.queryParams.endDate
    if (
      this.props.communityManagerData &&
      this.props.communityManagerData.community &&
      this.props.communityManagerData.community.usersList &&
      this.props.communityManagerData.community.usersList.length > 0
    ) {
      this.props.dispatch(
        getTopCreatorsList({
          creators: this.props.communityManagerData.community.usersList,
          startDate,
          endDate: Date.now(),
        })
      )
    }
  }

  _handleUserFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.userFilters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    this.setState({
      userFilters: { ...newFilters },
    })
  }

  _handleContentFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.contentFilters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    this.setState({
      contentFilters: { ...newFilters },
    })
  }

  _onTabChange = type => {
    // const filters = { ...restState, ...userFilters }
    this.setState({ currentTab: type }, () => {
      const { userFilters, contentFilters, ...restState } = this.state
      if (type === "creator") {
        const filters = { ...restState, ...userFilters }
        this.props.history.push(
          `/community/manage?${QueryString.stringify(filters)}`
        )
        this.props.dispatch(
          getUser({
            creators: this.state.userId || this.props.currentUser.userId,
            ...this.state.userFilters,
          })
        )
      }
      if (type === "video") {
        const filters = { ...restState, ...contentFilters }
        this.props.history.push(
          `/community/manage?${QueryString.stringify(filters)}`
        )
        if (
          this.props.communityManagerData &&
          this.props.communityManagerData.community &&
          this.props.communityManagerData.community.usersList &&
          this.props.communityManagerData.community.usersList.length > 0
        ) {
          this.props.dispatch(
            getCommunityContent({
              creators: this.props.communityManagerData.community.usersList,
            })
          )
        }
      }
      if (type === "dashboard") {
        const filters = { ...restState }
        this.props.history.push(
          `/community/manage?${QueryString.stringify(filters)}`
        )
        if (
          this.props.communityManagerData &&
          this.props.communityManagerData.community &&
          this.props.communityManagerData.community.usersList &&
          this.props.communityManagerData.community.usersList.length > 0
        ) {
          this.props.dispatch(
            getTopCreatorsList({
              creators: this.props.communityManagerData.community.usersList,
              startDate: null,
              endDate: null,
            })
          )
          let userType
          ;[13, 33].includes(this.props.communityManagerData.jotUserType)
            ? (userType =
                "communityManagerId=" + this.props.communityManagerData.userId)
            : (userType =
                this.props.communityManagerData.community.usersList.length != 0
                  ? "communityManagerId=" +
                    this.props.communityManagerData.userId
                  : "communityLeadId=" + this.props.communityManagerData.userId)

          this.props.dispatch(getChartData(userType))
        }
      }

      if (type === "changelog") {
        const filters = { ...restState }
        this.props.history.push(
          `/community/manage?${QueryString.stringify(filters)}`
        )
        if (
          this.props.communityManagerData &&
          this.props.communityManagerData.community &&
          this.props.communityManagerData.community.usersList &&
          this.props.communityManagerData.community.usersList.length > 0
        ) {
          this.props.dispatch(
            getLogs({
              userIds: this.props.communityManagerData.community.usersList,
              startDay,
              endDate: null,
            })
          )
        }
      }
      if (type == "userDashboard") {
        let userType =
          "communityLeadId=" + this.props.communityManagerData.userId

        this.props.dispatch(getChartData(userType))
      }
    })
  }

  _removeUserFilter = filter => {
    const newFilters = {
      ...this.state.userFilters,
      [filter]: userFilters[filter].defaultValue,
    }

    this.setState(
      {
        userFilters: newFilters,
      },
      () => {
        const { userFilters, contentFilters, ...restState } = this.state
        const filters = { ...restState, ...newFilters }
        this.props.history.push(
          `/community/manage?${QueryString.stringify(filters)}`
        )
        this.props.dispatch(
          getUser({
            creators: this.state.userId || this.props.currentUser.userId,
            ...this.state.userFilters,
          })
        )
      }
    )
  }

  _removeContentFilter = filter => {
    const newFilters = {
      ...this.state.contentFilters,
      [filter]: contentFilters[filter].defaultValue,
    }

    this.setState(
      {
        contentFilters: newFilters,
      },
      () => {
        const { userFilters, contentFilters, ...restState } = this.state
        const filters = { ...restState, ...newFilters }
        this.props.history.push(
          `/community/manage?${QueryString.stringify(filters)}`
        )
        if (
          this.props.communityManagerData &&
          this.props.communityManagerData.community &&
          this.props.communityManagerData.community.usersList &&
          this.props.communityManagerData.community.usersList.length > 0
        ) {
          this.props.dispatch(
            getCommunityContent({
              creators: this.props.communityManagerData.community.usersList,
              ...this.state.contentFilters,
            })
          )
        }
      }
    )
  }

  render() {
    const { total } = this.props.contentData
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.props.communityUser &&
          this.props.communityUser.community &&
          this.props.communityUser.community.usersList &&
          this.props.communityUser.community.usersList.length === 0 && (
            <NoDataFound message="No Data Found" />
          )}
        {this.props.currentUser &&
          [1, 2, 11, 12].includes(this.props.currentUser.jotUserType) &&
          !this.props.currentUser.community &&
          !this.state.userId && (
            <Link to="/community/manager/management">
              <div className="text-center mt-5 font-size-16">
                <i className="fa fa-info-circle mx-2" />
                Please Select Community Manager to display dashboard!
              </div>
            </Link>
          )}
        {!(
          this.props.currentUser &&
          [1, 2, 11, 12].includes(this.props.currentUser.jotUserType) &&
          !this.props.currentUser.community &&
          !this.state.userId
        ) &&
          !(
            this.props.communityUser &&
            this.props.communityUser.community &&
            this.props.communityUser.community.usersList &&
            this.props.communityUser.community.usersList.length === 0
          ) && (
            <Dashboard
              user={
                (this.props.moderator && this.props.moderator[0]) ||
                this.props.currentUser
              }
              currentTab={this.state.currentTab}
              communityUserData={this.props.communityUser}
              onVideoClick={currentPage => this.onVideoClick(currentPage)}
              onCreatorClick={currentPage => this.onCreatorClick(currentPage)}
              contentData={
                this.props.contentData && this.props.contentData.data
              }
              total={total && total.value ? total.value : total ? total : 1}
              onDashboardClick={creators => this.onDashboardClick(creators)}
              topList={this.props.topList}
              aggData={this.props.aggData}
              history={this.props.history}
              pageType={
                this.props.queryParams && this.props.queryParams.pageType
              }
              searchAfter={this.props.searchAfter}
              onTabChange={this._onTabChange}
              currentPage={this.state.contentFilters.currentPage}
              currentCreatorPage={this.state.userFilters.currentCreatorPage}
              currentUser={this.props.currentUser}
              communityManagerData={this.props.communityManagerData}
              logs={this.props.logs}
              userFilters={this.state.userFilters}
              _handleFilterChange={this._handleUserFilterChange}
              _handleContentFilterChange={this._handleContentFilterChange}
              onUserSearch={this._onUserSearch}
              totalCreators={this.props.totalCreators}
              contentFilters={this.state.contentFilters}
              onContentrSearch={this._onContentSearch}
              removeUserFilter={this._removeUserFilter}
              removeContentFilter={this._removeContentFilter}
              toggleshowdetails={this.state.toggleshowdetails}
              toggleShowState={this._toggleShowState}
            />
          )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "activityLog.count"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "community.loading"),
    reportList: _.get(store, "activityLog.reportList"),
    communityUser: _.get(store, "community.communityUser"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    moderator: _.get(store, "cmsUser.user"),
    contentData: _.get(store, "community.contentData"),
    topList: _.get(store, "community.topList"),
    aggData: _.get(store, "community.aggData"),
    searchAfter: _.get(store, "content.searchAfter"),
    communityManagerData: _.get(store, "community.communityManager"),
    logs: _.get(store, "community.logs"),
    totalCreators: _.get(store, "community.totalCreators"),
  }
}

export default connect(mapStateToProps)(ManageDashboard)
