import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"
import {
  getRooms,
  getRoomsSuccess,
  upsertRoom,
  upsertRoomSuccess,
  shutRoom,
  shutRoomSuccess,
  getJoshLiveConfig,
  getJoshLiveConfigSuccess,
  updateJoshLiveConfig,
  updateJoshLiveConfigSuccess,
} from "appRedux/slices/joshlive"

import {
  requestRooms,
  requestUpsertRoom,
  requestShutRoom,
  requestGetJoshLiveConfig,
  requestUpdateJoshLiveConfig,
} from "../requests/joshlive"

function* handleGetRooms(params) {
  try {
    const data = yield call(requestRooms, params)
    yield put(getRoomsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertRoom(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertRoom, params)
    yield put(upsertRoomSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleShutRoom(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestShutRoom, params)
    yield put(shutRoomSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetJoshLiveConfig(params) {
  try {
    const data = yield call(requestGetJoshLiveConfig, params)
    yield put(getJoshLiveConfigSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateJoshLiveConfig(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdateJoshLiveConfig, params)
    yield put(updateJoshLiveConfigSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getRooms.type, handleGetRooms)
  yield takeLatest(upsertRoom.type, handleUpsertRoom)
  yield takeLatest(shutRoom.type, handleShutRoom)
  yield takeLatest(getJoshLiveConfig.type, handleGetJoshLiveConfig)
  yield takeLatest(updateJoshLiveConfig.type, handleUpdateJoshLiveConfig)
}
