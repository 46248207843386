import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { Modal } from "antd"
import { Row, Col, Button, Card, CardBody } from "reactstrap"
import Reorder from "../../components/Reorder/reorderPageEntities"
import _ from "lodash"
import {
  reorderCollectionEntities,
  upsertTargetCollectionEntities,
  removeCollectionEntityByLocation,
} from "../../appRedux/slices/zeroSearch"
import ValidatorForm, { MultiSelect, Radio } from "../ValidatorForm"
import * as XLSX from "xlsx"
import { MdDownload } from "react-icons/md"
import { Button as MuiButton, Stack } from "@mui/material"
import { LANGUAGE_MAPPING } from "constants/uiConstants"
class CollectionEntityCard extends React.Component {
  state = {
    showModal: false,
    locations: [],
    cardData: this.props.data,
  }

  componentDidMount() {
    let stateLocationList = []
    let cityLocationList = []
    this.props.locationList.map(location => {
      location.children.map(childrenLocation => {
        let childrenLocationObject = {}
        childrenLocationObject["label"] = childrenLocation.name
        childrenLocationObject["value"] = childrenLocation.dhTagId
        cityLocationList.push(childrenLocationObject)
      })
      let stateLocationObject = {}
      stateLocationObject["label"] = location.name
      stateLocationObject["value"] = location.dhTagId
      stateLocationList.push(stateLocationObject)
    })
    this.setState({
      stateLocationList,
      cityLocationList,
    })
  }

  _getDefaultLocations = () => {
    let locations = []
    this.state.selectedEntity.locations &&
      this.state.selectedEntity.locations.length > 0 &&
      this.state.selectedEntity.locations.forEach(entity => {
        if (entity.location_id) {
          locations.push(entity.location_id)
        }
      })
    return locations
  }

  _getLocations = () => {
    const locationArray = this.props.locationList.reduce((acc, location) => {
      let cityArray = []
      if (location.children && location.children.length > 0) {
        cityArray = location.children.map(city => {
          return { label: city.name, value: city.dhTagId }
        })
      }
      return [
        ...acc,
        ...cityArray,
        { label: location.name, value: location.dhTagId },
      ]
    }, [])
    return locationArray
  }

  _reorderCollectionEntities = async () => {
    const [entity] = this.state.entityData
    const data = _.deepClean({
      location_id: entity.location_id,
      lang_code: this.props.activeTab,
      entities: this.state.orderedUUIDs,
    })
    const response = await this.props.dispatch(
      reorderCollectionEntities({ data, collectionId: this.props.collectionId })
    )
  }

  _onSubmit = async () => {
    this.setState({ showModal: false })
    const { entityId: entity_uuid, lang_code } = this.state.selectedEntity
    let data = { entity_uuid, lang_code: this.props.activeTab }
    if (this.state.type === "status") {
      data.status = this.state.status
    } else {
      data.locations = this.state.locations
    }
    const response = await this.props.dispatch(
      upsertTargetCollectionEntities(data)
    )
    this.setState({ disableButton: false })
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          {this.state.type === "status" && (
            <Col span={12}>
              <Radio
                label={"Status"}
                field={"status"}
                className="creator-form"
                defaultValue={
                  this.state.selectedEntity && this.state.selectedEntity.status
                }
                options={[
                  { label: "PUBLISHED", value: "PUBLISHED" },
                  { label: "DRAFT", value: "DRAFT" },
                  { label: "REMOVED", value: "REMOVED" },
                ]}
                onChange={val => this.setState({ status: val })}
                radioType="radio"
              />
            </Col>
          )}
          {this.state.type === "location" && (
            <Col span={12}>
              <MultiSelect
                label={""}
                placeholderLabel="Location"
                field={"locations"}
                className="creator-form"
                onSelectValues={val => this.setState({ locations: val })}
                defaultValue={
                  this.state.selectedEntity && this._getDefaultLocations()
                }
                options={this._getLocations()}
              />
            </Col>
          )}
        </Row>
      </ValidatorForm>
    )
  }

  _renderEditModal = () => {
    return (
      <Modal
        visible={this.state.showEditModal}
        onCancel={() => this.setState({ showEditModal: false, action: "edit" })}
        closable={true}
        centered
        title={`Edit ${this.state.type === "status" ? "Status" : "Location"}`}
        maskClosable
        footer={null}
        destroyOnClose
        bodyStyle={{ overflowY: "auto", height: "20vh" }}
      >
        <div className="view_container">
          {this._form()}
          <div className="d-flex justify-content-end">
            <Button color="primary" onClick={() => this._onSubmit()}>
              Done
            </Button>
          </div>
        </div>
      </Modal>
    )
  }

  _onEdit = (val, type) => {
    const locations =
      val.locations && val.locations.length > 0
        ? val.locations.map(location => location.location_id)
        : []
    this.setState({
      selectedEntity: val,
      locations,
      showEditModal: true,
      action: "update",
      type,
    })
  }

  _onClick = (entityData, action, i, cardType) => {
    this.setState({
      showModal: true,
      entityData,
      action,
      selected: i,
      cardType,
    })
  }

  _getTitle = (value, type) => {
    let title = ""
    if (type === "language") {
      title = this.props.languageMap[value] || "Default"
    }
    if (type === "location") {
      const [a, b, c] = value.split("_")
      const key = c ? b.concat(`_${c}`) : b
      if (c) {
        this.state.cityLocationList &&
          this.state.cityLocationList.length > 0 &&
          this.state.cityLocationList.forEach(k => {
            if (k["value"] === key) {
              title = (this.props.languageMap[a] || "Default").concat(
                `- ${k["label"]}`.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
              )
            }
          })
      } else
        this.state.stateLocationList &&
          this.state.stateLocationList.length > 0 &&
          this.state.stateLocationList.forEach(k => {
            if (k["value"] === key) {
              title = (this.props.languageMap[a] || "Default").concat(
                `- ${k["label"]}`.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
              )
            }
          })
    }
    return title
  }

  _renderCard = (id, entityData, type, i) => {
    const selected = this.state.selected === id
    return (
      <Card
        onClick={() => this._onClick(entityData, "reorder", id, type)}
        key={id}
        style={{
          width: 150,
          margin: "1rem",
          boxShadow: "1px 4px 1px #AEAEAE",
          cursor: "pointer",
          backgroundColor: selected ? "#28a745" : "transparent",
        }}
      >
        <CardBody>
          <div className="d-flex justify-content-center mb-4 text-truncate-2 font-sixe-15">
            <i
              className={
                type === "location"
                  ? "fas fa-map-marked-alt fa-5x text-black-50"
                  : "fas fa-language fa-5x text-black-50"
              }
            />
          </div>
          <div className="font-size-16 ">{this._getTitle(id, type)}</div>
        </CardBody>
      </Card>
    )
  }

  onDelete = async selectedEntity => {
    this.setState({ selectedEntity, action: "delete" })
    let loading, messageDesc
    const { entityId: entity_uuid, location_id } = selectedEntity
    messageDesc = "Updating page entity"
    let data = { entity_uuid, lang_code: this.props.activeTab, location_id }
    const response = await this.props.dispatch(
      removeCollectionEntityByLocation({ data })
    )
  }

  _renderReorder = (id, data) => {
    return (
      <Reorder
        onEdit={(val, type) => this._onEdit(val, type)}
        onOrder={orderedUUIDs => this.setState({ orderedUUIDs })}
        onDelete={entity => this.onDelete(entity)}
        data={data}
        formAction="update"
        onAdd={entity =>
          this.setState({ showAddForm: true, selectedData: entity })
        }
        action="edit"
        selectedKey={this.state.selected}
        cardType={this.state.cardType}
      />
    )
  }
  handleDownloadExcel = () => {
    const collectionEntities = this.state.entityData
    let collectionEntitiesFiltered = [["UUID", "TITLE"]]
    collectionEntities
      .slice()
      .filter(slot => slot.status != "REMOVED")
      .sort((a, b) => a.view_order - b.view_order)
      .map(item => {
        collectionEntitiesFiltered.push([
          item.entityData
            ? item.entityData.banner_uuid ||
              item.entityData.combo_id ||
              item.entityData.content_uuid ||
              item.entityData.template_uuid ||
              item.entityData.zone_uuid ||
              item.entityData.music_playlist_uuid ||
              item.entityData.music_label_uuid ||
              item.entityData.chip_uuid ||
              item.entityData.challenge_id ||
              item.entityData.collection_uuid ||
              item.entityData.music_artist_uuid ||
              item.entityData.audio_id ||
              item.entityData.contest_id ||
              item.entityData.user_uuid ||
              item.entityData.name ||
              item.entityData.hashtag
            : item.banner_uuid ||
              item.combo_id ||
              item.content_uuid ||
              item.template_uuid ||
              item.zone_uuid ||
              item.music_playlist_uuid ||
              item.music_label_uuid ||
              item.chip_uuid ||
              item.challenge_id ||
              item.collection_uuid ||
              item.music_artist_uuid ||
              item.audio_id ||
              item.contest_id ||
              item.user_uuid ||
              item.name ||
              item.hashtag,
          item.entityData
            ? item.entityData.heading_title ||
              item.entityData.title ||
              item.entityData.name ||
              item.entityData.hashtag ||
              item.entityData.sticker_name ||
              item.entityData.content_title ||
              item.entityData.asset_label
            : item.heading_title ||
              item.title ||
              item.name ||
              item.hashtag ||
              item.sticker_name ||
              item.content_title ||
              item.asset_label,
        ])
      })
    const worksheet = XLSX.utils.json_to_sheet(
      collectionEntitiesFiltered.slice(1) || []
    )
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "List")
    XLSX.utils.sheet_add_aoa(worksheet, [collectionEntitiesFiltered[0]], {
      origin: "A1",
    })
    XLSX.writeFile(
      workbook,
      `${LANGUAGE_MAPPING[this.state?.selected]}-List.xlsx`
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.state.cardData && this.state.cardData.length > 0 && (
          <Card style={{ width: "100%" }}>
            <Row>
              <Col xl={5} lg={5} md={4} sm={12} xs={12}>
                <div
                  style={{
                    height: "55vh",
                    overflowY: "scroll",
                    marginLeft: "10px",
                  }}
                >
                  <div style={{ display: "flex", flexFlow: "wrap" }}>
                    {this.state.cardData.map((entity, i) => {
                      const [key] = Object.keys(entity)
                      if (key.split("_").length > 1) {
                        return this._renderCard(key, entity[key], "location", i)
                      }
                      return this._renderCard(key, entity[key], "language", i)
                    })}
                  </div>
                </div>
              </Col>
              <Col xl={7} lg={7} md={8} sm={12} xs={12}>
                {this.state.showModal && (
                  <div
                    style={{ margin: "1rem", marginTop: 0 }}
                    className="d-flex justify-content-between"
                  >
                    <div
                      style={{
                        fontWeight: "700",
                        marginBottom: "5px",
                        marginTop: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {this.state.showModal &&
                        this._getTitle(
                          this.state.selected,
                          this.state.cardType
                        )}
                    </div>
                    <MuiButton
                      variant="outlined"
                      startIcon={<MdDownload />}
                      // disabled={
                      //   !this.props?.csvData?.length || this.props.csvLoading
                      // }
                      onClick={this.handleDownloadExcel}
                    >
                      {" "}
                      List
                    </MuiButton>
                    <Button
                      color="primary"
                      onClick={() => this._reorderCollectionEntities()}
                    >
                      Done
                    </Button>
                  </div>
                )}
                <div
                  style={{
                    height: "55vh",
                    overflowY: "scroll",
                    marginLeft: "10px",
                  }}
                >
                  {this.state.showModal && this.state.selected && (
                    <>
                      {this._renderReorder(
                        this.state.selected,
                        this.state.entityData
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        )}
        {this.state.showEditModal && this._renderEditModal()}
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    refetch: _.get(store, "zeroSearch.refetch"),
  }
}

export default connect(mapStateToProps)(CollectionEntityCard)
