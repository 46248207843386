import Axios from "axios"
import toastr from "toastr"

export const requestGetIssueComments = async ({ payload }) => {
  try {
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"]
    payload["offset"] = offset
    payload["limit"] = limit
    _.unset(payload, "currentPage")
    _.unset(payload, "pageSize")
    let data = await Axios.post(
      `/api/comment/search?offset=${offset}&limit=${limit}`,
      payload
    )
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestCreateIssueComment = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/comment/create`, payload)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
