import Axios from "axios"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

export const getThemeficationList = async payload => {
  try {
    let { data } = await Axios.post(`/api/theme/list`, payload)

    return data?.result ? data.result : []
  } catch (error) {
    throw error
  }
}

export const upsertThemification = async payload => {
  try {
    toastr.info("Data saving in progress !!")
    let { data } = await Axios.post(`/api/theme/upsert`, payload)
    toastr.success("Data Saved successfully")
    return data?.result ? data.result : []
  } catch (error) {
    toastr.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "Error in Upsert theme"
    )
    throw error
  }
}
export const checkItems = async payload => {
  try {
    toastr.info("Data fetching in progress !!")
    let { data } = await Axios.post(`/api/theme/checkItem`, payload)
    toastr.success("Data fetched successfully")
    return data?.result ? data.result : []
  } catch (error) {
    toastr.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "Error in fetching items"
    )
    throw error
  }
}

export const deleteTheme = async payload => {
  try {
    toastr.info("Theme deleting in progress !!")
    let { data } = await Axios.post(`/api/theme/delete`, { themeName: payload })
    toastr.success("Theme Deleted successfully")
    return data?.result ? data.result : []
  } catch (error) {
    toastr.error("Error in Deleting theme")
    throw error
  }
}
