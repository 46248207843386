import { createSlice } from "@reduxjs/toolkit"

const sponsoredTagsSlice = createSlice({
  name: "sponseredTags",
  initialState: {
    brandList: [],
    loading: false,
    total: 0,
  },
  reducers: {
    getBrands(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getBrandsSuccess(state, { payload }) {
      return {
        ...state,
        brandList: payload.data.data && payload.data.data.brands,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        loading: false,
      }
    },
    upsertWhitelistedUser(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertWhitelistedUserSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    upsertMastheadContent(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertMastheadContentSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    resetState(state, action) {
      return {
        ...state,
        brandList: [],
        total: 0,
        loading: false,
      }
    },
  },
})

export const {
  getBrands,
  getBrandsSuccess,
  upsertWhitelistedUser,
  upsertWhitelistedUserSuccess,
  upsertMastheadContent,
  upsertMastheadContentSuccess,
  resetState,
} = sponsoredTagsSlice.actions

export default sponsoredTagsSlice.reducer
