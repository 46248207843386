import React from "react"
import { Upload, Select as AntSelect } from "antd"
import { Row, Col, Button } from "reactstrap"
import _ from "lodash"
import ValidatorForm, { TextField, Select, Switch } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import "./cameraAsset.style.css"
const { Option } = AntSelect

export default class CameraAssetUpsertForm extends ErrorBoundComponent {
  state = {
    formData: {},
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "thumbnail") {
        this.setState({
          staticImageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          staticImage: fileObj.file,
        })
      }
    }
  }

  _onImageRemove = type => {
    if (type === "thumbnail") {
      this.setState({
        staticImageList: [],
        staticImage: null,
      })
    } else
      this.setState({
        imageList: [],
        image: null,
      })
  }

  componentDidMount() {
    let cameraAsset = this.props.cameraAsset
    if (!_.isEmpty(cameraAsset)) {
      let formData = {
        title: cameraAsset.title,
        hashtag: cameraAsset.hashtag,
        filter_id: cameraAsset.filter_id,
        effect_id: cameraAsset.effect_id,
        mask_id: cameraAsset.mask_id,
        fuAssetIds: cameraAsset.fuAssetIds,
        audio_id: cameraAsset.audio_id,
        challenge_id: cameraAsset.challenge_id,
        duetable_video_id: cameraAsset.duetable_video_id,
        is_active: cameraAsset.is_active,
        music_playlist_id: cameraAsset.music_playlist_id,
        is_audio_fixed: cameraAsset.config
          ? cameraAsset.config.is_audio_fixed ?? false
          : false,
        is_filter_fixed: cameraAsset.config
          ? cameraAsset.config.is_filter_fixed ?? false
          : false,
        is_effects_fixed: cameraAsset.config
          ? cameraAsset.config.is_effects_fixed ?? false
          : false,
        is_mask_fixed: cameraAsset.config
          ? cameraAsset.config.is_mask_fixed ?? false
          : false,
        allow_gallery_upload: cameraAsset.config
          ? cameraAsset.config.allow_gallery_upload ?? false
          : false,
        is_dub_disabled: cameraAsset.config
          ? cameraAsset.config.is_dub_disabled ?? false
          : false,
        after_post_deeplink: cameraAsset.config
          ? cameraAsset.config.after_post_deeplink ?? ""
          : "",
      }
      this.setState({ formData })
    }
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    if (!_.isEmpty(value)) {
      formData[field] = value
      this.setState({ formData })
    }
  }

  _handleMultiSelect = value => {
    let formData = this.state.formData
    formData["fuAssetIds"] = value
    this.setState({ formData })
  }

  _handleSelect = (field, value) => {
    let formData = this.state.formData
    formData[field] = value
    this.setState({ formData })
  }

  _form = () => {
    const { faceUnityList = [], stickers = [], arGames = [] } = this.props
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col sm={12} lg={6}>
            <TextField
              label="Title"
              defaultValue={this.state.formData.title}
              placeholderLabel="Combo Title"
              field="title"
              allowClear={true}
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              onChange={value => this._handleFieldChange("title", value)}
            />
          </Col>

          <Col sm={12} lg={6}>
            <TextField
              label={"Hashtag"}
              field={"hashtag"}
              allowClear={true}
              placeholderLabel="hashtag"
              defaultValue={this.state.formData.hashtag}
            />
          </Col>
          <Col sm={12} lg={6}>
            <div style={{ paddingBottom: "5px", paddingTop: "2px" }}>
              Filter
            </div>
            <AntSelect
              label="Filter"
              style={{ width: "100%" }}
              placeholder="Filter Id"
              defaultValue={
                this.props.cameraAsset.filter_id
                  ? this.props.cameraAsset.filter_id
                  : []
              }
              onChange={val => this._handleSelect("filter_id", val)}
              optionLabelProp="label"
              optionFilterProp="children"
              showArrow
              showSearch
              size={"large"}
              field="filter_id"
              allowClear={true}
            >
              {this.props.filtersList.length > 0 &&
                this.props.filtersList.map(data => (
                  <selectOption value={data.id} label={data.name}>
                    <img src={data.coverUrl} className="thumnailImage" />
                    {data.name}
                  </selectOption>
                ))}
            </AntSelect>
            {/* <Select
              label={"Filter"}
              placeholderLabel="Filter Id"
              defaultValue={this.state.formData.filter_id}
              field="filter_id"
              allowClear={true}
              className="creator-form"
              options={this.props.filtersList}
              optionLabelProp="label"
              valueAttr="id"
              optionsRenderer={data => {
                return (
                  <Option
                    key={`filter_options_${data.id}`}
                    value={data.id}
                    label={data.name}
                  >
                    <img
                      key={"filter_img_" + data.id}
                      src={data.coverUrl}
                      className="thumnailImage"
                    />
                    {data.name}
                  </Option>
                )
              }}
            ></Select> */}
          </Col>
          <Col sm={12} lg={6}>
            <div style={{ paddingBottom: "5px", paddingTop: "2px" }}>
              Effect
            </div>
            <AntSelect
              label="Effect"
              style={{ width: "100%" }}
              placeholder="Effect Id"
              valueAttr="id"
              defaultValue={
                this.props.cameraAsset.effect_id
                  ? this.props.cameraAsset.effect_id
                  : []
              }
              onChange={val => this._handleSelect("effect_id", val)}
              optionLabelProp="label"
              optionFilterProp="children"
              showArrow
              showSearch
              size={"large"}
              field="effect_id"
              allowClear={true}
            >
              {this.props.effectsList.length > 0 &&
                this.props.effectsList.map(data => (
                  <selectOption value={data.id} label={data.name}>
                    <img src={data.coverUrl} className="thumnailImage" />
                    {data.name}
                  </selectOption>
                ))}
            </AntSelect>
            {/* <Select
              label={"Effect"}
              placeholderLabel="Effect Id"
              defaultValue={this.state.formData.effect_id}
              field="effect_id"
              allowClear={true}
              valueAttr="id"
              className="creator-form"
              options={this.props.effectsList}
              optionLabelProp="label"
              optionsRenderer={data => {
                return (
                  <Option
                    key={"effect_option_" + data.id}
                    value={data.id}
                    label={data.name}
                  >
                    <img
                      key={"effect_img_" + data.id}
                      src={data.coverUrl}
                      className="thumnailImage"
                    />
                    {data.name}
                  </Option>
                )
              }}
            /> */}
          </Col>
          <Col sm={12} lg={6} className="multi">
            <div style={{ paddingBottom: "5px", paddingTop: "2px" }}>Game</div>
            <AntSelect
              label="Game"
              style={{ width: "100%" }}
              placeholder="Game Id"
              defaultValue={
                this.props.cameraAsset.game_id
                  ? this.props.cameraAsset.game_id
                  : []
              }
              onChange={val => this._handleSelect("game_id", val)}
              optionLabelProp="label"
              optionFilterProp="children"
              showArrow
              showSearch
              size={"large"}
              field="game_id"
              allowClear={true}
            >
              {arGames.length > 0 &&
                arGames.map(data => (
                  <selectOption value={data.id} label={data.name}>
                    <img src={data.cover_url} className="thumnailImage" />
                    {data.name}
                  </selectOption>
                ))}
            </AntSelect>
          </Col>
          <Col sm={12} lg={6}>
            <div style={{ paddingBottom: "5px", paddingTop: "2px" }}>Mask</div>
            <AntSelect
              label="Mask"
              style={{ width: "100%" }}
              placeholder="Mask Id"
              defaultValue={
                this.props.cameraAsset.mask_id
                  ? this.props.cameraAsset.mask_id
                  : []
              }
              onChange={val => this._handleSelect("mask_id", val)}
              optionLabelProp="label"
              optionFilterProp="children"
              showArrow
              showSearch
              size={"large"}
              field="mask_id"
              allowClear={true}
            >
              {this.props.masksList.length > 0 &&
                this.props.masksList.map(data => (
                  <selectOption value={data.id} label={data.name}>
                    <img src={data.coverUrl} className="thumnailImage" />
                    {data.name}
                  </selectOption>
                ))}
            </AntSelect>
            {/* <Select
              label={"Mask"}
              placeholderLabel="Mask Id"
              field="mask_id"
              allowClear={true}
              className="creator-form"
              defaultValue={this.state.formData.mask_id}
              options={this.props.masksList}
              optionLabelProp="label"
              valueAttr="id"
              optionsRenderer={data => {
                return (
                  <Option
                    key={"mask_option_" + data.id}
                    value={data.id}
                    label={data.name}
                  >
                    <img
                      key={"mask_img_" + data.id}
                      src={data.coverUrl}
                      className="thumnailImage"
                    />
                    {data.name}
                  </Option>
                )
              }}
            /> */}
          </Col>
          <Col sm={12} lg={6} className="multi">
            <div style={{ paddingBottom: "5px", paddingTop: "2px" }}>
              Face Unity
            </div>
            <AntSelect
              label="Face Unity"
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Face Unity Id"
              defaultValue={
                this.props.cameraAsset.fuAssetIds
                  ? this.props.cameraAsset.fuAssetIds
                  : []
              }
              onChange={this._handleMultiSelect}
              optionLabelProp="label"
              optionFilterProp="children"
              showArrow
              showSearch
              size={"large"}
              field="fuAssetIds"
              allowClear={true}
            >
              {faceUnityList.length > 0 &&
                faceUnityList.map(data => (
                  <selectOption value={data.id} label={data.name}>
                    <img src={data.coverUrl} className="thumnailImage" />
                    {data.name}
                  </selectOption>
                ))}
            </AntSelect>
          </Col>
          <Col sm={12} lg={6} className="multi">
            <div style={{ paddingBottom: "5px", paddingTop: "2px" }}>
              Sticker
            </div>
            <AntSelect
              label="Sticker"
              style={{ width: "100%" }}
              placeholder="Sticker Id"
              defaultValue={
                this.props.cameraAsset.sticker_id
                  ? this.props.cameraAsset.sticker_id
                  : []
              }
              onChange={val => this._handleSelect("sticker_id", val)}
              optionLabelProp="label"
              optionFilterProp="children"
              showArrow
              showSearch
              size={"large"}
              field="sticker_id"
              allowClear={true}
            >
              {stickers.length > 0 &&
                stickers.map(data => (
                  <selectOption value={data.asset_id} label={data.sticker_name}>
                    <img
                      src={data.sticker_thumbnail_url}
                      className="thumnailImage"
                    />
                    {data.sticker_name}
                  </selectOption>
                ))}
            </AntSelect>
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label={"Audio"}
              field={"audio_id"}
              allowClear={true}
              placeholderLabel="Audio Id"
              defaultValue={this.state.formData.audio_id}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label={"Challenge"}
              field={"challenge_id"}
              allowClear={true}
              placeholderLabel="Challenge Id"
              defaultValue={this.state.formData.challenge_id}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label={"Duetable Video"}
              field={"duetable_video_id"}
              allowClear={true}
              placeholderLabel="Duetable Video Id"
              defaultValue={this.state.formData.duetable_video_id}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="After Post Deeplink"
              field="after_post_deeplink"
              allowClear={true}
              placeholderLabel="Enter deeplink here..."
              defaultValue={this.state.formData.after_post_deeplink}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="Music Playlist Id"
              field="music_playlist_id"
              allowClear={true}
              placeholderLabel="Music Playlist Id"
              defaultValue={this.state.formData.music_playlist_id}
            />
          </Col>
          <div className="d-flex mt-1">
            <Col sm={12} lg={2}>
              <Switch
                label={"Is Audio Fixed"}
                field={"is_audio_fixed"}
                defaultValue={this.state.formData.is_audio_fixed}
              />
            </Col>
            <Col sm={12} lg={2}>
              <Switch
                label={"Is Filter Fixed"}
                field={"is_filter_fixed"}
                defaultValue={this.state.formData.is_filter_fixed}
              />
            </Col>
            <Col sm={12} lg={2}>
              <Switch
                label={"Is Effects Fixed"}
                field={"is_effects_fixed"}
                defaultValue={this.state.formData.is_effects_fixed}
              />
            </Col>
            <Col sm={12} lg={2}>
              <Switch
                label={"Is Mask Fixed"}
                field={"is_mask_fixed"}
                defaultValue={this.state.formData.is_mask_fixed}
              />
            </Col>
            <Col sm={12} lg={2}>
              <Switch
                label={"Allow Gallery Upload"}
                field={"allow_gallery_upload"}
                defaultValue={this.state.formData.allow_gallery_upload}
              />
            </Col>
            <Col sm={12} lg={2}>
              <Switch
                label={"Is Dub Disabled"}
                field={"is_dub_disabled"}
                defaultValue={this.state.formData.is_dub_disabled}
              />
            </Col>
          </div>
          <div>
            <Col sm="12" md="6">
              <Row style={{ marginTop: "10px" }}>
                <Upload
                  showUploadList={true}
                  onRemove={() => this._onImageRemove("thumbnail")}
                  defaultFileList={this.state.staticImageList}
                  fileList={this.state.staticImageList}
                  customRequest={fileObj =>
                    this._uploadImage(fileObj, "thumbnail")
                  }
                >
                  <Button color="primary" onClick={() => {}}>
                    Upload Thumbnail
                  </Button>
                </Upload>
              </Row>
            </Col>
          </div>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            size="default"
            type="submit"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            {this.props.formAction === "create" ? "Create" : "Update"}
          </Button>
          <Button
            color="danger"
            size="default"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
            onClick={() => this.props.onCancel()}
            className="mx-2"
          >
            Cancel
          </Button>
        </div>
      </ValidatorForm>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }

    let formDataCopy = _.cloneDeep(formData)
    formDataCopy["config"] = {}

    formDataCopy["config"]["is_audio_fixed"] = formData["is_audio_fixed"]
    formDataCopy["config"]["is_filter_fixed"] = formData["is_filter_fixed"]
    formDataCopy["config"]["is_effects_fixed"] = formData["is_effects_fixed"]
    formDataCopy["config"]["is_mask_fixed"] = formData["is_mask_fixed"]
    formDataCopy["config"]["allow_gallery_upload"] =
      formData["allow_gallery_upload"]
    formDataCopy["config"]["after_post_deeplink"] =
      formData["after_post_deeplink"]
    formDataCopy["config"]["is_dub_disabled"] = formData["is_dub_disabled"]
    formDataCopy["fuAssetIds"] = this.state.formData["fuAssetIds"]
    formDataCopy["sticker_id"] = this.state.formData["sticker_id"]
    formDataCopy["game_id"] = this.state.formData["game_id"]
    formDataCopy["effect_id"] = this.state.formData["effect_id"]
    formDataCopy["mask_id"] = this.state.formData["mask_id"]
    formDataCopy["filter_id"] = this.state.formData["filter_id"]
    formDataCopy["is_active"] = true

    delete formDataCopy["is_audio_fixed"]
    delete formDataCopy["is_filter_fixed"]
    delete formDataCopy["is_effects_fixed"]
    delete formDataCopy["is_mask_fixed"]
    delete formDataCopy["allow_gallery_upload"]
    delete formDataCopy["is_dub_disabled"]

    if (this.props.formAction === "update") {
      formDataCopy["combo_id"] = this.props.cameraAsset.combo_id
    }

    let data = new FormData()
    data.append("details", JSON.stringify(formDataCopy))

    if (this.state.staticImage) {
      data.append("thumbnail", this.state.staticImage)
    }

    this.setState({
      actionLoading: true,
    })

    this.props.onSubmit(data)
  }

  _render = () => {
    return <Auxiliary loading={this.props.loading}>{this._form()}</Auxiliary>
  }
}
