import React, { Component } from "react"
import _ from "lodash"
import { connect } from "react-redux"
import { Result } from "antd"
import { resetError } from "appRedux/slices/common"
import logo from "../assets/images/loader.svg"

class Auxiliary extends Component {
  constructor(props) {
    super(props)
  }

  componentWillUnmount() {
    if (this.props.error && this.props.error != "") {
      this.props.dispatch(resetError())
    }
  }

  render() {
    const props = this.props
    if (props.error) {
      console.error(props.error)
      return (
        <Result
          status={_.get(props.error, "response.status") || 500}
          subTitle={
            _.get(props.error, "response.data.message") ||
            _.get(props.error, "message") ||
            "Something went wrong !!"
          }
        />
      )
    }
    if (props.loading) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: props?.height || "90vh" }}
        >
          <img src={logo} alt="" className="rounded-circle" height="50" />
        </div>
      )
    }
    return props.children
  }
}

export default connect(null)(Auxiliary)
