import React from "react"
import { Card, CardBody, Col, Table, Badge, Row, CardImg } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { size, map } from "lodash"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import CustomScrollbars from "util/CustomScrollbars"
import ReactCardFlip from "react-card-flip"
import ReactTimeAgo from "react-time-ago"
import "./style.css"

class ZoneCard extends React.Component {
  state = { isPlaying: false, isFlipped: false, isPlayedFirst: true }
  audioRef = React.createRef()

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  render() {
    const { zone, customClassname = "" } = this.props
    const enableEdit =
      this.props.currentUser?.permissions?.includes("EDIT_BROWSE_ZONE")
    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection={"horizontal"}
      >
        <Card
          className={`${customClassname} text-center shadow-lg`}
          style={{
            minHeight: "410px",
          }}
        >
          <div
            className="bg-primary bg-soft"
            onClick={() => {
              this.props.showDetails
                ? window.open(
                    `/zone/detail?pageType=video&zone_id=${zone.zone_uuid}`,
                    "_blank"
                  )
                : ""
            }}
            type={!enableEdit ? "" : "button"}
          >
            <Row>
              {zone && zone.zone_image ? (
                <div>
                  <CardImg
                    top
                    className="img-fluid"
                    src={zone.zone_image}
                    style={{
                      height: "15rem",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : (
                <div
                  className="py-5"
                  style={{
                    height: "15rem",
                    cursor: "pointer",
                    backgroundColor: "lightgray",
                  }}
                >
                  <i className="mdi mdi-image-area fa-7x py-4 text-secondary"></i>
                </div>
              )}
            </Row>
          </div>
          <CardBody style={{ padding: "1rem 0.25rem" }}>
            <Tooltip title={zone.title}>
              <div className="font-size-16 text-truncate font-weight-semibold">
                {zone.title}
              </div>
            </Tooltip>
            <div className="">
              {zone.target_languages && zone.target_languages.length > 0 ? (
                <Tooltip title="Target Languages">
                  {map(
                    zone.target_languages,
                    (language, index) =>
                      index < 2 && (
                        <Badge className="badge-soft-info ms-1" key={index}>
                          <div className="font-size-14">
                            {language.toUpperCase()}
                          </div>
                        </Badge>
                      )
                  )}
                  {size(zone.target_languages) > 2 && (
                    <Badge className="badge-soft-success ms-1">
                      <Tooltip
                        placement="right"
                        title={zone.target_languages
                          .slice(1, zone.target_languages.length)
                          .map(a => (
                            <div>{a}</div>
                          ))}
                      >
                        <div className="font-size-14">
                          +{size(zone.target_languages) - 2} more
                        </div>
                      </Tooltip>
                    </Badge>
                  )}
                </Tooltip>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
            <div className=" my-2" style={{ height: "20px" }}>
              <Badge className="badge-soft-primary ms-1 font-size-13">
                {zone.status}
              </Badge>
              {zone.level && (
                <Tooltip title="Level">
                  <Badge className="badge-soft-primary ms-1 font-size-13">
                    {zone.level == 0 ? "L0" : `L${zone.level}`}
                  </Badge>
                </Tooltip>
              )}
              {zone.created_date && (
                <Tooltip title="Created">
                  <Badge className="badge-soft-info ms-1 font-size-13">
                    <ReactTimeAgo
                      date={
                        Number.isNaN(zone.created_date)
                          ? Date.parse(zone.created_date)
                          : zone.created_date
                      }
                      locale="en-US"
                    />
                  </Badge>
                </Tooltip>
              )}
            </div>
          </CardBody>
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={zone.zone_uuid}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Zone ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <Tooltip title="Edit Zone">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_MANAGE_ZONE"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick(zone)
                  }}
                />
              </Tooltip>
            </div>
            <div className="flex-fill">
              <Tooltip title="Add Collection">
                <i
                  className="bx bx-plus"
                  role="button"
                  onClick={() => this.props.onAddCollection(zone.zone_uuid)}
                />
              </Tooltip>
            </div>
            <div className="flex-fill">
              <Tooltip title="Manage Entities">
                <i
                  className="bx bx-coin-stack"
                  role="button"
                  onClick={() => {
                    this.props.history.push(
                      `/manage/pageEntities?pageId=${zone.zone_uuid}&page=zone`
                    )
                  }}
                />
              </Tooltip>
            </div>
            <div className="flex-fill">
              <Tooltip title="View More">
                <i
                  className="bx bx-play"
                  role="button"
                  style={{ fontSize: "20px" }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ isFlipped: true })
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Card>
        <Card
          className={`${customClassname} text-center shadow-lg`}
          style={{
            height: "410px",
            borderRadius: "10px",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <CustomScrollbars>
            <CardBody className="px-0 py-0">
              <Row className="my-sm-2">
                <Col className="ms-2">
                  <div className="avatar-xs">
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-warning" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="bx bx-bar-chart" />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end me-2">
                  <div
                    className="avatar-xs"
                    role="button"
                    onClick={() => this.setState({ isFlipped: false })}
                  >
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-dark" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="fas fa-times-circle" />
                      </span>
                    </div>
                  </div>
                  <div></div>
                </Col>
              </Row>
              <Row>
                <div className="table-responsive">
                  <Table className="mb-0 table-striped">
                    <tbody>
                      <tr className="text-start">
                        <th scope="row">Description:</th>
                        <td className="break-word">
                          {zone.description || "-"}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Allow Follow</th>
                        <td>
                          {(zone.allow_follow &&
                            zone.allow_follow.toString()) ||
                            "-"}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Sub Title:</th>
                        <td>{zone.sub_title || "-"}</td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Hashtags:</th>
                        <td>
                          {(zone.hashtag &&
                            zone.hashtag.map((zones, index) => (
                              <span key={index}>
                                {zones}
                                {zone.hashtag.length > index + 1 ? ", " : ""}
                              </span>
                            ))) ||
                            "-"}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Enabled Tabs:</th>
                        <td>
                          {(zone.enabled_tabs &&
                            zone.enabled_tabs.map((zones, index) => (
                              <span key={index}>
                                {zones}
                                {zone.enabled_tabs.length > index + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))) ||
                            "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </CardBody>
          </CustomScrollbars>
        </Card>
      </ReactCardFlip>
    )
  }
}

export default ZoneCard
