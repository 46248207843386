export const contentFilters = {
  searchAfter: {
    filterName: "Search After",
    defaultValue: null,
  },
  verifiedProfiles: {
    filterName: "Verified Profiles",
    defaultValue: "",
  },
  contentType: {
    filterName: "Content Type",
    defaultValue: "",
  },
  searchText: {
    filterName: "Search Text",
    defaultValue: "",
  },
  searchType: {
    filterName: "Search Type",
    defaultValue: "content_uuid",
    valueMap: {
      content_uuid: "Content UUID",
      created_by: "User UUID",
      title: "Title",
      tag: "Tag",
      created_day: "Created Day",
      reject_reason: "Reject Reason",
      target_category: "Approve Reason",
      target_category_v2: "Target Category",
      reprocessed_moderation: "ReProcessed Moderation Level",
    },
  },
  audioId: {
    filterName: "Audio ID",
    defaultValue: "",
  },
  clusteredAudioId: {
    filterName: "Clustered Audio ID",
    defaultValue: "",
  },
  sourcePlatform: {
    filterName: "Source Platfrom",
    defaultValue: "",
  },
  sourceUrl: {
    filterName: "Source URL",
    defaultValue: "",
  },
  glamourGrade: {
    filterName: "Glamour Grade",
    defaultValue: "",
    valueMap: {
      "": "All",
      a: "Grade A",
      b: "Grade B",
      c: "Grade C",
    },
  },
  glamourRegion: {
    filterName: "Glamour Region",
    defaultValue: "",
    valueMap: {
      "": "All",
      indian: "Indian",
      international_fair: "International Fair",
      international_black: "International Black",
      south_asian_chinese: "South Asian / Chinese",
    },
  },
  glamourLevel: {
    filterName: "Glamour Level",
    defaultValue: "",
    valueMap: {
      "": "All",
      pretty_girl: "G1 - Pretty Girl",
      glamorous: "G2 - Glamourous",
      classy_hot_sexy: "G3 - Classy hot sexy",
      masala: "G4 - Masala",
      vulgar_masala: "G5 - Vulgar masala",
      acceptable_child_nudity: "G6 - Acceptable child nudity",
      partial_adult_nudity: "G7 - Partial adult nudity",
    },
  },
  moderationLevel: {
    filterName: "Moderation Level",
    defaultValue: "",
    valueMap: {
      "": "All",
      3: "Auto Approved",
      0: "Auto Disabled",
      2: "Human Approved",
      "-1": "Human Disabled",
      1: "Unmoderated",
    },
  },
  rangeSearchType: {
    filterName: "Range Search Type",
    defaultValue: null,
    valueMap: {
      review_bucket: "Bucket",
      download_count: "Download Count",
      like_count: "Like Count",
      share_count: "Share Count",
      view_count: "View Count",
      video_duration: "Video Duration",
      "taxonomy_meta.video_quality_attributes.rating": "Taxonomy Rating",
    },
  },
  opSearchType: {
    filterName: "Range Search Operator",
    defaultValue: null,
    valueMap: {
      bt: "Between",
      eq: "Equal to",
      gt: "Greater Than",
      lt: "Less Than",
    },
  },
  rangeSearchText: {
    filterName: "Range Search Text",
    defaultValue: null,
  },
  rangeSearchFrom: {
    filterName: "Range Search From",
    defaultValue: null,
  },
  rangeSearchTo: {
    filterName: "Range Search To",
    defaultValue: null,
  },
  createdDateFrom: {
    filterName: "Created Date From",
    defaultValue: null,
  },
  createdDateTo: {
    filterName: "Created Date To",
    defaultValue: null,
  },
  orderByField: {
    filterName: "Order By",
    defaultValue: "view_count",
    valueMap: {
      view_count: "View Count",
      share_count: "Share Count",
      download_count: "Download Count",
      like_count: "Like Count",
      created_date: "Created Date",
      t_score_v2: "T Score V2",
      qc_order: "QC Order",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
  sourceType: {
    filterName: "Source Type",
    defaultValue: "non_ads",
    valueMap: {
      "": "All Content",
      ads: "Ads Content",
      non_ads: "Non Ads Content",
    },
  },
  inputZone: {
    filterName: "Input Zone",
    defaultValue: "",
  },
  activeZone: {
    filterName: "Active Zone",
    defaultValue: "",
  },
  type: {
    filterName: "Type",
    defaultValue: "",
  },
  includeLang: {
    filterName: "Include Languages",
    defaultValue: "",
    valueMap: {
      all: "All",
      bn: "Bangala",
      bh: "Bhojpuri",
      en: "English",
      gu: "Gujarati",
      kn: "Kannada",
      hi: "Hindi",
      mr: "Marathi",
      ml: "Malayalam",
      or: "Odiya",
      pa: "Punjabi",
      ta: "Tamil",
      te: "Telugu",
    },
  },
  excludeLang: {
    filterName: "Exclude Languages",
    defaultValue: "",
    valueMap: {
      all: "All",
      bn: "Bangala",
      bh: "Bhojpuri",
      en: "English",
      gu: "Gujarati",
      kn: "Kannada",
      hi: "Hindi",
      mr: "Marathi",
      ml: "Malayalam",
      or: "Odiya",
      pa: "Punjabi",
      ta: "Tamil",
      te: "Telugu",
    },
  },
  bucket: {
    filterName: "Bucket",
    defaultValue: "-1",
  },
  maskedContent: {
    filterName: "Masked Content",
    defaultValue: "",
  },
  taggedContent: {
    filterName: "Tagged Content",
    defaultValue: "",
    valueMap: {
      Y: "Yes",
      N: "No",
    },
  },
  trendingContent: {
    filterName: "Trending Content",
    defaultValue: "",
    valueMap: {
      Y: "Yes",
      N: "No",
    },
  },
  isAssetUsed: {
    filterName: "Asset Used",
    defaultValue: "",
    valueMap: {
      true: "Yes",
      false: "No",
    },
  },
  videoSource: {
    filterName: "Video Source",
    defaultValue: "",
  },
  qcDone: {
    filterName: "QC Done",
    defaultValue: "",
  },
  shoppableContent: {
    filterName: "Shoppable Content",
    defaultValue: "",
    valueMap: {
      Y: "Yes",
      N: "No",
    },
  },
  shoppableContent: {
    filterName: "Duplicate Content",
    defaultValue: "",
    valueMap: {
      Y: "Yes",
      N: "No",
    },
  },
  duetable: {
    filterName: "Duetable Content",
    defaultValue: "",
    valueMap: {
      Y: "Yes",
      N: "No",
    },
  },
  reviewDone: {
    filterName: "Review Done",
    defaultValue: "",
  },
  rating: {
    filterName: "Rating",
    defaultValue: "",
  },
  trendingEntityId: {
    filterName: "Trending Entity Id",
    defaultValue: "",
  },
  pageSize: {
    filterName: "Page Size",
    defaultValue: 10,
  },
  acrMetaCreatedDateFrom: {
    filterName: "ACR Meta Created Date From",
    defaultValue: null,
  },
  acrMetaCreatedDateTo: {
    filterName: "ACR Meta Created Created Date To",
    defaultValue: null,
  },
  blockedReason: {
    filterName: "Blocked Reason",
    defaultValue: null,
  },
  acrMetaLabel: {
    filterName: "ACT Meta Label",
    defaultValue: null,
  },
}

export const blockedContentFilters = {
  acrMetaCreatedDateFrom: {
    filterName: "ACR Meta Created Date From",
    defaultValue: null,
  },
  acrMetaCreatedDateTo: {
    filterName: "ACR Meta Created Created Date To",
    defaultValue: null,
  },
  blockedReason: {
    filterName: "Blocked Reason",
    defaultValue: null,
  },
  acrMetaLabel: {
    filterName: "ACT Meta Label",
    defaultValue: null,
  },
}

export const joshPaymentAudit = {}

export const taxonomyFilters = {
  contentUUID: {
    filterName: "Content ID",
    defaultValue: null,
  },
  deskType: {
    filterName: "Desk Type",
    defaultValue: "",
    valueMap: {
      LANGUAGE_DESK: "Language Desk",
      QC_DESK: "QC Desk",
      INTERNAL_QC_DESK: "Internal QC Desk",
    },
  },
  moderationLevel: {
    filterName: "Moderation Level",
    defaultValue: "",
    valueMap: {
      "": "All",
      3: "Auto Approved",
      0: "Auto Disabled",
      2: "Human Approved",
      "-1": "Human Disabled",
      1: "Unmoderated",
    },
  },
  rangeSearchType: {
    filterName: "Range Search Type",
    defaultValue: null,
    valueMap: {
      review_bucket: "Bucket",
      download_count: "Download Count",
      like_count: "Like Count",
      share_count: "Share Count",
      view_count: "View Count",
      video_duration: "Video Duration",
      "taxonomy_meta.video_quality_attributes.rating": "Taxonomy Rating",
    },
  },
  opSearchType: {
    filterName: "Range Search Operator",
    defaultValue: null,
    valueMap: {
      bt: "Between",
      eq: "Equal to",
      gt: "Greater Than",
      lt: "Less Than",
    },
  },
  rangeSearchText: {
    filterName: "Range Search Text",
    defaultValue: null,
  },
  rangeSearchFrom: {
    filterName: "Range Search From",
    defaultValue: null,
  },
  rangeSearchTo: {
    filterName: "Range Search To",
    defaultValue: null,
  },
  createdDateFrom: {
    filterName: "Created Date From",
    defaultValue: null,
  },
  createdDateTo: {
    filterName: "Created Date To",
    defaultValue: null,
  },
  orderByField: {
    filterName: "Order By",
    defaultValue: "view_count",
    valueMap: {
      view_count: "View Count",
      share_count: "Share Count",
      download_count: "Download Count",
      like_count: "Like Count",
      created_date: "Created Date",
      t_score_v2: "T Score V2",
      qc_order: "QC Order",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
  sourceType: {
    filterName: "Source Type",
    defaultValue: "non_ads",
    valueMap: {
      "": "All Content",
      ads: "Ads Content",
      non_ads: "Non Ads Content",
    },
  },
  langCode: {
    filterName: "Language",
    defaultValue: "",
    valueMap: {
      bn: "Bangala",
      bh: "Bhojpuri",
      en: "English",
      gu: "Gujarati",
      kn: "Kannada",
      hi: "Hindi",
      mr: "Marathi",
      ml: "Malayalam",
      or: "Odiya",
      pa: "Punjabi",
      ta: "Tamil",
      te: "Telugu",
    },
  },
  bucket: {
    filterName: "Bucket",
    defaultValue: "-1",
  },
  maskedContent: {
    filterName: "Masked Content",
    defaultValue: "",
  },
  taggedContent: {
    filterName: "Tagged Content",
    defaultValue: "",
    valueMap: {
      Y: "Yes",
      N: "No",
    },
  },
  trendingContent: {
    filterName: "Trending Content",
    defaultValue: "",
    valueMap: {
      Y: "Yes",
      N: "No",
    },
  },
  isAssetUsed: {
    filterName: "Asset Used",
    defaultValue: "",
    valueMap: {
      true: "Yes",
      false: "No",
    },
  },
  videoSource: {
    filterName: "Video Source",
    defaultValue: "",
  },
  qcDone: {
    filterName: "QC Done",
    defaultValue: "",
  },
  shoppableContent: {
    filterName: "Shoppable Content",
    defaultValue: "",
    valueMap: {
      Y: "Yes",
      N: "No",
    },
  },
  reviewDone: {
    filterName: "Review Done",
    defaultValue: "",
  },
  rating: {
    filterName: "Rating",
    defaultValue: "",
  },
  trendingEntityId: {
    filterName: "Trending Entity Id",
    defaultValue: "",
  },
  pageSize: {
    filterName: "Page Size",
    defaultValue: 10,
  },
}

export const userContentFilters = {
  moderationLevel: {
    filterName: "Moderation Level",
    defaultValue: "",
    valueMap: {
      "": "All",
      3: "Auto Approved",
      0: "Auto Disabled",
      2: "Human Approved",
      "-1": "Human Disabled",
      1: "Unmoderated",
    },
  },
  searchType: {
    filterName: "Search Type",
    defaultValue: "content_uuid",
    valueMap: {
      content_uuid: "Content UUID",
      created_by: "User UUID",
      title: "Title",
      tag: "Tag",
      created_day: "Created Day",
      reject_reason: "Reject Reason",
      target_category: "Approve Reason",
      target_category_v2: "Target Category",
      reprocessed_moderation: "ReProcessed Moderation Level",
    },
  },
  searchText: {
    filterName: "Search Text",
    defaultValue: "",
  },
  orderByField: {
    filterName: "Order By",
    defaultValue: "view_count",
    valueMap: {
      view_count: "View Count",
      share_count: "Share Count",
      download_count: "Download Count",
      like_count: "Like Count",
      created_date: "Created Date",
      t_score_v2: "T Score V2",
      qc_order: "QC Order",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
  langCode: {
    filterName: "Language",
    defaultValue: "",
    valueMap: {
      bn: "Bangala",
      bh: "Bhojpuri",
      en: "English",
      gu: "Gujarati",
      kn: "Kannada",
      hi: "Hindi",
      mr: "Marathi",
      ml: "Malayalam",
      or: "Odiya",
      pa: "Punjabi",
      ta: "Tamil",
      te: "Telugu",
    },
  },
}

export const zoneContentFilters = {
  filterView: {
    filterName: "View",
    defaultValue: false,
    valueMap: {
      true: "Filter View",
      false: "App View",
    },
  },
  moderationLevel: {
    filterName: "Moderation Level",
    defaultValue: "",
    valueMap: {
      "": "All",
      3: "Auto Approved",
      0: "Auto Disabled",
      2: "Human Approved",
      "-1": "Human Disabled",
      1: "Unmoderated",
    },
  },
  source: {
    filterName: "Source",
    defaultValue: "",
  },
  viewsGt: {
    filterName: "View Count From",
    defaultValue: null,
  },
  viewsLt: {
    filterName: "View Count To",
    defaultValue: null,
  },
  addDateGt: {
    filterName: "Addition Date From",
    defaultValue: null,
  },
  addDateLt: {
    filterName: "Addition Date To",
    defaultValue: null,
  },
  sort: {
    filterName: "Order By",
    defaultValue: "view_count",
    valueMap: {
      view_count: "View Count",
      created_date: "Created Date",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
  langCode: {
    filterName: "Language",
    defaultValue: "hi",
    valueMap: {
      bn: "Bangala",
      bh: "Bhojpuri",
      en: "English",
      gu: "Gujarati",
      kn: "Kannada",
      hi: "Hindi",
      mr: "Marathi",
      ml: "Malayalam",
      or: "Odiya",
      pa: "Punjabi",
      ta: "Tamil",
      te: "Telugu",
    },
  },
}

export const zoneImageFilters = {
  filterView: {
    filterName: "View",
    defaultValue: false,
    valueMap: {
      true: "Filter View",
      false: "App View",
    },
  },
  moderationLevel: {
    filterName: "Moderation Level",
    defaultValue: "",
    valueMap: {
      "": "All",
      3: "Auto Approved",
      0: "Auto Disabled",
      2: "Human Approved",
      "-1": "Human Disabled",
      1: "Unmoderated",
    },
  },
  source: {
    filterName: "Source",
    defaultValue: "",
  },
  viewsGt: {
    filterName: "View Count From",
    defaultValue: null,
  },
  viewsLt: {
    filterName: "View Count To",
    defaultValue: null,
  },
  addDateGt: {
    filterName: "Addition Date From",
    defaultValue: null,
  },
  addDateLt: {
    filterName: "Addition Date To",
    defaultValue: null,
  },
  sort: {
    filterName: "Order By",
    defaultValue: "view_count",
    valueMap: {
      view_count: "View Count",
      created_date: "Created Date",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
  langCode: {
    filterName: "Language",
    defaultValue: "hi",
    valueMap: {
      bn: "Bangala",
      bh: "Bhojpuri",
      en: "English",
      gu: "Gujarati",
      kn: "Kannada",
      hi: "Hindi",
      mr: "Marathi",
      ml: "Malayalam",
      or: "Odiya",
      pa: "Punjabi",
      ta: "Tamil",
      te: "Telugu",
    },
  },
}

export const zoneSocialFilters = {
  filterView: {
    filterName: "View",
    defaultValue: false,
    valueMap: {
      true: "Filter View",
      false: "App View",
    },
  },
  moderationLevel: {
    filterName: "Moderation Level",
    defaultValue: "",
    valueMap: {
      "": "All",
      3: "Auto Approved",
      0: "Auto Disabled",
      2: "Human Approved",
      "-1": "Human Disabled",
      1: "Unmoderated",
    },
  },
  source: {
    filterName: "Source",
    defaultValue: "",
  },
  viewsGt: {
    filterName: "View Count From",
    defaultValue: null,
  },
  viewsLt: {
    filterName: "View Count To",
    defaultValue: null,
  },
  addDateGt: {
    filterName: "Addition Date From",
    defaultValue: null,
  },
  addDateLt: {
    filterName: "Addition Date To",
    defaultValue: null,
  },
  sort: {
    filterName: "Order By",
    defaultValue: "view_count",
    valueMap: {
      view_count: "View Count",
      created_date: "Created Date",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
  langCode: {
    filterName: "Language",
    defaultValue: "hi",
    valueMap: {
      bn: "Bangala",
      bh: "Bhojpuri",
      en: "English",
      gu: "Gujarati",
      kn: "Kannada",
      hi: "Hindi",
      mr: "Marathi",
      ml: "Malayalam",
      or: "Odiya",
      pa: "Punjabi",
      ta: "Tamil",
      te: "Telugu",
    },
  },
}

export const cmsUserFilters = {
  searchType: {
    filterName: "Search Type",
    defaultValue: "email",
  },
  searchText: {
    filterName: "Search Text",
    defaultValue: "",
  },
  userStatus: {
    filterName: "User Status",
    defaultValue: "",
  },
  agency: {
    filterName: "Agency",
    defaultValue: "",
  },
  jotUserType: {
    filterName: "User Type",
    defaultValue: "",
  },
  permissions: {
    filterName: "Permissions",
    defaultValue: "",
  },
}

export const agencyFilters = {
  agency: {
    filterName: "Agency",
    defaultValue: "",
  },
  type: {
    filterName: "Type",
    defaultValue: "",
  },
  language: {
    filterName: "Language",
    defaultValue: "",
  },
}

export const embeddingFilters = {
  clusterId: {
    filterName: "Cluster Id",
    defaultValue: "",
  },
}
export const issueListFilter = {
  issueId: {
    filterName: "Issue Id",
    defaultValue: "",
  },
  userUuid: {
    filterName: "User Uuid",
    defaultValue: "",
  },
  contentUuid: {
    filterName: "Content Uuid",
    defaultValue: "",
  },
  status: {
    filterName: "Issue Status",
    defaultValue: "",
  },
  issueSeverity: {
    filterName: "Issue Severity",
    defaultValue: "",
  },
  reportedBy: {
    filterName: "Reported By",
    defaultValue: "",
  },
  assignedTo: {
    filterName: "Assigned To",
    defaultValue: "",
  },
  createdDateFrom: {
    filterName: "Created On",
    defaultValue: null,
  },
  createdDateTo: {
    filterName: "Created On",
    defaultValue: null,
  },
  modifiedDateFrom: {
    filterName: "Modified On",
    defaultValue: null,
  },
  modifiedDateTo: {
    filterName: "Modified On",
    defaultValue: null,
  },
  title: {
    filterName: "Title",
    defaultValue: "",
  },
}

export const bulkFilters = {
  searchText: {
    filterName: "Search Text",
    defaultValue: "",
  },
  searchType: {
    filterName: "Search Type",
    defaultValue: "uploadedBy",
  },
  status: {
    filterName: "Status",
    defaultValue: "",
  },
  createdDateFrom: {
    filterName: "Created Date From",
    defaultValue: null,
  },
  createdDateTo: {
    filterName: "Created Date To",
    defaultValue: null,
  },
  orderByField: {
    filterName: "Order By",
    defaultValue: "updatedOn",
    valueMap: {
      updatedOn: "Updated On",
      createdOn: "Created On",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
}

export const discoveryPageFilters = {
  pageId: {
    filterName: "Page Id",
    defaultValue: "",
  },
  title: {
    filterName: "Title",
    defaultValue: "",
  },
  status: {
    filterName: "Status",
    defaultValue: "",
  },
  pageType: {
    filterName: "Page Type",
    defaultValue: "",
  },
}

export const cameraAssetFilters = {
  comboId: {
    filterName: "Combo Id",
    defaultValue: "",
  },
  title: {
    filterName: "Title",
    defaultValue: "",
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
}

export const audioFilters = {
  searchTypeArtist: {
    filterName: "Search Type Artist",
    defaultValue: "artist_id",
    valueMap: {
      artist_id: "Artist ID",
      artist_name: "Artist Name",
    },
  },
  searchTypeLabel: {
    filterName: "Search Type Label",
    defaultValue: "label_id",
    valueMap: {
      label_id: "Label ID",
      label_name: "Label Name",
    },
  },
  searchTypeAlbum: {
    filterName: "Search Type Album",
    defaultValue: "album_id",
    valueMap: {
      album_id: "Album ID",
      album_name: "Album Name",
    },
  },
  uploadedBy: {
    filterName: "Uploaded By",
    defaultValue: "",
  },
  orderByField: {
    filterName: "Order By",
    defaultValue: "created_date",
    valueMap: {
      modified_date: "Modified Date",
      na: "N/A",
      created_date: "Created Date",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
  audioId: {
    filterName: "Audio Id",
    defaultValue: "",
  },
  clusteredAudioId: {
    filterName: "Clustered Audio ID",
    defaultValue: "",
  },
  categories: {
    filterName: "Categories",
    defaultValue: [],
  },
  languages: {
    filterName: "Language",
    defaultValue: [],
  },
  title: {
    filterName: "Title",
    defaultValue: "",
  },
  sponsored: {
    filterName: "Sponsored",
    defaultValue: "N",
  },
  artist: {
    filterName: "artist",
    defaultValue: "",
  },
  acrMetaCreatedDateFrom: {
    filterName: "ACR Meta Created Date From",
    defaultValue: null,
  },
  acrMetaCreatedDateTo: {
    filterName: "ACR Meta Created Created Date To",
    defaultValue: null,
  },
  blockedReason: {
    filterName: "Blocked Reason",
    defaultValue: null,
  },
  acrMetaLabel: {
    filterName: "ACT Meta Label",
    defaultValue: null,
  },
}

export const imageFilters = {
  imageId: {
    filterName: "Image Id",
    defaultValue: "",
  },
  userUUID: {
    filterName: "User UUID",
    defaultValue: "",
  },
  title: {
    filterName: "Title",
    defaultValue: "",
  },
  ce_type: {
    filterName: "Type",
    defaultValue: "",
  },
  sourceType: {
    filterName: "Source Type",
    defaultValue: "",
  },
  langCode: {
    filterName: "Language",
    defaultValue: "",
  },
  isActive: {
    filterName: "Is Active",
    defaultValue: "",
  },
  moderationLevel: {
    filterName: "Moderation Level",
    defaultValue: "",
  },
  inputZone: {
    filterName: "Input Zone",
    defaultValue: "",
  },
  activeZone: {
    filterName: "Active Zone",
    defaultValue: "",
  },
}
export const zoneFilters = {
  zoneId: {
    filterName: "Zone Id",
    defaultValue: "",
  },
  title: {
    filterName: "Title",
    defaultValue: "",
  },
  subType: {
    filterName: "Sub-Type",
    defaultValue: "",
  },
  type: {
    filterName: "Type",
    defaultValue: "",
  },
  status: {
    filterName: "Status",
    defaultValue: "",
  },
  // subTitle: {
  //   filterName: "Sub Title",
  //   defaultValue: "",
  // },
  langCode: {
    filterName: "Language",
    defaultValue: "",
  },
  allowFollow: {
    filterName: "Allow Follow",
    defaultValue: "",
  },
  level: {
    filterName: "Level",
    defaultValue: "",
  },
  zoneType: {
    filterName: "Zone Type",
    defaultValue: "",
  },
}

export const templateSectionFilters = {
  sectionName: {
    filterName: "Section Name",
    defaultValue: "",
  },
  sectionId: {
    filterName: "Section Id",
    defaultValue: "",
  },
  isActive: {
    filterName: "Status",
    defaultValue: "",
  },
}

export const memeFilters = {
  id: {
    filterName: "Id",
    defaultValue: "",
  },
}

export const listingsFilters = {
  id: {
    filterName: "Id",
    defaultValue: "",
  },
  // langCode: {
  //   filterName: "Language",
  //   defaultValue: "",
  // },
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  genre: {
    filterName: "Genre",
    defaultValue: "",
  },
}

export const socialFilters = {
  socialId: {
    filterName: "Social Id",
    defaultValue: "",
  },
  title: {
    filterName: "Title",
    defaultValue: "",
  },
  userUUID: {
    filterName: "User UUID",
    defaultValue: "",
  },
  ce_type: {
    filterName: "Type",
    defaultValue: "",
  },
  isActive: {
    filterName: "Is Active",
    defaultValue: "",
  },
  embedType: {
    filterName: "Embed Type",
    defaultValue: "",
  },
  langCode: {
    filterName: "Language",
    defaultValue: "",
  },
  format: {
    filterName: "Format",
    defaultValue: "",
  },
  inputZone: {
    filterName: "Input Zone",
    defaultValue: "",
  },
  activeZone: {
    filterName: "Active Zone",
    defaultValue: "",
  },
}

export const commentFilters = {
  searchType: {
    filterName: "Search Type",
    defaultValue: "id",
  },
  searchText: {
    filterName: "Search Text",
    defaultValue: null,
  },
  userStatus: {
    filterName: "User Status",
    defaultValue: "",
  },
  moderationLevel: {
    filterName: "Moderation Level",
    defaultValue: "",
  },
  moderationLevel: {
    filterName: "Moderation Level",
    defaultValue: "",
    valueMap: {
      "": "All",
      3: "Auto Approved",
      0: "Auto Disabled",
      2: "Human Approved",
      "-1": "Human Disabled",
      1: "Unmoderated",
    },
  },
  rangeSearchType: {
    filterName: "Range Search Type",
    defaultValue: null,
    valueMap: {
      review_bucket: "Bucket",
      download_count: "Download Count",
      like_count: "Like Count",
      share_count: "Share Count",
      view_count: "View Count",
      video_duration: "Video Duration",
    },
  },
  opSearchType: {
    filterName: "Range Search Operator",
    defaultValue: null,
    valueMap: {
      bt: "Between",
      eq: "Equal to",
      gt: "Greater Than",
      lt: "Less Than",
    },
  },
  rangeSearchText: {
    filterName: "Range Search Text",
    defaultValue: null,
  },
  rangeSearchFrom: {
    filterName: "Range Search From",
    defaultValue: null,
  },
  rangeSearchTo: {
    filterName: "Range Search To",
    defaultValue: null,
  },
  createdDateFrom: {
    filterName: "Created Date From",
    defaultValue: null,
  },
  createdDateTo: {
    filterName: "Created Date To",
    defaultValue: null,
  },
  orderByField: {
    filterName: "Order By",
    defaultValue: "view_count",
    valueMap: {
      view_count: "View Count",
      share_count: "Share Count",
      download_count: "Download Count",
      like_count: "Like Count",
      created_date: "Created Date",
      t_score_v2: "T Score V2",
      qc_order: "QC Order",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
}

export const hashtagFilters = {
  name: {
    filterName: "Hashtag Name",
    defaultValue: "",
  },
}

export const entityFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
}

export const effectFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  type: {
    filterName: "Type",
    defaultValue: "",
  },
}

export const templateFilters = {
  templateId: {
    filterName: "Template ID",
    defaultValue: "",
  },
  label: {
    filterName: "Label",
    defaultValue: "",
  },
  category: {
    filterName: "Category",
    defaultValue: "",
  },
}

export const stickersForSearchAndSelectFilters = {
  mimeType: {
    filterName: "Mime Type",
    defaultValue: "animatedsticker",
  },
  isActive: {
    filterName: "Is Active",
    defaultValue: true,
  },
  name: {
    filterName: "Sticker Name",
    defaultValue: "",
  },
}

export const communityLeaderboardFilters = {
  podLeader: {
    filterName: "Pod Leader",
    defaultValue: "",
  },
  podAM: {
    filterName: "Pod AM",
    defaultValue: "",
  },
  pod: {
    filterName: "Pod",
    defaultValue: true,
  },
  langCode: {
    filterName: "Language",
    defaultValue: "",
  },
  paidType: {
    filterName: "Paid Type",
    defaultValue: "",
  },
  rankSegment: {
    filterName: "Rank Segment",
    defaultValue: "",
  },
  pageSize: {
    filterName: "Page Size",
    defaultValue: 10,
  },
}

export const brandLogoFilters = {
  campaignUUID: {
    filterName: "Campaign UUID",
    defaultValue: null,
  },
  brandUUID: {
    filterName: "Brand UUID",
    defaultValue: null,
  },
  campaignName: {
    filterName: "Campaign Name",
    defaultValue: "",
  },
}

export const kycDashFilters = {
  userUuid: {
    filterName: "User UUID",
    defaultValue: null,
  },
  kycNumber: {
    filterName: "KYC Number",
    defaultValue: null,
  },
}

export const panKycFilters = {
  kycNumber: {
    filterName: "KYC Number",
    defaultValue: null,
  },
  userUuid: {
    filterName: "User UUID",
    defaultValue: null,
  },
  status: {
    filterName: "Status",
    defaultValue: "",
  },
}
export const curationFilters = {
  contentUuid: {
    filterName: "Content Id",
    defaultValue: null,
  },
  action: {
    filterName: "Action",
    defaultValue: null,
  },
  langCode: {
    filterName: "Language",
    defaultValue: "",
  },
  interest: {
    filterName: "Interest",
    defaultValue: "",
  },
  is_seed_video: {
    filterName: "Seed Video",
    defaultValue: "",
  },
}

export const trendingFilters = {
  audioId: {
    filterName: "Audio Id",
    defaultValue: "",
  },
  status: {
    filterName: "Status",
    defaultValue: [],
  },
  hashtag: {
    filterName: "hashtag",
    defaultValue: [],
  },
  trendingEntityId: {
    filterName: "Entity Id",
    defaultValue: "",
  },
}

export const challengeFilters = {
  searchType: {
    filterName: "Search Type",
    defaultValue: "",
  },
  searchText: {
    filterName: "Search Text",
    defaultValue: "",
  },
  type: {
    filterName: "Type",
    defaultValue: "",
  },
  status: {
    filterName: "Status",
    defaultValue: "",
  },
  sponsored: {
    filterName: "Sponsered",
    defaultValue: "N",
  },
}

export const storiesFilters = {
  storyId: {
    filterName: "Story Id",
    defaultValue: "",
  },
}

export const seriesFilters = {
  seriesId: {
    filterName: "Series Id",
    defaultValue: "",
  },
}

export const audioStoriesCollectionFilters = {
  colectionId: {
    filterName: "Collection Id",
    defaultValue: "",
  },
}

export const cameraAssetTemplateFilters = {
  label: {
    filterName: "Label",
    defaultValue: "",
  },
  isProcessed: {
    filterName: "Is Processed",
    defaultValue: "",
    valueMap: {
      true: "True",
      false: "False",
    },
  },
}

export const manageBannerFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  bannerId: {
    filterName: "Banner Id",
    defaultValue: "",
  },
}

export const manageChipsFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  chipsId: {
    filterName: "Chips Id",
    defaultValue: "",
  },
}

export const manageMusicArtistFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  artistId: {
    filterName: "Artist Id",
    defaultValue: "",
  },
}

export const manageMusicLabelFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  labelId: {
    filterName: "Label Id",
    defaultValue: "",
  },
}

export const manageMusicPlaylistFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  playListId: {
    filterName: "Playlist Id",
    defaultValue: "",
  },
}

export const manageNamedCollectionFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  collectionId: {
    filterName: "Collection Id",
    defaultValue: "",
  },
}

export const manageGiftsFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  giftId: {
    filterName: "Gift Id",
    defaultValue: "",
  },
}

export const packageFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  packageId: {
    filterName: "Package Id",
    defaultValue: "",
  },
}

export const packageCollectionFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  packageCollectionId: {
    filterName: "Package Collection Id",
    defaultValue: "",
  },
}

export const manageGiftCollectionsFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  giftCollectionId: {
    filterName: "Gift Collections Id",
    defaultValue: "",
  },
}

export const manageGiftCollectionEntityFilters = {
  giftCollectionEntityId: {
    filterName: "Gift Collection Entity Id",
    defaultValue: "",
  },
}

export const packageCollectionEntityFilters = {
  packageCollectionEntityId: {
    filterName: "Package Collection Entity Id",
    defaultValue: "",
  },
}

export const manageWebGamesFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  webGameId: {
    filterName: "Web-Game Id",
    defaultValue: "",
  },
}

export const manageWebviewBannerFilters = {
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  bannerId: {
    filterName: "Banner Id",
    defaultValue: "",
  },
}

export const contestFilters = {
  searchType: {
    filterName: "Search Type",
    defaultValue: "",
  },
  searchText: {
    filterName: "Search Text",
    defaultValue: "",
  },
  type: {
    filterName: "Type",
    defaultValue: "",
    valueMap: {
      type1: "Contest",
      type2: "Contest With Only Categories",
      type3: "Contest With Only Sub Categories",
      type4: "Contest With Categories and Sub Categories",
    },
  },
  sponsored: {
    filterName: "Sponsered",
    defaultValue: "",
  },
}

export const roomFilters = {
  searchType: {
    filterName: "Search Type",
    defaultValue: "title",
  },
  searchText: {
    filterName: "Search Text",
    defaultValue: null,
  },
  orderByField: {
    filterName: "Order By",
    defaultValue: "startDateTime",
    valueMap: {
      featuredIndex: "Featured Index",
      count: "count",
      startDateTime: "Start Time",
      endDateTime: "End Time",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
  // hashTags: {
  //   filterName: "Hash Tags",
  //   defaultValue: null,
  // },
}

export const activityFilters = {
  searchText: {
    filterName: "Search Text",
    defaultValue: "",
  },
  action: {
    filterName: "Action",
    defaultValue: undefined,
    valueMap: {
      activate: "Activate",
      deactivate: "DeActivate",
      content_tag_target_category_v2: "Tag Category",
      tag_non_english: "Tag Non English",
      create_content: "Create Content",
      update_content: "Update Content",
      bulk_create_content: "Bulk Create Content",
      content_quality_check: "Quality Check Content",
      create_app_user: "Create App User",
      update_app_user: "Update App User",
      bulk_create_app_user: "Bulk Create App Users",
      tag_content: "Tag Content",
    },
  },
  searchType: {
    filterName: "Search Type",
    defaultValue: "uuid",
    valueMap: {
      uuid: "UUID",
      moderator: "moderator",
    },
  },
  createdDateFrom: {
    filterName: "From Date",
    defaultValue: null,
  },
  createdDateTo: {
    filterName: "To Date",
    defaultValue: null,
  },
}

export const userFilters = {
  searchAfter: {
    filterName: "Search After",
    defaultValue: null,
  },
  searchType: {
    filterName: "Search Type",
    defaultValue: "user_uuid",
    valueMap: {
      name: "Name",
      city: "City",
      handle: "Handle",
      state: "State",
      user_uuid: "User ID",
      phone: "Phone Number",
      client_uuid: "Client ID",
    },
  },
  searchText: {
    filterName: "Search Text",
    defaultValue: "",
  },
  creatorType: {
    filterName: "Creator Type",
    defaultValue: "",
  },
  rangeSearchType: {
    filterName: "Range Search Type",
    defaultValue: null,
    valueMap: {
      review_bucket: "Bucket",
      download_count: "Download Count",
      like_count: "Like Count",
      share_count: "Share Count",
      view_count: "View Count",
      video_duration: "Video Duration",
    },
  },
  opSearchType: {
    filterName: "Range Search Operator",
    defaultValue: null,
    valueMap: {
      bt: "Between",
      eq: "Equal to",
      gt: "Greater Than",
      lt: "Less Than",
    },
  },
  rangeSearchText: {
    filterName: "Range Search Text",
    defaultValue: null,
  },
  rangeSearchFrom: {
    filterName: "Range Search From",
    defaultValue: null,
  },
  rangeSearchTo: {
    filterName: "Range Search To",
    defaultValue: null,
  },
  createdDateFrom: {
    filterName: "Created Date From",
    defaultValue: null,
  },
  createdDateTo: {
    filterName: "Created Date To",
    defaultValue: null,
  },
  orderByField: {
    filterName: "Order By",
    defaultValue: "posts_count",
    valueMap: {
      posts_count: "Posts Count",
      like_count: "Real Like Count",
      share_count: "Real Share Count",
      view_count: "Real View Count",
      download_count: "Real Download Count",
      rc_total_like_count: "Like Count",
      rc_total_share_count: "Share Count",
      rc_total_view_count: "View Count",
      rc_total_download_count: "Download Count",
      nsfw_posts: "NSFW Count",
      first_content_date: "First Creation Date",
      last_content_date: "Last Creation Date",
      moderated_posts_count: "Moderated Posts",
    },
  },
  order: {
    filterName: "Order",
    defaultValue: "desc",
    valueMap: {
      asc: "Ascending",
      desc: "Descending",
    },
  },
  curatedType: {
    filterName: "Curated Type",
    defaultValue: null,
    valueMap: {
      c: "Community",
      k: "Kol",
      m: "Macro",
      n: "Nano",
      o: "OGC",
      s: "Celebrity/Stars",
      l: "Micro",
      u: "UGC",
    },
  },
  contentCreateLangs: {
    filterName: "Creation Language",
    defaultValue: "",
    valueMap: {
      bn: "Bangala",
      bh: "Bhojpuri",
      en: "English",
      gu: "Gujarati",
      kn: "Kannada",
      hi: "Hindi",
      mr: "Marathi",
      ml: "Malayalam",
      or: "Odiya",
      pa: "Punjabi",
      ta: "Tamil",
      te: "Telugu",
    },
  },
  userLangPrimary: {
    filterName: "Primay Language",
    defaultValue: "",
    valueMap: {
      bn: "Bangala",
      bh: "Bhojpuri",
      en: "English",
      gu: "Gujarati",
      kn: "Kannada",
      hi: "Hindi",
      mr: "Marathi",
      ml: "Malayalam",
      or: "Odiya",
      pa: "Punjabi",
      ta: "Tamil",
      te: "Telugu",
    },
  },
  category: {
    filterName: "Categories",
    defaultValue: "",
    valueMap: {
      Love: "love",
      Bhakti: "bhakti",
      "Dance & Music": "dance&music",
      "News & Information": "news&info",
      Sports: "sports",
      "Health & Fitness": "health&fitness",
      Food: "food",
      Motivation: "motivation",
      "DIY & Hacks": "diy&hacks",
      "Beauty & Fashion": "beauty&fashion",
      "Movies & Entertainment": "movies&entertainment",
      "Funny & Humour": "funny&humour",
      CCTV: "cctv",
      Travel: "travel",
      Glamour: "glamour",
    },
  },
}

export const manageAdsConfigFilters = {
  id: {
    filterName: "Id",
    defaultValue: "",
  },
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  status: {
    filterName: "Status",
    defaultValue: "",
  },
}

export const manageAdsProfileFilters = {
  id: {
    filterName: "Id",
    defaultValue: "",
  },
  name: {
    filterName: "Name",
    defaultValue: "",
  },
  status: {
    filterName: "Status",
    defaultValue: "",
  },
}

export const LANGUAGES = [
  { value: "bn", label: "Bangala" },
  { value: "bh", label: "Bhojpuri" },
  { value: "en", label: "English" },
  { value: "gu", label: "Gujarati" },
  { value: "hi", label: "Hindi" },
  { value: "kn", label: "Kannada" },
  { value: "ml", label: "Malayalam" },
  { value: "mr", label: "Marathi" },
  { value: "or", label: "Odiya" },
  { value: "pa", label: "Punjabi" },
  { value: "ta", label: "Tamil" },
  { value: "te", label: "Telugu" },
]
export const ETHNICITY = [
  { value: "indian", label: "Indian" },
  { value: "chinese", label: "Chinese" },
  { value: "afro", label: "Afro" },
  { value: "arab", label: "Arab" },
  { value: "white", label: "White" },
  { value: "mixed", label: "Mixed" },
  { value: "na", label: "NA" },
  { value: "others", label: "Others" },
]
export const ETHNICITY_OTHERS = [
  { value: "arab", label: "Arab" },
  { value: "afro", label: "Afro" },
  { value: "white", label: "White" },
]
export const REORDERLANGUAGES = [
  { value: "default", label: "Default" },
  { value: "bh", label: "Bhojpuri" },
  { value: "bn", label: "Bangala" },
  { value: "en", label: "English" },
  { value: "gu", label: "Gujarati" },
  { value: "hi", label: "Hindi" },
  { value: "kn", label: "Kannada" },
  { value: "ml", label: "Malayalam" },
  { value: "mr", label: "Marathi" },
  { value: "ta", label: "Tamil" },
  { value: "te", label: "Telugu" },
  { value: "or", label: "Odiya" },
  { value: "pa", label: "Punjabi" },
]

export const CONTENT_TYPE = [
  { value: "MEME", label: "Meme" },
  { value: "NEWS", label: "News" },
  { value: "ENTERTAINMENT", label: "Entertainment" },
  { value: "VIDEO", label: "Video" },
  { value: "GREETING", label: "Greeting" },
]

export const LANGUAGE_MAPPING = {
  bn: "Bangala",
  bh: "Bhojpuri",
  en: "English",
  gu: "Gujarati",
  hi: "Hindi",
  kn: "Kannada",
  ml: "Malayalam",
  mr: "Marathi",
  or: "Odiya",
  pa: "Punjabi",
  ta: "Tamil",
  te: "Telugu",
}

export const MODERATION_LEVEL_MAP = {
  "": "All",
  3: "Auto Approved",
  0: "Auto Disabled",
  2: "Human Approved",
  "-1": "Human Disabled",
  1: "Unmoderated",
}

export const CATEGORIES = [
  { value: "Trending", label: "Trending" },
  { value: "Filmy", label: "Filmy" },
  { value: "Funny", label: "Funny" },
  { value: "Dialogues", label: "Dialogues" },
  { value: "BGM", label: "BGM" },
  { value: "Status", label: "Status" },
  { value: "Romantic", label: "Romantic" },
  { value: "SwagHai", label: "Swag Hai" },
  { value: "YesudasHits", label: "Yesudas Hits" },
  { value: "SingleSuperStars", label: "Single Superstars" },
  { value: "DBoss", label: "DBoss" },
  { value: "PrinceSK", label: "Prince SK" },
]

export const DISPLAY_PERSONA = [
  { value: "celebrity", label: "Celebrity" },
  { value: "foodie", label: "Foodie" },
  { value: "genius", label: "Genius" },
  { value: "khiladi", label: "Khiladi" },
  { value: "mentor", label: "Mentor" },
  { value: "performer", label: "Performer" },
  { value: "popular", label: "Popular" },
  { value: "styleicon", label: "Style Icon" },
  { value: "techguru", label: "Tech Guru" },
  { value: "traveller", label: "Traveller" },
  { value: "joshileyfoodies", label: "Joshiley Foodies" },
  { value: "joshileyicons", label: "Joshiley Icons" },
  { value: "joshileymasters", label: "Joshiley Masters" },
  { value: "joshileymentors", label: "Joshiley Mentors" },
  { value: "joshileystars", label: "Joshiley Stars" },
  { value: "joshileywanderers", label: "Joshiley Wanderers" },
]

export const TARGET_CATEGORY = [
  { label: "GLAMOUR", value: "glamour" },
  { label: "DANCE", value: "dance" },
  { label: "PRANK", value: "prank" },
  { label: "COOKING", value: "cooking" },
  { label: "BHAKTI", value: "bhakti" },
  { label: "MOTIVATIONAL", value: "motivational" },
  { label: "MUSIC", value: "music" },
  { label: "DIY & HACKS", value: "diy & hacks" },
  { label: "EDUCATION", value: "education" },
  { label: "SPORTS", value: "sports" },
  { label: "FITNESS", value: "fitness" },
  { label: "BEAUTY", value: "beauty" },
  { label: "HEALTH", value: "health" },
  { label: "MOVIES & ENTERTAINMENT", value: "movies & entertainment" },
]

export const DEACTIVATE_REASONS = [
  { value: "accident", label: "Accident" },
  { value: "animal_cruelty", label: "Animal Cruelty" },
  { value: "anti_national", label: "Anti National" },
  { value: "anti_religion", label: "Anti Religion" },
  { value: "banned_organization", label: "Banned Organization" },
  { value: "blurred", label: "Blurred" },
  { value: "blood", label: "Blood" },
  { value: "child_nudity", label: "Child Nudity" },
  { value: "competitor_logo", label: "Competitor Logo" },
  { label: "Copyright Content", value: "copyright_content" },
  { value: "cyberbullying_&_harassment", label: "Cyberbullying & Harassment" },
  { value: "death", label: "Death" },
  { value: "extreme_violence", label: "Extreme Violence" },
  {
    value: "identity_theft_&_impersonation",
    label: "Identity Theft & Impersonation",
  },
  { value: "illegal_display_of_weapons", label: "Illegal Display Of Weapons" },
  { value: "josh_logo", label: "Josh Logo" },
  { value: "misinformation_&_fake_news", label: "Misinformation & Fake News" },
  { value: "mob_violence", label: "Mob Violence" },
  { value: "porn", label: "Porn" },
  { value: "sexual_molestation_ugc", label: "Sexual Molestation UGC" },
  {
    value: "sexual_molestation_ott_movie",
    label: "Sexual Molestation OTT/Movie",
  },
  { value: "small_duration", label: "Small Duration" },
  { value: "suicide", label: "Suicide" },
  { value: "text_profanity", label: "Text Profanity" },
]

export const ROOM_DEACTIVATE_REASONS = [
  { label: "PORN", value: "porn" },
  { label: "BLOOD", value: "blood" },
  { label: "DEATH", value: "death" },
  { label: "SUICIDE", value: "suicide" },
  { label: "ANTI NATIONAL", value: "anti_national" },
  { label: "ANTI RELIGION", value: "anti_religion" },
  { label: "ANIMAL CRUELTY", value: "animal_cruelty" },
  { label: "CHILD NUDITY", value: "child_nudity" },
  { label: "ABUSIVE", value: "abusive" },
  { label: "VIOLENCE", value: "violence" },
  { label: "MISINFORMATION & FAKE NEWS", value: "misinformation_&_fake_news" },
  { label: "CYBERBULLYING & HARASSMENT", value: "cyberbullying_&_harassment" },
  {
    label: "IDENTITY THEFT & IMPERSONATION",
    value: "identity_theft_&_impersonation",
  },
  { label: "MOB VIOLENCE", value: "mob_violence" },
]

export const COMPETITION_LOGO_MAP = {
  roposo: {
    label: "Roposo",
    value: "roposo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/roposo.jpg",
  },
  sharechat: {
    label: "ShareChat",
    value: "sharechat",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/sharechat.jpg",
  },
  likee: {
    label: "LIKEE",
    value: "likee",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/likee.png",
  },
  moj: {
    label: "Moj",
    value: "moj",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/moj.png",
  },
  snackvideo: {
    label: "Snack Video",
    value: "snackvideo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/snack_video.png",
  },
  mxtakatak: {
    label: "MX Taka tak",
    value: "mxtakatak",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/mxtakatak.jpg",
  },
  tiktok: {
    label: "TikTok",
    value: "tiktok",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/tiktok.png",
  },
  instareels: {
    label: "Insta Reels",
    value: "instareels",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/instaReels.png",
  },
  vigovideo: {
    label: "Vigo Video",
    value: "vigovideo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/vigoVideo.jpg",
  },
  snapchat: {
    label: "Snapchat",
    value: "snapchat",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/snapchat.png",
  },
  zili: {
    label: "Zili",
    value: "zili",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/zili.png",
  },
  tiki: {
    label: "Tiki",
    value: "tiki",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/tiki.png",
  },
  chingari: {
    label: "Chingari",
    value: "chingari",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/chingari.jpg",
  },
  rizzle: {
    label: "Rizzle",
    value: "rizzle",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/rizzle.png",
  },
  litlot: {
    label: "Litlot",
    value: "litlot",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/litlot.png",
  },
  mitron: {
    label: "Mitron",
    value: "mitron",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/mitron.jpg",
  },
  triller: {
    label: "Triller",
    value: "triller",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/triller.png",
  },
  noizz: {
    label: "Noizz",
    value: "noizz",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/noiz.png",
  },
  youtubeshorts: {
    label: "Youtube Shorts",
    value: "youtubeshorts",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/youtubeShorts.png",
  },
  changa: {
    label: "Changa",
    value: "changa",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/changa.jpg",
  },
  helo: {
    label: "Helo",
    value: "helo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/helo.png",
  },
  vmate: {
    label: "Vmate",
    value: "vmate",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/vmate.jpg",
  },
  "4fun": {
    label: "4Fun",
    value: "4fun",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/4fun.png",
  },
  madlipz: {
    label: "Madlipz",
    value: "madlipz",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/madlipz.jpg",
  },
  dubsmash: {
    label: "Dubsmash",
    value: "dubsmash",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/dubsmash.png",
  },
  snakevideo: {
    label: "Snake Video",
    value: "snakevideo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/snake-video.png",
  },
  sharara: {
    label: "Sharara",
    value: "sharara",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/sharara.png",
  },
  tangiapp: {
    label: "Tangi App",
    value: "tangiapp",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/tangi_app.jpg",
  },
  firework: {
    label: "Firework",
    value: "firework",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Firework_app.jpg",
  },
  kwai: {
    label: "Kwai",
    value: "kwai",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Kwai_app.jpg",
  },
  hipi: {
    label: "HiPi",
    value: "hipi",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/HiPi_app.jpg",
  },
  chilli5: {
    label: "Chilli5",
    value: "chilli5",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Chilli5_app.png",
  },
  welike: {
    label: "Welike",
    value: "welike",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Welike_app.png",
  },
  kindda: {
    label: "Kindda",
    value: "kindda",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/kindda_app.png",
  },
  injoy: {
    label: "Injoy",
    value: "injoy",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Injoy_app.png",
  },
  kadak: {
    label: "Kadak",
    value: "kadak",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/kadak_app.png",
  },
  biptok: {
    label: "BIPTOK",
    value: "biptok",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/BIPTOK_app.png",
  },
  brilla: {
    label: "BRILLA",
    value: "brilla",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/BRILLA_app.png",
  },
  alap: {
    label: "ALAP",
    value: "alap",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/ALAP_app.png",
  },
  mobistar: {
    label: "MOBISTAR",
    value: "mobistar",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/MOBISTAR_app.png",
  },
  zoomerang: {
    label: "ZOOMERANG",
    value: "zoomerang",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/ZOOMERANG_app.png",
  },
  vshorts: {
    label: "V SHORTS",
    value: "vshorts",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/V_SHORTS_app.png",
  },
  vshots: {
    label: "VSHOTS",
    value: "vshots",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/VSHOTS_app.png",
  },
  trendo: {
    label: "Trendo",
    value: "trendo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Trendo_app.png",
  },
  vskit: {
    label: "VSKIT",
    value: "vskit",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/VSKIT_app.png",
  },
  inditok: {
    label: "INDITOK",
    value: "inditok",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/INDITOK_app.png",
  },
  zapak: {
    label: "ZAPAK",
    value: "zapak",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/ZAPAK_app.png",
  },
  ticklickapp: {
    label: "Ticklick App",
    value: "ticklickapp",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Ticklick_app.png",
  },
  miqtok: {
    label: "MiqTok",
    value: "miqtok",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/MiqTok_app.png",
  },
  others: { label: "Others", value: "others", logoSrc: "" },
}

export const competition_logos = [
  {
    label: "Roposo",
    value: "roposo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/roposo.jpg",
  },
  {
    label: "ShareChat",
    value: "sharechat",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/sharechat.jpg",
  },
  {
    label: "LIKEE",
    value: "likee",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/likee.png",
  },
  {
    label: "Moj",
    value: "moj",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/moj.png",
  },
  {
    label: "Snack Video",
    value: "snackvideo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/snack_video.png",
  },
  {
    label: "MX Taka tak",
    value: "mxtakatak",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/mxtakatak.jpg",
  },
  {
    label: "TikTok",
    value: "tiktok",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/tiktok.png",
  },
  {
    label: "Insta Reels",
    value: "instareels",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/instaReels.png",
  },
  {
    label: "Vigo Video",
    value: "vigovideo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/vigoVideo.jpg",
  },
  {
    label: "Snapchat",
    value: "snapchat",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/snapchat.png",
  },
  {
    label: "Zili",
    value: "zili",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/zili.png",
  },
  {
    label: "Tiki",
    value: "tiki",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/tiki.png",
  },
  {
    label: "Chingari",
    value: "chingari",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/chingari.jpg",
  },
  {
    label: "Rizzle",
    value: "rizzle",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/rizzle.png",
  },
  {
    label: "Litlot",
    value: "litlot",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/litlot.png",
  },
  {
    label: "Mitron",
    value: "mitron",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/mitron.jpg",
  },
  {
    label: "Triller",
    value: "triller",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/triller.png",
  },
  {
    label: "Noizz",
    value: "noizz",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/noiz.png",
  },
  {
    label: "Youtube Shorts",
    value: "youtubeshorts",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/youtubeShorts.png",
  },
  {
    label: "Changa",
    value: "changa",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/changa.jpg",
  },
  {
    label: "Helo",
    value: "helo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/helo.png",
  },
  {
    label: "Vmate",
    value: "vmate",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/vmate.jpg",
  },
  {
    label: "4Fun",
    value: "4fun",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/4fun.png",
  },
  {
    label: "Madlipz",
    value: "madlipz",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/madlipz.jpg",
  },
  {
    label: "Dubsmash",
    value: "dubsmash",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/dubsmash.png",
  },
  {
    label: "Snake Video",
    value: "snakevideo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/snake-video.png",
  },
  {
    label: "Sharara",
    value: "sharara",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/sharara.png",
  },
  {
    label: "Tangi App",
    value: "tangiapp",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/tangi_app.jpg",
  },
  {
    label: "Firework",
    value: "firework",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Firework_app.jpg",
  },
  {
    label: "Kwai",
    value: "kwai",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Kwai_app.jpg",
  },
  {
    label: "HiPi",
    value: "hipi",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/HiPi_app.jpg",
  },
  {
    label: "Chilli5",
    value: "chilli5",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Chilli5_app.png",
  },
  {
    label: "Welike",
    value: "welike",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Welike_app.png",
  },
  {
    label: "Kindda",
    value: "kindda",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/kindda_app.png",
  },
  {
    label: "Injoy",
    value: "injoy",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Injoy_app.png",
  },
  {
    label: "Kadak",
    value: "kadak",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/kadak_app.png",
  },
  {
    label: "BIPTOK",
    value: "biptok",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/BIPTOK_app.png",
  },
  {
    label: "BRILLA",
    value: "brilla",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/BRILLA_app.png",
  },
  {
    label: "ALAP",
    value: "alap",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/ALAP_app.png",
  },
  {
    label: "MOBISTAR",
    value: "mobistar",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/MOBISTAR_app.png",
  },
  {
    label: "ZOOMERANG",
    value: "zoomerang",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/ZOOMERANG_app.png",
  },
  {
    label: "V SHORTS",
    value: "vshorts",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/V_SHORTS_app.png",
  },
  {
    label: "VSHOTS",
    value: "vshots",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/VSHOTS_app.png",
  },
  {
    label: "Trendo",
    value: "trendo",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Trendo_app.png",
  },
  {
    label: "VSKIT",
    value: "vskit",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/VSKIT_app.png",
  },
  {
    label: "INDITOK",
    value: "inditok",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/INDITOK_app.png",
  },
  {
    label: "ZAPAK",
    value: "zapak",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/ZAPAK_app.png",
  },
  {
    label: "Ticklick App",
    value: "ticklickapp",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/Ticklick_app.png",
  },
  {
    label: "MiqTok",
    value: "miqtok",
    logoSrc:
      "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/competitor-logos/MiqTok_app.png",
  },
  { label: "Others", value: "others", logoSrc: "" },
]

export const BANNED_ORGANIZATIONS_MAP = {
  AIIC: { label: "All India Imams Council (AIIC)", value: "AIIC" },
  CFI: { label: "Campus Front of India (CFI)", value: "CFI" },
  EIF: { label: "Empower India Foundation (EIF)", value: "EIF" },
  JF: { label: "Junior Front (JF)", value: "JF" },
  NCHRO: {
    label: "National Confederation of Human Rights Organization (NCHRO)",
    value: "NCHRO",
  },
  PFI: { label: "Popular Front of India (PFI)", value: "PFI" },
  RFK: { label: "Rehab Foundation Kerala (RFK)", value: "RFK" },
  RIF: { label: "Rehab India Foundation (RIF)", value: "RIF" },
  WFM: { label: "National Women’s Front (WFM)", value: "WFM" },
}

export const banned_organizations = [
  { label: "All India Imams Council (AIIC)", value: "AIIC" },
  { label: "Campus Front of India (CFI)", value: "CFI" },
  { label: "Empower India Foundation (EIF)", value: "EIF" },
  { label: "Junior Front (JF)", value: "JF" },
  {
    label: "National Confederation of Human Rights Organization (NCHRO)",
    value: "NCHRO",
  },
  { label: "Popular Front of India (PFI)", value: "PFI" },
  { label: "Rehab Foundation Kerala (RFK)", value: "RFK" },
  { label: "Rehab India Foundation (RIF)", value: "RIF" },
  { label: "National Women’s Front (WFM)", value: "WFM" },
]

export const TARGET_CATEGORY_V2 = [
  { label: "Love", value: "love" },
  { label: "Bhakti", value: "bhakti" },
  { label: "Dance & Music", value: "dance&music" },
  { label: "News & Information", value: "news&info" },
  { label: "Sports", value: "sports" },
  { label: "Health & Fitness", value: "health&fitness" },
  { label: "Food", value: "food" },
  { label: "Motivation", value: "motivation" },
  { label: "DIY & Hacks", value: "diy&hacks" },
  { label: "Beauty & Fashion", value: "beauty&fashion" },
  { label: "Movies & Entertainment", value: "movies&entertainment" },
  { label: "Funny & Humour", value: "funny&humour" },
  { label: "CCTV", value: "cctv" },
  { label: "Travel", value: "travel" },
  { label: "Glamour", value: "glamour" },
]

export const MODERATION_REASONS = [
  { value: "ABUSIVE", label: "Abusive" },
  { value: "ADULT", label: "Adult" },
  { value: "BAD_QUALITY", label: "Bad Quality" },
  { value: "BIASED", label: "Biased" },
  { value: "COPYRIGHT", label: "Copyright" },
  { value: "FAKE", label: "Fake" },
  { value: "INCOMPLETE", label: "Incomplete" },
  { value: "NOT_READABLE", label: "Not Readable" },
  { value: "SPAM", label: "Spam Words" },
  { value: "VULGAR", label: "Vulgar" },
]

export const CURATED_TYPES = [
  { label: "Macro", value: "m" },
  { label: "UGC", value: "u" },
  { label: "Nano", value: "n" },
  { label: "Community", value: "c" },
  { label: "Kol", value: "k" },
  { label: "OGC", value: "o" },
  { label: "Celebrity/Stars", value: "s" },
  { label: "Micro", value: "l" },
  { label: "Internal", value: "i" },
  { label: "Ghost", value: "g" },
  { label: "Internal Brand", value: "ib" },
  { label: "External Brand", value: "eb" },
  { label: "Uploader", value: "b" },
  { label: "Churnot Creator", value: "x" },
]

export const GENRE_MAPPING = {
  entertainment: "Entertainment",
  skit_humanity: "Skit - Humanity",
  skit_romantic_heartbreak: "Skit - Romantic/Heart break",
  skit_family_love_respect: "Skit - Family love/Respect",
  skit_patriotic: "Skit - Patriotic",
  lipsync_romantic_heartbreak: "Lip Sync - Romantic/Heart break",
  lipsync_dialogue: "Lip Sync - Dialogue",
  movie_tv_ott_clips: "Movie/TV/OTT Clips",
  movie_tv_ott_celebs: "Movie/TV/OTT Celebs",
  video_selfie: "Video selfie",
  others: "Others",
  comedy_n_fun: "Comedy & Fun",
  fails: "Accidents & Fails",
  dance: "Dance",
  poetry_or_shayari: "Poetry or Shayari",
  pranks: "Pranks",
  stunts: "Stunts",
  status_wishes: "Status & Wishes",
  romantic_heartbreak: "Romantic/Heart Break",
  family_love: "Family love",
  festivals: "Festivals",
  good_morning_wishes: "Good morning wishes",
  good_day_wishes: "Good day wishes",
  good_night_wishes: "Good night wishes",
  motivational: "Motivational",
  other_imp_days_national_days: "Other Imp Days - National days",
  devotional: "Devotional",
  patriotic: "Patriotic",
  image_selfie: "Image selfie",
  fun_party: "Fun & Party",
  food_cooking: "Food & Cooking",
  non_veg: "Non Veg",
  veg: "Veg",
  fitness_health: "Fitness & Health",
  beauty_fashion: "Beauty & Fashion",
  education: "Education",
  astrology: "Astrology",
  aries: "Aries",
  taurus: "Taurus",
  gemini: "Gemini",
  cancer: "Cancer",
  leo: "Leo",
  virgo: "Virgo",
  value: "virgo",
  libra: "Libra",
  scorpio: "Scorpio",
  sagittarius: "Sagittarius",
  capricon: "Capricon",
  zodiac_horoscope: "Raashi Bhavishya",
  business_horoscope: "Business/Shares/Stocks/Investments/Profit/Loss etc",
  relationship_horoscope: "Relationships",
  matrimonial_horoscope: "Matrimonial",
  career_horoscope: "Career/Jobs/Education",
  general_prediction:
    "General Prediction (Palmistry/Forehead/Birth Marks/Tatoos/Vastu/Numerology/Tarot)",
  nakshatra_based_horoscope: "Festivals/Nakshtra/Planetory Changes",
  aquarius: "Aquarius",
  pisces: "Pisces",
  generic_astro: "Generic Astro",
  motivational: "Motivational",
  life_management: "Life management",
  relationship_tips: "Relationship tips",
  self_grooming: "Self Grooming",
  success_motivation: "Success series",
  money_motivation: "Money",
  good_manners: "Good manners",
  communication_skills: "Improving Communication Skills",
  patriotism: "Patriotism",
  social_contribution: "Contributing to society(Willingness to change world",
  gods_or_goddess: "Gods or Goddess",
  hinduism: "Hinduism",
  islam: "Islam",
  sikhism: "Sikhism",
  christianity: "Christianity",
  jainism: "Jainism",
  buddhism: "Buddhism",
  other_religions: "Other Religions",
  brahma: "Brahma",
  vishnu: "Vishunu",
  shiva: "Shiva",
  laxmi: "Laxmi",
  saraswati: "Saraswati",
  ganesha: "Ganesha",
  krishna: "Krishna",
  ram: "Ram",
  hanuman: "Hanuman",
  durga: "Durga",
  kali: "Kali",
  other_gods: "Other Gods",
  pets_wildlife_sealife: "Pets / Wildlife / Sealife",
  cars_bikes_aircrafts: "Cars, Bikes & Planes",
  gadgets: "Gadgets",
  toys: "Toys",
  arts_crafts: "Arts & Crafts",
  diy_hacks: "DIY & Hacks",
  photography: "Photography",
  value: "photography",
  sports: "Sports",
  cricket: "Cricket",
  football: "Football",
  magic_performance: "Magic Performance",
  architecture: "Architecture",
  travel: "Travel",
  news: "News",
  cant_identify_genre: "Can't Identify",
}

export const GENRE_LIST = [
  {
    label: "Entertainment",
    value: "entertainment",
  },
  {
    label: "Skit - Humanity",
    value: "skit_humanity",
  },
  {
    label: "Skit - Romantic/Heart break",
    value: "skit_romantic_heartbreak",
  },
  {
    label: "Skit - Family love/Respect",
    value: "skit_family_love_respect",
  },
  {
    label: "Skit - Patriotic",
    value: "skit_patriotic",
  },
  {
    label: "Lip Sync - Romantic/Heart break",
    value: "lipsync_romantic_heartbreak",
  },
  {
    label: "Lip Sync - Dialogue",
    value: "lipsync_dialogue",
  },
  {
    label: "Movie/TV/OTT Clips",
    value: "movie_tv_ott_clips",
  },
  {
    label: "Movie/TV/OTT Celebs",
    value: "movie_tv_ott_celebs",
  },
  {
    label: "Video selfie",
    value: "video_selfie",
  },
  {
    label: "Others",
    value: "others",
  },
  {
    label: "Comedy & Fun",
    value: "comedy_n_fun",
  },
  {
    label: "Accidents & Fails",
    value: "fails",
  },
  {
    label: "Dance",
    value: "dance",
  },
  {
    label: "Poetry or Shayari",
    value: "poetry_or_shayari",
  },
  {
    label: "Pranks",
    value: "pranks",
  },
  {
    label: "Stunts",
    value: "stunts",
  },
  {
    label: "Status & Wishes",
    value: "status_wishes",
  },
  {
    label: "Romantic/Heart Break",
    value: "romantic_heartbreak",
  },
  {
    label: "Family love",
    value: "family_love",
  },
  {
    label: "Festivals",
    value: "festivals",
  },
  {
    label: "Good morning wishes",
    value: "good_morning_wishes",
  },
  {
    label: "Good day wishes",
    value: "good_day_wishes",
  },
  {
    label: "Good night wishes",
    value: "good_night_wishes",
  },
  {
    label: "Motivational",
    value: "motivational",
  },
  {
    label: "Other Imp Days - National days",
    value: "other_imp_days_national_days",
  },
  {
    label: "Devotional",
    value: "devotional",
  },
  {
    label: "Patriotic",
    value: "patriotic",
  },
  {
    label: "Image selfie",
    value: "image_selfie",
  },
  {
    label: "Others",
    value: "others",
  },
  {
    label: "Fun & Party",
    value: "fun_party",
  },
  {
    label: "Food & Cooking",
    value: "food_cooking",
  },
  {
    label: "Non Veg",
    value: "non_veg",
  },
  {
    label: "Veg",
    value: "veg",
  },
  {
    label: "Fitness & Health",
    value: "fitness_health",
  },
  {
    label: "Beauty & Fashion",
    value: "beauty_fashion",
  },
  {
    label: "Education",
    value: "education",
  },
  {
    label: "Astrology",
    value: "astrology",
  },
  {
    label: "Aries",
    value: "aries",
  },
  {
    label: "Taurus",
    value: "taurus",
  },
  {
    label: "Gemini",
    value: "gemini",
  },
  {
    label: "Cancer",
    value: "cancer",
  },
  {
    label: "Leo",
    value: "leo",
  },
  {
    label: "Virgo",
    value: "virgo",
  },
  {
    label: "Libra",
    value: "libra",
  },
  {
    label: "Scorpio",
    value: "scorpio",
  },
  {
    label: "Sagittarius",
    value: "sagittarius",
  },
  {
    label: "Capricon",
    value: "capricon",
  },
  {
    label: "Raashi Bhavishya",
    value: "zodiac_horoscope",
  },
  {
    label: "Business/Shares/Stocks/Investments/Profit/Loss etc",
    value: "business_horoscope",
  },
  {
    label: "Relationships",
    value: "relationship_horoscope",
  },
  {
    label: "Matrimonial",
    value: "matrimonial_horoscope",
  },
  {
    label: "Career/Jobs/Education",
    value: "career_horoscope",
  },
  {
    label:
      "General Prediction (Palmistry/Forehead/Birth Marks/Tatoos/Vastu/Numerology/Tarot)",
    value: "general_prediction",
  },
  {
    label: "Festivals/Nakshtra/Planetory Changes",
    value: "nakshatra_based_horoscope",
  },
  {
    label: "Others",
    value: "others",
  },
  {
    label: "Aquarius",
    value: "aquarius",
  },
  {
    label: "Pisces",
    value: "pisces",
  },
  {
    label: "Generic Astro",
    value: "generic_astro",
  },
  {
    label: "Motivational",
    value: "motivational",
  },
  {
    label: "Life management",
    value: "life_management",
  },
  {
    label: "Relationship tips",
    value: "relationship_tips",
  },
  {
    label: "Self Grooming",
    value: "self_grooming",
  },
  {
    label: "Success series",
    value: "success_motivation",
  },
  {
    label: "Money",
    value: "money_motivation",
  },
  {
    label: "Good manners",
    value: "good_manners",
  },
  {
    label: "Improving Communication Skills",
    value: "communication_skills",
  },
  {
    label: "Patriotism",
    value: "patriotism",
  },
  {
    label: "Contributing to society(Willingness to change world",
    value: "social_contribution",
  },
  {
    label: "Others",
    value: "others",
  },
  {
    label: "Gods or Goddess",
    value: "gods_or_goddess",
  },
  {
    label: "Hinduism",
    value: "hinduism",
  },
  {
    label: "Islam",
    value: "islam",
  },
  {
    label: "Sikhism",
    value: "sikhism",
  },
  {
    label: "Christianity",
    value: "christianity",
  },
  {
    label: "Jainism",
    value: "jainism",
  },
  {
    label: "Buddhism",
    value: "buddhism",
  },
  {
    label: "Other Religions",
    value: "other_religions",
  },

  {
    label: "Brahma",
    value: "brahma",
  },
  {
    label: "Vishunu",
    value: "vishnu",
  },
  {
    label: "Shiva",
    value: "shiva",
  },
  {
    label: "Laxmi",
    value: "laxmi",
  },
  {
    label: "Saraswati",
    value: "saraswati",
  },
  {
    label: "Ganesha",
    value: "ganesha",
  },
  {
    label: "Krishna",
    value: "krishna",
  },
  {
    label: "Ram",
    value: "ram",
  },
  {
    label: "Hanuman",
    value: "hanuman",
  },
  {
    label: "Durga",
    value: "durga",
  },
  {
    label: "Kali",
    value: "kali",
  },
  {
    label: "Other Gods",
    value: "other_gods",
  },
  {
    label: "Pets / Wildlife / Sealife",
    value: "pets_wildlife_sealife",
  },
  {
    label: "Cars, Bikes & Planes",
    value: "cars_bikes_aircrafts",
  },
  {
    label: "Gadgets",
    value: "gadgets",
  },
  {
    label: "Toys",
    value: "toys",
  },
  {
    label: "Arts & Crafts",
    value: "arts_crafts",
  },
  {
    label: "DIY & Hacks",
    value: "diy_hacks",
  },
  {
    label: "Photography",
    value: "photography",
  },
  {
    label: "Sports",
    value: "sports",
  },
  {
    label: "Cricket",
    value: "cricket",
  },
  {
    label: "Football",
    value: "football",
  },
  {
    label: "Magic Performance",
    value: "magic_performance",
  },
  {
    label: "Architecture",
    value: "architecture",
  },
  {
    label: "Travel",
    value: "travel",
  },
  {
    label: "News",
    value: "news",
  },
  {
    label: "Can't Identify",
    value: "cant_identify_genre",
  },
]

export const CURATED_GENRE_LIST = [
  { label: "Lip Sync", value: "lip_sync" },
  { label: "Mixed", value: "mixed" },
  { label: "Status", value: "status" },
  { label: "Skit", value: "skit" },
  { label: "Dancing", value: "dancing" },
  { label: "Music", value: "music" },
  { label: "Beauty Fashion", value: "beauty_fashion" },
  { label: "Fitness", value: "fitness" },
  { label: "Poetry", value: "poetry" },
  { label: "Food", value: "food" },
  { label: "Artist", value: "artist" },
  { label: "Devotional", value: "devotional" },
  { label: "Meme", value: "meme" },
  { label: "Travel", value: "travel" },
  { label: "News", value: "news" },
  { label: "Gaming", value: "gaming" },
  { label: "Diy", value: "diy" },
  { label: "Education", value: "education" },
  { label: "Vlogging", value: "vlogging" },
  { label: "NSFW", value: "nsfw" },
  { label: "Educational", value: "educational" },
  { label: "Stunts", value: "stunts" },
  { label: "Brand", value: "brand" },
  { label: "Vulgar", value: "vulgar" },
  { label: "Random", value: "random" },
  { label: "Erotic", value: "erotic" },
  { label: "Sports", value: "sports" },
  { label: "Glamorous", value: "glamorous" },
  { label: "Hot", value: "hot" },
  { label: "Entertainment", value: "entertainment" },
  { label: "Comedy", value: "comedy" },
  { label: "Photography", value: "photography" },
  { label: "Motivational", value: "motivational" },
  { label: "Automobile", value: "automobile" },
  { label: "Pets/Animals", value: "pets_and_animals" },
  { label: "NGO/Gov", value: "ngo_gov" },
]

export const CURATED_GENRE_LIST_MAPPING = {
  lip_sync: "Lip Sync",
  mixed: "Mixed",
  status: "Status",
  skit: "Skit",
  dancing: "Dancing",
  music: "Music",
  beauty_fashion: "Beauty Fashion",
  fitness: "Fitness",
  poetry: "Poetry",
  food: "Food",
  artist: "Artist",
  devotional: "Devotional",
  meme: "Meme",
  travel: "Travel",
  news: "News",
  gaming: "Gaming",
  diy: "Diy",
  education: "Education",
  vlogging: "Vlogging",
  nsfw: "NSFW",
  educational: "Educational",
  stunts: "Stunts",
  brand: "Brand",
  vulgar: "Vulgar",
  random: "Random",
  erotic: "Erotic",
  sports: "Sports",
  glamorous: "Glamorous",
  hot: "Hot",
}

export const CURATED_TYPE_MAPPING = {
  m: "Macro",
  u: "UGC",
  n: "Nano",
  c: "Community",
  k: "Kol",
  o: "OGC",
  s: "Celebrity/Stars",
  l: "Micro",
  i: "Internal",
  g: "Ghost",
  ib: "Internal Brand",
  eb: "External Brand",
  b: "Uploader",
  x: "Churnot Creator",
}

export const CTA_ENTITY_LIST = [
  { label: "AUDIO", value: "AUDIO" },
  { label: "CHALLENGE", value: "CHALLENGE" },
  { label: "CONTEST", value: "CONTEST" },
  { label: "CREATOR", value: "CREATOR" },
  { label: "EFFECT", value: "EFFECT" },
  { label: "GIFT", value: "GIFT" },
  { label: "STICKER", value: "STICKER" },
  { label: "TEMPLATE", value: "TEMPLATE" },
  { label: "ZONE", value: "ZONE" },
]

export const CTA_ENTITY_MAP = {
  AUDIO: "AUDIO",
  CHALLENGE: "CHALLENGE",
  CONTEST: "CONTEST",
  CREATOR: "CREATOR",
  EFFECT: "EFFECT",
  GIFT: "GIFT",
  STICKER: "STICKER",
  TEMPLATE: "TEMPLATE",
  ZONE: "ZONE",
}

export const CTA_DEFAULT_DATA = {
  STICKER: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/sticker_icon.png",
    title: "Checkout this cool sticker.",
    cta: {
      text: "Use",
      view_type: 1,
    },
  },
  EFFECT: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/effect_icon.png",
    title: "Checkout this trending effect.",
    cta: {
      text: "Use",
      view_type: 1,
    },
  },
  TEMPLATE: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/templates_icon.png",
    title: "Checkout this new template.",
    cta: {
      text: "Use",
      view_type: 1,
    },
  },
  AUDIO: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/Music_icon.png",
    title: "Try this popular Audio",
    cta: {
      text: "Use",
      view_type: 1,
    },
  },
  CHALLENGE: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/challenge_cup.png",
    title: "Participate in the challenge and win prizes",
    cta: {
      text: "Participate",
      view_type: 2,
    },
  },
  CONTEST: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/challenge_cup.png",
    title: "Participate in the contest and win prizes",
    cta: {
      text: "Participate",
      view_type: 2,
    },
  },
  CREATOR: {
    icon_url: null,
    title: "View more content of the creator",
    cta: {
      text: "Explore",
      view_type: 5,
    },
  },
  GIFT: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/gift_icon.png",
    title: "Loving it.. send the creator gifts",
    cta: {
      text: "Send",
      icon_url:
        "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/gift-fill.png",
      view_type: 3,
    },
  },
  ZONE: {
    // Not needed  // between zone and cta, more priority
    icon_url: null,
    title: null,
    cta: {
      text: "null",
      view_type: 4,
    },
  },
  LIKE: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/like_icon.png",
    title: "Show your love to the creator",
    cta: {
      text: "Like",
      selected_text: "Liked",
      view_type: 3,
      selected_icon_url:
        "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/like_button_selected.png",
      icon_url:
        "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/like_button.png",
    },
  },
  COMMENT: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/comment_icon.png",
    title: "Show your love in the comments",
    cta: {
      text: "Comment",
      view_type: 1,
    },
  },
  SHARE: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/share_cta.png",
    title: "Share it and enjoy with your friends",
    cta: {
      text: "Share",
      view_type: 1,
    },
  },
  DUET: {
    icon_url:
      "https://stream.myjosh.in/stream/prod-ugc-contents/icons/cta_icons/Duet_icon.png",
    title: "Duet with the content and get more likes",
    cta: {
      text: "Try",
      view_type: 4,
    },
  },
}

export const ISSUE_ACTIVITY_MAPPING = {
  issueId: "Issue Id",
  summary: "Summary",
  phoneMake: "Phone Make",
  phoneModel: "Phone Model",
  clientId: "Client Id",
  supportingAssets: "Files",
  pocList: "Poc List",
  osVersion: "OS Version",
  appVersion: "App version",
  contentUuid: "Content Id",
  userUuid: "User Id",
  issueType: "Issue Type",
  issueSeverity: "Issue Severity",
  status: "Status",
  reportedBy: "Reported By",
  createdOn: "Created On",
  modifiedOn: "Modified On",
  assignedTo: "Assigned To",
  title: "Title",
  comment: "Comment",
  supportingAssets: "Attachments",
}

export const USER_TYPE_MAPPING = {
  i: "Internal",
  g: "Ghost",
  ib: "Internal Brand",
  eb: "External Brand",
  e: "External",
  u: "UGC",
}

export const PERMISSIONS_ARRAY = [
  // this array doesn't contain all the permissions. Need to check and update
  { label: "Edit Browse Content", value: "EDIT_BROWSE_CONTENT" },
  { label: "View Browse Content", value: "VIEW_BROWSE_CONTENT" },
  { label: "View Manage Content", value: "VIEW_MANAGE_CONTENT" },
  { label: "Edit Manage Content", value: "EDIT_MANAGE_CONTENT" },
  { label: "Edit Boost Content", value: "VIEW_BOOST_CONTENT" },
  { label: "View Boost Content", value: "EDIT_BOOST_CONTENT" },
  { label: "Edit Dashboard", value: "EDIT_DASHBOARD" },
  { label: "Edit Community Management", value: "EDIT_COMMUNITY_MANAGEMENT" },
  { label: "Edit Browse Comment", value: "EDIT_BROWSE_COMMENT" },
  { label: "Edit Manage Comment", value: "EDIT_MANAGE_COMMENT" },
  { label: "Edit Manage Trending", value: "EDIT_MANAGE_TRENDING" },
  { label: "Edit Tag Trending", value: "EDIT_TAG_TRENDING" },
  { label: "View Tag Trending", value: "VIEW_TAG_TRENDING" },
  { label: "Edit Manage Campaign", value: "EDIT_MANAGE_CAMPAIGN" },
  { label: "Edit Browse Room", value: "EDIT_BROWSE_ROOM" },
  { label: "View Browse Room", value: "VIEW_BROWSE_ROOM" },
  { label: "Edit Browse Hashtag", value: "EDIT_BROWSE_HASHTAG" },
  { label: "Edit Browse User", value: "EDIT_BROWSE_USER" },
  { label: "View Browse User", value: "VIEW_BROWSE_USER" },
  { label: "View User Details", value: "VIEW_USER_DETAILS" },
  { label: "Edit User Details", value: "EDIT_USER_DETAILS" },
  { label: "Edit Update Delta History", value: "EDIT_USER_DELTA_HISTORY" },
  { label: "Edit Boost Follow History", value: "EDIT_BOOST_FOLLOW_HISTORY" },
  { label: "Edit Boost Follow Meta/Status", value: "EDIT_BOOST_FOLLOW_META" },
  { label: "Edit Browse Brand", value: "EDIT_BROWSE_BRAND" },
  { label: "Edit Browse Challenge", value: "EDIT_BROWSE_CHALLENGE" },
  { label: "View Browse Challenge", value: "VIEW_BROWSE_CHALLENGE" },
  { label: "Edit Manage Challenge", value: "EDIT_MANAGE_CHALLENGE" },
  { label: "View Manage Challenge", value: "VIEW_MANAGE_CHALLENGE" },
  { label: "View Browse Contest", value: "VIEW_BROWSE_CONTEST" },
  { label: "View Manage Contest", value: "VIEW_MANAGE_CONTEST" },
  { label: "Edit Browse Contest", value: "EDIT_BROWSE_CONTEST" },
  { label: "Edit Manage Contest", value: "EDIT_MANAGE_CONTEST" },
  { label: "View Manage Audio", value: "VIEW_MANAGE_AUDIO" },
  { label: "Edit Manage Audio", value: "EDIT_MANAGE_AUDIO" },
  { label: "Edit Manage Camera Assets", value: "EDIT_MANAGE_CAMERA_ASSETS" },
  {
    label: "Edit Manage Category Assets",
    value: "EDIT_MANAGE_CATEGORY_ASSETS",
  },
  { label: "Edit Templates", value: "EDIT_TEMPLATES" },
  { label: "Edit Manage Sticker Assets", value: "EDIT_MANAGE_STICKER_ASSETS" },
  {
    label: "Edit Manage Sticker Subtype",
    value: "EDIT_MANAGE_STICKER_SUBTYPE",
  },
  { label: "Edit Manage Stickers", value: "EDIT_MANAGE_STICKERS" },
  { label: "View Manage Sticker Assets", value: "VIEW_MANAGE_STICKER_ASSETS" },
  {
    label: "View Manage Sticker Subtype",
    value: "VIEW_MANAGE_STICKER_SUBTYPE",
  },
  { label: "View Manage Stickers", value: "VIEW_MANAGE_STICKERS" },
  { label: "Edit Banners", value: "EDIT_BANNERS" },
  { label: "Edit Chips", value: "EDIT_CHIPS" },
  { label: "Edit Music Artist", value: "EDIT_MUSIC_ARTIST" },
  { label: "Edit Music Label", value: "EDIT_MUSIC_LABEL" },
  { label: "Edit Music Playlist", value: "EDIT_MUSIC_PLAYLIST" },
  { label: "Edit Named Collection", value: "EDIT_NAMED_COLLECTION" },
  { label: "Edit Web Games", value: "EDIT_WEB_GAMES" },
  { label: "Edit Web View Banners", value: "EDIT_WEB_VIEW_BANNERS" },
  { label: "Edit Browse Issue", value: "EDIT_BROWSE_ISSUE" },
  { label: "Edit Discovery Page", value: "EDIT_DISCOVERY_PAGE" },
  { label: "Edit Icons", value: "EDIT_ICONS" },
  { label: "View Icons", value: "VIEW_ICONS" },
  {
    label: "Edit Manage Collection Entities",
    value: "EDIT_MANAGE_COLLECTION_ENTITIES",
  },
  { label: "Edit Manage CMS User", value: "EDIT_MANAGE_CMS_USER" },
  { label: "Edit Manage Source", value: "EDIT_MANAGE_SOURCE" },
  { label: "Edit View Activity", value: "EDIT_VIEW_ACTIVITY" },
  { label: "Edit Bulk Upload Report", value: "EDIT_BULK_UPLOAD_REPORT" },
  { label: "Edit Boost Report", value: "EDIT_BOOST_REPORT" },
  { label: "Tag Content", value: "TAG_CONTENT" },
  { label: "View Activity", value: "VIEW_ACTIVITY" },
  { label: "Moderator Admin", value: "MODERATOR_ADMIN" },
  { label: "QC Tag Content", value: "QC_TAG_CONTENT" },
  { label: "Internal QC Tag Content", value: "INTERNAL_QC_TAG_CONTENT" },
  { label: "Review Ghost Content", value: "REVIEW_GHOST_CONTENT" },
  { label: "Tag Trending", value: "TAG_TRENDING" },
  { label: "Label Content", value: "LABEL_CONTENT" },
  { label: "Issue Admin", value: "ISSUE_ADMIN" },
  { label: "Issue User", value: "ISSUE_USER" },
  { label: "Edit Manage Event", value: "EDIT_MANAGE_EVENT" },
  { label: "View Manage Event", value: "VIEW_MANAGE_EVENT" },
  { label: "Update User Agency", value: "UPDATE_USER_AGENCY" },
  { label: "Edit Gifts", value: "EDIT_GIFTS" },
  { label: "Spin", value: "SPIN" },
  { label: "View Gifts", value: "VIEW_GIFTS" },
  { label: "Edit Package", value: "EDIT_PACKAGE" },
  { label: "View Package", value: "VIEW_PACKAGE" },
  { label: "Hide Dashboard", value: "HIDE_DASHBOARD" },
  { label: "Edit CMS Video Asset", value: "EDIT_CMS_VIDEO_ASSET" },
  { label: "View CMS Video Asset", value: "VIEW_CMS_VIDEO_ASSET" },
  { label: "Edit CMS Video Template", value: "EDIT_CMS_VIDEO_TEMPLATE" },
  { label: "View CMS Video Template", value: "VIEW_CMS_VIDEO_TEMPLATE" },
  { label: "View Taxonomy Dashboard", value: "VIEW_TAXONOMY_DASHBOARD" },
  { label: "Edit Content Title", value: "EDIT_CONTENT_TITLE" },
  { label: "Edit Tag Title", value: "EDIT_TAG_TITLE" },
  { label: "View Browse Image", value: "VIEW_BROWSE_IMAGE" },
  { label: "Edit Browse Image", value: "EDIT_BROWSE_IMAGE" },
  { label: "View Manage Image", value: "VIEW_MANAGE_IMAGE" },
  { label: "Edit Manage Image", value: "EDIT_MANAGE_IMAGE" },
  { label: "View Browse Zone", value: "VIEW_BROWSE_ZONE" },
  { label: "Edit Browse Zone", value: "EDIT_BROWSE_ZONE" },
  { label: "View Manage Zone", value: "VIEW_MANAGE_ZONE" },
  { label: "Edit Manage Zone", value: "EDIT_MANAGE_ZONE" },
  { label: "View Browse Social", value: "VIEW_BROWSE_SOCIAL" },
  { label: "Edit Browse Social", value: "EDIT_BROWSE_SOCIAL" },
  { label: "View Manage Social", value: "VIEW_MANAGE_SOCIAL" },
  { label: "Edit Manage Social", value: "EDIT_MANAGE_SOCIAL" },
  { label: "QC Search By ID", value: "QC_SEARCH_BY_ID" },
  { label: "View Dashboard", value: "VIEW_DASHBOARD" },
  { label: "View Browse Issue", value: "VIEW_BROWSE_ISSUE" },
  { label: "View Community Management", value: "VIEW_COMMUNITY_MANAGEMENT" },
  { label: "View Browse Meme", value: "VIEW_BROWSE_MEME" },
  { label: "Edit Browse Meme", value: "EDIT_BROWSE_MEME" },
  { label: "View Ads Config", value: "VIEW_ADS_CONFIG" },
  { label: "Edit Ads Config", value: "EDIT_ADS_CONFIG" },
  { label: "View Ads Profile", value: "VIEW_ADS_PROFILE" },
  { label: "Edit Ads Profile", value: "EDIT_ADS_PROFILE" },
  { label: "Browse Chat Bot", value: "CHAT_BOT" },
  { label: "View Browse CTA", value: "VIEW_BROWSE_CTA" },
  { label: "Edit Browse CTA", value: "EDIT_BROWSE_CTA" },
  { label: "View Browse KYC", value: "VIEW_BROWSE_KYC" },
  { label: "Edit Browse KYC", value: "EDIT_BROWSE_KYC" },
  { label: "Browse Exclution Entity", value: "BROWSE_EXCLUSION_ENTITY" },
  {
    label: "Content Activation",
    value: "CONTENT_ACTIVATE_PERMISSION",
  },
  {
    label: "Mark As Seed",
    value: "MARK_AS_SEED",
  },
  {
    label: "Curation Interest sub Interest Edit",
    value: "CURATION_INTEREST_SUBINTEREST",
  },
  {
    label: "Copyright Content Activation",
    value: "ACTIVATE_COPYRIGHT_CONTENT",
  },
  {
    label: "Banned Content Activation",
    value: "ACTIVATE_BANNED_CONTENT",
  },
  {
    label: "Content Deactivation",
    value: "CONTENT_DEACTIVATE_PERMISSION",
  },
  {
    label: "External Brand Content Deactivation",
    value: "EB_CONTENT_DEACTIVATION_PERMISSION",
  },
  {
    label: "Activate Banned Content",
    value: "ACTIVATE_BANNED_CONTENT",
  },
  { value: "ZONE_REVIEW", label: "Zone Review" },
  { value: "VERIFY_WEBSITE_LINK", label: "Verify Website Link" },
  { value: "EDIT_JOSH_LIVE", label: "Edit Josh Live" },
  { value: "EDIT_GIFT_ENABLED", label: "Edit Gift Enabled" },
  { value: "EDIT_CREATOR_PAYOUT", label: "Edit Creator Payout" },

  { value: "VIEW_AGENCY_LIMIT", label: "View Agency Limit" },
  { value: "EDIT_AGENCY_LIMIT", label: "Edit Agency Limit" },
  { label: "View Real Stats", value: "VIEW_REAL_STATS" },
  { label: "View Browse Listing", value: "VIEW_BROWSE_LISTINGS" },
  { label: "Edit Browse Listing", value: "EDIT_BROWSE_LISTINGS" },

  { label: "View Browse Listing", value: "VIEW_BROWSE_LISTINGS" },
  { label: "Edit Browse Listing", value: "EDIT_BROWSE_LISTINGS" },
  { label: "View Content Source", value: "VIEW_CONTENT_SOURCE" },
  { label: "Edit Manage FAQ", value: "EDIT_MANAGE_FAQS" },
  { label: "View Manage FAQ", value: "VIEW_MANAGE_FAQS" },
  { label: "Edit Josh Live Config", value: "EDIT_JOSH_LIVE_CONFIG" },
  {
    label: "View Dashboard Transactions",
    value: "VIEW_DASHBOARDS_TRANSACTION",
  },
  { label: "View Embedding", value: "VIEW_EMBEDDING" },
  { label: "Edit Content Private Mode", value: "EDIT_CONTENT_PRIVATE_MODE" },
  { label: "View Video Greetings", value: "VIEW_CMS_VIDEO_GREETINGS" },
  { label: "Edit Video Greetings", value: "EDIT_CMS_VIDEO_GREETINGS" },
  { label: "Edit KYC Failed Status", value: "EDIT_KYC_FAILED_STATUS" },
  { label: "Edit Allow Content Info", value: "EDIT_ALLOW_CONTENT_INFO" },
  { label: "Restore Content", value: "RESTORE_CONTENT" },
  { label: "View Original Video", value: "VIEW_ORIGINAL_VIDEO" },

  { label: "Manage Interest", value: "MANAGE_INTEREST" },
  { label: "Manage Curation", value: "MANAGE_CURATION" },
  { label: "Edit Manage Interest", value: "EDIT_INTEREST" },

  { label: "Manage Strategy", value: "MANAGE_STRATEGY" },

  { label: "Edit Manage Strategy", value: "EDIT_STRATEGY" },
  { label: "Insta Crawling", value: "WEB_CRAWLING" },
  { label: "Youtube Crawling", value: "WEB_CRAWLING_YOUTUBE" },
  { label: "Camera Reorder", value: "CAMERA_REORDER" },
  { label: "View Duplicate Content", value: "VIEW_DUPLICATE_CONTENT" },
  { label: "Edit Duplicate Content", value: "EDIT_DUPLICATE_CONTENT" },
  { label: "Edit AI Caption", value: "EDIT_AI_CAPTION" },
  { label: "Phone No Block", value: "PHONE_USER_BLOCK" },
  { label: "Manage Themification", value: "THEMIFICATION" },
  { label: "Legal Action", value: "LEGAL_ACTION" },
  { label: "Audit CMS User", value: "AUDIT_CMS_USER" },
  { label: "Edit Badges", value: "EDIT_BADGES" },
  { label: "View Badges", value: "VIEW_BADGES" },
  { label: "Manage Audio Story", value: "MANAGE_AUDIO_STORY" },
  { label: "Edit Audio Story", value: "EDIT_AUDIO_STORY" },
  { label: "Manage Audio Series", value: "MANAGE_AUDIO_SERIES" },
  { label: "Edit Audio Series", value: "EDIT_AUDIO_SERIES" },
  {
    label: "Manage Audio Story Collections",
    value: "MANAGE_AUDIO_STORY_COLLECTION",
  },
  {
    label: "Edit Audio Story Collections",
    value: "EDIT_AUDIO_STORY_COLLECTION",
  },
  {
    label: "View Blocked Content",
    value: "VIEW_BLOCKED_CONTENT",
  },
]

export const COMPETITION_LOGO = {
  Roposo: "roposo",
  Vmate: "vmate",
  ShareChat: "sharechat",
  Chingari: "chingari",
  Mitron: "mitron",
  "Vigo Video": "vigo_video",
  "Tangi App": "tangi_app",
  Triller: "triller",
  Firework: "firework",
  Kwai: "kwai",
  HiPi: "hipi",
  "Moj (Sharechat)": "moj_sharechat",
  Zili: "zili",
  "MX Taka tak": "mx_taka_tak",
  Changa: "changa",
  LITLOT: "litlot",
  Chilli5: "chilli5",
  Welike: "welike",
  Madlipz: "madlipz",
  kindda: "kindda",
  Injoy: "injoy",
  kadak: "kadak",
  BIPTOK: "biptok",
  BRILLA: "brilla",
  ALAP: "alap",
  MOBISTAR: "mobistar",
  SHARARA: "sharara",
  ZOOMERANG: "zoomerang",
  "V SHORTS": "v_shorts",
  VSHOTS: "vshots",
  VSKIT: "vskit",
  RIZZLE: "rizzle",
  INDITOK: "inditok",
  SPLASH: "splash",
  ZAPAK: "zapak",
  "Ticklick app": "ticklick_app",
  MiqTok: "miqtok",
  "4Fun": "4fun",
  "TIK TOK": "tik_tok",
  "snack video": "snack_video",
  HELO: "helo",
  "INSTA REELS": "insta_reels",
  DUBSMASH: "dubsmash",
  LIKEE: "likee",
  Tiki: "tiki",
  InShot: "inshot",
}

export const collectionElements = {
  CREATOR: [{ label: "Video", value: "VIDEO" }],
  BANNER: [{ label: "Video", value: "VIDEO" }],
  CONTEST: [{ label: "Video", value: "VIDEO" }],
  HASHTAG: [{ label: "Video", value: "VIDEO" }],
  CHALLENGE: [{ label: "Video", value: "VIDEO" }],
  MUSIC_ARTIST: [{ label: "Music", value: "MUSIC" }],
  MUSIC_PLAYLIST: [{ label: "Music", value: "MUSIC" }],
  MUSIC_LABEL: [{ label: "Music", value: "MUSIC" }],
  STICKER: [{ label: "Video", value: "VIDEO" }],
  EFFECT: [{ label: "Video", value: "VIDEO" }],
  TEMPLATE: [{ label: "Video", value: "VIDEO" }],
  GAME: [{ label: "Video", value: "VIDEO" }],
  MUSIC: [{ label: "Video", value: "VIDEO" }],
  ZONE: [
    { label: "Video", value: "VIDEO" },
    { label: "Image", value: "IMAGE" },
    { label: "Social", value: "SOCIAL" },
  ],
  NAMED_COLLECTION: [
    { label: "Video", value: "VIDEO" },
    { label: "Music", value: "MUSIC" },
    { label: "Chip", value: "CHIP" },
    { label: "Combination", value: "COMBINATION" },
    { label: "Creator", value: "CREATOR" },
    { label: "Banner", value: "BANNER" },
    { label: "Sub Banner", value: "SUBBANNER" },
    { label: "Video Banner", value: "VIDEO_BANNER" },
    { label: "Music Artist", value: "MUSIC_ARTIST" },
    { label: "Music Playlist", value: "MUSIC_PLAYLIST" },
    { label: "Music Label", value: "MUSIC_LABEL" },
    { label: "Sticker", value: "STICKER" },
    { label: "Effect", value: "EFFECT" },
    { label: "Template", value: "TEMPLATE" },
    { label: "Game", value: "GAME" },
    { label: "Web View Banner", value: "WEBVIEW_BANNER" },
    { label: "Image", value: "IMAGE" },
    { label: "Zone", value: "ZONE" },
    { label: "Audio Story", value: "AUDIO_STORY" },
  ],
  COLLECTION_FORMAT: [
    { label: "NONE", value: "NONE" },
    { label: "HC", value: "HC" },
    { label: "FC", value: "FC" },
  ],
}

export const COLLECTION_TO_LAYOUT_MAP = {
  VIDEO: [
    { label: "Type 2", value: "LAYOUT_TYPE_2" }, // y
    { label: "Greeting Promo Card", value: "GREETING_PROMO_CARD" },
  ],
  STICKER: [
    { label: "Type 2", value: "LAYOUT_TYPE_2" }, // y
  ],
  EFFECT: [
    { label: "Type 2", value: "LAYOUT_TYPE_2" }, // y
  ],
  TEMPLATE: [
    { label: "Type 2", value: "LAYOUT_TYPE_2" }, // y
  ],
  MUSIC: [
    { label: "Type 5", value: "LAYOUT_TYPE_5" }, // y
    { label: "Type 8", value: "LAYOUT_TYPE_8" },
  ],
  CHIP: [
    { label: "Type 11", value: "LAYOUT_TYPE_11" }, // y
    { label: "Type 12", value: "LAYOUT_TYPE_12" },
  ],
  WEBVIEW_BANNER: [
    { label: "Type 16", value: "LAYOUT_TYPE_16" }, // y
  ],
  CREATOR: [
    { label: "Type 3", value: "LAYOUT_TYPE_3" }, // y
  ],
  LIVE: [
    { label: "Type 17", value: "LAYOUT_TYPE_17" }, // y
  ],
  MUSIC_ARTIST: [
    { label: "Type 3", value: "LAYOUT_TYPE_3" }, //y
  ],
  MUSIC_PLAYLIST: [
    { label: "Type 6", value: "LAYOUT_TYPE_6" }, //y
  ],
  MUSIC_LABEL: [
    { label: "Type 4", value: "LAYOUT_TYPE_4" }, // y
  ],
  BANNER: [
    { label: "Type 1", value: "LAYOUT_TYPE_1" },
    { label: "Type 13", value: "LAYOUT_TYPE_13" },
  ],
  VIDEO_BANNER: [{ label: "Type 9", value: "LAYOUT_TYPE_9" }],
  GAME: [
    { label: "Type 5", value: "LAYOUT_TYPE_5" },
    { label: "Type 8", value: "LAYOUT_TYPE_8" },
    { label: "Type 14", value: "LAYOUT_TYPE_14" },
  ],
  COMMON: [{ label: "Type 15", value: "LAYOUT_TYPE_15" }],
  SUBBANNER: [{ label: "Type 7", value: "LAYOUT_TYPE_7" }],
  IMAGE: [
    { label: "Type 18", value: "LAYOUT_TYPE_18" },
    { label: "Type 19", value: "LAYOUT_TYPE_19" },
    { label: "Greeting Promo Card", value: "GREETING_PROMO_CARD" },
  ],
  ZONE: [{ label: "Type 19", value: "LAYOUT_TYPE_19" }],
  RECENT_SEARCH: [{ label: "Type 22", value: "LAYOUT_TYPE_22" }],
  GALLERY: [{ label: "Type 21", value: "LAYOUT_TYPE_21" }],
  MEDIA: [{ label: "Type 21", value: "LAYOUT_TYPE_21" }],
}

export const USER_DEACTIVATE_REASONS = [
  { label: "Profile Image NSFW", value: "Profile_Image_NSFW" },
  { label: "Profile Name NSFW", value: "Profile_Name_NSFW" },
  { label: "Profile Handle NSFW", value: "Profile_Handle_NSFW" },
  { label: "Profile Description NSFW", value: "Profile_Description_NSFW" },
  { label: "Profile Content NSFW", value: "Profile_Content_NSFW" },
  { label: "Govt. Requested", value: "Govt_Requested" },
  { label: "Creator Requested", value: "Creator_Requested" },
  { label: "Copyright", value: "Copyright" },
  { label: "Account Manager Requested", value: "Account_Manager_Requested" },
  { label: "Impersonation", value: "Impersonation" },
  { label: "Abusive Comments", value: "Abusive_Comments" },
  { label: "Spam Comments", value: "Spam_Comments" },
]

export const SOURCES = [
  { label: "Reels", value: "REELS" },
  { label: "Moz", value: "MOZ" },
  { label: "Youtube Shorts", value: "YOUTUBE_SHORTS" },
  { label: "Yahoo", value: "YAHOO" },
  { label: "Twitter", value: "TWITTER" },
  { label: "Facebook", value: "FACEBOOK" },
]

export const EVENT_TYPES = [
  { label: "Date", value: "date" },
  { label: "Day", value: "day" },
  { label: "Part of Day", value: "part_of_day" },
  { label: "Religious Festivals", value: "religious_festivals" },
  { label: "National Festivals", value: "national_festivals" },
  { label: "Regional Festivals", value: "regional_festivals" },
  { label: "Celeb B'days", value: "celeb_bdays" },
  { label: "Election", value: "election" },
  { label: "Sports", value: "sports" },
  { label: "RIP", value: "rip" },
]

export const TEAM_TO_DL_MAP = {
  iOS: "creatorissues_ios@verse.in",
  Android: "creatorissues_android@verse.in",
  Moderation: "bismay.nayak@verse.in",
  PWA: "creatorissues_pwa@verse.in",
  Product: "creatorissues_product@verse.in",
}

export const ISSUE_TYPES = {
  LOGIN: {
    label: "Unable to login",
    priority: "high",
  },
  UPLOAD_VIDEO: {
    label: "Unable to upload a video",
    priority: "high",
  },
  CREATOR_HACKED: {
    label: "Creator account is hacked",
    priority: "high",
  },
  MODERATION_ERROR: {
    label: "Moderation error",
    priority: "moderate",
  },
  ACCOUNT_AUTHENTICATION: {
    label: "Request to change account authentication method",
    priority: "moderate",
  },
  LINK_SOCIAL_MEDIA: {
    label: "Unable to link social media",
    priority: "low",
  },
  VIDEO_NOT_APPEARING: {
    label: "Videos not appearing under profile",
    priority: "low",
  },
  FOREIGN_COUNTRY: {
    label: "Using app in foreign contry",
    priority: "low",
  },
  OTHER: {
    label: "Other",
    priority: "low",
  },
}

export const USER_TYPES = [
  {
    label: "Super Admin",
    value: 1,
    allow: [1],
  },
  {
    label: "Admin",
    value: 2,
    allow: [1],
  },
  { label: "Head of Community", value: 3, allow: [1, 2, 3] },
  { label: "Community Team Lead", value: 4 },
  { label: "Josh Manager", value: 5 },
  { label: "Community Account Manager", value: 6 },
  {
    label: "Audio Manager",
    value: 7,
  },
  {
    label: "Issue Manager",
    value: 8,
  },
  {
    label: "Operations Team",
    value: 9,
  },
  {
    label: "Operations Lead",
    value: 10,
  },
  {
    label: "Head of Operations",
    value: 11,
  },
  {
    label: "Ingestion Team",
    value: 12,
  },
  {
    label: "Discovery page managment",
    value: 13,
  },
  {
    label: "Challenge Managment",
    value: 14,
  },
  {
    label: "Promotions",
    value: 15,
  },
  {
    label: "Content Hygine",
    value: 16,
  },
  {
    label: "Notification Team",
    value: 17,
  },
  {
    label: "Customer Service",
    value: 18,
  },
  {
    label: "Moderation Internal",
    value: 19,
  },
  {
    label: "Moderation Agency",
    value: 20,
  },
  {
    label: "Labelling-Deck",
    value: 21,
  },
]

export const NO_PERMISSION_MESSAGE = `You don't have appropriate permission to perform this action`
export const ACTIONS_TO_NAME = {
  activate: "Activated",
  deactivate: "Deactivated",
  tag_content: "Tagged",
  skip_tag_content: "Skipped Tag",
  qc_tag_content_ok: "QC OKed",
  qc_tag_content_modify: "Modified the QC tag",
  labeling_stage_one: "Labeling Stage One",
  labeling_stage_two: "Labeling Stage Two",
  labeling_stage_three: "Labeling Stage Three",
}

export const labelsForViolenceAndGlamour = {
  glamour: {
    non_glamour: "No Glamour",
    masala:
      "G4 - MASALA: 30-50% exposure, Sexually Suggestive,/Bikini Shoot w. high skin show,/CRINGY",
    vulgar_masala:
      "G5 - Vulgar MASALA: 50-70% exposure,Bare Hips/back, Love making with not much parts visible",
    masala_hot_sexy:
      "G3 - The woman is wearing VERY skimpy clothes, and the audio-visual seems to showcase very crude sexual movements or suggestiveness",
    glamorous:
      "G2 - The woman is wearing VERY skimpy clothes, but the audio-visual is classy (premium or international)",
    classy_hot_sexy:
      "G3 - The woman is wearing sexy clothes, with lots of skin visible, but is not making any sexually suggestive moves or actions",
    pretty_girl:
      "G1 - The woman is wearing pretty clothes, with low/ minimal skin show, and is mainly trying to look pretty",
    body_art:
      "G5 - Showcasing body art and tattoos where the focus is on the art; no sexual movements in the video; upper body nudity is allowed but not genitals",
    acceptable_child_nudity_a: "G6 - The focus is on nude child's cuteness",
    acceptable_child_nudity_b:
      "G6 - The focus is on partailly nude child's cuteness",
    partial_adult_nudity_a:
      "G7 - Strong artistic focus and only upper body nudity",
    partial_adult_nudity_b: "G7 - No artistic focus but no genitals visible",
    partial_adult_nudity_c:
      "G7 - Very transparent clothing over the breasts & genital area",
    partial_adult_nudity_d:
      "G7- Non-transparent clothing, only barely covering the breasts & genital area",
    molestation:
      "A man sexually touching a woman without her permission; videos that seem to capture women in compromising positions/ nudity without their permission; hidden camera",
    unacceptable_child_nudity_a:
      "The focus is on nude child's sexuality/genitals",
    unacceptable_child_nudity_b:
      "The focus is on partailly nude child's sexuality/genitals",
    full_adult_nudity_a:
      "Full adult nudity, Strong artistic focus and only upper body nudity",
    full_adult_nudity_b: "No artistic focus but no genitals visible",
  },
  violence: {
    no_violence: "No Violence",
    legal_display_of_weapons: "Legal display of weapons in the video",
    animal_sports: "Animal being used for Sports (no killing or bloodshed)",
    physical_abuse: "Physical Abuse to Humans",
    public_protest: "Public protest",
    property_damage: "Property damage",
  },
  ethnicity: {
    chinese: "Chinese",
    indian: "Indian",
    afro: "Afro",
    white: "White",
    mixed: "Mixed",
    NA: "NA",
  },
}
export const tagTOLabel = {
  video_violence: "Video Violence",
  video_glamour: "Video Glamour",
  look_feel: "Look Feel",
  screen_coverage: "Screen Coverage",
  audio_vulgarity: "Audio Vulgarity",
  background_noise: "Background Noise",
  sound_quality: "Sound Quality",
  competition_logo: "Competition Logo",
  people_in_video: "People In Video",
  male_x_factor: "Male X Factor",
  taxonomy_language: "Taxonomy Language",
  taxonomy_video_format: "Taxonomy Video Format",
  taxonomy_emotions: "Taxonomy Emotions",
  target_theme: "Target Theme",
  video_quality_attributes: "Video Quality Attributes",
  target_languages: "Target Languages",
  taxonomy_genre: "Taxonomy Genre",
  orthogonal_attributes: "Orthogonal Attributes",
  ...labelsForViolenceAndGlamour.glamour,
  ...labelsForViolenceAndGlamour.violence,
  ...LANGUAGE_MAPPING,
  no_language: "No Language",
  "other_comedy_&_fun": "Other Comedy & Fun",
  adult: "Adult",
  movie_clipping: "Movie Clipping",
  song_clipping: "Song/Album Clipping",
  reality_tv_shows: "Reality TV Shows",
  tv_serial_clipping: "TV Serial Clipping",
  reviews: "Reviews",
  trailers: "Trailers",
  film_stars: "Film Stars",
  tv_stars: "Tv Stars",
  movie_clipping: "Movie Clipping",
  song_clipping: "Song/Album Clipping",
  reality_tv_shows: "Reality TV Shows",
  tv_serial_clipping: "TV Serial Clipping",
  reviews: "Reviews",
  trailers: "Trailers",
  gossip: "Gossip",
  film_stars: "Film Stars",
  tv_stars: "TV Stars",
  launch_events: "Launch Events",
  award_events: "Award Events",
  cartoon_shows_or_anime: "Cartoon shows or anime",
  other: "Other",
  entertainment_theme: "Theme",
  comedy_shows_standup: "Comedy Shows & Standup Comedians",
  roasts: "Roasts",
  adult_comedy: "Adult Comedy",
  memes: "Memes",
  other_comedy_fun: "Other Comedy & Fun",
  comedy_n_fun_type: "Comedy & Fun Type",
  kids: "Kids",
  family: "Family",
  couple: "Couple",
  sibling: "Sibling",
  friends: "Friends",
  adult: "Adult",
  "other_comedy_&_fun": "Other Comedy & Fun",
  comedy_n_fun_theme: "Comedy N Fun Theme",
  comedy_n_fun: "Comedy N Fun",
  animal_fails: "Animals fail",
  kids_fails: "Kids fail",
  adult_fail: "Adult fail",
  cctv_fails: "CCTV fails",
  stunt_fail: "Stunt Fail",
  random_fails: "Random fails",
  offbeat_hatke_fails: "Offbeat / Hatke fails",
  accident_disaster: "Accident & Disaster",
  unusual_things_horror_mysteries: "Unusual Things, Horror & Mysteries",
  other_fails: "Other fails",
  surprise_element: "Surprise Element",
  quality_talent: "Quality Talent",
  rating: "Rating",
  relatable: "Relatable",
  presentable: "Presentable",
  simple: "Simple",
  skip_reason: "Skip Reason",
  abusive: "ABUSIVE",
  accident: "ACCIDENT",
  animal_cruelty: "ANIMAL CRUELTY",
  anti_national: "ANTI NATIONAL",
  anti_religion: "ANTI RELIGION",
  blood: "BLOOD",
  blurred: "BLURRED",
  child_nudity: "CHILD NUDITY",
  competitor_logo: "COMPETITOR LOGO",
  banned_organization: "BANNED ORGANIZATION",
  death: "DEATH",
  josh_logo: "JOSH LOGO",
  masala: "MASALA",
  misc: "MISC",
  porn: "PORN",
  small_duration: "SMALL DURATION",
  suicide: "SUICIDE",
  violence: "VIOLENCE",
  entity_festival_groups: "Festival Groups",
  entity_festival_values: "Festival Name",
  target_type: "Taxonomy Genre",
  normal_camera_video: "Normal Camera Video - can be shot on mobile",
  camera_video_text_onscreen: "Camera Video with Text on Screen",
  special_effects_camera_video:
    "Special Effects Camera Video - not possible without editing",
  duet: "Duet - Two videos side by side, people interacting",
  reactions: "Reactions - Two videos, one reacting to another",
  slide_show: "Slide Show - one or more images joined together",
  animation_cartoons_digitalart: "Animation / Cartoons / Digital Art",
  clips_n_recordings: "Clips & Recordings",
  audio_language: "Audio Language",
  target_sub_type: "Taxonomy Genre",
  target_theme: "Taxonomy Genre",
  taxonomy_video_quality: "Glamour & Violence",
  taxonomy_elements: "Elements",
  city_tier: "City Tier",
}

export const emailListForNotifWorthyDeactivation = [
  "shahbaz.hasan@verse.in",
  "james.pj@eternoinfotech.com",
  "sharanajata.mishra@eternoinfotech.com",
  "ramjendra.dixit@verse.in",
  "yuvika.agrawal@verse.in",
  "bismay.nayak@verse.in",
  "dipta.pramanick@verse.in",
  "dheeraj.ram@verse.in",
  "debosmita.dey@verse.in",
  "joshnotify@eternoinfotech.com",
]

export const taxonomySideBarKeyValue = {
  skit_humanity: "Skit - Humanity",
  skit_romantic_heartbreak: "Skit - Romantic/Heart break",
  skit_family_love_respect: "Skit - Family love/Respect",
  skit_patriotic: "Skit - Patriotic",
  lipsync_romantic_heartbreak: "Lip Sync - Romantic/Heart break",
  lipsync_dialogue: "Lip Sync - Dialogue",
  movie_tv_ott_clips: "Movie/TV/OTT Clips",
  movie_tv_ott_celebs: "Movie/TV/OTT Celebs",
  video_selfie: "Video selfie",
  entertainment: "Entertainment",
  comedy_n_fun: "Comedy & Fun",
  fails: "Accidents & Fails",
  dance: "Dance",
  poetry_or_shayari: "Poetry or Shayari",
  pranks: "Pranks",
  stunts: "Stunts",
  status_wishes: "Status & Wishes",
  fun_party: "Fun & Party",
  food_cooking: "Food & Cooking",
  fitness_health: "Fitness & Health",
  beauty_fashion: "Beauty & Fashion",
  education: "Education",
  astrology: "Astrology",
  generic_astro: "Generic Astro",
  guru_speaks_motivational_spiritual_patriotic:
    "Guru Speaks - Motivational / Spiritual / Patriotic",
  gods_or_goddess: "Gods or Goddess",
  pets_wildlife_sealife: "Pets / Wildlife / Sealife",
  cars_bikes_aircrafts: "Cars, Bikes & Planes",
  gadgets: "Gadgets",
  toys: "Toys",
  arts_crafts: "Arts & Crafts",
  diy_hacks: "DIY & Hacks",
  photography: "Photography",
  sports: "Sports",
  cricket: "Cricket",
  football: "Football",
  magic_performance: "Magic Performance",
  architecture: "Architecture",
  travel: "Travel",
  news: "News",
  cant_identify_genre: "Can't Identify",
  romantic_heartbreak: "Romantic/Heart Break",
  family_love: "Family love",
  festivals: "Festivals",
  good_morning_wishes: "Good morning wishes",
  good_day_wishes: "Good day wishes",
  good_night_wishes: "Good night wishes",
  motivational: "Motivational",
  other_imp_days_national_days: "Other Imp Days - National days",
  devotional: "Devotional",
  patriotic: "Patriotic",
  image_selfie: "Image selfie",
  type_non_veg: "Is it Veg or Non Veg?",
  non_veg: "Non Veg",
  veg: "Veg",
  indoor: "Indoor",
  gym: "Gym",
  park: "Park",
  rooftop: "Rooftop",
  sky: "Sky",
  road_track: "Road / Track",
  fields_farms: "Fields / Farms",
  jungle: "Jungle",
  beach: "Beach",
  water_body: "Water Body",
  mountains: "Mountains",
  airport: "Airport",
  railway_station: "Railway Station",
  stadium: "Stadium",
  shopping_mall_market: "Shopping Mall / Market",
  hotel_restaurant: "Hotel/Restaurant",
  beautiful_architecture: "Beautiful Architecture",
  famous_architecture: "Famous Architecture",
  temple: "Temple",
  masjid_mosque: "Masjid (Mosque)",
  gurudwara: "Gurudwara",
  church: "Church",
  mixed: "Mixed",
  international: "International",
  international_fair: "International Fair",
  international_black: "International Black",
  south_asian_chinese: "South Asian / Chinese",
  rural: "Rural",
  cant_identify: "Can't Identify",
  unacceptable_child_nudity: "Unacceptable child nudity",
  "full_adult_nudity#b": "Full adult nudity",
  acceptable_child_nudity: "Acceptable child nudity",
  "partial_adult_nudity#c": "Partial adult nudity",
  "partial_adult_nudity#d": "Partial adult nudity",
  pretty_girl: "Pretty girl",
  glamorous: "Glamorous",
  classy_hot_sexy: "Classy hot sexy",
  masala: "Masala",
  vulgar_masala: "Vulgar masala",
  physical_abuse: "Physical abuse",
  animal_sports: "Animal Sports",
  legal_display_of_weapons: "Legal Display Of Weapons",
  all: "All",
  north: "North",
  south: "South",
  few: "Few",
  non_glamour: "No Glamour",
  no_violence: "No Violence",
  hinduism: "Hinduism",
  islam: "Islam",
  sikhism: "Sikhism",
  christianity: "Christianity",
  jainism: "Jainism",
  buddhism: "Buddhism",
  other_religions: "Other Religions",
  brahma: "Brahma",
  vishnu: "Vishnu",
  shiva: "Shiva",
  laxmi: "Laxmi",
  saraswati: "Saraswati",
  ganesha: "Ganesha",
  krishna: "Krishna",
  ram: "Ram",
  hanuman: "Hanuman",
  durga: "Durga",
  kali: "Kali",
  other_gods: "Other gods",
  life_management: "Life management",
  relationship_tips: "Relationship tips",
  self_grooming: "Self Grooming",
  success_motivation: "Success series",
  money_motivation: "Money",
  good_manners: "Good manners",
  communication_skills: "Improving Communication Skills",
  patriotism: "Patriotism",
  social_contribution: "Contributing to society(Willingness to change world",
  zodiac_horoscope: "Raashi Bhavishya",
  business_horoscope: "Business/Shares/Stocks/Investments/Profit/Loss etc",
  relationship_horoscope: "Relationships",
  matrimonial_horoscope: "Matrimonial",
  career_horoscope: "Career/Jobs/Education",
  general_prediction: "General Prediction",
  nakshatra_based_horoscope: "Festivals/Nakshtra/Planetory Changes",
  others: "Others",
  zone: "zone",
  a: "Grade A",
  b: "Grade B",
  c: "Grade C",
  indian: "Indian",
  international: "International",
  single: "Single",
  multiple: "Multiple",
  chinese: "Chinese",
  afro: "Afro",
  white: "White",
  mixed: "Mixed",
  na: "NA",
}

export const agencyNames = [
  { value: "PALETTEINTERACTIVE", label: "Palette Interactive" },
  { value: "SRMFILMS", label: "SRM Film" },
  { value: "OPPORTURE", label: "Opporture" },
  { value: "INTERNAL", label: "Internal" },
  { value: "HEXAWARE", label: "Hexaware" },
]

export const deskTypeOptions = [
  { label: "Labelling", value: "LABELLING" },
  { label: "Moderation", value: "MODERATION" },
  { label: "Taxonomy", value: "TAXONOMY" },
  { label: "TAXONOMY STAGE 1", value: "TAXONOMY_STAGE_1" },
  { label: "TAXONOMY STAGE 2", value: "TAXONOMY_STAGE_2" },
  { label: "TAXONOMY STAGE 3", value: "TAXONOMY_STAGE_3" },
  { label: "Taxonomy Qc", value: "TAXONOMY_QC" },
  { label: "Taxonomy Internal QC", value: "TAXONOMY_INTERNAL_QC" },
  { label: "ZONE APPROVAL", value: "ZONE_APPROVAL" },
  { label: "Trending", value: "TRENDING" },
  { label: "REVIEW WEBSITE LINK", value: "REVIEW_WEBSITE_LINK" },
  { label: "ZONE REVIEW", value: "ZONE_REVIEW" },
]

export const creatorProfileV2 = [
  { label: "ORIGINAL HQ CREATOR", value: "ORIGINAL_HQ_CREATOR" },
  { label: "ORIGINAL NON HQ CREATOR", value: "ORIGINAL_NON_HQ_CREATOR" },
  { label: "UPLOADER DUMPER", value: "UPLOADER_DUMPER" },
  { label: "UPLOADER NON DUMPER", value: "UPLOADER_NON_DUMPER" },
  { label: "UPLOADER CURATOR", value: "UPLOADER_CURATOR" },
]
