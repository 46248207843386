import React from "react"
import { Card, CardBody, Col, Button, Row, CardImg, Badge } from "reactstrap"
import { Select, Input, Tabs, Tooltip } from "antd"
import { getImage } from "./util"
import "./styles.css"

const { Option } = Select
const { TabPane } = Tabs
export const COLLECTION_PROPERTIES = {
  CHALLENGE: {
    cardClass: "challenge-card-container",
    name: "Challenge",
    icon: "bx bx-trophy",
  },

  CREATOR: {
    cardClass: "user-card-container",
    name: "Creator",
    icon: "bx bx-user",
  },

  CONTEST: {
    cardClass: "contest-card-container",
    name: "Contest",
    icon: "fas fa-trophy",
  },

  HASHTAG: {
    cardClass: "hashtag-card-container",
    name: "Hashtag",
    icon: "bx bx-hash",
  },
  MUSIC_ARTIST: {
    cardClass: "discovery-card-container",
    icon: "bx bx-microphone",
    name: "Music Artist",
  },
  MUSIC_LABEL: {
    cardClass: "discovery-card-container",
    icon: "bx bx-play-circle",
    name: "Music Label",
  },
  MUSIC_PLAYLIST: {
    cardClass: "discovery-card-container",
    icon: "bx bxs-playlist",
    name: "Music Playlist",
  },
  NAMED_COLLECTION: {
    cardClass: "discovery-card-container",
    name: "Named Collection",
    icon: "bx bx-collection",
  },
  STICKER: {
    cardClass: "discovery-card-container",
    name: "Sticker",
    icon: "bx bx-sticker",
  },
  EFFECT: {
    cardClass: "discovery-card-container",
    name: "Effect",
    icon: "bx bxs-magic-wand",
  },
  GAME: {
    cardClass: "discovery-card-container",
    name: "Game",
    icon: "bx bx-bot",
  },
  TEMPLATE: {
    cardClass: "discovery-card-container",
    name: "Template",
    icon: "bx bx-table",
  },
  MUSIC: {
    cardClass: "discovery-card-container",
    name: "Music",
    icon: "bx bx-music",
  },
  ZONE: {
    cardClass: "discovery-card-container",
    name: "Zone",
    icon: "bx bx-radio-circle",
  },
}

const TYPE_TO_KEY_MAP = {
  BANNER: "banner_uuid",
  SUBBANNER: "banner_uuid",
  MUSIC_ARTIST: "music_artist_uuid",
  MUSIC_LABEL: "music_label_uuid",
  MUSIC_PLAYLIST: "music_playlist_uuid",
  NAMED_COLLECTION: "collection_uuid",
  VIDEO_BANNER: "banner_uuid",
  STICKER: "asset_id",
  EFFECT: "id",
  WEBVIEW_BANNER: "banner_uuid",
  CHIP: "chip_uuid",
  TEMPLATE: "template_uuid",
  GAME: "game_uuid",
}

export class RenderEntityCards extends React.Component {
  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div
            className="hashtag-card-container"
            style={{ paddingLeft: "60px" }}
          >
            {this.props.cardData.map(data => {
              return (
                <div
                  onClick={() =>
                    this.props.callback(data[TYPE_TO_KEY_MAP[this.props.type]])
                  }
                >
                  <Card
                    className={`${
                      this.props.selectedData.includes(
                        data[TYPE_TO_KEY_MAP[this.props.type]]
                      )
                        ? "border-2 border-success"
                        : ""
                    } text-center shadow-lg`}
                    style={{ width: 200 }}
                  >
                    {this.props.type !== "NAMED_COLLECTION" ? (
                      data.thumbnail ||
                      data.static_thumbnail ||
                      data.web_url ||
                      data.icon_url ? (
                        <CardImg
                          src={
                            data.thumbnail ||
                            data.static_thumbnail ||
                            data.web_url ||
                            data.icon_url
                          }
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <div
                          className="py-5"
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            backgroundColor: "lightgray",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <i
                            className={`${getImage({
                              collectionType: this.props.type,
                            })} fa-8x text-black-50`}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className="py-5"
                        style={{
                          height: "200px",
                          cursor: "pointer",
                          backgroundColor: "lightgray",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <i
                          className={`${getImage({
                            collectionType: this.props.type,
                          })}
                    fa-8x text-black-50`}
                        />
                      </div>
                    )}
                    <CardBody className="font-size-16">
                      {data.title_ops ||
                        data.title ||
                        data.page_title ||
                        data.heading_title ||
                        data.webview_uuid ||
                        data.chip_uuid}
                    </CardBody>
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}
export class RenderStickerCards extends React.Component {
  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div
            className="hashtag-card-container"
            style={{ paddingLeft: "60px" }}
          >
            {this.props.cardData.map(data => {
              return (
                <div
                  onClick={() =>
                    this.props.callback(data[TYPE_TO_KEY_MAP[this.props.type]])
                  }
                >
                  <Card
                    className={`${
                      this.props.selectedData.includes(
                        data[TYPE_TO_KEY_MAP[this.props.type]]
                      )
                        ? "border-2 border-success"
                        : ""
                    } text-center shadow-lg`}
                    style={{ width: 200 }}
                  >
                    {this.props.type !== "NAMED_COLLECTION" ? (
                      data.sticker_thumbnail_url || data.sticker_url ? (
                        <CardImg
                          src={data.sticker_thumbnail_url || data.sticker_url}
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <div
                          className="py-5"
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            backgroundColor: "lightgray",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <i
                            className={`${getImage({
                              collectionType: this.props.type,
                            })} fa-8x text-black-50`}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className="py-5"
                        style={{
                          height: "200px",
                          cursor: "pointer",
                          backgroundColor: "lightgray",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <i
                          className={`${getImage({
                            collectionType: this.props.type,
                          })}
                    fa-8x text-black-50`}
                        />
                      </div>
                    )}
                    <CardBody className="font-size-16">
                      {data.sticker_name + ` (${data.sticker_mime_type})`}
                    </CardBody>
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export class RenderStickers extends React.Component {
  render() {
    const { selectedData } = this.props
    const { rows = [] } = this.props.cardData
    const stickerIds = selectedData.map(item => item.stickerId)
    return (
      <div>
        {rows && rows.length > 0 && (
          <div
            className="hashtag-card-container"
            style={{ paddingLeft: "60px" }}
          >
            {rows.map(data => {
              return (
                <div onClick={() => this.props.callback(data)}>
                  <Card
                    className={`${
                      stickerIds.includes(data["stickerId"])
                        ? "border-2 border-success"
                        : ""
                    } text-center shadow-lg`}
                    style={{ width: 200 }}
                  >
                    {this.props.type !== "NAMED_COLLECTION" ? (
                      data.thumbnailUrl || data.stickerUrl ? (
                        <CardImg
                          src={data.thumbnailUrl || data.stickerUrl}
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <div
                          className="py-5"
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            backgroundColor: "lightgray",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <i
                            className={`${getImage({
                              collectionType: "STICKER",
                            })} fa-8x text-black-50`}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className="py-5"
                        style={{
                          height: "200px",
                          cursor: "pointer",
                          backgroundColor: "lightgray",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <i
                          className={`${getImage({
                            collectionType: "STICKER",
                          })}
                    fa-8x text-black-50`}
                        />
                      </div>
                    )}
                    <CardBody className="font-size-16">
                      {data.stickerName + ` (${data.mimeType})`}
                    </CardBody>
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export class RenderStickerSubType extends React.Component {
  render() {
    const { selectedData } = this.props
    // const mappedAssets = [...new Map(selectedData.map(item => [item["pk"], item])).values()];
    const subTypeIds = selectedData.map(item => item.subTabId)
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div
            className="hashtag-card-container"
            style={{ paddingLeft: "60px" }}
          >
            {this.props.cardData.map(data => {
              return (
                <div onClick={() => this.props.callback(data)}>
                  <Card
                    className={`${
                      subTypeIds.includes(data["subTabId"])
                        ? "border-2 border-success"
                        : ""
                    } text-center shadow-lg`}
                    style={{ width: 200 }}
                  >
                    {this.props.type !== "NAMED_COLLECTION" ? (
                      data.thumbnailUrl || data.stickerUrl ? (
                        <CardImg
                          src={data.thumbnailUrl || data.stickerUrl}
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <div
                          className="py-5"
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            backgroundColor: "lightgray",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <i
                            className={`${getImage({
                              collectionType: "STICKER",
                            })} fa-8x text-black-50`}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className="py-5"
                        style={{
                          height: "200px",
                          cursor: "pointer",
                          backgroundColor: "lightgray",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <i
                          className={`${getImage({
                            collectionType: "STICKER",
                          })}
                    fa-8x text-black-50`}
                        />
                      </div>
                    )}
                    <CardBody className="font-size-16">
                      {data.displayName ? data.displayName : data.subTabId}
                    </CardBody>
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}
export class RenderEffectCards extends React.Component {
  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div
            className="hashtag-card-container"
            style={{ paddingLeft: "60px" }}
          >
            {this.props.cardData.map(data => {
              return (
                <div
                  onClick={() =>
                    this.props.callback(data[TYPE_TO_KEY_MAP[this.props.type]])
                  }
                >
                  <Card
                    className={`${
                      this.props.selectedData.includes(
                        data[TYPE_TO_KEY_MAP[this.props.type]]
                      )
                        ? "border-2 border-success"
                        : ""
                    } text-center shadow-lg`}
                    style={{ width: 200 }}
                  >
                    {this.props.type !== "NAMED_COLLECTION" ? (
                      data.cover_url ? (
                        <CardImg
                          src={data.cover_url}
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <div
                          className="py-5"
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            backgroundColor: "lightgray",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <i
                            className={`${getImage({
                              collectionType: this.props.type,
                            })} fa-8x text-black-50`}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className="py-5"
                        style={{
                          height: "200px",
                          cursor: "pointer",
                          backgroundColor: "lightgray",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <i
                          className={`${getImage({
                            collectionType: this.props.type,
                          })}
                    fa-8x text-black-50`}
                        />
                      </div>
                    )}
                    <CardBody className="font-size-16">
                      <div className="font-size-14 text-truncate font-weight-semibold">
                        {data.name ? data.name : <div>&nbsp;</div>}
                      </div>
                      {data.type_name ? (
                        <Tooltip title={"Type"}>
                          <Badge className="badge-soft-primary ms-1 font-size-13 my-2">
                            {data.type_name}
                          </Badge>
                        </Tooltip>
                      ) : (
                        <div>&nbsp;</div>
                      )}
                    </CardBody>
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}
export class RenderTemplateCard extends React.Component {
  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div
            className="hashtag-card-container"
            style={{ paddingLeft: "60px" }}
          >
            {this.props.cardData.map(data => {
              return (
                <div
                  onClick={() =>
                    this.props.callback(data[TYPE_TO_KEY_MAP[this.props.type]])
                  }
                >
                  <Card
                    className={`${
                      this.props.selectedData.includes(
                        data[TYPE_TO_KEY_MAP[this.props.type]]
                      )
                        ? "border-2 border-success"
                        : ""
                    } text-center shadow-lg`}
                    style={{ width: 200 }}
                  >
                    {this.props.type !== "NAMED_COLLECTION" ? (
                      data.static_thumbnail || data.dynamic_thumbnail ? (
                        <CardImg
                          src={data.static_thumbnail || data.dynamic_thumbnail}
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <div
                          className="py-5"
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            backgroundColor: "lightgray",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <i
                            className={`${getImage({
                              collectionType: this.props.type,
                            })} fa-8x text-black-50`}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className="py-5"
                        style={{
                          height: "200px",
                          cursor: "pointer",
                          backgroundColor: "lightgray",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <i
                          className={`${getImage({
                            collectionType: this.props.type,
                          })}
                    fa-8x text-black-50`}
                        />
                      </div>
                    )}
                    <CardBody className="font-size-16">
                      <div className="font-size-14 text-truncate font-weight-semibold">
                        {data.asset_label ? data.asset_label : data.asset_id}
                      </div>
                      {data.category ? (
                        <Tooltip title={"Category"}>
                          <Badge className="badge-soft-primary ms-1 font-size-13 my-2">
                            {data.category}
                          </Badge>
                        </Tooltip>
                      ) : (
                        <div>&nbsp;</div>
                      )}
                      {/* <div className="">
                        {data.subtitle && (
                          <Badge className="badge-soft-primary ms-1 font-size-13 my-2">
                            {data.subtitle}
                          </Badge>
                        )}
                      </div> */}
                    </CardBody>
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}
export class RenderGameCard extends React.Component {
  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div
            className="hashtag-card-container"
            style={{ paddingLeft: "60px" }}
          >
            {this.props.cardData.map(data => {
              return (
                <div
                  onClick={() =>
                    this.props.callback(data[TYPE_TO_KEY_MAP[this.props.type]])
                  }
                >
                  <Card
                    className={`${
                      this.props.selectedData.includes(
                        data[TYPE_TO_KEY_MAP[this.props.type]]
                      )
                        ? "border-2 border-success"
                        : ""
                    } text-center shadow-lg`}
                    style={{ width: 200 }}
                  >
                    {this.props.type !== "NAMED_COLLECTION" ? (
                      data.thumbnail || data.cover_url ? (
                        <CardImg
                          src={data.thumbnail || data.cover_url}
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            opacity: "0.8",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                      ) : (
                        <div
                          className="py-5"
                          style={{
                            height: "200px",
                            cursor: "pointer",
                            backgroundColor: "lightgray",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                          }}
                        >
                          <i
                            className={`${getImage({
                              collectionType: this.props.type,
                            })} fa-8x text-black-50`}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className="py-5"
                        style={{
                          height: "200px",
                          cursor: "pointer",
                          backgroundColor: "lightgray",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <i
                          className={`${getImage({
                            collectionType: this.props.type,
                          })}
                    fa-8x text-black-50`}
                        />
                      </div>
                    )}
                    <CardBody className="font-size-16">
                      {data.title ? data.title : <div>&nbsp;</div>}
                    </CardBody>
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}
export class RenderCollectionCards extends React.Component {
  render() {
    return (
      <div>
        {this.props.sorterList && this.props.sorterList.length > 0 && (
          <div className="hashtag-card-container">
            {this.props.sorterList.map(data => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.props.callback({
                      [`${data.collectionId}:${data.collectionType}`]: data,
                    })
                  }
                >
                  <Card
                    className={`${
                      Object.keys(this.props.selectedData).includes(
                        `${data.collectionId}:${data.collectionType}`
                      )
                        ? "border-2 border-success"
                        : ""
                    } text-center shadow-lg`}
                    style={{
                      height: "260px",
                      borderRadius: "10px",
                      backgroundColor: "",
                    }}
                  >
                    {data.thumbnail ||
                    data.profile_pic ||
                    data.banner_url ||
                    data.web_url ||
                    data.chip_url ? (
                      <CardImg
                        src={
                          data.thumbnail ||
                          data.profile_pic ||
                          data.banner_url ||
                          data.web_url ||
                          data.chip_url
                        }
                        style={{
                          height: "200px",
                          cursor: "pointer",
                          opacity: "0.8",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      />
                    ) : (
                      <div
                        className="py-5"
                        style={{
                          height: "200px",
                          cursor: "pointer",
                          backgroundColor: "lightgray",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <i
                          className={`${getImage(data)} fa-8x text-black-50`}
                        />
                      </div>
                    )}
                    <CardBody className="font-size-16">
                      {data["title"] ||
                        data["heading_title"] ||
                        data["hashtag"] ||
                        data["name"] ||
                        data["collectionId"]}
                    </CardBody>
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export class RenderCollectionFilter extends React.Component {
  render() {
    return (
      <Card className="shadow-lg">
        <CardBody>
          <Row
            className="d-flex justify-content-between"
            style={{ margin: "0px 60px 10px 60px", width: "100%" }}
            gutter={this.props.isMobile ? 0 : 8}
          >
            <Col sm="10">
              <Row>
                <Col sm="4" className="my-1">
                  <Select
                    allowClear={true}
                    placeholder="Creator Type"
                    onChange={value => {
                      this.props.initializeTitle(),
                        this.props._handleFilterChange(value, "collectionType")
                    }}
                    defaultValue={"CHALLENGE"}
                    style={{ width: "100%" }}
                  >
                    <Option value="CHALLENGE">Challenge</Option>
                    <Option value="CREATOR">Creator</Option>
                    <Option value="HASHTAG">Hashtag</Option>
                    <Option value="MUSIC_ARTIST">Music Artist</Option>
                    <Option value="MUSIC_LABEL">Music Label</Option>
                    <Option value="MUSIC_PLAYLIST">Music Playlist</Option>
                    <Option value="NAMED_COLLECTION">Named Collection</Option>
                  </Select>
                </Col>
                <Col>
                  <Input.Group compact={true} className="my-1">
                    <Input
                      placeholder={"Collection Title"}
                      onChange={e =>
                        this.props._handleFilterChange(e.target.value, "title")
                      }
                      allowClear={true}
                      name="title"
                      style={{ width: "100%" }}
                    />
                  </Input.Group>
                </Col>
                <Col sm="2">
                  <Button
                    color="success"
                    className="d-flex"
                    onClick={() => {
                      this.props._searchHashtag()
                    }}
                  >
                    <div className="mx-2">Apply</div>
                    <span>
                      <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
                    </span>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col sm="2">
              <Button
                color="primary"
                onClick={() => {
                  this.props.onAdd()
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

// export class RenderStickers extends React.Component {
//   _getInfo = data => {
//     return data.name ? (
//       <div> Name: {data.name} </div>
//     ) : (
//       <div> ID: {data.id} </div>
//     )
//   }

//   render() {
//     const { selectedData } = this.props
//     const { rows = [] } = this.props.cardData
//     // const mappedAssets = [...new Map(selectedData.map(item => [item["pk"], item])).values()];
//     const stickerIds = selectedData.map(item => item.stickerId)
//     return (
//       <div>
//         {rows && rows.length > 0 && (
//           <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
//             {rows.map(data => {
//               return (
//                 <div
//                   style={{ margin: "10px 10px 10px 60px" }}
//                   onClick={() => this.props.callback(data)}
//                 >
//                   <Card
//                     style={{ width: 200 }}
//                     cover={
//                       <img
//                         src={data.thumbnailUrl}
//                         width="150"
//                         height="200"
//                         alt=""
//                       />
//                     }
//                     actions={[
//                       <Popover
//                         trigger="hover"
//                         content={() => this._getInfo(data)}
//                         color="#D3D3D3"
//                         mouseEnterDelay="1"
//                         destroyTooltipOnHide
//                       >
//                         <InfoCircleOutlined key="info" />
//                       </Popover>,
//                       <CheckCircleFilled
//                         key="checked"
//                         style={{
//                           color: stickerIds.includes(data["stickerId"])
//                             ? "#28a745"
//                             : "grey",
//                         }}
//                       />,
//                     ]}
//                   >
//                     <Meta
//                       title={
//                         data.stickerName ? data.stickerName : data.stickerId
//                       }
//                     />
//                   </Card>
//                 </div>
//               )
//             })}
//           </div>
//         )}
//         {rows && rows.length <= 0 && <div>No data present</div>}
//       </div>
//     )
//   }
// }
