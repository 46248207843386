import React from "react"
import { Upload, message } from "antd"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle, Form } from "reactstrap"
import ValidatorForm, {
  Select,
  TextField,
  TextArea,
  Switch,
  MultiSelect,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { ISSUE_TYPES, TEAM_TO_DL_MAP } from "../../constants/uiConstants"
import toastr from "toastr"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import "./form.css"
export default class CreateIssueForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      formElementType: this.props.data && this.props.data.element_type,
      selectedFiles: [],
      priority: undefined,
      isAdmin: this.props.isAdmin,
      additionalPocs: [],
    }
  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  _form = () => {
    const { issueData = [] } = this.props
    return (
      <Card>
        <CardBody>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={6}>
                <TextField
                  label={"Title*"}
                  placeholderLabel="Title"
                  field={"title"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : issueData && issueData.title
                  }
                  className="creator-form"
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Phone Make*"}
                  placeholderLabel="Phone Make"
                  field={"phoneMake"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : issueData && issueData.phoneMake
                  }
                  className="creator-form"
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Phone Model*"}
                  placeholderLabel="Phone Model"
                  field={"phoneModel"}
                  className="creator-form"
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : issueData && issueData.phoneModel
                  }
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm="12" md="6">
                <Select
                  label={"Assigned To"}
                  placeholderLabel="Assigned To"
                  field={"assignedTo"}
                  allowClear={true}
                  className="creator-form"
                  defaultValue={issueData && issueData.assignedTo}
                  type="single-select"
                  options={
                    this.props.cmsUserList &&
                    this.props.cmsUserList.length > 0 &&
                    this.props.cmsUserList.map(user => {
                      return { label: user.email, value: user.email }
                    })
                  }
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Client ID*"}
                  placeholderLabel="Client ID"
                  field={"clientId"}
                  className="creator-form"
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : issueData && issueData.clientId
                  }
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Profile Name*"}
                  placeholderLabel="Profile Name"
                  field={"profileName"}
                  className="creator-form"
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm="12" md="6">
                <MultiSelect
                  label="Responsible Team*"
                  placeholderLabel="Responsible Team"
                  field="pocList"
                  allowClear={true}
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                  options={Object.keys(TEAM_TO_DL_MAP).map(key => {
                    return { label: key, value: TEAM_TO_DL_MAP[key] }
                  })}
                  onChange={val => this.setState({ pocList: val })}
                />
              </Col>
              <Col sm="12" md="6">
                <MultiSelect
                  label="POC List"
                  placeholderLabel="POC List"
                  field="additionalPocs"
                  allowClear={true}
                  className="creator-form"
                  options={
                    this.props.cmsUserList &&
                    this.props.cmsUserList.length > 0 &&
                    this.props.cmsUserList.map(user => {
                      return { label: user.email, value: user.email }
                    })
                  }
                  onChange={val => this.setState({ additionalPocs: val })}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"OS Version*"}
                  placeholderLabel="OS Version"
                  field={"osVersion"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : issueData && issueData.osVersion
                  }
                  className="creator-form"
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"App Version*"}
                  placeholderLabel="App Version"
                  field={"appVersion"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : issueData && issueData.appVersion
                  }
                  className="creator-form"
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Content ID"}
                  placeholderLabel="Content ID"
                  field={"contentUuid"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : issueData && issueData.contentUuid
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"User UUID"}
                  placeholderLabel="User UUID"
                  field={"userUuid"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : issueData && issueData.userUuid
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm="12" md="6">
                <Select
                  label="Issue Type*"
                  placeholderLabel="Issue Type"
                  field="issueType"
                  allowClear={true}
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                  options={Object.keys(ISSUE_TYPES).map(key => {
                    return { label: ISSUE_TYPES[key].label, value: key }
                  })}
                  onChange={val =>
                    this.setState({ priority: ISSUE_TYPES[val].priority })
                  }
                />
              </Col>
              <Col sm="12" md="6">
                <Select
                  label="Issue Severity*"
                  placeholderLabel="Issue Severity"
                  field="issueSeverity"
                  allowClear={true}
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                  defaultValue={this.state.priority}
                  disabled={!this.state.isAdmin}
                  options={[
                    { label: "High", value: "high" },
                    { label: "Moderate", value: "moderate" },
                    { label: "Low", value: "low" },
                  ]}
                />
              </Col>
              <Col sm="12" md="6">
                <Select
                  label="Status*"
                  placeholderLabel="Status"
                  field="status"
                  allowClear={true}
                  className="creator-form"
                  defaultValue={issueData && issueData.status}
                  options={[
                    { label: "Reported", value: "reported" },
                    { label: "Verified and Open", value: "verified_open" },
                    { label: "In Progress", value: "in_progress" },
                    {
                      label: "Waiting for feedback",
                      value: "waiting_for_feedback",
                    },
                    { label: "Closed", value: "closed" },
                  ]}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>

              <Col sm="12" md="6">
                <TextArea
                  label={"Summary*"}
                  placeholderLabel="Summary"
                  field={"summary"}
                  inputType={"text"}
                  validations={["required"]}
                  errors={["This field is required"]}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : issueData && issueData.summary
                  }
                  className="creator-form"
                />
              </Col>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Dropzone</CardTitle>
                    <p className="card-title-desc">
                      {" "}
                      Drop Supporting Assets here
                    </p>
                    <Form>
                      <Dropzone
                        onDrop={acceptedFiles =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="flex" style={{ marginTop: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <Button htmlType="submit" color="primary">
                      {this.props.action === "update" ? "Update" : "Create"}
                    </Button>
                    <Button
                      onClick={() => this.props.onCancel()}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (_.isEmpty(this.state.selectedFiles)) {
      toastr.error("Please Add Supporting Assets")
      return
    }

    if (_.isEmpty(this.state.pocList)) {
      toastr.error("Responsible Teams cannot be empty")
      return
    }

    let data
    if (formData) data = new FormData()
    let modifiedData = formData

    //To get Content ID from link
    let contentId = formData.contentUuid.split("/")
    if (contentId && contentId.length > 0) {
      modifiedData["contentUuid"] = contentId[contentId.length - 1]
    } else {
      modifiedData["contentUuid"] = formData.contentUuid
    }

    //To get User ID from link
    let userIdArr = formData.userUuid.split("/")
    if (userIdArr && userIdArr.length > 0) {
      let useruuid = userIdArr[userIdArr.length - 1]
      if (useruuid.includes("?")) {
        let id = useruuid.split("?")
        modifiedData["userUuid"] = id[0]
      } else {
        modifiedData["userUuid"] = userIdArr[userIdArr.length - 1]
      }
    } else {
      modifiedData["userUuid"] = formData.userUuid
    }

    modifiedData["pocList"] = JSON.stringify(this.state.pocList)
    if (this.state.additionalPocs) {
      modifiedData["additionalPocs"] = JSON.stringify(this.state.additionalPocs)
    }
    modifiedData["reportedBy"] = this.props.currentUser.email
    data.append("fields", JSON.stringify(modifiedData))

    for (let i = 0; i < this.state.selectedFiles.length; i++) {
      data.append("files", this.state.selectedFiles[i])
    }
    this.props.onSubmit(data)
  }

  _render = () => {
    return this._form()
  }
}
