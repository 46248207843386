import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Pagination, Tabs } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import UpsertEntityForm from "./upsertEntity"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import {
  getEntities,
  resetState,
  upsertCollection,
  upsertEntity,
} from "../../appRedux/slices/zeroSearch"
import EntityCard from "../../components/Discovery/entityCard"
import UpsertTrendingPayload from "../CommonTrending/trendingUpsert"
import { markTrending } from "appRedux/slices/trending"
import Chips from "components/CommonFilter/chips"
import {
  manageBannerFilters,
  NO_PERMISSION_MESSAGE,
} from "constants/uiConstants"

import toastr from "toastr"
import { Button } from "@mui/material"

const { TabPane } = Tabs

const COLLECTION_PROPERTIES = {
  BANNER: {
    cardClass: "hashtag-card-container",
    name: "Banners",
    icon: "bx bx-flag",
  },
}

const FILTER_KEYS = [
  {
    key_name: "name",
    display_name: "Name",
  },
  {
    key_name: "bannerId",
    display_name: "Banner Id",
  },
]
class ManageBanners extends ErrorBoundComponent {
  state = {
    showModal: false,
    showTrendingForm: false,
    templateId: "",
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      type:
        this.props.queryParams && this.props.queryParams["type"]
          ? this.props.queryParams["type"]
          : "BANNER",
      name:
        this.props.queryParams && this.props.queryParams["name"]
          ? this.props.queryParams["name"]
          : "",
      bannerId:
        this.props.queryParams && this.props.queryParams["bannerId"]
          ? this.props.queryParams["bannerId"]
          : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getEntities({ filters: _.deepClean(filters) }))
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getEntities({ filters: _.deepClean(filters) }))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({ filters: newFilters })
  }

  _onTrendingClick = bannerData => {
    this.setState({
      showTrendingForm: true,
      action: "create",
      bannerId: bannerData.banner_uuid,
      bannerData: bannerData,
    })
  }

  _onTrendingSubmit = async payload => {
    let response
    response = await this.props.dispatch(
      markTrending({
        data: payload,
        type: "banner",
        entityId: this.state.bannerId ? this.state.bannerId : "",
      })
    )

    this.setState({ showTrendingForm: false, bannerData: null })
  }

  _trendingForm = () => {
    return (
      <div className="view_container">
        <UpsertTrendingPayload
          pageId={this.state.currentPageId}
          type={
            this.props.queryParams && this.props.queryParams.type
              ? this.props.queryParams.type
              : "BANNER"
          }
          onSubmit={this._onTrendingSubmit}
          data={
            this.state.bannerData && this.state.bannerData.trending_meta_cms
              ? this.state.bannerData.trending_meta_cms
              : ""
          }
          action={
            this.state.bannerData &&
            !_.isEmpty(this.state.bannerData.trending_meta_cms)
              ? "update"
              : "create"
          }
          onCancel={() =>
            this.setState({ showTrendingForm: false, bannerId: "" })
          }
        />
      </div>
    )
  }

  _searchEntity = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/banners/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(getEntities({ filters: _.deepClean(filters) }))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/banners/manage?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      ),
        this.props.dispatch(getEntities({ filters: _.deepClean(newFilters) }))
    })
  }

  _onSubmit = async payload => {
    let type
    type = "banner"
    let response
    response = await this.props.dispatch(
      upsertEntity({
        data: payload,
        type,
        action: this.state.action,
      })
    )

    this.setState({ showModal: false, entityData: null })
  }

  _modal = () => {
    return (
      <div className="view_container">
        <UpsertEntityForm
          pageId={this.state.currentPageId}
          type={
            this.props.queryParams && this.props.queryParams.type
              ? this.props.queryParams.type
              : "BANNER"
          }
          onSubmit={this._onSubmit}
          data={this.state.entityData}
          action={this.state.action}
          onCancel={() => this.setState({ showModal: false })}
        />
      </div>
    )
  }

  _onEdit = entityData => {
    this.setState({ entityData, showModal: true, action: "update" })
  }

  _getTitle = () => {
    const type = this.props.queryParams.type
    const typeToLowerCase = type.replace(/_|-|\./g, " ").toLowerCase()
    return (
      <h4>
        {typeToLowerCase.charAt(0).toUpperCase() + typeToLowerCase.slice(1)}
      </h4>
    )
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    this.setState({
      filters: newFilters,
    })
  }

  _searchCollection = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/banners/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    ),
      this.props.dispatch(getEntities({ filters: _.deepClean(filters) }))
  }

  _closeFilters = () => {
    this._searchCollection()
    this.setState({
      showFilters: false,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: manageBannerFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/banners/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getEntities({ filters: _.deepClean(this.state.filters) })
        )
      }
    )
  }

  _buttons = () => {
    return (
      <div className="d-flex justify-content-end my-2">
        <div className="d-flex">
          <Button
            variant="contained"
            className="d-flex"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes("EDIT_BANNERS")
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.setState({ showModal: true, action: "create" })
            }}
          >
            Add
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Auxiliary
          loading={this.props.loading}
          error={_.get(this.props, "common.error")}
        >
          {!this.state.showModal && !this.state.showTrendingForm && (
            <React.Fragment>
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                type="manage_banner"
                toggleFilters={this._toggleFilters}
                filterKeys={FILTER_KEYS}
              />
              {this._buttons()}
              <div className="discovery-card-container">
                {this.props.entityList &&
                  this.props.entityList.map((entity, i) => {
                    return (
                      <EntityCard
                        data={entity}
                        locationList={this.props.locationList}
                        onEditClick={this._onEdit}
                        hideDelete={true}
                        showTrending={true}
                        pageSource="manageEntity"
                        onTrendingClick={this._onTrendingClick}
                        type="BANNER"
                        currentUser={this.props.currentUser}
                        location={this.props.location}
                      />
                    )
                  })}
              </div>
              {this.props.entityList && this.props.entityList.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Pagination
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                    onChange={this._onPageChange}
                    defaultCurrent={this.state.filters.currentPage}
                    total={this.props.total < 10000 ? this.props.total : 10000}
                    pageSize={this.state.filters.pageSize}
                    showSizeChanger={false}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </Auxiliary>
        {this.state.showModal && this._modal()}
        {this.state.showTrendingForm && this._trendingForm()}
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zeroSearch.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "zeroSearch.loading"),
    entityList: _.get(store, "zeroSearch.entities"),
    refetch: _.get(store, "zeroSearch.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageBanners)
