import React from "react"
import { DatePicker } from "antd"
import _ from "lodash"
import { Row, Col, Card, CardBody, CardTitle, Button, Input } from "reactstrap"
import { TextField, Switch } from "../../containers/ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import ReactChipInput from "react-chip-input"
import toastr from "toastr"
import moment from "moment"
import { getS3Path } from "../../clientServices/liveRoomService"
import "./style.css"
import dayjs from "dayjs"

export default class RoomUpsertForm extends ErrorBoundComponent {
  state = {
    formData: {
      id: this.props.data && this.props.data.id ? this.props.data.id : "",
      title:
        this.props.data && this.props.data.title ? this.props.data.title : "",
      featuredIndex:
        this.props.data && this.props.data.featuredIndex
          ? this.props.data.featuredIndex
          : "",
      interestSubCategoryName:
        this.props.data && this.props.data.interestSubCategoryName
          ? this.props.data.interestSubCategoryName
          : "",
      interestCategoryName:
        this.props.data && this.props.data.interestCategoryName
          ? this.props.data.interestCategoryName
          : "",
      hashTags:
        this.props.data && this.props.data.hashTags
          ? this.props.data.hashTags
          : [],
      hostId:
        this.props.data && this.props.data.hostId ? this.props.data.hostId : "",
      hostName:
        this.props.data && this.props.data.hostName
          ? this.props.data.hostName
          : "",
      roomCategory:
        this.props.data && this.props.data.roomCategory
          ? this.props.data.roomCategory
          : "VOD",
      streamType:
        this.props.data && this.props.data.streamType
          ? this.props.data.streamType
          : "",
      showLiveLabel:
        this.props.data && this.props.data.showLiveLabel
          ? this.props.data.showLiveLabel
          : false,
      isGiftingEnabled:
        this.props.data && this.props.data.isGiftingEnabled
          ? this.props.data.isGiftingEnabled
          : false,
      allowComments:
        this.props.data && this.props.data.allowComments
          ? this.props.data.allowComments
          : false,
      startDateTime:
        this.props.data && this.props.data.startDateTime
          ? this.props.data.startDateTime
          : "",
      endDateTime:
        this.props.data && this.props.data.endDateTime
          ? this.props.data.endDateTime
          : "",
      s3LocationInputPath:
        this.props.data && this.props.data.s3LocationInputPath
          ? this.props.data.s3LocationInputPath
          : "",
      playerInfo:
        this.props.data && this.props.data.playerInfo
          ? this.props.data.playerInfo
          : {
              clickable: false,
              autoPlay: true,
            },
      tpLiveMode:
        this.props.data && this.props.data.tpLiveMode
          ? this.props.data.tpLiveMode
          : "Landscape",
    },
    errors: {},
    chips: ["hashtags"],
    videoType: "",
    videoURL: "",
    processingURL: false,
  }

  addChip = value => {
    const chips = this.state.formData.hashTags.slice()
    chips.push(value)
    let formData = this.state.formData
    formData["hashTags"] = chips
    this.setState({ formData })
  }

  removeChip = index => {
    const chips = this.state.formData.hashTags.slice()
    chips.splice(index, 1)
    let formData = this.state.formData
    formData["hashTags"] = chips
    this.setState({ formData })
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    if (field == "roomCategory" && value == "VOD") {
      formData.streamType = ""
    }
    this.setState({ formData })
  }
  _handlePlayerInfoFieldChange = (field, value) => {
    let formData = _.cloneDeep(this.state.formData.playerInfo)
    formData[field] = value
    const newInfo = { ...this.state.formData, ...{ playerInfo: formData } }
    this.setState({ formData: newInfo })
  }

  _handleFieldToggleChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value
    this.setState({ formData })
  }
  _handleFileUpload = (key, file) => {
    this.setState({
      [key]: file,
    })
  }
  _findFormErrors = () => {
    const newErrors = {}
    const {
      hostName,
      hostId,
      title,
      s3LocationInputPath,
      startDateTime,
      playerInfo,
      streamType,
    } = this.state.formData
    const { s3Location } = this.state
    if (!hostName || hostName === "")
      newErrors.hostName = "Please provide the Host Name!"
    if (!hostId || hostId === "")
      newErrors.hostId = "Please provide the Host id!"
    if (!title || title === "") newErrors.title = "Please provide the Title!"
    if (!startDateTime || startDateTime === "")
      newErrors.startDateTime = "Please provide the Start Date!"

    if (streamType == "YOUTUBE_CDN") {
      if (!playerInfo.type || playerInfo.type == "") {
        newErrors.type = "Please provide type"
      }

      if (!playerInfo.videoId || playerInfo.videoId == "") {
        newErrors.videoId = "Please provide Video Id"
      }
      if (!playerInfo.videoOrientation || playerInfo.videoOrientation == "") {
        newErrors.videoOrientation = "Please provide Video Orientation"
      }
    }
    if (this.props.action == "create" && streamType != "YOUTUBE_CDN") {
      if ((!s3LocationInputPath || s3LocationInputPath === "") && !s3Location) {
        if (!s3LocationInputPath || s3LocationInputPath === "") {
          newErrors.s3LocationInputPath =
            "Please provide the Video Path or Video!"
        }
        if (!s3Location) {
          newErrors.s3Location = "Please provide the Video File!"
        }
      }
    }

    return newErrors
  }

  _processVideo = async () => {
    if (!this.state.videoURL) {
      toastr.error("Video URL is missing.")
    }
    this.setState({ processingURL: true })
    try {
      const path = await getS3Path(this.state.videoURL, this.state.videoType)
      this._handleFieldChange("s3LocationInputPath", path)
    } catch (error) {
    } finally {
      this.setState({ processingURL: false })
    }
  }

  _form = () => {
    const layout = {
      labelCol: { xs: 4, sm: 4, md: 6, lg: 6, xl: 6 },
      wrapperCol: { xs: 20, sm: 20, md: 18, lg: 18, xl: 18 },
    }
    return (
      <div>
        <Row>
          {this.props.action == "update" && (
            <Col sm="12" lg="6" className="pb-2">
              <label>
                {" "}
                Id<span className="text-danger">*</span>
              </label>
              <TextField
                placeholderLabel="Id"
                field={"id"}
                onChange={value => this._handleFieldChange("id", value)}
                className="creator-form"
                disabled={true}
                defaultValue={this.props.data && this.props.data.id}
                errors={["This field is required"]}
                inputFieldSize="medium"
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col sm="12" lg="6" className="pb-2">
            <div>
              <label> Host ID</label>
              <TextField
                placeholderLabel="Host ID"
                style={
                  this.state.errors.hostId ? { border: "1px solid red" } : {}
                }
                field={"hostId"}
                onChange={value => this._handleFieldChange("hostId", value)}
                defaultValue={this.props.data && this.props.data.hostId}
                className="creator-form"
                validations={["required"]}
                errors={["This field is required"]}
                inputFieldSize="medium"
              />
              {this.state.errors?.hostId && (
                <div style={{ color: "red" }}>{this.state.errors.hostId}</div>
              )}
            </div>
          </Col>
          <Col sm="12" lg="6" className="pb-2">
            <label>Host Name</label>
            <TextField
              placeholderLabel="Host Name"
              style={
                this.state.errors.hostName ? { border: "1px solid red" } : {}
              }
              field={"hostName"}
              onChange={value => this._handleFieldChange("hostName", value)}
              defaultValue={this.props.data && this.props.data.hostName}
              className="creator-form"
              inputFieldSize="medium"
            />
            {this.state.errors?.hostName && (
              <div style={{ color: "red" }}>{this.state.errors.hostName}</div>
            )}
          </Col>
          <Col sm="12" lg="6" className="pb-2">
            <label>Title</label>
            <TextField
              style={this.state.errors.title ? { border: "1px solid red" } : {}}
              placeholderLabel="Title"
              field={"title"}
              onChange={value => this._handleFieldChange("title", value)}
              defaultValue={this.props.data && this.props.data.title}
              className="creator-form"
              inputFieldSize="medium"
            />
            {this.state.errors?.title && (
              <div style={{ color: "red" }}>{this.state.errors.title}</div>
            )}
          </Col>
          <Col sm="12" lg="6" className="pb-2">
            <label>Featured Index</label>
            <TextField
              placeholderLabel="Featured Index"
              field={"featuredIndex"}
              onChange={value =>
                this._handleFieldChange("featuredIndex", value)
              }
              defaultValue={this.props.data && this.props.data.featuredIndex}
              className="creator-form"
              inputFieldSize="medium"
            />
          </Col>
          <Col sm={12} lg={6} className="pb-2">
            <div>
              <label>Live Mode</label>
              <select
                className="form-select"
                type="text"
                style={{ width: "100%" }}
                value={this.state.formData.tpLiveMode}
                onChange={e =>
                  this._handleFieldChange("tpLiveMode", e.target.value)
                }
              >
                <option value="Landscape">Landscape</option>
                <option value="Portrait">Portrait</option>
              </select>
            </div>
          </Col>
          {/* <Col sm="12" lg="6" className="pb-2">
            <label>Aspect Ratio</label>
            <TextField
              placeholderLabel="Aspect Ratio"
              onChange={value =>
                this._handleFieldChange("aspectRatio", value)
              }
              defaultValue={this.props.data && this.props.data.aspectRatio}
              className="creator-form"
              inputFieldSize="medium"
            />
          </Col> */}
          <Col sm={12} lg={6}>
            <div>
              <label>Room Category</label>
              <select
                className="form-select"
                type="text"
                style={{ width: "100%" }}
                value={this.state.formData.roomCategory}
                onChange={e =>
                  this._handleFieldChange("roomCategory", e.target.value)
                }
              >
                <option value="VOD">VOD</option>
                {/* <option value="creator_live">Creator Live</option> */}
                <option value="third_party_live">Third Party Live</option>
              </select>
            </div>
          </Col>
          <Col sm="12" lg="6">
            <label>Hashtags</label>
            <div style={{ minWidth: "92%" }}>
              <ReactChipInput
                chips={this.state.formData.hashTags}
                onSubmit={value => this.addChip(value)}
                onRemove={index => this.removeChip(index)}
              />
            </div>
          </Col>

          <Col sm={12} lg={6}>
            <div>
              {" "}
              <label> Stream Type </label>
              <select
                style={{ width: "100%" }}
                className="form-select"
                type="text"
                value={this.state.formData.streamType}
                onChange={e =>
                  this._handleFieldChange("streamType", e.target.value)
                }
              >
                <option value="">Select Room Category</option>
                <option value="CDN">CDN</option>
                <option value="TRTC">TRTC</option>
                {this.state.formData.roomCategory == "third_party_live" && (
                  <option value="YOUTUBE_CDN">Youtube CDN</option>
                )}
              </select>
            </div>
          </Col>
        </Row>

        {this.state.formData.streamType == "YOUTUBE_CDN" && (
          <span>
            <Row style={{ margin: "auto", marginTop: "15px", fontWeight: 600 }}>
              Player Info :
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col sm={12} lg={6}>
                <div>
                  {" "}
                  <label> Type </label>
                  <select
                    // style={{ width: "100%" }}
                    style={
                      this.state.errors.type
                        ? { width: "100%", border: "1px solid red" }
                        : { width: "100%" }
                    }
                    className="form-select"
                    type="text"
                    value={this.state.formData.playerInfo?.type}
                    onChange={e =>
                      this._handlePlayerInfoFieldChange("type", e.target.value)
                    }
                  >
                    <option value="">Select Player Type</option>
                    <option value="youtube">Youtube</option>
                  </select>
                </div>
                {this.state.errors?.type && (
                  <div style={{ color: "red" }}>{this.state.errors.type}</div>
                )}
              </Col>
              <Col sm="12" lg="6" className="pb-2">
                <label>VideoId</label>
                <TextField
                  placeholderLabel="Video Id"
                  field={"videoId"}
                  style={
                    this.state.errors.videoId ? { border: "1px solid red" } : {}
                  }
                  className="creator-form"
                  onChange={value =>
                    this._handlePlayerInfoFieldChange("videoId", value)
                  }
                  inputFieldSize="medium"
                  defaultValue={this.state.formData.playerInfo?.videoId}
                />
                {this.state.errors?.videoId && (
                  <div style={{ color: "red" }}>
                    {this.state.errors.videoId}
                  </div>
                )}
              </Col>
              <Col sm="12" lg="6" className="pb-2">
                <label>Width</label>
                <TextField
                  placeholderLabel="Width"
                  field={"videoWidth"}
                  className="creator-form"
                  onChange={value =>
                    this._handlePlayerInfoFieldChange("videoWidth", value)
                  }
                  inputFieldSize="medium"
                  defaultValue={this.state.formData.playerInfo?.videoWidth}
                />
              </Col>
              <Col sm="12" lg="6" className="pb-2">
                <label>Height</label>
                <TextField
                  placeholderLabel="Height"
                  field={"videoHeight"}
                  className="creator-form"
                  onChange={value =>
                    this._handlePlayerInfoFieldChange("videoHeight", value)
                  }
                  inputFieldSize="medium"
                  defaultValue={this.state.formData.playerInfo?.videoHeight}
                />
              </Col>
              <Col sm={12} lg={6}>
                <div>
                  {" "}
                  <label> Video Orientation </label>
                  <select
                    // style={{ width: "100%" }}
                    style={
                      this.state.errors.videoOrientation
                        ? { width: "100%", border: "1px solid red" }
                        : { width: "100%" }
                    }
                    className="form-select"
                    type="text"
                    value={this.state.formData.playerInfo?.videoOrientation}
                    onChange={e =>
                      this._handlePlayerInfoFieldChange(
                        "videoOrientation",
                        e.target.value
                      )
                    }
                  >
                    <option value="">Select Video Orientation</option>
                    <option value="horizontal">Horizontal</option>
                    <option value="vertical">vertical</option>
                  </select>
                </div>
                {this.state.errors?.videoOrientation && (
                  <div style={{ color: "red" }}>
                    {this.state.errors.videoOrientation}
                  </div>
                )}
              </Col>
              <Col sm={12} lg={3} style={{ marginTop: "20px" }}>
                <Switch
                  label={"Clickable"}
                  field={"clickable"}
                  onChange={value =>
                    this._handlePlayerInfoFieldChange("clickable", value)
                  }
                  defaultValue={this.state.formData.playerInfo?.clickable}
                />
              </Col>
              <Col sm={12} lg={3} style={{ marginTop: "20px" }}>
                <Switch
                  label={"AutoPlay"}
                  field={"autoPlay"}
                  onChange={value =>
                    this._handlePlayerInfoFieldChange("autoPlay", value)
                  }
                  defaultValue={this.state.formData.playerInfo?.autoPlay}
                />
              </Col>
            </Row>
          </span>
        )}
        <Row style={{ marginTop: "20px" }}>
          <Col sm="12" lg="6" className="pb-2">
            <label>Interest Category Name</label>
            <TextField
              placeholderLabel="Interest Category Name"
              field={"interestCategoryName"}
              className="creator-form"
              onChange={value =>
                this._handleFieldChange("interestCategoryName", value)
              }
              inputFieldSize="medium"
              defaultValue={
                this.props.data && this.props.data.interestCategoryName
              }
            />
          </Col>
          <Col sm="12" lg="6" className="pb-2">
            <label>Interest Sub Category Name</label>
            <TextField
              placeholderLabel="Interest Sub Category Name"
              field={"interestSubCategoryName"}
              onChange={value =>
                this._handleFieldChange("interestSubCategoryName", value)
              }
              className="creator-form"
              inputFieldSize="medium"
              defaultValue={
                this.props.data && this.props.data.interestSubCategoryName
              }
            />
          </Col>
          <Col sm={12} lg={6}>
            <div>
              <label>Start Date</label>
              <DatePicker
                style={
                  this.state.errors.startDateTime
                    ? { border: "1px solid red", width: "100%" }
                    : { width: "100%" }
                }
                defaultValue={
                  this.props?.data?.startDateTime
                    ? dayjs.unix(
                        this.props?.data.startDateTime / 1000,
                        "YYYY-MM-DD"
                      )
                    : null
                }
                format="YYYY-MM-DD HH:mm"
                // style={{
                //   width: "100%",
                // }}
                showTime
                placeholder="Start Date"
                onChange={(date, dateString) => {
                  // console.log(dateString.format("YYYY-MM-DD HH:mm:ss"))
                  this._handleFieldChange(
                    "startDateTime",
                    !_.isEmpty(dateString)
                      ? moment(dateString).format("YYYY-MM-DD HH:mm:ss")
                      : null
                  )
                }}
              />
              {this.state.errors?.startDateTime && (
                <div style={{ color: "red" }}>
                  {this.state.errors.startDateTime}{" "}
                </div>
              )}
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <div>
              <label>End Date</label>
              <DatePicker
                defaultValue={
                  this.props?.data?.endDateTime
                    ? dayjs.unix(
                        this.props?.data?.endDateTime / 1000,
                        "YYYY-MM-DD"
                      )
                    : null
                }
                format="YYYY-MM-DD HH:mm"
                showTime
                style={{
                  width: "100%",
                }}
                placeholder="End Date"
                onChange={(date, dateString) => {
                  this._handleFieldChange(
                    "endDateTime",
                    !_.isEmpty(dateString)
                      ? moment(dateString).format("YYYY-MM-DD HH:mm:ss")
                      : null
                  )
                }}
              />
            </div>
          </Col>
          {this.props.action == "create" && (
            <Row>
              <Col sm="12" lg="12">
                <Row>
                  <Col sm="12" lg="12" className="mt-2">
                    <div>
                      <label>Video Path</label>
                      <Input
                        size="md"
                        placeholderLabel="S3 Location Path"
                        field={"s3LocationInputPath"}
                        onChange={e =>
                          this._handleFieldChange(
                            "s3LocationInputPath",
                            e.target.value
                          )
                        }
                        value={this.state.formData.s3LocationInputPath}
                        className="creator-form"
                        required
                      />
                      {this.state.errors?.s3LocationInputPath && (
                        <div style={{ color: "red" }}>
                          {this.state.errors.s3LocationInputPath}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12} lg={2} className="pb-2">
                    <div>
                      <label>Video Type</label>
                      <select
                        className="form-select"
                        type="text"
                        style={{ width: "100%" }}
                        value={this.state.videoType}
                        onChange={e =>
                          this.setState({ videoType: e.target.value })
                        }
                      >
                        <option value="">Select Type</option>
                        <option value="youtube">Youtube</option>
                        <option value="standard">Standard</option>
                      </select>
                    </div>
                  </Col>
                  {this.state.videoType === "youtube" ||
                  this.state.videoType === "standard" ? (
                    <>
                      <Col sm="12" lg="8" className="">
                        <div>
                          <label>Video URL</label>
                          <TextField
                            placeholderLabel="Video Path"
                            field={"videoURL"}
                            onChange={value =>
                              this.setState({ videoURL: value })
                            }
                            defaultValue={this.state.videoURL}
                            className="creator-form"
                            validations={
                              this.state.videoType ? ["required"] : []
                            }
                            errors={["This field is required"]}
                            inputFieldSize="medium"
                          />
                        </div>
                      </Col>
                      <Col
                        sm="12"
                        lg="2"
                        className="d-flex justify-content-end align-items-center mt-2"
                      >
                        <div className="">
                          <Button
                            className="mx-2"
                            color="primary"
                            size="default"
                            onClick={() => this._processVideo()}
                            onDoubleClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                              return
                            }}
                            disabled={this.state.processingURL}
                          >
                            {this.state.processingURL ? (
                              <i
                                class="fa fa-spinner loadingBtn me-2"
                                aria-hidden="true"
                              />
                            ) : (
                              ""
                            )}
                            {this.state.processingURL
                              ? "Processing URL"
                              : "Process URL"}
                          </Button>
                        </div>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </Col>
            </Row>
          )}
          <Row className="pt-2">
            <Col sm={12} lg={3}>
              <Switch
                label={"Live Enable"}
                field={"showLiveLabel"}
                onChange={value =>
                  this._handleFieldToggleChange("showLiveLabel", value)
                }
                defaultValue={
                  this.props.data?.showLiveLabel == true ? true : false
                }
              />
            </Col>
            {/* <Col sm={12} lg={3}>
              <Switch
                label={"Gift Enabled"}
                field={"gift_enabled"}
                onChange={value =>
                  this._handleFieldToggleChange("gift_enabled", value)
                }
                defaultValue={
                  this.props.data?.isGiftingEnabled == true ? true : false
                }
              />
            </Col> */}
            <Col sm={12} lg={3}>
              <Switch
                label={"Allow Comments"}
                field={"allowComments"}
                onChange={value =>
                  this._handleFieldToggleChange("allowComments", value)
                }
                defaultValue={
                  this.props.data?.allowComments == true ? true : false
                }
              />
            </Col>
          </Row>
          {this.props.action == "create" && (
            <Col sm={12} md={6} lg={6} className="">
              <label className="col-form-label" style={{ paddingTop: "0px" }}>
                Video
              </label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this._handleFileUpload(`s3Location`, e.target.files[0])
                }}
                style={{ padding: "0.54rem 0.75rem" }}
              />
            </Col>
          )}
        </Row>

        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              const errors = this._findFormErrors()
              if (Object.keys(errors).length > 0) {
                this.setState({
                  errors,
                })
                return
              }
              this.props.onSubmit(this.state.formData, this.state.s3Location)
            }}
            color="primary"
            size="default"
            type="button"
            disabled={this.state.actionLoading || this.state.processingURL}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            {this.props.action == "create" ? "Create" : "Update"}
          </Button>
          <Button
            className="mx-2"
            color="danger"
            size="default"
            onClick={() => this.props.onCancel()}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
            disabled={this.state.processingURL}
          >
            Cancel
          </Button>
        </div>
      </div>
      // </ValidatorForm>
    )
  }

  _render = () => {
    return (
      <Card>
        <CardBody>{this._form()}</CardBody>
      </Card>
    )
  }
}
