import React from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Divider, Select } from "antd"
import { Col, Row } from "reactstrap"
const { Option } = Select
import { LANGUAGES, GENRE_LIST } from "../../constants/uiConstants"

const ListingsDrawer = props => {
  return (
    <React.Fragment>
      <Row gutter={12}>
        <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Listing Id</h5>
          </Divider>
          <Input
            defaultValue={props.filters["id"]}
            placeholder="Listing Id"
            onChange={e =>
              props.handleFilterChange(e.target.value.trim(), "id")
            }
            allowClear={true}
            name="id"
            style={{ width: "100%" }}
          />
        </Col>
        <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Listing Name</h5>
          </Divider>
          <Input
            defaultValue={props.filters["name"]}
            placeholder="Listing Name"
            onChange={e =>
              props.handleFilterChange(e.target.value.trim(), "name")
            }
            allowClear={true}
            name="name"
            style={{ width: "100%" }}
          />
        </Col>
        {/* <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Language</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Language"
            defaultValue={props.filters["langCode"]}
            onChange={value => {
              props.handleFilterChange(value, "langCode")
            }}
            style={{ width: "100%" }}
          >
            {LANGUAGES.map(lang => (
              <Option value={lang.value}>{lang.label}</Option>
            ))}
          </Select>
        </Col> */}
        <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Genre</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Genre"
            defaultValue={props.filters["genre"]}
            onChange={value => {
              props.handleFilterChange(value, "genre")
            }}
            style={{ width: "100%" }}
          >
            {GENRE_LIST.map(genre => (
              <Option value={genre.value}>{genre.label}</Option>
            ))}
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(ListingsDrawer)
