import React, { Component, useEffect } from "react"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { connect } from "react-redux"
import { Pagination, Collapse } from "antd"
import { getActivityList } from "appRedux/slices/activity"
import ErrorBoundComponent from "components/ErrorBoundComponent"
import {
  COMPETITION_LOGO_MAP,
  BANNED_ORGANIZATIONS_MAP,
} from "../../constants/uiConstants"
import "./Timeline.css"

const { Panel } = Collapse

import {
  ACTIONS_TO_NAME,
  DEACTIVATE_REASONS,
} from "../../constants/uiConstants"
class Timelines extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      filters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 5,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
      },
    }
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getActivityList(
          _.deepClean({
            searchText: this.props.action_id,
            searchType: "uuid",
            fromDate: "1638748800000",
          })
        )
      )
    }
  }
  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({
      filters: newFilters,
    })
  }
  showAndHide = val => {
    let key = "show" + val
    this.setState({
      [key]: !this.state[key],
    })
  }

  tag_content_render = item => {
    const zoneInfo = item?.meta_info?.stage_info?.zone_info?.value || undefined
    return (
      <div>
        <Collapse bordered={false}>
          <Panel header="show details" key="1">
            {item.action == "tag_content" ||
            item.action == "labeling_stage_one" ||
            item.action == "labeling_stage_two" ||
            item.action == "labeling_stage_three"
              ? this.props.renderTaxonomyDetails(
                  item.meta_info.details,
                  zoneInfo
                )
              : ""}
          </Panel>
        </Collapse>
      </div>
    )
  }
  skip_tag_content_render = item => {
    let value = DEACTIVATE_REASONS.filter(
      el => el.value == item.meta_info?.details?.skip_reason
    )
    return (
      <div>
        {item.meta_info?.details?.skip_reason
          ? `due to ${value[0]?.label}${
              value[0]?.value === "competitor_logo"
                ? item.meta_info?.details?.competitor_logo_name_text
                  ? ` - ${item.meta_info?.details?.competitor_logo_name_text}`
                  : ` - ${
                      COMPETITION_LOGO_MAP[
                        item.meta_info?.details?.competitor_logo_name
                      ]?.label || item.meta_info?.details?.competitor_logo_name
                    }`
                : ""
            }${
              value[0]?.value === "banned_organization"
                ? ` - ${
                    BANNED_ORGANIZATIONS_MAP[
                      item.meta_info?.details?.banned_organization_name
                    ]?.label ||
                    item.meta_info?.details?.banned_organization_name
                  }`
                : ""
            }`
          : ""}
      </div>
    )
  }
  deactivate_content_render = item => {
    let value = DEACTIVATE_REASONS.filter(
      el => el.value == item.meta_info?.reject_reason
    )
    return (
      <span>
        {item.meta_info?.reject_reason
          ? `due to ${value[0]?.label}${
              value[0]?.value === "competitor_logo"
                ? item.meta_info?.competitor_logo_name_text
                  ? ` - ${item.meta_info.competitor_logo_name_text}`
                  : ` - ${
                      COMPETITION_LOGO_MAP[item.meta_info.competitor_logo_name]
                        ?.label || item.meta_info.competitor_logo_name
                    }`
                : ""
            }${
              value[0]?.value === "banned_organization"
                ? ` - ${
                    BANNED_ORGANIZATIONS_MAP[
                      item.meta_info.banned_organization_name
                    ]?.label || item.meta_info.banned_organization_name
                  }`
                : ""
            }`
          : ""}
      </span>
    )
  }
  qc_tag_content_modify_render = item => {
    const zoneInfo = item?.meta_info?.zone_info || undefined
    return (
      <div>
        <Collapse bordered={false}>
          <Panel header="show details" key="1">
            {item.action == "qc_tag_content_modify"
              ? this.props.renderTaxonomyModifyDetails(item.meta_info, zoneInfo)
              : ""}
          </Panel>
        </Collapse>
      </div>
    )
  }
  render() {
    const filteredList =
      this.props.activityList.activityLogData &&
      this.props.activityList.activityLogData.filter(el =>
        this.props.actions.includes(el.action)
      )

    const sortedList = _.sortBy(filteredList, ["created_date"]).reverse()

    let activityFilteredList = _(sortedList)
      .drop((this.state.filters.currentPage - 1) * this.state.filters.pageSize)
      .take(this.state.filters.pageSize)
      .value()
    const total = sortedList.length
    return (
      <React.Fragment>
        <div>
          <CardBody style={{ position: "relative", paddingLeft: "0px" }}>
            {/* <CardTitle className="mb-3 h4">Timeline</CardTitle> */}
            <div className="timeline_list">
              <ul className="verti-timeline list-unstyled">
                {this.props.activityList &&
                  this.props.activityList.activityLogData &&
                  (activityFilteredList.length != 0
                    ? activityFilteredList.map((item, i) => (
                        <li
                          className={
                            item.action == "tag_content"
                              ? "event-list"
                              : "event-list"
                          }
                        >
                          <div className="event-timeline-dot mt2">
                            <i className="bx bx-right-arrow-circle font-size-18" />
                          </div>
                          <div className="d-flex wd85">
                            <div className="flex-shrink-0 me-3 flex200px">
                              <h5 className="font-size-14 d-flex">
                                <div className="flex175px">
                                  <div>
                                    {new Date(item.created_date).toDateString()}
                                    &nbsp;
                                    {new Date(
                                      item.created_date
                                    ).toLocaleTimeString()}
                                  </div>
                                </div>
                                {item.action == "activate" ||
                                item.action == "qc_tag_content_ok" ? (
                                  <i className="bx bx-comment-check iconstyle green"></i>
                                ) : item.action == "deactivate" ||
                                  item.action == "skip_tag_content" ? (
                                  <i className="bx bx-block iconstyle red"></i>
                                ) : (
                                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle" />
                                )}
                              </h5>
                            </div>
                            <div className="flex-grow-1 mt2">
                              <b> {ACTIONS_TO_NAME[item.action]}</b> by{" "}
                              {item.email}{" "}
                              {item.action == "skip_tag_content"
                                ? this.skip_tag_content_render(item)
                                : ""}
                              {item.action == "deactivate"
                                ? this.deactivate_content_render(item)
                                : ""}
                              {/* {item.meta_info &&
                              item.meta_info.moderation_status ? (
                                <span>
                                  and moderation status was{" "}
                                  <b>{item.meta_info.moderation_status}</b>
                                </span>
                              ) : (
                                ""
                              )} */}
                            </div>
                          </div>
                          <div>
                            {item.action == "tag_content" ||
                            item.action == "labeling_stage_one" ||
                            item.action == "labeling_stage_two" ||
                            item.action == "labeling_stage_three"
                              ? this.tag_content_render(item)
                              : ""}

                            {item.action == "qc_tag_content_modify"
                              ? this.qc_tag_content_modify_render(item)
                              : ""}
                          </div>
                        </li>
                      ))
                    : "No Timeline available")}
              </ul>
            </div>
            <div className="d-flex justify-content-end mb-3 pagination_position">
              <Pagination
                style={{ marginTop: "1rem" }}
                onChange={this._onPageChange}
                defaultCurrent={this.state.filters.currentPage}
                total={total < 10000 ? total : 10000}
                pageSize={this.state.filters.pageSize}
                showSizeChanger={false}
              />
            </div>
          </CardBody>
        </div>
      </React.Fragment>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "activityLog.loading")
      ? _.get(store, "activityLog.loading")
      : false,
    activityList: _.get(store, "activityLog"),
    moderatorList: _.get(store, "cmsUser.userList") || [],
    refetchData: _.get(store, "appUser.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "activityLog.count"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    reportList: _.get(store, "activityLog.reportList"),
  }
}

export default connect(mapStateToProps)(Timelines)
