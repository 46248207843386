import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ContentList from "../../components/ContentList/contentList"
import { Select } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getTrendingContent,
  fetchContentByUuids,
} from "appRedux/slices/content"
import { getLocationData } from "appRedux/slices/location"
import { getTrendingSourceData } from "appRedux/slices/trending"
import Auxiliary from "../../util/Auxiliary"
import NoDataFound from "../../components/NoDataFound/noDataFound"
import { getTrendingEntityByFilters } from "appRedux/slices/trending"
import "../Content/browseContent.style.css"

const { Option } = Select

class TagTrendingContent extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedContent: {},
      pageType: this.props.queryParams && this.props.queryParams["pageType"],
      filters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        hashtag:
          this.props.queryParams && this.props.queryParams["hashtag"]
            ? this.props.queryParams["hashtag"]
            : this.props.hashtag
            ? this.props.hashtag
            : "",

        audioId:
          this.props.queryParams && this.props.queryParams["audioId"]
            ? this.props.queryParams["audioId"]
            : this.props.audioId
            ? this.props.audioId
            : "",
        deskId:
          this.props.currentUser &&
          this.props.currentUser.source &&
          this.props.currentUser.source["TRENDING"]
            ? this.props.currentUser.source["TRENDING"]
            : undefined,
        entityId:
          this.props.queryParams && this.props.queryParams["entityId"]
            ? this.props.queryParams["entityId"]
            : undefined,
        size: 0,
      },
    }
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      if (
        filters &&
        (filters.entityId ||
          filters.hashtag ||
          filters.audioId ||
          filters.deskId)
      ) {
        if (this.state.filters.deskId) {
          this.props.dispatch(
            getTrendingSourceData(this.props.currentUser.source["TRENDING"])
          )
        } else {
          this.props.dispatch(getTrendingContent(_.deepClean(filters)))
          this.props.dispatch(
            getTrendingEntityByFilters({ pageSize: 1000, page: 1 })
          )
          this.setState({
            filters,
          })
        }
      }

      this.props.dispatch(getLocationData())
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.deskData &&
      prevProps.deskData !== this.props.deskData &&
      this.props.deskData.length > 0
    ) {
      const deskIds =
        this.props.deskData.length < 10
          ? this.props.deskData
          : this.props.deskData.slice(0, 10)
      this.props.dispatch(fetchContentByUuids(deskIds))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    this.setState({
      filters: newFilters,
    })
  }

  _searchContent = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    if (this.props.isManagePage) {
      this.props.history.push(
        `/trending/content?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
    } else
      this.props.history.push(
        `/trending/tag?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
    this.setState({ isOpen: false }, () =>
      this.props.dispatch(getTrendingContent(_.deepClean(filters)))
    )
  }

  _onPageChange = (value, previewContent) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    if (previewContent === "next") {
      newFilters["currentPage"] = value + 1
      this.setState({
        view: "details",
      })
    }

    if (previewContent === "prev") {
      newFilters["currentPage"] = value - 1
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        if (this.state.pageType === "manage") {
          this.props.history.push(
            `/trending/content?pageType=manage&${QueryString.stringify(
              _.deepClean(this.state.filters)
            )}`
          )
          this.props.dispatch(
            getTrendingContent(_.deepClean(this.state.filters))
          )
        } else if (this.props.deskData && this.props.deskData.length > 0) {
          this.props.history.push(
            `/trending/tag?${QueryString.stringify(
              _.deepClean(this.state.filters)
            )}`
          )
          const pageSize = this.state.filters.pageSize
          const currentPage = this.state.filters.currentPage
          const deskIds = this.props.deskData.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          )
          this.props.dispatch(fetchContentByUuids(deskIds))
        }
      }
    )
  }

  // _onPageChange = (value, previewContent) => {
  //   let newFilters = _.cloneDeep(this.state.filters)
  //   newFilters["currentPage"] = value
  //   // newFilters["previewContent"] = previewContent ? true : false
  //   if (this.props.deskData && this.props.deskData.length > 0) {
  //     this.setState(
  //       {
  //         filters: newFilters,
  //       },
  //       () => {
  //         if (this.state.pageType === "manage") {
  //           this.props.history.push(
  //             `/trending/content?${QueryString.stringify(
  //               _.deepClean(this.state.filters)
  //             )}`
  //           )
  //         } else
  //           this.props.history.push(
  //             `/trending/tag?${QueryString.stringify(
  //               _.deepClean(this.state.filters)
  //             )}`
  //           )
  //         const pageSize = this.state.filters.pageSize
  //         const currentPage = this.state.filters.currentPage
  //         const deskIds = this.props.deskData.slice(
  //           (currentPage - 1) * pageSize,
  //           currentPage * pageSize
  //         )
  //         this.props.dispatch(fetchContentByUuids(deskIds))
  //       }
  //     )
  //   } else
  //     this.setState(
  //       {
  //         filters: newFilters,
  //       },
  //       () => {
  //         if (this.state.pageType === "manage") {
  //           this.props.history.push(
  //             `/trending/content?${QueryString.stringify(
  //               _.deepClean(this.state.filters)
  //             )}`
  //           )
  //         } else
  //           this.props.history.push(
  //             `/trending/tag?${QueryString.stringify(
  //               _.deepClean(this.state.filters)
  //             )}`
  //           )
  //         this.props.dispatch(
  //           getTrendingContent(_.deepClean(this.state.filters))
  //         )
  //       }
  //     )
  // }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.props.contentList && this.props.contentList.length > 0 ? (
          <ContentList
            contentList={this.props.contentList}
            isReProcessedModerationList={
              this.state.filters["searchType"] === "reprocessed_moderation" &&
              this.state.filters["searchText"]
            }
            onPageChange={this._onPageChange}
            total={
              this.props.deskData && this.props.deskData.length > 0
                ? this.props.deskData.length
                : this.props.total
            }
            searchAfter={this.props.searchAfter}
            isMobile={this.props.isMobile}
            currentUser={this.props.currentUser}
            currentPage={this.state.filters["currentPage"]}
            pageType="content"
            pageSize={this.state.filters["pageSize"]}
            previewContent={this.state.previewContent}
            pageSource={
              this.state.pageType === "manage"
                ? "manage-trending"
                : "task-trending"
            }
            contentView={
              this.state.pageType === "manage" ? "dashboard" : "grid"
            }
            trendingEntityList={this.props.trendingEntityList}
            entityId={this.state.filters.entityId}
            history={this.props.history}
          />
        ) : (
          <NoDataFound message="No Data To Display" />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    contentList: _.get(store, "content.content"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "content.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "content.loading"),
    deskData: _.get(store, "trending.deskData"),
    trendingEntityList: _.get(store, "trending.trendingEntityList"),
  }
}

export default connect(mapStateToProps)(TagTrendingContent)
