import { createSlice } from "@reduxjs/toolkit"

const challengeSlice = createSlice({
  name: "challenge",
  initialState: {
    challenge: {},
    refetchChallengeList: false,
    loading: false,
    challengeContentUUIDsDetails: [],
    challengeList: [],
  },
  reducers: {
    getChallengeListByFilters(state, action) {
      return {
        ...state,
        loading: true,
        refetchChallengeList: false,
        challengeList: [],
      }
    },
    getChallengeListByFiltersSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        challengeList: payload.data.data,
        total: payload.data?.total?.value || 1,
      }
    },
    getChallengeDetails(state) {
      return {
        ...state,
        loading: true,
        refetchChallengeList: false,
        challenge: {},
      }
    },
    getChallengeDetailsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        challenge: payload.data,
      }
    },
    upsertChallenge(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertChallengeSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
      }
    },
    updateChallengeWinners(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateChallengeStatus(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateChallengeVideoMeta(state) {
      return {
        ...state,
        loading: true,
      }
    },
    deleteBanner(state) {
      return {
        ...state,
        loading: true,
      }
    },
    deleteBannerSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    resetState() {
      return {
        challenge: {},
        refetchChallengeList: false,
        loading: false,
        challengeContentUUIDsDetails: [],
        challengeList: [],
      }
    },
  },
})

export const {
  getChallengeListByFilters,
  getChallengeListByFiltersSuccess,
  getChallengeDetails,
  getChallengeDetailsSuccess,
  upsertChallenge,
  upsertChallengeSuccess,
  updateChallengeWinners,
  updateChallengeStatus,
  updateChallengeVideoMeta,
  deleteBanner,
  deleteBannerSuccess,
  resetState,
} = challengeSlice.actions

export default challengeSlice.reducer
