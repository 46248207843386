import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import { fetchError } from "appRedux/slices/common"
import {
  getSocialDetails,
  getSocialDetailsSuccess,
  getSocial,
  getSocialSuccess,
  upsertSocial,
  upsertSocialSuccess,
} from "appRedux/slices/social"
import {
  requestGetSocial,
  requestUpsertSocial,
  requestGetSocialDetails,
} from "../requests/social"

function* handleGetSocial(params) {
  try {
    const data = yield call(requestGetSocial, params)
    yield put(getSocialSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetSocialDetails(params) {
  try {
    const data = yield call(requestGetSocialDetails, params)
    yield put(getSocialDetailsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertSocial(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertSocial, params)
    yield put(upsertSocialSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getSocial.type, handleGetSocial)
  yield takeLatest(upsertSocial.type, handleUpsertSocial)
  yield takeLatest(getSocialDetails.type, handleGetSocialDetails)
}
