import { createSlice } from "@reduxjs/toolkit"

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: {
    currentUser: null,
    loading: false,
    dataFetched: false,
  },
  reducers: {
    getCurrentUser(state) {
      return {
        ...state,
        loading: true,
      }
    },
    setCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload.data,
        loading: false,
        dataFetched: true,
      }
    },
    getHostName(state) {
      return {
        ...state,
        hostName,
      }
    },
    setHostName(state, { payload }) {
      return {
        ...state,
        hostName: payload,
      }
    },
  },
})

export const { getCurrentUser, setCurrentUser, getHostName, setHostName } =
  currentUserSlice.actions

export default currentUserSlice.reducer
