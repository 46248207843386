import Axios from "axios"

export const requestGetCurrentUser = async () => {
  try {
    let data = await Axios.get("/api/auth/getCurrentUser")
    return data
  } catch (error) {
    throw error
  }
}
