import Axios from "axios"

export const getFontFamilies = async params => {
  try {
    let data = await Axios.get(`/api/cmsVideoGreetings/getFontFamilies`)
    return data?.data
  } catch (error) {
    throw error
  }
}

export const getItemFile = async params => {
  try {
    let data = await Axios.get(
      `/api/cmsVideoGreetings/getItemFile?itemId=${params}`
    )
    return data?.data
  } catch (error) {
    throw error
  }
}
