import { createSlice } from "@reduxjs/toolkit"

const templateSectionSlice = createSlice({
  name: "templateSection",
  initialState: {
    allTemplateSections: [],
    templateSections: [],
    total: 0,
    loading: false,
    refetchData: false,
    templatesList: [],
    refetchTemplateData: false,
  },
  reducers: {
    getTemplateSection(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getTemplateSectionSuccess(state, { payload }) {
      return {
        ...state,
        templateSections: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    upsertTemplateSection(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertTemplateSectionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    reorderTemplateSection(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    reorderTemplateSectionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    getAllTemplateSections(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getAllTemplateSectionsSuccess(state, { payload }) {
      return {
        ...state,
        allTemplateSections: payload.data.data,
        loading: false,
        refetchData: false,
      }
    },
    addTemplate(state) {
      return {
        ...state,
        loading: true,
        refetchTemplateData: false,
      }
    },
    addTemplateSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchTemplateData: true,
      }
    },
    getTemplates(state) {
      return {
        ...state,
        loading: true,
        refetchTemplateData: false,
        templatesList: [],
      }
    },
    getTemplatesSuccess(state, { payload }) {
      return {
        ...state,
        templatesList: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchTemplateData: false,
      }
    },
    updateVersion(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    updateVersionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    refreshTemplate(state) {
      return {
        ...state,
        loading: false,
        refetchTemplateData: true,
      }
    },
    refreshTemplateSection(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState(state) {
      return {
        ...state,
        templateSection: [],
        allTemplateSections: [],
        total: 0,
        loading: false,
        refetchData: false,
      }
    },
    resetTemplateState(state) {
      return {
        ...state,
        templatesList: [],
        refetchTemplateData: false,
      }
    },
  },
})

export const {
  getTemplateSection,
  getTemplateSectionSuccess,
  upsertTemplateSection,
  upsertTemplateSectionSuccess,
  reorderTemplateSection,
  reorderTemplateSectionSuccess,
  getAllTemplateSections,
  getAllTemplateSectionsSuccess,
  addTemplate,
  addTemplateSuccess,
  getTemplates,
  getTemplatesSuccess,
  refreshTemplate,
  refreshTemplateSection,
  updateVersion,
  updateVersionSuccess,
  resetState,
  resetTemplateState,
} = templateSectionSlice.actions

export default templateSectionSlice.reducer
