import React, { useState } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import ZoneMapCard from "./zoneMapCard"

const ZoneMapList = props => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: props?.keyZone?.level == props?.level ? 4 : 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: props?.keyZone?.level == props?.level ? 4 : 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: props?.keyZone?.level == props?.level ? 1 : 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      <Carousel
        responsive={responsive}
        style={{ backgroundColor: "#e8e2e2", margin: "auto" }}
        className={`${props.zones.length > 4 ? "" : "zone_map_cards"}`}
        focusOnSelect={true}
        key={props?.keyZone?.zone_uuid}
      >
        {props.zones.map(zone => (
          <ZoneMapCard
            zone={zone}
            // setSelectedZoneParent={props.setSelectedZoneParent}
            // setSelectedZoneChild={props.setSelectedZoneChild}
            // selectedZoneParent={props.selectedZoneParent}
            // selectedZoneChild={props.selectedZoneChild}
            keyZone={props.keyZone}
            showMap={props.showMap}
            setKeyZone={props.setKeyZone}
            showSibling={props.showSibling}
            key={zone.zone_uuid}
          />
        ))}
      </Carousel>
    </>
  )
}

export default ZoneMapList
