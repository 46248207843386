import { createSlice } from "@reduxjs/toolkit"

const kycSlice = createSlice({
  name: "kyc",
  initialState: {
    loading: false,
    refetch: false,
    kycList: [],
    csvLoading: false,
    csvData: [],
    total: 0,
  },
  reducers: {
    getKyc(state) {
      return {
        ...state,
        loading: true,
        refetch: false,
      }
    },
    getKycSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        kycList: payload?.data?.rows || [],
        total: payload?.data?.count || 1,
        refetch: false,
      }
    },
    getPendingKYCCSVData(state) {
      return {
        ...state,
        csvLoading: true,
        refetchData: false,
      }
    },
    getPendingKYCCSVDataSuccess(state, { payload }) {
      return {
        ...state,
        csvData: payload.data,
        csvLoading: false,
        refetchData: false,
      }
    },
    uploadKycData(state) {
      return {
        ...state,
        refetchData: false,
      }
    },
    uploadKycDataSuccess(state, { payload }) {
      return {
        ...state,
        refetchData: false,
      }
    },
    resetState() {
      return {
        loading: false,
        kycList: [],
        refetch: false,
        csvData: [],
        csvLoading: false,
        total: 0,
      }
    },
  },
})

export const {
  getKyc,
  getKycSuccess,
  getPendingKYCCSVData,
  getPendingKYCCSVDataSuccess,
  uploadKycData,
  uploadKycDataSuccess,
  resetState,
} = kycSlice.actions

export default kycSlice.reducer
