import React from "react"
import { Row, Col } from "antd"
import _ from "lodash"
import { TextField } from "../../containers/ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import "./styles.css"
import { ChromePicker } from "react-color"
import PropTypes from "prop-types"

class ColorPicker extends ErrorBoundComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    field: PropTypes.string,
    defaultColor: PropTypes.string,
    placeholderLabel: PropTypes.array,
  }

  onChange(color, field) {
    this.setState({ [field]: color })
    const { onColorChange = () => {} } = this.props
    onColorChange(color)
  }

  handleChangeComplete(color, field) {
    this.setState({ [field]: color })
  }

  render() {
    const defaultColor = this.props.defaultColor || "#5154b2"
    return (
      <Col span={24}>
        {this.props.label && (
          <div style={{ marginBottom: "10px" }}>{this.props.label}</div>
        )}
        <Row
          className="color-selector d-flex align-items-center"
          onClick={() =>
            this.setState({
              showColorCodePicker: !this.state.showColorCodePicker,
            })
          }
        >
          <Col span={2}>
            <span
              className="circle"
              style={{
                background:
                  (this.state[this.props.field] &&
                    this.state[this.props.field].hex) ||
                  defaultColor,
                cursor: "pointer",
              }}
            />
          </Col>
          <Col span={20} offset={2}>
            <TextField
              placeholderLabel={this.props.placeholderLabel || ""}
              field={this.props.field}
              defaultValue={
                this.state[this.props.field]
                  ? this.state[this.props.field].hex
                  : this.props.defaultColor
                  ? this.props.defaultColor
                  : "#5154b2"
              }
              onChange={val => {
                this.props.onColorChange({ hex: val })
                this.setState({ [this.props.field]: { hex: val } })
              }}
            />
          </Col>
        </Row>
        {this.state.showColorCodePicker && (
          <ChromePicker
            color={this.state[this.props.field]}
            onChange={color => this.onChange(color, this.props.field)}
            onChangeComplete={color =>
              this.handleChangeComplete(color, this.props.field)
            }
          />
        )}
      </Col>
    )
  }
}

export default ColorPicker
