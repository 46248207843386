import { createSlice } from "@reduxjs/toolkit"
import {
  emotionsData,
  genreData,
  orthogonalAttributeData,
  orthogonalQualityData,
  audioVulgarityData,
  backgroundData,
  cityTierData,
  peopleInVideoData,
  eventData,
  glamourData,
  violenceData,
  targetLanguageData,
  ethnicityData,
  peopleCount,
} from "constants/taxonomy"
import { taxonomyRoutes } from "components/TaxonomyDashboard/TaxonomyRoutes"

const initialState = {
  emotions: emotionsData,
  genre: genreData,
  subGenre: genreData,
  orthogonalQuality: orthogonalQualityData,
  orthogonalAttribute: orthogonalAttributeData,
  audioVulgarity: audioVulgarityData,
  background: backgroundData,
  cityTier: cityTierData,
  peopleInVideo: peopleInVideoData,
  event: eventData,
  glamour: glamourData,
  violence: violenceData,
  ethnicity: ethnicityData,
  peopleCount: peopleCount,
  targetLanguage: targetLanguageData,
  zone: [],
  taxonomyRoutes,
}
const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState: _.cloneDeep(initialState),
  reducers: {
    setTaxonomyData(state, { payload }) {
      return { ...state, [payload.currentTab]: payload.currentData }
    },
    resetTaxonomyState(state) {
      return _.cloneDeep(initialState)
    },
    resetInitialStateOnly(state, { payload }) {
      return {
        ..._.cloneDeep(initialState),
        taxonomyRoutes: payload.taxonomyRoutes,
      }
    },
    setTaxonomyRoutes(state, { payload }) {
      return {
        ...state,
        taxonomyRoutes: payload.taxonomyRoutes,
      }
    },
  },
})

export const {
  setTaxonomyData,
  resetTaxonomyState,
  setTaxonomyRoutes,
  resetInitialStateOnly,
} = taxonomySlice.actions

export default taxonomySlice.reducer
