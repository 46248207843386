import React from "react"
import toastr from "toastr"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { Input } from "reactstrap"
import QueryString from "query-string"
import {
  //upsertZone,
  resetState,
  getZoneDetails,
  getZones,
  deleteBanner,
  addPreviewImage,
  getAllZones,
} from "appRedux/slices/zone"
import { upsertZone } from "../../clientServices/zoneService"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import ZoneUpsertForm from "./zoneUperstForm"

class ManageZone extends ErrorBoundComponent {
  state = {
    enableAnyway: false,
    filters: {
      zoneUuid:
        this.props.queryParams && this.props.queryParams["zoneUuid"]
          ? this.props.queryParams["zoneUuid"]
          : null,
    },
    showUpsertModal: true,
    formAction: "create",
    contentUUIDs: [],
  }

  componentDidMount() {
    this.props.dispatch(getAllZones({ currentPage: 1, pageSize: 10000 }))

    if (!this.props.common.error && this.state.filters.zoneUuid) {
      this.props.dispatch(
        getZoneDetails({ zoneUuid: this.state.filters.zoneUuid })
      )
      this.setState({
        formAction: "update",
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this?.props?.queryParams?.zoneUuid !== prevProps?.queryParams?.zoneUuid ||
      this.props.refetchZoneData
    ) {
      this.props.dispatch(
        getZoneDetails({ zoneUuid: this.state.filters.zoneUuid })
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onZoneUpsertSubmit = async formData => {
    // this.props.dispatch(upsertZone(formData));
    const response = await upsertZone(formData)
    if (response.status !== 200 && response && response.includes("403")) {
      this.setState({
        enableAnyway: true,
      })
    } else {
      this.setState({
        enableAnyway: false,
      })
    }
  }

  _onDeleteBanner = data => {
    toastr.info("Action in progress !!")
    this.props.dispatch(deleteBanner(data))
  }

  _previewImageSubmit = async data => {
    const response = await this.props.dispatch(addPreviewImage(data))
    this.setState({ showPreviewImageForm: false })
  }

  _searchZone = value => {
    if (value) {
      this.props.history.push(`/zone/manage?zoneUuid=${value}`)
      this.setState(
        {
          filters: {
            ...this.state.filters,
            zoneUuid: value,
          },
          formAction: "update",
        },
        () => {
          this.props.dispatch(
            getZoneDetails({ zoneUuid: this.state.filters.zoneUuid })
          )
        }
      )
    }
  }

  _filters = () => {
    return (
      <Row style={{ marginBottom: "1rem" }}>
        <Col lg="4">
          <Input
            placeholder="Zone ID"
            onChange={e => this._searchZone(e.target.value)}
            validations={["required"]}
            defaultValue={this.state.filters.zoneUuid}
          />
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        <ZoneUpsertForm
          formAction={this.state.formAction}
          onSubmit={this._onZoneUpsertSubmit}
          enableAnyway={this.state.enableAnyway}
          zone={this.props.zone}
          currentUserType={this.props.currentUserType}
          currentUser={this.props.currentUser}
          history={this.props.history}
          zoneList={this.props.zoneList}
          deleteBanner={this._onDeleteBanner}
          onPreviewImageSubmit={this._previewImageSubmit}
          allZoneList={this.props.allZoneList}
        />
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    zoneList: _.get(store, "zone.zoneList"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    zone: _.get(store, "zone.zone"),
    refetchZoneData: _.get(store, "zone.refetchZoneData"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(ManageZone)
