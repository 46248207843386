import moment from "moment"
import React from "react"
import Rating from "react-rating"
import { Popover } from "antd"
import { Button, ButtonGroup } from "@mui/material"

export default class ActionButtons extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      taxonomyRating: 0,
    }
  }
  componentWillUnmount() {
    this.didUnmount = true
  }

  popoverContent = () => {
    return (
      <div>
        <Rating
          emptySymbol="far fa-star fa-1x text-muted"
          fullSymbol="fas fa-star fa-1x text-warning"
          initialRating={this.state.taxonomyRating}
          onChange={rate => {
            this.setState({
              taxonomyRating: rate,
            })
          }}
        />
        <div
          className="px-2 btn btn-sm"
          role="button"
          style={{
            border: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
            backgroundColor: "#34c38f",
            color: "#ffffff",
            marginLeft: "2px",
          }}
          onClick={() =>
            this.props.onReviewClick(
              this.props?.content?.content_uuid,
              this.state.taxonomyRating
            )
          }
        >
          Ok
        </div>
      </div>
    )
  }

  render() {
    const content = this.props.content
    const showTagContent = this.props.pageType === "tagContent"
    const showRemoveDuplicate = this.props.currentUser.jotUserType < 4
    const showTaxonomyContent =
      this.props.pageType === "taxonomyContent" ||
      this.props.pageType === "task"
    const showActivateButton =
      content.moderation_level !== 2 && !showTagContent && !showTaxonomyContent
    const showDeActivateButton =
      content.moderation_level !== -1 && !showTaxonomyContent
    const showTagNonEnglish =
      this.props.showTagNonEnglish && !showTagContent && !showTaxonomyContent
    const showConvertToVertical =
      this.props.convertToVertical && !showTagContent && !showTaxonomyContent
    const showQCButton =
      ((this.props.currentUser.permissions &&
        this.props.currentUser.permissions.indexOf("VIEW_ACTIVITY") >= 0) ||
        !this.props.currentUser.agency) &&
      (content.moderation_level === 2 || content.moderation_level === -1) &&
      !showTagContent &&
      !showTaxonomyContent
    const disableShoppalbleButton = content.shoppable_content
    let today = moment(new Date())
    let notif_worthy_update_time =
      content.notif_worthy_update_time &&
      moment(new Date(content.notif_worthy_update_time))
    let isNotifWorthyContent =
      content &&
      content.notif_worthy &&
      content.notif_worthy_update_time &&
      moment.duration(today.diff(notif_worthy_update_time)).asHours() < 48
    let notifContentDeactivate =
      isNotifWorthyContent &&
      this.props.currentUser &&
      (![1, 2].includes(this.props.currentUser.userType) ||
        ![1, 2].includes(this.props.currentUser.jotUserType))
    let isExternalBrandContent =
      content &&
      content.user_profile &&
      (content.user_profile.user_type === "eb" ||
        content.user_profile.user_type === "ib")
    let adsContent = content.adsContent
    let enableDeactivateButton =
      this.props.currentUser.permissions &&
      (isExternalBrandContent || adsContent
        ? this.props.currentUser.permissions.includes(
            "CONTENT_DEACTIVATE_PERMISSION"
          ) ||
          this.props.currentUser.permissions.includes(
            "EB_CONTENT_DEACTIVATION_PERMISSION"
          )
        : this.props.currentUser.permissions.includes(
            "CONTENT_DEACTIVATE_PERMISSION"
          ))
    let disableActivateButton =
      (this.props.currentUser.jotUserType > 3 &&
        this.props.currentUser.jotUserType !== 6 &&
        content.user_profile.verified) ||
      (content.taxonomy_meta?.skip_reason &&
        !this.props?.currentUser?.permissions?.includes(
          "CONTENT_ACTIVATE_PERMISSION"
        )) ||
      (content?.reject_reason === "copyright_content" &&
        !this.props?.currentUser?.permissions?.includes(
          "ACTIVATE_COPYRIGHT_CONTENT"
        )) ||
      (content?.reject_reason === "banned_organization" &&
        !this.props?.currentUser?.permissions?.includes(
          "ACTIVATE_BANNED_CONTENT"
        ))

    if (this.props.currentUser.jotUserType < 3) {
      disableActivateButton = false
    }

    return (
      <div className="d-flex">
        <ButtonGroup size="small">
          {!this.props.isModerationSection && (
            <Button onClick={() => this.props.contentAction(content, "action")}>
              {this.props.isMobile ? "" : "Update Action"}
            </Button>
          )}
          {this.props.isReProcessedModerationList &&
            !this.props.isModerationSection && (
              <>
                <Button
                  disabled={
                    this.props.currentUser.jotUserType > 3 &&
                    content.user_profile.verified
                  }
                  onClick={event =>
                    this.props.reProcessedModerationAction(content, "accept")
                  }
                >
                  {this.props.isMobile ? "" : "Accept"}
                </Button>
                <Button
                  disabled={
                    this.props.currentUser.jotUserType > 3 &&
                    content.user_profile.verified
                  }
                  style={{ color: "red", borderColor: "red" }}
                  onClick={event =>
                    this.props.reProcessedModerationAction(content, "reject")
                  }
                >
                  {this.props.isMobile ? "" : "Reject"}
                </Button>
              </>
            )}
          {!this.props.isReProcessedModerationList && (
            <>
              {" "}
              {!this.props.isModerationSection &&
                (!disableShoppalbleButton ? (
                  <Button
                    onClick={event =>
                      this.props.contentAction(content, "shoppable")
                    }
                    disabled={disableShoppalbleButton}
                  >
                    {this.props.isMobile ? "" : "Shoppable"}
                  </Button>
                ) : (
                  <Button
                    onClick={event =>
                      this.props.contentAction(content, "unshoppable")
                    }
                  >
                    {this.props.isMobile ? "" : "Remove Shoppable"}
                  </Button>
                ))}
              {showTagNonEnglish && !this.props.isModerationSection && (
                <Button
                  onClick={event =>
                    this.props.contentAction(content, "tagNonEnglish")
                  }
                >
                  {this.props.isMobile ? "EN" : "Non English"}
                </Button>
              )}
              {showConvertToVertical && !this.props.isModerationSection && (
                <Button
                  onClick={event =>
                    this.props.contentAction(content, "convertToVertical")
                  }
                >
                  {this.props.isMobile ? "" : "Convert To Vertical"}
                </Button>
              )}
              {showRemoveDuplicate && !this.props.isModerationSection && (
                <Button
                  onClick={() =>
                    this.props.contentAction(content, "remove_duplicate")
                  }
                  disabled={content.duplicate_content ? false : true}
                >
                  {this.props.isMobile ? "" : "Remove Duplicate"}
                </Button>
              )}
              {!this.props.isModerationSection && (
                <Popover content={this.popoverContent} trigger="click">
                  <Button>Raise Review</Button>
                </Popover>
              )}
              {showQCButton && this.props.isModerationSection && (
                <Button
                  onClick={() =>
                    this.props.contentAction(content, "quality_check")
                  }
                  disabled={
                    content.qc_done ||
                    content?.reject_reason === "copyright_content"
                  }
                >
                  {this.props.isMobile ? "" : "QC"}
                </Button>
              )}
              {this.props.isModerationSection && (
                <Button
                  disabled={
                    content.reviewed ||
                    content?.reject_reason === "copyright_content"
                  }
                  onClick={event =>
                    this.props.contentAction(content, "review_content")
                  }
                >
                  {this.props.isMobile ? "" : "Review"}
                </Button>
              )}
              {this.props.isModerationSection &&
                this.props?.currentUser?.permissions?.includes(
                  "RESTORE_CONTENT"
                ) && (
                  <Button
                    onClick={event =>
                      this.props.contentAction(content, "restore_content")
                    }
                  >
                    {this.props.isMobile ? "" : "Restore"}
                  </Button>
                )}
              {showTagContent && !this.props.isModerationSection && (
                <Button
                  disabled={this.props.tagContentDisabled}
                  onClick={() => this.props.handleTagContent(content)}
                >
                  Tag Content
                </Button>
              )}
              {showTaxonomyContent && !this.props.isModerationSection && (
                <Button
                  onClick={event =>
                    this.props.contentAction(content, "taxonomyContent")
                  }
                >
                  Add Taxonomy
                </Button>
              )}
              {showActivateButton && this.props.isModerationSection && (
                <Button
                  disabled={disableActivateButton}
                  onClick={event =>
                    this.props.contentAction(content, "activate")
                  }
                >
                  {this.props.isMobile ? "" : "Activate"}
                </Button>
              )}
              {showDeActivateButton && this.props.isModerationSection && (
                <Button
                  disabled={!enableDeactivateButton}
                  onClick={() =>
                    this.props.deativateReasonFunc(
                      content,
                      "deactivate",
                      isNotifWorthyContent
                    )
                  }
                >
                  {this.props.isMobile ? "" : "DeActivate"}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      </div>
    )
  }
}
