import React from "react"
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, { Select, TextField, TextArea } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import "./cmsVideoAssets.styles.css"

class UpsertCMSVideoTemplatesForm extends ErrorBoundComponent {
  _form = () => {
    let effectOptions = []
    let stickerOptions = []
    let baseStickerPosition = '{"top": 300, "left": 300}'
    for (let videoAsset of this.props.cmsVideoAssets) {
      if (videoAsset.type === "STICKER") {
        stickerOptions.push({ label: videoAsset.name, value: videoAsset.uuid })
      } else {
        effectOptions.push({ label: videoAsset.name, value: videoAsset.uuid })
      }
    }
    return (
      <Card>
        <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
          <CardTitle>{`${
            this.props.formAction === "create" ? "Add" : "Edit"
          } Video Template`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              {this.props.formAction === "update" && (
                <Col sm={12} md={4}>
                  <TextField
                    label={"uuid"}
                    placeholderLabel="Enter the Name"
                    field="uuid"
                    disabled
                    defaultValue={
                      this.props.formAction === "update"
                        ? _.get(this.props, "videoTemplate.uuid")
                        : ""
                    }
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
              )}
              <Col sm={12} md={4}>
                <TextField
                  label={"Name"}
                  placeholderLabel="Enter the Name"
                  field="name"
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoTemplate.name")
                      : ""
                  }
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={4}>
                <TextField
                  label={"Campaign Name"}
                  placeholderLabel="Enter the Campaign Name"
                  field="campaignName"
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoTemplate.campaignName")
                      : ""
                  }
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={4}>
                <Select
                  label="Status"
                  placeholderLabel="Template Status"
                  field="status"
                  className="creator-form"
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoTemplate.status")
                      : "ACTIVE"
                  }
                  options={[
                    { label: "Active", value: "ACTIVE" },
                    { label: "InActive", value: "INACTIVE" },
                  ]}
                />
              </Col>
              <Col sm={12} md={4}>
                <Select
                  label="Effect UUID"
                  placeholderLabel="Select Effect"
                  field="effectId"
                  className="creator-form"
                  disabled={
                    this.props.formAction === "update" &&
                    _.get(this.props, "videoTemplate.effectId")
                  }
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoTemplate.effectId")
                      : ""
                  }
                  options={effectOptions}
                />
              </Col>

              <Col sm={12} md={4}>
                <Select
                  label="Primary Sticker"
                  placeholderLabel="Select Primary Sticker"
                  field="primarySticker"
                  className="creator-form"
                  disabled={
                    this.props.formAction === "update" &&
                    _.get(this.props, "videoTemplate.primarySticker")
                  }
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoTemplate.primarySticker")
                      : ""
                  }
                  options={stickerOptions}
                />
              </Col>
              <Col sm={12} md={4}>
                <Select
                  label="Secondary Sticker"
                  placeholderLabel="Select Secondary Sticker"
                  field="secondarySticker"
                  className="creator-form"
                  disabled={
                    this.props.formAction === "update" &&
                    _.get(this.props, "videoTemplate.secondarySticker")
                  }
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoTemplate.secondarySticker")
                      : ""
                  }
                  options={stickerOptions}
                />
              </Col>
              <Col sm={12} md={4}>
                <Select
                  label="Tertiary Sticker"
                  placeholderLabel="Select Tertiary Sticker"
                  field="tertiarySticker"
                  className="creator-form"
                  disabled={
                    this.props.formAction === "update" &&
                    _.get(this.props, "videoTemplate.tertiarySticker")
                  }
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoTemplate.tertiarySticker")
                      : ""
                  }
                  options={stickerOptions}
                />
              </Col>
              <Col sm={12} md={4}>
                <TextArea
                  label={"Primary Sticker Position"}
                  placeholderLabel="Enter the Sticker Postition"
                  field="primaryStickerPosition"
                  defaultValue={
                    this.props.formAction === "update"
                      ? JSON.stringify(
                          _.get(
                            this.props,
                            "videoTemplate.primaryStickerPosition"
                          )
                        )
                      : baseStickerPosition
                  }
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={4}>
                <TextArea
                  label={"Secondary Sticker Position"}
                  placeholderLabel="Enter the Sticker Postition"
                  field="secondaryStickerPosition"
                  defaultValue={
                    this.props.formAction === "update"
                      ? JSON.stringify(
                          _.get(
                            this.props,
                            "videoTemplate.secondaryStickerPosition"
                          )
                        )
                      : baseStickerPosition
                  }
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={4}>
                <TextArea
                  label={"Tertiary Sticker Position"}
                  placeholderLabel="Enter the Sticker Postition"
                  field="tertiaryStickerPosition"
                  defaultValue={
                    this.props.formAction === "update"
                      ? JSON.stringify(
                          _.get(
                            this.props,
                            "videoTemplate.tertiaryStickerPosition"
                          )
                        )
                      : baseStickerPosition
                  }
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    htmlType="submit"
                    disabled={this.state.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                  >
                    {this.props.formAction === "update" ? "Update" : "Create"}
                  </button>
                  <button
                    onClick={() => {
                      this.props.onCancel()
                    }}
                    color="danger"
                    className="btn btn-danger mx-2"
                  >
                    Cancel
                  </button>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    this.props.onSubmit(formData)
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

export default UpsertCMSVideoTemplatesForm
