import React, { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { connect } from "react-redux"
import Axios from "axios"
import QueryString from "query-string"
import _ from "lodash"
import { map } from "lodash"
import { Pagination, Tag } from "antd"
import Auxiliary from "util/Auxiliary"
import { Col, Container, Row, Table } from "reactstrap"
import { Tooltip } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { formatNumber } from "../../util/common"
import toastr from "toastr"
import { CSVLink } from "react-csv"

const fetchURLs = async payload => {
  try {
    const { filters } = payload
    const offset = filters["currentPage"] ? filters["currentPage"] - 1 : 0
    const limit = filters["pageSize"] ? filters["pageSize"] : 10
    _.unset(filters, "currentPage")
    const { data } = await Axios.post(
      `/api/content/urls?offset=${offset}&limit=${limit}`,
      { ...payload }
    )
    return data
  } catch (error) {
    return error
  }
}

const fetchReports = async () => {
  try {
    const { data } = await Axios.post(`/api/content/all-urls`, {
      filters: { platformNotExist: "Y" },
    })
    return data
  } catch (error) {
    return error
  }
}

const SourceContentUrl = props => {
  const [filters, setFilters] = useState({
    pageSize:
      props.queryParams && props.queryParams["pageSize"]
        ? props.queryParams["pageSize"]
        : 10,
    currentPage:
      props.queryParams && props.queryParams["currentPage"]
        ? props.queryParams["currentPage"]
        : 1,
    platformNotExist: "Y",
  })

  const [csvReportsData, setCsvReportsData] = useState([])

  useEffect(() => {
    props.history.push(
      `/content/source/urls?${QueryString.stringify(
        _.deepClean({ ...filters })
      )}`
    )
  }, [filters.currentPage])

  const fetchURLsQuery = useQuery({
    queryKey: ["content-urls", { currentPage: filters.currentPage }],
    queryFn: () =>
      fetchURLs({
        filters: _.cloneDeep(filters),
      }),
  })

  const fetchReportsQuery = useQuery({
    queryKey: ["url-reports"],
    queryFn: () => fetchReports(),
    onSuccess: result => {
      const data = result.map(content => {
        return {
          ContentUUID: content.content_uuid,
          SourceURL: content.source_meta[0].source_url,
        }
      })

      setCsvReportsData(data)
    },
  })

  const _onPageChange = value => {
    let newFilters = _.cloneDeep(filters)
    newFilters["currentPage"] = value
    setFilters(newFilters)
  }

  return (
    <Auxiliary
      loading={fetchURLsQuery.isFetching}
      error={fetchURLsQuery.isError}
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-primary align-self-end d-flex btn-sm font-size-14"
          style={{ width: "fit-content", fontWeight: "bold" }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <i class="bx bx-download bx-xs"></i>{" "}
            <div>
              &nbsp;
              <CSVLink style={{ color: "white" }} data={csvReportsData}>
                Reports
              </CSVLink>
            </div>
          </div>
        </button>
      </div>

      {fetchURLsQuery.isSuccess && (
        <React.Fragment>
          <div>
            <Container fluid>
              {fetchURLsQuery.data.total.value > 0 && (
                <div className="text-center mb-2">
                  <Tag color="geekblue">
                    Showing <b>{fetchURLsQuery.data.content.length}</b> of{" "}
                    <b>{formatNumber(fetchURLsQuery.data.total.value)}</b>{" "}
                    Reports
                  </Tag>
                </div>
              )}
              <Row>
                <Col lg="12">
                  <div className="table-responsive">
                    <Table className="project-list-table table-nowrap align-middle table-borderless">
                      <tbody>
                        {map(fetchURLsQuery.data.content, (report, index) => (
                          <tr key={index}>
                            <td className="width130">
                              <img
                                className="avatar-lg"
                                src={report.animated_icon_url}
                              />
                            </td>
                            <td>
                              <tr>
                                <td>
                                  <Tooltip
                                    title={report.source_meta[0].source_url}
                                  >
                                    <div
                                      className="fontSize18 my-1 mb-1 ml-6 fw-bold"
                                      style={{
                                        maxWidth: "55rem",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {report.source_meta[0].source_url}
                                    </div>
                                  </Tooltip>
                                </td>
                              </tr>
                              {/* </div> */}
                            </td>
                            <td>
                              <CopyToClipboard
                                text={report.content_uuid}
                                onCopy={e => {
                                  toastr.success("Copied sucessfully!")
                                }}
                              >
                                <Tooltip title="Copy Content ID">
                                  <i
                                    className="fontSize18 bx bx-copy"
                                    role="button"
                                  />
                                </Tooltip>
                              </CopyToClipboard>
                              <CopyToClipboard
                                text={report.source_meta[0].source_url}
                                onCopy={e => {
                                  toastr.success("Copied sucessfully!")
                                }}
                              >
                                <Tooltip title="Copy Source URL">
                                  <i
                                    className="fontSize18 bx bx-copy mx-2"
                                    role="button"
                                  />
                                </Tooltip>
                              </CopyToClipboard>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="d-flex justify-content-end mb-3">
            <Pagination
              style={{ marginTop: "1rem" }}
              onChange={_onPageChange}
              defaultCurrent={filters.currentPage}
              total={
                fetchURLsQuery.data.total.value < 10000
                  ? fetchURLsQuery.data.total.value
                  : 10000
              }
              pageSize={filters.pageSize}
              showSizeChanger={false}
            />
          </div>
        </React.Fragment>
      )}
    </Auxiliary>
  )
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
  }
}

export default connect(mapStateToProps)(SourceContentUrl)
