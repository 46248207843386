import { Pagination, Tag } from "antd"
import { getCmsUserList } from "appRedux/slices/cmsUser"
import { getCommentByFilter, resetState } from "appRedux/slices/comment"
import Chips from "components/CommonFilter/chips"
import _ from "lodash"
import QueryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { commentFilters } from "../../constants/uiConstants"
import Auxiliary from "../../util/Auxiliary"
import "./browseComment.style.css"
import CommentCard from "./commentCard"
import { formatNumber } from "../../util/common"

class BrowseComment extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : null,
      searchType:
        this.props.queryParams && this.props.queryParams["searchType"]
          ? this.props.queryParams["searchType"]
          : "id",
      moderationLevel:
        this.props.queryParams && this.props.queryParams["moderationLevel"]
          ? this.props.queryParams["moderationLevel"]
          : "",
      rangeSearchType:
        this.props.queryParams && this.props.queryParams["rangeSearchType"]
          ? this.props.queryParams["rangeSearchType"]
          : null,
      opSearchType:
        this.props.queryParams && this.props.queryParams["opSearchType"]
          ? this.props.queryParams["opSearchType"]
          : null,
      rangeSearchText:
        this.props.queryParams && this.props.queryParams["rangeSearchText"]
          ? this.props.queryParams["rangeSearchText"]
          : null,
      rangeSearchFrom:
        this.props.queryParams && this.props.queryParams["rangeSearchFrom"]
          ? this.props.queryParams["rangeSearchFrom"]
          : null,
      rangeSearchTo:
        this.props.queryParams && this.props.queryParams["rangeSearchTo"]
          ? this.props.queryParams["rangeSearchTo"]
          : null,
      bucket:
        this.props.queryParams && this.props.queryParams["bucket"]
          ? this.props.queryParams["bucket"]
          : "-1",
      createdDateFrom:
        this.props.queryParams && this.props.queryParams["createdDateFrom"]
          ? this.props.queryParams["createdDateFrom"]
          : null,
      createdDateTo:
        this.props.queryParams && this.props.queryParams["createdDateTo"]
          ? this.props.queryParams["createdDateTo"]
          : null,
      orderByField:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : "created_date",
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "desc",
      moderatedBy:
        this.props.queryParams && this.props.queryParams["moderatedBy"]
          ? this.props.queryParams["moderatedBy"]
          : "",
      moderatedDateFrom:
        this.props.queryParams && this.props.queryParams["moderatedDateFrom"]
          ? this.props.queryParams["moderatedDateFrom"]
          : null,
      moderatedDateTo:
        this.props.queryParams && this.props.queryParams["moderatedDateTo"]
          ? this.props.queryParams["moderatedDateTo"]
          : null,
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getCmsUserList())

      this.props.dispatch(getCommentByFilter(_.deepClean(filters)))
      const filterCount = Object.keys(_.deepClean(filters)).length
      this.setState({ filterCount, filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }
    this.setState({ filters: newFilters })
  }

  _searchComment = () => {
    let { filters } = this.state
    // if (filters && filters.pageSize) {
    //   delete filters.currentPage
    // }
    this.props.history.push(
      `/comment/browse?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(getCommentByFilter(_.deepClean(filters)))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/comment/browse?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
      this.props.dispatch(getCommentByFilter(_.deepClean(this.state.filters)))
    })
  }

  _closeFilters = () => {
    this._searchComment()
    this.setState({
      showFilters: false,
      showAdvancedFilter: false,
    })
  }

  _showAdvancedFilter = () => {
    this.setState({ showAdvancedFilter: true })
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: commentFilters[filter].defaultValue,
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/comment/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        let filters = _.cloneDeep(this.state.filters)
        this.props.dispatch(
          getCommentByFilter({ filters: _.deepClean(filters) })
        )
      }
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <Chips
          type="comment"
          showFilters={this.state.showFilters}
          showAdvancedFilters={this.state.showAdvancedFilters}
          filters={this.state.filters}
          search={this._closeFilters}
          removeFilter={this._removeFilter}
          handleFilterChange={this._handleFilterChange}
          toggleFilters={this._toggleFilters}
          toggleAdvancedFilters={this._toggleAdvancedFilters}
          moderatorList={this.props.moderatorList}
        />
        {this.props.commentList && this.props.commentList.length > 0 && (
          <div style={{ textAlign: "center" }} className="mb-2">
            <Tag color="geekblue">
              Showing <b>{this.props.commentList.length}</b> of{" "}
              <b>{formatNumber(this.props.total)}</b> Comments
            </Tag>
          </div>
        )}
        {this.props.commentList &&
          this.props.commentList.length > 0 &&
          this.props.commentList.map(hashtag => {
            return (
              <CommentCard
                key={hashtag.name}
                data={hashtag}
                history={this.props.history}
                onSearch={this._closeFilters}
                currentUser={this.props.currentUser}
              />
            )
          })}
        <div className="d-flex justify-content-end mb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={this._onPageChange}
            defaultCurrent={this.state.filters.currentPage}
            total={this.props.total < 10000 ? this.props.total : 10000}
            pageSize={this.state.filters.pageSize}
            showSizeChanger={false}
          />
        </div>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    commentList: _.get(store, "comment.comment"),
    queryParams: _.get(store, "routing.location.search")
      ? QueryString.parseUrl(_.get(store, "routing.location.search")).query
      : undefined,
    total: _.get(store, "comment.total"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "comment.loading"),
  }
}

export default connect(mapStateToProps)(BrowseComment)
