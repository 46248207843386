import Axios from "axios"
import toastr from "toastr"

export const requestGetIssues = async ({ payload }) => {
  try {
    const offset = payload["from"] ? payload["from"] - 1 : 0
    const limit = payload["size"] || 10
    payload["offset"] = offset
    payload["limit"] = limit
    _.unset(payload, "from")
    _.unset(payload, "size")
    let data = await Axios.post(
      `/api/issue/search?offset=${offset}&limit=${limit}`,
      payload
    )
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestGetActivity = async ({ payload }) => {
  try {
    const offset = payload["currentPage"] ? payload["currentPage"] : 1
    const limit = payload["pageSize"]
    payload["offset"] = offset
    payload["limit"] = limit
    _.unset(payload, "currentPage")
    _.unset(payload, "pageSize")
    let data = await Axios.post(
      `/api/issue/activity?offset=${offset}&limit=${limit}`,
      payload
    )
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestUpsertIssue = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/issue/create`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateIssue = async ({ payload }) => {
  try {
    let data = await Axios.put(
      `/api/issue/update/${payload.issueId}`,
      payload.data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
