import { keyLabelMap, keyTextMap } from "./constant"

export const getLablesAndValues = (
  data,
  labelMap = undefined,
  calculatePercentage = false,
  valueKey = "doc_count"
) => {
  let values = []
  let labels = []
  let percentageValues = []
  if (data && data?.length != 0 && data?.data?.total?.buckets?.length != 0) {
    if (data?.data?.total?.buckets?.length === 1) {
      if (!data.data.total.buckets[0].key) {
        return undefined
      }
    }
    for (let item of data?.data?.total?.buckets || []) {
      if (item.key) {
        labels.push((labelMap && labelMap[item.key]) || item.key)
        values.push(item[valueKey])
        if (calculatePercentage) {
          const percentage = (item.doc_count / item.total_doc) * 100
          const percentageValue = `${item.doc_count} (${Math.round(
            Number.isNaN(percentage) ? 0 : percentage
          )}%)`
          percentageValues.push(percentageValue)
        }
      }
    }
    return { labels, values, percentageValues }
  }
  return undefined
}

export const getStackedBarData = (data, labelMap = undefined) => {
  let values = {}
  if (data && data?.length != 0 && data?.data?.total?.buckets?.length != 0) {
    if (data?.data?.total?.buckets?.length === 1) {
      if (!data.data.total.buckets[0].key) {
        return undefined
      }
    }
    for (let item of data?.data?.total?.buckets) {
      for (let item2 of item?.curatedType?.buckets) {
        if (values[(labelMap && labelMap[item2.key]) || item2.key]) {
          values[(labelMap && labelMap[item2.key]) || item2.key].push({
            date: item.key_as_string.split("T")[0],
            count: item2.doc_count,
          })
        } else {
          values[(labelMap && labelMap[item2.key]) || item2.key] = [
            {
              date: item.key_as_string.split("T")[0],
              count: item2.doc_count,
            },
          ]
        }
      }
    }
    return values
  }

  return undefined
}

export const getAllActiveUserGraphData = data => {
  if (data && data?.length != 0 && data?.data?.total?.buckets?.length != 0) {
    if (data?.data?.total?.buckets?.length === 1) {
      if (!data.data.total.buckets[0].key) {
        return undefined
      }
    }
    const values = []
    const labels = []
    const text = []
    for (let item of data?.data?.total?.buckets) {
      if (item.key && item.key_as_string) {
        const date = item.key_as_string
          ? item.key_as_string.split("T")[0] || item.key_as_string || item.key
          : item.key
        labels.push(date)
        values.push(item.active_percentage)
        text.push(`<b>Active Creator: ${item.active_percentage} %</b>`)
      }
    }
    return [
      {
        x: labels,
        y: values,
        text,
        type: "scatter",
        hovertemplate: "<br><b>Date: %{x}</b><br>" + "<b>%{text}</b>",
      },
    ]
  }
  return undefined
}

export const getAllTypeActiveUserGraphData = (data, labelMap = undefined) => {
  if (data && data?.length != 0 && data?.data?.total?.buckets?.length != 0) {
    if (data?.data?.total?.buckets?.length === 1) {
      if (!data?.data?.total?.buckets[0]?.key) {
        return undefined
      }
    }
    const dataMap = {}
    const graphData = []
    for (let item of data?.data?.total?.buckets) {
      const { key, key_as_string = "", type_map = [] } = item || {}
      const date = key_as_string
        ? key_as_string.split("T")[0] || key_as_string || key
        : key
      if (type_map && type_map.length != 0) {
        type_map.forEach(type => {
          const keyName = labelMap[type.key] || type.key
          if (dataMap[keyName]) {
            dataMap[keyName] = [
              ...dataMap[keyName],
              { key: date, value: type.active_percentage },
            ]
          } else {
            dataMap[keyName] = [{ key: date, value: type.active_percentage }]
          }
        })
      }
    }
    Object.keys(dataMap).forEach(type => {
      const values = []
      const labels = []
      const text = []
      dataMap[type].forEach(data => {
        labels.push(data.key)
        values.push(data.value)
        text.push(`<b>${type}: <i>${data.value} %</i></b>`)
      })
      graphData.push({
        x: labels,
        y: values,
        text,
        type: "scatter",
        name: type,
        hovertemplate: "<br><b>Date: %{x}</b><br>" + "<b>%{text}</b>",
      })
    })
    return graphData
  }
  return undefined
}

export const getAllTypePostCountFunnelGraphData = data => {
  if (data && Object.keys(data) && Object.keys(data).length) {
    const type = "funnel"
    const y_axis = []
    const x_axis = []
    const text = []
    Object.keys(data).forEach(key => {
      if (data[key]) {
        x_axis.push(data[key])
        let percentage = 0
        if (data.totalPostCount)
          percentage = (data[key] / data.totalPostCount) * 100 || 0
        const percentageValue = `${Math.round(
          Number.isNaN(percentage) ? 0 : percentage
        )}%`
        y_axis.push(
          `<b>Title: <i>${keyLabelMap[key]}</i><br>Count: <i>${data[key]}</i><br>Percentage: <i>${percentageValue}</i></b>`
        )
        text.push(`<b>${keyLabelMap[key]}<br>${percentageValue}</b>`)
      }
    })

    return [
      {
        type,
        y: y_axis,
        x: x_axis,
        text,
        hovertemplate: "%{y}<extra></extra>",
      },
    ]
  }
  return undefined
}
