import React from "react"
import ErrorBoundComponent from "components/ErrorBoundComponent"
import { connect } from "react-redux"
import { Pagination } from "antd"
import QueryString from "query-string"
import toastr from "toastr"
import { getContestListByFilters, upsertContest } from "appRedux/slices/contest"
import Auxiliary from "../../util/Auxiliary"
import ContestCard from "./contestCard"
import _ from "lodash"
import Chips from "components/CommonFilter/chips"
import { contestFilters, NO_PERMISSION_MESSAGE } from "constants/uiConstants"

class BrowseContest extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize: 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchType: this.props.queryParams
        ? this.props.queryParams["searchType"]
        : undefined,
      searchText: this.props.queryParams
        ? this.props.queryParams["searchText"]
        : undefined,
      type: this.props.queryParams ? this.props.queryParams["type"] : undefined,
      status: this.props.queryParams
        ? this.props.queryParams["status"]
        : undefined,
      sponsored:
        this.props.queryParams && this.props.queryParams["sponsored"]
          ? this.props.queryParams["sponsored"]
          : "",
    },
  }
  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getContestListByFilters(_.deepClean(this.state.filters))
      )
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/contest/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getContestListByFilters(_.deepClean(this.state.filters))
        )
      }
    )
  }

  _closeFilters = () => {
    this._searchContest()
    this.setState({
      showAdvancedFilter: false,
      showFilters: false,
    })
  }

  _searchContest = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/contest/browse?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(
      getContestListByFilters(_.deepClean(this.state.filters))
    )
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }
    this.setState({ filters: newFilters })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: contestFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/contest/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getContestListByFilters(_.deepClean(this.state.filters))
        )
      }
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <Chips
          showFilters={this.state.showFilters}
          filters={this.state.filters}
          search={this._closeFilters}
          removeFilter={this._removeFilter}
          handleFilterChange={this._handleFilterChange}
          type="contest"
          toggleFilters={this._toggleFilters}
        />
        <div className="d-flex justify-content-end mb-3">
          <button
            className="btn btn-primary"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes(
                  "VIEW_MANAGE_CONTEST"
                )
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.props.history.push(`/contest/manage`)
            }}
          >
            Create
          </button>
        </div>
        {this.props.contestList?.length > 0 && (
          <>
            <div className="contest-card-container">
              {this.props.contestList.map(contest => {
                return (
                  <div className="contest-card">
                    <ContestCard
                      contest={contest}
                      currentUser={this.props.currentUser}
                    />
                  </div>
                )
              })}
            </div>
            <div className="d-flex justify-content-end py-2">
              <Pagination
                style={{ marginTop: "1rem" }}
                onChange={this._onPageChange}
                defaultCurrent={this.state.filters["currentPage"]}
                total={this.props.total}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "contest.loading"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    contestList: _.get(store, "contest.contestList"),
    total: _.get(store, "contest.total"),
  }
}

export default connect(mapStateToProps)(BrowseContest)
