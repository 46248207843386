import Axios from "axios"
import toastr from "toastr"

export const requestMemes = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/meme/get?offset=${offset}&limit=${limit}`,
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertMeme = async ({ payload }) => {
  try {
    const url = `/api/meme/upsert?action=${payload.action}`
    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestReorderMeme = async ({ payload }) => {
  try {
    const url = `/api/meme/reorder/target`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
