import { all, call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import {
  requestgetUser,
  requestGetManagersList,
  requestGetUnassignedManagersList,
  requestgetCommunityUserByType,
  requestGetCommunityContent,
  requestGetTopCreatorsList,
  requestGetAggData,
  requestGetChartData,
  requestgetAllCreatorNumberByCuratedGenre,
  requestgetAllCreatorNumberByLanguage,
  requestGetManagerData,
  requestDeleteUser,
  requestGetChangelog,
  requestGetLeads,
  requestGetTimespanChartData,
  requestGetTrendsChartData,
  requestGetActiveUserTrendsChartData,
  requestGetTypeWiseActiveUserTrendsChartData,
  requestGetAllPostTrendsChartData,
  requestGetAllPercentageOfPostByXViewData,
  requestGetAllPostsData,
  requestGetAllUserData,
  requestGetTeamData,
  requestGetCSVData,
  requestGetDuplicateContentChartData,
  requestUploadLeaderboard,
  requestLeaderboardUsersByFilters,
} from "appRedux/sagas/requests/community"
import {
  requestCreateUser,
  requestUpdateUser,
} from "appRedux/sagas/requests/cmsUser"
import {
  getUser,
  getUserSuccess,
  getManagersList,
  getManagersListSuccess,
  getUnassignedManagersList,
  getUnassignedManagersListSuccess,
  getCommunityUserByType,
  getCommunityUserByTypeSuccess,
  getCommunityContent,
  getCommunityContentSuccess,
  getTopCreatorsList,
  getTopCreatorsListSuccess,
  getAggData,
  getAggDataSuccess,
  getTeamData,
  getTeamDataSuccess,
  getManagerData,
  getManagerDataSuccess,
  deleteUser,
  deleteUserSuccess,
  createUser,
  updateUser,
  updateUserSuccess,
  createUserSuccess,
  getLogs,
  getLogsSuccess,
  getLeads,
  getLeadsSuccess,
  getChartDataSuccess,
  getChartData,
  getGenreTimespanChartData,
  getPodTimespanChartData,
  getLanguageTimespanChartData,
  getGenreTimespanChartDataSuccess,
  getPodTimespanChartDataSuccess,
  getLanguageTimespanChartDataSuccess,
  getGenreTrendsChartData,
  getPodTrendsChartData,
  getLanguageTrendsChartData,
  getGenreTrendsChartDataSuccess,
  getPodTrendsChartDataSuccess,
  getLangTrendsChartDataSuccess,
  getActiveUserTrendsChartData,
  getActiveUserTrendsChartDataSuccess,
  getPodActiveUserTrendsChartData,
  getPodActiveUserTrendsChartDataSuccess,
  getLanguageActiveUserTrendsChartData,
  getLanguageActiveUserTrendsChartDataSuccess,
  getGenreActiveUserTrendsChartData,
  getGenreActiveUserTrendsChartDataSuccess,
  getAllPostTrendsChartData,
  getAllPostTrendsChartDataSuccess,
  getAllPercentageOfPostByXViewDataSuccess,
  getAllPercentageOfPostByXViewData,
  getAllPostsData,
  getAllPostsDataSuccess,
  getAllUserData,
  getAllUserDataSuccess,
  getCSVData,
  getCSVDataSuccess,
  getDuplicateContentChartData,
  getDuplicateContentChartDataSuccess,
  uploadLeaderboard,
  getLeaderboardDataByFilters,
  getLeaderboardDataByFiltersSuccess,
} from "appRedux/slices/community"
import { fetchError } from "appRedux/slices/common"

const POD = "pod"
const LANGUAGE = "language"
const GENRE = "genre"

function* handleGetUser(params) {
  try {
    const data = yield call(requestgetUser, params)
    yield put(getUserSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetManagersList(params) {
  try {
    const data = yield call(requestGetManagersList, params)
    yield put(getManagersListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetManagerData(params) {
  try {
    const data = yield call(requestGetManagerData, params)
    yield put(getManagerDataSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetUnassignedManagersList(params) {
  try {
    const data = yield call(requestGetUnassignedManagersList, params)
    yield put(getUnassignedManagersListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetCommunityUserByType(params) {
  try {
    const data = yield call(requestgetCommunityUserByType, params)
    yield put(getCommunityUserByTypeSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetCommunityContent(params) {
  try {
    const data = yield call(requestGetCommunityContent, params)
    yield put(getCommunityContentSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleTopCreatorsList(params) {
  try {
    const data = yield call(requestGetTopCreatorsList, params)
    yield put(getTopCreatorsListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAggData(params) {
  try {
    const data = yield call(requestGetAggData, params)
    yield put(getAggDataSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetTeamData(params) {
  try {
    const data = yield call(requestGetTeamData, params)
    yield put(getTeamDataSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetChartData(params) {
  try {
    const [data, data2, data3] = yield all([
      call(requestGetChartData, params),
      call(requestgetAllCreatorNumberByCuratedGenre, params),
      call(requestgetAllCreatorNumberByLanguage, params),
    ])
    yield put(
      getChartDataSuccess({
        data: {
          ByCuratedType: data.data,
          ByCuratedGenre: data2.data,
          ByLanguage: data3.data,
        },
      })
    )
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetTimespanChartData(params) {
  try {
    const { payload: { keyName = "", graphType = "" } = {} } = params || {}

    if (keyName && graphType) {
      const response = yield call(requestGetTimespanChartData, params)
      if (graphType === POD) {
        yield put(getPodTimespanChartDataSuccess({ podData: response }))
      } else if (graphType === LANGUAGE) {
        yield put(
          getLanguageTimespanChartDataSuccess({ languageData: response })
        )
      } else if (graphType === GENRE) {
        yield put(getGenreTimespanChartDataSuccess({ genreData: response }))
      }
    } else {
      if (graphType === POD) {
        yield put(getPodTimespanChartDataSuccess({}))
      } else if (graphType === LANGUAGE) {
        yield put(getLanguageTimespanChartDataSuccess({}))
      } else if (graphType === GENRE) {
        yield put(getGenreTimespanChartDataSuccess({}))
      }
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* handleGetTrendsByTypeChartData(params) {
  try {
    const { payload: { keyName = "", graphType = "" } = {} } = params || {}

    if (keyName && graphType) {
      const response = yield call(requestGetTrendsChartData, params)
      if (graphType === POD) {
        yield put(getPodTrendsChartDataSuccess({ podData: response }))
      } else if (graphType === LANGUAGE) {
        yield put(getLangTrendsChartDataSuccess({ languageData: response }))
      } else if (graphType === GENRE) {
        yield put(getGenreTrendsChartDataSuccess({ genreData: response }))
      }
    } else {
      if (graphType === POD) {
        yield put(getPodTrendsChartDataSuccess({}))
      } else if (graphType === LANGUAGE) {
        yield put(getLangTrendsChartDataSuccess({}))
      } else if (graphType === GENRE) {
        yield put(getGenreTrendsChartDataSuccess({}))
      }
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* handleGetAllPostTrendsChartData(params) {
  try {
    const { payload: { keyName = "" } = {} } = params || {}

    if (keyName) {
      const response = yield call(requestGetAllPostTrendsChartData, params)
      yield put(getAllPostTrendsChartDataSuccess({ allPostData: response }))
    } else {
      yield put(getAllPostTrendsChartDataSuccess({}))
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* handleGetAllPercentageOfPostByXViewData(params) {
  try {
    const { payload: { keyName = "" } = {} } = params || {}

    if (keyName) {
      const response = yield call(
        requestGetAllPercentageOfPostByXViewData,
        params
      )
      yield put(
        getAllPercentageOfPostByXViewDataSuccess({
          percentageViewData: response,
        })
      )
    } else {
      yield put(getAllPercentageOfPostByXViewDataSuccess({}))
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* handleGetActiveUserTrendsChartData(params) {
  try {
    const { payload: { keyName = "" } = {} } = params || {}
    if (keyName) {
      const data = yield call(requestGetActiveUserTrendsChartData, params)
      yield put(getActiveUserTrendsChartDataSuccess(data))
    } else {
      yield put(getActiveUserTrendsChartDataSuccess({}))
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetTypeWiseActiveUserTrendsChartData(params) {
  try {
    const { payload: { keyName = "", graphType = "" } = {} } = params || {}

    if (keyName && graphType) {
      const response = yield call(
        requestGetTypeWiseActiveUserTrendsChartData,
        params
      )
      if (graphType === POD) {
        yield put(getPodActiveUserTrendsChartDataSuccess({ podData: response }))
      } else if (graphType === LANGUAGE) {
        yield put(
          getLanguageActiveUserTrendsChartDataSuccess({
            languageData: response,
          })
        )
      } else if (graphType === GENRE) {
        yield put(
          getGenreActiveUserTrendsChartDataSuccess({ genreData: response })
        )
      }
    } else {
      if (graphType === POD) {
        yield put(getPodActiveUserTrendsChartDataSuccess({}))
      } else if (graphType === LANGUAGE) {
        yield put(getLanguageActiveUserTrendsChartDataSuccess({}))
      } else if (graphType === GENRE) {
        yield put(getGenreActiveUserTrendsChartDataSuccess({}))
      }
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAllPostsData(params) {
  try {
    const { payload: { keyName = "" } = {} } = params || {}
    if (keyName) {
      const data = yield call(requestGetAllPostsData, params)
      yield put(getAllPostsDataSuccess(data))
    } else {
      yield put(getAllPostsDataSuccess({}))
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAllUserData(params) {
  try {
    const { payload: { keyName = "" } = {} } = params || {}
    if (keyName) {
      const data = yield call(requestGetAllUserData, params)
      yield put(getAllUserDataSuccess(data))
    } else {
      yield put(getAllUserDataSuccess([]))
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteUser(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestDeleteUser, params)
    yield put(deleteUserSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCreateUser(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestCreateUser, params)
    yield put(createUserSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateUser(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdateUser, params)
    yield put(updateUserSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetLogs(params) {
  try {
    const data = yield call(requestGetChangelog, params)
    yield put(getLogsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetLeads(params) {
  try {
    const data = yield call(requestGetLeads, params)
    yield put(getLeadsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetCSVData(params) {
  try {
    const data = yield call(requestGetCSVData, params)
    yield put(getCSVDataSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetDuplicateContentChartData(params) {
  try {
    const { payload: { keyName = "" } = {} } = params || {}
    if (keyName) {
      const data = yield call(requestGetDuplicateContentChartData, params)
      yield put(getDuplicateContentChartDataSuccess(data))
    } else {
      yield put(getDuplicateContentChartDataSuccess({}))
    }
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUploadLeaderboard(params) {
  try {
    const data = yield call(requestUploadLeaderboard, params)
    toastr.success(data.data)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetLeaderboardUsers(params) {
  try {
    const data = yield call(requestLeaderboardUsersByFilters, params)
    yield put(getLeaderboardDataByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getUser.type, handleGetUser)
  yield takeLatest(getManagersList.type, handleGetManagersList)
  yield takeLatest(getManagerData.type, handleGetManagerData)
  yield takeLatest(
    getUnassignedManagersList.type,
    handleGetUnassignedManagersList
  )
  yield takeLatest(getCommunityUserByType.type, handleGetCommunityUserByType)
  yield takeLatest(getCommunityContent.type, handleGetCommunityContent)
  yield takeLatest(getTopCreatorsList.type, handleTopCreatorsList)
  yield takeLatest(getAggData.type, handleGetAggData)
  yield takeLatest(getTeamData.type, handleGetTeamData)
  yield takeLatest(getChartData.type, handleGetChartData)
  yield takeLatest(getPodTimespanChartData.type, handleGetTimespanChartData)
  yield takeLatest(getGenreTimespanChartData.type, handleGetTimespanChartData)
  yield takeLatest(
    getLanguageTimespanChartData.type,
    handleGetTimespanChartData
  )
  yield takeLatest(getPodTrendsChartData.type, handleGetTrendsByTypeChartData)
  yield takeLatest(getGenreTrendsChartData.type, handleGetTrendsByTypeChartData)
  yield takeLatest(
    getLanguageTrendsChartData.type,
    handleGetTrendsByTypeChartData
  )
  yield takeLatest(
    getActiveUserTrendsChartData.type,
    handleGetActiveUserTrendsChartData
  )
  yield takeLatest(
    getPodActiveUserTrendsChartData.type,
    handleGetTypeWiseActiveUserTrendsChartData
  )
  yield takeLatest(
    getLanguageActiveUserTrendsChartData.type,
    handleGetTypeWiseActiveUserTrendsChartData
  )
  yield takeLatest(
    getGenreActiveUserTrendsChartData.type,
    handleGetTypeWiseActiveUserTrendsChartData
  )

  yield takeLatest(
    getAllPostTrendsChartData.type,
    handleGetAllPostTrendsChartData
  )

  yield takeLatest(
    getAllPercentageOfPostByXViewData.type,
    handleGetAllPercentageOfPostByXViewData
  )
  yield takeLatest(getAllPostsData.type, handleAllPostsData)
  yield takeLatest(getAllUserData.type, handleAllUserData)
  yield takeLatest(deleteUser.type, handleDeleteUser)
  yield takeLatest(createUser.type, handleCreateUser)
  yield takeLatest(updateUser.type, handleUpdateUser)
  yield takeLatest(getLogs.type, handleGetLogs)
  yield takeLatest(getLeads.type, handleGetLeads)
  yield takeLatest(getCSVData.type, handleGetCSVData)
  yield takeLatest(
    getDuplicateContentChartData.type,
    handleGetDuplicateContentChartData
  )
  yield takeLatest(uploadLeaderboard.type, handleUploadLeaderboard)
  yield takeLatest(getLeaderboardDataByFilters.type, handleGetLeaderboardUsers)
}
