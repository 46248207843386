import React from "react"
import { connect } from "react-redux"
import { Modal } from "react-bootstrap"
import ChipInput from "material-ui-chip-input"
import { Row, Col, Card, CardTitle, CardBody, Button } from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import { Pagination, Divider, Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Auxiliary from "../../util/Auxiliary"
import ValidatorForm, { TextField, Select } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getSpinTabConfig,
  updateSpinTabConfig,
} from "../../clientServices/spinConfigService"

class SpinTabConfig extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      spinConfig: [],
    }
  }

  componentDidMount = async () => {
    const response = await getSpinTabConfig()
    this.setState({
      spinConfig:
        response?.spinConfig?.map(spin => {
          if (!spin.id) {
            return { ...spin, id: uuidv4() }
          }
          return spin
        }) || [],
    })
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      [key]: file,
    })
  }

  deletetab = id => {
    const spinConfig = this.state?.spinConfig?.filter(spin => {
      if (spin.id != id) {
        return spin
      }
    })
    this.setState({ spinConfig })
  }

  _getSpinForm = (spin, idx) => {
    return (
      <>
        <Divider className="m-auto">
          <h5 className="mb-0 text-muted">{`Spin - ${idx + 1}`}</h5>
        </Divider>
        <Row className="mb-2">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"Spin ID"}
              field={`spinConfig.${idx}.id`}
              className="creator-form"
              defaultValue={spin.id}
              disabled
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Text"}
              field={`spinConfig.${idx}.text`}
              className="creator-form"
              defaultValue={spin.text || ""}
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Popup Text"}
              field={`spinConfig.${idx}.popup_text`}
              className="creator-form"
              defaultValue={spin.popup_text || ""}
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <label>Background Color</label>
            <input
              className="form-control form-control-color w-100"
              type="color"
              value={this.state[`bg_color_${spin.id}`] || spin.bg_color || null}
              onChange={e =>
                this.setState({ [`bg_color_${spin.id}`]: e.target.value })
              }
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <label>Text Color</label>
            <input
              className="form-control form-control-color w-100"
              type="color"
              value={
                this.state[`text_color_${spin.id}`] || spin.text_color || null
              }
              onChange={e =>
                this.setState({ [`text_color_${spin.id}`]: e.target.value })
              }
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <label className="col-form-label" style={{ paddingTop: "0px" }}>
              Icon URL
            </label>
            <input
              type="file"
              className="form-control"
              onChange={e => {
                this._handleFileUpload(`icon_url=${spin.id}`, e.target.files[0])
                this.setState({ [`hide_image_${spin.id}`]: true })
              }}
              style={{ padding: "0.54rem 0.75rem" }}
            />
            {spin.icon_url && !this.state[`hide_image_${spin.id}`] ? (
              <div className="d-flex justify-content-between align-items-center bg-light">
                <img
                  src={spin.icon_url}
                  width="50px"
                  height="50px"
                  className="p-2"
                />
                <i
                  className="bx bx bx-trash-alt text-danger p-2"
                  role="button"
                  onClick={() =>
                    this.setState({ [`hide_image_${spin.id}`]: true })
                  }
                />
              </div>
            ) : null}
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="d-flex justify-content-end align-items-end"
          >
            <i
              className="bx bx bx-trash-alt bx-sm text-danger p-2"
              role="button"
              onClick={() => this.deletetab(spin.id)}
            />
          </Col>
        </Row>
      </>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    const data =
      formData?.spinConfig?.map(spin => {
        const obj = {
          ...spin,
          bg_color: this.state[`bg_color_${spin.id}`] || "#000000",
          text_color: this.state[`text_color_${spin.id}`] || "#000000",
        }
        const oldSpinData = this.state.spinConfig.filter(
          config => config.id === spin.id
        )

        if (this.state && !this.state[`icon_url=${spin.id}`]) {
          if (oldSpinData && oldSpinData[0] && oldSpinData[0].icon_url) {
            obj.icon_url = oldSpinData[0].icon_url
          }
        }

        if (this.state && this.state[`hide_image_${spin.id}`]) {
          delete obj.icon_url
        }

        if (this.state && !this.state[`bg_color_${spin.id}`]) {
          if (oldSpinData && oldSpinData[0] && oldSpinData[0].bg_color) {
            obj.bg_color = oldSpinData[0].bg_color
          }
        }
        if (this.state && !this.state[`text_color_${spin.id}`]) {
          if (oldSpinData && oldSpinData[0] && oldSpinData[0].text_color) {
            obj.text_color = oldSpinData[0].text_color
          }
        }
        return obj
      }) || []

    let finalData = new FormData()

    finalData.append("data", JSON.stringify(data))

    Object.keys(this.state).forEach(key => {
      if (key.includes("icon_url")) {
        finalData.append(key, this.state[key])
      }
    })
    toastr.info("Action in progress...")
    await updateSpinTabConfig(finalData)
    toastr.success("Action successfull!!")

    location.reload()
  }

  render() {
    return (
      <>
        <div className="bg-white">
          <Modal.Header>
            <Modal.Title>
              <CardTitle className="font-size-18 text-wrap">
                Spin Config
              </CardTitle>
            </Modal.Title>
          </Modal.Header>
          <div className="m-2 p-2">
            <div className="d-flex justify-content-end">
              <Button
                color="outline-primary"
                className="btn-sm font-weight-semibold w-xs font-size-12"
                onClick={() =>
                  this.setState(prev_state => ({
                    spinConfig: [
                      ...prev_state.spinConfig,
                      {
                        id: uuidv4(),
                      },
                    ],
                  }))
                }
              >
                <i className="fa fa-plus mx-1 my-1" /> Spin
              </Button>
            </div>
            <ValidatorForm
              onSubmit={this._onSubmit}
              layout={"vertical"}
              {...this._formLayout}
            >
              {this.state.spinConfig && this.state.spinConfig.length > 0 ? (
                <>
                  {this.state.spinConfig.map((spin, idx) => {
                    return this._getSpinForm(spin, idx)
                  })}
                  <div
                    className="d-flex justify-content-end"
                    style={{ marginTop: "10px" }}
                  >
                    <Button htmlType="submit" color="primary">
                      Submit
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center hero-section text-muted"
                  style={{ fontStyle: "italic" }}
                >
                  No spin config present.
                </div>
              )}
            </ValidatorForm>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "spin.loading"),
    data: _.get(store, "spin.data"),
    refetch: _.get(store, "spin.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(SpinTabConfig)
