import PropTypes from "prop-types"
import React, { useState, createRef } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  Row,
  CardBody,
  Col,
  UncontrolledTooltip,
  Button,
  Table,
  Badge,
} from "reactstrap"
import { Tooltip, Tag } from "antd"
import { size, map, upperCase } from "lodash"
import moment from "moment"
import CopyToClipboard from "react-copy-to-clipboard"
import { formatNumber } from "../../util/common"
import ReactCardFlip from "react-card-flip"
import ReactTimeAgo from "react-time-ago"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { withRouter } from "react-router"
import CustomScrollbars from "util/CustomScrollbars"
import {
  NO_PERMISSION_MESSAGE,
  MODERATION_LEVEL_MAP,
  LANGUAGE_MAPPING,
} from "constants/uiConstants"
import { Rating } from "@mui/material"
import "./style.css"

const _renderThumbnail = content => {
  switch (content.format) {
    case "IMAGE":
      return (
        <img src={content.download_url} width="100%" height="455px" alt="" />
      )
    case "GIF":
      return (
        <img src={content.download_url} width="100%" height="380px" alt="" />
      )
    default:
      return (
        <img
          src={
            content.animated_icon_url
              ? content.animated_icon_url
              : content.thumbnail_url
              ? content.thumbnail_url
              : ""
          }
          width="100%"
          height="455px"
          alt=""
          style={{ filter: "blur(.5px)" }}
        />
      )
  }
}

const DuplicateContentCard = props => {
  const hasEditPermission =
    props.currentUser &&
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("EDIT_BROWSE_CONTENT")
  const [isOpen, setIsOpen] = useState(false)
  const [isFlipped, setIsFlipped] = useState(false)
  const [showVideo, setVideo] = useState(false)
  const [controls, setControls] = useState(props.currentUser.jotUserType !== 4)
  const [playbackRate, setPlayBackRate] = useState(
    props.pageType === "tagContent" ? "1.0" : "1.0"
  )
  const [hostName, setHostName] = useState(window.location.hostname)
  const [content, setContent] = useState(props.content)
  const videoRef = createRef()
  const starStyle = {
    fontSize: "18px",
  }
  const _setPlayBack = () => {
    videoRef.current.playbackRate = playbackRate
    videoRef.current.volume = 0.4
  }
  const isTagContent = props.pageType === "task"

  const _onEdit = () => {
    props.history.push(`/content/manage?contentUUID=${content.content_uuid}`)
  }

  const _getModerationImage = content => {
    if (content.reviewed) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/review.jpg"
    } else if (content.qc_done) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/qcDone.jpg"
    } else if (content.moderation_level) {
      switch (content.moderation_level) {
        case 2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-approved.jpg"

        case 3:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-approved.jpg"

        case -1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-disabled.jpg"

        case 0:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-disabled.jpg"

        case -2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/machine-disabled.jpg"

        case 1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/unmoderated.jpg"

        default:
          return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
      }
    } else {
      return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
    }
  }

  const realStatPermissionUser =
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("VIEW_REAL_STATS")
  let contentTitle = !_.isEmpty(content.content_title)
    ? content.content_title
    : !_.isEmpty(content.prepared_content_title)
    ? content.prepared_content_title
    : "<No Title>"
  let bucket = "review_bucket" in content ? content.review_bucket : undefined
  const showTaxonomyContent =
    props.pageType === "taxonomyContent" || props.pageType === "task"
  const showDeActivateButton =
    content.moderation_level !== -1 && !showTaxonomyContent
  const showButtonToModerator =
    props.currentUser.jotUserType !== 4 &&
    (props.pageType === "content" ||
      props.pageType === "tagContent" ||
      props.pageType === "taxonomyContent" ||
      props.pageType === "task")
  const { customClassname = "" } = props
  const enableEdit = props.enableEdit === false ? false : true
  let today = moment(new Date())
  let notif_worthy_update_time =
    content.notif_worthy_update_time &&
    moment(new Date(content.notif_worthy_update_time))
  let isNotifWorthyContent =
    content &&
    content.notif_worthy &&
    content.notif_worthy_update_time &&
    moment.duration(today.diff(notif_worthy_update_time)).asHours() < 48
  let notifContentDeactivate =
    isNotifWorthyContent &&
    props.currentUser &&
    (![1, 2].includes(props.currentUser.userType) ||
      ![1, 2].includes(props.currentUser.jotUserType) ||
      (props.currentUser.permissions &&
        props.currentUser.permissions.includes("DEACTIVATE_NOTIFY_WORTHY")))

  let isExternalBrandContent =
    content &&
    content.user_profile &&
    (content.user_profile.user_type === "eb" ||
      content.user_profile.user_type === "ib")
  let adsContent = content.adsContent
  let enableDeactivateButton =
    props.currentUser.permissions &&
    (isExternalBrandContent || adsContent
      ? props.currentUser.permissions.includes(
          "CONTENT_DEACTIVATE_PERMISSION"
        ) ||
        props.currentUser.permissions.includes(
          "EB_CONTENT_DEACTIVATION_PERMISSION"
        )
      : props.currentUser.permissions.includes("CONTENT_DEACTIVATE_PERMISSION"))
  const servableContent =
    content?.is_active &&
    (content?.moderation_level >= 2 ||
      (content?.moderation_level === 1 &&
        content?.auto_moderation_label === "sfw"))
      ? true
      : false

  let nonServableContentReason = "Non Servable"

  if (content && !content.is_active) {
    nonServableContentReason = "Content is InActive."
  } else if (content && content.moderation_level < 1) {
    nonServableContentReason = `Moderation status is ${
      MODERATION_LEVEL_MAP[content.moderation_level]
    }.`
  } else if (
    content &&
    content.moderation_level === 1 &&
    content.auto_moderation_label !== "sfw"
  ) {
    nonServableContentReason = `Moderation status is ${
      MODERATION_LEVEL_MAP[content.moderation_level]
    } but Auto Moderation Label value is ${content.auto_moderation_label}.`
  }

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection={"horizontal"}>
      <Card
        className={`${customClassname} text-center shadow-lg ${
          servableContent ? "" : "border-4 border-danger"
        }`}
        style={{ borderRadius: "10px" }}
      >
        <div
          className="bg-primary bg-soft"
          onClick={() => {
            !enableEdit || hostName === "cms.myjosh.in"
              ? ""
              : props.editClick(content, "details")
          }}
          type={!enableEdit ? "" : "button"}
        >
          <Row>
            {!showVideo && (
              <Col className="align-self-end" style={{ width: "100%" }}>
                {_renderThumbnail(content)}
                <div
                  style={{ position: "absolute", top: "10px", left: "20px" }}
                >
                  <img
                    src={_getModerationImage(content)}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "40px",
                    width: "200px",
                  }}
                  className="d-flex justify-content-around"
                >
                  {!content.is_active && (
                    <div className="deleted-icon mx-2">
                      <Tooltip title="Deleted">
                        <i className="mdi mdi-delete-forever" />
                      </Tooltip>
                    </div>
                  )}
                  {content.duplicate_content && (
                    <div className="duplicate-icon mx-2">
                      <Tooltip title="Duplicate">
                        <i className="mdi mdi-content-duplicate" />
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <img
                    src={
                      (content &&
                        content.user_profile &&
                        content.user_profile.profile_pic) ||
                      "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
                    }
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                </div>
                <div
                  role="button"
                  id={"play" + content.content_uuid}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setVideo(!showVideo)
                  }}
                >
                  <i
                    className="bx bx-play"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "70px",
                      color: "white",
                    }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={"play" + content.content_uuid}
                  >
                    Play Video
                  </UncontrolledTooltip>
                </div>
                <div className="hashtag-lang-block" style={{ bottom: "120px" }}>
                  {!_.isEmpty(content.tags_array) && (
                    <div className="hashtag-lang">
                      {"#" + content.tags_array[0]}
                    </div>
                  )}
                  <div className="hashtag-lang" style={{ marginLeft: "5px" }}>
                    {content.lang_code ? content.lang_code.toUpperCase() : ""}
                  </div>
                </div>
                <div className="image-text" style={{ bottom: "90px" }}>
                  {" "}
                  {contentTitle}
                </div>
                <div
                  className="rating"
                  style={{ bottom: "60px", background: "rgba(0, 0, 0, 0)" }}
                >
                  <Tooltip title="Trending Rating">
                    <div>
                      <Rating
                        defaultValue={
                          content.trending_meta && content.trending_meta.rating
                        }
                        readOnly
                        size="small"
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="taxonomy-rating" style={{ bottom: "150px" }}>
                  <Tooltip title="Taxonomy Rating">
                    <div>
                      <Rating
                        defaultValue={
                          content.taxonomy_meta &&
                          content.taxonomy_meta.video_quality_attributes &&
                          content.taxonomy_meta.video_quality_attributes.rating
                        }
                        readOnly
                        size="small"
                      />
                    </div>
                  </Tooltip>
                </div>

                {content.created_date && (
                  <div className="text-block" style={{ bottom: "38px" }}>
                    <ReactTimeAgo
                      date={
                        Number.isNaN(content.created_date)
                          ? Date.parse(content.created_date)
                          : content.created_date
                      }
                      locale="en-US"
                    />
                  </div>
                )}
                <div className="tagged-trending-block">
                  {content.taxonomy_meta && (
                    <Tooltip title="Tagged">
                      <Tag color="red">Tagged</Tag>
                    </Tooltip>
                  )}
                  {content.trending_meta && (
                    <Tooltip title="Trending" className="mt-1">
                      <Tag color="orange">Trending</Tag>
                    </Tooltip>
                  )}
                </div>
              </Col>
            )}
            {showVideo && (
              <Col>
                <video
                  src={content.preview_url}
                  ref={videoRef}
                  height="455px"
                  width="250px"
                  onCanPlay={() => _setPlayBack()}
                  controls={controls}
                  onEnded={() => {
                    setControls(true)
                  }}
                  controlsList={
                    props.currentUser.jotUserType > 3 ? "nodownload" : ""
                  }
                  // muted={props.pageType === "tagContent" ? false : true}

                  onContextMenu={e => {
                    e.preventDefault()
                    return false
                  }}
                  autoPlay
                />
              </Col>
            )}
          </Row>
        </div>
        <CardBody style={{ padding: ".5rem" }}>
          <div
            className="d-flex flex-wrap align-items-start"
            style={{ height: "3rem" }}
          >
            <Tooltip title="Moderation Status">
              <Tag
                color={
                  content.moderation_level === 1 ||
                  content.moderation_level === 3 ||
                  content.moderation_level === 0
                    ? "red"
                    : content.moderation_level === 2
                    ? "#87d068"
                    : "#108ee9"
                }
                style={{
                  color:
                    content.moderation_level === -1 ||
                    content.moderation_level === -2
                      ? "white"
                      : "black",
                }}
              >
                {content.moderation_status}
              </Tag>
            </Tooltip>
            {!servableContent && (
              <Tooltip title={nonServableContentReason}>
                <Tag color="#f50">Non Servable</Tag>
              </Tooltip>
            )}
            {content.moderation_level === -1 && (
              <Tooltip title="Reject Reason">
                <Tag color="#87d068" className="mt-1">
                  {content.reject_reason
                    ? upperCase(content.reject_reason.split("_").join(" "))
                    : ""}
                </Tag>
              </Tooltip>
            )}
            {content.notif_worthy && (
              <Tooltip title="Notify Worthy">
                <Tag color="#87d068" className="mt-1">
                  Notify Worthy
                </Tag>
              </Tooltip>
            )}
            {/* {content.trending_meta && (
              <Tooltip title="Trending" className="mt-1">
                <Tag color="orange">Trending</Tag>
              </Tooltip>
            )} */}
          </div>
          <div className="d-flex justify-content-around align-items-center pt-2">
            <div
              className="d-flex flex-column align-items-center"
              onClick={() => {
                !enableEdit || hostName == "cms.myjosh.in"
                  ? ""
                  : props.editClick(content, "details")
              }}
              type={!enableEdit ? "" : "button"}
            >
              <i
                className="bx bx-show"
                style={{ padding: "2px", fontSize: "16px" }}
              />
              <span>
                {" "}
                {formatNumber(content.view_count + content.fk_views) || 0}
              </span>
            </div>
            <div
              className="d-flex flex-column align-items-center"
              onClick={() => {
                !enableEdit || hostName == "cms.myjosh.in"
                  ? ""
                  : props.editClick(content, "details")
              }}
              type={!enableEdit ? "" : "button"}
            >
              <i
                className="bx bx-like"
                style={{ padding: "2px", fontSize: "16px" }}
              />
              <span>
                {" "}
                {formatNumber(content.like_count + content.fk_likes) || 0}
              </span>
            </div>
            <div
              className="d-flex flex-column align-items-center"
              onClick={() => {
                !enableEdit || hostName == "cms.myjosh.in"
                  ? ""
                  : props.editClick(content, "details")
              }}
              type={!enableEdit ? "" : "button"}
            >
              <i
                className="bx bx-share-alt"
                style={{ padding: "2px", fontSize: "16px" }}
              />
              <span>
                {formatNumber(content.share_count + content.fk_shares) || 0}
              </span>
            </div>
            <div>
              <Tooltip title="View More">
                <i
                  className="bx bx-play"
                  role="button"
                  style={{ fontSize: "20px" }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setIsFlipped(true)
                  }}
                />
              </Tooltip>
            </div>
          </div>

          <div>
            {map(
              content.permissions,
              (tag, index) =>
                index < 2 && (
                  <Link
                    to="#"
                    className="badge bg-primary font-size-11 m-1"
                    key={"_skill_" + content.id + index}
                  >
                    {tag}
                  </Link>
                )
            )}
            {size(content.permissions) > 2 && (
              <Button
                to="#"
                className="badge bg-success font-size-11 m-1"
                key={"_skill_" + content.id}
                onClick={() => setIsOpen(!isOpen)}
              >
                <span
                  title={content.permissions
                    .slice(1, content.permissions, length)
                    .map(a => (
                      <span>{a}</span>
                    ))}
                >
                  +{size(content.permissions) - 1} more
                </span>
              </Button>
            )}
          </div>
        </CardBody>
        <div className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center">
          <div className="flex-fill">
            <CopyToClipboard
              text={content.content_uuid}
              onCopy={e => {
                toastr.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy Content ID">
                <i className="bx bx-copy" role="button" />
              </Tooltip>
            </CopyToClipboard>
          </div>
          {props?.currentUser?.jotUserType < 3 && (
            <div className="flex-fill">
              <Tooltip title="Download Video">
                <a href={content.wmj_url} target="_blank" rel="noreferrer">
                  <i className="bx bx-download" role="button" />
                </a>
              </Tooltip>
            </div>
          )}

          {!isTagContent && hostName != "cms.myjosh.in" && (
            <React.Fragment>
              <div className="flex-fill">
                <Tooltip title="Edit">
                  <i
                    className="bx bx-edit-alt"
                    role="button"
                    onClick={() => _onEdit()}
                  />
                </Tooltip>
              </div>
            </React.Fragment>
          )}
          {isTagContent && (
            <React.Fragment>
              <div className="flex-fill">
                <Tooltip title="Review">
                  <i
                    className="bx bx-search-alt"
                    role="button"
                    onClick={() =>
                      props.contentAction(content, "review_content")
                    }
                  />
                </Tooltip>
              </div>
            </React.Fragment>
          )}
        </div>
      </Card>
      <Card
        className="text-center shadow-lg"
        style={{
          height: "612px",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        <CustomScrollbars>
          <CardBody className="px-0 py-0">
            <Row className="my-sm-2">
              <Col className="ms-2">
                <div className="avatar-xs">
                  <div className="avatar-title rounded-circle bg-light">
                    <span
                      className={
                        "avatar-title rounded-circle bg-light bg-" +
                        "secondary" +
                        " text-warning" +
                        " font-size-16" +
                        " font-weight-semibold"
                      }
                    >
                      <i className="bx bx-bar-chart" />
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="d-flex justify-content-end me-2">
                <div
                  className="avatar-xs"
                  role="button"
                  onClick={() => setIsFlipped(false)}
                >
                  <div className="avatar-title rounded-circle bg-light">
                    <span
                      className={
                        "avatar-title rounded-circle bg-light bg-" +
                        "secondary" +
                        " text-dark" +
                        " font-size-16" +
                        " font-weight-semibold"
                      }
                    >
                      <i className="fas fa-times-circle" />
                    </span>
                  </div>
                </div>
                <div></div>
              </Col>
            </Row>
            <Row>
              <div className="table-responsive">
                <Table className="mb-0 table-striped">
                  <tbody>
                    <tr className="text-start">
                      <th scope="row">Views:</th>
                      <td>
                        {formatNumber(content.view_count + content.fk_views) ||
                          0}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Downloads:</th>
                      <td>
                        {formatNumber(
                          content.download_count + content.fk_downloads
                        ) || 0}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Likes:</th>
                      <td>
                        {" "}
                        {formatNumber(content.like_count + content.fk_likes) ||
                          0}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Shares:</th>
                      <td>
                        {formatNumber(
                          content.share_count + content.fk_shares
                        ) || 0}
                      </td>
                    </tr>
                    {realStatPermissionUser && (
                      <React.Fragment>
                        <tr className="text-start">
                          <th scope="row">Real Downloads:</th>
                          <td>{formatNumber(content.download_count) || 0}</td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Real Views:</th>
                          <td>{formatNumber(content.view_count) || 0}</td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Real Likes:</th>
                          <td>{formatNumber(content.like_count) || 0}</td>
                        </tr>
                        <tr className="text-start">
                          <th scope="row">Real Shares:</th>
                          <td>
                            {content.share_count
                              ? formatNumber(content.share_count)
                              : 0}
                          </td>
                        </tr>
                      </React.Fragment>
                    )}

                    <tr className="text-start">
                      <th scope="row">User:</th>
                      <td className="break-word">
                        {content.user_profile && content.user_profile.user_name}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Language:</th>
                      <td>
                        {content.lang_code
                          ? LANGUAGE_MAPPING[content.lang_code]
                          : ""}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Moderation Status:</th>
                      <td className="break-word">
                        {content.moderation_status}
                      </td>
                    </tr>
                    {bucket !== undefined && (
                      <tr className="text-start">
                        <th scope="row">Review Bucket:</th>
                        <td>{bucket}</td>
                      </tr>
                    )}
                    {content.user_profile && content.user_profile.verified && (
                      <tr className="text-start">
                        <th scope="row">User Type:</th>
                        <td>Verified</td>
                      </tr>
                    )}
                    {content.video_source && (
                      <tr className="text-start">
                        <th scope="row">Video Source:</th>
                        <td>{content.video_source}</td>
                      </tr>
                    )}
                    {content.notif_worthy && (
                      <tr className="text-start">
                        <th scope="row">Notification Worthy:</th>
                        <td>{content.notif_worthy}</td>
                      </tr>
                    )}
                    {content.enhancement_selected &&
                      content.enhancement_selected.length > 0 && (
                        <tr className="text-start">
                          <th scope="row">Enhancement Selected:</th>
                          <td style={{ wordBreak: "break-word" }}>
                            {content.enhancement_selected.map(enhancement => (
                              <span>{enhancement}</span>
                            ))}
                          </td>
                        </tr>
                      )}
                    {content.tags_array && content.tags_array.length > 0 && (
                      <tr className="text-start">
                        <th scope="row">Hashtags:</th>
                        <td className="break-word">
                          {content.tags_array.map(tag => (
                            <Badge
                              pill
                              className="badge-soft-primary m-1"
                              style={{ fontSize: "12px" }}
                            >
                              #{tag}
                            </Badge>
                          ))}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Row>
          </CardBody>
        </CustomScrollbars>
      </Card>
    </ReactCardFlip>
  )
}

DuplicateContentCard.propTypes = {
  content: PropTypes.object,
}

export default withRouter(DuplicateContentCard)
