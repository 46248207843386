import React from "react"
import { Divider } from "antd"
import _ from "lodash"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { TextField } from "../../containers/ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"

export default class RoomUpsertForm extends ErrorBoundComponent {
  state = {
    formData: { ...this.props.data },
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value
    this.setState({ formData })
  }

  _form = () => {
    return (
      // <div>
      //   <Divider>Warning Dialogue Box</Divider>
      //   <Row>
      // <Col sm="12" lg="6" className="pb-2">
      //   <div>
      //     <label>Header</label>
      //     <TextField
      //       placeholderLabel="Header"
      //       field={"hostId"}
      //       onChange={value => this._handleFieldChange("hostId", value)}
      //       defaultValue={this.props.data && this.props.data.hostId}
      //       className="creator-form"
      //       inputFieldSize="medium"
      //     />
      //   </div>
      // </Col>
      //     <Col sm="12" lg="6" className="pb-2">
      //       <label>Warning Text</label>
      //       <TextField
      //         placeholderLabel="Warning Text"
      //         field={"hostName"}
      //         onChange={value => this._handleFieldChange("hostName", value)}
      //         defaultValue={this.props.data && this.props.data.hostName}
      //         className="creator-form"
      //         inputFieldSize="medium"
      //       />
      //     </Col>
      //     <Col sm="12" lg="6" className="pb-2">
      //       <label>Agree</label>
      //       <TextField
      //         placeholderLabel="Agree Text"
      //         field={"title"}
      //         onChange={value => this._handleFieldChange("title", value)}
      //         defaultValue={this.props.data && this.props.data.title}
      //         className="creator-form"
      //         inputFieldSize="medium"
      //       />
      //     </Col>
      //     <Col sm="12" lg="6" className="pb-2">
      //       <label>Cancel</label>
      //       <TextField
      //         placeholderLabel="Cancel Text"
      //         field={"featuredIndex"}
      //         onChange={value =>
      //           this._handleFieldChange("featuredIndex", value)
      //         }
      //         defaultValue={this.props.data && this.props.data.featuredIndex}
      //         className="creator-form"
      //         inputFieldSize="medium"
      //       />
      //     </Col>
      //   </Row>
      // </div>
      <div>
        <Divider>Static Config</Divider>
        <Row>
          <Col sm="12" lg="6" className="pb-2">
            <div>
              <label>Minimun Warning Count Before Room Shut</label>
              <TextField
                placeholderLabel="Minimun Warning Count Before Room Shut"
                onChange={value =>
                  this._handleFieldChange("shut.min.warning.count", value)
                }
                defaultValue={this.state.formData["shut.min.warning.count"]}
                className="creator-form"
                inputFieldSize="medium"
              />
            </div>
          </Col>
          <Col sm="12" lg="6" className="pb-2">
            <div>
              <label>TTL between two warnings (In Seconds)</label>
              <TextField
                placeholderLabel="TTL between two warnings (In Seconds)"
                onChange={value =>
                  this._handleFieldChange("redis.room.warning.sent.ttl", value)
                }
                defaultValue={
                  this.state.formData["redis.room.warning.sent.ttl"]
                }
                className="creator-form"
                inputFieldSize="medium"
              />
            </div>
          </Col>
          <Col sm="12" lg="6" className="pb-2">
            <div>
              <label>TTL remove warnings (In Minutes)</label>
              <TextField
                placeholderLabel="TTL remove warnings (In Minutes)"
                onChange={value =>
                  this._handleFieldChange("warning.remove.ttl", value)
                }
                defaultValue={this.state.formData["warning.remove.ttl"]}
                className="creator-form"
                inputFieldSize="medium"
              />
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => this.props.onSubmit(this.state.formData)}
            color="primary"
            size="default"
            type="button"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            Update
          </Button>
          <Button
            className="mx-2"
            color="danger"
            size="default"
            onClick={this.props.onCancel}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  }

  _render = () => {
    return (
      <Card>
        <CardBody>{this._form()}</CardBody>
      </Card>
    )
  }
}
