import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"
import {
  getTemplateSection,
  getTemplateSectionSuccess,
  upsertTemplateSection,
  upsertTemplateSectionSuccess,
  reorderTemplateSection,
  reorderTemplateSectionSuccess,
  getAllTemplateSections,
  getAllTemplateSectionsSuccess,
  addTemplate,
  addTemplateSuccess,
  getTemplates,
  getTemplatesSuccess,
  updateVersion,
  updateVersionSuccess,
} from "../../slices/templateSection"
import {
  requestTemplateSection,
  requestUpsertTemplateSection,
  requestReorderTemplateSection,
  requestAddTemplate,
  requestUpdateVersion,
} from "../requests/templateSection"
import { requestTemplates } from "appRedux/sagas/requests/cameraAsset"

function* handleGetTemplateSection(params) {
  try {
    const data = yield call(requestTemplateSection, params)
    yield put(getTemplateSectionSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAllTemplateSection(params) {
  try {
    const data = yield call(requestTemplateSection, params)
    yield put(getAllTemplateSectionsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertTemplateSection(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertTemplateSection, params)
    yield put(upsertTemplateSectionSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleReorderTemplateSection(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestReorderTemplateSection, params)
    yield put(reorderTemplateSectionSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAddTemplate(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestAddTemplate, params)
    yield put(addTemplateSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetTemplate(params) {
  try {
    const data = yield call(requestTemplates, params)
    yield put(getTemplatesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateVersion(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdateVersion, params)
    yield put(updateVersionSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getTemplateSection.type, handleGetTemplateSection)
  yield takeLatest(upsertTemplateSection.type, handleUpsertTemplateSection)
  yield takeLatest(reorderTemplateSection.type, handleReorderTemplateSection)
  yield takeLatest(getAllTemplateSections.type, handleGetAllTemplateSection)
  yield takeLatest(addTemplate.type, handleAddTemplate)
  yield takeLatest(getTemplates.type, handleGetTemplate)
  yield takeLatest(updateVersion.type, handleUpdateVersion)
}
