import React, { Component } from "react"
import { Button, Chip } from "@mui/material"
import { MdAdd } from "react-icons/md"
import { Input, Select, DatePicker, Divider, Tooltip, Modal, Form } from "antd"
import { Col, Row } from "reactstrap"
import {
  getCmsUserList,
  getContentByInterestAndSubInterest,
  getInterest,
  getPreviousSessionStatus,
  getVideosByItemsIdsOrUserId,
  saveFilesToS3,
  updateReviewContent,
} from "clientServices/interestServices"
import { InboxOutlined } from "@ant-design/icons"
import { FaCloudUploadAlt, FaSearch } from "react-icons/fa"
import {
  MdSearch,
  MdOutlineFileUpload,
  MdOutlineArrowBackIosNew,
} from "react-icons/md"
import { message, Upload } from "antd"
import "./manageSeed.css"
import ImportVideos from "./ImportVideos"
import VideoCard from "./Videocard"
import moment from "moment"
import Auxiliary from "util/Auxiliary"
import { CURATED_TYPE_MAPPING, LANGUAGES } from "constants/uiConstants"
import toastr from "toastr"
import { CopyToClipboard } from "react-copy-to-clipboard"

const { Dragger } = Upload
const { Option } = Select
const { RangePicker } = DatePicker
export default class AddTabVideos extends Component {
  state = {
    filter: {
      isServable: "servable",
      metric: "absolute",
      interests_review_status: false,
      must_not_is_seed_video: true,
      tab: "addTabVideo",
      approvedBy: "",
      duplicate_interest_filter: "N",
    },
    formData: {},
    data: [],
    addVideosData: [],
    seedVideosSearchText: "",
    showImportVideos: false,
    fileList: [],
  }

  props = {
    onRemove: file => {
      const index = this.state.fileList.indexOf(file)
      const newFileList = this.state.fileList.slice()
      newFileList.splice(index, 1)
      this.setState({
        fileList: newFileList,
      })
      // setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...this.state.fileList, file])
      return false
    },
    customRequest: () => {},
    fileList: this.state.fileList,
  }

  async componentDidMount() {
    const subinterestdata = await getInterest({ entity_type: "sub_interest" })
    const previoussessionStatus = await getPreviousSessionStatus({
      interest: this.props.interest.id,
      subInterest: this.props.subInterest,
    })
    let subintereststatus = {}
    for (let j = 0; j < subinterestdata?.result?.data?.length; j++) {
      subintereststatus[subinterestdata?.result?.data[j].id] = {
        status: subinterestdata?.result?.data[j].is_active,
        name: subinterestdata?.result?.data[j].name,
      }
    }
    const cmsUserListData = await getCmsUserList()
    const interestdata = await getInterest({
      entity_type: "interest",
      type: this.props.interest.type,
      mode: this.props.interest.mode,
    })
    const subinterestData = await getInterest({
      entity_type: "interest",
      id: this.props.interest.id,
    })
    this.setState({
      subInterestStatusList: subintereststatus || {},
      initialinterestData: interestdata?.result?.data || [],
      initialsubinterestData:
        subinterestData?.result?.data[0]?.subInterests || [],
      interestData: interestdata?.result?.data || [],
      subinterestData: subinterestData?.result?.data[0]?.subInterests || [],
      previoussessionStatus: previoussessionStatus.isPreviousSessionActive,
      cmsUserList: cmsUserListData,
    })
    if (this.props.showAddVideos) {
      let formData = { ...this.state.formData }
      formData = {
        ...formData,
        mode: this.state.selectedVideoDetails?.is_private,
      }
      this.setState({
        showAddVideosPopup: true,
      })
      return
    }

    try {
      this.setState({ loading: true })
      const data = await getContentByInterestAndSubInterest({
        ...this.props,
        ...{
          isServable: "servable",
          interests_review_status: false,
          must_not_is_seed_video: true,
          tab: "addTabVideo",
          duplicate_interest_filter: "N",
        },
      })
      this.setState({
        filter: { ...this.state.filter, ...this.props },
        loading: false,
        data: data?.data?.result?.data,
      })
    } catch (error) {
      this.setState({ loading: false })
    }
  }

  _handleAddVideos = type => {
    this.setState({
      showAddVideosPopup: true,
      importType: type,
    })
  }

  _handleVideoDetail = async data => {
    this.setState({
      showVideoDetailPopup: true,
      fieldChange: false,
      selectedVideoDetails: data,
      formData: {
        mode: this.props.interest.mode,
        type: this.props.interest.type,
        interest: this.props.interest.id,
        subinterest: this.props.subInterest,
        interests_review_action: data.interests_review_action,
      },
      interestData: this.state.initialinterestData || [],
      subinterestData: this.state.initialsubinterestData || [],
    })
  }
  handleFilterChange = async (val, type) => {
    let filter = { ...this.state.filter }
    filter[type] = val

    const data = await getContentByInterestAndSubInterest(filter)
    this.setState({
      filter: { ...filter, ...this.props },
      data: data?.data?.result?.data || [],
    })
  }
  handleDateFilterChange = async (val, type) => {
    let filter = { ...this.state.filter }
    filter[type] = !_.isEmpty(val[0]) ? moment(val[0]).format("x") : null
    filter["createdDateTo"] = !_.isEmpty(val[1])
      ? moment(val[1]).format("x")
      : null

    const data = await getContentByInterestAndSubInterest(filter)
    this.setState({
      filter: { ...filter, ...this.props },
      data: data?.data?.result?.data || [],
    })
  }
  _renderFilters = () => {
    return (
      <Row>
        <Col lg="2" md="3" style={{ paddingRight: "0px" }}>
          <Input
            // defaultValue={props.filter["audioId"]}
            placeholder={"Search Item ID"}
            onChange={e =>
              this.handleFilterChange(e.target.value, "content_uuid")
            }
            allowClear={true}
            name="content_uuid"
            size="large"
            style={{ width: "100%" }}
          />
        </Col>
        <Col lg="2" style={{ paddingRight: "0px" }}>
          <Row>
            <Col lg="4" style={{ paddingRight: "2px" }}>
              <Select
                placeholder="Duration"
                // defaultValue={props.filter["searchTypeArtist"]}
                style={{ width: "100%" }}
                onChange={value => {
                  this.handleFilterChange(value, "duration")
                  //handleNameFilter(value)
                }}
                size="large"
              >
                <Option value="">All</Option>
                <Option value="6">&lt;6sec</Option>
                <Option value="15">&lt;15sec</Option>
                <Option value="30">&lt;30sec</Option>
                <Option value="45">&lt;45sec</Option>
                <Option value="60">&gt;1min</Option>
              </Select>
            </Col>
            <Col lg="4" style={{ paddingLeft: "2px" }}>
              <Select
                placeholder="Language"
                defaultValue={this.state.filter["langCode"]}
                onChange={value => {
                  this.handleFilterChange(value, "langCode")
                  //handleNameFilter(value)
                }}
                style={{ width: "100%" }}
                size="large"
                options={[...[{ value: null, label: "None" }], ...LANGUAGES]}
              />
            </Col>
            <Col lg="4" style={{ paddingLeft: "2px" }}>
              <Select
                placeholder="Metric"
                defaultValue={this.state.filter["metric"]}
                onChange={value => {
                  this.handleFilterChange(value, "metric")
                  //handleNameFilter(value)
                }}
                style={{ width: "100%" }}
                size="large"
              >
                <Option value="absolute">Absolute</Option>
                <Option value="vp">VP</Option>
              </Select>
            </Col>
          </Row>
        </Col>

        <Col lg="1" style={{ paddingRight: "0px" }}>
          <Select
            placeholder="Duplicate"
            defaultValue={this.state.filter["duplicate_interest_filter"]}
            onChange={value => {
              this.handleFilterChange(value, "duplicate_interest_filter")
              //handleNameFilter(value)
            }}
            style={{ width: "100%" }}
            size="large"
          >
            <Option value="Y">True</Option>
            <Option value="N">False</Option>
          </Select>
        </Col>
        <Col lg="3" md="8" style={{ paddingRight: "0px" }}>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <RangePicker
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  onChange={(date, dateString) => {
                    this.handleDateFilterChange(dateString, "createdDateFrom")
                  }}
                  size="large"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="1" style={{ paddingRight: "0px" }}>
          <Select
            placeholder="Servable"
            defaultValue={this.state.filter["isServable"]}
            onChange={value => {
              this.handleFilterChange(value, "isServable")
              //handleNameFilter(value)
            }}
            size="large"
            style={{ width: "100%" }}
          >
            <Option value="">All</Option>
            <Option value="servable">Servable</Option>
            <Option value="nonServable">Non Servable</Option>
          </Select>
        </Col>
        <Col lg="1" style={{ paddingRight: "0px" }}>
          <Select
            placeholder="Approved By"
            defaultValue={this.state.filter["approvedBy"]}
            onChange={value => {
              this.handleFilterChange(value, "approvedBy")
              //handleNameFilter(value)
            }}
            size="large"
            style={{ width: "100%" }}
          >
            <Option value="">All</Option>
            <Option value="approvedByOps">Approved By OPS</Option>
            <Option value="reviewedByOps">Reviewed By OPS</Option>
            <Option value="approvedByFeed">Approved By Feed</Option>
          </Select>
        </Col>
        <Col lg="2" md="8" style={{ paddingRight: "0px" }}>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Select
                  allowClear={true}
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                  onChange={e =>
                    this.handleFilterChange(e, "interestReviewedBy")
                  }
                  placeholder="Reviewed By"
                >
                  {this.state.cmsUserList &&
                    this.state.cmsUserList.length > 0 &&
                    this.state.cmsUserList.map(user => (
                      <Option value={user.email}>{user.name}</Option>
                    ))}
                </Select>
                {/* <Input
            // defaultValue={props.filter["audioId"]}
            placeholder={"Tagged By"}
            onChange={e =>
              this.handleFilterChange(e.target.value, "interestReviewedBy")
            }
            allowClear={true}
            name="interestReviewedBy"
            size="large"
            style={{ width: "100%" }}
          /> */}
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    )
  }

  _renderVideo = video => {
    return (
      <div
        onClick={() => {
          this._handleVideoDetail(video)
        }}
      >
        <VideoCard
          key={video.content_uuid}
          details={true}
          content={video}
          metric={this.state.filter.metric}
        ></VideoCard>

        {/* <video
          src={video.download_url}
          // ref={videoRef}
          height="455px"
          width="250px"
          onCanPlay={() => _setPlayBack()}
          controls={controls}
          onEnded={() => {
            setControls(true)
          }}
          controlsList={
            props.currentUser.jotUserType > 3 ? "nodownload" : ""
          }
          muted={props.pageType === "tagContent" ? false : true}

          onContextMenu={e => {
            e.preventDefault()
            return false
          }}
          autoPlay
        />
        <div>Stats</div> */}
      </div>
    )
  }

  _handleSearchVideos = async () => {
    try {
      this.setState({ loading: true })
      const data = await getVideosByItemsIdsOrUserId(
        this.state.seedVideosSearchText
      )
      this.setState({
        addVideosData: data.data.result.data,
        showImportVideos: true,
        showAddVideosPopup: false,
        loading: false,
      })
    } catch (error) {
      this.setState({ loading: false })
    }
  }
  _getModerationImage = content => {
    if (content.reviewed) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/review.jpg"
    } else if (content.qc_done) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/qcDone.jpg"
    } else if (content.moderation_level) {
      switch (content.moderation_level) {
        case 2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-approved.jpg"

        case 3:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-approved.jpg"

        case -1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-disabled.jpg"

        case 0:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-disabled.jpg"

        case -2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/machine-disabled.jpg"

        case 1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/unmoderated.jpg"

        default:
          return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
      }
    } else {
      return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
    }
  }
  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    this.setState({ formData }, () => {
      console.log(this.state)
    })
  }

  _renderVideoDetailsPopup = () => {
    return (
      <Modal
        width={1000}
        title=""
        open={this.state.showVideoDetailPopup}
        footer={null}
        onCancel={() => {
          this.setState({ showVideoDetailPopup: false })
          Modal.destroyAll()
        }}
        destroyOnClose
      >
        {this.state.selectedVideoDetails && (
          <>
            {" "}
            <div className="">
              <Row style={{ marginBottom: "-15px" }}>
                <Col lg="4">
                  <VideoCard
                    height="600px"
                    details={false}
                    content={this.state.selectedVideoDetails}
                    playVideo={true}
                    autoPlay={true}
                  ></VideoCard>
                </Col>
                <Col lg="8">
                  <Row>
                    <Col lg="6">
                      <div style={{ display: "flex" }}>
                        <img
                          src={this._getModerationImage(
                            this.state.selectedVideoDetails
                          )}
                          className="me-3 rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                        <div style={{ fontSize: "11px", marginLeft: "10px" }}>
                          <b>
                            {
                              this.state.selectedVideoDetails?.user_profile
                                ?.name
                            }
                          </b>
                          <div style={{ textAlign: "initial" }}>
                            {
                              CURATED_TYPE_MAPPING[
                                this.state.selectedVideoDetails?.user_profile
                                  ?.user_type
                              ]
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4">
                      <Chip
                        color="secondary"
                        size="small"
                        label={
                          this.state.selectedVideoDetails.source === "AGENCY"
                            ? "AGENCY"
                            : "NON-AGENCY"
                        }
                      />
                    </Col>
                    <Col lg="2">
                      <CopyToClipboard
                        text={this.state.selectedVideoDetails?.content_uuid}
                        onCopy={e => {
                          toastr.success("Copied sucessfully!")
                        }}
                      >
                        <Tooltip title="Copy Content ID">
                          <i className="bx bx-copy" role="button" />
                        </Tooltip>
                      </CopyToClipboard>
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col lg="6">
                      {" "}
                      <label>Mode</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          showSearch
                          size="large"
                          allowClear={true}
                          // placeholder="Include Languages"
                          // defaultValue={props.filters["includeLang"]}
                          onChange={value => {
                            this._handleFieldChange("mode", value)
                            this.setState({
                              interestData: [],
                              subinterestData: [],
                              formData: {
                                ...this.state.formData,
                                ...{
                                  type: "",
                                  interest: "",
                                  subinterest: "",
                                },
                              },
                            })
                          }}
                          defaultValue={this.state.formData.mode}
                          style={{ width: "100%" }}
                        >
                          <Option value="">---Choose Mode---</Option>
                          <Option value="PUBLIC">Public Mode</Option>
                          <Option value="PRIVATE">Private Mode</Option>
                        </Select>
                      </Form.Item>
                    </Col> */}
                    <Col lg="6">
                      <label>Bucket</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          showSearch
                          size="large"
                          allowClear={true}
                          // placeholder="Include Languages"
                          // defaultValue={props.filters["includeLang"]}
                          onChange={async e => {
                            this._handleFieldChange("type", e)

                            const interestdata = await getInterest({
                              entity_type: "interest",
                              type: e,
                              mode: this.state.formData.mode,
                            })
                            this.setState({
                              interestData: interestdata?.result?.data || [],
                              fieldChange: true,
                              formData: {
                                ...this.state.formData,
                                ...{
                                  interest: "",
                                  subinterest: "",
                                },
                              },
                            })
                          }}
                          value={this.state.formData.type}
                          style={{ width: "100%" }}
                        >
                          <Option value="">---Choose Bucket---</Option>
                          <Option value="CATEGORY">Category</Option>
                          <Option value="TREND">Trend</Option>
                          <Option value="EVENT">Event</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg="6">
                      <label>Mark as Seed</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          showSearch
                          size="large"
                          allowClear={true}
                          // placeholder="Include Languages"
                          // defaultValue={props.filter["includeLang"]}
                          onChange={async e => {
                            this._handleFieldChange("mark_as_seed", e)
                            this.setState({ fieldChange: true })
                          }}
                          style={{ width: "100%" }}
                        >
                          <Option value="">---Choose---</Option>
                          <Option value={true}>True</Option>
                          <Option value={false}>False</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="6">
                      {" "}
                      <label>Interest</label>
                      <Form.Item>
                        <Select
                          onChange={async e => {
                            this._handleFieldChange("interest", e)
                            const subinterestData = await getInterest({
                              entity_type: "interest",
                              id: e,
                            })
                            this.setState({
                              subinterestData:
                                subinterestData?.result?.data[0]
                                  ?.subInterests || [],
                              fieldChange: true,
                              formData: {
                                ...this.state.formData,
                                ...{
                                  subinterest: "",
                                },
                              },
                            })
                          }}
                          size="large"
                          // placeholder="---Choose Interest---"
                          value={this.state.formData.interest}
                          style={{ width: "100%" }}
                        >
                          <Option value="">---Choose Interest---</Option>
                          {this.state.interestData?.map(item => (
                            <Option value={item.id}>{item.name}</Option>
                          ))}

                          {/* <Option value="PRIVATE">Private Mode</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg="6">
                      {" "}
                      <label>Sub-Interest</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          // showSearch
                          // allowClear={true}
                          // placeholder="Include Languages"
                          // defaultValue={props.filters["includeLang"]}
                          onChange={value => {
                            this._handleFieldChange("subinterest", value)
                            this.setState({
                              fieldChange: true,
                            })
                          }}
                          value={this.state.formData.subinterest}
                          style={{ width: "100%" }}
                          size="large"
                        >
                          <Option value="">---Choose subInterest---</Option>
                          {this.state.subinterestData?.map(item => (
                            <Option value={item}>
                              {this.state.subInterestStatusList[item].name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  {this.state.selectedVideoDetails.interests_review_action && (
                    <Row>
                      <Col lg="6">
                        {" "}
                        <label>Status</label>
                        <Form.Item>
                          <Select
                            // mode="multiple"
                            // showSearch
                            // allowClear={true}
                            // placeholder="Include Languages"
                            // defaultValue={props.filters["includeLang"]}
                            onChange={value => {
                              this._handleFieldChange(
                                "interests_review_action",
                                value
                              )
                              this.setState({
                                fieldChange: true,
                              })
                            }}
                            value={this.state.formData.interests_review_action}
                            style={{ width: "100%" }}
                            size="large"
                          >
                            <Option value="ACCEPTED">Accepted</Option>
                            <Option value="REVIEWED">Reviewed</Option>
                            <Option value="REJECTED">Rejected</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg="6">
                        {" "}
                        <label>Reviewed By</label>
                        <Form.Item>
                          <div>
                            {this.state.selectedVideoDetails
                              .taxonomy_task_info &&
                              this.state.selectedVideoDetails.taxonomy_task_info
                                .task_info_v1 &&
                              this.state.selectedVideoDetails.taxonomy_task_info
                                .task_info_v1.length > 0 &&
                              this.state.selectedVideoDetails.taxonomy_task_info.task_info_v1.filter(
                                item =>
                                  item.action == "interest_REVIEWED" ||
                                  item.action == "interest_REJECTED" ||
                                  item.action == "interest_ACCEPTED"
                              )[0].completed_by}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <br />
                  <Row>
                    <Col lg="12">
                      <table id="tbl" cellpading="2px" width="100%">
                        <thead>
                          <th>Metrics</th>
                          <th>Real</th>
                          <th>Gamified</th>
                          <th>VP%</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Views</td>
                            <td>
                              {this.state.selectedVideoDetails?.view_count ||
                                `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails?.fk_view_count ||
                                `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails?.view_count *
                                    100) /
                                  this.state.selectedVideoDetails?.fk_view_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.view_count *
                                      100) /
                                    this.state.selectedVideoDetails
                                      ?.fk_view_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Likes</td>
                            <td>
                              {this.state.selectedVideoDetails?.like_count ||
                                `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails?.fk_like_count ||
                                `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails?.like_count *
                                    100) /
                                  this.state.selectedVideoDetails?.fk_like_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.like_count *
                                      100) /
                                    this.state.selectedVideoDetails
                                      ?.fk_like_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Shares</td>
                            <td>
                              {this.state.selectedVideoDetails?.share_count ||
                                `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.fk_share_count || `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails
                                    ?.share_count *
                                    100) /
                                  this.state.selectedVideoDetails?.fk_like_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.share_count *
                                      100) /
                                    this.state.selectedVideoDetails
                                      ?.fk_like_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Downloads</td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.download_count || `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.fk_download_count || `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails
                                    ?.download_count *
                                    100) /
                                  this.state.selectedVideoDetails?.play_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.download_count *
                                      100) /
                                    this.state.selectedVideoDetails?.play_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Comments</td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.comments_count || `-`}
                            </td>
                            <td>-</td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails
                                    ?.comments_count *
                                    100) /
                                  this.state.selectedVideoDetails?.play_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.comments_count *
                                      100) /
                                    this.state.selectedVideoDetails?.play_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="12">
                      <table id="tbl" cellpading="2px" width="100%">
                        <thead>
                          <th>Parameter</th>
                          <th>Value</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Resolution</td>
                            <td>
                              {this.state.selectedVideoDetails?.pixel_size}
                            </td>
                          </tr>
                          <tr>
                            <td>Duration</td>
                            <td>
                              {new Date(
                                this.state.selectedVideoDetails
                                  ?.video_duration * 1000
                              )
                                .toISOString()
                                .slice(14, 19)}
                            </td>
                          </tr>
                          <tr>
                            <td>Bitrate</td>
                            <td>{this.state.selectedVideoDetails?.bit_rate}</td>
                          </tr>
                          <tr>
                            <td>FPS</td>
                            <td>
                              {this.state.selectedVideoDetails?.fps || `-`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="12" style={{ marginBottom: "5px" }}>
                      <Button
                        onClick={() => {
                          this._handleUpdateVideoSeed()
                        }}
                        disabled={this.state.fieldChange ? false : true}
                        style={
                          this.state.fieldChange
                            ? {
                                width: "100%",
                                color: "white",
                                backgroundColor: "#0ca789",
                                borderColor: "#0ca789",
                              }
                            : {
                                width: "100%",
                                color: "white",
                                backgroundColor: "grey",
                                borderColor: "grey",
                              }
                        }
                        variant="contained"
                        className="mx-1"
                        color="primary"
                        size="small"
                        // startIcon={<MdAdd />}
                      >
                        Update Content
                      </Button>
                    </Col>

                    {/* <Col lg="12">
                      <Button
                        onClick={() => {
                          this._handleRemoveSeed()
                          this.setState({ showVideoDetailPopup: false })
                        }}
                        style={{
                          width: "100%",
                          backgroundColor: "#f24725",
                          borderColor: "#f24725",
                          color: "white",
                        }}
                        variant="contained"
                        className="mx-1"
                        color="primary"
                        size="small"
                        // startIcon={<MdAdd />}
                      >
                        Remove from Seed List
                      </Button>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Modal>
    )
  }

  _handleFilesUpload = ({ fileList }) => {
    // you store them in state, so that you can make a http req with them later
    this.setState({ fileList })
  }

  _handleRemoveSeed = async () => {
    let contentReview = await updateReviewContent({
      content_uuid: this.state.selectedVideoDetails.content_uuid,
      active_interests: [this.state.formData.interest],
    })
  }
  _handleUpdateVideoSeed = async () => {
    if (
      this.state.formData.type == "" ||
      this.state.formData.interest == "" ||
      this.state.formData.subinterest == ""
    ) {
      toastr.error("All fields are mandatory")
      return
    }

    let contentReview = await updateReviewContent({
      content_uuid: this.state.selectedVideoDetails.content_uuid,
      active_interests:
        this.state.formData.interests_review_action == "REJECTED"
          ? []
          : [this.state.formData.interest],
      active_sub_interests:
        this.state.formData.interests_review_action == "REJECTED"
          ? []
          : [this.state.formData.subinterest],
      input_interests: [this.state.formData.interest],
      input_sub_interests: [this.state.formData.subinterest],
      interests_review_status: true,
      interests_review_action: this.state.formData.interests_review_action,
      is_seed_video: this.state.formData.mark_as_seed,
    })
    this.setState({ showVideoDetailPopup: false, fieldChange: false })
  }
  _uploadFilesToS3 = async () => {
    const formData = new FormData()
    const details = {
      interest: this.props.interest.id,
      subInterest: this.props.subInterest,
    }
    formData.append("details", JSON.stringify(details))

    this.state.fileList.forEach((file, index) => {
      formData.append(`file${index + 1}`, file.originFileObj)
    })

    try {
      this.setState({ loading: true })
      const data = await saveFilesToS3(formData)
      this.setState({
        addVideosData: data.data.data,
        showImportVideos: true,
        showAddVideosPopup: false,
        importType: "create",
        loading: false,
      })
    } catch (error) {
      this.setState({ loading: true })
    }

    this.props.closeInterest()
  }

  _renderAddVideosPopup = () => {
    return (
      <Modal
        width={1000}
        title="Add Videos"
        open={this.state.showAddVideosPopup}
        footer={null}
        onCancel={() => {
          this.setState({ showAddVideosPopup: false })
        }}
        destroyOnClose
      >
        {this.state.importType !== "create" && (
          <div className="d-flex mb-2">
            <Input
              // defaultValue={props.filter["audioId"]}
              placeholder={"Search ItemID, UserId"}
              onChange={e => {
                this.setState({
                  seedVideosSearchText: e.target.value,
                })
              }}
              allowClear={true}
              name="audioId"
              style={{ width: "100%" }}
              value={this.state.seedVideosSearchText}
              size="large"
            />
            <Button
              onClick={this._handleSearchVideos}
              variant="contained"
              className="mx-1"
              color="primary"
              // size="small"
              startIcon={<MdSearch />}
            >
              Search
            </Button>
          </div>
        )}

        <Dragger
          multiple
          beforeUpload={() => false}
          onChange={this._handleFilesUpload}
        >
          <div
            style={{ height: "15rem", color: "#1976d2" }}
            className="d-flex flex-column justify-content-center"
          >
            <p className="ant-upload-drag-icon">
              <FaCloudUploadAlt size={60} />
            </p>
            <p>Drag & Drop To Upload From A Computer</p>
          </div>
          {/* <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            banned files.
          </p> */}
        </Dragger>
        <div className="d-flex justify-content-end mt-2">
          <Button
            onClick={this._uploadFilesToS3}
            variant="contained"
            color="primary"
            // size="small"
            startIcon={<MdOutlineFileUpload />}
            disabled={this.state.fileList.length <= 0}
          >
            Upload
          </Button>
        </div>
      </Modal>
    )
  }

  _handleGoBack = () => {
    this.props.handleCloseSeed()
  }

  render() {
    return (
      <Auxiliary loading={this.state.loading}>
        {!this.state.showImportVideos && !this.props.showAddVideos && (
          <>
            <div className="d-flex justify-content-between">
              <h3>
                Add Videos - {this.props.interest.name} -{" "}
                {this.props.subInterestName}
              </h3>
              <div>
                <Button
                  onClick={this._handleGoBack}
                  variant="outlined"
                  className="mx-1"
                  // color="success"
                  // size="small"
                  startIcon={<MdOutlineArrowBackIosNew size={15} />}
                >
                  Back
                </Button>
                {this.state.previoussessionStatus && (
                  <Button
                    onClick={() => {
                      this.setState({
                        showImportVideos: true,
                        importType: "create",
                      })
                    }}
                    variant="outlined"
                    // style={{ backgroundColor: "#0ca789", borderColor: "#0ca789" }}
                    className="mx-1"
                    // color="success"
                    // size="small"
                    // startIcon={<MdAdd />}
                  >
                    Previous Session
                  </Button>
                )}
                <Button
                  onClick={this._handleAddVideos}
                  variant="contained"
                  // style={{ backgroundColor: "#0ca789", borderColor: "#0ca789" }}
                  className="mx-1"
                  color="success"
                  // size="small"
                  startIcon={<MdAdd />}
                >
                  Add Videos
                </Button>
              </div>
            </div>
            <br />
            {this._renderFilters()}
            {this.state?.data && (
              <div className="seed-videos-container" style={{ margin: "10px" }}>
                {this.state?.data?.map(item => this._renderVideo(item))}
              </div>
            )}
          </>
        )}
        <br />

        {this._renderAddVideosPopup()}
        {this._renderVideoDetailsPopup()}
        {this.state.showImportVideos && (
          <ImportVideos
            videos={this.state.addVideosData}
            type={this.state.importType}
            tab="addTabVideo"
            interest={this.props.interest}
            subInterest={this.props.subInterest}
            subInterestName={this.props.subInterestName}
            closeImportVideos={async () => {
              const previoussessionStatus = await getPreviousSessionStatus({
                interest: this.props.interest.id,
                subInterest: this.props.subInterest,
              })

              this.setState({
                showImportVideos: false,
                previoussessionStatus:
                  previoussessionStatus.isPreviousSessionActive,
              })
            }}
            handleAddVideos={this._handleAddVideos}
          />
        )}
      </Auxiliary>
    )
  }
}
