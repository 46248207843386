import { createSlice } from "@reduxjs/toolkit"

const memeSlice = createSlice({
  name: "listings",
  initialState: {
    listings: [],
    total: 0,
    loading: false,
    refetchData: false,
  },
  reducers: {
    getListings(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getListingsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        total: payload.data.total || 1,
        listings: payload.data.data,
      }
    },
    upsertListings(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertListingsSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    refreshListings(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState() {
      return {
        listings: [],
        total: 0,
        loading: false,
        refetchData: false,
      }
    },
  },
})

export const {
  getListings,
  getListingsSuccess,
  upsertListings,
  upsertListingsSuccess,
  resetState,
  refreshListings,
} = memeSlice.actions

export default memeSlice.reducer
