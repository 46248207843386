import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { getAllZones, resetState } from "appRedux/slices/zone"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button as MuiButton, Stack, Switch } from "@mui/material"
import { Card, CardBody, Row, Col, Button } from "reactstrap"
import moment from "moment"
import ReactSelect from "react-select"
import { LANGUAGES } from "constants/uiConstants"
import dayjs from "dayjs"
import {
  deleteTheme,
  getThemeficationList,
  upsertThemification,
} from "clientServices/themificationService"
import { Input, Pagination, Upload } from "antd"
import { Select, DatePicker, Tooltip, Divider, Modal, Form } from "antd"
import { MdEdit, MdDelete, MdDisabledByDefault } from "react-icons/md"
import { AiTwotoneHeart, AiFillEye, AiOutlineStop } from "react-icons/ai"
import { TiTick } from "react-icons/ti"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import ValidatorForm, {
  TextField,
  Switch as VSwitch,
} from "containers/ValidatorForm"
const { Option } = Select

class ThemeficationList extends ErrorBoundComponent {
  state = {
    showInterestForm: false,
    filters: [],
    listData: [],
    errors: {},
    loading: true,
  }

  async componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      window.addEventListener("keydown", this._keyDownHandler)
    }
    const listData = await getThemeficationList()
    this.setState({
      listData,
      loading: false,
    })
  }
  _handledelete = async row => {
    await deleteTheme(row.themeName)
    const listData = await getThemeficationList()
    this.setState({
      listData,
      loading: false,
    })
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }
  showModal = async row => {
    this.setState({
      isModalOpen: true,
      row: row,
    })
  }
  handleOk = async () => {
    let data = new FormData()
    let state = { ...this.state.row }
    delete state.mergeOp
    delete state.intervals
    delete state.expression
    delete state.condition

    data.append(
      "details",
      JSON.stringify({
        ...state,
        ...{ disable: !this.state?.row?.disable },
      })
    )

    let result = await upsertThemification(data)
    // this._handledelete(this.state.row)
    const listData = await getThemeficationList()
    this.setState({
      listData,
      loading: false,
      isModalOpen: false,
    })
  }
  handleCancel = () => {
    this.setState({
      isModalOpen: false,
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        {" "}
        <Modal
          title="Confirmation"
          open={this.state.isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>
            Do you want to {this.state?.row?.disable ? "Enable" : "Disable"}{" "}
            this Theme?
          </p>
        </Modal>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Theme Name</b>
                  </TableCell>

                  <TableCell>
                    <b>Start Time</b>
                  </TableCell>
                  <TableCell>
                    <b>End Time</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  {/* <TableCell>
                    <b>Disable</b>
                  </TableCell> */}
                  <TableCell>
                    <b>Actions</b>
                  </TableCell>
                  {/* <TableCell>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.listData.map(row => (
                  <TableRow
                    style={{ height: "45px" }}
                    key={row.name}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.themeName}</TableCell>

                    <TableCell>
                      {row.startTime
                        ? moment
                            .unix(row.startTime / 1000)
                            .format("DD-MM-YY hh:mm a")
                        : ""}
                    </TableCell>

                    <TableCell>
                      {row.expireTime
                        ? moment
                            .unix(row.expireTime / 1000)
                            .format("DD-MM-YY hh:mm a")
                        : ""}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    {/* <TableCell>
                      {row.disable == true
                        ? "True"
                        : row.disable == false
                        ? "False"
                        : ""}
                     
                    </TableCell> */}
                    <TableCell>
                      <Row>
                        <Col md={2}>
                          {row.status == "DISABLED" ||
                          row.status == "COMPLETED" ? (
                            <></>
                          ) : row.expireTime / 1000 > moment().unix() ? (
                            <Button
                              style={{ marginRight: "5px" }}
                              onClick={async () => {
                                this.props.setCurrentRow(row)
                              }}
                            >
                              <MdEdit />
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col md={2}>
                          {" "}
                          {row.expireTime / 1000 > moment().unix() ? (
                            <Button
                              onClick={async () => {
                                this.showModal(row)
                                //   this._handledelete(row)
                              }}
                            >
                              {row?.disable ? (
                                <TiTick title="Enable" />
                              ) : (
                                <AiOutlineStop title="Disable" />
                              )}
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex justify-content-end pb-3">
            {/* <Pagination
              style={{ marginTop: "1rem" }}
              onChange={this._handlePageChange}
              defaultCurrent={this.state.filters.currentPage}
              total={this.state.total}
              pageSize={this.state.filters.pageSize}
              showSizeChanger={false}
            /> */}
          </div>
        </Paper>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(ThemeficationList)
