import React, { createRef } from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _Get from "lodash/get"
import _CloneDeep from "lodash/cloneDeep"
import _Words from "lodash/words"
import _IsEmpty from "lodash/isEmpty"
import { Input } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Row, Col, Card, CardTitle, CardBody } from "reactstrap"
import { Button } from "@mui/material"
import { getLocationData } from "appRedux/slices/location"
import { getContentByFilter, resetState } from "appRedux/slices/content"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import "./content.styles.css"
import UploadBulkContentForm from "./uploadBulkContentForm"
import CreateContentForm from "./createContentForm"
import ContentDetails from "components/ContentDetails/contentDetails"
import NoDataFound from "components/NoDataFound/noDataFound"
import { MdAdd, MdOutlineUpload } from "react-icons/md"

class ManageContent extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.videoRef = createRef()

    this.state = {
      filters: {
        searchText:
          this.props.queryParams && this.props.queryParams["contentUUID"]
            ? this.props.queryParams["contentUUID"]
            : this.props.match &&
              this.props.match.params &&
              this.props.match.params["contentUUID"]
            ? this.props.match.params["contentUUID"]
            : null,
        searchType:
          this.props.queryParams && this.props.queryParams["searchType"]
            ? this.props.queryParams["searchType"]
            : "content_uuid",
      },
      contentUUID: this.props.queryParams
        ? this.props.queryParams["contentUUID"]
        : this.props.match &&
          this.props.match.params &&
          this.props.match.params["contentUUID"]
        ? this.props.match.params["contentUUID"]
        : "",
      showModal: false,
      action: null,
      playbackRate: "2.0",
    }
  }

  componentDidMount() {
    this.props.dispatch(getLocationData())
    if (
      !this.props.common.error &&
      ((this.props.queryParams &&
        !_.isEmpty(this.props.queryParams["contentUUID"])) ||
        (this.props.match &&
          this.props.match.params &&
          this.props.match.params["contentUUID"]))
    ) {
      this.props.dispatch(
        getContentByFilter({
          searchText:
            this.props.queryParams && this.props.queryParams["contentUUID"]
              ? this.props.queryParams["contentUUID"]
              : this.props.match &&
                this.props.match.params &&
                this.props.match.params["contentUUID"]
              ? this.props.match.params["contentUUID"]
              : null,
          searchType: "content_uuid",
        })
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  componentDidUpdate(prevProps) {
    if (
      this?.props?.queryParams?.contentUUID !==
      prevProps?.queryParams?.contentUUID
    ) {
      this._searchContent(this?.props?.queryParams?.contentUUID)
    }
  }

  _searchContent = value => {
    this.props.history.push(`/content/manage?contentUUID=${value}`)
    this.setState({ contentUUID: value }, () => {
      this.props.dispatch(
        getContentByFilter({
          searchText:
            this.props.queryParams && this.props.queryParams["contentUUID"]
              ? this.props.queryParams["contentUUID"]
              : value,
          searchType: "content_uuid",
        })
      )
    })
  }

  _filters = () => {
    return (
      <Col lg="4">
        <Input
          placeholder="Content UUID"
          onChange={e => this._searchContent(e.target.value)}
          validations={["required"]}
          size="large"
          defaultValue={this.state.contentUUID || ""}
          errors={["This field is required"]}
        />
      </Col>
    )
  }

  _actionButtons = () => {
    return (
      <div
        className="d-flex justify-content-end text-sm-end"
        style={{ paddingBottom: "15px" }}
      >
        <Button
          color="primary"
          variant="contained"
          className="font-16 btn-block btn btn-primary"
          style={{ marginRight: "5px" }}
          onClick={() =>
            this.setState(
              {
                showForm: false,
              },
              () =>
                this.setState({
                  formAction: "upload",
                  showForm: true,
                })
            )
          }
          startIcon={<MdOutlineUpload />}
        >
          Upload
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="font-16 btn-block btn btn-primary"
          onClick={() =>
            this.setState(
              {
                showForm: false,
              },
              () =>
                this.setState({
                  formAction: "create",
                  showForm: true,
                })
            )
          }
          startIcon={<MdAdd />}
        >
          Create
        </Button>
      </div>
    )
  }

  _getContentUpsertForm = () => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_CONTENT")

    return (
      <Card>
        <CardTitle className="p-3 pb-0">{"Upload Content"}</CardTitle>
        <CardBody>
          {this.state.formAction === "create" ? (
            <CreateContentForm
              locationList={this.props.locationList}
              onCancel={() =>
                this.setState({ showForm: false, formAction: null })
              }
              hasEditPermission={hasEditPermission}
            />
          ) : (
            <UploadBulkContentForm
              onCancel={() =>
                this.setState({ showForm: false, formAction: null })
              }
            />
          )}
        </CardBody>
      </Card>
    )
  }

  render() {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_CONTENT")

    const hasPrivateModePermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_CONTENT_PRIVATE_MODE")
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_Get(this.props, "common.error")}
      >
        <div className="d-flex justify-content-between">
          {this._filters()}
          {this._actionButtons()}
        </div>

        <Row>{this.state.showForm && this._getContentUpsertForm()}</Row>
        {this.props.content ? (
          <ContentDetails
            content={this.props.content}
            currentUser={this.props.currentUser}
            locationList={this.props.locationList}
            hasEditPermission={hasEditPermission}
            hasPrivateModePermission={hasPrivateModePermission}
          />
        ) : this.props?.queryParams?.contentUUID ? (
          <NoDataFound message="Content with the provided ID doesn't exist" />
        ) : (
          ""
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _Get(store, "content.loading"),
    common: _Get(store, "common"),
    content:
      _.get(store, "content.content").length == 1
        ? _.get(store, "content.content")[0]
        : null,
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    locationList: _.get(store, "locationReducer.locationList"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageContent)
