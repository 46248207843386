import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import {
  getLanguageData,
  getLanguageDataSuccess,
  getAgencyData,
  getAgencyDataSuccess,
} from "appRedux/slices/uiConstants"
import {
  requestLanguageData,
  requestAgencyData,
} from "appRedux/sagas/requests/uiConstants"

function* handleGetLanguageData(params) {
  try {
    const data = yield call(requestLanguageData, params)
    yield put(getLanguageDataSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAgencyData(params) {
  try {
    const data = yield call(requestAgencyData, params)
    yield put(getAgencyDataSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getLanguageData.type, handleGetLanguageData)
  yield takeLatest(getAgencyData.type, handleGetAgencyData)
}
