import React from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import "../../containers/Zone/style.css"
import dayjs from "dayjs"

const BlockedContentDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        {/* <Row style={{ marginBottom: "10px" }}> */}
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              ACR Meta Created Date
            </h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <DatePicker
                  defaultValue={
                    props.filters["acrMetaCreatedDateFrom"]
                      ? dayjs.unix(
                          props.filters["acrMetaCreatedDateFrom"] / 1000,
                          "YYYY-MM-DD"
                        )
                      : null
                  }
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  placeholder="From Date"
                  onChange={(date, dateString) => {
                    props.handleFilterChange(
                      !_.isEmpty(dateString)
                        ? moment(dateString).format("x")
                        : null,
                      "acrMetaCreatedDateFrom"
                    )
                  }}
                  style={{ width: "100%" }}
                />
                <DatePicker
                  defaultValue={
                    props.filters["acrMetaCreatedDateTo"]
                      ? dayjs.unix(
                          props.filters["acrMetaCreatedDateTo"] / 1000,
                          "YYYY-MM-DD"
                        )
                      : null
                  }
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  placeholder="To Date"
                  onChange={(date, dateString) => {
                    props.handleFilterChange(
                      !_.isEmpty(dateString)
                        ? moment(dateString).format("x")
                        : null,
                      "acrMetaCreatedDateTo"
                    )
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="2" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">ACR Meta Label</h5>
          </Divider>
          <Input.Group compact={true}>
            <Input
              defaultValue={props.filters["acrMetaLabel"]}
              placeholder={"Label Name"}
              onChange={e =>
                props.handleFilterChange(e.target.value.trim(), "acrMetaLabel")
              }
              allowClear={true}
              name="acrMetaLabel"
            />
          </Input.Group>
        </Col>
        <Col lg="2" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Blocked Reason</h5>
          </Divider>
          <Input.Group compact={true}>
            <Input
              defaultValue={props.filters["blockedReason"]}
              placeholder={"Blocked Reason"}
              onChange={e =>
                props.handleFilterChange(e.target.value.trim(), "blockedReason")
              }
              allowClear={true}
              name="blockedReason"
            />
          </Input.Group>
        </Col>
      </Row>
      {/* </Row> */}
    </React.Fragment>
  )
}

export default withRouter(BlockedContentDrawer)
