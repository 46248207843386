import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Select, Button, Divider } from "antd"
import { Modal } from "react-bootstrap"
import ValidatorForm, { TextField } from "../ValidatorForm"
import { Row, Col, CardTitle, Card, CardBody } from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "./style.css"

import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import {
  getMediaData,
  updateMediaData,
  getCategoryList,
  updateCategoryData,
  deleteCategoryData,
} from "../../clientServices/chatBotService"

const { Option } = Select

class ChatBot extends ErrorBoundComponent {
  constructor(props) {
    super(props)

    this.state = {
      showForm: false,
      loading: false,
      mediaList: [],
      deletedMediaList: [],
      category: [],
      media_category:
        this.props.queryParams && this.props.queryParams["media_category"]
          ? this.props.queryParams["media_category"]
          : "images",
      filters: {
        greetings_category:
          this.props.queryParams && this.props.queryParams["greetings_category"]
            ? this.props.queryParams["greetings_category"]
            : "",
        media_category:
          this.props.queryParams && this.props.queryParams["media_category"]
            ? this.props.queryParams["media_category"]
            : "images",
      },
    }
  }

  componentDidMount = async () => {
    let category = await getCategoryList()
    this.setState(
      {
        category: category.data || [],
        filters: {
          ...this.state.filters,
          ...{
            greetings_category:
              category.data && category.data.length > 0
                ? category.data[0].key
                : "",
          },
        },
      },
      async () => {
        await this._getMediaData()
      }
    )
  }

  _getMediaData = async () => {
    if (!this.state?.filters?.greetings_category) {
      toastr.error("Greetings Category is not selected.")
      return
    }

    if (!this.state?.filters?.media_category) {
      toastr.error("Media Category is not selected.")
      return
    }

    this.setState({ loading: true })

    const data = await getMediaData(
      this.state.filters.greetings_category,
      this.state.filters.media_category
    )

    this.props.history.push(
      `/chat-bot/browse?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.setState({
      loading: false,
      mediaList: data,
      showForm: false,
      media_category: this.state.filters.media_category,
    })
  }

  _getFilters = () => {
    return (
      <div className="bg-white d-flex p-2 justify-content-between">
        <div className="d-flex" style={{ width: "90%" }}>
          <div className="d-flex align-items-center" style={{ width: "50%" }}>
            <label className="m-2 font-weight-semibold">
              Greetings Category :{" "}
            </label>
            <Select
              placeholder="Select Greetings Category"
              style={{ minWidth: "60%" }}
              allowClear={true}
              defaultValue={this.state?.filters?.greetings_category || ""}
              onChange={value =>
                this.setState(prevState => ({
                  filters: { ...prevState.filters, greetings_category: value },
                }))
              }
            >
              {this.state.category &&
                this.state.category.map(item => (
                  <Option value={item.key}>{item.value}</Option>
                ))}
              {/* <Option value="">Select Greetings Category</Option>
              <Option value="good_morning">Good Morning</Option>
              <Option value="good_night">Good Night</Option>
              <Option value="shayari">Shayari</Option>
              <Option value="love">Love</Option> */}
            </Select>
          </div>
          <div className="d-flex align-items-center" style={{ width: "50%" }}>
            <label className="m-2 font-weight-semibold">
              Media Category :{" "}
            </label>
            <Select
              placeholder="Select Media Category"
              style={{ minWidth: "60%" }}
              allowClear={true}
              defaultValue={this.state?.filters?.media_category || ""}
              onChange={value =>
                this.setState(prevState => ({
                  filters: { ...prevState.filters, media_category: value },
                }))
              }
            >
              <Option value="">Select Media Category</Option>
              <Option value="images">Images</Option>
              <Option value="videos">Videos</Option>
              <Option value="stickers">Stickers</Option>
            </Select>
          </div>
        </div>
        <Button
          onClick={this._getMediaData}
          role="button"
          // className="badge bg-primary font-size-12 m-1"
          // color="outline-primary"
          className="btn-sm badge bg-primary font-weight-semibold w-xs font-size-12 m-1"
          disabled={
            !(
              this.state.filters.greetings_category &&
              this.state.filters.media_category
            )
          }
        >
          <span>
            <i className="bx bx-check-double font-size-16 align-middle"></i>
          </span>
          <span className="mx-1">Apply</span>
        </Button>
      </div>
    )
  }

  deleteMedia = currentMedia => {
    const mediaList = this.state?.mediaList?.filter(media => {
      if (media.id != currentMedia.id) {
        return media
      }
    })
    if (this.state[`url=${currentMedia.id}`]) {
      this.setState({ mediaList, [`url=${currentMedia.id}`]: null })
    } else if (!currentMedia?.url && !this.state[`url=${currentMedia.id}`]) {
      this.setState({ mediaList })
    } else {
      this.setState(prevState => ({
        mediaList,
        deletedMediaList: [...prevState.deletedMediaList, currentMedia],
      }))
    }
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      [key]: file,
    })
  }

  _getMediaForm = (media, idx) => {
    return (
      <div className="border p-2">
        <Divider className="m-auto">
          <h5 className="mb-0 text-muted">{`Media - ${idx + 1}`}</h5>
        </Divider>
        <Row>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"Media ID"}
              field={`mediaList.${idx}.id`}
              className="creator-form"
              defaultValue={media.id}
              disabled
            />
          </Col>
          <Col
            sm={12}
            md={12}
            lg={12}
            className={`${
              media?.url
                ? ""
                : "d-flex justify-content-center align-items-center"
            }`}
            style={media?.url ? {} : { height: "455px" }}
          >
            {media.url ? (
              <div className="d-flex justify-content-center align-items-center">
                {this.state.media_category === "videos" ? (
                  <video
                    src={media.url}
                    height="455px"
                    width="250px"
                    controls={true}
                    muted={true}
                    controlsList={"nodownload"}
                    autoPlay
                    className="pb-2"
                  />
                ) : (
                  <img
                    src={media.url}
                    height="455px"
                    width="250px"
                    className="pb-2"
                  />
                )}
              </div>
            ) : (
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this._handleFileUpload(`url=${media.id}`, e.target.files[0])
                }}
                style={{ padding: "0.54rem 0.75rem" }}
              />
            )}
          </Col>
          <div
            // sm={12}
            // md={2}
            // lg={2}
            className="d-flex justify-content-between align-items-center border-top"
          >
            <div className="font-size-15 p-2">
              <b>Size:</b> {(media.size / 1024).toFixed(2)} KB
            </div>
            <i
              className="bx bx bx-trash-alt bx-sm text-danger p-2"
              role="button"
              onClick={() => this.deleteMedia(media)}
            />
          </div>
        </Row>
      </div>
    )
  }
  _onCategoryDelete = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    toastr.info("Action in progress...")
    await deleteCategoryData({ key: this.state.deleteCategoryKey })

    this.setState({ showForm: false })
    toastr.success("Action successfull!!")
  }

  _onCategorySubmit = async ({ formData, errors }) => {
    // console.log(formData)
    if (errors) {
      return
    }
    if (formData.key) {
      let a = formData.key
      formData.key = a.toLowerCase()
    }
    toastr.info("Action in progress...")
    await updateCategoryData(formData)

    this.setState({ showForm: false })
    toastr.success("Action successfull!!")
  }
  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    const data =
      formData?.mediaList?.map(media => {
        const obj = {
          ...media,
        }
        const oldMediaData = this.state.mediaList.filter(
          config => config.id === media.id
        )

        if (this.state && !this.state[`url=${media.id}`]) {
          if (oldMediaData && oldMediaData[0] && oldMediaData[0].url) {
            obj.url = oldMediaData[0].url
          }
        }

        return obj
      }) || []

    let finalData = new FormData()

    const filnalData = {
      greetings_category: this.state.filters.greetings_category,
      media_category: this.state.filters.media_category,
      mediaData: data || [],
      deletedMediaData: this.state.deletedMediaList || [],
    }

    finalData.append("data", JSON.stringify(filnalData))

    Object.keys(this.state).forEach(key => {
      if (key.includes("url") && this.state[key]) {
        finalData.append(key, this.state[key])
      }
    })

    toastr.info("Action in progress...")
    this.setState({ loading: true })
    await updateMediaData(finalData)
    this.setState({ loading: false })
    toastr.success("Action successfull!!")
  }
  _onCategoryAddForm = () => {
    this.setState({
      showForm: true,
    })
  }
  oncancel = () => {
    this.setState({ showForm: false })
  }
  _modal = () => {
    return (
      <>
        <Card>
          <CardTitle className="p-3 pb-0">{"Add Category"}</CardTitle>
          <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
            <ValidatorForm
              onSubmit={this._onCategorySubmit}
              layout={"vertical"}
              {...this._formLayout}
            >
              <Row>
                <Col md="4" lg="4" sm="12">
                  <TextField
                    label="Category Key"
                    placeholderLabel="Category Key"
                    field="key"
                    validations={["required"]}
                    allowClear={true}
                    className="creator-form"
                    errors={["This field is required"]}
                  />
                </Col>
                <Col md="4" lg="4" sm="12">
                  <TextField
                    label="Category Value"
                    placeholderLabel="Category Value"
                    field="value"
                    validations={["required"]}
                    allowClear={true}
                    className="creator-form"
                    errors={["This field is required"]}
                  />
                </Col>
                <Col md="3" lg="3" sm="12">
                  <div
                    className="d-flex"
                    style={{
                      marginTop: "32px",
                    }}
                  >
                    <Button
                      htmlType="submit"
                      color="primary"
                      disabled={this.state.loading}
                      className="font-16 btn-block btn btn-primary"
                      onDoubleClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        return
                      }}
                    >
                      {/* <i className="bx bx-upload me-1" /> */}
                      Submit
                    </Button>
                    <Button
                      color="danger"
                      size="default"
                      disabled={this.state.loading}
                      onDoubleClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        return
                      }}
                      onClick={() => this.oncancel()}
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </ValidatorForm>
          </CardBody>
        </Card>
        <Card>
          <CardTitle className="p-3 pb-0">{"Delete Category"}</CardTitle>
          <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
            <ValidatorForm
              onSubmit={this._onCategoryDelete}
              layout={"vertical"}
              {...this._formLayout}
            >
              <Row>
                <Col md="4" lg="4" sm="12">
                  <Select
                    placeholder="Select Greetings Category"
                    style={{ minWidth: "60%" }}
                    allowClear={true}
                    validations={["required"]}
                    errors={["This field is required"]}
                    onChange={value =>
                      this.setState(prevState => ({
                        deleteCategoryKey: value,
                      }))
                    }
                  >
                    {this.state.category &&
                      this.state.category.map(item => (
                        <Option value={item.key}>{item.value}</Option>
                      ))}
                  </Select>
                </Col>

                <Col md="3" lg="3" sm="12">
                  <div className="d-flex">
                    <Button
                      htmlType="submit"
                      color="primary"
                      disabled={this.state.loading}
                      className="font-16 btn-block btn btn-primary"
                      onDoubleClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        return
                      }}
                    >
                      {/* <i className="bx bx-upload me-1" /> */}
                      Submit
                    </Button>
                    <Button
                      color="danger"
                      size="default"
                      disabled={this.state.loading}
                      onDoubleClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        return
                      }}
                      onClick={() => this.oncancel()}
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </ValidatorForm>
          </CardBody>
        </Card>
      </>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        <div>{this._getFilters()}</div>
        <div className="bg-white">
          <div className="m-2 p-2">
            <div className=" d-flex justify-content-between align-items-center">
              <Modal.Title className="mt-2">
                <CardTitle className="font-size-18 text-wrap">
                  Media List
                </CardTitle>
              </Modal.Title>
              <div>
                {!this.state.showForm && (
                  <Button
                    color="outline-primary"
                    className="btn-sm badge bg-primary font-weight-semibold w-xs font-size-12 me-2"
                    onClick={this._onCategoryAddForm}
                  >
                    <i className="fa fa-edit mx-1 my-1" /> Greeting Category
                  </Button>
                )}

                <Button
                  color="outline-primary"
                  className="btn-sm badge bg-primary font-weight-semibold w-xs font-size-12 me-2"
                  disabled={
                    !(
                      this.state.filters.greetings_category &&
                      this.state.filters.media_category
                    )
                  }
                  onClick={() =>
                    // console.log( Uuid())
                    this.setState({
                      mediaList: [
                        ...this.state.mediaList,
                        {
                          id: uuidv4(),
                        },
                      ],
                    })
                  }
                >
                  <i className="fa fa-plus mx-1 my-1" /> Media
                </Button>
                <Button
                  color="outline-primary"
                  className="btn-sm badge bg-primary font-weight-semibold w-xs font-size-12"
                  disabled={
                    !(
                      this.state.filters.greetings_category &&
                      this.state.filters.media_category
                    )
                  }
                  onClick={() => location.reload()}
                >
                  <i className="fa fa-undo mx-1 my-1" /> Refresh
                </Button>
              </div>
            </div>
            <Divider className="mt-2 mb-3" />
            {this.state.showForm && <Col lg="12">{this._modal()}</Col>}
            {!this.state.showForm && (
              <ValidatorForm
                onSubmit={this._onSubmit}
                layout={"vertical"}
                {...this._formLayout}
              >
                {this.state.mediaList && this.state.mediaList.length > 0 ? (
                  <>
                    <div className="chat_bot-card-container">
                      {this.state.mediaList.map((media, idx) => {
                        return this._getMediaForm(media, idx)
                      })}
                    </div>
                    <div
                      className="d-flex justify-content-end border-top pt-3 mt-3"
                      style={{ marginTop: "10px" }}
                    >
                      <Button
                        htmlType="submit"
                        // color="outline-primary"
                        className="btn-sm badge bg-primary font-weight-semibold w-xs font-size-12"
                      >
                        <i class="fa fa-save mx-1 my-1"></i>
                        Submit
                      </Button>
                    </div>
                  </>
                ) : (
                  <div
                    className="d-flex justify-content-center hero-section text-muted"
                    style={{ fontStyle: "italic" }}
                  >
                    No media present.
                  </div>
                )}
              </ValidatorForm>
            )}
          </div>
        </div>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "content.loading"),
  }
}

export default connect(mapStateToProps)(ChatBot)
