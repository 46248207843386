import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import {
  Row,
  Col,
  Button,
  Divider,
  Table,
  Modal,
  Tooltip,
  message,
  Pagination,
} from "antd"
import { Card, CardBody, CardTitle } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  CopyOutlined,
  CloseOutlined,
  RiseOutlined,
} from "@ant-design/icons"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import {
  getVideoMaterialTypes,
  getAllResourceForType,
  deleteVideoMaterialResourceById,
  upsertVideoAsset,
  getAssetTypes,
  updateAssetMappingOrder,
} from "../../appRedux/slices/videoAssets"
import { Select } from "../ValidatorForm"
import VideoAssetUpsertForm from "./videoAssetUpsertForm"
// import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelect"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import "./cameraAsset.style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import { withRouter } from "react-router-dom"
import UpsertTrendingPayload from "../CommonTrending/trendingUpsert"
import { markTrending } from "appRedux/slices/trending"
import { getTrendingData } from "../../clientServices/trendingService"

import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import toastr from "toastr"
class ManageVideoAssets extends ErrorBoundComponent {
  tabId =
    this.props.queryParams && this.props.queryParams["typeId"]
      ? this.props.queryParams["typeId"]
      : ""
  state = {
    showTrendingForm: false,
    effectId: "",
    videoMaterialType:
      this.props.queryParams && !_.isEmpty(this.tabId)
        ? parseInt(this.tabId)
        : 1,
    showUpsertModal: false,
    videoAsset: {},
    mixedAssetSearch: false,
    array: undefined,
    mixedAssets: [],
    showAssetMapping: false,
    assetData: [],
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      pageNumber:
        this.props.queryParams && this.props.queryParams["pageNumber"]
          ? this.props.queryParams["pageNumber"]
          : 1,
      typeId:
        this.props.queryParams && this.props.queryParams["typeId"]
          ? this.props.queryParams["typeId"]
          : "",
      tabType:
        this.props.queryParams && this.props.queryParams["tabType"]
          ? this.props.queryParams["tabType"]
          : "",
      assetTypeId:
        this.props.queryParams && this.props.queryParams["assetTypeId"]
          ? this.props.queryParams["assetTypeId"]
          : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(getVideoMaterialTypes())
      this.props.dispatch(getAllResourceForType(this.state.videoMaterialType))
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.refetchData &&
      !this.props.common.error &&
      prevProps.refetchData !== this.props.refetchData
    ) {
      this._closeUpsertVideoAssetModal()
      this.props.dispatch(getAllResourceForType(this.state.videoMaterialType))
    }
  }

  _onSearch = () => {
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_MANAGE_CATEGORY_ASSETS"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({
      mixedAssetSearch:
        this.props.queryParams["tabType"] === "MIXED" ? true : false,
    })
  }

  _onTrendingClick = async effect => {
    const data = await getTrendingData({
      type:
        effect && effect.assetTypeName && effect.assetTypeName === "FU_GAME"
          ? "game"
          : "effect",
      entityId: effect.id,
    })
    this.setState({
      trendingData: !_.isEmpty(data) ? data : {},
      showTrendingForm: true,
      action: "create",
      effectId: effect.id,
      effectData: effect,
    })
  }

  _onTrendingSubmit = async payload => {
    let response
    response = await this.props.dispatch(
      markTrending({
        data: payload,
        type:
          this.state.effectData &&
          this.state.effectData.assetTypeName &&
          this.state.effectData.assetTypeName === "FU_GAME"
            ? "game"
            : "effect",
        entityId: this.state.effectId ? this.state.effectId : "",
      })
    )
    this.setState({ showTrendingForm: false, effectData: null })
  }

  _trendingForm = () => {
    return (
      <div className="view_container">
        <UpsertTrendingPayload
          type={
            this.props.queryParams && this.props.queryParams.type
              ? this.props.queryParams.type
              : "EFFECT"
          }
          onSubmit={this._onTrendingSubmit}
          data={
            this.state.trendingData && this.state.trendingData.trending_meta_cms
              ? this.state.trendingData.trending_meta_cms
              : ""
          }
          action={
            this.state.trendingData &&
            !_.isEmpty(this.state.trendingData.trending_meta_cms)
              ? "update"
              : "create"
          }
          onCancel={() =>
            this.setState({ showTrendingForm: false, effectId: "" })
          }
        />
      </div>
    )
  }

  // _generateDeeplink = (data) => {
  //   let decodeObj = {
  //     title: data.title,
  //     url: data.cta_data.inline_cta
  //   }
  //   const encoded = encodeURIComponent(JSON.stringify(decodeObj));
  //   return `https://share.myjosh.in/webItem/nhbrowser?webModel=${encoded}`;
  // }

  _columnMeta = () => {
    return [
      {
        title: "Cover Image",
        dataIndex: "coverUrl",
        key: "coverUrl",
        render: text => <img src={text} width={75} height={75} />,
      },
      {
        title: "Name",
        dataIndex: "name" ? "name" : "alias",
        key: "name",
      },
      {
        title: "View Order",
        dataIndex: "viewOrder",
        key: "viewOrder",
        align: "center",
      },
      {
        title: "Asset Type",
        dataIndex: "assetTypeName",
        key: "assetTypeName",
        align: "center",
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        render: (text, rowData) => (
          <span>
            <CopyToClipboard
              text={rowData.id}
              onCopy={() => {
                message.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy Asset UUID">
                <Button style={{ marginRight: "5px" }}>
                  <CopyOutlined />
                </Button>
              </Tooltip>
            </CopyToClipboard>
            <Divider type="vertical" />
            <Button
              style={{ marginRight: "5px" }}
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_MANAGE_CATEGORY_ASSETS"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this.props.dispatch(
                  deleteVideoMaterialResourceById({
                    typeId: this.props.queryParams["assetTypeId"],
                    resourceId: rowData.id,
                  })
                )
              }}
              disabled={
                this.props.queryParams &&
                this.props.queryParams["tabType"] &&
                this.props.queryParams["tabType"] === "MIXED"
              }
            >
              <DeleteOutlined />
            </Button>
            <Divider type="vertical" />
            <Button
              style={{ marginRight: "5px" }}
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_MANAGE_CATEGORY_ASSETS"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this._upsertVideoAsset("edit", rowData)
              }}
              disabled={
                this.props.queryParams &&
                this.props.queryParams["tabType"] &&
                this.props.queryParams["tabType"] === "MIXED"
              }
            >
              <EditOutlined />
            </Button>
            <Divider type="vertical" />
            <Button
              style={{ marginRight: "5px" }}
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_MANAGE_CATEGORY_ASSETS"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this._onTrendingClick(rowData)
              }}
              disabled={
                this.props.queryParams &&
                this.props.queryParams["tabType"] &&
                this.props.queryParams["tabType"] === "MIXED"
              }
            >
              <RiseOutlined />
            </Button>
          </span>
        ),
      },
    ]
  }

  _showSelectedMaterialTypeInfo = () => {
    return (
      <Table
        dataSource={
          this.props.videoMaterialTypeInfo
            ? this.props.videoMaterialTypeInfo
            : []
        }
        columns={this._columnMeta()}
        scroll={{ x: "max-content" }}
        size="small"
      />
    )
  }

  _onVideoAssetUpsertSubmit = formData => {
    const data = {
      formData,
      query: {
        action: this.state.upsertAction,
        typeId: this.props.queryParams["assetTypeId"],
        resourceId: this.state.videoAsset.id,
      },
    }
    this.props.dispatch(upsertVideoAsset(data))
  }

  _closeUpsertVideoAssetModal = () => {
    this.setState({
      showUpsertModal: false,
      upsertAction: "create",
      videoAsset: {},
    })
  }

  _closeAssetOrder = () => {
    this.setState({
      showAssetMapping: false,
      mixedAssetSearch: false,
      mixedAssets: [],
    })
  }

  _upsertVideoAssetModal = () => {
    return (
      <VideoAssetUpsertForm
        onSubmit={this._onVideoAssetUpsertSubmit}
        formAction={this.state.upsertAction}
        videoAsset={this.state.videoAsset}
        loading={this.props.audioLoading}
        common={this.props.common}
        typeId={this.state.videoMaterialType}
        queryParams={this.props.queryParams}
        onCancel={this._closeUpsertVideoAssetModal}
      />
    )
  }

  _modal = () => {
    return (
      <Card>
        <CardTitle className="p-3 pb-0">{"Create/Edit VideoAsset"}</CardTitle>
        <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
          {this._upsertVideoAssetModal()}
        </CardBody>
      </Card>
    )
  }

  _upsertVideoAsset = (action, videoAsset) => {
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_MANAGE_CATEGORY_ASSETS"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({
      showUpsertModal: true,
      upsertAction: action,
      videoAsset,
    })
  }

  _showAssetList = assetList => {
    this.setState({
      mixedAssets: assetList,
    })
  }

  _updateAssetMappings = () => {
    const { mixedAssets } = this.state
    const payload = []
    const tab_Id = this.props.queryParams["typeId"]
    for (let i = 0; i < mixedAssets.length; i++) {
      payload.push({
        assetId: mixedAssets[i]["pk"],
        viewOrder: i,
      })
    }
    this.setState({
      showAssetMapping: false,
      mixedAssetSearch: false,
      mixedAssets: [],
    })
    this.props.dispatch(updateAssetMappingOrder({ tabId: tab_Id, payload }))
  }

  _onAdd = arr => {
    const mappedAssetsList = !_.isEmpty(this.props.videoMaterialTypeInfo)
      ? [...this.props.videoMaterialTypeInfo, ...arr]
      : [...arr]
    const mappedAssets = [
      ...new Map(mappedAssetsList.map(item => [item["pk"], item])).values(),
    ]
    if (mappedAssets && mappedAssets.length > 0) {
      this.setState({
        mixedAssets: mappedAssets,
        showAssetMapping: true,
      })
    }
  }

  _onReorder = () => {
    const { videoMaterialTypeInfo } = this.props
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_MANAGE_CATEGORY_ASSETS"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({
      mixedAssets: videoMaterialTypeInfo,
      showAssetMapping: true,
    })
  }

  _filters = () => {
    const { videoMaterialTypeInfo } = this.props
    return (
      <Row gutter={12}>
        {_.isEmpty(this.props.queryParams) && (
          <Col span={12}>
            <Select
              size="default"
              placeholderLabel={"Video Matarial Types"}
              defaultValue={this.state.videoMaterialType}
              options={this.props.videoMaterialTypes.map(type => ({
                label: type.typeName,
                value: type.typeId,
              }))}
              onChange={value => this.setState({ videoMaterialType: value })}
            />
          </Col>
        )}
        <Col span={24}>
          <div className="d-flex justify-content-end">
            {this.props.queryParams &&
              this.props.queryParams["tabType"] === "MIXED" && (
                <Button
                  type="primary"
                  style={{
                    marginLeft: "5px",
                  }}
                  onClick={this._onSearch}
                >
                  <PlusOutlined />
                  Add More
                </Button>
              )}
            {this.props.queryParams &&
              this.props.queryParams["tabType"] === "MIXED" &&
              videoMaterialTypeInfo &&
              videoMaterialTypeInfo.length > 0 && (
                <Button
                  type="primary"
                  style={{
                    marginLeft: "5px",
                  }}
                  onClick={this._onReorder}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "8px" }}
                    icon={faSyncAlt}
                  />
                  Reorder
                </Button>
              )}
            {this.props.queryParams &&
              this.props.queryParams["tabType"] &&
              this.props.queryParams["tabType"] !== "MIXED" && (
                <Button
                  type="primary"
                  style={{
                    marginLeft: "5px",
                  }}
                  onClick={() => this._upsertVideoAsset("create", {})}
                >
                  Create
                </Button>
              )}
          </div>
        </Col>
      </Row>
    )
  }

  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  _onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const mixedAssets = this._reorder(
      this.state.mixedAssets,
      result.source.index,
      result.destination.index
    )

    this.setState({
      mixedAssets,
    })
  }

  _createOrderList = () => {
    return (
      <Modal
        centered
        onOk={this._updateAssetMappings}
        visible={this.state.showAssetMapping}
        onCancel={this._closeAssetOrder}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this._updateAssetMappings}
            style={{ textAlign: "left" }}
          >
            UPDATE
          </Button>,
        ]}
        title="Order Assets"
        destroyOnClose
        bodyStyle={{ overflowY: "auto", overflowX: "hidden", height: "60vh" }}
      >
        <div style={{ marginBottom: 30, width: "100%" }} bordered={true}>
          <DragDropContext onDragEnd={this._onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.mixedAssets.map((asset, index) => (
                    <Draggable
                      key={`${asset["pk"]}`}
                      draggableId={`${asset["pk"]}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div>
                            <span>
                              <img
                                alt="asset"
                                src={asset.coverUrl}
                                width={75}
                                height={75}
                              />
                            </span>
                            <span style={{ marginLeft: 50 }}>
                              {asset.name ? asset.name : asset.id}
                            </span>
                            <span style={{ float: "right" }}>
                              <Button
                                size="small"
                                id={`${asset["pk"]}`}
                                onClick={target => {
                                  let mixedAssets = _.cloneDeep(
                                    this.state.mixedAssets
                                  )
                                  _.remove(
                                    mixedAssets,
                                    asset => asset.pk == target.currentTarget.id
                                  )
                                  this.setState({ mixedAssets })
                                }}
                              >
                                <CloseOutlined />
                              </Button>
                            </span>
                            <Divider type="horizontal" />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal>
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["pageNumber"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/videoAssets/manage?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
    })
  }

  render() {
    const { videoMaterialTypeInfo } = this.props
    const { pageSize, pageNumber } = this.state.filters
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showTrendingForm && this._filters()}
        {this.state.mixedAssetSearch && (
          <SearchAndSelect
            type="mixed"
            visible={this.state.mixedAssetSearch}
            onClose={() => this.setState({ mixedAssetSearch: false })}
            onAdd={arr => this._onAdd(arr)}
            hidePagination={true}
          />
        )}
        {this.state.showAssetMapping && <div>{this._createOrderList()}</div>}
        {!this.state.showTrendingForm &&
          !this.state.showUpsertModal &&
          videoMaterialTypeInfo &&
          videoMaterialTypeInfo.length > 0 &&
          this._showSelectedMaterialTypeInfo()}
        {!this.state.showTrendingForm &&
          this.state.showUpsertModal &&
          this._modal()}
        {/* {!this.state.showTrendingForm && this.state.showUpsertModal && this._upsertVideoAssetModal()} */}
        {this.state.showTrendingForm && this._trendingForm()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    loading: _.get(store, "videoAssets.loading"),
    videoMaterialTypes: _.get(store, "videoAssets.videoMaterialTypes"),
    videoMaterialTypeInfo: _.get(store, "videoAssets.videoMaterialTypeInfo"),
    refetchData: _.get(store, "videoAssets.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    assetTypes: _.get(store, "videoAssets.assetTypes"),
  }
}

export default connect(mapStateToProps)(ManageVideoAssets)
