import { createSlice } from "@reduxjs/toolkit"

const tinodeSlice = createSlice({
  name: "tinode",
  initialState: {
    currentChatWindow: null,
  },
  reducers: {
    setCurrentChatWindow(state, { payload }) {
      return { ...state, currentChatWindow: payload }
    },
    resetState(state) {
      return {
        currentChatWindow: null,
      }
    },
  },
})

export const { setCurrentChatWindow } = tinodeSlice.actions

export default tinodeSlice.reducer
