import Axios from "axios"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

export const requestGetSourceListByFilters = async ({ payload }) => {
  const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
  const limit = payload["pageSize"]
  payload["offset"] = offset
  payload["limit"] = limit
  try {
    let data = await Axios.post(`/api/source/list`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestCreateSource = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/source/create`, payload)
    toastr.success("Action Successfull!!", "")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateSource = async ({ payload }) => {
  try {
    let data = await Axios.put(`/api/source/update`, payload)
    toastr.success("Action Successfull!!", "")
    return data
  } catch (error) {
    throw error
  }
}

export const requestCreateDesk = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/source/desk/create`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestListTask = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/source/task/list`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestMarkTaskCompleted = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/source/task/mark-completed`, payload)
    return data
  } catch (error) {
    throw error
  }
}
