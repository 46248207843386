import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestGetSourceListByFilters,
  requestCreateSource,
  requestCreateDesk,
  requestListTask,
  requestMarkTaskCompleted,
  requestUpdateSource,
} from "appRedux/sagas/requests/source"
import {
  getSourceListByFiltersSuccess,
  getSourceListByFilters,
  createSource,
  createSourceSuccess,
  createDesk,
  listTask,
  listTaskSuccess,
  markTaskCompleted,
  updateSource,
  updateSourceSuccess,
} from "appRedux/slices/source"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

function* handleGetSourceListByFilters(params) {
  try {
    const data = yield call(requestGetSourceListByFilters, params)
    yield put(getSourceListByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCreateSource(params) {
  try {
    toastr.info("Action in progress...", "")
    const data = yield call(requestCreateSource, params)
    yield put(createSourceSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateSource(params) {
  try {
    toastr.info("Action in progress...", "")
    const data = yield call(requestUpdateSource, params)
    yield put(updateSourceSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCreateDesk(params) {
  try {
    toastr.info("Action in progress...", "")
    const data = yield call(requestCreateDesk, params)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleListTask(params) {
  try {
    const data = yield call(requestListTask, params)
    yield put(listTaskSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleMarkTaskCompleted(params) {
  try {
    const data = yield call(requestMarkTaskCompleted, params)
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getSourceListByFilters.type, handleGetSourceListByFilters)
  yield takeLatest(createSource.type, handleCreateSource)
  yield takeLatest(createDesk.type, handleCreateDesk)
  yield takeLatest(updateSource.type, handleUpdateSource)
  yield takeLatest(listTask.type, handleListTask)
  yield takeLatest(markTaskCompleted.type, handleMarkTaskCompleted)
}
