import { createSlice } from "@reduxjs/toolkit"

const zeroSearchSlice = createSlice({
  name: "zeroSearch",
  initialState: {
    pageList: [],
    total: 0,
    loading: false,
    pageEntitiesByLanguageLocation: [],
    pageEntities: [],
    tabs: [],
    collectionEntitiesGroups: [],
    collectionEntities: [],
    entities: [],
    totalPageEntities: 0,
    refetchData: false,
  },
  reducers: {
    getPageList(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getPageListSuccess(state, { payload }) {
      return {
        ...state,
        pageList: payload && payload.data.data,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        loading: false,
        refetchData: false,
      }
    },

    getEntities(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        entities: [],
      }
    },
    getEntitiesSuccess(state, { payload }) {
      return {
        ...state,
        entities: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    getPageEntities(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        entities: [],
      }
    },
    getPageEntitiesSuccess(state, { payload }) {
      return {
        ...state,
        pageEntities: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        totalPageEntities:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    getCollectionEntitiesGroups(state) {
      return {
        ...state,
        loading: true,
        collectionEntitiesGroups: [],
        refetchData: false,
      }
    },
    getCollectionEntitiesGroupsSuccess(state, { payload }) {
      return {
        ...state,
        collectionEntitiesGroups: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    getCollectionEntities(state) {
      return {
        ...state,
        loading: true,
        collectionEntities: [],
        refetchData: false,
      }
    },
    getCollectionEntitiesSuccess(state, { payload }) {
      return {
        ...state,
        collectionEntities: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    getPageEntitiesByLanguageLocations(state) {
      return {
        ...state,
        loading: true,
        pageEntitiesByLanguageLocation: [],
        tabs: [],
        refetchData: false,
      }
    },
    getPageEntitiesByLanguageLocationsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        pageEntitiesByLanguageLocation: payload.data.data,
        tabs: payload.data.languages,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    getCollectionEntitiesByLanguageLocations(state) {
      return {
        ...state,
        loading: true,
        collectionEntitiesByLanguageLocation: [],
        tabs: [],
        refetchData: false,
      }
    },
    getCollectionEntitiesByLanguageLocationsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        collectionEntitiesByLanguageLocation: payload.data.data,
        tabs: payload.data.languages,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    reorderPageEntities(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    reorderPageEntitiesSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    reorderDefaultPageEntities(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    reorderDefaultPageEntitiesSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    reorderDefaultCollectionEntities(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    reorderDefaultCollectionEntitiesSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    reorderCollectionEntities(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    reorderCollectionEntitiesSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    removePageEntityByLocation(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    removePageEntityByLocationSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    removeCollectionEntityByLocation(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    removeCollectionEntityByLocationSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    removePageEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    removePageEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    removeGame(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    removeGameSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertPageEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertPageEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertPageTheme(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertPageThemeSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertPage(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertPageSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertTargetPageEntities(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertTargetPageEntitiesSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertPageEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertCollection(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertCollectionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertWebGameSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertWebGame(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    updateCollectionEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    updateCollectionEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    deleteCollectionEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    deleteCollectionEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertTargetCollectionEntities(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertTargetCollectionEntitiesSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertCollectionEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertCollectionEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    removePageTheme(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    removePageThemeSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState(state) {
      return {
        ...state,
        pageList: [],
        total: 0,
        loading: false,
        refetchData: false,
        pageEntitiesByLanguageLocation: [],
        pageEntities: [],
        tabs: [],
        collectionEntitiesGroups: [],
        collectionEntities: [],
        totalPageEntities: 0,
      }
    },
  },
})

export const {
  getPageList,
  getPageListSuccess,
  getPageEntities,
  getPageEntitiesSuccess,
  getEntities,
  getEntitiesSuccess,
  getPageEntitiesByLanguageLocations,
  getPageEntitiesByLanguageLocationsSuccess,
  getCollectionEntitiesGroups,
  getCollectionEntitiesGroupsSuccess,
  getCollectionEntities,
  getCollectionEntitiesSuccess,
  getCollectionEntitiesByLanguageLocations,
  getCollectionEntitiesByLanguageLocationsSuccess,
  reorderPageEntities,
  reorderPageEntitiesSuccess,
  reorderDefaultPageEntities,
  reorderDefaultPageEntitiesSuccess,
  reorderDefaultCollectionEntities,
  reorderDefaultCollectionEntitiesSuccess,
  reorderCollectionEntities,
  reorderCollectionEntitiesSuccess,
  removePageEntityByLocation,
  removePageEntityByLocationSuccess,
  removePageEntity,
  removePageEntitySuccess,
  removeCollectionEntityByLocation,
  removeCollectionEntityByLocationSuccess,
  upsertPageEntity,
  upsertPageEntitySuccess,
  upsertTargetPageEntities,
  upsertTargetPageEntitiesSuccess,
  updateCollectionEntity,
  updateCollectionEntitySuccess,
  deleteCollectionEntity,
  deleteCollectionEntitySuccess,
  upsertTargetCollectionEntities,
  upsertTargetCollectionEntitiesSuccess,
  upsertCollection,
  upsertCollectionSuccess,
  upsertEntity,
  upsertEntitySuccess,
  upsertCollectionEntity,
  upsertCollectionEntitySuccess,
  removePageTheme,
  removePageThemeSuccess,
  upsertPage,
  upsertPageSuccess,
  upsertPageTheme,
  upsertPageThemeSuccess,
  resetState,
  upsertWebGameSuccess,
  upsertWebGame,
  removeGame,
  removeGameSuccess,
} = zeroSearchSlice.actions

export default zeroSearchSlice.reducer
