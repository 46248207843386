import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { Row, Col, Divider, Table, Modal, Tooltip, Button, message } from "antd"
import { Button as ReactButton } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { CopyOutlined, CloseOutlined } from "@ant-design/icons"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import {
  getSubTabsForTab,
  updateStickerSubTabMapping,
  updateStickersMapping,
  getStickersForSubTabsTab,
} from "../../appRedux/slices/stickers"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import "./stickers.style.css"
import {
  getStickersForTabSubTab,
  getStickersForNextPage,
} from "../../clientServices/stickerService"
import InfiniteScroll from "react-infinite-scroll-component"
import toastr from "toastr"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"

class ManageStickersMapping extends ErrorBoundComponent {
  tabId =
    this.props.queryParams && this.props.queryParams["typeId"]
      ? this.props.queryParams["typeId"]
      : ""
  selectedFilter =
    this.props.queryParams && this.props.queryParams["selectedFilter"]
      ? this.props.queryParams["selectedFilter"]
      : ""
  state = {
    videoMaterialType:
      this.props.queryParams && !_.isEmpty(this.tabId)
        ? parseInt(this.tabId)
        : 1,
    selectedFilter:
      this.props.queryParams &&
      !_.isEmpty(this.selectedFilter) &&
      this.selectedFilter,
    tabId:
      this.props.queryParams && !_.isEmpty(this.tabId) && parseInt(this.tabId),
    showUpsertModal: false,
    videoAsset: {},
    mixedAssetSearch: false,
    isAddStickers: false,
    array: undefined,
    mixedAssets: [],
    stickersList: [],
    showAssetMapping: false,
    assetData: [],
    subTabId: "",
    showStickers: false,
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      pageNumber:
        this.props.queryParams && this.props.queryParams["pageNumber"]
          ? this.props.queryParams["pageNumber"]
          : 1,
      typeId:
        this.props.queryParams && this.props.queryParams["typeId"]
          ? this.props.queryParams["typeId"]
          : "",
      tabType:
        this.props.queryParams && this.props.queryParams["tabType"]
          ? this.props.queryParams["tabType"]
          : "",
      assetTypeId:
        this.props.queryParams && this.props.queryParams["assetTypeId"]
          ? this.props.queryParams["assetTypeId"]
          : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getSubTabsForTab({
          selectedFilter: this.state.selectedFilter,
          tabId: this.state.tabId,
        })
      )
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.refetchData &&
      !this.props.common.error &&
      prevProps.refetchData !== this.props.refetchData
    ) {
      this._closeUpsertVideoAssetModal()
      this.props.dispatch(
        getSubTabsForTab({
          selectedFilter: this.state.selectedFilter,
          tabId: this.state.tabId,
        })
      )
    }

    // if(prevProps !== this.props) {
    //   this.setState({
    //     stickersList: !_.isEmpty(this.props.stickersForsubTabsTab) ? this.props.stickersForsubTabsTab : []
    //   })
    // }
  }

  _onSearch = () => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_ASSETS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.setState({
      mixedAssetSearch: true,
    })
  }

  _onAddStickers = async subtabId => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_ASSETS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }

    const data = await getStickersForTabSubTab({
      selectedFilter: this.state.selectedFilter,
      tabId: this.state.tabId,
      subTabId: subtabId,
    })
    this.setState({
      isAddStickers: true,
      subTabId: subtabId,
      // showStickers: true,
      stickersList: data.rows,
      //nextPageUrl: data.next_page_url || "",
      total: data.total,
    })
    // this.props.dispatch(
    //   getStickersForSubTabsTab({
    //     selectedFilter: this.state.selectedFilter,
    //     tabId: this.state.tabId,
    //     subTabId: subtabId,
    //   })
    // )
    // this.setState({
    //   isAddStickers: true,
    //   subTabId: subtabId,
    // })
  }

  _columnMeta = () => {
    return [
      {
        title: "Name",
        dataIndex: "displayName",
        key: "displayName",
      },
      {
        title: "Active",
        dataIndex: "isActive",
        key: "isActive",
        render: text => (text ? "true" : "false"),
        align: "center",
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        render: (text, rowData) => (
          <span>
            <CopyToClipboard
              text={rowData.subTabId}
              onCopy={() => {
                message.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy Subtab ID">
                <Button style={{ marginRight: "5px" }}>
                  <CopyOutlined />
                </Button>
              </Tooltip>
            </CopyToClipboard>
            <Divider type="vertical" />
            <ReactButton
              color="warning"
              style={{ marginLeft: "5px" }}
              onClick={() => this._onAddStickers(rowData.subTabId)}
              disabled={
                this.props.queryParams &&
                this.props.queryParams["tabType"] &&
                this.props.queryParams["tabType"] === "MIXED"
              }
            >
              Add Stickers
            </ReactButton>
            <Divider type="vertical" />
            {this.props.queryParams && (
              <ReactButton
                color="primary"
                style={{
                  marginLeft: "5px",
                }}
                onClick={() => this._onReorderStickers(rowData.subTabId)}
              >
                Reorder
              </ReactButton>
            )}
          </span>
        ),
      },
    ]
  }

  _showSelectedMaterialTypeInfo = () => {
    return (
      <Table
        dataSource={this.props.subTabsForTab ? this.props.subTabsForTab : []}
        columns={this._columnMeta()}
        scroll={{ x: "max-content" }}
        size="small"
      />
    )
  }

  _onVideoAssetUpsertSubmit = formData => {
    this.props.upsertVideoAsset(formData, {
      action: this.state.upsertAction,
      typeId: this.props.queryParams["assetTypeId"],
      resourceId: this.state.videoAsset.id,
    })
  }

  _closeUpsertVideoAssetModal = () => {
    this.setState({
      showUpsertModal: false,
      upsertAction: "create",
      videoAsset: {},
    })
  }

  _closeAssetOrder = () => {
    this.setState({
      showAssetMapping: false,
      showStickers: false,
      mixedAssetSearch: false,
      isAddStickers: false,
      mixedAssets: [],
      stickersList: [],
    })
  }

  _upsertVideoAssetModal = () => {
    return (
      <Modal
        centered
        visible={this.state.showUpsertModal}
        onCancel={this._closeUpsertVideoAssetModal}
        footer={null}
        title="Create/Edit VideoAsset"
        destroyOnClose
        bodyStyle={{ overflowY: "auto", overflowX: "hidden", height: "80vh" }}
      ></Modal>
    )
  }

  _upsertVideoAsset = (action, videoAsset) => {
    this.setState({
      showUpsertModal: true,
      upsertAction: action,
      videoAsset,
    })
  }

  _showAssetList = assetList => {
    this.setState({
      mixedAssets: assetList,
    })
  }

  _updateAssetMappings = () => {
    const { mixedAssets } = this.state
    const payload = []
    const tabId = this.props.queryParams["typeId"]
    for (let i = 0; i < mixedAssets.length; i++) {
      payload.push({
        subTabId: mixedAssets[i]["subTabId"],
        viewOrder: i,
      })
    }
    this.setState({
      showAssetMapping: false,
      mixedAssetSearch: false,
      mixedAssets: [],
    })
    this.props.dispatch(
      updateStickerSubTabMapping({
        tabId: tabId,
        selectedFilter: this.props.queryParams["selectedFilter"],
        payload,
      })
    )
    // this.props.updateAssetMappingOrder({ tabId: tabId, payload });
    // this.props.getAllResourceForType(this.state.videoMaterialType);
  }

  _updateStickerMappings = async () => {
    // if (
    //   this.state.stickersList.length < this.state.total &&
    //   !_.isEmpty(this.state.nextPageUrl)
    // ) {
    //   const data = await getStickersForNextPage(this.state.nextPageUrl)
    //   this.setState({
    //     showStickers: true,
    //     stickersList: [...this.state.stickersList, ...data.rows],
    //     nextPageUrl: data.next_page_url || "",
    //   })
    // }
    //  while(!_.isEmpty(this.state.nextPageUrl)) {
    //     const data = await getStickersForNextPage(this.state.nextPageUrl)
    //     this.setState({
    //       showStickers: true,
    //       stickersList: [...this.state.stickersList, ...data.rows],
    //       nextPageUrl: data.next_page_url || "",
    //     })
    //   }
    const { stickersList, subTabId } = this.state
    const payload = []
    const tabId = this.props.queryParams["typeId"]
    for (let i = 0; i < stickersList.length; i++) {
      payload.push({
        stickerId: stickersList[i]["stickerId"],
        viewOrder: i,
      })
    }
    this.setState({
      showStickers: false,
      mixedAssetSearch: false,
      stickersList: [],
    })
    this.props.dispatch(
      updateStickersMapping({
        tabId: tabId,
        subTabId: subTabId,
        selectedFilter: this.props.queryParams["selectedFilter"],
        payload,
      })
    )
  }

  _onDrangEnd = results => {
    const { destination, source } = results
    // if (!destination) {
    //   return;
    // }
    const assetDataCpy = this.state.assetData
    const mixedAssetsCpy = []
    const draggedAssetProp = results.draggableId.split("_")
    const draggedAsset = draggedAssetProp[0]
    // const audioOrder = Array.from(this.state.audioOrder);
    let srcIdx = source.index
    let desIdx = destination.index
    // let desIdx = 4;
    if (srcIdx > desIdx) {
      let temp = assetDataCpy[srcIdx]
      for (let i = srcIdx - 1; i >= desIdx; i--) {
        assetDataCpy[i + 1] = assetDataCpy[i]
      }
      assetDataCpy[desIdx] = temp
    } else {
      let temp = assetDataCpy[srcIdx]
      for (let i = srcIdx; i < desIdx; i++) {
        assetDataCpy[i] = assetDataCpy[i + 1]
      }
      assetDataCpy[desIdx] = temp
    }
    for (let data of assetDataCpy) {
      for (let mixedObj of this.state.mixedAssets) {
        if (mixedObj["subTabId"] === data) {
          mixedAssetsCpy.push(mixedObj)
          break
        }
      }
    }
    this.setState({
      mixedAssets: mixedAssetsCpy,
    })
  }

  _onAdd = arr => {
    const mappedAssetsList = !_.isEmpty(this.props.subTabsForTab)
      ? [...this.props.subTabsForTab, ...arr]
      : [...arr]
    const mappedAssets = [
      ...new Map(
        mappedAssetsList.map(item => [item["subTabId"], item])
      ).values(),
    ]
    if (mappedAssets && mappedAssets.length > 0) {
      this.setState({
        mixedAssets: mappedAssets,
        showAssetMapping: true,
      })
    }
  }

  _onStickersAdd = arr => {
    // const mappedAssetsList = !_.isEmpty(this.props.stickersForsubTabsTab)
    //   ? [...this.props.stickersForsubTabsTab, ...arr]
    //   : [...arr];

    const mappedAssetsList = !_.isEmpty(this.state.stickersList)
      ? [...this.state.stickersList, ...arr]
      : [...arr]
    const mappedAssets = [
      ...new Map(
        mappedAssetsList.map(item => [item["stickerId"], item])
      ).values(),
    ]
    if (mappedAssets && mappedAssets.length > 0) {
      this.setState({
        stickersList: mappedAssets,
        showStickers: true,
      })
    }
  }

  _onReorder = () => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_ASSETS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    const { subTabsForTab } = this.props
    this.setState({
      mixedAssets: subTabsForTab,
      showAssetMapping: true,
    })
  }

  _onReorderStickers = async subTabId => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_ASSETS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    const data = await getStickersForTabSubTab({
      selectedFilter: this.state.selectedFilter,
      tabId: this.state.tabId,
      subTabId: subTabId,
    })
    this.setState({
      subTabId,
      showStickers: true,
      stickersList: data.rows,
      //nextPageUrl: data.next_page_url || "",
      total: data.total,
    })
  }

  _filters = () => {
    const { subTabsForTab } = this.props
    return (
      <Row gutter={12}>
        <Col span={24}>
          <div className="d-flex justify-content-end">
            {this.props.queryParams && (
              <ReactButton
                color="primary"
                style={{
                  marginLeft: "5px",
                }}
                onClick={this._onSearch}
              >
                Add More
              </ReactButton>
            )}
            {this.props.queryParams &&
              subTabsForTab &&
              subTabsForTab.length > 0 && (
                <ReactButton
                  color="primary"
                  style={{
                    marginLeft: "5px",
                  }}
                  onClick={this._onReorder}
                >
                  Reorder
                </ReactButton>
              )}
          </div>
        </Col>
      </Row>
    )
  }

  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  _onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const mixedAssets = this._reorder(
      this.state.mixedAssets,
      result.source.index,
      result.destination.index
    )

    this.setState({
      mixedAssets,
    })
  }

  _onDragEndStickers = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const stickersList = this._reorder(
      this.state.stickersList,
      result.source.index,
      result.destination.index
    )

    this.setState({
      stickersList,
    })
  }

  _createOrderList = () => {
    return (
      <Modal
        centered
        onOk={this._updateAssetMappings}
        visible={this.state.showAssetMapping}
        onCancel={this._closeAssetOrder}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this._updateAssetMappings}
            style={{ textAlign: "left" }}
          >
            UPDATE
          </Button>,
        ]}
        title="Order Assets"
        destroyOnClose
        bodyStyle={{ overflowY: "auto", overflowX: "hidden", height: "60vh" }}
      >
        <div style={{ marginBottom: 30, width: "100%" }} bordered={true}>
          <DragDropContext onDragEnd={this._onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.mixedAssets.map((asset, index) => (
                    <Draggable
                      key={`${asset["subTabId"]}`}
                      draggableId={`${asset["subTabId"]}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div>
                            <span style={{ marginLeft: 50 }}>
                              {asset.displayName
                                ? asset.displayName
                                : asset.subTabId}
                            </span>
                            <span style={{ float: "right" }}>
                              <Button
                                size="small"
                                id={`${asset["subTabId"]}`}
                                onClick={target => {
                                  let mixedAssets = _.cloneDeep(
                                    this.state.mixedAssets
                                  )
                                  _.remove(
                                    mixedAssets,
                                    asset =>
                                      asset.subTabId == target.currentTarget.id
                                  )
                                  this.setState({ mixedAssets })
                                }}
                              >
                                <CloseOutlined />
                              </Button>
                            </span>
                            <Divider type="horizontal" />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal>
    )
  }

  // scrollHandler = async () => {
  //    while(!_.isEmpty(this.state.nextPageUrl)) {
  //     const data = await getStickersForNextPage(this.state.nextPageUrl)
  //     this.setState({
  //       showStickers: true,
  //       stickersList: [...this.state.stickersList, ...data.rows],
  //       nextPageUrl: data.next_page_url || "",
  //     })
  //   }
  // }

  _createStickerList = () => {
    const { stickersList, total } = this.state
    return (
      <Modal
        centered
        onOk={this._updateStickerMappings}
        visible={this.state.showStickers}
        onCancel={this._closeAssetOrder}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this._updateStickerMappings}
            style={{ textAlign: "left" }}
          >
            UPDATE
          </Button>,
        ]}
        title="Order Stickers"
        destroyOnClose
        bodyStyle={{ overflowY: "auto", overflowX: "hidden", height: "80vh" }}
      >
        <div style={{ marginBottom: 30, width: "100%" }} bordered={true}>
          {/* <InfiniteScroll
            dataLength={(stickersList && stickersList.length) || 0} //This is important field to render the next data
            next={this.scrollHandler}
            hasMore={
              stickersList.length < total && !_.isEmpty(this.state.nextPageUrl)
            }
            className="zone_infinite_scroll_list_view"
            height="80vh"
            style={{ overflowX: "hidden" }}
          > */}
          <DragDropContext onDragEnd={this._onDragEndStickers}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {stickersList.map((asset, index) => (
                    <Draggable
                      key={`${asset["stickerId"]}`}
                      draggableId={`${asset["stickerId"]}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div>
                            <span>
                              <img
                                alt="asset"
                                src={asset.thumbnailUrl}
                                width={75}
                                height={75}
                              />
                            </span>
                            <span style={{ marginLeft: 50, marginRight: 50 }}>
                              {asset.stickerName
                                ? asset.stickerName
                                : asset.stickerId}
                            </span>
                            {/* <span><img alt="asset" src={asset.stickerUrl} width={75} height={75} /></span> */}
                            <span style={{ float: "right" }}>
                              <Button
                                size="small"
                                id={`${asset["stickerId"]}`}
                                onClick={target => {
                                  let stickersList = _.cloneDeep(
                                    this.state.stickersList
                                  )
                                  _.remove(
                                    stickersList,
                                    asset =>
                                      asset.stickerId == target.currentTarget.id
                                  )
                                  this.setState({ stickersList })
                                }}
                              >
                                <CloseOutlined />
                              </Button>
                            </span>
                            <Divider type="horizontal" />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {/* </div></InfiniteScroll> */}
        </div>
      </Modal>
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["pageNumber"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/stickers/mapping?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
    })
  }

  render() {
    const { subTabsForTab, stickersForsubTabsTab } = this.props
    const { pageSize, pageNumber } = this.state.filters
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        {this.state.mixedAssetSearch && (
          <SearchAndSelect
            type="STICKERSUBTYPE"
            visible={this.state.mixedAssetSearch}
            onClose={() => this.setState({ mixedAssetSearch: false })}
            onAdd={arr => this._onAdd(arr)}
            hidePagination={true}
            queryParams={this.props.queryParams}
          />
        )}
        {this.state.isAddStickers && (
          <SearchAndSelect
            type="STICKERS"
            visible={this.state.isAddStickers}
            onClose={() => this.setState({ isAddStickers: false })}
            onAdd={arr => this._onStickersAdd(arr)}
            hidePagination={false}
            queryParams={this.props.queryParams}
          />
        )}
        {this.state.showAssetMapping && <div>{this._createOrderList()}</div>}
        {this.state.showStickers && <div>{this._createStickerList()}</div>}
        {subTabsForTab &&
          subTabsForTab.length > 0 &&
          this._showSelectedMaterialTypeInfo()}
        {this.state.showUpsertModal && this._upsertVideoAssetModal()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    loading: _.get(store, "stickers.loading"),
    stickersForsubTabsTab: _.get(store, "stickers.stickersForsubTabsTab"),
    refetchData: _.get(store, "stickers.refetchData"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    assetTypes: _.get(store, "videoAssets.assetTypes"),
    subTabsForTab: _.get(store, "stickers.subTabsForTab"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageStickersMapping)
