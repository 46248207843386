import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import QueryString from "query-string"
import {
  getPageList,
  resetState,
  removePageTheme,
  upsertPageEntity,
  getPageEntities,
  upsertPage,
  upsertPageTheme,
} from "appRedux/slices/zeroSearch"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getLocationData } from "../../appRedux/slices/location"
import Auxiliary from "../../util/Auxiliary"
import { Select, Pagination } from "antd"
import PageCard from "components/Discovery/pageCard"
import Chips from "components/CommonFilter/chips"
import "./styles.css"
import EditPageForm from "./editPageForm"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import { getLanguageData } from "../../appRedux/slices/uiConstants"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import DiscoveryPageForm from "../Discovery/editPageForm"
import toastr from "toastr"
import { Button } from "reactstrap"

const { Option } = Select
class ManageJoshPages extends ErrorBoundComponent {
  state = {
    previewModal: false,
    currentPageId: null,

    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      pageId:
        this.props.queryParams && this.props.queryParams["pageId"]
          ? this.props.queryParams["pageId"]
          : "",
      title:
        this.props.queryParams && this.props.queryParams["title"]
          ? this.props.queryParams["title"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : "",
      pageType:
        this.props.queryParams && this.props.queryParams["pageType"]
          ? this.props.queryParams["pageType"]
          : "",
    },
    showFilters: false,
    showAdvancedFilters: false,
    showSeachAndSelectModal: false,
    showEditForm: false,
    total: 0,
    hasEditPermission:
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_DISCOVERY_PAGE"),
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(getLocationData())
      this.props.dispatch(getLanguageData())
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getPageList(_.deepClean(filters)))
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getPageList(_.deepClean(filters)))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onPageChange = (value, previewContent) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    newFilters["previewContent"] = previewContent ? true : false
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/manage/pages?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        ),
          this.props.dispatch(getPageList(_.deepClean(newFilters)))
      }
    )
  }

  // _openPreviewPageModal = (id, title) => {
  //   this.setState({
  //     previewModal: true,
  //     currentPageId: id,
  //     currentPageTitle: title,
  //   })
  // }

  // _handleFilterChange = (value, type) => {
  //   const newFilters = _.cloneDeep(this.state.filters)
  //   newFilters[type] = value

  //   if (type === "searchType") {
  //     newFilters["searchText"] = undefined
  //   }

  //   this.setState({
  //     filters: newFilters,
  //   })
  // }

  // _searchPage = () => {
  //   let { filters } = this.state
  //   if (filters && filters.pageSize) {
  //     delete filters.currentPage
  //   }
  //   this.props.history.push(
  //     `/manage/pages?${QueryString.stringify(_.deepClean(this.state.filters))}`
  //   )
  // }

  // _renderPagePreviewModal = () => {
  //   return (
  //     <Modal
  //       centered
  //       visible={this.state.previewModal}
  //       footer={null}
  //       width="50vw"
  //       title={
  //         <div style={{ fontSize: "16px", fontWeight: "bold" }}>
  //           {this.state.currentPageTitle}
  //         </div>
  //       }
  //       bodyStyle={{ padding: "1rem", overflowY: "scroll", height: "90vh" }}
  //       onCancel={() => this.setState({ previewModal: false })}
  //     >
  //       <PagePreview
  //         pageId={this.state.currentPageId}
  //         title={this.state.currentPageTitle}
  //       />
  //     </Modal>
  //   )
  // }

  _upsertPage = async (data, type) => {
    const response = await this.props.dispatch(
      upsertPage({ data, action: this.state.action })
    )
    this.setState({ showEditForm: false })
  }

  _upsertPageTheme = async (data, type) => {
    this.setState({ showEditForm: false })

    const response = await this.props.dispatch(
      upsertPageTheme({ data, action: this.state.action })
    )
  }

  _onSearch = () => {
    let filters = _.cloneDeep(this.state.filters)
    _.map(this.state.filters, (filter, key) => {
      if (!filter) {
        _.unset(filters, key)
      }
    })
    this.props.history.push(
      `/manage/pages?${QueryString.stringify(_.deepClean(this.state.filters))}`
    ),
      this.props.dispatch(getPageList(_.deepClean(this.state.filters)))
  }

  _closeFilters = () => {
    this._onSearch()
    this.setState({ showFilters: false, showAdvancedFilters: false })
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: hashtagFilters[filter].defaultValue,
      showFilters: false,
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/manage/pages?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        ),
          this.props.dispatch(getPageList(_.deepClean(newFilters)))
      }
    )
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({ filters: newFilters })
  }

  _onEditClick = (action, data) => {
    switch (action) {
      case "update":
        this.setState({ showEditForm: true, action, selectedPage: data })
        break
      case "add":
        this.props.dispatch(
          getPageEntities({
            pageEntityId: data.page_uuid,
            filters: {
              pageSize: 1000,
              currentPage: 1,
            },
          })
        )
        this.setState({
          showSeachAndSelectModal: true,
          selectedPage: data,
        })
    }
  }

  _onAdd = data => {
    if (!this.state.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    const [collection] = Object.values(data)
    const { target, ...collectionData } = collection
    this.setState({
      selectedPage: {
        ...collectionData,
        page_uuid: this.state.selectedPage.page_uuid,
        view_order: this.props.totalPageEntities,
      },
      showAddForm: true,
      action: "edit",
    })
  }

  onDeleteThemeClick = async theme => {
    const response = await this.props.dispatch(
      removePageTheme({
        pageUuid: this.state.selectedPage.page_uuid,
        themeType: theme.theme_type,
      })
    )
    this.setState({ showEditForm: false })
  }

  _addPageEntity = async (data, action, collectionId) => {
    const entities =
      this.props.pageEntities &&
      this.props.pageEntities.map(entity => entity.collection_uuid)
    if (entities.includes(collectionId)) {
      toastr.error(`Page entity ${collectionId} already exists!!`)
      return
    }
    const response = await this.props.dispatch(
      upsertPageEntity({ data, action: "create", entities })
    )
    this.setState({ showAddForm: false, showSeachAndSelectModal: false })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showEditForm &&
          !this.state.showSeachAndSelectModal &&
          !this.state.showAddForm && (
            <React.Fragment>
              <Chips
                type="discoveryPage"
                showFilters={this.state.showFilters}
                showAdvancedFilters={this.state.showAdvancedFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                toggleFilters={this._toggleFilters}
                toggleAdvancedFilters={this._toggleAdvancedFilters}
              />
              <div className="discovery-card-container">
                {this.props.pageList &&
                  this.props.pageList.length > 0 &&
                  this.props.pageList.map((page, i) => {
                    return (
                      <PageCard
                        data={page}
                        key={i + page.page_uuid}
                        onEditClick={this._onEditClick}
                        history={this.props.history}
                        hasEditPermission={this.state.hasEditPermission}
                      />
                    )
                  })}
              </div>
              {this.props.pageList && this.props.pageList.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Pagination
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    onChange={this._onPageChange}
                    defaultCurrent={this.state.filters.currentPage}
                    total={this.props.total < 10000 ? this.props.total : 10000}
                    pageSize={this.state.filters.pageSize}
                    showSizeChanger={false}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        {this.state.showEditForm && (
          <EditPageForm
            formAction={this.state.action}
            onCancel={() => this.setState({ showEditForm: false })}
            data={this.state.selectedPage}
            title={"Edit Page"}
            onDeleteThemeClick={this.onDeleteThemeClick}
            onThemeUpsert={this._upsertPageTheme}
            onSubmit={this._upsertPage}
            hasEditPermission={this.state.hasEditPermission}
          />
        )}
        {this.state.showSeachAndSelectModal &&
          (this.state.selectedPage &&
          this.state.selectedPage.page_type === "FEED" ? (
            <SearchAndSelect
              type="feed-collection"
              visible={this.state.showSeachAndSelectModal}
              onClose={() => this.setState({ showSeachAndSelectModal: false })}
              onAdd={arr => this._onAdd(arr)}
              selectOne={true}
              hidePagination={false}
              history={this.props.history}
            />
          ) : (
            <SearchAndSelect
              type="collection"
              visible={this.state.showSeachAndSelectModal}
              onClose={() => this.setState({ showSeachAndSelectModal: false })}
              onAdd={arr => this._onAdd(arr)}
              selectOne={true}
              hidePagination={false}
              history={this.props.history}
              pageId={this.state.selectedPage.page_uuid}
            />
          ))}
        {this.state.showAddForm && (
          <DiscoveryPageForm
            formAction="edit"
            onCancel={() => this.setState({ showAddForm: false })}
            data={this.state.selectedPage}
            locationList={this.props.locationList}
            title={"Add Collection"}
            languageList={this.props.languageList}
            onSubmit={(data, action, collectionId) =>
              this._addPageEntity(data, "create", collectionId)
            }
            hasEditPermission={this.state.hasEditPermission}
          />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zeroSearch.loading"),
    locationList: _.get(store, "locationReducer.locationList"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    userDeskMappingList: _.get(store, "cmsUser.userDeskMappingList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    agencyList: _.get(store, "cmsUser.agencyList"),
    pageList: _.get(store, "zeroSearch.pageList"),
    pageEntities: _.get(store, "zeroSearch.pageEntities"),
    total: _.get(store, "zeroSearch.total"),
    refetch: _.get(store, "zeroSearch.refetchData"),
    languageList: _.get(store, "uiConstants.languageList"),
    totalPageEntities: _.get(store, "zeroSearch.totalPageEntities"),
  }
}

export default connect(mapStateToProps)(ManageJoshPages)
