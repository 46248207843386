import Axios from "axios"
import toastr from "toastr"

export const updateFaq = async formData => {
  toastr.info("Action Started !!")
  try {
    let data = await Axios.post(`/api/faq/update`, { data: formData })
    return data
  } catch (error) {
    toastr.error("Error in updating the data")
    throw error
  }
}

export const getFaqData = async formData => {
  try {
    let data = await Axios.get(`/api/faq/get`, formData)
    return data
  } catch (error) {
    toastr.error("Error in getting the data")
    throw error
  }
}
