import Axios from "axios"
import toastr from "toastr"

export const requestGetEvents = async ({ payload }) => {
  try {
    let data = await Axios.get(`/api/event/get`)
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestGetActivity = async ({ payload }) => {
  try {
    const offset = payload["currentPage"] ? payload["currentPage"] : 1
    const limit = payload["pageSize"]
    payload["offset"] = offset
    payload["limit"] = limit
    _.unset(payload, "currentPage")
    _.unset(payload, "pageSize")
    let data = await Axios.post(
      `/api/issue/activity?offset=${offset}&limit=${limit}`,
      payload
    )
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestUpsertEvent = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/event/create`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateEvent = async ({ payload }) => {
  try {
    let data = await Axios.put(
      `/api/event/update/${payload.id}`,
      payload.data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
