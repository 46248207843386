import React from "react"
import { Row, Col } from "reactstrap"
import ReactTimeAgo from "react-time-ago"
import { Tooltip, Tag, Modal, Radio, Button } from "antd"
import "toastr/build/toastr.min.css"
import toastr from "toastr"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  activateDeactivateComment,
  pinComment,
} from "../../clientServices/commentService"
import { MODERATION_REASONS } from "../../constants/uiConstants"

import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import { Card, Chip, Stack, CardContent } from "@mui/material"

class CommentCard extends React.Component {
  state = {
    showActionReasonModal: false,
  }
  _getModerationStatus = moderationLevel => {
    let moderationStatus
    switch (moderationLevel) {
      case 0:
        moderationStatus = "Unsure"
        break
      case 1:
        moderationStatus = "UnModerated"
        break
      case 2:
        moderationStatus = "Human Approved"
        break
      case 3:
        moderationStatus = "Auto Approved"
        break
      case -1:
        moderationStatus = "Auto Disabled"
        break
      case -2:
        moderationStatus = "Human Disabled"
        break
      default:
    }
    return moderationStatus
  }

  _handlePinComment = async comment => {
    try {
      if (comment.parent_post_type === "COMMENT") {
        toastr.error("Parent Post type cannot be COMMENT!!")
        return
      }
      let response = await pinComment(comment.id, !comment.is_pinned)
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status.code === 200
      ) {
        toastr.success(`Comment Pinned/Unpinned Successfully`)
        this.props.onSearch()
      } else {
        if (response && response.message.includes("401")) {
          toastr.error("User Not Verified!!")
        } else {
          throw new Error()
        }
      }
    } catch (error) {
      toastr.error(`Comment Pin/Unpin Failed`)
    }
  }

  _handleCommentAction = async (comment, commentAction) => {
    try {
      let response = await activateDeactivateComment(
        comment.id,
        this.state.moderationReason,
        this.props.currentUser.email,
        commentAction
      )
      let commentList = this.state.commentList

      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status.code === 200
      ) {
        toastr.success(`Comment ${commentAction}d Successfully`)
        this.props.onSearch()
        this.setState({
          commentList,
          showActionReasonModal: false,
          selectedComment: {},
          moderationReason: null,
        })
      } else {
        if (response && response.message.includes("401")) {
          toastr.error("User Not Verified!!")
        } else {
          throw new Error()
        }
      }
    } catch (error) {
      toastr.error(`Comment ${commentAction} Failed`)
    }
  }

  _handleModerationReason = event => {
    this.setState({ moderationReason: event.target.value })
  }

  _commentActionModal = () => {
    let buttonColor = this.state.commentAction == "activate" ? "green" : "red"

    return (
      <>
        <h6>De Activate Reasons</h6>
        <Radio.Group
          onChange={this._handleModerationReason}
          value={this.state.moderationReason}
        >
          <Row gutter={12}>
            {MODERATION_REASONS.map(reason => {
              return (
                <Col lg="4">
                  <Radio value={reason.value}>{reason.label}</Radio>
                </Col>
              )
            })}
          </Row>
        </Radio.Group>
        <Row gutter={24} style={{ marginTop: "10px" }}>
          <Col>
            <Button
              type="dashed"
              style={{ color: buttonColor, borderColor: buttonColor }}
              onClick={() =>
                this._handleCommentAction(
                  this.state.selectedComment,
                  this.state.commentAction
                )
              }
              disabled={this.state.actionButtonLoading}
            >
              {this.state.commentAction == "activate"
                ? "Activate"
                : "De Activate"}
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  _handleDeactivateAction = comment => {
    this.setState({
      showActionReasonModal: true,
      selectedComment: comment,
      commentAction: "deactivate",
    })
  }

  _renderImage = data => {
    if (data?.sticker_comment?.url) {
      return (
        <div
          style={{
            cursor: "pointer",
            width: "130px",
            height: "130px",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <img src={data.sticker_comment.url} width="100px" height="100px" />
        </div>
      )
    }
    return (
      <div
        style={{
          cursor: "pointer",
          width: "130px",
          height: "130px",
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <i className="far fa-comment-dots fa-7x text-secondary avatar-lg" />
      </div>
    )
  }

  render() {
    const { data } = this.props
    return (
      <>
        <React.Fragment>
          <Card className="mb-2">
            <CardContent style={{ padding: 0 }}>
              <div className="d-flex">
                {this._renderImage(data)}
                <div>
                  <Tooltip title={data.title}>
                    <div
                      className="text-truncate text-start font-size-18 my-3 mb-1 ml-6 fw-bold"
                      style={{ height: "24px", width: "50vw" }}
                    >
                      {data.title}
                    </div>
                  </Tooltip>
                  <div className="d-flex flex-wrap mt-4 ml-6">
                    <Stack direction="row" spacing={1}>
                      <div>
                        <Tooltip title="Moderation Status">
                          <Tag
                            className="font-size-12"
                            color={
                              data.moderation_level === 1 ||
                              data.moderation_level === 3 ||
                              data.moderation_level === 0
                                ? "red"
                                : data.moderation_level === 2
                                ? "#87d068"
                                : "#108ee9"
                            }
                            style={{
                              color:
                                data.moderation_level !== -1
                                  ? "black"
                                  : "white",
                            }}
                          >
                            {this._getModerationStatus(data.moderation_level)}
                          </Tag>
                        </Tooltip>
                      </div>
                      {data.review_bucket && (
                        <Tooltip title="Review Bucket">
                          <Chip
                            size="small"
                            variant="outlined"
                            color="primary"
                            label={data.review_bucket}
                          />
                        </Tooltip>
                      )}
                      <Tooltip title="User">
                        <Chip
                          size="small"
                          variant="outlined"
                          color="warning"
                          label={data.user_profile.display_name}
                        />
                      </Tooltip>
                      <Tooltip title="Handle">
                        <Chip
                          size="small"
                          variant="outlined"
                          color="warning"
                          label={data.user_profile.handle}
                        />
                      </Tooltip>
                      {data.created_date && (
                        <Tooltip title={"Created"}>
                          <Chip
                            variant="outlined"
                            size="small"
                            color="success"
                            label={
                              <ReactTimeAgo
                                date={
                                  Number.isNaN(data.created_date)
                                    ? Date.parse(data.created_date)
                                    : data.created_date
                                }
                                locale="en-US"
                              />
                            }
                          />
                        </Tooltip>
                      )}
                      {data.modified_date && (
                        <Tooltip title={"Modified"}>
                          <Chip
                            variant="outlined"
                            size="small"
                            color="success"
                            label={
                              <ReactTimeAgo
                                date={
                                  Number.isNaN(data.modified_date)
                                    ? Date.parse(data.modified_date)
                                    : data.modified_date
                                }
                                locale="en-US"
                              />
                            }
                          />
                        </Tooltip>
                      )}
                      {data.agg_modified_at && (
                        <Tooltip title={data.agg_modified_at}>
                          <Chip
                            variant="outlined"
                            size="small"
                            color="success"
                            label={
                              <ReactTimeAgo
                                date={
                                  Number.isNaN(data.agg_modified_at)
                                    ? Date.parse(data.agg_modified_at)
                                    : data.agg_modified_at
                                }
                                locale="en-US"
                              />
                            }
                          />
                        </Tooltip>
                      )}
                    </Stack>
                  </div>
                </div>
                <div className="d-flex flex-fill font-size-20 p-2 align-items-center justify-content-end text-center">
                  <CopyToClipboard
                    text={data.id}
                    onCopy={e => {
                      toastr.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy Comment ID">
                      <i className="fontSize18 bx bx-copy mx-2" role="button" />
                    </Tooltip>
                  </CopyToClipboard>
                  <Tooltip
                    title={data?.is_pinned ? "Unpin Comment" : "Pin Comment"}
                  >
                    <i
                      className="bx bx-pin mx-2"
                      role="button"
                      onClick={() => {
                        if (
                          !this.props.currentUser?.permissions?.includes(
                            "EDIT_BROWSE_COMMENT"
                          )
                        ) {
                          toastr.error(NO_PERMISSION_MESSAGE)
                          return
                        }
                        this._handlePinComment(data)
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Activate Comment">
                    <i
                      className="bx bx-check-double text-success"
                      role="button"
                      onClick={() => {
                        if (
                          !this.props.currentUser?.permissions?.includes(
                            "EDIT_BROWSE_COMMENT"
                          )
                        ) {
                          toastr.error(NO_PERMISSION_MESSAGE)
                          return
                        }
                        this._handleCommentAction(data, "activate")
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="DeActivate Comment">
                    <i
                      className="bx bx-block mx-2 text-danger"
                      role="button"
                      onClick={() => {
                        if (
                          !this.props.currentUser?.permissions?.includes(
                            "EDIT_BROWSE_COMMENT"
                          )
                        ) {
                          toastr.error(NO_PERMISSION_MESSAGE)
                          return
                        }
                        this._handleDeactivateAction(data)
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </React.Fragment>
        {this.state.showActionReasonModal && (
          <Modal
            centered
            visible={this.state.showActionReasonModal}
            closable={true}
            footer={null}
            onCancel={() =>
              this.setState({
                showActionReasonModal: false,
                moderationReason: "",
              })
            }
          >
            {this._commentActionModal()}
          </Modal>
        )}
      </>
    )
  }
}

export default CommentCard
