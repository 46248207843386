import React from "react"
import _ from "lodash"
const ZoneAddedCard = props => {
  const { data } = props
  return (
    <div
      className={
        props.className ||
        `question-card text-center contentCard ${
          data.added_type === "input" ? "selectedCard" : "warningCard"
        }`
      }
    >
      <div
        className="bg-transparent d-flex justify-content-between align-items-center w-100"
        style={{ padding: "0 5px", flex: "auto" }}
      >
        <div className="card-text-footer">{props.title || data.title}</div>
        <span className="font-size-24" onClick={() => props.removeZone(data)}>
          <i className="fa fa-window-close text-danger" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  )
}

export default ZoneAddedCard
