import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import {
  requestChallengeListByFilters,
  requestGetChallengeDetails,
  requestUpsertChallenge,
  requestUpdateChallengeStatus,
  requestUpdateChallengeVideoMeta,
  requestUpdateChallengeWinners,
  requestDeleteBanner,
} from "appRedux/sagas/requests/challenge"
import {
  getChallengeListByFilters,
  getChallengeListByFiltersSuccess,
  getChallengeDetails,
  getChallengeDetailsSuccess,
  upsertChallenge,
  upsertChallengeSuccess,
  updateChallengeStatus,
  updateChallengeWinners,
  updateChallengeVideoMeta,
  deleteBanner,
  deleteBannerSuccess,
} from "appRedux/slices/challenge"
import { fetchError } from "appRedux/slices/common"

function* handleGetChallengeListByFilters(params) {
  try {
    const data = yield call(requestChallengeListByFilters, params)
    yield put(getChallengeListByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetChallengeDetails(params) {
  try {
    const data = yield call(requestGetChallengeDetails, params)
    yield put(getChallengeDetailsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertChallenge(params) {
  try {
    const data = yield call(requestUpsertChallenge, params)
    yield put(upsertChallengeSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateChallengeStatus(params) {
  try {
    const data = yield call(requestUpdateChallengeStatus, params)
    yield put(upsertChallengeSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateChallengeWinners(params) {
  try {
    const data = yield call(requestUpdateChallengeWinners, params)
    yield put(upsertChallengeSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateChallengeVideoMeta(params) {
  try {
    const data = yield call(requestUpdateChallengeVideoMeta, params)
    yield put(upsertChallengeSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteBanner(params) {
  try {
    const data = yield call(requestDeleteBanner, params)
    yield put(deleteBannerSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(
    getChallengeListByFilters.type,
    handleGetChallengeListByFilters
  )
  yield takeLatest(getChallengeDetails.type, handleGetChallengeDetails)
  yield takeLatest(upsertChallenge.type, handleUpsertChallenge)
  yield takeLatest(updateChallengeStatus.type, handleUpdateChallengeStatus)
  yield takeLatest(updateChallengeWinners.type, handleUpdateChallengeWinners)
  yield takeLatest(
    updateChallengeVideoMeta.type,
    handleUpdateChallengeVideoMeta
  )
  yield takeLatest(deleteBanner.type, handleDeleteBanner)
}
