import React from "react"
import { connect } from "react-redux"
import { Card, CardBody, Col, Table, Badge, Row, CardImg } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip, Popconfirm } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import CustomScrollbars from "util/CustomScrollbars"
import ReactCardFlip from "react-card-flip"
import ReactTimeAgo from "react-time-ago"
import { getImage } from "../../components/SearchAndSelect/util"
import { removePageEntity } from "../../appRedux/slices/zeroSearch"
import "./templateStyle.css"

import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
class TemplateCard extends React.Component {
  state = { isFlipped: false, stateLocationList: [], cityLocationList: [] }

  componentDidMount() {
    let cityLocationList = []
    let stateLocationList = []
    this.props.locationList &&
      this.props.locationList.map(location => {
        location.children.map(childrenLocation => {
          let childrenLocationObject = {}
          childrenLocationObject["label"] = childrenLocation.name
          childrenLocationObject["value"] = childrenLocation.dhTagId
          cityLocationList.push(childrenLocationObject)
        })
        let stateLocationObject = {}
        stateLocationObject["label"] = location.name
        stateLocationObject["value"] = location.dhTagId
        stateLocationList.push(stateLocationObject)
      })
    this.setState({
      stateLocationList,
      cityLocationList,
    })
  }

  _getTitle = (value, type) => {
    let title = ""
    if (type === "language") {
      title = value
    }
    if (type === "location") {
      const [b, c] = value.split("_")
      const key = c ? b.concat(`_${c}`) : b
      if (c) {
        this.state.cityLocationList &&
          this.state.cityLocationList.length > 0 &&
          this.state.cityLocationList.forEach(k => {
            if (k["value"] === key) {
              title = k["label"]
            }
          })
      } else
        this.state.stateLocationList &&
          this.state.stateLocationList.length > 0 &&
          this.state.stateLocationList.forEach(k => {
            if (k["value"] === key) {
              title = k["label"]
            }
          })
    }
    return title
  }

  _getLocations = (data, type) => {
    let locations = []
    let languages = []
    data.target.forEach(entity => {
      if (
        type === "location" &&
        entity.lang_code === "default" &&
        entity.locations &&
        entity.locations.length > 0
      ) {
        entity.locations.forEach(location => {
          const loc =
            this.props.locationList &&
            this.props.locationList.filter(
              l => l.dhTagId === location.location_id
            )
          locations.push(location.location_id)
        })
      }
      if (type === "language") {
        languages.push(entity.lang_code)
      }
    })
    return type === "location" ? locations : languages
  }

  _removePageEntity = async entityId => {
    const response = await this.props.dispatch(removePageEntity(entityId))
  }

  render() {
    const { data } = this.props
    const languageArray = data.target && this._getLocations(data, "language")
    const locationArray = data.target && this._getLocations(data, "location")
    const image = data
      ? data.static_thumbnail ||
        data.dynamic_thumbnail ||
        data.preview_thumbnail
      : undefined

    let title = data && data.asset_label ? data.asset_label : undefined

    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection={"horizontal"}
      >
        <Card
          className={`text-center shadow-lg ${this.props.customClassname}`}
          style={{
            minHeight: "250px",
            borderRadius: "10px",
          }}
        >
          <div className="bg-primary bg-soft" onClick={() => {}}>
            <Row>
              {image ? (
                <div
                  id={"play" + data.template_uuid}
                  style={{ height: "15rem" }}
                >
                  <CardImg
                    top
                    className="img-fluid"
                    src={image}
                    alt="Skote"
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    style={{
                      height: "15rem",
                      cursor: "pointer",
                      opacity: "0.8",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                </div>
              ) : (
                <div
                  className="py-5 px-0"
                  style={{
                    height: "15rem",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <i
                    className={`fas ${getImage(
                      data
                    )} fa-7x py-4 text-secondary`}
                  />
                </div>
              )}
            </Row>
          </div>
          <CardBody style={{ padding: "1rem 0.25rem" }}>
            <Tooltip title={title} style={{ minHeight: "20px" }}>
              <div className="font-size-16 text-truncate font-weight-semibold">
                <i className={`mx-1`} /> {title}
              </div>
            </Tooltip>

            {(!this.props.pageSource ||
              (this.props.pageSource &&
                this.props.pageSource !== "manageEntity")) && (
              <div style={{ minHeight: "30px" }}>
                <Badge
                  className={`${"badge-soft-danger"} ms-1 font-size-13 my-2 text-black`}
                >
                  {data.collection_type}
                </Badge>
              </div>
            )}
            {(!this.props.pageSource ||
              (this.props.pageSource &&
                this.props.pageSource === "manageEntity" &&
                data.category)) && (
              <div style={{ minHeight: "30px" }}>
                <Badge
                  className={`${"badge-soft-success"} ms-1 font-size-13 my-2 text-black`}
                >
                  <Tooltip title="Element Type">{data.category}</Tooltip>
                </Badge>
              </div>
            )}

            {(!this.props.pageSource ||
              (this.props.pageSource &&
                this.props.pageSource !== "manageEntity")) && (
              <div>
                {languageArray && languageArray.length > 0 ? (
                  languageArray.slice(0, 2).map((lang, i) => {
                    return (
                      <Badge className="badge-soft-primary ms-1 my-1">
                        <div className="font-size-15">{lang}</div>
                      </Badge>
                    )
                  })
                ) : (
                  <div> </div>
                )}
                {languageArray && languageArray.length > 2 && (
                  <Tooltip
                    title={languageArray
                      .slice(2, languageArray.length)
                      .map(lang => {
                        return <div>{lang}</div>
                      })}
                    placement="right"
                  >
                    <Badge className="badge-soft-primary ms-1">
                      <div
                        className="font-size-15 px-2"
                        key={"language_more" + languageArray.length}
                        style={{ cursor: "pointer" }}
                      >
                        +{languageArray.length - 2} more
                      </div>
                    </Badge>
                  </Tooltip>
                )}
              </div>
            )}
            {(!this.props.pageSource ||
              (this.props.pageSource &&
                this.props.pageSource !== "manageEntity")) && (
              <div>
                {locationArray && locationArray.length > 0 ? (
                  locationArray.slice(0, 1).map((loc, i) => {
                    return (
                      <Badge className="badge-soft-success ms-1 my-1">
                        <div className="font-size-15">
                          {this._getTitle(loc, "location")}
                        </div>
                      </Badge>
                    )
                  })
                ) : (
                  <div> </div>
                )}
                {locationArray && locationArray.length > 1 && (
                  <Tooltip
                    title={locationArray
                      .slice(1, locationArray.length)
                      .map(loc => {
                        return <div>{this._getTitle(loc, "location")}</div>
                      })}
                    placement="right"
                  >
                    <Badge className="badge-soft-success ms-1">
                      <div
                        className="font-size-15 px-2"
                        key={"language_more" + locationArray.length}
                        style={{ cursor: "pointer" }}
                      >
                        +{locationArray.length - 1} more
                      </div>
                    </Badge>
                  </Tooltip>
                )}
              </div>
            )}
            {this.props.pageSource &&
              this.props.pageSource === "manageEntity" && (
                <div style={{ minHeight: "30px" }}>
                  {data.created_date && (
                    <Tooltip title="Created Date">
                      <Badge className="badge-soft-danger ms-1 font-size-13 text-black">
                        <ReactTimeAgo date={data.created_date} locale="en-US" />
                      </Badge>
                    </Tooltip>
                  )}{" "}
                  {data.modified_date && (
                    <Tooltip title="Modified Date">
                      <Badge className="badge-soft-danger ms-1 font-size-13 text-black">
                        <ReactTimeAgo
                          date={data.modified_date}
                          locale="en-US"
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </div>
              )}
          </CardBody>
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={data.template_uuid}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Template ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            {!this.props.hideButton && (
              <>
                <div className="flex-fill">
                  <Tooltip title="Edit Template">
                    <i
                      className="bx bx-edit-alt"
                      role="button"
                      onClick={() => {
                        if (
                          !this.props.currentUser?.permissions?.includes(
                            "EDIT_TEMPLATES"
                          )
                        ) {
                          toastr.error(NO_PERMISSION_MESSAGE)
                          return
                        }
                        this.props.onEditClick(data)
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="flex-fill">
                  <Tooltip title="Manage Trending">
                    <i
                      className="bx bx-trending-up"
                      role="button"
                      onClick={() => {
                        if (
                          !this.props.currentUser?.permissions?.includes(
                            "EDIT_TEMPLATES"
                          )
                        ) {
                          toastr.error(NO_PERMISSION_MESSAGE)
                          return
                        }
                        this.props.onTrendingClick(data)
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="flex-fill">
                  <Popconfirm
                    placement="topRight"
                    title="Are you sure you want to delete this Template?"
                    onConfirm={() => {
                      if (
                        !this.props.currentUser?.permissions?.includes(
                          "EDIT_TEMPLATES"
                        )
                      ) {
                        toastr.error(NO_PERMISSION_MESSAGE)
                        return
                      }
                      this.props.onDeleteClick(data.template_uuid)
                    }}
                  >
                    <i
                      className="bx bx bx-trash-alt text-danger"
                      role="button"
                    />
                  </Popconfirm>
                </div>
              </>
            )}
          </div>
        </Card>
        <Card
          className="text-center shadow-lg"
          style={{
            height: "440px",
            borderRadius: "10px",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <CustomScrollbars>
            <CardBody className="px-0 py-0">
              <Row className="my-sm-2">
                <Col className="ms-2">
                  <div className="avatar-xs">
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-warning" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="bx bx-bar-chart" />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end me-2">
                  <div
                    className="avatar-xs"
                    role="button"
                    onClick={() => this.setState({ isFlipped: false })}
                  >
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-dark" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="fas fa-times-circle" />
                      </span>
                    </div>
                  </div>
                  <div></div>
                </Col>
              </Row>
              <Row>
                <div className="table-responsive">
                  <Table className="mb-0 table-striped">
                    <tbody>
                      <tr className="text-start">
                        <th scope="row">Deeplink:</th>
                        <td className="break-word">
                          <CopyToClipboard
                            text={data.deeplink}
                            onCopy={e => {
                              toastr.success("Copied sucessfully!")
                            }}
                          >
                            <Tooltip title="Copy Deeplink">
                              <i className="bx bx-copy" role="button" />
                            </Tooltip>
                          </CopyToClipboard>
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Theme Name:</th>
                        <td className="break-word">{data.theme_name || "-"}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </CardBody>
          </CustomScrollbars>
        </Card>
      </ReactCardFlip>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    refetch: _.get(store, "cameraAsset.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(TemplateCard)
