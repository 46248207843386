import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import UserList from "../../components/UserList/userList"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getUserListByFilters,
  getAppUserDetails,
  resetState,
} from "appRedux/slices/appUser"
import { getLocationData } from "appRedux/slices/location"
import Auxiliary from "../../util/Auxiliary"
import Chips from "components/CommonFilter/chips"
import { userFilters } from "../../constants/uiConstants"
import "./styles.css"

class BrowseUser extends ErrorBoundComponent {
  state = {
    selectedUser: {},
    stateLocationList: [],
    cityLocationList: [],
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 12,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchAfter:
        this.props.queryParams && this.props.queryParams["searchAfter"]
          ? this.props.queryParams["searchAfter"]
          : null,
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : "",
      searchType:
        this.props.queryParams && this.props.queryParams["searchType"]
          ? this.props.queryParams["searchType"]
          : "user_uuid",
      creatorType:
        this.props.queryParams && this.props.queryParams["creatorType"]
          ? this.props.queryParams["creatorType"]
          : "",
      contentCreateLangs:
        this.props.queryParams && this.props.queryParams["contentCreateLangs"]
          ? this.props.queryParams["contentCreateLangs"]
          : "",
      userLangPrimary:
        this.props.queryParams && this.props.queryParams["userLangPrimary"]
          ? this.props.queryParams["userLangPrimary"]
          : "",
      rangeSearchType:
        this.props.queryParams && this.props.queryParams["rangeSearchType"]
          ? this.props.queryParams["rangeSearchType"]
          : null,
      opSearchType:
        this.props.queryParams && this.props.queryParams["opSearchType"]
          ? this.props.queryParams["opSearchType"]
          : null,
      rangeSearchText:
        this.props.queryParams && this.props.queryParams["rangeSearchText"]
          ? this.props.queryParams["rangeSearchText"]
          : null,
      rangeSearchFrom:
        this.props.queryParams && this.props.queryParams["rangeSearchFrom"]
          ? this.props.queryParams["rangeSearchFrom"]
          : null,
      rangeSearchTo:
        this.props.queryParams && this.props.queryParams["rangeSearchTo"]
          ? this.props.queryParams["rangeSearchTo"]
          : null,
      createdDateFrom:
        this.props.queryParams && this.props.queryParams["createdDateFrom"]
          ? this.props.queryParams["createdDateFrom"]
          : null,
      createdDateTo:
        this.props.queryParams && this.props.queryParams["createdDateTo"]
          ? this.props.queryParams["createdDateTo"]
          : null,
      orderByField:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : "posts_count",
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "desc",
      curatedType:
        this.props.queryParams && this.props.queryParams["curatedType"]
          ? this.props.queryParams["curatedType"]
          : null,
    },
    previewUser: false,
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.deepClean(this.state.filters)
      if (this.props.currentUser && this.props.currentUser.jotUserType === 6) {
        filters.brandUsers = "Y"
      } else if (
        this.props.currentUser &&
        this.props.currentUser.jotUserType === 3
      ) {
        filters.brandUsers = "N"
      }
      this.props.dispatch(getUserListByFilters(filters))
      this.props.dispatch(getLocationData())

      const filterCount = Object.keys(_.deepClean(this.state.filters)).length
      this.setState({ filterCount })

      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locationList.length !== this.props.locationList.length) {
      let stateLocationList = []
      let cityLocationList = []
      let cityMap = {}
      let stateMap = {}
      this.props.locationList.map(location => {
        location.children.map(childrenLocation => {
          let childrenLocationObject = {}
          childrenLocationObject["label"] = childrenLocation.name
          childrenLocationObject["value"] = childrenLocation.dhTagId
          cityMap[childrenLocation.dhTagId] = childrenLocation.name
          cityLocationList.push(childrenLocationObject)
        })
        let stateLocationObject = {}
        stateLocationObject["label"] = location.name
        stateLocationObject["value"] = location.dhTagId
        stateMap[location.dhTagId] = location.name
        stateLocationList.push(stateLocationObject)
      })
      this.setState({ stateLocationList, cityLocationList, stateMap, cityMap })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }
    if (type === "searchText" || type === "order" || type === "creatorType") {
      this.setState({
        filters: newFilters,
      })
    } else {
      this.setState({
        filters: newFilters,
      })
    }
  }

  _searchUser = () => {
    this.props.history.push(
      `/user/browse?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
    if (this.state.filters.searchType === "phone") {
      return this.props.dispatch(
        getAppUserDetails({
          phoneNumber: this.state.filters.searchText,
        })
      )
    }
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }

    this.props.dispatch(getUserListByFilters(_.deepClean(this.state.filters)))
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: userFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/user/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getUserListByFilters(_.deepClean(this.state.filters))
        )
      }
    )
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _closeFilters = () => {
    this._searchUser()
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _showFilters = () => {
    this.setState({
      showFilters: true,
    })
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/user/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getUserListByFilters(_.deepClean(this.state.filters))
        )
      }
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.state.stateLocationList &&
          this.state.stateLocationList.length > 0 && (
            <>
              <Chips
                showFilters={this.state.showFilters}
                showAdvancedFilters={this.state.showAdvancedFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                type="user"
                toggleFilters={this._toggleFilters}
                toggleAdvancedFilters={this._toggleAdvancedFilters}
                cityLocationList={this.state.cityLocationList}
                stateLocationList={this.state.stateLocationList}
              />
              <UserList
                userList={this.props.userList}
                onPageChange={this._onPageChange}
                total={this.props.total}
                searchAfter={this.props.searchAfter}
                isMobile={this.props.isMobile}
                currentPage={this.state.filters["currentPage"]}
                pageSize={this.state.filters["pageSize"]}
                currentUser={this.props.currentUser}
                stateMap={this.state.stateMap}
                cityMap={this.state.cityMap}
                filters={this.state.filters}
              />
            </>
          )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    locationList: _.get(store, "locationReducer.locationList"),
    userList: _.get(store, "appUser.user"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "appUser.total"),
    searchAfter: _.get(store, "appUser.searchAfter"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "appUser.loading"),
    userDetails: _.get(store, "appUser.userDetails"),
  }
}

export default connect(mapStateToProps)(BrowseUser)
