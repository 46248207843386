import { Pagination, Select, Tag } from "antd"
import {
  getAudioDetails,
  getAudioTabs,
  resetState,
  updateAudioStatus,
  updateAudioVisibility,
  getPlayList,
  upsertAudio,
} from "appRedux/slices/audio"
import AudioCard from "components/AudioList/audioCard"
import Chips from "components/CommonFilter/chips"
import _ from "lodash"
import QueryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { Button } from "@mui/material"
import toastr from "toastr"
import { markTrending } from "../../appRedux/slices/trending"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  audioFilters,
  NO_PERMISSION_MESSAGE,
} from "../../constants/uiConstants"
import MarkTrendingForm from "../../containers/CommonTrending/trendingUpsert"
import Auxiliary from "../../util/Auxiliary"
import ValidatorForm, { Switch, TextField } from "../ValidatorForm"
import AudioUpsertForm from "./audioUpsertForm"
import "./styles.css"
import UploadBulkAudioForm from "./uploadBulkContentForm"
import { formatNumber } from "../../util/common"
import { bulkUploadDeactivateAudio } from "../../clientServices/audioService"

class ManageAudio extends ErrorBoundComponent {
  state = {
    filters: {
      audioId: this.props.queryParams ? this.props.queryParams["audioId"] : "",
      clusteredAudioId:
        this.props.queryParams && this.props.queryParams["clusteredAudioId"]
          ? this.props.queryParams["clusteredAudioId"]
          : "",
      currentPage: this.props.queryParams
        ? this.props.queryParams["currentPage"]
        : 1,
      categories: this.props.queryParams
        ? this.props.queryParams["categories"]
        : [],
      languages: this.props.queryParams
        ? this.props.queryParams["languages"]
        : [],
      title: this.props.queryParams ? this.props.queryParams["title"] : "",
      artist: this.props.queryParams ? this.props.queryParams["artist"] : "",
      sponsored: this.props.queryParams
        ? this.props.queryParams["sponsored"]
        : this.props.currentUserType == 6
        ? "Y"
        : "N",
      album: this.props.queryParams ? this.props.queryParams["album"] : "",
      label: this.props.queryParams ? this.props.queryParams["label"] : "",
      uploadedBy:
        this.props.queryParams && this.props.queryParams["uploadedBy"]
          ? this.props.queryParams["uploadedBy"]
          : "",
      searchTypeArtist:
        this.props.queryParams && this.props.queryParams["searchTypeArtist"]
          ? this.props.queryParams["searchTypeArtist"]
          : "artist_id",
      searchTypeLabel:
        this.props.queryParams && this.props.queryParams["searchTypeLabel"]
          ? this.props.queryParams["searchTypeLabel"]
          : "label_id",
      searchTypeAlbum:
        this.props.queryParams && this.props.queryParams["searchTypeAlbum"]
          ? this.props.queryParams["searchTypeAlbum"]
          : "album_id",
      orderByField:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : "created_date",
      blockedReason:
        this.props.queryParams && this.props.queryParams["blockedReason"]
          ? this.props.queryParams["blockedReason"]
          : "",
      acrMetaLabel:
        this.props.queryParams && this.props.queryParams["acrMetaLabel"]
          ? this.props.queryParams["acrMetaLabel"]
          : "",
      acrMetaCreatedDateFrom:
        this.props.queryParams &&
        this.props.queryParams["acrMetaCreatedDateFrom"]
          ? this.props.queryParams["acrMetaCreatedDateFrom"]
          : null,
      acrMetaCreatedDateTo:
        this.props.queryParams && this.props.queryParams["acrMetaCreatedDateTo"]
          ? this.props.queryParams["acrMetaCreatedDateTo"]
          : null,
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "desc",
    },
    showModal: false,
    showUploadModal: false,
    audio: {},
    action: "create",
    showFilters: false,
    showAdvancedFilters: false,
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      let filters = _.cloneDeep(this.state.filters)
      _.map(this.state.filters, (filter, key) => {
        if (!filter) {
          _.unset(filters, key)
        }
      })
      this.props.dispatch(getAudioDetails(_.deepClean(filters)))
      this.props.dispatch(getAudioTabs())
      this.props.dispatch(getPlayList())
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.common.error && this.props.refetchAudioList) {
      let filters = _.cloneDeep(this.state.filters)
      _.map(this.state.filters, (filter, key) => {
        if (!filter) {
          _.unset(filters, key)
        }
      })
      this.setState({
        showModal: false,
        modalType: "",
        audioId: null,
        defaultValue: null,
        showUploadModal: false,
      })
      this.props.dispatch(getAudioDetails(_.deepClean(filters)))
    }
    if (this?.props?.queryParams?.audioId !== prevProps?.queryParams?.audioId) {
      let newFilters = _.cloneDeep(this.state.filters)
      newFilters["audioId"] = this?.props?.queryParams?.audioId
      this.setState({
        filters: newFilters,
      })
      this.props.dispatch(getAudioDetails(_.deepClean(newFilters)))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _onSearch = () => {
    let filters = _.cloneDeep(this.state.filters)
    _.map(this.state.filters, (filter, key) => {
      if (!filter) {
        _.unset(filters, key)
      }
    })
    this.props.history.push(`/audio/manage?${QueryString.stringify(filters)}`)
    this.props.dispatch(getAudioDetails(_.deepClean(filters)))
  }

  _closeFilters = () => {
    this._onSearch()
    this.setState({ showFilters: false, showAdvancedFilters: false })
  }

  _handleFilterChange = (value, type) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: audioFilters[filter].defaultValue,
      showFilters: false,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/audio/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        let filters = _.cloneDeep(this.state.filters)
        this.props.dispatch(getAudioDetails(_.deepClean(filters)))
      }
    )
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _audioFilterForm = () => {
    return (
      <Chips
        type="audio"
        showFilters={this.state.showFilters}
        showAdvancedFilters={this.state.showAdvancedFilters}
        filters={this.state.filters}
        search={this._closeFilters}
        removeFilter={this._removeFilter}
        handleFilterChange={this._handleFilterChange}
        toggleFilters={this._toggleFilters}
        toggleAdvancedFilters={this._toggleAdvancedFilters}
      />
    )
  }

  _closeModal = () => {
    this.setState({
      showModal: false,
      modalType: "",
      audioId: null,
      defaultValue: null,
    })
  }

  _showUpsertAudio = (action, audio) => {
    this.setState({
      showModal: true,
      action,
      modalType: action,
      audio,
    })
  }

  _toggleStatus = async ({ formData, errors }) => {
    if (
      formData.active === true &&
      this.state.audio.blocked_content === "AUDIO_COPYRIGHT"
    ) {
      formData.enable_blocked_content = "Y"
    }
    if (errors) {
      return
    }
    this.props.dispatch(updateAudioStatus(formData))
  }

  _toggleVisibility = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    this.props.dispatch(updateAudioVisibility(formData))
  }

  _getStatusUpdateForm = () => {
    const updateDisabled =
      this.state.audio.blocked_content === "AUDIO_COPYRIGHT" &&
      this.props.currentUserType > 2
    return (
      <ValidatorForm
        onSubmit={this._toggleStatus}
        {...this._formLayout}
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <TextField
              field={"id"}
              label={"Audio Id"}
              disabled
              defaultValue={this.state.audio && this.state.audio.id}
            />
          </Col>
          <Col span={24}>
            <Switch
              label={"Status"}
              field={"active"}
              defaultValue={this.state.audio && !this.state.audio.not_active}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            variant="contained"
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
            disabled={updateDisabled}
          >
            Update
          </Button>
          <Button
            color="error"
            variant="contained"
            className="mx-2"
            onClick={() => this.setState({ showModal: false })}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            Cancel
          </Button>
        </div>
      </ValidatorForm>
    )
  }

  _onUpload = async () => {
    const formData = new FormData()
    formData.append("file", this.state.csvFile)
    await bulkUploadDeactivateAudio(formData)
    this.setState({ showBulkUploadForm: false })
  }

  _getVisibilityForm = () => {
    return (
      <ValidatorForm
        onSubmit={this._toggleVisibility}
        {...this._formLayout}
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <TextField
              field={"id"}
              label={"Audio Id"}
              disabled
              defaultValue={this.state.audio && this.state.audio.id}
            />
          </Col>
          <Col span={24}>
            <Switch
              label={"Visibility"}
              field={"visible"}
              defaultValue={this.state.audio && !this.state.audio.not_visible}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            htmlType="submit"
            variant="contained"
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            Update
          </Button>
          <Button
            color="error"
            className="mx-2"
            variant="contained"
            onClick={() => this.setState({ showModal: false })}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            Cancel
          </Button>
        </div>
      </ValidatorForm>
    )
  }

  onTrendingSubmit = data => {
    this.props.dispatch(
      markTrending({ data, entityId: this.state.audio.id, type: "audio" })
    )
    this._closeModal()
  }

  _getModalContent = () => {
    switch (this.state.modalType) {
      case "status": {
        return this._getStatusUpdateForm()
      }
      case "visibility": {
        return this._getVisibilityForm()
      }
      case "trending": {
        return (
          <MarkTrendingForm
            onSubmit={this.onTrendingSubmit}
            onCancel={this._closeModal}
            data={
              this.state.audio && this.state.audio.trending_meta_cms
                ? this.state.audio.trending_meta_cms
                : ""
            }
          />
        )
      }
      default: {
        return (
          <AudioUpsertForm
            onSubmit={data => this.props.dispatch(upsertAudio(data))}
            formAction={this.state.action}
            audio={this.state.audio}
            playList={this.props.playList}
            currentUserType={this.props.currentUserType}
            audioTabsBasedOnLanguage={this.props.audioTabsBasedOnLanguage}
            onCancel={this._closeModal}
          />
        )
      }
    }
  }

  _modal = () => {
    return (
      <Card>
        <CardTitle className="p-3 pb-0">{"Create / Update Audio"}</CardTitle>
        <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
          {this._getModalContent()}
        </CardBody>
      </Card>
    )
  }

  _performManageAudioAction = async (action, audioId, defaultValue) => {
    this.setState({
      modalType: action,
      showModal: true,
      audioId,
      defaultValue,
    })
  }

  _showAudioDetails = () => {
    return (
      <React.Fragment>
        <Col lg={this.state.showForm ? "7" : "12"}>
          <div className="trending-card-container">
            {this.props.audioList.map((audio, index) => {
              return (
                <AudioCard
                  audio={audio}
                  key={`${index}_${audio.title}`}
                  editClick={(audio, type) => this.editClick(audio, type)}
                  audioDetails={
                    this.props.audioDetails
                      ? this.props.audioDetails.filter(
                          audio => audio && audio.id === audio.id
                        )
                      : null
                  }
                  currentUser={this.props.currentUser}
                  history={this.props.history}
                  onEditClick={(action, audio) =>
                    this.setState({ showModal: false }, () => {
                      this.setState({
                        showModal: true,
                        action,
                        modalType: action,
                        audio,
                      })
                    })
                  }
                />
              )
            })}
          </div>
        </Col>
      </React.Fragment>
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._onSearch()
      }
    )
  }

  _pagination = () => {
    return (
      <div className="d-flex justify-challenge-end">
        <Pagination
          style={{ marginTop: "1rem" }}
          onChange={this._onPageChange}
          current={parseInt(this.state.filters["currentPage"], 10)}
          defaultCurrent={parseInt(this.state.filters["currentPage"], 10)}
          total={this.props.total > 10000 ? 10000 : this.props.total}
          showSizeChanger={false}
        />
      </div>
    )
  }

  _createUploadModal = () => {
    return (
      <Card>
        <CardTitle className="p-3 pb-0">{"Upload Content"}</CardTitle>
        <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
          <UploadBulkAudioForm
            onCancel={() =>
              this.setState({ showUploadModal: false, action: null })
            }
          />
        </CardBody>
      </Card>
    )
  }

  _createbulkUploadModal = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="6">
              <label className="col-form-label">Upload CSV File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this.setState({
                    csvFile: e.target.files[0],
                  })
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm="12">
              <div className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="font-16"
                  color="primary"
                  onClick={this._onUpload}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  // disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showBulkUploadModal: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._audioFilterForm()}
        {!(this.state.showModal || this.state.showUploadModal) && (
          <div className="d-flex justify-content-end mb-3">
            <Button
              variant="contained"
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_MANAGE_AUDIO"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this._showUpsertAudio("create", {})
              }}
            >
              Create
            </Button>
            <Button
              variant="contained"
              className="mx-2"
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_MANAGE_AUDIO"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this.setState({ showUploadModal: true, action: "upload" })
              }}
            >
              Upload
            </Button>
          </div>
        )}
        <Row>
          {!(this.state.showModal || this.state.showUploadModal) && (
            <>
              {this.props.total &&
                this.props.audioList &&
                this.props.audioList.length > 0 && (
                  <div style={{ textAlign: "center" }} className="mb-2">
                    <Tag color="geekblue">
                      Showing <b>{this.props.audioList.length}</b> of{" "}
                      <b>{formatNumber(this.props.total)}</b> Audios
                    </Tag>
                  </div>
                )}
              {!_.isEmpty(this.props.audioList) && this._showAudioDetails()}
              <div className="d-flex justify-content-end mb-3">
                {this._pagination()}
              </div>
            </>
          )}
          {this.state.showModal && this._modal()}
          {this.state.showUploadModal && this._createUploadModal()}
        </Row>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "audio.loading"),
    refetchAudioList: _.get(store, "audio.refetchAudioList"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    audioList: _.get(store, "audio.audioList"),
    total: _.get(store, "audio.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    audioTabsBasedOnLanguage: _.get(store, "audio.audioTabsBasedOnLanguage"),
    playList: _.get(store, "audio.playList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageAudio)
