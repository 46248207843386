import { createSlice } from "@reduxjs/toolkit"

const appUserSlice = createSlice({
  name: "appUser",
  initialState: {
    userDetails: {},
    user: [],
    loading: false,
    refetchData: false,
    userVideos: [],
    totalUserVideos: 0,
    boostHistory: [],
    logs: [],
    boostFollowMeta: null,
  },
  reducers: {
    getUserListByFilters(state, action) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        user: [],
      }
    },
    getUserListByFiltersSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        user: payload.data.users,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 0,
        refetchData: false,
      }
    },
    getAppUserDetailsES(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        userDetails: {},
      }
    },
    getAppUserDetailsESSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        userDetails: payload.data,
        refetchData: false,
      }
    },
    fuzzySearchUser(state) {
      return {
        ...state,
        loading: true,
        user: [],
      }
    },
    fuzzySearchUserSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        user: payload.data?.rows ? payload.data.rows : [],
        total: payload.data?.count,
      }
    },
    getAppUserVideos(state) {
      return {
        ...state,
        userVideos: [],
        loading: true,
      }
    },
    getTasksByFilter(state) {
      return {
        ...state,
        loading: true,
        refetchTaskList: false,
        userList: [],
      }
    },
    getTasksByFilterSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        userList: payload.data.user || [],
        total: payload.data.total,
      }
    },
    getAppUserVideosSuccess(state, { payload }) {
      return {
        ...state,
        userVideos: payload.data.content,
        loading: false,
        totalUserVideos: payload.data.total.value
          ? payload.data.total.value
          : payload.data.total,
      }
    },
    getLogs(state) {
      return {
        ...state,
        logs: [],
        loading: true,
      }
    },
    getLogsSuccess(state, { payload }) {
      return {
        ...state,
        logs: payload.data,
        loading: false,
      }
    },
    updateAppUserCuratedEntities() {},
    manageUser() {},
    updateUser() {},
    updateUserType() {},
    getAppUserDetails(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getAppUserDetailsSuccess(state, { payload }) {
      const newDetails = {
        ...state.userDetails,
        ...payload.data,
        phone_no: payload.data.phone_no,
      }
      return {
        ...state,
        userDetails: { ...newDetails },
        loading: false,
        user: [{ ...newDetails }],
      }
    },
    tagUser() {},
    createAppUser(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createAppUserSuccess(state) {
      return {
        ...state.userDetails,
        loading: false,
      }
    },
    getAppUserDetailsES() {},
    uploadUser() {},
    getBoostFollowHistory(state) {
      return {
        ...state,
        loading: true,
        refetchBoostHistory: false,
      }
    },
    getBoostFollowHistorySuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        boostHistory: payload.data,
      }
    },

    getDeltaHistory(state) {
      return {
        ...state,
        loading: true,
        refetchBoostHistory: false,
      }
    },
    getDeltaHistorySuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        boostHistory: payload.data,
      }
    },

    getActivityBoostHistory(state) {
      return {
        ...state,
        loading: true,
        refetchBoostHistory: false,
      }
    },
    getActivityBoostHistorySuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        boostHistory: payload.data.data,
        total: payload.data.total,
      }
    },
    boostFollow(state) {
      return {
        ...state,
        loading: true,
        refetchBoostHistory: false,
      }
    },
    boostFollowSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchBoostHistory: true,
      }
    },
    updateDelta(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateDeltaSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    getBoostFollowStatus(state) {
      return {
        ...state,
        loading: true,
        boostFollowMeta: null,
      }
    },
    getBoostFollowStatusSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        boostFollowMeta: payload.data,
      }
    },
    toggleShoppable(state) {
      return {
        ...state,
        loading: true,
      }
    },
    toggleShoppableSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    toggleAllowJoshLive(state) {
      return {
        ...state,
        loading: true,
      }
    },
    toggleAllowJoshLiveSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    updateUserAICaption(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateUserAICaptionSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    liveModerateUser() {},
    resetState() {
      return {
        userDetails: {},
        user: [],
        loading: false,
        refetchData: false,
        userVideos: [],
        totalUserVideos: 0,
        refetchBoostHistory: false,
        boostFollowMeta: null,
      }
    },
  },
})

export const {
  getUserListByFilters,
  getUserListByFiltersSuccess,
  resetState,
  getAppUserDetailsES,
  getAppUserDetailsESSuccess,
  getAppUserVideos,
  getAppUserVideosSuccess,
  manageUser,
  updateUser,
  updateUserType,
  updateAppUserCuratedEntities,
  tagUser,
  getAppUserDetails,
  getAppUserDetailsSuccess,
  getLogsSuccess,
  getLogs,
  createAppUser,
  createAppUserSuccess,
  uploadUser,
  getBoostFollowHistory,
  getBoostFollowHistorySuccess,
  getActivityBoostHistory,
  getActivityBoostHistorySuccess,
  boostFollow,
  boostFollowSuccess,
  getBoostFollowStatus,
  getBoostFollowStatusSuccess,
  updateDeltaSuccess,
  updateDelta,
  getDeltaHistory,
  getDeltaHistorySuccess,
  toggleShoppable,
  toggleShoppableSuccess,
  toggleAllowJoshLive,
  toggleAllowJoshLiveSuccess,
  fuzzySearchUser,
  fuzzySearchUserSuccess,
  getTasksByFilter,
  updateUserAICaption,
  updateUserAICaptionSuccess,
  getTasksByFilterSuccess,
  liveModerateUser,
} = appUserSlice.actions

export default appUserSlice.reducer
