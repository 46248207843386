import React from "react"
import moment from "moment"
import { Col, Row } from "reactstrap"
import { Input, Select, DatePicker, Divider, Tag } from "antd"
import dayjs from "dayjs"
const { Option } = Select

const IssueListDrawer = props => {
  return (
    <>
      <Row>
        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By Issue Id</h5>
          </Divider>
          <Input
            defaultValue={props.filters["issueId"]}
            placeholder={"Issue Id"}
            onChange={e => props.handleFilterChange(e.target.value, "issueId")}
            allowClear={true}
            name="issueId"
            style={{ width: "100%" }}
          />
        </Col>

        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              Filter By User Uuid
            </h5>
          </Divider>
          <Input
            defaultValue={props.filters["userUuid"]}
            placeholder={"User Uuid"}
            onChange={e => props.handleFilterChange(e.target.value, "userUuid")}
            allowClear={true}
            name="userUuid"
            style={{ width: "100%" }}
          />
        </Col>

        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              Filter By Content Uuid
            </h5>
          </Divider>
          <Input
            defaultValue={props.filters["contentUuid"]}
            placeholder={"Content Uuid"}
            onChange={e =>
              props.handleFilterChange(e.target.value, "contentUuid")
            }
            allowClear={true}
            name="contentUuid"
            style={{ width: "100%" }}
          />
        </Col>

        <Col lg="3" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              Filter By Issue Status
            </h5>
          </Divider>
          <Select
            allowClear={true}
            placeholder="Issue Status"
            defaultValue={props.filters["status"]}
            onChange={value => {
              props.handleFilterChange(value, "status")
            }}
            style={{ width: "100%" }}
          >
            <Option value="reported">REPORTED</Option>
            <Option value="verified_open">OPEN</Option>
            <Option value="in_progress">IN PROGRESS</Option>
            <Option value="waiting_for_feedback">WAITING</Option>
            <Option value="closed">CLOSED</Option>
          </Select>
        </Col>

        <Col lg="3" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              Filter By Issue Severity
            </h5>
          </Divider>
          <Select
            allowClear={true}
            placeholder="Issue Severity"
            defaultValue={props.filters["issueSeverity"]}
            onChange={value => {
              props.handleFilterChange(value, "issueSeverity")
            }}
            style={{ width: "100%" }}
          >
            <Option value="high">HIGH</Option>
            <Option value="moderate">MODERATE</Option>
            <Option value="low">LOW</Option>
          </Select>
        </Col>

        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              Filter By Reported By
            </h5>
          </Divider>
          <Input
            defaultValue={props.filters["reportedBy"]}
            placeholder={"Reported By"}
            onChange={e =>
              props.handleFilterChange(e.target.value, "reportedBy")
            }
            allowClear={true}
            name="reportedBy"
            style={{ width: "100%" }}
          />
        </Col>

        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              Filter By Assigned To
            </h5>
          </Divider>
          <Input
            defaultValue={props.filters["assignedTo"]}
            placeholder={"Assigned To"}
            onChange={e =>
              props.handleFilterChange(e.target.value, "assignedTo")
            }
            allowClear={true}
            name="assignedTo"
            style={{ width: "100%" }}
          />
        </Col>

        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              Filter By Created On
            </h5>
          </Divider>
          <div className="d-flex justify-content-around">
            <DatePicker
              defaultValue={
                props.filters["createdDateFrom"]
                  ? dayjs.unix(
                      props.filters["createdDateFrom"] / 1000,
                      "YYYY-MM-DD"
                    )
                  : null
              }
              format="YYYY-MM-DD HH:mm"
              showTime
              placeholder="Created On"
              onChange={(date, dateString) => {
                props.handleFilterChange(
                  !_.isEmpty(dateString)
                    ? moment(dateString).format("x")
                    : null,
                  "createdDateFrom"
                )
              }}
              style={{ width: "100%" }}
            />
            <DatePicker
              defaultValue={
                props.filters["createdDateTo"]
                  ? dayjs.unix(
                      props.filters["createdDateTo"] / 1000,
                      "YYYY-MM-DD"
                    )
                  : null
              }
              style={{ width: "100%" }}
              format="YYYY-MM-DD HH:mm"
              showTime
              placeholder="To Date"
              onChange={(date, dateString) => {
                props.handleFilterChange(
                  !_.isEmpty(dateString)
                    ? moment(dateString).format("x")
                    : null,
                  "createdDateTo"
                )
              }}
            />
          </div>
        </Col>

        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              Filter By Modified On
            </h5>
          </Divider>
          <div className="d-flex justify-content-around">
            <DatePicker
              defaultValue={
                props.filters["modifiedDateFrom"]
                  ? dayjs.unix(
                      props.filters["modifiedDateFrom"] / 1000,
                      "YYYY-MM-DD"
                    )
                  : null
              }
              format="YYYY-MM-DD HH:mm"
              showTime
              placeholder="Modified On"
              onChange={(date, dateString) => {
                props.handleFilterChange(
                  !_.isEmpty(dateString)
                    ? moment(dateString).format("x")
                    : null,
                  "modifiedDateFrom"
                )
              }}
              style={{ width: "100%" }}
            />
            <DatePicker
              defaultValue={
                props.filters["modifiedDateTo"]
                  ? dayjs.unix(
                      props.filters["modifiedDateTo"] / 1000,
                      "YYYY-MM-DD"
                    )
                  : null
              }
              format="YYYY-MM-DD HH:mm"
              showTime
              placeholder="Modified On"
              onChange={(date, dateString) => {
                props.handleFilterChange(
                  !_.isEmpty(dateString)
                    ? moment(dateString).format("x")
                    : null,
                  "modifiedDateTo"
                )
              }}
              style={{ width: "100%" }}
            />
          </div>
        </Col>

        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By Title</h5>
          </Divider>
          <Input
            defaultValue={props.filters["title"]}
            placeholder={"Title"}
            onChange={e => props.handleFilterChange(e.target.value, "title")}
            allowClear={true}
            name="title"
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </>
  )
}

export default IssueListDrawer
