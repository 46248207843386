import React from "react"
import { connect } from "react-redux"
import { Card, CardBody, Badge, Row, CardImg } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  NO_PERMISSION_MESSAGE,
  // LANGUAGE_MAPPING,
  GENRE_MAPPING,
} from "../../constants/uiConstants"
import ReactTimeAgo from "react-time-ago"
import _ from "lodash"

class ListingsCard extends React.Component {
  render() {
    const { data } = this.props

    // const locationMap = {}

    // this.props.locationList.forEach(location => {
    //   locationMap[location.dhTagId] = location.name
    // })

    const locationMap = this.props.locationList.reduce((acc, location) => {
      let cityMap = {}
      if (location.children && location.children.length > 0) {
        cityMap = location.children.reduce((accCity, city) => {
          return { ...cityMap, ...accCity, [city.dhTagId]: city.name }
        }, {})
      }
      return {
        ...acc,
        ...cityMap,
        [location.dhTagId]: location.name,
      }
    }, {})

    return (
      <React.Fragment>
        <Card
          onClick={() => this.props.onEditClick(data)}
          className="text-center shadow-lg"
          style={{
            minHeight: "250px",
            borderRadius: "10px",
          }}
          key={data.id}
        >
          <div className="bg-primary bg-soft">
            <Row>
              <div
                className="py-5 px-0"
                style={{
                  height: "15rem",
                }}
              >
                <i className={`fas fa-list fa-7x py-4 text-secondary`} />
              </div>
            </Row>
          </div>
          <CardBody style={{ padding: "1rem 0.25rem" }}>
            <Tooltip title={data.name} style={{ minHeight: "20px" }}>
              <div className="font-size-18 text-truncate font-weight-semibold my-1">
                <i className={`mx-1`} /> {data.name}
              </div>
            </Tooltip>

            {data.genre ? (
              <Tooltip title="Genre">
                <Badge
                  className={`${"badge-soft-primary"} ms-1 my-1 text-black me-1 font-size-14 text-truncate ellipsis`}
                  style={{
                    width: "fit-content",
                    maxWidth: "-webkit-fill-available",
                  }}
                >
                  {GENRE_MAPPING[data.genre] || data.genre}
                </Badge>
              </Tooltip>
            ) : null}
            <div className="">
              {data.location && data.location.length > 0 ? (
                <Tooltip title="Location">
                  {_.map(
                    data.location,
                    (location, index) =>
                      index < 1 && (
                        <Badge
                          className="badge-soft-info ms-1  my-1 text-black me-1"
                          key={index}
                        >
                          <div className="font-size-14">
                            {locationMap[location] || location}
                          </div>
                        </Badge>
                      )
                  )}
                  {_.size(data.location) > 1 && (
                    <Badge className="badge-soft-info ms-1 my-1 text-black me-1">
                      <Tooltip
                        placement="right"
                        title={data.location
                          .slice(1, data.location.length)
                          .map(a => (
                            <div>{locationMap[a] || a}</div>
                          ))}
                      >
                        <div className="font-size-14">
                          +{_.size(data.location) - 1} more
                        </div>
                      </Tooltip>
                    </Badge>
                  )}
                </Tooltip>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
            {/* {data.lang_code ? (
              <Tooltip title="Language">
                <div>
                  <Badge
                    className={`${"badge-soft-success"} ms-1 my-1 text-black me-1 font-size-14 `}
                  >
                    {LANGUAGE_MAPPING[data.lang_code] || lang_code}
                  </Badge>
                </div>
              </Tooltip>
            ) : null} */}
            <div>
              {data.created_on && (
                <Badge
                  className={`font-size-14 badge-soft-success ms-1 p-1 my-1`}
                >
                  <Tooltip title={"Created"}>
                    <ReactTimeAgo
                      date={
                        Number.isNaN(data.created_on)
                          ? Date.parse(data.created_on)
                          : data.created_on
                      }
                      locale="en-US"
                    />
                  </Tooltip>
                </Badge>
              )}
              {data.modified_on && (
                <Badge className={`font-size-14 badge-soft-warning ms-1 p-1`}>
                  <Tooltip title="Modified">
                    <ReactTimeAgo
                      date={
                        Number.isNaN(data.modified_on)
                          ? Date.parse(data.modified_on)
                          : data.modified_on
                      }
                      locale="en-US"
                    />
                  </Tooltip>
                </Badge>
              )}
            </div>
          </CardBody>
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={data?.id}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>

            <div className="flex-fill">
              <Tooltip title="Edit">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={e => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_BROWSE_LISTINGS"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick(data)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Card>
      </React.Fragment>
    )
  }
}

function mapStateToProps(store) {
  return {
    refetch: _.get(store, "listings.refetchData"),
    locationList: _.get(store, "locationReducer.locationList"),
  }
}

export default connect(mapStateToProps)(ListingsCard)
