import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

import {
  getAdsProfiles,
  getAdsProfilesSuccess,
  getAdsConfigs,
  getAdsConfigsSuccess,
  upsertAdsProfile,
  upsertAdsProfileSuccess,
  upsertAdsConfig,
  upsertAdsConfigSuccess,
  getAdsTabs,
  getAdsTabsSuccess,
} from "../../slices/ads"

import {
  requestAdsProfiles,
  requestAdsConfigs,
  requestUpsertAdsProfile,
  requestUpsertAdsConfig,
  requestAdsTabs,
} from "../requests/ads"

function* handleGetAdsConfigs(params) {
  try {
    const data = yield call(requestAdsConfigs, params)
    yield put(getAdsConfigsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAdsProfiles(params) {
  try {
    const data = yield call(requestAdsProfiles, params)
    yield put(getAdsProfilesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertAdsProfile(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertAdsProfile, params)
    yield put(upsertAdsProfileSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertAdsConfig(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertAdsConfig, params)
    yield put(upsertAdsConfigSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAdsTabs(params) {
  try {
    const data = yield call(requestAdsTabs, params)
    yield put(getAdsTabsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getAdsProfiles.type, handleGetAdsProfiles)
  yield takeLatest(getAdsConfigs.type, handleGetAdsConfigs)
  yield takeLatest(upsertAdsProfile.type, handleUpsertAdsProfile)
  yield takeLatest(upsertAdsConfig.type, handleUpsertAdsConfig)
  yield takeLatest(getAdsTabs.type, handleGetAdsTabs)
}
