import React from "react"
import { Upload, message, Select as AntSelect } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import _ from "lodash"
import { Button } from "@mui/material"
import { Row, Col } from "reactstrap"
import ValidatorForm, {
  TextField,
  MultiSelect,
  Switch,
  ChipsInput,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { LANGUAGES } from "../../constants/uiConstants"
import "./styles.css"
import AudioTrimmer from "components/AudioPlayer/Audio"
import moment from "moment"
require("moment-duration-format")

export default class AudioUpsertForm extends ErrorBoundComponent {
  state = {
    fileList: [],
    isSponsored: false,
    formData: {},
  }
  _formatDuration = duration => {
    return moment.duration(duration, "seconds").format("mm:ss", { trim: false })
  }
  _uploadAlbumArt = fileObj => {
    if (fileObj.file) {
      this.setState({
        albumArtFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        albumArt: fileObj.file,
      })
    }
  }

  _uploadAudio = fileObj => {
    if (fileObj.file) {
      this.setState({
        audioFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        audioFile: fileObj.file,
      })
    }
  }

  _onAudioRemove = () => {
    this.setState({
      audioFileList: [],
      audioFile: null,
    })
  }

  _handleMultiSelect = value => {
    let formData = this.state.formData
    formData["playlists_info"] = value
    this.setState({ formData })
  }

  _onAlbumArtRemove = () => {
    this.setState({
      albumArtFileList: [],
      albumArt: null,
    })
  }

  _handleCategoriesOptions = values => {
    this.setState({
      categories: this._constructCategoryOptionsForLangs(values),
    })
  }

  _constructCategoryOptionsForLangs(values) {
    let categoryOptions = []
    values &&
      values.map(item => {
        let categoryBasedonLang = this.props.audioTabsBasedOnLanguage[item]
        categoryOptions.push(categoryBasedonLang)
      })
    categoryOptions = categoryOptions
      .flat()
      .reduce((prevalues, currentValue) => {
        const found = prevalues.some(
          item => item.displayName == currentValue.displayName
        )
        if (!found) {
          prevalues.push(currentValue)
        }
        return prevalues
      }, [])
    return categoryOptions
  }

  _startTime = time => {
    this.setState(prev => {
      return {
        ...prev,
        startTime: this._formatDuration(time),
        startTimeInMilliSec: time,
      }
    })
  }
  _endTime = time => {
    this.setState(prev => {
      return {
        ...prev,
        endTime: this._formatDuration(time),
        endTimeInMilliSec: time,
      }
    })
  }
  _form = () => {
    const { playList = [] } = this.props
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col sm="12" md="6">
            <TextField
              label={"Title"}
              placeholderLabel="Title"
              field={"title"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update" ? this.props.audio.title : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          {(this.props.currentUserType == 6 ||
            (this.props.currentUserType < 3 &&
              (this.props.formAction !== "update" ||
                this.props.audio.sponsored === "Y"))) && (
            <>
              <Col sm="12" md="6">
                <Switch
                  label={"Sponsored"}
                  field={"sponsored"}
                  onChange={value => this.setState({ isSponsored: value })}
                  disabled={this.props.currentUserType > 3}
                  defaultValue={
                    this.props.audio.sponsored === "Y"
                      ? true
                      : this.props.currentUserType == 6
                      ? true
                      : false
                  }
                />
              </Col>
              <Col sm="12" md="6">
                <TextField
                  label={"Sponsored Text"}
                  defaultValue={
                    this.props.formAction === "update"
                      ? this.props.audio.sponsored_text
                      : ""
                  }
                  placeholderLabel="Sponsored Text"
                  field={"sponsored_text"}
                  className="creator-form"
                />
              </Col>
            </>
          )}
          <Col sm="12" md="6">
            <MultiSelect
              label={"Languages"}
              placeholderLabel="Languages"
              field={"languages"}
              // className="creator-form"
              defaultValue={
                this.props.formAction === "update" && this.props.audio.languages
              }
              options={LANGUAGES.map(lang => {
                return { label: lang.label, value: lang.value }
              })}
              validations={["requiredArr"]}
              errors={["This field is required"]}
              onSelectValues={this._handleCategoriesOptions}
              onDeselectValues={this._handleCategoriesOptions}
            />
          </Col>
          <Col sm={12} lg={6} className="multi">
            <div style={{ paddingBottom: "5px", paddingTop: "2px" }}>
              Playlist
            </div>
            <AntSelect
              label="Playlist"
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Playlist"
              defaultValue={
                this.props.audio.playlists_info
                  ? this.props.audio.playlists_info
                  : []
              }
              onChange={this._handleMultiSelect}
              optionLabelProp="label"
              optionFilterProp="children"
              showArrow
              showSearch
              size={"large"}
              field="playlists_info"
              allowClear={true}
            >
              {playList.length > 0 &&
                playList.map(data => (
                  <selectOption
                    value={data.music_playlist_uuid}
                    label={data.title}
                  >
                    <img src={data.thumbnail} className="thumnailImage" />
                    {data.title}
                  </selectOption>
                ))}
            </AntSelect>
          </Col>
          <Col sm="12" md="6">
            <MultiSelect
              label={"Categories"}
              placeholderLabel="Categories"
              defaultValue={
                this.props.formAction === "update" &&
                this.props.audio.categories
              }
              field={"categories"}
              options={
                this.props.formAction === "update"
                  ? this._constructCategoryOptionsForLangs(
                      this.props.audio.languages
                    )
                  : this.state.categories || []
              }
              validations={["requiredArr"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Artist"}
              placeholderLabel="Artist"
              field={"artist"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio.artist
                  : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Uploaded By"}
              placeholderLabel="Uploaded By"
              disabled={this.props.formAction === "update"}
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio.uploaded_by.user_name
                  : ""
              }
              field={"uploaded_by"}
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>

          <Col sm={12} md={6}>
            <ChipsInput
              label={"Tagged Videos"}
              placeholderLabel="Tagged Videos"
              field={"tagged_videos"}
              defaultValue={this.props.audio.tagged_videos || []}
              className="creator-form"
            />
          </Col>
          <Col sm="12" md="6">
            <Row style={{ marginTop: "10px" }}>
              <Upload
                showUploadList={true}
                onRemove={this._onAlbumArtRemove}
                defaultFileList={this.state.albumArtFileList}
                fileList={this.state.albumArtFileList}
                customRequest={this._uploadAlbumArt}
              >
                <Button variant="contained" startIcon={<UploadOutlined />}>
                  Click to upload album art
                </Button>
              </Upload>
            </Row>
          </Col>
          {this.props.formAction === "update" && (
            <>
              <Col sm="12" md="6">
                <Row>
                  <AudioTrimmer
                    audio={this.props.audio}
                    startTime={this._startTime}
                    endTime={this._endTime}
                  />
                </Row>
                <Row>
                  {" "}
                  <Col className="d-flex">
                    <React.Fragment>
                      <TextField
                        label={"Audio Start Time"}
                        placeholderLabel="Audio Start Time"
                        field={"default_start_selection_ms"}
                        disabled={this.props.formAction === "update"}
                        defaultValue={
                          this.props.action === "create"
                            ? 0
                            : this.state.startTime
                        }
                        className="creator-form"
                      />
                      <TextField
                        label={"Audio End Time"}
                        placeholderLabel="Audio End Time"
                        field={"default_end_selection_ms"}
                        disabled={this.props.formAction === "update"}
                        defaultValue={
                          this.props.action === "create"
                            ? 0
                            : this.state.endTime
                        }
                        className="creator-form"
                      />
                    </React.Fragment>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
        {this.props.formAction === "create" && (
          <Row style={{ marginTop: "10px" }}>
            <Upload
              showUploadList={true}
              onRemove={this._onAudioRemove}
              defaultFileList={this.state.audioFileList}
              fileList={this.state.audioFileList}
              customRequest={this._uploadAudio}
            >
              <Button variant="contained" startIcon={<UploadOutlined />}>
                Click to upload audio file
              </Button>
            </Upload>
          </Row>
        )}

        <div className="d-flex justify-content-end pb-2 pt-2">
          <Button
            variant="contained"
            type="submit"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            {this.props.formAction === "create" ? "Create" : "Update"}
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
            onClick={() => this.props.onCancel()}
            className="mx-2"
          >
            Cancel
          </Button>
        </div>
      </ValidatorForm>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (
      this.state.formAction === "create" &&
      !this.state.audioFile &&
      !this.state.albumArt
    ) {
      message.error("Please upload the required files")
    }

    let data = new FormData()

    if (
      this.props.currentUserType == 6 ||
      (this.props.currentUserType < 3 && formData.hasOwnProperty("sponsored"))
    ) {
      formData.sponsored = formData.sponsored ? "Y" : "N"
    }

    if (this.props.formAction === "create") {
      data.append("audio_file", this.state.audioFile)
      delete formData.default_start_selection_ms
      delete formData.default_end_selection_ms
    }

    if (this.state.albumArt) {
      data.append("album_art_file", this.state.albumArt)
    }

    if (this.state.formData["playlists_info"]) {
      formData.playlists_info = this.state.formData["playlists_info"]
    }

    if (this.props.formAction === "update") {
      formData.id = this.props.audio.id
      formData.action = "UPDATE_AUDIO"
      //changing audio startTime and End Time to milliseconds
      formData.default_start_selection_ms = Math.ceil(
        this.state.startTimeInMilliSec * 1000
      )
      formData.default_end_selection_ms = Math.ceil(
        this.state.endTimeInMilliSec * 1000
      )
    }

    data.append("details", JSON.stringify(formData))
    this.setState({
      actionLoading: true,
    })
    this.props.onSubmit(data)
  }

  _render = () => {
    return this._form()
  }
}
