import { Pagination, Tag } from "antd"
import { getSocial, resetState } from "appRedux/slices/social"
import { getAllZones } from "appRedux/slices/zone"
import { bulkUploadSocialDeactivate } from "clientServices/soicalService"
import Chips from "components/CommonFilter/chips"
import _ from "lodash"
import QueryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import { Card, Button, Col, Row, CardBody } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { socialFilters } from "../../constants/uiConstants"
import Auxiliary from "../../util/Auxiliary"
import SocialCard from "./socialCard"
import { formatNumber } from "../../util/common"

class BrowseSocial extends ErrorBoundComponent {
  state = {
    filters: {
      socialId: this.props.queryParams
        ? this.props.queryParams["socialId"]
        : "",
      title: this.props.queryParams ? this.props.queryParams["title"] : "",
      userUUID: this.props.queryParams
        ? this.props.queryParams["userUUID"]
        : "",
      embedType: this.props.queryParams
        ? this.props.queryParams["embedType"]
        : "",
      inputZone: this.props.queryParams
        ? this.props.queryParams["inputZone"]
        : "",
      activeZone: this.props.queryParams
        ? this.props.queryParams["activeZone"]
        : "",
      status: this.props.queryParams ? this.props.queryParams["hashtag"] : "",
      ce_type: this.props.queryParams ? this.props.queryParams["ce_type"] : "",
      langCode: this.props.queryParams
        ? this.props.queryParams["langCode"]
        : "",
      isActive: this.props.queryParams
        ? this.props.queryParams["isActive"]
        : "",
      format: this.props.queryParams ? this.props.queryParams["format"] : "",
      currentPage: this.props.queryParams
        ? this.props.queryParams["currentPage"]
        : 1,
      pageSize: this.props.pageSize ? this.props.queryParams["pageSize"] : 10,
    },
    showModal: false,
    showUploadModal: false,
    action: "create",
    showFilters: false,
    showAdvancedFilters: false,
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      let filters = _.cloneDeep(this.state.filters)
      _.map(this.state.filters, (filter, key) => {
        if (!filter) {
          _.unset(filters, key)
        }
      })
      this.props.dispatch(getSocial(filters))
      this.props.dispatch(getAllZones({ currentPage: 1, pageSize: 10000 }))
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _onSearch = () => {
    let filters = _.cloneDeep(this.state.filters)
    _.map(this.state.filters, (filter, key) => {
      if (!filter) {
        _.unset(filters, key)
      }
    })
    this.props.history.push(`/social/browse?${QueryString.stringify(filters)}`)
    this.props.dispatch(getSocial(filters))
  }

  _closeFilters = () => {
    this._onSearch()
    this.setState({ showFilters: false, showAdvancedFilters: false })
  }

  _handleFilterChange = (value, type) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: socialFilters[filter].defaultValue,
      showFilters: false,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/social/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        let filters = _.cloneDeep(this.state.filters)
      }
    )
  }
  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }
  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _renderFilters = () => {
    const allZoneList = []
    this.props?.allZoneList.map(zone => {
      if (zone && zone.zone_uuid) {
        allZoneList.push({
          ...zone,
          value: zone.zone_uuid,
          label: zone.title || zone.sub_title,
        })
      }
    })
    return (
      <Chips
        type="social"
        showFilters={this.state.showFilters}
        showAdvancedFilters={this.state.showAdvancedFilters}
        filters={this.state.filters}
        search={this._closeFilters}
        removeFilter={this._removeFilter}
        handleFilterChange={this._handleFilterChange}
        toggleFilters={this._toggleFilters}
        toggleAdvancedFilters={this._toggleAdvancedFilters}
        allZoneList={allZoneList}
      />
    )
  }
  _showSocialDetails = () => {
    return (
      <React.Fragment>
        <Col lg={this.state.showForm ? "7" : "12"}>
          <div className="trending-card-container">
            {this.props.socialList.map((social, index) => {
              return (
                <SocialCard
                  social={social}
                  key={`${index}_${social.title}`}
                  editClick={(social, type) => this.editClick(social, type)}
                  currentUser={this.props.currentUser}
                  history={this.props.history}
                  onEditClick={social =>
                    this.props.history.push(
                      `/social/manage?socialUuid=${social.content_uuid}`
                    )
                  }
                />
              )
            })}
          </div>
        </Col>
      </React.Fragment>
    )
  }

  _onUpload = async () => {
    const formData = new FormData()
    formData.append("files", this.state.csvFile)
    await bulkUploadSocialDeactivate(formData)
    this.setState({ showBulkUploadForm: false })
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._onSearch()
      }
    )
  }

  _pagination = () => {
    return (
      <div className="d-flex justify-challenge-end">
        <Pagination
          style={{ marginTop: "1rem" }}
          onChange={this._onPageChange}
          current={parseInt(this.state.filters["currentPage"], 10)}
          defaultCurrent={parseInt(this.state.filters["currentPage"], 10)}
          total={this.props.total > 10000 ? 10000 : this.props.total}
          showSizeChanger={false}
        />
      </div>
    )
  }

  _bulkUploadForm = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="6">
              <label className="col-form-label">Upload CSV File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this.setState({
                    csvFile: e.target.files[0],
                  })
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm="12">
              <div className="d-flex justify-content-end">
                <Button
                  className="font-16 btn-block btn btn-primary"
                  color="primary"
                  onClick={this._onUpload}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="danger"
                  size="default"
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showBulkUploadForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  toggleUploadBulk = () => {
    if (
      this.props.currentUser?.jotRoles?.includes("ADMIN") ||
      this.props.currentUser?.jotRoles?.includes("SUPER_ADMIN")
    ) {
      this.setState({ showBulkUploadForm: true })
      return
    }

    toastr.error(NO_PERMISSION_MESSAGE)
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._renderFilters()}
        <div className="d-flex justify-content-end mb-3">
          <button
            className="btn btn-primary"
            onClick={() => {
              this.props.dispatch(resetState())
              this.props.history.push(`/social/manage`)
            }}
          >
            Create
          </button>
          <button
            color="primary"
            className="font-16 btn-block btn btn-primary mx-1"
            onClick={this.toggleUploadBulk}
          >
            <i className="fas fa-arrow-up me-1" />
            Upload
          </button>
        </div>
        {this.state.showBulkUploadForm && this._bulkUploadForm()}

        <Row>
          {this.props.total &&
            this.props.socialList &&
            this.props.socialList.length > 0 && (
              <div style={{ textAlign: "center" }} className="mb-2">
                <Tag color="geekblue">
                  Showing <b>{this.props.socialList.length}</b> of{" "}
                  <b>{formatNumber(this.props.total)}</b> Socials
                </Tag>
              </div>
            )}
          {!_.isEmpty(this.props.socialList) && this._showSocialDetails()}
          <div className="d-flex justify-content-end mb-3">
            {this._pagination()}
          </div>
        </Row>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "social.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "social.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    socialList: _.get(store, "social.socialList"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(BrowseSocial)
