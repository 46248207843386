import React from "react"
import Play from "./Play"
import Pause from "./Pause"
import Bar from "./Bar"
import "./style.scss"

import useAudioPlayer from "./useAudioPlayer"

function Audio1(props) {
  const { audio, startTime, endTime } = props
  const {
    curTime,
    curEndTime,
    curStartTime,
    duration,
    playing,
    setPlaying,
    setcurStartTime,
    setClickedTime,
    setcurEndTime,
  } = useAudioPlayer(audio, startTime, endTime)

  return (
    <div className="player">
      <audio id="audio">
        <source src={audio.url} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className="controls">
        {playing ? (
          <Pause handleClick={() => setPlaying(false)} />
        ) : (
          <Play handleClick={() => setPlaying(true)} />
        )}
        <Bar
          curTime={curTime}
          curStartTime={curStartTime}
          curEndTime={curEndTime}
          duration={duration}
          onTimeUpdate={(time, type) =>
            type === "start"
              ? setcurStartTime(time)
              : type === "play"
              ? setClickedTime(time)
              : setcurEndTime(time)
          }
        />
      </div>
    </div>
  )
}

export default Audio1
