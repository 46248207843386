import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import { fetchError } from "appRedux/slices/common"

import {
  createLimit,
  createLimitSuccess,
  deleteLimit,
  deleteLimitSuccess,
  getLimits,
  getLimitsSuccess,
  updateLimit,
  updateLimitSuccess,
  upsertLimit,
  upsertLimitSuccess,
} from "appRedux/slices/limit"
import {
  requestCreatelimit,
  requestDeletelimit,
  requestGetlimit,
  requestUpdatelimit,
} from "../requests/limit"

function* handleGetlimits(params) {
  try {
    const data = yield call(requestGetlimit, params)
    yield put(getLimitsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCreatelimit(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestCreatelimit, params)
    yield put(createLimitSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* handleUpdatelimit(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdatelimit, params)
    yield put(updateLimitSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeletelimit(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestDeletelimit, params)
    yield put(deleteLimitSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getLimits.type, handleGetlimits)
  yield takeLatest(createLimit.type, handleCreatelimit)
  yield takeLatest(updateLimit.type, handleUpdatelimit)
  yield takeLatest(deleteLimit.type, handleDeletelimit)
}
