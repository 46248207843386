import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/logo.svg"
import logosm from "../../assets/images/favicon.ico"
import { Sidebar } from "react-pro-sidebar"

const SidebarComp = props => {
  return (
    <React.Fragment>
      {/* <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logosm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logosm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="48" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent currentUser={props.currentUser} />
        </div>
        <div className="sidebar-background"></div>
      </div> */}
      <div className="vertical-menu">
        <div
          className="navbar-brand-box"
          style={{ backgroundColor: "#0b2948" }}
        >
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logosm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logosm} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="48" />
            </span>
          </Link>
        </div>
        <Sidebar
          backgroundColor="#0b2948"
          className="h-100"
          rootStyles={{
            color: "#8ba1b7",
          }}
        >
          <SidebarContent currentUser={props.currentUser} />
        </Sidebar>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

export default withRouter(SidebarComp)
