import { Alert } from "@mui/material"
import React from "react"

function NoDataFound(props) {
  return (
    <Alert variant="filled" severity="error">
      {props.message}
    </Alert>
  )
}

export default NoDataFound
