import Axios from "axios"
import toastr from "toastr"

export const requestGetSocial = async ({ payload }) => {
  const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
  _.unset(payload, "currentPage")
  try {
    let data = await Axios.post(`/api/socials/list?offset=${offset}`, payload)
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestUpsertSocial = async ({ payload }) => {
  try {
    let data = {}
    if (payload.id) {
      data = await Axios.post(
        `/api/socials/upsert?id=${payload.id}`,
        payload.formData
      )
    } else data = await Axios.post("/api/socials/upsert", payload.formData)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetSocialDetails = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/socials/getSocialDetails?socialUuid=${payload.socialUuid}`
    )
    return data
  } catch (error) {
    throw error
  }
}
