import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import { fetchError } from "appRedux/slices/common"
import {
  requestUpsertEvent,
  requestGetEvents,
  requestUpdateEvent,
} from "../requests/events"
import {
  createEvent,
  createEventSuccess,
  getEvents,
  getEventsSuccess,
  upsertEvent,
  upsertEventSuccess,
} from "../../slices/events"

function* handleGetEvents(params) {
  try {
    const data = yield call(requestGetEvents, params)
    yield put(getEventsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertEvent(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertEvent, params)
    yield put(createEventSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateEvent(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdateEvent, params)
    yield put(upsertEventSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getEvents.type, handleGetEvents)
  yield takeLatest(createEvent.type, handleUpsertEvent)
  yield takeLatest(upsertEvent.type, handleUpdateEvent)
}
