import { createSlice } from "@reduxjs/toolkit"

const ctaSlice = createSlice({
  name: "cta",
  initialState: {
    loading: false,
    entityList: [],
    refetch: false,
    total: 0,
  },
  reducers: {
    getCta(state) {
      return {
        ...state,
        loading: true,
        refetch: false,
      }
    },
    getCtaSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        entityList: payload?.data?.data?.custom_cta || [],
        total: payload?.data?.data?.total_custom_cta || 1,
        refetch: false,
      }
    },
    upsertCta(state) {
      return {
        ...state,
        loading: true,
        refetch: false,
      }
    },
    upsertCtaSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetch: true,
      }
    },
    deleteCta(state) {
      return {
        ...state,
        loading: true,
        refetch: false,
      }
    },
    deleteCtaSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetch: true,
      }
    },
    updateCtaEntity(state) {
      return {
        ...state,
        loading: true,
        refetch: false,
      }
    },
    updateCtaEntitySuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetch: false,
      }
    },
    resetState() {
      return {
        loading: false,
        entityList: [],
        refetch: false,
        total: 0,
      }
    },
  },
})

export const {
  getCta,
  getCtaSuccess,
  upsertCta,
  upsertCtaSuccess,
  deleteCta,
  deleteCtaSuccess,
  updateCtaEntity,
  updateCtaEntitySuccess,
  resetState,
} = ctaSlice.actions

export default ctaSlice.reducer
