import { createSlice } from "@reduxjs/toolkit"

const eventsSlice = createSlice({
  name: "events",
  initialState: {
    loading: false,
    loadingActivity: false,
    eventList: [],
    refetchData: false,
    total: 0,
    issuedDetails: 0,
  },

  reducers: {
    getEvents(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getEventsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        eventList: payload.data || [],
        total: payload.total,
      }
    },
    createEvent(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createEventSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: true,
        createStatus: payload.status,
      }
    },
    upsertEvent(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertEventSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        loadingActivity: false,
        eventList: [],
        refetchData: true,
        upsertStatus: payload.status,
      }
    },
    getActivity(state) {
      return {
        ...state,
        loadingActivity: true,
      }
    },
    getActivitySuccess(state, { payload }) {
      return {
        ...state,
        loadingActivity: false,
        activityList: payload.result.data || [],
        totalActivity: payload.result.count || 0,
      }
    },
    resetState() {
      return {
        loading: false,
        loadingActivity: false,
        eventList: [],
        refetchData: false,
        total: 0,
        openIssues: 0,
      }
    },
  },
})

export const {
  createEvent,
  createEventSuccess,
  getEvents,
  getEventsSuccess,
  getActivity,
  upsertEvent,
  upsertEventSuccess,
  getActivitySuccess,
  resetState,
} = eventsSlice.actions

export default eventsSlice.reducer
