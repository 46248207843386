import Axios from "axios"
import toastr from "toastr"

export const getSourceDetails = async id => {
  try {
    let data = await Axios.get(`/api/source/details?sourceId=${id}`)
    return data.data
  } catch (error) {
    toastr.error("Error in bulk deactivating socials")
    throw error
  }
}
