import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Tabs } from "antd"
import { Button, Col, Row } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import { LANGUAGES } from "../../constants/uiConstants"
import { getLocationData } from "../../appRedux/slices/location"
import { getLanguageData } from "../../appRedux/slices/uiConstants"
import {
  getCollectionEntitiesByLanguageLocations,
  getCollectionEntities,
  resetState,
} from "../../appRedux/slices/zeroSearch"
import ValidatorForm, { MultiSelect } from "../ValidatorForm"
import CollectionEntityCard from "./collectionEntityCard"
import ReorderDefaultCollectionEntities from "./defaultOrderCollectionEntities"

const { TabPane } = Tabs

class ReorderCollectionEntities extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      showDefaultOrder: false,
      activeTab: undefined,
      languageMap: {},
      filters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10000,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        collectionId:
          this.props.queryParams && this.props.queryParams["collectionId"]
            ? this.props.queryParams["collectionId"]
            : "",
        collectionType:
          this.props.queryParams && this.props.queryParams["collectionType"]
            ? this.props.queryParams["collectionType"]
            : "",
        elementType:
          this.props.queryParams && this.props.queryParams["elementType"]
            ? this.props.queryParams["elementType"]
            : "",
        elementId:
          this.props.queryParams && this.props.queryParams["elementId"]
            ? this.props.queryParams["elementId"]
            : "",
        sortKey: "view_order",
        sortOrder: "asc",
      },
    }
  }

  componentDidMount() {
    this.props.dispatch(getLocationData())
    this.props.dispatch(getLanguageData())
    const languageMap = LANGUAGES.reduce((acc, curr) => {
      return { ...acc, [curr.value]: curr.label }
    }, {})
    if (this.props.queryParams && this.props.queryParams.collectionId) {
      this.props.dispatch(
        getCollectionEntitiesByLanguageLocations({
          collectionId: this.props.queryParams.collectionId,
        })
      )
      this.props.dispatch(getCollectionEntities({ ...this.state.filters }))
    }
    this.setState({ languageMap })
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      this.props.dispatch(
        getCollectionEntitiesByLanguageLocations({
          collectionId: this.props.queryParams.collectionId,
        })
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _renderCards = () => {
    let data = []
    Object.keys(this.props.collectionEntities).forEach(key => {
      const a = key.split("_")[0]
      const b = this.state.activeTab || this.props.tabs[0]
      if (a === b) {
        data.push({ [key]: this.props.collectionEntities[key] })
      }
    })
    return (
      <CollectionEntityCard
        locationList={this.props.locationList}
        languageMap={this.state.languageMap}
        data={data}
        collectionId={this.props.queryParams.collectionId}
        collectionType={this.props.queryParams.collectionType}
        elementType={this.props.queryParams.elementType}
        activeTab={this.state.activeTab || this.props.tabs[0]}
        {...this.props}
      />
    )
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row gutter={16}>
          <Col span={24}>
            <MultiSelect
              label={"Target Languages"}
              placeholderLabel="Target Languages"
              field={"languages"}
              className="creator-form"
              defaultValue=""
              options={LANGUAGES}
            />
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  render() {
    let modifiedArr =
      this.props.tabs && this.props.tabs.length > 0
        ? [...this.props.tabs].sort()
        : []
    if (modifiedArr.indexOf("default") > -1) {
      modifiedArr.push(modifiedArr.splice(modifiedArr.indexOf("default"), 1)[0])
    }
    const newModifiedArr = ["default_sort", ...modifiedArr]
    return (
      <Auxiliary
        loading={this.props.loading && this.props.tabs.length !== 0}
        error={_.get(this.props, "common.error")}
      >
        <React.Fragment>
          <div className="d-flex justify-content-between my-2">
            <Button
              color="primary"
              onClick={() =>
                this.props.history.push(
                  `/manage/collection/entities/list?collectionId=${
                    this.props.queryParams.collectionId
                  }&collectionType=${
                    this.props.queryParams &&
                    this.props.queryParams.collectionType
                  }&elementType=${
                    this.props.queryParams && this.props.queryParams.elementType
                  }`
                )
              }
            >
              <i className="fa fa-angle-left" /> Go Back
            </Button>
            <Button
              color="primary"
              onClick={() =>
                this.props.dispatch(
                  getCollectionEntitiesByLanguageLocations({
                    collectionId: this.props.queryParams.collectionId,
                  })
                )
              }
            >
              <i className="fa fa-undo" /> Refresh
            </Button>
          </div>
          {this.props.tabs &&
            this.props.tabs.length > 0 &&
            !this.state.showDefaultOrder && (
              <Tabs
                onChange={key => this.setState({ activeTab: key })}
                type="card"
                activeKey={
                  this.state.activeTab ||
                  (this.props.tabs &&
                    this.props.tabs.length > 0 &&
                    this.props.tabs[0])
                }
              >
                {newModifiedArr.map(tab => {
                  return (
                    <TabPane
                      tab={
                        tab === "default_sort"
                          ? "Default"
                          : (this.state.languageMap &&
                              this.state.languageMap[tab]) ||
                            "Location"
                      }
                      // tab={this.state.languageMap[tab] || "Location"}
                      key={tab}
                    >
                      <Row>
                        <Col span={24}>
                          <Row>
                            {tab === "default_sort" && (
                              <ReorderDefaultCollectionEntities
                                collectionEntities={
                                  this.props.collectionEntitiesDefault
                                }
                                hasEditPermission={this.state.hasEditPermission}
                              />
                            )}
                            {!_.isEmpty(this.props.collectionEntities) &&
                              this._renderCards(tab)}
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                  )
                })}
              </Tabs>
            )}
        </React.Fragment>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zeroSearch.totalEntities"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "zeroSearch.loading"),
    entityList: _.get(store, "zeroSearch.entities"),
    collectionEntitiesDefault: _.get(store, "zeroSearch.collectionEntities"),
    collectionEntities: _.get(
      store,
      "zeroSearch.collectionEntitiesByLanguageLocation"
    ),
    tabs: _.get(store, "zeroSearch.tabs"),
    locationList: _.get(store, "locationReducer.locationList"),
    loadingPageEntities: _.get(store, "zeroSearch.pageEntityLoading"),
    refetch: _.get(store, "zeroSearch.refetchData"),
  }
}

export default connect(mapStateToProps)(ReorderCollectionEntities)
