import React from "react"
import { withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider, Checkbox } from "antd"
import { Col, Row } from "reactstrap"
import {
  DEACTIVATE_REASONS,
  LANGUAGES,
  TARGET_CATEGORY_V2,
} from "../../constants/uiConstants"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

const { Option } = Select

const FeedEntityDrawer = props => {
  return (
    <>
      <Row className="mb-3">
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">View</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters.filterView == true}
            placeholder="Filter View"
            style={{ width: "100%" }}
            onChange={value => props.handleFilterChange(value, "filterView")}
          >
            <Option value={true}>Filter View</Option>
            <Option value={false}>App View</Option>
          </Select>
        </Col>
        {props.filters.filterView != true ? (
          <Col lg="4" md="8">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Language Code</h5>
            </Divider>
            <Select
              // mode="multiple"
              showSearch
              // allowClear={true}
              placeholder="Language Code"
              defaultValue={props.filters["langCode"]}
              onChange={value => {
                props.handleFilterChange(value, "langCode")
              }}
              style={{ width: "100%" }}
            >
              {LANGUAGES.map(lang => (
                <Option value={lang.value}>{lang.label}</Option>
              ))}
            </Select>
          </Col>
        ) : (
          <>
            <Col lg="4" md="8">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Source</h5>
              </Divider>
              <Select
                allowClear={true}
                defaultValue={props.filters.source}
                placeholder="Source"
                style={{ width: "100%" }}
                onChange={value => props.handleFilterChange(value, "source")}
              >
                <Option value="">All</Option>
                <Option value="Agent">Agent</Option>
                <Option value="AI">AI</Option>
                <Option value="Operations">Operations</Option>
                <Option value="UGC">UGC</Option>
              </Select>
            </Col>
            <Col lg="4" md="8">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">
                  Moderation Level
                </h5>
              </Divider>
              <Select
                allowClear={true}
                placeholder="Moderation level"
                defaultValue={props.filters["moderationLevel"]}
                onChange={value => {
                  props.handleFilterChange(value, "moderationLevel")
                }}
                style={{ width: "100%" }}
              >
                <Option value="">All</Option>
                <Option value="3">Auto Approved</Option>
                <Option value="0">Auto Disabled</Option>
                <Option value="2">Human Approved</Option>
                <Option value="-1">Human Disabled</Option>
                <Option value="1">Unmoderated</Option>
              </Select>
            </Col>
            <Col lg="4" md="8">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">View Count</h5>
              </Divider>
              <Input.Group compact={true}>
                <Row className="w-100">
                  <Col className="d-flex">
                    <Input
                      defaultValue={props.filters["viewsGt"]}
                      placeholder="From"
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value.trim(),
                          "viewsGt"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%" }}
                      type="number"
                    />
                    <Input
                      defaultValue={props.filters["viewsLt"]}
                      placeholder="To"
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value.trim(),
                          "viewsLt"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%" }}
                      type="number"
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Col>
            <Col lg="4" md="8">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Addition Date</h5>
              </Divider>
              <Input.Group compact={true}>
                <Row className="w-100">
                  <Col className="d-flex">
                    <DatePicker
                      defaultValue={
                        props.filters["addDateGt"]
                          ? moment(props.filters["addDateGt"], "YYYY-MM-DD")
                          : null
                      }
                      placeholder="From"
                      format="YYYY-MM-DD"
                      onChange={(moment, dateString) => {
                        props.handleFilterChange(dateString, "addDateGt")
                      }}
                    />
                    <DatePicker
                      defaultValue={
                        props.filters["addDateLt"]
                          ? moment(props.filters["addDateLt"], "YYYY-MM-DD")
                          : null
                      }
                      placeholder="To"
                      format="YYYY-MM-DD"
                      onChange={(moment, dateString) => {
                        props.handleFilterChange(dateString, "addDateLt")
                      }}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Col>
            <Col lg="4" md="8">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Order By</h5>
              </Divider>
              <Input.Group compact={true}>
                <Row className="w-100">
                  <Col className="d-flex">
                    <Select
                      allowClear={true}
                      defaultValue={props.filters["sort"]}
                      placeholder="Sort By"
                      onChange={value => {
                        props.handleFilterChange(value, "sort")
                      }}
                      style={{ width: "100%" }}
                    >
                      <Option value="created_date">Recency</Option>
                      <Option value="view_count">View Count</Option>
                    </Select>
                    <Select
                      allowClear={true}
                      defaultValue={props.filters["order"]}
                      placeholder="Order"
                      onChange={value => {
                        props.handleFilterChange(value, "order")
                      }}
                      style={{ width: "100%" }}
                    >
                      <Option value="asc">Ascending</Option>
                      <Option value="desc">Descending</Option>
                    </Select>
                  </Col>
                </Row>
              </Input.Group>
            </Col>
          </>
        )}
      </Row>
    </>
  )
}

export default withRouter(FeedEntityDrawer)
