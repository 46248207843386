import React from "react"
import { Upload, DatePicker } from "antd"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import { UploadOutlined } from "@ant-design/icons"
import ValidatorForm, { Select, TextField } from "../ValidatorForm"
import { getIconIds } from "../../appRedux/slices/icons"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import "./icons.style.css"
import moment from "moment"
import dayjs from "dayjs"

class UpdateIconForm extends ErrorBoundComponent {
  state = {
    formData: {},
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value
    this.setState({ formData })
  }

  _onRemoveFile = type => {
    switch (type) {
      case "activeIcon":
        this.setState({
          activeIconFileList: [],
          activeIconImage: null,
        })
        break
      case "inactiveIcon":
        this.setState({
          inActiveIconFileList: [],
          inActiveIconImage: null,
        })
        break
      case "individualIcon":
        this.setState({
          individualIconFileList: [],
          individualIconImage: null,
        })
        break
      case "homeInactiveIcon":
        this.setState({
          homeInactiveIconFileList: [],
          homeInactiveImage: null,
        })
        break

      default:
        break
    }
  }

  _uploadFile = (fileObj, type) => {
    if (fileObj.file) {
      switch (type) {
        case "activeIcon":
          this.setState({
            activeIconFileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            activeIconImage: fileObj.file,
          })
          break
        case "inactiveIcon":
          this.setState({
            inActiveIconFileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            inActiveIconImage: fileObj.file,
          })
          break
        case "individualIcon":
          this.setState({
            individualIconFileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            individualIconImage: fileObj.file,
          })
          break
        case "homeInactiveIcon":
          this.setState({
            homeInactiveIconFileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            homeInactiveImage: fileObj.file,
          })
          break
        default:
          return ""
      }
    }
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          {this.props.themeId === 2 && (
            <Col sm={12} lg={6}>
              <Select
                label="Icons IDs"
                placeholderLabel="Icons IDs"
                field="individualIconId"
                className="creator-form"
                onChange={value =>
                  this._handleFieldChange("individualIconId", value)
                }
                defaultValue={
                  this.props.formAction === "update"
                    ? _.get(this.props, "theme.iconId")
                    : null
                }
                options={this.props.iconsIds.map(id => ({
                  label: id,
                  value: id,
                }))}
              />
            </Col>
          )}
          {!_.isEmpty(this.props.themeAsset) && this.props.themeId !== 2 && (
            <Col
              sm={12}
              lg={4}
              style={{ marginTop: "1rem" }}
              className="d-flex flex-column"
            >
              {this.props.themeAsset && (
                <span>
                  <img
                    alt="icon"
                    src={this.props.themeAsset.active_state}
                    style={{ height: "215px", width: "200px" }}
                  />
                </span>
              )}
              <Upload
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                showUploadList={true}
                listType="picture"
                // previewFile={true}
                onRemove={() => this._onRemoveFile("activeIcon")}
                defaultFileList={this.state.activeIconFileList}
                fileList={this.state.activeIconFileList}
                customRequest={fileObj =>
                  this._uploadFile(fileObj, "activeIcon")
                }
              >
                {this.state.activeIconFileList &&
                this.state.activeIconFileList.length >= 1 ? null : (
                  <Button>
                    <UploadOutlined />
                    Click to update Active Icon1
                  </Button>
                )}
              </Upload>
            </Col>
          )}
          {!_.isEmpty(this.props.themeAsset) &&
            this.props.themeId !== 2 &&
            this.props.themeAsset.tabId !== 3 && (
              <Col
                className="d-flex flex-column"
                sm={12}
                lg={4}
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
              >
                {this.props.themeAsset && (
                  <span>
                    <img
                      alt="icon"
                      src={this.props.themeAsset.non_active_state}
                      style={{ height: "215px", width: "200px" }}
                    />
                  </span>
                )}
                <Upload
                  showUploadList={true}
                  listType="picture"
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                  // previewFile={true}
                  onRemove={() => this._onRemoveFile("inactiveIcon")}
                  defaultFileList={this.state.inActiveIconFileList}
                  fileList={this.state.inActiveIconFileList}
                  customRequest={fileObj =>
                    this._uploadFile(fileObj, "inactiveIcon")
                  }
                >
                  {this.state.inActiveIconFileList &&
                  this.state.inActiveIconFileList.length >= 1 ? null : (
                    <Button>
                      <UploadOutlined />
                      Click to update Inactive Icon
                    </Button>
                  )}
                </Upload>
              </Col>
            )}
          {!_.isEmpty(this.props.themeAsset) &&
            this.props.themeId !== 2 &&
            this.props.themeAsset.tabId !== 3 &&
            this.props.themeAsset.tabId !== 1 && (
              <Col
                className="d-flex flex-column"
                sm={12}
                lg={4}
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
              >
                {this.props.themeAsset && (
                  <span>
                    <img
                      alt="icon"
                      src={this.props.themeAsset.home_non_active_state}
                      style={{ height: "215px", width: "200px" }}
                    />
                  </span>
                )}
                <Upload
                  showUploadList={true}
                  listType="picture"
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                  // previewFile={true}
                  onRemove={() => this._onRemoveFile("homeInactiveIcon")}
                  defaultFileList={this.state.homeInactiveIconFileList}
                  fileList={this.state.homeInactiveIconFileList}
                  customRequest={fileObj =>
                    this._uploadFile(fileObj, "homeInactiveIcon")
                  }
                >
                  {this.state.homeInactiveIconFileList &&
                  this.state.homeInactiveIconFileList.length >= 1 ? null : (
                    <Button>
                      <UploadOutlined />
                      Click to update Home Inactive Icon
                    </Button>
                  )}
                </Upload>
              </Col>
            )}
          {_.isEmpty(this.props.themeAsset) && this.props.themeId !== 2 && (
            <Col
              className="d-flex flex-column"
              sm={12}
              lg={4}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              {this.props.theme && this.props.theme.iconUrl && (
                <span>
                  <img
                    alt="icon"
                    src={this.props.theme.iconUrl}
                    style={{ height: "215px", width: "200px", padding: "10px" }}
                  />
                </span>
              )}
              <Upload
                showUploadList={true}
                listType="picture"
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                // previewFile={true}
                onRemove={() => this._onRemoveFile("individualIcon")}
                defaultFileList={this.state.individualIconFileList}
                fileList={this.state.individualIconFileList}
                customRequest={fileObj =>
                  this._uploadFile(fileObj, "individualIcon")
                }
              >
                {this.state.individualIconFileList &&
                this.state.individualIconFileList.length >= 1 ? null : (
                  <Button>
                    <UploadOutlined />
                    Click to update Individual Icon
                  </Button>
                )}
              </Upload>
            </Col>
          )}
          {this.props.themeId === 1 && (
            <Col sm={12} lg={6}>
              <Select
                label="Device Type"
                placeholderLabel="Device Type"
                field="deviceType"
                className="creator-form"
                disabled={true}
                onChange={value => this._handleFieldChange("deviceType", value)}
                defaultValue={
                  this.props.formAction === "update"
                    ? this.props.selectedFilter
                    : null
                }
                options={[
                  { value: "android", label: "ANDROID" },
                  { value: "ios", label: "IOS" },
                ]}
              />
            </Col>
          )}
          {this.props.themeId === 1 && (
            <Col sm={12} lg={6}>
              <TextField
                style={{ width: "100%" }}
                label={"Max Version"}
                placeholderLabel="Max Version"
                field={"maxAppVersion"}
                defaultValue={
                  this.props.formAction === "update"
                    ? this.props?.themeAsset?.max_version
                    : ""
                }
                className="creator-form"
              />
            </Col>
          )}
          {this.props.themeId === 1 && (
            <Col sm={12} lg={6}>
              <TextField
                style={{ width: "100%" }}
                label={"Min Version"}
                placeholderLabel="Min Version"
                field={"minAppVersion"}
                defaultValue={
                  this.props.formAction === "update"
                    ? this.props?.themeAsset?.min_version
                    : ""
                }
                className="creator-form"
              />
            </Col>
          )}
          <Col sm={12} lg={6}>
            <div>
              Start Date
              <DatePicker
                defaultValue={
                  _.isEmpty(this.props.themeAsset) &&
                  this.props.theme.start_date
                    ? dayjs.unix(
                        this.props.theme.start_date / 1000,
                        "YYYY-MM-DD"
                      )
                    : !_.isEmpty(this.props.themeAsset) &&
                      this.props.themeAsset.start_date
                    ? dayjs.unix(
                        this.props.themeAsset.start_date / 1000,
                        "YYYY-MM-DD"
                      )
                    : null
                }
                format="YYYY-MM-DD"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                placeholder="Valid From"
                onChange={(date, dateString) => {
                  this._handleFieldChange(
                    "startDate",
                    !_.isEmpty(dateString)
                      ? moment(dateString).format("x")
                      : null
                  )
                }}
              />
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <div>
              End Date
              <DatePicker
                defaultValue={
                  _.isEmpty(this.props.themeAsset) && this.props.theme.end_date
                    ? dayjs.unix(this.props.theme.end_date / 1000, "YYYY-MM-DD")
                    : !_.isEmpty(this.props.themeAsset) &&
                      this.props.themeAsset.end_date
                    ? dayjs.unix(
                        this.props.themeAsset.end_date / 1000,
                        "YYYY-MM-DD"
                      )
                    : null
                }
                format="YYYY-MM-DD"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                placeholder="Valid Till"
                onChange={(date, dateString) => {
                  this._handleFieldChange(
                    "endDate",
                    !_.isEmpty(dateString)
                      ? moment(dateString).format("x")
                      : null
                  )
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} lg={12}>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                color="primary"
                size="default"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                {this.props.formAction === "update" ? "Update" : "Create"}
              </Button>
              <Button
                color="danger"
                size="default"
                disabled={this.state.actionLoading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    const { theme } = this.props
    if (errors) {
      return
    }

    const data = new FormData()
    if (!_.isEmpty(this.state.activeIconImage)) {
      data.append("activeIcon", this.state.activeIconImage)
    }

    if (!_.isEmpty(this.state.inActiveIconImage)) {
      data.append("inactiveIcon", this.state.inActiveIconImage)
    }

    if (!_.isEmpty(this.state.homeInactiveImage)) {
      data.append("homeInactiveIcon", this.state.homeInactiveImage)
    }

    if (!_.isEmpty(this.state.individualIconImage)) {
      data.append("individualIcon", this.state.individualIconImage)
    }

    Object.keys(this.state.formData).forEach(key => {
      if (
        key === "startDate" &&
        _.isEmpty(this.state.formData[key]) &&
        theme &&
        theme.start_date
      ) {
        data.append("startDate", 0)
      } else if (
        key === "endDate" &&
        _.isEmpty(this.state.formData[key]) &&
        theme &&
        theme.end_date
      ) {
        data.append("endDate", 0)
      } else if (
        this.state.formData[key] !== undefined &&
        this.state.formData[key] !== ""
      ) {
        data.append(key, this.state.formData[key])
      }
    })

    if (this.props.themeAsset && this.props.themeAsset.tabId) {
      data.append("tabId", this.props.themeAsset.tabId)
    }

    if (!_.isEmpty(formData.deviceType)) {
      data.append("deviceType", this.props.selectedFilter)
    }

    if (!_.isEmpty(formData.minAppVersion)) {
      data.append("minAppVersion", formData.minAppVersion)
    }

    if (!_.isEmpty(formData.maxAppVersion)) {
      data.append("maxAppVersion", formData.maxAppVersion)
    }

    this.props.onSubmit(data)
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    loading: _.get(store, "icons.loading"),
    iconsIds: _.get(store, "icons.iconsIds"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data.userType"),
  }
}

export default connect(mapStateToProps)(UpdateIconForm)
