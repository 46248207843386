import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import UserList from "../../components/UserList/userList"
import { SearchOutlined } from "@ant-design/icons"
import { Input, Select, Button } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { fuzzySearchUser, resetState } from "../../appRedux/slices/appUser"
import Auxiliary from "../../util/Auxiliary"
import { Card, CardBody } from "reactstrap"
import "./styles.css"
const { Option } = Select

class FuzzySearchUser extends ErrorBoundComponent {
  state = {
    selectedUser: {},
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : null,
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(fuzzySearchUser(_.deepClean(this.state.filters)))
      const filterCount = Object.keys(_.deepClean(this.state.filters)).length
      this.setState({
        filterCount,
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    this.setState({
      filters: newFilters,
    })
  }

  _searchUser = () => {
    let { filters } = this.state
    // if (filters && filters.pageSize) {
    //   delete filters.currentPage
    // }
    this.props.history.push(
      `/user/fuzzySearch?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(fuzzySearchUser(_.deepClean(this.state.filters)))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/user/fuzzySearch?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(fuzzySearchUser(_.deepClean(this.state.filters)))
      }
    )
  }

  _onInputKeydown = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      e.stopPropagation()
      this._searchUser()
    }
  }

  _filters = () => {
    return (
      <Card>
        <CardBody>
          <div className="d-flex">
            <div
              className="d-flex justify-content-start"
              style={{ width: "100%" }}
            >
              <Input
                defaultValue={this.state.filters["searchText"]}
                placeholder={"Search Value"}
                onChange={e =>
                  this._handleFilterChange(e.target.value, "searchText")
                }
                onKeyDown={this._onInputKeydown}
                allowClear={true}
                name="searchId"
                style={{ width: "50%" }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Select
                style={{ marginRight: "10px" }}
                allowClear={true}
                defaultValue={this.state.filters["pageSize"]}
                onChange={value => this._handleFilterChange(value, "pageSize")}
              >
                <Option value="10">10</Option>
                <Option value="25">25</Option>
                <Option value="50">50</Option>
                <Option value="100">100</Option>
              </Select>
              <div
                onClick={() => this._searchUser()}
                role="button"
                className="btn btn-success btn-sm mx-1 d-flex align-items-center"
              >
                <div className="mx-2">Apply</div>
                <span>
                  <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
                </span>
              </div>
              {/* <Button
                type="dashed"
                style={{
                  marginRight: "10px",
                  color: "blue",
                  borderColor: "blue",
                }}
                onClick={() => {
                  this._searchUser()
                }}
              >
                <SearchOutlined /> Search
              </Button> */}
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/user/fuzzySearch?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(fuzzySearchUser(_.deepClean(this.state.filters)))
      }
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        {this.state.showAdvancedFilter && this._drawer()}
        <UserList
          userList={this.props.userList}
          onPageChange={this._onPageChange}
          total={this.props.total || 1}
          isMobile={this.props.isMobile}
          currentPage={this.state.filters["currentPage"]}
          pageSize={this.state.filters["pageSize"]}
          manageUser={this.props.manageUser}
          currentUser={this.props.currentUser}
        />
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    userList: _.get(store, "appUser.user"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "appUser.total"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "appUser.loading"),
  }
}

export default connect(mapStateToProps)(FuzzySearchUser)
