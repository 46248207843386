import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { resetState } from "appRedux/slices/content"
import Auxiliary from "../../util/Auxiliary"
import { contentFilters } from "../../constants/uiConstants"
import EmbeddingList from "./embeddingList"
import { requestContentByFilters } from "clientServices/contentService"
import toastr from "toastr"
import { Button, Input, Popover, Select } from "antd"
import { Col, Row, Badge } from "reactstrap"
import { BiArrowToTop } from "react-icons/bi"

const { Option } = Select

const FILTER_SORT_TYPE_MAP = {
  download_count: "Download Count",
  like_count: "Like Count",
  share_count: "Share Count",
  view_count: "View Count",
  moderation_level: "Moderation Level",
  pcc_score: "PCC Score",
  fy_ctr_score: "Fy CTR Score",
  ctr_score: "CTR Score",
  created_date: "Created Date",
}

const OP_SEARCH_TYPE_MAP = {
  bt: "Between",
  eq: "Equal to",
  gt: "Greater Than",
  lt: "Less Than",
}

const ORDER_MAP = {
  asc: "Ascending",
  desc: "Descending",
}

class EmbeddingContent extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    let view = "grid"
    if (this.props.queryParams?.view && this.props.queryParams?.content_uuid) {
      view = this.props.queryParams?.view || "grid"
    }

    this.state = {
      embData: [],
      view: view || "grid",
      loading: false,
      mouseOver: false,
      open: false,
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 45,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      primaryFilter:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : "",

      filters: {
        z0clusterId:
          this.props.queryParams && this.props.queryParams["z0clusterId"]
            ? this.props.queryParams["z0clusterId"]
            : "",
        z1clusterId:
          this.props.queryParams && this.props.queryParams["z1clusterId"]
            ? this.props.queryParams["z1clusterId"]
            : "",
        groupBy:
          this.props.queryParams && this.props.queryParams["groupBy"]
            ? this.props.queryParams["groupBy"]
            : "",
        orderByField:
          this.props.queryParams && this.props.queryParams["orderByField"]
            ? this.props.queryParams["orderByField"]
            : "view_count",
        rangeArray:
          this.props.queryParams && this.props.queryParams["rangeArray"]
            ? this.props.queryParams["rangeArray"]
            : [
                {
                  rangeSearchType: "",
                  opSearchType: "",
                  rangeSearchFrom: "",
                  rangeSearchText: "",
                  rangeSearchTo: "",
                },
              ],
        sortArray:
          this.props.queryParams && this.props.queryParams["sortArray"]
            ? this.props.queryParams["sortArray"]
            : [{ orderByField: "view_count", order: "desc" }],
        order:
          this.props.queryParams && this.props.queryParams["order"]
            ? this.props.queryParams["order"]
            : "desc",
        rangeSearchType:
          this.props.queryParams && this.props.queryParams["rangeSearchType"]
            ? this.props.queryParams["rangeSearchType"]
            : null,
        opSearchType:
          this.props.queryParams && this.props.queryParams["opSearchType"]
            ? this.props.queryParams["opSearchType"]
            : null,
        rangeSearchText:
          this.props.queryParams && this.props.queryParams["rangeSearchText"]
            ? this.props.queryParams["rangeSearchText"]
            : null,
        rangeSearchFrom:
          this.props.queryParams && this.props.queryParams["rangeSearchFrom"]
            ? this.props.queryParams["rangeSearchFrom"]
            : null,
        rangeSearchTo:
          this.props.queryParams && this.props.queryParams["rangeSearchTo"]
            ? this.props.queryParams["rangeSearchTo"]
            : null,
        createdDateFrom:
          this.props.queryParams && this.props.queryParams["createdDateFrom"]
            ? this.props.queryParams["createdDateFrom"]
            : null,
        createdDateTo:
          this.props.queryParams && this.props.queryParams["createdDateTo"]
            ? this.props.queryParams["createdDateTo"]
            : null,
      },
    }
  }

  setOpen(params = true) {
    this.setState({
      open: params,
    })
  }
  setOpenFalse = () => {
    this.setState({
      open: false,
    })
  }
  async componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone({
        ...this.state.filters,
        ...{
          currentPage: 1,
          pageSize: this.state.pageSize,
        },
      })
      let embData = []

      this.setState({ loading: true })

      if (this.state.filters.z0clusterId || this.state.filters.z1clusterId) {
        embData = await requestContentByFilters(_.deepClean(filters))
      }
      // for (let p = 0; p < this.state.filters["rangeArray"].length; p++) {
      //   console.log(
      //     QueryString.stringify(
      //       _.deepClean(this.state.filters["rangeArray"][p])
      //     )
      //   )
      // }
      // console.log(this.state.filters["rangeArray"])
      // console.log(
      //   QueryString.stringify(_.deepClean(this.state.filters["rangeArray"]))
      // )
      const filterCount = Object.keys(_.deepClean(filters)).length
      let strfilter = { ...this.state.filters }
      delete strfilter.rangeArray
      delete strfilter.sortArray
      this.props.history.push(
        `/content/embedding?${QueryString.stringify(_.deepClean(strfilter))}`
      )
      this.setState({
        loading: false,
        view: "grid",
        filterCount,
        filters,
        primaryFilter: filters.sortArray
          ? filters.sortArray[0].orderByField
          : "",
        embData: embData?.data?.content || [],
      })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter") {
      return this._searchContent()
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    if (
      type === "searchText" ||
      type === "order" ||
      type === "moderationLevel" ||
      type === "langCode"
    ) {
      this.setState({
        filters: newFilters,
      })
    } else {
      this.setState({
        filters: newFilters,
      })
    }
  }
  _handleRangeFilterChange = (value, type, index) => {
    const newFilters = _.cloneDeep(this.state.filters.rangeArray)
    newFilters[index][type] = value

    this.setState({
      filters: {
        ...this.state.filters,
        rangeArray: newFilters,
      },
    })
  }
  _handleSortFilterChange = (value, type, index) => {
    const newFilters = _.cloneDeep(this.state.filters.sortArray)
    newFilters[index][type] = value

    this.setState({
      filters: {
        ...this.state.filters,
        sortArray: newFilters,
      },
    })
  }
  _searchContent = async () => {
    if (this.state.loading) {
      toastr.remove()
      return toastr.warning("Data fetching already in progress.")
    }
    let filters = _.clone({
      ...this.state.filters,
      ...{
        currentPage: 1,
        pageSize: this.state.pageSize,
      },
    })
    let embData = []
    this.setState({ loading: true })

    if (this.state.filters.z0clusterId || this.state.filters.z1clusterId) {
      embData = await requestContentByFilters(_.deepClean(filters))
    }

    const filterCount = Object.keys(_.deepClean(filters)).length
    // for(let p=0;p<this.state.filters["rangeArray"].length;p++){
    //   console.log(QueryString.stringify( _.deepClean(this.state.filters["rangeArray"][p])))
    // }

    let strfilter = { ...this.state.filters }
    delete strfilter.rangeArray
    delete strfilter.sortArray
    this.props.history.push(
      `/content/embedding?${QueryString.stringify(_.deepClean(strfilter))}`
    )

    this.setState({
      loading: false,
      isOpen: false,
      view: "grid",
      filterCount,
      currentPage: 1,
      filters,
      primaryFilter: filters.sortArray ? filters.sortArray[0].orderByField : "",
      embData: embData?.data?.content || [],
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: contentFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => this._closeFilters()
    )
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({
      showFilters: false,
    })
  }

  fetchMoreEmbData = async () => {
    let embdata = await requestContentByFilters(
      _.deepClean({
        ...this.state.filters,
        ...{
          currentPage: +this.state.currentPage + 1,
          pageSize: this.state.pageSize,
        },
      })
    )

    this.setState({
      embData: embdata.data.content,
      currentPage: this.state.currentPage + 1,
    })
  }

  addRangeFilters = () => {
    let addRangeFilter = [...this.state.filters.rangeArray]
    addRangeFilter.push({
      rangeSearchType: "",
      opSearchType: "",
      rangeSearchFrom: "",
      rangeSearchText: "",
      rangeSearchTo: "",
    })
    this.setState({
      filters: {
        ...this.state.filters,
        rangeArray: addRangeFilter,
      },
    })
  }
  addSortFilters = () => {
    let addSortFilter = [...this.state.filters.sortArray]
    addSortFilter.push({
      orderByField: "",
      order: "",
    })
    this.setState({
      filters: {
        ...this.state.filters,
        sortArray: addSortFilter,
      },
    })
  }
  deleteRangeFilter = index => {
    let addRangeFilter = [...this.state.filters.rangeArray]

    let arr = addRangeFilter.splice(index, 1)
    this.setState({
      filters: {
        ...this.state.filters,
        rangeArray: addRangeFilter,
      },
    })
  }
  deleteSortFilter = index => {
    let addSortFilter = [...this.state.filters.sortArray]
    let arr = addSortFilter.splice(index, 1)
    this.setState({
      filters: {
        ...this.state.filters,
        sortArray: addSortFilter,
      },
    })
  }
  EmbeddingDrawer = () => {
    return (
      <React.Fragment>
        <div style={{ position: "sticky", top: "70px", zIndex: "1" }}>
          {this.state.filters?.rangeArray[0]?.rangeSearchType &&
          this.state.filters?.rangeArray[0]?.opSearchType &&
          (this.state.filters?.rangeArray[0]?.rangeSearchFrom ||
            this.state.filters?.rangeArray[0]?.rangeSearchText ||
            this.state.filters?.rangeArray[0]?.rangeSearchTo) ? (
            <Row className="bg-white p-2 pt-1 pb-1 d-flex align-items-center font-weight-semibold">
              {/* rangeSearchType: "",
                  opSearchType: "",
                  rangeSearchFrom: "",
                  rangeSearchText: "",
                  rangeSearchTo: "", */}
              <Col lg="1" md="1" style={{ width: "fit-content" }}>
                {this.state.filters?.rangeArray[0]?.rangeSearchType &&
                  this.state.filters?.rangeArray[0]?.opSearchType &&
                  (this.state.filters?.rangeArray[0]?.rangeSearchFrom ||
                    this.state.filters?.rangeArray[0]?.rangeSearchText ||
                    this.state.filters?.rangeArray[0]?.rangeSearchTo) &&
                  "Filters: "}
              </Col>
              <Col lg="11" md="11">
                {this.state.filters.rangeArray.map((item, index) => {
                  if (
                    item?.rangeSearchType &&
                    item?.opSearchType &&
                    (item?.rangeSearchFrom ||
                      item?.rangeSearchText ||
                      item?.rangeSearchTo)
                  ) {
                    let result = `${
                      FILTER_SORT_TYPE_MAP[item.rangeSearchType]
                    } | ${OP_SEARCH_TYPE_MAP[item.opSearchType]}`
                    if (item.rangeSearchText) {
                      result += ` | Value: ${item.rangeSearchText}`
                    }
                    if (item.rangeSearchFrom) {
                      result += ` | From: ${item.rangeSearchFrom}`
                    }
                    if (item.rangeSearchTo) {
                      result += ` | To: ${item.rangeSearchTo}`
                    }
                    return (
                      <Badge
                        className="badge-soft-primary ms-1 mt-1 font-size-12 font-weight-semibold"
                        key={index}
                      >
                        {result}
                      </Badge>
                    )
                  }
                })}
              </Col>
            </Row>
          ) : null}
          {this.state.filters?.sortArray[0]?.orderByField &&
          this.state.filters?.sortArray[0]?.order ? (
            <Row className="bg-white p-2 pt-1 pb-1 d-flex align-items-center font-weight-semibold">
              <Col
                lg="1"
                md="1"
                style={{ width: "fit-content", marginRight: "6px" }}
              >
                {this.state.filters?.sortArray[0]?.orderByField &&
                  this.state.filters?.sortArray[0]?.order &&
                  "Sorts: "}
              </Col>
              <Col lg="11" md="11">
                {this.state.filters.sortArray.map((item, index) => {
                  if (item?.orderByField && item?.order) {
                    let result = `${
                      FILTER_SORT_TYPE_MAP[item.orderByField]
                    } | ${ORDER_MAP[item.order]}`
                    return (
                      <Badge
                        className="badge-soft-primary ms-1 mt-1 font-size-12 font-weight-semibold"
                        key={index}
                      >
                        {result}
                      </Badge>
                    )
                  }
                })}
              </Col>
            </Row>
          ) : null}
          <Row className="mb-3 bg-white p-2 d-flex align-items-center">
            <Col lg="1" md="1">
              {/* <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Z0 Cluster Id</h5>
            </Divider> */}
              <Input
                defaultValue={this.state.filters["z0clusterId"]}
                placeholder="Z0 Cluster Id"
                onChange={e =>
                  this._handleFilterChange(e.target.value.trim(), "z0clusterId")
                }
                allowClear={true}
                name="z0clusterId"
                style={{ width: "100%" }}
              />
            </Col>

            <Col lg="1" md="1">
              {/* <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Z1 Cluster Id</h5>
            </Divider> */}
              <Input
                defaultValue={this.state.filters["z1clusterId"]}
                placeholder="Z1 Cluster Id"
                onChange={e =>
                  this._handleFilterChange(e.target.value.trim(), "z1clusterId")
                }
                allowClear={true}
                name="z1clusterId"
                style={{ width: "100%" }}
              />
            </Col>
            <Col lg="1" md="1">
              <Popover
                placement="bottom"
                content={
                  <div>
                    <Input.Group compact={true}>
                      {this.state.filters.rangeArray.map((item, index) => (
                        <>
                          <Row style={{ width: "100%", marginBottom: "5px" }}>
                            <Col className="d-flex justify-content-center align-items-center">
                              <Select
                                key={index}
                                allowClear={true}
                                defaultValue={item["rangeSearchType"]}
                                placeholder="Range Search Type"
                                onChange={(value, e) => {
                                  this._handleRangeFilterChange(
                                    value,
                                    "rangeSearchType",
                                    index
                                  )
                                }}
                                onMouseDown={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                value={item["rangeSearchType"]}
                                style={{ width: "600px" }}
                              >
                                <Option value="download_count">
                                  Download Count
                                </Option>
                                <Option value="like_count">Like Count</Option>
                                <Option value="share_count">Share Count</Option>
                                <Option value="view_count">View Count</Option>
                                <Option value="moderation_level">
                                  Moderation Level
                                </Option>
                                <Option value="pcc_score">PCC Score</Option>
                                <Option value="fy_ctr_score">
                                  Fy CTR Score
                                </Option>
                                <Option value="ctr_score">CTR Score</Option>
                              </Select>
                              <Select
                                allowClear={true}
                                defaultValue={
                                  this.state.filters.rangeArray[index][
                                    "opSearchType"
                                  ]
                                }
                                placeholder="Operator"
                                value={item["opSearchType"]}
                                onChange={value => {
                                  this._handleRangeFilterChange(
                                    value,
                                    "opSearchType",
                                    index
                                  )
                                }}
                                onMouseDown={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                style={{ width: "600px" }}
                              >
                                <Option value="bt">Between</Option>
                                <Option value="eq">Equal to</Option>
                                <Option value="gt">Greater Than</Option>
                                <Option value="lt">Less Than</Option>
                              </Select>
                              {this.state.filters.rangeArray[index][
                                "opSearchType"
                              ] !== "bt" && (
                                <Input
                                  defaultValue={
                                    this.state.filters.rangeArray[index][
                                      "rangeSearchText"
                                    ]
                                  }
                                  placeholder={"Value"}
                                  onChange={e =>
                                    this._handleRangeFilterChange(
                                      e.target.value,
                                      "rangeSearchText",
                                      index
                                    )
                                  }
                                  onMouseDown={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                  }}
                                  value={item["rangeSearchText"]}
                                  allowClear={true}
                                  style={{ width: "450px" }}
                                />
                              )}
                              {this.state.filters.rangeArray[index][
                                "opSearchType"
                              ] === "bt" && (
                                <Input
                                  defaultValue={
                                    this.state.filters.rangeArray[index][
                                      "rangeSearchFrom"
                                    ]
                                  }
                                  placeholder={"From"}
                                  onChange={e => {
                                    this._handleRangeFilterChange(
                                      e.target.value,
                                      "rangeSearchFrom",
                                      index
                                    )
                                  }}
                                  value={item["rangeSearchFrom"]}
                                  onMouseDown={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                  }}
                                  allowClear={true}
                                  style={{ width: "200px" }}
                                />
                              )}
                              {this.state.filters.rangeArray[index][
                                "opSearchType"
                              ] === "bt" && (
                                <Input
                                  defaultValue={
                                    this.state.filters.rangeArray[index][
                                      "rangeSearchTo"
                                    ]
                                  }
                                  placeholder={"To"}
                                  onChange={e =>
                                    this._handleRangeFilterChange(
                                      e.target.value,
                                      "rangeSearchTo",
                                      index
                                    )
                                  }
                                  value={item["rangeSearchTo"]}
                                  onMouseDown={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                  }}
                                  allowClear={true}
                                  style={{ width: "200px" }}
                                />
                              )}
                              <i
                                class="bx bx-trash"
                                style={{
                                  fontSize: "21px",
                                  color: "#f46a6a",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.deleteRangeFilter(index)
                                }}
                              >
                                {" "}
                              </i>
                            </Col>
                          </Row>{" "}
                          <br />
                        </>
                      ))}

                      <Button type="primary" onClick={this.addRangeFilters}>
                        Add
                      </Button>

                      {/* <Button onClick={this.setOpenFalse}>close</Button> */}
                    </Input.Group>
                  </div>
                }
                title="Range Filters"
                trigger="click"
                open={this.state.open}
                // visible={this.state.open}
                onOpenChange={() => this.setOpen}
              >
                <Button type="primary">Range Filters</Button>
              </Popover>
            </Col>

            <Col lg="1" md="1">
              <Popover
                placement="bottom"
                content={
                  <div>
                    <Input.Group compact={true}>
                      {this.state.filters.sortArray.map((item, index) => (
                        <>
                          <Row style={{ width: "100%", marginBottom: "5px" }}>
                            <Col className="d-flex">
                              <Select
                                allowClear={true}
                                defaultValue={
                                  this.state.filters.sortArray["orderByField"]
                                }
                                placeholder="Order By"
                                onChange={value => {
                                  this._handleSortFilterChange(
                                    value,
                                    "orderByField",
                                    index
                                  )
                                }}
                                value={item["orderByField"]}
                                style={{ width: "100%" }}
                              >
                                <React.Fragment>
                                  <Option value="view_count">View Count</Option>
                                  <Option value="share_count">
                                    Share Count
                                  </Option>
                                  <Option value="download_count">
                                    Download Count
                                  </Option>
                                  <Option value="like_count">Like Count</Option>
                                  <Option value="moderation_level">
                                    Moderation Level
                                  </Option>
                                  <Option value="pcc_score">PCC Score</Option>
                                  <Option value="fy_ctr_score">
                                    Fy CTR Score
                                  </Option>
                                  <Option value="ctr_score">CTR Score</Option>
                                </React.Fragment>

                                <Option value="created_date">
                                  Created Date
                                </Option>
                              </Select>
                              <Select
                                allowClear={true}
                                defaultValue={
                                  this.state.filters.sortArray["order"]
                                }
                                placeholder="Order"
                                onChange={value => {
                                  this._handleSortFilterChange(
                                    value,
                                    "order",
                                    index
                                  )
                                }}
                                style={{ width: "100%" }}
                                value={item["order"]}
                              >
                                <Option value="asc">Ascending</Option>
                                <Option value="desc">Descending</Option>
                              </Select>
                              <i
                                class="bx bx-trash"
                                style={{
                                  fontSize: "21px",
                                  color: "#f46a6a",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.deleteSortFilter(index)
                                }}
                              >
                                {" "}
                              </i>
                            </Col>
                          </Row>
                          <br />
                        </>
                      ))}
                      <Button type="primary" onClick={this.addSortFilters}>
                        Add
                      </Button>
                    </Input.Group>
                  </div>
                }
                title="Sort Filters"
                trigger="click"
                open={this.state.open}
                // visible={this.state.open}
                onOpenChange={() => this.setOpen}
              >
                <Button type="primary">Sort Filters</Button>
              </Popover>
            </Col>
            {/* <Col lg="3" md="3">
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex">
                  <Select
                    allowClear={true}
                    defaultValue={this.state.filters["orderByField"]}
                    placeholder="Order By"
                    onChange={value => {
                      this._handleFilterChange(value, "orderByField")
                    }}
                    style={{ width: "100%" }}
                  >
                    <React.Fragment>
                      <Option value="view_count">View Count</Option>
                      <Option value="share_count">Share Count</Option>
                      <Option value="download_count">Download Count</Option>
                      <Option value="like_count">Like Count</Option>
                      <Option value="moderation_level">Moderation Level</Option>
                      <Option value="pcc_score">PCC Score</Option>
                      <Option value="fy_ctr_score">Fy CTR Score</Option>
                      <Option value="ctr_score">CTR Score</Option>
                    </React.Fragment>

                    <Option value="created_date">Created Date</Option>
                  </Select>
                  <Select
                    allowClear={true}
                    defaultValue={this.state.filters["order"]}
                    placeholder="Order"
                    onChange={value => {
                      this._handleFilterChange(value, "order")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="asc">Ascending</Option>
                    <Option value="desc">Descending</Option>
                  </Select>
                </Col>
              </Row>
            </Input.Group>
          </Col> */}
            <Col lg="1" md="1">
              <div
                role="button"
                to="#"
                className="btn badge btn-success font-size-14 m-1 p-2"
                onClick={() => this._searchContent()}
              >
                Apply
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const updateContentList = [...this.state.embData] || []

    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.state.view === "grid" && this.EmbeddingDrawer()}
        <EmbeddingList
          {...this.props}
          filters={this.state.filters}
          primaryFilter={this.state.primaryFilter}
          contentList={updateContentList}
          fetchMoreEmbData={this.fetchMoreEmbData}
          total={this.props.total}
          contentView={this.state.view}
          isMobile={this.props.isMobile}
          currentUser={this.props.currentUser}
          currentPage={this.state["currentPage"]}
          pageSize={this.state["pageSize"]}
          loading={this.state.loading}
        />
        <BiArrowToTop
          onMouseEnter={() => this.setState({ mouseOver: true })}
          onMouseLeave={() => this.setState({ mouseOver: false })}
          style={{
            position: "fixed",
            top: "90%",
            right: "2%",
            fontSize: "50px",
            color: "white",
            padding: "2px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          className="bg-success"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }}
        />
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    contentList: _.get(store, "content.content"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "content.total"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "content.loading"),
  }
}

export default connect(mapStateToProps)(EmbeddingContent)
