import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestStickerTabsList,
  requestStickers,
  requestDeleteStickerTab,
  requestDeleteSticker,
  requestUpsertStickerAsset,
  requestUpsertSticker,
  requestStickerSubTypeList,
  requestDeleteStickerSubType,
  requestUpsertStickerSubType,
  requestMimeTypes,
  requestAllSubTabsForTab,
  requestUpdateStickerSubTabMapping,
  requestAllStickersForTabSubTab,
  requestUpdateStickerMapping,
  requestUpdateStickerTabVersion,
  requestUpdateStickerTabOrder,
  requestUploadBulkStickers,
} from "../requests/stickers"
import {
  getStickersTabList,
  getStickersTabListSuccess,
  upsertStickerAsset,
  upsertStickerAssetSuccess,
  deleteStickerTabById,
  deleteStickerTabByIdSuccess,
  deleteStickerById,
  deleteStickerByIdSuccess,
  updateStickerTabOrder,
  updateStickerTabOrderSuccess,
  getStickersSubTypeList,
  getStickersSubTypeListSuccess,
  upsertStickerSubType,
  upsertStickerSubTypeSuccess,
  deleteStickerSubTypeById,
  deleteStickerSubTypeByIdSuccess,
  getMimeTypes,
  getMimeTypesSuccess,
  getSubTabsForTab,
  getSubTabsForTabSuccess,
  updateStickerSubTabMapping,
  updateStickerSubTabMappingSuccess,
  getStickersForSubTabsTab,
  getStickersForSubTabsTabSuccess,
  updateStickersMapping,
  updateStickersMappingSuccess,
  upsertSticker,
  upsertStickerSuccess,
  getStickers,
  getStickersSuccess,
  uploadBulkStickers,
  updateStickerTabVersion,
  updateStickerTabVersionSuccess,
} from "appRedux/slices/stickers"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

function* handleUpdateStickerTabOrderGenerator(payload) {
  try {
    const data = yield call(requestUpdateStickerTabOrder, payload)
    yield put(updateStickerTabOrderSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleStickerTabsListGenerator(payload) {
  try {
    const data = yield call(requestStickerTabsList, payload)
    yield put(getStickersTabListSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleStickersGenerator(payload) {
  try {
    const data = yield call(requestStickers, payload)
    yield put(getStickersSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteStickerTabGenerator({ payload }) {
  try {
    const data = yield call(requestDeleteStickerTab, payload)
    toastr.success("Action successfull!!")

    yield put(deleteStickerTabByIdSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleUploadBulkStickers(params) {
  try {
    const data = yield call(requestUploadBulkStickers, params)
    toastr.success(data.data)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteStickerGenerator({ payload }) {
  try {
    const data = yield call(requestDeleteSticker, payload)
    toastr.success("Action successfull!!")

    yield put(deleteStickerByIdSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleUpsertStickerAssetGenerator({ payload, params }) {
  try {
    const data = yield call(requestUpsertStickerAsset, payload, params)
    toastr.success("Action successfull!!")

    yield put(upsertStickerAssetSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleUpsertStickerGenerator({ payload }) {
  try {
    const data = yield call(requestUpsertSticker, payload)
    toastr.success("Action successfull!!")
    yield put(upsertStickerSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleStickerSubTypeListGenerator(payload) {
  try {
    const data = yield call(requestStickerSubTypeList, payload)
    yield put(getStickersSubTypeListSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteStickerSubTypeGenerator({ payload }) {
  try {
    const data = yield call(requestDeleteStickerSubType, payload)
    toastr.success("Action successfull!!")

    yield put(deleteStickerSubTypeByIdSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleUpsertStickerSubTypeGenerator({ payload, params }) {
  try {
    const data = yield call(requestUpsertStickerSubType, payload, params)
    toastr.success("Action successfull!!")

    yield put(upsertStickerSubTypeSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleMimeTypesGenerator(payload) {
  try {
    const data = yield call(requestMimeTypes, payload)
    yield put(getMimeTypesSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAllSubTabsForTabGenerator({ payload }) {
  try {
    const data = yield call(requestAllSubTabsForTab, payload)
    yield put(getSubTabsForTabSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateStickerSubTabMappingGenerator(payload) {
  try {
    const data = yield call(requestUpdateStickerSubTabMapping, payload)
    yield put(updateStickerSubTabMappingSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAllStickersForSubTabGenerator({ payload }) {
  try {
    const data = yield call(requestAllStickersForTabSubTab, payload)
    yield put(getStickersForSubTabsTabSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateStickerMappingGenerator(payload) {
  try {
    const data = yield call(requestUpdateStickerMapping, payload)
    yield put(updateStickersMappingSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateStickerTabVersionGenerator({ payload }) {
  try {
    const data = yield call(requestUpdateStickerTabVersion, payload)
    toastr.success("Action successfull!!")

    yield put(updateStickerTabVersionSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getStickersTabList.type, handleStickerTabsListGenerator)
  yield takeLatest(deleteStickerTabById.type, handleDeleteStickerTabGenerator)
  yield takeLatest(deleteStickerById.type, handleDeleteStickerGenerator)
  yield takeLatest(upsertStickerAsset.type, handleUpsertStickerAssetGenerator)
  yield takeLatest(
    getStickersSubTypeList.type,
    handleStickerSubTypeListGenerator
  )
  yield takeLatest(
    deleteStickerSubTypeById.type,
    handleDeleteStickerSubTypeGenerator
  )
  yield takeLatest(
    upsertStickerSubType.type,
    handleUpsertStickerSubTypeGenerator
  )
  yield takeLatest(getMimeTypes.type, handleMimeTypesGenerator)
  yield takeLatest(getSubTabsForTab.type, handleAllSubTabsForTabGenerator)
  yield takeLatest(
    updateStickerSubTabMapping.type,
    handleUpdateStickerSubTabMappingGenerator
  )
  yield takeLatest(
    getStickersForSubTabsTab.type,
    handleAllStickersForSubTabGenerator
  )
  yield takeLatest(
    updateStickersMapping.type,
    handleUpdateStickerMappingGenerator
  )
  yield takeLatest(upsertSticker.type, handleUpsertStickerGenerator)
  yield takeLatest(
    updateStickerTabOrder.type,
    handleUpdateStickerTabOrderGenerator
  )
  yield takeLatest(uploadBulkStickers.type, handleUploadBulkStickers)
  yield takeLatest(getStickers.type, handleStickersGenerator)
  yield takeLatest(
    updateStickerTabVersion.type,
    handleUpdateStickerTabVersionGenerator
  )
}
