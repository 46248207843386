import React from "react"
import { connect } from "react-redux"
import Auxiliary from "../../util/Auxiliary"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getSpinGiftStats } from "../../clientServices/spinConfigService"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import BootstrapTable from "react-bootstrap-table-next"
import "./style.css"

class SpinGiftStats extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      statsList: [],
      columns: [
        {
          dataField: "entity_type",
          text: "Gift Type",
          style: {
            width: "25%",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>
                  {cellContent || (cellContent == 0 ? cellContent : "-")}
                </div>
              </div>
            )
          },
        },
        {
          dataField: "amount",
          text: "Amount",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "25%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>
                  {cellContent || (cellContent == 0 ? cellContent : "-")}
                </div>
              </div>
            )
          },
        },
        {
          dataField: "jems_count",
          text: "Jems Count",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "25%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>
                  {cellContent || (cellContent == 0 ? cellContent : "-")}
                </div>
              </div>
            )
          },
        },
        {
          dataField: "entitycount",
          text: "Gift Count",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "25%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>
                  {cellContent || (cellContent == 0 ? cellContent : "-")}
                </div>
              </div>
            )
          },
        },
      ],
    }
  }

  componentDidMount = async () => {
    if (!this.props.common.error) {
      this.setState({ loading: true })
      const data = (await getSpinGiftStats()) || {}
      const statsList = data || []
      this.setState({ loading: false, statsList })
    }
  }
  render() {
    return (
      <div className="bg-white">
        <Auxiliary
          loading={this.state.loading}
          error={_.get(this.props, "common.error")}
        >
          {this.state?.statsList && this.state?.statsList?.length > 0 ? (
            <div className="m-2 p-2">
              <ToolkitProvider
                keyField="id"
                data={this.state.statsList || []}
                columns={this.state.columns}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <div className="table-responsive">
                      <BootstrapTable
                        {...toolkitProps.baseProps}
                        // {...paginationTableProps}
                        responsive
                        //   defaultSorted={defaultSorted}
                        bordered={false}
                        striped={false}
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap table-check"}
                        headerWrapperClasses={"table-light"}
                      />
                    </div>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center hero-section text-muted"
              style={{ fontStyle: "italic" }}
            >
              No data present.
            </div>
          )}
        </Auxiliary>
      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "spin.loading"),
    spinStats: _.get(store, "spin.spinStats"),
    refetch: _.get(store, "spin.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(SpinGiftStats)
