import { createSlice } from "@reduxjs/toolkit"

const joshliveSlice = createSlice({
  name: "joshlive",
  initialState: {
    roomsList: [],
    loading: false,
    total: 0,
  },
  reducers: {
    getRooms(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getRoomsSuccess(state, { payload }) {
      return {
        ...state,
        roomsList: payload.data && payload.data.data,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        loading: false,
      }
    },
    getLiveRooms(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getLiveRoomsSuccess(state, { payload }) {
      return {
        ...state,
        roomsList: payload.data && payload.data.data,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        loading: false,
      }
    },
    upsertRoom(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getJoshLiveConfig(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getJoshLiveConfigSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        liveConfig: payload.data,
      }
    },
    upsertRoomSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    shutRoom(state) {
      return {
        ...state,
        loading: true,
      }
    },
    shutRoomSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    updateJoshLiveConfig(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateJoshLiveConfigSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    resetState(state, action) {
      return {
        ...state,
        roomsList: [],
        total: 0,
        loading: false,
      }
    },
  },
})

export const {
  getRooms,
  getRoomsSuccess,
  getLiveRooms,
  getLiveRoomsSuccess,
  upsertRoom,
  upsertRoomSuccess,
  shutRoom,
  shutRoomSuccess,
  getJoshLiveConfig,
  getJoshLiveConfigSuccess,
  updateJoshLiveConfig,
  updateJoshLiveConfigSuccess,
  resetState,
} = joshliveSlice.actions

export default joshliveSlice.reducer
