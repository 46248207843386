import PropTypes, { func } from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Collapse,
  Button,
  Table,
  Badge,
  Row,
  CardTitle,
  CardImg,
  CardText,
} from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import images from "assets/images"
import { isEmpty, size, map } from "lodash"
import { Carousel, Tooltip, Tag } from "antd"
import { DivOverlay } from "react-leaflet"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import CustomScrollbars from "util/CustomScrollbars"
import ReactCardFlip from "react-card-flip"
import { formatNumber } from "../../util/common"
import ReactTimeAgo from "react-time-ago"
import "./style.css"
import { deleteImage } from "../../appRedux/slices/image"

class ImageCard extends React.Component {
  state = { isPlaying: false, isFlipped: false, isPlayedFirst: true }
  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  _getModerationStatus = moderationLevel => {
    switch (moderationLevel) {
      case 1:
        return "Unmoderated"
        break
      case 2:
        return "Human Approved"
        break
      case 3:
        return "Auto Approved"
        break
      case 0:
        return "Auto Disabled"
        break
      case -1:
        return "Human Disabled"
        break
      default:
        return "-"
    }
  }

  render() {
    const { image, customClassname = "" } = this.props
    const enableEdit =
      this.props.currentUser?.permissions?.includes("EDIT_BROWSE_IMAGE")

    const showButtonToModerator = this.props.currentUser.jotUserType !== 4

    const showDeActivateButton = image.moderation_level !== -1

    let isExternalBrandContent =
      image &&
      image.user_profile &&
      (image.user_profile.user_type === "eb" ||
        image.user_profile.user_type === "ib")

    let enableDeactivateButton =
      this.props.currentUser.permissions &&
      (isExternalBrandContent
        ? this.props.currentUser.permissions.includes(
            "CONTENT_DEACTIVATE_PERMISSION"
          ) ||
          this.props.currentUser.permissions.includes(
            "EB_CONTENT_DEACTIVATION_PERMISSION"
          )
        : this.props.currentUser.permissions.includes(
            "CONTENT_DEACTIVATE_PERMISSION"
          ))

    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection={"horizontal"}
      >
        <Card
          className={`${customClassname} text-center shadow-lg`}
          style={{
            // minHeight: "280px",
            borderRadius: "10px",
          }}
        >
          <div
            className="bg-primary bg-soft"
            onClick={() => {
              !enableEdit ? "" : this.props.onEditClick(image)
            }}
            type={!enableEdit ? "" : "button"}
          >
            <Row>
              <Col className="align-self-end" style={{ width: "100%" }}>
                {image && (image.thumbnail_url || image.image_details) ? (
                  <div>
                    {image.image_details && image.image_details.length ? (
                      <Carousel afterChange={() => {}} autoplay>
                        {image.image_details
                          .filter(img => img.orig_image)
                          .map(element => {
                            return (
                              <div>
                                <CardImg
                                  top
                                  className="img-fluid"
                                  src={element.orig_image}
                                  alt="Skote"
                                  // onClick={e => {
                                  //   e.preventDefault()
                                  //   e.stopPropagation()
                                  // }}
                                  style={{
                                    height: "455px",
                                    // width: "256px",
                                    cursor: "pointer",
                                    opacity: "0.8",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                  }}
                                />
                              </div>
                            )
                          })}
                      </Carousel>
                    ) : (
                      <CardImg
                        top
                        className="img-fluid"
                        src={image.thumbnail_url}
                        alt="Skote"
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        style={{
                          height: "455px",
                          width: "256px",
                          cursor: "pointer",
                          opacity: "0.8",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    className="py-5"
                    style={{
                      height: "455px",
                      width: "256px",
                      cursor: "pointer",
                      backgroundColor: "lightgray",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <i className="fas fa-volume-mute fa-7x py-4 text-secondary" />
                  </div>
                )}

                <div
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <img
                    src={
                      (image &&
                        image.user_profile &&
                        image.user_profile.profile_pic) ||
                      "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
                    }
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                </div>

                <div className="hashtag-lang-block">
                  {image?.tags_array?.map(tag => (
                    <div className="hashtag-lang" title="HashTag">
                      {"#" + tag}
                    </div>
                  ))}
                  <div
                    className="hashtag-lang"
                    style={{ marginLeft: "5px" }}
                    title="Lang Code"
                  >
                    {image.lang_code ? image.lang_code.toUpperCase() : ""}
                  </div>
                </div>

                {image.created_date && (
                  <div className="text-block">
                    <ReactTimeAgo
                      date={
                        Number.isNaN(image.created_date)
                          ? Date.parse(image.created_date)
                          : image.created_date
                      }
                      locale="en-US"
                    />
                  </div>
                )}

                <div className="image-text">
                  {" "}
                  {image?.content_title || "<No Title>"}
                </div>
              </Col>
            </Row>
          </div>
          <CardBody style={{ padding: ".5rem" }}>
            <div className="d-flex">
              {image &&
                (image.moderation_level || image.moderation_level === 0) && (
                  <Tooltip title="Moderation Status">
                    <Tag
                      color={
                        image.moderation_level === 1 ||
                        image.moderation_level === 3 ||
                        image.moderation_level === 0
                          ? "red"
                          : image.moderation_level === 2
                          ? "#87d068"
                          : "#108ee9"
                      }
                      style={{
                        color:
                          image.moderation_level === -1 ||
                          image.moderation_level === -2
                            ? "white"
                            : "black",
                      }}
                    >
                      {this._getModerationStatus(image.moderation_level)}
                    </Tag>
                  </Tooltip>
                )}
              {image && (
                <Tooltip title="Status">
                  <Tag>
                    {image?.is_active?.toString() == "true"
                      ? "Active"
                      : "Inactive"}
                  </Tag>
                </Tooltip>
              )}
              {image.taxonomy_meta && (
                <Tooltip title="Tagged">
                  <Tag color="red">Tagged</Tag>
                </Tooltip>
              )}
            </div>
            <div className="d-flex justify-content-around align-items-center pt-2">
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  !enableEdit ? "" : this.props.onEditClick(image)
                }}
                type={!enableEdit ? "" : "button"}
              >
                <i
                  className="bx bx-show"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {" "}
                  {formatNumber(image.view_count + image.fk_views) || 0}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  !enableEdit ? "" : this.props.onEditClick(image)
                }}
                type={!enableEdit ? "" : "button"}
              >
                <i
                  className="bx bx-like"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {" "}
                  {formatNumber(image.like_count + image.fk_likes) || 0}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  !enableEdit ? "" : this.props.onEditClick(image)
                }}
                type={!enableEdit ? "" : "button"}
              >
                <i
                  className="bx bx-share-alt"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {formatNumber(image.share_count + image.fk_shares) || 0}
                </span>
              </div>
            </div>
          </CardBody>
          {/* <CardBody style={{ padding: "1rem 0.25rem" }}>
            <Tooltip title={image.content_title}>
              <div className="font-size-16 text-truncate font-weight-semibold">
                {image.content_title}
              </div>
            </Tooltip>
            <div className="">
              <Tooltip title="Target Languages">
                {map(
                  image.target_languages,
                  (language, index) =>
                    index < 2 && (
                      <Badge className="badge-soft-info ms-1">
                        <div className="font-size-14">
                          {language.toUpperCase()}
                        </div>
                      </Badge>
                    )
                )}
                {size(image.target_languages) > 2 && (
                  <Badge className="badge-soft-success ms-1">
                    <Tooltip
                      placement="right"
                      title={image.target_languages
                        .slice(1, image.target_languages.length)
                        .map(a => (
                          <div>{a}</div>
                        ))}
                    >
                      <div className="font-size-14 p-1">
                        +{size(image.target_languages) - 2} more
                      </div>
                    </Tooltip>
                  </Badge>
                )}
              </Tooltip>
            </div>
            <div className=" my-2" style={{ height: "20px" }}>
              {image.type && (
                <Tooltip title="Type">
                  <Badge className="badge-soft-primary ms-1 font-size-13">
                    {image.type}
                  </Badge>
                </Tooltip>
              )}
              {image.created_date && (
                <Tooltip title="Created">
                  <Badge className="badge-soft-info ms-1 font-size-13">
                    <ReactTimeAgo
                      date={
                        Number.isNaN(image.created_date)
                          ? Date.parse(image.created_date)
                          : image.created_date
                      }
                      locale="en-US"
                    />
                  </Badge>
                </Tooltip>
              )}

              <Badge className="badge-soft-primary ms-1 font-size-13">
                {image.is_active ? "ACTIVE" : "INACTIVE"}
              </Badge>
            </div>
          </CardBody> */}
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={image.content_uuid}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Image ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <Tooltip title="Edit Image">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_BROWSE_IMAGE"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick(image)
                  }}
                />
              </Tooltip>
            </div>

            <div className="flex-fill">
              <Tooltip title="Edit In New Window">
                <Link
                  to={
                    enableEdit
                      ? `/image/manage?imageUuid=${image.content_uuid}`
                      : ""
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bx bx-link-external" role="button" />
                </Link>
              </Tooltip>
            </div>

            {showButtonToModerator && (
              <div className="flex-fill">
                {showDeActivateButton ? (
                  <div className="flex-fill">
                    <Tooltip title="Deactivate">
                      <i
                        className="bx bx-block"
                        role="button"
                        disabled={true}
                        onClick={() => {
                          if (enableDeactivateButton) {
                            // setContent({ ...image, moderation_level: -1 })
                            // props.deativateReasonFunc(image, "deactivate")
                            this.props.setShowRejectReasonModal(
                              "deactivate",
                              image
                            )
                          } else {
                            toastr.error(NO_PERMISSION_MESSAGE)
                          }
                        }}
                        style={{ color: "red" }}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <div className="flex-fill">
                    <Tooltip title="Activate">
                      <i
                        className="bx bx-comment-error"
                        role="button"
                        style={{ color: "green" }}
                        onClick={() => {
                          if (
                            image?.reject_reason === "copyright_content" &&
                            !this.props?.currentUser?.permissions?.includes(
                              "ACTIVATE_COPYRIGHT_CONTENT"
                            )
                          ) {
                            toastr.error("You can not activate this image !!")
                            return
                          }
                          // setContent({ ...image, moderation_level: 1 })
                          // props.contentAction(image, "activate")
                          this.props.setShowRejectReasonModal("activate", image)
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            )}

            {/* <div className="flex-fill">
              <Tooltip title="Deactivate">
                <i
                  className="bx bx-block"
                  role="button"
                  disabled={true}
                  onClick={() => this.props.handleDelete(image.content_uuid)}
                  style={{ color: "red" }}
                />
              </Tooltip>
            </div> */}

            <div className="flex-fill">
              <Tooltip title="View More">
                <i
                  className="bx bx-play"
                  role="button"
                  style={{ fontSize: "20px" }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ isFlipped: true })
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Card>
        <Card
          className={`${customClassname} text-center shadow-lg`}
          style={{
            height: "569px",
            borderRadius: "10px",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <CustomScrollbars>
            <CardBody className="px-0 py-0">
              <Row className="my-sm-2">
                <Col className="ms-2">
                  <div className="avatar-xs">
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-warning" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="bx bx-bar-chart" />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end me-2">
                  <div
                    className="avatar-xs"
                    role="button"
                    onClick={() => this.setState({ isFlipped: false })}
                  >
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-dark" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="fas fa-times-circle" />
                      </span>
                    </div>
                  </div>
                  <div></div>
                </Col>
              </Row>
              <Row>
                <div className="table-responsive">
                  <Table className="mb-0 table-striped">
                    <tbody>
                      <tr className="text-start">
                        <th scope="row">Moderation Level:</th>
                        <td className="break-word">
                          {image.moderation_level || "-"}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Source Type:</th>
                        <td>{image.src_type || "-"}</td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Active Zones:</th>
                        <td>
                          {(image.active_zones &&
                            image.active_zones.map((zone, index) => (
                              <span>
                                {zone}
                                {image.active_zones.length > index + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))) ||
                            "-"}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Input Zones:</th>
                        <td>
                          {(image.input_zones &&
                            image.input_zones.map((zone, index) => (
                              <span>
                                {zone}
                                {image.input_zones.length > index + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))) ||
                            "-"}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Image Count:</th>
                        <td>{image.image_count || "-"}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </CardBody>
          </CustomScrollbars>
        </Card>
      </ReactCardFlip>
    )
  }
}

export default ImageCard
