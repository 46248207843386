import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import ZoneMapList from "./zoneMapList"
import { getZoneByIds, getZoneList } from "clientServices/zoneService"
import Auxiliary from "../../util/Auxiliary"
import ZoneMapCard from "./zoneMapCard"

const projection = [
  "type",
  "sub_type",
  "level",
  "zone_image",
  "zone_icon",
  "title",
  "sub_title",
  "zone_uuid",
  "status",
  "lang_code",
  "zone_type",
]

class ZoneMap extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      resetState: {
        sibling_zones: this.props.sibling_zones || [],
        keyZone: this.props.zoneDetails
          ? { ...this.props.zoneDetails, iskey: true }
          : {},
        l0_zones: this.props.l0_ZoneMapResponse || [],
        l1_zones: this.props.l1_ZoneMapResponse || [],
        l2_zones: this.props.l2_ZoneMapResponse || [],
      },
      sibling_zones: this.props.sibling_zones || [],
      keyZone: this.props.zoneDetails
        ? { ...this.props.zoneDetails, iskey: true }
        : {},
      l0_zones: this.props.l0_ZoneMapResponse || [],
      l1_zones: this.props.l1_ZoneMapResponse || [],
      l2_zones: this.props.l2_ZoneMapResponse || [],
      // selectedZoneParent: {},
      // selectedZoneChild: {},
      l0_show: true,
      l1_show: true,
      l2_show: true,
      l_loading: false,
      // l1_loading: false,
      // l2_loading: false,
    }
  }

  componentDidMount() {
    if (this.state?.keyZone?.zone_uuid) {
      if (this.state?.keyZone.level == 2) {
        this.setState({
          l2_zones: [],
        })
      } else if (this.state?.keyZone.level == 1) {
        this.setState({
          l1_zones: [],
        })
      } else if (this.state?.keyZone.level == 0) {
        this.setState({
          l0_zones: [],
        })
      }
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      this.state?.keyZone?.zone_uuid &&
      this.state?.keyZone?.zone_uuid !== prevState?.keyZone?.zone_uuid &&
      !this.state?.keyZone?.isKey
    ) {
      const {
        type = [],
        sub_type = [],
        level,
        zone_uuid,
      } = this.state.keyZone || {}
      let zoneIds = []
      if (level == 2) {
        this.setState({ l_loading: true })
        if (type?.length > 0) {
          zoneIds = [...zoneIds, ...type]
        }
        if (sub_type?.length > 0) {
          zoneIds = [...zoneIds, ...sub_type]
        }
        const l0_ZoneMapResponse = []
        const l1_ZoneMapResponse = []
        const zoneMapResponse = (await getZoneByIds(zoneIds)) || []
        zoneMapResponse.forEach(zone => {
          if (zone.level == 0) {
            l0_ZoneMapResponse.push(zone)
          } else if (zone.level == 1) {
            l1_ZoneMapResponse.push(zone)
          }
        })

        const sibling_zone_list_0 = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "2",
          types: type,
          projection,
        })

        const sibling_zone_list_1 = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "2",
          subTypes: sub_type,
          projection,
        })

        let zoneIdMap = {}
        ;[...sibling_zone_list_1, ...sibling_zone_list_0].forEach(zone => {
          if (!zoneIdMap[zone.zone_uuid]) {
            zoneIdMap[zone.zone_uuid] = zone
          }
        })

        const sibling_zones = Object.values(zoneIdMap) || []

        this.setState({
          l0_zones: l0_ZoneMapResponse,
          l1_zones: l1_ZoneMapResponse,
          l2_zones: [],
          sibling_zones,
          l_loading: false,
        })
      } else if (level == 1) {
        this.setState({ l_loading: true })
        if (type?.length > 0) {
          zoneIds = [...zoneIds, ...type]
        }
        const l0_ZoneMapResponse = (await getZoneByIds(zoneIds)) || []
        const l2_ZoneMapResponse = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "2",
          subTypes: [zone_uuid],
          projection,
        })

        let siblingZoneIds = []

        l2_ZoneMapResponse.forEach(
          zone => (siblingZoneIds = [...siblingZoneIds, ...zone.sub_type])
        ) || []

        const zoneIdSet = new Set(siblingZoneIds)

        const sibling_zone_list_2 = (await getZoneByIds([...zoneIdSet])) || []

        const sibling_zone_list_0 =
          (await getZoneList({
            currentPage: 1,
            pageSize: 10000,
            level: "1",
            types: type,
            projection,
          })) || []

        let zoneIdMap = {}
        ;[...sibling_zone_list_0, ...sibling_zone_list_2].forEach(zone => {
          if (!zoneIdMap[zone.zone_uuid]) {
            zoneIdMap[zone.zone_uuid] = zone
          }
        })

        const sibling_zones = Object.values(zoneIdMap)

        this.setState({
          l2_zones: l2_ZoneMapResponse,
          l0_zones: l0_ZoneMapResponse,
          l1_zones: [],
          sibling_zones,
          l_loading: false,
        })
      } else if (level == 0) {
        this.setState({ l_loading: true })
        const l1_ZoneMapResponse = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "1",
          types: [zone_uuid],
          projection,
        })
        const l2_ZoneMapResponse = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "2",
          types: [zone_uuid],
          projection,
        })

        let zoneIds = []

        l1_ZoneMapResponse.forEach(
          zone => (zoneIds = [...zoneIds, ...zone.type])
        ) || []
        l2_ZoneMapResponse.forEach(
          zone => (zoneIds = [...zoneIds, ...zone.type])
        ) || []

        const zoneIdSet = new Set(zoneIds)

        const sibling_zones = (await getZoneByIds([...zoneIdSet])) || []

        this.setState({
          l1_zones: l1_ZoneMapResponse,
          l2_zones: l2_ZoneMapResponse,
          l0_zones: [],
          sibling_zones,
          l_loading: false,
        })
      }
    }
  }

  // setSelectedZoneParent = zone => {
  //   this.setState({ selectedZoneParent: zone })
  // }

  // setSelectedZoneChild = zone => {
  //   this.setState({ selectedZoneChild: zone })
  // }

  showMap = (level, operation) => {
    if (level == 2) {
      if (operation == "show_child") {
        this.setState({ l1_show: true })
      } else if (operation == "hide_child") {
        this.setState({ l1_show: false })
      }
    } else if (level == 1) {
      if (operation == "show_child") {
        this.setState({ l0_show: true })
      } else if (operation == "hide_child") {
        this.setState({ l0_show: false })
      } else if (operation == "show_parent") {
        this.setState({ l2_show: true })
      } else if (operation == "hide_parent") {
        this.setState({ l2_show: false })
      }
    } else if (level == 0) {
      if (operation == "show_parent") {
        this.setState({ l1_show: true })
      } else if (operation == "hide_parent") {
        this.setState({ l1_show: false })
      }
    }
  }

  setKeyZone = zone => {
    this.setState({ keyZone: zone })
  }

  showSibling = show => {
    if (show) {
      if (this.state?.keyZone?.zone_uuid) {
        // const zoneList = [this.state.keyZone]
        const zoneList = []
        this.state.sibling_zones.forEach(zone => {
          if (this.state.keyZone.zone_uuid != zone.zone_uuid) {
            zoneList.push(zone)
          }
        })

        if (this.state?.keyZone.level == 2) {
          this.setState({
            l2_zones: zoneList,
          })
        } else if (this.state?.keyZone.level == 1) {
          this.setState({
            l1_zones: zoneList,
          })
        } else if (this.state?.keyZone.level == 0) {
          this.setState({
            l0_zones: zoneList,
          })
        }
      }
    } else {
      if (this.state?.keyZone?.zone_uuid) {
        if (this.state?.keyZone.level == 2) {
          this.setState({
            l2_zones: [],
            // l2_zones: [this.state.keyZone],
          })
        } else if (this.state?.keyZone.level == 1) {
          this.setState({
            // l1_zones: [this.state.keyZone],
            l1_zones: [],
          })
        } else if (this.state?.keyZone.level == 0) {
          this.setState({
            // l0_zones: [this.state.keyZone],
            l0_zones: [],
          })
        }
      }
    }
  }

  render() {
    const { level: key_level = 0 } = this.state.keyZone

    let label_1 = ""
    let label_2 = ""
    let label_up_1 = false
    let label_down_1 = false
    let label_up_2 = false
    let label_down_2 = false

    if (key_level == 2) {
      if (this.state?.l0_zones?.length) {
        label_up_1 = true
        label_1 = "Associated L0 Zones"
      }
      if (this.state?.l1_zones?.length) {
        label_up_2 = true
        label_2 = "Associated L1 Zones"
      }
    } else if (key_level == 1) {
      if (this.state?.l0_zones?.length) {
        label_up_1 = true
        label_1 = "Associated L0 Zones"
      }
      if (this.state?.l2_zones?.length) {
        label_down_2 = true
        label_2 = "Associated L2 Zones"
      }
    } else if (key_level == 0) {
      if (this.state?.l1_zones?.length) {
        label_down_1 = true
        label_1 = "Associated L1 Zones"
      }
      if (this.state?.l2_zones?.length) {
        label_down_2 = true
        label_2 = "Associated L2 Zones"
      }
    }

    return (
      <>
        <div className="d-flex justify-content-end">
          <button
            className="btn-primary btn-right btn-rounded font-weight-semibold"
            onClick={() => {
              this.setState({
                sibling_zones: this.props.sibling_zones || [],
                keyZone: this.props.zoneDetails
                  ? { ...this.props.zoneDetails, iskey: true }
                  : {},
                l0_zones: this.props.l0_ZoneMapResponse || [],
                l1_zones: this.props.l1_ZoneMapResponse || [],
                l2_zones: this.props.l2_ZoneMapResponse || [],
              })
            }}
          >
            <i class="fa fa-undo fa-sm me-1" aria-hidden="true"></i>
            Reset
          </button>
        </div>
        <Auxiliary loading={this.state.l_loading}>
          {this.state.l0_show ? (
            <div
              className={
                this.state.l0_zones.length < 1
                  ? "d-flex justify-content-around"
                  : key_level == 0
                  ? "sibling_list"
                  : ""
              }
            >
              {key_level == 0 ? (
                <div
                  className="figure"
                  style={{
                    width: this.state.l0_zones.length > 0 ? "100%" : "20%",
                  }}
                >
                  <ZoneMapCard
                    zone={this?.state?.keyZone}
                    keyZone={this?.state?.keyZone}
                    showMap={this.showMap}
                    setKeyZone={this.setKeyZone}
                    showSibling={this.showSibling}
                    style={{
                      width: "fit-content",
                    }}
                  />
                </div>
              ) : null}
              {this.state.l0_zones.length > 0 ? (
                <ZoneMapList
                  zones={this.state.l0_zones || []}
                  keyZone={this.state.keyZone}
                  showMap={this.showMap}
                  setKeyZone={this.setKeyZone}
                  showSibling={this.showSibling}
                  level={0}
                />
              ) : null}
            </div>
          ) : null}
          {label_up_1 ? (
            <div className="d-flex justify-content-around align-items-center mt-1">
              <i
                class={`fa fa-chevron-up fa-2x`}
                style={{
                  color: "secondary",
                }}
              ></i>
            </div>
          ) : null}
          {label_1 ? <div className="level_devider">{label_1}</div> : null}
          {label_down_1 ? (
            <div className="d-flex justify-content-around align-items-center mt-1">
              <i
                class={`fa fa-chevron-down fa-2x`}
                style={{
                  color: "secondary",
                }}
              ></i>
            </div>
          ) : null}
          {this.state.l1_show ? (
            <div
              className={
                this.state.l1_zones.length < 1
                  ? "d-flex justify-content-around"
                  : key_level == 1
                  ? "sibling_list"
                  : ""
              }
            >
              {key_level == 1 ? (
                <div
                  className="figure"
                  style={{
                    width: this.state.l1_zones.length > 0 ? "100%" : "20%",
                  }}
                >
                  <ZoneMapCard
                    zone={this?.state?.keyZone}
                    keyZone={this?.state?.keyZone}
                    showMap={this.showMap}
                    setKeyZone={this.setKeyZone}
                    showSibling={this.showSibling}
                  />
                </div>
              ) : null}
              {this.state.l1_zones.length > 0 ? (
                <ZoneMapList
                  zones={this.state.l1_zones || []}
                  keyZone={this.state.keyZone}
                  showMap={this.showMap}
                  setKeyZone={this.setKeyZone}
                  showSibling={this.showSibling}
                  level={1}
                />
              ) : null}
            </div>
          ) : null}
          {label_up_2 ? (
            <div className="d-flex justify-content-around align-items-center mt-1">
              <i
                class={`fa fa-chevron-up fa-2x`}
                style={{
                  color: "secondary",
                }}
              ></i>
            </div>
          ) : null}
          {label_2 ? <div className="level_devider">{label_2}</div> : null}
          {label_down_2 ? (
            <div className="d-flex justify-content-around align-items-center mt-1">
              <i
                class={`fa fa-chevron-down fa-2x`}
                style={{
                  color: "secondary",
                }}
              ></i>
            </div>
          ) : null}
          {this.state.l2_show ? (
            <div
              className={
                this.state.l2_zones.length < 1
                  ? "d-flex justify-content-around"
                  : key_level == 2
                  ? "sibling_list"
                  : ""
              }
            >
              {key_level == 2 ? (
                <div
                  className="figure"
                  style={{
                    width: this.state.l2_zones.length > 0 ? "100%" : "20%",
                  }}
                >
                  <ZoneMapCard
                    zone={this?.state?.keyZone}
                    keyZone={this?.state?.keyZone}
                    showMap={this.showMap}
                    setKeyZone={this.setKeyZone}
                    showSibling={this.showSibling}
                    style={{
                      width: "fit-content",
                    }}
                  />
                </div>
              ) : null}
              {this.state.l2_zones.length > 0 ? (
                <ZoneMapList
                  zones={this.state.l2_zones || []}
                  keyZone={this.state.keyZone}
                  showMap={this.showMap}
                  setKeyZone={this.setKeyZone}
                  showSibling={this.showSibling}
                  level={2}
                />
              ) : null}
            </div>
          ) : null}
        </Auxiliary>
      </>
    )
  }
}

export default ZoneMap
