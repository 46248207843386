import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import { fetchError } from "appRedux/slices/common"
import {
  requestUpsertEvent,
  requestGetEvents,
  requestUpdateEvent,
} from "../requests/events"
import {
  createEvent,
  createEventSuccess,
  getEvents,
  getEventsSuccess,
  upsertEvent,
  upsertEventSuccess,
} from "../../slices/events"
import {
  deleteImage,
  deleteImageSuccess,
  getImages,
  getImagesSuccess,
  upsertImage,
  upsertImageSuccess,
} from "appRedux/slices/image"
import {
  requestDeleteImage,
  requestGetImages,
  requestUpsertImage,
} from "../requests/image"

function* handleGetImages(params) {
  try {
    const data = yield call(requestGetImages, params)
    yield put(getImagesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertImage(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertImage, params)
    yield put(upsertImageSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateEvent(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdateEvent, params)
    yield put(upsertEventSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteImage(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestDeleteImage, params)
    yield put(deleteImageSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getImages.type, handleGetImages)
  yield takeLatest(upsertImage.type, handleUpsertImage)
  yield takeLatest(upsertEvent.type, handleUpdateEvent)
  yield takeLatest(deleteImage.type, handleDeleteImage)
}
