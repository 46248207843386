import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { Shimmer } from "react-shimmer"
import {
  DEACTIVATE_REASONS,
  TARGET_CATEGORY,
  TARGET_CATEGORY_V2,
} from "../../constants/uiConstants"
import _ from "lodash"
import "./embestyle.css"
import "toastr/build/toastr.min.css"
import EmbeddingCard from "./embeddingCard"
import InfiniteScroll from "react-infinite-scroll-component"
import { getColor } from "util/common"

class EmbeddingList extends React.Component {
  constructor(props) {
    super(props)
    let contentList = []
    let counter = 0

    for (let item of this.props.contentList) {
      const content = { ...item }
      if (this.props.contentList.length > 1) {
        if (counter === 0) {
          content["prev"] = null
          content["next"] = this.props.contentList[counter + 1]
        } else if (counter < this.props.contentList.length - 1) {
          content["prev"] = this.props.contentList[counter - 1]
          content["next"] = this.props.contentList[counter + 1]
        } else {
          content["prev"] = this.props.contentList[counter - 1]
          content["next"] = null
        }
        counter++
      } else {
        content["prev"] = null
        content["next"] = null
      }
      contentList.push(content)
    }
    let selectedContent = contentList[0]

    this.state = {
      selectedContent: selectedContent,
      contentList: contentList,
      redirectUserEdit: false,
      redirectContentEdit: false,
      showActionReasonModal: false,
      deactivatReason: DEACTIVATE_REASONS,
      activateReason: TARGET_CATEGORY,
      targetCategory: TARGET_CATEGORY_V2,
      actionButtonLoading: false,
      previewTaxonomyContent: false,
      contentView: this.props.contentView || "grid",
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading) {
      return {
        contentList: [],
      }
    }
    return {
      contentList: state.contentList.concat(props.contentList),
    }
  }

  _onEditClick = (action, entity) => {
    this.setState({
      action,
      showModal: true,
      videoData: entity,
      entityId: entity.content_uuid,
    })
  }
  fetchMoreData = () => {
    this.props.fetchMoreEmbData()
  }

  render() {
    const { contentList = [] } = this.state
    const contentMap = {}
    contentList.forEach(content => {
      if (!contentMap[content?.content_uuid]) {
        contentMap[content?.content_uuid] = content
      }
    })
    const newContentList = (contentMap && Object.values(contentMap)) || []
    return (
      <>
        {!this.state.showModal && (
          <React.Fragment>
            <div>
              {!this.props.contentList.length && !this.props.loading ? (
                <div
                  className="d-flex justify-content-center hero-section text-muted text-primary"
                  style={{ fontWeight: "bold" }}
                >
                  <p className="text-danger">
                    No data available for the provided filters !
                  </p>
                </div>
              ) : (
                <InfiniteScroll
                  dataLength={newContentList.length}
                  next={this.fetchMoreData}
                  hasMore={true}
                  // scrollThreshold="500px"
                  loader={
                    <>
                      {Array(36)
                        .fill()
                        .map(i => (
                          <div class="embedding-card">
                            <Shimmer width={200} height={325} />
                          </div>
                        ))}
                    </>
                  }
                >
                  {!this.state.previewTaxonomyContent
                    ? newContentList &&
                      newContentList.length > 0 &&
                      (this.state.contentView === "grid" &&
                      this.props.contentView !== "details" ? (
                        <>
                          {" "}
                          {newContentList.map((content, index) =>
                            content && content.content_uuid ? (
                              <div className="embedding-card">
                                <EmbeddingCard
                                  content={content}
                                  key={index}
                                  borderColor={getColor(
                                    index / newContentList.length
                                  )}
                                  currentUser={this.props.currentUser}
                                  pageSource={this.props.pageSource}
                                  pageType={this.props.pageType}
                                  onEditClick={this._onEditClick}
                                  primaryFilter={this.props.primaryFilter}
                                />
                              </div>
                            ) : null
                          )}
                        </>
                      ) : (
                        <></>
                      ))
                    : ""}
                </InfiniteScroll>
              )}
            </div>
          </React.Fragment>
        )}
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    isMobile: _.get(store, "common.isMobile"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
  }
}

export default connect(mapStateToProps)(EmbeddingList)
