import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const bulkUploadImageDeactivate = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/images/bulk/deactivate`, formData)
    return data
  } catch (error) {
    toastr.error("Error in bulk deactivating images")
    throw error
  }
}

export const activateDeactivateImage = async (content, action, currentUser) => {
  try {
    let response = await Axios.post(
      `/api/images/action/${action}?contentId=${content.content_uuid}`,
      content
    )
    return response
  } catch (error) {
    if (error && error.message && error.message.includes("401")) {
      return { message: "User Not Verified!!" }
    }
    return error
  }
}

export const markTaskAsCompletedForImage = async (payload, sourceId) => {
  try {
    let data = await Axios.post(
      `/api/images/moderation/markTaskComplete?sourceId=${sourceId}`,
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const updateImage = async payload => {
  try {
    let data = await Axios.post(`/api/images/upsert`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action successful ...")
    return data
  } catch (error) {
    throw error
  }
}
