import { createSlice } from "@reduxjs/toolkit"

const commonSlice = createSlice({
  name: "common",
  initialState: {
    error: "",
    loading: false,
    message: "",
  },
  reducers: {
    fetchError(state, action) {
      return { ...state, loading: false, error: action.payload, message: "" }
    },
    resetError(state, { payload }) {
      return { ...state, error: "", message: "", loading: false }
    },
  },
})

export const { fetchError, resetError } = commonSlice.actions

export default commonSlice.reducer
