import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { DatePicker, Input, Select as AntSelect } from "antd"
import { Button, Row, Col, Card, CardBody, Table } from "reactstrap"
import ValidatorForm, { TextField, Select } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getBoostFollowStatus, resetState } from "appRedux/slices/appUser"
import Auxiliary from "../../util/Auxiliary"
import moment from "moment"
import { bulkUploadUserForBoosting } from "clientServices/appUserService"
import NoDataFound from "components/NoDataFound/noDataFound"
class BoostFollowStatus extends ErrorBoundComponent {
  state = {
    filters: {
      userId:
        this.props.queryParams && this.props.queryParams["userId"]
          ? this.props.queryParams["userId"]
          : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error && this.state.filters.userId) {
      this.props.dispatch(getBoostFollowStatus(_.deepClean(this.state.filters)))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({ filters: newFilters })
  }

  _form = () => {
    const { boostFollowMeta } = this.props
    if (!boostFollowMeta) {
      return (
        <Row className="mt-4">
          <Col lg="6">
            <NoDataFound message="No data available! Please provide the valid user ID" />
          </Col>
        </Row>
      )
    }
    return (
      <Row>
        <Col lg="6">
          <Card title="Status/Meta Details" className="mt-4">
            <CardBody>
              <div className="table-responsive" role="button">
                <Table className=" mb-0 table-striped">
                  <tbody>
                    <tr>
                      <th className="me-3 text-dark">Assigned: </th>
                      <td>{boostFollowMeta.assigned}</td>
                    </tr>
                    <tr>
                      <th className="me-3 text-dark">Pending: </th>
                      <td>{boostFollowMeta.pending}</td>
                    </tr>
                    <tr>
                      <th className="me-3 text-dark">Requested: </th>
                      <td>{boostFollowMeta.requested}</td>
                    </tr>
                    <tr>
                      <th className="me-3 text-dark">Boosting Days: </th>
                      <td>{boostFollowMeta.boostingDays}</td>
                    </tr>
                    <tr>
                      <th className="me-3 text-dark">Days Remaining: </th>
                      <td>{boostFollowMeta.daysRemaining}</td>
                    </tr>
                    <tr>
                      <th className="me-3 text-dark">Start Time: </th>
                      <td>{boostFollowMeta.startTime}</td>
                    </tr>
                    <tr>
                      <th className="me-3 text-dark">End Time: </th>
                      <td>{boostFollowMeta.endTime}</td>
                    </tr>
                    <tr>
                      <th className="me-3 text-dark">Last Processed: </th>
                      <td>{boostFollowMeta.lastProcessed}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  _onUpload = async () => {
    const formData = new FormData()
    formData.append("csvFile", this.state.csvFile)
    await bulkUploadUserForBoosting(formData)
  }

  _onContentSearch = () => {
    this.props.history.push(
      `/appuser/boost/status?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(getBoostFollowStatus(_.deepClean(this.state.filters)))
  }

  _filters = () => {
    return (
      <div className="d-flex">
        <Row style={{ flex: 1 }}>
          <Col lg="4" className="d-flex">
            <Input
              defaultValue={this.state.filters["userId"] || ""}
              type="text"
              onChange={e => this._handleFilterChange(e.target.value, "userId")}
              id="autoSizingInput"
              placeholder="User ID"
              errors={["This field is required"]}
            />
            <button
              onClick={this._onContentSearch}
              role="button"
              className="btn btn-success d-flex align-items-center ms-3"
            >
              <div className="mx-2">Apply</div>
              <span>
                <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
              </span>
            </button>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "appUser.loading"),
    boostFollowMeta: _.get(store, "appUser.boostFollowMeta"),
  }
}

export default connect(mapStateToProps)(BoostFollowStatus)
