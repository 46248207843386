import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const registerPackage = async payload => {
  try {
    toastr.info("Registration in progress !!")
    let data = await Axios.post(`/api//package/entity/register`, { ...payload })
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestGetKycStatus = async () => {
  try {
    let data = await Axios.get(`/api/pan/statusCount`)
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestGetTransactionData = async payload => {
  try {
    let data = await Axios.post(`/api/transactions/dashboard`, payload)
    return data.data
  } catch (error) {
    toastr.error("Please verify user to get data")
    throw error
  }
}

export const updateKYCStatus = async payload => {
  try {
    let data = await Axios.post(`/api/pan/kycStatusUpdate`, payload)
    toastr.success("Kyc Status updated Successfully")
    return data.data
  } catch (error) {
    throw error
  }
}

// export const reportKYCStatus = async payload => {
//   try {
//     let data = await Axios.post(`/api/pan/kycStatusUpdate`, payload)
//     toastr.success("Kyc Status updated Successfully")
//     return data.data
//   } catch (error) {
//     throw error
//   }
// }

export const reportKYCStatus = async payload => {
  try {
    // const currentPage = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const size = 10000000
    let data = await Axios.post(`/api/pan/get?size=${size}`, { ...payload })
    return data.data
  } catch (error) {
    throw error
  }
}
