import Axios from "axios"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

export const getZoneList = async payload => {
  const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
  const limit = payload["pageSize"] ? payload["pageSize"] : 10
  _.unset(payload, "currentPage")
  try {
    let { data } = await Axios.post(
      `/api/zones/list?offset=${offset}&limit=${limit}`,
      payload
    )
    return data?.result?.data ? data.result.data : []
  } catch (error) {
    throw error
  }
}

export const getZoneByIds = async payload => {
  try {
    let { data } = await Axios.post(`/api/zones/listByIds`, payload)

    return data?.result ? data.result : []
  } catch (error) {
    throw error
  }
}

export const fuzzySearchZones = async payload => {
  try {
    let { data } = await Axios.post(`/api/zones/fuzzySearch`, payload)
    return data?.result ? data.result : []
  } catch (error) {
    throw error
  }
}

export const addZoneSuggession = async payload => {
  try {
    let { data, status } = await Axios.post(
      `/api/zones/suggession/add`,
      payload
    )
    return { data, status }
  } catch (error) {
    toastr.error("Something went wrong.")
    throw error
  }
}

export const getZoneMetricsById = async payload => {
  try {
    let { data } = await Axios.get(
      `/api/zones/getZoneMetricsById?zone_id=${payload}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const upsertZone = async payload => {
  try {
    toastr.info("Action in Progress...")
    let data = await Axios.post("/api/zones/upsert", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    if (error && error.message && error.message.includes("403")) {
      toastr.error("Zone with similar names found")
      return error.message
    } else {
      toastr.error("Zone creation failed")
      return error.message
    }
  }
}
