import React, { Component } from "react"
import { Button, Chip } from "@mui/material"
import { MdAdd } from "react-icons/md"
import {
  Input,
  Select,
  DatePicker,
  Divider,
  Modal,
  Form,
  Radio,
  Tooltip,
  Space,
  Pagination,
} from "antd"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import {
  getBlipContent,
  getContentByInterestAndSubInterest,
  getInterest,
  getVideosByItemsIdsOrUserId,
  saveFilesToS3,
  updateReviewContent,
} from "clientServices/interestServices"
import { InboxOutlined } from "@ant-design/icons"
import { FaCloudUploadAlt, FaSearch } from "react-icons/fa"
import {
  MdSearch,
  MdOutlineFileUpload,
  MdOutlineArrowBackIosNew,
} from "react-icons/md"
import { BiShowAlt } from "react-icons/bi"
import { message, Upload } from "antd"
import "./manageSeed.css"
import ImportVideos from "./ImportVideos"
import VideoCard from "./Videocard"
import moment from "moment"
import Auxiliary from "util/Auxiliary"
import { CURATED_TYPE_MAPPING } from "constants/uiConstants"
import _ from "lodash"
import toastr from "toastr"
import { CopyToClipboard } from "react-copy-to-clipboard"

const { Dragger } = Upload
const { Option } = Select
const { TextArea } = Input
const { RangePicker } = DatePicker
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export default class ReviewSeed extends Component {
  state = {
    filter: {
      isServable: "servable",
      metric: "absolute",
      interests_review_status: true,
      blip_knn: 25,
      duplicate_interest_filter: "N",
    },
    formData: {},
    data: [],
    addVideosData: [],
    seedVideosSearchText: "",
    showImportVideos: false,
    fileList: [],
    blipCurrentPage: 0,
    bliploader: true,
    agencyCurrentPage: 0,
    localCurrentPage: 0,
    crawlerCurrentPage: 0,
    blipPageSize: 100,
    agencyPageSize: 20,
    localPageSize: 20,
    crawlerPageSize: 20,
  }

  props = {
    onRemove: file => {
      const index = this.state.fileList.indexOf(file)
      const newFileList = this.state.fileList.slice()
      newFileList.splice(index, 1)
      this.setState({
        fileList: newFileList,
      })
      // setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...this.state.fileList, file])
      return false
    },
    customRequest: () => {},
    fileList: this.state.fileList,
  }

  async componentDidMount() {
    const subinterestdata = await getInterest({ entity_type: "sub_interest" })

    let subintereststatus = {}
    let blipContents = {}
    for (let j = 0; j < subinterestdata?.result?.data?.length; j++) {
      subintereststatus[subinterestdata?.result?.data[j].id] = {
        status: subinterestdata?.result?.data[j].is_active,
        name: subinterestdata?.result?.data[j].name,
      }
    }
    const interestdata = await getInterest({
      entity_type: "interest",
      type: this.props.interest.type,
      mode: this.props.interest.mode,
    })
    const subinterestData = await getInterest({
      entity_type: "interest",
      id: this.props.interest.id,
    })

    this.setState({ loading: true })
    const seedContentData = await getContentByInterestAndSubInterest({
      ...this.props,
      ...{
        isServable: "servable",
        interests_review_status_seed: true,
        is_seed_video: true,
        tab: "manageSeed",
        duplicate_interest_filter: "N",
      },
    })

    this.setState({
      subInterestStatusList: subintereststatus || {},
      initialinterestData: interestdata?.result?.data || [],
      initialsubinterestData:
        subinterestData?.result?.data[0]?.subInterests || [],
      interestData: interestdata?.result?.data || [],
      subinterestData: subinterestData?.result?.data[0]?.subInterests || [],
    })

    try {
      const data = await getContentByInterestAndSubInterest({
        ...this.props,
        ...{ isServable: "servable", must_not_is_seed_video: true },
      })
      this.setState({
        filter: { ...this.state.filter, ...this.props },
        loading: false,
        data: data?.data?.result?.data,
      })
    } catch (error) {
      this.setState({ loading: false })
    }
    let blipreqcontentId = []
    for (let p = 0; p < seedContentData?.data?.result?.data?.length; p++) {
      blipreqcontentId.push({
        content_id: seedContentData.data.result.data[p]["content_uuid"],
        created_day: seedContentData.data.result.data[p]["created_day"],
      })
    }

    if (blipreqcontentId.length != 0) {
      blipContents = await getBlipContent({
        content_id: blipreqcontentId,
        count: this.state.filter["blip_knn"] || 100,
      })
    }

    this.setState({
      blipContents: blipContents.data,
      bliploader: false,
      seedContentData: seedContentData?.data?.result?.data || [],
    })
    if (this.props.showAddVideos) {
      let formData = { ...this.state.formData }
      formData = {
        ...formData,
        mode: this.state.selectedVideoDetails?.is_private,
      }
      this.setState({
        showAddVideosPopup: true,
      })
      return
    }
  }

  _handleAddVideos = type => {
    this.setState({
      showAddVideosPopup: true,
      importType: type,
    })
  }

  _handleVideoDetail = async data => {
    this.setState({
      showVideoDetailPopup: true,
      selectedVideoDetails: data,
      formData: {
        mode: this.props.interest.mode,
        type: this.props.interest.type,
        interest: this.props.interest.id,
        subinterest: this.props.subInterest,
      },
      interestData: this.state.initialinterestData || [],
      subinterestData: this.state.initialsubinterestData || [],
    })
  }
  handleFilterChange = async (val, type) => {
    let filter = { ...this.state.filter }
    filter[type] = val

    if (type == "blip_knn") {
      let blipreqcontentId = []
      let blipContents = {}
      for (let p = 0; p < this.state.seedContentData?.length; p++) {
        blipreqcontentId.push({
          content_id: this.state.seedContentData[p]["content_uuid"],
          created_day: this.state.seedContentData[p]["created_day"],
        })
      }

      if (blipreqcontentId.length != 0) {
        blipContents = await getBlipContent({
          content_id: blipreqcontentId,
          count: val || 100,
        })
      }
      this.setState({
        blipContents: blipContents.data,
      })
    } else {
      const data = await getContentByInterestAndSubInterest(filter)
      this.setState({
        filter: { ...filter, ...this.props },
        data: data?.data?.result?.data || [],
      })
    }
  }
  handleDateFilterChange = async (val, type) => {
    let filter = { ...this.state.filter }
    filter[type] = !_.isEmpty(val[0]) ? moment(val[0]).format("x") : null
    filter["createdDateTo"] = !_.isEmpty(val[1])
      ? moment(val[1]).format("x")
      : null

    const data = await getContentByInterestAndSubInterest(filter)
    this.setState({
      filter: { ...filter, ...this.props },
      data: data?.data?.result?.data || [],
    })
  }
  _renderFilters = () => {
    return (
      <Row>
        <Col lg="3" md="3">
          <Input
            // defaultValue={props.filter["audioId"]}
            placeholder={"Search Item ID"}
            onChange={e =>
              this.handleFilterChange(e.target.value, "content_uuid")
            }
            allowClear={true}
            name="content_uuid"
            size="large"
            style={{ width: "100%" }}
          />
        </Col>
        <Col lg="1">
          <Select
            placeholder="Blip KNN"
            defaultValue={this.state.filter["blip_knn"]}
            style={{ width: "100%" }}
            onChange={value => {
              this.handleFilterChange(value, "blip_knn")
              //handleNameFilter(value)
            }}
            size="large"
          >
            <Option value="25">25</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
            <Option value="125">125</Option>
            <Option value="150">150</Option>
            <Option value="200">200</Option>
          </Select>
        </Col>
        <Col lg="1">
          <Select
            placeholder="Duration"
            // defaultValue={props.filter["searchTypeArtist"]}
            style={{ width: "100%" }}
            onChange={value => {
              this.handleFilterChange(value, "duration")
              //handleNameFilter(value)
            }}
            size="large"
          >
            <Option value="">All</Option>
            <Option value="6">&lt;6sec</Option>
            <Option value="15">&lt;15sec</Option>
            <Option value="30">&lt;30sec</Option>
            <Option value="45">&lt;45sec</Option>
            <Option value="60">&gt;1min</Option>
          </Select>
        </Col>
        <Col lg="1">
          <Select
            placeholder="Metric"
            defaultValue={this.state.filter["metric"]}
            onChange={value => {
              this.handleFilterChange(value, "metric")
              //handleNameFilter(value)
            }}
            style={{ width: "100%" }}
            size="large"
          >
            <Option value="absolute">Absolute</Option>
            <Option value="vp">VP</Option>
          </Select>
        </Col>
        <Col lg="1" style={{ paddingRight: "0px" }}>
          <Select
            placeholder="Duplicate"
            defaultValue={this.state.filter["duplicate_interest_filter"]}
            onChange={value => {
              this.handleFilterChange(value, "duplicate_interest_filter")
              //handleNameFilter(value)
            }}
            style={{ width: "100%" }}
            size="large"
          >
            <Option value="Y">True</Option>
            <Option value="N">False</Option>
          </Select>
        </Col>
        <Col lg="3" md="8">
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <RangePicker
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  onChange={(date, dateString) => {
                    this.handleDateFilterChange(dateString, "createdDateFrom")
                  }}
                  size="large"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="2">
          <Select
            placeholder="Servable"
            defaultValue={this.state.filter["isServable"]}
            onChange={value => {
              this.handleFilterChange(value, "isServable")
              //handleNameFilter(value)
            }}
            size="large"
            style={{ width: "100%" }}
          >
            <Option value="">All</Option>
            <Option value="servable">Servable</Option>
            <Option value="nonServable">Non Servable</Option>
          </Select>
        </Col>
      </Row>
    )
  }

  _renderVideo = video => {
    return (
      <div
        onClick={() => {
          this._handleVideoDetail(video)
        }}
      >
        <VideoCard
          key={video.content_uuid}
          parity={video.parity}
          review={true}
          details={true}
          content={video}
          metric={this.state.filter.metric}
        ></VideoCard>
      </div>
    )
  }

  _getModerationImage = content => {
    if (content.reviewed) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/review.jpg"
    } else if (content.qc_done) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/qcDone.jpg"
    } else if (content.moderation_level) {
      switch (content.moderation_level) {
        case 2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-approved.jpg"

        case 3:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-approved.jpg"

        case -1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-disabled.jpg"

        case 0:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-disabled.jpg"

        case -2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/machine-disabled.jpg"

        case 1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/unmoderated.jpg"

        default:
          return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
      }
    } else {
      return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
    }
  }
  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    this.setState({ formData }, () => {
      console.log(this.state)
    })
  }
  activeInterest = async () => {
    if (
      this.state.formData.type == "" ||
      this.state.formData.interest == "" ||
      this.state.formData.subinterest == ""
    ) {
      toastr.error("All fields are mandatory")
      return
    }
    let contentReview = await updateReviewContent({
      content_uuid: this.state.selectedVideoDetails.content_uuid,
      active_interests: [this.state.formData.interest],
      active_sub_interests: [this.state.formData.subinterest],
      input_interests: this.state.selectedVideoDetails.input_interests
        ? [
            ...new Set([
              ...this.state.selectedVideoDetails.input_interests,
              ...[this.state.formData.interest],
            ]),
          ]
        : [this.state.formData.interest],

      input_sub_interests: this.state.selectedVideoDetails.input_sub_interests
        ? [
            ...new Set([
              ...this.state.selectedVideoDetails.input_sub_interests,
              ...[this.state.formData.subinterest],
            ]),
          ]
        : [this.state.formData.subinterest],
      is_seed_video: this.state.formData.mark_as_seed,
      interests_review_status: true,
    })
    sleep(3000)
    const data = await getContentByInterestAndSubInterest({
      ...this.props,
      ...{
        isServable: this.state.filter["isServable"],
        must_not_is_seed_video: true,
      },
    })
    this.setState({
      filter: { ...this.state.filter, ...this.props },
      loading: false,
      data: data?.data?.result?.data,
      showVideoDetailPopup: false,
    })
  }
  removeInterest = async () => {
    let contentReview = await updateReviewContent({
      content_uuid: this.state.selectedVideoDetails.content_uuid,
      interests_review_status: true,
      interest_reject_reason: this.state.interest_reject_reason,
      active_interests: [],
      active_sub_interests: [],
    })
    sleep(2000)
    const data = await getContentByInterestAndSubInterest({
      ...this.props,
      ...{
        isServable: this.state.filter["isServable"],
        must_not_is_seed_video: true,
      },
    })
    this.setState({
      filter: { ...this.state.filter, ...this.props },
      loading: false,
      data: data?.data?.result?.data,
    })
  }
  _renderVideoDetailsPopup = () => {
    return (
      <Modal
        width={1000}
        title=""
        // style={{ padding: "17px" }}
        open={this.state.showVideoDetailPopup}
        footer={null}
        onCancel={() => {
          this.setState({ showVideoDetailPopup: false })
          Modal.destroyAll()
        }}
        destroyOnClose
      >
        <Modal
          title="Reject Reason"
          open={this.state.rejectReasonPopup}
          onCancel={() => {
            this.setState({ rejectReasonPopup: false })
            Modal.destroyAll()
          }}
          onOk={() => {
            this.removeInterest()
            this.setState({
              rejectReasonPopup: false,
              showVideoDetailPopup: false,
            })
          }}
          destroyOnClose
          style={{ padding: "17px" }}
        >
          <Radio.Group
            onChange={e => {
              this.setState({
                interest_reject_reason: e.target.value,
              })
            }}
          >
            <Space direction="vertical">
              <Radio value="video_quality">Video Quality</Radio>
              <Radio value="audio_quality">Audio Quality</Radio>
              <Radio value="big_border">Big Border</Radio>
              <Radio value="text_ratio">Text Ratio</Radio>
              <Radio value="video_length">Video Length</Radio>
              <Radio value="relevance">Relevance</Radio>
            </Space>
          </Radio.Group>
          <Form layout="vertical">
            <Form.Item label="Others">
              <TextArea
                label="Others"
                size="large"
                rows={3}
                placeholder="Add Others"
                onChange={e => {
                  this.setState({
                    interest_reject_reason: e.target.value,
                  })
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Reference Seed Video"
          open={this.state.showSeedVideo}
          onCancel={() => {
            this.setState({ showSeedVideo: false })
            Modal.destroyAll()
          }}
          destroyOnClose
          style={{ padding: "17px" }}
        >
          {" "}
          <VideoCard
            height="600px"
            details={true}
            content={this.state.selectedSeedContent}
            playVideo={true}
            autoPlay={true}
          ></VideoCard>
        </Modal>
        {this.state.selectedVideoDetails && (
          <>
            {" "}
            <div className="">
              <Row style={{ marginBottom: "-15px" }}>
                <Col lg="4">
                  <VideoCard
                    height="600px"
                    details={false}
                    content={this.state.selectedVideoDetails}
                    playVideo={true}
                    autoPlay={true}
                  ></VideoCard>
                </Col>
                <Col lg="8">
                  <Row>
                    <Col lg="6">
                      <div style={{ display: "flex" }}>
                        <img
                          src={this._getModerationImage(
                            this.state.selectedVideoDetails
                          )}
                          className="me-3 rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                        <div style={{ fontSize: "11px", marginLeft: "10px" }}>
                          <b>
                            {
                              this.state.selectedVideoDetails?.user_profile
                                ?.name
                            }
                          </b>
                          <div style={{ textAlign: "initial" }}>
                            {
                              CURATED_TYPE_MAPPING[
                                this.state.selectedVideoDetails?.user_profile
                                  ?.user_type
                              ]
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4">
                      <Chip
                        color="secondary"
                        size="small"
                        label={
                          this.state.selectedVideoDetails.source === "AGENCY"
                            ? "AGENCY"
                            : "NON-AGENCY"
                        }
                      />
                    </Col>
                    <Col lg="2">
                      <CopyToClipboard
                        text={this.state.selectedVideoDetails?.content_uuid}
                        onCopy={e => {
                          toastr.success("Copied sucessfully!")
                        }}
                      >
                        <Tooltip title="Copy Content ID">
                          <i
                            className="bx bx-copy"
                            style={{ marginRight: "10px" }}
                            role="button"
                          />
                        </Tooltip>
                      </CopyToClipboard>

                      {this.state.selectedVideoDetails
                        ?.source_blip_content_id && (
                        <Tooltip title="Open seed video">
                          <Link
                            to={`/content/manage?contentUUID=${this.state.selectedVideoDetails?.source_blip_content_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "unset" }}
                          >
                            <BiShowAlt
                              style={{
                                cursor: "pointer",
                                marginBottom: "4px",
                                color: "unset",
                                marginLeft: "5px",
                              }}
                            />
                          </Link>
                        </Tooltip>
                      )}
                      {this.state.selectedVideoDetails?.duplicate_content && (
                        <Tooltip title="Open Original Video">
                          <Link
                            to={`/content/manage?contentUUID=${this.state.selectedVideoDetails?.original_content_uuid}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "unset" }}
                          >
                            <i className="bx bx-shape-circle" role="button" />
                          </Link>
                        </Tooltip>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col lg="6">
                      {" "}
                      <label>Mode</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          showSearch
                          size="large"
                          allowClear={true}
                          // placeholder="Include Languages"
                          // defaultValue={props.filter["includeLang"]}
                          onChange={value => {
                            this._handleFieldChange("mode", value)
                            this.setState({
                              interestData: [],
                              subinterestData: [],
                              formData: {
                                ...this.state.formData,
                                ...{
                                  type: "",
                                  interest: "",
                                  subinterest: "",
                                },
                              },
                            })
                          }}
                          value={this.state.formData.mode}
                          style={{ width: "100%" }}
                        >
                          <Option value="">---Choose Mode---</Option>
                          <Option value="PUBLIC">Public Mode</Option>
                          <Option value="PRIVATE">Private Mode</Option>
                        </Select>
                      </Form.Item>
                    </Col> */}
                    <Col lg="6">
                      <label>Bucket</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          showSearch
                          size="large"
                          allowClear={true}
                          // placeholder="Include Languages"
                          // defaultValue={props.filter["includeLang"]}
                          onChange={async e => {
                            this._handleFieldChange("type", e)

                            const interestdata = await getInterest({
                              entity_type: "interest",
                              type: e,
                              mode: this.state.formData.mode,
                            })
                            this.setState({
                              interestData: interestdata?.result?.data || [],
                              formData: {
                                ...this.state.formData,
                                ...{
                                  interest: "",
                                  subinterest: "",
                                },
                              },
                            })
                          }}
                          value={this.state.formData.type}
                          style={{ width: "100%" }}
                        >
                          <Option value="">---Choose Bucket---</Option>
                          <Option value="CATEGORY">Category</Option>
                          <Option value="TREND">Trend</Option>
                          <Option value="EVENT">Event</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg="6">
                      <label>Mark as Seed</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          showSearch
                          size="large"
                          allowClear={true}
                          // placeholder="Include Languages"
                          // defaultValue={props.filter["includeLang"]}
                          onChange={async e => {
                            this._handleFieldChange("mark_as_seed", e)
                          }}
                          style={{ width: "100%" }}
                        >
                          <Option value="">---Choose---</Option>
                          <Option value={true}>True</Option>
                          <Option value={false}>False</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="6">
                      {" "}
                      <label>Interest</label>
                      <Form.Item>
                        <Select
                          onChange={async e => {
                            this._handleFieldChange("interest", e)
                            const subinterestData = await getInterest({
                              entity_type: "interest",
                              id: e,
                            })
                            this.setState({
                              subinterestData:
                                subinterestData?.result?.data[0]
                                  ?.subInterests || [],
                              formData: {
                                ...this.state.formData,
                                ...{
                                  subinterest: "",
                                },
                              },
                            })
                          }}
                          size="large"
                          // placeholder="---Choose Interest---"
                          value={this.state.formData.interest}
                          style={{ width: "100%" }}
                        >
                          <Option value="">---Choose Interest---</Option>
                          {this.state.interestData?.map(item => (
                            <Option value={item.id}>{item.name}</Option>
                          ))}

                          {/* <Option value="PRIVATE">Private Mode</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg="6">
                      {" "}
                      <label>Sub-Interest</label>
                      <Form.Item>
                        <Select
                          // mode="multiple"
                          // showSearch
                          // allowClear={true}
                          // placeholder="Include Languages"
                          // defaultValue={props.filter["includeLang"]}
                          onChange={value => {
                            this._handleFieldChange("subinterest", value)
                          }}
                          value={this.state.formData.subinterest}
                          style={{ width: "100%" }}
                          size="large"
                        >
                          <Option value="">---Choose subInterest---</Option>
                          {this.state.subinterestData?.map(item => (
                            <Option value={item}>
                              {this.state.subInterestStatusList[item].name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="12">
                      <table id="tbl" cellpading="2px" width="100%">
                        <thead>
                          <th>Metrics</th>
                          <th>Real</th>
                          <th>Gamified</th>
                          <th>VP%</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Views</td>
                            <td>
                              {this.state.selectedVideoDetails?.view_count ||
                                `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails?.fk_view_count ||
                                `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails?.view_count *
                                    100) /
                                  this.state.selectedVideoDetails?.fk_view_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.view_count *
                                      100) /
                                    this.state.selectedVideoDetails
                                      ?.fk_view_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Likes</td>
                            <td>
                              {this.state.selectedVideoDetails?.like_count ||
                                `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails?.fk_like_count ||
                                `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails?.like_count *
                                    100) /
                                  this.state.selectedVideoDetails?.fk_like_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.like_count *
                                      100) /
                                    this.state.selectedVideoDetails
                                      ?.fk_like_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Shares</td>
                            <td>
                              {this.state.selectedVideoDetails?.share_count ||
                                `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.fk_share_count || `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails
                                    ?.share_count *
                                    100) /
                                  this.state.selectedVideoDetails?.fk_like_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.share_count *
                                      100) /
                                    this.state.selectedVideoDetails
                                      ?.fk_like_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Downloads</td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.download_count || `-`}
                            </td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.fk_download_count || `-`}
                            </td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails
                                    ?.download_count *
                                    100) /
                                  this.state.selectedVideoDetails?.play_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.download_count *
                                      100) /
                                    this.state.selectedVideoDetails?.play_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                          <tr>
                            <td>Comments</td>
                            <td>
                              {this.state.selectedVideoDetails
                                ?.comments_count || `-`}
                            </td>
                            <td>-</td>
                            <td>
                              {isNaN(
                                (
                                  (this.state.selectedVideoDetails
                                    ?.comments_count *
                                    100) /
                                  this.state.selectedVideoDetails?.play_count
                                ).toFixed(2)
                              )
                                ? "-"
                                : `${(
                                    (this.state.selectedVideoDetails
                                      ?.comments_count *
                                      100) /
                                    this.state.selectedVideoDetails?.play_count
                                  ).toFixed(2)}%`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="12">
                      <table id="tbl" cellpading="2px" width="100%">
                        <thead>
                          <th>Parameter</th>
                          <th>Value</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Resolution</td>
                            <td>
                              {this.state.selectedVideoDetails?.pixel_size}
                            </td>
                          </tr>
                          <tr>
                            <td>Duration</td>
                            <td>
                              {this.state.selectedVideoDetails?.video_duration
                                ? new Date(
                                    this.state.selectedVideoDetails
                                      ?.video_duration * 1000
                                  )
                                    .toISOString()
                                    .slice(14, 19)
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Bitrate</td>
                            <td>{this.state.selectedVideoDetails?.bit_rate}</td>
                          </tr>
                          <tr>
                            <td>FPS</td>
                            <td>
                              {this.state.selectedVideoDetails?.fps || `-`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="12" style={{ marginBottom: "5px" }}>
                      <Button
                        onClick={async () => {
                          this.activeInterest()
                        }}
                        style={{
                          width: "100%",
                          color: "white",
                          backgroundColor: "#0ca789",
                          borderColor: "#0ca789",
                        }}
                        variant="contained"
                        className="mx-1"
                        color="primary"
                        size="small"
                        // startIcon={<MdAdd />}
                      >
                        Approve
                      </Button>
                    </Col>

                    <Col lg="12">
                      <Button
                        onClick={() => {
                          // this.removeInterest()
                          this.setState({ rejectReasonPopup: true })
                        }}
                        style={{
                          width: "100%",
                          backgroundColor: "#f24725",
                          borderColor: "#f24725",
                          color: "white",
                        }}
                        variant="contained"
                        className="mx-1"
                        color="primary"
                        size="small"
                        // startIcon={<MdAdd />}
                      >
                        Reject
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Modal>
    )
  }

  _handleFilesUpload = ({ fileList }) => {
    // you store them in state, so that you can make a http req with them later
    this.setState({ fileList })
  }

  _handleRemoveSeed = () => {
    console.log("removed")
  }

  _handleGoBack = () => {
    this.props.handleCloseSeed()
  }

  _onPageChange = value => {
    this.setState({
      blipCurrentPage: value,
    })
  }
  _onAgencyPageChange = value => {
    this.setState({
      agencyCurrentPage: value,
    })
  }
  _onLocalPageChange = value => {
    this.setState({
      localCurrentPage: value,
    })
  }
  _onCrawlerPageChange = value => {
    this.setState({
      crawlerCurrentPage: value,
    })
  }
  render() {
    let filteredBlipContents =
      this.state?.blipContents?.length > 0
        ? _(this.state?.blipContents)
            .drop((this.state.blipCurrentPage - 1) * this.state.blipPageSize)
            .take(this.state.blipPageSize)
            .value()
        : []

    let localContents =
      this.state?.data?.filter(i => i?.source == "LOCAL_UPLOAD").length > 0
        ? _(this.state?.data?.filter(i => i?.source == "LOCAL_UPLOAD"))
            .drop((this.state.localCurrentPage - 1) * this.state.localPageSize)
            .take(this.state.localPageSize)
            .value()
        : []
    let agencyContents =
      this.state?.data?.filter(i => i?.source == "AGENCY").length > 0
        ? _(this.state?.data?.filter(i => i?.source == "AGENCY"))
            .drop(
              (this.state.agencyCurrentPage - 1) * this.state.agencyPageSize
            )
            .take(this.state.agencyPageSize)
            .value()
        : []
    let crawlerContents =
      this.state?.data?.filter(i => i?.source == "CRAWLER").length > 0
        ? _(this.state?.data?.filter(i => i?.source == "CRAWLER"))
            .drop(
              (this.state.crawlerCurrentPage - 1) * this.state.crawlerPageSize
            )
            .take(this.state.crawlerPageSize)
            .value()
        : []

    return (
      <Auxiliary loading={this.state.loading}>
        {!this.state.showImportVideos && !this.props.showAddVideos && (
          <>
            <div className="d-flex justify-content-between">
              <h3>
                Review Videos - {this.props.interest.name} -{" "}
                {this.props.subInterestName}
              </h3>
              <div>
                <Button
                  onClick={this._handleGoBack}
                  variant="outlined"
                  className="mx-1"
                  // color="success"
                  // size="small"
                  startIcon={<MdOutlineArrowBackIosNew size={15} />}
                >
                  Back
                </Button>
              </div>
            </div>
            <br />
            {this._renderFilters()}
            <br />
            {this.state?.data && (
              <>
                {this.state?.data?.length > 0 && (
                  <>
                    <div>
                      <h4>Local Upload</h4>
                    </div>
                    <div>
                      {" "}
                      <div
                        className="seed-videos-container"
                        style={{ margin: "10px" }}
                      >
                        {localContents.map(item => this._renderVideo(item))}
                        <Pagination
                          style={{ marginTop: "1rem" }}
                          onChange={this._onLocalPageChange}
                          defaultCurrent={this.state.localCurrentPage}
                          total={
                            this.state?.data?.filter(
                              i => i?.source == "LOCAL_UPLOAD"
                            ).length < 10000
                              ? this.state?.data?.filter(
                                  i => i?.source == "LOCAL_UPLOAD"
                                ).length
                              : 10000
                          }
                          pageSize={this.state.localPageSize}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </>
                )}
                {agencyContents.length > 0 && (
                  <>
                    {" "}
                    <div>
                      <h4>Agency</h4>
                    </div>
                    <div
                      className="seed-videos-container"
                      style={{ margin: "10px" }}
                    >
                      {" "}
                      {agencyContents.map(item => this._renderVideo(item))}
                    </div>{" "}
                    <div className="d-flex justify-content-end pb-3">
                      <Pagination
                        style={{ marginTop: "1rem" }}
                        onChange={this._onAgencyPageChange}
                        defaultCurrent={this.state.agencyCurrentPage}
                        total={
                          this.state?.data?.filter(i => i?.source == "AGENCY")
                            .length < 10000
                            ? this.state?.data?.filter(
                                i => i?.source == "AGENCY"
                              ).length
                            : 10000
                        }
                        pageSize={this.state.agencyPageSize}
                        showSizeChanger={false}
                      />
                    </div>
                  </>
                )}
                {crawlerContents.length > 0 && (
                  <>
                    {" "}
                    <div>
                      <h4>Crawler</h4>
                    </div>
                    <div
                      className="seed-videos-container"
                      style={{ margin: "10px" }}
                    >
                      {" "}
                      {crawlerContents.map(item => this._renderVideo(item))}
                    </div>{" "}
                    <div className="d-flex justify-content-end pb-3">
                      {" "}
                      <Pagination
                        style={{ marginTop: "1rem" }}
                        onChange={this._onCrawlerPageChange}
                        defaultCurrent={this.state.crawlerCurrentPage}
                        total={
                          this.state?.data?.filter(i => i?.source == "CRAWLER")
                            .length < 10000
                            ? this.state?.data?.filter(
                                i => i?.source == "CRAWLER"
                              ).length
                            : 10000
                        }
                        pageSize={this.state.crawlerPageSize}
                        showSizeChanger={false}
                      />
                    </div>
                  </>
                )}
                <h4> Blip</h4>
                <Auxiliary loading={this.state.bliploader}>
                  {filteredBlipContents.length > 0 && (
                    <>
                      {" "}
                      <div
                        className="seed-videos-container"
                        style={{ margin: "10px" }}
                      >
                        {" "}
                        {filteredBlipContents.map(item =>
                          this._renderVideo(item)
                        )}
                      </div>
                      <div className="d-flex justify-content-end pb-3">
                        <Pagination
                          style={{ marginTop: "1rem" }}
                          onChange={this._onPageChange}
                          defaultCurrent={this.state.blipCurrentPage}
                          total={
                            this.state?.blipContents?.length < 10000
                              ? this.state?.blipContents?.length
                              : 10000
                          }
                          pageSize={this.state.blipPageSize}
                          showSizeChanger={false}
                        />
                      </div>{" "}
                    </>
                  )}
                </Auxiliary>
                {/* {this.state?.data?.filter(i => !i.hasOwnProperty("source"))
                  .length > 0 && (
                  <>
                    {" "}
                    <div>
                      <h4>No source</h4>
                    </div>
                    <div
                      className="seed-videos-container"
                      style={{ margin: "10px" }}
                    >
                      {" "}
                      {this.state?.data
                        ?.filter(i => !i.hasOwnProperty("source"))
                        .map(item => this._renderVideo(item))}
                    </div>{" "}
                  </>
                )} */}
              </>
            )}
          </>
        )}
        <br />

        {this._renderVideoDetailsPopup()}
      </Auxiliary>
    )
  }
}
