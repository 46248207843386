import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Row, Col, Card, CardTitle, CardBody, Button } from "reactstrap"
import {
  getCameraAssetListByFilters,
  getFiltersList,
  getEffectsList,
  getMasksList,
  getStickersList,
  upsertCameraAsset,
  getFuList,
  getArGamesList,
  moderateCameraAsset,
  upgradeCameraAssetVersion,
  resetState,
} from "appRedux/slices/cameraAsset"
import Auxiliary from "../../util/Auxiliary"
import "flatpickr/dist/themes/material_blue.css"
import CameraAssetCard from "components/CameraAssetsList/cameraAssetCard"
import CameraAssetList from "./cameraAssetList"
import CameraAssetUpsertForm from "./cameraAssetUpsertForm"
import "../Comment/browseComment.style.css"
import {
  message,
  Input,
  Tag,
  Tooltip,
  List,
  Select,
  Modal,
  Pagination,
} from "antd"
import Chips from "../../components/CommonFilter/chips"
import {
  NO_PERMISSION_MESSAGE,
  cameraAssetFilters,
} from "../../constants/uiConstants"
import toastr from "toastr"
import { formatNumber } from "../../util/common"

class ManageCameraAsset extends ErrorBoundComponent {
  state = {
    filters: {
      comboId: this.props.queryParams ? this.props.queryParams["comboId"] : "",
      order: this.props.queryParams ? this.props.queryParams["order"] : "desc",
      currentPage: this.props.queryParams
        ? this.props.queryParams["currentPage"]
        : 1,
      title: this.props.queryParams ? this.props.queryParams["title"] : "",
    },
    showModal: false,
    cameraAsset: {},
    action: "create",
    showFilters: false,
    showAdvancedFilters: false,
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      let filters = _.cloneDeep(this.state.filters)
      _.map(this.state.filters, (filter, key) => {
        if (!filter) {
          _.unset(filters, key)
        }
      })
      this.props.dispatch(
        getCameraAssetListByFilters(_.deepClean(this.state.filters))
      )
      this.props.dispatch(getFiltersList())
      this.props.dispatch(getEffectsList())
      this.props.dispatch(getMasksList())
      this.props.dispatch(getFuList())
      this.props.dispatch(getStickersList())
      this.props.dispatch(getArGamesList())
    }
  }

  componentDidUpdate() {
    if (!this.props.common.error && this.props.refetchCameraAssetList) {
      let filters = _.cloneDeep(this.state.filters)
      _.map(this.state.filters, (filter, key) => {
        if (!filter) {
          _.unset(filters, key)
        }
      })
      this.setState({
        showModal: false,
        audioId: null,
        defaultValue: null,
      })
      this.props.dispatch(
        getCameraAssetListByFilters(_.deepClean(this.state.filters))
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _searchContent = () => {
    let filters = _.cloneDeep(this.state.filters)
    _.map(this.state.filters, (filter, key) => {
      if (!filter) {
        _.unset(filters, key)
      }
    })
    this.props.history.push(
      `/cameraAsset/manage?${QueryString.stringify(filters)}`
    )
    this.props.dispatch(getCameraAssetListByFilters(_.deepClean(filters)))
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({ showFilters: false, showAdvancedFilters: false })
  }

  _handleFilterChange = (value, type) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: cameraAssetFilters[filter].defaultValue,
      showFilters: false,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/cameraAsset/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        let filters = _.cloneDeep(this.state.filters)
        this.props.dispatch(getCameraAssetListByFilters(_.deepClean(filters)))
      }
    )
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _closeModal = () => {
    this.setState({
      showModal: false,
      audioId: null,
      defaultValue: null,
    })
  }

  _showUpsertAsset = (action, cameraAsset) => {
    this.setState({
      showModal: true,
      action,
      cameraAsset,
    })
  }

  _toggleStatus = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    this.props.dispatch(updateAudioStatus(formData))
  }

  _toggleVisibility = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    this.props.dispatch(updateAudioVisibility(formData))
  }

  _onCameraAssetUpsertSubmit = async formData => {
    this.props.dispatch(upsertCameraAsset(formData))
  }

  _getModalContent = () => {
    return (
      <CameraAssetUpsertForm
        cameraAsset={this.state.cameraAsset}
        loading={this.props.audioLoading}
        common={this.props.common}
        currentUser={this.props.currentUser}
        formAction={this.state.action}
        onCancel={this._closeModal}
        onSubmit={this._onCameraAssetUpsertSubmit}
        filtersList={this.props.filtersList}
        stickers={this.props.stickers}
        arGames={this.props.arGames}
        effectsList={this.props.effectsList}
        masksList={this.props.masksList}
        faceUnityList={this.props.faceUnityList}
        selectedUser={this.state.selectedUser}
      />
    )
  }

  _modal = () => {
    return (
      <Card>
        <CardTitle className="p-3 pb-0">{"Create/Edit Camera Asset"}</CardTitle>
        <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
          {this._getModalContent()}
        </CardBody>
      </Card>
    )
  }

  _performManageAudioAction = async (action, audioId, defaultValue) => {
    this.setState({
      modalType: action,
      showModal: true,
      audioId,
      defaultValue,
    })
  }

  _onModerationClick = (comboId, isActive) => {
    let data = {}
    data.comboId = comboId
    data.action = isActive ? "deactivate" : "activate"
    this.props.dispatch(moderateCameraAsset(data))
  }

  _showAssetDetails = () => {
    return (
      <React.Fragment>
        <Col lg={this.state.showForm ? "7" : "12"}>
          <div className="trending-card-container">
            {this.props.cameraAssetList.map((cameraAsset, index) => {
              return (
                <CameraAssetCard
                  cameraAsset={cameraAsset}
                  key={`${index}_${cameraAsset.title}`}
                  editClick={(cameraAsset, type) =>
                    this.editClick(cameraAsset, type)
                  }
                  currentUser={this.props.currentUser}
                  history={this.props.history}
                  onEditClick={(action, cameraAsset) =>
                    this.setState({ showModal: false }, () => {
                      this.setState({
                        showModal: true,
                        action,
                        cameraAsset,
                      })
                    })
                  }
                  onModerationClick={this._onModerationClick}
                />
              )
            })}
          </div>
        </Col>
      </React.Fragment>
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._searchContent()
      }
    )
  }

  _pagination = () => {
    return (
      <div className="d-flex justify-challenge-end">
        <Pagination
          style={{ marginTop: "1rem" }}
          onChange={this._onPageChange}
          current={parseInt(this.state.filters["currentPage"], 10)}
          defaultCurrent={parseInt(this.state.filters["currentPage"], 10)}
          total={this.props.total}
          showSizeChanger={false}
        />
      </div>
    )
  }

  onUpgradeVersion = () => {
    this.props.dispatch(upgradeCameraAssetVersion())
  }

  render() {
    const lg = this.state.showModal ? "0" : "12"
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showModal &&
          this.props.cameraAssetList &&
          this.props.cameraAssetList.length > 0 && (
            <Chips
              showFilters={this.state.showFilters}
              showAdvancedFilters={this.state.showAdvancedFilters}
              filters={this.state.filters}
              search={this._closeFilters}
              removeFilter={this._removeFilter}
              handleFilterChange={this._handleFilterChange}
              type="camera_asset"
              toggleFilters={this._toggleFilters}
              toggleAdvancedFilters={this._toggleAdvancedFilters}
            />
          )}
        {!this.state.showModal && (
          <div className="d-flex justify-content-end mb-3">
            <Button
              color="primary"
              className="d-flex mx-2"
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_MANAGE_CAMERA_ASSETS"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this.onUpgradeVersion()
              }}
            >
              <i className="fa fa-chevron-up mx-1 my-1" />
              Upgrade Version
            </Button>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_MANAGE_CAMERA_ASSETS"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this._showUpsertAsset("create", {})
              }}
            >
              Create
            </button>
          </div>
        )}
        {this.props.total &&
          !this.state.showModal &&
          this.props.cameraAssetList &&
          this.props.cameraAssetList.length > 0 && (
            <div style={{ textAlign: "center" }} className="mb-2">
              <Tag color="geekblue">
                Showing <b>{this.props.cameraAssetList.length}</b> of{" "}
                <b>{formatNumber(this.props.total)}</b> Camera Assets
              </Tag>
            </div>
          )}
        <Row>
          <Col lg={lg} style={{ display: lg === "0" ? "none" : "block" }}>
            {!_.isEmpty(this.props.cameraAssetList) && this._showAssetDetails()}
            <div className="d-flex justify-content-end mb-3">
              {this._pagination()}
            </div>
          </Col>
          {this.state.showModal && <Col lg="12">{this._modal()}</Col>}
        </Row>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    cameraAssetList: _.get(store, "cameraAsset.cameraAssetList"),
    refetchCameraAssetList: _.get(store, "cameraAsset.refetchCameraAssetList"),
    filtersList: _.get(store, "cameraAsset.filtersList"),
    stickers: _.get(store, "cameraAsset.stickers"),
    arGames: _.get(store, "cameraAsset.arGames"),
    effectsList: _.get(store, "cameraAsset.effectsList"),
    masksList: _.get(store, "cameraAsset.masksList"),
    faceUnityList: _.get(store, "cameraAsset.faceUnityList"),
    total: _.get(store, "cameraAsset.total.value"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    reportList: _.get(store, "activityLog.reportList"),
    moderatorList: _.get(store, "cmsUser.userList"),
    communityUser: _.get(store, "community.communityUser"),
    communityManagers: _.get(store, "community.communityManagers"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "cameraAsset.loading"),
    unassignedManagers: _.get(store, "community.unassignedManagers"),
    managerDataLoading: _.get(store, "community.loading"),
  }
}

export default connect(mapStateToProps)(ManageCameraAsset)
