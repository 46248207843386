import Axios from "axios"
import toastr from "toastr"

export const requestGetlimit = async ({ payload }) => {
  //   const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
  //   _.unset(payload, "currentPage")
  try {
    let data = await Axios.post(`/api/limit/list`, payload)
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestCreatelimit = async ({ payload }) => {
  try {
    let data = await Axios.post("/api/limit/create", payload)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
export const requestUpdatelimit = async ({ payload }) => {
  try {
    let data = await Axios.put("/api/limit/update/" + payload.id, payload)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeletelimit = async ({ payload }) => {
  try {
    let data = await Axios.delete(`/api/limit/delete/${payload.id}`)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
