import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import {
  Button,
  Row,
  Col,
  CardImg,
  Card as ReactCard,
  CardBody,
  Badge,
} from "reactstrap"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { reorderDefaultCollectionEntities } from "../../appRedux/slices/zeroSearch"
import toastr from "toastr"
import Auxiliary from "../../util/Auxiliary"
import { Divider, Input, Pagination, Tabs, Modal, Card, Tooltip } from "antd"
import "../Comment/browseComment.style.css"
import * as XLSX from "xlsx"
import { MdDownload } from "react-icons/md"
import { Button as MuiButton, Stack } from "@mui/material"

const { Meta } = Card

class ReorderDefaultCollectionEntities extends ErrorBoundComponent {
  state = {
    collectionEntities: [],
    showDefaultOrder: true,
    showModal: true,
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      collectionId: this.props["collectionId"]
        ? this.props["collectionId"]
        : "",
      collectionType: this.props["collectionType"]
        ? this.props["collectionType"]
        : "",
      elementType: this.props["elementType"] ? this.props["elementType"] : "",
      elementId: this.props["elementId"] ? this.props["elementId"] : "",
      sortKey: "view_order",
      sortOrder: "asc",
    },
  }

  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  _onDragEnd = result => {
    // dropped outside the list
    const prevEntityList = this.state?.collectionEntities?.length
      ? this.state.collectionEntities
      : this.props.collectionEntities

    if (!result.destination) {
      return
    }

    const collectionEntities = this._reorder(
      prevEntityList,
      result.source.index,
      result.destination.index
    )
    this.setState({
      collectionEntities,
    })
  }

  _updateTabOrder = () => {
    const { collectionEntities } = this.state
    const payload = {}
    const entities = []

    for (let i = 0; i < collectionEntities.length; i++) {
      entities.push(collectionEntities[i]["entity_uuid"])
    }
    payload.entities = entities
    this.props.dispatch(reorderDefaultCollectionEntities({ payload }))
  }

  handleDownloadExcel = () => {
    const collectionEntities = this.state?.collectionEntities?.length
      ? this.state.collectionEntities
      : this.props.collectionEntities

    let collectionEntitiesFiltered = [["UUID", "TITLE"]]
    collectionEntities
      .slice()
      .filter(slot => slot.status != "REMOVED")
      .sort((a, b) => a.view_order - b.view_order)
      .map(item => {
        collectionEntitiesFiltered.push([
          item.banner_uuid ||
            item.combo_id ||
            item.content_uuid ||
            item.template_uuid ||
            item.zone_uuid ||
            item.music_playlist_uuid ||
            item.music_label_uuid ||
            item.chip_uuid ||
            item.challenge_id ||
            item.collection_uuid ||
            item.music_artist_uuid ||
            item.audio_id ||
            item.contest_id ||
            item.user_uuid ||
            item.name ||
            item.hashtag,
          item.heading_title ||
            item.title ||
            item.name ||
            item.hashtag ||
            item.sticker_name ||
            item.content_title ||
            item.asset_label,
        ])
      })
    const worksheet = XLSX.utils.json_to_sheet(
      collectionEntitiesFiltered.slice(1) || []
    )
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "List")
    XLSX.utils.sheet_add_aoa(worksheet, [collectionEntitiesFiltered[0]], {
      origin: "A1",
    })
    XLSX.writeFile(workbook, "Default-List.xlsx")
  }

  _renderCard = (id, entityData, type, i) => {
    return (
      <Tooltip title="Default">
        <ReactCard
          key={id}
          style={{
            width: 150,
            margin: "1rem",
            boxShadow: "1px 4px 1px #AEAEAE",
            cursor: "pointer",
            backgroundColor: "#28a745",
          }}
        >
          <CardBody>
            <div className="d-flex justify-content-center mb-4 text-truncate-2 font-size-15">
              <i className="bx bx-shuffle fa-5x text-black-50" />
            </div>
            <div className="font-size-16 ">Default</div>
          </CardBody>
        </ReactCard>
      </Tooltip>
    )
  }

  render() {
    const collectionEntities = this.state?.collectionEntities?.length
      ? this.state.collectionEntities
      : this.props.collectionEntities
    return (
      <React.Fragment>
        <ReactCard>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <div
                style={{
                  height: "55vh",
                  overflowY: "scroll",
                  marginLeft: "10px",
                }}
              >
                <div style={{ display: "flex", flexFlow: "wrap" }}>
                  {this._renderCard("default")}
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              {this.state.showModal && (
                <div
                  style={{ margin: "1rem" }}
                  className="d-flex justify-content-between"
                >
                  <div
                    style={{
                      fontWeight: "700",
                      marginBottom: "5px",
                      marginTop: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {this.state.showModal && "Default"}
                  </div>
                  <MuiButton
                    variant="outlined"
                    startIcon={<MdDownload />}
                    // disabled={
                    //   !this.props?.csvData?.length || this.props.csvLoading
                    // }
                    onClick={this.handleDownloadExcel}
                  >
                    {" "}
                    List
                  </MuiButton>
                  <Button
                    color="primary"
                    onClick={() => {
                      this._updateTabOrder()
                    }}
                  >
                    Done
                  </Button>
                </div>
              )}
              <div
                style={{
                  height: "55vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div style={{ marginBottom: 30 }} bordered={true}>
                  <Auxiliary loading={this.props.loadingPageEntities}>
                    <DragDropContext onDragEnd={this._onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {collectionEntities.map((tab, index) => (
                              <Draggable
                                key={`${tab["entity_uuid"]}`}
                                draggableId={`${tab["entity_uuid"]}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Row>
                                      <Col>
                                        <Card
                                          className="cardStyle d-flex align-items-center"
                                          cover={
                                            tab.icon_url ||
                                            tab.album_art ||
                                            tab?.entityData?.thumbnail ||
                                            tab?.entityData?.profile_pic ||
                                            tab?.entityData?.album_art ||
                                            tab?.entityData?.static_thumbnail ||
                                            tab.banner_url ||
                                            tab.thumbnail ||
                                            tab.zone_image ||
                                            tab.thumbnail_url ? (
                                              <img
                                                alt="image"
                                                // src="https://stream.coolfie.io/stream/prod-ugc-contents/zerosearch/thumbnails/0a9cbabb5ac44725/82b24ff6f32882a2/0a9cbabb5ac4472582b24ff6f32882a2.jpg"
                                                src={
                                                  tab.icon_url ||
                                                  tab.album_art ||
                                                  tab?.entityData?.thumbnail ||
                                                  tab?.entityData
                                                    ?.profile_pic ||
                                                  tab?.entityData?.album_art ||
                                                  tab?.entityData
                                                    ?.static_thumbnail ||
                                                  tab.banner_url ||
                                                  tab.thumbnail ||
                                                  tab.zone_image ||
                                                  tab.thumbnail_url
                                                }
                                                style={{
                                                  width: 60,
                                                  height: 60,
                                                }}
                                                className="me-3"
                                              />
                                            ) : (
                                              <div>
                                                <i
                                                  style={{
                                                    width: 60,
                                                    height: 60,
                                                  }}
                                                  className="bx bx-gift bx-lg me-3"
                                                />
                                              </div>
                                            )
                                          }
                                          bordered={true}
                                          style={{
                                            padding: "10px 20px",
                                            display: "flex",
                                            // width: "50px",
                                          }}
                                        >
                                          <div className="d-flex">
                                            <Meta
                                              title={
                                                tab
                                                  ? tab.title ||
                                                    tab.hashtag ||
                                                    tab.name ||
                                                    tab.sticker_name ||
                                                    tab.asset_label ||
                                                    tab.content_title
                                                  : undefined
                                              }
                                              className="me-2"
                                            />
                                            <CopyToClipboard
                                              text={tab?.entity_uuid}
                                              onCopy={e => {
                                                toastr.success(
                                                  "Copied sucessfully!"
                                                )
                                              }}
                                              className="me-2"
                                            >
                                              <Tooltip title="Copy Entity ID">
                                                <i
                                                  className="bx bx-copy-alt"
                                                  role="button"
                                                />
                                              </Tooltip>
                                            </CopyToClipboard>
                                            <CopyToClipboard
                                              text={tab?.collection_uuid}
                                              onCopy={e => {
                                                toastr.success(
                                                  "Copied sucessfully!"
                                                )
                                              }}
                                            >
                                              <Tooltip title="Copy Collection ID">
                                                <i
                                                  className="bx bx-copy"
                                                  role="button"
                                                />
                                              </Tooltip>
                                            </CopyToClipboard>
                                          </div>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Auxiliary>
                </div>
                {/* {!this.state.showDefaultOrder && this._showSpecificOrdering()} */}
              </div>
            </Col>
          </Row>
        </ReactCard>
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zeroSearch.totalEntities"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "zeroSearch.loadingEntities"),
    collectionEntities: _.get(store, "zeroSearch.collectionEntities"),
    refetch: _.get(store, "zeroSearch.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loadingPageEntities: _.get(store, "zeroSearch.loadingPageEntities"),
  }
}

export default connect(mapStateToProps)(ReorderDefaultCollectionEntities)
