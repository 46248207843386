import Axios from "axios"

export const getSpinStaticConfig = async () => {
  try {
    const response = await Axios.get(`/api/spin/static-config/get`)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const getSpinStats = async () => {
  try {
    const response = await Axios.get(`/api/spin/stats/gte`)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const getSpinUserStats = async (payload, offset = 0, limit = 10) => {
  try {
    const from = offset ? offset - 1 : 0
    const response = await Axios.post(
      `/api/spin/transaction?offset=${from}&limit=${limit}`,
      payload
    )
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const getTop100spin = async payload => {
  try {
    const response = await Axios.post(`/api/spin/top100spin`, payload)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const getSpinGiftStats = async () => {
  try {
    const response = await Axios.get(`/api/spin/giftQuery`)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const updateSpinStaticConfig = async payload => {
  try {
    const response = await Axios.post("/api/spin/static-config/update", payload)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const getCampaignSpinConfig = async () => {
  try {
    const response = await Axios.get(`/api/campaign/spin-config/get`)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const updateCampaignSpinConfig = async payload => {
  try {
    const response = await Axios.post(
      "/api/campaign/spin-config/update",
      payload
    )
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const getSpinTabConfig = async () => {
  try {
    const response = await Axios.get(`/api/spin/tab-config/get`)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const updateSpinTabConfig = async payload => {
  try {
    const response = await Axios.post("/api/spin/tab-config/update", payload)
    return response.data
  } catch (error) {
    return error
  }
}
