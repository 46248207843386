import { Tooltip } from "antd"
import { getCSVData, getTopCreatorsList } from "appRedux/slices/community"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
import { formatNumber } from "../../util/common"
import "./community.css"
import DashboardReportCard from "./Report/DashboardReportCard"
import { Input, Select, DatePicker, Divider } from "antd"
import {
  LANGUAGES,
  CURATED_TYPES,
  CURATED_GENRE_LIST,
} from "../../constants/uiConstants"

const { Option } = Select

const DashboardCard = props => {
  const {
    data: userProfile = {},
    onGetUserProfile,
    creators = [],
    topList: oldTopList = [],
  } = props

  const dispatch = useDispatch()

  const {
    loading,
    csvData,
    communityManagerData,
    csvLoading,
    topList: newTopList,
  } = useSelector(state => ({
    loading: _.get(state, "community.loading"),
    csvData: _.get(state, "community.csvData"),
    communityManagerData: _.get(state, "community.communityManager"),
    csvLoading: _.get(state, "community.csvLoading"),
    topList: _.get(state, "community.topList"),
  }))

  const [targetLanguage, setTargetLanguage] = useState("")
  const [targetGenre, setTargetGenre] = useState("")
  const [targetCuratedType, setTargetCuratedType] = useState("")

  useEffect(() => {
    if (!csvLoading && communityManagerData && communityManagerData.userId) {
      dispatch(
        getCSVData({
          communityUserId: communityManagerData?.userId || "",
        })
      )
    }
  }, [csvLoading])

  const userOptions = {
    1: "Super Admin",
    2: "Admin",
    3: "Content Manager", // Create Update Challem, audio and content
    4: "Moderator", // Requires permissions to be updated in the users table and same reads to be handled at routes
    5: "Ads Ops", // For source Type and sponsor text in challenge and audio
    7: "Internal Moderator",
  }
  // eslint-disable-next-line no-unused-vars
  const topList = newTopList || oldTopList || []

  if (creators && creators.length && topList.length === 0) {
    return null
  }

  const _renderUserCard = (user, key_name, label) => {
    return (
      <Col sm="4">
        <Card className="text-center shadow-sm" style={{ height: "200px" }}>
          <Link to={`/appuser/details?userUuid=${user.user_uuid}`}>
            <CardBody className="pt-1" style={{ cursor: "pointer" }}>
              <div className="mb-4 mt-4">
                <div style={{ position: "relative" }}>
                  <img
                    className="rounded-circle avatar-sm img-fluid"
                    src={user.profile_pic}
                    alt=""
                  />
                  <span className="verified-icon-small">
                    <i className="mdi mdi-check-decagram"></i>
                  </span>
                </div>
              </div>
              <Tooltip title={user.name}>
                <h5 className="font-size-13 mb-1 text-truncate text-dark">
                  {user.name}
                </h5>
              </Tooltip>
              <Tooltip title={`@${user.handle}`}>
                <p className="font-size-13 text-truncate text-muted mb-1">{`@${user.handle}`}</p>
              </Tooltip>
              <Tooltip title={`${user[key_name]}`}>
                <p className="font-size-13 text-truncate text-muted mb-4">{`${label}: ${formatNumber(
                  user[key_name]
                )}`}</p>
              </Tooltip>
            </CardBody>
          </Link>
        </Card>
      </Col>
    )
  }

  const getTopCreatorsListByFilter = async () => {
    if (
      communityManagerData &&
      communityManagerData.community &&
      communityManagerData.community.usersList &&
      communityManagerData.community.usersList.length > 0
    ) {
      await dispatch(
        getTopCreatorsList({
          creators: communityManagerData?.community?.usersList || [],
          startDate: null,
          endDate: null,
          curatedType: targetCuratedType || null,
          genre: targetGenre || null,
          language: targetLanguage || null,
        })
      )
    }
  }

  return (
    <React.Fragment>
      <Container fluid>
        {creators && creators.length ? (
          <>
            <Card>
              <button
                type="button"
                className="btn btn-primary m-2 align-self-end d-flex"
                style={{ width: "fit-content", fontWeight: "bold" }}
                disabled={!csvData.length}
                // onClick={() => setAllPostTimespan(LAST_POST_DATE)}
              >
                <i class="bx bx-download bx-xs mt-1"></i>{" "}
                <div>
                  &nbsp;
                  <CSVLink style={{ color: "white" }} data={csvData || []}>
                    CSV File
                  </CSVLink>
                </div>
              </button>
            </Card>
            <div className="dashboard-card-container">
              <DashboardReportCard {...props} />
            </div>
            <Card>
              <Row className="d-flex justify-content-between">
                <Col md="11" lg="11" className="mt-2 mb-2">
                  <Row>
                    <Col className="ml-6">
                      <span className="font-size-14 mb-0 font-weight-semibold">
                        Language:{" "}
                      </span>
                      <Select
                        // mode="multiple"
                        showSearch
                        allowClear={true}
                        placeholder="Language"
                        value={targetLanguage}
                        onChange={value => setTargetLanguage(value)}
                        style={{ width: "60%" }}
                      >
                        <Option value="">All</Option>
                        {LANGUAGES.map(lang => (
                          <Option value={lang.value}>{lang.label}</Option>
                        ))}
                      </Select>
                    </Col>
                    <Col>
                      <span className="font-size-14 mb-0 font-weight-semibold">
                        Curated Type:{" "}
                      </span>
                      <Select
                        // mode="multiple"
                        showSearch
                        allowClear={true}
                        placeholder="Curated Type"
                        value={targetCuratedType}
                        onChange={value => setTargetCuratedType(value)}
                        style={{ width: "60%" }}
                      >
                        <Option value="">All</Option>
                        {CURATED_TYPES.map(pod => (
                          <Option value={pod.value}>{pod.label}</Option>
                        ))}
                      </Select>
                    </Col>
                    <Col>
                      <span className="font-size-14 mb-0 font-weight-semibold">
                        Genre:{" "}
                      </span>
                      <Select
                        // mode="multiple"
                        showSearch
                        allowClear={true}
                        placeholder="Genre"
                        value={targetGenre}
                        onChange={value => setTargetGenre(value)}
                        style={{ width: "60%" }}
                      >
                        <Option value="">All</Option>
                        {CURATED_GENRE_LIST.map(pod => (
                          <Option value={pod.value}>{pod.label}</Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>
                <Col md="1" lg="1">
                  <button
                    type="button"
                    className="btn btn-primary m-2 align-self-end d-flex p-1 mt-2"
                    style={{ width: "fit-content", fontWeight: "bold" }}
                    disabled={!csvData.length}
                    onClick={() =>
                      getTopCreatorsListByFilter({
                        targetLanguage,
                        targetCuratedType,
                        targetGenre,
                      })
                    }
                  >
                    Apply
                  </button>
                </Col>
              </Row>
            </Card>
            <div className="dashboard-card-container">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Top Likes</CardTitle>
                  <Row>
                    {topList.topLikes &&
                      topList.topLikes.data &&
                      topList.topLikes.data.map((user, indx) => {
                        return _renderUserCard(user, "like_count", "Likes")
                      })}
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Top Viewes</CardTitle>
                  <Row>
                    {topList.topViews &&
                      topList.topViews.data &&
                      topList.topViews.data.map((user, indx) => {
                        return _renderUserCard(user, "view_count", "Views")
                      })}
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Top Shares</CardTitle>
                  <Row>
                    {topList.topShares &&
                      topList.topShares.data &&
                      topList.topShares.data.map((user, indx) => {
                        return _renderUserCard(user, "share_count", "Shares")
                      })}
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Top Posts</CardTitle>
                  <Row>
                    {topList.topPosts &&
                      topList.topPosts.data &&
                      topList.topPosts.data.map((user, indx) => {
                        return _renderUserCard(user, "posts_count", "Posts")
                      })}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </>
        ) : (
          <div
            className="font-size-18 text-center text-muted font_italic_bold"
            style={{ marginTop: "35vh" }}
          >
            Not Data Available.
          </div>
        )}
      </Container>
    </React.Fragment>
  )
}

DashboardCard.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(DashboardCard)
