import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"
import {
  getPackage,
  getPackageSuccess,
  upsertPackage,
  upsertPackageSuccess,
  upsertPackageCollection,
  upsertPackageCollectionSuccess,
  upsertPackageCollectionEntity,
  upsertPackageCollectionEntitySuccess,
  deletePackage,
  deletePackageSuccess,
} from "../../slices/package"
import {
  requestPackage,
  requestUpsertPackage,
  requestDeletePackage,
  requestUpsertPackageCollection,
  requestUpsertPackageCollectionEntity,
} from "../requests/package"

function* handleEntities(params) {
  try {
    const data = yield call(requestPackage, params)
    yield put(getPackageSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertPackage(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertPackage, params)
    yield put(upsertPackageSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeletePackage(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestDeletePackage, params)
    yield put(deletePackageSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertPackageCollection(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertPackageCollection, params)
    yield put(upsertPackageCollectionSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertPackageCollectionEntity(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertPackageCollectionEntity, params)
    yield put(upsertPackageCollectionEntitySuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getPackage.type, handleEntities)
  yield takeLatest(upsertPackage.type, handleUpsertPackage)
  yield takeLatest(upsertPackageCollection.type, handleUpsertPackageCollection)
  yield takeLatest(
    upsertPackageCollectionEntity.type,
    handleUpsertPackageCollectionEntity
  )
  yield takeLatest(deletePackage.type, handleDeletePackage)
}
