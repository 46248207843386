import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestCMSVideoTemplateListByFilters,
  requestUpsertCMSVideoTemplate,
  requestUpsertCMSVideoGreetings,
  requestCMSVideoGreetingsListByFilters,
} from "../requests/cmsVideoTemplates"
import {
  getCMSVideoTemplateListByFilters,
  getCMSVideoTemplateListByFiltersSuccess,
  upsertCMSVideoTemplate,
  upsertCMSVideoTemplateSuccess,
  upsertCMSVideoGreetings,
  upsertCMSVideoGreetingsSuccess,
  getCMSVideoGreetingsListByFilters,
  getCMSVideoGreetingsListByFiltersSuccess,
} from "../../slices/cmsVideoTemplates"
import { fetchError } from "appRedux/slices/common"

function* handleGetCMSVideoTemplateListByFilters(payload) {
  try {
    const data = yield call(requestCMSVideoTemplateListByFilters, payload)
    yield put(getCMSVideoTemplateListByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetCMSVideoGreetingsListByFilters(payload) {
  try {
    const data = yield call(requestCMSVideoGreetingsListByFilters, payload)
    yield put(getCMSVideoGreetingsListByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertCMSVideoTemplate(payload) {
  try {
    yield call(requestUpsertCMSVideoTemplate, payload)
    yield put(upsertCMSVideoTemplateSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertCMSVideoGreetings(payload) {
  try {
    const data = yield call(requestUpsertCMSVideoGreetings, payload)
    yield put(upsertCMSVideoGreetingsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(
    getCMSVideoTemplateListByFilters.type,
    handleGetCMSVideoTemplateListByFilters
  )
  yield takeLatest(
    getCMSVideoGreetingsListByFilters.type,
    handleGetCMSVideoGreetingsListByFilters
  )
  yield takeLatest(upsertCMSVideoTemplate.type, handleUpsertCMSVideoTemplate)
  yield takeLatest(upsertCMSVideoGreetings.type, handleUpsertCMSVideoGreetings)
}
