import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import toastr from "toastr"
import _ from "lodash"
import moment from "moment"
import "moment-timezone"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, {
  TextField,
  Select,
  DateTimePicker,
  MultiSelect,
} from "../ValidatorForm"
import { Tooltip } from "antd"
import "./ads.styles.css"
import dayjs from "dayjs"

const { Option } = Select

export default class AdsConfigForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      profileOptions:
        (this.props?.profileList &&
          this.props.profileList?.map(profile => {
            if (profile.id) {
              return { label: profile.name || profile.id, value: profile.id }
            }
          })) ||
        [],
      tabOptions:
        (this.props?.tabList &&
          this.props?.tabList.map(tab => {
            return { label: tab, value: tab }
          })) ||
        [],
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>
            {`${!this.props?.data ? "Add" : "Edit"} Ads Config`}
            <ValidatorForm
              onSubmit={this._onSubmit}
              layout={"vertical"}
              {...this._formLayout}
            >
              <Row>
                <Col sm={12} md={6}>
                  <TextField
                    label="Name"
                    placeholderLabel="Enter Name"
                    field={"name"}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    // inputFieldSize="medium"
                    defaultValue={this.props?.data?.name || ""}
                  />
                </Col>
                <Col sm={12} md={6} className="font_weight_normal">
                  <Select
                    label="Status"
                    placeholderLabel="Select Status"
                    field="status"
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    options={[
                      { value: "ACTIVE", label: "Active" },
                      { value: "DRAFT", label: "Draft" },
                      { value: "INACTIVE", label: "Inactive" },
                    ]}
                    // size="medium"
                    defaultValue={this.props?.data?.status || "ACTIVE"}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <TextField
                    label="Priority"
                    placeholderLabel="Enter Priority"
                    field={"priority"}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    // inputFieldSize="medium"
                    defaultValue={this.props?.data?.priority || ""}
                    inputType="number"
                  />
                </Col>
                <Col sm={12} md={6}>
                  <MultiSelect
                    label={"Tabs"}
                    placeholderLabel="Select Tabs"
                    field={"tabs"}
                    className="creator-form font_weight_normal"
                    defaultValue={this.props?.data?.tabs}
                    options={this.state.tabOptions || []}
                    validations={["requiredArr"]}
                    errors={["This field is required"]}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <MultiSelect
                    label={"Profiles"}
                    placeholderLabel="Select Profiles"
                    field={"profiles"}
                    defaultValue={this.props?.data?.profiles}
                    options={this.state.profileOptions || []}
                    validations={["requiredArr"]}
                    className="creator-form font_weight_normal"
                    errors={["This field is required"]}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <DateTimePicker
                    style={{ padding: "9px", width: "100%" }}
                    label="Start Time"
                    field="start_time"
                    defaultValue={
                      this.props.data && this.props.data.start_time
                        ? dayjs(this.props.data.start_time)
                        : null
                    }
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <DateTimePicker
                    style={{ padding: "9px", width: "100%" }}
                    label={
                      <div>
                        End Time
                        <Tooltip title="If you do not select any date it will be considered as infinite time period">
                          <span>
                            {" "}
                            <i class="bx bx-info-circle"></i>
                          </span>
                        </Tooltip>
                      </div>
                    }
                    field="end_time"
                    defaultValue={
                      this.props.data &&
                      this.props.data.end_time &&
                      this.props.data.end_time != -1
                        ? dayjs(this.props.data.end_time)
                        : null
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="flex" style={{ marginTop: "10px" }}>
                    <div className="d-flex justify-content-end">
                      <Button htmlType="submit" color="primary">
                        {this.props.data ? "Update" : "Create"}
                      </Button>
                      <Button
                        onClick={() => {
                          this.props.onCancel()
                          // this._resetState()
                        }}
                        color="danger"
                        className="mx-2"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </ValidatorForm>
          </CardTitle>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    if (!formData.name) {
      return toastr.error("Name is mandatory.")
    }
    if (!formData.status) {
      return toastr.error("Status is mandatory.")
    }
    if (!formData.priority && formData.priority != 0) {
      return toastr.error("Priority is mandatory.")
    }
    if (!formData.tabs || !formData.tabs.length) {
      return toastr.error("Tabs is mandatory.")
    }
    if (!formData.profiles || !formData.profiles.length) {
      return toastr.error("Profiles is mandatory.")
    }

    let end_time = -1

    if (!formData.start_time) {
      return toastr.error("Start Time is mandatory.")
    }

    let start_time = dayjs(formData.start_time).valueOf()

    if (formData.end_time) {
      end_time = formData.end_time
    }

    if (end_time != -1) {
      end_time = dayjs(end_time).valueOf()
    }

    if (end_time != -1 && start_time > end_time) {
      return toastr.error("Start Time can not be greater than End Time.")
    }

    const payload = {
      name: formData.name,
      status: formData.status,
      priority: formData.priority,
      tabs: formData.tabs,
      profiles: formData.profiles,
      start_time,
      end_time,
    }

    if (this.props?.data?.id) {
      payload.id = this.props.data.id
    }
    this.props.onSubmit(payload)
  }

  render() {
    return this._form()
  }
}
