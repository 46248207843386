import Axios from "axios"
import toastr from "toastr"

export const requestAdsProfiles = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/joshads/profile/get?offset=${offset}&limit=${limit}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}

export const requestAdsConfigs = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/joshads/config/get?offset=${offset}&limit=${limit}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}

export const requestUpsertAdsProfile = async ({ payload }) => {
  try {
    const url = `/api/joshads/profile/upsert`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertAdsConfig = async ({ payload }) => {
  try {
    const url = `/api/joshads/config/upsert`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestAdsTabs = async () => {
  try {
    let data = await Axios.get(`/api/joshads/tabs/get`)
    return data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}
