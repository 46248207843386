import Axios from "axios"
import toastr from "toastr"

export const requestChallengeListByFilters = async ({ payload }) => {
  try {
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    _.unset(payload, "currentPage")
    let data = await Axios.post(
      `/api/challenge/getChallengeListByFilter?offset=${offset}`,
      { filters: payload }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetChallengeDetails = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/challenge/getChallengeDetailsES?challengeId=${payload.challengeUuid}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertChallenge = async ({ payload }) => {
  try {
    let data = await Axios.post("/api/challenge/upsert", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateChallengeStatus = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/challenge/update/status`, payload)
    toastr.success("Action Successful !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateChallengeVideoMeta = async ({ payload }) => {
  try {
    let data = await Axios.post(
      `/api/challenge/update/video/${payload.action}`,
      payload.formData
    )
    toastr.success("Action Successful !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateChallengeWinners = async ({ payload }) => {
  try {
    let data = await Axios.post(
      `/api/challenge/winners/${payload.action}`,
      payload.formData
    )
    toastr.success("Action Successful !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteBanner = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/challenge/delete/banner`, payload)
    toastr.success("Action Successful !!")
    return data
  } catch (error) {
    throw error
  }
}
