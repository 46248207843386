import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Collapse,
  Button,
  Table,
  Badge,
  Row,
  CardTitle,
  CardImg,
  CardText,
} from "reactstrap"
import ReactTimeAgo from "react-time-ago"
import { CopyToClipboard } from "react-copy-to-clipboard"
import images from "assets/images"
import { isEmpty, size, map } from "lodash"
import { Tooltip } from "antd"
import "./styles.css"
import { DivOverlay } from "react-leaflet"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Switch } from "../ValidatorForm"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"

let currentDate = Date.now()

class TrendingCard extends React.Component {
  state = { isPlaying: false }
  audioRef = React.createRef()

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  _playPreview() {
    if (!this.state.isPlaying) {
      this.setState({ isPlaying: true })
      this.audioRef.play()
    } else {
      this.setState({ isPlaying: false })
      this.audioRef.pause()
    }
  }

  render() {
    const { trendingEntity, audioDetails = [] } = this.props
    const [audio] = audioDetails || []
    const endDate = new Date(trendingEntity.end_date * 1)
    return (
      <React.Fragment>
        <Card
          className={`text-center shadow-lg`}
          style={{
            minHeight: "280px",
            borderRadius: "10px",
            backgroundColor:
              trendingEntity &&
              (trendingEntity.status === "ENDED" ||
                trendingEntity.status === "inactive")
                ? "darkgrey"
                : "",
          }}
        >
          {audio && audio.album_art ? (
            <div
              role="button"
              id={"play" + trendingEntity.entity_uuid}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                this._playPreview()
              }}
              style={{ height: "15rem" }}
            >
              <CardImg
                top
                className="img-fluid"
                src={audio.album_art}
                alt="Skote"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{
                  height: "15rem",
                  cursor: "pointer",
                  opacity: "0.8",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              />
              <i
                key={audio.id}
                className={this.state.isPlaying ? "bx bx-pause" : "bx bx-play"}
                style={{
                  position: "absolute",
                  top: "30%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "120px",
                  color: "white",
                }}
              />
              <UncontrolledTooltip
                placement="top"
                target={"play" + trendingEntity.entity_uuid}
              >
                Play Audio
              </UncontrolledTooltip>
              <audio
                ref={input => {
                  this.audioRef = input
                }}
                src={audio.url}
                style={{ display: "none" }}
              />
            </div>
          ) : (
            <div
              className="py-5"
              style={{
                height: "15rem",
                cursor: "pointer",
                backgroundColor: "lightgray",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <i className="fas fa-volume-mute fa-7x py-4 text-secondary" />
            </div>
          )}
          <CardBody
            style={{ cursor: "pointer" }}
            onClick={e => {
              this.props.editClick(trendingEntity, "tag")
            }}
          >
            <CardTitle className="mt-0 d-flex justify-content-center">
              <div>{trendingEntity.title}</div>
              <div
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <CopyToClipboard
                  text={trendingEntity.entity_uuid}
                  onCopy={e => toastr.success("Copied Successfully")}
                >
                  <Tooltip title="Copy Entity UUID">
                    <div
                      role="button"
                      onClick={() => {}}
                      id={"copy" + trendingEntity.entity_uuid}
                    >
                      <i className="bx bx-copy fa-1x px-2" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <Tooltip
                title={
                  ["active", "STARTED", "true"].includes(trendingEntity.status)
                    ? "STARTED"
                    : "ENDED"
                }
              >
                <i
                  className={`mdi mdi-circle ${
                    ["STARTED", "active", "true"].includes(
                      trendingEntity.status
                    )
                      ? "text-success"
                      : "text-danger"
                  }`}
                />
              </Tooltip>
              <Tooltip title="Edit">
                <div
                  role="button"
                  className="px-2"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!this.props.hasEditPermission) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                    } else this.props.editClick(trendingEntity, "edit")
                  }}
                >
                  <i className="bx bx-pencil" />
                </div>
              </Tooltip>
              <Tooltip title="Edit">
                <div
                  role="button"
                  className="px-2"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (audio) {
                      this.props.history.push(
                        `/content/browse?trendingEntityId=${trendingEntity.entity_uuid}`
                      )
                    }
                  }}
                >
                  <i className="bx bx-link-external" />
                </div>
              </Tooltip>
            </CardTitle>
            <CardText>
              <div className="d-flex justify-content-center px-3">
                <Tooltip title="Click to see all the content with this audio">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (audio) {
                        this.props.history.push(
                          `/content/browse?audioId=${audio.aduio_id}`
                        )
                      }
                    }}
                  >
                    {audio && (
                      <div className="d-flex">
                        <i className="fas fa-music px-2 py-1" />
                        <div>{audio.title}</div>
                      </div>
                    )}
                  </div>
                </Tooltip>
                {audio && (
                  <div
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    className="px-2"
                  >
                    <CopyToClipboard
                      text={audio.id}
                      onCopy={e => toastr.success("Copied Successfully")}
                    >
                      <Tooltip title="Copy Audio Id">
                        <div
                          role="button"
                          onClick={() => {}}
                          id={"copy" + audio.id}
                        >
                          <i className="bx bx-copy fa-1x" />
                        </div>
                      </Tooltip>
                    </CopyToClipboard>
                  </div>
                )}
              </div>
            </CardText>
            <CardText className="d-flex justify-content-center">
              <div
                style={{ cursor: "pointer" }}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  this.props.history.push(
                    `/content/browse?searchType=tag&searchText=${trendingEntity.hashtag}`
                  )
                }}
              >
                <Tooltip
                  title={`Click to see all the contents with #${trendingEntity.hashtag}`}
                >
                  #{trendingEntity.hashtag}
                </Tooltip>
              </div>
              <div
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <CopyToClipboard
                  text={trendingEntity.hashtag}
                  onCopy={e => toastr.success("Copied Successfully")}
                >
                  <Tooltip title="Copy Hashtag">
                    <div
                      role="button"
                      onClick={() => {}}
                      id={"copy" + trendingEntity.hashtag}
                    >
                      <i className="bx bx-copy fa-1x px-2" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </CardText>
            <CardText className="d-flex justify-content-center">
              {trendingEntity.lang_code &&
                trendingEntity.lang_code.length > 0 &&
                trendingEntity.lang_code.slice(0, 2).map((tag, i) => {
                  return (
                    <Badge className="badge-soft-primary ms-1 my-1">
                      <div
                        className="font-size-15"
                        role="button"
                        key={"language" + trendingEntity.title + i}
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          this.props.history.push(
                            `/content/browse?langCode=${tag}`
                          )
                        }}
                      >
                        <Tooltip
                          title={`Click to see all ${this._getLanguage(
                            tag
                          )} contents`}
                        >
                          {this._getLanguage(tag)}
                        </Tooltip>
                      </div>
                    </Badge>
                  )
                })}
              {trendingEntity.lang_code &&
                trendingEntity.lang_code.length > 2 && (
                  <Tooltip
                    title={trendingEntity.lang_code
                      .slice(2, trendingEntity.lang_code.length)
                      .map(lang => {
                        return (
                          <div
                            role="button"
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                              this.props.history.push(
                                `/content/browse?langCode=${lang}`
                              )
                            }}
                          >
                            {this._getLanguage(lang)}
                          </div>
                        )
                      })}
                    placement="right"
                  >
                    <Badge className="badge-soft-primary ms-1 my-1">
                      <div
                        className="font-size-15 px-2"
                        key={"language_more" + trendingEntity.lang_code.length}
                        style={{ cursor: "pointer" }}
                      >
                        +{trendingEntity.lang_code.length - 2} more
                      </div>
                    </Badge>
                  </Tooltip>
                )}
            </CardText>
            {trendingEntity.end_date && (
              <CardText className="d-flex justify-content-center">
                <Badge
                  className={`${
                    trendingEntity.end_date - currentDate > 86400000
                      ? "badge-soft-primary"
                      : "badge-soft-danger"
                  } ms-1 my-1`}
                >
                  <div
                    className="font-size-15"
                    role="button"
                    key={"end_date" + trendingEntity.end_date}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <Tooltip title={"End date"}>
                      {trendingEntity.end_date < currentDate
                        ? "Ended "
                        : "Ending "}
                      {!isNaN(endDate.getTime()) && trendingEntity.end_date && (
                        <ReactTimeAgo
                          future={!(trendingEntity.end_date < currentDate)}
                          date={
                            Number.isNaN(endDate)
                              ? Date.parse(endDate)
                              : endDate
                          }
                          locale="en-US"
                        />
                      )}
                    </Tooltip>
                  </div>
                </Badge>
              </CardText>
            )}
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default TrendingCard
