import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Select, Divider } from "antd"
import { Col, Row } from "reactstrap"
import { PERMISSIONS_ARRAY } from "../../constants/uiConstants"
import "react-datepicker/dist/react-datepicker.css"

const { Option } = Select

const UserDrawer = props => {
  const userTypeMap = {
    1: "Super Admin",
    2: "Admin",
    3: "Content Manager",
    4: "Moderator",
    5: "User",
    6: "Ad Ops",
    7: "Internal Ops",
  }
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Select
                  placeholder="Search Type"
                  style={{ width: "45%" }}
                  allowClear={true}
                  defaultValue={props.filters["searchType"]}
                  onChange={value =>
                    props.handleFilterChange(value, "searchType")
                  }
                >
                  <Option value="userId">User Id</Option>
                  <Option value="name">User Name</Option>
                  <Option value="email">User Email</Option>
                </Select>
                {props.filters["searchType"] === "moderation_reason" ? (
                  <Select
                    defaultValue={props.filters["searchText"]}
                    placeholder="Moderation Reason"
                    onChange={value => {
                      props.handleFilterChange(value, "searchText")
                    }}
                    allowClear={true}
                    name="searchId"
                    style={{ width: "55%" }}
                  >
                    <Option value="">All</Option>
                    {MODERATION_REASONS.map(reason => (
                      <Option value={reason.value}>{reason.label}</Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    defaultValue={props.filters["searchText"]}
                    placeholder={"Search Value"}
                    onChange={e =>
                      props.handleFilterChange(e.target.value, "searchText")
                    }
                    allowClear={true}
                    name="searchId"
                    style={{ width: "55%" }}
                  />
                )}
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="2" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Status</h5>
          </Divider>
          <Select
            allowClear={true}
            placeholder="Moderation level"
            defaultValue={props.filters["userStatus"]}
            onChange={value => {
              props.handleFilterChange(value, "userStatus")
            }}
            style={{ width: "100%" }}
          >
            <Option value="">All Status</Option>
            <Option value="1">Active</Option>
            <Option value="0">Inactive</Option>
          </Select>
        </Col>
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Agency</h5>
          </Divider>
          <Select
            onChange={value => {
              props.handleFilterChange(value, "agency")
            }}
            style={{ width: "100%" }}
            defaultValue={props.filters["agency"]}
            placeholder="Agency"
            allowClear
          >
            <Option value="">All Agencies</Option>
            <Option value="PALETTEINTERACTIVE">Palette Interactive</Option>
            <Option value="SRMFILMS">SRM Films</Option>
            <Option value="OPPORTURE">Opporture</Option>
            <Option value="INTERNAL">Internal</Option>
            <Option value="HEXAWARE">Hexaware</Option>
            <Option value="NO_AGENCY">No Agency</Option>
          </Select>
        </Col>
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Permissions</h5>
          </Divider>
          <Select
            onChange={value => {
              props.handleFilterChange(value, "permissions")
            }}
            style={{ width: "100%" }}
            defaultValue={props.filters["permissions"]}
            placeholder="Permission"
            allowClear
          >
            <React.Fragment>
              {PERMISSIONS_ARRAY.map(permission => (
                <Option value={permission.value}>{permission.label}</Option>
              ))}
              <Option value="">Permissions</Option>
              {props.currentUser.jotUserType > 1 && (
                <React.Fragment>
                  <Option value="TAG_CONTENT_ADMIN">Tag Content Admin</Option>
                  <Option value="COMMUNITY_MANAGER">Community Manager</Option>
                  <Option value="VIEW_REAL_STATS">View Real Stats</Option>
                  <Option value="MANAGE_CMS_USERS">Manage CMS Users</Option>
                </React.Fragment>
              )}
            </React.Fragment>
          </Select>
        </Col>
        <Col lg="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">User Type</h5>
          </Divider>
          <Select
            onChange={value => {
              props.handleFilterChange(value, "jotUserType")
            }}
            style={{ width: "100%" }}
            defaultValue={props.filters["jotUserType"]}
            placeholder="User Type"
            allowClear
          >
            <Option value="">User Type</Option>
            {Object.keys(userTypeMap).map(type => (
              <Option value={type}>{userTypeMap[type]}</Option>
            ))}
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

UserDrawer.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(UserDrawer)
