import { createSlice } from "@reduxjs/toolkit"

const packageSlice = createSlice({
  name: "package",
  initialState: {
    pageList: [],
    total: 0,
    loading: false,
    pageEntitiesByLanguageLocation: [],
    pageEntities: [],
    tabs: [],
    collectionEntitiesGroups: [],
    collectionEntities: [],
    entities: [],
    totalPageEntities: 0,
    refetchData: false,
  },
  reducers: {
    getPackage(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        entities: [],
      }
    },
    getPackageSuccess(state, { payload }) {
      return {
        ...state,
        entities: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    upsertPackage(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertPackageSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertPackageCollection(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertPackageCollectionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    upsertPackageCollectionEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertPackageCollectionEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    deletePackage(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    deletePackageSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    resetState(state) {
      return {
        ...state,
        pageList: [],
        total: 0,
        loading: false,
        refetchData: false,
        pageEntitiesByLanguageLocation: [],
        pageEntities: [],
        tabs: [],
        collectionEntitiesGroups: [],
        collectionEntities: [],
        totalPageEntities: 0,
      }
    },
  },
})

export const {
  getPackage,
  getPackageSuccess,
  upsertPackage,
  upsertPackageSuccess,
  upsertPackageCollection,
  upsertPackageCollectionSuccess,
  upsertPackageCollectionEntity,
  upsertPackageCollectionEntitySuccess,
  deletePackage,
  deletePackageSuccess,
  resetState,
} = packageSlice.actions

export default packageSlice.reducer
