import { createSlice } from "@reduxjs/toolkit"

const cmsVideoTemplatesSlice = createSlice({
  name: "cmsVideoTemplates",
  initialState: {
    cmsVideoTemplates: [],
    cmsVideoGreetings: [],
    loading: false,
    captions: null,
    previewFile: null,
  },
  reducers: {
    getCMSVideoTemplateListByFilters(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getCMSVideoTemplateListByFiltersSuccess(state, payload) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        cmsVideoTemplates: payload.payload.data,
      }
    },
    getCMSVideoGreetingsListByFilters(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    setCaptions(state, payload) {
      return {
        ...state,
        captions: payload.payload,
      }
    },
    getCMSVideoGreetingsListByFiltersSuccess(state, payload) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        cmsVideoGreetings: payload.payload.data,
      }
    },
    upsertCMSVideoTemplate(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertCMSVideoGreetings(state) {
      return {
        ...state,
        previewFile: null,
        loading: true,
      }
    },

    upsertCMSVideoGreetingsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: true,
        previewFile: payload.data,
      }
    },

    upsertCMSVideoTemplateSuccess(state, payload) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState(state) {
      return {
        loading: false,
        refetchData: false,
        cmsVideoTemplates: [],
        captions: null,
        previewFile: null,
      }
    },
  },
})

export const {
  getCMSVideoTemplateListByFilters,
  getCMSVideoTemplateListByFiltersSuccess,
  upsertCMSVideoTemplate,
  upsertCMSVideoGreetings,
  upsertCMSVideoGreetingsSuccess,
  upsertCMSVideoTemplateSuccess,
  getCMSVideoGreetingsListByFilters,
  getCMSVideoGreetingsListByFiltersSuccess,
  resetState,
  setCaptions,
} = cmsVideoTemplatesSlice.actions

export default cmsVideoTemplatesSlice.reducer
