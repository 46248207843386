import React from "react"
import { connect } from "react-redux"
import { Upload } from "antd"
import { Row, Col, Button } from "reactstrap"
import ValidatorForm from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { uploadBulkStickers } from "appRedux/slices/stickers"
import _ from "lodash"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
//import "./styles.css"

class UploadBulkStickersForm extends ErrorBoundComponent {
  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }

  _onStickerRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col style={{ marginTop: "10px" }}>
            <Upload
              showUploadList={true}
              onRemove={this._onStickerRemove}
              defaultFileList={this.state.csvFileList}
              fileList={this.state.csvFileList}
              customRequest={this._uploadCSV}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <Button color="primary">
                <i
                  className="bx bx-upload
me-2"
                />{" "}
                Click to upload CSV
              </Button>
            </Upload>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-end">
              <Button
                htmlType="submit"
                color="primary"
                disabled={this.state.loading}
                className="font-16 btn-block btn btn-primary"
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                {/* <i className="bx bx-upload me-1" /> */}
                Submit
              </Button>
              <Button
                color="danger"
                size="default"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (!this.state.csvFile) {
      toastr.error("Please upload the csv !!")
      return
    }

    formData.bulk_upload = true

    let data = new FormData()
    data.append("file", this.state.csvFile)

    this.props.dispatch(uploadBulkStickers(data))
    this.props.onCancel()
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    loading: _.get(store, "user.loading"),
    common: _.get(store, "common"),
  }
}

export default connect(mapStateToProps)(UploadBulkStickersForm)
