import React from "react"
import { connect } from "react-redux"
import toastr from "toastr"
import { Tooltip } from "antd"
import { Card, CardBody, CardImg } from "reactstrap"
import {
  getCMSVideoAssetListByFilters,
  upsertCMSVideoAsset,
  resetState,
} from "../../appRedux/slices/cmsVideoAssets"
import UpsertCMSVideoAssetsForm from "./upsertCMSVideoAssetsForm"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"

class ManageCMSVideoAssets extends ErrorBoundComponent {
  state = {
    filters: {},
    showUpsertModal: false,
    videoAsset: {},
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getCMSVideoAssetListByFilters(_.deepClean(this.state.filters))
      )
    }
  }

  componentDidUpdate() {
    if (!this.props.common.error && this.props.refetchData) {
      this._closeUpsertModal()
      this.props.dispatch(
        getCMSVideoAssetListByFilters(_.deepClean(this.state.filters))
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onSubmit = async formData => {
    this.props.dispatch(upsertCMSVideoAsset(formData))
  }

  _closeUpsertModal = () => {
    this.setState({
      showUpsertModal: false,
      formAction: "create",
      videoAsset: {},
    })
  }

  _onCreateVideoAsset = () => {
    this.setState({
      showUpsertModal: true,
      formAction: "create",
      videoAsset: {},
    })
  }

  _onEditVideoAsset = videoAsset => {
    this.setState({
      showUpsertModal: true,
      formAction: "update",
      videoAsset: videoAsset,
    })
  }

  _upsertModal = () => {
    return (
      <div className="view_container">
        <UpsertCMSVideoAssetsForm
          formAction={this.state.formAction}
          videoAsset={this.state.videoAsset}
          onSubmit={this._onSubmit}
          onCancel={this._closeUpsertModal}
        />
      </div>
    )
  }

  _actionButtons = () => {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-outline-primary font-size-15 mb-4"
          onClick={this._onCreateVideoAsset}
        >
          Create
        </button>
      </div>
    )
  }
  _listCmsVideoAssets = () => {
    return (
      <div className="discovery-card-container">
        {this.props.cmsVideoAssets.map(videoAsset => (
          <Card
            className="text-center shadow-lg"
            style={{
              minHeight: "250px",
              borderRadius: "10px",
            }}
          >
            <CardBody className="px-0 py-0">
              <CardImg
                src={videoAsset.sourceFile}
                width="100%"
                height="450px"
                alt="asset"
                className="p-1"
              />
              <Tooltip title={videoAsset.name} style={{ minHeight: "20px" }}>
                <div className="font-size-16 text-truncate font-weight-semibold">
                  <i className={`mx-1`} /> {videoAsset.name}
                </div>
              </Tooltip>
              <Tooltip title={videoAsset.type} style={{ minHeight: "20px" }}>
                <div
                  className="my-1 mb-1 text-muted"
                  style={{ fontSize: "14px", wordBreak: "break-word" }}
                >
                  {videoAsset.type}
                </div>
              </Tooltip>
            </CardBody>
            <div
              className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
              onClick={e => e.stopPropagation()}
            >
              <div className="flex-fill">
                <CopyToClipboard
                  text={videoAsset.uuid}
                  onCopy={e => {
                    toastr.success("Copied sucessfully!")
                  }}
                >
                  <Tooltip title="Copy ID">
                    <i className="bx bx-copy-alt" role="button" />
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <div className="flex-fill">
                <Tooltip title="Edit">
                  <i
                    className="bx bx-edit-alt"
                    role="button"
                    onClick={e => {
                      if (
                        !this.props.currentUser?.permissions?.includes(
                          "EDIT_CMS_VIDEO_ASSET"
                        ) &&
                        this.props?.location?.pathname.split("/")[1] ===
                          "cmsVideoAssets"
                      ) {
                        toastr.error(NO_PERMISSION_MESSAGE)
                        return
                      }
                      this._onEditVideoAsset(videoAsset)
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </Card>
        ))}
      </div>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showUpsertModal ? (
          <>
            {this._actionButtons()}
            {this._listCmsVideoAssets()}
          </>
        ) : null}
        {this.state.showUpsertModal && this._upsertModal()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    cmsVideoAssets: _.get(store, "cmsVideoAssets.cmsVideoAssets"),
    refetchData: _.get(store, "cmsVideoAssets.refetchData"),
    loading: _.get(store, "cmsVideoAssets.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageCMSVideoAssets)
