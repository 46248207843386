import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"

const ZoneCard = props => {
  const { data } = props
  const isSelected = props.zoneData && props.zoneData.includes(data.zone_uuid)
  const status = isSelected ? "REMOVE" : "ADD"
  const imgSource = data.zone_image
    ? data.zone_image
    : "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/default/default.jpg"
  return (
    <div
      className={
        props.className ||
        `question-card text-center contentCard ${
          isSelected ? "selectedCard" : ""
        }`
      }
      onClick={() => props._handleSelection(data, status, props.action)}
      style={{
        width: props.width || "7rem",
        height: props.height || "10.625rem",
      }}
    >
      <div className="card-img-container">
        {/* <Popover
          trigger="hover"
          content={() => props.renderCardContent(data)}
          color="#D3D3D3"
          mouseEnterDelay="1"
          destroyTooltipOnHide
        > */}
        <div
          style={{
            position: "absolute",
            top: "2px",
            left: "50px",
            width: "80px",
          }}
          className="d-flex justify-content-around"
        >
          {
            <div className="level-icon mx-2">
              {/* <Tooltip title="Level"> */}
              {data.level === "2" ? "L2" : data.level === "1" ? "L1" : "L0"}
              {/* </Tooltip> */}
            </div>
          }
        </div>
        <img
          className="card-img-top-custom"
          src={imgSource}
          alt="Card image caption"
        />
        {/* </Popover> */}
      </div>
      <div
        className="bg-transparent d-flex justify-content-between align-items-center w-100"
        style={{ padding: "7px", flex: "auto" }}
      >
        {/* <Tooltip title={props.title || data.title}> */}
        <div className="card-text-footer">{props.title || data.title}</div>
        {/* </Tooltip> */}
        {/* <Popover
          trigger="hover"
          color="#D3D3D3"
          content={() => props.renderCardContent(data)}
          destroyTooltipOnHide
        > */}
        {/* <div style={{ marginLeft: "2px" }}>
            <InfoCircleOutlined size={60} />
          </div> */}
        {/* </Popover> */}
      </div>
    </div>
  )
}

ZoneCard.PropTypes = {
  data: PropTypes.object.isRequired,
  _handleSelection: PropTypes.func,
  renderCardContent: PropTypes.func,
}

export default ZoneCard
