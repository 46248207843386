import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import QueryString from "query-string"
import { Divider, Tabs, Switch, Card, Tooltip, message } from "antd"
import { Col, Row } from "reactstrap"
import { Button } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import "./cameraReorder.css"
import _, { cloneDeep } from "lodash"
import { AiOutlineClose, AiOutlineCopy } from "react-icons/ai"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { getPageEntitiesByLanguageLocations } from "appRedux/slices/zeroSearch"

import { REORDERLANGUAGES } from "constants/uiConstants"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import SearchAndSelect from "components/SearchAndSelect/searchAndSelectV2"
import { cameraReorder, cameraReorderList } from "clientServices/cameraService"
import {
  requestChallengeByFilters,
  requestTemplatesList,
} from "clientServices/challengeService"
import CopyToClipboard from "react-copy-to-clipboard"
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const { TabPane } = Tabs

class CameraReorder extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeKey: 0,
      flag: false,
      selectedlang: "default",
      showDefaultOrder: false,
      activeTab: undefined,
      challengeInfo: [],
      templateInfo: [],
      items: REORDERLANGUAGES.map((item, index) => {
        return {
          key: index,
          label: item.label,
          value: item.value,
          children: this._renderCards(item),
        }
      }),
      hasEditPermission:
        this.props.currentUser &&
        this.props.currentUser.permissions &&
        this.props.currentUser.permissions.includes("EDIT_DISCOVERY_PAGE"),
      challenges: REORDERLANGUAGES.reduce(
        (ac, { ["value"]: x, ...rest }) => ((ac[x] = []), ac),
        {}
      ),
      templates: REORDERLANGUAGES.reduce(
        (ac, { ["value"]: x, ...rest }) => ((ac[x] = []), ac),
        {}
      ),
    }
  }

  async componentDidMount() {
    let reorderList = await cameraReorderList(
      REORDERLANGUAGES.map(item => item.value)
    )
    console.log(this.state.challenges)
    let items = cloneDeep(this.state.items)
    let challengeList = await requestChallengeByFilters({ pageSize: 10000 })

    let templateList = await requestTemplatesList({
      filters: { pageSize: 10000 },
    })
    this.setState({
      challenges: reorderList.data.challenges,
      templates: reorderList.data.templates,
      selectedlang: REORDERLANGUAGES[0].value,
      items,
      activeKey: 0,
      challengeInfo: challengeList.data.data,
      templateInfo: templateList.data.data,
    })
  }

  _onDragEndTemplate = result => {
    // dropped outside the list

    // console.log(result)
    // const prevEntityList = this.state?.challenge
    const prevEntityList = this.state.templates[this.state.selectedlang]
    if (!result.destination) {
      return
    }

    const pageEntities = this._reorder(
      prevEntityList,
      result.source.index,
      result.destination.index
    )
    let templateState = cloneDeep(this.state.templates)
    templateState[this.state.selectedlang] = pageEntities
    this.setState({
      templates: templateState,
    })

    // console.log(this.state)
  }
  _onDragEnd = result => {
    // dropped outside the list

    // console.log(result)
    // const prevEntityList = this.state?.challenge
    const prevEntityList = this.state.challenges[this.state.selectedlang]
    if (!result.destination) {
      return
    }

    const pageEntities = this._reorder(
      prevEntityList,
      result.source.index,
      result.destination.index
    )
    let challengeState = cloneDeep(this.state.challenges)
    challengeState[this.state.selectedlang] = pageEntities
    this.setState({
      challenges: challengeState,
    })

    // console.log(this.state)
  }
  removeChallenge = index => {
    let challengearr = cloneDeep(this.state.challenges)
    challengearr[this.state.selectedlang].splice(index, 1)
    let items = cloneDeep(this.state.items)
    this.setState({
      challenges: challengearr,
      flag: !this.state.flag,
      items,
    })
  }
  removeTemplate = index => {
    let templatearr = cloneDeep(this.state.templates)
    templatearr[this.state.selectedlang].splice(index, 1)
    let items = cloneDeep(this.state.items)
    this.setState({
      templates: templatearr,
      flag: !this.state.flag,
      items,
    })
  }
  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }
  _renderCards = languageMap => {
    return (
      <Row key={this.state.flag} className="p-0 mb-2">
        <Col lg="6" md="6">
          <Button
            color="primary"
            style={{ marginBottom: "5px" }}
            onClick={() =>
              this.setState({
                selectChallengeModal: true,
              })
            }
          >
            Select Challenge
          </Button>

          <DragDropContext key={this.state.flag} onDragEnd={this._onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.challenges[languageMap.value].map(
                    (tab, index) => (
                      <Draggable
                        key={`${tab}`}
                        draggableId={`${tab}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Row>
                              <Col>
                                <Card
                                  className="cardStyle d-flex align-items-center"
                                  cover={
                                    <img
                                      alt="image"
                                      // src="https://stream.coolfie.io/stream/prod-ugc-contents/zerosearch/thumbnails/0a9cbabb5ac44725/82b24ff6f32882a2/0a9cbabb5ac4472582b24ff6f32882a2.jpg"
                                      src={
                                        this.state.challengeInfo.filter(
                                          item => item.challenge_id == tab
                                        ).length > 0
                                          ? this.state.challengeInfo.filter(
                                              item => item.challenge_id == tab
                                            )[0].banner_url
                                          : ""
                                      }
                                      style={{
                                        width: 60,
                                        height: 60,
                                      }}
                                      className="me-3"
                                    />
                                  }
                                  bordered={true}
                                  style={{
                                    padding: "10px 20px",
                                    display: "flex",

                                    // width: "50px",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginLeft: "auto",
                                    }}
                                  >
                                    {this.state.challengeInfo.filter(
                                      item => item.challenge_id == tab
                                    ).length > 0
                                      ? this.state.challengeInfo.filter(
                                          item => item.challenge_id == tab
                                        )[0].hashtag
                                      : ""}
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: "auto",
                                      width: "100px",
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <CopyToClipboard
                                      text={tab}
                                      onCopy={e => {
                                        toastr.success("Copied sucessfully!")
                                      }}
                                    >
                                      <Tooltip title="Copy Challenge ID">
                                        <i
                                          className="bx bx-copy"
                                          role="button"
                                        />
                                      </Tooltip>
                                    </CopyToClipboard>
                                    <AiOutlineClose
                                      onClick={() =>
                                        this.removeChallenge(index)
                                      }
                                    />
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
        <Col lg="6" md="6">
          <Button
            color="primary"
            style={{ marginBottom: "5px" }}
            onClick={() =>
              this.setState({
                selectTemplateModal: true,
              })
            }
          >
            Select Template
          </Button>

          <DragDropContext
            key={this.state.flag}
            onDragEnd={this._onDragEndTemplate}
          >
            <Droppable droppableId="droppable2">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.templates[languageMap.value].map((tab, index) => (
                    <Draggable
                      key={`${tab}`}
                      draggableId={`${tab}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Row>
                            <Col>
                              <Card
                                className="cardStyle d-flex align-items-center"
                                cover={
                                  <img
                                    alt="image"
                                    // src="https://stream.coolfie.io/stream/prod-ugc-contents/zerosearch/thumbnails/0a9cbabb5ac44725/82b24ff6f32882a2/0a9cbabb5ac4472582b24ff6f32882a2.jpg"
                                    src={
                                      this.state.templateInfo.filter(
                                        item => item.template_uuid == tab
                                      ).length > 0
                                        ? this.state.templateInfo.filter(
                                            item => item.template_uuid == tab
                                          )[0].static_thumbnail
                                        : ""
                                    }
                                    style={{
                                      width: 60,
                                      height: 60,
                                    }}
                                    className="me-3"
                                  />
                                }
                                bordered={true}
                                style={{
                                  padding: "10px 20px",
                                  display: "flex",
                                  // width: "50px",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "auto",
                                  }}
                                >
                                  {this.state.templateInfo.filter(
                                    item => item.template_uuid == tab
                                  ).length > 0
                                    ? this.state.templateInfo.filter(
                                        item => item.template_uuid == tab
                                      )[0].asset_label
                                    : ""}
                                </div>
                                <div
                                  style={{
                                    marginLeft: "auto",
                                    width: "100px",
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <CopyToClipboard
                                    text={tab}
                                    onCopy={e => {
                                      toastr.success("Copied sucessfully!")
                                    }}
                                  >
                                    <Tooltip title="Copy Template ID">
                                      <i className="bx bx-copy" role="button" />
                                    </Tooltip>
                                  </CopyToClipboard>
                                  <AiOutlineClose
                                    onClick={() => this.removeTemplate(index)}
                                  />
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
    )
  }
  onChange = key => {
    this.setState({
      selectedlang: REORDERLANGUAGES[key].value,
      activeKey: key,
    })
    console.log(this.props.challengeList)
  }
  onSave = async () => {
    // console.log(this.state.challenge)
    await cameraReorder({
      language: this.state.selectedlang,
      challenges: this.state.challenges[this.state.selectedlang],
      templates: this.state.templates[this.state.selectedlang],
    })
  }
  render() {
    return (
      <Auxiliary
        loading={this.props.loading && this.props.tabs.length !== 0}
        error={_.get(this.props, "common.error")}
      >
        <React.Fragment>
          <Button
            color="primary"
            style={{ float: "Right" }}
            onClick={() => this.onSave()}
          >
            Save
          </Button>
          <Tabs
            activeKey={this.state.activeKey}
            items={this.state.items}
            onChange={this.onChange}
            destroyInactiveTabPane={true}
          />
        </React.Fragment>
        {this.state.selectChallengeModal && (
          <SearchAndSelect
            type="CHALLENGE"
            visible={this.state.selectChallengeModal}
            onClose={() => this.setState({ selectChallengeModal: false })}
            onAdd={arr => {
              let challengearr = cloneDeep(this.state.challenges)
              challengearr[this.state.selectedlang] = []
              let items = cloneDeep(this.state.items)
              challengearr[this.state.selectedlang].push(...arr)
              this.setState({
                challenges: challengearr,
                flag: !this.state.flag,
                items,
              })
            }}
            selectedArr={this.state.challenges[this.state.selectedlang]}
          />
        )}
        {this.state.selectTemplateModal && (
          <SearchAndSelect
            type="TEMPLATE"
            visible={this.state.selectTemplateModal}
            onClose={() => this.setState({ selectTemplateModal: false })}
            onAdd={arr => {
              let templatesarr = cloneDeep(this.state.templates)
              templatesarr[this.state.selectedlang] = []
              let items = cloneDeep(this.state.items)
              templatesarr[this.state.selectedlang].push(...arr)
              this.setState({
                templates: templatesarr,
                flag: !this.state.flag,
                items,
              })
            }}
            selectedArr={this.state.templates[this.state.selectedlang]}
          />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zeroSearch.totalEntities"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "zeroSearch.loadingEntities"),
    entityList: _.get(store, "zeroSearch.entities"),
    pageEntities: _.get(store, "zeroSearch.pageEntitiesByLanguageLocation"),
    tabs: _.get(store, "zeroSearch.tabs"),
    refetch: _.get(store, "zeroSearch.refetchData"),
    locationList: _.get(store, "locationReducer.locationList"),
    loadingPageEntities: _.get(store, "zeroSearch.pageEntityLoading"),
    languageList: _.get(store, "uiConstants.languageList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    challengeList: _.get(store, "challenge.challengeList"),
  }
}

export default connect(mapStateToProps)(CameraReorder)
