import React from "react"
import { Divider } from "antd"
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Card, CardBody, Row, Col } from "reactstrap"
import { ChipsInput, MultiSelect, TextField } from "../ValidatorForm"
import { Button } from "reactstrap"
import { Form } from "react-bootstrap"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import ZonePreviewImageForm from "./zonePreviewImageForm"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"

export default class ZoneUpsertForm extends ErrorBoundComponent {
  state = {
    formData: {
      cover_banner: [
        {
          id: uuidv4(),
          view_order: 0,
          type: "image",
          cta_data: {
            inline_cta_text: "",
          },
          isExist: false,
        },
      ],
      preview_image: [],
      fileList: {},
      zone_target: [],
      fan_labels: {},
      level: 0,
      sub_type: [],
      type: [],
      status: "DRAFT",
      zone_type: "COLLECTION",
    },
    showAudioDetails: false,
    action: this.props.action,
    errors: {},
    status: "DRAFT",
    selectedLangs: [],
  }

  componentDidMount() {
    const colors = []

    Array.from(Array(10).keys()).forEach(_ => {
      colors.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
    })

    this.setState({
      colors,
    })

    const zone = _.cloneDeep(this.props.zone)
    let selectedLangs = [...this.state.selectedLangs]

    if (!_.isEmpty(zone)) {
      let fan_labels = this.state.formData.fan_labels

      if (!_.isEmpty(zone.fan_labels)) {
        if (zone.fan_labels.value[0]) {
          fan_labels.top = zone.fan_labels.value[0]
        }
        if (zone.fan_labels.value[1]) {
          fan_labels.super = zone.fan_labels.value[1]
        }
      }

      if (!_.isEmpty(zone.cover_banner)) {
        const cover_banner = _.cloneDeep(zone.cover_banner)
        cover_banner.forEach(banner => {
          banner.isExist = true
        })
        zone.cover_banner = cover_banner
      }

      let zoneTargets = _.isEmpty(zone.zone_target)
        ? []
        : _.cloneDeep(zone.zone_target)

      if (!_.isEmpty(zoneTargets)) {
        zoneTargets.forEach(zone => {
          let fan_labels = {}
          if (!_.isEmpty(zone.fan_labels)) {
            if (zone.fan_labels.value[0]) {
              fan_labels.top = zone.fan_labels.value[0]
            }
            if (zone.fan_labels.value[1]) {
              fan_labels.super = zone.fan_labels.value[1]
            }
          }
          zone.fan_labels = fan_labels
        })
      }

      let formData = {
        title: zone.title,
        sub_title: zone.sub_title,
        allow_follow: zone.allow_follow,
        description: zone.description,
        lang_code: zone.lang_code,
        level: zone.level ? zone.level : 0,
        status: zone.status ? zone.status : "DRAFT",
        sub_type: _.isEmpty(zone.sub_type) ? [] : zone.sub_type,
        type: _.isEmpty(zone.type) ? [] : zone.type,
        cover_banner: zone.cover_banner ? zone.cover_banner : [],
        hashtag: zone.hashtag ? zone.hashtag.join(",") : "",
        alias: zone.alias ? zone.alias.join(",") : "",
        enabled_tabs: _.isEmpty(zone.enabled_tabs) ? [] : zone.enabled_tabs,
        zone_target: zoneTargets,
        zone_type: zone.zone_type,
        target_languages: _.isEmpty(zone.target_languages)
          ? []
          : zone.target_languages,
        fan_labels,
        auto_scroll_ms: zone.auto_scroll_ms,
        preview_image: zone.prev_images || [],
        tagged_videos: zone.tagged_videos || [],
      }

      this.setState({
        formData: {
          ...this.state.formData,
          ...formData,
        },
        selectedLangs: _.uniq(selectedLangs),
      })
    }
  }

  _findFormErrors = () => {
    const newErrors = {}
    const { title } = this.state.formData
    if (!title || title === "")
      newErrors.title = "Please provide the zone title!"
    return newErrors
  }

  disabledReview = () => {
    let disable = false
    if (this.props.zone?.zone_image) {
      if (this.props.zone?.cover_banner) {
        for (let i = 0; i < this.props.zone?.cover_banner.length; i++) {
          if (this.props.zone?.cover_banner[i]?.thumbnail) {
            disable = true
            break
          }
        }
      }
      return disable
    }
  }

  _handleFieldChange = (field, value, parent, index) => {
    if (parent) {
      if (parent === "cover_banner") {
        const cover_banner = _.cloneDeep(this.state.formData.cover_banner)
        if (field === "cta_data") {
          cover_banner[index] = {
            ...cover_banner[index],
            cta_data: {
              inline_cta_text: value,
            },
          }
        } else {
          cover_banner[index] = {
            ...cover_banner[index],
            [field]: value,
          }
        }
        this.setState({
          formData: {
            ...this.state.formData,
            cover_banner,
          },
        })
      } else if (parent === "fan_labels") {
        let fan_labels = { ...this.state.formData.fan_labels }
        fan_labels[field] = value

        this.setState({
          formData: {
            ...this.state.formData,
            fan_labels,
          },
        })
      }
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [field]: value,
        },
        errors: {
          ...this.state.errors,
          [field]: null,
        },
      })
    }
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      formData: {
        ...this.state.formData,
        fileList: {
          ...this.state.formData.fileList,
          [key]: file,
        },
      },
    })
  }

  _handleTargetFieldsChange = (field, value, index, imageIndex, parent) => {
    const zone_target = [...this.state.formData.zone_target]
    if (parent) {
      if (parent === "cover_banner") {
        const cover_banner = this.state.formData.zone_target[index].cover_banner

        if (field === "cta_data") {
          cover_banner[imageIndex] = {
            ...cover_banner[imageIndex],
            cta_data: {
              inline_cta_text: value,
            },
          }
        } else {
          cover_banner[imageIndex] = {
            ...cover_banner[imageIndex],
            [field]: value,
          }
        }

        zone_target[index] = {
          ...zone_target[index],
          cover_banner,
        }

        this.setState({
          formData: {
            ...this.state.formData,
            zone_target,
          },
        })
      } else if ((parent = "fan_labels")) {
        let fan_labels = _.isEmpty(
          this.state.formData.zone_target[index].fan_labels
        )
          ? {}
          : this.state.formData.zone_target[index].fan_labels
        fan_labels[field] = value

        zone_target[index] = {
          ...zone_target[index],
          fan_labels,
        }
        this.setState({
          formData: {
            ...this.state.formData,
            zone_target,
          },
        })
      } else {
        zone_target[index] = {
          ...zone_target[index],
          [parent]: {
            ...zone_target[index][parent],
            [field]: value,
          },
        }
        this.setState({
          formData: {
            ...this.state.formData,
            zone_target,
          },
        })
      }
    } else {
      if (field === "lang_code") {
        const selectedLangs = [...this.state.selectedLangs]
        selectedLangs[index] = value
        this.setState({
          selectedLangs,
        })
      }
      zone_target[index] = {
        ...zone_target[index],
        [field]: value,
      }
      this.setState({
        formData: {
          ...this.state.formData,
          zone_target,
        },
      })
    }
  }

  _addTarget = () => {
    const zone_target = [
      ...this.state.formData.zone_target,
      {
        cover_banner: [
          {
            id: uuidv4(),
            view_order: 0,
            type: "image",
          },
        ],
        fan_labels: {},
      },
    ]

    this.setState({
      formData: {
        ...this.state.formData,
        zone_target,
      },
    })
  }

  _renderTargetForm = (target, index, color) => {
    return (
      <>
        <Row>
          <Col lg="6">
            <label className="col-form-label">Language</label>
            <div>
              <select
                className="form-select"
                onChange={e =>
                  this._handleTargetFieldsChange(
                    "lang_code",
                    e.target.value,
                    index
                  )
                }
                defaultValue={target.lang_code}
                value={target.lang_code}
              >
                <option value="">{`<----- Select Language ----->`}</option>
                {LANGUAGES.map(lang => (
                  <>
                    <option value={lang.value} key={lang.value}>
                      {lang.label}
                    </option>
                  </>
                ))}
              </select>
            </div>
          </Col>
          <Col lg="6">
            <Form.Group>
              <Form.Label className="col-form-label">Title</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                onChange={e =>
                  this._handleTargetFieldsChange("title", e.target.value, index)
                }
                defaultValue={target.title}
              />
            </Form.Group>
          </Col>

          <Col lg="6">
            <Form.Group>
              <Form.Label>Sub Title</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                onChange={e =>
                  this._handleTargetFieldsChange(
                    "sub_title",
                    e.target.value,
                    index
                  )
                }
                defaultValue={target.sub_title}
              />
            </Form.Group>
          </Col>

          <Col lg="6">
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="form-control"
                as="textarea"
                onChange={e =>
                  this._handleTargetFieldsChange(
                    "description",
                    e.target.value,
                    index
                  )
                }
                defaultValue={target.description}
              />
            </Form.Group>
          </Col>
        </Row>
        <Divider style={{ color }}>Cover Banner</Divider>
        <Row className="mb-3">
          {target.cover_banner &&
            target.cover_banner.map((imageDsta, imgIndex) => {
              return (
                <div key={imgIndex + imageDsta.image_uuid}>
                  {this._renderTargetImageDetails(imageDsta, imgIndex, index)}
                </div>
              )
            })}
          <div className="d-flex justify-content-start mt-3">
            <div
              className="btn btn-primary"
              onClick={() => this._addTargetImages(target, index)}
              role="button"
            >
              Add Banner
            </div>
          </div>
        </Row>

        <Divider style={{ color }}>Fan Labels</Divider>
        <Row className="mb-5">
          <div className="d-flex justify-content-start mt-3">
            <Col lg="2">
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  defaultValue={"Top"}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col lg="3">
              <Form.Group>
                <Form.Label>Value</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  onChange={e =>
                    this._handleTargetFieldsChange(
                      "top",
                      e.target.value,
                      index,
                      null,
                      "fan_labels"
                    )
                  }
                  defaultValue={target.fan_labels.top}
                />
              </Form.Group>
            </Col>
            <Col lg="2" className="ms-5">
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  defaultValue={"Super"}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col lg="3">
              <Form.Group>
                <Form.Label>Value</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  onChange={e =>
                    this._handleTargetFieldsChange(
                      "super",
                      e.target.value,
                      index,
                      null,
                      "fan_labels"
                    )
                  }
                  defaultValue={target.fan_labels.super}
                />
              </Form.Group>
            </Col>
          </div>
        </Row>
      </>
    )
  }

  _onSubmit = () => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_ZONE")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }

    const errors = this._findFormErrors()
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      })
      return
    }

    let formData = { ...this.state.formData }
    if (formData.alias) {
      formData.alias = formData.alias.split(",")
    } else {
      formData.alias = []
    }
    if (formData.level == 0) {
      delete formData.type
      delete formData.sub_type
    } else if (formData.level == 1) {
      delete formData.sub_type
    }
    if (!_.isEmpty(formData.fan_labels)) {
      const types = []
      const values = []
      let shouldUpdate = false
      if (formData.fan_labels.top) {
        shouldUpdate = true
        types.push("top")
        values.push(formData.fan_labels.top)
      }
      if (formData.fan_labels.super) {
        shouldUpdate = true
        types.push("super")
        values.push(formData.fan_labels.super)
      }

      if (shouldUpdate) {
        formData.fan_labels = {
          type: types,
          value: values,
        }
      }
    }

    if (formData.hashtag) {
      formData.hashtag = formData.hashtag.split(",")
    } else {
      formData.hashtag = []
    }

    if (this.props.formAction === "update") {
      formData.zone_uuid = this.props.zone.zone_uuid
    }

    let data = new FormData()

    Object.keys(formData.fileList).forEach(fileName => {
      data.append(fileName, formData.fileList[fileName])
    })

    delete formData?.fileList

    if (_.isEmpty(formData.zone_target)) {
      delete formData.zone_target
    } else {
      formData.zone_target.forEach(zone => {
        if (!_.isEmpty(zone.fan_labels)) {
          const types = []
          const values = []
          let shouldUpdate = false
          if (zone.fan_labels.top) {
            shouldUpdate = true
            types.push("top")
            values.push(zone.fan_labels.top)
          }
          if (zone.fan_labels.super) {
            shouldUpdate = true
            types.push("super")
            values.push(zone.fan_labels.super)
          }

          if (shouldUpdate) {
            zone.fan_labels = {
              type: types,
              value: values,
            }
          }
        }
      })
    }

    data.append("details", JSON.stringify(formData))
    if (this.props.formAction === "update") {
      data.append("action", "UPDATE")
    }
    this.props.onSubmit(data)
  }

  _addImages = () => {
    let cover_banner = [...this.state.formData.cover_banner]
    cover_banner = [
      ...cover_banner,
      {
        id: uuidv4(),
        view_order: cover_banner.length,
        type: "image",
        isExist: false,
      },
    ]
    this.setState({
      formData: {
        ...this.state.formData,
        cover_banner,
      },
    })
  }

  _addTargetImages = (target, index) => {
    let cover_banner = [...target.cover_banner]
    cover_banner = [
      ...cover_banner,
      {
        type: "image",
        id: uuidv4(),
        view_order: cover_banner.length,
        isExist: false,
      },
    ]

    let zone_target = this.state.formData.zone_target
    zone_target[index] = {
      ...zone_target[index],
      cover_banner,
    }

    this.setState({
      formData: {
        ...this.state.formData,
        zone_target,
      },
    })
  }

  _renderUpsertForm = () => {
    return (
      <Card>
        <CardBody>
          <div className="d-flex justify-content-end mt-3">
            <div
              className="btn btn-success"
              onClick={this._onSubmit}
              role="button"
            >
              {this.props.formAction === "update" ? `Update` : `Create`}
            </div>
            <Button
              color="danger"
              size="default"
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                return
              }}
              onClick={() => this.props.history.push(`/zone/browse`)}
              className="mx-2"
            >
              Cancel
            </Button>
          </div>
          <Row className="mb-3">
            {this.props.enableAnyway && (
              <Col lg="12">
                <div className="form-check form-switch form-switch-lg mt-3 pt-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={this.state.formData.allow_anyway}
                    onChange={e =>
                      this._handleFieldChange("allow_anyway", e.target.checked)
                    }
                  />
                  <label className="form-check-label">
                    {this.props.formAction === "update"
                      ? `Update Anyway`
                      : `Create Anyway`}
                  </label>
                </div>
              </Col>
            )}
            <Col lg="3">
              <label className="col-form-label">Language</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange("lang_code", e.target.value)
                  }
                  value={this.state.formData.lang_code}
                >
                  <option value="">{`<----- Select Language ----->`}</option>

                  {LANGUAGES.map(lang => (
                    <option value={lang.value} key={lang.value}>
                      {lang.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col lg="5">
              <Form.Group>
                <label className="col-form-label">Title</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  onChange={e =>
                    this._handleFieldChange("title", e.target.value)
                  }
                  defaultValue={this.state.formData.title}
                  isInvalid={!!this.state.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg="4">
              <Form.Group>
                <label className="col-form-label">Sub Title</label>
                <Form.Control
                  className="form-control"
                  type="text"
                  onChange={e =>
                    this._handleFieldChange("sub_title", e.target.value)
                  }
                  defaultValue={this.state.formData.sub_title}
                />
              </Form.Group>
            </Col>

            <Col lg="6">
              <Form.Group>
                <label className="col-form-label">Description</label>

                <Form.Control
                  className="form-control"
                  as="textarea"
                  onChange={e =>
                    this._handleFieldChange("description", e.target.value)
                  }
                  defaultValue={this.state.formData.description}
                />
              </Form.Group>
            </Col>
            <Col lg="2">
              <label className="col-form-label">Level</label>
              <div>
                <select
                  className="form-select"
                  type="text"
                  value={this.state.formData.level}
                  onChange={e => {
                    this._handleFieldChange("level", e.target.value)
                  }}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </div>
            </Col>
            <Col lg="2">
              <label className="col-form-label">Status</label>
              <div>
                <select
                  className="form-select"
                  type="text"
                  value={this.state.formData.status}
                  onChange={e =>
                    this._handleFieldChange("status", e.target.value)
                  }
                >
                  <option value="DRAFT">Draft</option>
                  <option disabled={this.disabledReview()} value="IN_REVIEW">
                    In Review
                  </option>
                  <option value="PUBLISHED">Published</option>
                  <option value="DISABLED">Disabled</option>
                </select>
              </div>
            </Col>

            <Col lg="2">
              <Form.Group>
                <label className="col-form-label">Auto Scroll (In ms)</label>

                <Form.Control
                  className="form-control"
                  onChange={e =>
                    this._handleFieldChange("auto_scroll_ms", e.target.value)
                  }
                  defaultValue={this.state.formData.auto_scroll_ms}
                />
              </Form.Group>
            </Col>

            {(this.state.formData.level == 1 ||
              this.state.formData.level == 2) && (
              <Col lg="6">
                <Row>
                  <Col sm="6" md="9">
                    <label className="col-form-label">Type</label>
                    <TextField
                      placeholderLabel="Select Type"
                      field="type"
                      value={this.state.formData.type}
                      onChange={val => {
                        if (val.trim()) {
                          this.setState({
                            formData: {
                              ...this.state.formData,
                              type: val.split(","),
                            },
                          })
                        } else {
                          this.setState({
                            formData: { ...this.state.formData, type: [] },
                          })
                        }
                      }}
                      className="creator-form"
                      defaultValue={this.state.formData.type}
                      style={{ width: "95%" }}
                    />
                  </Col>
                  <Col
                    sm="6"
                    md="3"
                    style={{ alignSelf: "flex-end", marginBottom: "9px" }}
                  >
                    <Button
                      color="primary"
                      onClick={() =>
                        this.setState({
                          selectTypeModal: true,
                        })
                      }
                    >
                      Select
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}

            {this.state.formData.level == 2 && (
              <Col lg="6">
                {/* <label className="col-form-label">Sub Types</label> */}

                <Row>
                  <Col sm="6" md="9" lg="9">
                    <label className="col-form-label">Sub Type</label>
                    <TextField
                      placeholderLabel="Select Sub Type"
                      field="sub_type"
                      className="creator-form"
                      value={this.state.formData.sub_type}
                      onChange={val => {
                        if (val.trim()) {
                          this.setState({
                            formData: {
                              ...this.state.formData,
                              sub_type: val.split(","),
                            },
                          })
                        } else {
                          this.setState({
                            formData: { ...this.state.formData, sub_type: [] },
                          })
                        }
                      }}
                      defaultValue={this.state.formData.sub_type}
                      style={{ width: "95%" }}
                    />
                  </Col>
                  <Col
                    sm="6"
                    md="3"
                    style={{ alignSelf: "flex-end", marginBottom: "9px" }}
                  >
                    <Button
                      color="primary"
                      onClick={() =>
                        this.setState({
                          selectSubTypeModal: true,
                        })
                      }
                    >
                      Select
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}

            <Col lg="6">
              <label className="col-form-label">Target Languages</label>
              <MultiSelect
                placeholderLabel="Languages"
                className="creator-form"
                defaultValue={this.state.formData.target_languages}
                options={LANGUAGES.map(lang => {
                  return { label: lang.label, value: lang.value }
                })}
                onChange={value =>
                  this._handleFieldChange("target_languages", value)
                }
              />
            </Col>
            <Col lg="6">
              <label className="col-form-label">Tagged Videos</label>
              <ChipsInput
                // label={"Tagged Videos"}
                placeholderLabel="Tagged Videos"
                field={"tagged_videos"}
                defaultValue={this.state.formData.tagged_videos || []}
                onChange={value =>
                  this._handleFieldChange("tagged_videos", value)
                }
                className="creator-form"
              />
            </Col>
            <Col lg="6">
              <label className="col-form-label">Enabled Tabs</label>
              <MultiSelect
                placeholderLabel="Languages"
                className="creator-form"
                defaultValue={this.state.formData.enabled_tabs}
                options={[
                  { value: "VIDEO", label: "Video" },
                  { value: "IMAGE", label: "Image" },
                  { value: "SOCIAL", label: "Social" },
                  { value: "NEWS", label: "News" },
                ]}
                onChange={value =>
                  this._handleFieldChange("enabled_tabs", value)
                }
              />
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="col-form-label">Hashtag</label>

                <Form.Control
                  className="form-control"
                  onChange={e =>
                    this._handleFieldChange("hashtag", e.target.value)
                  }
                  defaultValue={this.state.formData.hashtag}
                />
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <label className="col-form-label">Alias</label>
                <Form.Control
                  className="form-control"
                  onChange={e =>
                    this._handleFieldChange("alias", e.target.value)
                  }
                  defaultValue={this.state.formData.alias}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.alias}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg="6" className="d-flex">
              <div>
                <label className="col-form-label">Zone Thumbnail</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={e => {
                    this._handleFileUpload("zone_thumbnail", e.target.files[0])
                  }}
                />
              </div>
              {this.props?.zone?.zone_image && (
                <Card className="mt-4 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete mx-5">
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        src={this.props.zone.zone_image}
                      />
                    </div>
                  </div>
                </Card>
              )}
            </Col>
            <Col lg="4">
              <Form.Group>
                <label className="col-form-label">Zone Type</label>
                <div>
                  <select
                    className="form-select"
                    type="text"
                    value={this.state.formData.zone_type}
                    onChange={e => {
                      this._handleFieldChange("zone_type", e.target.value)
                    }}
                  >
                    <option value="COLLECTION">Collection</option>
                    <option value="PERSON">Person</option>
                    <option value="DEITY">Deity</option>
                    <option value="ASTRO">Astro</option>
                  </select>
                </div>
              </Form.Group>
            </Col>
            <Col>
              <div className="form-check form-switch form-switch-lg mt-3 pt-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={this.state.formData.allow_follow}
                  onChange={e =>
                    this._handleFieldChange("allow_follow", e.target.checked)
                  }
                />
                <label className="form-check-label">Allow Follow</label>
              </div>
            </Col>
            <Divider>Fan Labels</Divider>
            <Row className="mb-3">
              <div className="d-flex justify-content-start mt-3">
                <Col lg="2">
                  <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      defaultValue={"Top"}
                      disabled
                    />
                  </Form.Group>
                </Col>

                <Col lg="3">
                  <Form.Group>
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      onChange={e =>
                        this._handleFieldChange(
                          "top",
                          e.target.value,
                          "fan_labels"
                        )
                      }
                      defaultValue={this.state.formData.fan_labels.top}
                    />
                  </Form.Group>
                </Col>
                <Col lg="2" className="ms-5">
                  <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      defaultValue={"Super"}
                      disabled
                    />
                  </Form.Group>
                </Col>

                <Col lg="3">
                  <Form.Group>
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      onChange={e =>
                        this._handleFieldChange(
                          "super",
                          e.target.value,
                          "fan_labels"
                        )
                      }
                      defaultValue={this.state.formData.fan_labels.super}
                    />
                  </Form.Group>
                </Col>
              </div>
            </Row>
          </Row>
          <Divider>Cover Banner</Divider>
          <Row>
            {this.state.formData.cover_banner &&
              this.state.formData.cover_banner.map((imageDsta, index) => {
                return (
                  <div key={index + imageDsta.image_uuid}>
                    {this._renderImageDetails(imageDsta, index)}
                  </div>
                )
              })}
            <div className="d-flex justify-content-start mt-3 mx-4">
              <div
                className="btn btn-primary"
                onClick={() => this._addImages()}
                role="button"
              >
                Add Banner
              </div>
            </div>
          </Row>
          {this.state.formData?.zone_target?.map((item, index) => {
            const color = this.state.colors[index]
              ? this.state.colors[index]
              : ""
            return (
              <>
                <Divider style={{ color }}>
                  <span>Target #{index + 1}</span>
                </Divider>
                {this._renderTargetForm(item, index, color)}
              </>
            )
          })}
          <div className="d-flex justify-content-center">
            <div
              className="mt-3 btn btn-primary"
              onClick={this._addTarget}
              role="button"
            >
              Add Target
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <div
              className="btn btn-success"
              onClick={this._onSubmit}
              role="button"
            >
              {this.props.formAction === "update" ? `Update` : `Create`}
            </div>
            <Button
              color="danger"
              size="default"
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                return
              }}
              onClick={() => this.props.history.push(`/zone/browse`)}
              className="mx-2"
            >
              Cancel
            </Button>
          </div>
          {this.props?.zone?.zone_uuid && (
            <>
              <Divider>Preview Image</Divider>
              <div className="d-flex justify-content-center">
                <div
                  className="mt-3 btn btn-primary mb-2"
                  onClick={() => this.setState({ showPreviewImageForm: true })}
                  role="button"
                >
                  Add Preview Image
                </div>
              </div>
              <Row>
                <Col
                  className="trending-card-container"
                  style={{
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(150px, 1fr))",
                  }}
                >
                  {this.state.formData.preview_image &&
                    this.state.formData.preview_image.map(
                      (imageData, index) => {
                        return (
                          <div key={index + imageData?.id || ""}>
                            {this._renderPreviewImageDetails(imageData, index)}
                          </div>
                        )
                      }
                    )}
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
    )
  }

  _renderTargetImageDetails = (imageData, imageIndex, targetIndex) => {
    return (
      <Row className="m-2">
        <Col lg="1" style={{ width: "50px" }}>
          <div className="sl_no">{+imageIndex + 1}</div>
        </Col>
        <Col lg="11">
          <Row>
            <Col lg="2">
              <label className="col-form-label">Banner Type</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleTargetFieldsChange(
                      "type",
                      e.target.value,
                      targetIndex,
                      imageIndex,
                      "cover_banner"
                    )
                  }
                  value={imageData.type}
                >
                  <option value="image">Image</option>
                  <option value="exo">Video</option>
                  <option value="youtube_embed">YouTube</option>
                </select>
              </div>
            </Col>
            {imageData.type === "image" && (
              <>
                <Col lg="4">
                  <div>
                    <div>
                      <label className="col-form-label">
                        Upload Image file
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={e => {
                          const img = new Image()
                          img.onload = () => {
                            const height = img.height
                            const width = img.width
                            this._handleTargetFieldsChange(
                              "height",
                              height,
                              targetIndex,
                              imageIndex,
                              "cover_banner"
                            )
                            this._handleTargetFieldsChange(
                              "width",
                              width,
                              targetIndex,
                              imageIndex,
                              "cover_banner"
                            )
                            img.src = URL.createObjectURL(e.target.files[0])
                          }
                          this._handleFileUpload(
                            `image_${imageData.id}`,
                            e.target.files[0]
                          )
                        }}
                      />
                    </div>
                  </div>
                </Col>
                {imageData.thumbnail && (
                  <Col>
                    <Card className="mt-4 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                      <div className="d-flex align-items-center">
                        <div>
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            src={imageData.thumbnail}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                )}
              </>
            )}
            {["exo", "youtube_embed"].includes(imageData.type) && (
              <Col lg="3">
                <div>
                  <div>
                    <label className="col-form-label">
                      Upload Video Thumbnail
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        const img = new Image()
                        img.onload = () => {
                          const height = img.height
                          const width = img.width
                          this._handleTargetFieldsChange(
                            "height",
                            height,
                            targetIndex,
                            imageIndex,
                            "cover_banner"
                          )
                          this._handleTargetFieldsChange(
                            "width",
                            width,
                            targetIndex,
                            imageIndex,
                            "cover_banner"
                          )
                          img.src = URL.createObjectURL(e.target.files[0])
                        }
                        this._handleFileUpload(
                          `image_${imageData.id}`,
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </div>
              </Col>
            )}
            {imageData.type === "exo" && (
              <>
                <Col lg="3">
                  <div>
                    <div>
                      <label className="col-form-label">
                        Upload Video File
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={e => {
                          this._handleFileUpload(
                            `video_${imageData.id}`,
                            e.target.files[0]
                          )
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </>
            )}
            {["youtube_embed"].includes(imageData.type) && (
              <Col lg="4">
                <label className="col-form-label">Youtube ID</label>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={
                      imageData.player_url ? imageData.player_url : ""
                    }
                    onChange={e =>
                      this._handleTargetFieldsChange(
                        "player_url",
                        e.target.value,
                        targetIndex,
                        imageIndex,
                        "cover_banner"
                      )
                    }
                  />
                </div>
              </Col>
            )}
            {["youtube_embed", "exo"].includes(imageData.type) && (
              <>
                <Col lg="4">
                  <label className="col-form-label">Title</label>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={imageData.title ? imageData.title : ""}
                      onChange={e =>
                        this._handleTargetFieldsChange(
                          "title",
                          e.target.value,
                          targetIndex,
                          imageIndex,
                          "cover_banner"
                        )
                      }
                    />
                  </div>
                </Col>
                <Col lg="2">
                  <label className="col-form-label">Width</label>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={imageData.width ? imageData.width : ""}
                      onChange={e =>
                        this._handleFieldChange(
                          "width",
                          e.target.value,
                          "cover_banner",
                          imageIndex
                        )
                      }
                    />
                  </div>
                </Col>
                <Col lg="2">
                  <label className="col-form-label">Height</label>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={imageData.height ? imageData.height : ""}
                      onChange={e =>
                        this._handleFieldChange(
                          "height",
                          e.target.value,
                          "cover_banner",
                          imageIndex
                        )
                      }
                    />
                  </div>
                </Col>
              </>
            )}

            <Col lg="4">
              <label className="col-form-label">CTA text</label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={
                    imageData.cta_data?.inline_cta_text
                      ? imageData.cta_data.inline_cta_text
                      : ""
                  }
                  onChange={e =>
                    this._handleTargetFieldsChange(
                      "cta_data",
                      e.target.value,
                      targetIndex,
                      imageIndex,
                      "cover_banner"
                    )
                  }
                />
              </div>
            </Col>
            {imageData.isExist && (
              <Col className="mx-4 mt-5">
                <span
                  className="delete-icon"
                  onClick={() =>
                    this._deleteTargetBanner(imageData.id, imageData.lang_code)
                  }
                >
                  <i className="mdi mdi-delete"></i>
                </span>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    )
  }

  _deleteTargetBanner = (bannerId, langCode) => {
    const data = {
      zone_uuid: this.props.zone.zone_uuid,
      lang_code: langCode,
      banner_id: bannerId,
    }
    this.props.deleteBanner(data)
  }

  _renderImageDetails = (imageData, imageIndex) => {
    return (
      <Row className="m-2">
        <Col lg="1" style={{ width: "50px" }}>
          <div className="sl_no">{+imageIndex + 1}</div>
        </Col>
        <Col lg="11">
          <Row>
            <Col lg="2">
              <label className="col-form-label">Banner Type</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange(
                      "type",
                      e.target.value,
                      "cover_banner",
                      imageIndex
                    )
                  }
                  value={imageData.type}
                >
                  <option value="image">Image</option>
                  <option value="exo">Video</option>
                  <option value="youtube_embed">YouTube</option>
                </select>
              </div>
            </Col>
            {imageData.type === "image" && (
              <>
                <Col lg="4">
                  <div>
                    <div>
                      <label className="col-form-label">
                        Upload Image file
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={e => {
                          const img = new Image()
                          img.onload = () => {
                            const height = img.height
                            const width = img.width
                            this._handleFieldChange(
                              "height",
                              height,
                              "cover_banner",
                              imageIndex
                            )
                            this._handleFieldChange(
                              "width",
                              width,
                              "cover_banner",
                              imageIndex
                            )
                            img.src = URL.createObjectURL(e.target.files[0])
                          }
                          this._handleFileUpload(
                            `image_${imageData.id}`,
                            e.target.files[0]
                          )
                        }}
                      />
                    </div>
                  </div>
                </Col>
                {imageData.thumbnail && (
                  <Col>
                    <Card className="mt-4 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                      <div className="d-flex align-items-center">
                        <div>
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            src={imageData.thumbnail}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                )}
              </>
            )}
            {["exo", "youtube_embed"].includes(imageData.type) && (
              <Col lg="3">
                <div>
                  <div>
                    <label className="col-form-label">
                      Upload Video Thumbnail
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          `image_${imageData.id}`,
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </div>
              </Col>
            )}
            {imageData.type === "exo" && (
              <>
                <Col lg="3">
                  <div>
                    <div>
                      <label className="col-form-label">
                        Upload Video File
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={e => {
                          this._handleFileUpload(
                            `video_${imageData.id}`,
                            e.target.files[0]
                          )
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </>
            )}
            {imageData.type !== "image" && (
              <>
                <Col lg="4">
                  <label className="col-form-label">Title</label>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={imageData.title ? imageData.title : ""}
                      onChange={e =>
                        this._handleFieldChange(
                          "title",
                          e.target.value,
                          "cover_banner",
                          imageIndex
                        )
                      }
                    />
                  </div>
                </Col>
                {["youtube_embed"].includes(imageData.type) && (
                  <Col lg="4">
                    <label className="col-form-label">Youtube ID</label>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={
                          imageData.player_url ? imageData.player_url : ""
                        }
                        onChange={e =>
                          this._handleFieldChange(
                            "player_url",
                            e.target.value,
                            "cover_banner",
                            imageIndex
                          )
                        }
                      />
                    </div>
                  </Col>
                )}
                <Col lg="2">
                  <label className="col-form-label">Width</label>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={imageData.width ? imageData.width : ""}
                      onChange={e =>
                        this._handleFieldChange(
                          "width",
                          e.target.value,
                          "cover_banner",
                          imageIndex
                        )
                      }
                    />
                  </div>
                </Col>
                <Col lg="2">
                  <label className="col-form-label">Height</label>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={imageData.height ? imageData.height : ""}
                      onChange={e =>
                        this._handleFieldChange(
                          "height",
                          e.target.value,
                          "cover_banner",
                          imageIndex
                        )
                      }
                    />
                  </div>
                </Col>
              </>
            )}
            <Col lg="4">
              <label className="col-form-label">CTA text</label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={
                    imageData.cta_data?.inline_cta_text
                      ? imageData.cta_data?.inline_cta_text
                      : ""
                  }
                  onChange={e =>
                    this._handleFieldChange(
                      "cta_data",
                      e.target.value,
                      "cover_banner",
                      imageIndex
                    )
                  }
                />
              </div>
            </Col>
            {imageData.isExist && (
              <Col className="mx-4 mt-5">
                <span
                  className="delete-icon"
                  onClick={() =>
                    this._deleteTargetBanner(
                      imageData.id,
                      this.state.formData.lang_code
                    )
                  }
                >
                  <i className="mdi mdi-delete"></i>
                </span>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    )
  }

  _renderPreviewImageDetails = (imageData, imageIndex) => {
    return (
      <Row className="preview_image_card">
        <Col className="d-flex">
          <div>
            <img
              src={imageData.url}
              alt="Zone Image"
              style={{ height: "100px", width: "100px" }}
            />
          </div>
        </Col>
      </Row>
    )
  }

  _render = () => {
    const typeList = []
    const subTypeList = []
    const zoneFilterList = []
    this.props?.allZoneList.map(zone => {
      if (zone && zone.zone_uuid && +zone.level === 0) {
        typeList.push({
          ...zone,
          value: zone.zone_uuid,
          label: zone.title || zone.sub_title,
        })
      } else if (zone && zone.zone_uuid && +zone.level === 1) {
        subTypeList.push({
          ...zone,
          value: zone.zone_uuid,
          label: zone.title || zone.sub_title,
        })
      }
      zoneFilterList.push({
        ...zone,
        value: zone.zone_uuid,
        label: zone.title || zone.sub_title,
      })
    })
    return (
      <Auxiliary loading={this.props.loading}>
        {!this.state.showPreviewImageForm && this._renderUpsertForm()}
        {this.state.selectTypeModal && (
          <SearchAndSelect
            type="ZONE"
            visible={this.state.selectTypeModal}
            onClose={() => this.setState({ selectTypeModal: false })}
            onAdd={arr =>
              this.setState({ formData: { ...this.state.formData, type: arr } })
            }
            selectedArr={_.uniq(this.state.formData.type.map(a => a))}
            zoneLevel={0}
            allZoneList={typeList}
          />
        )}
        {this.state.selectSubTypeModal && (
          <SearchAndSelect
            type="ZONE"
            visible={this.state.selectSubTypeModal}
            onClose={() => this.setState({ selectSubTypeModal: false })}
            onAdd={arr =>
              this.setState({
                formData: { ...this.state.formData, sub_type: arr },
              })
            }
            zoneId={this.state.active_zones}
            selectedArr={_.uniq(this.state.formData.sub_type.map(a => a))}
            zoneLevel={1}
            allZoneList={subTypeList}
          />
        )}
        {this.state.showPreviewImageForm && (
          <ZonePreviewImageForm
            onClose={() => {
              this.setState({ showPreviewImageForm: false })
            }}
            onSubmit={this.props.onPreviewImageSubmit}
            zoneId={this.props?.zone?.zone_uuid}
            imageList={this.props?.zone?.prev_images || []}
          />
        )}
      </Auxiliary>
    )
  }
}
