import React from "react"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import QuestionCard from "./questionCard"

import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"

class Questionnaire extends ErrorBoundComponent {
  _handleSelection = (e, index) => {
    let currentData = _.cloneDeep(this.props.currentData)
    let stepCompleted = true
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = _.cloneDeep(this.props.taxonomyRoutes)
    const indexTab = taxonomyRoutes.findIndex(
      item => item.key == this.props.selectedSection
    )
    let currentQuestion
    currentData.forEach((data, currIdx) => {
      if (index === currIdx) {
        currentQuestion = data
        data.selected = true
        data.value = e.target.value
        data.partialSelected = e.target.value
        selectedDetails[data.taxonomyJsonKey][data.key] = data.value
      }
    })

    currentData.forEach(data => {
      if (data.level > currentQuestion.level) {
        data.selected = false
        data.isVisible = false
        data.partialSelected = undefined
        delete data.value
        delete selectedDetails[data.taxonomyJsonKey][data.key]
      }
    })

    currentData.forEach(data => {
      if (
        data.parent === currentQuestion.key &&
        data.dependentValue === currentQuestion.value
      ) {
        data.isVisible = true
      }
    })

    currentData.forEach(data => {
      if (data.isVisible && data.value == undefined) {
        stepCompleted = false

        taxonomyRoutes[indexTab].completed = false
      }
    })

    if (stepCompleted) {
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    }

    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.setSelectedDetails(selectedDetails)
  }

  _renderStepper = () => {
    let text = "Select All"
    this.props.currentData.forEach(item => {
      if (item.level > 0) {
        text = "Questionnaire"
      }
    })

    return (
      <div style={{ margin: "1rem", fontWeight: "bold" }}>
        {this.state.labelToDisplay}&nbsp;({text})
      </div>
    )
  }

  _renderQuestions = () => {
    let data = this.props.currentData
    data = data.filter(item => item.isVisible)

    return (
      <PerfectScrollbar>
        <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
          {data.map((question, idx) => {
            return (
              <QuestionCard
                question={question}
                _handleSelection={e => this._handleSelection(e, idx)}
                renderCardContent={this.props.renderCardContent}
              />
            )
          })}
        </div>
      </PerfectScrollbar>
    )
  }

  render() {
    return null
  }
}

export default Questionnaire
