import React from "react"
import { connect } from "react-redux"
import { Modal, Card, message, Select, Radio, Tooltip } from "antd"
import { Card as ReactCard, CardTitle, CardBody, Badge } from "reactstrap"
import { Button, Row, Col } from "reactstrap"
import {
  getStickersSubTypeList,
  upsertStickerSubType,
  updateStickerTabOrder,
  deleteStickerSubTypeById,
  resetState,
} from "../../appRedux/slices/stickers"
import UpsertStickersSubTypeForm from "./upsertStickersSubTypeForm"
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import toastr from "toastr"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import "./stickers.style.css"
const { Meta } = Card

class ManageStickersSubType extends ErrorBoundComponent {
  state = {
    filters: {},
    showUpsertModal: false,
    stickerAsset: {},
    selectedFilter: "CAMERA",
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getStickersSubTypeList({
          selectedFilter: this.state.selectedFilter,
        })
      )
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.refetchData &&
      !this.props.common.error &&
      prevProps.refetchData !== this.props.refetchData
    ) {
      this._closeUpsertModal()
      this.props.dispatch(
        getStickersSubTypeList({
          selectedFilter: this.state.selectedFilter,
        })
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onSubmit = async formData => {
    const { stickerAsset } = this.state
    const { stickersSubTypeList } = this.props
    const updateFormData = {}
    for (let tab of stickersSubTypeList) {
      if (
        this.state.formAction === "create" &&
        formData.displayName === tab.displayName
      ) {
        message.error("Tab already exist with this Name")
        return
      }

      if (
        this.state.formAction === "update" &&
        this.state.stickerAsset.subTabId !== tab.subTabId &&
        formData.displayName === tab.displayName
      ) {
        message.error("Tab already exist with this Name")
        return
      }
    }
    if (formData.isActive !== stickerAsset.isActive) {
      updateFormData["isActive"] = formData.isActive
    }
    if (formData.displayName !== stickerAsset.displayName) {
      updateFormData["displayName"] = formData.displayName
    }
    const payload =
      this.state.formAction === "update" ? updateFormData : formData
    const data = {
      payload,
      query: {
        selectedFilter: this.state.selectedFilter,
        subTabId: stickerAsset.subTabId,
        action: this.state.formAction,
      },
    }
    if (!_.isEmpty(payload)) {
      this.props.dispatch(upsertStickerSubType(data))
    } else {
      message.error("Nothing to Update")
      this.setState({
        showUpsertModal: false,
      })
    }
  }

  _closeUpsertModal = () => {
    this.setState({
      showUpsertModal: false,
      formAction: "create",
      stickerAsset: {},
    })
  }

  _onCreateSubType = () => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_SUBTYPE")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.setState({
      showUpsertModal: true,
      formAction: "create",
      stickerAsset: {},
    })
  }

  _onEditTabAsset = (e, stickerAsset) => {
    e.preventDefault()
    e.stopPropagation()
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_SUBTYPE")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.setState({
      showUpsertModal: true,
      formAction: "update",
      stickerAsset: stickerAsset,
    })
  }

  _onDeleteTabById = (e, subTabId) => {
    e.preventDefault()
    e.stopPropagation()
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_SUBTYPE")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.props.dispatch(
      deleteStickerSubTypeById({
        selectedFilter: this.state.selectedFilter,
        subTabId: subTabId,
      })
    )
  }

  _upsertModal = () => {
    return (
      <Card title="Create/Edit Sticker Subtype">{this._getModalContent()}</Card>
    )
  }

  _getModalContent = () => {
    return (
      <UpsertStickersSubTypeForm
        formAction={this.state.formAction}
        stickerAsset={this.state.stickerAsset}
        stickersFor={this.state.selectedFilter}
        onSubmit={this._onSubmit}
        onCancel={this._closeUpsertModal}
      />
    )
  }

  _handleFilterChange = event => {
    const radioVal = event.target.value
    this.props.dispatch(getStickersSubTypeList({ selectedFilter: radioVal }))
    this.setState({
      selectedFilter: radioVal,
    })
  }

  _actionButtons = () => {
    return (
      <div>
        <Row className="actions-buttons" gutter={24}>
          <Col className="select" span={4}>
            <Radio.Group
              onChange={this._handleFilterChange}
              defaultValue={"CAMERA"}
              value={this.state.selectedFilter}
            >
              <Radio value={"CAMERA"}>Camera</Radio>
              <Radio value={"COMMENT"}>Comments</Radio>
            </Radio.Group>
          </Col>
          <Col>
            <div className="d-flex justify-content-end">
              <Button color="primary" onClick={this._onCreateSubType}>
                Create
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  _tabsList = () => {
    return (
      <div className="discovery-card-container">
        {this.props.stickersSubTypeList.map(tab => (
          <ReactCard
            className="overflow-hidden text-center shadow-lg"
            key={tab.displayName || tab.tab_id || tab.tab_name}
          >
            {tab.displayName && (
              <div
                style={{
                  padding: "12px",
                  margin: "12px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {tab.displayName}
              </div>
            )}
            <div className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center">
              <div className="flex-fill">
                <Tooltip title="Edit">
                  <i
                    className="bx bx-edit-alt"
                    role="button"
                    onClick={
                      tab.tabType !== "LOCAL"
                        ? e => this._onEditTabAsset(e, tab)
                        : ""
                    }
                  />
                </Tooltip>
              </div>
              <div className="flex-fill">
                <Tooltip title="Delete">
                  <i
                    className="bx bx-trash"
                    role="button"
                    onClick={
                      tab.tabType !== "LOCAL"
                        ? e => this._onDeleteTabById(e, tab.subTabId)
                        : ""
                    }
                  />
                </Tooltip>
              </div>
            </div>
          </ReactCard>
        ))}
      </div>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showUpsertModal && this._actionButtons()}
        {this.state.showUpsertModal && this._upsertModal()}
        {!this.state.showUpsertModal && this._tabsList()}
      </Auxiliary>
    )
  }
}
function mapStateToProps(store) {
  return {
    stickersSubTypeList: _.get(store, "stickers.stickersSubTypeList"),
    refetchData: _.get(store, "stickers.refetchData"),
    loading: _.get(store, "stickers.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageStickersSubType)
