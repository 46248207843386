import React from "react"
import moment from "moment"
import _ from "lodash"
import toastr from "toastr"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, {
  Select,
  TextField,
  TextArea,
  DateTimePicker,
  Switch,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import dayjs from "dayjs"

export default class UpsertTrendingPayload extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data || {},
      fileList: [],
      formElementType: this.props.data && this.props.data.element_type,
    }
  }

  _form = () => {
    const { data } = this.state
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Trending meta`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={6}>
                <TextField
                  label={"Score"}
                  placeholderLabel="Score"
                  field={"score"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.score
                  }
                  className="creator-form"
                  inputType={"number"}
                  minValue={0}
                  validations={["required", "number"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Label"}
                  placeholderLabel="Label"
                  field={"label"}
                  className="creator-form"
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.label
                  }
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <DateTimePicker
                  label="Start Time"
                  field="start_time"
                  style={{ padding: "9px", width: "100%" }}
                  defaultValue={
                    data && data.start_time ? dayjs(data.start_time) : undefined
                  }
                  onChange={val =>
                    this.setState({
                      data: { ...this.state.data, start_time: val },
                    })
                  }
                />
              </Col>
              <Col sm={12} md={6}>
                <DateTimePicker
                  label="End Time"
                  field="end_time"
                  style={{ padding: "9px", width: "100%" }}
                  defaultValue={
                    data && data.end_time ? dayjs(data.end_time) : undefined
                  }
                  onChange={val =>
                    this.setState({
                      data: { ...this.state.data, end_time: val },
                    })
                  }
                />
              </Col>
              <Col sm={12} md={6}>
                <Switch
                  label={"Status"}
                  field={"status"}
                  defaultValue={
                    this.props.data && this.props.data.status === "ACTIVE"
                      ? true
                      : false
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="flex" style={{ marginTop: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <Button htmlType="submit" color="primary">
                      {this.props.action === "update"
                        ? "Update"
                        : "Mark Trending"}
                    </Button>
                    <Button
                      onClick={() => this.props.onCancel()}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    if (errors) {
      return
    }
    formData.status = formData.status ? "ACTIVE" : "INACTIVE"
    formData.score = formData.score ? parseInt(formData.score) : ""
    formData.start_time = !_.isEmpty(formData.start_time)
      ? parseInt(dayjs(formData.start_time).valueOf)
      : null
    formData.end_time = !_.isEmpty(formData.end_time)
      ? parseInt(dayjs(formData.end_time).valueof())
      : null
    let data = formData
    this.props.onSubmit(data)
  }

  _render = () => {
    return this._form()
  }
}
