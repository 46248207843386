import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { getAllZones, resetState } from "appRedux/slices/zone"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button as MuiButton, Stack } from "@mui/material"
import { Form, Input, Tabs, DatePicker, Divider, Select, Upload } from "antd"
import { Card, CardBody, Row, Col, Button } from "reactstrap"
import moment from "moment"
import ReactSelect from "react-select"
import { LANGUAGES } from "constants/uiConstants"
import dayjs from "dayjs"
import { upsertThemification } from "clientServices/themificationService"
import toastr from "toastr"
import { Switch } from "@mui/material"
import { MultiSelect } from "containers/ValidatorForm"

const { Option } = Select

class CreateThemeficationForm extends ErrorBoundComponent {
  state = {
    showInterestForm: false,
    formData: {
      themeName: "",
      startTime: "",
      expireTime: "",
      zones: "",
      languages: [],
      pageConfigs: {
        0: "",
        1: "",
        2: "",
      },
    },
    errors: {},
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      window.addEventListener("keydown", this._keyDownHandler)
    }
    if (this.props.currentRow) {
      this.setState({
        formData: {
          ...this.props.currentRow,
        },
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    this.setState(
      {
        formData,
        errors: {
          ...this.state.errors,
          [field]: null,
        },
      },
      () => {
        console.log(this.state.errors)
      }
    )
  }
  _handlePageConfigFieldChange = (field, index, value) => {
    let formData = this.state.formData
    formData[field][index] = value

    this.setState(
      {
        formData,
        errors: {
          ...this.state.errors,
          [field]: null,
        },
      },
      () => {
        console.log(this.state.errors)
      }
    )
  }
  formatOptionLabel = ({ value, title, zone_image }) => {
    return (
      <span>
        {zone_image ? (
          <img
            src={zone_image}
            alt={title}
            style={{ hight: "20px", width: "20px" }}
          />
        ) : (
          <i className="fa fa-users" aria-hidden="true"></i>
        )}{" "}
        &nbsp;
        <b>{title}</b>
      </span>
    )
  }
  _onVideoRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }
  OnSubmit = async () => {
    console.log(this.state)

    if (this.state.formData?.themeName.trim() == "") {
      toastr.error("Name is mandatory")
      return
    }
    if (
      this.state.formData.startTime == "" ||
      this.state.formData.startTime == null
    ) {
      toastr.error("Start Time is mandatory")
      return
    }
    if (
      this.state.formData.expireTime == "" ||
      this.state.formData.expireTime == null
    ) {
      toastr.error("Expire Time is mandatory")
      return
    }
    if (this.state.formData.expireTime <= this.state.formData.startTime) {
      toastr.error("Expire Time cannot be past start time")
      return
    }
    if (this.state.formData.expireTime / 1000 < moment().unix()) {
      toastr.error("Expire Time cannot be past current time")
      return
    }
    if (this.state.formData.languages == "") {
      toastr.error("Language is mandatory")
      return
    }

    if (this.state.formData.pageConfigs) {
      let counter = 0
      for (let config in this.state.formData.pageConfigs) {
        if (this.state.formData.pageConfigs[config] == "") {
          counter++
        }
      }
      if (counter > 2) {
        toastr.error("At least one page config is mandatory")
        return
      }
    }

    let data = new FormData()
    data.append("file", this.state.csvFile)
    data.append(
      "details",
      JSON.stringify({
        ...this.state.formData,
        action: this.props?.currentRow ? "update" : "create",
      })
    )
    let result = await upsertThemification(data)
    if (result) {
      this.props.toggleCreateForm()
    }
  }
  iscreateThemeForm = () => {
    this.props.toggleCreateForm()
  }
  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }
  render() {
    // const allZoneList = []
    // this.props?.allZoneList.map(zone => {
    //   if (zone && zone.zone_uuid) {
    //     allZoneList.push({
    //       ...zone,
    //       value: zone.zone_uuid,
    //       label: zone.title || zone.sub_title,
    //     })
    //   }
    // })
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          <Card>
            <CardBody>
              <Row style={{ marginBottom: "5px" }}>
                <Col lg="12">
                  <h4>
                    <b> Add Theme</b>
                  </h4>
                </Col>
              </Row>
              <Form layout="vertical" autoComplete="false">
                <Row className="mb-3">
                  <Col lg="6">
                    <Form.Item
                      label="Theme Name"
                      validateStatus={this.state.errors?.themeName && "error"}
                    >
                      <Input
                        type="text"
                        placeholder="Add Theme Name.."
                        onChange={e =>
                          this._handleFieldChange("themeName", e.target.value)
                        }
                        defaultValue={this.state.formData.themeName}
                        disabled={this.props.currentRow ? true : false}
                        value={this.state.formData.themeName}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Form.Item label="Start Time">
                      <DatePicker
                        style={
                          this.state.errors.startTime
                            ? {
                                border: "1px solid red",
                                width: "100%",
                                height: "55%",
                              }
                            : { width: "100%", height: "55%" }
                        }
                        defaultValue={
                          this.props?.currentRow?.startTime
                            ? dayjs.unix(
                                this.props.currentRow?.startTime / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        placeholder="Start Time"
                        onChange={(date, dateString) => {
                          this._handleFieldChange(
                            "startTime",
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null
                          )
                        }}
                        disabled={
                          this.props?.currentRow?.status == "ACTIVE"
                            ? true
                            : false
                        }
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg="6">
                    <Form.Item label="Expire Time">
                      <DatePicker
                        style={
                          this.state.errors.expireTime
                            ? {
                                border: "1px solid red",
                                width: "100%",
                                height: "55%",
                              }
                            : { width: "100%", height: "55%" }
                        }
                        defaultValue={
                          this.props?.currentRow?.expireTime
                            ? dayjs.unix(
                                this.props.currentRow?.expireTime / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        placeholder="Expire Time"
                        onChange={(date, dateString) => {
                          this._handleFieldChange(
                            "expireTime",
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null
                          )
                        }}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <Form.Item label="Content CSV">
                      <Upload
                        onRemove={this._onVideoRemove}
                        defaultFileList={this.state.csvFileList}
                        fileList={this.state.csvFileList}
                        customRequest={this._uploadCSV}
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                      >
                        <Button color="primary">
                          <i className="bx bx-upload me-2" /> Click to upload
                          CSV
                        </Button>
                      </Upload>
                    </Form.Item>
                    <label style={{ marginTop: "5px", marginBottom: "10px" }}>
                      Language:
                    </label>
                    <Select
                      mode="multiple"
                      showSearch
                      size="large"
                      allowClear={true}
                      // placeholder="Include Languages"
                      defaultValue={this.props?.currentRow?.languages}
                      onChange={e => this._handleFieldChange("languages", e)}
                      style={{ width: "100%" }}
                    >
                      {[...[{ value: "all", label: "All" }], ...LANGUAGES].map(
                        lang => (
                          <Option value={lang.value}>{lang.label}</Option>
                        )
                      )}
                    </Select>
                    <Form.Item
                      label="Page Configs"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingBottom: "5px",
                        }}
                      >
                        <div style={{ width: "12%" }}>0 : </div>{" "}
                        <Input
                          type="number"
                          placeholder="Add 0th.."
                          min={1}
                          onChange={e =>
                            this._handlePageConfigFieldChange(
                              "pageConfigs",
                              0,
                              e.target.value
                            )
                          }
                          defaultValue={this.state.formData.pageConfigs["0"]}
                          value={this.state.formData.pageConfigs["0"]}
                          size="large"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingBottom: "5px",
                        }}
                      >
                        <div style={{ width: "12%" }}>1 : </div>{" "}
                        <Input
                          type="number"
                          placeholder="Add 1st.."
                          onChange={e =>
                            this._handlePageConfigFieldChange(
                              "pageConfigs",
                              1,
                              e.target.value
                            )
                          }
                          defaultValue={this.state.formData.pageConfigs["1"]}
                          value={this.state.formData.pageConfigs["1"]}
                          size="large"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingBottom: "5px",
                        }}
                      >
                        <div style={{ width: "12%" }}>2 : </div>{" "}
                        <Input
                          type="number"
                          placeholder="Add 2nd.."
                          onChange={e =>
                            this._handlePageConfigFieldChange(
                              "pageConfigs",
                              2,
                              e.target.value
                            )
                          }
                          defaultValue={this.state.formData.pageConfigs["2"]}
                          value={this.state.formData.pageConfigs["2"]}
                          size="large"
                        />
                      </div>
                    </Form.Item>
                    {/* <label>Disable :</label>
                    <div>
                      <Switch
                        defaultChecked={
                          this.props?.currentRow?.disable == true ? true : false
                        }
                        onChange={e =>
                          this._handleFieldChange("disable", e.target.checked)
                        }
                      />
                    </div> */}
                  </Col>
                  {this.props.currentRow && (
                    <Col md="6">
                      Content Ids:{" "}
                      <div style={{ overflowY: "auto", height: "250px" }}>
                        {this.props?.currentRow?.items.map(row => {
                          return <div>{row} &nbsp;</div>
                        })}
                      </div>
                      <br />
                      Zone Id:{" "}
                      <div style={{ overflowY: "auto", height: "250px" }}>
                        {this.props?.currentRow?.zones.map(row => {
                          return <div>{row} &nbsp;</div>
                        })}
                      </div>
                    </Col>
                  )}
                </Row>
              </Form>
              <Row>
                <div className="d-flex justify-content-end">
                  <Button
                    htmlType="submit"
                    className="font-16 btn-block btn btn-primary"
                    color="primary"
                    disabled={this.state.loading}
                    onClick={() => {
                      this.OnSubmit()
                    }}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="danger"
                    size="default"
                    disabled={this.state.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                    onClick={() => this.iscreateThemeForm()}
                    className="mx-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </CardBody>
          </Card>
          {/* <CreateCreateThemeficationFormForm /> */}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(CreateThemeficationForm)
