import Axios from "axios"
import _ from "lodash"

export const activateDeactivateComment = async (
  commentUUID,
  moderationReason,
  moderatorId,
  action
) => {
  try {
    let payload = {
      comment_uuid: commentUUID,
      moderation_reason: moderationReason,
      moderation_info: {
        moderator_id: moderatorId,
        moderated_time: new Date().getTime(),
      },
    }
    let response = await Axios.post(
      `/api/comment/action/${action}`,
      _.deepClean(payload)
    )
    return response
  } catch (error) {
    if (error && error.message && error.message.includes("401")) {
      return { message: "User Not Verified!!" }
    }
    return error
  }
}

export const pinComment = async (commentUUID, isPinned) => {
  try {
    let details = {
      id: commentUUID,
      is_pinned: isPinned,
    }
    let response = await Axios.post(`/api/comment/pin`, details)
    return response
  } catch (error) {
    if (error && error.message && error.message.includes("401")) {
      return { message: "User Not Verified!!" }
    }
    return error
  }
}
