import React from "react"
import toastr from "toastr"
import { connect } from "react-redux"
import QueryString from "query-string"
import {
  getChallengeDetails,
  resetState,
  upsertChallenge,
  updateChallengeWinners,
  updateChallengeStatus,
  updateChallengeVideoMeta,
  deleteBanner,
} from "appRedux/slices/challenge"
import _ from "lodash"
import ChallengeContentList from "../../components/ChallengeContentList/challengeContentList"
import { Divider } from "antd"
import {
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  CardTitle,
} from "reactstrap"
import ValidatorForm, { ChipsInput, TextField, Select } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import ChallengeUpsertForm from "./challengeUpsertForm"
import Auxiliary from "../../util/Auxiliary"
import "./styles.css"
import { Card, CardBody, Input, Label, FormGroup } from "reactstrap"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import { getContentByUUIDs } from "clientServices/contentService"

class ManageChallenge extends ErrorBoundComponent {
  state = {
    filters: {
      challengeUuid:
        this.props.queryParams && this.props.queryParams["challengeUuid"]
          ? this.props.queryParams["challengeUuid"]
          : null,
    },
    showUpdateChallengeMetaModal: false,
    showUpsertModal: true,
    formAction: "create",
    challenge: this.props.challenge,
    contentUUIDs: [],
    videoActionMeta: {
      orig: {
        paramTitle: "Original Video ID",
        modalTitle: "Update Original Video",
        type: "orig_video_id",
        esType: "orig_video_id",
      },
      promo: {
        paramTitle: "Promo Video ID",
        modalTitle: "Update Promo Video",
        type: "promo_video_id",
        esType: "promo_video_id",
      },
      featured: {
        paramTitle: "Featured Video IDs",
        modalTitle: "Update Featured Videos",
        type: "feature_video_ids",
        esType: "featured_video_ids",
      },
      winners: {
        paramTitle: "Winner Video IDs",
        modalTitle: "Update Winner Videos",
        type: "winners",
        esType: "winners",
      },
      leaders: {
        paramTitle: "Leaderboard Video IDs",
        modalTitle: "Update Leaderboard Videos",
        type: "leaders",
        esType: "leaderboard",
      },
      status: {
        paramTitle: "Challenge Status",
        modalTitle: "Update Challenge Status",
        type: "status",
        esType: "status",
      },
    },
  }

  componentDidMount() {
    if (!this.props.common.error && this.state.filters.challengeUuid) {
      this.props.dispatch(
        getChallengeDetails({ challengeUuid: this.state.filters.challengeUuid })
      )
      this.setState({
        formAction: "update",
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (_.isEmpty(this.state.challenge)) {
      this.setState({
        challenge: this.props.challenge,
      })
    }
    if (
      this?.props?.queryParams?.challengeUuid !==
      prevProps?.queryParams?.challengeUuid
    ) {
      this._searchChallenge(this?.props?.queryParams?.challengeUuid)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onChallengeUpsertSubmit = async formData => {
    toastr.info("Action in progress !!")
    this.props.dispatch(upsertChallenge(formData))
  }

  _onDeleteBanner = data => {
    toastr.info("Action in progress !!")
    this.props.dispatch(deleteBanner(data))
  }

  _onManageChallengeClick = action => {
    let contentUUIDs = _.cloneDeep(
      this.props.challenge[this.state.videoActionMeta[action]["esType"]]
    )
    let target = _.cloneDeep(this.state.challenge.target)

    switch (action) {
      case "featured":
        contentUUIDs =
          contentUUIDs && contentUUIDs.length > 0 ? [...contentUUIDs] : []
        break
      case "leaders":
        contentUUIDs =
          contentUUIDs && contentUUIDs.length > 0
            ? contentUUIDs
                .sort((a, b) => a.rank > b.rank)
                .map(content => content.content_uuid)
            : []
        if (target && target.length > 0) {
          target.forEach(targetLang => {
            if (targetLang.leaderboard) {
              targetLang.leaderboard = targetLang.leaderboard
                .sort((a, b) => a.rank > b.rank)
                .map(content => content.content_uuid)
            }
          })
        }
        break
      case "winners":
        contentUUIDs =
          contentUUIDs && contentUUIDs.length > 0
            ? contentUUIDs
                .sort((a, b) => a.rank > b.rank)
                .map(content => content.content_uuid)
            : []
        if (target && target.length > 0) {
          target.forEach(targetLang => {
            if (targetLang.winners) {
              targetLang.winners = targetLang.winners
                .sort((a, b) => a.rank > b.rank)
                .map(content => content.content_uuid)
            }
          })
        }
        break
      default:
        contentUUIDs = []
    }

    this.setState({
      showUpdateChallengeMetaForm: true,
      showUpsertModal: false,
      action: action,
      contentUUIDs,
      fetchContent: true,
      showContentList: false,
      challenge: {
        ...this.state.challenge,
        target,
      },
    })
  }

  _onChallengeMetaSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    toastr.info("Action in progress !!")
    let { action } = this.state
    let { type, esType } = this.state.videoActionMeta[action]
    const clonedFormData = _.cloneDeep(formData)

    delete formData["undefined"]

    if (action === "winners" || action === "leaders" || action === "featured") {
      let uuids = [...clonedFormData[type]]
      this.state.challenge?.target?.forEach(item => {
        const newIds = item[esType] ? item[esType] : []
        uuids = _.uniq([...uuids, ...newIds])
      })

      let duplicateOrDeletedVideos = await this._getDuplicateOrDisabledVideos(
        uuids
      )
      if (duplicateOrDeletedVideos.length > 0) {
        toastr.error(
          "Duplicate / Deleted / Human Disabled Video is added: " +
            duplicateOrDeletedVideos.join(", ")
        )
        return
      }
    }

    if (action === "winners" || action === "leaders") {
      let counter = 1
      formData[type] = formData[type].map(content => ({
        content_id: content,
        rank: counter++,
      }))
      let targ
      targ = this.state.challenge?.target?.map(target => {
        if (target[esType] && target[esType].length > 0) {
          return {
            lang_code: target.lang_code,
            [type]: target[esType].map((item, index) => {
              return {
                content_id: item,
                rank: index + 1,
              }
            }),
          }
        }
      })

      if (targ) {
        targ = targ.filter(item => item != null)
      }

      this.props.dispatch(
        updateChallengeWinners({
          action: this.state.action,
          formData: { ...formData, target: targ },
        })
      )
    }

    if (action === "orig" || action === "featured" || action === "promo") {
      this.props.dispatch(
        updateChallengeVideoMeta({
          action: this.state.action,
          formData: {
            ...clonedFormData,
            target: this.state.challenge.target,
          },
        })
      )
    } else if (action === "status") {
      this.props.dispatch(updateChallengeStatus(formData))
    }
  }

  _updateChallengeMetaModal = () => {
    return (
      <>
        {this._updateChallengeMetaForm()}
        {this.state.showContentList &&
          this.props.challengeContentUUIDsDetails &&
          this.props.challengeContentUUIDsDetails.length > 0 && (
            <ChallengeContentList
              style={{ marginTop: "1rem" }}
              contentUUIDs={this.state.contentUUIDs}
              contentUUIDsDetails={this.props.challengeContentUUIDsDetails}
            />
          )}
      </>
    )
  }

  _updateChallengeMetaModalCancel = () => {
    let newState = _.cloneDeep(this.state)
    newState.showUpdateChallengeMetaModal = false
    newState.challenge = {}
    newState.contentUUIDs = []
    newState.showContentList = false
    this.setState(newState)
  }

  _updateChallengeMetaContentUUIDs = contentUUIDs => {
    if (contentUUIDs && contentUUIDs.length > 0) {
      this.setState({
        contentUUIDs: contentUUIDs,
        showContentList: true,
      })
    }
  }

  _getDuplicateOrDisabledVideos = async uuids => {
    let { data } = await getContentByUUIDs(uuids)
    const duplicateOrDeletedVideos = []
    for (let content of data) {
      if (
        (content.is_active !== undefined && !content.is_active) ||
        content.duplicate_content ||
        content.moderation_level < 0
      ) {
        duplicateOrDeletedVideos.push(content.content_uuid)
      }
    }
    return duplicateOrDeletedVideos
  }

  _handleChallengeIdChange = challengeUuid => {
    this.props.history.push(`/appuser/details?userUuid=${userId}`)
    this.setState(
      {
        filters: {
          ...this.state.filters,
          challengeUuid,
        },
      },
      () => {
        this.props.dispatch(
          getChallengeDetails({
            challengeUuid: this.state.filters.challengeUuid,
          })
        )
        this.setState({
          formAction: "update",
        })
      }
    )
  }

  _handleTargetFieldsChange = (field, value, index) => {
    const target = [...this.state.challenge.target]
    target[index] = {
      ...target[index],
      [field]: value,
    }

    this.setState({
      challenge: {
        ...this.state.challenge,
        target,
      },
    })
  }

  _renderTargetForm = (action, target, index) => {
    let { paramTitle, esType, type } = this.state.videoActionMeta[action]
    let chipsval = []
    if (
      target &&
      target[esType] &&
      (action === "featured" || action === "winners" || action === "leaders")
    ) {
      chipsval = target[esType]
    }
    return (
      <>
        <label className="col-form-label">Language</label>
        <div>
          <select
            className="form-select"
            defaultValue={target.lang_code}
            disabled
          >
            {LANGUAGES.map(lang => (
              <option value={lang.value}>{lang.label}</option>
            ))}
          </select>
        </div>
        {(action === "promo" || action === "orig") && (
          <>
            <Label htmlFor="type">{paramTitle}</Label>
            <Input
              label={paramTitle}
              placeholder={paramTitle}
              validations={["required"]}
              onChange={e =>
                this._handleTargetFieldsChange(type, e.target.value, index)
              }
              value={target && target[type] ? target[type] : ""}
              errors={["This field is required"]}
            />
          </>
        )}
        {(action === "featured" ||
          action === "winners" ||
          action === "leaders") && (
          <ChipsInput
            label={paramTitle}
            placeholderLabel={paramTitle}
            validations={["required"]}
            onChange={e =>
              this._handleTargetFieldsChange(esType, e.value, index)
            }
            value={chipsval}
            errors={["This field is required"]}
            style={{ width: "50%" }}
          />
        )}
      </>
    )
  }

  _updateChallengeMetaForm = () => {
    let { challenge, action } = this.state
    let { paramTitle, esType, type } = this.state.videoActionMeta[action]
    let chipsval = challenge[esType]

    if (["winners", "leaders"].includes(action)) {
      if (!chipsval) {
        chipsval = []
      } else {
        chipsval = [...challenge[esType]]
          .sort((a, b) => a.rank - b.rank)
          .map(item => item.content_uuid)
      }
    }
    return (
      <Card>
        <CardBody>
          <CardTitle>{paramTitle}</CardTitle>
          <ValidatorForm
            onSubmit={this._onChallengeMetaSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col lg={12}>
                <Label htmlFor="type">Challenge ID</Label>
                <Col lg="12">
                  <TextField
                    placeholder="Challenge ID"
                    disabled
                    defaultValue={this.state.filters.challengeUuid}
                    field="challenge_id"
                  />
                </Col>
              </Col>
              <Col lg={12}>
                {(action === "promo" || action === "orig") && (
                  <>
                    <TextField
                      label={paramTitle}
                      placeholder={paramTitle}
                      field={type}
                      validations={["required"]}
                      defaultValue={
                        challenge && challenge[esType]
                          ? challenge[esType]
                          : null
                      }
                      errors={["This field is required"]}
                    />
                  </>
                )}
                {(action === "featured" ||
                  action === "winners" ||
                  action === "leaders") && (
                  <ChipsInput
                    label={paramTitle}
                    placeholderLabel={paramTitle}
                    field={type}
                    validations={["required"]}
                    onChange={value =>
                      this._updateChallengeMetaContentUUIDs(value)
                    }
                    defaultValue={chipsval}
                    errors={["This field is required"]}
                    style={{ width: "50%" }}
                  />
                )}
                {(action === "featured" ||
                  action === "promo" ||
                  action === "orig" ||
                  action === "winners" ||
                  action === "leaders") && (
                  <>
                    {challenge?.target?.map((target, index) => {
                      return (
                        <>
                          <Divider>Target #{index + 1}</Divider>
                          {this._renderTargetForm(action, target, index)}
                        </>
                      )
                    })}
                  </>
                )}
                {action === "status" && (
                  <>
                    <Label htmlFor={type}>Status</Label>
                    <div>
                      <Select
                        label={"Status"}
                        placeholder="Challenge Status"
                        field={type}
                        defaultValue={
                          challenge && challenge[esType]
                            ? challenge[esType]
                            : null
                        }
                        options={[
                          { label: "Started", value: "STARTED" },
                          { label: "Not Started", value: "NOT_STARTED" },
                          { label: "Cancelled", value: "CANCELLED" },
                          { label: "Ended", value: "ENDED" },
                        ]}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-2">
              <button
                className="btn btn-primary"
                type="submit"
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                Update
              </button>
            </div>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _searchChallenge = value => {
    if (value) {
      this.props.history.push(`/challenge/manage?challengeUuid=${value}`)
      this.setState(
        {
          filters: {
            ...this.state.filters,
            challengeUuid: value,
          },
          formAction: "update",
        },
        () => {
          this.props.dispatch(
            getChallengeDetails({
              challengeUuid: value,
            })
          )
        }
      )
    }
  }

  _filters = () => {
    return (
      <Row style={{ marginBottom: "1rem" }}>
        <Col lg="4">
          <Input
            placeholder="Challenge ID"
            onChange={e => this._searchChallenge(e.target.value)}
            validations={["required"]}
            defaultValue={this.state.filters.challengeUuid}
          />
        </Col>
        {this.state.formAction === "update" && (
          <Col lg="8" className="d-flex justify-content-end mt-2">
            <div className="button-items">
              <ButtonDropdown
                isOpen={this.state.isTagging}
                toggle={() => {
                  if (
                    !this.props.currentUser?.permissions?.includes(
                      "EDIT_MANAGE_CHALLENGE"
                    )
                  ) {
                    toastr.error(NO_PERMISSION_MESSAGE)
                    return
                  }
                  this.setState({ isTagging: !this.state.isTagging })
                }}
              >
                <DropdownToggle caret color="primary">
                  <i className="bx bx-caret-down font-size-16 align-middle me-2"></i>{" "}
                  Tagging
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => this._onManageChallengeClick("winners")}
                  >
                    <i className="bx bx-trophy font-size-16 align-middle me-2" />
                    Winners
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this._onManageChallengeClick("leaders")}
                  >
                    <i className="bx bx-trophy font-size-16 align-middle me-2" />
                    Leaders
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this._onManageChallengeClick("featured")}
                  >
                    <i className="bx bx-trending-up font-size-16 align-middle me-2" />
                    Featured
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this._onManageChallengeClick("promo")}
                  >
                    <i className="dripicons-tags font-size-16 align-middle me-2" />
                    Promo
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this._onManageChallengeClick("orig")}
                  >
                    <i
                      className="mdi mdi-registered-trademark
 font-size-16 align-middle me-2"
                    />
                    Original
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>

              <button
                className="btn btn-primary"
                onClick={() => {
                  if (
                    !this.props.currentUser?.permissions?.includes(
                      "EDIT_MANAGE_CHALLENGE"
                    )
                  ) {
                    toastr.error(NO_PERMISSION_MESSAGE)
                    return
                  }
                  this._onManageChallengeClick("status")
                }}
              >
                <i className="bx bx-edit-alt font-size-16 align-middle me-2"></i>
                Status
              </button>
            </div>
          </Col>
        )}
      </Row>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        {!this.state.showUpdateChallengeMetaForm && (
          <ChallengeUpsertForm
            formAction={this.state.formAction}
            onSubmit={this._onChallengeUpsertSubmit}
            challenge={this.props.challenge}
            currentUserType={this.props.currentUserType}
            currentUser={this.props.currentUser}
            deleteBanner={this._onDeleteBanner}
            onAudioChange={() => {}}
          />
        )}
        {this.state.showUpdateChallengeMetaForm &&
          this._updateChallengeMetaModal()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "challenge.loading"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    audio: store.audio.audioList.length === 1 ? store.audio.audioList[0] : null,
    audioLoading: _.get(store, "audio.loading"),
    common: _.get(store, "common"),
    refetchChallengeList: _.get(store, "challenge.refetchChallengeList"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    challengeContentUUIDsDetails: _.get(
      store,
      "challenge.challengeContentUUIDsDetails"
    ),
    challenge: _.get(store, "challenge.challenge"),
  }
}

export default connect(mapStateToProps)(ManageChallenge)
