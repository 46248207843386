import Axios from "axios"
import toastr from "toastr"

export const bulkUploadSocialDeactivate = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/socials/bulk/deactivate`, formData)
    return data
  } catch (error) {
    toastr.error("Error in bulk deactivating socials")
    throw error
  }
}
