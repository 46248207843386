import React from "react"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Divider } from "antd"
import { Button } from "reactstrap"
import QuestionCard from "./questionCard"
import ImageCard from "./imageCard"
import { Col, Radio, Row } from "antd"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"

const { Group } = Radio

class Violence extends ErrorBoundComponent {
  state = {
    currentTab: "violence",
    labelToDisplay: "Violence",
  }

  _handleSelection = value => {
    const currentData = cloneDeep(this.props.currentData)
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    const indexTab = taxonomyRoutes.findIndex(item => item.key == "violence")
    if (value === "no_violence") {
      selectedDetails["taxonomy_violence"]["violence_type"] = ""
    } else {
      selectedDetails["taxonomy_violence"]["violence_type"] = value
    }

    // currentData.forEach(item => {
    //   if (item.key === question.key) {
    //     item.selected = true
    //     item.partialSelected = value
    //     item.value = value

    //     if (question.isMultiple) {
    //       const existingData =
    //         selectedDetails[question.taxonomyJsonKey][question.key]
    //       if (!existingData) {
    //         selectedDetails[question.taxonomyJsonKey][question.key] = [value]
    //         item.value = [value]
    //       } else {
    //         selectedDetails[question.taxonomyJsonKey][question.key] = uniq([
    //           ...existingData,
    //           value,
    //         ])
    //         item.value = uniq([...existingData, value])
    //       }
    //     } else {
    //       selectedDetails[question.taxonomyJsonKey][question.key] = value
    //       selectedDetails[question.taxonomyJsonKey]["sub_type"] = sub_type
    //     }
    //   }

    //   if (item.parent === question.key) {
    //     if (item.dependentValue === value) {
    //       item.isVisible = true
    //     } else {
    //       item.isVisible = false
    //       delete item.selected
    //       item.value = undefined
    //       item.partialSelected = undefined
    //       delete selectedDetails[question.taxonomyJsonKey][item.key]
    //     }
    //   } else {
    //     if (item.level >= question.level && item.key !== question.key) {
    //       item.isVisible = false
    //       delete item.selected
    //       item.value = undefined
    //       delete selectedDetails[question.taxonomyJsonKey][item.key]
    //     }
    //   }
    // })

    let stepCompleted = true

    // currentData.forEach(item => {
    //   if (item.isVisible && item.value == undefined) {
    //     stepCompleted = false
    //     taxonomyRoutes[indexTab].completed = false
    //   }
    // })

    if (stepCompleted) {
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    }
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )
    this.setState({
      currentData,
      ignore: false,
      tag: false,
      ignoreRatingLessThan4: false,
      retag: false,
    })

    this.props.setSelectedDetails(selectedDetails)
  }

  _renderCard = (question, key, idx) => {
    return (
      <>
        <div>
          <div>{question.label}</div>
          <div className="d-flex flex-wrap">
            {question.sublevel.map(item => {
              const data = _.cloneDeep(item)
              if (question.isMultiple) {
                if (
                  this.props.selectedDetails?.taxonomy_violence
                    ?.violence_type === data.value
                ) {
                  data.selected = true
                }
              } else {
                if (
                  this.props.selectedDetails?.taxonomy_violence
                    ?.violence_type === data.value
                ) {
                  data.selected = true
                } else {
                  data.selected = false
                }
              }
              return (
                <ImageCard
                  data={data}
                  _handleSelection={data => this._handleSelection(data.value)}
                  renderCardContent={this.props.renderCardContent}
                />
              )
            })}
          </div>
        </div>
      </>
    )
  }

  _renderStepper = () => {
    let text = "Select All"
    this.props.currentData.forEach(item => {
      if (item.level > 0) {
        text = "Questionnaire"
      }
    })

    return (
      <div style={{ margin: "1rem", fontWeight: "bold" }}>
        {this.state.labelToDisplay}&nbsp;({text})
      </div>
    )
  }

  _renderQuestions = () => {
    return (
      <div className="d-flex text-center contentCard my-2">
        <PerfectScrollbar>
          <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
            {this.props.currentData.map((question, idx) => {
              if (question.type === "card") {
                return this._renderCard(question, idx)
              }

              return (
                <ImageCard
                  data={question}
                  _handleSelection={data => this._handleSelection(data.key)}
                  renderCardContent={this.props.renderCardContent}
                />
              )
            })}
          </div>
        </PerfectScrollbar>
      </div>
    )
  }

  _renderTitle = () => {
    return <div className="font-weight-semibold pt-3">Violence</div>
  }

  _renderOptions = () => {
    return (
      <div className="d-flex">
        {
          <Row
            className={`d-flex  text-center contentCard my-3 justify-content-around`}
          >
            <Col span={24} className="my-2">
              What kind of violence is diplayed in the video?
            </Col>
            {/* <Col span={24} className="d-flex flex-wrap mx-4">
              <Group
                style={{ display: "contents" }}
                onChange={e => this._handleSelection(e.target.value)}
                value={
                  this.props.selectedDetails.taxonomy_violence.violence_type
                }
              >
                <div className="topicRadioBtn">
                  <Radio value="">No Violence</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="physical_abuse">Human Abuse</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="animal_sports">Animal Abuse</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="legal_display_of_weapons">
                    Legal usage of weapons
                  </Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="public_protest">Public protest</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="property_damage">Property damage</Radio>
                </div>
              </Group>
            </Col> */}
          </Row>
        }
      </div>
    )
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderTitle()}
        {this._renderOptions()}
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store) {
  return {
    currentUser: _.get(store, "currentUser.currentUser.data"),
    currentData: _.get(store, "taxonomy.violence"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(Violence)
