import { createSlice } from "@reduxjs/toolkit"

const stickerSlice = createSlice({
  name: "stickers",
  initialState: {
    loading: false,
    refetchData: false,
    stickersTabList: [],
    stickersSubTypeList: [],
    mimeTypes: [],
    subTabsForTab: [],
    stickersForsubTabsTab: [],
    stickersList: [],
    assets: [],
  },
  reducers: {
    getStickersTabList: (state, action) => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    getStickersTabListSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        stickersTabList: action.payload.data || [],
        refetchData: false,
      }
    },

    upsertStickerAsset: (state, action) => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    upsertStickerAssetSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    uploadBulkStickers() {},
    deleteStickerTabById: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    deleteStickerTabByIdSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    deleteStickerById: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    deleteStickerByIdSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    updateStickerTabOrder: state => {
      return {
        ...state,
        loading: true,
      }
    },

    updateStickerTabOrderSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    getStickersSubTypeList: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    getStickersSubTypeListSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        stickersSubTypeList: action.payload.data || [],
        refetchData: false,
      }
    },

    upsertStickerSubType: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    upsertStickerSubTypeSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    deleteStickerSubTypeById: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    deleteStickerSubTypeByIdSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    getMimeTypes: (state, { payload }) => {
      return {
        ...state,
        // loading: true,
        refetchData: false,
      }
    },

    getMimeTypesSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        mimeTypes: action.payload.data,
        refetchData: false,
      }
    },

    getSubTabsForTab: state => {
      return {
        ...state,
        loading: true,
      }
    },

    getSubTabsForTabSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        subTabsForTab:
          action.payload && action.payload.data ? action.payload.data : [],
        refetchData: false,
      }
    },

    updateStickerSubTabMapping: state => {
      return {
        ...state,
        loading: true,
      }
    },

    updateStickerSubTabMappingSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    getStickersForSubTabsTab: state => {
      return {
        ...state,
        loading: true,
      }
    },

    getStickersForSubTabsTabSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        stickersForsubTabsTab: action.payload.data.rows || [],
        refetchData: false,
      }
    },

    updateStickersMapping: state => {
      return {
        ...state,
        loading: true,
      }
    },

    updateStickersMappingSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    upsertSticker: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    upsertStickerSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    getStickers: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    getStickersSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        stickersList: payload.data,
        refetchData: false,
      }
    },

    updateStickerTabVersion: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    updateStickerTabVersionSuccess: state => {
      return {
        ...state,
        loading: false,
      }
    },

    resetState: state => {
      return {
        ...state,
        assets: [],
        refetchData: false,
      }
    },
  },
})

export const {
  getStickers,
  getStickersSuccess,
  getStickersForSubTabsTab,
  getStickersForSubTabsTabSuccess,
  getMimeTypes,
  getMimeTypesSuccess,
  updateStickerTabVersion,
  updateStickerTabVersionSuccess,
  upsertSticker,
  upsertStickerSuccess,
  upsertStickerAsset,
  upsertStickerAssetSuccess,
  updateStickerSubTabMapping,
  updateStickerSubTabMappingSuccess,
  deleteStickerById,
  deleteStickerByIdSuccess,
  deleteStickerSubTypeById,
  deleteStickerSubTypeByIdSuccess,
  deleteStickerTabById,
  deleteStickerTabByIdSuccess,
  getStickersSubTypeList,
  getStickersSubTypeListSuccess,
  getStickersTabList,
  getStickersTabListSuccess,
  getSubTabsForTab,
  getSubTabsForTabSuccess,
  updateStickerTabOrder,
  uploadBulkStickers,
  updateStickerTabOrderSuccess,
  updateStickersMapping,
  updateStickersMappingSuccess,
  upsertStickerSubType,
  upsertStickerSubTypeSuccess,
  resetState,
} = stickerSlice.actions

export default stickerSlice.reducer
