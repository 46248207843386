import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Table,
  Badge,
  Row,
  CardImg,
  CardFooter,
} from "reactstrap"
import { Tooltip } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const ZoneMapCard = props => {
  const [showMapParent, setShowMapParent] = useState(false)
  const [showMapChild, setShowMapChild] = useState(false)
  const [showSiblingBtn, setShowSiblingBtn] = useState(false)
  const [showSibling, setShowSibling] = useState(false)
  const { zone = {} } = props || {}
  // useEffect(() => {
  //   if (props?.selectedZoneParent?.zone_uuid !== props?.zone?.zone_uuid && props?.selectedZoneParent?.level == props?.zone?.level) {
  //     setShowMapParent(false)
  //   }
  //   if (props?.selectedZoneChild?.zone_uuid !== props?.zone?.zone_uuid && props?.selectedZoneChild?.level == props?.zone?.level) {
  //     setShowMapChild(false)
  //   }
  // })
  return (
    <>
      {/* {zone?.level != 0 &&
      props?.keyZone?.zone_uuid == props?.zone?.zone_uuid ? (
        <div className="d-flex justify-content-around align-items-center mt-1">
          <i
            class={`fa fa-chevron-circle-up fa-2x`}
            style={{
              color: "green",
              cursor: "pointer",
              visibility:
                zone?.level != 0 &&
                props?.keyZone?.zone_uuid == props?.zone?.zone_uuid
                  ? ""
                  : "hidden",
            }}
            aria-hidden="true"
            // onClick={() => {
            //   if (showMapParent) {
            //     setShowMapParent(false)
            //     props.showMap(zone.level, "hide_parent")
            //   } else {
            //     setShowMapParent(true)
            //     // props.setSelectedZoneParent(zone)
            //     props.showMap(zone.level, "show_parent")
            //   }
            // }}
          ></i>
        </div>
      ) : null} */}
      <Card
        className={`text-center m-2 zoneMapCard`}
        style={
          props.zone.isKey ||
          props?.keyZone?.zone_uuid == props?.zone?.zone_uuid
            ? {
                border: "5px solid green",
              }
            : {
                border: "1px solid silver",
              }
        }
        onClick={() => props.setKeyZone(zone)}
      >
        <div className="bg-primary bg-soft">
          <Row
            onMouseOver={() => setShowSiblingBtn(true)}
            onMouseLeave={() => setShowSiblingBtn(false)}
            className="justify-content-center"
          >
            {zone && zone.zone_image ? (
              <div>
                <CardImg
                  top
                  className="img-fluid"
                  src={zone.zone_image}
                  alt="Skote"
                  style={{
                    height: "9rem",
                    cursor: "pointer",
                    opacity: showSiblingBtn ? "0.5" : "0.8",
                  }}
                />
              </div>
            ) : (
              <div
                className="px-3"
                style={{
                  width: "85%",
                  height: "9rem",
                  cursor: "pointer",
                  backgroundColor: "lightgray",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  opacity: showSiblingBtn ? "0.5" : "1",
                }}
              >
                <i className="fas fa-volume-mute fa-7x py-4 text-secondary" />
              </div>
            )}
            {showSiblingBtn &&
            props?.keyZone?.zone_uuid == props?.zone?.zone_uuid ? (
              showSibling ? (
                <div
                  className="sibling_btn"
                  onClick={() => {
                    setShowSibling(false)
                    props.showSibling(false)
                  }}
                  title="Hide Sibling"
                >
                  <i class="fa fa-angle-double-right fa-2x"></i>
                  <i class="fa fa-angle-double-left fa-2x ms-1"></i>
                </div>
              ) : (
                <div
                  className="sibling_btn"
                  onClick={() => {
                    setShowSibling(true)
                    props.showSibling(true)
                  }}
                  title="Show Sibling"
                >
                  <i class="fa fa-angle-double-left fa-2x"></i>
                  <i class="fa fa-angle-double-right fa-2x ms-1"></i>
                </div>
              )
            ) : null}
            {zone.level || zone.level == 0 ? (
              <Tooltip title="Level">
                <div className="zone_map_card_level font-weight-semibold font-size-12 level_position">
                  {zone.level == 0 ? "L0" : `L${zone.level}`}
                </div>
              </Tooltip>
            ) : null}
          </Row>
        </div>
        <CardBody className="pt-2 pb-2 ps-1 pe-1">
          {/* <div className="d-flex justify-content-between "> */}
          <div className="d-flex align-items-center flex-wrap">
            <Tooltip title={zone.title}>
              <div className="font-size-12 zone-title-ellipse font-weight-semibold">
                {zone.title}
              </div>
            </Tooltip>
          </div>
          {/* <div className="d-flex justify-content-between align-items-center flex-wrap"> */}
          {/* {(zone.level || zone.level == 0) && (
              <Tooltip title="Level">
                <div className="zone_map_card_level font-weight-semibold font-size-10">
                  {zone.level == 0 ? "L0" : `L${zone.level}`}
                </div>
              </Tooltip>
            )} */}

          {/* </div> */}
          {/* </div> */}
        </CardBody>
        <CardFooter className="p-0 pt-1">
          <div
            className="d-flex justify-content-between align-items-center flex-wrap"
            // style={{
            //   borderBottomLeftRadius: "10px",
            //   borderBottomRightRadius: "10px",
            // }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={zone.zone_uuid}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Zone ID">
                  <i className="bx bx-copy bx-xs" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <Tooltip title="View Zone Details">
                {/* <Link
                    to={`/zone/detail?pageType=video&zone_id=${zone.zone_uuid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
                <div>
                  <i
                    class="bx bx-link-external bx-xs"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(
                        `/zone/detail?pageType=video&zone_id=${zone.zone_uuid}`,
                        "_blank"
                      )
                    }}
                  />
                </div>
                {/* </Link> */}
              </Tooltip>
            </div>
          </div>
        </CardFooter>
      </Card>
      {/* {zone?.level != 2 &&
      props?.keyZone?.zone_uuid == props?.zone?.zone_uuid ? (
        <div className="d-flex justify-content-around align-items-center">
          <i
            class="fa fa-chevron-circle-down fa-2x"
            style={{
              color: "green",
              cursor: "pointer",
              visibility:
                zone?.level != 2 &&
                props?.keyZone?.zone_uuid == props?.zone?.zone_uuid
                  ? ""
                  : "hidden",
            }}
            aria-hidden="true"
            // onClick={() => {
            //   if (showMapChild) {
            //     setShowMapChild(false)
            //     props.showMap(zone.level, "hide_child")
            //   } else {
            //     setShowMapChild(true)
            //     // props.setSelectedZoneChild(props.zone)
            //     props.showMap(zone.level, "show_child")
            //   }
            // }}
          ></i>
        </div>
      ) : null} */}
    </>
  )
}

export default ZoneMapCard
