import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import { Row, Col, Card, CardTitle, CardBody } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { Tabs, Upload } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import ColdStartList from "./ColdStartList"
import UpsertInterest from "./UpsertInterests"
import ManageSeed from "./ManageSeed"
import { MdAdd } from "react-icons/md"
import ReviewSeed from "./ReviewSeed"
import AddTabVideos from "./AddTabVideos"
import ValidatorForm from "containers/ValidatorForm"
import { uploadContent } from "../../appRedux/slices/content"

class ManageColdStart extends ErrorBoundComponent {
  state = {
    showInterestForm: false,
    items: [
      {
        key: "1",
        label: `Public`,
        children: (
          <ColdStartList
            mode="PUBLIC"
            _toggleInterest={(action, data) =>
              this.setState({
                showInterestForm: !this.state.showInterestForm,
                action,
                interestData: data,
              })
            }
            toggleManageReviewVideos={data => {
              this.setState({
                showReviewSeed: true,
                currentInterest: data.currentInterest,
                currentSubInterest: data.currentSubInterest,
                subInterestName: data.subInterestName,
              })
            }}
            toggleManageSeedVideos={data => {
              this.setState({
                showManageSeed: true,
                currentInterest: data.currentInterest,
                currentSubInterest: data.currentSubInterest,
                subInterestName: data.subInterestName,
              })
            }}
            toggleManageAddVideos={data => {
              this.setState({
                showAddVideos: true,
                currentInterest: data.currentInterest,
                currentSubInterest: data.currentSubInterest,
                subInterestName: data.subInterestName,
              })
            }}
            toggleAddVideos={data => {
              this.setState({
                showTabAddVideos: true,
                currentInterest: data.currentInterest,
                currentSubInterest: data.currentSubInterest,
                subInterestName: data.subInterestName,
              })
            }}
          ></ColdStartList>
        ),
      },
      // {
      //   key: "2",
      //   label: `Private`,
      //   children: (
      //     <ColdStartList
      //       mode="PRIVATE"
      //       _toggleInterest={(action, data) =>
      //         this.setState({
      //           showInterestForm: !this.state.showInterestForm,
      //           action,
      //           interestData: data,
      //         })
      //       }
      //       toggleManageReviewVideos={data => {
      //         this.setState({
      //           showReviewSeed: true,
      //           currentInterest: data.currentInterest,
      //           currentSubInterest: data.currentSubInterest,
      //           subInterestName: data.subInterestName,
      //         })
      //       }}
      //       toggleManageSeedVideos={data => {
      //         this.setState({
      //           showManageSeed: true,
      //           currentInterest: data.currentInterest,
      //           currentSubInterest: data.currentSubInterest,
      //           subInterestName: data.subInterestName,
      //         })
      //       }}
      //       toggleManageAddVideos={data => {
      //         this.setState({
      //           showAddVideos: true,
      //           currentInterest: data.currentInterest,
      //           currentSubInterest: data.currentSubInterest,
      //           subInterestName: data.subInterestName,
      //         })
      //       }}
      //     ></ColdStartList>
      //   ),
      // },
    ],
    showUploadForm: false,
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _toggleInterest = (action, data) => {
    this.setState({
      showInterestForm: !this.state.showInterestForm,
      action,
      interestData: data,
    })
  }

  onChange = key => {
    console.log(key)
  }

  toggleAddInterest = () => {
    this.setState({ showInterestForm: false })
  }

  _handleCloseSeed = () => {
    this.setState({
      showManageSeed: false,
      showReviewSeed: false,
      showTabAddVideos: false,
    })
  }

  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }
  _onVideoRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }
  _onBulkSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    try {
      formData.bulk_upload = true
      formData.uploadType = "update"
      let data = new FormData()
      data.append("file", this.state.csvFile)
      data.append("details", JSON.stringify(formData))
      this.props.dispatch(uploadContent(data))
      // const response = await upsertBulkInstagramData(data)
    } catch (error) {}
  }

  _bulkinstaHandleUpsertFrom = () => {
    return (
      <Card>
        <CardTitle className="m-2">Bulk Action</CardTitle>
        <CardBody>
          <ValidatorForm layout={"vertical"} onSubmit={this._onBulkSubmit}>
            <Row>
              <Col style={{ marginTop: "30px" }}>
                <Upload
                  showUploadList={true}
                  onRemove={this._onVideoRemove}
                  defaultFileList={this.state.csvFileList}
                  fileList={this.state.csvFileList}
                  customRequest={this._uploadCSV}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<UploadOutlined />}
                  >
                    Click to upload CSV
                  </Button>
                </Upload>
              </Col>
            </Row>
            <Row>
              <div className="d-flex justify-content-end">
                <Button
                  type="submit"
                  variant="contained"
                  className="font-16 btn-block btn btn-primary"
                  color="success"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  {/* <i className="bx bx-plus me-1" /> */}
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showUploadForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }
  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          {this.state.showInterestForm && (
            <UpsertInterest
              toggleAddInterest={this.toggleAddInterest}
              interestData={this.state.interestData}
              action={this.state.action}
            />
          )}
          {(this.state.showManageSeed || this.state.showAddVideos) && (
            <ManageSeed
              interest={this.state.currentInterest}
              subInterest={this.state.currentSubInterest}
              showAddVideos={this.state.showAddVideos}
              subInterestName={this.state.subInterestName}
              closeInterest={() => {
                this.setState({ showManageSeed: true })
              }}
              handleCloseSeed={this._handleCloseSeed}
            />
          )}
          {(this.state.showTabAddVideos || this.state.showAddVideos) && (
            <AddTabVideos
              interest={this.state.currentInterest}
              subInterest={this.state.currentSubInterest}
              showAddVideos={this.state.showAddVideos}
              subInterestName={this.state.subInterestName}
              closeInterest={() => {
                this.setState({ showTabAddVideos: true })
              }}
              handleCloseSeed={this._handleCloseSeed}
            />
          )}
          {this.state.showReviewSeed && (
            <ReviewSeed
              interest={this.state.currentInterest}
              subInterest={this.state.currentSubInterest}
              showAddVideos={this.state.showAddVideos}
              subInterestName={this.state.subInterestName}
              handleCloseSeed={this._handleCloseSeed}
              closeInterest={() => {
                this.setState({ showReviewSeed: true })
              }}
            />
          )}

          {!this.state.showInterestForm &&
            !this.state.showManageSeed &&
            !this.state.showReviewSeed &&
            !this.state.showTabAddVideos && (
              <>
                <Row>
                  <Col lg="10" md="12">
                    <h3>Manage Cold Start</h3>
                  </Col>

                  <Col lg="2" md="12">
                    <div className="d-flex justify-content-end mb-3">
                      <Button
                        variant="contained"
                        onClick={() => this.setState({ showUploadForm: true })}
                      >
                        Upload
                      </Button>
                    </div>
                  </Col>
                </Row>
                {this.state.showUploadForm && this._bulkinstaHandleUpsertFrom()}

                <Tabs
                  defaultActiveKey="1"
                  items={this.state.items}
                  onChange={this.onChange}
                />
              </>
            )}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageColdStart)
