import React from "react"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Steps } from "antd"
import ImageCard from "./imageCard"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"

const { Step } = Steps

class Cards extends ErrorBoundComponent {
  _handleSelection = data => {
    let currentData = _.cloneDeep(this.props.currentData)
    let currentLevel = this.state.currentLevel
    let currentHierarchy = [...this.state.currentHierarchy]
    let selectionType = this.state.selectionType
    let labelToDisplay = this.state.labelToDisplay
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = _.cloneDeep(this.props.taxonomyRoutes)

    const indexTab = taxonomyRoutes.findIndex(
      item => item.key == this.props.selectedSection
    )
    if (selectionType === "ALL") {
      currentData.forEach(item => {
        if (item.value === data.value) {
          item.selected = true
        }
      })
    } else {
      currentData.forEach(item => {
        if (item.value === data.value) {
          item.selected = true
          selectedDetails[data.taxonomyJsonKey][data.key] = item.value
        } else {
          if (item.level > data.level) {
            item.selected = false
            selectedDetails[item.taxonomyJsonKey][item.key] = ""
          }
          if (item.level === data.level) {
            item.selected = false
          }
        }
      })
      currentLevel++
    }

    currentData.forEach(item => {
      if (item.level === data.level + 1) {
        if (
          item.parent === data.value ||
          (Array.isArray(item.parent) && item.parent.includes(data.value))
        ) {
          item.isVisible = true
        } else {
          delete item.isVisible
        }
      }
    })

    const nextLevelItems = currentData.filter(
      item =>
        item.level === currentLevel &&
        (item.parent === data.value ||
          (Array.isArray(item.parent) && item.parent.includes(data.value)))
    ).length

    if (nextLevelItems <= 0) {
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    } else {
      taxonomyRoutes[indexTab].completed = false
    }

    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.setSelectedDetails(selectedDetails)
    this.setState({
      currentData,
      currentHierarchy,
      selectedDetails,
      selectionType,
      labelToDisplay,
      currentLevel,
    })
  }

  _renderStepper = () => {
    let text =
      this.state.selectionType === "SINGLE" ? "Select Any" : "Select All"
    const filterdData = this.props.currentData.filter(card => {
      if (card.level === this.state.currentLevel) return true
    })
    let displayLabel
    filterdData.forEach(item => {
      if (item.displayLabel && item.isVisible) displayLabel = item.displayLabel
    })
    const steps = [...Array(this.state.currentLevel + 1).keys()]
    if (this.state.currentLevel > 0) {
      return (
        <div>
          <Steps
            type="navigation"
            size="small"
            current={this.state.currentLevel}
          >
            {steps.map((item, i) => (
              <Step key={item} title={`L${i}`} />
            ))}
          </Steps>
          <div style={{ margin: "1rem", fontWeight: "bold" }}>
            {displayLabel}
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ margin: "1rem", fontWeight: "bold" }}>
          {this.state.labelToDisplay}&nbsp;({text})
        </div>
      )
    }
  }

  _renderQuestions = () => {
    let data = this.props.currentData
    const filterdData = data.filter(card => {
      if (card.level === this.state.currentLevel && card.isVisible) return true
    })
    return (
      <PerfectScrollbar>
        <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
          {filterdData.map((data, index) => {
            return (
              <ImageCard
                key={index}
                data={data}
                _handleSelection={this._handleSelection}
                renderCardContent={this.props.renderCardContent}
              />
            )
          })}
        </div>
      </PerfectScrollbar>
    )
  }
  render() {
    return false
  }
}

export default Cards
