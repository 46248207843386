import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Chips from "components/CommonFilter/chips"
import {
  communityLeaderboardFilters,
  NO_PERMISSION_MESSAGE,
} from "../../constants/uiConstants"
import QueryString from "query-string"
import {
  Card,
  CardBody,
  Col,
  CardTitle,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Pagination } from "antd"
import CopyToClipboard from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import LeaderboardUpsertForm from "./leaderboardUpsertForm"
import Auxiliary from "../../util/Auxiliary"
import {
  uploadLeaderboard,
  getLeaderboardDataByFilters,
} from "appRedux/slices/community"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { isEmpty, size, map } from "lodash"

class CommunityLeaderboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leaderboardUsers: [],
      modal: false,
      expanded: false,
      filters: {
        currentPage: this.props.queryParams
          ? this.props.queryParams["currentPage"]
          : 1,
        pageSize:
          this.props.queryParam && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        podLeader:
          this.props.queryParams && this.props.queryParams["podLeader"]
            ? this.props.queryParams["podLeader"]
            : "",
        podAM:
          this.props.queryParams && this.props.queryParams["podAM"]
            ? this.props.queryParams["podAM"]
            : "",
        pod:
          this.props.queryParams && this.props.queryParams["pod"]
            ? this.props.queryParams["pod"]
            : "",
        paidType:
          this.props.queryParams && this.props.queryParams["paidType"]
            ? this.props.queryParams["paidType"]
            : "",
        langCode:
          this.props.queryParams && this.props.queryParams["langCode"]
            ? this.props.queryParams["langCode"]
            : "",
        rankSegment:
          this.props.queryParams && this.props.queryParams["rankSegment"]
            ? this.props.queryParams["rankSegment"]
            : "",
      },
      leaderboardColumns: [
        {
          text: <div style={{ color: "Black" }}>{"Rank"}</div>,
          dataField: "userRank",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Handle Name"}</div>,
          dataField: "handleName",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Rank Gained/Lost"}</div>,
          dataField: "rankGainedLost",
          sort: true,
          formatter: (cellContent, user) => (
            <div>
              {user.rankGainedLost < 0 ? (
                <span>
                  <i style={{ color: "red" }} className="bx bx-caret-down" />
                  {user.rankGainedLost}
                </span>
              ) : user.rankGainedLost == 0 ? (
                <span>{user.rankGainedLost}</span>
              ) : (
                <span>
                  <i style={{ color: "green" }} className="bx bx-caret-up" />
                  {user.rankGainedLost}
                </span>
              )}
            </div>
          ),
        },
        {
          text: <div style={{ color: "Black" }}>{"Rant Segment"}</div>,
          dataField: "rankSegment",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Prev. Rank"}</div>,
          dataField: "previousRank",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Fans"}</div>,
          dataField: "fans",
          sort: true,
          formatter: (cellContent, user) => (
            <div className="text-truncate">
              {user.fans
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          ),
        },
        {
          text: <div style={{ color: "Black" }}>{"Final Score"}</div>,
          dataField: "finalScore",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Language"}</div>,
          dataField: "langCode",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Paid Type"}</div>,
          dataField: "paidType",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Pod"}</div>,
          dataField: "pod",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Pod Leader"}</div>,
          dataField: "podLeader",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Pod AM"}</div>,
          dataField: "podAM",
          sort: true,
        },
        {
          text: <div style={{ color: "Black" }}>{"Active %"}</div>,
          dataField: "activePercentage",
          sort: true,
          formatter: (cellContent, user) => (
            <div className="text-truncate">{user.activePercentage + "%"}</div>
          ),
        },
        {
          text: <div style={{ color: "Black" }}>{"Content Popularity"}</div>,
          dataField: "contentPopularity",
          sort: true,
          formatter: (cellContent, user) => (
            <div className="text-truncate">{user.contentPopularity + "%"}</div>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    const { leaderboardUsers } = this.props
    this.setState({ leaderboardUsers })
    let filters = _.cloneDeep(this.state.filters)
    this.props.dispatch(getLeaderboardDataByFilters(filters))
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.state
    if (
      !this.props.common.error &&
      this.props.queryParams &&
      JSON.stringify(prevProps.queryParams) !==
        JSON.stringify(this.props.queryParams)
    ) {
      this.props.dispatch(getLeaderboardDataByFilters(filters))
    }
  }

  _onBulkUploadLeaderboard = () => {
    this.setState({
      showForm: true,
      formAction: "upload",
    })
  }

  _onPageChange = value => {
    this.setState({ loading: true })
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._search()
      }
    )
  }

  _onSubmitLeaderboard = formData => {
    this.props.dispatch(uploadLeaderboard(formData))
  }

  _modal = () => {
    return (
      <Card>
        <CardTitle className="p-3 pb-0">{"Upload Leaderboard"}</CardTitle>
        <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
          {this.state.formAction === "upload" && (
            <LeaderboardUpsertForm
              currentUser={this.props.currentUser}
              action={this.state.formAction}
              onCancel={this.oncancel}
              onSubmit={this._onSubmitLeaderboard}
              selectedUser={this.state.selectedUser}
            />
          )}
        </CardBody>
      </Card>
    )
  }

  oncancel = () => {
    this.setState({ showForm: false, selectedUser: undefined })
  }
  /* Insert,Update Delete data */

  _handleFieldChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _search = () => {
    this.props.history.push(
      `/community/leaderboard?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: communityLeaderboardFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/community/leaderboard?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
      }
    )
  }

  render() {
    // if (this.props.loading) {
    //   return
    // }
    const { SearchBar } = Search
    const { isEdit, leaderboardUsers = [] } = this.state

    const defaultSorted = [
      {
        dataField: "userRank", // if dataField is not match to any column you defined, it will be ignored.
        order: "asc", // desc or asc
      },
    ]

    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <React.Fragment>
          <Container fluid style={{ minHeight: "90vh" }}>
            <Row>
              <Col span={22}>
                <Chips
                  showFilters={this.state.showFilters}
                  filters={this.state.filters}
                  search={this._search}
                  removeFilter={this._removeFilter}
                  handleFilterChange={this._handleFieldChange}
                  type="community_leaderboard"
                  toggleFilters={this._toggleFilters}
                />
              </Col>
            </Row>
            <Row className="justify-content-end">
              {!this.state.showForm && (
                <Col sm="8">
                  <div className="text-sm-end pb-2">
                    <Button
                      color="primary"
                      style={{ marginLeft: "5px" }}
                      onClick={this._onBulkUploadLeaderboard}
                    >
                      <i className="bx bx-upload me-1" />
                      Bulk Upload
                    </Button>
                  </div>
                </Col>
              )}
              {!this.state.showForm &&
                this.props.leaderboardUsers &&
                this.props.leaderboardUsers.length > 0 && (
                  <Col lg={this.state.showForm ? "7" : "12"}>
                    <Card>
                      <CardBody>
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.leaderboardColumns}
                          data={this.props.leaderboardUsers}
                          bootstrap4
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover text-center"
                                      }
                                      keyField="userId"
                                      bordered={true}
                                      striped={true}
                                      responsive
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <Pagination
                                    style={{
                                      marginTop: "1rem",
                                      listStyle: "none",
                                      display: "flex",
                                    }}
                                    onChange={val => this._onPageChange(val)}
                                    defaultCurrent={
                                      this.props.queryParams.currentPage
                                    }
                                    total={
                                      this.props.total < 10000
                                        ? this.props.total
                                        : 10000
                                    }
                                    pageSize={this.state.filters.pageSize}
                                    showSizeChanger={false}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              {this.state.showForm && <Col lg="12">{this._modal()}</Col>}
            </Row>
          </Container>
        </React.Fragment>
      </Auxiliary>
    )
  }
}

CommunityLeaderboard.propTypes = {
  leaderboardUsers: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
}
function mapStateToProps(store, ownProps) {
  return {
    leaderboardUsers: _.get(store, "community.leaderboardUsers"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : 1,
    total: _.get(store, "community.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    communityManagers: _.get(store, "community.communityManagers"),
    currentUserType: _.get(store, "currentUser.currentUser.data.userType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "community.leaderboardloading"),
    refetchData: _.get(store, "community.refetchData"),
    totalManagers: _.get(store, "community.totalManagers"),
    leadsData: _.get(store, "community.leads"),
  }
}

export default connect(mapStateToProps)(CommunityLeaderboard)
