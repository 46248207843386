import Axios from "axios"
import toastr from "toastr"
import QueryString from "query-string"

export const requestStickerTabsList = async ({ payload }) => {
  try {
    const data = await Axios.get(
      `/api/stickerAssets/getStickersTabList?selectedFilter=${payload.selectedFilter}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestStickers = async obj => {
  const { payload } = obj
  try {
    if (payload.name) {
      const data = await Axios.get(
        `/api/stickerAssets/getAllStickers?pageNumber=${
          payload.pageNumber - 1
        }&pageSize=${payload.pageSize}&selectedFilter=${
          payload.selectedFilter
        }&mimeType=${payload.mimeType}&isActive=${payload.isActive}&name=${
          payload.name
        }`
      )
      return data
    } else {
      const data = await Axios.get(
        `/api/stickerAssets/getAllStickers?pageNumber=${
          payload.pageNumber - 1
        }&pageSize=${payload.pageSize}&selectedFilter=${
          payload.selectedFilter
        }&mimeType=${payload.mimeType}&isActive=${payload.isActive}`
      )
      return data
    }
  } catch (error) {
    throw error
  }
}

export const requestDeleteStickerTab = async payload => {
  try {
    const data = await Axios.delete(
      `/api/stickerAssets/deleteStickerTab?selectedFilter=${payload.selectedFilter}&tabId=${payload.tabId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteSticker = async payload => {
  try {
    const data = await Axios.delete(
      `/api/stickerAssets/deleteSticker?selectedFilter=${payload.selectedFilter}&tabId=${payload.tabId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUploadBulkStickers = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/stickers/upload/bulk`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertStickerAsset = async payload => {
  try {
    const data = await Axios.post(
      `/api/stickerAssets/upsertStickerAsset?${QueryString.stringify(
        payload.query
      )}`,
      payload.payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertSticker = async payload => {
  try {
    const data = await Axios.post(
      `/api/stickerAssets/upsertSticker?${QueryString.stringify(
        payload.query
      )}`,
      payload.formData
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestStickerSubTypeList = async ({ payload }) => {
  try {
    const data = await Axios.get(
      `/api/stickerAssets/getStickersSubTypeList?selectedFilter=${payload.selectedFilter}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteStickerSubType = async payload => {
  try {
    const data = await Axios.delete(
      `/api/stickerAssets/deleteStickerSubType?selectedFilter=${payload.selectedFilter}&subTabId=${payload.subTabId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertStickerSubType = async payload => {
  try {
    const data = await Axios.post(
      `/api/stickerAssets/upsertStickerSubType?${QueryString.stringify(
        payload.query
      )}`,
      payload.payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestMimeTypes = async ({ payload }) => {
  try {
    const data = await Axios.get(
      `/api/stickerAssets/getMimeTypes?selectedFilter=${payload.selectedFilter}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestAllSubTabsForTab = async payload => {
  try {
    const data = await Axios.get(
      `/api/stickerAssets/getSubTabsForTab?tabId=${payload.tabId}&selectedFilter=${payload.selectedFilter}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateStickerSubTabMapping = async ({ payload }) => {
  try {
    let data = await Axios.post(
      `/api/stickerAssets/updateStickerSubTabMapping?selectedFilter=${payload.selectedFilter}&tabId=${payload.tabId}`,
      payload.payload
    )
    toastr.success(`Order Updated successfully!!`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestAllStickersForTabSubTab = async payload => {
  try {
    const data = await Axios.get(
      `/api/stickerAssets/getStickersForTabSubTab?tabId=${payload.tabId}&subTabId=${payload.subTabId}&selectedFilter=${payload.selectedFilter}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateStickerMapping = async ({ payload }) => {
  try {
    let data = await Axios.post(
      `/api/stickerAssets/updateStickerMappingOrder?selectedFilter=${payload.selectedFilter}&tabId=${payload.tabId}&subTabId=${payload.subTabId}`,
      payload.payload
    )
    toastr.success(`Order Updated successfully!!`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateStickerTabVersion = async ({
  selectedFilter,
  tabId,
}) => {
  try {
    const data = await Axios.post(
      `/api/stickerAssets/updateStickerTabVersion?selectedFilter=${selectedFilter}&tabId=${tabId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateStickerTabOrder = async ({ payload }) => {
  try {
    let data = await Axios.post(
      `/api/stickerAssets/updateStickerTabOrder?selectedFilter=${payload.selectedFilter}`,
      payload.payload
    )
    toastr.success(`Order Updated successfully!!`)
    return data
  } catch (error) {
    throw error
  }
}
