import Axios from "axios"
import QueryString from "query-string"

export const updateZeroSearchConfig = async (language, formData) => {
  try {
    let response = await Axios.post(
      `/api/zeroSearch/updateConfig?language=${language}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    return response
  } catch (error) {
    return error
  }
}

export const deleteZeroSearchConfig = async id => {
  try {
    let response = await Axios.delete(`/api/zeroSearch?id=${id}`)
    return response
  } catch (error) {
    return error
  }
}

export const upsertPage = async (data, action) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/upsert?action=${action}`,
      data,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    return response
  } catch (error) {
    throw error
  }
}

export const orderPageEntities = async (data, pageId) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/entities/order?pageId=${pageId}`,
      data
    )
    return response
  } catch (error) {
    return error
  }
}

export const reorderCollectionEntities = async (data, collectionId) => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entities/target/reorder?collectionId=${collectionId}`,
      data
    )
    return response
  } catch (error) {
    return error
  }
}

export const reorderPageEntities = async (data, pageId) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/entities/target/reorder?pageId=${pageId}`,
      data
    )
    return response
  } catch (error) {
    return error
  }
}

export const fetchCollectionElements = async params => {
  try {
    let data = await Axios.get(
      `/api/discovery/collection/preview?${QueryString.stringify(params)}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const deleteCollectionEntity = async entityId => {
  try {
    let response = await Axios.delete(
      `/api/discovery/collection/entities/remove?entityId=${entityId}`
    )
    return response
  } catch (error) {
    return error
  }
}

export const removePageEntityByLocation = async data => {
  try {
    let response = await Axios.delete(
      `/api/discovery/page/entities/locations/remove`,
      { data }
    )
    return response
  } catch (error) {
    return error
  }
}

export const removeCollectionEntityByLocation = async data => {
  try {
    let response = await Axios.delete(
      `/api/discovery/collection/entities/locations/remove`,
      { data }
    )
    return response
  } catch (error) {
    return error
  }
}

export const upsertCollectionEntity = async payload => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entities/upsert`,
      payload
    )
    return response
  } catch (error) {
    return error
  }
}

export const updateCollectionEntity = async payload => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entities/update`,
      payload
    )
    return response
  } catch (error) {
    return error
  }
}

export const removePageEntity = async entityId => {
  try {
    let response = await Axios.delete(
      `/api/discovery/page/entities/remove?entityId=${entityId}`
    )
    return response
  } catch (error) {
    return error
  }
}

export const removePageTheme = async (pageUuid, themeType) => {
  try {
    let response = await Axios.delete(
      `/api/discovery/page/theme/remove?pageUuid=${pageUuid}&themeType=${themeType}`
    )
    return response
  } catch (error) {
    return error
  }
}

export const upsertPageTheme = async (data, action) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/theme/upsert?action=${action}`,
      data
    )
    return response
  } catch (error) {
    throw error
  }
}

export const orderCollectionEntities = async (data, collectionId) => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entities/order?collectionId=${collectionId}`,
      data
    )
    return response
  } catch (error) {
    return error
  }
}

export const upsertEntity = async (data, type, action) => {
  try {
    let url
    if (type === "banner") {
      url = `/api/discovery/entities/banner/upsert?action=${action}`
    } else {
      url = `/api/discovery/entities/entity/upsert?action=${action}&type=${type}`
    }
    let response = await Axios.post(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    return response
  } catch (error) {
    return error
  }
}

export const upsertCollection = async (data, action) => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/upsert?action=${action}`,
      data
    )
    return response
  } catch (error) {
    return error
  }
}

export const upsertPageEntity = async (data, action) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/entities/upsert?action=${action}`,
      data
    )
    return response
  } catch (error) {
    throw error
  }
}

export const upsertTargetPageEntities = async data => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/entities/target/upsert`,
      data
    )
    return response
  } catch (error) {
    throw error
  }
}

export const upsertTargetCollectionEntities = async data => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entities/target/upsert`,
      data
    )
    return response
  } catch (error) {
    throw error
  }
}
