import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Button,
  Table,
  Badge,
  Row,
  CardImg,
} from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { size, map } from "lodash"
import { Tooltip, Popconfirm } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import CustomScrollbars from "util/CustomScrollbars"
import ReactCardFlip from "react-card-flip"
import ReactTimeAgo from "react-time-ago"
import { getImage } from "../../components/SearchAndSelect/util"
import { removePageEntity } from "../../appRedux/slices/zeroSearch"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import "./pageStyle.css"

class PageEntityCard extends React.Component {
  state = { isFlipped: false }

  _getLocations = (data, type) => {
    let locations = []
    let languages = []
    data.target.forEach(entity => {
      if (
        type === "location" &&
        entity.lang_code === "default" &&
        entity.locations &&
        entity.locations.length > 0
      ) {
        entity.locations.forEach(location => {
          locations.push(location.location_id)
        })
      }
      if (type === "language") {
        languages.push(entity.lang_code)
      }
    })
    return type === "location" ? locations : languages
  }

  _removePageEntity = async entityId => {
    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    const response = await this.props.dispatch(removePageEntity(entityId))
  }

  render() {
    const { data, audioDetails = [] } = this.props
    const languageArray = data.target && this._getLocations(data, "language")
    const image = data.entityData
      ? data.entityData.thumbnail ||
        data.entityData.profile_pic ||
        data.entityData.album_art ||
        data.entityData.static_thumbnail ||
        data.banner_url ||
        data.icon_url ||
        data.zone_image ||
        data.thumbnail_url
      : undefined

    let title = data.entityData
      ? data.entityData.title ||
        data.entityData.hashtag ||
        data.entityData.name ||
        data.entityData.sticker_name ||
        data.entityData.asset_label
      : undefined
    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection={"horizontal"}
      >
        <Card
          className={`text-center shadow-lg`}
          style={{
            minHeight: "250px",
          }}
          onClick={() => this.props.onEditClick("update", data)}
        >
          <div className="bg-primary bg-soft" onClick={() => {}}>
            <Row>
              {image ? (
                <div id={"play" + data.page_uuid} style={{ height: "15rem" }}>
                  <CardImg
                    top
                    className="img-fluid"
                    src={image}
                    alt="Skote"
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    style={{
                      height: "15rem",
                      cursor: "pointer",
                      opacity: "0.8",
                    }}
                  />
                </div>
              ) : (
                <div
                  className="py-5 px-0"
                  style={{
                    height: "15rem",
                  }}
                >
                  <i
                    className={`fas ${getImage(
                      data
                    )} fa-7x py-4 text-secondary`}
                  />
                </div>
              )}
            </Row>
          </div>
          <CardBody style={{ padding: "1rem 0.25rem" }}>
            <Tooltip title={title} style={{ minHeight: "20px" }}>
              <div className="font-size-16 text-truncate font-weight-semibold">
                <i className={`mx-1`} /> {title}
              </div>
            </Tooltip>

            <div style={{ minHeight: "30px" }}>
              <Badge
                className={`${"badge-soft-danger"} ms-1 font-size-13 my-2 text-black`}
              >
                {data.collection_type}
              </Badge>
            </div>
            <div style={{ minHeight: "30px" }}>
              <Badge
                className={`${
                  data.collection_status === "PUBLISHED"
                    ? "badge-soft-success"
                    : data.collection_status === "DRAFT"
                    ? "badge-soft-info"
                    : "badge-soft-danger"
                } ms-1 font-size-13 mb-2 text-black`}
              >
                {data.collection_status}
              </Badge>
            </div>
            {languageArray && languageArray.length > 0 ? (
              languageArray.slice(0, 2).map((lang, i) => {
                return (
                  <Badge className="badge-soft-primary ms-1 my-1">
                    <div className="font-size-15">{lang}</div>
                  </Badge>
                )
              })
            ) : (
              <div>N/A</div>
            )}
            {languageArray && languageArray.length > 2 && (
              <Tooltip
                title={languageArray
                  .slice(2, languageArray.length)
                  .map(lang => {
                    return <div>{lang}</div>
                  })}
                placement="right"
              >
                <Badge className="badge-soft-primary ms-1">
                  <div
                    className="font-size-15 px-2"
                    key={"language_more" + languageArray.length}
                    style={{ cursor: "pointer" }}
                  >
                    +{languageArray.length - 2} more
                  </div>
                </Badge>
              </Tooltip>
            )}
            {/* <div style={{ minHeight: "30px" }}>
              <Badge className="badge-soft-dark ms-1 font-size-13 text-black">
                <ReactTimeAgo date={data.created_date} locale="en-US" />
              </Badge>
            </div> */}
            <div>
              {map(
                data.permissions,
                (tag, index) =>
                  index < 2 && (
                    <Link
                      to="#"
                      className="badge bg-primary font-size-11 m-1"
                      key={"_skill_" + data.id + index}
                    >
                      {tag}
                    </Link>
                  )
              )}
              {size(data.permissions) > 2 && (
                <Button
                  to="#"
                  className="badge bg-success font-size-11 m-1"
                  key={"_skill_" + data.id}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span
                    title={data.permissions
                      .slice(1, data.permissions, length)
                      .map(a => (
                        <span>{a}</span>
                      ))}
                  >
                    +{size(data.permissions) - 1} more
                  </span>
                </Button>
              )}
            </div>
          </CardBody>
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={data.collection_uuid}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Collection ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>

            <div className="flex-fill">
              <CopyToClipboard
                text={data.entity_uuid}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Entity ID">
                  <i className="bx bx-copy-alt" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>

            <div className="flex-fill">
              <Tooltip title="Edit Page Entity">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={() => this.props.onEditClick("update", data)}
                />
              </Tooltip>
            </div>

            <div className="flex-fill">
              <Popconfirm
                placement="topRight"
                title="Are you sure you want to delete this entity?"
                onConfirm={() => {
                  this._removePageEntity(data.entity_uuid)
                }}
              >
                <Tooltip title="Delete Page Entity">
                  <i className="bx bx bx-trash-alt text-danger" role="button" />
                </Tooltip>
              </Popconfirm>
            </div>
            {/* <div className="flex-fill">
              <Tooltip title="Manage Entities">
                <i
                  className="bx bx-play"
                  role="button"
                  onClick={() =>
                    this.setState({
                      isFlipped: true,
                    })
                  }
                />
              </Tooltip>
            </div> */}
          </div>
        </Card>
        <Card
          className="text-center shadow-lg"
          style={{
            height: "440px",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <CustomScrollbars>
            <CardBody className="px-0 py-0">
              <Row className="my-sm-2">
                <Col className="ms-2">
                  <div className="avatar-xs">
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-warning" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="bx bx-bar-chart" />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end me-2">
                  <div
                    className="avatar-xs"
                    role="button"
                    onClick={() => this.setState({ isFlipped: false })}
                  >
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-dark" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="fas fa-times-circle" />
                      </span>
                    </div>
                  </div>
                  <div></div>
                </Col>
              </Row>
              <Row>
                <div className="table-responsive">
                  <Table className="mb-0 table-striped">
                    <tbody>
                      <tr className="text-start">
                        <th scope="row">Deeplink:</th>
                        <td className="break-word">
                          <CopyToClipboard
                            text={data.deeplink}
                            onCopy={e => {
                              toastr.success("Copied sucessfully!")
                            }}
                          >
                            <Tooltip title="Copy Deeplink">
                              <i className="bx bx-copy" role="button" />
                            </Tooltip>
                          </CopyToClipboard>
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Theme Name:</th>
                        <td className="break-word">{data.theme_name || "-"}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </CardBody>
          </CustomScrollbars>
        </Card>
      </ReactCardFlip>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    refetch: _.get(store, "zeroSearch.refetchData"),
  }
}

export default connect(mapStateToProps)(PageEntityCard)
