import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"

const { Option } = Select

const HashtagDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Hashtag Name</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["name"]}
                  placeholder={"Hashtag Name"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "name")
                  }
                  allowClear={true}
                  name="name"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

HashtagDrawer.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(HashtagDrawer)
