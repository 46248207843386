import React from "react"
import { connect } from "react-redux"
import { Modal, Card, message, Tooltip, Select, Radio, Popconfirm } from "antd"
import {
  Button,
  Row,
  Col,
  CardImg,
  Card as ReactCard,
  CardBody,
  Badge,
} from "reactstrap"
import {
  getStickersTabList,
  upsertStickerAsset,
  updateStickerTabOrder,
  deleteStickerTabById,
  updateStickerTabVersion,
  resetState,
} from "../../appRedux/slices/stickers"
import UpsertStickersTabForm from "./upsertStickersTabForm"
import toastr from "toastr"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import { EditOutlined, DeleteOutlined, UpOutlined } from "@ant-design/icons"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import _ from "lodash"
import "./stickers.style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const { Meta } = Card

class ManageStickersTab extends ErrorBoundComponent {
  state = {
    filters: {},
    showUpsertModal: false,
    videoAsset: {},
    stickerAsset: {},
    tabsData: [],
    stickerTabsFromProps: [],
    showOrder: false,
    selectedFilter: "CAMERA",
    isComments: false,
    isCamera: true,
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getStickersTabList({
          selectedFilter: this.state.selectedFilter,
        })
      )
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.refetchData &&
      !this.props.common.error &&
      prevProps.refetchData !== this.props.refetchData
    ) {
      this._closeUpsertModal()
      this.props.dispatch(
        getStickersTabList({
          selectedFilter: this.state.selectedFilter,
        })
      )
    }
    if (prevProps.stickersTabList !== this.props.stickersTabList) {
      const initOrder = []
      for (let tab of this.props.stickersTabList) {
        initOrder.push(tab["tabId"])
      }
      this.setState({
        tabsData: initOrder,
        stickerTabsFromProps: this.props.stickersTabList,
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onSubmit = async formData => {
    const { stickerAsset } = this.state
    const { stickersTabList } = this.props
    const updateFormData = {}
    for (let tab of stickersTabList) {
      if (
        this.state.formAction === "create" &&
        formData.displayName === tab.displayName
      ) {
        message.error("Tab already exist with this Name")
        return
      }

      if (
        this.state.formAction === "update" &&
        this.state.stickerAsset.tabId !== tab.tabId &&
        formData.displayName === tab.displayName
      ) {
        message.error("Tab already exist with this Name")
        return
      }
    }
    if (formData.isActive !== stickerAsset.isActive) {
      updateFormData["isActive"] = formData.isActive
    }
    if (formData.displayName !== stickerAsset.displayName) {
      updateFormData["displayName"] = formData.displayName
    }
    const payload =
      this.state.formAction === "update" ? updateFormData : formData
    const data = {
      payload,
      query: {
        selectedFilter: this.state.selectedFilter,
        tabId: stickerAsset.tabId,
        action: this.state.formAction,
      },
    }
    if (!_.isEmpty(payload)) {
      this.props.dispatch(upsertStickerAsset(data))
    } else {
      message.error("Nothing to Update")
      this.setState({
        showUpsertModal: false,
      })
    }
  }

  _closeUpsertModal = () => {
    this.setState({
      showUpsertModal: false,
      formAction: "create",
      stickerAsset: {},
    })
  }

  _onCreateStickerAsset = () => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_ASSETS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.setState({
      showUpsertModal: true,
      formAction: "create",
      stickerAsset: {},
    })
  }

  _onEditTabAsset = (e, stickerAsset) => {
    e.preventDefault()
    e.stopPropagation()
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_ASSETS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.setState({
      showUpsertModal: true,
      formAction: "update",
      stickerAsset: stickerAsset,
    })
  }

  _onDeleteTabById = (e, tabId) => {
    e.preventDefault()
    e.stopPropagation()
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_ASSETS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.props.dispatch(
      deleteStickerTabById({
        selectedFilter: this.state.selectedFilter,
        tabId: tabId,
      })
    )
  }

  _onUpdateVersion = (e, tabId) => {
    e.preventDefault()
    e.stopPropagation()
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_ASSETS")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.props.dispatch(
      updateStickerTabVersion({
        selectedFilter: this.state.selectedFilter,
        tabId: tabId,
      })
    )
  }

  _closeUpsertModal = () => {
    this.setState({
      showUpsertModal: false,
      formAction: "create",
      stickerAsset: {},
    })
  }

  _getModalContent = () => {
    return (
      <UpsertStickersTabForm
        formAction={this.state.formAction}
        stickerAsset={this.state.stickerAsset}
        stickersFor={this.state.selectedFilter}
        onSubmit={this._onSubmit}
        onCancel={this._closeUpsertModal}
      />
    )
  }

  _modal = () => {
    return (
      <Card title="Create/Edit Category Asset">{this._getModalContent()}</Card>
    )
  }

  _handleFilterChange = event => {
    const radioVal = event.target.value
    this.props.dispatch(
      getStickersTabList({
        selectedFilter: radioVal,
      })
    )
    this.setState({
      selectedFilter: radioVal,
    })
    if (radioVal === "CAMERA") {
      this.setState({
        isCamera: true,
        isComments: false,
      })
    } else {
      this.setState({
        isComments: true,
        isCamera: false,
      })
    }
  }

  _actionButtons = () => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_MANAGE_STICKER_ASSETS")
    return (
      <div>
        <Row className="actions-buttons" gutter={24}>
          <Col className="select" span={4}>
            <Radio.Group
              onChange={this._handleFilterChange}
              defaultValue={"CAMERA"}
              value={this.state.selectedFilter}
            >
              <Radio value={"CAMERA"}>Camera</Radio>
              <Radio value={"COMMENT"}>Comments</Radio>
            </Radio.Group>
          </Col>
          <Col className="buttons" span={8}>
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                style={{
                  marginRight: "5px",
                }}
                onClick={() => {
                  if (!hasEditPermission) {
                    return toastr.error(NO_PERMISSION_MESSAGE)
                  }
                  this.setState({ showOrder: true })
                }}
              >
                <i className="fa fa-refresh" style={{ marginRight: "8px" }}></i>
                Reorder
              </Button>
              <Button color="primary" onClick={this._onCreateStickerAsset}>
                Create
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  _getColor = tabType => {
    switch (tabType) {
      case "REMOTE":
        return "mediumorchid"

      case "EXTERNAL":
        return "hotpink"

      case "LOCAL":
        return "dodgerblue"
      default:
        return ""
    }
  }

  _updateTabOrder = () => {
    const { stickerTabsFromProps } = this.state
    const payload = []
    const localTabs = stickerTabsFromProps.filter(
      tab => tab.tabType === "LOCAL"
    )
    for (let i = 0; i < localTabs.length; i++) {
      payload.push({
        tabId: localTabs[i]["tabId"],
        viewOrder: i,
      })
    }
    const remoteData = stickerTabsFromProps.filter(
      tab => tab.tabType !== "LOCAL"
    )
    for (let i = localTabs.length; i < remoteData.length; i++) {
      payload.push({
        tabId: remoteData[i - localTabs.length]["tabId"],
        viewOrder: i,
      })
    }
    this.props.dispatch(
      updateStickerTabOrder({
        payload,
        selectedFilter: this.state.selectedFilter,
      })
    )
    this.setState({ showOrder: false })
  }

  _getRedirectUrl = tab => {
    let redirectUrl = ""
    if (tab.tabType !== "LOCAL") {
      redirectUrl = this.props.history.push(
        `/stickers/mapping?typeId=${tab.tabId}&selectedFilter=${this.state.selectedFilter}`
      )
    }
    return redirectUrl
  }

  _getCover = tabOperation => {
    switch (tabOperation) {
      case "LOCAL":
        return "fa fa-home"

      case "REMOTE":
        return "fa fa-gamepad"

      case "EXTERNAL":
        return "fa fa-camera-retro"

      default:
        return "fa fa-binoculars"
    }
  }

  _tabsList = () => {
    return (
      <div className="trending-card-container">
        {this.props.stickersTabList.map(tab => (
          <ReactCard className="overflow-hidden text-center shadow-lg">
            <CardBody
              className="px-0 py-0"
              style={{ cursor: "pointer" }}
              onClick={() => this._getRedirectUrl(tab)}
            >
              <div style={{ margin: "10px" }}>
                <i
                  className={this._getCover(tab.tabType)}
                  style={{
                    fontSize: "15vh",
                    color: this._getColor(tab.tabType),
                  }}
                ></i>
              </div>
              {tab.displayName && (
                <Badge
                  pill
                  className="badge-soft-primary m-1"
                  style={{ fontSize: "14px" }}
                >
                  <div className="p-1 font-size-14">{tab.displayName}</div>
                </Badge>
              )}
              {tab.tabType && (
                <div className="my-1 mb-1">
                  <Badge
                    pill
                    className="badge-soft-success m-1"
                    style={{ fontSize: "14px" }}
                  >
                    <div className="p-1 font-size-14">{tab.tabType}</div>
                  </Badge>
                </div>
              )}
            </CardBody>
            <div className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center">
              <div className="flex-fill">
                <Tooltip title="Edit Sticker Tab">
                  <i
                    className="bx bx-edit-alt"
                    role="button"
                    onClick={
                      tab.tabType !== "LOCAL"
                        ? e => this._onEditTabAsset(e, tab)
                        : ""
                    }
                  />
                </Tooltip>
              </div>
              <div className="flex-fill">
                <Popconfirm
                  placement="topRight"
                  title="Are you sure you want to delete this Sticker Tab?"
                  onConfirm={
                    tab.tabType !== "LOCAL"
                      ? e => this._onDeleteTabById(e, tab.tabId)
                      : ""
                  }
                >
                  <i className="bx bx bx-trash-alt" role="button" />
                </Popconfirm>
              </div>
              <div className="flex-fill">
                <Tooltip title="Update Version">
                  <i
                    className="bx bx-up-arrow"
                    role="button"
                    onClick={e => this._onUpdateVersion(e, tab.tabId)}
                  />
                </Tooltip>
              </div>
            </div>
          </ReactCard>
        ))}
      </div>
    )
  }

  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  _onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const stickerTabsFromProps = this._reorder(
      this.state.stickerTabsFromProps,
      result.source.index,
      result.destination.index
    )

    this.setState({
      stickerTabsFromProps,
    })
  }

  _closeTabOrder = () => {
    this.setState({ showOrder: false })
  }

  _createTabOrder = () => {
    return (
      <Modal
        centered
        onOk={this._updateTabOrder}
        visible={this.state.showOrder}
        onCancel={this._closeTabOrder}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this._updateTabOrder}
            style={{ textAlign: "left" }}
          >
            UPDATE
          </Button>,
        ]}
        title="Order Category Tabs"
        destroyOnClose
        bodyStyle={{ overflowY: "auto", overflowX: "hidden", height: "60vh" }}
      >
        <div style={{ marginBottom: 30 }} bordered={true}>
          <DragDropContext onDragEnd={this._onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.stickerTabsFromProps.map((tab, index) => (
                    <Draggable
                      key={`${tab["tabId"]}`}
                      draggableId={`${tab["tabId"]}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {tab.tabType !== "LOCAL" && (
                            <Row gutter={16}>
                              <Col sm={24} md={8} lg={6} xl={4}>
                                <Card
                                  className="cardStyle"
                                  cover={
                                    <FontAwesomeIcon
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        margin: "auto",
                                        color: this._getColor(tab.tabType),
                                      }}
                                      icon={this._getCover(
                                        tab.asset_type
                                          ? tab.asset_type
                                          : tab.tabType
                                      )}
                                    />
                                  }
                                  bordered={true}
                                  style={{
                                    padding: "12px",
                                    display: "flex",
                                    width: "460px",
                                  }}
                                >
                                  <Meta
                                    title={tab.displayName}
                                    description={tab.tabType}
                                  />
                                </Card>
                              </Col>
                            </Row>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showUpsertModal && this._actionButtons()}
        {this.state.showUpsertModal && this._modal()}
        {!this.state.showUpsertModal && this._tabsList()}
        {this.state.showOrder && this._createTabOrder()}
      </Auxiliary>
    )
  }
}
function mapStateToProps(store) {
  return {
    stickersTabList: _.get(store, "stickers.stickersTabList"),
    refetchData: _.get(store, "stickers.refetchData"),
    loading: _.get(store, "stickers.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageStickersTab)
