import PropTypes from "prop-types"
import React, { useState, createRef, useEffect } from "react"
import { Card, Row, CardBody, Col, UncontrolledTooltip } from "reactstrap"
import { Button } from "@mui/material"
import { formatNumber } from "../../util/common"
import { withRouter } from "react-router"
import { CURATED_TYPE_MAPPING } from "constants/uiConstants"
import { FaShare } from "react-icons/fa"
import { AiTwotoneHeart, AiFillEye } from "react-icons/ai"
import { Chip, Stack } from "@mui/material"
import "./style.css"

const _renderThumbnail = (content, props) => {
  switch (content.format) {
    case "IMAGE":
      return (
        <img
          src={content.download_url}
          width="100%"
          height={props.height ? props.height : "455px"}
          alt=""
        />
      )
    case "GIF":
      return (
        <img src={content.download_url} width="100%" height="380px" alt="" />
      )
    default:
      return (
        <img
          src={content.icon_url ? content.icon_url : ""}
          width="100%"
          height={props.height ? props.height : "455px"}
          alt=""
          style={{ filter: "blur(.5px)", opacity: "0.8", cursor: "pointer" }}
        />
      )
  }
}

const VideoCard = props => {
  const { type } = props
  const [content, setContent] = useState(props.content)
  const [showVideo, setVideo] = useState(false)
  const [controls, setControls] = useState(type === "curation" ? false : true)
  const [muted, setMuted] = useState(true)
  const [playbackRate, setPlayBackRate] = useState("1.0")
  const videoRef = createRef()
  let actionMeta = null
  if (content?.taxonomy_task_info?.task_info_v1) {
    for (
      let i = content?.taxonomy_task_info?.task_info_v1.length - 1;
      i >= 0;
      i--
    ) {
      if (
        content?.taxonomy_task_info?.task_info_v1[i]?.action?.includes(
          "interest"
        )
      ) {
        actionMeta = content?.taxonomy_task_info?.task_info_v1[i]
        break
      }
    }
  }

  useEffect(() => {
    if (props.autoPlay) {
      setVideo(true)
      if (videoRef.current) {
        videoRef.current.play()
        setMuted(false)
      }
    }
  }, [videoRef])

  const servableContent =
    content?.is_active &&
    (content?.moderation_level >= 2 ||
      (content?.moderation_level === 1 &&
        content?.auto_moderation_label === "sfw"))
      ? true
      : false
  const _setPlayBack = () => {
    videoRef.current.playbackRate = playbackRate
    videoRef.current.volume = 0.4
  }

  const _getModerationImage = content => {
    if (content.reviewed) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/review.jpg"
    } else if (content.qc_done) {
      return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/qcDone.jpg"
    } else if (content.moderation_level) {
      switch (content.moderation_level) {
        case 2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-approved.jpg"

        case 3:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-approved.jpg"

        case -1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/human-disabled.jpg"

        case 0:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/auto-disabled.jpg"

        case -2:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/machine-disabled.jpg"

        case 1:
          return "https://prod-ugc-contents.s3.ap-south-1.amazonaws.com/josh-cms/moderation-images/unmoderated.jpg"

        default:
          return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
      }
    } else {
      return "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
    }
  }

  return (
    <div>
      <Card
        className={`text-center shadow-lg ${
          servableContent ? "" : "border-4 border-danger"
        }`}
        style={{ borderRadius: "10px" }}
      >
        <Row>
          {!showVideo && (
            <Col className="align-self-end" style={{ width: "100%" }}>
              {_renderThumbnail(content, props)}
              {(props.details || props.review) && (
                <div
                  style={{ position: "absolute", top: "10px", left: "20px" }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src={_getModerationImage(content)}
                      className="rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <div style={{ fontSize: "14px", marginLeft: "10px" }}>
                      <b>{content?.user_profile?.name}</b>
                      <div style={{ textAlign: "initial" }}>
                        {CURATED_TYPE_MAPPING[content?.user_profile?.user_type]}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {
                <div
                  style={{ position: "absolute", bottom: "32px", width: "92%" }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    {" "}
                    <div style={{}}>
                      {content.pixel_size && (
                        <Chip
                          label={`${content?.pixel_size.split("x")[0]}p`}
                          style={{ backgroundColor: "#404bb2" }}
                          color="primary"
                        />
                      )}
                    </div>
                    <div style={{}}>
                      {content.video_duration && (
                        <Chip
                          label={new Date(content?.video_duration * 1000)
                            .toISOString()
                            .slice(14, 19)}
                          style={{ backgroundColor: "#404bb2" }}
                          color="primary"
                        />
                      )}
                    </div>
                    <div>
                      {content.parity && (
                        <Chip
                          label={`${content?.parity.toFixed(5)}`}
                          style={{ backgroundColor: "#404bb2" }}
                          color="primary"
                        />
                      )}
                    </div>
                  </div>
                </div>
              }

              <div
                role="button"
                id={"play" + content.content_uuid}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setVideo(!showVideo)
                }}
              >
                {props.playVideo && (
                  <>
                    <i
                      className="bx bx-play"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "70px",
                        color: "white",
                      }}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={"play" + content.content_uuid}
                    >
                      Play Video
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </Col>
          )}
          {showVideo && (
            <Col>
              <video
                src={content.download_url}
                ref={videoRef}
                style={{ objectFit: "contain" }}
                height={props.height ? props.height : "455px"}
                width="100%"
                onCanPlay={() => _setPlayBack()}
                controls={controls}
                onEnded={() => {
                  setControls(true)
                  if (type === "curation") {
                    props.handleDisable()
                  }
                }}
                controlsList={
                  props?.currentUser?.jotUserType > 3 ? "nodownload" : ""
                }
                muted={muted}
                onContextMenu={e => {
                  e.preventDefault()
                  return false
                }}
                autoPlay={true}
              />
            </Col>
          )}
        </Row>
        {props.details && (
          <CardBody style={{ padding: ".5rem" }}>
            <div className="d-flex justify-content-around align-items-center pt-2">
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {}}
                type={""}
              >
                <AiFillEye
                  style={{ padding: "2px", fontSize: "19px", color: "grey" }}
                />
                <span>
                  {" "}
                  {props.metric == "absolute"
                    ? formatNumber(content.view_count) || 0
                    : content.fk_views
                    ? `${(
                        (content.view_count * 100) /
                        content.fk_views
                      ).toFixed(2)}%`
                    : "-"}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {}}
                type={""}
              >
                <AiTwotoneHeart
                  style={{ padding: "2px", fontSize: "19px", color: "grey" }}
                />
                <span>
                  {" "}
                  {props.metric == "absolute"
                    ? formatNumber(content.like_count) || 0
                    : content.fk_likes
                    ? `${(
                        (content.like_count * 100) /
                        content.fk_likes
                      ).toFixed(2)}%`
                    : "-"}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {}}
                type={""}
              >
                <FaShare
                  style={{ padding: "2px", fontSize: "19px", color: "grey" }}
                />
                <span>
                  {props.metric == "absolute"
                    ? formatNumber(content.share_count) || 0
                    : content.fk_shares
                    ? `${(
                        (content.share_count * 100) /
                        content.fk_shares
                      ).toFixed(2)}%`
                    : "-"}
                </span>
              </div>
            </div>
            {actionMeta && (
              <div className="mt-1">
                <Chip
                  size="small"
                  color="primary"
                  variant="filled"
                  className={`m-1 p-2 font-size-12`}
                  label={actionMeta.action.split("_")[1]}
                />
                <Chip
                  size="small"
                  color="secondary"
                  variant="filled"
                  className={`ms-1 p-2 font-size-12`}
                  label={actionMeta.completed_by}
                />
                {actionMeta.action.split("_")[1] === "REJECTED" && (
                  <Chip
                    size="small"
                    color="warning"
                    variant="filled"
                    className={`m-1 p-2 font-size-12`}
                    label={content.interest_reject_reason}
                  />
                )}
              </div>
            )}
          </CardBody>
        )}
      </Card>
    </div>
  )
}

VideoCard.propTypes = {
  content: PropTypes.object,
}

export default withRouter(VideoCard)
