import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const requestPageList = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"]
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/discovery/page/list?offset=${offset}&limit=${limit}`,
      { filters }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestPageEntities = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  const sortOrder = payload?.filters["sortOrder"] || ""
  const sortKey = payload?.filters["sortKey"] || ""
  try {
    let data = await Axios.get(
      `/api/discovery/page/entities/list?pageEntityId=${payload.pageEntityId}&offset=${offset}&limit=${limit}&sortOrder=${sortOrder}&sortKey=${sortKey}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestEntities = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/discovery/entities/list?type=${payload.filters.type}&offset=${offset}&limit=${limit}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestPageEntitiesByLanLoc = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/discovery/page/entities/language-locations/list?pageEntityId=${payload.pageEntityId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestCollectionEntitiesByLanLoc = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/discovery/collection/entities/language-locations/list?collectionId=${payload.collectionId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestCollectionEntitiesGroups = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/discovery/collection/entities/groups/list?collectionType=${payload.collectionType}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestCollectionEntities = async ({ payload }) => {
  const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
  const limit = payload["pageSize"]
  try {
    let data = await Axios.get(
      `/api/discovery/collection/entities/list?collectionType=${payload.collectionType}&collectionId=${payload.collectionId}&limit=${limit}&offset=${offset}&elementId=${payload.elementId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestReorderPageEntities = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/entities/target/reorder?pageId=${payload.pageId}`,
      payload.data
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestDefaultReorderPageEntities = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/entities/default/reorder`,
      payload.payload.entities
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestDefaultReorderCollectionEntities = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entity/default/reorder`,
      payload.payload.entities
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestReorderCollectionEntities = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entities/target/reorder?collectionId=${payload.collectionId}`,
      payload.data
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertTargetPageEntities = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/entities/target/upsert`,
      payload
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertTargetCollectionEntities = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entities/target/upsert`,
      payload
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertPageEntities = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/entities/upsert?action=${payload.action}`,
      payload.data
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertCollection = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/upsert?action=${payload.action}`,
      payload.data
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertPageTheme = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/theme/upsert?action=${payload.action}`,
      payload.data
    )

    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertEntity = async ({ payload }) => {
  try {
    let url
    if (payload.type === "banner") {
      url = `/api/discovery/entities/banner/upsert?action=${payload.action}`
    } else if (payload.type === "gift") {
      url = `/api/discovery/entities/gift/upsert?action=${payload.action}`
    } else {
      url = `/api/discovery/entities/entity/upsert?action=${payload.action}&type=${payload.type}`
    }
    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertWebGame = async ({ payload }) => {
  try {
    let url
    if (!_.isEmpty(payload.gameId) && payload.action === "update") {
      url = `/api/webgame/upsert?action=${payload.action}&gameId=${payload.gameId}`
    } else url = `/api/webgame/upsert?action=${payload.action}`

    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertPage = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/page/upsert?action=${payload.action}`,
      payload.data,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpdateCollectionEntity = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entities/update`,
      payload
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertCollectionEntity = async ({ payload }) => {
  try {
    let response = await Axios.post(
      `/api/discovery/collection/entities/upsert`,
      payload
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestRemovePageEntityByLocation = async ({ payload }) => {
  try {
    let response = await Axios.delete(
      `/api/discovery/page/entities/locations/remove`,
      { data: payload.data }
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestRemovePageTheme = async ({ payload }) => {
  try {
    let response = await Axios.delete(
      `/api/discovery/page/theme/remove?pageUuid=${payload.pageUuid}&themeType=${payload.themeType}`
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestRemoveCollectionEntityByLocation = async ({ payload }) => {
  try {
    let response = await Axios.delete(
      `/api/discovery/collection/entities/locations/remove`,
      { data: payload.data }
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestDeleteCollectionEntity = async ({ payload }) => {
  try {
    let response = await Axios.delete(
      `/api/discovery/collection/entities/remove?entityId=${payload}`
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestRemovePageEntity = async ({ payload }) => {
  try {
    let response = await Axios.delete(
      `/api/discovery/page/entities/remove?entityId=${payload}`
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestRemoveGame = async ({ payload }) => {
  try {
    let response = await Axios.delete(`/api/webgame/remove?gameId=${payload}`)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
