import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"
import images from "assets/images"
import { size, map } from "lodash"
import { Tooltip } from "antd"

const CardContact = props => {
  const [isOpen, setIsOpen] = useState(false)

  const { user, color } = props
  let permissions = []
  if (user.permissions && user.permissions.length > 0) {
    permissions = [...permissions, ...user.permissions]
  }
  if (user.jotPermissions && user.jotPermissions.length > 0) {
    permissions = [...permissions, ...user.jotPermissions]
  }
  let defaultPermissions = new Set(permissions)
  if (user.jotRevokedPermissions && user.jotRevokedPermissions.length > 0) {
    for (let elem of user.jotRevokedPermissions) {
      if (defaultPermissions.has(elem)) {
        defaultPermissions.delete(elem)
      }
    }
  }
  permissions = [...defaultPermissions]
  return (
    <React.Fragment>
      <Card
        className="text-center shadow-lg"
        style={{ height: "250px", borderRadius: "10px" }}
      >
        <CardBody>
          {!user.img ? (
            <div className="avatar-sm mx-auto mb-4">
              <span
                className={
                  "avatar-title rounded-circle bg-soft bg-" +
                  color +
                  " text-" +
                  color +
                  " font-size-18"
                }
              >
                {user.name.charAt(0).toUpperCase()}
              </span>
            </div>
          ) : (
            <div className="mb-4">
              <img
                className="rounded-circle avatar-sm"
                src={images[user.img]}
                alt=""
              />
            </div>
          )}

          <h5 className="font-size-15 mb-1">
            <Link to="#" className="text-dark">
              {user.name}
            </Link>
          </h5>
          <h5
            className="font-size-15 mb-1 text-truncate"
            style={{ height: "20px" }}
          >
            <Link to="#" className="text-dark">
              {user.email}
            </Link>
          </h5>
          <p className="text-muted">{user.designation}</p>

          <div style={{ height: "25px" }}>
            {map(
              permissions,
              (tag, index) =>
                index < 1 && (
                  <Badge className="badge-soft-info ms-1">
                    <div className="font-size-12 p-1">{tag}</div>
                  </Badge>
                )
            )}
            {size(permissions) > 1 && (
              <Badge className="badge-soft-success ms-1">
                <Tooltip
                  placement="right"
                  title={permissions.slice(1, permissions.length).map(a => (
                    <div>{a}</div>
                  ))}
                >
                  <div className="font-size-12 p-1">
                    +{size(permissions) - 1} more
                  </div>
                </Tooltip>
              </Badge>
            )}
          </div>
        </CardBody>
        <CardFooter className="bg-transparent border-top">
          <div className="contact-links d-flex font-size-20">
            <div className="flex-fill">
              <div
                role="button"
                id={"message" + user.id}
                onClick={() => props.editClick(user, "upgrade")}
              >
                <i className="bx bx-up-arrow" />
                <UncontrolledTooltip
                  placement="top"
                  target={"message" + user.id}
                >
                  Upgrade
                </UncontrolledTooltip>
              </div>
            </div>
            <div className="flex-fill">
              <div
                id={"project" + user.id}
                role="button"
                onClick={() => props.editClick(user, "update")}
              >
                <i className="bx bx-pencil" />
                <UncontrolledTooltip
                  placement="top"
                  target={"project" + user.id}
                >
                  Update
                </UncontrolledTooltip>
              </div>
            </div>
            <div className="flex-fill">
              <div
                role="button"
                id={"profile" + user.id}
                onClick={() => props.editClick(user, "profile")}
              >
                <i className="bx bx-user-circle" />
                <UncontrolledTooltip
                  placement="top"
                  target={"profile" + user.id}
                >
                  Profile
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

CardContact.propTypes = {
  user: PropTypes.object,
}

export default CardContact
