import React, { createRef } from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { withRouter } from "react-router"
import _Get from "lodash/get"
import _CloneDeep from "lodash/cloneDeep"
import _Words from "lodash/words"
import _IsEmpty from "lodash/isEmpty"
import { Input, message, Modal, Pagination } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Row, Col, Card, CardTitle, CardBody, Button } from "reactstrap"
import { getLocationData } from "appRedux/slices/location"
import { getContentByFilter } from "appRedux/slices/content"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import "./content.styles.css"
import UploadBulkContentForm from "./uploadBulkContentForm"
import CreateContentForm from "./createContentForm"
import ContentDetails from "components/ContentDetails/contentDetails"
import AgencyLimitCreateForm from "./agencyLimitCreateForm"
import { deleteLimit, getLimits, resetState } from "appRedux/slices/limit"
import AgencylimitList from "./agencylimitList"
import Chips from "components/CommonFilter/chips"
import { agencyFilters, NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import NoDataFound from "components/NoDataFound/noDataFound"

class AgencyLimit extends ErrorBoundComponent {
  constructor(props) {
    super(props)

    this.state = {
      filters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 0,
        agency:
          this.props.queryParams && this.props.queryParams["agency"]
            ? this.props.queryParams["agency"]
            : "",
        type:
          this.props.queryParams && this.props.queryParams["type"]
            ? this.props.queryParams["type"]
            : "",
        language:
          this.props.queryParams && this.props.queryParams["language"]
            ? this.props.queryParams["language"]
            : "",
      },
      showModal: false,
      action: null,
    }
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getLimits({ filters: _.deepClean(this.state.filters) })
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  componentDidUpdate(prevProps) {
    let filters = _.cloneDeep(this.state.filters)
    if (
      !this.props.common.error &&
      this.props.queryParams &&
      JSON.stringify(prevProps.queryParams) !==
        JSON.stringify(this.props.queryParams)
    ) {
      this.props.dispatch(
        getLimits({ filters: _.deepClean(this.state.filters) })
      )
    }
  }

  _searchAgencyLimit = value => {
    let { filters } = this.state
    this.props.history.push(
      `/agency/limit?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
    this.props.dispatch(getLimits({ filters: _.deepClean(this.state.filters) }))
  }

  _actionButtons = () => {
    return (
      <div
        className="d-flex justify-content-end text-sm-end"
        style={{ paddingBottom: "15px" }}
      >
        <Button
          color="primary"
          className="font-16 btn-block btn btn-primary"
          onClick={() =>
            this.setState(
              {
                showForm: false,
              },
              () =>
                this.setState({
                  formAction: "create",
                  showForm: true,
                })
            )
          }
        >
          <i className="bx bx-plus me-1" />
          Create
        </Button>
      </div>
    )
  }

  editAgencyLimit = agency => {
    // console.log(agency)
    this.setState(
      {
        agencyData: agency,
        showForm: false,
      },
      () =>
        this.setState({
          formAction: "update",
          showForm: true,
        })
    )
  }

  deleteAgency = agency => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_AGENCY_LIMIT")
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }

    this.props.dispatch(deleteLimit(agency))
    this.props.dispatch(getLimits())
  }
  _closeFilters = () => {
    this._searchAgencyLimit()
    this.setState({ showFilters: false, showForm: false })
  }
  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: agencyFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => this._closeFilters()
    )
  }
  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      // newFilters["searchText"] = undefined
    }
    this.setState({ filters: newFilters })
  }
  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }
  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/agency/limit?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
    })
    this.props.dispatch(getLimits(_.deepClean(newFilters)))
  }

  _getAgencyUpsertForm = () => {
    const hasEditPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_AGENCY_LIMIT")

    return (
      <Card>
        <CardTitle className="p-3 pb-0">{"Create Limit"}</CardTitle>
        <CardBody>
          {this.state.formAction === "create" ? (
            <AgencyLimitCreateForm
              onCancel={() =>
                this.setState({
                  showForm: false,
                  formAction: null,
                  agencyData: null,
                })
              }
              hasEditPermission={hasEditPermission}
            />
          ) : this.state.formAction === "update" ? (
            <AgencyLimitCreateForm
              formAction="update"
              agencyData={this.state.agencyData}
              onCancel={() =>
                this.setState({ showForm: false, formAction: null })
              }
              hasEditPermission={hasEditPermission}
            />
          ) : (
            <span></span>
          )}
        </CardBody>
      </Card>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_Get(this.props, "common.error")}
      >
        <Chips
          showFilters={this.state.showFilters}
          filters={this.state.filters}
          search={this._closeFilters}
          removeFilter={this._removeFilter}
          handleFilterChange={this._handleFilterChange}
          type="agencylimit"
          toggleFilters={this._toggleFilters}
        />
        {/* {this._filters()} */}
        {!this.state.showForm && this._actionButtons()}

        <Row>{this.state.showForm && this._getAgencyUpsertForm()}</Row>
        {!this.state.showForm &&
        this.props.limitlist &&
        this.props.limitlist.length > 0 ? (
          <>
            <AgencylimitList
              userList={this.props.limitlist}
              handleApproveReject={this.editAgencyLimit}
              deleteAgency={this.deleteAgency}
              total={this.state.total}
              currentUser={this.props.currentUser}
              currentPage={this.state.filters["currentPage"]}
              pageSize={this.state.filters["pageSize"]}
              //  formAction={this.state.formAction}
            />
            <div className="d-flex justify-content-end">
              <Pagination
                className="m-2"
                onChange={this._onPageChange}
                defaultCurrent={this.state.filters.currentPage}
                total={
                  this.props.agencyTotal < 10000 ? this.props.agencyTotal : 10
                }
                pageSize={this.state.filters.pageSize}
                showSizeChanger={false}
              />
            </div>
          </>
        ) : (
          !this.state.showForm && <NoDataFound message="No Data To Display" />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _Get(store, "content.loading"),
    common: _Get(store, "common"),
    content:
      _.get(store, "content.content").length == 1
        ? _.get(store, "content.content")[0]
        : null,
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    limitlist: _.get(store, "limit.limitList"),
    agencyTotal: _.get(store, "limit.total"),
  }
}

export default connect(mapStateToProps)(AgencyLimit)
