import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import { fetchError } from "appRedux/slices/common"
import {
  getZoneDetails,
  getZoneDetailsSuccess,
  getZones,
  getZonesSuccess,
  upsertZone,
  upsertZoneSuccess,
  deleteBanner,
  deleteBannerSuccess,
  getAllZones,
  getAllZonesSuccess,
  addPreviewImage,
  addPreviewImageSuccess,
  getTasksByFilter,
  getTasksByFilterSuccess,
  getAppZoneVideos,
  getAppZoneVideosSuccess,
  getAppZoneImages,
  getAppZoneImagesSuccess,
  getAppZoneSocials,
  getAppZoneSocialsSuccess,
} from "appRedux/slices/zone"
import {
  requestAllZones,
  requestGetZones,
  requestUpsertZone,
  requestGetZoneDetails,
  requestDeleteBanner,
  requestAddPreviewImage,
  requestTasksByFilters,
  requestFeedEntityByFilters,
} from "../requests/zone"

function* handleGetZones(params) {
  try {
    const data = yield call(requestGetZones, params)
    yield put(getZonesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAllZones(params) {
  try {
    const data = yield call(requestAllZones, params)
    yield put(getAllZonesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetZoneDetails(params) {
  try {
    const data = yield call(requestGetZoneDetails, params)
    yield put(getZoneDetailsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertZone(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertZone, params)
    yield put(upsertZoneSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteBanner(params) {
  try {
    const data = yield call(requestDeleteBanner, params)
    yield put(deleteBannerSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAddPreviewImage(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestAddPreviewImage, params)
    yield put(addPreviewImageSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}
function* handleGetTasksByFilters(params) {
  try {
    const data = yield call(requestTasksByFilters, params)
    yield put(getTasksByFilterSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAppZoneVideos(params) {
  try {
    const data = yield call(requestFeedEntityByFilters, params)
    yield put(getAppZoneVideosSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAppZoneImages(params) {
  try {
    const data = yield call(requestFeedEntityByFilters, params)
    yield put(getAppZoneImagesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAppZoneSocials(params) {
  try {
    const data = yield call(requestFeedEntityByFilters, params)
    yield put(getAppZoneSocialsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getZones.type, handleGetZones)
  yield takeLatest(upsertZone.type, handleUpsertZone)
  yield takeLatest(getZoneDetails.type, handleGetZoneDetails)
  yield takeLatest(deleteBanner.type, handleDeleteBanner)
  yield takeLatest(getAllZones.type, handleGetAllZones)
  yield takeLatest(addPreviewImage.type, handleAddPreviewImage)
  yield takeLatest(getTasksByFilter.type, handleGetTasksByFilters)
  yield takeLatest(getAppZoneVideos.type, handleGetAppZoneVideos)
  yield takeLatest(getAppZoneImages.type, handleGetAppZoneImages)
  yield takeLatest(getAppZoneSocials.type, handleGetAppZoneSocials)
}
