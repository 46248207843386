import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Popconfirm } from "antd"
import { Button } from "reactstrap"
import _ from "lodash"
import React from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { Select, Tooltip, Tag } from "antd"
import { Row, Col } from "reactstrap"
import { getImage } from "../SearchAndSelect/util"

class ReorderableList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
    }

    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidMount() {
    const items = [...this.props.data]
      .sort((a, b) => a.view_order - b.view_order)
      .map(entity => entity.entityId)
    this.setState({ items })
  }

  componentDidUpdate(prevProp, prevstate) {
    if (prevProp.selectedKey !== this.props.selectedKey) {
      const items = [...this.props.data]
        .sort((a, b) => a.view_order - b.view_order)
        .map(entity => entity.entityId)
      this.setState({ items })
    }
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const items = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    )
    this.props.onOrder(items)

    this.setState({
      items,
    })
  }

  _getCardData = item => {
    let pageEntity = {}
    this.props.data.forEach(entity => {
      if (entity.entityId === item) {
        pageEntity = entity
      }
    })
    const { entityData } = pageEntity
    const title =
      entityData &&
      (entityData.heading_title ||
        entityData.title ||
        entityData.name ||
        entityData.hashtag ||
        entityData.sticker_name ||
        entityData.content_title ||
        entityData.asset_label)
    return (
      <Row>
        <Col sm="12" md="2">
          {pageEntity.entityData &&
          (pageEntity.entityData.album_art ||
            pageEntity.entityData.static_thumbnail ||
            pageEntity.entityData.sticker_thumbnail_url ||
            (pageEntity.entityData.thumbnail &&
              pageEntity.entityData.thumbnail.replace("_r1_", "_v1_")) ||
            pageEntity.entityData.profile_pic ||
            pageEntity.entityData.thumbnail_url ||
            pageEntity.entityData.zone_image ||
            pageEntity.banner_url) ? (
            <img
              src={
                pageEntity.entityData.thumbnail ||
                pageEntity.entityData.profile_pic ||
                pageEntity.entityData.album_art ||
                pageEntity.entityData.static_thumbnail ||
                pageEntity.entityData.sticker_thumbnail_url ||
                pageEntity.banner_url ||
                pageEntity.icon_url ||
                pageEntity.entityData.thumbnail_url ||
                pageEntity.entityData.zone_image
              }
              alt=""
              style={{ height: "50px", width: "50px" }}
            />
          ) : (
            <i
              style={{
                width: "50px",
                height: "50px",
                color: "darkgrey",
                padding: "10px",
                margin: "auto",
              }}
              className={`${getImage(pageEntity)} fa-2x`}
            />
          )}
        </Col>
        <Col sm="12" md="6">
          <div>{title || pageEntity.entityId}</div>
          <div>{pageEntity.collection_type}</div>
          <div>{pageEntity.layout_type}</div>
          {pageEntity.status && (
            <Tooltip title="Status">
              <Tag color="#2db7f5" className="tagStyle">
                {pageEntity.status}
              </Tag>
            </Tooltip>
          )}
        </Col>
        <Col sm="12" md="4">
          <div className="d-flex justify-content-end">
            {this.props.action === "edit" &&
              this.props.selectedKey !== "default" &&
              this.props.cardType === "language" && (
                <>
                  <Button
                    color="primary"
                    onClick={() => this.props.onEdit(pageEntity, "location")}
                    className="mx-2"
                  >
                    <i className="bx bx-pencil" />
                    &nbsp;Location
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.props.onEdit(pageEntity, "status")}
                  >
                    <i className="bx bx-pencil" />
                    &nbsp;Status
                  </Button>
                </>
              )}
            {this.props.cardType === "location" && (
              <Popconfirm
                placement="topRight"
                title="Are you sure you want to delete this entity?"
                onConfirm={() => this.props.onDelete(pageEntity)}
              >
                <Button color="danger">
                  <i className="fas fa-trash" />
                </Button>
              </Popconfirm>
            )}
          </div>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {this.state.items.map((item, index) => {
                return (
                  <Draggable
                    key={item}
                    draggableId={`${item}-id`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        style={provided.draggableProps.style}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          style={{
                            border: "1px solid #E5E5E5",
                            padding: "10px",
                            marginBottom: "10px",
                            cursor: "pointer",
                          }}
                        >
                          {this._getCardData(item)}
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

export default ReorderableList
