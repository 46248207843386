import Axios from "axios"

export const getMediaData = async (greetings_category, media_category) => {
  try {
    const response = await Axios.get(
      `/api/chatbot/media/get?greetings_category=${greetings_category}&media_category=${media_category}`
    )
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const updateMediaData = async payload => {
  try {
    const response = await Axios.post("/api/chatbot/media/update", payload)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const updateCategoryData = async payload => {
  try {
    const response = await Axios.post("/api/chatbot/category/update", payload)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const getCategoryList = async payload => {
  try {
    const response = await Axios.get("/api/chatbot/category/list", payload)
    return response.data || {}
  } catch (error) {
    return error
  }
}
export const deleteCategoryData = async payload => {
  try {
    const response = await Axios.post("/api/chatbot/category/delete", payload)
    return response.data || {}
  } catch (error) {
    return error
  }
}
