import Axios from "axios"
import toastr from "toastr"

export const requestGetZones = async ({ payload }) => {
  const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
  const limit = payload["pageSize"] ? payload["pageSize"] : 10
  _.unset(payload, "currentPage")
  try {
    let data = await Axios.post(
      `/api/zones/list?offset=${offset}&limit=${limit}`,
      payload
    )
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestAllZones = async () => {
  try {
    let data = await Axios.get(`/api/zones/all`)
    return data.data
  } catch (error) {
    throw error
  }
}

export const requestFeedEntityByFilters = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/zones/getEntityByFilters?offset=${offset}&limit=${limit}`,
      {
        filters: payload,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertZone = async ({ payload }) => {
  try {
    let data = await Axios.post("/api/zones/upsert", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetZoneDetails = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/zones/getZoneDetails?zoneUuid=${payload.zoneUuid}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteBanner = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/zones/delete/banner`, payload)
    toastr.success("Action Successful !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestAddPreviewImage = async ({ payload }) => {
  try {
    let data = await Axios.post("/api/zones/previewimage/add", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
export const requestTasksByFilters = async ({ payload }) => {
  try {
    const filters = payload.filters
    const offset = payload?.filters["currentPage"]
      ? payload.filters["currentPage"] - 1
      : 0
    const limit = payload?.filters["pageSize"]
      ? payload.filters["pageSize"]
      : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/zones/getTaskByFilters?offset=${offset}&limit=${limit}`,
      {
        filters: payload.filters,
        currentUser: payload.currentUser,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}
