import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Upload } from "antd"
import toastr from "toastr"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, { MultiSelect } from "../ValidatorForm"
import { LANGUAGES } from "constants/uiConstants"

export default class GiftForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      type: this.props?.data?.type || "PHOTO",
      height: this.props?.data?.height || null,
      width: this.props?.data?.width || null,
      view_order: this.props?.data?.view_order || null,
      lang_code:
        (this.props?.data?.target &&
          this.props.data.target.length &&
          this.props.data.target.map(({ lang_code = "" } = {}) => lang_code)) ||
        [],
    }
  }

  componentWillUnmount() {
    this._resetState()
  }

  _resetState() {
    this.setState({
      type: "",
      height: null,
      width: null,
      view_order: null,
    })
  }

  _onImageRemove = type => {
    if (type === "thumbnail_image") {
      this.setState({
        thumbnail_image_url_list: [],
        thumbnailImage: null,
      })
    }
    if (type === "download_image") {
      this.setState({
        download_image_url_list: [],
        downloadImage: null,
      })
    }
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "thumbnail_image") {
        this.setState({
          thumbnail_image_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          thumbnailImage: fileObj.file,
        })
      }
      if (type === "download_image") {
        this.setState({
          download_image_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          downloadImage: fileObj.file,
        })
      }
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Meme`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={4} className="mb-2">
                <label>Type</label>
                <input
                  className="form-control"
                  value={this.state.type}
                  disabled
                />
              </Col>

              <Col sm={12} md={4} className="mb-2">
                <label>Height</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.height}
                    onChange={e => {
                      if (e.target.value && e.target.value < 0) {
                        toastr.error("height can not be zero...")
                        return
                      }
                      this.setState({ height: e.target.value })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mb-2">
                <label>Width</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.width}
                    onChange={e => {
                      if (e.target.value && e.target.value < 0) {
                        toastr.error("width can not be zero...")
                        return
                      }
                      this.setState({ width: e.target.value })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mb-2">
                <label>View Order</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.view_order}
                    onChange={e => {
                      if (e.target.value && e.target.value < 0) {
                        toastr.error("view_order can not be zero...")
                        return
                      }
                      this.setState({ view_order: e.target.value })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mb-2">
                <MultiSelect
                  label={"Languages"}
                  placeholderLabel="Languages"
                  field={"lang_code"}
                  defaultValue={this.state.lang_code || []}
                  // onChange={langs => {
                  //   this.setState({ lang_code: langs })
                  // }}
                  options={LANGUAGES.map(language => {
                    return { label: language.label, value: language.value }
                  })}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm="12" md="6">
                <Row style={{ marginTop: "10px" }}>
                  <Upload
                    showUploadList={true}
                    onRemove={() => this._onImageRemove("thumbnail_image")}
                    defaultFileList={this.state.thumbnail_image_url_list}
                    fileList={this.state.thumbnail_image_url_list}
                    customRequest={fileObj =>
                      this._uploadImage(fileObj, "thumbnail_image")
                    }
                  >
                    <Button color="primary" onClick={() => {}}>
                      <i className="fas fa-upload" />
                      &nbsp; Upload Thumbnail Image
                    </Button>
                  </Upload>
                </Row>
              </Col>
              {/* <Col sm="12" md="6">
                <Row style={{ marginTop: "10px" }}>
                  <Upload
                    showUploadList={true}
                    onRemove={() => this._onImageRemove("download_image")}
                    defaultFileList={this.state.download_image_url_list}
                    fileList={this.state.download_image_url_list}
                    customRequest={fileObj =>
                      this._uploadImage(fileObj, "download_image")
                    }
                  >
                    <Button color="primary" onClick={() => {}}>
                      <i className="fas fa-upload" />
                      &nbsp; Upload Download Image
                    </Button>
                  </Upload>
                </Row>
              </Col> */}
            </Row>
            <Row>
              <Col>
                <div className="flex" style={{ marginTop: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <Button htmlType="submit" color="primary">
                      {this.props.action === "update" ? "Update" : "Create"}
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.onCancel()
                        this._resetState()
                      }}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data = new FormData()
    const payload = {}
    const { data: memeData = {} } = this.props || {}
    const { id } = memeData || {}
    const { type, height, width, view_order, thumbnailImage, downloadImage } =
      this.state

    if (id) {
      payload.id = id
    }

    if (type) {
      payload.type = type
    }
    if (height || height == 0) {
      payload.height = +height
    }
    if (width || width == 0) {
      payload.width = +width
    }
    if (view_order || view_order == 0) {
      payload.view_order = +view_order
    }

    if (formData && formData.lang_code) {
      payload.languages = formData.lang_code || []
    }

    if (thumbnailImage) {
      data.append("meme_thumbnail", thumbnailImage)
      data.append("download_url", thumbnailImage)
    }
    // if (downloadImage) {
    //   data.append("download_url", downloadImage)
    // }

    data.append("payload", JSON.stringify(payload))
    this.props.onSubmit(data)
    this._resetState()
  }

  render() {
    return this._form()
  }
}
