import Validator from "validator"

const Validations = {
  required: (val, err) => {
    if (!val && val !== 0) {
      return err || "Required"
    }
    return ""
  },
  requiredArr: (val, err) => {
    if (!val || !val.length) {
      return err || "Required"
    }
    return ""
  },
  number: (val, err) => {
    const numberReg = new RegExp("^[0-9]+$")
    if (!numberReg.test(val)) {
      return err || "Required to be number"
    }
    return null
  },
  char: (val, err) => {
    const charReg = new RegExp("^[^0-9]+$")
    if (!charReg.test(val)) {
      return err || "Required to be character"
    }
  },
  dateRangeRequired: (val, err) => {
    if (!val.startDate || !val.endDate) {
      return err || "Required"
    }
    return ""
  },
  isFloat: (val, err) => {
    if (val && !Validator.isFloat(String(val))) {
      return err || "Should be in decimal"
    }
    return ""
  },
  isInteger: (val, err) => {
    if (val && !Validator.isInt(String(val))) {
      return err || "Should be integer"
    }
    return ""
  },
  rteRequired: (val, err) => {
    if (!val.getEditorState().getCurrentContent().hasText()) {
      return err || "Required"
    }
    return ""
  },
  email: (val, err) => {
    if (!val || !Validator.isEmail(val.trim())) {
      return err || "Please enter a valid email"
    }
    return ""
  },
  phoneNumber: (val, err) => {
    const numberReg = new RegExp("^[0-9]+$")
    if (!val || !numberReg.test(val)) {
      return err || "Please enter a valid phone number"
    }
    if (!val || val.toString().length !== 10) {
      return err || "Phone number should be 10 digits only"
    }
    return null
  },
  profileLink: (val, err) => {
    if (!val || val.length > 200) {
      return err || "Social profile link should be within 200 characters"
    }
    return ""
  },
  password: (val, err) => {
    if (!val || val.length > 100) {
      return err || "Password should be within 100 characters"
    }
    return ""
  },
  max300chars: (val, err) => {
    if (val && val.length > 300) {
      return err || "Expecting 300 or less characters"
    }
    return ""
  },
  max400chars: (val, err) => {
    if (val && val.length > 400) {
      return err || "Expecting 300 or less characters"
    }
    return ""
  },
}

export default Validations
