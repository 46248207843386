import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { getZones, getAllZones, resetState } from "appRedux/slices/zone"
import _ from "lodash"
import { Tag, Pagination } from "antd"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import { formatNumber } from "../../util/common"
import ZoneCard from "./zoneCard"
import Chips from "components/CommonFilter/chips"
import toastr from "toastr"
import { zoneFilters } from "../../constants/uiConstants"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import { getPageEntities, upsertPageEntity } from "appRedux/slices/zeroSearch"
import EditPageForm from "containers/Discovery/editPageForm"
import { getLanguageData } from "../../appRedux/slices/uiConstants"
import { getLocationData } from "../../appRedux/slices/location"

class BrowseZone extends ErrorBoundComponent {
  state = {
    filters: {
      zoneId: this.props.queryParams ? this.props.queryParams["zoneId"] : "",
      title: this.props.queryParams ? this.props.queryParams["title"] : "",
      zoneType: this.props.queryParams
        ? this.props.queryParams["zoneType"]
        : "",
      status: this.props.queryParams ? this.props.queryParams["hashtag"] : "",
      type: this.props.queryParams ? this.props.queryParams["type"] : "",
      subType: this.props.queryParams ? this.props.queryParams["subType"] : "",
      langCode: this.props.queryParams
        ? this.props.queryParams["langCode"]
        : "",
      allowFollow: this.props.queryParams
        ? this.props.queryParams["allowFollow"]
        : "",
      currentPage: this.props.queryParams
        ? this.props.queryParams["currentPage"]
        : 1,
      pageSize: this.props.pageSize ? this.props.queryParams["pageSize"] : 10,
    },
    showModal: false,
    showUploadModal: false,
    action: "create",
    showFilters: false,
    showAdvancedFilters: false,
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      let filters = _.cloneDeep(this.state.filters)
      _.map(this.state.filters, (filter, key) => {
        if (!filter) {
          _.unset(filters, key)
        }
      })
      this.props.dispatch(getLocationData())
      this.props.dispatch(getLanguageData())
      this.props.dispatch(getZones(filters))
      this.props.dispatch(getAllZones({ currentPage: 1, pageSize: 10000 }))
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _onSearch = () => {
    let filters = _.cloneDeep(this.state.filters)
    _.map(this.state.filters, (filter, key) => {
      if (!filter) {
        _.unset(filters, key)
      }
    })
    this.props.history.push(`/zone/browse?${QueryString.stringify(filters)}`)
    this.props.dispatch(getZones(filters))
  }

  _closeFilters = () => {
    this._onSearch()
    this.setState({ showFilters: false, showAdvancedFilters: false })
  }

  _handleFilterChange = (value, type) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: zoneFilters[filter].defaultValue,
      showFilters: false,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/zone/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        let filters = _.cloneDeep(this.state.filters)
        this.props.dispatch(getZones(filters))
      }
    )
  }
  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }
  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _renderFilters = () => {
    const typeList = []
    const subTypeList = []
    const zoneFilterList = []
    this.props?.allZoneList.map(zone => {
      if (zone && zone.zone_uuid && +zone.level === 0) {
        typeList.push({
          ...zone,
          value: zone.zone_uuid,
          label: zone.title || zone.sub_title,
        })
      } else if (zone && zone.zone_uuid && +zone.level === 1) {
        subTypeList.push({
          ...zone,
          value: zone.zone_uuid,
          label: zone.title || zone.sub_title,
        })
      }
      zoneFilterList.push({
        ...zone,
        value: zone.zone_uuid,
        label: zone.title || zone.sub_title,
      })
    })

    return (
      <Chips
        type="zone"
        showFilters={this.state.showFilters}
        showAdvancedFilters={this.state.showAdvancedFilters}
        filters={this.state.filters}
        search={this._closeFilters}
        removeFilter={this._removeFilter}
        handleFilterChange={this._handleFilterChange}
        toggleFilters={this._toggleFilters}
        toggleAdvancedFilters={this._toggleAdvancedFilters}
        typeList={typeList}
        subTypeList={subTypeList}
        allZoneList={zoneFilterList}
      />
    )
  }
  _showZoneDetails = () => {
    return (
      <React.Fragment>
        <Col lg={this.state.showForm ? "7" : "12"}>
          <div className="trending-card-container">
            {this.props.zoneList.map((zone, index) => {
              return (
                <ZoneCard
                  zone={zone}
                  key={`${index}_${zone.title}`}
                  editClick={(zone, type) => this.editClick(zone, type)}
                  currentUser={this.props.currentUser}
                  history={this.props.history}
                  onEditClick={zone =>
                    this.props.history.push(
                      `/zone/manage?zoneUuid=${zone.zone_uuid}`
                    )
                  }
                  onAddCollection={this.onAddCollection}
                  showDetails={true}
                />
              )
            })}
          </div>
        </Col>
      </React.Fragment>
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._onSearch()
      }
    )
  }

  _pagination = () => {
    return (
      <div className="d-flex justify-challenge-end">
        <Pagination
          style={{ marginTop: "1rem" }}
          onChange={this._onPageChange}
          current={parseInt(this.state.filters["currentPage"], 10)}
          defaultCurrent={parseInt(this.state.filters["currentPage"], 10)}
          total={this.props.total > 10000 ? 10000 : this.props.total}
          showSizeChanger={false}
        />
      </div>
    )
  }

  onAddCollection = data => {
    this.props.dispatch(
      getPageEntities({
        pageEntityId: data,
        filters: {
          pageSize: 1000,
          currentPage: 1,
        },
      })
    )
    this.setState({
      showSeachAndSelectModal: true,
      selectedPage: data,
    })
  }

  _onAdd = data => {
    const [collection] = Object.values(data)
    const { target, ...collectionData } = collection
    this.setState({
      selectedPage: {
        ...collectionData,
        page_uuid: this.state.selectedPage,
        view_order: this.props.totalPageEntities,
      },
      showAddForm: true,
      action: "edit",
    })
  }

  _addPageEntity = async (data, action, collectionId) => {
    const entities =
      this.props.pageEntities &&
      this.props.pageEntities.map(entity => entity.collection_uuid)

    if (entities.includes(collectionId)) {
      toastr.error(`Page entity ${collectionId} already exists!!`)
      return
    }
    const response = await this.props.dispatch(
      upsertPageEntity({ data, action: "create", entities })
    )
    this.setState({ showAddForm: false, showSeachAndSelectModal: false })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.state.showAddForm && (
          <EditPageForm
            formAction="edit"
            onCancel={() => this.setState({ showAddForm: false })}
            data={this.state.selectedPage}
            locationList={this.props.locationList}
            title={"Add Collection"}
            languageList={this.props.languageList}
            onSubmit={(data, action, collectionId) =>
              this._addPageEntity(data, "create", collectionId)
            }
            hasEditPermission={true}
            pageType="ZONE"
          />
        )}
        {this.state.showSeachAndSelectModal && (
          <SearchAndSelect
            type="collection"
            visible={this.state.showSeachAndSelectModal}
            onClose={() => this.setState({ showSeachAndSelectModal: false })}
            onAdd={arr => this._onAdd(arr)}
            selectOne={true}
            hidePagination={false}
            history={this.props.history}
          />
        )}
        {!this.state.showAddForm && this._renderFilters()}
        {!this.state.showAddForm && (
          <div className="d-flex justify-content-end mb-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                if (
                  !this.props.currentUser?.permissions?.includes(
                    "EDIT_BROWSE_ZONE"
                  )
                ) {
                  toastr.error(NO_PERMISSION_MESSAGE)
                  return
                }
                this.props.dispatch(resetState())
                this.props.history.push(`/zone/manage`)
              }}
            >
              Create
            </button>
          </div>
        )}

        {!this.state.showAddForm && (
          <Row>
            {this.props.total &&
              this.props.zoneList &&
              this.props.zoneList.length > 0 && (
                <div style={{ textAlign: "center" }} className="mb-2">
                  <Tag color="geekblue">
                    Showing <b>{this.props.zoneList.length}</b> of{" "}
                    <b>{formatNumber(this.props.total)}</b> Zones
                  </Tag>
                </div>
              )}
            {!_.isEmpty(this.props.zoneList) && this._showZoneDetails()}
            <div className="d-flex justify-content-end mb-3">
              {this._pagination()}
            </div>
          </Row>
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    zoneList: _.get(store, "zone.zoneList"),
    allZoneList: _.get(store, "zone.allZoneList"),
    totalPageEntities: _.get(store, "zeroSearch.totalPageEntities"),
    languageList: _.get(store, "uiConstants.languageList"),
    pageEntities: _.get(store, "zeroSearch.pageEntities"),
    locationList: _.get(store, "locationReducer.locationList"),
  }
}

export default connect(mapStateToProps)(BrowseZone)
