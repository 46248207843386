import React, { PureComponent } from "react"
import Plot from "react-plotly.js"

const Chart = props => {
  let { data, title, layout = {} } = props

  return <Plot data={data} layout={{ ...layout, title }} />
}

export default Chart
