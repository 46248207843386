import React from "react"
import { Upload, Select as AntSelect, DatePicker, Divider } from "antd"
import { Row, Col, Button } from "reactstrap"
import _ from "lodash"
import ValidatorForm, { TextField, Select, Switch } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import moment from "moment"
import { getBrands } from "appRedux/slices/sponsoredTags"
//import "./cameraAsset.style.css"
import dayjs from "dayjs"
const { Option } = AntSelect

export default class BrandLogoCreateForm extends ErrorBoundComponent {
  state = {
    formData: {
      horizontal_animation_type: this.props?.campaign?.logo_meta?.filter(
        item => item.type == "HORIZONTAL"
      )[0]?.cta?.animation
        ? this.props?.campaign?.logo_meta.filter(
            item => item.type == "HORIZONTAL"
          )[0].cta.animation
        : "0",
      vertical_animation_type: this.props?.campaign?.logo_meta?.filter(
        item => item.type == "VERTICAL"
      )[0]?.cta?.animation
        ? this.props?.campaign?.logo_meta.filter(
            item => item.type == "VERTICAL"
          )[0].cta.animation
        : "0",
    },
  }

  componentDidMount() {}

  _handleFieldChange = (field, value) => {
    // console.log(field, value, "handle field change")
    let formData = this.state.formData
    if (!_.isEmpty(value)) {
      formData[field] = value
      this.setState({ formData })
    }
  }

  _handleMultiSelect = value => {
    let formData = this.state.formData
    formData["fuAssetIds"] = value
    this.setState({ formData })
  }

  _handleSelect = (field, value) => {
    let formData = this.state.formData
    formData[field] = value
    this.setState({ formData })
  }

  _onImageRemove = type => {
    if (type === "horizontal_Logo_url") {
      this.setState({
        horizontal_Logo_url_list: [],
        horizontal_Logo_url: null,
      })
    }
    if (type === "vertical_Logo_url") {
      this.setState({
        vertical_Logo_url_list: [],
        vertical_Logo_url: null,
      })
    }
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "horizontal_Logo_url") {
        this.setState({
          horizontal_Logo_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          horizontal_Logo_url: fileObj.file,
        })
      }
      if (type === "vertical_Logo_url") {
        this.setState({
          vertical_Logo_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          vertical_Logo_url: fileObj.file,
        })
      }
    }
  }

  _form = () => {
    let horizontalData = {}
    let horizontal = []
    let verticalData = {}
    let vertical = []
    if (this.props.formAction !== "create") {
      const { logo_meta = [] } = this.props.campaign
      horizontal =
        logo_meta && logo_meta.filter(data => data.type === "HORIZONTAL")
      vertical = logo_meta && logo_meta.filter(data => data.type === "VERTICAL")
      horizontalData =
        horizontal && horizontal.length > 0 && horizontal[0]["cta"]
          ? {
              ...horizontal[0]["cta"],
              ...{
                enabled: horizontal[0]["enabled"]
                  ? horizontal[0]["enabled"]
                  : horizontal[0]["cta"]["enabled"],
              },
            }
          : {}
      verticalData =
        vertical && vertical.length > 0 && vertical[0]["cta"]
          ? {
              ...vertical[0]["cta"],
              ...{
                enabled: vertical[0]["enabled"]
                  ? vertical[0]["enabled"]
                  : vertical[0]["cta"]["enabled"],
              },
            }
          : {}

      // this.setState({
      //   horizontal_animation_type:horizontalData?.animation,
      //   vertical_animation_type:verticalData?.animation
      // })
    }

    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            size="default"
            type="submit"
            //onClick={() => this._onSubmit}
            //disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            {this.props.formAction === "create" ? "Create" : "Update"}
          </Button>
          <Button
            color="danger"
            size="default"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
            onClick={() => this.props.onCancel()}
            className="mx-2"
          >
            Cancel
          </Button>
        </div>
        <Row>
          {this.props.formAction === "update" && (
            <Col sm={12} lg={6}>
              <TextField
                label="Campaign UUID"
                defaultValue={this.props?.campaign?.campaign_uuid || ""}
                placeholderLabel="Campaign UUID"
                field="campaign_uuid"
                disabled={true}
                allowClear={true}
                className="creator-form"
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col sm={12} lg={6}>
            <TextField
              label="Campaign Name"
              defaultValue={this.props?.campaign?.campaign_name || ""}
              placeholderLabel="Campaign Name"
              field="campaign_name"
              allowClear={true}
              className="creator-form"
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="Brand UUID"
              defaultValue={this.props?.campaign?.brand_uuid || ""}
              placeholderLabel="Brand UUID"
              field="brand_uuid"
              //disabled={true}
              allowClear={true}
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              //onChange={value => this._handleFieldChange("brand_uuid", value)}
            />
          </Col>
          <Col sm={12} lg={12}>
            <Divider>
              <h3 className="font-size-16 mb-0 text-muted">Horizontal</h3>
            </Divider>
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label={"Label"}
              field={"horizontal_label"}
              allowClear={true}
              placeholderLabel="Label"
              defaultValue={horizontalData?.label}
            />
          </Col>

          {/* <Col sm={12} lg={6}>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Animation Type</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Animation Type"
            defaultValue={horizontalData?.animation}
            onChange={value => {
              this._handleFieldChange(value, "horizontal_animation_type")
            }}
            style={{ width: "100%" }}
          >
            <Option value="0">0</Option>
            <Option value="1">1</Option>
            <Option value="2">Top 1k</Option>
            <Option value="3">Top 5k</Option>
            <Option value="Remaining">Remaining</Option>
            <Option value="Top 100">Top 100</Option>
            <Option value="Top 500">Top 500</Option>
            <Option value="Top 1k">Top 1k</Option>
            <Option value="Top 5k">Top 5k</Option>
            <Option value="Remaining">Remaining</Option>
          </Select>
        </Col> */}
          <Col sm={12} lg={6}>
            {/* <TextField
              label={"Animation Type"}
              field={"horizontal_animation_type"}
              allowClear={true}
              placeholderLabel="Animation Type"
              defaultValue={horizontalData?.animation}
            /> */}
            <label> Animation Type</label>

            <select
              className="form-select"
              type="text"
              value={this.state.formData.horizontal_animation_type}
              onChange={e =>
                this._handleFieldChange(
                  "horizontal_animation_type",
                  e.target.value
                )
              }
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </select>
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label={"Deeplink"}
              field={"horizontal_deep_link"}
              allowClear={true}
              placeholderLabel="Deeplink"
              defaultValue={horizontalData?.deep_link}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label={"Deeplink IOS"}
              field={"horizontal_deep_link_ios"}
              allowClear={true}
              placeholderLabel="Deeplink"
              defaultValue={horizontalData?.deep_link_ios}
            />
          </Col>

          <Col
            sm={12}
            lg={4}
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <Upload
              showUploadList={true}
              onRemove={() => this._onImageRemove("horizontal_Logo_url")}
              defaultFileList={this.state.horizontal_Logo_url_list}
              fileList={this.state.horizontal_Logo_url_list}
              customRequest={fileObj =>
                this._uploadImage(fileObj, "horizontal_Logo_url")
              }
            >
              <Button color="primary" onClick={() => {}}>
                <i className="fas fa-upload" />
                &nbsp; Upload Logo
              </Button>
            </Upload>
            {horizontalData?.logo_url && (
              <img
                width="50px"
                height="50px"
                style={{ marginLeft: "15px" }}
                src={horizontalData?.logo_url}
              />
            )}
            {/* <TextField
    label={"Logo URL"}
    field={"horizontal_logo_url"}
    allowClear={true}
    placeholderLabel="Logo URL"
    defaultValue={horizontalData?.logo_url}
  /> */}
          </Col>
          <Col sm={12} lg={2}>
            <Switch
              label={"Show Arrow"}
              field={"horizontal_show_arrow"}
              defaultValue={horizontalData?.show_arrow == true ? true : false}
            />
          </Col>
          <Col sm={6} lg={2}>
            <Switch
              label={"Enable"}
              field={"horizontal_enable"}
              defaultValue={horizontalData?.enabled == true ? true : false}
            />
          </Col>
          <Col sm={12} lg={2}>
            <Switch
              label={"IOS disable"}
              field={"horizontal_ios_disabled"}
              defaultValue={horizontalData?.ios_disabled == true ? true : false}
            />
          </Col>
          <Col sm={6} lg={2}>
            <Switch
              label={"Android disable"}
              field={"horizontal_android_disabled"}
              defaultValue={
                horizontalData?.android_disabled == true ? true : false
              }
            />
          </Col>
          <Col sm={12} lg={12}>
            <Divider>
              <h3 className="font-size-16 mb-0 text-muted">Vertical</h3>
            </Divider>
          </Col>
          {/* <Col sm={12} lg={6}>
            <TextField
              label={"Label"}
              field={"vertical_label"}
              allowClear={true}
              placeholderLabel="Label"
              defaultValue={verticalData?.label}
            />
          </Col> */}
          <Col sm={12} lg={6}>
            <TextField
              label={"Deeplink"}
              field={"vertical_deep_link"}
              allowClear={true}
              placeholderLabel="Deeplink"
              defaultValue={verticalData?.deep_link}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label={"Deeplink IOS"}
              field={"vertical_deep_link_ios"}
              allowClear={true}
              placeholderLabel="Deeplink"
              defaultValue={verticalData?.deep_link_ios}
            />
          </Col>
          <Col sm={12} lg={6}>
            {/* <TextField
              label={"Animation Type"}
              field={"vertical_animation_type"}
              allowClear={true}
              placeholderLabel="Animation Type"
              defaultValue={verticalData?.animation}
            /> */}
            <label> Animation Type</label>
            <select
              className="form-select"
              type="text"
              value={this.state.formData.vertical_animation_type}
              onChange={e =>
                this._handleFieldChange(
                  "vertical_animation_type",
                  e.target.value
                )
              }
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </select>
          </Col>
          <Col sm={12} lg={6}></Col>
          <br />
          <Col
            sm={12}
            lg={4}
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <Upload
              showUploadList={true}
              onRemove={() => this._onImageRemove("vertical_Logo_url")}
              defaultFileList={this.state.vertical_Logo_url_list}
              fileList={this.state.vertical_Logo_url_list}
              customRequest={fileObj =>
                this._uploadImage(fileObj, "vertical_Logo_url")
              }
            >
              <Button color="primary" onClick={() => {}}>
                <i className="fas fa-upload" />
                &nbsp; Upload Logo
              </Button>
            </Upload>
            {verticalData?.logo_url && (
              <img
                width="50px"
                height="50px"
                style={{ marginLeft: "15px" }}
                src={verticalData?.logo_url}
              />
            )}

            {/* <TextField
              label={"Logo URL"}
              field={"vertical_logo_url"}
              allowClear={true}
              placeholderLabel="Logo URL"
              defaultValue={verticalData?.logo_url}
            /> */}
          </Col>
          <Col sm={12} lg={2}>
            <Switch
              label={"Show Arrow"}
              field={"vertical_show_arrow"}
              defaultValue={verticalData?.show_arrow === true ? true : false}
            />
          </Col>
          <Col sm={6} lg={2}>
            <Switch
              label={"Enable"}
              field={"vertical_enable"}
              defaultValue={verticalData?.enabled == true ? true : false}
            />
          </Col>
          <Col sm={6} lg={2}>
            <Switch
              label={"IOS disable"}
              field={"vertical_ios_disabled"}
              defaultValue={verticalData?.ios_disabled == true ? true : false}
            />
          </Col>
          <Col sm={6} lg={2}>
            <Switch
              label={"Android Disable"}
              field={"vertical_android_disabled"}
              defaultValue={
                verticalData?.android_disabled == true ? true : false
              }
            />
          </Col>
          <Col sm={12} lg={12}>
            <Divider />
          </Col>
          <Col sm={12} lg={3}>
            <div>
              Start Date
              <DatePicker
                defaultValue={
                  this.props?.campaign?.start_time
                    ? dayjs.unix(
                        this.props?.campaign.start_time / 1000,
                        "YYYY-MM-DD"
                      )
                    : null
                }
                format="YYYY-MM-DD HH:mm"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                showTime
                placeholder="Valid From"
                onChange={(date, dateString) => {
                  this._handleFieldChange(
                    "start_date",
                    !_.isEmpty(dateString)
                      ? moment(dateString).format("x")
                      : null
                  )
                }}
              />
            </div>
          </Col>
          <Col sm={12} lg={3}>
            <div>
              End Date
              <DatePicker
                defaultValue={
                  this.props?.campaign?.end_time
                    ? dayjs.unix(
                        this.props?.campaign?.end_time / 1000,
                        "YYYY-MM-DD"
                      )
                    : null
                }
                format="YYYY-MM-DD HH:mm"
                showTime
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                placeholder="Valid Till"
                onChange={(date, dateString) => {
                  this._handleFieldChange(
                    "end_date",
                    !_.isEmpty(dateString)
                      ? moment(dateString).format("x")
                      : null
                  )
                }}
              />
            </div>
          </Col>
          <Col sm={6} lg={3}>
            <Switch
              label={"Status"}
              field={"status"}
              defaultValue={
                this.props?.campaign?.status == "true" ? true : false
              }
            />
          </Col>
          <Col sm={6} lg={3}>
            <Switch
              label={"Show"}
              field={"show"}
              defaultValue={this.props?.campaign?.show == true ? true : false}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            size="default"
            type="submit"
            //onClick={() => this._onSubmit}
            //disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            {this.props.formAction === "create" ? "Create" : "Update"}
          </Button>
          <Button
            color="danger"
            size="default"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
            onClick={() => this.props.onCancel()}
            className="mx-2"
          >
            Cancel
          </Button>
        </div>
      </ValidatorForm>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let formData1 = { ...this.state.formData, ...formData }
    for (let i = 0; i < this.props.campaign?.logo_meta?.length; i++) {
      if (this.props.campaign?.logo_meta[i]?.type == "HORIZONTAL") {
        formData1 = {
          ...formData1,
          ...{
            horizontal_logo_url: this.props.campaign?.logo_meta[i].cta.logo_url,
          },
        }
      } else if (this.props.campaign?.logo_meta[i]?.type == "VERTICAL") {
        formData1 = {
          ...formData1,
          ...{
            vertical_logo_url: this.props.campaign?.logo_meta[i].cta.logo_url,
          },
        }
      }
    }

    let data = new FormData()
    const { horizontal_Logo_url, vertical_Logo_url } = this.state

    data.append("HORIZONTAL", horizontal_Logo_url)
    data.append("VERTICAL", vertical_Logo_url)
    data.append("details", JSON.stringify(formData1))
    // const data = { ...formData, ...this.state.formData }

    this.props.onSubmit(data)
  }

  _render = () => {
    return <Auxiliary loading={this.props.loading}>{this._form()}</Auxiliary>
  }
}
