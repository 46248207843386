import React from "react"
import { Divider, Select } from "antd"
import _ from "lodash"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Card, CardBody, Row, Col } from "reactstrap"
import { MultiSelect } from "../ValidatorForm"
import { Form } from "react-bootstrap"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import toastr from "toastr"
import { TextField } from "../ValidatorForm"
import { Button } from "reactstrap"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import ReactSelect from "react-select"
import { isJson } from "../../util/common"

export default class ImageUpsertForm extends ErrorBoundComponent {
  state = {
    formData: {
      image_details: [
        {
          height: "",
          width: "",
          id: uuidv4(),
          view_order: 0,
        },
      ],
      lang_code: "en",
      fileList: {},
      is_active: true,
      ce_type: "PHOTO",
      src_type: "GALLERY",
      active_zones: [],
      input_zones: [],
      target_languages: [],
      campaign_uuid: "",
    },
    action: this.props.action,
    errors: {},
    selectedLangs: [],
  }

  componentDidMount() {
    const image = this.props.image
    let selectedLangs = [...this.state.selectedLangs]

    if (!_.isEmpty(image)) {
      let formData = {
        title: image.content_title,
        ce_type: image.ce_type ? image.ce_type : image.type,
        lang_code: image.lang_code,
        is_active: image.is_active,
        src_type: image.src_type,
        user_uuid: image?.user_profile?.user_uuid,
        image_details: image.image_details,
        image_count: image.image_count,
        target_languages: _.isEmpty(image.target_languages)
          ? []
          : image.target_languages,
        input_zones: _.isEmpty(image.input_zones) ? [] : image.input_zones,
        active_zones: _.isEmpty(image.active_zones) ? [] : image.active_zones,
        zone_info: _.isEmpty(image.zone_info_v1) ? [] : image.zone_info_v1,
        campaign_uuid: image.campaign_uuid ? image.campaign_uuid : "",
        captions: image.captions ? image.captions : "",
      }
      this.setState({
        formData: {
          ...this.state.formData,
          ...formData,
        },
        selectedLangs: _.uniq(selectedLangs),
      })
    }
  }

  formatOptionLabel({ campaign_uuid, title, campaign_name }) {
    return (
      <span>
        <i className="fa fa-users" aria-hidden="true"></i>
        &nbsp;
        <b>{campaign_name}</b>
      </span>
    )
  }

  _handleFieldChange = (field, value, parent, index) => {
    if (parent) {
      if (parent) {
        const image_details = [...this.state.formData.image_details]
        image_details[index] = {
          ...image_details[index],
          [field]: value,
        }
        this.setState({
          formData: {
            ...this.state.formData,
            image_details,
          },
        })
      }
    } else {
      if (field === "bannerType") {
        if (value === "image") {
          this.setState({
            formData: {
              ...this.state.formData,
              banner_list: [
                {
                  element_cta: "",
                  id: uuidv4(),
                  view_order: 0,
                },
              ],
              video: null,
              bannerType: "image",
            },
          })
        } else {
          this.setState({
            formData: {
              ...this.state.formData,
              video: {
                id: uuidv4(),
                auto_play: true,
                mute_on_start: true,
              },
              banner_list: null,
              bannerType: "video",
            },
          })
        }
      } else {
        this.setState({
          formData: {
            ...this.state.formData,
            [field]: value,
          },
          errors: {
            ...this.state.errors,
            [field]: null,
          },
        })
      }
    }
  }

  _handleHeightandWidthFieldChange = (
    field,
    value,
    field2,
    value2,
    parent,
    index
  ) => {
    if (parent) {
      if (parent) {
        const image_details = [...this.state.formData.image_details]
        image_details[index] = {
          ...image_details[index],
          ...{ [field]: value, [field2]: value2 },
        }
        this.setState({
          formData: {
            ...this.state.formData,
            image_details,
          },
        })
      }
    } else {
      if (field === "bannerType") {
        if (value === "image") {
          this.setState({
            formData: {
              ...this.state.formData,
              banner_list: [
                {
                  element_cta: "",
                  id: uuidv4(),
                  view_order: 0,
                },
              ],
              video: null,
              bannerType: "image",
            },
          })
        } else {
          this.setState({
            formData: {
              ...this.state.formData,
              video: {
                id: uuidv4(),
                auto_play: true,
                mute_on_start: true,
              },
              banner_list: null,
              bannerType: "video",
            },
          })
        }
      } else {
        this.setState({
          formData: {
            ...this.state.formData,
            [field]: value,
          },
          errors: {
            ...this.state.errors,
            [field]: null,
          },
        })
      }
    }
  }
  _handleFileUpload = (key, file) => {
    this.setState({
      formData: {
        ...this.state.formData,
        fileList: {
          ...this.state.formData.fileList,
          [key]: file,
        },
      },
    })
  }

  _onSubmit = () => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_IMAGE")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }

    let formData = { ...this.state.formData }

    let title_array =
      this.state.formData &&
      this.state.formData.title &&
      this.state.formData.title.split(" ")
    let raw_title =
      title_array &&
      title_array.map(arr => {
        if (arr.includes("#")) {
          return `<hashtag ${arr.replace("#", "")}>`
        }
        if (arr.includes("@")) {
          return `<mention ${arr.replace("@", "")}>`
        } else return arr
      })

    formData.title = raw_title && raw_title.join(" ")

    if (this.props.formAction === "update") {
      formData.image_uuid = this.props.image.content_uuid
    }

    let data = new FormData()
    let imageDetailsMap = {}
    if (!_.isEmpty(formData.image_details)) {
      imageDetailsMap = formData.image_details.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: curr }),
        {}
      )
      formData.image_details = imageDetailsMap
    }

    Object.keys(formData.fileList).forEach(fileName => {
      data.append(fileName, formData.fileList[fileName])
    })

    delete formData?.fileList

    if (formData && formData.image_details.length > 0) {
      formData.image_count = formData.image_details.length
    }
    let sub_type_zone = []
    let updated_all_active_zones = formData?.active_zones
    let updated_active_zones = []

    // check for valid zone
    for (let i = 0; i < updated_all_active_zones.length; i++) {
      for (let j = 0; j < this.props.zoneList.length; j++) {
        if (updated_all_active_zones[i] == this.props.zoneList[j].zone_uuid) {
          updated_active_zones = [
            ...updated_active_zones,
            this.props.zoneList[j].zone_uuid,
          ]
        }
      }
    }

    let validZones = [...updated_active_zones]

    validZones.map(item => {
      this.props.zoneList.map(zone => {
        if (item == zone.zone_uuid) {
          if (zone.level == 2) {
            updated_active_zones = [
              ...updated_active_zones,
              ...zone.sub_type,
              ...zone.type,
            ]
            sub_type_zone = [...sub_type_zone, ...zone.sub_type]
          } else if (zone.level == 1) {
            updated_active_zones = [...updated_active_zones, ...zone.type]
          }
        }
      })
    })

    sub_type_zone.map(item => {
      this.props.zoneList.map(zone => {
        if (item == zone.zone_uuid) {
          if (zone.level == 1) {
            updated_active_zones = [...updated_active_zones, ...zone.type]
          }
        }
      })
    })

    const newActiveSet = new Set(updated_active_zones)
    const activeZones = [...newActiveSet]

    formData.active_zones = [...newActiveSet]
    formData.input_zones = [...formData.input_zones, ...formData.active_zones]
    const newInputSet = new Set(formData.input_zones)
    formData.input_zones = [...newInputSet]
    const inputZones = [...newInputSet]
    let zoneInfo = formData.zone_info_v1 || []

    if (zoneInfo?.length > 0) {
      if (!_.isEmpty(activeZones)) {
        for (let activeZone of activeZones) {
          let zoneHandled = false
          for (let zone of zoneInfo) {
            if (zone.zone_id === activeZone) {
              zone.shouldRetrieve = true
              zoneHandled = true
              if (
                zone.status === "system_removed" ||
                zone.status === "human_removed"
              ) {
                zone.status = "system_approved"
                zone.modified_on = moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30")
              }
            }
          }

          if (!zoneHandled) {
            zoneInfo.push({
              zone_id: activeZone,
              status: "system_approved",
              source: "UGC",
              added_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_by: this.props.currentUser.email,
              shouldRetrieve: true,
            })
          }
        }

        for (let zone of zoneInfo) {
          if (!zone.shouldRetrieve) {
            zone.status = "human_removed"
            zone.modified_on = moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30")
          }
          // delete zone?.shouldRetrieve
        }
      } else {
        for (let zone of zoneInfo) {
          zone.status = "human_removed"
          zone.modified_on = moment()
            .tz("Asia/Calcutta")
            .format("YYYY-MM-DDTHH:mm:ss+05:30")
        }
      }
    } else {
      if (!_.isEmpty(activeZones)) {
        for (let zone of activeZones) {
          zoneInfo.push({
            zone_id: zone,
            status: "system_approved",
            source: "UGC",
            added_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_by: this.props.currentUser.email,
            shouldRetrieve: true,
          })
        }
      }
    }
    //zoneInfo for Input zone
    if (zoneInfo?.length > 0) {
      if (!_.isEmpty(inputZones)) {
        for (let inputZone of inputZones) {
          let zoneHandled = false
          for (let zone of zoneInfo) {
            if (zone.zone_id === inputZone) {
              zone.shouldRetrieve = true
              zoneHandled = true
              if (
                zone.status === "system_removed" ||
                zone.status === "human_removed"
              ) {
                zone.status = "added"
                zone.modified_on = moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30")
              }
            }
          }

          if (!zoneHandled) {
            zoneInfo.push({
              zone_id: inputZone,
              status: "added",
              source: "UGC",
              added_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_by: this.props.currentUser.email,
              shouldRetrieve: true,
            })
          }
        }

        for (let zone of zoneInfo) {
          if (!zone.shouldRetrieve) {
            zone.status = "human_removed"
            zone.modified_on = moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30")
          }
          delete zone?.shouldRetrieve
        }
      } else {
        for (let zone of zoneInfo) {
          zone.status = "human_removed"
          zone.modified_on = moment()
            .tz("Asia/Calcutta")
            .format("YYYY-MM-DDTHH:mm:ss+05:30")
        }
      }
    } else {
      if (!_.isEmpty(inputZones)) {
        for (let zone of inputZones) {
          zoneInfo.push({
            zone_id: zone,
            status: "added",
            source: "UGC",
            added_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_by: this.props.currentUser.email,
          })
        }
      }
    }

    formData.zone_info = zoneInfo
    data.append("details", JSON.stringify(formData))
    if (this.props.formAction === "update") {
      data.append("action", "UPDATE")
    } else {
      data.append("action", "CREATE")
    }
    this.props.onSubmit(data)
  }

  _addImages = () => {
    let image_details = [...this.state.formData.image_details]
    image_details = [
      ...image_details,
      {
        height: "",
        width: "",
        id: uuidv4(),
        view_order: image_details.length,
      },
    ]
    this.setState({
      formData: {
        ...this.state.formData,
        image_details,
      },
    })
  }

  _renderUpsertForm = () => {
    const allCampaignList = []
    this.props?.campaignList?.data?.map(camp => {
      if (camp && camp.campaign_uuid && camp.status == "true") {
        allCampaignList.push({
          ...camp,
          value: camp.campaign_uuid,
          label: camp.campaign_name,
        })
      }
    })
    return (
      <Card>
        <CardBody>
          <Row className="mb-3">
            <Col lg="6">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  onChange={e =>
                    this._handleFieldChange("title", e.target.value)
                  }
                  defaultValue={this.state.formData.title}
                  isInvalid={!!this.state.errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.title}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg="3">
              <label>Type</label>
              <div>
                <select
                  className="form-select"
                  type="text"
                  value={this.state.formData.ce_type}
                  onChange={e =>
                    this._handleFieldChange("ce_type", e.target.value)
                  }
                >
                  <option value="PHOTO">Photo</option>
                  <option value="MEME">Meme</option>
                  <option value="NEWS">News</option>
                  <option value="GREETING">Greeting</option>
                </select>
              </div>
            </Col>

            <Col lg="3">
              <label>Language</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange("lang_code", e.target.value)
                  }
                  value={this.state.formData.lang_code}
                >
                  {LANGUAGES.map(lang => (
                    <option value={lang.value} key={lang.value}>
                      {lang.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>

            <Col lg="6">
              <label className="col-form-label">Target Languages</label>
              <MultiSelect
                placeholderLabel="Languages"
                className="creator-form"
                defaultValue={this.state.formData.target_languages}
                options={LANGUAGES.map(lang => {
                  return { label: lang.label, value: lang.value }
                })}
                onChange={value =>
                  this._handleFieldChange("target_languages", value)
                }
              />
            </Col>
            <Col lg="3">
              <label className="col-form-label">Source Type</label>
              <div>
                <select
                  className="form-select"
                  type="text"
                  value={this.state.formData.src_type}
                  onChange={e =>
                    this._handleFieldChange("src_type", e.target.value)
                  }
                >
                  <option value="GALLERY">Gallery</option>
                  <option value="CAMERA">Camera</option>
                </select>
              </div>
            </Col>
            <Col lg="3" className="d-flex align-items-center mt-3">
              <div className="me-5">
                <div className="form-check form-switch form-switch-lg">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={e =>
                      this._handleFieldChange("is_active", e.target.checked)
                    }
                    checked={
                      this.state.formData.is_active
                        ? this.state.formData.is_active
                        : this.state.formData?.is_active?.toString() == "false"
                        ? false
                        : true
                    }
                  />
                  <label className="form-check-label">Is Active</label>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <label className="col-form-label">User ID</label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  field="user_uuid"
                  onChange={e =>
                    this._handleFieldChange("user_uuid", e.target.value)
                  }
                  defaultValue={this.state.formData.user_uuid}
                />
              </div>
            </Col>
            <Col sm="12" lg="6">
              <label
                className="form-check-label"
                style={{ marginBottom: "18px" }}
              >
                Campaign Id
              </label>

              <ReactSelect
                value={
                  allCampaignList?.filter(
                    camp =>
                      camp?.campaign_uuid ===
                      this.state?.formData?.campaign_uuid
                  )[0] || ""
                }
                isClearable={true}
                style={{ width: "100%", height: "100px" }}
                isMulti={false}
                options={allCampaignList || []}
                formatOptionLabel={this.formatOptionLabel}
                onChange={value =>
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      campaign_uuid: value?.campaign_uuid
                        ? value.campaign_uuid
                        : "",
                    },
                  })
                }
                placeholder="Select Campaign"
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Row>
                <Col sm="6" md="9">
                  <label className="col-form-label">Input Zones</label>
                  <TextField
                    placeholderLabel="Select Input Zones"
                    field="input_zones"
                    value={this.state.formData.input_zones}
                    onChange={val => {
                      if (val.trim()) {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            input_zones: val.split(","),
                          },
                        })
                      } else {
                        this.setState({
                          formData: { ...this.state.formData, input_zones: [] },
                        })
                      }
                    }}
                    className="creator-form"
                    defaultValue={this.state.formData.input_zones}
                    style={{ width: "95%" }}
                  />
                </Col>
                <Col
                  sm="6"
                  md="3"
                  style={{ alignSelf: "flex-end", marginBottom: "9px" }}
                >
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        selectInputZones: true,
                      })
                    }
                  >
                    Select
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg="6" sm="12">
              <Row>
                <Col sm="6" md="9">
                  <label className="col-form-label">Active Zones</label>
                  <TextField
                    placeholderLabel="Select Active Zones"
                    field="active_zones"
                    className="creator-form"
                    value={this.state.formData.active_zones}
                    onChange={val => {
                      if (val.trim()) {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            active_zones: val.split(","),
                          },
                        })
                      } else {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            active_zones: [],
                          },
                        })
                      }
                    }}
                    defaultValue={this.state.formData.active_zones}
                    style={{ width: "95%" }}
                  />
                </Col>
                <Col
                  sm="6"
                  md="3"
                  style={{ alignSelf: "flex-end", marginBottom: "9px" }}
                >
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        selectActiveZoneModal: true,
                      })
                    }
                  >
                    Select
                  </Button>
                </Col>
              </Row>
            </Col>
            {this.props.formAction === "update" && (
              <Col sm="6" md="9">
                <label className="col-form-label">Input Captions</label>
                <div className="d-flex align-items-center">
                  <TextField
                    placeholderLabel="Enter JSON"
                    defaultValue={this.state.formData.captions}
                    onChange={val => {
                      if (val.trim()) {
                        if (!isJson(val.trim())) {
                          this.setState({
                            formData: {
                              ...this.state.formData,
                              captions: [],
                            },
                          })
                          toastr.remove()
                          return toastr.error("Enter valid JSON")
                        } else {
                          this.setState({
                            formData: {
                              ...this.state.formData,
                              captions: val.trim(),
                            },
                          })
                        }
                      }
                    }}
                    className="creator-form"
                    style={{ width: "100%" }}
                  />
                  <Button
                    role="button"
                    style={{ width: "fit-content" }}
                    className="btn btn-info d-flex mx-4"
                    onClick={() => {
                      this.props.history.push(
                        `/cmsVideoGreetings/manage?item_uuid=${this.props.image.content_uuid}`
                      )
                    }}
                  >
                    Preview
                  </Button>
                </div>
              </Col>
            )}
          </Row>
          <Row className="mb-3">
            <Divider>Image Details</Divider>
            {this.state.formData.image_details &&
              this.state.formData.image_details
                .filter(img => !img.is_deleted)
                .map((imageData, index) => {
                  return (
                    <div key={index + imageData.image_uuid}>
                      {this._renderImageDetails(imageData, index)}
                    </div>
                  )
                })}
            <div className="d-flex justify-content-start mt-3">
              <div
                className="btn btn-success"
                onClick={() => this._addImages()}
                role="button"
              >
                Add Image
              </div>
            </div>
          </Row>

          <div className="d-flex justify-content-end mt-3">
            <div
              className="btn btn-success"
              onClick={this._onSubmit}
              role="button"
            >
              {this.props.formAction === "update" ? `Update` : `Create`}
            </div>
            <Button
              color="danger"
              size="default"
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                return
              }}
              onClick={() => this.props.history.push(`/image/browse`)}
              className="mx-2"
            >
              Cancel
            </Button>
          </div>
        </CardBody>
      </Card>
    )
  }

  _deleteImage = (image, index) => {
    let modifiedImageDetails = this.state.formData.image_details.map(
      (img, i) => {
        if (i === index) {
          return { ...img, is_deleted: true }
        } else return img
      }
    )
    this.setState({
      formData: {
        ...this.state.formData,
        image_details: modifiedImageDetails,
      },
    })
  }

  _renderImageDetails = (imageData, imageIndex) => {
    return (
      <Row>
        <Col lg="6">
          <div>
            <div>
              <label className="col-form-label">Upload Image</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  const img = new Image()
                  img.onload = () => {
                    const height = img.height
                    const width = img.width
                    this._handleHeightandWidthFieldChange(
                      "width",
                      width,
                      "height",
                      height,
                      imageData.id,
                      imageIndex
                    )
                  }
                  img.src = URL.createObjectURL(e.target.files[0])
                  this._handleFileUpload(imageData.id, e.target.files[0])
                }}
              />
            </div>
          </div>
        </Col>
        <Col className="align-self-end mx-5">
          <Row>
            <Col lg="4">
              {imageData.orig_image && (
                <Card className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                  <div>
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          src={imageData.orig_image}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>
              )}
            </Col>
            {this.props.formAction === "update" && (
              <Col lg="4" className="mt-4">
                <span
                  className="delete-icon"
                  onClick={() => this._deleteImage(imageData, imageIndex)}
                >
                  <i className="mdi mdi-delete"></i>
                </span>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    )
  }

  _render = () => {
    return (
      <Auxiliary loading={this.props.loading}>
        {this._renderUpsertForm()}
        {this.state.selectInputZones && (
          <SearchAndSelect
            type="ZONE"
            visible={this.state.selectInputZones}
            onClose={() => this.setState({ selectInputZones: false })}
            onAdd={arr =>
              this.setState({
                formData: { ...this.state.formData, input_zones: arr },
              })
            }
            zoneId={this.state.formData.input_zones}
            selectedArr={_.uniq(this.state.formData.input_zones.map(a => a))}
            allZoneList={this.props.zoneList.map(zone => {
              return {
                ...zone,
                value: zone.zone_uuid,
                label: zone.title || zone.sub_title,
              }
            })}
          />
        )}
        {this.state.selectActiveZoneModal && (
          <SearchAndSelect
            type="ZONE"
            visible={this.state.selectActiveZoneModal}
            onClose={() => this.setState({ selectActiveZoneModal: false })}
            onAdd={arr =>
              this.setState({
                formData: { ...this.state.formData, active_zones: arr },
              })
            }
            zoneId={this.state.active_zones}
            selectedArr={_.uniq(this.state.formData.active_zones.map(a => a))}
            allZoneList={this.props.zoneList.map(zone => {
              return {
                ...zone,
                value: zone.zone_uuid,
                label: zone.title || zone.sub_title,
              }
            })}
          />
        )}
      </Auxiliary>
    )
  }
}
