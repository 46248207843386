import Axios from "axios"
import toastr from "toastr"

export const requestContestListByFilters = async ({ payload }) => {
  try {
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    _.unset(payload, "currentPage")
    let data = await Axios.post(
      `/api/contest/getcontestListByFilter?offset=${offset}`,
      { filters: payload }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertContest = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/contest/upsert`, payload)
    toastr.success("Action Successfull!!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteBanner = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/contest/delete/banner`, payload)
    toastr.success("Action Successfull!!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetContestDetails = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/contest/details?id=${payload.contestUuid}`,
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateCategory = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/contest/category/update`, payload)
    toastr.success("Action Successfull!!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestAddCategory = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/contest/category/add`, payload)
    toastr.success("Action Successfull!!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestAddSubCategory = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/contest/subcategory/add`, payload)
    toastr.success("Action Successfull!!")
    return data
  } catch (error) {
    throw error
  }
}
