import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import QueryString from "query-string"
import {
  campaignCreate,
  campaignUpdate,
  campaignDelete,
  resetState,
  getCampaign,
} from "../../appRedux/slices/campaign"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import { Row, Col, Button } from "react-bootstrap"
import { Pagination, Tag } from "antd"
import { Card, CardBody } from "reactstrap"
import CampaignCard from "./campaignCard"
import UpsertForm from "./upsertForm"
import "./styles.css"
import { bulkUploadCampaign } from "clientServices/campaignService"

class ManageCampaign extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      size:
        this.props.queryParams && this.props.queryParams["size"]
          ? this.props.queryParams["size"]
          : 10,
      from:
        this.props.queryParams && this.props.queryParams["from"]
          ? this.props.queryParams["from"]
          : 1,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
    },
    showBulkUploadForm: false,
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(getCampaign(_.deepClean(this.state.filters)))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  toggleUploadBulk = arg => {
    this.setState({ showBulkUploadForm: true })
  }

  _onUpload = async () => {
    const formData = new FormData()
    formData.append("file", this.state.csvFile)
    await bulkUploadCampaign(formData)
    this.setState({ showBulkUploadForm: false })
  }

  _onSubmit = formData => {
    this.setState({ showForm: false })
    if (this.state.action === "create") {
      this.props.dispatch(campaignCreate(formData))
    } else
      this.props.dispatch(
        campaignUpdate({
          id: this.state.selectedCampaign.campaign_uuid,
          data: formData,
        })
      )
    this.props.dispatch(getCampaign(_.deepClean(this.state.filters)))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.dispatch(getCampaign(_.deepClean(newFilters)))
    })
  }

  _bulkUploadForm = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="6">
              <label className="col-form-label">Upload CSV File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this.setState({
                    csvFile: e.target.files[0],
                  })
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm="12">
              <div className="d-flex justify-content-end">
                <Button
                  className="font-16 btn-block btn btn-primary"
                  variant="primary"
                  onClick={this._onUpload}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="danger"
                  size="default"
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showBulkUploadForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showForm && (
          <div className="d-flex justify-content-end mb-2">
            <Button
              color="primary"
              className="font-16 btn-block btn btn-primary mx-1"
              onClick={this.toggleUploadBulk}
            >
              <i className="fas fa-arrow-up me-1" />
              Upload
            </Button>
          </div>
        )}
        {this.state.showBulkUploadForm && this._bulkUploadForm()}
        {!this.state.showForm && (
          <>
            <div className="campaign-card-container">
              {this.props.campaignList?.data &&
                this.props.campaignList.data.length > 0 &&
                this.props.campaignList.data.map((campaign, index) => {
                  return (
                    <CampaignCard
                      key={`${campaign.campaign_uuid}+${index}`}
                      campaign={campaign}
                      onEditClick={selectedCampaign =>
                        this.setState({ showForm: true, selectedCampaign })
                      }
                      onDeleteClick={(selectedCampaign, action) => {
                        this.setState({ selectedCampaign, action }, () => {
                          this.props.dispatch(
                            campaignDelete(selectedCampaign.campaign_uuid)
                          )
                          this.props.dispatch(
                            getCampaign(_.deepClean(this.state.filters))
                          )
                        })
                      }}
                    />
                  )
                })}
            </div>
            {this.props.totalCampaign > 0 && (
              <div className="d-flex justify-content-end mb-3 pagination_position">
                <Pagination
                  style={{ marginTop: "1rem" }}
                  onChange={this._onPageChange}
                  defaultCurrent={this.state.filters.currentPage}
                  total={
                    this.props.totalCampaign < 10000
                      ? this.props.totalCampaign
                      : 10000
                  }
                  pageSize={this.state.filters.pageSize}
                  showSizeChanger={false}
                />
              </div>
            )}
          </>
        )}
        {this.state.showForm && (
          <UpsertForm
            currentUser={this.props.currentUser}
            action={this.state.action}
            onSubmit={formData => this._onSubmit(formData)}
            campaign={this.state.selectedCampaign}
            onCancel={() =>
              this.setState({
                showForm: false,
                selectedCampaign: {},
                action: "",
              })
            }
          />
        )}
      </Auxiliary>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    contentList: _.get(store, "content.content"),
    total: _.get(store, "trending.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "campaign.loading"),
    trendingEntityList: _.get(store, "trending.trendingEntityList"),
    audioDetails: _.get(store, "trending.audioDetails"),
    campaignList: _.get(store, "campaign.campaign"),
    totalCampaign: _.get(store, "campaign.totalCampaign"),
    refetch: _.get(store, "campaign.refetchData"),
  }
}
export default connect(mapStateToProps)(withRouter(ManageCampaign))
