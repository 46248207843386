import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Divider, Input, Pagination, Tabs } from "antd"
import { Button } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import UpsertEntityForm from "./upsertEntity"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import {
  getEntities,
  resetState,
  upsertCollection,
  upsertEntity,
} from "../../appRedux/slices/zeroSearch"
import EntityCard from "../../components/Discovery/entityCard"
import Chips from "components/CommonFilter/chips"
import {
  manageMusicPlaylistFilters,
  NO_PERMISSION_MESSAGE,
} from "constants/uiConstants"

import toastr from "toastr"

const { TabPane } = Tabs

const COLLECTION_PROPERTIES = {
  MUSIC_PLAYLIST: {
    cardClass: "discovery-card-container",
    icon: "bx bxs-playlist",
    name: "Music Playlist",
  },
}

const FILTER_KEYS = [
  {
    key_name: "name",
    display_name: "Name",
  },
  {
    key_name: "playListId",
    display_name: "Playlist Id",
  },
]

class ManageMusicPlaylist extends ErrorBoundComponent {
  state = {
    showModal: false,
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      type:
        this.props.queryParams && this.props.queryParams["type"]
          ? this.props.queryParams["type"]
          : "MUSIC_PLAYLIST",
      name:
        this.props.queryParams && this.props.queryParams["name"]
          ? this.props.queryParams["name"]
          : "",
      playListId:
        this.props.queryParams && this.props.queryParams["playListId"]
          ? this.props.queryParams["playListId"]
          : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getEntities({ filters: _.deepClean(filters) }))
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getEntities({ filters: _.deepClean(filters) }))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({ filters: newFilters })
  }

  _searchEntity = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/musicPlaylist/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(getEntities({ filters: _.deepClean(filters) }))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/musicPlaylist/manage?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      ),
        this.props.dispatch(getEntities({ filters: _.deepClean(newFilters) }))
    })
  }

  _onSubmit = async payload => {
    let type
    type = "playlist"

    let response
    if (type === "Named Collection") {
      response = await this.props.dispatch(
        upsertCollection({ data: payload, action: this.state.action })
      )
    } else {
      response = await this.props.dispatch(
        upsertEntity({
          data: payload,
          type,
          action: this.state.action,
        })
      )
    }
    this.setState({ showModal: false, entityData: null })
  }

  _modal = () => {
    return (
      <div className="view_container">
        <UpsertEntityForm
          pageId={this.state.currentPageId}
          type={
            this.props.queryParams && this.props.queryParams.type
              ? this.props.queryParams.type
              : "MUSIC_PLAYLIST"
          }
          onSubmit={this._onSubmit}
          data={this.state.entityData}
          action={this.state.action}
          onCancel={() => this.setState({ showModal: false })}
        />
      </div>
    )
  }

  _onEdit = entityData => {
    this.setState({ entityData, showModal: true, action: "update" })
  }

  _getTitle = () => {
    const type = this.props.queryParams.type
    const typeToLowerCase = type.replace(/_|-|\./g, " ").toLowerCase()
    return (
      <h4>
        {typeToLowerCase.charAt(0).toUpperCase() + typeToLowerCase.slice(1)}
      </h4>
    )
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    this.setState({
      filters: newFilters,
    })
  }

  _searchCollection = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/musicPlaylist/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    ),
      this.props.dispatch(getEntities({ filters: _.deepClean(filters) }))
  }

  _closeFilters = () => {
    this._searchCollection()
    this.setState({
      showFilters: false,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: manageMusicPlaylistFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/musicPlaylist/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getEntities({ filters: _.deepClean(this.state.filters) })
        )
      }
    )
  }

  _buttons = () => {
    return (
      <div className="d-flex justify-content-end my-2">
        <div className="d-flex">
          <Button
            color="primary"
            className="d-flex"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes(
                  "EDIT_MUSIC_PLAYLIST"
                )
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.setState({ showModal: true, action: "create" })
            }}
          >
            <i className="fa fa-plus mx-1 my-1" />
            Add
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Auxiliary
          loading={this.props.loading}
          error={_.get(this.props, "common.error")}
        >
          {!this.state.showModal && (
            <React.Fragment>
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                type="manage_music_playlist"
                toggleFilters={this._toggleFilters}
                filterKeys={FILTER_KEYS}
              />
              {this._buttons()}
              <div className="discovery-card-container">
                {this.props.entityList &&
                  this.props.entityList.map((entity, i) => {
                    return (
                      <EntityCard
                        data={entity}
                        locationList={this.props.locationList}
                        onEditClick={this._onEdit}
                        hideDelete={true}
                        pageSource="manageEntity"
                        currentUser={this.props.currentUser}
                        location={this.props.location}
                      />
                    )
                  })}
              </div>
              {this.props.entityList && this.props.entityList.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Pagination
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                    onChange={this._onPageChange}
                    defaultCurrent={this.state.filters.currentPage}
                    total={this.props.total < 10000 ? this.props.total : 10000}
                    pageSize={this.state.filters.pageSize}
                    showSizeChanger={false}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </Auxiliary>
        {/* <Tabs
          onChange={key => {
            const newFilters = _.cloneDeep(this.state.filters)
            newFilters["type"] = key
            newFilters["name"] = undefined
            this.setState(
              {
                filters: newFilters,
              },
              () => this._searchEntity()
            )
          }}
          type="card"
          activeKey={this.state.filters.type}
        >
          {Object.keys(COLLECTION_PROPERTIES).map(key => {
            return (
              <TabPane
                key={key}
                tab={
                  <span>
                    <span>
                      <i
                        className={`${COLLECTION_PROPERTIES[key].icon} mx-2`}
                      />
                    </span>
                    <span className="mb-2">
                      {COLLECTION_PROPERTIES[key].name}
                    </span>
                  </span>
                }
                className="font-size-15"
              >
                <Auxiliary
                  loading={this.props.loading}
                  error={_.get(this.props, "common.error")}
                >
                  {!this.state.showModal && (
                    <React.Fragment>
                      {this._filters()}
                      <div className="discovery-card-container">
                        {this.props.entityList &&
                          this.props.entityList.map((entity, i) => {
                            return (
                              <EntityCard
                                data={entity}
                                locationList={this.props.locationList}
                                onEditClick={this._onEdit}
                                hideDelete={true}
                                pageSource="manageEntity"
                              />
                            )
                          })}
                      </div>
                      {this.props.entityList &&
                        this.props.entityList.length > 0 && (
                          <div className="d-flex justify-content-end">
                            <Pagination
                              style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                              }}
                              onChange={this._onPageChange}
                              defaultCurrent={this.state.filters.currentPage}
                              total={
                                this.props.total < 10000
                                  ? this.props.total
                                  : 10000
                              }
                              pageSize={this.state.filters.pageSize}
                              showSizeChanger={false}
                            />
                          </div>
                        )}
                    </React.Fragment>
                  )}
                </Auxiliary>
              </TabPane>
            )
          })}
        </Tabs> */}
        {this.state.showModal && this._modal()}
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zeroSearch.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "zeroSearch.loading"),
    entityList: _.get(store, "zeroSearch.entities"),
    refetch: _.get(store, "zeroSearch.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageMusicPlaylist)
