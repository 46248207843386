import React from "react"
import logo from "../../assets/images/loader.svg"

const Spinner = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <img src={logo} alt="" className="rounded-circle" height="125" />
    </div>
  )
}

export default Spinner
