import React from "react"
import { message, Upload } from "antd"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import { EditOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons"
import ValidatorForm, {
  ChipsInput,
  Select,
  Switch,
  TextField,
} from "../ValidatorForm"
import { getMimeTypes } from "appRedux/slices/stickers"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import "./stickers.style.css"

class UpsertStickersForm extends ErrorBoundComponent {
  state = {
    formData: {
      type: null,
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.getMimeTypes({ selectedFilter: this.props.stickersFor })
    }
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value
    this.setState({ formData })
  }

  _onRemoveFile = type => {
    switch (type) {
      case "stickerUrl":
        this.setState({
          stickerImageFileList: [],
          stickerImage: null,
        })
        break
      case "thumbnailUrl":
        this.setState({
          thumbnailImageFileList: [],
          thumbnailImage: null,
        })
        break
      default:
        break
    }
  }

  _uploadFile = (fileObj, type) => {
    if (fileObj.file) {
      switch (type) {
        case "stickerUrl":
          this.setState({
            stickerImageFileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            stickerImage: fileObj.file,
          })
          break
        case "thumbnailUrl":
          this.setState({
            thumbnailImageFileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            thumbnailImage: fileObj.file,
          })
          break
        default:
          return ""
      }
    }
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col sm={12} lg={6}>
            <TextField
              style={{ width: "100%" }}
              label={"Name"}
              placeholderLabel="Name"
              field={"stickerName"}
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "stickerAsset.stickerName")
                  : ""
              }
              className="creator-form"
            />
          </Col>
          {this.props.stickersFor === "CAMERA" && (
            <Col sm={12} lg={6}>
              <TextField
                style={{ width: "100%" }}
                label={"Asset ID"}
                placeholderLabel="Asset ID"
                field={"assetId"}
                disabled={this.props.formAction === "update"}
                defaultValue={
                  this.props.formAction === "update"
                    ? _.get(this.props, "stickerAsset.assetId")
                    : ""
                }
                className="creator-form"
              />
            </Col>
          )}
          {this.props.stickersFor === "CAMERA" && (
            <Col sm={12} md={6}>
              <ChipsInput
                label={"Tagged Videos"}
                placeholderLabel="Tagged Videos"
                field={"taggedVideos"}
                defaultValue={
                  this.props.formAction === "update"
                    ? _.get(this.props, "stickerAsset.taggedVideos")
                    : []
                }
                className="creator-form"
              />
            </Col>
          )}
          <Col sm={12} lg={6}>
            <Select
              label="Mime Type"
              disabled={this.props.formAction === "update"}
              placeholderLabel="Mime Type"
              field="mimeType"
              className="creator-form"
              onChange={value => this._handleFieldChange("mimeType", value)}
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "stickerAsset.mimeType")
                  : null
              }
              options={this.props.mimeTypes.map(mime => ({
                label: mime,
                value: mime,
              }))}
            />
          </Col>
          <Col sm={12} lg={6}>
            <Switch
              label="Is Active"
              field="isActive"
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "stickerAsset.isActive")
                  : true
              }
              onChange={value => this._handleFieldChange("isActive", value)}
            />
          </Col>
          <Col sm={12} lg={6} style={{ marginTop: "1rem" }}>
            <Upload
              showUploadList={true}
              listType="picture"
              // previewFile={true}
              onRemove={() => this._onRemoveFile("stickerUrl")}
              defaultFileList={this.state.stickerImageFileList}
              fileList={this.state.stickerImageFileList}
              customRequest={fileObj => this._uploadFile(fileObj, "stickerUrl")}
            >
              {this.state.stickerImageFileList &&
              this.state.stickerImageFileList.length >= 1 ? null : (
                <Button>
                  <UploadOutlined />{" "}
                  {this.props.formAction !== "update"
                    ? `Upload Sticker Image`
                    : `Update Sticker Image`}{" "}
                </Button>
              )}
            </Upload>
          </Col>
          <Col
            sm={12}
            lg={6}
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            <Upload
              showUploadList={true}
              listType="picture"
              // previewFile={true}
              onRemove={() => this._onRemoveFile("thumbnailUrl")}
              defaultFileList={this.state.thumbnailImageFileList}
              fileList={this.state.thumbnailImageFileList}
              customRequest={fileObj =>
                this._uploadFile(fileObj, "thumbnailUrl")
              }
            >
              {this.state.thumbnailImageFileList &&
              this.state.thumbnailImageFileList.length >= 1 ? null : (
                <Button>
                  <UploadOutlined />{" "}
                  {this.props.formAction !== "update"
                    ? `Upload Thumbnail Image`
                    : `Update Thumbnail Image`}{" "}
                </Button>
              )}
            </Upload>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                color="primary"
                size="default"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                {this.props.formAction === "update" ? "Update" : "Create"}
              </Button>
              <Button
                color="danger"
                size="default"
                disabled={this.state.actionLoading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (
      _.isEmpty(this.state.formData.mimeType) &&
      this.props.formAction === "create"
    ) {
      message.error("Please Select mimeType!!")
      return
    }

    if (
      _.isEmpty(this.state.stickerImage) &&
      this.props.formAction === "create"
    ) {
      message.error("Please upload Sticker Image!!")
      return
    }

    if (
      _.isEmpty(this.state.thumbnailImage) &&
      this.props.formAction === "create"
    ) {
      message.error("Please upload Thumbnail Image!!")
      return
    }

    if (this.props.stickersFor === "CAMERA" && _.isEmpty(formData.assetId)) {
      message.error("Please enter Asset ID!!")
      return
    }

    const data = new FormData()

    if (!_.isEmpty(this.state.stickerImage)) {
      data.append("stickerUrl", this.state.stickerImage)
    }

    if (!_.isEmpty(this.state.thumbnailImage)) {
      data.append("thumbnailUrl", this.state.thumbnailImage)
    }

    if (!_.isEmpty(formData.stickerName)) {
      data.append("stickerName", formData.stickerName)
    }

    if (!_.isEmpty(formData.assetId)) {
      data.append("assetId", formData.assetId)
    }

    if (!_.isEmpty(formData.taggedVideos)) {
      data.append("taggedVideos", formData.taggedVideos.join(","))
    }

    Object.keys(this.state.formData).forEach(key => {
      if (
        this.state.formData[key] !== undefined &&
        this.state.formData[key] !== ""
      ) {
        data.append(key, this.state.formData[key])
      }
    })

    this.props.onSubmit(data)
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    mimeTypes: _.get(store, "stickers.mimeTypes"),
    loading: _.get(store, "stickers.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data.jotUserType"),
  }
}

export default connect(mapStateToProps, { getMimeTypes })(UpsertStickersForm)
