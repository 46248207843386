import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import QueryString from "query-string"
import { resetState } from "../../appRedux/slices/campaign"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import { Row, Col } from "react-bootstrap"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import TransactionTable from "./TransactionTable"
import {
  requestGetKycStatus,
  requestGetTransactionData,
} from "clientServices/kycService"
import "../Campaign/styles.css"

class ManageDashTransactions extends ErrorBoundComponent {
  state = {
    showList: false,
    kycData: {},
    giftData: {},
    redeemData: {},
    beneficiaryData: {},
    purchaseData: {},
    searchdetails: {
      type: "",
    },
    loader: false,
    page: 0,
    kycFilters: {
      userUuid: "",
      kycNumber: "",
    },
    showFilters: false,
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      let data = {}
      let kycdata = await requestGetKycStatus()
      try {
        data = await requestGetTransactionData({})
      } catch {
        data = {}
      }
      this.setState({
        ...this.state,
        ...{ kycData: kycdata },
        ...{
          giftData: data.gift
            ? {
                failed: data.gift.failed_count,
                in_progress: data.gift.in_progress_count,
                success: data.gift.success_count,
                total: data.gift.total_count,
              }
            : {},
        },
        ...{
          redeemData: data.redeem
            ? {
                failed: data.redeem.failed_count,
                in_progress: data.redeem.in_progress_count,
                success: data.redeem.success_count,
                total: data.redeem.total_count,
              }
            : {},
        },
        ...{
          beneficiaryData: data.beneficiary
            ? {
                failed: data.beneficiary.failed_count,
                in_progress: data.beneficiary.in_progress_count,
                success: data.beneficiary.success_count,
                total: data.beneficiary.total_count,
              }
            : {},
        },
        ...{
          purchaseData: data.purchase
            ? {
                failed: data.purchase.failed_count,
                in_progress: data.purchase.in_progress_count,
                success: data.purchase.success_count,
                total: data.purchase.total_count,
              }
            : {},
        },
      })
      this.props.history.push(`/dashboards/transaction`)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }
  setLoader = val => {
    this.setState({ loader: val })
  }
  setPage = page => {
    this.setState({ ...this.state, page })
  }

  buttonClick = (details, status) => {
    this.setState({
      ...this.state,

      ...{
        searchdetails: { type: details.name, status },
        showList: true,
        page: 0,
        kycFilters: {
          userUuid: "",
          kycNumber: "",
        },
      },
    })
  }
  card = details => {
    return (
      <React.Fragment>
        <CardContent>
          <Typography
            style={{ textAlign: "center" }}
            variant="h5"
            component="div"
          >
            {details.name}
          </Typography>
          <br />
          <Typography variant="body1">
            <Button
              onClick={() => this.buttonClick(details)}
              variant="outlined"
              style={
                details.name == this.state.searchdetails.type &&
                this.state.searchdetails.status == undefined
                  ? {
                      borderColor: "#1976d2",
                      backgroundColor: "#1976d2",
                      color: "white",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
                  : {
                      borderColor: "#1976d2",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
              }
            >
              {" "}
              Total: {details.total}
            </Button>

            <br />
            <Button
              onClick={() => this.buttonClick(details, "SUCCESS")}
              style={
                details.name == this.state.searchdetails.type &&
                this.state.searchdetails.status == "SUCCESS"
                  ? {
                      borderColor: "green",
                      backgroundColor: "green",
                      color: "white",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
                  : {
                      color: "green",
                      borderColor: "green",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
              }
              variant="outlined"
            >
              {" "}
              Success: {details.success}
            </Button>

            <br />

            <Button
              onClick={() => {
                this.setState(() => this.buttonClick(details, "IN_PROGRESS"))
              }}
              color="warning"
              style={
                details.name == this.state.searchdetails.type &&
                this.state.searchdetails.status == "IN_PROGRESS"
                  ? {
                      borderColor: "#ed6c02",
                      backgroundColor: "#ed6c02",
                      color: "white",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
                  : {
                      padding: "5px",
                      color: "#ed6c02",
                      borderColor: "#ed6c02",
                      width: "100%",
                      marginBottom: "5px",
                    }
              }
              variant="outlined"
            >
              {" "}
              In Progress: {details.in_progress}
            </Button>

            <br />
            <Button
              onClick={() => this.buttonClick(details, "FAILED")}
              style={
                details.name == this.state.searchdetails.type &&
                this.state.searchdetails.status == "FAILED"
                  ? {
                      borderColor: "red",
                      backgroundColor: "red",
                      color: "white",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
                  : {
                      color: "red",
                      borderColor: "red",
                      padding: "5px",
                      width: "100%",
                    }
              }
              variant="outlined"
            >
              {" "}
              Failed: {details.failed}
            </Button>
          </Typography>
        </CardContent>
      </React.Fragment>
    )
  }

  setFilters = filters => {
    this.setState({
      kycFilters: filters,
    })
  }
  toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }
  render() {
    return (
      <Auxiliary error={_.get(this.props, "common.error")}>
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col lg="2" md="8">
            <Box sx={{ boxShadow: 1 }}>
              <Card variant="outlined">
                {this.card({ name: "GIFT", ...this.state.giftData })}
              </Card>
            </Box>
          </Col>
          <Col lg="2" md="8">
            <Box sx={{ boxShadow: 1 }}>
              <Card variant="outlined">
                {this.card({ name: "REDEEM", ...this.state.redeemData })}
              </Card>
            </Box>
          </Col>
          <Col lg="2" md="8">
            <Box sx={{ boxShadow: 1 }}>
              <Card variant="outlined">
                {this.card({ name: "KYC", ...this.state.kycData })}
              </Card>
            </Box>
          </Col>
          <Col lg="2" md="8">
            <Box sx={{ boxShadow: 1 }}>
              <Card variant="outlined">
                {this.card({
                  name: "BENEFICIARY",
                  ...this.state.beneficiaryData,
                })}
              </Card>
            </Box>
          </Col>
          <Col lg="2" md="8">
            <Box sx={{ boxShadow: 1 }}>
              <Card variant="outlined">
                {this.card({ name: "PURCHASE", ...this.state.purchaseData })}
              </Card>
            </Box>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            {this.state.showList && (
              <TransactionTable
                style={this.state.loader ? { display: "hidden" } : {}}
                loader={this.state.loader}
                searchdetails={this.state.searchdetails}
                page={this.state.page}
                setPage={this.setPage}
                filters={
                  this.state.searchdetails.type == "KYC"
                    ? this.state.kycFilters
                    : {}
                }
                setLoader={this.setLoader}
                setFilters={this.setFilters}
                toggleFilters={this.toggleFilters}
                showFilters={this.state.showFilters}
              ></TransactionTable>
            )}

            {/* {this.state.loader && <Auxiliary
        loading={this.state.loader}></Auxiliary>} */}
          </Col>
        </Row>
      </Auxiliary>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,

    total: _.get(store, "trending.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "campaign.loading"),
    trendingEntityList: _.get(store, "trending.trendingEntityList"),

    refetch: _.get(store, "campaign.refetchData"),
  }
}
export default connect(mapStateToProps)(withRouter(ManageDashTransactions))
