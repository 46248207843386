import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Media,
  Row,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Images
import profile1 from "assets/images/profile-img.png"

// import charts
import images from "assets/images"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import BootstrapTable from "react-bootstrap-table-next"

const ContactsProfile = props => {
  const { data: userProfile, onGetUserProfile } = props

  const userOptions = {
    1: "Super Admin",
    2: "Admin",
    3: "Content Manager", // Create Update Challem, audio and content
    4: "Moderator", // Requires permissions to be updated in the users table and same reads to be handled at routes
    5: "Ads Ops", // For source Type and sponsor text in challenge and audio
    7: "Internal Moderator",
  }
  // eslint-disable-next-line no-unused-vars
  const [miniCards, setMiniCards] = useState([
    {
      title: "User Status",
      iconClass: "",
      text: userProfile.userStatus === 1 ? "Active" : "Inactive",
    },
    {
      title: "User Type",
      iconClass: "",
      text: userOptions[userProfile.jotUserType],
    },
    { title: "User Id", iconClass: "", text: userProfile.userId },
  ])

  const MiniCards1 = (title, text, iconClass) => {
    return (
      <React.Fragment>
        <Col md="4">
          <Card className="mini-stats-wid">
            <CardBody>
              <Media>
                <Media body>
                  <p className="text-muted fw-medium mb-2">{title}</p>
                  <h4 className="mb-0">{text}</h4>
                </Media>
              </Media>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">CMS User Profile</h5>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profile1} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="10">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span
                          className={
                            "avatar-title rounded-circle bg-soft bg-" +
                            "primary" +
                            " text-" +
                            "primary" +
                            " font-size-16"
                          }
                        >
                          {userProfile.name.charAt(0)}
                        </span>
                      </div>
                      <h5 className="font-size-15">{userProfile.name}</h5>
                      <p className="text-muted mb-0">{userProfile.email}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Information</CardTitle>
                  <p className="text-muted mb-4">
                    {userProfile.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{userProfile.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{userProfile.email}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) =>
                  MiniCards1(
                    card.title,
                    card.text,
                    card.iconClass,
                    "_card_" + key
                  )
                )}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Permissions</CardTitle>
                  {userProfile.permissions &&
                    userProfile.permissions.map(permission => {
                      return <div>{permission}</div>
                    })}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Agency</CardTitle>
                  <div>{userProfile.agency}</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(ContactsProfile)
