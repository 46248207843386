import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import toastr from "toastr"
import QueryString from "query-string"
import {
  getPageEntities,
  resetState,
  upsertPageEntity,
} from "../../appRedux/slices/zeroSearch"
import { upgradeCameraAssetVersion } from "../../appRedux/slices/cameraAsset"
import { Input, Col, Row } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getLanguageData } from "appRedux/slices/uiConstants"
import { getLocationData } from "../../appRedux/slices/location"
import Auxiliary from "../../util/Auxiliary"
import { Pagination } from "antd"
import { Button } from "reactstrap"
import PageEntityCard from "components/Discovery/pageEntityCard"
import "./styles.css"
import EditPageForm from "./editPageForm"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"

class ManagePageEntities extends ErrorBoundComponent {
  state = {
    previewModal: false,
    currentPageId: null,
    hasEditPermission:
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("EDIT_DISCOVERY_PAGE"),
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      pageId:
        this.props.queryParams && this.props.queryParams["pageId"]
          ? this.props.queryParams["pageId"]
          : "",
      title:
        this.props.queryParams && this.props.queryParams["title"]
          ? this.props.queryParams["title"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let modifiedFilter = { ...this.state.filters, pageSize: 10000 }
      this.props.dispatch(getLocationData())
      this.props.dispatch(getLanguageData())
      this.props.dispatch(
        getPageEntities({
          pageEntityId: this.props.queryParams.pageId,
          filters: _.deepClean(modifiedFilter),
        })
      )
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      this.props.dispatch(
        getPageEntities({
          pageEntityId: this.props.queryParams.pageId,
          filters: _.deepClean(this.state.filters),
        })
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _searchEntities = () => {
    this.props.history.push(
      `/manage/pageEntities?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )

    this.props.dispatch(
      getPageEntities({
        pageEntityId: this.props.queryParams.pageId,
        filters: _.deepClean(this.state.filters),
      })
    )
    this.setState({
      title: this.state.filters.title,
    })
  }

  _onPageChange = (value, previewContent) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    let modifiedFilter = { ...this.state.newFilters, pageSize: 10000 }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/manage/pageEntities?${QueryString.stringify(
            _.deepClean(newFilters)
          )}`
        ),
          this.props.dispatch(
            getPageEntities({
              pageEntityId: this.props.queryParams.pageId,
              filters: _.deepClean(modifiedFilter),
            })
          )
      }
    )
  }

  onUpgradeVersion = () => {
    this.props.dispatch(upgradeCameraAssetVersion())
  }

  _onEditClick = (action, data) => {
    if (!this.state.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    this.setState({ action, showForm: true, selectedData: data })
  }

  _updatePageEntity = async (data, action) => {
    const result = await this.props.dispatch(upsertPageEntity({ action, data }))
    this.setState({ showForm: false })
  }

  _getFiltererList = () => {
    const sortedList = _.sortBy(this.props.pageEntities, ["view_order"])
    return sortedList.filter(entity => {
      if (
        entity.entityData &&
        (entity.entityData.title ||
          entity.entityData.name ||
          entity.entityData.sticker_name ||
          entity.entityData.asset_label ||
          entity.entityData.hashtag) &&
        (
          entity.entityData.title ||
          entity.entityData.name ||
          entity.entityData.sticker_name ||
          entity.entityData.asset_label ||
          entity.entityData.hashtag
        )
          .toLowerCase()
          .includes(this.state.filters.title.toLowerCase())
      ) {
        return entity
      }
    })
  }

  render() {
    const filteredEntityList = this._getFiltererList()
    const list = _(filteredEntityList)
      .drop((this.state.filters.currentPage - 1) * this.state.filters.pageSize)
      .take(this.state.filters.pageSize)
      .value()
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showForm && (
          <React.Fragment>
            <Row>
              <div className="d-flex justify-content-between my-2">
                <Col md="6">
                  <Button
                    color="primary"
                    onClick={() => {
                      if (
                        this.props.queryParams &&
                        this.props.queryParams["page"] === "zone"
                      ) {
                        this.props.history.push(`/zone/browse`)
                      } else {
                        this.props.history.push(`/manage/pages`)
                      }
                    }}
                  >
                    <i className="fa fa-angle-left" /> Go Back
                  </Button>
                </Col>
                <Col md="6">
                  <div className="d-flex align-items-center justify-content-end">
                    <Input
                      defaultValue={this.state.filters["title"]}
                      placeholder={"Title"}
                      onChange={e => {
                        const newFilters = _.cloneDeep(this.state.filters)
                        newFilters["title"] = e.target.value
                        this.setState({ filters: newFilters })
                      }}
                      allowClear={true}
                      name="title"
                    />
                    <Button
                      color="primary"
                      className="mx-2 d-flex"
                      onClick={() =>
                        this.props.history.push(
                          `/reorder/pageEntities?pageId=${this.props.queryParams.pageId}`
                        )
                      }
                    >
                      <span className="mx-1">
                        <i className="fas fa-sync" />
                      </span>{" "}
                      Reorder
                    </Button>
                    {this.state.filters.pageId === "main-tab-page" && (
                      <Button
                        color="primary"
                        className="d-flex mx-2"
                        onClick={() => {
                          if (
                            !this.props.currentUser?.permissions?.includes(
                              "EDIT_DISCOVERY_PAGE"
                            )
                          ) {
                            toastr.error(NO_PERMISSION_MESSAGE)
                            return
                          }
                          this.onUpgradeVersion()
                        }}
                      >
                        <span className="mx-1">
                          <i className="fa fa-chevron-up" />
                        </span>
                        Version
                      </Button>
                    )}
                    <Button
                      onClick={() =>
                        this.props.dispatch(
                          getPageEntities({
                            pageEntityId: this.props.queryParams.pageId,
                            filters: _.deepClean({
                              ...this.state.filters,
                              pageSize: 10000,
                            }),
                          })
                        )
                      }
                      color="primary"
                      className="d-flex"
                    >
                      <span className="mx-1">
                        <i className="fas fa-undo" />
                      </span>{" "}
                      Refresh
                    </Button>
                  </div>
                </Col>
              </div>
            </Row>
            <div className="discovery-card-container">
              {list &&
                list.map((entity, i) => {
                  return (
                    <div
                      role="button"
                      onClick={() =>
                        this.props.history.push(
                          `/manage/collectionEntities?collectionType=${entity.collection_type}&currentPage=1&pageSize=1&title=${entity.collection_uuid}`
                        )
                      }
                    >
                      <PageEntityCard
                        data={entity}
                        onEditClick={this._onEditClick}
                        hasEditPermission={this.state.hasEditPermission}
                      />
                    </div>
                  )
                })}
            </div>
            {this.props.pageEntities && this.props.pageEntities.length > 0 && (
              <div className="d-flex justify-content-end">
                <Pagination
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  onChange={this._onPageChange}
                  defaultCurrent={this.state.filters.currentPage}
                  total={
                    this._getFiltererList().length < 10000
                      ? this._getFiltererList().length
                      : 10000
                  }
                  pageSize={this.state.filters.pageSize}
                  showSizeChanger={false}
                />
              </div>
            )}
          </React.Fragment>
        )}
        {this.state.showForm && (
          <EditPageForm
            onSubmit={data => this._updatePageEntity(data, "update")}
            data={this.state.selectedData}
            formAction="edit"
            locationList={this.props.locationList}
            onCancel={() => this.setState({ showForm: false })}
            languageList={this.props.languageList}
            title={"Edit Collection"}
            hasEditPermission={this.state.hasEditPermission}
          />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    locationList: _.get(store, "locationReducer.locationList"),
    languageList: _.get(store, "uiConstants.languageList"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    pageList: _.get(store, "zeroSearch.pageList"),
    pageEntities: _.get(store, "zeroSearch.pageEntities"),
    refetchPageList: _.get(store, "zeroSearch.refetchPageList"),
    total: _.get(store, "zeroSearch.total"),
    loading: _.get(store, "zeroSearch.loading"),
    refetch: _.get(store, "zeroSearch.refetchData"),
  }
}

export default connect(mapStateToProps)(ManagePageEntities)
