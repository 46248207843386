import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { agencyNames, deskTypeOptions, LANGUAGES } from "constants/uiConstants"
// import { LANGUAGES } from "constants/uiConstants"

const { Option } = Select

const AgencyLimitDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Agency</h5>
          </Divider>
          <Select
            onChange={value => {
              props.handleFilterChange(value, "agency")
            }}
            style={{ width: "100%" }}
            defaultValue={props.filters["agency"]}
            placeholder="Agency"
            allowClear
          >
            {agencyNames.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
            ;
          </Select>
        </Col>

        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Type</h5>
          </Divider>
          <Select
            onChange={value => {
              props.handleFilterChange(value, "type")
            }}
            style={{ width: "100%" }}
            defaultValue={props.filters["type"]}
            placeholder="Type"
            allowClear
          >
            {deskTypeOptions.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
            ;
          </Select>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Language</h5>
          </Divider>
          <Select
            onChange={value => {
              props.handleFilterChange(value, "language")
            }}
            style={{ width: "100%" }}
            defaultValue={props.filters["language"]}
            placeholder="Language"
            allowClear
          >
            {LANGUAGES.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
            ;
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(AgencyLimitDrawer)
