import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import Questionnaire from "./questionnaire"
import { Col, Radio, Row } from "antd"
import { Button } from "reactstrap"
const { Group } = Radio
import { cloneDeep } from "lodash"
import QuestionCard from "./questionCard"
import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"
import { emotionsData } from "../../constants/taxonomy"
import ImageCard from "./imageCard"

class Emotions extends Questionnaire {
  state = {
    currentTab: "emotions",
    labelToDisplay: "Emotions",
  }

  componentDidMount() {
    let showOptions =
      this.props.selectedDetails?.taxonomy_emotions?.heart_touching
    this.setState({
      showOptions,
    })
  }

  _handleSelection = value => {
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    const indexTab = taxonomyRoutes.findIndex(item => item.key == "emotions")

    let emotionsData = ["romantic", "sad", "heartbreak"]

    selectedDetails["taxonomy_emotions"][value] = true
    emotionsData
      .filter(emotion => emotion !== value)
      .forEach(item => {
        selectedDetails["taxonomy_emotions"][item] = false
      })

    let stepCompleted = true

    if (stepCompleted) {
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    }
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.setSelectedDetails(selectedDetails)
  }

  _handleClick = value => {
    if (value === "YES") {
      this.setState({ showOptions: true })
      this.props.selectedDetails["taxonomy_emotions"]["heart_touching"] = true
      this.props.setSelectedDetails(this.props.selectedDetails)
    } else {
      this.setState({ showOptions: false })
      this.props.selectedDetails["taxonomy_emotions"]["heart_touching"] = false

      let emotionsData = ["romantic", "sad", "heartbreak"]
      this.props.selectedDetails["taxonomy_emotions"][value] = true
      emotionsData.forEach(item => {
        this.props.selectedDetails["taxonomy_emotions"][item] = false
      })

      let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)

      const indexTab = taxonomyRoutes.findIndex(item => item.key == "emotions")
      taxonomyRoutes[indexTab].completed = true
      this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
      this.props.setSelectedDetails(this.props.selectedDetails)
      this.props.setSelectedSection("city_tier")
    }
  }

  _renderOptions = () => {
    return (
      <React.Fragment>
        <QuestionCard
          question={{
            label: "Is this video depicting a Heart Touching emotion?",
            level: "L1",
            key: "is_emotion_present",
            sublevel: [
              {
                label: "Yes",
                key: "simple",
                value: "YES",
              },
              {
                label: "No",
                key: "simple",
                value: "NO",
              },
            ],
            partialSelected: this.state.showOptions,
          }}
          renderCardContent={() => {}}
          _handleSelection={e => this._handleClick(e.target.value)}
          partialSelected={this.state.showOptions}
        />
      </React.Fragment>
    )
  }

  // _renderOptions = () => {
  //   const borderClass =
  //     this.state.showOptions === undefined
  //       ? ""
  //       : this.state.showOptions
  //       ? "selectedCard"
  //       : "unSelectedCard"
  //   return (
  //     <div className="d-flex">
  //       {
  //         <Row
  //           className={`d-flex question-card text-center contentCard my-3 justify-content-around ${borderClass}`}
  //         >
  //           <Col className="mx-2 my-4 text-truncate-2">
  //             Is this video depicting a Heart Touching emotion?
  //           </Col>

  //           <Col
  //             role="button"
  //             sm="1"
  //             lg="1"
  //             className="my-3 mx-2"
  //             onClick={() => {
  //               this.setState({ showOptions: true })
  //               this.props.selectedDetails["taxonomy_emotions"][
  //                 "heart_touching"
  //               ] = true
  //               this.props.setSelectedDetails(this.props.selectedDetails)
  //             }}
  //             disabled={!this.props.enableButton}
  //           >
  //             <Button color="success">Yes</Button>
  //           </Col>
  //           <Col
  //             role="button"
  //             sm="1"
  //             lg="1"
  //             className="my-3 mx-2"
  //             onClick={() => {
  //               this.setState({ showOptions: false })
  //               this.props.selectedDetails["taxonomy_emotions"][
  //                 "heart_touching"
  //               ] = false
  //               let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)

  //               const indexTab = taxonomyRoutes.findIndex(
  //                 item => item.key == "emotions"
  //               )
  //               taxonomyRoutes[indexTab].completed = true
  //               this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
  //               this.props.setSelectedDetails(this.props.selectedDetails)
  //               this.props.setSelectedSection("violence")
  //             }}
  //           >
  //             {" "}
  //             <Button color="danger">No</Button>
  //           </Col>
  //         </Row>
  //       }
  //     </div>
  //   )
  // }

  _renderTitle = () => {
    return <div className="font-weight-semibold pt-3">Emotions</div>
  }

  _renderEmotions = () => {
    return (
      <div className="d-flex">
        {
          <Row
            className={`d-flex question-card text-center contentCard my-2 justify-content-around`}
          >
            <Col span={24} className="my-3">
              What kind of emotion is diplayed in the video?
            </Col>
            <Col span={24} className="d-flex flex-wrap mx-4">
              <Group
                style={{ display: "contents" }}
                onChange={e => this._handleSelection(e.target.value)}
                value={
                  this.props.selectedDetails.taxonomy_emotions.romantic
                    ? "romantic"
                    : this.props.selectedDetails.taxonomy_emotions.sad
                    ? "sad"
                    : this.props.selectedDetails.taxonomy_emotions.heartbeak
                    ? "heartbeak"
                    : ""
                }
              >
                <div className="topicRadioBtn">
                  <Radio value="romantic">Romantic</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="sad">Sad</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="heartbeak">Heartbreak</Radio>
                </div>
              </Group>
            </Col>
          </Row>
        }
      </div>
    )
  }

  _renderOptionCard = () => {
    return (
      <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
        {emotionsData.map(data => {
          let emotionData = data
          if (
            this.props.selectedDetails?.taxonomy_emotions &&
            this.props.selectedDetails.taxonomy_emotions[data.key]
          ) {
            emotionData.selected = true
          } else {
            emotionData.selected = false
          }
          return (
            <ImageCard
              data={emotionData}
              _handleSelection={data => this._handleSelection(data.key)}
              renderCardContent={this.props.renderCardContent}
            />
          )
        })}
      </div>
    )
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderTitle()}
        {this._renderOptions()}
        {this.state.showOptions && this._renderOptionCard()}
        {/* {this._renderQuestions()} */}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    currentData: _.get(store, "taxonomy.emotions"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(Emotions)
