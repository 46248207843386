import React from "react"
import { connect } from "react-redux"
import { message, Upload, DatePicker, Divider } from "antd"
import { Button, Row, Col } from "reactstrap"
import { UploadOutlined } from "@ant-design/icons"
import ValidatorForm, {
  TextField,
  Select,
  MultiSelect,
  Switch,
  DateTimePicker,
} from "../ValidatorForm"
import moment from "moment"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { createContent } from "../../appRedux/slices/content"
import Categories from "../../constants/categories.json"
import Locations from "../../constants/locations.json"
import { getZones } from "../../appRedux/slices/zone"
import {
  LANGUAGES,
  CATEGORIES,
  NO_PERMISSION_MESSAGE,
  CONTENT_TYPE,
  CTA_DEFAULT_DATA,
} from "../../constants/uiConstants"
import toastr from "toastr"
import _ from "lodash"
import "./content.styles.css"

import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import ReactSelect from "react-select"
import { getBrandCampaign } from "appRedux/slices/campaign"
const { Option } = Select
import dayjs from "dayjs"

class CreateContentForm extends ErrorBoundComponent {
  state = {
    stateLocationList: [],
    cityLocationList: [],
    formatType: "VIDEO",
    active_zones: [],
    input_zones: [],
  }

  componentDidMount() {
    let stateLocationList = []
    let cityLocationList = []
    this.props.locationList.map(location => {
      location.children.map(childrenLocation => {
        let childrenLocationObject = {}
        childrenLocationObject["label"] = childrenLocation.name
        childrenLocationObject["value"] = childrenLocation.dhTagId
        cityLocationList.push(childrenLocationObject)
      })
      let stateLocationObject = {}
      stateLocationObject["label"] = location.name
      stateLocationObject["value"] = location.dhTagId
      stateLocationList.push(stateLocationObject)
    })
    this.setState({
      stateLocationList,
      cityLocationList,
      src_type: this.props.currentUserType == 6 ? "ads" : "non_ads",
    })
    this.props.dispatch(
      getZones({
        currentPage: 1,
        pageSize: 10000,
      })
    )
    this.props.dispatch(getBrandCampaign({ currentPage: 1, pageSize: 10000 }))
  }
  formatOptionLabel({ campaign_uuid, title, campaign_name }) {
    return (
      <span>
        <i className="fa fa-users" aria-hidden="true"></i>
        &nbsp;
        <b>{campaign_name}</b>
      </span>
    )
  }

  _uploadVideo = fileObj => {
    if (fileObj.file) {
      let formatType = ""
      if (fileObj.file.type.includes("video")) {
        formatType = "VIDEO"
      } else if (
        fileObj.file.type.includes("image") &&
        this.state.src_type == "ads"
      ) {
        if (fileObj.file.name.includes(".gif")) {
          formatType = "GIF"
        } else {
          formatType = "IMAGE"
        }
      } else {
        this.setState({
          videoFile: "",
          videoFileList: [],
        })
        return toastr.error("Upload only video formats.")
      }

      this.setState({
        videoFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        videoFile: fileObj.file,
        formatType,
        display_profile_image: formatType === "VIDEO" ? "Y" : "N",
        allow_soundboard: formatType !== "VIDEO" ? "N" : "",
        allow_soundboard_reuse: formatType !== "VIDEO" ? "N" : "",
        hideSoundBar: formatType === "VIDEO" ? false : true,
      })
    }
  }

  _onVideoRemove = () => {
    this.setState({
      videoFileList: [],
      videoFile: null,
    })
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      [key]: file,
    })
  }

  _handleTemplateSelection = templateId => {
    const templateDetails = _.cloneDeep(
      this.props.videoTemplates.find(template => template.uuid === templateId)
    )
    if (templateDetails.primarySticker) {
      let primarySticker = this.props.videoAssets.find(
        asset => asset.uuid === templateDetails.primarySticker
      )
      templateDetails.primaryStickerType = primarySticker.stickerType
    }
    if (templateDetails.secondarySticker) {
      let secondarySticker = this.props.videoAssets.find(
        asset => asset.uuid === templateDetails.secondarySticker
      )
      templateDetails.secondaryStickerType = secondarySticker.stickerType
    }
    if (templateDetails.tertiarySticker) {
      let tertiarySticker = this.props.videoAssets.find(
        asset => asset.uuid === templateDetails.tertiarySticker
      )
      templateDetails.tertiaryStickerType = tertiarySticker.stickerType
    }
    this.setState({
      templateDetails,
    })
  }

  _handleStickerChange = (value, stickerType, type) => {
    if (type === "DATE") {
      value = value.tz("Asia/Calcutta").format("DD-MM-YYYY")
    }
    if (type === "LOCATION") {
      value = _.capitalize(value.split("(")[0])
    }

    const templateDetails = _.cloneDeep(this.state.templateDetails)
    templateDetails[stickerType] = value
    this.setState({ templateDetails })
  }

  _renderStickerLabels = (stickerType, stickerLabel) => {
    const label =
      stickerLabel === "primary"
        ? "primaryStickerLabel"
        : stickerLabel === "secondary"
        ? "secondaryStickerLabel"
        : "tertiaryStickerLabel"
    const fieldLabel =
      stickerLabel === "primary"
        ? "Primary Sticker"
        : stickerLabel === "secondary"
        ? "Secondary Sticker"
        : "Tertiary Sticker"
    if (stickerType === "LOCATION") {
      return (
        <Col sm="12" lg="6">
          <Select
            label={fieldLabel}
            placeholderLabel={fieldLabel}
            options={this.state.cityLocationList.map(city => ({
              label: city.label,
              value: city.label,
            }))}
            onChange={e => this._handleStickerChange(e, label, "LOCATION")}
          />
        </Col>
      )
    } else if (stickerType === "DATE") {
      return (
        <Col sm="12" lg="6">
          <div>
            <label>{fieldLabel}</label>
          </div>
          <DatePicker
            defaultValue={""}
            format="DD-MM-YYYY"
            style={{ padding: "8px", width: "100%" }}
            placeholder={fieldLabel}
            showTime={false}
            onChange={e => this._handleStickerChange(e, label, "DATE")}
          />
        </Col>
      )
    } else {
      return (
        <Col sm="12" lg="6">
          <TextField
            label={fieldLabel}
            placeholderLabel={fieldLabel}
            onChange={e => this._handleStickerChange(e, label)}
          />
        </Col>
      )
    }
  }

  _form = () => {
    const allCampaignList = []
    this.props?.campaignList?.data?.map(camp => {
      if (camp && camp.campaign_uuid && camp.status == "true") {
        allCampaignList.push({
          ...camp,
          value: camp.campaign_uuid,
          label: camp.campaign_name,
        })
      }
    })
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col sm="12" lg="6">
            <TextField
              label={"Title"}
              placeholderLabel="Content Title"
              field={"title"}
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" lg="6">
            <TextField
              label={"Tags"}
              placeholderLabel="Provide Hashtags"
              field={"hashtags"}
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          {
            <Col sm="12" lg="4">
              <Select
                label={"Language"}
                placeholderLabel="Select a Language"
                field="user_lang"
                validations={["required"]}
                errors={["This field is required"]}
                options={LANGUAGES}
              />
            </Col>
          }
          <Col sm="12" lg="4">
            <Select
              label={"Category"}
              placeholderLabel="Category"
              field="categoryTitle"
              validations={["required"]}
              errors={["This field is required"]}
              options={Categories.map(item => ({
                label: item.category_name,
                value: item.category_name,
              }))}
            />
          </Col>
          <Col sm="12" lg="4">
            <TextField
              label={"Created By"}
              placeholderLabel="Created By"
              field={"user_uuid"}
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" lg="6">
            <Select
              label={"Granularity"}
              placeholderLabel="Granularity"
              field="granularity"
              options={[
                { label: "CITY", value: "CITY" },
                { label: "STATE", value: "STATE" },
              ]}
              onChange={value => this.setState({ granularity: value })}
            />
          </Col>
          {/* TODO: Fetch the City and State list here and add restriction for Maximum 3 locations here */}
          <Col sm="12" lg="6">
            <MultiSelect
              label={"Locations"}
              placeholderLabel="Location"
              field={"location"}
              options={
                this.state.granularity == "CITY"
                  ? this.state.cityLocationList
                  : this.state.stateLocationList
              }
              maxTagsAccepted={3}
            />
          </Col>
          <Col sm="12" lg="6">
            <MultiSelect
              label={"Target Languages"}
              placeholderLabel="Target Languages"
              field={"cms_target_langs"}
              options={LANGUAGES.map(language => {
                return { label: language.label, value: language.value }
              })}
            />
          </Col>
          {(this.props.currentUserType == 6 ||
            this.props.currentUserType < 3) && (
            <>
              <Col sm="12" lg="6">
                <Select
                  label={"Source Type"}
                  field={"src_type"}
                  disabled={this.props.currentUserType > 2}
                  defaultValue={
                    this.props.currentUserType == 6 ? "ads" : "non_ads"
                  }
                  onChange={value => this.setState({ src_type: value })}
                  options={[
                    { label: "Ads", value: "ads" },
                    { label: "Non Ads", value: "non_ads" },
                  ]}
                />
              </Col>
              {this.state.src_type === "ads" && (
                <>
                  <Col sm="12" lg="6">
                    <TextField
                      label={"Sponsored Text"}
                      placeholderLabel="Sponsored Text"
                      field={"sponsored_text"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Select
                      label={"Duetable"}
                      field={"duetable"}
                      defaultValue={
                        this.props.data && this.props.data.duetable
                          ? this.props.data.duetable
                          : "D"
                      }
                      options={[
                        { label: "YES", value: "Y" },
                        { label: "NO", value: "N" },
                      ]}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch label={"Allow Download"} field={"allow_download"} />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch label={"Allow Share"} field={"allow_share"} />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Allow Like"}
                      field={"allow_like"}
                      defaultValue={true}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      key={this.state.allow_soundboard}
                      label={"Allow Soundboard"}
                      field={"allow_soundboard"}
                      disabled={this.state.hideSoundBar}
                      defaultValue={
                        this.state.allow_soundboard &&
                        this.state.allow_soundboard == "Y"
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      key={this.state.allow_soundboard_reuse}
                      label={"Allow Soundboard Reuse"}
                      field={"allow_soundboard_reuse"}
                      disabled={this.state.hideSoundBar}
                      defaultValue={
                        this.state.allow_soundboard_reuse &&
                        this.state.allow_soundboard_reuse == "Y"
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Allow Comments"}
                      field={"allow_commenting"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Display Profile Image"}
                      field={"display_profile_image"}
                      defaultValue={
                        this.state.display_profile_image &&
                        this.state.display_profile_image == "Y"
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Hashtag Clickable"}
                      field={"hashtag_clickable"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Allow Profile Name Click"}
                      field={"allow_profile_name_click"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Display Profile Name"}
                      field={"display_profile_name"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch label={"Display Tag"} field={"display_tag"} />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch label={"Display Camera"} field={"display_camera"} />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Display Home Tabs"}
                      field={"display_home_tabs"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Display Descending"}
                      field={"display_desc"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch label={"Display Mute"} field={"display_mute"} />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Display Effects"}
                      field={"display_effects"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Display Pages Info"}
                      field={"display_pages_info"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch label={"Allow Follow"} field={"allow_follow"} />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Display Use Asset"}
                      field={"display_use_asset"}
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Switch
                      label={"Display below comment box"}
                      field={"display_below_comment_box"}
                    />
                  </Col>
                </>
              )}
            </>
          )}
          <Col sm="12" lg="6">
            <DateTimePicker
              style={{ padding: "9px", width: "100%" }}
              label="Post Publish Time"
              field="post_publish_time_epoch"
              defaultValue={null}
            />
          </Col>

          <Col sm="12" lg="6">
            <DateTimePicker
              style={{ padding: "9px", width: "100%" }}
              label="Post Expiry Time"
              field="post_expiry_time"
              defaultValue={null}
            />
          </Col>
          <Col sm="12" lg="6">
            <Select
              label={"Type"}
              placeholderLabel="Type"
              field="ce_type"
              validations={["required"]}
              errors={["This field is required"]}
              options={CONTENT_TYPE}
            />
          </Col>
          <Col sm="12" lg="6">
            <label
              className="form-check-label"
              style={{ marginBottom: "10px" }}
            >
              Campaign Id
            </label>
            <ReactSelect
              value={
                allCampaignList?.filter(
                  camp => camp?.campaign_uuid === this.state?.campaign_uuid
                )[0] || ""
              }
              isClearable={true}
              style={{ width: "100%", height: "100px" }}
              isMulti={false}
              options={allCampaignList || []}
              formatOptionLabel={this.formatOptionLabel}
              onChange={value =>
                this.setState({
                  campaign_uuid: value?.campaign_uuid
                    ? value.campaign_uuid
                    : null,
                })
              }
              placeholder="Select Campaign"
            />
          </Col>

          <Col sm="12" lg="6">
            <Row>
              <Col sm="6" md="9">
                <TextField
                  label="Input Zones"
                  placeholderLabel="Select Input Zones"
                  field="input_zones"
                  value={this.state.input_zones}
                  onChange={val => {
                    if (val.trim()) {
                      this.setState({ input_zones: val.split(",") })
                    } else {
                      this.setState({ input_zones: [] })
                    }
                  }}
                  defaultValue={this.state.input_zones}
                  style={{ width: "95%" }}
                />
              </Col>
              <Col
                sm="6"
                md="3"
                style={{ alignSelf: "flex-end", marginBottom: "9px" }}
              >
                <Button
                  color="primary"
                  onClick={() =>
                    this.setState({
                      selectInputZones: true,
                    })
                  }
                >
                  Select
                </Button>
              </Col>
            </Row>
          </Col>
          <Col sm="12" lg="6">
            <Row>
              <Col sm="6" md="9">
                <TextField
                  label="Active Zones"
                  // disabled="true"
                  placeholderLabel="Select Active Zones"
                  field="active_zones"
                  value={this.state.active_zones}
                  onChange={val => {
                    if (val.trim()) {
                      this.setState({ active_zones: val.split(",") })
                    } else {
                      this.setState({ active_zones: [] })
                    }
                  }}
                  defaultValue={this.state.active_zones}
                  style={{ width: "95%" }}
                />
              </Col>
              <Col
                sm="6"
                md="3"
                style={{ alignSelf: "flex-end", marginBottom: "9px" }}
              >
                <Button
                  color="primary"
                  onClick={() =>
                    this.setState({
                      selectActiveZoneModal: true,
                    })
                  }
                >
                  Select
                </Button>
              </Col>
            </Row>
          </Col>
          <Col sm="12" lg="6">
            <Select
              label={"Template"}
              placeholderLabel="Template"
              field="templateId"
              options={this.props.videoTemplates?.map(template => ({
                value: template.uuid,
                label: template.name,
              }))}
              onChange={this._handleTemplateSelection}
            />
          </Col>
          {this.state.templateDetails?.primarySticker &&
            this._renderStickerLabels(
              this.state.templateDetails.primaryStickerType,
              "primary"
            )}
          {this.state.templateDetails?.secondarySticker &&
            this._renderStickerLabels(
              this.state.templateDetails.secondaryStickerType,
              "secondary"
            )}
          {this.state.templateDetails?.tertiarySticker &&
            this._renderStickerLabels(
              this.state.templateDetails.tertiaryStickerType,
              "tertiary"
            )}

          <Col sm="12" lg="12" style={{ marginTop: "10px" }}>
            <Upload
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              showUploadList={true}
              onRemove={this._onVideoRemove}
              defaultFileList={this.state.videoFileList}
              fileList={this.state.videoFileList}
              customRequest={this._uploadVideo}
            >
              <Button color="primary">
                <UploadOutlined /> Click to upload
              </Button>
            </Upload>
          </Col>
        </Row>
        <Divider className="m-auto">
          <div className="font-size-14 text-muted">Custom CTA</div>
        </Divider>
        <Row>
          <div className="d-flex align-items-center">
            <span className="me-2">Like CTA:</span>
            <Switch onChange={val => this.setState({ enableLikeCta: val })} />
          </div>
          {this.state?.enableLikeCta ? (
            <div>
              <Row>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Entity Type"}
                    field={"like_cta.type"}
                    value="LIKE"
                    disabled
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Title"}
                    placeholderLabel="Enter Title"
                    field={"like_cta.title"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Sub-Title"}
                    placeholderLabel="Enter Sub-Title"
                    field={"like_cta.subtitle"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">CTA Icon</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "cta_icon_like",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>
                <Divider className="m-auto">
                  <h5 className="mb-0 text-muted font-size-14">CTA</h5>
                </Divider>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"CTA Text"}
                    placeholderLabel="Enter Text"
                    field={"like_cta.cta.text"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"CTA Selected Text"}
                    placeholderLabel="Enter Selected Text"
                    field={"like_cta.cta.selected_text"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <Select
                    label={"CTA View Type"}
                    placeholderLabel="Enter View Type"
                    field="like_cta.cta.view_type"
                    options={[
                      { label: "Button with background", value: 1 },
                      { label: "Arrow", value: 2 },
                      { label: "Button with icon", value: 3 },
                      { label: "Text with arrow", value: 4 },
                      { label: "Only text", value: 5 },
                    ]}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">Button Icon</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "button_icon_like",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">
                      Button Selected Icon
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "button_selected_icon_like",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>

                <div className="text-muted font-size-13 mt-2 mb-2">
                  <span className="font-weight-semibold">Note:</span> If no
                  value selected, it will take the default value for some
                  fields.
                </div>
              </Row>
            </div>
          ) : null}
        </Row>
        <Row className="border-top">
          <div className="d-flex align-items-center">
            <span className="me-2">Comment CTA:</span>
            <Switch
              onChange={val => this.setState({ enableCommentCta: val })}
            />
          </div>
          {this.state?.enableCommentCta ? (
            <div>
              <Row>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Entity Type"}
                    field={"comment_cta.type"}
                    value="COMMENT"
                    disabled
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Title"}
                    placeholderLabel="Enter Title"
                    field={"comment_cta.title"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Sub-Title"}
                    placeholderLabel="Enter Sub-Title"
                    field={"comment_cta.subtitle"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">CTA Icon</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "cta_icon_comment",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>
                <Divider className="m-auto">
                  <h5 className="mb-0 text-muted font-size-14">CTA</h5>
                </Divider>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"CTA Text"}
                    placeholderLabel="Enter Text"
                    field={"comment_cta.cta.text"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"CTA Selected Text"}
                    placeholderLabel="Enter Selected Text"
                    field={"comment_cta.cta.selected_text"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <Select
                    label={"CTA View Type"}
                    placeholderLabel="Enter View Type"
                    field="comment_cta.cta.view_type"
                    options={[
                      { label: "Button with background", value: 1 },
                      { label: "Arrow", value: 2 },
                      { label: "Button with icon", value: 3 },
                      { label: "Text with arrow", value: 4 },
                      { label: "Only text", value: 5 },
                    ]}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">Button Icon</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "button_icon_comment",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">
                      Button Selected Icon
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "button_selected_icon_comment",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>

                <div className="text-muted font-size-13 mt-2 mb-2">
                  <span className="font-weight-semibold">Note:</span> If no
                  value selected, it will take the default value for some
                  fields.
                </div>
              </Row>
            </div>
          ) : null}
        </Row>
        <Row>
          <div className="d-flex align-items-center">
            <span className="me-2">Share CTA:</span>
            <Switch onChange={val => this.setState({ enableShareCta: val })} />
          </div>
          {this.state?.enableShareCta ? (
            <div>
              <Row>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Entity Type"}
                    field={"share_cta.type"}
                    value="SHARE"
                    disabled
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Title"}
                    placeholderLabel="Enter Title"
                    field={"share_cta.title"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Sub-Title"}
                    placeholderLabel="Enter Sub-Title"
                    field={"share_cta.subtitle"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">CTA Icon</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "cta_icon_share",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>
                <Divider className="m-auto">
                  <h5 className="mb-0 text-muted font-size-14">CTA</h5>
                </Divider>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"CTA Text"}
                    placeholderLabel="Enter Text"
                    field={"share_cta.cta.text"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"CTA Selected Text"}
                    placeholderLabel="Enter Selected Text"
                    field={"share_cta.cta.selected_text"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <Select
                    label={"CTA View Type"}
                    placeholderLabel="Enter View Type"
                    field="share_cta.cta.view_type"
                    options={[
                      { label: "Button with background", value: 1 },
                      { label: "Arrow", value: 2 },
                      { label: "Button with icon", value: 3 },
                      { label: "Text with arrow", value: 4 },
                      { label: "Only text", value: 5 },
                    ]}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">Button Icon</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "button_icon_share",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">
                      Button Selected Icon
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "button_selected_icon_share",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>

                <div className="text-muted font-size-13 mt-2 mb-2">
                  <span className="font-weight-semibold">Note:</span> If no
                  value selected, it will take the default value for some
                  fields.
                </div>
              </Row>
            </div>
          ) : null}
        </Row>
        <Row className="border-top">
          <div className="d-flex align-items-center">
            <span className="me-2">Duet CTA:</span>
            <Switch onChange={val => this.setState({ enableDuetCta: val })} />
          </div>
          {this.state?.enableDuetCta ? (
            <div>
              <Row>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Entity Type"}
                    field={"duet_cta.type"}
                    value="DUET"
                    disabled
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Title"}
                    placeholderLabel="Enter Title"
                    field={"duet_cta.title"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"Sub-Title"}
                    placeholderLabel="Enter Sub-Title"
                    field={"duet_cta.subtitle"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">CTA Icon</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "cta_icon_duet",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>
                <Divider className="m-auto">
                  <h5 className="mb-0 text-muted font-size-14">CTA</h5>
                </Divider>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"CTA Text"}
                    placeholderLabel="Enter Text"
                    field={"duet_cta.cta.text"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <TextField
                    label={"CTA Selected Text"}
                    placeholderLabel="Enter Selected Text"
                    field={"duet_cta.cta.selected_text"}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <Select
                    label={"CTA View Type"}
                    placeholderLabel="Enter View Type"
                    field="duet_cta.cta.view_type"
                    options={[
                      { label: "Button with background", value: 1 },
                      { label: "Arrow", value: 2 },
                      { label: "Button with icon", value: 3 },
                      { label: "Text with arrow", value: 4 },
                      { label: "Only text", value: 5 },
                    ]}
                  />
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">Button Icon</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "button_icon_duet",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>
                <Col sm={12} md={4} lg={4} className="mb-2">
                  <div>
                    <label className="col-form-label">
                      Button Selected Icon
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          "button_selected_icon_duet",
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </Col>

                <div className="text-muted font-size-13 mt-2 mb-2">
                  <span className="font-weight-semibold">Note:</span> If no
                  value selected, it will take the default value for some
                  fields.
                </div>
              </Row>
            </div>
          ) : null}
        </Row>
        <Row gutter={16}>
          <Col sm="12">
            <div className="d-flex justify-content-end">
              <Button
                htmlType="submit"
                className="font-16 btn-block btn btn-primary"
                color="primary"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                <i className="bx bx-plus me-1" />
                Create
              </Button>
              <Button
                color="danger"
                size="default"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    if (errors) {
      return
    }

    if (!this.state.videoFile) {
      return toastr.error("Please upload the video.")
    }

    formData.bulk_upload = true
    formData.batch_seq = 35
    formData.format = this.state.formatType

    if (!_.isEmpty(formData.post_publish_time_epoch)) {
      formData.post_publish_time_epoch = Math.floor(
        dayjs(formData.post_publish_time_epoch.valueOf()) / 1000
      )
    }
    if (!_.isEmpty(formData.post_expiry_time)) {
      formData.post_expiry_time = dayjs(formData.post_expiry_time).format(
        "YYYY-MM-DDTHH:MM:ssZ"
      )
    }
    if (this.state?.campaign_uuid) {
      formData.campaign_uuid = this.state.campaign_uuid
    } else {
      formData.campaign_uuid = undefined
    }

    if (
      this.props.currentUserType == 6 ||
      (this.props.currentUserType < 3 &&
        formData.hasOwnProperty("src_type") &&
        formData.src_type === "ads")
    ) {
      formData.allow_download = formData.allow_download ? "Y" : "N"
      formData.allow_share = formData.allow_share ? "Y" : "N"
      formData.allow_like = formData.allow_like ? "Y" : "N"
      formData.allow_soundboard = formData.allow_soundboard ? "Y" : "N"
      formData.allow_soundboard_reuse = formData.allow_soundboard_reuse
        ? "Y"
        : "N"
      formData.allow_commenting = formData.allow_commenting ? "Y" : "N"
      formData.display_profile_image = formData.display_profile_image
        ? "Y"
        : "N"
      formData.hashtag_clickable = formData.hashtag_clickable ? "Y" : "N"
      formData.allow_profile_name_click = formData.allow_profile_name_click
        ? "Y"
        : "N"
      formData.display_profile_name = formData.display_profile_name ? "Y" : "N"
    }
    if (formData.location.length > 0) {
      if (formData.granularity === "STATE") {
        formData.state_ids = formData.location
        delete formData.location
      } else {
        formData.state_ids = []
        formData.city_ids = []
        for (let city of formData.location) {
          if (
            !formData.state_ids.includes(city.split("_")[0].replace("c", "s"))
          ) {
            formData.state_ids.push(city.split("_")[0].replace("c", "s"))
          }
          formData.city_ids.push(city)
        }
        delete formData.location
      }
    }
    let zoneInfo = []
    if (formData.active_zones.length > 0) {
      let sub_type_zone = []
      let updated_all_active_zones = this.state?.active_zones
      let updated_active_zones = []
      let relational_zones = []

      // check for valid zone
      for (let i = 0; i < updated_all_active_zones.length; i++) {
        for (let j = 0; j < this.props.zoneList.length; j++) {
          if (updated_all_active_zones[i] == this.props.zoneList[j].zone_uuid) {
            updated_active_zones = [
              ...updated_active_zones,
              this.props.zoneList[j].zone_uuid,
            ]
          }
        }
      }

      let validZones = [...updated_active_zones]

      validZones.map(item => {
        this.props.zoneList.map(zone => {
          if (item == zone.zone_uuid) {
            if (zone.level == 2) {
              updated_active_zones = [
                ...updated_active_zones,
                ...zone.sub_type,
                ...zone.type,
              ]
              relational_zones = [
                ...relational_zones,
                ...zone.sub_type,
                ...zone.type,
              ]
              sub_type_zone = [...sub_type_zone, ...zone.sub_type]
            } else if (zone.level == 1) {
              updated_active_zones = [...updated_active_zones, ...zone.type]
              relational_zones = [...relational_zones, ...zone.type]
            }
          }
        })
      })

      sub_type_zone.map(item => {
        this.props.zoneList.map(zone => {
          if (item == zone.zone_uuid) {
            if (zone.level == 1) {
              updated_active_zones = [...updated_active_zones, ...zone.type]
              relational_zones = [...relational_zones, ...zone.type]
            }
          }
        })
      })
      const newActiveSet = new Set(updated_active_zones)
      const activeZones = [...newActiveSet]

      zoneInfo = activeZones.map(data => {
        let added_type
        if (relational_zones.includes(data)) {
          added_type = "relational"
        } else {
          added_type = "input"
        }
        return {
          zone_id: data,
          status: "approved",
          source: "UGC",
          added_type: added_type,
          added_on: moment()
            .tz("Asia/Calcutta")
            .format("YYYY-MM-DDTHH:mm:ss+05:30"),
          modified_on: moment()
            .tz("Asia/Calcutta")
            .format("YYYY-MM-DDTHH:mm:ss+05:30"),
          modified_by: this.props.currentUser.email,
        }
      })

      if (formData.input_zones.length > 0) {
        let zoneInfoCopy = [...zoneInfo]
        for (let i = 0; i < formData.input_zones.length; i++) {
          for (let j = 0; j < zoneInfoCopy.length; j++) {
            if (formData.input_zones[i] === zoneInfoCopy[j].zone_id) {
              break
            }
            if (j == zoneInfoCopy.length - 1) {
              zoneInfo.push({
                zone_id: formData.input_zones[i],
                status: "added",
                source: "UGC",
                added_type: "input",
                added_on: moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30"),
                modified_on: moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30"),
                modified_by: this.props.currentUser.email,
              })
            }
          }
        }
      }
      formData.active_zones = [...newActiveSet]
      formData.input_zones = [...formData.input_zones, ...formData.active_zones]
      const newInputSet = new Set(formData.input_zones)
      formData.input_zones = [...newInputSet]
      formData.zone_info = zoneInfo
    }

    if (this.state.templateDetails) {
      const templateDetails = this.state.templateDetails
      formData.template_meta = {
        template_id: templateDetails.uuid,
        effect_id: templateDetails.effectId,
      }
      const sticker_data = []
      if (templateDetails.primarySticker) {
        sticker_data.push({
          id: templateDetails.primarySticker,
          type: templateDetails.primaryStickerType,
          order: "primary",
          label: templateDetails.primaryStickerLabel,
        })
      }
      if (templateDetails.secondarySticker) {
        sticker_data.push({
          id: templateDetails.secondarySticker,
          type: templateDetails.secondaryStickerType,
          order: "secondary",
          label: templateDetails.secondaryStickerLabel,
        })
      }
      if (templateDetails.tertiarySticker) {
        sticker_data.push({
          id: templateDetails.tertiarySticker,
          type: templateDetails.tertiaryStickerType,
          order: "tertiary",
          label: templateDetails.tertiaryStickerLabel,
        })
      }

      if (!_.isEmpty(sticker_data)) {
        formData.template_meta.sticker_data = sticker_data
      }
    }

    let fData = new FormData()
    const custom_cta = []

    if (this.state?.enableLikeCta) {
      let data = { ...formData.like_cta }
      const { type } = data || {}
      if (!data?.title && CTA_DEFAULT_DATA[type]?.title) {
        data.title = CTA_DEFAULT_DATA[type].title || ""
      }

      if (!data?.subtitle && CTA_DEFAULT_DATA[type]?.subtitle) {
        data.subtitle = CTA_DEFAULT_DATA[type].subtitle || ""
      }

      if (!this.state.cta_icon_like && CTA_DEFAULT_DATA[type]?.icon_url) {
        data.icon_url = CTA_DEFAULT_DATA[type].icon_url || ""
      }

      if (!data?.cta?.text && CTA_DEFAULT_DATA[type]?.cta?.text) {
        data.cta.text = CTA_DEFAULT_DATA[type].cta.text || ""
      }

      if (
        !data?.cta?.selected_text &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_text
      ) {
        data.cta.selected_text = CTA_DEFAULT_DATA[type].cta.selected_text || ""
      }

      if (!data?.cta?.view_type && CTA_DEFAULT_DATA[type]?.cta?.view_type) {
        data.cta.view_type = CTA_DEFAULT_DATA[type].cta.view_type || ""
      }

      if (
        !this.state.button_icon_like &&
        CTA_DEFAULT_DATA[type]?.cta?.icon_url
      ) {
        data.cta.icon_url = CTA_DEFAULT_DATA[type].cta.icon_url || ""
      }

      if (
        !this.state.button_selected_icon_like &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_icon_url
      ) {
        data.cta.selected_icon_url =
          CTA_DEFAULT_DATA[type].cta.selected_icon_url || ""
      }

      custom_cta.push(data)
      if (this.state.cta_icon_like) {
        fData.append("cta_icon_like", this.state.cta_icon_like)
      }
      if (this.state.button_icon_like) {
        fData.append("button_icon_like", this.state.button_icon_like)
      }
      if (this.state.button_selected_icon_like) {
        fData.append(
          "button_selected_icon_like",
          this.state.button_selected_icon_like
        )
      }
    }
    delete formData?.like_cta
    if (this.state?.enableCommentCta) {
      let data = { ...formData.comment_cta }
      const { type } = data || {}
      if (!data?.title && CTA_DEFAULT_DATA[type]?.title) {
        data.title = CTA_DEFAULT_DATA[type].title || ""
      }

      if (!data?.subtitle && CTA_DEFAULT_DATA[type]?.subtitle) {
        data.subtitle = CTA_DEFAULT_DATA[type].subtitle || ""
      }

      if (!this.state.cta_icon_comment && CTA_DEFAULT_DATA[type]?.icon_url) {
        data.icon_url = CTA_DEFAULT_DATA[type].icon_url || ""
      }

      if (!data?.cta?.text && CTA_DEFAULT_DATA[type]?.cta?.text) {
        data.cta.text = CTA_DEFAULT_DATA[type].cta.text || ""
      }

      if (
        !data?.cta?.selected_text &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_text
      ) {
        data.cta.selected_text = CTA_DEFAULT_DATA[type].cta.selected_text || ""
      }

      if (!data?.cta?.view_type && CTA_DEFAULT_DATA[type]?.cta?.view_type) {
        data.cta.view_type = CTA_DEFAULT_DATA[type].cta.view_type || ""
      }

      if (
        !this.state.button_icon_comment &&
        CTA_DEFAULT_DATA[type]?.cta?.icon_url
      ) {
        data.cta.icon_url = CTA_DEFAULT_DATA[type].cta.icon_url || ""
      }

      if (
        !this.state.button_selected_icon_comment &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_icon_url
      ) {
        data.cta.selected_icon_url =
          CTA_DEFAULT_DATA[type].cta.selected_icon_url || ""
      }

      custom_cta.push(data)
      if (this.state.cta_icon_comment) {
        fData.append("cta_icon_comment", this.state.cta_icon_comment)
      }
      if (this.state.button_icon_comment) {
        fData.append("button_icon_comment", this.state.button_icon_comment)
      }
      if (this.state.button_selected_icon_comment) {
        fData.append(
          "button_selected_icon_comment",
          this.state.button_selected_icon_comment
        )
      }
    }
    delete formData?.comment_cta
    if (this.state?.enableDuetCta) {
      let data = { ...formData.duet_cta }
      const { type } = data || {}
      if (!data?.title && CTA_DEFAULT_DATA[type]?.title) {
        data.title = CTA_DEFAULT_DATA[type].title || ""
      }

      if (!data?.subtitle && CTA_DEFAULT_DATA[type]?.subtitle) {
        data.subtitle = CTA_DEFAULT_DATA[type].subtitle || ""
      }

      if (!this.state.cta_icon_duet && CTA_DEFAULT_DATA[type]?.icon_url) {
        data.icon_url = CTA_DEFAULT_DATA[type].icon_url || ""
      }

      if (!data?.cta?.text && CTA_DEFAULT_DATA[type]?.cta?.text) {
        data.cta.text = CTA_DEFAULT_DATA[type].cta.text || ""
      }

      if (
        !data?.cta?.selected_text &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_text
      ) {
        data.cta.selected_text = CTA_DEFAULT_DATA[type].cta.selected_text || ""
      }

      if (!data?.cta?.view_type && CTA_DEFAULT_DATA[type]?.cta?.view_type) {
        data.cta.view_type = CTA_DEFAULT_DATA[type].cta.view_type || ""
      }

      if (
        !this.state.button_icon_duet &&
        CTA_DEFAULT_DATA[type]?.cta?.icon_url
      ) {
        data.cta.icon_url = CTA_DEFAULT_DATA[type].cta.icon_url || ""
      }

      if (
        !this.state.button_selected_icon_duet &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_icon_url
      ) {
        data.cta.selected_icon_url =
          CTA_DEFAULT_DATA[type].cta.selected_icon_url || ""
      }

      custom_cta.push(data)
      if (this.state.cta_icon_duet) {
        fData.append("cta_icon_duet", this.state.cta_icon_duet)
      }
      if (this.state.button_icon_duet) {
        fData.append("button_icon_duet", this.state.button_icon_duet)
      }
      if (this.state.button_selected_icon_duet) {
        fData.append(
          "button_selected_icon_duet",
          this.state.button_selected_icon_duet
        )
      }
    }
    delete formData?.duet_cta
    if (this.state?.enableShareCta) {
      let data = { ...formData.share_cta }
      const { type } = data || {}
      if (!data?.title && CTA_DEFAULT_DATA[type]?.title) {
        data.title = CTA_DEFAULT_DATA[type].title || ""
      }

      if (!data?.subtitle && CTA_DEFAULT_DATA[type]?.subtitle) {
        data.subtitle = CTA_DEFAULT_DATA[type].subtitle || ""
      }

      if (!this.state.cta_icon_share && CTA_DEFAULT_DATA[type]?.icon_url) {
        data.icon_url = CTA_DEFAULT_DATA[type].icon_url || ""
      }

      if (!data?.cta?.text && CTA_DEFAULT_DATA[type]?.cta?.text) {
        data.cta.text = CTA_DEFAULT_DATA[type].cta.text || ""
      }

      if (
        !data?.cta?.selected_text &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_text
      ) {
        data.cta.selected_text = CTA_DEFAULT_DATA[type].cta.selected_text || ""
      }

      if (!data?.cta?.view_type && CTA_DEFAULT_DATA[type]?.cta?.view_type) {
        data.cta.view_type = CTA_DEFAULT_DATA[type].cta.view_type || ""
      }

      if (
        !this.state.button_icon_share &&
        CTA_DEFAULT_DATA[type]?.cta?.icon_url
      ) {
        data.cta.icon_url = CTA_DEFAULT_DATA[type].cta.icon_url || ""
      }

      if (
        !this.state.button_selected_icon_comment &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_icon_url
      ) {
        data.cta.selected_icon_url =
          CTA_DEFAULT_DATA[type].cta.selected_icon_url || ""
      }

      custom_cta.push(data)
      if (this.state.cta_icon_share) {
        fData.append("cta_icon_share", this.state.cta_icon_share)
      }
      if (this.state.button_icon_share) {
        fData.append("button_icon_share", this.state.button_icon_share)
      }
      if (this.state.button_selected_icon_share) {
        fData.append(
          "button_selected_icon_share",
          this.state.button_selected_icon_share
        )
      }
    }
    delete formData?.share_cta

    formData.custom_cta = custom_cta || []

    fData.append("file", this.state.videoFile)
    // console.log(JSON.stringify(formData))
    fData.append("details", JSON.stringify(formData))
    this.props.dispatch(createContent(fData))
    this.props.onCancel()

    // let data = new FormData()
    // data.append("file", this.state.videoFile)
    // console.log(JSON.stringify(formData))
    // data.append("details", JSON.stringify(formData))
    // this.props.dispatch(createContent(data))
    // this.props.onCancel()
  }

  getStateList() {
    // Generate States List as [{"label": "tripura","value": "s33"},{"label": "andaman & nicobar islands","value": "s1"},{"label": "chandigarh","value": "s6"},{"label": "dadra and nagar haveli","value": "s8"}]
    return Locations.map(location => {
      return { label: location.name, value: location.dhTagId }
    })
  }

  getCitiesList() {
    // Generate Cities List as [{"label": "amarpur(tripura)","value": "c33_812~s33"},{"label": "dhalai(tripura)","value": "c33_813~s33"}]
    return Locations.reduce(function (acc, location) {
      return acc.concat(
        location.children.map(city => {
          return {
            label: city.name,
            value: city.dhTagId + "~" + location.dhTagId,
          }
        })
      )
    }, [])
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
        {this.state.selectInputZones && (
          <SearchAndSelect
            type="ZONE"
            visible={this.state.selectInputZones}
            onClose={() => this.setState({ selectInputZones: false })}
            onAdd={arr => this.setState({ input_zones: arr })}
            zoneId={this.state.input_zones}
            selectedArr={_.uniq(this.state.input_zones.map(a => a))}
            allZoneList={this.props.zoneList.map(zone => {
              return {
                ...zone,
                value: zone.zone_uuid,
                label: zone.title || zone.sub_title,
              }
            })}
          />
        )}
        {this.state.selectActiveZoneModal && (
          <SearchAndSelect
            type="ZONE"
            visible={this.state.selectActiveZoneModal}
            onClose={() => this.setState({ selectActiveZoneModal: false })}
            onAdd={arr => this.setState({ active_zones: arr })}
            zoneId={this.state.active_zones}
            selectedArr={_.uniq(this.state.active_zones.map(a => a))}
            allZoneList={this.props.zoneList.map(zone => {
              return {
                ...zone,
                value: zone.zone_uuid,
                label: zone.title || zone.sub_title,
              }
            })}
          />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    loading: _.get(store, "content.createContentLoading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    locationList: _.get(store, "locationReducer.locationList"),
    zoneList: _.get(store, "zone.zoneList"),
    videoTemplates: _.get(store, "cmsVideoTemplates.cmsVideoTemplates"),
    videoAssets: _.get(store, "cmsVideoAssets.cmsVideoAssets"),
    campaignList: _.get(store, "campaign.campaign"),
    totalCampaign: _.get(store, "campaign.totalCampaign"),
  }
}

export default connect(mapStateToProps)(CreateContentForm)
