import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Divider, Switch, Upload, Select, DatePicker } from "antd"
import toastr from "toastr"
import _ from "lodash"
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap"
import { Button } from "@mui/material"
import ValidatorForm, { DateTimePicker } from "../ValidatorForm"
import dayjs from "dayjs"

const { Option } = Select

export default class BadgeForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      badge_title: this.props?.data?.badge_title || "",
      badge_id: this.props?.data?.badge_id || null,
      status: this.props?.data?.status || "DRAFT",
      start_time: this.props?.data?.start_time || null,
      end_time: this.props?.data?.end_time || null,
      view_order: this.props?.data?.view_order || 0,
      badge_url: this.props?.data?.badge_url ? this.props?.data?.badge_url : "",
      start_time: this.props?.data?.start_time
        ? dayjs(this.props.data.start_time)
        : "",
      end_time: this.props?.data?.end_time
        ? dayjs(this.props.data.end_time)
        : "",
      created_on: this.props?.data?.created_on || null,
      updated_on: this.props?.data?.created_on || null,
    }
  }

  componentWillUnmount() {
    this._resetState()
  }

  _resetState() {
    this.setState({
      badge_title: null,
      status: null,
      start_time: null,
      end_time: null,
      view_order: null,
    })
  }

  _onImageRemove = type => {
    if (type === "badge_url") {
      this.setState({
        badge_url_list: [],
        badgeUrl: null,
      })
    }
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "badge_url") {
        this.setState({
          badge_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          badgeUrl: fileObj.file,
        })
      }
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Create" : "Update"
          } Badge`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={4} className="mt-2">
                <label>Title</label>
                <input
                  className="form-control"
                  value={this.state.badge_title}
                  onChange={e => this.setState({ badge_title: e.target.value })}
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>View Order</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.view_order}
                    onChange={e => {
                      if (e.target.value && e.target.value < 0) {
                        toastr.error("View order can not be less than zero...")
                        return
                      }
                      this.setState({ view_order: Number(e.target.value) })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <label>Status</label>
                <Select
                  allowClear={true}
                  placeholder="Status"
                  defaultValue={this.state.status}
                  onChange={value => {
                    this.setState({
                      status: value,
                    })
                  }}
                  style={{ width: "100%" }}
                  size="large"
                >
                  <Option value="PUBLISHED">Published</Option>
                  <Option value="DRAFT">Draft</Option>
                  <Option value="REMOVED">Removed</Option>
                </Select>
              </Col>
              <Col sm="12" lg="4" className="mt-2">
                <DatePicker
                  style={{ padding: "9px", width: "100%" }}
                  label="Start Time"
                  onChange={value => {
                    this.setState({
                      start_time: value,
                    })
                  }}
                  defaultValue={this.state.start_time}
                  format="YYYY-MM-DD HH:mm"
                  showTime
                />
              </Col>

              <Col sm="12" lg="4" className="mt-2">
                <DatePicker
                  style={{ padding: "9px", width: "100%" }}
                  label="End Time"
                  onChange={value => {
                    this.setState({
                      end_time: value,
                    })
                  }}
                  defaultValue={this.state.end_time}
                  format="YYYY-MM-DD HH:mm"
                  showTime
                />
              </Col>

              <Divider>Files</Divider>
              <Col sm="12" md="4">
                <Row
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("badge_url")}
                      defaultFileList={this.state.badge_url}
                      fileList={this.state.badge_url_list || []}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "badge_url")
                      }
                    >
                      <Button
                        color="primary"
                        onClick={() => {}}
                        variant="contained"
                      >
                        Upload Badge image
                      </Button>
                    </Upload>
                  </div>
                </Row>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <div className="d-flex justify-content-end mt-4">
                  <Button type="submit" color="success" variant="contained">
                    {this.props.action === "update" ? "Update" : "Create"}
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.onCancel()
                      this._resetState()
                    }}
                    color="error"
                    className="mx-2"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data = new FormData()
    const payload = {
      ...this.state,
    }

    if (!_.isEmpty(payload.start_time)) {
      payload.start_time = dayjs(payload.start_time).valueOf()
    }

    if (!_.isEmpty(payload.end_time)) {
      payload.end_time = dayjs(payload.end_time).valueOf()
    }

    if (!this.state.badgeUrl && this.props.action === "create") {
      toastr.error("Please upload badge image")
      return
    }

    if (this.state.badgeUrl) {
      data.append("badgeFile", this.state.badgeUrl)
    }

    delete payload.badge_url_list
    delete payload.badgeUrl

    data.append("payload", JSON.stringify(payload))
    this.props.onSubmit(data)
    this._resetState()
  }

  render() {
    return this._form()
  }
}
