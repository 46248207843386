import React from "react"
import { Container } from "reactstrap"
import DashboardReportCard from "./Report/DashboardReportCard"

const UserDashboardCard = props => {
  return (
    <>
      <Container fluid>
        <div className="dashboard-card-container">
          <DashboardReportCard {...props} keyName="communityLeadId" />
        </div>
      </Container>
    </>
  )
}

export default UserDashboardCard
