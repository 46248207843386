import { createSlice } from "@reduxjs/toolkit"

const contentSlice = createSlice({
  name: "content",
  initialState: {
    loading: false,
    contentList: [],
    content: [],
    taskDetails: [],
    selectedContent: {},
    refetchTaskList: false,
    reassignAllTaskLoading: false,
    agencyStat: [],
    boostHistory: [],
    refetchBoostHistory: false,
    total: 0,
    curatedTypes: [],
    eventList: [],
    eventsloading: false,
    loadingAgentChart: false,
    loadingUniqueTaggedContentChart: false,
    loadingLanguageChart: false,
    loadingAgentByLangChart: false,
    agencyStat: [],
    agentList: [],
    uniqueTaggedQCTaggedContentStat: [],
    languageStats: [],
    agentByLangStat: [],
  },
  reducers: {
    getContentByFilter(state, action) {
      return {
        ...state,
        content: [],
        loading: true,
      }
    },
    getContentByFilterSuccess(state, action) {
      return {
        ...state,
        content: action.payload.data.content,
        total:
          action.payload.data.total && action.payload.data.total.value
            ? action.payload.data.total.value
            : action.payload.data.total
            ? action.payload.data.total
            : 0,
        loading: false,
      }
    },
    getTasksByFilter(state) {
      return {
        ...state,
        loading: true,
        refetchTaskList: false,
        content: [],
      }
    },
    getTasksByFilterSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        content: payload.data.content,
        total: payload.data.total,
      }
    },
    getContentById(state) {
      return {
        ...state,
        selectedContent: {},
        loading: true,
      }
    },
    getContentByIdSuccess(state, action) {
      return {
        ...state,
        selectedContent:
          action.payload.data.content && action.payload.data.content[0],
        loading: false,
      }
    },
    getTrendingContent(state) {
      return {
        ...state,
        content: [],
        loading: true,
      }
    },
    fetchContentByUuids(state) {
      return {
        ...state,
        content: [],
        loading: true,
      }
    },
    fetchContentByUuidsSuccess(state, action) {
      return {
        ...state,
        content: action.payload.data,
        total:
          action.payload.data.total && action.payload.data.total.value
            ? action.payload.data.total.value
            : action.payload.data.total
            ? action.payload.data.total
            : 0,
        loading: false,
      }
    },
    getTrendingContentSuccess(state, action) {
      return {
        ...state,
        content: action.payload.data.content,
        total:
          action.payload.data.total && action.payload.data.total.value
            ? action.payload.data.total.value
            : action.payload.data.total
            ? action.payload.data.total
            : 0,
        loading: false,
      }
    },
    getContentDataInRange(state, action) {
      return {
        ...state,
        contentList: [],
        total: 0,
        loading: true,
      }
    },
    getContentDataInRangeSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        contentList: payload.contentList,
        total: payload.total,
      }
    },
    updateTag() {},
    createContent(state) {
      return {
        ...state,
        createContentLoading: true,
      }
    },
    createContentSuccess(state) {
      return {
        ...state,
        createContentLoading: false,
      }
    },
    updateContent(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateContentSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    updateContentZones(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateContentZonesSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    updateAction(state) {
      return {
        ...state,
        loading: false,
      }
    },
    updateActionSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    uploadContent(state) {
      return {
        ...state,
        loading: true,
      }
    },
    uploadContentSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    shoppableCuratedTypes(state) {
      return {
        ...state,
        loading: true,
      }
    },
    shoppableCuratedTypesSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    upsertShoppableContent(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertShoppableContentSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    labelContent(state) {
      return {
        ...state,
      }
    },
    labelContentSuccess(state) {
      return {
        ...state,
        loading: false,
      }
    },
    boostContent(state) {
      return {
        ...state,
        loading: true,
        refetchBoostHistory: false,
      }
    },
    boostContentSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchBoostHistory: true,
      }
    },
    getLabelContentList(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getLabelContentListSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        contentList: payload.data.content,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
      }
    },
    getBoostContentHistory(state) {
      return {
        ...state,
        loading: true,
        refetchBoostHistory: false,
      }
    },
    getBoostContentHistorySuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        boostHistory: payload.data,
      }
    },
    getShoppableCuratedTypes(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getShoppableCuratedTypesSuccess(state, payload) {
      return {
        ...state,
        loading: false,
        curatedTypes: payload.data,
      }
    },
    getEvents(state) {
      return {
        ...state,
        eventsloading: true,
      }
    },
    getEventsSuccess(state, { payload }) {
      return {
        ...state,
        eventsloading: false,
        eventList: payload.data.data,
      }
    },
    getAgencyLevelStats(state) {
      return {
        ...state,
        loadingAgentChart: true,
      }
    },
    getAgencyLevelStatsSuccess(state, { payload }) {
      return {
        ...state,
        loadingAgentChart: false,
        agencyStat: payload.data,
      }
    },
    getAgentListByAgency(state) {
      return {
        ...state,
        loadingAgentChart: true,
      }
    },
    getAgentListByAgencySuccess(state, { payload }) {
      return {
        ...state,
        loadingAgentChart: false,
        agentList: payload.data,
      }
    },
    getUniqueTaggedAndQCTaggedContainData(state) {
      return {
        ...state,
        loadingUniqueTaggedContentChart: true,
      }
    },
    getUniqueTaggedAndQCTaggedContainDataSuccess(state, { payload }) {
      return {
        ...state,
        loadingUniqueTaggedContentChart: false,
        uniqueTaggedQCTaggedContentStat: payload.data,
      }
    },
    getLanguageLevelStats(state) {
      return {
        ...state,
        loadingLanguageChart: true,
      }
    },
    getLanguageLevelStatsSuccess(state, { payload }) {
      return {
        ...state,
        loadingLanguageChart: false,
        languageStats: payload.data,
      }
    },
    getAgentByLanguage(state) {
      return {
        ...state,
        loadingAgentByLangChart: true,
      }
    },
    getAgentByLanguageSuccess(state, { payload }) {
      return {
        ...state,
        loadingAgentByLangChart: false,
        agentByLangStat: payload.data,
      }
    },
    markQCCompleted(state) {
      return {
        ...state,
      }
    },
    markQCCompletedSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
      }
    },
    resetState(state) {
      return {
        loading: false,
        contentList: [],
        content: [],
        taskDetails: [],
        refetchTaskList: false,
        reassignAllTaskLoading: false,
        labelContent,
        labelContentSuccess,
        agencyStat: [],
        loadingAgentChart: false,
        loadingUniqueTaggedContentChart: false,
        loadingLanguageChart: false,
        loadingAgentByLangChart: false,
        agencyStat: [],
        agentList: [],
        uniqueTaggedQCTaggedContentStat: [],
        languageStats: [],
        agentByLangStat: [],
      }
    },
  },
})

export const {
  getContentByFilter,
  getContentByFilterSuccess,
  getContentById,
  getContentByIdSuccess,
  getContentDataInRange,
  getContentDataInRangeSuccess,
  getTrendingContent,
  getTrendingContentSuccess,
  resetState,
  updateContent,
  updateContentZones,
  updateContentZonesSuccess,
  fetchContentByUuidsSuccess,
  fetchContentByUuids,
  updateTag,
  uploadContent,
  updateContentSuccess,
  createContent,
  createContentSuccess,
  uploadContentSuccess,
  getLabelContentList,
  getLabelContentListSuccess,
  labelContent,
  labelContentSuccess,
  boostContent,
  boostContentSuccess,
  getBoostContentHistory,
  getBoostContentHistorySuccess,
  updateAction,
  updateActionSuccess,
  shoppableCuratedTypes,
  shoppableCuratedTypesSuccess,
  getShoppableCuratedTypes,
  getShoppableCuratedTypesSuccess,
  upsertShoppableContent,
  upsertShoppableContentSuccess,
  getTasksByFilter,
  getTasksByFilterSuccess,
  getEvents,
  getEventsSuccess,
  getAgencyLevelStats,
  getAgencyLevelStatsSuccess,
  getAgentListByAgency,
  getAgentListByAgencySuccess,
  getUniqueTaggedAndQCTaggedContainData,
  getUniqueTaggedAndQCTaggedContainDataSuccess,
  getLanguageLevelStats,
  getLanguageLevelStatsSuccess,
  getAgentByLanguage,
  getAgentByLanguageSuccess,
  markQCCompleted,
  markQCCompletedSuccess,
} = contentSlice.actions

export default contentSlice.reducer
