// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU"
export const WINDOW_WIDTH = "WINDOW-WIDTH"
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE"
//
export const GET_ZERO_SEARCH_CONFIG_DATA = "GET_ZERO_SEARCH_CONFIG_DATA"
export const GET_ZERO_SEARCH_CONFIG_DATA_SUCCESS =
  "GET_ZERO_SEARCH_CONFIG_DATA_SUCCESS"
export const RESET_ZERO_SEARCH_CONFIG_STATE = "RESET_ZERO_SEARCH_CONFIG_STATE"
export const GET_PAGE_LIST = "GET_PAGE_LIST"
export const GET_PAGE_LIST_SUCCESS = "GET_PAGE_LIST_SUCCESS"
export const GET_PAGE_ENTITIES = "GET_PAGE_ENTITIES"
export const GET_PAGE_ENTITIES_SUCCESS = "GET_PAGE_ENTITIES_SUCCESS"
export const GET_ENTITIES = "GET_ENTITIES"
export const GET_ENTITIES_SUCCESS = "GET_ENTITIES_SUCCESS"
export const GET_COLLECTION_LIST = "GET_COLLECTION_LIST"
export const GET_COLLECTION_LIST_SUCCESS = "GET_COLLECTION_LIST_SUCCESS"
export const GET_COLLECTION_ENTITIES = "GET_COLLECTION_ENTITIES"
export const GET_COLLECTION_ENTITIES_SUCCESS = "GET_COLLECTION_ENTITIES_SUCCESS"
export const GET_COLLECTION_ENTITIES_GROUPS = "GET_COLLECTION_ENTITIES_GROUPS"
export const GET_COLLECTION_ENTITIES_GROUPS_SUCCESS =
  "GET_COLLECTION_ENTITIES_GROUPS_SUCCESS"
export const UPSERT_PAGE = "UPSERT_PAGE"
export const UPSERT_PAGE_SUCCESS = "UPSERT_PAGE_SUCCESS"
export const GET_PAGE_PREVIEW = "GET_PAGE_PREVIEW"
export const GET_PAGE_PREVIEW_SUCCESS = "GET_PAGE_PREVIEW_SUCCESS"
export const UPSERT_ENTITY = "UPSERT_ENTITY"
export const UPSERT_ENTITY_SUCCESS = "UPSERT_ENTITY_SUCCESS"
export const UPSERT_COLLECTION_ENTITY = "UPSERT_COLLECTION_ENTITY"
export const UPSERT_COLLECTION_ENTITY_SUCCESS =
  "UPSERT_COLLECTION_ENTITY_SUCCESS"
export const RESET_ENTITY_STATE = "RESET_ENTITY_STATE"
export const RESET_PAGE_ENTITIES = "RESET_PAGE_ENTITIES"
export const GET_PAGE_ENTITIES_BY_LAN_LOC_SUCCESS =
  "GET_PAGE_ENTITIES_BY_LAN_LOC_SUCCESS"
export const GET_PAGE_ENTITIES_BY_LAN_LOC = "GET_PAGE_ENTITIES_BY_LAN_LOC"
export const GET_COLLECTION_ENTITIES_BY_LAN_LOC_SUCCESS =
  "GET_COLLECTION_ENTITIES_BY_LAN_LOC_SUCCESS"
export const GET_COLLECTION_ENTITIES_BY_LAN_LOC =
  "GET_COLLECTION_ENTITIES_BY_LAN_LOC"

//Similarity Data
export const GET_SIMILARITY_DATA = "GET_SIMILARITY_DATA"
export const GET_SIMILARITY_DATA_SUCCESS = "GET_SIMILARITY_DATA_SUCCESS"

//Search Data in date and bucket range
export const GET_CONTENT_DATA_IN_RANGE = "GET_CONTENT_DATA_IN_RANGE"
export const GET_CONTENT_DATA_IN_RANGE_SUCCESS =
  "GET_CONTENT_DATA_IN_RANGE_SUCCESS"
//Configs
export const GET_CONFIG_DATA = "GET_CONFIG_DATA"
export const GET_CONFIG_DATA_SUCCESS = "GET_CONFIG_DATA_SUCCESS"

//Comment
export const GET_COMMENT_BY_FILTER = "GET_COMMENT_BY_FILTER"
export const GET_COMMENT_BY_FILTER_SUCCESS = "GET_COMMENT_BY_FILTER_SUCCESS"
export const MODERATE_COMMENT = "MODERATE_COMMENT"
export const MODERATE_COMMENT_SUCCESS = "MODERATE_COMMENT_SUCCESS"
export const RESET_COMMENT_STATE = "RESET_COMMENT_STATE"

//CMS VIDEO ASSETS
export const GET_CMS_VIDEO_ASSETS_BY_FILTER = "GET_CMS_VIDEO_ASSETS_BY_FILTER"
export const GET_CMS_VIDEO_ASSETS_BY_FILTER_SUCCESS =
  "GET_CMS_VIDEO_ASSETS_BY_FILTER_SUCCESS"
export const UPSERT_CMS_VIDEO_ASSETS = "UPSERT_CMS_VIDEO_ASSETS"
export const UPSERT_CMS_VIDEO_ASSETS_SUCCESS = "UPSERT_CMS_VIDEO_ASSETS_SUCCESS"
export const RESET_CMS_VIDEO_ASSETS_STATE = "RESET_CMS_VIDEO_ASSETS_STATE"

//CMS VIDEO Templates
export const GET_CMS_VIDEO_TEMPLATES_BY_FILTER =
  "GET_CMS_VIDEO_TEMPLATES_BY_FILTER"
export const GET_CMS_VIDEO_TEMPLATES_BY_FILTER_SUCCESS =
  "GET_CMS_VIDEO_TEMPLATES_BY_FILTER_SUCCESS"
export const UPSERT_CMS_VIDEO_TEMPLATES = "UPSERT_CMS_VIDEO_TEMPLATES"
export const UPSERT_CMS_VIDEO_TEMPLATES_SUCCESS =
  "UPSERT_CMS_VIDEO_TEMPLATES_SUCCESS"
export const RESET_CMS_VIDEO_TEMPLATES_STATE = "RESET_CMS_VIDEO_TEMPLATES_STATE"

//Content
export const GET_CONTENT_BY_FILTER = "GET_CONTENT_BY_FILTER"
export const GET_CONTENT_BY_FILTER_SUCCESS = "GET_CONTENT_BY_FILTER_SUCCESS"
export const GET_TASKS_BY_FILTER = "GET_TASKS_BY_FILTER"
export const GET_LATEST_COMPLETED_CONTENT_SUCCESS =
  "GET_LATEST_COMPLETED_CONTENT_SUCCESS"
export const GET_LATEST_COMPLETED_CONTENT = "GET_LATEST_COMPLETED_CONTENT"
export const GET_TASKS_BY_FILTER_SUCCESS = "GET_TASKS_BY_FILTER_SUCCESS"
export const GET_AGGREGATED_USERS_TASK_COUNT = "GET_AGGREGATED_USERS_TASK_COUNT"
export const GET_AGGREGATED_USERS_TASK_COUNT_SUCCESS =
  "GET_AGGREGATED_USERS_TASK_COUNT_SUCCESS"
export const GET_CONTENT_BY_UUIDS = "GET_CONTENT_BY_UUIDS"
export const GET_CONTENT_BY_UUIDS_SUCCESS = "GET_CONTENT_BY_UUIDS_SUCCESS"
export const MODERATE_CONTENT = "MODERATE_CONTENT"
export const MODERATE_CONTENT_SUCCESS = "MODERATE_CONTENT_SUCCESS"
export const REASSIGN_TASKS = "REASSIGN_TASKS"
export const REASSIGN_TASKS_SUCCESS = "REASSIGN_TASKS_SUCCESS"
export const UPDATE_CONTENT = "UPDATE_CONTENT"
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS"
export const CREATE_CONTENT = "CREATE_CONTENT"
export const CREATE_CONTENT_SUCCESS = "CREATE_CONTENT_SUCCESS"
export const UPLOAD_CONTENT = "UPLOAD_CONTENT"
export const UPLOAD_CONTENT_SUCCESS = "UPLOAD_CONTENT_SUCCESS"
export const UPLOAD_ACCEPTED_CONTENT = "UPLOAD_ACCEPTED_CONTENT"
export const UPLOAD_ACCEPTED_CONTENT_SUCCESS = "UPLOAD_ACCEPTED_CONTENT_SUCCESS"
export const RESET_CONTENT_STATE = "RESET_CONTENT_STATE"
export const REASSIGN_ALL_TASKS = "REASSIGN_ALL_TASKS"
export const REASSIGN_ALL_TASKS_SUCCESS = "REASSIGN_ALL_TASKS_SUCCESS"
export const IMAGE_OPERATIONS = "IMAGE_OPERATIONS"
export const IMAGE_OPERATIONS_SUCCESS = "IMAGE_OPERATIONS_SUCCESS"
export const GET_AGENCY_LEVEL_STATS = "GET_AGENCY_LEVEL_STATS"
export const GET_AGENCY_LEVEL_STATS_SUCCESS = "GET_AGENCY_LEVEL_STATS_SUCCESS"

//Users
export const GET_CMS_USER_LIST = "GET_CMS_USER_LIST"
export const GET_CMS_USER_LIST_SUCCESS = "GET_CMS_USER_LIST_SUCCESS"
export const GET_CMS_USER_LIST_BY_FILTER = "GET_CMS_USER_LIST_BY_FILTER"
export const GET_CMS_USER_LIST_BY_FILTER_SUCCESS =
  "GET_CMS_USER_LIST_BY_FILTER_SUCCESS"
export const RESET_CMS_USER_STATE = "RESET_CMS_USER_STATE"
export const GET_DESKS_LIST = "GET_DESKS_LIST"
export const GET_DESKS_LIST_SUCCESS = "GET_DESKS_LIST_SUCCESS"

//APP USER
export const GET_USER_BY_FILTER = "GET_USER_BY_FILTER"
export const GET_USER_BY_FILTER_SUCCESS = "GET_USER_BY_FILTER_SUCCESS"
export const FUZZY_SEARCH_USER = "FUZZY_SEARCH_USER"
export const FUZZY_SEARCH_USER_SUCCESS = "FUZZY_SEARCH_USER_SUCCESS"
export const GET_APP_USER_DETAILS = "GET_APP_USER_DETAILS"
export const GET_APP_USER_DETAILS_SUCCESS = "GET_APP_USER_DETAILS_SUCCESS"
export const GET_APP_USER_DETAILS_ES = "GET_APP_USER_DETAILS_ES"
export const GET_APP_USER_DETAILS_ES_SUCCESS = "GET_APP_USER_DETAILS_ES_SUCCESS"
export const MANAGE_APP_USER = "MANAGE_APP_USER"
export const MANAGE_APP_USER_SUCCESS = "MANAGE_APP_USER_SUCCESS"
export const RESET_APP_USER_STATE = "RESET_APP_USER_STATE"
export const RESET_USER_STATE = "RESET_USER_STATE"
export const CREATE_APP_USER = "CREATE_APP_USER"
export const CREATE_APP_USER_SUCCESS = "CREATE_APP_USER_SUCCESS"
export const UPDATE_APP_USER = "UPDATE_APP_USER"
export const UPDATE_APP_USER_SUCCESS = "UPDATE_APP_USER_SUCCESS"
export const TAG_USER = "TAG_USER"
export const TAG_USER_SUCCESS = "TAG_USER_SUCCESS"
export const UPLOAD_USER = "UPLOAD_USER"
export const UPLOAD_USER_SUCCESS = "UPLOAD_USER_SUCCESS"
export const UPDATE_APP_USER_CURATED_ENTITIES =
  "UPDATE_APP_USER_CURATED_ENTITIES"
export const UPDATE_APP_USER_CURATED_ENTITIES_SUCCESS =
  "UPDATE_APP_USER_CURATED_ENTITIES_SUCCESS"

// Activity Log details
export const GET_ACTIVITY_DETAILS = "GET_ACTIVITY_DETAILS"
export const GET_ACTIVITY_DETAILS_SUCCESS = "GET_ACTIVITY_DETAILS_SUCCESS"
export const GET_ACTIVITY_DETAILS_RESET_STATE =
  "GET_ACTIVITY_DETAILS_RESET_STATE"

//Audio
export const GET_AUDIO_DETAILS = "GET_AUDIO_DETAILS"
export const GET_AUDIO_DETAILS_SUCCESS = "GET_AUDIO_DETAILS_SUCCESS"
export const GET_AUDIO_LIST_FOR_OPTIONS = "GET_AUDIO_LIST_FOR_OPTIONS"
export const GET_AUDIO_LIST_FOR_OPTIONS_SUCCESS =
  "GET_AUDIO_LIST_FOR_OPTIONS_SUCCESS"
export const GET_AUDIO_ORDER_BY_FILTER = "GET_AUDIO_ORDER_BY_FILTER"
export const GET_AUDIO_ORDER_BY_FILTER_SUCCESS =
  "GET_AUDIO_ORDER_BY_FILTER_SUCCESS"
export const UPDATE_AUDIO_ORDER = "UPDATE_AUDIO_ORDER"
export const UPDATE_AUDIO_ORDER_SUCCESS = "UPDATE_AUDIO_ORDER_SUCCESS"
export const UPSERT_AUDIO = "UPSERT_AUDIO"
export const UPSERT_AUDIO_SUCCESS = "UPSERT_AUDIO_SUCCESS"
export const UPDATE_AUDIO_STATUS = "UPDATE_AUDIO_STATUS"
export const UPDATE_AUDIO_STATUS_SUCCESS = "UPDATE_AUDIO_STATUS_SUCCESS"
export const UPDATE_AUDIO_VISIBILITY = "UPDATE_AUDIO_VISIBILITY"
export const UPDATE_AUDIO_VISIBILITY_SUCCESS = "UPDATE_AUDIO_VISIBILITY_SUCCESS"
export const RESET_AUDIO_STATE = "RESET_AUDIO_STATE"
export const GET_AUDIO_TABS = "GET_AUDIO_TABS"
export const GET_AUDIO_TABS_SUCCESS = "GET_AUDIO_TABS_SUCCESS"
export const INSERT_AUDIO_TABS = "INSERT_AUDIO_TABS"
export const INSERT_AUDIO_TABS_SUCCESS = "INSERT_AUDIO_TABS_SUCCESS"
export const UPDATE_AUDIO_TABS = "UPDATE_AUDIO_TABS"
export const UPDATE_AUDIO_TABS_SUCCESS = "UPDATE_AUDIO_TABS_SUCCESS"
export const DELETE_AUDIO_TABS = "DELETE_AUDIO_TABS"
export const DELETE_AUDIO_TABS_SUCCESS = "DELETE_AUDIO_TABS_SUCCESS"
export const RESET_RESPONSE_STATUS = "RESET_RESPONSE_STATUS"

// CameraAsset
export const GET_CAMERA_ASSET_LIST_BY_FILTERS =
  "GET_CAMERA_ASSET_LIST_BY_FILTERS"
export const GET_CAMERA_ASSET_LIST_BY_FILTERS_SUCCESS =
  "GET_CAMERA_ASSET_LIST_BY_FILTERS_SUCCESS"
export const UPSERT_CAMERA_ASSET = "UPSERT_CAMERA_ASSET"
export const UPSERT_CAMERA_ASSET_SUCCESS = "UPSERT_CAMERA_ASSET_SUCCESS"
export const MODERATE_CAMERA_ASSET = "MODERATE_CAMERA_ASSET"
export const MODERATE_CAMERA_ASSET_SUCCESS = "MODERATE_CAMERA_ASSET_SUCCESS"

export const GET_VIDEO_MATERIALS_FILTERS_LIST =
  "GET_VIDEO_MATERIALS_FILTERS_LIST"
export const GET_VIDEO_MATERIALS_FILTERS_LIST_SUCCESS =
  "GET_VIDEO_MATERIALS_FILTERS_LIST_SUCCESS"
export const GET_VIDEO_MATERIALS_EFFECTS_LIST =
  "GET_VIDEO_MATERIALS_EFFECTS_LIST"
export const GET_VIDEO_MATERIALS_EFFECTS_LIST_SUCCESS =
  "GET_VIDEO_MATERIALS_EFFECTS_LIST_SUCCESS"
export const GET_VIDEO_MATERIALS_MASKS_LIST = "GET_VIDEO_MATERIALS_MASKS_LIST"
export const GET_VIDEO_MATERIALS_MASKS_LIST_SUCCESS =
  "GET_VIDEO_MATERIALS_MASKS_LIST_SUCCESS"

//CMS USERS
export const RESET_USERS_STATE = "RESET_USERS_STATE"
export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const GET_USER_DESK_MAPPING_LIST = "GET_USER_DESK_MAPPING_LIST"
export const GET_USER_DESK_MAPPING_LIST_SUCCESS =
  "GET_USER_DESK_MAPPING_LIST_SUCCESS"
export const GET_AGENCY_LIST = "GET_AGENCY_LIST"
export const GET_AGENCY_LIST_SUCCESS = "GET_AGENCY_LIST_SUCCESS"
export const GET_BULK_REPORT_LIST = "GET_BULK_REPORT_LIST"
export const GET_BULK_REPORT_LIST_SUCCESS = "GET_BULK_REPORT_LIST_SUCCESS"
export const GET_BULK_REPORT_BY_ID = "GET_BULK_REPORT_BY_ID"
export const GET_BULK_REPORT_BY_ID_SUCCESS = "GET_BULK_REPORT_BY_ID_SUCCESS"

//Contact Module const
export const FETCH_START = "fetch_start"
export const FETCH_SUCCESS = "fetch_success"
export const FETCH_ERROR = "fetch_error"
export const SHOW_MESSAGE = "SHOW_MESSAGE"
export const HIDE_MESSAGE = "HIDE_MESSAGE"
export const ON_SHOW_LOADER = "ON_SHOW_LOADER"
export const ON_HIDE_LOADER = "ON_HIDE_LOADER"
export const SET_MOBILE_VIEW = "SET_MOBILE_VIEW"
export const RESET_ERROR = "RESET_ERROR"

//handshake
export const GET_HANDSHAKE_CONFIG_DATA = "GET_HANDSHAKE_CONFIG_DATA"
export const GET_HANDSHAKE_CONFIG_DATA_SUCCESS =
  "GET_HANDSHAKE_CONFIG_DATA_SUCCESS"
export const RESET_HANDSHAKE_STATE = "RESET_HANDSHAKE_STATE"

export const SET_CURRENT_USER = "SET_CURRENT_USER"
export const SET_CURRENT_USER_SUCCESS = "SET_CURRENT_USER_SUCCESS"
export const GET_CURRENT_USER = "GET_CURRENT_USER"
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS"
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"

export const UPLOAD_AUDIO = "UPLOAD_AUDIO"
export const UPLOAD_AUDIO_SUCCESS = "UPLOAD_AUDIO_SUCCESS"

//LOCATION
export const GET_LOCATION_DATA = "GET_LOCATION_DATA"
export const GET_LOCATION_DATA_SUCCESS = "GET_LOCATION_DATA_SUCCESS"

//SECTION
export const GET_SECTION_DATA = "GET_SECTION_DATA"
export const GET_SECTION_DATA_SUCCESS = "GET_SECTION_DATA_SUCCESS"
export const CREATE_SECTION_DATA = "CREATE_SECTION_DATA"
export const CREATE_SECTION_DATA_SUCCESS = "CREATE_SECTION_DATA_SUCCESS"
export const UPDATE_SECTION_DATA = "UPDATE_SECTION_DATA"
export const UPDATE_SECTION_DATA_SUCCESS = "UPDATE_SECTION_DATA_SUCCESS"
export const DELETE_SECTION_DATA = "DELETE_SECTION_DATA"
export const DELETE_SECTION_DATA_SUCCESS = "DELETE_SECTION_DATA_SUCCESS"
export const RESET_SECTION_STORE = "RESET_SECTION_STORE"

//SEARCH ORDER
export const GET_HASHTAG_BY_FILTER = "GET_HASHTAG_BY_FILTER"
export const GET_HASHTAG_BY_FILTER_SUCCESS = "GET_HASHTAG_BY_FILTER_SUCCESS"
export const GET_ENTITY_SORT_ORDER = "GET_ENTITY_SORT_ORDER"
export const GET_ENTITY_SORT_ORDER_SUCCESS = "GET_ENTITY_SORT_ORDER_SUCCESS"
export const UDPATE_ENTITY_SORT_ORDER = "UDPATE_ENTITY_SORT_ORDER"
export const UDPATE_ENTITY_SORT_ORDER_SUCCESS =
  "UDPATE_ENTITY_SORT_ORDER_SUCCESS"
export const RESET_HASHTAG_STATE = "RESET_HASHTAG_STATE"

//VIDEO ASSETS
export const GET_VIDEO_MATERIAL_TYPES = "GET_VIDEO_MATERIAL_TYPES"
export const GET_VIDEO_MATERIAL_TYPES_SUCCESS =
  "GET_VIDEO_MATERIAL_TYPES_SUCCESS"
export const GET_ALL_RESOURCE_FOR_TYPE = "GET_ALL_RESOURCE_FOR_TYPE"
export const GET_ALL_RESOURCE_FOR_TYPE_SUCCESS =
  "GET_ALL_RESOURCE_FOR_TYPE_SUCCESS"
export const DELETE_VIDEO_MATERIAL_RESOURCE = "DELETE_VIDEO_MATERIAL_RESOURCE"
export const DELETE_VIDEO_MATERIAL_RESOURCE_SUCESSS =
  "DELETE_VIDEO_MATERIAL_RESOURCE_SUCESSS"
export const UPSERT_VIDEO_ASSET = "UPSERT_VIDEO_ASSET"
export const UPSERT_VIDEO_ASSET_SUCCESS = "UPSERT_VIDEO_ASSET_SUCCESS"
export const GET_TABS_LIST = "GET_TABS_LIST"
export const GET_TABS_LIST_SUCCESS = "GET_TABS_LIST_SUCCESS"
export const DELETE_TAB = "DELETE_TAB"
export const DELETE_TAB_SUCESSS = "DELETE_TAB_SUCESSS"
export const UPSERT_TAB_ASSET = "UPSERT_TAB_ASSET"
export const UPSERT_TAB_ASSET_SUCCESS = "UPSERT_TAB_ASSET_SUCCESS"
export const GET_ASSET_TYPES = "GET_ASSET_TYPES"
export const GET_ASSET_TYPES_SUCCESS = "GET_ASSET_TYPES_SUCCESS"
export const GET_ALL_ASSETS_FOR_ASSETTYPE = "GET_ALL_ASSETS_FOR_ASSETTYPE"
export const GET_ALL_ASSETS_FOR_ASSETTYPE_SUCCESS =
  "GET_ALL_ASSETS_FOR_ASSETTYPE_SUCCESS"
export const UPDATE_ASSET_MAPPING_ORDER = "UPDATE_ASSET_MAPPING_ORDER"
export const UPDATE_ASSET_MAPPING_ORDER_SUCCESS =
  "UPDATE_ASSET_MAPPING_ORDER_SUCCESS"
export const UPDATE_TAB_ORDER = "UPDATE_TAB_ORDER"
export const UPDATE_TAB_ORDER_SUCCESS = "UPDATE_TAB_ORDER_SUCCESS"
export const UPDATE_TAB_VERSION = "UPDATE_TAB_VERSION"
export const UPDATE_TAB_VERSION_SUCESSS = "UPDATE_TAB_VERSION_SUCESSS"
export const RESET_ASSET_STATE = "RESET_ASSET_STATE"

//STICKERS
export const GET_STICKERS_TAB_LIST = "GET_STICKERS_TAB_LIST"
export const GET_STICKERS_TAB_LIST_SUCCESS = "GET_STICKERS_TAB_LIST_SUCCESS"
export const UPSERT_STICKER_ASSET = "UPSERT_STICKER_ASSET"
export const UPSERT_STICKER_ASSET_SUCCESS = "UPSERT_STICKER_ASSET_SUCCESS"
export const UPDATE_STICKER_TAB_ORDER = "UPDATE_STICKER_TAB_ORDER"
export const UPDATE_STICKER_TAB_ORDER_SUCCESS =
  "UPDATE_STICKER_TAB_ORDER_SUCCESS"
export const RESET_STICKER_STATE = "RESET_STICKER_STATE"
export const DELETE_STICKER_TAB = "DELETE_STICKER_TAB"
export const DELETE_STICKER_TAB_SUCCESS = "DELETE_STICKER_TAB_SUCCESS"
export const UPDATE_STICKER_TAB_VERSION = "UPDATE_STICKER_TAB_VERSION"
export const UPDATE_STICKER_TAB_VERSION_SUCCESS =
  "UPDATE_STICKER_TAB_VERSION_SUCCESS"

export const GET_STICKERS_SUBTYPE_LIST = "GET_STICKERS_SUBTYPE_LIST"
export const GET_STICKERS_SUBTYPE_LIST_SUCCESS =
  "GET_STICKERS_SUBTYPE_LIST_SUCCESS"
export const UPSERT_STICKER_SUBTYPE = "UPSERT_STICKER_SUBTYPE"
export const UPSERT_STICKER_SUBTYPE_SUCCESS = "UPSERT_STICKER_SUBTYPE_SUCCESS"
export const UPDATE_STICKER_SUBTYPE_MAPPING = "UPDATE_STICKER_SUBTYPE_MAPPING"
export const UPDATE_STICKER_SUBTYPE_MAPPING_SUCCESS =
  "UPDATE_STICKER_SUBTYPE_MAPPING_SUCCESS"
export const DELETE_STICKER_SUBTYPE = "DELETE_STICKER_SUBTYPE"
export const DELETE_STICKER_SUBTYPE_SUCCESS = "DELETE_STICKER_SUBTYPE_SUCCESS"
export const GET_SUBTABS_FOR_TAB = "GET_SUBTABS_FOR_TAB"
export const GET_SUBTABS_FOR_TAB_SUCCESS = "GET_SUBTABS_FOR_TAB_SUCCESS"

export const GET_STICKERS = "GET_STICKERS"
export const GET_STICKERS_SUCCESS = "GET_STICKERS_SUCCESS"
export const GET_MIME_TYPES = "GET_MIME_TYPES"
export const GET_MIME_TYPES_SUCCESS = "GET_MIME_TYPES_SUCCESS"
export const UPDATE_STICKER_MAPPING_ORDER = "UPDATE_STICKER_MAPPING_ORDER"
export const UPDATE_STICKER_MAPPING_ORDER_SUCCESS =
  "UPDATE_STICKER_MAPPING_ORDER_SUCCESS"
export const DELETE_STICKER = "DELETE_STICKER"
export const DELETE_STICKER_SUCCESS = "DELETE_STICKER_SUCCESS"
export const UPSERT_STICKER = "UPSERT_STICKER"
export const UPSERT_STICKER_SUCCESS = "UPSERT_STICKER_SUCCESS"
export const GET_STICKERS_FOR_TAB_SUBTAB = "GET_STICKERS_FOR_TAB_SUBTAB"
export const GET_STICKERS_FOR_TAB_SUBTAB_SUCCESS =
  "GET_STICKERS_FOR_TAB_SUBTAB_SUCCESS"

/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"
