import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const getExclusionEntitiesData = async () => {
  try {
    const response = await Axios.get(`/api/exclusion-entities/get`)
    return response.data || {}
  } catch (error) {
    return error
  }
}

export const updateExclusionEntitiesData = async payload => {
  try {
    await Axios.post(`/api/exclusion-entities/update`, payload)
    toastr.success("Action Successful!")
  } catch (error) {
    return error
  }
}
