import Axios from "axios"
import QueryString from "query-string"

export const requestHashtagByFilters = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"] ? payload.filters["pageSize"] : null
  try {
    let data = await Axios.post(
      `/api/hashtag/getHashtagListByFilter?${QueryString.stringify({
        offset,
        limit,
      })}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    throw error
  }
}
