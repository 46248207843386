import { createSlice } from "@reduxjs/toolkit"

const badgeSlice = createSlice({
  name: "badge",
  initialState: {
    pageList: [],
    total: 0,
    loading: false,
    pageEntitiesByLanguageLocation: [],
    pageEntities: [],
    tabs: [],
    collectionEntitiesGroups: [],
    collectionEntities: [],
    entities: [],
    totalPageEntities: 0,
    refetchData: false,
  },
  reducers: {
    getBadges(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        entities: [],
      }
    },
    getBadgesSuccess(state, { payload }) {
      return {
        ...state,
        entities: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    upsertBadge(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertBadgeSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertBadgeCollectionEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertBadgeCollectionEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertBadgeCollection(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertBadgeCollectionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    deleteBadge(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    deleteBadgeSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    reorderGiftCollectionEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: true,
      }
    },
    reorderGiftCollectionEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upgradeBadgeListVersion(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upgradeBadgeListVersionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    resetState(state) {
      return {
        ...state,
        pageList: [],
        total: 0,
        loading: false,
        refetchData: false,
        pageEntitiesByLanguageLocation: [],
        pageEntities: [],
        tabs: [],
        collectionEntitiesGroups: [],
        collectionEntities: [],
        totalPageEntities: 0,
      }
    },
  },
})

export const {
  getBadges,
  getBadgesSuccess,
  upsertBadge,
  upsertBadgeSuccess,
  reorderGiftCollectionEntity,
  reorderGiftCollectionEntitySuccess,
  upsertBadgeCollectionEntity,
  upsertBadgeCollectionEntitySuccess,
  deleteBadge,
  deleteBadgeSuccess,
  upsertBadgeCollection,
  upsertBadgeCollectionSuccess,
  upgradeBadgeListVersion,
  upgradeBadgeListVersionSuccess,
  resetState,
} = badgeSlice.actions

export default badgeSlice.reducer
