import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"
import {
  getBrands,
  getBrandsSuccess,
  upsertMastheadContent,
  upsertMastheadContentSuccess,
  upsertWhitelistedUser,
  upsertWhitelistedUserSuccess,
} from "appRedux/slices/sponsoredTags"
import {
  requestBrands,
  requestUpsertMastheadContent,
  requestUpsertWhitelistedUser,
} from "../requests/sponsoredTags"

function* handleGetBrands(params) {
  try {
    const data = yield call(requestBrands, params)
    yield put(getBrandsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertUser(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertWhitelistedUser, params)
    yield put(upsertWhitelistedUserSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertMasthead(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertMastheadContent, params)
    yield put(upsertMastheadContentSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getBrands.type, handleGetBrands)
  yield takeLatest(upsertWhitelistedUser.type, handleUpsertUser)
  yield takeLatest(upsertMastheadContent.type, handleUpsertMasthead)
}
