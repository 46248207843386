import React from "react"
import { connect } from "react-redux"
import Auxiliary from "../../util/Auxiliary"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import BootstrapTable from "react-bootstrap-table-next"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Row, Col, Button } from "reactstrap"
import { Tooltip, Pagination, Upload, Divider } from "antd"
import _ from "lodash"
import moment from "moment"
import toastr from "toastr"
import QueryString from "query-string"
import {
  getKyc,
  getPendingKYCCSVData,
  uploadKycData,
} from "../../appRedux/slices/kyc"
import { Modal } from "react-bootstrap"
import ValidatorForm from "../ValidatorForm"
import * as XLSX from "xlsx"
import Chips from "components/CommonFilter/chips"
import { panKycFilters } from "constants/uiConstants"
import { Button as MuiButton, Stack } from "@mui/material"
import { MdDownload, MdUpload } from "react-icons/md"
import NoDataFound from "components/NoDataFound/noDataFound"
import { reportKYCStatus, updateKYCStatus } from "clientServices/kycService"
import { AiOutlineStop } from "react-icons/ai"

import { Input, Select, DatePicker } from "antd"
const { Option } = Select
class BrowseKYC extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      showUploadModal: false,
      toggleDownloadReport: false,
      filters: {
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        size:
          this.props.queryParams && this.props.queryParams["size"]
            ? this.props.queryParams["size"]
            : 100,
        kycNumber:
          this.props.queryParams && this.props.queryParams["kycNumber"]
            ? this.props.queryParams["kycNumber"]
            : "",
        userUuid:
          this.props.queryParams && this.props.queryParams["userUuid"]
            ? this.props.queryParams["userUuid"]
            : "",
        status:
          this.props.queryParams && this.props.queryParams["status"]
            ? this.props.queryParams["status"]
            : "",
      },
      reportFilters: {
        from: "",
        to: "",
        status: "",
      },
      columns: [
        {
          dataField: "name",
          text: "User",
          style: {
            width: "10%",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-row text-capitalize">
                <CopyToClipboard
                  text={entity.userUuid}
                  onCopy={e => {
                    toastr.success("Copied sucessfully!")
                  }}
                >
                  <Tooltip title="Copy User UUID">
                    <i className="fontSize18 bx bx-copy me-2" role="button" />
                  </Tooltip>
                </CopyToClipboard>
                <div className="pe-2">{cellContent || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "kycNumber",
          text: "PAN Number",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "20%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>{cellContent || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "status",
          text: "Status",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "20%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>
                  {cellContent ? cellContent.split("_").join(" ") : "-"}
                </div>
              </div>
            )
          },
        },
        {
          dataField: "reason",
          text: "Reason",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "25%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>{cellContent || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "modifiedOn",
          text: "Modified On",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "25%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>
                  {cellContent
                    ? moment(cellContent)
                        .utcOffset("+00:00")
                        .format("DD-MM-YYYY hh:mm:ss A")
                    : "-"}
                </div>
              </div>
            )
          },
        },
        {
          dataField: "actions",
          text: "Action",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "25%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>
                  <Tooltip title="Mark Failed Status">
                    <AiOutlineStop
                      style={{ fontSize: "17px" }}
                      onClick={() => this._onMarkfailed(entity)}
                    ></AiOutlineStop>
                    {/* <i
                    className="bx bx-error-circle"
                    role="button"
                   
                  /> */}
                  </Tooltip>
                </div>
              </div>
            )
          },
        },
      ],
    }
  }

  componentDidMount = () => {
    const newFilters = _.cloneDeep(this.state.filters)
    this.props.dispatch(getKyc(_.deepClean(newFilters)))

    this.props.dispatch(getPendingKYCCSVData())
    let filteredcolumn = this.state.columns
    if (
      !this.props.currentUser.permissions.includes("EDIT_KYC_FAILED_STATUS")
    ) {
      filteredcolumn = this.state.columns.filter(
        action => action.dataField != "actions"
      )
    }
    this.setState({ ...this.state, ...{ columns: filteredcolumn } })

    this.props.history.push(
      `/kyc/browse?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
  }

  _onMarkfailed = async data => {
    // let payload=data;

    await updateKYCStatus({
      ...data,
      created_by: this.props.currentUser.email,
      status: "FAILED",
      reason: "PAN Failed due to PAN not linked with Aadhar",
    })
    const newFilters = _.cloneDeep(this.state.filters)
    this.props.dispatch(getKyc(_.deepClean(newFilters)))

    this.props.dispatch(getPendingKYCCSVData())
    let filteredcolumn = this.state.columns

    this.setState({ ...this.state, ...{ columns: filteredcolumn } })
  }

  _search = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/kyc/browse?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
    this.props.dispatch(getKyc(_.deepClean(filters))) // this.setState({
    //   showFilters: false,
    //   showAdvancedFilters: false,
    // })
  }
  _closeFilters = () => {
    this._search()
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: panKycFilters[filter].defaultValue,
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/kyc/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )

        this.props.dispatch(getKyc(_.deepClean(newFilters)))
      }
    )
  }
  _handleFieldChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/kyc/browse?${QueryString.stringify(_.deepClean(this.state.filters))}`
      )
      this.props.dispatch(getKyc(_.deepClean(newFilters)))
    })
  }

  _onVideoRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }

  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (!this.state.csvFile) {
      toastr.error("Please upload the csv !!")
      return
    }

    let data = new FormData()
    data.append("file", this.state.csvFile)

    this.props.dispatch(uploadKycData(data))
    this.setState({
      showUploadModal: false,
    })
    this._onVideoRemove()
  }

  handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      this.props.csvData.slice(1) || []
    )
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "pending-kyc-lis")
    XLSX.utils.sheet_add_aoa(worksheet, [this.props.csvData[0]], {
      origin: "A1",
    })
    XLSX.writeFile(workbook, "PendingKYCList.xlsx")
  }
  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  handleDownloadReport = () => {
    this.setState({
      toggleDownloadReport: !this.state.toggleDownloadReport,
    })
  }
  handleFilterChanges = (value, type) => {
    const newFilters = _.cloneDeep(this.state.reportFilters)
    newFilters[type] = value

    this.setState({
      reportFilters: newFilters,
    })
  }

  submitreport = async () => {
    // console.log(this.state.reportFilters)

    const report = await reportKYCStatus(this.state.reportFilters)
    // console.log(report)
    let reportcsv = [["PAN", "USER UUID", "UPDATED ON", "STATUS"]]
    report.rows.map(item => {
      reportcsv.push([
        item.kycNumber,
        item.userUuid,
        item.modifiedOn,
        item.status,
      ])
    })
    const worksheet = XLSX.utils.json_to_sheet(reportcsv.slice(1) || [])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "kyc-report")
    XLSX.utils.sheet_add_aoa(worksheet, [reportcsv[0]], {
      origin: "A1",
    })
    XLSX.writeFile(workbook, "KycReport.xlsx")
  }
  downloadReportForm = () => {
    return (
      <>
        <Row className="mb-3">
          <Col lg="2" md="2">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">From</h5>
            </Divider>
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={
                this.state.reportFilters["from"]
                  ? moment(this.state.reportFilters["from"], "YYYY-MM-DD")
                  : null
              }
              format="YYYY-MM-DD"
              onChange={(moment, dateString) => {
                this.handleFilterChanges(dateString, "from")
              }}
            />
          </Col>
          <Col lg="2" md="2">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">To</h5>
            </Divider>
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={
                this.state.reportFilters["to"]
                  ? moment(this.state.reportFilters["to"], "YYYY-MM-DD")
                  : null
              }
              format="YYYY-MM-DD"
              onChange={(moment, dateString) => {
                this.handleFilterChanges(dateString, "to")
              }}
            />
          </Col>
          {
            <Col lg="2" md="2">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Status</h5>
              </Divider>
              <Input.Group compact={true}>
                <Select
                  placeholder="Status"
                  style={{ width: "100%" }}
                  allowClear={true}
                  defaultValue={this.state.reportFilters["status"]}
                  onChange={value => this.handleFilterChanges(value, "status")}
                >
                  <Option value="">ALL</Option>
                  <Option value="SUCCESS">SUCCESS</Option>
                  <Option value="IN_PROGRESS">IN_PROGRESS</Option>
                  <Option value="FAILED">FAILED</Option>
                </Select>
              </Input.Group>
            </Col>
          }
        </Row>
        <Row>
          <div className="d-flex justify-content-end">
            <Button
              htmlType="submit"
              className="font-16 btn-block btn btn-primary"
              color="primary"
              disabled={this.state.loading}
              onClick={() => {
                this.submitreport()
              }}
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                return
              }}
            >
              Submit
            </Button>
            <Button
              color="danger"
              size="default"
              disabled={this.state.loading}
              onDoubleClick={e => {
                e.preventDefault()
                e.stopPropagation()
                return
              }}
              onClick={() => this.handleDownloadReport()}
              className="mx-2"
            >
              Cancel
            </Button>
          </div>
        </Row>
      </>
    )
  }

  render() {
    return (
      <>
        <div className="bg-white p-2">
          <Auxiliary
            loading={this.props.loading}
            error={_.get(this.props, "common.error")}
          >
            {" "}
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="p-1">
                <MuiButton
                  variant="outlined"
                  startIcon={<MdDownload />}
                  disabled={
                    !this.props?.csvData?.length || this.props.csvLoading
                  }
                  style={{ marginLeft: "13px" }}
                  onClick={this.handleDownloadReport}
                >
                  Download Report
                </MuiButton>
              </div>
              <div className="d-flex justify-content-end p-1">
                <Stack direction="row" spacing={1}>
                  <MuiButton
                    variant="outlined"
                    startIcon={<MdDownload />}
                    disabled={
                      !this.props?.csvData?.length || this.props.csvLoading
                    }
                    onClick={this.handleDownloadExcel}
                  >
                    Pending KYC
                  </MuiButton>
                  {/* </CSVLink> */}
                  <MuiButton
                    variant="outlined"
                    startIcon={<MdUpload />}
                    onClick={() => this.setState({ showUploadModal: true })}
                  >
                    KYC Report
                  </MuiButton>
                </Stack>
              </div>
            </div>
            {!this.state.toggleDownloadReport && (
              <Row>
                <Col span={22}>
                  <Chips
                    showFilters={this.state.showFilters}
                    filters={this.state.filters}
                    search={this._closeFilters}
                    removeFilter={this._removeFilter}
                    handleFilterChange={this._handleFieldChange}
                    type="panKyc"
                    toggleFilters={this._toggleFilters}
                  />
                </Col>
              </Row>
            )}
            {this.state.showUploadModal ? (
              <div>
                <Modal.Header>
                  <Modal.Title className="font-size-18 text-wrap">
                    Upload KYC Report
                  </Modal.Title>
                </Modal.Header>
                <ValidatorForm
                  onSubmit={this._onSubmit}
                  layout={"vertical"}
                  {...this._formLayout}
                >
                  <div className="d-flex align-items-baseline border-bottom">
                    <Modal.Body>
                      <Upload
                        showUploadList={true}
                        onRemove={this._onVideoRemove}
                        defaultFileList={this.state.csvFileList}
                        fileList={this.state.csvFileList}
                        customRequest={this._uploadCSV}
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                      >
                        <Button color="primary">
                          <i className="bx bx-upload me-2" /> Click to upload
                          CSV
                        </Button>
                      </Upload>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="my-1"
                        color="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        className="my-1 mx-2"
                        color="danger"
                        onClick={() => {
                          this.setState({
                            showUploadModal: false,
                          })
                          this._onVideoRemove()
                        }}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </div>
                </ValidatorForm>
              </div>
            ) : // </Modal>
            null}
            {this.state.toggleDownloadReport && this.downloadReportForm()}
            {!this.state.toggleDownloadReport &&
              (this.props.kycList && this.props.kycList?.length > 0 ? (
                <div className="m-2 p-2">
                  <ToolkitProvider
                    keyField="id"
                    data={this.props.kycList || []}
                    columns={this.state.columns}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <div className="table-responsive">
                          <BootstrapTable
                            {...toolkitProps.baseProps}
                            // {...paginationTableProps}
                            responsive
                            //   defaultSorted={defaultSorted}
                            bordered={false}
                            striped={false}
                            // selectRow={selectRow}
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                  <div className="d-flex justify-content-end">
                    <Pagination
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                      onChange={this._onPageChange}
                      defaultCurrent={this.state.filters.currentPage}
                      total={
                        this.props.total < 10000 ? this.props.total : 10000
                      }
                      pageSize={this.state.filters.size}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              ) : (
                <NoDataFound message="No data present" />
              ))}
          </Auxiliary>
        </div>
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    kycList: _.get(store, "kyc.kycList") || [],
    refetch: _.get(store, "kyc.refetch") || false,
    loading: _.get(store, "kyc.loading") || false,
    csvData: _.get(store, "kyc.csvData") || false,
    csvLoading: _.get(store, "kyc.csvLoading") || false,
    total: _.get(store, "kyc.total"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(BrowseKYC)
