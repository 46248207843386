import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import QueryString from "query-string"
import { resetState } from "../../appRedux/slices/campaign"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import Box from "@mui/material/Box"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import CopyToClipboard from "react-copy-to-clipboard"
import { requestGetJoshPaymentAudit } from "clientServices/kycService"
import "../Campaign/styles.css"
import Chips from "components/CommonFilter/chips"
import { Tooltip } from "antd"
import toastr from "toastr"

const regexExp =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
const columns = [
  { id: "id", label: "Transaction ID", minWidth: 80 },
  { id: "user_uuid", label: "Transaction By", minWidth: 80 },
  {
    id: "action",
    label: "Action",
    minWidth: 120,
  },
  {
    id: "transaction_type",
    label: "Transaction Type",
    minWidth: 120,
  },
  {
    id: "platform_currency_count",
    label: "Platform Currency Count",
    minWidth: 120,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
  },
  {
    id: "created_date",
    label: "Created Date",
    minWidth: 150,
  },
]

class JoshPaymentAudit extends ErrorBoundComponent {
  state = {
    showList: false,
    kycData: {},
    giftData: {},
    redeemData: {},
    beneficiaryData: {},
    purchaseData: {},
    searchdetails: {
      type: "",
    },
    loader: false,
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 0,
    },
    showFilters: false,
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      let data = {}
      try {
        data = await requestGetJoshPaymentAudit({
          filters: { ...this.state.filters },
        })
        this.setState({
          showList: true,
          auditData: data.data,
          total: data.total.value,
          searchdetails: {
            ...this.state.searchdetails,
            type: "JOSH_PAYMENT_AUDIT",
          },
        })
      } catch {
        data = {}
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }
  setLoader = val => {
    this.setState({ loader: val })
  }
  setPage = page => {
    this.setState({ ...this.state, page })
  }

  buttonClick = (details, status) => {
    this.setState({
      ...this.state,

      ...{
        searchdetails: { type: details.name, status },
        showList: true,
        page: 0,
        kycFilters: {
          userUuid: "",
          kycNumber: "",
        },
      },
    })
  }
  card = details => {
    return (
      <React.Fragment>
        <CardContent>
          <Typography
            style={{ textAlign: "center" }}
            variant="h5"
            component="div"
          >
            {details.name}
          </Typography>
          <br />
          <Typography variant="body1">
            <Button
              onClick={() => this.buttonClick(details)}
              variant="outlined"
              style={
                details.name == this.state.searchdetails.type &&
                this.state.searchdetails.status == undefined
                  ? {
                      borderColor: "#1976d2",
                      backgroundColor: "#1976d2",
                      color: "white",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
                  : {
                      borderColor: "#1976d2",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
              }
            >
              {" "}
              Total: {details.total}
            </Button>

            <br />
            <Button
              onClick={() => this.buttonClick(details, "SUCCESS")}
              style={
                details.name == this.state.searchdetails.type &&
                this.state.searchdetails.status == "SUCCESS"
                  ? {
                      borderColor: "green",
                      backgroundColor: "green",
                      color: "white",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
                  : {
                      color: "green",
                      borderColor: "green",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
              }
              variant="outlined"
            >
              {" "}
              Success: {details.success}
            </Button>

            <br />

            <Button
              onClick={() => {
                this.setState(() => this.buttonClick(details, "IN_PROGRESS"))
              }}
              color="warning"
              style={
                details.name == this.state.searchdetails.type &&
                this.state.searchdetails.status == "IN_PROGRESS"
                  ? {
                      borderColor: "#ed6c02",
                      backgroundColor: "#ed6c02",
                      color: "white",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
                  : {
                      padding: "5px",
                      color: "#ed6c02",
                      borderColor: "#ed6c02",
                      width: "100%",
                      marginBottom: "5px",
                    }
              }
              variant="outlined"
            >
              {" "}
              In Progress: {details.in_progress}
            </Button>

            <br />
            <Button
              onClick={() => this.buttonClick(details, "FAILED")}
              style={
                details.name == this.state.searchdetails.type &&
                this.state.searchdetails.status == "FAILED"
                  ? {
                      borderColor: "red",
                      backgroundColor: "red",
                      color: "white",
                      padding: "5px",
                      width: "100%",
                      marginBottom: "5px",
                    }
                  : {
                      color: "red",
                      borderColor: "red",
                      padding: "5px",
                      width: "100%",
                    }
              }
              variant="outlined"
            >
              {" "}
              Failed: {details.failed}
            </Button>
          </Typography>
        </CardContent>
      </React.Fragment>
    )
  }

  setFilters = filters => {
    this.setState({
      kycFilters: filters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    if (
      type === "searchText" ||
      type === "order" ||
      type === "moderationLevel" ||
      type === "langCode"
    ) {
      this.setState({
        filters: newFilters,
      })
    } else {
      this.setState({
        filters: newFilters,
      })
    }
  }

  _handleChangePage = async (event, newPage) => {
    this.setState({
      filters: {
        ...this.state.filters,
        currentPage: newPage,
      },
    })

    let data = {}
    try {
      data = await requestGetJoshPaymentAudit({
        filters: { ...this.state.filters, currentPage: newPage },
      })
      this.setState({
        showList: true,
        auditData: data.data,
        total: data.total.value,
        searchdetails: {
          ...this.state.searchdetails,
          type: "JOSH_PAYMENT_AUDIT",
        },
      })
    } catch {
      data = {}
    }
  }

  render() {
    return (
      <Auxiliary error={_.get(this.props, "common.error")}>
        <Chips
          showFilters={this.state.showFilters}
          filters={this.state.filters}
          // search={this._closeFilters}
          removeFilter={this._removeFilter}
          handleFilterChange={this._handleFilterChange}
          type="josh_payment_audit"
          toggleFilters={this._toggleFilters}
          toggleAdvancedFilters={this._toggleAdvancedFilters}
        />
        <Box sx={{ boxShadow: 1 }}>
          <TableContainer sx={{}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontWeight: 800 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.auditData?.map(row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map(column => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}{" "}
                            {regexExp.test(value) ? (
                              <CopyToClipboard
                                text={value}
                                onCopy={e => {
                                  toastr.success("Copied sucessfully!")
                                }}
                              >
                                <Tooltip title="Copy Id">
                                  <i
                                    className="fontSize18 bx bx-copy-alt"
                                    role="button"
                                  />
                                </Tooltip>
                              </CopyToClipboard>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={this.state.total}
            rowsPerPage={this.state.filters.pageSize}
            page={this.state.filters.currentPage}
            onPageChange={this._handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Auxiliary>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,

    total: _.get(store, "trending.total"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}
export default connect(mapStateToProps)(withRouter(JoshPaymentAudit))
