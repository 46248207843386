import React, { createRef } from "react"
import { connect } from "react-redux"
import CopyToClipboard from "react-copy-to-clipboard"
import QueryString from "query-string"
import _ from "lodash"
import { Card, CardBody, Col, Row, Badge, Button } from "reactstrap"
import { withRouter } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Tooltip } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Radio, { Group } from "antd/lib/radio"
import { markDTATaskAsCompleted } from "clientServices/contentService"
import { getSourceDetails } from "clientServices/sourceService"
import { zoneUpdateReview } from "clientServices/appUserService"
import "../../containers/Content/browseContent.style.css"

class ZoneReviewDetail extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      zone: this.props.zone,
      // zone: {
      //   ...this.props.zone,
      //   generated_banner: [
      //     {
      //       thumbnail:
      //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
      //       id: "b117c82d-9ec6-45c5-97d3-984dd4af0c3e",
      //       template_id: "collage_1",
      //       status: "APPROVED",
      //     },
      //     {
      //       thumbnail:
      //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
      //       id: "c117c82d-9ec6-45c5-97d3-984dd4af0c3e",
      //       template_id: "collage_1",
      //       status: "APPROVED",
      //     },
      //   ],
      //   generated_profile_images: [
      //     {
      //       thumbnail:
      //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
      //       id: "d117c82d-9ec6-45c5-97d3-984dd4af0c3e",
      //       template_id: "collage_1",
      //       status: "APPROVED",
      //     },
      //     {
      //       thumbnail:
      //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
      //       id: "e117c82d-9ec6-45c5-97d3-984dd4af0c3e",
      //       template_id: "collage_1",
      //       status: "APPROVED",
      //     },
      //   ],
      // },
      selectedBanner: [],
      selectedProfile: "",
    }
    this.videoRef = createRef()
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.setState({
        zone: this.props.zone,
        // zone: {
        //   ...this.props.zone,
        //   generated_banner: [
        //     {
        //       thumbnail:
        //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
        //       id: "b117c82d-9ec6-45c5-97d3-984dd4af0c3e",
        //       template_id: "collage_1",
        //       status: "APPROVED",
        //     },
        //     {
        //       thumbnail:
        //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
        //       id: "c117c82d-9ec6-45c5-97d3-984dd4af0c3e",
        //       template_id: "collage_1",
        //       status: "APPROVED",
        //     },
        //   ],
        //   generated_profile_images: [
        //     {
        //       thumbnail:
        //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
        //       id: "d117c82d-9ec6-45c5-97d3-984dd4af0c3e",
        //       template_id: "collage_1",
        //       status: "APPROVED",
        //     },
        //     {
        //       thumbnail:
        //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
        //       id: "e117c82d-9ec6-45c5-97d3-984dd4af0c3e",
        //       template_id: "collage_1",
        //       status: "APPROVED",
        //     },
        //   ],
        // },
      })

      //   this.props.dispatch(getZones({ currentPage: 1, pageSize: 10000 }))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.common.error) {
      if (prevProps.zone.zone_uuid !== prevState.zone.zone_uuid) {
        this.setState({
          zone: this.props.zone,
          // zone: {
          //   ...this.props.zone,
          //   generated_banner: [
          //     {
          //       thumbnail:
          //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
          //       id: "b117c82d-9ec6-45c5-97d3-984dd4af0c3e",
          //       template_id: "collage_1",
          //       status: "APPROVED",
          //     },
          //     {
          //       thumbnail:
          //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
          //       id: "c117c82d-9ec6-45c5-97d3-984dd4af0c3e",
          //       template_id: "collage_1",
          //       status: "APPROVED",
          //     },
          //   ],
          //   generated_profile_images: [
          //     {
          //       thumbnail:
          //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
          //       id: "d117c82d-9ec6-45c5-97d3-984dd4af0c3e",
          //       template_id: "collage_1",
          //       status: "APPROVED",
          //     },
          //     {
          //       thumbnail:
          //         "https://stream.myjosh.in/stream/prod-ugc-contents/zone/925540cbd73d4e55/b81a1c2c5d8ebaa0/b117c82d-9ec6-45c5-97d3-984dd4af0c3e_1655435593_hi_thumbnail.webp",
          //       id: "e117c82d-9ec6-45c5-97d3-984dd4af0c3e",
          //       template_id: "collage_1",
          //       status: "APPROVED",
          //     },
          //   ],
          // },
        })
      }
    }
  }

  onChangeBanner(id) {
    let selected = this.state.selectedBanner
    let find = selected.indexOf(id)

    if (find > -1) {
      selected.splice(find, 1)
    } else {
      selected.push(id)
    }

    this.setState({ selected })
  }
  onChangeProfile(e) {
    this.setState({
      selectedProfile: e.target.value,
    })
  }
  disabledone() {
    let disable = false

    if (
      this.state.zone.generated_banner &&
      !this.state.zone.generated_profile_images
    ) {
      if (this.state.selectedBanner.length) {
        disable = false
      } else {
        disable = true
      }
    } else if (
      !this.state.zone.generated_banner &&
      this.state.zone.generated_profile_images
    ) {
      if (this.state.selectedProfile.length) {
        disable = false
      } else {
        disable = true
      }
    } else {
      if (!(this.state.selectedProfile && this.state.selectedBanner?.length)) {
        disable = true
      } else {
        disable = false
      }
    }
    return disable
  }
  async _updateZone(action) {
    let selectedZone = _.cloneDeep(this.state.zone)
    let sourceData = {}
    toastr.info("Action in progress...")
    if (selectedZone && selectedZone.source_id) {
      sourceData = await getSourceDetails(selectedZone.source_id)
    }
    let banner = []
    let profileImg = []
    let selectedbanner = { ...this.state.selectedBanner }
    if (action == "skip") {
      const payload = {
        zone_uuid: selectedZone.zone_uuid,
        status: "DRAFT",
        action: action,
      }
      const response = await zoneUpdateReview({
        payload,
      })
      if (response && response.status === 200) {
        const taskResponse = await markDTATaskAsCompleted({
          sourceData,
          content: selectedZone,
        })
        if (taskResponse && taskResponse?.data?.status?.code === 200) {
          toastr.success("Action Successfull !!")
          this.props.nextContent()
        }
      } else {
        toastr.error("Error in review submission!!")
      }
    } else {
      //mark status approved for banner and profile image
      if (selectedZone.generated_banner) {
        banner = selectedZone?.generated_banner.map(item => {
          if (this.state.selectedBanner.includes(item.template_id)) {
            item.status = "APPROVED"
          }
          return item
        })
      }
      if (selectedZone.generated_profile_images) {
        profileImg = selectedZone?.generated_profile_images.map(item => {
          if (this.state.selectedProfile.includes(item.id)) {
            item.status = "APPROVED"
          }
          return item
        })
      }

      const payload = {
        zone_uuid: selectedZone.zone_uuid,
        generated_profile_images:
          profileImg.length > 0 ? profileImg : undefined,
        generated_banner: banner.length > 0 ? banner : undefined,
        reviewed_by: this.props.currentUser.email,
        reviewed_on: new Date(),
      }
      // this.props.nextContent();
      const response = await zoneUpdateReview({
        payload,
      })
      if (response && response.status === 200) {
        const taskResponse = await markDTATaskAsCompleted({
          sourceData,
          content: selectedZone,
        })
        if (taskResponse && taskResponse?.data?.status?.code === 200) {
          toastr.success("Action Successfull !!")
          this.props.nextContent()
        }
      } else {
        toastr.error("Error in review submission!!")
      }
    }
  }
  render() {
    const {
      title,
      zone_uuid,
      zone_type,
      sub_title,
      level,
      description,
      sub_type,
      type,
    } = this.state.zone || {}
    return (
      <>
        <Card>
          <CardBody className="pt-4">
            <div
              className="text-muted mt-1"
              style={{
                marginBottom: "7px",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Zone Details
            </div>

            <div className="grid-container">
              <div className="grid-child">
                <div className="d-flex align-items-center justify-content-between">
                  <td>Zone Title</td>
                  <th>{title || ""}</th>
                </div>
              </div>
              <div className="grid-child">
                <div className="d-flex align-items-center justify-content-between">
                  <td>Zone UUID</td>
                  <th>
                    {zone_uuid || ""}
                    <CopyToClipboard
                      text={zone_uuid}
                      onCopy={e => {
                        toastr.success("Copied sucessfully!")
                      }}
                    >
                      <Tooltip title="Copy Zone ID">
                        <i className="bx bx-copy mx-1" role="button" />
                      </Tooltip>
                    </CopyToClipboard>
                  </th>
                </div>
              </div>
              <div className="language">
                <div className="d-flex align-items-center justify-content-between">
                  <td>Zone Type</td>
                  <th>{zone_type || ""}</th>
                </div>
              </div>
              <div className="language">
                <div className="d-flex align-items-center justify-content-between">
                  <td>Sub Title</td>
                  <th>{sub_title || ""}</th>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <div>Level</div>
                  <div style={{ fontWeight: "600" }}>{level || ""}</div>
                </div>
              </div>
              <div className="created-by">
                <div className="d-flex align-items-center font-size-14 justify-content-between">
                  <td>Description</td>
                  <th>{description || ""}</th>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        {(this.state.zone?.generated_profile_images ||
          this.state.zone?.generated_banner) && (
          <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="d-flex "
              style={{ paddingLeft: "12%" }}
            >
              {this.state.zone?.generated_profile_images && (
                <div style={{ flex: 1 }}>
                  <div className="mb-2 p-1  font-weight-semibold  d-flex justify-content-between align-items-center">
                    Select Profile Images:
                  </div>

                  <Group
                    style={{ display: "contents" }}
                    onChange={e => this.onChangeProfile(e)}
                  >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {this.state.zone?.generated_profile_images.map(
                        language => (
                          <div
                            className="topicRadioBtn2"
                            style={{ width: "fit-content" }}
                          >
                            <Radio value={language.id}>
                              {
                                <img
                                  src={language.thumbnail}
                                  height="200px"
                                  width="300px"
                                />
                              }
                            </Radio>
                          </div>
                        )
                      )}
                    </div>
                  </Group>
                </div>
              )}

              {this.state.zone.generated_banner && (
                <div style={{ flex: 1 }}>
                  <div className="mb-2 p-1 font-weight-semibold  d-flex justify-content-between align-items-center">
                    Select Banners:
                  </div>

                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {this.state.zone?.generated_banner.map(item => {
                      return (
                        <div
                          className="topicRadioBtn2"
                          style={{ width: "fit-content" }}
                        >
                          <label key={item.template_id} className="m-0 d-flex">
                            <input
                              type="checkbox"
                              onChange={() =>
                                this.onChangeBanner(item.template_id)
                              }
                              selected={this.state.selectedBanner.includes(
                                item.template_id
                              )}
                              className="me-2"
                            ></input>
                            <img
                              src={item.thumbnail}
                              height="200px"
                              width="300px"
                              className="me-3"
                            />
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        )}
        <Row>
          {!this.state.zone?.generated_profile_images &&
            !this.state.zone?.generated_banner && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                No Profile Image & Banner to Review{" "}
              </div>
            )}
        </Row>
        <Row>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            className="d-flex"
            style={{ justifyContent: "flex-end", paddingRight: "25px" }}
          >
            <Button
              role="button"
              style={{ width: "fit-content" }}
              className="btn btn-success d-flex mx-1 mt-2"
              onClick={() => {
                this._updateZone("skip")
              }}
              // disabled={this.disabledone()}
            >
              Skip
            </Button>
            {(this.state.zone?.generated_profile_images ||
              this.state.zone?.generated_banner) && (
              <Button
                role="button"
                style={{ width: "fit-content" }}
                className="btn btn-success d-flex mx-1 mt-2"
                onClick={() => {
                  this._updateZone("save")
                }}
                disabled={this.disabledone()}
              >
                Save
              </Button>
            )}
          </Col>
        </Row>
      </>
    )
  }
}

function mapStateToProps(store) {
  return {
    contentList: _.get(store, "content.content"),
    locationList: _.get(store, "locationReducer.locationList"),
    audioList: _.get(store, "audio.audioList"),
    queryParams: _.get(store, "routing.location.search")
      ? QueryString.parseUrl(_.get(store, "routing.location.search")).query
      : undefined,
    total: _.get(store, "content.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "locationReducer.loading"),
    trendingEntityList: _.get(store, "trending.trendingEntityList"),
  }
}

export default withRouter(connect(mapStateToProps)(ZoneReviewDetail))
