import Axios from "axios"
import toastr from "toastr"

export const getInstagramHandles = async formData => {
  try {
    let data = await Axios.post(`/api/crawler/instagram/handles`, {
      ...formData,
    })
    return data
  } catch (error) {
    toastr.error("Error in getting the data")
    throw error
  }
}

export const upsertInstagramData = async formData => {
  try {
    let data = await Axios.post(`/api/crawler/instagram/upsert-handles`, {
      ...formData,
    })
    toastr.info("Handle Is Ingested")
    return data
  } catch (error) {
    toastr.error(
      error && error?.response?.data?.message
        ? error.response.data.message
        : "Error in upserting the data"
    )
    throw error
  }
}

export const upsertBulkInstagramData = async formData => {
  try {
    let data = await Axios.post(`/api/crawler/handles/bulk`, formData)
    return data
  } catch (error) {
    toastr.error("Error in upserting the data")
    throw error
  }
}
export const getYoutubeHandles = async formData => {
  try {
    let data = await Axios.post(`/api/crawler/youtube/handles`, {
      ...formData,
    })
    return data
  } catch (error) {
    toastr.error("Error in getting the data")
    throw error
  }
}

export const upsertYoutubeData = async formData => {
  try {
    let data = await Axios.post(`/api/crawler/youtube/upsert-handles`, {
      ...formData,
    })
    toastr.info("Handle Is Ingested")
    return data
  } catch (error) {
    toastr.error(
      error && error?.response?.data?.message
        ? error.response.data.message
        : "Error in upserting the data"
    )
    throw error
  }
}

export const upsertBulkYoutubeData = async formData => {
  try {
    let data = await Axios.post(`/api/crawler/youtube/handles/bulk`, formData)
    return data
  } catch (error) {
    toastr.error("Error in upserting the data")
    throw error
  }
}
