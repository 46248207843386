import { call, takeLatest, put } from "redux-saga/effects"
import toastr from "toastr"
import {
  requestAudioDetails,
  requestGetAudioTabs,
  requestUpdateAudioStatus,
  requestUpdateAudioVisibility,
  requestUploadAudio,
  requestUpsertAudio,
  requestPlaylist,
} from "appRedux/sagas/requests/audio"
import {
  getAudioDetails,
  getAudioDetailsSuccess,
  getAudioTabs,
  getAudioTabsSuccess,
  updateAudioStatus,
  updateAudioStatusSuccess,
  updateAudioVisibility,
  updateAudioVisibilitySuccess,
  uploadBulkAudio,
  uploadBulkAudioSuccess,
  upsertAudio,
  upsertAudioSuccess,
  getPlayList,
  getPlayListSuccess,
} from "appRedux/slices/audio"
import { fetchError } from "appRedux/slices/common"

function* handleGetAudioDetails(params) {
  try {
    const data = yield call(requestAudioDetails, params)
    yield put(getAudioDetailsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAudioTabs(params) {
  try {
    const data = yield call(requestGetAudioTabs, params)
    yield put(getAudioTabsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertAudio(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertAudio, params)
    yield put(upsertAudioSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUploadAudio(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUploadAudio, params)
    yield put(uploadBulkAudioSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateAudioStatus(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdateAudioStatus, params)
    yield put(updateAudioStatusSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateAudioVisibility(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpdateAudioVisibility, params)
    yield put(updateAudioVisibilitySuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handlePlayList() {
  try {
    const data = yield call(requestPlaylist)
    yield put(getPlayListSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getAudioDetails.type, handleGetAudioDetails)
  yield takeLatest(getAudioTabs.type, handleGetAudioTabs)
  yield takeLatest(upsertAudio.type, handleUpsertAudio)
  yield takeLatest(uploadBulkAudio.type, handleUploadAudio)
  yield takeLatest(updateAudioStatus.type, handleUpdateAudioStatus)
  yield takeLatest(updateAudioVisibility.type, handleUpdateAudioVisibility)
  yield takeLatest(getPlayList.type, handlePlayList)
}
