import Axios from "axios"

export const createUser = async ({ data }) => {
  try {
    return await Axios.post("/api/users/createUser", data)
  } catch (error) {
    return error
  }
}

export const updateUser = async ({ data }) => {
  try {
    return await Axios.post("/api/users/updateUser", data)
  } catch (error) {
    throw error
  }
}

export const updatePassword = async data => {
  try {
    return await Axios.post("/api/users/updatePassword", data)
  } catch (error) {
    return error
  }
}

export const upgradeUser = async ({ data }) => {
  try {
    return await Axios.post("/api/users/modifyUserAccessLevel", { ...data })
  } catch (error) {
    return error
  }
}

export const upsertUserForTask = async ({ data }) => {
  try {
    return await Axios.post("/api/users/upsertUserForTask", { ...data })
  } catch (error) {
    return error
  }
}

export const createDesk = async ({ data }) => {
  try {
    return await Axios.post("/api/users/createDesk", data)
  } catch (error) {
    return error
  }
}

export const updateDesk = async ({ data }) => {
  try {
    return await Axios.post("/api/users/updateDesk", data)
  } catch (error) {
    return error
  }
}

export const updateUserDeskMapping = async ({ data }) => {
  try {
    return await Axios.post("/api/users/updateUserDesk", data)
  } catch (error) {
    return error
  }
}

export const createUserDeskMapping = async ({ data }) => {
  try {
    return await Axios.post("/api/users/createUserDeskMapping", data)
  } catch (error) {
    return error
  }
}

export const checkUserDeskMappingStatus = async ({ data }) => {
  try {
    return await Axios.post("/api/users/checkUserDeskMappingStatus", data)
  } catch (error) {
    return error
  }
}

export const getUsersByAgency = async ({ data }) => {
  try {
    return await Axios.post("/api/users/getUsersByAgency", data)
  } catch (error) {
    return error
  }
}

export const getUsersByStatus = async data => {
  try {
    return await Axios.post("/api/user/getUsersByStatus", data)
  } catch (error) {
    return error
  }
}

export const getDesksByAgency = async ({ data }) => {
  try {
    return await Axios.post("/api/users/getDesksByAgency", data)
  } catch (error) {
    return error
  }
}

export const getSourcesForSelectedType = async deskId => {
  try {
    const response = await Axios.get(
      `/api/users/getSourcesForSelectedType?deskId=${deskId}`
    )
    return response.data.data.sources
  } catch (error) {
    return error
  }
}

export const getAllSources = async type => {
  try {
    const response = await Axios.post(`/api/source/list?type=${type}`, {
      limit: 1000,
    })
    return response.data.data
  } catch (error) {
    return error
  }
}
