import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider, Tag } from "antd"
import { Col, Row } from "reactstrap"
import dayjs from "dayjs"

const { Option } = Select

const RoomDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              placeholder="Search Type"
              style={{ width: "45%" }}
              allowClear={true}
              defaultValue={
                props && props.filters && props.filters["searchType"]
              }
              onChange={value => props.handleFilterChange(value, "searchType")}
            >
              <Option value="title">Title</Option>
              <Option value="hostId">User Id</Option>
              <Option value="id">Room Id</Option>
              <Option value="roomId">Room No</Option>
            </Select>

            <Input
              defaultValue={
                props && props.filters && props.filters["searchText"]
              }
              placeholder={"Search Value"}
              onChange={e =>
                props.handleFilterChange(e.target.value, "searchText")
              }
              allowClear={true}
              name="searchId"
              style={{ width: "55%" }}
            />
          </Input.Group>
        </Col>
        <Col lg="4" md="8" style={{ marginBottom: "5px" }}>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Order By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              allowClear={true}
              defaultValue={
                props && props.filters && props.filters["orderByField"]
              }
              placeholder="Order By"
              onChange={value => {
                props.handleFilterChange(value, "orderByField")
              }}
              style={{ width: "50%" }}
            >
              <Option value="featuredIndex">Featured Index</Option>
              <Option value="startDateTime">Start Day</Option>
              <Option value="endDateTime">End Day</Option>
              <Option value="count">Count</Option>
            </Select>
            <Select
              allowClear={true}
              defaultValue={props && props.filters && props.filters["order"]}
              placeholder="Order"
              onChange={value => {
                props.handleFilterChange(value, "order")
              }}
              style={{ width: "50%" }}
            >
              <Option value="asc">Ascending</Option>
              <Option value="desc">Descending</Option>
            </Select>
          </Input.Group>
        </Col>
        {/* <Col lg="3" md="6" style={{ marginBottom: "5px" }}>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Hash Tags</h5>
          </Divider>
          <Input
            defaultValue={props.filters["hashTags"]}
            placeholder={"Hash Tags"}
            onChange={e => props.handleFilterChange(e.target.value, "hashTags")}
            allowClear={true}
            name="hashTags"
            style={{ width: "55%" }}
          />
        </Col> */}
      </Row>

      {props.showAdvancedFilters && (
        <Row style={{ marginBottom: "10px" }} gutter={8}>
          <Col lg="6" md="12">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Range Filter</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex justify-content-center">
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["rangeSearchType"]}
                    placeholder="Range Search Type"
                    onChange={value => {
                      props.handleFilterChange(value, "rangeSearchType")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="review_bucket">Bucket</Option>
                    <Option value="report_count">Report Count</Option>
                  </Select>
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["opSearchType"]}
                    placeholder="Operator"
                    onChange={value => {
                      props.handleFilterChange(value, "opSearchType")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="bt">Between</Option>
                    <Option value="eq">Equal to</Option>
                    <Option value="gt">Greater Than</Option>
                    <Option value="lt">Less Than</Option>
                  </Select>
                  {props.filters["opSearchType"] !== "bt" && (
                    <Input
                      defaultValue={props.filters["rangeSearchText"]}
                      placeholder={"Range Search Text"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value,
                          "rangeSearchText"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  )}
                  {props.filters["opSearchType"] === "bt" && (
                    <Input
                      defaultValue={props.filters["rangeSearchFrom"]}
                      placeholder={"Range Search From"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value,
                          "rangeSearchFrom"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  )}
                  {props.filters["opSearchType"] === "bt" && (
                    <Input
                      defaultValue={props.filters["rangeSearchTo"]}
                      placeholder={"Range Search To"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value,
                          "rangeSearchTo"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  )}
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col lg="4">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Created Date</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row>
                <Col className="d-flex">
                  <DatePicker
                    defaultValue={
                      props.filters["createdDateFrom"]
                        ? dayjs.unix(
                            props.filters["createdDateFrom"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="From Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "createdDateFrom"
                      )
                    }}
                  />
                  <DatePicker
                    defaultValue={
                      props.filters["createdDateTo"]
                        ? dayjs.unix(
                            props.filters["createdDateTo"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="To Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "createdDateTo"
                      )
                    }}
                  />
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col lg="3">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Moderated By</h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Moderator"
              defaultValue={props.filters["moderatedBy"]}
              onChange={value => {
                props.handleFilterChange(value, "moderatedBy")
              }}
              style={{ width: "100%" }}
            >
              <Option value="">All</Option>
              {props.moderatorList.map(user => (
                <Option value={user.email}>{user.name}</Option>
              ))}
            </Select>
          </Col>
          <Col span={24}>
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Moderated Date</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row>
                <Col className="d-flex">
                  <DatePicker
                    defaultValue={
                      props.filters["moderatedDateFrom"]
                        ? dayjs.unix(
                            props.filters["moderatedDateFrom"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="From Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "moderatedDateFrom"
                      )
                    }}
                  />
                  <DatePicker
                    defaultValue={
                      props.filters["moderatedDateTo"]
                        ? dayjs.unix(
                            props.filters["moderatedDateTo"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="To Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "moderatedDateTo"
                      )
                    }}
                  />
                </Col>
              </Row>
            </Input.Group>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default withRouter(RoomDrawer)
