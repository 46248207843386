import React, { useState, useEffect, useRef, useCallback } from "react"
import useKeypress from "./hooks/useKeypress"
import useOnClickOutside from "./hooks/useOnClickOutside"
import DOMPurify from "dompurify"
import "./inlineEditStyles.css"
import { Select, DatePicker } from "antd"
import { Switch } from "../../containers/ValidatorForm"
import useHover from "./hooks/useHover"
import moment from "moment"

const { Option } = Select

function InlineEdit(props) {
  const [isInputActive, setIsInputActive] = useState(false)
  const [inputValue, setInputValue] = useState(props.text)

  const wrapperRef = useRef(null)
  const textRef = useRef(null)
  const inputRef = useRef(null)
  const [refBox, isHovered] = useHover()

  const enter = useKeypress("Enter")
  const esc = useKeypress("Escape")

  const { onSetText } = props
  useOnClickOutside(wrapperRef, () => {
    if (
      isInputActive &&
      props.type !== "single-select" &&
      props.type !== "multi-select" &&
      props.type !== "custom-multi-select" &&
      props.type !== "date-picker" &&
      props.type !== "switch"
    ) {
      onSetText(inputValue)
      setIsInputActive(false)
    }
  })

  const onEnter = useCallback(() => {
    if (enter) {
      onSetText(inputValue)
      setIsInputActive(false)
      props.onSubmit(inputValue)
    }
  }, [enter, inputValue, onSetText])

  const onEsc = useCallback(() => {
    if (esc) {
      setInputValue(props.text)
      setIsInputActive(false)
    }
  }, [esc, props.text])

  useEffect(() => {
    if (isInputActive) {
      inputRef.current.focus()
    }
  }, [isInputActive])

  useEffect(() => {
    if (isInputActive) {
      onEnter()
      onEsc()
    }
  }, [onEnter, onEsc, isInputActive])

  useEffect(() => {
    setInputValue(props.text)
  }, [props.text])

  const handleInputChange = useCallback(
    event => {
      setInputValue(DOMPurify.sanitize(event.target.value))
    },
    [setInputValue]
  )

  const handleSelectChange = useCallback(
    value => {
      setInputValue(value)
      setIsInputActive(false)
      onSetText(value)
      props.onSubmit(value)
    },
    [setInputValue]
  )

  const handleMultiSelectChange = useCallback(value => {
    setInputValue(value)
    onSetText(value)
  })

  const handleDateChange = useCallback((moment, value) => {
    setInputValue(value)
    onSetText(value)
  })

  const handleSwitchChange = useCallback(value => {
    setInputValue(value)
    onSetText(value)
  })

  const handleSpanClick = useCallback(
    () => setIsInputActive(true),
    [setIsInputActive]
  )

  const _renderInput = inputRef => {
    switch (props.type) {
      case "textfield":
        return (
          <input
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            className={`inline-text_input inline-text_input--${
              isInputActive ? "active" : "hidden"
            }`}
          />
        )
      case "textarea":
        return (
          <textarea
            rows="3"
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            className={`w-100 inline-text_input inline-text_input--${
              isInputActive ? "active" : "hidden"
            }`}
          />
        )
      case "single-select":
        if (!isInputActive) {
          return null
        }
        return (
          <Select
            ref={inputRef}
            value={inputValue}
            onChange={handleSelectChange}
            options={props.options}
            disabled={props.disabled}
            style={{ width: "10rem" }}
            showSearch
          />
        )

      case "multi-select":
        if (!isInputActive) {
          return null
        }
        return (
          <Select
            ref={inputRef}
            value={inputValue}
            onChange={handleMultiSelectChange}
            options={props.options}
            mode="multiple"
            disabled={props.disabled}
            style={{ width: "10rem" }}
          />
        )
      case "custom-multi-select":
        if (!isInputActive) {
          return null
        }
        return (
          <Select
            ref={inputRef}
            value={inputValue}
            onChange={handleMultiSelectChange}
            mode="multiple"
            optionLabelProp="label"
            disabled={props.disabled}
            style={{ width: "10rem" }}
            filterOption={(input, option) => {
              if (option.value) {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              } else {
                return []
              }
            }}
          >
            {props.options.map(itemObj => (
              <Option
                key={itemObj.value}
                value={itemObj.value}
                label={itemObj.label}
              >
                <div>
                  <div className={itemObj.heighlight ? props.customClass : ""}>
                    {itemObj.label}
                    {itemObj.text && <div>{itemObj.text}</div>}
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        )
      case "date-picker":
        if (!isInputActive) {
          return null
        }
        return (
          <DatePicker
            ref={inputRef}
            value={moment(
              inputValue,
              props.dateTimeFormat ? props.dateTimeFormat : "YYYY-MM-DD"
            )}
            format={props.dateTimeFormat || "YYYY-MM-DD"}
            style={{ width: "100%" }}
            onChange={handleDateChange}
            allowClear={false}
            disabled={props.disabled}
          />
        )
      case "switch":
        return (
          <Switch
            ref={inputRef}
            value={inputValue}
            onChange={handleSwitchChange}
            disabled={props.disabled}
          />
        )
    }
  }

  return (
    <div
      ref={refBox}
      className="d-flex justify-content-between align-items-center"
      onClick={handleSpanClick}
      role="button"
    >
      <span className="inline-text" ref={wrapperRef}>
        {
          <span
            ref={textRef}
            className={`inline-text_copy inline-text_copy--${
              !isInputActive ? "active" : "hidden"
            }`}
          >
            {["multi-select", "custom-multi-select", "date-picker"].includes(
              props.type
            )
              ? props.component
              : props.text}
          </span>
        }
        <div>{_renderInput(inputRef)}</div>
      </span>
      {isHovered && props.showHover && !isInputActive && (
        <div className="text-muted mx-2 font-size-12">
          <i className="bx bx-pencil" />
        </div>
      )}
    </div>
  )
}

export default InlineEdit
