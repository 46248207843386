import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Upload } from "antd"
import toastr from "toastr"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, { Switch } from "../ValidatorForm"

export default class TemplateSectionForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      sectionId: this.props?.data?.section_id || "",
      sectionName: this.props?.data?.section_name || "",
      isActive: this.props?.data?.is_active === false ? false : true,
    }
  }

  componentWillUnmount() {
    this._resetState()
  }

  _resetState() {
    this.setState({
      sectionId: "",
      sectionName: "",
      isActive: true,
    })
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Template Section`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={4} className="mt-2">
                <label>Section Name</label>
                <input
                  className="form-control"
                  value={this.state.sectionName}
                  onChange={e => this.setState({ sectionName: e.target.value })}
                  required
                />
              </Col>
              <Col sm={12} md={4} className="mt-2">
                <Switch
                  label="Status"
                  field={"isActive"}
                  className="creator-form"
                  // onChange={e => this.setState({ isActive: e.target.value })}
                  defaultValue={this.state.isActive}
                  errors={["This field is required"]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="flex" style={{ marginTop: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <Button htmlType="submit" color="primary">
                      {this.props.action === "update" ? "Update" : "Create"}
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.onCancel()
                        this._resetState()
                      }}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data = new FormData()
    const payload = {}
    const { sectionId, sectionName } = this.state

    if (sectionId) {
      payload.sectionId = sectionId
    }

    if (sectionName) {
      payload.sectionName = sectionName
    }

    payload.isActive = formData.isActive

    data.append("payload", JSON.stringify(payload))
    this.props.onSubmit(data)
    this._resetState()
  }

  render() {
    return this._form()
  }
}
