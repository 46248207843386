import React, { PureComponent } from "react"
import Plot from "react-plotly.js"

const PieCharts = props => {
  let {
    labels,
    values,
    text,
    marker = [],
    texttemplate = "",
    title,
    layout = {},
  } = props

  return (
    <Plot
      data={[
        {
          values: values,
          labels: labels,
          text: text,
          marker: marker,
          texttemplate: texttemplate,
          type: "pie",
        },
      ]}
      layout={{ ...layout, title }}
    />
  )
}

export default PieCharts
