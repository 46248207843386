import React from "react"
import { Card, CardBody, CardFooter, Col, Badge, CardTitle } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import ReactPlayer from "react-player"
import { AiOutlineWarning } from "react-icons/ai"

class ModerationRoomCard extends React.Component {
  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
    this.state = {
      isAuthorized: false,
      isFlipped: false,
    }
  }

  componentDidMount = async () => {
    const { data } = this.props
  }

  _setPlayBack = () => {
    this.videoRef.current.playbackRate = 1.0
    this.videoRef.current.volume = 0.1
  }

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  getTitle = (title, field, titleAlt) => {
    return (
      <Badge
        className={`${
          field === true ? "badge-soft-success" : "badge-soft-danger"
        } ms-1 my-1`}
      >
        <div className="font-size-15">
          <Tooltip title={title}>
            <div>
              <span>{field === true ? title : titleAlt}</span>{" "}
              <i className={field === true ? "fas fa-check" : ""} />
            </div>
          </Tooltip>
        </div>
      </Badge>
    )
  }

  render() {
    const { data } = this.props
    const endDate = new Date(data.endDateTime).valueOf()
    return (
      <React.Fragment>
        <Card
          className={`text-center shadow-lg`}
          style={{
            height: "568px",
            borderRadius: "10px",
            backgroundColor: "",
          }}
        >
          <Col>
            <ReactPlayer
              url={
                data.playBackUrlHls
                  ? "https:" + data.playBackUrlHls.split(":")[1]
                  : data.deepLink
              }
              playing
              loop
              controls
              height="455px"
              width="100%"
              muted
            />
          </Col>

          <CardBody>
            <CardTitle className="mt-0 d-flex mx-1" style={{ height: "20px" }}>
              <Tooltip title={data.title}>
                <div className="text-truncate">{data.title}</div>
              </Tooltip>
              <div
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <CopyToClipboard
                  text={data.id}
                  onCopy={e => toastr.success("Copied Successfully")}
                >
                  <Tooltip title="Copy Id">
                    <div role="button" onClick={() => {}} id={"copy" + data.id}>
                      <i className="bx bx-copy fa-1x px-2" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </CardTitle>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <CopyToClipboard
                  text={data.roomId}
                  title="Copy Room Id"
                  onCopy={() => {
                    toastr.success("Copied Successfully")
                  }}
                >
                  <Tooltip title="Copy Room ID">
                    <div role="button">
                      <i className="bx bx-building-house" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <div className="flex-fill">
                <CopyToClipboard
                  text={data.hostId}
                  title="Copy Host Id"
                  onCopy={() => {
                    toastr.success("Copied Successfully")
                  }}
                >
                  <Tooltip title="Copy Host Id">
                    <div role="button">
                      <i className="bx bx-user" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <div className="flex-fill">
                <Tooltip title="Warn User">
                  <div
                    role="button"
                    onClick={() => {
                      if (
                        !this.props.currentUser?.permissions?.includes(
                          "EDIT_BROWSE_ROOM"
                        )
                      ) {
                        toastr.error(NO_PERMISSION_MESSAGE)
                        return
                      }
                      this.props.onWarnUser(data)
                    }}
                  >
                    <AiOutlineWarning className="mb-2 text-primary" />
                  </div>
                </Tooltip>
              </div>
              <div className="flex-fill">
                <Tooltip title="Shut Room">
                  <div
                    role="button"
                    onClick={() => {
                      if (
                        !this.props.currentUser?.permissions?.includes(
                          "EDIT_BROWSE_ROOM"
                        )
                      ) {
                        toastr.error(NO_PERMISSION_MESSAGE)
                        return
                      }
                      this.props.onShutRoom(data.id)
                    }}
                  >
                    <i className="bx bx-block" style={{ color: "red" }} />
                  </div>
                </Tooltip>
              </div>
            </div>
          </CardFooter>
        </Card>
      </React.Fragment>
    )
  }
}

export default ModerationRoomCard
