import { AvField, AvForm } from "availity-reactstrap-validation"
import React from "react"
import "react-datepicker/dist/react-datepicker.css"
import { Button, Card, CardBody, Col, FormGroup, Label } from "reactstrap"

const UpgradeUser = props => {
  const _onSubmit = formData => {
    props.onSubmit(formData)
  }

  const userOptions = [
    {
      label: "Admin",
      value: "admin",
      disabled: props.currentUser.jotUserType > 1,
    },
    { label: "Content Manager", value: "content-manager" },
    { label: "Moderator", value: "moderator" },
    { label: "User", value: "user" },
  ]

  const _getOptions = options => {
    return options.filter(option => !option.disabled)
  }

  return (
    <React.Fragment>
      <Col lg="5">
        <Card style={{ height: "80vh" }}>
          <CardBody>
            <AvForm onValidSubmit={(e, values) => _onSubmit(values)}>
              <FormGroup className="mb-4" row>
                <Label htmlFor="email" className="col-form-label col-lg-2">
                  Email
                </Label>
                <Col lg="10">
                  <AvField
                    name="targetClientID"
                    placeholder="Enter Valid Email"
                    type="email"
                    errorMessage="Invalid Email"
                    validate={{
                      required: { value: true },
                      email: { value: true },
                    }}
                    disabled={true}
                    defaultValue={props.data && props.data.email}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="targetAccessLevel"
                  className="col-form-label col-lg-2"
                >
                  Role
                </Label>
                <Col lg="10">
                  <AvField type="select" name="targetAccessLevel" default="">
                    <option value=""></option>
                    {_getOptions(userOptions).map(ele => (
                      <option value={ele.value}>{ele.label}</option>
                    ))}
                  </AvField>
                </Col>
              </FormGroup>
              <div className="d-flex justify-content-end">
                <Button type="submit" color="primary" className="mx-2">
                  Upgrade
                </Button>
                <Button color="danger" onClick={props.onCancel}>
                  Cancel
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default UpgradeUser
