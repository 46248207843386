import PropTypes from "prop-types"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Card, Row, CardBody, Col, Table } from "reactstrap"
import ReactCardFlip from "react-card-flip"
import { formatNumber } from "../../util/common"
import CopyToClipboard from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import { manageUser } from "appRedux/slices/appUser"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import toastr from "toastr"
import {
  CURATED_TYPE_MAPPING,
  NO_PERMISSION_MESSAGE,
  USER_TYPE_MAPPING,
} from "constants/uiConstants"
import CustomScrollbars from "util/CustomScrollbars"

const UserCard = props => {
  const [isFlipped, setIsFlipped] = useState(false)
  const [user, setUser] = useState(props.user)
  const dispatch = useDispatch()

  const realStatPermissionUser =
    // props.currentUser.jotUserType < 3 ||
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("VIEW_REAL_STATS")

  const _onEdit = () => {
    props.history.push(`/appuser/details?userUuid=${user.user_uuid}`)
  }

  const { customClassname = "" } = props

  const _getRealStats = () => {
    return (
      <>
        <tr className="text-start">
          <th scope="row">Real Views:</th>
          <td>{formatNumber(user.view_count) || "N/A"}</td>
        </tr>
        <tr className="text-start">
          <th scope="row">Real Followers:</th>
          <td>{formatNumber(user.followers_uu) || "N/A"}</td>
        </tr>
        <tr className="text-start">
          <th scope="row">Real Likes:</th>
          <td>{formatNumber(user.like_count) || "N/A"}</td>
        </tr>
        <tr className="text-start">
          <th scope="row">Real Shares:</th>
          <td>{formatNumber(user.share_count) || "N/A"}</td>
        </tr>
        <tr className="text-start">
          <th scope="row">Real Downloads:</th>
          <td>{formatNumber(user.download_count) || "N/A"}</td>
        </tr>
        <tr className="text-start">
          <th scope="row">Unique Users Viewed:</th>
          <td>{formatNumber(user.view_uu) || "N/A"}</td>
        </tr>
        <tr className="text-start">
          <th scope="row">Unique Users Likes:</th>
          <td>{formatNumber(user.like_uu) || "N/A"}</td>
        </tr>
        <tr className="text-start">
          <th scope="row">Unique Users Downloads:</th>
          <td>{formatNumber(user.download_uu) || "N/A"}</td>
        </tr>
        <tr className="text-start">
          <th scope="row">Unique Users Engaged:</th>
          <td>{formatNumber(user.engagement_uu) || "N/A"}</td>
        </tr>
        <tr className="text-start">
          <th scope="row">Unique Users Shared:</th>
          <td>{formatNumber(user.share_uu) || "N/A"}</td>
        </tr>
      </>
    )
  }

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection={"horizontal"}>
      <Card
        className={`${customClassname} text-center shadow-lg`}
        style={{ height: "440px", borderRadius: "10px" }}
      >
        <CardBody className="px-1 py-0">
          <Row className="my-sm-2">
            <Col>
              <div className="avatar-xs">
                <div className="avatar-title rounded-circle bg-light">
                  <span
                    className={
                      "avatar-title rounded-circle bg-light bg-" +
                      "secondary" +
                      " text-info" +
                      " font-size-16" +
                      " font-weight-semibold"
                    }
                  >
                    <Tooltip
                      title={
                        user.display_persona || "Display persona is unknown"
                      }
                    >
                      <i className="fas fa-theater-masks" />
                    </Tooltip>
                  </span>
                </div>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="avatar-xs">
                <div className="avatar-title rounded-circle bg-light">
                  <span
                    className={
                      "avatar-title rounded-circle bg-light bg-" +
                      "secondary" +
                      " text-warning" +
                      " font-size-16" +
                      " font-weight-semibold"
                    }
                  >
                    <Tooltip
                      title={
                        USER_TYPE_MAPPING[user.user_type] ||
                        "User type is unknown"
                      }
                    >
                      {user.user_type && user.user_type.toUpperCase()}
                    </Tooltip>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <div
            role="button"
            onClick={() => {
              if (!props.pageSource) {
                _onEdit()
              }
              if (props.pageSource && props.pageSource === "manageCollection") {
                props.onEditClick(user)
              }
            }}
          >
            <div className="mb-4">
              <div style={{ position: "relative" }}>
                <img
                  className="rounded-circle avatar-lg img-fluid"
                  src={
                    user.profile_pic ||
                    "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
                  }
                  alt=""
                />
                {user.verified && (
                  <span href="#" className="verified-icon" title="Video Play">
                    <i className="mdi mdi-check-decagram"></i>
                  </span>
                )}
              </div>
            </div>

            <div style={{ height: "24px" }}>
              <h5 className="font-size-17 mb-1 text-truncate text-dark">
                {user.name}
              </h5>
            </div>
            <div>
              <p className="font-size-15 text-muted mb-4">{`@${
                user.handle || user.user_name
              }`}</p>
            </div>
            <div style={{ height: "64px" }}>
              <h6>{user.bio_data}</h6>
            </div>
          </div>
          <div style={{ height: "32px" }}>
            {!_.isEmpty(user.tagged_social_account) && (
              <div
                className="font-size-20 d-flex justify-content-center mb-1"
                style={{ height: "64px" }}
              >
                {user.tagged_social_account.instagram_info && (
                  <a
                    href={
                      user.tagged_social_account.instagram_info.profile_link
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="d-block mx-2" style={{ color: "#bc1888" }}>
                      <i className="fab fa-instagram"></i>
                    </span>
                  </a>
                )}
                {user.tagged_social_account.youtube_info && (
                  <a
                    href={user.tagged_social_account.youtube_info.profile_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="d-block mx-2" style={{ color: "#FF0000" }}>
                      <i className="fab fa-youtube" />
                    </span>
                  </a>
                )}
                {/* <span className="d-block mx-2" style={{ color: "#4267B2" }}>
                <i className="fas fa-globe"></i>
              </span> */}
              </div>
            )}
          </div>
          <div className="d-flex justify-content-around align-items-center border-top pt-2">
            <div className="d-flex flex-column">
              <i
                className="bx bx-show"
                style={{ padding: "2px", fontSize: "16px" }}
              />
              <span>{formatNumber(user.view_count + user.fk_views) || 0}</span>
            </div>
            <div className="d-flex flex-column">
              <i
                className="bx bx-like"
                style={{ padding: "2px", fontSize: "16px" }}
              />
              <span>{formatNumber(user.like_count + user.fk_likes) || 0}</span>
            </div>
            <div className="d-flex flex-column">
              <i
                className="bx bx-share-alt"
                style={{ padding: "2px", fontSize: "16px" }}
              />
              <span>
                {formatNumber(user.share_count + user.fk_shares) || 0}
              </span>
            </div>
            <div>
              <Tooltip title="View More">
                <i
                  className="bx bx-play"
                  role="button"
                  style={{ fontSize: "20px" }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setIsFlipped(true)
                  }}
                />
              </Tooltip>
            </div>
          </div>
          {props.pageType === "boostFollows" && (
            <>
              <div>Boosted By: {user.userName}</div>
              <div className="d-flex justify-content-around align-items-center border-top pt-2">
                <Tooltip title="Count">
                  <div className="d-flex align-items-center">
                    <i
                      className="mdi mdi-chart-timeline-variant"
                      style={{ padding: "2px", fontSize: "16px" }}
                    />
                    <span> {formatNumber(user.count) || 0}</span>
                  </div>
                </Tooltip>
                <Tooltip title="Boosting Period">
                  <div className="d-flex align-items-center">
                    <i
                      className="bx bx-show"
                      style={{ padding: "2px", fontSize: "16px" }}
                    />
                    <span> {formatNumber(user.boostingPeriod)}</span>
                  </div>
                </Tooltip>
                <div>
                  <Tooltip title="View More">
                    <i
                      className="bx bx-play"
                      role="button"
                      style={{ fontSize: "20px" }}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        setIsFlipped(true)
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </>
          )}
          {props.pageType === "deltaHistory" && (
            <>
              <div>Updated By: {user.userName}</div>
              <div className="d-flex justify-content-around align-items-center border-top pt-2">
                <Tooltip title="Delta Count">
                  <div className="d-flex align-items-center">
                    <i
                      className="mdi mdi-chart-timeline-variant"
                      style={{ padding: "2px", fontSize: "16px" }}
                    />
                    <span> {formatNumber(user.deltaCount) || 0}</span>
                  </div>
                </Tooltip>
                <Tooltip title="Curated Type">
                  <div className="d-flex align-items-center">
                    <i
                      className="bx bx-user"
                      style={{ padding: "2px", fontSize: "16px" }}
                    />
                    <span> {CURATED_TYPE_MAPPING[user.curatedType]}</span>
                  </div>
                </Tooltip>
                <div>
                  <Tooltip title="View More">
                    <i
                      className="bx bx-play"
                      role="button"
                      style={{ fontSize: "20px" }}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        setIsFlipped(true)
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </>
          )}
          <Row>
            <Col lg={1} xl={2} md={1} sm={3}></Col>
          </Row>
        </CardBody>
        <div
          className="contact-links d-flex font-size-20 p-2 bg-light border-top"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <div className="flex-fill">
            <CopyToClipboard
              text={user.user_uuid}
              onCopy={e => {
                toastr.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy User ID">
                <i role="button" className="bx bx-copy" />
              </Tooltip>
            </CopyToClipboard>
          </div>
          {(!props.pageSource ||
            (props.pageSource && props.pageSource === "manageCollection")) && (
            <div
              className="flex-fill"
              style={user.moderation_level <= -1 ? { cursor: "no-drop" } : {}}
            >
              <Tooltip title="Edit">
                <i
                  role="button"
                  className="bx bx-edit-alt"
                  style={
                    user.moderation_level <= -1 ? { pointerEvents: "none" } : {}
                  }
                  onClick={() =>
                    props.pageSource && props.pageSource === "manageCollection"
                      ? props.onEditClick(user)
                      : _onEdit()
                  }
                />
              </Tooltip>
            </div>
          )}
          <div
            className="flex-fill"
            style={user.moderation_level <= -1 ? { cursor: "no-drop" } : {}}
          >
            <Tooltip title="Edit In New Window">
              <Link
                to={`/appuser/details?userUuid=${user.user_uuid}`}
                target="_blank"
                rel="noopener noreferrer"
                style={
                  user.moderation_level <= -1 ? { pointerEvents: "none" } : {}
                }
              >
                <i className="bx bx-link-external"></i>
              </Link>
            </Tooltip>
          </div>
          {!props.pageSource && (
            <div className="flex-fill">
              {user.moderation_level <= -1 ? (
                <Tooltip title="Un-Block">
                  <i
                    role="button"
                    className="bx bx-comment-error"
                    style={{ color: "green" }}
                    onClick={() => {
                      if (
                        !props.currentUser?.permissions?.includes(
                          "EDIT_BROWSE_USER"
                        )
                      ) {
                        toastr.error(NO_PERMISSION_MESSAGE)
                        return
                      }
                      setUser({ ...user, moderation_level: 1 })
                      dispatch(
                        manageUser({
                          action: "unblock",
                          userUuid: user.user_uuid,
                        })
                      )
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Block">
                  <i
                    role="button"
                    className="bx bx-block"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (
                        !props.currentUser?.permissions?.includes(
                          "EDIT_BROWSE_USER"
                        )
                      ) {
                        toastr.error(NO_PERMISSION_MESSAGE)
                        return
                      }
                      setUser({ ...user, moderation_level: -1 })
                      dispatch(
                        manageUser({
                          action: "block",
                          userUuid: user.user_uuid,
                        })
                      )
                    }}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </Card>
      <Card
        className={`${customClassname} text-center shadow-lg`}
        style={{
          height: "440px",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        <CustomScrollbars>
          <CardBody className="px-0 py-0">
            <Row className="my-sm-2">
              <Col className="ms-2">
                <div className="avatar-xs">
                  <div className="avatar-title rounded-circle bg-light">
                    <span
                      className={
                        "avatar-title rounded-circle bg-light bg-" +
                        "secondary" +
                        " text-warning" +
                        " font-size-16" +
                        " font-weight-semibold"
                      }
                    >
                      <i className="bx bx-bar-chart" />
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="d-flex justify-content-end me-2">
                <div
                  className="avatar-xs"
                  role="button"
                  onClick={() => setIsFlipped(false)}
                >
                  <div className="avatar-title rounded-circle bg-light">
                    <span
                      className={
                        "avatar-title rounded-circle bg-light bg-" +
                        "secondary" +
                        " text-dark" +
                        " font-size-16" +
                        " font-weight-semibold"
                      }
                    >
                      <i className="fas fa-times-circle" />
                    </span>
                  </div>
                </div>
                <div></div>
              </Col>
            </Row>
            <Row>
              <div className="table-responsive" role="button" onClick={_onEdit}>
                <Table className=" mb-0 table-striped">
                  <tbody>
                    <tr className="text-start">
                      <th scope="row">Total Posts:</th>
                      <td>{formatNumber(user.posts_count)}</td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">NSFW Posts:</th>
                      <td>
                        {formatNumber(
                          user.auto_nsfw_posts + user.manual_nsfw_posts
                        ) || "N/A"}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Views:</th>
                      <td>
                        {formatNumber(user.fk_views + user.view_count) || "N/A"}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Likes:</th>
                      <td>
                        {formatNumber(user.fk_likes + user.like_count) || "N/A"}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Shares:</th>
                      <td>
                        {formatNumber(user.fk_shares + user.share_count) ||
                          "N/A"}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Downloads:</th>
                      <td>
                        {formatNumber(
                          user.fk_downloads + user.download_count
                        ) || "N/A"}
                      </td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Total Followers:</th>
                      <td>{formatNumber(user.api_followers) || "N/A"}</td>
                    </tr>
                    {realStatPermissionUser && _getRealStats()}
                    <tr className="text-start">
                      <th scope="row">Average Posts per Week:</th>
                      <td>{formatNumber(user.avg_posts) || "N/A"}</td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Post Streak in Last 15 days:</th>
                      <td>{formatNumber(user.post_streak) || "N/A"}</td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Gallery Posts:</th>
                      <td>{formatNumber(user.gallery_posts_count) || "N/A"}</td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Camera Posts:</th>
                      <td>{formatNumber(user.camera_posts_count) || "N/A"}</td>
                    </tr>
                    <tr className="text-start">
                      <th scope="row">Other Posts:</th>
                      <td>
                        {formatNumber(user.othersource_posts_count) || "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Row>
          </CardBody>
        </CustomScrollbars>
      </Card>
    </ReactCardFlip>
  )
}

UserCard.propTypes = {
  user: PropTypes.object,
}

export default withRouter(UserCard)
