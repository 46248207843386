import { createSlice } from "@reduxjs/toolkit"

const communitySlice = createSlice({
  name: "community",
  initialState: {
    communityUser: [],
    loading: false,
    leaderboardloading: false,
    teamLoading: false,
    csvLoading: false,
    managerDataLoading: false,
    dataLoading: false,
    contentData: [],
    topList: [],
    aggData: [],
    teamData: {},
    chartData: [],
    videoLoading: false,
    total: 0,
    totalCreators: 0,
    csvData: [],
    leaderboardUsers: [],
  },
  reducers: {
    resetState(state) {
      return {
        communityUser: [],
        contentData: [],
        topList: [],
        aggData: [],
        teamData: {},
        loading: false,
        refetchData: false,
        totalCreators: 0,
        csvData: [],
      }
    },
    getUser(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        totalCreators: 0,
      }
    },
    getUserSuccess(state, action) {
      return {
        ...state,
        communityUser: action.payload.data.data,
        loading: false,
        refetchData: false,
        totalCreators:
          action.payload.data.total && action.payload.data.total.value
            ? action.payload.data.total.value
            : action.payload.data.total
            ? action.payload.data.total
            : 1,
      }
    },

    getManagerData(state) {
      return {
        ...state,
        loading: true,
        communityManager: [],
        refetchData: false,
      }
    },
    getManagerDataSuccess(state, action) {
      return {
        ...state,
        communityManager: action.payload.data,
        loading: false,
        refetchData: false,
      }
    },
    getManagersList(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        totalManagers: 0,
      }
    },
    getManagersListSuccess(state, action) {
      return {
        ...state,
        communityManagers: action.payload.data.data,
        loading: false,
        refetchData: false,
        totalManagers: action.payload.data.count,
      }
    },
    getUnassignedManagersList(state) {
      return {
        ...state,
        managerDataLoading: true,
        refetchData: false,
      }
    },
    getUnassignedManagersListSuccess(state, action) {
      return {
        ...state,
        unassignedManagers: action.payload.data,
        managerDataLoading: false,
        refetchData: false,
      }
    },
    getCommunityUserByType(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        total: 0,
      }
    },
    getCommunityUserByTypeSuccess(state, action) {
      return {
        ...state,
        communityUser: action.payload.data.data,
        loading: false,
        refetchData: false,
        total: action.payload.data.count,
      }
    },
    getCommunityContent(state) {
      return { ...state, loading: true, refetchData: false }
    },
    getCommunityContentSuccess(state, action) {
      return {
        ...state,
        contentData: action.payload.data,
        loading: false,
        refetchData: false,
      }
    },
    getTopCreatorsList(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getTopCreatorsListSuccess(state, action) {
      return {
        ...state,
        loading: false,
        topList: action.payload.data,
        refetchData: false,
      }
    },
    getChartData(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getChartDataSuccess(state, action) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        chartData: action.payload.data,
      }
    },
    getGenreTimespanChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getPodTimespanChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getLanguageTimespanChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getGenreTrendsChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getPodTrendsChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getLanguageTrendsChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getAllPostTrendsChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getAllPercentageOfPostByXViewData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getGenreTimespanChartDataSuccess(state, action) {
      const { genreData: { data: genreData = {} } = {} } = action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        genreTimespanChartData: genreData,
      }
    },
    getPodTimespanChartDataSuccess(state, action) {
      const { podData: { data: podData = {} } = {} } = action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        podTimespanChartData: podData,
      }
    },
    getLanguageTimespanChartDataSuccess(state, action) {
      const { languageData: { data: languageData = {} } = {} } =
        action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        languageTimespanChartData: languageData,
      }
    },

    getGenreTrendsChartDataSuccess(state, action) {
      const { genreData: { data: genreData = {} } = {} } = action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        genreTrendsChartData: genreData,
      }
    },
    getPodTrendsChartDataSuccess(state, action) {
      const { podData: { data: podData = {} } = {} } = action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        podTrendsChartData: podData,
      }
    },
    getLangTrendsChartDataSuccess(state, action) {
      const { languageData: { data: languageData = {} } = {} } =
        action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        languageTrendsChartData: languageData,
      }
    },
    getAllPostTrendsChartDataSuccess(state, action) {
      const { allPostData: { data: allPostData = {} } = {} } =
        action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        allPostTrendsChartData: allPostData,
      }
    },
    getAllPercentageOfPostByXViewDataSuccess(state, action) {
      const { percentageViewData: { data: percentageViewData = {} } = {} } =
        action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        percentageViewData: percentageViewData,
      }
    },
    getActiveUserTrendsChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getActiveUserTrendsChartDataSuccess(state, action) {
      const { data = {} } = action.payload || {}
      return {
        ...state,
        loading: false,
        refetchData: false,
        activeUserTrendsChartData: data,
      }
    },
    getPodActiveUserTrendsChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getPodActiveUserTrendsChartDataSuccess(state, action) {
      const { podData: { data: podData = {} } = {} } = action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        podActiveUserTrendsChartData: podData,
      }
    },
    getLanguageActiveUserTrendsChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getLanguageActiveUserTrendsChartDataSuccess(state, action) {
      const { languageData: { data: languageData = {} } = {} } =
        action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        languageActiveUserTrendsChartData: languageData,
      }
    },
    getGenreActiveUserTrendsChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getGenreActiveUserTrendsChartDataSuccess(state, action) {
      const { genreData: { data: genreData = {} } = {} } = action.payload || {}

      return {
        ...state,
        loading: false,
        refetchData: false,
        genreActiveUserTrendsChartData: genreData,
      }
    },
    getAllPostsData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getAllPostsDataSuccess(state, action) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        allTypePostCountData: action.payload.data,
      }
    },
    getAllUserData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getAllUserDataSuccess(state, action) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        allUserData: action.payload.data,
      }
    },
    getAggData(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getAggDataSuccess(state, action) {
      return {
        ...state,
        loading: false,
        aggData: action.payload.data,
        refetchData: false,
      }
    },
    getTeamData(state) {
      return {
        ...state,
        loading: true,
        teamLoading: true,
        refetchData: false,
      }
    },
    getTeamDataSuccess(state, action) {
      return {
        ...state,
        loading: false,
        teamLoading: false,
        teamData: action.payload.data.data,
        refetchData: false,
      }
    },
    getLogs(state) {
      return {
        ...state,
        logs: [],
        loading: true,
      }
    },
    getLogsSuccess(state, { payload }) {
      return {
        ...state,
        logs: payload.data,
        loading: false,
      }
    },
    getLeads(state) {
      return {
        ...state,
        leads: [],
        loading: true,
      }
    },
    getLeadsSuccess(state, { payload }) {
      return {
        ...state,
        leads: payload.data,
        loading: false,
      }
    },
    deleteUser(state) {
      return {
        ...state,
        loading: true,
      }
    },
    deleteUserSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    createUser(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createUserSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    updateUser(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateUserSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    getCSVData(state) {
      return {
        ...state,
        loading: true,
        csvLoading: true,
        refetchData: false,
      }
    },
    getCSVDataSuccess(state, { payload }) {
      return {
        ...state,
        csvData: payload.data,
        loading: false,
        csvLoading: true,
        refetchData: false,
      }
    },
    getDuplicateContentChartData(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    getDuplicateContentChartDataSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        duplicateContentData: payload.data,
      }
    },
    uploadLeaderboard() {},
    getLeaderboardDataByFilters(state) {
      return {
        ...state,
        leaderboardloading: true,
        refetchData: false,
        leaderboardUsers: [],
      }
    },
    getLeaderboardDataByFiltersSuccess(state, { payload }) {
      return {
        ...state,
        leaderboardloading: false,
        leaderboardUsers: payload.data.users || [],
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 0,
        refetchData: false,
      }
    },
  },
})

export const {
  getUser,
  getUserSuccess,
  getManagersList,
  getManagersListSuccess,
  getCommunityUserByType,
  getCommunityUserByTypeSuccess,
  getUnassignedManagersList,
  getUnassignedManagersListSuccess,
  getCommunityContent,
  getCommunityContentSuccess,
  getTopCreatorsList,
  getTopCreatorsListSuccess,
  getChartData,
  getChartDataSuccess,
  getGenreTimespanChartData,
  getPodTimespanChartData,
  getLanguageTimespanChartData,
  getGenreTimespanChartDataSuccess,
  getPodTimespanChartDataSuccess,
  getLanguageTimespanChartDataSuccess,
  getGenreTrendsChartData,
  getPodTrendsChartData,
  getLanguageTrendsChartData,
  getGenreTrendsChartDataSuccess,
  getPodTrendsChartDataSuccess,
  getLangTrendsChartDataSuccess,
  getAllPostTrendsChartData,
  getAllPostTrendsChartDataSuccess,
  getActiveUserTrendsChartData,
  getActiveUserTrendsChartDataSuccess,
  getPodActiveUserTrendsChartData,
  getPodActiveUserTrendsChartDataSuccess,
  getLanguageActiveUserTrendsChartData,
  getLanguageActiveUserTrendsChartDataSuccess,
  getGenreActiveUserTrendsChartData,
  getGenreActiveUserTrendsChartDataSuccess,
  getAllPercentageOfPostByXViewData,
  getAllPercentageOfPostByXViewDataSuccess,
  getAllUserData,
  getAllUserDataSuccess,
  getAllPostsData,
  getAllPostsDataSuccess,
  getAggData,
  getAggDataSuccess,
  getTeamData,
  getTeamDataSuccess,
  getManagerDataSuccess,
  getManagerData,
  deleteUser,
  deleteUserSuccess,
  createUser,
  createUserSuccess,
  updateUser,
  updateUserSuccess,
  getLogs,
  getLogsSuccess,
  getLeads,
  getLeadsSuccess,
  getCSVData,
  getCSVDataSuccess,
  getDuplicateContentChartData,
  getDuplicateContentChartDataSuccess,
  uploadLeaderboard,
  getLeaderboardDataByFilters,
  getLeaderboardDataByFiltersSuccess,
  resetState,
} = communitySlice.actions

export default communitySlice.reducer
