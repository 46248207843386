import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { Popover } from "antd"
import { InfoCircleOutlined, GoogleOutlined } from "@ant-design/icons"

const ZoneCard = props => {
  const { data } = props
  const isSelected =
    props.selectedIds && props.selectedIds.includes(data.zone_uuid)
  const relationalSelected =
    props.relationalIds && props.relationalIds.includes(data.zone_uuid)
  const status = isSelected ? "REMOVE" : "ADD"
  const imgSource = data.zone_image
    ? data.zone_image
    : "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/default/default.jpg"
  return (
    <div
      className={
        props.className ||
        `question-card text-center contentCard ${
          isSelected ? "selectedCard" : ""
        } ${relationalSelected ? "warningCard" : ""}`
      }
      onClick={() => props.handleSelection(data, status, props.action)}
    >
      <div
        className="bg-transparent d-flex justify-content-between align-items-center w-100"
        style={{ padding: "7px", flex: "auto" }}
      >
        <div
          className="card-text-footer
        "
        >
          {props.title || data.title}
        </div>
        <div className="d-flex align-items-baseline">
          {data.zone_image && (
            <Popover
              trigger="hover"
              color="#D3D3D3"
              content={() => props.renderCardContent(data)}
              destroyTooltipOnHide
            >
              <InfoCircleOutlined size={60} className="mx-2" />
            </Popover>
          )}

          <a
            href={`https://www.google.com/search?q=${data.title}&igu=1&tbm=isch`}
            target="_blank"
            rel="noreferrer"
          >
            <GoogleOutlined size={60} />
          </a>
        </div>
      </div>
    </div>
  )
}

ZoneCard.PropTypes = {
  data: PropTypes.object.isRequired,
  _handleSelection: PropTypes.func,
  renderCardContent: PropTypes.func,
}

export default ZoneCard
