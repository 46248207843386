import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  Row,
  Col,
  CardImg,
  Card as ReactCard,
  CardBody,
  Badge,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Chips from "components/CommonFilter/chips"
import {
  manageGiftCollectionEntityFilters,
  NO_PERMISSION_MESSAGE,
} from "constants/uiConstants"
import { getAllTemplateSections } from "../../appRedux/slices/templateSection"
import Auxiliary from "../../util/Auxiliary"
import {
  Divider,
  Input,
  Pagination,
  Tabs,
  Modal,
  Card,
  message,
  Tooltip,
  Select,
  Radio,
  Popconfirm,
} from "antd"
import "../Comment/browseComment.style.css"
import _ from "lodash"
import "./templateSectionStyle.css"

const { Meta } = Card

const TepmlateSectionReorder = props => {
  const dispatch = useDispatch()

  const [templateSectionList, setTemplateSectionList] = useState(
    props?.data || []
  )

  useEffect(async () => {
    await dispatch(
      getAllTemplateSections({ filters: { pageSize: 1000, currentPage: 1 } })
    )
  }, [dispatch])

  const { currentUser, allTemplateSections } = useSelector(state => ({
    currentUser: _.get(state, "currentUser.currentUser"),
    allTemplateSections: _.get(state, "templateSection.allTemplateSections"),
  }))

  useEffect(() => {
    setTemplateSectionList(allTemplateSections)
  }, [allTemplateSections])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = result => {
    // dropped outside the list
    const oldSectionList = templateSectionList?.length
      ? templateSectionList
      : props?.data || []

    if (!result.destination) {
      return
    }

    const dataList = reorder(
      oldSectionList,
      result.source.index,
      result.destination.index
    )
    setTemplateSectionList(dataList)
  }

  const sectionList = templateSectionList.length
    ? templateSectionList
    : props?.data || []
  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <Button
          size="sm"
          color="primary"
          className="font-size-12 text-truncate"
          onClick={() => props.onCancel()}
        >
          <i className="fa fa-chevron-left mx-1 my-1" />
          Back
        </Button>

        <Button
          size="sm"
          color="primary"
          className="font-size-12 text-truncate me-2"
          onClick={() => {
            // if (!currentUser?.permissions?.includes("EDIT_TEMPLATES")) {
            //   toastr.error(NO_PERMISSION_MESSAGE)
            //   return
            // }
            props.onSubmit(templateSectionList)
          }}
        >
          <i className="fa fa-save mx-1 my-1" />
          Update
        </Button>
      </div>
      <div style={{ marginBottom: 30 }} bordered={true}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sectionList.map((tab, index) => (
                  <Draggable
                    key={`${tab["section_id"]}`}
                    draggableId={`${tab["section_id"]}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Row>
                          <Col>
                            <Card
                              className="cardStyle"
                              cover={
                                <span className="avatar-title rounded-circle bg-gradient font-size-24 font-weight-semibold width_70px">
                                  {(tab.section_name &&
                                    tab.section_name.charAt(0).toUpperCase()) ||
                                    ""}
                                </span>
                              }
                              bordered={true}
                              style={{
                                padding: "10px 20px",
                                display: "flex",
                              }}
                            >
                              <div className="d-flex">
                                <Meta
                                  title={tab.section_name}
                                  className="me-2"
                                />
                                <CopyToClipboard
                                  text={tab?.section_id}
                                  onCopy={e => {
                                    toastr.success("Copied sucessfully!")
                                  }}
                                  className="me-2"
                                >
                                  <Tooltip title="Copy Template Section ID">
                                    <i
                                      className="bx bx-copy-alt"
                                      role="button"
                                    />
                                  </Tooltip>
                                </CopyToClipboard>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}

export default TepmlateSectionReorder
