import Axios from "axios"
import toastr from "toastr"

export const requestCMSVideoAssetListByFilters = async ({ payload }) => {
  try {
    let data = await Axios.post(
      "/api/cmsVideoAssets/getCMSVideoAssetsByFilter",
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertCMSVideoAsset = async ({ payload }) => {
  try {
    let data = await Axios.post(
      "/api/cmsVideoAssets/upsertCMSVideoAssets",
      payload
    )
    toastr.success("Updated successfully!!")
    return data
  } catch (error) {
    throw error
  }
}
