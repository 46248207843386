import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
// layouts Format
import DashboardLayout from "../components/Dashboard/DashboardLayout"
import NonAuthLayout from "../components/NonAuthLayout"

const Authmiddleware = ({
  component: Component,
  currentUser,
  route,
  isAuthProtected,
  parentRoute,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !currentUser) {
        return <Redirect to="/login" />
      }
      if (isAuthProtected && currentUser) {
        let userPermissions =
          currentUser.data.permissions &&
          currentUser.data.permissions.length > 0
            ? currentUser.data.permissions
            : []
        if (route.roles.includes(currentUser.data.jotUserType)) {
          return (
            <DashboardLayout
              currentRoute={route}
              parentRoute={parentRoute}
              currentUser={currentUser.data}
            >
              <Component title={route.title} {...props} />
            </DashboardLayout>
          )
        }
        for (let userPermission of userPermissions) {
          if (route.permissions && route.permissions.includes(userPermission)) {
            return (
              <DashboardLayout
                currentRoute={route}
                parentRoute={parentRoute}
                currentUser={currentUser.data}
              >
                <Component title={route.title} {...props} />
              </DashboardLayout>
            )
          }
        }
        if (route?.path !== "/user/verify") {
          return <Redirect to="/user/verify" />
        }
      }
      return (
        <NonAuthLayout>
          <Component title={route.title} {...props} />
        </NonAuthLayout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  route: PropTypes.any,
  currentUser: PropTypes.object,
  parentRoute: PropTypes.object,
}

export default Authmiddleware
