import { createSlice } from "@reduxjs/toolkit"

const cmsUserSlice = createSlice({
  name: "cmsUser",
  initialState: {
    userList: null,
    loading: false,
    deskList: null,
    userDeskMappingList: null,
    agencyList: [],
    cmsUserList: null,
    total: 0,
    user: [],
    roles: [],
  },
  reducers: {
    getCmsUserList(state, action) {
      return {
        ...state,
        userList: null,
      }
    },

    getCmsUserListSuccess(state, { payload }) {
      return {
        ...state,
        userList: payload.data,
      }
    },

    getRoles(state, action) {
      return {
        ...state,
        roles: null,
      }
    },

    getRolesSuccess(state, { payload }) {
      return {
        ...state,
        roles: payload.data,
      }
    },

    getUserListByFilters(state, action) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        user: [],
      }
    },
    getUserListByFiltersSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        user: payload.data.userList,
        total: payload.data.total,
        refetchData: false,
      }
    },
    updateUser(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateUserSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    createUser(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createUserSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    getDesksList(state, action) {
      return {
        ...state,
        deskList: null,
      }
    },

    getDesksListSuccess(state, { payload }) {
      return {
        ...state,
        deskList: payload.data,
      }
    },
    getAgencyList(state, action) {
      return {
        ...state,
        agencyList: null,
      }
    },

    getAgencyListSuccess(state, { payload }) {
      return {
        ...state,
        agencyList: payload.data,
      }
    },
    resetState(state) {
      return {
        userList: [],
        total: 0,
        cmsUserList: [],
        user: [],
      }
    },
  },
})

export const {
  getUserListByFilters,
  getCmsUserList,
  getCmsUserListSuccess,
  getUserListByFiltersSuccess,
  updateUser,
  updateUserSuccess,
  resetState,
  createUser,
  getRoles,
  getRolesSuccess,
  createUserSuccess,
  getDesksList,
  getDesksListSuccess,
  getAgencyList,
  getAgencyListSuccess,
} = cmsUserSlice.actions

export default cmsUserSlice.reducer
