import { call, put, takeLatest } from "redux-saga/effects"
import { requestLocationList } from "../requests/location"
import {
  getLocationData,
  getLocationDataSuccess,
} from "appRedux/slices/location"
import { fetchError } from "appRedux/slices/common"

function* handleGetLocationList() {
  try {
    const data = yield call(requestLocationList)
    yield put(getLocationDataSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getLocationData.type, handleGetLocationList)
}
