import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Divider } from "antd"
import { Col, Row } from "reactstrap"

const TemplateDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="6" md="8">
          <Input.Group compact={true}>
            <Row className="w-100 d-flex">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["templateId"]}
                  placeholder={"Template ID"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "templateId")
                  }
                  allowClear={true}
                  name="templateId"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["label"]}
                  placeholder={"Label"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "label")
                  }
                  allowClear={true}
                  name="label"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["category"]}
                  placeholder={"Category"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "category")
                  }
                  allowClear={true}
                  name="category"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

TemplateDrawer.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(TemplateDrawer)
