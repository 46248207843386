import React from "react"
import ErrorBoundComponent from "components/ErrorBoundComponent"
import { connect } from "react-redux"
import QueryString from "query-string"
import UpsertContest from "./upsertContest"
import {
  upsertContest,
  getContestDetails,
  updateCategory,
  addCategory,
  addSubCategory,
  deleteBanner,
} from "appRedux/slices/contest"
import _ from "lodash"
import Auxiliary from "../../util/Auxiliary"
import { resetState } from "appRedux/slices/contest"

class ManageContest extends ErrorBoundComponent {
  state = {
    filters: {
      contestUuid:
        this.props.queryParams && this.props.queryParams["contestUuid"]
          ? this.props.queryParams["contestUuid"]
          : null,
    },
    formAction: "create",
  }

  componentDidMount() {
    if (!this.props.common.error && this.state.filters.contestUuid) {
      this.props.dispatch(
        getContestDetails({ contestUuid: this.state.filters.contestUuid })
      )
      this.setState({
        formAction: "update",
      })
    }
  }

  componentDidUpdate() {
    if (this.props.refetchContestDetails) {
      this.props.dispatch(
        getContestDetails({ contestUuid: this.state.filters.contestUuid })
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _upsertContest = formData => {
    this.props.dispatch(upsertContest(formData))
  }

  _updateCategory = formData => {
    this.props.dispatch(updateCategory(formData))
  }

  _addCategory = formData => {
    this.props.dispatch(addCategory(formData))
  }

  _addSubCategory = formData => {
    this.props.dispatch(addSubCategory(formData))
  }

  _deleteBanner = formData => {
    this.props.dispatch(deleteBanner(formData))
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <UpsertContest
          upsertContest={this._upsertContest}
          updateCategory={this._updateCategory}
          addCategory={this._addCategory}
          contest={this.props.contest}
          formAction={this.state.formAction}
          updateSubCategory={this._addSubCategory}
          deleteBanner={this._deleteBanner}
          currentUser={this.props.currentUser}
        />
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "contest.loading"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    contest: _.get(store, "contest.contest"),
    refetchContestDetails: _.get(store, "contest.refetchContestDetails"),
  }
}

export default connect(mapStateToProps)(ManageContest)
