export const jsonData = [
  {
    label: "Is the video vulgar?",
    parent: "taxonomy_video_quality",
    level: "L1",
    key: "is_video_vulgar",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
    dependentQuestionnaire: [
      {
        label: "Is it a intentional sexual act?",
        parent: "taxonomy_video_quality",
        level: "L1",
        key: "intentional_sexual_act",
        sublevel: [
          {
            label: "Yes",
            key: "simple",
            value: true,
          },
          {
            label: "No",
            key: "simple",
            value: false,
          },
        ],
        dependentValue: true,
      },
      {
        label: "Is it showing a kiss?",
        parent: "taxonomy_video_quality",
        level: "L1",
        key: "kiss",
        sublevel: [
          {
            label: "Yes",
            key: "simple",
            value: true,
          },
          {
            label: "No",
            key: "simple",
            value: false,
          },
        ],
        dependentValue: true,
      },
      {
        label: "Is it showing a romantic hug?",
        parent: "taxonomy_video_quality",
        level: "L1",
        key: "romantic_hug",
        sublevel: [
          {
            label: "Yes",
            key: "simple",
            value: true,
          },
          {
            label: "No",
            key: "simple",
            value: false,
          },
        ],
        dependentValue: true,
      },
      {
        label: "Is it showing an intentional tease (arousing sexuality)?",
        parent: "taxonomy_video_quality",
        level: "L1",
        key: "intentional_tease",
        sublevel: [
          {
            label: "Yes",
            key: "simple",
            value: true,
          },
          {
            label: "No",
            key: "simple",
            value: false,
          },
        ],
        dependentValue: true,
      },
      {
        label:
          "Is the video glamorous/sexy (but not arousing sexually, its just stylish clothing which might have a bit of exposure)?",
        parent: "taxonomy_video_quality",
        level: "L1",
        key: "glamorous_sexy",
        sublevel: [
          {
            label: "Yes",
            key: "simple",
            value: true,
          },
          {
            label: "No",
            key: "simple",
            value: false,
          },
        ],
        dependentValue: true,
      },
    ],
  },
]
