import React from "react"
import { connect } from "react-redux"
import Questionnaire from "./questionnaire"

class OrthogonalQuality extends Questionnaire {
  state = {
    currentTab: "orthogonalQuality",
    labelToDisplay: "Orthogonal Quality",
  }
  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderStepper()}
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    currentData: _.get(store, "taxonomy.orthogonalQuality"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(OrthogonalQuality)
