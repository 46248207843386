import React from "react"
import { withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, Divider } from "antd"
import { Col, Row } from "reactstrap"
import { LANGUAGES } from "../../constants/uiConstants"
import "react-datepicker/dist/react-datepicker.css"
import ReactSelect from "react-select"
import "../../containers/Zone/style.css"

const { Option } = Select

const formatOptionLabel = ({ value, title, zone_image }) => {
  return (
    <span>
      {zone_image ? (
        <img
          src={zone_image}
          alt={title}
          style={{ hight: "20px", width: "20px" }}
        />
      ) : (
        <i className="fa fa-users" aria-hidden="true"></i>
      )}{" "}
      &nbsp;
      <b>{title}</b>
    </span>
  )
}

const ImageDrawer = props => {
  const callFrom = props.callFrom || undefined
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Image Id</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["imageId"]}
                  placeholder={"Image Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "imageId")
                  }
                  allowClear={true}
                  name="imageId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Title</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["title"]}
                  placeholder={"Title"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "title")
                  }
                  allowClear={true}
                  name="title"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">User UUID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["userUUID"]}
                  placeholder={"User UUID"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "userUUID")
                  }
                  allowClear={true}
                  name="userUUID"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Type</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["ce_type"]}
            style={{ width: "100%" }}
            disabled={props.currentUser.jotUserType > 2}
            onChange={value => props.handleFilterChange(value, "ce_type")}
          >
            <Option value="PHOTO">PHOTO</Option>
            <Option value="MEME">MEME</Option>
            <Option value="NEWS">NEWS</Option>
          </Select>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Source Type</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["sourceType"]}
            style={{ width: "100%" }}
            disabled={props.currentUser.jotUserType > 2}
            onChange={value => props.handleFilterChange(value, "sourceType")}
          >
            <Option value="GALLERY">GALLERY</Option>
            <Option value="CAMERA">CAMERA</Option>
          </Select>
        </Col>

        <Col
          lg={callFrom == "zone_image" ? "3" : "2"}
          md={callFrom == "zone_image" ? "3" : "4"}
        >
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Language</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Language"
            defaultValue={props.filters["langCode"]}
            onChange={value => {
              props.handleFilterChange(value, "langCode")
            }}
            style={{ width: "100%" }}
          >
            {LANGUAGES.map(lang => (
              <Option value={lang.value}>{lang.label}</Option>
            ))}
          </Select>
        </Col>
        <Col
          lg={callFrom == "zone_image" ? "3" : "2"}
          md={callFrom == "zone_image" ? "3" : "4"}
        >
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Is Active</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["isActive"]}
            style={{ width: "100%" }}
            disabled={props.currentUser.jotUserType > 2}
            onChange={value => props.handleFilterChange(value, "isActive")}
          >
            <Option value="true">True</Option>
            <Option value="false">False</Option>
          </Select>
        </Col>
        {callFrom !== "zone_image" && (
          <>
            <Col lg="2" md="4">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Input Zone</h5>
              </Divider>
              <ReactSelect
                // defaultInputValue={props.filters["subType"]}
                value={
                  props.allZoneList.filter(
                    zone => zone?.zone_uuid === props.filters["inputZone"]
                  )[0] || ""
                }
                style={{ width: "100%" }}
                isMulti={false}
                options={props.allZoneList}
                formatOptionLabel={formatOptionLabel}
                onChange={value =>
                  props.handleFilterChange(value.zone_uuid, "inputZone")
                }
                placeholder="select input zone..."
              />
            </Col>

            <Col lg="2" md="4">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Active Zone</h5>
              </Divider>
              <ReactSelect
                // defaultInputValue={props.filters["subType"]}
                value={
                  props.allZoneList.filter(
                    zone => zone?.zone_uuid === props.filters["activeZone"]
                  )[0] || ""
                }
                style={{ width: "100%" }}
                isMulti={false}
                options={props.allZoneList}
                formatOptionLabel={formatOptionLabel}
                onChange={value =>
                  props.handleFilterChange(value.zone_uuid, "activeZone")
                }
                placeholder="select active zone..."
              />
            </Col>
          </>
        )}
      </Row>
    </React.Fragment>
  )
}

export default withRouter(ImageDrawer)
