import React from "react"
import { connect } from "react-redux"
import ChipInput from "material-ui-chip-input"
import { Card, Button, Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getExclusionEntitiesData,
  updateExclusionEntitiesData,
} from "../../clientServices/exclusionEntitiesService"
import Auxiliary from "../../util/Auxiliary"

class ExclusionEntities extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      clusters: [],
      hashtags: [],
      removedHashtags: [],
      removedClusters: [],
      loading: false,
    }
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    const data = await getExclusionEntitiesData()

    this.setState({
      loading: false,
      hashtags: data?.hashtagData || [],
      clusters: data?.clusterData || [],
    })
  }

  addChip = (value, key) => {
    const keywords = this.state[key].slice()
    keywords.push(value)
    this.setState({ [key]: keywords })
  }

  removeChip = (value, index, key) => {
    const hashtags = [...this.state.hashtags]
    const clusters = [...this.state.clusters]

    if (key === "hashtags") {
      hashtags.splice(index, 1)
    }
    if (key === "clusters") {
      clusters.splice(index, 1)
    }
    this.setState({ hashtags, clusters })
  }

  onSubmit = async () => {
    await updateExclusionEntitiesData({
      hashtags: this.state.hashtags,
      clusters: this.state.clusters,
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        <Card style={{ padding: "10px" }}>
          <Row>
            <Col sm="12" md="12" lg="12">
              <label className="me-1" htmlFor="radioVertical">
                Hashtags
              </label>
              <br></br>
              <ChipInput
                value={this.state.hashtags}
                onAdd={chip => this.addChip(chip, "hashtags")}
                onDelete={(chip, index) =>
                  this.removeChip(chip, index, "hashtags")
                }
                style={{ padding: "5px", minHeight: "100px" }}
              />
            </Col>
            <Col sm="12" md="12" lg="12" className="mt-2">
              <label className="me-1" htmlFor="radioVertical">
                Clusters
              </label>
              <br></br>
              <ChipInput
                value={this.state.clusters}
                onAdd={chip => this.addChip(chip, "clusters")}
                onDelete={(chip, index) =>
                  this.removeChip(chip, index, "clusters")
                }
                style={{ padding: "5px", minHeight: "100px" }}
              />
            </Col>
            <Col
              sm="12"
              md="12"
              lg="12"
              className="mt-3 d-flex justify-content-end"
            >
              <Button color="primary" onClick={this.onSubmit}>
                Submit
              </Button>
            </Col>
          </Row>
        </Card>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ExclusionEntities)
