import React, { useEffect, useState, createRef } from "react"
import { connect } from "react-redux"
import CommentDetailPage from "./commentDetailPage"
import { Tag } from "antd"
import Dropzone from "react-dropzone"
import NoDataFound from "../../components/NoDataFound/noDataFound"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Form,
  CardTitle,
  Modal,
} from "reactstrap"
import { Tabs, Tooltip, DatePicker } from "antd"
import { Carousel } from "react-bootstrap"
import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Divider } from "antd"
import { Link } from "react-router-dom"
import { getContentByFilter } from "appRedux/slices/content"
import ContentCard from "components/ContentCard/contentCard"
import { getUserListByFilters as getCmsUser } from "appRedux/slices/cmsUser"
import { getUserListByFilters } from "appRedux/slices/appUser"
import { upsertIssue } from "appRedux/slices/creatorIssues"
import UserCard from "components/UserList/userCard"
import InlineEdit from "../../components/InlineEdit/inlineEdit"
import _ from "lodash"
import { ISSUE_TYPES, TEAM_TO_DL_MAP } from "../../constants/uiConstants"
import { CopyToClipboard } from "react-copy-to-clipboard"

const { TabPane } = Tabs

const STATUS_MAP = {
  reported: {
    name: "Reported",
    colorCode: "#3746ff",
    textColor: "FFFFFF",
  },
  verified_open: {
    name: "Verified and Open",
    colorCode: "#ff0047",
    textColor: "FFFFFF",
  },
  in_progress: {
    name: "In Progress",
    colorCode: "#329ea8",
    textColor: "FFFFFF",
  },
  waiting_for_feedback: {
    name: "Waiting for Feedback",
    colorCode: "#f9fd0f",
    textColor: "black",
  },
  closed: {
    name: "Closed",
    colorCode: "green",
    textColor: "black",
  },
}

const PRIORITY_MAP = {
  high: {
    name: "High",
    colorCode: "#ff0047",
    textColor: "FFFFFF",
  },
  low: {
    name: "Low",
    colorCode: "#3746ff",
    textColor: "black",
  },
  moderate: {
    name: "Moderate",
    colorCode: "#f9fd0f",
    textColor: "black",
  },
}

class IssueDetailsWidget extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      messages: this.props.commentData,
      groups: [],
      activeTab: "1",
      curMessage: "",
      issuesData: undefined,
      curChildMessage: "",
      issueDetails: this.props.issuesData,
      fileList: [],
      formElementType: this.props.data && this.props.data.element_type,
      selectedFiles: [],
      editPermission:
        this.props.currentUser?.permissions?.includes("EDIT_BROWSE_ISSUE"),
    }
  }
  componentDidMount() {
    const [issuesData] = this.props.issuesData
    this.setState({ issuesData })
    this.props.dispatch(getCmsUser({ currentPage: 1, pageSize: "10000" }))
  }

  onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setcurMessage(value)
    }
  }

  _renderSupportingDetails = (asset, assetType) => {
    let [, , , type] = asset.fileName.split(".")
    if (
      assetType === "image" ||
      ["webp", "jpg", "jpeg", "png"].includes(type)
    ) {
      return {
        src: asset.fileName ? asset.fileName : "",
        caption: "",
      }
    }

    if (type === "mp4" && assetType === "video") {
      return (
        <div className="d-flex justify-content-around">
          <video
            src={asset.fileName ? asset.fileName : ""}
            // ref={videoRef}
            height="450px"
            width="256px"
            controls={true}
            controlsList={"nodownload"}
            // muted={props.pageType === "tagContent" ? false : true}

            onContextMenu={e => {
              e.preventDefault()
              return false
            }}
            autoPlay={false}
          />
        </div>
      )
    }
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  formatter(date, now) {
    const d = now - date

    if (d < minuteSeconds * 2) {
      return {
        value: `now`, // this is the value should be displayed
        next: minuteSeconds * 2 - d, // this number is used to schedule the next update of a value
      }
    } else if (d < hourSeconds) {
      const minutes = Math.floor(d / minuteSeconds)
      return {
        value: `${minutes} minutes ago`,
        next: minuteSeconds - (d % 60),
      }
    } else if (d < daySeconds) {
      const hours = Math.floor(d / hourSeconds)
      return {
        value: `${hours} hour${hours > 1 ? `s` : ``} ago`,
        next: hourSeconds - (d % hourSeconds),
      }
    } else {
      const days = Math.floor(d / daySeconds)
      return {
        value: `${days} day${days > 1 ? `s` : ``} ago`,
        next: daySeconds - (d % daySeconds),
      }
    }
  }

  _updateIssueDetails = (field, value) => {
    let data = new FormData()
    const email = this.props.currentUser && this.props.currentUser.email
    data.append("fields", JSON.stringify({ [field]: value, modifiedBy: email }))
    this.props.dispatch(
      upsertIssue({ issueId: this.state.issuesData.issueId, data })
    )
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  _getTeam = val => {
    const keyArray = Object.keys(TEAM_TO_DL_MAP).filter(
      k => TEAM_TO_DL_MAP[k] === val
    )
    if (keyArray && keyArray[0]) {
      return keyArray[0]
    } else return val
  }

  _renderDetailsBlock = issuesData => {
    const currentEmail = this.props.currentUser && this.props.currentUser.email
    const isIssueOwner =
      this.props.isAdmin ||
      currentEmail === issuesData.assignedTo ||
      currentEmail === issuesData.reportedBy ||
      (issuesData.pocList &&
        issuesData.pocList.length > 0 &&
        issuesData.pocList.includes(currentEmail))

    return (
      <Card>
        <CardBody>
          <Row>
            <CardTitle className="pb-2 font-size-20">
              {this._renderTitle("title", issuesData)}
            </CardTitle>
            <div className="pb-3">
              {this._renderTitle("summary", issuesData, "textarea")}
            </div>
            <Divider />
            <div className="font-size-18 pb-3">Details</div>
            <Col md="6">
              <Row>
                <Col md="4" className="font-weight-semibold">
                  Status:
                </Col>
                {!isIssueOwner && (
                  <Col md="8" className="d-flex">
                    <div className="d-flex">
                      <Tag
                        color={
                          STATUS_MAP[issuesData.status]
                            ? STATUS_MAP[issuesData.status].colorCode
                            : "green"
                        }
                        className="tagStyle"
                        style={{
                          color: STATUS_MAP[issuesData.status]
                            ? STATUS_MAP[issuesData.status].textColor
                            : "black",
                        }}
                      >
                        {issuesData.status &&
                          STATUS_MAP[issuesData.status] &&
                          STATUS_MAP[issuesData.status].name}
                      </Tag>
                    </div>
                  </Col>
                )}
                {isIssueOwner && (
                  <Col md="8" className="d-flex">
                    <div className="d-flex">
                      <Tag
                        color={
                          STATUS_MAP[issuesData.status]
                            ? STATUS_MAP[issuesData.status].colorCode
                            : "green"
                        }
                        className="tagStyle"
                        style={{
                          color: STATUS_MAP[issuesData.status]
                            ? STATUS_MAP[issuesData.status].textColor
                            : "black",
                        }}
                      >
                        {this.state.editPermission ? (
                          <InlineEdit
                            text={
                              (this.state.issueDetails.status &&
                                STATUS_MAP[this.state.issueDetails.status] &&
                                STATUS_MAP[this.state.issueDetails.status]
                                  .name) ||
                              (issuesData.status &&
                                STATUS_MAP[issuesData.status] &&
                                STATUS_MAP[issuesData.status].name) ||
                              "Select"
                            }
                            onSetText={value =>
                              this.setState({
                                issueDetails: {
                                  ...this.state.status,
                                  status: value,
                                },
                              })
                            }
                            onSubmit={value =>
                              this._updateIssueDetails("status", value)
                            }
                            showHover={true}
                            type="single-select"
                            options={Object.keys(STATUS_MAP).map(status => {
                              return {
                                value: status,
                                label: STATUS_MAP[status]
                                  ? STATUS_MAP[status].name
                                  : "select",
                              }
                            })}
                          />
                        ) : (
                          (this.state.issueDetails.status &&
                            STATUS_MAP[this.state.issueDetails.status] &&
                            STATUS_MAP[this.state.issueDetails.status].name) ||
                          (issuesData.status &&
                            STATUS_MAP[issuesData.status] &&
                            STATUS_MAP[issuesData.status].name) ||
                          "Select"
                        )}
                      </Tag>
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="mt-1">
                <Col md="4" className="font-weight-semibold">
                  Priority:
                </Col>
                <Col md="8" className="d-flex">
                  <Tag
                    color={
                      PRIORITY_MAP[issuesData.issueSeverity] &&
                      PRIORITY_MAP[issuesData.issueSeverity].colorCode
                    }
                    className="tagStyle"
                    style={{
                      color:
                        PRIORITY_MAP[issuesData.issueSeverity] &&
                        PRIORITY_MAP[issuesData.issueSeverity].textColor,
                    }}
                  >
                    <React.Fragment>
                      {!isIssueOwner && (
                        <div>
                          {PRIORITY_MAP[issuesData.issueSeverity] &&
                            PRIORITY_MAP[issuesData.issueSeverity].name}
                        </div>
                      )}
                      {isIssueOwner &&
                        (this.state.editPermission ? (
                          <InlineEdit
                            text={
                              this.state.issueDetails.issueSeverity ||
                              (PRIORITY_MAP[issuesData.issueSeverity] &&
                                PRIORITY_MAP[issuesData.issueSeverity].name)
                            }
                            disabled={this.state.isAdmin}
                            onSetText={value =>
                              this.setState({
                                issueDetails: {
                                  ...this.state.issueDetails,
                                  issueSeverity: value,
                                },
                              })
                            }
                            onSubmit={value =>
                              this._updateIssueDetails("issueSeverity", value)
                            }
                            showHover={true}
                            type="single-select"
                            options={[
                              { label: "High", value: "high" },
                              { label: "Moderate", value: "moderate" },
                              { label: "Low", value: "low" },
                            ]}
                          />
                        ) : (
                          this.state.issueDetails.issueSeverity ||
                          (PRIORITY_MAP[issuesData.issueSeverity] &&
                            PRIORITY_MAP[issuesData.issueSeverity].name)
                        ))}
                    </React.Fragment>
                  </Tag>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md="4" className="font-weight-semibold">
                  Issue Type:
                </Col>
                <Col md="8" className="d-flex">
                  {this.state.editPermission ? (
                    <InlineEdit
                      text={
                        this.state.issueDetails.issueType ||
                        (ISSUE_TYPES[issuesData.issueType] &&
                          ISSUE_TYPES[issuesData.issueType].label)
                      }
                      onSetText={value =>
                        this.setState({
                          issueDetails: {
                            ...this.state.issueDetails,
                            issueType: value,
                          },
                        })
                      }
                      onSubmit={value => {
                        this._updateIssueDetails("issueType", value),
                          this._updateIssueDetails(
                            "issueSeverity",
                            ISSUE_TYPES[value].priority
                          )
                      }}
                      showHover={true}
                      type="single-select"
                      options={Object.keys(ISSUE_TYPES).map(key => {
                        return { label: ISSUE_TYPES[key].label, value: key }
                      })}
                    />
                  ) : (
                    this.state.issueDetails.issueType ||
                    (ISSUE_TYPES[issuesData.issueType] &&
                      ISSUE_TYPES[issuesData.issueType].label)
                  )}
                </Col>
              </Row>
              <Row className="py-2">
                <Col md="4" className="font-weight-semibold">
                  App Version:
                </Col>
                <Col md="8">{this._renderTitle("appVersion", issuesData)}</Col>
              </Row>
              <Row>
                <Col md="4" className="font-weight-semibold">
                  OS Version:
                </Col>
                <Col md="8">{this._renderTitle("osVersion", issuesData)}</Col>
              </Row>
              <Row className="py-2">
                <Col md="4" className="font-weight-semibold">
                  Phone Make:
                </Col>
                <Col md="8">{this._renderTitle("phoneMake", issuesData)}</Col>
              </Row>
              <Row>
                <Col md="4" className="font-weight-semibold">
                  phoneModel:
                </Col>
                <Col md="8">{this._renderTitle("phoneModel", issuesData)}</Col>
              </Row>
              <Row className="py-2">
                <Col md="4" className="font-weight-semibold d-flex">
                  Content Uuid:
                  {issuesData && !_.isEmpty(issuesData.contentUuid) && (
                    <span>
                      <CopyToClipboard
                        text={issuesData.contentUuid}
                        onCopy={e => {
                          toastr.success("Copied sucessfully!")
                        }}
                      >
                        <Tooltip title="Copy Content ID">
                          <i
                            className="fontSize18 bx bx-copy mx-2"
                            role="button"
                          />
                        </Tooltip>
                      </CopyToClipboard>
                    </span>
                  )}
                </Col>
                <Col md="8">{this._renderTitle("contentUuid", issuesData)}</Col>
              </Row>
              <Row className="">
                <Col md="4" className="font-weight-semibold">
                  Profile Name:
                </Col>
                <Col md="8">{this._renderTitle("profileName", issuesData)}</Col>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <Col md="4" className="font-weight-semibold">
                  Assigned To:
                </Col>
                <Col md="8">
                  {/* {this._renderTitle("assignedTo", issuesData)} */}
                  {this.state.editPermission ? (
                    <InlineEdit
                      text={
                        this.state.issueDetails.assignedTo ||
                        issuesData.assignedTo ||
                        "-----"
                      }
                      onSetText={value =>
                        this.setState({
                          issueDetails: {
                            ...this.state.issueDetails,
                            assignedTo: value,
                          },
                        })
                      }
                      onSubmit={value =>
                        this._updateIssueDetails("assignedTo", value)
                      }
                      showHover={this.state.editPermission}
                      type="single-select"
                      options={
                        this.props.cmsUserList &&
                        this.props.cmsUserList.length > 0 &&
                        this.props.cmsUserList.map(user => {
                          return { label: user.email, value: user.email }
                        })
                      }
                    />
                  ) : (
                    this.state.issueDetails.assignedTo ||
                    issuesData.assignedTo ||
                    "-----"
                  )}
                </Col>
              </Row>
              <Row className="py-2">
                <Col md="4" className="font-weight-semibold">
                  Reported By:
                </Col>
                <Col md="8">
                  <Link
                    to={`/users/manage?currentPage=1&pageSize=10&searchText=${issuesData.reportedBy}&searchType=userId`}
                  >
                    {issuesData.reportedBy}
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="font-weight-semibold">
                  Reported On:
                </Col>
                <Col md="8">
                  {moment(issuesData.createdOn).format("DD-MM-YY hh:mm")}
                </Col>
              </Row>
              <Row className="py-2">
                <Col md="4" className="font-weight-semibold">
                  Modified On:
                </Col>
                <Col md="8">
                  {moment(issuesData.modifiedOn).format("DD-MM-YY hh:mm")}
                </Col>
              </Row>
              <Row>
                <Col md="4" className="font-weight-semibold d-flex">
                  Client Id:
                  {issuesData && !_.isEmpty(issuesData.clientId) && (
                    <span>
                      <CopyToClipboard
                        text={issuesData.clientId}
                        onCopy={e => {
                          toastr.success("Copied sucessfully!")
                        }}
                      >
                        <Tooltip title="Copy Client ID">
                          <i
                            className="fontSize18 bx bx-copy mx-2"
                            role="button"
                          />
                        </Tooltip>
                      </CopyToClipboard>
                    </span>
                  )}
                </Col>
                <Col md="8">{this._renderTitle("clientId", issuesData)}</Col>
              </Row>
              <Row className="py-2">
                <Col md="4" className="font-weight-semibold d-flex">
                  User Uuid:
                  {issuesData && !_.isEmpty(issuesData.userUuid) && (
                    <span>
                      <CopyToClipboard
                        text={issuesData.userUuid}
                        onCopy={e => {
                          toastr.success("Copied sucessfully!")
                        }}
                      >
                        <Tooltip title="Copy User ID">
                          <i
                            className="fontSize18 bx bx-copy mx-2"
                            role="button"
                          />
                        </Tooltip>
                      </CopyToClipboard>
                    </span>
                  )}
                </Col>
                <Col md="8">{this._renderTitle("userUuid", issuesData)}</Col>
              </Row>
              <Row className="">
                <Col md="4" className="font-weight-semibold">
                  Responsible Teams:
                </Col>
                <Col md="8">
                  {this.state.editPermission ? (
                    <InlineEdit
                      component={
                        this.state.issueDetails &&
                        this.state.issueDetails.pocList
                          ? this.state.issueDetails.pocList.map(
                              poc =>
                                poc && (
                                  <Badge
                                    pill
                                    className="badge-soft-primary m-1"
                                  >
                                    <div className="font-size-12 p-1">
                                      {this._getTeam(poc)}
                                    </div>
                                  </Badge>
                                )
                            )
                          : issuesData.pocList &&
                            issuesData.pocList.map(
                              poc =>
                                poc && (
                                  <Badge
                                    pill
                                    className="badge-soft-primary m-1"
                                  >
                                    <div className="font-size-12 p-1">
                                      {this._getTeam(poc)}
                                    </div>
                                  </Badge>
                                )
                            )
                      }
                      text={
                        this.state.issueDetails.pocList
                          ? this.state.issueDetails.pocList.map(poc => poc)
                          : issuesData.pocList.map(poc => poc)
                      }
                      onSetText={value =>
                        this.setState({
                          issueDetails: {
                            ...this.state.issueDetails,
                            pocList: value,
                          },
                        })
                      }
                      onSubmit={value =>
                        this._updateIssueDetails(
                          "pocList",
                          JSON.stringify(value)
                        )
                      }
                      showHover={true}
                      type="multi-select"
                      options={Object.keys(TEAM_TO_DL_MAP).map(key => {
                        return { label: key, value: TEAM_TO_DL_MAP[key] }
                      })}
                    />
                  ) : this.state.issueDetails.pocList ? (
                    this.state.issueDetails.pocList.map(poc => poc)
                  ) : (
                    issuesData.pocList.map(poc => poc)
                  )}
                </Col>
              </Row>
              <Row className="py-1">
                <Col md="4" className="font-weight-semibold">
                  POC List:
                </Col>
                <Col md="8">
                  <InlineEdit
                    component={
                      this.state.issueDetails &&
                      this.state.issueDetails.additionalPocs
                        ? this.state.issueDetails.additionalPocs.map(
                            poc =>
                              poc && (
                                <Badge pill className="badge-soft-primary m-1">
                                  <div className="font-size-12 p-1">{poc}</div>
                                </Badge>
                              )
                          )
                        : issuesData.additionalPocs &&
                          issuesData.additionalPocs.length > 0
                        ? issuesData.additionalPocs.map(
                            poc =>
                              poc && (
                                <Badge pill className="badge-soft-primary m-1">
                                  <div className="font-size-12 p-1">{poc}</div>
                                </Badge>
                              )
                          )
                        : "-----"
                    }
                    text={
                      this.state.issueDetails.additionalPocs
                        ? this.state.issueDetails.additionalPocs.map(poc => poc)
                        : "-----"
                    }
                    onSetText={value =>
                      this.setState({
                        issueDetails: {
                          ...this.state.issueDetails,
                          additionalPocs: value,
                        },
                      })
                    }
                    onSubmit={value =>
                      this._updateIssueDetails(
                        "additionalPocs",
                        JSON.stringify(value)
                      )
                    }
                    showHover={true}
                    type="multi-select"
                    options={
                      this.props.cmsUserList &&
                      this.props.cmsUserList.length > 0 &&
                      [
                        { email: "" },
                        ...this.props.cmsUserList,
                        ...Object.keys(TEAM_TO_DL_MAP).map(key => {
                          return { email: TEAM_TO_DL_MAP[key] }
                        }),
                      ].map(user => {
                        return { label: user.email, value: user.email }
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="">
                <Col md="4" className="font-weight-semibold mt-2">
                  Release Date:
                </Col>
                <Col md="8" className="p-0">
                  {this._renderReleaseDate("releaseDate", issuesData)}
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  _fetchContentData = () => {
    if (
      this.state.issuesData.contentUuid &&
      this.props.contentList.length === 0
    ) {
      this.props.dispatch(
        getContentByFilter({
          searchType: "content_uuid",
          searchText: this.state.issuesData.contentUuid,
        })
      )
    }
    this.toggleTab("3")
  }

  _fetchCreatorData = () => {
    if (
      (this.state.issuesData.profileName || this.state.issuesData.userUuid) &&
      this.props.userList.length === 0
    ) {
      this.props.dispatch(
        getUserListByFilters({
          searchType: this.state.issuesData.profileName
            ? "handle"
            : "user_uuid",
          searchText:
            this.state.issuesData.profileName || this.state.issuesData.userUuid,
        })
      )
    }
    this.toggleTab("4")
  }

  _fetchData = k => {
    if (k === "3") {
      this._fetchContentData()
    }
    if (k === "4") {
      this._fetchCreatorData()
    }
  }

  _openFullScreen = image => {
    this.setState({ image })
  }

  _renderSupportingAssets = issuesData => {
    const items =
      issuesData.supportingAssets &&
      issuesData.supportingAssets.length > 0 &&
      issuesData.supportingAssets
        .filter(asset => {
          if (
            ["webp", "jpg", "jpeg", "png"].includes(
              asset.fileName.split(".")[3]
            )
          ) {
            return asset
          }
        })
        .map((asset, i) => this._renderSupportingDetails(asset, "image"))
    const slides = items.map(item => {
      return (
        <Carousel.Item
          key={item.src}
          onClick={() => this._openFullScreen(item.src)}
        >
          <img
            className="d-block"
            src={item.src}
            alt={item.altText}
            style={{
              height: "450px",
              width: "256px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <Carousel.Caption
            captionText={item.caption}
            captionHeader={item.caption}
          />
        </Carousel.Item>
      )
    })

    return (
      <Card style={{ height: "550px" }}>
        <Tabs onChange={k => this._fetchData(k)} type="card">
          <TabPane tab="Screenshots" key="1">
            <div>
              <ul className="list-unstyled chat-list">
                <PerfectScrollbar style={{ height: "450px" }}>
                  <Carousel>{slides}</Carousel>
                </PerfectScrollbar>
              </ul>
            </div>
          </TabPane>
          <TabPane tab="Videos" key="2">
            <div>
              <ul className="list-unstyled chat-list">
                <PerfectScrollbar style={{ height: "450px" }}>
                  {issuesData.supportingAssets &&
                    issuesData.supportingAssets.length > 0 &&
                    issuesData.supportingAssets
                      .filter(asset =>
                        ["mp4"].includes(asset.fileName.split(".")[3])
                      )
                      .map((asset, i) =>
                        this._renderSupportingDetails(asset, "video")
                      )}
                </PerfectScrollbar>
              </ul>
            </div>
          </TabPane>
          <TabPane tab="Content" key="3">
            <div>
              <ul className="list-unstyled chat-list">
                <PerfectScrollbar
                  style={{ height: "450px" }}
                  className="d-flex justify-content-center"
                >
                  <div className="content-card-container">
                    {this.props.contentList &&
                      this.props.contentList.length > 0 && (
                        <ContentCard
                          content={this.props.contentList[0]}
                          currentUser={this.props.currentUser}
                          pageSource="searchAndSelect"
                          key={this.props.contentList[0].content_uuid}
                          enableEdit={false}
                        />
                      )}
                  </div>
                </PerfectScrollbar>
              </ul>
            </div>
          </TabPane>
          <TabPane tab="Creator" key="4">
            <div>
              <ul className="list-unstyled chat-list">
                <PerfectScrollbar
                  style={{ height: "450px" }}
                  className="d-flex justify-content-center"
                >
                  <div style={{ width: "250px" }}>
                    {this.props.userList && this.props.userList.length > 0 && (
                      <UserCard
                        user={this.props.userList[0]}
                        currentUser={this.props.currentUser}
                        pageSource="searchAndSelect"
                        key={this.props.userList[0].user_uuid}
                      />
                    )}
                  </div>
                </PerfectScrollbar>
              </ul>
            </div>
          </TabPane>
        </Tabs>
      </Card>
    )
  }

  _renderTitle = (type, issuesData, fieldType) => {
    const show = this.state.editPermission
    return show ? (
      <InlineEdit
        text={this.state.issueDetails[type] || issuesData[type] || "-----"}
        onSetText={value =>
          this.setState({
            issueDetails: {
              ...this.state.issueDetails,
              [type]: value,
            },
          })
        }
        onSubmit={value => this._updateIssueDetails(type, value)}
        showHover={true}
        type={fieldType || "textfield"}
      />
    ) : (
      this.state.issueDetails[type] || issuesData[type] || "-----"
    )
  }

  _uploadAssets = async () => {
    let data = new FormData()
    const email = this.props.currentUser && this.props.currentUser.email
    for (let i = 0; i < this.state.selectedFiles.length; i++) {
      data.append("files", this.state.selectedFiles[i])
      data.append("fields", JSON.stringify({ modifiedBy: email }))
    }
    await this.props.dispatch(
      upsertIssue({ issueId: this.state.issuesData.issueId, data })
    )
    this.props.refetchIssuesData()
  }

  _renderDropzone = issuesData => {
    return (
      <Card>
        <Form className="">
          <Row>
            <Col sm="12" md="6">
              <Dropzone
                onDrop={acceptedFiles =>
                  this.handleAcceptedFiles(acceptedFiles)
                }
                className="border-0"
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-1 d-flex">
                        <i className="display-6 text-muted bx bxs-cloud-upload" />

                        <div className="px-2 py-3 font-size-16">
                          Drop files here or click to upload supporting assets.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Col>
            {this.state.selectedFiles.length > 0 && (
              <Col sm="12" md="6">
                <div
                  className="dropzone-previews mt-3"
                  id="file-previews"
                  style={{
                    height: "160px",
                    overflow: "hidden",
                    overflowY: "scroll",
                    width: "100%",
                  }}
                >
                  {this.state.selectedFiles.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  })}
                </div>
                <div className="my-2 mx-2 d-flex justify-content-end">
                  <div
                    role="button"
                    className="btn btn-primary mx-2"
                    onClick={() => this._uploadAssets()}
                  >
                    Upload
                  </div>
                  <div
                    role="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this.setState({ selectedFiles: [] })
                    }}
                  >
                    Cancel
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </Card>
    )
  }

  _renderReleaseDate = (type, issuesData) => {
    const show = this.state.editPermission
    const date = this.state.issueDetails[type] || issuesData[type] || null
    return show ? (
      <DatePicker
        // style={{ width: "80%" }}
        bordered={false}
        key={date || ""}
        defaultValue={date ? moment(date, "x") : null}
        format="YYYY-MM-DD HH:mm"
        showTime
        placeholder="Select Date"
        onChange={value =>
          this._updateIssueDetails(
            "releaseDate",
            value?.valueOf()?.toString() || null
          )
        }
      />
    ) : (
      (date && moment(date, "x")) || "-----"
    )
  }

  render() {
    const issuesData = this.state.issuesData
    if (_.isEmpty(issuesData) && !this.props.isAdmin) {
      return (
        <NoDataFound message="You are not authorized to access this data" />
      )
    }
    if (_.isEmpty(issuesData) && this.props.isAdmin) {
      return <NoDataFound message="No Data to Display" />
    }
    if (this.state.image) {
      return (
        <Modal
          isOpen={this.state.image}
          onClick={() => this.setState({ image: undefined })}
          onclickoutside={() => this.setState({ image: undefined })}
          size="md"
        >
          <img src={this.state.image} />
        </Modal>
      )
    }
    return (
      <React.Fragment>
        <div>
          <Container fluid>
            <Row>
              <div className="me-4">
                <div className="" style={{ minWidth: "350px" }}>
                  <Row>
                    <Col md="9">
                      {issuesData && this._renderDetailsBlock(issuesData)}
                      {issuesData && this._renderDropzone(issuesData)}
                    </Col>
                    <Col md="3">
                      {issuesData && this._renderSupportingAssets(issuesData)}
                    </Col>
                  </Row>
                </div>
              </div>

              <CommentDetailPage
                filters={this.props.filters}
                addComment={this.props.addComment}
              />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(store) {
  return {
    issuesList: _.get(store, "creatorIssues.issuesList"),
    contentList: _.get(store, "content.content"),
    loading: _.get(store, "issueComments.loading"),
    common: _.get(store, "common"),
    cmsUserList: _.get(store, "cmsUser.user"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    commentsList: _.get(store, "issueComments.commentsList"),
    userList: _.get(store, "appUser.user"),
  }
}

export default connect(mapStateToProps)(IssueDetailsWidget)
