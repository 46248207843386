import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"
import {
  getMemes,
  getMemesSuccess,
  upsertMeme,
  upsertMemeSuccess,
  reorderMeme,
  reorderMemeSuccess,
} from "../../slices/meme"
import {
  requestMemes,
  requestUpsertMeme,
  requestReorderMeme,
} from "../requests/meme"
import { func } from "prop-types"

function* handleGetMemes(params) {
  try {
    const data = yield call(requestMemes, params)
    yield put(getMemesSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertMeme(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertMeme, params)
    yield put(upsertMemeSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleReorderMeme(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestReorderMeme, params)
    yield put(reorderMemeSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getMemes.type, handleGetMemes)
  yield takeLatest(upsertMeme.type, handleUpsertMeme)
  yield takeLatest(reorderMeme.type, handleReorderMeme)
}
