import { Form, Tooltip, Select } from "antd"
import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"
// import "react-select/dist/react-select.css";
// import VirtualizedSelect from 'react-virtualized-select';
// import "react-virtualized-select/styles.css";
import ValidatorComponent from "./validator_component"
// import Select from "../virtualized_select"
import { FormContextItem } from "./form_context"

class CustomSelect extends ValidatorComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    layout: PropTypes.object,
    toolTipLabel: PropTypes.string,
    placeholderLabel: PropTypes.string,
    field: PropTypes.string,
    options: PropTypes.array.isRequired,
    optionsRenderer: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
    defaultValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]),
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateObj = {}
    let currentValue = nextProps.value ? nextProps.value : prevState.value

    if (!prevState.defaultUpdated && !_.isUndefined(nextProps.defaultValue)) {
      currentValue = nextProps.defaultValue
    }

    if (currentValue || currentValue === 0) {
      if (
        _.find(nextProps.options, option => {
          return option[prevState.valueAttr] == currentValue
        })
      ) {
        _.assignObjectForKey(stateObj, "value", currentValue)
      } else {
        _.assignObjectForKey(stateObj, "value", undefined)
      }
    }

    if (prevState.error) {
      _.assignObjectForKey(
        stateObj,
        "error",
        CustomSelect.validate(
          stateObj.value,
          nextProps.validations,
          nextProps.errors
        )
      )
    }

    if (_.isEmpty(stateObj)) {
      return null
    } else {
      return stateObj
    }
  }

  state = {
    value: undefined,
    error: "",
    defaultUpdated: false,
    valueAttr: this.props.valueAttr || "value",
  }

  _onChange = async value => {
    if (!this.props.value) {
      this.setState(
        {
          value,
          defaultUpdated: true,
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.value)
          }
        }
      )
    } else {
      if (this.props.onChange) {
        await this.props.onChange(value)
        if (this.state.error) {
          this._validate()
        }
      }
    }
  }

  _dropdownRender = (menuNode, props) => {
    return menuNode
  }

  _inputRenderer = () => {
    return (
      <Select
        allowClear={this.props.allowClear}
        showSearch={true}
        placeholder={this.props.placeholderLabel}
        className="full_width"
        optionFilterProp="children"
        disabled={this.props.disabled}
        value={this.state.value}
        onChange={this._onChange}
        dropdownRender={this._dropdownRender}
        optionLabelProp={this.props.optionLabelProp}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        size={this.props.size || "large"}
        style={this.props.style || {}}
      >
        {_.map(this.props.options, (data, index) =>
          this.props.optionsRenderer ? (
            this.props.optionsRenderer(data)
          ) : (
            <Select.Option
              key={`select_options_${data[this.state.valueAttr]}_${index}`}
              value={data[this.state.valueAttr]}
              disabled={data.disabled}
              size="small"
            >
              {data.label}
            </Select.Option>
          )
        )}
      </Select>
    )
  }

  _inputContainer = () => {
    if (this.props.toolTipLabel) {
      return (
        <Tooltip
          title={this.props.toolTipLabel}
          trigger={["focus"]}
          placement="bottom"
        >
          {this._inputRenderer()}
        </Tooltip>
      )
    }
    return this._inputRenderer()
  }

  _render = () => {
    return (
      <Form.Item
        className="full_width"
        validateStatus={this.state.error ? "error" : "success"}
        help={this.state.error}
        label={this.props.label}
        {...this.props.layout}
      >
        {this._inputContainer()}
      </Form.Item>
    )
  }
}

export default FormContextItem(CustomSelect)
