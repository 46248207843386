import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import Cards from "./cards"
import ImageCard from "./imageCard"
import PerfectScrollbar from "react-perfect-scrollbar"
import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"

import { Steps } from "antd"
const { Step } = Steps
class SubGenre extends Cards {
  state = {
    labelToDisplay: "Genre",
    currentHierarchy: ["genre"],
    selectionType: "SINGLE",
    currentTab: "genre",
    currentLevel: 1,
  }
  componentDidMount = () => {}
  _handleSelection = data => {
    let currentData = _.cloneDeep(this.props.currentData)
    let currentLevel = this.state.currentLevel
    let currentHierarchy = [...this.state.currentHierarchy]
    let selectionType = this.state.selectionType
    let labelToDisplay = this.state.labelToDisplay
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = _.cloneDeep(this.props.taxonomyRoutes)

    const indexTab = taxonomyRoutes.findIndex(
      item => item.key == this.props.selectedSection
    )
    if (selectionType === "ALL") {
      currentData.forEach(item => {
        if (item.value === data.value) {
          item.selected = true
        }
      })
    } else {
      currentData.forEach(item => {
        if (item.value === data.value) {
          item.selected = true
          selectedDetails[data.taxonomyJsonKey][data.key] = item.value
        } else {
          if (item.level > data.level) {
            item.selected = false
            selectedDetails[item.taxonomyJsonKey][item.key] = ""
          }
          if (item.level === data.level) {
            item.selected = false
          }
        }
      })
      currentLevel++
    }

    currentData.forEach(item => {
      if (item.level === data.level + 1) {
        if (
          item.parent === data.value ||
          (Array.isArray(item.parent) && item.parent.includes(data.value))
        ) {
          item.isVisible = true
        } else {
          delete item.isVisible
        }
      }
    })

    const nextLevelItems = currentData.filter(
      item =>
        item.level === currentLevel &&
        (item.parent === data.value ||
          (Array.isArray(item.parent) && item.parent.includes(data.value)))
    ).length

    if (nextLevelItems <= 0) {
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    } else {
      taxonomyRoutes[indexTab].completed = false
    }

    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.setSelectedDetails(selectedDetails)
    this.setState({
      currentData,
      currentHierarchy,
      selectedDetails,
      selectionType,
      labelToDisplay,
      currentLevel,
    })
  }
  _renderStepper = () => {
    let text =
      this.state.selectionType === "SINGLE" ? "Select Any" : "Select All"
    const filterdData = this.props.currentData.filter(card => {
      if (card.level === this.state.currentLevel) return true
    })
    let displayLabel
    filterdData.forEach(item => {
      if (item.displayLabel && item.isVisible) displayLabel = item.displayLabel
    })
    const steps = [...Array(this.state.currentLevel).keys()]
    if (this.state.currentLevel > 0) {
      return (
        <div>
          <Steps
            type="navigation"
            size="small"
            current={this.state.currentLevel}
          >
            {steps.map((item, i) => (
              <Step key={item} title={`L${i + 1}`} />
            ))}
          </Steps>
          <div style={{ margin: "1rem", fontWeight: "bold" }}>
            {displayLabel}
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ margin: "1rem", fontWeight: "bold" }}>
          {this.state.labelToDisplay}&nbsp;({text})
        </div>
      )
    }
  }
  _renderQuestions = () => {
    let data = this.props.currentData.filter(data => {
      if (this.state.currentLevel == 1) {
        if (
          this.props.selectedDetails &&
          this.props.selectedDetails["taxonomy_genre"] &&
          data.parent ===
            this.props.selectedDetails["taxonomy_genre"]["target_type"]
        ) {
          return true
        }
      } else if (this.state.currentLevel == 2) {
        if (data.parent) {
          if (
            data["parent"].includes(
              this.props.selectedDetails &&
                this.props.selectedDetails["taxonomy_genre"] &&
                this.props.selectedDetails["taxonomy_genre"]["target_sub_type"]
            )
          ) {
            return true
          }
        }
      }
    })

    const filterdData = data.filter(card => {
      if (card.level === this.state.currentLevel) return true
    })

    return filterdData.length == 0 ? (
      <> No SubGenre needed</>
    ) : (
      <>
        {this._renderStepper()}
        <PerfectScrollbar>
          <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
            {filterdData.map(data => {
              return (
                <ImageCard
                  data={data}
                  _handleSelection={this._handleSelection}
                  renderCardContent={this.props.renderCardContent}
                />
              )
            })}
          </div>
        </PerfectScrollbar>
      </>
    )
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "content.loading"),
    currentData: _.get(store, "taxonomy.genre"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(SubGenre)
