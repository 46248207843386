import React from "react"
import { Upload, Divider, message } from "antd"
import _ from "lodash"
import toastr from "toastr"
import ValidatorForm, {
  Select,
  TextField,
  Switch,
  MultiSelect,
  DateTimePicker,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Collapse } from "antd"
import "./styles.css"
import {
  COLLECTION_TO_LAYOUT_MAP,
  NO_PERMISSION_MESSAGE,
  collectionElements,
} from "../../constants/uiConstants"
import ColorPicker from "../../components/ColorPicker/colorPicker"
import { Button, Row, Col, Card, CardTitle, CardBody } from "reactstrap"
import dayjs from "dayjs"
export default class EditPageForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      locations: [],
      theme: {},
      index: ["0"],
    }
  }

  componentDidMount() {
    if (["add", "entityEdit"].includes(this.props.formAction)) {
      let stateLocationList = []
      let cityLocationList = []
      this.props.locationList.map(location => {
        location.children.map(childrenLocation => {
          let childrenLocationObject = {}
          childrenLocationObject["label"] = childrenLocation.name
          childrenLocationObject["value"] = childrenLocation.dhTagId
          cityLocationList.push(childrenLocationObject)
        })
        let stateLocationObject = {}
        stateLocationObject["label"] = location.name
        stateLocationObject["value"] = location.dhTagId
        stateLocationList.push(stateLocationObject)
      })
      this.setState({
        stateLocationList,
        cityLocationList,
      })
    }
  }

  _getOptions = () => {
    const elementType =
      this.props.data.element_type || this.props.data.elementType
    const collectionType =
      this.props.data &&
      (this.props.data.collection_type || this.props.data.collectionType)
    if (
      collectionType &&
      elementType === "VIDEO" &&
      (collectionType === "TEMPLATE" ||
        collectionType === "STICKER" ||
        collectionType === "EFFECT" ||
        collectionType === "MUSIC")
    ) {
      return COLLECTION_TO_LAYOUT_MAP["COMMON"]
    } else return COLLECTION_TO_LAYOUT_MAP[elementType]
  }

  _getDefaultLayout = layout => {
    const elementType =
      layout === "LAYOUT_TYPE_15"
        ? "COMMON"
        : this.props.data.element_type || this.props.data.elementType
    const layoutArr = COLLECTION_TO_LAYOUT_MAP[elementType]
    const layoutVal = layoutArr.filter(item => item.value === layout)
    return layoutVal[0]["value"]
  }

  _onImageRemove = type => {
    if (type === "background") {
      this.setState({
        backgroundImage: null,
        backgroundImageList: [],
      })
    }
    if (type === "overlay") {
      this.setState({
        overlayImageList: [],
        overlayImage: null,
      })
    } else {
      this.setState({
        imageList: [],
        image: null,
      })
    }
  }

  _getDefaultLocations = () => {
    let locations = []
    this.props.data.target.forEach(entity => {
      if (
        entity.lang_code === "default" &&
        entity.locations &&
        entity.locations.length > 0
      ) {
        entity.locations.forEach(location => {
          locations.push(location.location_id)
        })
      }
    })
    return locations
  }

  _getLocations = () => {
    const locationArray = this.props.locationList.reduce((acc, location) => {
      let cityArray = []
      if (location.children && location.children.length > 0) {
        cityArray = location.children.map(city => {
          return { label: city.name, value: city.dhTagId }
        })
      }
      return [
        ...acc,
        ...cityArray,
        { label: location.name, value: location.dhTagId },
      ]
    }, [])
    return locationArray
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "background") {
        this.setState({
          backgroundImageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          backgroundImage: fileObj.file,
        })
      }
      if (type === "overlay") {
        this.setState({
          overlayImageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          overlayImage: fileObj.file,
        })
      } else {
        this.setState({
          imageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          image: fileObj.file,
        })
      }
    }
  }

  onThemeSubmit = themes => {
    const baseTheme = themes
    let data = new FormData()
    const {
      colorCode = baseTheme.color_code,
      statusBarColor = baseTheme.status_bar_color,
      searchBarBGColor = baseTheme.search_bar_bg_color,
      searchBarFontColor = baseTheme.search_bar_font_color,
      bottomBarBG = baseTheme.bottom_bar_bg,
      searchBoxBGColor = baseTheme.search_box_bg_color,
      startDateMs = baseTheme.stare_date_ms,
      endDateMs = baseTheme.end_date_ms,
      themeName = baseTheme.theme_name,
      themeType = baseTheme.theme_type,
    } = this.state.theme

    if (this.state.backgroundImage) {
      data.append("file1", this.state.backgroundImage)
    }
    if (this.state.overlayImage) {
      data.append("file2", this.state.overlayImage)
    }

    data.append(
      "details",
      JSON.stringify({
        ..._.deepClean({
          colorCode,
          statusBarColor,
          searchBarBGColor,
          searchBarFontColor,
          bottomBarBG,
          searchBoxBGColor,
          startDateMS: startDateMs && startDateMs.valueOf().toString(),
          endDateMS: endDateMs && endDateMs.valueOf().toString(),
          themeName,
          themeType,
        }),
        pageUuid: this.props.data.page_uuid,
      })
    )
    this.props.onThemeUpsert(data, this.props.formAction)
  }

  renderThemeUpsert = (theme, header, key) => {
    let defaultEndDate =
      this.state.theme && this.state.theme.endDateMs
        ? this.state.endDateMs
        : theme.end_date_ms
        ? dayjs(Number(theme.end_date_ms))
        : null
    let defaultStartDate =
      this.state.theme && this.state.theme.startDateMs
        ? this.state.startDateMs
        : theme.start_date_ms
        ? dayjs(Number(theme.start_date_ms))
        : null
    if (this.state.theme.endDateMs) {
      defaultEndDate = dayjs(Number(this.state.theme.endDateMs))
    }
    if (this.state.theme.startDateMs) {
      defaultStartDate = dayjs(Number(this.state.theme.startDateMs))
    }
    return (
      <Collapse.Panel header={header} key={key} defaultActiveKey={["theme"]}>
        <Row className="m-2">
          <ValidatorForm
            onSubmit={this.onThemeSubmit}
            layout={"vertical"}
            style={{ width: "100%" }}
            {...this._formLayout}
          >
            <Col sm={12}>
              <Row
                style={{ width: "100%" }}
                className="justify-content-between"
              >
                <Col sm={5}>
                  <TextField
                    label={"Theme Name"}
                    placeholderLabel="Theme Name"
                    field={"themeName"}
                    defaultValue={theme.theme_name}
                    onChange={val =>
                      this.setState({
                        theme: { ...this.state.theme, themeName: val },
                      })
                    }
                    className="creator-form"
                  />
                </Col>
                <Col sm={5}>
                  <TextField
                    label={"Theme Type"}
                    placeholderLabel="Theme Type"
                    field={"themeType"}
                    defaultValue={theme.theme_type}
                    className="creator-form"
                    onChange={val =>
                      this.setState({
                        theme: { ...this.state.theme, themeType: val },
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="d-flex">
              <Row
                style={{ width: "100%" }}
                className="justify-content-between"
              >
                <Col sm={5}>
                  <DateTimePicker
                    label="Start Date"
                    field="startDateMs"
                    defaultValue={defaultStartDate}
                    onChange={val =>
                      this.setState({
                        theme: {
                          ...this.state.theme,
                          startDateMs: dayjs(val).valueOf(),
                        },
                      })
                    }
                    style={{ padding: "9px", width: "100%" }}
                  />
                </Col>
                <Col sm={5}>
                  <DateTimePicker
                    label="End Date"
                    style={{ padding: "9px", width: "100%" }}
                    field="endDateMs"
                    defaultValue={defaultEndDate}
                    onChange={val =>
                      this.setState({
                        theme: {
                          ...this.state.theme,
                          endDateMs: dayjs(val).valueOf(),
                        },
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <Row className="d-flex justify-content-between">
                <Col sm={5}>
                  <ColorPicker
                    label="Color Code"
                    placeholderLabel="Color Code"
                    field="colorCode"
                    defaultColor={theme.color_code || "#5154b2"}
                    onColorChange={val =>
                      this.setState({
                        theme: { ...this.state.theme, colorCode: val.hex },
                      })
                    }
                  />
                </Col>
                <Col sm={5} className="mx-3">
                  <ColorPicker
                    label="Status Bar Color"
                    placeholderLabel="Status Bar Color"
                    field="statusBarColor"
                    defaultColor={theme.status_bar_color || "#5154b2"}
                    onColorChange={val =>
                      this.setState({
                        theme: {
                          ...this.state.theme,
                          statusBarColor: val.hex,
                        },
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <Row className="d-flex justify-content-between">
                <Col sm={5}>
                  <ColorPicker
                    label="Search Bar Bg Color"
                    placeholderLabel="Search Bar Bg Color"
                    field="searchBarBGColor"
                    defaultColor={theme.search_bar_bg_color || "#5154b2"}
                    onColorChange={val =>
                      this.setState({
                        theme: {
                          ...this.state.theme,
                          searchBarBGColor: val.hex,
                        },
                      })
                    }
                  />
                </Col>
                <Col sm={5} className="mx-3">
                  <ColorPicker
                    label="Search Bar Font Color"
                    placeholderLabel="Search Bar Font Color"
                    field="searchBarFontColor"
                    defaultColor={theme.search_bar_font_color || "#5154b2"}
                    onColorChange={val =>
                      this.setState({
                        theme: {
                          ...this.state.theme,
                          searchBarFontColor: val.hex,
                        },
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <Row className="d-flex justify-content-between">
                <Col sm={5}>
                  <ColorPicker
                    label="Search Box Bg Color"
                    placeholderLabel="Search Box Bg Color"
                    field="searchBoxBGColor"
                    defaultColor={theme.search_box_bg_color || "#5154b2"}
                    onColorChange={val =>
                      this.setState({
                        theme: {
                          ...this.state.theme,
                          searchBoxBGColor: val.hex,
                        },
                      })
                    }
                  />
                </Col>
                <Col sm={5} className="mx-3">
                  <ColorPicker
                    label="Bottom Bar Bg"
                    placeholderLabel="Bottom Bar Bg"
                    field="bottomBarBG"
                    defaultColor={theme.bottom_bar_bg || "#5154b2"}
                    onColorChange={val =>
                      this.setState({
                        theme: { ...this.state.theme, bottomBarBG: val.hex },
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <Row>
                <Col>
                  <Row style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("background")}
                      defaultFileList={this.state.backgroundImageList}
                      fileList={this.state.backgroundImageList}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "background")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        <i className="fas fa-arrow-up" /> Upload background
                        image
                      </Button>
                    </Upload>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("overlay")}
                      defaultFileList={this.state.overlayImageList}
                      fileList={this.state.overlayImageList}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "overlay")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        <i className="fas fa-arrow-up" /> Upload overlay image
                      </Button>
                    </Upload>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              className="d-flex justify-content-end"
              style={{ width: "100%" }}
            >
              <Button
                htmlType="submit"
                onClick={e => {
                  e.preventDefault(), this.onThemeSubmit(theme)
                }}
                color="success"
              >
                Update Theme
              </Button>
            </Col>
          </ValidatorForm>
        </Row>
      </Collapse.Panel>
    )
  }

  _form = () => {
    const collectionType =
      this.props.data &&
      (this.props.data.collection_type || this.props.data.collectionType)
    const elementType =
      this.props.data &&
      (this.props.data.element_type || this.props.data.elementType)
    const defaultLayout =
      this.props.data && this.props.data.layout_type
        ? this._getDefaultLayout(this.props.data.layout_type)
        : this.props.data && this._getOptions() && this._getOptions()[0].value
    const themeHeader = (
      <span>
        {" "}
        <span
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            this.setState({ showAddtheme: false })
          }}
        >
          <i className="fas fa-trash" style={{ color: "red" }} />
        </span>{" "}
        #Theme
      </span>
    )
    return (
      <Card>
        <CardBody>
          <CardTitle>{this.props.title || ""}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            {!["add", "edit"].includes(this.props.formAction) && (
              <Row>
                <Col sm={6}>
                  <TextField
                    label={"Title"}
                    placeholderLabel="Title"
                    field={"title"}
                    disabled={this.props.formAction === "update" ? true : false}
                    defaultValue={this.props.data && this.props.data.title}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                  <Select
                    label="Page Type"
                    placeholderLabel="Page Type"
                    field="page_type"
                    disabled={this.props.formAction === "update" ? true : false}
                    allowClear={true}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                    defaultValue={this.props.data && this.props.data.page_type}
                    options={[
                      { label: "Discovery", value: "DISCOVERY" },
                      { label: "Audio Library", value: "AUDIO_LIBRARY" },
                      { label: "Feed Page", value: "FEED" },
                      { label: "Trending Page", value: "TRENDING" },
                      { label: "Music", value: "MUSIC" },
                      { label: "Game", value: "GAME" },
                      { label: "Effect", value: "EFFECT" },
                      { label: "Video", value: "VIDEO" },
                      { label: "Sticker", value: "STICKER" },
                      { label: "Template", value: "TEMPLATE" },
                      { label: "Zone", value: "ZONE" },
                    ]}
                  />
                </Col>
                <Col sm={6}>
                  <Select
                    label="Status"
                    placeholderLabel="Status"
                    field="status"
                    allowClear={true}
                    className="creator-form"
                    defaultValue={this.props.data && this.props.data.status}
                    disabled={this.props.data && this.props.data.default_page}
                    validations={["required"]}
                    errors={["This field is required"]}
                    options={[
                      { label: "Draft", value: "DRAFT" },
                      { label: "Published", value: "PUBLISHED" },
                      { label: "Removed", value: "REMOVED" },
                    ]}
                  />
                  <Switch
                    defaultValue={
                      this.props.data && this.props.data.is_trending
                    }
                    label={"Is Trending"}
                    field={"is_trending"}
                  />
                </Col>
                {this.props.formAction === "update" && (
                  <>
                    {this.props.data &&
                      this.props.data.themes &&
                      Object.keys(this.props.data.themes).filter(
                        themeKey =>
                          this.props.data.themes[themeKey].status === "ACTIVE"
                      ).length > 0 && (
                        <Divider
                          orientation="center"
                          style={{ fontSize: "16px" }}
                          plain
                        >
                          Theme
                        </Divider>
                      )}
                    <Col sm={12}>
                      <Collapse>
                        {this.props.data &&
                          this.props.data.themes &&
                          Object.keys(this.props.data.themes)
                            .filter(
                              themeKey =>
                                this.props.data.themes[themeKey].status ===
                                "ACTIVE"
                            )
                            .map((themeKey, i) => {
                              const theme = this.props.data.themes[themeKey]
                              const status = theme.status
                              const header = (
                                <span>
                                  {status === "ACTIVE" && (
                                    <span
                                      style={{ paddingRight: "10px" }}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        this.props.onDeleteThemeClick(theme)
                                      }}
                                    >
                                      <i
                                        className="fas fa-trash"
                                        style={{ color: "red" }}
                                      />
                                    </span>
                                  )}
                                  {themeKey}
                                </span>
                              )
                              const key = i
                              return this.renderThemeUpsert(theme, header, key)
                            })}
                      </Collapse>
                    </Col>
                    {this.state.showAddtheme && (
                      <Col sm={12}>
                        <Collapse defaultActiveKey={["theme"]}>
                          {this.renderThemeUpsert({}, themeHeader, "theme")}
                        </Collapse>
                      </Col>
                    )}
                    <Divider
                      orientation="center"
                      style={{ fontSize: "16px" }}
                      plain
                    >
                      <Button
                        color="primary"
                        onClick={() => {
                          if (!this.props.hasEditPermission) {
                            toastr.error(NO_PERMISSION_MESSAGE)
                          } else {
                            this.setState({
                              showAddtheme: true,
                              index: [...this.state.index, "0"],
                            })
                          }
                        }}
                      >
                        Add Theme
                      </Button>
                    </Divider>
                  </>
                )}
              </Row>
            )}
            {["add", "edit", "entityEdit"].includes(this.props.formAction) && (
              <>
                <Row>
                  <Col sm={6}>
                    <TextField
                      label={"Collection Type"}
                      placeholderLabel={collectionType}
                      field={"collection_type"}
                      disabled={true}
                      defaultValue={collectionType}
                      className="creator-form"
                    />
                  </Col>
                  <Col sm={6}>
                    <TextField
                      label={"Collection UUID"}
                      placeholderLabel={
                        this.props.data &&
                        (this.props.data.collectionId ||
                          this.props.data.collection_uuid)
                      }
                      field={"collection_uuid"}
                      validations={["required"]}
                      errors={["This field is required"]}
                      disabled={
                        !(collectionType && collectionType === "HASHTAG")
                      }
                      defaultValue={
                        this.props.data &&
                        (this.props.data.collectionId ||
                          this.props.data.collection_uuid)
                      }
                      className="creator-form"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <Select
                      label="Collection Format (V2)"
                      placeholderLabel="Collection Format"
                      field="collection_format"
                      allowClear={true}
                      disabled
                      className="creator-form"
                      defaultValue={this.props.data.collection_format}
                      options={collectionElements.COLLECTION_FORMAT}
                    />
                  </Col>
                  <Col sm={6}>
                    <TextField
                      label={"Element Type"}
                      placeholderLabel={elementType}
                      field={"element_type"}
                      disabled={true}
                      defaultValue={elementType}
                      className="creator-form"
                    />
                  </Col>
                  <Col sm={6}>
                    <Select
                      label="Layout Type"
                      placeholderLabel="Layout Type"
                      field="layout_type"
                      allowClear={true}
                      className="creator-form"
                      // defaultValue={
                      //   this.props.data &&
                      //   this._getOptions() &&
                      //   this._getOptions()[0].value
                      // }
                      defaultValue={defaultLayout}
                      options={this.props.data && this._getOptions()}
                    />
                  </Col>
                  {elementType === "VIDEO" && (
                    <Col sm={6}>
                      <Switch
                        defaultValue={
                          this.props.data && this.props.data.votable
                        }
                        label={"Votable"}
                        field={"votable"}
                      />
                    </Col>
                  )}
                  <Col sm={6}>
                    <Switch
                      defaultValue={
                        this.props.data && this.props.data.is_trending
                      }
                      label={"Is Trending"}
                      field={"is_trending"}
                    />
                  </Col>
                  <Col sm={6}>
                    <TextField
                      label={"Subtitle"}
                      placeholderLabel="Subtitle"
                      field={"subtitle"}
                      defaultValue={this.props.data && this.props.data.subtitle}
                      className="creator-form"
                    />
                  </Col>
                  {elementType === "CREATOR" && (
                    <Col sm={6}>
                      <Switch
                        defaultValue={
                          this.props.data && this.props.data.show_follow
                        }
                        label={"Show Follow"}
                        field={"show_follow"}
                      />
                    </Col>
                  )}
                  {["BANNER"].includes(elementType) && (
                    <>
                      <Col sm={6}>
                        <TextField
                          label={"Height"}
                          placeholderLabel={
                            this.props.data && this.props.data.height
                              ? this.props.data.height
                              : "180"
                          }
                          field={"height"}
                          defaultValue={
                            this.props.data && this.props.data.height
                              ? this.props.data.height
                              : "180"
                          }
                          className="creator-form"
                        />
                      </Col>
                      <Col sm={6}>
                        <TextField
                          label={"Width"}
                          placeholderLabel={
                            this.props.data && this.props.data.width
                              ? this.props.data.width
                              : "375"
                          }
                          field={"width"}
                          defaultValue={
                            this.props.data && this.props.data.width
                              ? this.props.data.width
                              : "375"
                          }
                          className="creator-form"
                        />
                      </Col>
                    </>
                  )}
                  {["SUBBANNER", "VIDEO_BANNER"].includes(elementType) && (
                    <>
                      <Col sm={6}>
                        <TextField
                          label={"Height"}
                          placeholderLabel={
                            this.props.data && this.props.data.height
                          }
                          field={"height"}
                          defaultValue={
                            this.props.data && this.props.data.height
                          }
                          className="creator-form"
                        />
                      </Col>
                      <Col sm={6}>
                        <TextField
                          label={"Width"}
                          placeholderLabel={
                            this.props.data && this.props.data.width
                          }
                          field={"width"}
                          defaultValue={
                            this.props.data && this.props.data.width
                          }
                          className="creator-form"
                        />
                      </Col>
                    </>
                  )}
                  <Col sm={6}>
                    <Select
                      label="Collection Status"
                      placeholderLabel="Collection Status"
                      field="collection_status"
                      allowClear={true}
                      className="creator-form"
                      defaultValue={
                        this.props.data &&
                        (this.props.data.status ||
                          this.props.data.collection_status)
                      }
                      validations={["required"]}
                      errors={["This field is required"]}
                      options={[
                        { label: "Draft", value: "DRAFT" },
                        { label: "Published", value: "PUBLISHED" },
                        { label: "Disabled", value: "DISABLED" },
                      ]}
                    />
                  </Col>
                  <Col sm={6}>
                    <MultiSelect
                      label={"Languages"}
                      placeholderLabel="Languages"
                      field={"languages"}
                      className="creator-form"
                      defaultValue={
                        this.props.data &&
                        this.props.data.target &&
                        this.props.data.target.map(lng => lng.lang_code)
                      }
                      options={
                        this.props.languageList &&
                        this.props.languageList.map(lang => {
                          return {
                            label: lang.constantLabel,
                            value: lang.constantVal,
                          }
                        })
                      }
                      validations={["requiredArr"]}
                      errors={["This field is required"]}
                    />
                  </Col>
                  {this.props.locationList &&
                    this.props.locationList.length > 0 && (
                      <Col sm={6}>
                        <MultiSelect
                          label={"Location"}
                          placeholderLabel="Location"
                          field={"locations"}
                          className="creator-form"
                          defaultValue={
                            this.props.data &&
                            this.props.data.target &&
                            this._getDefaultLocations()
                          }
                          options={this._getLocations()}
                        />
                      </Col>
                    )}
                  <Col sm={6}>
                    <Select
                      label="CTA Type"
                      placeholderLabel="CTA Type"
                      field="cta_type"
                      allowClear={true}
                      className="creator-form"
                      defaultValue={
                        this.props.data &&
                        (this.props.data.cta_type ||
                          this.props.data.inline_cta_type)
                      }
                      options={[
                        { label: "None", value: "NONE" },
                        { label: "See All", value: "SEE_ALL" },
                        { label: "Follow", value: "FOLLOW" },
                        { label: "Open Gallery", value: "GALLERY" },
                      ]}
                    />
                  </Col>
                  <Col sm={6}>
                    <TextField
                      label={"CTA Text"}
                      placeholderLabel="CTA Text"
                      field={"cta_text"}
                      defaultValue={
                        this.props.data &&
                        (this.props.data.cta_text ||
                          this.props.data.inline_cta_text)
                      }
                      className="creator-form"
                    />
                  </Col>
                  <Col>
                    {elementType === "COMBINATION" && (
                      <Select
                        label="Tab Type"
                        placeholderLabel="Tab Type"
                        field="tab_type"
                        disabled
                        className="creator-form"
                        defaultValue={
                          this.props.data && this.props.data.tab_type
                        }
                        options={[
                          { value: "LOCAL_CLEAR", label: "Local Clear" },
                          { value: "LOCAL_RECENT", label: "Local Recent" },
                          { value: "REMOTE", label: "Remote" },
                        ]}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={this._onImageRemove}
                      defaultFileList={this.state.image}
                      fileList={this.state.imageList}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "image")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        <i className="fas fa-upload" /> Upload Icon File
                      </Button>
                    </Upload>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col sm={12}>
                <div
                  className="flex justify-content-end"
                  style={{ marginTop: "10px" }}
                >
                  <div className="d-flex justify-content-end">
                    <button htmlType="submit" className="btn btn-success">
                      {this.props.formAction === "update"
                        ? "Update"
                        : this.props.formAction === "create"
                        ? "Create"
                        : "Add"}
                    </button>
                    <Button
                      className="mx-2"
                      onClick={() => this.props.onCancel()}
                      color="danger"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    if (errors) {
      return
    }
    let data = new FormData()

    const {
      color_code,
      status_bar_color,
      search_bar_bg_color,
      search_bar_font_color,
      bottom_bar_bg,
      search_box_bg_color,
      start_date_ms,
      end_date_ms,
      ...rest
    } = formData
    const collectionId = formData.collection_uuid
    if (["add", "edit", "entityEdit"].includes(this.props.formAction)) {
      const payload = {
        ...rest,
        page_type: this.props.pageType,
        page_uuid: this.props.data.page_uuid,
        entity_uuid: this.props.data.entity_uuid || this.props.data.entityId,
        collection_uuid: this.props.data.collectionId,
        collection_format: this.props.data.collection_format,
        view_order:
          this.props.formAction === "add"
            ? this.props.data.view_order !== 0
              ? this.props.data.view_order + 1
              : 0
            : this.props.data.view_order,
      }
      data.append("payload", JSON.stringify(payload))
      if (this.state.image) {
        data.append("file1", this.state.image)
      }
    } else {
      if (this.state.backgroundImage) {
        data.append("file1", this.state.backgroundImage)
      }
      if (this.state.overlayImage) {
        data.append("file2", this.state.overlayImage)
      }

      if (this.props.formAction === "update") {
        data.append(
          "details",
          JSON.stringify({ ...rest, page_uuid: this.props.data.page_uuid })
        )
      }
      if (this.props.formAction === "create") {
        data.append(
          "details",
          JSON.stringify({
            ...rest,
            theme: _.deepClean({
              color_code,
              status_bar_color,
              search_bar_bg_color,
              search_bar_font_color,
              bottom_bar_bg,
              search_box_bg_color,
              start_date_ms: start_date_ms && start_date_ms.valueOf(),
              end_date_ms: end_date_ms && end_date_ms.valueOf(),
            }),
          })
        )
      }
    }
    this.props.onSubmit(data, this.props.formAction, collectionId)
  }

  _render = () => {
    console.log(this.props.data)
    return this._form()
  }
}
