import Axios from "axios"
import toastr from "toastr"

export const requestBadges = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/badge/entity/list?offset=${offset}&limit=${limit}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertBadge = async ({ payload }) => {
  try {
    const url = `/api/Badge/entity/upsert?action=${payload.action}`
    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertBadgeCollection = async ({ payload }) => {
  try {
    const url = `/api/Badge/collection/upsert?action=${payload.action}`
    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestReorderBadgeCollectionEntity = async ({ payload }) => {
  try {
    const url = `/api/Badge/collection/entity/reorder`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertBadgeCollectionEntity = async ({ payload }) => {
  try {
    const url = `/api/Badge/collection/entity`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestDeleteBadges = async ({ payload }) => {
  try {
    const url = `/api/badge/delete`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpgradeBadgeListVersion = async () => {
  try {
    let response = await Axios.get(`/api/Badge/upgradeVersion`)
    toastr.success("Version Updated Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
