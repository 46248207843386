import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import { LANGUAGES, CATEGORIES } from "../../constants/uiConstants"
import "react-datepicker/dist/react-datepicker.css"
import ReactSelect from "react-select"
import "../../containers/Zone/style.css"

const { Option } = Select

const formatOptionLabel = ({ value, title, zone_image }) => {
  return (
    <span>
      {zone_image ? (
        <img
          src={zone_image}
          alt={title}
          style={{ hight: "20px", width: "20px" }}
        />
      ) : (
        <i className="fa fa-users" aria-hidden="true"></i>
      )}{" "}
      &nbsp;
      <b>{title}</b>
    </span>
  )
}

const ZoneDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Zone Id</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["zoneId"]}
                  placeholder={"Zone Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "zoneId")
                  }
                  allowClear={true}
                  name="zoneId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Title</h5>
          </Divider>

          <ReactSelect
            value={
              props.allZoneList.filter(
                zone => zone?.title === props.filters["title"]
              )[0] || ""
            }
            style={{ width: "100%" }}
            isMulti={false}
            options={props.allZoneList}
            formatOptionLabel={formatOptionLabel}
            onChange={value => props.handleFilterChange(value.title, "title")}
            placeholder="select title..."
          />
        </Col>
        {!props.hideTypeFilter && (
          <>
            <Col lg="3" md="3">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Type</h5>
              </Divider>
              <ReactSelect
                // defaultInputValue={props.filters["type"]}
                value={
                  props.typeList.filter(
                    zone => zone?.zone_uuid === props.filters["type"]
                  )[0] || ""
                }
                style={{ width: "100%" }}
                isMulti={false}
                options={props.typeList}
                formatOptionLabel={formatOptionLabel}
                onChange={value =>
                  props.handleFilterChange(value.zone_uuid, "type")
                }
                placeholder="select type..."
              />
            </Col>
            <Col lg="3" md="3">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Sub-Type</h5>
              </Divider>
              <ReactSelect
                // defaultInputValue={props.filters["subType"]}
                value={
                  props.subTypeList.filter(
                    zone => zone?.zone_uuid === props.filters["subType"]
                  )[0] || ""
                }
                style={{ width: "100%" }}
                isMulti={false}
                options={props.subTypeList}
                formatOptionLabel={formatOptionLabel}
                onChange={value =>
                  props.handleFilterChange(value.zone_uuid, "subType")
                }
                placeholder="select sub-type..."
              />
            </Col>
          </>
        )}
        {/* <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Type</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["type"]}
                  placeholder={"Type"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "type")
                  }
                  allowClear={true}
                  name="type"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col> */}
        {/* <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Sub Title</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["subTitle"]}
                  placeholder={"Sub Title"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "subTitle")
                  }
                  allowClear={true}
                  name="subTitle"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col> */}

        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Language</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Language"
            defaultValue={props.filters["langCode"]}
            onChange={value => {
              props.handleFilterChange(value, "langCode")
            }}
            style={{ width: "100%" }}
          >
            {LANGUAGES.map(lang => (
              <Option value={lang.value}>{lang.label}</Option>
            ))}
          </Select>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Status</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["status"]}
            style={{ width: "100%" }}
            disabled={props.currentUser.jotUserType > 2}
            onChange={value => props.handleFilterChange(value, "status")}
          >
            <Option value="Draft">Draft</Option>
            <Option value="IN_REVIEW">In Review</Option>
            <Option value="Published">Published</Option>
            <Option value="Disabled">Disabled</Option>
          </Select>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Zone type</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["zoneType"]}
            style={{ width: "100%" }}
            disabled={props.currentUser.jotUserType > 2}
            onChange={value => props.handleFilterChange(value, "zoneType")}
          >
            <Option value="COLLECTION">Collection</Option>
            <Option value="PERSON">Person</Option>
            <Option value="DEITY">Deity</Option>
            <Option value="ASTRO">Astro</Option>
          </Select>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Allow Follow</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["allowFollow"]}
            style={{ width: "100%" }}
            disabled={props.currentUser.jotUserType > 2}
            onChange={value => props.handleFilterChange(value, "allowFollow")}
          >
            <Option value="true">True</Option>
            <Option value="false">False</Option>
          </Select>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Level</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters["level"]}
            style={{ width: "100%" }}
            disabled={props.currentUser.jotUserType > 2}
            onChange={value => props.handleFilterChange(value, "level")}
          >
            <Option value="0">0</Option>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(ZoneDrawer)
