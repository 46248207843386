import { DatePicker, Divider, Input, Select } from "antd"
import _ from "lodash"
import React from "react"
import "react-datepicker/dist/react-datepicker.css"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import dayjs from "dayjs"

const { Option } = Select

const JoshPaymentAuditDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Select
                  placeholder="Search Type"
                  style={{ width: "30%" }}
                  allowClear={true}
                  defaultValue={props.filters["searchType"]}
                  onChange={value =>
                    props.handleFilterChange(value, "searchType")
                  }
                >
                  <Option value="uuid">UUID</Option>
                  <Option value="moderator">Moderator</Option>
                </Select>
                {props.filters["searchType"] === "uuid" && (
                  <Input
                    placeholder="Id"
                    onChange={e =>
                      props.handleFilterChange(e.target.value, "searchText")
                    }
                    validations={["required"]}
                    allowClear
                    defaultValue={props.filters["searchText"] || ""}
                    style={{ width: "70%" }}
                    errors={["This field is required"]}
                  />
                )}
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Action</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Select
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.filters["action"]}
                  onChange={e => props.handleFilterChange(e, "action")}
                  placeholder="Action"
                >
                  <Option value="activate">Activate</Option>
                  <Option value="deactivate">DeActivate</Option>
                  <Option value="content_tag_target_category_v2">
                    Tag Category
                  </Option>
                  <Option value="tag_non_english">Tag Non English</Option>
                  <Option value="create_content">Create Content</Option>
                  <Option value="update_content">Update Content</Option>
                  <Option value="bulk_create_content">
                    Bulk Create Content
                  </Option>
                  <Option value="content_quality_check">
                    Quality Check Content
                  </Option>
                  <Option value="create_app_user">Create App User</Option>
                  <Option value="update_app_user">Update App User</Option>
                  <Option value="bulk_create_app_user">
                    Bulk Create App Users
                  </Option>
                  <Option value="tag_content">Tag Content</Option>
                </Select>
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="4" md="8" className="d-flex">
          <DatePicker
            defaultValue={
              props.filters["fromDate"]
                ? dayjs.unix(props.filters["fromDate"] / 1000, "YYYY-MM-DD")
                : null
            }
            format="YYYY-MM-DD HH:mm"
            style={{ width: "50%" }}
            showTime
            placeholder="From Date"
            onChange={(date, dateString) => {
              props.handleFilterChange(
                !_.isEmpty(dateString) ? moment(dateString).format("x") : null,
                "fromDate"
              )
            }}
          />
          <DatePicker
            defaultValue={
              props.filters["toDate"]
                ? dayjs.unix(props.filters["toDate"] / 1000, "YYYY-MM-DD")
                : null
            }
            format="YYYY-MM-DD HH:mm"
            showTime
            style={{ width: "50%" }}
            placeholder="To Date"
            onChange={(date, dateString) => {
              props.handleFilterChange(
                !_.isEmpty(dateString) ? moment(dateString).format("x") : null,
                "toDate"
              )
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(JoshPaymentAuditDrawer)
