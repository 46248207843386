import React from "react"
import { connect } from "react-redux"
import { uniq, cloneDeep } from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Divider } from "antd"
import ImageCard from "./imageCard"
import QuestionCard from "./questionCard"

import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"

class TargetLanguages extends ErrorBoundComponent {
  state = {
    currentTab: "targetLanguage",
    labelToDisplay: "Target Language",
  }

  _handleSelection = (value, question, idx, _, sub_type) => {
    const currentData = cloneDeep(this.props.currentData)
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)

    const indexTab = taxonomyRoutes.findIndex(
      item => item.key == "target_languages"
    )
    currentData.forEach(item => {
      if (item.key === question.key) {
        item.selected = true
        item.partialSelected = value
        item.value = value

        if (question.isMultiple) {
          const existingData =
            selectedDetails[question.taxonomyJsonKey][question.key]
          if (!existingData) {
            selectedDetails[question.taxonomyJsonKey][question.key] = [value]
            item.value = [value]
          } else {
            selectedDetails[question.taxonomyJsonKey][question.key] = uniq([
              ...existingData,
              value,
            ])
            item.value = uniq([...existingData, value])
          }
        } else {
          selectedDetails[question.taxonomyJsonKey][question.key] = value
          selectedDetails[question.taxonomyJsonKey]["sub_type"] = sub_type
        }
      }

      if (item.parent === question.key) {
        if (item.dependentValue === value) {
          item.isVisible = true
        } else {
          item.isVisible = false
          delete item.selected
          item.value = undefined
          item.partialSelected = undefined
          delete selectedDetails[question.taxonomyJsonKey[item.key]]
        }
      } else {
        if (item.level >= question.level && item.key !== question.key) {
          item.isVisible = false
          delete item.selected
          item.value = undefined
          delete selectedDetails[question.taxonomyJsonKey][item.key]
        }
      }
    })

    if (
      this.state.selectedKeys &&
      this.state.selectedKeys[0] === "GLAMOUR" &&
      this.props.selectedContent.auto_moderation_label === "nsfw" &&
      !this.state.ignore &&
      !this.state.tag &&
      !this.state.ignoreRatingLessThan4 &&
      !this.state.retag &&
      selectedDetails &&
      selectedDetails.taxonomy_glamour &&
      !selectedDetails.taxonomy_glamour.glamour_element &&
      this.props.currentUser &&
      !this.props.currentUser.permissions.includes("QC_TAG_CONTENT")
    ) {
      this.setState({
        noGlamourTagToNsfw: true,
        glamourClickHandle: {
          value,
          question,
          idx,
          _,
          sub_type,
          selectedKeys: ["GLAMOUR"],
        },
      })
      return
    }
    if (
      this.state.selectedKeys &&
      this.state.selectedKeys[0] === "GLAMOUR" &&
      this.props.selectedContent.auto_moderation_label === "nsfw" &&
      !this.state.ignore &&
      !this.state.tag &&
      !this.state.ignoreRatingLessThan4 &&
      !this.state.retag &&
      ["classy_hot_sexy", "pretty_girl", "glamorous"].includes(
        selectedDetails?.taxonomy_glamour?.glamour_type
      )
    ) {
      this.setState({
        glamourTagToNsfwLessThan4: true,
        glamourClickHandle: {
          value,
          question,
          idx,
          _,
          sub_type,
          selectedKeys: ["GLAMOUR"],
        },
      })
      return
    }

    let stepCompleted = true

    currentData.forEach(item => {
      if (item.isVisible && item.value == undefined) {
        stepCompleted = false
        taxonomyRoutes[indexTab].completed = false
      }
    })

    if (stepCompleted) {
      // this.props.setSelectedSection("zone")
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    }
    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )
    this.setState({
      currentData,
      ignore: false,
      tag: false,
      ignoreRatingLessThan4: false,
      retag: false,
    })

    if (stepCompleted) {
      taxonomyRoutes[indexTab].completed = true
    }

    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.setSelectedDetails(selectedDetails)
  }

  _renderCard = (question, key, idx) => {
    return (
      <>
        <div>
          <div>{question.label}</div>
          <div className="d-flex flex-wrap">
            {question.sublevel.map(data => {
              let selectedCard = ""
              if (question.isMultiple) {
                if (question.value?.includes(data.value)) {
                  selectedCard = "selectedCard"
                }
              } else {
                if (question.value === data.value) {
                  selectedCard = "selectedCard"
                }
              }
              return (
                <ImageCard
                  className={`question-card text-center contentCard ${selectedCard}`}
                  data={data}
                  _handleSelection={e =>
                    this._handleSelection(
                      data.value,
                      question,
                      key,
                      question.key,
                      data.sub_type
                    )
                  }
                  renderCardContent={this.props.renderCardContent}
                />
              )
            })}
          </div>
        </div>
        <Divider />
      </>
    )
  }

  _renderStepper = () => {
    let text = "Select All"
    this.props.currentData.forEach(item => {
      if (item.level > 0) {
        text = "Questionnaire"
      }
    })

    return (
      <div style={{ margin: "1rem", fontWeight: "bold" }}>
        {this.state.labelToDisplay}&nbsp;({text})
      </div>
    )
  }

  _renderQuestions = () => {
    let data = this.props.currentData.filter(item => {
      return item.isVisible
    })
    return (
      <PerfectScrollbar>
        <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
          {data.map((question, idx) => {
            if (question.type === "card") {
              return this._renderCard(question, idx)
            }
            const options =
              question.sublevel &&
              question.sublevel.map(data => {
                return {
                  label: data.label,
                  value: data.value,
                }
              })
            return (
              <QuestionCard
                question={question}
                _handleSelection={e =>
                  this._handleSelection(e.target.value, question, idx)
                }
                renderCardContent={this.props.renderCardContent}
              />
            )
          })}
        </div>
      </PerfectScrollbar>
    )
  }
  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderStepper()}
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "content.loading"),
    currentData: _.get(store, "taxonomy.targetLanguage"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(TargetLanguages)
