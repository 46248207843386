import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import {
  Select as SelectOption,
  Tooltip,
  Pagination,
  Radio,
  Tabs,
  Tag,
} from "antd"
import { Col, Row, Input, Card, CardBody, CardTitle } from "reactstrap"
import { Button } from "@mui/material"
import ValidatorForm, {
  Select,
  TextField,
  Switch,
  MultiSelect,
} from "../ValidatorForm"
import toastr from "toastr"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { getLocationData } from "../../appRedux/slices/location"
import {
  orderCollectionEntities,
  deleteCollectionEntity,
} from "../../../src/clientServices/zeroSearchService"
import {
  getCollectionEntitiesGroups,
  upsertCollectionEntity,
  resetState,
} from "../../appRedux/slices/zeroSearch"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import { collectionElements } from "../../constants/uiConstants"
import { LANGUAGES } from "../../constants/uiConstants"
import CollectionEntityCard from "../../components/Discovery/collectionEntityCard"
import ChallengeCard from "../Challenge/challengeCard"
import UserCard from "../../components/UserList/userCard"
import "../Comment/browseComment.style.css"
import "../Discovery/styles.css"
import HashtagCard from "../Hashtag/hashtagCard"
import { getAllZones } from "appRedux/slices/zone"
import { formatNumber } from "../../util/common"

const { TabPane } = Tabs

const COLLECTION_PROPERTIES = {
  CHALLENGE: {
    cardClass: "challenge-card-container",
    name: "Challenge",
    icon: "bx bx-trophy",
  },

  CREATOR: {
    cardClass: "user-card-container",
    name: "Creator",
    icon: "bx bx-user",
  },
  CONTEST: {
    cardClass: "contest-card-container",
    name: "Contest",
    icon: "fas fa-trophy",
  },
  HASHTAG: {
    cardClass: "hashtag-card-container",
    name: "Hashtag",
    icon: "bx bx-hash",
  },
  MUSIC_ARTIST: {
    cardClass: "discovery-card-container",
    icon: "bx bx-microphone",
    name: "Music Artist",
  },
  MUSIC_LABEL: {
    cardClass: "discovery-card-container",
    icon: "bx bx-play-circle",
    name: "Music Label",
  },
  MUSIC_PLAYLIST: {
    cardClass: "discovery-card-container",
    icon: "bx bxs-playlist",
    name: "Music Playlist",
  },
  MUSIC: {
    cardClass: "discovery-card-container",
    name: "Music",
    icon: "bx bx-music",
  },
  STICKER: {
    cardClass: "discovery-card-container",
    name: "Sticker",
    icon: "bx bx-sticker",
  },
  EFFECT: {
    cardClass: "discovery-card-container",
    name: "Effect",
    icon: "bx bxs-magic-wand",
  },
  GAME: {
    cardClass: "discovery-card-container",
    name: "Game",
    icon: "bx bx-bot",
  },
  TEMPLATE: {
    cardClass: "discovery-card-container",
    name: "Template",
    icon: "bx bx-table",
  },
  ZONE: {
    cardClass: "discovery-card-container",
    name: "Zone",
    icon: "bx bx-radio-circle",
  },
  NAMED_COLLECTION: {
    cardClass: "discovery-card-container",
    name: "Named Collection",
    icon: "bx bx-collection",
  },
}

class ManageCollectionEntities extends ErrorBoundComponent {
  state = {
    showDeleteEntityPopup: false,
    elementTypes: [],
    value: 1,
    filters: {
      title:
        this.props.queryParams && this.props.queryParams["title"]
          ? this.props.queryParams["title"]
          : "",
      collectionType:
        this.props.queryParams && this.props.queryParams["collectionType"]
          ? this.props.queryParams["collectionType"]
          : "CHALLENGE",
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 8,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
    },
    title:
      this.props.queryParams && this.props.queryParams["title"]
        ? this.props.queryParams["title"]
        : "",
    searchedCollectionType:
      this.props.queryParams && this.props.queryParams["collectionType"]
        ? this.props.queryParams["collectionType"]
        : "CHALLENGE",
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getCollectionEntitiesGroups(_.cloneDeep(this.state.filters))
      )
      this.props.dispatch(getLocationData())
      this.props.dispatch(getAllZones({ currentPage: 1, pageSize: 10000 }))
    }
  }

  componentDidUpdate() {
    if (!this.props.common.error) {
      if (this.props.refetchGroups) {
        this.props.dispatch(
          getCollectionEntitiesGroups(_.cloneDeep(this.state.filters))
        )
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _searchEntities = () => {
    this.props.history.push(
      `/manage/collectionEntities?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(
      getCollectionEntitiesGroups(_.cloneDeep(this.state.filters))
    )
    this.setState({
      searchedCollectionType: this.state.filters.collectionType,
      title: this.state.filters.title,
    })
  }

  _handleCollectionSelect = collectionType => {
    const elementTypes = collectionElements[collectionType]
    this.setState({
      collectionType,
      elementTypes,
      elementType: elementTypes[0].value,
    })
  }

  _upsertCollection = async (data, type) => {
    let response
    switch (type) {
      case "reorder":
        response = await orderCollectionEntities(data, this.state.collectionId)
        break
      case "delete":
        response = await this.props.dispatch(deleteCollectionEntity(data))
        break
      case "add":
        response = await this.props.dispatch(upsertCollectionEntity(data))
        break
      default:
        return
    }
    this.setState({ showModal: false, showAddCollectionModal: false })
    this._searchEntities()
  }

  _onAdd = (entity, action) => {
    this.props.getCollectionEntities(entity)
    let type
    let element
    switch (entity.elementType) {
      case "CREATOR":
        type = "CREATOR"
        break
      case "VIDEO":
        type = "VIDEO"
        break
      case "MUSIC":
        type = "MUSIC"
        break
      case "MUSIC_ARTIST":
        type = "MUSIC_ARTIST"
        element = "music_artist_uuid"
        break
      case "MUSIC_PLAYLIST":
        type = "MUSIC_PLAYLIST"
        element = "music_playlist_uuid"
        break
      case "BANNER":
        type = "BANNER"
        element = "banner_uuid"
        break
      case "SUBBANNER":
        type = "SUBBANNER"
        element = "banner_uuid"
        break
      case "MUSIC_LABEL":
        type = "MUSIC_LABEL"
        element = "music_label_uuid"
        break
    }
    this.setState({
      showSeachAndSelectModal: true,
      type,
      entity,
      element,
      action,
    })
  }

  _getLocations = () => {
    const locationArray =
      this.props.locationList &&
      this.props.locationList.length > 0 &&
      this.props.locationList.reduce((acc, location) => {
        let cityArray = []
        if (location.children && location.children.length > 0) {
          cityArray = location.children.map(city => {
            return { label: city.name, value: city.dhTagId }
          })
        }
        return [
          ...acc,
          ...cityArray,
          { label: location.name, value: location.dhTagId },
        ]
      }, [])
    return locationArray
  }

  _renderCollectionEntitiesModal = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>Add Collection Entity</CardTitle>
          <ValidatorForm
            onSubmit={this._createCollectionEntities}
            layout={"vertical"}
            {...this._formLayout}
          >
            {this.props.formAction !== "add" && (
              <Row>
                <Col sm="12" md="6">
                  <Select
                    label="Collection Type"
                    placeholderLabel="Collection Type"
                    field="collection_type"
                    allowClear={true}
                    className="creator-form"
                    disabled={true}
                    onChange={value => this._handleCollectionSelect(value)}
                    defaultValue={
                      this.state.filters && this.state.filters.collectionType
                    }
                    options={[
                      { label: "Banner", value: "BANNER" },
                      { label: "Hashtag", value: "HASHTAG" },
                      { label: "Creator", value: "CREATOR" },
                      { label: "Challenge", value: "CHALLENGE" },
                      { label: "Contest", value: "CONTEST" },
                      { label: "Music Artist", value: "MUSIC_ARTIST" },
                      { label: "Music Playlist", value: "MUSIC_PLAYLIST" },
                      { label: "Music Label", value: "MUSIC_LABEL" },
                      { label: "Music", value: "MUSIC" },
                      { label: "Sticker", value: "STICKER" },
                      { label: "Effect", value: "EFFECT" },
                      { label: "Template", value: "TEMPLATE" },
                      { label: "Game", value: "GAME" },
                      { label: "zone", value: "ZONE" },
                      { label: "Named Collection", value: "NAMED_COLLECTION" },
                    ]}
                  />
                </Col>
                <Col sm="12" md="6">
                  <Row>
                    <Col sm="6" md="9">
                      <TextField
                        style={{ width: "95%" }}
                        label={"Collection ID"}
                        placeholderLabel="Select Collection"
                        field={"collection_uuid"}
                        className="creator-form"
                        defaultValue={this.state.collectionId}
                        validations={["required"]}
                        errors={["This field is required"]}
                        onChange={e => this.setState({ collectionId: e })}
                      />
                    </Col>
                    <Col
                      sm="6"
                      md="3"
                      style={{
                        display: "flex",
                        alignSelf: "flex-end",
                        marginBottom: "9px",
                      }}
                    >
                      <CopyToClipboard
                        text={this.state.collectionId}
                        onCopy={() => {
                          toastr.success("Copied sucessfully!")
                        }}
                      >
                        <Tooltip title="Copy Collection ID">
                          <Button
                            color="primary"
                            onClick={() => {}}
                            className="mx-1"
                            variant="contained"
                          >
                            <i className="fa fa-copy" />
                          </Button>
                        </Tooltip>
                      </CopyToClipboard>
                      <Button
                        color="primary"
                        onClick={() =>
                          this.setState({ selectCollectionModal: true })
                        }
                        variant="contained"
                      >
                        Select
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col sm="12" md="6">
                  <Select
                    label="Element Type"
                    placeholderLabel="Element Type"
                    field="element_type"
                    allowClear={true}
                    className="creator-form"
                    defaultValue={this.state.elementTypes[0].value}
                    onChange={value => this.setState({ elementType: value })}
                    options={this.state.elementTypes}
                  />
                </Col>
                <Col sm="12" md="6">
                  <Row>
                    <Col sm="6" md="9">
                      <TextField
                        label="Entities"
                        placeholderLabel="Select entities"
                        field="entities"
                        className="creator-form"
                        defaultValue={this.state.entities}
                        style={{ width: "95%" }}
                        validations={["required"]}
                        errors={["This field is required"]}
                      />
                    </Col>
                    <Col
                      sm="6"
                      md="3"
                      style={{ alignSelf: "flex-end", marginBottom: "9px" }}
                    >
                      <Button
                        color="primary"
                        onClick={() =>
                          this.setState({
                            selectElementsModal: true,
                            type: this.state.elementType,
                          })
                        }
                        variant="contained"
                      >
                        Select
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={6}>
                  <Select
                    label="Collection Format (V2)"
                    placeholderLabel="Collection Format"
                    field="collection_format"
                    allowClear={true}
                    className="creator-form"
                    defaultValue=""
                    options={collectionElements.COLLECTION_FORMAT}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TextField
                    label="Elements count"
                    placeholderLabel="Number of elements to fetch"
                    field="elements_count"
                    className="creator-form"
                    defaultValue={this.state.elements_count}
                    style={{ width: "95%" }}
                    inputType="Number"
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TextField
                    label="Collection query"
                    placeholderLabel="Collection query"
                    field="collection_query"
                    className="creator-form"
                    defaultValue={this.state.collection_query}
                  />
                </Col>
                <Col sm="12" md="6">
                  <MultiSelect
                    label={"Languages"}
                    placeholderLabel="Languages"
                    field={"languages"}
                    className="creator-form"
                    defaultValue=""
                    options={LANGUAGES.map(lang => {
                      return { label: lang.label, value: lang.value }
                    })}
                    validations={["requiredArr"]}
                    errors={["This field is required"]}
                  />
                </Col>
                <Col sm="12" md="6">
                  <MultiSelect
                    label={"Location"}
                    placeholderLabel="Location"
                    field={"locations"}
                    className="creator-form"
                    defaultValue=""
                    options={this._getLocations()}
                  />
                </Col>
                <Col sm={12} lg={2}>
                  <Switch
                    label={"Is Editable"}
                    field={"is_editable"}
                    defaultValue=""
                  />
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              <Col span={24}>
                <div className="flex justify-content-end">
                  <Button type="submit" color="primary" variant="contained">
                    <i className="fa fa-plus mx-1 my-1" />
                    Add
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    className="mx-2"
                    onClick={() =>
                      this.setState({
                        showAddCollectionModal: false,
                        elementTypes: [],
                        collectionId: null,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _createCollectionEntities = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data = formData
    if (data.entities && typeof data.entities === "string") {
      data.entities = data.entities.split(",")
    }
    this.setState({ showModal: false })
    this._upsertCollection(data, "add")
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState(
      { filters: newFilters, searchedCollectionType: value },
      () => {
        this.props.history.push(
          `/manage/collectionEntities?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getCollectionEntitiesGroups(_.cloneDeep(this.state.filters))
        )
      }
    )
  }

  onChange = e => {
    this.setState({ value: e.target.value })
  }

  _filters = () => {
    return (
      <>
        <Row
          style={{ margin: "0px 0px 10px 0px" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <div className="d-flex justify-content-lg-end px-0">
            <Col xs={12} sm={12} md={3}>
              <Input
                defaultValue={this.state.filters["title"]}
                placeholder={"Search By Title / Id"}
                onChange={e => {
                  const newFilters = _.cloneDeep(this.state.filters)
                  newFilters["title"] = e.target.value
                  this.setState({ filters: newFilters })
                }}
                allowClear={true}
                name="title"
                style={{ width: "100%" }}
              />
            </Col>
            <Button
              color="primary"
              onClick={() => {
                this._searchEntities()
              }}
              variant="contained"
              className="mx-2"
            >
              <i className="fa fa-search mx-1" /> Search
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this._handleCollectionSelect(this.state.filters.collectionType)
                this.setState({ showAddCollectionModal: true })
              }}
              variant="contained"
            >
              <i className="fa fa-plus mx-1 my-1" />
              Add
            </Button>
          </div>
        </Row>
      </>
    )
  }

  _getClassname = () => {
    switch (this.state.searchedCollectionType) {
      case "CHALLENGE":
        return "challenge-card-container"
      case "CREATOR":
        return "user-card-container"
      case "HASHTAG":
        return "hashtag-card-container"
      default:
        return "discovery-card-container"
    }
  }

  _onEdit = entity => {
    let collectionId = entity.collectionId
    let collectionType = entity.collectionType
    let elementType = entity.elementType
    let type = entity.type
    let collectionFormat = entity.collectionFormat

    this.props.history.push(
      `/manage/collection/entities/list?${QueryString.stringify({
        collectionId,
        collectionType,
        elementType,
        type,
        collectionFormat,
      })}`
    )
  }

  _renderCards = collection => {
    switch (collection.collectionType) {
      case "CHALLENGE":
        return (
          <ChallengeCard
            challenge={collection}
            currentUser={this.props.currentUser}
            onEditClick={this._onEdit}
            pageSource="manageCollection"
          />
        )
      case "CREATOR":
        return (
          <div className="user-card">
            <UserCard
              user={collection}
              currentUser={this.props.currentUser}
              pageSource="manageCollection"
              onEditClick={this._onEdit}
            />
          </div>
        )
      case "HASHTAG":
        return (
          <HashtagCard
            data={collection}
            currentUser={this.props.currentUser}
            pageSource="manageCollection"
            onEditClick={this._onEdit}
          />
        )
      default:
        return (
          <CollectionEntityCard data={collection} onEditClick={this._onEdit} />
        )
    }
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/manage/collectionEntities?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        ),
          this.props.dispatch(
            getCollectionEntitiesGroups(_.cloneDeep(this.state.filters))
          )
      }
    )
  }

  _getFilteredCollectionList = (type, title, collectionList) => {
    let filteredList = []
    let filtertTitle = title.toLowerCase().trim()
    switch (type) {
      case "CHALLENGE":
        filteredList = collectionList.filter(collection => {
          if (collection.hashtag || collection.collectionId) {
            return (
              collection.hashtag?.toLowerCase()?.includes(filtertTitle) ||
              collection.collectionId === title?.trim()
            )
          } else return
        })
        break
      case "HASHTAG":
        filteredList = collectionList.filter(collection => {
          if (collection.collectionId || collection.collectionId) {
            return (
              collection.collectionId?.toLowerCase()?.includes(filtertTitle) ||
              collection.collectionId === title?.trim()
            )
          } else return
        })
        break
      case "CREATOR":
        filteredList = collectionList.filter(collection => {
          if (collection.name || collection.collectionId) {
            return (
              collection.name?.toLowerCase()?.includes(filtertTitle) ||
              collection.collectionId === title?.trim()
            )
          } else return
        })
        break
      case "NAMED_COLLECTION":
        filteredList = collectionList.filter(collection => {
          if (collection.title || collection.collectionId) {
            return (
              collection.title?.toLowerCase()?.includes(filtertTitle) ||
              collection.collectionId === title?.trim()
            )
          } else return
        })
        break
      default:
        filteredList = collectionList.filter(collection => {
          if (collection.title || collection.collectionId) {
            return (
              collection.title?.toLowerCase()?.includes(filtertTitle) ||
              collection.collectionId === title?.trim()
            )
          } else return
        })
        break
    }
    return filteredList
  }

  _getFilteredList = () => {
    const sortedList = _.sortBy(this.props.collectionEntitiesGroupsList, [
      "created_date",
    ]).reverse()
    const filteredCollectionList = this.state.title
      ? this._getFilteredCollectionList(
          this.state.filters.collectionType,
          this.state.title,
          sortedList
        )
      : sortedList
    return filteredCollectionList
  }

  render() {
    const filteredCollectionList = this._getFilteredList()
    const collectionEntityList = _(filteredCollectionList)
      .drop((this.state.filters.currentPage - 1) * this.state.filters.pageSize)
      .take(this.state.filters.pageSize)
      .value()
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showAddCollectionModal && (
          <React.Fragment>
            <Tabs
              onChange={key => {
                this.setState(
                  {
                    activeTab: key,
                  },
                  this._handleFilterChange(key, "collectionType")
                )
              }}
              type="card"
              activeKey={this.state.filters.collectionType}
            >
              {Object.keys(COLLECTION_PROPERTIES).map(key => {
                return (
                  <TabPane
                    key={key}
                    tab={
                      <span>
                        <span>
                          <i
                            className={`${COLLECTION_PROPERTIES[key].icon} mx-2`}
                          />
                        </span>
                        <span className="mb-2">
                          {COLLECTION_PROPERTIES[key].name}
                        </span>
                      </span>
                    }
                    className="font-size-15"
                  >
                    {this._filters()}
                    {collectionEntityList &&
                      collectionEntityList.length > 0 && (
                        <div
                          style={{ textAlign: "center", paddingBottom: "10px" }}
                        >
                          <Tag color="geekblue">
                            Showing <b>{collectionEntityList.length}</b>{" "}
                            of&nbsp;
                            <b>{formatNumber(this.props.total)}</b> Collections
                          </Tag>
                        </div>
                      )}
                    <div className={this._getClassname()}>
                      {collectionEntityList &&
                        collectionEntityList.length > 0 &&
                        collectionEntityList.map((collection, i) =>
                          this._renderCards(collection, i)
                        )}
                    </div>
                    <div className="d-flex justify-content-end pb-3">
                      <Pagination
                        style={{ marginTop: "1rem" }}
                        onChange={this._onPageChange}
                        defaultCurrent={this.state.filters.currentPage}
                        total={
                          this._getFilteredList().length < 10000
                            ? this._getFilteredList().length
                            : 10000
                        }
                        pageSize={this.state.filters.pageSize}
                        showSizeChanger={false}
                      />
                    </div>
                  </TabPane>
                )
              })}
            </Tabs>
          </React.Fragment>
        )}
        {this.state.showAddCollectionModal &&
          this._renderCollectionEntitiesModal()}
        {this.state.selectElementsModal && this.state.elementType && (
          <SearchAndSelect
            type={this.state.elementType}
            visible={this.state.selectElementsModal}
            onClose={() => this.setState({ selectElementsModal: false })}
            onAdd={arr => this.setState({ entities: arr })}
            zoneId={
              this.state.collectionType === "ZONE"
                ? this.state.collectionId
                : undefined
            }
            allZoneList={this.props.allZoneList.map(zone => {
              return {
                ...zone,
                value: zone.zone_uuid,
                label: zone.title || zone.sub_title,
              }
            })}
          />
        )}
        {this.state.selectCollectionModal && this.state.collectionType && (
          <SearchAndSelect
            type={this.state.collectionType}
            visible={this.state.selectCollectionModal}
            onClose={() => this.setState({ selectCollectionModal: false })}
            onAdd={arr => this.setState({ collectionId: arr[0] })}
            selectOne
          />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    currentUser: _.get(store, "currentUser.currentUser.data"),
    total: _.get(store, "zeroSearch.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "zeroSearch.loading"),
    collectionEntitiesGroupsList: _.get(
      store,
      "zeroSearch.collectionEntitiesGroups"
    ),
    collectionEntities: _.get(store, "zeroSearch.collectionEntities"),
    collectionEntityLoading: _.get(store, "zeroSearch.collectionEntityLoading"),
    refetchGroups: _.get(store, "zeroSearch.refetchGroups"),
    locationList: _.get(store, "locationReducer.locationList"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(ManageCollectionEntities)
