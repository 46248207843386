import React from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Select, Divider } from "antd"
import { Col, Row } from "reactstrap"

const { Option } = Select

const ContestDrawer = props => {
  return (
    <React.Fragment>
      <Row gutter={12}>
        <Col lg="5">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              placeholder="Search Type"
              style={{ width: "35%" }}
              allowClear={true}
              size="default"
              defaultValue={props.filters["searchType"]}
              onChange={value => props.handleFilterChange(value, "searchType")}
            >
              <Option value="hashtag">Hashtag</Option>
              <Option value="contestId">Contest Id</Option>
            </Select>
            <Input
              placeholder="Search Text"
              allowClear={true}
              style={{ width: "65%" }}
              onChange={e =>
                props.handleFilterChange(e.target.value, "searchText")
              }
              validations={["required"]}
              defaultValue={props.filters.searchText}
              errors={["This field is required"]}
            />
          </Input.Group>
        </Col>
        <Col>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Contest Type</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters.type}
            placeholder="Contest Type"
            style={{ width: "100%" }}
            onChange={value => props.handleFilterChange(value, "type")}
          >
            <Option value="">All</Option>
            <Option value="type1">Contest</Option>
            <Option value="type2">Contest With Only Categories</Option>
            <Option value="type3">Contest With Only Sub Categories</Option>
            <Option value="type4">
              Contest With Categories and Sub Categories
            </Option>
          </Select>
        </Col>
        <Col>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Sponsered</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters.sponsored}
            style={{ width: "100%" }}
            disabled={props.currentUserType > 2}
            onChange={value => props.handleFilterChange(value, "sponsored")}
          >
            <Option value="">All</Option>
            <Option value="Y">Ads</Option>
            <Option value="N">Non Ads</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(ContestDrawer)
