import React from "react"
import { withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider, Checkbox } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { LANGUAGES } from "../../constants/uiConstants"
const { Option } = Select
import dayjs from "dayjs"
const TaxonomyDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Content Id</h5>
          </Divider>
          <Col>
            <Input
              defaultValue={props.filters["contentUUID"]}
              placeholder={"Content ID"}
              onChange={e =>
                props.handleFilterChange(e.target.value, "contentUUID")
              }
              allowClear={true}
              name="searchId"
            />
          </Col>
        </Col>
        {props.currentUser.jotUserType < 4 && (
          <Col lg="6" md="8">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Desk</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex">
                  <Select
                    placeholder="Desk Type"
                    style={{ width: "100%" }}
                    allowClear={true}
                    defaultValue={props.filters["deskType"]}
                    onChange={value =>
                      props.handleFilterChange(value, "deskType")
                    }
                  >
                    <Option value="">All</Option>
                    <Option value="LANGUAGE_DESK">Language Desk</Option>
                    <Option value="QC_DESK">QC Desk</Option>
                    <Option value="INTERNAL_QC_DESK">Internal QC Desk</Option>
                  </Select>
                  <Select
                    showSearch
                    allowClear={true}
                    placeholder="Desk"
                    defaultValue={props.filters["deskId"]}
                    onChange={e => props.handleFilterChange(e, "deskId")}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: "100%" }}
                  >
                    {props.deskOptions.map(desk => (
                      <Option value={desk.taskUserUuid}>{desk.name}</Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Input.Group>
          </Col>
        )}
        <Col lg="3" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Status</h5>
          </Divider>
          <Col>
            <Select
              allowClear={true}
              placeholder="Status"
              defaultValue={props.filters["status"] || ""}
              onChange={value => {
                props.handleFilterChange(value, "status")
              }}
              style={{ width: "100%" }}
            >
              <Option value="">All Tasks</Option>
              <Option value="CREATED">Pending</Option>
              <Option value="COMPLETED">Completed</Option>
            </Select>
          </Col>
        </Col>
      </Row>
      {props.currentUser.jotUserType < 4 && (
        <div className="d-flex justify-content-center">
          <div onClick={props.toggleAdvancedFilters} role="button">
            {props.showAdvancedFilters ? (
              <i
                className="fas fa-angle-double-up
  font-size-16 align-middle me-2"
              ></i>
            ) : (
              <i
                className="fas fa-angle-double-down
   font-size-16 align-middle me-2"
              ></i>
            )}
            {props.showAdvancedFilters ? "Hide" : "Show"} Advanced Filters
          </div>
        </div>
      )}

      {props.showAdvancedFilters && (
        <Row style={{ marginBottom: "10px" }}>
          <Col lg="4" md="8">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Order By</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex">
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["orderByField"]}
                    placeholder="Order By"
                    onChange={value => {
                      props.handleFilterChange(value, "orderByField")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="view_count">View Count</Option>
                    <Option value="created_date">Created Date</Option>
                  </Select>
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["order"]}
                    placeholder="Order"
                    onChange={value => {
                      props.handleFilterChange(value, "order")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="asc">Ascending</Option>
                    <Option value="desc">Descending</Option>
                  </Select>
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col lg="2" md="4">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Language</h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Language"
              defaultValue={props.filters["langCode"]}
              onChange={value => {
                props.handleFilterChange(value, "langCode")
              }}
              style={{ width: "100%" }}
            >
              <Option value="">All</Option>
              {LANGUAGES.map(lang => (
                <Option value={lang.value}>{lang.label}</Option>
              ))}
            </Select>
          </Col>
          <Col lg="2" md="4">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Bucket</h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Bucket"
              defaultValue={props.filters["bucket"]}
              onChange={value => {
                props.handleFilterChange(value, "bucket")
              }}
              style={{ width: "100%" }}
            >
              <Option value="-1">All</Option>
              {[...Array(100).keys()].map(value => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          </Col>
          <Col lg="4" md="8">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Created Date</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex">
                  <DatePicker
                    defaultValue={
                      props.filters["createdDateFrom"]
                        ? dayjs.unix(
                            props.filters["createdDateFrom"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="From Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "createdDateFrom"
                      )
                    }}
                    style={{ width: "100%" }}
                  />
                  <DatePicker
                    defaultValue={
                      props.filters["createdDateTo"]
                        ? dayjs.unix(
                            props.filters["createdDateTo"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="To Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "createdDateTo"
                      )
                    }}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col lg="4" md="8">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Completed Date</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex">
                  <DatePicker
                    defaultValue={
                      props.filters["taskCompletedDateFrom"]
                        ? dayjs.unix(
                            props.filters["taskCompletedDateFrom"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="From Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "taskCompletedDateFrom"
                      )
                    }}
                  />
                  <DatePicker
                    defaultValue={
                      props.filters["taskCompletedDateTo"]
                        ? dayjs.unix(
                            props.filters["taskCompletedDateTo"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="To Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "taskCompletedDateTo"
                      )
                    }}
                  />
                </Col>
              </Row>
            </Input.Group>
          </Col>
          {
            <Col lg="2" md="4">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Agency</h5>
              </Divider>
              <Select
                showSearch
                allowClear={true}
                placeholder="User"
                defaultValue={props.filters["agency"]}
                onChange={value => {
                  props.handleFilterChange(value, "agency")
                }}
                style={{ width: "100%" }}
              >
                <Option value="">All</Option>
                {props.agencyList.map(agency => (
                  <Option value={agency.key}>{agency.name}</Option>
                ))}
              </Select>
            </Col>
          }
          <Col lg="1" md="4">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Page Size</h5>
            </Divider>
            <Select
              allowClear={true}
              defaultValue={props.filters["pageSize"]}
              onChange={value => {
                props.handleFilterChange(value, "pageSize")
              }}
              style={{ width: "100%" }}
            >
              <Option value={10}>10</Option>
              <Option value={50}>50</Option>
              <Option value={100}>100</Option>
            </Select>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default withRouter(TaxonomyDrawer)
