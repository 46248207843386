import React from "react"
import { connect } from "react-redux"
import { message, Upload } from "antd"
import { Button, Row, Col } from "reactstrap"
import { PlusOutlined, UploadOutlined } from "@ant-design/icons"
import ValidatorForm, {
  TextField,
  Select,
  MultiSelect,
  Switch,
  DateTimePicker,
  ChipsInput,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { createContent } from "../../appRedux/slices/content"
import Categories from "../../constants/categories.json"
import Locations from "../../constants/locations.json"
import { getZones } from "../../appRedux/slices/zone"
import {
  LANGUAGES,
  CATEGORIES,
  NO_PERMISSION_MESSAGE,
  CONTENT_TYPE,
  agencyNames,
  deskTypeOptions,
} from "../../constants/uiConstants"
import toastr from "toastr"
import _ from "lodash"
import "./content.styles.css"

import { createLimit, getLimits, updateLimit } from "appRedux/slices/limit"

const { Option } = Select

class AgencyLimitCreateForm extends ErrorBoundComponent {
  state = {
    stateLocationList: [],
    cityLocationList: [],
    formatType: "VIDEO",
    active_zones: [],
    input_zones: [],
  }

  componentDidMount() {
    this.state.formData = this.props.agencyData
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col sm="12" lg="4">
            <Select
              label={"Agency"}
              placeholderLabel="Select a Agency"
              field="agency"
              defaultValue={this.props?.agencyData?.agency}
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              options={agencyNames}
            />
          </Col>

          <Col sm="12" lg="4">
            <Select
              label={"Type"}
              placeholderLabel="Select a Type"
              field="type"
              defaultValue={this.props?.agencyData?.type}
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              options={deskTypeOptions}
            />
          </Col>

          <Col sm="12" lg="4">
            <Select
              label={"Language"}
              placeholderLabel="Select a Language"
              field="language"
              defaultValue={this.props?.agencyData?.language}
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              options={LANGUAGES}
            />
          </Col>

          <Col sm="12" lg="4">
            <TextField
              label={"Daily Limit"}
              placeholderLabel="Daily Limit"
              defaultValue={this.props?.agencyData?.dailyLimit}
              field={"dailyLimit"}
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              inputType="number"
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm="12">
            <div className="d-flex justify-content-end">
              <Button
                htmlType="submit"
                className="font-16 btn-block btn btn-primary"
                color="primary"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                <i className="bx bx-plus me-1" />
                {this.props.formAction == "update" ? "Update" : "Create"}
              </Button>
              <Button
                color="danger"
                size="default"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    if (errors) {
      return
    }

    if (this.props.formAction == "update") {
      this.props.dispatch(
        updateLimit({ ...{ id: this.props.agencyData.id }, ...formData })
      )
    } else {
      this.props.dispatch(createLimit(formData))
    }
    this.props.onCancel()

    this.props.dispatch(getLimits())
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    loading: _.get(store, "content.createContentLoading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    locationList: _.get(store, "locationReducer.locationList"),
    zoneList: _.get(store, "zone.zoneList"),
    campaignList: _.get(store, "campaign.campaign"),
    totalCampaign: _.get(store, "campaign.totalCampaign"),
  }
}

export default connect(mapStateToProps)(AgencyLimitCreateForm)
