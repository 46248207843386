import React from "react"
import { connect } from "react-redux"
import { Button, Row, Col, CardImg, Card as ReactCard, Badge } from "reactstrap"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { LANGUAGE_MAPPING } from "constants/uiConstants"
import { Divider, Tooltip } from "antd"
import "../Comment/browseComment.style.css"
import { reorderMeme, refreshMeme } from "appRedux/slices/meme"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
class MemeReorderModel extends ErrorBoundComponent {
  state = {
    entityList: [],
  }

  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  _onDragEnd = result => {
    // dropped outside the list
    const prevEntityList = this.state?.entityList?.length
      ? this.state.entityList
      : this.props.data[this.props.activeTab]

    if (!result.destination) {
      return
    }

    const entityList = this._reorder(
      prevEntityList,
      result.source.index,
      result.destination.index
    )
    this.props.setReorderData(true)
    this.setState({
      entityList,
    })
  }

  _updateTabOrder = () => {
    const { entityList } = this.state
    const payload = {}
    const entities = []

    for (let i = 0; i < entityList.length; i++) {
      entities.push(entityList[i]["id"])
    }
    payload.entities = entities
    payload.lang_code = this.props.activeTab
    this.props.dispatch(reorderMeme({ payload }))
    this.props.dispatch(refreshMeme())
    this.props.onCancel()
  }

  render() {
    const entityList = this.state?.entityList?.length
      ? this.state.entityList
      : this.props.data[this.props.activeTab]
    return (
      <>
        <div style={{ padding: "20px", backgroundColor: "white" }}>
          <div className="d-flex justify-content-between">
            <Button
              color="primary"
              className="my-2"
              onClick={() => {
                this.props.dispatch(refreshMeme())
                this.props.onCancel()
              }}
            >
              <i className="fa fa-angle-left" /> Go Back
            </Button>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              {LANGUAGE_MAPPING[this.props.activeTab]}
            </h3>
            <Button
              color="primary"
              className="my-2"
              onClick={() => {
                this._updateTabOrder()
              }}
              disabled={!this.state.entityList.length}
            >
              <i className="fa fa-upload" /> Update
            </Button>
          </div>
          <Divider />
          <div bordered={true}>
            <DragDropContext onDragEnd={this._onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="mt-4"
                  >
                    {entityList.map((tab, index) => (
                      <Draggable
                        key={`${tab["id"]}`}
                        draggableId={`${tab["id"]}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Row>
                              <Col>
                                <ReactCard className="shadow-lg">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                      {tab.thumbnail_url ? (
                                        <CardImg
                                          top
                                          className="img-fluid"
                                          src={tab.thumbnail_url}
                                          alt="Meme"
                                          onClick={e => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                          }}
                                          style={{
                                            // padding: "0px 20px",
                                            // display: "flex",
                                            // alignItems: "center",
                                            width: "50px",
                                            height: "50px",
                                            margin: "10px 20px",
                                          }}
                                        />
                                      ) : (
                                        <i class="bx bx-gift bx-lg" />
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {tab.type && (
                                          <Tooltip title="Type">
                                            <Badge
                                              className={`${"badge-soft-primary"} ms-1 my-2 text-black me-1 font-size-16 text-truncate font-weight-semibold`}
                                            >
                                              {tab.type}
                                            </Badge>
                                          </Tooltip>
                                        )}
                                        {tab.target &&
                                          tab.target.length &&
                                          tab.target.map(
                                            ({ lang_code = "" } = {}) => (
                                              <Tooltip
                                                title="Language"
                                                style={{ minHeight: "5px" }}
                                              >
                                                <Badge
                                                  className={`${"badge-soft-success"} ms-1 my-2 text-black me-1 font-size-14 text-truncate`}
                                                >
                                                  {LANGUAGE_MAPPING[lang_code]}
                                                </Badge>
                                              </Tooltip>
                                            )
                                          )}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <CopyToClipboard
                                        text={tab?.id}
                                        onCopy={e => {
                                          toastr.success("Copied sucessfully!")
                                        }}
                                        className="me-2"
                                      >
                                        <Tooltip title="Copy Meme ID">
                                          <i
                                            className="bx bx-copy bx-sm"
                                            role="button"
                                          />
                                        </Tooltip>
                                      </CopyToClipboard>
                                      {tab?.download_url && (
                                        <CopyToClipboard
                                          text={tab?.download_url}
                                          onCopy={e => {
                                            toastr.success(
                                              "Copied sucessfully!"
                                            )
                                          }}
                                        >
                                          <Tooltip title="Copy Download Url">
                                            <i
                                              className="bx bx-copy-alt bx-sm mx-2"
                                              role="button"
                                            />
                                          </Tooltip>
                                        </CopyToClipboard>
                                      )}
                                    </div>
                                  </div>
                                </ReactCard>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "meme.loading"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(MemeReorderModel)
