import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { resetState, getActivityList } from "appRedux/slices/activity"
import Auxiliary from "../../util/Auxiliary"
import { Input, Select, DatePicker, List, Tooltip, Tag } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ContentList from "../../components/ContentList/contentList"
import "flatpickr/dist/themes/material_blue.css"
import { getCmsUserList } from "../../appRedux/slices/cmsUser"
import moment from "moment"
import toastr from "toastr"
import {
  Badge,
  Card,
  Col,
  Row,
  CardBody,
  InputGroup,
  FormGroup,
} from "reactstrap"
import "../Comment/browseComment.style.css"

import dayjs from "dayjs"

const { Option } = Select

class ViewActivity extends ErrorBoundComponent {
  state = {
    isClearable: true,
    filters: {
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : "",
      searchType:
        this.props.queryParams && this.props.queryParams["searchType"]
          ? this.props.queryParams["searchType"]
          : "uuid",
      action:
        this.props.queryParams && this.props.queryParams["action"]
          ? this.props.queryParams["action"]
          : undefined,
      fromDate: this.props.queryParams
        ? this.props.queryParams["fromDate"]
        : "",
      toDate: this.props.queryParams ? this.props.queryParams["toDate"] : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      if (this.props.queryParams) {
        this.props.dispatch(getActivityList(_.deepClean(this.state.filters)))
      }
      if (this.props.moderatorList && this.props.moderatorList.length === 0) {
        this.props.dispatch(getCmsUserList())
      }
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.common.error) {
      if (this.props.refetchData) {
        this.props.dispatch(getActivityList(_.deepClean(this.state.filters)))
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter") {
      return this._onSearch()
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    if (type === "searchText" && this.state.filters["searchType"] === "uuid") {
      newFilters[type] = value
    } else if (
      type === "searchType" ||
      type === "searchText" ||
      type === "action"
    ) {
      newFilters[type] = value
    } else {
      newFilters[type] = value
    }

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }
    this.setState({ filters: newFilters })
  }

  _searchUploadReport = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/activity/bulkuploadreport?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
  }

  _onSearch = () => {
    if (
      !this.state.filters.searchText &&
      _.isEmpty(this.state.filters.searchText)
    ) {
      toastr.info("Please provide the search text")
      return
    }
    this.props.history.push(
      `/activity?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
    this.props.dispatch(getActivityList(_.deepClean(this.state.filters)))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/activity/bulkuploadreport?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
    })
  }

  _closeFilters = () => {
    this._searchUploadReport()
  }

  actionOptions = [
    { value: "activate", label: "Activate" },
    { value: "deactivate", label: "DeActivate" },
    {
      value: "content_tag_target_category_v2",
      label: "Tag Category",
    },
    { value: "tag_non_english", label: "Tag Non English" },
    { value: "create_content", label: "Create Content" },
    { value: "update_content", label: "Update Content" },
    {
      value: "bulk_create_content",
      label: "Bulk Create Content",
    },
    {
      value: "content_quality_check",
      label: "Quality Check Content",
    },
    { value: "create_app_user", label: "Create App User" },
    { value: "update_app_user", label: "Update App User" },
    {
      value: "bulk_create_app_user",
      label: "Bulk Create App User",
    },
    { value: "tag_content", label: "Tag Content" },
    { value: "review_content", label: "Review Content" },
    { value: "remove_duplicate", label: "Remove Duplicate" },
  ]

  _filters = () => {
    return (
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row className="row gy-2 gx-3 align-items-center">
                <div className="col-md-5 col-lg-4">
                  {" "}
                  <FormGroup>
                    {" "}
                    <Input.Group compact={true}>
                      <Select
                        placeholder="Search Type"
                        style={{ width: "30%" }}
                        allowClear={true}
                        defaultValue={this.state.filters["searchType"]}
                        onChange={value =>
                          this._handleFilterChange(value, "searchType")
                        }
                      >
                        <Option value="uuid">UUID</Option>
                        <Option value="moderator">Moderator</Option>
                      </Select>

                      {this.state.filters["searchType"] &&
                        (this.state.filters["searchType"]["value"] === "uuid" ||
                          this.state.filters["searchType"] === "uuid") && (
                          <Input
                            placeholder="Id"
                            onChange={e =>
                              this._handleFilterChange(
                                e.target.value,
                                "searchText"
                              )
                            }
                            validations={["required"]}
                            allowClear
                            defaultValue={
                              this.state.filters["searchText"] || ""
                            }
                            style={{ width: "70%" }}
                            errors={["This field is required"]}
                          />
                        )}
                      {this.state.filters["searchType"] &&
                        (this.state.filters["searchType"]["value"] ===
                          "moderator" ||
                          this.state.filters["searchType"] === "moderator") && (
                          <Select
                            allowClear={true}
                            showSearch
                            defaultValue={
                              parseInt(this.state.filters["searchText"]) || ""
                            }
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .startsWith(input.toLowerCase())
                            }
                            onChange={e =>
                              this._handleFilterChange(e, "searchText")
                            }
                            placeholder="Moderator"
                            style={{ width: "70%" }}
                          >
                            {this.props.moderatorList.map(user => (
                              <Option value={user.userId}>{user.name}</Option>
                            ))}
                          </Select>
                        )}
                    </Input.Group>
                  </FormGroup>
                </div>
                <Col md="6" lg="2">
                  <Select
                    allowClear={true}
                    style={{ width: "100%" }}
                    defaultValue={this.state.filters["action"]}
                    onChange={e => this._handleFilterChange(e, "action")}
                    placeholder="Action"
                  >
                    <Option value="activate">Activate</Option>
                    <Option value="deactivate">DeActivate</Option>
                    <Option value="content_tag_target_category_v2">
                      Tag Category
                    </Option>
                    <Option value="tag_non_english">Tag Non English</Option>
                    <Option value="create_content">Create Content</Option>
                    <Option value="update_content">Update Content</Option>
                    <Option value="bulk_create_content">
                      Bulk Create Content
                    </Option>
                    <Option value="content_quality_check">
                      Quality Check Content
                    </Option>
                    <Option value="create_app_user">Create App User</Option>
                    <Option value="update_app_user">Update App User</Option>
                    <Option value="bulk_create_app_user">
                      Bulk Create App Users
                    </Option>
                    <Option value="tag_content">Tag Content</Option>
                    <Option value="review_content">Review Content</Option>
                  </Select>
                </Col>
                <div className="col-md-5 col-lg-4">
                  <FormGroup>
                    <InputGroup>
                      <DatePicker
                        defaultValue={
                          this.state.filters["fromDate"]
                            ? dayjs.unix(
                                this.state.filters["fromDate"] / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        style={{ width: "50%" }}
                        showTime
                        placeholder="From Date"
                        onChange={(date, dateString) => {
                          this._handleFilterChange(
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null,
                            "fromDate"
                          )
                        }}
                      />
                      <DatePicker
                        defaultValue={
                          this.state.filters["toDate"]
                            ? dayjs.unix(
                                this.state.filters["toDate"] / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        style={{ width: "50%" }}
                        placeholder="To Date"
                        onChange={(date, dateString) => {
                          this._handleFilterChange(
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null,
                            "toDate"
                          )
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
                <Col lg="2" className="d-flex justify-content-end">
                  <button
                    onClick={this._onSearch}
                    role="button"
                    className="btn btn-success d-flex align-items-center"
                  >
                    <div className="mx-2">Apply</div>
                    <span>
                      <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  _activityLogRenderer(activityLog) {
    return (
      <List.Item key={activityLog.recordId}>
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={18} lg={20} xl={21}>
            <Row style={{ width: "100%", cursor: "pointer" }}>
              <Col span={18}>
                <div className="amContentTitle">
                  <Tooltip title={activityLog.name}>
                    <div className="titleText"> {activityLog.name} </div>
                  </Tooltip>
                </div>
              </Col>
            </Row>
            <Row style={{ width: "100%", marginTop: 5 }}>
              <Col xs={24}>
                <Tooltip title="ACTION">
                  <Badge
                    className="badge-soft-primary ms-1 p-2"
                    style={{ maxWidth: "300px" }}
                    color={"#108ee9"}
                  >
                    {activityLog.action.toUpperCase()}
                  </Badge>
                </Tooltip>
                <Tooltip title="On">
                  <Badge
                    color="#2db7f5"
                    className="badge-soft-success ms-1 p-2"
                  >
                    {moment(activityLog.created_date).format(
                      "MMM DD YYYY, h:mm:ss a"
                    )}
                  </Badge>
                </Tooltip>
                {activityLog.action === "deactivate" &&
                  activityLog.meta_info &&
                  activityLog.meta_info.reject_reason && (
                    <Tooltip title="Reject Reason">
                      <Badge className="badge-soft-danger ms-1 p-2">
                        {activityLog.meta_info.reject_reason}
                      </Badge>
                    </Tooltip>
                  )}
                {activityLog.action === "block" && activityLog.meta_info && (
                  <Tooltip title="Reject Reason">
                    <Badge className="badge-soft-danger ms-1 p-2">
                      {activityLog.meta_info?.reject_reason}
                    </Badge>
                  </Tooltip>
                )}
                {activityLog.action === "content_tag_target_category_v2" &&
                  activityLog.meta_info &&
                  activityLog.meta_info.target_category_v2 && (
                    <Tooltip title="Target Category">
                      <Tag color="green" className="tagStyle">
                        {
                          TARGET_CATEGORY_V2.filter(
                            category =>
                              category.value ===
                              activityLog.meta_info.target_category_v2
                          ).map(cat => {
                            return cat.label
                          })[0]
                        }
                      </Tag>
                    </Tooltip>
                  )}
                {[
                  "create_app_user",
                  "update_app_user",
                  "bulk_create_app_user",
                  "update_app_user_type",
                  "update_app_user_curated_entities",
                ].includes(activityLog.action) && (
                  <CopyToClipboard
                    text={activityLog.action_on}
                    onCopy={() => {
                      toastr.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title={"User UUID: " + activityLog.action_on}>
                      <Badge className="badge-soft-info ms-1 p-2">
                        {activityLog.action_on}
                      </Badge>
                    </Tooltip>
                  </CopyToClipboard>
                )}
                {activityLog.action === "block" && (
                  <Tooltip title="Profile block reason">
                    <Badge
                      className="badge-soft-dark ms-1 p-2"
                      style={{ maxWidth: "300px" }}
                      color={"#65616D"}
                    >
                      {activityLog.description &&
                        activityLog.description.split("_").join(" ")}
                    </Badge>
                  </Tooltip>
                )}
                {activityLog["meta_info"] &&
                  activityLog["meta_info"]["auto_moderation_level"] && (
                    <Tooltip title="Moderation Status">
                      <Badge
                        className="badge-soft-dark ms-1 p-2"
                        style={{ maxWidth: "300px" }}
                        // color={"#65616D"}
                      >
                        {activityLog["meta_info"]["auto_moderation_level"]}
                      </Badge>
                    </Tooltip>
                  )}
              </Col>
            </Row>
          </Col>
        </Row>
      </List.Item>
    )
  }

  _activityLogList(activityLogData) {
    if (
      this.props.queryParams &&
      this.props.queryParams["searchType"] === "moderator" &&
      this.props.queryParams["action"] &&
      [
        "activate",
        "deactivate",
        "content_tag_target_category_v2",
        "tag_non_english",
        "create_content",
        "update_content",
        "bulk_create_content",
        "content_quality_check",
        "tag_content",
        "review_content",
      ].includes(this.props.queryParams["action"])
    ) {
      return (
        <ContentList
          contentList={activityLogData}
          isReProcessedModerationList={false}
          onPageChange={() => null}
          total={activityLogData.length}
          isMobile={false}
          currentUser={this.props.currentUser}
          currentPage={1}
          pageType="activity"
          pageSize={activityLogData.length}
          previewContent={false}
        />
      )
    } else {
      return (
        <List
          loading={this.props.loading}
          dataSource={activityLogData}
          renderItem={this._activityLogRenderer}
        />
      )
    }
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <div
          style={{
            minHeight:
              this.props.activityList &&
              this.props.activityList.activityLogData &&
              this.props.activityList.activityLogData.length > 0
                ? ""
                : "100vh",
          }}
        >
          {this._filters()}
        </div>
        {/* {!this.state.filters.toDate &&
          this.props.activityList.activityLogData != null &&
          (!this.state.filters.fromDate ||
            (this.state.filters.fromDate &&
              moment().diff(moment(this.state.filters.fromDate, "x"), "days") <=
                15)) && (
            // <div style={{ textAlign: "center" }}>
            //   <Badge color="geekblue">
            //     Showing for Last <b>15 days activity</b>, select date range
            //     above to search older history
            //   </Badge>
            // </div>

            <div className="text-center">
              <Tag color="geekblue">
                <span className="font-size-14 p-1">
                  Showing for Last <b>15 days activity</b>, select date range
                  above to search older history
                </span>
              </Tag>
            </div>
          )} */}
        {this.props.activityList &&
          this.props.activityList.activityLogData &&
          this.props.activityList.activityLogData.length > 0 &&
          this._activityLogList(this.props.activityList.activityLogData)}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "activityLog.loading")
      ? _.get(store, "activityLog.loading")
      : false,
    activityList: _.get(store, "activityLog"),
    moderatorList: _.get(store, "cmsUser.userList") || [],
    refetchData: _.get(store, "appUser.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    // queryParams: _.get(store, "routing.location.search") ? QueryString.parseUrl(_.get(store, "routing.location.search")).query : undefined,
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "activityLog.count"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    reportList: _.get(store, "activityLog.reportList"),
  }
}
export default connect(mapStateToProps)(withRouter(ViewActivity))
