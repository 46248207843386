import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import QueryString from "query-string"
import _ from "lodash"
import { Divider, Tabs, Col, Row, Switch } from "antd"
import { Button } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import {
  resetState,
  getPageEntitiesByLanguageLocations,
} from "appRedux/slices/zeroSearch"
import PageEntityCard from "./pageEntityCard"
import { getLocationData } from "../../appRedux/slices/location"
import { getLanguageData } from "appRedux/slices/uiConstants"
import DefaultReorder from "./defaultReorder"

const { TabPane } = Tabs

class ReorderPageEntities extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      showDefaultOrder: false,
      activeTab: undefined,
      hasEditPermission:
        this.props.currentUser &&
        this.props.currentUser.permissions &&
        this.props.currentUser.permissions.includes("EDIT_DISCOVERY_PAGE"),
    }
  }

  componentDidMount() {
    this.props.dispatch(getLocationData())
    this.props.dispatch(getLanguageData())
    if (this.props.queryParams && this.props.queryParams.pageId) {
      this.props.dispatch(
        getPageEntitiesByLanguageLocations({
          pageEntityId: this.props.queryParams.pageId,
        })
      )
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      this.props.dispatch(
        getPageEntitiesByLanguageLocations({
          pageEntityId: this.props.queryParams.pageId,
        })
      )
    }
  }

  _renderCards = languageMap => {
    let data = []
    Object.keys(this.props.pageEntities).forEach(key => {
      const a = key.split("_")[0]
      const b = this.state.activeTab || this.props.tabs[0]
      if (a === b) {
        data.push({ [key]: this.props.pageEntities[key] })
      }
    })
    return (
      <PageEntityCard
        locationList={this.props.locationList}
        languageMap={languageMap}
        data={data}
        pageId={this.props.queryParams.pageId}
        activeTab={this.state.activeTab || this.props.tabs[0]}
        hasEditPermission={this.state.hasEditPermission}
        {...this.props}
      />
    )
  }

  render() {
    const languageMap =
      this.props.languageList &&
      this.props.languageList.length > 0 &&
      this.props.languageList.reduce((acc, curr) => {
        return { ...acc, [curr.constantVal]: curr.constantLabel }
      }, {})
    let modifiedArr = []
    modifiedArr =
      this.props.tabs && this.props.tabs.length > 0
        ? [...this.props.tabs].sort()
        : []
    if (modifiedArr.indexOf("default") > -1) {
      modifiedArr.push(modifiedArr.splice(modifiedArr.indexOf("default"), 1)[0])
    }
    const newModifiedArr = ["default_sort", ...modifiedArr]
    return (
      <Auxiliary
        loading={this.props.loading && this.props.tabs.length !== 0}
        error={_.get(this.props, "common.error")}
      >
        <React.Fragment>
          <div className="d-flex justify-content-between">
            <Button
              color="primary"
              className="my-2"
              onClick={() =>
                this.props.history.push(
                  `/manage/pageEntities?pageId=${this.props.queryParams.pageId}`
                )
              }
            >
              <i className="fa fa-angle-left" /> Go Back
            </Button>
            <Button
              color="primary"
              className="my-2"
              onClick={() => {
                this.props.history.push(
                  `/reorder/pageEntities?pageId=${this.props.queryParams.pageId}`
                ),
                  this.props.dispatch(
                    getPageEntitiesByLanguageLocations({
                      pageEntityId: this.props.queryParams.pageId,
                    })
                  )
              }}
            >
              <i className="fa fa-undo" /> Refresh
            </Button>
          </div>
          {this.props.tabs &&
            this.props.tabs.length > 0 &&
            !this.state.showDefaultOrder && (
              <Tabs
                onChange={key => this.setState({ activeTab: key })}
                type="card"
                activeKey={
                  this.state.activeTab ||
                  (this.props.tabs &&
                    this.props.tabs.length > 0 &&
                    this.props.tabs[0])
                }
              >
                {newModifiedArr.map(tab => {
                  return (
                    <TabPane
                      tab={
                        tab === "default_sort"
                          ? "Default"
                          : (languageMap && languageMap[tab]) || "Location"
                      }
                      key={tab}
                    >
                      {tab === "default_sort" && (
                        <DefaultReorder
                          pageId={this.props.queryParams.pageId}
                          hasEditPermission={this.state.hasEditPermission}
                        />
                      )}
                      {!_.isEmpty(this.props.pageEntities) &&
                        this._renderCards(languageMap)}
                    </TabPane>
                  )
                })}
              </Tabs>
            )}
        </React.Fragment>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zeroSearch.totalEntities"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "zeroSearch.loadingEntities"),
    entityList: _.get(store, "zeroSearch.entities"),
    pageEntities: _.get(store, "zeroSearch.pageEntitiesByLanguageLocation"),
    tabs: _.get(store, "zeroSearch.tabs"),
    refetch: _.get(store, "zeroSearch.refetchData"),
    locationList: _.get(store, "locationReducer.locationList"),
    loadingPageEntities: _.get(store, "zeroSearch.pageEntityLoading"),
    languageList: _.get(store, "uiConstants.languageList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ReorderPageEntities)
