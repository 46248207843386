import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestTrendingEntityByFilters,
  requestTrendingSourceData,
  requestEntityUpsert,
  requestMarkTrending,
} from "appRedux/sagas/requests/trending"
import {
  getTrendingEntityByFilters,
  getTrendingEntityByFiltersSuccess,
  getTrendingSourceData,
  getTrendingSourceDataSuccess,
  entityUpsert,
  entityUpsertSuccess,
  markTrending,
  markTrendingSuccess,
} from "appRedux/slices/trending"
import toastr from "toastr"
import { fetchError } from "appRedux/slices/common"

function* handleGetTrendingEntityByFilters(params) {
  try {
    const data = yield call(requestTrendingEntityByFilters, params)
    yield put(getTrendingEntityByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetTrendingSourceData(params) {
  try {
    const data = yield call(requestTrendingSourceData, params)
    yield put(getTrendingSourceDataSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleEntityUpsert(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestEntityUpsert, params)
    yield put(entityUpsertSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleMarkTrending(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestMarkTrending, params)
    yield put(markTrendingSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(
    getTrendingEntityByFilters.type,
    handleGetTrendingEntityByFilters
  )
  yield takeLatest(getTrendingSourceData.type, handleGetTrendingSourceData)
  yield takeLatest(entityUpsert.type, handleEntityUpsert)
  yield takeLatest(markTrending.type, handleMarkTrending)
}
