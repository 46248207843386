import React from "react"
import { Tag, Pagination } from "antd"
import { connect } from "react-redux"
import CopyToClipboard from "react-copy-to-clipboard"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
// import UserCard from "./userCard"
//import "./styles.css"
//import "../ContentList/style.css"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { Button, Card, CardBody, Badge } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import { size, map } from "lodash"
import { Tooltip } from "antd"
//import { deleteAppUser } from "appRedux/slices/usersList"
class UserList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewmodal: false,
      modal: false,
      orders: [],
      appCode: this.props.appCode || "CREATOR_NETWORK",
      fetchAllUsers: this.props?.filters?.fetchAllUsers || false,
      permissions: [],
      userColumns: [
        {
          dataField: "img",
          text: "#",
          style: {
            width: "5%",
          },
          formatter: (cellContent, user, rowIndex, y) => {
            return (
              <>
                <div className="avatar-xs">
                  <img
                    src={user.profile_pic}
                    className="avatar-title rounded-circle"
                  />
                </div>
              </>
            )
          },
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
          formatter: (cellContent, user) => {
            return (
              <div className="d-flex flex-column">
                <div>{user.name || user.handle}</div>
                <div>{user.email}</div>
              </div>
            )
          },
        },
        {
          dataField: "website_link_status",
          text: "Website Link Status",
          sort: true,
          formatter: (cellContent, user) => {
            return (
              <div>
                <Badge
                  className={
                    user.website_link_status === "VERIFIED"
                      ? "badge-soft-success ms-1 rounded-pill px-3"
                      : "badge-soft-warning ms-1 rounded-pill px-3"
                  }
                >
                  <div className="font-size-12 p-1">
                    {user.website_link_status === "VERIFIED"
                      ? "Verified"
                      : "Not Verified"}
                  </div>
                </Badge>
              </div>
            )
          },
        },
        {
          dataField: "website_link",
          text: "Website Link",
          sort: true,
          formatter: (cellContent, user) => {
            return (
              <div className="d-flex flex-column">
                <a
                  href={user.website_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {user.website_link}
                </a>
              </div>
            )
          },
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Actions",
          formatter: (cellContent, user) => (
            <div className="d-flex align-items-center gap-3">
              <div role="button" className="text-success">
                <Tooltip title="Approve Website Link">
                  <i
                    className="bx bxs-check-shield me-1 font-size-22 fw-bold"
                    id="edittooltip"
                    onClick={e => {
                      e.stopPropagation(),
                        e.preventDefault(),
                        this.props.handleApproveReject(
                          "VERIFIED",
                          user,
                          "WEBSITE_LINK_VERIFIED"
                        )
                    }}
                  ></i>
                </Tooltip>
              </div>
              <div role="button" className="text-danger">
                <Tooltip title="Reject Website Link">
                  <i
                    className="bx bxs-shield-x font-size-22 fw-bold"
                    id="deletetooltip"
                    onClick={e => {
                      // if (
                      //   !this.props.currentUser?.permissions?.includes(
                      //     "EDIT_COMMUNITY_MANAGEMENT"
                      //   )
                      // ) {
                      //   toastr.error(NO_PERMISSION_MESSAGE)
                      //   return
                      // }
                      e.stopPropagation(),
                        e.preventDefault(),
                        this.props.handleApproveReject(
                          "REJECTED",
                          user,
                          "WEBSITE_LINK_REJECTED"
                        )
                    }}
                  ></i>
                </Tooltip>
              </div>
              <div role="button" className="text-warning">
                <Tooltip title="Skip Website Link">
                  <i
                    className="bx bxs-skip-next-circle font-size-22 fw-bold"
                    id="skiptooltip"
                    onClick={e => {
                      // if (
                      //   !this.props.currentUser?.permissions?.includes(
                      //     "EDIT_COMMUNITY_MANAGEMENT"
                      //   )
                      // ) {
                      //   toastr.error(NO_PERMISSION_MESSAGE)
                      //   return
                      // }
                      e.stopPropagation(),
                        e.preventDefault(),
                        this.props.handleApproveReject(
                          "SKIPPED",
                          user,
                          "WEBSITE_LINK_SKIPPED"
                        )
                    }}
                  ></i>
                </Tooltip>
              </div>
              <div>
                <CopyToClipboard
                  text={user.user_uuid}
                  onCopy={e => {
                    toastr.success("Copied sucessfully!")
                  }}
                >
                  <Tooltip title="Copy User ID">
                    <i
                      className="bx bx-copy me-1 font-size-22 fw-bold"
                      role="button"
                    />
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </div>
            // <Button
            //   type="button"
            //   color="primary"
            //   className="btn-sm btn-rounded"
            //   onClick={this.toggleViewModal}
            // >
            //   View Details
            // </Button>
          ),
        },
      ],
    }
    // this.toggle = this.toggle.bind(this)
    // this.toLowerCase1 = this.toLowerCase1.bind(this)
  }

  render() {
    const { userList } = this.props
    const defaultSorted = [
      {
        dataField: "name",
        order: "asc",
      },
    ]
    return (
      <>
        {this.props.total &&
          this.props.userList &&
          this.props.userList.length > 0 && (
            <div style={{ textAlign: "center" }} className="mb-2">
              <Tag color="geekblue">
                Showing <b>{this.props.userList.length}</b> of{" "}
                <b>{this.props.total}</b> Users
              </Tag>
            </div>
          )}
        {this.props.userList.length > 0 && (
          <div className="">
            <Card>
              <CardBody>
                <ToolkitProvider
                  keyField="id"
                  data={userList}
                  columns={this.state.userColumns}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          // {...paginationTableProps}
                          responsive
                          defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          // selectRow={selectRow}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        )}
        {/* <div className="d-flex justify-content-end mb-2">
          <Pagination
            style={{ marginTop: "1rem", listStyle: "none", display: "flex" }}
            onChange={val => this.props.onPageChange(val)}
            defaultCurrent={this.props.currentPage}
            total={this.props.total < 10000 ? this.props.total : 10000}
            pageSize={this.props.pageSize}
            showSizeChanger={false}
          />
        </div> */}
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    //userList: _.get(store, "appUser.userList"),
  }
}

export default connect(mapStateToProps)(UserList)
