import React from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import { Input, Divider, Select } from "antd"
import { Col, Row } from "reactstrap"

const { Option } = Select

const TemplateSectionDrawer = props => {
  return (
    <React.Fragment>
      <Row gutter={12}>
        <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Section Id</h5>
          </Divider>
          <Input
            defaultValue={props.filters["sectionId"]}
            placeholder="Section Id"
            onChange={e =>
              props.handleFilterChange(e.target.value.trim(), "sectionId")
            }
            allowClear={true}
            name="sectionId"
            style={{ width: "100%" }}
          />
        </Col>
        <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Section Name</h5>
          </Divider>
          <Input
            defaultValue={props.filters["sectionName"]}
            placeholder="Section Name"
            onChange={e =>
              props.handleFilterChange(e.target.value.trim(), "sectionName")
            }
            allowClear={true}
            name="sectionName"
            style={{ width: "100%" }}
          />
        </Col>
        <Col lg="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Status</h5>
          </Divider>
          <Select
            allowClear={true}
            defaultValue={props.filters.isActive}
            placeholder="Status"
            style={{ width: "100%" }}
            onChange={value => props.handleFilterChange(value, "isActive")}
          >
            <Option value="">All</Option>
            <Option value={true}>Active</Option>
            <Option value={false}>Inactive</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(TemplateSectionDrawer)
