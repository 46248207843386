import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestTabsList,
  requestDeleteTab,
  requestUpdateTabVersion,
  requestUpsertTabAsset,
  requestUpdateTabOrderData,
  requestAllResourceForType,
  requestVideoMaterialTypes,
  requestUpsertVideoAsset,
  requestAssetTypes,
  requestDeleteVideoMaterialResource,
  requestUpdateAssetMappingOrderData,
  requestAllAssetsForType,
} from "../requests/videoAssets"
import {
  getTabsList,
  getTabsListSuccess,
  upsertTabAsset,
  upsertTabAssetSuccess,
  updateTabOrder,
  updateTabOrderSuccess,
  deleteTabById,
  deleteTabByIdSuccess,
  updateTabVersion,
  updateTabVersionSuccess,
  getVideoMaterialTypes,
  getVideoMaterialTypesSuccess,
  getAllResourceForType,
  getAllResourceForTypeSuccess,
  deleteVideoMaterialResourceById,
  deleteVideoMaterialResourceByIdSuccess,
  updateAssetMappingOrder,
  updateAssetMappingOrderSuccess,
  upsertVideoAsset,
  upsertVideoAssetSuccess,
  getAssetTypes,
  getAssetTypesSuccess,
  getAllAssetsForType,
  getAllAssetsForTypeSuccess,
} from "appRedux/slices/videoAssets"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

function* handleUpdateAssetMappingOrder(payload) {
  try {
    const data = yield call(requestUpdateAssetMappingOrderData, payload)
    yield put(updateAssetMappingOrderSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleVideoMaterialTypes() {
  try {
    const data = yield call(requestVideoMaterialTypes)
    yield put(getVideoMaterialTypesSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAllAssetsForType({ payload }) {
  try {
    const data = yield call(requestAllAssetsForType, payload)
    yield put(getAllAssetsForTypeSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleAllResourceForType({ payload }) {
  try {
    const data = yield call(requestAllResourceForType, payload)
    yield put(getAllResourceForTypeSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteVideoMaterialResource({ payload }) {
  try {
    const data = yield call(requestDeleteVideoMaterialResource, payload)
    toastr.success("Action successfull!!")
    yield put(deleteVideoMaterialResourceByIdSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleUpsertVideAsset({ payload }) {
  try {
    const data = yield call(requestUpsertVideoAsset, payload)
    toastr.success("Action successfull!!")
    yield put(upsertVideoAssetSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleAssetTypes({ payload }) {
  try {
    const data = yield call(requestAssetTypes, payload)
    yield put(getAssetTypesSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleTabsList() {
  try {
    const data = yield call(requestTabsList)
    yield put(getTabsListSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleTabOrder(payload) {
  try {
    const data = yield call(requestUpdateTabOrderData, payload)
    yield put(updateTabOrderSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteTab({ payload }) {
  try {
    const data = yield call(requestDeleteTab, payload)
    toastr.success("Action successfull!!")
    yield put(deleteTabByIdSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleTabVersion({ payload }) {
  try {
    const data = yield call(requestUpdateTabVersion, payload)
    toastr.success("Action successfull!!")
    yield put(updateTabVersionSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

function* handleUpsertTabAsset({ payload }) {
  try {
    const data = yield call(requestUpsertTabAsset, payload)
    toastr.success("Action successfull!!")
    yield put(upsertTabAssetSuccess(data.data))
  } catch (error) {
    toastr.error("Action Failed !!")
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getTabsList.type, handleTabsList)
  yield takeLatest(updateTabOrder.type, handleTabOrder)
  yield takeLatest(upsertTabAsset.type, handleUpsertTabAsset)
  yield takeLatest(deleteTabById.type, handleDeleteTab)
  yield takeLatest(updateTabVersion.type, handleTabVersion)
  yield takeLatest(getVideoMaterialTypes.type, handleVideoMaterialTypes)
  yield takeLatest(getAllResourceForType.type, handleAllResourceForType)
  yield takeLatest(
    deleteVideoMaterialResourceById.type,
    handleDeleteVideoMaterialResource
  )
  yield takeLatest(updateAssetMappingOrder.type, handleUpdateAssetMappingOrder)
  yield takeLatest(upsertVideoAsset.type, handleUpsertVideAsset)
  yield takeLatest(getAssetTypes.type, handleAssetTypes)
  yield takeLatest(getAllAssetsForType.type, handleAllAssetsForType)
}
