export const emotionsData = [
  // {
  //   label: "Is this video depicting a Heart Touching emotion?",
  //   isVisible: true,
  //   taxonomyJsonKey: "taxonomy_emotions",
  //   level: 0,
  //   key: "heart_touching",
  //   sublevel: [
  //     {
  //       label: "Yes",
  //       key: "simple",
  //       value: true,
  //     },
  //     {
  //       label: "No",
  //       key: "simple",
  //       value: false,
  //     },
  //   ],
  // },
  {
    label: "Is it a romantic emotion?",
    parent: "heart_touching",
    taxonomyJsonKey: "taxonomy_emotions",
    level: 1,
    key: "romantic",
    sublevel: [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
    dependentValue: true,
  },
  {
    label: "Is it a Sad emotion?",
    parent: "heart_touching",
    taxonomyJsonKey: "taxonomy_emotions",
    level: 1,
    key: "sad",
    sublevel: [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
    dependentValue: true,
  },
  {
    label: "Is it a Heartbreak emotion?",
    parent: "heart_touching",
    taxonomyJsonKey: "taxonomy_emotions",
    level: 1,
    key: "heartbreak",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
    dependentValue: true,
  },
]

export const genreData = [
  {
    label: "Entertainment",
    value: "entertainment",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    help: {
      description:
        "Hollywood, Bollywood or other Indian films, TV shows, celebrities, Page 3 gossip, etc",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/entertainment.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/entertainment_h.mp4",
    },
  },
  {
    label: "Skit - Humanity",
    value: "skit_humanity",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Skit - Romantic/Heart break",
    value: "skit_romantic_heartbreak",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    displayLabel: "Entertainment (Select Any)",
  },
  {
    label: "Skit - Family love/Respect",
    value: "skit_family_love_respect",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Skit - Patriotic",
    value: "skit_patriotic",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Lip Sync - Romantic/Heart break",
    value: "lipsync_romantic_heartbreak",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Lip Sync - Dialogue",
    value: "lipsync_dialogue",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Movie/TV/OTT Clips",
    value: "movie_tv_ott_clips",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Movie/TV/OTT Celebs",
    value: "movie_tv_ott_celebs",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Video selfie",
    value: "video_selfie",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      description:
        "Choose only if this video selfie does not fall in any other sub genres",
    },
  },
  {
    label: "Others",
    value: "others",
    parent: "entertainment",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Comedy & Fun",
    value: "comedy_n_fun",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    selectionType: "ALL",
    help: {
      description:
        "Videos that have some funny element to it with the main intention of invoking humour",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/comedy.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/comedy.jpg",
    },
  },
  {
    label: "Accidents & Fails",
    value: "fails",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/funny_fails.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/funny_or_fails_h.mp4",
    },
  },
  {
    label: "Dance",
    value: "dance",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      description: "Solo/ Duo/ Group dancing to any audio track",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/dance.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/dance_h.mp4",
    },
  },
  {
    label: "Poetry or Shayari",
    value: "poetry_or_shayari",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    selectionType: "ALL",
    help: {
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/poetry_or_shayari.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/poetry_or_shayari_h.mp4",
    },
  },
  {
    label: "Pranks",
    value: "pranks",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    help: {
      description: "People playing pranks on other people - groups or solo",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/pranks.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/pranks_h.mp4",
    },
  },
  {
    label: "Stunts",
    value: "stunts",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    help: {
      description:
        "Humans attempting / doing amazing things - freestyle or with automobiles etc",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/stunts.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/stunts_h.mp4",
    },
  },
  {
    label: "Status & Wishes",
    value: "status_wishes",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    selectionType: "SINGLE",
    help: {
      description:
        "Status or wishes type videos (can be used as Whatsapp status)",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/status_wishes_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/status_wishes.jpg",
    },
  },
  {
    label: "Romantic/Heart Break",
    value: "romantic_heartbreak",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    displayLabel: "Status & Wishes (Select Any)",
  },
  {
    label: "Family love",
    value: "family_love",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Festivals",
    value: "festivals",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Good morning wishes",
    value: "good_morning_wishes",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Good day wishes",
    value: "good_day_wishes",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Good night wishes",
    value: "good_night_wishes",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Motivational",
    value: "motivational",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Other Imp Days - National days",
    value: "other_imp_days_national_days",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Devotional",
    value: "devotional",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Patriotic",
    value: "patriotic",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Image selfie",
    value: "image_selfie",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      description:
        "Choose only if this image selfie does not fall in any other sub genres",
    },
  },
  {
    label: "Others",
    value: "others",
    parent: "status_wishes",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Fun & Party",
    value: "fun_party",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    help: {
      description:
        "People celebrating festivals and special occassions like weddings",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/fun_party_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/fun_party.jpg",
    },
  },
  {
    label: "Food & Cooking",
    value: "food_cooking",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    selectionType: "ALL",
    help: {
      description: "All things related to food, eating and cooking",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/food_cooking_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/food_cooking.jpg",
    },
  },
  {
    label: "Non Veg",
    value: "non_veg",
    parent: "food_cooking",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    displayLabel: "Is it Veg or Non Veg? (Select Any)",
    key: "target_sub_type",
    help: {
      description:
        "Visual of uncooked meat, bones, etc. that vegetarians may not like",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Non+veg_1_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Non+veg_1.jpg",
    },
  },
  {
    label: "Veg",
    value: "veg",
    parent: "food_cooking",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      description:
        "If the visuals will not make a vegetarian feel uncomfortable",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Food+porn_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Food+porn.jpg",
    },
  },
  {
    label: "Fitness & Health",
    value: "fitness_health",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    help: {
      description: "Videos about workouts, exercise, diets, wellness, etc",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/fitness_health_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/fitness_health.jpg",
    },
  },
  {
    label: "Beauty & Fashion",
    value: "beauty_fashion",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/beauty_fashion_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/beauty_fashion.jpg",
    },
  },
  {
    label: "Education",
    value: "education",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    help: {
      description:
        "Academic and career-related learning content, plus fun things to learn",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/education_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/education.jpg",
    },
  },
  {
    label: "Astrology",
    value: "astrology",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    help: {
      description:
        "Videos which have astrology as the main theme, which predicts personalities and happenings of the past/future of a person. It'll also include ways to improve your future with the help of gems/stones etc.",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/astrology.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/astrology.jpg",
    },
  },

  {
    label: "Aries",
    value: "aries",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    displayLabel:
      "Which of the following zodiac does the content talk about? (Select Any)",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Aries_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Aries_english.jpg",
    },
  },
  {
    label: "Taurus",
    value: "taurus",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Tauras_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Tauras_english.jpg",
    },
  },
  {
    label: "Gemini",
    value: "gemini",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Gemini_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Gemini_english.jpg",
    },
  },
  {
    label: "Cancer",
    value: "cancer",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Cancer_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Cancer_english.jpg",
    },
  },
  {
    label: "Leo",
    value: "leo",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Leo_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Leo_english.jpg",
    },
  },
  {
    label: "Virgo",
    value: "virgo",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Virgo_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Virgo_english.jpg",
    },
  },
  {
    label: "Libra",
    value: "libra",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Libra_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Libra_english.jpg",
    },
  },
  {
    label: "Scorpio",
    value: "scorpio",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Scorpio_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Scorpio_english.jpg",
    },
  },
  {
    label: "Sagittarius",
    value: "sagittarius",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Saggittarius_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Saggittarius_english.jpg",
    },
  },
  {
    label: "Capricon",
    value: "capricon",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Capricon_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Capricon_english.jpg",
    },
  },
  {
    label: "Raashi Bhavishya",
    value: "zodiac_horoscope",
    key: "target_theme",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    displayLabel: "Which category applies best to the content? (Select Any)",
    parent: [
      "aries",
      "taurus",
      "gemini",
      "cancer",
      "leo",
      "virgo",
      "libra",
      "scorpio",
      "sagittarius",
      "capricorn",
      "aquarius",
      "pisces",
    ],
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/raashifal.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/raashifal.jpg",
    },
  },
  {
    label: "Business/Shares/Stocks/Investments/Profit/Loss etc",
    value: "business_horoscope",
    key: "target_theme",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: [
      "aries",
      "taurus",
      "gemini",
      "cancer",
      "leo",
      "virgo",
      "libra",
      "scorpio",
      "sagittarius",
      "capricorn",
      "aquarius",
      "pisces",
    ],
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/business_horoscope.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/business_horoscope.jpg",
    },
  },
  {
    label: "Relationships",
    value: "relationship_horoscope",
    key: "target_theme",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: [
      "aries",
      "taurus",
      "gemini",
      "cancer",
      "leo",
      "virgo",
      "libra",
      "scorpio",
      "sagittarius",
      "capricorn",
      "aquarius",
      "pisces",
    ],
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/relationship_horoscope.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/relationship_horoscope.jpg",
    },
  },
  {
    label: "Matrimonial",
    value: "matrimonial_horoscope",
    key: "target_theme",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: [
      "aries",
      "taurus",
      "gemini",
      "cancer",
      "leo",
      "virgo",
      "libra",
      "scorpio",
      "sagittarius",
      "capricorn",
      "aquarius",
      "pisces",
    ],
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/matrimonial_horoscope.png",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/matrimonial_horoscope.png",
    },
  },
  {
    label: "Career/Jobs/Education",
    value: "career_horoscope",
    key: "target_theme",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: [
      "aries",
      "taurus",
      "gemini",
      "cancer",
      "leo",
      "virgo",
      "libra",
      "scorpio",
      "sagittarius",
      "capricorn",
      "aquarius",
      "pisces",
    ],
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/education_career_horoscope.png",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/education_career_horoscope.png",
    },
  },
  {
    label:
      "General Prediction (Palmistry/Forehead/Birth Marks/Tatoos/Vastu/Numerology/Tarot)",
    value: "general_prediction",
    key: "target_theme",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: [
      "aries",
      "taurus",
      "gemini",
      "cancer",
      "leo",
      "virgo",
      "libra",
      "scorpio",
      "sagittarius",
      "capricorn",
      "aquarius",
      "pisces",
    ],
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/palmistry_horoscpe.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/palmistry_horoscpe.jpg",
    },
  },
  {
    label: "Festivals/Nakshtra/Planetory Changes",
    value: "nakshatra_based_horoscope",
    key: "target_theme",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: [
      "aries",
      "taurus",
      "gemini",
      "cancer",
      "leo",
      "virgo",
      "libra",
      "scorpio",
      "sagittarius",
      "capricorn",
      "aquarius",
      "pisces",
    ],
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/nakshatra_horoscope.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/nakshatra_horoscope.jpg",
    },
  },
  {
    label: "Others",
    value: "others",
    key: "target_theme",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: [
      "aries",
      "taurus",
      "gemini",
      "cancer",
      "leo",
      "virgo",
      "libra",
      "scorpio",
      "sagittarius",
      "capricorn",
      "aquarius",
      "pisces",
    ],
  },
  {
    label: "Aquarius",
    value: "aquarius",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Aquarius_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Aquarius_english.jpg",
    },
  },
  {
    label: "Pisces",
    value: "pisces",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Pisces_english.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Pisces_english.jpg",
    },
  },
  {
    label: "Generic Astro",
    value: "generic_astro",
    level: 1,
    parent: "astrology",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Motivational",
    value: "motivational",
    parent: "",
    level: 0,
    isVisible: true,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_type",
    selectionType: "ALL",
    help: {
      description:
        "Inspirational talks by famous (or less known) learned people",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Motivation.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Motivation.jpg",
    },
  },
  {
    label: "Life management",
    value: "life_management",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    displayLabel: "Which category applies best to the content? (Select Any)",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/life_management.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/life_management.jpg",
    },
  },
  {
    label: "Relationship tips",
    value: "relationship_tips",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/relationship_tips.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/relationship_tips.jpg",
    },
  },
  {
    label: "Self Grooming",
    value: "self_grooming",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/self_grooming.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/self_grooming.jpg",
    },
  },
  {
    label: "Success series",
    value: "success_motivation",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/success.png",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/success.png",
    },
  },
  {
    label: "Money",
    value: "money_motivation",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Money_Bag.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Money_Bag.jpg",
    },
  },
  {
    label: "Good manners",
    value: "good_manners",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/good_manners.png",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/good_manners.png",
    },
  },
  {
    label: "Improving Communication Skills",
    value: "communication_skills",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/communication_skills.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/communication_skills.jpg",
    },
  },
  {
    label: "Patriotism",
    value: "patriotism",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/patriotism.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/patriotism.jpg",
    },
  },
  {
    label: "Contributing to society(Willingness to change world",
    value: "social_contribution",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/social_contribtution.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/social_contribtution.jpg",
    },
  },
  {
    label: "Others",
    value: "others",
    level: 1,
    parent: "motivational",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",
  },
  {
    label: "Gods or Goddess",
    value: "gods_or_goddess",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    selectionType: "ALL",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/gods_goddesses_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/gods_goddesses.jpg",
    },
  },
  {
    label: "Hinduism",
    value: "hinduism",
    parent: "gods_or_goddess",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    displayLabel: "Which religion does it belong to? (Select Any)",
    key: "target_sub_type",

    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Shiva_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Shiva.jpg",
    },
  },
  {
    label: "Islam",
    value: "islam",
    parent: "gods_or_goddess",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",

    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Allah_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Allah.jpg",
    },
  },
  {
    label: "Sikhism",
    value: "sikhism",
    parent: "gods_or_goddess",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",

    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/sikhism_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/sikhism.jpg",
    },
  },
  {
    label: "Christianity",
    value: "christianity",
    parent: "gods_or_goddess",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",

    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Jesus_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Jesus.jpg",
    },
  },
  {
    label: "Jainism",
    value: "jainism",
    parent: "gods_or_goddess",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",

    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/jainism_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/jainism.jpg",
    },
  },
  {
    label: "Buddhism",
    value: "buddhism",
    parent: "gods_or_goddess",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",

    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Budhsim.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Budhsim.jpg",
    },
  },
  {
    label: "Other Religions",
    value: "other_religions",
    parent: "gods_or_goddess",
    level: 1,
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_sub_type",

    help: {
      description: "Unsure/ cannot figure out which religion",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/other_religions_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/other_religions.jpg",
    },
  },

  {
    label: "Brahma",
    value: "brahma",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    displayLabel:
      "Which Hindu god or goddess is present in the video? (Select Any)",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/brahma.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/brahma.jpg",
    },
  },
  {
    label: "Vishunu",
    value: "vishnu",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/vishnu.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/vishnu.jpg",
    },
  },
  {
    label: "Shiva",
    value: "shiva",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Shiva.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Shiva.jpg",
    },
  },
  {
    label: "Laxmi",
    value: "laxmi",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Laxmi.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Laxmi.jpg",
    },
  },
  {
    label: "Saraswati",
    value: "saraswati",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/saraswati.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/saraswati.jpg",
    },
  },
  {
    label: "Ganesha",
    value: "ganesha",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Ganesha.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Ganesha.jpg",
    },
  },
  {
    label: "Krishna",
    value: "krishna",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Krishna.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Krishna.jpg",
    },
  },
  {
    label: "Ram",
    value: "ram",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Ram.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Ram.jpg",
    },
  },
  {
    label: "Hanuman",
    value: "hanuman",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Hanuman.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Hanuman.jpg",
    },
  },
  {
    label: "Durga",
    value: "durga",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Durga.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Durga.jpg",
    },
  },
  {
    label: "Kali",
    value: "kali",
    level: 2,
    taxonomyJsonKey: "taxonomy_genre",
    parent: "hinduism",
    key: "target_theme",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Kali.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Kali.jpg",
    },
  },
  {
    label: "Other Gods",
    value: "other_gods",
    level: 2,
    parent: "hinduism",
    taxonomyJsonKey: "taxonomy_genre",
    key: "target_theme",
  },
  {
    label: "Pets / Wildlife / Sealife",
    value: "pets_wildlife_sealife",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      description: "Videos of animals, with or without human beings",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/pets_wildlife_sealife_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/pets_wildlife_sealife.jpg",
    },
  },
  {
    label: "Cars, Bikes & Planes",
    value: "cars_bikes_aircrafts",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    selectionType: "ALL",
    help: {
      description: "Any information related to cars and bikes",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/cars_bikes_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/cars_bikes.jpg",
    },
  },
  {
    label: "Gadgets",
    value: "gadgets",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    selectionType: "ALL",
    help: {
      description:
        "Videos featuring gadgets (Mobile), appliances, or any other tech related products",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/gadgets_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/gadgets.jpg",
    },
  },
  {
    label: "Toys",
    value: "toys",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      description:
        "This label will have videos where toy is the centric part of the video and the purpose of making the video is to show the toy.",
    },
  },
  {
    label: "Arts & Crafts",
    value: "arts_crafts",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      description: "Decorative handwriting",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/arts_crafts.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/arts_crafts_h.mp4",
    },
  },
  {
    label: "DIY & Hacks",
    value: "diy_hacks",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      description:
        '"How-to" style videos about everyday useful tips and tricks',
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/diy_hacks.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/diy_hacks_h.mp4",
    },
  },
  {
    label: "Photography",
    value: "photography",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    selectionType: "ALL",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/photography_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/photography.jpg",
    },
  },
  {
    label: "Sports",
    value: "sports",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    selectionType: "ALL",
    help: {
      description: "All sports and athletes playing sports",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/sports_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/sports.jpg",
    },
  },
  {
    label: "Cricket",
    value: "cricket",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    selectionType: "ALL",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/cricket_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/cricket.jpg",
    },
  },
  {
    label: "Football",
    value: "football",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    selectionType: "ALL",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/football_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/football.jpg",
    },
  },
  {
    label: "Magic Performance",
    value: "magic_performance",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/magic_performance.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/magic_performance_h.mp4",
    },
  },
  {
    label: "Architecture",
    value: "architecture",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    selectionType: "ALL",
    help: {
      description: "Buildings, monuments, ruins, bridges, etc",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/architecture_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/architecture.jpg",
    },
  },
  {
    label: "Travel",
    value: "travel",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      description:
        "Videos of various travel destinations and to some extent, it will make the viewers want to visit the place",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Road+Trip_h.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/Road+Trip.jpg",
    },
  },
  {
    label: "News",
    value: "news",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      description: "Videos covering the latest happenings across the world",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/news.jpg",
      video:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre_videos/news_h.mp4",
    },
  },
  {
    label: "Can't Identify",
    value: "cant_identify_genre",
    parent: "",
    taxonomyJsonKey: "taxonomy_genre",
    level: 0,
    isVisible: true,
    key: "target_type",
    help: {
      description:
        "Videos which have no meaning and cannot even consider in any of the above mentioned labels.",
    },
  },
]

export const orthogonalQualityData = [
  {
    label: "Is it simple?",
    isVisible: true,
    taxonomyJsonKey: "video_quality_attributes",
    level: 0,
    key: "simple",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
  {
    label: "Is it relatable?",
    isVisible: true,
    taxonomyJsonKey: "video_quality_attributes",
    level: 0,
    key: "relatable",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
  {
    label: "Does it have a quality talent?",
    isVisible: true,
    taxonomyJsonKey: "video_quality_attributes",
    level: 0,
    key: "quality_talent",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
  {
    label: "Does it have a surprise element?",
    isVisible: true,
    taxonomyJsonKey: "video_quality_attributes",
    level: 0,
    key: "surprise_element",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
  {
    label: "Is it presentable?",
    isVisible: true,
    taxonomyJsonKey: "video_quality_attributes",
    level: 0,
    key: "presentable",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
]

export const orthogonalAttributeData = [
  {
    label: "Is someone singing in the video?",
    isVisible: true,
    taxonomyJsonKey: "orthogonal_attributes",
    level: 0,
    key: "singing",
    sublevel: [
      {
        label: "Yes",
        key: "singing",
        value: true,
      },
      {
        label: "No",
        key: "singing",
        value: false,
      },
    ],
  },
  {
    label: "Is someone perfoming an act in the video?",
    isVisible: true,
    taxonomyJsonKey: "orthogonal_attributes",
    level: 0,
    key: "acting",
    sublevel: [
      {
        label: "Yes",
        key: "acting",
        value: true,
      },
      {
        label: "No",
        key: "acting",
        value: false,
      },
    ],
  },
  {
    label: "Is someone saying a dialogue in the video?",
    isVisible: true,
    taxonomyJsonKey: "orthogonal_attributes",
    level: 0,
    key: "dialogue",
    sublevel: [
      {
        label: "Yes",
        key: "dialogue",
        value: true,
      },
      {
        label: "No",
        key: "dialogue",
        value: false,
      },
    ],
  },
  {
    label: "Is there a background music in the video?",
    isVisible: true,
    taxonomyJsonKey: "orthogonal_attributes",
    level: 0,
    key: "music",
    sublevel: [
      {
        label: "Yes",
        key: "music",
        value: true,
      },
      {
        label: "No",
        key: "music",
        value: false,
      },
    ],
  },
  {
    label: "Is it an Original Performance by the creator?",
    isVisible: true,
    taxonomyJsonKey: "orthogonal_attributes",
    level: 0,
    key: "original_performance",
    sublevel: [
      {
        label: "Yes",
        key: "original_performance",
        value: true,
      },
      {
        label: "No",
        key: "original_performance",
        value: false,
      },
    ],
  },
]
export const audioVulgarityData = [
  {
    label: "Is there Audio in the Video?",
    isVisible: true,
    level: 0,
    key: "audio_present",
    taxonomyJsonKey: "taxonomy_video_quality",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
  {
    label: "Is the audio in the video vulgar?",
    parent: "audio_present",
    level: 1,
    key: "is_audio_vulgar",
    taxonomyJsonKey: "taxonomy_video_quality",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
    dependentValue: true,
  },
  {
    label: "Is it a friendly abuse?",
    parent: "is_audio_vulgar",
    level: 2,
    key: "friendly_abuse",
    taxonomyJsonKey: "taxonomy_video_quality",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
    dependentValue: true,
  },
  {
    label: "Is it a political abuse?",
    parent: "is_audio_vulgar",
    taxonomyJsonKey: "taxonomy_video_quality",
    level: 2,
    key: "polictical_abuse",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
    dependentValue: true,
  },
  {
    label: "Is it a religious abuse?",
    parent: "is_audio_vulgar",
    taxonomyJsonKey: "taxonomy_video_quality",
    level: 2,
    key: "religious_abuse",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
    dependentValue: true,
  },
]
export const backgroundData = [
  {
    label: "Indoor",
    value: "indoor",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Clearly indoor (home, office,,...)",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/indoor.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/indoor.jpg",
    },
  },
  {
    label: "Gym",
    value: "gym",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Shot inside a Gym setup",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/Workout.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/Workout.jpg",
    },
  },
  {
    label: "Park",
    value: "park",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Shot in a park",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/Park.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/Park.jpg",
    },
  },
  {
    label: "Rooftop",
    value: "rooftop",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Shot on a rooftop",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/rooftop.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/rooftop.jpg",
    },
  },
  {
    label: "Sky",
    value: "sky",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/sky.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/sky.jpg",
    },
  },
  {
    label: "Road / Track",
    taxonomyJsonKey: "taxonomy_elements",
    value: "road_track",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Any constructed road or rough/gravel/ mud track",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/road.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/road.jpg",
    },
  },
  {
    label: "Fields / Farms",
    taxonomyJsonKey: "taxonomy_elements",
    value: "fields_farms",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/farm.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/farm.jpg",
    },
  },
  {
    label: "Jungle",
    value: "jungle",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/jungle.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/jungle.jpg",
    },
  },
  {
    label: "Beach",
    value: "beach",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/beach.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/beach.jpg",
    },
  },
  {
    label: "Water Body",
    value: "water_body",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Any water body(sea, river, pond, lake)",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/water_body.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/water_body.jpg",
    },
  },
  {
    label: "Mountains",
    value: "mountains",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/mountain.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/mountain.jpg",
    },
  },
  {
    label: "Airport",
    value: "airport",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/airport.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/airport.jpg",
    },
  },
  {
    label: "Railway Station",
    value: "railway_station",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/railway.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/railway.jpg",
    },
  },
  {
    label: "Stadium",
    value: "stadium",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Sports stadiums or similar",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/stadium.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/stadium.jpg",
    },
  },
  {
    label: "Shopping Mall / Market",
    value: "shopping_mall_market",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/shopping.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/shopping.jpg",
    },
  },
  {
    label: "Hotel/Restaurant",
    value: "hotel_restaurant",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/hotel_restaurant.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/hotel_restaurant.jpg",
    },
  },
  {
    label: "Beautiful Architecture",
    value: "beautiful_architecture",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Any building/ monument that is beautiful but not famous",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/img/architecture.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/genre/thumb/architecture.jpg",
    },
  },
  {
    label: "Famous Architecture",
    value: "famous_architecture",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description:
        "Any building/ monument that is beautiful AND famous (e.g. Taj Mahal)",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/famous_arc.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/famous_arc.jpg",
    },
  },
  {
    label: "Temple",
    value: "temple",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/temple.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/temple.jpg",
    },
  },
  {
    label: "Masjid (Mosque)",
    value: "masjid_mosque",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/masjid.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/masjid.jpg",
    },
  },
  {
    label: "Gurudwara",
    value: "gurudwara",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/gurdwara.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/gurdwara.jpg",
    },
  },
  {
    label: "Church",
    value: "church",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/church.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/church.jpg",
    },
  },
  {
    label: "Mixed",
    value: "mixed",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Lots of different types of buildings/ elements",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/international_city.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/international_city.jpg",
    },
  },
  {
    label: "Others",
    value: "others",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,
    key: "background",
    help: {
      description: "Other Backgrounds",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/shot_in_rooftop.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/shot_in_rooftop.jpg",
    },
  },
]

export const cityTierData = [
  {
    label: "International",
    value: "international",
    taxonomyJsonKey: "taxonomy_elements",
    parent: "city_tier",
    level: 0,
    isVisible: true,

    key: "city_tier",
    help: {
      description:
        "Visuals look like they are in an international city/ location",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/international_city.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/international_city.jpg",
    },
  },
  {
    label: "Rural",
    value: "rural",
    parent: "city_tier",
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    isVisible: true,

    key: "city_tier",
    help: {
      description: "Visuals look like they are in a rural area/ village",
      image:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/img/rural_city.jpg",
      thumbnail:
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/elements/thumb/rural_city.jpg",
    },
  },
  {
    label: "Can't Identify",
    value: "cant_identify",
    taxonomyJsonKey: "taxonomy_elements",
    parent: "city_tier",
    level: 0,
    isVisible: true,
    key: "city_tier",
    help: {
      description: "Can't Identify",
    },
  },
]

export const peopleInVideoData = [
  {
    label: "Is there a Male in the video?",
    isVisible: true,
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    key: "male",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
  {
    label: "Is there a female in the video?",
    isVisible: true,
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    key: "female",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
  {
    label: "Is there a child in the video?",
    isVisible: true,
    taxonomyJsonKey: "taxonomy_elements",
    level: 0,
    key: "child",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
]

export const eventData = [
  {
    label: "Is there any event related to this video?",
    parent: "taxonomy_event",
    taxonomyJsonKey: "taxonomy_event",
    level: 0,
    isVisible: true,
    key: "event_element",
    sublevel: [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
  },
  {
    label: "What kind of event is diplayed in the video?",
    parent: "event_element",
    taxonomyJsonKey: "taxonomy_event",
    type: "card",
    level: 1,
    key: "event_type",
    sublevel: [],
    dependentValue: true,
  },
]

export const glamourData = [
  {
    parent: "glamour_element_partial_nudity_visible",
    level: 3,
    taxonomyJsonKey: "taxonomy_glamour",
    type: "card",
    key: "glamour_type",
    sublevel: [
      {
        label:
          "G1 - Pretty Girl: Clothes,with minimal skin show,is mainly trying to look pretty",
        value: "pretty_girl",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/glamour/Glamour_Type_1__PRETTY_GIRL.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/glamour/Glamour_Type_1.mp4",
        },
      },
      {
        label:
          "G2 - Glamourous: sexy clothes,some skin exposure, skin show is done in a casual/ “fun” way",
        value: "glamorous",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_2_GLAMOROUS.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_2_GLAMOROUS.mp4",
        },
      },
      {
        label:
          "G3 - Hot/Sexy: 20-30% exposure half boobs/Bikini/Limited exposure dance/NON-CRINGY",
        value: "classy_hot_sexy",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_3_HOTandSEXY.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_3_HOTandSEXY.mp4",
        },
      },
      {
        label:
          "G4 - MASALA: 30-50% exposure, Sexually Suggestive,/Bikini Shoot w. high skin show,/CRINGY",
        value: "masala",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_4_Masala.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_4_Masala.mp4",
        },
      },
      {
        label:
          "G5 - Vulgar MASALA: 50-70% exposure,Bare Hips/back, Love making with not much parts visible",
        value: "vulgar_masala",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_5_BODYART.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_5_BODYART.mp4",
        },
      },
      {
        label: "G6 - CUTENESS",
        key: "glamour_partial_nudity_visible_child",
        value: "acceptable_child_nudity",
        sub_type: "b",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_6_AcceptableCHILDNUDITY.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_6_AcceptableCHILDNUDITY.mp4",
        },
      },
      {
        label:
          "G7 - Sexual organs partially visible or visible through transparent clothing(Breasts)",
        key: "glamour_partial_nudity_visible_child",
        value: "partial_adult_nudity#c",
        sub_type: "c",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_7_PartialNUDITY.A.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_7_PartialNUDITY.mp4",
        },
      },
      {
        label: "G7 - Love making with lot of body parts visible",
        key: "glamour_partial_nudity_visible_adult",
        value: "partial_adult_nudity#d",
        sub_type: "d",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_7_PartialNUDITY.B.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_7_PartialNUDITY.B.mp4",
        },
      },
      {
        label: "Nude: Genitals/Public Hair Visible",
        value: "full_adult_nudity#b",
        sub_type: "b",
      },
      {
        label: "Sexuality/Genitals",
        value: "unacceptable_child_nudity",
        sub_type: "a",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_9_UNACCEPTABLE_CHILD_NUDITY.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_9_UNACCEPTABLE_CHILD_NUDITY.mp4",
        },
      },
    ],
    dependentValue: false,
  },
  // {
  //   label:
  //     "Is there any element of glamourous girls / hot & sexy women / sexuality in this video?",
  //   parent: "taxonomy_glamour",
  //   level: 0,
  //   taxonomyJsonKey: "taxonomy_glamour",
  //   isVisible: true,
  //   key: "glamour_element",
  //   sublevel: [
  //     {
  //       label: "Yes",
  //       value: true,
  //     },
  //     {
  //       label: "No",
  //       value: false,
  //     },
  //   ],
  // },
  // {
  //   label: "Is there full nudity visible in the video? (90-100% exposure)",
  //   parent: "glamour_element",
  //   level: 1,
  //   taxonomyJsonKey: "taxonomy_glamour",
  //   key: "glamour_element_full_nudity_visible",
  //   sublevel: [
  //     {
  //       label: "Yes",
  //       key: "simple",
  //       value: true,
  //     },
  //     {
  //       label: "No",
  //       key: "simple",
  //       value: false,
  //     },
  //   ],
  //   dependentValue: true,
  // },
  // {
  //   label: "The person who is nude is:",
  //   parent: "glamour_element_full_nudity_visible",
  //   level: 2,
  //   taxonomyJsonKey: "taxonomy_glamour",
  //   key: "person_who_is_nude",
  //   type: "card",
  //   sublevel: [
  //     {
  //       label: "CHILD",
  //       key: "glamour_nudity_visible_child",
  //       value: "glamour_nudity_visible_child",
  //     },
  //     {
  //       label: "ADULT",
  //       key: "glamour_nudity_visible_adult",
  //       value: "glamour_nudity_visible_adult",
  //     },
  //   ],
  //   dependentValue: true,
  // },
  // {
  //   label: "Choose the option which fits the video: ",
  //   parent: "person_who_is_nude",
  //   type: "card",
  //   taxonomyJsonKey: "taxonomy_glamour",
  //   key: "glamour_type",
  //   level: 3,
  //   sublevel: [
  //     {
  //       label: "NUDE: GENITALS/ PUBIC HAIR VISIBLE",
  //       value: "full_adult_nudity#b",
  //       sub_type: "b",
  //     },
  //   ],
  //   dependentValue: "glamour_nudity_visible_adult",
  // },
  // {
  //   label: "The focus of the videos is the child's",
  //   parent: "person_who_is_nude",
  //   level: 3,
  //   taxonomyJsonKey: "taxonomy_glamour",
  //   key: "glamour_type",
  //   type: "card",
  //   sublevel: [
  //     {
  //       label: "SEXUALITY/ GENITALS",
  //       value: "unacceptable_child_nudity",
  //       sub_type: "a",
  //       help: {
  //         thumbnail:
  //           "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_9_UNACCEPTABLE_CHILD_NUDITY.jpg",
  //         video:
  //           "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_9_UNACCEPTABLE_CHILD_NUDITY.mp4",
  //       },
  //     },
  //   ],
  //   dependentValue: "glamour_nudity_visible_child",
  // },
  // {
  //   label: "Is there partial nudity visible in the video? (70-90% exposure)",
  //   parent: "glamour_element_full_nudity_visible",
  //   level: 2,
  //   taxonomyJsonKey: "taxonomy_glamour",
  //   key: "glamour_element_partial_nudity_visible",
  //   sublevel: [
  //     {
  //       label: "Yes",
  //       key: "simple",
  //       value: true,
  //     },
  //     {
  //       label: "No",
  //       key: "simple",
  //       value: false,
  //     },
  //   ],
  //   dependentValue: false,
  // },
  // {
  //   label: "The person who is 70-90% nude is:",
  //   parent: "glamour_element_partial_nudity_visible",
  //   level: 3,
  //   taxonomyJsonKey: "taxonomy_glamour",
  //   key: "glamour_element_partial_nudity_person_visibility",
  //   type: "card",
  //   sublevel: [
  //     {
  //       label: "CHILD",
  //       value: "glamour_partial_nudity_visible_child",
  //     },
  //     {
  //       label: "ADULT",
  //       value: "glamour_partial_nudity_visible_adult",
  //     },
  //   ],
  //   dependentValue: true,
  // },
  // {
  //   label: "The focus of the videos is the child's",
  //   parent: "glamour_element_partial_nudity_person_visibility",
  //   level: 4,
  //   key: "glamour_type",
  //   type: "card",
  //   taxonomyJsonKey: "taxonomy_glamour",
  //   sublevel: [
  //     {
  //       label: "G6 - CUTENESS",
  //       key: "glamour_partial_nudity_visible_child",
  //       value: "acceptable_child_nudity",
  //       sub_type: "b",
  //       help: {
  //         thumbnail:
  //           "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_6_AcceptableCHILDNUDITY.jpg",
  //         video:
  //           "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_6_AcceptableCHILDNUDITY.mp4",
  //       },
  //     },
  //   ],
  //   dependentValue: "glamour_partial_nudity_visible_child",
  // },
  // {
  //   label: "The level of partial nudity is",
  //   parent: "glamour_element_partial_nudity_person_visibility",
  //   level: 4,
  //   key: "glamour_type",
  //   taxonomyJsonKey: "taxonomy_glamour",
  //   type: "card",
  //   sublevel: [
  //     {
  //       label:
  //         "G7 - Sexual organs partially visible or visible through transparent clothing(Breasts)",
  //       key: "glamour_partial_nudity_visible_child",
  //       value: "partial_adult_nudity#c",
  //       sub_type: "c",
  //       help: {
  //         thumbnail:
  //           "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_7_PartialNUDITY.A.jpg",
  //         video:
  //           "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_7_PartialNUDITY.mp4",
  //       },
  //     },
  //     {
  //       label: "G7 - Love making with lot of body parts visible",
  //       key: "glamour_partial_nudity_visible_adult",
  //       value: "partial_adult_nudity#d",
  //       sub_type: "d",
  //       help: {
  //         thumbnail:
  //           "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_7_PartialNUDITY.B.jpg",
  //         video:
  //           "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/Updated_Glamour/Glamour_Type_7_PartialNUDITY.B.mp4",
  //       },
  //     },
  //   ],
  //   dependentValue: "glamour_partial_nudity_visible_adult",
  // },
]

export const violenceData = [
  {
    parent: "violence_element",
    taxonomyJsonKey: "taxonomy_violence",
    type: "card",
    level: 1,
    key: "violence_type",
    sublevel: [
      {
        label: "No Violence",
        value: "no_violence",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/default/default.jpg",
        },
      },
      {
        label: "Physical Abuse to Humans",
        value: "physical_abuse",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/voilence/Violence_Type_2__Violence_physical_Abuse.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/voilence/Violence_Type_2.mp4",
        },
      },
      {
        label: "Animal being used for Sports (no killing or bloodshed)",
        value: "animal_sports",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/voilence/Violence_Type_3_Animal_Cruelity.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/voilence/Violence_Type_3.mp4",
        },
      },
      {
        label: "Legal display of weapons in the video",
        value: "legal_display_of_weapons",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/voilence/Violence_Type_1__Legal_display_of_Weapons.jpg",
          video:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/voilence/Violence_Type_1__Legal_display_of_Weapons.mp4",
        },
      },
      {
        label: "Public protest",
        value: "public_protest",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/voilence/protest.jpg",
        },
      },
      {
        label: "Property damage",
        value: "property_damage",
        help: {
          thumbnail:
            "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/voilence/Damage_to_public_property.jpg",
        },
      },
    ],
    dependentValue: true,
  },
]
export const ethnicityData = [
  {
    parent: "ethnicity_element",
    taxonomyJsonKey: "video_additional_info",
    type: "card",
    level: 1,
    key: "ethnicity_type",
    sublevel: [
      {
        label: "Indian",
        value: "indian",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/Indian.jpg",
        },
      },
      {
        label: "Chinese",
        value: "chinese",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/Chinese.png",
        },
      },
      {
        label: "Afro",
        value: "afro",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/Afro.jpg",
        },
      },
      {
        label: "Arab",
        value: "arab",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/Arab.jpeg",
        },
      },
      {
        label: "White",
        value: "white",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/White.png",
        },
      },
      {
        label: "Mixed",
        value: "mixed",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/Mixed.jpeg",
        },
      },
      {
        label: "Others",
        value: "others",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/Others.jpeg",
        },
      },
      {
        label: "NA",
        value: "na",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/NA.png",
        },
      },
    ],
    dependentValue: true,
  },
]

export const peopleCount = [
  {
    parent: "people_count_element",
    taxonomyJsonKey: "video_additional_info",
    type: "card",
    level: 1,
    key: "people_count_type",
    sublevel: [
      {
        label: "Single",
        value: "single",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/Single.png",
        },
      },
      {
        label: "Multiple",
        value: "multiple",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/Multiple.png",
        },
      },
      {
        label: "NA",
        value: "na",
        help: {
          thumbnail:
            "https://qa-saz.myjosh.in/stream-az/qa-josh-content/josh-cms/taxonomy/NA.png",
        },
      },
    ],
    dependentValue: true,
  },
]

export const targetLanguageData = [
  {
    label: "Can this video be consumed in other languages?",
    isVisible: true,
    level: 0,
    parent: "target_languages",
    key: "can_be_targeted",
    taxonomyJsonKey: "target_languages",
    sublevel: [
      {
        label: "Yes",
        key: "simple",
        value: true,
      },
      {
        label: "No",
        key: "simple",
        value: false,
      },
    ],
  },
  {
    label: "Please select one of the options: ",
    parent: "can_be_targeted",
    type: "card",
    taxonomyJsonKey: "target_languages",
    level: 1,
    key: "language_type",
    sublevel: [
      {
        label: "All Languages",
        value: "all",
        help: {
          description:
            "Only Music present, No Indian Language, but vira, Little touch of vernacular language which doesn't restricts for other langauge, A viral video which appeal all audience, Specific Genres",
        },
      },
      {
        label: "North Languages",
        value: "north",
        help: {
          description:
            "Will appeal to Nothern languagues: (Hindi, English, Bhojpuri, Marathi, Bengali, Gujarati, Punjabi, Oriya)",
        },
      },
      {
        label: "South Languages",
        value: "south",
        help: {
          description:
            "Will appeal to South languagues: (Telugu, Tamil, Kannada, Malayalam)",
        },
      },
      {
        label: "Few Languages",
        value: "few",
        help: {
          description: "Will appeal only to a few languagues",
        },
      },
    ],
    dependentValue: true,
  },
  {
    label: "Please select from the below options: ",
    parent: "language_type",
    type: "card",
    level: 2,
    taxonomyJsonKey: "target_languages",
    key: "languages",
    isMultiple: true,
    sublevel: [
      {
        label: "Hindi",
        value: "hi",
      },
      {
        label: "English",
        value: "en",
      },
      {
        label: "Telugu",
        value: "te",
      },
      {
        label: "Bhojpuri",
        value: "bh",
      },
      {
        label: "Kannada",
        value: "kn",
      },
      {
        label: "Tamil",
        value: "ta",
      },
      {
        label: "Bengali",
        value: "bn",
      },
      {
        label: "Malayalam",
        value: "ml",
      },
      {
        label: "Marathi",
        value: "mr",
      },
      {
        label: "Punjabi",
        value: "pn",
      },
      {
        label: "Gujarati",
        value: "gu",
      },
      {
        label: "Oriya",
        value: "or",
      },
    ],
    dependentValue: "few",
  },
]
