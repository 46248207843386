import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const createInterest = async payload => {
  try {
    let data = await Axios.post(`/api/interest/upsert`, payload)

    if (payload.action == "edit") {
      toastr.success("Sub-interest Updated Successfully")
    } else {
      toastr.success("Interest Created Successfully")
    }
    return data.data
  } catch (error) {
    throw error
  }
}

export const createStrategy = async payload => {
  try {
    let data = await Axios.post(`/api/strategy/upsert`, payload)
    if (payload.action == "edit") {
      toastr.success("Strategy Updated Successfully")
    } else {
      toastr.success("Strategy Created Successfully")
    }
    return data.data
  } catch (error) {
    throw error
  }
}
export const updateStrategy = async payload => {
  try {
    let data = await Axios.post(`/api/strategy/upsert`, payload)

    return data.data
  } catch (error) {
    throw error
  }
}

export const getInterest = async payload => {
  try {
    let data = await Axios.post(`/api/interest/get`, payload)
    //   toastr.success("interest get Successfully")
    return data.data
  } catch (error) {
    throw error
  }
}
export const getCmsUserList = async payload => {
  try {
    let data = await Axios.post(`/api/users/getCMSUsersList`, payload)
    //   toastr.success("interest get Successfully")
    return data.data
  } catch (error) {
    throw error
  }
}

export const getInterestReviewed = async payload => {
  try {
    let data = await Axios.post(`/api/interest/getInterestreviewed`, payload)
    //   toastr.success("interest get Successfully")
    return data.data
  } catch (error) {
    throw error
  }
}
export const getStartegy = async payload => {
  try {
    let data = await Axios.post(`/api/strategy/get`, payload)
    //   toastr.success("interest get Successfully")
    return data.data
  } catch (error) {
    throw error
  }
}

export const updateSubInterest = async payload => {
  try {
    let data = await Axios.post(`/api/subinterest/update`, payload)
    toastr.success("sub-interest Updated Successfully")
    return data.data
  } catch (error) {
    throw error
  }
}

export const interestDetails = async payload => {
  try {
    let data = await Axios.post(`/api/interest/content/get-stats`, payload)
    return data.data
  } catch (error) {
    throw error
  }
}
export const getContentByInterestAndSubInterest = async payload => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(`/api/interest/seed/videos`, {
      filters: payload,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const getDtaTaskContentByInterestAndSubInterest = async payload => {
  try {
    const { filters } = payload
    const offset = filters["currentPage"] ? filters["currentPage"] - 1 : 0
    const limit = filters["pageSize"] ? filters["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/interest/getTaskByFilters?offset=${offset}&limit=${limit}`,
      filters
    )
    return data
  } catch (error) {
    throw error
  }
}

export const updateReviewContent = async (payload, action) => {
  try {
    toastr.info("Action in progress...")
    let data = await Axios.post(`/api/interest/content/update`, payload)
    toastr.success("Content " + action ? action : "Updated" + " Successfully")
    return data
  } catch (error) {
    toastr.error("Content Update Failed")
    throw error
  }
}

export const getVideosByItemsIdsOrUserId = async payload => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(`/api/interest/search/videos`, {
      searchText: payload,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const saveFilesToS3 = async formData => {
  try {
    let response = await Axios.post(
      `/api/interest/seed/save-videos`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    return response
  } catch (error) {
    throw error
  }
}

export const getVideosFromS3 = async formData => {
  try {
    let response = await Axios.post(`/api/interest/seed/get-videos`, formData)
    return response
  } catch (error) {
    throw error
  }
}

export const addVideos = async formData => {
  try {
    let response = await Axios.post(`/api/interest/seed/add-videos`, formData)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteVideo = async formData => {
  try {
    let response = await Axios.post(`/api/interest/seed/delete`, formData)
    return response
  } catch (error) {
    throw error
  }
}
export const updateVideos = async formData => {
  console.log(
    "🚀 ~ file: interestServices.js:100 ~ updateVideos ~ formData:",
    formData
  )
  try {
    let response = await Axios.post(
      `/api/interest/seed/update-videos`,
      formData
    )
    return response
  } catch (error) {
    throw error
  }
}

export const startNewSession = async formData => {
  try {
    let response = await Axios.post(
      `/api/interest/seed/start-new-session`,
      formData
    )
    return response
  } catch (error) {
    throw error
  }
}

export const getPreviousSessionStatus = async formData => {
  try {
    let response = await Axios.post(
      `/api/interest/previous-session-status`,
      formData
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getBlipContent = async formData => {
  try {
    let response = await Axios.post(`/api/interest/blipContents`, formData)
    return response.data
  } catch (error) {}
}
