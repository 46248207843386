import React from "react"
import { Tag, Pagination } from "antd"
import UserCard from "./userCard"
import "./styles.css"
import "../ContentList/style.css"
import { formatNumber } from "../../util/common"
import NoDataFound from "components/NoDataFound/noDataFound"

export default class UserList extends React.Component {
  render() {
    return (
      <>
        {this.props.total &&
        this.props.userList &&
        this.props.userList.length > 0 ? (
          <div style={{ textAlign: "center" }} className="mb-2">
            <Tag color="geekblue">
              Showing <b>{this.props.userList.length}</b> of{" "}
              <b>{formatNumber(this.props.total)}</b> Users
            </Tag>
          </div>
        ) : (
          ""
        )}
        {this.props.userList.length > 0 ? (
          <div className="user-card-container">
            {this.props.userList.map(user => (
              <div className="user-card" key={user.user_uuid}>
                <UserCard
                  user={user}
                  currentUser={this.props.currentUser}
                  pageType={this.props.pageType}
                />
              </div>
            ))}
          </div>
        ) : (
          <NoDataFound message="No data found for provided filters" />
        )}
        <div className="d-flex justify-content-end pb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={this.props.onPageChange}
            defaultCurrent={this.props.currentPage}
            total={this.props.total < 10000 ? this.props.total : 10000}
            pageSize={this.props.pageSize}
            showSizeChanger={false}
          />
        </div>
      </>
    )
  }
}
