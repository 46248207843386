import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  MenuItemStyles,
} from "react-pro-sidebar"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import {
  authProtectedRoutes,
  authProtectedRoutesModeration,
} from "../../routes/AppRoutes"

import "./styles.css"

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
}

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const SidebarContent = props => {
  const ref = useRef()

  const [collapsed, setCollapsed] = React.useState(false)
  const [toggled, setToggled] = React.useState(false)
  const [broken, setBroken] = React.useState(false)
  const [rtl, setRtl] = React.useState(false)
  const [hasImage, setHasImage] = React.useState(false)
  const [theme, setTheme] = React.useState("light")
  const currentUser = props.currentUser

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      let matchingMenuItem = null
      const ul = document.getElementsByClassName("metismenu")[0]
      if (ul) {
        const items = ul.getElementsByTagName("a")
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i]
            break
          }
        }
        if (matchingMenuItem) {
          activateParentDropdown(matchingMenuItem)
        }
      }
    }
    initMenu()
  }, [props.location.pathname])

  // useEffect(() => {
  //   ref.current.recalculate()
  // })

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  }

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  function getSubMenuNodes(menuItem) {
    let subMenuNodes = []
    let userPermissions =
      currentUser.permissions && currentUser.permissions.length > 0
        ? currentUser.permissions
        : []
    for (let subMenuNode of menuItem.childRoutes) {
      if (
        subMenuNode.showInSidebar &&
        subMenuNode.permissions &&
        subMenuNode.permissions.includes(currentUser.permissions)
      ) {
        subMenuNodes.push(
          <Link to={subMenuNode.path} target="_self">
            <MenuItem active>{subMenuNode.title}</MenuItem>
          </Link>
        )
      } else {
        for (let userPermission of userPermissions) {
          if (
            subMenuNode.showInSidebar &&
            subMenuNode.permissions &&
            subMenuNode.permissions.includes(userPermission)
          ) {
            subMenuNodes.push(
              <Link to={subMenuNode.path} target="_self">
                <MenuItem active>{subMenuNode.title}</MenuItem>
              </Link>
            )
            break
          }
        }
      }
    }
    return subMenuNodes
  }

  function getMenuNode(menuItem) {
    if (menuItem.childRoutes && menuItem.childRoutes.length > 0) {
      let subMenuNodes = getSubMenuNodes(menuItem)
      if (subMenuNodes.length > 0) {
        return (
          // <li key={menuItem.key}>
          //   <Link to="/#" className="has-arrow ">
          //     <i className={menuItem.icon}></i>
          //     <span>{menuItem.title}</span>
          //   </Link>
          //   <ul
          //     key={menuItem.key + "submenu"}
          //     className="sub-menu"
          //     aria-expanded="false"
          //   >

          //   </ul>
          // </li>

          <SubMenu
            label={menuItem.title}
            icon={
              <i className={menuItem.icon} style={{ fontSize: "18px" }}></i>
            }
          >
            {subMenuNodes.map(subMenuNode => subMenuNode)}
          </SubMenu>
        )
      } else {
        return null
      }
    }
  }

  function getMenuItems() {
    const { hostName } = useSelector(state => state.currentUser)

    const routesToRender =
      hostName?.includes("localhost") ||
      hostName?.includes("jot.myjosh.in") ||
      hostName?.includes("jot-gke.myjosh.in")
        ? authProtectedRoutes
        : authProtectedRoutesModeration
    let menuItems = []
    routesToRender.sort((menu, next_menu) => {
      if (menu.title < next_menu.title) {
        return -1
      }
      if (menu.title > next_menu.title) {
        return 1
      }
      return 0
    })
    routesToRender.map(menuItem => {
      let menuNode = getMenuNode(menuItem)
      if (menuNode) {
        menuItems.push(menuNode)
      }
    })
    return menuItems
  }

  if (currentUser) {
    return (
      <React.Fragment>
        <div>
          {/* <MetisMenu id="side-menu" className="list-unstyled"> */}
          <Menu menuItemStyles={menuItemStyles}>
            {getMenuItems().map(menuItem => menuItem)}
          </Menu>
          {/* </MetisMenu> */}
        </div>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(SidebarContent)
