import React from "react"
import { Upload, Divider } from "antd"
import toastr from "toastr"
import _ from "lodash"
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, {
  Select,
  TextField,
  TextArea,
  Switch,
  ChipsInput,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { collectionElements } from "../../constants/uiConstants"
import ColorPicker from "../../components/ColorPicker/colorPicker"
import { Button } from "@mui/material"

const elementTypeOptions = [...collectionElements.NAMED_COLLECTION]
elementTypeOptions.sort((a, b) => {
  if (a.value < b.value) return -1
  return 1
})

const TYPE_TO_KEY_MAP = {
  BANNER: "banner_uuid",
  MUSIC_ARTIST: "music_artist_uuid",
  MUSIC_LABEL: "music_label_uuid",
  MUSIC_PLAYLIST: "music_playlist_uuid",
  NAMED_COLLECTION: "collection_uuid",
}

const TYPE_TO_FORM_KEY_MAP = {
  BANNER: "banner_uuid",
  MUSIC_ARTIST: "entity_uuid",
  MUSIC_LABEL: "entity_uuid",
  MUSIC_PLAYLIST: "entity_uuid",
  NAMED_COLLECTION: "collection_uuid",
}

export default class UpsertEntityForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      formElementType: this.props.data && this.props.data.element_type,
      cta_color: {},
      subtype: (this.props.data && this.props.data.type) || "BANNER",
    }
  }

  _onImageRemove = type => {
    if (type === "static") {
      this.setState({
        staticImageList: [],
        staticImage: null,
      })
    }
    if (type === "animated") {
      this.setState({
        animatedImageList: [],
        animatedImage: null,
      })
    }
    if (type === "static_thumbnail_v2") {
      this.setState({
        static_thumbnail_v2List: [],
        static_thumbnail_v2: null,
      })
    }
    if (type === "animated_thumbnail_v2") {
      this.setState({
        animated_thumbnail_v2List: [],
        animated_thumbnail_v2: null,
      })
    }

    if (type === "logo_file") {
      this.setState({
        logo_fileList: [],
        logo_file: null,
      })
    }
    if (type === "logo_animated_file") {
      this.setState({
        logo_animated_fileList: [],
        logo_animated_file: null,
      })
    }
    if (type === "video") {
      this.setState({
        videoList: [],
        video: null,
      })
    }
    if (type === "icon") {
      this.setState({
        iconImageList: [],
        iconImage: null,
      })
    }
    if (type === "cover") {
      this.setState({
        coverList: [],
        cover: null,
      })
    } else
      this.setState({
        imageList: [],
        image: null,
      })
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "static") {
        this.setState({
          staticImageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          staticImage: fileObj.file,
        })
      }
      if (type === "animated") {
        this.setState({
          animatedImageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          animatedImage: fileObj.file,
        })
      }
      if (type === "static_thumbnail_v2") {
        this.setState({
          static_thumbnail_v2List: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          static_thumbnail_v2: fileObj.file,
        })
      }
      if (type === "animated_thumbnail_v2") {
        this.setState({
          animated_thumbnail_v2List: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          animated_thumbnail_v2: fileObj.file,
        })
      }
      if (type === "logo_file") {
        this.setState({
          logo_fileList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          logo_file: fileObj.file,
        })
      }
      if (type === "logo_animated_file") {
        this.setState({
          logo_animated_fileList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          logo_animated_file: fileObj.file,
        })
      }
      if (type === "cta_icon") {
        this.setState({
          cta_iconList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          cta_icon: fileObj.file,
        })
      }
      if (type === "icon") {
        this.setState({
          iconImageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          iconImage: fileObj.file,
        })
      }
      if (type === "video") {
        this.setState({
          videoList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          video: fileObj.file,
        })
      }
      if (type === "cover") {
        this.setState({
          coverList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          cover: fileObj.file,
        })
      } else
        this.setState({
          imageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          image: fileObj.file,
        })
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Collection Entity`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              {!["NAMED_COLLECTION"].includes(this.props.type) && (
                <Col sm={12} md={6}>
                  <TextField
                    label={"Title"}
                    placeholderLabel="Title"
                    field={"title"}
                    defaultValue={
                      this.props.action === "create"
                        ? ""
                        : this.props.data && this.props.data.title
                    }
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
              )}
              {["CHIP"].includes(this.props.type) && (
                <React.Fragment>
                  <Col sm={12} md={6}>
                    <TextField
                      label={"Element CTA"}
                      placeholderLabel="Element CTA"
                      field={"element_cta"}
                      defaultValue={
                        this.props.action === "create"
                          ? ""
                          : this.props.data && this.props.data.element_cta
                      }
                      className="creator-form"
                    />
                  </Col>
                </React.Fragment>
              )}
              {["WEBVIEW_BANNER"].includes(this.props.type) && (
                <React.Fragment>
                  <Col sm={12} md={6}>
                    <TextField
                      style={{ width: "100%" }}
                      // inputType="Number"
                      label={"Height"}
                      placeholderLabel="Height"
                      field={"height"}
                      defaultValue={
                        this.props.action === "create"
                          ? ""
                          : this.props.data && this.props.data.height
                      }
                      className="creator-form"
                    />
                  </Col>
                  {(this.props.action === "create" ||
                    (_.isEmpty(this.props.data.web_view_rich_text) &&
                      _.isEmpty(this.props.data.web_url))) && (
                    <Col sm={12} md={6}>
                      <Switch
                        label={"Upload Web Image"}
                        onChange={value =>
                          this.setState({
                            isUploadImage: value,
                          })
                        }
                      />
                    </Col>
                  )}
                  {!this.state.isUploadImage &&
                    this.props.action === "create" && (
                      <Col sm={12} md={6}>
                        <TextArea
                          label={"Web View Rich Text"}
                          placeholderLabel="Web View Rich Text"
                          field={"web_view_rich_text"}
                          defaultValue={
                            this.props.action === "create"
                              ? ""
                              : this.props.data &&
                                this.props.data.web_view_rich_text
                          }
                          className="creator-form"
                        />
                      </Col>
                    )}
                  {!this.state.isUploadImage &&
                    this.props.action === "update" &&
                    _.isEmpty(this.props.data.web_view_rich_text) &&
                    _.isEmpty(this.props.data.web_url) && (
                      <Col sm={12} md={6}>
                        <TextArea
                          label={"Web View Rich Text"}
                          placeholderLabel="Web View Rich Text"
                          field={"web_view_rich_text"}
                          defaultValue={
                            this.props.action === "create"
                              ? ""
                              : this.props.data &&
                                this.props.data.web_view_rich_text
                          }
                          className="creator-form"
                        />
                      </Col>
                    )}
                  {this.props.action === "update" &&
                    this.props.data &&
                    _.isEmpty(this.props.data.web_url) &&
                    this.props.data.web_view_rich_text && (
                      <Col sm={12} md={6}>
                        <TextArea
                          label={"Web View Rich Text"}
                          placeholderLabel="Web View Rich Text"
                          field={"web_view_rich_text"}
                          defaultValue={
                            this.props.action === "create"
                              ? ""
                              : this.props.data &&
                                this.props.data.web_view_rich_text
                          }
                          className="creator-form"
                        />
                      </Col>
                    )}
                  {this.props.action === "update" &&
                    this.props.data &&
                    this.props.data.web_url && (
                      <Col sm={12} md={6}>
                        <TextArea
                          disabled={true}
                          label={"Web URL"}
                          defaultValue={this.props.data.web_url}
                          className="creator-form"
                        />
                      </Col>
                    )}
                </React.Fragment>
              )}
              {["MUSIC_ARTIST", "MUSIC_LABEL", "MUSIC_PLAYLIST"].includes(
                this.props.type
              ) && (
                <Col sm={12} md={6}>
                  <TextField
                    label={"Page Title"}
                    placeholderLabel="Page Title"
                    field={"page_title"}
                    className="creator-form"
                    defaultValue={
                      this.props.action === "create"
                        ? ""
                        : this.props.data && this.props.data.page_title
                    }
                  />
                </Col>
              )}
              {["BANNER"].includes(this.props.type) && (
                <Col sm={12} md={6}>
                  <TextField
                    label={"Title Ops"}
                    placeholderLabel="Title Ops"
                    field={"title_ops"}
                    defaultValue={
                      this.props.action === "create"
                        ? ""
                        : this.props.data && this.props.data["title_ops"]
                    }
                    className="creator-form"
                  />
                </Col>
              )}
              {["BANNER"].includes(this.props.type) && (
                <Col sm={12} md={6}>
                  <TextField
                    label={"CTA"}
                    placeholderLabel="Cta"
                    field={"cta"}
                    defaultValue={
                      this.props.action === "create"
                        ? ""
                        : this.props.data && this.props.data.cta
                    }
                    className="creator-form"
                  />
                </Col>
              )}
              {["BANNER"].includes(this.props.type) && (
                <Col sm={12} md={6}>
                  <Select
                    label="Type"
                    placeholderLabel="Type"
                    field="type"
                    allowClear={true}
                    className="creator-form"
                    defaultValue={this.props.data && this.props.data.type}
                    options={[
                      { label: "Banner", value: "BANNER" },
                      { label: "Sub Banner", value: "SUBBANNER" },
                      { label: "Video Banner", value: "VIDEO_BANNER" },
                    ]}
                    onChange={val => this.setState({ subtype: val })}
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
              )}
              {this.props.type === "BANNER" &&
                this.state.subtype &&
                this.state.subtype === "VIDEO_BANNER" && (
                  <Col span={24} className="d-flex">
                    <Col span={12}>
                      <Switch
                        label={"Autoplay"}
                        field={"auto_play"}
                        defaultValue={
                          this.props.data && this.props.data.auto_play
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Switch
                        label={"Mute On Start"}
                        field={"mute_on_start"}
                        defaultValue={
                          this.props.data && this.props.data.mute_on_start
                        }
                      />
                    </Col>
                  </Col>
                )}

              {this.props.type === "BANNER" &&
                this.state.subtype &&
                this.state.subtype !== "VIDEO_BANNER" && (
                  <React.Fragment>
                    <Col sm={12} md={6}>
                      <TextField
                        label={"Sub Title"}
                        placeholderLabel="Sub Title"
                        field={"subtitle"}
                        defaultValue={
                          this.props.action === "create"
                            ? ""
                            : this.props.data && this.props.data.subtitle
                        }
                        className="creator-form"
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <TextField
                        label={"Inline CTA Text"}
                        placeholderLabel="Inline CTA Text"
                        field={"inline_cta_text"}
                        defaultValue={
                          this.props.action === "create"
                            ? ""
                            : this.props.data &&
                              this.props.data.cta_data &&
                              this.props.data.cta_data.inline_cta_text
                        }
                        className="creator-form"
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <TextField
                        label={"Inline CTA"}
                        placeholderLabel="Inline CTA"
                        field={"inline_cta"}
                        defaultValue={
                          this.props.action === "create"
                            ? ""
                            : this.props.data &&
                              this.props.data.cta_data &&
                              this.props.data.cta_data.inline_cta
                        }
                        className="creator-form"
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <ColorPicker
                        label="Text Color"
                        placeholderLabel="Text Color"
                        field="text_color"
                        defaultColor={
                          (this.props.data &&
                            this.props.data.cta_data &&
                            this.props.data.cta_data.text_color) ||
                          "#5154b2"
                        }
                        onColorChange={val =>
                          this.setState({
                            cta_color: {
                              ...this.state.cta_color,
                              text_color: val.hex,
                            },
                          })
                        }
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <ColorPicker
                        label="Background Color"
                        placeholderLabel="Background Color"
                        field="background_color"
                        defaultColor={
                          (this.props.data &&
                            this.props.data.cta_data &&
                            this.props.data.cta_data.background_color) ||
                          "#5154b2"
                        }
                        onColorChange={val =>
                          this.setState({
                            cta_color: {
                              ...this.state.cta_color,
                              background_color: val.hex,
                            },
                          })
                        }
                      />
                    </Col>
                    {this.props.type !== "BANNER" && (
                      <Col sm="12" md="6">
                        <Switch
                          label={"Trending"}
                          field={"is_trending"}
                          defaultValue={
                            this.props.data && this.props.data.is_trending
                          }
                        />
                      </Col>
                    )}
                  </React.Fragment>
                )}
              {this.props.type === "BANNER" &&
                this.state.subtype &&
                this.state.subtype === "BANNER" && (
                  <React.Fragment>
                    <Col sm="12" md="6">
                      <Switch
                        label={"Verified"}
                        field={"is_verified"}
                        defaultValue={
                          this.props.data && this.props.data.is_verified
                        }
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <TextField
                        label={"Label"}
                        placeholderLabel="Label"
                        field={"label"}
                        defaultValue={
                          this.props.action === "create"
                            ? ""
                            : this.props.data && this.props.data.label
                        }
                        className="creator-form"
                      />
                    </Col>
                  </React.Fragment>
                )}

              {["BANNER"].includes(this.props.type) && (
                <div>
                  <Col sm="12" md="6">
                    <Row style={{ marginTop: "10px" }}>
                      <Upload
                        showUploadList={true}
                        onRemove={() => this._onImageRemove("static")}
                        defaultFileList={this.state.staticImageList}
                        fileList={this.state.staticImageList}
                        customRequest={fileObj =>
                          this._uploadImage(fileObj, "static")
                        }
                      >
                        <Button variant="contained" onClick={() => {}}>
                          <i className="fas fa-upload" />
                          &nbsp; Upload static image
                        </Button>
                      </Upload>
                    </Row>
                  </Col>
                  {this.props.type === "BANNER" &&
                    this.state.subtype &&
                    this.state.subtype !== "VIDEO_BANNER" && (
                      <React.Fragment>
                        <Col sm="12" md="6">
                          <Row style={{ marginTop: "10px" }}>
                            <Upload
                              showUploadList={true}
                              onRemove={() => this._onImageRemove("animated")}
                              defaultFileList={this.state.animatedImageList}
                              fileList={this.state.animatedImageList}
                              customRequest={fileObj =>
                                this._uploadImage(fileObj, "animated")
                              }
                            >
                              <Button variant="contained" onClick={() => {}}>
                                <i className="fas fa-upload" />
                                &nbsp; Upload animated image
                              </Button>
                            </Upload>
                          </Row>
                        </Col>
                        <Col sm="12" md="6">
                          <Row style={{ marginTop: "10px" }}>
                            <Upload
                              showUploadList={true}
                              onRemove={() => this._onImageRemove("icon")}
                              defaultFileList={this.state.iconImageList}
                              fileList={this.state.iconImageList}
                              customRequest={fileObj =>
                                this._uploadImage(fileObj, "icon")
                              }
                            >
                              <Button variant="contained" onClick={() => {}}>
                                <i className="fas fa-upload" />
                                &nbsp; Upload Icon Image
                              </Button>
                            </Upload>
                          </Row>
                        </Col>
                      </React.Fragment>
                    )}

                  <Divider>Version 2</Divider>
                  <Row style={{ marginTop: "10px" }}>
                    <Col sm={12} md={6}>
                      <TextField
                        label={"Custom Text"}
                        placeholderLabel="Custom Text"
                        field={"custom_text"}
                        defaultValue={
                          this.props.action === "create"
                            ? ""
                            : this.props.data && this.props.data.custom_text
                        }
                        className="creator-form"
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <TextField
                        label={"Chip Text"}
                        placeholderLabel="Chip Text"
                        field={"chip_text"}
                        defaultValue={
                          this.props.action === "create"
                            ? ""
                            : this.props.data && this.props.data.chip_text
                        }
                        className="creator-form"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <TextField
                        label={"Challenge Id"}
                        placeholderLabel="Challenge Id"
                        field={"challenge_id"}
                        defaultValue={
                          this.props.action === "create"
                            ? ""
                            : this.props.data && this.props.data.challenge_id
                        }
                        className="creator-form"
                        validations={["required"]}
                        errors={["This field is required"]}
                      />
                    </Col>
                  </Row>

                  <Col sm="12" md="6">
                    <Row style={{ marginTop: "10px" }}>
                      <Upload
                        showUploadList={true}
                        onRemove={() =>
                          this._onImageRemove("static_thumbnail_v2")
                        }
                        defaultFileList={this.state.static_thumbnail_v2List}
                        fileList={this.state.static_thumbnail_v2List}
                        customRequest={fileObj =>
                          this._uploadImage(fileObj, "static_thumbnail_v2")
                        }
                      >
                        <Button variant="contained" onClick={() => {}}>
                          <i className="fas fa-upload" />
                          &nbsp; Upload static thumbnail
                        </Button>
                      </Upload>
                    </Row>
                    {this.state.subtype &&
                      this.state.subtype !== "VIDEO_BANNER" && (
                        <Row style={{ marginTop: "10px" }}>
                          <Upload
                            showUploadList={true}
                            onRemove={() =>
                              this._onImageRemove("animated_thumbnail_v2")
                            }
                            defaultFileList={
                              this.state.sanimated_thumbnail_v2List
                            }
                            fileList={this.state.animated_thumbnail_v2List}
                            customRequest={fileObj =>
                              this._uploadImage(
                                fileObj,
                                "animated_thumbnail_v2"
                              )
                            }
                          >
                            <Button variant="contained" onClick={() => {}}>
                              <i className="fas fa-upload" />
                              &nbsp; Upload animated Thumbnail
                            </Button>
                          </Upload>
                        </Row>
                      )}
                    {this.state.subtype &&
                      this.state.subtype !== "VIDEO_BANNER" && (
                        <Row style={{ marginTop: "10px" }}>
                          <Upload
                            showUploadList={true}
                            onRemove={() => this._onImageRemove("cta_icon")}
                            defaultFileList={this.state.cta_iconList}
                            fileList={this.state.cta_iconList}
                            customRequest={fileObj =>
                              this._uploadImage(fileObj, "cta_icon")
                            }
                          >
                            <Button variant="contained" onClick={() => {}}>
                              <i className="fas fa-upload" />
                              &nbsp; Upload Inline CTA Icon
                            </Button>
                          </Upload>
                        </Row>
                      )}
                    {this.state.subtype &&
                      this.state.subtype !== "VIDEO_BANNER" && (
                        <Row style={{ marginTop: "10px" }}>
                          <Upload
                            showUploadList={true}
                            onRemove={() => this._onImageRemove("logo_file")}
                            defaultFileList={this.state.logo_fileList}
                            fileList={this.state.logo_fileList}
                            customRequest={fileObj =>
                              this._uploadImage(fileObj, "logo_file")
                            }
                          >
                            <Button variant="contained" onClick={() => {}}>
                              <i className="fas fa-upload" />
                              &nbsp; Upload Logo
                            </Button>
                          </Upload>
                        </Row>
                      )}
                    {this.state.subtype &&
                      this.state.subtype !== "VIDEO_BANNER" && (
                        <Row style={{ marginTop: "10px" }}>
                          <Upload
                            showUploadList={true}
                            onRemove={() =>
                              this._onImageRemove("logo_animated_file")
                            }
                            defaultFileList={this.state.logo_animated_fileList}
                            fileList={this.state.logo_animated_fileList}
                            customRequest={fileObj =>
                              this._uploadImage(fileObj, "logo_animated_file")
                            }
                          >
                            <Button variant="contained" onClick={() => {}}>
                              <i className="fas fa-upload" />
                              &nbsp; Upload Animated Logo
                            </Button>
                          </Upload>
                        </Row>
                      )}
                    {this.props.type === "BANNER" && (
                      <Col sm="12" md="6">
                        <Row style={{ marginTop: "10px" }}>
                          <Upload
                            showUploadList={true}
                            onRemove={() => this._onImageRemove("video")}
                            defaultFileList={this.state.videoList}
                            fileList={this.state.videoList}
                            customRequest={fileObj =>
                              this._uploadImage(fileObj, "video")
                            }
                          >
                            <Button variant="contained" onClick={() => {}}>
                              <i className="fas fa-upload" />
                              &nbsp; Upload Video
                            </Button>
                          </Upload>
                        </Row>
                      </Col>
                    )}
                  </Col>
                </div>
              )}
              {["MUSIC_LABEL"].includes(this.props.type) && (
                <Col sm="12" md="6">
                  <TextField
                    label={"Audio Max Duration"}
                    placeholderLabel="Audio Max Duration (In milliseconds)"
                    field={"max_selection_duration_ms"}
                    onChange={val => {
                      this.setState({
                        max_selection_duration_ms: Math.round(val),
                      })
                    }}
                    defaultValue={
                      this.props.data &&
                      this.props.data.max_selection_duration_ms
                        ? this.props.data.max_selection_duration_ms
                        : ""
                    }
                    value={this.state.max_selection_duration_ms}
                    className="creator-form"
                  />
                </Col>
              )}
              {["MUSIC_ARTIST", "MUSIC_LABEL", "MUSIC_PLAYLIST"].includes(
                this.props.type
              ) && (
                <Col sm="12" md="6">
                  <TextArea
                    label={"Feed API Config"}
                    placeholderLabel="Feed API Config"
                    field={"feed_api_config"}
                    defaultValue={
                      this.props.action === "create"
                        ? ""
                        : this.props.data && this.props.data.feed_api_config
                    }
                    className="creator-form"
                  />
                </Col>
              )}
              {["MUSIC_ARTIST", "MUSIC_LABEL", "MUSIC_PLAYLIST"].includes(
                this.props.type
              ) && (
                <Col sm={12} md={6}>
                  <ChipsInput
                    label={"Tagged Videos"}
                    placeholderLabel="Tagged Videos"
                    field={"tagged_videos"}
                    defaultValue={
                      this.props.action === "create"
                        ? []
                        : this.props.data && this.props.data.tagged_videos
                    }
                    className="creator-form"
                  />
                </Col>
              )}
              {["MUSIC_ARTIST", "MUSIC_LABEL", "MUSIC_PLAYLIST"].includes(
                this.props.type
              ) && (
                <React.Fragment>
                  {this.props.type !== "MUSIC_PLAYLIST" && (
                    <React.Fragment>
                      <Col sm={12} md={6}>
                        <TextArea
                          label={"Share Link"}
                          placeholderLabel="Share Link"
                          field={"share_link"}
                          defaultValue={
                            this.props.action === "create"
                              ? ""
                              : this.props.data && this.props.data.share_link
                          }
                          className="creator-form"
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <TextArea
                          label={"Share Text"}
                          placeholderLabel="Share Text"
                          field={"share_text"}
                          defaultValue={
                            this.props.action === "create"
                              ? ""
                              : this.props.data && this.props.data.share_text
                          }
                          className="creator-form"
                        />
                      </Col>
                      <Col sm="12" md="12">
                        <Row style={{ marginTop: "10px" }}>
                          <Upload
                            showUploadList={true}
                            onRemove={() => this._onImageRemove("cover")}
                            defaultFileList={this.state.coverList}
                            fileList={this.state.coverList}
                            customRequest={fileObj =>
                              this._uploadImage(fileObj, "cover")
                            }
                          >
                            <Button variant="contained" onClick={() => {}}>
                              <i className="fas fa-upload" />
                              &nbsp;Upload Background Cover
                            </Button>
                          </Upload>
                        </Row>
                      </Col>
                    </React.Fragment>
                  )}
                  <Col sm="12" md="12">
                    <Row style={{ marginTop: "10px" }}>
                      <Upload
                        showUploadList={true}
                        onRemove={() => this._onImageRemove("")}
                        defaultFileList={this.state.imageList}
                        fileList={this.state.imageList}
                        customRequest={fileObj =>
                          this._uploadImage(fileObj, "")
                        }
                      >
                        <Button variant="contained" onClick={() => {}}>
                          <i className="fas fa-upload" />
                          &nbsp; Upload Image
                        </Button>
                      </Upload>
                    </Row>
                  </Col>
                </React.Fragment>
              )}
              {["CHIP"].includes(this.props.type) && (
                <Row style={{ marginTop: "10px" }}>
                  <Upload
                    showUploadList={true}
                    onRemove={() => this._onImageRemove("")}
                    defaultFileList={this.state.imageList}
                    fileList={this.state.imageList}
                    customRequest={fileObj => this._uploadImage(fileObj, "")}
                  >
                    <Button variant="contained" onClick={() => {}}>
                      <i className="fas fa-upload" />
                      &nbsp;{" "}
                      {this.props.type === "CHIP"
                        ? "Upload Icon"
                        : "Upload Web Image"}
                    </Button>
                  </Upload>
                </Row>
              )}
              {((["WEBVIEW_BANNER"].includes(this.props.type) &&
                this.state.isUploadImage) ||
                (this.props.action === "update" &&
                  this.props.data &&
                  this.props.data.web_url)) && (
                <Row style={{ marginTop: "10px" }}>
                  <Upload
                    showUploadList={true}
                    onRemove={() => this._onImageRemove("")}
                    defaultFileList={this.state.imageList}
                    fileList={this.state.imageList}
                    customRequest={fileObj => this._uploadImage(fileObj, "")}
                  >
                    <Button variant="contained" onClick={() => {}}>
                      <i className="fas fa-upload" />
                      &nbsp;{" "}
                      {this.props.type === "CHIP"
                        ? "Upload Icon"
                        : "Upload Web Image"}
                    </Button>
                  </Upload>
                </Row>
              )}
              {this.props.type === "NAMED_COLLECTION" && (
                <React.Fragment>
                  <Col sm={12} md={6}>
                    <TextField
                      label={"Title"}
                      placeholderLabel="Title"
                      field={"title"}
                      defaultValue={
                        this.props.action === "create"
                          ? ""
                          : this.props.data && this.props.data.title
                      }
                      className="creator-form"
                      required="true"
                      validations={["required"]}
                      errors={["This field is required"]}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <TextField
                      label={"Page Title"}
                      placeholderLabel="Page Title"
                      field={"page_title"}
                      defaultValue={
                        this.props.action === "create"
                          ? ""
                          : this.props.data && this.props.data.page_title
                      }
                      className="creator-form"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <Select
                      label="Element Type"
                      placeholderLabel="Element Type"
                      field="element_type"
                      allowClear={true}
                      className="creator-form"
                      defaultValue={
                        this.props.data && this.props.data.element_type
                      }
                      options={elementTypeOptions}
                      validations={["required"]}
                      errors={["This field is required"]}
                      onChange={val => this.setState({ formElementType: val })}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <TextField
                      label={"Heading Cta"}
                      placeholderLabel="Heading Cta"
                      field={"heading_cta"}
                      defaultValue={
                        this.props.action === "create"
                          ? ""
                          : this.props.data && this.props.data.heading_cta
                      }
                      className="creator-form"
                    />
                  </Col>
                  {this.state.formElementType === "COMBINATION" && (
                    <Col sm={12} md={6}>
                      <Select
                        label="Tab Type"
                        placeholderLabel="Tab Type"
                        field="tab_type"
                        allowClear={true}
                        defaultValue={
                          this.props.data && this.props.data.tab_type
                        }
                        options={[
                          { value: "LOCAL_CLEAR", label: "Local Clear" },
                          { value: "LOCAL_RECENT", label: "Local Recent" },
                          { value: "REMOTE", label: "Remote" },
                        ]}
                      />
                    </Col>
                  )}
                  {this.state.formElementType &&
                    ["VIDEO", "IMAGE", "AUDIO_STORY"].includes(
                      this.state.formElementType
                    ) && (
                      <Col sm={12} md={6}>
                        <Select
                          label="Type"
                          placeholderLabel="Type"
                          field="type"
                          allowClear={true}
                          className="creator-form"
                          defaultValue={this.props.data && this.props.data.type}
                          options={[
                            { value: "AUDIO_STORY", label: "Audio Story" },
                            { value: "DUET", label: "Duet" },
                            { value: "GREETING", label: "Greeting" },
                            { value: "SHOPPABLE", label: "Shoppable" },
                          ]}
                        />
                      </Col>
                    )}
                </React.Fragment>
              )}

              {this.state.formElementType &&
                ["VIDEO", "MUSIC"].includes(this.state.formElementType) && (
                  <Col sm={12} md={6}>
                    <TextArea
                      label={"Feed API Config"}
                      placeholderLabel="Feed API Config"
                      field={"feed_api_config"}
                      defaultValue={
                        this.props.action === "create"
                          ? ""
                          : this.props.data && this.props.data.feed_api_config
                      }
                      className="creator-form"
                    />
                  </Col>
                )}
            </Row>
            <Row>
              <Col>
                <div
                  className="flex justify-content-end"
                  style={{ marginTop: "10px" }}
                >
                  <div className="d-flex">
                    <Button type="submit" variant="contained">
                      {this.props.action === "update" ? "Update" : "Create"}
                    </Button>
                    <Button
                      onClick={() => this.props.onCancel()}
                      color="error"
                      variant="contained"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (this.props.type === "BANNER" && formData && _.isEmpty(formData.cta)) {
      toastr.error("CTA is mandatory")
      return
    }

    if (this.props.type === "MUSIC_LABEL") {
      if (
        formData?.max_selection_duration_ms &&
        formData?.max_selection_duration_ms < 10000
      ) {
        toastr.error("Duration can not be less than 10000 milliseconds !!")
        return
      }
    }

    if (
      formData &&
      formData.cta &&
      !(
        formData.cta.startsWith("http://") ||
        formData.cta.startsWith("https://")
      )
    ) {
      toastr.error("The CTA must start with http:// or https:// request")
      return
    }
    if (
      formData &&
      formData.heading_cta &&
      !(
        formData.heading_cta.startsWith("http://") ||
        formData.heading_cta.startsWith("https://")
      )
    ) {
      toastr.error("The CTA must start with http:// or https:// request")
      return
    }
    if (this.props.action == "create") {
      if (
        this.props.type === "BANNER" &&
        this.state?.static_thumbnail_v2 != undefined &&
        this.state?.staticImage == undefined
      ) {
        toastr.error("Static Image is mandatory")
        return
      }
      if (
        this.props.type === "BANNER" &&
        this.state?.staticImage != undefined &&
        this.state?.static_thumbnail_v2 == undefined
      ) {
        toastr.error("Static Thumbnail is mandatory")
        return
      }
      if (
        this.props.type === "BANNER" &&
        this.state?.animated_thumbnail_v2 != undefined &&
        this.state?.animatedImage == undefined
      ) {
        toastr.error("Animated Image is mandatory")
        return
      }
      if (
        this.props.type === "BANNER" &&
        this.state?.animatedImage != undefined &&
        this.state?.animated_thumbnail_v2 == undefined
      ) {
        toastr.error("Animated Thumbnail is mandatory")
        return
      }
      if (
        this.props.type === "BANNER" &&
        this.state?.animated_thumbnail_v2 == undefined &&
        this.state?.animatedImage == undefined &&
        this.state?.staticImage == undefined &&
        this.state?.static_thumbnail_v2 == undefined
      ) {
        toastr.error("At least one image/animated file is mandatory")
        return
      }
    }

    let data
    let modifiedData = formData

    if (this.props.action === "update" && TYPE_TO_KEY_MAP[this.props.type]) {
      modifiedData[TYPE_TO_FORM_KEY_MAP[this.props.type]] =
        this.props.data[TYPE_TO_KEY_MAP[this.props.type]]
    }

    if (this.props.type === "BANNER") {
      data = new FormData()
      let modifiedData = formData
      if (this.props.action === "update") {
        modifiedData["banner_uuid"] = this.props.data.banner_uuid
      }
      data.append("payload", JSON.stringify(modifiedData))
      data.append("file1", this.state.staticImage)
      data.append("file2", this.state.animatedImage)
      data.append("file3", this.state.video)
      data.append("file4", this.state.iconImage)
      data.append("file5", this.state.static_thumbnail_v2)
      data.append("file6", this.state.animated_thumbnail_v2)
      data.append("file7", this.state.cta_icon)
      data.append("file8", this.state.logo_file)
      data.append("file9", this.state.logo_animated_file)
    }

    if (this.props.type === "CHIP") {
      data = new FormData()
      let modifiedData = formData
      if (this.props.action === "update") {
        modifiedData["chip_uuid"] = this.props.data.chip_uuid
      }
      data.append("payload", JSON.stringify(modifiedData))
      data.append("file1", this.state.image)
    }

    if (this.props.type === "WEBVIEW_BANNER") {
      data = new FormData()
      let modifiedData = formData
      if (this.props.action === "update") {
        modifiedData["webview_uuid"] = this.props.data.banner_uuid
      }
      data.append("payload", JSON.stringify(modifiedData))
      data.append("file1", this.state.image)
    }

    if (
      [
        "MUSIC_ARTIST",
        "MUSIC_LABEL",
        "MUSIC_PLAYLIST",
        // "WEBVIEW_BANNER",
      ].includes(this.props.type)
    ) {
      data = new FormData()
      let modifiedData = formData
      if (this.state.max_selection_duration_ms) {
        modifiedData = {
          ...formData,
          max_selection_duration_ms: this.state.max_selection_duration_ms,
        }
      }
      data.append("payload", JSON.stringify(modifiedData))
      data.append("file1", this.state.image)
      data.append("file5", this.state.coverV2)
      if (this.props.type !== "MUSIC_PLAYLIST") {
        data.append("file2", this.state.cover)
      }
    }

    if (this.props.type === "NAMED_COLLECTION") {
      data = formData
    }

    this.props.onSubmit(data)
  }

  _render = () => {
    return this._form()
  }
}
