import React, { Component, createRef } from "react"
import { Button } from "@mui/material"
import { MdAdd, MdCheck } from "react-icons/md"
import { Col, Row } from "reactstrap"
import { Input, Select, Checkbox, Form } from "antd"
import { CONTENT_TYPE, LANGUAGES } from "../../constants/uiConstants"
const { TextArea } = Input
const { Option } = Select
import _ from "lodash"
import {
  getVideosFromS3,
  addVideos,
  startNewSession,
  getInterest,
  updateVideos,
  deleteVideo,
} from "clientServices/interestServices"
import toastr from "toastr"
import Auxiliary from "util/Auxiliary"
import { BsFillPlayFill, BsFillTrashFill } from "react-icons/bs"
import { MdOutlineArrowBackIosNew } from "react-icons/md"
import { BiCut, BiCrop } from "react-icons/bi"
import { FaExpandAlt } from "react-icons/fa"
import { Chip } from "@mui/material"
import { MultiSelect } from "containers/ValidatorForm"

export default class ImportVideos extends Component {
  state = {
    videos: this.props.videos || [],
    formData: {
      lang_code: "hi",
      // category: "viral",
      ce_type: "VIDEO",
      seed_video: "Y",
      subInterest: "",
      interest: "",
      mode: "",
      bucket: "",
      source: "LOCAL_UPLOAD",
    },
    formDisabled: true,
  }

  async componentDidMount() {
    this.setState({ loading: true })
    if (this.props.type === "create") {
      const data = await getVideosFromS3({
        interest: this.props.interest.id,
        subInterest: this.props.subInterest,
      })
      let videos = _.cloneDeep(this.state.videos)

      videos = [...data.data.data]
      this.setState({
        videos,
      })
    } else {
      const subinterestdata = await getInterest({ entity_type: "sub_interest" })

      let subintereststatus = {}
      for (let j = 0; j < subinterestdata?.result?.data?.length; j++) {
        subintereststatus[subinterestdata?.result?.data[j].id] = {
          status: subinterestdata?.result?.data[j].is_active,
          name: subinterestdata?.result?.data[j].name,
        }
      }
      this.setState({
        subInterestStatusList: subintereststatus || {},
      })
    }
    this.setState({
      loading: false,
    })
  }

  _handleVideoSelect = video => {
    const videos = _.cloneDeep(this.state.videos)
    let count = 0
    videos.forEach(item => {
      if (item.content_uuid === video.content_uuid) {
        item.selected = !item.selected
      }
      if (item.selected) count++
    })

    this.setState({
      videos,
    })

    if (count > 0) {
      this.setState({ formDisabled: false })
    } else {
      this.setState({ formDisabled: true })
    }
  }

  _playVideo = (videoRef, video) => {
    // videoRef.current.playbackRate = playbackRate
    const videos = _.cloneDeep(this.state.videos)
    videos.forEach(item => {
      if (item.content_uuid === video.content_uuid) {
        item.isPlaying = true
      }
    })

    this.setState({
      videos,
    })

    videoRef.current.play()
    videoRef.current.volume = 0.4
    videoRef.current.controls = true
  }

  _renderVideo = video => {
    const videoRef = createRef()
    return (
      <div
        className="seed-video-card"
        style={{
          border: video.selected ? "3px solid #1976d2" : "",
          boxShadow: video.is_seed_video ? "0 0 6px 6px #48abe0" : "",
        }}
        onClick={() => this._handleVideoSelect(video)}
      >
        <video
          src={video.preview_url || video.mp4_url}
          height="460px"
          width="100%"
          style={{ objectFit: "contain" }}
          muted
          ref={videoRef}
          autoPlay={false}
        />
        {!video.isPlaying && (
          <div
            className="seed-video-play-button"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              this._playVideo(videoRef, video)
            }}
          >
            <BsFillPlayFill size={80} color="#ffffff" />
          </div>
        )}
        <div
          className="seed-video-expand m-1"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            // this._playVideo(videoRef, video)
          }}
        >
          {/* <FaExpandAlt size={30} color="#ffffff" /> */}
        </div>
        <div
          className="d-flex justify-content-around"
          style={{
            position: "absolute",
            // left: "65px",
            width: "100%",
            bottom: "60px",
          }}
        >
          <div>
            {video.pixel_size && (
              <Chip
                label={`${video?.pixel_size.split("x")[1]}p`}
                style={{ backgroundColor: "#1976d2" }}
                color="primary"
              />
            )}
          </div>
          <div>
            {video.video_duration && (
              <Chip
                label={new Date(video?.video_duration * 1000)
                  .toISOString()
                  .slice(14, 19)}
                style={{ backgroundColor: "#1976d2" }}
                color="primary"
              />
            )}
          </div>
        </div>
        {/* {this.props.type === "create" && (
          <div className="d-flex justify-content-between m-1">
            <Button
              variant="outlined"
              color="error"
              style={{ width: "100%" }}
              size="large"
              startIcon={<BiCut />}
            >
              Trim
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="error"
              style={{ width: "100%" }}
              className="px-4"
              startIcon={<BiCrop />}
            >
              Crop
            </Button>
          </div>
        )} */}
      </div>
    )
  }

  _renderVideos = () => {
    return (
      <div className="seed-videos-container">
        {this.state?.videos?.map(item => this._renderVideo(item))}
      </div>
    )
  }
  _handleDeleteFiles = async () => {
    try {
      const delvideos = this.state.videos.filter(item => item.selected)
      const data2 = await deleteVideo({
        videos: delvideos,
        interest: this.props.interest.id,
        subInterest: this.props.subInterest,
      })
      toastr.success("Action successful...")
      const data = await getVideosFromS3({
        interest: this.props.interest.id,
        subInterest: this.props.subInterest,
      })
      let videos = _.cloneDeep(this.state.videos)

      videos = [...data.data.data]
      this.setState({
        videos,
      })
    } catch (error) {
      toastr.error(error?.response?.data?.message || "Action failed")
    }
  }
  _handleFormSubmission = async () => {
    const videos = this.state.videos.filter(item => item.selected)
    try {
      toastr.info("Action in progress...")
      if (this.props.type === "create") {
        let formData = { ...this.state.formData }
        formData.interest = this.props.interest.id
        formData.subInterest = this.props.subInterest
        const data = await addVideos({
          videos,
          tab: this.props.tab,
          formData: formData,
          interest: this.props.interest.id,
          subInterest: this.props.subInterest,
        })
      } else {
        const data = await updateVideos({
          videos,
          tab: this.props.tab,
          formData: this.state.formData,
          interest: this.state.interest,
          subInterest: this.state.subInterest,
        })
      }
      toastr.clear()
      toastr.success("Action successful...")
      this.props.closeImportVideos()
    } catch (error) {
      toastr.error(error?.response?.data?.message || "Action failed")
    }
  }

  _handleFieldsChange = (value, field) => {
    const formData = _.cloneDeep(this.state.formData)
    if (field === "selectAll") {
      const videos = _.cloneDeep(this.state.videos)
      videos.forEach(item => {
        item.selected = value
      })
      let formDisabled = true
      if (value && videos.length > 0) {
        formDisabled = false
      }
      this.setState({ videos, formDisabled })
    }
    if (field === "mode" || field === "bucket") {
      formData.interest = ""
      formData.subInterest = ""
    }
    formData[field] = value

    this.setState({
      formData,
    })
  }

  _renderForm = () => {
    return (
      <Form
        layout="vertical"
        autoComplete="false"
        disabled={this.state.formDisabled}
      >
        <Form.Item>
          <Checkbox
            size="large"
            onChange={e => {
              this._handleFieldsChange(e.target.checked, "selectAll")
            }}
            disabled={false}
            // checked={props.filters["isAssetUsed"]}
          >
            Select All
          </Checkbox>
          {this.props.type === "create" && (
            <Button
              onClick={this._handleDeleteFiles}
              variant="contained"
              color="primary"
              size="small"
              // size="small"
              startIcon={<BsFillTrashFill />}
              style={{
                width: "30%",
                float: "right",
                backgroundColor: "#d2302f",
              }}
            >
              Delete
            </Button>
          )}
        </Form.Item>

        <Form.Item label="Description">
          <TextArea
            size="large"
            rows={3}
            placeholder="Add description"
            onChange={e => this._handleFieldsChange(e.target.value, "title")}
          />
        </Form.Item>
        <Form.Item label="Hashtags">
          <Input
            // defaultValue={props.filters["audioId"]}
            size="large"
            placeholder={"Add hashtags..."}
            onChange={e => this._handleFieldsChange(e.target.value, "hashtags")}
            allowClear={true}
            name="audioId"
            style={{ width: "100%" }}
            value={this.state.formData.hashtags}
          />
        </Form.Item>

        <Form.Item label="Language">
          <Select
            // mode="multiple"
            showSearch
            size="large"
            allowClear={true}
            // placeholder="Include Languages"
            // defaultValue={props.filters["includeLang"]}
            onChange={value => {
              this._handleFieldsChange(value, "lang_code")
            }}
            value={this.state.formData.lang_code}
            style={{ width: "100%" }}
          >
            {LANGUAGES.map(lang => (
              <Option value={lang.value}>{lang.label}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Target Languages">
          <Select
            mode="multiple"
            showSearch
            size="large"
            allowClear={true}
            // placeholder="Include Languages"
            // defaultValue={props.filters["includeLang"]}
            onChange={e => this._handleFieldsChange(e, "cms_target_langs")}
            style={{ width: "100%" }}
          >
            {LANGUAGES.map(lang => (
              <Option value={lang.value}>{lang.label}</Option>
            ))}
          </Select>
        </Form.Item>
        {this.props.type === "create" && (
          <Form.Item label="Specific UserID">
            <Input
              label={"Specific UserID"}
              size="large"
              placeholder="Leave empty for random..."
              field={"user_uuid"}
              // validations={["required"]}
              // errors={["This field is required"]}
              onChange={e =>
                this._handleFieldsChange(e.target.value, "user_uuid")
              }
            />
          </Form.Item>
        )}
        {/* <Form.Item label="Category">
          <Select
            label={"Category"}
            placeholderLabel="Category"
            field="categoryTitle"
            onChange={value => {
              this._handleFieldsChange(value, "categoryTitle")
            }}
            value={this.state.formData.categoryTitle}
            // validations={["required"]}
            // errors={["This field is required"]}
          >
            {Categories.map(item => (
              <Option value={item.category_name}>{item.category_name}</Option>
            ))}
          </Select>
        </Form.Item> */}
        {/* <Form.Item label="Type">
          <Select
            label={"Type"}
            size="large"
            placeholderLabel="Type"
            field="ce_type"
            // validations={["required"]}
            // errors={["This field is required"]}
            value={this.state.formData.ce_type}
            onChange={value => {
              this._handleFieldsChange(value, "ce_type")
            }}
          >
            {CONTENT_TYPE.map(item => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item label="Mode">
          <Select
            // mode="multiple"
            showSearch
            size="large"
            allowClear={true}
            // placeholder="Include Languages"
            // defaultValue={props.filters["includeLang"]}
            onChange={async value => {
              this._handleFieldsChange(value, "mode")
              const interestdata = await getInterest({
                entity_type: "interest",
                mode: value,
                type: this.state.formData.mode,
              })
              this.setState({
                interestData: interestdata?.result?.data || [],
              })
            }}
            defaultValue={
              this.props.type === "create"
                ? this.props.interest.mode
                : this.state.formData.mode && ""
            }
            style={{ width: "100%" }}
            disabled={this.props.type === "create"}
          >
            <Option value="">---Choose Mode---</Option>
            <Option value="PUBLIC">Public Mode</Option>
            <Option value="PRIVATE">Private Mode</Option>
          </Select>
        </Form.Item>
        {this.props.type !== "create" && (
          <Form.Item label="Bucket">
            <Select
              // mode="multiple"
              showSearch
              size="large"
              allowClear={true}
              // placeholder="Include Languages"
              // defaultValue={props.filters["includeLang"]}
              onChange={async value => {
                this._handleFieldsChange(value, "bucket")
                const interestdata = await getInterest({
                  entity_type: "interest",
                  mode: this.state.formData.mode,
                  type: value,
                })
                this.setState({
                  interestData: interestdata?.result?.data || [],
                })
              }}
              defaultValue={
                this.props.type === "create"
                  ? this.props.interest.mode
                  : this.state.formData.bucket && ""
              }
              style={{ width: "100%" }}
              disabled={this.props.type === "create"}
            >
              <Option value="">---Choose Bucket---</Option>
              <Option value="CATEGORY">Category</Option>
              <Option value="TREND">Trend</Option>
              <Option value="EVENT">Event</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Interest">
          <Select
            // mode="multiple"
            // showSearch
            // allowClear={true}
            // placeholder="Include Languages"
            // defaultValue={props.filters["includeLang"]}
            // onChange={value => {
            //   this._handleFieldsChange(value, "interest_mode")
            // }}
            onChange={async value => {
              this._handleFieldsChange(value, "interest")
              const subinterestData = await getInterest({
                entity_type: "interest",
                id: value,
              })
              this.setState({
                subinterestData:
                  subinterestData?.result?.data[0]?.subInterests || [],
              })
            }}
            size="large"
            // placeholder="---Choose Interest---"
            value={
              this.props.type === "create"
                ? this.props.interest.name
                : this.state.formData.interest
            }
            style={{ width: "100%" }}
            disabled={this.props.type === "create"}
          >
            {this.props.type === "create" ? (
              <Option value={this.props.interest.name}>
                {this.props.interest.name}
              </Option>
            ) : (
              <>
                <Option value="">---Choose Interest---</Option>
                {this.state.interestData?.map(item => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
              </>
            )}
            {/* <Option value="PRIVATE">Private Mode</Option> */}
          </Select>
        </Form.Item>
        <Form.Item label="Sub-Interest">
          <Select
            // mode="multiple"
            // showSearch
            // allowClear={true}
            // placeholder="Include Languages"
            // defaultValue={props.filters["includeLang"]}
            onChange={value => {
              this._handleFieldsChange(value, "subInterest")
            }}
            value={
              this.props.type === "create"
                ? this.props.subInterestName
                : this.state.formData.subInterest
            }
            style={{ width: "100%" }}
            disabled={this.props.type === "create"}
            size="large"
          >
            {this.props.type === "create" ? (
              <Option value={this.props.subInterestName}>
                {this.props.subInterestName}
              </Option>
            ) : (
              <>
                <Option value="">---Choose subInterest---</Option>
                {this.state.subinterestData?.map(item => (
                  <Option value={item}>
                    {this.state.subInterestStatusList[item].name}
                  </Option>
                ))}
              </>
            )}
          </Select>
        </Form.Item>
        {this.props.tab == "addTabVideo" && (
          <Form.Item label="Mark As Seed Video">
            <Select
              // mode="multiple"
              showSearch
              size="large"
              allowClear={true}
              // placeholder="Include Languages"
              // defaultValue={props.filters["includeLang"]}
              onChange={value => {
                this._handleFieldsChange(value, "seed_video")
              }}
              value={this.state.formData.seed_video}
              style={{ width: "100%" }}
            >
              <Option value="Y">Yes</Option>
              <Option value="N">No</Option>
            </Select>
          </Form.Item>
        )}

        <Button
          onClick={this._handleFormSubmission}
          variant="contained"
          className="mt-3"
          color="primary"
          size="large"
          // size="small"
          startIcon={<MdCheck />}
          style={{ width: "100%" }}
          disabled={this.state.formDisabled}
        >
          {this.props.tab == "addTabVideo"
            ? "Add to Video List"
            : "Add To Seed List"}
        </Button>
      </Form>
    )
  }

  _handleAddVideos = () => {
    this.props.handleAddVideos("create")
  }

  _handleStartNewSession = async () => {
    try {
      toastr.info("Action in progress...")
      const data = await startNewSession({
        interest: this.props.interest.id,
        subInterest: this.props.subInterest,
      })
      toastr.success("Action successful...")
      this.props.closeImportVideos()
    } catch (error) {
      toastr.error("Action failed...")
    }
  }

  _handleGoBack = () => {
    this.props.closeImportVideos()
  }

  render() {
    return (
      <Auxiliary loading={this.state.loading}>
        <Row>
          <Col lg={9}>
            <div className="d-flex justify-content-between mt-0 mb-2">
              <h2>Import Videos</h2>
              <div>
                <Button
                  onClick={this._handleGoBack}
                  variant="outlined"
                  className="mx-1"
                  // color="success"
                  // size="small"
                  startIcon={<MdOutlineArrowBackIosNew size={15} />}
                >
                  Back
                </Button>
                <Button
                  onClick={this._handleStartNewSession}
                  variant="outlined"
                  className="mx-1"
                  // color="success"
                  // size="small"
                  // startIcon={<MdAdd />}
                >
                  Start New Session
                </Button>
                <Button
                  onClick={this._handleAddVideos}
                  variant="contained"
                  className="mx-1"
                  color="success"
                  // size="small"
                  startIcon={<MdAdd />}
                >
                  Add Videos
                </Button>
              </div>
            </div>
            <div
              style={{
                height: "85vh",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              className="py-5 px-2"
            >
              {this._renderVideos()}
            </div>
          </Col>
          <Col>
            <div>{this._renderForm()}</div>
          </Col>
        </Row>
      </Auxiliary>
    )
  }
}
