import { Tooltip } from "antd"
import { map, size } from "lodash"
import React from "react"
import ReactCardFlip from "react-card-flip"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Link } from "react-router-dom"
import ReactTimeAgo from "react-time-ago"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import CustomScrollbars from "util/CustomScrollbars"
import {
  LANGUAGES,
  LANGUAGE_MAPPING,
  NO_PERMISSION_MESSAGE,
} from "../../constants/uiConstants"
import { formatNumber } from "../../util/common"
import "./style.css"

class AudioCard extends React.Component {
  state = { isPlaying: false, isFlipped: false, isPlayedFirst: true }
  audioRef = React.createRef()

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  _playPreview = () => {
    if (this.state.isPlayedFirst) {
      this.audioRef.currentTime = this.props.audio.default_start_selection_ms
        ? this.props.audio.default_start_selection_ms / 1000
        : 0
      this.setState({ isPlayedFirst: false })
    }
    var that = this

    var pauseAudioAt = function (aud, seconds, listenerToRemove) {
      // check whether we have passed 5 minutes,
      // current time is given in seconds
      if (aud.currentTime >= seconds) {
        // pause the playback
        aud.pause()
        if (typeof listenerToRemove === "function") {
          aud.removeEventListener("timeupdate", listenerToRemove)
          that.setState({ isPlaying: false, isPlayedFirst: true })

          // showBtn();
        }
      }
    }
    this.audioRef.addEventListener("timeupdate", function listener1() {
      pauseAudioAt(
        this,
        that.props.audio.default_end_selection_ms / 1000,
        listener1
      )
    })

    if (!this.state.isPlaying) {
      this.setState({ isPlaying: true })
      this.audioRef.play()
    } else {
      this.setState({ isPlaying: false })
      this.audioRef.pause()
      this.audioRef.removeEventListener("timeupdate", function () {})
    }
  }

  render() {
    const { audio, audioDetails = [], customClassname = "" } = this.props
    const realStatPermissionUser =
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("VIEW_REAL_STATS")

    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection={"horizontal"}
      >
        <Card
          className={`${customClassname} text-center shadow-lg`}
          style={{
            minHeight: "280px",
            borderRadius: "10px",
          }}
        >
          <div className="bg-primary bg-soft" onClick={() => {}}>
            <Row>
              {audio && audio.album_art ? (
                <div
                  role="button"
                  id={"play" + audio.id}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this._playPreview()
                  }}
                  style={{ height: "15rem" }}
                >
                  <CardImg
                    top
                    className="img-fluid"
                    src={audio.album_art}
                    alt="Skote"
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    style={{
                      height: "15rem",
                      cursor: "pointer",
                      opacity: "0.8",
                    }}
                  />
                  <Tooltip title={audio && audio.artist}>
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                    >
                      <img
                        src={
                          (audio &&
                            audio.artist_info &&
                            audio.artist_info.length > 0 &&
                            audio.artist_info[0].thumbnail) ||
                          "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
                        }
                        className="me-3 rounded-circle avatar-xs"
                        alt="artist-pic"
                      />
                    </div>
                  </Tooltip>
                  <i
                    key={audio.id}
                    className={
                      this.state.isPlaying ? "bx bx-pause" : "bx bx-play"
                    }
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "120px",
                      color: "white",
                    }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={"play" + audio.id}
                  >
                    Play Audio
                  </UncontrolledTooltip>
                  <audio
                    ref={input => {
                      this.audioRef = input
                    }}
                    src={audio.url}
                    style={{ display: "none" }}
                  />
                </div>
              ) : (
                <div
                  className="py-5"
                  style={{
                    height: "15rem",
                    cursor: "pointer",
                    backgroundColor: "lightgray",
                  }}
                >
                  <i className="fas fa-volume-mute fa-7x py-4 text-secondary" />
                </div>
              )}
            </Row>
          </div>
          <CardBody style={{ padding: "1rem 0.25rem" }}>
            <Tooltip title={audio.title}>
              <div className="font-size-14 text-truncate font-weight-semibold">
                <i className="fas fa-music mx-1" /> {audio.title}
              </div>
            </Tooltip>
            <div className="">
              {map(
                audio.languages,
                (language, index) =>
                  index < 2 && (
                    <Badge className="badge-soft-info ms-1 mt-1">
                      <div className="font-size-14">
                        {LANGUAGE_MAPPING[language]}
                      </div>
                    </Badge>
                  )
              )}
              {size(audio.languages) > 2 && (
                <Badge className="badge-soft-success ms-1">
                  <Tooltip
                    placement="right"
                    title={audio.languages
                      .slice(1, audio.languages.length)
                      .map(a => (
                        <div>{a}</div>
                      ))}
                  >
                    <div className="font-size-14 p-1">
                      +{size(audio.languages) - 2} more
                    </div>
                  </Tooltip>
                </Badge>
              )}
            </div>
            <div
              className="d-flex my-2 justify-content-center"
              style={{ height: "20px" }}
            >
              {audio.uploaded_by && (
                <Tooltip title="Uploaded By">
                  <Badge className="badge-soft-primary ms-1 font-size-13">
                    {audio.uploaded_by.name || audio.uploaded_by.user_name}
                  </Badge>
                </Tooltip>
              )}
              {audio.label_info && audio.label_info.label && (
                <Tooltip title={`Label : ${audio.label_info.label}`}>
                  <Badge
                    className="badge-soft-warning ms-1 font-size-13"
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                  >
                    {audio.label_info.label}
                  </Badge>
                </Tooltip>
              )}
            </div>
            {audio.created_date ? (
              <div className="">
                <Badge className="badge-soft-info ms-1 font-size-13">
                  <ReactTimeAgo
                    date={
                      Number.isNaN(audio.created_date)
                        ? Date.parse(audio.created_date)
                        : audio.created_date
                    }
                    locale="en-US"
                  />
                </Badge>
              </div>
            ) : null}

            <div className="d-flex justify-content-around align-items-center pt-2">
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {}}
              >
                <i
                  className="bx bx-show"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {" "}
                  {formatNumber(audio.view_count + audio.fk_views) || 0}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {}}
              >
                <i
                  className="bx bx-like"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {" "}
                  {formatNumber(audio.like_count + audio.fk_likes) || 0}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {}}
              >
                <i
                  className="bx bx-share-alt"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {formatNumber(audio.share_count + audio.fk_shares) || 0}
                </span>
              </div>
              <div>
                <Tooltip title="View More">
                  <i
                    className="bx bx-play"
                    role="button"
                    style={{ fontSize: "20px" }}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      this.setState({ isFlipped: true })
                    }}
                  />
                </Tooltip>
              </div>
            </div>

            <div>
              {map(
                audio.permissions,
                (tag, index) =>
                  index < 2 && (
                    <Link
                      to="#"
                      className="badge bg-primary font-size-11 m-1"
                      key={"_skill_" + audio.id + index}
                    >
                      {tag}
                    </Link>
                  )
              )}
              {size(audio.permissions) > 2 && (
                <Button
                  to="#"
                  className="badge bg-success font-size-11 m-1"
                  key={"_skill_" + audio.id}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span
                    title={audio.permissions
                      .slice(1, audio.permissions, length)
                      .map(a => (
                        <span>{a}</span>
                      ))}
                  >
                    +{size(audio.permissions) - 1} more
                  </span>
                </Button>
              )}
            </div>
          </CardBody>
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={audio.id}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Audio ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <Tooltip title="Edit Audio">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_MANAGE_AUDIO"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick("update", audio)
                  }}
                />
              </Tooltip>
            </div>
            <div className="flex-fill">
              <Tooltip title="Edit Visibility">
                <i
                  className="bx bx-show"
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_MANAGE_AUDIO"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick("visibility", audio)
                  }}
                />
              </Tooltip>
            </div>
            <div className="flex-fill">
              <Tooltip title="Mark Trending">
                <i
                  className="bx bx-line-chart"
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_MANAGE_AUDIO"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick("trending", audio)
                  }}
                />
              </Tooltip>
            </div>
            <div className="flex-fill">
              <Tooltip title="Edit status">
                <i
                  className="bx bx-check-circle"
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_MANAGE_AUDIO"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick("status", audio)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Card>
        <Card
          className={`${customClassname} text-center shadow-lg`}
          style={{
            height: "461.5px",
            borderRadius: "10px",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <CustomScrollbars>
            <CardBody className="px-0 py-0">
              <Row className="my-sm-2">
                <Col className="ms-2">
                  <div className="avatar-xs">
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-warning" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="bx bx-bar-chart" />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end me-2">
                  <div
                    className="avatar-xs"
                    role="button"
                    onClick={() => this.setState({ isFlipped: false })}
                  >
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-dark" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="fas fa-times-circle" />
                      </span>
                    </div>
                  </div>
                  <div></div>
                </Col>
              </Row>
              <Row>
                <div className="table-responsive">
                  <Table className="mb-0 table-striped">
                    <tbody>
                      <tr className="text-start">
                        <th scope="row">Source:</th>
                        <td className="break-word">{audio.source || "-"}</td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Original Source:</th>
                        <td>{audio.original_source || "-"}</td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Genre:</th>
                        <td>{audio.genre || "-"}</td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Artist:</th>
                        <td>{audio.artist || "-"}</td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Mood:</th>
                        <td>{audio.mood || "-"}</td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Language:</th>
                        <td>
                          {audio.languages && audio.languages.length > 0
                            ? audio.languages.map((lang, i) => {
                                return (
                                  <span>
                                    {i > 0 && <span>,&nbsp;</span>}
                                    {LANGUAGE_MAPPING[lang]}
                                  </span>
                                )
                              })
                            : ""}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Views:</th>
                        <td>
                          {formatNumber(audio.view_count + audio.fk_views) || 0}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Downloads:</th>
                        <td>
                          {formatNumber(
                            audio.download_count + audio.fk_downloads
                          ) || 0}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Likes:</th>
                        <td>
                          {" "}
                          {formatNumber(audio.like_count + audio.fk_likes) || 0}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Shares:</th>
                        <td>
                          {formatNumber(audio.share_count + audio.fk_shares) ||
                            0}
                        </td>
                      </tr>
                      {realStatPermissionUser && (
                        <React.Fragment>
                          <tr className="text-start">
                            <th scope="row">Real Downloads:</th>
                            <td>{formatNumber(audio.download_count) || 0}</td>
                          </tr>
                          <tr className="text-start">
                            <th scope="row">Real Views:</th>
                            <td>{formatNumber(audio.view_count) || 0}</td>
                          </tr>
                          <tr className="text-start">
                            <th scope="row">Real Likes:</th>
                            <td>{formatNumber(audio.like_count) || 0}</td>
                          </tr>
                          <tr className="text-start">
                            <th scope="row">Real Shares:</th>
                            <td>{audio.share_count ? audio.share_count : 0}</td>
                          </tr>
                        </React.Fragment>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </CardBody>
          </CustomScrollbars>
        </Card>
      </ReactCardFlip>
    )
  }
}

export default AudioCard
