import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ContentList from "../../components/ContentList/contentList"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Chips from "components/CommonFilter/chips"
import {
  getContentByFilter,
  getContentById,
  resetState,
} from "appRedux/slices/content"
import { getLocationData } from "appRedux/slices/location"
import Auxiliary from "../../util/Auxiliary"
import "./browseContent.style.css"
import { TARGET_CATEGORY_V2, contentFilters } from "../../constants/uiConstants"
import { getAllZones } from "appRedux/slices/zone"
import NoDataFound from "components/NoDataFound/noDataFound"
import DuplicateContentList from "components/ContentList/duplicateContentList"
import { getContentByUUIDs } from "clientServices/contentService"

class BrowseDuplicate extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    let view = "grid"
    if (this.props.queryParams?.view && this.props.queryParams?.content_uuid) {
      view = this.props.queryParams?.view || "grid"
    }
    this.state = {
      selectedContent: {},
      view: view || "grid",
      stateLocationList: [],
      cityLocationList: [],
      filters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        searchAfter:
          this.props.queryParams && this.props.queryParams["searchAfter"]
            ? this.props.queryParams["searchAfter"]
            : null,
        contentType:
          this.props.queryParams && this.props.queryParams["contentType"]
            ? this.props.queryParams["contentType"]
            : "",
        verifiedProfiles:
          this.props.queryParams && this.props.queryParams["verifiedProfiles"]
            ? this.props.queryParams["verifiedProfiles"]
            : "",
        searchText:
          this.props.queryParams && this.props.queryParams["searchText"]
            ? this.props.queryParams["searchText"]
            : null,
        audioId:
          this.props.queryParams && this.props.queryParams["audioId"]
            ? this.props.queryParams["audioId"]
            : "",
        clusteredAudioId:
          this.props.queryParams && this.props.queryParams["clusteredAudioId"]
            ? this.props.queryParams["clusteredAudioId"]
            : "",
        searchType:
          this.props.queryParams && this.props.queryParams["searchType"]
            ? this.props.queryParams["searchType"]
            : "content_uuid",
        moderationLevel:
          this.props.queryParams && this.props.queryParams["moderationLevel"]
            ? this.props.queryParams["moderationLevel"]
            : this.props.currentUser.permissions &&
              this.props.currentUser.permissions.includes("VIEW_ACTIVITY")
            ? "2"
            : "",
        langCode:
          this.props.queryParams && this.props.queryParams["langCode"]
            ? this.props.queryParams["langCode"]
            : "",
        rangeSearchType:
          this.props.queryParams && this.props.queryParams["rangeSearchType"]
            ? this.props.queryParams["rangeSearchType"]
            : null,
        opSearchType:
          this.props.queryParams && this.props.queryParams["opSearchType"]
            ? this.props.queryParams["opSearchType"]
            : null,
        rangeSearchText:
          this.props.queryParams && this.props.queryParams["rangeSearchText"]
            ? this.props.queryParams["rangeSearchText"]
            : null,
        rangeSearchFrom:
          this.props.queryParams && this.props.queryParams["rangeSearchFrom"]
            ? this.props.queryParams["rangeSearchFrom"]
            : null,
        rangeSearchTo:
          this.props.queryParams && this.props.queryParams["rangeSearchTo"]
            ? this.props.queryParams["rangeSearchTo"]
            : null,
        bucket:
          this.props.queryParams && this.props.queryParams["bucket"]
            ? this.props.queryParams["bucket"]
            : "-1",
        createdDateFrom:
          this.props.queryParams && this.props.queryParams["createdDateFrom"]
            ? this.props.queryParams["createdDateFrom"]
            : null,
        createdDateTo:
          this.props.queryParams && this.props.queryParams["createdDateTo"]
            ? this.props.queryParams["createdDateTo"]
            : null,
        orderByField:
          this.props.queryParams && this.props.queryParams["orderByField"]
            ? this.props.queryParams["orderByField"]
            : this.props.currentUser.permissions &&
              this.props.currentUser.permissions.includes("COMMUNITY_MANAGER")
            ? "created_date"
            : "view_count",
        order:
          this.props.queryParams && this.props.queryParams["order"]
            ? this.props.queryParams["order"]
            : "desc",
        sourceType:
          this.props.queryParams && this.props.queryParams["sourceType"]
            ? this.props.queryParams["sourceType"]
            : "non_ads",
        maskedContent:
          this.props.queryParams && this.props.queryParams["maskedContent"]
            ? this.props.queryParams["maskedContent"]
            : "",
        excludeLang:
          this.props.queryParams && this.props.queryParams["excludeLang"]
            ? this.props.queryParams["excludeLang"]
            : undefined,
        includeLang:
          this.props.queryParams && this.props.queryParams["includeLang"]
            ? this.props.queryParams["includeLang"]
            : undefined,
        isAssetUsed:
          this.props.queryParams && this.props.queryParams["isAssetUsed"]
            ? this.props.queryParams["isAssetUsed"] === "true"
              ? true
              : false
            : false,
        videoSource:
          this.props.queryParams && this.props.queryParams["videoSource"]
            ? this.props.queryParams["videoSource"]
            : "",
        qcDone:
          this.props.queryParams && this.props.queryParams["qcDone"]
            ? this.props.queryParams["qcDone"]
            : "",
        shoppableContent:
          this.props.queryParams && this.props.queryParams["shoppableContent"]
            ? this.props.queryParams["shoppableContent"]
            : "",
        duplicateContent:
          this.props.queryParams && this.props.queryParams["duplicateContent"]
            ? this.props.queryParams["duplicateContent"]
            : "Y",
        taggedContent:
          this.props.queryParams && this.props.queryParams["taggedContent"]
            ? this.props.queryParams["taggedContent"]
            : "",
        trendingContent:
          this.props.queryParams && this.props.queryParams["trendingContent"]
            ? this.props.queryParams["trendingContent"]
            : "",
        reviewDone:
          this.props.queryParams && this.props.queryParams["reviewDone"]
            ? this.props.queryParams["reviewDone"]
            : "",
        reModeration:
          this.props.queryParams && this.props.queryParams["reModeration"]
            ? this.props.queryParams["reModeration"]
            : "",
        rating:
          this.props.queryParams && this.props.queryParams["rating"]
            ? this.props.queryParams["rating"]
            : "",
        trendingEntityId:
          this.props.queryParams && this.props.queryParams["trendingEntityId"]
            ? this.props.queryParams["trendingEntityId"]
            : "",
        inputZone: this.props.queryParams
          ? this.props.queryParams["inputZone"]
          : "",
        activeZone: this.props.queryParams
          ? this.props.queryParams["activeZone"]
          : "",
        type: this.props.queryParams ? this.props.queryParams["type"] : "",
        sourcePlatform: this.props.queryParams
          ? this.props.queryParams["sourcePlatform"]
          : "",
        sourceUrl: this.props.queryParams
          ? this.props.queryParams["sourceUrl"]
          : "",
        glamourGrade: this.props.queryParams
          ? this.props.queryParams["glamourGrade"]
          : "",
        glamourRegion: this.props.queryParams
          ? this.props.queryParams["glamourRegion"]
          : "",
        glamourLevel: this.props.queryParams
          ? this.props.queryParams["glamourLevel"]
          : "",
      },
      previewContent:
        this.props.queryParams && this.props.queryParams["previewContent"]
          ? this.props.queryParams["previewContent"] === "true"
            ? true
            : false
          : false,
    }
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(getLocationData())
      let filters = _.clone(this.state.filters)

      if (
        this.props.currentUser &&
        this.props.currentUser.jotUserType === 7 &&
        !filters.rangeSearchType &&
        !filters.opSearchType == "gt" &&
        !filters.rangeSearchText == "500"
      ) {
        filters.opSearchType = "gt"
        filters.rangeSearchText = "500"
        filters.rangeSearchType = "view_count"
      }
      filters.createdDateFrom = (Math.floor(Date.now()) - 86400000).toString()
      filters.createdDateTo = Math.floor(Date.now()).toString()

      this.props.dispatch(getContentByFilter(_.deepClean(filters)))
      let originalContent = []

      this.setState({
        view: "grid",

        filters,
      })
    }
    window.addEventListener("keydown", this._keyDownHandler)
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    if (
      type === "searchText" ||
      type === "order" ||
      type === "moderationLevel" ||
      type === "langCode"
    ) {
      this.setState({
        filters: newFilters,
      })
    } else {
      this.setState({
        filters: newFilters,
      })
    }
  }

  _searchContent = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/content/duplicate?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.setState({ isOpen: false }, () =>
      this.props.dispatch(getContentByFilter(_.deepClean(filters)))
    )
  }

  _contentViewChange = contentView => {
    this.setState({ view: contentView })
  }

  _onPageChange = (value, previewContent) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = Number(value)
    if (previewContent === "next") {
      newFilters["currentPage"] = Number(value) + 1
      this.setState({
        view: "details",
      })
    }

    if (previewContent === "prev") {
      newFilters["currentPage"] = Number(value) - 1
      this.setState({
        view: "details",
      })
    }

    newFilters["previewContent"] = previewContent ? true : false
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/content/duplicate?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(getContentByFilter(_.deepClean(this.state.filters)))
      }
    )
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: contentFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => this._closeFilters()
    )
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _closeFilters = () => {
    this._searchContent()
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  render() {
    const updateContentList = [...this.props.contentList] || []
    const ContentIdList = []
    updateContentList.forEach(contentData => {
      ContentIdList.push(contentData.content_uuid)
    })

    if (
      updateContentList &&
      updateContentList.length &&
      this.props?.queryParams?.content_uuid &&
      this.props.selectedContent &&
      !ContentIdList.includes(this.props.queryParams.content_uuid)
    ) {
      updateContentList.push(this.props.selectedContent)
    }

    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.state.view === "grid" && (
          <Chips
            showFilters={this.state.showFilters}
            showAdvancedFilters={this.state.showAdvancedFilters}
            filters={this.state.filters}
            search={this._closeFilters}
            removeFilter={this._removeFilter}
            handleFilterChange={this._handleFilterChange}
            type="duplicate"
            toggleFilters={this._toggleFilters}
          />
        )}
        {updateContentList && updateContentList.length > 0 ? (
          <DuplicateContentList
            {...this.props}
            filters={this.state.filters}
            contentList={updateContentList}
            // originalContent={this.state.originalContent.data}
            isReProcessedModerationList={
              this.state.filters["searchType"] === "reprocessed_moderation" &&
              this.state.filters["searchText"]
            }
            onPageChange={this._onPageChange}
            total={this.props.total}
            contentView={this.state.view}
            searchAfter={this.props.searchAfter}
            isMobile={this.props.isMobile}
            currentUser={this.props.currentUser}
            currentPage={this.state.filters["currentPage"]}
            pageType="content"
            pageSize={this.state.filters["pageSize"]}
            previewContent={this.state.previewContent}
            contentViewChange={this._contentViewChange}
          />
        ) : (
          <NoDataFound message="No data found for provided filters" />
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    contentList: _.get(store, "content.content"),
    selectedContent: _.get(store, "content.selectedContent"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "content.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "content.loading"),
  }
}

export default connect(mapStateToProps)(BrowseDuplicate)
