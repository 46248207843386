import { createSlice } from "@reduxjs/toolkit"

const issueCommentsSlice = createSlice({
  name: "issueComments",
  initialState: {
    loading: false,
    issuesList: [],
    commentsList: [],
    refetchData: false,
    total: 0,
  },
  reducers: {
    getIssuesComments(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getIssuesCommentsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: true,
        commentsList: payload.result.data,
        total: payload.result.data,
      }
    },
    createIssueComment(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createIssueCommentSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState(state) {
      return {
        ...state,
        loading: false,
        issuesList: [],
        refetchData: false,
      }
    },
  },
})

export const {
  createIssueComment,
  createIssueCommentSuccess,
  getIssuesComments,
  getIssuesCommentsSuccess,
  resetState,
} = issueCommentsSlice.actions

export default issueCommentsSlice.reducer
