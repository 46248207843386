import Axios from "axios"
import toastr from "toastr"

export const requestPackage = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/package/entity/list?type=${payload.filters.type}&offset=${offset}&limit=${limit}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertPackage = async ({ payload }) => {
  try {
    const url = `/api/package/entity/upsert?action=${payload.action}`
    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestDeletePackage = async ({ payload }) => {
  try {
    const url = `/api/package/delete`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertPackageCollection = async ({ payload }) => {
  try {
    const url = `/api/package/collection/upsert?action=${payload.action}`
    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertPackageCollectionEntity = async ({ payload }) => {
  try {
    const action = payload.action
    delete payload["action"]
    const url = `/api/package/collection/entity?action=${action}`
    let response = await Axios.post(url, payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
