import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { Player } from "@lottiefiles/react-lottie-player"
import {
  Button,
  Row,
  Col,
  CardImg,
  Card as ReactCard,
  CardBody,
  Badge,
} from "reactstrap"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { CopyToClipboard } from "react-copy-to-clipboard"
import GiftForm from "./giftForm"
import GiftCard from "./giftCard"
import {
  getGifts,
  resetState,
  reorderGiftCollectionEntity,
} from "../../appRedux/slices/gift"
import Chips from "components/CommonFilter/chips"
import {
  manageGiftCollectionEntityFilters,
  NO_PERMISSION_MESSAGE,
} from "constants/uiConstants"
import Auxiliary from "../../util/Auxiliary"
import {
  Divider,
  Input,
  Pagination,
  Tabs,
  Modal,
  Card,
  message,
  Tooltip,
  Select,
  Radio,
  Popconfirm,
} from "antd"
import "../Comment/browseComment.style.css"

const { Meta } = Card

class ManageGiftCollectionEntitiesReorder extends ErrorBoundComponent {
  state = {
    entityList: [],
    filters: {
      pageSize: 1000,
      currentPage: 1,
      type:
        this.props.queryParams && this.props.queryParams["type"]
          ? this.props.queryParams["type"]
          : "GIFT_COLLECTION_ENTITY",
      giftCollectionId:
        this.props.queryParams && this.props.queryParams["giftCollectionId"]
          ? this.props.queryParams["giftCollectionId"]
          : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getGifts({ filters: _.deepClean(filters) }))
      this.setState({ filters })
    }
  }

  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  _onDragEnd = result => {
    // dropped outside the list
    const prevEntityList = this.state?.entityList?.length
      ? this.state.entityList
      : this.props.entityList

    if (!result.destination) {
      return
    }

    const entityList = this._reorder(
      prevEntityList,
      result.source.index,
      result.destination.index
    )
    this.setState({
      entityList,
    })
  }

  _updateTabOrder = () => {
    const { entityList } = this.state
    const payload = {}
    const entities = []

    for (let i = 0; i < entityList.length; i++) {
      entities.push(entityList[i]["gift_entity_uuid"])
    }
    payload.entities = entities
    payload.gift_collection_uuid = this.state?.filters?.giftCollectionId
    this.props.dispatch(reorderGiftCollectionEntity({ payload }))
    this.props.history.push(
      `/giftCollection/entities/manage?giftCollectionId=${this.state?.filters?.giftCollectionId}`
    )
  }

  render() {
    const entityList = this.state?.entityList?.length
      ? this.state.entityList
      : this.props.entityList
    return (
      <div>
        <div className="d-flex justify-content-between mb-2">
          <Button
            size="sm"
            color="primary"
            className="font-size-12 text-truncate"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes("EDIT_GIFTS")
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.props.history.push(
                `/giftCollection/entities/manage?giftCollectionId=${this.state?.filters?.giftCollectionId}`
              )
            }}
          >
            <i className="fa fa-chevron-left mx-1 my-1" />
            Back
          </Button>

          <Button
            size="sm"
            color="primary"
            className="font-size-12 text-truncate me-2"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes("EDIT_GIFTS")
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this._updateTabOrder()
            }}
          >
            <i className="fa fa-floppy-o mx-1 my-1" />
            Update
          </Button>
        </div>
        <div style={{ marginBottom: 30 }} bordered={true}>
          <DragDropContext onDragEnd={this._onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {entityList.map((tab, index) => (
                    <Draggable
                      key={`${tab["gift_entity_uuid"]}`}
                      draggableId={`${tab["gift_entity_uuid"]}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Row>
                            <Col>
                              <Card
                                className="cardStyle"
                                cover={
                                  tab.gift_thumbnail_url ||
                                  tab.thumbnail_url ||
                                  tab.gift_animated_url ||
                                  tab.gift_share_url ||
                                  tab.gift_unreceived_thumbnail_url ? (
                                    !(
                                      tab.gift_thumbnail_url ||
                                      tab.thumbnail_url ||
                                      tab.gift_animated_url ||
                                      tab.gift_share_url ||
                                      tab.gift_unreceived_thumbnail_url
                                    ).includes(".json") ? (
                                      <img
                                        alt="image"
                                        // src="https://stream.coolfie.io/stream/prod-ugc-contents/zerosearch/thumbnails/0a9cbabb5ac44725/82b24ff6f32882a2/0a9cbabb5ac4472582b24ff6f32882a2.jpg"
                                        src={
                                          tab.gift_thumbnail_url ||
                                          tab.thumbnail_url ||
                                          tab.gift_animated_url ||
                                          tab.gift_share_url ||
                                          tab.gift_unreceived_thumbnail_url
                                        }
                                        style={{ width: 60, height: 60 }}
                                        className="me-3"
                                      />
                                    ) : (
                                      <Player
                                        autoplay={true}
                                        loop={true}
                                        controls={true}
                                        src={
                                          tab.gift_thumbnail_url ||
                                          tab.thumbnail_url ||
                                          tab.gift_animated_url ||
                                          tab.gift_share_url ||
                                          tab.gift_unreceived_thumbnail_url
                                        }
                                        style={{ width: 60, height: 60 }}
                                      ></Player>
                                    )
                                  ) : (
                                    <i class="bx bx-gift bx-lg" />
                                  )
                                }
                                bordered={true}
                                style={{
                                  padding: "10px 20px",
                                  display: "flex",
                                  // width: "50px",
                                }}
                              >
                                <div className="d-flex">
                                  <Meta title={tab.title} className="me-2" />
                                  <CopyToClipboard
                                    text={tab?.gift_entity_uuid}
                                    onCopy={e => {
                                      toastr.success("Copied sucessfully!")
                                    }}
                                    className="me-2"
                                  >
                                    <Tooltip title="Copy Entity ID">
                                      <i
                                        className="bx bx-copy-alt"
                                        role="button"
                                      />
                                    </Tooltip>
                                  </CopyToClipboard>
                                  <CopyToClipboard
                                    text={tab?.gift_collection_uuid}
                                    onCopy={e => {
                                      toastr.success("Copied sucessfully!")
                                    }}
                                  >
                                    <Tooltip title="Copy Collection ID">
                                      <i className="bx bx-copy" role="button" />
                                    </Tooltip>
                                  </CopyToClipboard>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "gift.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "gift.loading"),
    entityList: _.get(store, "gift.entities"),
    refetch: _.get(store, "gift.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageGiftCollectionEntitiesReorder)
