import React from "react"
import { Tag, Pagination } from "antd"
import { connect } from "react-redux"
import CopyToClipboard from "react-copy-to-clipboard"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
// import UserCard from "./userCard"
//import "./styles.css"
//import "../ContentList/style.css"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import { Button, Card, CardBody, Badge } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import { size, map } from "lodash"
import { Tooltip } from "antd"
import { LANGUAGE_MAPPING } from "constants/uiConstants"
//import { deleteAppUser } from "appRedux/slices/usersList"
class AgencyLimitList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewmodal: false,
      modal: false,
      orders: [],
      appCode: this.props.appCode || "CREATOR_NETWORK",
      fetchAllUsers: this.props?.filters?.fetchAllUsers || false,
      permissions: [],
      userColumns: [
        {
          dataField: "id",
          text: "Id",

          formatter: (cellContent, user, rowIndex, y) => {
            return (
              <>
                <div className="d-flex flex-column">
                  <div>{user.id}</div>
                </div>
              </>
            )
          },
        },
        {
          dataField: "name",
          text: "Agency",
          sort: true,
          formatter: (cellContent, user) => {
            return (
              <div className="d-flex flex-column">
                <div>{user.agency}</div>
              </div>
            )
          },
        },
        {
          dataField: "type",
          text: "Type",
          sort: true,
          formatter: (cellContent, user) => {
            return (
              <div className="d-flex flex-column">
                <div>{user.type}</div>
              </div>
            )
          },
        },
        {
          dataField: "language",
          text: "Language",
          sort: true,
          formatter: (cellContent, user) => {
            return (
              <div className="d-flex flex-column">
                <div>{LANGUAGE_MAPPING[user.language] || user.language}</div>
              </div>
            )
          },
        },
        {
          dataField: "dailyLimit",
          text: "Daily Limit",
          sort: true,
          formatter: (cellContent, user) => {
            return (
              <div className="d-flex flex-column">
                <div>{user.dailyLimit}</div>
              </div>
            )
          },
        },

        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Actions",
          formatter: (cellContent, user) => (
            <div className="d-flex align-items-center gap-3">
              <div role="button">
                <Tooltip title="Edit">
                  <i
                    className="bx bx-edit-alt"
                    id="edittooltip"
                    onClick={e => {
                      e.stopPropagation(),
                        e.preventDefault(),
                        this.props.handleApproveReject(user)
                    }}
                  ></i>
                </Tooltip>
              </div>
              <div role="button">
                <Tooltip title="Delete">
                  <i
                    className="bx bx-trash-alt"
                    id="deletetooltip"
                    onClick={e => {
                      e.stopPropagation(),
                        e.preventDefault(),
                        this.props.deleteAgency(user)
                    }}
                  ></i>
                </Tooltip>
              </div>
            </div>
          ),
        },
      ],
    }
  }
  //   deleteAgency =(agency)=>{
  // this.props
  //   }

  render() {
    const { userList } = this.props
    const defaultSorted = [
      {
        dataField: "name",
        order: "asc",
      },
    ]
    return (
      <>
        {this.props.total &&
          this.props.userList &&
          this.props.userList.length > 0 && (
            <div style={{ textAlign: "center" }} className="mb-2">
              <Tag color="geekblue">
                Showing <b>{this.props.userList.length}</b> of{" "}
                <b>{this.props.total}</b> Users
              </Tag>
            </div>
          )}
        {this.props.userList.length > 0 && (
          <div className="">
            <Card>
              <CardBody>
                <ToolkitProvider
                  keyField="id"
                  data={userList}
                  columns={this.state.userColumns}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          // {...paginationTableProps}
                          responsive
                          defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          // selectRow={selectRow}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        )}
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    //userList: _.get(store, "appUser.userList"),
  }
}

export default connect(mapStateToProps)(AgencyLimitList)
