import React from "react"
import { Upload } from "antd"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, { ChipsInput, TextField } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import toastr from "toastr"
import ChipInput from "material-ui-chip-input"

export default class UpsertTemplateForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      formElementType: this.props.data && this.props.data.element_type,
      keywords: this.props?.data?.keywords || [],
    }
  }

  _onImageRemove = type => {
    if (type === "assetLicence") {
      this.setState({
        assetLicenceList: [],
        licence: null,
      })
    }
    if (type === "assetUrl") {
      this.setState({
        assetUrlList: [],
        assetUrlImage: null,
      })
    }
    if (type === "staticThumbnail") {
      this.setState({
        staticImageList: [],
        staticImage: null,
      })
    }
    if (type === "dynamicThumbnail") {
      this.setState({
        dynamicImageList: [],
        dynamicImage: null,
      })
    } else
      this.setState({
        imageList: [],
        image: null,
      })
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "assetLicence") {
        this.setState({
          assetLicenceList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          licence: fileObj.file,
        })
      }
      if (type === "assetUrl") {
        this.setState({
          assetUrlList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          assetUrlImage: fileObj.file,
        })
      }
      if (type === "staticThumbnail") {
        this.setState({
          staticImageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          staticImage: fileObj.file,
        })
      }
      if (type === "dynamicThumbnail") {
        this.setState({
          dynamicImageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          dynamicImage: fileObj.file,
        })
      } else
        this.setState({
          imageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          image: fileObj.file,
        })
    }
  }

  addChip = value => {
    const keywords = this.state.keywords.slice()
    keywords.push(value)
    this.setState({ keywords })
  }

  removeChip = (value, index) => {
    const keywords = this.state.keywords.slice()
    keywords.splice(index, 1)
    this.setState({ keywords })
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Collection Entity`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={6}>
                <TextField
                  label={"Asset ID"}
                  placeholderLabel="Asset ID"
                  field={"assetId"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.template_uuid
                  }
                  className="creator-form"
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Category"}
                  placeholderLabel="Category"
                  field={"category"}
                  className="creator-form"
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.category
                  }
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Inline CTA Text"}
                  placeholderLabel="Inline Cta Text"
                  field={"inlineCtaText"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data &&
                        this.props.data.cta_data &&
                        this.props.data.cta_data.inline_cta_text
                  }
                  className="creator-form"
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Inline CTA"}
                  placeholderLabel="Inline Cta"
                  field={"inlineCta"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data &&
                        this.props.data.cta_data &&
                        this.props.data.cta_data.inline_cta
                  }
                  className="creator-form"
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Subtitle"}
                  placeholderLabel="Subtitle"
                  field={"subtitle"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.subtitle
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Asset Label"}
                  placeholderLabel="Asset Label"
                  field={"assetLabel"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.asset_label
                  }
                  className="creator-form"
                  required="true"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm="12" md="6">
                <TextField
                  label={"Clip Number"}
                  placeholderLabel="Clip Number"
                  field={"clipNumber"}
                  inputType={"number"}
                  minValue={0}
                  validations={["required", "number"]}
                  errors={["This field is required"]}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.clip_number
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm="12" md="6">
                <TextField
                  label={"Photo Number"}
                  placeholderLabel="Photo Number"
                  field={"photoNumber"}
                  inputType={"number"}
                  minValue={0}
                  validations={["required", "number"]}
                  errors={["This field is required"]}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.photo_number
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm="12" md="6">
                <TextField
                  label={"Asset Time"}
                  placeholderLabel="Asset Time"
                  field={"assetTime"}
                  inputType={"number"}
                  minValue={0}
                  validations={["required", "number"]}
                  errors={["This field is required"]}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.asset_time
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm={12} md={6}>
                <ChipsInput
                  label={"Tagged Videos"}
                  placeholderLabel="Tagged Videos"
                  field={"taggedVideos"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.tagged_videos
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm="12" md="6">
                <label className="me-1" htmlFor="radioVertical">
                  Keywords
                </label>
                <br></br>
                <ChipInput
                  value={this.state.keywords}
                  onAdd={chip => this.addChip(chip)}
                  onDelete={(chip, index) => this.removeChip(chip, index)}
                />
              </Col>
              <div>
                <Col sm="12" md="6">
                  <Row style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("assetLicence")}
                      defaultFileList={this.state.assetLicenceList}
                      fileList={this.state.assetLicenceList}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "assetLicence")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        Upload Asset License
                      </Button>
                    </Upload>
                  </Row>
                </Col>
                <Col sm="12" md="6">
                  <Row style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("assetUrl")}
                      defaultFileList={this.state.assetUrlList}
                      fileList={this.state.assetUrlList}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "assetUrl")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        Upload Asset Image
                      </Button>
                    </Upload>
                  </Row>
                </Col>
                <Col sm="12" md="6">
                  <Row style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("staticThumbnail")}
                      defaultFileList={this.state.staticImageList}
                      fileList={this.state.staticImageList}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "staticThumbnail")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        Upload Static Thumbnail
                      </Button>
                    </Upload>
                  </Row>
                </Col>
                <Col sm="12" md="6">
                  <Row style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onImageRemove("dynamicThumbnail")}
                      defaultFileList={this.state.dynamicImageList}
                      fileList={this.state.dynamicImageList}
                      customRequest={fileObj =>
                        this._uploadImage(fileObj, "dynamicThumbnail")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        Upload Dynamic Thumbnail
                      </Button>
                    </Upload>
                  </Row>
                </Col>
              </div>
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-end">
                  <Button htmlType="submit" color="primary">
                    {this.props.action === "update" ? "Update" : "Create"}
                  </Button>
                  <Button
                    onClick={() => this.props.onCancel()}
                    color="danger"
                    className="mx-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    if (
      this.props.action !== "update" &&
      (_.isEmpty(this.state.licence) ||
        _.isEmpty(this.state.assetUrlImage) ||
        _.isEmpty(this.state.staticImage) ||
        _.isEmpty(this.state.dynamicImage))
    ) {
      toastr.error("License and Images are mandatory")
      return
    }
    let data
    data = new FormData()
    let modifiedData = formData
    if (this.props.action === "update") {
      modifiedData["banner_uuid"] = this.props.data.banner_uuid
    }
    Object.keys(modifiedData).forEach(key => {
      if (modifiedData[key] !== undefined && modifiedData[key] !== "") {
        data.append(key, modifiedData[key])
      }
    })
    data.append("keywords", this.state.keywords || [])
    data.append("file1", this.state.licence)
    data.append("file2", this.state.assetUrlImage)
    data.append("file3", this.state.staticImage)
    data.append("file4", this.state.dynamicImage)

    this.props.onSubmit(data)
  }

  _render = () => {
    return this._form()
  }
}
