import React, { Component } from "react"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { connect } from "react-redux"
import { Pagination } from "antd"
import { ISSUE_ACTIVITY_MAPPING } from "constants/uiConstants"
class ActivityComp extends Component {
  constructor(props) {
    super(props)
  }

  _renderName = item => {
    let currentUserEmail =
      this.props.currentUser && this.props.currentUser.email
    if (item.modifiedBy === currentUserEmail) {
      return "You have"
    }
    return item.modifiedBy.split("@")[0]
  }

  render() {
    return (
      <React.Fragment>
        <Card className="shadow-lg">
          <CardBody style={{ position: "relative" }}>
            <CardTitle className="mb-3 h4">Activity</CardTitle>
            <div className="activity_list">
              <ul className="verti-timeline list-unstyled">
                {this.props.activityList &&
                  this.props.activityList.map(item => (
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                      </div>
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <h5 className="font-size-14 d-flex">
                            <div>
                              <div>
                                {new Date(item.createdOn).toDateString()}
                              </div>
                              <div>
                                {new Date(item.createdOn).toLocaleTimeString()}
                              </div>
                            </div>
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                          </h5>
                        </div>
                        <div className="flex-grow-1">
                          <div className="activity-ellipse">
                            {item.modifiedBy ? this._renderName(item) : ""}
                            {item.issueFields?.data[0]?.key == "All"
                              ? ""
                              : " updated "}
                            {item.issueFields?.data[0]?.key == "All"
                              ? " created Issue " + item.issueId
                              : item.issueFields.data.map((items, i) => (
                                  <span>
                                    {ISSUE_ACTIVITY_MAPPING[items.key]}{" "}
                                    {i === item.issueFields.data.length - 1
                                      ? items.key == "supportingAssets"
                                        ? " for Issue " + item.issueId
                                        : items.key == "pocList"
                                        ? JSON.parse(items.value).map(
                                            (el, j) => <span>{el}, </span>
                                          )
                                        : " to " +
                                          items.value +
                                          " for Issue " +
                                          item.issueId
                                      : ", "}
                                  </span>
                                ))}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="d-flex justify-content-end mb-3 pagination_position">
              <Pagination
                style={{ marginTop: "1rem" }}
                onChange={this.props._onPageChange}
                defaultCurrent={this.props.activityFilters.currentPage}
                total={
                  this.props.totalActivity < 10000
                    ? this.props.totalActivity
                    : 10000
                }
                pageSize={this.props.activityFilters.pageSize}
                showSizeChanger={false}
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
function mapStateToProps(store) {
  return {
    activityList: _.get(store, "creatorIssues.activityList"),
    totalActivity: _.get(store, "creatorIssues.totalActivity"),
    loading: _.get(store, "creatorIssues.loading"),
    common: _.get(store, "common"),
    userList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ActivityComp)
// export default ActivityComp
