import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import { LANGUAGES, TARGET_CATEGORY_V2 } from "../../constants/uiConstants"
import "react-datepicker/dist/react-datepicker.css"
import dayjs from "dayjs"

const { Option } = Select

const UserDrawer = props => {
  const realStatPermissionUser =
    // props.currentUser.jotUserType < 3 ||
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("VIEW_REAL_STATS")
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="6" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Filter By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Select
                  placeholder="Search Type"
                  style={{ width: "45%" }}
                  allowClear={true}
                  defaultValue={props.filters["searchType"]}
                  onChange={value =>
                    props.handleFilterChange(value, "searchType")
                  }
                >
                  <Option value="name">Name</Option>
                  <Option value="handle">Handle</Option>
                  <Option value="city">City</Option>
                  <Option value="state">State</Option>
                  <Option value="user_uuid">User ID</Option>
                  <Option value="client_uuid">Client ID</Option>
                  <Option value="phone">Phone Number</Option>
                </Select>
                {(props.filters["searchType"] == "user_uuid" ||
                  props.filters["searchType"] == "client_uuid" ||
                  props.filters["searchType"] == "name" ||
                  props.filters["searchType"] == "handle" ||
                  props.filters["searchType"] === "phone") && (
                  <Input
                    value={props.filters["searchText"]}
                    placeholder={"Search Value"}
                    onChange={e =>
                      props.handleFilterChange(e.target.value, "searchText")
                    }
                    allowClear={true}
                    name="searchId"
                    style={{ width: "55%" }}
                  />
                )}
                {props.filters["searchType"] === "city" && (
                  <Select
                    showSearch
                    allowClear={true}
                    placeholder="City"
                    defaultValue={props.filters["searchText"]}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={value => {
                      props.handleFilterChange(value, "searchText")
                    }}
                    style={{ width: "55%" }}
                  >
                    <Option value="">All</Option>
                    {props.cityLocationList.length > 0 &&
                      props.cityLocationList.map(city => (
                        <Option value={city.value}>{city.label}</Option>
                      ))}
                  </Select>
                )}
                {props.filters["searchType"] === "state" && (
                  <Select
                    showSearch
                    allowClear={true}
                    placeholder="State"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultValue={props.filters["searchText"]}
                    onChange={value => {
                      props.handleFilterChange(value, "searchText")
                    }}
                    style={{ width: "55%" }}
                  >
                    <Option value="">All</Option>
                    {props.stateLocationList.length > 0 &&
                      props.stateLocationList.map(state => (
                        <Option value={state.value}>{state.label}</Option>
                      ))}
                  </Select>
                )}
                {props.filters["searchType"] === "created_day" && (
                  <DatePicker
                    defaultValue={
                      props.filters["searchText"]
                        ? dayjs.unix(
                            props.filters["searchText"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD"
                    style={{ width: "55%" }}
                    onChange={(moment, dateString) => {
                      props.handleFilterChange(dateString, "searchText")
                    }}
                  />
                )}
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="2" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Creator Type</h5>
          </Divider>
          <Select
            allowClear={true}
            placeholder="Creator Type"
            defaultValue={props.filters["creatorType"]}
            onChange={value => {
              props.handleFilterChange(value, "creatorType")
            }}
            style={{ width: "100%" }}
          >
            <Option value="">All</Option>
            <Option value="ghost">Ghost</Option>
            <Option value="internal">Internal</Option>
            <Option value="ugc">UGC</Option>
            <Option value="verified">Verified</Option>
            <Option value="internalBrand">Internal Brand</Option>
            <Option value="externalBrand">External Brand</Option>
          </Select>
        </Col>
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Order By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              allowClear={true}
              defaultValue={props.filters["orderByField"]}
              placeholder="Order By"
              onChange={value => {
                props.handleFilterChange(value, "orderByField")
              }}
              style={{ width: "50%" }}
            >
              <Option value="posts_count">Posts Count</Option>
              {realStatPermissionUser && (
                <React.Fragment>
                  <Option value="like_count">Real Like Count</Option>
                  <Option value="share_count">Real Share Count</Option>
                  <Option value="view_count">Real View Count</Option>
                  <Option value="download_count">Real Download Count</Option>
                </React.Fragment>
              )}
              <Option value="rc_total_like_count">Like Count</Option>
              <Option value="rc_total_share_count">Share Count</Option>
              <Option value="rc_total_view_count">View Count</Option>
              <Option value="rc_total_download_count">Download Count</Option>
              <Option value="nsfw_posts">NSFW Count</Option>
              <Option value="first_content_date">First Creation Date</Option>
              <Option value="last_content_date">Last Creation Date</Option>
              <Option value="moderated_posts_count">Moderated Posts</Option>
            </Select>
            <Select
              allowClear={true}
              defaultValue={props.filters["order"]}
              placeholder="Order"
              onChange={value => {
                props.handleFilterChange(value, "order")
              }}
              style={{ width: "50%" }}
            >
              <Option value="asc">Ascending</Option>
              <Option value="desc">Descending</Option>
            </Select>
          </Input.Group>
        </Col>
      </Row>
      <div className="d-flex justify-content-center">
        <div onClick={props.toggleAdvancedFilters} role="button">
          {props.showAdvancedFilters ? (
            <i
              className="fas fa-angle-double-up
 font-size-16 align-middle me-2"
            ></i>
          ) : (
            <i
              className="fas fa-angle-double-down
 font-size-16 align-middle me-2"
            ></i>
          )}
          {props.showAdvancedFilters ? "Hide" : "Show"} Advanced Filters
        </div>
      </div>
      {props.showAdvancedFilters && (
        <Row>
          <Col lg="6">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Range Filter</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex">
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["rangeSearchType"]}
                    placeholder="Range Search Type"
                    onChange={value => {
                      props.handleFilterChange(value, "rangeSearchType")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="posts_count">Posts</Option>
                    <Option value="moderated_posts_count">
                      Moderated Posts
                    </Option>
                    {realStatPermissionUser && (
                      <React.Fragment>
                        <Option value="like_count">Real Like Count</Option>
                        <Option value="share_count">Real Share Count</Option>
                        <Option value="view_count">Real View Count</Option>
                        <Option value="download_count">
                          Real Download Count
                        </Option>
                      </React.Fragment>
                    )}
                    <Option value="rc_total_like_count">Like Count</Option>
                    <Option value="rc_total_share_count">Share Count</Option>
                    <Option value="rc_total_view_count">View Count</Option>
                    <Option value="rc_total_download_count">
                      Download Count
                    </Option>
                  </Select>
                  <Select
                    allowClear={true}
                    defaultValue={props.filters["opSearchType"]}
                    placeholder="Operator"
                    onChange={value => {
                      props.handleFilterChange(value, "opSearchType")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="bt">Between</Option>
                    <Option value="eq">Equal to</Option>
                    <Option value="gt">Greater Than</Option>
                    <Option value="lt">Less Than</Option>
                  </Select>
                  {props.filters["opSearchType"] !== "bt" && (
                    <Input
                      defaultValue={props.filters["rangeSearchText"]}
                      placeholder={"Range Search Text"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value,
                          "rangeSearchText"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  )}
                  {props.filters["opSearchType"] === "bt" && (
                    <Input
                      defaultValue={props.filters["rangeSearchFrom"]}
                      placeholder={"Range Search From"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value,
                          "rangeSearchFrom"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  )}
                  {props.filters["opSearchType"] === "bt" && (
                    <Input
                      defaultValue={props.filters["rangeSearchTo"]}
                      placeholder={"Range Search To"}
                      onChange={e =>
                        props.handleFilterChange(
                          e.target.value,
                          "rangeSearchTo"
                        )
                      }
                      allowClear={true}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  )}
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col lg="2">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Curated Type</h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Curated Type"
              defaultValue={props.filters["curatedType"]}
              onChange={value => {
                props.handleFilterChange(value, "curatedType")
              }}
              style={{ width: "100%" }}
            >
              <Option value="c">Community</Option>
              <Option value="k">Kol</Option>
              <Option value="m">Macro</Option>
              <Option value="n">Nano</Option>
              <Option value="o">OGC</Option>
              <Option value="s">Celebrity/Stars</Option>
              <Option value="l">Micro</Option>
              <Option value="u">UGC</Option>
            </Select>
          </Col>
          <Col lg="2">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">
                Creation Language
              </h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Language"
              defaultValue={props.filters["contentCreateLangs"]}
              onChange={value => {
                props.handleFilterChange(value, "contentCreateLangs")
              }}
              style={{ width: "100%" }}
            >
              <Option value="">All</Option>
              {LANGUAGES.map(lang => (
                <Option value={lang.value}>{lang.label}</Option>
              ))}
            </Select>
          </Col>
          <Col lg="2">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Primay Language</h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Language"
              defaultValue={props.filters["userLangPrimary"]}
              onChange={value => {
                props.handleFilterChange(value, "userLangPrimary")
              }}
              style={{ width: "100%" }}
            >
              <Option value="">All</Option>
              {LANGUAGES.map(lang => (
                <Option value={lang.value}>{lang.label}</Option>
              ))}
            </Select>
          </Col>
          <Col lg="6">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Created Date</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex">
                  <DatePicker
                    defaultValue={
                      props.filters["createdDateFrom"]
                        ? dayjs.unix(
                            props.filters["createdDateFrom"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="From Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "createdDateFrom"
                      )
                    }}
                    style={{ width: "100%" }}
                  />
                  <DatePicker
                    defaultValue={
                      props.filters["createdDateTo"]
                        ? dayjs.unix(
                            props.filters["createdDateTo"] / 1000,
                            "YYYY-MM-DD"
                          )
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="To Date"
                    onChange={(date, dateString) => {
                      props.handleFilterChange(
                        !_.isEmpty(dateString)
                          ? moment(dateString).format("x")
                          : null,
                        "createdDateTo"
                      )
                    }}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col lg="4">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Categories</h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Categories"
              defaultValue={props.filters["category"]}
              onChange={value => {
                props.handleFilterChange(value, "category")
              }}
              style={{ width: "100%" }}
            >
              <Option value="">All</Option>
              {TARGET_CATEGORY_V2.map(cat => (
                <Option value={cat.value}>{cat.label}</Option>
              ))}
            </Select>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

UserDrawer.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(UserDrawer)
