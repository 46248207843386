import React from "react"
import _ from "lodash"
import {
  Button,
  Popover,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Card,
} from "antd"

import {
  SearchOutlined,
  CheckCircleFilled,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import {
  LANGUAGES,
  DEACTIVATE_REASONS,
  TARGET_CATEGORY_V2,
  CATEGORIES,
} from "../../constants/uiConstants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMusic,
  faVideo,
  faUser,
  faHashtag,
  faPlayCircle,
  faCompactDisc,
  faFlag,
  faExclamation,
  faGamepad,
  faTheaterMasks,
  faMagic,
  faImages,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons"

const { Option } = Select
const { Meta } = Card

const TYPE_TO_KEY_MAP = {
  BANNER: "banner_uuid",
  SUBBANNER: "banner_uuid",
  MUSIC_ARTIST: "music_artist_uuid",
  MUSIC_LABEL: "music_label_uuid",
  MUSIC_PLAYLIST: "music_playlist_uuid",
  NAMED_COLLECTION: "collection_uuid",
}

export class RenderHastagFilters extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row
          className="d-flex justify-content-between"
          style={{ margin: "0px 60px 10px 60px", width: "100%" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <Col span={12}>
            <Row>
              <Col span={8}>
                <Input.Group compact={true}>
                  <Input
                    placeholder={"Hashtag Name"}
                    onChange={e =>
                      this.props._handleFilterChange(e.target.value, "name")
                    }
                    allowClear={true}
                    name="name"
                    style={{ width: "100%" }}
                  />
                </Input.Group>
              </Col>
              <Col span={4}>
                <Button
                  type="dashed"
                  style={{
                    marginRight: "10px",
                    color: "blue",
                    borderColor: "blue",
                  }}
                  onClick={() => {
                    this.props._searchHashtag()
                  }}
                >
                  <SearchOutlined /> Search
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              type="dashed"
              style={{
                marginRight: "10px",
                color: "blue",
                borderColor: "blue",
              }}
              onClick={() => {
                this.props.onAdd()
              }}
            >
              <PlusOutlined /> Add
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export class RenderCollectionFilter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row
          className="d-flex justify-content-between"
          style={{ margin: "0px 60px 10px 60px", width: "100%" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <Col span={12}>
            <Row>
              <Col span={8}>
                <Select
                  allowClear={true}
                  placeholder="Creator Type"
                  onChange={value => {
                    this.props._handleFilterChange(value, "collectionType")
                  }}
                  defaultValue={"CHALLENGE"}
                  style={{ width: "100%" }}
                >
                  <Option value="CHALLENGE">Challenge</Option>
                  <Option value="CREATOR">Creator</Option>
                  <Option value="HASHTAG">Hashtag</Option>
                  <Option value="MUSIC_ARTIST">Music Artist</Option>
                  <Option value="MUSIC_LABEL">Music Label</Option>
                  <Option value="MUSIC_PLAYLIST">Music Playlist</Option>
                  <Option value="NAMED_COLLECTION">Named Collection</Option>
                </Select>
              </Col>
              <Col span={4}>
                <Button
                  type="dashed"
                  style={{
                    marginRight: "10px",
                    color: "blue",
                    borderColor: "blue",
                  }}
                  onClick={() => {
                    this.props._searchHashtag()
                  }}
                >
                  <SearchOutlined /> Search
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              type="dashed"
              style={{
                marginRight: "10px",
                color: "blue",
                borderColor: "blue",
              }}
              onClick={() => {
                this.props.onAdd()
              }}
            >
              <PlusOutlined /> Add
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export class RenderUserFilters extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row
          className="d-flex justify-content-between"
          style={{ margin: "0px 0px 10px 70px", width: "100%" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <Col span={20}>
            <Row>
              <Col span={12}>
                <Input.Group compact={true}>
                  <Select
                    placeholder="Search Type"
                    style={{ width: "45%" }}
                    allowClear={true}
                    onChange={value =>
                      this.props._handleFilterChange(value, "searchType")
                    }
                  >
                    <Option value="name">Name</Option>
                    <Option value="handle">Handle</Option>
                    <Option value="user_uuid">User UUID</Option>
                    <Option value="phone">Phone Number</Option>
                  </Select>
                  {(this.props.searchType === "user_uuid" ||
                    this.props.searchType === "name" ||
                    this.props.searchType === "handle" ||
                    this.props.searchType === "phone") && (
                    <Input
                      placeholder={"Search Value"}
                      onChange={e =>
                        this.props._handleFilterChange(
                          e.target.value,
                          "searchText"
                        )
                      }
                      allowClear={true}
                      name="searchId"
                      style={{ width: "55%" }}
                    />
                  )}
                  {this.props.searchType === "created_day" && (
                    <DatePicker
                      format="YYYY-MM-DD"
                      style={{ width: "55%" }}
                      onChange={(moment, dateString) => {
                        this.props._handleFilterChange(dateString, "searchText")
                      }}
                    />
                  )}
                </Input.Group>
              </Col>
              <Col span={7}>
                <Select
                  allowClear={true}
                  placeholder="Creator Type"
                  onChange={value => {
                    this.props._handleFilterChange(value, "creatorType")
                  }}
                  style={{ width: "90%" }}
                >
                  <Option value="">All</Option>
                  <Option value="ghost">Ghost</Option>
                  <Option value="internal">Internal</Option>
                  <Option value="ugc">UGC</Option>
                  <Option value="verified">Verified</Option>
                  <Option value="internalBrand">Internal Brand</Option>
                  <Option value="externalBrand">External Brand</Option>
                </Select>
              </Col>
              <Col span={3}>
                <Button
                  type="dashed"
                  style={{
                    marginRight: "10px",
                    color: "blue",
                    borderColor: "blue",
                  }}
                  onClick={() => {
                    this.props._searchHashtag()
                  }}
                >
                  <SearchOutlined /> Search
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Button
              type="dashed"
              style={{
                marginRight: "10px",
                color: "blue",
                borderColor: "blue",
              }}
              onClick={() => {
                this.props.onAdd()
              }}
            >
              <PlusOutlined /> Add
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export class RenderStickersFilter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row
          className="d-flex justify-content-between"
          style={{ margin: "0px 60px 10px 60px", width: "100%" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <Col span={24}>
            <Row>
              <Col span={6}>
                <Select
                  allowClear={true}
                  placeholder="Is Active"
                  onChange={value => {
                    this.props._handleFilterChange(value, "isActive")
                  }}
                  defaultValue={"true"}
                  style={{ width: "90%" }}
                >
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              </Col>
              <Col span={6}>
                <Select
                  style={{ width: "90%" }}
                  allowClear={true}
                  label="Mime Type"
                  placeholderLabel="Mime Type"
                  className="creator-form"
                  onChange={value =>
                    this.props._handleFilterChange(value, "mimeType")
                  }
                  defaultValue={"png"}
                  options={this.props.mimeTypes.map(mime => ({
                    label: mime,
                    value: mime,
                  }))}
                />
              </Col>
              <Col span={6}>
                <Input.Group>
                  <Input
                    placeholder={"Sticker Name"}
                    onChange={e =>
                      this.props._handleFilterChange(e.target.value, "name")
                    }
                    allowClear={true}
                    name="name"
                    style={{ width: "90%" }}
                  />
                </Input.Group>
              </Col>
              <Col span={6}>
                <div className="d-flex justify-content-end">
                  <Button
                    type="dashed"
                    style={{
                      marginRight: "10px",
                      color: "blue",
                      borderColor: "blue",
                    }}
                    onClick={() => {
                      this.props._searchStickers()
                    }}
                  >
                    <SearchOutlined /> Search
                  </Button>
                  <Button
                    type="dashed"
                    style={{
                      marginRight: "10px",
                      color: "blue",
                      borderColor: "blue",
                    }}
                    onClick={() => {
                      this.props.onAdd()
                    }}
                  >
                    <PlusOutlined /> Add
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export class RenderChallengeFilter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row
          className="d-flex justify-content-start"
          style={{ margin: "0px 0px 10px 60px", width: "100%" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <Col span={20}>
            <Row>
              <Col span={8}>
                <Input.Group compact={true}>
                  <Select
                    placeholder="Search Type"
                    style={{ width: "35%" }}
                    allowClear={true}
                    size="default"
                    onChange={value =>
                      this.props._handleFilterChange(value, "searchType")
                    }
                  >
                    <Option value="hashtag">Hashtag</Option>
                    <Option value="challengeId">Challenge Id</Option>
                  </Select>
                  <Input
                    placeholder="Search Text"
                    allowClear={true}
                    style={{ width: "65%" }}
                    onChange={e =>
                      this.props._handleFilterChange(
                        e.target.value,
                        "searchText"
                      )
                    }
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Input.Group>
              </Col>
              <Col span={5}>
                <Select
                  allowClear={true}
                  placeholder="Challenge Status"
                  style={{ width: "90%" }}
                  onChange={value =>
                    this.props._handleFilterChange(value, "status")
                  }
                >
                  <Option value="STARTED">Started</Option>
                  <Option value="NOT_STARTED">Not Started</Option>
                  <Option value="CREATED">Created</Option>
                  <Option value="CANCELLED">Cancelled</Option>
                  <Option value="ENDED">Ended</Option>
                </Select>
              </Col>
              <Col span={4}>
                <Select
                  allowClear={true}
                  placeholder="Challenge Type"
                  style={{ width: "90%" }}
                  onChange={value =>
                    this.props._handleFilterChange(value, "type")
                  }
                >
                  <Option value="HASHTAG">Hashtag</Option>
                  <Option value="AUDIO">Audio</Option>
                  <Option value="COLLAB">Collab</Option>
                </Select>
              </Col>
              <Col span={4}>
                <Select
                  allowClear={true}
                  style={{ width: "100%" }}
                  onChange={value =>
                    this.props._handleFilterChange(value, "sponsored")
                  }
                >
                  <Option value="Y">Ads</Option>
                  <Option value="N">Non Ads</Option>
                </Select>
              </Col>
              <Col span={3}>
                <Button
                  type="dashed"
                  style={{
                    marginRight: "10px",
                    color: "blue",
                    borderColor: "blue",
                  }}
                  onClick={() => {
                    this.props._searchHashtag()
                  }}
                >
                  <SearchOutlined /> Search
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Button
              type="dashed"
              style={{
                marginRight: "10px",
                color: "blue",
                borderColor: "blue",
              }}
              onClick={() => {
                this.props.onAdd()
              }}
            >
              <PlusOutlined /> Add
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export class RenderContentFilter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row
          className="d-flex justify-content-start"
          style={{ margin: "0px 0px 10px 60px", width: "100%" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <Col span={20}>
            <Row>
              <Col span={12}>
                <Input.Group compact={true}>
                  <Select
                    placeholder="Search Type"
                    style={{ width: "45%" }}
                    allowClear={true}
                    onChange={value =>
                      this.props._handleFilterChange(value, "searchType")
                    }
                  >
                    <Option value="content_uuid">Content UUID</Option>
                    <Option value="created_by">User UUID</Option>
                    <Option value="title">Title</Option>
                    <Option value="tag">Tag</Option>
                    <Option value="created_day">Created Day</Option>
                    <Option value="reject_reason">Reject Reason</Option>
                    <Option value="target_category">Approve Reason</Option>
                    <Option value="target_category_v2">Target Category</Option>
                    <Option value="reprocessed_moderation">
                      ReProcessed Moderation Level
                    </Option>
                  </Select>
                  {this.props["searchType"] !== "created_day" &&
                    this.props["searchType"] !== "reprocessed_moderation" &&
                    this.props["searchType"] !== "reject_reason" &&
                    this.props["searchType"] !== "target_category" &&
                    this.props["searchType"] !== "target_category_v2" && (
                      <Input
                        placeholder={"Search Value"}
                        onChange={e =>
                          this.props._handleFilterChange(
                            e.target.value.trim(),
                            "searchText"
                          )
                        }
                        allowClear={true}
                        name="searchId"
                        style={{ width: "55%" }}
                      />
                    )}
                  {this.props["searchType"] === "reject_reason" && (
                    <Select
                      onChange={e =>
                        this.props._handleFilterChange(e, "searchText")
                      }
                      allowClear={true}
                      placeholder="Reject Reason"
                      style={{ width: "55%" }}
                    >
                      {DEACTIVATE_REASONS.map(reason => (
                        <Option value={reason.value}>{reason.label}</Option>
                      ))}
                    </Select>
                  )}
                  {this.props.searchType === "created_day" && (
                    <DatePicker
                      format="YYYY-MM-DD"
                      style={{ width: "55%" }}
                      onChange={(moment, dateString) => {
                        this.props._handleFilterChange(dateString, "searchText")
                      }}
                    />
                  )}
                  {(this.props["searchType"] === "target_category" ||
                    this.props["searchType"] === "target_category_v2") && (
                    <Select
                      allowClear={true}
                      onChange={e =>
                        this.props._handleFilterChange(e, "searchText")
                      }
                      placeholder="Approval Reason"
                      style={{ width: "55%" }}
                    >
                      {TARGET_CATEGORY_V2.map(reason => (
                        <Option value={reason.value}>{reason.label}</Option>
                      ))}
                      <Option value="not_exist">Not Exist</Option>
                    </Select>
                  )}
                  {this.props["searchType"] === "reprocessed_moderation" && (
                    <Select
                      allowClear={true}
                      placeholder="New Auto Moderation Level"
                      onChange={e =>
                        this.props._handleFilterChange(e, "searchText")
                      }
                      style={{ width: "55%" }}
                    >
                      <Option value="nsfw">NSFW</Option>
                      <Option value="sfw">SFW</Option>
                    </Select>
                  )}
                </Input.Group>
              </Col>
              {/* <Col span={8}>
                <Select allowClear={true} placeholder="Moderation level"  onChange={(value) => { this.props._handleFilterChange(value, "moderationLevel") }} style={{ width: "90%" }}>
                  <Option value="">All</Option>
                  <Option value="3">Auto Approved</Option>
                  <Option value="0">Auto Disabled</Option>
                  <Option value="2">Human Approved</Option>
                  <Option value="-1">Human Disabled</Option>
                  <Option value="1">Unmoderated</Option>
                </Select>
              </Col> */}
              <Col span={4}>
                <Button
                  type="dashed"
                  style={{
                    marginRight: "10px",
                    color: "blue",
                    borderColor: "blue",
                  }}
                  onClick={() => {
                    this.props._searchHashtag()
                  }}
                >
                  <SearchOutlined /> Search
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Button
              type="dashed"
              style={{
                marginRight: "10px",
                color: "blue",
                borderColor: "blue",
              }}
              onClick={() => {
                this.props.onAdd()
              }}
            >
              <PlusOutlined /> Add
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export class RenderAudioFilter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row
          className="d-flex justify-content-start"
          style={{ margin: "0px 0px 10px 60px", width: "100%" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <Col span={22}>
            <Row>
              <Col span={5}>
                <Input
                  placeholder="Audio ID"
                  allowClear={true}
                  onChange={e => {
                    this.props._handleFilterChange(e.target.value, "audioId")
                  }}
                />
              </Col>
              <Col span={4}>
                <Input
                  placeholder="Title"
                  allowClear={true}
                  style={{ width: "95%" }}
                  onChange={e => {
                    this.props._handleFilterChange(e.target.value, "title")
                  }}
                />
              </Col>
              <Col span={4}>
                <Select
                  mode="multiple"
                  allowClear={true}
                  placeholder="Language"
                  onChange={value => {
                    this.props._handleFilterChange(value, "languages")
                  }}
                  style={{ width: "95%" }}
                >
                  <Option value="">All</Option>
                  {LANGUAGES.map(lang => (
                    <Option value={lang.value}>{lang.label}</Option>
                  ))}
                </Select>
              </Col>
              <Col span={4}>
                <Select
                  mode="multiple"
                  allowClear={true}
                  placeholder="Category"
                  onChange={value => {
                    this.props._handleFilterChange(value, "categories")
                  }}
                  style={{ width: "95%" }}
                >
                  {CATEGORIES.map(cat => (
                    <Option value={cat.value}>{cat.label}</Option>
                  ))}
                </Select>
              </Col>
              <Col span={4}>
                <Select
                  allowClear={true}
                  style={{ width: "95%" }}
                  disabled={this.props.currentUserType > 2}
                  onChange={value =>
                    this.props._handleFilterChange(value, "sponsored")
                  }
                >
                  <Option value="Y">Ads</Option>
                  <Option value="N">Non Ads</Option>
                </Select>
              </Col>
              <Col span={3}>
                <Button
                  type="dashed"
                  style={{
                    marginRight: "10px",
                    color: "blue",
                    borderColor: "blue",
                  }}
                  onClick={() => {
                    this.props._searchHashtag()
                  }}
                >
                  <SearchOutlined /> Search
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={2}>
            <Button
              type="dashed"
              style={{
                marginRight: "10px",
                color: "blue",
                borderColor: "blue",
              }}
              onClick={() => {
                this.props.onAdd()
              }}
            >
              <PlusOutlined /> Add
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export class RenderMixedAssetFilters extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row
          className="d-flex justify-content-between"
          style={{ margin: "0px 60px 10px 60px", width: "100%" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <Col span={16}>
            <Row>
              <Col span={12}>
                <Input.Group compact={true}>
                  <Select
                    placeholder="Search Type"
                    style={{ width: "90%" }}
                    defaultValue={1}
                    allowClear={true}
                    onChange={value =>
                      this.props._handleFilterChange(value, "searchType")
                    }
                    options={this.props.assetTypes.map(asset => ({
                      label: asset.assetTypeName,
                      value: asset.assetTypeId,
                    }))}
                  ></Select>
                </Input.Group>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    this.props._searchMixedAssetType()
                  }}
                >
                  <SearchOutlined /> Search
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{
                marginRight: "10px",
              }}
              onClick={() => {
                this.props.onAdd()
              }}
            >
              <PlusOutlined /> Add
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export class RenderStickerSubTypeFilters extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row
          className="d-flex justify-content-between"
          style={{ margin: "0px 60px 10px 60px", width: "100%" }}
          gutter={this.props.isMobile ? 0 : 8}
        >
          <Col>
            <Button
              type="dashed"
              style={{
                marginRight: "10px",
                color: "blue",
                borderColor: "blue",
              }}
              onClick={() => {
                this.props.onAdd()
              }}
            >
              <PlusOutlined /> Add
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export class RenderTitleCards extends React.Component {
  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {this.props.cardData.map(data => {
              return (
                <div
                  style={{ margin: "10px 0px 10px 60px" }}
                  onClick={() => this.props.callback(data.name)}
                >
                  <Card
                    style={{ width: 200 }}
                    cover={
                      <FontAwesomeIcon
                        style={{
                          width: "150px",
                          height: "150px",
                          color: "darkgrey",
                          padding: "10px",
                          margin: "auto",
                        }}
                        icon={faHashtag}
                      />
                    }
                    actions={[
                      <InfoCircleOutlined key="info" />,
                      <CheckCircleFilled
                        key="checked"
                        style={{
                          color: this.props.selectedData.includes(data.name)
                            ? "#28a745"
                            : "grey",
                        }}
                      />,
                    ]}
                  >
                    <Meta title={`${data.name}`} />
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export class RenderImageCards extends React.Component {
  render() {
    const {
      className = "",
      alt,
      coverTitle = "name",
      imageKey = "profile_pic",
      titleKey,
      keyToSend = "name",
      titleIcon = "",
      renderImage = false,
      renderCoverTitle = true,
    } = this.props
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {this.props.cardData.map(data => {
              return (
                <div
                  style={{ margin: "10px 0px 10px 60px" }}
                  onClick={() => this.props.callback(data[keyToSend])}
                >
                  <Card
                    style={{ width: 200 }}
                    cover={
                      data[imageKey] || alt ? (
                        <img
                          alt="image"
                          src={data[imageKey] || alt}
                          style={{ height: "200px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{
                            width: "150px",
                            height: "200px",
                            color: "darkgrey",
                            padding: "10px",
                            margin: "auto",
                          }}
                          icon={
                            imageKey === "profile_pic" ? faUser : faExclamation
                          }
                        />
                      )
                    }
                    actions={[
                      <InfoCircleOutlined key="info" />,
                      <CheckCircleFilled
                        key="checked"
                        style={{
                          color: this.props.selectedData.includes(
                            data[keyToSend]
                          )
                            ? "#28a745"
                            : "grey",
                        }}
                      />,
                    ]}
                  >
                    <Meta title={data[titleKey]} />
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export class RenderEntityCards1 extends React.Component {
  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {this.props.cardData.map(data => {
              return (
                <div
                  onClick={() =>
                    this.props.callback(data[TYPE_TO_KEY_MAP[this.props.type]])
                  }
                  className="card text-center userCardContent"
                  style={{ width: "11rem", height: "15rem" }}
                >
                  <div className="card-img-container">
                    <div>
                      {this.props.type !== "NAMED_COLLECTION" ? (
                        <img
                          className="card-img-top-custom"
                          src={data.thumbnail || data.static_thumbnail}
                          alt="Card image caption"
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="card-img-top-custom"
                          style={{
                            width: "150px",
                            height: "150px",
                            color: "darkgrey",
                            padding: "10px",
                            margin: "auto",
                          }}
                          icon={getImage(data)}
                        />
                      )}
                    </div>
                  </div>

                  <div>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: "700",
                        paddingTop: "5px",
                      }}
                    >
                      {data.title || data.page_title || data.heading_title}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        <Popover
                          placement="topLeft"
                          content="info"
                          trigger="click"
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                      <div>
                        <div
                          style={{
                            color: this.props.selectedData.includes(
                              data[TYPE_TO_KEY_MAP[this.props.type]]
                            )
                              ? "#28a745"
                              : "grey",
                          }}
                        >
                          <CheckCircleFilled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export class RenderEntityCards extends React.Component {
  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {this.props.cardData.map(data => {
              return (
                <div
                  style={{ margin: "10px 10px 10px 60px" }}
                  onClick={() =>
                    this.props.callback(data[TYPE_TO_KEY_MAP[this.props.type]])
                  }
                >
                  <Card
                    style={{ width: 200 }}
                    cover={
                      this.props.type !== "NAMED_COLLECTION" ? (
                        data.thumbnail || data.static_thumbnail ? (
                          <img
                            src={data.thumbnail || data.static_thumbnail}
                            alt="Card image caption"
                            style={{ height: "150px" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            style={{
                              width: "150px",
                              height: "150px",
                              color: "darkgrey",
                              padding: "10px",
                              margin: "auto",
                            }}
                            icon={getImage({ collectionType: this.props.type })}
                          />
                        )
                      ) : (
                        <FontAwesomeIcon
                          style={{
                            width: "150px",
                            height: "150px",
                            color: "darkgrey",
                            padding: "10px",
                            margin: "auto",
                          }}
                          icon={getImage(data)}
                        />
                      )
                    }
                    actions={[
                      <Popover
                        trigger="hover"
                        content=""
                        color="#D3D3D3"
                        mouseEnterDelay="1"
                        destroyTooltipOnHide
                      >
                        <InfoCircleOutlined key="info" />
                      </Popover>,
                      <CheckCircleFilled
                        key="checked"
                        style={{
                          color: this.props.selectedData.includes(
                            data[TYPE_TO_KEY_MAP[this.props.type]]
                          )
                            ? "#28a745"
                            : "grey",
                        }}
                      />,
                    ]}
                  >
                    <Meta
                      title={
                        data.title || data.page_title || data.heading_title
                      }
                    />
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export class RenderAudioCards extends React.Component {
  _renderCardContent = audio => {
    return (
      <div className="card" style={{ width: "20rem" }}>
        <audio
          style={{ width: "100%" }}
          key={audio.id}
          controlsList="nodownload"
          src={audio.url}
          controls
          onContextMenu={e => {
            e.preventDefault()
            return false
          }}
        />
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {this.props.cardData.map(audio => {
              const data = {}
              return (
                <div
                  style={{ margin: "10px 10px 10px 60px" }}
                  onClick={() => this.props.callback(audio.id)}
                >
                  <Card
                    style={{ width: 200 }}
                    cover={
                      <Popover
                        trigger="hover"
                        content={() => this._renderCardContent(audio)}
                        color="#D3D3D3"
                        mouseEnterDelay="1"
                        destroyTooltipOnHide
                      >
                        {audio.album_art ? (
                          <img
                            alt="image"
                            src={audio.album_art}
                            style={{ width: 200, height: 200 }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            style={{
                              width: "150px",
                              height: "150px",
                              color: "darkgrey",
                              padding: "10px",
                              margin: "auto",
                            }}
                            icon={faMusic}
                          />
                        )}
                      </Popover>
                    }
                    actions={[
                      <Popover
                        trigger="hover"
                        content={() => this._renderCardContent(audio)}
                        color="#D3D3D3"
                        mouseEnterDelay="1"
                        destroyTooltipOnHide
                      >
                        <InfoCircleOutlined key="info" />
                      </Popover>,
                      <CheckCircleFilled
                        key="checked"
                        style={{
                          color: this.props.selectedData.includes(audio.id)
                            ? "#28a745"
                            : "grey",
                        }}
                      />,
                    ]}
                  >
                    <Meta title={audio.title} />
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export const getImage = data => {
  let img = faExclamation
  let icon = "fas fa-exclamation"
  if (
    data.collectionType === "NAMED_COLLECTION" ||
    data.collection_type === "NAMED_COLLECTION"
  ) {
    if (data.elementType || data.element_type) {
      switch (data.elementType || data.element_type) {
        case "MUSIC":
          img = faMusic
          icon = "fas fa-music"
          break
        case "MUSIC_LABEL":
          img = faCompactDisc
          icon = "fas fa-compact-disc"
          break
        case "VIDEO":
        case "CHALLENGE":
          img = faVideo
          icon = "fas fa-video"
          break
        case "MUSIC_PLAYLIST":
          img = faPlayCircle
          icon = "fas fa-play-circle"
          break
        case "HASHTAG":
          img = faHashtag
          icon = "fas fa-hashtag"
          break
        case "BANNER":
        case "SUBBANNER":
        case "VIDOE_BANNER":
        case "WEBVIEW_BANNER":
          img = faFlag
          icon = "fas fa-flag"
          break
        case "CHIP":
          img = faFlag
          icon = "fas fa-align-justify"
          break
        case "MUSIC_ARTIST":
        case "CREATOR":
          img = faUser
          icon = "fas fa-user"
          break
        case "GAME":
          img = faGamepad
          icon = "fas fa-gamepad"
          break
        case "TEMPLATE":
          img = faImages
          icon = "fas fa-images"
          break
        case "STICKER":
          img = faTheaterMasks
          icon = "fas fa-theater-masks"
          break
        case "EFFECT":
          img = faGamepad
          icon = "fas fa-magic"
          break
        case "ZONE":
          img = faGamepad
          icon = "fas fa-dot-circle"
          break
        case "IMAGE":
          img = faGamepad
          icon = "fas fa-file-image"
          break
      }
    } else img = faExclamation
  } else {
    switch (data.collectionType || data.collection_type) {
      case "HASHTAG":
        img = faHashtag
        icon = "fas fa-hashtag"
        break
      case "BANNER":
        img = faFlag
        icon = "fas fa-flag"
        break
      case "WEBVIEW_BANNER":
        img = faFlag
        icon = "fas fa-flag"
        break
      case "CHIP":
        img = faFlag
        icon = "fas fa-align-justify"
        break
      case "MUSIC_ARTIST":
      case "CREATOR":
        img = faUser
        icon = "fas fa-user"
        break
      case "MUSIC_LABEL":
        img = faCompactDisc
        icon = "fas fa-compact-disc"
        break
      case "MUSIC_PLAYLIST":
        img = faPlayCircle
        icon = "fas fa-play-circle"
        break
      case "CHALLENGE":
        img = faVideo
        icon = "fas fa-video"
        break
      case "GAME":
        img = faGamepad
        icon = "fas fa-gamepad"
        break
      case "TEMPLATE":
        img = faImages
        icon = "fas fa-images"
        break
      case "STICKER":
        img = faTheaterMasks
        icon = "fas fa-theater-masks"
        break
      case "EFFECT":
        img = faMagic
        icon = "fas fa-magic"
        break
      case "MUSIC":
        img = faMicrophone
        icon = "fas fa-microphone"
        break
    }
  }
  return icon
}
export class RenderCollectionCards extends React.Component {
  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {this.props.cardData.map(data => {
              return (
                <div
                  style={{ margin: "10px 10px 10px 60px", cursor: "pointer" }}
                  onClick={() =>
                    this.props.callback({
                      [`${data.collectionId}:${data.collectionType}`]: data,
                    })
                  }
                >
                  <Card
                    style={{ width: 200 }}
                    cover={
                      data.thumbnail || data.profile_pic || data.banner_url ? (
                        <img
                          alt=""
                          src={
                            data.thumbnail ||
                            data.profile_pic ||
                            data.banner_url
                          }
                          style={{ height: 150 }}
                        />
                      ) : (
                        <div className="d-flex justify-content-around">
                          <i
                            className={`${getImage(data)} fa-7x text-black-50`}
                          />
                        </div>
                      )
                    }
                    actions={[
                      <InfoCircleOutlined key="info" />,
                      <CheckCircleFilled
                        key="checked"
                        onClick={() =>
                          this.props.callback({
                            [`${data.collectionId}:${data.collectionType}`]:
                              data,
                          })
                        }
                        style={{
                          color: Object.keys(this.props.selectedData).includes(
                            `${data.collectionId}:${data.collectionType}`
                          )
                            ? "#28a745"
                            : "grey",
                        }}
                      />,
                    ]}
                  >
                    <Meta
                      title={
                        data["title"] ||
                        data["heading_title"] ||
                        data["hashtag"] ||
                        data["name"] ||
                        data["collectionId"]
                      }
                    />
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export class RenderVideoCards extends React.Component {
  _renderCardContent = data => {
    const videSource = data.download_url ? data.download_url : null
    return (
      <div className="card" style={{ width: "20rem" }}>
        {videSource && (
          <video
            src={videSource}
            controls
            autoPlay
            controlsList={"nodownload"}
            loop
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        )}
        <div className="card-body">
          <h5 className="card-title">{data.content_title}</h5>
        </div>
      </div>
    )
  }

  _renderThumbnail = content => {
    if (content.download_url || content.thumbnail) {
      switch (content.format) {
        case "IMAGE":
          return (
            <img src={content.download_url} width="150" height="200" alt="" />
          )
        case "GIF":
          return (
            <img src={content.download_url} width="150" height="200" alt="" />
          )
        default:
          return (
            <img
              src={content.thumbnail.replace("_r1_", "_v1_")}
              width="150"
              height="200"
              alt=""
            />
          )
      }
    } else
      return (
        <FontAwesomeIcon
          style={{
            width: "150px",
            height: "200px",
            color: "darkgrey",
            padding: "10px",
            margin: "auto",
          }}
          icon={faVideo}
        />
      )
  }

  render() {
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {this.props.cardData.map(data => {
              return (
                <div
                  style={{ margin: "10px 10px 10px 60px" }}
                  onClick={() => this.props.callback(data.content_uuid)}
                >
                  <Card
                    style={{ width: 200 }}
                    cover={
                      <Popover
                        trigger="hover"
                        content={() => this._renderCardContent(data)}
                        color="#D3D3D3"
                        mouseEnterDelay="1"
                        destroyTooltipOnHide
                      >
                        {this._renderThumbnail(data)}
                      </Popover>
                    }
                    actions={[
                      <Popover
                        trigger="hover"
                        content={() => this._renderCardContent(data)}
                        color="#D3D3D3"
                        mouseEnterDelay="1"
                        destroyTooltipOnHide
                      >
                        <InfoCircleOutlined key="info" />
                      </Popover>,
                      <CheckCircleFilled
                        key="checked"
                        style={{
                          color: this.props.selectedData.includes(
                            data.content_uuid
                          )
                            ? "#28a745"
                            : "grey",
                        }}
                      />,
                    ]}
                  >
                    <Meta
                      title={
                        data.content_title
                          ? data.content_title
                          : data.prepared_content_title
                          ? data.prepared_content_title
                          : "<No Title>"
                      }
                    />
                  </Card>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export class RenderAssetType extends React.Component {
  _getInfo = data => {
    return data.name ? (
      <div> Name: {data.name} </div>
    ) : (
      <div> ID: {data.id} </div>
    )
  }

  render() {
    const { selectedData } = this.props
    // const mappedAssets = [...new Map(selectedData.map(item => [item["pk"], item])).values()];
    const assetPk = selectedData.map(item => item.pk)
    return (
      <div>
        {this.props.assets && this.props.assets.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {this.props.assets.map(data => {
              return (
                <div
                  style={{ margin: "10px 10px 10px 60px" }}
                  onClick={() => this.props.callback(data)}
                >
                  <Card
                    style={{ width: 200 }}
                    cover={
                      <img
                        src={data.coverUrl}
                        width="150"
                        height="200"
                        alt=""
                      />
                    }
                    actions={[
                      <Popover
                        trigger="hover"
                        content={() => this._getInfo(data)}
                        color="#D3D3D3"
                        mouseEnterDelay="1"
                        destroyTooltipOnHide
                      >
                        <InfoCircleOutlined key="info" />
                      </Popover>,
                      <CheckCircleFilled
                        key="checked"
                        style={{
                          color: assetPk.includes(data["pk"])
                            ? "#28a745"
                            : "grey",
                        }}
                      />,
                    ]}
                  >
                    <Meta title={data.name ? data.name : data.id} />
                  </Card>
                </div>
              )
            })}
          </div>
        )}
        {this.props.assets && this.props.assets.length <= 0 && (
          <div>No data present</div>
        )}
      </div>
    )
  }
}

export class RenderStickerSubType extends React.Component {
  _getInfo = data => {
    return data.name ? (
      <div> Name: {data.name} </div>
    ) : (
      <div> ID: {data.id} </div>
    )
  }

  render() {
    const { selectedData } = this.props
    // const mappedAssets = [...new Map(selectedData.map(item => [item["pk"], item])).values()];
    const subTypeIds = selectedData.map(item => item.subTabId)
    return (
      <div>
        {this.props.cardData && this.props.cardData.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {this.props.cardData.map(data => {
              return (
                <div
                  style={{ margin: "10px 10px 10px 60px" }}
                  onClick={() => this.props.callback(data)}
                >
                  <Card
                    style={{ width: 200 }}
                    // cover={<img src={data.coverUrl} width="150" height="200" alt="" />}
                    actions={[
                      <Popover
                        trigger="hover"
                        content={() => this._getInfo(data)}
                        color="#D3D3D3"
                        mouseEnterDelay="1"
                        destroyTooltipOnHide
                      >
                        <InfoCircleOutlined key="info" />
                      </Popover>,
                      <CheckCircleFilled
                        key="checked"
                        style={{
                          color: subTypeIds.includes(data["subTabId"])
                            ? "#28a745"
                            : "grey",
                        }}
                      />,
                    ]}
                  >
                    <Meta
                      title={
                        data.displayName ? data.displayName : data.subTabId
                      }
                    />
                  </Card>
                </div>
              )
            })}
          </div>
        )}
        {this.props.cardData && this.props.cardData.length <= 0 && (
          <div>No data present</div>
        )}
      </div>
    )
  }
}

export class RenderStickers extends React.Component {
  _getInfo = data => {
    return data.name ? (
      <div> Name: {data.name} </div>
    ) : (
      <div> ID: {data.id} </div>
    )
  }

  render() {
    const { selectedData } = this.props
    const { rows = [] } = this.props.cardData
    // const mappedAssets = [...new Map(selectedData.map(item => [item["pk"], item])).values()];
    const stickerIds = selectedData.map(item => item.stickerId)
    return (
      <div>
        {rows && rows.length > 0 && (
          <div className="d-flex flex-wrap" style={{ paddingLeft: "60px" }}>
            {rows.map(data => {
              return (
                <div
                  style={{ margin: "10px 10px 10px 60px" }}
                  onClick={() => this.props.callback(data)}
                >
                  <Card
                    style={{ width: 200 }}
                    cover={
                      <img
                        src={data.thumbnailUrl}
                        width="150"
                        height="200"
                        alt=""
                      />
                    }
                    actions={[
                      <Popover
                        trigger="hover"
                        content={() => this._getInfo(data)}
                        color="#D3D3D3"
                        mouseEnterDelay="1"
                        destroyTooltipOnHide
                      >
                        <InfoCircleOutlined key="info" />
                      </Popover>,
                      <CheckCircleFilled
                        key="checked"
                        style={{
                          color: stickerIds.includes(data["stickerId"])
                            ? "#28a745"
                            : "grey",
                        }}
                      />,
                    ]}
                  >
                    <Meta
                      title={
                        data.stickerName ? data.stickerName : data.stickerId
                      }
                    />
                  </Card>
                </div>
              )
            })}
          </div>
        )}
        {rows && rows.length <= 0 && <div>No data present</div>}
      </div>
    )
  }
}
