import { createSlice } from "@reduxjs/toolkit"

const acitivitySlice = createSlice({
  name: "activity",
  initialState: {
    activityLogData: null,
    loading: false,
    reportList: [],
  },
  reducers: {
    getActivityList(state, action) {
      return {
        ...state,
        activityLogData: [],
        loading: true,
      }
    },

    getActivityListSuccess(state, { payload }) {
      return {
        ...state,
        activityLogData: payload,
        loading: false,
      }
    },

    getBulkReportList(state, action) {
      return {
        ...state,
        loading: true,
        reportList: [],
      }
    },
    getBulkReportListSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        reportList: payload.data.reportList,
        count: payload.data.count,
        success_count: payload.data.success_count,
        fail_count: payload.data.fail_count,
        processing_count: payload.data.processing_count,
      }
    },
    resetState(state, action) {
      return {
        ...state,
        activityLogData: [],
        loading: false,
        refetchData: false,
      }
    },
  },
})

export const {
  getBulkReportList,
  getBulkReportListSuccess,
  resetState,
  getActivityList,
  getActivityListSuccess,
} = acitivitySlice.actions

export default acitivitySlice.reducer
