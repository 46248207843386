import { createSlice } from "@reduxjs/toolkit"

const trendingSlice = createSlice({
  name: "trending",
  initialState: {
    trendingEntityList: [],
    loading: false,
    refetchData: false,
    deskData: [],
  },
  reducers: {
    getTrendingSourceData(state) {
      return { ...state, loading: true, deskData: [] }
    },

    getTrendingSourceDataSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        deskData: payload.data.data,
        refetchData: false,
      }
    },

    getTrendingEntityByFilters(state, action) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        user: [],
        audioDetails: [],
      }
    },
    getTrendingEntityByFiltersSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        trendingEntityList: payload.data && payload.data.data,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        audioDetails: payload.data.audio,
        refetchData: false,
      }
    },
    entityUpsert(state) {
      return {
        ...state,
        loading: true,
      }
    },
    entityUpsertSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    markTrending(state) {
      return {
        ...state,
        loading: true,
      }
    },
    markTrendingSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState(state) {
      return {
        trendingEntityList: [],
        loading: false,
        refetchData: false,
      }
    },
  },
})

export const {
  getTrendingEntityByFilters,
  getTrendingEntityByFiltersSuccess,
  resetState,
  getTrendingSourceDataSuccess,
  getTrendingSourceData,
  entityUpsert,
  entityUpsertSuccess,
  markTrending,
  markTrendingSuccess,
} = trendingSlice.actions

export default trendingSlice.reducer
