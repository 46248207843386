import React from "react"
import { connect } from "react-redux"
import {
  Button,
  Modal,
  message,
  Row,
  Col,
  Tooltip,
  Card,
  Popconfirm,
} from "antd"
import { Card as ReactCard, CardBody, Badge } from "reactstrap"
import {
  getTabsList,
  upsertTabAsset,
  deleteTabById,
  updateTabOrder,
  updateTabVersion,
  resetState,
} from "appRedux/slices/videoAssets"
import UpsertCategoryAssetsForm from "./upsertCategoryAssetsForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import toastr from "toastr"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import "toastr/build/toastr.min.css"
import "./cameraAsset.style.css"

const { Meta } = Card

class ManageCategoryAssets extends ErrorBoundComponent {
  state = {
    filters: {},
    showUpsertModal: false,
    videoAsset: {},
    tabAsset: {},
    tabsData: [],
    tabsListFromProps: [],
    showOrder: false,
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(getTabsList())
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.refetchData &&
      !this.props.common.error &&
      prevProps.refetchData !== this.props.refetchData
    ) {
      this._closeUpsertModal()
      this.props.dispatch(getTabsList())
    }
    if (prevProps.tabsList !== this.props.tabsList) {
      const initOrder = []
      for (let tab of this.props.tabsList) {
        initOrder.push(tab["tab_id"])
      }
      this.setState({
        tabsData: initOrder,
        tabsListFromProps: this.props.tabsList,
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onSubmit = async (formData, iconData) => {
    const { tabAsset } = this.state
    const { tabsList } = this.props
    const updateFormData = {}

    for (let tab of tabsList) {
      if (
        this.state.formAction === "create" &&
        formData.tabName === tab.tab_name
      ) {
        message.error("Tab already exists with this name")
        return
      }

      if (
        this.state.formAction === "update" &&
        this.state.tabAsset.tab_id !== tab.tab_id &&
        formData.tabName === tab.tab_name
      ) {
        message.error("Tab already exists with this name")
        return
      }
    }
    if (formData.isActive !== tabAsset.is_active) {
      updateFormData["isActive"] = formData.isActive
    }
    if (formData.tabName !== tabAsset.tab_name) {
      updateFormData["tabName"] = formData.tabName
    }
    if (formData.redDotEnabled !== tabAsset.red_dot_enabled) {
      updateFormData["redDotEnabled"] = formData.redDotEnabled
    }

    const payload =
      this.state.formAction === "update" ? updateFormData : formData
    const payloadData = new FormData()

    payloadData.append("payload", JSON.stringify(payload))

    if (iconData) {
      payloadData.append("iconUrl", iconData)
    }

    const data = {
      payloadData,
      query: {
        tabId: tabAsset.tab_id,
        action: this.state.formAction,
      },
    }
    this.props.dispatch(upsertTabAsset(data))
    this.setState({
      showUpsertModal: false,
    })
  }

  _closeUpsertModal = () => {
    this.setState({
      showUpsertModal: false,
      formAction: "create",
      tabAsset: {},
    })
  }

  _onCreateVideoAsset = () => {
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_MANAGE_CATEGORY_ASSETS"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({ showUpsertModal: true, formAction: "create", tabAsset: {} })
  }

  _onEditTabAsset = (e, tabAsset) => {
    e.preventDefault()
    e.stopPropagation()
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_MANAGE_CATEGORY_ASSETS"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({
      showUpsertModal: true,
      formAction: "update",
      tabAsset: tabAsset,
    })
  }

  _onDeleteTabById = (e, tabId) => {
    e.preventDefault()
    e.stopPropagation()
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_MANAGE_CATEGORY_ASSETS"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.props.dispatch(deleteTabById(tabId))
  }

  _onUpdateVersion = (e, tabId) => {
    e.preventDefault()
    e.stopPropagation()
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_MANAGE_CATEGORY_ASSETS"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.props.dispatch(updateTabVersion(tabId))
  }

  _upsertModal = () => {
    return (
      <UpsertCategoryAssetsForm
        formAction={this.state.formAction}
        tabAsset={this.state.tabAsset}
        onSubmit={this._onSubmit}
        onCancel={this._closeModal}
      />
    )
  }

  _closeModal = () => {
    this.setState({
      showUpsertModal: false,
    })
  }

  _modal = () => {
    return <Card>{this._upsertModal()}</Card>
  }

  _actionButtons = () => {
    return (
      <div className="d-flex justify-content-end mb-2">
        <button
          className="btn btn-primary"
          style={{ marginRight: "5px" }}
          onClick={() => {
            if (
              !this.props.currentUser?.permissions?.includes(
                "EDIT_MANAGE_CATEGORY_ASSETS"
              )
            ) {
              toastr.error(NO_PERMISSION_MESSAGE)
              return
            }
            this.setState({ showOrder: true })
          }}
        >
          Reorder
        </button>
        <button className="btn btn-primary" onClick={this._onCreateVideoAsset}>
          Create
        </button>
      </div>
    )
  }

  _getColor = tabType => {
    switch (tabType) {
      case "BASIC":
        return "mediumorchid"
      case "MIXED":
        return "hotpink"
      case "LOCAL":
        return "dodgerblue"
      default:
        return ""
    }
  }

  _updateTabOrder = () => {
    const { tabsListFromProps } = this.state
    const payload = []
    const localTabs = tabsListFromProps.filter(tab => tab.tab_type === "LOCAL")
    for (let i = 0; i < localTabs.length; i++) {
      payload.push({
        tabId: localTabs[i]["tab_id"],
        viewOrder: i,
      })
    }
    const remoteData = tabsListFromProps.filter(tab => tab.tab_type !== "LOCAL")
    for (let i = localTabs.length; i < remoteData.length; i++) {
      payload.push({
        tabId: remoteData[i - localTabs.length]["tab_id"],
        viewOrder: i,
      })
    }
    this.props.dispatch(updateTabOrder({ payload }))
    this.setState({ showOrder: false })
  }

  _getRedirectUrl = tab => {
    let redirectUrl = ""
    if (tab.tab_operation !== "LOCAL") {
      redirectUrl =
        tab.tab_operation !== "MIXED"
          ? this.props.history.push(
              `/videoAssets/manage?typeId=${tab.tab_id}&tabType=${tab.tab_operation}&assetTypeId=${tab.asset_type}&tabName=${tab.tab_name}`
            )
          : this.props.history.push(
              `/videoAssets/manage?typeId=${tab.tab_id}&tabType=${tab.tab_operation}`
            )
    }
    return redirectUrl
  }

  _getCover = tabType => {
    switch (tabType) {
      case "MIXED":
        return "fa fa-infinity"
      case "LOCAL":
        return "fa fa-laptop-house"
      case 1:
        return "fa fa-filter"
      case 2:
        return "fa fa-camera-retro"
      case 3:
        return "fa fa-camera-retro"
      case 4:
        return "fa fa-binoculars"
      case 5:
        return "fa fa-diagnoses"
      case 6:
        return "fa fa-grin-hearts"
      case 7:
        return "fa fa-gamepad"
      default:
        return "fa fa-meh"
    }
  }

  _tabsList = () => {
    return (
      <div className="trending-card-container">
        {this.props.tabsList.map(tab => (
          <ReactCard
            className="overflow-hidden text-center shadow-lg"
            key={tab.tab_id || tab.tab_name}
          >
            <CardBody
              className="px-0 py-0"
              style={{ cursor: "pointer" }}
              onClick={() => this._getRedirectUrl(tab)}
            >
              <div style={{ margin: "20px" }}>
                <i
                  className={this._getCover(
                    tab.asset_type ? tab.asset_type : tab.tab_operation
                  )}
                  style={{
                    fontSize: "15vh",
                    color: this._getColor(tab.tab_operation),
                  }}
                ></i>
              </div>
              {tab.tab_name && (
                <Badge pill className="badge-soft-primary m-1">
                  <div className="p-1 font-size-12">{tab.tab_name}</div>
                </Badge>
              )}
              {tab.tab_operation && (
                <div className="my-1 mb-1">
                  <Badge pill className="badge-soft-success m-1">
                    <div className="p-1 font-size-12">{tab.tab_operation}</div>
                  </Badge>
                </div>
              )}
            </CardBody>
            <div className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center">
              <div className="flex-fill">
                <Tooltip title="Edit Assets">
                  <i
                    className="bx bx-edit-alt"
                    role="button"
                    onClick={
                      tab.tabType !== "LOCAL"
                        ? e => this._onEditTabAsset(e, tab)
                        : ""
                    }
                  />
                </Tooltip>
              </div>
              <div className="flex-fill">
                <Tooltip title="Update Version">
                  <i
                    className="bx bx-up-arrow"
                    role="button"
                    onClick={e =>
                      this._onUpdateVersion(e, { tabId: tab.tab_id })
                    }
                  />
                </Tooltip>
              </div>
              <div className="flex-fill">
                <Popconfirm
                  placement="topRight"
                  title="Are you sure you want to delete this Sticker Tab?"
                  onConfirm={
                    tab.tabType !== "LOCAL"
                      ? e => this._onDeleteTabById(e, { tabId: tab.tab_id })
                      : ""
                  }
                >
                  <i className="bx bx bx-trash-alt text-danger" role="button" />
                </Popconfirm>
              </div>
            </div>
          </ReactCard>
        ))}
      </div>
    )
  }

  _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  _onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const tabsListFromProps = this._reorder(
      this.state.tabsListFromProps,
      result.source.index,
      result.destination.index
    )

    this.setState({
      tabsListFromProps,
    })
  }

  _closeTabOrder = () => {
    this.setState({ showOrder: false })
  }

  _createTabOrder = () => {
    return (
      <Modal
        centered
        onOk={this._updateTabOrder}
        visible={this.state.showOrder}
        onCancel={this._closeTabOrder}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={this._updateTabOrder}
            style={{ textAlign: "left" }}
          >
            UPDATE
          </Button>,
        ]}
        title="Order Category Tabs"
        destroyOnClose
        bodyStyle={{ overflowY: "auto", overflowX: "hidden", height: "60vh" }}
      >
        <div style={{ marginBottom: 30 }} bordered={true}>
          <DragDropContext onDragEnd={this._onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.tabsListFromProps.map((tab, index) => (
                    <Draggable
                      key={`${tab["tab_id"]}`}
                      draggableId={`${tab["tab_id"]}`}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {tab.tab_type !== "LOCAL" && (
                            <Row gutter={16}>
                              <Col sm={24} md={8} lg={6} xl={4}>
                                <Card
                                  className="cardStyle"
                                  cover={
                                    <FontAwesomeIcon
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        margin: "auto",
                                        color: this._getColor(
                                          tab.tab_operation
                                        ),
                                      }}
                                      icon={this._getCover(
                                        tab.asset_type
                                          ? tab.asset_type
                                          : tab.tab_operation
                                      )}
                                    />
                                  }
                                  bordered={true}
                                  style={{
                                    padding: "12px",
                                    display: "flex",
                                    width: "460px",
                                  }}
                                >
                                  <Meta
                                    title={tab.tab_name}
                                    description={tab.tab_operation}
                                  />
                                </Card>
                              </Col>
                            </Row>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {!this.state.showOrder &&
          !this.state.showUpsertModal &&
          this._actionButtons()}
        {this.state.showUpsertModal && this._modal()}
        {!this.state.showOrder &&
          !this.state.showUpsertModal &&
          this._tabsList()}
        {this.state.showOrder && this._createTabOrder()}
      </Auxiliary>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    tabsList: _.get(store, "videoAssets.tabsList"),
    refetchData: _.get(store, "videoAssets.refetchData"),
    loading: _.get(store, "videoAssets.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageCategoryAssets)
