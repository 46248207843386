import Axios from "axios"
import toastr from "toastr"

export const requestCreateReview = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/review/createReview`, payload)
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
