import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import _ from "lodash"
import { connect } from "react-redux"
import { map } from "lodash"
import ReactTimeAgo from "react-time-ago"
import { Tooltip } from "antd"
import toastr from "toastr"
import { Card, CardContent, Chip, Stack } from "@mui/material"
import { Link } from "react-router-dom"

class BulkUploadreportList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reportList: this.props.reportList,
    }
  }

  _renderRouteComponent = report => {
    let route = null
    const type = report.entity_type
    switch (type) {
      case "CONTENT":
        route = (
          <Link
            to={`/content/manage?contentUUID=${report.output_entity_id}`}
            target="_blank"
            style={{ color: "#000" }}
          >
            <i className="fontSize18 bx bx-link-external" role="button" />
          </Link>
        )
        break
      case "USER":
        route = (
          <Link
            to={`/appuser/details?userUuid=${report.output_entity_id}`}
            target="_blank"
            style={{ color: "#000" }}
          >
            <i className="fontSize18 bx bx-link-external" role="button" />
          </Link>
        )
        break

      default:
        break
    }
    return route
  }

  render() {
    return (
      <>
        <React.Fragment>
          {map(this.state.reportList, report => {
            const inputMeta = JSON.parse(report.input_meta)
            let statusBadgeClass = "success"
            if (report.status === "FAILED") {
              statusBadgeClass = "error"
            }
            if (report.status === "PROCESSING_STARTED") {
              statusBadgeClass = "info"
            }
            return (
              <Card className="mt-2">
                <CardContent>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div
                        className="fontSize18 my-1 mb-1 ml-6 fw-bold"
                        style={{
                          maxWidth: "55rem",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Tooltip
                          title={
                            <span>
                              <b>Title:</b> $
                              {inputMeta.Title ||
                                inputMeta.UserHandle ||
                                inputMeta.title ||
                                inputMeta.TITLE}
                            </span>
                          }
                        >
                          {inputMeta.Title ||
                            inputMeta.UserHandle ||
                            inputMeta.title ||
                            inputMeta.TITLE}
                        </Tooltip>
                      </div>
                      <div className="d-flex justify-content-start">
                        <Stack direction="row" spacing={1}>
                          {report.status ? (
                            <Tooltip title="Status">
                              <Chip
                                size="small"
                                color={statusBadgeClass}
                                variant="filled"
                                className={`${statusBadgeClass} ms-1 p-2 font-size-12`}
                                label={report.status.split("_")[0]}
                              />
                            </Tooltip>
                          ) : null}
                          {report.csv_name ? (
                            <Chip
                              color="primary"
                              variant="outlined"
                              size="small"
                              label={report.csv_name}
                            />
                          ) : null}
                          {report.uploaded_by ? (
                            <Chip
                              color="success"
                              variant="outlined"
                              label={report.uploaded_by}
                              size="small"
                            />
                          ) : null}
                          {report.created_on ? (
                            <Tooltip title="Created On">
                              <Chip
                                color="warning"
                                variant="outlined"
                                size="small"
                                label={
                                  <ReactTimeAgo
                                    date={
                                      Number.isNaN(report.created_on)
                                        ? Date.parse(report.created_on)
                                        : report.created_on
                                    }
                                    locale="en-US"
                                  />
                                }
                              />
                            </Tooltip>
                          ) : null}
                          {report.updated_on ? (
                            <Tooltip title="Updated On">
                              <Chip
                                color="info"
                                variant="outlined"
                                size="small"
                                label={
                                  <ReactTimeAgo
                                    date={
                                      Number.isNaN(report.updated_on)
                                        ? Date.parse(report.updated_on)
                                        : report.updated_on
                                    }
                                    locale="en-US"
                                  />
                                }
                              />
                            </Tooltip>
                          ) : null}
                          {report.error_details ? (
                            <Chip
                              color="secondary"
                              size="small"
                              variant="outlined"
                              style={{
                                maxWidth: "20rem",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              label={
                                <Tooltip
                                  title={
                                    <span>
                                      <b>Error Details:</b>{" "}
                                      {report.error_details}
                                    </span>
                                  }
                                >
                                  {report.error_details}
                                </Tooltip>
                              }
                            />
                          ) : null}
                          {report.details ? (
                            <Chip
                              color="secondary"
                              size="small"
                              variant="outlined"
                              style={{
                                maxWidth: "20rem",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              label={
                                <Tooltip
                                  title={
                                    <span>
                                      <b>Details:</b> {report.details}
                                    </span>
                                  }
                                >
                                  {report.details}
                                </Tooltip>
                              }
                            />
                          ) : null}
                        </Stack>
                      </div>
                    </div>

                    <Stack direction="row" spacing={1}>
                      {report.status === "DONE" && (
                        <>{this._renderRouteComponent(report)}</>
                      )}
                      {report.status === "DONE" && (
                        <CopyToClipboard
                          text={report.output_entity_id || inputMeta.audio_id}
                          onCopy={e => {
                            toastr.success("Copied sucessfully!")
                          }}
                        >
                          <Tooltip title="Copy Output Entity Id">
                            <i
                              className="fontSize18 bx bx-copy-alt"
                              role="button"
                            />
                          </Tooltip>
                        </CopyToClipboard>
                      )}
                      <CopyToClipboard
                        text={report.input_meta}
                        onCopy={e => {
                          toastr.success("Copied sucessfully!")
                        }}
                      >
                        <Tooltip title="Copy Input Meta">
                          <i className="fontSize18 bx bx-copy" role="button" />
                        </Tooltip>
                      </CopyToClipboard>
                    </Stack>
                  </div>
                </CardContent>
              </Card>
            )
          })}
        </React.Fragment>
      </>
    )
  }
}

function mapStateToProps(store) {
  return {
    common: _.get(store, "common"),
    report: _.get(store, "activityLog.report"),
  }
}

export default connect(mapStateToProps)(BulkUploadreportList)
