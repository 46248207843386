import React from "react"
import { message, Upload, Option } from "antd"
import { Row, Col, Button } from "reactstrap"
import { connect } from "react-redux"
import { EditOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons"
import ValidatorForm, {
  Select,
  TextField,
  TextArea,
  Switch,
} from "../ValidatorForm"
import { getAssetTypes } from "appRedux/slices/videoAssets"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"

class UpsertCategoryAssetsForm extends ErrorBoundComponent {
  state = {
    formData: {
      type: null,
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      // this.props.getCMSVideoAssetsByFilter(_.deepClean(this.state.filters));
      this.props.getAssetTypes()
      // this.props.getAssetTypes();
    }
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    if (!_.isEmpty(value)) {
      formData[field] = value
      this.setState({ formData })
    }
  }

  _onImageRemove = type => {
    this.setState({
      icon_url_list: [],
      iconUrl: null,
    })
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      this.setState({
        icon_url_list: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        iconUrl: fileObj.file,
      })
    }
  }

  _form = () => {
    const { tabOperation, tabType } = this.state.formData
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col sm={12} lg={6}>
            <TextField
              label={"Tab Name"}
              placeholderLabel="Enter the Name"
              field="tabName"
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "tabAsset.tab_name")
                  : ""
              }
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              onChange={value => this._handleFieldChange("tabName", value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <Select
              label="Tab Type"
              placeholderLabel="Tab Type"
              disabled={this.props.formAction === "update"}
              field="tabType"
              className="creator-form"
              onChange={value => this._handleFieldChange("tabType", value)}
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "tabAsset.tab_type")
                  : null
              }
              options={[
                { label: "Local", value: "LOCAL" },
                { label: "Remote", value: "REMOTE" },
              ]}
            />
          </Col>
          <Col sm={12} lg={6}>
            <Select
              label="Tab Operation"
              placeholderLabel="Tab Operation"
              disabled={this.props.formAction === "update"}
              field="tabOperation"
              className="creator-form"
              onChange={value => this._handleFieldChange("tabOperation", value)}
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "tabAsset.tab_operation")
                  : null
              }
              options={
                tabType !== "LOCAL"
                  ? [
                      { label: "Basic", value: "BASIC" },
                      { label: "Mixed", value: "MIXED" },
                    ]
                  : [{ label: "Local", value: "LOCAL" }]
              }
            />
          </Col>
          {(tabOperation === "BASIC" ||
            _.get(this.props, "tabAsset.tab_operation") === "BASIC") && (
            <Col sm={12} lg={6}>
              <Select
                label="Asset Type"
                disabled={this.props.formAction === "update"}
                placeholderLabel="Asset Type"
                field="assetType"
                className="creator-form"
                onChange={value => this._handleFieldChange("assetType", value)}
                defaultValue={
                  this.props.formAction === "update"
                    ? _.get(this.props, "tabAsset.asset_type")
                    : null
                }
                options={this.props.assetTypes.map(asset => ({
                  label: asset.assetTypeName,
                  value: asset.assetTypeId,
                }))}
              />
            </Col>
          )}
          <Col span={6}>
            <Switch
              label="Is Active"
              field="isActive"
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "tabAsset.is_active")
                  : true
              }
              onChange={value => this._handleFieldChange("isActive", value)}
            />
          </Col>
          {tabType !== "LOCAL" && (
            <Col span={6}>
              <Switch
                label="Red Dot Enabled"
                field="redDotEnabled"
                defaultValue={
                  this.props.formAction === "update"
                    ? _.get(this.props, "tabAsset.red_dot_enabled")
                    : true
                }
                onChange={value =>
                  this._handleFieldChange("redDotEnabled", value)
                }
              />
            </Col>
          )}
          <Col lg="12">
            <Row style={{ marginTop: "10px" }}>
              <Upload
                showUploadList={true}
                onRemove={() => this._onImageRemove()}
                defaultFileList={this.state.icon_url_list}
                fileList={this.state.icon_url_list}
                customRequest={fileObj => this._uploadImage(fileObj)}
              >
                <Button color="primary" onClick={() => {}}>
                  <i className="fas fa-upload" />
                  &nbsp; Upload Icon
                </Button>
              </Upload>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                size="default"
                type="submit"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                {this.props.formAction === "update" ? "Update" : "Create"}
              </Button>
              <Button
                color="danger"
                size="default"
                disabled={this.state.actionLoading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    const { iconUrl } = this.state

    this.props.onSubmit(formData, iconUrl)
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    tabsList: _.get(store, "videoAssets.tabsList"),
    assetTypes: _.get(store, "videoAssets.assetTypes"),
    refetchData: _.get(store, "videoAssets.refetchData"),
    loading: _.get(store, "videoAssets.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data.jotUserType"),
  }
}

export default connect(mapStateToProps, { getAssetTypes })(
  UpsertCategoryAssetsForm
)
