import Axios from "axios"
import toastr from "toastr"

export const getS3Path = async (filePath, type) => {
  try {
    toastr.info("Action in progress !!", "")
    let data = await Axios.post(`/api/joshlive/download/file`, {
      filePath,
      type,
    })
    toastr.success("Action Successful !!", "")
    return data.data.data || ""
  } catch (error) {
    toastr.clear()
    toastr.error("Processing failed !!", "")
    throw error
  }
}
