import React from "react"
import { Divider } from "antd"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Row, Col, Card } from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import moment from "moment"
import { LANGUAGES } from "constants/uiConstants"

export default class UpsertSubCategory extends ErrorBoundComponent {
  state = {
    formData: {
      view_order: this.props.subCategoryId,
      ...this.props.subCategories[this.props.subCategoryId],
      banner_list: [
        {
          element_cta: "",
          id: uuidv4(),
          view_order: 0,
        },
      ],
      background_color_code: "#5f78f1",
      max_video_duration_ms: 60000,
      theme: {
        status_bar_color: "#5f78f1",
        background_color_code: "#5f78f1",
      },
      video: {
        id: uuidv4(),
        auto_play: true,
        mute_on_start: true,
      },
      config: {
        allow_gallery_upload: false,
      },
      lang_code: "en",
      bannerType: "image",
      target: [],
      fileList: {},
    },
    formAction: this.props.action,
    selectedLangs: [],
  }

  componentDidMount() {
    const subCategory = this.props.subCategory
    let selectedLangs = [...this.state.selectedLangs]
    let target = this.state.target

    if (
      !_.isEmpty(subCategory) &&
      this.props.formAction === "update" &&
      this.state.formData.contest_sub_category_id
    ) {
      let bannerType = "image"
      let banner_list = this.state.formData.banner_list
      let video = this.state.formData.video
      if (subCategory.banner_list) {
        bannerType = "image"
        banner_list = subCategory.banner_list
        video = null
      } else if (subCategory.video) {
        bannerType = "video"
        video = subCategory.video
        banner_list = null
      } else {
        bannerType = "image"
      }

      if (!_.isEmpty(subCategory.target)) {
        target = subCategory.target.map(item => {
          selectedLangs.push(item.lang_code)
          const currentTarget = { ...item }
          currentTarget.disabled = true
          currentTarget.banner_list = currentTarget.banner_data
          if (currentTarget.video) {
            currentTarget.bannerType = "video"
          } else {
            currentTarget.bannerType = "image"
          }
          return currentTarget
        })
      }

      this.setState(
        {
          formData: {
            ...this.state.formData,
            audio_id: subCategory.audio_id,
            background_color_code: subCategory.background_color_code,
            lang_code: subCategory.lang_code,
            banner_height: subCategory.banner_height,
            banner_width: subCategory.banner_width,
            min_video_duration_ms: subCategory.min_video_duration_ms,
            description: subCategory.description,
            theme: {
              status_bar_color: subCategory?.theme?.status_bar_color,
              background_color_code: subCategory?.theme?.background?.color_code,
              start_date_ms: subCategory?.theme?.start_date_ms,
              end_date_ms: subCategory?.theme?.end_date_ms,
            },
            config: {
              allow_gallery_upload: subCategory?.config?.allow_gallery_upload
                ? subCategory.config.allow_gallery_upload
                : false,
            },
            video,
            title: subCategory.title,
            banner_list,
            bannerType,
            target: target ? target : [],
            selectedLangs: _.uniq(selectedLangs),
          },
        },
        () => this._updateFormData()
      )
    } else {
      this._updateFormData()
    }
  }

  _addBannerForTarget = index => {
    const target = [...this.state.formData.target]
    const banner_list = target[index].banner_list
      ? target[index].banner_list
      : []
    target[index] = {
      ...target[index],
      banner_list: [
        ...banner_list,
        {
          element_cta: "",
          id: uuidv4(),
          view_order: banner_list.length,
        },
      ],
    }
    this.setState(
      {
        formData: {
          ...this.state.formData,
          target,
        },
      },
      () => this._updateFormData()
    )
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      formData: {
        ...this.state.formData,
        fileList: {
          ...this.state.formData.fileList,
          [key]: file,
        },
      },
    })
  }

  _handleFieldChange = (field, value, parent, index) => {
    if (parent) {
      switch (parent) {
        case "banner_list":
          const banner_list = [...this.state.formData.banner_list]
          banner_list[index] = {
            ...banner_list[index],
            [field]: value,
          }
          this.setState(
            {
              formData: {
                ...this.state.formData,
                banner_list,
              },
            },
            () => this._updateFormData()
          )
          break
        case "sub_category_data":
          const sub_category_data = [...this.state.formData.sub_category_data]
          sub_category_data[index] = {
            ...sub_category_data[index],
            [field]: value,
          }
          this.setState(
            {
              formData: {
                ...this.state.formData,
                sub_category_data,
              },
            },
            () => this._updateFormData()
          )
          break
        default:
          this.setState(
            {
              formData: {
                ...this.state.formData,
                [parent]: {
                  ...this.state.formData[parent],
                  [field]: value,
                },
              },
            },
            () => this._updateFormData()
          )
      }
    } else {
      if (field === "bannerType") {
        if (value === "image") {
          this.setState(
            {
              formData: {
                ...this.state.formData,
                banner_list: [
                  {
                    element_cta: "",
                    id: uuidv4(),
                    view_order: 0,
                  },
                ],
                video: null,
                bannerType: "image",
              },
            },
            () => this._updateFormData()
          )
        } else {
          this.setState(
            {
              formData: {
                ...this.state.formData,
                video: {
                  id: uuidv4(),
                  auto_play: true,
                  mute_on_start: true,
                },
                banner_list: null,
                bannerType: "video",
              },
            },
            () => this._updateFormData()
          )
        }
      } else
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [field]: value,
            },
          },
          () => this._updateFormData()
        )
    }
  }

  _handleTargetFieldsChange = (field, value, index, bannerIndex, parent) => {
    const target = [...this.state.formData.target]
    if (parent) {
      target[index] = {
        ...target[index],
        [parent]: {
          ...target[index][parent],
          [field]: value,
        },
      }
    } else {
      if (field === "bannerType") {
        if (value === "image") {
          target[index] = {
            ...target[index],
            banner_list: [
              {
                element_cta: "",
                id: uuidv4(),
                view_order: 0,
              },
            ],
            video: null,
            bannerType: "image",
          }
        } else {
          target[index] = {
            ...target[index],
            video: {
              id: uuidv4(),
              auto_play: true,
              mute_on_start: true,
            },
            banner_list: null,
            bannerType: "video",
          }
        }
      } else if (field === "element_cta") {
        const banner_list = [...target[index].banner_list]
        banner_list[bannerIndex] = {
          ...banner_list[bannerIndex],
          [field]: value,
        }
        target[index] = {
          ...target[index],
          banner_list,
        }
      } else {
        if (field === "lang_code") {
          const selectedLangs = [...this.state.selectedLangs]
          selectedLangs[index] = value
          this.setState({
            selectedLangs,
          })
        }
        target[index] = {
          ...target[index],
          [field]: value,
        }
      }
    }
    this.setState(
      {
        formData: {
          ...this.state.formData,
          target,
        },
      },
      () => this._updateFormData()
    )
  }

  _addTarget = () => {
    const target = [
      ...this.state.formData.target,
      {
        banner_list: [
          {
            element_cta: "",
            id: uuidv4(),
            view_order: 0,
          },
        ],
        video: {
          id: uuidv4(),
          auto_play: true,
          mute_on_start: true,
        },
        bannerType: "image",
      },
    ]

    this.setState(
      {
        formData: {
          ...this.state.formData,
          target,
        },
      },
      () => this._updateFormData()
    )
  }

  _renderTargetForm = (target, index) => {
    return (
      <Row>
        <Col lg="4">
          <label className="col-form-label">Language</label>
          <div>
            <select
              className="form-select"
              onChange={e =>
                this._handleTargetFieldsChange(
                  "lang_code",
                  e.target.value,
                  index
                )
              }
              defaultValue={target.lang_code}
            >
              <option disabled selected value hidden>
                {" "}
                -- Select Target Language --{" "}
              </option>
              {LANGUAGES.map(lang => (
                <option
                  value={lang.value}
                  disabled={
                    this.state.selectedLangs.indexOf(lang.value) >= 0 ||
                    this.state.formData.lang_code === lang.value
                  }
                >
                  {lang.label}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg="8">
          <label className="col-form-label">Description</label>
          <div>
            <textarea
              className="form-control"
              defaultValue={target.description}
              onChange={e =>
                this._handleTargetFieldsChange(
                  "description",
                  e.target.value,
                  index
                )
              }
              rows="1"
            />
          </div>
        </Col>

        <Row className="mb-3">
          <Col lg="4">
            <label className="col-form-label">Banner Type</label>
            <div>
              <select
                className="form-select"
                onChange={e =>
                  this._handleTargetFieldsChange(
                    "bannerType",
                    e.target.value,
                    index
                  )
                }
                value={target.bannerType}
                disabled={target.disabled}
              >
                <option value="image">Image</option>
                <option value="video">Video</option>
              </select>
            </div>
          </Col>
        </Row>
        {target.bannerType === "image" && (
          <>
            {target?.banner_list?.map((banner, bannerIndex) => (
              <Row className="mb-3">
                <Col>
                  <label className="col-form-label">Element CTA</label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="text"
                      onChange={e =>
                        this._handleTargetFieldsChange(
                          "element_cta",
                          e.target.value,
                          index,
                          bannerIndex
                        )
                      }
                      defaultValue={banner.element_cta}
                    />
                  </div>
                </Col>
                <Col>
                  <div>
                    <div>
                      <label className="col-form-label">Banner Thumbnail</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={e => {
                          this._handleFileUpload(
                            `category_banner_${banner.id}`,
                            e.target.files[0]
                          )
                        }}
                      />
                    </div>
                  </div>
                </Col>
                {banner.bannerUrl && (
                  <Col className="align-self-end mx-5">
                    <Card className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                      <div>
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              src={banner.bannerUrl}
                            />
                          </Col>
                          <Col className="mx-4">
                            <span
                              className="delete-icon"
                              onClick={() =>
                                this._deleteTargetBanner(
                                  banner.id,
                                  target.lang_code
                                )
                              }
                            >
                              <i className="mdi mdi-delete"></i>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                )}
              </Row>
            ))}
            <div>
              <div
                className="mt-2 btn btn-sm btn-primary"
                onClick={() => this._addBannerForTarget(index)}
                role="button"
              >
                Add Banner
              </div>
            </div>
          </>
        )}
        {target.bannerType === "video" && (
          <Row className="d-flex align-items-end">
            <Col lg="4">
              <div>
                <div>
                  <label className="col-form-label">Banner Video</label>
                  <input
                    type="file"
                    className="form-control"
                    disabled={!target.lang_code}
                    onChange={e => {
                      if (this.props.formAction === "update") {
                        this._handleFileUpload(
                          `${target.lang_code}_category_player_url_${this.state.formData.contest_sub_category_id}`,
                          e.target.files[0]
                        )
                      } else {
                        this.props.handleFileUpload(
                          `${target.lang_code}_category_player_url_${this.state.formData.id}`,
                          e.target.files[0]
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div>
                <div>
                  <label className="col-form-label">Banner Thumbnail</label>
                  <input
                    type="file"
                    className="form-control"
                    disabled={!target.lang_code}
                    onChange={e => {
                      const img = new Image()
                      img.onload = () => {
                        const height = img.height
                        const width = img.width

                        this._handleTargetFieldsChange(
                          "banner_height",
                          height,
                          index,
                          _,
                          "video"
                        )
                        this._handleTargetFieldsChange(
                          "banner_width",
                          height,
                          index,
                          _,
                          "video"
                        )
                      }

                      img.src = URL.createObjectURL(e.target.files[0])

                      if (this.props.formAction === "update") {
                        this._handleFileUpload(
                          `${target.lang_code}_category_video_thumbnail_${this.state.formData.contest_sub_category_id}`,
                          e.target.files[0]
                        )
                      } else {
                        this.props.handleFileUpload(
                          `${target.lang_code}_category_video_thumbnail_${this.state.formData.id}`,
                          e.target.files[0]
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg="2">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={e =>
                    this._handleTargetFieldsChange(
                      "auto_play",
                      e.target.checked,
                      index,
                      _,
                      "video"
                    )
                  }
                  checked={target.video.auto_play}
                />
                <label className="form-check-label">Auto Play</label>
              </div>
            </Col>
            <Col lg="2">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={e =>
                    this._handleTargetFieldsChange(
                      "mute_on_start",
                      e.target.checked,
                      index,
                      _,
                      "video"
                    )
                  }
                  checked={target.video.mute_on_start}
                />
                <label className="form-check-label">Mute On Start</label>
              </div>
            </Col>
          </Row>
        )}
      </Row>
    )
  }

  _addBanner = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        banner_list: [
          ...this.state.formData.banner_list,
          {
            element_cta: "",
            id: uuidv4(),
            view_order: this.state.formData.banner_list.length,
          },
        ],
      },
    })
  }

  _deleteBanner = bannerId => {
    const data = {
      banner_ids: [bannerId],
      contest_id: this.props.contest.contest_id,
      entity_id: this.state.formData.id,
      entity_type: this.state.formData.type,
    }
    this.props.deleteBanner(data)
  }

  _deleteTargetBanner = (bannerId, langCode) => {
    const data = {
      contest_id: this.props.contest.contest_id,
      entity_id: this.state.formData.id,
      entity_type: this.state.formData.type,
      target: [
        {
          lang_code: langCode,
          banner_ids: [bannerId],
        },
      ],
    }
    this.props.deleteBanner(data)
  }

  _form = () => {
    return (
      <>
        <Row className="mb-3">
          <Col lg="4">
            <label>Title</label>
            <div>
              <input
                className="form-control"
                type="text"
                onChange={e => this._handleFieldChange("title", e.target.value)}
                defaultValue={this.state.formData.title}
              />
            </div>
          </Col>
          <Col lg="4">
            <label>Language</label>
            <div>
              <select
                className="form-select"
                onChange={e =>
                  this._handleFieldChange("lang_code", e.target.value)
                }
                value={this.state.formData.lang_code || "en"}
                disabled={this.props.formAction === "update"}
              >
                {LANGUAGES.map(lang => (
                  <option value={lang.value} key={lang.value}>
                    {lang.label}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col lg="3" className="mt-4">
            <div className="form-check form-switch form-switch-lg">
              <input
                type="checkbox"
                className="form-check-input"
                onChange={e =>
                  this._handleFieldChange(
                    "allow_gallery_upload",
                    e.target.checked,
                    "config"
                  )
                }
                checked={this.state.formData.config.allow_gallery_upload}
              />
              <label className="form-check-label">Allow Gallery Upload</label>
            </div>
          </Col>
          <Col lg="4">
            <label>Audio</label>
            <div>
              <div>
                <input
                  className="form-control"
                  type="text"
                  onChange={e =>
                    this._handleFieldChange("audio_id", e.target.value)
                  }
                  defaultValue={this.state.formData.audio_id}
                />
              </div>
            </div>
          </Col>
          <Col lg="4">
            <label>Minimum Video Duration</label>
            <div>
              <div>
                <input
                  className="form-control"
                  type="number"
                  onChange={e =>
                    this._handleFieldChange(
                      "min_video_duration_ms",
                      +e.target.value
                    )
                  }
                  defaultValue={this.state.formData.min_video_duration_ms}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg="4">
            <label>Maximum Video Duration</label>
            <div>
              <div>
                <input
                  className="form-control"
                  type="number"
                  disabled
                  defaultValue="60000"
                  onChange={e =>
                    this._handleFieldChange(
                      "max_video_duration_ms",
                      +e.target.value
                    )
                  }
                />
              </div>
            </div>
          </Col>
          <Col lg="8">
            <label>Description</label>
            <div>
              <textarea
                className="form-control"
                rows="1"
                onChange={e =>
                  this._handleFieldChange("description", e.target.value)
                }
                defaultValue={this.state.formData.description}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg="6">
            <div>
              <label className="col-form-label">Icon File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  if (this.props.formAction === "update") {
                    this._handleFileUpload(
                      `category_icon_${this.state.formData.contest_sub_category_id}`,
                      e.target.files[0]
                    )
                  } else {
                    this.props.handleFileUpload(
                      `category_icon_${this.state.formData.id}`,
                      e.target.files[0]
                    )
                  }
                }}
              />
            </div>
          </Col>
        </Row>
        <Divider>
          <h5 className="mb-0">Theme</h5>
        </Divider>
        <Row className="mb-3">
          <Col lg="4">
            <label className="col-form-label">Start Date</label>
            <div>
              <input
                className="form-control"
                type="datetime-local"
                onChange={e =>
                  this._handleFieldChange(
                    "start_date_ms",
                    moment(e.target.value).valueOf() + "",
                    "theme"
                  )
                }
                value={moment(+this.state.formData.theme.start_date_ms).format(
                  "YYYY-MM-DDTHH:MM"
                )}
              />
            </div>
          </Col>
          <Col lg="4">
            <label className="col-form-label">End Date</label>
            <div>
              <input
                className="form-control"
                type="datetime-local"
                onChange={e =>
                  this._handleFieldChange(
                    "end_date_ms",
                    moment(e.target.value).valueOf() + "",
                    "theme"
                  )
                }
                value={moment(+this.state.formData.theme.end_date_ms).format(
                  "YYYY-MM-DDTHH:MM"
                )}
              />
            </div>
          </Col>
          <Col lg="2">
            <label className="col-form-label">Status Bar Color</label>
            <div>
              <input
                className="form-control form-control-color w-100"
                type="color"
                value={this.state.formData.theme.status_bar_color}
                onChange={e =>
                  this._handleFieldChange(
                    "status_bar_color",
                    e.target.value,
                    "theme"
                  )
                }
              />
            </div>
          </Col>
          <Col lg="2">
            <label className="col-form-label">Background Color</label>
            <div>
              <input
                className="form-control form-control-color w-100"
                type="color"
                value={this.state.formData.theme.background_color_code}
                onChange={e =>
                  this._handleFieldChange(
                    "background_color_code",
                    e.target.value,
                    "theme"
                  )
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <div>
                <label className="col-form-label">Overlay Image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={e => {
                    if (this.props.formAction === "update") {
                      this._handleFileUpload(
                        `category_overlay_image_${this.state.formData.contest_sub_category_id}`,
                        e.target.files[0]
                      )
                    } else {
                      this.props.handleFileUpload(
                        `category_overlay_image_${this.state.formData.id}`,
                        e.target.files[0]
                      )
                    }
                  }}
                />
              </div>
            </div>
          </Col>
          <Col>
            <div>
              <div>
                <label className="col-form-label">Background Image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={e => {
                    if (this.props.formAction === "update") {
                      this._handleFileUpload(
                        `category_theme_bck_image_${this.state.formData.contest_sub_category_id}`,
                        e.target.files[0]
                      )
                    } else {
                      this.props.handleFileUpload(
                        `category_theme_bck_image_${this.state.formData.id}`,
                        e.target.files[0]
                      )
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Divider>
          <h5 className="mb-0">Leaderboard</h5>
        </Divider>
        <Row className="mb-3">
          <Col lg="3">
            <label className="col-form-label">Background Color</label>
            <div className="col-md-10">
              <input
                className="form-control form-control-color w-100"
                type="color"
                value={this.state.formData.background_color_code}
                onChange={e =>
                  this._handleFieldChange(
                    "background_color_code",
                    e.target.value
                  )
                }
              />
            </div>
          </Col>
          <Col lg="4">
            <div>
              <div>
                <label className="col-form-label">
                  Leaderboard Background Image
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={e => {
                    if (this.props.formAction === "update") {
                      this._handleFileUpload(
                        `leaderboard_bck_image_${this.state.formData.contest_category_id}`,
                        e.target.files[0]
                      )
                    } else {
                      this.props.handleFileUpload(
                        `leaderboard_bck_image_${this.state.formData.id}`,
                        e.target.files[0]
                      )
                    }
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Divider>
          <h5 className="mb-0">Banner</h5>
        </Divider>
        <Row className="mb-3">
          <Col lg="4">
            <label className="col-form-label">Banner Type</label>
            <div>
              <select
                className="form-select"
                onChange={e =>
                  this._handleFieldChange("bannerType", e.target.value)
                }
                value={this.state.formData.bannerType}
                disabled={
                  this.props.formAction === "update" &&
                  this.state.formData.contest_sub_category_id
                }
              >
                <option value="image">Image</option>
                <option value="video">Video</option>
              </select>
            </div>
          </Col>
        </Row>
        {this.state.formData.bannerType === "image" && (
          <>
            {this.state.formData?.banner_list?.map((banner, index) => (
              <Row className="mb-3">
                <Col>
                  <label className="col-form-label">Element CTA</label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="text"
                      onChange={e =>
                        this._handleFieldChange(
                          "element_cta",
                          e.target.value,
                          "banner_list",
                          index
                        )
                      }
                      defaultValue={banner.element_cta}
                    />
                  </div>
                </Col>
                <Col>
                  <div>
                    <div>
                      <label className="col-form-label">Banner Thumbnail</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={e => {
                          const img = new Image()
                          img.onload = () => {
                            const height = img.height
                            const width = img.width

                            if (index === 0) {
                              this._handleFieldChange("banner_height", height)
                              this._handleFieldChange("banner_width", width)
                            }
                          }

                          img.src = URL.createObjectURL(e.target.files[0])

                          if (this.props.formAction === "update") {
                            this._handleFileUpload(
                              `category_banner_${banner.id}`,
                              e.target.files[0]
                            )
                          } else {
                            this.props.handleFileUpload(
                              `category_banner_${banner.id}`,
                              e.target.files[0]
                            )
                          }
                        }}
                      />
                    </div>
                  </div>
                </Col>
                {banner.bannerUrl && (
                  <Col className="align-self-end mx-5">
                    <Card className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                      <div>
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              src={banner.bannerUrl}
                            />
                          </Col>
                          <Col className="mx-4">
                            <span
                              className="delete-icon"
                              onClick={() => this._deleteBanner(banner.id)}
                            >
                              <i className="mdi mdi-delete"></i>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                )}
              </Row>
            ))}
            <div className="d-flex mt-2">
              <div
                role="button"
                className="btn btn-sm btn-primary"
                onClick={this._addBanner}
              >
                Add Banner
              </div>
            </div>
          </>
        )}
        {this.state.formData.bannerType === "video" && (
          <Row className="d-flex align-items-end">
            <Col lg="4">
              <div>
                <div>
                  <label className="col-form-label">Banner Video</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      if (this.props.formAction === "update") {
                        this._handleFileUpload(
                          `${this.state.formData.lang_code}_category_player_url_${this.state.formData.contest_category_id}`,
                          e.target.files[0]
                        )
                      } else {
                        this.props.handleFileUpload(
                          `${this.state.formData.lang_code}_category_player_url_${this.state.formData.id}`,
                          e.target.files[0]
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div>
                <div>
                  <label className="col-form-label">Banner Thumbnail</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      const img = new Image()
                      img.onload = () => {
                        const height = img.height
                        const width = img.width

                        this._handleFieldChange(
                          "banner_height",
                          height,
                          "video"
                        )
                        this._handleFieldChange("banner_width", width, "video")
                      }

                      img.src = URL.createObjectURL(e.target.files[0])

                      if (this.props.formAction === "update") {
                        this._handleFileUpload(
                          `${this.state.formData.lang_code}_category_video_thumbnail_${this.state.formData.contest_category_id}`,
                          e.target.files[0]
                        )
                      } else {
                        this.props.handleFileUpload(
                          `${this.state.formData.lang_code}_category_video_thumbnail_${this.state.formData.id}`,
                          e.target.files[0]
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg="2">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={e =>
                    this._handleFieldChange(
                      "auto_play",
                      e.target.checked,
                      "video"
                    )
                  }
                  checked={this.state.formData.video.auto_play}
                />
                <label className="form-check-label">Auto Play</label>
              </div>
            </Col>
            <Col lg="2">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={e =>
                    this._handleFieldChange(
                      "mute_on_start",
                      e.target.checked,
                      "video"
                    )
                  }
                  checked={this.state.formData.video.mute_on_start}
                />
                <label className="form-check-label">Mute On Start</label>
              </div>
            </Col>
          </Row>
        )}
        {this.state.formData?.target?.map((item, index) => {
          return (
            <>
              <Divider>Language Target #{index + 1}</Divider>
              {this._renderTargetForm(item, index)}
            </>
          )
        })}
        <div className="d-flex justify-content-end">
          <div
            className="mt-3 btn btn-primary"
            onClick={this._addTarget}
            role="button"
          >
            Add Target
          </div>
        </div>
        {this.props.formAction === "update" && (
          <div className="d-flex justify-content-end mt-3">
            <div
              role="button"
              className="btn btn-success"
              onClick={
                this.state.formData.contest_sub_category_id
                  ? this._updateSubCategory
                  : this._addSubCategory
              }
            >
              {this.state.formData.contest_sub_category_id
                ? `Update Sub Category #${this.props.subCategoryId + 1}`
                : `Add Sub Category #${this.props.subCategoryId + 1}`}
            </div>
          </div>
        )}
      </>
    )
  }

  _updateFormData = () => {
    const categories = [...this.props.categories]
    const subCategories = [
      ...categories[this.props.categoryId].sub_category_data,
    ]
    subCategories[this.props.subCategoryId] = this.state.formData

    categories[this.props.categoryId] = {
      ...categories[this.props.categoryId],
      sub_category_data: subCategories,
    }

    this.props.updateFormData(categories)
  }

  _updateSubCategory = () => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_CONTEST")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    const formData = _.cloneDeep(this.state.formData)
    const data = new FormData()

    delete formData?.sub_category_data
    delete formData?.newly_added
    if (formData.bannerType === "image") {
      delete formData?.video
    } else {
      delete formData?.banner_list
    }

    Object.keys(this.state.formData.fileList).forEach(fileName => {
      data.append(fileName, this.state.formData.fileList[fileName])
    })

    if (_.isEmpty(formData.target)) {
      delete formData.target
    } else {
      formData.target.forEach(item => {
        if (item.bannerType === "image") {
          delete item?.video
        } else {
          delete item?.banner_list
        }
      })
    }

    delete formData?.fileList
    delete formData?.banner_data

    data.append("details", JSON.stringify(formData))
    this.props.updateCategory(data)
  }

  _addSubCategory = () => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_CONTEST")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    const tempData = { ...this.state.formData }
    const formData = {}
    const data = new FormData()

    Object.keys(this.state.formData.fileList).forEach(fileName => {
      data.append(fileName, this.state.formData.fileList[fileName])
    })

    delete tempData?.fileList

    if (_.isEmpty(tempData?.target)) {
      delete tempData.target
    } else {
      tempData.target.forEach(item => {
        if (item.bannerType === "image") {
          delete item?.video
        } else {
          delete item?.banner_list
        }
      })
    }

    if (!_.isEmpty(tempData.sub_category_data)) {
      if (_.isEmpty(tempData?.sub_category_data?.target)) {
        delete tempData?.sub_category_data?.target
      } else {
        tempData?.sub_category_data?.target.forEach(item => {
          if (item.bannerType === "image") {
            delete item?.video
          } else {
            delete item?.banner_list
          }
        })
      }
      data.append(
        "sub_category_data",
        JSON.stringify(tempData.sub_category_data)
      )
    }
    delete tempData?.newly_added
    delete tempData?.sub_category_data

    if (this.state.formData.bannerType === "image") {
      delete tempData?.video
    } else {
      delete tempData?.banner_list
    }

    delete tempData?.bannerType

    formData.contest_id = this.props.contest.contest_id
    formData.category_id = this.props.contest_category_id
    formData.hashtag = this.props.contest.hashtag
    formData.type = "SUB_CATEGORY"
    formData.category_data = { ...tempData }

    data.append("details", JSON.stringify(formData))
    this.props.addCategory(data)
  }

  _render = () => {
    return <Auxiliary loading={this.props.loading}>{this._form()}</Auxiliary>
  }
}
