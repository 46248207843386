import { createSlice } from "@reduxjs/toolkit"

const cmsVideoAssetsSlice = createSlice({
  name: "cmsVideoAssets",
  initialState: {
    cmsVideoAssets: [],
    loading: false,
  },
  reducers: {
    getCMSVideoAssetListByFilters(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getCMSVideoAssetListByFiltersSuccess(state, payload) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        cmsVideoAssets: payload.payload.data,
      }
    },
    upsertCMSVideoAsset(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertCMSVideoAssetSuccess(state, payload) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState(state) {
      return {
        loading: false,
        refetchData: false,
        cmsVideoAssets: [],
      }
    },
  },
})

export const {
  getCMSVideoAssetListByFilters,
  getCMSVideoAssetListByFiltersSuccess,
  upsertCMSVideoAsset,
  upsertCMSVideoAssetSuccess,
  resetState,
} = cmsVideoAssetsSlice.actions

export default cmsVideoAssetsSlice.reducer
