import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { Card, CardBody, Row, Col, CardTitle, Badge } from "reactstrap"
import _ from "lodash"
import { Input, Tooltip, Checkbox, Tag } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getCommentsGroupByUser,
  resetState,
  bulkDeActivateComments,
} from "appRedux/slices/comment"
import Auxiliary from "../../util/Auxiliary"
import "./browseComment.style.css"
import ContentCard from "components/ContentCard/contentCard"
import CustomScrollbars from "util/CustomScrollbars"

import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import toastr from "toastr"
class BrowseComment extends ErrorBoundComponent {
  state = {
    filters: {
      userId:
        this.props.queryParams && this.props.queryParams["userId"]
          ? this.props.queryParams["userId"]
          : null,
      postId:
        this.props.queryParams && this.props.queryParams["postId"]
          ? this.props.queryParams["postId"]
          : null,
    },
    selectedCards: [],
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      if (filters.userId || filters.postId) {
        this.props.dispatch(getCommentsGroupByUser(_.deepClean(filters)))
      }
    }
  }

  componentDidUpdate() {
    let filters = _.clone(this.state.filters)
    if (this.props.refetchData && (filters.userId || filters.postId)) {
      this.props.dispatch(
        getCommentsGroupByUser(_.deepClean(this.state.filters))
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _getModerationStatus = moderationLevel => {
    let moderationStatus
    switch (moderationLevel) {
      case 0:
        moderationStatus = "Unsure"
        break
      case 1:
        moderationStatus = "UnModerated"
        break
      case 2:
        moderationStatus = "Human Approved"
        break
      case 3:
        moderationStatus = "Auto Approved"
        break
      case -1:
        moderationStatus = "Auto Disabled"
        break
      case -2:
        moderationStatus = "Human Disabled"
        break
      default:
    }
    return moderationStatus
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({ filters: newFilters })
  }

  _searchComment = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/comment/browse?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(getCommentByFilter(_.deepClean(filters)))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/comment/browse?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
      this.props.dispatch(getCommentByFilter(_.deepClean(this.state.filters)))
    })
  }

  _closeFilters = () => {
    this._searchComment()
    this.setState({ showAdvancedFilter: false })
  }

  _onCommentSearch = () => {
    const { filters } = this.state
    this.props.history.push(
      `/comment/manage?${QueryString.stringify(_.deepClean(filters))}`
    )
    if (filters.userId || filters.postId) {
      this.props.dispatch(getCommentsGroupByUser(_.deepClean(filters)))
    }
  }

  _bulkDeActivate = () => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_COMMENT")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }

    const data = this.state.selectedCards.map(id => {
      return {
        comment_id: id,
        system_block: true,
      }
    })
    this.props.dispatch(bulkDeActivateComments(data))
    this.setState({
      selectedCards: [],
    })
  }

  _bulkActivate = () => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_COMMENT")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }

    const data = this.state.selectedCards.map(id => {
      return {
        comment_id: id,
        system_block: false,
      }
    })
    this.props.dispatch(bulkDeActivateComments(data))
    this.setState({
      selectedCards: [],
    })
  }

  _filters = () => {
    return (
      <Row className="d-flex align-items-center mb-3">
        <Col lg="4" md="4">
          <Input
            allowClear={true}
            placeholder="User ID"
            value={this.state.filters["userId"]}
            onChange={e => {
              this._handleFilterChange(e.target.value, "userId")
            }}
            style={{ width: "100%" }}
          />
        </Col>
        <Col lg="4" md="4">
          <Input
            allowClear={true}
            placeholder="Post ID"
            value={this.state.filters["postId"]}
            onChange={e => {
              this._handleFilterChange(e.target.value, "postId")
            }}
            style={{ width: "100%" }}
          />
        </Col>
        <Col className="d-flex align-items-start justify-content-between">
          <button
            onClick={this._onCommentSearch}
            role="button"
            className="btn btn-success mx-1 d-flex align-items-center"
          >
            <div className="mx-2">Apply</div>
            <span>
              <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
            </span>
          </button>
        </Col>
        <Col lg="2" className="d-flex justify-content-end">
          <button
            onClick={this._bulkActivate}
            role="button"
            className="btn btn-primary mx-1 d-flex align-items-center"
          >
            <div className="mx-2">Activate</div>
          </button>
          <button
            onClick={this._bulkDeActivate}
            role="button"
            className="btn btn-primary d-flex align-items-center"
          >
            <div className="mx-2">DeActivate</div>
          </button>
        </Col>
      </Row>
    )
  }

  _selectCard = comment => {
    const selectedCards = [...this.state.selectedCards]
    if (selectedCards.indexOf(comment.id) < 0) {
      selectedCards.push(comment.id)
    } else {
      _.remove(selectedCards, id => {
        return comment.id === id
      })
    }
    this.setState({
      selectedCards,
    })
  }

  _selectAllCards = comments => {
    const ids = comments.map(comment => comment.id)
    const selectedCards = _.uniq([...this.state.selectedCards, ...ids])
    this.setState({
      selectedCards,
    })
  }

  _deSelectAllCards = comments => {
    const ids = comments.map(comment => comment.id)
    const selectedCards = [...this.state.selectedCards]
    _.remove(selectedCards, id => {
      return ids.indexOf(id) >= 0
    })
    this.setState({
      selectedCards,
    })
  }

  _handleSelectAll = (e, comments) => {
    if (e.target.checked) {
      this._selectAllCards(comments)
    } else {
      this._deSelectAllCards(comments)
    }
  }

  _renderComments = () => {
    const comments =
      this.props.commentGroups?.[this.state.selectedKey]?.comments
    return (
      <React.Fragment>
        <div className="mb-2">
          <Checkbox onChange={e => this._handleSelectAll(e, comments)}>
            Select All
          </Checkbox>
        </div>
        <div
          style={{
            height: "578px",
            overflowX: "hidden",
            overflowY: "auto",
            borderRadius: "10px",
          }}
        >
          <CustomScrollbars>
            {comments &&
              comments.length > 0 &&
              comments.map(comment => {
                return (
                  <div className="d-flex align-items-center mb-2">
                    <div>
                      <Checkbox
                        checked={
                          this.state.selectedCards.indexOf(comment.id) >= 0
                        }
                        onChange={() => this._selectCard(comment)}
                      />
                    </div>
                    <div className="flex-fill">
                      <Card className="m-0 ms-2">
                        <CardBody className="p-1">
                          <CardTitle className="mt-0 d-flex mx-1">
                            <Tooltip title={comment.title}>
                              <div className="text-truncate font-size-18">
                                {comment.title}
                              </div>
                            </Tooltip>
                          </CardTitle>
                          <div className="d-flex justify-content-end">
                            <Tooltip title="Comment Status">
                              <Badge
                                className={`badge-soft-${
                                  comment.is_active ? "success" : "danger"
                                } ms-1 p-2 font-size-14`}
                              >
                                {comment.is_active ? "Active" : "In Active"}
                              </Badge>
                            </Tooltip>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                )
              })}
          </CustomScrollbars>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        <Row>
          <Col lg={this.state.selectedKey ? "8" : "12"}>
            {this.props.commentGroups && (
              <div className="content-card-container">
                {Object.keys(this.props.commentGroups).map((key, index) => {
                  return (
                    <div className="content-card">
                      <ContentCard
                        content={this.props.commentGroups[key].content}
                        key={index}
                        currentUser={this.props.currentUser}
                        editClick={() => this.setState({ selectedKey: key })}
                        contentAction={this._contentAction}
                        deativateReasonFunc={this._deativateReason}
                        customClassname={
                          this.state.selectedKey ===
                          this.props.commentGroups[key].content.content_uuid
                            ? "border-2 border-success"
                            : ""
                        }
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </Col>
          {this.state.selectedKey && <Col lg="4">{this._renderComments()}</Col>}
        </Row>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    commentGroups: _.get(store, "comment.commentGroups"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "comment.loading"),
    refetchData: _.get(store, "comment.refetchData"),
  }
}

export default connect(mapStateToProps)(BrowseComment)
