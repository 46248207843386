import Axios from "axios"

export const requestGetBulkReportList = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] ? payload["pageSize"] : 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/reports/getReportsByFilter?offset=${offset}&limit=${limit}`,
      {
        filters: payload,
      }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetActivityDetails = async ({ payload }) => {
  try {
    let data = await Axios.post("/api/activity/log", { ...payload })

    return data.data
  } catch (error) {
    throw error
  }
}
