import React from "react"
import { connect } from "react-redux"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getTemplateSection,
  upsertTemplateSection,
  reorderTemplateSection,
  addTemplate,
  refreshTemplateSection,
  resetState,
} from "../../appRedux/slices/templateSection"
import {
  templateSectionFilters,
  NO_PERMISSION_MESSAGE,
} from "constants/uiConstants"
import Reorder from "../../components/Reorder/reorderPageEntities"
import Auxiliary from "../../util/Auxiliary"
import { Divider, Input, Pagination, Tabs } from "antd"
import Chips from "components/CommonFilter/chips"
import TemplateSectionCard from "./templateSectionCard"
import { Button } from "reactstrap"
import QueryString from "query-string"
import TemplateSectionForm from "./TemplateSectionForm"
import TepmlateSectionReorder from "./tepmlateSectionReorder"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import "../Comment/browseComment.style.css"
import SelectedTemplateList from "./SelectedTemplateList"

const FILTER_KEYS = [
  {
    key_name: "sectionName",
    display_name: "Section Name",
  },
  {
    key_name: "sectionId",
    display_name: "Section Id",
  },
  {
    key_name: "isActive",
    display_name: "Status",
  },
]

class TemplateSection extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      showSeachAndSelectModal: false,
      showModal: false,
      showReorder: false,
      showTrendingForm: false,
      currentData: null,
      filters: {
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        sectionName:
          this.props.queryParams && this.props.queryParams["sectionName"]
            ? this.props.queryParams["sectionName"]
            : "",
        isActive:
          this.props.queryParams && this.props.queryParams["isActive"]
            ? this.props.queryParams["isActive"]
            : null,
      },
    }
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getTemplateSection({ filters: _.deepClean(filters) }))
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getTemplateSection({ filters: _.deepClean(filters) }))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
      })
    }
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/template-section/manage?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      ),
        this.props.dispatch(
          getTemplateSection({ filters: _.deepClean(newFilters) })
        )
    })
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    this.setState({
      filters: newFilters,
    })
  }

  _searchCollection = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/template-section/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    ),
      this.props.dispatch(getTemplateSection({ filters: _.deepClean(filters) }))
  }

  _closeFilters = () => {
    this._searchCollection()
    this.setState({
      showFilters: false,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: templateSectionFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/template-section/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getTemplateSection({ filters: _.deepClean(this.state.filters) })
        )
      }
    )
  }

  _onSubmit = async payload => {
    let type
    type = "TEMPLATE_SECTION"
    let response
    response = await this.props.dispatch(
      upsertTemplateSection({
        data: payload,
        type,
        action: this.state.action,
      })
    )

    this.setState({ showModal: false, currentData: null })
  }

  _onEdit = currentData => {
    this.setState({ currentData, showModal: true, action: "update" })
  }

  _modal = () => {
    return (
      <div className="view_container">
        <TemplateSectionForm
          pageId={this.state.currentPageId}
          type={
            this.props.queryParams && this.props.queryParams.type
              ? this.props.queryParams.type
              : "TEMPLATE_SECTION"
          }
          onSubmit={this._onSubmit}
          data={this.state.currentData}
          action={this.state.action}
          onCancel={() =>
            this.setState({ showModal: false, currentData: null })
          }
        />
      </div>
    )
  }

  _onSubmitReorder = async data => {
    const request = data.map((section, idx) => ({
      sectionId: section.section_id,
      viewOrder: idx + 1,
    }))

    await this.props.dispatch(reorderTemplateSection(request))

    this.setState({ showReorder: false })
  }

  _reorder = data => {
    return (
      <TepmlateSectionReorder
        onCancel={() => this.setState({ showReorder: false })}
        onSubmit={this._onSubmitReorder}
        data={data}
      />
    )
  }

  _onClickCard = currentData => {
    // this.setState({ currentData, showSeachAndSelectModal: true })
    this.setState({ currentData, showTemplateModel: true })
  }

  _buttons = () => {
    return (
      <div className="d-flex justify-content-end my-2">
        <div className="d-flex">
          <Button
            color="primary"
            className="d-flex"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes("EDIT_TEMPLATES")
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.setState({ showModal: true, action: "create" })
            }}
          >
            <i className="fa fa-plus mx-1 my-1" />
            Add
          </Button>

          <Button
            color="primary"
            className="d-flex ml-6"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes("EDIT_TEMPLATES")
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.setState({ showReorder: true })
            }}
          >
            <i className="fa fa-sync mx-1 my-1" />
            Reorder
          </Button>

          <Button
            color="primary"
            className="d-flex ml-6"
            onClick={() => {
              this.props.dispatch(refreshTemplateSection())
            }}
          >
            <i className="fa fa-undo mx-1 my-1" />
            Refresh
          </Button>
        </div>
      </div>
    )
  }

  _templateModel = () => {
    return (
      <SelectedTemplateList
        data={this.state.currentData}
        onClose={() => {
          this.props.dispatch(refreshTemplateSection())
          this.setState({
            showTemplateModel: false,
            currentData: null,
          })
        }}
      />
    )
  }

  // _onAdd = async selectedElements => {
  //     const request = selectedElements.map((element, idx) => {
  //         return {
  //             sectionId: this.state.currentData.section_id,
  //             templateId: element,
  //             viewOrder: idx + 1
  //         };
  //     })

  //     await this.props.dispatch(
  //         addTemplate(request)
  //     );

  //     this.setState({ showSeachAndSelectModal: false });
  // }

  render() {
    return (
      <React.Fragment>
        <Auxiliary
          loading={this.props.loading}
          error={_.get(this.props, "common.error")}
        >
          {!this.state.showModal &&
            !this.state.showReorder &&
            !this.state.showTemplateModel && (
              <React.Fragment>
                <Chips
                  showFilters={this.state.showFilters}
                  filters={this.state.filters}
                  search={this._closeFilters}
                  removeFilter={this._removeFilter}
                  handleFilterChange={this._handleFilterChange}
                  type="manage_template_section"
                  toggleFilters={this._toggleFilters}
                  filterKeys={FILTER_KEYS}
                />
                {this._buttons()}
                <div className="discovery-card-container">
                  {this.props.templateSections &&
                    this.props.templateSections.map((section, i) => {
                      return (
                        <TemplateSectionCard
                          data={section}
                          onEditClick={this._onEdit}
                          currentUser={this.props.currentUser}
                          onClickCard={this._onClickCard}
                          // onDeleteClick={this._onDelete}
                          // isDeleteEnable={true}
                        />
                      )
                    })}
                </div>
                {this.props.templateSections &&
                  this.props.templateSections.length > 0 && (
                    <div className="d-flex justify-content-end">
                      <Pagination
                        style={{
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                        onChange={this._onPageChange}
                        defaultCurrent={this.state.filters.currentPage}
                        total={
                          this.props.total < 10000 ? this.props.total : 10000
                        }
                        pageSize={this.state.filters.pageSize}
                        showSizeChanger={false}
                      />
                    </div>
                  )}
              </React.Fragment>
            )}
        </Auxiliary>
        {this.state.showModal && this._modal()}
        {this.state.showReorder && this._reorder(this.props.templateSections)}
        {this.state.showTemplateModel && this._templateModel()}
        {/* {
                    this.state.showSeachAndSelectModal && (
                        <SearchAndSelect
                            type="TEMPLATE_SECTION"
                            visible={this.state.showSeachAndSelectModal}
                            onClose={() => this.setState({ showSeachAndSelectModal: false })}
                            onAdd={arr => this._onAdd(arr)}
                            // element={this.state.element}
                            selectedArr={_.uniq(
                                this.state.currentData?.sectionMappingData?.data.map(template => template.template_id) || []
                            )}
                        />
                    )
                } */}
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "templateSection.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "templateSection.loading"),
    templateSections: _.get(store, "templateSection.templateSections"),
    refetch: _.get(store, "templateSection.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(TemplateSection)
