import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestIconIds,
  requestUpdateIcon,
  requestThemesData,
} from "../requests/icons"
import {
  getAllThemes,
  getAllThemesSuccess,
  getIconIds,
  getIconIdsSuccess,
  updateIcon,
  updateIconSuccess,
} from "appRedux/slices/icons"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

function* handleThemeListGenerator(payload) {
  try {
    const data = yield call(requestThemesData, payload)
    yield put(getAllThemesSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateIconGenerator({ payload, params }) {
  try {
    const data = yield call(requestUpdateIcon, payload, params)
    toastr.success("Action successfull!!")
    yield put(updateIconSuccess(data.data))
  } catch (error) {
    toastr.error("Action failed!!")
    yield put(fetchError(error))
  }
}

function* handleIconsIdsGenerator() {
  try {
    const data = yield call(requestIconIds)
    yield put(getIconIdsSuccess(data.data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getAllThemes.type, handleThemeListGenerator)
  yield takeLatest(getIconIds.type, handleIconsIdsGenerator)
  yield takeLatest(updateIcon.type, handleUpdateIconGenerator)
}
