import { call, takeLatest, put } from "redux-saga/effects"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"
import {
  getListings,
  getListingsSuccess,
  upsertListings,
  upsertListingsSuccess,
} from "../../slices/listings"
import { requestListings, requestUpsertListings } from "../requests/listings"

function* handleGetListings(params) {
  try {
    const data = yield call(requestListings, params)
    yield put(getListingsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpsertListings(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestUpsertListings, params)
    yield put(upsertListingsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getListings.type, handleGetListings)
  yield takeLatest(upsertListings.type, handleUpsertListings)
}
