import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Upload } from "antd"
import toastr from "toastr"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import { Form } from "react-bootstrap"
import ValidatorForm from "../ValidatorForm"
import moment from "moment"
import "moment-timezone"

export default class packageCollectionForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      allowSave: true,
      collection_name: this.props?.data?.collection_name || "",
      status: this.props?.data?.status || "ACTIVE",
      type: this.props?.data?.type || "",
      sale_share_url: this.props?.data?.sale_share_url || "",
      priority_order: this.props?.data?.priority_order || null,
      discount_percentage: this.props?.data?.discount_percentage || null,
      purchase_allowed: this.props?.data?.purchase_allowed || null,
      start_time: this.props?.data?.start_time || null,
      end_time: this.props?.data?.end_time || null,
    }
  }

  componentWillUnmount() {
    this._resetState()
  }

  _resetState() {
    this.setState({
      allowSave: true,
      collection_name: "",
      status: "ACTIVE",
      type: "",
      sale_share_url: "",
      priority_order: null,
      discount_percentage: null,
      purchase_allowed: null,
      start_time: null,
      end_time: null,
    })
  }

  _onImageRemove = type => {
    if (type === "thumbnail") {
      this.setState({
        sale_thumbnail_url_list: [],
        sale_thumbnail_Image: null,
      })
    }
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "thumbnail") {
        this.setState({
          sale_thumbnail_url_list: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          sale_thumbnail_Image: fileObj.file,
        })
      }
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Package Collection`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={6}>
                <label>Collection Name</label>
                <input
                  className="form-control"
                  value={this.state.collection_name}
                  onChange={e =>
                    this.setState({ collection_name: e.target.value })
                  }
                />
              </Col>
              <Col sm={12} md={6}>
                <label>Status</label>
                <div>
                  <select
                    className="form-select"
                    onChange={e => this.setState({ status: e.target.value })}
                    value={this.state.status}
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </select>
                </div>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <label>Type</label>
                <div>
                  <input
                    className="form-control"
                    value={this.state.type}
                    onChange={e => this.setState({ type: e.target.value })}
                  />
                  {/* <select
                    className="form-select"
                    onChange={e => this.setState({ type: e.target.value })}
                    value={this.state.type}
                  >
                    <option value="GIFT">Gift</option>
                    <option value="SPIN">Spin</option>
                  </select> */}
                </div>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <label>Priority Order *</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.priority_order}
                    onChange={e => {
                      if (e.target.value && e.target.value < 0) {
                        toastr.error("Priority order can not be negative...")
                        return
                      }
                      this.setState({ priority_order: e.target.value })
                    }}
                    required
                  />
                </div>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <label>Discount Percentage</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.discount_percentage}
                    onChange={e => {
                      // if (e.target.value && e.target.value < 0) {
                      //   toastr.error(
                      //     "Discount percentage can not be negative..."
                      //   )
                      //   return
                      // }
                      this.setState({ discount_percentage: e.target.value })
                    }}
                  />
                </div>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <label>Purchase Allowed</label>
                <div>
                  <input
                    className="form-control"
                    type="number"
                    value={this.state.purchase_allowed}
                    onChange={e => {
                      if (e.target.value && e.target.value < 0) {
                        toastr.error("Purchase allowed can not be zero...")
                        return
                      }
                      this.setState({ purchase_allowed: e.target.value })
                    }}
                  />
                </div>
              </Col>

              <Col sm={12} md={3} className="mt-2">
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    onChange={e => {
                      if (e.target.value) {
                        const time = moment
                          .tz(e.target.value, "Asia/Calcutta")
                          .format("YYYY-MM-DDTHH:mm:ss+05:30")
                        const timeInMSec = moment(
                          time,
                          "YYYY-MM-DDTHH:mm:00"
                        ).valueOf()
                        if (
                          this.state.end_time &&
                          timeInMSec >
                            moment(
                              this.state.end_time,
                              "YYYY-MM-DDTHH:mm:00"
                            ).valueOf()
                        ) {
                          toastr.error(
                            "Start Time can not be greater than End Time..."
                          )
                          this.setState({ allowSave: false })
                          return
                        } else {
                          this.setState({ start_time: time, allowSave: true })
                        }
                      } else {
                        this.setState({ start_time: null, allowSave: true })
                      }
                    }}
                    key={this.state.start_time}
                    value={
                      this.state.start_time
                        ? moment(this.state.start_time).format(
                            "YYYY-MM-DDTHH:mm:00"
                          )
                        : null
                    }
                  />
                </Form.Group>
              </Col>

              <Col sm={12} md={3} className="mt-2">
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    onChange={e => {
                      if (e.target.value) {
                        const time = moment
                          .tz(e.target.value, "Asia/Calcutta")
                          .format("YYYY-MM-DDTHH:mm:ss+05:30")
                        const timeInMSec = moment(
                          time,
                          "YYYY-MM-DDTHH:mm:00"
                        ).valueOf()
                        if (
                          this.state.start_time &&
                          timeInMSec <
                            moment(
                              this.state.start_time,
                              "YYYY-MM-DDTHH:mm:00"
                            ).valueOf()
                        ) {
                          toastr.error(
                            "End Time can not be less than Start Time..."
                          )
                          this.setState({ allowSave: false })
                          return
                        } else {
                          this.setState({ end_time: time, allowSave: true })
                        }
                      } else {
                        this.setState({ end_time: null, allowSave: true })
                      }
                    }}
                    key={this.state.end_time}
                    value={
                      this.state.end_time
                        ? moment(this.state.end_time).format(
                            "YYYY-MM-DDTHH:mm:00"
                          )
                        : null
                    }
                  />
                </Form.Group>
              </Col>

              <Col sm={12} md={6} className="mt-2">
                <label>Sale Share Url</label>
                <input
                  className="form-control"
                  value={this.state.sale_share_url}
                  onChange={e =>
                    this.setState({ sale_share_url: e.target.value })
                  }
                />
              </Col>
              <Col sm="12" md="6" className="mt-4">
                <Row style={{ marginTop: "10px" }}>
                  <Upload
                    showUploadList={true}
                    onRemove={() => this._onImageRemove("thumbnail")}
                    defaultFileList={this.state.sale_thumbnail_url_list}
                    fileList={this.state.sale_thumbnail_url_list}
                    customRequest={fileObj =>
                      this._uploadImage(fileObj, "thumbnail")
                    }
                  >
                    <Button color="primary" onClick={() => {}}>
                      <i className="fas fa-upload" />
                      &nbsp; Upload thumbnail image
                    </Button>
                  </Upload>
                </Row>
              </Col>
            </Row>

            <Row className="d-flex ">
              <Col>
                <div
                  className="flex justify-content-end"
                  style={{ marginTop: "10px" }}
                >
                  <div className="d-flex">
                    <Button
                      htmlType="submit"
                      color={!this.state.allowSave ? "secondary" : "primary"}
                      disabled={!this.state.allowSave}
                    >
                      {this.props.action === "update" ? "Update" : "Create"}
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.onCancel()
                        this._resetState()
                      }}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }

    let data = new FormData()
    const payload = {}
    const { data: packageCollectionData = {} } = this.props || {}
    const { package_collection_uuid } = packageCollectionData || {}
    const {
      collection_name,
      status,
      type,
      sale_share_url,
      priority_order,
      discount_percentage,
      sale_thumbnail_Image,
      purchase_allowed,
      start_time,
      end_time,
    } = this.state

    if (package_collection_uuid) {
      payload.package_collection_uuid = package_collection_uuid
    }
    if (collection_name) {
      payload.collection_name = collection_name
    }
    if (status) {
      payload.status = status
    }
    if (type) {
      payload.type = type
    }
    if (start_time) {
      payload.start_time = moment(start_time).format()
    }
    if (end_time) {
      payload.end_time = moment(end_time).format()
    }
    if (sale_share_url) {
      payload.sale_share_url = sale_share_url
    }
    if (priority_order || priority_order == 0) {
      payload.priority_order = +priority_order
    }
    if (discount_percentage || discount_percentage == 0) {
      payload.discount_percentage = +discount_percentage
    }
    if (purchase_allowed || purchase_allowed == 0) {
      payload.purchase_allowed = +purchase_allowed
    }
    if (sale_thumbnail_Image) {
      data.append("sale_thumbnail_url", sale_thumbnail_Image)
    }

    data.append("payload", JSON.stringify(payload))
    this.props.onSubmit(data)
    this._resetState()
  }

  render() {
    return this._form()
  }
}
