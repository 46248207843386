import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"

const { Option } = Select

const StickersSearchAndSelectDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Sticker Name</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  placeholder={"Sticker Name"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "name")
                  }
                  allowClear={true}
                  name="name"
                  style={{ width: "90%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        {/* <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Asset ID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  placeholder={"Sticker ID"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "assetId")
                  }
                  allowClear={true}
                  name="assetId"
                  style={{ width: "90%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col> */}
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Mime Type</h5>
          </Divider>
          <Select
            onChange={value => {
              props.handleFilterChange(value, "mimeType")
            }}
            style={{ width: "90%" }}
            defaultValue={props.filters["mimeType"]}
            placeholder="Mime Type"
            allowClear
            options={props.mimeTypes.map(mime => ({
              label: mime,
              value: mime,
            }))}
          />
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Status</h5>
          </Divider>
          <Select
            allowClear={true}
            placeholder="Is Active"
            defaultValue={props.filters["isActive"]}
            onChange={value => {
              props.handleFilterChange(value, "isActive")
            }}
            style={{ width: "100%" }}
          >
            <Option value="true">True</Option>
            <Option value="false">False</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

StickersSearchAndSelectDrawer.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(StickersSearchAndSelectDrawer)
