import React from "react"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import { Divider } from "antd"
import ImageCard from "./imageCard"
import QuestionCard from "./questionCard"
import Questionnaire from "./questionnaire"

import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import {
  setTaxonomyData,
  setTaxonomyRoutes,
} from "../../appRedux/slices/taxonomy"

class Events extends Questionnaire {
  state = {
    currentTab: "event",
    labelToDisplay: "Event",
  }

  componentDidMount() {
    let modifiedJsonData = cloneDeep(this.props.currentData)
    const { eventList } = this.props
    if (eventList && eventList.length > 0) {
      let nowDate = new Date().toISOString()
      let activeEvent = []
      let inActiveEvent = []
      eventList.map(event => {
        if (
          event.status == "active" &&
          event.taxonomy_start_time < nowDate &&
          event.taxonomy_end_time > nowDate
        ) {
          activeEvent.push(event)
        } else inActiveEvent.push(event)
      })
      modifiedJsonData[1].sublevel = []
      activeEvent.forEach(item => {
        modifiedJsonData[1].sublevel.push({
          label: item.event_name,
          value: item.event_id,

          help: {
            thumbnail: item.event_thumbnail,
            video: item.event_video,
          },
        })
      })
      modifiedJsonData[1].sublevel.push({
        label: "Event not listed here",
        value: "inactive",
        key: "inactive",
        taxonomyJsonKey: "inactive",
      })
      modifiedJsonData[2] = {
        level: 3,
        label: "Non-Active Events",
        type: "card",
        key: "events_inactive",
        taxonomyJsonKey: "taxonomy_event",
        parent: "event_type",
        sublevel: inActiveEvent.map(item => {
          return {
            label: item.event_name,
            key: item.event_id,
            value: item.event_id,
            taxonomyJsonKey: item.event_id,

            lavel: 2,
            help: {
              thumbnail: item.event_thumbnail,
              video: item.event_video,
            },
          }
        }),
        dependentValue: "inactive",
      }
    }
    this.props.dispatch(
      setTaxonomyData({ currentData: modifiedJsonData, currentTab: "event" })
    )
    this.setState({ currentData: modifiedJsonData })
  }

  _handleSelection = (value, question, idx, _, sub_type) => {
    const currentData = cloneDeep(this.props.currentData)
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    const indexTab = taxonomyRoutes.findIndex(item => item.key == "event")

    currentData.forEach(item => {
      if (item.key === question.key) {
        item.selected = true
        item.partialSelected = value
        item.value = value

        if (question.isMultiple) {
          const existingData =
            selectedDetails[question.taxonomyJsonKey][question.key]
          if (!existingData) {
            selectedDetails[question.taxonomyJsonKey][question.key] = [value]
            item.value = [value]
          } else {
            selectedDetails[question.taxonomyJsonKey][question.key] = uniq([
              ...existingData,
              value,
            ])
            item.value = uniq([...existingData, value])
          }
        } else {
          selectedDetails[question.taxonomyJsonKey][question.key] = value
          selectedDetails[question.taxonomyJsonKey]["sub_type"] = sub_type
        }
      }

      if (item.parent === question.key) {
        if (item.dependentValue === value) {
          item.isVisible = true
        } else {
          item.isVisible = false
          delete item.selected
          item.value = undefined

          item.partialSelected = undefined
        }
      } else {
        if (item.level >= question.level && item.key !== question.key) {
          item.isVisible = false
          delete item.selected
          item.value = undefined
        }
      }
    })

    let stepCompleted = true

    currentData.forEach(item => {
      if (item.isVisible && item.value == undefined) {
        stepCompleted = false
        taxonomyRoutes[indexTab].completed = false
      }
    })

    if (stepCompleted) {
      this.props.setSelectedSection("glamour")
      taxonomyRoutes[indexTab].completed = true
    }
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )

    this.setState({
      currentData,
      ignore: false,
      tag: false,
      ignoreRatingLessThan4: false,
      retag: false,
    })

    this.props.setSelectedDetails(selectedDetails)
  }

  _renderCard = (question, key, idx) => {
    return (
      <>
        <div>
          <div>{question.label}</div>
          <div className="d-flex flex-wrap">
            {question.sublevel.map(data => {
              let selectedCard = ""
              if (question.isMultiple) {
                if (question.value?.includes(data.value)) {
                  selectedCard = "selectedCard"
                }
              } else {
                if (question.value === data.value) {
                  selectedCard = "selectedCard"
                }
              }
              return (
                <ImageCard
                  className={`question-card text-center contentCard ${selectedCard}`}
                  data={data}
                  _handleSelection={e =>
                    this._handleSelection(
                      data.value,
                      question,
                      key,
                      question.key,
                      data.sub_type
                    )
                  }
                  renderCardContent={this.props.renderCardContent}
                />
              )
            })}
          </div>
        </div>
        <Divider />
      </>
    )
  }

  _renderStepper = () => {
    let text = "Select All"
    return (
      <div style={{ margin: "1rem", fontWeight: "bold" }}>
        {this.state.labelToDisplay}&nbsp;({text})
      </div>
    )
  }

  _renderQuestions = () => {
    let data = this.props.currentData.filter(item => {
      return item.isVisible
    })

    return (
      <PerfectScrollbar>
        <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
          {data.map((question, idx) => {
            if (question.type === "card") {
              return this._renderCard(question, idx)
            }
            const options =
              question.sublevel &&
              question.sublevel.map(data => {
                return {
                  label: data.label,
                  value: data.value,
                }
              })
            return (
              <QuestionCard
                question={question}
                _handleSelection={e =>
                  this._handleSelection(e.target.value, question, idx)
                }
                renderCardContent={this.props.renderCardContent}
              />
            )
          })}
        </div>
      </PerfectScrollbar>
    )
  }
  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderStepper()}
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    eventList: _.get(store, "content.eventList"),
    currentData: _.get(store, "taxonomy.event"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(Events)
