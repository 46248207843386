import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Pagination } from "antd"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import UpsertForm from "./communityUpsertForm"
import {
  getUser,
  getManagersList,
  getCommunityUserByType,
  deleteUser,
  createUser,
  updateUser,
  getLeads,
} from "appRedux/slices/community"
import { getAgencyData } from "../../appRedux/slices/uiConstants"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import images from "assets/images"

import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { isEmpty, size, map } from "lodash"
import { divIcon } from "leaflet"
import { bulkUploadCommunity } from "../../clientServices/communityService"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import toastr from "toastr"

class ContactsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      modal: false,
      expanded: false,
      showBulkUploadForm: false,
      contactListColumns: [
        {
          text: "id",
          dataField: "userId",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => (
            <div className="text-truncate">{user.userId}</div>
          ),
        },
        {
          dataField: "img",
          text: "#",
          style: {
            width: "5%",
          },
          formatter: (cellContent, user, rowIndex, y) => {
            return (
              <>
                {[12, 32].includes(user.jotUserType) ? (
                  <div className="avatar-xs" role="button">
                    <span className="avatar-title rounded-circle font-size-18">
                      <i className="bx bx-chevron-down" />
                    </span>
                  </div>
                ) : (
                  <div className="avatar-xs">
                    <span className="avatar-title rounded-circle">
                      {user.name.charAt(0)}
                    </span>
                  </div>
                )}
              </>
            )
          },
        },
        {
          text: "LOGIN NAME",
          dataField: "name",
          sort: true,
          style: {
            width: "20%",
          },
          formatter: (cellContent, user) => {
            return (
              <>
                <h5 className="font-size-14 mb-1 text-truncate">
                  <div
                    role="button"
                    onClick={e => {
                      e.stopPropagation(), e.preventDefault()
                      this.props.history.push(
                        `/community/manage?userId=${user.userId}&currentTab=dashboard`
                      )
                    }}
                  >
                    {user.name}
                  </div>
                </h5>
              </>
            )
          },
        },
        {
          dataField: "email",
          text: "EMAIL",
          sort: true,
          style: {
            width: "30%",
          },
        },
        {
          text: "Type",
          dataField: "type",
          sort: true,
          style: {
            width: "20%",
          },
          formatter: (cellContent, user) => {
            if ([12, 32].includes(user.jotUserType)) {
              return <div>Lead</div>
            } else if (
              [13, 33].includes(user.jotUserType) &&
              user.community &&
              user.community.leadId
            ) {
              return <div>Manager</div>
            }
            return <div>Unassigned Manager</div>
          },
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          style: {
            width: "10%",
          },
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <div role="button" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_COMMUNITY_MANAGEMENT"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    e.stopPropagation(),
                      e.preventDefault(),
                      this.handleEditClick(user)
                  }}
                ></i>
              </div>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={e => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_COMMUNITY_MANAGEMENT"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    e.stopPropagation(),
                      e.preventDefault(),
                      this.handleDeleteUser(user)
                  }}
                ></i>
              </Link>
              {[13, 33].includes(user.jotUserType) &&
                (!user.community ||
                  (user.community && !user.community.leadId)) && (
                  <div role="button" className="text-success">
                    <i
                      className="mdi mdi-plus-circle font-size-18"
                      id="assigntooltip"
                      onClick={e => {
                        if (
                          !this.props.currentUser?.permissions?.includes(
                            "EDIT_COMMUNITY_MANAGEMENT"
                          )
                        ) {
                          toastr.error(NO_PERMISSION_MESSAGE)
                          return
                        }
                        e.stopPropagation(),
                          e.preventDefault(),
                          this.handleAssignClick(user)
                      }}
                    ></i>
                  </div>
                )}
            </div>
          ),
        },
      ],
    }
    this.handleEditClick = this.handleEditClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidUserSubmit = this.handleValidUserSubmit.bind(this)
    this.handleEditClicks = this.handleEditClicks.bind(this)
  }

  componentDidMount() {
    const { communityManagerData: users } = this.props
    this.props.dispatch(getAgencyData())
    this.setState({ users })
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  handleEditClicks = arg => {
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_COMMUNITY_MANAGEMENT"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({ showForm: true, formAction: "create" })
    this.toggle()
  }
  toggleUploadBulk = arg => {
    if (
      !this.props.currentUser?.permissions?.includes(
        "EDIT_COMMUNITY_MANAGEMENT"
      )
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({ showBulkUploadForm: true })
    // this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users } = this.props
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      this.setState({ users: {}, isEdit: false })
    }
    if (this.props.refetchData) {
      this.props.getInitialData()
    }
  }

  /* Insert,Update Delete data */

  handleDeleteUser = user => {
    this.props.dispatch(deleteUser({ id: user.userId }))
  }

  handleEditClick = arg => {
    const user = arg
    this.setState({ selectedUser: user, showForm: true, formAction: "edit" })
    this.toggle()
  }

  handleAssignClick = async arg => {
    const user = arg
    this.setState({
      selectedUser: user,
      showForm: true,
      formAction: "assign",
    })
    this.toggle()
  }

  /**
   * Handling submit user on user form
   */
  handleValidUserSubmit = (e, values) => {
    const { onAddNewUser, onUpdateUser } = this.props
    const { isEdit, users, selectedUser } = this.state

    if (isEdit) {
      const updateUser = {
        id: users.id,
        name: values.name,
        designation: values.designation,
        tags: values.tags,
        email: values.email,
        projects: values.projects,
      }

      // update user
      onUpdateUser(updateUser)
    } else {
      const newUser = {
        id: Math.floor(Math.random() * (30 - 20)) + 20,
        name: values["name"],
        designation: values["designation"],
        email: values["email"],
        tags: values["tags"],
        projects: values["projects"],
      }
      // save new user
      onAddNewUser(newUser)
    }
    this.setState({ selectedUser: null })
    this.toggle()
  }

  onSubmit = async formData => {
    this.oncancel()
    switch (this.state.formAction) {
      case "edit":
        this.props.dispatch(updateUser(formData))
        break
      case "create":
        this.props.dispatch(createUser(formData))
        break
      case "assign":
        const { community: { usersList } = {}, ...rest } =
          this.state.selectedUser
        const data = {
          ...rest,
          community: JSON.stringify({ usersList, leadId: formData.toString() }),
        }
        this.props.dispatch(updateUser(data))
        break
      default:
    }
  }

  oncancel = () => {
    this.setState({ showForm: false, selectedUser: undefined })
  }
  /* Insert,Update Delete data */

  rowEvents = {
    onClick: (e, row, index) => {
      this.setState({ index })
      if ([12, 32].includes(row.jotUserType)) {
        this.props.dispatch(
          getManagersList({
            userId: row.userId,
            subCurrentPage: this.props.subCurrentPage,
            subPageSize: this.props.subPageSize,
          })
        )
      }
    },
  }

  _getExpandedRows = () => {
    let expanded = []
    if (
      this.props.communityManagers &&
      this.props.communityManagers.length > 0
    ) {
      return [parseInt(this.props.communityManagers[0].community.leadId)]
    }
    return expanded
  }

  _onUpload = async () => {
    const formData = new FormData()
    formData.append("file", this.state.csvFile)
    await bulkUploadCommunity(formData)
    this.setState({ showBulkUploadForm: false })
  }

  _bulkUploadForm = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="6">
              <label className="col-form-label">Upload CSV File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this.setState({
                    csvFile: e.target.files[0],
                  })
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm="12">
              <div className="d-flex justify-content-end">
                <Button
                  className="font-16 btn-block btn btn-primary"
                  color="primary"
                  onClick={this._onUpload}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="danger"
                  size="default"
                  // disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showBulkUploadForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  render() {
    if (this.props.loading) {
      return
    }
    let leads = []
    let unAssignedManagers = []
    unAssignedManagers =
      (this.props.communityManagerData &&
        this.props.communityManagerData.length > 0 &&
        this.props.communityManagerData.filter(
          user =>
            [13, 33].includes(user.jotUserType) &&
            (!user.community || (user.community && !user.community.leadId))
        )) ||
      []
    leads =
      (this.props.communityManagerData &&
        this.props.communityManagerData.length > 0 &&
        this.props.communityManagerData.filter(user =>
          [12, 32].includes(user.jotUserType)
        )) ||
      []
    const { SearchBar } = Search
    const { isEdit, users = [] } = this.state

    const pageOptions = {
      sizePerPage: 10,
      totalSize: users.length, // replace later with size(users),
      custom: true,
    }

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ]

    const selectRow = {
      mode: "checkbox",
    }

    const expandRow = {
      expanded: this._getExpandedRows(),
      renderer: (row, rowIndex) => {
        return (
          <div>
            <BootstrapTable
              data={this.props.communityManagers}
              columns={this.state.contactListColumns.map(column => {
                const { text, ...rest } = column
                return { ...rest }
              })}
              keyField="userId"
              classes={"table align-middle table-light table-hover"}
              bordered={false}
              striped={false}
              responsive
            />
            <div className="pagination pagination-rounded justify-content-end mb-2">
              <Pagination
                style={{ marginTop: "1rem" }}
                onChange={val => this.props._onSubPageChange(val, row.userId)}
                defaultCurrent={this.props.subCurrentPage}
                total={
                  this.props.totalManagers < 10000
                    ? this.props.totalManagers
                    : 10000
                }
                pageSize={this.props.subPageSize}
                showSizeChanger={false}
              />
            </div>
          </div>
        )
      },
    }

    return (
      <React.Fragment>
        <Container fluid style={{ minHeight: "90vh" }}>
          <Row className="justify-content-end">
            {!this.state.showForm && (
              <Col sm="8">
                <div className="text-sm-end pb-2">
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={this.handleEditClicks}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New User
                  </Button>
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary mx-1"
                    onClick={this.toggleUploadBulk}
                    // onClick={() => this.setState({ showBulkUploadForm: true })   }
                  >
                    <i className="fas fa-arrow-up me-1" />
                    Upload
                  </Button>
                </div>
              </Col>
            )}
            <Col lg={this.state.showForm ? "7" : "12"}>
              {this.state.showBulkUploadForm && this._bulkUploadForm()}
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={this.state.contactListColumns}
                    data={
                      this.props.type === "head"
                        ? [...leads, ...unAssignedManagers]
                        : this.props.communityManagerData
                    }
                    search
                  >
                    {toolkitprops => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitprops.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              {this.props.type === "lead" ? (
                                <BootstrapTable
                                  {...toolkitprops.baseProps}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-hover"
                                  }
                                  keyField="userId"
                                  bordered={false}
                                  striped={false}
                                  responsive
                                />
                              ) : (
                                <BootstrapTable
                                  {...toolkitprops.baseProps}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-hover"
                                  }
                                  keyField="userId"
                                  bordered={false}
                                  striped={false}
                                  expandRow={expandRow}
                                  rowEvents={this.rowEvents}
                                  responsive
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2">
                            <Pagination
                              style={{ marginTop: "1rem" }}
                              onChange={val =>
                                this.props.type === "lead"
                                  ? this.props._onSubPageChange(val)
                                  : this.props._onPageChange(val)
                              }
                              defaultCurrent={
                                this.props.type === "lead"
                                  ? this.props.subCurrentPage
                                  : this.props.currentPage
                              }
                              total={
                                this.props.type === "lead"
                                  ? this.props.totalManagers < 10000
                                    ? this.props.totalManagers
                                    : 10000
                                  : this.props.total < 10000
                                  ? this.props.total
                                  : 10000
                              }
                              pageSize={
                                this.props.type === "lead"
                                  ? this.props.subPageSize
                                  : this.props.pageSize
                              }
                              showSizeChanger={false}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
            {this.state.showForm && (
              <UpsertForm
                currentUser={this.props.currentUser}
                action={this.state.formAction}
                oncancel={this.oncancel}
                onSubmit={this.onSubmit}
                leadsData={this.props.leadsData}
                selectedUser={this.state.selectedUser}
                agencyList={this.props.agencyList}
              />
            )}
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

ContactsList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
}
function mapStateToProps(store) {
  return {
    queryParams: _.get(store, "routing.location.search")
      ? QueryString.parseUrl(_.get(store, "routing.location.search")).query
      : undefined,
    total: _.get(store, "activityLog.count"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    communityManagers: _.get(store, "community.communityManagers"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "community.managerDataLoading"),
    dataLoading: _.get(store, "community.dataLoading"),
    refetchData: _.get(store, "community.refetchData"),
    total: _.get(store, "community.total"),
    totalManagers: _.get(store, "community.totalManagers"),
    leadsData: _.get(store, "community.leads"),
    agencyList: _.get(store, "uiConstants.agencyList"),
  }
}

export default connect(mapStateToProps)(ContactsList)
