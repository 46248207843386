import React from "react"
import { Divider } from "antd"
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Card, CardBody, Row, Col } from "reactstrap"
import moment from "moment"
import { ChipsInput, MultiSelect } from "../ValidatorForm"
import { Form } from "react-bootstrap"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import toastr from "toastr"

export default class ChallengeUpsertForm extends ErrorBoundComponent {
  state = {
    formData: {
      banner_list: [
        {
          element_cta: "",
          id: uuidv4(),
          view_order: 0,
        },
      ],
      video: {
        id: uuidv4(),
        auto_play: true,
        mute_on_start: true,
      },
      background_color_code: "#5f78f1",
      max_video_duration_ms: 60000,
      theme: {
        status_bar_color: "#5f78f1",
        background_color_code: "#5f78f1",
      },
      lang_code: "en",
      bannerType: "image",
      fileList: {},
      type: "HASHTAG",
      status: "NOT_STARTED",
      target: [],
    },
    showAudioDetails: false,
    action: this.props.action,
    errors: {},
    selectedLangs: [],
  }

  componentDidMount() {
    const challenge = this.props.challenge
    if (!_.isEmpty(challenge)) {
      let bannerType = "image"
      let banner_list = this.state.formData.banner_list
      let video = this.state.formData.video
      let target = this.state.formData.target
      let selectedLangs = [...this.state.selectedLangs]
      if (challenge.banner_list) {
        banner_list = challenge.banner_list
        bannerType = "image"
        video = null
      } else if (challenge.video) {
        bannerType = "video"
        video = challenge.video
        banner_list = null
      } else {
        bannerType = "image"
      }

      if (!_.isEmpty(challenge.target)) {
        challenge.target?.forEach(item => {
          if (!_.isEmpty(item)) {
            selectedLangs.push(item.lang_code)
            const currentTarget = { ...item }
            currentTarget.disabled = true
            if (currentTarget.video) {
              currentTarget.bannerType = "video"
            } else {
              currentTarget.bannerType = "image"
            }
            target.push(currentTarget)
          }
        })
      }

      let formData = {
        hashtag: challenge.hashtag,
        type: challenge.type,
        lang_code: challenge.lang_code,
        status: challenge.status,
        audio_id: challenge.audio_id,
        max_video_duration_ms: challenge.max_video_duration_ms,
        min_video_duration_ms: challenge.min_video_duration_ms,
        description: challenge.description,
        orig_video_id: challenge.orig_video_id,
        sponsored_text: challenge.sponsored_text,
        hide_stats: challenge.hide_stats === "Y" ? true : false,
        start_date: challenge.start_date,
        end_date: challenge.end_date,
        promotion_end_date: challenge.promotion_end_date,
        sponsored: challenge.sponsored === "Y" ? true : false,
        hide_banner: challenge.hide_banner === "Y" ? true : false,
        theme: {
          status_bar_color: challenge?.theme?.status_bar_color,
          background_color_code: challenge?.theme?.background?.color_code,
          start_date_ms: challenge?.theme?.start_date_ms,
          end_date_ms: challenge?.theme?.end_date_ms,
        },
        banner_list,
        video,
        is_votable: challenge.is_votable,
        background_color_code: challenge.background_color_code,
        bannerType,
        target: target ? target : [],
        target_languages: _.isEmpty(challenge.target_languages)
          ? []
          : challenge.target_languages,
      }
      this.setState({
        formData: {
          ...this.state.formData,
          ...formData,
        },
        selectedLangs: _.uniq(selectedLangs),
      })
    }
  }

  _handleFieldChange = (field, value, parent, index) => {
    if (parent) {
      switch (parent) {
        case "banner_list":
          const banner_list = [...this.state.formData.banner_list]
          banner_list[index] = {
            ...banner_list[index],
            [field]: value,
          }
          this.setState({
            formData: {
              ...this.state.formData,
              banner_list,
            },
          })
          break
        default:
          this.setState({
            formData: {
              ...this.state.formData,
              [parent]: {
                ...this.state.formData[parent],
                [field]: value,
              },
            },
          })
      }
    } else {
      if (field === "bannerType") {
        if (value === "image") {
          this.setState({
            formData: {
              ...this.state.formData,
              banner_list: [
                {
                  element_cta: "",
                  id: uuidv4(),
                  view_order: 0,
                },
              ],
              video: null,
              bannerType: "image",
            },
          })
        } else {
          this.setState({
            formData: {
              ...this.state.formData,
              video: {
                id: uuidv4(),
                auto_play: true,
                mute_on_start: true,
              },
              banner_list: null,
              bannerType: "video",
            },
          })
        }
      } else {
        this.setState({
          formData: {
            ...this.state.formData,
            [field]: value,
          },
          errors: {
            ...this.state.errors,
            [field]: null,
          },
        })
      }
    }
  }

  _findFormErrors = () => {
    const newErrors = {}
    const { hashtag, start_date, end_date } = this.state.formData
    if (!hashtag || hashtag === "")
      newErrors.hashtag = "Please provide the hashtag!"
    if (!start_date || start_date === "")
      newErrors.start_date = "Please provide the start date!"
    if (!end_date || end_date === "")
      newErrors.end_date = "Please provide the end date!"
    return newErrors
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      formData: {
        ...this.state.formData,
        fileList: {
          ...this.state.formData.fileList,
          [key]: file,
        },
      },
    })
  }

  _addBanner = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        banner_list: [
          ...this.state.formData.banner_list,
          {
            element_cta: "",
            id: uuidv4(),
            view_order: this.state.formData.banner_list.length,
          },
        ],
      },
    })
  }

  _addBannerForTarget = index => {
    const target = [...this.state.formData.target]
    target[index] = {
      ...target[index],
      banner_list: [
        ...target[index].banner_list,
        {
          element_cta: "",
          id: uuidv4(),
          view_order: target[index].banner_list.length,
        },
      ],
    }
    this.setState({
      formData: {
        ...this.state.formData,
        target,
      },
    })
  }

  _handleTargetFieldsChange = (field, value, index, bannerIndex, parent) => {
    const target = [...this.state.formData.target]
    if (parent) {
      target[index] = {
        ...target[index],
        [parent]: {
          ...target[index][parent],
          [field]: value,
        },
      }
      this.setState({
        formData: {
          ...this.state.formData,
          target,
        },
      })
    } else {
      if (field === "bannerType") {
        if (value === "image") {
          target[index] = {
            ...target[index],
            banner_list: [
              {
                element_cta: "",
                id: uuidv4(),
                view_order: 0,
              },
            ],
            video: null,
            bannerType: "image",
          }
          this.setState({
            formData: {
              ...this.state.formData,
              target,
            },
          })
        } else {
          target[index] = {
            ...target[index],
            video: {
              id: uuidv4(),
              auto_play: true,
              mute_on_start: true,
            },
            banner_list: null,
            bannerType: "video",
          }
          this.setState({
            formData: {
              ...this.state.formData,
              target,
            },
          })
        }
      } else if (field === "element_cta") {
        const banner_list = [...target[index].banner_list]
        banner_list[bannerIndex] = {
          ...banner_list[bannerIndex],
          [field]: value,
        }
        target[index] = {
          ...target[index],
          banner_list,
        }
        this.setState({
          formData: {
            ...this.state.formData,
            target,
          },
        })
      } else {
        if (field === "lang_code") {
          const selectedLangs = [...this.state.selectedLangs]
          selectedLangs[index] = value
          this.setState({
            selectedLangs,
          })
        }
        target[index] = {
          ...target[index],
          [field]: value,
        }
        this.setState({
          formData: {
            ...this.state.formData,
            target,
          },
        })
      }
    }
  }

  _addTarget = () => {
    const target = [
      ...this.state.formData.target,
      {
        banner_list: [
          {
            element_cta: "",
            id: uuidv4(),
            view_order: 0,
          },
        ],
        video: {
          id: uuidv4(),
          auto_play: true,
          mute_on_start: true,
        },
        bannerType: "image",
      },
    ]

    this.setState({
      formData: {
        ...this.state.formData,
        target,
      },
    })
  }

  _renderTargetForm = (target, index) => {
    return (
      <Row>
        <Col lg="2">
          <label className="col-form-label">Language</label>
          <div>
            <select
              className="form-select"
              onChange={e =>
                this._handleTargetFieldsChange(
                  "lang_code",
                  e.target.value,
                  index
                )
              }
              value={target.lang_code}
            >
              <option disabled selected value hidden>
                {" "}
                -- Select Target Language --{" "}
              </option>
              {LANGUAGES.map(lang => (
                <option
                  value={lang.value}
                  disabled={
                    this.state.selectedLangs.indexOf(lang.value) >= 0 ||
                    this.state.formData.lang_code === lang.value
                  }
                >
                  {lang.label}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg="6">
          <label className="col-form-label">Description</label>
          <div>
            <textarea
              className="form-control"
              defaultValue={target.description}
              onChange={e =>
                this._handleTargetFieldsChange(
                  "description",
                  e.target.value,
                  index
                )
              }
              rows="1"
            />
          </div>
        </Col>
        <Col lg="4">
          <label className="col-form-label">Original Video ID </label>
          <div>
            <input
              className="form-control"
              type="text"
              field="orig_video_id"
              onChange={e =>
                this._handleTargetFieldsChange(
                  "orig_video_id",
                  e.target.value,
                  index
                )
              }
              defaultValue={target.orig_video_id}
            />
          </div>
        </Col>
        <Row className="mb-3">
          <Col lg="4">
            <label className="col-form-label">Banner Type</label>
            <div>
              <select
                className="form-select"
                onChange={e =>
                  this._handleTargetFieldsChange(
                    "bannerType",
                    e.target.value,
                    index
                  )
                }
                value={target.bannerType}
                disabled={target.disabled}
              >
                <option value="image">Image</option>
                <option value="video">Video</option>
              </select>
            </div>
          </Col>
        </Row>
        {target.bannerType === "image" && (
          <>
            {target?.banner_list?.map((banner, bannerIndex) => (
              <Row className="mb-3">
                <Col lg="6">
                  <label className="col-form-label">Element CTA</label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      type="text"
                      onChange={e =>
                        this._handleTargetFieldsChange(
                          "element_cta",
                          e.target.value,
                          index,
                          bannerIndex
                        )
                      }
                      defaultValue={banner.element_cta}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div>
                    <div>
                      <label className="col-form-label">Banner Thumbnail</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={e => {
                          this._handleFileUpload(
                            `banner_${banner.id}`,
                            e.target.files[0]
                          )
                        }}
                      />
                    </div>
                  </div>
                </Col>
                {banner.bannerUrl && (
                  <Col className="align-self-end mx-5">
                    <Card className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                      <div>
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              src={banner.bannerUrl}
                            />
                          </Col>
                          <Col className="mx-4">
                            <span
                              className="delete-icon"
                              onClick={() =>
                                this._deleteTargetBanner(
                                  banner.id,
                                  target.lang_code
                                )
                              }
                            >
                              <i className="mdi mdi-delete"></i>
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                )}
              </Row>
            ))}
            <div>
              <div
                className="mt-2 btn btn-sm btn-primary"
                onClick={() => this._addBannerForTarget(index)}
                role="button"
              >
                Add Banner
              </div>
            </div>
          </>
        )}
        {target.bannerType === "video" && (
          <Row className="d-flex align-items-end">
            <Col lg="4">
              <div>
                <div>
                  <label className="col-form-label">Banner Video</label>
                  <input
                    type="file"
                    className="form-control"
                    disabled={!target.lang_code}
                    onChange={e => {
                      this._handleFileUpload(
                        `${target.lang_code}_player_url`,
                        e.target.files[0]
                      )
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div>
                <div>
                  <label className="col-form-label">Banner Thumbnail</label>
                  <input
                    type="file"
                    className="form-control"
                    disabled={!target.lang_code}
                    onChange={e => {
                      this._handleFileUpload(
                        `${target.lang_code}_video_thumbnail`,
                        e.target.files[0]
                      )
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg="2">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={e =>
                    this._handleTargetFieldsChange(
                      "auto_play",
                      e.target.checked,
                      index,
                      _,
                      "video"
                    )
                  }
                  checked={target.video.auto_play}
                />
                <label className="form-check-label">Auto Play</label>
              </div>
            </Col>
            <Col lg="2">
              <div className="form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={e =>
                    this._handleTargetFieldsChange(
                      "mute_on_start",
                      e.target.checked,
                      index,
                      _,
                      "video"
                    )
                  }
                  checked={target.video.mute_on_start}
                />
                <label className="form-check-label">Mute On Start</label>
              </div>
            </Col>
          </Row>
        )}
      </Row>
    )
  }

  _onSubmit = () => {
    if (
      !this.props.currentUser?.permissions?.includes("EDIT_MANAGE_CHALLENGE")
    ) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    const errors = this._findFormErrors()
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      })
      return
    }

    let formData = { ...this.state.formData }
    if (
      this.props.formAction === "update" &&
      formData?.fileList?.theme_bck_image
    ) {
      delete formData?.theme?.background_color_code
    }

    if (formData.type === "HASHTAG" && formData.status !== "NOT_STARTED") {
      delete formData.max_video_duration_ms
      delete formData.min_video_duration_ms
    }

    if (typeof formData.min_video_duration_ms === "string") {
      formData.min_video_duration_ms = parseInt(formData.min_video_duration_ms)
    }

    if (this.props.formAction === "update") {
      formData.challenge_id = this.props.challenge.challenge_id
      delete formData?.start_date
      delete formData?.status
    }

    formData.hide_stats = formData.hide_stats ? "Y" : "N"
    formData.sponsored = formData.sponsored ? "Y" : "N"
    formData.hide_banner = formData.hide_banner ? "Y" : "N"
    formData.hashtag = formData.hashtag.split(" ").join("").trim()

    let data = new FormData()

    Object.keys(formData.fileList).forEach(fileName => {
      data.append(fileName, formData.fileList[fileName])
    })

    delete formData?.fileList

    if (this.state.formData.bannerType === "image") {
      delete formData?.video
    } else {
      delete formData?.banner_list
    }

    if (_.isEmpty(formData.target)) {
      delete formData.target
    } else {
      formData.target.forEach(item => {
        if (item.bannerType === "image") {
          delete item?.video
        } else {
          delete item?.banner_list
        }
      })
    }

    data.append("details", JSON.stringify(formData))
    this.props.onSubmit(data)
  }

  _deleteBanner = bannerId => {
    const data = {
      banner_ids: [bannerId],
      challenge_id: this.props.challenge.challenge_id,
    }
    this.props.deleteBanner(data)
  }

  _deleteTargetBanner = (bannerId, langCode) => {
    const data = {
      challenge_id: this.props.challenge.challenge_id,
      target: [
        {
          lang_code: langCode,
          banner_ids: [bannerId],
        },
      ],
    }
    this.props.deleteBanner(data)
  }

  _renderUpsertForm = () => {
    return (
      <Card>
        <CardBody>
          <Divider>
            <h5 className="mb-0">Hashtag</h5>
          </Divider>
          <Row className="mb-3">
            <Col lg="4">
              <Form.Group>
                <Form.Label>Hashtag</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  onChange={e =>
                    this._handleFieldChange("hashtag", e.target.value)
                  }
                  defaultValue={this.state.formData.hashtag}
                  disabled={this.props.formAction === "update"}
                  isInvalid={!!this.state.errors.hashtag}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.hashtag}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg="4">
              <label>Sponsored Text</label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={this.state.formData.sponsored_text}
                  onChange={e =>
                    this._handleFieldChange("sponsored_text", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col lg="4" className="d-flex align-items-center mt-2">
              <div className="me-5">
                <div className="form-check form-switch form-switch-lg">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={e =>
                      this._handleFieldChange("sponsored", e.target.checked)
                    }
                    checked={
                      this.state.formData.sponsored
                        ? true
                        : this.props.currentUserType == 6
                        ? true
                        : false
                    }
                  />
                  <label className="form-check-label">Sponsored</label>
                </div>
              </div>
              <div>
                <div className="form-check form-switch form-switch-lg">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={e =>
                      this._handleFieldChange("hide_stats", e.target.checked)
                    }
                    checked={this.state.formData.hide_stats}
                  />
                  <label className="form-check-label">Hide Stats</label>
                </div>
              </div>
            </Col>

            <Col lg="4">
              <label className="col-form-label">Language</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange("lang_code", e.target.value)
                  }
                  value={this.state.formData.lang_code}
                  disabled={this.props.formAction === "update"}
                >
                  {LANGUAGES.map(lang => (
                    <option value={lang.value} key={lang.value}>
                      {lang.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col lg="4">
              <label className="col-form-label">Status</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange("status", e.target.value)
                  }
                  disabled={this.props.formAction === "update"}
                  value={this.state.formData.status}
                >
                  <option value="STARTED">Started</option>
                  <option value="NOT_STARTED">Not Started</option>
                  <option value="CANCELLED">Cancelled</option>
                  <option value="ENDED">Ended</option>
                </select>
              </div>
            </Col>

            <Col lg="4">
              <label className="col-form-label">Type</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange("type", e.target.value)
                  }
                  disabled={
                    this.props.formAction === "update"
                      ? this.props.currentUserType < 3
                        ? false
                        : true
                      : false
                  }
                  value={this.state.formData.type}
                >
                  <option value="AUDIO">Audio</option>
                  <option value="COLLAB">Collab</option>
                  <option value="HASHTAG">Hashtag</option>
                </select>
              </div>
            </Col>
            <Col lg="4">
              <Form.Group>
                <Form.Label className="col-form-label">Start Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  onChange={e =>
                    this._handleFieldChange(
                      "start_date",
                      moment
                        .tz(e.target.value, "Asia/Calcutta")
                        .format("YYYY-MM-DDTHH:mm:ss+05:30")
                    )
                  }
                  value={
                    this.props.formAction === "create"
                      ? null
                      : moment(this.state.formData.start_date).format(
                          "YYYY-MM-DDTHH:mm:00"
                        )
                  }
                  disabled={this.props.formAction === "update"}
                  isInvalid={!!this.state.errors.start_date}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.start_date}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group>
                <Form.Label className="col-form-label">End Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  onChange={e =>
                    this._handleFieldChange(
                      "end_date",
                      moment
                        .tz(e.target.value, "Asia/Calcutta")
                        .format("YYYY-MM-DDTHH:mm:ss+05:30")
                    )
                  }
                  value={
                    this.props.formAction === "create"
                      ? null
                      : moment(this.state.formData.end_date).format(
                          "YYYY-MM-DDTHH:mm:00"
                        )
                  }
                  isInvalid={!!this.state.errors.end_date}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.end_date}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group>
                <Form.Label className="col-form-label">
                  Promotion End Date
                </Form.Label>
                <Form.Control
                  type="datetime-local"
                  onChange={e =>
                    this._handleFieldChange(
                      "promotion_end_date",
                      moment
                        .tz(e.target.value, "Asia/Calcutta")
                        .format("YYYY-MM-DDTHH:mm:ss+05:30")
                    )
                  }
                  value={
                    this.props.formAction === "create"
                      ? null
                      : this.state.formData.promotion_end_date
                      ? moment(this.state.formData.promotion_end_date).format(
                          "YYYY-MM-DDTHH:mm:00"
                        )
                      : null
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.end_date}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg="4">
              <label className="col-form-label">Target Languages</label>
              <MultiSelect
                placeholderLabel="Languages"
                className="creator-form"
                defaultValue={this.state.formData.target_languages}
                options={LANGUAGES.map(lang => {
                  return { label: lang.label, value: lang.value }
                })}
                onChange={value =>
                  this._handleFieldChange("target_languages", value)
                }
              />
            </Col>
            {_.get(this.state, "formData.type") !== "HASHTAG" && (
              <Col lg="4">
                <label className="col-form-label">Audio</label>
                <div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={this.state.formData.audio_id}
                      disabled={
                        this.props.currentUserType < 3
                          ? false
                          : this.props.formAction === "update" &&
                            _.get(this.state, "formData.status") !==
                              "NOT_STARTED"
                      }
                      onChange={e => {
                        let value = e.target.value
                        if (!_.isEmpty(value)) {
                          this._handleFieldChange("min_video_duration_ms", null)
                          this._handleFieldChange("max_video_duration_ms", null)
                          this.setState({ showAudioDetails: true })
                          this.props.onAudioChange({
                            filters: { audioId: value },
                          })
                          this._handleFieldChange("audio_id", value)
                        }
                      }}
                    />
                  </div>
                </div>
              </Col>
            )}
            {_.get(this.state, "formData.type") !== "HASHTAG" &&
              this.props.audio &&
              this.state.showAudioDetails && (
                <Row>
                  <Col lg="4" style={{ marginTop: "10px" }}>
                    <b>Title: </b>
                    {_.get(this.props, "audio.title")}
                  </Col>
                  <Col lg="4">
                    <b>Artist: </b>
                    {_.get(this.props, "audio.artist")}
                  </Col>
                  <Col lg="4" style={{ marginBottom: "10px" }}>
                    <b>Duration: </b>
                    {_.get(this.props, "audio.duration_ms") + " ms"}
                  </Col>
                </Row>
              )}
            {_.get(this.state, "formData.type") !== "HASHTAG" && (
              <Col lg="4">
                <label className="col-form-label">Min Duration</label>
                <div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      disabled={
                        this.props.formAction === "update"
                          ? this.props.currentUserType > 1
                          : false
                      }
                      defaultValue={
                        _.get(this.state, "formData.min_video_duration_ms") ||
                        (_.get(this.props, "audio.duration_ms") - 5000 > 5000
                          ? _.get(this.props, "audio.duration_ms") - 5000
                          : 5000)
                      }
                      validations={["required"]}
                      onChange={e =>
                        this._handleFieldChange(
                          "min_video_duration_ms",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </Col>
            )}
            {_.get(this.state, "formData.type") !== "HASHTAG" && (
              <Col lg="4">
                <label className="col-form-label">Max Duration</label>
                <div>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={
                        _.get(this.props, "audio.duration_ms") <= 60000
                          ? _.get(this.props, "audio.duration_ms")
                          : 60000
                      }
                      disabled="true"
                      onChange={e =>
                        this._handleFieldChange(
                          "max_video_duration_ms",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </Col>
            )}
            <Col lg="8">
              <label className="col-form-label">Description</label>
              <div>
                <textarea
                  className="form-control"
                  defaultValue={this.state.formData.description}
                  onChange={e =>
                    this._handleFieldChange("description", e.target.value)
                  }
                  rows="1"
                />
              </div>
            </Col>
            <Col lg="4">
              <label className="col-form-label">Original Video ID </label>
              <div>
                <input
                  className="form-control"
                  type="text"
                  field="orig_video_id"
                  onChange={e =>
                    this._handleFieldChange("orig_video_id", e.target.value)
                  }
                  defaultValue={this.state.formData.orig_video_id}
                />
              </div>
            </Col>
            <Col lg="4" className="d-flex align-items-center">
              <div className="me-5">
                <div className="form-check form-switch form-switch-lg mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={this.state.formData.hide_banner}
                    onChange={e =>
                      this._handleFieldChange("hide_banner", e.target.checked)
                    }
                  />
                  <label className="form-check-label">Hide Banner</label>
                </div>
              </div>
              <div className="form-check form-switch form-switch-lg mt-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={
                    this.state.formData.is_votable === "Y" ||
                    this.state.formData.is_votable === true
                      ? true
                      : false
                  }
                  onChange={e =>
                    this._handleFieldChange("is_votable", e.target.checked)
                  }
                />
                <label className="form-check-label">Is Votable</label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div>
                <div>
                  <label className="col-form-label">Preview Thumbnail</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      this._handleFileUpload(
                        "preview_thumbnail",
                        e.target.files[0]
                      )
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div>
                <div>
                  <label className="col-form-label">Preview Video</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      this._handleFileUpload("preview_video", e.target.files[0])
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div>
                <div>
                  <label className="col-form-label">
                    Preview Verticle Thumbnail
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      this._handleFileUpload(
                        "preview_vertical_thumbnail",
                        e.target.files[0]
                      )
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Divider>
            <h5 className="mb-0">Theme</h5>
          </Divider>
          <Row className="mb-3">
            <Col lg="4">
              <label className="col-form-label">Start Date</label>
              <div>
                <input
                  className="form-control"
                  type="datetime-local"
                  onChange={e =>
                    this._handleFieldChange(
                      "start_date_ms",
                      moment(e.target.value).valueOf() + "",
                      "theme"
                    )
                  }
                  value={moment(
                    +this.state.formData.theme.start_date_ms
                  ).format("YYYY-MM-DDTHH:MM")}
                />
              </div>
            </Col>
            <Col lg="4">
              <label className="col-form-label">End Date</label>
              <div>
                <input
                  className="form-control"
                  type="datetime-local"
                  onChange={e =>
                    this._handleFieldChange(
                      "end_date_ms",
                      moment(e.target.value).valueOf() + "",
                      "theme"
                    )
                  }
                  value={moment(+this.state.formData.theme.end_date_ms).format(
                    "YYYY-MM-DDTHH:MM"
                  )}
                />
              </div>
            </Col>
            <Col lg="2">
              <label className="col-form-label">Status Bar Color</label>
              <div>
                <input
                  className="form-control form-control-color w-100"
                  type="color"
                  value={this.state.formData.theme.status_bar_color}
                  onChange={e =>
                    this._handleFieldChange(
                      "status_bar_color",
                      e.target.value,
                      "theme"
                    )
                  }
                />
              </div>
            </Col>
            <Col lg="2">
              <label className="col-form-label">Background Color</label>
              <div>
                <input
                  className="form-control form-control-color w-100"
                  type="color"
                  value={this.state.formData.theme.background_color_code}
                  onChange={e =>
                    this._handleFieldChange(
                      "background_color_code",
                      e.target.value,
                      "theme"
                    )
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div>
                <div>
                  <label className="col-form-label">Overlay Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      this._handleFileUpload("overlay_image", e.target.files[0])
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div>
                <div>
                  <label className="col-form-label">Background Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      this._handleFileUpload(
                        "theme_bck_image",
                        e.target.files[0]
                      )
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Divider>
            <h5 className="mb-0">Leaderboard</h5>
          </Divider>
          <Row className="mb-3">
            <Col lg="3">
              <label className="col-form-label">Background Color</label>
              <div className="col-md-10">
                <input
                  className="form-control form-control-color w-100"
                  type="color"
                  value={this.state.formData.background_color_code}
                  onChange={e =>
                    this._handleFieldChange(
                      "background_color_code",
                      e.target.value
                    )
                  }
                />
              </div>
            </Col>
            <Col lg="4">
              <div>
                <div>
                  <label className="col-form-label">
                    Leaderboard Background Image
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      this._handleFileUpload(
                        `leaderboard_bck_image`,
                        e.target.files[0]
                      )
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Divider>
            <h5 className="mb-0">Banner</h5>
          </Divider>
          <Row className="mb-3">
            <Col lg="4">
              <label className="col-form-label">Banner Type</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange("bannerType", e.target.value)
                  }
                  value={this.state.formData.bannerType}
                  disabled={this.props.formAction === "update"}
                >
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                </select>
              </div>
            </Col>
          </Row>
          {this.state.formData.bannerType === "image" && (
            <>
              {this.state.formData?.banner_list?.map((banner, index) => (
                <Row className="mb-3">
                  <Col lg="3">
                    <label className="col-form-label">Element CTA</label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="text"
                        onChange={e =>
                          this._handleFieldChange(
                            "element_cta",
                            e.target.value,
                            "banner_list",
                            index
                          )
                        }
                        defaultValue={banner.element_cta}
                      />
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="d-flex align-items-center">
                      <div>
                        <label className="col-form-label">Logo Thumbnail</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={e => {
                            this._handleFileUpload(
                              `logo_url_${banner.id}`,
                              e.target.files[0]
                            )
                          }}
                        />
                      </div>
                      {banner.logoUrl && (
                        <Card className="mt-3 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete mx-5">
                          <div className="d-flex align-items-center">
                            <div>
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                src={banner.logoUrl}
                              />
                            </div>
                          </div>
                        </Card>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="d-flex align-items-center">
                      <div>
                        <label className="col-form-label">
                          Animated Logo Thumbnail
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={e => {
                            this._handleFileUpload(
                              `logo_animated_url_${banner.id}`,
                              e.target.files[0]
                            )
                          }}
                        />
                      </div>
                      {banner.logoAnimatedUrl && (
                        <Card className="mt-3 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete mx-5">
                          <div className="d-flex align-items-center">
                            <div>
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                src={banner.logoAnimatedUrl}
                              />
                            </div>
                          </div>
                        </Card>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="d-flex align-items-center">
                      <div>
                        <label className="col-form-label">
                          Banner Thumbnail
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={e => {
                            const img = new Image()
                            img.onload = () => {
                              const height = img.height
                              const width = img.width

                              if (index === 0) {
                                this._handleFieldChange("banner_height", height)
                                this._handleFieldChange("banner_width", width)
                              }
                            }

                            img.src = URL.createObjectURL(e.target.files[0])

                            this._handleFileUpload(
                              `banner_${banner.id}`,
                              e.target.files[0]
                            )
                          }}
                        />
                      </div>
                      {banner.bannerUrl && (
                        <Card className="mt-3 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete mx-5">
                          <div className="d-flex align-items-center">
                            <div>
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                src={banner.bannerUrl}
                              />
                            </div>
                            <div className="mx-2">
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "1.5rem",
                                }}
                                onClick={() => this._deleteBanner(banner.id)}
                              >
                                <i className="mdi mdi-delete"></i>
                              </span>
                            </div>
                          </div>
                        </Card>
                      )}
                    </div>
                  </Col>
                </Row>
              ))}
              <div>
                <div
                  className="mt-2 btn btn-sm btn-primary"
                  onClick={this._addBanner}
                  role="button"
                >
                  Add Banner
                </div>
              </div>
            </>
          )}
          {this.state.formData.bannerType === "video" && (
            <Row className="d-flex align-items-end">
              <Col lg="4">
                <div>
                  <div>
                    <label className="col-form-label">Banner Video</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        this._handleFileUpload(
                          `${this.state.formData.lang_code}_player_url`,
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div>
                  <div>
                    <label className="col-form-label">Banner Thumbnail</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={e => {
                        const img = new Image()
                        img.onload = () => {
                          const height = img.height
                          const width = img.width

                          this._handleFieldChange(
                            "banner_height",
                            height,
                            "video"
                          )
                          this._handleFieldChange(
                            "banner_width",
                            width,
                            "video"
                          )
                        }

                        img.src = URL.createObjectURL(e.target.files[0])
                        this._handleFileUpload(
                          `${this.state.formData.lang_code}_video_thumbnail`,
                          e.target.files[0]
                        )
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col lg="4" className="d-flex align-items-center">
                <div className="me-5">
                  <div className="form-check form-switch form-switch-lg mb-3 mt-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={e =>
                        this._handleFieldChange(
                          "auto_play",
                          e.target.checked,
                          "video"
                        )
                      }
                      checked={this.state.formData.video.auto_play}
                    />
                    <label className="form-check-label">Auto Play</label>
                  </div>
                </div>
                <div className="form-check form-switch form-switch-lg mb-3 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={e =>
                      this._handleFieldChange(
                        "mute_on_start",
                        e.target.checked,
                        "video"
                      )
                    }
                    checked={this.state.formData.video.mute_on_start}
                  />
                  <label className="form-check-label">Mute On Start</label>
                </div>
              </Col>
            </Row>
          )}
          {this.state.formData?.target?.map((item, index) => {
            return (
              <>
                <Divider>Target #{index + 1}</Divider>
                {this._renderTargetForm(item, index)}
              </>
            )
          })}
          <div className="d-flex justify-content-end">
            <div
              className="mt-3 btn btn-primary"
              onClick={this._addTarget}
              role="button"
            >
              Add Target
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <div
              className="btn btn-success"
              onClick={this._onSubmit}
              role="button"
            >
              {this.props.formAction === "update" ? `Update` : `Create`}
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }

  _render = () => {
    return (
      <Auxiliary loading={this.props.loading}>
        {this._renderUpsertForm()}
      </Auxiliary>
    )
  }
}
