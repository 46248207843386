import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const getStoriesData = async payload => {
  try {
    let data = await Axios.post(`/api/audioStories/get`, payload)
    return data.data.result.data
  } catch (error) {
    throw error
  }
}

export const upsertStory = async payload => {
  try {
    let data = await Axios.post(`/api/audioStories/upsert`, payload)
    toastr.success("Story Updated Successfully")
    return data.data
  } catch (error) {
    throw error
  }
}

export const upsertSeries = async payload => {
  try {
    let data = await Axios.post(`/api/audio-series/upsert`, payload)
    toastr.success("Series Updated Successfully")
    return data.data
  } catch (error) {
    throw error
  }
}

export const getAudioStoryCollectionData = async payload => {
  try {
    let data = await Axios.post(`/api/audioStoryCollection/get`, payload)
    return data.data.result.data
  } catch (error) {
    throw error
  }
}

export const getSeriesData = async payload => {
  try {
    let data = await Axios.post(`/api/audioSeries/get`, payload)
    return data.data.result.data
  } catch (error) {
    throw error
  }
}
export const upsertStoryCollection = async payload => {
  try {
    let data = await Axios.post(`/api/audioStoryCollection/upsert`, payload)
    toastr.success("Collection Updated Successfully")
    return data.data
  } catch (error) {
    throw error
  }
}
