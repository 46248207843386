import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import {
  getChallengeListByFilters,
  resetState,
} from "appRedux/slices/challenge"
import _ from "lodash"
import { Pagination } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import "./styles.css"
import { challengeFilters } from "constants/uiConstants"
import ChallengeCard from "./challengeCard"
import toastr from "toastr"
import Chips from "components/CommonFilter/chips"

class BrowseChallenge extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchType: this.props.queryParams
        ? this.props.queryParams["searchType"]
        : undefined,
      searchText: this.props.queryParams
        ? this.props.queryParams["searchText"]
        : undefined,
      type: this.props.queryParams ? this.props.queryParams["type"] : undefined,
      status: this.props.queryParams
        ? this.props.queryParams["status"]
        : undefined,
      sponsored: this.props.queryParams
        ? this.props.queryParams["sponsored"]
        : this.props.currentUserType === 6
        ? "Y"
        : "N",
    },
    upsertAction: "create",
    challenge: {},
    action: "",
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getChallengeListByFilters(_.deepClean(this.state.filters))
      )
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (!this.props.common.error && this.props.refetchChallengeList) {
      this.props.getChallengeListByFilters(_.deepClean(this.state.filters))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _searchContent = () => {
    this.props.history.push(
      `/challenge/browse?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(
      getChallengeListByFilters(_.deepClean(this.state.filters))
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._searchContent()
      }
    )
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: challengeFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/challenge/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getChallengeListByFilters(_.deepClean(this.state.filters))
        )
      }
    )
  }

  _searchChallenge = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/challenge/browse?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(
      getChallengeListByFilters(_.deepClean(this.state.filters))
    )
  }

  _closeFilters = () => {
    this._searchChallenge()
    this.setState({
      showAdvancedFilter: false,
      showFilters: false,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <Chips
          showFilters={this.state.showFilters}
          filters={this.state.filters}
          search={this._closeFilters}
          removeFilter={this._removeFilter}
          handleFilterChange={this._handleFilterChange}
          type="challenge"
          toggleFilters={this._toggleFilters}
        />
        <div className="d-flex justify-content-end mb-3">
          <button
            className="btn btn-primary"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes(
                  "VIEW_MANAGE_CHALLENGE"
                )
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.props.history.push(`/challenge/manage`)
            }}
          >
            Create
          </button>
        </div>
        <div className="challenge-card-container">
          {this.props.challengeList.map(challenge => {
            return (
              <div key={challenge.challenge_uuid}>
                <ChallengeCard
                  challenge={challenge}
                  currentUser={this.props.currentUser}
                />
              </div>
            )
          })}
        </div>
        <div className="d-flex justify-content-end py-2">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={this._onPageChange}
            defaultCurrent={this.state.filters["currentPage"]}
            total={this.props.total}
            showSizeChanger={false}
          />
        </div>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "challenge.loading"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    common: _.get(store, "common"),
    total: _.get(store, "challenge.total"),
    refetchChallengeList: _.get(store, "challenge.refetchChallengeList"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    challengeList: _.get(store, "challenge.challengeList"),
  }
}

export default connect(mapStateToProps)(BrowseChallenge)
