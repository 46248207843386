import { call, takeLatest, put } from "redux-saga/effects"
import { requestGetCurrentUser } from "appRedux/sagas/requests/currentUser"
import { getCurrentUser, setCurrentUser } from "appRedux/slices/currentUser"
import { fetchError } from "appRedux/slices/common"

function* handleGetCurrentUser(params) {
  try {
    const data = yield call(requestGetCurrentUser, params)
    yield put(setCurrentUser(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getCurrentUser.type, handleGetCurrentUser)
}
