import React from "react"
import { Form, Select, Tooltip, Input } from "antd"

import _map from "lodash/map"
import _Find from "lodash/find"
import _Filter from "lodash/filter"
import _IsUndefined from "lodash/isUndefined"
import _IsEmpty from "lodash/isEmpty"

import ValidatorComponent from "./validator_component"
import { FormContextItem } from "./form_context"

class Hashtags extends ValidatorComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    const stateObj = {}
    let currentValue = nextProps.value ? nextProps.value : prevState.value

    if (!prevState.defaultUpdated && !_IsUndefined(nextProps.defaultValue)) {
      currentValue = nextProps.defaultValue
    }

    const value = _Filter(currentValue, val =>
      _Find(nextProps.options, { value: val }) ? true : false
    )

    stateObj.value = value
    if (prevState.error) {
      stateObj.error = Hashtags.validate(
        stateObj.value,
        nextProps.validations,
        nextProps.errors
      )
    }

    if (_IsEmpty(stateObj)) {
      return null
    } else {
      return stateObj
    }
  }

  state = {
    defaultUpdated: false,
    error: "",
    value: [],
  }
  _onChange = async value => {
    if (!this.props.value) {
      this.setState(
        {
          value,
          defaultUpdated: true,
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.value)
          }
        }
      )
    } else {
      if (this.props.onChange) {
        await this.props.onChange(value)
        if (this.state.error) {
          this._validate()
        }
      }
    }
  }

  _inputRenderer = () => {
    let { options, onSearch } = this.props
    return (
      <>
        <Select
          mode="tags"
          optionFilterProp="children"
          defaultValue={this.props.defaultValue}
          style={{ width: "100%" }}
          placeholder="Select Hashtags"
          onSelect={this.props.onChange}
          onDeselect={this.props.deSelect}
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {options &&
            options.map(e => (
              <Select.Option
                key={`select_options_${e.value}_${"index"}`}
                value={e.value}
              >
                {"#" + e.label}
              </Select.Option>
            ))}
        </Select>
      </>
    )
  }

  _inputContainer = () => {
    if (this.props.toolTipLabel) {
      return (
        <Tooltip
          title={this.props.toolTipLabel}
          trigger={["focus"]}
          placement="bottom"
        >
          {this._inputRenderer()}
        </Tooltip>
      )
    }
    return this._inputRenderer()
  }

  _render = () => {
    return (
      <Form.Item
        className="full_width"
        validateStatus={this.state.error ? "error" : "success"}
        help={this.state.error}
        label={this.props.label}
        {...this.props.layout}
        {...this.props}
      >
        {this._inputContainer()}
      </Form.Item>
    )
  }
}

export default FormContextItem(Hashtags)
