import { call, takeLatest, put } from "redux-saga/effects"
import { createReview, createReviewSuccess } from "../../slices/review"
import { requestCreateReview } from "../requests/review"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

function* handleCreateReview(params) {
  try {
    toastr.info("Action in progress ...")
    const data = yield call(requestCreateReview, params)
    yield put(createReviewSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(createReview.type, handleCreateReview)
}
