import { createSlice } from "@reduxjs/toolkit"

const trendingSlice = createSlice({
  name: "trending",
  initialState: {
    loading: false,
    refetchData: false,
    campaign: [],
  },
  reducers: {
    getCampaign(state) {
      return { ...state, loading: true, campaign: [] }
    },

    getCampaignSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        campaign: payload.data.result,
        refetchData: false,
        totalCampaign: payload.data.result?.total?.value || 0,
      }
    },

    getBrandCampaign(state) {
      return { ...state, loading: true, campaign: [] }
    },

    getBrandCampaignSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        campaign: payload.data.result,
        refetchData: false,
        totalCampaign: payload.data.result?.total?.value || 0,
      }
    },

    campaignCreate(state) {
      return {
        ...state,
        loading: true,
      }
    },
    campaignCreateSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    campaignUpdate(state) {
      return {
        ...state,
        loading: true,
      }
    },
    campaignUpdateSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    campaignDelete(state) {
      return {
        ...state,
        loading: true,
      }
    },
    campaignDeleteSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertBrandLogo(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertBrandLogoSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState(state) {
      return {
        campaign: [],
        loading: false,
        refetchData: false,
      }
    },
  },
})

export const {
  getCampaign,
  getCampaignSuccess,
  resetState,
  campaignCreate,
  campaignCreateSuccess,
  campaignUpdate,
  campaignUpdateSuccess,
  campaignDelete,
  campaignDeleteSuccess,
  getBrandCampaign,
  getBrandCampaignSuccess,
  upsertBrandLogo,
  upsertBrandLogoSuccess,
} = trendingSlice.actions

export default trendingSlice.reducer
