import Axios from "axios"

export const logoutUser = async () => {
  try {
    await Axios.get("/api/auth/logout")
  } catch (error) {
    return error
  }
}

export const login = async user => {
  try {
    const response = await Axios.post("/api/auth/login", user)
    return response.data
  } catch (error) {
    return error
  }
}

export const googlelogin = async user => {
  try {
    const response = await Axios.post("/api/auth/googlelogin", user)
    return response.data
  } catch (error) {
    return error
  }
}

export const getClientId = async () => {
  try {
    const response = await Axios.get("/api/auth/getClientId")
    return response.data
  } catch (error) {
    return error
  }
}

export const sendEmailOTP = async (mobileNo, email) => {
  try {
    const response = await Axios.post("/api/auth/otp/sendEmailOTP", {
      mobileNo,
      email,
    })
    return response.data
  } catch (error) {
    return error
  }
}

export const sendOTP = async mobileNo => {
  try {
    const response = await Axios.post("/api/auth/otp/send", { mobileNo })
    return response.data
  } catch (error) {
    return error
  }
}

export const verifyOTP = async (mobileNo, otp) => {
  try {
    const response = await Axios.post("/api/auth/otp/verify", { mobileNo, otp })
    return response.data
  } catch (error) {
    return error
  }
}
