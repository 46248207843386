import Axios from "axios"
import toastr from "toastr"
export const manageUser = async (action, userUuid) => {
  try {
    const response = await Axios.get(
      `/api/user/${action}?user_uuid=${userUuid}`
    )
    return response
  } catch (error) {
    return error
  }
}

export const createAppUser = async formData => {
  try {
    let response = await Axios.post(`/api/appuser/create`, formData)
    return response
  } catch (error) {
    if ("response" in error) {
      return error.response
    }
    return error
  }
}

export const bulkUploadUserForBoosting = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/appuser/boost/bulk`, formData)
    return data
  } catch (error) {
    toastr.error("Error in boosting follows")
    throw error
  }
}

export const bulkUploadUserForDeltaUpdate = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/appuser/delta/bulk`, formData)
    return data
  } catch (error) {
    toastr.error("Error in bulk updating delta")
    throw error
  }
}

export const updateAppUserWebsiteLink = async payload => {
  try {
    let response = await Axios.post(
      `/api/appuser/update?userUuid=${payload.userUuid}`,
      payload.data
    )
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const zoneUpdateReview = async payload => {
  try {
    let response = await Axios.post(`/api/zone/review`, payload)
    toastr.success("Action Successfull!!", "")
    return response
  } catch (error) {
    throw error
  }
}

export const markTaskAsCompleted = async payload => {
  try {
    let data = await Axios.post(`/api/user/markTaskComplete`, payload)
    return data
  } catch (error) {
    throw error
  }
}

export const requestGetAppUserDetailsES = async ({ payload }) => {
  try {
    let data = await Axios.get(
      `/api/user/getAppUserByID?userUUID=${payload.userUuid}`
    )
    return data
  } catch (error) {
    throw error
  }
}
