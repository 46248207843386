import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { Tabs } from "antd"
import { MdAdd } from "react-icons/md"
import StrategyTabList from "./StrategyTabList"
import UpsertStrategyForm from "./UpsertStrategyForm"
import {
  getInterest,
  getStartegy,
  interestDetails,
  updateSubInterest,
} from "clientServices/interestServices"
import "./strategy.css"

class ManageStrategy extends ErrorBoundComponent {
  state = {
    showStrategyForm: false,
    interestData: [],
    interestNameMappings: {},
    mode: "PUBLIC",
    items: [
      {
        key: "1",
        label: `Public`,
        children: (
          <StrategyTabList
            mode="PUBLIC"
            handleDuplicateStrategy={item => {
              this.setState({
                showStrategyForm: true,
                item,
              })
            }}
            interestNameMappings={this.state.interestNameMappings}
          />
        ),
        mode: "PUBLIC",
      },
      // {
      //   key: "2",
      //   label: `Private`,
      //   children: (
      //     <StrategyTabList
      //       mode="PRIVATE"
      //       handleDuplicateStrategy={item => {
      //         this.setState({
      //           showStrategyForm: true,
      //           item,
      //         })
      //       }}
      //       interestNameMappings={this.state.interestNameMappings}
      //     />
      //   ),
      //   mode: "PRIVATE",
      // },
    ],
  }

  async componentDidMount() {
    this._refetchData()

    // const subinterestdata = await getInterest({ entity_type: "sub_interest" })
  }

  _refetchData = async () => {
    this.setState({
      loading: true,
    })
    const interestData = await getInterest()

    this.setState(
      {
        loading: false,
        interestData: interestData?.result?.data || [],
      },
      () => {
        const interestNameMappings = {}
        this.state.interestData.forEach(interest => {
          interestNameMappings[interest.id] = interest.name
        })
        this.setState({
          interestNameMappings,
        })
      }
    )
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _toggleStrategy = (action, data) => {
    this.setState({
      showStrategyForm: !this.state.showStrategyForm,
      action,
    })
  }

  _closeStrategyForm = () => {
    this.setState(
      {
        showStrategyForm: false,
      },
      () => this._refetchData()
    )
  }

  toggleAddInterest = () => {
    this.setState({ showStrategyForm: false })
  }

  _handleCloseSeed = () => {
    this.setState({
      showManageSeed: false,
      showReviewSeed: false,
      showTabAddVideos: false,
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          {this.state.showStrategyForm && (
            <UpsertStrategyForm
              interestData={this.state.interestData}
              mode={this.state.mode}
              interestNameMappings={this.state.interestNameMappings}
              toggleStrategy={this._toggleStrategy}
              closeStrategyForm={this._closeStrategyForm}
              item={this.state.action == "create" ? null : this.state.item}
            />
          )}

          {!this.state.showStrategyForm && (
            <>
              <Row>
                <Col lg="10" md="12">
                  <h3>Startegy Management</h3>
                </Col>
                <Col lg="2" md="12">
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      // className="btn btn-primary"
                      variant="contained"
                      onClick={() => {
                        this._toggleStrategy("create", null)
                        //   this.props.history.push(`/feed/interests/manage`)
                      }}
                      startIcon={<MdAdd />}
                    >
                      New Feed Strategy
                    </Button>
                  </div>
                </Col>
              </Row>

              <Tabs
                defaultActiveKey="1"
                items={this.state.items}
                onTabClick={value => {
                  let mode = "PUBLIC"
                  if (value === "2") mode = "PRIVATE"
                  this.setState({
                    mode,
                  })
                }}
              />
            </>
          )}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageStrategy)
