import { createSlice } from "@reduxjs/toolkit"

const cameraAssetSlice = createSlice({
  name: "cameraAsset",
  initialState: {
    cameraAsset: {},
    refetchCameraAssetList: false,
    loading: false,
    templatesList: [],
    refetchData: false,
    total: 0,
    stickers: [],
    arGames: [],
  },
  reducers: {
    getCameraAssetListByFilters(state, action) {
      return {
        ...state,
        loading: true,
        refetchCameraAssetList: false,
        cameraAssetList: [],
      }
    },
    getCameraAssetListByFiltersSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        cameraAssetList: payload.data.data,
        total: payload.data.total,
      }
    },
    getFiltersList(state, action) {
      return {
        ...state,
        filtersList: [],
      }
    },
    getFiltersListSuccess(state, { payload }) {
      return {
        ...state,
        filtersList: payload.data.data,
      }
    },
    getEffectsList(state, action) {
      return {
        ...state,
        effectssList: [],
      }
    },
    getEffectsListSuccess(state, { payload }) {
      return {
        ...state,
        effectsList: payload.data.data,
      }
    },
    getMasksList(state, action) {
      return {
        ...state,
        masksList: [],
      }
    },
    getMasksListSuccess(state, { payload }) {
      return {
        ...state,
        masksList: payload.data.data,
      }
    },
    getFuList(state, action) {
      return {
        ...state,
        faceUnityList: [],
      }
    },
    getFuListSuccess(state, { payload }) {
      return {
        ...state,
        faceUnityList: payload.data.data,
      }
    },
    upsertCameraAsset(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertCameraAssetSuccess(state) {
      return {
        ...state,
        loading: false,
        // showUpsertModal: false,
        refetchCameraAssetList: true,
      }
    },
    upsertTemplate(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertTemplateSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    getTemplates(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        templatesList: [],
      }
    },
    getTemplatesSuccess(state, { payload }) {
      return {
        ...state,
        templatesList: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    getStickersList(state, action) {
      return {
        ...state,
        stickers: [],
      }
    },
    getStickersListSuccess(state, { payload }) {
      return {
        ...state,
        stickers: payload.data || [],
      }
    },
    getArGamesList(state, action) {
      return {
        ...state,
        arGames: [],
      }
    },
    getArGamesListSuccess(state, { payload }) {
      return {
        ...state,
        arGames: payload.data || [],
      }
    },
    upgradeCameraAssetVersion(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upgradeCameraAssetVersionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    removeTemplate(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    removeTemplateSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    moderateCameraAsset(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    moderateCameraAssetSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    resetState(state) {
      return {
        ...state,
        cameraAsset: {},
        templatesList: [],
        total: 0,
        loading: false,
        refetchData: false,
      }
    },
  },
})

export const {
  getCameraAssetListByFilters,
  getCameraAssetListByFiltersSuccess,
  getFiltersList,
  getFiltersListSuccess,
  getEffectsList,
  getEffectsListSuccess,
  getMasksList,
  getMasksListSuccess,
  upsertCameraAsset,
  upsertCameraAssetSuccess,
  getFuList,
  getFuListSuccess,
  upsertTemplate,
  upsertTemplateSuccess,
  getTemplates,
  getTemplatesSuccess,
  removeTemplate,
  removeTemplateSuccess,
  moderateCameraAsset,
  moderateCameraAssetSuccess,
  getStickersList,
  getStickersListSuccess,
  getArGamesList,
  getArGamesListSuccess,
  upgradeCameraAssetVersion,
  upgradeCameraAssetVersionSuccess,
  resetState,
} = cameraAssetSlice.actions

export default cameraAssetSlice.reducer
