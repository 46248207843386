import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import SimpleBar from "simplebar-react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { CardBody, Col, Container, Row, Table } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Divider, Tooltip, Pagination } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getAppZoneVideos,
  getAppZoneImages,
  getAppZoneSocials,
  resetState,
} from "appRedux/slices/zone"
import {
  getZoneByIds,
  getZoneList,
  getZoneMetricsById,
} from "clientServices/zoneService"
import Auxiliary from "../../util/Auxiliary"
import {
  zoneContentFilters,
  zoneImageFilters,
  zoneSocialFilters,
  LANGUAGE_MAPPING,
} from "../../constants/uiConstants"
import NoDataFound from "components/NoDataFound/noDataFound"
import Chips from "components/CommonFilter/chips"
import SocialFeedCard from "./ZoneEntityCards/socialFeedCard"
import VideoFeedCard from "./ZoneEntityCards/videoFeedCard"
import ImageFeedCard from "./ZoneEntityCards/imageFeedCard"
import SocialCard from "./ZoneEntityCards/socialCard"
import VideoCard from "./ZoneEntityCards/videoCard"
import ImageCard from "./ZoneEntityCards/imageCard"
import ZoneMap from "./zoneMap"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const projection = [
  "type",
  "sub_type",
  "level",
  "zone_image",
  "zone_icon",
  "title",
  "sub_title",
  "zone_uuid",
  "status",
  "lang_code",
  "zone_type",
]

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}
class DetailZone extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    const zone_id =
      this.props.queryParams && this.props.queryParams["zone_id"]
        ? this.props.queryParams["zone_id"]
        : ""
    this.state = {
      showMeta: true,
      zone_id,
      zoneDetails: {},
      sibling_zones: [],
      l0_ZoneMapResponse: [],
      l1_ZoneMapResponse: [],
      l2_ZoneMapResponse: [],
      zone_metrics: {},
      loading: true,
      video_post_count: 1,
      image_post_count: 1,
      social_post_count: 1,
      pageType:
        this.props.queryParams && this.props.queryParams["pageType"]
          ? this.props.queryParams["pageType"]
          : "video",
      socialFilters: {
        pageType: "social",
        zone_id,
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        filterView:
          this.props.queryParams &&
          this.props.queryParams["filterView"] &&
          this.props.queryParams["filterView"] == "true"
            ? true
            : false,
        addDateGt:
          this.props.queryParams && this.props.queryParams["addDateGt"]
            ? this.props.queryParams["addDateGt"]
            : null,
        addDateLt:
          this.props.queryParams && this.props.queryParams["addDateLt"]
            ? this.props.queryParams["addDateLt"]
            : null,
        viewsGt:
          this.props.queryParams && this.props.queryParams["viewsGt"]
            ? this.props.queryParams["viewsGt"]
            : null,
        viewsLt:
          this.props.queryParams && this.props.queryParams["viewsLt"]
            ? this.props.queryParams["viewsLt"]
            : null,
        source:
          this.props.queryParams && this.props.queryParams["source"]
            ? this.props.queryParams["source"]
            : "",
        moderationLevel:
          this.props.queryParams && this.props.queryParams["moderationLevel"]
            ? this.props.queryParams["moderationLevel"]
            : "",
        langCode:
          this.props.queryParams && this.props.queryParams["langCode"]
            ? this.props.queryParams["langCode"]
            : "hi",
        sort:
          this.props.queryParams && this.props.queryParams["sort"]
            ? this.props.queryParams["sort"]
            : "view_count",
        order:
          this.props.queryParams && this.props.queryParams["order"]
            ? this.props.queryParams["order"]?.toLowerCase()
            : "desc",
      },
      imageFilters: {
        pageType: "image",
        zone_id,
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        filterView:
          this.props.queryParams &&
          this.props.queryParams["filterView"] &&
          this.props.queryParams["filterView"] == "true"
            ? true
            : false,
        addDateGt:
          this.props.queryParams && this.props.queryParams["addDateGt"]
            ? this.props.queryParams["addDateGt"]
            : null,
        addDateLt:
          this.props.queryParams && this.props.queryParams["addDateLt"]
            ? this.props.queryParams["addDateLt"]
            : null,
        viewsGt:
          this.props.queryParams && this.props.queryParams["viewsGt"]
            ? this.props.queryParams["viewsGt"]
            : null,
        viewsLt:
          this.props.queryParams && this.props.queryParams["viewsLt"]
            ? this.props.queryParams["viewsLt"]
            : null,
        source:
          this.props.queryParams && this.props.queryParams["source"]
            ? this.props.queryParams["source"]
            : "",
        moderationLevel:
          this.props.queryParams && this.props.queryParams["moderationLevel"]
            ? this.props.queryParams["moderationLevel"]
            : "",
        langCode:
          this.props.queryParams && this.props.queryParams["langCode"]
            ? this.props.queryParams["langCode"]
            : "hi",
        sort:
          this.props.queryParams && this.props.queryParams["sort"]
            ? this.props.queryParams["sort"]
            : "view_count",
        order:
          this.props.queryParams && this.props.queryParams["order"]
            ? this.props.queryParams["order"]?.toLowerCase()
            : "desc",
      },
      videoFilters: {
        pageType: "video",
        zone_id,
        pageSize:
          this.props.queryParams && this.props.queryParams["pageSize"]
            ? this.props.queryParams["pageSize"]
            : 10,
        currentPage:
          this.props.queryParams && this.props.queryParams["currentPage"]
            ? this.props.queryParams["currentPage"]
            : 1,
        filterView:
          this.props.queryParams &&
          this.props.queryParams["filterView"] &&
          this.props.queryParams["filterView"] == "true"
            ? true
            : false,
        addDateGt:
          this.props.queryParams && this.props.queryParams["addDateGt"]
            ? this.props.queryParams["addDateGt"]
            : null,
        addDateLt:
          this.props.queryParams && this.props.queryParams["addDateLt"]
            ? this.props.queryParams["addDateLt"]
            : null,
        viewsGt:
          this.props.queryParams && this.props.queryParams["viewsGt"]
            ? this.props.queryParams["viewsGt"]
            : null,
        viewsLt:
          this.props.queryParams && this.props.queryParams["viewsLt"]
            ? this.props.queryParams["viewsLt"]
            : null,
        source:
          this.props.queryParams && this.props.queryParams["source"]
            ? this.props.queryParams["source"]
            : "",
        moderationLevel:
          this.props.queryParams && this.props.queryParams["moderationLevel"]
            ? this.props.queryParams["moderationLevel"]
            : "",
        langCode:
          this.props.queryParams && this.props.queryParams["langCode"]
            ? this.props.queryParams["langCode"]
            : "hi",
        sort:
          this.props.queryParams && this.props.queryParams["sort"]
            ? this.props.queryParams["sort"]
            : "view_count",
        order:
          this.props.queryParams && this.props.queryParams["order"]
            ? this.props.queryParams["order"]?.toLowerCase()
            : "desc",
      },
      zoneMapFilters: {
        pageType: "zone_map",
        zone_id,
      },
    }
  }

  componentDidMount = async () => {
    // this.props.dispatch(getAllZones({ currentPage: 1, pageSize: 10000 }))

    if (!this.props.common.error && this.state.zone_id) {
      const zoneResponse = await getZoneByIds([this.state.zone_id])
      const zoneDetails = (zoneResponse && zoneResponse[0]) || {}
      const { type = [], sub_type = [], level, zone_uuid } = zoneDetails || {}
      let l0_ZoneMapResponse = []
      let l1_ZoneMapResponse = []
      let l2_ZoneMapResponse = []
      let sibling_zones = []
      if (level == 2) {
        let zoneIds = []
        if (type?.length > 0) {
          zoneIds = [...zoneIds, ...type]
        }
        if (sub_type?.length > 0) {
          zoneIds = [...zoneIds, ...sub_type]
        }
        const zoneMapResponse = (await getZoneByIds(zoneIds)) || []
        zoneMapResponse.forEach(zone => {
          if (zone.level == 0) {
            l0_ZoneMapResponse.push(zone)
          } else if (zone.level == 1) {
            l1_ZoneMapResponse.push(zone)
          }
        })

        const sibling_zone_list_0 = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "2",
          types: type,
          projection,
        })

        const sibling_zone_list_1 = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "2",
          subTypes: sub_type,
          projection,
        })

        let zoneIdMap = {}
        ;[...sibling_zone_list_1, ...sibling_zone_list_0].forEach(zone => {
          if (!zoneIdMap[zone.zone_uuid]) {
            zoneIdMap[zone.zone_uuid] = zone
          }
        })

        sibling_zones = Object.values(zoneIdMap) || []
      } else if (level == 1) {
        let zoneIds = []
        if (zoneDetails?.type?.length > 0) {
          zoneIds = [...zoneIds, ...type]
        }

        l0_ZoneMapResponse = (await getZoneByIds(zoneIds)) || []
        l2_ZoneMapResponse = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "2",
          subTypes: [zone_uuid],
          projection,
        })
        let siblingZoneIds = []

        l2_ZoneMapResponse.forEach(
          zone => (siblingZoneIds = [...siblingZoneIds, ...zone.sub_type])
        ) || []

        const zoneIdSet = new Set(siblingZoneIds)

        const sibling_zone_list_2 = (await getZoneByIds([...zoneIdSet])) || []

        const sibling_zone_list_0 =
          (await getZoneList({
            currentPage: 1,
            pageSize: 10000,
            level: "1",
            types: type,
            projection,
          })) || []

        let zoneIdMap = {}
        ;[...sibling_zone_list_0, ...sibling_zone_list_2].forEach(zone => {
          if (!zoneIdMap[zone.zone_uuid]) {
            zoneIdMap[zone.zone_uuid] = zone
          }
        })

        sibling_zones = Object.values(zoneIdMap)
      } else if (level == 0) {
        l1_ZoneMapResponse = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "1",
          types: [zone_uuid],
          projection,
        })
        l2_ZoneMapResponse = await getZoneList({
          currentPage: 1,
          pageSize: 10000,
          level: "2",
          types: [zone_uuid],
          projection,
        })

        let zoneIds = []

        l1_ZoneMapResponse.forEach(
          zone => (zoneIds = [...zoneIds, ...zone.type])
        ) || []
        l2_ZoneMapResponse.forEach(
          zone => (zoneIds = [...zoneIds, ...zone.type])
        ) || []

        const zoneIdSet = new Set(zoneIds)

        sibling_zones = (await getZoneByIds([...zoneIdSet])) || []
      }
      if (this.state.pageType === "image") {
        this.props.dispatch(
          getAppZoneImages(_.deepClean(_.deepClean(this.state.imageFilters)))
        )
      } else if (this.state.pageType === "social") {
        this.props.dispatch(
          getAppZoneSocials(_.deepClean(_.deepClean(this.state.socialFilters)))
        )
      } else if (this.state.pageType === "video") {
        this.props.dispatch(
          getAppZoneVideos(_.deepClean(_.deepClean(this.state.videoFilters)))
        )
      }

      const zone_metrics = await getZoneMetricsById(this.state.zone_id)

      this.setState({
        zoneDetails,
        video_post_count: zoneDetails?.video_post_count || 1,
        image_post_count: zoneDetails?.image_post_count || 1,
        social_post_count: zoneDetails?.social_post_count || 1,
        l0_ZoneMapResponse,
        l1_ZoneMapResponse,
        l2_ZoneMapResponse,
        sibling_zones,
        zone_metrics,
        loading: false,
      })

      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (
      key === "Enter" &&
      (this.state.showContentFilters === true ||
        this.state.showImageFilters === true ||
        this.state.showSocialFilters === true)
    ) {
      if (this.state.pageType === "image") {
        return this._closeImageFilters()
      }
      if (this.state.pageType === "social") {
        return this._closeSocialFilters()
      }
      if (this.state.pageType === "video") {
        return this._closeContentFilters()
      }
    }
    if (
      key === "Escape" &&
      (this.state.showContentFilters === true ||
        this.state.showImageFilters === true ||
        this.state.showSocialFilters === true)
    ) {
      this.setState({
        showContentFilters: false,
        showImageFilters: false,
        showSocialFilters: false,
      })
    }
  }

  _toggleContentFilters = () => {
    this.setState({
      showContentFilters: !this.state.showContentFilters,
    })
  }

  _toggleImageFilters = () => {
    this.setState({
      showImageFilters: !this.state.showImageFilters,
    })
  }

  _toggleSocialFilters = () => {
    this.setState({
      showSocialFilters: !this.state.showSocialFilters,
    })
  }

  _onContentPageChange = value => {
    let newFilters = _.cloneDeep(this.state.videoFilters)
    newFilters["currentPage"] = value
    this.setState(
      {
        videoFilters: newFilters,
      },
      () => {
        this.props.history.push(
          `/zone/detail?${QueryString.stringify(
            _.deepClean(this.state.videoFilters)
          )}`
        )
        this.props.dispatch(
          getAppZoneVideos(_.deepClean(this.state.videoFilters))
        )
      }
    )
  }

  _handleContentFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.videoFilters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    if (
      type === "searchText" ||
      type === "order" ||
      type === "moderationLevel" ||
      type === "langCode"
    ) {
      this.setState({
        videoFilters: newFilters,
      })
    } else {
      this.setState({
        videoFilters: newFilters,
      })
    }
  }

  _searchContent = () => {
    let { videoFilters } = this.state
    if (videoFilters && videoFilters.pageSize) {
      delete videoFilters.currentPage
    }
    this.props.history.push(
      `/zone/detail?${QueryString.stringify(
        _.deepClean(this.state.videoFilters)
      )}`
    )
    this.setState({ isOpen: false }, () =>
      this.props.dispatch(getAppZoneVideos(_.deepClean(videoFilters)))
    )
  }

  _removeContentFilter = videoFilters => {
    const newFilters = {
      ...this.state.videoFilters,
      [videoFilters]: zoneContentFilters[videoFilters].defaultValue, // NEED CHANGE
    }

    this.setState(
      {
        videoFilters: newFilters,
      },
      () => {
        this.props.history.push(
          `/zone/detail?${QueryString.stringify(
            _.deepClean(this.state.videoFilters)
          )}`
        )
        this.props.dispatch(
          getAppZoneVideos(_.deepClean(this.state.videoFilters))
        )
      }
    )
  }

  _closeContentFilters = () => {
    this._searchContent()
    this.setState({
      showContentFilters: false,
    })
  }

  _renderVideos = () => {
    return (
      <>
        <Auxiliary
          loading={this.props.zoneVediosLoading}
          error={_.get(this.props, "common.error")}
        >
          <Chips
            showFilters={this.state.showContentFilters}
            // showAdvancedFilters={this.state.showAdvancedFilters}
            filters={this.state.videoFilters}
            search={this._closeContentFilters}
            removeFilter={this._removeContentFilter}
            handleFilterChange={this._handleContentFilterChange}
            type="zone_feed_content"
            toggleFilters={this._toggleContentFilters}
            key={this.state.pageType}
            // toggleAdvancedFilters={this._toggleAdvancedFilters}
          />
          {this.props?.zoneVideos?.length > 0 ? (
            !this.state?.socialFilters?.filterView ? (
              <>
                <div className="trending-card-container">
                  {this.props.zoneVideos.map((video, index) => {
                    return <VideoFeedCard video={video} />
                  })}
                </div>

                <div className="d-flex justify-content-end mb-3">
                  <div className="d-flex justify-challenge-end">
                    <Pagination
                      style={{ marginTop: "1rem" }}
                      onChange={this._onContentPageChange}
                      current={parseInt(
                        this.state.videoFilters["currentPage"],
                        10
                      )}
                      defaultCurrent={parseInt(
                        this.state.videoFilters["currentPage"],
                        10
                      )}
                      total={
                        this.state.video_post_count > 10000
                          ? 10000
                          : this.state.video_post_count
                      }
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="trending-card-container">
                  {this.props.zoneVideos.map((video, index) => {
                    return <VideoCard video={video} />
                  })}
                </div>

                <div className="d-flex justify-content-end mb-3">
                  <div className="d-flex justify-challenge-end">
                    <Pagination
                      style={{ marginTop: "1rem" }}
                      onChange={this._onContentPageChange}
                      // current={parseInt(
                      //   this.state.videoFilters["currentPage"],
                      //   10
                      // )}
                      defaultCurrent={parseInt(
                        this.state.videoFilters["currentPage"],
                        10
                      )}
                      total={
                        this.props.totalZoneVideos > 10000
                          ? 10000
                          : this.props.totalZoneVideos
                      }
                      pageSize={this.state.videoFilters.pageSize}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </>
            )
          ) : (
            <NoDataFound message="No Data To Display" />
          )}
        </Auxiliary>
      </>
    )
  }

  _onImagePageChange = value => {
    let newFilters = _.cloneDeep(this.state.imageFilters)
    newFilters["currentPage"] = value
    this.setState(
      {
        imageFilters: newFilters,
      },
      () => {
        this.props.history.push(
          `/zone/detail?${QueryString.stringify(
            _.deepClean(this.state.imageFilters)
          )}`
        )
        this.props.dispatch(
          getAppZoneImages(_.deepClean(this.state.imageFilters))
        )
      }
    )
  }

  _handleImageFilterChange = (value, type) => {
    let newFilters = _.cloneDeep(this.state.imageFilters)
    newFilters[type] = value
    this.setState({
      imageFilters: newFilters,
    })
  }

  _searchImage = () => {
    let { imageFilters } = this.state
    if (imageFilters && imageFilters.pageSize) {
      delete imageFilters.currentPage
    }
    this.props.history.push(
      `/zone/detail?${QueryString.stringify(
        _.deepClean(this.state.imageFilters)
      )}`
    )
    this.setState({ isOpen: false }, () =>
      this.props.dispatch(getAppZoneImages(_.deepClean(imageFilters)))
    )
  }

  _removeImageFilter = imageFilters => {
    const newFilters = {
      ...this.state.imageFilters,
      [imageFilters]: zoneImageFilters[imageFilters].defaultValue, // NEED CHANGE
    }

    this.setState(
      {
        imageFilters: newFilters,
      },
      () => {
        this.props.history.push(
          `/zone/detail?${QueryString.stringify(
            _.deepClean(this.state.imageFilters)
          )}`
        )
        this.props.dispatch(
          getAppZoneImages(_.deepClean(this.state.imageFilters))
        )
      }
    )
  }

  _closeImageFilters = () => {
    this._searchImage()
    this.setState({
      showImageFilters: false,
    })
  }

  _renderImages = () => {
    return (
      <>
        <Auxiliary
          loading={this.props.zoneImagesLoading}
          error={_.get(this.props, "common.error")}
        >
          <Chips
            showFilters={this.state.showImageFilters}
            // showAdvancedFilters={this.state.showAdvancedFilters}
            filters={this.state.imageFilters}
            search={this._closeImageFilters}
            removeFilter={this._removeImageFilter}
            handleFilterChange={this._handleImageFilterChange}
            type="zone_feed_image"
            toggleFilters={this._toggleImageFilters}
            key={this.state.pageType}
            // toggleAdvancedFilters={this._toggleAdvancedFilters}
          />
          {this.props?.zoneImages?.length > 0 ? (
            !this.state?.imageFilters?.filterView ? (
              <>
                <div className="trending-card-container">
                  {this.props.zoneImages.map((image, index) => {
                    return <ImageFeedCard image={image} />
                  })}
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <div className="d-flex justify-challenge-end">
                    <Pagination
                      style={{ marginTop: "1rem" }}
                      onChange={this._onImagePageChange}
                      current={parseInt(
                        this.state.imageFilters["currentPage"],
                        10
                      )}
                      defaultCurrent={parseInt(
                        this.state.imageFilters["currentPage"],
                        10
                      )}
                      total={
                        this.state.image_post_count > 10000
                          ? 10000
                          : this.state.image_post_count
                      }
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="trending-card-container">
                  {this.props.zoneImages.map((image, index) => {
                    return <ImageCard image={image} />
                  })}
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <div className="d-flex justify-challenge-end">
                    <Pagination
                      style={{ marginTop: "1rem" }}
                      onChange={this._onImagePageChange}
                      // current={parseInt(
                      //   this.state.imageFilters["currentPage"],
                      //   10
                      // )}
                      defaultCurrent={parseInt(
                        this.state.imageFilters["currentPage"],
                        10
                      )}
                      total={
                        this.props.totalZoneImages > 10000
                          ? 10000
                          : this.props.totalZoneImages
                      }
                      pageSize={this.state.imageFilters.pageSize}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </>
            )
          ) : (
            <NoDataFound message="No Data To Display" />
          )}
        </Auxiliary>
      </>
    )
  }

  _onSocialPageChange = value => {
    let newFilters = _.cloneDeep(this.state.socialFilters)
    newFilters["currentPage"] = value
    this.setState(
      {
        socialFilters: newFilters,
      },
      () => {
        this.props.history.push(
          `/zone/detail?${QueryString.stringify(
            _.deepClean(this.state.socialFilters)
          )}`
        )
        this.props.dispatch(
          getAppZoneSocials(_.deepClean(this.state.socialFilters))
        )
      }
    )
  }

  _handleSocialFilterChange = (value, type) => {
    let newFilters = _.cloneDeep(this.state.socialFilters)
    newFilters[type] = value
    this.setState({
      socialFilters: newFilters,
    })
  }

  _searchSocial = () => {
    let { socialFilters } = this.state
    if (socialFilters && socialFilters.pageSize) {
      delete socialFilters.currentPage
    }
    this.props.history.push(
      `/zone/detail?${QueryString.stringify(
        _.deepClean(this.state.socialFilters)
      )}`
    )
    this.setState({ isOpen: false }, () =>
      this.props.dispatch(getAppZoneSocials(_.deepClean(socialFilters)))
    )
  }

  _removeSocialFilter = socialFilters => {
    const newFilters = {
      ...this.state.socialFilters,
      [socialFilters]: zoneSocialFilters[socialFilters].defaultValue, // NEED CHANGE
    }

    this.setState(
      {
        socialFilters: newFilters,
      },
      () => {
        this.props.history.push(
          `/zone/detail?${QueryString.stringify(
            _.deepClean(this.state.socialFilters)
          )}`
        )
        this.props.dispatch(
          getAppZoneSocials(_.deepClean(this.state.socialFilters))
        )
      }
    )
  }

  _closeSocialFilters = () => {
    this._searchSocial()
    this.setState({
      showSocialFilters: false,
    })
  }

  _renderSocials = () => {
    return (
      <>
        <Auxiliary
          loading={this.props.zoneSocialsLoading}
          error={_.get(this.props, "common.error")}
        >
          <Chips
            showFilters={this.state.showSocialFilters}
            // showAdvancedFilters={this.state.showAdvancedFilters}
            filters={this.state.socialFilters}
            search={this._closeSocialFilters}
            removeFilter={this._removeSocialFilter}
            handleFilterChange={this._handleSocialFilterChange}
            type="zone_feed_social"
            toggleFilters={this._toggleSocialFilters}
            key={this.state.pageType}
            // toggleAdvancedFilters={this._toggleAdvancedFilters}
          />
          {this.props?.zoneSocials?.length > 0 ? (
            !this.state?.socialFilters?.filterView ? (
              <>
                <div className="trending-card-container">
                  {this.props.zoneSocials.map((social, index) => {
                    return <SocialFeedCard social={social} />
                  })}
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <div className="d-flex justify-challenge-end">
                    <Pagination
                      style={{ marginTop: "1rem" }}
                      onChange={this._onSocialPageChange}
                      current={parseInt(
                        this.state.socialFilters["currentPage"],
                        10
                      )}
                      defaultCurrent={parseInt(
                        this.state.socialFilters["currentPage"],
                        10
                      )}
                      total={
                        this.state.social_post_count > 10000
                          ? 10000
                          : this.state.social_post_count
                      }
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="trending-card-container">
                  {this.props.zoneSocials.map((social, index) => {
                    return <SocialCard social={social} />
                  })}
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <div className="d-flex justify-challenge-end">
                    <Pagination
                      style={{ marginTop: "1rem" }}
                      onChange={this._onSocialPageChange}
                      current={parseInt(
                        this.state.socialFilters["currentPage"],
                        10
                      )}
                      defaultCurrent={parseInt(
                        this.state.socialFilters["currentPage"],
                        10
                      )}
                      total={
                        this.props.totalZoneSocials > 10000
                          ? 10000
                          : this.props.totalZoneSocials
                      }
                      pageSize={this.state.socialFilters.pageSize}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </>
            )
          ) : (
            <NoDataFound message="No Data To Display" />
          )}
        </Auxiliary>
      </>
    )
  }

  _renderZoneMap = () => {
    return (
      <ZoneMap
        {...this.state.zoneMapFilters}
        zoneDetails={this.state.zoneDetails}
        l0_ZoneMapResponse={this.state.l0_ZoneMapResponse}
        l1_ZoneMapResponse={this.state.l1_ZoneMapResponse}
        l2_ZoneMapResponse={this.state.l2_ZoneMapResponse}
        sibling_zones={this.state.sibling_zones}
      />
    )
  }

  _renderPages = () => {
    switch (this.state.pageType) {
      case "video":
        return this._renderVideos()
      case "image":
        return this._renderImages()
      case "social":
        return this._renderSocials()
      case "zone_map":
        return this._renderZoneMap()
      default:
        break
    }
  }

  _selectTab = async type => {
    if (type === "video") {
      this.setState(
        {
          pageType: "video",
        },
        () => {
          this.props.history.push(
            `/zone/detail?${QueryString.stringify(
              _.deepClean(this.state.videoFilters)
            )}`
          )
          this.props.dispatch(
            getAppZoneVideos(_.deepClean(_.deepClean(this.state.videoFilters)))
          )
        }
      )
    }

    if (type === "image") {
      this.setState(
        {
          pageType: "image",
        },
        () => {
          this.props.history.push(
            `/zone/detail?${QueryString.stringify(
              _.deepClean(this.state.imageFilters)
            )}`
          )
          this.props.dispatch(
            getAppZoneImages(_.deepClean(_.deepClean(this.state.imageFilters)))
          )
        }
      )
    }

    if (type === "social") {
      this.setState(
        {
          pageType: "social",
        },
        () => {
          this.props.history.push(
            `/zone/detail?${QueryString.stringify(
              _.deepClean(this.state.socialFilters)
            )}`
          )
          this.props.dispatch(
            getAppZoneSocials(
              _.deepClean(_.deepClean(this.state.socialFilters))
            )
          )
        }
      )
    }

    if (type === "zone_map") {
      this.setState(
        {
          pageType: "zone_map",
        },
        () => {
          this.props.history.push(
            `/zone/detail?${QueryString.stringify(
              _.deepClean(this.state.zoneMapFilters)
            )}`
          )
          this.props.dispatch(
            getAppZoneSocials(
              _.deepClean(_.deepClean(this.state.zoneMapFilters))
            )
          )
        }
      )
    }
  }

  render() {
    const {
      zoneDetails: zone = {},
      l0_ZoneMapResponse,
      l1_ZoneMapResponse,
      l2_ZoneMapResponse,
      zone_metrics = {},
    } = this.state || {}

    if (!this.state?.zone_id) {
      return (
        <div className="d-flex justify-content-center hero-section text-muted font_italic_bold">
          Please Provide Zone Id.
        </div>
      )
    }

    let zoneBanner = []

    zone?.cover_banner &&
      zone?.cover_banner.length &&
      zone.cover_banner.forEach(banner => {
        if (banner.thumbnail) {
          zoneBanner.push(banner.thumbnail)
        }
      })

    if (zoneBanner.length < 1) {
      zoneBanner = [
        "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/default/default.jpg",
      ]
    }

    return (
      <Container fluid className="p-0">
        <Auxiliary loading={this.state.loading}>
          <Row>
            {this.state.showMeta ? (
              <Col xl="4" sm="6" md="5" lg="5" className="p-0">
                <SimpleBar style={{ height: "76vh", backgroundColor: "white" }}>
                  <div className="h-100">
                    <CardBody className="pt-0 h-100">
                      <Carousel
                        responsive={responsive}
                        style={{ backgroundColor: "#e8e2e2", margin: "auto" }}
                        autoPlaySpeed={3000}
                        arrows={true}
                        infinite={zoneBanner.length > 1 ? true : false}
                        autoPlay={zoneBanner.length > 1 ? true : false}
                        className="mt-2"
                        focusOnSelect={true}
                        key={zone.zone_uuid}
                      >
                        {zoneBanner.map(banner_image => (
                          <img
                            src={banner_image}
                            alt="Banner Image"
                            height="150px"
                            width="100%"
                          />
                        ))}
                      </Carousel>
                      <Row
                        className="align-items-baseline"
                        style={{ marginTop: "-30px" }}
                      >
                        <Col xl="4" md="5" lg="5" sm="5">
                          <div className="d-flex justify-content-center">
                            <div style={{ position: "relative" }}>
                              <img
                                className="rounded-circle avatar-lg img-fluid profile-pic border-bottom border-top border-start border-end"
                                src={
                                  zone?.zone_image ||
                                  zone?.zone_icon ||
                                  "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/default/default.jpg"
                                }
                                alt="Image"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xl="6" md="6" lg="6" sm="6">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="mt-3">
                              <h5 className="font-size-17 mb-1 text-dark d-flex">
                                <Tooltip
                                  title={
                                    zone?.title ||
                                    zone?.sub_title ||
                                    zone?.zone_uuid ||
                                    ""
                                  }
                                >
                                  <div className="zone_meta_title">
                                    {zone?.title ||
                                      zone?.sub_title ||
                                      zone?.zone_uuid ||
                                      ""}
                                  </div>
                                </Tooltip>
                                <CopyToClipboard
                                  text={zone?.zone_uuid}
                                  onCopy={e => {
                                    toastr.success("Copied sucessfully!")
                                  }}
                                >
                                  <Tooltip title="Copy Zone Id">
                                    <i
                                      className="bx bx-copy bx-xs text-muted ms-2"
                                      role="button"
                                    />
                                  </Tooltip>
                                </CopyToClipboard>
                              </h5>
                              {zone?.title && zone?.sub_title ? (
                                <p className="font-size-15 text-muted mb-4 d-flex">
                                  {zone?.sub_title}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                        <Col xl="2" md="1" lg="1" sm="1">
                          <button
                            type="button"
                            onClick={() => this.setState({ showMeta: false })}
                            className="btn btn-sm px-3 font-size-16 btn-boxshadow"
                            id="vertical-menu-btn"
                          >
                            <i
                              className="bx bx-log-out fa-lg"
                              title="Click to hide zone meta"
                            ></i>
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Divider className="align-items-start">
                          <h5 className="font-size-12 mb-0 text-muted">
                            About
                          </h5>
                        </Divider>
                        <div>
                          <Table className="mb-0 table-wrap table-striped table-sm">
                            <tbody>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "40%" }}>
                                  Status:
                                </th>
                                <td>{zone?.status || "N/A"}</td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  style={{ width: "40%" }}
                                  className="border-start"
                                >
                                  Level:
                                </th>
                                <td>{zone?.level || "N/A"}</td>
                              </tr>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "40%" }}>
                                  Language:
                                </th>
                                <td>
                                  {LANGUAGE_MAPPING[zone?.lang_code] ||
                                    zone?.lang_code ||
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  style={{ width: "40%" }}
                                  className="border-start"
                                >
                                  Type:
                                </th>
                                <td>{zone?.zone_type || "N/A"}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                      <Row>
                        <Divider className="align-items-start">
                          <h5 className="font-size-12 mb-0 text-muted">
                            Videos Played
                          </h5>
                        </Divider>
                        <div>
                          <Table className="mb-0 table-wrap table-striped table-sm">
                            <tbody>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  Total:
                                </th>
                                <td>
                                  {zone_metrics?.video_play?.total_count || 0}
                                </td>
                                <th
                                  scope="row"
                                  style={{ width: "30%" }}
                                  className="border-start"
                                >
                                  UGC:
                                </th>
                                <td>
                                  {zone_metrics?.video_play?.ugc_count || 0}
                                </td>
                              </tr>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  AI:
                                </th>
                                <td>
                                  {zone_metrics?.video_play?.ai_count || 0}
                                </td>
                                <th
                                  scope="row"
                                  style={{ width: "30%" }}
                                  className="border-start"
                                >
                                  Agent:
                                </th>
                                <td>
                                  {zone_metrics?.video_play?.agent_count || 0}
                                </td>
                              </tr>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  Operations:
                                </th>
                                <td>
                                  {zone_metrics?.video_play?.operations_count ||
                                    0}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>

                      <Row>
                        <Divider className="align-items-start">
                          <h5 className="font-size-12 mb-0 text-muted">
                            Videos
                          </h5>
                        </Divider>
                        <div>
                          <Table className="mb-0 table-wrap table-striped table-sm">
                            <tbody>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  Total:
                                </th>
                                <td>{zone_metrics?.video?.total_count || 0}</td>
                                <th
                                  scope="row"
                                  style={{ width: "30%" }}
                                  className="border-start"
                                >
                                  UGC:
                                </th>
                                <td>{zone_metrics?.video?.ugc_count || 0}</td>
                              </tr>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  AI:
                                </th>
                                <td>{zone_metrics?.video?.ai_count || 0}</td>
                                <th
                                  scope="row"
                                  style={{ width: "30%" }}
                                  className="border-start"
                                >
                                  Agent:
                                </th>
                                <td>{zone_metrics?.video?.agent_count || 0}</td>
                              </tr>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  Operations:
                                </th>
                                <td>
                                  {zone_metrics?.video?.operations_count || 0}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>

                      <Row>
                        <Divider className="align-items-start">
                          <h5 className="font-size-12 mb-0 text-muted">
                            Images
                          </h5>
                        </Divider>
                        <div>
                          <Table className="mb-0 table-wrap table-striped table-sm">
                            <tbody>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  Total:
                                </th>
                                <td>{zone_metrics?.image?.total_count || 0}</td>
                                <th
                                  scope="row"
                                  style={{ width: "30%" }}
                                  className="border-start"
                                >
                                  UGC:
                                </th>
                                <td>{zone_metrics?.image?.ugc_count || 0}</td>
                              </tr>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  AI:
                                </th>
                                <td>{zone_metrics?.image?.ai_count || 0}</td>
                                <th
                                  scope="row"
                                  style={{ width: "30%" }}
                                  className="border-start"
                                >
                                  Agent:
                                </th>
                                <td>{zone_metrics?.image?.agent_count || 0}</td>
                              </tr>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  Operations:
                                </th>
                                <td>
                                  {zone_metrics?.image?.operations_count || 0}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>

                      <Row>
                        <Divider className="align-items-start">
                          <h5 className="font-size-12 mb-0 text-muted">
                            Socials
                          </h5>
                        </Divider>
                        <div>
                          <Table className="mb-0 table-wrap table-striped table-sm">
                            <tbody>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  Total:
                                </th>
                                <td>
                                  {zone_metrics?.social?.total_count || 0}
                                </td>
                                <th
                                  scope="row"
                                  style={{ width: "30%" }}
                                  className="border-start"
                                >
                                  UGC:
                                </th>
                                <td>{zone_metrics?.social?.ugc_count || 0}</td>
                              </tr>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  AI:
                                </th>
                                <td>{zone_metrics?.social?.ai_count || 0}</td>
                                <th
                                  scope="row"
                                  style={{ width: "30%" }}
                                  className="border-start"
                                >
                                  Agent:
                                </th>
                                <td>
                                  {zone_metrics?.social?.agent_count || 0}
                                </td>
                              </tr>
                              <tr className="text-start">
                                <th scope="row" style={{ width: "30%" }}>
                                  Operations:
                                </th>
                                <td>
                                  {zone_metrics?.social?.operations_count || 0}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                      {/* {l0_ZoneMapResponse && l0_ZoneMapResponse.length > 0 ? (
                        <Row>
                          <Divider className="align-items-start">
                            <h5 className="font-size-12 mb-0 text-muted">
                              LO Zones
                            </h5>
                          </Divider>
                          <Col>
                            <PerfectScrollbar>
                              <div style={{ maxHeight: "20vh" }}>
                                {l0_ZoneMapResponse.map((current_zone, idx) => {
                                  return (
                                    <div
                                      className={`d-flex justify-content-between align-items-center ${
                                        idx % 2 === 0 ? "tag-item" : "target"
                                      }`}
                                    >
                                      <div className="font-weight-semibold">
                                        {current_zone?.title ||
                                          current_zone?.sub_title ||
                                          current_zone?.zone_uuid}
                                      </div>
                                      <div>
                                        <CopyToClipboard
                                          text={current_zone?.zone_uuid}
                                          onCopy={e => {
                                            toastr.success(
                                              "Copied sucessfully!"
                                            )
                                          }}
                                        >
                                          <Tooltip title="Copy Zone Id">
                                            <i
                                              className="bx bx-copy bx-xs text-muted ms-2"
                                              role="button"
                                            />
                                          </Tooltip>
                                        </CopyToClipboard>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </PerfectScrollbar>
                          </Col>
                        </Row>
                      ) : null}
                      {l1_ZoneMapResponse && l1_ZoneMapResponse.length > 0 ? (
                        <Row>
                          <Divider className="align-items-start">
                            <h5 className="font-size-12 mb-0 text-muted">
                              L1 Zones
                            </h5>
                          </Divider>
                          <Col>
                            <PerfectScrollbar>
                              <div style={{ maxHeight: "20vh" }}>
                                {l1_ZoneMapResponse.map((current_zone, idx) => {
                                  return (
                                    <div
                                      className={`d-flex justify-content-between align-items-center ${
                                        idx % 2 === 0 ? "tag-item" : "target"
                                      }`}
                                    >
                                      <div className="font-weight-semibold">
                                        {current_zone?.title ||
                                          current_zone?.sub_title ||
                                          current_zone?.zone_uuid}
                                      </div>
                                      <div>
                                        <CopyToClipboard
                                          text={current_zone?.zone_uuid}
                                          onCopy={e => {
                                            toastr.success(
                                              "Copied sucessfully!"
                                            )
                                          }}
                                        >
                                          <Tooltip title="Copy Zone Id">
                                            <i
                                              className="bx bx-copy bx-xs text-muted ms-2"
                                              role="button"
                                            />
                                          </Tooltip>
                                        </CopyToClipboard>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </PerfectScrollbar>
                          </Col>
                        </Row>
                      ) : null}
                      {l2_ZoneMapResponse && l2_ZoneMapResponse.length > 0 ? (
                        <Row>
                          <Divider className="align-items-start">
                            <h5 className="font-size-12 mb-0 text-muted">
                              L2 Zones
                            </h5>
                          </Divider>
                          <Col>
                            <PerfectScrollbar>
                              <div style={{ maxHeight: "20vh" }}>
                                {l2_ZoneMapResponse.map((current_zone, idx) => {
                                  return (
                                    <div
                                      className={`d-flex justify-content-between align-items-center ${
                                        idx % 2 === 0 ? "tag-item" : "target"
                                      }`}
                                    >
                                      <div className="font-weight-semibold">
                                        {current_zone?.title ||
                                          current_zone?.sub_title ||
                                          current_zone?.zone_uuid}
                                      </div>
                                      <div>
                                        <CopyToClipboard
                                          text={current_zone?.zone_uuid}
                                          onCopy={e => {
                                            toastr.success(
                                              "Copied sucessfully!"
                                            )
                                          }}
                                        >
                                          <Tooltip title="Copy Zone Id">
                                            <i
                                              className="bx bx-copy bx-xs text-muted ms-2"
                                              role="button"
                                            />
                                          </Tooltip>
                                        </CopyToClipboard>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </PerfectScrollbar>
                          </Col>
                        </Row>
                      ) : null} */}
                    </CardBody>
                  </div>
                </SimpleBar>
              </Col>
            ) : null}
            <Col xl={this.state.showMeta ? "8" : "12"} sm="12" md="12" lg="12">
              <SimpleBar style={{ height: "76vh" }}>
                <div className="d-flex mb-3 align-items-centerh-100 flex-wrap sticky-top bg-light">
                  {!this.state.showMeta && (
                    <div
                      role="button"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setState({ showMeta: true })}
                      className="text-muted mx-3 font-size-16"
                    >
                      <i
                        className="bx bx-log-in fa-lg"
                        title="Click to view zone meta"
                        style={{ marginRight: "5px" }}
                      />
                    </div>
                  )}
                  <div
                    role="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (this.state.pageType !== "video")
                        this._selectTab("video")
                    }}
                    className={
                      this.state.pageType === "video"
                        ? "text-black mx-3 font-size-18"
                        : "text-muted mx-3 font-size-16"
                    }
                  >
                    <i
                      className="fas fa-video"
                      style={{ marginRight: "5px" }}
                    />
                    Videos
                  </div>

                  <div
                    role="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (this.state.pageType !== "image")
                        this._selectTab("image")
                    }}
                    className={
                      this.state.pageType === "image"
                        ? "text-black d-flex align-items-center mx-3 font-size-18"
                        : "text-muted d-flex align-items-center mx-3 font-size-16"
                    }
                  >
                    <i
                      className="fas fa-image"
                      style={{ marginRight: "5px" }}
                    />
                    Images
                  </div>

                  <div
                    role="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (this.state.pageType !== "social")
                        this._selectTab("social")
                    }}
                    className={
                      this.state.pageType === "social"
                        ? "text-black d-flex align-items-center mx-3 font-size-18"
                        : "text-muted d-flex align-items-center mx-3 font-size-17"
                    }
                  >
                    <i
                      className="fas fa-address-card"
                      style={{ marginRight: "5px" }}
                    />
                    Social
                  </div>

                  <div
                    role="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (this.state.pageType !== "zone_map")
                        this._selectTab("zone_map")
                    }}
                    className={
                      this.state.pageType === "zone_map"
                        ? "text-black d-flex align-items-center mx-3 font-size-18"
                        : "text-muted d-flex align-items-center mx-3 font-size-17"
                    }
                  >
                    <i
                      className="fas fa-sitemap"
                      style={{ marginRight: "5px" }}
                    />
                    Zone Map
                  </div>
                </div>
                {this._renderPages()}
              </SimpleBar>
            </Col>
          </Row>
        </Auxiliary>
      </Container>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    zoneList: _.get(store, "zone.zoneList"),
    allZoneList: _.get(store, "zone.allZoneList"),
    totalPageEntities: _.get(store, "zeroSearch.totalPageEntities"),
    languageList: _.get(store, "uiConstants.languageList"),
    pageEntities: _.get(store, "zeroSearch.pageEntities"),
    locationList: _.get(store, "locationReducer.locationList"),
    zone: _.get(store, "zone.zone"),
    totalZoneVideos: _.get(store, "zone.totalZoneVideos"),
    zoneVideos: _.get(store, "zone.zoneVideos"),
    zoneVediosLoading: _.get(store, "zone.zoneVediosLoading"),
    totalZoneImages: _.get(store, "zone.totalZoneImages"),
    zoneImages: _.get(store, "zone.zoneImages"),
    zoneImagesLoading: _.get(store, "zone.zoneImagesLoading"),
    totalZoneSocials: _.get(store, "zone.totalZoneSocials"),
    zoneSocials: _.get(store, "zone.zoneSocials"),
    zoneSocialsLoading: _.get(store, "zone.zoneSocialsLoading"),
  }
}

export default connect(mapStateToProps)(DetailZone)
