import Axios from "axios"
import QueryString from "query-string"

import toastr from "toastr"

export const bulkUploadCampaign = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/campaign/bulk`, formData)
    return data
  } catch (error) {
    toastr.error("Error in bulk updating campaign ")
    throw error
  }
}

export const bulkUploadBrandCampaign = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/campaign/brand-logo/bulk`, formData)
    return data
  } catch (error) {
    toastr.error("Error in bulk updating campaign ")
    throw error
  }
}
