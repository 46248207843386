import Axios from "axios"
import toastr from "toastr"

export const requestCommentListByFilters = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    const limit = payload["pageSize"] || 10
    _.unset(filters, "currentPage")
    let data = await Axios.post(
      `/api/comment/getCommentByFilters?offset=${offset}&limit=${limit}`,
      { filters: payload }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestCommentsGroupByUser = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/comment/getCommentsGroupByUser`, {
      filters: payload,
    })
    return data
  } catch (error) {
    throw error
  }
}

export const requestBulkDeActivateComments = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/comment/bulkDeActivateComments`, {
      payload,
    })
    toastr.success("Action Successfull!!", "")
    return data
  } catch (error) {
    throw error
  }
}
