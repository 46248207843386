import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestUpsertCta,
  requestGetCta,
  requestDeleteCta,
  requestUpdateCtaEntity,
} from "appRedux/sagas/requests/cta"
import {
  getCta,
  getCtaSuccess,
  upsertCta,
  upsertCtaSuccess,
  deleteCta,
  deleteCtaSuccess,
  updateCtaEntity,
  updateCtaEntitySuccess,
} from "appRedux/slices/cta"
import { fetchError } from "appRedux/slices/common"

function* handleUpsertCta(params) {
  try {
    const data = yield call(requestUpsertCta, params)
    yield put(upsertCtaSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetCta(params) {
  try {
    const data = yield call(requestGetCta, params)
    yield put(getCtaSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleDeleteCta(params) {
  try {
    const data = yield call(requestDeleteCta, params)
    yield put(deleteCtaSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateCtaEntity(params) {
  try {
    const data = yield call(requestUpdateCtaEntity, params)
    yield put(updateCtaEntitySuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(upsertCta.type, handleUpsertCta)
  yield takeLatest(getCta.type, handleGetCta)
  yield takeLatest(deleteCta.type, handleDeleteCta)
  yield takeLatest(updateCtaEntity.type, handleUpdateCtaEntity)
}
