import React from "react"
import { connect } from "react-redux"
// import { Card, CardBody, Badge, Row, CardImg } from "reactstrap"
import { Card, CardBody, CardTitle, Badge } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import ReactTimeAgo from "react-time-ago"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"

class AdsProfileCard extends React.Component {
  render() {
    const { data } = this.props
    return (
      <>
        <Card
          className={`text-center shadow-sm align-self-center`}
          // onClick={() => props.goToUserPage(props.application)}
          style={{ cursor: "pointer" }}
          onClick={e => {
            if (
              !this.props.currentUser?.permissions?.includes(
                "EDIT_ADS_PROFILE"
              ) &&
              this.props?.location?.pathname.split("/")[1] === "ads" &&
              this.props?.location?.pathname.split("/")[2] === "profile"
            ) {
              toastr.error(NO_PERMISSION_MESSAGE)
              return
            }
            this.props._onEdit(data || {})
          }}
        >
          <CardBody>
            <div className="avatar-md mb-4 m-auto">
              <span
                className="avatar-title rounded-circle bg-gradient font-size-24 font-weight-semibold"
                style={{ backgroundColor: "#00a3ff" }}
              >
                {data?.name && data.name.charAt(0).toUpperCase()}
              </span>
            </div>
            <CardTitle className="font-size-18">{data.name}</CardTitle>
            {data.status && (
              <Tooltip title="Status" style={{ minHeight: "20px" }}>
                <Badge
                  className={`${
                    data.status === "ACTIVE"
                      ? "badge-soft-success"
                      : data.status === "DRAFT"
                      ? "badge-soft-warning"
                      : "badge-soft-danger"
                  } ms-1 my-2 text-black me-1 font-size-16 text-truncate `}
                >
                  {data.status}
                </Badge>
              </Tooltip>
            )}

            <div style={{ minHeight: "30px" }} className="mt-2">
              {data.start_time && (
                <Tooltip title="Start Time">
                  <Badge className="badge-soft-success ms-1 font-size-13 text-black">
                    <ReactTimeAgo date={data.start_time} locale="en-US" />
                  </Badge>
                </Tooltip>
              )}
              {data.end_time && (
                <Tooltip title="End Time">
                  <Badge className="badge-soft-danger ms-1 font-size-13 text-black">
                    {data.end_time != -1 ? (
                      <ReactTimeAgo date={data.end_time} locale="en-US" />
                    ) : (
                      "No Expiry"
                    )}
                  </Badge>
                </Tooltip>
              )}
            </div>
          </CardBody>
          <div
            className="d-flex font-size-20 p-2 border-top text-center"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <div className="flex-fill">
              <i
                className="bx bx-edit-alt"
                title="Edit"
                role="button"
                onClick={e => {
                  if (
                    !this.props.currentUser?.permissions?.includes(
                      "EDIT_ADS_PROFILE"
                    ) &&
                    this.props?.location?.pathname.split("/")[1] === "ads" &&
                    this.props?.location?.pathname.split("/")[2] === "profile"
                  ) {
                    toastr.error(NO_PERMISSION_MESSAGE)
                    return
                  }
                  this.props._onEdit(data || {})
                }}
              />
            </div>
            <div className="flex-fill">
              <CopyToClipboard
                text={data.id}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy ID">
                  <i className="bx bx-copy-alt" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            {/* <div className="flex-fill">
                            <i
                                title='Delete'
                                className="bx bx-trash-alt"
                                role="button"
                                onClick={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    confirmAlert({
                                        title: 'Delete Confirmation',
                                        message: `Are you sure you want to delete ${data.name} Application?`,
                                        buttons: [
                                            {
                                                label: 'Yes',
                                                // onClick: () => _onDelete(props?.application || {})
                                                onClick: () => { }
                                            },
                                            {
                                                label: 'No',
                                                onClick: () => { }
                                            }
                                        ]
                                    });
                                }}
                            />
                        </div> */}
          </div>
        </Card>
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    refetch: _.get(store, "meme.refetchData"),
  }
}

export default connect(mapStateToProps)(AdsProfileCard)
