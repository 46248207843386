import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import _ from "lodash"
import { connect } from "react-redux"
import { map } from "lodash"
import { Badge, Col, Container, Row, Table } from "reactstrap"
import toastr from "toastr"
import { Tooltip, Tag } from "antd"
import "./boostReport.scss"
import moment from "moment"
import { formatNumber } from "../../util/common"

class BoostReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      boostList: this.props.boostList,
    }
  }

  render() {
    return (
      <>
        <React.Fragment>
          <div>
            <Container fluid>
              {this.props.total &&
                this.state.boostList &&
                this.state.boostList.length > 0 && (
                  <div className="text-center mb-2">
                    <Tag color="geekblue">
                      Showing <b>{this.state.boostList.length}</b> of{" "}
                      <b>{formatNumber(this.props.total)}</b> Reports
                    </Tag>
                  </div>
                )}
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      <Table className="project-list-table table-nowrap align-middle table-borderless">
                        <tbody>
                          {map(this.state.boostList, (report, index) => (
                            <tr key={index}>
                              <td className="width130">
                                <img
                                  className="avatar-lg"
                                  src={
                                    report.type == "USER"
                                      ? report.profile_pic
                                      : report.animated_icon_url
                                  }
                                />
                              </td>
                              <td>
                                <tr>
                                  <td>
                                    <Tooltip
                                      title={`Name: ${
                                        report.type == "USER"
                                          ? report.name
                                          : report.content_title
                                      }`}
                                    >
                                      <div
                                        className="fontSize18 my-1 mb-1 ml-6 fw-bold"
                                        style={{
                                          maxWidth: "55rem",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {report.type == "USER"
                                          ? report.name
                                          : report.content_title}
                                      </div>
                                    </Tooltip>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Tooltip title="Status">
                                      <Badge
                                        className={`${
                                          report.status === "FAILED"
                                            ? "badge-soft-danger"
                                            : "badge-soft-success"
                                        } font-size-12 ms-1 p-2`}
                                      >
                                        {report.status}
                                      </Badge>
                                    </Tooltip>
                                    <Tooltip title="Boosted By">
                                      <Badge className="font-size-12 badge-soft-primary ms-1 p-2">
                                        {report.userName}
                                      </Badge>
                                    </Tooltip>
                                    <Tooltip title="Count">
                                      <Badge className="font-size-12 badge-soft-info ms-1 p-2">
                                        {report.count}
                                      </Badge>
                                    </Tooltip>
                                    <Tooltip title="Boosting Period">
                                      <Badge className="font-size-12 badge-soft-warning ms-1 p-2">
                                        {report.boostingPeriod}
                                      </Badge>
                                    </Tooltip>
                                    <Tooltip title="Boosted On">
                                      <Badge className="font-size-12 badge-soft-info ms-1 p-2">
                                        {moment(report.boostedOn).fromNow()}
                                      </Badge>
                                    </Tooltip>
                                    <Tooltip title="CSV Name">
                                      <Badge className="font-size-12 badge-soft-secondary ms-1 p-2">
                                        {report.csvName}
                                      </Badge>
                                    </Tooltip>
                                    <Badge className="font-size-12 badge-soft-dark ms-1 p-2 slim">
                                      {report.errorDetails}
                                    </Badge>
                                  </td>
                                </tr>
                                {/* </div> */}
                              </td>
                              <td>
                                <CopyToClipboard
                                  text={report.content_uuid}
                                  onCopy={e => {
                                    toastr.success("Copied sucessfully!")
                                  }}
                                >
                                  <Tooltip title="Copy Content ID">
                                    <i
                                      className="fontSize18 bx bx-copy"
                                      role="button"
                                    />
                                  </Tooltip>
                                </CopyToClipboard>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    )
  }
}

function mapStateToProps(store) {
  return {
    common: _.get(store, "common"),
    report: _.get(store, "activityLog.report"),
  }
}

export default connect(mapStateToProps)(BoostReport)
