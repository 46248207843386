import React from "react"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import ImageCard from "./imageCard"
import QuestionCard from "./questionCard"
import Questionnaire from "./questionnaire"
import "react-perfect-scrollbar/dist/css/styles.css"

import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"

const childTypes = ["acceptable_child_nudity"]
const adultTypes = [
  "pretty_girl",
  "glamorous",
  "classy_hot_sexy",
  "masala",
  "vulgar_masala",
  "partial_adult_nudity",
  "partial_adult_nudity#c",
  "partial_adult_nudity#d",
]

const GLAMOUR_OPTIONS = [
  {
    label: "No Glamour",
    level: 1,
    key: "no_glamour",
    taxonomyJsonKey: "taxonomy_glamour",
    dependentValue: true,
  },
  {
    label: "Adult",
    parent: "no_glamour",
    level: 2,
    key: "adult",
    taxonomyJsonKey: "taxonomy_glamour",
    dependentValue: true,
  },
  {
    label: "Child",
    parent: "no_glamour",
    taxonomyJsonKey: "taxonomy_glamour",
    level: 2,
    key: "child",
    dependentValue: true,
  },
]

const GRADE_OPTIONS = [
  {
    label: "Grade A",
    value: "a",
    key: "glamour_grade",
    taxonomyJsonKey: "taxonomy_glamour",
    help: {
      thumbnail:
        "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/Glamour+A+Image.webp",
    },
  },
  {
    label: "Grade B",
    value: "b",
    key: "glamour_grade",
    taxonomyJsonKey: "taxonomy_glamour",
    help: {
      thumbnail:
        "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/Glamour+B+Image.jfif",
    },
  },
  {
    label: "Grade C",
    value: "c",
    taxonomyJsonKey: "taxonomy_glamour",
    level: 2,
    key: "glamour_grade",
    help: {
      thumbnail:
        "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/Glamour++C+Image.jpg",
    },
  },
]

const REGION_OPTIONS = [
  {
    label: "Indian",
    value: "indian",
    key: "glamour_region",
    taxonomyJsonKey: "taxonomy_glamour",
    help: {
      thumbnail:
        "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/Indian+girl.jpg",
    },
  },
  {
    label: "International Fair",
    value: "international_fair",
    key: "glamour_region",
    taxonomyJsonKey: "taxonomy_glamour",
    help: {
      thumbnail:
        "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/International+Fair.jpg",
    },
  },
  {
    label: "International Black",
    value: "international_black",
    taxonomyJsonKey: "taxonomy_glamour",
    level: 2,
    key: "glamour_region",
    help: {
      thumbnail:
        "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/International+Black.jfif",
    },
  },
  {
    label: "South Asian / Chinese",
    value: "south_asian_chinese",
    taxonomyJsonKey: "taxonomy_glamour",
    level: 2,
    key: "glamour_region",
    help: {
      thumbnail:
        "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/chinese-girl.jpg",
    },
  },
]

class Glamour extends Questionnaire {
  state = {
    currentTab: "glamour",
    labelToDisplay: "Glamour",
  }

  componentDidMount() {
    let glamour = this.props.selectedDetails?.taxonomy_glamour?.glamour_type
    let glamourGrade =
      this.props.selectedDetails?.taxonomy_glamour?.glamour_grade
    let glamourRegion =
      this.props.selectedDetails?.taxonomy_glamour?.glamour_region
    let glamourType = this.props.selectedDetails?.taxonomy_glamour?.glamour_type
    let type = ""
    if (adultTypes.includes(glamour)) type = "adult"
    if (childTypes.includes(glamour)) type = "child"

    this.setState(
      {
        type,
      },
      () => {
        if (this.state.type === "adult") {
          this.setState({
            glamourType,
            glamourGrade,
            glamourRegion,
          })
        }
      }
    )
  }

  _handleSelection = (
    value,
    question,
    idx,
    _,
    sub_type,
    glamour_grade,
    glamour_region
  ) => {
    const currentData = cloneDeep(this.props.currentData)
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    const indexTab = taxonomyRoutes.findIndex(item => item.key == "glamour")

    if (value) {
      selectedDetails["taxonomy_glamour"]["glamour_type"] = value
      delete selectedDetails["taxonomy_glamour"]["glamour_grade"]
      delete selectedDetails["taxonomy_glamour"]["glamour_region"]
      if (this.state.type === "adult") {
        this.setState({
          glamourType: value,
          glamourGrade: null,
          glamourRegion: null,
        })
      }
    }

    if (glamour_grade) {
      selectedDetails["taxonomy_glamour"]["glamour_grade"] = glamour_grade
      delete selectedDetails["taxonomy_glamour"]["glamour_region"]
      this.setState({
        glamourGrade: glamour_grade,
        glamourRegion: null,
      })
    }

    if (glamour_region) {
      selectedDetails["taxonomy_glamour"]["glamour_region"] = glamour_region
      this.setState({
        glamourRegion: glamour_region,
      })
    }

    if (sub_type) {
      selectedDetails["taxonomy_glamour"]["sub_type"] = sub_type
    }

    if (
      this.props.selectedSection === "glamour" &&
      this.props.selectedContent.auto_moderation_label === "nsfw" &&
      !this.state.ignore &&
      !this.state.tag &&
      !this.state.ignoreRatingLessThan4 &&
      !this.state.retag &&
      ["classy_hot_sexy", "pretty_girl", "glamorous"].includes(
        this.props.selectedDetails?.taxonomy_glamour?.glamour_type
      ) &&
      this.props.selectedDetails?.taxonomy_glamour?.glamour_grade &&
      this.props.selectedDetails?.taxonomy_glamour?.glamour_region
    ) {
      this.props.setPopupType("glamourTagToNsfwLessThan4", true, currentData)
      this.props.setSelectedDetails(selectedDetails)
      if (this.props.isStageOne) {
        return
      }
    }

    let stepCompleted = false

    if (
      (selectedDetails["taxonomy_glamour"]["glamour_grade"] &&
        selectedDetails["taxonomy_glamour"]["glamour_region"]) ||
      this.state.type === "child"
    ) {
      stepCompleted = true
    }

    if (stepCompleted) {
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    } else {
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      // this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = false
    }
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )
    this.setState({
      currentData,
      ignore: false,
      tag: false,
      ignoreRatingLessThan4: false,
      retag: false,
    })

    this.props.setSelectedDetails(selectedDetails)
  }

  _renderCard = (question, key, idx) => {
    return (
      <>
        <div>
          <div>{question.label}</div>
          <div className="d-flex flex-wrap">
            {question.sublevel.map(item => {
              const data = _.cloneDeep(item)
              let glamourType
              if (adultTypes.includes(item.value)) glamourType = "adult"
              if (childTypes.includes(item.value)) glamourType = "child"
              if (
                this.props.selectedDetails?.taxonomy_glamour?.glamour_type ===
                data.value
              ) {
                data.selected = true
              } else {
                data.selected = false
              }
              if (question.isMultiple) {
                if (question.value?.includes(data.value)) {
                  data.partialSelected = true
                }
              } else {
                if (question.value === data.value) {
                  data.partialSelected = true
                }
              }
              return glamourType === this.state.type ? (
                <ImageCard
                  data={data}
                  _handleSelection={e =>
                    this._handleSelection(
                      data.value,
                      question,
                      key,
                      question.key,
                      data.sub_type
                    )
                  }
                  renderCardContent={this.props.renderCardContent}
                />
              ) : (
                ""
              )
            })}
          </div>
        </div>
      </>
    )
  }

  _renderStepper = () => {
    let text = "Select All"
    this.props.currentData.forEach(item => {
      if (item.level > 0) {
        text = "Questionnaire"
      }
    })

    return (
      <div style={{ margin: "1rem", fontWeight: "bold" }}>
        {this.state.labelToDisplay}&nbsp;({text})
      </div>
    )
  }

  _renderQuestions = () => {
    return (
      <>
        <div style={{ marginLeft: "25px" }}>
          Please select the appropriate glamour level
        </div>
        <br />
        <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
          {this.props.currentData.map((question, idx) => {
            if (question.type === "card") {
              return this._renderCard(question, idx)
            }
            return (
              <QuestionCard
                question={question}
                _handleSelection={e =>
                  this._handleSelection(e.target.value, question, idx)
                }
                renderCardContent={this.props.renderCardContent}
              />
            )
          })}
        </div>
      </>
    )
  }

  _renderTitle = () => {
    return <div className="font-weight-semibold pt-3">Glamour</div>
  }

  _handleTypeSelection = type => {
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    const indexTab = taxonomyRoutes.findIndex(item => item.key == "glamour")
    let selectedDetails = { ...this.props.selectedDetails }
    if (type == "no_glamour") {
      selectedDetails["taxonomy_glamour"]["glamour_type"] = ""
      delete selectedDetails["taxonomy_glamour"]["glamour_grade"]
      delete selectedDetails["taxonomy_glamour"]["glamour_region"]
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    } else {
      delete selectedDetails["taxonomy_glamour"]["glamour_grade"]
      delete selectedDetails["taxonomy_glamour"]["glamour_region"]
      taxonomyRoutes[indexTab].completed = false
      this.setState({
        glamourType: null,
        glamourGrade: null,
        glamourRegion: null,
      })
    }
    this.props.setSelectedDetails(selectedDetails)
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.setState({
      type,
    })
    this._renderOptions()
  }

  _renderOptions = () => {
    return (
      <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
        {GLAMOUR_OPTIONS.map(data => {
          let audioData = data
          let glamourType = this.state.type
          if (glamourType == data["key"]) {
            audioData.selected = true
          } else {
            audioData.selected = false
          }
          return (
            <ImageCard
              data={data}
              _handleSelection={data => this._handleTypeSelection(data.key)}
              renderCardContent={this.props.renderCardContent}
            />
          )
        })}
      </div>
    )
  }

  _renderCLevelGlamour = () => {
    let videSource =
      "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/Glamour+-+C.mp4"
    return (
      <div className="card" style={{ width: "20rem" }}>
        {/* <img src={imgSource} alt="Card image cap" className="card-img-top" /> */}
        <video
          src={videSource}
          controls
          autoPlay
          controlsList={"nodownload"}
          loop
          style={{ maxWidth: "100%", maxHeight: "250px" }}
        />
        <div className="card-body">
          <h5 className="card-title">Grade C</h5>
        </div>
      </div>
    )
  }

  _renderALevelGlamour = () => {
    let videSource =
      "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/Glamour+-+A.mp4"
    return (
      <div className="card" style={{ width: "20rem" }}>
        {/* <img src={imgSource} alt="Card image cap" className="card-img-top" /> */}
        <video
          src={videSource}
          controls
          autoPlay
          controlsList={"nodownload"}
          loop
          style={{ maxWidth: "100%", maxHeight: "250px" }}
        />
        <div className="card-body">
          <h5 className="card-title">Grade A</h5>
        </div>
      </div>
    )
  }

  _renderBLevelGlamour = () => {
    let videSource =
      "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/Glamour+-+B.mp4"
    return (
      <div className="card" style={{ width: "20rem" }}>
        {/* <img src={imgSource} alt="Card image cap" className="card-img-top" /> */}
        <video
          src={videSource}
          controls
          autoPlay
          controlsList={"nodownload"}
          loop
          style={{ maxWidth: "100%", maxHeight: "250px" }}
        />
        <div className="card-body">
          <h5 className="card-title">Grade B</h5>
        </div>
      </div>
    )
  }

  _renderGlamourGrade = () => {
    return (
      <>
        <div style={{ marginLeft: "25px" }}>
          Please select the appropriate glamour grade
        </div>
        <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
          {GRADE_OPTIONS.map(data => {
            let gradeData = data
            let glamourGrade = this.state.glamourGrade
            if (glamourGrade == data["value"]) {
              gradeData.selected = true
            } else {
              gradeData.selected = false
            }
            return (
              <ImageCard
                data={data}
                _handleSelection={data =>
                  this._handleSelection(
                    null,
                    null,
                    null,
                    null,
                    null,
                    data.value
                  )
                }
                renderCardContent={() => this._renderGlamourCard(data)}
              />
            )
          })}
        </div>
      </>
    )
  }

  _renderIndianGlamour = () => {
    let imgSource =
      "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/Indian+girl.jpg"
    return (
      <div className="card" style={{ width: "20rem" }}>
        <img src={imgSource} alt="Card image cap" className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title">Videos with Indian Looking Girls</h5>
        </div>
      </div>
    )
  }

  _renderIntFairGlamour = () => {
    let imgSource =
      "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/International+Fair.jpg"
    return (
      <div className="card" style={{ width: "20rem" }}>
        <img src={imgSource} alt="Card image cap" className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title">Videos with International Girls</h5>
        </div>
      </div>
    )
  }

  _renderIntBlackGlamour = () => {
    let imgSource =
      "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/International+Black.jfif"
    return (
      <div className="card" style={{ width: "20rem" }}>
        <img src={imgSource} alt="Card image cap" className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title">
            American/African/Or any other intenation black
          </h5>
        </div>
      </div>
    )
  }

  _renderSouthAsianGlamour = () => {
    let imgSource =
      "https://qa-josh-content.s3.ap-south-1.amazonaws.com/josh-cms/taxonomy/glamour/chinese-girl.jpg"
    return (
      <div className="card" style={{ width: "20rem" }}>
        <img src={imgSource} alt="Card image cap" className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title">
            Women from China/Thailand/Korea/other south asian countries
          </h5>
        </div>
      </div>
    )
  }

  _renderRegionCard = data => {
    if (data.value === "indian") return this._renderIndianGlamour()
    else if (data.value === "international_fair")
      return this._renderIntFairGlamour()
    else if (data.value === "international_black")
      return this._renderIntBlackGlamour()
    else return this._renderSouthAsianGlamour()
  }

  _renderGlamourCard = data => {
    if (data.value === "a") return this._renderALevelGlamour()
    else if (data.value === "b") return this._renderBLevelGlamour()
    else return this._renderCLevelGlamour()
  }

  _renderGlamourRegion = () => {
    return (
      <>
        <div style={{ marginLeft: "25px" }}>
          Please select the appropriate glamour region
        </div>
        <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
          {REGION_OPTIONS.map(data => {
            let gradeData = data
            let glamourRegion = this.state.glamourRegion
            if (glamourRegion == data["value"]) {
              gradeData.selected = true
            } else {
              gradeData.selected = false
            }
            return (
              <ImageCard
                data={data}
                _handleSelection={data =>
                  this._handleSelection(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    data.value
                  )
                }
                renderCardContent={() => this._renderRegionCard(data)}
              />
            )
          })}
        </div>
      </>
    )
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "scroll", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderTitle()}
        {this._renderOptions()}
        {(this.state.type == "adult" || this.state.type == "child") &&
          this._renderQuestions()}
        {this.state.glamourType && this._renderGlamourGrade()}
        {this.state.glamourGrade && this._renderGlamourRegion()}
      </div>
    )
  }
}
function mapStateToProps(store) {
  return {
    currentUser: _.get(store, "currentUser.currentUser.data"),
    currentData: _.get(store, "taxonomy.glamour"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(Glamour)
