import React from "react"
import { connect } from "react-redux"
import ReactSelect from "react-select"
import { Modal } from "react-bootstrap"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import BootstrapTable from "react-bootstrap-table-next"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Row, Col, Card, CardTitle, CardBody, Button } from "reactstrap"

import QueryString from "query-string"
import _ from "lodash"
import { Pagination, Divider, Tooltip } from "antd"
import toastr from "toastr"

import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import {
  CTA_ENTITY_LIST,
  CTA_ENTITY_MAP,
  CTA_DEFAULT_DATA,
} from "../../constants/uiConstants"
import ValidatorForm, { TextField, Select } from "../ValidatorForm"
import { getCta, deleteCta, upsertCta } from "../../appRedux/slices/cta"

import "./cta.css"

const view_type_map = {
  1: "Button with background",
  2: "Arrow",
  3: "Button with icon",
  4: "Text with arrow",
  5: "Only text",
}

class BrowseCTA extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
      currentData: null,
      entityType:
        this.props.queryParams && this.props.queryParams["entityType"]
          ? this.props.queryParams["entityType"]
          : CTA_ENTITY_LIST[0].value,
      ctaIcon: null,
      buttonIcon: null,
      buttonSelectedIcon: null,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      columns: [
        {
          dataField: "icon_url",
          text: "CTA Icon",
          // headerStyle: (colum, colIndex) => {
          //   return { width: '60px', textAlign: 'center' };
          // },
          // style: {
          //   width: "5%",
          // },
          formatter: (cellContent, entity) => {
            return (
              <>
                <div className="">
                  <img
                    src={cellContent}
                    height="50px"
                    width="50px"
                    alt="CTA Icon"
                  />
                </div>
              </>
            )
          },
        },
        {
          dataField: "id",
          text: "Entity Id",
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>
                  {cellContent || "-"}
                  <CopyToClipboard
                    text={cellContent || ""}
                    onCopy={e => {
                      toastr.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy Entity Id">
                      <i className="bx bx-copy me-2 ms-2" role="button" />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              </div>
            )
          },
        },
        {
          dataField: "title",
          text: "Title",
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>{cellContent || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "subtitle",
          text: "Subtitle",
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>{cellContent || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "cta.text",
          text: "CTA Text",
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>{cellContent || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "cta.selected_text",
          text: "CTA Selected Text",
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>{cellContent || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "cta.view_type",
          text: "CTA View Type",
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>{view_type_map[cellContent] || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "action_type",
          text: "Action Type",
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>{cellContent || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Actions",
          formatter: (cellContent, entity) => (
            <div className="d-flex align-items-center gap-3">
              <div role="button" className="text-success">
                <i
                  className="bx bx-edit-alt me-1 font-size-18 fw-bold"
                  id="edittooltip"
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.setState({ showForm: true, currentData: entity })
                  }}
                ></i>
              </div>
              <div role="button" className="text-danger">
                <i
                  className="bx bx-trash-alt font-size-18 fw-bold"
                  id="deletetooltip"
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.onDeleteEntity(entity)
                  }}
                ></i>
              </div>
            </div>
          ),
        },
      ],
    }
  }

  componentDidMount = () => {
    this.props.dispatch(
      getCta({
        entityType: this.state.entityType,
        currentPage: this.state.currentPage,
      })
    )
  }

  componentDidUpdate = () => {
    if (this.props.refetch) {
      this.props.dispatch(
        getCta({
          entityType: this.state.entityType,
          currentPage: this.state.currentPage,
        })
      )
    }
  }

  onDeleteEntity = entity => {
    this.props.dispatch(
      deleteCta({
        entityType: entity.type,
        entityId: entity.id,
      })
    )
  }

  onEntityTypeChange = value => {
    this.setState({ entityType: value.value }, () => {
      this.props.history.push(
        `/cta/browse?entityType=${this.state.entityType}&currentPage=${
          this.state?.currentPage || 1
        }`
      ),
        this.props.dispatch(
          getCta({
            entityType: this.state.entityType,
            currentPage: this.state.currentPage,
          })
        )
    })
  }

  _onPageChange = value => {
    this.setState({ currentPage: value }, () => {
      this.props.history.push(
        `/cta/browse?entityType=${this.state.entityType}&currentPage=${
          this.state?.currentPage || 1
        }`
      ),
        this.props.dispatch(
          getCta({
            entityType: this.state.entityType,
            currentPage: this.state.currentPage,
          })
        )
    })
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      [key]: file,
    })
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    let {
      entityType,
      currentData = null,
      ctaIcon,
      buttonIcon,
      buttonSelectedIcon,
    } = this.state

    let data = { ...formData }

    data.type = entityType

    delete data.entityType

    if (!data?.title && CTA_DEFAULT_DATA[entityType]?.title) {
      data.title = CTA_DEFAULT_DATA[entityType].title || ""
    }

    if (!data?.subtitle && CTA_DEFAULT_DATA[entityType]?.subtitle) {
      data.subtitle = CTA_DEFAULT_DATA[entityType].subtitle || ""
    }

    if (!ctaIcon && CTA_DEFAULT_DATA[entityType]?.icon_url) {
      data.icon_url =
        currentData?.icon_url || CTA_DEFAULT_DATA[entityType].icon_url || ""
    }

    if (!data?.cta?.text && CTA_DEFAULT_DATA[entityType]?.cta?.text) {
      data.cta.text = CTA_DEFAULT_DATA[entityType].cta.text || ""
    }

    if (
      !data?.cta?.selected_text &&
      CTA_DEFAULT_DATA[entityType]?.cta?.selected_text
    ) {
      data.cta.selected_text =
        CTA_DEFAULT_DATA[entityType].cta.selected_text || ""
    }

    if (!data?.cta?.view_type && CTA_DEFAULT_DATA[entityType]?.cta?.view_type) {
      data.cta.view_type = CTA_DEFAULT_DATA[entityType].cta.view_type || ""
    }

    if (!buttonIcon && CTA_DEFAULT_DATA[entityType]?.cta?.icon_url) {
      data.cta.icon_url =
        currentData?.cta?.icon_url ||
        CTA_DEFAULT_DATA[entityType].cta.icon_url ||
        ""
    }

    if (
      !buttonSelectedIcon &&
      CTA_DEFAULT_DATA[entityType]?.cta?.selected_icon_url
    ) {
      data.cta.selected_icon_url =
        currentData?.cta?.selected_icon_url ||
        CTA_DEFAULT_DATA[entityType].cta.selected_icon_url ||
        ""
    }

    let fData = new FormData()

    fData.append("details", JSON.stringify(data))

    if (ctaIcon) {
      fData.append("ctaIcon", ctaIcon)
    }

    if (buttonIcon) {
      fData.append("buttonIcon", buttonIcon)
    }

    if (buttonSelectedIcon) {
      fData.append("buttonSelectedIcon", buttonSelectedIcon)
    }

    this.props.dispatch(
      upsertCta({
        data: fData,
        entityType,
        action: !currentData ? "create" : "update",
      })
    )

    this.setState({
      currentData: null,
      showForm: false,
      ctaIcon: null,
      buttonIcon: null,
      buttonSelectedIcon: null,
    })
  }

  render() {
    const defaultSorted = [
      {
        dataField: "title",
        order: "asc",
      },
    ]
    return (
      <>
        {!this.state.showForm ? (
          <Card style={{ width: "100%", minHeight: "78vh" }}>
            <div className="d-flex justify-content-between m-2 mt-4">
              <ReactSelect
                value={
                  this.state.entityType
                    ? CTA_ENTITY_MAP[this.state.entityType]
                      ? {
                          label: CTA_ENTITY_MAP[this.state.entityType],
                          value: this.state.entityType,
                        }
                      : {
                          label: this.state.entityType,
                          value: this.state.entityType,
                        }
                    : null
                }
                isMulti={false}
                className="w-25"
                options={CTA_ENTITY_LIST}
                onChange={value => this.onEntityTypeChange(value)}
                placeholder="Select Entity Type..."
              />
              {this.state.entityType ? (
                <Button
                  color="outline-primary"
                  className="btn-sm font-weight-semibold w-xs font-size-12"
                  onClick={() =>
                    this.setState({ showForm: true, currentData: null })
                  }
                >
                  <i className="fa fa-plus mx-1 my-1" /> Create
                </Button>
              ) : null}
            </div>
            <div className="m-2">
              <Auxiliary
                loading={this.props.loading}
                error={_.get(this.props, "common.error")}
              >
                {this.props.entityList && this.props.entityList.length > 0 ? (
                  <>
                    <ToolkitProvider
                      keyField="id"
                      data={this.props.entityList || []}
                      columns={this.state.columns}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <div className="table-responsive">
                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              // {...paginationTableProps}
                              responsive
                              defaultSorted={defaultSorted}
                              bordered={false}
                              striped={false}
                              // selectRow={selectRow}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                    <div className="d-flex justify-content-end">
                      <Pagination
                        style={{
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                        onChange={this._onPageChange}
                        defaultCurrent={this.state.currentPage}
                        total={
                          this.props?.total < 10000 ? this.props.total : 10000
                        }
                        pageSize={10}
                        showSizeChanger={false}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="d-flex justify-content-center hero-section text-muted"
                    style={{ fontStyle: "italic" }}
                  >
                    No data present.
                  </div>
                )}
              </Auxiliary>
            </div>
          </Card>
        ) : (
          <div className="bg-white">
            <ValidatorForm
              onSubmit={this._onSubmit}
              layout={"vertical"}
              {...this._formLayout}
            >
              <Modal.Header>
                <Modal.Title>
                  <CardTitle className="font-size-18 text-wrap">
                    {`${
                      !this.state.currentData ? "Create" : "Edit"
                    } Custom CTA`}
                  </CardTitle>
                </Modal.Title>
              </Modal.Header>
              <div className="m-2 p-2">
                <Row>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <TextField
                      label={"Entity Type"}
                      field={"entityType"}
                      className="creator-form"
                      value={this.state.entityType || ""}
                      disabled
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <TextField
                      label={"Entity Id"}
                      field={"id"}
                      placeholderLabel="Enter Entity Id"
                      className="creator-form"
                      defaultValue={
                        (this.state.currentData && this.state.currentData.id) ||
                        ""
                      }
                      validations={["required"]}
                      errors={["This field is required"]}
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <TextField
                      label={"Title"}
                      placeholderLabel="Enter Title"
                      field={"title"}
                      className="creator-form"
                      defaultValue={
                        (this.state.currentData &&
                          this.state.currentData.title) ||
                        ""
                      }
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <TextField
                      label={"Sub-Title"}
                      placeholderLabel="Enter Sub-Title"
                      field={"subtitle"}
                      className="creator-form"
                      defaultValue={
                        (this.state.currentData &&
                          this.state.currentData.subtitle) ||
                        ""
                      }
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        <label className="col-form-label">CTA Icon</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={e => {
                            this._handleFileUpload("ctaIcon", e.target.files[0])
                          }}
                        />
                      </div>
                      {this.state?.currentData?.icon_url &&
                      !this.state.ctaIcon ? (
                        <>
                          <img
                            src={this.state?.currentData?.icon_url}
                            width="50px"
                            height="50px"
                            className="me-2 mt-2"
                          />
                          <i
                            className="bx bx bx-trash-alt text-danger mt-2"
                            role="button"
                            onClick={() =>
                              this.setState(prev_state => ({
                                ctaIcon: null,
                                currentData: {
                                  ...prev_state.currentData,
                                  icon_url: null,
                                },
                              }))
                            }
                          />
                        </>
                      ) : null}
                    </div>
                  </Col>
                  <Divider className="m-auto">
                    <h5 className="mb-0 text-muted">CTA</h5>
                  </Divider>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <TextField
                      label={"CTA Text"}
                      placeholderLabel="Enter Text"
                      field={"cta.text"}
                      className="creator-form"
                      defaultValue={
                        (this.state.currentData &&
                          this.state.currentData.cta &&
                          this.state.currentData.cta.text) ||
                        ""
                      }
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <TextField
                      label={"CTA Selected Text"}
                      placeholderLabel="Enter Selected Text"
                      field={"cta.selected_text"}
                      className="creator-form"
                      defaultValue={
                        (this.state.currentData &&
                          this.state.currentData.cta &&
                          this.state.currentData.cta.selected_text) ||
                        ""
                      }
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <Select
                      label={"CTA View Type"}
                      placeholderLabel="Enter View Type"
                      field="cta.view_type"
                      className="creator-form"
                      options={[
                        { label: "Button with background", value: 1 },
                        { label: "Arrow", value: 2 },
                        { label: "Button with icon", value: 3 },
                        { label: "Text with arrow", value: 4 },
                        { label: "Only text", value: 5 },
                      ]}
                      defaultValue={
                        (this.state.currentData &&
                          this.state.currentData.cta &&
                          this.state.currentData.cta.view_type &&
                          +this.state.currentData.cta.view_type) ||
                        ""
                      }
                    />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        <label className="col-form-label">Button Icon</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={e => {
                            this._handleFileUpload(
                              "buttonIcon",
                              e.target.files[0]
                            )
                          }}
                        />
                      </div>
                      {this.state?.currentData?.cta?.icon_url &&
                      !this.state.buttonIcon ? (
                        <>
                          <img
                            src={this.state?.currentData?.cta?.icon_url}
                            width="50px"
                            height="50px"
                            className="me-2 mt-2"
                          />
                          <i
                            className="bx bx bx-trash-alt text-danger mt-2"
                            role="button"
                            onClick={() =>
                              this.setState(prev_state => ({
                                ctaIcon: null,
                                currentData: {
                                  ...prev_state.currentData,
                                  cta: {
                                    ...prev_state.currentData.cta,
                                    icon_url: null,
                                  },
                                },
                              }))
                            }
                          />
                        </>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="mb-2">
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        <label className="col-form-label">
                          Button Selected Icon
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={e => {
                            this._handleFileUpload(
                              "buttonSelectedIcon",
                              e.target.files[0]
                            )
                          }}
                        />
                      </div>
                      {this.state?.currentData?.cta?.selected_icon_url &&
                      !this.state.buttonSelectedIcon ? (
                        <>
                          <img
                            src={
                              this.state?.currentData?.cta?.selected_icon_url
                            }
                            width="50px"
                            height="50px"
                            className="me-2 mt-2"
                          />
                          <i
                            className="bx bx bx-trash-alt text-danger mt-2"
                            role="button"
                            onClick={() =>
                              this.setState(prev_state => ({
                                ctaIcon: null,
                                currentData: {
                                  ...prev_state.currentData,
                                  cta: {
                                    ...prev_state.currentData.cta,
                                    selected_icon_url: null,
                                  },
                                },
                              }))
                            }
                          />
                        </>
                      ) : null}
                    </div>
                  </Col>

                  <div className="text-muted font-size-13 mt-2">
                    <span className="font-weight-semibold">Note:</span> If no
                    value selected, it will take the default value for some
                    fields.
                  </div>
                </Row>
              </div>
              <Modal.Footer>
                <Button className="my-3" color="primary" htmlType="submit">
                  Submit
                </Button>
                <Button
                  className="my-3 mx-2"
                  color="danger"
                  onClick={() => {
                    this.setState({
                      currentData: null,
                      showForm: false,
                      ctaIcon: null,
                      buttonIcon: null,
                      buttonSelectedIcon: null,
                    })
                  }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </ValidatorForm>
          </div>
          // </Modal>
        )}
      </>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    entityList: _.get(store, "cta.entityList") || [],
    refetch: _.get(store, "cta.refetch") || false,
    loading: _.get(store, "cta.loading") || false,
    total: _.get(store, "cta.total"),
  }
}

export default connect(mapStateToProps)(BrowseCTA)
