import React from "react"
import { withRouter } from "react-router"
import PropTypes from "prop-types"
import { Card, Row, CardBody, Col } from "reactstrap"
import CopyToClipboard from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "../style.css"

const VideoCard = props => {
  const { content_title, content_uuid, preview_url, tags } = props?.video || {}

  return (
    <Card style={{ width: "fit-content" }}>
      <div className="bg-primary bg-soft">
        <Row>
          <Col>
            <video
              src={preview_url}
              height="455px"
              width="250px"
              controls={true}
              muted={true}
              autoPlay
            />
          </Col>
        </Row>
      </div>
      <CardBody>
        <Tooltip title={content_title}>
          <div className="font-size-16 font-weight-semibold ellipsis">
            {content_title}
          </div>
        </Tooltip>
      </CardBody>
      <div className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center justify-content-center">
        <CopyToClipboard
          text={content_uuid}
          onCopy={e => {
            toastr.success("Copied sucessfully!")
          }}
        >
          <Tooltip title="Copy Content ID">
            <i className="bx bx-copy" role="button" />
          </Tooltip>
        </CopyToClipboard>
      </div>
    </Card>
  )
}

VideoCard.propTypes = {
  content: PropTypes.object,
}

export default withRouter(VideoCard)
