import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ContentList from "../../components/ContentList/contentList"
import { DatePicker, Input, Select as AntSelect } from "antd"
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  InputGroup,
} from "reactstrap"
import ValidatorForm, { TextField, Select } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getCmsUserList } from "../../appRedux/slices/cmsUser"
import {
  resetState,
  boostFollow,
  getBoostFollowHistory,
} from "appRedux/slices/appUser"
import Auxiliary from "../../util/Auxiliary"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import moment from "moment"
import { bulkUploadUserForBoosting } from "clientServices/appUserService"
import UserList from "components/UserList/userList"
import { NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import toastr from "toastr"
import dayjs from "dayjs"
class BoostFollow extends ErrorBoundComponent {
  state = {
    filters: {
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : "",
      searchType:
        this.props.queryParams && this.props.queryParams["searchType"]
          ? this.props.queryParams["searchType"]
          : "uuid",
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      fromDate: this.props.queryParams
        ? this.props.queryParams["fromDate"]
        : "",
      toDate: this.props.queryParams ? this.props.queryParams["toDate"] : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(
        getBoostFollowHistory(_.deepClean(this.state.filters))
      )

      if (this.props.moderatorList && this.props.moderatorList.length === 0) {
        this.props.dispatch(getCmsUserList())
      }
    }
  }

  componentDidUpdate() {
    if (this.props.refetchData) {
      this.props.dispatch(
        getBoostFollowHistory(_.deepClean(this.state.filters))
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }
    this.setState({ filters: newFilters })
  }

  _searchContent = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/content/tag?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    this.setState({
      showForm: false,
      showBulkUploadForm: false,
    })
    this.props.dispatch(boostFollow(formData))
  }

  _form = () => {
    return (
      <ValidatorForm onSubmit={this._onSubmit} layout={"vertical"}>
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" lg="6">
                <TextField
                  label={"User ID"}
                  placeholderLabel="User ID"
                  field={"id"}
                  className="creator-form"
                  value={this.state.selectedId}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm="12" lg="6">
                <TextField
                  label={"Follow Count"}
                  placeholderLabel="Provide Follow Count"
                  className="creator-form"
                  field={"followCount"}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>

              <Col sm="12" lg="6">
                <TextField
                  label="Boosting Days"
                  placeholderLabel="Provide Boosting Days"
                  className="creator-form"
                  field={"boostingDays"}
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              {/* <Col sm="12" lg="6">
                <Select
                  label="Boosting Days"
                  style={{ width: "100%" }}
                  field={"boostingDays"}
                  errors={["This field is required"]}
                  defaultValue={4}
                  validations={["required"]}
                  size="large"
                  options={[
                    { value: 4, label: 4 },
                    { value: 7, label: 7 },
                    { value: 15, label: 15 },
                    { value: 30, label: 30 },
                  ]}
                />
              </Col> */}
            </Row>
            <Row gutter={16}>
              <Col sm="12">
                <div className="d-flex justify-content-end">
                  <Button
                    htmlType="submit"
                    className="font-16 btn-block btn btn-primary"
                    color="primary"
                    disabled={this.state.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    color="danger"
                    size="default"
                    disabled={this.state.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                    onClick={() => this.setState({ showForm: false })}
                    className="mx-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ValidatorForm>
    )
  }

  _onUpload = async () => {
    const formData = new FormData()
    formData.append("csvFile", this.state.csvFile)
    await bulkUploadUserForBoosting(formData)
  }

  _bulkUploadForm = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="6">
              <label className="col-form-label">Upload CSV File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this.setState({
                    csvFile: e.target.files[0],
                  })
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm="12">
              <div className="d-flex justify-content-end">
                <Button
                  className="font-16 btn-block btn btn-primary"
                  color="primary"
                  onClick={this._onUpload}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="danger"
                  size="default"
                  disabled={this.state.loading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showBulkUploadForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  _onContentSearch = () => {
    this.props.history.push(
      `/user/boost?${QueryString.stringify(_.deepClean(this.state.filters))}`
    )
    this.props.dispatch(getBoostFollowHistory(_.deepClean(this.state.filters)))
  }

  _filters = () => {
    return (
      <Card>
        <CardBody>
          <div className="d-flex">
            <Row style={{ flex: 1 }}>
              <Col lg="2" md="2">
                <Select
                  defaultValue={this.state.filters["searchType"]}
                  onChange={value => {
                    this._handleFilterChange(value, "searchType")
                  }}
                  style={{ width: "100%" }}
                  options={[
                    { value: "uuid", label: "User UUID" },
                    { value: "moderator", label: "Moderator" },
                  ]}
                  size="default"
                />
              </Col>
              {this.state.filters["searchType"] === "uuid" && (
                <Col lg="3">
                  <Input
                    defaultValue={this.state.filters["searchText"] || ""}
                    type="text"
                    onChange={e =>
                      this._handleFilterChange(e.target.value, "searchText")
                    }
                    validations={["required"]}
                    id="autoSizingInput"
                    placeholder="ID"
                    errors={["This field is required"]}
                  />
                </Col>
              )}
              {this.state.filters["searchType"] === "moderator" && (
                <Col md="6" lg="2">
                  <Select
                    defaultValue={this.state.filters["searchText"]}
                    onChange={value =>
                      this._handleFilterChange(value, "searchText")
                    }
                    style={{ width: "100%" }}
                    options={this.props.moderatorList.map(user => {
                      return {
                        label: user.name,
                        value: user.userId.toString(),
                      }
                    })}
                  />
                </Col>
              )}

              <Col lg="4">
                <FormGroup>
                  <InputGroup>
                    <DatePicker
                      defaultValue={
                        this.state.filters["fromDate"]
                          ? dayjs.unix(
                              this.state.filters["fromDate"] / 1000,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                      format="YYYY-MM-DD HH:mm"
                      style={{ width: "50%" }}
                      showTime
                      placeholder="From Date"
                      onChange={(date, dateString) => {
                        this._handleFilterChange(
                          !_.isEmpty(dateString)
                            ? moment(dateString).format("x")
                            : null,
                          "fromDate"
                        )
                      }}
                    />
                    <DatePicker
                      defaultValue={
                        this.state.filters["toDate"]
                          ? dayjs.unix(
                              this.state.filters["toDate"] / 1000,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                      format="YYYY-MM-DD HH:mm"
                      showTime
                      style={{ width: "50%" }}
                      placeholder="To Date"
                      onChange={(date, dateString) => {
                        this._handleFilterChange(
                          !_.isEmpty(dateString)
                            ? moment(dateString).format("x")
                            : null,
                          "toDate"
                        )
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="2">
                <AntSelect
                  value={this.state.filters["status"]}
                  onChange={value => {
                    this._handleFilterChange(value, "status")
                  }}
                  placeholder="Status"
                  options={[
                    { value: "", label: "All" },
                    { value: "SUCCESS", label: "Done" },
                    { value: "FAILED", label: "Failed" },
                  ]}
                  style={{ width: "100%" }}
                  size="default"
                />
              </Col>
            </Row>
            <button
              onClick={this._onContentSearch}
              role="button"
              className="btn btn-success d-flex align-items-center"
            >
              <div className="mx-2">Apply</div>
              <span>
                <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
              </span>
            </button>
          </div>
        </CardBody>
      </Card>
    )
  }

  _actionButtons = () => {
    return (
      <div
        className="d-flex justify-content-end text-sm-end"
        style={{ paddingBottom: "15px" }}
      >
        <Button
          color="primary"
          className="font-16 btn-block btn btn-primary"
          onClick={() => {
            if (
              !this.props.currentUser?.permissions?.includes(
                "EDIT_BOOST_FOLLOW_HISTORY"
              )
            ) {
              toastr.error(NO_PERMISSION_MESSAGE)
              return
            }
            this.setState(
              {
                showForm: false,
                showBulkUploadForm: false,
              },
              () =>
                this.setState({
                  showForm: true,
                })
            )
          }}
        >
          <i className="fas fa-arrow-up me-1" />
          Boost Follows
        </Button>
        <Button
          color="primary"
          className="font-16 btn-block btn btn-primary mx-1"
          onClick={() => {
            if (
              !this.props.currentUser?.permissions?.includes(
                "EDIT_BOOST_FOLLOW_HISTORY"
              )
            ) {
              toastr.error(NO_PERMISSION_MESSAGE)
              return
            }
            this.setState(
              {
                showForm: false,
                showBulkUploadForm: false,
              },
              () =>
                this.setState({
                  showBulkUploadForm: true,
                })
            )
          }}
        >
          <i className="fas fa-arrow-up me-1" />
          Upload
        </Button>
      </div>
    )
  }

  render() {
    if (this.state.showSearchAndSelect) {
      return (
        <SearchAndSelect
          type="VIDEO"
          visible={this.state.showSearchAndSelect}
          onClose={() => this.setState({ showSearchAndSelect: false })}
          onAdd={arr => this.setState({ selectedId: arr[0] })}
          selectOne={true}
          selectedArr={[]}
        />
      )
    }

    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        {this._actionButtons()}
        {this.state.showForm && this._form()}
        {this.state.showBulkUploadForm && this._bulkUploadForm()}
        {this.props.userList &&
          this.props.userList.length > 0 &&
          !this.state.showForm &&
          !this.state.showBulkUploadForm && (
            <UserList
              userList={this.props.userList}
              onPageChange={this._onPageChange}
              total={this.props.total || this.props.userList.length}
              searchAfter={this.props.searchAfter || 0}
              isMobile={this.props.isMobile}
              currentUser={this.props.currentUser}
              currentPage={this.state.filters["currentPage"]}
              pageType="boostFollows"
              pageSize={this.state.filters["pageSize"] || 1}
            />
          )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    userList: _.get(store, "appUser.boostHistory"),
    total: _.get(store, "appUser.total"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "appUser.loading"),
    moderatorList: _.get(store, "cmsUser.userList") || [],
    refetchData: _.get(store, "appUser.refetchBoostHistory"),
  }
}

export default connect(mapStateToProps)(BoostFollow)
