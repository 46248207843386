import Axios from "axios"
import toastr from "toastr"

export const requestCameraAssetListByFilters = async ({ payload }) => {
  try {
    const offset = payload["currentPage"] - 1
    let data = await Axios.post(
      `/api/cameraAsset/getCameraAssetListByFilter?offset=${offset}`,
      { filters: payload }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestFiltersList = async () => {
  try {
    let data = await Axios.get(`/api/cameraAsset/filters`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestEffectsList = async () => {
  try {
    let data = await Axios.get(`/api/cameraAsset/effects`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestMasksList = async () => {
  try {
    let data = await Axios.get(`/api/cameraAsset/masks`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertCameraAsset = async ({ payload }) => {
  try {
    let data = await Axios.post("/api/cameraAsset/upsert", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Updated successfully!!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestModerateCameraAsset = async ({ payload }) => {
  try {
    let data = await Axios.post(
      `/api/cameraAsset/action/${payload.action}?comboId=${payload.comboId}`
    )
    toastr.success("Updated successfully!!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestFaceUnityList = async () => {
  try {
    let data = await Axios.get(`/api/cameraAsset/faceUnity`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestStickerList = async () => {
  try {
    let data = await Axios.get(`/api/cameraAsset/stickers`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestArGames = async () => {
  try {
    let data = await Axios.get(`/api/cameraAsset/arGames`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpgradeCameraAssetVersion = async () => {
  try {
    let response = await Axios.get(`/api/cameraAsset/upgradeVersion`)
    toastr.success("Camera Asset Version Updated Successfully !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestUpsertTemplate = async ({ payload }) => {
  try {
    let url
    if (!_.isEmpty(payload.templateId) && payload.action === "update") {
      url = `/api/cameraAsset/templates/upsert?action=${payload.action}&templateId=${payload.templateId}`
    } else url = `/api/cameraAsset/templates/upsert?action=${payload.action}`

    let response = await Axios.post(url, payload.data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestTemplates = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/cameraAsset/templates?offset=${offset}&limit=${limit}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestRemoveTemplate = async ({ payload }) => {
  try {
    let response = await Axios.delete(
      `/api/template/remove?templateId=${payload}`
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
