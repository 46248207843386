import React, { PureComponent } from "react"
import Plot from "react-plotly.js"

const BarCharts = props => {
  let { labels, values, title } = props

  return (
    <Plot
      data={[
        {
          x: labels,
          y: values,
          type: "bar",
        },
      ]}
      layout={{ title }}
    />
  )
}

export default BarCharts
