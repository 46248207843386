import { createSlice } from "@reduxjs/toolkit"

const videoAssetSlice = createSlice({
  name: "videoAssets",
  initialState: {
    videoMaterialTypes: [],
    loading: false,
    videoMaterialTypeInfo: [],
    refetchData: false,
    tabsList: [],
    assetTypes: [],
    assets: [],
  },
  reducers: {
    getVideoMaterialTypes(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    getVideoMaterialTypesSuccess(state, action) {
      return {
        ...state,
        loading: false,
        videoMaterialTypes: action.payload,
        refetchData: false,
      }
    },
    getAllResourceForType(state, action) {
      return {
        ...state,
        loading: true,
      }
    },
    getAllResourceForTypeSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        videoMaterialTypeInfo: payload.data.rows,
        refetchData: false,
      }
    },
    deleteVideoMaterialResourceById(state, action) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    deleteVideoMaterialResourceByIdSuccess(state, action) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertVideoAsset(state, action) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertVideoAssetSuccess(state, action) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    getAssetTypes(state, action) {
      return {
        ...state,
        // loading: true,
        refetchData: false,
      }
    },
    getAssetTypesSuccess(state, action) {
      return {
        ...state,
        loading: false,
        assetTypes: action.payload.data || [],
        refetchData: false,
      }
    },
    getAllAssetsForType(state) {
      return {
        ...state,
      }
    },
    getAllAssetsForTypeSuccess(state, action) {
      return {
        ...state,
        loading: false,
        assets: action.payload,
        refetchData: false,
      }
    },
    updateAssetMappingOrder(state, action) {
      return {
        ...state,
        loading: true,
      }
    },
    updateAssetMappingOrderSuccess(state, action) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    getTabsList(state, action) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getTabsListSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        tabsList: payload.data,
      }
    },
    upsertTabAsset(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertTabAssetSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    deleteTabById(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    deleteTabByIdSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    updateTabOrder(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateTabOrderSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    updateTabVersion(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    updateTabVersionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    resetState(state) {
      return {
        ...state,
        assets: [],
        loading: false,
        refetchData: false,
      }
    },
  },
})
export const {
  getTabsList,
  getTabsListSuccess,
  updateTabOrder,
  updateTabVersion,
  updateTabVersionSuccess,
  upsertTabAsset,
  updateTabOrderSuccess,
  upsertTabAssetSuccess,
  deleteTabById,
  deleteTabByIdSuccess,
  getVideoMaterialTypes,
  getVideoMaterialTypesSuccess,
  getAllResourceForType,
  getAllResourceForTypeSuccess,
  deleteVideoMaterialResourceById,
  deleteVideoMaterialResourceByIdSuccess,
  updateAssetMappingOrder,
  updateAssetMappingOrderSuccess,
  upsertVideoAsset,
  upsertVideoAssetSuccess,
  getAssetTypes,
  getAssetTypesSuccess,
  getAllAssetsForType,
  getAllAssetsForTypeSuccess,
  resetState,
} = videoAssetSlice.actions

export default videoAssetSlice.reducer
