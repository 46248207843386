import { Col, Input, Pagination, Row, Select, Tabs } from "antd"
import { Button } from "@mui/material"
import ContentCard from "components/ContentCard/contentCard"
import ContestCard from "containers/Contest/contestCard"
import ImageCard from "containers/Image/ImageCard"
import SocialCard from "containers/Social/socialCard"
import TemplateCard from "containers/Templates/templateCard"
import ZoneCard from "containers/Zone/zoneCard"
import _ from "lodash"
import QueryString from "query-string"
import React from "react"
import { Modal } from "react-bootstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardImg } from "reactstrap"
import { getMimeTypes } from "../../appRedux/slices/stickers"
import {
  getAllAssetsForType,
  getAssetTypes,
  resetState as resetAssetState,
} from "../../appRedux/slices/videoAssets"
import { getSearchAndSelectData } from "../../clientServices/searchAndSelect"
import Chips from "../../components/CommonFilter/chips"
import ChallengeCard from "../../containers/Challenge/challengeCard"
import HashtagCard from "../../containers/Hashtag/hashtagCard"
import Auxiliary from "../../util/Auxiliary"
import AudioCard from "../AudioList/audioCard"
import AudioStoryCard from "../../containers/AudioStories/storiesCard.js"
import "../TaxonomyContentPreview/styles.css"
import "../UserList/styles.css"
import UserCard from "../UserList/userCard"
import { TYPE_TO_FILTER_MAP } from "./config"
import CameraAssetCard from "../../components/CameraAssetsList/cameraAssetCard"
import "./styles.css"
import { getImage, RenderAssetType, RenderMixedAssetFilters } from "./util"
import {
  COLLECTION_PROPERTIES,
  RenderEffectCards,
  RenderEntityCards,
  RenderGameCard,
  RenderStickerCards,
  RenderStickers,
  RenderStickerSubType,
  RenderTemplateCard,
} from "./utilv2"
import GiftCard from "../../containers/Gifts/giftCard"
import PackageCard from "../../containers/Package/packageCard"
import { getAllZones } from "appRedux/slices/zone"
import { Chip } from "@mui/material"

const { TabPane } = Tabs

const GIFT_FILTER_KEYS = [
  {
    key_name: "name",
    display_name: "Name",
  },
  {
    key_name: "giftId",
    display_name: "Gift Id",
  },
]

const PACKAGE_FILTER_KEYS = [
  {
    key_name: "name",
    display_name: "Name",
  },
  {
    key_name: "packageId",
    display_name: "Package Id",
  },
]

class SearchAndSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: TYPE_TO_FILTER_MAP[this.props.type]
        ? TYPE_TO_FILTER_MAP[this.props.type].filters
        : TYPE_TO_FILTER_MAP["entity"].filters,
      oldSelectedValue: this.props.selectedArr ? this.props.selectedArr : [],
      array: this.props.selectedArr ? this.props.selectedArr : [],
      show: true,
      loading: true,
      searchAndSelectData: {
        data: [],
        total: 0,
      },
      showFilters: false,
      showAdvancedFilters: false,
      selectedTitle: undefined,
    }
  }

  componentDidMount() {
    if (this.props.type === "mixed") {
      this.props.dispatch(getAssetTypes())
    }
    if (this.props.type === "STICKERS") {
      this.props.dispatch(
        getMimeTypes({
          selectedFilter: this.props.queryParams["selectedFilter"],
        })
      )
    }
    if (this.props.pageId && this.props.pageId === "main-tab-page") {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            collectionType: "NAMED_COLLECTION",
            elementType: "COMBINATION",
          },
        },
        () => this._getData()
      )
    } else this._getData()
    this.props.dispatch(
      getAllZones({
        currentPage: 1,
        pageSize: 10000,
        projection: [
          "title",
          "sub_title",
          "type",
          "sub_type",
          "zone_uuid",
          "status",
          "level",
          "zone_image",
        ],
      })
    )
  }

  _getData = async () => {
    this.setState({ loading: true })
    let filters = _.clone(this.state.filters)
    if (this.props.filterType) {
      filters.ce_type = this.props.filterType
      filters.type = this.props.filterType
    }
    if (
      this.props.type === "STICKERSUBTYPE" ||
      this.props.type === "STICKERS"
    ) {
      filters["searchType"] = this.props.queryParams["selectedFilter"]
      const { data } = await getSearchAndSelectData(
        _.deepClean(filters),
        this.props.type
      )
      this.setState({
        searchAndSelectData: data,
        loading: false,
        array: this.props.selectedArr
          ? this.props.selectedArr
          : this.state.array,
      })
    } else if (this.props.zoneId) {
      filters["activeZones"] = this.props.zoneId
      const { data } = await getSearchAndSelectData(
        _.deepClean(filters),
        this.props.type
      )
      this.setState({
        searchAndSelectData: data,
        loading: false,
        array: this.props.selectedArr
          ? this.props.selectedArr
          : this.state.array,
        selectedTitle: ["collection", "feed-collection"].includes(
          this.props.type
        )
          ? this.state.filters.title
          : undefined,
      })
    } else {
      const { data } = await getSearchAndSelectData(
        _.deepClean(filters),
        this.props.type,
        this.props.zoneLevel
      )
      this.setState({
        searchAndSelectData: data,
        loading: false,
        array: this.props.selectedArr
          ? this.props.selectedArr
          : this.state.array,
        selectedTitle: ["collection", "feed-collection"].includes(
          this.props.type
        )
          ? this.state.filters.title
          : undefined,
      })
    }
  }

  _onPageChange = async value => {
    this.setState({ loading: true })
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    if (
      this.props.type === "STICKERSUBTYPE" ||
      this.props.type === "STICKERS"
    ) {
      newFilters["searchType"] = this.props.queryParams["selectedFilter"]
      const { data } = await getSearchAndSelectData(
        _.deepClean(newFilters),
        this.props.type
      )
      this.setState({
        filters: newFilters,
        searchAndSelectData: data,
        loading: false,
      })
    } else if (this.props.zoneLevel || this.props.zoneLevel == 0) {
      newFilters["level"] = this.props.zoneLevel
      const { data } = await getSearchAndSelectData(
        _.deepClean(newFilters),
        this.props.type,
        this.props.zoneLevel
      )
      this.setState({
        filters: newFilters,
        searchAndSelectData: data,
        loading: false,
      })
    } else {
      const { data } = await getSearchAndSelectData(
        _.deepClean(newFilters),
        this.props.type
      )
      this.setState({
        filters: newFilters,
        searchAndSelectData: data,
        loading: false,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return false
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetAssetState())
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }
    this.setState({
      filters: newFilters,
    })
  }

  _searchMixedAssetType = () => {
    const filters = _.clone(this.state.filters)
    this.props.dispatch(getAllAssetsForType({ filters: _.deepClean(filters) }))
  }

  _callback = value => {
    if (this.props.selectOne) {
      if (["collection", "feed-collection"].includes(this.props.type)) {
        const [key] = Object.keys(this.state.array)
        if (Object.keys(value).includes(key)) {
          this.setState({ array: [] })
        } else this.setState({ array: value })
      } else {
        const [key] = Object.values(this.state.array)
        if (value === key) {
          this.setState({ array: [] })
        } else this.setState({ array: [value] })
      }
    } else {
      let array = [...this.state.array] // make a separate copy of the array
      const index = array.indexOf(value)
      if (index !== -1) {
        array.splice(index, 1)
        this.setState({ array })
      } else this.setState({ array: [...array, value] })
    }
  }

  _renderCardContent = data => {
    const videSource = data.download_url ? data.download_url : null
    return (
      <div className="card" style={{ width: "20rem" }}>
        {videSource && (
          <video
            src={videSource}
            controls
            autoPlay
            controlsList={"nodownload"}
            loop
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        )}
        <div className="card-body">
          <h5 className="card-title">{data.content_title}</h5>
        </div>
      </div>
    )
  }

  onAddClick = () => {
    this.props.onClose()
    this.props.onAdd(this.state.array)
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _closeFilters = () => {
    this._getData()
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  _getFilteredCollectionList = (type, title, collectionList) => {
    let filteredList = []
    let filtertTitle = title.toLowerCase().trim()
    switch (type) {
      case "CHALLENGE":
        filteredList = collectionList.filter(collection => {
          if (collection.hashtag) {
            return collection.hashtag.toLowerCase().includes(filtertTitle)
          } else return
        })
        break
      case "ZONE":
        filteredList = collectionList.filter(collection => {
          if (collection.zone_uuid) {
            return collection.zone_uuid.toLowerCase().includes(filtertTitle)
          } else return
        })
        break
      case "IMAGE":
        filteredList = collectionList.filter(collection => {
          if (collection.content_uuid) {
            return collection.content_uuid.toLowerCase().includes(filtertTitle)
          } else return
        })
        break
      case "SOCIAL":
        filteredList = collectionList.filter(collection => {
          if (collection.content_uuid) {
            return collection.content_uuid.toLowerCase().includes(filtertTitle)
          } else return
        })
        break
      case "HASHTAG":
        filteredList = collectionList.filter(collection => {
          if (collection.collectionId) {
            return collection.collectionId.toLowerCase().includes(filtertTitle)
          } else return
        })
        break
      case "NAMED_COLLECTION":
        filteredList = collectionList.filter(collection => {
          if (collection.title) {
            return collection.title.toLowerCase().includes(filtertTitle)
          } else return
        })
        break
      default:
        filteredList = collectionList.filter(collection => {
          let collectionTitle =
            collection.title ||
            collection.name ||
            collection.type_name ||
            collection.collectionId
          if (collectionTitle) {
            return collectionTitle.toLowerCase().includes(filtertTitle)
          } else return
        })
        break
    }
    return filteredList
  }

  _getFilteredList = () => {
    const sortedList = _.sortBy(this.state.searchAndSelectData.data, [
      "created_date",
    ]).reverse()
    const filteredList = this.state.selectedTitle
      ? this._getFilteredCollectionList(
          this.state.filters.collectionType,
          this.state.selectedTitle,
          sortedList
        )
      : sortedList
    return filteredList
  }

  _getSortedList = () => {
    const filteredCollectionList = this._getFilteredList()
    const collectionEntityList = _(filteredCollectionList)
      .drop((this.state.filters.currentPage - 1) * this.state.filters.pageSize)
      .take(this.state.filters.pageSize)
      .value()
    return collectionEntityList
  }

  _assetCallback = value => {
    let array = [...this.state.array] // make a separate copy of the array
    array = [...new Map(array.map(item => [item["pk"], item])).values()]
    let assetPk = array.map(item => item.pk)
    const index = assetPk.indexOf(value["pk"])
    if (index !== -1) {
      array.splice(index, 1)
      this.setState({ array })
    } else {
      this.setState({ array: [...array, value] })
    }
  }

  render() {
    const allZoneList = []
    this.props?.allZoneList?.map(zone => {
      if (zone && zone.zone_uuid) {
        allZoneList.push({
          ...zone,
          value: zone.zone_uuid,
          label: zone.title || zone.sub_title,
        })
      }
    })

    let tabs = COLLECTION_PROPERTIES
    if (this.props.pageId && this.props.pageId === "main-tab-page") {
      tabs = { NAMED_COLLECTION: COLLECTION_PROPERTIES["NAMED_COLLECTION"] }
    }

    return (
      <Modal
        show={this.props.visible}
        onHide={this.props.onClose}
        footer={null}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modalTitle">
              {this.props.title || "Search And Select"}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col span={24}>
              {this.props.type === "CREATOR" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="user"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "COMBINATION" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="camera_asset"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "mixed" && (
                <RenderMixedAssetFilters
                  assetTypes={this.props.assetTypes}
                  searchType={this.state.filters["searchType"]}
                  _handleFilterChange={this._handleFilterChange}
                  _searchMixedAssetType={this._getData}
                  onAdd={this.onAddClick}
                />
              )}
              {this.props.type === "STICKER" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="entity"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "EFFECT" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="effect"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}

              {this.props.type === "TEMPLATE" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="template"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}

              {this.props.type === "GAME" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="entity"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}

              {this.props.type === "TEMPLATE_SECTION" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="camera_asset_template"
                      toggleFilters={this._toggleFilters}
                      // filterKeys={GIFT_FILTER_KEYS}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                      disabled={_.isEqual(
                        this.state.oldSelectedValue,
                        this.state.array
                      )}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}

              {this.props.type === "STICKERS" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      mimeTypes={this.props.mimeTypes}
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="stickerForSearchAndSelect"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}

              {this.props.type === "STICKERSUBTYPE" && (
                <Row className="d-flex justify-content-end pb-1">
                  <Col className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}

              {this.props.type === "HASHTAG" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="hashtag"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {[
                "MUSIC_ARTIST",
                "MUSIC_PLAYLIST",
                "MUSIC_LABEL",
                "BANNER",
                "SUBBANNER",
                "NAMED_COLLECTION",
                "VIDEO_BANNER",
                "WEBVIEW_BANNER",
                "CHIP",
              ].includes(this.props.type) && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="entity"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {(this.props.type === "VIDEO" ||
                this.props.type === "VIDEO_GREETING") && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="content"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "MUSIC" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="audio"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                      allZoneList={allZoneList}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "AUDIO_STORY" && (
                <Row className="mb-2">
                  <Col span={22}>
                    {/* <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="audio"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                      allZoneList={allZoneList}
                    /> */}
                  </Col>
                  <Col span={2} className="d-flex justify-content-end">
                    <Button variant="contained" onClick={this.onAddClick}>
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "AUDIO_SERIES" && (
                <Row className="mb-2">
                  <Col span={22}>
                    {/* <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="audio"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                      allZoneList={allZoneList}
                    /> */}
                  </Col>
                  <Col span={2} className="d-flex justify-content-end">
                    <Button variant="contained" onClick={this.onAddClick}>
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "CHALLENGE" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="challenge"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "ZONE" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="zone"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                      hideTypeFilter={true}
                      allZoneList={this.props.allZoneList}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "IMAGE" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="image"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "SOCIAL" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="social"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "CONTEST" && (
                <Row>
                  <Col span={23}></Col>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      showAdvancedFilters={this.state.showAdvancedFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="zone"
                      toggleFilters={this._toggleFilters}
                      toggleAdvancedFilters={this._toggleAdvancedFilters}
                      hideTypeFilter={true}
                      allZoneList={this.props.allZoneList}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "GIFT" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="manage_gifts"
                      toggleFilters={this._toggleFilters}
                      filterKeys={GIFT_FILTER_KEYS}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.type === "PACKAGE" && (
                <Row>
                  <Col span={22}>
                    <Chips
                      showFilters={this.state.showFilters}
                      filters={this.state.filters}
                      search={this._closeFilters}
                      removeFilter={() => {}}
                      handleFilterChange={this._handleFilterChange}
                      type="manage_package"
                      toggleFilters={this._toggleFilters}
                      filterKeys={PACKAGE_FILTER_KEYS}
                    />
                  </Col>
                  <Col span={2} className="align-self-center">
                    <Button
                      style={{ backgroundColor: "#556ee6", color: "white" }}
                      onClick={this.onAddClick}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              )}
              <Auxiliary
                loading={this.state.loading}
                error={_.get(this.props, "common.error")}
                style={{ justifyContent: "start" }}
              >
                {this.props.type === "CREATOR" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="user-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (user, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={user.user_uuid}
                            onClick={() => this._callback(user.user_uuid)}
                          >
                            <UserCard
                              user={user}
                              customClassname={
                                this.state.array.includes(user.user_uuid)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                              pageSource="searchAndSelect"
                              key={user.user_uuid}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {(this.props.type === "VIDEO" ||
                  this.props.type === "VIDEO_GREETING") &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="content-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (content, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={content.content_uuid}
                            onClick={() => this._callback(content.content_uuid)}
                          >
                            <ContentCard
                              content={content}
                              customClassname={
                                this.state.array.includes(content.content_uuid)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                              pageSource="searchAndSelect"
                              key={content.content_uuid}
                              enableEdit={false}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "CHALLENGE" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="challenge-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (content, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={content.challenge_id}
                            onClick={() => {
                              return this._callback(content.challenge_id)
                            }}
                          >
                            <ChallengeCard
                              challenge={content}
                              customClassname={
                                this.state.array.includes(content.challenge_id)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                              pageSource="searchAndSelect"
                              key={content.challenge_id}
                              enableEdit={false}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "ZONE" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="challenge-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (content, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={content.zone_uuid}
                            onClick={() => this._callback(content.zone_uuid)}
                          >
                            <ZoneCard
                              zone={content}
                              key={`${index}_${content.title}`}
                              currentUser={this.props.currentUser}
                              history={this.props.history}
                              customClassname={
                                this.state.array.includes(content.zone_uuid)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              pageSource="searchAndSelect"
                              enableEdit={false}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "IMAGE" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="image-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (content, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={content.content_uuid}
                            onClick={() => this._callback(content.content_uuid)}
                          >
                            <ImageCard
                              image={content}
                              key={`${index}_${content.title}`}
                              currentUser={this.props.currentUser}
                              history={this.props.history}
                              customClassname={
                                this.state.array.includes(content.content_uuid)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              pageSource="searchAndSelect"
                              enableEdit={false}
                              onEditClick={() => {}}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "SOCIAL" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="challenge-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (content, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={content.content_uuid}
                            onClick={() => this._callback(content.content_uuid)}
                          >
                            <SocialCard
                              social={content}
                              key={`${index}_${content.title}`}
                              currentUser={this.props.currentUser}
                              history={this.props.history}
                              customClassname={
                                this.state.array.includes(content.content_uuid)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              pageSource="searchAndSelect"
                              enableEdit={false}
                              onEditClick={() => {}}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "CONTEST" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="contest-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (content, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={content.contest_id}
                            onClick={() => {
                              return this._callback(content.contest_id)
                            }}
                          >
                            <ContestCard
                              contest={content}
                              customClassname={
                                this.state.array.includes(content.contest_id)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                              pageSource="searchAndSelect"
                              key={content.contest_id}
                              enableEdit={false}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "GIFT" &&
                  this.state.searchAndSelectData?.data?.length > 0 && (
                    <div className="discovery-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (gift, index) => (
                          <div
                            className="mx-3 discovery-card-container"
                            style={{ width: "100%" }}
                            key={gift.gift_element_uuid}
                            onClick={() => {
                              return this._callback(gift.gift_element_uuid)
                            }}
                          >
                            <GiftCard
                              data={gift}
                              key={gift.gift_element_uuid}
                              onEditClick={() => {}}
                              customClassname={
                                this.state.array.includes(
                                  gift.gift_element_uuid
                                )
                                  ? "border-4 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "PACKAGE" &&
                  this.state.searchAndSelectData?.data?.length > 0 && (
                    <div className="discovery-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (packageElement, index) => (
                          <div
                            className="mx-3 discovery-card-container"
                            style={{ width: "100%" }}
                            key={packageElement.package_element_uuid}
                            onClick={() => {
                              return this._callback(
                                packageElement.package_element_uuid
                              )
                            }}
                          >
                            <PackageCard
                              data={packageElement}
                              key={packageElement.package_element_uuid}
                              onEditClick={() => {}}
                              customClassname={
                                this.state.array.includes(
                                  packageElement.package_element_uuid
                                )
                                  ? "border-4 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "COMBINATION" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="trending-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (cameraAsset, index) => {
                          return (
                            <div
                              className="mx-3"
                              style={{ width: "100%" }}
                              key={cameraAsset.combo_id}
                              onClick={() => {
                                return this._callback(cameraAsset.combo_id)
                              }}
                            >
                              <CameraAssetCard
                                cameraAsset={cameraAsset}
                                customClassname={
                                  this.state.array.includes(
                                    cameraAsset.combo_id
                                  )
                                    ? "border-2 border-success"
                                    : ""
                                }
                                key={`${index}_${cameraAsset.title}`}
                                editClick={(cameraAsset, type) =>
                                  this.editClick(cameraAsset, type)
                                }
                                currentUser={this.props.currentUser}
                                history={this.props.history}
                                pageSource="searchAndSelect"
                                onModerationClick={() => {}}
                                onEditClick={() => {}}
                              />
                            </div>
                          )
                        }
                      )}
                    </div>
                  )}
                {this.props.type === "HASHTAG" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="hashtag-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (hashtag, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={hashtag.name}
                            onClick={() => {
                              return this._callback(hashtag.name)
                            }}
                          >
                            <HashtagCard
                              data={hashtag}
                              customClassname={
                                this.state.array.includes(hashtag.name)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                              pageSource="searchAndSelect"
                              key={hashtag.name}
                              enableEdit={false}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "MUSIC" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="trending-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (audio, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={audio.id}
                            onClick={() => {
                              return this._callback(audio.id)
                            }}
                          >
                            <AudioCard
                              audio={audio}
                              customClassname={
                                this.state.array.includes(audio.id)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                              pageSource="searchAndSelect"
                              key={audio.id}
                              enableEdit={false}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "AUDIO_STORY" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="trending-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (story, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={story.story_id}
                            onClick={() => {
                              return this._callback(story.story_id)
                            }}
                          >
                            <AudioStoryCard
                              audio={story}
                              customClassname={
                                this.state.array.includes(story.story_id)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                              pageSource="searchAndSelect"
                              key={story.story_id}
                              enableEdit={false}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {this.props.type === "AUDIO_SERIES" &&
                  this.state.searchAndSelectData.data.length > 0 && (
                    <div className="trending-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (story, index) => (
                          <div
                            className="mx-3"
                            style={{ width: "100%" }}
                            key={story.series_id}
                            onClick={() => {
                              return this._callback(story.series_id)
                            }}
                          >
                            <AudioStoryCard
                              audio={story}
                              customClassname={
                                this.state.array.includes(story.series_id)
                                  ? "border-2 border-success"
                                  : ""
                              }
                              currentUser={this.props.currentUser}
                              pageSource="searchAndSelect"
                              key={story.series_id}
                              enableEdit={false}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                {[
                  "MUSIC_ARTIST",
                  "MUSIC_PLAYLIST",
                  "MUSIC_LABEL",
                  "BANNER",
                  "SUBBANNER",
                  "NAMED_COLLECTION",
                  "VIDEO_BANNER",
                  "WEBVIEW_BANNER",
                  "CHIP",
                  "MUSIC",
                ].includes(this.props.type) && (
                  <RenderEntityCards
                    cardData={this.state.searchAndSelectData.data}
                    callback={data => this._callback(data)}
                    selectedData={this.state.array}
                    type={this.props.type}
                  />
                )}

                {this.props.type == "STICKER" && (
                  <RenderStickerCards
                    cardData={this.state.searchAndSelectData.data}
                    callback={data => this._callback(data)}
                    selectedData={this.state.array}
                    type={this.props.type}
                  />
                )}

                {this.props.type === "STICKERSUBTYPE" && (
                  <RenderStickerSubType
                    cardData={this.state.searchAndSelectData.data.data}
                    callback={data => this._callback(data)}
                    selectedData={this.state.array}
                    type={this.props.type}
                  />
                )}
                {this.props.type === "STICKERS" && (
                  <RenderStickers
                    cardData={this.state.searchAndSelectData.data.data}
                    callback={data => this._callback(data)}
                    selectedData={this.state.array}
                    type={this.props.type}
                  />
                )}

                {this.props.type === "mixed" && this.props.assets && (
                  <RenderAssetType
                    assets={this.state.searchAndSelectData.data.data}
                    callback={data => this._assetCallback(data)}
                    selectedData={this.state.array}
                  />
                )}
                {this.props.type == "EFFECT" && (
                  <RenderEffectCards
                    cardData={this.state.searchAndSelectData.data}
                    callback={data => this._callback(data)}
                    selectedData={this.state.array}
                    type={this.props.type}
                  />
                )}
                {this.props.type == "TEMPLATE" && (
                  <RenderTemplateCard
                    cardData={this.state.searchAndSelectData.data}
                    callback={data => this._callback(data)}
                    selectedData={this.state.array}
                    type={this.props.type}
                  />
                )}
                {this.props.type === "TEMPLATE_SECTION" &&
                  this.state.searchAndSelectData?.data?.length > 0 && (
                    <div className="discovery-card-container">
                      {this.state.searchAndSelectData.data.map(
                        (template, index) => (
                          <div
                            className="mx-3 discovery-card-container"
                            style={{ width: "100%" }}
                            key={template.template_uuid}
                            onClick={() => {
                              return this._callback(template.template_uuid)
                            }}
                          >
                            <TemplateCard
                              data={template}
                              key={template.template_uuid}
                              onEditClick={() => {}}
                              customClassname={
                                this.state.array.includes(
                                  template.template_uuid
                                )
                                  ? "border-4 border-success"
                                  : ""
                              }
                              hideButton={true}
                              currentUser={this.props.currentUser}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}

                {this.props.type == "GAME" && (
                  <RenderGameCard
                    cardData={this.state.searchAndSelectData.data}
                    callback={data => this._callback(data)}
                    selectedData={this.state.array}
                    type={this.props.type}
                  />
                )}

                {this.props.type === "feed-collection" && (
                  <React.Fragment>
                    <Row className="d-flex justify-content-end py-2">
                      <Col>
                        <Input.Group compact={true} className="my-1">
                          <Input
                            defaultValue={this.state.filters["title"]}
                            placeholder={"Collection Title"}
                            onChange={e =>
                              this._handleFilterChange(e.target.value, "title")
                            }
                            allowClear={true}
                            name="title"
                            style={{ width: "100%" }}
                          />
                        </Input.Group>
                      </Col>
                      <Col className="my-1 mx-2">
                        <Button
                          style={{
                            backgroundColor: "#556ee6",
                            color: "white",
                          }}
                          className="d-flex"
                          onClick={() => {
                            this._getData()
                          }}
                        >
                          <span>
                            <i className="fa fa-search font-size-16 align-middle me-2"></i>
                          </span>
                          <div className="mx-2">Search</div>
                        </Button>
                      </Col>
                      <Col className="my-1">
                        <Button
                          style={{
                            backgroundColor: "#556ee6",
                            color: "white",
                          }}
                          onClick={this.onAddClick}
                        >
                          <span>
                            <i className="fa fa-plus font-size-16 align-middle me-2"></i>
                          </span>
                          Add
                        </Button>
                      </Col>
                    </Row>
                    {this._getSortedList() &&
                      this._getSortedList().length > 0 && (
                        <div className="hashtag-card-container">
                          {this._getSortedList().map(data => {
                            return (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this._callback({
                                    [`${data.collectionId}:${data.collectionType}`]:
                                      data,
                                  })
                                }
                              >
                                <Card
                                  className={`${
                                    Object.keys(this.state.array).includes(
                                      `${data.collectionId}:${data.collectionType}`
                                    )
                                      ? "border-2 border-success"
                                      : ""
                                  } text-center shadow-lg`}
                                  style={{
                                    height: "314px",
                                    borderRadius: "10px",
                                    backgroundColor: "",
                                  }}
                                >
                                  {data.thumbnail ||
                                  data.profile_pic ||
                                  data.banner_url ||
                                  data.web_url ||
                                  data.sticker_thumbnail_url ||
                                  data.static_thumbnail ||
                                  data.cover_url ||
                                  data.album_art ||
                                  data.icon_url ? (
                                    <CardImg
                                      src={
                                        data.thumbnail ||
                                        data.profile_pic ||
                                        data.banner_url ||
                                        data.web_url ||
                                        data.sticker_thumbnail_url ||
                                        data.static_thumbnail ||
                                        data.cover_url ||
                                        data.album_art ||
                                        data.icon_url
                                      }
                                      style={{
                                        height: "200px",
                                        cursor: "pointer",
                                        opacity: "0.8",
                                        borderTopLeftRadius: "10px",
                                        borderTopRightRadius: "10px",
                                      }}
                                    />
                                  ) : (
                                    <div
                                      className="py-5"
                                      style={{
                                        height: "200px",
                                        cursor: "pointer",
                                        backgroundColor: "lightgray",
                                        borderTopLeftRadius: "10px",
                                        borderTopRightRadius: "10px",
                                      }}
                                    >
                                      <i
                                        className={`${getImage(
                                          data
                                        )} fa-8x text-black-50`}
                                      />
                                    </div>
                                  )}
                                  <CardBody className="font-size-16">
                                    {data["collection_format"] && (
                                      <Chip
                                        color="info"
                                        size="small"
                                        label={data["collection_format"]}
                                      />
                                    )}{" "}
                                    <br />
                                    {data["title"] ||
                                      data["heading_title"] ||
                                      data["hashtag"] ||
                                      data["name"] ||
                                      data["sticker_name"] ||
                                      data["asset_label"] ||
                                      data["collectionId"]}
                                  </CardBody>
                                </Card>
                              </div>
                            )
                          })}
                        </div>
                      )}
                  </React.Fragment>
                )}

                {["collection"].includes(this.props.type) && (
                  <div>
                    <Tabs
                      onChange={key => {
                        const newFilters = _.cloneDeep(this.state.filters)
                        newFilters["collectionType"] = key
                        newFilters["title"] = ""
                        this.setState(
                          {
                            filters: newFilters,
                            selectedTitle: undefined,
                            total: undefined,
                          },
                          () => this._getData()
                        )
                      }}
                      type="card"
                      activeKey={this.state.filters.collectionType}
                    >
                      {Object.keys(tabs).map(key => {
                        return (
                          <TabPane
                            key={key}
                            tab={
                              <span>
                                <span>
                                  <i
                                    className={`${COLLECTION_PROPERTIES[key].icon} mx-2`}
                                  />
                                </span>
                                <span className="mb-2">
                                  {COLLECTION_PROPERTIES[key].name}
                                </span>
                              </span>
                            }
                            className="font-size-15"
                          >
                            <Row className="d-flex justify-content-end py-2">
                              <Col>
                                <Input.Group compact={true} className="my-1">
                                  <Input
                                    defaultValue={this.state.filters["title"]}
                                    placeholder={"Collection Title"}
                                    onChange={e =>
                                      this._handleFilterChange(
                                        e.target.value,
                                        "title"
                                      )
                                    }
                                    allowClear={true}
                                    name="title"
                                    style={{ width: "100%" }}
                                  />
                                </Input.Group>
                              </Col>
                              <Col className="my-1 mx-2">
                                <Button
                                  style={{
                                    backgroundColor: "#556ee6",
                                    color: "white",
                                  }}
                                  className="d-flex"
                                  onClick={() => {
                                    this._getData()
                                  }}
                                >
                                  <span>
                                    <i className="fa fa-search font-size-16 align-middle me-2"></i>
                                  </span>
                                  <div className="mx-2">Search</div>
                                </Button>
                              </Col>
                              <Col className="my-1">
                                <Button
                                  style={{
                                    backgroundColor: "#556ee6",
                                    color: "white",
                                  }}
                                  onClick={this.onAddClick}
                                >
                                  <span>
                                    <i className="fa fa-plus font-size-16 align-middle me-2"></i>
                                  </span>
                                  Add
                                </Button>
                              </Col>
                            </Row>
                            {this._getSortedList() &&
                              this._getSortedList().length > 0 && (
                                <div className="hashtag-card-container">
                                  {this._getSortedList().map(data => {
                                    return (
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this._callback({
                                            [`${data.collectionId}:${data.collectionType}`]:
                                              data,
                                          })
                                        }
                                      >
                                        <Card
                                          className={`${
                                            Object.keys(
                                              this.state.array
                                            ).includes(
                                              `${data.collectionId}:${data.collectionType}`
                                            )
                                              ? "border-2 border-success"
                                              : ""
                                          } text-center shadow-lg`}
                                          style={{
                                            height: "314px",
                                            borderRadius: "10px",
                                            backgroundColor: "",
                                          }}
                                        >
                                          {data.thumbnail ||
                                          data.profile_pic ||
                                          data.banner_url ||
                                          data.web_url ||
                                          data.sticker_thumbnail_url ||
                                          data.static_thumbnail ||
                                          data.cover_url ||
                                          data.album_art ||
                                          data.icon_url ? (
                                            <CardImg
                                              src={
                                                data.thumbnail ||
                                                data.profile_pic ||
                                                data.banner_url ||
                                                data.web_url ||
                                                data.sticker_thumbnail_url ||
                                                data.static_thumbnail ||
                                                data.cover_url ||
                                                data.album_art ||
                                                data.icon_url
                                              }
                                              style={{
                                                height: "200px",
                                                cursor: "pointer",
                                                opacity: "0.8",
                                                borderTopLeftRadius: "10px",
                                                borderTopRightRadius: "10px",
                                              }}
                                            />
                                          ) : (
                                            <div
                                              className="py-5"
                                              style={{
                                                height: "200px",
                                                cursor: "pointer",
                                                backgroundColor: "lightgray",
                                                borderTopLeftRadius: "10px",
                                                borderTopRightRadius: "10px",
                                              }}
                                            >
                                              <i
                                                className={`${getImage(
                                                  data
                                                )} fa-8x text-black-50`}
                                              />
                                            </div>
                                          )}
                                          <CardBody className="font-size-16">
                                            {data["collection_format"] && (
                                              <Chip
                                                color="info"
                                                size="small"
                                                label={
                                                  data["collection_format"]
                                                }
                                              />
                                            )}{" "}
                                            <br />
                                            {data["title"] ||
                                              data["heading_title"] ||
                                              data["hashtag"] ||
                                              data["name"] ||
                                              data["sticker_name"] ||
                                              data["asset_label"] ||
                                              data["collectionId"]}
                                          </CardBody>
                                        </Card>
                                      </div>
                                    )
                                  })}
                                </div>
                              )}
                          </TabPane>
                        )
                      })}
                    </Tabs>
                  </div>
                )}
              </Auxiliary>
              {!this.props.hidePagination && (
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "100%" }}
                >
                  <Pagination
                    style={{ marginTop: "1rem" }}
                    onChange={this._onPageChange}
                    defaultCurrent={1}
                    total={
                      ["collection", "feed-collection"].includes(
                        this.props.type
                      ) && this.state.selectedTitle
                        ? this._getFilteredList().length
                        : this.state.searchAndSelectData.total &&
                          this.state.searchAndSelectData.total &&
                          this.state.searchAndSelectData.total.value
                        ? this.state.searchAndSelectData.total.value
                        : this.state.searchAndSelectData &&
                          this.state.searchAndSelectData.total
                        ? this.state.searchAndSelectData.total
                        : 1
                    }
                    pageSize={this.state.filters["pageSize"] || "12"}
                    showSizeChanger={false}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "history.location.search")
      ? QueryString.parseUrl(_.get(ownProps, "history.location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    assetTypes: _.get(store, "videoAssets.assetTypes"),
    refetchData: _.get(store, "videoAssets.refetchData"),
    assets: _.get(store, "videoAssets.assets"),
    mimeTypes: _.get(store, "stickers.mimeTypes"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default withRouter(connect(mapStateToProps)(SearchAndSelect))
