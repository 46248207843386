import Axios from "axios"
import toastr from "toastr"

export const createCommunityManager = async data => {
  try {
    let response = await Axios.post(`/api/community/manager/create`, data)
    return response
  } catch (error) {
    return error
  }
}

export const getLeads = async () => {
  try {
    let response = await Axios.get(`/api/community/getLeads`)
    return response
  } catch (error) {
    return error
  }
}

export const assignCommunityManager = async data => {
  try {
    let response = await Axios.post(`/api/community/manager/assign`, data)
    return response
  } catch (error) {
    return error
  }
}

export const updateCommunityUser = async data => {
  try {
    let response = await Axios.post(`/api/community/update`, data)
    return response
  } catch (error) {
    return error
  }
}

export const deleteUser = async data => {
  try {
    let response = await Axios.post(`/api/community/delete`, data)
    return response
  } catch (error) {
    return error
  }
}
export const bulkUploadCommunity = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/user/update/community`, formData)
    return data
  } catch (error) {
    toastr.error("Error in updating community")
    throw error
  }
}
export const downloadReport = async formData => {
  toastr.info("Downloading report...")
  try {
    let data = await Axios.post(`/api/reports/download`, formData)
    return data
  } catch (error) {
    toastr.error("Error in downloading report")
    throw error
  }
}
