import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"

const { Option } = Select

const DiscoveryPageDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Page Id</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["pageId"]}
                  placeholder={"Page Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "pageId")
                  }
                  allowClear={true}
                  name="pageId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Page Title</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["title"]}
                  placeholder={"Page Title"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "title")
                  }
                  allowClear={true}
                  name="title"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Status</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Status"
            defaultValue={props.filters["status"]}
            onChange={value => {
              props.handleFilterChange(value, "status")
            }}
            style={{ width: "100%" }}
          >
            <Option value="">All</Option>
            <Option value="PUBLISHED">Published</Option>
            <Option value="DRAFT">Draft</Option>
            <Option value="REMOVED">Removed</Option>
          </Select>
        </Col>
        {/* <Col lg="3" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Page Type</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Page Type"
            defaultValue={props.filters["pageType"]}
            onChange={value => {
              props.handleFilterChange(value, "pageType")
            }}
            style={{ width: "100%" }}
          >
            <Option value="">All</Option>
            <Option value="DISCOVERY">DISCOVERY</Option>
            <Option value="AUDIO">AUDIO</Option>
            <Option value="FEED">FEED</Option>
          </Select>
        </Col> */}
      </Row>
    </React.Fragment>
  )
}

DiscoveryPageDrawer.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(DiscoveryPageDrawer)
