import Axios from "axios"
import toastr from "toastr"

export const requestTrendingEntityByFilters = async ({ payload }) => {
  try {
    const filters = payload
    const offset = payload["page"] ? payload["page"] - 1 : 0
    const limit = payload["pageSize"]
    _.unset(filters, "page")
    let data = await Axios.post(
      `/api/trending/getTrendingEntityByFilters?offset=${offset}&limit=${limit}`,
      { filters: payload }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestTrendingSourceData = async ({ payload }) => {
  try {
    const deskId = payload
    let data = await Axios.get(`/api/source/trending?deskId=${deskId}`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestEntityUpsert = async ({ payload }) => {
  try {
    const response = await Axios.post("/api/trending/upsert", payload)
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}

export const requestMarkTrending = async ({ payload }) => {
  try {
    const response = await Axios.post(
      `/api/trending/mark-trending?type=${payload.type}&entityId=${payload.entityId}`,
      { data: payload.data }
    )
    toastr.success("Action Successfull !!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
