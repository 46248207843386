export const YESTERDAY = "yesterday"
export const LAST_7_DAYS = "l7"
export const LAST_15_DAYS = "l15"
export const LAST_30_DAYS = "l30"
export const LAST_POST_DATE = "lastPostDate"
export const LAST_QUARTER = "lq"

export const LAST_100_VIEWCOUNT = "v100"
export const LAST_1000_VIEWCOUNT = "v1000"
export const LAST_10000_VIEWCOUNT = "v10000"
export const LAST_100000_VIEWCOUNT = "v100000"

export const dayTimespanMap = {
  [YESTERDAY]: 1,
  [LAST_7_DAYS]: 7,
  [LAST_15_DAYS]: 15,
  [LAST_30_DAYS]: 30,
  [LAST_POST_DATE]: 50,
  [LAST_QUARTER]: 90,
}

export const viewMap = {
  [LAST_100_VIEWCOUNT]: 100,
  [LAST_1000_VIEWCOUNT]: 1000,
  [LAST_10000_VIEWCOUNT]: 10000,
  [LAST_100000_VIEWCOUNT]: 100000,
}

export const POD = "pod"
export const LANGUAGE = "language"
export const GENRE = "genre"
export const VIEWS = "views"

export const keyLabelMap = {
  totalPostCount: "Total Content",
  totalFeedEligiblePostCount: "Feed Eligible",
  totalCleanPostCount: "Clean Content",
  totalCleanPostViewGT0Count: "Clean Content (View > 0)",
  totalCleanPostViewGT100Count: "Clean Content (View > 100)",
  totalCleanPostViewGT500Count: "Clean Content (View > 500)",
  totalCleanPostViewGT1000Count: "Clean Content (View > 1000)",
}

export const keyTextMap = {
  totalPostCount: "Total Content",
  totalFeedEligiblePostCount: "Feed Eligible",
  totalCleanPostCount: "Clean Content",
  totalCleanPostViewGT0Count: "Clean Content<br>(View > 0)",
  totalCleanPostViewGT100Count: "Clean Content<br>(View > 100)",
  totalCleanPostViewGT500Count: "Clean Content<br>(View > 500)",
  totalCleanPostViewGT1000Count: "Clean Content<br>(View > 1000)",
}
