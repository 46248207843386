import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { Upload } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Row, Col, Button } from "reactstrap"
import ValidatorForm, {
  TextField,
  Select,
  TextArea,
  Switch,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { createAppUser } from "appRedux/slices/appUser"
import { LANGUAGES, creatorProfileV2 } from "../../constants/uiConstants"

class CreateAppUserForm extends ErrorBoundComponent {
  _uploadProfileImage = fileObj => {
    if (fileObj.file) {
      this.setState({
        profileImageFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        profileImageFile: fileObj.file,
      })
    }
  }

  _onProfileImageRemove = () => {
    this.setState({
      profileImageFileList: [],
      profileImageFile: null,
    })
  }

  componentDidMount() {
    this.setState({
      jotUserType:
        this.props.formAction == "edit"
          ? _.get(this.props, "userObj.user_type")
          : " ",
    })
  }

  _form = () => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row gutter={16}>
          <Col lg="6">
            <TextField
              //TODO
              label={"Name"}
              defaultValue={
                this.props.formAction == "edit"
                  ? _.get(this.props, "userObj.name")
                  : " "
              }
              placeholderLabel="Name of the User"
              field={"name"}
              maxLength="50"
              allowClear={true}
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          {this.props.formAction == "edit" ? (
            ""
          ) : (
            <Col lg="6">
              <TextField
                addonBefore="+91"
                placeholderLabel="Phone Number"
                defaultValue={
                  this.props.formAction == "edit"
                    ? _.get(this.props, "userObj.mobile_no")
                    : " "
                }
                disabled={this.props.formAction == "edit" ? true : false}
                label={"Phone"}
                field="mobile_no"
                className="creator-form"
                mask="9999999999"
              />
            </Col>
          )}
          <Col lg="6">
            <TextField
              //TODO
              label={"Handle"}
              defaultValue={
                this.props.formAction == "edit"
                  ? _.get(this.props, "userObj.user_name")
                  : " "
              }
              placeholderLabel="UserName identified as Handle for User"
              className="creator-form"
              field={"username"}
              maxLength="30"
              allowClear={true}
            />
          </Col>

          {this.props.formAction == "edit" ? (
            ""
          ) : (
            <Col lg="6">
              <TextField
                //TODO
                label={"Email"}
                defaultValue={
                  this.props.formAction == "edit"
                    ? _.get(this.props, "userObj.email")
                    : " "
                }
                placeholderLabel="Email"
                className="creator-form"
                field={"email"}
                allowClear={true}
                // validations={["email"]}
                // errors={["Please Enter a Valid Email"]}
              />
            </Col>
          )}

          {this.props.formAction == "edit" ? (
            ""
          ) : (
            <Col lg="6">
              <Select
                label={"Language"}
                defaultValue={
                  this.props.formAction == "edit"
                    ? _.get(this.props, "userObj.lang_code")
                    : " "
                }
                disabled={this.props.formAction == "edit" ? true : false}
                placeholderLabel="Select a Language"
                field="lang_code"
                className="creator-form"
                options={LANGUAGES}
                allowClear={true}
              />
            </Col>
          )}
          {
            <Col lg="6">
              <Select
                //TODO
                label={"Gender"}
                defaultValue={
                  this.props.formAction == "edit"
                    ? _.get(this.props, "userObj.gender") == 1
                      ? "male"
                      : _.get(this.props, "userObj.gender") === 0
                      ? "female"
                      : ""
                    : ""
                }
                placeholderLabel=""
                field="gender"
                className="creator-form"
                options={[
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                  { label: "Others", value: "others" },
                ]}
                allowClear={true}
              />
            </Col>
          }
          {
            <Col lg="6">
              <Select
                label={"User Type"}
                defaultValue={
                  this.props.formAction == "edit"
                    ? _.get(this.props, "userObj.user_type")
                    : " "
                }
                disabled={this.props.formAction == "edit" ? true : false}
                placeholderLabel=""
                field="type"
                style={{ width: "100%" }}
                className="creator-form"
                options={
                  this.props.currentUser.jotUserType === 6
                    ? [
                        { value: "ib", label: "Internal Brand" },
                        { value: "eb", label: "External Brand" },
                      ]
                    : this.props.currentUser.jotUserType < 3
                    ? [
                        { value: "g", label: "Ghost" },
                        { value: "i", label: "Internal" },
                        { value: "e", label: "External" },
                        { value: "ib", label: "Internal Brand" },
                        { value: "eb", label: "External Brand" },
                      ]
                    : [
                        { value: "g", label: "Ghost" },
                        { value: "i", label: "Internal" },
                        { value: "e", label: "External" },
                      ]
                }
                onChange={jotUserType => this.setState({ jotUserType })}
                allowClear={true}
              />
            </Col>
          }
          <Col lg="6">
            <Select
              label={"Creator Profile V2"}
              defaultValue={
                this.props.formAction == "edit"
                  ? _.get(this.props, "userObj.creator_profile_v2")
                  : null
              }
              disabled={this.props.formAction == "edit" ? true : false}
              placeholderLabel=""
              field="creator_profile_v2"
              style={{ width: "100%" }}
              className="creator-form"
              options={creatorProfileV2}
              allowClear={true}
            />
          </Col>
          <Col lg="6">
            <TextArea
              //TODO
              defaultValue={
                this.props.formAction == "edit"
                  ? _.get(this.props, "userObj.bio_data")
                  : " "
              }
              label={"User Bio Information"}
              placeholderLabel="User Bio / Description"
              className="creator-form"
              field={"bio_data"}
            />
          </Col>
          {(this.props.currentUser.jotUserType === 6 ||
            (this.props.currentUser.jotUserType < 3 &&
              ["ib", "eb"].includes(this.state.jotUserType))) && (
            <>
              <Col lg="6">
                <TextField
                  //TODO
                  label={"Sponsored Text"}
                  defaultValue={
                    this.props.formAction == "edit"
                      ? _.get(this.props, "userObj.sponsored_text")
                      : " "
                  }
                  placeholderLabel="Sponsored Text"
                  field={"sponsored_text"}
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>

              <Col lg="2">
                <Switch
                  //TODO
                  defaultValue={
                    this.props.formAction == "edit"
                      ? _.get(this.props, "userObj.hide_stats") == "Y"
                        ? true
                        : false
                      : false
                  }
                  label={"Hide Stats"}
                  field={"hide_stats"}
                />
              </Col>
              <Col lg="2">
                <Switch
                  //TODO
                  defaultValue={
                    this.props.formAction == "edit"
                      ? _.get(this.props, "userObj.allow_follow") == "Y"
                        ? true
                        : false
                      : false
                  }
                  label={"Allow Follow"}
                  field={"allow_follow"}
                />
              </Col>
            </>
          )}
          <Col lg="2">
            <Switch
              defaultValue={
                this.props.formAction == "edit"
                  ? _.get(this.props, "userObj.verified")
                  : this.props.currentUser.jotUserType === 6
                  ? true
                  : false
              }
              disabled={this.props.formAction == "edit" ? true : false}
              label={"Verified"}
              field={"verified"}
            />
          </Col>

          {this.props.formAction == "edit" && (
            <Col lg="6">
              <Select
                label={"Skip Masking"}
                defaultValue={
                  _.get(this.props, "userObj.skip_masking")
                    ? _.get(this.props, "userObj.skip_masking")
                    : "N"
                }
                field="skip_masking"
                className="creator-form"
                options={[
                  { label: "Yes", value: "Y" },
                  { label: "No", value: "N" },
                ]}
              />
            </Col>
          )}
          {this.props.formAction == "edit" && (
            <Col lg="6">
              <Select
                label="Skip Moderation"
                defaultValue={
                  _.get(this.props, "userObj.skip_moderation")
                    ? _.get(this.props, "userObj.skip_moderation")
                    : "N"
                }
                field="skip_moderation"
                className="creator-form"
                options={[
                  { label: "Yes", value: "Y" },
                  { label: "No", value: "N" },
                ]}
              />
            </Col>
          )}
          {this.props.formAction == "edit" && (
            <Col lg="6">
              <Select
                label="Paid"
                defaultValue={
                  _.get(this.props, "userObj.remunerate")
                    ? _.get(this.props, "userObj.remunerate")
                    : "N"
                }
                field="remunerate"
                className="creator-form"
                options={[
                  { label: "Yes", value: "Y" },
                  { label: "No", value: "N" },
                ]}
              />
            </Col>
          )}
          {this.props.formAction == "edit" && (
            <Col lg="6">
              <span>Profile Image</span>
              <br />
              <img
                src={this.props.userObj.profile_pic}
                style={{ width: "4rem", height: "4rem" }}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col style={{ marginTop: "10px" }}>
            <Upload
              showUploadList={true}
              onRemove={this._onProfileImageRemove}
              defaultFileList={this.state.profileImageFileList}
              fileList={this.state.profileImageFileList}
              customRequest={this._uploadProfileImage}
            >
              <Button color="primary">
                <i
                  className="bx bx-upload
me-2"
                />{" "}
                {this.props.formAction !== "edit"
                  ? `Click To Upload Profile Image`
                  : `Click to update Profile Image`}{" "}
              </Button>
            </Upload>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="d-flex justify-content-end">
              <Button
                htmlType="submit"
                color="primary"
                disabled={this.state.loading}
                className="font-16 btn-block btn btn-primary"
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                {/* <i className="bx bx-upload me-1" /> */}
                Submit
              </Button>
              <Button
                color="danger"
                size="default"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (this.props.formAction !== "edit" && !this.state.profileImageFile) {
      toastr.error("Please upload the profile picture !!")
      return
    }

    let jotUserType = formData["type"]
    if (
      this.props.currentUser.jotUserType === 6 ||
      (this.props.currentUser.jotUserType < 3 &&
        jotUserType &&
        ["ib", "eb"].includes(jotUserType))
    ) {
      formData.allow_follow = formData.allow_follow ? "Y" : "N"
      formData.hide_stats = formData.hide_stats ? "Y" : "N"
    }

    let data = new FormData()
    if (this.state.profileImageFile) {
      data.append("file", this.state.profileImageFile)
    }
    data.append("details", JSON.stringify(formData))
    this.props.dispatch(createAppUser(data))
    this.props.onCancel()
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(CreateAppUserForm)
