import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import { Tag, Pagination, Tooltip, message } from "antd"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { List, Typography, Select, Input } from "antd"
import { Button } from "@mui/material"
import toastr from "toastr"
import {
  getInterest,
  interestDetails,
  updateSubInterest,
} from "clientServices/interestServices"
import { MoreOutlined } from "@ant-design/icons"
import { Dropdown, Space } from "antd"
import { FaSeedling, FaShare } from "react-icons/fa"
import {
  MdDownloadForOffline,
  MdModeEditOutline,
  MdFlaky,
} from "react-icons/md"
import { AiTwotoneHeart, AiFillEye, AiOutlineStop } from "react-icons/ai"
import { BiSolidCloudUpload } from "react-icons/bi"
import { TiTick } from "react-icons/ti"
import Fuse from "fuse.js"
import { formatNumber } from "../../util/common"
import { Link, withRouter } from "react-router-dom"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { AiOutlineCopy } from "react-icons/ai"
const { Option } = Select
class ColdStartList extends ErrorBoundComponent {
  state = {
    data: [],
    filterdData: [],
    filter: {
      sort: "desc",
      mode: "",
      type: "",
      metric: "absolute",
    },
    subInterestStatusList: {},
  }
  constructor(props) {
    super(props)
    this.searchFn = _.debounce(this.searchFn, 1000)
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      this.setState({
        loading: true,
      })
      const interestdata = await getInterest({
        mode: this.props.mode,
        sort: "desc",
      })
      const subinterestdata = await getInterest({
        entity_type: "sub_interest",
        is_cold_start: true,
      })

      let subintereststatus = {}
      for (let j = 0; j < subinterestdata?.result?.data?.length; j++) {
        subintereststatus[subinterestdata?.result?.data[j].id] = {
          status: subinterestdata?.result?.data[j].is_active,
          name: subinterestdata?.result?.data[j].name,
          id: subinterestdata?.result?.data[j].id,
        }
      }

      //to get filtered interest with cold start subinterestes
      // const filteresColdstartInterest={
      //   result:{
      //     data:[]
      //   }
      // }
      //       for (let i = 0; i < interestdata?.result?.data?.length; i++) {
      //         for(let j=0;j<interestdata?.result?.data[i].subInterests.length ; j++){
      //           if(subintereststatus[interestdata?.result?.data[i].subInterests[j]]){
      //             filteresColdstartInterest.result.data
      //           }
      //           else{

      //           }
      //         }
      //       }

      //to get likes,share data
      let interestDetailspayload = {}
      for (let i = 0; i < interestdata?.result?.data?.length; i++) {
        for (
          let j = 0;
          j < interestdata?.result?.data[i].subInterests.length;
          j++
        ) {
          if (
            subintereststatus[interestdata?.result?.data[i].subInterests[j]]
          ) {
            if (interestDetailspayload[interestdata?.result?.data[i].id]) {
              interestdata.result.data[i].is_cold = true

              interestDetailspayload[interestdata?.result?.data[i].id].push(
                interestdata?.result?.data[i].subInterests[j]
              )
            } else {
              interestdata.result.data[i].is_cold = true
              interestDetailspayload[interestdata?.result?.data[i].id] = []
              interestDetailspayload[interestdata?.result?.data[i].id].push(
                interestdata?.result?.data[i].subInterests[j]
              )
            }
          }
        }
        // interestDetailspayload[interestdata?.result?.data[i].id] = {}
        // interestDetailspayload[interestdata?.result?.data[i].id] =
        //   interestdata?.result?.data[i].subInterests
      }
      let interestDetails2 = {}
      interestDetails2 = await interestDetails(interestDetailspayload)

      const data = interestdata?.result?.data || []

      data.forEach(interest => {
        let subInterests = interest.subInterests
        subInterests = subInterests.map(
          subInterest => subintereststatus[subInterest]
        )
        interest.subInterestsWithName = subInterests
      })

      this.setState({
        data,
        filter: {
          mode: this.props.mode,
          sort: "desc",
          type: "",
          metric: "absolute",
        },
        filterdData: interestdata?.result?.data || [],
        subInterestStatusList: subintereststatus || {},
        interestDetail: interestDetails2 || {},
        loading: false,
      })

      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    // this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._onSearch()
      }
    )
  }

  _manageSeedVideos = data => {
    this.props.toggleManageSeedVideos({
      currentInterest: data.interest,
      currentSubInterest: data.subInterest,
      subInterestName: data.subInterestName,
    })
  }
  _manageTabAddVideos = data => {
    this.props.toggleAddVideos({
      currentInterest: data.interest,
      currentSubInterest: data.subInterest,
      subInterestName: data.subInterestName,
    })
  }

  _manageReviewVideos = data => {
    this.props.toggleManageReviewVideos({
      currentInterest: data.interest,
      currentSubInterest: data.subInterest,
      subInterestName: data.subInterestName,
    })
  }

  _manageAddVideos = data => {
    this.props.toggleManageAddVideos({
      currentInterest: data.interest,
      currentSubInterest: data.subInterest,
    })
  }

  handleFilterChange = async (val, type) => {
    let filter = { ...this.state.filter }
    filter[type] = val

    filter["searchInterest"] = ""

    let interestdata = await getInterest(filter)
    if (type == "sort" && val == "disabled") {
      for (let i = 0; i < interestdata?.result?.data.length; i++) {
        const newsub = interestdata?.result?.data[i].subInterests.filter(
          ite => this.state.subInterestStatusList[ite].status == false
        )

        interestdata["result"]["data"][i]["subInterests"] = newsub
        // for(let j=0;j<interestdata?.result?.data[i].subInterests.length;j++){
        //   if(this.state.subInterestStatusList[interestdata?.result?.data[i].subInterests[j]].status){

        //   }
        // }
      }
    }
    interestdata["result"]["data"] = interestdata?.result?.data.filter(
      item => item.subInterests.length != 0
    )

    this.setState({
      filter,
      filterdData: interestdata?.result?.data || [],
    })
  }

  searchFn = async (val, type) => {
    let filter = { ...this.state.filter }
    filter[type] = val

    if (!val) {
      this.setState({
        filterdData: this.state.data,
      })
      return
    }

    const options = {
      shouldSort: true,
      // findAllMatches: true,
      // includeMatches: false,
      threshold: 0.4,
      // location: 0,
      // distance: 50,
      // maxPatternLength: 32,
      // useExtendedSearch: true,
      // minMatchCharLength: 2,
      keys: ["name", "subInterestsWithName.name"],
    }

    // console.log(this.state.data)

    const fuse = new Fuse(this.state.data, options)
    let data = fuse.search(`=${val}`)

    // const interestdata = await getInterest(filter)
    data = data.map(data => data.item)
    this.setState({
      filterdData: data || [],
    })
  }

  _renderSearch = () => {
    return (
      <Input
        // defaultValue={props.filters["audioId"]}
        placeholder={"Search Interest.."}
        size="large"
        onChange={e => this.searchFn(e.target.value, "searchInterest")}
        allowClear={true}
        name="searchInterest"
        value={this.state.filter.searchInterest}
        style={{ width: "100%" }}
      />
    )
  }
  _renderFilters = () => {
    return (
      <Row>
        <Col lg="3" md="3"></Col>
        <Col lg="3" md="3">
          <Select
            placeholder="Type"
            defaultValue={this.state.filter["type"]}
            style={{ width: "100%" }}
            onChange={value => {
              this.handleFilterChange(value, "type")
              //handleNameFilter(value)
            }}
            size="large"
          >
            <Option value="">All</Option>
            <Option value="category">Category</Option>
            <Option value="trend">Trend</Option>
            <Option value="event">Event</Option>
          </Select>
        </Col>
        <Col lg="3" md="3">
          <Select
            placeholder="Sort"
            style={{ width: "100%" }}
            defaultValue={this.state.filter["sort"]}
            onChange={value => {
              this.handleFilterChange(value, "sort")
              //handleNameFilter(value)
            }}
            size="large"
          >
            <Option value="desc">Latest</Option>
            <Option value="asc">Oldest</Option>
            <Option value="active">Active</Option>
            <Option value="disabled">Disabled</Option>
          </Select>
        </Col>
        <Col lg="3" md="3">
          <Select
            placeholder="Metric Value"
            style={{ width: "100%" }}
            defaultValue={this.state.filter["metric"]}
            onChange={value => {
              this.handleFilterChange(value, "metric")
              //handleNameFilter(value)
            }}
            size="large"
          >
            <Option value="absolute">Absolute</Option>
            <Option value="vp">VP</Option>
          </Select>
        </Col>
      </Row>
    )
  }
  _pagination = () => {
    return (
      <div className="d-flex justify-challenge-end">
        <Pagination
          style={{ marginTop: "1rem" }}
          onChange={this._onPageChange}
          current={parseInt(this.state.filters["currentPage"], 10)}
          defaultCurrent={parseInt(this.state.filters["currentPage"], 10)}
          total={this.props.total > 10000 ? 10000 : this.props.total}
          showSizeChanger={false}
        />
      </div>
    )
  }

  onChange = key => {
    console.log(key)
  }
  debounce = (func, wait) => {
    let timeout

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }
  render() {
    return (
      <Auxiliary
        loading={this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        <Row>
          <Col lg="12" md="12">
            {this._renderSearch()}
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg="6" md="12">
            <h4>My Watches</h4>
          </Col>
          <Col lg="6" md="12">
            {this._renderFilters()}
          </Col>
        </Row>
        {/* <Divider orientation="left">My Watches</Divider> */}

        {this.state.filterdData.map((item1, index) => (
          <>
            {item1.is_cold == true && (
              <List
                style={{ width: "100%", margin: "15px 0px" }}
                bordered
                dataSource={item1.subInterests}
                renderItem={(item, index) => (
                  <>
                    {index == 0 && item1.is_cold == true ? (
                      <List.Item style={{ width: "100%" }}>
                        <Row gutter={12} style={{ width: "100%" }}>
                          {" "}
                          <Col lg="4" md="8">
                            <b>{item1.name}</b>{" "}
                            <CopyToClipboard
                              text={item1.id}
                              onCopy={e => {
                                toastr.success("Copied sucessfully!")
                              }}
                            >
                              <Tooltip title="Copy Interest ID">
                                <i className="bx bx-copy" role="button" />
                              </Tooltip>
                            </CopyToClipboard>
                            <br></br>
                          </Col>
                          <Col
                            lg="3"
                            md="8"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "21px",
                              color: "grey",
                              alignItem: "center",
                            }}
                          >
                            <div>
                              {" "}
                              <AiFillEye />{" "}
                              <span style={{ fontSize: "15px" }}>
                                {this.state.filter.metric == "absolute"
                                  ? formatNumber(
                                      this.state.interestDetail[item1.id]
                                        ?.view_count_sum?.value,
                                      1
                                    ) || 0
                                  : this.state.interestDetail[item1.id]
                                      ?.fk_view_count_sum?.value
                                  ? `${(
                                      (this.state.interestDetail[item1.id]
                                        ?.view_count_sum?.value *
                                        100) /
                                      this.state.interestDetail[item1.id]
                                        ?.fk_view_count_sum?.value
                                    ).toFixed(2)}%`
                                  : "-"}
                              </span>
                            </div>
                            <div>
                              <AiTwotoneHeart style={{ color: "grey" }} />{" "}
                              <span style={{ fontSize: "15px" }}>
                                {this.state.filter.metric == "absolute"
                                  ? formatNumber(
                                      this.state.interestDetail[item1.id]
                                        ?.like_count_sum?.value,
                                      1
                                    ) || 0
                                  : this.state.interestDetail[item1.id]
                                      ?.fk_like_count_sum?.value
                                  ? `${(
                                      (this.state.interestDetail[item1.id]
                                        ?.like_count_sum?.value *
                                        100) /
                                      this.state.interestDetail[item1.id]
                                        ?.fk_like_count_sum?.value
                                    ).toFixed(2)}%`
                                  : "-"}
                              </span>
                            </div>

                            <div>
                              {" "}
                              <FaShare />
                              <span style={{ fontSize: "15px" }}>
                                {" "}
                                {this.state.filter.metric == "absolute"
                                  ? formatNumber(
                                      this.state.interestDetail[item1.id]
                                        ?.share_count_sum?.value,
                                      1
                                    ) || 0
                                  : this.state.interestDetail[item1.id]
                                      ?.fk_share_count_sum?.value
                                  ? `${(
                                      (this.state.interestDetail[item1.id]
                                        ?.share_count_sum?.value *
                                        100) /
                                      this.state.interestDetail[item1.id]
                                        ?.fk_share_count_sum?.value
                                    ).toFixed(2)}%`
                                  : "-"}
                              </span>
                            </div>
                            <div>
                              {" "}
                              <MdDownloadForOffline />
                              <span style={{ fontSize: "15px" }}>
                                {" "}
                                {this.state.filter.metric == "absolute"
                                  ? formatNumber(
                                      this.state.interestDetail[item1.id]
                                        ?.download_count_sum?.value,
                                      1
                                    ) || 0
                                  : this.state.interestDetail[item1.id]
                                      ?.fk_download_count_sum?.value
                                  ? `${(
                                      (this.state.interestDetail[item1.id]
                                        ?.download_count_sum?.value *
                                        100) /
                                      this.state.interestDetail[item1.id]
                                        ?.fk_download_count_sum?.value
                                    ).toFixed(2)}%`
                                  : "-"}
                              </span>
                            </div>
                          </Col>
                          <Col lg="5" md="2" style={{ textAlign: "right" }}>
                            <b>
                              {item1["type"].charAt(0).toUpperCase() +
                                item1["type"].slice(1).toLowerCase()}
                            </b>
                          </Col>
                        </Row>
                      </List.Item>
                    ) : (
                      ""
                    )}
                    {this.state.subInterestStatusList[item]?.name && (
                      <List.Item style={{ width: "100%" }}>
                        <Row gutter={12} style={{ width: "100%" }}>
                          <Col lg="4" md="8" style={{ paddingTop: "5px" }}>
                            <Typography.Text mark></Typography.Text>{" "}
                            {this.state.subInterestStatusList[item]?.name}{" "}
                            <CopyToClipboard
                              text={this.state.subInterestStatusList[item]?.id}
                              onCopy={e => {
                                toastr.success("Copied sucessfully!")
                              }}
                            >
                              <Tooltip title="Copy Sub Interest ID">
                                <i className="bx bx-copy" role="button" />
                              </Tooltip>
                            </CopyToClipboard>
                          </Col>
                          <Col
                            lg="3"
                            md="8"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "21px",
                              color: "grey",
                              alignItem: "center",
                            }}
                          >
                            <div>
                              {" "}
                              <AiFillEye />{" "}
                              <span style={{ fontSize: "15px" }}>
                                {this.state.filter.metric == "absolute"
                                  ? formatNumber(
                                      this.state.interestDetail[item]
                                        ?.view_count_sum?.value,
                                      1
                                    ) || 0
                                  : this.state.interestDetail[item.id]
                                      ?.fk_view_count_sum?.value
                                  ? `${(
                                      (this.state.interestDetail[item]
                                        ?.view_count_sum?.value *
                                        100) /
                                      this.state.interestDetail[item]
                                        ?.fk_view_count_sum?.value
                                    ).toFixed(2)}%`
                                  : "-"}
                              </span>
                            </div>
                            <div>
                              <AiTwotoneHeart style={{ color: "grey" }} />{" "}
                              <span style={{ fontSize: "15px" }}>
                                {this.state.filter.metric == "absolute"
                                  ? formatNumber(
                                      this.state.interestDetail[item]
                                        ?.like_count_sum?.value,
                                      1
                                    ) || 0
                                  : this.state.interestDetail[item.id]
                                      ?.fk_like_count_sum?.value
                                  ? `${(
                                      (this.state.interestDetail[item]
                                        ?.like_count_sum?.value *
                                        100) /
                                      this.state.interestDetail[item]
                                        ?.fk_like_count_sum?.value
                                    ).toFixed(2)}%`
                                  : "-"}
                              </span>
                            </div>

                            <div>
                              {" "}
                              <FaShare />
                              <span style={{ fontSize: "15px" }}>
                                {" "}
                                {this.state.filter.metric == "absolute"
                                  ? formatNumber(
                                      this.state.interestDetail[item]
                                        ?.share_count_sum?.value,
                                      1
                                    ) || 0
                                  : this.state.interestDetail[item.id]
                                      ?.fk_share_count_sum?.value
                                  ? `${(
                                      (this.state.interestDetail[item]
                                        ?.share_count_sum?.value *
                                        100) /
                                      this.state.interestDetail[item]
                                        ?.fk_share_count_sum?.value
                                    ).toFixed(2)}%`
                                  : "-"}
                              </span>
                            </div>
                            <div>
                              {" "}
                              <MdDownloadForOffline />
                              <span style={{ fontSize: "15px" }}>
                                {" "}
                                {this.state.filter.metric == "absolute"
                                  ? formatNumber(
                                      this.state.interestDetail[item]
                                        ?.download_count_sum?.value,
                                      1
                                    ) || 0
                                  : this.state.interestDetail[item.id]
                                      ?.fk_download_count_sum?.value
                                  ? `${(
                                      (this.state.interestDetail[item]
                                        ?.download_count_sum?.value *
                                        100) /
                                      this.state.interestDetail[item]
                                        ?.fk_download_count_sum?.value
                                    ).toFixed(2)}%`
                                  : "-"}
                              </span>
                            </div>
                          </Col>
                          <Col
                            lg="1"
                            md="2"
                            style={{ paddingTop: "5px", textAlign: "center" }}
                          >
                            {this.state.subInterestStatusList[item]?.status ==
                            true ? (
                              <Tag color="green">Active</Tag>
                            ) : (
                              <Tag color="red">Disabled</Tag>
                            )}
                          </Col>
                          <Col
                            lg="4"
                            md="3"
                            style={{
                              paddingRight: "1px",
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            {/* <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          this._manageReviewVideos({
                            interest: item1,
                            subInterest: item,
                            subInterestName:
                              this.state.subInterestStatusList[item].name,
                          })
                        }}
                        startIcon={<MdFlaky style={{ fontSize: "17px" }} />}
                      >
                        Review
                      </Button> */}
                            {/* <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          this._manageTabAddVideos({
                            interest: item1,
                            subInterest: item,
                            subInterestName:
                              this.state.subInterestStatusList[item].name,
                          })
                        }}
                        style={{ fontSize: "12px" }}
                        startIcon={
                          <BiSolidCloudUpload style={{ fontSize: "20px" }} />
                        }
                      >
                        Add videos
                      </Button>{" "} */}
                            <Button
                              style={{
                                fontSize: "12px",
                              }}
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                this._manageSeedVideos({
                                  interest: item1,
                                  subInterest: item,
                                  subInterestName:
                                    this.state.subInterestStatusList[item].name,
                                })
                              }}
                              startIcon={
                                <FaSeedling style={{ fontSize: "17px" }} />
                              }
                            >
                              Manage Seed
                            </Button>{" "}
                            <div
                              className="p-1 px-2"
                              style={{
                                border: "1px solid #1976d2",
                                borderRadius: "4px",
                              }}
                            >
                              <Dropdown
                                menu={{
                                  items: [
                                    // {
                                    //   key: "1",
                                    //   label: (
                                    //     <Button
                                    //       style={{ color: "black" }}
                                    //       color="primary"
                                    //       className="btn-block btn btn-primary"
                                    //       onClick={() => {
                                    //         // this.props.history.push(`/feed/interests/manage`)
                                    //         this.props._toggleInterest("edit", item1)
                                    //       }}
                                    //       startIcon={<MdModeEditOutline />}
                                    //     >
                                    //       Edit
                                    //     </Button>
                                    //   ),
                                    // },
                                    // {
                                    //   key: "2",
                                    //   label: (
                                    //     <Button
                                    //       style={{ color: "black" }}
                                    //       color="primary"
                                    //       className="btn-block btn btn-primary"
                                    //       onClick={async () => {
                                    //         await updateSubInterest({
                                    //           id: item,
                                    //           is_active:
                                    //             !this.state.subInterestStatusList[
                                    //               item
                                    //             ]?.status,
                                    //         })
                                    //       }}
                                    //       startIcon={
                                    //         this.state.subInterestStatusList[item]
                                    //           ?.status ? (
                                    //           <AiOutlineStop />
                                    //         ) : (
                                    //           <TiTick />
                                    //         )
                                    //       }
                                    //     >
                                    //       {this.state.subInterestStatusList[item]
                                    //         ?.status
                                    //         ? "Disable"
                                    //         : "Enable"}
                                    //     </Button>
                                    //   ),
                                    // },
                                    {
                                      key: "3",
                                      label: (
                                        <Button
                                          style={{ color: "black" }}
                                          color="primary"
                                          className="btn-block btn btn-primary"
                                          onClick={async () => {
                                            await updateSubInterest({
                                              id: item,
                                              is_cold_start: false,
                                            })
                                          }}
                                          startIcon={<TiTick />}
                                        >
                                          Disable Cold Start
                                        </Button>
                                      ),
                                    },
                                  ],
                                  selectable: true,
                                }}
                              >
                                <Typography.Link>
                                  <Space>
                                    <MoreOutlined
                                      style={{
                                        color: "#1976d2",
                                        marginTop: "9px",
                                      }}
                                    />
                                  </Space>
                                </Typography.Link>
                              </Dropdown>
                            </div>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  </>
                )}
              />
            )}
          </>
        ))}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    languageList: _.get(store, "uiConstants.languageList"),
    pageEntities: _.get(store, "zeroSearch.pageEntities"),
    locationList: _.get(store, "locationReducer.locationList"),
  }
}

export default withRouter(connect(mapStateToProps)(ColdStartList))
