import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import Chips from "components/CommonFilter/chips"
import WebsiteVerificationUserList from "../../components/UserWebsiteVerification/websiteVerificationUserList"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
//import { getTasksByFilter, resetState } from "appRedux/slices/content"
import {
  markTaskAsCompleted,
  updateAppUserWebsiteLink,
} from "../../clientServices/appUserService"
import { getSourceDetails } from "clientServices/sourceService"
import {
  getTasksByFilter,
  updateWebsiteVerification,
  resetState,
} from "appRedux/slices/appUser"
import Auxiliary from "../../util/Auxiliary"
import "../Content/browseContent.style.css"
import {
  getAgencyList,
  getCmsUserList,
  getDesksList,
} from "appRedux/slices/cmsUser"
import { taxonomyFilters } from "constants/uiConstants"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
class WebsiteVerification extends ErrorBoundComponent {
  state = {
    userList: [],
    total: 0,
    selectedContent: {},
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchAfter:
        this.props.queryParams && this.props.queryParams["searchAfter"]
          ? this.props.queryParams["searchAfter"]
          : null,
      moderationLevel:
        this.props.queryParams && this.props.queryParams["moderationLevel"]
          ? this.props.queryParams["moderationLevel"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : this.props.currentUser.userType === 4
          ? "CREATED"
          : "",
      opSearchType:
        this.props.queryParams && this.props.queryParams["opSearchType"]
          ? this.props.queryParams["opSearchType"]
          : null,
      createdDateFrom:
        this.props.queryParams && this.props.queryParams["createdDateFrom"]
          ? this.props.queryParams["createdDateFrom"]
          : null,
      createdDateTo:
        this.props.queryParams && this.props.queryParams["createdDateTo"]
          ? this.props.queryParams["createdDateTo"]
          : null,
      orderByField:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : this.props.currentUser.userType !== 4
          ? "created_date"
          : null,
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : this.props.currentUser.userType !== 4
          ? "desc"
          : null,
      contentUUID:
        this.props.queryParams && this.props.queryParams["contentUUID"]
          ? this.props.queryParams["contentUUID"]
          : null,
      langCode:
        this.props.queryParams && this.props.queryParams["langCode"]
          ? this.props.queryParams["langCode"]
          : "",
      agency:
        this.props.queryParams && this.props.queryParams["agency"]
          ? this.props.queryParams["agency"]
          : "",
      buckets:
        this.props.queryParams && this.props.queryParams["buckets"]
          ? this.props.queryParams["buckets"]
          : [],
      deskId:
        this.props.queryParams && this.props.queryParams["deskId"]
          ? this.props.queryParams["deskId"]
          : null,
      deskType:
        this.props.queryParams && this.props.queryParams["deskType"]
          ? this.props.queryParams["deskType"]
          : "",
      taskCompletedDateFrom:
        this.props.queryParams &&
        this.props.queryParams["taskCompletedDateFrom"]
          ? this.props.queryParams["taskCompletedDateFrom"]
          : null,
      taskCompletedDateTo:
        this.props.queryParams && this.props.queryParams["taskCompletedDateTo"]
          ? this.props.queryParams["taskCompletedDateTo"]
          : null,
      userEmail:
        this.props.queryParams && this.props.queryParams["userEmail"]
          ? this.props.queryParams["userEmail"]
          : "",
    },
  }

  async componentDidMount() {
    const filters = this.state.filters
    if (!this.props.common.error) {
      this.props.dispatch(
        getTasksByFilter({
          filters: _.deepClean(filters),
          currentUser: this.props.currentUser,
        })
      )
      const filterCount = Object.keys(_.deepClean(filters)).length
      this.setState({
        filterCount,
        filters,
        userList: this.props.userList,
        total: this.props.total,
      })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.common.error &&
      prevProps.userList !== this.props.userList
    ) {
      this.setState({
        userList: this.props.userList,
        total: this.props.total,
      })
    }
    // if (!this.props.common.error && this.props.refetchTaskList) {
    //   const filters = this.state.filters
    //   this.props.dispatch(
    //     getTasksByFilter({
    //       filters: _.deepClean(filters),
    //       currentUser: this.props.currentUser,
    //     })
    //   )
    // }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }

    this.setState({
      filters: newFilters,
    })
  }

  _closeFilters = () => {
    this.setState({
      showFilters: false,
      showAdvancedFilters: false,
    })
  }

  _closeFilters = () => {
    this.setState({
      showAdvancedFilter: false,
    })
  }

  _updateUser = async (value, user, field) => {
    let updatedUserList = this.state.userList
    let sourceData = {}
    if (user && user.source_id) {
      sourceData = await getSourceDetails(user.source_id)
    }
    const filterUserList = updatedUserList.filter(
      item => item.user_uuid != user.user_uuid
    )
    const data = new FormData()
    const userData = {
      website_link_status: value,
    }
    data.append("details", JSON.stringify(userData))
    const response = await updateAppUserWebsiteLink({
      data,
      userUuid: user.user_uuid,
    })
    if (response && response.status === 200) {
      const taskResponse = await markTaskAsCompleted({
        sourceData,
        userId: user.user_uuid,
        action: field,
      })
      if (taskResponse && taskResponse?.data?.status?.code === 200) {
        const filters = this.state.filters
        if (filterUserList && filterUserList.length > 0) {
          this.setState({
            userList: filterUserList,
            total: filterUserList.length,
          })
        } else {
          this.props.dispatch(
            getTasksByFilter({
              filters: _.deepClean(filters),
              currentUser: this.props.currentUser,
            })
          )
        }
      }
    }
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.state.userList && this.state.userList.length > 0 ? (
          <WebsiteVerificationUserList
            userList={this.state.userList}
            handleApproveReject={this._updateUser}
            total={this.state.total}
            searchAfter={this.props.searchAfter}
            isMobile={this.props.isMobile}
            currentUser={this.props.currentUser}
            currentPage={this.state.filters["currentPage"]}
            pageType="task"
            pageSize={this.state.filters["pageSize"]}
          />
        ) : (
          <div>No data available</div>
        )}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    payload: _.get(store, "appUser.payload"),
    contentList: _.get(store, "content.content"),
    userList: _.get(store, "appUser.userList"),
    refetchTaskList: _.get(store, "appUser.refetchTaskList"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "appUser.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "appUser.loading"),
    agencyList: _.get(store, "cmsUser.agencyList"),
    deskList: _.get(store, "cmsUser.deskList"),
  }
}

export default connect(mapStateToProps)(WebsiteVerification)
