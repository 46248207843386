import React from "react"
import { withRouter } from "react-router"
import EmbedContainer from "react-oembed-container"
import PropTypes from "prop-types"
import { Card, Row, CardBody, Col, CardImg } from "reactstrap"
import CopyToClipboard from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "../style.css"

const SocialCard = props => {
  const {
    content_title,
    content_uuid,
    embed_type: embedType = "",
    url,
    blockquote_text,
  } = props.social || {}

  const renderEmbeds = () => {
    if (blockquote_text) {
      return (
        <EmbedContainer className="article-content" markup={blockquote_text}>
          <article>
            <div dangerouslySetInnerHTML={{ __html: blockquote_text }} />
          </article>
        </EmbedContainer>
      )
    } else {
      return <i className="fas fa-solid fa-icons fa-7x py-4 text-secondary" />
    }
  }

  return (
    <Card style={{ width: "fit-content" }}>
      <div className="bg-primary bg-soft">
        <Row>
          <Col>
            <div
              className=""
              style={{
                cursor: "pointer",
                backgroundColor: "lightgray",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                height: "455px",
                width: "250px",
                overflowY: "hidden",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                {renderEmbeds()}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <CardBody>
        <Tooltip title={content_title}>
          <div className="font-size-16 font-weight-semibold ellipsis">
            {content_title}
          </div>
        </Tooltip>
      </CardBody>
      <div className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center justify-content-center">
        <CopyToClipboard
          text={content_uuid}
          onCopy={e => {
            toastr.success("Copied sucessfully!")
          }}
        >
          <Tooltip title="Copy Content ID">
            <i className="bx bx-copy" role="button" />
          </Tooltip>
        </CopyToClipboard>
      </div>
    </Card>
  )
}

SocialCard.propTypes = {
  content: PropTypes.object,
}

export default withRouter(SocialCard)
