import React from "react"
import { connect } from "react-redux"
import { Select, DatePicker, Input } from "antd"
import QueryString from "query-string"
import moment from "moment"
import _ from "lodash"
import BulkUploadReportList from "../../components/BulkReportList/bulkReportList"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getBulkReportList, resetState } from "appRedux/slices/activity"
import { getCmsUserList } from "appRedux/slices/cmsUser"
import Auxiliary from "../../util/Auxiliary"
import "flatpickr/dist/themes/material_blue.css"
import { Pagination, Tag } from "antd"
import { Col, Row, Card, CardBody } from "reactstrap"
import "../Comment/browseComment.style.css"
import { downloadReport } from "clientServices/communityService"
import { CSVLink } from "react-csv"
import { formatNumber } from "../../util/common"
import { Button, ButtonGroup } from "@mui/material"
import dayjs from "dayjs"

const { Option } = Select

class BulkUploadReport extends ErrorBoundComponent {
  state = {
    disableCSVBtn: true,
    reportCSVData: [],
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      searchText:
        this.props.queryParams && this.props.queryParams["searchText"]
          ? this.props.queryParams["searchText"]
          : null,
      searchType:
        this.props.queryParams && this.props.queryParams["searchType"]
          ? this.props.queryParams["searchType"]
          : "uploadedBy",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : "",
      orderByField:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : "updatedOn",
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "desc",
      createdDateFrom:
        this.props.queryParams && this.props.queryParams["createdDateFrom"]
          ? this.props.queryParams["createdDateFrom"]
          : null,
      createdDateTo:
        this.props.queryParams && this.props.queryParams["createdDateTo"]
          ? this.props.queryParams["createdDateTo"]
          : null,
    },
    showFilters: false,
    showAdvancedFilters: false,
    showReportForm: false,
    downloadList: [],
    downloadShow: false,
  }

  componentDidMount = async () => {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getBulkReportList(_.deepClean(filters)))
      this.props.dispatch(getCmsUserList())
      let res = []
      if (
        this.state.filters.searchType === "csvName" &&
        this.state.filters.searchText
      ) {
        this.setState({ disableCSVBtn: true })

        res = await this._onUpload()
      }
      this.setState({ reportCSVData: res || [], disableCSVBtn: false, filters })
    }
    window.addEventListener("keydown", this._keyDownHandler)
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter") {
      return this._searchUploadReport()
    }
  }

  toggleUploadBulk = arg => {
    this.setState({ showReportForm: true })
    // this.toggle()
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    if (type === "searchType") {
      newFilters["searchText"] = undefined
    }
    this.setState({ filters: newFilters })
  }

  _searchUploadReport = async () => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = 1
    this.setState({ filters: newFilters }, async () => {
      this.props.history.push(
        `/activity/bulkuploadreport?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
      this.props.dispatch(getBulkReportList(_.deepClean(this.state.filters)))
      let res = []
      if (
        this.state.filters.searchType === "csvName" &&
        this.state.filters.searchText
      ) {
        this.setState({ disableCSVBtn: true })

        res = await this._onUpload()
      }

      this.setState({ reportCSVData: res || [], disableCSVBtn: false })
    })
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/activity/bulkuploadreport?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
      this.props.dispatch(getBulkReportList(_.deepClean(this.state.filters)))
    })
  }

  _onUpload = async () => {
    const res = await downloadReport({
      csvName: this.state.filters["searchText"],
      status: this.state.filters["status"],
    })
    const newData = res.data.data.map(item => {
      let parsedVal = JSON.parse(item.input_meta)
      if (item.output_entity_id) {
        parsedVal["output_entity_id"] = item.output_entity_id
      }
      if (item.status) {
        parsedVal["status"] = item.status
      }
      if (item.error_details) {
        parsedVal["error_details"] = item.error_details
      }
      if (item.details) {
        parsedVal["details"] = item.details
      }

      return parsedVal
    })
    return newData
  }

  _filters = () => {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Row>
              <Col lg="3" md="8">
                <Input.Group compact={true}>
                  <Select
                    placeholder="Search Type"
                    style={{ width: "45%" }}
                    allowClear={true}
                    defaultValue={this.state.filters["searchType"]}
                    onChange={value =>
                      this._handleFilterChange(value, "searchType")
                    }
                    options={[
                      { value: "uploadedBy", label: "Uploaded By" },
                      { value: "csvName", label: "CSV Name" },
                      { value: "entityType", label: "Entity Type" },
                      { value: "output_entity_id", label: "Output Id" },
                    ]}
                  />

                  {this.state.filters["searchType"] === "uploadedBy" ? (
                    <Select
                      showSearch
                      defaultValue={this.state.filters["searchText"]}
                      placeholder="Uploaded By"
                      onChange={value => {
                        this._handleFilterChange(value, "searchText")
                      }}
                      allowClear={true}
                      name="searchId"
                      style={{ width: "55%" }}
                    >
                      {this.props.moderatorList &&
                        this.props.moderatorList.map(moderator => {
                          if (
                            moderator.userType < 4 ||
                            moderator.email === this.props.currentUser.email
                          ) {
                            return (
                              <Option
                                value={moderator.email}
                                key={moderator.email}
                              >
                                {moderator.name}
                              </Option>
                            )
                          }
                        })}
                    </Select>
                  ) : this.state.filters["searchType"] === "entityType" ? (
                    <Select
                      showSearch
                      defaultValue={this.state.filters["searchText"]}
                      placeholder="Entity Type"
                      onChange={value => {
                        this._handleFilterChange(value, "searchText")
                      }}
                      allowClear={true}
                      name="searchId"
                      style={{ width: "55%" }}
                    >
                      <Option value="AUDIO">Audio</Option>
                      <Option value="CONTENT">Content</Option>
                      <Option value="IMAGE">Image</Option>
                      <Option value="SOCIAL">Social</Option>
                      <Option value="USER">User</Option>
                      <Option value="ZONE">Zone</Option>
                      <Option value="CRAWLER">Crawler</Option>
                    </Select>
                  ) : (
                    <Input
                      defaultValue={this.state.filters["searchText"]}
                      placeholder={"Search Value"}
                      onChange={e =>
                        this._handleFilterChange(e.target.value, "searchText")
                      }
                      allowClear={true}
                      name="searchId"
                      style={{ width: "55%" }}
                    />
                  )}
                </Input.Group>
              </Col>
              <Col lg="2" md="6">
                <Select
                  allowClear={true}
                  placeholder="Status"
                  defaultValue={this.state.filters["status"]}
                  onChange={value => {
                    this._handleFilterChange(value, "status")
                  }}
                  style={{ width: "100%" }}
                  options={[
                    { value: "", label: "All" },
                    { value: "DONE", label: "Done" },
                    { value: "FAILED", label: "Failed" },
                    { value: "PROCESSING_STARTED", label: "Processing" },
                  ]}
                />
              </Col>
              <Col lg="3" md="6">
                <Input.Group compact={true}>
                  <Row>
                    <Col className="d-flex">
                      <Input.Group compact={true}>
                        <DatePicker
                          defaultValue={
                            this.state.filters["createdDateFrom"]
                              ? dayjs.unix(
                                  this.state.filters["createdDateFrom"] / 1000,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          style={{ width: "50%" }}
                          format="YYYY-MM-DD HH:mm"
                          showTime
                          placeholder="From Date"
                          onChange={(date, dateString) => {
                            this._handleFilterChange(
                              !_.isEmpty(dateString)
                                ? moment(dateString).format("x")
                                : null,
                              "createdDateFrom"
                            )
                          }}
                        />
                        <DatePicker
                          defaultValue={
                            this.state.filters["createdDateTo"]
                              ? dayjs.unix(
                                  this.state.filters["createdDateTo"] / 1000,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          format="YYYY-MM-DD HH:mm"
                          showTime
                          style={{ width: "50%" }}
                          placeholder="To Date"
                          onChange={(date, dateString) => {
                            this._handleFilterChange(
                              !_.isEmpty(dateString)
                                ? moment(dateString).format("x")
                                : null,
                              "createdDateTo"
                            )
                          }}
                        />
                      </Input.Group>
                    </Col>
                  </Row>
                </Input.Group>
              </Col>
              <Col lg="3" md="8">
                <Input.Group compact={true}>
                  <Select
                    allowClear={true}
                    defaultValue={this.state.filters["orderByField"]}
                    placeholder="Order By"
                    onChange={value => {
                      this._handleFilterChange(value, "orderByField")
                    }}
                    style={{ width: "50%" }}
                  >
                    <Option value="updatedOn">Updated Date</Option>
                    <Option value="createdOn">Created Date</Option>
                  </Select>
                  <Select
                    allowClear={true}
                    defaultValue={this.state.filters["order"]}
                    placeholder="Order"
                    onChange={value => {
                      this._handleFilterChange(value, "order")
                    }}
                    style={{ width: "50%" }}
                  >
                    <Option value="asc">Ascending</Option>
                    <Option value="desc">Descending</Option>
                  </Select>
                </Input.Group>
              </Col>
              <Col lg="1" md="8">
                <button
                  onClick={this._searchUploadReport}
                  role="button"
                  className="btn btn-success btn-sm d-flex align-items-center"
                >
                  <div className="p-1">Apply</div>
                  <span>
                    <i className="bx bx-check-double font-size-16 align-middle"></i>
                  </span>
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }

  dataFromdownloadListState = () => {
    return this.state.downloadList
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        <Row className="mb-2">
          <Col lg="4" md="4">
            <ButtonGroup variant="outlined" size="small">
              <Button color="success">
                Success: {formatNumber(this.props.success_count || 0)}
              </Button>
              <Button>
                Processing: {formatNumber(this.props.processing_count || 0)}
              </Button>
              <Button color="error">
                Failed: {formatNumber(this.props.fail_count || 0)}
              </Button>
            </ButtonGroup>
          </Col>
          <Col
            lg="4"
            md="4"
            className="d-flex justify-content-center align-items-center"
          >
            {this.props.total &&
              this.props.reportList &&
              this.props.reportList.length > 0 && (
                <div className="text-center">
                  <Tag color="geekblue">
                    <span className="font-size-14 p-1">
                      Showing <b>{this.props.reportList.length}</b> of{" "}
                      <b>{formatNumber(this.props.total)}</b> Reports
                    </span>
                  </Tag>
                </div>
              )}
          </Col>
          <Col
            lg="4"
            md="4"
            className="d-flex justify-content-end align-items-center"
          >
            {this.state.reportCSVData.length ? (
              <button
                type="button"
                className="btn btn-primary align-self-end d-flex btn-sm font-size-14"
                style={{ width: "fit-content", fontWeight: "bold" }}
                disabled={
                  this.state.disableCSVBtn || !this.state.reportCSVData.length
                }
              >
                <div className="d-flex justify-content-center align-items-center">
                  <i class="bx bx-download bx-xs"></i>{" "}
                  <div>
                    &nbsp;
                    <CSVLink
                      style={{ color: "white" }}
                      data={this.state.reportCSVData}
                    >
                      Reports
                    </CSVLink>
                  </div>
                </div>
              </button>
            ) : null}
          </Col>
        </Row>
        <BulkUploadReportList
          reportList={this.props.reportList}
          onPageChange={this._onPageChange}
          onSearch={this._searchUploadReport}
          total={this.props.total}
          currentPage={this.state.filters["currentPage"]}
          pageSize={this.state.filters["pageSize"]}
        />
        <div className="d-flex justify-content-end mb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={this._onPageChange}
            defaultCurrent={this.state.filters.currentPage}
            total={this.props.total < 10000 ? this.props.total : 10000}
            pageSize={this.state.filters.pageSize}
            showSizeChanger={false}
          />
        </div>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "activityLog.count"),
    success_count: _.get(store, "activityLog.success_count"),
    fail_count: _.get(store, "activityLog.fail_count"),
    processing_count: _.get(store, "activityLog.processing_count"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "activityLog.loading"),
    reportList: _.get(store, "activityLog.reportList"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(BulkUploadReport)
