import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
// import { LANGUAGES } from "constants/uiConstants"

const { Option } = Select

const PanKycDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Kyc Number</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["kycNumber"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "kycNumber")
                  }
                  allowClear={true}
                  name="kycNumber"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>

        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">User UUID</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["userUuid"]}
                  placeholder={"Search Value"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value.trim(), "userUuid")
                  }
                  allowClear={true}
                  name="userUuid"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        {!props.dash && (
          <Col lg="4" md="6">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Status</h5>
            </Divider>
            <Input.Group compact={true}>
              <Select
                placeholder="Status"
                style={{ width: "100%" }}
                allowClear={true}
                defaultValue={props && props.filters && props.filters["status"]}
                onChange={value => props.handleFilterChange(value, "status")}
              >
                <Option value="SUCCESS">SUCCESS</Option>
                <Option value="IN_PROGRESS">IN_PROGRESS</Option>
                <Option value="FAILED">FAILED</Option>
              </Select>
            </Input.Group>
          </Col>
        )}
      </Row>
    </React.Fragment>
  )
}

export default withRouter(PanKycDrawer)
