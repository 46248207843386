import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { getSocial, upsertSocial, resetState } from "appRedux/slices/social"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import SocialUpsertForm from "./socialUpsertForm"
import Auxiliary from "../../util/Auxiliary"
import { Input } from "reactstrap"
import { getZones } from "appRedux/slices/zone"

class ManageSocial extends ErrorBoundComponent {
  state = {
    filters: {
      socialId:
        this.props.queryParams && this.props.queryParams["socialUuid"]
          ? this.props.queryParams["socialUuid"]
          : null,
      currentPage: 1,
    },
    image: this.props.socialList && this.props.socialList[0],
    showUpsertModal: true,
    formAction: "create",
  }

  componentDidMount() {
    this.props.dispatch(getZones({ currentPage: 1, pageSize: 10000 }))
    if (!this.props.common.error && this.state.filters.socialId) {
      this.props.dispatch(getSocial(this.state.filters))
      this.setState({
        formAction: "update",
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this?.props?.queryParams?.socialUuid !==
      prevProps?.queryParams?.socialUuid
    ) {
      this._searchImage(this?.props?.queryParams?.socialUuid)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    this.setState({
      socialId: undefined,
    })
  }

  _onImageUpsertSubmit = async (formData, id) => {
    this.props.dispatch(upsertSocial({ formData, id }))
  }

  _searchImage = value => {
    if (value) {
      this.props.history.push(`/social/manage?socialUuid=${value}`)
      this.setState(
        {
          filters: {
            ...this.state.filters,
            socialId: value,
          },
          formAction: "update",
        },
        () => {
          this.props.dispatch(getSocial(this.state.filters))
        }
      )
    }
  }

  _filters = () => {
    return (
      <Row style={{ marginBottom: "1rem" }}>
        <Col lg="4">
          <Input
            placeholder="Social ID"
            onChange={e => this._searchImage(e.target.value)}
            validations={["required"]}
            defaultValue={this.state.filters.socialId}
          />
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}

        <SocialUpsertForm
          formAction={this.state.formAction}
          onSubmit={this._onImageUpsertSubmit}
          social={this.props.socialList && this.props.socialList[0]}
          currentUserType={this.props.currentUserType}
          currentUser={this.props.currentUser}
          history={this.props.history}
          zoneList={this.props.zoneList}
        />
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "social.loading"),
    socialList: _.get(store, "social.socialList"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    audio: store.audio.audioList.length === 1 ? store.audio.audioList[0] : null,
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    zoneList: _.get(store, "zone.zoneList"),
  }
}

export default connect(mapStateToProps)(ManageSocial)
