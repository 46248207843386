import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { MdAdd } from "react-icons/md"
import { getStoriesData } from "clientServices/audioStories"
import StoriesCard from "./storiesCard"
import AudioStoryForm from "./audioStoryForm"
import { sleep } from "util/common"
import { NO_PERMISSION_MESSAGE, storiesFilters } from "constants/uiConstants"
import Chips from "components/CommonFilter/chips"
import NoDataFound from "components/NoDataFound/noDataFound"

import toastr from "toastr"
class ManageStories extends ErrorBoundComponent {
  state = {
    showStoryForm: false,
    storiesData: [],
    currentStory: undefined,
    filters: {
      storyId: "",
    },
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      let storiesData = await getStoriesData()
      this.setState({
        storiesData,
      })
    }
  }

  fetchAudioStories = async () => {
    await sleep(2000)
    let storiesData = await getStoriesData()
    this.setState({
      storiesData,
      showStoryForm: false,
    })
  }
  onEditClick = (action, data) => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_AUDIO_STORY")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState({
      currentStory: data,
      formAction: action,
      showStoryForm: true,
    })
  }
  onCreateClick = (action, data) => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_AUDIO_STORY")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }

    this.setState({
      currentStory: {},
      formAction: action,
      showStoryForm: true,
    })
  }
  onCancel = () => {
    this.setState({
      currentStory: undefined,
      formAction: undefined,
      showStoryForm: false,
    })
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this._keyDownHandler)
  }
  _searchStories = async () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }

    let storiesData = await getStoriesData(_.deepClean(this.state.filters))
    this.setState({
      storiesData,
    })
  }
  _closeFilters = () => {
    this._searchStories()
    this.setState({
      showAdvancedFilter: false,
      showFilters: false,
    })
  }
  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: storiesFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      async () => {
        let storiesData = await getStoriesData()
        this.setState({
          storiesData,
        })
      }
    )
  }
  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }
  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }
  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          {this.state.showStoryForm && (
            <AudioStoryForm
              formAction={this.state.formAction}
              onCancel={this.onCancel}
              audio={this.state.currentStory}
              fetchAudioStories={this.fetchAudioStories}
            ></AudioStoryForm>
          )}

          {!this.state.showStoryForm && (
            <>
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                type="audio_stories"
                toggleFilters={this._toggleFilters}
              />
              <Row>
                <Col lg="10" md="12"></Col>
                <Col lg="2" md="12">
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      // className="btn btn-primary"
                      variant="contained"
                      onClick={() => this.onCreateClick("create", {})}
                      startIcon={<MdAdd />}
                    >
                      Create
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(256px, 1fr))",
                      gridGap: "2rem",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.state.storiesData?.map(item => {
                      return (
                        <StoriesCard
                          audio={item}
                          onEditClick={this.onEditClick}
                        ></StoriesCard>
                      )
                    })}
                  </div>
                  {!this.state.storiesData && (
                    <NoDataFound message="No data available" />
                  )}
                </Col>
              </Row>
            </>
          )}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManageStories)
