import React from "react"
import { connect } from "react-redux"
import Auxiliary from "../../util/Auxiliary"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getTop100spin } from "../../clientServices/spinConfigService"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import BootstrapTable from "react-bootstrap-table-next"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  Badge,
  Card,
  Col,
  Row,
  CardBody,
  InputGroup,
  FormGroup,
} from "reactstrap"
import { Input, Select, DatePicker, List, Tooltip, Tag, Pagination } from "antd"
import _ from "lodash"
import moment from "moment"
import toastr from "toastr"
import dayjs from "dayjs"

const { Option } = Select

class SpinTop100 extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      statsUserList: [],
      filters: {
        type: "SPIN_WHEEL",
        fromDate: moment().subtract(0, "days").startOf("day").format("x"),
        toDate: moment().subtract(0, "days").endOf("day").format("x"),
      },

      columns: [
        {
          dataField: "idx",
          text: "Sl no.",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "10%",
            textAlign: "center",
          },
          formatter: (cellContent, row, rowIndex, formatExtraData) => {
            return cellContent
          },
          sort: true,
        },
        {
          dataField: "transaction_by",
          text: "Transaction By",
          style: {
            width: "70%",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>
                  {cellContent || "-"}
                  <CopyToClipboard
                    text={cellContent || ""}
                    onCopy={e => {
                      toastr.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy Transaction By">
                      <i className="bx bx-copy me-2 ms-2" role="button" />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              </div>
            )
          },
        },
        {
          dataField: "count",
          text: "Count",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "20%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>
                  {cellContent || (cellContent == 0 ? cellContent : "-")}
                </div>
              </div>
            )
          },
        },
      ],
    }
  }

  componentDidMount = async () => {
    if (!this.props.common.error) {
      //   this.setState({ loading: true })
      //   const data =
      //     (await getSpinUserStats(
      //       _.deepClean({ filters: this.state.filters }),
      //       this.state.offset,
      //       this.state.limit
      //     )) || {}
      //   const statsUserList = data?.data || []
      //   this.setState({ loading: false, statsUserList, total: data?.count || 1 })
      await this._onSearch()
    }
  }

  _onSearch = async () => {
    this.setState({ loading: true })
    const data =
      (await getTop100spin(_.deepClean({ filters: this.state.filters }))) || {}

    const statsUserList =
      data?.map((dt, idx) => ({ ...dt, idx: idx + 1 })) || []
    this.setState({ loading: false, statsUserList, total: data?.count || 1 })
  }

  _filters = () => {
    return (
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row className="row gy-2 gx-3 align-items-center">
                <Col md="6" lg="4">
                  <Select
                    allowClear={true}
                    style={{ width: "100%" }}
                    value={this.state.filters.type || ""}
                    onChange={e =>
                      this.setState({
                        filters: { ...this.state.filters, type: e },
                      })
                    }
                    placeholder="Type"
                  >
                    <Option value="SPIN_WHEEL">SPIN_WHEEL</Option>
                    <Option value="PURCHASE">PURCHASE</Option>
                    <Option value="SYSTEM_GIFTS_RECEIVED">
                      SYSTEM_GIFTS_RECEIVED
                    </Option>
                  </Select>
                </Col>
                <Col lg="4" md="6">
                  <FormGroup>
                    <InputGroup>
                      <DatePicker
                        value={
                          this.state.filters.fromDate
                            ? dayjs.unix(
                                this.state.filters.fromDate / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        style={{ width: "50%" }}
                        showTime
                        placeholder="From Date"
                        onChange={(date, dateString) =>
                          this.setState({
                            filters: {
                              ...this.state.filters,
                              fromDate: !_.isEmpty(dateString)
                                ? moment(dateString).format("x")
                                : moment()
                                    .subtract(0, "days")
                                    .startOf("day")
                                    .format("x"),
                            },
                          })
                        }
                      />
                      <DatePicker
                        value={
                          this.state.filters.toDate
                            ? dayjs.unix(
                                this.state.filters.toDate / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        style={{ width: "50%" }}
                        placeholder="To Date"
                        onChange={(date, dateString) =>
                          this.setState({
                            filters: {
                              ...this.state.filters,
                              toDate: !_.isEmpty(dateString)
                                ? moment(dateString).format("x")
                                : moment()
                                    .subtract(0, "days")
                                    .endOf("day")
                                    .format("x"),
                            },
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="4" md="12" className="d-flex justify-content-end">
                  <button
                    onClick={this._onSearch}
                    role="button"
                    className="btn btn-success d-flex align-items-center"
                  >
                    <div className="mx-2">Apply</div>
                    <span>
                      <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div className="bg-white">
        <Auxiliary
          loading={this.state.loading}
          error={_.get(this.props, "common.error")}
        >
          <div>{this._filters()}</div>
          {this.state?.statsUserList &&
          this.state?.statsUserList?.length > 0 ? (
            <div className="d-flex m-2 p-2">
              <div style={{ width: "50%" }}>
                <ToolkitProvider
                  keyField="id"
                  data={
                    this.state.statsUserList?.slice(
                      0,
                      Math.ceil(this.state.statsUserList.length / 2)
                    ) || []
                  }
                  columns={this.state.columns}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          // {...paginationTableProps}
                          responsive
                          //   defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          // selectRow={selectRow}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
              <div style={{ width: "50%" }} className="border-start">
                <ToolkitProvider
                  keyField="id"
                  data={
                    this.state.statsUserList?.slice(
                      Math.ceil(this.state.statsUserList.length / 2)
                    ) || []
                  }
                  columns={this.state.columns}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          // {...paginationTableProps}
                          responsive
                          //   defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          // selectRow={selectRow}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center hero-section text-muted"
              style={{ fontStyle: "italic" }}
            >
              No data present.
            </div>
          )}
        </Auxiliary>
      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "spin.loading"),
    spinStats: _.get(store, "spin.spinStats"),
    refetch: _.get(store, "spin.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(SpinTop100)
