import React from "react"
import { connect } from "react-redux"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import PhoneVerificationForm from "./phoneVerificationForm"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"

class VerifyCMSUser extends ErrorBoundComponent {
  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <div
          style={{ marginTop: "50px" }}
          className="d-flex justify-content-center"
        >
          <PhoneVerificationForm {...this.props} />
        </div>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    common: _.get(store, "common"),
  }
}

export default connect(mapStateToProps, {})(VerifyCMSUser)
