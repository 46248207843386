import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import { MultiSelect, ChipsInput } from "../ValidatorForm"
import { LANGUAGES, GENRE_LIST } from "constants/uiConstants"
import { Divider } from "antd"
import toastr from "toastr"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import "./listing.css"

export default class ListingsForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        name: props?.data?.name ? props.data.name : "",
        genre: props?.data?.genre ? props.data.genre : "",
        // lang_code: props?.data?.lang_code ? props.data.lang_code : "en",
        location: props?.data?.location ? props.data.location : "",
        item_id: props?.data?.item_id ? props.data.item_id : [],
        id: props?.data?.id ? props.data.id : null,
        status: props?.data?.status ? props.data.status : "INACTIVE",
        target: props?.data?.target ? props.data.target : [],
      },
    }
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    if (field === "status") {
      if (value) formData[field] = "ACTIVE"
      else formData[field] = "INACTIVE"
    } else {
      formData[field] = value
    }
    this.setState({ formData })
  }

  _getLocations = () => {
    const locationArray = this.props.locationList.reduce((acc, location) => {
      let cityArray = []
      if (location.children && location.children.length > 0) {
        cityArray = location.children.map(city => {
          return { label: city.name, value: city.dhTagId }
        })
      }
      return [
        ...acc,
        ...cityArray,
        { label: location.name, value: location.dhTagId },
      ]
    }, [])
    return locationArray
  }

  _handleTargetFieldsChange = (field, value, index) => {
    const target = [...this.state.formData.target]
    target[index] = {
      ...target[index],
      [field]: value,
    }
    this.setState({
      formData: {
        ...this.state.formData,
        target,
      },
    })
  }

  _renderTargetForm = (target, index, selected_target_langs) => {
    return (
      <Row>
        <Col lg="4">
          <label>Language</label>
          <div>
            <select
              className="form-select"
              onChange={e =>
                this._handleTargetFieldsChange(
                  "lang_code",
                  e.target.value,
                  index
                )
              }
              value={target.lang_code}
            >
              <option value="">Select Language</option>
              {LANGUAGES.map(lang => (
                <option
                  value={lang.value}
                  disabled={selected_target_langs.includes(lang.value)}
                >
                  {lang.label}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col sm={6} md={6} className="mb-2">
          <label>Item Id</label>
          {/* <ChipsInput
            className="form-control"
            defaultValue={target.item_id || []}
            onChange={e => this._handleTargetFieldsChange("item_id", e, index)}
          /> */}
          <div className="d-flex align-items-start">
            <div className="width-100">
              <ChipsInput
                // style={{ width: "100% !important" }}
                className="form-control"
                defaultValue={target.item_id || []}
                onChange={e =>
                  this._handleTargetFieldsChange("item_id", e.value, index)
                }
                // onChange={e => console.log(e)}
                value={target.item_id || []}
              />
            </div>
            <Button
              className="ms-2"
              color="primary"
              onClick={() =>
                this.setState({
                  selectTargetItemId: true,
                  current_index: index,
                  current_tareget: target,
                })
              }
            >
              Select
            </Button>
          </div>
        </Col>
      </Row>
    )
  }

  _addTarget = () => {
    const target = [
      ...this.state.formData.target,
      {
        lang_code: "",
        item_id: "",
      },
    ]

    this.setState({
      formData: {
        ...this.state.formData,
        target,
      },
    })
  }

  _form = () => {
    const selected_target_langs = this.state.formData?.target.map(
      targetData => targetData?.lang_code || ""
    )
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Listing`}</CardTitle>
          <Row>
            <Col sm="6" lg="6" className="mb-2">
              <label>Name</label>
              <input
                className="form-control"
                value={this.state.formData.name}
                onChange={e =>
                  this._handleFieldChange("name", e.target.value.trim())
                }
                onKeyDown={e => {
                  if (e.code == "Space") {
                    toastr.remove()
                    toastr.error("Space is not allowed.")
                  }
                }}
              />
            </Col>

            <Col sm="6" lg="6" className="mb-2">
              <label>Genre</label>
              <select
                className="form-select"
                onChange={e => this._handleFieldChange("genre", e.target.value)}
                value={this.state.formData.genre}
              >
                <option value="">Select Genre</option>
                {GENRE_LIST.map(genre => (
                  <option value={genre.value}>{genre.label}</option>
                ))}
              </select>
            </Col>
            {/* <Col lg="4">
              <label>Language</label>
              <div>
                <select
                  className="form-select"
                  onChange={e =>
                    this._handleFieldChange("lang_code", e.target.value)
                  }
                  value={this.state.formData.lang_code}
                >
                  {LANGUAGES.map(lang => (
                    <option value={lang.value}>{lang.label}</option>
                  ))}
                </select>
              </div>
            </Col> */}
            {this.props.locationList && this.props.locationList.length > 0 && (
              <Col sm="6" lg="6">
                <label>Location</label>
                <MultiSelect
                  placeholderLabel="Location"
                  field={"locations"}
                  className="creator-form"
                  defaultValue={this.state.formData.location}
                  options={this._getLocations()}
                  onChange={e => this._handleFieldChange("location", e)}
                />
              </Col>
            )}
            <Col sm={6} md={6} className="mb-2">
              <label>Item Id</label>
              <div className="d-flex align-items-start">
                <div className="width-100">
                  <ChipsInput
                    // style={{ width: "100% !important" }}
                    className="form-control"
                    defaultValue={this.state.formData.item_id || []}
                    onChange={e => this._handleFieldChange("item_id", e.value)}
                    // onChange={e => console.log(e)}
                    value={this.state.formData.item_id || []}
                  />
                </div>
                <Button
                  className="ms-2"
                  color="primary"
                  onClick={() =>
                    this.setState({
                      selectItemId: true,
                    })
                  }
                >
                  Select
                </Button>
              </div>
            </Col>
            <Col lg="2">
              <div className="form-check form-switch form-switch-lg mt-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={e =>
                    this._handleFieldChange("status", e.target.checked)
                  }
                  checked={
                    this.state.formData.status === "INACTIVE" ? false : true
                  }
                />
                <label className="form-check-label">Status</label>
              </div>
            </Col>
          </Row>
          {this.state.formData?.target?.map((item, index) => {
            return (
              <>
                <Divider>Target #{index + 1}</Divider>
                {this._renderTargetForm(item, index, selected_target_langs)}
              </>
            )
          })}
          <div className="d-flex justify-content-center">
            <div
              className="mt-3 btn btn-success"
              onClick={this._addTarget}
              role="button"
            >
              Add Target
            </div>
          </div>
          <Row className="d-flex">
            <Col>
              <div className="d-flex justify-content-end">
                <Button onClick={this._onSubmit} color="primary">
                  {this.props.action === "update" ? "Update" : "Create"}
                </Button>
                <Button
                  onClick={() => {
                    this.props.onCancel()
                  }}
                  color="danger"
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = () => {
    const data = this.state.formData
    this.props.onSubmit(data)
  }

  render() {
    return (
      <>
        {this._form()}
        {this.state.selectItemId && (
          <SearchAndSelect
            type="VIDEO"
            visible={this.state.selectItemId}
            onClose={() => this.setState({ selectItemId: false })}
            onAdd={arr => this._handleFieldChange("item_id", arr)}
            // selectOne={true}
            selectedArr={this.state.formData.item_id || []}
          />
        )}
        {this.state.selectTargetItemId && (
          <SearchAndSelect
            type="VIDEO"
            visible={this.state.selectTargetItemId}
            onClose={() =>
              this.setState({
                selectTargetItemId: false,
                current_index: undefined,
                current_tareget: undefined,
              })
            }
            onAdd={arr =>
              this._handleTargetFieldsChange(
                "item_id",
                arr,
                this.state.current_index
              )
            }
            // selectOne={true}
            selectedArr={this.state.current_tareget.item_id || []}
          />
        )}
      </>
    )
  }
}
