import Axios from "axios"
import toastr from "toastr"

export const requestChallengeByFilters = async payload => {
  try {
    const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
    _.unset(payload, "currentPage")
    let data = await Axios.post(
      `/api/challenge/getChallengeListByFilter?offset=${offset}&limit=10000`,
      { filters: payload }
    )
    return data
  } catch (error) {
    throw error
  }
}
export const requestTemplatesList = async payload => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/cameraAsset/templates?offset=${offset}&limit=${limit}`,
      { filters: payload.filters }
    )
    return data
  } catch (error) {
    throw error
  }
}
