import React from "react"
import toastr from "toastr"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import ReactJSON from "react-json-view"
import {
  getSourceListByFilters,
  resetState,
  createSource,
  updateSource,
} from "appRedux/slices/source"
import { Select, Pagination, Input } from "antd"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import SourceCard from "./sourceCard"
import UpsertSourceForm from "./sourceUpsertForm"
import QueryString from "query-string"
import "./styles.css"
import { Card, CardBody, Row, Col } from "reactstrap"
import { Button } from "@mui/material"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"

const { Option } = Select

class ManageSource extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      type:
        this.props.queryParams && this.props.queryParams["type"]
          ? this.props.queryParams["type"]
          : "",
      status:
        this.props.queryParams && this.props.queryParams["status"]
          ? this.props.queryParams["status"]
          : "",
      displayName:
        this.props.queryParams && this.props.queryParams["displayName"]
          ? this.props.queryParams["displayName"]
          : null,
      sourceId:
        this.props.queryParams && this.props.queryParams["sourceId"]
          ? this.props.queryParams["sourceId"]
          : null,
      orderByField:
        this.props.queryParams && this.props.queryParams["orderByField"]
          ? this.props.queryParams["orderByField"]
          : "createdOn",
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "desc",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      this.props.dispatch(
        getSourceListByFilters(_.deepClean(this.state.filters))
      )
    }
  }

  componentDidUpdate() {
    if (this.props.refetchData) {
      this._onCancel()
      this.props.dispatch(
        getSourceListByFilters(_.deepClean(this.state.filters))
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onSourceSearch = () => {
    this._onCancel()
    let { filters } = this.state
    filters = {
      ...filters,
      currentPage: 1,
    }
    this.setState({
      filters,
    })
    this.props.history.push(
      `/source/manage?${QueryString.stringify(_.deepClean(filters))}`
    )
    this.props.dispatch(getSourceListByFilters(_.deepClean(filters)))
  }

  _upsertSource = (action, source) => {
    if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_SOURCE")) {
      toastr.error(NO_PERMISSION_MESSAGE)
      return
    }
    this.setState(
      {
        showForm: false,
      },
      () => {
        this.setState({
          showForm: true,
          action,
          source,
        })
      }
    )
  }

  _onCancel = () => {
    this.setState({
      showForm: false,
      action: null,
      source: null,
      showQuery: false,
      queryObject: null,
    })
  }

  _onSubmit = (sourceData, type) => {
    this.setState({ showForm: false })
    if (type === "create") {
      this.props.dispatch(createSource(sourceData))
    } else {
      this.props.dispatch(
        updateSource({
          ...sourceData,
          sourceId: this.state.source.source_id,
        })
      )
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    this.setState({
      filters: newFilters,
    })
  }

  _filters = () => {
    return (
      <>
        <Card>
          <CardBody>
            <Row className="d-flex align-items-center">
              <Col lg="2" md="3">
                <Input
                  placeholder="Source ID"
                  onChange={e => {
                    this._handleFilterChange(e.target.value, "sourceId")
                  }}
                  defaultValue={this.state.filters["sourceId"]}
                />
              </Col>
              <Col lg="2" md="3">
                <Select
                  allowClear={true}
                  placeholder="Source Status"
                  defaultValue={this.state.filters["status"]}
                  onChange={value => {
                    this._handleFilterChange(value, "status")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="">All</Option>
                  <Option value="ACTIVE">Active</Option>
                  <Option value="IN_ACTIVE">In-Active</Option>
                </Select>
              </Col>
              <Col lg="2" md="3">
                <Select
                  allowClear={true}
                  placeholder="Source Type"
                  defaultValue={this.state.filters["type"]}
                  onChange={value => {
                    this._handleFilterChange(value, "type")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="">All</Option>
                  <Option value="LABELLING">Labelling</Option>
                  <Option value="MODERATION">Moderation</Option>
                  <Option value="TAXONOMY">Taxonomy</Option>
                  <Option value="TAXONOMY_QC">Taxonomy QC</Option>
                  <Option value="TAXONOMY_INTERNAL_QC">
                    Taxonomy Internal QC
                  </Option>
                  <Option value="TRENDING">Trending</Option>
                  <Option value="REVIEW_INTERESTS">Review Interests</Option>
                  <Option value="SEED_INTERESTS">Seed Interests</Option>
                </Select>
              </Col>
              <Col lg="2" md="3">
                <Input
                  placeholder="Display Name"
                  onChange={e => {
                    this._handleFilterChange(e.target.value, "displayName")
                  }}
                  defaultValue={this.state.filters["displayName"]}
                />
              </Col>
              <Col lg="2" md="3">
                <Input.Group compact={true}>
                  <Select
                    allowClear={true}
                    defaultValue={this.state.filters["orderByField"]}
                    placeholder="Order By"
                    onChange={value => {
                      this._handleFilterChange(value, "orderByField")
                    }}
                    style={{ width: "50%" }}
                  >
                    <Option value="modifiedOn">Modified Date</Option>
                    <Option value="createdOn">Created Date</Option>
                  </Select>
                  <Select
                    allowClear={true}
                    defaultValue={this.state.filters["order"]}
                    placeholder="Order"
                    onChange={value => {
                      this._handleFilterChange(value, "order")
                    }}
                    style={{ width: "50%" }}
                  >
                    <Option value="asc">Ascending</Option>
                    <Option value="desc">Descending</Option>
                  </Select>
                </Input.Group>
              </Col>
              <Col lg="2" className="d-flex justify-content-end">
                <Button
                  onClick={this._onSourceSearch}
                  variant="contained"
                  size="small"
                  color="success"
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div
          className="d-flex justify-content-end mb-3"
          onClick={() => this._upsertSource("create", null)}
        >
          <Button variant="contained">Create</Button>
        </div>
      </>
    )
  }

  _viewQuery = obj => {
    this.setState(
      {
        queryObject: obj,
      },
      () => {
        this.setState({
          showQuery: true,
        })
      }
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/source/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(getSourceListByFilters(_.deepClean(newFilters)))
      }
    )
  }

  render() {
    let sourceList = this.props.sourceList

    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        <Row>
          {sourceList.length > 0 &&
            !this.state.showForm &&
            !this.state.showQuery && (
              <Col>
                {/* <SimpleBar style={{ height: "70vh" }}> */}
                <div className="source-card-container">
                  {sourceList.map((source, index) => (
                    <SourceCard
                      source={source}
                      key={`${index}_${source.title}`}
                      editClick={this._upsertSource}
                      viewQuery={this._viewQuery}
                    />
                  ))}
                </div>
                <div className="d-flex justify-content-end pb-3">
                  <Pagination
                    style={{ marginTop: "1rem" }}
                    onChange={this._onPageChange}
                    defaultCurrent={this.state.filters.currentPage}
                    total={this.props.total < 10000 ? this.props.total : 10000}
                    pageSize={this.state.filters.pageSize}
                    showSizeChanger={false}
                  />
                </div>
              </Col>
            )}
          {this.state.showForm && (
            <UpsertSourceForm
              action={this.state.action}
              onSubmit={this._onSubmit}
              source={this.state.source}
              onCancel={this._onCancel}
            />
          )}
          {this.state.showQuery && (
            <Col>
              <Card style={{ maxHeight: "65vh", overflow: "scroll" }}>
                <div className="d-flex justify-content-end m-2">
                  <Button color="danger" onClick={this._onCancel}>
                    Cancel
                  </Button>
                </div>
                <ReactJSON src={JSON.parse(this.state.queryObject)} />\
                <div className="d-flex justify-content-end m-2">
                  <Button color="danger" onClick={this._onCancel}>
                    Cancel
                  </Button>
                </div>
              </Card>
            </Col>
          )}
        </Row>
      </Auxiliary>
    )
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "source.loading"),
    sourceList: _.get(store, "source.sourceList"),
    total: _.get(store, "source.total"),
    refetchData: _.get(store, "source.refetchData"),
  }
}

export default connect(mapStateToProps)(withRouter(ManageSource))
