import React, { createRef } from "react"
import { connect } from "react-redux"
import MuiSwitch from "@mui/material/Switch"
import ReactTimeAgo from "react-time-ago"
import CopyToClipboard from "react-copy-to-clipboard"
import QueryString from "query-string"
import _, { add, capitalize, toUpper } from "lodash"
import moment from "moment"
import ErrorBoundComponent from "../ErrorBoundComponent"
import {
  updateContent,
  updateTag,
  updateAction,
  updateContentZones,
} from "appRedux/slices/content"
import { updateCtaEntity } from "appRedux/slices/cta"
import { getTrendingEntityByFilters } from "appRedux/slices/trending"
import { createReview } from "../../appRedux/slices/review"
import Timer from "react-compound-timer"
import Rating from "react-rating"
import { Card, CardBody, Col, Row, Badge } from "reactstrap"
import { Button as AntdButton, Input, Divider } from "antd"
import InlineEdit from "../InlineEdit/inlineEdit"
import { formatNumber, isJson } from "../../util/common"
import ActionButtons from "../ActionButtons/actionButtons"
import SimpleBar from "simplebar-react"
import { withRouter } from "react-router-dom"
import ActionForm from "./actionForm"
import ValidatorForm, {
  Switch,
  TextField,
  Select,
} from "../../containers/ValidatorForm"
import { Button, FormControlLabel } from "@mui/material"
import { getAllZones } from "appRedux/slices/zone"
import {
  activateDeactivateContent,
  tagContent,
  tagNonEnglish,
  reProcessedContentModerationAction,
  convertToVertical,
  qualityCheckContent,
  reviewContent,
  removeDuplicate,
  enlistContentAsShoppable,
  markContentAsUnShoppable,
  sendNotificationContentDeactivationAlert,
  restoreContent,
} from "../../clientServices/contentService"
import "../../containers/Content/browseContent.style.css"
import Categories from "../../constants/categories.json"
import {
  LANGUAGES,
  LANGUAGE_MAPPING,
  DEACTIVATE_REASONS,
  SOURCES,
  NO_PERMISSION_MESSAGE,
  labelsForViolenceAndGlamour,
  tagTOLabel,
  CONTENT_TYPE,
  emailListForNotifWorthyDeactivation,
  MODERATION_LEVEL_MAP,
  competition_logos,
  banned_organizations,
  CTA_DEFAULT_DATA,
} from "../../constants/uiConstants"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Tooltip, Tag } from "antd"
import Timelines from "components/Timeline/Timelines"
import WarningMessage from "../../containers/Content/warningMessageModal"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import ReactSelect from "react-select"
import { getBrandCampaign } from "appRedux/slices/campaign"
import { MdArrowDownward, MdArrowUpward } from "react-icons/md"
import { Player } from "@lottiefiles/react-lottie-player"
import { getInterest } from "clientServices/interestServices"

const currentDate = Date.now()

class ContentDetails extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    let like_cta = null
    let comment_cta = null
    let duet_cta = null
    let share_cta = null
    let deeplink_cta = null
    let custom_cta = this.props?.content?.custom_cta_map
      ? Object.values(this.props?.content?.custom_cta_map || {})
      : []
    custom_cta?.forEach(cta => {
      if (cta.type === "LIKE") {
        like_cta = cta
      } else if (cta.type === "COMMENT") {
        comment_cta = cta
      } else if (cta.type === "DUET") {
        duet_cta = cta
      } else if (cta.type === "SHARE") {
        share_cta = cta
      } else if (cta.type === "DEEPLINK") {
        deeplink_cta = cta
      }
    })

    this.state = {
      content: this.props.content,
      interestData: {},
      granularity:
        this.props.content &&
        this.props.content.targeted_locations &&
        this.props.content.targeted_locations.granularity
          ? this.props.content.targeted_locations.granularity
          : "",
      showReasonModal: false,
      deactivatReason: DEACTIVATE_REASONS,
      showVideo: false,
      stateLocationList: [],
      cityLocationList: [],
      playbackRate: this.props.pageType === "tagContent" ? "1.0" : "2.0",
      controls: this.props.currentUser.jotUserType !== 4,
      viral: (this.props.content && this.props.content.viral_meta) || {},
      trending: (this.props.content && this.props.content.trending_meta) || {},
      rating: 0,
      showTimeline: false,
      showTaxonomyTimeline: false,
      hasEditTrendingPermission:
        this.props.currentUser &&
        this.props.currentUser.permissions &&
        (this.props.currentUser.permissions.includes("EDIT_MANAGE_TRENDING") ||
          this.props.currentUser.permissions.includes("EDIT_TAG_TRENDING")),
      hasEditZonePermission:
        this.props.currentUser &&
        this.props.currentUser.permissions &&
        this.props.currentUser.permissions.includes("EDIT_MANAGE_ZONE"),
      greetingCaption: [],
      // taxonomyRating: 0,
      enableLikeCta: like_cta ? true : false,
      enableShareCta: share_cta ? true : false,
      enableCommentCta: comment_cta ? true : false,
      enableDuetCta: duet_cta ? true : false,
      enableDeeplinkCta: deeplink_cta ? true : false,
      like_cta,
      share_cta,
      comment_cta,
      duet_cta,
      deeplink_cta,
      captions: props.content.caption,
      playOriginal: false,
    }
    this.videoRef = createRef()
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      this.setState({ content: this.props.content })
      this.props.dispatch(
        getTrendingEntityByFilters({
          pageSize: 10000,
          page: 1,
          endDate: Date.now().toString(),
        })
      )
      const allinterestdata = await getInterest({})
      let interestData = {}
      for (let i = 0; i < allinterestdata?.result?.data.length; i++) {
        interestData[allinterestdata.result.data[i].id] =
          allinterestdata.result.data[i].name
      }
      this.setState({
        interestData,
      })
      this.props.dispatch(getAllZones())
      this.props.dispatch(getBrandCampaign({ currentPage: 1, pageSize: 10000 }))
      if (!this.props.loading) {
        if (
          _.isEmpty(this.state.stateLocationList) ||
          _.isEmpty(this.state.cityLocationList)
        ) {
          let stateLocationList = []
          let cityLocationList = []
          this.props.locationList.map(location => {
            location.children.map(childrenLocation => {
              let childrenLocationObject = {}
              childrenLocationObject["label"] = childrenLocation.name
              childrenLocationObject["value"] = childrenLocation.dhTagId
              cityLocationList.push(childrenLocationObject)
            })
            let stateLocationObject = {}
            stateLocationObject["label"] = location.name
            stateLocationObject["value"] = location.dhTagId
            stateLocationList.push(stateLocationObject)
          })

          this.setState({
            stateLocationList,
            cityLocationList,
          })
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.common.error) {
      if (prevProps.content.content_uuid !== prevState.content.content_uuid) {
        this.setState({
          content: this.props.content,
          trending:
            (this.props.content && this.props.content.trending_meta) || {},
          viral: (this.props.content && this.props.content.viral_meta) || {},
        })
      }
    }
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      [key]: file,
    })
  }

  formatOptionLabel({ value, label, logoSrc }) {
    return (
      <span>
        {logoSrc ? (
          <img
            src={logoSrc}
            alt={label}
            style={{ hight: "20px", width: "20px" }}
          />
        ) : (
          <i className="fa fa-users" aria-hidden="true"></i>
        )}{" "}
        &nbsp;
        <b>{label}</b>
      </span>
    )
  }
  formatOptionLabelCampaign({ campaign_uuid, title, campaign_name }) {
    return (
      <span>
        <i className="fa fa-users" aria-hidden="true"></i>
        &nbsp;
        <b>{campaign_name}</b>
      </span>
    )
  }
  _renderThumbnail = content => {
    switch (content.format) {
      case "IMAGE":
        return <img src={content.download_url} width="95%" alt="" />
      case "GIF":
        return <img src={content.download_url} width="95%" alt="" />
      default:
        return (
          <img
            src={
              content.animated_icon_url
                ? content.animated_icon_url
                : content.thumbnail_url
                ? content.thumbnail_url
                : ""
            }
            style={{ height: "72vh", width: "40vh" }}
            alt=""
          />
        )
    }
  }
  _handleTimeline = () => {
    this.setState({
      showTimeline: !this.state.showTimeline,
    })
  }
  _handleTaxonomyTimeline = () => {
    this.setState({
      showTaxonomyTimeline: !this.state.showTaxonomyTimeline,
    })
  }

  _contentAction = async (content, action) => {
    const { onTrendingTag = () => {}, hasEditPermission } = this.props
    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    try {
      if (action == "tagNonEnglish") {
        let response = await tagNonEnglish(content, action)
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success(`Content ${action} Successfull`)
          onTrendingTag()
        } else {
          throw Error()
        }
        return
      } else if (action === "convertToVertical") {
        let response = await convertToVertical(content, action)
        if (response && response.status && response.status === 200) {
          toastr.success(
            `Content will be processed for Horizontal to Vertical Conversion`
          )
          onTrendingTag()
        } else {
          throw Error()
        }
        return
      } else if (action === "target_category_v2") {
        await this.setState({
          showActionReasonModal: true,
          contentRow: content,
          contentAction: action,
        })
      } else if (action === "activate") {
        this._handleActivateDeactivateContent(content, "activate")
      } else if (action === "quality_check") {
        const response = await qualityCheckContent(content.content_uuid)
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success("The content is marked as QC done successfully!")
          onTrendingTag()
        }
      } else if (action === "review_content") {
        const response = await reviewContent(content.content_uuid)

        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success("The content is marked as Review done successfully!")
          onTrendingTag()
        }
      } else if (action === "remove_duplicate") {
        const response = await removeDuplicate(content.content_uuid)

        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success("Removed Duplicate Content !!")
        }
      } else if (action === "taxonomyContent") {
        this.setState({
          previewTaxonomyContent: true,
          content: content,
        })
      } else if (action === "action") {
        this.setState({ showActionForm: true })
      } else if (action === "shoppable") {
        const payload = {
          content_uuid: content.content_uuid,
          user_uuid: content.user_profile.user_uuid,
          download_url: content.download_url,
          curated_type: content.user_profile.curated_type,
        }
        const response = await enlistContentAsShoppable(payload)
        if (response && response.status === 200) {
          toastr.success("Content enlisted as shoppable !!")
        }
      } else if (action === "unshoppable") {
        const payload = {
          videos: [content],
          action,
        }
        const response = await markContentAsUnShoppable(payload)
        if (response && response.status === 200) {
          toastr.success("Content removed from shoppable !!")
        }
      } else if (action === "restore_content") {
        const payload = {
          contentUUID: content.content_uuid,
        }
        toastr.info("Action in progress !!")
        const response = await restoreContent(payload)
        if (response && response.status === 200) {
          toastr.success("Content sent for restoration !!")
        }
      } else {
        await this.setState({
          showActionReasonModal: true,
          contentRow: content,
          contentAction: action,
        })
      }
    } catch (error) {
      toastr.error(`Content ${action} Failed`)
    }
  }

  _updateTrending = () => {
    const data = {
      entityData: {
        content_uuid: this.props.content.content_uuid,
        trending_meta: _.deepClean(this.state.trending),
        viral_meta: _.deepClean(this.state.viral),
      },
      deskData: {
        deskId:
          this.props.currentUser &&
          this.props.currentUser.source &&
          this.props.currentUser.source["TRENDING"],
        dataId: this.props.content.content_uuid,
      },
    }

    this.props.dispatch(updateTag(data))
  }

  _updateCaptions = () => {
    const {
      hasEditPermission,
      currentUser: { permissions = [], userId } = {},
    } = this.props

    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    const { content } = this.state
    let contentData = {
      content_title: content.content_title,
      captions: this.state.captions,
    }
    this.props.dispatch(
      updateContent({
        contentId: this.state.content.content_uuid,
        contentData,
      })
    )
  }

  _updateZone = () => {
    const {
      hasEditPermission,
      currentUser: { permissions = [], userId } = {},
    } = this.props

    if (!hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    let sub_type_zone = []
    let updated_all_active_zones = this.state?.content?.active_zones
      ? this.state?.content?.active_zones
      : []
    let updated_active_zones = []

    let relational_zones = []

    // check for valid zone
    for (let i = 0; i < updated_all_active_zones.length; i++) {
      for (let j = 0; j < this.props.zoneList.length; j++) {
        if (updated_all_active_zones[i] == this.props.zoneList[j].zone_uuid) {
          updated_active_zones = [
            ...updated_active_zones,
            this.props.zoneList[j].zone_uuid,
          ]
        }
      }
    }

    let validZones = [...updated_active_zones]

    validZones.map(item => {
      this.props.zoneList.map(zone => {
        if (item == zone.zone_uuid) {
          if (zone.level == 2) {
            updated_active_zones = [
              ...updated_active_zones,
              ...zone.sub_type,
              ...zone.type,
            ]
            sub_type_zone = [...sub_type_zone, ...zone.sub_type]
            relational_zones = [
              ...relational_zones,
              ...zone.sub_type,
              ...zone.type,
            ]
          } else if (zone.level == 1) {
            updated_active_zones = [...updated_active_zones, ...zone.type]
            relational_zones = [...relational_zones, ...zone.type]
          }
        }
      })
    })

    sub_type_zone.map(item => {
      this.props.zoneList.map(zone => {
        if (item == zone.zone_uuid) {
          if (zone.level == 1) {
            updated_active_zones = [...updated_active_zones, ...zone.type]
            relational_zones = [...relational_zones, ...zone.type]
          }
        }
      })
    })

    const content = _.cloneDeep(this.state.content)

    const newActiveSet = new Set(updated_active_zones)
    if (!this.state.content.input_zones) {
      content.input_zones = [...this.state.content.active_zones]
    }

    const newInputSet = new Set(content.input_zones)
    content.input_zones = [...newInputSet]

    let contentData = {}
    const activeZones = [...newActiveSet]
    const inputZones = [...newInputSet]
    let zoneInfo = content.zone_info_v1 || []

    //zoneInfo for Active Zone
    if (zoneInfo?.length > 0) {
      let added_type = ""
      if (!_.isEmpty(activeZones)) {
        for (let activeZone of activeZones) {
          let zoneHandled = false

          if (relational_zones.includes(activeZone)) {
            added_type = "relational"
          } else {
            added_type = "input"
          }
          for (let zone of zoneInfo) {
            if (zone.zone_id === activeZone) {
              zone.shouldRetrieve = true
              zoneHandled = true
              if (
                zone.status === "system_removed" ||
                zone.status === "human_removed" ||
                zone.status === "removed"
              ) {
                zone.status = "approved"
                zone.added_type = added_type
                zone.modified_on = moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30")
                zone.modified_by = this.props.currentUser.email
              }
            }
          }

          if (!zoneHandled) {
            zoneInfo.push({
              zone_id: activeZone,
              status: "approved",
              source: "UGC",
              added_type: added_type,
              added_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_by: this.props.currentUser.email,
              shouldRetrieve: true,
            })
          }
        }

        for (let zone of zoneInfo) {
          if (!zone.shouldRetrieve) {
            if (relational_zones.includes(zone)) {
              added_type = "relational"
            } else {
              added_type = "input"
            }
            zone.status = "removed"
            zone.added_type = added_type
            zone.modified_by = this.props.currentUser.email
            zone.modified_on = moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30")
          }
          // delete zone?.shouldRetrieve
        }
      } else {
        for (let zone of zoneInfo) {
          if (relational_zones.includes(zone)) {
            added_type = "relational"
          } else {
            added_type = "input"
          }
          zone.status = "removed"
          zone.added_type = added_type
          zone.modified_on = moment()
            .tz("Asia/Calcutta")
            .format("YYYY-MM-DDTHH:mm:ss+05:30")
          zone.modified_by = this.props.currentUser.email
        }
      }
    } else {
      if (!_.isEmpty(activeZones)) {
        for (let zone of activeZones) {
          let added_type = ""
          if (relational_zones.includes(zone)) {
            added_type = "relational"
          } else {
            added_type = "input"
          }
          zoneInfo.push({
            zone_id: zone,
            status: "approved",
            source: "UGC",
            added_type: added_type,
            added_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_by: this.props.currentUser.email,
            shouldRetrieve: true,
          })
        }
      }
    }

    //zoneInfo for Input Zone
    if (zoneInfo?.length > 0) {
      if (!_.isEmpty(inputZones)) {
        for (let inputZone of inputZones) {
          let zoneHandled = false

          for (let zone of zoneInfo) {
            if (zone.zone_id === inputZone) {
              zone.shouldRetrieve = true
              zoneHandled = true
              if (
                zone.status === "system_removed" ||
                zone.status === "human_removed" ||
                zone.status === "removed"
              ) {
                zone.status = "added"
                zone.added_type = "input"
                zone.modified_on = moment()
                  .tz("Asia/Calcutta")
                  .format("YYYY-MM-DDTHH:mm:ss+05:30")
                zone.modified_by = this.props.currentUser.email
              }
            }
          }

          if (!zoneHandled) {
            zoneInfo.push({
              zone_id: inputZone,
              status: "added",
              source: "UGC",
              added_type: "input",
              added_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_by: this.props.currentUser.email,
              shouldRetrieve: true,
            })
          }
        }

        for (let zone of zoneInfo) {
          if (!zone.shouldRetrieve) {
            zone.added_type = "input"
            zone.status = "removed"
            zone.modified_on = moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30")
            zone.modified_by = this.props.currentUser.email
          }
          delete zone?.shouldRetrieve
        }
      } else {
        for (let zone of zoneInfo) {
          zone.added_type = "input"
          zone.status = "removed"
          zone.modified_on = moment()
            .tz("Asia/Calcutta")
            .format("YYYY-MM-DDTHH:mm:ss+05:30")
          zone.modified_by = this.props.currentUser.email
        }
      }
    } else {
      if (!_.isEmpty(inputZones)) {
        for (let zone of inputZones) {
          zoneInfo.push({
            zone_id: zone,
            status: "added",
            source: "UGC",
            added_type: "input",
            added_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_by: this.props.currentUser.email,
          })
        }
      }
    }

    if (!_.isEmpty(activeZones)) {
      content.input_zones = [...content.input_zones, ...activeZones]
    }

    for (let zone of zoneInfo) {
      delete zone?.shouldRetrieve
    }
    contentData["input_zones"] = [...new Set(content.input_zones)]
    contentData["active_zones"] = activeZones
    contentData["content_uuid"] = this.state.content.content_uuid
    contentData["zone_info"] = zoneInfo
    this.setState(
      {
        content: {
          ...content,
          active_zones: [...newActiveSet],
        },
      },
      this.props.dispatch(updateContentZones(contentData))
    )
  }

  _updateContent = (field, value) => {
    const {
      hasEditPermission,
      currentUser: { permissions = [], userId } = {},
    } = this.props
    const {
      user_profile: { community_manager_id = "", community_lead_id = "" } = {},
    } = this.state.content || {}
    const special_permissions_for_community =
      ["content_title", "tags"].includes(field) &&
      (permissions.includes("EDIT_CONTENT_TITLE") ||
        permissions.includes("EDIT_TAG_TITLE")) &&
      (community_manager_id == userId || community_lead_id == userId)

    if (!hasEditPermission && !special_permissions_for_community) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }

    const { content } = this.state
    let contentData = {
      content_title: content.content_title,
    }

    if (field === "location" && value.length > 0) {
      if (
        content &&
        content.targeted_locations &&
        content.targeted_locations.granularity === "STATE"
      ) {
        contentData.state_ids = value
        contentData.granularity = "STATE"
      } else {
        contentData.granularity = "CITY"
        contentData.state_ids = []
        contentData.city_ids = []
        for (let city of value) {
          if (
            !contentData.state_ids.includes(
              city.split("_")[0].replace("c", "s")
            )
          ) {
            contentData.state_ids.push(city.split("_")[0].replace("c", "s"))
          }
          contentData.city_ids.push(city)
        }
      }
    } else {
      contentData[field] = value
    }
    this.props.dispatch(
      updateContent({
        contentId: this.state.content.content_uuid,
        contentData,
      })
    )
  }

  _handleActivateDeactivateContent = async (content, contentAction) => {
    const { onTrendingTag = () => {} } = this.props
    try {
      let response = await activateDeactivateContent(
        content,
        contentAction,
        this.props.currentUser
      )
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status.code === 200
      ) {
        toastr.success(`Content ${contentAction}d Successfully`)
        if (this.state.isNotifWorthyContent && contentAction === "deactivate") {
          await sendNotificationContentDeactivationAlert({
            contentData: {
              id: content.content_uuid,
              deactivatedBy:
                this.props.currentUser && this.props.currentUser.email,
              title: content.content_title,
            },
            emailList: emailListForNotifWorthyDeactivation,
          })
        }
        onTrendingTag()
        if (
          ((this.props.currentUser.permissions &&
            this.props.currentUser.permissions.indexOf("VIEW_ACTIVITY") >= 0) ||
            !this.props.currentUser.agency) &&
          !content.qc_done &&
          (content.moderation_level === 2 || content.moderation_level === -1)
        ) {
          await qualityCheckContent(content.content_uuid)
        }
      } else {
        if (response && response.message.includes("401")) {
          toastr.error("User Not Verified!!")
        } else {
          throw new Error()
        }
      }
    } catch (error) {
      toastr.error(`Content ${contentAction} Failed`)
      this.setState({ reject_reason: "", target_category: "" })
    } finally {
      this.setState({
        actionButtonLoading: false,
      })
    }
  }

  _contentActionApi = async (content, action) => {
    try {
      this.setState({ actionButtonLoading: true })
      if (action == "tagNonEnglish") {
        let response = await tagNonEnglish(content, action)
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success(`Content Tagged as Non English Successfully`)
        } else {
          throw Error()
        }
        return
      }

      if (this.state.contentAction === "target_category_v2") {
        let response = await tagContent({
          content_uuid: content.content_uuid,
          target_category_v2: this.state.target_category_v2,
        })
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.status.code === 200
        ) {
          toastr.success(`Content Tagged Successfully`)
        } else {
          throw Error()
        }
        return
      }
      let contentDuplicate = { ...content }
      if (action == "deactivate") {
        contentDuplicate["reject_reason"] = this.state.reject_reason
        if (contentDuplicate["reject_reason"] == "misc") {
          contentDuplicate["miscRejectReason"] = this.state.miscRejectReason
        }
        if (contentDuplicate["reject_reason"] == "competitor_logo") {
          contentDuplicate["competitor_logo_name"] =
            this.state.competitor_logo_name
          if (contentDuplicate["competitor_logo_name"] == "others") {
            contentDuplicate["competitor_logo_name_text"] =
              this.state.competitor_logo_name_text
          }
        }
        if (contentDuplicate["reject_reason"] == "banned_organization") {
          contentDuplicate["banned_organization_name"] =
            this.state.banned_organization_name
        }
      } else {
        contentDuplicate["target_category"] = this.state.target_category
      }
      this._handleActivateDeactivateContent(
        contentDuplicate,
        this.state.contentAction
      )
    } catch (error) {
      toastr.error(`Content ${this.state.contentAction} Failed`)
      this.setState({ reject_reason: "", target_category: "" })
    } finally {
      this.setState({
        actionButtonLoading: false,
      })
    }
  }

  _deactivateContentModal = content => {
    let radioList = this.state.deactivatReason

    return (
      <div className="row-cols-2 d-flex justify-content-end">
        <Card className="shadow-lg border-2 mt-2">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="text-dark">De Activate Reasons</div>
              <div
                className="avatar-xs"
                role="button"
                onClick={() => this.setState({ showReasonModal: false })}
              >
                <div className="avatar-title rounded-circle bg-light">
                  <span
                    className={
                      "avatar-title rounded-circle bg-light bg-" +
                      "secondary" +
                      " text-dark" +
                      " font-size-16" +
                      " font-weight-semibold"
                    }
                  >
                    <i className="fas fa-times-circle" />
                  </span>
                </div>
              </div>
            </div>
            <Row>
              {radioList.map((reason, index) => {
                return (
                  <>
                    <Col lg={6} xl={6} sm={12}>
                      <div className="form-check form-radio-outline form-radio-primary mb-3">
                        <input
                          type="radio"
                          id={`radio${index}`}
                          name="customRadiooutlinecolor1"
                          className="form-check-input"
                          value={reason.value}
                          onChange={e =>
                            this.setState({ reject_reason: e.target.value })
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`radio${index}`}
                        >
                          {reason.label}
                        </label>
                      </div>
                    </Col>
                  </>
                )
              })}
            </Row>
            <Row className="mb-1">
              {this.state.reject_reason == "competitor_logo" ? (
                <Col lg={6} xl={6} sm={12}>
                  <ReactSelect
                    style={{ width: "100%" }}
                    isMulti={false}
                    options={competition_logos}
                    formatOptionLabel={this.formatOptionLabel}
                    onChange={value =>
                      this.setState({
                        competitor_logo_name: value.value,
                      })
                    }
                    placeholder="Select Logo..."
                  />
                </Col>
              ) : null}
              {this.state.competitor_logo_name === "others" ? (
                <Col>
                  <Input
                    placeholder={"Enter Logo..."}
                    // onChange={e =>
                    //   props.handleFilterChange(e.target.value, "name")
                    // }
                    // size="small"
                    className="h-100"
                    allowClear={true}
                    name="logo"
                    required
                    style={{ width: "100%" }}
                    onChange={e => {
                      this.setState({
                        competitor_logo_name_text: e.target.value,
                      })
                    }}
                  />
                </Col>
              ) : null}
              {this.state.reject_reason == "banned_organization" ? (
                <Col lg={24} xl={24} sm={24}>
                  <ReactSelect
                    style={{ width: "100%" }}
                    isMulti={false}
                    options={banned_organizations}
                    onChange={value =>
                      this.setState({
                        banned_organization_name: value.value,
                      })
                    }
                    placeholder="Select Organization..."
                  />
                </Col>
              ) : null}
            </Row>

            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  this.setState({ showReasonModal: false }, () => {
                    if (!this.props.hasEditPermission) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                    } else {
                      this._contentActionApi(
                        this.state.content,
                        this.state.contentAction
                      )
                    }
                  })
                }
                // role="button"
                className="btn btn-danger d-flex mx-1"
                disabled={
                  (this.state.reject_reason == "competitor_logo" &&
                    !this.state.competitor_logo_name) ||
                  (this.state.reject_reason == "competitor_logo" &&
                    this.state.competitor_logo_name === "others" &&
                    !this.state.competitor_logo_name_text) ||
                  (this.state.reject_reason == "banned_organization" &&
                    !this.state.banned_organization_name)
                }
              >
                De Activate
              </button>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }

  _setPlayBack = () => {
    this.videoRef.current.playbackRate = this.state.playbackRate
  }

  _deativateReason = (content, action, isNotifWorthyContent) => {
    this.setState({
      showReasonModal: isNotifWorthyContent ? false : true,
      content: content,
      contentAction: action,
      isNotifWorthyContent,
      showNotificationPopup: isNotifWorthyContent ? true : false,
    })
  }

  _getTrendingEntities = () => {
    const options = this.props.trendingEntityList.map(entity => {
      const endDate = new Date(entity.end_date * 1)
      return {
        label: entity.title,
        value: entity.entity_uuid,
        heighlight: entity.end_date - currentDate > 86400000 ? false : true,
        text: entity.end_date && (
          <div className="font-size-10 text-muted">
            {entity.end_date < currentDate ? "Ended " : "Ending "}
            {endDate ? (
              <ReactTimeAgo
                future={!(entity.end_date < currentDate)}
                date={Number.isNaN(endDate) ? Date.parse(endDate) : endDate}
                locale="en-US"
              />
            ) : null}
          </div>
        ),
      }
    })
    return options
  }

  _getDefault = (val, type) => {
    let defaultValue = ""
    const reff = type === "entity" ? this._getTrendingEntities() : SOURCES
    reff.forEach(element => {
      if (element.value === val) {
        defaultValue = element.label
      }
    })
    return defaultValue
  }

  _reProcessedModerationAction = async (content, action) => {
    try {
      let response = await reProcessedContentModerationAction(content, action)
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status.code === 200
      ) {
        toastr.success(`Content ${action} Successfull`)
        this.setState({
          selectedContent: this.state.previewContent
            ? this.state.selectedContent.next
            : "",
        })
      } else {
        throw Error()
      }
    } catch (error) {
      toastr.error(`Content ${action} Failed`)
    }
  }

  _viewUserProfile = () => {
    const { user_profile = {} } = this.state.content
    if (user_profile && !_.isEmpty(user_profile.user_uuid)) {
      this.props.history.push(
        `/appuser/details?userUuid=${user_profile.user_uuid}`
      )
    }
  }

  _onActionSubmit = details => {
    let data = {
      content_uuid: this.state.content.content_uuid,
      details: _.deepClean(details),
    }
    this.props.dispatch(updateAction(data))
    this.setState({ showActionForm: false })
  }

  _onReviewClick = (id, taxonomyRating) => {
    let data = {
      requestedOn: id,
      requestedBy: this.props.currentUser && this.props.currentUser.userId,
      status: "REQUESTED",
      rating: taxonomyRating,
    }
    this.props.dispatch(createReview(data))
  }

  _renderTaxonomyDetails = (metaInfo, zoneList = undefined) => {
    const genre = metaInfo?.taxonomy_genre?.target_type
      ? capitalize(metaInfo.taxonomy_genre.target_type.split("_").join(" "))
      : "N/A"
    const violenceType = metaInfo?.taxonomy_video_quality?.video_violence?.type
    const glamourType = metaInfo?.taxonomy_video_quality?.video_glamour?.type
    const glamourGrade = metaInfo?.taxonomy_video_quality?.video_glamour?.grade
    const glamourRegion =
      metaInfo?.taxonomy_video_quality?.video_glamour?.region
    const glamourSubType =
      metaInfo?.taxonomy_video_quality?.video_glamour?.sub_type
    let glamour = glamourSubType
      ? glamourType == "acceptable_child_nudity" ||
        glamourType == "partial_adult_nudity" ||
        glamourType == "unacceptable_child_nudity" ||
        glamourType == "full_adult_nudity"
        ? labelsForViolenceAndGlamour["glamour"][
            `${glamourType}_${glamourSubType}`
          ]
        : labelsForViolenceAndGlamour["glamour"][glamourType]
      : labelsForViolenceAndGlamour["glamour"][glamourType]

    const peopleInVideo = metaInfo?.taxonomy_elements?.people_in_video
      ? metaInfo.taxonomy_elements.people_in_video
          .split("_")
          .map(item => capitalize(item))
          .join(" ")
      : "N/A"
    const targetLanguages = metaInfo?.target_languages?.value
      ? metaInfo.target_languages.value
          .map(langCode => LANGUAGE_MAPPING[langCode])
          .join(", ")
      : "N/A"
    const emotions = metaInfo?.taxonomy_emotions?.type_v2
      ? metaInfo.taxonomy_emotions.type_v2
          .map(emotion => capitalize(emotion))
          .join(", ")
      : "N/A"
    const background = metaInfo?.taxonomy_elements?.background
      ? capitalize(metaInfo.taxonomy_elements.background.split("_").join(" "))
      : "N/A"
    const cityTier = metaInfo?.taxonomy_elements?.city_tier
      ? capitalize(metaInfo.taxonomy_elements.city_tier.split("_").join(" "))
      : "N/A"
    const audioVulgarity = metaInfo?.taxonomy_video_quality?.audio_vulgarity_v2
      ? metaInfo.taxonomy_video_quality.audio_vulgarity_v2
          .map(item => capitalize(item.split("_").join(" ")))
          .join(", ")
      : "N/A"
    const ethnicity = metaInfo?.video_additional_info?.ethnicity
      ? capitalize(metaInfo.video_additional_info.ethnicity)
      : "N/A"
    const people_count = metaInfo?.video_additional_info?.people_count
      ? capitalize(metaInfo.video_additional_info.people_count)
      : "N/A"
    return (
      <React.Fragment>
        <div className="grid-container">
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Genre</div>
            <div>{genre}</div>
          </div>
          <div className="d-flex   align-items-center justify-content-between grid-child">
            <div>Target Languages</div>
            <div>{targetLanguages}</div>
          </div>
          <div className="d-flex   align-items-center justify-content-between grid-child">
            <div>Emotions</div>
            <div>{emotions}</div>
          </div>
          <div className="d-flex   align-items-center justify-content-between grid-child">
            <div>Audio Vulgariy</div>
            <div>{audioVulgarity}</div>
          </div>
        </div>
        <div className="d-flex justify-content-center text-muted font-size-16">
          <div>
            <u>Elements</u>
          </div>
        </div>
        <div className="grid-container">
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Background</div>
            <div>{background}</div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>People In Video</div>
            <div>{peopleInVideo}</div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>City Tier</div>
            <div>{cityTier}</div>
          </div>
        </div>
        <div className="d-flex justify-content-center text-muted font-size-16">
          <div>
            <u>Quality Attributes</u>
          </div>
        </div>
        <div className="grid-container">
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Presentable</div>
            <div>
              {metaInfo?.video_quality_attributes?.presentable ? "Yes" : "No"}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Relatable</div>
            <div>
              {metaInfo?.video_quality_attributes?.realatable ? "Yes" : "No"}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Quality Content</div>
            <div>
              {metaInfo?.video_quality_attributes?.quality_talent
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Surprise Element</div>
            <div>
              {metaInfo?.video_quality_attributes?.surprise_element
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Simple</div>
            <div>
              {metaInfo?.video_quality_attributes?.simple ? "Yes" : "No"}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center text-muted font-size-16">
          <div>
            <u>Orthogonal Attributes</u>
          </div>
        </div>

        <div className="grid-container">
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Acting</div>
            <div>
              {metaInfo?.orthogonal_attributes?.includes("acting")
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Music</div>
            <div>
              {metaInfo?.orthogonal_attributes?.includes("music")
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Singing</div>
            <div>
              {metaInfo?.orthogonal_attributes?.includes("singing")
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Dialogue</div>
            <div>
              {metaInfo?.orthogonal_attributes?.includes("dialogue")
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Original Perfomance</div>
            <div>
              {metaInfo?.orthogonal_attributes?.includes("original_performance")
                ? "Yes"
                : "No"}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center text-muted font-size-16">
          <div>
            <u>Video Additional Information</u>
          </div>
        </div>

        <div className="grid-container">
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>Ethnicity</div>
            <div>{ethnicity}</div>
          </div>
          <div className="d-flex align-items-center justify-content-between grid-child">
            <div>People Count</div>
            <div>{people_count}</div>
          </div>
        </div>
        <div className="d-flex justify-content-center text-muted font-size-16">
          <div>
            <u>Violence</u>
          </div>
        </div>
        <div className="grid-child">
          {violenceType === "no_violence" || violenceType === undefined ? (
            <div>
              There is <b>NO</b> violence in this video
            </div>
          ) : (
            <p>{labelsForViolenceAndGlamour["violence"][violenceType]}</p>
          )}
        </div>
        <div className="d-flex justify-content-center text-muted font-size-16">
          <div>
            <u>Glamour</u>
          </div>
        </div>
        <div className="grid-child">
          {glamourType === "non_glamour" || glamourType === undefined ? (
            <div>
              There is <b>NO</b> glamour in this video
            </div>
          ) : (
            <>
              <p>{glamour}</p>
              {glamourGrade && (
                <p>
                  Grade is<b> {toUpper(glamourGrade)}</b>
                </p>
              )}
              {glamourRegion && (
                <p>
                  Region is <b>{toUpper(glamourRegion.split("_").join(" "))}</b>
                </p>
              )}
            </>
          )}
        </div>
        {zoneList ? (
          <>
            <div className="d-flex justify-content-center text-muted font-size-16">
              <div>
                <u>Zone</u>
              </div>
            </div>
            <div>
              {zoneList && zoneList.length > 0 ? (
                <>
                  <div
                    className="grid-container-6-col"
                    style={{ gridTemplateColumn: "repeat(4, 1fr)" }}
                  >
                    <div
                      className="d-flex align-items-center justify-content-between grid-child font-weight-semibold"
                      style={{ width: "100px" }}
                    >
                      <div className="text-truncate" title="Zone Id">
                        Zone Id
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between grid-child font-weight-semibold"
                      style={{ width: "70px" }}
                    >
                      <div className="text-truncate" title="Status">
                        Status
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between grid-child font-weight-semibold"
                      style={{ width: "70px" }}
                    >
                      <div className="text-truncate" title="Source">
                        Source
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between grid-child font-weight-semibold"
                      style={{ width: "70px" }}
                    >
                      <div className="text-truncate" title="Added Type">
                        Type
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between grid-child font-weight-semibold"
                      style={{ width: "100px" }}
                    >
                      <div className="text-truncate" title="Modified By">
                        Modified By
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between grid-child font-weight-semibold"
                      style={{ width: "100px" }}
                    >
                      <div className="text-truncate" title="Modified On">
                        Modified On
                      </div>
                    </div>
                  </div>
                  {zoneList.map(zone => (
                    <div
                      className="grid-container-6-col"
                      style={{ gridTemplateColumn: "repeat(4, 1fr)" }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-between grid-child"
                        style={{ width: "100px" }}
                      >
                        <div className="text-truncate" title={zone.zone_id}>
                          {zone.zone_id}
                        </div>
                        <CopyToClipboard
                          text={zone.zone_id}
                          onCopy={e => {
                            toastr.success("Copied sucessfully!")
                          }}
                        >
                          <Tooltip title="Copy Zone Id">
                            <i
                              className="bx bx-copy-alt bx-xxs"
                              role="button"
                            />
                          </Tooltip>
                        </CopyToClipboard>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between grid-child"
                        style={{ width: "70px" }}
                      >
                        <div className="text-truncate" title={zone.status}>
                          {zone.status}
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between grid-child"
                        style={{ width: "70px" }}
                      >
                        <div className="text-truncate" title={zone.source}>
                          {zone.source}
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between grid-child"
                        style={{ width: "70px" }}
                      >
                        <div className="text-truncate" title={zone.added_type}>
                          {zone.added_type}
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between grid-child"
                        style={{ width: "100px" }}
                      >
                        <div className="text-truncate" title={zone.modified_by}>
                          {zone.modified_by}
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-between grid-child"
                        style={{ width: "100px" }}
                      >
                        <div
                          className="text-truncate"
                          title={new Date(zone.modified_on).toDateString()}
                        >
                          {new Date(zone.modified_on).toDateString()}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="grid-child">
                  There is <b>NO</b> zone in this video
                </div>
              )}
            </div>
          </>
        ) : null}
      </React.Fragment>
    )
  }

  deepDiffMapper = (function () {
    return {
      VALUE_CREATED: "created",
      VALUE_UPDATED: "updated",
      VALUE_DELETED: "deleted",
      VALUE_UNCHANGED: "unchanged",
      map: function (obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw "Invalid argument. Function given, object expected."
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          return {
            type: this.compareValues(obj1, obj2),
            data: obj2 === undefined ? undefined : obj2,
          }
        }

        var diff = {}
        for (var key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue
          }

          var value2 = undefined
          if (obj2[key] !== undefined) {
            value2 = obj2[key]
          }

          diff[key] = this.map(obj1[key], value2)
        }
        for (var key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue
          }

          diff[key] = this.map(undefined, obj2[key])
        }

        return diff
      },
      compareValues: function (value1, value2) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED
        }
        if (
          this.isDate(value1) &&
          this.isDate(value2) &&
          value1.getTime() === value2.getTime()
        ) {
          return this.VALUE_UNCHANGED
        }
        if (value1 === undefined) {
          return this.VALUE_CREATED
        }
        if (value2 === undefined) {
          return this.VALUE_DELETED
        }
        return this.VALUE_UPDATED
      },
      isFunction: function (x) {
        return Object.prototype.toString.call(x) === "[object Function]"
      },
      isArray: function (x) {
        return Object.prototype.toString.call(x) === "[object Array]"
      },
      isDate: function (x) {
        return Object.prototype.toString.call(x) === "[object Date]"
      },
      isObject: function (x) {
        return Object.prototype.toString.call(x) === "[object Object]"
      },
      isValue: function (x) {
        return !this.isObject(x) && !this.isArray(x)
      },
    }
  })()

  modifiedContent = item => {
    let name, action, from, to, showData
    if (item.action && typeof item.action == "string") {
      if (item.action == "created") {
        name = tagTOLabel[item.name] ? tagTOLabel[item.name] : item.name
        action = "Created"
        if (typeof item.to == "string") {
          to = item.to
        } else {
          if (item.to?.type) {
            to = item.to.type
          } else {
            let str = ""
            for (let val in item.to) {
              str =
                str +
                (tagTOLabel[val] ? tagTOLabel[val] : val) +
                " : " +
                item.to[val] +
                " "
            }
            to = str
          }
        }
        showData = `${
          name == "type_v2" ? "Taxonomy Emotions" : name
        } is ${action} with values ${tagTOLabel[to] ? tagTOLabel[to] : to}`
        // to = typeof item.to == "string" ? item.to : item.to?.type
      } else if (item.action == "deleted") {
        name = tagTOLabel[item.name] ? tagTOLabel[item.name] : item.name
        action = "Deleted"

        showData = `${
          name == "type_v2" ? "Taxonomy Emotions" : name
        } is ${action}`
      } else {
        name = tagTOLabel[item.name] ? tagTOLabel[item.name] : item.name
        action = "Updated"
        from = item.from
        if (typeof item.to != "object") {
          to = item.to
        } else {
          if (item.to?.type) {
            to = item.to.type
          } else {
            let str = ""
            for (let val in item.to) {
              str =
                str +
                (tagTOLabel[item.to[val]?.data]
                  ? tagTOLabel[item.to[val]?.data]
                  : item.to[val]?.data) +
                " "
            }
            to = str
          }
        }
        showData = `${
          name == "type_v2" ? "Taxonomy Emotions" : name
        } ${action} with values ${
          tagTOLabel[from] ? tagTOLabel[from] : from
        } to ${tagTOLabel[to] ? tagTOLabel[to] : to}`
      }
    }

    return (
      <div className="flex-modify">
        {item.action == "created" ? (
          <i className="bx bx-pencil iconstyle2 green"></i>
        ) : item.action == "deleted" ? (
          <i className="bx bx-trash iconstyle2 red"></i>
        ) : (
          <i className="bx bx-edit iconstyle2 blue"></i>
        )}{" "}
        &nbsp; <span className="mt2"> {showData}</span>
      </div>
    )
  }
  _renderTaxonomyModifyDetails = (metaInfo, zoneList = undefined) => {
    let modifiedObject = this.deepDiffMapper.map(
      metaInfo.old_details,
      metaInfo.details
    )
    var modifiedObj = []

    for (let item in modifiedObject) {
      if (item == "version") {
      } else {
        if (modifiedObject[item].type) {
          if (typeof modifiedObject[item].type == "object") {
            if (
              item == "target_languages" &&
              modifiedObject[item]?.type?.type === "updated"
            ) {
              modifiedObj.push({
                name: item,
                action: modifiedObject[item].type?.type,
                from: "",
                to: modifiedObject[item].value,
              })
            } else {
              if (modifiedObject[item].type?.type === "updated") {
                modifiedObj.push({
                  name: item,
                  action: modifiedObject[item].type?.type,
                  from: modifiedObject[item].type?.data,
                  to: metaInfo.details[item],
                })
              } else if (modifiedObject[item].type?.type !== "unchanged") {
                modifiedObj.push({
                  name: item,
                  action: modifiedObject[item].type?.type,
                  from: "",
                  to: modifiedObject[item].type?.data,
                })
              }
            }
          } else {
            if (modifiedObject[item].type === "updated") {
              modifiedObj.push({
                name: item,
                action: modifiedObject[item].type,
                from: modifiedObject[item].data,
                to: metaInfo.details[item],
              })
            } else if (modifiedObject[item].type !== "unchanged") {
              modifiedObj.push({
                name: item,
                action: modifiedObject[item].type,
                from: "",
                to: modifiedObject[item].data,
              })
            }
          }
        } else {
          for (let items in modifiedObject[item]) {
            if (typeof modifiedObject[item][items].type == "object") {
              if (modifiedObject[item][items].type.type === "  ") {
                modifiedObj.push({
                  name: items,
                  action: modifiedObject[item][items].type.type,
                  from: modifiedObject[item][items].type.data,
                  to: metaInfo.details[item][items],
                })
              } else if (
                modifiedObject[item][items].type.type !== "unchanged"
              ) {
                modifiedObj.push({
                  name: items,
                  action: modifiedObject[item][items].type.type,
                  from: "",
                  to: `${modifiedObject[item][items].type.data}${
                    modifiedObject[item][items].sub_type &&
                    modifiedObject[item][items].sub_type.data
                      ? `_${modifiedObject[item][items].sub_type.data}`
                      : ""
                  }`,
                })
              }
            } else {
              if (modifiedObject[item][items].type === "updated") {
                modifiedObj.push({
                  name: items,
                  action: modifiedObject[item][items].type,
                  from: modifiedObject[item][items].data,
                  to: metaInfo.details[item][items],
                })
              } else if (
                modifiedObject[item][items] &&
                modifiedObject[item][items][0] &&
                modifiedObject[item][items][0].type === "updated"
              ) {
                modifiedObj.push({
                  name: items,
                  action: modifiedObject[item][items][0].type,
                  from: modifiedObject[item][items][0].data,
                  to: metaInfo.details[item][items][0],
                })
              } else if (modifiedObject[item][items].type !== "unchanged") {
                modifiedObj.push({
                  name: items,
                  action: modifiedObject[item][items].type,
                  from: "",
                  to: modifiedObject[item][items].data,
                })
              }
            }
          }
        }
      }
    }

    return (
      <div style={{ fontSize: "13px" }}>
        {modifiedObj.map(item =>
          item.action ? this.modifiedContent(item) : ""
        )}
        {zoneList && zoneList.length ? (
          <>
            <div className="font-weight-semibold my-2">Zone Info:</div>
            {zoneList.map(zone => {
              // const showData = `${zone.zone_id} -> ${zone.status} -> ${zone.source} -> ${zone.modified_by} -> ${new Date(zone.modified_on).toDateString()}`;
              const showData = (
                <span>
                  <span title="Zone Id">{zone.zone_id}</span>
                  <span>{" - "}</span>
                  <span title="Status">{zone.status}</span>
                  <span>{" - "}</span>
                  <span title="Source">{zone.source}</span>
                  <span>{" - "}</span>
                  <span title="Added Type">{zone.added_type}</span>
                  <span>{" - "}</span>
                  <span title="Modified By">{zone.modified_by}</span>
                  <span>{" - "}</span>
                  <span title="Modified On">
                    {new Date(zone.modified_on).toDateString()}
                  </span>
                </span>
              )
              return (
                <div className="flex-modify">
                  <i className="bx bx-hash iconstyle2 text-info"></i> &nbsp;{" "}
                  <span className="mt2"> {showData}</span>
                </div>
              )
            })}
          </>
        ) : null}
      </div>
    )
  }

  _renderWarningMessage = content => {
    return (
      <WarningMessage
        message={
          <div className="px-3">
            <div>
              <b>Notified Content</b>
            </div>
            <div className="pt-2">
              Deactivation Not Allowed.{" "}
              <p>This content has been sent as Notification</p>
            </div>
          </div>
        }
        onCancel={() => this.setState({ showNotificationPopup: false })}
        isVisible={this.state.showNotificationPopup}
        buttons={
          <div className="d-flex justify-content-center pb-3">
            <AntdButton
              type="danger"
              size="small"
              onClick={() => {
                if (
                  this.props.currentUser &&
                  ([1, 2].includes(this.props.currentUser.userType) ||
                    [1, 2].includes(this.props.currentUser.jotUserType) ||
                    (this.props.currentUser.permissions &&
                      this.props.currentUser.permissions.includes(
                        "DEACTIVATE_NOTIFY_WORTHY"
                      )))
                ) {
                  this.setState(
                    {
                      showReasonModal: true,
                      showNotificationPopup: false,
                    },
                    () => this._deactivateContentModal(content)
                  )
                }
                this.setState({ showNotificationPopup: false })
              }}
              style={{ textAlign: "left", fontSize: "16px" }}
              className="pb-4"
            >
              OK
            </AntdButton>
          </div>
        }
      />
    )
  }

  _onSubmitLike = async ({ formData, errors = null } = {}) => {
    if (errors) {
      return
    }
    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }

    const content_uuid = this.state.content.content_uuid
    const content_title = this.state?.content?.content_title || ""
    const tags = this.state?.content?.tags || ""
    let custom_cta = []
    custom_cta =
      this.state?.content?.custom_cta?.filter(cta => cta.type != "LIKE") || []

    if (this.state?.enableLikeCta) {
      let data = { ...(formData?.like_cta || {}) }
      const { type } = data || {}

      if (this.state?.like_cta?.id) {
        data.id = this.state?.like_cta?.id
      }
      if (!data?.title && CTA_DEFAULT_DATA[type]?.title) {
        data.title = CTA_DEFAULT_DATA[type].title || ""
      }

      if (!data?.subtitle && CTA_DEFAULT_DATA[type]?.subtitle) {
        data.subtitle = CTA_DEFAULT_DATA[type].subtitle || ""
      }

      if (
        !this.state.cta_icon_like &&
        (this.state?.like_cta?.icon_url || CTA_DEFAULT_DATA[type]?.icon_url)
      ) {
        data.icon_url =
          this.state?.like_cta?.icon_url ||
          CTA_DEFAULT_DATA[type].icon_url ||
          ""
      }

      if (!data?.cta?.text && CTA_DEFAULT_DATA[type]?.cta?.text) {
        data.cta.text = CTA_DEFAULT_DATA[type].cta.text || ""
      }

      if (
        !data?.cta?.selected_text &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_text
      ) {
        data.cta.selected_text = CTA_DEFAULT_DATA[type].cta.selected_text || ""
      }

      if (!data?.cta?.view_type && CTA_DEFAULT_DATA[type]?.cta?.view_type) {
        data.cta.view_type = CTA_DEFAULT_DATA[type].cta.view_type || ""
      }

      if (
        !this.state.button_icon_like &&
        (this.state?.like_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.icon_url)
      ) {
        data.cta.icon_url =
          this.state?.like_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type].cta.icon_url ||
          ""
      }

      if (
        !this.state.button_selected_icon_like &&
        (this.state?.like_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.selected_icon_url)
      ) {
        data.cta.selected_icon_url =
          this.state?.like_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type].cta.selected_icon_url ||
          ""
      }

      let fData = new FormData()

      custom_cta.push(data)
      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      if (this.state.cta_icon_like) {
        fData.append("cta_icon_like", this.state.cta_icon_like)
      }
      if (this.state.button_icon_like) {
        fData.append("button_icon_like", this.state.button_icon_like)
      }
      if (this.state.button_selected_icon_like) {
        fData.append(
          "button_selected_icon_like",
          this.state.button_selected_icon_like
        )
      }
      this.props.dispatch(updateCtaEntity(fData))
    } else {
      let fData = new FormData()

      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      this.props.dispatch(updateCtaEntity(fData))
    }
  }

  _onSubmitShare = async ({ formData, errors = null } = {}) => {
    if (errors) {
      return
    }

    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    const content_uuid = this.state.content.content_uuid
    const content_title = this.state?.content?.content_title || ""
    const tags = this.state?.content?.tags || ""
    let custom_cta = []
    custom_cta =
      this.state?.content?.custom_cta?.filter(cta => cta.type != "SHARE") || []

    if (this.state?.enableShareCta) {
      let data = { ...(formData?.share_cta || {}) }
      const { type } = data || {}

      if (this.state?.share_cta?.id) {
        data.id = this.state?.share_cta?.id
      }
      if (!data?.title && CTA_DEFAULT_DATA[type]?.title) {
        data.title = CTA_DEFAULT_DATA[type].title || ""
      }

      if (!data?.subtitle && CTA_DEFAULT_DATA[type]?.subtitle) {
        data.subtitle = CTA_DEFAULT_DATA[type].subtitle || ""
      }

      if (
        !this.state.cta_icon_share &&
        (this.state?.share_cta?.icon_url || CTA_DEFAULT_DATA[type]?.icon_url)
      ) {
        data.icon_url =
          this.state?.share_cta?.icon_url ||
          CTA_DEFAULT_DATA[type].icon_url ||
          ""
      }

      if (!data?.cta?.text && CTA_DEFAULT_DATA[type]?.cta?.text) {
        data.cta.text = CTA_DEFAULT_DATA[type].cta.text || ""
      }

      if (
        !data?.cta?.selected_text &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_text
      ) {
        data.cta.selected_text = CTA_DEFAULT_DATA[type].cta.selected_text || ""
      }

      if (!data?.cta?.view_type && CTA_DEFAULT_DATA[type]?.cta?.view_type) {
        data.cta.view_type = CTA_DEFAULT_DATA[type].cta.view_type || ""
      }
      debugger
      if (
        !this.state.button_icon_share &&
        (this.state?.share_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.icon_url)
      ) {
        debugger
        data.cta.icon_url =
          this.state?.share_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type].cta.icon_url ||
          ""
      }
      debugger

      if (
        !this.state.button_selected_icon_share &&
        (this.state?.share_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.selected_icon_url)
      ) {
        debugger

        data.cta.selected_icon_url =
          this.state?.share_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type].cta.selected_icon_url ||
          ""
      }

      let fData = new FormData()

      custom_cta.push(data)
      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      if (this.state.cta_icon_share) {
        fData.append("cta_icon_share", this.state.cta_icon_share)
      }
      if (this.state.button_icon_share) {
        fData.append("button_icon_share", this.state.button_icon_share)
      }
      if (this.state.button_selected_icon_share) {
        fData.append(
          "button_selected_icon_share",
          this.state.button_selected_icon_share
        )
      }
      this.props.dispatch(updateCtaEntity(fData))
    } else {
      let fData = new FormData()

      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      this.props.dispatch(updateCtaEntity(fData))
    }
  }

  _onSubmitComment = async ({ formData, errors = null } = {}) => {
    if (errors) {
      return
    }
    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }
    const content_uuid = this.state.content.content_uuid
    const content_title = this.state?.content?.content_title || ""
    const tags = this.state?.content?.tags || ""
    let custom_cta = []
    custom_cta =
      this.state?.content?.custom_cta?.filter(cta => cta.type !== "COMMENT") ||
      []

    if (this.state?.enableCommentCta) {
      let data = { ...(formData?.comment_cta || {}) }
      const { type } = data || {}

      if (this.state?.comment_cta?.id) {
        data.id = this.state?.comment_cta?.id
      }
      if (!data?.title && CTA_DEFAULT_DATA[type]?.title) {
        data.title = CTA_DEFAULT_DATA[type].title || ""
      }

      if (!data?.subtitle && CTA_DEFAULT_DATA[type]?.subtitle) {
        data.subtitle = CTA_DEFAULT_DATA[type].subtitle || ""
      }

      if (
        !this.state.cta_icon_comment &&
        (this.state?.comment_cta?.icon_url || CTA_DEFAULT_DATA[type]?.icon_url)
      ) {
        data.icon_url =
          this.state?.comment_cta?.icon_url ||
          CTA_DEFAULT_DATA[type].icon_url ||
          ""
      }

      if (!data?.cta?.text && CTA_DEFAULT_DATA[type]?.cta?.text) {
        data.cta.text = CTA_DEFAULT_DATA[type].cta.text || ""
      }

      if (
        !data?.cta?.selected_text &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_text
      ) {
        data.cta.selected_text = CTA_DEFAULT_DATA[type].cta.selected_text || ""
      }

      if (!data?.cta?.view_type && CTA_DEFAULT_DATA[type]?.cta?.view_type) {
        data.cta.view_type = CTA_DEFAULT_DATA[type].cta.view_type || ""
      }

      if (
        !this.state.button_icon_comment &&
        (this.state?.comment_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.icon_url)
      ) {
        data.cta.icon_url =
          this.state?.comment_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type].cta.icon_url ||
          ""
      }

      if (
        !this.state.button_selected_icon_comment &&
        (this.state?.comment_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.selected_icon_url)
      ) {
        data.cta.selected_icon_url =
          this.state?.comment_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type].cta.selected_icon_url ||
          ""
      }

      let fData = new FormData()

      custom_cta.push(data)

      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      if (this.state.cta_icon_comment) {
        fData.append("cta_icon_comment", this.state.cta_icon_comment)
      }
      if (this.state.button_icon_comment) {
        fData.append("button_icon_comment", this.state.button_icon_comment)
      }
      if (this.state.button_selected_icon_comment) {
        fData.append(
          "button_selected_icon_comment",
          this.state.button_selected_icon_comment
        )
      }
      this.props.dispatch(updateCtaEntity(fData))
    } else {
      let fData = new FormData()

      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      this.props.dispatch(updateCtaEntity(fData))
    }
  }

  _onSubmitDuet = async ({ formData, errors = null } = {}) => {
    if (errors) {
      return
    }
    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }

    const content_uuid = this.state.content.content_uuid
    const content_title = this.state?.content?.content_title || ""
    const tags = this.state?.content?.tags || ""
    let custom_cta = []
    custom_cta =
      this.state?.content?.custom_cta?.filter(cta => cta.type !== "DUET") || []

    if (this.state?.enableDuetCta) {
      let data = { ...(formData?.duet_cta || {}) }

      const { type } = data || {}

      if (this.state?.duet_cta?.id) {
        data.id = this.state?.duet_cta?.id
      }
      if (!data?.title && CTA_DEFAULT_DATA[type]?.title) {
        data.title = CTA_DEFAULT_DATA[type].title || ""
      }

      if (!data?.subtitle && CTA_DEFAULT_DATA[type]?.subtitle) {
        data.subtitle = CTA_DEFAULT_DATA[type].subtitle || ""
      }

      if (
        !this.state.cta_icon_duet &&
        (this.state?.duet_cta?.icon_url || CTA_DEFAULT_DATA[type]?.icon_url)
      ) {
        data.icon_url =
          this.state?.duet_cta?.icon_url ||
          CTA_DEFAULT_DATA[type].icon_url ||
          ""
      }

      if (!data?.cta?.text && CTA_DEFAULT_DATA[type]?.cta?.text) {
        data.cta.text = CTA_DEFAULT_DATA[type].cta.text || ""
      }

      if (
        !data?.cta?.selected_text &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_text
      ) {
        data.cta.selected_text = CTA_DEFAULT_DATA[type].cta.selected_text || ""
      }

      if (!data?.cta?.view_type && CTA_DEFAULT_DATA[type]?.cta?.view_type) {
        data.cta.view_type = CTA_DEFAULT_DATA[type].cta.view_type || ""
      }

      if (
        !this.state.button_icon_duet &&
        (this.state?.duet_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.icon_url)
      ) {
        data.cta.icon_url =
          this.state?.duet_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type].cta.icon_url ||
          ""
      }

      if (
        !this.state.button_selected_icon_duet &&
        (this.state?.duet_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.selected_icon_url)
      ) {
        data.cta.selected_icon_url =
          this.state?.duet_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type].cta.selected_icon_url ||
          ""
      }

      let fData = new FormData()

      custom_cta.push(data)

      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      if (this.state.cta_icon_duet) {
        fData.append("cta_icon_duet", this.state.cta_icon_duet)
      }
      if (this.state.button_icon_duet) {
        fData.append("button_icon_duet", this.state.button_icon_duet)
      }
      if (this.state.button_selected_icon_duet) {
        fData.append(
          "button_selected_icon_duet",
          this.state.button_selected_icon_duet
        )
      }

      this.props.dispatch(updateCtaEntity(fData))
    } else {
      let fData = new FormData()

      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      this.props.dispatch(updateCtaEntity(fData))
    }
  }

  _onSubmitDeeplink = async ({ formData, errors = null } = {}) => {
    if (errors) {
      return
    }
    if (!this.props.hasEditPermission) {
      return toastr.error(NO_PERMISSION_MESSAGE)
    }

    const content_uuid = this.state.content.content_uuid
    const content_title = this.state?.content?.content_title || ""
    const tags = this.state?.content?.tags || ""
    let custom_cta = []
    custom_cta =
      this.state?.content?.custom_cta?.filter(cta => cta.type !== "DEEPLINK") ||
      []

    if (this.state?.enableDeeplinkCta) {
      let data = { ...(formData?.deeplink_cta || {}) }

      const { type } = data || {}

      if (this.state?.deeplink_cta?.id) {
        data.id = this.state?.deeplink_cta?.id
      }
      if (!data?.title && CTA_DEFAULT_DATA[type]?.title) {
        data.title = CTA_DEFAULT_DATA[type].title || ""
      }

      if (!data?.subtitle && CTA_DEFAULT_DATA[type]?.subtitle) {
        data.subtitle = CTA_DEFAULT_DATA[type].subtitle || ""
      }

      if (
        !this.state.cta_icon_duet &&
        (this.state?.deeplink_cta?.icon_url || CTA_DEFAULT_DATA[type]?.icon_url)
      ) {
        data.icon_url =
          this.state?.deeplink_cta?.icon_url ||
          CTA_DEFAULT_DATA[type].icon_url ||
          ""
      }

      if (!data?.cta?.text && CTA_DEFAULT_DATA[type]?.cta?.text) {
        data.cta.text = CTA_DEFAULT_DATA[type].cta.text || ""
      }

      if (
        !data?.cta?.selected_text &&
        CTA_DEFAULT_DATA[type]?.cta?.selected_text
      ) {
        data.cta.selected_text = CTA_DEFAULT_DATA[type].cta.selected_text || ""
      }

      if (!data?.cta?.view_type && CTA_DEFAULT_DATA[type]?.cta?.view_type) {
        data.cta.view_type = CTA_DEFAULT_DATA[type].cta.view_type || ""
      }

      if (
        !this.state.button_icon_duet &&
        (this.state?.deeplink_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.icon_url)
      ) {
        data.cta.icon_url =
          this.state?.deeplink_cta?.cta?.icon_url ||
          CTA_DEFAULT_DATA[type].cta.icon_url ||
          ""
      }

      if (
        !this.state.button_selected_icon_duet &&
        (this.state?.deeplink_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type]?.cta?.selected_icon_url)
      ) {
        data.cta.selected_icon_url =
          this.state?.deeplink_cta?.cta?.selected_icon_url ||
          CTA_DEFAULT_DATA[type].cta.selected_icon_url ||
          ""
      }

      let fData = new FormData()

      custom_cta.push(data)

      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      if (this.state.cta_icon_duet) {
        fData.append("cta_icon_duet", this.state.cta_icon_duet)
      }
      if (this.state.button_icon_duet) {
        fData.append("button_icon_duet", this.state.button_icon_duet)
      }
      if (this.state.button_selected_icon_duet) {
        fData.append(
          "button_selected_icon_duet",
          this.state.button_selected_icon_duet
        )
      }

      this.props.dispatch(updateCtaEntity(fData))
    } else {
      let fData = new FormData()

      fData.append(
        "details",
        JSON.stringify({
          content_uuid,
          content_title,
          tags,
          custom_cta,
        })
      )

      this.props.dispatch(updateCtaEntity(fData))
    }
  }

  render() {
    const { stateLocationList, cityLocationList } = this.state
    const { audioList, onTrendingTag = () => {} } = this.props
    const content = { ...this.state.content }
    const cityIds =
      content.targeted_locations &&
      !_.isEmpty(content.targeted_locations.city_ids)
        ? content.targeted_locations.city_ids
        : []
    const stateIds =
      content.targeted_locations &&
      !_.isEmpty(content.targeted_locations.state_ids)
        ? content.targeted_locations.state_ids
        : []
    let result = []
    let targetCity =
      content.targeted_locations &&
      content.targeted_locations.granularity &&
      content.targeted_locations.granularity === "CITY" &&
      !_.isEmpty(content.targeted_locations.city_ids)
    if (targetCity && !_.isEmpty(cityLocationList)) {
      let foundCity
      const { city_ids = [] } = content.targeted_locations
      for (let i = 0; i < city_ids.length; i++) {
        foundCity = cityLocationList.find(function (post, index) {
          if (post.value == city_ids[i]) return true
        })
        result.push(foundCity?.label)
      }
    }
    let targetState =
      content.targeted_locations &&
      content.targeted_locations.granularity &&
      content.targeted_locations.granularity === "STATE" &&
      !_.isEmpty(content.targeted_locations.state_ids)
    if (targetState && !_.isEmpty(stateLocationList)) {
      let foundState
      const { state_ids = [] } = content.targeted_locations
      for (let i = 0; i < state_ids.length; i++) {
        foundState = stateLocationList.find(function (post, index) {
          if (post.value == state_ids[i]) return true
        })
        result.push(foundState?.label)
      }
    }
    let targetStateIds =
      content.targeted_locations &&
      content.targeted_locations.state_ids &&
      _.isEmpty(content.targeted_locations.granularity) &&
      !_.isEmpty(content.targeted_locations.state_ids)
    if (targetStateIds && !_.isEmpty(stateLocationList)) {
      let foundStates
      const { state_ids = [] } = content.targeted_locations
      for (let i = 0; i < state_ids.length; i++) {
        foundStates = stateLocationList.find(function (post, index) {
          if (post.value == state_ids[i]) return true
        })
        result.push(foundStates?.label)
      }
    }

    const tagTrendingPermission =
      this.props.currentUser &&
      ((this.props.currentUser.permissions &&
        this.props.currentUser.permissions.includes("TAG_TRENDING")) ||
        (this.props.currentUser.jotUserType &&
          this.props.currentUser.jotUserType === 1))

    const hasPlayOriginalPermission =
      this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("VIEW_ORIGINAL_VIDEO")

    if (content) {
      const { user_profile } = this.props.content
      const realStatPermissionUser =
        this.props.currentUser.permissions &&
        this.props.currentUser.permissions.includes("VIEW_REAL_STATS")
      const language = LANGUAGES.filter(
        lang => lang.value === content.lang_code
      )
      const selectedLang = language[0] && language[0]["label"]

      const servableContent =
        content?.is_active &&
        (content?.moderation_level >= 2 ||
          (content?.moderation_level === 1 &&
            content?.auto_moderation_label === "sfw"))
          ? true
          : false

      let nonServableContentReason = "Non Servable"

      if (content && !content.is_active) {
        nonServableContentReason = "Content is InActive."
      } else if (content && content.moderation_level < 1) {
        nonServableContentReason = `Moderation status is ${
          MODERATION_LEVEL_MAP[content.moderation_level]
        }.`
      } else if (
        content &&
        content.moderation_level === 1 &&
        content.auto_moderation_label !== "sfw"
      ) {
        nonServableContentReason = `Moderation status is ${
          MODERATION_LEVEL_MAP[content.moderation_level]
        } but Auto Moderation Label value is ${content.auto_moderation_label}.`
      }
      const allCampaignList = []
      this.props?.campaignList?.data?.map(camp => {
        if (camp && camp.campaign_uuid && camp.status == "true") {
          allCampaignList.push({
            ...camp,
            value: camp.campaign_uuid,
            label: camp.campaign_name,
          })
        }
      })
      return (
        <Row className="d-flex justify-content-center">
          <Col xl={8} lg={8} md={12} sm={12}>
            <SimpleBar style={{ height: "72vh", backgroundColor: "white" }}>
              {!this.state.showActionForm && (
                <Card>
                  <CardBody className="pt-4">
                    <Row>
                      <Col xl="11" md="11" lg="11" sm="12">
                        <div className="profile-verified">
                          <div
                            className="profile"
                            role="button"
                            onClick={() => {
                              if (!this.props.pageSource) {
                                this._viewUserProfile()
                              }
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                minWidth: "32px",
                                minHeight: "32px",
                              }}
                            >
                              <img
                                className="rounded-circle avatar-xs img-fluid"
                                src={
                                  user_profile.profile_pic ||
                                  "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="user-info">
                              {user_profile && user_profile.user_name && (
                                <div className="user-name d-flex align-items-center">
                                  <div>{user_profile.user_name}</div>
                                  {user_profile && user_profile.verified && (
                                    <div className="d-flex align-items-center mx-1">
                                      <Player
                                        autoplay={true}
                                        loop={true}
                                        controls={true}
                                        src="https://assets6.lottiefiles.com/packages/lf20_9tgeV6Gyat.json"
                                        style={{ width: 20, height: 20 }}
                                      ></Player>
                                    </div>
                                  )}
                                </div>
                              )}
                              {content.created_date && (
                                <div>
                                  {moment(content.created_date).format(
                                    "MMM DD YYYY, h:mm a"
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                      {this.props.hasPrivateModePermission && (
                        <Col xl="1" md="1" lg="1" sm="12">
                          Private
                          <Switch
                            onChange={val =>
                              this.setState({ is_private_content: val }, () => {
                                this._updateContent("is_private_content", val)
                              })
                            }
                            defaultValue={content.is_private_content}
                            disabled={!this.props.hasPrivateModePermission}
                          />
                        </Col>
                      )}
                    </Row>
                    <div>
                      <div
                        className="mb-3 mt-3"
                        style={{ borderTop: "2px solid #eff2f7" }}
                      ></div>
                      <div className="justify-content-between flex-wrap">
                        <div
                          className="text-muted mt-1"
                          style={{
                            marginBottom: "7px",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Moderation
                        </div>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div className="margin-t-b-auto fs-1">
                            <span className="">
                              <Tooltip title="Moderation Status">
                                <Tag
                                  color={
                                    content.moderation_level === 1 ||
                                    content.moderation_level === 3 ||
                                    content.moderation_level === 0
                                      ? "red"
                                      : content.moderation_level === 2
                                      ? "#87d068"
                                      : "#108ee9"
                                  }
                                  style={{
                                    color:
                                      content.moderation_level === -1 ||
                                      content.moderation_level === -2
                                        ? "white"
                                        : "black",
                                  }}
                                >
                                  {content.moderation_status}
                                </Tag>
                              </Tooltip>
                            </span>
                            {content.auto_moderation_label && (
                              <span className="mx-2">
                                <Tooltip title="Auto Moderation Label">
                                  {" "}
                                  <Tag
                                    color={
                                      content.auto_moderation_label === "nsfw"
                                        ? "red"
                                        : "#87d068"
                                    }
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    {content.auto_moderation_label}
                                  </Tag>
                                </Tooltip>
                              </span>
                            )}
                            {!servableContent && (
                              <span className="mx-2">
                                <Tooltip title={nonServableContentReason}>
                                  <Tag
                                    color="#f50"
                                    style={{
                                      color: "white",
                                    }}
                                  >
                                    Non Servable -{" "}
                                    {content.manual_moderation_label}
                                  </Tag>
                                </Tooltip>
                              </span>
                            )}
                          </div>

                          <div>
                            <div style={{ display: "flex" }}>
                              <Button
                                onClick={this._handleTimeline}
                                variant="outlined"
                                className="mx-1"
                                color="success"
                                size="small"
                                startIcon={
                                  this.state.showTimeline ? (
                                    <MdArrowUpward />
                                  ) : (
                                    <MdArrowDownward />
                                  )
                                }
                              >
                                {this.state.showTimeline
                                  ? "Hide Timeline"
                                  : "Show Timeline"}
                              </Button>
                              <ActionButtons
                                currentUser={this.props.currentUser}
                                isMobile={false}
                                isModerationSection={true}
                                content={this.props.content}
                                contentAction={this._contentAction}
                                isReProcessedModerationList={
                                  this.props.isReProcessedModerationList
                                }
                                reProcessedModerationAction={
                                  this._reProcessedModerationAction
                                }
                                deativateReasonFunc={this._deativateReason}
                                pageType={this.props.pageType}
                                showTagNonEnglish={
                                  content.lang_code === "en" &&
                                  !content.non_english
                                }
                                onReviewClick={this._onReviewClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.showTimeline ? "" : "display-none"
                        }
                      >
                        {" "}
                        <Timelines
                          key={content.content_uuid}
                          action_id={content.content_uuid}
                          actions={["activate", "deactivate"]}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        className="mb-3 mt-3"
                        style={{ borderTop: "2px solid #eff2f7" }}
                      ></div>
                      <div className="d-flex justify-content-between flex-wrap">
                        <div
                          className="text-muted mt-1"
                          style={{
                            marginBottom: "7px",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Actions
                        </div>
                        <div>
                          <ActionButtons
                            currentUser={this.props.currentUser}
                            isMobile={false}
                            isModerationSection={false}
                            content={this.props.content}
                            contentAction={this._contentAction}
                            isReProcessedModerationList={
                              this.props.isReProcessedModerationList
                            }
                            reProcessedModerationAction={
                              this._reProcessedModerationAction
                            }
                            deativateReasonFunc={this._deativateReason}
                            pageType={this.props.pageType}
                            showTagNonEnglish={
                              content.lang_code === "en" && !content.non_english
                            }
                            onReviewClick={this._onReviewClick}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.showReasonModal &&
                      this._deactivateContentModal(content)}
                    {this.state.showNotificationPopup &&
                      this._renderWarningMessage(content)}
                    <React.Fragment>
                      <div
                        className="mb-3 mt-3"
                        style={{ borderTop: "2px solid #eff2f7" }}
                      ></div>
                      <div
                        className="text-muted mt-1"
                        style={{
                          marginBottom: "7px",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Tag Trending
                      </div>
                      <div className="grid-container">
                        <div className="created-by">
                          <div className="d-flex align-items-center justify-content-between font-size-14">
                            <div style={{ fontWeight: "600" }}>
                              Trending Rating
                            </div>
                            <div className="d-flex">
                              <Rating
                                emptySymbol="far fa-star fa-1x text-muted"
                                fullSymbol="fas fa-star fa-1x text-warning"
                                initialRating={this.state.trending.rating}
                                onChange={rate => {
                                  this.setState({
                                    trending: {
                                      ...this.state.trending,
                                      rating: rate,
                                    },
                                  })
                                }}
                              />
                              {tagTrendingPermission &&
                                this.state.trending &&
                                this.state.trending.rating > 0 && (
                                  <div
                                    className="mx-2"
                                    role="button"
                                    onClick={() =>
                                      this.setState({
                                        trending: {
                                          ...this.state.trending,
                                          rating: 0,
                                        },
                                      })
                                    }
                                  >
                                    <i className="fas fa-window-close text-danger" />
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        {this.props.trendingEntityList &&
                          this.props.trendingEntityList.length > 0 && (
                            <div className="trending">
                              <div className="d-flex align-items-center justify-content-between font-size-14">
                                <div
                                  style={{ fontWeight: "600", padding: "10px" }}
                                >
                                  Trending Entity
                                </div>
                                <div>
                                  <InlineEdit
                                    component={
                                      !_.isEmpty(
                                        this.state.trending.entity_id
                                      ) &&
                                      typeof this.state.trending.entity_id ===
                                        "object" &&
                                      this.state.trending.entity_id.length >
                                        0 ? (
                                        this.state.trending.entity_id.map(
                                          entity => {
                                            return (
                                              <Badge
                                                pill
                                                className="badge-soft-primary m-1"
                                              >
                                                <div className="font-size-12 p-1">
                                                  {this._getDefault(
                                                    entity,
                                                    "entity"
                                                  )}
                                                </div>
                                              </Badge>
                                            )
                                          }
                                        )
                                      ) : (
                                        <div>N/A</div>
                                      )
                                    }
                                    showHover={
                                      this.props.currentUser.jotUserType < 5
                                    }
                                    text={
                                      !_.isEmpty(
                                        this.state.trending.entity_id
                                      ) &&
                                      typeof this.state.trending.entity_id ===
                                        "object" &&
                                      this.state.trending.entity_id.length > 0
                                        ? this.state.trending.entity_id
                                        : []
                                    }
                                    onSetText={value => {
                                      this.setState({
                                        trending: {
                                          ...this.state.trending,
                                          entity_id: value,
                                        },
                                      })
                                    }}
                                    onSubmit={value => {}}
                                    type="custom-multi-select"
                                    options={this._getTrendingEntities()}
                                    customclassName="badge-soft-danger text-dark"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>{" "}
                      <div
                        className="mb-3 mt-3"
                        style={{ borderTop: "2px solid #eff2f7" }}
                      ></div>
                      <div
                        className="text-muted mt-1"
                        style={{
                          marginBottom: "7px",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Viral
                      </div>
                      <div className="grid-container">
                        <div className="created-by">
                          <div className="d-flex align-items-center justify-content-between font-size-14">
                            <div style={{ fontWeight: "600" }}>Source</div>
                            <div>
                              <InlineEdit
                                component={
                                  !_.isEmpty(this.state.viral.source) ? (
                                    typeof this.state.viral.source ===
                                      "object" &&
                                    this.state.viral.source.length > 0 ? (
                                      this.state.viral.source.map(entity => {
                                        return (
                                          <Badge
                                            pill
                                            className="badge-soft-primary m-1"
                                          >
                                            <div className="font-size-12 p-1">
                                              {this._getDefault(
                                                entity,
                                                "source"
                                              )}
                                            </div>
                                          </Badge>
                                        )
                                      })
                                    ) : (
                                      <Badge
                                        pill
                                        className="badge-soft-primary m-1"
                                      >
                                        <div className="font-size-12 p-1">
                                          {this._getDefault(
                                            this.state.viral.source,
                                            "source"
                                          )}
                                        </div>
                                      </Badge>
                                    )
                                  ) : (
                                    <div>N/A</div>
                                  )
                                }
                                showHover={
                                  this.props.currentUser.jotUserType < 5
                                }
                                text={
                                  !_.isEmpty(this.state.viral.source)
                                    ? typeof this.state.viral.source ===
                                        "object" &&
                                      this.state.viral.source.length > 0
                                      ? this.state.viral.source
                                      : this.state.viral.source
                                    : []
                                }
                                onSetText={value =>
                                  this.setState({
                                    viral: {
                                      ...this.state.viral,
                                      source: value,
                                    },
                                  })
                                }
                                onSubmit={value => {
                                  if (!this.props.hasEditPermission) {
                                    return toastr.error(NO_PERMISSION_MESSAGE)
                                  }
                                }}
                                type="custom-multi-select"
                                options={SOURCES}
                                customclassName="badge-soft-danger text-dark"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="trending">
                          <div className="d-flex align-items-center justify-content-between font-size-14">
                            <div style={{ fontWeight: "600", padding: "10px" }}>
                              Date
                            </div>
                            <div>
                              <InlineEdit
                                component={
                                  !_.isEmpty(this.state.viral.date) ? (
                                    <div>{this.state.viral.date}</div>
                                  ) : (
                                    "N/A"
                                  )
                                }
                                text={
                                  this.state.viral.date
                                    ? this.state.viral.date
                                    : moment().format("YYYY-MM-DD")
                                }
                                onSetText={value =>
                                  this.setState({
                                    viral: {
                                      ...this.state.viral,
                                      date: value,
                                    },
                                  })
                                }
                                showHover={
                                  this.props.currentUser.jotUserType < 4
                                }
                                onSubmit={value => {
                                  if (!this.props.hasEditPermission) {
                                    return toastr.error(NO_PERMISSION_MESSAGE)
                                  }
                                }}
                                type="date-picker"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="trending">
                          <div className="font-size-14">
                            <div style={{ fontWeight: "600", padding: "10px" }}>
                              User ID
                            </div>
                            <div>
                              <Row className="d-flex align-items-center ps-2">
                                <Col sm="6" md="9">
                                  {" "}
                                  <TextField
                                    disabled={
                                      !(this.props.currentUser.jotUserType < 4)
                                    }
                                    placeholderLabel="Select User"
                                    className="creator-form"
                                    value={this.state.viral.user_id || []}
                                    onChange={val => {
                                      if (val.trim()) {
                                        this.setState({
                                          viral: {
                                            ...this.state.viral,
                                            user_id: val.split(","),
                                          },
                                        })
                                      } else {
                                        this.setState({
                                          viral: {
                                            ...this.state.viral,
                                            user_id: [],
                                          },
                                        })
                                      }
                                    }}
                                    defaultValue={this.state?.viral?.user_id}
                                    style={{ width: "95%" }}
                                  />
                                </Col>
                                <Col
                                  sm="6"
                                  md="3"
                                  style={{
                                    alignSelf: "flex-end",
                                    marginBottom: "9px",
                                  }}
                                >
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    disabled={
                                      !(this.props.currentUser.jotUserType < 4)
                                    }
                                    onClick={() =>
                                      this.setState({
                                        selectInputUser: true,
                                      })
                                    }
                                  >
                                    Select
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button
                          size="small"
                          variant="outlined"
                          color="success"
                          onClick={() => {
                            if (!this.state.hasEditTrendingPermission) {
                              toastr.error(NO_PERMISSION_MESSAGE)
                            } else {
                              this.setState(
                                {
                                  trending:
                                    (this.props.content &&
                                      this.props.content.trending_meta) ||
                                    {},
                                },
                                this._updateTrending(),
                                onTrendingTag()
                              )
                            }
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </React.Fragment>
                    <div
                      className="mb-3 mt-3"
                      style={{ borderTop: "2px solid #eff2f7" }}
                    ></div>
                    {content.content_title?.trim() && (
                      <Row>
                        <div
                          className="text-muted mt-1"
                          style={{
                            marginBottom: "7px",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Title
                        </div>
                        <div style={{ fontSize: "15px" }} className="w-100">
                          <InlineEdit
                            text={content.content_title}
                            showHover={this.props.currentUser.jotUserType < 4}
                            onSetText={value =>
                              this.setState({
                                content: {
                                  ...this.state.content,
                                  content_title: value,
                                },
                              })
                            }
                            onSubmit={value =>
                              this._updateContent("content_title", value)
                            }
                            type="textarea"
                          />
                        </div>
                      </Row>
                    )}
                    {content.source_meta && content.source_meta.length > 0 && (
                      <Row>
                        <div
                          className="text-muted mt-1"
                          style={{
                            marginBottom: "7px",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          Source Meta
                        </div>
                        <div className="grid-container">
                          <div className="grid-child">
                            <div className="d-flex align-items-center justify-content-between">
                              <td>Platform</td>
                              <th>{content.source_meta[0].source_platform}</th>
                            </div>
                          </div>
                        </div>
                        <div className="grid-container">
                          <div className="grid-child">
                            <div className="d-flex align-items-center justify-content-between">
                              <td>Likes</td>
                              <th>{content.source_meta[0].source_likes}</th>
                            </div>
                          </div>
                          <div className="grid-child">
                            <div className="d-flex align-items-center justify-content-between">
                              <td>Shares</td>
                              <th>{content.source_meta[0].source_shares}</th>
                            </div>
                          </div>
                        </div>
                        <div className="grid-child mt-1 mx-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <td>URL</td>
                            <th>
                              <CopyToClipboard
                                text={content.source_meta[0].source_url}
                                onCopy={e => {
                                  toastr.success("Copied sucessfully!")
                                }}
                              >
                                <Tooltip title="Copy URL">
                                  <i className="bx bx-copy" role="button" />
                                </Tooltip>
                              </CopyToClipboard>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={content.source_meta[0].source_url}
                              >
                                {content.source_meta[0].source_url}
                              </a>
                            </th>
                          </div>
                        </div>
                      </Row>
                    )}
                    {content.taxonomy_meta && (
                      <>
                        <div
                          className="mb-3 mt-3"
                          style={{ borderTop: "2px solid #eff2f7" }}
                        ></div>
                        <div className="d-flex justify-content-between flex-wrap">
                          <div
                            className="text-muted mt-1"
                            style={{
                              marginBottom: "7px",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            Taxonomy
                          </div>
                          <Button
                            onClick={this._handleTaxonomyTimeline}
                            variant="outlined"
                            size="small"
                            color="success"
                            startIcon={
                              this.state.showTaxonomyTimeline ? (
                                <MdArrowUpward />
                              ) : (
                                <MdArrowDownward />
                              )
                            }
                          >
                            {this.state.showTaxonomyTimeline
                              ? "Hide Timeline"
                              : "Show Timeline"}
                          </Button>
                        </div>
                        {content.auto_moderation_label && (
                          <div className="px-3">
                            <span>
                              <Tooltip title="Auto Moderation Label">
                                {" "}
                                <Tag
                                  color={
                                    content.auto_moderation_label === "nsfw"
                                      ? "red"
                                      : "#87d068"
                                  }
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {content.auto_moderation_label}
                                </Tag>
                              </Tooltip>
                            </span>
                          </div>
                        )}
                        <div
                          className={
                            this.state.showTaxonomyTimeline
                              ? ""
                              : "display-none"
                          }
                        >
                          {" "}
                          <Timelines
                            key={content.content_uuid}
                            action_id={content.content_uuid}
                            actions={[
                              "tag_content",
                              "skip_tag_content",
                              "qc_tag_content_ok",
                              "qc_tag_content_modify",
                              "labeling_stage_one",
                              "labeling_stage_two",
                              "labeling_stage_three",
                            ]}
                            renderTaxonomyDetails={this._renderTaxonomyDetails}
                            renderTaxonomyModifyDetails={
                              this._renderTaxonomyModifyDetails
                            }
                          />
                        </div>
                        <div className="grid-container">
                          <div className="created-by">
                            <div className="d-flex align-items-center justify-content-between font-size-14">
                              <div>Rating</div>
                              <div className="d-flex">
                                <Rating
                                  emptySymbol="far fa-star fa-1x text-muted"
                                  fullSymbol="fas fa-star fa-1x text-warning"
                                  initialRating={
                                    content.taxonomy_meta &&
                                    content.taxonomy_meta
                                      .video_quality_attributes &&
                                    content.taxonomy_meta
                                      .video_quality_attributes.rating
                                  }
                                  onChange={() => {}}
                                  quiet={true}
                                  readonly={true}
                                />
                                {/* {content.taxonomy_meta &&
                                  content.taxonomy_meta
                                    .video_quality_attributes &&
                                  content.taxonomy_meta.video_quality_attributes
                                    .rating && (
                                    <Popover
                                      content={popoverContent}
                                      trigger="click"
                                    >
                                      <div
                                        className="px-2 btn btn-sm"
                                        role="button"
                                        style={{
                                          border: "1px",
                                          borderStyle: "solid",
                                          borderRadius: "4px",
                                          backgroundColor: "#34c38f",
                                          color: "#ffffff",
                                          marginLeft: "2px",
                                        }}
                                        // onClick={() =>
                                        //   this._onReviewClick(
                                        //     content.content_uuid
                                        //   )
                                        // }
                                      >
                                        Raise Review
                                      </div>
                                    </Popover>
                                  )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {this._renderTaxonomyDetails(content.taxonomy_meta)}
                        </div>
                      </>
                    )}
                    <div
                      className="mb-3 mt-3"
                      style={{ borderTop: "2px solid #eff2f7" }}
                    ></div>
                    <div
                      className="text-muted mt-1"
                      style={{
                        marginBottom: "7px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Tag Trending
                    </div>
                    <div className="grid-container">
                      <div className="hashtags">
                        {content.tags && (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <i
                                style={{ paddingRight: "10px" }}
                                className="bx bxs-award"
                              />{" "}
                              <span
                                style={{
                                  padding: "5px",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                  wordBreak: "break-word",
                                }}
                              >
                                <InlineEdit
                                  text={content.tags ? content.tags : ""}
                                  onSetText={value =>
                                    this.setState({
                                      content: {
                                        ...this.state.content,
                                        tags: value,
                                      },
                                    })
                                  }
                                  showHover={
                                    this.props.currentUser.jotUserType < 4
                                  }
                                  onSubmit={value =>
                                    this._updateContent("tags", value)
                                  }
                                  type="textarea"
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      {content && content.audio_title && (
                        <div className="created-by d-flex align-items-center justify-content-start">
                          <i
                            style={{
                              paddingRight: "10px",
                              fontWeight: "600",
                            }}
                            className="bx bx-music"
                          />
                          <span>{content.audio_title}</span>
                          <div className="ms-auto">
                            <CopyToClipboard
                              text={content.audio_id}
                              onCopy={e => {
                                toastr.success("Copied sucessfully!")
                              }}
                            >
                              <Tooltip title="Copy Audio ID">
                                <i className="bx bx-copy bx-xs" role="button" />
                              </Tooltip>
                            </CopyToClipboard>
                          </div>
                        </div>
                      )}
                      <div className="created-by">
                        <div className="d-flex align-items-center font-size-14 justify-content-between">
                          <div>Created By</div>
                          <div style={{ fontWeight: "600" }}>
                            {user_profile && user_profile.name
                              ? user_profile.name
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="target">
                        <div className="d-flex align-items-center font-size-14 justify-content-between">
                          <div>Target Languages</div>
                          <div style={{ fontWeight: "600" }}>
                            <InlineEdit
                              component={
                                content.target_languages
                                  ? content.target_languages.map(
                                      lang =>
                                        LANGUAGE_MAPPING[lang] && (
                                          <Badge
                                            pill
                                            className="badge-soft-primary m-1"
                                          >
                                            <div className="font-size-12 p-1">
                                              {LANGUAGE_MAPPING[lang]}
                                            </div>
                                          </Badge>
                                        )
                                    )
                                  : "N/A"
                              }
                              showHover={this.props.currentUser.jotUserType < 4}
                              text={
                                content.target_languages
                                  ? content.target_languages.map(lang => lang)
                                  : ["en"]
                              }
                              onSetText={value =>
                                this.setState({
                                  content: {
                                    ...this.state.content,
                                    target_languages: value,
                                  },
                                })
                              }
                              onSubmit={value =>
                                this._updateContent("cms_target_langs", value)
                              }
                              type="multi-select"
                              options={LANGUAGES}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="category">
                        <div className="d-flex align-items-center font-size-14 justify-content-between">
                          <div>Category</div>
                          <div style={{ fontWeight: "600" }}>
                            <InlineEdit
                              text={
                                content && content.content_category_id
                                  ? Categories.filter(
                                      cat =>
                                        cat.category_id + "" ==
                                        content.content_category_id
                                    )[0].category_name
                                  : "N/A"
                              }
                              showHover={this.props.currentUser.jotUserType < 4}
                              onSetText={value => {
                                this.setState({
                                  content: {
                                    ...this.state.content,
                                    content_category_id: Categories.filter(
                                      cat => cat.category_name === value
                                    )[0].category_id,
                                  },
                                })
                              }}
                              onSubmit={value =>
                                this._updateContent("category_name", value)
                              }
                              type="single-select"
                              options={Categories.map(item => ({
                                label: item.category_name,
                                value: item.category_name,
                              }))}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="duetable">
                        <div className="d-flex align-items-center font-size-14 justify-content-between">
                          <div>Duetable</div>
                          <div style={{ fontWeight: "600" }}>
                            <InlineEdit
                              showHover={this.props.currentUser.jotUserType < 4}
                              text={content.duetable === "Y" ? "Yes" : "No"}
                              onSetText={value =>
                                this.setState({
                                  content: {
                                    ...this.state.content,
                                    duetable: value === "Y" ? "Yes" : "No",
                                  },
                                })
                              }
                              onSubmit={value =>
                                this._updateContent("duetable", value)
                              }
                              type="single-select"
                              options={[
                                { label: "Yes", value: "Y" },
                                { label: "No", value: "N" },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid-child">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Language</div>
                          <div style={{ fontWeight: "600" }}>
                            <InlineEdit
                              text={content.lang_code ? selectedLang : ""}
                              showHover={this.props.currentUser.jotUserType < 4}
                              onSetText={value =>
                                this.setState({
                                  content: {
                                    ...this.state.content,
                                    lang_code: value,
                                  },
                                })
                              }
                              onSubmit={value =>
                                this._updateContent("lang_code", value)
                              }
                              type="single-select"
                              options={LANGUAGES}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="granularity">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Granularity</div>
                          <div style={{ fontWeight: "600" }}>
                            <InlineEdit
                              text={
                                content &&
                                content.targeted_locations &&
                                content.targeted_locations.granularity
                                  ? content.targeted_locations.granularity
                                  : "N/A"
                              }
                              showHover={this.props.currentUser.jotUserType < 4}
                              onSetText={value =>
                                this.setState({
                                  content: {
                                    ...this.state.content,
                                    targeted_locations: {
                                      ...content.targeted_locations,
                                      granularity: value,
                                    },
                                  },
                                })
                              }
                              onSubmit={value =>
                                this._updateContent("granularity", value)
                              }
                              type="single-select"
                              options={[
                                { label: "CITY", value: "CITY" },
                                { label: "STATE", value: "STATE" },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="granularity">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Post Publish Time</div>
                          <div style={{ fontWeight: "600" }}>
                            <InlineEdit
                              component={
                                content && content.post_publish_time_epoch ? (
                                  <div>
                                    {new Date(
                                      content.post_publish_time_epoch * 1000
                                    ).toLocaleDateString()}
                                  </div>
                                ) : (
                                  "N/A"
                                )
                              }
                              text={
                                content.post_publish_time_epoch
                                  ? moment(
                                      content.post_publish_time_epoch * 1000
                                    ).format("YYYY-MM-DD")
                                  : moment().format("YYYY-MM-DD")
                              }
                              onSetText={value =>
                                this.setState({
                                  content: {
                                    ...this.state.content,
                                    post_publish_time_epoch: value * 1000,
                                  },
                                })
                              }
                              customValue={true}
                              dateTimeFormat="YYYY-MM-DD HH:mm:ss"
                              onSubmit={value =>
                                this._updateContent(
                                  "post_publish_time_epoch",
                                  Math.floor(moment(value).unix())
                                )
                              }
                              type="date-picker"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="duetable">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Post Expiry Time</div>
                          <div style={{ fontWeight: "600" }}>
                            <InlineEdit
                              component={
                                !_.isEmpty(
                                  content && content.post_expiry_time
                                ) ? (
                                  <div>
                                    {new Date(
                                      content.post_expiry_time
                                    ).toLocaleDateString()}
                                  </div>
                                ) : (
                                  "N/A"
                                )
                              }
                              text={
                                content.post_expiry_time
                                  ? content.post_expiry_time
                                  : moment().format("YYYY-MM-DD")
                              }
                              onSetText={value =>
                                this.setState({
                                  content: {
                                    ...this.state.content,
                                    post_expiry_time: value,
                                  },
                                })
                              }
                              customValue={true}
                              dateTimeFormat="YYYY-MM-DD HH:mm:ss"
                              onSubmit={value =>
                                this._updateContent(
                                  "post_expiry_time",
                                  moment(value).format("YYYY-MM-DDTHH:mmZ")
                                )
                              }
                              type="date-picker"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="duetable">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Notification Worthy</div>
                          <div style={{ fontWeight: "600" }}>
                            {content.notif_worthy ? "True" : "False"}
                          </div>
                        </div>
                      </div>
                      <div className="location">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Location</div>
                          <div style={{ fontWeight: "600" }}>
                            <InlineEdit
                              component={
                                !_.isEmpty(result)
                                  ? result.map(city => (
                                      <Badge
                                        pill
                                        className="badge-soft-primary m-1"
                                      >
                                        <div className="font-size-12 p-1">
                                          {city}
                                        </div>
                                      </Badge>
                                    ))
                                  : "N/A"
                              }
                              showHover={this.props.currentUser.jotUserType < 4}
                              text={
                                content &&
                                content.targeted_locations &&
                                content.targeted_locations.granularity
                                  ? content.targeted_locations.granularity ===
                                    "CITY"
                                    ? cityIds
                                    : stateIds
                                  : content.targeted_locations &&
                                    !_.isEmpty(
                                      content.targeted_locations.state_ids
                                    )
                                  ? content.targeted_locations.state_ids.map(
                                      state => state
                                    )
                                  : []
                              }
                              onSetText={value => {
                                let ids = []
                                let key = "state_ids"
                                if (
                                  content.targeted_locations &&
                                  content.targeted_locations.granularity ===
                                    "CITY"
                                ) {
                                  ids = cityIds
                                  key = "city_ids"
                                } else {
                                  ids = stateIds
                                }
                                this.setState({
                                  content: {
                                    ...this.state.content,
                                    targeted_locations: {
                                      ...content.targeted_locations,
                                      [key]: [...value],
                                    },
                                  },
                                })
                              }}
                              onSubmit={value =>
                                this._updateContent("location", value)
                              }
                              type="multi-select"
                              options={
                                content.targeted_locations &&
                                content.targeted_locations.granularity &&
                                content.targeted_locations.granularity ===
                                  "CITY"
                                  ? this.state.cityLocationList
                                  : this.state.stateLocationList
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="location">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Type</div>
                          <div style={{ fontWeight: "600" }}>
                            <InlineEdit
                              text={
                                content && content.ce_type
                                  ? content.ce_type
                                  : "N/A"
                              }
                              showHover={this.props.currentUser.jotUserType < 4}
                              onSetText={value =>
                                this.setState({
                                  content: {
                                    ...this.state.content,

                                    ce_type: value,
                                  },
                                })
                              }
                              onSubmit={value =>
                                this._updateContent("ce_type", value)
                              }
                              disabled={
                                this.state.content.ce_type == "GREETING" &&
                                "true"
                              }
                              type="single-select"
                              options={CONTENT_TYPE}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="location">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Campaign</div>
                          <div style={{ width: "70%", fontWeight: "600" }}>
                            <ReactSelect
                              // defaultInputValue={props.filters["subType"]}
                              value={
                                allCampaignList?.filter(
                                  camp =>
                                    camp?.campaign_uuid ===
                                    content?.campaign_uuid
                                )[0] || ""
                              }
                              isClearable={true}
                              // value={this.state?.campaign_uuid}
                              style={{ width: "100%", height: "100px" }}
                              isMulti={false}
                              options={allCampaignList || []}
                              formatOptionLabel={this.formatOptionLabelCampaign}
                              onChange={value =>
                                this._updateContent(
                                  "campaign_uuid",
                                  value?.campaign_uuid
                                    ? value.campaign_uuid
                                    : ""
                                )
                              }
                              placeholder="Select Campaign"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="location">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Embedding </div>
                          <div style={{ width: "70%", fontWeight: "600" }}>
                            {content.embedding && content.embedding.length > 0
                              ? content.embedding.map(
                                  (item, index) =>
                                    item +
                                    (index != content.embedding.length - 1
                                      ? ", "
                                      : "")
                                )
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="location">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Interest </div>
                          <div style={{ width: "70%", fontWeight: "600" }}>
                            {content.active_interests &&
                            content.active_interests.length > 0
                              ? content.active_interests.map(
                                  (item, index) =>
                                    this.state.interestData[item] +
                                    (index !=
                                    content.active_interests.length - 1
                                      ? ", "
                                      : "")
                                )
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="location">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Sub-Interest </div>
                          <div style={{ width: "70%", fontWeight: "600" }}>
                            {content.active_sub_interests &&
                            content.active_sub_interests.length > 0
                              ? content.active_sub_interests.map(
                                  (item, index) =>
                                    this.state.interestData[item] +
                                    (index !=
                                    content.active_sub_interests.length - 1
                                      ? ", "
                                      : "")
                                )
                              : ""}
                          </div>
                        </div>
                      </div>

                      <div className="location">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Ingestion Source </div>
                          <div style={{ width: "70%", fontWeight: "600" }}>
                            {content.source || ""}
                          </div>
                        </div>
                      </div>
                      <div className="duetable">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>Seed Video</div>
                          <div style={{ fontWeight: "600" }}>
                            {content.is_seed_video ? "True" : "False"}
                          </div>
                        </div>
                      </div>

                      {content.src_type === "ads" && (
                        <React.Fragment>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Sponsored Text</div>
                              <div style={{ fontWeight: "600" }}>
                                <InlineEdit
                                  text={content.sponsored_text || "N/A"}
                                  showHover={true}
                                  onSetText={value =>
                                    this.setState({
                                      content: {
                                        ...this.state.content,
                                        sponsored_text: value,
                                      },
                                    })
                                  }
                                  onSubmit={value =>
                                    this._updateContent("sponsored_text", value)
                                  }
                                  type="textfield"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Allow Share</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"allow_share"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.allow_share == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("allow_share", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Allow Download</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"allow_download"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.allow_download == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("allow_download", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Allow Like</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"allow_like"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.allow_like == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("allow_like", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Allow Soundboard</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"allow_soundboard"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.allow_soundboard ==
                                      "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("allow_soundboard", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Allow Soundboard Reuse</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"allow_soundboard_reuse"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config
                                      .allow_soundboard_reuse == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent(
                                      "allow_soundboard_reuse",
                                      val
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Allow Comments</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"allow_commenting"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.allow_commenting ==
                                      "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("allow_commenting", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Allow Profile Image</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_profile_image"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config
                                      .display_profile_image == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent(
                                      "display_profile_image",
                                      val
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Hashtag Clickable</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"hashtag_clickable"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.hashtag_clickable ==
                                      "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent(
                                      "hashtag_clickable",
                                      val
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Allow Profile Name Click</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"allow_profile_name_click"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config
                                      .allow_profile_name_click == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent(
                                      "allow_profile_name_click",
                                      val
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display Profile Name</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_profile_name"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config
                                      .display_profile_name == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent(
                                      "display_profile_name",
                                      val
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Allow Follow</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"allow_follow"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.allow_follow == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("allow_follow", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display Camera</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_camera"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.display_camera == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("display_camera", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display Tag</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_tag"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.display_tag == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("display_tag", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display Home Tabs</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_home_tabs"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.display_home_tabs ==
                                      "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent(
                                      "display_home_tabs",
                                      val
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display Descending</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_desc"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.display_desc == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("display_desc", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display Mute</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_mute"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.display_mute == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("display_mute", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="duetable">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display Effects</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_effects"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.display_effects ==
                                      "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent("display_effects", val)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display Pages Info</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_pages_info"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.display_pages_info ==
                                      "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent(
                                      "display_pages_info",
                                      val
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display Use Asset</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_use_asset"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config.display_use_asset ==
                                      "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent(
                                      "display_use_asset",
                                      val
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Display below comment box</div>
                              <div style={{ fontWeight: "600" }}>
                                <Switch
                                  field={"display_below_comment_box"}
                                  defaultValue={
                                    content.control_config &&
                                    content.control_config
                                      .display_below_comment_box == "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={value => {
                                    let val = value ? "Y" : "N"
                                    this._updateContent(
                                      "display_below_comment_box",
                                      val
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {content.duet_meta &&
                        content.duet_meta.creator_user_name && (
                          <div className="audio d-flex align-items-center justify-content-start">
                            <i
                              style={{
                                paddingRight: "10px",
                                fontWeight: "600",
                              }}
                              className="bx bxs-user-voice"
                            />
                            {"Duet with " + content.duet_meta.creator_user_name}
                          </div>
                        )}
                      <div className="additional"></div>
                    </div>

                    <div
                      className="mb-3 mt-3"
                      style={{ borderTop: "2px solid #eff2f7" }}
                    ></div>

                    <div
                      className="text-muted mt-1"
                      style={{
                        marginBottom: "7px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Zones
                    </div>
                    <div className="grid-container">
                      <div className="grid-child">
                        <div className="location">
                          <div>
                            <Row>
                              <div style={{ fontWeight: "600" }}>
                                <Row>
                                  <Col sm="6" md="9">
                                    <label>Input Zones</label>
                                    <TextField
                                      placeholderLabel="Select Input Zones"
                                      field="input_zones"
                                      className="creator-form"
                                      value={this.state.content.input_zones}
                                      onChange={val => {
                                        if (val.trim()) {
                                          this.setState({
                                            content: {
                                              ...this.state.content,
                                              input_zones: val.split(","),
                                            },
                                          })
                                        } else {
                                          this.setState({
                                            content: {
                                              ...this.state.content,
                                              input_zones: [],
                                            },
                                          })
                                        }
                                      }}
                                      defaultValue={
                                        this.state.content.input_zones
                                      }
                                      style={{ width: "95%" }}
                                    />
                                  </Col>
                                  <Col
                                    sm="6"
                                    md="3"
                                    style={{
                                      alignSelf: "flex-end",
                                      marginBottom: "9px",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={() =>
                                        this.setState({
                                          selectInputZones: true,
                                        })
                                      }
                                    >
                                      Select
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="grid-child">
                        <div className="location">
                          <div className="">
                            <Row>
                              <div style={{ fontWeight: "600" }}>
                                <Row>
                                  <Col sm="6" md="9">
                                    {" "}
                                    <label>Active Zones</label>
                                    <TextField
                                      placeholderLabel="Select Active Zones"
                                      field="active_zones"
                                      className="creator-form"
                                      value={this.state.content.active_zones}
                                      onChange={val => {
                                        if (val.trim()) {
                                          this.setState({
                                            content: {
                                              ...this.state.content,
                                              active_zones: val.split(","),
                                            },
                                          })
                                        } else {
                                          this.setState({
                                            content: {
                                              ...this.state.content,
                                              active_zones: [],
                                            },
                                          })
                                        }
                                      }}
                                      defaultValue={
                                        this.state.content.active_zones
                                      }
                                      style={{ width: "95%" }}
                                    />
                                  </Col>
                                  <Col
                                    sm="6"
                                    md="3"
                                    style={{
                                      alignSelf: "flex-end",
                                      marginBottom: "9px",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={() =>
                                        this.setState({
                                          selectActiveZoneModal: true,
                                          // type: this.state.elementType,
                                        })
                                      }
                                    >
                                      Select
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        size="small"
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          if (!this.state.hasEditZonePermission) {
                            toastr.error(NO_PERMISSION_MESSAGE)
                          } else {
                            this._updateZone()
                          }
                        }}
                      >
                        Save
                      </Button>
                    </div>
                    <div
                      className="mb-3 mt-3"
                      style={{ borderTop: "2px solid #eff2f7" }}
                    ></div>
                    <div
                      className="text-muted mt-1"
                      style={{
                        marginBottom: "7px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Greeting Caption
                    </div>
                    <div className="grid-container2">
                      <div className="grid-child">
                        <div className="location">
                          <div className="">
                            <Row>
                              {" "}
                              <div style={{ fontWeight: "600" }}>
                                <Row>
                                  <Col>
                                    {" "}
                                    <label>Input Captions</label>
                                    <TextField
                                      placeholderLabel="Enter JSON"
                                      field="caption_input"
                                      className="creator-form"
                                      value={this.state.captions}
                                      onChange={val => {
                                        if (val.trim()) {
                                          if (!isJson(val.trim())) {
                                            this.setState({
                                              captions: [],
                                            })
                                            return toastr.error(
                                              "Enter valid JSON"
                                            )
                                          } else {
                                            this.setState({
                                              captions: val.trim(),
                                            })
                                          }
                                        }
                                      }}
                                      style={{ width: "100%" }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        size="small"
                        variant="outlined"
                        className="mx-1"
                        color="info"
                        onClick={() => {
                          this.props.history.push(
                            `/cmsVideoGreetings/manage?item_uuid=${content.content_uuid}`
                          )
                        }}
                      >
                        Preview
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          if (!this.state.hasEditZonePermission) {
                            toastr.error(NO_PERMISSION_MESSAGE)
                          } else {
                            this._updateCaptions()
                          }
                        }}
                      >
                        Save
                      </Button>
                    </div>
                    <div
                      className="mb-3 mt-3"
                      style={{ borderTop: "2px solid #eff2f7" }}
                    ></div>

                    <div
                      className="text-muted mt-1"
                      style={{
                        marginBottom: "7px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Stats
                    </div>
                    <div className="grid-container">
                      <div className="grid-child">
                        <div className="d-flex align-items-center justify-content-between">
                          <td>Views</td>
                          <th>
                            {formatNumber(
                              content.view_count + content.fk_views
                            ) || 0}
                          </th>
                        </div>
                      </div>
                      <div className="grid-child">
                        <div className="d-flex align-items-center justify-content-between">
                          <td>Downloads</td>
                          <th>
                            {formatNumber(
                              content.download_count + content.fk_downloads
                            ) || 0}
                          </th>
                        </div>
                      </div>
                      <div className="language">
                        <div className="d-flex align-items-center justify-content-between">
                          <td>Likes</td>
                          <th>
                            {formatNumber(
                              content.like_count + content.fk_likes
                            ) || 0}
                          </th>
                        </div>
                      </div>
                      <div className="language">
                        <div className="d-flex align-items-center justify-content-between">
                          <td>Shares</td>
                          <th>
                            {formatNumber(
                              content.share_count + content.fk_shares
                            ) || 0}
                          </th>
                        </div>
                      </div>
                      {realStatPermissionUser && (
                        <>
                          <div className="grid-child">
                            <div className="d-flex align-items-center justify-content-between">
                              <td>Real Views</td>
                              <th>{formatNumber(content.view_count) || 0}</th>
                            </div>
                          </div>
                          <div className="grid-child">
                            <div className="d-flex align-items-center justify-content-between">
                              <td>Real Downloads</td>
                              <th>
                                {formatNumber(content.download_count) || 0}
                              </th>
                            </div>
                          </div>
                          <div className="language">
                            <div className="d-flex align-items-center justify-content-between">
                              <td>Real Likes</td>
                              <th>{formatNumber(content.like_count) || 0}</th>
                            </div>
                          </div>
                          <div className="language">
                            <div className="d-flex align-items-center justify-content-between">
                              <td>Real Shares</td>
                              <th>
                                {content.share_count
                                  ? formatNumber(content.share_count)
                                  : 0}
                              </th>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className="mb-3 mt-3"
                      style={{ borderTop: "2px solid #eff2f7" }}
                    ></div>
                    <div
                      className="text-muted mt-1"
                      style={{
                        marginBottom: "7px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Custom CTA
                    </div>
                    <div className="">
                      <Row>
                        <div className="d-flex align-items-center">
                          <span className="me-2 font-size-16">Like CTA:</span>
                          <Switch
                            onChange={val =>
                              this.setState({ enableLikeCta: val }, () => {
                                !this.state.enableLikeCta &&
                                  this._onSubmitLike()
                              })
                            }
                            defaultValue={this.state.enableLikeCta}
                            disabled={!this.props.hasEditPermission}
                          />
                        </div>
                        {this.state?.enableLikeCta ? (
                          <div>
                            <ValidatorForm
                              onSubmit={this._onSubmitLike}
                              layout={"vertical"}
                              {...this._formLayout}
                            >
                              <Row>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Entity Type"}
                                    field={"like_cta.type"}
                                    className="creator-form"
                                    value="LIKE"
                                    disabled
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Title"}
                                    placeholderLabel="Enter Title"
                                    field={"like_cta.title"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.like_cta &&
                                        this.state.like_cta.title) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Sub-Title"}
                                    placeholderLabel="Enter Sub-Title"
                                    field={"like_cta.subtitle"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.like_cta &&
                                        this.state.like_cta.subtitle) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        CTA Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "cta_icon_like",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.like_cta?.icon_url &&
                                    !this.state.cta_icon_like ? (
                                      <>
                                        <img
                                          src={this.state?.like_cta?.icon_url}
                                          width="50px"
                                          height="50px"
                                          className="mx-2 mt-3"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              cta_icon_like: null,
                                              like_cta: {
                                                ...prev_state.like_cta,
                                                icon_url: null,
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Divider className="m-auto">
                                  <h5 className="mb-0 text-muted font-size-14">
                                    CTA
                                  </h5>
                                </Divider>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Text"}
                                    placeholderLabel="Enter Text"
                                    field={"like_cta.cta.text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.like_cta &&
                                        this.state.like_cta.cta &&
                                        this.state.like_cta.cta.text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Selected Text"}
                                    placeholderLabel="Enter Selected Text"
                                    field={"like_cta.cta.selected_text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.like_cta &&
                                        this.state.like_cta.cta &&
                                        this.state.like_cta.cta
                                          .selected_text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <Select
                                    label={"CTA View Type"}
                                    placeholderLabel="Enter View Type"
                                    field="like_cta.cta.view_type"
                                    className="creator-form"
                                    options={[
                                      {
                                        label: "Button with background",
                                        value: 1,
                                      },
                                      { label: "Arrow", value: 2 },
                                      { label: "Button with icon", value: 3 },
                                      { label: "Text with arrow", value: 4 },
                                      { label: "Only text", value: 5 },
                                    ]}
                                    defaultValue={
                                      (this.state.like_cta &&
                                        this.state.like_cta.cta &&
                                        this.state.like_cta.cta.view_type &&
                                        +this.state.like_cta.cta.view_type) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_icon_like",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.like_cta?.cta?.icon_url &&
                                    !this.state.button_icon_like ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.like_cta?.cta?.icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_icon_like: null,
                                              like_cta: {
                                                ...(prev_state.like_cta || {}),
                                                cta: {
                                                  ...(prev_state?.like_cta
                                                    ?.cta || {}),
                                                  icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Selected Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_selected_icon_like",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.like_cta?.cta
                                      ?.selected_icon_url &&
                                    !this.state.button_selected_icon_like ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.like_cta?.cta
                                              ?.selected_icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_selected_icon_like: null,
                                              like_cta: {
                                                ...(prev_state.like_cta || {}),
                                                cta: {
                                                  ...(prev_state?.like_cta
                                                    ?.cta || {}),
                                                  selected_icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>

                                <div className="text-muted font-size-13 mt-2 mb-2">
                                  <span className="font-weight-semibold">
                                    Note:
                                  </span>{" "}
                                  If no value selected, it will take the default
                                  value for some fields.
                                </div>
                                <div className="d-flex justify-content-end mb-2">
                                  <Button
                                    size="small"
                                    color="success"
                                    variant="outlined"
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </ValidatorForm>
                          </div>
                        ) : null}
                      </Row>
                      <Row className="border-top">
                        <div className="d-flex align-items-center">
                          <span className="me-2 font-size-16">
                            Comment CTA:
                          </span>
                          <Switch
                            onChange={val =>
                              this.setState({ enableCommentCta: val }, () => {
                                !this.state.enableCommentCta &&
                                  this._onSubmitComment()
                              })
                            }
                            defaultValue={this.state.enableCommentCta}
                            disabled={!this.props.hasEditPermission}
                          />
                        </div>
                        {this.state?.enableCommentCta ? (
                          <div>
                            <ValidatorForm
                              onSubmit={this._onSubmitComment}
                              layout={"vertical"}
                              {...this._formLayout}
                            >
                              <Row>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Entity Type"}
                                    field={"comment_cta.type"}
                                    className="creator-form"
                                    value="COMMENT"
                                    disabled
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Title"}
                                    placeholderLabel="Enter Title"
                                    field={"comment_cta.title"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.comment_cta &&
                                        this.state.comment_cta.title) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Sub-Title"}
                                    placeholderLabel="Enter Sub-Title"
                                    field={"comment_cta.subtitle"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.comment_cta &&
                                        this.state.comment_cta.subtitle) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        CTA Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "cta_icon_comment",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.comment_cta?.icon_url &&
                                    !this.state.cta_icon_comment ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.comment_cta?.icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="mx-2 mt-3"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              cta_icon_comment: null,
                                              comment_cta: {
                                                ...prev_state.comment_cta,
                                                icon_url: null,
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Divider className="m-auto">
                                  <h5 className="mb-0 text-muted font-size-14">
                                    CTA
                                  </h5>
                                </Divider>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Text"}
                                    placeholderLabel="Enter Text"
                                    field={"comment_cta.cta.text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.comment_cta &&
                                        this.state.comment_cta.cta &&
                                        this.state.comment_cta.cta.text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Selected Text"}
                                    placeholderLabel="Enter Selected Text"
                                    field={"comment_cta.cta.selected_text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.comment_cta &&
                                        this.state.comment_cta.cta &&
                                        this.state.comment_cta.cta
                                          .selected_text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <Select
                                    label={"CTA View Type"}
                                    placeholderLabel="Enter View Type"
                                    field="comment_cta.cta.view_type"
                                    className="creator-form"
                                    options={[
                                      {
                                        label: "Button with background",
                                        value: 1,
                                      },
                                      { label: "Arrow", value: 2 },
                                      { label: "Button with icon", value: 3 },
                                      { label: "Text with arrow", value: 4 },
                                      { label: "Only text", value: 5 },
                                    ]}
                                    defaultValue={
                                      (this.state.comment_cta &&
                                        this.state.comment_cta.cta &&
                                        this.state.comment_cta.cta.view_type &&
                                        +this.state.comment_cta.cta
                                          .view_type) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_icon_comment",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.comment_cta?.cta?.icon_url &&
                                    !this.state.button_icon_comment ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.comment_cta?.cta
                                              ?.icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_icon_comment: null,
                                              comment_cta: {
                                                ...(prev_state.comment_cta ||
                                                  {}),
                                                cta: {
                                                  ...(prev_state?.comment_cta
                                                    ?.cta || {}),
                                                  icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Selected Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_selected_icon_comment",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.comment_cta?.cta
                                      ?.selected_icon_url &&
                                    !this.state.button_selected_icon_comment ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.comment_cta?.cta
                                              ?.selected_icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_selected_icon_comment:
                                                null,
                                              comment_cta: {
                                                ...(prev_state.comment_cta ||
                                                  {}),
                                                cta: {
                                                  ...(prev_state?.comment_cta
                                                    ?.cta || {}),
                                                  selected_icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>

                                <div className="text-muted font-size-13 mt-2 mb-2">
                                  <span className="font-weight-semibold">
                                    Note:
                                  </span>{" "}
                                  If no value selected, it will take the default
                                  value for some fields.
                                </div>
                                <div className="d-flex justify-content-end mb-2">
                                  <Button
                                    size="small"
                                    color="success"
                                    variant="outlined"
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </ValidatorForm>
                          </div>
                        ) : null}
                      </Row>
                      <Row className="border-top">
                        <div className="d-flex align-items-center">
                          <span className="me-2 font-size-16">Share CTA:</span>
                          <Switch
                            onChange={val =>
                              this.setState({ enableShareCta: val }, () => {
                                !this.state.enableShareCta &&
                                  this._onSubmitShare()
                              })
                            }
                            defaultValue={this.state.enableShareCta}
                            disabled={!this.props.hasEditPermission}
                          />
                        </div>
                        {this.state?.enableShareCta ? (
                          <div>
                            <ValidatorForm
                              onSubmit={this._onSubmitShare}
                              layout={"vertical"}
                              {...this._formLayout}
                            >
                              <Row>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Entity Type"}
                                    field={"share_cta.type"}
                                    className="creator-form"
                                    value="SHARE"
                                    disabled
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Title"}
                                    placeholderLabel="Enter Title"
                                    field={"share_cta.title"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.share_cta &&
                                        this.state.share_cta.title) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Sub-Title"}
                                    placeholderLabel="Enter Sub-Title"
                                    field={"share_cta.subtitle"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.share_cta &&
                                        this.state.share_cta.subtitle) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        CTA Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "cta_icon_share",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.share_cta?.icon_url &&
                                    !this.state.cta_icon_share ? (
                                      <>
                                        <img
                                          src={this.state?.share_cta?.icon_url}
                                          width="50px"
                                          height="50px"
                                          className="mx-2 mt-3"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              cta_icon_share: null,
                                              share_cta: {
                                                ...prev_state.share_cta,
                                                icon_url: null,
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Divider className="m-auto">
                                  <h5 className="mb-0 text-muted font-size-14">
                                    CTA
                                  </h5>
                                </Divider>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Text"}
                                    placeholderLabel="Enter Text"
                                    field={"share_cta.cta.text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.share_cta &&
                                        this.state.share_cta.cta &&
                                        this.state.share_cta.cta.text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Selected Text"}
                                    placeholderLabel="Enter Selected Text"
                                    field={"share_cta.cta.selected_text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.share_cta &&
                                        this.state.share_cta.cta &&
                                        this.state.share_cta.cta
                                          .selected_text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <Select
                                    label={"CTA View Type"}
                                    placeholderLabel="Enter View Type"
                                    field="share_cta.cta.view_type"
                                    className="creator-form"
                                    options={[
                                      {
                                        label: "Button with background",
                                        value: 1,
                                      },
                                      { label: "Arrow", value: 2 },
                                      { label: "Button with icon", value: 3 },
                                      { label: "Text with arrow", value: 4 },
                                      { label: "Only text", value: 5 },
                                    ]}
                                    defaultValue={
                                      (this.state.share_cta &&
                                        this.state.share_cta.cta &&
                                        this.state.share_cta.cta.view_type &&
                                        +this.state.share_cta.cta.view_type) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_icon_share",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.share_cta?.cta?.icon_url &&
                                    !this.state.button_icon_share ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.share_cta?.cta?.icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_icon_share: null,
                                              share_cta: {
                                                ...(prev_state.share_cta || {}),
                                                cta: {
                                                  ...(prev_state?.share_cta
                                                    ?.cta || {}),
                                                  icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Selected Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_selected_icon_share",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.share_cta?.cta
                                      ?.selected_icon_url &&
                                    !this.state.button_selected_icon_share ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.share_cta?.cta
                                              ?.selected_icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_selected_icon_share: null,
                                              share_cta: {
                                                ...(prev_state.share_cta || {}),
                                                cta: {
                                                  ...(prev_state?.share_cta
                                                    ?.cta || {}),
                                                  selected_icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>

                                <div className="text-muted font-size-13 mt-2 mb-2">
                                  <span className="font-weight-semibold">
                                    Note:
                                  </span>{" "}
                                  If no value selected, it will take the default
                                  value for some fields.
                                </div>
                                <div className="d-flex justify-content-end mb-2">
                                  <Button
                                    size="small"
                                    color="success"
                                    variant="outlined"
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </ValidatorForm>
                          </div>
                        ) : null}
                      </Row>
                      <Row className="border-top">
                        <div className="d-flex align-items-center">
                          <span className="me-2 font-size-16">Duet CTA:</span>
                          <Switch
                            onChange={val =>
                              this.setState({ enableDuetCta: val }, () => {
                                !this.state.enableDuetCta &&
                                  this._onSubmitDuet()
                              })
                            }
                            defaultValue={this.state.enableDuetCta}
                            disabled={!this.props.hasEditPermission}
                          />
                        </div>
                        {this.state?.enableDuetCta ? (
                          <div>
                            <ValidatorForm
                              onSubmit={this._onSubmitDuet}
                              layout={"vertical"}
                              {...this._formLayout}
                            >
                              <Row>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Entity Type"}
                                    field={"duet_cta.type"}
                                    className="creator-form"
                                    value="DUET"
                                    disabled
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Title"}
                                    placeholderLabel="Enter Title"
                                    field={"duet_cta.title"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.duet_cta &&
                                        this.state.duet_cta.title) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Sub-Title"}
                                    placeholderLabel="Enter Sub-Title"
                                    field={"duet_cta.subtitle"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.duet_cta &&
                                        this.state.duet_cta.subtitle) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        CTA Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "cta_icon_duet",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.duet_cta?.icon_url &&
                                    !this.state.cta_icon_duet ? (
                                      <>
                                        <img
                                          src={this.state?.duet_cta?.icon_url}
                                          width="50px"
                                          height="50px"
                                          className="mx-2 mt-3"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              cta_icon_duet: null,
                                              duet_cta: {
                                                ...prev_state.duet_cta,
                                                icon_url: null,
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Divider className="m-auto">
                                  <h5 className="mb-0 text-muted font-size-14">
                                    CTA
                                  </h5>
                                </Divider>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Text"}
                                    placeholderLabel="Enter Text"
                                    field={"duet_cta.cta.text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.duet_cta &&
                                        this.state.duet_cta.cta &&
                                        this.state.duet_cta.cta.text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Selected Text"}
                                    placeholderLabel="Enter Selected Text"
                                    field={"duet_cta.cta.selected_text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.duet_cta &&
                                        this.state.duet_cta.cta &&
                                        this.state.duet_cta.cta
                                          .selected_text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <Select
                                    label={"CTA View Type"}
                                    placeholderLabel="Enter View Type"
                                    field="duet_cta.cta.view_type"
                                    className="creator-form"
                                    options={[
                                      {
                                        label: "Button with background",
                                        value: 1,
                                      },
                                      { label: "Arrow", value: 2 },
                                      { label: "Button with icon", value: 3 },
                                      { label: "Text with arrow", value: 4 },
                                      { label: "Only text", value: 5 },
                                    ]}
                                    defaultValue={
                                      (this.state.duet_cta &&
                                        this.state.duet_cta.cta &&
                                        this.state.duet_cta.cta.view_type &&
                                        +this.state.duet_cta.cta.view_type) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_icon_duet",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.duet_cta?.cta?.icon_url &&
                                    !this.state.button_icon_duet ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.duet_cta?.cta?.icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_icon_duet: null,
                                              duet_cta: {
                                                ...(prev_state.duet_cta || {}),
                                                cta: {
                                                  ...(prev_state?.duet_cta
                                                    ?.cta || {}),
                                                  icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Selected Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_selected_icon_duet",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.duet_cta?.cta
                                      ?.selected_icon_url &&
                                    !this.state.button_selected_icon_duet ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.duet_cta?.cta
                                              ?.selected_icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_selected_icon_duet: null,
                                              duet_cta: {
                                                ...(prev_state.duet_cta || {}),
                                                cta: {
                                                  ...(prev_state?.duet_cta
                                                    ?.cta || {}),
                                                  selected_icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>

                                <div className="text-muted font-size-13 mt-2 mb-2 text-danger">
                                  <span className="font-weight-semibold">
                                    Note:
                                  </span>{" "}
                                  If no value selected, it will take the default
                                  value for some fields.
                                </div>
                                <div className="d-flex justify-content-end mb-2">
                                  <Button
                                    size="small"
                                    color="success"
                                    variant="outlined"
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </ValidatorForm>
                          </div>
                        ) : null}
                      </Row>
                      <Row className="border-top">
                        <div className="d-flex align-items-center">
                          <span className="me-2 font-size-16">
                            Deeplink CTA:
                          </span>
                          <Switch
                            onChange={val =>
                              this.setState({ enableDeeplinkCta: val }, () => {
                                !this.state.enableDeeplinkCta &&
                                  this._onSubmitDeeplink()
                              })
                            }
                            defaultValue={this.state.enableDeeplinkCta}
                            disabled={!this.props.hasEditPermission}
                          />
                        </div>
                        {this.state?.enableDeeplinkCta ? (
                          <div>
                            <ValidatorForm
                              onSubmit={this._onSubmitDeeplink}
                              layout={"vertical"}
                              {...this._formLayout}
                            >
                              <Row>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Entity Type"}
                                    field={"deeplink_cta.type"}
                                    className="creator-form"
                                    value="DEEPLINK"
                                    disabled
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Title"}
                                    placeholderLabel="Enter Title"
                                    field={"deeplink_cta.title"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.deeplink_cta &&
                                        this.state.deeplink_cta.title) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"Sub-Title"}
                                    placeholderLabel="Enter Sub-Title"
                                    field={"deeplink_cta.subtitle"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.deeplink_cta &&
                                        this.state.deeplink_cta.subtitle) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <TextField
                                    label="Deep Link"
                                    placeholderLabel="Enter Deep Link"
                                    field={"deeplink_cta.deep_link"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.deeplink_cta &&
                                        this.state.deeplink_cta.deep_link) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <label className="col-form-label">
                                        CTA Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "cta_icon_duet",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.deeplink_cta?.icon_url &&
                                    !this.state.cta_icon_duet ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.deeplink_cta?.icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="mx-2 mt-3"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              cta_icon_duet: null,
                                              deeplink_cta: {
                                                ...prev_state.deeplink_cta,
                                                icon_url: null,
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Divider className="m-auto">
                                  <h5 className="mb-0 text-muted font-size-14">
                                    CTA
                                  </h5>
                                </Divider>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Text"}
                                    placeholderLabel="Enter Text"
                                    field={"deeplink_cta.cta.text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.deeplink_cta &&
                                        this.state.deeplink_cta.cta &&
                                        this.state.deeplink_cta.cta.text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <TextField
                                    label={"CTA Selected Text"}
                                    placeholderLabel="Enter Selected Text"
                                    field={"deeplink_cta.cta.selected_text"}
                                    className="creator-form"
                                    defaultValue={
                                      (this.state.deeplink_cta &&
                                        this.state.deeplink_cta.cta &&
                                        this.state.deeplink_cta.cta
                                          .selected_text) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={4} lg={4} className="mb-2">
                                  <Select
                                    label={"CTA View Type"}
                                    placeholderLabel="Enter View Type"
                                    field="deeplink_cta.cta.view_type"
                                    className="creator-form"
                                    options={[
                                      {
                                        label: "Button with background",
                                        value: 1,
                                      },
                                      { label: "Arrow", value: 2 },
                                      { label: "Button with icon", value: 3 },
                                      { label: "Text with arrow", value: 4 },
                                      { label: "Only text", value: 5 },
                                    ]}
                                    defaultValue={
                                      (this.state.deeplink_cta &&
                                        this.state.deeplink_cta.cta &&
                                        this.state.deeplink_cta.cta.view_type &&
                                        +this.state.deeplink_cta.cta
                                          .view_type) ||
                                      ""
                                    }
                                  />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_icon_duet",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.deeplink_cta?.cta?.icon_url &&
                                    !this.state.button_icon_duet ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.deeplink_cta?.cta
                                              ?.icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_icon_duet: null,
                                              deeplink_cta: {
                                                ...(prev_state.deeplink_cta ||
                                                  {}),
                                                cta: {
                                                  ...(prev_state?.deeplink_cta
                                                    ?.cta || {}),
                                                  icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="me-2">
                                      <label className="col-form-label">
                                        Button Selected Icon
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        onChange={e => {
                                          this._handleFileUpload(
                                            "button_selected_icon_duet",
                                            e.target.files[0]
                                          )
                                        }}
                                      />
                                    </div>
                                    {this.state?.deeplink_cta?.cta
                                      ?.selected_icon_url &&
                                    !this.state.button_selected_icon_duet ? (
                                      <>
                                        <img
                                          src={
                                            this.state?.deeplink_cta?.cta
                                              ?.selected_icon_url
                                          }
                                          width="50px"
                                          height="50px"
                                          className="me-2 mt-2"
                                        />
                                        <i
                                          className="bx bx bx-trash-alt text-danger mt-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState(prev_state => ({
                                              button_selected_icon_duet: null,
                                              deeplink_cta: {
                                                ...(prev_state.deeplink_cta ||
                                                  {}),
                                                cta: {
                                                  ...(prev_state?.deeplink_cta
                                                    ?.cta || {}),
                                                  selected_icon_url: null,
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </Col>

                                <div className="text-muted font-size-13 mt-2 mb-2 text-danger">
                                  <span className="font-weight-semibold">
                                    Note:
                                  </span>{" "}
                                  If no value selected, it will take the default
                                  value for some fields.
                                </div>
                                <div className="d-flex justify-content-end mb-2">
                                  <Button
                                    size="small"
                                    color="success"
                                    variant="outlined"
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </ValidatorForm>
                          </div>
                        ) : null}
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              )}
              {this.state.showActionForm && (
                <div className="p-3">
                  <ActionForm
                    onCancel={() => this.setState({ showActionForm: false })}
                    onSubmit={this._onActionSubmit}
                    data={this.state.content && this.state.content.action_meta}
                  />
                </div>
              )}
            </SimpleBar>
          </Col>
          <Col xl={4} lg={4} md={12} sm={12}>
            <Timer direction="forward">
              {({ start, resume, pause, stop, reset }) => {
                if (this.props.shouldReset) {
                  reset()
                  this.props.changeReset()
                }
                return (
                  <div className="d-flex flex-column align-items-center">
                    <div>
                      <video
                        key={this.props.content.content_uuid}
                        src={
                          this.state.playOriginal
                            ? this.props.content.original_url
                            : this.props.content.preview_url
                        }
                        style={{ height: "65vh", width: "38vh" }}
                        ref={this.videoRef}
                        onCanPlay={() => this._setPlayBack()}
                        controls={this.state.controls}
                        onEnded={() => {
                          this.setState({ controls: true })
                        }}
                        controlsList={
                          this.props.currentUser.jotUserType > 3
                            ? "nodownload"
                            : ""
                        }
                        muted={
                          this.props.pageType === "tagContent" ? false : true
                        }
                        onContextMenu={e => {
                          e.preventDefault()
                          return false
                        }}
                        autoPlay
                      />
                    </div>

                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "46vh" }}
                    >
                      <div
                        className="btn-group mt-2 mt-xl-"
                        data-toggle="button"
                        onChange={event =>
                          this.setState({ playbackRate: event.target.value })
                        }
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio1"
                          value="1.0"
                          autoComplete="off"
                          defaultChecked={this.state.playbackRate === "1.0"}
                        />
                        <label
                          className="btn btn-secondary"
                          htmlFor="btnradio1"
                        >
                          1X
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio2"
                          value="2.0"
                          autoComplete="off"
                          defaultChecked={this.state.playbackRate === "2.0"}
                        />
                        <label
                          className="btn btn-secondary"
                          htmlFor="btnradio2"
                        >
                          2X
                        </label>
                      </div>
                      {hasPlayOriginalPermission && (
                        <div>
                          <FormControlLabel
                            value={this.state.playOriginal}
                            control={
                              <MuiSwitch
                                color="primary"
                                checked={this.state.playOriginal}
                                onChange={e => {
                                  this.setState({
                                    playOriginal: e.target.checked,
                                  })
                                }}
                              />
                            }
                            label="Original"
                            labelPlacement="top"
                          />
                        </div>
                      )}
                      {content.video_duration && (
                        <div>
                          <Tooltip title="Video Duration in Seconds">
                            <Badge pill className="badge-soft-success">
                              <div className="fs-4">
                                {content.video_duration
                                  ? Math.round(content.video_duration)
                                  : ""}
                              </div>
                            </Badge>
                          </Tooltip>
                        </div>
                      )}
                      {/* <div>
                        <div>
                          <Badge pill className="badge-soft-success fs-5">
                            <Timer.Minutes /> :&nbsp;
                            <Timer.Seconds />
                          </Badge>
                        </div>
                      </div> */}
                      {!this.props.isMobile && (
                        <div
                          className="d-flex justify-content-evenly align-items-center fs-4 pe-sm-3"
                          style={{ width: "15vh" }}
                        >
                          <CopyToClipboard
                            text={content.content_uuid}
                            onCopy={e => {
                              toastr.success("Copied sucessfully!")
                            }}
                          >
                            <Tooltip title="Copy Content ID">
                              <i className="bx bx-copy" role="button" />
                            </Tooltip>
                          </CopyToClipboard>
                          <CopyToClipboard
                            text={content.user_profile.user_uuid}
                            onCopy={e => {
                              toastr.success("Copied sucessfully!")
                            }}
                          >
                            <Tooltip title="Copy User UUID">
                              <i
                                className="mdi mdi-information-variant"
                                role="button"
                              />
                            </Tooltip>
                          </CopyToClipboard>
                          <CopyToClipboard
                            text={content.share_url}
                            onCopy={e => {
                              toastr.success("Copied sucessfully!")
                            }}
                          >
                            <Tooltip title="Copy Share URl">
                              <i className="bx bx-share-alt" role="button" />
                            </Tooltip>
                          </CopyToClipboard>
                        </div>
                      )}
                    </div>
                  </div>
                )
              }}
            </Timer>
          </Col>
          {this.state.selectInputUser && (
            <SearchAndSelect
              type="CREATOR"
              // selectOne={true}
              visible={this.state.selectInputUser}
              onClose={() => this.setState({ selectInputUser: false })}
              onAdd={arr => {
                this.setState({
                  viral: {
                    ...this.state.viral,
                    user_id: arr || [],
                  },
                })
              }}
              selectedArr={
                this.state?.viral?.user_id
                  ? Array.isArray(this.state.viral.user_id)
                    ? _.uniq(this.state.viral.user_id.map(a => a))
                    : [this.state.viral.user_id]
                  : []
              }
            />
          )}
          {this.state.selectInputZones && (
            <SearchAndSelect
              type="ZONE"
              visible={this.state.selectInputZones}
              onClose={() => this.setState({ selectInputZones: false })}
              onAdd={arr =>
                this.setState({
                  content: { ...this.state.content, input_zones: arr },
                })
              }
              selectedArr={
                this.state.content.input_zones
                  ? _.uniq(this.state.content.input_zones.map(a => a))
                  : []
              }
              allZoneList={this.props.zoneList.map(zone => {
                return {
                  ...zone,
                  value: zone.zone_uuid,
                  label: zone.title || zone.sub_title,
                }
              })}
            />
          )}
          {this.state.selectActiveZoneModal && (
            <SearchAndSelect
              type="ZONE"
              visible={this.state.selectActiveZoneModal}
              onClose={() => this.setState({ selectActiveZoneModal: false })}
              onAdd={arr =>
                this.setState({
                  content: { ...this.state.content, active_zones: arr },
                })
              }
              selectedArr={
                this.state.content.active_zones
                  ? _.uniq(this.state.content.active_zones.map(a => a))
                  : []
              }
              allZoneList={this.props.zoneList.map(zone => {
                return {
                  ...zone,
                  value: zone.zone_uuid,
                  label: zone.title || zone.sub_title,
                }
              })}
            />
          )}
        </Row>
      )
    }
  }
}

function mapStateToProps(store) {
  return {
    contentList: _.get(store, "content.content"),
    locationList: _.get(store, "locationReducer.locationList"),
    audioList: _.get(store, "audio.audioList"),
    queryParams: _.get(store, "routing.location.search")
      ? QueryString.parseUrl(_.get(store, "routing.location.search")).query
      : undefined,
    total: _.get(store, "content.total"),
    searchAfter: _.get(store, "content.searchAfter"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.userList"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "locationReducer.loading"),
    trendingEntityList: _.get(store, "trending.trendingEntityList"),
    zoneList: _.get(store, "zone.allZoneList"),
    campaignList: _.get(store, "campaign.campaign"),
    totalCampaign: _.get(store, "campaign.totalCampaign"),
  }
}

export default withRouter(connect(mapStateToProps)(ContentDetails))
