import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Collapse,
  Button,
  Table,
  Badge,
  Row,
  CardTitle,
  CardImg,
  CardText,
} from "reactstrap"
import ReactTimeAgo from "react-time-ago"
import { CopyToClipboard } from "react-copy-to-clipboard"
import images from "assets/images"
import { isEmpty, size, map } from "lodash"
import { Tooltip } from "antd"
import { DivOverlay } from "react-leaflet"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Switch } from "../ValidatorForm"
import { LANGUAGES, LANGUAGE_MAPPING } from "../../constants/uiConstants"
import "./styles.css"

let currentDate = Date.now()

class TrendingCard extends React.Component {
  state = { isPlaying: false }
  audioRef = React.createRef()

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  _playPreview() {
    if (!this.state.isPlaying) {
      this.setState({ isPlaying: true })
      this.audioRef.play()
    } else {
      this.setState({ isPlaying: false })
      this.audioRef.pause()
    }
  }

  _renderArrayElements = (dataArr, tooltipTitle, type) => {
    return (
      <React.Fragment>
        {dataArr &&
          dataArr.length > 0 &&
          dataArr.slice(0, 2).map((tag, i) => {
            return (
              <Badge className="badge-soft-primary ms-1 my-1">
                <div
                  className="font-size-15"
                  key={"language" + this.props.campaign.campaign_uuid + i}
                >
                  <Tooltip title={tooltipTitle}>
                    {type === "language" ? this._getLanguage(tag) : tag}
                  </Tooltip>
                </div>
              </Badge>
            )
          })}
        {dataArr && dataArr.length > 2 && (
          <Tooltip
            title={dataArr.slice(2, dataArr.length).map(element => {
              return (
                <div>
                  {type === "language" ? this._getLanguage(element) : element}
                </div>
              )
            })}
            placement="right"
          >
            <Badge className="badge-soft-primary ms-1 my-1">
              <div
                className="font-size-15 px-2"
                key={"element_more" + dataArr.length}
                style={{ cursor: "pointer" }}
              >
                +{dataArr.length - 2} more
              </div>
            </Badge>
          </Tooltip>
        )}
      </React.Fragment>
    )
  }

  render() {
    const {
      trendingEntity = {},
      audioDetails = [],
      campaign,
      source = {},
    } = this.props
    const [audio] = audioDetails || []
    const endDate = new Date(campaign.end_time * 1)
    const startDate = new Date(campaign.start_time * 1)

    return (
      <React.Fragment>
        <Card className="text-center shadow-sm" style={{ minHeight: "280px" }}>
          <div className="py-3 font-size-20">{campaign.campaign_name}</div>
          <CardBody>
            <div className="table-responsive">
              <Table className=" mb-0 table-striped">
                <tbody>
                  <tr className="text-start">
                    <th scope="row">Name:</th>
                    <td>{campaign.campaign_name || "-"}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Ad name:</th>
                    <td>{campaign.ad_name || "-"}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Ad set:</th>
                    <td>{campaign.ad_set || "-"}</td>
                  </tr>

                  <tr className="text-start">
                    <th scope="row">Genre:</th>
                    <td>{campaign.genre || "-"}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Item Id:</th>
                    <td>{campaign.item_id || "-"}</td>
                  </tr>

                  <tr className="text-start">
                    <th scope="row">Languages:</th>
                    <td>{LANGUAGE_MAPPING[campaign.language] || "-"}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Channel:</th>
                    <td>{campaign.channel || "-"}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Zone Id:</th>
                    <td>{campaign.zone_id || "-"}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Is Affiliate:</th>
                    <Switch
                      className="creator-form"
                      defaultValue={
                        campaign.is_affiliate && campaign.is_affiliate === 1
                      }
                      disabled={true}
                    />
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <CopyToClipboard
                  text={campaign.campaign_uuid}
                  title="Copy Campaign Id"
                  onCopy={() => {
                    toastr.success("Copied Successfully")
                  }}
                >
                  <Tooltip title="Copy Source ID">
                    <div role="button">
                      <i className="bx bx-copy" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              {/* <div className="flex-fill">
                <CopyToClipboard title="Edit">
                  <Tooltip title="Edit Campaign">
                    <div
                      role="button"
                      onClick={() => this.props.onEditClick(campaign)}
                    >
                      <i className="bx bx-pencil" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <div className="flex-fill">
                <CopyToClipboard title="Delete">
                  <Tooltip title="Delete Campaign">
                    <div
                      role="button"
                      onClick={() =>
                        this.props.onDeleteClick(campaign, "delete")
                      }
                    >
                      <i className="mdi mdi-delete-outline" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div> */}
            </div>
          </CardFooter>
        </Card>
      </React.Fragment>
    )
  }
}

export default TrendingCard
