import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Card, CardBody, Badge, Row, CardImg } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { size, map } from "lodash"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { getImage } from "../../components/SearchAndSelect/util"
import { removePageEntity } from "../../appRedux/slices/zeroSearch"
import "./pageStyle.css"

class CollectionEntityCard extends React.Component {
  state = { isFlipped: false }

  _getLocations = (data, type) => {
    let locations = []
    let languages = []
    data.target.forEach(entity => {
      if (
        type === "location" &&
        entity.lang_code === "default" &&
        entity.locations &&
        entity.locations.length > 0
      ) {
        entity.locations.forEach(location => {
          locations.push(location.location_id)
        })
      }
      if (type === "language") {
        languages.push(entity.lang_code)
      }
    })
    return type === "location" ? locations : languages
  }

  _removePageEntity = async entityId => {
    const response = await this.props.dispatch(removePageEntity(entityId))
  }

  render() {
    const { data } = this.props
    const image =
      data.thumbnail ||
      data.profile_pic ||
      data.banner_url ||
      data.static_thumbnail ||
      data.cover_url ||
      data.sticker_thumbnail_url ||
      data.album_art ||
      data.thumbnail_url ||
      data.zone_image
    const title =
      data["title"] ||
      data["heading_title"] ||
      data["hashtag"] ||
      data["name"] ||
      data["page_title"] ||
      data["sticker_name"] ||
      data["asset_label"] ||
      data["collectionId"] ||
      ""

    return (
      <Card
        className={`text-center shadow-lg`}
        onClick={() => this.props.onEditClick(data)}
        style={{
          minHeight: "250px",
          borderRadius: "10px",
        }}
      >
        <div className="bg-primary bg-soft" onClick={() => {}}>
          <Row>
            {image ? (
              <div id={"play" + data.page_uuid} style={{ height: "15rem" }}>
                <CardImg
                  top
                  className="img-fluid"
                  src={image}
                  alt="Skote"
                  style={{
                    height: "15rem",
                    cursor: "pointer",
                    opacity: "0.8",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                />
              </div>
            ) : (
              <div
                className="py-5 px-0"
                style={{
                  height: "15rem",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <i
                  className={`fas ${getImage(data)} fa-7x py-4 text-secondary`}
                />
              </div>
            )}
          </Row>
        </div>
        <CardBody style={{ padding: "1rem 0.25rem" }}>
          <Tooltip title={title} style={{ minHeight: "20px" }}>
            <div className="font-size-16 text-truncate font-weight-semibold">
              <i className={`mx-1`} /> {title}
            </div>
          </Tooltip>

          <div style={{ minHeight: "30px" }}>
            <Badge
              className={`${"badge-soft-danger"} ms-1 font-size-13 my-2 text-black`}
            >
              {data.elementType || data.element_type}
            </Badge>
          </div>
          {data.collectionType &&
            ["MUSIC_LABEL", "MUSIC_PLAYLIST", "MUSIC_ARTIST"].includes(
              data.collectionType
            ) && (
              <div style={{ minHeight: "30px" }}>
                <Badge
                  className={`${"badge-soft-info"} ms-1 font-size-13 my-2 text-black`}
                >
                  {data.status}
                </Badge>
                {data.music_count && (
                  <Badge
                    className={`${"badge-soft-info"} ms-1 font-size-13 my-2 text-black`}
                  >
                    Music Count: {data.music_count}
                  </Badge>
                )}
              </div>
            )}
          {/* <div style={{ minHeight: "30px" }}>
              <Badge className="badge-soft-dark ms-1 font-size-13 text-black">
                <ReactTimeAgo date={data.created_date} locale="en-US" />
              </Badge>
            </div> */}
          <div>
            {map(
              data.permissions,
              (tag, index) =>
                index < 2 && (
                  <Link
                    to="#"
                    className="badge bg-primary font-size-11 m-1"
                    key={"_skill_" + data.id + index}
                  >
                    {tag}
                  </Link>
                )
            )}
          </div>
        </CardBody>
        <div
          className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
          onClick={e => e.stopPropagation()}
        >
          <div className="flex-fill">
            <CopyToClipboard
              text={data.collectionId}
              onCopy={e => {
                toastr.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy Collection ID">
                <i className="bx bx-copy" role="button" />
              </Tooltip>
            </CopyToClipboard>
          </div>
          {data.cta && (
            <div className="flex-fill">
              <CopyToClipboard
                text={data.cta}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy CTA">
                  <i className="bx bx-copy-alt" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
          )}

          <div className="flex-fill">
            <Tooltip title="Edit Collection Entity">
              <i
                className="bx bx-edit-alt"
                role="button"
                onClick={() => this.props.onEditClick(data)}
              />
            </Tooltip>
          </div>
        </div>
      </Card>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    refetch: _.get(store, "zeroSearch.refetchData"),
  }
}

export default connect(mapStateToProps)(CollectionEntityCard)
