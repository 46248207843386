import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { taxonomyRoutes } from "./TaxonomyRoutes"

class TaxonomyQuestions extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedSection: "",
    }
  }

  _renderCardContent = data => {
    const videSource = data.help && data.help.video ? data.help.video : null
    const imgSource = data.help && data.help.image ? data.help.image : null
    return (
      <div className="card" style={{ width: "20rem" }}>
        {videSource && (
          <video
            src={videSource}
            controls
            autoPlay
            controlsList={"nodownload"}
            loop
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        )}
        {imgSource && (
          <img src={imgSource} alt="Card image cap" className="card-img-top" />
        )}
        <div className="card-body">
          <h5 className="card-title">{data.label}</h5>
          {data.help && data.help.description && (
            <p className="card-text">{data.help.description}</p>
          )}
        </div>
      </div>
    )
  }

  sectionSwitch(taxonomyRoutes, param) {
    const filterdRoute = taxonomyRoutes.filter(item => item.key == param)
    if (filterdRoute.length <= 0) return
    const SectionComponent = filterdRoute[0].component
    return (
      <SectionComponent
        renderCardContent={this._renderCardContent}
        {...this.props}
      ></SectionComponent>
    )
  }
  render() {
    return (
      <div>
        {this.sectionSwitch(taxonomyRoutes, this.props.selectedSection)}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    eventList: _.get(store, "content.eventList"),
    eventsloading: _.get(store, "content.eventsloading"),
  }
}

export default connect(mapStateToProps)(TaxonomyQuestions)
