import React from "react"
import { Upload, message } from "antd"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm, {
  Select,
  TextField,
  TextArea,
  ChipsInput,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { collectionElements } from "../../constants/uiConstants"

const TYPE_TO_KEY_MAP = {
  BANNER: "banner_uuid",
  MUSIC_ARTIST: "music_artist_uuid",
  MUSIC_LABEL: "music_label_uuid",
  MUSIC_PLAYLIST: "music_playlist_uuid",
  NAMED_COLLECTION: "collection_uuid",
}

const TYPE_TO_FORM_KEY_MAP = {
  BANNER: "banner_uuid",
  MUSIC_ARTIST: "entity_uuid",
  MUSIC_LABEL: "entity_uuid",
  MUSIC_PLAYLIST: "entity_uuid",
  NAMED_COLLECTION: "collection_uuid",
}

export default class UpsertGameForm extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      fileList: [],
      formElementType: this.props.data && this.props.data.element_type,
    }
  }

  _onRemoveFile = type => {
    switch (type) {
      case "animatedFile":
        this.setState({
          animatedFileList: [],
          animatedFile: null,
        })
      case "thumbnail":
        this.setState({
          staticImageList: [],
          staticImage: null,
        })
      default:
        break
    }
    // if (type === "thumbnail") {
    //   this.setState({
    //     staticImageList: [],
    //     staticImage: null,
    //   })
    // } else
    //   this.setState({
    //     imageList: [],
    //     image: null,
    //   })
  }

  _uploadFile = (fileObj, type) => {
    if (fileObj.file) {
      switch (type) {
        case "animatedFile":
          this.setState({
            animatedFileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            animatedFile: fileObj.file,
          })
          break
        case "thumbnail":
          this.setState({
            staticImageList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            staticImage: fileObj.file,
          })
          break
      }
    }
  }

  _uploadImage = (fileObj, type) => {
    if (fileObj.file) {
      if (type === "thumbnail") {
        this.setState({
          staticImageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          staticImage: fileObj.file,
        })
      } else
        this.setState({
          imageList: [
            {
              uid: "-1",
              name: fileObj.file.name,
              status: "done",
              url: "",
            },
          ],
          image: fileObj.file,
        })
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>{`${
            this.props.action === "create" ? "Add" : "Edit"
          } Collection Entity`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm={12} md={6}>
                <TextField
                  label={"Title"}
                  placeholderLabel="Title"
                  field={"title"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.title
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Subtitle"}
                  placeholderLabel="Subtitle"
                  field={"subtitle"}
                  className="creator-form"
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.subtitle
                  }
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Inline CTA Text"}
                  placeholderLabel="Inline Cta Text"
                  field={"inlineCtaText"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data &&
                        this.props.data.cta_data &&
                        this.props.data.cta_data.inline_cta_text
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm={12} md={6}>
                <TextField
                  label={"Inline CTA"}
                  placeholderLabel="Inline Cta"
                  field={"inlineCta"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data &&
                        this.props.data.cta_data &&
                        this.props.data.cta_data.inline_cta
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm={12} md={6}>
                <TextArea
                  label={"Description"}
                  placeholderLabel="Description"
                  field={"description"}
                  defaultValue={
                    this.props.action === "create"
                      ? ""
                      : this.props.data && this.props.data.description
                  }
                  className="creator-form"
                />
              </Col>
              <Col sm={12} md={6}>
                <ChipsInput
                  label={"Tagged Videos"}
                  placeholderLabel="Tagged Videos"
                  field={"taggedVideos"}
                  defaultValue={
                    this.props.action === "create"
                      ? []
                      : this.props.data && this.props.data.tagged_videos
                  }
                  className="creator-form"
                />
              </Col>
              <div>
                <Col sm="12" md="6">
                  <Row style={{ marginTop: "10px" }}>
                    <Upload
                      showUploadList={true}
                      onRemove={() => this._onRemoveFile("thumbnail")}
                      defaultFileList={this.state.staticImageList}
                      fileList={this.state.staticImageList}
                      customRequest={fileObj =>
                        this._uploadFile(fileObj, "thumbnail")
                      }
                    >
                      <Button color="primary" onClick={() => {}}>
                        Upload Thumbnail
                      </Button>
                    </Upload>
                  </Row>
                </Col>
                <Col sm={6} lg={3} style={{ marginTop: "1rem" }}>
                  <Upload
                    showUploadList={true}
                    listType="picture"
                    // previewFile={true}
                    onRemove={() => this._onRemoveFile("animatedFile")}
                    defaultFileList={this.state.animatedFileList}
                    fileList={this.state.animatedFileList}
                    customRequest={fileObj =>
                      this._uploadFile(fileObj, "animatedFile")
                    }
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    {this.state.animatedFileList &&
                    this.state.animatedFileList.length >= 1 ? null : (
                      <Button color="primary" onClick={() => {}}>
                        {this.props.formAction !== "edit"
                          ? `Upload Animated File`
                          : `Update Animated File`}{" "}
                      </Button>
                    )}
                  </Upload>
                </Col>
              </div>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <div className="flex" style={{ marginTop: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <Button htmlType="submit" color="primary">
                      {this.props.action === "update" ? "Update" : "Create"}
                    </Button>
                    <Button
                      onClick={() => this.props.onCancel()}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data
    data = new FormData()
    let modifiedData = formData
    Object.keys(modifiedData).forEach(key => {
      if (modifiedData[key] !== undefined && modifiedData[key] !== "") {
        data.append(key, modifiedData[key])
      }
    })
    if (!_.isEmpty(this.state.staticImage)) {
      data.append("file1", this.state.staticImage)
    }
    if (!_.isEmpty(this.state.animatedFile)) {
      data.append("animatedUrl", this.state.animatedFile)
    }
    this.props.onSubmit(data)
  }

  _render = () => {
    return this._form()
  }
}
