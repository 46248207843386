import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestCommentListByFilters,
  requestCommentsGroupByUser,
  requestBulkDeActivateComments,
} from "appRedux/sagas/requests/comment"
import {
  getCommentByFilter,
  getCommentByFilterSuccess,
  getCommentsGroupByUser,
  getCommentsGroupByUserSuccess,
  bulkDeActivateComments,
  bulkDeActivateCommentsSuccess,
} from "appRedux/slices/comment"
import toastr from "toastr"
import { fetchError } from "appRedux/slices/common"

function* handleGetChallengeListByFilters(params) {
  try {
    const data = yield call(requestCommentListByFilters, params)
    yield put(getCommentByFilterSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetCommentsGroupByUser(params) {
  try {
    const data = yield call(requestCommentsGroupByUser, params)
    yield put(getCommentsGroupByUserSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleBulkDeActivateComments(params) {
  try {
    toastr.info("Action in progress !!")
    const data = yield call(requestBulkDeActivateComments, params)
    yield put(bulkDeActivateCommentsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getCommentByFilter.type, handleGetChallengeListByFilters)
  yield takeLatest(getCommentsGroupByUser.type, handleGetCommentsGroupByUser)
  yield takeLatest(bulkDeActivateComments.type, handleBulkDeActivateComments)
}
