import React, { useState } from "react"
import { Input, Modal, Radio, Row, Col, Button } from "antd"
import {
  competition_logos,
  banned_organizations,
  LANGUAGES,
} from "../../constants/uiConstants"
import ReactSelect from "react-select"
const { Group } = Radio
const formatOptionLabel = ({ value, label, logoSrc }) => {
  return (
    <span>
      {logoSrc ? (
        <img
          src={logoSrc}
          alt={label}
          style={{ hight: "25px", width: "25px" }}
        />
      ) : (
        <i className="fa fa-users" aria-hidden="true"></i>
      )}
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      <span className="font-size-12">
        <b>{label.toString()}</b>
      </span>
    </span>
  )
}
const formatOptionLabelBO = ({ value, label, logoSrc }) => {
  return (
    <span className="font-size-12">
      <b>{label.toString()}</b>
    </span>
  )
}
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
  }),

  singleValue: (provided, state) => {
    return { ...provided, fontSize: 10 }
  },
}
const SkipModel = props => {
  const { currentUser } = props
  const [skipVal, setSkipVal] = useState("")
  const [comptetiorLogo, setComptetiorLogo] = useState("")
  const [bannedOrganization, setBannedOrganization] = useState("")
  const isQCUser =
    (currentUser.permissions &&
      currentUser.permissions.includes("QC_TAG_CONTENT")) ||
    (currentUser.permissions &&
      currentUser.permissions.includes("INTERNAL_QC_TAG_CONTENT"))
  return (
    <Modal
      centered
      className="container"
      visible={true}
      onCancel={() => props.onCancel()}
      footer={null}
      bodyStyle={{ padding: "2rem 1rem", overflow: "auto" }}
      width="50vw"
    >
      {/* {!isQCUser && (
        <Row>
          <div className="d-flex flex-wrap">
            <Col span={24}>
              <span className="hashtaglabels">Language</span>
            </Col>
            <Group
              style={{ display: "contents" }}
              onChange={e => props.skipContentHanlder(e, "language")}
              value={props.selectedLanguage}
            >
              {LANGUAGES.map(language => (
                <div className="topicRadioBtn">
                  <Radio value={language.value}>{language.label}</Radio>
                </div>
              ))}
              <div className="topicRadioBtn">
                <Radio value="unknown">Not Sure</Radio>
              </div>
            </Group>
          </div>
        </Row>
      )} */}
      <Row>
        <div className="d-flex flex-wrap" style={{ width: "100%" }}>
          <Col span={24}>
            <span className="hashtaglabels">Reject</span>
          </Col>
          <Col span={24} style={{ display: "flex" }} className="flex-wrap">
            <Group
              style={{ display: "contents" }}
              onChange={e => {
                setSkipVal(e.target.value)
                props.skipContentHanlder(e, "rejection")
              }}
              value={props.selectedRejectReason}
            >
              <div className="topicRadioBtn">
                <Radio value="accident">Accident</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="animal_cruelty">Animal Cruelty</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="anti_national">Anti National</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="anti_religion">Anti Religion</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="banned_organization">Banned Organization</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="blurred">Blurred</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="blood">Blood</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="child_nudity">Child Nudity</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="competitor_logo">Competitor Logo</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="cyberbullying_&_harassment">
                  {" "}
                  Cyberbullying &amp; Harassment{" "}
                </Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="death">Death</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="extreme_violence">Extreme Violence</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="identity_theft_&_impersonation">
                  Identity Theft &amp; Impersonation
                </Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="illegal_display_of_weapons">
                  Illegal Display Of Weapons
                </Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="josh_logo">Josh Logo</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="misinformation_&_fake_news">
                  Misinformation &amp; Fake News{" "}
                </Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="mob_violence">Mob Violence</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="porn">Porn</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="sexual_molestation_ugc">
                  Sexual Molestation UGC
                </Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="sexual_molestation_ott_movie">
                  Sexual Molestation OTT/Movie
                </Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="small_duration">Small Duration</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="suicide">Suicide</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="text_profanity">Text Profanity</Radio>
              </div>
              <div className="topicRadioBtn">
                <Radio value="misc">Other Reason(Misc)</Radio>
              </div>
            </Group>
          </Col>
          <Col span={24}>
            {skipVal == "competitor_logo" && (
              <div className="topicRadioBtn">
                <Row>
                  <Col
                    span={props.competitorLogoName === "others" ? 6 : 24}
                    style={{ marginRight: "5px" }}
                  >
                    <ReactSelect
                      key={comptetiorLogo}
                      styles={customStyles}
                      menuPlacement="top"
                      value={
                        competition_logos.filter(
                          logo => logo?.value === props.competitorLogoName
                        )[0] || ""
                      }
                      maxMenuHeight={150}
                      fontSize="50"
                      // value={props.competitorLogoName}
                      style={{ width: "100%" }}
                      isMulti={false}
                      options={competition_logos}
                      formatOptionLabel={formatOptionLabel}
                      onChange={value => {
                        props.skipContentHanlder(value.value, "comptetiorLogo")
                      }}
                      placeholder="Select Logo..."
                    />
                  </Col>
                  <Col className="flex-grow-1">
                    {props.competitorLogoName === "others" ? (
                      <Input
                        placeholder={"Enter Logo..."}
                        value={props.competitorLogoNameText || ""}
                        className="h-100"
                        allowClear={true}
                        name="logo"
                        required
                        style={{ width: "100%" }}
                        onChange={e => {
                          props.skipContentHanlder(e, "comptetiorLogoName")
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
              </div>
            )}
            {skipVal == "banned_organization" && (
              <div className="topicRadioBtn">
                <Row>
                  <Col span={24} style={{ marginRight: "5px" }}>
                    <ReactSelect
                      key={bannedOrganization}
                      styles={customStyles}
                      menuPlacement="top"
                      value={
                        banned_organizations.filter(
                          organization =>
                            organization?.value === props.bannedOrganizationName
                        )[0] || ""
                      }
                      maxMenuHeight={150}
                      fontSize="50"
                      // value={props.competitorLogoName}
                      style={{ width: "100%" }}
                      isMulti={false}
                      options={banned_organizations}
                      formatOptionLabel={formatOptionLabelBO}
                      onChange={value => {
                        props.skipContentHanlder(
                          value.value,
                          "bannedOrganization"
                        )
                      }}
                      placeholder="Select Organization..."
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </div>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            style={{
              color: "blue",
              borderColor: "blue",
              marginTop: 10,
              marginBottom: 10,
              float: "right",
            }}
            disabled={
              (skipVal == "competitor_logo" && !props.competitorLogoName) ||
              (skipVal == "competitor_logo" &&
                props.competitorLogoName === "others" &&
                !props.competitorLogoNameText) ||
              (skipVal == "banned_organization" &&
                !props.bannedOrganizationName)
            }
            type="dashed"
            onClick={() => props.skipContent(props.content, props.reset)}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default SkipModel
