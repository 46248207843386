import PropTypes, { func } from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import EmbedContainer from "react-oembed-container"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Collapse,
  Button,
  Table,
  Badge,
  Row,
  CardTitle,
  CardImg,
  CardText,
} from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import images from "assets/images"
import { isEmpty, size, map, indexOf } from "lodash"
import { Tooltip, Tag } from "antd"
import { DivOverlay } from "react-leaflet"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import CustomScrollbars from "util/CustomScrollbars"
import ReactCardFlip from "react-card-flip"
import { formatNumber } from "../../util/common"
import ReactTimeAgo from "react-time-ago"
import {
  TwitterEmbed,
  FacebookEmbed,
  InstagramEmbed,
  YouTubeEmbed,
} from "react-social-media-embed"
import "./style.css"

class SocialCard extends React.Component {
  state = { isPlaying: false, isFlipped: false, isPlayedFirst: true }
  audioRef = React.createRef()

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  _renderEmbeds = () => {
    const {
      embed_type: embedType = "",
      url,
      blockquote_text,
    } = this.props.social
    if (blockquote_text) {
      return (
        <EmbedContainer className="article-content" markup={blockquote_text}>
          <article>
            <div dangerouslySetInnerHTML={{ __html: blockquote_text }} />
          </article>
        </EmbedContainer>
      )
    } else {
      return <i className="fas fa-solid fa-icons fa-7x py-4 text-secondary" />
    }
  }

  _getModerationStatus = moderationLevel => {
    switch (moderationLevel) {
      case 1:
        return "Unmoderated"
        break
      case 2:
        return "Human Approved"
        break
      case 3:
        return "Auto Approved"
        break
      case 0:
        return "Auto Disabled"
        break
      case -1:
        return "Human Disabled"
        break
      default:
        return "-"
    }
  }

  render() {
    const { social, customClassname = "" } = this.props
    const enableEdit =
      this.props.currentUser?.permissions?.includes("EDIT_MANAGE_AUDIO")

    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection={"horizontal"}
      >
        <Card
          className={`${customClassname} text-center shadow-lg`}
          style={{
            minHeight: "180px",
            borderRadius: "10px",
            width: "256px",
          }}
        >
          <div
            className="bg-primary bg-soft"
            onClick={() => {
              !enableEdit ? "" : this.props.onEditClick(social)
            }}
            type={!enableEdit ? "" : "button"}
          >
            <Row>
              <Col className="align-self-end" style={{ width: "100%" }}>
                <div
                  className=""
                  style={{
                    cursor: "pointer",
                    backgroundColor: "lightgray",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    height: "455px",
                    width: "256px",
                    overflowY: "hidden",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {this._renderEmbeds()}
                  </div>
                </div>
                {/* {social.blockquote_text ? (
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: social.blockquote_text,
                      }}
                    ></div>
                  ) : (
                    <i className="fas fa-solid fa-icons fa-7x py-4 text-secondary" />
                  )} */}

                <div
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <img
                    src={
                      (social &&
                        social.user_profile &&
                        social.user_profile.profile_pic) ||
                      "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
                    }
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                </div>

                <div className="hashtag-lang-block">
                  {social.tags_array.map(tag => (
                    <div className="hashtag-lang" title="HashTag">
                      {"#" + tag}
                    </div>
                  ))}
                  <div
                    className="hashtag-lang"
                    style={{ marginLeft: "5px" }}
                    title="Lang Code"
                  >
                    {social.lang_code ? social.lang_code.toUpperCase() : ""}
                  </div>
                </div>

                <div className="embed-block">
                  {social.embed_type && social.embed_type && (
                    <div title="Embed Type">{social.embed_type}</div>
                  )}
                </div>
                <div className="text-block">
                  <ReactTimeAgo
                    date={
                      Number.isNaN(social.created_date)
                        ? Date.parse(social.created_date)
                        : social.created_date
                    }
                    locale="en-US"
                  />
                </div>

                <div className="image-text">
                  {" "}
                  {social?.content_title || "<No Title>"}
                </div>
              </Col>
            </Row>
          </div>
          <CardBody style={{ padding: ".5rem" }}>
            <div className="d-flex">
              {social &&
                (social.moderation_level || social.moderation_level === 0) && (
                  <Tooltip title="Moderation Status">
                    <Tag
                      color={
                        social.moderation_level === 1 ||
                        social.moderation_level === 3 ||
                        social.moderation_level === 0
                          ? "red"
                          : social.moderation_level === 2
                          ? "#87d068"
                          : "#108ee9"
                      }
                      style={{
                        color:
                          social.moderation_level === -1 ||
                          social.moderation_level === -2
                            ? "white"
                            : "black",
                      }}
                    >
                      {this._getModerationStatus(social.moderation_level)}
                    </Tag>
                  </Tooltip>
                )}
              <Tooltip title="Status">
                <Tag>
                  {social?.is_active?.toString() == "true"
                    ? "Active"
                    : "Inactive"}
                </Tag>
              </Tooltip>
              {social.taxonomy_meta && (
                <Tooltip title="Tagged">
                  <Tag color="red">Tagged</Tag>
                </Tooltip>
              )}
            </div>
            <div className="d-flex justify-content-around align-items-center pt-2">
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  !enableEdit ? "" : this.props.onEditClick(social)
                }}
                type={!enableEdit ? "" : "button"}
              >
                <i
                  className="bx bx-show"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {" "}
                  {formatNumber(social.view_count + social.fk_views) || 0}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  !enableEdit ? "" : this.props.onEditClick(social)
                }}
                type={!enableEdit ? "" : "button"}
              >
                <i
                  className="bx bx-like"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {" "}
                  {formatNumber(social.like_count + social.fk_likes) || 0}
                </span>
              </div>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  !enableEdit ? "" : this.props.onEditClick(social)
                }}
                type={!enableEdit ? "" : "button"}
              >
                <i
                  className="bx bx-share-alt"
                  style={{ padding: "2px", fontSize: "16px" }}
                />
                <span>
                  {formatNumber(social.share_count + social.fk_shares) || 0}
                </span>
              </div>
            </div>
          </CardBody>
          {/* <CardBody style={{ padding: "1rem 0.25rem" }}>
            <Tooltip title={social.title}>
              <div className="font-size-16 text-truncate font-weight-semibold">
                {social.content_title}
              </div>
            </Tooltip>
            <div className="">
              {social.target_languages && social.target_languages.length > 0 ? (
                <Tooltip title="Target Languages">
                  {map(
                    social.target_languages,
                    (language, index) =>
                      index < 2 && (
                        <Badge className="badge-soft-info ms-1">
                          <div className="font-size-14">
                            {language.toUpperCase()}
                          </div>
                        </Badge>
                      )
                  )}
                  {size(social.target_languages) > 2 && (
                    <Badge className="badge-soft-success ms-1">
                      <Tooltip
                        placement="right"
                        title={social.target_languages
                          .slice(1, social.target_languages.length)
                          .map(a => (
                            <div>{a}</div>
                          ))}
                      >
                        <div className="font-size-14 p-1">
                          +{size(social.target_languages) - 2} more
                        </div>
                      </Tooltip>
                    </Badge>
                  )}
                </Tooltip>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
            <div className=" my-2" style={{ height: "20px" }}>
              {social.type && (
                <Tooltip title="Type">
                  <Badge className="badge-soft-primary ms-1 font-size-13">
                    {social.type}
                  </Badge>
                </Tooltip>
              )}
              {social.created_date && (
                <Tooltip title="Created">
                  <Badge className="badge-soft-info ms-1 font-size-13">
                    <ReactTimeAgo
                      date={
                        Number.isNaN(social.created_date)
                          ? Date.parse(social.created_date)
                          : social.created_date
                      }
                      locale="en-US"
                    />
                  </Badge>
                </Tooltip>
              )}

              <Badge className="badge-soft-primary ms-1 font-size-13">
                {social.is_active ? "ACTIVE" : "INACTIVE"}
              </Badge>
            </div>
          </CardBody> */}
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={social.content_uuid}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Social ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <Tooltip title="Edit Social">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_MANAGE_AUDIO"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick(social)
                  }}
                />
              </Tooltip>
            </div>

            <div className="flex-fill">
              <Tooltip title="Edit In New Window">
                <Link
                  to={
                    enableEdit
                      ? `/social/manage?socialUuid=${social.content_uuid}`
                      : ""
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bx bx-link-external" role="button" />
                </Link>
              </Tooltip>
            </div>

            <div className="flex-fill">
              <Tooltip title="View More">
                <i
                  className="bx bx-play"
                  role="button"
                  style={{ fontSize: "20px" }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ isFlipped: true })
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Card>
        <Card
          className={`${customClassname} text-center shadow-lg`}
          style={{
            height: "567px",
            borderRadius: "10px",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <CustomScrollbars>
            <CardBody className="px-0 py-0">
              <Row className="my-sm-2">
                <Col className="ms-2">
                  <div className="avatar-xs">
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-warning" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="bx bx-bar-chart" />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end me-2">
                  <div
                    className="avatar-xs"
                    role="button"
                    onClick={() => this.setState({ isFlipped: false })}
                  >
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-dark" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="fas fa-times-circle" />
                      </span>
                    </div>
                  </div>
                  <div></div>
                </Col>
              </Row>
              <Row>
                <div className="table-responsive">
                  <Table className="mb-0 table-striped">
                    <tbody>
                      <tr className="text-start">
                        <th scope="row">URL:</th>
                        <td className="break-word">{social.url || "-"}</td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Moderation Level:</th>
                        <td className="break-word">
                          {social.moderation_level || "-"}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Embed Type:</th>
                        <td>
                          {(social.embed_type &&
                          typeof social.embed_type == "array"
                            ? social.embed_type.map((socials, index) => (
                                <span>
                                  {socials}
                                  {social.embed_type.length > index + 1
                                    ? ", "
                                    : ""}
                                </span>
                              ))
                            : social.embed_type) || "-"}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Format:</th>
                        <td>{social.format || "-"}</td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Tags Array:</th>
                        <td>
                          {(social.tags_array &&
                            social.tags_array.map((socials, index) => (
                              <span>
                                {socials}
                                {social.tags_array.length > index + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))) ||
                            "-"}
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Input Zones:</th>
                        <td>
                          {(social.input_zones &&
                            social.input_zones.map((socials, index) => (
                              <span>
                                {socials}
                                {social.input_zones.length > index + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))) ||
                            "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </CardBody>
          </CustomScrollbars>
        </Card>
      </ReactCardFlip>
    )
  }
}

export default SocialCard
