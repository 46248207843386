import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { Tabs } from "antd"
import { MdAdd } from "react-icons/md"
import { getSeriesData } from "clientServices/audioStories"
import StoriesCard from "./storiesCard"
import AudioSeriesForm from "./audioSeriesForm"
import { sleep } from "util/common"

class AudioSeasonList extends ErrorBoundComponent {
  state = {
    showSeriesForm: false,
    storiesData: [],
    currentSeries: undefined,
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      let storiesData = await getSeriesData()
      this.setState({
        storiesData,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          <>
            <Row>
              <Col lg="12" md="12">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(256px, 1fr))",
                    gridGap: "2rem",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  {this.state.storiesData.map(item => {
                    return (
                      <StoriesCard
                        audio={item}
                        onEditClick={this.onEditClick}
                      ></StoriesCard>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </>
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(AudioSeasonList)
