import React from "react"
import { Button } from "antd"
import { Card, CardBody, Row, Col } from "reactstrap"
import _ from "lodash"
import ValidatorForm, { ChipsInput } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"

export default class UpsertForm extends ErrorBoundComponent {
  _form = () => {
    return (
      <Card>
        <CardBody>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm="12" md="6" style={{ marginBottom: "10px" }}>
                <ChipsInput
                  label={`${
                    this.props.formAction === "user" ? "Users" : "Contents"
                  } to be added`}
                  placeholderLabel={
                    this.props.formAction === "user"
                      ? "Add users"
                      : "Add contents"
                  }
                  field="add"
                  className="creator-form"
                />
              </Col>
              <Col sm="12" md="6">
                <ChipsInput
                  label={`${
                    this.props.formAction === "user"
                      ? "Whietlisted users to be removed"
                      : "Masthead contents to be ignored"
                  }`}
                  placeholderLabel={
                    this.props.formAction === "user"
                      ? "Remove users"
                      : "Remove contents"
                  }
                  field="remove"
                  className="creator-form"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="flex" style={{ marginTop: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" htmlType="submit">
                      Submit
                    </button>
                    <button
                      className="btn btn-danger mx-2"
                      onClick={() => this.props.onCancel()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    this.props.onSubmit(_.deepClean(formData))
  }

  _render = () => {
    return this._form()
  }
}
