import React from "react"
import { connect } from "react-redux"
import { Modal, Card, Divider, Radio } from "antd"
import { Button, Row, Col } from "reactstrap"
import UpdateIconForm from "./updateIconForm"
import {
  getAllThemes,
  updateIcon,
  getIconIds,
  resetState,
} from "../../appRedux/slices/icons"
import { EditOutlined } from "@ant-design/icons"
import QueryString from "query-string"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import "./icons.style.css"
const { Meta } = Card

class ManageIcons extends ErrorBoundComponent {
  state = {
    showUpsertModal: false,
    themeAsset: {},
    filters: {
      selectedFilter: this.props.queryParams
        ? this.props.queryParams["selectedFilter"]
        : "android",
    },
    selectedFilter: "android",
  }

  componentDidMount() {
    if (!this.props.common.error) {
      const { filters } = this.state
      this.props.dispatch(
        getAllThemes({ selectedFilter: filters["selectedFilter"] })
      )
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.refetchData &&
      !this.props.common.error &&
      prevProps.refetchData !== this.props.refetchData
    ) {
      this._closeUpsertModal()
      this.props.dispatch(
        getAllThemes({ selectedFilter: this.state.selectedFilter })
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _closeUpsertModal = () => {
    this.setState({
      showUpsertModal: false,
      themeAsset: {},
      theme: {},
      themeId: "",
      tabId: "",
    })
  }

  _onEditTabAsset = (theme, themeAsset) => {
    if (theme.themeId === 2) {
      this.props.dispatch(getIconIds())
    }
    this.setState({
      showUpsertModal: true,
      formAction: "update",
      themeAsset: themeAsset,
      themeId: theme.themeId,
      tabId: !_.isEmpty(themeAsset) ? themeAsset.tabId : "",
      theme: theme,
    })
  }

  _onSubmit = formData => {
    if (!_.isEmpty(this.state.tabId)) {
      formData["tabId"] = this.state.tabId ? this.state.tabId : ""
    }
    const data = {
      formData,
      query: {
        action: this.state.formAction,
        themeId: this.state.themeId,
      },
    }
    this.props.dispatch(updateIcon(data))
  }

  _getModalContent = () => {
    return (
      <UpdateIconForm
        formAction={this.state.formAction}
        themeAsset={this.state.themeAsset}
        onSubmit={this._onSubmit}
        themeId={this.state.themeId}
        theme={this.state.theme}
        selectedFilter={this.state.filters.selectedFilter}
        onCancel={this._closeUpsertModal}
      />
    )
  }

  _modal = () => {
    return <Card title="Edit Icons">{this._getModalContent()}</Card>
  }

  _handleFieldChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
    if (type === "selectedFilter") {
      this.props.dispatch(getAllThemes({ selectedFilter: value }))
      this.setState({
        selectedFilter: value,
      })
    }
  }

  _getColor = tabType => {
    switch (tabType) {
      case 1:
        return "mediumorchid"

      case 2:
        return "hotpink"

      case 3:
        return "dodgerblue"
      default:
        return ""
    }
  }

  _getCover = themeId => {
    switch (themeId) {
      case 1:
        return "fa fa-wrench"

      case 2:
        return "fa fa-image"

      case 3:
        return "fa fa-expand"

      default:
        return "fa fa-binoculars"
    }
  }

  _navMenu = () => {
    const { themeData = [] } = this.props
    return (
      <div style={{ marginTop: "35px" }}>
        {themeData &&
          themeData.length > 0 &&
          themeData.map(theme => (
            <div>
              <div>
                <Row
                  gutter={24}
                  style={{
                    margin: "0px 0px 10px 0px",
                    cursor:
                      (theme.tabData && theme.tabData.length > 0) ||
                      theme.iconId ||
                      theme.iconUrl
                        ? ""
                        : "pointer",
                  }}
                  onClick={
                    (theme.tabData && theme.tabData.length > 0) ||
                    theme.iconId ||
                    theme.iconUrl
                      ? ""
                      : () => this._onEditTabAsset(theme)
                  }
                >
                  <Divider
                    className={
                      (theme.tabData && theme.tabData.length > 0) ||
                      theme.iconId ||
                      theme.iconUrl
                        ? "theme"
                        : "themeClick"
                    }
                    style={{ marginLeft: "-88px", fontSize: "20px" }}
                    orientation="left"
                  >
                    <span>
                      <i
                        className={this._getCover(theme.themeId)}
                        style={{
                          fontSize: "25px",
                          width: "25px",
                          marginRight: "10px",
                          color: this._getColor(theme.themeId),
                        }}
                      ></i>
                    </span>
                    {theme.themeName}
                  </Divider>
                </Row>
                {theme.themeId === 1 && (
                  <Row className="actions-buttons">
                    <Col className="select" span={4}>
                      <Radio.Group
                        onChange={e =>
                          this._handleFieldChange(
                            e.target.value,
                            "selectedFilter"
                          )
                        }
                        defaultValue={"android"}
                        value={this.state.filters.selectedFilter}
                      >
                        <Radio value={"android"}>ANDROID</Radio>
                        <Radio value={"ios"}>IOS</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                )}
                <Row gutter={24}>
                  {theme.tabData &&
                    theme.tabData.length > 0 &&
                    theme.tabData.map(tab => (
                      <Col lg="2" sm="6" md="4">
                        <Card
                          className="tabData"
                          cover={
                            <div className="cardTab">
                              <img
                                alt="Icon"
                                height="110px"
                                style={{ maxWidth: "100px" }}
                                src={tab.active_state}
                              />
                              <img
                                alt="Icon"
                                style={{ maxWidth: "100px" }}
                                height="110px"
                                src={tab.non_active_state}
                              />
                            </div>
                          }
                          bordered={true}
                          style={{
                            padding: "12px",
                            marginBottom: "12px",
                            boxShadow: "1px 4px 1px #AEAEAE",
                          }}
                          actions={[
                            <EditOutlined
                              key="edit"
                              onClick={() => this._onEditTabAsset(theme, tab)}
                            />,
                          ]}
                        >
                          <Meta title={tab.tabName} />
                        </Card>
                      </Col>
                    ))}
                </Row>
                {theme && theme.iconId && (
                  <Row gutter={24}>
                    <Col sm={24} md={4} lg={4} xl={4}>
                      <Card
                        className="tabData"
                        cover={
                          <div className="cardTab">
                            <img
                              alt="App icon"
                              height="110px"
                              src={theme.appIconIdUrl}
                            />
                          </div>
                        }
                        bordered={true}
                        style={{
                          padding: "12px",
                          marginBottom: "12px",
                          boxShadow: "1px 4px 1px #AEAEAE",
                        }}
                        actions={[
                          <EditOutlined
                            key="edit"
                            onClick={() => this._onEditTabAsset(theme)}
                          />,
                        ]}
                      >
                        <Meta title={theme.iconId} />
                      </Card>
                    </Col>
                  </Row>
                )}
                {theme && theme.iconUrl && (
                  <Row gutter={24}>
                    <Col sm={24} md={4} lg={4} xl={4}>
                      <Card
                        className="tabData"
                        cover={
                          <div className="cardTab">
                            <img
                              alt="sticker"
                              height="110px"
                              src={theme.iconUrl}
                            />
                          </div>
                        }
                        bordered={true}
                        style={{
                          padding: "12px",
                          marginBottom: "12px",
                          boxShadow: "1px 4px 1px #AEAEAE",
                        }}
                        actions={[
                          <EditOutlined
                            key="edit"
                            onClick={() => this._onEditTabAsset(theme)}
                          />,
                        ]}
                      >
                        <Meta title={theme.themeName} />
                      </Card>
                    </Col>
                  </Row>
                )}
              </div>
              {/* <Divider /> */}
            </div>
          ))}
      </div>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this.state.showUpsertModal && this._modal()}
        {!this.state.showUpsertModal && this._navMenu()}
      </Auxiliary>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    refetchData: _.get(store, "icons.refetchData"),
    loading: _.get(store, "icons.loading"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data.userType"),
    themeData: _.get(store, "icons.themeData"),
  }
}

export default connect(mapStateToProps)(ManageIcons)
