import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Switch, Upload } from "antd"
import toastr from "toastr"
import _ from "lodash"
import { Card, CardBody, Button, Row, Col, CardTitle } from "reactstrap"
import ValidatorForm from "../ValidatorForm"
import { v4 as uuidv4 } from "uuid"

export default class ZonePreviewImageForm extends ErrorBoundComponent {
  _onImageRemove = fileObj => {
    const preview_image_list = this.state.preview_image_list || []
    const previewImage = this.state.previewImage || []
    const fileNameList = preview_image_list.map(fileData => fileData.name) || []
    const idx = fileNameList.indexOf(fileObj.name)
    preview_image_list.splice(idx, 1)
    previewImage.splice(idx, 1)
    this.setState({
      preview_image_list,
      previewImage,
    })
  }

  _uploadImage = fileObj => {
    if (fileObj.file) {
      const id = uuidv4()
      this.setState(prevState => ({
        preview_image_list: [
          ...(prevState.preview_image_list || []),
          {
            uid: id,
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        previewImage: [
          ...(prevState.previewImage || []),
          { file: fileObj.file, id },
        ],
      }))
    }
  }

  _form = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>Add Preview Image</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              <Col sm="12" md="4">
                <Row style={{ marginTop: "10px" }}>
                  <Upload
                    showUploadList={true}
                    onRemove={fileObj => this._onImageRemove(fileObj)}
                    defaultFileList={this.state.preview_image_list}
                    fileList={this.state.preview_image_list}
                    customRequest={fileObj => this._uploadImage(fileObj)}
                  >
                    <Button color="primary" onClick={() => {}}>
                      <i className="fas fa-upload" />
                      &nbsp;{" "}
                      {this.state.preview_image_list &&
                      this.state.preview_image_list.length
                        ? "Upload More Image"
                        : "Upload Image"}
                    </Button>
                  </Upload>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="flex" style={{ marginTop: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <Button htmlType="submit" color="primary">
                      Add
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.onClose()
                      }}
                      color="danger"
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data = new FormData()
    const payload = {}
    let imageList = this.props.imageList
    const zoneId = this.props.zoneId || {}
    // const { gift_element_uuid } = giftData || {};
    if (!zoneId) {
      toastr.error("ZoneId Missing")
    }
    const { previewImage } = this.state

    if (previewImage) {
      previewImage.map(image => {
        if (image.id && image.file) {
          imageList = [...imageList, { id: image.id }]
          data.append(image.id, image.file)
        }
      })
    }

    const details = {
      zone_uuid: zoneId,
      image_url: imageList,
    }

    data.append("details", JSON.stringify(details))

    this.props.onSubmit(data)
  }

  render() {
    return this._form()
  }
}
