import { Pagination, Select, Tag, Radio, Input } from "antd"
import { deleteImage, getImages, resetState } from "appRedux/slices/image"
import { getAllZones } from "appRedux/slices/zone"
import Chips from "components/CommonFilter/chips"
import _ from "lodash"
import QueryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import toastr from "toastr"
import {
  bulkUploadImageDeactivate,
  activateDeactivateImage,
} from "../../clientServices/imageService"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  imageFilters,
  NO_PERMISSION_MESSAGE,
  competition_logos,
  banned_organizations,
} from "../../constants/uiConstants"
import Auxiliary from "../../util/Auxiliary"
import ImageCard from "./ImageCard"
import { formatNumber } from "../../util/common"
import ReactSelect from "react-select"

const { Group } = Radio

class ManageImage extends ErrorBoundComponent {
  state = {
    filters: {
      imageId: this.props.queryParams ? this.props.queryParams["imageId"] : "",
      userUUID: this.props.queryParams
        ? this.props.queryParams["userUUID"]
        : "",
      title: this.props.queryParams ? this.props.queryParams["title"] : "",
      sourceType: this.props.queryParams
        ? this.props.queryParams["sourceType"]
        : "",
      moderationLevel: this.props.queryParams
        ? this.props.queryParams["moderationLevel"]
        : "",
      ce_type: this.props.queryParams ? this.props.queryParams["ce_type"] : "",
      langCode: this.props.queryParams
        ? this.props.queryParams["langCode"]
        : "",
      isActive: this.props.queryParams
        ? this.props.queryParams["isActive"]
        : "",
      currentPage: this.props.queryParams
        ? this.props.queryParams["currentPage"]
        : 1,
      pageSize: this.props.pageSize ? this.props.queryParams["pageSize"] : 10,
      inputZone: this.props.queryParams
        ? this.props.queryParams["inputZone"]
        : "",
      activeZone: this.props.queryParams
        ? this.props.queryParams["activeZone"]
        : "",
    },
    showModal: false,
    showUploadModal: false,
    action: "create",
    showFilters: false,
    showAdvancedFilters: false,
    showRejectReasonModal: false,
    activeDeactiveImage: null,
    activeDeactiveAction: "",
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      let filters = _.cloneDeep(this.state.filters)
      _.map(this.state.filters, (filter, key) => {
        if (!filter) {
          _.unset(filters, key)
        }
      })
      this.props.dispatch(getImages(filters))
      this.props.dispatch(getAllZones({ currentPage: 1, pageSize: 10000 }))
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _setShowRejectReasonModal = (action, image) => {
    if (action === "deactivate") {
      this.setState({
        showRejectReasonModal: true,
        activeDeactiveAction: action,
        activeDeactiveImage: image,
      })
    } else if (action === "activate") {
      this.setState(
        {
          activeDeactiveAction: action,
          activeDeactiveImage: image,
        },
        () => this._handleActivateDeactivateImage()
      )
    }
  }

  _onSearch = () => {
    let filters = _.cloneDeep(this.state.filters)
    _.map(this.state.filters, (filter, key) => {
      if (!filter) {
        _.unset(filters, key)
      }
    })
    this.props.history.push(`/image/browse?${QueryString.stringify(filters)}`)
    this.props.dispatch(getImages(filters))
  }

  _closeFilters = () => {
    this._onSearch()
    this.setState({ showFilters: false, showAdvancedFilters: false })
  }

  _handleFilterChange = (value, type) => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({
      filters: newFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: imageFilters[filter].defaultValue,
      showFilters: false,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/image/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        let filters = _.cloneDeep(this.state.filters)
      }
    )
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _renderFilters = () => {
    const allZoneList = []
    this.props?.allZoneList.map(zone => {
      if (zone && zone.zone_uuid) {
        allZoneList.push({
          ...zone,
          value: zone.zone_uuid,
          label: zone.title || zone.sub_title,
        })
      }
    })
    return (
      <Chips
        type="image"
        showFilters={this.state.showFilters}
        showAdvancedFilters={this.state.showAdvancedFilters}
        filters={this.state.filters}
        search={this._closeFilters}
        removeFilter={this._removeFilter}
        handleFilterChange={this._handleFilterChange}
        toggleFilters={this._toggleFilters}
        toggleAdvancedFilters={this._toggleAdvancedFilters}
        allZoneList={allZoneList}
      />
    )
  }

  _showImageDetails = () => {
    return (
      <React.Fragment>
        <Col lg={this.state.showForm ? "7" : "12"}>
          <div className="trending-card-container">
            {this.props.imageList.map((image, index) => {
              return (
                <ImageCard
                  image={image}
                  key={`${index}_${image.title}`}
                  editClick={(image, type) => this.editClick(image, type)}
                  currentUser={this.props.currentUser}
                  history={this.props.history}
                  handleDelete={id => this.props.dispatch(deleteImage({ id }))}
                  onEditClick={image =>
                    this.props.history.push(
                      `/image/manage?imageUuid=${image.content_uuid}`
                    )
                  }
                  setShowRejectReasonModal={this._setShowRejectReasonModal}
                />
              )
            })}
          </div>
        </Col>
      </React.Fragment>
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._onSearch()
      }
    )
  }

  _onUpload = async () => {
    const formData = new FormData()
    formData.append("files", this.state.csvFile)
    await bulkUploadImageDeactivate(formData)
    this.setState({ showBulkUploadForm: false })
  }

  _pagination = () => {
    return (
      <div className="d-flex justify-challenge-end">
        <Pagination
          style={{ marginTop: "1rem" }}
          onChange={this._onPageChange}
          current={parseInt(this.state.filters["currentPage"], 10)}
          defaultCurrent={parseInt(this.state.filters["currentPage"], 10)}
          total={this.props.total > 10000 ? 10000 : this.props.total}
          showSizeChanger={false}
        />
      </div>
    )
  }

  _bulkUploadForm = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="6">
              <label className="col-form-label">Upload CSV File</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  this.setState({
                    csvFile: e.target.files[0],
                  })
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm="12">
              <div className="d-flex justify-content-end">
                <Button
                  className="font-16 btn-block btn btn-primary"
                  color="primary"
                  onClick={this._onUpload}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="danger"
                  size="default"
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.setState({ showBulkUploadForm: false })}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  toggleUploadBulk = () => {
    if (
      this.props.currentUser?.jotRoles?.includes("ADMIN") ||
      this.props.currentUser?.jotRoles?.includes("SUPER_ADMIN")
    ) {
      this.setState({ showBulkUploadForm: true })
      return
    }

    toastr.error(NO_PERMISSION_MESSAGE)
  }

  formatOptionLabel = ({ value, label, logoSrc }) => {
    return (
      <span>
        {logoSrc ? (
          <img
            src={logoSrc}
            alt={label}
            style={{ hight: "20px", width: "20px" }}
          />
        ) : (
          <i className="fa fa-users" aria-hidden="true"></i>
        )}{" "}
        &nbsp;
        <b>{label}</b>
      </span>
    )
  }

  _handleActivateDeactivateImage = async () => {
    if (this.state.activeDeactiveImage) {
      let skipedContentData = { ...this.state.activeDeactiveImage }
      skipedContentData["reject_reason"] = this.state.reject_reason
      if (this.state.competitor_logo_name) {
        skipedContentData["competitor_logo_name"] =
          this.state.competitor_logo_name
      }
      if (this.state.competitor_logo_name_text) {
        skipedContentData["competitor_logo_name_text"] =
          this.state.competitor_logo_name_text
      }
      if (this.state.banned_organization_name) {
        skipedContentData["banned_organization_name"] = banned_organization_name
      }
      if (this.state.misc_reject_reason) {
        skipedContentData["misc_reject_reason"] = this.state.misc_reject_reason
      }

      let result = await activateDeactivateImage(
        skipedContentData,
        this.state.activeDeactiveAction,
        this.props.currentUser
      )

      if (result?.data?.code === 200) {
        toastr.remove()
        toastr.success("Action successful!!")
        this.setState({
          showRejectReasonModal: false,
          activeDeactiveImage: null,
          activeDeactiveAction: "",
          reject_reason: "",
          competitor_logo_name: "",
          competitor_logo_name_text: "",
          banned_organization_name: "",
          misc_reject_reason: "",
        })
      }
    }
    return
  }

  _deactivateContentModal = () => {
    const moderator =
      (this.props.currentUser?.jotUserType &&
        this.props.currentUser.jotUserType === 4) ||
      (this.props.currentUser?.userType &&
        this.props.currentUser.userType === 4)

    return (
      <div style={{ padding: "10px" }}>
        <ModalHeader
          toggle={() =>
            this.setState({
              showRejectReasonModal: false,
              activeDeactiveImage: null,
              activeDeactiveAction: "",
              reject_reason: "",
            })
          }
        >
          <span className="font-weight-semibold">De Activate Reasons</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Group
              style={{ display: "contents" }}
              onChange={e => this.setState({ reject_reason: e.target.value })}
              value={this.state.reject_reason || ""}
            >
              <Col span={24} style={{ display: "flex" }} className="flex-wrap">
                <div className="topicRadioBtn">
                  <Radio value="competitor_logo">COMPETITOR LOGO</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="banned_organization">BANNED ORGANIZATION</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="josh_logo">JOSH LOGO</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="masala">MASALA</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="porn">PORN</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="blurred">BLURRED</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="blood">BLOOD</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="death">DEATH</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="accident">ACCIDENT</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="suicide">SUICIDE</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="anti_national">ANTI NATIONAL</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="anti_religion">ANTI RELIGION</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="misc">MISC</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="animal_cruelty">ANIMAL CRUELTY</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="child_nudity">CHILD NUDITY</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="abusive">ABUSIVE</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="violence">VIOLENCE</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="copyright_content" disabled={moderator}>
                    COPYRIGHT CONTENT
                  </Radio>
                </div>

                <div className="topicRadioBtn">
                  <Radio
                    value="misinformation_&_fake_news"
                    disabled={moderator}
                  >
                    MISINFORMATION &amp; FAKE NEWS{" "}
                  </Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio
                    value="cyberbullying_&_harassment"
                    disabled={moderator}
                  >
                    {" "}
                    CYBERBULLYING &amp; HARASSMENT{" "}
                  </Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio
                    value="identity_theft_&_impersonation"
                    disabled={moderator}
                  >
                    IDENTITY THEFT &amp; IMPERSONATION
                  </Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="mob_violence">MOB VIOLENCE</Radio>
                </div>
              </Col>
            </Group>
          </Row>
          <Row>
            {this.state.reject_reason &&
            this.state.reject_reason === "competitor_logo" ? (
              <Col lg={6} xl={6} sm={12} className="p-1">
                <ReactSelect
                  style={{ width: "100%" }}
                  isMulti={false}
                  options={competition_logos}
                  formatOptionLabel={this.formatOptionLabel}
                  onChange={value =>
                    this.setState({ competitor_logo_name: value.value })
                  }
                  placeholder="Select Logo..."
                />
              </Col>
            ) : null}

            {this.state.reject_reason &&
            this.state.reject_reason === "competitor_logo" &&
            this.state.competitor_logo_name &&
            this.state.competitor_logo_name === "others" ? (
              <Col className="p-1">
                <Input
                  placeholder={"Enter Logo..."}
                  // onChange={e =>
                  //   props.handleFilterChange(e.target.value, "name")
                  // }
                  // size="small"
                  className="h-100"
                  allowClear={true}
                  name="logo"
                  required
                  style={{ width: "100%" }}
                  onChange={e => {
                    this.setState({
                      competitor_logo_name_text: e.target.value,
                    })
                  }}
                />
              </Col>
            ) : null}

            {this.state.reject_reason &&
            this.state.reject_reason === "banned_organization" ? (
              <Col lg={24} xl={24} sm={24} className="p-1">
                <ReactSelect
                  style={{ width: "100%" }}
                  isMulti={false}
                  options={banned_organizations}
                  onChange={value =>
                    this.setState({
                      banned_organization_name: value.value,
                    })
                  }
                  placeholder="Select Organization..."
                />
              </Col>
            ) : null}

            {this.state.reject_reason && this.state.reject_reason === "misc" ? (
              <Col className="p-1">
                <Input
                  placeholder={"Enter Reject Reason..."}
                  // onChange={e =>
                  //   props.handleFilterChange(e.target.value, "name")
                  // }
                  // size="small"
                  className="h-100"
                  allowClear={true}
                  name="logo"
                  required
                  style={{ width: "100%" }}
                  onChange={e => {
                    this.setState({
                      misc_reject_reason: e.target.value,
                    })
                  }}
                />
              </Col>
            ) : null}
          </Row>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={() => {
              this._handleActivateDeactivateImage()
              this.setState({
                showRejectReasonModal: false,
              })
            }}
            // role="button"
            className="btn btn-danger d-flex mx-1"
            disabled={
              (this.state.reject_reason == "competitor_logo" &&
                !this.state.competitor_logo_name) ||
              (this.state.reject_reason == "competitor_logo" &&
                this.state.competitor_logo_name === "others" &&
                !this.state.competitor_logo_name_text) ||
              (this.state.reject_reason == "banned_organization" &&
                !this.state.banned_organization_name) ||
              (this.state.reject_reason == "misc" &&
                !this.state.misc_reject_reason)
            }
          >
            {this.state.activeDeactiveAction == "activate"
              ? "Activate"
              : "De Activate"}
          </button>
          <button
            onClick={() =>
              this.setState({
                showRejectReasonModal: false,
                activeDeactiveImage: null,
                activeDeactiveAction: "",
                reject_reason: "",
                competitor_logo_name: "",
                competitor_logo_name_text: "",
                banned_organization_name: "",
                misc_reject_reason: "",
              })
            }
            className="btn btn-danger d-flex mx-1"
          >
            Cancel
          </button>
        </ModalFooter>
      </div>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._renderFilters()}
        <div className="d-flex justify-content-end mb-3">
          <button
            className="btn btn-primary"
            onClick={() => {
              this.props.dispatch(resetState())
              this.props.history.push(`/image/manage`)
            }}
          >
            Create
          </button>
          <button
            color="primary"
            className="font-16 btn-block btn btn-primary mx-1"
            onClick={this.toggleUploadBulk}
          >
            <i className="fas fa-arrow-up me-1" />
            Upload
          </button>
        </div>
        {this.state.showBulkUploadForm && this._bulkUploadForm()}

        <Row>
          {this.props.total &&
            this.props.imageList &&
            this.props.imageList.length > 0 && (
              <div style={{ textAlign: "center" }} className="mb-2">
                <Tag color="geekblue">
                  Showing <b>{this.props.imageList.length}</b> of{" "}
                  <b>{formatNumber(this.props.total)}</b> Images
                </Tag>
              </div>
            )}
          {!_.isEmpty(this.props.imageList) && this._showImageDetails()}
          <div className="d-flex justify-content-end mb-3">
            {this._pagination()}
          </div>
        </Row>
        {this.state.showRejectReasonModal ? (
          <>
            <Modal
              centered={true}
              className="exampleModal"
              isOpen={this.state.showRejectReasonModal}
            >
              {this._deactivateContentModal()}
            </Modal>
          </>
        ) : null}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "image.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "image.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    imageList: _.get(store, "image.imageList"),
    allZoneList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(ManageImage)
