import Axios from "axios"
import toastr from "toastr"

export const requestListings = async ({ payload }) => {
  const offset = payload.filters["currentPage"]
    ? payload.filters["currentPage"] - 1
    : 0
  const limit = payload.filters["pageSize"]
  try {
    let data = await Axios.post(
      `/api/listings/get-listings?offset=${offset}&limit=${limit}`,
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertListings = async ({ payload }) => {
  try {
    const url = `/api/listings/upsert?action=${payload.action}`
    let response = await Axios.post(url, payload.data)
    toastr.success("Action Successfull !!!")
    return response
  } catch (error) {
    toastr.error(error)
    return error
  }
}
