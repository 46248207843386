import React, { useEffect, useState } from "react"
import { Card, Input } from "reactstrap"
import _ from "lodash"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import PieCharts from "../../../components/Charts/PieChart"
import BarCharts from "components/Charts/BarChart"
import LineBarCharts from "components/Charts/LineBarChart"
import { formatNumber } from "../../../util/common"

import {
  CURATED_GENRE_LIST_MAPPING,
  CURATED_TYPE_MAPPING,
  LANGUAGE_MAPPING,
} from "constants/uiConstants"
import {
  getPodTimespanChartData,
  getGenreTimespanChartData,
  getLanguageTimespanChartData,
  getGenreTrendsChartData,
  getPodTrendsChartData,
  getLanguageTrendsChartData,
  getAllPostTrendsChartData,
  getActiveUserTrendsChartData,
  getPodActiveUserTrendsChartData,
  getLanguageActiveUserTrendsChartData,
  getGenreActiveUserTrendsChartData,
  getAllPercentageOfPostByXViewData,
  getAllPostsData,
  getAllUserData,
  getDuplicateContentChartData,
} from "appRedux/slices/community"
import StackedBarCharts from "components/Charts/StackedBarChart"
import Chart from "components/Charts/Chart"
import {
  getLablesAndValues,
  getStackedBarData,
  getAllActiveUserGraphData,
  getAllTypeActiveUserGraphData,
  getAllTypePostCountFunnelGraphData,
} from "./ReportUtil/graphDataFormatter"
import Auxiliary from "../../../util/Auxiliary"
import {
  YESTERDAY,
  LAST_7_DAYS,
  LAST_15_DAYS,
  LAST_30_DAYS,
  LAST_POST_DATE,
  LAST_QUARTER,
  LAST_100_VIEWCOUNT,
  LAST_1000_VIEWCOUNT,
  LAST_10000_VIEWCOUNT,
  LAST_100000_VIEWCOUNT,
  dayTimespanMap,
  viewMap,
  POD,
  LANGUAGE,
  GENRE,
  VIEWS,
} from "./ReportUtil/constant"

const DashboardReportCard = props => {
  const {
    loading,
    communityManagerData,
    podTimespanChartData,
    languageTimespanChartData,
    genreTimespanChartData,
    podTrendsChartData,
    genreTrendsChartData,
    allPostTrendsChartData,
    languageTrendsChartData,
    chartData,
    genreActiveUserTrendsChartData,
    languageActiveUserTrendsChartData,
    podActiveUserTrendsChartData,
    activeUserTrendsChartData,
    percentageViewData,
    allTypePostCountData,
    allUserData,
    duplicateContentData,
  } = useSelector(state => ({
    loading: _.get(state, "community.loading"),
    allUserData: _.get(state, "community.allUserData"),
    communityManagerData: _.get(state, "community.communityManager"),
    podTimespanChartData: _.get(state, "community.podTimespanChartData"),
    languageTimespanChartData: _.get(
      state,
      "community.languageTimespanChartData"
    ),
    genreTimespanChartData: _.get(state, "community.genreTimespanChartData"),
    podTrendsChartData: _.get(state, "community.podTrendsChartData"),
    languageTrendsChartData: _.get(state, "community.languageTrendsChartData"),
    genreTrendsChartData: _.get(state, "community.genreTrendsChartData"),
    allPostTrendsChartData: _.get(state, "community.allPostTrendsChartData"),
    chartData: _.get(state, "community.chartData"),
    genreActiveUserTrendsChartData: _.get(
      state,
      "community.genreActiveUserTrendsChartData"
    ),
    languageActiveUserTrendsChartData: _.get(
      state,
      "community.languageActiveUserTrendsChartData"
    ),
    podActiveUserTrendsChartData: _.get(
      state,
      "community.podActiveUserTrendsChartData"
    ),
    activeUserTrendsChartData: _.get(
      state,
      "community.activeUserTrendsChartData"
    ),
    percentageViewData: _.get(state, "community.percentageViewData"),
    allTypePostCountData: _.get(state, "community.allTypePostCountData"),
    duplicateContentData: _.get(state, "community.duplicateContentData"),
  }))

  const timespanChartData = {
    genreData: genreTimespanChartData,
    languageData: languageTimespanChartData,
    podData: podTimespanChartData,
  }
  const trendsChartData = {
    allPostData: allPostTrendsChartData,
    genreData: genreTrendsChartData,
    languageData: languageTrendsChartData,
    podData: podTrendsChartData,
    activeUserData: activeUserTrendsChartData,
    genreActiveUserData: genreActiveUserTrendsChartData,
    languageActiveUserData: languageActiveUserTrendsChartData,
    podActiveUserData: podActiveUserTrendsChartData,
  }

  const dispatch = useDispatch()

  const getUserOptions = () => {
    if (allUserData && allUserData.length > 0) {
      const userOptions = allUserData.map(user => {
        const { user_uuid, name, profile_pic, handle } = user

        let image = <i className="bx bx-user-circle fa-2xl"></i>
        if (profile_pic) {
          image = (
            <img
              key={user_uuid}
              src={profile_pic}
              alt={name || handle}
              width="30"
              height="30"
            />
          )
        }

        return { image, value: user_uuid, label: name || handle }
      })
      return userOptions
    }
    return null
  }

  const [podTimespan, setPodTimespan] = useState(YESTERDAY)
  const [languageTimespan, setLanguageTimespan] = useState(YESTERDAY)
  const [genreTimespan, setGenreTimespan] = useState(YESTERDAY)
  const [podTrends, setPodTrends] = useState(LAST_7_DAYS)
  const [languageTrends, setLanguageTrends] = useState(LAST_7_DAYS)
  const [genreTrends, setGenreTrends] = useState(LAST_7_DAYS)
  const [allPostTrends, setallPostTrends] = useState(LAST_7_DAYS)
  const [podActiveUserTrends, setPodActiveUserTrends] = useState(LAST_7_DAYS)
  const [languageActiveUserTrends, setLanguageActiveUserTrends] =
    useState(LAST_7_DAYS)
  const [genreActiveUserTrends, setGenreActiveUserTrends] =
    useState(LAST_7_DAYS)
  const [activeUserTrends, setActiveUserTrends] = useState(LAST_7_DAYS)

  const [activePerViewDays, setActivePerViewDays] = useState(LAST_7_DAYS)

  const [activePerView, setActivePerView] = useState(LAST_100_VIEWCOUNT)
  const [selectedCreator, setSelectedCreator] = useState()
  const [selectedCreatorForDuplicate, setSelectedCreatorForDuplicate] =
    useState()

  const [allPostTimespan, setAllPostTimespan] = useState(YESTERDAY)
  const [duplicateContentTimespan, setDuplicateContentTimespan] =
    useState(LAST_7_DAYS)

  useEffect(() => {
    if (podTimespan) {
      dispatch(
        getPodTimespanChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[podTimespan],
          graphType: POD,
        })
      )
    }
  }, [podTimespan])

  useEffect(() => {
    if (languageTimespan) {
      dispatch(
        getLanguageTimespanChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[languageTimespan],
          graphType: LANGUAGE,
        })
      )
    }
  }, [languageTimespan])

  useEffect(() => {
    if (genreTimespan) {
      dispatch(
        getGenreTimespanChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[genreTimespan],
          graphType: GENRE,
        })
      )
    }
  }, [genreTimespan])
  useEffect(() => {
    if (podTrends) {
      dispatch(
        getPodTrendsChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData?.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[podTrends],
          graphType: POD,
        })
      )
    }
  }, [podTrends])

  useEffect(() => {
    if (languageTrends) {
      dispatch(
        getLanguageTrendsChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[languageTrends],
          graphType: LANGUAGE,
        })
      )
    }
  }, [languageTrends])

  useEffect(() => {
    if (genreTrends) {
      dispatch(
        getGenreTrendsChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData?.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[genreTrends],
          graphType: GENRE,
        })
      )
    }
  }, [genreTrends])
  useEffect(() => {
    if (allPostTrends) {
      dispatch(
        getAllPostTrendsChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[allPostTrends],
        })
      )
    }
  }, [allPostTrends])

  useEffect(() => {
    if (podActiveUserTrends) {
      dispatch(
        getPodActiveUserTrendsChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[podActiveUserTrends],
          graphType: POD,
        })
      )
    }
  }, [podActiveUserTrends])

  useEffect(() => {
    if (languageActiveUserTrends) {
      dispatch(
        getLanguageActiveUserTrendsChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[languageActiveUserTrends],
          graphType: LANGUAGE,
        })
      )
    }
  }, [languageActiveUserTrends])

  useEffect(() => {
    if (genreActiveUserTrends) {
      dispatch(
        getGenreActiveUserTrendsChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[genreActiveUserTrends],
          graphType: GENRE,
        })
      )
    }
  }, [genreActiveUserTrends])

  useEffect(() => {
    if (activeUserTrends) {
      dispatch(
        getActiveUserTrendsChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          timespan: dayTimespanMap[activeUserTrends],
        })
      )
    }
  }, [activeUserTrends])

  useEffect(() => {
    if (activePerView && selectedCreator && activePerViewDays) {
      dispatch(
        getAllPercentageOfPostByXViewData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList.length != 0
            ? "communityManagerId"
            : "communityLeadId",
          views: viewMap[activePerView],
          selectedUser: selectedCreator,
          timespan: dayTimespanMap[activePerViewDays],
        })
      )
    }
  }, [activePerView, selectedCreator, activePerViewDays])

  useEffect(() => {
    if (allPostTimespan) {
      dispatch(
        getAllPostsData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          timespan: dayTimespanMap[allPostTimespan],
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
        })
      )
    }
  }, [allPostTimespan])

  useEffect(() => {
    if (duplicateContentTimespan && selectedCreatorForDuplicate) {
      dispatch(
        getDuplicateContentChartData({
          communityLeadId: communityManagerData?.community?.leadId
            ? communityManagerData?.community?.leadId
            : communityManagerData.userId,
          communityManagerId: communityManagerData.userId,
          timespan: dayTimespanMap[duplicateContentTimespan],
          selectedUser: selectedCreatorForDuplicate,
          keyName: props.keyName
            ? props.keyName
            : [13, 33].includes(communityManagerData.jotUserType)
            ? "communityManagerId"
            : communityManagerData?.community?.usersList?.length != 0
            ? "communityManagerId"
            : "communityLeadId",
        })
      )
    }
  }, [duplicateContentTimespan, selectedCreatorForDuplicate])

  useEffect(() => {
    dispatch(
      getAllUserData({
        communityLeadId: communityManagerData?.community?.leadId
          ? communityManagerData?.community?.leadId
          : communityManagerData.userId,
        communityManagerId: communityManagerData.userId,
        keyName: props.keyName
          ? props.keyName
          : [13, 33].includes(communityManagerData.jotUserType)
          ? "communityManagerId"
          : communityManagerData?.community?.usersList.length != 0
          ? "communityManagerId"
          : "communityLeadId",
      })
    )
  }, [])

  const _noChartDataCard = (
    title,
    setCustomStyle = false,
    { height = "422px", marginTop = "28px", fontSize = "19px" } = {}
  ) => {
    return (
      <div
        className="noDataChart"
        style={
          setCustomStyle
            ? {
                height,
                marginTop,
                fontSize,
              }
            : {}
        }
      >
        <div className="chartTitle">{title}</div>
        <div className="noDataTitle">No Data Available</div>
      </div>
    )
  }

  const _report = arg => {
    let ByCuratedTypeLabelsAndValues
    let ByCuratedGenreLabelsAndValues
    let ByLanguageLabelsAndValues
    if (arg["ByCuratedType"]) {
      ByCuratedTypeLabelsAndValues = getLablesAndValues(
        arg["ByCuratedType"],
        CURATED_TYPE_MAPPING
      )
    }
    if (arg["ByCuratedGenre"]) {
      ByCuratedGenreLabelsAndValues = getLablesAndValues(
        arg["ByCuratedGenre"],
        CURATED_GENRE_LIST_MAPPING
      )
    }
    if (arg["ByLanguage"]) {
      ByLanguageLabelsAndValues = getLablesAndValues(
        arg["ByLanguage"],
        LANGUAGE_MAPPING
      )
    }

    return (
      <>
        <Card>
          {ByCuratedTypeLabelsAndValues ? (
            <PieCharts
              key={props.toggleshowdetails}
              labels={ByCuratedTypeLabelsAndValues.labels}
              values={ByCuratedTypeLabelsAndValues.values}
              title={"Unique Creator By Pod (Overall)"}
            />
          ) : (
            _noChartDataCard("Unique Creator By Pod (Overall)")
          )}
        </Card>

        <Card>
          {ByCuratedGenreLabelsAndValues ? (
            <BarCharts
              key={props.toggleshowdetails}
              labels={ByCuratedGenreLabelsAndValues.labels}
              values={ByCuratedGenreLabelsAndValues.values}
              title={"Unique Creator By Genre (Overall)"}
            />
          ) : (
            _noChartDataCard("Unique Creator By Genre (Overall)")
          )}
        </Card>

        <Card>
          {ByLanguageLabelsAndValues ? (
            <BarCharts
              key={props.toggleshowdetails}
              labels={ByLanguageLabelsAndValues.labels}
              values={ByLanguageLabelsAndValues.values}
              title={"Unique Creator By Language (Overall)"}
            />
          ) : (
            _noChartDataCard("Unique Creator By Language (Overall)")
          )}
        </Card>
      </>
    )
  }

  const _timespanWiseReport = args => {
    const { genreData = {}, languageData = {}, podData = {} } = args || {}

    let activeByPodData
    let activeByLanguageData
    let activeByGenreData

    if (podData && Object.keys(podData).length > 0) {
      activeByPodData = getLablesAndValues(
        podData,
        CURATED_TYPE_MAPPING,
        true,
        "total_doc"
      )
    }
    if (languageData && Object.keys(languageData).length > 0) {
      activeByLanguageData = getLablesAndValues(
        languageData,
        LANGUAGE_MAPPING,
        true,
        "total_doc"
      )
    }
    if (genreData && Object.keys(genreData).length > 0) {
      activeByGenreData = getLablesAndValues(
        genreData,
        CURATED_GENRE_LIST_MAPPING,
        true,
        "total_doc"
      )
    }

    return (
      <>
        <Card>
          {activeByPodData ? (
            <LineBarCharts
              key={props.toggleshowdetails}
              title="% Active By Pod"
              lineData={{
                labels: activeByPodData.labels,
                values: activeByPodData.percentageValues,
              }}
              barData={{
                labels: activeByPodData.labels,
                values: activeByPodData.values,
              }}
            />
          ) : podTimespan ? (
            _noChartDataCard("% Active By Pod")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                podTimespan === YESTERDAY ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodTimespan(YESTERDAY)}
            >
              <strong>1 Day</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                podTimespan === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodTimespan(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                podTimespan === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodTimespan(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                podTimespan === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodTimespan(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
          </div>
        </Card>
        <Card>
          {activeByLanguageData ? (
            <LineBarCharts
              key={props.toggleshowdetails}
              title="% Active By Language"
              lineData={{
                labels: activeByLanguageData.labels,
                values: activeByLanguageData.percentageValues,
              }}
              barData={{
                labels: activeByLanguageData.labels,
                values: activeByLanguageData.values,
              }}
            />
          ) : languageTimespan ? (
            _noChartDataCard("% Active By Language")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                languageTimespan === YESTERDAY ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageTimespan(YESTERDAY)}
            >
              <strong>1 Day</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                languageTimespan === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageTimespan(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                languageTimespan === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageTimespan(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                languageTimespan === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageTimespan(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
          </div>
        </Card>
        <Card>
          {activeByGenreData ? (
            <LineBarCharts
              key={props.toggleshowdetails}
              title="% Active By Genre"
              lineData={{
                labels: activeByGenreData.labels,
                values: activeByGenreData.percentageValues,
              }}
              barData={{
                labels: activeByGenreData.labels,
                values: activeByGenreData.values,
              }}
            />
          ) : genreTimespan ? (
            _noChartDataCard("% Active By Genre")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                genreTimespan === YESTERDAY ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreTimespan(YESTERDAY)}
            >
              <strong>1 Day</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                genreTimespan === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreTimespan(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                genreTimespan === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreTimespan(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                genreTimespan === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreTimespan(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
          </div>
        </Card>
      </>
    )
  }
  const _trendsReport = args => {
    const {
      genreData = {},
      languageData = {},
      podData = {},
      allPostData = {},
      activeUserData = {},
      genreActiveUserData = {},
      languageActiveUserData = {},
      podActiveUserData = {},
    } = args || {}

    let activeByPodData
    let activeByLanguageData
    let activeByGenreData
    let activeByAllPost
    let refinedactiveByAllPost
    let activeUserGraphData
    let activePodUserGraphData
    let activeLanguageUserGraphData
    let activeGenreUserGraphData

    if (podData && Object.keys(podData).length > 0) {
      activeByPodData = getStackedBarData(podData, CURATED_TYPE_MAPPING)
    }
    if (languageData && Object.keys(languageData).length > 0) {
      activeByLanguageData = getStackedBarData(languageData, LANGUAGE_MAPPING)
    }
    if (genreData && Object.keys(genreData).length > 0) {
      activeByGenreData = getStackedBarData(
        genreData,
        CURATED_GENRE_LIST_MAPPING
      )
    }
    if (allPostData && Object.keys(allPostData).length > 0) {
      activeByAllPost = getLablesAndValues(allPostData, CURATED_TYPE_MAPPING)
      if (activeByAllPost) {
        refinedactiveByAllPost = [
          {
            x: activeByAllPost.labels,
            y: activeByAllPost.values,
            type: "scatter",
          },
        ]
      }
    }

    if (activeUserData && Object.keys(activeUserData).length > 0) {
      activeUserGraphData = getAllActiveUserGraphData(activeUserData)
    }
    if (podActiveUserData && Object.keys(podActiveUserData).length > 0) {
      activePodUserGraphData = getAllTypeActiveUserGraphData(
        podActiveUserData,
        CURATED_TYPE_MAPPING
      )
    }
    if (
      languageActiveUserData &&
      Object.keys(languageActiveUserData).length > 0
    ) {
      activeLanguageUserGraphData = getAllTypeActiveUserGraphData(
        languageActiveUserData,
        LANGUAGE_MAPPING
      )
    }
    if (genreActiveUserData && Object.keys(genreActiveUserData).length > 0) {
      activeGenreUserGraphData = getAllTypeActiveUserGraphData(
        genreActiveUserData,
        CURATED_GENRE_LIST_MAPPING
      )
    }
    return (
      <>
        <Card>
          {activeByAllPost ? (
            <Chart
              key={props.toggleshowdetails}
              title="Trends - Posts Over Time"
              data={refinedactiveByAllPost}
            />
          ) : allPostTrends ? (
            _noChartDataCard("Trends - Posts Over Time")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                allPostTrends === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setallPostTrends(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                allPostTrends === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setallPostTrends(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                allPostTrends === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setallPostTrends(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                allPostTrends === LAST_QUARTER ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setallPostTrends(LAST_QUARTER)}
            >
              <strong>90 Days</strong>
            </button>
          </div>
        </Card>
        <Card>
          {activeByPodData ? (
            <StackedBarCharts
              key={props.toggleshowdetails}
              title="Trends - Posts By Pod"
              data={activeByPodData}
            />
          ) : podTrends ? (
            _noChartDataCard("Trends - Posts By Pod")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                podTrends === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodTrends(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                podTrends === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodTrends(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                podTrends === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodTrends(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                podTrends === LAST_QUARTER ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodTrends(LAST_QUARTER)}
            >
              <strong>90 Days</strong>
            </button>
          </div>
        </Card>
        <Card>
          {activeByLanguageData ? (
            <StackedBarCharts
              key={props.toggleshowdetails}
              title="Trends - Posts By Language"
              data={activeByLanguageData}
            />
          ) : languageTrends ? (
            _noChartDataCard("Trends -Posts Trends By Language")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                languageTrends === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageTrends(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                languageTrends === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageTrends(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                languageTrends === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageTrends(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                languageTrends === LAST_QUARTER ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageTrends(LAST_QUARTER)}
            >
              <strong>90 Days</strong>
            </button>
          </div>
        </Card>
        <Card>
          {activeByGenreData ? (
            <StackedBarCharts
              key={props.toggleshowdetails}
              title="Trends - Posts By Genre"
              data={activeByGenreData}
            />
          ) : genreTrends ? (
            _noChartDataCard("Trends - Posts By Genre")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                genreTrends === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreTrends(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                genreTrends === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreTrends(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                genreTrends === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreTrends(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                genreTrends === LAST_QUARTER ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreTrends(LAST_QUARTER)}
            >
              <strong>90 Days</strong>
            </button>
          </div>
        </Card>
        <Card>
          {activeUserGraphData ? (
            <Chart
              key={props.toggleshowdetails}
              title="Trends - % Active Creators"
              data={activeUserGraphData}
            />
          ) : activeUserTrends ? (
            _noChartDataCard("Trends - % Active Creators")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                activeUserTrends === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActiveUserTrends(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                activeUserTrends === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActiveUserTrends(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                activeUserTrends === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActiveUserTrends(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                activeUserTrends === LAST_QUARTER ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActiveUserTrends(LAST_QUARTER)}
            >
              <strong>90 Days</strong>
            </button>
          </div>
        </Card>
        <Card>
          {activePodUserGraphData ? (
            <Chart
              key={props.toggleshowdetails}
              title="Trends - % Active By Pod"
              data={activePodUserGraphData}
            />
          ) : podActiveUserTrends ? (
            _noChartDataCard("Trends - % Active By Pod")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                podActiveUserTrends === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodActiveUserTrends(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                podActiveUserTrends === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodActiveUserTrends(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                podActiveUserTrends === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodActiveUserTrends(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                podActiveUserTrends === LAST_QUARTER ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setPodActiveUserTrends(LAST_QUARTER)}
            >
              <strong>90 Days</strong>
            </button>
          </div>
        </Card>
        <Card>
          {activeLanguageUserGraphData ? (
            <Chart
              key={props.toggleshowdetails}
              title="Trends - % Active By Language"
              data={activeLanguageUserGraphData}
            />
          ) : languageActiveUserTrends ? (
            _noChartDataCard("Trends - % Active By Language")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                languageActiveUserTrends === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageActiveUserTrends(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                languageActiveUserTrends === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageActiveUserTrends(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                languageActiveUserTrends === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageActiveUserTrends(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                languageActiveUserTrends === LAST_QUARTER ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setLanguageActiveUserTrends(LAST_QUARTER)}
            >
              <strong>90 Days</strong>
            </button>
          </div>
        </Card>
        <Card>
          {activeGenreUserGraphData ? (
            <Chart
              key={props.toggleshowdetails}
              title="Trends - % Active By Genre"
              data={activeGenreUserGraphData}
            />
          ) : genreActiveUserTrends ? (
            _noChartDataCard("Trends - % Active By Genre")
          ) : null}
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                genreActiveUserTrends === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreActiveUserTrends(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                genreActiveUserTrends === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreActiveUserTrends(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                genreActiveUserTrends === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreActiveUserTrends(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                genreActiveUserTrends === LAST_QUARTER ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setGenreActiveUserTrends(LAST_QUARTER)}
            >
              <strong>90 Days</strong>
            </button>
          </div>
        </Card>
      </>
    )
  }

  const formatOptionLabel = ({ value, label, image }) => {
    return (
      <span>
        {image}&nbsp;
        <b>{label}</b>
      </span>
    )
  }

  const _viewPercPerUser = args => {
    let label = []
    let val = []
    let text = []
    let texttemplate = "%{value} (%{percent})"
    const marker = { colors: ["rgb(255,165,0)", "rgb(0, 0, 255)"] }

    for (let item in args?.data) {
      if (item === "totalCount") {
        label.push("Rest Posts")
        val.push(args.data[item] - args.data["activePost"])
      } else {
        label.push(`Posts > ${formatNumber(activePerView.slice(1))} views`)
        val.push(args.data[item])
      }
    }

    const layout = {
      height: 390,
      margin: {
        b: 10,
      },
    }

    return (
      <>
        <Card>
          {val.length != 0 && val[1] != 0 ? (
            <PieCharts
              key={props.toggleshowdetails}
              labels={label}
              values={val}
              text={text}
              texttemplate={texttemplate}
              title={"% of Post Per Views"}
              layout={layout}
              marker={marker}
            />
          ) : (
            _noChartDataCard("% of Post Per Views", true, { height: "360px" })
          )}
          <Select
            isMulti={false}
            className="margin_10px z_index_10"
            options={getUserOptions()}
            formatOptionLabel={formatOptionLabel}
            onChange={val => setSelectedCreator(val.value)}
            placeholder="select user..."
          />
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                activePerView === LAST_100_VIEWCOUNT ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActivePerView(LAST_100_VIEWCOUNT)}
            >
              <strong>100 Views</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                activePerView === LAST_1000_VIEWCOUNT ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActivePerView(LAST_1000_VIEWCOUNT)}
            >
              <strong>1000 Views</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                activePerView === LAST_10000_VIEWCOUNT ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActivePerView(LAST_10000_VIEWCOUNT)}
            >
              <strong>10k Views</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                activePerView === LAST_100000_VIEWCOUNT ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActivePerView(LAST_100000_VIEWCOUNT)}
            >
              <strong>100k Views</strong>
            </button>
          </div>
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-primary ${
                activePerViewDays === LAST_7_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActivePerViewDays(LAST_7_DAYS)}
            >
              <strong>7 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-danger ${
                activePerViewDays === LAST_30_DAYS ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActivePerViewDays(LAST_30_DAYS)}
            >
              <strong>30 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-success ${
                activePerViewDays === LAST_POST_DATE ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActivePerViewDays(LAST_POST_DATE)}
            >
              <strong>50 Days</strong>
            </button>
            <button
              type="button"
              className={`btn btn-info ${
                activePerViewDays === LAST_QUARTER ? "disabled" : ""
              } timespan-btn-margin`}
              onClick={() => setActivePerViewDays(LAST_QUARTER)}
            >
              <strong>90 Days</strong>
            </button>
          </div>
        </Card>
      </>
    )
  }

  const _consumptionFunnelReportChart = () => {
    let allTypePostCountGraphData
    if (allTypePostCountData && Object.keys(allTypePostCountData).length > 0) {
      allTypePostCountGraphData =
        getAllTypePostCountFunnelGraphData(allTypePostCountData)
    }
    const layout = {
      yaxis: {
        visible: false,
        showticklabels: false,
      },
      xaxis: {
        tickprefix: "<b>",
        ticksuffix: "</b>",
      },
      height: 500,
      margin: {
        b: 10,
        l: 20,
        r: 20,
      },
    }
    return (
      <Card>
        {allTypePostCountData &&
        allTypePostCountData.totalPostCount &&
        allTypePostCountGraphData ? (
          <Chart
            key={props.toggleshowdetails}
            title="Consumption Funnel of Posts"
            data={allTypePostCountGraphData}
            layout={layout}
          />
        ) : (
          _noChartDataCard("Consumption Funnel of Posts")
        )}
        <div className="btn-group" role="group">
          <button
            type="button"
            className={`btn btn-primary ${
              allPostTimespan === YESTERDAY ? "disabled" : ""
            } timespan-btn-margin`}
            onClick={() => setAllPostTimespan(YESTERDAY)}
          >
            <strong>1 Day</strong>
          </button>
          <button
            type="button"
            className={`btn btn-primary ${
              allPostTimespan === LAST_7_DAYS ? "disabled" : ""
            } timespan-btn-margin`}
            onClick={() => setAllPostTimespan(LAST_7_DAYS)}
          >
            <strong>7 Days</strong>
          </button>
          <button
            type="button"
            className={`btn btn-info ${
              allPostTimespan === LAST_15_DAYS ? "disabled" : ""
            } timespan-btn-margin`}
            onClick={() => setAllPostTimespan(LAST_15_DAYS)}
          >
            <strong>15 Days</strong>
          </button>
          <button
            type="button"
            className={`btn btn-danger ${
              allPostTimespan === LAST_30_DAYS ? "disabled" : ""
            } timespan-btn-margin`}
            onClick={() => setAllPostTimespan(LAST_30_DAYS)}
          >
            <strong>30 Days</strong>
          </button>
          <button
            type="button"
            className={`btn btn-success ${
              allPostTimespan === LAST_POST_DATE ? "disabled" : ""
            } timespan-btn-margin`}
            onClick={() => setAllPostTimespan(LAST_POST_DATE)}
          >
            <strong>50 Days</strong>
          </button>
        </div>
      </Card>
    )
  }

  const _duplicateContentChart = () => {
    let label = []
    let val = []
    let text = []
    let texttemplate = "%{value} (%{percent})"
    const marker = { colors: ["rgb(255,165,0)", "rgb(0, 0, 255)"] }

    Object.keys(duplicateContentData || {}).forEach(key => {
      const label_value =
        key === "duplicate_content"
          ? "Duplicate Content"
          : "Non Duplicate Content"
      label.push(label_value)
      val.push(duplicateContentData[key])
    })

    const layout = {
      height: 390,
      margin: {
        b: 10,
      },
    }

    return (
      <Card>
        {duplicateContentData &&
        (duplicateContentData?.duplicate_content != 0 ||
          duplicateContentData?.non_duplicate_content != 0) &&
        selectedCreatorForDuplicate ? (
          <PieCharts
            key={props.toggleshowdetails}
            labels={label}
            values={val}
            text={text}
            texttemplate={texttemplate}
            title={"Duplicate Content"}
            layout={layout}
            marker={marker}
          />
        ) : (
          _noChartDataCard("Duplicate Content", true, { height: "360px" })
        )}
        <Select
          isMulti={false}
          className="margin_10px z_index_10"
          options={getUserOptions()}
          formatOptionLabel={formatOptionLabel}
          onChange={val => setSelectedCreatorForDuplicate(val.value)}
          placeholder="select user..."
        />
        <div className="btn-group" role="group">
          <button
            type="button"
            className={`btn btn-primary ${
              duplicateContentTimespan === LAST_7_DAYS ? "disabled" : ""
            } timespan-btn-margin`}
            onClick={() => setDuplicateContentTimespan(LAST_7_DAYS)}
          >
            <strong>7 Days</strong>
          </button>
          <button
            type="button"
            className={`btn btn-danger ${
              duplicateContentTimespan === LAST_30_DAYS ? "disabled" : ""
            } timespan-btn-margin`}
            onClick={() => setDuplicateContentTimespan(LAST_30_DAYS)}
          >
            <strong>30 Days</strong>
          </button>
          <button
            type="button"
            className={`btn btn-success ${
              duplicateContentTimespan === LAST_POST_DATE ? "disabled" : ""
            } timespan-btn-margin`}
            onClick={() => setDuplicateContentTimespan(LAST_POST_DATE)}
          >
            <strong>50 Days</strong>
          </button>
          <button
            type="button"
            className={`btn btn-info ${
              duplicateContentTimespan === LAST_QUARTER ? "disabled" : ""
            } timespan-btn-margin`}
            onClick={() => setDuplicateContentTimespan(LAST_QUARTER)}
          >
            <strong>90 Days</strong>
          </button>
        </div>
      </Card>
    )
  }

  return (
    <>
      <Auxiliary loading={loading}>
        {chartData && _report(chartData)}
        {_timespanWiseReport(timespanChartData)}
        {_trendsReport(trendsChartData)}
        {_consumptionFunnelReportChart()}
        {_viewPercPerUser(percentageViewData)}
        {_duplicateContentChart()}
      </Auxiliary>
    </>
  )
}

export default DashboardReportCard
