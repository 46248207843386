import React from "react"
import { connect } from "react-redux"
import { Modal } from "react-bootstrap"
import ChipInput from "material-ui-chip-input"
import { Row, Col, Card, CardTitle, CardBody, Button } from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import { Pagination, Divider, Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Auxiliary from "../../util/Auxiliary"
import ValidatorForm, { TextField, Select } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getSpinStaticConfig,
  updateSpinStaticConfig,
} from "../../clientServices/spinConfigService"

class SpinStaticConfig extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      banner_list: [],
      static_buttons_list: [],
      chips_list: [],
      feed_hashtags: [],
    }
  }

  componentDidMount = async () => {
    const response = await getSpinStaticConfig()

    this.setState({
      data: response,
      banner_list:
        response?.banner_list?.map(banner => {
          if (!banner.id) {
            return { ...banner, id: uuidv4() }
          }
          return banner
        }) || [],
      static_buttons_list:
        response?.static_buttons_list?.map(button => {
          if (!button.button_id) {
            return { ...button, button_id: uuidv4() }
          }
          return button
        }) || [],
      chips_list:
        response?.chips_list?.map(chip => {
          if (!chip.chip_id) {
            return { ...chip, chip_id: uuidv4() }
          }
          return chip
        }) || [],
      feed_hashtags: response?.feed_hashtags || [],
    })
  }

  _handleFileUpload = (key, file) => {
    this.setState({
      [key]: file,
    })
  }

  addChip = value => {
    const feed_hashtags = this.state.feed_hashtags.slice()
    feed_hashtags.push(value)
    this.setState({ feed_hashtags })
  }

  removeChip = (value, index) => {
    const feed_hashtags = this.state.feed_hashtags.slice()
    feed_hashtags.splice(index, 1)
    this.setState({ feed_hashtags })
  }

  deleteBanner = id => {
    const banner_list = this.state?.banner_list?.filter(banner => {
      if (banner.id != id) {
        return banner
      }
    })
    this.setState({ banner_list })
  }

  deleteButton = id => {
    const static_buttons_list = this.state?.static_buttons_list?.filter(
      button => {
        if (button.button_id != id) {
          return button
        }
      }
    )
    this.setState({ static_buttons_list })
  }

  deleteChip = id => {
    const chips_list = this.state?.chips_list?.filter(chip => {
      if (chip.chip_id != id) {
        return chip
      }
    })
    this.setState({ chips_list })
  }

  _getBannerForm = (banner, idx) => {
    return (
      <>
        <Divider className="m-auto">
          <h5 className="mb-0 text-muted">{`Banner - ${idx + 1}`}</h5>
        </Divider>
        <Row className="mb-2">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"Banner ID"}
              field={`banner_list.${idx}.id`}
              className="creator-form"
              defaultValue={banner.id}
              disabled
            />
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"View Order"}
              field={`banner_list.${idx}.view_order`}
              className="creator-form"
              type="number"
              defaultValue={
                banner.view_order || banner.view_order === 0
                  ? banner.view_order
                  : (this.state.banner_list &&
                      this.state.banner_list[idx] &&
                      this.state.banner_list[idx].view_order) ||
                    idx
              }
              disabled
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Deeplink"}
              field={`banner_list.${idx}.deeplink`}
              className="creator-form"
              defaultValue={banner.deeplink || ""}
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Type"}
              field={`banner_list.${idx}.type`}
              className="creator-form"
              defaultValue={banner.type || ""}
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <label className="col-form-label" style={{ paddingTop: "0px" }}>
              Thumbnail
            </label>
            <input
              type="file"
              className="form-control"
              onChange={e => {
                this._handleFileUpload(
                  `thumbnail_banner=${banner.id}`,
                  e.target.files[0]
                )
                this.setState({ [`hide_image_${banner.id}`]: true })
              }}
              style={{ padding: "0.54rem 0.75rem" }}
            />
            {banner.thumbnail && !this.state[`hide_image_${banner.id}`] ? (
              <div className="d-flex justify-content-between align-items-center bg-light">
                <img
                  src={banner.thumbnail}
                  width="50px"
                  height="50px"
                  className="p-2"
                />
                <i
                  className="bx bx bx-trash-alt text-danger p-2"
                  role="button"
                  onClick={() =>
                    this.setState({ [`hide_image_${banner.id}`]: true })
                  }
                />
              </div>
            ) : null}
          </Col>
          <Col sm={12} md={6} lg={6} className="" style={{ display: "none" }}>
            <TextField
              field={`banner_list.${idx}.thumbnail`}
              className="creator-form"
              defaultValue={banner.thumbnail || ""}
            />
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="d-flex justify-content-end align-items-end"
          >
            <i
              className="bx bx bx-trash-alt bx-sm text-danger p-2"
              role="button"
              onClick={() => this.deleteBanner(banner.id)}
            />
          </Col>
        </Row>
      </>
    )
  }

  _getButtonForm = (button, idx) => {
    return (
      <>
        <Divider className="m-auto">
          <h5 className="mb-0 text-muted">{`Button - ${idx + 1}`}</h5>
        </Divider>
        <Row className="mb-2">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"Button ID"}
              field={`static_buttons_list.${idx}.button_id`}
              className="creator-form"
              defaultValue={button.button_id}
              disabled
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Text"}
              field={`static_buttons_list.${idx}.text`}
              className="creator-form"
              defaultValue={button.text || ""}
              //   inputFieldSize="medium"
            />
          </Col>
          <Col sm={10} md={5} lg={5} className="">
            <label className="col-form-label" style={{ paddingTop: "0px" }}>
              Icon URL
            </label>
            <input
              type="file"
              className="form-control"
              onChange={e => {
                this._handleFileUpload(
                  `icon_url_button=${button.button_id}`,
                  e.target.files[0]
                )
              }}
              style={{ padding: "0.54rem 0.75rem" }}
            />
            {button.icon_url &&
            !this.state[`hide_image_${button.button_id}`] ? (
              <div className="d-flex justify-content-between align-items-center bg-light">
                <img
                  src={button.icon_url}
                  width="50px"
                  height="50px"
                  className="p-2"
                />
                <i
                  className="bx bx bx-trash-alt text-danger p-2"
                  role="button"
                  onClick={() =>
                    this.setState({ [`hide_image_${button.button_id}`]: true })
                  }
                />
              </div>
            ) : null}
          </Col>
          <Col sm={10} md={5} lg={5} className="" style={{ display: "none" }}>
            <TextField
              field={`static_buttons_list.${idx}.icon_url`}
              className="creator-form"
              defaultValue={button.icon_url || ""}
            />
          </Col>
          <Col
            sm={2}
            md={1}
            lg={1}
            className="d-flex justify-content-end align-items-end"
          >
            <i
              className="bx bx bx-trash-alt bx-sm text-danger p-2"
              role="button"
              onClick={() => this.deleteButton(button.button_id)}
            />
          </Col>
        </Row>
      </>
    )
  }

  _getChipForm = (chip, idx) => {
    return (
      <>
        <Divider className="m-auto">
          <h5 className="mb-0 text-muted">{`Chip - ${idx + 1}`}</h5>
        </Divider>
        <Row className="mb-2">
          <Col
            sm={12}
            md={6}
            lg={6}
            className="mb-2"
            style={{ display: "none" }}
          >
            <TextField
              label={"Chip ID"}
              field={`chips_list.${idx}.chip_id`}
              className="creator-form"
              defaultValue={chip.chip_id}
              disabled
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Deeplink"}
              field={`chips_list.${idx}.deeplink`}
              className="creator-form"
              defaultValue={chip.deeplink || ""}
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <TextField
              label={"Type"}
              field={`chips_list.${idx}.type`}
              className="creator-form"
              defaultValue={chip.type || ""}
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="">
            <label className="col-form-label" style={{ paddingTop: "0px" }}>
              Icon URL
            </label>
            <input
              type="file"
              className="form-control"
              onChange={e => {
                this._handleFileUpload(
                  `icon_url_chip=${chip.chip_id}`,
                  e.target.files[0]
                )
              }}
              style={{ padding: "0.54rem 0.75rem" }}
            />
            {chip.icon_url && !this.state[`hide_image_${chip.chip_id}`] ? (
              <div className="d-flex justify-content-between align-items-center bg-light">
                <img
                  src={chip.icon_url}
                  width="50px"
                  height="50px"
                  className="p-2"
                />
                <i
                  className="bx bx bx-trash-alt text-danger p-2"
                  role="button"
                  onClick={() =>
                    this.setState({ [`hide_image_${chip.chip_id}`]: true })
                  }
                />
              </div>
            ) : null}
          </Col>
          <Col sm={12} md={6} lg={6} className="" style={{ display: "none" }}>
            <TextField
              field={`chips_list.${idx}.icon_url`}
              className="creator-form"
              defaultValue={chip.icon_url || ""}
            />
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="d-flex justify-content-end align-items-end"
          >
            <i
              className="bx bx bx-trash-alt bx-sm text-danger p-2"
              role="button"
              onClick={() => this.deleteChip(chip.chip_id)}
            />
          </Col>
        </Row>
      </>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    formData.feed_hashtags = this.state?.feed_hashtags || []

    let finalData = new FormData()

    if (this.state.banner_url) {
      finalData.append("banner_url", this.state.banner_url)
    } else {
      const url = this.state?.data?.banner_url || ""
      if (url) {
        formData.banner_url = url
      }
    }

    if (this.state.theme_bg_image) {
      finalData.append("theme_bg_image", this.state.theme_bg_image)
    } else {
      const url = this.state?.data?.theme_bg_image || ""
      if (url) {
        formData.theme_bg_image = url
      }
    }

    if (this.state["hide_image_theme_bg_image"]) {
      delete formData.theme_bg_image
    }

    if (this.state["hide_image_banner_url"]) {
      delete formData.banner_url
    }

    formData?.banner_list?.forEach(banner => {
      if (this.state[`hide_image_${banner.id}`]) {
        delete banner.thumbnail
      }
    })

    formData?.static_buttons_list?.forEach(button => {
      if (this.state[`hide_image_${button.button_id}`]) {
        delete button.icon_url
      }
    })

    formData?.chips_list?.forEach(chip => {
      if (this.state[`hide_image_${chip.chip_id}`]) {
        delete chip.icon_url
      }
    })

    Object.keys(this.state).forEach(key => {
      if (key.includes("icon_url")) {
        finalData.append(key, this.state[key])
      } else if (key.includes("thumbnail")) {
        finalData.append(key, this.state[key])
      }
    })

    finalData.append("data", JSON.stringify(formData))
    toastr.info("Action in progress...")
    await updateSpinStaticConfig(finalData)
    toastr.success("Action successfull!!")
    location.reload()
  }

  render() {
    return (
      <>
        <div className="bg-white">
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Modal.Header>
              <Modal.Title>
                <CardTitle className="font-size-18 text-wrap">
                  Spin Static Config
                </CardTitle>
              </Modal.Title>
            </Modal.Header>
            <div className="m-2 p-2">
              <Row>
                <Col sm={12} md={6} lg={6} className="">
                  <TextField
                    label={"Text"}
                    field={"text"}
                    className="creator-form"
                    defaultValue={
                      (this.state.data && this.state.data.text) || ""
                    }
                  />
                </Col>
                <Col sm={12} md={6} lg={6} className="">
                  <TextField
                    label={"Banner Height"}
                    field={"banner_height"}
                    inputType="number"
                    className="creator-form"
                    defaultValue={
                      (this.state.data && this.state.data.banner_height) || 0
                    }
                  />
                </Col>
                <Col sm={12} md={6} lg={6} className="">
                  <TextField
                    label={"Banner Width"}
                    field={"banner_width"}
                    inputType="number"
                    className="creator-form"
                    defaultValue={
                      (this.state.data && this.state.data.banner_width) || 0
                    }
                  />
                </Col>
                <Col sm="12" md="6" lg={6}>
                  <label className="me-1" htmlFor="radioVertical">
                    Hashtags
                  </label>
                  <br></br>
                  <ChipInput
                    value={this.state.feed_hashtags}
                    onAdd={chip => this.addChip(chip)}
                    onDelete={(chip, index) => this.removeChip(chip, index)}
                  />
                </Col>
                <Col sm={12} md={6} lg={6} className="">
                  <label
                    className="col-form-label"
                    style={{ paddingTop: "0px" }}
                  >
                    Theme Background Image
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      this._handleFileUpload(
                        "theme_bg_image",
                        e.target.files[0]
                      )
                      this.setState({
                        [`hide_image_theme_bg_image`]: true,
                      })
                    }}
                  />
                  {this.state.data.theme_bg_image &&
                  !this.state[`hide_image_theme_bg_image`] ? (
                    <div className="d-flex justify-content-between align-items-center bg-light">
                      <img
                        src={this.state.data.theme_bg_image}
                        width="50px"
                        height="50px"
                        className="p-2"
                      />
                      <i
                        className="bx bx bx-trash-alt text-danger p-2"
                        role="button"
                        onClick={() =>
                          this.setState({
                            [`hide_image_theme_bg_image`]: true,
                          })
                        }
                      />
                    </div>
                  ) : null}
                </Col>

                <Col sm={12} md={6} lg={6} className="">
                  <label
                    className="col-form-label"
                    style={{ paddingTop: "0px" }}
                  >
                    Banner URL
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={e => {
                      this._handleFileUpload("banner_url", e.target.files[0])
                      this.setState({
                        [`hide_image_banner_url`]: true,
                      })
                    }}
                  />
                  {this.state.data.banner_url &&
                  !this.state[`hide_image_banner_url`] ? (
                    <div className="d-flex justify-content-between align-items-center bg-light">
                      <img
                        src={this.state.data.banner_url}
                        width="50px"
                        height="50px"
                        className="p-2"
                      />
                      <i
                        className="bx bx bx-trash-alt text-danger p-2"
                        role="button"
                        onClick={() =>
                          this.setState({
                            [`hide_image_banner_url`]: true,
                          })
                        }
                      />
                    </div>
                  ) : null}
                </Col>
              </Row>
              <div className="mt-2 mb-2 font-size-18 text-center text-muted border-top">
                Banner List
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  color="outline-primary"
                  className="btn-sm font-weight-semibold w-xs font-size-12"
                  onClick={() => {
                    this.setState(prev_state => ({
                      banner_list: [
                        ...prev_state.banner_list,
                        {
                          id: uuidv4(),
                        },
                      ],
                    }))
                  }}
                >
                  <i className="fa fa-plus mx-1 my-1" /> Banner
                </Button>
              </div>
              {this.state.banner_list && this.state.banner_list.length > 0 ? (
                this.state.banner_list.map((banner, idx) => {
                  return this._getBannerForm(banner, idx)
                })
              ) : (
                <div
                  className="d-flex justify-content-center m-5 text-muted"
                  style={{ fontStyle: "italic" }}
                >
                  No Banner present.
                </div>
              )}
              <div className="mt-2 mb-2 font-size-18 text-center text-muted border-top">
                Static Buttons List
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  color="outline-primary"
                  className="btn-sm font-weight-semibold w-xs font-size-12"
                  onClick={() => {
                    this.setState(prev_state => ({
                      static_buttons_list: [
                        ...prev_state.static_buttons_list,
                        {
                          button_id: uuidv4(),
                        },
                      ],
                    }))
                  }}
                >
                  <i className="fa fa-plus mx-1 my-1" /> Button
                </Button>
              </div>
              {this.state.static_buttons_list &&
              this.state.static_buttons_list.length > 0 ? (
                this.state.static_buttons_list.map((button, idx) => {
                  return this._getButtonForm(button, idx)
                })
              ) : (
                <div
                  className="d-flex justify-content-center m-5 text-muted"
                  style={{ fontStyle: "italic" }}
                >
                  No Button present.
                </div>
              )}

              <div className="mt-2 mb-2 font-size-18 text-center text-muted border-top">
                Chips List
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  color="outline-primary"
                  className="btn-sm font-weight-semibold w-xs font-size-12"
                  onClick={() => {
                    this.setState(prev_state => ({
                      chips_list: [
                        ...prev_state.chips_list,
                        {
                          chip_id: uuidv4(),
                        },
                      ],
                    }))
                  }}
                >
                  <i className="fa fa-plus mx-1 my-1" /> Chip
                </Button>
              </div>
              {this.state.chips_list && this.state.chips_list.length > 0 ? (
                this.state.chips_list.map((chip, idx) => {
                  return this._getChipForm(chip, idx)
                })
              ) : (
                <div
                  className="d-flex justify-content-center m-5 text-muted"
                  style={{ fontStyle: "italic" }}
                >
                  No Chip present.
                </div>
              )}
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "10px" }}
            >
              <Button htmlType="submit" color="primary" className="m-2">
                Submit
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "spin.loading"),
    data: _.get(store, "spin.data"),
    refetch: _.get(store, "spin.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(SpinStaticConfig)
