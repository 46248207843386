import { Player } from "@lottiefiles/react-lottie-player"
import React, { useState } from "react"
// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const color = ["warning", "primary", "success", "info"][
    Math.floor(Math.random() * 3)
  ]

  return (
    <React.Fragment>
      <div style={{ display: "flex", margin: "auto 5px" }}>
        <span style={{ fontSize: "15px" }}>Verified </span>
        <Player
          autoplay={true}
          loop={true}
          controls={true}
          src={
            props.currentUser.otpVerified
              ? "https://assets6.lottiefiles.com/packages/lf20_9tgeV6Gyat.json"
              : "https://assets4.lottiefiles.com/packages/lf20_tvjnk4yn.json"
          }
          style={{ width: 20, height: 20 }}
        ></Player>
      </div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="d-flex align-items-center">
            <div className="avatar-sm">
              <span
                className={
                  "avatar-title rounded-circle bg-soft bg-" +
                  color +
                  " text-" +
                  color +
                  " font-size-18"
                }
                alt="Header Avatar"
              >
                {props.currentUser.name.charAt(0).toUpperCase()}
              </span>
            </div>
            <div className="d-none d-xl-inline-block ms-2 me-1">
              {props.currentUser.name}
            </div>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            <Link to="/user/change-password" className="text-dark">
              <i className="bx bx-lock-open font-size-16 align-middle me-1" />
              <span>{"Change Password"}</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/user/verify" className="text-dark">
              <i className="bx bx-comment-check font-size-16 align-middle me-1" />
              <span
                onClick={() => {
                  const location = { ...window.location }
                  window.prev_location = location
                }}
              >
                {"Verify User"}
              </span>
            </Link>
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/api/auth/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{"Logout"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

const mapStatetoProps = store => {
  return {
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default withRouter(connect(mapStatetoProps, {})(ProfileMenu))
