import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ErrorBoundComponent from "../ErrorBoundComponent"
import { Menu } from "antd"
import { CheckCircleFilled } from "@ant-design/icons"
import "./TaxonomySideBar.css"

class TaxonomySideBar extends ErrorBoundComponent {
  menuClick = event => {
    this.props.setSelectedSection(event)
  }

  getMenuNode = menuItem => {
    return (
      <Menu.Item
        key={menuItem.key}
        style={{
          marginTop: "0px",
          marginBottom: "0px",
        }}
      >
        <div className="d-flex">
          <span
            style={{
              color: menuItem.completed ? "#28a745" : "grey",
              marginTop: "-.1rem",
            }}
            className="d-block"
          >
            <CheckCircleFilled />
          </span>
          <span className="mx-1">{menuItem.title}</span>
        </div>
      </Menu.Item>
    )
  }

  getMenuItems = () => {
    let menuItems = []
    this.props.taxonomyRoutes.map(menuItem => {
      if (menuItem.showInSidebar) {
        let menuNode = this.getMenuNode(menuItem)
        if (menuNode) {
          menuItems.push(menuNode)
        }
      }
    })
    return menuItems
  }
  getMenuItemsS1 = () => {
    let menuItems = []
    this.props.taxonomyRoutes.map(menuItem => {
      if (menuItem.showInSidebar && menuItem.stage == 1) {
        let menuNode = this.getMenuNode(menuItem)
        if (menuNode) {
          menuItems.push(menuNode)
        }
      }
    })
    return menuItems
  }
  getMenuItemsS2 = () => {
    let menuItems = []
    this.props.taxonomyRoutes.map(menuItem => {
      if (menuItem.showInSidebar && menuItem.stage == 2) {
        let menuNode = this.getMenuNode(menuItem)
        if (menuNode) {
          menuItems.push(menuNode)
        }
      }
    })
    return menuItems
  }
  getMenuItemsS3 = () => {
    let menuItems = []
    this.props.taxonomyRoutes.map(menuItem => {
      if (menuItem.showInSidebar && menuItem.stage == 3) {
        let menuNode = this.getMenuNode(menuItem)
        if (menuNode) {
          menuItems.push(menuNode)
        }
      }
    })
    return menuItems
  }

  render() {
    return (
      <div className="logo wd100">
        {this.props.stageShow ? (
          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            selectedKeys={[this.props.selectedSection]}
            onClick={e => this.menuClick(e.key)}
          >
            <Menu.ItemGroup key="stage1" title="Stage 1">
              {this.getMenuItemsS1().map(menuItem => menuItem)}
            </Menu.ItemGroup>
            {this.getMenuItemsS2().length != 0 && (
              <Menu.ItemGroup key="stage2" title="Stage 2">
                {this.getMenuItemsS2().map(menuItem => menuItem)}
              </Menu.ItemGroup>
            )}
            {this.getMenuItemsS3().length != 0 && (
              <Menu.ItemGroup key="stage3" title="Stage 3">
                {this.getMenuItemsS3().map(menuItem => menuItem)}
              </Menu.ItemGroup>
            )}
            {this.props.selectedSection === "zone_approval" ||
            this.props.selectedSection === "moderation" ? (
              ""
            ) : (
              <Menu.ItemGroup key="data" title="Selected Data"></Menu.ItemGroup>
            )}
          </Menu>
        ) : (
          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            selectedKeys={[this.props.selectedSection]}
            onClick={e => this.menuClick(e.key)}
          >
            <Menu.ItemGroup key="Video" title="Video">
              {this.getMenuItems().map(menuItem => menuItem)}
            </Menu.ItemGroup>
            {this.props.selectedSection === "zone_approval" ||
            this.props.selectedSection === "moderation" ? (
              ""
            ) : (
              <Menu.ItemGroup key="data" title="Selected Data"></Menu.ItemGroup>
            )}
          </Menu>
        )}
      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(TaxonomySideBar)
