import { createSlice } from "@reduxjs/toolkit"

const iconsSlice = createSlice({
  name: "icons",
  initialState: {
    loading: false,
    refetchData: false,
    themeData: [],
    iconsIds: [],
  },

  reducers: {
    getAllThemes: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    getAllThemesSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        themeData: payload.data,
        refetchData: false,
      }
    },

    updateIcon: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    updateIconSuccess: state => {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },

    getIconIds: state => {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },

    getIconIdsSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        iconsIds: payload.data || [],
        refetchData: false,
      }
    },

    resetState: state => {
      return {
        ...state,
        themeData: [],
        refetchData: false,
      }
    },
  },
})

export const {
  getAllThemes,
  getAllThemesSuccess,
  getIconIds,
  getIconIdsSuccess,
  updateIcon,
  updateIconSuccess,
  resetState,
} = iconsSlice.actions

export default iconsSlice.reducer
