import React from "react"
import EmbedContainer from "react-oembed-container"

export default class EmbedSocialContainer extends React.Component {
  render() {
    return (
      <EmbedContainer markup={this.props.content.blockquote_text}>
        <article key={this.props.content.content_uuid}>
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.content.blockquote_text,
            }}
          />
        </article>
      </EmbedContainer>
    )
  }
}
