import React from "react"
import { connect } from "react-redux"
import { Card, CardBody, Col, Table, Badge, Row, CardImg } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip, Popconfirm } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import CustomScrollbars from "util/CustomScrollbars"
import ReactCardFlip from "react-card-flip"
import ReactTimeAgo from "react-time-ago"
import { getImage } from "../../components/SearchAndSelect/util"
import { removePageEntity } from "../../appRedux/slices/zeroSearch"
// import "../../components/Discovery/"
// import "./pageStyle.css"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"

class PackageCard extends React.Component {
  render() {
    const { data } = this.props

    const image = data
      ? data.package_thumbnail_url || data.sale_thumbnail_url
      : undefined

    const title = data ? data.title || data.collection_name : undefined

    return (
      <React.Fragment>
        <Card
          onClick={() =>
            this.props.isCardClickEnable
              ? this.props.isPackageCollection
                ? this.props.onCardClick(data)
                : this.props.onEditClick(data)
              : {}
          }
          className={`${
            this.props.customClassname || ""
          } text-center shadow-lg`}
          style={{
            minHeight: "250px",
            borderRadius: "10px",
          }}
          key={
            data?.package_element_uuid || data?.package_collection_uuid || ""
          }
        >
          <div className="bg-primary bg-soft">
            <Row>
              {image ? (
                <div
                  id={"play" + data.package_element_uuid}
                  style={{ height: "15rem" }}
                >
                  <CardImg
                    top
                    className="img-fluid"
                    src={image}
                    alt="Skote"
                    // onClick={e => {
                    //   e.preventDefault()
                    //   e.stopPropagation()
                    // }}
                    style={{
                      height: "15rem",
                      cursor: "pointer",
                      opacity: "0.8",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                </div>
              ) : (
                <div
                  className="py-5 px-0"
                  style={{
                    height: "15rem",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <i className={`fas fa-gift fa-7x py-4 text-secondary`} />
                </div>
              )}
            </Row>
          </div>
          <CardBody style={{ padding: "1rem 0.25rem" }}>
            <Tooltip title={title} style={{ minHeight: "20px" }}>
              <div className="font-size-16 text-truncate font-weight-semibold">
                <i className={`mx-1`} /> {title}
              </div>
            </Tooltip>

            <div style={{ minHeight: "30px" }}>
              {data.status ? (
                <Tooltip title="Status" style={{ minHeight: "20px" }}>
                  <Badge
                    className={`${"badge-soft-warning"} ms-1 font-size-13 my-2 text-black me-1`}
                  >
                    {data.status}
                  </Badge>
                </Tooltip>
              ) : null}
              {data.orig_price ? (
                <Tooltip title="Orginal Price" style={{ minHeight: "20px" }}>
                  <Badge
                    className={`${"badge-soft-warning"} ms-1 font-size-13 my-2 text-black me-1`}
                  >
                    <b>Original Price: </b>
                    {data.orig_price}
                  </Badge>
                </Tooltip>
              ) : null}
              {data.discount_percentage ? (
                <Tooltip
                  title="Discounted Percentage"
                  style={{ minHeight: "20px" }}
                >
                  <Badge
                    className={`${"badge-soft-info"} ms-1 font-size-13 my-2 text-black me-1`}
                  >
                    <b>Discounted Percentage: </b>
                    {data.discount_percentage} %
                  </Badge>
                </Tooltip>
              ) : null}
              {data.discounted_price ? (
                <Tooltip title="Discounted Price" style={{ minHeight: "20px" }}>
                  <Badge
                    className={`${"badge-soft-success"} ms-1 font-size-13 my-2 text-black me-1`}
                  >
                    <b>Discounted Price: </b>
                    {data.discounted_price}
                  </Badge>
                </Tooltip>
              ) : null}
              {data.jems_count ? (
                <Tooltip title="Jems" style={{ minHeight: "20px" }}>
                  <Badge
                    className={`${"badge-soft-primary"} ms-1 font-size-13 my-2 text-black me-1`}
                  >
                    <b>Jems: </b>
                    {data.jems_count}
                  </Badge>
                </Tooltip>
              ) : null}
              {data.currency ? (
                <Tooltip title="Currency" style={{ minHeight: "20px" }}>
                  <Badge
                    className={`${"badge-soft-danger"} ms-1 font-size-13 my-2 text-black me-1`}
                  >
                    <b>Currency: </b>
                    {data.currency}
                  </Badge>
                </Tooltip>
              ) : null}
              {data.priority_order ? (
                <Tooltip title="Priority Order" style={{ minHeight: "20px" }}>
                  <Badge
                    className={`${"badge-soft-danger"} ms-1 font-size-13 my-2 text-black me-1`}
                  >
                    <b>Priority Order: </b>
                    {data.priority_order}
                  </Badge>
                </Tooltip>
              ) : null}
              {data.purchase_allowed ? (
                <Tooltip title="Purchase Allowed" style={{ minHeight: "20px" }}>
                  <Badge
                    className={`${"badge-soft-warning"} ms-1 font-size-13 my-2 text-black me-1`}
                  >
                    <b>Purchase Allowed: </b>
                    {data.purchase_allowed}
                  </Badge>
                </Tooltip>
              ) : null}
            </div>

            {data.tag ? (
              <div style={{ minHeight: "30px" }} className="mt-2">
                <Tooltip title="Tag" style={{ minHeight: "20px" }}>
                  <b>Tags: </b>
                  {data.tag.title && (
                    <Badge
                      className={`${"badge-soft-secondary"} ms-1 font-size-13 my-2 text-black me-1`}
                    >
                      {data.tag.title}
                    </Badge>
                  )}
                  {data.tag.colour && (
                    <Badge
                      className={`${"badge-soft-secondary"} ms-1 font-size-13 my-2 text-black me-1`}
                    >
                      {data.tag.colour}
                    </Badge>
                  )}
                </Tooltip>
              </div>
            ) : null}

            {data.start_time || data.end_time ? (
              <div style={{ minHeight: "30px" }} className="mt-2">
                {data.start_time && (
                  <Tooltip title="Start Time">
                    <Badge className="badge-soft-success ms-1 font-size-13 text-black">
                      <ReactTimeAgo date={data.start_time} locale="en-US" />
                    </Badge>
                  </Tooltip>
                )}
                {data.end_time && (
                  <Tooltip title="End Time">
                    <Badge className="badge-soft-success ms-1 font-size-13 text-black">
                      <ReactTimeAgo date={data.end_time} locale="en-US" />
                    </Badge>
                  </Tooltip>
                )}
              </div>
            ) : null}

            {data.created_date ||
            data.entity_created_date ||
            data.modified_date ||
            data.entity_modified_date ? (
              <div style={{ minHeight: "30px" }} className="mt-2">
                {(data.created_date || data.entity_created_date) && (
                  <Tooltip title="Created Date">
                    <Badge className="badge-soft-danger ms-1 font-size-13 text-black">
                      <ReactTimeAgo
                        date={data.entity_created_date || data.created_date}
                        locale="en-US"
                      />
                    </Badge>
                  </Tooltip>
                )}
                {(data.modified_date || data.entity_modified_date) && (
                  <Tooltip title="Modified Date">
                    <Badge className="badge-soft-danger ms-1 font-size-13 text-black">
                      <ReactTimeAgo
                        date={data.entity_modified_date || data.modified_date}
                        locale="en-US"
                      />
                    </Badge>
                  </Tooltip>
                )}
              </div>
            ) : null}
          </CardBody>
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={
                  data?.package_entity_uuid ||
                  data?.package_element_uuid ||
                  data?.package_collection_uuid
                }
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy ID">
                  <i className="bx bx-copy-alt" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>

            <div className="flex-fill">
              <Tooltip title="Edit">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_PACKAGE"
                      ) &&
                      this.props?.location?.pathname.split("/")[1] === "package"
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.onEditClick(data)
                  }}
                />
              </Tooltip>
            </div>

            {this.props.isDeleteEnable ? (
              <div className="flex-fill">
                <Tooltip title="Delete">
                  <i
                    className="bx bx-trash"
                    role="button"
                    onClick={e => {
                      if (
                        !this.props.currentUser?.permissions?.includes(
                          "EDIT_PACKAGE"
                        ) &&
                        this.props?.location?.pathname.split("/")[1] ===
                          "package"
                      ) {
                        toastr.error(NO_PERMISSION_MESSAGE)
                        return
                      }
                      this.props.onDeleteClick(data)
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
          </div>
        </Card>
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    refetch: _.get(store, "package.refetchData"),
  }
}

export default connect(mapStateToProps)(PackageCard)
