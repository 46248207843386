import Axios from "axios"
import toastr from "toastr"

export const requestCMSVideoTemplateListByFilters = async ({ payload }) => {
  try {
    let data = await Axios.post(
      "/api/cmsVideoTemplates/getCMSVideoTemplatesByFilter",
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestCMSVideoGreetingsListByFilters = async ({ payload }) => {
  try {
    let data = await Axios.post(
      "/api/cmsVideoTemplates/getCMSVideoGreetingsByFilter",
      payload
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertCMSVideoTemplate = async ({ payload }) => {
  try {
    let data = await Axios.post(
      "/api/cmsVideoTemplates/upsertCMSVideoTemplates",
      payload
    )
    toastr.success("Updated successfully!!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertCMSVideoGreetings = async ({ payload }) => {
  try {
    let data = await Axios.post(
      "/api/cmsVideoTemplates/upsertCMSVideoGreetings",
      payload
    )
    toastr.success("Updated successfully!!")
    return data
  } catch (error) {
    throw error
  }
}
