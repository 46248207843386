import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"

const { Option } = Select

const CameraAssetListDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Combo Id</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["comboId"]}
                  placeholder={"Combo Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "comboId")
                  }
                  allowClear={true}
                  name="comboId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="4" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Title</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["title"]}
                  placeholder={"Title"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "title")
                  }
                  allowClear={true}
                  name="title"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="8" style={{ marginBottom: "5px" }}>
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">
              Order By Created Date
            </h5>
          </Divider>
          <Input.Group compact={true}>
            <Select
              allowClear={true}
              defaultValue={props.filters["order"]}
              placeholder="Order"
              onChange={value => {
                props.handleFilterChange(value, "order")
              }}
              style={{ width: "50%" }}
            >
              <Option value="asc">Ascending</Option>
              <Option value="desc">Descending</Option>
            </Select>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(CameraAssetListDrawer)
