import React from "react"
import { connect } from "react-redux"
import _, { values } from "lodash"
import Questionnaire from "./questionnaire"
import QuestionCard from "./questionCard"
import ImageCard from "./imageCard"

import { cloneDeep } from "lodash"
import { Col, Radio, Row } from "antd"

import { Button } from "reactstrap"
const { Group } = Radio

import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"

const AUDIO_VULGARITY_OPTIONS = [
  {
    label: "Non Abusive",
    level: 1,
    key: "is_audio_vulgar",
    taxonomyJsonKey: "taxonomy_video_quality",
    dependentValue: true,
  },
  {
    label: "Friendly Abuse",
    parent: "is_audio_vulgar",
    level: 2,
    key: "friendly_abuse",
    taxonomyJsonKey: "taxonomy_video_quality",
    dependentValue: true,
  },
  {
    label: "Political Abuse",
    parent: "is_audio_vulgar",
    taxonomyJsonKey: "taxonomy_video_quality",
    level: 2,
    key: "polictical_abuse",
    dependentValue: true,
  },
  {
    label: "Religious Abuse",
    parent: "is_audio_vulgar",
    taxonomyJsonKey: "taxonomy_video_quality",
    level: 2,
    key: "religious_abuse",
    dependentValue: true,
  },
]
class AudioVulgariy extends Questionnaire {
  state = {
    currentTab: "audioVulgarity",
    labelToDisplay: "Audio Vulgarity",
    showOptions:
      this.props.selectedDetails["taxonomy_video_quality"]["audio_present"] ==
      undefined
        ? undefined
        : this.props.selectedDetails["taxonomy_video_quality"]["audio_present"]
        ? true
        : false,
  }

  _handleClick = value => {
    if (value) {
      this.setState({ showOptions: true }, () => {
        this.props.selectedDetails["taxonomy_video_quality"][
          "audio_present"
        ] = true
        this.props.setSelectedDetails(this.props.selectedDetails)
      })
    } else {
      this.setState({ showOptions: false }, () => {
        this._handleSelection("audio_present")
        this.props.selectedDetails["taxonomy_video_quality"][
          "audio_present"
        ] = false
        this.props.setSelectedDetails(this.props.selectedDetails)
        this.props.taxonomyRoutes
        this.props.setSelectedSection("glamour")
      })
    }
  }

  _handleSelection = value => {
    const currentData = cloneDeep(this.props.currentData)
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    const indexTab = taxonomyRoutes.findIndex(
      item => item.key == "audio_vulgarity"
    )
    let audioData = [
      "is_audio_vulgar",
      "friendly_abuse",
      "polictical_abuse",
      "religious_abuse",
    ]

    if (value === "audio_present") {
      selectedDetails["taxonomy_video_quality"]["audio_present"] = false
      audioData.forEach(item => {
        selectedDetails["taxonomy_video_quality"][item] = false
      })
    } else if (value === "is_audio_vulgar") {
      audioData.forEach(item => {
        selectedDetails["taxonomy_video_quality"][item] = false
      })
    } else {
      selectedDetails["taxonomy_video_quality"]["audio_present"] = true
      selectedDetails["taxonomy_video_quality"][value] = true
      audioData.forEach(item => {
        if (item !== value) {
          selectedDetails["taxonomy_video_quality"][item] = false
        }
      })
      selectedDetails["taxonomy_video_quality"]["is_audio_vulgar"] = true
    }

    let stepCompleted = true

    if (stepCompleted) {
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    }
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )
    this.setState({
      currentData,
      ignore: false,
      tag: false,
      ignoreRatingLessThan4: false,
      retag: false,
    })

    this.props.setSelectedDetails(selectedDetails)
  }
  _renderTitle = () => {
    return <div className="font-weight-semibold pt-3">Audio Vulgarity</div>
  }

  _renderOptions = () => {
    return (
      <React.Fragment>
        <QuestionCard
          question={{
            label: "Is audio present?",
            level: "L1",
            key: "is_audio_presentr",
            sublevel: [
              {
                label: "Yes",
                key: "simple",
                value: true,
              },
              {
                label: "No",
                key: "simple",
                value: false,
              },
            ],
            partialSelected: this.state.showOptions,
          }}
          renderCardContent={() => {}}
          _handleSelection={e => this._handleClick(e.target.value)}
          partialSelected={this.state.showOptions}
        />
      </React.Fragment>
    )
  }

  _renderOptionCard = () => {
    return (
      <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
        {AUDIO_VULGARITY_OPTIONS.map(data => {
          let audioData = data
          if (
            data.key === "is_audio_vulgar" &&
            this.props.selectedDetails.taxonomy_video_quality &&
            this.props.selectedDetails.taxonomy_video_quality.audio_present &&
            !this.props.selectedDetails.taxonomy_video_quality.friendly_abuse &&
            !this.props.selectedDetails.taxonomy_video_quality
              .polictical_abuse &&
            !this.props.selectedDetails.taxonomy_video_quality.religious_abuse
          ) {
            audioData.selected = true
          } else if (
            data.key !== "is_audio_vulgar" &&
            this.props.selectedDetails.taxonomy_video_quality[data.key]
          ) {
            audioData.selected = true
          } else {
            audioData.selected = false
          }
          return (
            <ImageCard
              data={data}
              _handleSelection={data => this._handleSelection(data.key)}
              renderCardContent={this.props.renderCardContent}
            />
          )
        })}
      </div>
    )
  }

  _renderAudioOptions = () => {
    return (
      <div className="d-flex">
        {
          <Row
            className={`d-flex question-card text-center contentCard my-5 justify-content-around`}
          >
            <Col span={24} className="my-3">
              What kind of audio vulgarity is diplayed in the video?
            </Col>
            <Col span={24} className="d-flex flex-wrap mx-4">
              <Group
                style={{ display: "contents" }}
                onChange={e => this._handleSelection(e.target.value)}
                value={
                  this.props.selectedDetails.taxonomy_video_quality
                    .is_audio_vulgar
                    ? "is_audio_vulgar"
                    : this.props.selectedDetails.taxonomy_video_quality
                        .friendly_abuse
                    ? "friendly_abuse"
                    : this.props.selectedDetails.taxonomy_video_quality
                        .polictical_abuse
                    ? "polictical_abuse"
                    : this.props.selectedDetails.taxonomy_video_quality
                        .religious_abuse
                    ? "religious_abuse"
                    : ""
                }
              >
                <div className="topicRadioBtn">
                  <Radio value="is_audio_vulgar">Non Abusive</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="friendly_abuse">Friendly Abuse</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="polictical_abuse">Political Abuse</Radio>
                </div>
                <div className="topicRadioBtn">
                  <Radio value="religious_abuse">Religious Abuse</Radio>
                </div>
              </Group>
            </Col>
          </Row>
        }
      </div>
    )
  }
  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderTitle()}
        {this._renderOptions()}
        {this.state.showOptions && this._renderOptionCard()}

        {/* {this.state.showOptions && this._renderAudioOptions()} */}
        {/* {this._renderQuestions()} */}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    currentData: _.get(store, "taxonomy.audioVulgarity"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(AudioVulgariy)
