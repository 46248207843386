import React from "react"
import { connect } from "react-redux"
import Auxiliary from "../../util/Auxiliary"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getSpinUserStats } from "../../clientServices/spinConfigService"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  Badge,
  Card,
  Col,
  Row,
  CardBody,
  InputGroup,
  FormGroup,
} from "reactstrap"
import { Input, Select, DatePicker, List, Tooltip, Tag, Pagination } from "antd"
import _ from "lodash"
import moment from "moment"
import toastr from "toastr"
import dayjs from "dayjs"
const { Option } = Select

class SpinUserStats extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      statsUserList: [],
      offset: 1,
      limit: 10,
      total: 1,
      //   filters: { orderBy: "", type: "", fromDate: null, toDate: null },
      filters: { type: "", fromDate: null, toDate: null },

      columns: [
        {
          dataField: "transactionId",
          text: "Transaction Id",
          style: {
            width: "25%",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>
                  {cellContent || "-"}
                  <CopyToClipboard
                    text={cellContent || ""}
                    onCopy={e => {
                      toastr.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy Transaction Id">
                      <i className="bx bx-copy me-2 ms-2" role="button" />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              </div>
            )
          },
        },
        {
          dataField: "transactionBy",
          text: "Transaction By",
          style: {
            width: "25%",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column">
                <div>
                  {cellContent || "-"}
                  <CopyToClipboard
                    text={cellContent || ""}
                    onCopy={e => {
                      toastr.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy Transaction By">
                      <i className="bx bx-copy me-2 ms-2" role="button" />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              </div>
            )
          },
        },
        {
          dataField: "type",
          text: "Type",
          style: {
            width: "25%",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>{cellContent || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "modifiedOn",
          text: "Modified On",
          style: {
            width: "25%",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>{cellContent || "-"}</div>
              </div>
            )
          },
        },
      ],
    }
  }

  componentDidMount = async () => {
    if (!this.props.common.error) {
      //   this.setState({ loading: true })
      //   const data =
      //     (await getSpinUserStats(
      //       _.deepClean({ filters: this.state.filters }),
      //       this.state.offset,
      //       this.state.limit
      //     )) || {}
      //   const statsUserList = data?.data || []
      //   this.setState({ loading: false, statsUserList, total: data?.count || 1 })
      await this._onSearch()
    }
  }

  _onSearch = async () => {
    this.setState({ loading: true })
    const data =
      (await getSpinUserStats(
        _.deepClean({ filters: this.state.filters }),
        this.state.offset,
        this.state.limit
      )) || {}
    const statsUserList = data?.data || []
    this.setState({ loading: false, statsUserList, total: data?.count || 1 })
  }

  _onPageChange = async value => {
    this.setState({ offset: value }, async () => {
      await this._onSearch()
    })
  }

  _filters = () => {
    return (
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row className="row gy-2 gx-3 align-items-center">
                {/* <Col md="4" lg="3">
                  <Input
                    placeholder="Transaction By"
                    onChange={e =>
                      this.setState({
                        filters: { ...this.state.filters, orderBy: e.target.value },
                      })
                    }
                    allowClear
                    value={this.state.filters.orderBy || ""}
                    style={{ width: "100%" }}
                  />
                  <Select
                    allowClear={true}
                    style={{ width: "100%" }}
                    value={this.state.filters.type || ""}
                    onChange={e =>
                      this.setState({ filters: { ...this.state.filters, type: e } })
                    }
                    placeholder="Type"
                  >
                    <Option value=""></Option>
                    <Option value="transactionId">Transaction Id</Option>
                    <Option value="PURCHASE">PURCHASE</Option>
                    <Option value="PURCHASE">PURCHASE</Option>
                    <Option value="PURCHASE">PURCHASE</Option>
                  </Select>
                </Col> */}
                <Col md="6" lg="4">
                  <Select
                    allowClear={true}
                    style={{ width: "100%" }}
                    value={this.state.filters.type || ""}
                    onChange={e =>
                      this.setState({
                        filters: { ...this.state.filters, type: e },
                      })
                    }
                    placeholder="Type"
                  >
                    <Option value="">All</Option>
                    <Option value="SPIN_WHEEL">SPIN_WHEEL</Option>
                    <Option value="PURCHASE">PURCHASE</Option>
                    <Option value="SYSTEM_GIFTS_RECEIVED">
                      SYSTEM_GIFTS_RECEIVED
                    </Option>
                  </Select>
                </Col>
                <Col lg="4" md="6">
                  <FormGroup>
                    <InputGroup>
                      <DatePicker
                        value={
                          this.state.filters.fromDate
                            ? dayjs.unix(
                                this.state.filters.fromDate / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        style={{ width: "50%" }}
                        showTime
                        placeholder="From Date"
                        onChange={(date, dateString) =>
                          this.setState({
                            filters: {
                              ...this.state.filters,
                              fromDate: !_.isEmpty(dateString)
                                ? moment(dateString).format("x")
                                : null,
                            },
                          })
                        }
                      />
                      <DatePicker
                        value={
                          this.state.filters.toDate
                            ? dayjs.unix(
                                this.state.filters.toDate / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        style={{ width: "50%" }}
                        placeholder="To Date"
                        onChange={(date, dateString) =>
                          this.setState({
                            filters: {
                              ...this.state.filters,
                              toDate: !_.isEmpty(dateString)
                                ? moment(dateString).format("x")
                                : null,
                            },
                          })
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="4" md="12" className="d-flex justify-content-end">
                  <button
                    onClick={this._onSearch}
                    role="button"
                    className="btn btn-success d-flex align-items-center"
                  >
                    <div className="mx-2">Apply</div>
                    <span>
                      <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
                    </span>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  render() {
    const defaultSorted = [
      {
        dataField: "label",
        order: "asc",
      },
    ]

    return (
      <div className="bg-white">
        <Auxiliary
          loading={this.state.loading}
          error={_.get(this.props, "common.error")}
        >
          <div>{this._filters()}</div>
          {this.state?.statsUserList &&
          this.state?.statsUserList?.length > 0 ? (
            <div className="m-2 p-2">
              <ToolkitProvider
                keyField="id"
                data={this.state.statsUserList || []}
                columns={this.state.columns}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <div className="table-responsive">
                      <BootstrapTable
                        {...toolkitProps.baseProps}
                        // {...paginationTableProps}
                        responsive
                        //   defaultSorted={defaultSorted}
                        bordered={false}
                        striped={false}
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap table-check"}
                        headerWrapperClasses={"table-light"}
                      />
                    </div>
                  </React.Fragment>
                )}
              </ToolkitProvider>
              <div className="d-flex justify-content-end">
                <Pagination
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                  onChange={this._onPageChange}
                  defaultCurrent={this.state.offset}
                  total={this.state?.total < 10000 ? this.state.total : 10000}
                  pageSize={10}
                  showSizeChanger={false}
                />
              </div>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center hero-section text-muted"
              style={{ fontStyle: "italic" }}
            >
              No data present.
            </div>
          )}
        </Auxiliary>
      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "spin.loading"),
    spinStats: _.get(store, "spin.spinStats"),
    refetch: _.get(store, "spin.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(SpinUserStats)
