import React from "react"
import { Card, CardBody, CardFooter, Table } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "./styles.css"

class CampaignCard extends React.Component {
  render() {
    const { campaign } = this.props
    let horizontalData = {}
    let horizontal = []
    let verticalData = {}
    let vertical = []
    const { logo_meta = [] } = campaign
    horizontal =
      logo_meta && logo_meta.filter(data => data.type === "HORIZONTAL")
    vertical = logo_meta && logo_meta.filter(data => data.type === "VERTICAL")
    horizontalData =
      horizontal && horizontal.length > 0 && horizontal[0]["cta"]
        ? horizontal[0]["cta"]
        : {}
    verticalData =
      vertical && vertical.length > 0 && vertical[0]["cta"]
        ? vertical[0]["cta"]
        : {}

    return (
      <React.Fragment>
        <Card className="text-center shadow-sm" style={{ minHeight: "280px" }}>
          <div className="py-3 font-size-20">{campaign.campaign_name}</div>
          <CardBody>
            <div className="table-responsive">
              <Table className=" mb-0 table-striped">
                <tbody>
                  {/* <tr className="text-start">
                                        <th scope="row">Name:</th>
                                        <td>{campaign.campaign_name || "-"}</td>
                                    </tr> */}
                  <tr className="text-start">
                    <th scope="row">Brand Id:</th>
                    <td>{campaign.brand_uuid || "-"}</td>
                  </tr>
                </tbody>
              </Table>
              <div
                className="font-weight-semibold py-2"
                style={{ fontSize: "15px" }}
              >
                Horizontal
              </div>
              <Table className=" mb-0 table-striped">
                <tbody>
                  <tr className="text-start">
                    <th scope="row">Label:</th>
                    <td>{horizontalData.label || "-"}</td>
                  </tr>

                  <tr className="text-start">
                    <th scope="row">Deep Link:</th>
                    <td className="flex">
                      <div
                        className="ellipsis"
                        title={horizontalData.deep_link || "-"}
                      >
                        {horizontalData.deep_link || "-"}
                        {<span>&nbsp;</span>}{" "}
                      </div>
                      {horizontalData.deep_link && (
                        <CopyToClipboard
                          text={horizontalData?.deep_link}
                          title="Copy Deep Link"
                          onCopy={() => {
                            toastr.success("Copied Successfully")
                          }}
                        >
                          <Tooltip title="Copy Deep Link">
                            <div role="button">
                              <i
                                className="bx bx-copy"
                                style={{
                                  marginTop: "1px",
                                }}
                              />
                            </div>
                          </Tooltip>
                        </CopyToClipboard>
                      )}{" "}
                    </td>
                  </tr>

                  <tr className="text-start">
                    <th scope="row">Show Arrow:</th>
                    <td>
                      {horizontalData.show_arrow ? "True" : "False" || "-"}
                    </td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Animation Type:</th>
                    <td>{horizontalData.animation || "-"}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Logo Url:</th>
                    {/* <td>{horizontalData.logo_url || "-"}</td> */}
                    <td>
                      {" "}
                      {horizontalData.logo_url ? (
                        <div style={{ display: "flex" }}>
                          <img
                            height="35px"
                            width="35px"
                            style={{ marginRight: "15px" }}
                            src={horizontalData.logo_url}
                          ></img>{" "}
                          <CopyToClipboard
                            text={horizontalData.logo_url}
                            title="Copy Logo Url"
                            onCopy={() => {
                              toastr.success("Copied Successfully")
                            }}
                          >
                            <Tooltip title="Copy Logo Url">
                              <div role="button">
                                <i
                                  className="bx bx-copy"
                                  style={{
                                    verticalAlign: "bottom",
                                    marginTop: "12px",
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </CopyToClipboard>{" "}
                        </div>
                      ) : (
                        "-"
                      )}{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div
                className="font-weight-semibold py-2"
                style={{ fontSize: "15px" }}
              >
                Vertical
              </div>
              <Table className=" mb-0 table-striped">
                <tbody>
                  {/* <tr className="text-start">
                    <th scope="row">Label:</th>
                    <td>{verticalData.label || "-"}</td>
                  </tr> */}

                  <tr className="text-start">
                    <th scope="row">Deep Link:</th>
                    <td className="flex">
                      {" "}
                      <div
                        className="ellipsis"
                        title={verticalData.deep_link || "-"}
                      >
                        {verticalData.deep_link || "-"}
                        {<span>&nbsp;</span>}{" "}
                      </div>
                      {verticalData.deep_link && (
                        <CopyToClipboard
                          text={verticalData?.deep_link}
                          title="Copy Deep Link"
                          onCopy={() => {
                            toastr.success("Copied Successfully")
                          }}
                        >
                          <Tooltip title="Copy Deep Link">
                            <div role="button">
                              <i
                                className="bx bx-copy"
                                style={{
                                  marginTop: "1px",
                                }}
                              />
                            </div>
                          </Tooltip>
                        </CopyToClipboard>
                      )}{" "}
                    </td>
                  </tr>

                  <tr className="text-start">
                    <th scope="row">Show Arrow:</th>
                    <td>{verticalData.show_arrow ? "True" : "False" || "-"}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Animation Type:</th>
                    <td>{verticalData.animation || "-"}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Logo Url:</th>
                    <td>
                      {" "}
                      {verticalData.logo_url ? (
                        <div style={{ display: "flex" }}>
                          <img
                            height="35px"
                            width="35px"
                            style={{ marginRight: "15px" }}
                            src={verticalData.logo_url}
                          ></img>{" "}
                          <CopyToClipboard
                            text={verticalData.logo_url}
                            title="Copy Logo Url"
                            onCopy={() => {
                              toastr.success("Copied Successfully")
                            }}
                          >
                            <Tooltip title="Copy Logo Url">
                              <div role="button">
                                <i
                                  className="bx bx-copy"
                                  style={{
                                    verticalAlign: "bottom",
                                    marginTop: "12px",
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </CopyToClipboard>{" "}
                        </div>
                      ) : (
                        "-"
                      )}{" "}
                    </td>
                    {/* <td>{verticalData.logo_url || "-"}</td> */}
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <CopyToClipboard
                  text={campaign.campaign_uuid}
                  title="Copy Campaign Id"
                  onCopy={() => {
                    toastr.success("Copied Successfully")
                  }}
                >
                  <Tooltip title="Copy Source ID">
                    <div role="button">
                      <i className="bx bx-copy" />
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              </div>
              <div className="flex-fill">
                <Tooltip title="Edit Brand Logo">
                  <i
                    className="bx bx-edit-alt"
                    role="button"
                    onClick={() => {
                      this.props.onEditClick("update", campaign)
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </CardFooter>
        </Card>
      </React.Fragment>
    )
  }
}

export default CampaignCard
