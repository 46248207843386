import { Modal } from "antd"
import React from "react"
import { CloseCircleFilled } from "@ant-design/icons"
import "./selectedDataSection.css"

class WarningMessage extends React.Component {
  render() {
    return (
      <Modal
        footer={null}
        bodyStyle={{ padding: "unset", border: "10px solid #ff4d4f" }}
        visible={this.props.isVisible}
        onCancel={() => this.props.onCancel()}
        width={425}
      >
        <div>
          <div
            className="d-flex justify-content-center py-2 warning-modal-title"
            style={{ fontSize: "18px" }}
          >
            Warning!!!
          </div>
          <div className="d-flex p-3" style={{ fontSize: "18px" }}>
            <CloseCircleFilled className="fa-3x" style={{ color: "red" }} />
            {this.props.message}
          </div>
          {this.props.buttons}
        </div>
      </Modal>
    )
  }
}

export default WarningMessage
