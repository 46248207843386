import React from "react"
import { connect } from "react-redux"
import { Modal } from "react-bootstrap"
import { Row, Col, CardTitle, Button } from "reactstrap"
import { v4 as uuidv4 } from "uuid"
import { Divider } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import ValidatorForm, { TextField } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import TargetWiseSpinConfig from "./TargetWiseSpinConfig"
import {
  getCampaignSpinConfig,
  updateCampaignSpinConfig,
} from "../../clientServices/spinConfigService"

class CampaignSpinConfig extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      campaignList: [],
    }
  }

  componentDidMount = async () => {
    const response = await getCampaignSpinConfig()

    this.setState({
      campaignList: response || [],
    })
  }

  setTargetValue = (val, key, campaignId, targetId) => {
    const newCampaignList = [...this.state.campaignList]
    newCampaignList.forEach(campaign => {
      if (campaign.id === campaignId) {
        campaign.target.forEach(targetData => {
          if (targetData.id === targetId) {
            targetData[key] = val
          }
        })
      }
    })

    this.setState({ campaignList: [...newCampaignList] })
  }

  setTargetImage = (file, key, hideKey) => {
    this.setState({ [hideKey]: true, [key]: file })
  }

  hideTargetImage = key => {
    this.setState({ [key]: true })
  }

  setTargetEntityValue = (
    val,
    key,
    entityKey,
    campaignId,
    targetId,
    entityId
  ) => {
    const newCampaignList = [...this.state.campaignList]
    newCampaignList.forEach(campaign => {
      if (campaign.id === campaignId) {
        campaign.target.forEach(targetData => {
          if (targetData.id === targetId) {
            targetData[entityKey].forEach(entity => {
              if (entity.id === entityId) {
                entity[key] = val
              }
            })
          }
        })
      }
    })

    this.setState({ campaignList: [...newCampaignList] })
  }

  deleteEntity = (entityKey, campaignId, targetId, entityId, idx) => {
    const newCampaignList = [...this.state.campaignList]
    newCampaignList.forEach(campaign => {
      if (campaign.id === campaignId) {
        campaign.target.forEach(targetData => {
          if (targetData.id === targetId) {
            targetData[entityKey].splice(idx, 1)
          }
        })
      }
    })

    this.setState({ campaignList: [...newCampaignList] })
  }

  deleteTarget = (campaignId, targetId, idx) => {
    const newCampaignList = [...this.state.campaignList]
    newCampaignList.forEach(campaign => {
      if (campaign.id === campaignId) {
        campaign.target.splice(idx, 1)
      }
    })

    this.setState({ campaignList: [...newCampaignList] })
  }

  setFieldValue = (value, key, campaignId) => {
    const newCampaignList = [...this.state.campaignList]
    newCampaignList.forEach(campaign => {
      if (campaign.id === campaignId) {
        campaign[key] = value
      }
    })

    this.setState({ campaignList: [...newCampaignList] })
  }

  hideTarget = key => {
    this.setState({ [key]: !this.state[key] })
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }

    let newState = { ...this.state }

    let finalData = new FormData()

    for (let key in newState) {
      if (key.includes("hide_image")) {
        delete newState[key]
      } else if (key === "campaignList") {
        finalData.append("campaign_list", JSON.stringify(newState[key]))
      } else {
        finalData.append(key, newState[key])
      }
    }

    toastr.info("Action in progress...")
    await updateCampaignSpinConfig(finalData)
    toastr.success("Action successfull!!")
    location.reload()
  }

  render() {
    return (
      <>
        <div className="bg-white">
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Modal.Header>
              <Modal.Title>
                <CardTitle className="font-size-18 text-wrap">
                  Campaign Spin Config
                </CardTitle>
              </Modal.Title>
            </Modal.Header>
            <div className="m-2 p-2">
              <div className="d-flex justify-content-between">
                <div className="font-size-18 text-muted">Campaign List</div>
                <div className="">
                  <Button
                    color="outline-primary"
                    className="btn-sm font-weight-semibold w-xs font-size-14"
                    onClick={() => {
                      this.setState(prev_state => ({
                        campaignList: [
                          ...prev_state.campaignList,
                          {
                            id: uuidv4(),
                          },
                        ],
                      }))
                    }}
                  >
                    <i className="fa fa-plus mx-1 my-1" /> Campaign
                  </Button>
                </div>
              </div>
              {this.state.campaignList && this.state.campaignList.length > 0 ? (
                this.state.campaignList.map((campaign, idx) => {
                  return (
                    <div key={campaign.id}>
                      <Divider className="m-auto">
                        <h5 className="mb-0 text-muted font-size-18">{`Campaign - ${
                          idx + 1
                        }`}</h5>
                      </Divider>
                      <div className="m-2 p-2">
                        <Row>
                          <Col sm={12} md={6} lg={6} className="">
                            <TextField
                              label={"Campaign Name"}
                              field={`this.state.campaignList.${idx}.campaign_name`}
                              className="creator-form"
                              defaultValue={
                                (campaign && campaign.campaign_name) || ""
                              }
                              onChange={val =>
                                this.setFieldValue(
                                  val,
                                  "campaign_name",
                                  campaign.id
                                )
                              }
                            />
                          </Col>
                          <Col
                            sm={12}
                            md={6}
                            lg={6}
                            className="d-flex justify-content-end align-items-end"
                          >
                            <div
                              onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                            >
                              <Button
                                color="outline-primary"
                                className="btn-sm font-weight-semibold w-xs font-size-12"
                                onClick={() => {
                                  const newCampaignList = [
                                    ...this.state.campaignList,
                                  ]
                                  if (newCampaignList[idx].target) {
                                    newCampaignList[idx].target.push({
                                      id: uuidv4(),
                                    })
                                  } else {
                                    newCampaignList[idx].target = [
                                      {
                                        id: uuidv4(),
                                      },
                                    ]
                                  }
                                  this.setState({
                                    campaignList: [...newCampaignList],
                                  })
                                }}
                              >
                                <i className="fa fa-plus mx-1 my-1" /> Target
                              </Button>
                              <Button
                                color="outline-primary"
                                className="btn-sm font-weight-semibold w-xs font-size-12 ms-2"
                                onClick={() => {
                                  this.setState({
                                    [`show_${campaign.id}`]:
                                      !this.state[`show_${campaign.id}`],
                                  })
                                }}
                              >
                                {this.state[`show_${campaign.id}`] ? (
                                  <>
                                    <i className="fa fa-eye-slash mx-1 my-1" />{" "}
                                    Target
                                  </>
                                ) : (
                                  <>
                                    <i className="fa fa-eye mx-1 my-1" /> Target
                                  </>
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {(this.state[`show_${campaign.id}`] &&
                        campaign.target &&
                        campaign.target.map((config, indx) => {
                          return (
                            <TargetWiseSpinConfig
                              {...this.state}
                              config={config}
                              campaignData={campaign}
                              indx={indx}
                              key={`${config.id}_${config?.banner_list?.length}_${config?.static_buttons_list?.length}_${config?.chips_list?.length}`}
                              campaignId={campaign.id}
                              targetId={config.id}
                              setTargetValue={this.setTargetValue}
                              setTargetImage={this.setTargetImage}
                              hideTargetImage={this.hideTargetImage}
                              setTargetEntityValue={this.setTargetEntityValue}
                              deleteEntity={this.deleteEntity}
                              deleteTarget={this.deleteTarget}
                              hideTarget={this.hideTarget}
                            />
                          )
                        })) ||
                        null}
                    </div>
                  )
                })
              ) : (
                <div
                  className="d-flex justify-content-center m-5 text-muted"
                  style={{ fontStyle: "italic" }}
                >
                  No campaign data present.
                </div>
              )}
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "10px" }}
            >
              <Button htmlType="submit" color="primary" className="m-2">
                Submit
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "spin.loading"),
    data: _.get(store, "spin.data"),
    refetch: _.get(store, "spin.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(CampaignSpinConfig)
