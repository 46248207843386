import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Divider, Input, Pagination, Tabs, Select } from "antd"
import { Button } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import UpsertTemplateForm from "./upsertTemplate"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import {
  getEntities,
  resetState,
  upsertEntity,
} from "../../appRedux/slices/zeroSearch"
import {
  upsertTemplate,
  getTemplates,
  removeTemplate,
} from "../../appRedux/slices/cameraAsset"
import TemplateCard from "./templateCard"
import { markTrending } from "appRedux/slices/trending"
import UpsertTrendingPayload from "../CommonTrending/trendingUpsert"
import Chips from "components/CommonFilter/chips"
import {
  cameraAssetTemplateFilters,
  NO_PERMISSION_MESSAGE,
} from "constants/uiConstants"
import toastr from "toastr"

const { Option } = Select
const { TabPane } = Tabs

const COLLECTION_PROPERTIES = {
  NAMED_COLLECTION: {
    cardClass: "discovery-card-container",
    name: "Named Collection",
    icon: "bx bx-collection",
  },
}
class ManageTemplate extends ErrorBoundComponent {
  state = {
    showModal: false,
    showTrendingForm: false,
    templateId: "",
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      label:
        this.props.queryParams && this.props.queryParams["label"]
          ? this.props.queryParams["label"]
          : "",
      isProcessed:
        this.props.queryParams && this.props.queryParams["isProcessed"]
          ? this.props.queryParams["isProcessed"]
          : null,
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getTemplates({ filters: _.deepClean(filters) }))
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getTemplates({ filters: _.deepClean(filters) }))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({ filters: newFilters })
  }

  _searchEntity = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/templates/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
    this.props.dispatch(getEntities({ filters: _.deepClean(filters) }))
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/templates/manage?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      ),
        this.props.dispatch(getTemplates({ filters: _.deepClean(newFilters) }))
    })
  }
  _onTrendingClick = templateData => {
    this.setState({
      showTrendingForm: true,
      action: "create",
      templateId: templateData.template_uuid,
      templateData: templateData,
    })
  }

  _trendingForm = () => {
    return (
      <div className="view_container">
        <UpsertTrendingPayload
          pageId={this.state.currentPageId}
          type={
            this.props.queryParams && this.props.queryParams.type
              ? this.props.queryParams.type
              : "TEMPLATE"
          }
          onSubmit={this._onTrendingSubmit}
          data={
            this.state.templateData && this.state.templateData.trending_meta_cms
              ? this.state.templateData.trending_meta_cms
              : ""
          }
          action={
            this.state.templateData &&
            !_.isEmpty(this.state.templateData.trending_meta_cms)
              ? "update"
              : "create"
          }
          onCancel={() =>
            this.setState({ showTrendingForm: false, templateId: "" })
          }
        />
      </div>
    )
  }

  _onSubmit = async payload => {
    let response
    response = await this.props.dispatch(
      upsertTemplate({
        data: payload,
        action: this.state.action,
        templateId:
          this.state.entityData && this.state.entityData.template_uuid
            ? this.state.entityData.template_uuid
            : "",
      })
    )

    this.setState({ showModal: false, entityData: null })
  }

  _modal = () => {
    return (
      <div className="view_container">
        <UpsertTemplateForm
          pageId={this.state.currentPageId}
          type={
            this.props.queryParams && this.props.queryParams.type
              ? this.props.queryParams.type
              : "NAMED_COLLECTION"
          }
          onSubmit={this._onSubmit}
          data={this.state.entityData}
          action={this.state.action}
          onCancel={() => this.setState({ showModal: false })}
        />
      </div>
    )
  }

  _onEdit = entityData => {
    this.setState({ entityData, showModal: true, action: "update" })
  }

  _getTitle = () => {
    const type = this.props.queryParams.type
    const typeToLowerCase = type.replace(/_|-|\./g, " ").toLowerCase()
    return (
      <h4>
        {typeToLowerCase.charAt(0).toUpperCase() + typeToLowerCase.slice(1)}
      </h4>
    )
  }

  _onDelete = async templateId => {
    await this.props.dispatch(removeTemplate(templateId))
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    this.setState({
      filters: newFilters,
    })
  }

  _closeFilters = () => {
    this._searchTemplate()
    this.setState({
      showFilters: false,
    })
  }

  _searchTemplate = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/templates/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    ),
      this.props.dispatch(getTemplates({ filters: _.deepClean(filters) }))
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: cameraAssetTemplateFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/templates/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getTemplates({ filters: _.deepClean(this.state.filters) })
        )
      }
    )
  }

  _onTrendingSubmit = async payload => {
    let response = await this.props.dispatch(
      markTrending({
        data: payload,
        type: "template",
        entityId: this.state.templateId ? this.state.templateId : "",
      })
    )
    this.setState({ showTrendingForm: false, templateData: null })
  }

  _buttons = () => {
    return (
      <div className="d-flex justify-content-end my-2">
        <div className="d-flex">
          <Button
            color="primary"
            className="d-flex"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes("EDIT_TEMPLATES")
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.setState({ showModal: true, action: "create" })
            }}
          >
            <i className="fa fa-plus mx-1 my-1" />
            Add
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Auxiliary
          loading={this.props.loading}
          error={_.get(this.props, "common.error")}
        >
          {!this.state.showModal && !this.state.showTrendingForm && (
            <React.Fragment>
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                type="camera_asset_template"
                toggleFilters={this._toggleFilters}
              />
              {this._buttons()}
              <div className="discovery-card-container">
                {this.props.templatesList &&
                  this.props.templatesList.map((entity, i) => {
                    return (
                      <TemplateCard
                        data={entity}
                        locationList={this.props.locationList}
                        onEditClick={this._onEdit}
                        hideDelete={false}
                        onDeleteClick={this._onDelete}
                        pageSource="manageEntity"
                        onTrendingClick={this._onTrendingClick}
                      />
                    )
                  })}
              </div>
              {this.props.templatesList &&
                this.props.templatesList.length > 0 && (
                  <div className="d-flex justify-content-end">
                    <Pagination
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                      onChange={this._onPageChange}
                      defaultCurrent={this.state.filters.currentPage}
                      total={
                        this.props.total < 10000 ? this.props.total : 10000
                      }
                      pageSize={this.state.filters.pageSize}
                      showSizeChanger={false}
                    />
                  </div>
                )}
            </React.Fragment>
          )}
        </Auxiliary>
        {this.state.showModal && this._modal()}
        {this.state.showTrendingForm && this._trendingForm()}
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "cameraAsset.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "cameraAsset.loading"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    templatesList: _.get(store, "cameraAsset.templatesList"),
    refetch: _.get(store, "cameraAsset.refetchData"),
  }
}

export default connect(mapStateToProps)(ManageTemplate)
