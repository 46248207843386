import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestUserListByFilters,
  requestGetAppUserDetailsES,
  requestManageAppUser,
  requsetUpdateAppUser,
  requestUpdateAppUserCuratedEntities,
  requestTagUser,
  requestLiveModerateUser,
  requestGetAppUserDetails,
  requestLogs,
  requestCreateAppUser,
  requestUploadUser,
  requestBoostFollowHistory,
  requestBoostFollow,
  requestBoostFollowStatus,
  requestUpdateFollow,
  requestDeltaHistory,
  requestToggleShoppable,
  requestActivityBoostHistory,
  requestToggleAllowJoshLive,
  requestFuzzySearchUsers,
  requsetUpdateAppUserType,
  requestTasksByFilters,
  requestUpdateUserAICaption,
} from "appRedux/sagas/requests/appUser"
import { requestContentByFilters } from "appRedux/sagas/requests/content"
import {
  getUserListByFilters,
  getUserListByFiltersSuccess,
  getAppUserDetailsES,
  getAppUserDetailsESSuccess,
  getAppUserVideos,
  getAppUserVideosSuccess,
  manageUser,
  updateUser,
  updateUserType,
  updateAppUserCuratedEntities,
  tagUser,
  getAppUserDetails,
  getLogs,
  getLogsSuccess,
  createAppUser,
  createAppUserSuccess,
  uploadUser,
  boostFollow,
  boostFollowSuccess,
  getBoostFollowHistory,
  getBoostFollowHistorySuccess,
  getBoostFollowStatus,
  getBoostFollowStatusSuccess,
  updateDelta,
  updateDeltaSuccess,
  getDeltaHistory,
  getDeltaHistorySuccess,
  toggleShoppable,
  toggleShoppableSuccess,
  getAppUserDetailsSuccess,
  getActivityBoostHistorySuccess,
  getActivityBoostHistory,
  toggleAllowJoshLive,
  toggleAllowJoshLiveSuccess,
  fuzzySearchUser,
  fuzzySearchUserSuccess,
  getTasksByFilter,
  getTasksByFilterSuccess,
  liveModerateUser,
  updateUserAICaption,
  updateUserAICaptionSuccess,
} from "appRedux/slices/appUser"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

function* handleGetUserListByFilters(params) {
  try {
    const data = yield call(requestUserListByFilters, params)
    yield put(getUserListByFiltersSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAppUserDetailsES(params) {
  try {
    const data = yield call(requestGetAppUserDetailsES, params)
    yield put(getAppUserDetailsESSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleFuzzySearchUser(params) {
  try {
    const data = yield call(requestFuzzySearchUsers, params)
    yield put(fuzzySearchUserSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetTasksByFilters(params) {
  try {
    const data = yield call(requestTasksByFilters, params)
    yield put(getTasksByFilterSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAppUserVideos(params) {
  try {
    const data = yield call(requestContentByFilters, params)
    yield put(getAppUserVideosSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleManageAppUser(params) {
  try {
    toastr.info("Action in progress...", "")
    yield call(requestManageAppUser, params)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateAppUser(params) {
  try {
    toastr.info("Action in progress...", "")
    yield call(requsetUpdateAppUser, params)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateAppUserType(params) {
  try {
    toastr.info("Action in progress...", "")
    yield call(requsetUpdateAppUserType, params)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateAppUserCuratedEntities(params) {
  try {
    toastr.info("Action in progress...", "")
    yield call(requestUpdateAppUserCuratedEntities, params)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleTagUser(params) {
  try {
    toastr.info("Action in progress...", "")
    yield call(requestTagUser, params)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleLiveModerateUser(params) {
  try {
    toastr.info("Action in progress...", "")
    yield call(requestLiveModerateUser, params)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetAppUserDetails(params) {
  try {
    toastr.info("Action in progress...", "")
    const data = yield call(requestGetAppUserDetails, params)
    yield put(getAppUserDetailsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetLogs(params) {
  try {
    const data = yield call(requestLogs, params)
    yield put(getLogsSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleCreateAppUser(params) {
  try {
    const data = yield call(requestCreateAppUser, params)
    yield put(createAppUserSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUploadUser(params) {
  try {
    const data = yield call(requestUploadUser, params)
    toastr.success(data.data)
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleBoostFollowGenerator(payload) {
  try {
    toastr.info("Action in progress...", "")
    yield call(requestBoostFollow, payload)
    yield put(boostFollowSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateDeltaGenerator(payload) {
  try {
    toastr.info("Action in progress...", "")
    yield call(requestUpdateFollow, payload)
    yield put(updateDeltaSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetBoostFollowHistoryGenerator(payload) {
  try {
    const data = yield call(requestBoostFollowHistory, payload)
    yield put(getBoostFollowHistorySuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetDeltaHistoryGenerator(payload) {
  try {
    const data = yield call(requestDeltaHistory, payload)
    yield put(getDeltaHistorySuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetActivityBoostHistoryGenerator(payload) {
  try {
    const data = yield call(requestActivityBoostHistory, payload)
    yield put(getActivityBoostHistorySuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateShoppableFlagGenerator(payload) {
  try {
    const data = yield call(requestToggleShoppable, payload)
    yield put(toggleShoppableSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateAllowJoshLiveFlagGenerator(payload) {
  try {
    const data = yield call(requestToggleAllowJoshLive, payload)
    yield put(toggleAllowJoshLiveSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUpdateUserAICaptionGenerator(payload) {
  try {
    const data = yield call(requestUpdateUserAICaption, payload)
    yield put(updateUserAICaptionSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetBoostFollowStatusGenerator(payload) {
  try {
    const data = yield call(requestBoostFollowStatus, payload)
    yield put(getBoostFollowStatusSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getUserListByFilters.type, handleGetUserListByFilters)
  yield takeLatest(getAppUserDetailsES.type, handleGetAppUserDetailsES)
  yield takeLatest(fuzzySearchUser.type, handleFuzzySearchUser)
  yield takeLatest(getAppUserVideos.type, handleGetAppUserVideos)
  yield takeLatest(manageUser.type, handleManageAppUser)
  yield takeLatest(updateUser.type, handleUpdateAppUser)
  yield takeLatest(updateUserType.type, handleUpdateAppUserType)
  yield takeLatest(
    updateAppUserCuratedEntities.type,
    handleUpdateAppUserCuratedEntities
  )
  yield takeLatest(tagUser.type, handleTagUser)
  yield takeLatest(liveModerateUser.type, handleLiveModerateUser)
  yield takeLatest(getAppUserDetails.type, handleGetAppUserDetails)
  yield takeLatest(getLogs.type, handleGetLogs)
  yield takeLatest(createAppUser.type, handleCreateAppUser)
  yield takeLatest(uploadUser.type, handleUploadUser)
  yield takeLatest(boostFollow.type, handleBoostFollowGenerator)
  yield takeLatest(updateDelta.type, handleUpdateDeltaGenerator)

  yield takeLatest(
    getBoostFollowHistory.type,
    handleGetBoostFollowHistoryGenerator
  )
  yield takeLatest(getDeltaHistory.type, handleGetDeltaHistoryGenerator)
  yield takeLatest(toggleShoppable.type, handleUpdateShoppableFlagGenerator)
  yield takeLatest(
    toggleAllowJoshLive.type,
    handleUpdateAllowJoshLiveFlagGenerator
  )
  yield takeLatest(updateUserAICaption.type, handleUpdateUserAICaptionGenerator)
  yield takeLatest(
    getActivityBoostHistory.type,
    handleGetActivityBoostHistoryGenerator
  )
  yield takeLatest(
    getBoostFollowStatus.type,
    handleGetBoostFollowStatusGenerator
  )
  yield takeLatest(getTasksByFilter.type, handleGetTasksByFilters)
}
