import PropTypes from "prop-types"
import React, { useState, createRef } from "react"
import ReactCardFlip from "react-card-flip"
import { Card, Row, CardBody, Col, Table } from "reactstrap"
import "./embestyle.css"
import { formatNumber } from "../../util/common"
import "toastr/build/toastr.min.css"
import { withRouter } from "react-router"
import { BsInfoLg } from "react-icons/bs"
import { FaCopy } from "react-icons/fa"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import moment from "moment"
import { LANGUAGE_MAPPING } from "constants/uiConstants"

const _renderThumbnail = content => {
  switch (content.format) {
    case "IMAGE":
      return (
        <img src={content.download_url} width="100%" height="325px" alt="" />
      )
    case "GIF":
      return (
        <img src={content.download_url} width="100%" height="380px" alt="" />
      )
    default:
      return (
        <img
          src={
            content.animated_icon_url
              ? content.animated_icon_url
              : content.thumbnail_url
              ? content.thumbnail_url
              : ""
          }
          width="100%"
          height="325px"
          alt=""
          style={{ filter: "blur(.5px)" }}
        />
      )
  }
}

const EmbeddingCard = props => {
  const [isFlipped, setIsFlipped] = useState(false)
  const [showVideo, setVideo] = useState(false)
  const [controls, setControls] = useState(props.currentUser.jotUserType !== 4)
  const [playbackRate, setPlayBackRate] = useState(
    props.pageType === "tagContent" ? "1.0" : "1.0"
  )
  const [content, setContent] = useState(props.content)
  const videoRef = createRef()

  const _setPlayBack = () => {
    videoRef.current.playbackRate = playbackRate
    videoRef.current.volume = 0.4
  }

  const { customClassname = "" } = props
  const enableEdit = props.enableEdit === false ? false : true

  let primaryFilters = "default"
  if (props.primaryFilter) {
    switch (props.primaryFilter) {
      case "share_count":
        primaryFilters = "share_count"
        break
      case "download_count":
        primaryFilters = "download_count"
        break
      case "like_count":
        primaryFilters = "like_count"
        break
      case "created_date":
        primaryFilters = "created_date"
        break
      case "t_score_v2":
        primaryFilters = "t_score_v2"
        break
      case "pcc_score":
        primaryFilters = "pcc_score"
        break
      case "fy_ctr_score":
        primaryFilters = "fy_ctr_score"
        break
      case "ctr_score":
        primaryFilters = "ctr_score"
        break
      default:
        primaryFilters = "default"
        break
    }
  }

  const realStatPermissionUser =
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("VIEW_REAL_STATS")

  let bucket = "review_bucket" in content ? content.review_bucket : undefined

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection={"horizontal"}>
      <Card
        className={`${customClassname}`}
        style={{ border: `3px solid ${props.borderColor}` }}
      >
        <div
          className="bg-primary bg-soft"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            setIsFlipped(true)
          }}
          type={!enableEdit ? "" : "button"}
        >
          {!showVideo && (
            <Col className="align-self-end" style={{ width: "100%" }}>
              {_renderThumbnail(content)}

              <div
                role="button"
                id={"play" + content.content_uuid}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setVideo(!showVideo)
                }}
              >
                <i
                  className="bx bx-play"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "70px",
                    color: "white",
                  }}
                />
                <Tooltip title="View More Info">
                  <BsInfoLg
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: "0%",
                      fontSize: "20px",
                      color: "white",
                      background: "rgba(0, 0, 0, 0.7)",
                      padding: "2px 2px 2px 2px",
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      setIsFlipped(true)
                    }}
                  />
                </Tooltip>
                <CopyToClipboard
                  text={content.content_uuid}
                  onCopy={e => {
                    toastr.remove()
                    toastr.success("Copied sucessfully!")
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  <Tooltip title="Copy Content Id">
                    <FaCopy
                      style={{
                        position: "absolute",
                        top: "0%",
                        right: "0%",
                        fontSize: "20px",
                        color: "white",
                        background: "rgba(0, 0, 0, 0.7)",
                        padding: "2px 2px 2px 2px",
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        // setIsFlipped(true)
                      }}
                    />
                  </Tooltip>
                </CopyToClipboard>
                <div className="display_count">
                  {primaryFilters === "default" ? (
                    <>
                      <div className="d-flex align-items-center">
                        <div>
                          <i
                            className="bx bx-show"
                            style={{
                              paddingRight: "2px",
                              fontSize: "16px",
                              paddingTop: "3px",
                            }}
                          />
                        </div>
                        <span> {formatNumber(content.view_count) || 0}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <div>
                          <i
                            className="bx bx-like"
                            style={{
                              // paddingTop: "4px",
                              paddingRight: "2px",
                              fontSize: "16px",
                              paddingTop: "3px",
                            }}
                          />
                        </div>
                        <span> {formatNumber(content.like_count) || 0}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center">
                        {primaryFilters === "share_count" ? (
                          <>
                            <div>
                              <i
                                className="bx bx-share"
                                style={{
                                  // paddingTop: "4px",
                                  paddingRight: "2px",
                                  fontSize: "16px",
                                  paddingTop: "3px",
                                }}
                              />
                            </div>
                            <span>
                              {" "}
                              {formatNumber(content.share_count) || 0}
                            </span>
                          </>
                        ) : null}
                        {primaryFilters === "like_count" ? (
                          <>
                            <div>
                              <i
                                className="bx bx-like"
                                style={{
                                  // paddingTop: "4px",
                                  paddingRight: "2px",
                                  fontSize: "16px",
                                  paddingTop: "3px",
                                }}
                              />
                            </div>
                            <span>
                              {" "}
                              {formatNumber(content.like_count) || 0}
                            </span>
                          </>
                        ) : null}
                        {primaryFilters === "download_count" ? (
                          <>
                            <div>
                              <i
                                className="bx bx-download"
                                style={{
                                  // paddingTop: "4px",
                                  paddingRight: "2px",
                                  fontSize: "16px",
                                  paddingTop: "3px",
                                }}
                              />
                            </div>
                            <span>
                              {" "}
                              {formatNumber(content.download_count) || 0}
                            </span>
                          </>
                        ) : null}
                        {primaryFilters === "t_score_v2" ? (
                          <div className="d-flex align-items-center">
                            <span
                              style={{
                                // paddingTop: "4px",
                                paddingRight: "2px",
                                fontSize: "13px",
                              }}
                            >
                              T
                            </span>
                            <span>
                              {" "}
                              {formatNumber(content.t_score_v2.toFixed(2)) || 0}
                            </span>
                          </div>
                        ) : null}
                        {primaryFilters === "created_date" ? (
                          <>
                            <i
                              className="bx bx-calendar"
                              style={{
                                // paddingTop: "4px",
                                paddingRight: "2px",
                                fontSize: "16px",
                                paddingTop: "3px",
                              }}
                            />
                            <span>
                              {" "}
                              {new Date(
                                content.created_date
                              ).toLocaleDateString()}
                            </span>
                          </>
                        ) : null}
                        {primaryFilters === "pcc_score" ? (
                          <Tooltip title="PCC Score">
                            {" "}
                            <i
                              className="bx bx-chart"
                              style={{
                                // paddingTop: "4px",
                                paddingRight: "0px",
                                fontSize: "16px",
                                paddingTop: "3px",
                              }}
                            />
                            <span> {formatNumber(content.pcc_score) || 0}</span>
                          </Tooltip>
                        ) : null}
                        {primaryFilters === "fy_ctr_score" ? (
                          <Tooltip title="FY CTR Score">
                            <i
                              className="bx bx-chart"
                              style={{
                                // paddingTop: "4px",
                                paddingRight: "0px",
                                fontSize: "16px",
                                paddingTop: "3px",
                              }}
                            />
                            <span>
                              {" "}
                              {formatNumber(content.fy_ctr_score) || 0}
                            </span>
                          </Tooltip>
                        ) : null}
                        {primaryFilters === "ctr_score" ? (
                          <Tooltip title="CTR Score">
                            <i
                              className="bx bx-chart"
                              style={{
                                // paddingTop: "4px",
                                paddingRight: "0px",
                                fontSize: "16px",
                                paddingTop: "3px",
                              }}
                            />
                            <span> {formatNumber(content.ctr_score) || 0}</span>
                          </Tooltip>
                        ) : null}
                      </div>
                      <div className="d-flex align-items-center">
                        <div>
                          <i
                            className="bx bx-show"
                            style={{
                              // paddingTop: "4px",
                              paddingRight: "2px",
                              fontSize: "16px",
                              paddingTop: "3px",
                            }}
                          />
                        </div>
                        <span> {formatNumber(content.view_count) || 0}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          )}
          {showVideo && (
            <Col
              className="align-self-end"
              style={{ width: "100%", height: "325px" }}
            >
              <Tooltip title="View More Info">
                <BsInfoLg
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: "0%",
                    fontSize: "20px",
                    color: "white",
                    background: "rgba(0, 0, 0, 0.7)",
                    padding: "2px 2px 2px 2px",
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsFlipped(true)
                  }}
                />
              </Tooltip>
              <CopyToClipboard
                text={content.content_uuid}
                onCopy={e => {
                  toastr.remove()
                  toastr.success("Copied sucessfully!")
                }}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
              >
                <Tooltip title="Copy Content Id">
                  <FaCopy
                    style={{
                      position: "absolute",
                      top: "0%",
                      right: "0%",
                      fontSize: "20px",
                      zIndex: "1",
                      color: "white",
                      background: "rgba(0, 0, 0, 0.7)",
                      padding: "2px 2px 2px 2px",
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      // setIsFlipped(true)
                    }}
                  />
                </Tooltip>
              </CopyToClipboard>
              <video
                src={content.preview_url}
                ref={videoRef}
                height="325px"
                width="100%"
                onCanPlay={() => _setPlayBack()}
                controls={controls}
                onEnded={() => {
                  setControls(true)
                }}
                class="embvideo"
                controlsList={
                  props.currentUser.jotUserType > 3 ? "nodownload" : ""
                }
                // muted={props.pageType === "tagContent" ? false : true}

                onContextMenu={e => {
                  e.preventDefault()
                  return false
                }}
                autoPlay
              />
              <div className="display_count">
                {primaryFilters === "default" ? (
                  <>
                    <div className="d-flex align-items-center">
                      <div>
                        <i
                          className="bx bx-show"
                          style={{
                            paddingRight: "2px",
                            fontSize: "16px",
                            paddingTop: "3px",
                          }}
                        />
                      </div>
                      <span> {formatNumber(content.view_count) || 0}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <i
                          className="bx bx-like"
                          style={{
                            // paddingTop: "4px",
                            paddingRight: "2px",
                            fontSize: "16px",
                            paddingTop: "3px",
                          }}
                        />
                      </div>
                      <span> {formatNumber(content.like_count) || 0}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex align-items-center">
                      {primaryFilters === "share_count" ? (
                        <>
                          <div>
                            <i
                              className="bx bx-share"
                              style={{
                                // paddingTop: "4px",
                                paddingRight: "2px",
                                fontSize: "16px",
                                paddingTop: "3px",
                              }}
                            />
                          </div>
                          <span> {formatNumber(content.share_count) || 0}</span>
                        </>
                      ) : null}
                      {primaryFilters === "like_count" ? (
                        <>
                          <div>
                            <i
                              className="bx bx-like"
                              style={{
                                // paddingTop: "4px",
                                paddingRight: "2px",
                                fontSize: "16px",
                                paddingTop: "3px",
                              }}
                            />
                          </div>
                          <span> {formatNumber(content.like_count) || 0}</span>
                        </>
                      ) : null}
                      {primaryFilters === "download_count" ? (
                        <>
                          <div>
                            <i
                              className="bx bx-download"
                              style={{
                                // paddingTop: "4px",
                                paddingRight: "2px",
                                fontSize: "16px",
                                paddingTop: "3px",
                              }}
                            />
                          </div>
                          <span>
                            {" "}
                            {formatNumber(content.download_count) || 0}
                          </span>
                        </>
                      ) : null}
                      {primaryFilters === "t_score_v2" ? (
                        <div className="d-flex align-items-center">
                          <span
                            style={{
                              // paddingTop: "4px",
                              paddingRight: "2px",
                              fontSize: "13px",
                            }}
                          >
                            T
                          </span>
                          <span>
                            {" "}
                            {formatNumber(content.t_score_v2.toFixed(2)) || 0}
                          </span>
                        </div>
                      ) : null}
                      {primaryFilters === "created_date" ? (
                        <>
                          <i
                            className="bx bx-calendar"
                            style={{
                              // paddingTop: "4px",
                              paddingRight: "2px",
                              fontSize: "16px",
                              paddingTop: "3px",
                            }}
                          />
                          <span>
                            {" "}
                            {new Date(
                              content.created_date
                            ).toLocaleDateString()}
                          </span>
                        </>
                      ) : null}
                      {primaryFilters === "pcc_score" ? (
                        <Tooltip title="PCC Score">
                          {" "}
                          <i
                            className="bx bx-chart"
                            style={{
                              // paddingTop: "4px",
                              paddingRight: "0px",
                              fontSize: "16px",
                              paddingTop: "3px",
                            }}
                          />
                          <span> {formatNumber(content.pcc_score) || 0}</span>
                        </Tooltip>
                      ) : null}
                      {primaryFilters === "fy_ctr_score" ? (
                        <Tooltip title="FY CTR Score">
                          <i
                            className="bx bx-chart"
                            style={{
                              // paddingTop: "4px",
                              paddingRight: "0px",
                              fontSize: "16px",
                              paddingTop: "3px",
                            }}
                          />
                          <span>
                            {" "}
                            {formatNumber(content.fy_ctr_score) || 0}
                          </span>
                        </Tooltip>
                      ) : null}
                      {primaryFilters === "ctr_score" ? (
                        <Tooltip title="CTR Score">
                          <i
                            className="bx bx-chart"
                            style={{
                              // paddingTop: "4px",
                              paddingRight: "0px",
                              fontSize: "16px",
                              paddingTop: "3px",
                            }}
                          />
                          <span> {formatNumber(content.ctr_score) || 0}</span>
                        </Tooltip>
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <i
                          className="bx bx-show"
                          style={{
                            // paddingTop: "4px",
                            paddingRight: "2px",
                            fontSize: "16px",
                            paddingTop: "3px",
                          }}
                        />
                      </div>
                      <span> {formatNumber(content.view_count) || 0}</span>
                    </div>
                  </>
                )}
              </div>
            </Col>
          )}
        </div>
      </Card>
      <Card
        className={`text-center embedding-card`}
        style={{
          // height: "612px",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "0px",
        }}
      >
        {/* <CustomScrollbars> */}
        <CardBody className="px-0 py-0">
          <Row>
            <Col className="d-flex justify-content-end">
              <div role="button" onClick={() => setIsFlipped(false)}>
                <div className=" bg-danger" style={{ width: "20px" }}>
                  <span className={"font-size-14" + " font-weight-semibold"}>
                    <i className="fa fa-times" style={{ color: "white" }} />
                  </span>
                </div>
              </div>
              <div></div>
            </Col>
          </Row>
          <Row className="font-size-12">
            <div className="table-responsive">
              <Table className="mb-0 table-striped">
                <tbody>
                  <tr className="text-start">
                    <th scope="row">Views:</th>
                    <td>{formatNumber(content.view_count) || 0}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Downloads:</th>
                    <td>{formatNumber(content.download_count) || 0}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Likes:</th>
                    <td> {formatNumber(content.like_count) || 0}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Shares:</th>
                    <td>{formatNumber(content.share_count) || 0}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Moderation Level:</th>
                    <td>{formatNumber(content.moderation_level) || 0}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">PCC Score:</th>
                    <td>{formatNumber(content.pcc_score) || 0}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">CTR Score:</th>
                    <td>{formatNumber(content.ctr_score) || 0}</td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">FY CTR Score:</th>
                    <td>{formatNumber(content.fy_ctr_score) || 0}</td>
                  </tr>

                  <tr className="text-start">
                    <th scope="row">Language:</th>
                    <td>
                      {content.lang_code
                        ? LANGUAGE_MAPPING[content.lang_code]
                        : ""}
                    </td>
                  </tr>
                  <tr className="text-start">
                    <th scope="row">Created Date:</th>
                    <td className="break-word">
                      {moment(content.created_date).format(
                        "DD-MM-YYYY HH:MM:SS"
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>
        </CardBody>
        {/* </CustomScrollbars> */}
      </Card>
    </ReactCardFlip>
  )
}

EmbeddingCard.propTypes = {
  content: PropTypes.object,
}

export default withRouter(EmbeddingCard)
