import { createSlice } from "@reduxjs/toolkit"

const adsSlice = createSlice({
  name: "ads",
  initialState: {
    adsProfileList: [],
    adsProfileTotal: 0,
    adsConfigList: [],
    adsConfigTotal: 0,
    loading: false,
    refetchData: false,
    tabList: [],
  },
  reducers: {
    getAdsTabs(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getAdsTabsSuccess(state, { payload }) {
      return {
        ...state,
        tabList: payload.data,
        loading: false,
        refetchData: false,
      }
    },
    getAdsProfiles(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getAdsProfilesSuccess(state, { payload }) {
      return {
        ...state,
        adsProfileList: payload.data.data,
        loading: false,
        adsProfileTotal: payload.data.total || 1,
        refetchData: false,
      }
    },
    getAdsConfigs(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getAdsConfigsSuccess(state, { payload }) {
      return {
        ...state,
        adsConfigList: payload.data.data,
        loading: false,
        adsConfigTotal: payload.data.total || 1,
        refetchData: false,
      }
    },
    upsertAdsProfile(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertAdsProfileSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertAdsConfig(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertAdsConfigSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    resetState(state) {
      return {
        ...state,
        adsProfileList: [],
        adsProfileTotal: 0,
        adsConfigList: [],
        adsConfigTotal: 0,
        loading: false,
        refetchData: false,
        tabList: [],
      }
    },
  },
})

export const {
  getAdsProfiles,
  getAdsProfilesSuccess,
  getAdsConfigs,
  getAdsConfigsSuccess,
  upsertAdsProfile,
  upsertAdsProfileSuccess,
  upsertAdsConfig,
  upsertAdsConfigSuccess,
  getAdsTabs,
  getAdsTabsSuccess,
  resetState,
} = adsSlice.actions

export default adsSlice.reducer
