import { createSlice } from "@reduxjs/toolkit"

const reviewSlice = createSlice({
  name: "review",
  initialState: {
    loading: false,
  },
  reducers: {
    createReview(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createReviewSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
  },
})

export const { createReview, createReviewSuccess } = reviewSlice.actions

export default reviewSlice.reducer
