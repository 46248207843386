import React from "react"
import { connect } from "react-redux"
import _, { cloneDeep } from "lodash"
import { Spin } from "antd"
import { CardTitle, Button, Row, Col } from "reactstrap"
import Fuse from "fuse.js"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Modal } from "react-bootstrap"
import Cards from "./cards"
import { getZoneByIds } from "clientServices/zoneService"
import ZoneAdditionCard from "./zoneAdditionCard"
import { setTaxonomyRoutes } from "../../appRedux/slices/taxonomy"
import "./TaxonomyDashboard.css"
import ZoneAddedCard from "./zoneAddedCard"
import { addZoneSuggession } from "../../clientServices/zoneService"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

class Zone extends Cards {
  constructor(props) {
    super(props)
    let showZoneList = []
    this.state = {
      zoneSuggessionTitle: "",
      showZoneSuggessionModel: false,
      labelToDisplay: "Zone",
      currentHierarchy: ["zone"],
      currentTab: "zone",
      renderZoneFirst: true,
      selectedIds:
        this.props.selectedDetails?.approved_zone_obj?.selectedIds || [],
      relationalIds:
        this.props.selectedDetails?.approved_zone_obj?.relationalIds || [],
      zoneList: this.props.zoneList,
      addedZones:
        this.props.selectedDetails?.approved_zone_obj?.addedZones || [],
      qcApprovedZones: [],
      qcRejectedZones: [],
      selectedZones:
        this.props.selectedDetails?.approved_zone_obj?.addedZones || [],
      loadingL1Zones: false,
      loadingL2Zones: false,
      l0ZoneList: this.props.l0ZoneList,
      l1ZoneList: this.props.l1ZoneList,
      l2ZoneList: this.props.l2ZoneList,
      zoneInfo: this.props.selectedContent.zone_info_v1 || [],
      qcApprovedZones:
        this.props.selectedDetails?.approved_zone_obj?.qcApprovedZones || [],
      showZoneList,
      selectedL0Zone: null,
      addingZones: false,
    }
    this._handleFuzzySearch = _.debounce(this._handleFuzzySearch, 1000)
  }

  _handleDone = () => {
    if (!this.state.showZoneSuggessionModel) {
      let showZoneSuggessionModel = true
      this.state.addedZones.forEach(zone => {
        if (zone.level == "2") showZoneSuggessionModel = false
      })

      if (showZoneSuggessionModel) {
        this.setState({ showZoneSuggessionModel })
        return
      }
    }

    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    let selectedDetails = cloneDeep(this.props.selectedDetails)
    const indexTab = taxonomyRoutes.findIndex(
      item => item.key == "zone_addition"
    )
    taxonomyRoutes[indexTab].completed = true

    let approvedZoneObj = {
      qcApprovedZones: [],
      qcRejectedZones: [],
      qcApprovedZonesData: [],
    }
    approvedZoneObj = selectedDetails["approved_zone_obj"]
      ? { ...approvedZoneObj, ...selectedDetails["approved_zone_obj"] }
      : approvedZoneObj
    approvedZoneObj = {
      ...approvedZoneObj,
      zoneInfo: this.state.zoneInfo,
      qcApprovedZones: _.uniq([
        ...approvedZoneObj.qcApprovedZones,
        ...this.state.selectedIds,
      ]).filter(id => !this.state.qcRejectedZones.includes(id)),
      qcRejectedZones: _.uniq([
        ...approvedZoneObj.qcRejectedZones,
        ...this.state.qcRejectedZones,
      ]),
      qcApprovedZonesData: [
        ...approvedZoneObj.qcApprovedZonesData,
        ...this.state.addedZones,
      ],
      selectedIds: [...this.state.selectedIds],
      relationalIds: [...this.state.relationalIds],
      addedZones: [...this.state.addedZones],
    }
    selectedDetails["approved_zone_obj"] = approvedZoneObj

    this.props.setSelectedDetails(selectedDetails)
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    let nextIndex = 0
    for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
      if (taxonomyRoutes[i].showInSidebar) {
        nextIndex = i
        break
      }
    }
    this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
  }

  _handleFuzzySearch = async e => {
    const query = e.target.value
    if (_.isEmpty(query)) {
      this.setState({
        l0ZoneList: [...this.props.l0ZoneList],
        l1ZoneList: [],
        l2ZoneList: [],
      })
      return
    }

    this.setState({
      loadingL0Zones: true,
      loadingL1Zones: true,
      loadingL2Zones: true,
    })

    // const options = {
    //   isCaseSensitive: false,
    //   findAllMatches: true,
    //   includeMatches: false,
    //   includeScore: true,
    //   useExtendedSearch: false,
    //   threshold: 0.4,
    //   location: 0,
    //   distance: 2,
    //   maxPatternLength: 32,
    //   minMatchCharLength: 2,
    //   keys: ["title"],
    // }

    const options = {
      shouldSort: true,
      findAllMatches: true,
      includeMatches: false,
      threshold: 0.5,
      location: 0,
      distance: 50,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["title"],
    }

    const fuse = new Fuse(this.props.allZonesList, options)

    const data = fuse.search(query)
    const zoneList = data.map(zone => zone.item)
    const l0ZoneList = zoneList.filter(zone => zone.level == "0")
    const l1ZoneList = zoneList.filter(zone => zone.level == "1")
    const l2ZoneList = zoneList.filter(zone => zone.level == "2")
    this.setState({
      l0ZoneList,
      l1ZoneList,
      l2ZoneList,
      loadingL0Zones: false,
      loadingL1Zones: false,
      loadingL2Zones: false,
    })
  }

  _handleRemoveZone = data => {
    if (data.added_type === "relational") return
    let selectedIds = _.cloneDeep(this.state.selectedIds)
    let addedZones = _.cloneDeep(this.state.addedZones)
    let selectedZones = _.cloneDeep(this.state.selectedZones)
    if (data.level == "0") {
      selectedIds = _.remove(selectedIds, id => id !== data.zone_uuid)
      addedZones = _.remove(addedZones, zone => {
        return zone.zone_uuid !== data.zone_uuid
      })
      selectedZones = _.remove(selectedZones, zone => {
        return zone.zone_uuid !== data.zone_uuid
      })
    }
    if (data.level == "1") {
      selectedIds = _.remove(selectedIds, id => id !== data.zone_uuid)
      addedZones = _.remove(addedZones, zone => {
        return zone.zone_uuid !== data.zone_uuid
      })
      selectedZones = _.remove(selectedZones, zone => {
        return zone.zone_uuid !== data.zone_uuid
      })
    }
    if (data.level == "2") {
      selectedIds = _.remove(selectedIds, id => id !== data.zone_uuid)
      addedZones = _.remove(addedZones, zone => {
        return zone.zone_uuid !== data.zone_uuid
      })
      selectedZones = _.remove(selectedZones, zone => {
        return zone.zone_uuid !== data.zone_uuid
      })
    }

    this.setState(
      {
        selectedIds,
        selectedZones,
        addedZones,
      },
      () => this._handleAddZones()
    )
  }

  _renderAddedZonesList = () => {
    return (
      <Row>
        {this.state.addedZones.map(zone => {
          return (
            <Col md="4">
              <ZoneAddedCard data={zone} removeZone={this._handleRemoveZone} />
            </Col>
          )
        })}
      </Row>
    )
  }

  _renderAddedZones = () => {
    return (
      <Row className="mt-2 mx-1">
        <Col>
          <div className="d-flex justify-content-between align-items-center mb-1">
            <div className="d-flex ">Added Zones</div>
            <div>
              <Button color="success" onClick={this._handleDone}>
                Done
              </Button>
            </div>
          </div>
          <div style={{ height: "15vh" }} className="border">
            <PerfectScrollbar>
              {this.state.addingZones ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <Spin tip="Loading selected zones..." />
                </div>
              ) : (
                <div style={{ overflowX: "hidden" }}>
                  {this._renderAddedZonesList()}
                </div>
              )}
            </PerfectScrollbar>
          </div>
        </Col>
      </Row>
    )
  }

  _renderSearchBar = () => {
    return (
      <Row className="mx-1 mt-2">
        <Col>
          <div class="input-group">
            <span class="input-group-append">
              <button
                class="btn btn-outline-secondary bg-primary border"
                type="button"
              >
                <i class="fa fa-search text-white"></i>
              </button>
            </span>
            <input
              class="form-control border"
              type="text"
              id="example-search-input"
              placeholder="Search for zones.."
              onChange={this._handleFuzzySearch}
            />
          </div>
        </Col>
      </Row>
    )
  }

  _handleSelection = async data => {
    const addedIds = this.state.addedZones.map(zone => zone.zone_uuid)
    if (
      this.state.selectedIds?.includes(data.zone_uuid) &&
      !addedIds.includes(data.zone_uuid)
    ) {
      let selectedIds = this.state.selectedIds.filter(id => {
        return data.zone_uuid !== id
      })
      let selectedZones = _.remove(this.state.selectedZones, zone => {
        return zone.zone_uuid !== data.zone_uuid
      })
      this.setState({
        selectedIds,
        selectedZones,
      })

      let l0ZoneList = [...this.state.l0ZoneList]
      let l1ZoneList = [...this.state.l1ZoneList]
      let l2ZoneList = [...this.state.l2ZoneList]
      if (data.level == "0") {
        this.setState({
          loadingL0Zones: true,
        })
        l0ZoneList = [...this.props.l0ZoneList]
        l1ZoneList = []
        l2ZoneList = []
      }
      if (data.level == "1") {
        this.setState({
          loadingL1Zones: true,
          loadingL2Zones: true,
        })
        l1ZoneList = this.props.allZonesList
          .filter(zone => zone.level == "1")
          .filter(zone => {
            let shouldFilter = false
            l0ZoneList.forEach(l0Zone => {
              if (zone?.type?.includes(l0Zone.zone_uuid)) {
                shouldFilter = true
              }
            })
            return shouldFilter
          })
        // l1ZoneList = await getZoneList({
        //   currentPage: 1,
        //   pageSize: 10000,
        //   level: "1",
        //   types: l0ZoneList.map(zone => zone.zone_uuid),
        // })
        // l2ZoneList = await getZoneList({
        //   currentPage: 1,
        //   pageSize: 10000,
        //   level: "2",
        //   types: l0ZoneList.map(zone => zone.zone_uuid),
        // })
        l2ZoneList = this.props.allZonesList
          .filter(zone => zone.level == "2")
          .filter(zone => {
            let shouldFilter = false
            l0ZoneList.forEach(l0Zone => {
              if (zone?.type?.includes(l0Zone.zone_uuid)) {
                shouldFilter = true
              }
            })
            return shouldFilter
          })
      }
      this.setState({
        l0ZoneList,
        l1ZoneList: l1ZoneList.filter(zone => zone.status !== "DISABLED"),
        l2ZoneList: l2ZoneList.filter(zone => zone.status !== "DISABLED"),
        loadingL1Zones: false,
        loadingL2Zones: false,
        loadingL0Zones: false,
      })
      return
    }
    if (data.level == "0") {
      const selectedIds = [...this.state.selectedIds, data.zone_uuid]
      const relationalIds = _.remove(this.state.relationalIds, zone => {
        return zone !== data.zone_uuid
      })
      const selectedZones = [...this.state.selectedZones, data]

      const l0ZoneList = [data]
      const types = [data.zone_uuid]

      this.setState({
        loadingL1Zones: true,
        loadingL2Zones: true,
        l0ZoneList,
        selectedIds,
        selectedZones,
        relationalIds,
      })
      // const l1ZoneList = await getZoneList({
      //   currentPage: 1,
      //   pageSize: 10000,
      //   level: "1",
      //   types,
      // })
      const l1ZoneList = this.props.allZonesList.filter(
        zone => zone.level == "1" && zone?.type?.includes(data.zone_uuid)
      )
      // const l2ZoneList = await getZoneList({
      //   currentPage: 1,
      //   pageSize: 10000,
      //   level: "2",
      //   types,
      // })
      const l2ZoneList = this.props.allZonesList.filter(
        zone => zone.level == "2" && zone?.type?.includes(data.zone_uuid)
      )
      this.setState({
        l1ZoneList: l1ZoneList.filter(zone => zone.status !== "DISABLED"),
        l2ZoneList: l2ZoneList.filter(zone => zone.status !== "DISABLED"),
        loadingL1Zones: false,
        loadingL2Zones: false,
      })
    }

    if (data.level === "1") {
      const selectedIds = [...this.state.selectedIds, data.zone_uuid]
      const selectedZones = [...this.state.selectedZones, data]

      const relationalIds = _.remove(this.state.relationalIds, zone => {
        return zone !== data.zone_uuid
      })

      const l1ZoneList = [data]
      const subTypes = [data.zone_uuid]

      this.setState({
        loadingL2Zones: true,
        selectedIds,
        selectedZones,
        l1ZoneList,
        relationalIds,
      })

      // const l2ZoneList = await getZoneList({
      //   currentPage: 1,
      //   pageSize: 10000,
      //   level: "2",
      //   subTypes,
      // })
      const l2ZoneList = this.props.allZonesList.filter(
        zone => zone.level == "2" && zone?.sub_type?.includes(data.zone_uuid)
      )
      this.setState({
        l2ZoneList: l2ZoneList.filter(zone => zone.status !== "DISABLED"),
        selectedZones,
        loadingL2Zones: false,
      })
    }

    if (data.level == "2") {
      const selectedIds = [...this.state.selectedIds, data.zone_uuid]
      const selectedZones = [...this.state.selectedZones, data]

      this.setState({ selectedIds, selectedZones })
    }
  }

  _renderLOZones = () => {
    return (
      <Col md="4">
        <div className="p-1 d-flex justify-content-center">Level 0</div>

        <div style={{ height: "50vh" }} className="border">
          <PerfectScrollbar>
            <div>
              {this.state.l0ZoneList.map(data => {
                return (
                  <ZoneAdditionCard
                    key={data.zone_uuid}
                    data={data}
                    selectedIds={this.state.selectedIds}
                    relationalIds={this.state.relationalIds}
                    handleSelection={this._handleSelection}
                    renderCardContent={this._renderCardContent}
                  />
                )
              })}
            </div>
          </PerfectScrollbar>
        </div>
      </Col>
    )
  }

  _renderL1Zones = () => {
    return (
      <Col md="4">
        <div className="p-1 d-flex justify-content-center">Level 1</div>
        <div style={{ height: "50vh" }} className="border">
          {this.state.loadingL1Zones ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Spin tip="Loading data..." />
            </div>
          ) : (
            <PerfectScrollbar>
              <div>
                {this.state.l1ZoneList.map(data => {
                  return (
                    <ZoneAdditionCard
                      key={data.zone_uuid}
                      data={data}
                      handleSelection={this._handleSelection}
                      selectedIds={this.state.selectedIds}
                      relationalIds={this.state.relationalIds}
                      renderCardContent={this._renderCardContent}
                    />
                  )
                })}
              </div>
            </PerfectScrollbar>
          )}
        </div>
      </Col>
    )
  }

  _renderL2Zones = () => {
    return (
      <Col md="4">
        <div className="p-1 d-flex justify-content-center">Level 2</div>
        <div style={{ height: "50vh" }} className="border">
          {this.state.loadingL2Zones ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Spin tip="Loading data..." />
            </div>
          ) : (
            <PerfectScrollbar>
              <div>
                {this.state.l2ZoneList.map(data => {
                  return (
                    <ZoneAdditionCard
                      key={data.zone_uuid}
                      data={data}
                      handleSelection={this._handleSelection}
                      selectedIds={this.state.selectedIds}
                      renderCardContent={this._renderCardContent}
                    />
                  )
                })}
              </div>
            </PerfectScrollbar>
          )}
        </div>
      </Col>
    )
  }

  _handleAddZones = async () => {
    this.setState({
      addingZones: true,
    })
    let ids = []
    let relationalIds = []
    this.state.selectedZones.forEach(zone => {
      ids.push(zone.zone_uuid)
      if (!_.isEmpty(zone.type)) {
        ids = [...ids, ...zone.type]
      }
      if (!_.isEmpty(zone.sub_type)) {
        ids = [...ids, ...zone.sub_type]
      }
    })
    ids = _.uniq(ids)

    let result = _.cloneDeep(
      this.props.allZonesList.filter(zone => ids.includes(zone.zone_uuid))
    )
    result.forEach(zone => {
      if (this.state.selectedIds.includes(zone.zone_uuid)) {
        zone.added_type = "input"
      } else {
        zone.added_type = "relational"
        relationalIds.push(zone.zone_uuid)
      }
    })
    this.setState({
      addedZones: result,
      addingZones: false,
      relationalIds,
      l0ZoneList: [...this.props.l0ZoneList],
      l1ZoneList: [],
      l2ZoneList: [],
    })
  }

  _renderCardContent = data => {
    const imgSource = data.zone_image
    return (
      <div className="card" style={{ width: "20rem" }}>
        {imgSource && (
          <img src={imgSource} alt="Card image cap" className="card-img-top" />
        )}
        <div className="card-body">
          <h5 className="card-title">{data.title}</h5>
          <p>{data.sub_title}</p>
        </div>
      </div>
    )
  }

  addZoneSuggession = async () => {
    if (!this.state?.zoneSuggessionTitle) {
      toastr.warning("Zone Title is missing!")
      return
    }

    if (!this.props?.selectedContent?.content_uuid) {
      toastr.warning("Content Id is missing!")
      return
    }

    const request = {
      title: this.state.zoneSuggessionTitle,
      content_id: this.props.selectedContent.content_uuid,
    }

    const response = await addZoneSuggession(request)
    if (response.status == 200) {
      toastr.success(
        response?.data?.message || "Zone Suggestion added successfully!"
      )
      this._handleDone()
      this.setState({
        showZoneSuggessionModel: false,
        zoneSuggessionTitle: "",
      })
    } else {
      toastr.error("Something went wrong.")
    }
  }

  render() {
    return (
      <>
        <div
          className="middle-container"
          style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
        >
          {this._renderAddedZones()}
          {this._renderSearchBar()}
          <Row className="mt-2 mx-1">
            {this._renderLOZones()}
            {this._renderL1Zones()}
            {this._renderL2Zones()}
          </Row>
          <div className="d-flex justify-content-center m-2">
            <div
              role="button"
              className="btn btn-primary mx-2"
              onClick={this._handleAddZones}
            >
              Add
            </div>
          </div>
        </div>
        {this.state.showZoneSuggessionModel ? (
          <Modal
            show={this.state.showZoneSuggessionModel}
            onHide={() => {
              this.setState({
                showZoneSuggessionModel: false,
                zoneSuggessionTitle: "",
              })
            }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <CardTitle className="font-size-18 text-wrap">
                  Add Zone Suggession
                </CardTitle>
              </Modal.Title>
            </Modal.Header>
            <div className="m-3 p-3">
              <CardTitle className="text-wrap text-center">
                <div className="font-size-16">Not able to find a Zone?</div>
                <div className="font-size-14">
                  Please enter a zone suggession if any in the below box
                </div>
              </CardTitle>
              <div className="form-group purple-border">
                <input
                  className="form-control bg-gradient font-size-15"
                  onChange={e => {
                    this.setState({
                      zoneSuggessionTitle: e.target.value,
                    })
                  }}
                  value={this.state.zoneSuggessionTitle}
                  placeholder="Enter Title"
                  required
                />
              </div>
            </div>
            <Modal.Footer>
              <Button
                className="mx-2"
                color="danger"
                onClick={this._handleDone}
              >
                Not sure
              </Button>
              <Button
                color="primary"
                onClick={this.addZoneSuggession}
                disabled={!this.state.zoneSuggessionTitle}
              >
                Add Suggession
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    )
  }
}
function mapStateToProps(store) {
  const zoneList =
    store.zone?.zoneList?.filter(zone => zone.status !== "DISABLED") || []
  const allZonesList =
    store.zone?.allZoneList?.filter(zone => zone.status !== "DISABLED") || []
  const l0ZoneList = zoneList.filter(zone => zone.level == "0")
  const l1ZoneList = zoneList.filter(zone => zone.level == "1")
  const l2ZoneList = zoneList.filter(zone => zone.level == "2")
  return {
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "content.loading"),
    currentData: _.get(store, "taxonomy.zone"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
    zoneList,
    l0ZoneList,
    l1ZoneList,
    l2ZoneList,
    allZonesList,
  }
}

export default connect(mapStateToProps)(Zone)
