import Axios from "axios"

export const getStickersForTabSubTab = async params => {
  try {
    let data = await Axios.get(
      `/api/stickerAssets/getStickersForTabSubTabReorder?tabId=${params.tabId}&subTabId=${params.subTabId}&selectedFilter=${params.selectedFilter}`
    )
    return data?.data
  } catch (error) {
    throw error
  }
}

export const getStickersForNextPage = async params => {
  try {
    let data = await Axios.get(
      `/api/stickerAssets/getStickersForNextPage?url=${params}`
    )
    return data.data.data
  } catch (error) {
    throw error
  }
}

// export const getStickersCount = async (params) => {
//     try {
//         let data = await Axios.get(`/api/stickerAssets/getStickersCount?isActive=${params.isActive}&mimeTypes=${params.mimeType}&selectedFilter=${params.selectedFilter}`);
//         return data.data.data;
//     } catch (error) {
//       throw error;
//     }
// }
