import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { Button } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import PackageCollectionEntityForm from "./packageCollectionEntityForm"
import PackageCard from "./packageCard"
import {
  getPackage,
  resetState,
  // upsertGift,
  upsertPackageCollectionEntity,
  deletePackage,
} from "../../appRedux/slices/package"
import Chips from "components/CommonFilter/chips"
import {
  packageCollectionEntityFilters,
  NO_PERMISSION_MESSAGE,
} from "constants/uiConstants"
import Auxiliary from "../../util/Auxiliary"
import { Divider, Input, Pagination, Tabs } from "antd"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import "../Comment/browseComment.style.css"

const FILTER_KEYS = [
  {
    key_name: "packageCollectionEntityId",
    display_name: "Package Collection Entity Id",
  },
]

class ManagePackageCollectionEntities extends ErrorBoundComponent {
  state = {
    showModal: false,
    showOrder: false,
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      type:
        this.props.queryParams && this.props.queryParams["type"]
          ? this.props.queryParams["type"]
          : "PACKAGE_COLLECTION_ENTITY",
      packageCollectionId:
        this.props.queryParams && this.props.queryParams["packageCollectionId"]
          ? this.props.queryParams["packageCollectionId"]
          : "",
      packageCollectionEntityId:
        this.props.queryParams &&
        this.props.queryParams["packageCollectionEntityId"]
          ? this.props.queryParams["packageCollectionEntityId"]
          : "",
    },
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getPackage({ filters: _.deepClean(filters) }))
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentDidUpdate() {
    if (this.props.refetch) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getPackage({ filters: _.deepClean(filters) }))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
      })
    }
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/packageCollection/entities/manage?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      ),
        this.props.dispatch(getPackage({ filters: _.deepClean(newFilters) }))
    })
  }

  _getTitle = () => {
    const type = this.props.queryParams.type
    const typeToLowerCase = type.replace(/_|-|\./g, " ").toLowerCase()
    return (
      <h4>
        {typeToLowerCase.charAt(0).toUpperCase() + typeToLowerCase.slice(1)}
      </h4>
    )
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value

    this.setState({
      filters: newFilters,
    })
  }

  _searchCollection = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/packageCollection/entities/manage?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    ),
      this.props.dispatch(getPackage({ filters: _.deepClean(filters) }))
  }

  _closeFilters = () => {
    this._searchCollection()
    this.setState({
      showFilters: false,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: packageCollectionEntityFilters[filter].defaultValue,
    }

    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/packageCollection/entities/manage?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        this.props.dispatch(
          getPackage({ filters: _.deepClean(this.state.filters) })
        )
      }
    )
  }

  _onSubmit = async payload => {
    let response
    response = await this.props.dispatch(
      upsertPackageCollectionEntity({ payload, action: "update" })
    )

    this.setState({ showModal: false, entityData: null })
  }

  _modal = () => {
    return (
      <div className="view_container">
        <PackageCollectionEntityForm
          pageId={this.state.currentPageId}
          type={
            this.props.queryParams && this.props.queryParams.type
              ? this.props.queryParams.type
              : "PACKAGE_COLLECTION_ENTITY"
          }
          onSubmit={this._onSubmit}
          data={this.state.entityData}
          action={this.state.action}
          onCancel={() => this.setState({ showModal: false, entityData: null })}
        />
      </div>
    )
  }

  _onEdit = entityData => {
    this.setState({ entityData, showModal: true, action: "update" })
  }

  _onDelete = entityData => {
    const { package_entity_uuid } = entityData
    const payload = {
      deletedId: package_entity_uuid,
      type: "PACKAGE_COLLECTION_ENTITY",
    }

    this.props.dispatch(deletePackage({ payload }))
    this.props.history.push(
      `/packageCollection/entities/manage?packageCollectionId=${this.state?.filters?.packageCollectionId}`
    )
  }

  _onAdd = selectedElements => {
    const payload = {}
    if (selectedElements) {
      payload.entities = selectedElements
    }
    payload.package_collection_uuid = this.state?.filters?.packageCollectionId
    this.props.dispatch(
      upsertPackageCollectionEntity({ payload, action: "add" })
    )
    this.props.history.push(
      `/packageCollection/entities/manage?packageCollectionId=${this.state?.filters?.packageCollectionId}`
    )
  }

  _buttons = () => {
    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex my-2">
          <Button
            size="sm"
            color="primary"
            className="font-size-12 text-truncate"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes("EDIT_PACKAGE")
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.props.history.push(`/packageCollections/manage`)
            }}
          >
            <i className="fa fa-chevron-left mx-1 my-1" />
            Back
          </Button>
        </div>
        <div className="d-flex my-2">
          <Button
            size="sm"
            color="primary"
            className="font-size-12 text-truncate me-2"
            onClick={() => {
              if (
                !this.props.currentUser?.permissions?.includes("EDIT_PACKAGE")
              ) {
                toastr.error(NO_PERMISSION_MESSAGE)
                return
              }
              this.setState({ showSeachAndSelectModal: true })
            }}
          >
            <i className="fa fa-plus mx-1 my-1" />
            Add
          </Button>

          <Button
            onClick={() => {
              this.props.dispatch(
                getPackage({ filters: _.deepClean(this.state.filters) })
              )
            }}
            color="primary"
            size="sm"
            className="font-size-12 text-truncate"
          >
            <i className="fa fa-undo" /> Refresh
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Auxiliary
          loading={this.props.loading}
          error={_.get(this.props, "common.error")}
        >
          {!this.state.showModal && (
            <React.Fragment>
              {this._buttons()}
              <Chips
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                search={this._closeFilters}
                removeFilter={this._removeFilter}
                handleFilterChange={this._handleFilterChange}
                type="manage_package_collection_entity"
                toggleFilters={this._toggleFilters}
                filterKeys={FILTER_KEYS}
              />
              <div className="discovery-card-container">
                {this.props.entityList &&
                  this.props.entityList.map((entity, i) => {
                    return (
                      <PackageCard
                        data={entity}
                        onEditClick={this._onEdit}
                        currentUser={this.props.currentUser}
                        onDeleteClick={this._onDelete}
                        isDeleteEnable={true}
                      />
                    )
                  })}
              </div>
              {this.props.entityList && this.props.entityList.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Pagination
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                    onChange={this._onPageChange}
                    defaultCurrent={this.state.filters.currentPage}
                    total={this.props.total < 10000 ? this.props.total : 10000}
                    pageSize={this.state.filters.pageSize}
                    showSizeChanger={false}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </Auxiliary>
        {this.state.showModal && this._modal()}
        {this.state.showSeachAndSelectModal && (
          <SearchAndSelect
            type="PACKAGE"
            visible={this.state.showSeachAndSelectModal}
            onClose={() => this.setState({ showSeachAndSelectModal: false })}
            onAdd={arr => this._onAdd(arr)}
            // element={this.state.element}
            selectedArr={_.uniq(
              this.props.entityList.map(a => a.package_element_uuid)
            )}
          />
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "package.total"),
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "package.loading"),
    entityList: _.get(store, "package.entities"),
    refetch: _.get(store, "package.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(ManagePackageCollectionEntities)
