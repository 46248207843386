import { Popover } from "antd"
import PropTypes from "prop-types"
import React from "react"
import { InfoCircleOutlined, GoogleOutlined } from "@ant-design/icons"
import "./SelectedZoneCards.css"

const ZoneCard = props => {
  const { data, actionDisabled = false } = props
  const borderColor =
    props.isSelected === "removed"
      ? "border-danger"
      : props.isSelected === "selected"
      ? "border-success"
      : ""
  const imgSource =
    data.help && data.help.thumbnail
      ? data.help.thumbnail
      : props.thumbnail
      ? props.thumbnail
      : data.zone_image
      ? data.zone_image
      : "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/default/default.jpg"
  return (
    <>
      <div
        className={`card border border-2 ${borderColor}`}
        style={{ width: "11rem", margin: "1rem", height: "16rem" }}
      >
        <img
          className="card-img-top"
          style={{ height: "176px" }}
          alt="..."
          src={imgSource}
        />
        <div className="card-body p-0">
          <div className="d-flex align-items-center">
            <div className="card-title m-2 card-data" style={{ flex: 1 }}>
              {data.title}
            </div>
            <div className="d-flex align-items-baseline mx-2">
              {data.zone_image && (
                <Popover
                  trigger="hover"
                  color="#D3D3D3"
                  content={() => props.renderCardContent(data)}
                  destroyTooltipOnHide
                >
                  <InfoCircleOutlined size={60} className="mx-2" />
                </Popover>
              )}

              <a
                href={`https://www.google.com/search?q=${data.title}&igu=1&tbm=isch`}
                target="_blank"
                rel="noreferrer"
              >
                <GoogleOutlined size={60} />
              </a>
            </div>
          </div>
          {!actionDisabled ? (
            <div className="d-flex">
              <div
                role="button"
                className={`border px-2 pt-1 ${
                  props.isSelected === "selected" ? "border-success" : ""
                }`}
                style={{ flex: 1, textAlign: "center" }}
                onClick={() =>
                  props._handleSelection(data, "APPROVED", props.action)
                }
              >
                <i
                  className={`fa fa-check-circle fa-2x text-${
                    props.isSelected === "selected" ? "black-50" : "success"
                  }`}
                />
              </div>
              <div
                role="button"
                className={`border px-2 pt-1 ${
                  props.isSelected === "removed" ? "border-danger" : ""
                }`}
                style={{ flex: 1, textAlign: "center" }}
                onClick={() =>
                  props._handleSelection(data, "REMOVED", props.action)
                }
                disabled={!props.isSelected}
              >
                <i
                  className={`fas fa-times-circle fa-2x text-${
                    props.isSelected === "removed" ? "black-50" : "danger"
                  }`}
                />
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <div
                role="button"
                className={`border px-2 pt-1 ${
                  props.isSelected === "selected" ? "border-success" : ""
                }`}
                style={{ flex: 1, textAlign: "center" }}
                // onClick={() =>
                //   props._handleSelection(data, "APPROVED", props.action)
                // }
                disabled={actionDisabled}
              >
                <i
                  className={`fa fa-check-circle fa-2x text-black-50`}
                  disabled={true}
                />
              </div>
              <div
                role="button"
                className={`border px-2 pt-1 ${
                  props.isSelected === "removed" ? "border-danger" : ""
                }`}
                style={{ flex: 1, textAlign: "center" }}
                // onClick={() =>
                //   props._handleSelection(data, "REMOVED", props.action)
                // }
                // disabled={!props.isSelected}
                disabled={true}
              >
                <i
                  className={`fas fa-times-circle fa-2x text-black-50`}
                  disabled={actionDisabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

ZoneCard.PropTypes = {
  data: PropTypes.object.isRequired,
  _handleSelection: PropTypes.func,
  renderCardContent: PropTypes.func,
}

export default ZoneCard
