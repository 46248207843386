import { Tooltip } from "antd"
import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Card, CardBody, CardImg } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { LANGUAGES, NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import "./styles.css"
import { Chip } from "@mui/material"

class SeriesCard extends React.Component {
  state = { isPlaying: false, isFlipped: false, isPlayedFirst: true }
  audioRef = React.createRef()

  _getLanguage = langCode => {
    const [language] = LANGUAGES.filter(langObj => langObj.value === langCode)
    return language && language.label
  }

  _playPreview = () => {
    if (this.state.isPlayedFirst) {
      this.audioRef.currentTime = this.props.series.default_start_selection_ms
        ? this.props.series.default_start_selection_ms / 1000
        : 0
      this.setState({ isPlayedFirst: false })
    }
    var that = this

    var pauseAudioAt = function (aud, seconds, listenerToRemove) {
      // check whether we have passed 5 minutes,
      // current time is given in seconds
      if (aud.currentTime >= seconds) {
        // pause the playback
        aud.pause()
        if (typeof listenerToRemove === "function") {
          aud.removeEventListener("timeupdate", listenerToRemove)
          that.setState({ isPlaying: false, isPlayedFirst: true })

          // showBtn();
        }
      }
    }
    this.audioRef.addEventListener("timeupdate", function listener1() {
      pauseAudioAt(
        this,
        that.props.series.default_end_selection_ms / 1000,
        listener1
      )
    })

    if (!this.state.isPlaying) {
      this.setState({ isPlaying: true })
      this.audioRef.play()
    } else {
      this.setState({ isPlaying: false })
      this.audioRef.pause()
      this.audioRef.removeEventListener("timeupdate", function () {})
    }
  }

  render() {
    const { series, audioDetails = [], customClassname = "" } = this.props

    return (
      <Card
        className={`${customClassname} text-center shadow-lg`}
        style={{
          minHeight: "280px",
          borderRadius: "10px",
          width: "80%",
        }}
      >
        <div className="bg-primary bg-soft" onClick={() => {}}>
          <div>
            {series && series.cover_url ? (
              <div
                role="button"
                id={"play" + series.story_id}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  this._playPreview()
                }}
                style={{ height: "15rem" }}
              >
                <CardImg
                  top
                  className="img-fluid"
                  src={series.cover_url}
                  alt="Skote"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  style={{
                    height: "15rem",
                    cursor: "pointer",
                    opacity: "0.8",
                  }}
                />
              </div>
            ) : (
              <div
                className="py-5"
                style={{
                  height: "15rem",
                  cursor: "pointer",
                  backgroundColor: "lightgray",
                }}
              >
                <i className="fas fa-volume-mute fa-7x py-4 text-secondary" />
              </div>
            )}
          </div>
        </div>
        <CardBody style={{ padding: "1rem 0.25rem" }}>
          <Tooltip title={series.title}>
            <div className="font-size-14 text-truncate font-weight-semibold">
              {series.title}
            </div>
          </Tooltip>
          {series.status ? (
            <Tooltip title={"Status"}>
              <Chip
                color="primary"
                variant="outlined"
                size="small"
                label={_.capitalize(series.status)}
              />
            </Tooltip>
          ) : null}
          {series.story_count ? (
            <Tooltip title={"Story Count"}>
              <Chip
                color="primary"
                variant="outlined"
                size="small"
                className="m-2"
                label={_.capitalize(series.story_count)}
              />
            </Tooltip>
          ) : null}
          {series.type && (
            <Tooltip title={"Type"}>
              <div className="font-size-14 text-truncate font-weight-semibold">
                {series.type}
              </div>
            </Tooltip>
          )}
          {series.collection_id && (
            <Tooltip title={"Collection ID"}>
              <div className="font-size-14 text-truncate font-weight-semibold">
                {series.collection_id}
              </div>
            </Tooltip>
          )}
        </CardBody>
        <div
          className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <div className="flex-fill">
            <CopyToClipboard
              text={series.series_id}
              onCopy={e => {
                toastr.success("Copied sucessfully!")
              }}
            >
              <Tooltip title="Copy Series ID">
                <i className="bx bx-copy" role="button" />
              </Tooltip>
            </CopyToClipboard>
          </div>
          <div className="flex-fill">
            <Tooltip title="Edit">
              <i
                className="bx bx-edit-alt"
                role="button"
                onClick={() => {
                  // if (
                  //   !this.props.currentUser?.permissions?.includes(
                  //     "EDIT_MANAGE_AUDIO"
                  //   )
                  // ) {
                  //   toastr.error(NO_PERMISSION_MESSAGE)
                  //   return
                  // }
                  this.props.onEditClick("update", series)
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Card>
    )
  }
}

export default SeriesCard
