import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Button } from "@mui/material"
import { Tabs } from "antd"
import CreateThemeficationForm from "./CreateThemeficationForm"
import ThemeficationList from "./ThemeficationList"
import CheckItems from "./CheckItems"

class Themefication extends ErrorBoundComponent {
  state = {
    iscreateThemeForm: false,
    isValidItemCheck: false,
    isListThemification: true,
    currentRow: undefined,
  }

  componentDidMount() {
    if (
      !this.props.common.error &&
      this.state.filters &&
      !_.isEmpty(this.state.filters)
    ) {
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _toggleThemeForm = () => {
    this.setState({
      currentRow: undefined,
      iscreateThemeForm: !this.state.iscreateThemeForm,
      isListThemification: !this.state.isListThemification,
      isValidItemCheck: false,
    })
  }

  _toggleListForm = () => {
    this.setState({
      currentRow: undefined,
      iscreateThemeForm: false,
      isListThemification: !this.state.isListThemification,
      isValidItemCheck: !this.state.isValidItemCheck,
    })
  }
  setCurrentRow = row => {
    this.setState({
      currentRow: row,
      iscreateThemeForm: !this.state.iscreateThemeForm,
      isListThemification: !this.state.isListThemification,
    })
  }
  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <>
          {this.state.isListThemification && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {" "}
              <Button
                // className="btn btn-primary"
                variant="contained"
                onClick={() => {
                  this._toggleThemeForm()
                  //   this.props.history.push(`/feed/interests/manage`)
                }}
                style={{ marginBottom: "15px", marginRight: "10px" }}
              >
                Create Themification
              </Button>
              <Button
                // className="btn btn-primary"
                variant="contained"
                onClick={() => {
                  this._toggleListForm()
                  //   this.props.history.push(`/feed/interests/manage`)
                }}
                style={{ marginBottom: "15px" }}
              >
                Check Items
              </Button>
            </div>
          )}

          {this.state.isListThemification && (
            <ThemeficationList setCurrentRow={this.setCurrentRow} />
          )}
          {this.state.iscreateThemeForm && (
            <CreateThemeficationForm
              toggleCreateForm={this._toggleThemeForm}
              currentRow={this.state.currentRow}
            />
          )}
          {this.state.isValidItemCheck && (
            <CheckItems
              toggleListForm={this._toggleListForm}
              currentRow={this.state.currentRow}
            />
          )}
        </>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(Themefication)
