import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import {
  upsertCMSVideoGreetings,
  resetState,
  setCaptions,
} from "../../appRedux/slices/cmsVideoTemplates"
import { Col, Row } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import _ from "lodash"
import UpsertCMSVideoGreetingsForm from "./upsertCMSVideoGreetingsForm"
import { getFontFamilies, getItemFile } from "clientServices/greetingService"
import Auxiliary from "util/Auxiliary"
import { updateImage } from "clientServices/imageService"
import toastr from "toastr"
import { updateContent } from "clientServices/contentService"
import { LoadingOutlined } from "@ant-design/icons"
import { Button } from "@mui/material"

class ManageCMSVideoGreetings extends ErrorBoundComponent {
  state = {
    filters: {
      itemId:
        this.props.queryParams && this.props.queryParams["item_uuid"]
          ? this.props.queryParams["item_uuid"]
          : null,
    },
    showUpsertModal: true,
    formAction: "create",
    videoTemplate: {},
    fontFamilies: {},
    loading: false,
    updatingCaptions: false,
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      const fontFamilies = await getFontFamilies()
      this.setState({
        fontFamilies,
      })

      if (this.state.filters.itemId) {
        this._processItemId()
      }
    }
  }

  _processItemId = async () => {
    this.setState({
      loading: true,
    })
    const content = await getItemFile(this.state.filters.itemId)
    const response = await fetch(content.preview_url)
    const blob = await response.blob()
    const itemFile = new File(
      [blob],
      `item.${content.fileType === "image" ? ".png" : ".mp4"}`,
      {
        type: blob.type,
      }
    )
    this.setState(
      {
        itemFile,
        content,
      },
      () => {
        this.setState({
          loading: false,
        })
      }
    )
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onSubmit = async formData => {
    this.props.dispatch(upsertCMSVideoGreetings(formData))
  }

  _setCaptions = captions => {
    this.props.dispatch(setCaptions(captions))
  }

  _closeUpsertModal = () => {
    this.setState({
      showUpsertModal: false,
      formAction: "create",
      videoTemplate: {},
    })
  }

  _onCreateVideoTemplate = () => {
    this.setState({
      showUpsertModal: true,
      formAction: "create",
      videoTemplate: {},
    })
  }

  _onEditVideoTemplate = videoTemplate => {
    this.setState({
      showUpsertModal: true,
      formAction: "update",
      videoTemplate: videoTemplate,
    })
  }

  _upsertModal = () => {
    return (
      <div className="view_container">
        <UpsertCMSVideoGreetingsForm
          captions={
            this.state.content?.captions
              ? JSON.parse(this.state.content.captions)
              : ""
          }
          onSubmit={this._onSubmit}
          setCaptions={this._setCaptions}
          previewFile={this.props.previewFile}
          fontFamilies={this.state.fontFamilies}
          loading={this.props.loading}
          itemFile={this.state.itemFile}
          itemId={this.state.filters.itemId}
          content={this.state.content}
        />
      </div>
    )
  }

  _handleItemIdChange = id => {
    this.props.history.push(`/cmsVideoGreetings/manage?item_uuid=${id}`)
    this.props.dispatch(resetState())
    this.setState(
      {
        filters: {
          ...this.state.filters,
          itemId: id,
        },
      },
      () => this._processItemId()
    )
  }

  _updateCaptions = async () => {
    this.setState({
      updatingCaptions: true,
    })
    if (this.state.filters.itemId.startsWith("i_")) {
      let formData = {
        captions: JSON.stringify(this.props.captions),
        image_uuid: this.state.filters.itemId,
      }
      let data = new FormData()
      data.append("action", "UPDATE")
      data.append("details", JSON.stringify(formData))
      toastr.info("Action in progress ...")
      try {
        await updateImage(data)
      } catch (error) {
        toastr.error("Action failed ...")
      }
    } else {
      const { content } = this.state
      let contentData = {
        content_title: content.content_title,
        captions: JSON.stringify(this.props.captions),
      }
      toastr.info("Action in progress ...")
      try {
        await updateContent({
          contentId: this.state.content.content_uuid,
          contentData,
        })
      } catch (error) {
        toastr.error("Action failed ...")
      }
    }
    this.setState({
      updatingCaptions: false,
    })
  }

  render() {
    return (
      <Auxiliary
        loading={this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        <Row className="p-0 mb-2">
          <Col lg="4">
            <div>
              <input
                className="form-control"
                type="text"
                onChange={e => this._handleItemIdChange(e.target.value)}
                defaultValue={this.state.filters.userUuid}
                placeholder="Content ID / Image ID"
                value={this.state.filters.itemId}
              />
            </div>
          </Col>
          {this.state.filters.itemId && (
            <Col>
              <Button
                variant="contained"
                htmlType="submit"
                disabled={
                  this.props.loading ||
                  !this.props.previewFile ||
                  this.state.updatingCaptions
                }
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={this._updateCaptions}
              >
                {this.state.updatingCaptions ? (
                  <div>
                    Updating ... <LoadingOutlined />
                  </div>
                ) : (
                  "Update Captions"
                )}
              </Button>
            </Col>
          )}
        </Row>
        <div>{this._upsertModal()}</div>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    cmsVideoGreetings: _.get(store, "cmsVideoTemplates.cmsVideoGreetings"),
    refetchData: _.get(store, "cmsVideoTemplates.refetchData"),
    loading: _.get(store, "cmsVideoTemplates.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    previewFile: _.get(store, "cmsVideoTemplates.previewFile"),
    captions: _.get(store, "cmsVideoTemplates.captions"),
  }
}

export default connect(mapStateToProps)(ManageCMSVideoGreetings)
