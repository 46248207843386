import React from "react"
import { Container, Card } from "reactstrap"
import { connect } from "react-redux"
import QueryString from "query-string"
import moment from "moment"
import _ from "lodash"
import { Button, Input, Row, Select, DatePicker, notification } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

import {
  getAgencyLevelStats,
  getLanguageLevelStats,
  getAgentByLanguage,
  getUniqueTaggedAndQCTaggedContainData,
  getAgentListByAgency,
} from "../../appRedux/slices/content"
import Auxiliary from "../../util/Auxiliary"
import "./taxonomyReport.css"
import Chart from "../../components/Charts/Chart"
import { LANGUAGE_MAPPING, LANGUAGES } from "../../constants/uiConstants"
import dayjs from "dayjs"

const { Option } = Select

const LAST_1_HOUR = "1H"
const LAST_1_DAY = 1
const LAST_3_DAYS = 3
const LAST_7_DAYS = 7
const LAST_15_DAYS = 15

const ALL_AGENCY = "ALL"
const OPPORTURE_AGENCY = "OPPORTURE"
const SRMFILMS_AGENCY = "SRMFILMS"
const HEXAWARE_AGENCY = "HEXAWARE"

const LANGUAGES_LIST = [{ value: "all", label: "All" }, ...LANGUAGES]

class TaxonomyReport extends React.Component {
  constructor(props) {
    super(props)
    const isInternalUser =
      this.props.currentUser && this.props.currentUser?.userType < 4
        ? true
        : false
    const isAdmin =
      this.props.currentUser && this.props.currentUser?.userType < 4
        ? true
        : this?.props?.currentUser?.permissions.includes("AGENCY_ADMIN")
        ? true
        : false
    const isAuthorised =
      this.props.currentUser && this.props.currentUser?.userType < 4
        ? true
        : this?.props?.currentUser?.permissions.includes("AGENCY_ADMIN") ||
          this?.props?.currentUser?.permissions.includes("TAG_CONTENT") ||
          this?.props?.currentUser?.permissions.includes("QC_TAG_CONTENT") ||
          this?.props?.currentUser?.permissions.includes(
            "INTERNAL_QC_TAG_CONTENT"
          )
        ? true
        : false
    this.agency = !(
      this.props.currentUser && this.props.currentUser?.userType < 4
    )
      ? this.props.currentUser.agency
        ? this.props.currentUser.agency
        : ALL_AGENCY
      : OPPORTURE_AGENCY
    this.days = LAST_1_DAY
    this.langCode = "all"
    this.dateFrom = null
    this.dateTo = null
    this.agent =
      this.props.currentUser && this.props.currentUser?.userType < 4
        ? null
        : this?.props?.currentUser?.permissions.includes("AGENCY_ADMIN")
        ? null
        : (this.props.currentUser && this.props.currentUser.email) || null
    const query_agency =
      this.props.queryParams && this.props.queryParams["agency"]
        ? this.props.queryParams["agency"]
        : ""
    const query_days =
      this.props.queryParams && this.props.queryParams["days"]
        ? this.props.queryParams["days"]
        : ""
    const query_langCode =
      this.props.queryParams && this.props.queryParams["langCode"]
        ? this.props.queryParams["langCode"]
        : ""
    const query_agent =
      this.props.queryParams && this.props.queryParams["agent"]
        ? this.props.queryParams["agent"]
        : null
    const query_dateFrom =
      this.props.queryParams && this.props.queryParams["dateFrom"]
        ? this.props.queryParams["dateFrom"]
        : null
    const query_dateTo =
      this.props.queryParams && this.props.queryParams["dateTo"]
        ? this.props.queryParams["dateTo"]
        : null

    if (isAuthorised && query_agency) {
      if (isInternalUser || isAdmin) {
        if (isInternalUser && query_agency && this.agency !== query_agency) {
          this.agency = query_agency
          this.days = query_days
          this.langCode = query_langCode
          this.agent = query_agent
          this.dateFrom = query_dateFrom || null
          this.dateTo = query_dateTo || null
        } else if (query_agency && this.agency === query_agency) {
          this.agency = query_agency
          this.days = query_days
          this.langCode = query_langCode
          this.agent = query_agent
          this.dateFrom = query_dateFrom || null
          this.dateTo = query_dateTo || null
        } else {
          this.props.history.push(`/tasks/taxonomy/dashboard`)
        }
      } else if (
        query_agency &&
        query_agent &&
        this.agency === query_agency &&
        this.agent === query_agent
      ) {
        this.agency = query_agency
        this.days = query_days
        this.langCode = query_langCode
        this.agent = query_agent
        this.dateFrom = query_dateFrom || null
        this.dateTo = query_dateTo || null
      } else {
        this.props.history.push(`/tasks/taxonomy/dashboard`)
      }
    }

    this.state = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      isInternalUser,
      agency: this.agency,
      agent: this.agent,
      isAdmin,
      isAuthorised,
      days: this.days,
      langCode: this.langCode,
      languageChartFilter: {
        agency: this.agency,
        days: this.days,
        langCode: this.langCode,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      },
      agentByLanguageChartFilter: {
        agency: this.agency,
        days: this.days,
        langCode: this.langCode,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      },
      agentChartFilter: {
        agency: this.agency,
        days: this.days,
        email: this.agent,
        langCode: this.langCode,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      },
      uniqueTaggedQCTaggedContainChartFilter: {
        agency: this.agency,
        days: this.days,
        langCode: this.langCode,
        isInternal: isInternalUser,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      },
    }
  }

  componentDidMount() {
    if (!this.props.common.error) {
      ;(this.state.isInternalUser ||
        (this.state.isAuthorised && this.state.isAdmin)) &&
        this.props.dispatch(
          getLanguageLevelStats(_.deepClean(this.state.languageChartFilter))
        )
      ;(this.state.isInternalUser ||
        (this.state.isAuthorised && this.state.isAdmin)) &&
        this.props.dispatch(
          getAgentByLanguage(_.deepClean(this.state.agentByLanguageChartFilter))
        )
      this.state.agent &&
        this.props.dispatch(
          getAgencyLevelStats(_.deepClean(this.state.agentChartFilter))
        )
      ;(this.state.isInternalUser ||
        (this.state.isAuthorised && this.state.isAdmin)) &&
        this.props.dispatch(
          getUniqueTaggedAndQCTaggedContainData(
            _.deepClean(this.state.uniqueTaggedQCTaggedContainChartFilter)
          )
        )
      ;(this.state.isInternalUser ||
        (this.state.isAuthorised && this.state.isAdmin)) &&
        this.props.dispatch(
          getAgentListByAgency(_.deepClean(this.state.agentChartFilter))
        )
    }
  }

  _noChartDataCard = (
    title,
    setCustomStyle = false,
    { height = "422px", marginTop = "28px", fontSize = "19px" } = {}
  ) => {
    return (
      <div
        className="noDataChart"
        style={
          setCustomStyle
            ? {
                height,
                marginTop,
                fontSize,
              }
            : {}
        }
      >
        <div className="chartTitle">{title}</div>
        <div className="noDataTitle">No Data Available</div>
      </div>
    )
  }

  _renderChartForLanguage = () => {
    const { languageStats = [] } = this.props || {}
    const language = []
    const langCount = []
    languageStats &&
      languageStats.map(lang => {
        language.push(LANGUAGE_MAPPING[lang.key] || lang.key)
        langCount.push(lang.doc_count)
      })
    var data = [
      {
        values: langCount,
        labels: language,
        texttemplate: "%{label}: %{value}",
        type: "pie",
        textposition: "inside",
      },
    ]

    return (
      <Card>
        {(languageStats && languageStats.length) ||
        this.props.loadingLanguageChart ? (
          <Auxiliary loading={this.props.loadingLanguageChart}>
            <Chart
              title="Language Wise Tag Content"
              data={data}
              key={this.props.loadingLanguageChart}
            />
          </Auxiliary>
        ) : (
          this._noChartDataCard("Language Wise Tag Content")
        )}
      </Card>
    )
  }

  _renderChartForAgentByLanguage = () => {
    const { agentByLangStat = [] } = this.props || {}
    const email = []
    const content = []
    agentByLangStat &&
      agentByLangStat.map(agentData => {
        email.push(agentData.key.split("@")[0].toUpperCase())
        content.push(agentData.doc_count)
      })
    var data = [
      {
        values: content,
        labels: email,
        type: "pie",
        texttemplate: "%{label}: %{value}",
        textposition: "inside",
      },
    ]

    return (
      <Card>
        {(agentByLangStat && agentByLangStat.length) ||
        this.props.loadingAgentByLangChart ? (
          <Auxiliary loading={this.props.loadingAgentByLangChart}>
            <Chart
              title={
                this.state.agentByLanguageChartFilter.langCode === "all"
                  ? "Tag Content By Top 15 Agents"
                  : "Language Wise Tag Content By Agents"
              }
              data={data}
              key={this.props.loadingAgentByLangChart}
            />
          </Auxiliary>
        ) : (
          this._noChartDataCard(
            this.state.agentByLanguageChartFilter.langCode === "all"
              ? "Tag Content By Top 15 Agents"
              : "Language Wise Tag Content By Agents"
          )
        )}
      </Card>
    )
  }

  setAgent = email => {
    this.setState(
      prevState => ({
        agentChartFilter: { ...prevState.agentChartFilter, email },
        agent: email,
      }),
      () => {
        this.state.agent &&
          this.props.dispatch(
            getAgencyLevelStats(_.deepClean(this.state.agentChartFilter))
          )
        this.props.history.push(
          `/tasks/taxonomy/dashboard?${QueryString.stringify(
            _.deepClean({
              agency: this.state.agency,
              langCode: this.state.langCode,
              agent: this.state.agent,
              days: this.state.days,
              dateFrom: this.state.dateFrom,
              dateTo: this.state.dateTo,
            })
          )}`
        )
      }
    )
  }

  _renderChartForAgent = () => {
    const { agencyStat = [] } = this.props || {}
    const date = []
    const content = []
    agencyStat &&
      agencyStat.map(agentData => {
        date.push(agentData.key_as_string.split("T")[0])
        content.push(agentData.doc_count)
      })

    var data = [
      {
        y: content,
        x: date,
        type: "bar",
      },
    ]

    const layout = {
      height: 400,
      margin: {
        b: 40,
      },
    }

    const title =
      this.state.isInternalUser || this.state.isAdmin
        ? "Agent Wise Tag Content"
        : "Tag Content History"

    return (
      <Card>
        {this.state.agent &&
        ((agencyStat && agencyStat.length) || this.props.loadingAgentChart) ? (
          <Auxiliary loading={this.props.loadingAgentChart}>
            <Chart
              title={title}
              data={data}
              layout={layout}
              key={this.props.loadingAgentChart}
            />
          </Auxiliary>
        ) : (
          this._noChartDataCard(title, true, { height: "370px" })
        )}

        {this.state.isInternalUser ||
        (this.state.isAuthorised && this.state.isAdmin) ? (
          <Select
            showSearch
            allowClear={true}
            placeholder="agent"
            onChange={value => {
              this.setAgent(value)
            }}
            className="input_select"
            size="small"
            value={this.state.agent}
          >
            {this.props.agentList?.map(agent => (
              <Option value={agent.email}>{agent.name}</Option>
            )) || []}
          </Select>
        ) : null}
      </Card>
    )
  }

  getFormattedUniqueContentData = (uniqueContentdata = []) => {
    const formattedData = []
    if (
      (this.state.uniqueTaggedQCTaggedContainChartFilter.agency &&
        this.state.uniqueTaggedQCTaggedContainChartFilter.agency ===
          ALL_AGENCY) ||
      this.state.uniqueTaggedQCTaggedContainChartFilter.isInternal
    ) {
      const lables = []
      const qcTagContentData = []
      const internalQCTagContentData = []
      const tagContentData = []
      const skipTagContentData = []
      uniqueContentdata.forEach(data => {
        lables.push(data.key_as_string.split("T")[0])
        tagContentData.push(data?.unique_tag_content_count || 0)
        qcTagContentData.push(data?.unique_qc_tag_content_count || 0)
        internalQCTagContentData.push(
          data?.unique_internal_qc_tag_content_count || 0
        )
        skipTagContentData.push(data?.skip_tag_content_count || 0)
      })
      formattedData.push({
        x: lables,
        y: tagContentData,
        name: "Tag Conent",
        type: "bar",
      })
      formattedData.push({
        x: lables,
        y: qcTagContentData,
        name: "QC Tag Conent",
        type: "bar",
      })
      formattedData.push({
        x: lables,
        y: internalQCTagContentData,
        name: "Internal QC Tag Conent",
        type: "bar",
      })
      formattedData.push({
        x: lables,
        y: skipTagContentData,
        name: "Skip Tag Conent",
        type: "bar",
      })
    } else {
      const lables = []
      const qcTagContentData = []
      const tagContentData = []
      const skipTagContentData = []
      uniqueContentdata.forEach(data => {
        lables.push(data.key_as_string.split("T")[0])
        qcTagContentData.push(data?.unique_qc_tag_content_count || 0)
        tagContentData.push(data?.unique_tag_content_count || 0)
        skipTagContentData.push(data?.skip_tag_content_count || 0)
      })
      formattedData.push({
        x: lables,
        y: tagContentData,
        name: "Tag Conent",
        type: "bar",
      })
      formattedData.push({
        x: lables,
        y: qcTagContentData,
        name: "QC Tag Conent",
        type: "bar",
      })
      formattedData.push({
        x: lables,
        y: skipTagContentData,
        name: "Skip Tag Conent",
        type: "bar",
      })
    }
    return formattedData
  }

  _renderUniqueTaggedAndQCTaggedContent = () => {
    const { uniqueTaggedQCTaggedContentStat = [] } = this.props || {}
    const data = this.getFormattedUniqueContentData(
      uniqueTaggedQCTaggedContentStat
    )
    const layout = {
      // showlegend: false,
      legend: { orientation: "h" },
      margin: {
        l: 30,
        r: 10,
      },
    }
    return (
      <Card>
        {(uniqueTaggedQCTaggedContentStat &&
          uniqueTaggedQCTaggedContentStat.length) ||
        this.props.loadingUniqueTaggedContentChart ? (
          <Auxiliary loading={this.props.loadingUniqueTaggedContentChart}>
            <Chart
              title="Tagged & QC Tagged Content"
              data={data}
              key={this.props.loadingUniqueTaggedContentChart}
              layout={layout}
            />
          </Auxiliary>
        ) : (
          this._noChartDataCard("Agent Wise Tag Content")
        )}
      </Card>
    )
  }

  setChartAgency = agency => {
    if (agency !== this.state.agency) {
      this.setState(
        prevState => ({
          agent: null,
          agency,
          languageChartFilter: { ...prevState.languageChartFilter, agency },
          agentByLanguageChartFilter: {
            ...prevState.agentByLanguageChartFilter,
            agency,
          },
          agentChartFilter: {
            ...prevState.agentChartFilter,
            agency,
            email: null,
          },
          uniqueTaggedQCTaggedContainChartFilter: {
            ...prevState.uniqueTaggedQCTaggedContainChartFilter,
            agency,
          },
        }),
        () => {
          this.props.dispatch(
            getLanguageLevelStats(_.deepClean(this.state.languageChartFilter))
          )
          this.props.dispatch(
            getUniqueTaggedAndQCTaggedContainData(
              _.deepClean(this.state.uniqueTaggedQCTaggedContainChartFilter)
            )
          )
          this.props.dispatch(
            getAgentByLanguage(
              _.deepClean(this.state.agentByLanguageChartFilter)
            )
          )
          this.props.dispatch(
            getAgentListByAgency(_.deepClean(this.state.agentChartFilter))
          )
          this.props.history.push(
            `/tasks/taxonomy/dashboard?${QueryString.stringify(
              _.deepClean({
                agency: this.state.agency,
                langCode: this.state.langCode,
                agent: this.state.agent,
                days: this.state.days,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
              })
            )}`
          )
        }
      )
    }
  }

  setChartTimespan = days => {
    if (days != this.state.days) {
      this.setState(
        prevState => ({
          days,
          dateFrom: null,
          dateTo: null,
          languageChartFilter: {
            ...prevState.languageChartFilter,
            days,
            dateFrom: null,
            dateTo: null,
          },
          agentByLanguageChartFilter: {
            ...prevState.agentByLanguageChartFilter,
            days,
            dateFrom: null,
            dateTo: null,
          },
          agentChartFilter: {
            ...prevState.agentChartFilter,
            days,
            dateFrom: null,
            dateTo: null,
          },
          uniqueTaggedQCTaggedContainChartFilter: {
            ...prevState.uniqueTaggedQCTaggedContainChartFilter,
            days,
            dateFrom: null,
            dateTo: null,
          },
        }),
        () => {
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getLanguageLevelStats(_.deepClean(this.state.languageChartFilter))
            )
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getAgentByLanguage(
                _.deepClean(this.state.agentByLanguageChartFilter)
              )
            )
          this.state.agent &&
            this.props.dispatch(
              getAgencyLevelStats(_.deepClean(this.state.agentChartFilter))
            )
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getUniqueTaggedAndQCTaggedContainData(
                _.deepClean(this.state.uniqueTaggedQCTaggedContainChartFilter)
              )
            )
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getAgentListByAgency(_.deepClean(this.state.agentChartFilter))
            )
          this.props.history.push(
            `/tasks/taxonomy/dashboard?${QueryString.stringify(
              _.deepClean({
                agency: this.state.agency,
                langCode: this.state.langCode,
                agent: this.state.agent,
                days: this.state.days,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
              })
            )}`
          )
        }
      )
    }
  }

  setChartLangCode = langCode => {
    this.setState(
      prevState => ({
        agent: null,
        langCode,
        languageChartFilter: { ...prevState.languageChartFilter, langCode },
        agentByLanguageChartFilter: {
          ...prevState.agentByLanguageChartFilter,
          langCode,
        },
        agentChartFilter: {
          ...prevState.agentChartFilter,
          langCode,
          email: null,
        },
        uniqueTaggedQCTaggedContainChartFilter: {
          ...prevState.uniqueTaggedQCTaggedContainChartFilter,
          langCode,
        },
      }),
      () => {
        this.props.dispatch(
          getLanguageLevelStats(_.deepClean(this.state.languageChartFilter))
        )
        this.props.dispatch(
          getUniqueTaggedAndQCTaggedContainData(
            _.deepClean(this.state.uniqueTaggedQCTaggedContainChartFilter)
          )
        )
        this.props.dispatch(
          getAgentByLanguage(_.deepClean(this.state.agentByLanguageChartFilter))
        )
        this.props.dispatch(
          getAgentListByAgency(_.deepClean(this.state.agentChartFilter))
        )
        this.props.history.push(
          `/tasks/taxonomy/dashboard?${QueryString.stringify(
            _.deepClean({
              agency: this.state.agency,
              langCode: this.state.langCode,
              agent: this.state.agent,
              days: this.state.days,
              dateFrom: this.state.dateFrom,
              dateTo: this.state.dateTo,
            })
          )}`
        )
      }
    )
  }

  _handleFilterChange = (value, type) => {
    if (type === "dateTo") {
      if (this.state.dateFrom && value && +this.state.dateFrom > +value) {
        return toastr.error("To Date should be greater than From Date.")
      }
    }
    if (type === "dateFrom") {
      if (this.state.dateTo && value && +this.state.dateTo < +value) {
        return toastr.error("To Date should be greater than From Date.")
      }
    }
    this.setState(
      prevState => ({
        [type]: value,
        days: type === "dateFrom" && !value ? LAST_1_DAY : null,
        languageChartFilter: {
          ...prevState.languageChartFilter,
          days: type === "dateFrom" && !value ? LAST_1_DAY : null,
          [type]: value,
        },
        agentByLanguageChartFilter: {
          ...prevState.agentByLanguageChartFilter,
          days: type === "dateFrom" && !value ? LAST_1_DAY : null,
          [type]: value,
        },
        agentChartFilter: {
          ...prevState.agentChartFilter,
          days: type === "dateFrom" && !value ? LAST_1_DAY : null,
          [type]: value,
        },
        uniqueTaggedQCTaggedContainChartFilter: {
          ...prevState.uniqueTaggedQCTaggedContainChartFilter,
          days: type === "dateFrom" && !value ? LAST_1_DAY : null,
          [type]: value,
        },
      }),
      () => {
        if (type === "dateFrom" && value > this.state.dateTo) {
          this.setState({ dateTo: null })
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getLanguageLevelStats(
                _.deepClean({ ...this.state.languageChartFilter, dateTo: null })
              )
            )
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getAgentByLanguage(
                _.deepClean({
                  ...this.state.agentByLanguageChartFilter,
                  dateTo: null,
                })
              )
            )
          this.state.agent &&
            this.props.dispatch(
              getAgencyLevelStats(
                _.deepClean({ ...this.state.agentChartFilter, dateTo: null })
              )
            )
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getUniqueTaggedAndQCTaggedContainData(
                _.deepClean({
                  ...this.state.uniqueTaggedQCTaggedContainChartFilter,
                  dateTo: null,
                })
              )
            )
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getAgentListByAgency(
                _.deepClean({ ...this.state.agentChartFilter, dateTo: null })
              )
            )
          this.props.history.push(
            `/tasks/taxonomy/dashboard?${QueryString.stringify(
              _.deepClean({
                agency: this.state.agency,
                langCode: this.state.langCode,
                agent: this.state.agent,
                days: this.state.days,
                dateFrom: this.state.dateFrom,
                dateTo: null,
              })
            )}`
          )
        } else {
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getLanguageLevelStats(_.deepClean(this.state.languageChartFilter))
            )
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getAgentByLanguage(
                _.deepClean(this.state.agentByLanguageChartFilter)
              )
            )
          this.state.agent &&
            this.props.dispatch(
              getAgencyLevelStats(_.deepClean(this.state.agentChartFilter))
            )
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getUniqueTaggedAndQCTaggedContainData(
                _.deepClean(this.state.uniqueTaggedQCTaggedContainChartFilter)
              )
            )
          ;(this.state.isInternalUser ||
            (this.state.isAuthorised && this.state.isAdmin)) &&
            this.props.dispatch(
              getAgentListByAgency(_.deepClean(this.state.agentChartFilter))
            )
          this.props.history.push(
            `/tasks/taxonomy/dashboard?${QueryString.stringify(
              _.deepClean({
                agency: this.state.agency,
                langCode: this.state.langCode,
                agent: this.state.agent,
                days: this.state.days,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
              })
            )}`
          )
        }
      }
    )
  }

  render() {
    const { isAuthorised = false } = this.state || {}

    return (
      <>
        {isAuthorised ? (
          <Container fluid>
            <div className="filter_bar">
              {this.state.isInternalUser ? (
                <div className="display-flex">
                  <div className="agency_label">Agency :</div>
                  <div className="btn-group btn-group-sm" role="group">
                    <Button
                      type="button"
                      className={`btn ${
                        this.state.agency === OPPORTURE_AGENCY
                          ? "btn-primary"
                          : "btn-outline-primary"
                      } filter_btn_margin`}
                      onClick={() => this.setChartAgency(OPPORTURE_AGENCY)}
                    >
                      <strong>{OPPORTURE_AGENCY}</strong>
                    </Button>
                    <Button
                      type="button"
                      className={`btn ${
                        this.state.agency === SRMFILMS_AGENCY
                          ? "btn-primary"
                          : "btn-outline-primary"
                      } filter_btn_margin`}
                      onClick={() => this.setChartAgency(SRMFILMS_AGENCY)}
                    >
                      <strong>{SRMFILMS_AGENCY}</strong>
                    </Button>
                    <Button
                      type="button"
                      className={`btn ${
                        this.state.agency === HEXAWARE_AGENCY
                          ? "btn-primary"
                          : "btn-outline-primary"
                      } filter_btn_margin`}
                      onClick={() => this.setChartAgency(HEXAWARE_AGENCY)}
                    >
                      <strong>{HEXAWARE_AGENCY}</strong>
                    </Button>
                    <Button
                      type="button"
                      className={`btn ${
                        this.state.agency === ALL_AGENCY
                          ? "btn-primary"
                          : "btn-outline-primary"
                      } filter_btn_margin`}
                      onClick={() => this.setChartAgency(ALL_AGENCY)}
                    >
                      <strong>{ALL_AGENCY}</strong>
                    </Button>
                  </div>
                </div>
              ) : null}
              <div className="display-flex">
                <div className="agency_label">Time Span :</div>
                <div className="btn-group btn-group-sm" role="group">
                  <Button
                    type="button"
                    className={`btn ${
                      this.state.days == LAST_1_HOUR
                        ? "btn-primary"
                        : "btn-outline-primary"
                    } filter_btn_margin`}
                    onClick={() => this.setChartTimespan(LAST_1_HOUR)}
                  >
                    <strong>1 HOUR</strong>
                  </Button>
                  <Button
                    type="button"
                    className={`btn ${
                      Number(this.state.days) === LAST_1_DAY
                        ? "btn-primary"
                        : "btn-outline-primary"
                    } filter_btn_margin`}
                    onClick={() => this.setChartTimespan(LAST_1_DAY)}
                  >
                    <strong>1 DAY</strong>
                  </Button>
                  <Button
                    type="button"
                    className={`btn ${
                      Number(this.state.days) === LAST_3_DAYS
                        ? "btn-primary"
                        : "btn-outline-primary"
                    } filter_btn_margin`}
                    onClick={() => this.setChartTimespan(LAST_3_DAYS)}
                  >
                    <strong>3 DAYS</strong>
                  </Button>
                  <Button
                    type="button"
                    className={`btn ${
                      Number(this.state.days) === LAST_7_DAYS
                        ? "btn-primary"
                        : "btn-outline-primary"
                    } filter_btn_margin`}
                    onClick={() => this.setChartTimespan(LAST_7_DAYS)}
                  >
                    <strong>7 DAYS</strong>
                  </Button>
                  <Button
                    type="button"
                    className={`btn ${
                      Number(this.state.days) === LAST_15_DAYS
                        ? "btn-primary"
                        : "btn-outline-primary"
                    } filter_btn_margin`}
                    onClick={() => this.setChartTimespan(LAST_15_DAYS)}
                  >
                    <strong>15 DAYS</strong>
                  </Button>
                  <Input.Group compact={true}>
                    <Row>
                      <DatePicker
                        key={this.state.dateFrom}
                        defaultValue={
                          this.state.dateFrom
                            ? dayjs.unix(
                                this.state.dateFrom / 1000,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        placeholder="From Date"
                        onChange={(date, dateString) => {
                          this._handleFilterChange(
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null,
                            "dateFrom"
                          )
                        }}
                      />
                      <DatePicker
                        key={this.state.dateTo}
                        defaultValue={
                          this.state.dateTo
                            ? dayjs.unix(this.state.dateTo / 1000, "YYYY-MM-DD")
                            : null
                        }
                        format="YYYY-MM-DD HH:mm"
                        showTime
                        placeholder="To Date"
                        disabled={!this.state?.dateFrom}
                        onChange={(date, dateString) => {
                          this._handleFilterChange(
                            !_.isEmpty(dateString)
                              ? moment(dateString).format("x")
                              : null,
                            "dateTo"
                          )
                        }}
                      />
                    </Row>
                  </Input.Group>
                </div>
              </div>
              {this.state.isInternalUser ||
              (this.state.isAuthorised && this.state.isAdmin) ? (
                <div className="display-flex">
                  <div className="agency_label">Language :</div>
                  <Select
                    showSearch
                    allowClear={true}
                    placeholder="language"
                    defaultValue={this.state.langCode}
                    onChange={value => {
                      this.setChartLangCode(value)
                    }}
                    size="medium"
                    style={{ maxWidth: "150px", minWidth: "120px" }}
                  >
                    {LANGUAGES_LIST.map(lang => (
                      <Option value={lang.value}>{lang.label}</Option>
                    ))}
                  </Select>
                </div>
              ) : null}
            </div>

            <div className="dashboard-card-container">
              {this.state.isInternalUser ||
              (this.state.isAuthorised && this.state.isAdmin)
                ? this._renderUniqueTaggedAndQCTaggedContent()
                : null}
              {this.state.isInternalUser ||
              (this.state.isAuthorised && this.state.isAdmin)
                ? this._renderChartForLanguage()
                : null}
              {this.state.isInternalUser ||
              (this.state.isAuthorised && this.state.isAdmin)
                ? this._renderChartForAgentByLanguage()
                : null}
              {this._renderChartForAgent()}
            </div>
          </Container>
        ) : (
          <div className="text-muted not-authorised-page">
            You are not authorised to see this page.
          </div>
        )}
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    loading: _.get(store, "content.loading"),
    loadingLanguageChart: _.get(store, "content.loadingLanguageChart"),
    loadingAgentByLangChart: _.get(store, "content.loadingAgentByLangChart"),
    loadingAgentChart: _.get(store, "content.loadingAgentChart"),
    loadingUniqueTaggedContentChart: _.get(
      store,
      "content.loadingUniqueTaggedContentChart"
    ),
    agencyStat: _.get(store, "content.agencyStat"),
    agentList: _.get(store, "content.agentList"),
    uniqueTaggedQCTaggedContentStat: _.get(
      store,
      "content.uniqueTaggedQCTaggedContentStat"
    ),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    currentUser: _.get(store, "currentUser.currentUser.data"),
    languageStats: _.get(store, "content.languageStats"),
    agentByLangStat: _.get(store, "content.agentByLangStat"),
  }
}

export default connect(mapStateToProps)(TaxonomyReport)
