import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Button,
  Table,
  Badge,
  Row,
  CardImg,
} from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { size, map } from "lodash"
import { Tooltip } from "antd"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import CustomScrollbars from "util/CustomScrollbars"
import ReactCardFlip from "react-card-flip"
import ReactTimeAgo from "react-time-ago"
import { NO_PERMISSION_MESSAGE } from "../../constants/uiConstants"
import "./pageStyle.css"

class PageCard extends React.Component {
  state = { isFlipped: false }

  _getIcon = () => {
    const { data } = this.props
    return data && data.page_type === "DISCOVERY"
      ? "fa-search"
      : data &&
        (data.page_type === "AUDIO_LIBRARY" || data.page_type === "MUSIC")
      ? "fa-music"
      : data && data.page_type === "TRENDING"
      ? "fas fa-chart-line"
      : data && data.page_type === "GAME"
      ? "fas fa-gamepad"
      : data && data.page_type === "VIDEO"
      ? "fas fa-video"
      : "fa-align-center"
  }

  render() {
    const { data, audioDetails = [] } = this.props

    return (
      <ReactCardFlip
        isFlipped={this.state.isFlipped}
        flipDirection={"horizontal"}
      >
        <Card
          className={`text-center shadow-lg`}
          style={{
            minHeight: "250px",
          }}
        >
          <div className="bg-primary bg-soft" onClick={() => {}}>
            <Row>
              {data && data.overlay_image ? (
                <div id={"play" + data.page_uuid} style={{ height: "15rem" }}>
                  <CardImg
                    top
                    className="img-fluid"
                    src={data.overlay_image}
                    alt="Skote"
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    style={{
                      height: "15rem",
                      cursor: "pointer",
                      opacity: "0.8",
                    }}
                  />
                  <Tooltip
                    title={data && data.background_image && "Background Image"}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                    >
                      <img
                        src={
                          (data && data.background_image) ||
                          "https://qa-stream.myjosh.in/stream/qa-josh-content/profile_pictures/default.png"
                        }
                        className="me-3 rounded-circle avatar-xs"
                        alt="overlay-image"
                      />
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <div
                  className="py-5 px-0"
                  style={{
                    height: "15rem",
                  }}
                >
                  <i
                    className={`fas ${this._getIcon()} fa-7x py-4 text-secondary`}
                  />
                </div>
              )}
            </Row>
          </div>
          <CardBody style={{ padding: "1rem 0.25rem" }}>
            <Tooltip title={data.title} style={{ minHeight: "20px" }}>
              <div className="font-size-16 text-truncate font-weight-semibold">
                <i className={`fa ${this._getIcon()} mx-1`} /> {data.title}
              </div>
            </Tooltip>

            <div style={{ minHeight: "30px" }}>
              <Badge
                className={`${"badge-soft-danger"} ms-1 font-size-13 my-2 text-black`}
              >
                {data.page_type}
              </Badge>
            </div>
            <div style={{ minHeight: "30px" }}>
              <Badge
                className={`${
                  data.status === "PUBLISHED"
                    ? "badge-soft-success"
                    : data.status === "DRAFT"
                    ? "badge-soft-info"
                    : "badge-soft-danger"
                } ms-1 font-size-13 mb-2 text-black`}
              >
                {data.status}
              </Badge>
            </div>
            <div style={{ minHeight: "30px" }}>
              <Badge className="badge-soft-dark ms-1 font-size-13 text-black">
                <ReactTimeAgo date={data.created_date} locale="en-US" />
              </Badge>
            </div>
            <div>
              {map(
                data.permissions,
                (tag, index) =>
                  index < 2 && (
                    <Link
                      to="#"
                      className="badge bg-primary font-size-11 m-1"
                      key={"_skill_" + data.id + index}
                    >
                      {tag}
                    </Link>
                  )
              )}
              {size(data.permissions) > 2 && (
                <Button
                  to="#"
                  className="badge bg-success font-size-11 m-1"
                  key={"_skill_" + data.id}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span
                    title={data.permissions
                      .slice(1, data.permissions, length)
                      .map(a => (
                        <span>{a}</span>
                      ))}
                  >
                    +{size(data.permissions) - 1} more
                  </span>
                </Button>
              )}
            </div>
          </CardBody>
          <div
            className="contact-links d-flex font-size-20 p-2 bg-light border-top text-center"
            style={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div className="flex-fill">
              <CopyToClipboard
                text={data.page_uuid}
                onCopy={e => {
                  toastr.success("Copied sucessfully!")
                }}
              >
                <Tooltip title="Copy Page ID">
                  <i className="bx bx-copy" role="button" />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <div className="flex-fill">
              <Tooltip title="Edit Page">
                <i
                  className="bx bx-edit-alt"
                  role="button"
                  onClick={() => {
                    if (!this.props.hasEditPermission) {
                      return toastr.error(NO_PERMISSION_MESSAGE)
                    } else this.props.onEditClick("update", data)
                  }}
                />
              </Tooltip>
            </div>
            <div className="flex-fill">
              <Tooltip title="Add Collection">
                <i
                  className="bx bx-plus"
                  role="button"
                  onClick={() => {
                    if (!this.props.hasEditPermission) {
                      return toastr.error(NO_PERMISSION_MESSAGE)
                    } else this.props.onEditClick("add", data)
                  }}
                />
              </Tooltip>
            </div>
            <div className="flex-fill">
              <Tooltip title="Manage Entities">
                <i
                  className="bx bx-coin-stack"
                  role="button"
                  onClick={() => {
                    this.props.history.push(
                      `/manage/pageEntities?pageId=${data.page_uuid}`
                    )
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Card>
        <Card
          className="text-center shadow-lg"
          style={{
            height: "440px",
            borderRadius: "10px",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <CustomScrollbars>
            <CardBody className="px-0 py-0">
              <Row className="my-sm-2">
                <Col className="ms-2">
                  <div className="avatar-xs">
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-warning" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="bx bx-bar-chart" />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end me-2">
                  <div
                    className="avatar-xs"
                    role="button"
                    onClick={() => this.setState({ isFlipped: false })}
                  >
                    <div className="avatar-title rounded-circle bg-light">
                      <span
                        className={
                          "avatar-title rounded-circle bg-light bg-" +
                          "secondary" +
                          " text-dark" +
                          " font-size-16" +
                          " font-weight-semibold"
                        }
                      >
                        <i className="fas fa-times-circle" />
                      </span>
                    </div>
                  </div>
                  <div></div>
                </Col>
              </Row>
              <Row>
                <div className="table-responsive">
                  <Table className="mb-0 table-striped">
                    <tbody>
                      <tr className="text-start">
                        <th scope="row">Deeplink:</th>
                        <td className="break-word">
                          <CopyToClipboard
                            text={data.deeplink}
                            onCopy={e => {
                              toastr.success("Copied sucessfully!")
                            }}
                          >
                            <Tooltip title="Copy Deeplink">
                              <i className="bx bx-copy" role="button" />
                            </Tooltip>
                          </CopyToClipboard>
                        </td>
                      </tr>
                      <tr className="text-start">
                        <th scope="row">Theme Name:</th>
                        <td className="break-word">{data.theme_name || "-"}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </CardBody>
          </CustomScrollbars>
        </Card>
      </ReactCardFlip>
    )
  }
}

export default PageCard
