import { Checkbox, DatePicker, Divider, Input, Pagination, Select } from "antd"
import { getTeamData } from "appRedux/slices/community"
import _ from "lodash"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import ReactTimeAgo from "react-time-ago"
import { CardBody, Col, Container, Row, Table } from "reactstrap"
import SimpleBar from "simplebar-react"
import Chips from "../../components/CommonFilter/chips"
import ContentList from "../../components/ContentList/contentList"
import UserCard from "../../components/UserList/userCard"
import {
  contentFilters,
  LANGUAGES,
  TARGET_CATEGORY_V2,
} from "../../constants/uiConstants"
import { formatNumber } from "../../util/common"
import "./community.css"
import Dashboard from "./dashboardCard"
import UserDashboardCard from "./UserDashboardCard"
import dayjs from "dayjs"
const { Option } = Select

const ACTION_MAP = {
  block: {
    label: "Blocked",
    icon: "bx bx-x-circle",
    textColor: "danger",
  },
  update_app_user: {
    label: "Updated",
    icon: "bx bx-pencil",
    textColor: "primary",
    message: " ",
  },
  unverify: {
    label: "Un Verified",
    icon: "bx bx-user-x",
    textColor: "danger",
  },
  verify: {
    label: "Verified",
    icon: "bx bx-user-check",
    textColor: "success",
  },
  unblock: {
    label: "Unblocked",
    icon: "bx bx-check-circle",
    textColor: "success",
  },
  bulk_create_app_user: {
    label: "bulk create app user",
    icon: "bx bx-user-plus",
    textColor: "primary",
  },
}

const DashboardPage = props => {
  const {
    user: userProfile,
    onGetUserProfile,
    communityUserData,
    communityManagerData = {},
  } = props

  const { teamData, loading, teamLoading } = useSelector(state => ({
    teamData: _.get(state, "community.teamData"),
    loading: _.get(state, "community.loading"),
    teamLoading: _.get(state, "community.teamLoading"),
  }))

  const [pageType, setPageType] = useState(props.currentTab)
  const [range, setRange] = useState("All time")
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [open, setClick] = useState(true)
  const [currentPage, setCurrentPage] = useState(props.currentPage)
  const [currentCreatorPage, setCurrentCreatorPage] = useState(
    props.currentCreatorPage
  )
  const [showUserFilter, setShowUserFilter] = useState(false)
  const [showContentFilter, setShowContentFilter] = useState(false)
  const [showMoreAdvanceUserFilters, setShowMoreAdvanceUserFilters] =
    useState(false)
  const [showMoreAdvancedContentFilters, setShowMoreAdvancedContentFilters] =
    useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !teamData ||
      _.isEmpty(teamData) ||
      (teamData && !_.isEqual(teamData, teamData))
    ) {
      if (
        !teamLoading &&
        props?.communityManagerData?.userId &&
        props?.communityManagerData?.jotUserType &&
        [12, 32].includes(props?.communityManagerData?.jotUserType)
      ) {
        dispatch(getTeamData(props.communityManagerData.userId))
      }
    }
  }, [teamData, teamLoading])

  const userOptions = {
    1: "Super Admin",
    2: "Admin",
    3: "Content Manager", // Create Update Challem, audio and content
    4: "Moderator", // Requires permissions to be updated in the users table and same reads to be handled at routes
    5: "Ads Ops", // For source Type and sponsor text in challenge and audio
    7: "Internal Moderator",
  }

  const timeRange = [
    "Yesterday",
    "Last week",
    "Last month",
    "All time",
    "Custom",
  ]

  const realStatPermissionUser =
    // props.currentUser.jotUserType < 3 ||
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("VIEW_REAL_STATS")
  // eslint-disable-next-line no-unused-vars

  let userDash
  ;[13, 33].includes(props.communityManagerData?.jotUserType)
    ? (userDash = false)
    : (userDash = true)
  const _renderDashboard = () => {
    return (
      <Row className="mx-1">
        <Dashboard
          data={props.currentUser}
          creators={
            props.communityManagerData &&
            props.communityManagerData.community &&
            props.communityManagerData.community.usersList
          }
          topList={props.topList}
          toggleshowdetails={props.toggleshowdetails}
        />
      </Row>
    )
  }

  const _renderThumbnail = content => {
    switch (content.format) {
      case "IMAGE":
        return (
          <img
            className="rounded-circle avatar-sm"
            src={content.download_url}
            width="100%"
            height="455px"
            alt=""
          />
        )
      case "GIF":
        return (
          <img
            className="rounded-circle avatar-sm"
            src={content.download_url}
            width="100%"
            height="380px"
            alt=""
          />
        )
      default:
        return (
          <img
            className="rounded-circle avatar-sm"
            src={
              content.animated_icon_url
                ? content.animated_icon_url
                : content.thumbnail_url
                ? content.thumbnail_url
                : ""
            }
            width="100%"
            height="455px"
            alt=""
            style={{ filter: "blur(.5px)" }}
          />
        )
    }
  }

  const _contentDrawer = realStatPermissionUser => {
    const communityManager =
      props.currentUser.permissions &&
      props.currentUser.permissions.includes("COMMUNITY_MANAGER")
    return (
      <React.Fragment>
        <Row className="mb-3">
          <Col lg="4" md="8">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Filter By</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex">
                  <Select
                    placeholder="Search Type"
                    style={{ width: "100%" }}
                    allowClear={true}
                    defaultValue={props.contentFilters["searchType"]}
                    onChange={value =>
                      props._handleContentFilterChange(value, "searchType")
                    }
                  >
                    <Option value="content_uuid">Content UUID</Option>
                    <Option value="created_by">User UUID</Option>
                    <Option value="title">Title</Option>
                    <Option value="tag">Tag</Option>
                    <Option value="created_day">Created Day</Option>
                    <Option value="reject_reason">Reject Reason</Option>
                    <Option value="target_category">Approve Reason</Option>
                    <Option value="target_category_v2">Target Category</Option>
                    <Option value="reprocessed_moderation">
                      ReProcessed Moderation Level
                    </Option>
                  </Select>
                  {props.contentFilters["searchType"] !== "created_day" &&
                    props.contentFilters["searchType"] !==
                      "reprocessed_moderation" &&
                    props.contentFilters["searchType"] !== "reject_reason" &&
                    props.contentFilters["searchType"] !== "target_category" &&
                    props.contentFilters["searchType"] !==
                      "target_category_v2" && (
                      <Input
                        defaultValue={props.contentFilters["searchText"]}
                        placeholder={"Search Value"}
                        onChange={e =>
                          props._handleContentFilterChange(
                            e.target.value.trim(),
                            "searchText"
                          )
                        }
                        allowClear={true}
                        name="searchId"
                        style={{ width: "100%" }}
                      />
                    )}
                  {props.contentFilters["searchType"] === "created_day" && (
                    <DatePicker
                      defaultValue={
                        props.contentFilters["searchText"]
                          ? dayjs.unix(
                              props.contentFilters["searchText"] / 1000,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                      format="YYYY-MM-DD"
                      onChange={(moment, dateString) => {
                        props._handleContentFilterChange(
                          dateString,
                          "searchText"
                        )
                      }}
                    />
                  )}
                  {props.contentFilters["searchType"] === "reject_reason" && (
                    <Select
                      allowClear={true}
                      defaultValue={props.contentFilters["searchText"]}
                      onChange={e =>
                        props._handleContentFilterChange(e, "searchText")
                      }
                      placeholder="Reject Reason"
                      style={{ width: "100%" }}
                    >
                      {DEACTIVATE_REASONS.map(reason => (
                        <Option value={reason.value}>{reason.label}</Option>
                      ))}
                    </Select>
                  )}
                  {(props.contentFilters["searchType"] === "target_category" ||
                    props.contentFilters["searchType"] ===
                      "target_category_v2") && (
                    <Select
                      allowClear={true}
                      defaultValue={props.contentFilters["searchText"]}
                      onChange={e =>
                        props._handleContentFilterChange(e, "searchText")
                      }
                      placeholder="Approval Reason"
                      style={{ width: "100%" }}
                    >
                      {TARGET_CATEGORY_V2.map(reason => (
                        <Option value={reason.value}>{reason.label}</Option>
                      ))}
                      <Option value="not_exist">Not Exist</Option>
                    </Select>
                  )}
                  {props.contentFilters["searchType"] ===
                    "reprocessed_moderation" && (
                    <Select
                      allowClear={true}
                      defaultValue={props.contentFilters["searchText"]}
                      placeholder="New Auto Moderation Level"
                      onChange={e =>
                        props._handleContentFilterChange(e, "searchText")
                      }
                      style={{ width: "100%" }}
                    >
                      <Option value="nsfw">NSFW</Option>
                      <Option value="sfw">SFW</Option>
                    </Select>
                  )}
                </Col>
              </Row>
            </Input.Group>
          </Col>
          <Col lg="6" md="8">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Order By</h5>
            </Divider>
            <Input.Group compact={true}>
              <Row className="w-100">
                <Col className="d-flex">
                  <Select
                    allowClear={true}
                    defaultValue={props.contentFilters["orderByField"]}
                    placeholder="Order By"
                    onChange={value => {
                      props._handleContentFilterChange(value, "orderByField")
                    }}
                    style={{ width: "100%" }}
                  >
                    {!communityManager && (
                      <React.Fragment>
                        <Option value="view_count">View Count</Option>
                        <Option value="share_count">Share Count</Option>
                        <Option value="download_count">Download Count</Option>
                        <Option value="like_count">Like Count</Option>
                      </React.Fragment>
                    )}
                    <Option value="created_date">Created Date</Option>
                    <Option value="t_score_v2">T Score V2</Option>
                    {(props.currentUser.jotUserType < 3 ||
                      (props.currentUser.jotUserType === 4 &&
                        props.currentUser.permissions &&
                        props.currentUser.permissions.includes(
                          "VIEW_ACTIVITY"
                        ))) && <Option value="qc_order">QC Order</Option>}
                  </Select>
                  <Select
                    allowClear={true}
                    defaultValue={props.contentFilters["order"]}
                    placeholder="Order"
                    onChange={value => {
                      props._handleContentFilterChange(value, "order")
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value="asc">Ascending</Option>
                    <Option value="desc">Descending</Option>
                  </Select>
                </Col>
              </Row>
            </Input.Group>
          </Col>
          {/* <Col lg="2" md="4">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Moderation Level</h5>
            </Divider>
            <Col span={24}>
              <Select
                allowClear={true}
                placeholder="Moderation level"
                defaultValue={props.contentFilters["moderationLevel"]}
                onChange={value => {
                  props._handleContentFilterChange(value, "moderationLevel")
                }}
                style={{ width: "100%" }}
              >
                <Option value="">All</Option>
                <Option value="3">Auto Approved</Option>
                <Option value="0">Auto Disabled</Option>
                <Option value="2">Human Approved</Option>
                <Option value="-1">Human Disabled</Option>
                <Option value="1">Unmoderated</Option>
              </Select>
            </Col>
          </Col> */}
          <Col lg="2" md="4">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Language</h5>
            </Divider>
            <Select
              showSearch
              allowClear={true}
              placeholder="Language"
              defaultValue={props.contentFilters["langCode"]}
              onChange={value => {
                props._handleContentFilterChange(value, "langCode")
              }}
              style={{ width: "100%" }}
            >
              <Option value="">All</Option>
              {LANGUAGES.map(lang => (
                <Option value={lang.value}>{lang.label}</Option>
              ))}
            </Select>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <div
            onClick={() =>
              setShowMoreAdvancedContentFilters(!showMoreAdvancedContentFilters)
            }
            role="button"
          >
            {showMoreAdvancedContentFilters ? (
              <i
                className="fas fa-angle-double-up
font-size-16 align-middle me-2"
              ></i>
            ) : (
              <i
                className="fas fa-angle-double-down
 font-size-16 align-middle me-2"
              ></i>
            )}
            {showMoreAdvancedContentFilters ? "Hide" : "Show"} Advanced Filters
          </div>
        </div>

        {showMoreAdvancedContentFilters && (
          <Row style={{ marginBottom: "10px" }}>
            <Col lg="4" md="4">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Audio Id</h5>
              </Divider>
              <Input
                defaultValue={props.contentFilters["audioId"]}
                placeholder={"Search Value"}
                onChange={e =>
                  props._handleContentFilterChange(
                    e.target.value.trim(),
                    "audioId"
                  )
                }
                allowClear={true}
                name="audioId"
                style={{ width: "100%" }}
              />
            </Col>
            <Col lg="6" md="12">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Range Filter</h5>
              </Divider>
              <Input.Group compact={true}>
                <Row className="w-100">
                  <Col className="d-flex justify-content-center">
                    <Select
                      allowClear={true}
                      defaultValue={props.contentFilters["rangeSearchType"]}
                      placeholder="Range Search Type"
                      onChange={value => {
                        props._handleContentFilterChange(
                          value,
                          "rangeSearchType"
                        )
                      }}
                      style={{ width: "100%" }}
                    >
                      <Option value="review_bucket">Bucket</Option>
                      {realStatPermissionUser && (
                        <React.Fragment>
                          <Option value="download_count">Download Count</Option>
                          <Option value="like_count">Like Count</Option>
                          <Option value="share_count">Share Count</Option>
                          <Option value="view_count">View Count</Option>
                        </React.Fragment>
                      )}
                      <Option value="video_duration">Video Duration</Option>
                      <Option value="taxonomy_meta.video_quality_attributes.rating">
                        Taxonomy Rating
                      </Option>
                    </Select>
                    <Select
                      allowClear={true}
                      defaultValue={props.contentFilters["opSearchType"]}
                      placeholder="Operator"
                      onChange={value => {
                        props._handleContentFilterChange(value, "opSearchType")
                      }}
                      style={{ width: "100%" }}
                    >
                      <Option value="bt">Between</Option>
                      <Option value="eq">Equal to</Option>
                      <Option value="gt">Greater Than</Option>
                      <Option value="lt">Less Than</Option>
                    </Select>
                    {props.contentFilters["opSearchType"] !== "bt" && (
                      <Input
                        defaultValue={props.contentFilters["rangeSearchText"]}
                        placeholder={"Range Search Text"}
                        onChange={e =>
                          props._handleContentFilterChange(
                            e.target.value,
                            "rangeSearchText"
                          )
                        }
                        allowClear={true}
                        style={{ width: "100%", marginBottom: "5px" }}
                      />
                    )}
                    {props.contentFilters["opSearchType"] === "bt" && (
                      <Input
                        defaultValue={props.contentFilters["rangeSearchFrom"]}
                        placeholder={"Range Search From"}
                        onChange={e =>
                          props._handleContentFilterChange(
                            e.target.value,
                            "rangeSearchFrom"
                          )
                        }
                        allowClear={true}
                        style={{ width: "100%", marginBottom: "5px" }}
                      />
                    )}
                    {props.contentFilters["opSearchType"] === "bt" && (
                      <Input
                        defaultValue={props.contentFilters["rangeSearchTo"]}
                        placeholder={"Range Search To"}
                        onChange={e =>
                          props._handleContentFilterChange(
                            e.target.value,
                            "rangeSearchTo"
                          )
                        }
                        allowClear={true}
                        style={{ width: "100%", marginBottom: "5px" }}
                      />
                    )}
                  </Col>
                </Row>
              </Input.Group>
            </Col>
            {props.currentUser.jotUserType < 3 && (
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Source Type</h5>
                </Divider>
                <Select
                  allowClear={true}
                  defaultValue={props.contentFilters["sourceType"]}
                  onChange={value => {
                    props._handleContentFilterChange(value, "sourceType")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="">All Content</Option>
                  <Option value="ads">Ads Content</Option>
                  <Option value="non_ads">Non Ads Content</Option>
                </Select>
              </Col>
            )}

            {
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Video Source</h5>
                </Divider>
                <Select
                  allowClear={true}
                  defaultValue={props.contentFilters["videoSource"]}
                  onChange={value => {
                    props._handleContentFilterChange(value, "videoSource")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="">All Sources</Option>
                  <Option value="GALLERY">Gallery</Option>
                  <Option value="CAMERA">Camera</Option>
                </Select>
              </Col>
            }
            <Col lg="2" md="4">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Bucket</h5>
              </Divider>
              <Select
                showSearch
                allowClear={true}
                placeholder="Bucket"
                defaultValue={props.contentFilters["bucket"]}
                onChange={value => {
                  props._handleContentFilterChange(value, "bucket")
                }}
                style={{ width: "100%" }}
              >
                <Option value="-1">All</Option>
                {[...Array(100).keys()].map(value => (
                  <Option value={value}>{value}</Option>
                ))}
              </Select>
            </Col>
            {props.currentUser.jotUserType && !props.currentUser.agency && (
              <Col lg="2" md="4">
                <Divider>
                  <h5 className="font-size-11 mb-0 text-muted">Content Type</h5>
                </Divider>
                <Select
                  placeholder="Content Type"
                  allowClear={true}
                  style={{ width: "100%" }}
                  defaultValue={props.contentFilters["contentType"]}
                  onChange={value =>
                    props._handleContentFilterChange(value, "contentType")
                  }
                >
                  <Option value="">All Users</Option>
                  <Option value="e">External</Option>
                  <Option value="i">Internal</Option>
                  <Option value="g">Ghost</Option>
                </Select>
              </Col>
            )}
            <Col lg="4" md="8">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Created Date</h5>
              </Divider>
              <Input.Group compact={true}>
                <Row className="w-100">
                  <Col className="d-flex">
                    <DatePicker
                      defaultValue={
                        props.contentFilters["createdDateFrom"]
                          ? dayjs.unix(
                              props.contentFilters["createdDateFrom"] / 1000,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                      format="YYYY-MM-DD HH:mm"
                      showTime
                      placeholder="From Date"
                      onChange={(date, dateString) => {
                        props._handleContentFilterChange(
                          !_.isEmpty(dateString)
                            ? moment(dateString).format("x")
                            : null,
                          "createdDateFrom"
                        )
                      }}
                      style={{ width: "100%" }}
                    />
                    <DatePicker
                      defaultValue={
                        props.contentFilters["createdDateTo"]
                          ? dayjs.unix(
                              props.contentFilters["createdDateTo"] / 1000,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                      format="YYYY-MM-DD HH:mm"
                      showTime
                      placeholder="To Date"
                      onChange={(date, dateString) => {
                        props._handleContentFilterChange(
                          !_.isEmpty(dateString)
                            ? moment(dateString).format("x")
                            : null,
                          "createdDateTo"
                        )
                      }}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Col>
            <Col lg="2" md="4">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">QC Status</h5>
              </Divider>
              <Select
                placeholder="QC Status"
                allowClear={true}
                style={{ width: "100%" }}
                defaultValue={props.contentFilters["qcDone"]}
                onChange={value =>
                  props._handleContentFilterChange(value, "qcDone")
                }
              >
                <Option value="">All</Option>
                <Option value="Y">Done</Option>
                <Option value="N">Not Done</Option>
              </Select>
            </Col>
            <Col lg="2" md="4">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Review Status</h5>
              </Divider>
              <Select
                placeholder="Review Status"
                allowClear={true}
                style={{ width: "100%" }}
                defaultValue={props.contentFilters["reviewDone"]}
                onChange={value =>
                  props._handleContentFilterChange(value, "reviewDone")
                }
              >
                <Option value="">All</Option>
                <Option value="Y">Done</Option>
                <Option value="N">Not Done</Option>
              </Select>
            </Col>
            {props.currentUser.jotUserType < 3 &&
              props.currentUser.jotUserType !== 6 && (
                <Col lg="2" md="4">
                  <Divider>
                    <h5 className="font-size-11 mb-0 text-muted">
                      Verified Profiles
                    </h5>
                  </Divider>
                  <Select
                    placeholder="Verified Profiles"
                    allowClear={true}
                    style={{ width: "100%" }}
                    defaultValue={props.contentFilters["verifiedProfiles"]}
                    onChange={value =>
                      props._handleContentFilterChange(
                        value,
                        "verifiedProfiles"
                      )
                    }
                  >
                    <Option value="">All</Option>
                    <Option value="Y">True</Option>
                    <Option value="N">False</Option>
                  </Select>
                </Col>
              )}
            <Col lg="2" md="4">
              <Divider>
                <h5 className="font-size-11 mb-0 text-muted">Page Size</h5>
              </Divider>
              <Select
                allowClear={true}
                defaultValue={props.contentFilters["pageSize"]}
                onChange={value => {
                  props._handleContentFilterChange(value, "pageSize")
                }}
                style={{ width: "100%" }}
              >
                <Option value={10}>10</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </Col>
            <Col lg="2" className="mt-5">
              <Checkbox
                onChange={e => {
                  props._handleContentFilterChange(
                    e.target.checked,
                    "maskedContent"
                  )
                }}
                checked={props.contentFilters["maskedContent"]}
              >
                {" "}
                Masked Content{" "}
              </Checkbox>
            </Col>
            <Col lg="2" className="mt-5">
              <Checkbox
                onChange={e => {
                  props._handleContentFilterChange(
                    e.target.checked,
                    "isAssetUsed"
                  )
                }}
                checked={props.contentFilters["isAssetUsed"]}
              >
                {" "}
                Asset Used{" "}
              </Checkbox>
            </Col>
          </Row>
        )}
      </React.Fragment>
    )
  }

  const _renderVideo = () => {
    const creators =
      (props.communityManagerData &&
        props.communityManagerData.community &&
        props.communityManagerData.community.usersList) ||
      []
    return creators && creators.length ? (
      <>
        <Chips
          showFilters={showContentFilter}
          filters={props.contentFilters}
          allowedFilters={contentFilters}
          toggleFilters={flg => {
            setShowContentFilter(flg)
            setShowMoreAdvancedContentFilters(false)
          }}
          drawer={_contentDrawer}
          search={_closeContentFilters}
          removeFilter={props.removeContentFilter}
          showMoreAdvancedFilters={setShowMoreAdvancedContentFilters}
        />
        {props.contentData && props.contentData.length > 0 && (
          <ContentList
            contentList={props.contentData}
            onPageChange={val => {
              props.onVideoClick(val)
              setCurrentPage(val)
            }}
            total={props.total}
            searchAfter={props.searchAfter}
            isMobile={props.isMobile}
            currentUser={props.currentUser}
            currentPage={props.currentPage || 1}
            pageType="content"
            pageSize={9}
            contentViewChange={() => {}}
          />
        )}
      </>
    ) : (
      <div
        className="font-size-18 text-center text-muted font_italic_bold"
        style={{ marginTop: "35vh" }}
      >
        Not Data Available.
      </div>
    )
  }
  const _renderChangelog = () => {
    const creators =
      (props.communityManagerData &&
        props.communityManagerData.community &&
        props.communityManagerData.community.usersList) ||
      []
    if (creators && creators.length) {
      if (props.logs && props.logs.length > 0) {
        return (
          <React.Fragment>
            <div>
              <Container fluid>
                <Row>
                  <Col lg="12">
                    <div className="">
                      <div className="table-responsive">
                        <Table className="TFtable align-middle table-borderless">
                          <tbody>
                            {props.logs.map((log, index) => {
                              let color = ["warning", "primary", "info"][
                                Math.floor(Math.random() * 3)
                              ]
                              return (
                                <tr key={index}>
                                  <td>
                                    <Row>
                                      {log.action && !log.content_uuid && (
                                        <React.Fragment>
                                          <Col
                                            sm="1"
                                            style={{ marginRight: "2px" }}
                                          >
                                            {_getSmallAvatars(
                                              <i
                                                className={`${
                                                  ACTION_MAP[log.action].icon ||
                                                  ""
                                                } font-size-24`}
                                              />,
                                              "dark",
                                              ACTION_MAP[log.action].textColor,
                                              "avatar-sm"
                                            )}
                                          </Col>

                                          <Col sm="10">
                                            {log.action &&
                                              log.action !==
                                                "bulk_create_app_user" && (
                                                <span>
                                                  Profile{" "}
                                                  <Link
                                                    to={`/appuser/details?userUuid=${log.actionOn}`}
                                                  >
                                                    {log.actionOn}
                                                  </Link>{" "}
                                                  {ACTION_MAP[log.action]
                                                    .message
                                                    ? ACTION_MAP[log.action]
                                                        .message
                                                    : "status changed to "}
                                                  <b>
                                                    {ACTION_MAP[log.action]
                                                      .label || "changes"}
                                                  </b>
                                                </span>
                                              )}
                                            {log.action &&
                                              log.action ===
                                                "bulk_create_app_user" && (
                                                <div>
                                                  Action on{" "}
                                                  <Link
                                                    to={`appuser/details?userUuid=${log.actionOn}`}
                                                  >
                                                    {log.actionOn}
                                                  </Link>{" "}
                                                  through{" "}
                                                  {ACTION_MAP[log.action].label}
                                                </div>
                                              )}
                                            <div className="text-muted">
                                              {(log.createdDate ||
                                                log.created_date) && (
                                                <ReactTimeAgo
                                                  date={
                                                    Number.isNaN(
                                                      log.createdDate ||
                                                        log.created_date
                                                    )
                                                      ? Date.parse(
                                                          log.createdDate ||
                                                            log.created_date
                                                        )
                                                      : log.createdDate ||
                                                        log.created_date
                                                  }
                                                  locale="en-US"
                                                />
                                              )}
                                            </div>
                                          </Col>
                                        </React.Fragment>
                                      )}
                                    </Row>
                                    {log.content_uuid && (
                                      <Row>
                                        <React.Fragment>
                                          <Col
                                            sm="1"
                                            style={{ marginRight: "2px" }}
                                          >
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              {_renderThumbnail(log)}
                                            </div>
                                          </Col>
                                          <Col sm="10">
                                            <div>
                                              Created video with video Id{" "}
                                              <Link
                                                to={`/content/manage?contentUUID=${log.content_uuid}`}
                                              >
                                                {log.content_uuid}
                                              </Link>
                                            </div>
                                            <div className="text-muted">
                                              {(log.createdDate ||
                                                log.created_date) && (
                                                <ReactTimeAgo
                                                  date={
                                                    Number.isNaN(
                                                      log.createdDate ||
                                                        log.created_date
                                                    )
                                                      ? Date.parse(
                                                          log.createdDate ||
                                                            log.created_date
                                                        )
                                                      : log.createdDate ||
                                                        log.created_date
                                                  }
                                                  locale="en-US"
                                                />
                                              )}
                                            </div>
                                          </Col>
                                        </React.Fragment>
                                      </Row>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        )
      }
    } else {
      return (
        <div
          className="font-size-18 text-center text-muted font_italic_bold"
          style={{ marginTop: "35vh" }}
        >
          Not Data Available.
        </div>
      )
    }
  }

  const _renderUserDashboard = () => {
    return (
      <Row className="mx-1">
        <UserDashboardCard {...props} />
      </Row>
    )
  }

  const _closeFilters = () => {
    props.onUserSearch()
    setShowUserFilter(false)
    setShowMoreAdvanceUserFilters(false)
  }

  const _closeContentFilters = () => {
    props.onContentrSearch()
    setShowContentFilter(false)
    setShowMoreAdvancedContentFilters(false)
  }

  const _toggleAdvancedFilters = () => {
    setShowMoreAdvanceUserFilters(!showMoreAdvanceUserFilters)
  }

  const _renderCreator = () => {
    const count =
      props.communityManagerData &&
      props.communityManagerData.community &&
      props.communityManagerData.community.usersList &&
      props.communityManagerData.community.usersList.length

    const creators =
      (props.communityManagerData &&
        props.communityManagerData.community &&
        props.communityManagerData.community.usersList) ||
      []

    return creators && creators.length ? (
      <>
        <Chips
          showFilters={showUserFilter}
          toggleFilters={setShowUserFilter}
          search={_closeFilters}
          removeFilter={props.removeUserFilter}
          handleFilterChange={props._handleFilterChange}
          filters={props.userFilters}
          type="user"
          toggleAdvancedFilters={_toggleAdvancedFilters}
          showAdvancedFilters={showMoreAdvanceUserFilters}
        />
        <div className="creator-card-container mx-3">
          {props.communityUserData &&
            props.communityUserData.map((user, index) => {
              return (
                <UserCard
                  user={user}
                  key={index}
                  currentUser={props.currentUser}
                />
              )
            })}
        </div>
        <div className="d-flex justify-content-end pb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={val => {
              props.onCreatorClick(val)
              setCurrentCreatorPage(val)
            }}
            defaultCurrent={currentCreatorPage}
            total={
              props.totalCreators && props.totalCreators < 10000
                ? props.totalCreators
                : 10000
            }
            pageSize={9}
            showSizeChanger={false}
          />
        </div>
      </>
    ) : (
      <div
        className="font-size-18 text-center text-muted font_italic_bold"
        style={{ marginTop: "35vh" }}
      >
        Not Data Available.
      </div>
    )
  }

  const _getSmallAvatars = (element, background, text, className) => {
    return (
      <div className={className || "avatar-xs"}>
        <div className="avatar-title rounded-circle bg-light">
          <span
            className={
              "avatar-title rounded-circle bg-light bg-" +
              background +
              " text-" +
              text +
              " bg-opacity-10" +
              " font-size-16" +
              " font-weight-semibold"
            }
          >
            {element}
          </span>
        </div>
      </div>
    )
  }

  const _renderPages = () => {
    switch (props.currentTab) {
      case "dashboard":
        return _renderDashboard()
        break
      case "creator":
        return _renderCreator()
        break
      case "video":
        return _renderVideo()
      case "changelog":
        return _renderChangelog()
        break
      case "userDashboard":
        return _renderUserDashboard()
        break
    }
  }

  const handleDateRange = range => {
    let start
    let end = Date.now()
    if (range === "Yesterday") {
      start = Date.now() - 1 * 24 * 60 * 60 * 1000
      setEndDate(end)
      setStartDate(start)
      props.history.push(`/community/manage?startDate=${start}&endDate=${end}`)
      props.onDashboardClick(start, end)
    }
    if (range === "Last week") {
      start = Date.now() - 7 * 24 * 60 * 60 * 1000
      setEndDate(end)
      setStartDate(start)
      props.history.push(`/community/manage?startDate=${start}&endDate=${end}`)
    }
    if (range === "Last month") {
      start = Date.now() - 30 * 24 * 60 * 60 * 1000
      setEndDate(end)
      setStartDate(start)
      props.history.push(`/community/manage?startDate=${start}&endDate=${end}`)
    }
    if (range === "All time") {
      props.history.push(`/community/manage`)
    }
  }

  if (_.isEmpty(communityManagerData)) {
    return null
  }

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Row>
          {props.toggleshowdetails ? (
            <Col xxl="4" xl="3" sm="12" md="12" lg="12" className="p-0">
              <SimpleBar style={{ height: "85vh", backgroundColor: "white" }}>
                <div className="h-100">
                  <CardBody className="pt-0 h-100">
                    <Col>
                      <Row style={{ paddingTop: "20px" }}>
                        <Col sm="12" md="12" lg="12" xl="3">
                          <div className="avatar-md mb-4">
                            <span
                              className={
                                "avatar-title rounded-circle bg-soft bg-" +
                                "primary" +
                                " text-" +
                                "primary" +
                                " font-size-16"
                              }
                            >
                              {communityManagerData.name &&
                                communityManagerData.name
                                  .charAt(0)
                                  .toUpperCase()}
                            </span>
                          </div>
                        </Col>
                        <Col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="7"
                          style={{ marginTop: "20px" }}
                        >
                          <Row>
                            <h5 className="font-size-16 text-black font-weight-semibold">
                              {communityManagerData.name}
                            </h5>
                          </Row>
                          <Row>
                            <Col sm="10" xs="8" md="10" lg="10" xl="10">
                              <p className="text-muted mb-0 font-size-15">
                                {[13, 33].includes(
                                  communityManagerData.jotUserType
                                )
                                  ? "Community Manager"
                                  : "Community Lead"}
                              </p>
                            </Col>
                            {/* <Col sm="2" xs="4" lg="2" md="2" xl="2">
                          <i className="bx bx-pencil" />
                        </Col> */}
                          </Row>
                        </Col>
                        <Col xl="2" sm="12" md="12" lg="12" className="p-0">
                          <button
                            type="button"
                            onClick={() => props.toggleShowState()}
                            className="btn btn-sm px-3 font-size-16 btn-boxshadow"
                            id="vertical-menu-btn"
                          >
                            <i
                              className="bx bx-log-out fa-lg"
                              title="Click to hide User Details"
                            ></i>
                          </button>
                        </Col>
                      </Row>
                      {props.currentUser &&
                        [11, 12, 1, 2].includes(
                          props.currentUser.jotUserType
                        ) && (
                          <Row style={{ borderTop: "outset" }}>
                            <Col>
                              <div
                                className="font-weight-semibold text-muted font-size-16"
                                style={{ marginTop: "10px" }}
                              >
                                Agency
                              </div>
                              <Row style={{ marginBottom: "10px" }}>
                                <Col
                                  sm="10"
                                  xs="8"
                                  md="10"
                                  lg="10"
                                  xl="10"
                                  className="text-black font-size-15"
                                >
                                  {communityManagerData.agency || "Internal"}
                                </Col>
                                {/* <Col sm="2" xs="4" md="2" lg="2" xl="2">
                          <i className="bx bx-pencil" />
                        </Col> */}
                              </Row>
                            </Col>
                          </Row>
                        )}
                      <Row style={{ borderTop: "outset" }}>
                        <Col>
                          <div
                            className="font-weight-semibold text-muted font-size-16"
                            style={{ marginTop: "10px" }}
                          >
                            Creator Details
                          </div>
                          <Row className="text-black font-size-15 my-2">
                            <Col sm="1" style={{ marginRight: "5px" }}>
                              {_getSmallAvatars(
                                <i className="far fa-circle" />,
                                "black",
                                "primary"
                              )}
                            </Col>
                            <Col
                              sm="8"
                              style={{ paddingTop: "3px" }}
                              className="mx-2"
                            >
                              Active
                            </Col>
                          </Row>
                          <Row
                            className="text-black font-size-15 my-2"
                            style={{ marginBottom: "10px" }}
                          >
                            <Col sm="1" style={{ marginRight: "5px" }}>
                              {_getSmallAvatars(
                                <i className="fas fa-user" />,
                                "black",
                                "black-50"
                              )}
                            </Col>
                            <Col
                              sm="8"
                              style={{ paddingTop: "3px" }}
                              className="mx-2"
                            >
                              Managing{" "}
                              <span className="font-weight-semibold">
                                {(props.communityManagerData &&
                                  props.communityManagerData.community &&
                                  props.communityManagerData.community
                                    .usersList &&
                                  props.communityManagerData.community.usersList
                                    .length) ||
                                  0}
                              </span>{" "}
                              creators
                            </Col>
                          </Row>
                          <Row className="text-black font-size-15 my-2">
                            <Col sm="1" style={{ marginRight: "5px" }}>
                              {_getSmallAvatars(
                                <i className="fas fa-video" />,
                                "black",
                                "black-50"
                              )}
                            </Col>
                            <Col
                              sm="8"
                              style={{ paddingTop: "3px" }}
                              className="mx-2"
                            >
                              Total{" "}
                              <span className="font-weight-semibold">
                                {formatNumber(
                                  (props.aggData && props.aggData.posts) || 0
                                )}
                              </span>{" "}
                              contents
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {[12, 32].includes(communityManagerData?.jotUserType) && (
                        <Row style={{ borderTop: "outset" }}>
                          <Col>
                            <div
                              className="font-weight-semibold text-muted font-size-16"
                              style={{ marginTop: "10px" }}
                            >
                              Manager Details
                            </div>
                            <Row
                              className="text-black font-size-15 my-2"
                              style={{ marginBottom: "10px" }}
                            >
                              <Col sm="1" style={{ marginRight: "5px" }}>
                                {_getSmallAvatars(
                                  <i className="fas fa-user" />,
                                  "black",
                                  "black-50"
                                )}
                              </Col>
                              <Col
                                sm="8"
                                style={{ paddingTop: "3px" }}
                                className="mx-2"
                              >
                                Managing{" "}
                                <span className="font-weight-semibold">
                                  {formatNumber(
                                    (teamData && teamData.teamCount) || 0
                                  )}
                                </span>{" "}
                                managers
                              </Col>
                            </Row>
                            <Row className="text-black font-size-15 my-2">
                              <Col sm="1" style={{ marginRight: "5px" }}>
                                {_getSmallAvatars(
                                  <i className="fas fa-video" />,
                                  "black",
                                  "black-50"
                                )}
                              </Col>
                              <Col
                                sm="8"
                                style={{ paddingTop: "3px" }}
                                className="mx-2"
                              >
                                Total{" "}
                                <span className="font-weight-semibold">
                                  {formatNumber(
                                    (teamData && teamData.posts) || 0
                                  )}
                                </span>{" "}
                                contents
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row style={{ borderTop: "outset" }}>
                        <Col>
                          <div
                            className="font-weight-semibold text-muted font-size-16"
                            style={{ marginTop: "10px" }}
                          >
                            Stats
                          </div>
                          <Row style={{ marginBottom: "10px" }}>
                            <div className="table-responsive">
                              <Table className="table-nowrap mb-0">
                                <tbody>
                                  <tr className="text-start">
                                    <th scope="row">Views</th>
                                    <td className="d-flex justify-content-end">
                                      {formatNumber(
                                        props.aggData && props.aggData.fkviews
                                      ) || "NA"}
                                    </td>
                                  </tr>
                                  <tr className="text-start">
                                    <th scope="row">Downloads</th>
                                    <td className="d-flex justify-content-end">
                                      {formatNumber(
                                        props.aggData &&
                                          props.aggData.fkdownloads
                                      ) || "NA"}
                                    </td>
                                  </tr>
                                  <tr className="text-start">
                                    <th scope="row">Shares</th>
                                    <td className="d-flex justify-content-end">
                                      {formatNumber(
                                        props.aggData && props.aggData.fkshares
                                      ) || "NA"}
                                    </td>
                                  </tr>
                                  <tr className="text-start">
                                    <th scope="row">Likes</th>
                                    <td className="d-flex justify-content-end">
                                      {formatNumber(
                                        props.aggData && props.aggData.fklikes
                                      ) || "NA"}
                                    </td>
                                  </tr>
                                  <tr className="text-start">
                                    <th scope="row">Total Followers</th>
                                    <td className="d-flex justify-content-end">
                                      {formatNumber(
                                        props.aggData && props.aggData.followers
                                      ) || "NA"}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      {realStatPermissionUser && (
                        <Row style={{ borderTop: "outset" }}>
                          <Col>
                            <div
                              className="font-weight-semibold text-muted font-size-16"
                              style={{ marginTop: "10px" }}
                            >
                              Real Stats
                            </div>
                            <Row style={{ marginBottom: "10px" }}>
                              <div className="table-responsive">
                                <Table className="table-nowrap mb-0">
                                  <tbody>
                                    <tr className="text-start">
                                      <th scope="row">Views</th>
                                      <td className="d-flex justify-content-end">
                                        {formatNumber(
                                          props.aggData && props.aggData.views
                                        ) || "NA"}
                                      </td>
                                    </tr>
                                    <tr className="text-start">
                                      <th scope="row">Downloads</th>
                                      <td className="d-flex justify-content-end">
                                        {formatNumber(
                                          props.aggData &&
                                            props.aggData.downloads
                                        ) || "NA"}
                                      </td>
                                    </tr>
                                    <tr className="text-start">
                                      <th scope="row">Shares</th>
                                      <td className="d-flex justify-content-end">
                                        {formatNumber(
                                          props.aggData && props.aggData.shares
                                        ) || "NA"}
                                      </td>
                                    </tr>
                                    <tr className="text-start">
                                      <th scope="row">Likes</th>
                                      <td className="d-flex justify-content-end">
                                        {formatNumber(
                                          props.aggData && props.aggData.likes
                                        ) || "NA"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </CardBody>
                </div>
              </SimpleBar>
            </Col>
          ) : null}
          <Col
            xxl="8"
            xl={props.toggleshowdetails ? "9" : "12"}
            sm="12"
            md="12"
            lg="12"
          >
            <SimpleBar style={{ height: "85vh" }}>
              <div className="d-flex mb-3 align-items-centerh-100 flex-wrap sticky-top bg-light">
                {!props.toggleshowdetails ? (
                  <div
                    sm="12"
                    lg="3"
                    role="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => props.toggleShowState()}
                    className={
                      props.currentTab === "dashboard"
                        ? "text-black d-flex align-items-center mx-3 font-size-18"
                        : "text-muted d-flex align-items-center mx-3 font-size-16"
                    }
                  >
                    <i
                      className="bx bx-log-in fa-lg"
                      title="Click to view User Details"
                    ></i>
                  </div>
                ) : null}

                <div
                  sm="12"
                  lg="3"
                  role="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onTabChange("dashboard")}
                  className={
                    props.currentTab === "dashboard"
                      ? "text-black d-flex align-items-center mx-3 font-size-18"
                      : "text-muted d-flex align-items-center mx-3 font-size-16"
                  }
                >
                  <i
                    className="fas fa-border-all"
                    style={{ marginRight: "5px" }}
                  />
                  Dashboard
                </div>
                {userDash && (
                  <div
                    role="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => props.onTabChange("userDashboard")}
                    className={
                      props.currentTab === "userDashboard"
                        ? "text-black d-flex align-items-center mx-3 font-size-18"
                        : "text-muted d-flex align-items-center mx-3 font-size-16"
                    }
                  >
                    <i
                      className="fas fa-border-all"
                      style={{ marginRight: "5px" }}
                    />
                    Team Dashboard
                  </div>
                )}
                <div
                  role="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onTabChange("creator")}
                  className={
                    props.currentTab === "creator"
                      ? "text-black d-flex align-items-center mx-3 font-size-18"
                      : "text-muted d-flex align-items-center mx-3 font-size-17"
                  }
                >
                  <i className="fas fa-user" style={{ marginRight: "5px" }} />
                  Creators
                </div>

                <div
                  role="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onTabChange("video")}
                  className={
                    props.currentTab === "video"
                      ? "text-black d-flex align-items-center mx-3 font-size-18"
                      : "text-muted d-flex align-items-center mx-3 font-size-16"
                  }
                >
                  <i className="fas fa-video" style={{ marginRight: "5px" }} />
                  Videos
                </div>
                <div
                  role="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onTabChange("changelog")}
                  className={
                    props.currentTab === "changelog"
                      ? "text-black d-flex align-items-center mx-3 font-size-18"
                      : "text-muted d-flex align-items-center mx-3 font-size-16"
                  }
                >
                  <i className="fas fa-tasks" style={{ marginRight: "5px" }} />
                  Changelog
                </div>
              </div>
              {_renderPages()}
            </SimpleBar>
          </Col>

          {/* <Col
            xl="8"
            sm="12"
            md="12"
            lg="12"
            style={{ maxHeight: "85vh", overflowY: "scroll" }}
          >
            <Row
              style={{ fontSize: "18px", paddingBottom: "20px" }}
              className="mx-auto mx-xl-4"
            >
              <Col sm="4" lg="3">
                <div
                  role="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onTabChange("dashboard")}
                  className={
                    props.currentTab === "dashboard"
                      ? "text-black"
                      : "text-muted"
                  }
                >
                  <i
                    className="fas fa-border-all"
                    style={{ marginRight: "5px" }}
                  />
                  Dashboard
                </div>
              </Col>
              <Col sm="4" lg="3">
                <div
                  role="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onTabChange("creator")}
                  className={
                    props.currentTab === "creator" ? "text-black" : "text-muted"
                  }
                >
                  <i className="fas fa-user" style={{ marginRight: "5px" }} />
                  Creators
                </div>
              </Col>
              <Col sm="4" lg="3">
                <div
                  role="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onTabChange("video")}
                  className={
                    props.currentTab === "video" ? "text-black" : "text-muted"
                  }
                >
                  <i className="fas fa-video" style={{ marginRight: "5px" }} />
                  Videos
                </div>
              </Col>
            </Row> */}
          {/* <Row className="mx-xl-2" style={{maxWidth:'40vw'}}>
            {pageType==='dashboard' && <div className="font-size-14 mb-2 text-black">
              <span className="font-weight-semibold">Date Range:</span>  {timeRange.map(r=>{

                return <div className="form-check form-check-inline" onClick={()=>setRange(r)}>
                <Input
                  type="radio"
                  id={"customRadioInline_"+r}
                  name="customRadioInline1"
                  className="form-check-input"
                  checked={r === range}
                  onChange={()=>{handleDateRange(r); setClick(r==='Custom'?true:false)}}
                />
                <Label
                  className="form-check-label"
                  htmlFor="customRadioInline1"
                >
                  {r}
                </Label>
              </div>
              })}
            </div>
            }
            <Popover placement="right" target="customRadioInline_Custom" isOpen={range === 'Custom' && open}>
              <PopoverBody>
                <Row className="justify-content-end">
                  <DatePicker
                    defaultValue={
                      startDate
                        ? moment(startDate, "x")
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="From Date"
                    onChange={(date,dateString) => {
                      setStartDate(!_.isEmpty(dateString)
                      ? moment(dateString).format("x")
                      : null);
                      setClick(range === 'Custom'? true:false)
                    }}
                    style={{ width: "100%" }}
                  />
                  <DatePicker
                    defaultValue={
                      endDate
                        ? moment(endDate, "x")
                        : null
                    }
                    format="YYYY-MM-DD HH:mm"
                    showTime
                    placeholder="To Date"
                    onChange={(date,dateString) => {
                      setEndDate(!_.isEmpty(dateString)
                      ? moment(dateString).format("x")
                      : null);
                      setClick(range === 'Custom'? true:false)
                    }}
                    style={{ width: "100%" }}
                  />
                  <Col md="4" className="mt-2">
                    <Button color="primary" onClick={()=>setClick(false)}>Ok</Button>
                  </Col>
                </Row>
              </PopoverBody>
            </Popover>
            </Row> */}
          {/* {_renderPages()}
          </Col> */}
        </Row>
      </Container>
    </React.Fragment>
  )
}

DashboardPage.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(DashboardPage)
