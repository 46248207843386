import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import { getImages, upsertImage, resetState } from "appRedux/slices/image"
import _ from "lodash"
import { Row, Col } from "reactstrap"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import ImageUpsertForm from "./imageUpsertForm"
import Auxiliary from "../../util/Auxiliary"
import { Input } from "reactstrap"
import { getZones } from "appRedux/slices/zone"
import { getBrandCampaign } from "appRedux/slices/campaign"
class ManageImage extends ErrorBoundComponent {
  state = {
    filters: {
      imageId:
        this.props.queryParams && this.props.queryParams["imageUuid"]
          ? this.props.queryParams["imageUuid"]
          : null,
      currentPage: 1,
    },
    image: this.props.imageList && this.props.imageList[0],
    showUpsertModal: true,
    formAction: "create",
  }

  componentDidMount() {
    this.props.dispatch(getZones({ currentPage: 1, pageSize: 10000 }))
    this.props.dispatch(getBrandCampaign({ currentPage: 1, pageSize: 10000 }))
    if (!this.props.common.error && this.state.filters.imageId) {
      this.props.dispatch(getImages(this.state.filters))
      this.setState({
        formAction: "update",
      })
    }
  }

  componentDidUpdate(prevProps) {
    // if (_.isEmpty(this.state.image)) {
    //   this.setState({
    //     image: this.props.imageList && this.props.imageList[0],
    //   })
    // }
    if (
      this?.props?.queryParams?.imageUuid !== prevProps?.queryParams?.imageUuid
    ) {
      this._searchImage(this?.props?.queryParams?.imageUuid)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  _onImageUpsertSubmit = async formData => {
    this.props.dispatch(upsertImage(formData))
  }

  _searchImage = value => {
    if (value) {
      this.props.history.push(`/image/manage?imageUuid=${value}`)
      this.setState(
        {
          filters: {
            ...this.state.filters,
            imageId: value,
          },
          formAction: "update",
        },
        () => {
          this.props.dispatch(getImages(this.state.filters))
        }
      )
    }
  }

  _filters = () => {
    return (
      <Row style={{ marginBottom: "1rem" }}>
        <Col lg="4">
          <Input
            placeholder="Image ID"
            onChange={e => this._searchImage(e.target.value)}
            validations={["required"]}
            defaultValue={this.state.filters.imageId}
          />
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._filters()}
        <ImageUpsertForm
          formAction={this.state.formAction}
          onSubmit={this._onImageUpsertSubmit}
          image={this.props.imageList && this.props.imageList[0]}
          currentUserType={this.props.currentUserType}
          currentUser={this.props.currentUser}
          history={this.props.history}
          zoneList={this.props.zoneList}
          campaignList={this.props.campaignList}
        />
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "image.loading"),
    imageList: _.get(store, "image.imageList"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    audio: store.audio.audioList.length === 1 ? store.audio.audioList[0] : null,
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    zoneList: _.get(store, "zone.zoneList"),
    campaignList: _.get(store, "campaign.campaign"),
    totalCampaign: _.get(store, "campaign.totalCampaign"),
  }
}

export default connect(mapStateToProps)(ManageImage)
