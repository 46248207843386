import { createSlice } from "@reduxjs/toolkit"
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout"

const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    layoutType: layoutTypes.VERTICAL,
    layoutWidth: layoutWidthTypes.FLUID,
    leftSideBarTheme: leftSideBarThemeTypes.DARK,
    leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
    leftSideBarType: leftSidebarTypes.CONDENSED,
    topbarTheme: topBarThemeTypes.LIGHT,
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
  },
  reducers: {
    changeLayout(state, action) {
      return {
        ...state,
        layoutType: action.payload,
      }
    },
    changePreLoader(state, action) {
      return {
        ...state,
        isPreloader: action.payload,
      }
    },
    changeSidebarTheme(state, action) {
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    },
    changeSidebarThemeImage(state, action) {
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    },
    changeSidebarType(state, action) {
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    },
    changeTopbarTheme(state, action) {
      return {
        ...state,
        topbarTheme: action.payload,
      }
    },
    changeLayoutWidth(state, action) {
      return {
        ...state,
        layoutWidth: action.payload,
      }
    },
  },
})

export const {
  changePreLoader,
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} = layoutSlice.actions

export default layoutSlice.reducer
