import { useState, useEffect } from "react"

function useAudioPlayer(audiocard, callback, callback2) {
  const [duration, setDuration] = useState()
  const [curTime, setCurTime] = useState(
    audiocard.default_start_selection_ms
      ? audiocard.default_start_selection_ms / 1000
      : 0
  )
  const [curEndTime, setcurEndTime] = useState(
    audiocard.default_end_selection_ms
      ? audiocard.default_end_selection_ms / 1000
      : audiocard.duration_ms / 1000
  )

  const [curStartTime, setcurStartTime] = useState(
    audiocard.default_start_selection_ms
      ? audiocard.default_start_selection_ms / 1000
      : 0
  )

  const [playing, setPlaying] = useState(false)
  const [played, setPlayed] = useState(false)
  const [clickedTime, setClickedTime] = useState()

  useEffect(() => {
    const audio = document.getElementById("audio")

    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration)
    }
    callback(curStartTime)
    callback2(curEndTime)
    const setAudioTime = () => {
      setCurTime(audio.currentTime)
    }
    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData)

    audio.addEventListener("timeupdate", setAudioTime)

    // React state listeners: update DOM on React state changes
    // playing ? audio.play() : audio.pause()
    if (playing) {
      if (!played) {
        audio.currentTime = curStartTime
      }
      if (audio.currentTime > curEndTime) {
        setPlaying(false)
      }
      audio.play()
      setPlayed(true)
    } else {
      audio.pause()
      setPlayed(false)
    }
    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime
      setClickedTime(null)
    }

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData)
      audio.removeEventListener("timeupdate", setAudioTime)
    }
  }, [
    curTime,
    curEndTime,
    curStartTime,
    curStartTime,
    clickedTime,
    playing,
    played,
    setPlayed,
    setDuration,
    setCurTime,
    setcurStartTime,
    setcurEndTime,
    setClickedTime,
  ])

  return {
    curTime,
    curEndTime,
    duration,
    curStartTime,
    playing,
    setPlaying,
    setClickedTime,
    setcurEndTime,
    setcurStartTime,
  }
}

export default useAudioPlayer
