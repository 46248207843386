import { createSlice } from "@reduxjs/toolkit"

const giftSlice = createSlice({
  name: "gift",
  initialState: {
    pageList: [],
    total: 0,
    loading: false,
    pageEntitiesByLanguageLocation: [],
    pageEntities: [],
    tabs: [],
    collectionEntitiesGroups: [],
    collectionEntities: [],
    entities: [],
    totalPageEntities: 0,
    refetchData: false,
  },
  reducers: {
    getGifts(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
        entities: [],
      }
    },
    getGiftsSuccess(state, { payload }) {
      return {
        ...state,
        entities: payload.data.data,
        loading: false,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    upsertGift(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertGiftSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertGiftCollectionEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertGiftCollectionEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upsertGiftCollection(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upsertGiftCollectionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    deleteGifts(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    deleteGiftsSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    reorderGiftCollectionEntity(state) {
      return {
        ...state,
        loading: true,
        refetchData: true,
      }
    },
    reorderGiftCollectionEntitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: true,
      }
    },
    upgradeGiftListVersion(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    upgradeGiftListVersionSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchData: false,
      }
    },
    resetState(state) {
      return {
        ...state,
        pageList: [],
        total: 0,
        loading: false,
        refetchData: false,
        pageEntitiesByLanguageLocation: [],
        pageEntities: [],
        tabs: [],
        collectionEntitiesGroups: [],
        collectionEntities: [],
        totalPageEntities: 0,
      }
    },
  },
})

export const {
  getGifts,
  getGiftsSuccess,
  upsertGift,
  upsertGiftSuccess,
  reorderGiftCollectionEntity,
  reorderGiftCollectionEntitySuccess,
  upsertGiftCollectionEntity,
  upsertGiftCollectionEntitySuccess,
  deleteGifts,
  deleteGiftsSuccess,
  upsertGiftCollection,
  upsertGiftCollectionSuccess,
  upgradeGiftListVersion,
  upgradeGiftListVersionSuccess,
  resetState,
} = giftSlice.actions

export default giftSlice.reducer
