import React from "react"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Divider } from "antd"
import { Button } from "reactstrap"
import QuestionCard from "./questionCard"
import ImageCard from "./imageCard"
import { Col, Radio, Row } from "antd"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { setTaxonomyData, setTaxonomyRoutes } from "appRedux/slices/taxonomy"

const { Group } = Radio

class Ethnicity extends ErrorBoundComponent {
  state = {
    currentTab: "ethnicity",
    labelToDisplay: "Ethnicity",
  }

  _handleSelection = value => {
    const currentData = cloneDeep(this.props.currentData)
    let selectedDetails = { ...this.props.selectedDetails }
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    const indexTab = taxonomyRoutes.findIndex(item => item.key == "ethnicity")
    if (value === "no_violence") {
      selectedDetails["video_additional_info"]["ethnicity"] = ""
    } else if (selectedDetails["video_additional_info"]) {
      selectedDetails["video_additional_info"]["ethnicity"] = value
    } else {
      selectedDetails["video_additional_info"] = {}
      selectedDetails["video_additional_info"]["ethnicity"] = value
    }

    let stepCompleted = true

    if (stepCompleted) {
      let nextIndex = 0
      for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
        if (taxonomyRoutes[i].showInSidebar) {
          nextIndex = i
          break
        }
      }
      this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
      taxonomyRoutes[indexTab].completed = true
    }
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    this.props.dispatch(
      setTaxonomyData({ currentData, currentTab: this.state.currentTab })
    )
    this.setState({
      currentData,
      ignore: false,
      tag: false,
      ignoreRatingLessThan4: false,
      retag: false,
    })

    this.props.setSelectedDetails(selectedDetails)
  }

  _renderCard = (question, key, idx) => {
    return (
      <>
        <div>
          <div>{question.label}</div>
          <div className="d-flex flex-wrap">
            {question.sublevel.map(item => {
              const data = _.cloneDeep(item)
              if (question.isMultiple) {
                if (
                  this.props.selectedDetails?.video_additional_info
                    ?.ethnicity === data.value
                ) {
                  data.selected = true
                }
              } else {
                if (
                  this.props.selectedDetails?.video_additional_info
                    ?.ethnicity === data.value
                ) {
                  data.selected = true
                } else {
                  data.selected = false
                }
              }
              return (
                <ImageCard
                  data={data}
                  _handleSelection={data => this._handleSelection(data.value)}
                  renderCardContent={this.props.renderCardContent}
                />
              )
            })}
          </div>
        </div>
      </>
    )
  }

  _renderStepper = () => {
    let text = "Select All"
    this.props.currentData.forEach(item => {
      if (item.level > 0) {
        text = "Questionnaire"
      }
    })

    return (
      <div style={{ margin: "1rem", fontWeight: "bold" }}>
        {this.state.labelToDisplay}&nbsp;({text})
      </div>
    )
  }

  _renderQuestions = () => {
    return (
      <div className="d-flex text-center contentCard my-2">
        <PerfectScrollbar>
          <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
            {this.props.currentData.map((question, idx) => {
              if (question.type === "card") {
                return this._renderCard(question, idx)
              }

              return (
                <ImageCard
                  data={question}
                  _handleSelection={data => this._handleSelection(data.key)}
                  renderCardContent={this.props.renderCardContent}
                />
              )
            })}
          </div>
        </PerfectScrollbar>
      </div>
    )
  }

  _renderTitle = () => {
    return <div className="font-weight-semibold pt-3">Ethnicity</div>
  }

  _renderOptions = () => {
    return (
      <div className="d-flex">
        {
          <Row
            className={`d-flex  text-center contentCard my-3 justify-content-around`}
          >
            <Col span={24} className="my-2">
              What kind of ethnicity is diplayed in the video?
            </Col>
          </Row>
        }
      </div>
    )
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderTitle()}
        {this._renderOptions()}
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store) {
  return {
    currentUser: _.get(store, "currentUser.currentUser.data"),
    currentData: _.get(store, "taxonomy.ethnicity"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(Ethnicity)
