import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import { Pagination, Tag } from "antd"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getHashtagByFilter, resetState } from "appRedux/slices/hashtag"
import Auxiliary from "../../util/Auxiliary"
import "../Comment/browseComment.style.css"
import HashtagCard from "./hashtagCard"
import Chips from "components/CommonFilter/chips"
import { hashtagFilters } from "constants/uiConstants"
import { formatNumber } from "../../util/common"
import "./styles.css"

class BrowseHashtag extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 12,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      name:
        this.props.queryParams && this.props.queryParams["name"]
          ? this.props.queryParams["name"]
          : null,
    },
    showFilters: false,
    showAdvancedFilters: false,
  }

  componentDidMount() {
    if (!this.props.common.error) {
      let filters = _.clone(this.state.filters)
      this.props.dispatch(getHashtagByFilter({ filters: _.deepClean(filters) }))
      this.setState({ filters })
      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _keyDownHandler = ({ key }) => {
    if (key === "Enter" && this.state.showFilters === true) {
      return this._closeFilters()
    }
    if (key === "Escape" && this.state.showFilters === true) {
      this.setState({
        showFilters: false,
        showAdvancedFilters: false,
      })
    }
  }

  _handleFilterChange = (value, type) => {
    const newFilters = _.cloneDeep(this.state.filters)
    newFilters[type] = value
    this.setState({ filters: newFilters })
  }

  _searchHashtag = () => {
    let { filters } = this.state
    if (filters && filters.pageSize) {
      delete filters.currentPage
    }
    this.props.history.push(
      `/hashtag/browse?${QueryString.stringify(
        _.deepClean(this.state.filters)
      )}`
    )
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState({ filters: newFilters }, () => {
      this.props.history.push(
        `/hashtag/browse?${QueryString.stringify(
          _.deepClean(this.state.filters)
        )}`
      )
      this.props.dispatch(
        getHashtagByFilter({ filters: _.deepClean(newFilters) })
      )
    })
  }

  _onSearch = () => {
    let filters = _.cloneDeep(this.state.filters)
    _.map(this.state.filters, (filter, key) => {
      if (!filter) {
        _.unset(filters, key)
      }
    })
    this.props.history.push(`/hashtag/browse?${QueryString.stringify(filters)}`)
    this.props.dispatch(getHashtagByFilter({ filters: _.deepClean(filters) }))
  }

  _closeFilters = () => {
    this._onSearch()
    this.setState({ showFilters: false, showAdvancedFilters: false })
  }

  _toggleAdvancedFilters = () => {
    this.setState({
      showAdvancedFilters: !this.state.showAdvancedFilters,
    })
  }

  _toggleFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    })
  }

  _removeFilter = filter => {
    const newFilters = {
      ...this.state.filters,
      [filter]: hashtagFilters[filter].defaultValue,
      showFilters: false,
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.props.history.push(
          `/hashtag/browse?${QueryString.stringify(
            _.deepClean(this.state.filters)
          )}`
        )
        let filters = _.cloneDeep(this.state.filters)
        this.props.dispatch(
          getHashtagByFilter({ filters: _.deepClean(filters) })
        )
      }
    )
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <Chips
          type="hashtag"
          showFilters={this.state.showFilters}
          showAdvancedFilters={this.state.showAdvancedFilters}
          filters={this.state.filters}
          search={this._closeFilters}
          removeFilter={this._removeFilter}
          handleFilterChange={this._handleFilterChange}
          toggleFilters={this._toggleFilters}
          toggleAdvancedFilters={this._toggleAdvancedFilters}
        />
        {this.props.hashtagList && this.props.hashtagList.length > 0 && (
          <div style={{ textAlign: "center" }} className="mb-2">
            <Tag color="geekblue">
              Showing <b>{this.props.hashtagList.length}</b> of{" "}
              <b>{formatNumber(this.props.total)}</b> Hashtags
            </Tag>
          </div>
        )}
        <div className="hashtag-card-container">
          {this.props.hashtagList &&
            this.props.hashtagList.length > 0 &&
            this.props.hashtagList.map(hashtag => {
              return (
                <HashtagCard
                  key={hashtag.name}
                  data={hashtag}
                  history={this.props.history}
                />
              )
            })}
        </div>
        <div className="d-flex justify-content-end mb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={this._onPageChange}
            defaultCurrent={this.state.filters.currentPage}
            total={this.props.total < 10000 ? this.props.total : 10000}
            pageSize={this.state.filters.pageSize}
            showSizeChanger={false}
          />
        </div>
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    hashtagList: _.get(store, "hashtag.hashtagListByFilter"),
    queryParams: _.get(ownProps, "routing.location.search")
      ? QueryString.parseUrl(_.get(ownProps, "routing.location.search")).query
      : undefined,
    total: _.get(store, "hashtag.total"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "hashtag.loading"),
  }
}

export default connect(mapStateToProps)(BrowseHashtag)
