import React, { Fragment } from "react"
import { connect } from "react-redux"
import _, { cloneDeep } from "lodash"
import { Button, Row, Col } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import Cards from "./cards"
import SelectedZoneCard from "./SelectedZoneCards"
import { setTaxonomyRoutes } from "../../appRedux/slices/taxonomy"
import "./TaxonomyDashboard.css"
import { getZoneByIds } from "clientServices/zoneService"
import { Spin } from "antd"

class ZoneVerification extends Cards {
  constructor(props) {
    super(props)
    let showZoneList = []
    this.state = {
      labelToDisplay: "Zone",
      currentHierarchy: ["zone"],
      currentTab: "zone",
      currentLevel: 0,
      zoneData: [],
      selectedIds: [],
      selectedZones: [],
      zoneList: this.props.zoneList,
      selectedZoneCard: undefined,
      addedZones: [],
      qcApprovedZones: [],
      qcRejectedZones: [],
      removedList: [],
      addedList: [],
      fixedZoneList: [],
      zoneInfo: this.props.selectedContent.zone_info_v1 || [],
      qcApprovedZones:
        this.props.selectedDetails?.approved_zone_obj?.qcApprovedZones || [],
      qcRejectedZones:
        this.props.selectedDetails?.approved_zone_obj?.qcRejectedZones || [],
      showZoneList,
      selectedZoneImage: "",
      url: "",
      loadingZones: false,
    }
  }

  async componentDidMount() {
    const addedZones = []
    const fixedZoneList = []
    const qcApprovedZones = [...this.state.qcApprovedZones]
    const qcRejectedZones = [...this.state.qcRejectedZones]
    const ids = []
    this.state.zoneInfo &&
      this.state.zoneInfo.forEach(zone => {
        ids.push(zone.zone_id)
        if (
          !qcApprovedZones.includes(zone.zone_id) &&
          !qcRejectedZones.includes(zone.zone_id)
        ) {
          if (zone.status.includes("added")) {
            addedZones.push(zone.zone_id)
          }
          if (zone.status.includes("removed")) {
            qcRejectedZones.push(zone.zone_id)
          }
          if (zone.status.includes("approved")) {
            qcApprovedZones.push(zone.zone_id)
          }
          if (zone.status.includes("fixed")) {
            fixedZoneList.push(zone.zone_id)
          }
        }
      })
    this.setState({
      loadingZones: true,
    })
    let result = this.props.allZonesList.filter(zone =>
      ids.includes(zone.zone_uuid)
    )
    this.setState({
      zoneList: this.props.zoneList,
      zoneData: [],
      addedZones,
      qcApprovedZones,
      qcRejectedZones,
      qcApprovedZonesData: result,
      loadingZones: false,
      fixedZoneList,
    })
  }

  setZoneImage = img => {
    this.setState({
      selectedZoneImage: img,
      url: "https://www.google.com/search?q=" + img + "+images&igu=1",
    })
  }

  _handleDone = () => {
    let taxonomyRoutes = cloneDeep(this.props.taxonomyRoutes)
    let selectedDetails = cloneDeep(this.props.selectedDetails)
    const indexTab = taxonomyRoutes.findIndex(
      item => item.key == "zone_verification"
    )
    taxonomyRoutes[indexTab].completed = true
    let approvedZoneObj = {
      qcApprovedZones: [],
      qcRejectedZones: [],
      qcApprovedZonesData: [],
    }
    approvedZoneObj = selectedDetails["approved_zone_obj"]
      ? { ...approvedZoneObj, ...selectedDetails["approved_zone_obj"] }
      : approvedZoneObj

    approvedZoneObj = {
      ...approvedZoneObj,
      zoneInfo: this.state.zoneInfo,
      qcApprovedZones: _.uniq([
        ...approvedZoneObj.qcApprovedZones,
        ...this.state.qcApprovedZones,
      ]).filter(id => !this.state.qcRejectedZones.includes(id)),
      qcRejectedZones: _.uniq([
        ...approvedZoneObj.qcRejectedZones,
        ...this.state.qcRejectedZones,
      ]),
      qcApprovedZonesData: [
        ...approvedZoneObj.qcApprovedZonesData,
        ...this.state.qcApprovedZonesData,
      ],
    }
    selectedDetails["approved_zone_obj"] = approvedZoneObj

    this.props.setSelectedDetails(selectedDetails)
    this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))

    let nextIndex = 0
    for (let i = indexTab + 1; i < taxonomyRoutes.length; i++) {
      if (taxonomyRoutes[i].showInSidebar) {
        nextIndex = i
        break
      }
    }
    this.props.setSelectedSection(taxonomyRoutes[nextIndex].key)
  }

  setQCTaxonomyData = (status, data) => {
    const zoneInfo = _.cloneDeep(this.state.zoneInfo)
    let qcApprovedZones = this.state.qcApprovedZones
    let qcRejectedZones = this.state.qcRejectedZones
    let removedList = this.state.removedList
    let addedList = this.state.addedList
    if (status === "YES" || status === "ADD" || status === "APPROVED") {
      if (qcRejectedZones.includes(data.zone_uuid)) {
        qcRejectedZones = qcRejectedZones.filter(id => id !== data.zone_uuid)
      }
      if (status === "ADD") {
        addedList.push(data.zone_uuid)
      }
      qcApprovedZones.push(data.zone_uuid)
      zoneInfo.forEach(zone => {
        if (zone.zone_id === data.zone_uuid) {
          ;(zone.status = "approved"), (zone.modified_on = new Date())
          zone.modified_by = this.props.currentUser.email
        }
      })
    } else if (status === "REMOVE") {
      if (qcApprovedZones.includes(data.zone_uuid)) {
        qcApprovedZones = qcApprovedZones.filter(id => id !== data.zone_uuid)
      }
      if (addedList.includes(data.zone_uuid)) {
        addedList = addedList.filter(id => id !== data.zone_uuid)
      }
      removedList.push(data.zone_uuid)
    } else if (status === "REMOVED") {
      if (qcApprovedZones.includes(data.zone_uuid)) {
        qcApprovedZones = qcApprovedZones.filter(id => id !== data.zone_uuid)
      }
      if (addedList.includes(data.zone_uuid)) {
        addedList = addedList.filter(id => id !== data.zone_uuid)
      }
      removedList.push(data.zone_uuid)

      let shouldAdd = false

      this.props?.selectedContent?.zone_info_v1?.forEach(zone => {
        if (zone.zone_id === data.zone_uuid) shouldAdd = true
      })

      if (shouldAdd) {
        qcRejectedZones.push(data.zone_uuid)
      }
    } else {
      if (qcApprovedZones.includes(data.zone_uuid)) {
        qcApprovedZones = qcApprovedZones.filter(id => id !== data.zone_uuid)
      }
      if (
        !removedList.includes(data.zone_uuid) &&
        !addedList.includes(data.zone_uuid)
      ) {
        qcRejectedZones.push(data.zone_uuid)
      }
      zoneInfo.forEach(zone => {
        if (zone.zone_id === data.zone_uuid) {
          ;(zone.status = "removed"), (zone.modified_on = new Date())
          zone.modified_by = this.props.currentUser.email
        }
      })
    }

    this.setState({
      addedList: _.uniq(addedList),
      removedList: _.uniq(removedList),
      qcApprovedZones: _.uniq(qcApprovedZones),
      qcRejectedZones: _.uniq(qcRejectedZones),
      zoneInfo,
    })
  }

  donedisable = () => {
    let disabled = false
    for (let zone of this.state.zoneInfo) {
      if (zone.status == "added") {
        let flag = true
        let disabledzone = false
        let allzoneID = this.props.allZonesList
          .filter(item => item.status != "DISABLED")
          .map(items => items.zone_uuid)
        if (allzoneID.includes(zone.zone_id)) {
          disabledzone = false
        } else {
          disabledzone = true
        }

        if (disabledzone) {
        } else {
          for (let appzone of this.state.qcApprovedZones) {
            if (appzone === zone.zone_id) {
              flag = false
            }
          }

          for (let rejzone of this.state.qcRejectedZones) {
            if (rejzone === zone.zone_id) {
              flag = false
            }
          }
          if (flag) {
            disabled = true
            break
          }
        }
      }
    }
    return disabled
  }

  _renderCardContent = data => {
    const imgSource = data.zone_image
    return (
      <div className="card" style={{ width: "20rem" }}>
        {imgSource && (
          <img src={imgSource} alt="Card image cap" className="card-img-top" />
        )}
        <div className="card-body">
          <h5 className="card-title">{data.title}</h5>
          <p>{data.sub_title}</p>
        </div>
      </div>
    )
  }

  _renderZones = () => {
    let data = this.props.allZonesList
    let allTypes = []
    let allSubTypes = []
    allTypes = data.filter(zone => zone.level == 0)
    allSubTypes = data.filter(zone => zone.level == 1)

    this._handleClick = (data, status) => {
      this.setQCTaxonomyData(status, data)
    }

    let allZones = [
      ...this.state.addedZones,
      ...this.state.qcApprovedZones,
      ...this.state.qcRejectedZones,
    ]

    let zoneData = []
    let allZoneFiltered = []
    for (let item of allZones) {
      for (let item2 of this.state.zoneInfo) {
        if (item === item2.zone_id) {
          allZoneFiltered = [...allZoneFiltered, item]
        }
      }
    }
    this.props.allZonesList.forEach(zone => {
      if (allZoneFiltered.includes(zone.zone_uuid)) {
        zoneData.push(zone)
      }
    })

    let fixedZoneData = []
    let fixedZoneFiltered = []
    for (let item of this.state.fixedZoneList || []) {
      for (let item2 of this.state.zoneInfo) {
        if (item === item2.zone_id) {
          fixedZoneFiltered = [...fixedZoneFiltered, item]
        }
      }
    }
    this.props.allZonesList.forEach(zone => {
      if (fixedZoneFiltered.includes(zone.zone_uuid)) {
        fixedZoneData.push(zone)
      }
    })

    return (
      <Fragment>
        <Row className="mt-2 mx-1">
          <Col lg="12">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <div className="d-flex ">Added Zones</div>
              <div>
                <Button
                  disabled={this.donedisable()}
                  color="success"
                  onClick={() => this._handleDone()}
                >
                  Done
                </Button>
              </div>
            </div>
            <div style={{ height: "90vh" }}>
              <PerfectScrollbar>
                {this.state.loadingZones ? (
                  <div className="d-flex justify-content-center align-items-center h-50">
                    <Spin tip="Loading data..." />
                  </div>
                ) : (
                  <>
                    <div
                      className="d-flex flex-wrap m-1"
                      style={{ overflowX: "hidden" }}
                    >
                      {zoneData.map(data => {
                        let selectedCard = ""
                        let isSelected = false

                        if (
                          this.state.qcApprovedZones.includes(data.zone_uuid)
                        ) {
                          selectedCard = "selectedCard"
                          isSelected = "selected"
                        } else if (
                          this.state.qcRejectedZones.includes(data.zone_uuid)
                        ) {
                          selectedCard = "unSelectedCard"
                          isSelected = "removed"
                        }

                        return (
                          <SelectedZoneCard
                            data={data}
                            _handleSelection={this._handleClick}
                            renderCardContent={this._renderCardContent}
                            className={`d-flex question-card text-center contentCard mx-2 ${selectedCard}`}
                            action={null}
                            isSelected={isSelected}
                            setZoneImage={this.setZoneImage}
                          />
                        )
                      })}
                    </div>
                    {fixedZoneData && fixedZoneData.length > 0 ? (
                      <>
                        <div className="d-flex border-top mt-4 pt-3 pb-2">
                          Fixed Zones
                        </div>
                        <div
                          className="d-flex flex-wrap m-1"
                          style={{ overflowX: "hidden" }}
                        >
                          {fixedZoneData.map(data => {
                            return (
                              <SelectedZoneCard
                                data={data}
                                // _handleSelection={this._handleClick}
                                renderCardContent={this._renderCardContent}
                                className={`d-flex question-card text-center contentCard mx-2 selectedCard`}
                                action={null}
                                // isSelected={isSelected}
                                setZoneImage={this.setZoneImage}
                                actionDisabled={true}
                              />
                            )
                          })}
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </PerfectScrollbar>
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderZones()}
      </div>
    )
  }
}
function mapStateToProps(store) {
  const zoneList =
    store.zone?.zoneList?.filter(zone => zone.status !== "DISABLED") || []
  return {
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "content.loading"),
    currentData: _.get(store, "taxonomy.zone"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
    zoneList,
    allZonesList: _.get(store, "zone.allZoneList"),
  }
}

export default connect(mapStateToProps)(ZoneVerification)
