import React from "react"
import { connect } from "react-redux"
import Questionnaire from "./questionnaire"
import QuestionCard from "./questionCard"

import PerfectScrollbar from "react-perfect-scrollbar"
class OrthogonalAttribute extends Questionnaire {
  state = {
    currentTab: "orthogonalAttribute",
    labelToDisplay: "Orthogonal Attribute",
  }

  _renderQuestions = () => {
    let data = [...this.props.currentData]
    // data = data.filter(item => item.isVisible)
    // data = data.map(item => {
    //   return {
    //     ...item,
    //     selected: item.selected
    //       ? item.selected
    //       : this.props.selectedDetails.orthogonal_attributes[item.key]
    //       ? this.props.selectedDetails.orthogonal_attributes[item.key]
    //       : false,
    //     partialSelected: item.partialSelected
    //       ? item.partialSelected
    //       : this.props.selectedDetails.orthogonal_attributes[item.key]
    //       ? this.props.selectedDetails.orthogonal_attributes[item.key]
    //       : false,
    //   }
    // })

    return (
      <PerfectScrollbar>
        <div className="d-flex flex-wrap" style={{ margin: "1rem" }}>
          {data.map((question, idx) => {
            return (
              <QuestionCard
                question={question}
                _handleSelection={e => this._handleSelection(e, idx)}
                renderCardContent={this.props.renderCardContent}
              />
            )
          })}
        </div>
      </PerfectScrollbar>
    )
  }

  render() {
    return (
      <div
        className="middle-container"
        style={{ overflowY: "hidden", borderRight: "2px solid whitesmoke" }}
      >
        {this._renderStepper()}
        {this._renderQuestions()}
      </div>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    currentData: _.get(store, "taxonomy.orthogonalAttribute"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
  }
}

export default connect(mapStateToProps)(OrthogonalAttribute)
