import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { Card, CardBody, Row, Col } from "reactstrap"
import { Divider } from "antd"
import { getFaqData, updateFaq } from "clientServices/creatorPayoutService"
import toastr from "toastr"
import { Select } from "antd"
import _ from "lodash"
import { Button } from "@mui/material"
import { LANGUAGES } from "constants/uiConstants"

const { Option } = Select

const initialData = {
  faq: [
    {
      question: "",
      answer: "",
    },
  ],
  please_note: "",
  bonus: {
    description: "",
    table_data: "",
  },
  promo_card: {
    title: "",
    sub_title: "",
    description: "",
  },
  main_program_page: "",
}

export default class manageFAQ extends ErrorBoundComponent {
  state = {
    formData: {
      ...initialData,
    },
    selectedLang: "en",
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    })

    let { data } = await getFaqData()

    if (data) {
      this.setState({
        formData: {
          ...data,
        },
        loading: false,
      })
    }
  }

  _handleFaqsChange = (field, value, index) => {
    let faq = [...this.state.formData.faq]
    faq[index] = {
      ...faq[index],
      [field]: value,
    }
    this.setState({
      formData: {
        ...this.state.formData,
        faq,
      },
    })
  }

  _handleFieldChange = (field, value, parent) => {
    if (parent) {
      if (field === "table_data") {
        try {
          value = JSON.parse(value)
        } catch (error) {
          toastr.remove()
          toastr.error("Please provide valid JSON")
          return
        }
      }
      this.setState({
        formData: {
          ...this.state.formData,
          [parent]: {
            ...this.state.formData[parent],
            [field]: value,
          },
        },
      })
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [field]: value,
        },
      })
    }
  }

  _handleLanguageSelection = async value => {
    this.setState({
      loading: true,
    })

    let { data } = await getFaqData()
    if (data) {
      let formData = {
        ...data,
      }
      if (value !== "en") {
        formData = {
          ...formData,
          ...initialData,
          ...data[value],
        }
      }
      this.setState({
        selectedLang: value,
        formData,
        loading: false,
      })
    }
  }

  _upsertFaq = async () => {
    try {
      this.setState({
        loading: true,
      })
      let { data } = await getFaqData()
      if (this.state.selectedLang !== "en") {
        data = {
          ...data,
          [this.state.selectedLang]: {
            ..._.pick(
              this.state.formData,
              "faq",
              "bonus",
              "please_note",
              "promo_card",
              "main_program_page"
            ),
          },
        }
      } else {
        data = {
          ...data,
          ..._.pick(
            this.state.formData,
            "faq",
            "bonus",
            "please_note",
            "promo_card",
            "main_program_page"
          ),
        }
      }
      await updateFaq(JSON.stringify(data))
      toastr.remove()
      toastr.success("Action successful !!")
    } catch (error) {}
    this.setState({
      loading: false,
    })
  }

  render() {
    return (
      <Card>
        <CardBody>
          <div className="d-flex justify-content-end">
            <Button
              color="success"
              variant="contained"
              onClick={this._upsertFaq}
              disabled={this.state.loading}
            >
              Update
            </Button>
          </div>
          <Col lg="2" md="4">
            <Divider>
              <h5 className="font-size-11 mb-0 text-muted">Language</h5>
            </Divider>
            <Select
              label={"Language"}
              placeholder="Language"
              value={this.state.selectedLang}
              onChange={this._handleLanguageSelection}
              style={{ width: "100%" }}
            >
              {LANGUAGES.map(lang => (
                <Option value={lang.value}>{lang.label}</Option>
              ))}
            </Select>
          </Col>
          <Divider>
            <h5 className="mb-0">FAQ</h5>
          </Divider>
          {this.state.formData.faq.map((faq, index) => {
            return (
              <Row className="mb-3 d-flex align-items-center" key={index}>
                {/* <Col></Col> */}
                <Col lg="5">
                  <div className="d-flex align-items-center">
                    <span className="me-2 font-weight-semibold">
                      #{index + 1}
                    </span>
                    <textarea
                      className="form-control"
                      rows="1"
                      placeholder="Please provide question"
                      onChange={e =>
                        this._handleFaqsChange(
                          "question",
                          e.target.value,
                          index
                        )
                      }
                      value={faq.question}
                    />
                  </div>
                </Col>
                <Col lg="7">
                  <div>
                    <textarea
                      className="form-control"
                      rows="1"
                      placeholder="Please provide answer"
                      onChange={e =>
                        this._handleFaqsChange("answer", e.target.value, index)
                      }
                      value={faq.answer}
                    />
                  </div>
                </Col>
              </Row>
            )
          })}
          <div className="d-flex justify-content-center">
            <Button
              variant="contained"
              onClick={() =>
                this.setState({
                  formData: {
                    ...this.state.formData,
                    faq: [
                      ...this.state.formData.faq,
                      { question: "", answer: "" },
                    ],
                  },
                })
              }
            >
              Add Question
            </Button>
          </div>
          <Divider>
            <h5 className="mb-0">Please Note</h5>
          </Divider>
          <Row>
            <Col>
              <div>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Please provide HTML"
                  onChange={e =>
                    this._handleFieldChange("please_note", e.target.value)
                  }
                  value={this.state.formData.please_note}
                />
              </div>
            </Col>
          </Row>
          <Divider>
            <h5 className="mb-0">Bonus</h5>
          </Divider>
          <Row>
            <Col lg="6">
              <label>Description</label>
              <div>
                <textarea
                  className="form-control"
                  rows="4"
                  placeholder="Please provide description"
                  onChange={e =>
                    this._handleFieldChange(
                      "description",
                      e.target.value,
                      "bonus"
                    )
                  }
                  value={this.state.formData.bonus.description}
                />
              </div>
            </Col>
            <Col lg="6">
              <label>Table Data</label>
              <div>
                <textarea
                  className="form-control"
                  rows="4"
                  placeholder="Please provide table data in JSON format"
                  onChange={e =>
                    this._handleFieldChange(
                      "table_data",
                      e.target.value,
                      "bonus"
                    )
                  }
                  value={JSON.stringify(this.state.formData.bonus.table_data)}
                />
              </div>
            </Col>
          </Row>
          <Divider>
            <h5 className="mb-0">Promo Card</h5>
          </Divider>
          <Row>
            <Col lg="6">
              <label>Title</label>
              <div>
                <textarea
                  className="form-control"
                  rows="1"
                  placeholder="Please provide title"
                  onChange={e =>
                    this._handleFieldChange(
                      "title",
                      e.target.value,
                      "promo_card"
                    )
                  }
                  value={this.state.formData.promo_card.title}
                />
              </div>
            </Col>
            <Col lg="6">
              <label>Sub Title</label>
              <div>
                <textarea
                  className="form-control"
                  rows="1"
                  placeholder="Please provide sub title"
                  onChange={e =>
                    this._handleFieldChange(
                      "sub_title",
                      e.target.value,
                      "promo_card"
                    )
                  }
                  value={this.state.formData.promo_card.sub_title}
                />
              </div>
            </Col>
            <Col lg="6" className="mt-2">
              <label>Description</label>
              <div>
                <textarea
                  className="form-control"
                  rows="1"
                  placeholder="Please provide description"
                  onChange={e =>
                    this._handleFieldChange(
                      "description",
                      e.target.value,
                      "promo_card"
                    )
                  }
                  value={this.state.formData.promo_card.description}
                />
              </div>
            </Col>
          </Row>
          <Divider>
            <h5 className="mb-0">Main Program Page</h5>
          </Divider>
          <Row>
            <Col>
              <div>
                <textarea
                  className="form-control"
                  rows="5"
                  placeholder="Please provide HTML"
                  onChange={e =>
                    this._handleFieldChange("main_program_page", e.target.value)
                  }
                  value={this.state.formData.main_program_page}
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button
              color="success"
              variant="contained"
              onClick={this._upsertFaq}
              disabled={this.state.loading}
            >
              Update
            </Button>
          </div>
        </CardBody>
      </Card>
    )
  }
}
