import React from "react"
import { Button, Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { EditOutlined, PlusOutlined } from "@ant-design/icons"
import ValidatorForm, { Select, TextField, Switch } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"

class UpsertStickersTabForm extends ErrorBoundComponent {
  state = {
    formData: {
      type: null,
    },
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value
    this.setState({ formData })
  }

  _form = () => {
    const { stickersFor } = this.props
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col sm={12} lg={6}>
            <TextField
              label={"Display Name"}
              placeholderLabel="Enter the Name"
              field="displayName"
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "stickerAsset.displayName")
                  : ""
              }
              className="creator-form"
              validations={["required"]}
              errors={["This field is required"]}
              onChange={value => this._handleFieldChange("displayName", value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <Select
              label="Tab Type"
              placeholderLabel="Tab Type"
              disabled={this.props.formAction === "update"}
              field="tabType"
              validations={["required"]}
              errors={["This field is required"]}
              className="creator-form"
              onChange={value => this._handleFieldChange("tabType", value)}
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "stickerAsset.tabType")
                  : null
              }
              options={
                stickersFor === "CAMERA"
                  ? [
                      { label: "Local", value: "LOCAL" },
                      { label: "Remote", value: "REMOTE" },
                    ]
                  : [
                      { label: "Local", value: "LOCAL" },
                      { label: "Remote", value: "REMOTE" },
                      { label: "External", value: "EXTERNAL" },
                    ]
              }
            />
          </Col>
          <Col sm={12} lg={6}>
            <Switch
              disabled={this.props.formAction === "update"}
              label="Is Language Specific"
              field="isLangSpecific"
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "stickerAsset.isLangSpecific")
                  : true
              }
              onChange={value =>
                this._handleFieldChange("isLangSpecific", value)
              }
            />
          </Col>
          <Col sm={12} lg={6}>
            <Switch
              label="Is Active"
              field="isActive"
              defaultValue={
                this.props.formAction === "update"
                  ? _.get(this.props, "stickerAsset.isActive")
                  : true
              }
              onChange={value => this._handleFieldChange("isActive", value)}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                color="primary"
                size="default"
                disabled={this.state.loading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
              >
                {this.props.formAction === "update" ? "Update" : "Create"}
              </Button>
              <Button
                color="danger"
                size="default"
                disabled={this.state.actionLoading}
                onDoubleClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }}
                onClick={() => this.props.onCancel()}
                className="mx-2"
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    this.props.onSubmit(formData)
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  return {
    loading: _.get(store, "stickers.loading"),
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data.jotUserType"),
  }
}

export default connect(mapStateToProps)(UpsertStickersTabForm)
