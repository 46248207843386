import React, { useState } from "react"
import Select from "react-select"
import Switch from "react-switch"
import _ from "lodash"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Tooltip,
} from "reactstrap"
import ValidatorForm, { ChipsInput } from "../ValidatorForm"
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation"
import { PERMISSIONS_ARRAY } from "../../constants/uiConstants"
import { Offsymbol, OnSymbol } from "util/switchButtonUtil"
import CustomSelect from "../../components/CustomSelect/customSelect"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import StateManager from "react-select"

const UpsertUser = props => {
  const userStatus = props.data && props.data.userStatus === 0 ? false : true
  const [agency, setAgency] = useState(
    (props.selectedUser && props.selectedUser.agency) || null
  )
  const [name, setName] = useState(
    props.selectedUser && props.selectedUser.name
      ? props.selectedUser.name
      : null
  )
  const [email, setEmail] = useState(
    props.selectedUser && props.selectedUser.email
      ? props.selectedUser.email
      : null
  )
  const [password, setPassword] = useState(
    props.selectedUser && props.selectedUser.password
      ? props.selectedUser.password
      : null
  )
  const [switch1, setSwitch] = useState(userStatus)
  const [permissions, setPermissions] = useState(
    (props.selectedUser && props.selectedUser.permissions) || null
  )
  const [jotUserType, setJotUserType] = useState(
    props.action === "edit" ? props.selectedUser.jotUserType : null
  )
  const [usersList, setUsersList] = useState(
    props.selectedUser &&
      props.selectedUser.community &&
      props.selectedUser.community.usersList
      ? props.selectedUser.community.usersList
      : []
  )
  const [lead, setLead] = useState(
    [12, 32].includes(props.currentUser.jotUserType)
      ? props.currentUser.userId
      : props.selectedUser && props.selectedUser.community
      ? props.selectedUser.community.leadId
      : null
  )
  const [clicked, setClicked] = useState(false)
  const [showSearchAndSelect, setShowSearchAndSelect] = useState(false)

  const userOptions = [
    { label: "Community Head", value: 11 },
    { label: "Community Lead", value: 12 },
    { label: "Community Account Manager", value: 13 },
    { label: "Josh Manager", value: 33 },
  ]

  const permissionArray = [
    ...PERMISSIONS_ARRAY,
    {
      label: "Tag Content Admin",
      value: "TAG_CONTENT_ADMIN",
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "Community Manager",
      value: "COMMUNITY_MANAGER",
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "View Real Stats",
      value: "VIEW_REAL_STATS",
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "Manage CMS Users",
      value: "MANAGE_CMS_USERS",
      disabled: props.currentUser.jotUserType > 1,
    },
  ]

  const _onSubmit = formData => {
    if (props.action === "assign") {
      props.onSubmit(lead)
    } else {
      formData.creatorId = _.get(props, "data.userId")
        ? _.get(props, "data.userId")
        : ""

      formData.userStatus = switch1 ? 1 : 0
      formData.name = name
      formData.email = email
      formData.password = password
      formData.permissions = !_.isEmpty(permissions)
        ? JSON.stringify(permissions)
        : null
      formData.agency = agency ? agency.value : null
      formData.jotUserType = jotUserType
      formData.community =
        props.formAction === "edit"
          ? `{ "leadId": ${lead}, "usersList":${usersList} }`
          : JSON.stringify({ leadId: lead, usersList })
      var data = _.omitBy(formData, _.isNil)
      props.onSubmit(data)
    }
  }

  const _setPermissions = val => {
    const perm = val.map(element => {
      return element.value
    })
    setPermissions(perm)
  }

  const _getElement = (array, val) => {
    let element
    array.forEach(option => {
      if (option.value === val) {
        element = option
      }
    })
    return element
  }
  const _getPermissions = perms => {
    let array = []
    permissionArray.forEach(p => {
      if (perms.includes(p.value)) {
        array.push(p)
      }
    })
    return array
  }

  if (props.action === "assign") {
    return (
      <Col lg="5">
        <Card style={{ height: "80vh" }}>
          <CardBody>
            <AvForm onValidSubmit={(e, values) => _onSubmit(values)}>
              <div className="font-weight-semibold d-flex justify-content-between mb-2">
                Assign Community User{" "}
                <div className="d-flex justify-content-end">
                  <Button type="submit" color="primary" className="mx-2">
                    {props.action === "create" ? "Create" : "Update"}
                  </Button>
                  <Button color="danger" onClick={props.oncancel} className="">
                    Cancel
                  </Button>
                </div>
              </div>
              <FormGroup className="mb-4" row>
                <Label htmlFor="name" className="col-form-label col-lg-2">
                  Assign To
                </Label>
                <Col lg="10">
                  <Select
                    isMulti={false}
                    options={
                      props.leadsData &&
                      props.leadsData.length > 0 &&
                      props.leadsData.map(lead => {
                        return { label: lead.email, value: lead.userId }
                      })
                    }
                    onChange={val => setLead(val.value)}
                  />
                </Col>
              </FormGroup>
            </AvForm>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                color="primary"
                className="mx-2"
                onClick={() => _onSubmit()}
              >
                Update
              </Button>
              <Button color="danger" onClick={props.oncancel}>
                Cancel
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }

  const getDefaultLead = () => {
    const defaultLead =
      props.leadsData &&
      props.leadsData.length > 0 &&
      props.leadsData
        .filter(leadData => leadData.userId == lead)
        .map(leadData => {
          return { label: leadData.email, value: leadData.userId }
        })
    if (defaultLead && defaultLead.length === 1) {
      return defaultLead[0]
    } else return null
  }

  const _getOptions = options => {
    return options.filter(option => !option.disabled)
  }

  if (showSearchAndSelect) {
    return (
      <SearchAndSelect
        type="CREATOR"
        visible={showSearchAndSelect}
        onClose={() => setShowSearchAndSelect(false)}
        onAdd={arr => setUsersList(arr)}
        selectedArr={_.uniq(usersList)}
      />
    )
  }
  return (
    <React.Fragment>
      <Col lg="5">
        <Card>
          <CardBody>
            <AvForm onValidSubmit={(e, values) => _onSubmit(values)}>
              <div className="font-weight-semibold d-flex justify-content-between mb-2">
                {props.action === "create" ? "Create" : "Update"} Community User{" "}
                <div className="d-flex justify-content-end">
                  <Button type="submit" color="primary" className="mx-2">
                    {props.action === "create" ? "Create" : "Update"}
                  </Button>
                  <Button color="danger" onClick={props.oncancel} className="">
                    Cancel
                  </Button>
                </div>
              </div>

              <FormGroup className="mb-4" row>
                <Label htmlFor="name" className="col-form-label col-lg-2">
                  Full Name
                </Label>
                <Col lg="10">
                  <AvField
                    name="name"
                    type="text"
                    defaultValue={name}
                    onChange={e => setName(e.target.value)}
                    required
                    errorMessage="Enter Name"
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="email" className="col-form-label col-lg-2">
                  Email
                </Label>
                <Col lg="10">
                  <AvField
                    name="email"
                    placeholder="Enter Valid Email"
                    type="email"
                    errorMessage="Invalid Email"
                    validate={{
                      required: { value: true },
                      email: { value: true },
                    }}
                    defaultValue={email}
                    onChange={e => setEmail(e.target.value)}
                    disabled={props.action === "edit"}
                  />
                </Col>
              </FormGroup>

              {props.action === "create" && (
                <FormGroup className="mb-4" row>
                  <Label htmlFor="password" className="col-form-label col-lg-2">
                    Password
                  </Label>
                  <Col lg="10">
                    <AvField
                      name="password"
                      type="password"
                      placeholder="Password"
                      errorMessage="Enter password"
                      autocomplete="off"
                      validate={{
                        required: {
                          value: props.action === "create" ? true : false,
                        },
                      }}
                      defaultValue={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </Col>
                </FormGroup>
              )}
              {props.action === "create" && (
                <FormGroup className="mb-4" row>
                  <Label
                    htmlFor="jotUserType"
                    className="col-form-label col-lg-2"
                  >
                    User Type
                  </Label>
                  <Col lg="10" onClick={() => setClicked(true)}>
                    <CustomSelect
                      isMulti={false}
                      defaultValue={
                        props.data && props.data.jotUserType
                          ? _getElement(userOptions, props.data.jotUserType)
                          : _getElement(userOptions, jotUserType)
                      }
                      options={
                        [12, 32].includes(props.currentUser.jotUserType)
                          ? [
                              { label: "Community Account Manager", value: 13 },
                              { label: "Josh Manager", value: 33 },
                            ]
                          : userOptions
                      }
                      onChange={val => setJotUserType(val.value)}
                      isValid={jotUserType || !clicked}
                      errorMessage="Select User Type"
                    />
                  </Col>
                </FormGroup>
              )}
              <React.Fragment>
                {[13, 33].includes(jotUserType) && (
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="type" className="col-form-label col-lg-2">
                      Lead
                    </Label>
                    <Col lg="10">
                      {![12, 32].includes(props.currentUser.jotUserType) && (
                        <Select
                          isMulti={false}
                          disabled={[12, 32].includes(
                            props.currentUser.jotUserType
                          )}
                          options={[
                            { label: "None", value: null },
                            ...(props.leadsData &&
                              props.leadsData.length > 0 &&
                              props.leadsData.map(lead => {
                                return { label: lead.email, value: lead.userId }
                              })),
                          ]}
                          onChange={val => setLead(val.value)}
                          defaultValue={getDefaultLead()}
                        />
                      )}
                      {[12, 32].includes(props.currentUser.jotUserType) && (
                        <Input
                          field="usersList"
                          onChange={e => setLead(e.target.value)}
                          disabled={true}
                          defaultValue={getDefaultLead().label}
                        />
                      )}
                      {/* <Input
                        field="usersList"
                        onChange={e => setLead(e.target.value)}
                        disabled={props.currentUser.jotUserType === 12}
                        defaultValue={lead}
                      /> */}
                    </Col>
                  </FormGroup>
                )}
                <FormGroup className="mb-4" row>
                  <Label
                    htmlFor="usersList"
                    className="col-form-label col-lg-2"
                  >
                    Users List
                  </Label>
                  <Col lg="10">
                    <ChipsInput
                      field="usersList"
                      onChange={val => setUsersList(val)}
                      defaultValue={usersList}
                      fixedHeight={true}
                    />
                    <Button
                      color="primary"
                      className="float-end"
                      onClick={() => setShowSearchAndSelect(true)}
                    >
                      Select
                    </Button>
                  </Col>
                </FormGroup>
              </React.Fragment>
              <FormGroup className="mb-4" row>
                <Label htmlFor="agency" className="col-form-label col-lg-2">
                  Agency
                </Label>
                <Col lg="10">
                  <Select
                    type="select"
                    name="agency"
                    onChange={val => setAgency(val)}
                    options={[...props.agencyList]
                      .sort((a, b) =>
                        a.constantLabel.localeCompare(b.constantLabel)
                      )
                      .map(lang => {
                        return {
                          label: lang.constantLabel,
                          value: lang.constantVal,
                        }
                      })}
                    defaultValue={
                      props.data &&
                      props.data.agency &&
                      _getElement(props.agencyList, props.data.agency)
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="permissions"
                  className="col-form-label col-lg-2"
                >
                  Permissions
                </Label>
                <Col lg="10">
                  <Select
                    isMulti={true}
                    onChange={val => _setPermissions(val)}
                    defaultValue={
                      props.data &&
                      props.data.permissions &&
                      _getPermissions(props.data.permissions)
                    }
                    options={
                      !agency || (agency && agency.value === "INTERNAL")
                        ? _getOptions(permissionArray)
                        : permissionArray.filter(permission =>
                            [
                              "BROWSE_CONTENT",
                              "TAG_CONTENT",
                              "QC_TAG_CONTENT",
                            ].includes(permission.value)
                          )
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="userStatus" className="col-form-label col-lg-2">
                  Status
                </Label>
                <Col lg="10">
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2"
                    onColor="#626ed4"
                    onChange={() => setSwitch(!switch1)}
                    checked={switch1}
                  />
                </Col>
              </FormGroup>
              <div className="d-flex justify-content-end">
                <Button type="submit" color="primary" className="mx-2">
                  {props.action === "create" ? "Create" : "Update"}
                </Button>
                <Button color="danger" onClick={props.oncancel}>
                  Cancel
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default UpsertUser
