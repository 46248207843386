import React from "react"
import _ from "lodash"
import { Button } from "@mui/material"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import ValidatorForm, { TextField, Switch } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { upsertSeries } from "clientServices/audioStories"
import ColorPicker from "../../components/ColorPicker/colorPicker"
import SearchAndSelect from "../../components/SearchAndSelect/searchAndSelectV2"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { MdAdd, MdArrowBack } from "react-icons/md"
import "./styles.css"
require("moment-duration-format")

class AudioSeriesForm extends ErrorBoundComponent {
  state = {
    fileList: [],
    isSponsored: false,
    formData: {},
    selectedArr: [],
    currentSeason: 1,
    parentStoriesData: [
      {
        season_number: 1,
        stories: [],
      },
    ],
  }

  async componentDidMount() {
    if (!this.props.common.error && this.props.formAction !== "create") {
      let parentStoriesData =
        this.props.groupedSeriesData[this.props.audioSeries.parent_series_id]
      parentStoriesData.sort((a, b) => a.season_number - b.season_number)
      this.setState({
        parentStoriesData,
      })
    }
  }

  _form = item => {
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          {item.series_id && (
            <Col sm="12" md="6">
              <TextField
                label={"Series Id"}
                placeholderLabel="Series Id"
                field={"series_id"}
                className="creator-form"
                defaultValue={item.series_id}
                disabled={true}
              />
            </Col>
          )}
          {item.parent_series_id && (
            <Col sm="12" md="6">
              <TextField
                label={"Parent Series Id"}
                placeholderLabel="Parent Series Id"
                field={"parent_series_id"}
                className="creator-form"
                defaultValue={item.parent_series_id}
                disabled={true}
              />
            </Col>
          )}
          {item.season_number && (
            <Col sm="12" md="6">
              <TextField
                label={"Season Number"}
                placeholderLabel="Season Number"
                field={"season_number"}
                className="creator-form"
                defaultValue={item.season_number}
                disabled={true}
              />
            </Col>
          )}
          <Col sm="12" md="6">
            <TextField
              label={"Title"}
              placeholderLabel="Title"
              field={"title"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update" ? item.title : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Description"}
              placeholderLabel="Description"
              field={"description"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update" ? item.description : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <Row>
              <Col sm="6" md="9">
                <TextField
                  label="Stories"
                  placeholderLabel="Select stories"
                  field="stories"
                  className="creator-form"
                  defaultValue={item.stories.join(",")}
                  // style={{ width: "95%" }}
                  // validations={["required"]}
                  // errors={["This field is required"]}
                />
              </Col>
              <Col
                sm="6"
                md="3"
                style={{ alignSelf: "flex-end", marginBottom: "9px" }}
              >
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState(
                      {
                        currentSeason: item.season_number,
                      },
                      () => {
                        let selectedArr = []
                        this.state.parentStoriesData.forEach(item => {
                          if (item.season_number === this.state.currentSeason) {
                            selectedArr = item.stories
                          }
                        })
                        this.setState({
                          selectAudioStoryModal: true,
                          selectedArr,
                        })
                      }
                    )
                  }}
                  variant="contained"
                >
                  Select
                </Button>
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Source"}
              placeholderLabel="Source"
              field={"source"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update" ? item.source : ""
              }
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Cover Url"}
              placeholderLabel="Cover Url"
              field={"cover_url"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update" ? item.cover_url : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"CTA Text"}
              placeholderLabel="CTA Text"
              field={"cta_text"}
              className="creator-form"
              defaultValue={item.cta_text || ""}
              // validations={["required"]}
              // errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"CTA Icon"}
              placeholderLabel="CTA Icon"
              field={"cta_icon"}
              className="creator-form"
              defaultValue={item.cta_icon || ""}
              // validations={["required"]}
              // errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <ColorPicker
              label="CTA Text Color"
              placeholderLabel="CTA Text Color"
              field="cta_text_color"
              defaultColor={item.cta_text_color || "#5154b2"}
            />
          </Col>
          <Col sm="12" md="6">
            <ColorPicker
              label="BG Color"
              placeholderLabel="BG Color"
              field="bg_color"
              defaultColor={item.bg_color || "#5154b2"}
            />
          </Col>
          <Col sm="12" md="6">
            <ColorPicker
              label="CTA Icon Color"
              placeholderLabel="CTA Icon Color"
              field="cta_icon_color"
              defaultColor={item.cta_icon_color || "#5154b2"}
            />
          </Col>
          <Col sm={12} md={6}>
            <label>Status</label>
            <Switch
              defaultValue={item.status == "active" ? true : false}
              field={"status"}
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-end pb-2 pt-2">
          <Button
            variant="contained"
            type="submit"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            {this.props.formAction === "create" ? "Create" : "Update"}
          </Button>
        </div>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    try {
      if (errors) {
        return
      }
      formData.stories = formData.stories.split(",")
      formData.status == true
        ? (formData.status = "active")
        : (formData.status = "inactive")

      await upsertSeries(formData)
      this.props.fetchAudioStories()
    } catch (err) {}
  }

  _handleAddSeason = () => {
    const currentData = _.cloneDeep(this.state.parentStoriesData)
    currentData.push({
      season_number: currentData.length + 1,
      stories: [],
      parent_series_id: this.props.audioSeries.parent_series_id,
    })

    this.setState({
      parentStoriesData: currentData,
    })
  }

  _handleSelectStories = arr => {
    let parentStoriesData = [...this.state.parentStoriesData]
    parentStoriesData.forEach(item => {
      if (item.season_number === this.state.currentSeason) {
        item.stories = arr
      }
    })
    this.setState({
      parentStoriesData,
    })
  }

  _render = () => {
    return (
      <>
        <Button
          variant="contained"
          disabled={this.state.actionLoading}
          onDoubleClick={e => {
            e.preventDefault()
            e.stopPropagation()
            return
          }}
          onClick={() => this.props.onCancel()}
          className="my-2"
          startIcon={<MdArrowBack />}
        >
          Back
        </Button>
        {this.state.parentStoriesData.map(season => {
          return (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h5>Season {season.season_number}</h5>
              </AccordionSummary>
              <AccordionDetails>{this._form(season)}</AccordionDetails>
            </Accordion>
          )
        })}
        {this.props.formAction !== "create" && (
          <div className="d-flex justify-content-end mt-2">
            <Button
              startIcon={<MdAdd />}
              variant="contained"
              onClick={this._handleAddSeason}
            >
              Add Season
            </Button>
          </div>
        )}
        {this.state.selectAudioStoryModal && (
          <SearchAndSelect
            type="AUDIO_STORY"
            visible={this.state.selectAudioStoryModal}
            onClose={() => this.setState({ selectAudioStoryModal: false })}
            onAdd={arr => this._handleSelectStories(arr)}
            selectedArr={this.state.selectedArr}
          />
        )}
      </>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(AudioSeriesForm)
