import React from "react"
import { Upload, message, Select as AntSelect } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import _ from "lodash"
import { Button } from "@mui/material"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import ValidatorForm, {
  TextField,
  MultiSelect,
  Switch,
  ChipsInput,
  TextArea,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import ColorPicker from "../../components/ColorPicker/colorPicker"
import "./styles.css"
import moment from "moment"
import { upsertStory } from "clientServices/audioStories"
require("moment-duration-format")

class AudioStoryForm extends ErrorBoundComponent {
  state = {
    fileList: [],
    isSponsored: false,
    formData: {},
  }

  _formatDuration = duration => {
    return moment.duration(duration, "seconds").format("mm:ss", { trim: false })
  }

  _uploadAlbumArt = fileObj => {
    if (fileObj.file) {
      this.setState({
        albumArtFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        albumArt: fileObj.file,
      })
    }
  }

  _uploadAudio = fileObj => {
    if (fileObj.file) {
      this.setState({
        audioFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        audioFile: fileObj.file,
      })
    }
  }

  _onAudioRemove = () => {
    this.setState({
      audioFileList: [],
      audioFile: null,
    })
  }

  _handleMultiSelect = value => {
    let formData = this.state.formData
    formData["playlists_info"] = value
    this.setState({ formData })
  }

  _form = () => {
    const { playList = [] } = this.props
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          {this.props.formAction === "update" && (
            <Col sm="12" md="6">
              <TextField
                label={"Id"}
                placeholderLabel="Id"
                field={"story_id"}
                className="creator-form"
                defaultValue={
                  this.props.formAction === "update"
                    ? this.props.audio.story_id
                    : ""
                }
                disabled={true}
                validations={["required"]}
                errors={["This field is required"]}
              />
            </Col>
          )}
          <Col sm="12" md="6">
            <TextField
              label={"Title"}
              placeholderLabel="Title"
              field={"title"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update" ? this.props.audio.title : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Description"}
              placeholderLabel="Description"
              field={"description"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.description
                  : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Series Id"}
              placeholderLabel="Series Id"
              field={"series_id"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.series_id
                  : ""
              }
              disabled={true}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Story Number"}
              placeholderLabel="Story Number"
              field={"story_number"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.story_number
                  : ""
              }
              disabled={true}
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Source"}
              placeholderLabel="Source"
              field={"source"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.source
                  : ""
              }
            />
          </Col>
          <Col sm="12" md="6">
            <TextField
              label={"Cover Url"}
              placeholderLabel="Cover Url"
              field={"cover_url"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio?.cover_url
                  : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm="12" md="6">
            <TextArea
              label={"Stream Url"}
              placeholderLabel="Stream Url"
              field={"stream_url"}
              className="creator-form"
              defaultValue={
                this.props.formAction === "update"
                  ? this.props.audio.stream_url
                  : ""
              }
              validations={["required"]}
              errors={["This field is required"]}
            />
          </Col>

          <Col sm={12} md={6}>
            <ChipsInput
              label={"Reciters"}
              placeholderLabel="Reciters"
              field={"reciters"}
              defaultValue={this.props.audio?.reciters || []}
              className="creator-form"
            />
          </Col>
          <Col sm="12" md="6">
            <ColorPicker
              label="BG Color"
              placeholderLabel="BG Color"
              field="bg_color"
              defaultColor={this.props.audio?.bg_color || "#5154b2"}
            />
          </Col>
          <Col sm={12} md={6}>
            <label> Status</label>
            <Switch
              defaultValue={this.props.audio.status == "active" ? true : false}
              field={"status"}
              // onChange={e => this._handleChangeStatus(e, row)}
            />
          </Col>
          {/* <Col sm="12" md="6">
            <Row style={{ marginTop: "10px" }}>
              <Upload
                showUploadList={true}
                onRemove={this._onAlbumArtRemove}
                defaultFileList={this.state.albumArtFileList}
                fileList={this.state.albumArtFileList}
                customRequest={this._uploadAlbumArt}
              >
                <Button variant="contained" startIcon={<UploadOutlined />}>
                  Click to upload album art
                </Button>
              </Upload>
            </Row>
          </Col> */}
        </Row>
        {/* {this.props.formAction === "create" && (
          <Row style={{ marginTop: "10px" }}>
            <Upload
              showUploadList={true}
              onRemove={this._onAudioRemove}
              defaultFileList={this.state.audioFileList}
              fileList={this.state.audioFileList}
              customRequest={this._uploadAudio}
            >
              <Button variant="contained" startIcon={<UploadOutlined />}>
                Click to upload audio file
              </Button>
            </Upload>
          </Row>
        )} */}

        <div className="d-flex justify-content-end pb-2 pt-2">
          <Button
            variant="contained"
            type="submit"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
          >
            {this.props.formAction === "create" ? "Create" : "Update"}
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={this.state.actionLoading}
            onDoubleClick={e => {
              e.preventDefault()
              e.stopPropagation()
              return
            }}
            onClick={() => this.props.onCancel()}
            className="mx-2"
          >
            Cancel
          </Button>
        </div>
      </ValidatorForm>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    try {
      if (errors) {
        return
      }
      formData.status == true
        ? (formData.status = "active")
        : (formData.status = "inactive")

      let updateAudioStory = await upsertStory(formData)
      this.props.fetchAudioStories()

      // this.props.onSubmit(data)
    } catch (err) {}
  }

  _render = () => {
    return this._form()
  }
}
function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(AudioStoryForm)
