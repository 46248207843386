import { call, takeLatest, put } from "redux-saga/effects"
import {
  requestGetKyc,
  requestGetPendingKYCCSVData,
  requestUploadKycData,
} from "appRedux/sagas/requests/kyc"
import {
  getKyc,
  getKycSuccess,
  getPendingKYCCSVData,
  getPendingKYCCSVDataSuccess,
  uploadKycData,
  uploadKycDataSuccess,
} from "appRedux/slices/kyc"
import { fetchError } from "appRedux/slices/common"
import toastr from "toastr"

function* handleGetKyc(params) {
  try {
    const data = yield call(requestGetKyc, params)
    yield put(getKycSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleGetPendingKYCCSVData(params) {
  try {
    const data = yield call(requestGetPendingKYCCSVData, params)
    yield put(getPendingKYCCSVDataSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}

function* handleUploadKycData(params) {
  try {
    const data = yield call(requestUploadKycData, params)
    toastr.success("Acction Successful!")
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* watcherSaga() {
  yield takeLatest(getKyc.type, handleGetKyc)
  yield takeLatest(getPendingKYCCSVData.type, handleGetPendingKYCCSVData)
  yield takeLatest(uploadKycData.type, handleUploadKycData)
}
