import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import {
  LANGUAGES,
  TARGET_CATEGORY_V2,
  CATEGORIES,
} from "../../constants/uiConstants"
import "react-datepicker/dist/react-datepicker.css"

const { Option } = Select

const AudioDrawer = props => {
  const realStatPermissionUser =
    // props.currentUser.jotUserType < 3 ||
    props.currentUser.permissions &&
    props.currentUser.permissions.includes("VIEW_REAL_STATS")
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Audio Id</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["audioId"]}
                  placeholder={"Audio Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "audioId")
                  }
                  allowClear={true}
                  name="audioId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Entity Id</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["trendingEntityId"]}
                  placeholder={"Entity Id"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "trendingEntityId")
                  }
                  allowClear={true}
                  name="trendingEntityId"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Hashtag</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["hashtag"]}
                  placeholder={"hashtag"}
                  onChange={e =>
                    props.handleFilterChange(e.target.value, "hashtag")
                  }
                  allowClear={true}
                  name="hashtag"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        <Col lg="3" md="6">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Status</h5>
          </Divider>
          <Select
            showSearch
            allowClear={true}
            placeholder="Status"
            defaultValue={props.filters["status"]}
            onChange={value => {
              props.handleFilterChange(value, "status")
            }}
            style={{ width: "100%" }}
          >
            <Option value="">All</Option>
            <Option value="STARTED">Started</Option>
            <Option value="ENDED">Ended</Option>
          </Select>
        </Col>
      </Row>
    </React.Fragment>
  )
}

AudioDrawer.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(AudioDrawer)
