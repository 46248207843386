import React from "react"
import _ from "lodash"
import { connect } from "react-redux"
import moment from "moment"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Card, CardBody, Row, Col } from "reactstrap"
import { DateTimePicker } from "containers/ValidatorForm"
import { Form, Input, Select, DatePicker } from "antd"
import { createInterest, getInterest } from "clientServices/interestServices"
import dayjs from "dayjs"
import { MdAdd } from "react-icons/md"
import { AiOutlineClose } from "react-icons/ai"
import { Button } from "@mui/material"
import toastr from "toastr"
import Cron from "react-cron-generator"

const regexExp =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
class UpsertInterest extends ErrorBoundComponent {
  state = {
    formData: {
      name: "",
      isActive: true,
      mode: "PUBLIC",
      type: "CATEGORY",
      event_type: "one_time",
      subInterests: [],
      algoName: {},
      action: "create",
      recurring_cron_expression: "",
      subInterestsName: [],
      trendings_since: "today",
      trend_direction: "upwards",
    },
    addsubinterestformtoggle: true,
    new_subinterest: "",

    action: this.props.action,
    errors: {},
    selectedLangs: [],
    menuProps: {
      items: [
        {
          label: "1st menu item",
          key: "1",
        },
        {
          label: "2nd menu item",
          key: "2",
        },
      ],
      onClick: this.handleMenuClick,
    },
  }

  async componentDidMount() {
    const subinterestdata = await getInterest({ entity_type: "sub_interest" })

    let subintereststatus = {}
    for (let j = 0; j < subinterestdata?.result?.data?.length; j++) {
      subintereststatus[subinterestdata?.result?.data[j].id] = {
        status: subinterestdata?.result?.data[j].is_active,
        name: subinterestdata?.result?.data[j].name,
        algo_name: subinterestdata?.result?.data[j].algo_name,
      }
    }

    if (this.props.interestData) {
      const interest = this.props.interestData
      let formData = {
        id: interest.id,
        name: interest.name,
        isActive: interest.isActive,
        mode: interest.mode,
        type: interest.type,
        subInterests: interest.subInterests,
        event_start_date: interest.event_start_date,
        event_end_date: interest.event_end_date,
        event_type: interest.event_type,
        action: "edit",
        trendings_since: interest.trendings_since,
        trend_direction: interest.trend_direction,
      }

      this.setState({
        formData: {
          ...this.state.formData,
          ...formData,
        },
        subinterestdata: subinterestdata?.result?.data,
        subInterestStatusList: subintereststatus || {},
      })
    } else {
      this.setState({
        subinterestdata: subinterestdata?.result?.data,
      })
    }
  }

  _handleFieldChange = (field, value) => {
    console.log(
      "🚀 ~ file: UpsertInterests.js:95 ~ UpsertInterest ~ field:",
      field,
      value
    )
    let formData = this.state.formData
    formData[field] = value

    this.setState(
      {
        formData,
        errors: {
          ...this.state.errors,
          [field]: null,
        },
      },
      () => {
        console.log(this.state.errors)
      }
    )
  }

  _findFormErrors = () => {
    const newErrors = {}
    const {
      name,
      mode,
      type,
      trend_direction,
      trendings_since,
      event_end_date,
      event_start_date,
      event_type,
      recurring_cron_expression,
    } = this.state.formData
    console.log(
      "🚀 ~ file: UpsertInterests.js:110 ~ UpsertInterest ~ this.state.formData:",
      this.state.formData
    )
    if (!name || name === "") newErrors.name = "This field is required"
    if (!mode || mode === "") newErrors.mode = "This field is required"
    if (!type || type === "") newErrors.type = "This field is required"
    if (type === "TREND") {
      if (!trend_direction || trend_direction === "")
        newErrors.trend_direction = "This field is required"
      if (!trendings_since || trendings_since === "")
        newErrors.trendings_since = "This field is required"
    }
    if (type === "EVENT") {
      if (!event_end_date || event_end_date === "")
        newErrors.event_end_date = "This field is required"
      if (!event_start_date || event_start_date === "")
        newErrors.event_start_date = "This field is required"
      if (!event_type || event_type === "")
        newErrors.event_type = "This field is required"
      if (
        event_type == "recurring" &&
        (!recurring_cron_expression || recurring_cron_expression === "")
      )
        newErrors.recurring_cron_expression = "This field is required"
    }
    return newErrors
  }

  _handleNotFieldChange = (field, value) => {
    this.setState({ [field]: value })
  }

  _onSubmit = async () => {
    // if (!this.props.currentUser?.permissions?.includes("EDIT_MANAGE_IMAGE")) {
    //   toastr.error(NO_PERMISSION_MESSAGE)
    //   return
    // }

    const errors = this._findFormErrors()
    // console.log("🚀 ~ file: UpsertInterests.js:143 ~ UpsertInterest ~ _onSubmit= ~ errors:", errors)
    // return
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      })
      return
    }

    let formData = { ...this.state.formData }

    if (formData.subInterests.length == 0) {
      toastr.error("Enter at least one sub-interest")
      return
    }

    // console.log("🚀 ~ file: UpsertInterests.js:155 ~ UpsertInterest ~ _onSubmit= ~ formData:", formData)
    // return

    if (formData.name.length == 0) {
      toastr.error("please enter name")
      return
    }
    if (this.props.action !== "edit") {
      const interestdata = await getInterest({
        mode: formData.mode,
        sort: "desc",
        entity_type: "interest",
        type: formData.type,
      })
      let duplicateFlag = false
      for (let i = 0; i < interestdata?.result?.data?.length > 0; i++) {
        if (
          interestdata?.result?.data[i].name.toLowerCase() ==
          formData.name.toLowerCase()
        ) {
          duplicateFlag = true
          break
        }
      }
      if (duplicateFlag) {
        toastr.error("Same namae Interest already exist")
        return
      }
    }
    if (formData.type != "EVENT") {
      delete formData.event_start_date
      delete formData.event_end_date
      delete formData.event_type
      delete formData.recurring_cron_expression
    }
    if (formData.type != "TREND") {
      delete formData.trendings_since
      delete formData.trend_direction
    }

    await createInterest(formData)
    this.props.toggleAddInterest()
  }

  handleButtonClick = e => {
    message.info("Click on left button.")
    console.log("click left button", e)
  }
  handleMenuClick = e => {
    message.info("Click on menu item.")
    console.log("click", e)
  }
  removeSubinterest = (i, data) => {
    let formData = this.state.formData
    let subInterest = this.state.formData.subInterests
    let subInterestName = this.state.formData.subInterestsName
    let algoName = this.state.formData.algoName
    subInterestName.splice(subInterest.length - i - 1, 1)
    subInterest.splice(i, 1)
    delete algoName[data]
    this.setState({
      formData: {
        ...formData,
        subInterestsName: subInterestName,
        subInterests: subInterest,
        algoName: algoName,
      },
    })
  }
  _renderUpsertForm = () => {
    return (
      <Card>
        <CardBody>
          <Row style={{ marginBottom: "5px" }}>
            <Col lg="12">
              <h4>
                <b> Add Interest</b>
              </h4>
            </Col>
          </Row>

          {this.state.showCronStart && (
            <Card>
              <div>
                <div className="d-flex justify-content-center pt-3">
                  <Cron
                    onChange={e => {
                      this.setState({
                        cronpopUpval: e,
                      })
                    }}
                    value={this.state.formData.recurring_cron_expression}
                    showResultText={true}
                    showResultCron={true}
                  />
                </div>
                <div className="d-flex justify-content-end my-3 mx-3">
                  <div className="px-2">
                    <Button
                      color="success"
                      variant="outlined"
                      onClick={() => {
                        this._handleFieldChange(
                          "recurring_cron_expression",
                          this.state.cronpopUpval
                        )
                        this.setState({ showCronStart: false })
                      }}
                      size="large"
                    >
                      Done
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="error"
                      variant="outlined"
                      onClick={() => {
                        this._handleFieldChange(
                          "recurring_cron_expression",
                          undefined
                        )
                        this.setState({
                          showCronStart: false,
                          startCronValue: undefined,
                        })
                      }}
                      size="large"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          )}
          {!this.state.showCronStart && (
            <Form layout="vertical" autoComplete="false">
              <Row className="mb-3">
                <Col lg="12">
                  <Form.Item
                    label="Name"
                    validateStatus={this.state.errors?.name && "error"}
                  >
                    <Input
                      type="text"
                      placeholder="Add Interest name.."
                      onChange={e =>
                        this._handleFieldChange("name", e.target.value)
                      }
                      value={this.state.formData.name}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg="2">
                  <Form.Item label="Interest Type">
                    <Select
                      type="text"
                      size="large"
                      value={this.state.formData.type}
                      onChange={value => this._handleFieldChange("type", value)}
                    >
                      <Select.Option value="CATEGORY">Category</Select.Option>
                      <Select.Option value="TREND">Trend</Select.Option>
                      <Select.Option value="EVENT">Event</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg="2">
                  <Form.Item label="Mode">
                    <Select
                      size="large"
                      type="text"
                      value={this.state.formData.mode}
                      onChange={value => this._handleFieldChange("mode", value)}
                    >
                      <Select.Option value="PUBLIC">Public</Select.Option>
                      <Select.Option value="PRIVATE">Private</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {this.state.formData.type == "EVENT" && (
                  <>
                    <Col lg="2">
                      <Form.Item label="Event Type">
                        <Select
                          type="text"
                          value={this.state.formData.event_type}
                          onChange={value =>
                            this._handleFieldChange("event_type", value)
                          }
                          size="large"
                        >
                          <Select.Option value="one_time">
                            One-Time
                          </Select.Option>
                          <Select.Option value="seasonal">
                            Seasonal
                          </Select.Option>
                          <Select.Option value="recurring">
                            Recurring
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {this.state.formData.event_type != "recurring" && (
                      <Col lg="2">
                        <Form.Item label="Start Date">
                          <DatePicker
                            style={
                              this.state.errors.event_start_date
                                ? {
                                    border: "1px solid red",
                                    width: "100%",
                                    height: "55%",
                                  }
                                : { width: "100%", height: "55%" }
                            }
                            defaultValue={
                              this.state?.formData?.event_start_date
                                ? dayjs(this.state.formData?.event_start_date)
                                : null
                            }
                            format="YYYY-MM-DD HH:mm"
                            showTime
                            placeholder="Start Date"
                            onChange={(date, dateString) => {
                              this._handleFieldChange(
                                "event_start_date",
                                !_.isEmpty(dateString)
                                  ? moment(dateString).format(
                                      "YYYY-MM-DDTHH:mm:ss"
                                    )
                                  : null
                              )
                            }}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {this.state.formData.event_type != "recurring" && (
                      <Col lg="2">
                        <Form.Item label="End Date">
                          <DatePicker
                            style={
                              this.state.errors.event_end_date
                                ? {
                                    border: "1px solid red",
                                    width: "100%",
                                    height: "55%",
                                  }
                                : { width: "100%", height: "55%" }
                            }
                            defaultValue={
                              this.state?.formData?.event_end_date
                                ? dayjs(this.state?.formData?.event_end_date)
                                : null
                            }
                            format="YYYY-MM-DD HH:mm"
                            showTime
                            placeholder="End Date"
                            onChange={(date, dateString) => {
                              this._handleFieldChange(
                                "event_end_date",
                                !_.isEmpty(dateString)
                                  ? moment(dateString).format(
                                      "YYYY-MM-DDTHH:mm:ss"
                                    )
                                  : null
                              )
                            }}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {this.state.formData.event_type == "recurring" && (
                      <Col lg="2">
                        <Form.Item
                          label="Cron Expression"
                          validateStatus={
                            this.state.errors?.recurring_cron_expression &&
                            "error"
                          }
                        >
                          <Input
                            type="text"
                            placeholder="Add Cron Exp.."
                            onClick={() =>
                              this.setState({ showCronStart: true })
                            }
                            value={
                              this.state.formData.recurring_cron_expression
                            }
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )}
                {this.state.formData.type == "TREND" && (
                  <>
                    <Col lg="2">
                      <Form.Item label="Trending Since">
                        <Select
                          value={this.state.formData.trendings_since}
                          onChange={value =>
                            this._handleFieldChange("trendings_since", value)
                          }
                          size="large"
                        >
                          <Select.Option value="today">Today</Select.Option>
                          <Select.Option value="1_day_ago">
                            1 Day Ago
                          </Select.Option>
                          <Select.Option value="less_than_a_week">
                            Less than a week
                          </Select.Option>
                          <Select.Option value="more_than_a_week">
                            More than a week
                          </Select.Option>
                          <Select.Option value="more_than_a_month">
                            More than a month
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg="2">
                      <Form.Item label="Trend Direction">
                        <Select
                          type="text"
                          value={this.state.formData.trend_direction}
                          onChange={e =>
                            this._handleFieldChange("trend_direction", e)
                          }
                          size="large"
                        >
                          <Select.Option value="upwards">Upwards</Select.Option>
                          <Select.Option value="downwords">
                            Downwards
                          </Select.Option>
                          <Select.Option value="dead">Dead</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
              <br />
              <Row>
                <Row>
                  <Col lg="12" md="12">
                    <b>Sub-Interests</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ul>
                      {this.state.formData.subInterests.map((data, i) => (
                        <li
                          style={{
                            borderBottom: "1px solid #d9d9d9",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "46px",
                            paddingLeft: "10px",
                          }}
                        >
                          {this.state.subInterestStatusList &&
                          this.state.subInterestStatusList[data]
                            ? this.state.subInterestStatusList[data].name
                            : data}{" "}
                          -{" "}
                          {this.state.subInterestStatusList &&
                          this.state.subInterestStatusList[data]
                            ? this.state.subInterestStatusList[data].algo_name
                            : this.state.formData.algoName
                            ? this.state.formData.algoName[data]
                            : data}
                          {/* {'2'+regexExp.test(data)} */}
                          {regexExp.test(data) ? (
                            ""
                          ) : (
                            <AiOutlineClose
                              onClick={() => this.removeSubinterest(i, data)}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
                {this.state.addsubinterestformtoggle && (
                  <Row>
                    <Col lg="5">
                      <Input
                        type="text"
                        style={
                          this.state.addError ? { border: "1px solid red" } : {}
                        }
                        placeholder="Add Sub-Interest.."
                        onChange={e =>
                          this._handleNotFieldChange(
                            "new_subinterest",
                            e.target.value
                          )
                        }
                        defaultValue={this.state.new_subinterest}
                        size="large"
                      />
                    </Col>
                    <Col lg="5">
                      <Input
                        type="text"
                        style={
                          this.state.addError ? { border: "1px solid red" } : {}
                        }
                        placeholder="Add Algo Name.."
                        onChange={e =>
                          this._handleNotFieldChange(
                            "algo_name",
                            e.target.value
                          )
                        }
                        defaultValue={this.state.algo_name}
                        size="large"
                      />
                    </Col>
                    <Col lg="2">
                      <Button
                        color="success"
                        variant="outlined"
                        startIcon={<MdAdd />}
                        onClick={async () => {
                          if (this.state.new_subinterest == "") {
                            return
                          }
                          let flag = true
                          for (
                            let i = 0;
                            i < this.state.subinterestdata?.length;
                            i++
                          ) {
                            if (
                              this.state.subinterestdata[i].name ==
                              this.state.new_subinterest
                            ) {
                              flag = false
                              break
                            }
                          }

                          if (flag) {
                            let a = this.state.formData.subInterestsName
                            let b = this.state.formData.subInterests
                            let c = this.state.formData.algoName
                            b.push(this.state.new_subinterest)
                            a.push(this.state.new_subinterest)
                            c[this.state.new_subinterest] = this.state.algo_name
                            let formData = this.state.formData
                            this.setState({
                              formData: {
                                ...formData,
                                subInterestsName: a,
                                subInterests: b,
                                algoName: c,
                              },
                              addsubinterestformtoggle: false,
                              new_subinterest: "",
                              addError: "",
                            })
                          } else {
                            this.setState({
                              addError: "SubInterest Already Exist",
                            })
                          }
                        }}
                        size="large"
                        style={{
                          width: "100%",
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                    {this.state.addError && (
                      <div style={{ color: "red" }}>{this.state.addError}</div>
                    )}
                  </Row>
                )}
                <br />
                <Row style={{ marginTop: "10px" }}>
                  <Col lg="4">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        this.setState({
                          addsubinterestformtoggle: true,
                        })
                      }}
                      startIcon={<MdAdd />}
                      size="large"
                    >
                      Add New
                    </Button>
                  </Col>
                </Row>
              </Row>

              <Row>
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    color="success"
                    variant="contained"
                    onClick={this._onSubmit}
                    style={{
                      width: "90%",
                    }}
                    role="button"
                    size="large"
                  >
                    {this.props.action === "edit" ? `Update` : `Create`}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                    style={{
                      width: "10%",
                    }}
                    onClick={() => this.props.toggleAddInterest()}
                    className="mx-2"
                    size="large"
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </Form>
          )}
        </CardBody>
      </Card>
    )
  }

  _render = () => {
    return (
      <Auxiliary loading={this.props.loading}>
        {this._renderUpsertForm()}
      </Auxiliary>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    zoneList: _.get(store, "zone.zoneList"),
    allZoneList: _.get(store, "zone.allZoneList"),
    totalPageEntities: _.get(store, "zeroSearch.totalPageEntities"),
    languageList: _.get(store, "uiConstants.languageList"),
    pageEntities: _.get(store, "zeroSearch.pageEntities"),
    locationList: _.get(store, "locationReducer.locationList"),
  }
}

export default connect(mapStateToProps)(UpsertInterest)
