import Axios from "axios"
import toastr from "toastr"
import QueryString from "query-string"

export const requestThemesData = async ({ payload }) => {
  try {
    const data = await Axios.get(
      `/api/icons/getAllThemeData?deviceType=${payload.selectedFilter}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestIconIds = async () => {
  try {
    const data = await Axios.get(`/api/icons/getAppIconIds`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateIcon = async payload => {
  try {
    const data = await Axios.post(
      `/api/icons/updateIcons?${QueryString.stringify(payload.query)}`,
      payload.formData
    )
    return data
  } catch (error) {
    throw error
  }
}
