import {
  AlignLeftOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  ShareAltOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { Button, Carousel, message, Modal, Popover, Tag, Tooltip } from "antd"
import { Button as MuiButton } from "@mui/material"
import {
  resetTaxonomyState,
  setTaxonomyData,
  setTaxonomyRoutes,
  resetInitialStateOnly,
} from "appRedux/slices/taxonomy"
import { getSourceDetails } from "clientServices/sourceService"
import _ from "lodash"
import moment from "moment"
import QueryString from "query-string"
import React, { createRef } from "react"
import Timer from "react-compound-timer"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { connect } from "react-redux"
import ReactTimeAgo from "react-time-ago"
import { Badge, Col, Row } from "reactstrap"
import toastr from "toastr"
import { getZones } from "../../appRedux/slices/zone"
import {
  activateDeactivateContent,
  getAdsStatus,
  markQcTaskAsCompleted,
  markTaskAsCompleted,
  sendNotifContentDeactivationAlert,
  tagContentTaxonomy,
  updateContentZones,
} from "../../clientServices/contentService"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { emailListForNotifWorthyDeactivation } from "../../constants/uiConstants"
import {
  buildActivityLog,
  clearStageWiseData,
  parseESPayload,
  transformToESPayload,
} from "../../util/taxonomy"
import TaxonomyQuestions from "../TaxonomyDashboard/TaxonomyQuestions"
import TaxonomySideBar from "../TaxonomyDashboard/TaxonomySideBar"
import EmbedSocialContainer from "./embedContainer"
import SelectedDataSection from "./selectedDataSection"
import SkipModel from "./skipModel"
import "./TaxonomyDashboard.css"
import { taxonomyRoutes as defaultTaxonomyRoutes } from "./TaxonomyRoutes"
import WarningMessage from "./warningPopup"

const INDEX_TO_HASH_MAPPING = {
  0: "#ff8080",
  1: "#ff4d4d",
  2: "#ff3333",
  3: "#ff0000",
  4: "#cc0000",
  5: "#990000",
}

const initialState = {
  tabs: {
    GENRE: false,
    ORTHOGONAL_QUALITY: false,
    ORTHOGONAL_ATTRIBUTE: false,
    EMOTIONS: false,
    VIDEO_CONTENT_QUALITY: false,
    BACKGROUND: false,
    CITY_TIER: false,
    PEOPLE_IN_VIDEO: false,
    EVENT: false,
    GLAMOUR: false,
    VIOLENCE: false,
    TARGET_LANGUAUE: false,
    // ZONE: false,
  },
  showSkipContentModal: false,
  selectedDetails: {
    taxonomy_genre: {
      target_type: "",
      target_sub_type: "",
      target_theme: "",
    },
    taxonomy_emotions: {
      type: "",
      sub_type: "",
      types: [],
    },
    taxonomy_video_quality: {
      look_feel: "",
      screen_coverage: "",
      is_video_vulgar: "",
      audio_vulgarity: "",
      is_audio_vulgar: "",
      sound_quality: "",
      background_noise: "",
    },
    taxonomy_elements: {
      background: "",
      people_in_video: "",
      city_tier: "",
      kid_x_factor: "",
      male_x_factor: "",
      female_x_factor: "",
    },
    taxonomy_event: {
      event_type: "",
      event_id: "",
    },
    taxonomy_video_format: {
      type: "",
    },
    video_quality_attributes: {
      simple: "",
      relatable: "",
      presentable: "",
      quality_talent: "",
      surprise_element: "",
      rating: "",
    },
    orthogonal_attributes: {
      singing: "",
      acting: "",
      dialogue: "",
      music: "",
    },
    video_additional_info: {
      ethnicity: "",
      people_count: "",
    },
    taxonomy_violence: {
      violence_type: "",
    },
    taxonomy_glamour: {
      glamour_type: "",
    },
    target_languages: {
      languages: [],
    },
    skip_reason: "",
    // zone_obj: [],
  },
  selectedLanguage: null,
  selectedRejectReason: null,
  competitorLogoName: null,
  competitorLogoNameText: null,
  bannedOrganizationName: null,
}

class TaxonomyDashboard extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      contentType:
        this.props.selectedContent.content_uuid.split("_")[0] === "i"
          ? "image"
          : null,
      okModifySkipActive:
        this.props.selectedContent.content_uuid.split("_")[0] === "i"
          ? true
          : false,
      selectedSection: "default",
      selectedDetails: _.cloneDeep(initialState.selectedDetails),
      playbackRate: "1.0",
      controls: this.props.currentUser.userType !== 4,
      linkedCards: [],
      currentData: [],
      previousData: [],
      displayData: [],
      clickType: "",
      enableUpdateButton: false,
      isVideoCompleted: false,
      taxonomyDisplayOrderArray: [],
      eventList: [],
      playCount: 0,
      tabs: { ...initialState.tabs },
      stageWise: false,
    }
    this.videoRef = createRef()
    this.popupVideoRef = createRef()
  }

  async componentDidMount() {
    let sourceData = {}
    let selectedSection = "glamour"
    let taxonomyRoutes = _.cloneDeep(this.props.taxonomyRoutes)
    taxonomyRoutes = taxonomyRoutes.filter(item => {
      if (item.key === "audio_vulgarity") {
        return this.props.currentUser.permissions.includes(
          "INTERNAL_QC_TAG_CONTENT"
        )
      }
      return true
    })
    const stage1Routes = [
      "glamour",
      "audio_vulgarity",
      "violence",
      "ethnicity",
      "people_count",
      "is_bharat",
    ]
    if (this.props.selectedContent.source_id) {
      sourceData = await getSourceDetails(this.props.selectedContent.source_id)
      if (["moderation", "qc_moderation"].includes(sourceData.subType)) {
        selectedSection = "moderation"
        taxonomyRoutes.forEach(route => {
          if (route.key === "moderation") {
            route.showInSidebar = true
          }
        })
      } else {
        // for stage 1
        if (["labeling_stage_one"].includes(sourceData.subType)) {
          this.setState({ stageWise: true })
          selectedSection = "glamour"
          taxonomyRoutes.forEach(route => {
            if (stage1Routes.includes(route.key)) {
              route.showInSidebar = true
              route.stage = 1
            }

            if (
              route.key === "glamour" &&
              [1, 2, 3, 4, 5, 7].includes(
                this.props.selectedContent?.taxonomy_meta
                  ?.taxonomy_video_quality?.video_glamour?.value
              )
            ) {
              const grade =
                this.props.selectedContent?.taxonomy_meta
                  ?.taxonomy_video_quality?.video_glamour?.grade
              const region =
                this.props.selectedContent?.taxonomy_meta
                  ?.taxonomy_video_quality?.video_glamour?.region
              if (_.isEmpty(grade) || _.isEmpty(region)) {
                route.completed = false
              }
            }
          })
          //stage 2
        } else {
          taxonomyRoutes = _.cloneDeep(this.props.taxonomyRoutes)
          taxonomyRoutes.forEach(route => {
            route.showInSidebar = true
          })
          console.log(
            "🚀 ~ file: TaxonomyDashboard.js:228 ~ TaxonomyDashboard ~ componentDidMount ~ taxonomyRoutes2:",
            taxonomyRoutes
          )

          // this.props.dispatch(
          //   setTaxonomyRoutes({ taxonomyRoutes: taxonomyRoutes })
          // )
        }
      }
      taxonomyRoutes.forEach(route => {
        if (route.key === "default") {
          route.showInSidebar = false
        }
      })

      this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
      this.setState({ sourceData })
    } else {
      taxonomyRoutes.forEach(route => {
        if (route.key === "zone_verification") {
          if (this.props.selectedContent?.zone_info_v1?.length > 0) {
            route.showInSidebar = true
          }
        } else if (
          route.key === "moderation" ||
          route.key === "zone_approval" ||
          route.key === "default"
        ) {
        } else {
          route.showInSidebar = true
        }
      })
      this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
    }

    if (this.props.selectedContent.taxonomy_meta) {
      const selectedDetails = await parseESPayload(
        this.props.selectedContent.taxonomy_meta
      )
      this.setState({
        controls: this.props.currentUser.userType !== 4,
        selectedDetails,
        sourceData,
      })
    }

    this.setState({
      okModifySkipActive:
        this.props.selectedContent.content_uuid.split("_")[0] === "i"
          ? true
          : false,
      startTime: Date.now(),
      deactivatedAsCopyRightReasons:
        this.props.selectedContent &&
        this.props.selectedContent.reject_reason === "copyright_content" &&
        !this.props?.currentUser?.permissions?.includes(
          "ACTIVATE_COPYRIGHT_CONTENT"
        ),
      sourceData,
      selectedSection,
      // isShowActivateDeactivatedContent: true,
    })

    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  _shouldDisable = () => {
    let shouldEnable = !this.props.taxonomyRoutes
      .filter(item => item.showInSidebar)
      .map(item => item.completed)
      .includes(false)
    if (this.state.enableUpdateButton !== shouldEnable) {
      this.setState({ enableUpdateButton: shouldEnable })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedContent.content_uuid !==
      prevProps.selectedContent.content_uuid
    ) {
      const selectedDetails = this.props.selectedContent.taxonomy_meta
        ? await parseESPayload(this.props.selectedContent.taxonomy_meta)
        : this.state.selectedDetails
      this.setState({
        selectedDetails,
      })
      const taxonomyRoutes = _.cloneDeep(this.props.taxonomyRoutes)
      if (this.state.stageWise) {
        const stage1Routes = [
          "glamour",
          "audio_vulgarity",
          "violence",
          "ethnicity",
          "people_count",
          "is_bharat",
        ]
        let selectedSection = this.state.selectedSection
        taxonomyRoutes.forEach(item => {
          item.completed = false
          item.showInSidebar = false
          delete item.stage
        })
        // for stage 1
        if (["labeling_stage_one"].includes(this.state.sourceData.subType)) {
          this.setState({ stageWise: true })
          selectedSection = "glamour"
          taxonomyRoutes.forEach(route => {
            if (stage1Routes.includes(route.key)) {
              route.showInSidebar = true
              route.stage = 1
              route.completed = false
            } else {
              route.showInSidebar = false
              route.stage = 1
              route.completed = false
            }
            if (
              route.key === "glamour" &&
              [1, 2, 3, 4, 5, 7].includes(
                this.props.selectedContent?.taxonomy_meta
                  ?.taxonomy_video_quality?.video_glamour?.value
              )
            ) {
              const grade =
                this.props.selectedContent?.taxonomy_meta
                  ?.taxonomy_video_quality?.video_glamour?.grade
              const region =
                this.props.selectedContent?.taxonomy_meta
                  ?.taxonomy_video_quality?.video_glamour?.region
              if (_.isEmpty(grade) || _.isEmpty(region)) {
                route.completed = false
              }
            }
          })
          //stage 2
        }
        this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))

        this.setState({ selectedSection })
      } else {
        const taxonomyRoutes2 = _.cloneDeep(taxonomyRoutes)
        taxonomyRoutes2.forEach(route => {
          if (route.key === "zone_verification") {
            if (this.props.selectedContent?.zone_info_v1?.length > 0) {
              route.showInSidebar = true
            } else {
              route.showInSidebar = false
            }
          }
        })
        this.props.dispatch(
          setTaxonomyRoutes({ taxonomyRoutes: taxonomyRoutes2 })
        )
      }

      let shouldEnable = !this.props.taxonomyRoutes
        .map(item => item.completed)
        .includes(false)
      if (prevState.enableUpdateButton !== shouldEnable) {
        this.setState({ enableUpdateButton: shouldEnable })
      }
      let sourceData = {}
      if (this.props.selectedContent.source_id) {
        sourceData = await getSourceDetails(
          this.props.selectedContent.source_id
        )
      }
      this.setState({
        controls: this.props.currentUser.userType !== 4,
        okModifySkipActive:
          this.props.selectedContent.content_uuid.split("_")[0] === "i"
            ? true
            : false,
        selectedDetails,
        expandedKeys: null,
        deactivatedAsCopyRightReasons:
          this.props.selectedContent &&
          this.props.selectedContent.reject_reason === "copyright_content",
        sourceData,
        // isShowActivateDeactivatedContent: true,
      })
    }
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount() {
    this.didUnmount = true
    window.removeEventListener("keyup", this.handleKeyUp, false)
    this.props.dispatch(
      setTaxonomyRoutes({ taxonomyRoutes: defaultTaxonomyRoutes })
    )
    // this.props.taxonomyRoutes.map(item => {
    //   item.completed = false
    // })
  }

  _setInitialData = () => {
    this.setState({
      selectedDetails: _.cloneDeep(initialState.selectedDetails),
      currentData: [],
      previousData: [],
      displayData: [],
    })
    this.props.dispatch(resetTaxonomyState())
    this.props.onCloseRequest()
  }

  _handleEsc = () => {
    if (!this._shouldHideModal() || this.props.escPressed) {
      this.setState({ showConfirmationPopup: true, clickType: "Esc" })
    } else this._setInitialData()
  }

  handleKeyUp = e => {
    const keys = {
      27: () => {
        e.preventDefault()
        e.stopPropagation()
        this._handleEsc()
        window.removeEventListener("keyup", this.handleKeyUp, false)
      },
    }

    if (keys[e.keyCode]) {
      keys[e.keyCode]()
    }
  }
  _setSelectedTab = (tab, completed) => {
    this.setState({
      tabs: {
        ...this.state.tabs,
        [tab]: completed,
      },
    })
  }

  _setSelectedDetails = data => {
    this.setState({
      selectedDetails: data,
    })
  }

  setSelectedSection = val => {
    this.setState({
      selectedSection: val,
    })
  }

  _setPlayBack = () => {
    this.videoRef.current.playbackrate = this.state.playbackRate
    this.videoRef.current.volume = 0.5
  }

  _setVolume = () => {
    this.popupVideoRef.current.volume = 0.25
  }
  _renderEmbed = content => {
    return <EmbedSocialContainer key={content.content_uuid} content={content} />
  }

  _generateContentPreview = content => {
    const height = window.innerHeight - 160
    const width = (height * 9) / 16
    if (content.content_uuid && content.content_uuid.split("_")[0] === "i") {
      return (
        <img
          style={{ width: width, height: height, marginTop: "2%" }}
          src={content.thumbnail_url}
        />
      )
    } else if (
      content.content_uuid &&
      content.content_uuid.split("_")[0] === "e"
    ) {
      return (
        <div style={{ height, width }}>
          {content.blockquote_text && this._renderEmbed(content)}
        </div>
      )
    }
    switch (content.format) {
      case "IMAGE":
        return (
          <img
            style={{ width: "60%", height: "auto", marginTop: "2%" }}
            src={content.download_url}
          />
        )
      case "GIF":
        return (
          <img
            style={{ width: "60%", height: "auto", marginTop: "2%" }}
            src={content.download_url}
          />
        )
      default:
        return (
          <Col span={23} offset={1} style={{ top: "5px" }}>
            <img
              src="http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/default/safe_area.png"
              style={{ position: "absolute" }}
              width={width}
              height={height}
            ></img>
            <div>
              <video
                src={content.preview_url}
                width={width}
                height={height}
                playbackRate={this.state.playbackRate}
                ref={this.videoRef}
                onCanPlay={() => this._setPlayBack()}
                controls={this.state.controls}
                onEnded={() => {
                  this.setState(
                    {
                      okModifySkipActive: true,
                      controls: true,
                      playCount: this.state.playCount + 1,
                      isVideoCompleted: true,
                    },
                    () => {
                      if (this.state.playCount === 1) {
                        this.setState({
                          isTagWarningVisible:
                            content &&
                            (content.auto_moderation_label === "nsfw" ||
                              content.taxonomy_meta?.taxonomy_video_quality
                                ?.video_glamour?.value > 3),
                          noGlamourTagToNsfw:
                            content &&
                            content.auto_moderation_label === "nsfw" &&
                            content.taxonomy_meta?.taxonomy_video_quality
                              ?.video_glamour?.value === 0,
                        })
                      }
                    }
                  )
                  this.videoRef.current.play()
                }}
                controlsList={
                  this.props.currentUser.userType > 3 ? "nodownload" : ""
                }
                muted={this.props.currentUser.userType < 4 ? true : false}
                onContextMenu={e => {
                  e.preventDefault()
                  return false
                }}
                autoPlay={true}
              />
            </div>
          </Col>
        )
    }
  }

  _getInfo = (date, info) => {
    const qcInfoPermission =
      this.props.currentUser.userType < 4 ||
      (this.props.currentUser.permissions &&
        this.props.currentUser.permissions.filter(permission =>
          [
            "QC_TAG_CONTENT",
            "INTERNAL_QC_TAG_CONTENT",
            "TAG_CONTENT_ADMIN",
          ].includes(permission)
        ).length > 0)
    const internalQcInfoPermission =
      this.props.currentUser.userType < 4 ||
      (this.props.currentUser.permissions &&
        this.props.currentUser.permissions.includes("INTERNAL_QC_TAG_CONTENT"))
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ fontSize: "14px", fontWeight: "700", color: "grey" }}>
            Created:&nbsp;
          </div>
          <div style={{ fontSize: "14px", fontWeight: "700" }}>
            <ReactTimeAgo date={date} locale="en-US" />
          </div>
        </div>
        <div>
          {info && info.completed_by && (
            <div style={{ display: "flex" }}>
              <div
                style={{ fontSize: "14px", fontWeight: "700", color: "grey" }}
              >
                Completed By:&nbsp;
              </div>
              <div style={{ fontSize: "14px", fontWeight: "700" }}>
                {info.completed_by}
              </div>
            </div>
          )}
          <React.Fragment>
            {qcInfoPermission && info && info.qc_completed_by && (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "grey",
                  }}
                >
                  QC Completed By:&nbsp;
                </div>
                <div style={{ fontSize: "14px", fontWeight: "700" }}>
                  {info.qc_completed_by}
                </div>
              </div>
            )}
            {internalQcInfoPermission &&
              info &&
              info.internal_qc_completed_by && (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "grey",
                    }}
                  >
                    Internal QC Completed By:&nbsp;
                  </div>
                  <div style={{ fontSize: "14px", fontWeight: "700" }}>
                    {info.internal_qc_completed_by}
                  </div>
                </div>
              )}
          </React.Fragment>
        </div>
      </React.Fragment>
    )
  }

  _changeVideo = action => {
    const taxonomyRoutes = _.cloneDeep(this.props.taxonomyRoutes)
    if (this.props.selectedContent.source_id) {
      let selectedSection = this.state.selectedSection
      if (
        ["moderation", "qc_moderation"].includes(this.state.sourceData.subType)
      ) {
        selectedSection = "moderation"
        taxonomyRoutes.forEach(route => {
          if (route.key === "moderation") {
            route.showInSidebar = true
          }
        })
      } else {
        selectedSection = "glamour"
        // this.setState({ stageWise: false })
        taxonomyRoutes.forEach(route => {
          if (
            !["zone_approval", "moderation", "sub_genre"].includes(route.key)
          ) {
            route.showInSidebar = true
          }
        })
        taxonomyRoutes.forEach(item => {
          item.completed = false
        })
        this.props.dispatch(resetTaxonomyState())
      }

      taxonomyRoutes.forEach(route => {
        if (route.key === "default") {
          route.showInSidebar = false
        }
      })

      this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
      this.props.dispatch(resetInitialStateOnly({ taxonomyRoutes }))
      this.setState({ selectedSection })
    } else {
      this.props.dispatch(resetTaxonomyState())
      taxonomyRoutes.forEach(item => {
        item.completed = false
      })
      taxonomyRoutes.forEach(route => {
        if (route.key === "default") {
          route.showInSidebar = false
        }
      })

      this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
      this.setSelectedSection("audio_vulgarity")
    }

    // this.setSelectedSection("genre")
    const displayData = {}
    let localInitialState = JSON.parse(JSON.stringify(initialState))
    this.setState(
      {
        reset: true,
        displayData,
        playCount: 0,
        isNotifWorthyContent: false,
        okModifySkipActive: false,
        isVideoCompleted: false,
        controls: this.props.currentUser.userType !== 4,
        ...localInitialState,
      },
      action()
    )
  }

  _shouldHideModal = () => {
    const hideModal =
      this.props.taxonomyRoutes
        .map(route => {
          return route.completed
        })
        .indexOf(true) < 0
    return hideModal
  }

  _handleNext = reset => {
    if (this._shouldHideModal()) {
      this.setState({ startTime: Date.now() })
      reset()
      return this._changeVideo(this.props.nextContent)
    }
    return this.setState({ showConfirmationPopup: true, clickType: "Next" })
  }

  _skipContentModal = isNotifWorthyContent => {
    if (isNotifWorthyContent) {
      this.setState({ showNotifWorthyPopup: true, isNotifWorthyContent })
    } else {
      this.setState({
        showSkipContentModal: true,
      })
    }
  }

  _handleOk = async (reset, content) => {
    let loading
    try {
      const data = {
        content_uuid: content.content_uuid,
        startTime: this.state.startTime,
        endTime: Date.now(),
        timeTakenInMilliSec: Date.now() - this.state.startTime,
      }

      let sourceId = content.source_id
      toastr.remove()
      loading = toastr.info("Action in progress...")
      const sendToInternalQC =
        this.props.currentUser.permissions &&
        this.props.currentUser.permissions.includes("QC_TAG_CONTENT") &&
        Math.floor(Math.random() * 10) <= 5
      const response = await markQcTaskAsCompleted(
        data,
        sendToInternalQC,
        sourceId
      )

      if (response.status === 200) {
        toastr.remove()
        toastr.success("Review submitted successfully!!")
        reset()
        this.setState(
          {
            startTime: Date.now(),
            selectedDetails: initialState.selectedDetails,
          },
          () => {
            this._changeVideo(this.props.nextContent)
          }
        )
      } else {
        toastr.remove()
        toastr.error("Error in review submission!!")
      }
    } catch (error) {
      toastr.remove()
      toastr.error("Error in review submission!!")
    } finally {
      this.setState({
        ignore: false,
        ignoreRatingLessThan4: false,
      })
    }
  }

  _cleanSelectedDetails = details => {
    let updatedDetails = {}
    let filteredDetails = {}
    Object.keys(details).forEach(key => {
      if (Array.isArray(details[key]) || typeof details[key] === "string") {
        updatedDetails[key] = details[key]
      } else {
        updatedDetails[key] = _.omitBy(
          details[key],
          v =>
            _.isUndefined(v) ||
            _.isNull(v) ||
            v === "" ||
            (!["boolean", "number"].includes(typeof v) && _.isEmpty(v))
        )
      }
    })
    Object.keys(updatedDetails).forEach(key => {
      if (!_.isEmpty(updatedDetails[key])) {
        filteredDetails[key] = updatedDetails[key]
      }
    })
    return filteredDetails
  }

  _updateContentZones = async (currentContent, reset, source) => {
    let content = _.cloneDeep(currentContent)
    let sub_type_zone = []
    let active_zones = []
    if (this.state.selectedDetails?.approved_zone_obj?.qcApprovedZones) {
      active_zones =
        this.state.selectedDetails.approved_zone_obj.qcApprovedZones
    }

    if (content.active_zones && content.active_zones.length > 0) {
      active_zones = [...active_zones, ...content.active_zones]
    }
    let updated_all_active_zones = _.uniq(active_zones).filter(
      zoneId =>
        !this.state.selectedDetails.approved_zone_obj.qcRejectedZones.includes(
          zoneId
        )
    )
    let updated_active_zones = []

    let relational_zones = []
    let allnondisabledzones = this.props.allZonesList.filter(
      item => item.status != "DISABLED"
    )

    // check for valid zone
    for (let i = 0; i < updated_all_active_zones.length; i++) {
      for (let j = 0; j < allnondisabledzones.length; j++) {
        if (updated_all_active_zones[i] == allnondisabledzones[j].zone_uuid) {
          updated_active_zones = [
            ...updated_active_zones,
            allnondisabledzones[j].zone_uuid,
          ]
        }
      }
    }

    let validZones = [...updated_active_zones]

    validZones.map(item => {
      allnondisabledzones.map(zone => {
        if (item == zone.zone_uuid) {
          if (zone.level == 2) {
            updated_active_zones = [
              ...updated_active_zones,
              ...zone.sub_type,
              ...zone.type,
            ]
            sub_type_zone = [...sub_type_zone, ...zone.sub_type]
            relational_zones = [
              ...relational_zones,
              ...zone.sub_type,
              ...zone.type,
            ]
          } else if (zone.level == 1) {
            updated_active_zones = [...updated_active_zones, ...zone.type]
            relational_zones = [...relational_zones, ...zone.type]
          }
        }
      })
    })

    sub_type_zone.map(item => {
      allnondisabledzones.map(zone => {
        if (item == zone.zone_uuid) {
          if (zone.level == 1) {
            updated_active_zones = [...updated_active_zones, ...zone.type]
            relational_zones = [...relational_zones, ...zone.type]
          }
        }
      })
    })

    const newActiveSet = new Set(updated_active_zones)
    if (!content.input_zones) {
      if (content.active_zones && content.active_zones.length > 0) {
        content.input_zones = [...content.active_zones]
      } else {
        content.input_zones = []
      }
    }

    const newInputSet = new Set(content.input_zones)
    content.input_zones = [...newInputSet]

    let contentData = {}
    const activeZones = [...newActiveSet]
    const inputZones = [...newInputSet]
    let zoneInfo = _.cloneDeep(content.zone_info_v1) || []

    for (let info of zoneInfo) {
      delete info?.shouldRetrieve
    }
    if (this.state.selectedDetails?.approved_zone_obj?.qcApprovedZones) {
      for (let appzones of this.state.selectedDetails?.approved_zone_obj
        ?.qcApprovedZones) {
        if (relational_zones.includes(appzones)) {
          var index = relational_zones.indexOf(appzones)
          if (index !== -1) {
            relational_zones.splice(index, 1)
          }
        }
      }
    }
    //zoneInfo for Active Zone
    if (zoneInfo?.length > 0) {
      let added_type = ""
      if (!_.isEmpty(activeZones)) {
        for (let activeZone of activeZones) {
          let zoneHandled = false
          if (relational_zones.includes(activeZone)) {
            added_type = "relational"
          } else {
            added_type = "input"
          }
          for (let zone of zoneInfo) {
            if (zone.zone_id === activeZone) {
              zone.shouldRetrieve = true
              zoneHandled = true
              ;(zone.modified_by = this.props.currentUser.email),
                (zone.status = zone?.status === "fixed" ? "fixed" : "approved")
              zone.added_type = added_type
              zone.modified_on = moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30")
            }
          }

          if (!zoneHandled) {
            zoneInfo.push({
              zone_id: activeZone,
              status: "approved",
              source: "Agent",
              added_type: added_type,
              added_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_on: moment()
                .tz("Asia/Calcutta")
                .format("YYYY-MM-DDTHH:mm:ss+05:30"),
              modified_by: this.props.currentUser.email,
              shouldRetrieve: true,
            })
          }
        }

        for (let zone of zoneInfo) {
          if (!zone.shouldRetrieve) {
            if (relational_zones.includes(zone)) {
              added_type = "relational"
            } else {
              added_type = "input"
            }
            zone.status = zone?.status === "fixed" ? "fixed" : "removed"
            zone.added_type = added_type
            zone.modified_by = this.props.currentUser.email
            zone.modified_on = moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30")
          }
          // delete zone?.shouldRetrieve
        }
      } else {
        for (let zone of zoneInfo) {
          if (relational_zones.includes(zone)) {
            added_type = "relational"
          } else {
            added_type = "input"
          }
          zone.status = zone?.status === "fixed" ? "fixed" : "removed"
          zone.added_type = added_type
          zone.modified_by = this.props.currentUser.email
          zone.modified_on = moment()
            .tz("Asia/Calcutta")
            .format("YYYY-MM-DDTHH:mm:ss+05:30")
        }
      }
    } else {
      if (!_.isEmpty(activeZones)) {
        for (let zone of activeZones) {
          let added_type = ""
          if (relational_zones.includes(zone)) {
            added_type = "relational"
          } else {
            added_type = "input"
          }
          zoneInfo.push({
            zone_id: zone,
            status: "approved",
            source: "Agent",
            added_type: added_type,
            added_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_on: moment()
              .tz("Asia/Calcutta")
              .format("YYYY-MM-DDTHH:mm:ss+05:30"),
            modified_by: this.props.currentUser.email,
          })
        }
      }
    }

    if (!_.isEmpty(activeZones)) {
      if (content.input_zones && content.input_zones.length > 0) {
        content.input_zones = [...content.input_zones, ...activeZones]
      } else {
        content.input_zones = [...activeZones]
      }
    }

    for (let info of zoneInfo) {
      delete info?.shouldRetrieve
    }
    //zone status to zone_added

    // if (currentContent.zone_info_v1) {
    //   for (let zone of zoneInfo) {
    //     let isMarked = false
    //     for (let currzone of currentContent.zone_info_v1) {
    //       if (zone.zone_id == currzone.zone_id) {
    //         isMarked = true
    //       }
    //     }
    //     if (!isMarked) {
    //       zone.status = "added"
    //     }
    //   }
    // }

    // else {
    //   for (let zone of zoneInfo) {
    //     zone.status = "added"
    //   }
    // }

    contentData["input_zones"] = [...new Set(content.input_zones)]
    contentData["active_zones"] = activeZones
    contentData["content_uuid"] = content.content_uuid
    contentData["zone_info"] = zoneInfo

    try {
      toastr.remove()
      toastr.info("Action in progress !!")
      const response = await updateContentZones(contentData)
      if (response?.data?.status?.code === 200) {
        const data = {
          content_uuid: content.content_uuid,
          startTime: this.state.startTime,
          endTime: Date.now(),
          timeTakenInMilliSec: Date.now() - this.state.startTime,
        }
        if (source !== "taxonomy") {
          const taskResponse = await markTaskAsCompleted(
            {
              content,
              sourceData: this.state.sourceData,
              action: "ZONE_APPROVAL",
            },
            content.source_id
          )
          if (taskResponse?.data?.status?.code === 200) {
            toastr.remove()
            toastr.success("Action Successfull !!")
            reset()
            this._changeVideo(this.props.nextContent)
            this.setState({ doneButtonClicked: false })
          }
        }
      } else {
        toastr.remove()
        toastr.error("Some error occured!")
      }
      return zoneInfo
    } catch (error) {
      toastr.remove()
      toastr.error("Some error occured!")
    }
  }

  _tagContentStageWise = async (
    content,
    reset,
    skipped,
    moderationAction,
    subType
  ) => {
    let detailsData = this._cleanSelectedDetails(
      clearStageWiseData(
        transformToESPayload(
          initialState.selectedDetails,
          this.state.selectedDetails,
          content,
          this.props.eventList
        ),
        subType
      )
    )
    let zoneInfov1 = []
    // if (
    //   subType == "labeling_stage_one" &&
    //   !detailsData.hasOwnProperty("skip_reason") &&
    //   (!detailsData.hasOwnProperty("taxonomy_genre") ||
    //     (detailsData.taxonomy_genre &&
    //       Object.keys(detailsData.taxonomy_genre).length == 0))
    // ) {
    //   toastr.remove()
    //   toastr.info("Genre is not tagged")

    //   return
    // }
    if (this.state.selectedDetails?.approved_zone_obj) {
      zoneInfov1 = await this._updateContentZones(content, reset, "taxonomy")
    } else {
      if (content.zone_info_v1) {
        zoneInfov1 = content.zone_info_v1
      }
    }
    let activityLog = buildActivityLog(
      detailsData,
      content.taxonomy_meta
        ? content.taxonomy_meta
        : initialState.selectedDetails,
      subType,
      this.state.selectedDetails,
      zoneInfov1
    )
    let isDeactive = [0, -1].includes(content.moderation_level) ? true : false
    if (
      isDeactive &&
      moderationAction === "activate" &&
      !this.state.ignoreActivateWarning
    ) {
      this.setState({ activateDeactivatedContent: true })
    }

    if (moderationAction === "activate") {
      delete detailsData["skip_reason"]
    }

    if (moderationAction === "deactivate") {
      Object.keys(detailsData).forEach(function (itm) {
        if (
          itm != "skip_reason" &&
          itm != "competitor_logo_name" &&
          itm != "competitor_logo_name_text" &&
          itm != "banned_organization_name"
        ) {
          delete detailsData[itm]
        }
      })
    }

    const taxonomyMeta = {
      content_uuid: content.content_uuid,
      details: detailsData,
      startTime: this.state.startTime,
      endTime: Date.now(),
      timeTakenInMilliSec: Date.now() - this.state.startTime,
    }

    try {
      if (moderationAction === "deactivate") {
        toastr.remove()
        toastr.info("Action in progress...")
        const taskResponse = await markTaskAsCompleted(
          {
            content,
            sourceData: this.state.sourceData,
            action: subType,
            taxonomyMeta,
            activityLog,
            zoneInfov1,
          },
          content.source_id
        )

        if (taskResponse?.data?.status?.code === 200) {
          let skipedContentData = { ...content }
          skipedContentData["competitor_logo_name"] =
            this.state.competitorLogoName
          skipedContentData["competitor_logo_name_text"] =
            this.state.competitorLogoNameText
          skipedContentData["banned_organization_name"] =
            this.state.bannedOrganizationName

          if (this.state.selectedRejectReason) {
            skipedContentData["reject_reason"] = this.state.selectedRejectReason
          } else {
            skipedContentData["reject_reason"] =
              this.state?.selectedDetails?.skip_reason
          }
          await activateDeactivateContent(
            skipedContentData,
            moderationAction,
            this.props.currentUser
          )
          if (this.state.isNotifWorthyContent) {
            await sendNotifContentDeactivationAlert({
              emailList: emailListForNotifWorthyDeactivation,
              contentData: {
                id: content.content_uuid,
                title: content.content_title,
                notif_worthy_update_time: content.notif_worthy_update_time,
                deactivatedBy:
                  this.props.currentUser && this.props.currentUser.email,
              },
            })
          }
          toastr.remove()
          toastr.success("Content tagged successfully!!")
          reset()
          this.setState(
            {
              startTime: Date.now(),
              selectedDetails: initialState.selectedDetails,
            },
            () => {
              this._changeVideo(this.props.nextContent)
            }
          )

          return
        }
      }
      if (isDeactive && !this.state.ignoreActivateWarning) {
        return
      } else {
        toastr.remove()
        toastr.info("Action in progress...")
        const taskResponse = await markTaskAsCompleted(
          {
            content,
            sourceData: this.state.sourceData,
            action: subType === "zone_approval" ? "ZONE_APPROVAL" : subType,
            taxonomyMeta,
            activityLog,
            zoneInfov1,
          },
          content.source_id
        )
        if (taskResponse?.data?.status?.code === 200) {
          let skipedContentData = { ...content }

          if (this.state.selectedRejectReason) {
            skipedContentData["reject_reason"] = this.state.selectedRejectReason
          } else {
            skipedContentData["reject_reason"] =
              this.state?.selectedDetails?.skip_reason
          }
          skipedContentData["competitor_logo_name"] =
            this.state.competitorLogoName
          skipedContentData["competitor_logo_name_text"] =
            this.state.competitorLogoNameText
          skipedContentData["banned_organization_name"] =
            this.state.bannedOrganizationName
          await activateDeactivateContent(
            skipedContentData,
            moderationAction,
            this.props.currentUser
          )
          if (
            this.state.isNotifWorthyContent &&
            moderationAction === "deactivate"
          ) {
            await sendNotifContentDeactivationAlert({
              emailList: emailListForNotifWorthyDeactivation,
              contentData: {
                id: content.content_uuid,
                title: content.content_title,
                notif_worthy_update_time: content.notif_worthy_update_time,
                deactivatedBy:
                  this.props.currentUser && this.props.currentUser.email,
              },
            })
          }
          toastr.remove()
          toastr.success("Content tagged successfully!!")
          reset()
          this.setState(
            {
              startTime: Date.now(),
              selectedDetails: initialState.selectedDetails,
            },
            () => {
              this._changeVideo(this.props.nextContent)
            }
          )
        }
      }
    } catch (error) {
      // TODO: Revisit this, instead of relying on the error message includes try sending proper response format from BE
      if (error && error.message.includes("401")) {
        toastr.remove()
        toastr.error("User Not Verified!!")
      } else if (error && error.message.includes("400")) {
        toastr.remove()
        toastr.error(
          "The Content QC is already completed, Content Tagging is not allowed"
        )
      } else {
        toastr.remove()
        toastr.error("Error in tagging content!!")
      }
    } finally {
      // loading()
      setTimeout(() => {
        this.setState({
          enableUpdateButton: true,
          ignore: false,
          ignoreRatingLessThan4: false,
          ignoreActivateWarning: false,
        })
      }, 2000)
    }
  }

  _updateContent = async (content, reset, skipped, moderationAction) => {
    try {
      const isAdsContent = await getAdsStatus({
        contentId: content.content_uuid,
      })
      if (isAdsContent && moderationAction === "deactivate") {
        toastr.remove()
        toastr.error(`Ads content can't be deactivated !!`)
        return
      }
    } catch {
      toastr.remove()
      toastr.error("Error in getting ads status for the content")
      return
    }
    const subType = this.state.sourceData.subType
    this.setState({
      enableUpdateButton: false,
    })

    if (
      [
        "labeling_stage_one",
        // "labeling_stage_two",
        // "labeling_stage_three",
        "zone_approval",
      ].includes(subType)
    ) {
      this._tagContentStageWise(
        content,
        reset,
        skipped,
        moderationAction,
        subType
      )
      return
    }

    let zoneInfov1 = []

    if (
      this.state.selectedDetails?.approved_zone_obj?.qcApprovedZones ||
      this.state.selectedDetails?.approved_zone_obj?.qcRejectedZones
    ) {
      zoneInfov1 = await this._updateContentZones(content, reset, "taxonomy")
    } else {
      if (content.zone_info_v1) {
        zoneInfov1 = content.zone_info_v1
      }
    }

    let loading
    let isQC =
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("QC_TAG_CONTENT")
    const sourceInfo = this.props.currentUser?.source
    const taxonomyDeskId =
      sourceInfo?.TAXONOMY ||
      sourceInfo?.TAXONOMY_STAGE_1 ||
      sourceInfo?.TAXONOMY_STAGE_2 ||
      sourceInfo?.TAXONOMY_STAGE_3
    const sendToInternalQC = isQC && Math.floor(Math.random() * 10) <= 5
    const sendToQC =
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("TAG_CONTENT") &&
      Math.floor(Math.random() * 10) < 3
    try {
      let detailsData = this._cleanSelectedDetails(
        transformToESPayload(
          initialState.selectedDetails,
          this.state.selectedDetails,
          content,
          this.props.eventList
        )
      )

      let isDeactive = [0, -1].includes(content.moderation_level) ? true : false
      if (
        isDeactive &&
        moderationAction === "activate" &&
        !this.state.ignoreActivateWarning
      ) {
        this.setState({
          activateDeactivatedContent: true,
          // isShowActivateDeactivatedContent: true,
        })
      }

      if (moderationAction === "activate") {
        delete detailsData["skip_reason"]
      }

      if (moderationAction === "deactivate") {
        Object.keys(detailsData).forEach(function (itm) {
          if (itm != "skip_reason") {
            if (
              itm != "competitor_logo_name" &&
              itm != "banned_organization_name"
            ) {
              if (itm != "competitor_logo_name_text") delete detailsData[itm]
            }
          }
        })
      }

      const contentData = {
        content_uuid: content.content_uuid,
        details: detailsData,
        startTime: this.state.startTime,
        endTime: Date.now(),
        timeTakenInMilliSec: Date.now() - this.state.startTime,
      }

      if (moderationAction === "deactivate") {
        let sourceId = content.source_id
        toastr.remove()
        loading = toastr.info("Action in progress...")
        const response = await tagContentTaxonomy(
          contentData,
          sendToQC,
          skipped,
          sendToInternalQC,
          taxonomyDeskId,
          sourceId,
          zoneInfov1
        )
        if (response?.status === 200) {
          let skipedContentData = { ...content }
          skipedContentData["reject_reason"] = this.state.selectedRejectReason
          skipedContentData["competitor_logo_name"] =
            this.state.competitorLogoName
          skipedContentData["competitor_logo_name_text"] =
            this.state.competitorLogoNameText
          skipedContentData["banned_organization_name"] =
            this.state.bannedOrganizationName
          await activateDeactivateContent(
            skipedContentData,
            moderationAction,
            this.props.currentUser
          )
          if (this.state.isNotifWorthyContent) {
            await sendNotifContentDeactivationAlert({
              emailList: emailListForNotifWorthyDeactivation,
              contentData: {
                id: content.content_uuid,
                title: content.content_title,
                notif_worthy_update_time: content.notif_worthy_update_time,
                deactivatedBy:
                  this.props.currentUser && this.props.currentUser.email,
              },
            })
          }
          toastr.remove()
          toastr.success("Content tagged successfully!!")
          reset()
          this.setState(
            {
              startTime: Date.now(),
              selectedDetails: initialState.selectedDetails,
            },
            () => this._changeVideo(this.props.nextContent, isQC)
          )

          return
        }
      }
      if (isDeactive && !this.state.ignoreActivateWarning) {
        return
      } else {
        // const response={}
        let sourceId = content.source_id
        toastr.remove()
        loading = toastr.info("Action in progress...")
        const response = await tagContentTaxonomy(
          contentData,
          sendToQC,
          skipped,
          sendToInternalQC,
          taxonomyDeskId,
          sourceId,
          zoneInfov1
        )
        if (response?.status === 200) {
          let skipedContentData = { ...content }
          skipedContentData["reject_reason"] = this.state.selectedRejectReason
          await activateDeactivateContent(
            skipedContentData,
            moderationAction,
            this.props.currentUser
          )
          if (
            this.state.isNotifWorthyContent &&
            moderationAction === "deactivate"
          ) {
            await sendNotifContentDeactivationAlert({
              emailList: emailListForNotifWorthyDeactivation,
              contentData: {
                id: content.content_uuid,
                title: content.content_title,
                notif_worthy_update_time: content.notif_worthy_update_time,
                deactivatedBy:
                  this.props.currentUser && this.props.currentUser.email,
              },
            })
          }
          toastr.remove()
          toastr.success("Content tagged successfully!!")
          reset()
          this.setState(
            {
              startTime: Date.now(),
              selectedDetails: initialState.selectedDetails,
            },
            () => this._changeVideo(this.props.nextContent, isQC)
          )
        }
      }
    } catch (error) {
      // TODO: Revisit this, instead of relying on the error message includes try sending proper response format from BE
      if (error && error.message.includes("401")) {
        toastr.remove()
        toastr.error("User Not Verified!!")
      } else if (error && error.message.includes("400")) {
        toastr.remove()
        toastr.error(
          "The Content QC is already completed, Content Tagging is not allowed"
        )
      } else {
        toastr.remove()
        toastr.error("Error in tagging content!!")
      }
    } finally {
      // loading()
      setTimeout(() => {
        this.setState({
          enableUpdateButton: true,
          ignore: false,
          ignoreRatingLessThan4: false,
          ignoreActivateWarning: false,
        })
      }, 2000)
    }
  }

  _onModerationUpdate = async (
    content,
    reset,
    skipped,
    moderationAction,
    rejectReason,
    otherProps = null
  ) => {
    let loading
    try {
      let sourceId = content.source_id
      toastr.remove()
      loading = toastr.info("Action in progress...")

      const {
        content_type = "content",
        competitor_logo_name,
        competitor_logo_name_text,
        banned_organization_name,
        misc_reject_reason,
      } = otherProps || {}

      let action = ""

      let skipedContentData = { ...content }
      skipedContentData["reject_reason"] = rejectReason
      if (content_type === "image") {
        if (competitor_logo_name) {
          skipedContentData["competitor_logo_name"] = competitor_logo_name
        }
        if (competitor_logo_name_text) {
          skipedContentData["competitor_logo_name_text"] =
            competitor_logo_name_text
        }
        if (banned_organization_name) {
          skipedContentData["banned_organization_name"] =
            banned_organization_name
        }
        if (misc_reject_reason) {
          skipedContentData["misc_reject_reason"] = misc_reject_reason
        }
        let result = await activateDeactivateContent(
          skipedContentData,
          moderationAction,
          this.props.currentUser
        )
        if (result?.data?.code === 200) {
          await markTaskAsCompleted(
            {
              content,
              sourceData: this.state.sourceData,
              action,
            },
            sourceId
          )
          toastr.remove()
          toastr.success("Content tagged successfully!!")
          reset()
          this.setState(
            {
              startTime: Date.now(),
              selectedDetails: initialState.selectedDetails,
            },
            () => this._changeVideo(this.props.nextContent)
          )
        }
      } else {
        let result = await activateDeactivateContent(
          skipedContentData,
          moderationAction,
          this.props.currentUser
        )
        if (result?.data?.code === 200) {
          await markTaskAsCompleted(
            {
              content,
              sourceData: this.state.sourceData,
              action,
            },
            sourceId
          )
          toastr.remove()
          toastr.success("Content tagged successfully!!")
          reset()
          this.setState(
            {
              startTime: Date.now(),
              selectedDetails: initialState.selectedDetails,
            },
            () => this._changeVideo(this.props.nextContent)
          )
        }
      }
      // }
    } catch (error) {
      // TODO: Revisit this, instead of relying on the error message includes try sending proper response format from BE
      if (error && error.message.includes("401")) {
        toastr.remove()
        toastr.error("User Not Verified!!")
      } else if (error && error.message.includes("400")) {
        toastr.remove()
        toastr.error(
          "The Content QC is already completed, Content Tagging is not allowed"
        )
      } else {
        toastr.remove()
        toastr.error("Error in tagging content!!")
      }
    } finally {
      // loading()
      setTimeout(() => {
        this.setState({
          enableUpdateButton: true,
          ignore: false,
          ignoreRatingLessThan4: false,
          ignoreActivateWarning: false,
        })
      }, 2000)
    }
  }

  _skipContent = async (content, reset) => {
    const selectedDetails = {
      skip_reason: "",
    }

    if (this.state.selectedRejectReason) {
      selectedDetails["skip_reason"] = this.state.selectedRejectReason
      if (
        selectedDetails["skip_reason"] == "competitor_logo" &&
        this.state.competitorLogoName == null
      ) {
        toastr.remove()
        toastr.error("Please select Competitor Logo")
        return
      }

      if (
        selectedDetails["skip_reason"] == "banned_organization" &&
        this.state.bannedOrganizationName == null
      ) {
        toastr.remove()
        toastr.error("Please select Banned Organization")
        return
      }

      selectedDetails["competitor_logo_name"] = this.state.competitorLogoName
      selectedDetails["competitor_logo_name_text"] =
        this.state.competitorLogoNameText
      selectedDetails["banned_organization_name"] =
        this.state.bannedOrganizationName
      this.setState(
        {
          selectedDetails,
        },
        () => this._updateContent(content, reset, true, "deactivate")
      )
    } else if (this.state.selectedLanguage) {
      let loading
      try {
        toastr.remove()
        loading = toastr.info("Action in progress...")
        const data = {
          startTime: this.state.startTime,
          endTime: Date.now(),
          timeTakenInMilliSec: Date.now() - this.state.startTime,
          isLanguageSkip: true,
        }
        // const response = await skipContentTagging(
        //   data,
        //   content["content_uuid"],
        //   this.state.selectedLanguage
        // )
        let activityLog = {
          skip_reason: {
            status: "added",
            value: this.state.selectedLanguage,
          },
          added: [],
          modified: [],
        }
        const response = await markTaskAsCompleted(
          {
            content,
            sourceData: this.state.sourceData,
            action: this.state.sourceData.subType,
            taxonomyMeta: data,
            activityLog,
          },
          content.source_id
        )
        if (response.status === 200) {
          let skipedContentData = { ...content }
          skipedContentData["reject_reason"] = "language_mismatch"
          await activateDeactivateContent(
            skipedContentData,
            "deactivate",
            this.props.currentUser
          )
          toastr.remove()
          toastr.success("Content tagged successfully!!")
          reset()
          this._changeVideo(this.props.nextContent)
        } else {
          reset()
          toastr.remove()
          toastr.error("Error in tagging content!!")
        }
      } catch (error) {
        reset()
        toastr.remove()
        toastr.error("Error in tagging content!!")
      } finally {
        this.setState({ startTime: Date.now() })
      }
    } else {
      toastr.remove()
      toastr.error("Please select language or reject reason")
    }
  }

  _renderFooterButtons = (content, reset) => {
    this._shouldDisable()
    const { currentUser } = this.props
    const disableUpdate =
      content &&
      content.reject_reason === "copyright_content" &&
      !currentUser?.permissions?.includes("ACTIVATE_COPYRIGHT_CONTENT")
    let isExternalBrandContent =
      content &&
      content.user_profile &&
      (content.user_profile.user_type === "eb" ||
        content.user_profile.user_type === "ib")
    let adsContent = content.adsContent
    let updateDisabled =
      !(
        this.state.enableUpdateButton &&
        (["i", "e"].includes(content.content_uuid.split("_")[0])
          ? true
          : this.state.isVideoCompleted)
      ) || disableUpdate
    if (
      ["zone_approval", "qc_zone_approval"].includes(
        this.state.selectedSection
      ) &&
      this.state.isVideoCompleted &&
      this.state.doneButtonClicked
    ) {
      updateDisabled = false
    }

    const showSkipButton =
      (currentUser.userType === 4 &&
        currentUser.permissions &&
        currentUser.permissions.includes("TAG_CONTENT")) ||
      (currentUser.permissions &&
        currentUser.permissions.includes("QC_TAG_CONTENT")) ||
      (currentUser.permissions &&
        currentUser.permissions.includes("INTERNAL_QC_TAG_CONTENT"))
    let today = moment(new Date())
    let notif_worthy_update_time =
      content.notif_worthy_update_time &&
      moment(new Date(content.notif_worthy_update_time))
    let isNotifWorthyContent =
      content &&
      content.notif_worthy &&
      content.notif_worthy_update_time &&
      moment.duration(today.diff(notif_worthy_update_time)).asHours() < 48
    let zoneApproval =
      this.state.sourceData?.subType &&
      this.state.sourceData.subType === "zone_approval"
    return (
      <React.Fragment>
        <Row style={{ margin: ".5rem" }}>
          <Col lg="2" md="2">
            <Tooltip title={content.content_title}>
              <Button
                size="small"
                icon={<AlignLeftOutlined />}
                type="dashed"
              ></Button>
            </Tooltip>
          </Col>
          <Col lg="2" md="2">
            <Popover
              placement="topLeft"
              content={this._getInfo(
                content.created_date,
                content.taxonomy_task_info
              )}
              trigger="click"
            >
              <Button
                size="small"
                icon={<InfoCircleOutlined />}
                type="dashed"
              ></Button>
            </Popover>
          </Col>
          <Col lg="2" md="2">
            <CopyToClipboard
              text={content.share_url}
              onCopy={() => {
                message.success("Copied sucessfully!")
              }}
            >
              <Button
                size="small"
                icon={<ShareAltOutlined />}
                type="dashed"
              ></Button>
            </CopyToClipboard>
          </Col>
          <Col lg="2" md="2">
            <Tooltip title={`User :- ${content.user_profile.user_name}`}>
              <Tag
                color="orange"
                style={{
                  maxWidth: "6rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {" "}
                <UserOutlined />
              </Tag>
            </Tooltip>
          </Col>
          <Col lg="2" md="2">
            {content.lang_code && (
              <Tooltip title="Language">
                <Tag color="#f50" style={{ color: "black" }}>
                  {content.lang_code.toUpperCase()}
                </Tag>
              </Tooltip>
            )}
          </Col>
          <Col lg="2" md="2">
            <div>
              <div>
                <Tag color="#538eed">
                  <div>
                    <Timer.Minutes /> :&nbsp;
                    <Timer.Seconds />
                  </div>
                </Tag>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="d-flex" style={{ margin: ".5rem 0" }}>
          <Col lg="4" md="4" className="d-flex">
            <button
              className="btn d-flex align-self-sm-center"
              disabled={!content["prev"]}
              onClick={() => {
                reset()
                this.setState({ startTime: Date.now() })
                this._changeVideo(this.props.prevContent)
              }}
              style={{
                color: "blue",
                borderColor: "blue",
              }}
            >
              <LeftOutlined className="p-sm-1" />
            </button>
            <button
              className="btn d-flex align-self-sm-center mx-1"
              onClick={() => this._handleNext(reset)}
              style={{
                color: "blue",
                borderColor: "blue",
              }}
            >
              <RightOutlined className="p-sm-1" />
            </button>
          </Col>
          {this.state.selectedSection === "moderation" &&
            this.state.sourceData?.subType === "qc_moderation" && (
              <Col lg="6" md="6" className="d-flex justify-content-end">
                <button
                  className="btn btn-danger mx-1 d-flex font-size-14"
                  disabled={!this.state.okModifySkipActive}
                  onClick={() =>
                    this._onModerationUpdate(content, () => {}, true, "ok")
                  }
                >
                  Ok
                </button>
              </Col>
            )}
          {this.state.selectedSection !== "moderation" && (
            <React.Fragment>
              {this.props.currentUser.permissions &&
              (this.props.currentUser.permissions.includes("QC_TAG_CONTENT") ||
                this.props.currentUser.permissions.includes(
                  "INTERNAL_QC_TAG_CONTENT"
                )) ? (
                <React.Fragment>
                  <Col lg="2" md="3">
                    {showSkipButton && (
                      <button
                        className="btn btn-danger mx-1 d-flex font-size-14"
                        disabled={
                          !this.state.okModifySkipActive ||
                          isExternalBrandContent ||
                          adsContent
                        }
                        onClick={() =>
                          this._skipContentModal(isNotifWorthyContent)
                        }
                      >
                        Skip
                      </button>
                    )}
                  </Col>
                  <Col lg="2" md="3">
                    <button
                      className="btn btn-success d-flex mx-1 font-size-14"
                      disabled={!this.state.okModifySkipActive}
                      onClick={() => this._handleOk(reset, content)}
                    >
                      Ok
                    </button>
                  </Col>
                  <Col lg="3" md="3">
                    <button
                      className="btn btn-danger d-flex font-size-14"
                      disabled={!this.state.okModifySkipActive || disableUpdate}
                      onClick={() =>
                        this._updateContent(content, reset, false, "activate")
                      }
                    >
                      Modify
                    </button>
                  </Col>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Col lg="4" md="4">
                    {showSkipButton && (
                      <button
                        disabled={isExternalBrandContent || adsContent}
                        className="btn btn-danger d-flex mx-2 font-size-14"
                        onClick={() =>
                          this._skipContentModal(isNotifWorthyContent)
                        }
                      >
                        Skip
                      </button>
                    )}
                  </Col>
                  {!zoneApproval && (
                    <Col lg="4" md="4">
                      <button
                        className="btn btn-success d-flex ml-1 font-size-14"
                        disabled={updateDisabled}
                        onClick={() =>
                          this._updateContent(content, reset, false, "activate")
                        }
                      >
                        Update
                      </button>
                    </Col>
                  )}
                  {zoneApproval && (
                    <Col lg="3" md="3">
                      <button
                        className="btn btn-success d-flex font-size-14"
                        disabled={
                          !this.state.okModifySkipActive || disableUpdate
                        }
                        onClick={() =>
                          this._updateContent(content, reset, false, "activate")
                        }
                      >
                        Update
                      </button>
                    </Col>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Row>
      </React.Fragment>
    )
  }

  _renderCardContent = data => {
    const videSource = data.help && data.help.video ? data.help.video : null
    const imgSource = data.help && data.help.image ? data.help.image : null
    const prevImageSource =
      data.prev_images && data.prev_images.length > 0 ? data.prev_images : null
    return (
      <div className="card" style={{ width: "20rem" }}>
        {videSource && (
          <video
            src={videSource}
            controls
            autoPlay
            controlsList={"nodownload"}
            loop
            volume="0.25"
            style={{ maxWidth: "100%", maxHeight: "200px" }}
            ref={this.popupVideoRef}
            onCanPlay={() => this._setVolume()}
          />
        )}
        {imgSource && (
          <img src={imgSource} alt="Card image cap" className="card-img-top" />
        )}
        {prevImageSource && (
          <div onClick={e => e.preventDefault}>
            <Carousel
              afterChange={() => {}}
              autoplay={true}
              dotPosition={"top"}
            >
              {prevImageSource.map(element => {
                return (
                  <div>
                    <img
                      src={element.url}
                      alt="Card image cap"
                      className="d-flex justify-content-center"
                      style={{
                        height: "220px",
                        color: "#fff",
                        lineHeight: "160px",
                        textAlign: "center",
                        background: "#364d79",
                        maxWidth: "100%",
                        position: "relative",
                      }}
                    />
                  </div>
                )
              })}
            </Carousel>
          </div>
        )}
        <div className="card-body">
          <h5 className="card-title">{data.label}</h5>
          {data.help && data.help.description && (
            <p className="card-text">{data.help.description}</p>
          )}
        </div>
      </div>
    )
  }

  _handleClick = reset => {
    this.setState({ showConfirmationPopup: false })
    switch (this.state.clickType) {
      case "Next":
        reset()
        this.setState({ startTime: Date.now() })
        this._changeVideo(this.props.nextContent)
        break
      case "Esc":
        this._setInitialData()
        break
    }
  }

  _skipContentHanlder = (event, type) => {
    if (type === "language") {
      return this.setState({
        selectedLanguage: event.target.value,
        selectedRejectReason: null,
        selectedCompetitor: null,
        competitorLogoName: null,
        competitorLogoNameText: null,
        bannedOrganizationName: null,
      })
    } else if (type == "comptetiorLogo") {
      return this.setState({
        selectedLanguage: null,
        selectedCompetitor: null,
        competitorLogoName: event,
        competitorLogoNameText: null,
        bannedOrganizationName: null,
      })
    } else if (type == "comptetiorLogoName") {
      return this.setState({
        competitorLogoNameText: event.target.value,
      })
    } else if (type == "bannedOrganization") {
      return this.setState({
        selectedLanguage: null,
        selectedCompetitor: null,
        competitorLogoName: null,
        competitorLogoNameText: null,
        bannedOrganizationName: event,
      })
    } else {
      return this.setState({
        selectedRejectReason: event.target.value,
        selectedLanguage: null,
        selectedCompetitor: null,
        competitorLogoName: null,
        competitorLogoNameText: null,
        bannedOrganizationName: null,
      })
    }
  }

  _renderConfirmationPopup = reset => {
    return (
      <Modal
        centered
        visible={true}
        onCancel={() => this.setState({ showConfirmationPopup: false })}
        footer={null}
        size="sm"
      >
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <div>Are you sure you want to proceed without updating?</div>
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={() => this._handleClick(reset)}
              style={{
                color: "blue",
                borderColor: "blue",
                marginRight: "10px",
              }}
            >
              YES
            </Button>
            <Button
              onClick={() => this.setState({ showConfirmationPopup: false })}
              style={{ color: "blue", borderColor: "blue" }}
            >
              NO
            </Button>
          </div>
        </div>
      </Modal>
    )
  }

  _renderWarningMessages = (content, reset) => {
    let taxonomyRoutes = _.cloneDeep(this.props.taxonomyRoutes)
    if (this.state.showNotifWorthyPopup) {
      return (
        <WarningMessage
          message={
            <div className="px-3">
              <div>
                <b>Notified Content</b>
              </div>
              <div className="pt-2">
                Deactivation Not Allowed.{" "}
                <p>This content has been sent as Notification</p>
              </div>
            </div>
          }
          onCancel={() => this.setState({ showNotifWorthyPopup: false })}
          isVisible={this.state.showNotifWorthyPopup}
          buttons={
            <div className="d-flex justify-content-center pb-3">
              <MuiButton
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  if (
                    this.props.currentUser &&
                    [1, 2].includes(this.props.currentUser.userType)
                  ) {
                    this._skipContentModal()
                  }
                  this.setState({ showNotifWorthyPopup: false })
                }}
                style={{ textAlign: "left", fontSize: "16px" }}
              >
                OK
              </MuiButton>
            </div>
          }
        />
      )
    }
    if (this.state.isTagWarningVisible) {
      return (
        <WarningMessage
          message={
            <div className="px-3">
              <div>
                Detected as <b>GLAMOUR</b> content
              </div>
              <div className="pt-2">
                Tag with <b>CAUTION!!!</b>.
              </div>
            </div>
          }
          onCancel={() => this.setState({ isTagWarningVisible: false })}
          isVisible={this.state.isTagWarningVisible}
          buttons={
            <div className="d-flex justify-content-center pb-3">
              <MuiButton
                size="small"
                color="error"
                variant="contained"
                onClick={() => this.setState({ isTagWarningVisible: false })}
                style={{ textAlign: "left", fontSize: "16px" }}
              >
                OK
              </MuiButton>
            </div>
          }
        />
      )
    }
    if (this.state.deactivatedAsCopyRightReasons) {
      return (
        <WarningMessage
          message={
            <div className="px-3">
              <div>Disabled for Copy Right Violation</div>
              <div className="pt-3"> Activation Not Allowed</div>
            </div>
          }
          onCancel={() =>
            this.setState({ deactivatedAsCopyRightReasons: false })
          }
          isVisible={this.state.deactivatedAsCopyRightReasons}
          buttons={
            <div className="d-flex justify-content-center pb-3">
              <MuiButton
                color="error"
                variant="contained"
                size="small"
                onClick={() =>
                  this.setState(
                    { deactivatedAsCopyRightReasons: false },
                    this._handleNext(reset)
                  )
                }
                style={{ textAlign: "left", fontSize: "16px" }}
              >
                OK
              </MuiButton>
            </div>
          }
        />
      )
    }
    if (
      this.state.activateDeactivatedContent
      // &&
      // this.state.isShowActivateDeactivatedContent
    ) {
      return (
        <WarningMessage
          message={
            <div className="px-3">
              <div>
                Deactivated as <b>NSFW</b> content
              </div>
              <div>Tagging will activate the content</div>
              <div className="pt-2">
                Are you sure to <b>Activate</b> it.
              </div>
            </div>
          }
          onCancel={() =>
            this.setState({
              activateDeactivatedContent: false,
              // isShowActivateDeactivatedContent: false,
            })
          }
          isVisible={this.state.activateDeactivatedContent}
          buttons={
            <div className="d-flex justify-content-center pb-3">
              <MuiButton
                color="error"
                variant="contained"
                size="small"
                onClick={() =>
                  this.setState(
                    {
                      activateDeactivatedContent: false,
                      // isShowActivateDeactivatedContent: false,
                      ignoreActivateWarning: true,
                    },
                    () => this._updateContent(content, reset, false, "activate")
                  )
                }
                style={{ textAlign: "left", fontSize: "16px" }}
              >
                Activate
              </MuiButton>
              <MuiButton
                size="small"
                color="error"
                variant="contained"
                className="mx-3"
                onClick={() => {
                  this.setState({
                    activateDeactivatedContent: false,
                    // isShowActivateDeactivatedContent: false,
                  })
                  this._skipContentModal()
                }}
                style={{ textAlign: "left", fontSize: "16px" }}
              >
                Skip
              </MuiButton>
            </div>
          }
        />
      )
    }
    if (this.state.glamourTagToNsfwLessThan4) {
      return (
        <WarningMessage
          message={
            <div className="px-3">
              <div>
                <b>NSFW detected.</b>{" "}
              </div>
              <div className="pt-2">
                Have you selected the <b>correct GLAMOUR TAG</b>..?
              </div>
            </div>
          }
          onCancel={() => this.setState({ glamourTagToNsfwLessThan4: false })}
          isVisible={this.state.glamourTagToNsfwLessThan4}
          buttons={
            <div className="d-flex justify-content-center pb-3">
              <MuiButton
                color="error"
                size="small"
                variant="contained"
                onClick={() =>
                  this.setState(
                    {
                      glamourTagToNsfwLessThan4: false,
                      ignoreRatingLessThan4: true,
                    },
                    () => {
                      this.setSelectedSection("violence")
                      taxonomyRoutes.filter(
                        route => route.key === "glamour"
                      )[0].completed = true
                      this.props.dispatch(setTaxonomyRoutes({ taxonomyRoutes }))
                      this.props.dispatch(
                        setTaxonomyData({
                          currentData: this.state.currentData,
                          currentTab: "glamour",
                        })
                      )
                    }
                  )
                }
                style={{ textAlign: "left", fontSize: "16px" }}
              >
                Yes
              </MuiButton>
              <MuiButton
                size="small"
                color="error"
                variant="contained"
                className="mx-2"
                onClick={() =>
                  this.setState({
                    glamourTagToNsfwLessThan4: false,
                    retag: true,
                  })
                }
                style={{ textAlign: "left", fontSize: "16px" }}
              >
                Retag
              </MuiButton>
            </div>
          }
        />
      )
    }
    if (this.state.noGlamourTagToNsfw) {
      return (
        <WarningMessage
          message={
            <div className="px-3">
              <div>
                Detected as <b>GLAMOUR</b> content
              </div>
              <div className="pt-2">
                Glamour <b>Tag</b> not Done..
              </div>
            </div>
          }
          onCancel={() => this.setState({ noGlamourTagToNsfw: false })}
          isVisible={this.state.noGlamourTagToNsfw}
          buttons={
            <div className="d-flex justify-content-center pb-3">
              <MuiButton
                color="error"
                variant="contained"
                size="small"
                onClick={() =>
                  this.setState(
                    { noGlamourTagToNsfw: false, ignore: true },
                    () => {
                      if (
                        this.props?.currentUser?.permissions.includes(
                          "QC_TAG_CONTENT"
                        )
                      ) {
                      } else {
                        this.setSelectedSection("violence")
                        taxonomyRoutes.filter(
                          route => route.key === "glamour"
                        )[0].completed = true
                        this.props.dispatch(
                          setTaxonomyRoutes({ taxonomyRoutes })
                        )
                        this.props.dispatch(
                          setTaxonomyData({
                            currentData: this.state.currentData,
                            currentTab: "glamour",
                          })
                        )
                      }
                    }
                  )
                }
                style={{ textAlign: "left" }}
              >
                {this.props?.currentUser?.permissions.includes("QC_TAG_CONTENT")
                  ? "QC Modify"
                  : "Ignore"}
              </MuiButton>
              <MuiButton
                size="small"
                color="error"
                variant="contained"
                className="mx-3"
                onClick={() =>
                  this.setState(
                    {
                      noGlamourTagToNsfw: false,
                    },
                    () => {
                      if (
                        this.props?.currentUser?.permissions.includes(
                          //Need to check for QC
                          "QC_TAG_CONTENT"
                        )
                      ) {
                        this.setState({ ignore: true }, () =>
                          this._handleOk(reset, content)
                        )
                      } else {
                      }
                    }
                  )
                }
                style={{ textAlign: "left" }}
              >
                {this.props?.currentUser?.permissions.includes("QC_TAG_CONTENT")
                  ? "QC Ok"
                  : "Tag"}
              </MuiButton>
            </div>
          }
        />
      )
    }
  }

  _setPopuoType = (type, value, currentData) => {
    this.setState({ [type]: value, currentData })
  }

  _renderQCTag = (index, length, postMessage, preMessage) => {
    let isQC =
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("QC_TAG_CONTENT")
    return (
      <Col
        span={3}
        key={index}
        className="d-flex justify-content-between"
        style={{ marginTop: "5px" }}
      >
        <div>
          <div className="d-flex justify-content-center font-weight-bold">
            <Tag
              color={index <= 5 ? INDEX_TO_HASH_MAPPING[index] : "#990000"}
            >{`${preMessage} QC ${index + 1} ${postMessage}`}</Tag>
          </div>
        </div>
        <div>
          {index < length - 1 ? (
            <i
              className="fa fa-angle-double-right"
              style={{ paddingRight: "30px" }}
            />
          ) : (
            ""
          )}
          {isQC && index === length - 1 && (
            <i
              className="fa fa-angle-double-right"
              style={{ paddingRight: "30px" }}
            />
          )}
        </div>
      </Col>
    )
  }

  _renderQCInfo = () => {
    let qcInfo = this.props.selectedContent?.taxonomy_task_info?.qc_info
    let isQC =
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions.includes("QC_TAG_CONTENT")
    if (qcInfo && qcInfo.length > 0) {
      return (
        <Row style={{ paddingBottom: "5px" }}>
          {qcInfo.map((qc, index) => {
            return this._renderQCTag(index, qcInfo.length, "Done", "")
          })}
          {isQC &&
            this._renderQCTag(qcInfo.length, qcInfo.length, "", "Current")}
        </Row>
      )
    } else return
  }

  render() {
    let content = this.props.selectedContent
    let qcInfo = content?.taxonomy_task_info?.qc_info
    let currentIndex = qcInfo ? qcInfo.length + 1 : 0
    let isStageOne =
      this.state.sourceData?.subType &&
      this.state.sourceData.subType === "labeling_stage_one"
    return (
      // <Auxiliary loading={this.props.zoneLoading}>
      <Modal
        centered
        // className="container"
        visible={true}
        // onCancel={this.props.onCancel}
        closable={false}
        footer={null}
        width="100vw"
        bodyStyle={{ height: "100vh", padding: 0 }}
      >
        <Timer direction="forward">
          {({ start, resume, pause, stop, reset }) => (
            <Row className="d-flex">
              <Col lg="2" md="2" style={{ maxWidth: "250px" }}>
                <Row>
                  <TaxonomySideBar
                    setSelectedSection={this.setSelectedSection}
                    tabs={this.state.tabs}
                    selectedSection={this.state.selectedSection}
                    stageShow={this.state.stageWise}
                  />
                </Row>
                <Row
                  style={{
                    paddingLeft: "24px",
                    overflow: "overlay",
                  }}
                >
                  {this.state.selectedSection === "zone_approval" ||
                  this.state.selectedSection === "moderation" ? (
                    <span></span>
                  ) : (
                    <SelectedDataSection
                      selectedDetails={{ ...this.state.selectedDetails }}
                    />
                  )}
                </Row>
              </Col>
              <Col
                lg="7"
                md="7"
                style={{
                  border: currentIndex ? "solid" : "",
                  borderColor:
                    currentIndex <= 5
                      ? INDEX_TO_HASH_MAPPING[currentIndex]
                      : "#990000",
                }}
              >
                <div className="d-flex justify-content-center">
                  {content && content.notif_worthy ? (
                    <div className="text-center">
                      <Badge
                        className={`badge-soft-danger ms-1 p-2 m-2 font-size-15 fw-bold`}
                      >
                        Notify Worthy
                      </Badge>
                    </div>
                  ) : null}
                  {content && content.adsContent ? (
                    <div className="text-center">
                      <Badge
                        className={`badge-soft-danger ms-1 p-2 m-2 font-size-15 fw-bold`}
                      >
                        Ads Content
                      </Badge>
                    </div>
                  ) : null}
                </div>
                <div style={{ paddingLeft: "15px" }}>
                  {this._renderQCInfo()}
                </div>

                <TaxonomyQuestions
                  key={content.content_uuid}
                  selectedSection={this.state.selectedSection}
                  selectedDetails={_.cloneDeep(this.state.selectedDetails)}
                  tabs={this.state.tabs}
                  setSelectedDetails={this._setSelectedDetails}
                  setSelectedSection={this.setSelectedSection}
                  renderCardContent={this._renderCardContent}
                  setSelectedTab={this._setSelectedTab}
                  setPopupType={this._setPopuoType}
                  selectedContent={content}
                  onModerationUpdate={this._onModerationUpdate}
                  sourceData={this.state.sourceData}
                  enableButton={this.state.okModifySkipActive}
                  handleDone={val => this.setState({ doneButtonClicked: val })}
                  isStageOne={isStageOne}
                />
              </Col>
              <React.Fragment>
                <Col lg="3" md="3">
                  <Row>{this._generateContentPreview(content)}</Row>
                  {this._renderFooterButtons(content, reset)}
                </Col>
                {this.state.showConfirmationPopup &&
                  this._renderConfirmationPopup(reset)}{" "}
                {/*Esc in content component need to be handled*/}
                {this.state.activateDeactivatedContent &&
                  this._renderWarningMessages(content, reset)}
                {this.state.noGlamourTagToNsfw &&
                  isStageOne &&
                  this._renderWarningMessages(content, reset)}
                {/*Check for QC*/}
                {this.state.glamourTagToNsfwLessThan4 &&
                  isStageOne &&
                  this._renderWarningMessages(content, reset)}{" "}
                {/*Done*/}
                {this.state.isTagWarningVisible &&
                  isStageOne &&
                  this._renderWarningMessages(content)}{" "}
                {/*Done*/}
                {this.state.showEntityPopup &&
                  this._renderEntitiesSelectionPopup()}{" "}
                {/*No Clue*/}
                {this.state.showSkipContentModal && (
                  <SkipModel
                    currentUser={this.props.currentUser}
                    content={content}
                    reset={reset}
                    selectedRejectReason={this.state.selectedRejectReason}
                    competitorLogoName={this.state.competitorLogoName}
                    competitorLogoNameText={this.state.competitorLogoNameText}
                    bannedOrganizationName={this.state.bannedOrganizationName}
                    selectedLanguage={this.state.selectedLanguage}
                    skipContentHanlder={this._skipContentHanlder}
                    skipContent={this._skipContent}
                    onCancel={() => {
                      this.setState({
                        showSkipContentModal: false,
                        selectedLanguage: null,
                        selectedRejectReason: null,
                        selectedCompetitor: null,
                      })
                    }}
                  />
                )}
                {this.state.deactivatedAsCopyRightReasons &&
                  this._renderWarningMessages(content, reset)}
                {/* put next() after ok*/}
                {this.state.showNotifWorthyPopup &&
                  this._renderWarningMessages(content, reset)}
              </React.Fragment>
            </Row>
          )}
        </Timer>
      </Modal>
      // </Auxiliary>
    )
  }
}
function mapStateToProps(store, ownProps) {
  return {
    total: _.get(store, "content.total"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    loading: _.get(store, "content.loading"),
    eventList: _.get(store, "content.eventList"),
    taxonomyRoutes: _.get(store, "taxonomy.taxonomyRoutes"),
    zoneList: _.get(store, "zone.zoneList"),
    allZonesList: _.get(store, "zone.allZoneList"),
    zoneLoading: _.get(store, "zone.loading"),
  }
}

export default connect(mapStateToProps)(TaxonomyDashboard)
