import React from "react"
import { Upload, message, Checkbox, DatePicker } from "antd"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { UploadOutlined } from "@ant-design/icons"
import _ from "lodash"
import ValidatorForm, {
  TextField,
  Select,
  Switch,
  ChipsInput,
} from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import "./cameraAsset.style.css"
import moment from "moment"
import dayjs from "dayjs"

export default class VideoAssetUpsertForm extends ErrorBoundComponent {
  state = {
    formData: {},
    removeCoverImageTwo: false,
  }

  componentDidMount() {
    let videoAsset = this.props.videoAsset
    if (!_.isEmpty(videoAsset)) {
      let formData = {
        id: videoAsset.id,
        category: videoAsset.category,
        name: videoAsset.name,
        desc: videoAsset.desc,
        tags: videoAsset.tags,
        version: videoAsset.version,
        minAppVersion: videoAsset.minAppVersion,
        packageSize: videoAsset.packageSize,
        supportedAspectRatio: videoAsset.supportedAspectRatio,
        idx: videoAsset.idx,
        viewOrder: videoAsset.viewOrder,
        filterType: videoAsset.filterType,
        userAction: videoAsset.userAction,
        alias: videoAsset.alias,
        notShowInThumbnail: videoAsset.notShowInThumbnail,
        hasSound: videoAsset.hasSound,
        isArchived: videoAsset.isArchived,
        label: videoAsset.label,
        validTill: videoAsset.validTill,
        taggedVideos: videoAsset.taggedVideos ? videoAsset.taggedVideos : [],
      }
      this.setState({ formData })
    }
  }

  _uploadFile = (fileObj, type) => {
    if (fileObj.file) {
      switch (type) {
        case "animatedFile":
          this.setState({
            animatedFileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            animatedFile: fileObj.file,
          })
          break
        case "packageFile":
          this.setState({
            packageFileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            packageFile: fileObj.file,
          })
          break
        case "coverImageOne":
          this.setState({
            coverImage1FileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            coverImage1: fileObj.file,
          })
          break
        case "coverImageTwo":
          this.setState({
            coverImage2FileList: [
              {
                uid: "-1",
                name: fileObj.file.name,
                status: "done",
                url: "",
              },
            ],
            coverImage2: fileObj.file,
          })
          break
      }
    }
  }

  _onRemoveFile = type => {
    switch (type) {
      case "animatedFile":
        this.setState({
          animatedFileList: [],
          animatedFile: null,
        })
      case "packageFile":
        this.setState({
          packageFileList: [],
          packageFile: null,
        })
      case "coverImageOne":
        this.setState({
          coverImage1FileList: [],
          coverImage1: null,
        })
        break
      case "coverImageTwo":
        this.setState({
          coverImage2FileList: [],
          coverImage2: null,
        })
      default:
        break
    }
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value
    this.setState({ formData })
  }

  _form = () => {
    let tabName =
      this.props.queryParams && this.props.queryParams.tabName
        ? this.props.queryParams.tabName
        : ""
    let tabType =
      this.props.queryParams && this.props.queryParams.tabType
        ? this.props.queryParams.tabType
        : ""
    return (
      <ValidatorForm
        onSubmit={this._onSubmit}
        layout={"vertical"}
        {...this._formLayout}
      >
        <Row>
          <Col sm={12} lg={6}>
            <TextField
              label="ID"
              defaultValue={this.state.formData.id}
              field="id"
              allowClear={true}
              disabled={this.props.formAction === "edit"}
              validations={["required"]}
              errors={["This field is required"]}
              onChange={value => this._handleFieldChange("id", value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="Name"
              defaultValue={this.state.formData.name}
              onChange={value => this._handleFieldChange("name", value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="Description"
              defaultValue={this.state.formData.desc}
              onChange={value => this._handleFieldChange("desc", value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="SDK Type"
              defaultValue={this.state.formData.sdkType}
              onChange={value => this._handleFieldChange("sdkType", value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="View Order"
              defaultValue={this.state.formData.viewOrder}
              inputType={"number"}
              validations={["required"]}
              errors={["This field is required"]}
              onChange={value => this._handleFieldChange("viewOrder", value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="Label"
              defaultValue={this.state.formData.label}
              field="label"
              allowClear={true}
              errors={["This field is required"]}
              onChange={value => this._handleFieldChange("label", value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <div>
              Valid Till
              <DatePicker
                defaultValue={
                  this.props.videoAsset.validTill
                    ? dayjs.unix(
                        this.props.videoAsset.validTill / 1000,
                        "YYYY-MM-DD"
                      )
                    : null
                }
                format="YYYY-MM-DD HH:mm"
                showTime
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                placeholder="Valid Till"
                onChange={(date, dateString) => {
                  this._handleFieldChange(
                    "validTill",
                    !_.isEmpty(dateString)
                      ? moment(dateString).format("x")
                      : null
                  )
                }}
              />
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <Select
              label="Filter Type"
              defaultValue={this.state.formData.filterType}
              options={[
                { label: "EFFECT", value: "EFFECT" },
                { label: "SIMPLE_FILTER", value: "SIMPLE_FILTER" },
              ]}
              onChange={value => this._handleFieldChange("filterType", value)}
              validations={this.props.typeId === 2 ? ["required"] : []}
              errors={["This field is required"]}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="User Action"
              defaultValue={this.state.formData.userAction}
              onChange={value => this._handleFieldChange("userAction", value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <TextField
              label="Alias"
              defaultValue={this.state.formData.alias}
              onChange={value => this._handleFieldChange("alias", value)}
            />
          </Col>
          <Col sm={12} md={6}>
            <ChipsInput
              label={"Tagged Videos"}
              placeholderLabel="Tagged Videos"
              onChange={value => this._handleFieldChange("taggedVideos", value)}
              defaultValue={this.state.formData.taggedVideos}
              className="creator-form"
            />
          </Col>
          <Col sm={6} lg={2}>
            <Switch
              label="Has Sound"
              defaultValue={this.state.formData.hasSound}
              onChange={value => this._handleFieldChange("hasSound", value)}
            />
          </Col>
          <Col sm={12} lg={2}>
            <Switch
              label="Is Archived"
              defaultValue={this.state.formData.isArchived}
              onChange={value => this._handleFieldChange("isArchived", value)}
            />
          </Col>
          <Col sm={12} lg={2} style={{ alignSelf: "center" }}>
            <Checkbox
              checked={this.state.formData.notShowInThumbnail}
              onChange={e =>
                this._handleFieldChange("notShowInThumbnail", e.target.checked)
              }
            >
              Do not show in thumbnail
            </Checkbox>
          </Col>
          {tabName === "GAME_BASIC" && tabType === "BASIC" && (
            <Col sm={6} lg={3} style={{ marginTop: "1rem" }}>
              <Upload
                showUploadList={true}
                listType="picture"
                onRemove={() => this._onRemoveFile("animatedFile")}
                defaultFileList={this.state.animatedFileList}
                fileList={this.state.animatedFileList}
                customRequest={fileObj =>
                  this._uploadFile(fileObj, "animatedFile")
                }
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                {this.state.animatedFileList &&
                this.state.animatedFileList.length >= 1 ? null : (
                  <Button>
                    <UploadOutlined />{" "}
                    {this.props.formAction !== "edit"
                      ? `Upload Animated File`
                      : `Update Animated File`}{" "}
                  </Button>
                )}
              </Upload>
            </Col>
          )}
          <Col sm={6} lg={3} style={{ marginTop: "1rem" }}>
            <Upload
              showUploadList={true}
              listType="picture"
              onRemove={() => this._onRemoveFile("packageFile")}
              defaultFileList={this.state.packageFileList}
              fileList={this.state.packageFileList}
              customRequest={fileObj =>
                this._uploadFile(fileObj, "packageFile")
              }
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              {this.state.packageFileList &&
              this.state.packageFileList.length >= 1 ? null : (
                <Button>
                  <UploadOutlined />{" "}
                  {this.props.formAction !== "edit"
                    ? `Upload Package File`
                    : `Update Package File`}{" "}
                </Button>
              )}
            </Upload>
          </Col>
          <Col sm={6} lg={3} style={{ marginTop: "1rem" }}>
            <Upload
              showUploadList={true}
              listType="picture"
              onRemove={() => this._onRemoveFile("coverImageOne")}
              defaultFileList={this.state.coverImage1FileList}
              fileList={this.state.coverImage1FileList}
              customRequest={fileObj =>
                this._uploadFile(fileObj, "coverImageOne")
              }
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              {this.state.coverImage1FileList &&
              this.state.coverImage1FileList.length >= 1 ? null : (
                <Button>
                  <UploadOutlined />{" "}
                  {this.props.formAction !== "edit"
                    ? `Upload Cover Image`
                    : `Update Cover Image`}{" "}
                </Button>
              )}
            </Upload>
          </Col>
          <Col
            sm={6}
            lg={3}
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            <Upload
              showUploadList={true}
              listType="picture"
              onRemove={() => this._onRemoveFile("coverImageTwo")}
              defaultFileList={this.state.coverImage2FileList}
              fileList={this.state.coverImage2FileList}
              customRequest={fileObj =>
                this._uploadFile(fileObj, "coverImageTwo")
              }
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              {this.state.coverImage2FileList &&
              this.state.coverImage2FileList.length >= 1 ? null : (
                <Button>
                  <UploadOutlined />{" "}
                  {this.props.formAction !== "edit"
                    ? `Upload Cover Image 2`
                    : `Update Cover Image 2`}{" "}
                </Button>
              )}
            </Upload>
          </Col>
          {this.props.formAction === "edit" && (
            <Col style={{ alignSelf: "center" }}>
              <Checkbox
                checked={this.state.removeCoverImageTwo}
                onChange={e =>
                  this.setState({ removeCoverImageTwo: e.target.checked })
                }
              >
                Remove Cover Image 2
              </Checkbox>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col className="d-flex justify-content-end" span={24}>
            <div className="flex">
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  size="default"
                  type="submit"
                  disabled={this.state.actionLoading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                >
                  {this.props.formAction === "create" ? "Create" : "Update"}
                </Button>
                <Button
                  color="danger"
                  size="default"
                  disabled={this.state.actionLoading}
                  onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }}
                  onClick={() => this.props.onCancel()}
                  className="mx-2"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _onSubmit = ({ formData, errors }) => {
    if (errors) {
      return
    }

    if (
      _.isEmpty(this.state.packageFile) &&
      this.props.formAction === "create"
    ) {
      message.error("Please upload package file!!")
      return
    }

    if (
      _.isEmpty(this.state.coverImage1) &&
      this.props.formAction === "create"
    ) {
      message.error("Please upload cover image!!")
      return
    }

    const data = new FormData()

    if (!_.isEmpty(this.state.packageFile)) {
      data.append("packageUrl", this.state.packageFile)
    }

    if (!_.isEmpty(this.state.animatedFile)) {
      data.append("animatedUrl", this.state.animatedFile)
    }

    if (!_.isEmpty(this.state.coverImage1)) {
      data.append("coverUrl", this.state.coverImage1)
    }

    if (
      !_.isEmpty(this.state.coverImage2) &&
      this.state.removeCoverImageTwo === false
    ) {
      data.append("coverUrl2", this.state.coverImage2)
    }

    if (!_.isEmpty(this.state.taggedVideos)) {
      formData.taggedVideos = taggedVideos.join(",")
    }

    Object.keys(this.state.formData).forEach(key => {
      if (
        this.state.formData[key] !== undefined &&
        this.state.formData[key] !== ""
      ) {
        data.append(key, this.state.formData[key])
      }
    })
    this.props.onSubmit(data)
  }

  _render = () => {
    return <Auxiliary loading={this.props.loading}>{this._form()}</Auxiliary>
  }
}
