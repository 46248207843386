import { createSlice } from "@reduxjs/toolkit"

const audioSlice = createSlice({
  name: "audio",
  initialState: {
    audioList: [],
    audioOrder: [],
    refetchAudioList: false,
    loading: false,
    audioTabsBasedOnLanguage: {},
    playList: [],
    responseStatus: false,
  },
  reducers: {
    getAudioDetails(state, action) {
      return {
        ...state,
        loading: true,
        audioList: [],
        refetchAudioList: false,
      }
    },
    getAudioDetailsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        audioList: payload.data.data,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        refetchData: false,
      }
    },
    getAudioTabs(state, action) {
      return {
        ...state,
        loadin: true,
        audioTabsList: [],
        audioTabsBasedOnLanguage: {},
      }
    },
    getAudioTabsSuccess(state, { payload }) {
      let audioTabsList =
        payload.data &&
        payload.data.rows &&
        payload.data.rows.map(tab => {
          return {
            displayName: tab.displayName,
            categoryKey: tab.categoryKey,
            langWiseDetails: _.map(tab.langWiseDetails, (langObj, key) => {
              return {
                lang: key,
                ...langObj,
              }
            }).sort((a, b) => a.viewOrder - b.viewOrder),
          }
        })
      const audioTabsBasedOnLanguage =
        payload.data &&
        payload.data.rows &&
        payload.data.rows.reduce((previousValue, currentValue) => {
          Object.keys(currentValue.langWiseDetails).map(item => {
            if (currentValue.langWiseDetails[item]["status"] == "Active") {
              if (Array.isArray(previousValue[item])) {
                previousValue[item] = [
                  ...previousValue[item],
                  {
                    displayName: currentValue.displayName,
                    status: currentValue.langWiseDetails[item]["status"],
                    viewOrder: currentValue.langWiseDetails[item]["viewOrder"],
                    label: currentValue.displayName,
                    value: currentValue.categoryKey,
                  },
                ]
              } else {
                previousValue[item] = [
                  {
                    displayName: currentValue.displayName,
                    viewOrder: currentValue.langWiseDetails[item]["viewOrder"],
                    label: currentValue.displayName,
                    value: currentValue.categoryKey,
                  },
                ]
              }
            }
          })
          return previousValue
        }, {})
      return {
        ...state,
        audioTabsList: _.sortBy(audioTabsList, "displayName"),
        audioTabsBasedOnLanguage: audioTabsBasedOnLanguage,
        refetchData: false,
      }
    },
    upsertAudio(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertAudioSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchAudioList: true,
      }
    },
    uploadBulkAudio(state) {
      return {
        ...state,
        loading: true,
      }
    },
    uploadBulkAudioSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchAudioList: true,
      }
    },

    updateAudioStatus(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateAudioStatusSuccess(state) {
      return {
        ...state,
        loading: false,
        refetchAudioList: true,
      }
    },
    updateAudioVisibility(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateAudioVisibilitySuccess(state) {
      return {
        ...state,
        loading: false,
        refetchAudioList: true,
      }
    },
    getPlayList(state, action) {
      return {
        ...state,
        playList: [],
      }
    },
    getPlayListSuccess(state, { payload }) {
      return {
        ...state,
        playList: payload.data || [],
      }
    },

    resetState() {
      return {
        audioList: [],
        audioOrder: [],
        refetchAudioList: false,
        loading: false,
        audioTabsBasedOnLanguage: {},
        responseStatus: false,
      }
    },
  },
})

export const {
  getAudioDetails,
  getAudioDetailsSuccess,
  getAudioTabs,
  getAudioTabsSuccess,
  upsertAudio,
  upsertAudioSuccess,
  uploadBulkAudio,
  uploadBulkAudioSuccess,
  updateAudioStatus,
  updateAudioStatusSuccess,
  updateAudioVisibility,
  updateAudioVisibilitySuccess,
  getPlayList,
  getPlayListSuccess,
  resetState,
} = audioSlice.actions

export default audioSlice.reducer
