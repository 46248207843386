import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import toastr from "toastr"
import { AES, enc } from "crypto-js"
import { getCurrentUser } from "../../appRedux/slices/currentUser"
//redux
import { useSelector, useDispatch } from "react-redux"
import {
  login,
  googlelogin,
  getClientId,
} from "../../clientServices/authService"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import GoogleLogin from "react-google-login"
// import images
import logo from "../../assets/images/logo.svg"
import "./icon.css"

const Login = () => {
  const dispatch = useDispatch()

  const [clientId, setClientId] = useState(undefined)
  const [message, setMessage] = useState(undefined)

  useEffect(() => {
    ;(async () => {
      let clientId = await getClientId()
      if (clientId) {
        var bytes = AES.decrypt(clientId, "googleClientId") // Decrypt the client ID
        var originalClientId = bytes.toString(enc.Utf8)
        setClientId(originalClientId)
      }
    })()
  }, [])

  const { error } = useSelector(state => ({
    error: false,
  }))

  const { hostName } = useSelector(state => state.currentUser)

  const normalLogin =
    hostName?.includes("localhost") ||
    hostName?.includes("cms.myjosh.in") ||
    hostName?.includes("qa-jot.myjosh.in") ||
    hostName?.includes("jot-gke.myjosh.in")
      ? true
      : false

  const responseGoogle = async response => {
    let emailArray =
      response.profileObj && response.profileObj.email
        ? response.profileObj.email.split("@")
        : []
    let [email, domain] = emailArray
    if (response && response.profileObj) {
      let responsep = await googlelogin({ token: response.tokenId })
      if (
        responsep &&
        responsep.code &&
        responsep.code === 500 &&
        responsep.message
      ) {
        setMessage(responsep.message)
      }
      dispatch(getCurrentUser())
    }
  }

  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    try {
      await login(values)
      dispatch(getCurrentUser())
    } catch (error) {
    } finally {
    }
  }

  if (message) {
    toastr.error(message)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Josh Operations Tool</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome !!</h5>
                        <p>Sign in to continue to Josh Operations Tool.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 mb-5">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="px-2">
                    {normalLogin && (
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            value=""
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>

                        <div className="mb-4">
                          <AvField
                            name="password"
                            label="Password"
                            value=""
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block py-2"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                        {/* <Divider>
                        <span className="font-size-13 font-weight-semibold">
                          OR
                        </span>
                      </Divider> */}

                        {/* <div className="mt-3 text-center">
                        <h5 className="font-size-14 mb-3">Sign in </h5>
                      </div> */}

                        {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                      </AvForm>
                    )}
                    {!normalLogin && (
                      <div>
                        {clientId && (
                          <GoogleLogin
                            clientId={clientId}
                            buttonText="Login with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy="single_host_origin"
                            render={renderProps => (
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-danger btn-block"
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.disabled}
                                >
                                  <div className="d-flex justify-content-center">
                                    <div>
                                      <i className="fab fa-google fa-2x px-2"></i>
                                    </div>
                                    <div className="pt-1">
                                      Log In with Google
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 text-center">
                <p>
                  © {new Date().getFullYear()} Josh Operations Tool
                  <i className="mdi mdi-heart text-danger mx-1" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
