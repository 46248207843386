import React, { useEffect, useState, createRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import _, { isEmpty, map } from "lodash"
import Select from "react-select"
import moment from "moment"
import { Button, Card, CardBody, Col, Table, Row, Spinner } from "reactstrap"
import classnames from "classnames"
import {
  getIssuesComments,
  createIssueComment,
} from "../../appRedux/slices/issuesComments"
import { getUserListByFilters } from "../../appRedux/slices/cmsUser"
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "util/Auxiliary"
import { NO_PERMISSION_MESSAGE } from "constants/uiConstants"
import toastr from "toastr"
import { MentionsInput, Mention } from "react-mentions"
import defaultStyle from "./commentMentionStyle"
import "./comment.css"

class CommentDetailsPage extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      messages: this.props.commentData,
      taggedUserList: [],
      childTaggedUserList: [],
      groups: [],
      activeTab: "1",
      curMessage: "",
      issuesData: undefined,
      curChildMessage: "",
      commentedBy: "",
    }
  }

  componentDidMount() {
    let filters = this.props.filters
    if (this.state.commentedBy) {
      filters.commentedBy = this.state.commentedBy
    }
    this.props.dispatch(getIssuesComments(filters))
  }

  componentDidUpdate() {
    if (this.props.refetchData) {
      let filters = _.clone(this.props.filters)
    }
    // if (
    //   this.props.moderatorList &&
    //   this.props.moderatorList.length === 0 &&
    //   this.props.commentsList &&
    //   !this.props.cmsUsersoading
    // ) {
    //   const ids = this.props.commentsList
    //     .map(comment => comment.commentedBy)
    //     .filter(function (value, index, array) {
    //       return array.indexOf(value) === index
    //     })
    //   if (ids && ids.length > 0) {
    //     this.props.dispatch(getUserListByFilters({ ids }))
    //   }
    // }
  }

  _renderInfoTable = () => {
    const issuesData = this.state.issuesData
    return (
      <Table className=" mb-0 table-striped">
        <tbody>
          <tr className="text-start">
            <th scope="row">Os Version:</th>
            <td>{issuesData.osVersion}</td>
          </tr>
          <tr className="text-start">
            <th scope="row">App Version:</th>
            <td>{issuesData.appVersion}</td>
          </tr>
          <tr className="text-start">
            <th scope="row">Phone Make:</th>
            <td>{issuesData.phoneMake}</td>
          </tr>
          <tr className="text-start">
            <th scope="row">Phone Model:</th>
            <td>{issuesData.phoneModel}</td>
          </tr>
        </tbody>
      </Table>
    )
  }

  setcurMessage = message => {
    this.setState({
      curMessage: message,
    })
  }
  setcurChildMessage = message => {
    this.setState({
      curChildMessage: message,
    })
  }

  _getMessageTree = messages => {
    let commentList = messages.reduce((acc, curr) => {
      return { ...acc, [curr.commentId]: curr }
    }, {})
    let parentList = messages.filter(message => !message.parentCommentId)
    let childList = messages.filter(message => message.parentCommentId)

    let childGrouping = childList.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.parentCommentId]: acc[curr.parentCommentId]
          ? [...acc[curr.parentCommentId], curr]
          : [curr],
      }
    }, {})
    let messageTree = []

    parentList.forEach(element => {
      if (childGrouping[element.commentId]) {
        messageTree.push({
          ...commentList[element.commentId],
          childList: childGrouping[element.commentId],
        })
      } else messageTree.push(commentList[element.commentId])
    })

    return messageTree
  }

  refetchCommentData = () => {
    let filters = this.props.filters
    if (this.state.commentedBy) {
      filters.commentedBy = this.state.commentedBy
    }
    this.props.dispatch(getIssuesComments(filters))
  }

  _getModeratorEmail = commentedBy => {
    let [moderator] = this.props.moderatorList.filter(
      moderator => moderator.userId === commentedBy
    )
    if (moderator) {
      return (
        <Link
          to={`/users/manage?currentPage=1&pageSize=10&searchText=${commentedBy}&searchType=userId`}
        >
          {moderator.email}
        </Link>
      )
    }
    return ""
  }

  _renderCommentList = message => {
    const userList =
      this.props?.cmsUserList?.map(user => ({
        id: user.email,
        display: user.name || user.email,
      })) || []
    if (message.childList) {
      return (
        <li
          key={"test_k" + message.commentId}
          className={
            message.commentedBy === this.props.currentUser.email ? "" : ""
          }
        >
          <div className="conversation-list" style={{ width: "100%" }}>
            <div className="d-flex">
              <div
                className="ctext-wrap"
                style={{ width: "100%", borderRadius: "unset" }}
              >
                <div>
                  <p className="chat-time mb-2">
                    <i
                      className="fa fa-user pt-1 px-1 fa-1x border-1 text-light"
                      style={{
                        border: "solid",
                        marginRight: "5px",
                        backgroundColor: "#326ca6",
                      }}
                    />
                    <Link
                      to={`/users/manage?currentPage=1&pageSize=10&searchText=${message.commentedBy}&searchType=email`}
                    >
                      {message.commentedBy}
                    </Link>
                    <i className="bx bx-time-five align-middle me-1 mx-2" />
                    {moment(message.commentedOn).format("DD-MM-YY hh:mm")}
                  </p>
                  <span>{message.commentText}</span>
                </div>
              </div>
              <div
                role="button"
                onClick={() =>
                  this.setState({
                    showTextArea: true,
                    commentParentId: message.commentId,
                  })
                }
              >
                <i className="fas fa-plus-circle fa-2x px-1 py-4" />
              </div>
            </div>
            {this.state.showTextArea &&
              this.state.commentParentId === message.commentId && (
                <div id={this.state.commentParentId}>
                  <div className="p-3 chat-input-section">
                    <Row>
                      <Col>
                        <div className="position-relative">
                          <MentionsInput
                            markup="@[__name__](__email__)"
                            value={this.state.curChildMessage || ""}
                            onChange={event =>
                              this.setcurChildMessage(event.target.value)
                            }
                            style={defaultStyle}
                            className="mentions__suggestions__list"
                            allowSuggestionsAboveCursor={true}
                          >
                            <Mention trigger="@" data={userList || []} />
                          </MentionsInput>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          type="button"
                          color="primary"
                          onClick={() => {
                            if (
                              !this.props.currentUser?.permissions?.includes(
                                "EDIT_BROWSE_ISSUE"
                              )
                            ) {
                              toastr.error(NO_PERMISSION_MESSAGE)
                              return
                            }
                            this.props.addComment(
                              this.state.curChildMessage,
                              this.state.commentParentId
                            )
                            this.setcurChildMessage("")
                            this.setState({ showTextArea: false })
                          }}
                          className="btn btn-primary chat-send w-md "
                        >
                          <span className="d-none d-sm-inline-block me-2">
                            Add
                          </span>{" "}
                          <i className="fa fa-plus-circle" />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            {message.childList.map(child => {
              return (
                <div
                  className="ctext-wrap my-1"
                  style={{
                    // backgroundColor: "#b5d6ec",
                    marginLeft: "55px",
                    borderRadius: "unset",
                    marginRight: "33px",
                  }}
                >
                  <p className="chat-time mb-2">
                    <i
                      className="fa fa-user pt-1 px-1 fa-1x border-1 text-light"
                      style={{
                        border: "solid",
                        marginRight: "5px",
                        backgroundColor: "#326ca6",
                      }}
                    />
                    <Link
                      to={`/users/manage?currentPage=1&pageSize=10&searchText=${child.commentedBy}&searchType=email`}
                    >
                      {child.commentedBy}
                    </Link>
                    <i className="bx bx-time-five align-middle me-1 mx-2" />
                    {moment(child.commentedOn).format("DD-MM-YY hh:mm")}
                  </p>
                  <span>{child.commentText}</span>
                </div>
              )
            })}
          </div>
        </li>
      )
    } else
      return (
        <li
          key={"test_k" + message.commentId}
          className={
            message.commentedBy === this.props.currentUser.email ? "" : ""
          }
        >
          <div className="conversation-list" style={{ width: "100%" }}>
            <div className="d-flex">
              <div
                className="ctext-wrap"
                style={{ width: "100%", borderRadius: "unset" }}
              >
                <div>
                  <p className="chat-time mb-2">
                    <i
                      className="fa fa-user pt-1 px-1 fa-1x border-1 text-light"
                      style={{
                        border: "solid",
                        marginRight: "5px",
                        backgroundColor: "#326ca6",
                      }}
                    />
                    <Link
                      to={`/users/manage?currentPage=1&pageSize=10&searchText=${message.commentedBy}&searchType=email`}
                    >
                      {message.commentedBy}
                    </Link>
                    <i className="bx bx-time-five align-middle me-1 mx-2" />
                    {moment(message.commentedOn).format("DD-MM-YY hh:mm")}
                  </p>
                  <span>{message.commentText}</span>
                </div>
              </div>
              <div
                role="button"
                onClick={() =>
                  this.setState({
                    showTextArea: true,
                    commentParentId: message.commentId,
                  })
                }
              >
                <i className="fas fa-plus-circle fa-2x px-1 py-4" />
              </div>
            </div>
            {this.state.showTextArea &&
              this.state.commentParentId === message.commentId && (
                <div id={this.state.commentParentId}>
                  <div className="p-3 chat-input-section">
                    <Row>
                      <Col>
                        <div className="position-relative">
                          <MentionsInput
                            markup="@[__name__](__email__)"
                            value={this.state.curChildMessage || ""}
                            onChange={event =>
                              this.setcurChildMessage(event.target.value)
                            }
                            style={defaultStyle}
                            className="mentions__suggestions__list"
                            allowSuggestionsAboveCursor={true}
                          >
                            <Mention trigger="@" data={userList || []} />
                          </MentionsInput>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          type="button"
                          color="primary"
                          onClick={() => {
                            if (
                              !this.props.currentUser?.permissions?.includes(
                                "EDIT_BROWSE_ISSUE"
                              )
                            ) {
                              toastr.error(NO_PERMISSION_MESSAGE)
                              return
                            }
                            this.props.addComment(
                              this.state.curChildMessage,
                              this.state.commentParentId
                            )
                            this.setcurChildMessage("")
                            this.setState({ showTextArea: false })
                          }}
                          className="btn btn-primary "
                        >
                          <span className="d-none d-sm-inline-block me-2">
                            Add
                          </span>{" "}
                          <i className="fa fa-plus-circle" />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
          </div>
        </li>
      )
  }

  _getOptions = () => {
    let list =
      this.props.moderatorList && this.props.moderatorList.length > 0
        ? this.props.moderatorList.map(moderator => {
            return { label: moderator.email, value: moderator.userId }
          })
        : []
    return list
  }

  render() {
    const userList =
      this.props.cmsUserList.map(user => ({
        id: user.email,
        display: user.name || user.email,
      })) || []

    return (
      <div className="w-100 user-chat">
        <Card>
          <div className="px-4 pt-4 comments">
            <Row>
              <Col md="4" xs="9">
                <h5 className="font-size-18">Comments</h5>
              </Col>
              <Col md="8" xs="3">
                <ul className="list-inline user-chat-nav text-end mb-0 ">
                  {/* <div style={{ width: "100%" }}>
                  <Select
                    isMulti={false}
                    onChange={value =>
                      this.setState(
                        { commentedBy: value.value.toString() },
                        () => this.refetchCommentData()
                      )
                    }
                    defaultValue={this.state.commentedBy}
                    options={[
                      { label: "All Comments", value: "" },
                      ...this._getOptions(),
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div> */}
                  <li
                    className="list-inline-item d-none d-sm-inline-block"
                    role="button"
                    onClick={() => this.refetchCommentData()}
                  >
                    <i className="fa fa-undo px-2 py-2" />
                  </li>
                </ul>
              </Col>
            </Row>
          </div>

          <div>
            <div className="chat-conversation p-3">
              <ul className="list-unstyled">
                <PerfectScrollbar
                  style={{ height: "fit-content", maxHeight: "470px" }}
                >
                  {this.props.loading ? (
                    <div
                      style={{ width: "100%" }}
                      className="d-flex justify-content-center"
                    >
                      <Spinner type="grow" color="warning" />
                    </div>
                  ) : (
                    this.props.commentsList &&
                    this._getMessageTree(this.props.commentsList).map(
                      (message, i) => this._renderCommentList(message)
                    )
                  )}
                </PerfectScrollbar>
              </ul>
            </div>
          </div>
          <div className="p-3 chat-input-section">
            <Row>
              <Col>
                <div className="position-relative">
                  {/* <input
                    type="text"
                    value={this.state.curMessage}
                    onKeyPress={this.onKeyPress}
                    onChange={e => this.setcurMessage(e.target.value)}
                    className="form-control"
                    placeholder="Add Comment......"
                  /> */}
                  <MentionsInput
                    markup="@[__name__](__email__)"
                    value={this.state.curMessage || ""}
                    onChange={event => this.setcurMessage(event.target.value)}
                    style={defaultStyle}
                    className="mentions__suggestions__list"
                    allowSuggestionsAboveCursor={true}
                  >
                    <Mention trigger="@" data={userList || []} />
                  </MentionsInput>
                </div>
              </Col>
              <Col className="col-auto">
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    if (
                      !this.props.currentUser?.permissions?.includes(
                        "EDIT_BROWSE_ISSUE"
                      )
                    ) {
                      toastr.error(NO_PERMISSION_MESSAGE)
                      return
                    }
                    this.props.addComment(this.state.curMessage)
                    this.setcurMessage("")
                  }}
                  className="btn btn-primary chat-send w-md "
                >
                  <span
                    className="d-none d-sm-inline-block me-2"
                    style={{ borderRadius: "unset" }}
                  >
                    Add
                  </span>{" "}
                  <i className="fa fa-plus-circle" />
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "issueComments.loading"),
    issuesDataLoading: _.get(store, "creatorIssues.loading"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "cmsUser.total"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.user"),
    cmsUsersoading: _.get(store, "cmsUser.loading"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    refetchData: _.get(store, "cmsUser.refetchData"),
    commentsList: _.get(store, "issueComments.commentsList"),
    issuesList: _.get(store, "creatorIssues.issuesList"),
    cmsUserList: _.get(store, "cmsUser.user"),
    // userList: _.get(store, "appUser.user"),
  }
}

export default connect(mapStateToProps)(CommentDetailsPage)
