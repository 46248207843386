import React, { useState } from "react"
import Select from "react-select"
import _ from "lodash"
import moment from "moment"
import toastr from "toastr"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import ValidatorForm, { ChipsInput, Switch } from "../ValidatorForm"
import { AvForm, AvGroup } from "availity-reactstrap-validation"
import {
  PERMISSIONS_ARRAY,
  LANGUAGES,
  NO_PERMISSION_MESSAGE,
} from "../../constants/uiConstants"
import { Offsymbol, OnSymbol } from "util/switchButtonUtil"
import { MultiSelect, DateTimeRangePicker } from "../ValidatorForm"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

const targetAudienceOptions = [
  { label: "Creator", value: "creator" },
  { label: "Consumer", value: "consumer" },
]

const UpsertEntity = props => {
  const [params, setPatams] = useState({})
  const _onSubmit = formData => {
    const {
      dateRange,
      langCode,
      status,
      audioId,
      hashtag,
      audioList,
      hashtagList,
      ...rest
    } = params
    const language =
      langCode && langCode.length > 0 && langCode.map(lang => lang.value)
    const [startDate, endDate] = dateRange || []
    let data = {}
    if ((!startDate || !endDate) && props.action !== "edit") {
      return toastr.error("Please Select valid Start and End Dates")
    }
    data = {
      ...rest,
      audioId: audioId
        ? audioId
        : audioList && audioList.length > 0
        ? audioList[0]
        : undefined,
      hashtag: hashtag
        ? hashtag
        : hashtagList && hashtagList.length > 0
        ? hashtagList[0]
        : undefined,
      audioList: audioList ? audioList : audioId ? [audioId] : undefined,
      hashtagList: hashtagList ? hashtagList : hashtag ? [hashtag] : undefined,
      startDate: startDate && startDate.valueOf(),
      endDate: endDate && endDate.valueOf(),
      langCode: language,
      status: status,
    }
    if (props.action === "edit") {
      if (!props.data.audio_list && props.data.audio_id && !data.audioList) {
        data["audioList"] = _getDefaultAudio()
      }
      if (!props.data.hashtag_list && props.data.hashtag && !data.hashtagList) {
        data["hashtagList"] = _getDefaultHashtag()
      }
      if (props.data.audio_list && !props.data.audio_id) {
        data["audioId"] = props.data.audio_list[0]
      }
      if (props.data.hashtagList && !props.data.hashtag) {
        data["hashatg"] = props.data.hashtag_list[0]
      }
      data = { ...data, entityUuid: props.data.entity_uuid }
    }
    props.onSubmit(data)
    props.oncancel()
  }

  const _getDefaultAudio = () => {
    let list = []
    if (props.data && props.data.audio_list) {
      list.push(...props.data.audio_list)
    }
    if (props.data && props.data.audio_id) {
      list.push(props.data.audio_id)
    }
    return list.filter((item, pos) => list.indexOf(item) === pos)
  }

  const _getDefaultHashtag = () => {
    let list = []
    if (props.data && props.data.hashtag_list) {
      list.push(...props.data.hashtag_list)
    }
    if (props.data && props.data.hashtag) {
      list.push(props.data.hashtag)
    }
    return list.filter((item, pos) => list.indexOf(item) === pos)
  }

  const startedDate =
    props.data &&
    props.data.start_date &&
    moment(parseInt(props.data.start_date)).format("YYYY-MM-DD")
  const endedDate =
    props.data &&
    props.data.end_date &&
    moment(parseInt(props.data.end_date)).format("YYYY-MM-DD")

  const { jotUserType, permissions } = props.currentUser
  const isEditor =
    jotUserType &&
    (jotUserType === 1 ||
      jotUserType === 2 ||
      (permissions && permissions.includes("EDIT_MANAGE_TRENDING")))

  const _validateDate = val => {
    const [startDate, endDate] = val || []
    if (!endDate) {
      setPatams({ ...params, dateRange: val })
    }
    if (startDate && endDate) {
      const isForOneWeek = moment(endDate).diff(moment(startDate), "days")
      if (isForOneWeek <= 3) {
        setPatams({ ...params, dateRange: val })
      } else {
        setPatams({ ...params, dateRange: undefined })
        toastr.error("Date Range should not be more than 3 days")
      }
    }
  }

  return (
    <React.Fragment>
      <Col lg="5">
        <Card style={{ minHeight: "85vh" }}>
          <CardBody>
            <h4 className="card-title pb-3">
              {props.action === "create" ? "Create" : "Edit"} Trending Entity
            </h4>
            <Form>
              <FormGroup className="mb-4" row>
                <Label htmlFor="title" className="col-form-label col-lg-2">
                  Title
                </Label>
                <Col lg="10">
                  <Input
                    name="title"
                    placeholder="Enter title"
                    type="test"
                    onChange={val =>
                      setPatams({ ...params, title: val.target.value })
                    }
                    defaultValue={props.data && props.data.title}
                  />
                </Col>
              </FormGroup>
              {props.action === "edit" && (
                <FormGroup className="mb-4" row>
                  <Label
                    htmlFor="entityUuid"
                    className="col-form-label col-lg-2"
                  >
                    Entity UUID
                  </Label>
                  <Col lg="10">
                    <Input
                      name="entityUuid"
                      placeholder="Enter Entity UUID"
                      type="text"
                      disabled="true"
                      onChange={val =>
                        setPatams({ ...params, entityUuid: val.target.value })
                      }
                      defaultValue={props.data && props.data.entity_uuid}
                    />
                  </Col>
                </FormGroup>
              )}
              <FormGroup className="mb-4" row>
                <Label htmlFor="hashtag" className="col-form-label col-lg-2">
                  Hashtags
                </Label>
                <Col lg="10">
                  <ChipsInput
                    // label="audioId"
                    placeholderLabel="Enter Hashtag"
                    field={"hashtagList"}
                    onChange={val => setPatams({ ...params, hashtagList: val })}
                    defaultValue={_getDefaultHashtag()}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="audioId" className="col-form-label col-lg-2">
                  Audio Ids
                </Label>
                <Col lg="10">
                  <Col sm="12" className="pb-2">
                    <ChipsInput
                      // label="audioId"
                      placeholderLabel="Enter Audio Id"
                      field={"audioList"}
                      onChange={val => setPatams({ ...params, audioList: val })}
                      defaultValue={_getDefaultAudio()}
                    />
                  </Col>
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="status" className="col-form-label col-lg-2">
                  Status
                </Label>
                <Col lg="10">
                  <Switch
                    className="me-1 mb-sm-8 mb-2"
                    onColor="#626ed4"
                    onChange={val =>
                      setPatams({
                        ...params,
                        status: val ? "STARTED" : "ENDED",
                      })
                    }
                    defaultValue={
                      props.data &&
                      ["STARTED", "active", "true"].includes(props.data.status)
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="status" className="col-form-label col-lg-2">
                  Date Range
                </Label>
                <Col lg="10">
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="Start Date-End Date"
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                        defaultDate: [startedDate, endedDate],
                      }}
                      onChange={val => _validateDate(val)}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="status" className="col-form-label col-lg-2">
                  Language
                </Label>
                <Col lg="10">
                  <Select
                    isMulti={true}
                    defaultInputValue=""
                    placeholder="Global"
                    options={LANGUAGES}
                    classNamePrefix="select2-selection"
                    isClearable={true}
                    defaultValue={
                      props.data &&
                      props.data.lang_code &&
                      props.data.lang_code.map(lang => {
                        return { label: lang, value: lang }
                      })
                    }
                    onChange={val => setPatams({ ...params, langCode: val })}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="mb-4" row>
                <Label htmlFor="status" className="col-form-label col-lg-2">
                  Target Audience
                </Label>
                <Col lg="10">
                  <Select
                    defaultInputValue=""
                    placeholder="Select"
                    options={targetAudienceOptions}
                    classNamePrefix="select2-selection"
                    isClearable={true}
                    defaultValue={
                      props.data &&
                      props.data.target_audience &&
                      targetAudienceOptions.filter(
                        obj => obj.value === props.data.target_audience
                      )
                    }
                    onChange={val =>
                      setPatams({ ...params, targetAudience: val.value })
                    }
                  />
                </Col>
              </FormGroup>
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  onClick={() => {
                    if (!props.hasEditPermission) {
                      return toastr.error(NO_PERMISSION_MESSAGE)
                    } else _onSubmit()
                  }}
                  className="mx-3"
                  disabled={!isEditor}
                >
                  {props.action === "create" ? "Create" : "Update"}
                </Button>
                <Button color="danger" onClick={() => props.oncancel()}>
                  Cancel
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default UpsertEntity
