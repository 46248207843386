import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const requestBrands = async ({ payload }) => {
  try {
    let data = await Axios.get("/api/sponsoredTags/brands")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertWhitelistedUser = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/sponsoredTags/upsertWhitelistedUsers`, {
      data: payload,
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertMastheadContent = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/sponsoredTags/upsertMastheadContent`, {
      data: payload,
    })
    toastr.success("Action Successfull !!")
    return data
  } catch (error) {
    throw error
  }
}
