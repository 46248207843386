import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import _, { map } from "lodash"
import { Input, Select, DatePicker, Divider } from "antd"
import { Col, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { agencyNames, deskTypeOptions, LANGUAGES } from "constants/uiConstants"
// import { LANGUAGES } from "constants/uiConstants"

const { Option } = Select

const EmbeddingDrawer = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="4" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Z0 Cluster Id</h5>
          </Divider>
          <Input
            defaultValue={props.filters["z0clusterId"]}
            placeholder="Z0 Cluster Id"
            onChange={e =>
              props.handleFilterChange(e.target.value.trim(), "z0clusterId")
            }
            allowClear={true}
            name="z0clusterId"
            style={{ width: "100%" }}
          />
        </Col>

        <Col lg="4" md="4">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Z1 Cluster Id</h5>
          </Divider>
          <Input
            defaultValue={props.filters["z1clusterId"]}
            placeholder="Z1 Cluster Id"
            onChange={e =>
              props.handleFilterChange(e.target.value.trim(), "z1clusterId")
            }
            allowClear={true}
            name="z1clusterId"
            style={{ width: "100%" }}
          />
        </Col>
        <Col lg="4" md="8">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Order By</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Select
                  allowClear={true}
                  defaultValue={props.filters["orderByField"]}
                  placeholder="Order By"
                  onChange={value => {
                    props.handleFilterChange(value, "orderByField")
                  }}
                  style={{ width: "100%" }}
                >
                  <React.Fragment>
                    <Option value="view_count">View Count</Option>
                    <Option value="share_count">Share Count</Option>
                    <Option value="download_count">Download Count</Option>
                    <Option value="like_count">Like Count</Option>
                    <Option value="review_order">Review Order</Option>
                  </React.Fragment>

                  <Option value="created_date">Created Date</Option>
                  <Option value="t_score_v2">T Score V2</Option>
                  <Option value="qc_order">QC Order</Option>
                </Select>
                <Select
                  allowClear={true}
                  defaultValue={props.filters["order"]}
                  placeholder="Order"
                  onChange={value => {
                    props.handleFilterChange(value, "order")
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="asc">Ascending</Option>
                  <Option value="desc">Descending</Option>
                </Select>
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(EmbeddingDrawer)
