import React from "react"
import { Row, Col, notification, message } from "antd"
import { Button } from "reactstrap"
import _ from "lodash"

import Auxiliary from "../../util/Auxiliary"

import ValidatorForm, { TextField } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { updatePassword } from "../../clientServices/cmsUserService"

export default class ChangePassword extends ErrorBoundComponent {
  _onSumit = async () => {
    if (this.state.password !== this.state.confirmPassword) {
      notification.error({
        message: "Password Mismatch",
        description: "Confirmed Password did not match",
      })
      return
    } else {
      let loading
      try {
        loading = message.loading("Updating Password..", 0)
        const response = await updatePassword({ password: this.state.password })
        if (response.status === 200) {
          message.success("Password Updated")
        } else {
          message.error("Passowrd Updating failed")
        }
      } catch (error) {
        message.error("Passowrd Updating failed")
      } finally {
        loading()
      }
    }
  }

  _form = () => {
    return (
      <ValidatorForm onSubmit={this._onSumit} layout={"vertical"}>
        <Row gutter={8}>
          <Col span={24}>
            <TextField
              inputType="password"
              field="password"
              label="New Password"
              onChange={val => this.setState({ password: val })}
              validations={["required"]}
              errors={["Password is required"]}
            />
          </Col>
          <Col span={24}>
            <TextField
              field="confirmPassword"
              label="Confirm Password"
              onChange={val => this.setState({ confirmPassword: val })}
              validations={["required"]}
              errors={["Please confirm your password"]}
            />
          </Col>
          <Col className="d-flex justify-content-center" span={24}>
            <div
              role="button"
              className="btn btn-primary d-flex mx-1"
              htmlType="submit"
              onClick={this._onSumit}
            >
              Update
            </div>
          </Col>
        </Row>
      </ValidatorForm>
    )
  }

  _render = () => {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        <div
          style={{ marginTop: "50px" }}
          className="d-flex justify-content-center"
        >
          {this._form()}
        </div>
      </Auxiliary>
    )
  }
}
