import Axios from "axios"
import toastr from "toastr"
import QueryString from "query-string"

export const requestVideoMaterialTypes = async () => {
  try {
    const data = await Axios.get(`/api/videoAssets/getMaterialTypes`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestAllResourceForType = async typeId => {
  try {
    const data = await Axios.get(
      `/api/videoAssets/getMaterialTypeInfo?typeId=${typeId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestAllAssetsForType = async params => {
  const filters = params.filters
  const offset = params.filters["currentPage"]
    ? params.filters["currentPage"] - 1
    : 0
  const limit = params.filters["pageSize"] ? params.filters["pageSize"] : null
  try {
    const data = await Axios.get(
      `/api/videoAssets/getAssetsForAssetType?assetType=${params.filters.searchType}`
    )
    //?${QueryString.stringify({ offset, limit })}
    // const data = await Axios.get(`/api/videoAssets/getAssetsForAssetType?assetType=${assetType}`);
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteVideoMaterialResource = async ({
  typeId,
  resourceId,
}) => {
  try {
    const data = await Axios.delete(
      `/api/videoAssets/deleteVideoMaterialResource?typeId=${typeId}&resourceId=${resourceId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertVideoAsset = async payload => {
  try {
    const data = await Axios.post(
      `/api/videoAssets/upsertVideoAsset?${QueryString.stringify(
        payload.query
      )}`,
      payload.formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestAssetTypes = async payload => {
  try {
    const isTabbable = payload ? true : false
    const data = await Axios.get(
      `/api/videoAssets/getAssetTypes?isTabbable=${isTabbable}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateAssetMappingOrderData = async ({ payload }) => {
  try {
    let data = await Axios.post(
      `/api/videoAssets/updateAssetMappingOrder`,
      payload
    )
    toastr.success(`Order Updated successfully!!`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestTabsList = async () => {
  try {
    let data = await Axios.get(`/api/videoAssets/getTabsList`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestDeleteTab = async ({ tabId }) => {
  try {
    const data = await Axios.delete(`/api/videoAssets/deleteTab?tabId=${tabId}`)
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateTabVersion = async ({ tabId }) => {
  try {
    const data = await Axios.post(
      `/api/videoAssets/updateTabVersion?tabId=${tabId}`
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpsertTabAsset = async payload => {
  try {
    const data = await Axios.post(
      `/api/videoAssets/upsertTabAsset?${QueryString.stringify(payload.query)}`,
      payload.payloadData
    )
    return data
  } catch (error) {
    throw error
  }
}

export const requestUpdateTabOrderData = async ({ payload }) => {
  try {
    let data = await Axios.post(`/api/videoAssets/updateTabOrder`, payload)
    toastr.success(`Order Updated successfully!!`)
    return data
  } catch (error) {
    throw error
  }
}
