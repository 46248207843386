import React from "react"
import toastr from "toastr"
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap"
import { Button, Upload } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import ValidatorForm, { Select, TextField, TextArea } from "../ValidatorForm"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import _ from "lodash"
import "./cmsVideoAssets.styles.css"

class UpsertCMSVideoAssetsForm extends ErrorBoundComponent {
  state = {
    formData: {
      type: null,
    },
    effectFile: null,
  }

  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    if (!_.isEmpty(value)) {
      formData[field] = value
      this.setState({ formData })
    }
  }

  _uploadEffect = fileObj => {
    if (fileObj.file) {
      this.setState({
        effectFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        effectFile: fileObj.file,
      })
    }
  }

  _beforeUpload = file => {
    const isFilePng = file.type === "image/png"
    if (!isFilePng) {
      toastr.error("You can only upload .png file!")
      return false
    }
  }

  _onEffectRemove = () => {
    this.setState({
      effectFileList: [],
      effectFile: null,
    })
  }

  _form = () => {
    return (
      <Card>
        <CardBody style={{ maxHeight: "75vh", overflowY: "auto" }}>
          <CardTitle>{`${
            this.props.formAction === "create" ? "Add" : "Edit"
          } Video Asset`}</CardTitle>
          <ValidatorForm
            onSubmit={this._onSubmit}
            layout={"vertical"}
            {...this._formLayout}
          >
            <Row>
              {this.props.formAction === "update" && (
                <Col sm={12} md={4}>
                  <TextField
                    label={"uuid"}
                    placeholderLabel="Enter the Name"
                    field="uuid"
                    disabled
                    defaultValue={
                      this.props.formAction === "update"
                        ? _.get(this.props, "videoAsset.uuid")
                        : ""
                    }
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
              )}
              <Col sm={12} md={4}>
                <TextField
                  label={"Name"}
                  placeholderLabel="Enter the Name"
                  field="name"
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoAsset.name")
                      : ""
                  }
                  className="creator-form"
                  validations={["required"]}
                  errors={["This field is required"]}
                />
              </Col>
              <Col sm={12} md={4}>
                <Select
                  label="Type"
                  placeholderLabel="Asset Type"
                  field="type"
                  className="creator-form"
                  disabled={this.props.formAction === "update"}
                  onChange={value => this._handleFieldChange("type", value)}
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoAsset.type")
                      : null
                  }
                  options={[
                    { label: "Effect", value: "EFFECT" },
                    { label: "Sticker", value: "STICKER" },
                  ]}
                />
              </Col>
              <Col sm={12} md={4}>
                <Select
                  label="Status"
                  placeholderLabel="Asset Status"
                  field="status"
                  className="creator-form"
                  onChange={value => this._handleFieldChange("status", value)}
                  defaultValue={
                    this.props.formAction === "update"
                      ? _.get(this.props, "videoAsset.status")
                      : "ACTIVE"
                  }
                  options={[
                    { label: "Active", value: "ACTIVE" },
                    { label: "InActive", value: "INACTIVE" },
                  ]}
                />
              </Col>
              {(this.state.formData.type === "STICKER" ||
                this.props.videoAsset.type === "STICKER") && (
                <Col sm={12} md={4}>
                  <Select
                    label="Sticker Type"
                    placeholderLabel="Sticker Type"
                    disabled={this.props.formAction === "update"}
                    field="stickerType"
                    className="creator-form"
                    defaultValue={
                      this.props.formAction === "update"
                        ? _.get(this.props, "videoAsset.stickerType")
                        : "LOCATION"
                    }
                    options={[
                      { label: "Date", value: "DATE" },
                      { label: "Location", value: "LOCATION" },
                      { label: "Title", value: "TITLE" },
                    ]}
                  />
                </Col>
              )}
              {(this.state.formData.type === "STICKER" ||
                this.props.videoAsset.type === "STICKER") && (
                <Col sm={12} md={6}>
                  <TextArea
                    label={"Base Html"}
                    placeholderLabel="Enter the Asset Source"
                    field="baseHtml"
                    defaultValue={
                      this.props.formAction === "update"
                        ? _.get(this.props, "videoAsset.baseHtml")
                        : ""
                    }
                    rows={3}
                    className="creator-form"
                    validations={["required"]}
                    errors={["This field is required"]}
                  />
                </Col>
              )}
            </Row>
            {(this.state.formData.type === "EFFECT" ||
              this.props.videoAsset.type === "EFFECT") && (
              <Row style={{ marginTop: "10px" }}>
                <Upload
                  showUploadList={true}
                  onRemove={this._onEffectRemove}
                  defaultFileList={this.state.effectFileList}
                  fileList={this.state.effectFileList}
                  customRequest={this._uploadEffect}
                  beforeUpload={this._beforeUpload}
                >
                  <Button>
                    <UploadOutlined /> Click to upload effect
                  </Button>
                </Upload>
              </Row>
            )}
            <Row>
              <Col>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    htmlType="submit"
                    disabled={this.state.loading}
                    onDoubleClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }}
                  >
                    {this.props.formAction === "update" ? "Update" : "Create"}
                  </button>
                  <button
                    onClick={() => {
                      this.props.onCancel()
                    }}
                    color="danger"
                    className="btn btn-danger mx-2"
                  >
                    Cancel
                  </button>
                </div>
              </Col>
            </Row>
          </ValidatorForm>
        </CardBody>
      </Card>
    )
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    let data = new FormData()
    data.append("details", JSON.stringify(formData))
    if (formData.type === "EFFECT" && this.state.effectFile) {
      data.append("file", this.state.effectFile)
    }
    this.props.onSubmit(data)
  }

  render() {
    return (
      <Auxiliary
        loading={this.props.loading}
        error={_.get(this.props, "common.error")}
      >
        {this._form()}
      </Auxiliary>
    )
  }
}

export default UpsertCMSVideoAssetsForm
