import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import QueryString from "query-string"
import _ from "lodash"
import "../Campaign/styles.css"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import {
  requestGetKyc,
  requestGetTransactionData,
} from "clientServices/kycService"
import Chips from "../../components/CommonFilter/chips"
import { kycDashFilters } from "../../constants/uiConstants"
import Box from "@mui/material/Box"
import CopyToClipboard from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import moment from "moment"
import Auxiliary from "util/Auxiliary"
const regexExp =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const GIFT = [
  { id: "transaction_by", label: "Transaction By", minWidth: 80 },
  { id: "transaction_to", label: "Transaction To", minWidth: 80 },

  {
    id: "currency_amount",
    label: "Amount",
    minWidth: 120,
  },
  {
    id: "ledger",
    label: "Ledger",
    minWidth: 120,
  },
  {
    id: "entity_id",
    label: "Entity Id",
    minWidth: 120,
  },
  {
    id: "entity_type",
    label: "Entity Type",
    minWidth: 120,
  },
  {
    id: "platform_currency_count",
    label: "Platform Currency Count",
    minWidth: 120,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
  },
  {
    id: "modified_on",
    label: "Modified On",
    minWidth: 150,
  },
]

const REDEEM = [
  { id: "transaction_by", label: "Transaction By", minWidth: 80 },
  { id: "transaction_to", label: "Transaction To", minWidth: 80 },

  {
    id: "currency_amount",
    label: "Amount",
    minWidth: 120,
  },
  {
    id: "ledger",
    label: "Ledger",
    minWidth: 120,
  },

  {
    id: "platform_currency_count",
    label: "Platform Currency Count",
    minWidth: 120,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
  },
  {
    id: "modified_on",
    label: "Modified On",
    minWidth: 150,
  },
]
const PURCHASE = [
  { id: "transaction_by", label: "Transaction By", minWidth: 80 },

  {
    id: "ledger",
    label: "Ledger",
    minWidth: 120,
  },
  {
    id: "transaction_type",
    label: "Transaction Type",
    minWidth: 120,
  },
  {
    id: "platform_currency_count",
    label: "Platform Currency Count",
    minWidth: 120,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
  },
  {
    id: "modified_on",
    label: "Modified On",
    minWidth: 150,
  },
]

const JOSH_PAYMENT_AUDIT = [
  { id: "transaction_by", label: "Transaction By", minWidth: 80 },

  {
    id: "ledger",
    label: "Ledger",
    minWidth: 120,
  },
  {
    id: "transaction_type",
    label: "Transaction Type",
    minWidth: 120,
  },
  {
    id: "platform_currency_count",
    label: "Platform Currency Count",
    minWidth: 120,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
  },
  {
    id: "modified_on",
    label: "Modified On",
    minWidth: 150,
  },
]
const BENEFICIARY = [
  { id: "userId", label: "User Id", minWidth: 80 },
  { id: "beneficiaryId", label: "Beneficiary Id", minWidth: 80 },

  {
    id: "name",
    label: "Name",
    minWidth: 120,
  },
  {
    id: "accountNumber",
    label: "Account No.",
    minWidth: 120,
  },
  {
    id: "ifscCode",
    label: "IFSC Code",
    minWidth: 120,
  },
  {
    id: "refNo",
    label: "Ref No",
    minWidth: 120,
  },
  {
    id: "telephone",
    label: "Telephone",
    minWidth: 120,
  },

  {
    id: "type",
    label: "Type",
    minWidth: 80,
  },
  {
    id: "vpa",
    label: "VPA",
    minWidth: 80,
  },

  {
    id: "modifiedOn",
    label: "Modified On",
    minWidth: 150,
  },
]

const TransactionTable = props => {
  //   const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [rows, setRows] = React.useState([])
  const [columns, setColumns] = React.useState([])
  const [noOfData, setNoOfData] = React.useState(0)
  const handleChangePage = async (event, newPage) => {
    props.setPage(newPage)
  }

  const setKycData = async (page, filters) => {
    setColumns([
      { id: "user_uuid", label: "User", minWidth: 130 },
      { id: "name", label: "Pan Name", minWidth: 80 },
      { id: "pan_number", label: "Pan Number", minWidth: 80 },
      {
        id: "status",
        label: "Status",
        minWidth: 80,
      },
      {
        id: "reason",
        label: "Reason",
        minWidth: 120,
      },
      {
        id: "modifiedOn",
        label: "Modified On",
        minWidth: 150,
      },
    ])
    const rowskyc = await requestGetKyc({
      currentPage: page + 1,
      size: "10",
      status: props.searchdetails.status,
      ...filters,
    })
    setNoOfData(rowskyc.count)
    let internalRow = []
    rowskyc?.rows.map(kyc => {
      internalRow.push({
        user_uuid: kyc.userUuid,
        pan_number: kyc.kycNumber,
        name: kyc.name,
        status: kyc.status,
        reason: kyc.reason != null ? kyc.reason : "-",
        modifiedOn: moment(kyc.modifiedOn).format("DD-MM-YYYY:HH:MM:SS"),
      })
    })
    setRows(internalRow)
    props.setLoader(false)
  }

  const modifyGiftRows = rowskyc => {
    return rowskyc?.transaction_list
      ? rowskyc?.transaction_list.map(item => {
          return {
            transaction_by: item.transaction_by,
            transaction_to: item.transaction_to,
            status: item.status,
            currency_amount: item.currency_amount,
            ledger: item.ledger,
            modified_on: moment(parseInt(item.modified_on)).format(
              "DD-MM-YYYY:HH:MM:SS"
            ),
            entity_id: item.entity_id,
            entity_type: item.entity_type,
            platform_currency_count: item.platform_currency_count,
            transaction_type: item.transaction_type,
          }
        })
      : []
  }

  const modifyBeneficiaryRows = rowskyc => {
    return rowskyc?.beneficiary_transaction_list
      ? rowskyc?.beneficiary_transaction_list.map(item => {
          return {
            userId: item.userId,
            accountNumber: item.accountNumber,
            beneficiaryId: item.beneficiaryId,
            modifiedOn: moment(parseInt(item.modifiedOn)).format(
              "DD-MM-YYYY:HH:MM:SS"
            ),
            ifscCode: item.ifscCode,
            name: item.name,
            refNo: item.refNo,
            type: item.type,
            telephone: item.telephone,
            vpa: item.vpa,
          }
        })
      : []
  }

  const setGiftData = async (type, page) => {
    switch (type) {
      case "GIFT":
        setColumns(GIFT)

        break
      case "REDEEM":
        setColumns(REDEEM)

        break
      case "BENEFICIARY":
        setColumns(BENEFICIARY)

        break
      case "PURCHASE":
        setColumns(PURCHASE)
      case "JOSH_PAYMENT_AUDIT":
        setColumns(JOSH_PAYMENT_AUDIT)

        break
    }

    const rowskyc = await requestGetTransactionData({
      page: page,
      rows: "10",
      status: props.searchdetails.status,
      type: props.searchdetails.type,
    })
    let status2 =
      props.searchdetails.status == undefined
        ? "total_count"
        : props.searchdetails.status.toLowerCase() + "_count"
    setNoOfData(rowskyc[props.searchdetails.type.toLowerCase()][status2])
    let internalRow = []

    switch (type) {
      case "GIFT":
      case "REDEEM":
      case "PURCHASE":
        internalRow = modifyGiftRows(rowskyc)
        break

      case "BENEFICIARY":
        internalRow = modifyBeneficiaryRows(rowskyc)
        break
    }

    setRows(internalRow)
    props.setLoader(false)
  }

  useEffect(() => {
    if (props.searchdetails.type == "KYC") {
      props.setLoader(true)
      setKycData(props.page)
    } else if (
      props.searchdetails.type == "GIFT" ||
      props.searchdetails.type == "REDEEM" ||
      props.searchdetails.type == "BENEFICIARY" ||
      props.searchdetails.type == "PURCHASE"
    ) {
      props.setLoader(true)
      setGiftData(props.searchdetails.type, props.page)
    } else {
      setRows([])
      setColumns([])
    }
  }, [props.searchdetails, props.page])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    props.setPage(0)
  }

  const _search = async () => {
    let filters = props.filters
    props.setPage(0)
    setKycData(0, filters)
    // const rowskyc = await requestGetKyc({ currentPage: props.page + 1, size: "10", status: props.searchdetails.status, ...{filters} })
    // this.props.dispatch(getBrandCampaign(_.deepClean(filters)))
  }
  const _closeFilters = () => {
    _search()

    props.toggleFilters(false)
  }

  const _removeFilter = filter => {
    const newFilters = {
      ...props.filters,
      [filter]: kycDashFilters[filter].defaultValue,
    }

    props.setFilters(newFilters)

    setKycData(0, newFilters)
  }

  const _handleFieldChange = (value, type) => {
    const newFilters = _.cloneDeep(props.filters)
    newFilters[type] = value
    props.setFilters(newFilters)
  }
  const _toggleFilters = () => {
    props.toggleFilters()
  }

  return (
    <Auxiliary height="30vh" loading={props.loader}>
      {rows.length != 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden", minWidth: 650 }}>
          <span>
            {" "}
            {props.searchdetails.type == "KYC" && (
              <Chips
                showFilters={props.showFilters}
                filters={props.filters}
                search={_closeFilters}
                removeFilter={_removeFilter}
                handleFilterChange={_handleFieldChange}
                type={props.searchdetails.type || ""}
                dash={true}
                toggleFilters={_toggleFilters}
              />
            )}
            <Box sx={{ boxShadow: 1 }}>
              <TableContainer sx={{}}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, fontWeight: 800 }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map(column => {
                            const value = row[column.id]
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}{" "}
                                {regexExp.test(value) ? (
                                  <CopyToClipboard
                                    text={value}
                                    onCopy={e => {
                                      toastr.success("Copied sucessfully!")
                                    }}
                                  >
                                    <Tooltip title="Copy Id">
                                      <i
                                        className="fontSize18 bx bx-copy-alt"
                                        role="button"
                                      />
                                    </Tooltip>
                                  </CopyToClipboard>
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={noOfData}
                rowsPerPage={rowsPerPage}
                page={props.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>{" "}
          </span>
        </Paper>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
          }}
        >
          No data found
        </div>
      )}
    </Auxiliary>
  )
}

const mapStateToProps = (store, ownProps) => {
  return {
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    kycList: _.get(store, "kyc.kycList") || [],
    common: _.get(store, "common"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}
export default connect(mapStateToProps)(withRouter(TransactionTable))
