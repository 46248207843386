import { List, Row, Col, Tooltip, Tag, message, Button } from "antd"
import React from "react"
import _ from "lodash"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { AiOutlineAim, AiOutlineCopy } from "react-icons/ai"
import "./styles.css"

export default class ChallengeContentList extends React.Component {
  _renderThumbnail = content => {
    switch (content.format) {
      case "IMAGE":
        return (
          <img src={content.download_url} width="100" height="100" alt="" />
        )
      case "GIF":
        return (
          <img src={content.download_url} width="100" height="100" alt="" />
        )
      default:
        return (
          <img
            src={
              content.animated_icon_url
                ? content.animated_icon_url
                : content.thumbnail_url
                ? content.thumbnail_url
                : ""
            }
            width="100"
            height="100"
            alt=""
          />
        )
    }
  }

  _contentRenderer = content => {
    let contentTitle = !_.isEmpty(content.content_title)
      ? content.content_title
      : !_.isEmpty(content.prepared_content_title)
      ? content.prepared_content_title
      : "<No Title>"
    return (
      <List.Item key={content.content_uuid}>
        <Row style={{ width: "100%" }}>
          <Col sm={24} md={3}>
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="thumnailImage">
                {this._renderThumbnail(content)}
              </div>
            </div>
          </Col>
          <Col sm={24} md={21}>
            <Row style={{ width: "100%", cursor: "pointer" }}>
              <Col span={18}>
                <div className="amContentTitle">
                  <Tooltip title={contentTitle}>
                    <div className="titleText"> {contentTitle} </div>
                  </Tooltip>
                </div>
              </Col>
            </Row>
            <Row
              style={{ width: "100%", marginTop: this.props.isMobile ? 10 : 5 }}
            >
              <Col span={24}>
                <Tooltip title="Name">
                  <Tag color="orange" className="tagStyle">
                    {" "}
                    {content.user_profile.name}
                  </Tag>
                </Tooltip>
                <Tooltip title="Handle">
                  <Tag color="green" className="tagStyle">
                    {" "}
                    {content.user_profile.user_name}
                  </Tag>
                </Tooltip>
                <Tooltip title="Moderation Status">
                  <Tag
                    className="tagStyle"
                    color={
                      content.moderation_level === 1 ||
                      content.moderation_level === 3 ||
                      content.moderation_level === 0
                        ? "red"
                        : content.moderation_level === 2
                        ? "#87d068"
                        : "#108ee9"
                    }
                    style={{
                      color:
                        content.moderation_level !== -1 ? "black" : "white",
                    }}
                  >
                    {content.moderation_status}
                  </Tag>
                </Tooltip>
                {content.duplicate_content && (
                  <Tag className="tagStyle" color="#0ad1ca">
                    Duplicate
                  </Tag>
                )}
                {!content.is_active && (
                  <Tooltip title="Content">
                    <Tag color="red" className="tagStyle">
                      Deleted
                    </Tag>
                  </Tooltip>
                )}

                <Tooltip title="Tags">
                  {content.tags_array &&
                    content.tags_array.length > 0 &&
                    content.tags_array.map(tag => (
                      <Tag color="blue" className="tagStyle">
                        #{tag}
                      </Tag>
                    ))}
                </Tooltip>
                {!content.tags_array.includes(this.props.hashtag) && (
                  <Tooltip title="Content">
                    <Tag color="red" className="tagStyle">
                      Invalid
                    </Tag>
                  </Tooltip>
                )}
                {
                  <CopyToClipboard
                    text={content.content_uuid}
                    onCopy={() => {
                      message.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy Content ID">
                      <Button
                        size="small"
                        style={{
                          color: "blue",
                          borderColor: "blue",
                          verticalAlign: "top",
                        }}
                        icon={<AiOutlineCopy />}
                        type="dashed"
                      />
                    </Tooltip>
                  </CopyToClipboard>
                }
                {content.duplicate_content && (
                  <CopyToClipboard
                    text={content.original_content_uuid}
                    onCopy={() => {
                      message.success("Copied sucessfully!")
                    }}
                  >
                    <Tooltip title="Copy Original Content ID">
                      <Button
                        size="small"
                        style={{
                          color: "blue",
                          borderColor: "blue",
                          margin: "0 5px",
                          verticalAlign: "top",
                        }}
                        icon={<AiOutlineAim />}
                        type="dashed"
                      />
                    </Tooltip>
                  </CopyToClipboard>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </List.Item>
    )
  }

  render() {
    return (
      <List
        dataSource={this.props.contentUUIDsDetails}
        renderItem={this._contentRenderer}
        style={{ maxHeight: "60vh", overflowY: "scroll" }}
      />
    )
  }
}
