import React from "react"
import _ from "lodash"
import { InfoCircleOutlined } from "@ant-design/icons"
import { Popover, Tooltip } from "antd"

const ImageCard = props => {
  const { data } = props
  const imgSource =
    data.help && data.help.thumbnail
      ? data.help.thumbnail
      : props.thumbnail
      ? props.thumbnail
      : data.zone_image
      ? data.zone_image
      : "http://qa-stream.coolfie.io/stream/qa-josh-content/josh-cms/taxonomy/default/default.jpg"
  return (
    <div
      className={
        props.className ||
        `question-card text-center contentCard ${
          data.selected ? "selectedCard" : ""
        }`
      }
      onClick={() => props._handleSelection(data)}
      style={{
        width: props.width || "8rem",
        height: props.height || "11.625rem",
      }}
    >
      <div className="card-img-container">
        <Popover
          trigger="hover"
          content={() => props.renderCardContent(data)}
          color="#D3D3D3"
          mouseEnterDelay="1"
          destroyTooltipOnHide
        >
          <div>
            <img
              className="card-img-top-custom"
              src={imgSource}
              alt="Card image caption"
            />
          </div>
        </Popover>
      </div>
      <div
        className="bg-transparent d-flex justify-content-between align-items-center w-100"
        style={{ padding: "7px", flex: "auto" }}
      >
        <Tooltip title={props.label || data.label}>
          <div className="card-text-footer">{props.label || data.label}</div>
        </Tooltip>
        <Popover
          trigger="hover"
          color="#D3D3D3"
          content={() => props.renderCardContent(data)}
          destroyTooltipOnHide
        >
          <div style={{ marginLeft: "2px" }}>
            <InfoCircleOutlined size={60} />
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default ImageCard
