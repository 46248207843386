import React from "react"
import { Input, Pagination, Upload } from "antd"
import { Select, DatePicker, Tooltip, Divider, Modal, Form } from "antd"
import {
  upsertBulkInstagramData,
  upsertInstagramData,
} from "clientServices/crawlerService"
import Auxiliary from "util/Auxiliary"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Button, Switch } from "@mui/material"
import { UploadOutlined } from "@ant-design/icons"
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap"
import ValidatorForm, {
  TextField,
  Switch as VSwitch,
} from "containers/ValidatorForm"
import moment from "moment"
import { MdEdit } from "react-icons/md"
import { getInterest } from "clientServices/interestServices"
import { LANGUAGES } from "constants/uiConstants"
import { getUsersByStatus, updateUser } from "clientServices/cmsUserService"
const { Option } = Select
import toastr from "toastr"

class CmsUserAudit extends React.Component {
  state = {
    loading: false,
    formData: {
      interest: "",
      subinterest: "",
    },
    userData: [],
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 10,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      orderBy:
        this.props.queryParams && this.props.queryParams["orderBy"]
          ? this.props.queryParams["orderBy"]
          : "last_crawled",
      order:
        this.props.queryParams && this.props.queryParams["order"]
          ? this.props.queryParams["order"]
          : "ASC",
      instaHandle:
        this.props.queryParams && this.props.queryParams["instaHandle"]
          ? this.props.queryParams["instaHandle"]
          : "",
      userUuid:
        this.props.queryParams && this.props.queryParams["userUuid"]
          ? this.props.queryParams["userUuid"]
          : "",
    },
    showCreateForm: false,
    showUploadForm: false,
    showUpdateForm: false,
    currentData: {},
  }

  async componentDidMount() {
    this._fetchUsers()
  }

  _fetchUsers = async () => {
    this.setState({
      loading: true,
    })
    try {
      const { data } = await getUsersByStatus({
        status: false,
      })
      this.setState({
        loading: false,
        userData: data.data,
        total: data.total,
        showCreateForm: false,
        showUploadForm: false,
        showUpdateForm: false,
      })
    } catch (error) {
      this.setState({
        loading: false,
        showCreateForm: false,
        showUploadForm: false,
        showUpdateForm: false,
      })
    }
  }
  _uploadCSV = fileObj => {
    if (fileObj.file) {
      this.setState({
        csvFileList: [
          {
            uid: "-1",
            name: fileObj.file.name,
            status: "done",
            url: "",
          },
        ],
        csvFile: fileObj.file,
      })
    }
  }

  _onVideoRemove = () => {
    this.setState({
      csvFileList: [],
      csvFile: null,
    })
  }
  _handlePageChange = value => {
    this.setState(
      {
        filters: { ...this.state.filters, currentPage: value },
      },
      () => this._fetchUsers()
    )
  }

  _handleChangeStatus = async (e, rowData) => {
    this.setState({
      loading: true,
    })
    const data = {
      ...rowData,
      userStatus: e.target.checked,
      action: "update",
    }
    try {
      const response = await updateUser({ data })
      toastr.success("Action successful...")
    } catch (error) {
      toastr.error("Error in updating user status...")
      this.setState({
        loading: false,
      })
    } finally {
      this._fetchUsers()
    }
  }

  _onSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    try {
      this.setState({
        loading: true,
      })
      const response = await upsertInstagramData({
        ...formData,
        ...{
          active_interest: this.state.formData.interest,
          active_subinterest: this.state.formData.subinterest,
          language: this.state.formData.language,
        },
      })
    } catch (error) {
      this.setState({
        loading: false,
      })
    } finally {
      this._fetchUsers()
    }
  }

  _onUpdate = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    try {
      this.setState({
        loading: true,
      })
      const response = await upsertInstagramData({
        ...formData,
        ...{
          active_interest: this.state.formData.interest,
          active_subinterest: this.state.formData.subinterest,
          language: this.state.formData.language,
        },
        action: "update",
      })
    } catch (error) {
      this.setState({
        loading: false,
      })
    } finally {
      this._fetchUsers()
    }
  }

  _onBulkSubmit = async ({ formData, errors }) => {
    if (errors) {
      return
    }
    try {
      this.setState({
        loading: true,
      })
      let data = new FormData()
      data.append("files", this.state.csvFile)
      const response = await upsertBulkInstagramData(data)
    } catch (error) {
      this.setState({
        loading: false,
      })
    } finally {
      this._fetchUsers()
    }
  }
  _handleFieldChange = (field, value) => {
    let formData = this.state.formData
    formData[field] = value

    this.setState({ formData }, () => {
      console.log(this.state)
    })
  }

  _filters = () => {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg="4" md="8">
              <Input
                defaultValue={this.state.filters["instaHandle"]}
                placeholder={"Instagram Handle"}
                onChange={e =>
                  this.setState({
                    filters: {
                      ...this.state.filters,
                      instaHandle: e.target.value,
                    },
                  })
                }
                allowClear={true}
                name="searchId"
                size="large"
              />
            </Col>
            <Col lg="4" md="8">
              <Input
                defaultValue={this.state.filters["userUuid"]}
                placeholder={"Josh User UUID"}
                onChange={e =>
                  this.setState({
                    filters: {
                      ...this.state.filters,
                      userUuid: e.target.value,
                    },
                  })
                }
                allowClear={true}
                name="searchId"
                size="large"
              />
            </Col>
            <Col lg="1" md="8">
              <Input
                defaultValue={this.state.filters["gap"]}
                placeholder={"Gap"}
                onChange={e =>
                  this.setState({
                    filters: {
                      ...this.state.filters,
                      gap: e.target.value,
                    },
                  })
                }
                type="number"
                allowClear={true}
                name="gap"
                size="large"
              />
            </Col>
            <Col lg="2" md="12">
              <div className="d-flex justify-content-end mb-3">
                <Button
                  color="success"
                  variant="contained"
                  onClick={this._fetchUsers}
                >
                  Apply
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  render() {
    return (
      <Auxiliary loading={this.state.loading}>
        {/* <div style={{ display: "flex", justifyContent: "right" }}>
          <div className="d-flex mb-3 justify-content-end">
            <Button
              variant="contained"
              onClick={() =>
                this.setState({ showCreateForm: true, showUploadForm: false })
              }
            >
              Create
            </Button>
          </div>
          <div
            className="d-flex mb-3 justify-content-end"
            style={{ marginLeft: "10px" }}
          >
            <Button
              variant="contained"
              onClick={() =>
                this.setState({ showUploadForm: true, showCreateForm: false })
              }
            >
              Upload
            </Button>
          </div>
        </div> */}
        {/* {this._filters()}
         */}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Roles</b>
                  </TableCell>
                  <TableCell>
                    <b>Agency</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.userData.map(row => (
                  <TableRow
                    key={row.name}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <b>{row.userId}</b>
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {row.jotRoles != null && row.jotRoles.length > 0
                        ? row.jotRoles.join(",")
                        : ""}
                    </TableCell>
                    <TableCell>{row.agency}</TableCell>
                    <TableCell>
                      <Switch
                        defaultChecked={row.userStatus}
                        onChange={e => this._handleChangeStatus(e, row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <div className="d-flex justify-content-end pb-3">
            <Pagination
              style={{ marginTop: "1rem" }}
              onChange={this._handlePageChange}
              defaultCurrent={this.state.filters.currentPage}
              total={this.state.total}
              pageSize={this.state.filters.pageSize}
              showSizeChanger={false}
            />
          </div> */}
        </Paper>
      </Auxiliary>
    )
  }
}

export default CmsUserAudit
