import { createSlice } from "@reduxjs/toolkit"

const limitSlice = createSlice({
  name: "limit",
  initialState: {
    loading: false,
    loadingActivity: false,
    limitList: [],
    refetchData: false,
    total: 0,
  },

  reducers: {
    getLimits(state) {
      return {
        ...state,
        loading: true,
        refetchData: false,
      }
    },
    getLimitsSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: false,
        limitList: payload.data || [],
        total: payload?.count ? payload?.count : 1,
      }
    },
    createLimit(state) {
      return {
        ...state,
        loading: true,
      }
    },
    createLimitSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: true,
        createStatus: payload.status,
      }
    },
    updateLimit(state) {
      return {
        ...state,
        loading: true,
      }
    },
    updateLimitSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
        refetchData: true,
        createStatus: payload.status,
      }
    },
    upsertLimit(state) {
      return {
        ...state,
        loading: true,
      }
    },
    upsertLimitSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
      }
    },
    getActivity(state) {
      return {
        ...state,
        loadingActivity: true,
      }
    },
    getActivitySuccess(state, { payload }) {
      return {
        ...state,
        loadingActivity: false,
        activityList: payload.result.data || [],
        totalActivity: payload.result.count || 0,
      }
    },
    deleteLimit(state) {
      return {
        ...state,
        loading: true,
      }
    },
    deleteLimitSuccess(state, { payload }) {
      return {
        ...state,
        loading: false,
      }
    },
    resetState() {
      return {
        loading: false,
        loadingActivity: false,
        limitList: [],
        refetchData: false,
        total: 0,
        openIssues: 0,
      }
    },
  },
})

export const {
  getLimits,
  getLimitsSuccess,
  getActivity,
  createLimit,
  createLimitSuccess,
  updateLimit,
  updateLimitSuccess,
  upsertLimit,
  upsertLimitSuccess,
  getActivitySuccess,
  deleteLimit,
  deleteLimitSuccess,
  resetState,
} = limitSlice.actions

export default limitSlice.reducer
