import Axios from "axios"

export const entityUpsert = async payload => {
  try {
    const response = await Axios.post("/api/trending/upsert", payload)
    return response.data
  } catch (error) {
    return error
  }
}

export const getTrendingData = async params => {
  try {
    const response = await Axios.get(
      `/api/trending/getTrendingData?type=${params.type}&entityId=${params.entityId}`
    )
    return response.data.data || {}
  } catch (error) {
    return error
  }
}
