import React from "react"
import toastr from "toastr"
import { connect } from "react-redux"
import QueryString from "query-string"
import { resetState } from "appRedux/slices/zone"
import _ from "lodash"
import { Tag, Pagination, Modal } from "antd"
import { Row, Col, Card } from "reactstrap"
import { BiSolidUserCircle } from "react-icons/bi"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import Auxiliary from "../../util/Auxiliary"
import { Form, List, Typography, Select, Input } from "antd"
import { Button } from "@mui/material"
import {
  createStrategy,
  updateStrategy,
  getInterest,
  getStartegy,
  interestDetails,
  updateSubInterest,
} from "clientServices/interestServices"
import { MoreOutlined } from "@ant-design/icons"
import { Dropdown, Space } from "antd"
import { FaSeedling, FaShare } from "react-icons/fa"
import { HiDuplicate } from "react-icons/hi"
import { BsFillStopCircleFill } from "react-icons/bs"
import { IoMdStats, IoMdRefresh } from "react-icons/io"
import {
  MdDownloadForOffline,
  MdModeEditOutline,
  MdFlaky,
} from "react-icons/md"
import { AiTwotoneHeart, AiFillEye, AiOutlineStop } from "react-icons/ai"
import { BiSolidCloudUpload, BiPowerOff } from "react-icons/bi"
import { TiTick } from "react-icons/ti"
import Fuse from "fuse.js"
import { formatNumber } from "../../util/common"
import { Link, withRouter } from "react-router-dom"
import { LANGUAGES, LANGUAGE_MAPPING } from "constants/uiConstants"
import "./strategy.css"
import moment from "moment"
const { Option } = Select

class StrategyTabList extends ErrorBoundComponent {
  state = {
    data: [],
    filterdData: [],
    filter: {
      sort: "desc",
      mode: "PUBLIC",
      type: "",
      metric: "absolute",
    },
    subInterestStatusList: {},
    currentStrategy: null,
    lang_code: "hi",
  }
  constructor(props) {
    super(props)
    this.searchFn = _.debounce(this.searchFn, 1000)
  }

  async componentDidMount() {
    if (!this.props.common.error) {
      this.setState({
        loading: true,
      })
      const interestdata = await getInterest({
        mode: this.props.mode,
        sort: "desc",
      })

      const interestData = await getInterest()

      const strategyData = await getStartegy({
        mode: this.props.mode,
      })

      //to get likes,share data
      let interestDetailspayload = {}
      for (let i = 0; i < interestdata?.result?.data?.length; i++) {
        interestDetailspayload[interestdata?.result?.data[i].id] = {}
        interestDetailspayload[interestdata?.result?.data[i].id] =
          interestdata?.result?.data[i].subInterests
      }
      let interestDetails2 = {}
      interestDetails2 = await interestDetails(interestDetailspayload)

      // const data = interestdata?.result?.data || []

      // data.forEach(interest => {
      //   let subInterests = interest.subInterests
      //   subInterests = subInterests.map(
      //     subInterest => subintereststatus[subInterest]
      //   )
      //   interest.subInterestsWithName = subInterests
      // })

      this.setState(
        {
          data: strategyData?.result?.data || [],
          filter: {
            mode: this.props.mode,
            sort: "desc",
            type: "",
            metric: "absolute",
          },
          filterdData: strategyData?.result?.data || [],
          interestData: interestData?.result?.data || [],
          // subInterestStatusList: subintereststatus || {},
          interestDetail: interestDetails2 || {},
          loading: false,
        },
        () => {
          const interestNameMappings = {}
          this.state.interestData.forEach(interest => {
            interestNameMappings[interest.id] = interest.name
          })

          this.setState({
            interestNameMappings,
          })
        }
      )

      window.addEventListener("keydown", this._keyDownHandler)
    }
  }

  componentWillUnmount() {
    // this.props.dispatch(resetState())
    window.removeEventListener("keydown", this._keyDownHandler)
  }

  _onPageChange = value => {
    let newFilters = _.cloneDeep(this.state.filters)
    newFilters["currentPage"] = value
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this._onSearch()
      }
    )
  }

  _manageSeedVideos = data => {
    this.props.toggleManageSeedVideos({
      currentInterest: data.interest,
      currentSubInterest: data.subInterest,
      subInterestName: data.subInterestName,
    })
  }
  _manageTabAddVideos = data => {
    this.props.toggleAddVideos({
      currentInterest: data.interest,
      currentSubInterest: data.subInterest,
      subInterestName: data.subInterestName,
    })
  }

  _manageReviewVideos = data => {
    this.props.toggleManageReviewVideos({
      currentInterest: data.interest,
      currentSubInterest: data.subInterest,
      subInterestName: data.subInterestName,
    })
  }

  _manageAddVideos = data => {
    this.props.toggleManageAddVideos({
      currentInterest: data.interest,
      currentSubInterest: data.subInterest,
    })
  }

  handleFilterChange = async (val, type) => {
    let filter = { ...this.state.filter }
    filter[type] = val

    const strategyData = await getStartegy(filter)

    this.setState({
      filter,
      filterdData: strategyData?.result?.data || [],
    })
  }

  _renderCombinations = (item, index) => {
    if (!_.isEmpty(item.combinations)) {
      const data = Object.keys(item.combinations).map(k => {
        if (k !== "selectedInterest" && !_.isEmpty(item.combinations[k]))
          return (
            <li>
              {this.state.interestNameMappings[k]} &rarr;{" "}
              {item.combinations[k].toUpperCase()}
              {/* <TiDelete
                color="red"
                className="mx-2"
                size={20}
                onClick={() => this._removeCombination(k, index)}
                role="button"
              /> */}
            </li>
          )
      })
      return (
        <ul className="mb-2" style={{ marginTop: "18px" }}>
          {data}
        </ul>
      )
    }
  }
  _handleFieldsChange = (field, value) => {
    let formData = this.state
    formData[field] = value

    this.setState(formData)
  }

  _renderStrategyDetails = () => {
    const dataSource =
      this.state.currentStrategy?.slots[this.state.lang_code].data
    return (
      <Modal
        width={1000}
        title="Strategy Slots"
        open={this.state.showStrategyDetails}
        footer={null}
        onCancel={() => {
          this.setState({ showStrategyDetails: false })
        }}
        // bodyStyle={{ padding: "1rem"}}
        destroyOnClose
      >
        <div
          style={{
            maxHeight: "70vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Form layout="vertical" autoComplete="false">
            <Row>
              <Col lg="6">
                <Form.Item label="Target Language">
                  <Select
                    // mode="multiple"
                    showSearch
                    size="large"
                    allowClear={true}
                    // placeholder="Include Languages"
                    // defaultValue={props.filters["includeLang"]}
                    onChange={value => {
                      this.setState({
                        lang_code: value,
                      })
                    }}
                    value={this.state.lang_code}
                    style={{ width: "100%" }}
                  >
                    {LANGUAGES.map(lang => (
                      <Option value={lang.value}>{lang.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <List
            itemLayout="vertical"
            size="large"
            className="mt-2"
            dataSource={
              this.state.currentStrategy?.slots[this.state.lang_code].data
            }
            renderItem={(item, index) => {
              let likes = 0
              let shares = 0
              let views = 0
              let downloads = 0
              let fk_likes = 0
              let fk_shares = 0
              let fk_views = 0
              let fk_downloads = 0
              let arrSet = new Set()

              for (let j in item.combinations) {
                arrSet.add(j)
              }

              let setArr = [...arrSet]
              for (let p = 0; p < setArr.length; p++) {
                likes =
                  likes +
                  this.state.interestDetail[setArr[p]]?.like_count_sum?.value
                shares =
                  shares +
                  this.state.interestDetail[setArr[p]]?.share_count_sum?.value
                views =
                  views +
                  this.state.interestDetail[setArr[p]]?.view_count_sum?.value
                downloads =
                  downloads +
                  this.state.interestDetail[setArr[p]]?.download_count_sum
                    ?.value
                fk_likes =
                  fk_likes +
                  this.state.interestDetail[setArr[p]]?.fk_like_count_sum?.value
                fk_shares =
                  fk_shares +
                  this.state.interestDetail[setArr[p]]?.fk_share_count_sum
                    ?.value
                fk_views =
                  fk_views +
                  this.state.interestDetail[setArr[p]]?.fk_view_count_sum?.value
                fk_downloads =
                  fk_downloads +
                  this.state.interestDetail[setArr[p]]?.fk_download_count_sum
                    ?.value
              }
              return (
                <Card className="border">
                  {/* <CardTitle>{item.name}</CardTitle> */}

                  <Row>
                    <Col
                      lg="1"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="p-2">
                        <BiSolidUserCircle size={60} color="#1976d2" />
                      </div>
                    </Col>
                    <Col lg="7" style={{ display: "flex" }}>
                      <div className="mt-2" style={{ paddingTop: "17px" }}>
                        <h4>{item.name}</h4>
                      </div>
                      {this._renderCombinations(item, index)}
                    </Col>
                    <Col
                      lg="4"
                      md="8"
                      style={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "21px",
                        color: "grey",
                        alignItem: "center",
                      }}
                    >
                      <div>
                        {" "}
                        <AiFillEye />{" "}
                        <span style={{ fontSize: "16px" }}>
                          {this.state.filter.metric == "absolute"
                            ? formatNumber(views)
                            : fk_views == 0
                            ? "-"
                            : `${((views * 100) / fk_views).toFixed(2)}%`}
                        </span>
                      </div>
                      <div>
                        <AiTwotoneHeart style={{ color: "grey" }} />{" "}
                        <span style={{ fontSize: "16px" }}>
                          {this.state.filter.metric == "absolute"
                            ? formatNumber(likes)
                            : fk_likes == 0
                            ? "-"
                            : `${((likes * 100) / fk_likes).toFixed(2)}%`}
                        </span>
                      </div>

                      <div>
                        {" "}
                        <FaShare />
                        <span style={{ fontSize: "16px" }}>
                          {" "}
                          {this.state.filter.metric == "absolute"
                            ? formatNumber(shares)
                            : fk_shares == 0
                            ? "-"
                            : `${((shares * 100) / fk_shares).toFixed(2)}%`}
                        </span>
                      </div>
                      <div>
                        {" "}
                        <MdDownloadForOffline />
                        <span style={{ fontSize: "16px" }}>
                          {" "}
                          {this.state.filter.metric == "absolute"
                            ? formatNumber(downloads)
                            : fk_downloads == 0
                            ? "-"
                            : `${((downloads * 100) / fk_downloads).toFixed(
                                2
                              )}%`}
                        </span>
                      </div>
                    </Col>
                    {/* <Col className="d-flex align-items-center justify-content-end">
                    <Select
                      showSearch
                      size="large"
                      allowClear={true}
                      placeholder="Choose Interest"
                      // defaultValue={props.filters["includeLang"]}
                      onChange={value => {
                        this._handleFieldsChange(value, "interest", index)
                      }}
                      style={{ width: "40%" }}
                    >
                      {this.props?.interestData.map(item => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                    <Select
                      showSearch
                      size="large"
                      allowClear={true}
                      placeholder="Choose Type"
                      // defaultValue={props.filters["includeLang"]}
                      onSelect={value => {
                        this._handleFieldsChange(value, "type", index)
                      }}
                      style={{ width: "40%" }}
                      className="mx-2"
                    >
                      <Option value="popular">Popular</Option>
                      <Option value="fresh">Fresh</Option>
                    </Select>
                  </Col> */}
                  </Row>
                </Card>
              )
            }}
          />
        </div>
      </Modal>
    )
  }
  _manageStopButton = async (data, action) => {
    ;(data.action = "edit"), (data.status = action)
    const data2 = await createStrategy(data)
    const strategyData = await getStartegy({
      mode: this.props.mode,
    })
    this.setState({
      data: strategyData?.result?.data || [],
      filterdData: strategyData?.result?.data || [],
      loading: false,
    })
  }
  activateStrategy = async () => {
    let activatedStrategy = this.state.filterdData.filter(
      item => item.status == "ACTIVE"
    )

    if (activatedStrategy.length != 0) {
      activatedStrategy[0].action = "edit"
      activatedStrategy[0].status = "RESUME"
      await updateStrategy(activatedStrategy[0])
    }

    let copy = { ...this.state.toBeactivateStrategy }

    copy.action = "edit"
    copy.status = "ACTIVE"
    await updateStrategy(copy)

    toastr.success("Strategy activated")
    const strategyData = await getStartegy({
      mode: this.props.mode,
    })
    this.setState({
      data: strategyData?.result?.data || [],
      filterdData: strategyData?.result?.data || [],
      loading: false,
      activeStatusPopup: false,
    })
  }
  _renderConfirmationPopup = () => {
    return (
      <Modal
        title="Active Confirmation"
        open={this.state.activeStatusPopup}
        onOk={() => this.activateStrategy()}
        onCancel={() => {
          this.setState({ activeStatusPopup: false })
        }}
      >
        Do you want to Active this Strategy?
      </Modal>
    )
  }

  searchFn = async (val, type) => {
    let filter = { ...this.state.filter }
    filter[type] = val

    if (!val) {
      this.setState({
        filterdData: this.state.data,
      })
      return
    }

    const options = {
      shouldSort: true,
      // findAllMatches: true,
      // includeMatches: false,
      threshold: 0.7,
      // location: 0,
      // distance: 50,
      // maxPatternLength: 32,
      // useExtendedSearch: true,
      // minMatchCharLength: 2,
      keys: ["name"],
    }

    // console.log(this.state.data)

    const fuse = new Fuse(this.state.data, options)
    let data = fuse.search(`=${val}`)

    // const interestdata = await getInterest(filter)
    data = data.map(data => data.item)
    this.setState({
      filterdData: data || [],
    })
  }

  _renderSearch = () => {
    return (
      <Input
        // defaultValue={props.filters["audioId"]}
        placeholder={"Search Strategy.."}
        size="large"
        onChange={e => this.searchFn(e.target.value, "searchInterest")}
        allowClear={true}
        name="searchInterest"
        value={this.state.filter.searchInterest}
        style={{ width: "100%" }}
      />
    )
  }
  _renderFilters = () => {
    return (
      <Row>
        <Col lg="6" md="3">
          <Select
            placeholder="Sort"
            style={{ width: "100%" }}
            defaultValue={this.state.filter["sort"]}
            onChange={value => {
              this.handleFilterChange(value, "sort")
              //handleNameFilter(value)
            }}
            size="large"
          >
            <Option value="desc">Latest</Option>
            <Option value="asc">Oldest</Option>
          </Select>
        </Col>
        <Col lg="6" md="3">
          <Select
            placeholder="Metric Value"
            style={{ width: "100%" }}
            defaultValue={this.state.filter["metric"]}
            onChange={value => {
              this.handleFilterChange(value, "metric")
              //handleNameFilter(value)
            }}
            size="large"
          >
            <Option value="absolute">Absolute</Option>
            <Option value="vp">VP</Option>
          </Select>
        </Col>
      </Row>
    )
  }
  _pagination = () => {
    return (
      <div className="d-flex justify-challenge-end">
        <Pagination
          style={{ marginTop: "1rem" }}
          onChange={this._onPageChange}
          current={parseInt(this.state.filters["currentPage"], 10)}
          defaultCurrent={parseInt(this.state.filters["currentPage"], 10)}
          total={this.props.total > 10000 ? 10000 : this.props.total}
          showSizeChanger={false}
        />
      </div>
    )
  }

  onChange = key => {
    console.log(key)
  }
  debounce = (func, wait) => {
    let timeout

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }
  render() {
    return (
      <Auxiliary
        loading={this.state.loading}
        error={_.get(this.props, "common.error")}
      >
        <Row>
          <Col lg="12" md="12">
            {this._renderSearch()}
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg="8" md="12">
            {/* <h4>My Watches</h4> */}
          </Col>
          <Col lg="4" md="12">
            {this._renderFilters()}
          </Col>
        </Row>
        {/* <Divider orientation="left">My Watches</Divider> */}

        {this.state.filterdData.filter(item => item.status == "ACTIVE")
          .length != 0 && (
          <>
            Active
            <List
              style={{ width: "100%", margin: "15px 0px" }}
              bordered
              dataSource={this.state.filterdData.filter(
                item => item.status == "ACTIVE"
              )}
              renderItem={(item, index) => {
                let likes = 0
                let shares = 0
                let views = 0
                let downloads = 0
                let fk_likes = 0
                let fk_shares = 0
                let fk_views = 0
                let fk_downloads = 0
                let setArr = []

                for (let k in item.slots) {
                  for (let i = 0; i < item.slots[k].data.length; i++) {
                    for (let j in item.slots[k].data[i].combinations) {
                      setArr.push(j)
                    }
                  }
                }
                // let setArr = [...arrSet]
                for (let p = 0; p < setArr.length; p++) {
                  likes =
                    likes +
                    this.state.interestDetail[setArr[p]]?.like_count_sum?.value
                  shares =
                    shares +
                    this.state.interestDetail[setArr[p]]?.share_count_sum?.value
                  views =
                    views +
                    this.state.interestDetail[setArr[p]]?.view_count_sum?.value
                  downloads =
                    downloads +
                    this.state.interestDetail[setArr[p]]?.download_count_sum
                      ?.value
                  fk_likes =
                    fk_likes +
                    this.state.interestDetail[setArr[p]]?.fk_like_count_sum
                      ?.value
                  fk_shares =
                    fk_shares +
                    this.state.interestDetail[setArr[p]]?.fk_share_count_sum
                      ?.value
                  fk_views =
                    fk_views +
                    this.state.interestDetail[setArr[p]]?.fk_view_count_sum
                      ?.value
                  fk_downloads =
                    fk_downloads +
                    this.state.interestDetail[setArr[p]]?.fk_download_count_sum
                      ?.value
                }

                return (
                  <>
                    <List.Item style={{ width: "100%" }}>
                      <Row
                        gutter={12}
                        style={{ width: "100%" }}
                        className="d-flex align-items-center"
                      >
                        {" "}
                        <Col
                          lg="7"
                          md="8"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              showStrategyDetails: true,
                              currentStrategy: item,
                            })
                          }}
                        >
                          <h4>{item.name}</h4>
                          <div>
                            {item.created_on
                              ? moment(item.created_on).format(
                                  "DD-MM-YYYY:hh:mm:ss"
                                )
                              : ""}
                          </div>
                        </Col>
                        <Col
                          lg="3"
                          md="8"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "21px",
                            color: "grey",
                            alignItem: "center",
                          }}
                        >
                          <div>
                            {" "}
                            <AiFillEye />{" "}
                            <span style={{ fontSize: "16px" }}>
                              {this.state.filter.metric == "absolute"
                                ? formatNumber(views)
                                : fk_views == 0
                                ? "-"
                                : `${((views * 100) / fk_views).toFixed(2)}%`}
                            </span>
                          </div>
                          <div>
                            <AiTwotoneHeart style={{ color: "grey" }} />{" "}
                            <span style={{ fontSize: "16px" }}>
                              {this.state.filter.metric == "absolute"
                                ? formatNumber(likes)
                                : fk_likes == 0
                                ? "-"
                                : `${((likes * 100) / fk_likes).toFixed(2)}%`}
                            </span>
                          </div>

                          <div>
                            {" "}
                            <FaShare />
                            <span style={{ fontSize: "16px" }}>
                              {" "}
                              {this.state.filter.metric == "absolute"
                                ? formatNumber(shares)
                                : fk_shares == 0
                                ? "-"
                                : `${((shares * 100) / fk_shares).toFixed(2)}%`}
                            </span>
                          </div>
                          <div>
                            {" "}
                            <MdDownloadForOffline />
                            <span style={{ fontSize: "16px" }}>
                              {" "}
                              {this.state.filter.metric == "absolute"
                                ? formatNumber(downloads)
                                : fk_downloads == 0
                                ? "-"
                                : `${((downloads * 100) / fk_downloads).toFixed(
                                    2
                                  )}%`}
                            </span>
                          </div>
                        </Col>
                        <Col
                          lg="2"
                          md="3"
                          style={{
                            paddingRight: "1px",
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          {/* <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              this._manageTabAddVideos({
                                interest: item1,
                                subInterest: item,
                                subInterestName:
                                  this.state.subInterestStatusList[item].name,
                              })
                            }}
                            style={{
                              fontSize: "12px",
                              color: "#404bb2",
                              borderColor: "#7b85d8",
                            }}
                            startIcon={
                              <IoMdStats style={{ fontSize: "20px" }} />
                            }
                          >
                            Stats
                          </Button>{" "} */}
                          <div style={{ width: "105px" }}></div>
                          {/* <Button
                            style={{
                              fontSize: "12px",
                              color: "#f24725",
                              borderColor: "#f8a392",
                              width: "105px",
                            }}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              this._manageStopButton(item, "RESUME")
                            }}
                            startIcon={
                              <BsFillStopCircleFill
                                style={{ fontSize: "17px" }}
                              />
                            }
                          >
                            Stop
                          </Button>{" "} */}
                          <div
                            className="p-1 px-2"
                            style={{
                              border: "1px solid #1976d2",
                              borderRadius: "4px",
                            }}
                          >
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: "1",
                                    label: (
                                      <Button
                                        style={{ color: "black" }}
                                        color="primary"
                                        className="btn-block btn btn-primary"
                                        onClick={() => {
                                          // this.props.history.push(`/feed/interests/manage`)
                                          this.props.handleDuplicateStrategy(
                                            item
                                          )
                                        }}
                                        startIcon={<HiDuplicate />}
                                      >
                                        Duplicate
                                      </Button>
                                    ),
                                  },
                                ],
                                selectable: true,
                              }}
                            >
                              <Typography.Link>
                                <Space>
                                  <MoreOutlined
                                    style={{
                                      color: "#1976d2",
                                      marginTop: "9px",
                                    }}
                                  />
                                </Space>
                              </Typography.Link>
                            </Dropdown>
                          </div>
                        </Col>
                      </Row>
                    </List.Item>
                  </>
                )
              }}
            />
          </>
        )}

        {this.state.filterdData.filter(item => item.name != "ACTIVE").length !=
          0 && (
          <>
            {" "}
            Others
            <List
              style={{ width: "100%", margin: "15px 0px" }}
              bordered
              dataSource={this.state.filterdData.filter(
                item => item.status != "ACTIVE"
              )}
              renderItem={(item, index) => {
                let likes = 0
                let shares = 0
                let views = 0
                let downloads = 0
                let fk_likes = 0
                let fk_shares = 0
                let fk_views = 0
                let fk_downloads = 0
                let setArr = []
                for (let k in item.slots) {
                  for (let i = 0; i < item.slots[k].data.length; i++) {
                    for (let j in item.slots[k].data[i].combinations) {
                      setArr.push(j)
                    }
                  }
                }
                // let setArr = [...arrSet]
                for (let p = 0; p < setArr.length; p++) {
                  likes =
                    likes +
                    this.state.interestDetail[setArr[p]]?.like_count_sum?.value
                  shares =
                    shares +
                    this.state.interestDetail[setArr[p]]?.share_count_sum?.value
                  views =
                    views +
                    this.state.interestDetail[setArr[p]]?.view_count_sum?.value
                  downloads =
                    downloads +
                    this.state.interestDetail[setArr[p]]?.download_count_sum
                      ?.value
                  fk_likes =
                    fk_likes +
                    this.state.interestDetail[setArr[p]]?.fk_like_count_sum
                      ?.value
                  fk_shares =
                    fk_shares +
                    this.state.interestDetail[setArr[p]]?.fk_share_count_sum
                      ?.value
                  fk_views =
                    fk_views +
                    this.state.interestDetail[setArr[p]]?.fk_view_count_sum
                      ?.value
                  fk_downloads =
                    fk_downloads +
                    this.state.interestDetail[setArr[p]]?.fk_download_count_sum
                      ?.value
                }
                return (
                  <>
                    <List.Item style={{ width: "100%" }}>
                      <Row
                        gutter={12}
                        style={{ width: "100%" }}
                        className="d-flex align-items-center"
                      >
                        {" "}
                        <Col
                          lg="7"
                          md="8"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              showStrategyDetails: true,
                              currentStrategy: item,
                            })
                          }}
                        >
                          <h4>{item.name}</h4>
                          <div>
                            {item.created_on
                              ? moment(item.created_on).format(
                                  "DD-MM-YYYY:hh:mm:ss"
                                )
                              : ""}
                          </div>
                        </Col>
                        <Col
                          lg="3"
                          md="8"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "21px",
                            color: "grey",
                            alignItem: "center",
                          }}
                        >
                          <div>
                            {" "}
                            <AiFillEye />{" "}
                            <span style={{ fontSize: "16px" }}>
                              {this.state.filter.metric == "absolute"
                                ? formatNumber(views)
                                : fk_views == 0
                                ? "-"
                                : `${((views * 100) / fk_views).toFixed(2)}%`}
                            </span>
                          </div>
                          <div>
                            <AiTwotoneHeart style={{ color: "grey" }} />{" "}
                            <span style={{ fontSize: "16px" }}>
                              {this.state.filter.metric == "absolute"
                                ? formatNumber(likes)
                                : fk_likes == 0
                                ? "-"
                                : `${((likes * 100) / fk_likes).toFixed(2)}%`}
                            </span>
                          </div>

                          <div>
                            {" "}
                            <FaShare />
                            <span style={{ fontSize: "16px" }}>
                              {" "}
                              {this.state.filter.metric == "absolute"
                                ? formatNumber(shares)
                                : fk_shares == 0
                                ? "-"
                                : `${((shares * 100) / fk_shares).toFixed(2)}%`}
                            </span>
                          </div>
                          <div>
                            {" "}
                            <MdDownloadForOffline />
                            <span style={{ fontSize: "16px" }}>
                              {" "}
                              {this.state.filter.metric == "absolute"
                                ? formatNumber(downloads)
                                : fk_downloads == 0
                                ? "-"
                                : `${((downloads * 100) / fk_downloads).toFixed(
                                    2
                                  )}%`}
                            </span>
                          </div>
                        </Col>
                        <Col
                          lg="2"
                          md="3"
                          style={{
                            paddingRight: "1px",
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          {/* <Button
                            color="primary"
                            style={{
                              fontSize: "12px",
                              color: "#404bb2",
                              borderColor: "#7b85d8",
                            }}
                            variant="outlined"
                            onClick={() => {
                              this._manageTabAddVideos({
                                interest: item1,
                                subInterest: item,
                                subInterestName:
                                  this.state.subInterestStatusList[item].name,
                              })
                            }}
                            startIcon={
                              <IoMdStats style={{ fontSize: "20px" }} />
                            }
                          >
                            Stats
                          </Button>{" "} */}
                          <Button
                            style={
                              item.status == "RESUME"
                                ? {
                                    fontSize: "12px",
                                    color: "#f9c612",
                                    borderColor: "#f6d86a",
                                    width: "105px",
                                  }
                                : {
                                    fontSize: "12px",
                                    color: "#0ca789",
                                    borderColor: "#84d3c4",
                                    width: "105px",
                                  }
                            }
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              this.setState({
                                activeStatusPopup: true,
                                toBeactivateStrategy: item,
                              })
                              // this._manageStopButton(item, "ACTIVE")
                            }}
                            // disabled={
                            //   this.state.filterdData.filter(
                            //     item => item.status == "ACTIVE"
                            //   ).length != 0
                            //     ? true
                            //     : false
                            // }
                            startIcon={
                              item.status == "RESUME" ? (
                                <IoMdRefresh style={{ fontSize: "17px" }} />
                              ) : (
                                <BiPowerOff style={{ fontSize: "17px" }} />
                              )
                            }
                          >
                            {item.status ? item.status : "START"}
                          </Button>{" "}
                          <div
                            className="p-1 px-2"
                            style={{
                              border: "1px solid #1976d2",
                              borderRadius: "4px",
                            }}
                          >
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: "1",
                                    label: (
                                      <Button
                                        style={{ color: "black" }}
                                        color="primary"
                                        className="btn-block btn btn-primary"
                                        onClick={() => {
                                          // this.props.history.push(`/feed/interests/manage`)
                                          this.props.handleDuplicateStrategy(
                                            item
                                          )
                                        }}
                                        startIcon={<HiDuplicate />}
                                      >
                                        Duplicate
                                      </Button>
                                    ),
                                  },
                                ],
                                selectable: true,
                              }}
                            >
                              <Typography.Link>
                                <Space>
                                  <MoreOutlined
                                    style={{
                                      color: "#1976d2",
                                      marginTop: "9px",
                                    }}
                                  />
                                </Space>
                              </Typography.Link>
                            </Dropdown>
                          </div>
                        </Col>
                      </Row>
                    </List.Item>
                  </>
                )
              }}
            />
          </>
        )}
        {this._renderStrategyDetails()}
        {this._renderConfirmationPopup()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "zone.loading"),
    common: _.get(store, "common"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "zone.total"),
    currentUserType: _.get(store, "currentUser.currentUser.data.jotUserType"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    languageList: _.get(store, "uiConstants.languageList"),
    pageEntities: _.get(store, "zeroSearch.pageEntities"),
    locationList: _.get(store, "locationReducer.locationList"),
  }
}

export default withRouter(connect(mapStateToProps)(StrategyTabList))
