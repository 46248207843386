import React from "react"
import { connect } from "react-redux"
import QueryString from "query-string"
import _ from "lodash"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import {
  getIssuesComments,
  createIssueComment,
} from "../../appRedux/slices/issuesComments"
import { getIssues, resetState } from "appRedux/slices/creatorIssues"
import Auxiliary from "../../util/Auxiliary"
import Comment from "./comment"
import "toastr/build/toastr.min.css"
import { Button } from "reactstrap"
class IssueDetails extends ErrorBoundComponent {
  state = {
    filters: {
      pageSize:
        this.props.queryParams && this.props.queryParams["pageSize"]
          ? this.props.queryParams["pageSize"]
          : 1000,
      currentPage:
        this.props.queryParams && this.props.queryParams["currentPage"]
          ? this.props.queryParams["currentPage"]
          : 1,
      issueId:
        this.props.queryParams && this.props.queryParams["issueId"]
          ? this.props.queryParams["issueId"]
          : undefined,
    },
    issueFilters: {
      issueId:
        this.props.queryParams && this.props.queryParams["issueId"]
          ? this.props.queryParams["issueId"]
          : undefined,
      isAdmin:
        this.props.currentUser &&
        (this.props.currentUser.permissions.includes("ISSUE_ADMIN") ||
          [1, 2].includes(this.props.currentUser.userType) ||
          [1, 2].includes(this.props.currentUser.jotUserType))
          ? true
          : false,
      owner_email:
        this.props.currentUser && this.props.currentUser["email"]
          ? this.props.currentUser["email"]
          : "",
    },
    redirect: false,
  }

  componentDidMount() {
    if (this.state.filters.issueId) {
      this.refetchIssuesData()
    }
  }

  refetchIssuesData = () => {
    let filters = _.clone(this.state.issueFilters)
    filters = { ...filters, isAdmin: true }
    this.props.dispatch(getIssues(filters))
  }

  componentDidUpdate() {
    if (this.props.refetchData) {
      this.refetchIssuesData()
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetState())
  }

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  _addComment = async (comment, parentCommentId) => {
    let commentData = {}
    let [issueData] = this.props.issuesList
    commentData.commentedBy = this.props.currentUser.email
    commentData.issueId = issueData.issueId
    commentData.status = "active"
    if (parentCommentId) {
      commentData.parentCommentId = parentCommentId
    }

    const taggedUserList = []

    comment?.split("@[").forEach((msg, idx) => {
      if (idx !== 0) {
        const email = msg?.split("](")[1]?.split(")")[0]
        if (email) {
          taggedUserList.push(email)
        }
      }
    })

    let currentMessage = ""
    let isName = false
    let isInsert = true
    let idx = 0
    for (let str of comment) {
      if (comment[+idx] === "@" && comment[+idx + 1] === "[") {
        ;+idx++
        isName = true
      } else if (
        comment[+idx] === "]" &&
        isName === true &&
        comment[+idx + 1] === "("
      ) {
        isName = false
        ;+idx++
        isInsert = false
      } else if (isName && isInsert) {
        currentMessage = currentMessage + comment[+idx]
      } else if (comment[+idx] === ")" && isInsert === false) {
        isInsert = true
      } else if (comment[+idx] !== "@" && isInsert) {
        currentMessage = currentMessage + comment[+idx]
      } else if (isInsert) {
        currentMessage = currentMessage + comment[+idx]
      }
      idx++
      if (idx >= comment.length) {
        break
      }
    }
    commentData.taggedUserList = taggedUserList || []
    commentData.commentText = currentMessage || ""

    this.props.dispatch(createIssueComment(commentData))
    let filters = _.clone(this.state.filters)
    await this.sleep(1000)
    this.props.dispatch(getIssuesComments(filters))
  }

  render() {
    let filters = _.clone(this.state.filters)
    return (
      <Auxiliary
        loading={this.props.issuesDataLoading}
        error={_.get(this.props, "common.error")}
      >
        <div className="d-flex justify-content-between my-2 ms-3">
          <Button color="primary" onClick={() => window.history.back()}>
            <i className="fa fa-angle-left" /> Go Back
          </Button>
        </div>
        <Comment
          commentData={this.props.commentsList}
          issuesData={this.props.issuesList}
          currentUser={this.props.currentUser}
          addComment={this._addComment}
          filters={filters}
          refetchIssuesData={this.refetchIssuesData}
          isAdmin={this.state.issueFilters.isAdmin}
        />
      </Auxiliary>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    loading: _.get(store, "issueComments.loading"),
    issuesDataLoading: _.get(store, "creatorIssues.loading"),
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
    total: _.get(store, "cmsUser.total"),
    common: _.get(store, "common"),
    moderatorList: _.get(store, "cmsUser.user"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    refetchData: _.get(store, "creatorIssues.refetchData"),
    commentsList: _.get(store, "issueComments.commentsList"),
    issuesList: _.get(store, "creatorIssues.issuesList"),
  }
}

export default connect(mapStateToProps)(IssueDetails)
