import { createSlice } from "@reduxjs/toolkit"

const hashtagSlice = createSlice({
  name: "hashtag",
  initialState: {
    hashtagListByFilter: [],
    loading: false,
    total: 0,
  },
  reducers: {
    getHashtagByFilter(state) {
      return {
        ...state,
        loading: true,
      }
    },
    getHashtagByFilterSuccess(state, { payload }) {
      return {
        ...state,
        hashtagListByFilter: payload.data.data,
        total:
          payload.data.total && payload.data.total.value
            ? payload.data.total.value
            : payload.data.total
            ? payload.data.total
            : 1,
        loading: false,
      }
    },
    resetState(state, action) {
      return {
        ...state,
        hashtagListByFilter: [],
        total: 0,
        loading: false,
      }
    },
  },
})

export const { getHashtagByFilter, getHashtagByFilterSuccess, resetState } =
  hashtagSlice.actions

export default hashtagSlice.reducer
