import React from "react"
import { connect } from "react-redux"
import Auxiliary from "../../util/Auxiliary"
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import { getSpinStats } from "../../clientServices/spinConfigService"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import BootstrapTable from "react-bootstrap-table-next"

class SpinStats extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      statsList: [],
      columns: [
        {
          dataField: "label",
          text: "Label",
          style: {
            width: "80%",
          },

          formatter: (cellContent, entity) => {
            const label =
              cellContent?.split("_")?.join(" ")?.toLowerCase() || "-"
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>{label || "-"}</div>
              </div>
            )
          },
        },
        {
          dataField: "value",
          text: "Value",
          headerStyle: {
            textAlign: "center",
          },
          style: {
            width: "20%",
            textAlign: "center",
          },
          formatter: (cellContent, entity) => {
            return (
              <div className="d-flex flex-column text-capitalize">
                <div>
                  {cellContent || (cellContent == 0 ? cellContent : "-")}
                </div>
              </div>
            )
          },
        },
      ],
    }
  }

  componentDidMount = async () => {
    if (!this.props.common.error) {
      this.setState({ loading: true })
      const data = (await getSpinStats()) || {}
      const statsList =
        (data &&
          Object.keys(data)?.map(stats => ({
            label: stats,
            value: data[stats],
          }))) ||
        []
      this.setState({ loading: false, statsList })
    }
  }
  render() {
    const defaultSorted = [
      {
        dataField: "label",
        order: "asc",
      },
    ]

    return (
      <div className="bg-white">
        <Auxiliary
          loading={this.state.loading}
          error={_.get(this.props, "common.error")}
        >
          {this.state?.statsList && this.state?.statsList?.length > 0 ? (
            <div className="d-flex m-2 p-2">
              <div style={{ width: "50%" }}>
                <ToolkitProvider
                  keyField="id"
                  data={
                    this.state.statsList?.slice(
                      0,
                      Math.ceil(this.state.statsList.length / 2)
                    ) || []
                  }
                  columns={this.state.columns}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          // {...paginationTableProps}
                          responsive
                          defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          // selectRow={selectRow}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
              <div style={{ width: "50%" }} className="border-start">
                <ToolkitProvider
                  keyField="id"
                  data={
                    this.state.statsList?.slice(
                      Math.ceil(this.state.statsList.length / 2)
                    ) || []
                  }
                  columns={this.state.columns}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          // {...paginationTableProps}
                          responsive
                          defaultSorted={defaultSorted}
                          bordered={false}
                          striped={false}
                          // selectRow={selectRow}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center hero-section text-muted"
              style={{ fontStyle: "italic" }}
            >
              No data present.
            </div>
          )}
        </Auxiliary>
      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    common: _.get(store, "common"),
    isMobile: _.get(store, "common.isMobile"),
    loading: _.get(store, "spin.loading"),
    spinStats: _.get(store, "spin.spinStats"),
    refetch: _.get(store, "spin.refetchData"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
  }
}

export default connect(mapStateToProps)(SpinStats)
